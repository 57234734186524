import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import CreateableSelect from "../../../../common/components/FormFields/CreateableSelect";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import { fetchToDoServices } from "./Services";

const ToDoListComponent = ({ toDoArr, setToDoArr }) => {
  // Initialize the form with default values
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      serviceName: null,
      isUrgent: false,
      isRepeat: false,
      taskDate: null,
      taskTime: null,
    },
  });

  const [servicesArr, setServicesArr] = useState([]);

  const isUrgent = watch("isUrgent", true);

  const handleAddTask = (data) => {
    console.log(data);
    // Check if serviceName is selected
    if (data.serviceName === null) {
      setError("serviceName", {
        type: "manual",
        message: "Service is required",
      });
      return; // Prevent further execution if there's an error
    }

    const newTask = {
      id: toDoArr.length + 1,
      Task: data.serviceName.value,
      taskId: data.serviceName.id || null,
      groupId: data.serviceName.GroupId || null,
      "Is Urgent": data.isUrgent,
      "Scheduled Date": !!data.taskDate
        ? format(new Date(data.taskDate), "yyyy-MM-dd")
        : null,
      "Scheduled Time": !!data.taskTime
        ? format(data.taskTime, "HH:mm:ss.000")
        : null,
      "Is Repeat": data.isRepeat,
      CreatedBy: data.createdBy || "",
      CreatedFor: data.createdFor || "",
    };

    console.log("newTask", newTask);

    setToDoArr([...toDoArr, newTask]);
    reset();
  };

  const handleDeleteTask = (taskId) => {
    const updatedArr = toDoArr.filter((task) => task.id !== taskId);
    setToDoArr(updatedArr);
  };

  const renderActions = (row) => {
    return (
      <div className="flex gap-2 items-center">
        <div className="flex cursor-pointer items-center">
          <DeleteOnIcon
            title="Delete"
            onClick={() => handleDeleteTask(row.id)}
          />
        </div>
      </div>
    );
  };

  const handleFetchServiceArr = (searchString) => {
    searchString &&
      fetchToDoServices(searchString).then((response) => {
        setServicesArr(response.data.result);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div
      className="mt-3 bg-white shadow-md rounded-lg overflow-hidden "
      onKeyDown={handleKeyDown}
    >
      <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#D9D9D9] px-2 rounded-t-lg">
        To Do List
      </div>
      <form className="p-4">
        <div className="grid grid-cols-8 gap-4">
          <div className="col-span-2">
            <CreateableSelect
              control={control}
              name="serviceName"
              placeholder="Service"
              dataArray={servicesArr}
              onInputChange={(e) => handleFetchServiceArr(e)}
              isSearchable={true}
              searchIcon={true}
              isClearable={true}
              inputRef={register("serviceName")}
              maxMenuHeight={150}
              error={errors.serviceName}
            />
          </div>
          <CheckBoxField
            control={control}
            name="isUrgent"
            label="Is Urgent"
            onChange={(e) => setValue("isUrgent", e.target.checked)}
          />
          <CheckBoxField
            control={control}
            name="isRepeat"
            label="Is Repeat"
            onChange={(e) => setValue("isRepeat", e.target.checked)}
          />
          {/* Conditional Rendering of Date and Time Picker */}
          <div className="col-span-3 grid grid-cols-2 gap-3">
            {!isUrgent && (
              <>
                <DatePickerFieldNew
                  control={control}
                  name="taskDate"
                  label="Scheduled Date"
                  value={null}
                  disableFuture={false}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
                <div className="w-full">
                  <Controller
                    control={control}
                    name="taskTime"
                    render={({ field: { value, onChange } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopTimePicker
                          label="Scheduled Time"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                style: {
                                  fontSize: "14px",
                                  position: "absolute",
                                  top: "-2px",
                                },
                              }}
                              sx={{
                                svg: {
                                  color: "#1e3a8a",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",
                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",
                                    borderColor: "transparent",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
              </>
            )}
          </div>
          <CommonButton
            label="Add"
            className="bg-customBlue text-white"
            onClick={() => handleSubmit(handleAddTask)()}
          />
        </div>
      </form>
      <div className="px-3">
        {toDoArr?.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={toDoArr}
            removeHeaders={["id"]}
            renderActions={renderActions}
          />
        ) : (
          <div className="p-20 text-center">No Data Found!</div>
        )}
      </div>
    </div>
  );
};

export default ToDoListComponent;

