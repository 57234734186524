// export const GeneratePrint = (contentRef) => {
//   const clonedContent = contentRef.current.cloneNode(true);
//   const printStyles = `
//            body {
//              font-family: Arial, sans-serif;
//            }
          
//            @media screen {
//              #pdfContent {
//                display: none !important;
//              }
//            }
         
      
//            @media print {

//            {
//     @page
//     {
//          size: 8.5in 11in; 
//     }
// }
            
//              body * {
//                visibility: hidden;
//                overflow: hidden;
//                height: 0;
//              }
//              #pdfContent, #pdfContent * {
//                visibility: visible;
//                height: auto;
//              }
//             //  #pdfContent {
//             //    position: absolute;
//             //    left: 0;
//             //    top: 0;
//             //  }
//            }
//          `;

//   // Create a new style element for print styles
//   const style = document.createElement("style");
//   style.innerHTML = printStyles;

//   // Set an ID for the cloned content div
//   clonedContent.id = "pdfContent";

//   // Append the style element and cloned content to the document body
//   document.body.appendChild(style);
//   document.body.appendChild(clonedContent);

//   // Print the content
//   window.print();

//   // Cleanup: Remove the added style element and cloned content
//   document.body.removeChild(style);
//   document.body.removeChild(clonedContent);

//   // window.onafterprint = function () {
//   //   setIsPrint(false);
//   // };
// };



export const GeneratePrint = (contentRef, isA5 = false) => {
  const clonedContent = contentRef.current.cloneNode(true);
  
  // Determine page size based on the isA5 prop
  const pageSize = isA5 ? '5.83in 8.27in' : '8.5in 11in'; // A5 vs A4

  const printStyles = `
    body {
      font-family: Arial, sans-serif;
    }
    
    @media screen {
      #pdfContent {
        display: none !important;
      }
    }

    @media print {
      @page {
        size: ${pageSize};
      }
      
      body * {
        visibility: hidden;
        overflow: hidden;
        height: 0;
      }
      #pdfContent, #pdfContent * {
        visibility: visible;
        height: auto;
      }
    }
  `;

  // Create a new style element for print styles
  const style = document.createElement("style");
  style.innerHTML = printStyles;

  // Set an ID for the cloned content div
  clonedContent.id = "pdfContent";

  // Append the style element and cloned content to the document body
  document.body.appendChild(style);
  document.body.appendChild(clonedContent);

  // Print the content
  window.print();

  // Cleanup: Remove the added style element and cloned content
  document.body.removeChild(style);
  document.body.removeChild(clonedContent);
};

