import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
// import { unitDropDown } from "../../../services/CommonServices/CommonService";

import Tabs from "./Tabs";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  getPathTestdata,
  pathCategoryDropdown,
  postTestCreation,
  serviceDropdownSearch,
} from "../../../services/pathology/Test/TestCreationService";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  // height: "2xl:25% xl:",
  // overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
  p: 3,
};

export default function TestCreationModel(props) {
  const schema = yup.object().shape({
    testName: yup.string().required("Required"),
    // testCode: yup.string().required("Required"),
    printReportName: yup.string().required("Required"),
    category: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Please Select Item Category"),
        label: yup.string().required("Please Select Item Category"),
      }),
  });

  const defaultValues = {
    id: "",
    testName: "",
    testCode: "",
    printReportName: "",
    unit: null,
    status: true,
    subTestName: "",
    suggestionNote: "",
    footNote: "",
    machineName: null,
    timeCompletion: "",
    techniqueUsed: "",
    cultureSensitivityTest: false,
    hasAuthentication: false,
    isConsent: false,
    uniqueSampleNoRequired: false,
    isHistopathTest: false,
    parameterNamehas: null,
    parameterName: null,
    hasSubTest: false,
    template: null,
    inableScope: false,
    hideOrder: false,
    reportTemplate: false,
  };
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    watch,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = methods;

  const [unitOption, setUnitOption] = React.useState([]);
  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);
  const [parameterData, setParameterData] = React.useState([]);
  const [storeData, setStoreData] = React.useState([]);
  const [parameterId, setParameteId] = React.useState([]);
  const [serviceOption, setServiceOption] = React.useState([]);
  const [templateData, setTemplateData] = React.useState([]);
  const [pathCategoryOption, setPathCategoryOption] = useState([]);
  const [editData, setEditdata] = useState(null);

  let isHistopathTestWatch = watch("isHistopathTest");
  let ReportTemplate = watch("reportTemplate");

  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };
  let watchSubTest = watch("hasSubTest");

  console.log("isHistopathTestWatch", editData);

  let isHistopathTestValue;
  if (isHistopathTestWatch === true) {
    isHistopathTestValue = 0;
  } else if (isHistopathTestWatch === false) {
    isHistopathTestValue = 1;
  }

  let templateArr = templateData?.map((row) => row.id);
  let hideOrder = watch("hideOrder");
  console.log("openPost", hideOrder);

  const onSubmitDataHandler = (data) => {
    console.log("data is department", data);

    if (props.edit === false) {
      let saveObj = {
        addedBy: 154,
        categoryId: data?.category?.id,
        isNablScope: data?.inableScope === true ? true : false,
        isHideOrder: data?.hideOrder === true ? true : false,
        interpretation: data?.interpretation,
        footNote: data.footNote,
        hasBorder: true,
        hasNormalRange: true,
        hasObserved: true,
        hasTemplate: ReportTemplate === true ? true : false,
        hasThreeLevels: true,
        hasWordProcessor: true,
        isApproved: data?.hasAuthentication === true ? true : false,
        isBlankTemplate: true,
        isConsent: data.isConsent,
        isCultureSenTest:
          data.cultureSensitivityTest === true
            ? data.cultureSensitivityTest
            : false,
        isHistopathTest:
          isHistopathTestValue === true ? isHistopathTestValue : false,
        isMicro: true,
        isProfileTest: true,
        isSubTest: watchSubTest,
        isUniqueSampleNoRequired:
          data.uniqueSampleNoRequired === true ? true : false,
        machine: "string",
        nearTAT:
          Number(data?.timeCompletion) !== " "
            ? Number(data?.timeCompletion)
            : 0,
        note: data?.suggestionNote,
        pathTemplateIds: templateArr,
        pathTestId: 0,
        pathTestParameterDtoList:
          watchSubTest === false
            ? parameterData?.length > 0
              ? parameterData
              : null
            : finalData?.length > 0
            ? finalData
            : null,
        provTAT: 0,
        serviceId: data?.service?.id,
        status: data.status,
        technique: data.techniqueUsed,
        template: "string",
        testCode: "string",
        testCost: 0,
        testName: data.testName,
        testPrintName: data.printReportName,
        turnAroundTime: "string",
        updatedBy: 154,
      };
      console.log("saveObj", saveObj);
      if (ReportTemplate === true && templateArr?.length === 0) 
      {
        warningAlert("Please Select At Least one Template")
      } else {
        setStoreData(saveObj);
        setOpenPost(true);
      }
    } else if (props.edit === true) {
      let updateObj = {
        addedBy: 154,
        categoryId: 0,
        isNablScope: data?.inableScope === true ? true : false,
        isHideOrder: data?.hideOrder === true ? true : false,
        footNote: data.footNote,
        hasBorder: true,
        hasNormalRange: true,
        hasObserved: true,
        hasTemplate: true,
        hasThreeLevels: true,
        hasWordProcessor: true,
        isApproved: data?.hasAuthentication === true ? true : false,
        isBlankTemplate: true,
        isConsent: data.isConsent === true ? true : false,
        isCultureSenTest: data.cultureSensitivityTest === true ? true : false,
        isHistopathTest: isHistopathTestValue === true ? true : false,
        isMicro: true,
        isProfileTest: true,
        isSubTest: data.hasSubTest === true ? true : false,
        isUniqueSampleNoRequired:
          data.uniqueSampleNoRequired === true ? true : false,
        machine: "string",
        nearTAT: 0,
        note: "string",
        pathTemplateIds: templateArr,
        pathTestId: 0,
        pathTestParameterDtoList:
          watchSubTest === false
            ? parameterData?.length > 0
              ? parameterData
              : null
            : finalData?.length > 0
            ? finalData
            : null,
        provTAT: 0,
        serviceId: 0,
        status:data.status === true?true:false,
        technique: data.techniqueUsed,
        template: "string",
        testCode: "string",
        testCost: 0,
        testName: data.testName,
        testPrintName: data.printReportName,
        turnAroundTime: "string",
        updatedBy: 154,
      };
      setOpenPut(true);
      setStoreData(updateObj);
    }
  };

  //Post / Add API Function
  function addRecord() {
    postTestCreation(storeData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleReset();
        props.setOpen(false);
        props.setEdit(false);
        setOpenPost(false);
        props.populateTable();
        setOpenPut(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  //Unit Dropdown API
  // function unitDropDownApi() {
  //   unitDropDown().then((response) => {
  //     setUnitOption(response.data.result);
  //   });
  // }

  // Service AutoSearch Dropdown
  const handleChange = (autoSearchString) => {
    console.log("autoSearchString", autoSearchString);
    autoSearchString &&
      serviceDropdownSearch(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          console.log("resItem", res.result);
          setServiceOption(res.result);
        });
  };

  //Path Category Dropdown
  function pathCategoryDropdownApi() {
    pathCategoryDropdown().then((response) => {
      setPathCategoryOption(response.data.result);
    });
  }

  useEffect(() => {
    // unitDropDownApi();
    pathCategoryDropdownApi();
  }, []);
  useEffect(() => {
    if (props.edit === true) {
      getPathTestdata(props.dataEdit?.PathTestId).then((response) => {
        setEditdata(response.data.result);
      });
    }
  }, [props.edit]);

  console.log("editDataeditData", editData);
  useEffect(() => {
    if (editData !== null && editData !== undefined) {
      setValue("testName", editData["Test Name"]);
      setValue("printReportName", editData["Test Print Name"]);
      setValue("category", {
        id: editData?.CategoryId,
        label: editData["Path Category"],
        value: editData["Path Category"],
      });
      setValue("service", {
        id: editData?.ServiceId,
        label: editData["Service Name"],
        value: editData["Service Name"],
      });
      setValue("status", editData?.Status);
      setValue("reportTemplate", editData?.HasTemplate);
      setValue("suggestionNote", editData?.Note);
      setValue("footNote", editData?.FootNote);
      setValue("timeCompletion", editData?.NearTAT);
      setValue("interpretation", editData?.Interpretation);
      setValue("inableScope", editData?.isNablScope);
      setValue("hideOrder", editData?.isHideOder);
      setValue("techniqueUsed", editData?.Technique);
      setValue("cultureSensitivityTest", editData?.IsCultureSenTest);
      setValue("hasAuthentication", editData?.IsApproved);
      setValue("isConsent", editData?.isconsent);
      setValue("uniqueSampleNoRequired", editData?.IsUniqueSampleNoRequired);
      setValue("isHistopathTest", editData?.IsHistopathTest);
      setValue("hasSubTest", editData?.IsSubTest);

      if (editData !== null) {
        let newArr = [...templateData];
        if (editData["Test Templates"] !== null) {
          for (let obj of editData?.["Test Templates"]) {
            newArr.push({
              id: obj?.Id,
              template: obj?.ShortDescription,
            });
            setTemplateData(newArr);
          }
        }
      }
      if (editData !== null) {
        let newArr = [...parameterData];
        if (editData["Test Parameters"] !== null) {
          for (let obj of editData?.["Test Parameters"]) {
            newArr.push({
              parameterId: obj?.ParameterId,
              parameterName: obj?.Description,
            });
            setParameterData(newArr);
          }
        }
      }
    }
  }, [editData]);

  function handleReset() {
    setValue("category", null);
    setValue("inableScope", false);
    setValue("hideOrder", false);
    setValue("testName", "");
    setValue("printReportName", "");
    setValue("service", "");
    setValue("status", false);
    setValue("reportTemplate", false);
    setValue("inableScope", false);
    setValue("hideOrder", false);
    setValue("suggestionNote", "");
    setValue("footNote", "");
    setValue("machineName", null);
    setValue("timeCompletion", null);
    setValue("techniqueUsed", null);
    setValue("cultureSensitivityTest", false);
    setValue("hasAuthentication", false);
    setValue("isConsent", false);
    setValue("isHistopathTest", false);
    setValue("hasSubTest", false);
    setFinalData([]);
    setTemplateData([]);
    setParameterData([]);
  }

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              reset(defaultValues);
              props.handleClose();
              props.setEdit(false);
              handleReset();
              setEditdata(null);
            }}
          />
          <FormProvider {...methods}>
            <fieldset className=" text-left lg:px-2  py-2 rounded mt-1 lg:m-2 border border-grey-100 p-3">
              <legend className="px-3 ml-4 lg:ml-0 font-bold text-gray-800">
                Pathalogy Test Creation
              </legend>
              <form
                className="grid grid-cols-1 w-full  pt-2"
                onSubmit={handleSubmit(onSubmitDataHandler)}
              >
                <div className="grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 px-5 lg:px-2 gap-3">
            

                  <div>
                    <InputField
                      maxLength={100}
                      control={control}
                      name="testName"
                      label="Test  Description *"
                      error={errors.testName}
                    />
                  </div>
                  <div>
                    <InputField
                      control={control}
                      name="printReportName"
                      label="Print Report Name *"
                      error={errors.printReportName}
                    />
                  </div>

                  <div>
                    <DropdownField
                      control={control}
                      name="category"
                      placeholder="Category *"
                      error={errors.category}
                      dataArray={pathCategoryOption}
                    />
                  </div>
                  <div>
                    <SearchDropdown
                      control={control}
                      name="service"
                      placeholder="Service"
                      searchIcon={true}
                      dataArray={serviceOption}
                      handleInputChange={handleChange}
                    />
                  </div>
                  <div className="flex gap-2 -mt-1">
                    <CheckBoxField
                      control={control}
                      label="Active"
                      name="status"
                    />
                    <CheckBoxField
                      control={control}
                      label="Report Template"
                      name="reportTemplate"
                    />
                  </div>
                  <div className="col-span-2">
                    <InputArea
                      control={control}
                      name="interpretation"
                      label="Interpretation"
                    />
                  </div>
                  <div className="flex gap-2 -mt-2 ">
                    <CheckBoxField
                      control={control}
                      name="inableScope"
                      label="Is NablScope"
                    />
                    <CheckBoxField
                      control={control}
                      label="Hide Order "
                      name="hideOrder"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 pt-2 pl-2">
                  <Tabs
                    ReportTemplate={ReportTemplate}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    setParameterData={setParameterData}
                    parameterData={parameterData}
                    setParameteId={setParameteId}
                    parameterId={parameterId}
                    setTemplateData={setTemplateData}
                    templateData={templateData}
                  />
                </div>

                <div className="flex space-x-3  justify-end pt-2">
                  {props.edit !== false ? (
                    <CommonButton
                      label="Cancel"
                      className="border border-red-600 text-red-600"
                      type="button"
                      onClick={() => {
                        props.handleClose();
                        props.setEdit(false);
                        reset(defaultValues);
                      }}
                    />
                  ) : (
                    <CommonButton
                      label="Reset"
                      className=" border border-red-600 text-red-600"
                      type="button"
                      onClick={() => reset(defaultValues)}
                    />
                  )}
                  {props.edit === true ? (
                    <CommonButton
                      label="Update"
                      className="bg-customBlue text-white"
                      type="submit"
                    />
                  ) : (
                    <CommonButton
                      label="Add"
                      className="bg-customBlue text-white"
                      type="submit"
                    />
                  )}
                </div>
              </form>
            </fieldset>
          </FormProvider>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationSubmitFunc={addRecord}
        confirmationHandleClose={() => {
          setOpenPost(false);
        }}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
      <ConfirmationModal
        confirmationOpen={openPut}
        confirmationHandleClose={handleClosePut}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Update"
      />
    </div>
  );
}
