import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

// list of gtransfer
export const fetchListOfTransfer = (admissionId) => {
  return apiClient.get(`/admission/bedTransferList/${admissionId}`, {
    headers: authHeader(),
  });
};

// bed transfer data
export const postBedTransferData = (finalObject) => {
  return apiClient.post(`/bedTransfer/saveBedTransfer`, finalObject, {
    headers: authHeader(),
  });
};

// bed transfer data
export const fetchPatientTransferPatientInfo = (admissionId) => {
  return apiClient.get(`/patientTransfer/patientInfo/${admissionId}`, {
    headers: authHeader(),
  });
};

// bed transfer data
export const fetchBedTransferAutoComplete = (searchString) => {
  return apiClient.get(`/bedTransfer/patientSearch/${searchString}`, {
    headers: authHeader(),
  });
};
