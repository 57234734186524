import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//department
export const fetchDepartmentList = () => {
  return apiClient.get(`/dischargeAdviceBy/departmentList`, {
    headers: authHeader(),
  });
};

//department
export const fetchUserList = (userId) => {
  return apiClient.get(`/dischargeAdviceBy/usersList/${userId}`, {
    headers: authHeader(),
  });
};

//post final obj
export const postDischargeMessage = (finalObj) => {
  return apiClient.post(
    `/dischargeAdviceBy/sendMessageToDepartment`,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

//used in messeging console/task manager
export const fetchListOfMessages = (listObj) => {
  return apiClient.post(`/dischargeAdviceBy/getListOfMessages`, listObj, {
    headers: authHeader(),
  });
};
