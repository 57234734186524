import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { successAlert } from "../../../common/components/Toasts/CustomToasts";
import {
  fetchETURegisterList,
  saveETURegister,
} from "../../services/etuRegisterServices/etuRegisterServices";
import BodyHandOverPrint from "../../prints/BodyHandOverPrint";

const removeHeaders = [
  "patchDate",
  "Address",
  "AgeYear",
  "ArrivalTime",
  "BatchNo",
  "BillId",
  "BillNo",
  "BodyHandedOverBy",
  "BodyHandedOverTo",
  "BodyHandoverRemark",
  "DepartmentId",
  "DepartureTime",
  "Diagnosis",
  "Gender",
  "GenderId",
  "ID",
  "IsDC",
  "IsDoctorVisit",
  "IsMLC",
  "IsNurseVisit",
  "IsPM",
  "IsSingleDose",
  "IsVaccineDateEdited",
  "MortuaryInTime",
  "MortuaryOutTime",
  "NextVaccineDate",
  "PatientId",
  "ReceivedCallDateTime",
  "StaffIds",
  "Staffs",
  "Treatement",
  "TrnType",
  "VaccineDoseDurationID",
  "VaccineId",
  ,
  "VisitedLocation",
];
const actions = ["print"];
const BodyHandover = (props) => {
  const schema = yup.object().shape({});
  const defaultValues = {
    isMlc: "",
    isPm: "",
    isDc: "",
    bodyHandOverTo: "",

    bodyHandOverBy: "",
    mortuaryOutTime: "",
    bodyHandOverRemark: "",
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [departureTime, setDepartureTime] = React.useState(new Date());
  const [mortuaryInTimeVal, setMortuaryInTimeVal] = React.useState(new Date());
  const [mortuaryOutTime, setMortuaryOutTime] = React.useState(new Date());
  const [bodyHandOverObj, setBodyHandOverObj] = React.useState(null);
  const [bodyHanoverList, setBodyHandOverList] = React.useState([]);
  const [rowIndex, setRowIndex] = React.useState();
  const [cellInfo, setCellInfo] = React.useState();
  const [bodyHandOverDate, setBodyHandOverDate] = React.useState(new Date());
  const [resetConfirmationOpen, setResetConfirmationOpen] = React.useState(
    false
  );
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [privilege, setPrivilege] = React.useState(null);
  let callreveiveddatetimeVal = watch("callreveiveddate");

  let staffsVal = watch("staffs");
  let mlcVal = watch("mlc");
  let dcVal = watch("dc");
  let pmVal = watch("isPm");
  let location = useLocation();

  //useEffects
  useEffect(() => {}, []);
  //functions
  const handleOpen = () => {
    setConfirmationOpen(true);
  };
  const handleClose = () => {
    setConfirmationOpen(false);
  };

  const handleResetOpenConfirmation = () => {
    setResetConfirmationOpen(true);
  };
  const handleResetCloseConfirmation = () => {
    if (resetConfirmationOpen === true) {
      setResetConfirmationOpen(false);
    }
  };

  // fetch listing of etu register home visit
  useEffect(() => {
    fetchETURegisterList(props.eturegisteroptionVal.label)
      .then((response) => response.data)
      .then((res) => {
        setBodyHandOverList(res);
      });
  }, []);

  useEffect(() => {
    let staffObj = {};
    staffObj.id = 0;
    staffObj.value = cellInfo?.Staffs;
    staffObj.label = cellInfo?.Staffs;
    if (cellInfo) {
      setValue("isMlc", cellInfo?.IsMLC === 1 ? true : false);
      setValue("isPm", cellInfo?.IsPM === 1 ? true : false);
      setValue("staffs", cellInfo?.staffObj);
      setValue("isDc", cellInfo?.IsDC === 1 ? true : false);
      setValue("bodyHandOverTo", cellInfo?.BodyHandedOverTo);
      setValue("bodyHandOverBy", cellInfo?.BodyHandedOverBy);
      setValue("bodyHandOverRemark", cellInfo?.BodyHandoverRemark);
      let handOverDate = cellInfo?.patchDate;
      setBodyHandOverDate(handOverDate);
      const formatedMortuaryInTime = cellInfo?.MortuaryInTime;
      setMortuaryInTimeVal(new Date(formatedMortuaryInTime));
      const formatedMortuaryOutTime = cellInfo?.MortuaryOutTime;
      setMortuaryOutTime(new Date(formatedMortuaryOutTime));
    }
  }, [cellInfo]);

  useEffect(() => {
    if (props.showInfo === true) {
      setValue("bodyHandOverDate", new Date());
      setValue("bodyHandOverTo", "");
      setValue("mortuaryInTime", new Date());
      setValue("bodyHandOverBy", "");
      setValue("mortuaryOutTime", new Date());
      setValue("bodyHandOverRemark", "");
      setValue("isMlc", false);
      setValue("isPm", false);
      setValue("isDc", false);
    }
  }, [props.showInfo]);

  function OnSubmitDataHandler(data) {
    let getPatientInfo = localStorage.getItem("userInfo");
    let getPatientInfoObj = JSON.parse(getPatientInfo);

    let postETURegisterObj = {
      arrivalTime: "",

      batchNo: data.batchNo ? data.batchNo : null,

      billId: 0,
      addedBy: getPatientInfoObj.userId,
      billNo: data.billNo ? data.billNo : null,
      bodyHandOverBy: data.bodyHandOverBy ? data.bodyHandOverBy : null,
      bodyHandOverRemark: data.bodyHandOverRemark
        ? data.bodyHandOverRemark
        : null,
      bodyHandOverTo: data.bodyHandOverTo ? data.bodyHandOverTo : null,
      departmentId: data.departmentId ? data.departmentId : null,
      departureTime: format(departureTime, "yyyy-MM-dd'T'HH:mm:ss")
        ? format(departureTime, "yyyy-MM-dd'T'HH:mm:ss")
        : null,
      diagnosis: data.diagnosis ? data.diagnosis : null,
      isDc: data.isDc ? data.isDc : null,
      isDoctorVisit: data.isDoctorVisit ? data.isDoctorVisit : null,
      isMlc: data.isMlc ? data.isMlc : null,
      isNurseVisit: data.isNurseVisit ? data.isNurseVisit : null,
      isPm: data.isPm ? data.isPm : null,
      isSingleDose: data.isSingleDose ? data.isSingleDose : null,
      isVaccineDateEdited: data.isVaccineDateEdited
        ? data.isVaccineDateEdited
        : null,
      isVccRemainderSMSSend: data.isVccRemainderSMSSend
        ? data.isVccRemainderSMSSend
        : null,
      mortuaryInTime: format(mortuaryInTimeVal, "yyyy-MM-dd'T'HH:mm:ss")
        ? format(mortuaryInTimeVal, "yyyy-MM-dd'T'HH:mm:ss")
        : null,
      mortuaryOutTime: format(mortuaryOutTime, "yyyy-MM-dd'T'HH:mm:ss")
        ? format(mortuaryOutTime, "yyyy-MM-dd'T'HH:mm:ss")
        : null,
      nextVaccineDate: "2023-05-02T10:16:17.049Z",
      patientId: props.patientId,
      receivedCallDateTime: callreveiveddatetimeVal
        ? callreveiveddatetimeVal
        : null,

      tmType: props.eturegisteroptionVal.label,
      treatment: data.treatment,
      vaccineDoseDurationId: data.vaccineDoseDurationId
        ? data.vaccineDoseDurationId
        : null,
      vaccineId: data.vaccineId ? data.vaccineId : null,
      visitedLocation: data.visitedLocation ? data.visitedLocation : null,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    setBodyHandOverObj(postETURegisterObj);
    handleOpen();
  }

  function addRecord() {
    let etuObj = bodyHandOverObj;
    saveETURegister(etuObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        reset(defaultValues, props.etuRegisterSearchVal);
        props.populateTable();
      });
  }

  function resetHomeVisit() {
    handleResetCloseConfirmation();
    setBodyHandOverDate(new Date());
    setMortuaryInTimeVal(new Date());
    setMortuaryOutTime(new Date());
    reset(defaultValues);
    setRowIndex();
    props.setShowInfo(true);
  }

  function displayRowData(row) {}
  // get row action and row data
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1 items-center cursor-pointer">
        {props.userActions &&
          props.userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-1 items-center cursor-pointer">
                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title={"Print"}>
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                              // if (props.etuType === "HomeVisit") {
                              //   props.printHomeReceipt(Number(row?.ID));
                              // } else if (props.etuType === "AmbulanceCall") {
                              //   props.printAmbulanceCallReceipt(
                              //     Number(row?.ID)
                              //   );
                              // } else if (props.etuType === "BodyHandover") {
                              //   props.printBodyHandoverReceipt(Number(row?.ID));
                              // } else if (props.etuType === "Vaccine") {
                              //   props.printVaccineChartReceipt(Number(row?.ID));
                              // }
                              props.setEtuRegisterId(row?.ID);
                              setOpenPrintModal(true);
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };
  // get selected Row Data
  function handleSelectedRow(row, index) {
    displayRowData(row, index);
    setCellInfo(row);
    if (props.showInfo === true) {
      props.setShowInfo(false);
    }
  }

  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 2000);

    return (
      <div className="hidden">
        <BodyHandOverPrint
          etuRegisterId={props.etuRegisterId}
          selectedFromDate={props.selectedFromDate}
          selectedToDate={props.selectedToDate}
        />
      </div>
    );
  };

  return (
    <div className=" pb-4">
      {props.showInfo === true ? (
        <>
          {props.patientInfoById && props.patientInfoById !== "" ? (
            <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
              <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 font-semibold">Patient Name</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById.PatientName &&
                      props.patientInfoById.PatientName}
                  </span>
                </div>
                <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                    MR No.
                  </span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById && props.patientInfoById.MRNo}
                  </span>
                </div>

                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 xl:w-8 font-semibold">Age</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById && props.patientInfoById.AgeYear}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                  <span className="w-24 lg:w-20 font-semibold">Address </span>

                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById && props.patientInfoById.Address}
                  </span>
                </div>
              </div>
            </fieldset>
          ) : null}
        </>
      ) : null}
      <div className="w-full py-2">
        {props.dataResult.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={props.dataResult}
            highlightRow={true}
            renderActions={renderActions}
            handleSelectedRow={handleSelectedRow}
            tableClass="lg:h-[300px] 2xl:h-[450px]"
            removeHeaders={removeHeaders}
          />
        ) : (
          <div className="text-center py-12">
            <span>Records Not Found...</span>
          </div>
        )}
        {/* {props.data.length > 0 ? (
          <EtuRegisterTable
            showInfo={props.showInfo}
            rowIndex={rowIndex}
            setRowIndex={setRowIndex}
            setShowInfo={props.setShowInfo}
            actions={actions}
            data={props.data}
            setData={props.setData}
            displayRowData={displayRowData}
            setCellInfo={setCellInfo}
            etuType={props?.etuType}
            printBodyHandoverReceipt={props.printBodyHandoverReceipt}
          />
        ) : (
          <div className="text-center py-12">
            <span>Records Not Found...</span>
          </div>
        )} */}
      </div>
      {props.showInfo === false ? (
        <>
          {cellInfo && cellInfo !== "" ? (
            <>
              <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 font-semibold">Patient Name</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo?.PatientName && cellInfo?.PatientName}
                    </span>
                  </div>
                  <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                      MR No.
                    </span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.MRNo}
                    </span>
                  </div>

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 xl:w-8 font-semibold">Age</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.AgeYear}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                    <span className="w-24 lg:w-20 font-semibold">Address</span>

                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.Address}
                    </span>
                  </div>
                </div>
              </fieldset>
            </>
          ) : null}
        </>
      ) : null}
      {(props.patientInfoById && props.patientInfoById !== "") ||
      (cellInfo && cellInfo !== "") ? (
        <>
          <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-1 items-center">
            <div className="text-md font-semibold py-2">Body Handover</div>
            <div className=" flex justify-end">
              {props.showInfo === true ? null : (
                <CommonButton
                  label="New"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    handleResetOpenConfirmation();
                  }}
                />
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(OnSubmitDataHandler)}>
            <div className="flex gap-2">
              <div>
                <DatePickerFieldNew
                  control={control}
                  name="bodyHandOverDate"
                  label="Body Handover Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("bodyHandOverDate", newValue);
                  }}
                  // disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <CheckBoxField name="isMlc" label="MLC" control={control} />
              <CheckBoxField name="isPm" label="PM" control={control} />

              <CheckBoxField name="isDc" label="DC" control={control} />
            </div>
            <div className="grid grid-cols-4 gap-2 py-2">
              <div className=" ">
                <InputField
                  control={control}
                  name="bodyHandOverTo"
                  label="Body Handed Over To"
                />
              </div>
              <div className=" ">
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopTimePicker
                        label="Mortuary In Time"
                        value={mortuaryInTimeVal}
                        onChange={(newValue) => {
                          setMortuaryInTimeVal(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            fullWidth
                            size="small"
                            {...params}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                position: "absolute",
                                top: "-2px",
                              },
                            }}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "#0B83A5",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  name="mortuaryInTime"
                />
              </div>
              <div className=" ">
                <InputField
                  control={control}
                  name="bodyHandOverBy"
                  label="Body Handed Over By"
                />
              </div>
              <div className=" ">
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopTimePicker
                        label="Mortuary Out Time"
                        value={mortuaryOutTime}
                        onChange={(newValue) => {
                          setMortuaryOutTime(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            fullWidth
                            size="small"
                            {...params}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                position: "absolute",
                                top: "-2px",
                              },
                            }}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "#0B83A5",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  name="mortuaryOutTime"
                />
              </div>
            </div>
            <div className="w-full">
              <TextField
                id="outlined-multiline-static"
                name="bodyHandOverRemark"
                {...register("bodyHandOverRemark")}
                label="Body Handover Remark"
                InputLabelProps={{ shrink: true }}
                multiline
                fullWidth
              />
            </div>
            <div className="flex gap-2 justify-end py-2">
              {props.userActions &&
                props.userActions.map((actions, i) => (
                  <>
                    {actions.isAction === false ? (
                      <>
                        <div className="flex gap-2 cursor-pointer">
                          {actions.action === "Create" ? (
                            <>
                              {props.showInfo === true ? (
                                <>
                                  <div className="">
                                    <CommonButton
                                      label="Reset"
                                      type="button"
                                      className="border border-customRed text-customRed"
                                      onClick={() => {
                                        reset(defaultValues);
                                        props.setPatientInfoById("");
                                        props.setValue(
                                          "etuRegisterSearch",
                                          null
                                        );
                                        setCellInfo("");
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <CommonButton
                                      type="submit"
                                      label="Save"
                                      className="bg-customGreen text-white"
                                      onClick={() => {
                                        setPrivilege(actions?.action);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
            </div>
          </form>
        </>
      ) : null}
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          handleClose();
          addRecord();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save ETU Details?"
        confirmationButtonMsg="Save"
      />
      <ConfirmationModal
        confirmationOpen={resetConfirmationOpen}
        confirmationHandleClose={handleResetCloseConfirmation}
        confirmationSubmitFunc={() => {
          resetHomeVisit();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure to Reset Body Handover?"
        confirmationButtonMsg="Proceed"
      />
      {openPrintModal && renderPrint()}
    </div>
  );
};

export default BodyHandover;
