import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { CloseIcon } from "../../../assets/icons/CustomIcons";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import InputField from "../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import DatePickerField from "../../../common/components/FormFields/DatePickerFieldNew";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  getOfficePermissionSearch,
  saveNewOfficePermission,
} from "../../services/officePermissionServices/OfficePermissionsServices";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
  padding: 2,
};
const NewOfficeModal = (props) => {
  const {
    openNewRquestModal,
    setOpenNewRequestModal,
    actionObject,
    setActionObject,
    getUserInfo,
    floorId,
    populateTable,
    displayInfo,
  } = props;
  const [openPost, setOpenPost] = useState(false);
  const [searchPatientOptions, setSearchPatientOptions] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const { control, setValue, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      searchPatient: null,
      surgeryName: "",
      surgeryDate: null,
      isOtReserved: false,
      remark: "",
    },
  });

  const handlePatientSearch = (searchString) => {
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString)) {
      getOfficePermissionSearch(
        searchString,
        floorId?.id > 0 ? floorId?.id : 0
      ).then((response) => {
        setSearchPatientOptions(response.data.result);
      });
    }
  };

  const handlePatientSelect = (value) => {
    if (value !== null) {
    }
  };

  const onSubmitData = (data) => {
    console.log("datadatadata123", data);
    let obj = {
      mrno:
        displayInfo !== null ? displayInfo?.MRNo : data?.searchPatient?.MRNo,
      admissionId:
        displayInfo !== null
          ? displayInfo?.AdmissionId
          : data?.searchPatient?.id,
      surgeryName: data?.surgeryName,
      surgeryDate: data?.surgeryDate,
      isOtReserved: data?.isOtReserved,
      userName: getUserInfo?.userName,
      remark: data?.remark,
      privilege: actionObject?.action,
      menuId: actionObject?.menuId,
    };
    console.log("objobjobj123", obj);

    setFinalData(obj);
    setOpenPost(true);
  };

  const saveNewOffice = () => {
    saveNewOfficePermission(finalData)
      .then((response) => {
        successAlert(response.data.message);
        setOpenPost(false);
        setOpenNewRequestModal(false);
        populateTable(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenPost(false);
      });
  };

  return (
    <>
      <Modal open={openNewRquestModal}>
        <Box sx={ModalStyle} className="space-y-2">
          <div className="flex justify-between">
            <label className="font-semibold">New Request</label>
            <button
              type="button"
              onClick={() => {
                setOpenNewRequestModal(false);
                setActionObject(null);
              }}
            >
              <CloseIcon />
            </button>
          </div>
          {displayInfo !== null && displayInfo !== undefined && (
            <div className="p-2 bg-[#F1F1F1] flex space-x-10 items-center">
              <div className="flex items-center">
                <label className="font-semibold">Patient Name</label>
                <label>&nbsp;:&nbsp;{displayInfo?.["Patient Name"]}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold">Bed No.</label>
                <label>&nbsp;:&nbsp;{displayInfo?.["Bed No"]}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold">MRNo.</label>
                <label>&nbsp;:&nbsp;{displayInfo?.["MRNo"]}</label>
              </div>
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmitData)}
            className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3"
          >
            {(displayInfo === null || displayInfo === undefined) && (
              <SearchDropdown
                control={control}
                name="searchPatient"
                placeholder="Search By Patient Name/MRNo"
                searchIcon={true}
                isClearable={true}
                dataArray={searchPatientOptions}
                handleInputChange={handlePatientSearch}
                onChange={handlePatientSelect}
              />
            )}
            <InputField
              control={control}
              name="surgeryName"
              label="Surgery Name"
            />
            <div className="flex space-x-2">
              <DatePickerField
                control={control}
                name="surgeryDate"
                inputFormat="dd-MM-yyyy"
                onChange={(newDate) => {
                  setValue("surgeryDate", newDate);
                }}
              />
              <CheckBoxField
                control={control}
                name="isOtReserved"
                label="OT Reserved"
              />
            </div>
            <div className="flex space-x-2 lg:col-span-2">
              <InputField control={control} name="remark" label="Remark" />
              <CommonButton
                type="submit"
                label="Save"
                className="bg-customGreen text-white"
              />
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={() => setOpenPost(false)}
        confirmationSubmitFunc={saveNewOffice}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure want to add New Office ?"
        confirmationButtonMsg="Confirm"
      />
    </>
  );
};

export default NewOfficeModal;

