import React from "react";
import { Box, Modal } from "@mui/material";
import CommonButton from "./Buttons/CommonButton";

export default function ConfirmationModal({
  confirmationOpen,
  confirmationHandleClose,
  confirmationSubmitFunc,
  confirmationMsg,
  confirmationButtonMsg,
  confirmationLabel,
}) {
  return (
    <div>
      <Modal
        open={confirmationOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            borderRadius: "0.5rem",
            boxShadow: 24,
            px: 4,
            pb: 4,
          }}
        >
          <p className="mt-6 tracking-wide font-medium  text-lg ">
            {confirmationLabel}
          </p>
          <div className=" justify-center align-middle">
            <p className="my-1 tracking-wide text-lg">{confirmationMsg}</p>
            <div className="mt-3 flex  justify-center space-x-4">
              <CommonButton
                type="button"
                label="Cancel"
                onClick={confirmationHandleClose}
                className="border border-customRed text-customRed"
              />
              <CommonButton
                type="button"
                label={confirmationButtonMsg}
                onClick={confirmationSubmitFunc}
                className="bg-customGreen text-white"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
