// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// export const UseCreatePdfBase64 = (pdfContent) => {
//   return html2canvas(pdfContent).then((canvas) => {
//     const imageData = canvas.toDataURL("image/png");
//     const pdfDoc = new jsPDF("p", "mm", "a6", true);
//     pdfDoc.addImage(imageData, "PNG", 10, 10, 190, 0);
//     return pdfDoc.output("datauristring");
//   });
// };

// if (index < pdfContent.length - 1) {
//   pdfDoc.addPage(); // Add a new page for the next element
// }

import React from "react";
import html2pdf from "html2pdf.js";

export const UseCreatePdfBase64 = (content, format = "a4") => {
  // Adjust the font size and other styles as needed
  var style = `
       <style>
           .printBody {
               font-size: 6px; /* Adjust the font size */
           }
           /* Add any other styling adjustments as needed */
       </style>
   `;

  // Combine the styling with the original content
  var contentWithStyle = style + content.outerHTML;
  const options = {
    filename: "my-document.pdf",
    margin: [5, 0, 5, 0],
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: format || "a4", orientation: "portrait" },
  };
  return html2pdf()
    .set(options)
    .from(contentWithStyle)
    .toPdf()
    .get("pdf")
    .then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(1);
        pdf.setTextColor(100);
        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const pageNumberText = `Page ${i} of ${totalPages}`;
        pdf.text(pageNumberText, pageWidth - 10, pageHeight - 2);
      }
    })
    .output("datauristring");
};

