import { Icon, Tooltip } from "@mui/material";
import BedRelease from "../../ipd/components/bedallocation/assets/bed.svg";
import AllowSlipIcon from "./AllowtoGoToSlipIcon.svg";
import AllowSlipIcondiabled from "./AllowtoGoToSlipIcondiabled.svg";
import Barcode from "./Barcode.svg";
import BloodAcceptanceOff from "./BloodAcceptanceOff.svg";
import BloodAcceptanceOn from "./BloodAcceptanceOn.svg";
import cancelOff from "./CancelOff.svg";
import cancelOn from "./CancelOn.svg";
import etuCsOff from "./EtuCSOff.svg";
import etuCsOn from "./EtuCSOn.svg";
import eyeOff from "./EyeOff.svg";
import eyeOn from "./EyeOn.svg";
import IPDIconBedImg from "./IPDIconBedImg.svg";
import issue from "./Issue.svg";
import opdTreatRecordOff from "./OPDTreatRecordOff.svg";
import opdTreatRecordOn from "./OPDTreatRecordOn.svg";
import PrintOn from "./PrintOn.svg";
import reserveBed from "./ReserveBed.svg";
import transferToWardOff from "./TransferToWardOff.svg";
import transferToWardOn from "./TransferToWardOn.svg";
import unReserveBed from "./UnReserveBed.svg";
import bedTransferI from "./bedTransferI.svg";
import chargesAsPerI from "./charges as per.svg";
import complaintStatusOff from "./complaintStatusOff.svg";
import complaintStatusOn from "./complaintStatusOn.svg";
import deleteOff from "./deleteOff.svg";
import deleteOn from "./deleteOn.svg";
import dischargeI from "./dischargeI.svg";
import editOn from "./editOn.svg";
import holdOff from "./holdOff.svg";
import holdOn from "./holdOn.svg";
//ipd
import receivePtOff from "../admissionAssets/Receive Disable.svg";
import receivePtOn from "../admissionAssets/Receive.svg";
import BloodIssueNote from "../icons/Blood Issue Note.svg";
import BloodRequisition from "../icons/Blood Requisition.svg";
import DisablePrint from "../icons/DisablePrintIcon.svg";
import DischargeSummary from "../icons/DischargeSummery.svg";
import DivaTool from "../icons/DivaTool.svg";
import PatientTransfer from "../icons/Patient Transfer.svg";
import PostFumigation from "../icons/PostFumigation.svg";
import PushTool from "../icons/PushTool.svg";
import ScheduleDisable from "../icons/ScheduleDisableIcon.svg";
import ScheduleEnable from "../icons/ScheduleEnableIcon.svg";
import WhatsAppDisable from "../icons/WhatsAppDisableIcon.svg";
import WhatsAppEnable from "../icons/WhatsAppEnableIcon.svg";
import preFumigation from "../icons/preFumigation.svg";
import AddFilledOutine from "./AddFilledOutine.svg";
import AddOutline from "./AddOutline.svg";
import AssignOff from "./AssignOff.svg";
import AssignOn from "./AssignOn.svg";
import CarePlan from "./CarePlan.svg";
import Close from "./Close.svg";
import DrugAdmChart from "./DrugAdmChart.svg";
import DrugOrder from "./DrugOrder.svg";
import narcoticDrug from "./narcoticDrug.svg";
import vitalsRadar from "./vitalRadarIcon.svg";
import bedAllow from "./bed.svg";
import pendingAntibiotic from "./Pending Antibiotic.svg";
import consent from "./Consent.svg";
import initiateDischarge from "./initiateDischarge.svg";
import DispatchOn from "./dispatchOn.svg";
import DispatchOff from "./dispatchOff.svg";
import ventilatorBundle from "./ventilatorBundle.svg";
import notes from "./notes.svg";
import anesthesia_Notes from "./anesthesia Notes.svg";
///

// export const CreateIcon = ({ src, onClick,sx ,height,width title }) => (
//   <Tooltip title={title}>
//     <Icon sx={sx}>
//       <img src={src} height={height} width={width} onClick={onClick} />
//     </Icon>
//   </Tooltip>
// );

//////////////bed image view action icons
// IpdIcon
export const IPDIconBed = () => (
  <Icon>
    <img src={IPDIconBedImg} height={20} width={20} />
  </Icon>
);

// DischargeI
export const DischargeI = () => (
  <Icon>
    <img src={dischargeI} height={20} width={20} />
  </Icon>
);

// DischargeI
export const ChargesAsPerI = () => (
  <Icon>
    <img src={chargesAsPerI} height={22} width={22} />
  </Icon>
);

// IpdIcon
export const BedReleaseBed = () => (
  <Icon>
    <img src={BedRelease} height={20} width={20} />
  </Icon>
);

// reserve bed Icon

export const BedTransferBed = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={bedTransferI} onClick={onClick} height={20} width={20} />
    </Icon>
  </Tooltip>
);

// reserve bed Icon

export const ReserveBedIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={reserveBed} onClick={onClick} />
    </Icon>
  </Tooltip>
);
// un-reserve bed Icon
export const UnReserveBedIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={unReserveBed} onClick={onClick} />
    </Icon>
  </Tooltip>
);

/////////////////////for emergency listing///

export const CancelOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={cancelOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const CancelOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={cancelOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const EtuCsOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={etuCsOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const EtuCsOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={etuCsOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const OpdTreatRecordOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={opdTreatRecordOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const OpdTreatRecordOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={opdTreatRecordOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const TransferToWardOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={transferToWardOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const TransferToWardOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={transferToWardOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

////////////common///
export const EyeOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ paddingTop: "5px" }}>
      <img src={eyeOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const EditOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={editOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const EyeOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ paddingTop: "5px" }}>
      <img src={eyeOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);
////admission

export const ReceivePtOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ paddingTop: "5px" }}>
      <img src={receivePtOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const ReceivePtOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ paddingTop: "5px" }}>
      <img src={receivePtOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

//barcode icon
export const BarcodeIcon = ({ onClick }) => (
  <Icon>
    <img src={Barcode} height={30} width={30} onClick={onClick} />
  </Icon>
);

export const BarcodeOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon
     //sx={{ marginTop: "5px" }}
     >
      <img src={Barcode} onClick={onClick} />
    </Icon>
  </Tooltip>
);

// issued items at OT
export const IssueIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={issue} height={30} width={30} onClick={onClick} />
    </Icon>
  </Tooltip>
);

////blood bank related
export const BloodAcceptanceOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={BloodAcceptanceOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const BloodAcceptanceOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={BloodAcceptanceOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const PrintOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={PrintOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DeleteOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={deleteOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DeleteOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={deleteOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);
////Ot permission related

export const HoldOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={holdOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const HoldOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={holdOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AllowGoSlipIconEnabled = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={AllowSlipIcon} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AllowGoSlipIconDisabled = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={AllowSlipIcondiabled} onClick={onClick} />
    </Icon>
  </Tooltip>
);

///complaint management

export const ComplaintStatusOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={complaintStatusOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const ComplaintStatusOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={complaintStatusOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const WhatsAppEnableIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={WhatsAppEnable} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const WhatsAppDisableIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={WhatsAppDisable} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const ScheduleEnableIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={ScheduleEnable} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const ScheduleDisableIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={ScheduleDisable} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DisablePrintIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DisablePrint} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AssignOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={AssignOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AssignOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={AssignOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

///fumigation Chart
export const PostFumigationOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={PostFumigation} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const PreFumigationOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon sx={{ marginTop: "5px" }}>
      <img src={preFumigation} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const CarePlanIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={CarePlan} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const PushToolIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={PushTool} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DivaToolIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DivaTool} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DischargeSummaryIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DischargeSummary} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const PatientTransferIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={PatientTransfer} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const BloodRequisitionIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={BloodRequisition} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const BloodIssueNoteIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={BloodIssueNote} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DrugOrderIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DrugOrder} onClick={onClick} />
    </Icon>
  </Tooltip>
);
export const DrugAdmChartIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DrugAdmChart} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AddOutlineIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={AddOutline} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AddFilledIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={AddFilledOutine} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const CloseIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={Close} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const NarcoticDrugIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={narcoticDrug} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const VitalsRadarIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={vitalsRadar} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const BedAllowIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={bedAllow} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const PendingAntibioticIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={pendingAntibiotic} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const ConsentIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={consent} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const InitiateDischargeIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={initiateDischarge} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DispatchOnIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DispatchOn} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const DispatchOffIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={DispatchOff} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const VentilatorBundleIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={ventilatorBundle} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const NotesIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={notes} onClick={onClick} />
    </Icon>
  </Tooltip>
);

export const AnesthesiaNotesIcon = ({ title, onClick }) => (
  <Tooltip title={title}>
    <Icon>
      <img src={anesthesia_Notes} onClick={onClick} />
    </Icon>
  </Tooltip>
);
