import apiClient from "../../../../http-common";

//company autocomplete
export const getCompanyOptions = () => {
  return apiClient.get(`/misc/company`);
};

//get list of pending bills for pharmacy company settlement
export const getCompanyPendingBills = (searchObj) => {
  return apiClient.post(
    `/pharmacySettlement/compSettlementPendingBillList`,
    searchObj
  );
};

//save pharmacy company settlement
export const savePharmaCompanySettlement = (postObject) => {
  return apiClient.post(
    `/pharmacySettlement/saveCompanySettlement`,
    postObject
  );
};

//get settled company bills list
export const getSettledCompanyBills = (searchObj) => {
  return apiClient.post(`/companySettlement/previousReceiptList`, searchObj);
};
