const blobToBase64 = async (response) => {
  if (!(response.data instanceof Blob)) {
    throw new Error("Expected parameter to be a Blob");
  }

  const reader = new FileReader();
  reader.readAsDataURL(response.data);

  await new Promise((resolve, reject) => {
    reader.onloadend = () => {
      let base64String = reader.result;
      base64String = base64String.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });

  return reader.result.split(",")[1];
};

export { blobToBase64 };
