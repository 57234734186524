// import React from 'react'

import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchBedCategory,
  fetchChargesPerBedCategory,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { changeChargesPerBedCategory } from "../../services/bedAllowcationServices/BedAllowcationServices";

export const Schema = yup.object().shape({
  BedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  ChargesPerBedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),
});

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "40%", lg: "25%" },
  // minWidth:"25%",
  // height: "30%",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function ChargesAsPerModal(props) {
  const {
    open,
    handleClose,
    selectedRow,
    menuId,
    privilege,
    getBedListData,
    AdmissionId,
    bedDetails,
  } = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [bedCategory, setBedCategory] = useState();
  //   const [selectedBedCategory, setSelectedBedCategory] = useState(0);
  const [chargesPerBedCategory, setChargesPerBedCategory] = useState();

  //
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
    defaultValues: {
      BedCategory: null,
      ChargesPerBedCategory: null,
      ChargesPerRemark: "",
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    getBedCategory();
    getChargesPerBedCategory();
  }, []);

  const getBedCategory = () => {
    fetchBedCategory()
      .then((response) => response.data)
      .then((res) => {
        //
        setBedCategory(res.result);
      });
  };

  const getChargesPerBedCategory = () => {
    fetchChargesPerBedCategory()
      .then((response) => response.data)
      .then((res) => {
        setChargesPerBedCategory(res.result);
      });
  };

  useEffect(() => {
    setValue("BedCategory", {
      id: bedDetails?.CategoryId,
      label: bedDetails?.BedCategory,
      value: bedDetails?.BedCategory,
    });
    setValue("ChargesPerBedCategory", {
      id: bedDetails?.ChargesAsPerBedCategoryId,
      label: bedDetails?.chargesAsPerCategory,
      value: bedDetails?.chargesAsPerCategory,
    });
  }, [bedDetails]);

  const onSubmit = (data) => {
    let selectedObj = {
      admissionId: AdmissionId,
      bedCategoryId: data.BedCategory.id,
      chargesPerBedCategoryId: data.ChargesPerBedCategory.id,
      remark: data.ChargesPerRemark,
      menuId: menuId,
      privilege: privilege,
      //
    };

    changeChargesPerBedCategory(selectedObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        handleClose();
        getBedListData();
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res.message);
        setOpenBackdrop(false);
        handleClose();
      });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                Charges As Per
              </div>

              <hr className="border mx-1  border-blue-900" />
              <div className="text-sm grid grid-cols-1 xl:grid-cols-2 mt-2 mb-4 ">
                <div className="flex col-span-1 xl:col-span-2">
                  <span className="w-[95px] text-[#0b83a5]">Patient Name</span>
                  <span className="text-[#0b83a5]">&nbsp;:&nbsp;</span>
                  <span className="text-black">
                    {bedDetails?.ptInfo && bedDetails?.ptInfo["Patient Name"]}
                  </span>
                </div>
                <div className="flex ">
                  <span className="w-[95px] text-[#0b83a5]">MR No.</span>
                  <span className="text-[#0b83a5]">&nbsp;:&nbsp;</span>
                  <span className="text-black">
                    {bedDetails?.ptInfo && bedDetails?.ptInfo?.MRNo}
                  </span>
                </div>
                <div className="flex">
                  <span className="w-[95px] xl:w-[65px] text-[#0b83a5]">
                    Bed No.
                  </span>
                  <span className="text-[#0b83a5]">&nbsp;:&nbsp;</span>
                  <span className="text-black">{bedDetails?.BedNo}</span>
                </div>
              </div>
              <div className=" gap-3 mt-3 grid grid-cols-1">
                {/* //Bed Category*/}
                <div className="">
                  <DropdownField
                    control={control}
                    error={errors.BedCategory}
                    name="BedCategory"
                    label="Bed Category"
                    dataArray={bedCategory}
                    isSearchable={false}
                    isDisabled={true}
                    placeholder="Bed Category *"
                  />
                </div>
                {/* //charges Bed Category// */}
                <div className="md:mb-3 lg:mb-0 grid grid-cols-1">
                  <DropdownField
                    control={control}
                    error={errors.ChargesPerBedCategory}
                    name="ChargesPerBedCategory"
                    label="ChargesPerBedCategory"
                    dataArray={chargesPerBedCategory}
                    isSearchable={false}
                    isDisabled={false}
                    placeholder="Charges Per Bed Category *"
                  />
                </div>
                {/* //charges Bed Category// */}
                <div className="md:mb-3 lg:mb-0 grid grid-cols-1">
                  <InputField
                    control={control}
                    name="ChargesPerRemark"
                    label="Remark"
                  />
                </div>
              </div>
              <div className="flex justify-end my-2 gap-3">
                <CommonButton
                  label="No"
                  onClick={() => {
                    handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  label="Yes"
                  className="bg-customGreen text-white"
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
}
