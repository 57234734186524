import * as React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";

import { Tooltip } from "@mui/material";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { errorAlertCustom } from "../../../../common/components/Toasts/CustomToasts";

export default function ConsumedItemListTable(props) {

  //allHeaders is an object which contains the title of all the columns of the table
  let allHeaders = null;

  let newHeaders = [];

  if (props.dataArray.length > 0) {
    //allHeaders is an object which contains the title of all the columns of the table
    allHeaders = Object.keys(props.dataArray[0]);


    newHeaders.push("Action");

    for (let headerName of allHeaders) {
      if (
        headerName !== "correspondingChargeIdValue" &&
        headerName !== "BatchesRequired"
      ) {
        newHeaders.push(headerName);
      }
    }

    allHeaders = newHeaders;
  }


  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
          {/* Display the table ; if and only if the dataArray's length is greater than 0 */}
          {props.dataArray.length > 0 ? (
            <Paper sx={{ width: "100%", my: 1, height: "100%" }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-48 2xl:h-60"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          // paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      {allHeaders &&
                        allHeaders.map((singleHeader) => (
                          <TableCell
                            // style={{ width: 160 }}
                            // align="left"
                            key={singleHeader}
                          >
                            <span className="text-gray-600  whitespace-nowrap">
                              {singleHeader}
                            </span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {props.dataArray.map((singleDataObj) => {
                     
                      return (
                        <>
                          <TableRow>
                            {allHeaders &&
                              allHeaders.map((header) =>
                                header === "Action" ? (
                                  <TableCell key={header}>
                                    <div className="flex">
                                      {
                                        <Tooltip title="Delete">
                                          <DeleteOutlineOutlinedIcon
                                            className="text-red-700 mr-2 cursor-pointer"
                                            onClick={() => {
                                             

                                              props.deleteFromConsumedItemListTable(
                                                singleDataObj
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      }

                                      {
                                        <Tooltip title="Edit">
                                          <DriveFileRenameOutlineIcon
                                            className="text-blue-900 mr-2 cursor-pointer"
                                            onClick={() => {
                                             

                                              let batchesRequiredFlagVal =
                                                !singleDataObj.BatchesRequired;

                                              props.setBatchesRequiredFlag(
                                                batchesRequiredFlagVal
                                              );

                                              props.setEditableDrugObj(
                                                singleDataObj
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      }
                                    </div>
                                  </TableCell>
                                ) : (
                                  <TableCell key={header}>
                                    <span className="text-gray-600  whitespace-nowrap">
                                      {singleDataObj[header]}
                                    </span>
                                  </TableCell>
                                )
                              )}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Paper sx={{ width: "100%", my: 1, height: "100%" }}>
              <div>
                <TableContainer
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: 7,
                      height: 7,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#7393B3",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "lightBlue",
                    },
                  }}
                  className="rounded h-48 2xl:h-60"
                >
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            // paddingY: 0.5,
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      ></TableRow>
                    </TableHead>

                    <TableBody>
                      <div className="row">
                        <div className="grid justify-center text-black mt-20">
                          Data Not Available.
                        </div>
                      </div>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          )}
        </Box>

        <div className="flex justify-end mt-6">
          <div>
            {props.userActions &&
              props.userActions.map((singleActionObj) => {
                if (
                  singleActionObj.isAction === false &&
                  singleActionObj.action === "Create"
                ) {
                  return (
                    <>
                      <CommonButton
                        label="Save"
                        className="bg-customGreen text-white"
                        onClick={() => {
                          if (props.dataArray.length > 0) {
                            props.setConfirmationOpen(true);
                          } else {
                            errorAlertCustom("Please select item.");
                          }
                        }}
                      />
                    </>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
}
