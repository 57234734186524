import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//Patient AutoComplete
export const fetchPatientDocuments = (patientId) => {
  return apiClient.get(`/documentManagement/getPatientDocuments/${patientId}`, {
    headers: authHeader(),
  });
};

//Patient AutoComplete
export const uploadPtDocument = (postObj) => {
  return apiClient.post(
    `/documentManagement/uploadDocument`,
    postObj,

    {
      headers: authHeader(),
    }
  );
};

//Patient AutoComplete
export const savePatientDocuments = (postObj) => {
  return apiClient.post(
    `/documentManagement/savePatientDocuments`,
    postObj,

    {
      headers: authHeader(),
    }
  );
};

//
export const getDocumentByPath = (path1, path2, path3, path4, fileName) => {
  return apiClient.get(
    `/getDocumentByPath/${path1}/${path2}/${path3}/${path4}/${fileName}`,

    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
};
