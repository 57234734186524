import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PatientEngagementContext } from "./PatientEngagement";

export default function CommonTable(props) {
  

  const { getPatientType } = React.useContext(PatientEngagementContext);
 
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "lightblue",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1E3A8A",
                },
              }}
              className="rounded h-[200px] lg:h-[450px]"
            >
            
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                    className="whitespace-nowrap"
                  >
                    <TableCell>Patient Name</TableCell>
                    <TableCell>MR No.</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.result &&
                    props.data.result.map((row, index) => (
                      <TableRow
                        key={index.id}
                        tabIndex={-1}
                        style={{
                          backgroundColor:
                            props.selectedID === index ? "#FFC44B" : "",
                          height: props.selectedID === index ? "32px" : "",
                        }}
                        sx={{
                          "& td": {
                            paddingY: 0.4,
                          },
                        }}
                        onClick={(event) => {
                          props.setOpdCoordinator(row);
                          props.setPreDetailsId(row.PatientId);

                          if (getPatientType === "Opd") {
                            props.setPatientVisitId(row.visitId);
                          } else {
                            props.setPatientVisitId(row.admissionId);
                          }
                          props.setSelectedID(index);

                          
                        }}
                        // sx={{
                        //   "&.Mui-selected": {
                        //     backgroundColor: "#2e8b57",
                        //   },
                        //   ":focus": {
                        //     backgroundColor: "#bbdefb",
                        //   },
                        // }}
                      >
                        <TableCell className="whitespace-nowrap">
                          {row.PatientName}
                        </TableCell>
                        <TableCell>{row.MRNo}</TableCell>
                        <TableCell>{row.Gender}</TableCell>
                        <TableCell className="whitespace-nowrap">{row.AgeYear}</TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.Date}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.Address}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
