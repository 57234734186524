import { format, isAfter } from "date-fns";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ComplaintStatusOnIcon } from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { fetchDepartment } from "../../../commonServices/miscellaneousServices/MiscServices";
import { fetchComplaintList } from "../../services/complaintManagementServices/ComplaintManagementServices";
import AddNewComplaintModal from "./AddNewComplaintModal";
import ComplaintStatusModal from "./ComplaintStatusModal";
import { fetchCashCounterData } from "../../../login/services/LoginServices.js";
import DropdownField from "../../../common/components/FormFields/DropdownField";

const complaintStatusArr = [
  { id: 0, label: "All", value: "All" },
  { id: 1, label: "Pending", value: "Pending" },
  { id: 2, label: "Completed", value: "Completed" },
];

const complaintType = [
  { id: 0, label: "All", value: "All" },
  { id: 1, label: "In Complaints", value: "In Complaints" },
  { id: 2, label: "Out Complaints", value: "Out Complaints" },
];
// const complaintStatusArr = [
//   { id: 0, label: "All", value: "Advanced" },
//   { id: 1, label: "Pending", value: "Pending" },
//   { id: 2, label: "Ongoing", value: "Ongoing" },
//   { id: 3, label: "Closed", value: "Closed" },
// ];

const ComplaintManagement = (props) => {
  const location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const methods = useForm({
    mode: "onChange",
    //resolver: yupResolver(validationSchema),
    defaultValues: {
      toDate: new Date(),
      fromDate: new Date(),
      complaintType: 0,
      complaintStatus: 0,
    },
  });

  const { control, register, watch } = methods;

  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");

  ///related to table

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  //
  const [departments, setDepartments] = useState([]);
  const [selectedFromDept, setSelectedFromDept] = useState(null);
  const [selectedToDept, setSelectedToDept] = useState(null);
  // Actions Confirmation
  const [openNewComplaintModal, setOpenNewComplaintModal] = useState(false);
  const handleOpenNewComplaintModal = () => setOpenNewComplaintModal(true);
  const handleCloseNewComplaintModal = () => setOpenNewComplaintModal(false);

  const [openComplaintStatusModal, setOpenComplaintStatusModal] = useState(
    false
  );
  const handleOpenComplaintStatusModal = () =>
    setOpenComplaintStatusModal(true);
  const handleCloseComplaintStatusModal = () =>
    setOpenComplaintStatusModal(false);

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate", new Date());
  let complaint_Status = watch("complaintStatus");
  let complaint_Type = watch("complaintType");

  //

  // ;

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [
    FromDate,
    ToDate,
    selectedFromDept,
    selectedToDept,
    complaint_Status,
    complaint_Type,
  ]);

  const populateTable = (forPagination) => {
    const listObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      complaintType: Number(complaint_Type),
      fromDepartmentId: selectedFromDept?.id || null,
      status: Number(complaint_Status),
      //toDepartmentId: token?.departmentId, //selectedToDept?.id,
      toDepartmentId: token?.cashCounterId, //selectedToDept?.id,
      menuId: props.menuId || location?.state?.menuId,
      privilege: "",
    };

    fetchComplaintList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
        setUserActions(res.actions);
      });
  };

  const getDeprtments = (searchString) => {
    // searchString &&
    //   fetchDepartment(searchString)
    fetchCashCounterData(token?.loginName, token?.unitId)
      .then((response) => response.data)
      .then((res) => {
        setDepartments(res.result);
      });
  };

  useEffect(() => {
    getDeprtments();
  }, []);

  ////////table related
  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction && actions.action === "Status" ? (
                <>
                  <div className="">
                    <ComplaintStatusOnIcon
                      onClick={() => {
                        handleOpenComplaintStatusModal();
                        setPrivilege(actions.action);
                        setSelectedRow(row);
                      }}
                      title="Complaint Status"
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="mt-12">
      <div className="grid  items-center">
        <div className="text-center text-black font-bold text-xl mb-3 ">
          Complaint Management
        </div>
      </div>
      <div className="grid lg:grid-cols-7 md:grid-cols-2 mb-3 gap-3">
        <div className="col-span-2">
          <DropdownField
            control={control}
            placeholder="From Department"
            dataArray={departments}
            name="fromDepartment"
            searchIcon={true}
            isClearable={true}
            menuPlacement={"bottom"}
            handleInputChange={getDeprtments}
            inputRef={{
              ...register("fromDepartment", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedFromDept(e.target.value);
                  } else {
                    setSelectedFromDept(null);
                    methods.reset();
                  }
                },
              }),
            }}
          />
        </div>
        {/*<div className="">
          <DropdownField
            control={control}
            // error={errors.MLCType}
            name="toDepartment"
            placeholder="To Department"
            dataArray={departments}
            isSearchable={false}
            inputRef={{
              ...register("toDepartment", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedToDept(e.target.value);
                  } else {
                    setSelectedToDept(null);
                    methods.reset();
                  }
                },
              }),
            }}
          />
          </div>*/}
        {/* from Date */}
        <div className="">
          <DatePickerFieldNew
            control={control}
            name="fromDate"
            label="From Date"
            value={new Date()}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        {/* from Date */}
        <div className="">
          <DatePickerFieldNew
            control={control}
            name="toDate"
            label="To Date"
            value={new Date()}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        <div className="flex lg:col-span-3 md:col-span-2 gap-3 items-center">
          <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
            Complaint Status :
          </p>
          <div className="mt-1">
            <RadioField
              label=""
              name="complaintStatus"
              control={control}
              dataArray={complaintStatusArr}
            />
          </div>
        </div>
        <div className="flex lg:col-span-3 md:col-span-2 gap-3 items-center">
          <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
            Complaint Type :
          </p>
          <div className="mt-1">
            <RadioField
              label=""
              name="complaintType"
              control={control}
              dataArray={complaintType}
            />
          </div>
        </div>
        <div className="flex md:col-span-2 lg:col-span-4 justify-end">
          {userActions.map((actions) => (
            <>
              {!actions.isAction && actions.action === "Create" ? (
                <>
                  <div className="">
                    <CommonButton
                      label="Add New Complaint"
                      className="bg-customBlue text-white"
                      onClick={() => {
                        handleOpenNewComplaintModal();
                        setPrivilege(actions.action);
                      }}
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </div>

      <div className="my-2">
        {dataResult.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            populateTable={populateTable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            count={count}
            tableClass="lg:h-[350px] 2xl:h-[550px]"
            removeHeaders={[
              "ID",
              "Code Type",
              "timeForPatch",
              "departmentid",
              "Complaint Type",
              "ToDepartmentId",
              "IsEquipmentComplaint",
              "IsComplete",
              "Photo",
              "FromDepartmentId",
              "FromDeptComplaintStatus",
              "ToDeptComplaintStatus",
              "Compl Type",
              "REC No",
            ]}
            renderActions={renderActions}
            highlightRow={false}
          />
        ) : (
          <p className="text-center my-32 ">No Data Found</p>
        )}
      </div>

      {openNewComplaintModal ? (
        <AddNewComplaintModal
          open={openNewComplaintModal}
          setOpen={setOpenNewComplaintModal}
          handleOpen={handleOpenNewComplaintModal}
          handleClose={handleCloseNewComplaintModal}
          privilege={privilege}
          menuId={props.menuId || location?.state?.menuId}
          populateTable={populateTable}
        />
      ) : null}

      {openComplaintStatusModal ? (
        <ComplaintStatusModal
          open={openComplaintStatusModal}
          setOpen={setOpenComplaintStatusModal}
          handleOpen={handleOpenComplaintStatusModal}
          handleClose={handleCloseComplaintStatusModal}
          privilege={privilege}
          menuId={props.menuId || location?.state?.menuId}
          selectedRow={selectedRow}
          populateTable={populateTable}
        />
      ) : null}
    </div>
  );
};

export default ComplaintManagement;
