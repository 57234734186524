import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { getPatientInfoOpdIpdAutocomplete } from "../../../../commonServices/patientInfoServices/PatientInfoServices";
import {
  fetchPatientTransferList,
  fetchPrintPatientTransfer,
} from "../../../services/patientTransfer/PatientTransferServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "74%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

let showSpinner = true;

export default function PatientTransferListing(props) {
  // var token = JSON.parse(localStorage.getItem("userInfo"));
  let location = useLocation();

  const { open, handleClose, handleEditData, userActions } = props;

  //dates
  const [options, setOptions] = React.useState([]);
  const [patient, setPatient] = React.useState(0);
  //
  const [dataResult, setDataResult] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  const { control, register, watch } = methods;

  //watch
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  //handleChange
  const handleChangeSearch = (searchString) => {
    if (searchString !== undefined && searchString !== "") {
      getPatientInfoOpdIpdAutocomplete(searchString, 1)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, patient]);

  const populateTable = (forPagination) => {
    // if (forPagination) {
    //   showSpinner = true;
    // }

    showSpinner = true;

    var listObj = {
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      //
      page: !forPagination ? 0 : page,
      admissionId: props?.AdmissionId
        ? props?.AdmissionId
        : patient?.AdmissionId !== null
        ? patient?.AdmissionId
        : 0,
      size: rowsPerPage,
      menuId: location?.state?.menuId,
    };

    fetchPatientTransferList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        // setUserActions(res.actions);
        setCount(res.count);
        showSpinner = false;
      });
  };

  const handleOpenPrintModal = (row) => {
    // print service
    fetchPrintPatientTransfer(row.PTID).then((response) => {
      if (response) {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      }
    });
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction && (
              <>
                <div className="flex gap-1">
                  {actions.action === "Edit" && (
                    <div>
                      <Tooltip title={"Edit Patient Transfer Form"}>
                        <DriveFileRenameOutlineIcon
                          sx={{
                            color: "#1e3a8a",
                          }}
                          onClick={() => {
                            props.handleEditData(row);
                            handleClose();
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {actions.action === "Print" && (
                    <div>
                      <Tooltip title={"Print Patient Transfer Form"}>
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            handleOpenPrintModal(row);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />

          <div className={"text-lg font-semibold pt-3"}>
            View Patient Transfer
          </div>

          <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center mt-3">
            <div className="grid md:grid-cols-4 lg:grid-cols-2 md:col-span-2 lg:col-span-3 gap-3">
              {/* from Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              {/* to Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
            </div>
            <div className="lg:col-span-4 z-50">
              <SearchDropdown
                control={control}
                placeholder="Search By Patient Name / MR.No./Mob No."
                dataArray={options}
                name="patientTransferListSearch"
                searchIcon={true}
                isClearable={true}
                handleInputChange={handleChangeSearch}
                isDisabled={props?.AdmissionId ? true : false}
                inputRef={{
                  ...register("patientTransferListSearch", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        setPatient(e.target.value);
                      } else {
                        setPatient(null);
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>

          {showSpinner ? (
            <div className="flex justify-center mt-14">
              <LoadingSpinner />
            </div>
          ) : dataResult && dataResult.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              renderActions={renderActions}
              highlightRow={false}
              // handleSelectedRow={handleSelectedRow}
              tableClass={"rounded lg:h-52 md:h-72 xl:h-80"}
              removeHeaders={["PTID"]}
            />
          ) : (
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
}
