import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import CommonButton from "../Buttons/CommonButton";
import { successAlert } from "../Toasts/CustomToasts";

// Define validation schema
const schema = yup.object().shape({
  numberOfCopies: yup
    .number()
    .required("Number of copies is required")
    .min(1, "Number of copies must be at least 1"),
});

const NumberOfCopiesModal = (props) => {
  const { open, onClose, handleSave } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: { numberOfCopies: 1 },
    resolver: yupResolver(schema),
  });

  const numberOfCopies = watch("numberOfCopies");

  const handleIncrement = () => {
    setValue("numberOfCopies", numberOfCopies + 1);
  };

  const handleDecrement = () => {
    if (numberOfCopies > 1) {
      setValue("numberOfCopies", numberOfCopies - 1);
    }
  };

  const onSubmit = (data) => {
    console.log("Submitted data:", data);
    handleSave(data);
    successAlert("Print Sent To Printer Successfully !");
    onClose();
  };

  return (
    <div>
      {/* Modal */}
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            p: 2,
            "&:focus": {
              outline: "none",
            },
          }}
          className="bg-white  rounded-lg shadow-lg"
        >
          <h2 className="text-lg font-semibold ">Number Of Copies</h2>
          <hr className="border-t border-blue-900 my-2" />
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex items-center justify-center space-x-4">
              <button
                type="button"
                onClick={handleDecrement}
                className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full hover:bg-gray-300 transition"
              >
                <RemoveIcon />
              </button>
              <Controller
                name="numberOfCopies"
                control={control}
                render={({ field }) => (
                  <span className="text-2xl font-bold">{field.value}</span>
                )}
              />
              <button
                type="button"
                onClick={handleIncrement}
                className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full hover:bg-gray-300 transition"
              >
                <AddIcon />
              </button>
            </div>
            <div className="flex justify-center space-x-3">
              <CommonButton
                label={"Cancel"}
                className="mt-4 border border-customRed text-customRed"
                onClick={() => {
                  onClose();
                }}
              />
              <CommonButton
                type="submit"
                label={"Proceed"}
                className="mt-4 bg-customGreen text-white"
              />
            </div>
          </form>
        </Box>
      </Modal>

      {/* Backdrop */}
    </div>
  );
};

export default NumberOfCopiesModal;
