import React, { useEffect, useState } from "react";
import CommonButton from "../../common/components/Buttons/CommonButton";
import DepartmentWiseReport from "./departmentwisereports/DepartmentWiseReport";
import IPDWiseReport from "./ipdwisereports/IPDWiseReport";
import OPDWiseReports from "./opdwisereports/OPDWiseReports";
import TemplateWiseReport from "./templatewisereports/TemplateWiseReport";
import UserWiseReports from "./userwisereports/PatientWiseReport";
import PatientQuestionWiseReport from "./patientquestionwisereport/PatientQuestionWiseReport";
import CommonDivider from "../../common/components/divider/CommonDivider";

const reportsArr = [
  {
    id: 1,
    name: "Tempalate Wise Report",
    routerLink: "/reports/templatewisereport",
  },
  {
    id: 2,
    name: "Department Wise Report",
    routerLink: "/reports/departmentwisereport",
  },
  {
    id: 3,
    name: "OPD Wise Report",
    routerLink: "/reports/opdwisereport",
  },
  {
    id: 4,
    name: "IPD Wise Report",
    routerLink: "/reports/ipdwisereport",
  },
  {
    id: 5,
    name: "Question Wise Report",
    routerLink: "/reports/ipdwisereport",
  },
  {
    id: 6,
    name: "Patient Wise Report",
    routerLink: "/reports/patientwisereport",
  },
  {
    id: 7,
    name: "Patient Question Wise Report",
    routerLink: "/reports/patientquestionwisereport",
  },
];

const bgColorsForButton = [
  "#C4FFC3",
  "#F0FFC3",
  "#FEFFE0",
  "#FFE1CF",
  "#FFCBCB",
  "#EE9A49",
  "#ADD8E6",
];

function FeedBackReport() {
  const [selectedReport, setSelectedReport] = useState(null);


  useEffect(() => {
    const defaultReport = reportsArr.find(
      (report) => report.name === "Tempalate Wise Report"
    );

    if (defaultReport) {
      setSelectedReport(defaultReport);
    }
  }, []);

  return (
    <div className="pt-16">
      <h1 className="text-center  font-semibold text-lg">Feedback Reports</h1>
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-3 2xl:gap-0 2xl:flex 2xl:space-x-2 2xl:items-center my-2 2xl:w-[65%] rounded text-sm">
        {reportsArr.map((list, index) => {
          // const bgColor = getBackgroundColor(list.name);
          const isSelected = selectedReport === list;
          const bgColor = bgColorsForButton[index];
          return (
            <button
              key={index}
              type="button"
              className={`border h-9 px-3 w-max rounded text-base font-medium   ${
                selectedReport === list
                  ? "bg-customBlue text-white text-xs whitespace-nowrap"
                  : list.name === "Template Wise Report"
                  ? "bg-customBlue text-white text-xs whitespace-nowrap"
                  : ` bg-[${bgColor}] text-xs border-[${bgColor}] whitespace-nowrap`
              }`}
              style={{
                backgroundColor: isSelected ? "#073763" : bgColor,
                border: isSelected ? "#073763" : bgColor,

              }}
              onClick={() => {
                setSelectedReport(list);
              }}
            >
              {list.name}
            </button>
          );
        })}
      </div>
      
      <CommonDivider />

      {selectedReport?.name === "Tempalate Wise Report" && (
        <div className=" mt-4">
          <TemplateWiseReport />
        </div>
      )}
      {(selectedReport?.name === "Department Wise Report" ||
        selectedReport?.name === "Question Wise Report") && (
        <div className=" mt-4">
          <DepartmentWiseReport selectedReport={selectedReport} />
        </div>
      )}
      {selectedReport?.name === "OPD Wise Report" && (
        <div className=" mt-4">
          <OPDWiseReports />
        </div>
      )}
      {selectedReport?.name === "IPD Wise Report" && (
        <div className=" mt-4">
          <IPDWiseReport />
        </div>
      )}
      {selectedReport?.name === "Patient Wise Report" && (
        <div className=" mt-4">
          <UserWiseReports />
        </div>
      )}
      {selectedReport?.name === "Patient Question Wise Report" && (
        <div className=" mt-4">
          <PatientQuestionWiseReport />
        </div>
      )}
    </div>
  );
}

export default FeedBackReport;

