import React from "react";
import PrintIcon from '@mui/icons-material/Print';
function PrintButton({ onClick }) {
  return (
    <div>
      <button
        type="button"
        onClick={onClick}
        className="h-9 px-3 w-full  bg-[#4B5563] text-white rounded text-sm font-bold "
      >
      <PrintIcon />
      </button>
    </div>
  );
}

export default PrintButton;
