import React from "react";
export const UseCheckConsecutiveCharacters = (value) => {
  let numberExpression = /[0-9]/g;

  if (value.match(numberExpression) === null) {
    const inputValue = value;
    let hasThreeConsecutive = false;

    if (inputValue.length >= 3) {
      for (let i = 2; i < inputValue.length; i++) {
        if (
          inputValue[i] === inputValue[i - 1] &&
          inputValue[i] === inputValue[i - 2]
        ) {
          hasThreeConsecutive = true;
          break;
        }
      }
    }
    return !hasThreeConsecutive;
  } else {
    return true;
  }
};
