import { Box, Modal } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../common/components/Buttons/CommonButton";
import DatePickerFieldNew from "../common/components/FormFields/DatePickerFieldNew";

export default function ConfirmationModalDatepicker(props) {
  const {
    setdatareschedule,
    rowdata,
    confirmationOpen,
    confirmationHandleClose,
    callReschedule,
    datareschedule,
  } = props;

  const defaultValues = {
    dateandtime: new Date(),
  };

  const { control, watch, handleSubmit, register } = useForm({
    defaultValues,
  });

  // const handleSubmitdata = (data) => {
  //
  //   let obj = {
  //     postDischargeDetailsId: rowdata?.["post_discharge_details_id"],
  //     appointmentdate: format(
  //       data?.dateandtime,
  //       "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
  //     ),
  //   };
  //
  //   setdatareschedule(obj);
  // };
  return (
    <div>
      <Modal
        open={confirmationOpen}
        onClose={confirmationHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            borderRadius: "0.5rem",
            boxShadow: 24,
            px: 2,
            pb: 2,
          }}
        >
          <form>
            <p className=" font-semibold text-[18px] mt-3">Confirmation</p>
            <h1 className=" my-2 text-center  font-Poppins">
              Please Choose Call Reschedule Date ?
            </h1>
            <div className="my-3">
              <DatePickerFieldNew
                control={control}
                name="dateandtime"
                label="Date & Time"
                value={new Date()}
                inputFormat="dd-MM-yyyy"
                inputRef={{
                  ...register("dateandtime", {
                    onChange: (e) => {
                      setdatareschedule((datareschedule) => ({
                        ...datareschedule,
                        postDischargeDetailsId:
                          rowdata["post_discharge_details_id"],
                        rescheduleDate: e.target.value,
                      }));
                    },
                  }),
                }}
              />
            </div>

            <div className=" flex justify-center gap-3">
              <CommonButton
                label="Cancel"
                type="button"
                className="cancelButton border border-customRed text-customRed"
                onClick={confirmationHandleClose}
              />
              <CommonButton
                label="Confirm"
                type="button"
                className="h-9 px-2 text-sm font-medium  bg-customGreen text-white rounded "
                // onClick={callReschedule}
                onClick={() => {
                  // handleSubmitdata();
                  callReschedule(datareschedule);
                }}
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
