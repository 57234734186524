import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";


export const fetchPatientForDataMerge = (patientInfo) => {
    return apiClient.get(`/patientInfo/${patientInfo}`, {
      headers: authHeader(),
    });
  };


export const postPatientDataMerge = (createObj) => {
    return apiClient.post(`/administration/mergePatientDetails`, createObj, {
      headers: authHeader(),
    });
  };

  
  