import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

//shifts
export const fetchShifts = () => {
  return apiClient.get(`/doctorSlots/getShifts`, {
    headers: authHeader(),
  });
};

//weekDays
export const fetchWeekDays = () => {
  return apiClient.get(`/doctorSlots/getWeekDays`, {
    headers: authHeader(),
  });
};

//save Slots
export const saveSlotsForDoctor = (menuId, privilege, slotObj) => {
  return apiClient.post(
    `/doctorSlots/doctorSlots/${menuId}/${privilege}`,
    slotObj,
    {
      headers: authHeader(),
    }
  );
};

//save Slots
export const fetchExstingSlotData = (doctorId, unitId) => {
  return apiClient.get(
    `/doctorSlots/getDoctorSchedules/${doctorId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

//save Slots
export const fetchScheduledDoctorsSlotsList = (
  departmentId,
  doctorId,
  unitId
) => {
  return apiClient.get(
    `/doctorSlots/getScheduleDoctorSlotsList/${departmentId}/${doctorId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

//save Slots
export const deleteSlotData = (slotId) => {
  return apiClient.get(`/doctorSlots/deleteDoctorSlot/${slotId}`, {
    headers: authHeader(),
  });
};

export const fetchDoctorsByUnit = (unitId) => {
  return apiClient.get(`/misc/getDoctorByUnit/${unitId}`, {
    headers: authHeader(),
  });
};

export const fetchDepartmentOfDoctor = (doctorId) => {
  return apiClient.get(`/doctorSlots/getDepartmentOfDoctor/${doctorId}`, {
    headers: authHeader(),
  });
};
