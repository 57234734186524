import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const getDietOrderPatientList = (obj) => {
  return apiClient.post(`/diticianModule/dietOrderPatientList`, obj, {
    headers: authHeader(),
  });
};
export const fetchBarcodedietOrderInfo = (barcodeObj) => {
  return apiClient.post(`/barcode/dietOrderInfo`, barcodeObj,{
    responseType: "blob",
    headers: authHeader(),
  });
};

export const getDietOrderList = (admissionId) => {
  return apiClient.get(`/diticianModule/dietOrderList/${admissionId}`, {
    headers: authHeader(),
  });
};
