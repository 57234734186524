import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//other
export const saveCodeForm = (finalObj) => {
  return apiClient.post(
    `codeForm/saveCodeForm
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

//orange
export const saveCodeOrangeForm = (finalObj) => {
  return apiClient.post(
    `/codeForm/saveCodeOrangeForm
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

//yellow
export const saveCodeYellowForm = (finalObj) => {
  return apiClient.post(
    `/codeForm/saveCodeYellowForm
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

//list
export const fetchCodeFormList = (listObj) => {
  return apiClient.post(
    `/codeForm/getCodeFormList
    `,
    listObj,
    {
      headers: authHeader(),
    }
  );
};
