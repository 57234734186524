// /api/clinicalCareChart/patientList
import axios from "axios";
import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//autoComplete
export const fetchPatientListForStaffVsPatientRatio = (searchObj) => {
  return apiClient.post(`clinicalCareChart/patientList`, searchObj, {
    headers: authHeader(),
  });
};

export const fetchStaffAssignHistoryList = (admissionIds) => {
  return apiClient.get(
    `/staffVsPatientRation/assignedStaffList/${admissionIds}`,
    {
      headers: authHeader(),
    }
  );
};
//api/staffVsPatientRation/assignStaffToPatient

export const cancelAssigndStaff = (id) => {
  return apiClient.get(`/staffVsPatientRation/cancelAssignedStaff/${id}`, {
    headers: authHeader(),
  });
};

export const saveAssignStaff = async (assignStaff) => {
  return await apiClient.post(
    `/staffVsPatientRation/assignStaffToPatient`,
    assignStaff,
    {
      headers: authHeader(),
    }
  );
};

export const staffVsPatientRatio = (floorId) => {
  return apiClient.get(`/staffVsPatientRation/staffVsPatientRatio/${floorId}`, {
    headers: authHeader(),
  });
};