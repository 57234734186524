import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { searchPatientInformation } from "../../../../nursingStation/services/cliinicalChartServices/divatoolservices/DivatoolServices";
import {
  fetchDepartmentForConsent,
  fetchPatientInformationById,
} from "../../../../operationTheater/services/otConsentServices/OtConsentServices ";
import {
  addNewCertificate,
  fetchCertificateTemplate,
  getPreviousCertificateList,
} from "../services/certificateservice/CertificateServices";
// import CertificateTable from "./CertificateTable";
import JoditEditor from "jodit-react";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CertificatePrint from "../../../prints/CertificatePrint";

const Certificate = () => {
  const defaultValues = {
    patientSearch: null,
    patientType: 0,
    // departmentName: null,
    certificateTemplate: null,
    language: 0,
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    watch,
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let editor = useRef(null);
  let patientTypeVal = watch("patientType");
  let templete = watch("certificateTemplate");
  //let departmentNameVal = watch("departmentName");
  let patientSearchVal = watch("patientSearch");
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const config = useMemo(
    () => ({
      readonly: false,
      useSearch: false,
      spellcheck: false,
      enter: "P",
      defaultMode: "1",
      toolbarAdaptive: false,
      toolbarSticky: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      minHeight: 400,
      minWidth: null,
      buttons: [
        "bold",
        "underline",
        "strikethrough",
        "italic",
        "indent",
        "outdent",
        "image",
        "fontsize",
        "paragraph",
        "brush",
        "|",
        "align",
        "ul",
        "ol",
        "table",
        "hr",
        "symbol",
        "eraser",
        "copyformat",
        "superscript",
        "subscript",
        "undo",
        "redo",
        "find",
        "preview",
        "print",
      ],
      editorCssClass: "alic",
      placeHolder: "",
      controls: {
        fontsize: {
          list: [
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "16",
            "18",
            "24",
            "30",
            "36",
            "48",
            "60",
            "72",
            "96",
            "100",
          ],
        },
        font: {
          command: "fontname",
          list: {
            "": "Default",
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
          },
        },
      },
    }),
    []
  );

  const patientType = [
    {
      id: 0,
      value: "OPD",
      label: "OPD",
    },
    {
      id: 1,
      value: "IPD",
      label: "IPD",
    },
  ];

  const language = [
    {
      id: 0,
      value: "English",
      label: "English",
    },
  ];

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [templeteList, setTempleteList] = useState([]);
  const [selectedTblRow, setSelectedTblRow] = useState(null);
  const [options, setOptions] = React.useState([]);
  const [patientInfo, setPatientInfo] = React.useState(null);
  const [deptForConsent, setDeptForConsent] = useState();
  const [consentTemplete, setConsentTemplete] = useState();
  const [admissionId, setAdmissionId] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [content, setContent] = React.useState("");

  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const location = useLocation();
  //
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [certficateDtlsId, setCertficateDtlsId] = React.useState(null);

  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 500);

    return <div className="hidden">{component}</div>;
  };
  //

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    fetchCertificateTemplate()
      .then((response) => response.data)
      .then((res) => {
        setConsentTemplete(res.result);
      });
  }, []);

  function getPatientInfo(selectedPatient) {
    fetchPatientInformationById(selectedPatient?.id, patientTypeVal)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
      });

    fetchDepartmentForConsent()
      .then((response) => response.data)
      .then((res) => {
        setDeptForConsent(res.result);
      });
  }

  useEffect(() => {
    if (patientSearchVal !== null) {
      getPatientInfo(patientSearchVal);
    } else if (patientSearchVal === null) {
      setSelectedTblRow([]);
      setContent("");
      // setPatientInfo(null);
      // setValue("departmentName", null);
      setValue("certificateTemplate", null);
    }

    if (patientSearchVal !== null) {
      if (patientTypeVal === 0) {
        setAdmissionId(patientSearchVal?.VisitId);
        // getPatientInfo(patientSearchVal);
      } else if (patientTypeVal === 1) {
        setAdmissionId(patientSearchVal?.AdmissionId);
        // getPatientInfo(patientSearchVal);
      }
    } else if (patientSearchVal === null) {
      setPatientInfo(null);
      setTempleteList([]);
      setContent("");
    }

    if (patientSearchVal !== null) {
      getTempleteList(patientSearchVal);
    }
    // if (patientSearchVal !== null) {
    //   setValue("departmentName", {
    //     id: patientInfo?.DepartmentId,
    //     label: patientInfo?.Department,
    //     value: patientInfo?.DepartmentId,
    //   });
    // }
  }, [patientSearchVal]);

  const handleChange = (searchString) => {
    if (searchString !== "") {
      searchPatientInformation(searchString, patientTypeVal)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  React.useEffect(() => {
    if (selectedTblRow !== null) {
      setValue("certificateTemplate", {
        id: selectedTblRow["ConsentTemplateId"],
        label: selectedTblRow["Certificate Name"],
        value: selectedTblRow["Template Description"],
      });
      setContent(selectedTblRow["Template Description"]);
    }
  }, [admissionId, selectedTblRow]);

  const getTempleteList = (patientSearchVal) => {
    if (patientSearchVal !== null) {
      getPreviousCertificateList(
        patientSearchVal?.VisitId || patientSearchVal?.AdmissionId,
        location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setTempleteList(res.result);
        });
    }
  };

  const onSubmit = (data) => {
    if (patientInfo !== null && data.certificateTemplate !== null) {
      setFinalData(data);
      setOpenConfirmationModal(true);
    } else if (patientInfo === null) {
      warningAlert("Please Select Patient...");
    } else if (data.certificateTemplate === null) {
      warningAlert("Please Select Templete...");
    }
  };

  const finalObj = {
    addedBy: 0,
    opdIpd: patientTypeVal,
    consentFor: 0,
    consentTempId: 0,
    nokAddress: "",
    nokName: "",
    nokPhoneNo: "",
    nokRelationshipId: "",
    opdIpdExternalId: 0,
    tempDescription: null,
    templateId: 0,
    menuId: "",
    privilege: "",
  };
  const handleFormSubmit = (data) => {
    if (patientInfo === null) {
      warningAlert("Please Select Patient...!");
    } else if (patientInfo !== null) {
      setOpenBackdrop(true);
      setOpenConfirmationModal(false);

      finalObj.menuId =
        location?.state?.menuId && Number(location.state.menuId);
      finalObj.privilege = privilege;
      finalObj.addedBy = token.userId;
      finalObj.consentFor = 0;
      finalObj.consentTempId = data.certificateTemplate.id;
      finalObj.nokAddress = "";
      finalObj.nokName = patientInfo.patientName;
      finalObj.nokPhoneNo = patientInfo.MobileNo;
      finalObj.nokRelationshipId = "";
      finalObj.opdIpd = Number(data.patientType);

      finalObj.tempDescription = content;

      if (Number(data.patientType) === 0) {
        finalObj.opdIpdExternalId = patientInfo.visitId;
      } else if (Number(data.patientType) === 1) {
        finalObj.opdIpdExternalId = patientInfo.admissionId;
      }

      ///////////////
      addNewCertificate(finalObj)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 302) {
            warningAlert(res.message);
          } else if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            // setPatientInfo(null);
            setTempleteList([]);
            setContent("");
            // reset(defaultValues);
            getTempleteList(patientSearchVal);
            setValue("certificateTemplate", null);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          setOpenBackdrop(false);
        });
    }
  };

  // actions and print
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {userActions.map((obj) => (
          <>
            {!obj.isAction && obj.action === "Print" ? (
              <>
                <Tooltip title="Print">
                  <button
                    type="button"
                    onClick={() => {
                      setOpenPrintModal(true);
                      setCertficateDtlsId(row?.cerificateDtlsId);
                    }}
                  >
                    <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                  </button>
                </Tooltip>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="mt-14 text-xl font-bold flex justify-center">
            Certificate
          </div>
          <div className="flex gap-2">
            <div className="flex items-center">
              <label className="font-semibold">Patient Type :&nbsp;</label>
              <div
                className="mx-2 mt-1"
                onClick={() => {
                  // reset(defaultValues);
                  setContent("");
                  setPatientInfo(null);
                  setTempleteList([]);
                  resetField("patientSearch");
                }}
              >
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={patientType}
                />
              </div>
            </div>
            <div className="w-96">
              <SearchDropdown
                name="patientSearch"
                control={control}
                searchIcon={true}
                placeholder="Search By Patient Name / MR.No."
                handleInputChange={handleChange}
                isClearable={true}
                dataArray={options}
              />
            </div>
          </div>

          {/* Patient Information */}
          <div className="border items-center justify-center bg-gray-100 border-gray-300 text-left w-full rounded-md mb-2 px-2 mt-2">
            <div className=" grid  lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-1 ">
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.patientName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">Gender</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.gender}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.ageYear}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">Patient Source</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.patientSource}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">Patient Category</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.patientCategory}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold w-28 ">Tariff</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.tariff}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold w-28">Company</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo?.company}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold w-28">Doctor</span>
                <div className="whitespace-nowrap flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal ">
                    {patientInfo?.doctorName}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {templeteList && templeteList.length > 0 ? (
            <div className="mt-2">
              <CommonDynamicTableNew
                dataResult={templeteList}
                handleSelectedRow={setSelectedTblRow}
                renderActions={renderActions}
                removeHeaders={[
                  "Template Description",
                  "TempalteDescription",
                  "cerificateDtlsId",
                  "ConsentTemplateId",
                ]}
              />
            </div>
          ) : null}

          <div className="space-y-2 pt-2">
            <div className="flex gap-2 w-full items-center">
              {/* <div className="w-full">
                <DropdownField
                  control={control}
                  name="departmentName"
                  placeholder="Select Department"
                  isSearchable={false}
                  dataArray={deptForConsent}
                  isDisabled={patientInfo === null ? true : false}
                />
          </div> */}
              <div className="w-[40%]">
                <DropdownField
                  control={control}
                  name="certificateTemplate"
                  placeholder="Certificate Template"
                  dataArray={consentTemplete}
                  inputRef={{
                    ...register("certificateTemplate", {
                      onChange: (e) => {
                        setContent(e.target.value?.value);
                      },
                    }),
                  }}
                  isDisabled={patientInfo === null ? true : false}
                />
              </div>
              <div className="w-full ml-2">
                <RadioField
                  control={control}
                  name="language"
                  dataArray={language}
                />
              </div>

              {/* {patientInfo !== null ? */}
              {/*userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Print" ? (
                    <>
                      {patientInfo !== null ? (
                        <Tooltip title="Print">
                          <LocalPrintshopIcon
                            onClick={() => {
                              setOpenPrintModal(true);
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </>
                          ) : null}
                </>
                          ))*/}
              {/* : null } */}
            </div>
            <div className="mt-2">
              <JoditEditor
                ref={editor}
                name="templateDescription"
                config={config}
                value={content}
                onBlur={(c) => {
                  if (c !== "") {
                    setContent(c);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex justify-end gap-3 items-center pt-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                        setContent("");
                        setPatientInfo(null);
                      }}
                    />

                    <CommonButton
                      type="submit"
                      className="bg-customGreen text-white"
                      label="Save"
                      onClick={() => {
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </form>

      {!!openPrintModal &&
        renderPrint(<CertificatePrint printId={certficateDtlsId} />)}

      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default Certificate;
