import * as React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function PhlebitiesScoreTable(props) {
  const [rowIndex, setRowIndex] = React.useState(null);

  //allHeaders is an object which contains the title of all the columns of the table
  let allHeaders = null;

  //allHeaders is an object which contains the title of all the columns of the table
  if (props.dataArray.length > 0) {
    allHeaders = Object.keys(props.dataArray[0]);
  }

  //event listener function for the click of Table Row
  function handleClick(row, indexVal) {
    props.reset(props.defaultValues);

    props.setIncomingPhlebitiesScore(row);
    setRowIndex(indexVal);

    //vipScore is 0
    if (row["IVSite"]) {
      props.setValue("ivSiteHealthy", true);
    }

    //vipScore is 1
    if (row["SlightPainIV"]) {
      props.setValue("painNearIv", true);
    }

    if (row["SlightRednessIV"]) {
      props.setValue("rednessNearIv", true);
    }

    //vipScore is 2
    if (row["PainAtIV"]) {
      props.setValue("painAtIvSite", true);
    }

    if (row["Redness"]) {
      props.setValue("redness", true);
    }

    if (row["Swelling"]) {
      props.setValue("swelling", true);
    }

    //vipScore is 3
    if (row["PainCannula"]) {
      props.setValue("painatAlongCannula", true);
    }

    if (row["RednessAroundSite"]) {
      props.setValue("rednessAroundSite", true);
    }

    if (row["Extensive_Swelling"]) {
      props.setValue("swellingFour", true);
    }

    //vipScore is 4
    if (row["Extensive1_PainCannula"]) {
      props.setValue("painatAlongCannulaFive", true);
    }

    if (row["Extensive1_RednessAroundSite"]) {
      props.setValue("rednessAroundSiteFive", true);
    }

    if (row["Extensive1_Swelling"]) {
      props.setValue("swellingFive", true);
    }

    if (row["Extensive1_PalpableCord"]) {
      props.setValue("phalableVenousCordFive", true);
    }

    //vipScore is 5
    if (row["Extensive_PainCannula"]) {
      props.setValue("painatAlongCannulaSix", true);
    }

    if (row["Extensive_RednessAroundSite"]) {
      props.setValue("rednessAroundSiteSix", true);
    }

    if (row["SignEvident_Swelling"]) {
      props.setValue("swellingSix", true);
    }

    if (row["Extensive_PalpableCord"]) {
      props.setValue("phalableVenousCordSix", true);
    }

    if (row["Extensive1_Pyrexia"]) {
      props.setValue("pyrexia", true);
    }

    //The row object is
    // {
    //   "IVSite": 1,

    //   "SlightPainIV": 0,
    //   "SlightRednessIV": 0,

    //   "PainAtIV": 0,
    //   "Redness": 0,
    //   "Swelling": 0,

    //   "PainCannula": 0,
    //   "RednessAroundSite": 0,
    //   "Extensive_Swelling": 0,

    //   "Extensive1_PainCannula": 0,
    //   "Extensive1_RednessAroundSite": 0,
    //   "Extensive1_Swelling": 0,
    //   "Extensive1_PalpableCord": 0,

    //   "Extensive_PainCannula": 0,
    //   "Extensive_RednessAroundSite": 0,
    //   "SignEvident_Swelling": 0,
    //   "Extensive_PalpableCord": 0,
    //   "Extensive1_Pyrexia": 0,
    // }
  }

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
          {/* Display the table ; if and only if the dataArray's length is greater than 0 */}
          {props.dataArray.length > 0 ? (
            <Paper sx={{ width: "100%", my: 1 }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-48 2xl:h-60"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      {allHeaders &&
                        allHeaders.map((singleHeader) => (
                          <TableCell
                            // style={{ width: 160 }}
                            // align="left"
                            key={singleHeader}
                          >
                            <span className="text-gray-600  whitespace-nowrap">
                              {singleHeader}
                            </span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {props.dataArray.map((singleDataObj, index) => {
                      return (
                        <>
                          <TableRow
                            onClick={() => {
                              handleClick(singleDataObj, index);
                            }}
                            style={{
                              color: "white",
                              backgroundColor:
                                rowIndex === index ? "#a6d8e8" : "",
                            }}
                          >
                            {allHeaders &&
                              allHeaders.map((header) => (
                                <TableCell key={header}>
                                  <span className="text-gray-600  whitespace-nowrap">
                                    {singleDataObj[header]}
                                  </span>
                                </TableCell>
                              ))}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Paper sx={{ width: "100%", my: 1 }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-48 2xl:h-60"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    ></TableRow>
                  </TableHead>

                  <TableBody>
                    <div className="row">
                      <div className="grid justify-center text-black">
                        Data Not Available.
                      </div>
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </div>
    </>
  );
}
