import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchPrintInPatientReturn } from "../../../../nursingStation/services/inPatientReturnServices/InPatientReturnServices";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchIssueItemList,
  fetchItemDetails,
  fetchPatientDetails,
  fetchPatientList,
  fetchTaxType,
  postInPatientReturnData,
} from "../../../services/pharmacy/inPatientReturnServices/InPatientReturnServices";
import ItemDetailsTable from "./ItemDetailsTable";
import { useLocation } from "react-router-dom";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import { parseISO } from "date-fns";
import InPatientReturnListModal from "./InPatientReturnListModal";
import RadioField from "../../../../common/components/FormFields/RadioField";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import NumberOfCopiesModal from "../../../../common/components/printModal/NumberOfCopiesModal";
import { routeToPrinterDevice } from "../../../../commonServices/barCodeServices/BarCodeServices";
import { blobToBase64 } from "../../../../common/components/printModal/BlobToB64";

const ReturnTypeArr = [
  {
    id: 0,
    value: "Cash",
    label: "Cash",
  },
  {
    id: 1,
    value: "Credit",
    label: "Credit",
  },
];
const PharmacyInPatientReturn = (props) => {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let location = useLocation();
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  //
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [printResponse, setPrintResponse] = useState(null);

  //
  const [userActions, setUserActions] = useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showSpinner1, setShowSpinner1] = React.useState(null);

  //autocomlete
  const [patientList, setPatientList] = React.useState();
  //patient details
  const [patientDetails, setPatientDetails] = React.useState(null);
  //item details
  const [dataResult, setDataResult] = React.useState([]);
  const [itemDetails, setItemDetails] = React.useState([]);
  const [errorToTblInput, setErrorToTblInput] = useState(false);

  //gst
  const [totalGst, setTotalGst] = React.useState();
  //total Return Amt
  const [totalReturnAmt, setTotalReturnAmt] = React.useState();
  //item taxType
  const [taxType, setTaxType] = React.useState([]);
  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);

  // InPatientReturnListModal
  const [openIssueReturnListModal, setOpenIssueReturnListModal] =
    React.useState(false);
  const handleOpenIssueReturnListModal = () =>
    setOpenIssueReturnListModal(true);
  const handleCloseIssueReturnListModal = () =>
    setOpenIssueReturnListModal(false);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    props.handleClose && props.handleClose();
  };
  //

  const [privilege, setPrivilege] = React.useState("");

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      inPatientReturnSearch: "",
      storeName: "",
      taxTypes: "",
      netReturnAmount: 0,
      totalAmount: 0,
      totalGstAmount: 0,
      serChargeAmount: 0,
      remark: "",
      //
      returnType: 1,
    },
  });

  const { control, getValues, register, setValue, reset, watch } = methods;

  let selected_returnType = Number(watch("returnType"));

  useEffect(() => {
    setValue("storeName", token.storeName);
  }, []);

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(
        props.menuId ? props.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    setValue("netReturnAmount", totalReturnAmt);
    setValue("totalAmount", totalReturnAmt);
    setValue("totalGstAmount", totalGst);
  }, [totalReturnAmt, totalGst]);

  useEffect(() => {
    // tax type
    fetchTaxType()
      .then((response) => response.data)
      .then((res) => {
        setTaxType(res.result);
      });
  }, []);

  // patient list autocomplet //fetchPatientDetails
  const searchPatient = (e) => {
    e &&
      UseCheckConsecutiveCharacters(e) &&
      fetchPatientList(e)
        .then((response) => response.data)
        .then((res) => {
          setPatientList(res.result);
        });
  };

  useEffect(() => {
    getPatientDetails(props?.admissionId);
  }, []);

  useEffect(() => {
    // handleReturnList(props?.admissionId);
    !!patientDetails &&
      handleListDetails(props?.admissionId || patientDetails.AdmissionId);
  }, [selected_returnType]);

  const getPatientDetails = (e) => {
    //for patient details
    if (e && e !== null) {
      fetchPatientDetails(e)
        .then((response) => response.data)
        .then((res) => {
          if (!res.result?.IsNursingClearanceDone) {
            setPatientDetails(res.result);

            //for item details
            // handleReturnList(e);
            handleListDetails(e);
          } else {
            warningAlert(
              "Nursing clearence is already given for this patient."
            );
          }
        });
    } else if (e === null) {
      setPatientDetails(null);
      reset();
    }
  };

  const handleReturnList = (e) => {
    console.log("selected_returnType", patientDetails, e);
    if (!!e || !!patientDetails) {
      setShowSpinner(true);
      setItemDetails([]);
      setShowSpinner1(null);

      fetchIssueItemList(
        e || patientDetails.AdmissionId,

        Number(selected_returnType)
      )
        .then((response) => response.data)
        .then((res) => {
          setDataResult(res.result);
          console.log("dataResult", dataResult);
          setShowSpinner(false);
        })
        .catch(() => {
          setShowSpinner(false);
        });
    }
  };

  ///
  const handleSelectedRow = (row) => {
    handleListDetails(row?.IPDIssueId);
  };

  const handleListDetails = (e) => {
    console.log(e);
    if (!!e) {
      setShowSpinner1(true);
      fetchItemDetails(
        // patientDetails.AdmissionId,
        e,
        0,
        // IPDIssueId,
        props.menuId ? props.menuId : location?.state?.menuId,
        Number(selected_returnType)
      )
        .then((response) => response.data)
        .then((res) => {
          setItemDetails(res.result);
          // setUserActions(res.actions);
          setShowSpinner1(false);
        })
        .catch(() => {
          setShowSpinner1(null);
        });
    }
  };

  //final obj
  const finalObj = {
    menuId: "",
    privilege: "",
    addedBy: 0,
    cashCounterId: 0,
    cashCredit: 0,
    discountAmount: 0,
    inPatientReturnDetailsDto: [],
    isApproved: false,
    isFree: true,
    remark: "string",
    serChargeAmt: 0,
    storeId: 0,
    totalAmount: 0,
    totalAmountPayable: 0,
    totalOtherTaxAmt: 0,
    totalPaidAmount: 0,
    totalTaxAmt: 0,
    totalVatAmount: 0,
    isCashCredit: null,
  };

  ////onSubmit post object below  selectedDrugData

  const onSubmit = (data) => {
    const nonZeroItems = itemDetails.filter(
      (item) => item.Quantity !== undefined && item.Quantity !== 0
    );

    if (patientDetails === null) {
      warningAlert("Please Select Patient !");
    } else if (errorToTblInput || nonZeroItems.length === 0) {
      warningAlert("Please Issue Valid Quantity !");
    } else if (!errorToTblInput && patientDetails !== null) {
      setOpenConfirmationModal(true);
    }
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    //
    finalObj.menuId = props.menuId ? props.menuId : location?.state?.menuId;
    finalObj.privilege = privilege;
    finalObj.addedBy = token.userId;
    finalObj.admissionId = patientDetails.AdmissionId;
    finalObj.cashCounterId = token.cashCounterId;
    finalObj.cashCredit = 0;
    finalObj.discountAmount = 0;
    finalObj.isApproved = true;
    finalObj.isFree = true;
    finalObj.serChargeAmt = getValues("serChargeAmount");
    finalObj.remark = getValues("remark");
    finalObj.storeId = token.storeId;
    finalObj.totalAmount = Number(getValues("totalAmount"));
    finalObj.totalAmountPayable = getValues("netReturnAmount");
    finalObj.totalOtherTaxAmt = 0;
    finalObj.totalPaidAmount = getValues("netReturnAmount");
    finalObj.totalOtherTaxAmt = getValues("totalGstAmount");
    finalObj.totalTaxAmt = getValues("totalGstAmount");
    finalObj.totalVatAmount = getValues("netPayableAmount");
    finalObj.isCashCredit = Number(selected_returnType);

    // to set data according service obj
    let TblData = [];
    for (let i = 0; i < itemDetails.length; i++) {
      let tableObj = {
        avlQtyForApproval: itemDetails[i].QtyAvlForReturn,
        batchCode: itemDetails[i].BatchCode,
        batchExpiryDate:
          itemDetails[i].BatchExpiryDate !== ""
            ? parseISO(itemDetails[i].BatchExpiryDate)
            : "1900-01-01T00:00:00.000Z",
        conversion: itemDetails[i].Conversion,
        discPercentage: itemDetails[i].QtyAvlForReturn, ///
        discount: itemDetails[i].QtyAvlForReturn, ///
        ipdIssueId: itemDetails[i].IPDIssueId,
        ipdReturnRate: itemDetails[i].QtyAvlForReturn, //
        isConsignment: true, //
        itemId: itemDetails[i].ItemId,
        mrp: itemDetails[i].MRP,
        netAmount: itemDetails[i].QtyAvlForReturn, //
        otherTax: itemDetails[i].OtherTaxAmount,
        purchaseRate: itemDetails[i].PurchaseRate,
        returnQty: itemDetails[i].Quantity,
        taxAmount: itemDetails[i].TaxAmount,
        totalAmount: itemDetails[i].returnPrice, //
        totalForVat: itemDetails[i].QtyAvlForReturn, //
        unitLandedRate: itemDetails[i].QtyAvlForReturn, //
        unitMrp: itemDetails[i].UnitMRP,
        vatAmount: itemDetails[i].QtyAvlForReturn, //
        vatInclusive: itemDetails[i].VATInclusive,
        vatPercentage: itemDetails[i].VATPercentage,
      };
      TblData.push(tableObj);
    }

    const nonZeroItems = TblData.filter(
      (item) => item.returnQty !== undefined && item.returnQty !== 0
    );

    finalObj.inPatientReturnDetailsDto = nonZeroItems;

    //

    postInPatientReturnData(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          methods.reset();

          setOpenBackdrop(false);
          setPatientDetails(null);
          setItemDetails([]);
          setValue("remark", "");
          methods.resetField("inPatientReturnSearch");
          methods.resetField("taxTypes");

          handleOpenPrintModal(res.result);
        }
      })
      .catch(() => {
        setOpenBackdrop(false);
      });
  };

  // print service

  const handleOpenPrintModal = (ipdReturnId) => {
    fetchPrintInPatientReturn(
      ipdReturnId,
      selected_returnType === 1 ? itemDetails[0].IPDIssueId : 0,
      "A5",
      selected_returnType
    ).then((response) => {
      if (defaultPrinters) {
        setOpenNumberOfCopiesModal(true);
        setPrintResponse(response);
      } else {
        setOpenPrintModal(true);
        setUrlforPrint(response);
      }
    });
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setPrintResponse(null);
    });
  };

  return (
    <div className="mt-12">
      {/* heading */}
      <div className="text-center text-black font-bold text-xl my-2 ">
        In Patient Return
      </div>
      <form>
        {/* //search// */}
        <div className="grid md:grid-cols-5 lg:grid-cols-6 gap-3">
          <div className="w-full md:col-span-5 lg:col-span-2 z-50">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={patientList}
              name="inPatientReturnSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={searchPatient}
              isDisabled={props?.admissionId ? true : false}
              inputRef={{
                ...register("inPatientReturnSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      getPatientDetails(e.target.value.id);
                    } else {
                      setPatientDetails(null);
                      setItemDetails([]);
                      setValue("remark", "");
                      methods.resetField("taxTypes");
                    }
                  },
                }),
              }}
            />
          </div>

          {/* // Store// */}
          <div className="">
            <InputField
              control={control}
              name="storeName"
              label="Store Name "
              disabled={true}
            />
          </div>

          {/* // Tax Type// */}
          <div className="">
            <DropdownField
              control={control}
              // error={errors.nationality}
              name="taxTypes"
              label="Tax Type"
              dataArray={taxType}
              isSearchable={false}
              isClearable={false}
              placeholder="Tax Type"
              inputRef={{
                ...register("district", {
                  onChange: (e) => {
                    // setSelectedTaxType(e.target.value.id);
                  },
                }),
              }}
            />
          </div>

          <div className=" flex gap-5 mt-2  ">
            <span className="text-sm font-semibold">Type</span>
            <RadioField
              label=""
              name="returnType"
              control={control}
              dataArray={ReturnTypeArr}
            />
          </div>

          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {obj.action === "View" ? (
                      <div>
                        <CommonButton
                          label="Show Return List"
                          onClick={() => handleOpenIssueReturnListModal()}
                          className="bg-customBlue text-white"
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        {/* patient Details */}
        <fieldset
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
        >
          <div className=" grid  grid-cols-4 2xl:grid-cols-4  ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.patientName}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.mrNo}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">IPD No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.IpdNo}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Issue No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.IPDIssueNo}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Gender</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.gender}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Age</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.AgeYear}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Doctor</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.doctor}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Bed No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.BedNo}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Ward</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.Ward}
                </span>
              </div>
            </div>
          </div>
        </fieldset>

        {!!patientDetails && (
          <>
            {/* {showSpinner ? (
              <div className="flex justify-center my-20">
                <LoadingSpinner />
              </div>
            ) : dataResult.length > 0 ? (
              <CommonDynamicTableNew  
                dataResult={dataResult}
                handleSelectedRow={handleSelectedRow}
                tableClass="h-52"
              />
            ) : (
              <div className="text-center my-5 ">No Data Found !</div>
            )} */}

            {showSpinner1 ? (
              <div className="flex justify-center my-32">
                <LoadingSpinner />
              </div>
            ) : itemDetails.length > 0 ? (
              <div className="mt-2">
                <ItemDetailsTable
                  data={itemDetails}
                  itemDetails={itemDetails}
                  setItemDetails={setItemDetails}
                  setTotalGst={setTotalGst}
                  setTotalReturnAmt={setTotalReturnAmt}
                  setErrorToTblInput={setErrorToTblInput}
                />
              </div>
            ) : (
              <div className="text-center my-32">No Data Found !</div>
            )}

            {itemDetails.length > 0 && (
              <>
                {" "}
                {/* formfields */}
                <div className="grid grid-cols-7 gap-3 mt-3">
                  <div className="col-span-3">
                    <TextField
                      id="outlined-multiline-static"
                      name="remark"
                      {...register("remark")}
                      label="Remark"
                      multiline
                      InputLabelProps={{ shrink: true }}
                      rows={1}
                      control={control}
                      fullWidth
                    />
                  </div>

                  {/* ///serChargeAmount/// */}
                  <div className="">
                    <InputField
                      name="serChargeAmount"
                      variant="outlined"
                      label="SerCharge Amount "
                      control={control}
                      disabled={true}
                      isDisabled={true}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>

                  {/* ///Total Gst Amount/// */}
                  <div className="">
                    <InputField
                      name="totalGstAmount"
                      variant="outlined"
                      label="Total Gst Amount"
                      // error={errors.code}
                      control={control}
                      disabled={true}
                      isDisabled={true}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>

                  {/* ///Total Amount/// */}
                  <div className="">
                    <InputField
                      name="totalAmount"
                      variant="outlined"
                      label="Total Return "
                      // error={errors.code}
                      control={control}
                      disabled={true}
                      isDisabled={true}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>

                  {/* ///net return Amount/// */}
                  <div className="">
                    <InputField
                      name="netReturnAmount"
                      variant="outlined"
                      label="Net Return Amount"
                      // error={errors.code}
                      control={control}
                      disabled={true}
                      isDisabled={true}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                {/* Buttons */}
                <div>
                  <div className="flex gap-3   justify-end">
                    {userActions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "Create" ? (
                          <>
                            <div>
                              <CommonButton
                                label="Reset"
                                onClick={() => {
                                  setPatientDetails(null);
                                  setItemDetails([]);
                                  setValue("remark", "");
                                  methods.resetField("inPatientReturnSearch");
                                  methods.resetField("taxTypes");
                                }}
                                className="border border-customRed text-customRed"
                              />
                            </div>
                            <div
                              onClick={() => {
                                setPrivilege(obj.action);
                              }}
                            >
                              <CommonButton
                                label="Save"
                                onClick={onSubmit}
                                className="bg-customGreen text-white"
                              />
                            </div>
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </form>

      {/* print */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* InPatientListModal */}
      {openIssueReturnListModal ? (
        <InPatientReturnListModal
          open={openIssueReturnListModal}
          setOpen={setOpenIssueReturnListModal}
          handleOpen={handleOpenIssueReturnListModal}
          handleClose={handleCloseIssueReturnListModal}
          patientDetails={patientDetails}
          userActions={userActions}
          menuId={location?.state?.menuId}
        />
      ) : null}

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default PharmacyInPatientReturn;
