import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

//search autocomplet Dropdown API
//it used in bed transfer

//autocomplete service for seach patient from ipd admission
export const fetchSearchDropDown = (searchString, opdIpd) => {
  //
  return apiClient.get(
    `/billSettlement/getPatientSearch/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchSearchedData = (searchPatient) => {
  return apiClient.get(`/patientInfo/registration/${searchPatient}`, {
    headers: authHeader(),
  });
};

// bed transfer patient info--admission searched patient data
export const getBedTransferPatientInfo = (patientId) => {
  return apiClient.get(`/patientInfo/transfer/${patientId}`, {
    headers: authHeader(),
  });
};

//autocomplete service for seach patient from ipd admission
export const getPatientAdmissionAutoComplete = (searchString) => {
  //
  return apiClient.get(`/patientInfo/admissionAutoComplete/${searchString}`, {
    headers: authHeader(),
  });
};

//autocomplete service for seach patient from ipd admission
export const getPatientInfoOpdIpdAutocomplete = (searchString, opdIpd) => {
  return apiClient.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchPatientInfoAutocomplete = (patientInfo) => {
  return apiClient.get(`/patientInfo/${patientInfo}`, {
    headers: authHeader(),
  });
};
