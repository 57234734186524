import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { differenceInDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../../common/components/FormFields/InputArea";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchDoctor } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchPatientInfoMlcDetails,
  saveMlcInjuryCertificate,
} from "../../../services/admissionServices/AdmissionServices";
import MlcInjuryCertificatesListModal from "./MlcInjuryCertificatesListModal";

const CertificateTypeArr = [
  {
    id: 1,
    label: "Injury Certificate",
    value: "Injury Certificate",
  },
  {
    id: 2,
    label: "MLC Certificate",
    value: "MLC Certificate",
  },
];

export default function MlcInjuryCertificatesModal(props) {
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const {
    handleClose,
    privilege,
    menuId,
    selectedMlcData,
    populateTable,
  } = props;
  //
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [doctor, setDoctor] = useState([]);
  const [mlcDetails, setMlcDetails] = useState(null);

  const [selectedCertificateType, setSelectedCertificateType] = useState(1);

  //

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [allFormDetails, setAllFormDetails] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);
  //

  //listing modal
  const [openCertificatesModal, setOpenCertificatesModal] = React.useState(
    false
  );
  const handleOpenCertificatesModal = () => setOpenCertificatesModal(true);
  const handleCloseCertificatesModal = () => setOpenCertificatesModal(false);
  //
  //

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      // certificateType: 1,

      //Injury certificate
      certificateThat: "",
      residance: "",
      hadComeToDate: new Date(),
      hadComeToTime: new Date(),
      injuryEximined: "",
      natureOfInjury: "",
      weaponUsed: "",
      simple_grievous_dangerous: "",
      tretFromDate: new Date(),
      tretToDate: new Date(),
      duration: "",

      //MLC certificate
      address: "",
      broughtBy: "",
      constable: "",
      dateOfExamination: new Date(),
      timeOfExamination: new Date(),
      dateOfAccident_Assault: new Date(),
      timeOfAccident_Assault: new Date(),
      ageOfInjury: "",
      briefHistory: "",
      causeOfInjury: "",
      admittedUnder: null,
      ward_bed: "",
      policeStation: "",
      buckleNumber: "",
      infGivenDate: new Date(),
      infGivenTime: new Date(),

      //
      identificationMarkOne: "",
      identificationMarkTwo: "",
    },
  });

  const { control, watch, register, setValue } = methods;

  let certificateType_selected = watch("certificateType");
  let TretFromDate = watch("tretFromDate");
  let TretToDate = watch("tretToDate");

  useEffect(() => {
    setValue("certificateType", selectedCertificateType);
  }, [selectedCertificateType]);

  useEffect(() => {
    setValue("duration", differenceInDays(TretToDate, TretFromDate));
  }, [TretFromDate, TretToDate]);

  useEffect(() => {
    patchDefaultData();
  }, [mlcDetails]);

  const patchDefaultData = () => {
    if (!!mlcDetails) {
      setValue("address", mlcDetails?.Address);
      setValue("certificateThat", mlcDetails?.PatientName);
      setValue("ward_bed", mlcDetails.Ward + "," + mlcDetails.BedNo);
      setValue("policeStation", mlcDetails.PoliceStation);
      setValue("constable", mlcDetails.Constable);
      setValue("buckleNumber", mlcDetails.BuckleNo);
      setValue("admittedUnder", {
        id: mlcDetails.AdmittingDocId,
        label: mlcDetails.DoctorName,
        value: mlcDetails.DoctorName,
      });
      setValue("identificationMarkOne", mlcDetails.identificationMarkOne || "");
      setValue("identificationMarkTwo", mlcDetails.identificationMarkTwo || "");
      setValue("residance", mlcDetails.Address || "");
      setValue("broughtBy", mlcDetails.NameAddressOfPersonWhoBaughtPT || "");
    }
  };

  const getPatientInfoMlcDetails = () => {
    fetchPatientInfoMlcDetails(selectedMlcData?.AdmissionId).then(
      (response) => {
        setMlcDetails(response.data.result);
      }
    );
  };

  useEffect(() => {
    getPatientInfoMlcDetails();
  }, [selectedMlcData]);

  const onSubmit = (data) => {
    setAllFormDetails(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);

    const finalObject = {
      address: selectedCertificateType === 1 ? data.residance : data.address,
      admDoctorId: data.admittedUnder?.id || 0,
      ageDay: data.AgeDay,
      ageMonth: data.AgeMonth,
      ageOfInjury: data.ageOfInjury,
      ageYear: selectedMlcData.AgeYear,
      briefHistory: data.briefHistory,
      buckleNo: data.buckleNumber || "",
      causeOfInjury: data.causeOfInjury,
      certificateType: Number(data.certificateType),
      constable: data.Constable || "", //
      dateTimeOfAccidentAssault: `${
        format(data.dateOfAccident_Assault, "yyyy-MM-dd") +
        "T" +
        format(data.timeOfAccident_Assault, "HH:mm:ss.SSS") +
        "Z"
      }`,

      dateTimeOfExamination: `${
        format(data.dateOfExamination, "yyyy-MM-dd") +
        "T" +
        format(data.timeOfExamination, "HH:mm:ss.SSS") +
        "Z"
      }`,
      duration: data.duration,
      genderId: selectedMlcData.GenderId,
      injuriesExaminedByDr: data.injuryEximined,
      menuId: menuId,
      mlcDateTime: "2024-02-02T08:42:34.286Z",
      mlcNo: selectedMlcData.MLCNo,
      mobileNo: selectedMlcData.MobileNo,
      mrNo: selectedMlcData.MRNo,
      nameAddressOfPersonWhoBoughtPT: data.broughtBy,
      natureOfInjury: data.natureOfInjury,
      opdIpdExternal: 1,
      opdIpdExternalId: selectedMlcData.AdmissionId,
      patientName: selectedMlcData["Patient Name"],
      policeStation: data.policeStation || "",
      privilege: privilege,
      ptArrivalDateTime: `${
        format(data.hadComeToDate, "yyyy-MM-dd") +
        "T" +
        format(data.hadComeToTime, "HH:mm:ss.SSS") +
        "Z"
      }`,
      simpleGraviousDangerous: data.simple_grievous_dangerous,
      treatToTime: format(data.tretToDate, "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"),
      treatFromTime: format(data.tretFromDate, "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"),
      wardBedNo: data.ward_bed,
      weaponUsed: data.weaponUsed,
      //
      identificationMarkOne: data.identificationMarkOne,
      identificationMarkTwo: data.identificationMarkTwo,
    };

    //finalObject.menuId = menuId;
    //finalObject.privilege = privilege;

    setOpenBackdrop(true);

    saveMlcInjuryCertificate(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          methods.reset();
          setOpenBackdrop(false);
          handleClose();
        }
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded text-black font-semibold">
              Certificates
            </div>

            <hr className="border mx-1 border-customBlue" />
            <form>
              <div className="mx-1 flex justify-between items-center mb-3">
                <div
                  className="flex px-2 gap-3 items-center my-2"
                  onClick={(e) => {
                    setSelectedCertificateType(Number(e.target.value));
                    methods.reset();
                    patchDefaultData();
                  }}
                >
                  <RadioField
                    label=""
                    name="certificateType"
                    control={control}
                    dataArray={CertificateTypeArr}
                  />
                </div>
                <div>
                  <CommonButton
                    label="Certificate List"
                    className="bg-customBlue text-white"
                    onClick={() => {
                      handleOpenCertificatesModal();
                    }}
                  />
                </div>
              </div>

              <div>
                {Number(selectedCertificateType) === 1 && (
                  <div className="grid lg:grid-cols-6 md:grid-cols-2 gap-3">
                    <div className="lg:col-span-2">
                      <InputField
                        name="certificateThat"
                        label="Certificate That"
                        variant="outlined"
                        control={control}
                        disabled={true}
                        inputProps={{ maxLength: 50 }}
                        // error={errors.loginName}
                      />
                    </div>
                    <div className="lg:col-span-2">
                      <InputArea
                        control={control}
                        name="residance"
                        label="Residence"
                        placeholder="Residence"
                      />
                    </div>
                    <div className=" ">
                      <DatePickerFieldNew
                        control={control}
                        name="hadComeToDate"
                        label="Had Come To Date"
                        value={new Date()}
                        disableFuture={false}
                        disablePast={false}
                        inputFormat="dd-MM-yyyy"
                        // disabled={true}
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        name="hadComeToTime"
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              label="Had Come To Time"
                              value={value}
                              onChange={onChange}
                              disabled={false}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  // error={errors.startTime}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: " ",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                    <div className="lg:col-span-6">
                      <InputArea
                        control={control}
                        name="injuryEximined"
                        label="He/She had following injury when examined by doctor"
                        placeholder="He/She had following injury when examined by doctor"
                      />
                    </div>
                    <div className="lg:col-span-6">
                      <InputArea
                        control={control}
                        name="natureOfInjury"
                        label="Nature Of Injury"
                        placeholder="Nature Of Injury"
                      />
                    </div>
                    <div className="lg:col-span-3">
                      <InputArea
                        control={control}
                        name="weaponUsed"
                        label="The weapon used sharp/blunt"
                        placeholder="The weapon used sharp/blunt"
                      />
                    </div>
                    <div className="lg:col-span-3">
                      <InputArea
                        control={control}
                        name="simple_grievous_dangerous"
                        label="Simple/grevious or Dangerous"
                        placeholder="simple/grevious or dangerous"
                      />
                    </div>
                    <div className="lg:col-span-3">
                      <InputField
                        name="identificationMarkOne"
                        variant="outlined"
                        label="Identification Mark 1"
                        // error={errors.identificationMarkOne}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>
                    <div className="lg:col-span-3">
                      <InputField
                        name="identificationMarkTwo"
                        variant="outlined"
                        label="Identification Mark 2"
                        // error={errors.identificationMarkTwo}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-6 grid md:grid-cols-5 lg:grid-cols-9 gap-3">
                      <p className="md:col-span-9 lg:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins">
                        He/She was treated as inamdar patient As OPD
                      </p>
                      <div className="col-span-2 ">
                        <DatePickerFieldNew
                          control={control}
                          name="tretFromDate"
                          label="From Date"
                          value={new Date()}
                          // disableFuture={true}
                          // disablePast={false}
                          inputFormat="dd-MM-yyyy"
                          // disabled={true}
                        />
                      </div>
                      <div className="col-span-2 ">
                        <DatePickerFieldNew
                          control={control}
                          name="tretToDate"
                          label="To Date"
                          value={new Date()}
                          // disableFuture={true}
                          // disablePast={false}
                          inputFormat="dd-MM-yyyy"
                          // disabled={true}
                        />
                      </div>
                      <div className="">
                        <InputField
                          name="duration"
                          label="Duration (Days)"
                          variant="outlined"
                          control={control}
                          disabled={true}
                          inputProps={{ maxLength: 50 }}
                          // error={errors.loginName}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {Number(selectedCertificateType) === 2 && (
                  <div className="grid lg:grid-cols-6 md:grid-cols-2 gap-3">
                    <fieldset
                      className="border border-gray-300 md:col-span-2 lg:col-span-6 w-full
 text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 "
                    >
                      <div className="py-2 grid grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm ">
                          <span className=" font-semibold w-28">
                            Patient Name
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedMlcData &&
                                selectedMlcData["Patient Name"]}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                          <span className=" font-semibold w-28">Age</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedMlcData && selectedMlcData.AgeYear}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                          <span className=" font-semibold w-28">Gender</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedMlcData && selectedMlcData.Gender}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                          <span className=" font-semibold w-28">
                            Mobile Number
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedMlcData && selectedMlcData.ContactNo}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                          <span className=" font-semibold w-28">MLC No.</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedMlcData && selectedMlcData.MLCNo}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                          <span className=" font-semibold w-28">Reg. No.</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedMlcData && selectedMlcData.MRNo}
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <div className="col-span-2 lg:col-span-3">
                      <InputArea
                        control={control}
                        name="address"
                        label="Address"
                        placeholder="Address"
                      />
                    </div>

                    <div className="lg:col-span-3 col-span-2">
                      <InputArea
                        control={control}
                        name="broughtBy"
                        label="Name And Address Of The person who has brought the patient"
                        placeholder="Name And Address Of The person who has brought the patient"
                      />
                    </div>
                    <div className=" ">
                      <DatePickerFieldNew
                        control={control}
                        name="dateOfExamination"
                        label="Date Of Examination"
                        value={new Date()}
                        disableFuture={false}
                        disablePast={false}
                        inputFormat="dd-MM-yyyy"
                        // disabled={true}
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        name="timeOfExamination"
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              label="Time Of Examination"
                              value={value}
                              onChange={onChange}
                              disabled={false}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  // error={errors.startTime}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: " ",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                    <div className=" ">
                      <DatePickerFieldNew
                        control={control}
                        name="dateOfAccident_Assault"
                        label="Date Of Accident/Assault"
                        value={new Date()}
                        disableFuture={false}
                        disablePast={false}
                        inputFormat="dd-MM-yyyy"
                        //disabled={true}
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        name="timeOfAccident_Assault"
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              label="Time Of Accident/Assault"
                              value={value}
                              onChange={onChange}
                              disabled={false}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  // error={errors.startTime}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: " ",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>

                    <div className="">
                      <InputField
                        name="ageOfInjury"
                        label="Age Of Injury"
                        variant="outlined"
                        control={control}
                        inputProps={{ maxLength: 50 }}
                        // error={errors.loginName}
                      />
                    </div>

                    <div className="col-span-2 lg:col-span-3">
                      <InputArea
                        control={control}
                        name="briefHistory"
                        label="Brief History,Clinical Examination and Diagnosis"
                        placeholder="Brief History,Clinical Examination and Diagnosis"
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-3">
                      <InputArea
                        control={control}
                        name="causeOfInjury"
                        label="Cause Of Injury"
                        placeholder="Cause Of Injury"
                      />
                    </div>

                    <div className="">
                      <SearchDropdown
                        control={control}
                        searchIcon={true}
                        isSerchable={true}
                        isClearable={true}
                        // error={errors.doctor}
                        // maxMenuHeight={150}
                        name="admittedUnder"
                        // label="Doctor *"
                        placeholder="Admitted Under"
                        dataArray={doctor}
                        handleInputChange={(e) => {
                          // Doctor
                          e &&
                            fetchDoctor(e)
                              .then((response) => {
                                setDoctor(response.data.result);
                              })
                              .catch(() => {});
                        }}
                      />
                    </div>
                    <div className="">
                      <InputField
                        name="ward_bed"
                        label="Ward And Bed No."
                        variant="outlined"
                        control={control}
                        disabled={true}
                        inputProps={{ maxLength: 50 }}
                        // error={errors.loginName}
                      />
                    </div>
                    <div className="lg:col-span-2">
                      <InputField
                        name="identificationMarkOne"
                        variant="outlined"
                        label="Identification Mark 1"
                        // error={errors.identificationMarkOne}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>
                    <div className="lg:col-span-2">
                      <InputField
                        name="identificationMarkTwo"
                        variant="outlined"
                        label="Identification Mark 2"
                        // error={errors.identificationMarkTwo}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>
                    <p className="md:col-span-2 lg:col-span-6 mr-3 font-bold tracking-wide text-sm font-Poppins">
                      Information Given To
                    </p>

                    <div className="">
                      <InputField
                        name="policeStation"
                        label="Police Station"
                        variant="outlined"
                        control={control}
                        disabled={true}
                        inputProps={{ maxLength: 50 }}
                        // error={errors.loginName}
                      />
                    </div>
                    <div className="">
                      <InputField
                        name="constable"
                        label="Constable"
                        variant="outlined"
                        control={control}
                        disabled={true}
                        inputProps={{ maxLength: 50 }}
                        // error={errors.loginName}
                      />
                    </div>
                    <div className="">
                      <InputField
                        name="buckleNumber"
                        label="Buckle Number"
                        variant="outlined"
                        control={control}
                        disabled={true}
                        inputProps={{ maxLength: 50 }}
                        // error={errors.loginName}
                      />
                    </div>
                    <div className=" ">
                      <DatePickerFieldNew
                        control={control}
                        name="infGivenDate"
                        label="Date"
                        value={new Date()}
                        disableFuture={false}
                        disablePast={false}
                        inputFormat="dd-MM-yyyy"
                        // disabled={true}
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        name="infGivenTime"
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              label="Time"
                              value={value}
                              onChange={onChange}
                              disabled={false}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  // error={errors.startTime}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: " ",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                <CommonButton
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/*  Modal    */}
      {openCertificatesModal ? (
        <MlcInjuryCertificatesListModal
          open={openCertificatesModal}
          setOpen={setOpenCertificatesModal}
          handleOpen={handleOpenCertificatesModal}
          handleClose={handleCloseCertificatesModal}
          // userActions={userActions}
          selectedCertificateType={selectedCertificateType}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={() => handleFormSubmit(allFormDetails)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
