import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//autoComplete
export const fetchBloodRequisitionAcceptanceListSearch = (searchObj) => {
  return apiClient.post(
    `/bloodBank/bloodRequisitionAcceptanceListSearch
  `,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};


//Blood ReqAcceptance List
export const fetchBloodReqAcceptanceList = (tableObj) => {
  return apiClient.post(`/bloodBank/bloodRequisitionAcceptanceList`, tableObj, {
    headers: authHeader(),
  });
};

//accept Blood Requisition 
export const acceptBloodRequisition = (RequisitionID,menuId,privilege) => {
  return apiClient.get(
    `/bloodBank/acceptBloodRequisition/${RequisitionID}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};
