import WorkOrderGeneration from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const savePhysiotherapyWorkOrder = (postObj) => {
  return WorkOrderGeneration.post(
    `/physiotherapy/savePhysiotherapyWorkOrder`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};
