// /api/patientInfo/opdIpdAutoComplete/{searchString}/{opdIpd}
import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const searchPatientInformation = (searchString, opdIpd) => {
  return apiClient.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const patientInfo = (admissionId) => {
  return apiClient.get(`/divaTool/patientInfo/${admissionId}`, {
    headers: authHeader(),
  });
};

export const getDivaToolList = (id) => {
  return apiClient.get(`/divaTool/divaToolParaHeaders/${id}`, {
    headers: authHeader(),
  });
};

export const addNewDivaTool = async (createDivatool) => {
  return await apiClient.post(`/divaTool/saveDivaTool`, createDivatool, {
    headers: authHeader(),
  });
};

export const getDivatoolPostList = async (divatoolObj) => {
  return await apiClient.post(`/divaTool/divaToolViewList`, divatoolObj, {
    headers: authHeader(),
  });
};

export const getDivaToolDetails = async (divaToolId) => {
  // /api/divaTool/getDivaToolDetails/{divaToolId}
  return await apiClient.get(`/divaTool/getDivaToolDetails/${divaToolId}`, {
    headers: authHeader(),
  });
};


export const GetDivaToolPrint = (id) => {
  return apiClient.get(`/divaTool/getDivaToolDetails/${id}`, {
    headers: authHeader(),
  });
};