import { Modal, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { EditIcon } from "../../../assets/icons/InvestigationIcon";
import CancelButton from "../../../common/components/Buttons/CancelButton";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SaveScheduleButton from "../../../common/components/Buttons/SaveScheduleButton";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import {
  errorAlert
} from "../../../common/components/Toasts/CustomToasts";

export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",

  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
};

function DrugSchedulTable(props) {
  const { control, watch, setValue } = useForm({ mode: "onChange" });

  let applyToAll = watch("applyToAll");
  const [cell, setCell] = React.useState();
  //modal
  const [open, setOpen] = React.useState(false);

  const [saveBtnFlag, setSaveBtnFlag] = React.useState(false);

  const [newSchedule, setNewSchedule] = React.useState({
    id: null,
    scheduleTitle: "",
    newTime: "",
  });

  const [isDisabled, setIsDisabled] = React.useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const outerHeaders = Object.keys(props.data[0]);
  let filterHeader = props.data[0]?.timeDto;

  // let tempHeader = props.data.result.filter((val) => val?.timeDto !== null);
  // let pushHeader = [];

  // filterHeader?.length > 0 &&
  // filterHeader.map((val) => {
  // let valHeader = Object.keys(val);
  // pushHeader.push(valHeader[0]);
  // });
  // let scheduleHeaders = []
  // for(let scheduleObj of filterHeader){
  // let tempArray = Object.keys(scheduleObj);
  // for (let propertName of tempArray){
  // if(propertName !== "ID"){
  // scheduleHeaders.push(propertName)
  // }
  // }
  // }

  //

  // const finalHeaders = [...outerHeaders, ...scheduleHeaders];
  // const headers = removeHeaders(finalHeaders, ["id", "timeDto", "ID"]);

  const headers = Array.from(
    new Set([
      "date",
      ...props.data
        .flatMap((row) => row.timeDto || [])
        .flatMap((timeSlot) =>
          Object.keys(timeSlot).filter((key) => key !== "ID")
        ),
    ])
  );

  //use Effect to update the table
  useEffect(() => {
    let clonedData = structuredClone(props.data);
    if (
      newSchedule.id !== "" &&
      newSchedule.scheduleTitle !== "" &&
      newSchedule.newTime !== ""
    ) {
      setIsDisabled(false);
      // if (props.editRow === true) {
      // clonedData = clonedData.map((singleObj) => {
      // for (let newSlotObj of singleObj.timeDto) {
      // if (newSchedule.id === newSlotObj.ID || newSlotObj.id) {
      // // singleObj.timeDto is an array
      // singleObj.timeDto = singleObj.timeDto.map((scheduleObj) => {
      // // Check whether the given object has the required scheduleTitle
      // if (scheduleObj.hasOwnProperty(newSchedule.scheduleTitle)) {
      // scheduleObj[newSchedule.scheduleTitle] = newSchedule.newTime;
      // }
      // return scheduleObj; // Return the modified or original object
      // });
      // }
      // }
      // return singleObj; // Return the modified or original object
      // });
      // } else {
      clonedData = clonedData.map((singleObj) => {
        if (newSchedule.id === singleObj.id) {
          // singleObj.timeDto is an array
          singleObj.timeDto = singleObj.timeDto.map((scheduleObj) => {
            // Check whether the given object has the required scheduleTitle
            if (scheduleObj.hasOwnProperty(newSchedule.scheduleTitle)) {
              scheduleObj[newSchedule.scheduleTitle] = newSchedule.newTime;
            }
            return scheduleObj; // Return the modified or original object
          });
        }

        return singleObj; // Return the modified or original object
      });
      // }
    }

    // Update the UI of the table by modifying the state variable
    if (saveBtnFlag === true) {
      props.setDrugScheduleData(clonedData);
    }
  }, [newSchedule, saveBtnFlag]);

  //useEffect to modify the entire data in the column of the table
  useEffect(() => {
    if (!isDisabled && applyToAll) {
      let clonedData = structuredClone(props.data);
      if (
        newSchedule.id !== "" &&
        newSchedule.scheduleTitle !== "" &&
        newSchedule.newTime !== ""
      ) {
        clonedData = clonedData.map((singleObj) => {
          // singleObj.timeDto is an array
          singleObj.timeDto = singleObj.timeDto.map((scheduleObj) => {
            // check whether the given object has the required scheduleTitle
            if (scheduleObj.hasOwnProperty(newSchedule.scheduleTitle)) {
              scheduleObj[newSchedule.scheduleTitle] = newSchedule.newTime;
            }
            return scheduleObj; // Return the modified or original object
          });
          return singleObj; // Return the modified or original object
        });
      }
      // Update the UI of the table by modifying the state variable
      if (saveBtnFlag) {
        props.setDrugScheduleData(clonedData);
      }
    }
  }, [applyToAll, isDisabled, saveBtnFlag, newSchedule, props.data]);

  // finalMlcDto.reportingTime = format(
  // data.reportingTime,
  // "yyyy-MM-dd'T'HH:mm:ss"
  // );

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", border: "1px solid lightgray" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-72"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold w-full flex items-center space-x-2 text-sm capitalize">
                          {header !== "date" && (
                            <EditIcon className="ml-2 cursor-pointer" />
                          )}
                          <span>{header}</span>
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow key={index} className="w-full">
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell className="whitespace-nowrap" key={i}>
                              {header === "date" ? (
                                <span className="text-xs">{row[header]}</span>
                              ) : (
                                <Tooltip title="Edit">
                                  <div
                                    type="button"
                                    key={i}
                                    className="cursor-pointer flex space-x-1 items-center p-1 "
                                    colSpan={row.timeDto?.length}
                                  >
                                    <div
                                      key={i}
                                      className="border border-customBlue rounded px-2 text-xs"
                                    >
                                      {row.timeDto?.map((singleObj, j) => (
                                        <span
                                          key={j}
                                          className="flex items-center"
                                        >
                                          {singleObj["Time Slot " + (j + 1)] ===
                                          "-" ? (
                                            ""
                                          ) : (
                                            <button
                                              className="m-0 "
                                              key={j}
                                              type="button"
                                              onClick={(e) => {
                                                setNewSchedule(
                                                  (previousState) => {
                                                    return {
                                                      ...previousState,
                                                      id: row.id,
                                                      scheduleTitle: header,
                                                      newTime: "",
                                                    };
                                                  }
                                                );

                                                props.displayView(row, index);
                                                setCell(
                                                  `2020-01-01 ${e.target.innerText}`
                                                );
                                                setValue("applyToAll", false);
                                                setIsDisabled(true);
                                                setSaveBtnFlag(false);
                                                handleOpen();
                                                if (
                                                  props.userActions?.length > 0
                                                ) {
                                                  for (let obj of props.userActions) {
                                                    if (
                                                      obj.action === "Create"
                                                    ) {
                                                      props.setPrivilege(
                                                        "Create"
                                                      );
                                                    }
                                                  }
                                                }
                                              }}
                                            >
                                              {singleObj[header]}
                                            </button>
                                          )}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </Tooltip>
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <div className="flex items-center space-x-3 justify-end my-1">
          {/* <ResetButton /> */}
          <CommonButton
            label="Save"
            className="bg-customGreen text-white "
            type="submit"
          />
        </div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Style} className=" h-auto max-w-[60%] p-4 ">
              <div>
                <CancelPresentationIconButton
                  className=""
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>

              <div className="row mt-7">
                {/* Time picker component */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    label=" To Time"
                    name="toTime"
                    value={cell}
                    onChange={(newValue) => {
                      console.log("newValue", newValue);
                      let dateStr = newValue === null ? new Date() : newValue;
                      let date = new Date(dateStr);
                      let hours = date.getHours();
                      let minutes = date.getMinutes();
                      console.log("hours", isNaN(hours), minutes);
                      let ampm;
                      if (hours >= 12) {
                        ampm = "PM";
                      } else {
                        ampm = "AM";
                      }
                      hours = hours % 12;
                      if (hours < 10) {
                        hours = "0" + hours;
                      }
                      if (minutes < 10) {
                        minutes = "0" + minutes;
                      }
                      let timeStr = `${hours}:${minutes}` + ` ${ampm}`;
                      let timeStrDefault = `00:00` + ` ${ampm}`;
                      console.log("timeStr", timeStr, timeStrDefault);
                      setCell(newValue);
                      setNewSchedule((previousState) => {
                        return {
                          ...previousState,
                          newTime: !isNaN(hours) ? timeStr : timeStrDefault,
                        };
                      });
                      isNaN(hours) &&
                        errorAlert("Kindly Select Correct Time Slot !");
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="bg-white"
                        fullWidth
                        size="small"
                        {...params}
                        sx={{
                          svg: { color: "#0B83A5" },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="flex items-center justify-between mt-2 space-x-3">
                <fieldset disabled={isDisabled}>
                  <CheckBoxField
                    control={control}
                    name="applyToAll"
                    label="Apply To All"
                    placeholder="Apply To All"
                    style={isDisabled ? { color: "gray" } : null}
                  />
                </fieldset>
                <div className="flex space-x-3">
                  <CancelButton
                    onClick={() => {
                      handleClose();
                    }}
                  />
                  <SaveScheduleButton
                    onClick={() => {
                      setSaveBtnFlag(true);
                      handleClose();
                    }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default React.memo(DrugSchedulTable);
