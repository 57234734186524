import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import TimePickerFieldNew from "../../../../common/components/FormFields/TimePickerFieldNew";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { format } from "date-fns";
import { fetchAntibiotics } from "../../../services/otDetailsServices/OtDetailsServices";
import { OtDetailsContext } from "./OtDetails";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";

// Validation Schema
const schema = yup.object().shape({
  antibioticGivenAtWard: yup.object().required("Required"),
  antibioticGivenAtWardTime: yup
    .date()
    .required("Required")
    .typeError("Invalid time"),
  antibioticGivenAtOt: yup.object(),
  antibioticGivenAtOtTime: yup.date().typeError("Invalid time"),
});

const AntibioticGiven = () => {
  const {
    antibioticAtWardArr,
    setAntibioticAtWardArr,
    antibioticAtOtArr,
    setAntibioticAtOtArr,
  } = useContext(OtDetailsContext);

  const {
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      antibioticGivenAtWard: null,
      antibioticGivenAtWardTime: new Date(),
      antibioticGivenAtOt: null,
      antibioticGivenAtOtTime: new Date(),
    },
  });

  const [antibiotcsArr, setAntibiotcsArr] = React.useState([]);

  useEffect(() => {
    fetchAntibiotics()
      .then((response) => response.data)
      .then((res) => {
        setAntibiotcsArr(res.result);
      });
  }, []);

  const validateAndAdd = (type) => {
    // Get values
    const antibiotic = getValues(
      type === "ward" ? "antibioticGivenAtWard" : "antibioticGivenAtOt"
    );
    const time = getValues(
      type === "ward" ? "antibioticGivenAtWardTime" : "antibioticGivenAtOtTime"
    );

    // Clear previous errors
    clearErrors([
      type === "ward" ? "antibioticGivenAtWard" : "antibioticGivenAtOt",
      type === "ward" ? "antibioticGivenAtWardTime" : "antibioticGivenAtOtTime",
    ]);

    // Validate and add
    if (antibiotic && time) {
      const antibioticObj = {
        antibioticId: antibiotic.id,
        "Antibiotic Name": antibiotic.label,
        time: format(time, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
        Time: format(time, "dd/MM/yyyy HH:mm a"),
      };

      if (type === "ward") {
        setAntibioticAtWardArr([...antibioticAtWardArr, antibioticObj]);
        setValue("antibioticGivenAtWard", null);
        setValue("antibioticGivenAtWardTime", new Date());
      } else {
        setAntibioticAtOtArr([...antibioticAtOtArr, antibioticObj]);
        setValue("antibioticGivenAtOt", null);
        setValue("antibioticGivenAtOtTime", new Date());
      }
    } else {
      if (!antibiotic) {
        setError(
          type === "ward" ? "antibioticGivenAtWard" : "antibioticGivenAtOt",
          {
            type: "manual",
            message: "Required",
          }
        );
      }
      if (!time) {
        setError(
          type === "ward"
            ? "antibioticGivenAtWardTime"
            : "antibioticGivenAtOtTime",
          {
            type: "manual",
            message: "Required",
          }
        );
      }
    }
  };

  console.log(antibioticAtOtArr);
  

 const renderActionWard = (row, index) => {
   const handleDelete = (row) => {
     setAntibioticAtWardArr((prev) =>
       prev.filter((item) => item.antibioticId !== row.antibioticId)
     );
   };

   return (
     <>
       <DeleteOnIcon onClick={() => handleDelete(row)} />
     </>
   );
 };

 const renderActionOt = (row, index) => {
   const handleDelete = (row) => {
     setAntibioticAtOtArr((prev) =>
       prev.filter((item) => item.antibioticId !== row.antibioticId)
     );
   };

   return (
     <>
       <DeleteOnIcon onClick={() => handleDelete(row)} />
     </>
   );
 };


  return (
    <div>
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
        <div>
          <div className="mt-2 text-gray-700 font-medium whitespace-nowrap">
            Antibiotics At Ward
          </div>
          <div className="grid grid-cols-5 gap-3 mt-2">
            <div className="col-span-2">
              <DropdownField
                control={control}
                name="antibioticGivenAtWard"
                placeholder="Antibiotic Given At Ward"
                isSearchable={false}
                dataArray={antibiotcsArr}
                error={errors.antibioticGivenAtWard}
              />
            </div>
            <div className="col-span-2">
              <TimePickerFieldNew
                control={control}
                name="antibioticGivenAtWardTime"
                label=" Time"
                value={new Date()}
                error={errors.antibioticGivenAtWardTime}
              />
            </div>
            <div>
              <CommonButton
                label={"Add"}
                className={"bg-customBlue text-white"}
                onClick={() => validateAndAdd("ward")}
              />
            </div>
          </div>
          {antibioticAtWardArr.length > 0 && (
            <CommonDynamicTableNew
              dataResult={antibioticAtWardArr}
              removeHeaders={["antibioticId", "time"]}
              renderActions={renderActionWard}
            />
          )}
        </div>
        <div>
          <div className="mt-2 text-gray-700 font-medium whitespace-nowrap">
          Antibiotics At OT
          </div>

          <div className="grid grid-cols-5 gap-3 mt-2">
            <div className="col-span-2">
              <DropdownField
                control={control}
                name="antibioticGivenAtOt"
                placeholder="Antibiotic Given At Ot"
                isSearchable={false}
                dataArray={antibiotcsArr}
                error={errors.antibioticGivenAtOt}
              />
            </div>
            <div className="col-span-2">
              <TimePickerFieldNew
                control={control}
                name="antibioticGivenAtOtTime"
                label=" Time"
                value={new Date()}
                error={errors.antibioticGivenAtOtTime}
              />
            </div>
            <div>
              <CommonButton
                label={"Add"}
                className={"bg-customBlue text-white"}
                onClick={() => validateAndAdd("ot")}
              />
            </div>
          </div>
          {antibioticAtOtArr.length > 0 && (
            <CommonDynamicTableNew
              dataResult={antibioticAtOtArr}
              removeHeaders={["antibioticId", "time"]}
              renderActions={renderActionOt}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AntibioticGiven;
