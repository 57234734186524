// import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import {
//   addNewDivaTool,
//   getDivaToolList,
//   patientInfo,
//   searchPatientInformation,
// } from "../../services/cliinicalChartServices/divatoolservices/DivatoolServices";
// import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
// import DivaToolTable from "./DivaToolTable";
// import { format } from "date-fns";
// import ConfirmationModal from "../../../common/components/ConfirmationModal";
// import {
//   errorAlert,
//   successAlert,
//   warningAlert,
// } from "../../../common/components/Toasts/CustomToasts";
// import ViewDivaTool from "./ViewDivaTool";
// import CommonButton from "../../../common/components/Buttons/CommonButton";
// import { useLocation } from "react-router-dom";
// import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
// import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

// export default function DivaTool() {
//   const defaultValues = {
//     patientSearch: null,
//     cannulationDate: new Date(),
//   };

//   const { control, handleSubmit, setValue, watch, reset } = useForm({
//     mode: "onChange",
//     defaultValues,
//   });

//   const [options, setOptions] = useState([]);
//   const [patientInform, setPatientInform] = useState(null);
//   const [divalToolData, setDivaToolData] = useState([]);
//   const [finalData, setFinalData] = useState([]);
//   const [openPost, setOpenPost] = useState(false);
//   const [divaScore, setDivaScore] = useState(0);
//   const [viewOpen, setViewOpen] = useState(false);
//   const handleViewOpen = () => setViewOpen(true);
//   const handleViewClose = () => setViewOpen(false);
//   const [resetFlag, setResetFlag] = useState(false);
//   const [userActions, setUserActions] = useState([]);
//   const [privilege, setPrivilege] = useState("");
//   const handleClosePost = () => {
//     if (openPost) {
//       setOpenPost(false);
//     }
//   };
//   let cannulationDate = watch("cannulationDate");
//   let patientSearch = watch("patientSearch");
//   const location = useLocation();

//   const callDivaToolTableApi = () => {
//     getDivaToolList()
//       .then((response) => {
//         return response.data;
//       })
//       .then((res) => {
//         setDivaToolData(res.result);
//       });
//   };

//   const handleChange = (searchString) => {
//     let PatientType = 1;
//     if (searchString !== "") {
//       searchPatientInformation(searchString, PatientType)
//         .then((response) => response.data)
//         .then((res) => {
//           setOptions(res.result);
//         });
//     }
//   };

//   function postDivaTool(obj) {
//     addNewDivaTool(obj)
//       .then((response) => {
//         if (response.data.statusCode === 200) {
//           successAlert(response.data.message);
//           callDivaToolTableApi();
//           setValue("patientSearch", null);
//           setDivaScore(0);
//           reset(defaultValues);
//           setResetFlag(true);
//         }
//       })
//       .catch((error) => {
//         errorAlert(error.response.data.message || error.message);
//         handleClosePost();
//       });
//   }

//   function addRecord() {
//     setOpenPost(false);
//     postDivaTool(finalData);
//     setResetFlag(false);
//   }

//   const onSubmitDataHandler = () => {
//     setResetFlag(false);
//     let useInfoObj = JSON.parse(localStorage.getItem("userInfo"));

//     if (patientSearch === null) {
//       setOpenPost(false);
//       warningAlert("Please Select Patient...!");
//     } else if (patientSearch !== null) {
//       let postObj = {
//         addedBy: useInfoObj.userId,
//         admissionId: patientSearch?.AdmissionId,
//         cannulationDate: format(cannulationDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
//         divaScore: divaScore,
//         patientId: patientSearch?.id,
//         menuId: location?.state?.menuId,
//         privilege: privilege,
//       };

//       let divaToolDetailsArray = [];
//       for (let divaToolObj of divalToolData) {
//         for (let divaToolParaDetailDtoObj of divaToolObj.divaToolParaDetailDtoList) {
//           let requirObj = {};

//           requirObj.dtParameterDetailsId = divaToolParaDetailDtoObj.detailId;
//           requirObj.isNo = divaToolParaDetailDtoObj.isNo
//             ? divaToolParaDetailDtoObj.isNo
//             : 0;
//           requirObj.isYes = divaToolParaDetailDtoObj.isYes
//             ? divaToolParaDetailDtoObj.isYes
//             : 0;
//           requirObj.noScoreValue = divaToolParaDetailDtoObj.noScoreValue;
//           requirObj.remark = divaToolParaDetailDtoObj.remark
//             ? divaToolParaDetailDtoObj.remark
//             : "";
//           requirObj.yesScoreValue = divaToolParaDetailDtoObj.yesScoreValue;
//           divaToolDetailsArray.push(requirObj);
//         }
//       }
//       postObj.divaToolDetailsDtoList = divaToolDetailsArray;
//       setOpenPost(true);
//       setFinalData(postObj);
//     }
//   };

//   useEffect(() => {
//     location?.state?.menuId &&
//       fetchUserActionsByMenuId(location?.state?.menuId)
//         .then((response) => response.data)
//         .then((res) => {
//           setUserActions(res.result);
//         });

//     callDivaToolTableApi();
//   }, []);

//   useEffect(() => {
//     if (patientSearch?.AdmissionId !== null) {
//       patientInfo(patientSearch?.AdmissionId)
//         .then((response) => response.data)
//         .then((res) => {
//           setPatientInform(res.result);
//         });
//     }
//   }, [patientSearch]);

//   useEffect(() => {
//     if (divalToolData) {
//       let divaScoreVal = 0;
//       for (let divaObject of divalToolData) {
//         for (let obj of divaObject.divaToolParaDetailDtoList) {
//           if (obj.isYes === 1 || obj.isNo === 1) {
//             divaScoreVal = Number(divaScoreVal) + Number(obj.sumValue);
//             setDivaScore(divaScoreVal);
//           } else if (obj.isYes === 0 || obj.isNo === 0) {
//             divaScoreVal = Number(divaScoreVal) - Number(obj.sumValue);
//             setDivaScore(divaScoreVal);
//           }
//         }
//       }
//     }
//   }, [divalToolData]);

//   return (
//     <div className="pt-14 px-3">
//       <span className="grid justify-center text-center text-black font-bold text-xl ">
//         DivaTool
//       </span>
//       <form onSubmit={handleSubmit(onSubmitDataHandler)}>
//         <div className="w-full flex justify-between xl:grid xl:grid-cols-4 items-center gap-3">
//           <div className="xl:col-span-1">
//             <SearchDropdown
//               name="patientSearch"
//               control={control}
//               searchIcon={true}
//               placeholder="Search By Patient Name / MR.No."
//               handleInputChange={handleChange}
//               isClearable={true}
//               dataArray={options}
//             />
//           </div>

//           <div></div>
//           <div></div>
//           <div className="flex justify-end">
//             {userActions.map((obj) => (
//               <>
//                 {!obj.isAction && obj.action === "View" ? (
//                   <>
//                     <CommonButton
//                       type="button"
//                       className="bg-customBlue text-white"
//                       label="View"
//                       onClick={() => {
//                         handleViewOpen();
//                       }}
//                     />
//                   </>
//                 ) : null}
//               </>
//             ))}
//           </div>
//         </div>

//         <div
//           className="md:grid border border-gray-200 my-2 py-2 bg-gray-100  md:grid-cols-[9%_1%_20%_7%_1%_15%_7%_1%_10%_10%_1%_20%]
//         whitespace-nowrap text-sm rounded  px-4 "
//         >
//           <div className="font-semibold ">Patient Name</div>
//           <div>:</div>
//           <div>
//             {patientSearch !== null ? patientInform?.PatientName : null}
//           </div>
//           <div className="font-semibold ">Age</div>
//           <div>:</div>
//           <div>{patientSearch !== null ? patientInform?.AgeYear : null}</div>
//           <div className="font-semibold ">Gender</div>
//           <div>:</div>
//           <div>{patientSearch !== null ? patientInform?.Gender : null}</div>
//           <div className="font-semibold ">Doctor Name</div>
//           <div>:</div>
//           <div>{patientSearch !== null ? patientInform?.DoctorName : null}</div>
//         </div>

//         <div className="flex items-center justify-between py-1 gap-2">
//           <div className="flex gap-2 items-center">
//             <span className="text-sm font-semibold whitespace-nowrap">
//               Date of IV Cannulation :
//             </span>
//             <div className="w-[40%] bg-white">
//               <DatePickerFieldNew
//                 control={control}
//                 name="cannulationDate"
//                 label="From Date"
//                 value={new Date()}
//                 onChange={(newValue) => {
//                   setValue("cannulationDate", newValue);
//                 }}
//                 disablePast={false}
//                 disableFuture={false}
//                 inputFormat="dd-MM-yyyy"
//               />
//             </div>
//           </div>

//           <div className="flex gap-2 mr-4">
//             <span className="font-semibold pt-1">DIVA Score : </span>
//             <span className="font-bold text-lg">{divaScore}</span>
//           </div>
//         </div>

//         <DivaToolTable
//           setDivaToolData={setDivaToolData}
//           divalToolData={divalToolData}
//           setDivaScore={setDivaScore}
//           resetFlag={resetFlag}
//           setResetFlag={setResetFlag}
//         />
//         <hr className="border-1 pt-1  " />

//         <div className="grid grid-cols-[5%_1%_20%_9%_1%_20%_9%_1%_20%] whitespace-nowrap text-lg rounded px-4">
//           <div className="font-semibold ">Mild</div>
//           <div className="font-semibold ">:</div>
//           <div>0 - 3 Assigned nurse</div>
//           <div className="font-semibold ">Moderate</div>
//           <div className="font-semibold ">:</div>
//           <div>4 - 6 Assigned senior nurse</div>
//           <div className="font-semibold ">Severe</div>
//           <div className="font-semibold ">:</div>
//           <div>{">"} 7 Call IV team with use of technology</div>
//         </div>

//         <div className="flex gap-2 justify-end">
//           {userActions.map((obj) => (
//             <>
//               {!obj.isAction && obj.action === "Create" ? (
//                 <>
//                   <CommonButton
//                     type="button"
//                     className="border border-customRed text-customRed"
//                     label="Reset"
//                     onClick={() => {
//                       reset(defaultValues);
//                       reset(defaultValues);
//                       setDivaScore(0);
//                       setResetFlag(true);
//                     }}
//                   />
//                   <CommonButton
//                     type="submit"
//                     className="bg-customGreen text-white"
//                     label="Save"
//                     onClick={() => {
//                       setPrivilege(obj.action);
//                     }}
//                   />
//                 </>
//               ) : null}
//             </>
//           ))}
//         </div>
//       </form>
//       <ConfirmationModal
//         confirmationOpen={openPost}
//         confirmationHandleClose={handleClosePost}
//         confirmationSubmitFunc={addRecord}
//         confirmationLabel="Confirmation"
//         confirmationMsg="Are you sure want to add this record ?"
//         confirmationButtonMsg="Add"
//       />
//       {viewOpen ? (
//         <ViewDivaTool
//           viewOpen={viewOpen}
//           handleViewClose={handleViewClose}
//           setDivaToolData={setDivaToolData}
//           divalToolData={divalToolData}
//           // setState={setState}
//         />
//       ) : null}
//     </div>
//   );
// }

import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  addNewDivaTool,
  getDivaToolDetails,
  getDivaToolList,
  patientInfo,
  searchPatientInformation,
} from "../../services/cliinicalChartServices/divatoolservices/DivatoolServices";
import DivaToolTable from "./DivaToolTable";
import ViewDivaTool from "./ViewDivaTool";
import { Tooltip } from "@mui/material";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";
// import DivaToolPrint from "../../prints/DivaToolPrint";

export default function DivaTool(props) {
  let useInfoObj = JSON.parse(localStorage.getItem("userInfo"));

  const defaultValues = {
    patientSearch: null,
    cannulationDate: new Date(),
  };

  const { control, handleSubmit, setValue, watch, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [options, setOptions] = useState([]);
  const [patientInform, setPatientInform] = useState(null);
  const [divalToolData, setDivaToolData] = useState([]);
  const [selectDivaToolViewRow, setSelectDivaToolViewRow] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [divaScore, setDivaScore] = useState(0);
  const [viewOpen, setViewOpen] = useState(false);
  const handleViewClose = () => setViewOpen(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const location = useLocation();
  const [cannulationDate, patientSearch] = watch([
    "cannulationDate",
    "patientSearch",
  ]);

  const callDivaToolTableApi = () => {
    getDivaToolList(
      selectDivaToolViewRow === null ? 0 : selectDivaToolViewRow?.Id
    )
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setDivaToolData(res.result);
      })
      .catch((error) => {});
  };

  const handleChange = (searchString) => {
    let PatientType = 1;
    if (searchString !== "") {
      searchPatientInformation(searchString, PatientType)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  function postDivaTool(obj) {
    addNewDivaTool(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setValue("patientSearch", null);
          setDivaScore(0);
          reset(defaultValues);
          setResetFlag(true);
          callDivaToolTableApi();
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }

  function addRecord() {
    postDivaTool(finalData);
    setOpenPost(false);
    setResetFlag(false);
    props.setOpenDivatool(false);
  }

  const onSubmitDataHandler = () => {
    setResetFlag(false);
    setOpenPost(false);
    let postObj = {
      addedBy: useInfoObj.userId,
      admissionId: patientSearch?.AdmissionId
        ? patientSearch?.AdmissionId
        : props.selectedPatient?.AdmissionId,
      cannulationDate: format(cannulationDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      divaScore: divaScore,
      patientId: patientInform?.PatientId
        ? patientInform?.PatientId
        : props.patientInformation?.PatientId,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };

    let divaToolDetailsArray = [];
    for (let divaToolObj of divalToolData) {
      for (let divaToolParaDetailDtoObj of divaToolObj.divaToolParaDetailDtoList) {
        let requirObj = {};

        requirObj.dtParameterDetailsId = divaToolParaDetailDtoObj.detailId;
        requirObj.isNo = Number(divaToolParaDetailDtoObj.isNo)
          ? Number(divaToolParaDetailDtoObj.isNo)
          : 0;
        requirObj.isYes = divaToolParaDetailDtoObj.isYes
          ? divaToolParaDetailDtoObj.isYes
          : 0;
        requirObj.noScoreValue = divaToolParaDetailDtoObj.noScoreValue;
        requirObj.remark = divaToolParaDetailDtoObj.remark
          ? divaToolParaDetailDtoObj.remark
          : "";
        requirObj.yesScoreValue = divaToolParaDetailDtoObj.yesScoreValue;
        divaToolDetailsArray.push(requirObj);
      }
    }
    postObj.divaToolDetailsDtoList = divaToolDetailsArray;
    if (patientSearch !== null || props.patientInfo !== undefined) {
      setOpenPost(true);
      setFinalData(postObj);
    } else if (props.patientInfo === undefined) {
      errorAlert("Please Select Patient First!");
    }

    //props.setOpenDivatool(true);
    // }
  };

  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(props.menuId || location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    callDivaToolTableApi();
  }, [selectDivaToolViewRow]);

  useEffect(() => {
    if (patientSearch !== null) {
      patientInfo(patientSearch?.AdmissionId)
        .then((response) => response.data)
        .then((res) => {
          setPatientInform(res.result);
        });
    }
  }, [patientSearch]);

  useEffect(() => {
    if (divalToolData) {
      let divaScoreVal = 0;
      for (let divaObject of divalToolData) {
        for (let obj of divaObject.divaToolParaDetailDtoList) {
          if (obj.isYes === 1 || obj.isNo === 1) {
            divaScoreVal = Number(divaScoreVal) + Number(obj.sumValue);
            setDivaScore(divaScoreVal);
          } else if (obj.isYes === 0 || obj.isNo === 0) {
            divaScoreVal = Number(divaScoreVal) - Number(obj.sumValue);
            setDivaScore(divaScoreVal);
          }
        }
      }
    }
  }, [divalToolData]);

  return (
    <div className={`${props.openDivatool ? "" : "pt-14 px-3"}`}>
      <span className="grid justify-center text-center text-black font-bold text-xl ">
        DivaTool
      </span>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="w-full flex justify-between xl:grid xl:grid-cols-4 items-center gap-3">
          <div className="xl:col-span-1">
            <SearchDropdown
              name="patientSearch"
              control={control}
              searchIcon={true}
              placeholder="Search By Patient Name / MR.No."
              handleInputChange={handleChange}
              isClearable={true}
              dataArray={options}
              isDisabled={
                selectDivaToolViewRow !== null
                  ? true
                  : false || props.patientInfo !== undefined
                  ? true
                  : false
              }
            />
          </div>

          <div></div>
          <div></div>
          <div className="flex justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "View" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="View"
                      onClick={() => {
                        setViewOpen(true);
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>

        <div
          className="md:grid border border-gray-200 my-2 py-2 bg-gray-100  md:grid-cols-[9%_1%_20%_7%_1%_15%_7%_1%_10%_10%_1%_20%] 
        whitespace-nowrap text-sm rounded  px-4 "
        >
          <div className="font-semibold ">Patient Name</div>
          <div>:</div>
          <div>
            {patientSearch !== null
              ? patientInform?.PatientName
              : selectDivaToolViewRow
              ? selectDivaToolViewRow?.["Patient Name"]
              : props.selectedPatient?.PatientName}
          </div>
          <div className="font-semibold ">Age</div>
          <div>:</div>
          <div>
            {patientSearch !== null
              ? patientInform?.AgeYear
              : selectDivaToolViewRow
              ? selectDivaToolViewRow?.AgeYear
              : props.selectedPatient?.AgeYear}
          </div>
          <div className="font-semibold ">Gender</div>
          <div>:</div>
          <div>
            {patientSearch !== null
              ? patientInform?.Gender
              : selectDivaToolViewRow
              ? selectDivaToolViewRow?.Gender
              : props.selectedPatient?.Gender}
          </div>
          <div className="font-semibold ">Doctor Name</div>
          <div>:</div>
          <div>
            {patientSearch !== null
              ? patientInform?.DoctorName
              : selectDivaToolViewRow
              ? selectDivaToolViewRow?.DoctorName
              : props.selectedPatient?.DoctorName}
          </div>
        </div>

        <div className="flex items-center justify-between py-1 gap-2">
          <div className="flex gap-2 items-center">
            <span className="text-sm font-semibold whitespace-nowrap">
              Date of IV Cannulation :
            </span>
            <div className="w-[40%] bg-white">
              <DatePickerFieldNew
                control={control}
                name="cannulationDate"
                label="From Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("cannulationDate", newValue);
                }}
                disablePast={false}
                disableFuture={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
          </div>

          <div className="flex gap-2 mr-4 items-center">
            <span className="font-semibold pt-1">DIVA Score : </span>
            <span className="font-bold text-lg pt-1">{divaScore}</span>
          </div>
        </div>

        <fieldset>
          <DivaToolTable
            setDivaToolData={setDivaToolData}
            divalToolData={divalToolData}
            setDivaScore={setDivaScore}
            resetFlag={resetFlag}
            setResetFlag={setResetFlag}
          />
        </fieldset>

        <hr className="border-1 pt-1  " />

        <div className="flex space-x-4 2xl:space-x-20 text-sm px-2">
          <p className="flex space-x-3">
            <span className="font-semibold">Mild &nbsp;&nbsp;&nbsp; :</span>
            <span className="">0 - 3 Assigned nurse</span>
          </p>
          <p className="flex space-x-3  ">
            <span className="font-semibold">Moderate &nbsp;&nbsp;&nbsp; :</span>
            <span className="">4 - 6 Assigned senior nurse</span>
          </p>
          <p className="flex space-x-3  ">
            <span className="font-semibold">Severe &nbsp;&nbsp;&nbsp; :</span>
            <span className="">
              {">"} 7 Call IV team with use of technologye
            </span>
          </p>
        </div>

        <div className="flex gap-2 justify-end">
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <CommonButton
                    type="button"
                    className="border border-customRed text-customRed"
                    label="Reset"
                    onClick={() => {
                      setDivaToolData([]);
                      reset(defaultValues);
                      setDivaScore(0);
                      setSelectDivaToolViewRow(null);
                      setResetFlag(true);
                      callDivaToolTableApi();
                    }}
                  />
                  <CommonButton
                    type="submit"
                    className="bg-customGreen text-white"
                    label="Save"
                    onClick={() => {
                      setPrivilege(obj.action);
                    }}
                  />
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
      {viewOpen ? (
        <ViewDivaTool
          patientInformation={props.patientInformation}
          fromClinicalChart={props.fromClinicalChart}
          viewOpen={viewOpen}
          handleViewClose={handleViewClose}
          setDivaToolData={setDivaToolData}
          divalToolData={divalToolData}
          selectDivaToolViewRow={selectDivaToolViewRow}
          setSelectDivaToolViewRow={setSelectDivaToolViewRow}
          setDivaScore={setDivaScore}
        />
      ) : null}
    </div>
  );
}
