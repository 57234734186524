import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Checkbox, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import { ModalStyle } from "../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonDivider from "../../../common/components/divider/CommonDivider";
import {
  cancelDrug,
  updateDrugHoldUnHoldStatus,
  updateDrugInstruction,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import DrugAdministratorGivenByModal from "./DrugAdministratorGivenByModal";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { CapitalizeStatement } from "../../../common/components/Custom Hooks/CapitalizeStatement";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    background: "#F1F1F1",
    position: "sticky",
    top: "0px",
    left: "0px",
    zIndex: 50,
  },
}));

function DrugAdministratorDetailsModal(props) {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Given: false,
      holdDrug: false,
      instruction: "",
    },
  });
  const { register, control, setValue, watch } = methods;

  // local variable
  //set rows object to table
  const allHeaders = Object.keys(
    props.drugAdmDtlsSlots.result?.length > 0
      ? props.drugAdmDtlsSlots.result[0].timeSlots[0]
      : []
  );
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  const headers = removeHeaders(allHeaders, ["id", "isDelay", "ableToHold"]);
  let instructionValue = watch("instruction");

  // state variables
  const [selectedRowGivenId, setSelectedRowGivenId] = useState(null);
  const [selectedDrugHoldOrUnHoldStatus, setSelectedDrugHoldOrUnHoldStatus] =
    useState(false);
  // given drug
  const [openGivenByModal, setOpenGivenByModal] = useState(false);
  const [holdUserPermision, setHoldUserPermision] = useState(null);

  // hold or unhold
  const [
    confirmationModalForHoldUnHoldDrug,
    setConfirmationModalForHoldUnHoldDrug,
  ] = useState(false);

  // is close drug
  const [openConfirmationModalForIsClose, setOpenConfirmationModalForIsClose] =
    useState(false);

  // save confirmation modal
  const [
    openUpdateInstructionConfirmationModal,
    setOpenUpdateInstructionConfirmationModal,
  ] = useState(false);

  const handleOpenGivenByModal = () => setOpenGivenByModal(true);
  const handleCloseGivenByModal = () => setOpenGivenByModal(false);

  const handleOpenHoldDrugOrUnHold = () =>
    setConfirmationModalForHoldUnHoldDrug(true);

  const handleCloseHoldDrugOrUnHold = () =>
    setConfirmationModalForHoldUnHoldDrug(false);

  const handleOpenIsCloseDrug = () => setOpenConfirmationModalForIsClose(true);
  const handleCloseIsCloseDrug = () =>
    setOpenConfirmationModalForIsClose(false);

  const handleOpenUpdateInstructionConfirmationModal = () =>
    setOpenUpdateInstructionConfirmationModal(true);

  const handleCloseUpdateInstructionConfirmationModal = () =>
    setOpenUpdateInstructionConfirmationModal(false);

  const handleHoldUnHold = () => {
    updateDrugHoldUnHoldStatus(
      selectedDrugHoldOrUnHoldStatus,
      selectedRowGivenId.id,
      props.selectedAdmDrugRowList.DrugAdminHeaderId,
      props.patientInfo?.menuId !== undefined
        ? props.patientInfo.menuId
        : props.menuId,
      props.privilege
    )
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.populateTable();
        props.getDrugList();
        setConfirmationModalForHoldUnHoldDrug(false);
        handleCloseHoldDrugOrUnHold();
        setSelectedRowGivenId(null);
        setSelectedDrugHoldOrUnHoldStatus(false);
        props.setDrugDetailsLoadingSpinner(false);
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        props.setOpenLoader(false);
      });
  };
  const HoldDrugOrUnholdDrug = () => {
    // props.setOpenLoader(true);
    handleHoldUnHold();
    setConfirmationModalForHoldUnHoldDrug(false);
    handleCloseHoldDrugOrUnHold();
  };

  // update instruction
  const updateInstructions = () => {
    // props.setOpenLoader(true);
    updateDrugInstruction(
      props.row?.PrescriptionDetailId,
      CapitalizeStatement(instructionValue),
      props.patientInfo.menuId !== undefined
        ? props.patientInfo.menuId
        : props.menuId,
      props.privilege
    )
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleCloseUpdateInstructionConfirmationModal();
        setSelectedRowGivenId(null);
        props.setDrugDetailsLoadingSpinner(false);
        props.populateTable();
        props.getDrugList();
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.message || error.message);
        props.setOpenLoader(false);
      });
  };

  // is Close Api
  const isCloseDrug = () => {
    // props.setOpenLoader(false);

    cancelDrug(
      selectedRowGivenId.id,
      props.patientInfo.menuId !== undefined
        ? props.patientInfo.menuId
        : props.menuId,
      props.privilege
    )
      .then((res) => {
        successAlert(res.data.message);
        props.setDrugDetailsLoadingSpinner(false);
        handleCloseIsCloseDrug();
        props.populateTable();
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.message || error.message);
        props.setOpenLoader(false);
      });
  };

  const handleGivenByCheckbox = (row, event, index) => {
    if (event.target.checked) {
      handleOpenGivenByModal();
    }
  };

  //
  useEffect(() => {
    let selectedRow = props.tableData.filter(function (e) {
      return (
        e.DrugAdminHeaderId === props.selectedAdmDrugRowList?.DrugAdminHeaderId
      );
    });
    setValue("instruction", selectedRow[0]?.DrugInstruction);
  }, [props]);

  useEffect(() => {
    let holdDrugPermission =
      props.userActions &&
      props.userActions?.find(
        (item) => item?.isAction && item.action === "Hold"
      );
    setHoldUserPermision(holdDrugPermission);
    console.log("holdDrugPermission", holdDrugPermission);
  }, [props.userActions]);

  console.log("123456", props.selectedPatientInfo.BedReleased);

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="h-[80%] w-[80%] p-4 ">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              // reset(defaultValues);
              props.setDrugAdmDtlsSlots([]);
              props.setSelectedRow(null);
            }}
          />

          <fieldset
            disabled={props.row.IsStopDrugAdmin ? true : false}
            onClick={() =>
              props.row.IsStopDrugAdmin && warningAlert("Drug is stopped...")
            }
          >
            <div className="row">
              <h1 className="text-center text-customBlue text-xl font-semibold">
                Drug Administrator Details
              </h1>
              <div className="mt-2">
                <div className="flex space-x-2 items-center w-6/12">
                  <div className="w-full">
                    <InputField
                      control={control}
                      label="Instructions"
                      name="instruction"
                    />
                  </div>
                  <div>
                    {props.userActions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "Create" ? (
                          <>
                            <CommonButton
                              type="button"
                              className="border border-customBlue text-customBlue h-9"
                              label="Change"
                              onClick={() => {
                                if (instructionValue !== "") {
                                  props.setPrivilege(obj.action);
                                  handleOpenUpdateInstructionConfirmationModal();
                                } else {
                                  props.setPrivilege("");
                                  warningAlert("Please Add Instructions!");
                                }
                              }}
                            />
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
                <div className="bg-gray-100 rounded shadow flex justify-between p-4 border mt-2 font-semibold text-gray-600 text-xs 2xl:text-sm">
                  <div className="flex space-x-2 items-center">
                    <h1 className="">Drug Name </h1>
                    <span>:</span>
                    <span>{props.selectedAdmDrugRowList?.["Item Name"]}</span>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <h1 className="">Route </h1>
                    <span>:</span>
                    <span>{props.selectedAdmDrugRowList?.Route}</span>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <h1 className="">Frequency </h1>
                    <span>:</span>
                    <span>{props.selectedAdmDrugRowList?.Frequency}</span>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <h1 className="">Dr. Name </h1>
                    <span>:</span>
                    <span>
                      {props.selectedPatientInfo?.["Doctor Name"] ||
                        props.selectedPatientInfo?.DoctorName}
                    </span>
                  </div>
                </div>
              </div>
              {props.drugDetailsLoadingSpinner === true ? (
                <div className="flex justify-center my-28">
                  <LoadingSpinner />
                </div>
              ) : (
                ""
              )}

              {props.drugDetailsLoadingSpinner === false &&
              props.drugAdmDtlsSlots.result?.length > 0 ? (
                <div className="mt-2">
                  <CommonDivider />
                  <div className="grid w-auto mt-1">
                    <Box sx={{ width: "100%", overflow: "hidden" }}>
                      <Paper sx={{ width: "100%", my: 1 }}>
                        <TableContainer
                          sx={{
                            "&::-webkit-scrollbar": {
                              width: 7,
                              height: 10,
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "#7393B3",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "lightBlue",
                            },
                          }}
                          className="rounded  border-gray-300 py-2"
                        >
                          <Table
                            size="small"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableBody>
                              <TableRow>
                                <StyledTableCell className="whitespace-nowrap border border-gray-300">
                                  <span className="text-gray-600 font-semibold">
                                    Date
                                  </span>
                                </StyledTableCell>
                                {props.drugAdmDtlsSlots.result.map(
                                  (row, index) => {
                                    return (
                                      <TableCell
                                        key={index}
                                        className="whitespace-nowrap border border-gray-300"
                                        sx={{ background: "#F1F1F1" }}
                                        colSpan={row.timeSlots.length}
                                        align="justify"
                                      >
                                        <div className="flex justify-center text-gray-700 font-semibold">
                                          {row.date}
                                        </div>
                                      </TableCell>
                                    );
                                  }
                                )}
                              </TableRow>
                              {headers &&
                                headers.map((header, i) => (
                                  <TableRow key={header}>
                                    <StyledTableCell className="whitespace-nowrap border border-gray-300">
                                      <span className="text-gray-600 font-semibold">
                                        {header}
                                      </span>
                                    </StyledTableCell>
                                    {props.drugAdmDtlsSlots.result.map(
                                      (row, index) => {
                                        return (
                                          <>
                                            {row.timeSlots.map(
                                              (
                                                PatientDrugDetails,
                                                innerIndex
                                              ) => {
                                                const isAbleToHold =
                                                  PatientDrugDetails.ableToHold;

                                                return (
                                                  <TableCell
                                                    key={innerIndex}
                                                    className={`whitespace-nowrap flex justify-center border border-gray-300 ${
                                                      header ===
                                                        "Drug Administrator Time" &&
                                                      PatientDrugDetails.isDelay
                                                        ? "text-customGreen"
                                                        : "text-customBlue"
                                                    }`}
                                                    sx={
                                                      i == 0
                                                        ? {
                                                            background:
                                                              "#F1F1F1",
                                                          }
                                                        : {
                                                            background:
                                                              "#ffffff",
                                                          }
                                                    }
                                                    onClick={(e) => {
                                                      let tempObj = {
                                                        id: PatientDrugDetails.id,
                                                        isDelay:
                                                          PatientDrugDetails.isDelay,
                                                        ableToHold:
                                                          PatientDrugDetails.ableToHold,
                                                        Time: PatientDrugDetails.Time,
                                                        "Click To Administrator Drug":
                                                          e.target.value ===
                                                          "on"
                                                            ? true
                                                            : false,
                                                        "Drug Administrator Date":
                                                          PatientDrugDetails[
                                                            "Drug Administrator Date"
                                                          ],
                                                        "Drug Administrator Time":
                                                          PatientDrugDetails[
                                                            "Drug Administrator Time"
                                                          ],
                                                        "Drug Remark":
                                                          PatientDrugDetails[
                                                            "Drug Remark"
                                                          ],
                                                        "Drug Administrator By":
                                                          PatientDrugDetails[
                                                            "Drug Administrator By"
                                                          ],
                                                        "Click To Hold/UnHold Drug Admin":
                                                          PatientDrugDetails[
                                                            "Click To Hold/UnHold Drug Admin"
                                                          ],
                                                        "Drug Dose":
                                                          PatientDrugDetails[
                                                            "Drug Dose"
                                                          ],
                                                        "Hold/UnHoldBy":
                                                          PatientDrugDetails[
                                                            "Hold/UnHoldBy"
                                                          ],
                                                        Cancel:
                                                          PatientDrugDetails.Cancel,
                                                      };
                                                      setSelectedRowGivenId(
                                                        tempObj
                                                      );
                                                    }}
                                                  >
                                                    <div className="w-fit mx-auto">
                                                      <div className="w-fit mx-auto">
                                                        {header ===
                                                        "Drug Administrator Time" ? (
                                                          <span
                                                            className="font-medium"
                                                            style={{
                                                              color:
                                                                PatientDrugDetails.isDelay
                                                                  ? "#D11A2A"
                                                                  : "#1e3a8a",
                                                            }}
                                                          >
                                                            {
                                                              PatientDrugDetails[
                                                                "Drug Administrator Time"
                                                              ]
                                                            }
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            {header !==
                                                              "CancelSchTime" &&
                                                            header !==
                                                              "Given" &&
                                                            header !==
                                                              "GivenBy" &&
                                                            header !==
                                                              "HoldDrug" &&
                                                            header !==
                                                              "HoldBy" ? (
                                                              <div className="flex justify-center text-gray-500 font-semibold">
                                                                {
                                                                  PatientDrugDetails[
                                                                    header
                                                                  ]
                                                                }
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                      {header ===
                                                      "Click To Administrator Drug" ? (
                                                        <div className="">
                                                          <Checkbox
                                                            type="checkbox"
                                                            className="h-5 w-5"
                                                            name={`Given${innerIndex}`}
                                                            disabled={
                                                              !props
                                                                .selectedPatientInfo
                                                                .BedReleased &&
                                                              PatientDrugDetails.isDelay ===
                                                                true
                                                                ? true
                                                                : false ||
                                                                  PatientDrugDetails[
                                                                    "Click To Hold/UnHold Drug Admin"
                                                                  ] === true
                                                                ? true
                                                                : false
                                                            }
                                                            checked={
                                                              PatientDrugDetails[
                                                                "Click To Administrator Drug"
                                                              ]
                                                            }
                                                            {...register(
                                                              `Given`
                                                            )}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleGivenByCheckbox(
                                                                row,
                                                                event,
                                                                index
                                                              );
                                                            }}
                                                            control={control}
                                                          />
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                    {header === "GivenBy" ? (
                                                      <div></div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {header ===
                                                    "Click To Hold/UnHold Drug Admin" ? (
                                                      <div className="text-center">
                                                        <Checkbox
                                                          name={`holdDrug${innerIndex}`}
                                                          type="checkbox"
                                                          className="h-4 w-4"
                                                          disabled={
                                                            holdUserPermision !==
                                                              undefined &&
                                                            holdUserPermision.action ===
                                                              "Hold" &&
                                                            holdUserPermision.isAction &&
                                                            !props
                                                              .selectedPatientInfo
                                                              .BedReleased &&
                                                            PatientDrugDetails.ableToHold ===
                                                              true
                                                              ? false
                                                              : true
                                                          }
                                                          onChange={(e) => {
                                                            setValue(
                                                              `holdDrug${innerIndex}`,
                                                              PatientDrugDetails[
                                                                "Click To Hold/UnHold Drug Admin"
                                                              ]
                                                            );
                                                            handleOpenHoldDrugOrUnHold();
                                                            setSelectedDrugHoldOrUnHoldStatus(
                                                              e.target.checked
                                                            );
                                                            props.setPrivilege(
                                                              "Hold"
                                                            );
                                                          }}
                                                          checked={
                                                            PatientDrugDetails[
                                                              "Click To Hold/UnHold Drug Admin"
                                                            ]
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {header === "Cancel" ? (
                                                      <div className="text-center ">
                                                        {props.userActions
                                                          ?.length > 0 &&
                                                          props.userActions.map(
                                                            (list) => {
                                                              console.log(
                                                                "list12345",
                                                                props.selectedPatientInfo,
                                                                isAbleToHold
                                                              );
                                                              return (
                                                                <>
                                                                  {list?.action ===
                                                                    "Cancel" && (
                                                                    <button
                                                                      disabled={
                                                                        // !props
                                                                        //   .selectedPatientInfo
                                                                        //   .BedReleased ||
                                                                        isAbleToHold ===
                                                                        false
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      type="button"
                                                                      onClick={() => {
                                                                        handleOpenIsCloseDrug();

                                                                        props.setPrivilege(
                                                                          "Cancel"
                                                                        );
                                                                      }}
                                                                    >
                                                                      <HighlightOffIcon
                                                                        className={
                                                                          !props
                                                                            .selectedPatientInfo
                                                                            .BedReleased &&
                                                                          isAbleToHold ===
                                                                            true
                                                                            ? `text-customRed cursor-pointer`
                                                                            : `text-gray-400`
                                                                        }
                                                                      />
                                                                    </button>
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </TableCell>
                                                );
                                              }
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  </div>
                </div>
              ) : (
                <>
                  {props.drugDetailsLoadingSpinner === false && (
                    <div className="flex justify-center my-28">
                      <h1 className="text-gray-600 text-center">
                        No Records Found
                        <span className="animate-pulse tracking-widest">
                          ...
                        </span>
                      </h1>
                    </div>
                  )}
                </>
              )}
            </div>
          </fieldset>
        </Box>
      </Modal>
      {openGivenByModal ? (
        <DrugAdministratorGivenByModal
          open={openGivenByModal}
          handleClose={handleCloseGivenByModal}
          selectedRow={selectedRowGivenId}
          selectedSlot={props.drugAdmDtlsSlots.result}
          populateTable={props.populateTable}
          setSelectedRow={setSelectedRowGivenId}
          menuId={
            props.patientInfo.menuId !== undefined
              ? props.patientInfo.menuId
              : props.menuId
          }
          privilege={props.privilege}
          setPrivilege={props.setPrivilege}
          userActions={props.userActions}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        confirmationOpen={confirmationModalForHoldUnHoldDrug}
        confirmationHandleClose={handleCloseHoldDrugOrUnHold}
        confirmationSubmitFunc={HoldDrugOrUnholdDrug}
        confirmationLabel="Confirmation"
        confirmationMsg="Do You Really Want To Hold Or UnHold This Drug ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={openConfirmationModalForIsClose}
        confirmationHandleClose={handleCloseIsCloseDrug}
        confirmationSubmitFunc={isCloseDrug}
        confirmationLabel="Confirmation"
        confirmationMsg="Do You Really Want To Cancel Selected Schedule Time ...!!!"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={openUpdateInstructionConfirmationModal}
        confirmationHandleClose={handleCloseUpdateInstructionConfirmationModal}
        confirmationSubmitFunc={updateInstructions}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure Want To Update Instruction Drug ?"
        confirmationButtonMsg="Yes"
      />
    </div>
  );
}
export default DrugAdministratorDetailsModal;
