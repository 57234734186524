import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { CancelOnIcon } from "../../../assets/icons/CustomIcons";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchDepartment,
  fetchEmoloyeeName,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { saveNewComplaint } from "../../services/complaintManagementServices/ComplaintManagementServices";
import ViewImageModal from "../../../common/components/imageViewer/ViewImageModal";
import { fetchCashCounterData } from "../../../login/services/LoginServices.js";
import { getCashCounterOptions } from "../../../UserManagement/Services/UserManagementServices";
// import ViewImageModal from "../../../administration/components/screenSaver/ViewImageModal";

export default function AddNewComplaintModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const { handleClose, menuId, privilege, populateTable } = props;
  //
  const [departments, setDepartments] = useState([]);
  const [toDepartments, setToDepartments] = useState([]);
  const [reportedByEmp, setReportedByEmp] = useState([]);
  const [selectedFromDept, setSelectedFromDept] = useState(null);
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  //
  const [selectedDocuments, setSelectedDocuments] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [base64String, setBase64String] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [openViewImageModal, setOpenViewImageModal] = useState(false);

  //
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "50%",
    width: {
      lg: "55%",
      sm: "80%",
    },
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      complaintDept: null,
      reportedBy: null,
      isEquipmentComplaint: false,
      complaintDescription: "",
      coplaintImg: null,
    },
  });

  const { control, watch, register, setValue } = methods;

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };
  const handleFormSubmit = (data) => {
    const finalObj = {
      complaintDepartments: data?.complaintDept?.map((item) => ({
        departmentId: item.id,
        departmentName: item.label,
      })),
      date: new Date(),
      details: data?.complaintDescription,
      // fromDepartmentId: token?.departmentId,
      fromDepartmentId: data?.complaintFromDept?.id,
      isEquipmentComplaint: 0,
      menuId: menuId,
      photo: base64String,
      imageName: imageName,
      privilege: privilege,
      recNo: 0,
      username: token?.userName,
    };
    saveNewComplaint(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          methods.reset();
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  const getDeprtments = () => {
    //departments are cashcounters for this by NS
    // fetchDepartment(searchString)
    fetchCashCounterData(token?.loginName, token?.unitId)
      .then((response) => response.data)
      .then((res) => {
        setDepartments(res.result);
      });

    getCashCounterOptions()
      .then((response) => response.data)
      .then((res) => {
        setToDepartments(res.result);
      });
  };

  useEffect(() => {
    getDeprtments();
  }, []);

  useEffect(() => {
    fetchEmoloyeeName()
      .then((response) => response.data)
      .then((res) => {
        setReportedByEmp(res.result);
      });
  }, []);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedDocuments(files);

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string
        const imageName = file.name;

        setPreviewUrl(reader.result);
        setBase64String(base64String);
        setImageName(imageName);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setPreviewUrl(null);
    setValue("coplaintImg", null);
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Add New Complaint
            </div>

            <hr className="border mx-1  border-customBlue" />
            <form>
              <div className=" py-2 grid lg:grid-cols-3 md:grid-cols-2 gap-3 mt-3 items-center">
                <div className="">
                  <DropdownField
                    control={control}
                    placeholder="Complaint From/ Department"
                    dataArray={departments}
                    name="complaintFromDept"
                    searchIcon={true}
                    isClearable={true}
                    handleInputChange={getDeprtments}
                    // isMulti={true}
                    maxMenuHeight={250}
                    menuPlacement={"bottom"}
                    inputRef={{
                      ...register("complaintDept", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedFromDept(e.target.value);
                          } else {
                            setSelectedFromDept(null);
                            methods.reset();
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="">
                  <DropdownField
                    control={control}
                    placeholder="Complaint To/ Department"
                    dataArray={toDepartments}
                    name="complaintDept"
                    searchIcon={true}
                    isClearable={true}
                    handleInputChange={getDeprtments}
                    isMulti={true}
                    maxMenuHeight={250}
                    menuPlacement={"bottom"}
                    inputRef={{
                      ...register("complaintDept", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedFromDept(e.target.value);
                          } else {
                            setSelectedFromDept(null);
                            methods.reset();
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="isEquipmentComplaint"
                    label="Is Equipment Complaint/ Transfer"
                  />
                </div>
                <div className="md:col-span-2 lg:col-span-3">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="complaintDescription"
                    label="Complaint Description"
                    placeholder="Complaint Description"
                  />
                </div>
                <div className="md:col-span-2 lg:col-span-1">
                  <DropdownField
                    control={control}
                    // error={errors.MLCType}
                    name="reportedBy"
                    placeholder="Reported By"
                    dataArray={reportedByEmp}
                    isSearchable={false}
                    maxMenuHeight={150}
                    menuPlacement={"bottom"}
                  />
                </div>
                <div>
                  <input
                    type="file"
                    {...register("coplaintImg")}
                    onChange={handleFileChange}
                    // multiple
                    accept=".pdf,.doc,.docx,.jpg, .jpeg, .png, .gif"
                  ></input>
                </div>

                <div className="relative">
                  {previewUrl && (
                    <>
                      <div className="absolute top-2 right-2 cursor-pointer">
                        <CancelOnIcon onClick={handleDeleteImage} />
                      </div>
                      <img
                        src={previewUrl}
                        alt="Image Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          marginTop: "10px",
                        }}
                        onClick={() => setOpenViewImageModal(true)}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                <CommonButton
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {openViewImageModal ? (
        <ViewImageModal
          open={openViewImageModal}
          setOpen={setOpenViewImageModal}
          handleOpen={() => setOpenViewImageModal(true)}
          handleClose={() => setOpenViewImageModal(false)}
          ImagePath={previewUrl}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
