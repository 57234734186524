import React, { useEffect, useRef } from "react";
import NabhLogo from "../../assets/icons/NabhLogo";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { fetchMlcInjuryCertificatePrint } from "../services/admissionServices/AdmissionServices";

export default function InjuryCertificatePrint({ certificateId }) {
  const printRef = useRef(null);

  const [dataResult, setDataResult] = React.useState();

  React.useState(() => {
    fetchMlcInjuryCertificatePrint(certificateId)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, []);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <table className="w-full" ref={printRef}>
      <thead
        className=" w-full"
        style={{
          "-fs-table-paginate": "paginate",
        }}
      >
        <div>
          <div className=" flex justify-between items-center">
            <NabhLogo />
            <h1 className=" text-[18px] font-semibold">
              {dataResult?.CertficateType}
            </h1>
            <InamdarHospiLogo />
          </div>
        </div>
        <div>
          <div className="p-1 grid grid-cols-[25%_1%_32%] mt-2">
            <div className="font-semibold text-[11px]">
              Injury Certificate No
            </div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.injuryCertificateNo}</div>
            <div className="font-semibold text-[11px]">Certificate That</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.CertificateThat}</div>
            <div className="font-semibold text-[11px]">Recidence</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.Precidence}</div>
            <div className="font-semibold text-[11px]">
              Had Come To Hospital
            </div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.HadComeToHospital}</div>
          </div>

          <div className="font-semibold text-[11px] p-1">
            He/She had following Injuries when examined by Doctor
          </div>
          <div className="text-[11px]  pl-2">{dataResult?.DoctorName} </div>
        </div>
      </thead>
      <tbody className="w-full">
        <div>
          <div className="font-semibold text-[11px] mt-5 p-1">
            Nature Of Injury
          </div>
          <div className=" text-[11px] ml-5">{dataResult?.NatureOfInjury}</div>
          <div className=" flex text-[11px] p-1">
            <h1 className=" font-semibold">
              He/She Was Treated As Inamdar Patient As OPD From
            </h1>
            <h1>&nbsp;{dataResult?.TreatFromTime}&nbsp;</h1>
            <h1 className=" font-semibold">To</h1>
            <h1>&nbsp;{dataResult?.TreatToTime}&nbsp;</h1>
            <h1 className=" font-semibold">Duration</h1>
            <h1>&nbsp;5 Day(s)</h1>
          </div>
          <div className=" flex space-x-3 p-1 text-[11px]">
            <h1 className=" font-semibold">The weapon used sharp / blunt</h1>
            <h1>SHARP ( KNIFE ) & BLUNT ( STONE )</h1>
          </div>
          <div className="p-1  flex space-x-10">
            <div
              className="  text-[11px] font-semibold
            "
            >
              Whether simple / grevious or dangerous
            </div>
            <div className="space-y-3">
              <h1 className=" text-[11px]">simple</h1>
              <h1 className="font-semibold text-[11px]">Signature</h1>
              <h1 className=" flex space-x-5 items-center font-semibold text-[11px]">
                <p>Name</p>
                <p className=" w-96 border-b-[1px] border-black">&nbsp;</p>
              </h1>
              <h1 className=" flex space-x-5 items-center font-semibold text-[11px]">
                <p>Reg. No</p>
                <h1 className=" flex">
                  <p className=" w-20 border-b-[1px] border-black">&nbsp;</p>On
                  <p className=" w-20 border-b-[1px] border-black">&nbsp;</p>/
                  <p className=" w-20 border-b-[1px] border-black">&nbsp;</p>/20
                </h1>
              </h1>
              <h1 className=" flex">
                <p className=" text-[11px]">Designation</p>
                <p className=" w-20 border-b-[1px] border-black">&nbsp;</p>
              </h1>
            </div>
          </div>
        </div>
      </tbody>
    </table>
  );
}
