import { Divider, TextField } from "@mui/material";
import React, { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchStore } from "../../../../commonServices/miscellaneousServices/MiscServices";
import { searchStockAdjustmentItem } from "../../../services/generalStore/stockAdjustmentServices/stockAdjustmentServices";
import { itemBatchNumber } from "../../../services/stockAdjustment/StockAdjustmentServices";

import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import BatchInfoModal from "./BatchInfoModal";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  expiryAdjustmentPdfPrint,
  postExpiryAdjustment,
} from "../../../services/generalStore/expiryAdjustment/ExpiryAdjustmentServices";

const Actions = [
  {
    id: 0,
    action: "Print",
    isAction: false,
  },
  {
    id: 1,
    action: "Add",
    isAction: false,
  },
];
function ExpiryAdjustment() {
  let location = useLocation();
  const schema = yup.object().shape({});
  const defaultValues = {
    itemCode: null,
    itemBatchNo: 0,
    newBatchNo: 0,
    searchExpiryAdjustment: "",
  };
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
    handleSubmit,
    register,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variable
  const [storeDropdown, setStoreDropdown] = React.useState();

  const [tempArr, setTempArr] = React.useState([]);
  const [itemId, setItemId] = React.useState([]);
  const [postObj, setPostObj] = React.useState([]);
  const [openBatchInfoModal, setOpenBatchInfoModal] = React.useState(false);
  const [selectedBatchData, setSelectedBatchData] = React.useState([]);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [searchString, setSearchString] = React.useState([]);
  const [batchItemInfoObj, setBatchItemInfoObj] = React.useState(null);
  const [expiryAdjustment, setExpiryAdjustment] = React.useState(null);
  const [itemName, setItemName] = React.useState();
  const [newExpiry, setNewExpiry] = React.useState(new Date());
  const [expirySearchResult, setExpirySearchResult] = React.useState(null);
  const [customSearchError, setCustomSearchError] = React.useState("");
  const [customBatchError, setCustomBatchError] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(0);

  // other varibles
  let searchExpiryAdjustmentVal = getValues("searchExpiryAdjustment");
  let itemBatchNoVal = getValues("itemBatchNo");

  // useEffect
  // store api call
  React.useEffect(() => {
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        setStoreDropdown(res.result);
      });
  }, []);

  // patch login store to dropdwon by create obj in id,value and label format
  useEffect(() => {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let storeId = userInfoObj.storeId;

    let storeIdValueLabelObj = {
      id: storeId,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };

    setValue("store", storeIdValueLabelObj);
  }, []);

  // after batch selction pathch values againest that batch into form
  useEffect(() => {
    if (batchItemInfoObj) {
      setExpiryAdjustment(batchItemInfoObj);
      let itemName = getValues("searchExpiryAdjustment");
      setItemName(itemName);
      setValue("itemBatchNo", batchItemInfoObj.BatchCode);
      setValue("newBatchNo", batchItemInfoObj.UnitMRP);
    }
  }, [batchItemInfoObj, setValue, getValues]);

  // button visibility  and actions permissions
  console.log("locationlocation", location.state.menuId);
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  // functions start
  // open batch modal
  const handleOpenBatchModal = () => {
    setOpenBatchInfoModal(true);
  };
  const handleCloseBatchModal = () => {
    setOpenBatchInfoModal(false);
  };

  // open confirmation dialog
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handleChange = (autoSearchString) => {
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfo);

    let searchObj = {
      deptStoreId: userInfoObj.storeId,
      isConsignment: 1,
      itemCode: "%",
      itemName: "%",
      deptStore: 1,
      searchString: autoSearchString,
    };
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      searchStockAdjustmentItem(searchObj)
        .then((response) => response.data)
        .then((res) => {
          if (searchObj !== null) {
            setSearchString(res.result);
          } else {
            setSearchString([]);
          }
        });
    }
  };

  // function move tab in batch selection
  function findNextTabStop(el) {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    return list[3].focus();
  }

  // adjustment add to table from selected batch
  function addUpdatedExpiryAdjustmentToTable() {
    if (
      searchExpiryAdjustmentVal === null ||
      searchExpiryAdjustmentVal === ""
    ) {
      setCustomSearchError("Search Item For Expiry Adjustment");
    }
    if (itemBatchNoVal === 0 || itemBatchNoVal === "") {
      setCustomBatchError("No Item for Expiry Adjustment");
    }

    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let storeId = userInfoObj.storeId;

    let storeIdValueLabelObj = {
      id: storeId,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };
    setValue("store", storeIdValueLabelObj);
    let newBatchNoVal = getValues("newBatchNo");

    let Obj = {};
    Obj.ItemName = searchExpiryAdjustmentVal.label;
    Obj.batchCode = batchItemInfoObj.BatchCode
      ? batchItemInfoObj.BatchCode
      : null;
    Obj.itemId = itemId;
    Obj.newBatchCode = newBatchNoVal;
    Obj.newExpiryDate = format(newExpiry, "yyyy-MM-dd'T'HH:mm:ss");

    Obj.oldExpiryDate = batchItemInfoObj.BatchExpDatePatch?.toString()?.replaceAll(
      ".000+00:00",
      ""
    );

    if (
      (searchExpiryAdjustmentVal === null ||
        searchExpiryAdjustmentVal === "") &&
      (itemBatchNoVal === 0 || itemBatchNoVal === "")
    ) {
      setCustomSearchError("Search Item For Expiry Adjustment");
      setCustomBatchError("No Item for Expiry Adjustment");
    } else {
      let temp = [...tempArr];
      temp.push(Obj);
      setTempArr(temp);
      reset(defaultValues);
      setExpirySearchResult(null);
      setExpiryAdjustment(null);
      setValue("store", storeIdValueLabelObj);
      setItemName();
    }
  }

  // post object of expiry adjustment
  function saveExpiryAdjustment() {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);

    let postObj = {
      addedBy: userInfoObj.userId,
      expiryAdjustmentDetailsDto: tempArr,
      isConsignment: true,
      storeId: userInfoObj.storeId,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };

    setPostObj(postObj);
    setOpenBackdrop(true);
    handleOpenConfirmation();
  }

  // Save expiry adjustment function
  function addRecord() {
    handleCloseConfirmation();
    postExpiryAdjustment(postObj)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert(res.message);

        handleOpenPrintModal(res.result);
        setOpenConfirmation(false);
        setExpiryAdjustment(null);
        setValue("itemBatchNo", null);
        setValue("newBatchNo", null);
        setTempArr([]);
      })
      .catch((error) => {
        // errorAlert(error.message);
        setOpenBackdrop(false);
        console.log("errorAlerterrorAlert", error);
      });
  }

  //prints print
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (mrpAdjustmentId) => {
    expiryAdjustmentPdfPrint(mrpAdjustmentId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  function onSubmitDataHandler(data) {}
  // get row data function
  function handleSelectedRow() {}

  return (
    <div className="mt-12 space-y-2">
      <div className="py-2">
        <p className="text-center text-xl text-gray-700 font-Poppins">
          Expiry Adjustment
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="flex fles-wrap">
          <div className="w-[30%] mx-5">
            <DropdownField
              control={control}
              error={errors.authorizedBy}
              name="store"
              placeholder="Store"
              dataArray={storeDropdown}
              isDisabled={true}
            />
          </div>
        </div>
        <div className=" flex flex-col-reverse lg:flex-row lg:space-x-3 text-sm my-2">
          <div className="lg:w-[55%] xl:w-[70%] border rounded bg-white p-2  mt-2 lg:mt-0 lg:pb-0 lg:bg-transparent lg:border-none ">
            <div className="px-4 font-bold text-gray-700 font-Poppins">
              Expiry Adjustment List
            </div>
            {tempArr && tempArr.length > 0 ? (
              <>
                <CommonDynamicTable
                  data={tempArr}
                  requiredActions={false}
                  setSelectedRow={setSelectedRow}
                  tableClass={"rounded xl:h-[400px] lg:h-36   md:h-52"}
                  handleSelectedRow={handleSelectedRow}
                />
              </>
            ) : (
              <div className="text-center py-12">
                <p>No Record Found....</p>
              </div>
            )}

            <div className="text-right py-2">
              {tempArr.length > 0 && userActions?.length > 0
                ? userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <>
                          <CommonButton
                            label="Save"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              saveExpiryAdjustment();
                              setPrivilege(obj?.action);
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))
                : ""}
            </div>
          </div>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ color: "black", borderRightWidth: 2 }}
          />
          <form onSubmit={handleSubmit(onSubmit)}></form>
          <div className="lg:w-[50%] xl:w-[35%] bg-white rounded border p-2">
            <div className="mt-4 col-span-3 lg:col-span-2 ">
              <SearchDropdown
                control={control}
                name="searchExpiryAdjustment"
                placeholder="Search By Item Name"
                dataArray={searchString}
                searchIcon={true}
                isClearable={true}
                isSearchable={true}
                handleInputChange={handleChange}
                inputRef={{
                  ...register("searchExpiryAdjustment", {
                    onChange: (e) => {
                      console.log(
                        "searchExpiryAdjustmentsearchExpiryAdjustmentsearchExpiryAdjustment",
                        e
                      );
                      findNextTabStop(document.activeElement);
                      if (e.target.value !== null) {
                        setItemId(e.target.value.id);
                        setCustomSearchError("");
                      } else {
                        setSearchString([]);
                      }
                    },
                  }),
                }}
              />
              {customSearchError !== "" ? (
                <div className="text-red-500 flex font-sm">
                  <span className="text-xs">{customSearchError} *</span>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="grid grid-cols-2 text-sm lg:text-xs xl:text-sm font-semibold gap-3  py-4">
              <div className="flex space-x-7 lg:space-x-5 xl:space-x-7 lg:col-span-2 whitespace-nowrap">
                <h1>Item Name</h1>
                <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                  : &nbsp;{itemName && itemName.label}
                </label>
              </div>
              <div className="flex space-x-2 whitespace-nowrap">
                <h1>Curr.Batch.No</h1>
                <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                  
                  : &nbsp;{expiryAdjustment && expiryAdjustment.BatchCode}
                </label>
              </div>
              <div className="flex space-x-2 lg:space-x-2 xl:space-x-2 whitespace-nowrap">
                <h1 className="">Current Expiry</h1>
                <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal ">
                  : &nbsp;
                  {expiryAdjustment &&
                    expiryAdjustment.BatchExpDate.toString().replaceAll(
                      ".000+00:00",
                      ""
                    )}
                </label>
              </div>
              <div className="flex space-x-7 lg:space-x-6   whitespace-nowrap">
                <h1>Current Bal </h1>
                <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                  : &nbsp;{expiryAdjustment && expiryAdjustment.BalQty}
                </label>
              </div>
              <div className="flex space-x-5  whitespace-nowrap">
                <h1 className="">Current MRP</h1>
                <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                  : &nbsp; {expiryAdjustment && expiryAdjustment.UnitMRP}
                </label>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="">
                <TextField
                  name="itemBatchNo"
                  {...register("itemBatchNo")}
                  size="small"
                  // type="number"
                  variant="outlined"
                  // inputRef={itemKitRefrence}
                  label="New Batch No*"
                  error={errors.itemBatchNo}
                  control={control}
                  inputProps={{
                    style: {
                      height: "18px",
                    },
                  }}
                  onKeyDown={(e) => {
                    console.log("on key press event", e);
                    if (e.key === "Enter") {
                      let getStoreVal = getValues("store");
                      let getItemVal = getValues("searchExpiryAdjustment");
                      console.log(
                        "getStoreValgetStoreValgetStoreValgetStoreVal",
                        getItemVal
                      );
                      setOpenBatchInfoModal(true);
                      setCustomBatchError("");
                      let currentDate = new Date();
                      let batchPostObj = {
                        batchExpDate: currentDate,
                        isConsignment: 0,
                        itemId: getItemVal.id,
                        storeId: 3,
                      };
                      console.log(
                        "batchPostObjbatchPostObjbatchPostObj",
                        batchPostObj
                      );
                      itemBatchNumber(batchPostObj).then((response) => {
                        console.log(
                          "selectedbatch no is",
                          response.data.result
                        );
                        setSelectedBatchData(response.data.result);
                      });
                    }
                  }}
                />
                {customBatchError !== "" ? (
                  <>
                    <span className="text-red-500  text-xs flex whitespace-nowrap ">
                      {customBatchError}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="">
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disablePast
                        label="New Date"
                        value={newExpiry}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          console.log("newValuenewValuenewValue", newValue);

                          setNewExpiry(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            name="fromDateCalender"
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "#0B83A5",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  name="fromDateCalender"
                />
              </div>
            </div>

            <div className="mt-3 flex space-x-2 lg:justify-end ">
              <CommonButton
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  setValue("itemBatchNo", null);
                  setValue("newBatchNo", null);
                  setValue("searchExpiryAdjustment", null);
                  setNewExpiry(new Date());
                }}
              />

              <CommonButton
                label="Add"
                className="bg-customGreen text-white"
                onClick={() => {
                  addUpdatedExpiryAdjustmentToTable();
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <BatchInfoModal
        handleOpen={handleOpenBatchModal}
        handleClose={handleCloseBatchModal}
        open={openBatchInfoModal}
        setOpen={setOpenBatchInfoModal}
        selectedBatchData={selectedBatchData}
        setBatchItemInfoObj={setBatchItemInfoObj}
        tableData={tempArr}
      />
      <ConfirmationModal
        confirmationOpen={openConfirmation}
        confirmationHandleClose={handleCloseConfirmation}
        confirmationSubmitFunc={() => {
          addRecord(postObj);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save MRP Adjustment ?"
        confirmationButtonMsg="Save"
      />
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
}
export default ExpiryAdjustment;
