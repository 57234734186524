import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import AppointmentPage from "../opd/components/AppointmentBooking/AppointmentPage";
import CancelPresentationIconButton from "../common/components/Buttons/CancelPresentationIconButton";

export default function PostDischargeBookAppoinmentModal(props) {
  const { handleClose, menuId, PatientId } = props;
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
    p: 3,
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div>
            {
              <AppointmentPage
                PatientId={PatientId}
                menuId={menuId}
                isFromOutside={true}
              />
            }
          </div>
        </Box>
      </Modal>
    </div>
  );
}
