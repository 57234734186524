import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const getPatientData = (listObj) => {
  return apiClient.post(`/ipdCharges/patientInfoBillCharges`, listObj, {
    headers: authHeader(),
  });
};

// export const getPatientData = (
//   patientId,
//   admissionId,
//   category,
//   isConsolidate
// ) => {
//
//   return apiClient.get(
//     `/ipdCharges/patientInfoBillCharges?patientId=${patientId}&admissionId=${admissionId}&category=${category}&consolidate=${isConsolidate}`,
//     {
//       headers: authHeader(),
//     }
//   );
// };

export const getAutoSearchPatientList = (searchString, isCurrentAdmitted) => {
  return apiClient.get(
    `/ipdCharges/patientInfo/${searchString}/${isCurrentAdmitted}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDoctorList = (searchString) => {
  return apiClient.get(`/misc/doctor/${searchString}`, {
    headers: authHeader(),
  });
};

//PAckage List
export const getPackageList = (admissionId) => {
  return apiClient.get(`/ipdCharges/listOfPackage/${admissionId}`, {
    headers: authHeader(),
  });
};

//Convert Charge to Package
export const convertToPackage = (convertData) => {
  return apiClient.post(`/ipdCharges/convertCharges`, convertData, {
    headers: authHeader(),
  });
};

//Search Service
export const searchService = (searchData) => {
  return apiClient.post(`/services/search`, searchData, {
    headers: authHeader(),
  });
};

//Save Services
export const saveServices = (searchData, menuId, privilege) => {
  return apiClient.post(
    `/ipdCharges/saveCharges/${menuId}/${privilege}`,
    searchData,
    {
      headers: authHeader(),
    }
  );
};

//Delete Service
export const deleteService = (serviceDetails) => {
  return apiClient.post(`/ipdCharges/deleteCharge`, serviceDetails, {
    headers: authHeader(),
  });
};
