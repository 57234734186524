import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import InputField from "../../../../common/components/FormFields/InputField";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

function Output(props) {
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => setOpenPost(false);
  const [outputFinalData, setOutputFinalData] = React.useState(); //input Save final data

  const defaultValues = {
    urine: "",
    urineQuantity: "",
    drainage: "",
    drainageQuantity: "",
    stool: "",
    stoolQuantity: "",
    ngAspiratic: "",
    ngAspiraticQuantity: "",
    outputOther: "",
    outputOtherQuantity: "",
    outputPdHd: "",
    outputPdHdQuantity: "",
  };
  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = methods;

  // function postOutputData(obj) {
  //   saveInputOutput(obj)
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         successAlert(response.data.message);
  //         setOpenBackdrop(false);
  //         props.inputPopulateTable(props.admissionId, props.status);
  //       }
  //     })
  //     .catch((error) => {
  //       errorAlert(error.message);
  //       setOpenBackdrop(false);0
  //     });
  // }

  let getUserId = JSON.parse(localStorage.getItem("userInfo"));
  const onSubmitOutputDataHandler = (data) => {
    if (props.admissionId !== null) {
      if (props?.admissionId !== undefined) {
        if (isDirty !== false) {
          setOpenPost(true);
          setOutputFinalData(data); //final data
        } else {
          warningAlert("Please enter value...!");
        }
      }
    } else if (props.admissionId === undefined || props.admissionId === null) {
      warningAlert("Please Select Patient...!");
    }
  };

  function addRecordOutput(data) {
    setOpenPost(false);
    props.setOpenBackdrop(true);
    let postedObj = {
      menuId: props.menuId,
      privilege: props.privilege,
      addedBy: getUserId.userId,
      admissionId: props.admissionId,
      outTakeUrine: data.urineQuantity,
      outTakeUrineDetails: data.urine,
      outTakeDrane: data.drainageQuantity,
      outTakeDraneDetails: data.drainage,
      outTakeStool: data.stoolQuantity,
      outTakeStoolDetails: data.stool,
      outTakeNg: data.ngAspiraticQuantity,
      outTakeEngDetails: data.ngAspiratic,
      outTakeOther: data.outputOtherQuantity,
      outTakeOtherDetails: data.outputOther,
      outTakePd: data.outputPdHdQuantity,
      outTakePdDetails: data.outputPdHd,
    };
    props.postOutputData(postedObj); //post final output data
    reset(defaultValues);
  }

  useEffect(() => {
    let resetObj = {
      //OutPut

      //   {
      //     "Date": "06-12-2023",
      //     "Time": "03:52:04 PM",
      //     "URINE": "8         ",
      //     "DRANGE": "8         ",
      //     "Stool": "8         ",
      //     "OUTTAKEOther": "8         ",
      //     "UrinDetails": "8                                                                                                   ",
      //     "DRANGEDetails": "8                                                                                                   ",
      //     "StoolDetails": "8                                                                                                   ",
      //     "OUTTAKEOtherDetails": "8                                                                                                   ",
      //     "outtakepd": "8",
      //     "outttakeng": "8         ",
      //     "outttakengdetails": "8                                                                                                   ",
      //     "outtakepddetails": "8",
      //     "Id": 2158142
      // }

      urine: props.selectedRow?.UrinDetails,
      urineQuantity: Number(props.selectedRow?.URINE),
      drainage: props.selectedRow?.DRANGEDetails,
      drainageQuantity: Number(props.selectedRow?.DRANGE),
      stool: props.selectedRow?.StoolDetails,
      stoolQuantity: Number(props.selectedRow?.Stool),
      ngAspiratic: props.selectedRow?.outttakengdetails,
      ngAspiraticQuantity: Number(props.selectedRow?.outttakeng),
      outputOther: props.selectedRow?.OUTTAKEOtherDetails,
      outputOtherQuantity: Number(props.selectedRow?.OUTTAKEOther),
      outputPdHd: props.selectedRow?.outtakepddetails,
      outputPdHdQuantity: Number(props.selectedRow?.outtakepd),
    };
    reset(resetObj);
  }, [props.selectedRow]);

  return (
    <div className="w-full">
      <form
      // onSubmit={handleSubmit(onSubmitOutputDataHandler)}
      >
        <div className="flex mt-1">
          <div className="grid grid-cols-4 gap-2 m-2 border-gray-500 border-r-2 pr-4">
            <div className="col-span-3">
              <InputField
                name="urine"
                variant="outlined"
                label="Urine"
                control={control}
              />
            </div>
            <InputField
              name="urineQuantity"
              type="number"
              variant="outlined"
              label="Qty"
              control={control}
            />

            <div className="col-span-3">
              <InputField
                name="drainage"
                variant="outlined"
                label="Drange"
                control={control}
              />
            </div>
            <InputField
              name="drainageQuantity"
              type="number"
              variant="outlined"
              label="Qty"
              control={control}
            />

            <div className="col-span-3">
              <InputField
                name="stool"
                variant="outlined"
                label="Stool"
                control={control}
              />
            </div>
            <InputField
              name="stoolQuantity"
              type="number"
              variant="outlined"
              label="Qty"
              control={control}
            />
          </div>
          <div className="grid grid-cols-4 gap-2 m-2">
            <div className="col-span-3">
              <InputField
                name="ngAspiratic"
                variant="outlined"
                label="NG Aspiratic"
                control={control}
              />
            </div>
            <InputField
              name="ngAspiraticQuantity"
              type="number"
              variant="outlined"
              label="Qty"
              control={control}
            />

            <div className="col-span-3">
              <InputField
                name="outputOther"
                variant="outlined"
                label="Other"
                control={control}
              />
            </div>
            <InputField
              name="outputOtherQuantity"
              type="number"
              variant="outlined"
              label="Qty"
              control={control}
            />

            <div className="col-span-3">
              <InputField
                name="outputPdHd"
                variant="outlined"
                label="PD/HD"
                control={control}
              />
            </div>
            <InputField
              name="outputPdHdQuantity"
              type="number"
              variant="outlined"
              label="Qty"
              control={control}
            />
          </div>
        </div>
        <div className=" flex gap-2 justify-end pb-2">
          <CommonButton
            type="button"
            className="bg-customBlue text-white"
            label="View All"
            onClick={() => {
              props.handleOpen();
            }}
          />
          <CommonButton
            type="button"
            label="Reset"
            className="resetbutton border border-customRed text-customRed"
            
            onClick={() => {
              reset(defaultValues);
              props.setSelectedRow(null);
            }}
          />
          {props.userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <div
                  onClick={() => {
                    props.setPrivilege(obj?.action);
                  }}
                >
                  {props.patientInformation?.BedReleased === true ||
                    props.selectedPatient?.BedReleased ===
                      true ? null : (
                    <>
                      {props.selectedRow === null ? (
                        <div>
                          <CommonButton
                            //type="submit"
                            className="bg-customGreen text-white"
                            label="Save"
                            onClick={methods.handleSubmit(
                              onSubmitOutputDataHandler
                            )}
                            
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ) : null}
            </>
          ))}
        </div>
      </form>

      <CommonBackDrop openBackdrop={props.openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={() => {
          addRecordOutput(outputFinalData);
        }}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}

export default Output;
