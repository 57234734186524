import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import {
  fetchCompany,
  fetchItemCategory,
  fetchItemClass,
  fetchItemType,
  fetchStore,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  getGenericName,
  getItemName,
  getItemStockList,
} from "../../services/inventoryStockServices/InventoryStockService";
import CurrentStockPrint from "../../prints/inventorystock/CurrentStockPrint";
import InventoryStockPrinmodal from "./inventoryStockPrint/InventoryStockPrintmodal";
import DaysPrint from "../../prints/inventorystock/DaysPrint";
import CurrentStockNonCosignment from "../../prints/inventorystock/CurrentStockNonCosignment";
import ItemExpirydetailsPrint from "../../prints/inventorystock/ItemExpirydetailsPrint";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { useLocation } from "react-router-dom";

export default function InventoryStock() {
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  const AssetTypeArray = [
    { id: 0, label: "All", value: 0 },
    { id: 1, label: "Fixed", value: 1 },
    { id: 2, label: "Non Fixed", value: 2 },
  ];

  const allexpiryItemDaily = [
    { id: 1, label: "All Expiry Items", value: 1 },
    { id: 2, label: "Days", value: 2 },
  ];

  const printOptions = [
    { id: 3, label: "Current Stock", vallue: 3 },
    { id: 4, label: "Non Consignmennt Print", vaule: 4 },
  ];
  const defaultValues = {
    store: null,
    assetType: "0",
    allexpiryItemDaily: "1",
    itemType: null,
    itemCategory: null,
    generic: null,
    itemClass: null,
    itemName: null,
    manufacturer: null,
    days: 0,
  };

  const { control, watch, setValue, register, reset, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let [
    storeVal,
    itemTypeVal,
    itemCategoryVal,
    genericVal,
    itemClassVal,
    itemVal,
    manufacturerVal,
    assetTypeVal,
    allexpiryItemDailyVal,
  ] = watch([
    "store",
    "itemType",
    "itemCategory",
    "generic",
    "itemClass",
    "itemName",
    "manufacturer",
    "assetType",
    "allexpiryItemDaily",
  ]);

  const location = useLocation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [storeOptions, setStoreOptions] = useState([]);
  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  const [itemCategoryOptions, setItemCategoryOptions] = useState([]);
  const [genericOptions, setGenericOptions] = useState([]);
  const [itemClassOptions, setItemClassOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(24);
  const [count, setCount] = React.useState();
  const [dataResult, setDataResult] = useState([]);
  const [userAction, setUserActions] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const [openprintModal, setOpenprintModal] = useState(false);

  let watchDays = watch("days");

  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenprintModal(false);
    }, 5000);

    return (
      <div className="hidden">
        {Number(allexpiryItemDailyVal) === 1 && (
          <ItemExpirydetailsPrint storeVal={storeVal} watchDays={Number(0)} />
        )}

        {Number(allexpiryItemDailyVal) === 2 && (
          <DaysPrint storeVal={storeVal} watchDays={watchDays} />
        )}

        {Number(allexpiryItemDailyVal) === 3 && (
          <CurrentStockPrint storeVal={storeVal} />
        )}

        {Number(allexpiryItemDailyVal) === 4 && <CurrentStockNonCosignment />}
      </div>
    );
  };

  const populateTable = (forPagination) => {
    let defaultParams = {
      isFixedAsset: Number(assetTypeVal),
      itemCategoryId: itemCategoryVal !== null ? itemCategoryVal?.id : null,
      itemClassId: itemClassVal !== null ? itemClassVal?.id : null,
      itemCompanyId: manufacturerVal !== null ? manufacturerVal?.id : null,
      itemGenericId: genericVal !== null ? genericVal?.id : null,
      itemId: itemVal !== null ? itemVal?.id : null,
      itemTypeId: itemTypeVal !== null ? itemTypeVal?.id : null,
      storeId: storeVal !== null ? storeVal?.id : null,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    setShowSpinner(true);
    getItemStockList(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setShowSpinner(false);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
        }
        setCount(res.count);
      })
      .catch((error) => {
        setShowSpinner(false);
        errorAlert(error.message);
      });
  };

  //Item Name
  const handleChange = (autoServceSearchString) => {
    if (autoServceSearchString !== "") {
      getItemName(autoServceSearchString)
        .then((response) => response.data)
        .then((res) => {
          setItemOptions(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  const handleChangeForGenericName = (autoServceSearchString) => {
    if (autoServceSearchString !== "") {
      getGenericName(autoServceSearchString)
        .then((response) => response.data)
        .then((res) => {
          setGenericOptions(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  useEffect(() => {
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        setStoreOptions(res.result);
      });

    fetchItemType()
      .then((response) => response.data)
      .then((res) => {
        setItemTypeOptions(res.result);
      });
    // item category dropdown api
    fetchItemCategory()
      .then((response) => response.data)
      .then((res) => {
        setItemCategoryOptions(res.result);
      });
    // item class dropdown api
    fetchItemClass()
      .then((response) => response.data)
      .then((res) => {
        setItemClassOptions(res.result);
      });
    //CompanyOptions
    fetchCompany()
      .then((response) => response.data)
      .then((res) => {
        setManufacturerOptions(res.result);
      });
  }, []);

  useEffect(() => {
    //Tabel
    if (storeVal) {
      populateTable();
    }
  }, [
    storeVal,
    itemTypeVal,
    itemCategoryVal,
    genericVal,
    itemClassVal,
    itemVal,
    manufacturerVal,
    assetTypeVal,
  ]);

  useEffect(() => {
    let logStore = {
      id: getUserInfo.storeId,
      label: getUserInfo.storeName,
      value: getUserInfo.storeId,
    };

    if (getUserInfo.storeId !== null && getUserInfo.storeName !== null) {
      setValue("store", logStore);
    }

    // USER ACTIONS
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    if (Number(allexpiryItemDailyVal) !== 2) {
      setValue("days", 0);
    }
  }, [allexpiryItemDailyVal]);

  return (
    <div className="mt-12 mb-2 px-3">
      <h1 className="text-center text-black font-bold text-xl">Item Stock</h1>

      <div className="grid grid-cols-5 gap-x-2 gap-y-3 pt-3">
        <DropdownField
          control={control}
          name="store"
          placeholder="Store"
          dataArray={storeOptions}
          isSearchable={true}
          isClearable={true}
        />
        <DropdownField
          control={control}
          name="itemType"
          placeholder="ItemType"
          dataArray={itemTypeOptions}
          isSearchable={true}
          isClearable={true}
        />
        <DropdownField
          control={control}
          name="itemCategory"
          placeholder="Item Category"
          dataArray={itemCategoryOptions}
          isSearchable={true}
          isClearable={true}
        />
        <SearchDropdown
          control={control}
          name="generic"
          placeholder="Generic Name"
          searchIcon={true}
          isSearchable={true}
          isClearable={true}
          dataArray={genericOptions}
          handleInputChange={handleChangeForGenericName}
        />
        <DropdownField
          control={control}
          name="itemClass"
          placeholder="Item Class"
          dataArray={itemClassOptions}
          isSearchable={true}
          isClearable={true}
        />
        <SearchDropdown
          control={control}
          name="itemName"
          placeholder="Item Name"
          searchIcon={true}
          isSearchable={true}
          isClearable={true}
          dataArray={itemOptions}
          handleInputChange={handleChange}
        />
        <DropdownField
          control={control}
          name="manufacturer"
          placeholder="Manufacturer"
          dataArray={manufacturerOptions}
          isSearchable={true}
          isClearable={true}
        />
        <div className="w-full flex items-center col-span-2 space-x-4">
          <span className="text-sm font-semibold pb-2">Asset Type:</span>
          <RadioField
            control={control}
            name="assetType"
            dataArray={AssetTypeArray}
          />
        </div>
      </div>

      <div className="flex space-x-3 pt-3 ml-4 items-center">
        <RadioField
          label=""
          name="allexpiryItemDaily"
          control={control}
          dataArray={allexpiryItemDaily}
        />
        <div className="w-[80px]">
          <InputField
            control={control}
            label=""
            name="days"
            disabled={allexpiryItemDailyVal !== "2"}
          />
        </div>
        <div className="">
          <RadioField
            label=""
            name="allexpiryItemDaily"
            control={control}
            dataArray={printOptions}
          />
        </div>
        <Tooltip title="Print">
          <button
            onClick={() => {
              setOpenprintModal(true);
            }}
          >
            <PrintOnIcon />
          </button>
        </Tooltip>
      </div>

      {showSpinner ? (
        <div className="flex pt-6 justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dataResult.length > 0 ? (
            <>
              <CommonDynamicTablePaginationNew
                dataResult={dataResult}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                highlightedRow={false}
                removeHeaders={[
                  "Id",
                  "IsCons",
                  "StockingUOM",
                  "MaxStockLevel",
                  "BatchCode",
                  "BatchExpDate",
                  "ScheduleH1",
                  "UnitMRP",
                  "Description",
                ]}
                tableHeading="Inventory Stock List"
                highlightRow={false}
                tableClass="h-[570px] 2xl:h-[580]"
              />
            </>
          ) : (
            <h1 className="flex justify-center py-6 font-bold text-gray-600">
              No Record Found...
            </h1>
          )}
        </>
      )}

      <InventoryStockPrinmodal
        open={open}
        handleClose={handleClose}
        storeVal={storeVal}
      />

      {openprintModal && renderPrint()}
    </div>
  );
}
