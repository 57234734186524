import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { fetchPreviousList } from "../../services/officePermissionServices/OfficePermissionsServices";

export default function PreviousListModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const { handleClose } = props;

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  //

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  useEffect(() => {
    populateTable();
  }, []);

  const populateTable = (forPagination) => {
    const PreviousListObj = {
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      cashCounterId: token?.cashCounterId,
      requestFlag: 1,
    };
    fetchPreviousList(PreviousListObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Accepted Office Permissions
            </div>

            <hr className="border mx-1  border-blue-900" />

            <div>
              {dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  highlightRow={false}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  tableClass="h-96 2xl:h-max-[550px]"
                  removeHeaders={["User Name"]}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>

            <div className="gap-3 my-3 flex justify-end">
              <CommonButton
                label="Cancel"
                className="border border-customRed text-customRed"
                onClick={() => handleClose()}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

