import { yupResolver } from "@hookform/resolvers/yup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelButton from "../../../../common/components/Buttons/CancelButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { saveNursingNightReport } from "../../../services/nursingReportServices/nursingTakeOverDetailsServices";
import { Box, Modal, TextField } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import TableDropDownField from "../../../../common/components/FormFields/TableDropDownField";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};
const staffDuty = [
  {
    id: 1,
    value: "Morning",
    label: "Morning",
  },
  {
    id: 2,
    value: "AfterNoon",
    label: "AfterNoon",
  },
  {
    id: 2,
    value: "Night",
    label: "Night",
  },
];
//main function
export default function NursingReportTable(props) {
  
  const schema = yup.object().shape({});
  const defaultValues = {
    remarks: "",
    treatment: "",
    diagnosis: "",
    nursing: "",
    staffDuty: null,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  // state variables
  const [open, setOpen] = React.useState(false);
  const [checkBoxAction, setCheckBoxAction] = React.useState(false);
  const [postObj, setPostObj] = React.useState([]);
  const [OpenConfirmation, setOpenConfirmation] = React.useState(false);
  const [privilege, setPrivilege] = React.useState(null);
  const [openDiagnosisModel, setOpenDiagnosiisModel] = React.useState(false);
  const [openTreatmentModel, setOpenTreatmentModel] = React.useState(false);
  const [
    openNursingcareNotesModel,
    setOpenNursingcareNotesModel,
  ] = React.useState(false);
  const [openRemarkModel, setOpenRemarkModel] = React.useState(false);

  const [diagnosis, setDiagnosis] = React.useState("");
  const [treatmentGiven, setTreatmentGiven] = React.useState("");
  const [nursingCareNotes, setNursingCareNotes] = React.useState("");
  const [remark, setRemarks] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [inputTreatmentValue, setInputTreatmentValue] = React.useState("");
  const [
    inputNursingcareNotesValue,
    setInputNursingcareNotesValue,
  ] = React.useState("");
  const [selectedDiagnosis, setSelectedDiagnosis] = React.useState("");

  const [selectedTreatment, setSelectedTreatment] = React.useState("");
  const [selectedNursingNote, setSelectedNursingNote] = React.useState("");
  const [selectedRemark, setSelectedRemark] = React.useState("");
  const [inputRemarkValue, setInputRemarkValue] = React.useState("");

  const [selectedIndex, setSelectedIndex] = React.useState(null);

  // other varibles

  // useEffect
  React.useEffect(() => {
    props.actions.forEach((action) => {
      if (action === "Checkbox") {
        setCheckBoxAction(true);
      }
    });
  }, []);

  // functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  function checkBoxActions(e, row, index) {
    

    let selectedRow = row;
    selectedRow.isChecked = e;
    props.data[index] = selectedRow;
    
  }
  

  // diagnosis input field add key to table
  const handleDiagnosisClick = (row, index) => {
    setValue("diagnosis", row.diagnosis);
    setOpenDiagnosiisModel(true);
  };

  // teatment given field add key to table
  const handleTreatmentGiven = (row, index) => {
    setValue("treatment", row?.treatment);
    setOpenTreatmentModel(true);
  };

  // nursing care and notes add key to table
  const handleNursingCareNotes = (row) => {
    //  setInputNursingcareNotesValue("");
    //setNursingCareNotes(inputNursingcareNotesValue);
    setValue("nursing", row.diagnosis);

    setOpenNursingcareNotesModel(true);
    // let selectedRow = row;
    // selectedRow.notes = e.target.value;
    // props.data[index] = selectedRow;
    
    
  };

  const handleRemarks = (row) => {
    
    // setInputRemarkValue("");
    setValue("remarks", row?.remark);
    setOpenRemarkModel(true);
    // let selectedRow = row;
    // selectedRow.notes = e.target.value;
    // props.data[index] = selectedRow;
  };

  // staff Duty key add to table obj
  const handleStaffDuty = (e, row, index) => {
    
  
    let staffDutyVal = watch(`staffDuty${e?.target?.value?.id}`);

    let selectedRow = row;
    selectedRow.shiftId = watch(`staffDuty${e?.target?.value?.id}`);
    selectedRow.evening = watch(`staffDuty${e?.target?.value?.id}`);
    props.data[index] = selectedRow;
    
  };

  
  //submit diagnosis data
  function onSubmitDiagnosis(row, index) {
    let tempArr = [...props?.data];
    tempArr[selectedIndex].diagnosis = inputValue;
    props?.setData(tempArr);
    setOpenDiagnosiisModel(false);
    setSelectedDiagnosis("");

    //setSelectedIndex(null);
    // setDiagnosis("");
  }
  //submit Treatment data
  function onSubmitTreatment() {
    let tempArr = props?.data;
    tempArr[selectedIndex].treatment = inputTreatmentValue;
    props?.setData(tempArr);
    setOpenTreatmentModel(false);
    setSelectedTreatment("");
  }
  //submit nursing care note
  function onSubmitNursingCareNote() {
    let tempArr = [...props.data];
    tempArr[selectedIndex].nursing = inputNursingcareNotesValue;
    props?.setData(tempArr);
    setOpenNursingcareNotesModel(false);
    setSelectedNursingNote("");
  }

  //onSubmit Remarks
  function onSubmitRemark() {
    let tempArr = [...props?.data];
    tempArr[selectedIndex].remark = inputRemarkValue;
    setOpenRemarkModel(false);
    props?.setData(tempArr);
    setSelectedRemark("");
  }
  function onSubmitDataHandler() {
    let userStringObj = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userStringObj);
    let temp = [...props.data];
    let arr = [];

    for (let obj of temp) {
      if (obj.isChecked === true) {
        
        let tempObj = {};
        // tempObj.admissionId = obj.AdmissionId;
        tempObj.admissionId = obj.admissionId;
        tempObj.addedBy = userInfoObj.userId;
        tempObj.cashCounterId = userInfoObj.cashCounterId;
        tempObj.diagnosis = obj.diagnosis;
        tempObj.remark = obj.remark;
        tempObj.nursing = obj.nursing;
        tempObj.shiftId = obj.shiftId;
        tempObj.evening = "";
        tempObj.menuId = props?.menuId;
        tempObj.privilege = privilege;
        tempObj.treatment = obj.treatment;
        tempObj.floorId = props.floorVal?.id;

        
        arr.push(tempObj);
        
        // addRecord(arr);
      }
    }
    if (arr?.length > 0) {
      handleOpenConfirmation();
    } else {
      warningAlert("Please Select Patient");
    }
    setPostObj(arr);
  }

  // save data to mursing report

  function addRecord() {
    saveNursingNightReport(postObj)
      .then((response) => response.data)
      .then((res) => {
        
        setPostObj([]);
        successAlert(res.message);
        handleCloseConfirmation();
        setDiagnosis("");
        reset();
      });
  }

  React.useEffect(() => {
    if (openDiagnosisModel && selectedDiagnosis !== "") {
      setValue("modalDiagnosis", selectedDiagnosis);
    } else {
      setValue("modalDiagnosis", "");
    }
  }, [openDiagnosisModel]);

  React.useEffect(() => {
    if (openTreatmentModel && selectedTreatment !== "") {
      setValue("modalTreatment", selectedTreatment);
    } else {
      setValue("modalTreatment", "");
    }
  }, [openTreatmentModel]);

  React.useEffect(() => {
    if (openNursingcareNotesModel && selectedNursingNote !== "") {
      setValue("modalNursingNote", selectedNursingNote);
    } else {
      setValue("modalNursingNote", "");
    }
  }, [openNursingcareNotesModel]);
  React.useEffect(() => {
    if (openRemarkModel && selectedRemark !== "") {
      setValue("modalRemark", selectedRemark);
    } else {
      setValue("modalRemark", "");
    }
  }, [openRemarkModel]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid w-auto">
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 10,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "lightBlue",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1e3a8a",
                    borderRadius: 4,
                  },
                }}
                className="rounded lg:h-[500px] 2xl:h-[500px]"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center  whitespace-nowrap">
                          Actions
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          MR NO.
                        </span>
                      </TableCell>

                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          Patient Name
                        </span>
                      </TableCell>

                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          Consultant Name
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          Bed No.
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          Diagnosis
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          Treatment Given
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                          Nursing Care / Notes
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                          Remarks
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                          Staff Duty
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {props.data.map((row, index) => (
                      <TableRow
                        key={index}
                        onClick={() => {}}
                        sx={{
                          "& td": {
                            paddingY: 0,
                            alignItems: "center",
                          },
                        }}
                      >
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap ">
                          {checkBoxAction ? (
                            <fieldset
                              onChange={(e) => {
                                checkBoxActions(e.target.checked, row, index);
                                
                              }}
                              className="items-center"
                            >
                              <CheckBoxField
                                control={control}
                                name={`selectOptions${index}`}
                              />
                            </fieldset>
                          ) : null}
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.MRNO}
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row[`Patient Name`]}
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row[`Doctor Name`]}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row[`Bed Number`]}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {
                            <>
                              <input
                                className="border border-[#0B83A5] rounded text-center"
                                defaultValue={row.diagnosis}
                                name={`diagnosis${index}`}
                                placeholder="Diagnosis"
                                onChange={(e) => setDiagnosis(e.target.value)}
                                onClick={() => {
                                  handleDiagnosisClick(row, index);
                                  setSelectedIndex(index);
                                  if (
                                    row.diagnosis !== undefined &&
                                    row.diagnosis !== ""
                                  ) {
                                    setSelectedDiagnosis(row.diagnosis);
                                  } else {
                                    setSelectedDiagnosis("");
                                  }
                                }}
                              />
                            </>
                          }
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {
                            <>
                              <input
                                type="text"
                                defaultValue={row?.treatment}
                                name={`treatment${index}`}
                                className="border border-[#0B83A5] rounded text-center"
                                placeholder="Teatment Given"
                                // {...register("teatmentgiven")}
                                onChange={(e) =>
                                  setTreatmentGiven(e.target.value)
                                }
                                onClick={(e) => {
                                  handleTreatmentGiven();
                                  setSelectedIndex(index);
                                  if (
                                    row?.treatment !== undefined &&
                                    row?.treatment !== ""
                                  ) {
                                    
                                    setSelectedTreatment(row?.treatment);
                                  } else {
                                    setSelectedTreatment("");
                                  }
                                }}
                              />
                            </>
                          }
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {
                            <>
                              <input
                                type="text"
                                defaultValue={row?.nursing}
                                name={`nursing${index}`}
                                className="border border-[#0B83A5] rounded text-center"
                                placeholder="Nursing Care Notes"
                                onChange={(e) =>
                                  setNursingCareNotes(e.target.value)
                                }
                                onClick={(e) => {
                                  handleNursingCareNotes(row);
                                  setSelectedIndex(index);
                                  if (
                                    row.nursing !== undefined &&
                                    row?.nursing !== ""
                                  ) {
                                    setSelectedNursingNote(row?.nursing);
                                  } else {
                                    setSelectedNursingNote("");
                                  }
                                }}
                              />
                            </>
                          }
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {
                            <>
                              <input
                                type="text"
                                name={`remarks${index}`}
                                className="border border-[#0B83A5] rounded text-center"
                                placeholder="Remarks"
                                defaultValue={row?.remark}
                                onChange={(e) => setRemarks(e.target.value)}
                                // {...register("remarks")}
                                onClick={(e) => {
                                  handleRemarks(row);
                                  setSelectedIndex(index);
                                  if (
                                    row.remark !== undefined &&
                                    row?.remark !== ""
                                  ) {
                                    setSelectedRemark(row?.remark);
                                  } else {
                                    setSelectedRemark("");
                                  }
                                }}
                              />
                            </>
                          }
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap ">
                          <div className="w-44">
                            <TableDropDownField
                              control={control}
                              name={`staffDuty${index}`}
                              label="Staff Duty"
                              placeholder="Staff Duty"
                              isSearchable={true}
                              dataArray={staffDuty}
                              inputRef={{
                                ...register(`staffDuty${index}`, {
                                  onChange: (e) => {
                                    
                                   
                                    let staffDutyVal = watch(
                                      `staffDuty${index}`
                                    );

                                    let selectedRow = row;
                                    selectedRow.shiftId = staffDutyVal.id;
                                    selectedRow.evening = watch(
                                      `staffDuty${e?.target?.value?.id}`
                                    );
                                    props.data[index] = selectedRow;
                                    
                                    
                                  },
                                }),
                              }}
                            />

                            {/* <SearchDropdown
                            control={control}
                            error={errors.shelfName}
                            name={`rowShelf${index}`}
                            label="Shelf"
                            defaultValue={{
                              id: row.ShelfId,
                              label: row.Shelf,
                              value: row.Shelf,
                            }}
                            placeholder="Shelf"
                            searchIcon={true}
                            isSearchable={true}
                            isClearable={true}
                            dataArray={shelf}
                            handleInputChange={handleShelfChange}
                            inputRef={{
                              ...register(`rowShelf${index}`, {
                                onChange: (e) => {
                                  if (e.target.value !== null) {
                                    let dataArray = [...props.data];
                                    let rowData = row;
                                    rowData.Shelf = e.target.value;
                                    rowData.ShelfId = e.target.value.id;
                                    dataArray[index] = rowData;
                                    props.setStoreStockList(dataArray);
                                  } else {
                                    e.target.value = "";
                                  }
                                },
                              }),
                            }}
                          />*/}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          <div className="flex justify-end gap-2 py-2">
            {props.userActions &&
              props.userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        label="Reset"
                        className="border border-customRed text-customRed"
                        onClick={() => {
                          reset(defaultValues);
                        }}
                      />
                      <CommonButton
                        label="Save"
                        type="submit"
                        className="  bg-customGreen text-white "
                        onClick={() => {
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
          </div>
          <ConfirmationModal
            confirmationOpen={OpenConfirmation}
            confirmationHandleClose={handleCloseConfirmation}
            confirmationSubmitFunc={() => {
              handleCloseConfirmation();
              addRecord(postObj);
            }}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure want to add this record ?"
            confirmationButtonMsg="Add"
          />
        </div>
      </form>
      {openDiagnosisModel && (
        <Modal
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(8, 8, 8, 0.28)",
            },
          }}
          //hideBackdrop={true}
          open={openDiagnosisModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <form onSubmit={handleSubmit()}>
              <div className="grid grid-cols-1 md:grid-cols-1 pb-6 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    setOpenDiagnosiisModel(false);
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="grid grid-cols-1 w-[100%] py-2">
                <fieldset
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Diagnosis"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setValue("modalDiagnosis", e.target.value);
                    }}
                    {...register("modalDiagnosis")}
                    multiline
                    inputProps={{
                      style: { overflow: "hidden" },
                      width: "100%",
                    }}
                  />
                </fieldset>
              </div>
              <div className="flex gap-2 justify-end">
                <CommonButton
                  type="button"
                  label="Ok"
                  className="bg-customGreen text-white "
                  onClick={() => {
                    onSubmitDiagnosis();
                  }}
                />
              </div>
            </form>
          </Box>
        </Modal>
      )}

      {openTreatmentModel && (
        <Modal
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(8, 8, 8, 0.28)",
            },
          }}
          open={openTreatmentModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <form onSubmit={handleSubmit()}>
              <div className="grid grid-cols-1 md:grid-cols-1 pb-6 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    setOpenTreatmentModel(false);
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="grid grid-cols-1 w-[100%] py-2">
                <fieldset
                  onChange={(e) => {
                    setInputTreatmentValue(e.target.value);
                  }}
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Treatment Given"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setValue("modalTreatment", e.target.value);
                    }}
                    {...register("modalTreatment")}
                    multiline
                    inputProps={{
                      style: { overflow: "hidden" },
                      width: "100%",
                    }}
                  />
                </fieldset>
              </div>
              <div className="flex gap-2 justify-end">
                <CommonButton
                  type="button"
                  label="Ok"
                  className="bg-customGreen text-white "
                  onClick={() => {
                    onSubmitTreatment();
                  }}
                />
              </div>
            </form>
          </Box>
        </Modal>
      )}
      {openNursingcareNotesModel && (
        <Modal
          //   hideBackdrop={true}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(8, 8, 8, 0.28)",
            },
          }}
          open={openNursingcareNotesModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <form onSubmit={handleSubmit()}>
              <div className="grid grid-cols-1 md:grid-cols-1 pb-6 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    setOpenNursingcareNotesModel(false);
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="grid grid-cols-1 w-[100%] py-2">
                <fieldset
                  onChange={(e) => {
                    setInputNursingcareNotesValue(e.target.value);
                  }}
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Nursing Care Notes"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      
                    
                      setValue("modalNursingNote", e.target.value);
                    }}
                    {...register("modalNursingNote")}
                    multiline
                    inputProps={{
                      style: { overflow: "hidden" },
                      width: "100%",
                    }}
                  />
                </fieldset>
              </div>
              <div className="flex gap-2 justify-end">
                <CommonButton
                  type="button"
                  label="Ok"
                  className="bg-customGreen text-white "
                  onClick={() => {
                    onSubmitNursingCareNote();
                  }}
                />
              </div>
            </form>
          </Box>
        </Modal>
      )}

      {openRemarkModel && (
        <Modal
          //   hideBackdrop={true}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(8, 8, 8, 0.28)",
            },
          }}
          open={openRemarkModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <form onSubmit={handleSubmit()}>
              <div className="grid grid-cols-1 md:grid-cols-1 pb-6 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    setOpenRemarkModel(false);
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="grid grid-cols-1 w-[100%] py-2">
                <fieldset
                  onChange={(e) => {
                    setInputRemarkValue(e.target.value);
                  }}
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Remark"
                    name="remark"
                    sx={{ width: "100%" }}
                    onChange={(e) => setValue("modalRemark", e.target.value)}
                    {...register("modalRemark")}
                    multiline
                    inputProps={{
                      style: { overflow: "hidden" },
                      width: "100%",
                    }}
                  />
                </fieldset>
              </div>
              <div className="flex gap-2 justify-end">
                <CommonButton
                  type="button"
                  label="Ok"
                  className="bg-customGreen text-white "
                  onClick={() => {
                    onSubmitRemark();
                  }}
                />
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </>
  );
}
