import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Button, Modal } from "@mui/material";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BlankProfile from "../../../../../assets/registrationAssets/blankProfile.jpeg";

import { Box } from "@mui/system";
import { format, isAfter, parseISO } from "date-fns";
import dayjs from "dayjs";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../../common/components/FormFields/InputField";
import InputLimit from "../../../../../common/components/FormFields/InputLimit";
import SearchDropdown from "../../../../../common/components/FormFields/searchDropdown";
import { warningAlert } from "../../../../../common/components/Toasts/CustomToasts";
import {
  fetchBirthDetails,
  fetchGenders,
  fetchNationality,
  fetchPrefix,
} from "../../../../../commonServices/miscellaneousServices/MiscServices";
import { fetchAdharStatus } from "../../../../../opd/services/registrationServices/PatientRegistrationServices";
import {
  fetchEmergencyNumber,
  fetchSearchDropDown,
} from "../../../../services/admissionServices/AdmissionServices";
import UploadProfileModal from "./UploadProfileModal";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";

const PersonalInfo = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [enteredAge, setEnteredAge] = useState();
  const [genders, setGenders] = useState([]);
  const [nationality, setNationality] = useState();
  const [emergencyNumber, setEmergencyNumber] = useState();
  const [prefixData, setPrefixData] = useState();
  const [searchData, setSearchData] = useState();

  const [isDisabled, setIsDisabled] = useState(false);
  const {
    profilePic,
    setProfilePic,
    setProfilePicName,
    searchPatientId,
    setsearchPatientId,
    IsEmergency,
    location,
    setIsDisableSearch,
    patchData,
    setPatchData,
    // dob,
    // setDob,
    setCasualtyDate,
    setCasualtyTime,
    isForEdit,
  } = props;

  const {
    control,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const isNoEmail = watch("noEmail");


  ///////////////// Api integration below /////////////////////

  // // API to Get Patient Data

  const handleChange = (e) => {
    let search = e;
    if (search && search.length !== null) {
      fetchSearchDropDown(search, IsEmergency)
        .then((response) => {
          setSearchData(response.data.result);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    getPrefix();
    getGenders();
    getNationality();
    getEmergencyNumber();

    ///populate data in fields
  }, [searchPatientId]);

  const getPrefix = () => {
    fetchPrefix()
      .then((response) => response.data)
      .then((res) => {
        setPrefixData(res.result);
      })
      .catch(() => {});
  };

  //  DOB ==1st case [[API Calculate Age Based on DOB]]
  const getAgeDOB = (e) => {
    let dobValue = e;
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    fullDOB.length === 10 &&
      fetchBirthDetails(0, fullDOB).then((response) => {
        setValue("age", Number(response.data.result.years), {
          shouldValidate: true,
        });
        setValue(
          "ageInYears",
          response?.data?.result?.years ? response?.data?.result?.years : 0
        );
        setValue(
          "ageInMonths",
          response.data.result.months ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days ? response.data.result.days : 0
        );
      });
  };

  //DOB == 2nd case [[API Calculate DOB based on Age]]
  useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA").then((response) => {
        //       // setDobState(`${response.data.result.dob}T00:00:00.000Z`);
        setValue("dobDate", `${response.data.result.dob}T00:00:00.000Z`, {
          shouldValidate: true,
        });
        setValue("ageInYears", Number(enteredAge));
        setValue(
          "ageInMonths",
          response.data.result.months ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days ? response.data.result.days : 0
        );
      });
    }
  }, [enteredAge]);

  //API For Gender

  const getGenders = () => {
    fetchGenders().then((response) => {
      setGenders(response.data.result);
    });
  };

  //API For Nationality List

  const getNationality = () => {
    fetchNationality().then((response) => {
      setNationality(response.data.result);
    });
  };

  //API For  emergency number

  const getEmergencyNumber = () => {
    fetchEmergencyNumber()
      .then((response) => {
        setEmergencyNumber(response.data.result);
      })
      .catch(() => {});
  };

  //API For adhar status
  const getAdharStatus = (aadharNo) => {
    fetchAdharStatus(aadharNo).then((response) => {
      Number(aadharNo) === 0 && props.setDuplicateAdhar(false); ///if zero allow to submit
      if (response.data.result) {
        props.setDuplicateAdhar(true);
        warningAlert(response.data.message); //checked also @ onsubmit
      } else if (!response.data.result) {
        props.setDuplicateAdhar(false);
      }
    });
  };

  useEffect(() => {
    if (location.state !== null) {
      setIsDisableSearch(location.state.isEditInfo);
    }
  }, [location]);

  useEffect(() => {
    if (patchData !== null && patchData?.adharNo !== null) {
      const isAadharNumberValid = /^(\d)\1{11}$/.test(patchData?.adharNo);
      isAadharNumberValid && getAdharStatus(patchData?.adharNo);
    }
    if (patchData) {
      fetchBirthDetails(0, format(new Date(patchData?.DOB), "yyyy-MM-dd")).then(
        (response) => {
          // setDobState(`${response.data.result.dob}T00:00:00.000Z`);
          setValue("dobDate", patchData?.DOB, {
            shouldValidate: true,
          });
          setValue("age", Number(response.data.result?.years));
          setValue("ageInYears", Number(response.data.result?.years));
          setValue(
            "ageInMonths",
            response.data.result.months ? response.data.result.months : 0
          );
          setValue(
            "ageInDays",
            response.data.result.days ? response.data.result.days : 0
          );
        }
      );
    }
  }, [patchData]);

  return (
    <div>
      <div className="grid lg:grid-cols-10 md:grid-cols-5 sm:grid-cols-3  gap-3  ">
        <div className=" lg:col-span-9 md:col-span-4 sm:col-span-2 grid lg:grid-cols-6 md:grid-cols-4 gap-3 ">
          {/* //Patient Search // */}
          <div className=" col-span-4 lg:grid lg:grid-cols-2">
            <div className="">
              <SearchDropdown
                control={control}
                placeholder="Search Patient Name/MRNo/Mob No"
                dataArray={searchData}
                name="searchPatient"
                searchIcon={true}
                isClearable={true}
                handleInputChange={handleChange}
                isDisabled={isForEdit}
                inputRef={{
                  ...register("searchPatient", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        setsearchPatientId(e.target.value.id);
                      } else {
                        setsearchPatientId(null);
                        setPatchData(null);
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>

          {/* //Select Emergency// */}
          <div className="lg:col-span-2 md:col-span-2">
            <DropdownField
              control={control}
              name="SelectEmergencyNo"
              label="Emergency"
              dataArray={emergencyNumber}
              isSearchable={false}
              isDisabled={false}
              isClearable={false}
              placeholder="Emergency *"
              inputRef={{
                ...register("SelectEmergencyNo", {
                  onChange: (e) => {
                    setCasualtyDate(parseISO(e.target.value.Date));
                    let time = parseISO(e.target.value.Time);
                    setCasualtyTime(dayjs(time));
                  },
                }),
              }}
            />
          </div>
          {/* //Title & First Name//// */}
          <div className="grid grid-cols-3 md:col-span-2 lg:col-span-2 gap-3">
            {/* //Title// */}
            <div className="col-span-1 ">
              <DropdownField
                control={control}
                error={errors.prefix}
                name="prefix"
                label="Prefix *"
                dataArray={prefixData}
                //dataArray={dummyObj}
                isSearchable={false}
                placeholder="Prefix *"
                isClearable={false}
                inputRef={{
                  ...register("prefix", {
                    onChange: (e) => {
                      //
                      setValue(
                        "gender",
                        {
                          id: e.target.value.SexId,
                          label: e.target.value.gender,
                          value: e.target.value.gender,
                        },
                        { shouldValidate: true }
                      );
                    },
                  }),
                }}
              />
            </div>

            <div className="col-span-2">
              {/* //first Name// */}
              <InputField
                name="firstName"
                variant="outlined"
                label="First Name *"
                error={errors.firstName}
                control={control}
                disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              />
            </div>
          </div>
          {/* //Middle Name// */}
          <div className="col-span-2">
            <InputField
              name="middleName"
              variant="outlined"
              label="Middle Name "
              // error={errors.middleName}
              control={control}
              disabled={isDisabled}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          {/* //last Name// */}
          <div className="col-span-2 ">
            <InputField
              name="lastName"
              variant="outlined"
              label="Last Name *"
              error={errors.lastName}
              control={control}
              disabled={isDisabled}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          {/* //Date of Birth and Age // */}
          <div className="lg:col-span-2 md:col-span-2">
            <div className="grid grid-cols-3 gap-3">
              {/* //Date of Birth // */}

              <div className="col-span-2 ">
                <DatePickerFieldNew
                  control={control}
                  error={errors.dobDate}
                  name="dobDate"
                  label="Date of Birth"
                  value={new Date()}
                  inputRef={{
                    ...register("dobDate", {
                      onChange: (e) => {
                        isAfter(e?.target?.value, new Date(1900, 1, 1)) &&
                          getAgeDOB(e?.target?.value);
                      },
                    }),
                  }}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              {/* //Age// */}
              <div>
                <InputLimit
                  maxDigits={3}
                  name="age"
                  variant="outlined"
                  label="Age *"
                  error={errors.age}
                  control={control}
                  inputRef={{
                    ...register("age", {
                      onChange: (e) => {
                        setEnteredAge(e.target.value);
                      },
                    }),
                  }}
                  onKeyDown={(e) => {
                    if (
                      e?.key === "-" ||
                      e?.key === "+" ||
                      e?.code === "ArrowUp" ||
                      e?.code === "ArrowDown" ||
                      e?.code === "NumpadDecimal"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* //Years Months Days // */}
          <div className=" md:col-span-2 lg:col-span-2">
            <div className="grid grid-cols-3 gap-3">
              {/* //Years// */}

              <InputField
                name="ageInYears"
                variant="outlined"
                label="Years"
                disabled={true}
              />
              {/* //Months// */}
              <InputField
                name="ageInMonths"
                variant="outlined"
                label="Months"
                disabled={true}
              />
              {/* //Days// */}
              <InputField
                name="ageInDays"
                variant="outlined"
                label="Days"
                disabled={true}
              />
            </div>
          </div>
          {/* //Gender// */}
          <div className="">
            <DropdownField
              control={control}
              error={errors.gender}
              name="gender"
              label="genders"
              dataArray={genders}
              isSearchable={false}
              isClearable={false}
              placeholder="Gender *"
            />
          </div>

          {/* //Mobile Number// */}
          <div className="">
            <InputLimit
              name="mobileNumber"
              control={control}
              label="Mobile *"
              error={errors.mobileNumber}
              disabled={isDisabled}
              required
              maxDigits={10}
              type={"number"}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </div>

          <div className="md:col-span-2 lg:col-span-2">
            <InputLimit
              maxDigits={10}
              name="altMobileNumber"
              // error={errors.altMobileNumber}
              variant="outlined"
              label="Alternate/WhatsApp Mobile "
              control={control}
              disabled={isDisabled}
              type={"number"}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          {/* //Email Id // */}
          <div className="col-span-2 grid grid-cols-4 gap-3 ">
            <div>
              <CheckBoxField
                control={control}
                name="noEmail"
                label="No Email"
                style={{ paddingRight: 0, paddingLeft: 1 }}
              />
            </div>
            {!isNoEmail && (
              <div className="col-span-3">
                <InputField
                  name="email"
                  variant="outlined"
                  label="Email Id"
                  error={errors.email}
                  control={control}
                  disabled={isDisabled}
                  inputProps={{ maxLength: 100 }}
                  dontCapitalize={true}
                />
              </div>
            )}
          </div>
          {/* //Adhar Card Number */}
          <div className="col-span-2 ">
            <InputLimit
              maxDigits={12}
              name="adharNumber"
              type={"number"}
              variant="outlined"
              label="Aadhar Card Number *"
              error={errors.adharNumber}
              control={control}
              disabled={isDisabled}
              inputProps={{ maxLength: 100 }}
              inputRef={{
                ...register("adharNumber", {
                  onChange: (e) => {
                    if (e.target.value.length === 12) {
                      if (
                        patchData !== null &&
                        patchData.adharNo !== e.target.value
                      ) {
                        getAdharStatus(e.target.value);
                      } else if (patchData === null) {
                        getAdharStatus(e.target.value);
                      }
                    } else {
                      props.setDuplicateAdhar(false);
                    }
                  },
                }),
              }}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>

        {/* //Profile Pic// */}
        <div className=" row-span-2  ">
          <div className="flex justify-center -mt-2 border border-slate-300 rounded-md">
            {profilePic !== BlankProfile ? (
              <CancelOutlinedIcon
                onClick={() => {
                  setProfilePic(BlankProfile);
                }}
                sx={{
                  position: "absolute",
                  right: "16px",
                  zIndex: 10,
                  color: "#b91c1c",
                }}
              />
            ) : null}

            <Button onClick={handleOpen} disabled={isDisabled}>
              <div className="flex flex-col  ">
                <img
                  src={profilePic}
                  alt="Profile Pic"
                  className="h-28 w-32 mx-auto object-scale-down"
                />
                {profilePic === BlankProfile ? (
                  <p className="text-xs md:row-start-6 md:col-span-2 lg:row-start-5 lg:col-span-1">
                    Upload Profile
                  </p>
                ) : (
                  <p className="text-xs md:row-start-6 md:col-span-2 lg:row-start-5 lg:col-span-1">
                    Change Profile
                  </p>
                )}
              </div>
            </Button>
          </div>
        </div>
      </div>

      {/* //Patient Profile Pic Modal // */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            width: { lg: "30%", md: "50%" },
            borderRadius: "0.5rem",
          }}
        >
          <UploadProfileModal
            setProfilePic={setProfilePic}
            setOpen={setOpen}
            setProfilePicName={setProfilePicName}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default PersonalInfo;
