import * as React from "react";

//imports from material ui library
import { Box, Modal, Tooltip } from "@mui/material";

import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";

//icon for closing the modal form
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

import { useForm } from "react-hook-form";

import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";

import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import {
  fetchNursingAssessmentList,
  getAssessmentPrint,
} from "../../../services/cliinicalChartServices/initialNursingAssessment/InitialNursingAssessmentServices";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  // height: "80%",
  overflowY: "scroll",
  overflowX: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

function AssessmentListingModal(props) {
  const defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  const { control, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //Below are the props required for the
  //CommonDynamicTablePagination component
  //--------------------------------------------------------------------------------
  const [count, setCount] = React.useState();

  const [data, setData] = React.useState({ result: [] });

  const [page, setPage] = React.useState(0);

  const [dataResult, setDataResult] = React.useState([]);

  const [fromDateObj, setFromDateObj] = React.useState(new Date());

  const [toDateObj, setToDateObj] = React.useState(new Date());

  //State variable to get the page size from the child component.
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const [urlforPrint, setUrlforPrint] = React.useState();

  const Actions = [
    {
      id: 0,
      action: "printAssessment",
      isAction: true,
    },
  ];

  // React.useEffect(() => {
  //   let searchObj = {
  //     admissionId: 0,
  //     fromDate: new Date(),
  //     page: page,
  //     size: rowsPerPage,
  //     toDate: new Date(),
  //   };

  //   fetchNursingAssessmentList(searchObj).then((response) => {
  //     let tempObj = {};
  //     tempObj.result = response.data.result;
  //     tempObj.statusCode = response.data.statusCode;

  //     setData(tempObj);

  //     if (forPagination) {
  //       setDataResult((prevData) => [...prevData, ...response.data.result]);
  //     } else {
  //       setDataResult(response.data.result);
  //       setPage(0);
  //     }
  //     setCount(response.data.count);
  //   });
  // }, []);

  //populate the CommonMasterTable using the function populateTable
  // const populateTable = (forPagination) => {
  //   let searchObj = {
  //     admissionId: 0,
  //     fromDate: fromDateObj,
  //     page: !forPagination ? 0 : page,
  //     size: rowsPerPage,
  //     toDate: toDateObj,
  //   };

  //   fetchNursingAssessmentList(searchObj).then((response) => {
  //     let tempObj = {};
  //     tempObj.result = response.data.result;
  //     tempObj.statusCode = response.data.statusCode;

  //     setData(tempObj);
  //     if (forPagination) {
  //       setDataResult((prevData) => [...prevData, ...response.data.result]);
  //     } else {
  //       setDataResult(response.data.result);
  //       setPage(0);
  //     }
  //     setCount(response.data.count);
  //   });
  // };

  //Below is the renderActions function
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((singleActionObj) => (
          <>
            <div className="flex gap-2 cursor-pointer">
              {singleActionObj.action === "printAssessment" &&
              singleActionObj.isAction === true ? (
                <>
                  <Tooltip title="Print Patient Assessment">
                    <LocalPrintshopIcon
                      sx={{ color: "#4B5563" }}
                      onClick={() => {
                        let admissionIdVal = row.AdmissionID;
                        let initialNursingAssId = row.id;

                        handleOpenPrintModal(
                          admissionIdVal,
                          initialNursingAssId
                        );
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ))}
      </div>
    );
  };

  //function to return the date in yyyy-MM-DD format
  function getRequiredDateVal(inputDateVal) {
    let requiredDate = null;

    let objectDate = new Date(inputDateVal);

    let day = String(objectDate.getDate());

    if (day.length === 1) {
      day = `0${day}`;
    }

    let month = objectDate.getMonth();

    month = month + 1;

    month = String(month);

    if (month.length === 1) {
      month = `0${month}`;
    }

    let year = objectDate.getFullYear();

    requiredDate = `${year}-${month}-${day}`;

    if (requiredDate.length === 10) {
      return requiredDate;
    } else {
      return null;
    }
  }

  //Event listener function for the click of search icon
  function populateTable(forPagination) {
    const admissionIdVal = props?.patientInformation?.AdmissionId;
    const fromDateValue = getRequiredDateVal(getValues("fromDate"));
    const toDateValue = getRequiredDateVal(getValues("toDate"));
  
    const searchObj = {
      admissionId: admissionIdVal,
      fromDate: fromDateValue ? new Date(fromDateValue)?.toISOString() : null,
      toDate: toDateValue ? new Date(toDateValue)?.toISOString() : null,
      page: !forPagination ? 0 : page,      size: rowsPerPage,
    };
  
    fetchNursingAssessmentList(searchObj).then((response) => {
      const { result, statusCode, count } = response.data;
  
      setData({ result, statusCode });
      
      if (forPagination) {
        setDataResult((prevData) => [...prevData, ...result]);
      } else {
        setDataResult(result);
        setPage(0);
      }
  
      setCount(count);
    });
  }
  

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleOpenPrintModal = (admissionIdVal, initialNursingAssId) => {
    getAssessmentPrint(admissionIdVal, initialNursingAssId).then((response) => {
      setOpenPrintModal(true);

      setUrlforPrint(response);
    });
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              onClick={() => {
                props.setOpen(false);
              }}
              style={{
                paddingLeft: 10,
              }}
            />
          </div>

          <div className="row">
            <fieldset className="border border-gray-300 text-left  lg:mx-auto lg:px-4 lg:m-2  md:ml-0 md:mr-0  rounded   ">
              <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                Nursing Assessment List
              </legend>

              <div className="grid lg:grid-cols-5 gap-2 md:grid-cols-2 md:p-5 items-center">
                {/* This is the div for date picker */}
                <div>
                  <DatePickerFieldNew
                    name="fromDate"
                    control={control}
                    label="From Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                      setFromDateObj(newValue);
                    }}
                    // disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* This is the div for date picker. */}
                <div>
                  <DatePickerFieldNew
                    name="toDate"
                    control={control}
                    label="To Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                      setToDateObj(newValue);
                    }}
                    // disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* This is the div for SearchIcon button. */}
                <div>
                  <CommonButton
                    searchIcon
                    className="bg-customBlue text-white"
                    onClick={() => {
                     // populateAssessmentListTable();
                     populateTable();
                    }}
                  />
                </div>
              </div>
            </fieldset>

            {data.hasOwnProperty("result") &&
            data.result.length > 0 &&
            data.statusCode === 200 ? (
              <CommonDynamicTablePaginationNew
                dataResult={dataResult}
                renderActions={renderActions}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                tableClass={"rounded lg:h-72 2xl:h-96"}
              />
            ) : (
              <>
                <div className="text-center mt-10">No Record Found</div>
              </>
            )}
          </div>
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}

export default AssessmentListingModal;

