import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { Checkbox } from "@mui/material";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { BodyHandedOverConsentInamdarAndIpIssuePrint } from "../services/etuRegisterServices/etuRegisterServices";

export default function BodyHandedOverPrintAll(props) {
  const [dataResult, setDataResult] = React.useState();
  const printRef = useRef(null);

  let printObj = {
    fromDate: props?.selectedFromDate,
    toDate: props?.selectedToDate,
  };

  React.useEffect(() => {
    BodyHandedOverConsentInamdarAndIpIssuePrint(printObj)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, []);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <table className=" w-full" ref={printRef}>
      <thead
        className=" w-full"
        style={{
          "-fs-table-paginate": "paginate",
        }}
      >
        <h1 className=" text-[13px] font-semibold text-center border-black border-b-[1px]">
          Body Handover Register
        </h1>
        <div className="  grid grid-cols-[8%_25%_8%_5%_5%_5%_14%_15%_15%] text-[11px] border-b-[1px] border-black">
          <div>MR No.</div>
          <div>Patient Name</div>
          <div>Date</div>
          <div>IsMLC</div>
          <div>IsPM</div>
          <div>IsDC</div>
          <div>Body Handed Over To</div>
          <div>Body Handed Over By</div>
          <div>Body Hand Over Remark</div>
        </div>
      </thead>

      <tbody className=" w-full">
        <div>
          {dataResult?.data?.map((dataTable) => {
            let mlcTrue;
            if (dataTable.IsMLC === 1) {
              mlcTrue = true;
            } else {
              mlcTrue = false;
            }
            let IsPMTrue;
            if (dataTable.IsPM === 1) {
              IsPMTrue = true;
            } else {
              IsPMTrue = false;
            }
            let IsDCTrue;
            if (dataTable.IsDC === 1) {
              IsDCTrue = true;
            } else {
              IsDCTrue = false;
            }

            return (
              <div className=" grid grid-cols-[8%_25%_8%_5%_5%_5%_14%_15%_15%]">
                <div className=" mt-2 text-[11px]">{dataTable?.MRNo}</div>
                <div className=" mt-2 text-[11px]">
                  {dataTable?.patientName}
                </div>
                <div className=" mt-2 text-[11px]">{dataTable?.date}</div>
                <div className="">
                  <Checkbox size="small" checked={mlcTrue} className="" />
                </div>
                <div className="">
                  <Checkbox size="small" checked={IsPMTrue} />
                </div>
                <div className="">
                  <Checkbox size="small" checked={IsDCTrue} />
                </div>
                <div className=" mt-2 text-[11px]">
                  {dataTable?.BodyHandedOverTo}
                </div>
                <div className=" mt-2 text-[11px]">
                  {dataTable?.BodyHandedOverBy}
                </div>
                <div className=" mt-2 text-[11px]">
                  {dataTable?.BodyHandoverRemark}
                </div>
              </div>
            );
          })}
        </div>
      </tbody>
    </table>
  );
}
