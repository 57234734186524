import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button, Tooltip } from "@mui/material";
//set descending sort order

export default function AddServicesTable(props) {
  let cloned = structuredClone(props.data);
  const deleteService = (index) => {
    let resultantArray = cloned.filter((row) => row.serviceId !== index);

    props.setServiceArr(resultantArray);
  };

  return (
    <>
      <div className="grid w-[100%]">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-56 2xl:h-60"
            >
              <Table stickyHeader>
                <TableHead className="flex justify-between">
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                    <TableCell>Service Code</TableCell>
                    <TableCell>Service Description</TableCell>
                    <TableCell>Rate / Gross</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0.5,
                          },
                        }}
                      >
                        <TableCell className="justify-center">
                          {
                            <Button
                              onClick={() => {
                                deleteService(row.serviceId);
                              }}
                            >
                              <Tooltip title="Delete">
                                <DeleteOutlineOutlinedIcon className="text-red-500 cursor-pointer" />
                              </Tooltip>
                            </Button>
                          }
                        </TableCell>
                        <TableCell>{row.serviceCode}</TableCell>
                        <TableCell>{row.serviceDiscription}</TableCell>
                        <TableCell>{row.testCharges}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
