export const numberOfPatientsData = [
  {
    name: "Mon",
    "New Patients": 400,
    "Old Patients": 240,
  },
  {
    name: "Tue",
    "New Patients": 300,
    "Old Patients": 139,
  },
  {
    name: "Wed",
    "New Patients": 200,
    "Old Patients": 680,
  },
  {
    name: "Thu",
    "New Patients": 278,
    "Old Patients": 390,
  },
  {
    name: "Fri",
    "New Patients": 189,
    "Old Patients": 480,
  },
  {
    name: "Sat",
    "New Patients": 239,
    "Old Patients": 380,
  },
];
