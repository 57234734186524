import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Modal, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchCompany } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchPreviousReceiptList,
  opdCompanySettlementPdfPrint,
} from "../../../services/companySettlementServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  // height: "60%",
  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 3,
};

const Actions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
];
const removeHeaders = [
  "TDSPercentage",
  "TDSPercentage",
  "Paid Amount",
  "Paid Amount",
  "ReceiptTime",
  "Narration",
  "CashPayAmount",
  "ChequeNo",
  "ChequeDate",
  "ChequePayAmount",
  "DDNo",
  "DDDate",
  "DDPayAmount",
  "DDBankName",
  "CreditCardNo",
  "CreditCardPayAmount",
  "DebitCardNo",
  "DebitCardPayAmount",
  "DDBankName ",
  "CreditCardBankName",
  "DebitCardBankName",
  "ChequeBankName",
  "TrustName",
  "PaymentType",
];
const dietOptions = [];
const actions = [""];
function PreviousCompSettlementModal(props) {
  const defaultValues = {
    company: null,
  };
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [companyDropdown, setCompanyDropdown] = useState("");
  const [receiptList, setReceiptList] = useState([]);
  let companyId = getValues("company");
  //useEffect
  useEffect(() => {
    if (props.openPreviourReceipt) {
      fetchCompany()
        .then((response) => response.data)
        .then((res) => {
          setCompanyDropdown(res.result);
        });
    }
  }, []);

  function getPreviourCompanySettlementReceipts() {
    let receiptObj = {
      companyId: companyId?.id,
      fromDate: selectedFromDate ? selectedFromDate : new Date(),
      opdIpdExternalId: 0,
      toDate: selectedToDate ? selectedToDate : new Date(),
    };
    fetchPreviousReceiptList(receiptObj)
      .then((response) => response.data)
      .then((res) => {
        setReceiptList(res.result);
      });
  }
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "print" ? (
                    <Tooltip title={"Print"}>
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          handleOpenPrintModal(row?.PaymentId);
                          // settlementReceiptPdf(row?.PaymentId);
                          // setOpenSettlementPrintModal(true);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  //prints print
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (paymentId) => {
    opdCompanySettlementPdfPrint(paymentId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };
  function handleSelectedRow(row) {}
  return (
    <div className=" bg-white px-6">
      <Modal
        open={props.openPreviourReceipt}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          {/* <div className="text-center ">Company Settelement Receipts</div> */}
          <div className="grid grid-cols-1 md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleCloseReceipt();
                setReceiptList([]);

                setValue("fromDateCalender", new Date());
                setValue("toDateCalender", new Date());

                setValue("company", null);
              }}
              style={{
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="grid grid-cols-5 gap-2 py-2">
            <div className="col-span-2">
              <DropdownField
                control={control}
                name="company"
                label="Company"
                placeholder="Company"
                dataArray={companyDropdown}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="fromDateCalender"
                label="From Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
                inputRef={{
                  ...register("fromDateCalender", {
                    onChange: (e) => {
                      let formatedDate = format(e.target.value, "yyyy-MM-dd");
                      let formatedDateTime =
                        formatedDate + "T" + "18:30:00.000Z";
                      setSelectedFromDate(formatedDateTime);
                    },
                  }),
                }}
              />
            </div>
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="toDateCalender"
                label="To Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
                inputRef={{
                  ...register("toDateCalender", {
                    onChange: (e) => {
                      let formatedDate = format(e.target.value, "yyyy-MM-dd");
                      let formatedDateTime =
                        formatedDate + "T" + "18:30:00.000Z";

                      setSelectedToDate(formatedDateTime);
                    },
                  }),
                }}
              />
            </div>
            <div className="">
              <CommonButton
                searchIcon
                className="bg-customBlue text-white"
                onClick={() => {
                  getPreviourCompanySettlementReceipts();
                }}
              />
            </div>
          </div>
          <div className="col-span-3 w-full ">
            <span className="font-bold py-1">
              List Of Company Settlement Receipts
            </span>
            {receiptList.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={receiptList}
                renderActions={renderActions}
                highlightRow={false}
                handleSelectedRow={handleSelectedRow}
                removeHeaders={removeHeaders}
                tableClass={"rounded lg:h-52 md:h-72"}
              />
            ) : (
              <>
                <div className="text-center mt-4 p-4">No Record Found</div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
}

export default PreviousCompSettlementModal;
