import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import AlternateDrugTable from "./AlternateDrugTable";
import DrugTable from "./DrugTable";
import GetIndentModal from "./GetIndentModal";
// import InPatientListModal from "./inPatientIssueList/InPatientIssueListModal";
import { useRef } from "react";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import ItemDetailsTable from "./ItemDetailsTable";
import IssueToDeptListModal from "./issueToDeptListing/issueToDeptListModal";

import { parseISO } from "date-fns";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchApplicableStores,
  fetchStore,
} from "../../../../commonServices/miscellaneousServices/MiscServices";
import * as yup from "yup";
import {
  fetchKitListItems,
  fetchStoreDropdown,
} from "../../../services/commonServices/CommonServices";
import { fetchReceivedBy } from "../../../services/commonServices/issueToDept/IssueToDept";
import {
  fetchAlternateDrugNameTable,
  fetchDrugNameTable,
} from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import {
  fetchKitList,
  fetchPrintIssueToDept,
  postIssueToDeptData,
} from "../../../services/pharmacy/issueToDeptServices/IssueToDeptServices";
import NumberOfCopiesModal from "../../../../common/components/printModal/NumberOfCopiesModal";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { blobToBase64 } from "../../../../common/components/printModal/BlobToB64";
import { routeToPrinterDevice } from "../../../../commonServices/barCodeServices/BarCodeServices";

const schema = yup.object().shape({
  issueReceivedBy: yup
    .object()
    .nullable()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Required"),
});

const GsIssueToDept = () => {
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = token.storeId;

  //
  //prints
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [printResponse, setPrintResponse] = useState(null);

  //for show quantity errors
  const [customError, setCustomError] = useState();
  const [stores, setStores] = useState();
  const [receivedBy, setReceivedBy] = useState();
  //
  const [inputOneFocus, setInputOneFocus] = useState(false);
  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  //indent modal
  const [openIndentModal, setOpenIndentModal] = React.useState(false);
  const handleOpenIndentModal = () => setOpenIndentModal(true);
  const handleCloseIndentModal = () => setOpenIndentModal(false);
  const [selectedIndentTbleRow, setSelectedIndentTbleRow] = useState(null);

  // InPatientListModal
  const [openIssueListModal, setOpenIssueListModal] = React.useState(false);
  const handleOpenIssueListModal = () => setOpenIssueListModal(true);
  const handleCloseIssueListModal = () => setOpenIssueListModal(false);
  //autocomlete
  const [patientDetails, setPatientDetails] = React.useState(null);
  //search string of "search by item/input-1" input
  const [drugSearchString, setDrugSearchString] = useState();
  //table data

  const [drugTableData, setDrugTableData] = useState([]);
  const [altDrugTableData, setAltDrugTableData] = useState([]);
  const [selectedGenericId, setSelectedGenericId] = useState();
  const [selectedDrugRowData, setSelectedDrugRowData] = useState(null);
  //last selected index
  const [lastIndex, setLastIndex] = React.useState();
  // itm details drg tbl
  const [selectedItemDtlsID, setSelectedItemDtlsID] = React.useState(null);
  const [selectedDrug, setSelectedDrug] = React.useState(null);
  const [errorToTblInput, setErrorToTblInput] = useState(false);

  //used to substitute
  const [selctedRowData, setSelctedRowData] = React.useState(null);
  const [itemToBeSubstituted, setItemToBeSubstituted] = useState(null);

  //Alt drug tbl Row id
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = React.useState(null);
  //item Kit List
  const [itemKitList, setItemKitList] = React.useState();
  //item against Kit
  const [itemKitListItemsData, setItemKitListItemsData] = React.useState();
  // drug tbl Row id
  const [selectedDrugRowID, setSelectedDrugRowID] = React.useState(0);

  const [selectedDrugData, setSelectedDrugData] = useState([]);
  ///calculations populate
  const [noOfItems, setNoOfItems] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  //empty input on enter setEmptyDrugInput
  const [emptyDrugInput, setEmptyDrugInput] = useState();
  //open batch selection modal
  const [openBatchModal, setOpenBatchModal] = React.useState(false);
  const handleOpenBatchModal = () => setOpenBatchModal(true);
  const handleCloseBatchModal = () => setOpenBatchModal(false);

  /////flag to check batch selected inside table input
  const [batchInsideTableInput, setBatchInsideTableInput] = useState(null);

  // selected row data of batch table inside batch selection modal
  const [batchSelctedRowData, setBatchSelctedRowData] = React.useState(null);

  //selected from get indent table-2
  //  const [selectedIndentListItems, setSelectedIndentListItems] = useState([]);

  const location = useLocation();

  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const batchInputFocus = useRef(null);
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      // storeName: "",
      toStore: null,
      totalAmount: 0,
      netPayableAmount: 0,
      roundedAmount: 0,
      indentConditionRadio: true,
      issueReceivedBy: null,

      "input-1": "", //item search
      "input-2": "", //batch no
      "input-3": "", //item qty
      "input-4": null, //kit search
      "input-5": "", //kit qty
    },
  });

  const {
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    register,
    setValue,
    watch,
    resetField,
  } = methods;

  const getIndentInfo = () => {
    handleOpenIndentModal();
  };
  const getIssueList = () => {
    handleOpenIssueListModal();
  };

  useEffect(() => {
    ///focus input 1 on add button click
    if (patientDetails !== null) {
      inputRefOne.current.focus();
    }
    getStoreNameDropdown();
  }, [patientDetails]);

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);
  const inputRefFive = useRef(null);
  const inputRefaddItem = useRef(null);
  const inputRefAddKit = useRef(null);

  function focusKitSearchDropdown() {
    let fieldsetTag = inputRefFour.current;

    let inputTagCollection = fieldsetTag.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];

    inputTagOne.focus();
  }

  //use ref hook to shift the focus on batch selection modal
  const FocusBatchModal = useRef();
  const FocusDrugTable = useRef();
  // const FocusItemDetailsTable = useRef();
  // const removeFocus = useRef();
  const removeFocusSearch = useRef(null);
  // const typeDrugNameInputField = useRef();

  // //The focus batch modal
  const focusBatchSelModal = () => {
    if (FocusBatchModal.current) {
      FocusBatchModal.current.focus();
    }
  };
  // //The focus Drug Table
  const focusDrugTable = () => {
    if (FocusDrugTable.current) {
      FocusDrugTable.current.focus();
    }
  };

  //////// on enter batch input open batch modal
  const handleKeypressForBatch = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      if (selectedDrug !== null) {
        setBatchInsideTableInput(false);
        handleOpenBatchModal();
        setSelctedRowData(null);
      }
    }
  };

  const removeFocusItmDtlsTbl = () => {};

  const removeFocusInputSearch = () => {
    if (removeFocusSearch.current) {
      removeFocusSearch.current.blur();
    }
  };

  useEffect(() => {
    setValue("FromStore", {
      id: token.storeId,
      label: token.storeName,
      value: token.storeName,
    });

    fetchStoreDropdown()
      .then((response) => response.data)
      .then((res) => {
        setStores(res.result);
      });

    // fetchApplicableStores(storeId)
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setStores(res.result);
    //   });

    fetchReceivedBy()
      .then((response) => response.data)
      .then((res) => {
        setReceivedBy(res.result);
      });
  }, []);

  useEffect(() => {
    if (openBatchModal) {
      focusBatchSelModal();
      removeFocusItmDtlsTbl();
    }
  }, [openBatchModal]);

  useEffect(() => {
    if (drugTableData?.length > 0) {
      focusDrugTable();
    }
  }, [drugTableData]);

  useEffect(() => {
    if (!!selectedIndentTbleRow) {
      setValue("toStore", {
        id: selectedIndentTbleRow.FromStoreId,
        label: selectedIndentTbleRow.FromDepartment,
        value: selectedIndentTbleRow.FromDepartment,
      });
    } else {
      setValue("toStore", null);
    }
  }, [selectedIndentTbleRow]);

  //search by item kit
  const searchByItemKit = (e) => {
    fetchKitList(e)
      .then((response) => response.data)
      .then((res) => {
        setItemKitList(res.result);
      });
  };

  ///kit list items
  const getKitListItems = (e) => {
    if (e.target.value !== null && e.target.value !== undefined) {
      fetchKitListItems(e.target.value.id, token.storeId)
        .then((response) => response.data)
        .then((res) => {
          setItemKitListItemsData(res.result);
        });
    }
  };

  //search by item name/drug name table data
  const watchInput1 = watch("input-1");
  const watchInput2 = watch("input-2");
  const ToStore = watch("toStore", null);
  const withIndent = watch("indentConditionRadio");

  useEffect(() => {
    setBatchSelctedRowData(null);

    const drugListObj = {
      deptStore: 1,
      deptStoreId: ToStore?.id,
      isConsignment: 0,
      itemCode: "0",
      itemName: "%",
      // searchString: watchInput1,
      searchString: drugSearchString,
    };

    // let token = JSON.parse(localStorage.getItem("userInfo"));
    // let storeId = token.cashCounterId;
    if (
      // inputOneFocus === true &&
      drugSearchString !== ""
      // watchInput1 !== undefined &&
      // watchInput1 !== ""
    ) {
      fetchDrugNameTable(drugListObj)
        .then((response) => response.data)
        .then((res) => {
          setDrugTableData(res.result);
        });
    }
    // }
  }, [
    watchInput1,
    // inputOneFocus, drugSearchString
  ]);

  // alternate drug table object
  if (selectedDrugRowData !== null && selectedDrugRowData !== undefined) {
    var altDrugObj = {
      deptStore: 1,
      deptStoreId: token.storeId,
      genericId: selectedDrugRowData.ItemGenericId,
      isConsignment: selectedDrugRowData.IsConsignment ? 1 : 0,
      itemCode: "%", //selectedDrugRowData.Code,
      itemName: "%", //selectedDrugRowData.Drug,
      itemClassId:
        selectedDrugRowData?.ItemClass === 0
          ? null
          : selectedDrugRowData?.ItemClass,
    };
  }

  //only for empty qty(input -3) when batch no. empty(input-2)
  useEffect(() => {
    if (watchInput2 === "") {
      setValue("input-3", "");

      // const e = { target: { name: "input-0" } };
      // onEnterFocusNext(e)
    }
  }, [watchInput2]);

  //
  useEffect(() => {
    // storename dropdown

    getStoreNameDropdown();
    //Alternate drug name data altDrugObj totalAmount

    getAlternateDrugNameTable();
    setValue("noOfItems", selectedDrugData.length);
    setValue("totalQuantity", totalQuantity);
    setValue("totalAmount", totalAmount.toFixed(2));
    setValue("netPayableAmount", totalAmount.toFixed(2));
    setValue("roundedAmount", totalAmount.toFixed(0));

    //test

    //i-1
    // if (selectedDrug !== null) {
    //   setValue("input-1", selectedDrug && selectedDrug.Drug);
    // } else if (watchInput1 === "") {
    //   setValue("input-1", "");
    //   setSelectedDrug(null);
    //   setBatchSelctedRowData(null);
    // }

    //i-2
    if (
      batchSelctedRowData !== null &&
      selectedDrug !== null &&
      !batchInsideTableInput
    ) {
      setValue("input-2", batchSelctedRowData.BatchCode);
    } else if (watchInput1 === "") {
      setValue("input-2", "");
      setValue("input-3", "");
    }
    // else if (watchInput2 === "") {
    //   setValue("input-3", "");
    // }

    //i-3
  }, [
    selectedDrugData,
    selectedDrugRowData,
    storeId,
    selectedGenericId,
    noOfItems,
    totalQuantity,
    emptyDrugInput,
    selectedDrug,
    batchSelctedRowData,
    batchInsideTableInput,
  ]);

  useEffect(() => {
    //i-1
    if (selectedDrug !== null) {
      setValue("input-1", selectedDrug && selectedDrug.Drug);
    }
  }, [selectedDrug]);

  //Alternate drug name data altDrugObj
  const getAlternateDrugNameTable = () => {
    if (selectedDrugRowData !== null && selectedDrugRowData !== undefined) {
      fetchAlternateDrugNameTable(altDrugObj)
        .then((response) => response.data)
        .then((res) => {
          setAltDrugTableData(res.result);
        });
    }
  };
  // storename dropdown
  const getStoreNameDropdown = () => {
    fetchStore(storeId)
      .then((response) => response.data)
      .then((res) => {
        setValue("storeName", res.result.StoreName);
      });
  };

  //add items in item details table table

  const getQuantity = (e) => {
    if (e.target.value === "" || e.target.value > selectedDrug.BalanceQty) {
      setCustomError(true);
    } else if (
      e.target.value !== "" ||
      e.target.value <= selectedDrug.BalanceQty
    ) {
      setCustomError(false);
    }
  };

  //to mearge kit & items  itemwise
  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.Id === val.Id);
      if (ind !== -1) {
        acc[ind]["Quantity"] = acc[ind]["Quantity"] + val["Quantity"];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };
  //add items from drug table / alt drug tbl

  const addToItemDetailsTbl = () => {
    //proceed if only patient selcted
    let quantityValue = getValues("input-3");

    if (!customError && quantityValue !== "" && quantityValue !== "0") {
      const batchValue = getValues("input-2");

      let batchError;
      if (
        selectedDrug?.BatchesRequired === "Yes" &&
        batchSelctedRowData === null
      ) {
        batchError = true;
      } else if (selectedDrug?.BatchesRequired === "No") {
        batchError = false;
      }

      if (batchError) {
        warningAlert("Please Select Batch Number...");
      } else {
        selectedDrug.BatchNumber = batchValue;

        selectedDrug.BatchExpDate = batchSelctedRowData?.BatchExpDatePatch;
        selectedDrug.UnitMRP = batchSelctedRowData?.UnitMRP;
        selectedDrug.BatchQty =
          batchSelctedRowData !== null
            ? batchSelctedRowData?.BalQty
            : selectedDrug.BalanceQty;
        selectedDrug.BalanceQty =
          batchSelctedRowData !== null
            ? batchSelctedRowData?.BalQty
            : selectedDrug.BalanceQty;

        if (batchSelctedRowData !== null) {
          //selectedDrug?.BatchExpDatePatch = batchSelctedRowData?.BatchExpDatePatch;
        }

        selectedDrug.Quantity = parseInt(quantityValue);
        //calculate with add
        selectedDrug.Amount = Number(quantityValue * selectedDrug.UnitMRP);

        let arr = [...selectedDrugData];
        arr.push(selectedDrug);
        setSelectedDrugData(mergeArray(arr));

        ///case-1 :: total Quantity calculation
        let quantity = 0;
        let totalQuantity = 0;

        for (var i = 0; i < arr.length; i++) {
          quantity = arr[i].Quantity;
          totalQuantity += quantity;
        }

        setTotalQuantity(totalQuantity);

        //case-2 :: calculations for amount according to quantity

        let amount = 0;
        let totalAmount = 0;

        for (var i = 0; i < arr.length; i++) {
          quantity =
            arr[i].Quantity !== undefined ? arr[i].Quantity : Number(0);

          amount = Number(quantity) * Number(arr[i].UnitMRP);

          arr[i].Amount = amount;
          totalAmount += amount;
        }

        setTotalAmount(totalAmount);

        setValue("input-1", "");
        setValue("input-2", "");
        setValue("input-3", "");
        setSelectedDrug(null);
        setBatchSelctedRowData(null);
      }
      //
    }
    // ///focus input 1 on add button click
    // const e = { target: { name: "input-0" } };
    // onEnterFocusNext(e);
  };

  //add items from kit list dropdown
  const addKitToItemDetailsTable = () => {
    var KitQty = getValues("input-5");
    let arr = [...selectedDrugData];
    if (itemKitListItemsData !== undefined && KitQty !== "") {
      for (let i = 0; i < itemKitListItemsData.length; i++) {
        let existingQty = itemKitListItemsData[i].Quantity;
        let updatedQty = existingQty * KitQty;
        itemKitListItemsData[i].Quantity = updatedQty;
      }
      const newArr = arr.concat(itemKitListItemsData);
      setSelectedDrugData(mergeArray(newArr));
      // setSelectedDrugData(newArr);
      if (KitQty !== "") {
        clearErrors(["input-4"]);
        setValue("input-4", null);
        setValue("input-5", "");
      } else if (KitQty === "") {
        setError("input-5", { type: "custom", message: "Required" });
        setValue("input-5", null);
      }
      // setValue("input-4", null);
      // setValue("input-5", null);
    }

    // const newArr = arr.concat(itemKitListItemsData);
    // setSelectedDrugData(newArr);

    /////

    // ///focus input 4 on add button click
    // const e = { target: { name: "input-3" } };
    // onEnterFocusNext(e);
  };

  //finalObj
  const finalObj = {
    addedBy: 0,
    fromStoreId: 0,
    isAccepted: true,
    issueToDepartmentDetailsDto: [],
    receivedBy: 0,
    remarks: "",
    toStoreId: 0,
    totalItems: 0,
    totalMrpAmount: 0,
    totalPurAmount: 0,
    totalVatAmt: 0,
    type: true,
    withIndent: true,
  };

  ////onSubmit post object below  selectedDrugData

  const onSubmit = () => {
    let RecievedBy = getValues("issueReceivedBy");

    let validBatch = selectedDrugData.some(
      (item) =>
        item.BatchNumber === "" &&
        (item.BatchesRequired === "Yes" || item.BatchesRequired === true)
    );

    if (ToStore === null) {
      warningAlert("Please Select To Store !");
    } else if (selectedDrugData.length === 0) {
      warningAlert("Please Select Item For Issue!");
    } else if (validBatch) {
      warningAlert("Please Select Batch Number !");
    } else if (errorToTblInput) {
      warningAlert("Please Issue Valid Quantity !");
    } else if (RecievedBy === null) {
      setError("issueReceivedBy", { type: "custom", message: "Required" });
    } else if (!errorToTblInput && selectedDrugData.length > 0) {
      setOpenConfirmationModal(true);
    }
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);
    let RecievedBy = getValues("issueReceivedBy");
    let TotalAmt = getValues("totalAmount");

    //
    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;
    finalObj.addedBy = token.userId;
    finalObj.isAccepted = true;
    finalObj.receivedBy =
      RecievedBy !== undefined && RecievedBy !== null ? RecievedBy.id : 0;
    finalObj.type = true;
    finalObj.withIndent = withIndent;
    finalObj.remarks = getValues("remark");
    finalObj.fromStoreId = token.storeId;
    finalObj.toStoreId = ToStore !== undefined ? ToStore.id : 0;
    finalObj.totalMrpAmount = Number(TotalAmt);
    finalObj.totalPurAmount = Number(TotalAmt);
    finalObj.totalVatAmt = 0; //getValues("totalTaxAmount");

    // to set data according service obj
    let TblData = [];
    for (let i = 0; i < selectedDrugData.length; i++) {
      let tableObj = {
        batchCode: selectedDrugData[i].BatchNumber,
        conversion: selectedDrugData[i].Conversion,
        batchExpDate:
          selectedDrugData[i].BatchExpDatePatch !== undefined
            ? parseISO(selectedDrugData[i].BatchExpDatePatch)
            : "1900-01-01T00:00:00.000Z",
        indentId: selectedDrugData[i].IndentId || 0,
        indentDetailsId: selectedDrugData[i].IndentDetailId || 0,
        isConsignment: true,
        quantity: Number(selectedDrugData[i].Quantity),
        itemId: selectedDrugData[i].Id,
        kitId: 0,
        kitItemQty: 0,
        mrp: Number(selectedDrugData[i].MRP),
        netAmount: Number(selectedDrugData[i].Amount),
        purchaseRate: Number(selectedDrugData[i].PurchaseRate),
        totalAmt: Number(selectedDrugData[i].Amount),
        totalForVat: 0,
        totalMrpAmount: 0,
        totalPurAmount: 0,
        totalVatAmount: 0,
        unitLandedAmount: 0,
        unitLandedRate: 0,
        unitMrp: Number(selectedDrugData[i].UnitMRP),
        vatAmount: 0,
        vatInclusive: selectedDrugData[i].VATInClusive,
        vatPercentage: selectedDrugData[i].VATPercentage,
      };
      TblData.push(tableObj);
    }

    const nonZeroItems = TblData.filter(
      (item) => item.quantity !== undefined && item.quantity !== 0
    );

    finalObj.totalItems = nonZeroItems.length;
    finalObj.issueToDepartmentDetailsDto = nonZeroItems;

    //
    postIssueToDeptData(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          methods.reset();
          //
          setOpenBackdrop(false);
          setSelectedDrugData([]);
          setPatientDetails(null);
          setTotalAmount(0);
          setTotalQuantity(0);
          setAltDrugTableData([]);
          setDrugTableData([]);
          resetField("remark");
          setSelectedIndentTbleRow(null);
          handleOpenPrintModal(res?.result)
        }
      })
      .catch((res) => {
        // errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  function focusKitSearchDropdown() {
    let fieldsetTag = inputRefFour.current;

    let inputTagCollection = fieldsetTag.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];

    inputTagOne.focus();
  }

  //////print

  const handleOpenPrintModal = (issueId) => {
    // print service
    issueId &&
      fetchPrintIssueToDept(issueId,"A5").then((response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setPrintResponse(response);
        } else {
          setOpenPrintModal(true);

          setUrlforPrint(response);
        }
      });
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setPrintResponse(null);
    });
  };

  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          Issue To Department
        </div>
        {/* <form> */}
        {/* //search// */}
        <div className="grid grid-cols-6 lg:grid-cols-12 gap-3">
          {/* //Indent From Store// */}
          <div className="col-span-3 lg:col-span-2">
            <DropdownField
              control={control}
              // error={errors.nationality}
              name="FromStore"
              label="From Store"
              isSearchable={false}
              isClearable={false}
              placeholder=" From Store"
              isDisabled={true}
            />
          </div>

          {/* //Indent To Store// */}
          <div className="col-span-3 lg:col-span-2">
            <DropdownField
              name="toStore"
              label="To Store"
              control={control}
              dataArray={stores}
              isSearchable={false}
              isClearable={true}
              placeholder="To Store"
            />
          </div>

          {/* is Emergency radio button */}
          <div className="md:col-span-3 lg:col-span-3 flex md:justify-start  gap-2 items-center">
            <CheckBoxField
              control={control}
              name="indentConditionRadio"
              label="With Indent"
              style={{ paddingRight: 0, paddingLeft: 1 }}
            />
          </div>
          {/* buttons */}
          <div className="flex gap-2 col-span-3 lg:col-span-5 justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {obj.action === "Get Indent" ? (
                      <div className="flex justify-end">
                        <CommonButton
                          label="Get Indent"
                          onClick={getIndentInfo}
                          className="bg-customBlue  text-white "
                          disabled={withIndent ? false : true}
                        />
                      </div>
                    ) : null}
                    {obj.action === "Previous Issue" ? (
                      <div>
                        <CommonButton
                          label="Show Previous Issue"
                          onClick={getIssueList}
                          className="bg-customBlue text-white "
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        {/* item search */}
        <div className="mt-2">
          <div className="my-3">
            <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
              Item Details
            </span>
          </div>

          <div className="md:grid md:gap-3 md:grid-cols-8 lg:flex lg:gap-2">
            <div
              className="col-span-2 lg:w-[300px] md:mb-3 md:col-span-4 lg:mb-0"
              onFocus={() => {
                setInputOneFocus(true);
              }}
              onBlur={() => {
                setInputOneFocus(false);
              }}
              onClick={() => {
                //  inputRefOne.current.focus();
              }}
              onChange={(e) => setDrugSearchString(e.target.value)}
            >
              <InputField
                control={control}
                name="input-1"
                label="Search by Item "
                placeholder="Search by Item "
                disabled={ToStore !== null && !withIndent ? false : true}
                inputRef={inputRefOne}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (
                      selectedDrug?.BatchesRequired === false ||
                      selectedDrug?.BatchesRequired === "No"
                    ) {
                      inputRefThree.current.focus();
                    } else {
                      inputRefTwo.current.focus();
                    }
                  }
                }}
              />
            </div>
            {selectedDrug?.BatchesRequired !== "No" &&
              selectedDrug?.BatchesRequired !== false && (
                <div className="md:col-span-2 ">
                  <InputField
                    name="input-2"
                    size="small"
                    inputRef={inputRefTwo}
                    disabled={ToStore !== null && !withIndent ? false : true}
                    variant="outlined"
                    label="Batch Number"
                    {...register("input-2")}
                    control={control}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inputRefThree.current.focus();
                        handleKeypressForBatch(e);
                      }
                    }}
                  />
                </div>
              )}

            <div className="lg:w-15">
              <TextField
                inputRef={inputRefThree}
                error={customError}
                size="small"
                type="number"
                color={customError ? "error" : "primary"}
                InputLabelProps={{
                  shrink: watchInput1 !== null ? true : false,
                  style: { fontSize: "14px" },
                }}
                InputProps={{
                  style: {
                    fontSize: "14px",
                    height: "36.3px",
                    background: "white",
                  },
                }}
                sx={{
                  input: {
                    color: customError ? "#d32f2f" : "#212529",
                  },
                  label: {
                    color: customError ? "#d32f2f" : "#212529",
                  },
                }}
                name="input-3"
                variant="outlined"
                label="Qty"
                {...register("input-3")}
                onChange={(e) => {
                  getQuantity(e);
                }}
                disabled={ToStore !== null && !withIndent ? false : true}
                control={control}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    inputRefaddItem.current.focus();
                  }
                }}
              />
            </div>

            <div>
              <CommonButton
                label="Add"
                className="bg-customGreen text-white"
                referance={inputRefaddItem}
                onClick={() => {
                  addToItemDetailsTbl();
                  inputRefOne.current.focus();
                }}
              />
            </div>

            <div className="  bg-gray-500 lg:grid md:hidden ">
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem={true}
              />
            </div>

            <div className=" md:col-span-8 md:grid  md:grid-cols-4 lg:flex gap-2 ">
              <div className="col-span-2 lg:w-[320px] z-40" ref={inputRefFour}>
                <SearchDropdown
                  control={control}
                  // referance={(e) => {
                  //
                  // }}//not working for search dropdown...need to find reason
                  name="input-4"
                  label="Search by Item Kit"
                  placeholder="Search by Item Kit"
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={ToStore !== null && !withIndent ? false : true}
                  handleInputChange={searchByItemKit} //searchString
                  inputRef={{
                    ...register("input-4", {
                      onChange: (e) => {
                        getKitListItems(e);
                        inputRefFive.current.focus();
                      },
                    }),
                  }}
                  dataArray={itemKitList}
                />
              </div>

              <div className="lg:w-15">
                <InputField
                  name="input-5"
                  type="number"
                  variant="outlined"
                  disabled={ToStore !== null && !withIndent ? false : true}
                  label="Qty*"
                  error={errors.input - 5}
                  control={control}
                  inputRef={inputRefFive}
                  onKeyDown={(e) =>
                    e.key === "Enter" && inputRefAddKit?.current?.focus()
                  }
                />
              </div>

              <div>
                <CommonButton
                  label="Add"
                  onClick={() => {
                    addKitToItemDetailsTable();
                    focusKitSearchDropdown();
                    // const e = { target: { name: "input-3" } };
                    // onEnterFocusNext(e);
                  }}
                  className="bg-customGreen text-white "
                />
              </div>
            </div>
          </div>
        </div>
        {/* drug name table */}
        <div className="grid grid-cols-2 mt-2 gap-3">
          {/* drug tbl */}
          <div>
            {drugTableData.length > 0 && watchInput1 !== "" ? (
              <>
                <div>
                  <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Drug Name
                  </span>
                </div>
                <div ref={FocusDrugTable}>
                  <DrugTable
                    // drugTableData={drugTableData}
                    data={drugTableData}
                    selectedItemDtlsID={selectedItemDtlsID}
                    setSelectedDrug={setSelectedDrug}
                    setSelectedItemDtlsID={setSelectedItemDtlsID}
                    setDrugTableData={setDrugTableData}
                    setAltDrugTableData={setAltDrugTableData}
                    selectedGenericId={selectedGenericId}
                    setSelectedGenericId={setSelectedGenericId}
                    setSelectedDrugRowData={setSelectedDrugRowData}
                    setSelectedDrugData={setSelectedDrugData}
                    selectedDrugData={selectedDrugData}
                    setEmptyDrugInput={setEmptyDrugInput}
                    //row ids lastIndex, setLastIndex
                    selectedDrugRowID={selectedDrugRowID}
                    setSelectedDrugRowID={setSelectedDrugRowID}
                    //last indx
                    lastIndex={lastIndex}
                    setLastIndex={setLastIndex}
                    //
                    setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                    selectedAltDrugRowID={selectedAltDrugRowID}
                    //
                    // unFocusDrugInputField={unFocusDrugInputField}
                    removeFocusInputSearch={removeFocusInputSearch}
                    //
                    batchInputFocus={batchInputFocus}
                    //
                    inputRefThree={inputRefThree}
                    //
                    setDrugSearchString={setDrugSearchString}
                  />
                </div>
              </>
            ) : null}
          </div>
          {/* alt drug tbl */}
          <div>
            {altDrugTableData.length > 0 &&
            drugTableData.length &&
            watchInput1 !== "" ? (
              <>
                <div>
                  <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Alternate Drug Name
                  </span>
                </div>

                <div>
                  <AlternateDrugTable
                    data={altDrugTableData}
                    setSelectedDrug={setSelectedDrug}
                    //
                    selectedItemDtlsID={selectedItemDtlsID}
                    setSelectedItemDtlsID={setSelectedItemDtlsID}
                    setAltDrugTableData={setAltDrugTableData}
                    setDrugTableData={setDrugTableData}
                    setSelectedDrugData={setSelectedDrugData}
                    selectedDrugData={selectedDrugData}
                    //
                    setSelectedDrugRowData={setSelectedDrugRowData}
                    //row ids
                    selectedAltDrugRowID={selectedAltDrugRowID}
                    setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                    //
                    setSelectedDrugRowID={setSelectedDrugRowID}
                    selectedDrugRowID={selectedDrugRowID}
                    //
                    lastIndex={lastIndex}
                    //
                    setDrugSearchString={setDrugSearchString}
                    //
                    inputRefThree={inputRefThree}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/* Selected item details table setNoOfItems, setTotalQuantity */}
        <div
          className="mt-2"
          // ref={FocusItemDetailsTable}
          // onMouseOut={() => handleMouseOut(FocusItemDetailsTable.current)}
        >
          <ItemDetailsTable
            data={selectedDrugData}
            selectedDrug={selectedDrug}
            selectedDrugData={selectedDrugData}
            setSelectedDrugData={setSelectedDrugData}
            selectedItemDtlsID={selectedItemDtlsID}
            setSelectedItemDtlsID={setSelectedItemDtlsID}
            setNoOfItems={setNoOfItems}
            setTotalQuantity={setTotalQuantity}
            setTotalAmount={setTotalAmount}
            //
            openBatchModal={openBatchModal}
            setOpenBatchModal={setOpenBatchModal}
            handleOpenBatchModal={handleOpenBatchModal}
            handleCloseBatchModal={handleCloseBatchModal}
            //
            FocusBatchModal={FocusBatchModal}
            FocusDrugTable={FocusDrugTable}
            //
            drugTableData={drugTableData}
            batchSelctedRowData={batchSelctedRowData}
            setBatchSelctedRowData={setBatchSelctedRowData}
            //
            // onEnterFocusNext={onEnterFocusNext}
            //
            batchInsideTableInput={batchInsideTableInput}
            setBatchInsideTableInput={setBatchInsideTableInput}
            //
            setErrorToTblInput={setErrorToTblInput}
            errorToTblInput={errorToTblInput}
            //
            selctedRowData={selctedRowData}
            setSelctedRowData={setSelctedRowData}
            setItemToBeSubstituted={setItemToBeSubstituted}
            itemToBeSubstituted={itemToBeSubstituted}
          />
        </div>
        {/* remark & other calculations fields */}
        <div className="grid lg:grid-cols-6 mt-3 gap-3">
          {/* ///no of items/// */}
          <div className="">
            <InputField
              // sx={{backgroundColor: '#808080'}}
              name="noOfItems"
              variant="outlined"
              label="No of Items "
              // error={errors.code}
              control={control}
              disabled={true}
              isDisabled={true}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          {/* ///Total Tax Amount/// */}
          <div className="">
            <InputField
              // sx={{backgroundColor: '#808080'}}
              name="totalTaxAmount"
              variant="outlined"
              label="Total Tax Amount "
              // error={errors.code}
              control={control}
              disabled={true}
              isDisabled={true}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          {/* ///Total Amount/// */}
          <div className="">
            <InputField
              // sx={{backgroundColor: '#808080'}}
              name="totalAmount"
              variant="outlined"
              label="Total Amount "
              // error={errors.code}
              control={control}
              disabled={true}
              isDisabled={true}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          {/*  receivedBy */}
          <div className="">
            <DropdownField
              control={control}
              error={errors?.issueReceivedBy}
              name="issueReceivedBy"
              label="Received By"
              dataArray={receivedBy}
              isSearchable={false}
              placeholder=" Received By"
              isClearable={false}
            />
          </div>

          {/* Remark */}
          <div className="col-span-2">
            {/* //Remark.// */}

            <TextField
              id="outlined-multiline-static"
              name="remark"
              {...register("remark")}
              label="Remark"
              multiline
              InputLabelProps={{ shrink: true }}
              rows={1}
              fullWidth
            />
          </div>
          {/* buttons */}
          <div className="flex gap-3 justify-end col-span-2 lg:col-span-6">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <div>
                      <CommonButton
                        label="Reset"
                        onClick={() => {
                          setSelectedDrugData([]);
                          setPatientDetails(null);
                          setTotalAmount(0);
                          setTotalQuantity(0);
                          setAltDrugTableData([]);
                          setDrugTableData([]);
                          setSelectedIndentTbleRow(null);
                          resetField("remark");
                        }}
                        className="border border-customRed text-customRed "
                      />
                    </div>
                    <div>
                      <CommonButton
                        label="Save"
                        className="bg-customGreen text-white "
                        onClick={() => {
                          onSubmit();
                          setPrivilege(obj.action);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        {/* </form> */}
      </div>

      {/* get Indent Modal    */}
      {openIndentModal ? (
        <GetIndentModal
          open={openIndentModal}
          setOpen={setOpenIndentModal}
          handleOpen={handleOpenIndentModal}
          handleClose={handleCloseIndentModal}
          patientDetails={patientDetails}
          // selectedIndentListItems={selectedIndentListItems}
          // setSelectedIndentListItems={setSelectedIndentListItems}

          //
          setSelectedDrugData={setSelectedDrugData}
          selectedDrugData={selectedDrugData}
          selectedItemDtlsID={selectedItemDtlsID}
          //

          setSelectedIndentTbleRow={setSelectedIndentTbleRow}
          selectedIndentTbleRow={selectedIndentTbleRow}
        />
      ) : null}

      {/* IssueToDeptListModal */}
      {openIssueListModal ? (
        <IssueToDeptListModal
          open={openIssueListModal}
          setOpen={setOpenIssueListModal}
          handleOpen={handleOpenIssueListModal}
          handleClose={handleCloseIssueListModal}
          userActions={userActions}
        />
      ) : null}

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
};

export default GsIssueToDept;
