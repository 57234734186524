import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const saveFumigationChart = (finalObj) => {
  return apiClient.post(
    `/fumigationChart/saveFumigationChart
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchFumigationOrderList = (listObj) => {
  return apiClient.post(`/fumigationChart/getFumigationOrderList`, listObj, {
    headers: authHeader(),
  });
};

export const savePrePostSwab = (finalObj) => {
  return apiClient.post(`/fumigationChart/savePrePostSwab`, finalObj, {
    headers: authHeader(),
  });
};

export const fetchFumigationDetails = (fId) => {
  return apiClient.get(
    `/fumigationChart/getFumigationDetails/${fId}
    `,
    {
      headers: authHeader(),
    }
  );
};
