export const Notconnected = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="34" rx="4" fill="#E03434" />
        <path
          d="M16.0004 17.0001C15.4325 17.0001 14.8994 16.7612 14.4978 16.3283L8.13477 11.475L9.50155 10L15.8651 14.8533C15.9583 14.9534 16.0429 14.9534 16.1357 14.8533L22.475 10.025L23.8418 11.5L17.503 16.3283C17.1014 16.7617 16.5683 17.0001 16.0004 17.0001Z"
          fill="white"
        />
        <path
          d="M20.3685 21.9387L20.1085 23.8395C20.0494 24.2725 20.3021 24.6855 20.703 24.8109L24.3784 25.9626C24.7521 26.0798 25.1539 25.9146 25.3516 25.5633L26.8243 22.9408C27.1993 22.2734 26.953 21.4126 26.2852 21.0775C24.6507 20.2585 21.4208 19 16.9999 19C12.5795 19 9.34908 20.2581 7.71456 21.0775C7.04684 21.4122 6.80093 22.2734 7.17547 22.9408L8.64818 25.5633C8.84545 25.9146 9.24772 26.0798 9.62135 25.9626L13.2968 24.8109C13.6977 24.685 13.9504 24.272 13.8913 23.8395L13.6308 21.9372C13.564 21.4478 13.8922 20.9934 14.3622 20.9336C15.1799 20.8297 16.0604 20.7675 16.9994 20.7679C17.9376 20.7679 18.819 20.8306 19.6376 20.9355C20.1076 20.9953 20.4358 21.4492 20.3685 21.9387Z"
          fill="white"
        />
        <path
          d="M25.5874 8.63956L25.1399 13.6195L20.6074 8.19214L25.5874 8.63956Z"
          fill="white"
        />
      </svg>
    );
  };