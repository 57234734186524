import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import {
  fetchDrugDose,
  fetchDrugGenericName,
  fetchDrugInstructions,
  fetchDrugRoute,
} from "../../services/dischargeSummary/DischargeSummaryServices";
import CurrentMedicationTable from "./CurrentMedicationTable";
import { PatientTransferContext } from "./PatientTransfer";

const CurrentMedication = () => {
  const { medicationData, setMedicationData } = useContext(
    PatientTransferContext
  );

  const [drugName, setDrugName] = useState();
  const [drugDose, setDrugDose] = useState();
  const [drugInstruction, setDrugInstrucForm] = useState();
  const [drugRoute, setDrugRoute] = useState();
  const [drugSearchString, setDrugSearchString] = useState();

  const {
    register,
    control,
    formState: { errors },
    getValues,
    resetField,
    setError,
    setValue,
  } = useFormContext();

  useEffect(() => {
    getDrugDose();
    getDrugName();
    getDrugInstruction();
    getDrugRoute();
  }, [drugSearchString]);

  //   DrugName
  const getDrugName = () => {
    fetchDrugGenericName(drugSearchString)
      .then((response) => response.data)
      .then((res) => {
        setDrugName(res.result);
      });
  };
  // drugDose
  const getDrugDose = () => {
    fetchDrugDose()
      .then((response) => {
        //
        setDrugDose(response.data.result);
      })
      .catch((response) => {
        //
      });
  };

  //   drugInstruction
  const getDrugInstruction = () => {
    fetchDrugInstructions()
      .then((response) => response.data)
      .then((res) => {
        //
        setDrugInstrucForm(res.result);
      });
  };

  //   drugRoute
  const getDrugRoute = () => {
    fetchDrugRoute()
      .then((response) => response.data)
      .then((res) => {
        //
        setDrugRoute(res.result);
      });
  };

  const addCurrentMedication = () => {
    let ItemName = getValues("itemName");
    let Dose = getValues("dose");
    let Route = getValues("route");
    let Frequency = getValues("frequency");
    let Instructions = getValues("instructions");

    if (ItemName === "") {
      setError("itemName", { type: "custom", message: "Required" });
    }
    if (Dose === "") {
      setError("dose", { type: "custom", message: "Required" });
    }
    if (Route === "") {
      setError("route", { type: "custom", message: "Required" });
    }
    if (Frequency === null) {
      setError("frequency", { type: "custom", message: "Required" });
    }

    if (ItemName !== "" && Dose !== "" && Route !== "" && Frequency !== "") {
      let arr = [...medicationData];

      let obj = {
        ItemName: ItemName.label,
        Dose: Dose,
        Route: Route.label,
        Frequency: Frequency.label,
        Instructions: Instructions,
      };
      arr.push(obj);

      setMedicationData(arr);

      setValue("itemName", "");
      setValue("dose", "");
      setValue("route", "");
      setValue("frequency", "");
      setValue("instructions", "");
    }
  };
  return (
    <>
      <form>
        <div>
          <div className="text-black font-bold text-lg pb-3">Prescription</div>
          <div className="grid md:grid-cols-2 lg:grid-cols-6 gap-3">
            <div className="">
              {/* <InputField
                name="itemName"
                variant="outlined"
                label="Item Name *"
                error={errors.itemName}
                control={control}
                // disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              /> */}

              <CreateableSelect
                control={control}
                onInputChange={
                  (e) => {
                    setDrugSearchString(e);
                  }
                  // setSelectedPrefix(e.target.value.label);
                }
                name="itemName"
                placeholder="Item Name *"
                dataArray={drugName}
                error={errors.itemName}
                isSearchable={true}
                searchIcon={true}
                isClearable={true}
              />
            </div>
            <div className="">
              {/* <InputField
                name="dose"
                variant="outlined"
                label="Dose *"
                error={errors.dose}
                control={control}
                // disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              /> */}
              <DropdownField
                control={control}
                error={errors.frequency}
                name="frequency"
                placeholder="Frequency *"
                isSearchable={false}
                dataArray={drugDose}
              />
            </div>
            <div className="">
              {/* <InputField
                name="route"
                variant="outlined"
                label="Route *"
                error={errors.route}
                control={control}
                // disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              /> */}

              <DropdownField
                control={control}
                error={errors.route}
                name="route"
                placeholder="Route *"
                dataArray={drugRoute}
                isSearchable={false}
              />
            </div>
            <div className="">
              {/* <InputField
                name="frequency"
                variant="outlined"
                label="Frequency *"
                error={errors.frequency}
                control={control}
                // disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              /> */}

              <InputField
                name="dose"
                variant="outlined"
                label="Dose * "
                error={errors.dose}
                control={control}
                // disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div className="">
              <InputField
                name="instructions"
                variant="outlined"
                label="Instructions"
                // error={errors.instructions}
                control={control}
                // disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              />
              {/*
              <DropdownField
                control={control}
                // error={errors.instructions}
                name="instructions"
                placeholder="Instructions"
                dataArray={drugInstruction}
                isSearchable={false}
              />
            */}
            </div>

            <div className="flex justify-end lg:col-span-1">
              <div>
                {/* <button
                  type="button"
                  className="h-9 px-3 text-base font-medium  bg-green-600 text-white rounded "
                  onClick={() => addCurrentMedication()}
                >
                  Add
                </button> */}

                <CommonButton
                  label="Add"
                  className="bg-customGreen text-white"
                  onClick={() => addCurrentMedication()}
                />
              </div>
            </div>
          </div>
          <div className="pt-2">
            <CurrentMedicationTable
              data={medicationData}
              setServiceData={setMedicationData}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default CurrentMedication;
