import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const supplierListForPOMultiple = async (itemId) => {
  return await apiClient.get(
    `/purchaseOrder/supplierListForPOMultiple/${itemId}`,
    {
      headers: authHeader(),
    }
  );
};

export const addNewPurchaseOrderMultiple = (
  menuId,
  privilege,
  createPurchaseOrderMultiple
) => {
  return apiClient.post(
    `/purchaseOrder/savePurchaseOrderMultiple/${menuId}/${privilege}`,
    createPurchaseOrderMultiple,
    {
      headers: authHeader(),
    }
  );
};

// POST PO Indent List
export const getIndentForPOMultiple = async (poIndentObj) => {
  return await apiClient.post(
    `/purchaseOrder/getIndentForPOMultiple`,
    poIndentObj,
    {
      headers: authHeader(),
    }
  );
};

export const getIndentDetailsForPOMultiple = async (indentId) => {
  return await apiClient.get(
    `/purchaseOrder/getIndentDetailsForPOMultiple/${indentId}`,
    {
      headers: authHeader(),
    }
  );
};
