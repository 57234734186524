import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  deleteAlert,
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import InputField from "../../../../common/components/FormFields/InputField";
import Output from "./Output";
import BothInputOutput from "./BothInputOutput";
import ViewAllModal from "./ViewAllModal";
import {
  deleteInputOutput,
  getAllInputOutput,
  inputOutputViewList,
  saveInputOutput,
} from "../../../services/cliinicalChartServices/inputoutputServices/InoutOutputServices";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import { addDays, format, isPast } from "date-fns";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: "#00ff00",
    },
  },
});

function InputOutput(props) {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [selected, setSelected] = React.useState("both"); //button toggle
  const [openPost, setOpenPost] = React.useState(false);

  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);
  const handleClosePost = () => setOpenPost(false);
  const [inputDataResult, setInputDataResult] = useState([]); // populate table data
  const [inputFinalData, setInputFinalData] = React.useState(); //input Save final data
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // toggle button
  const handleChanger = (e) => {
    setSelected(e.target.value);
  };
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);
  //
  console.log("props", props);

  ///

  console.log("targetTime", props?.selectedRow?.AdmissionDate);
  let dateObj = new Date(props?.selectedRow?.AdmissionDate?.split("+")[0]);

  const targetTime = new Date(`${format(dateObj, "yyyy-MM-dd")}T07:59:59`);
  console.log("targetTime", targetTime);

  const hasPassed = isPast(targetTime);
  ///
  const [fromDate, setFromDate] = useState(
    hasPassed ? new Date() : addDays(new Date(), -1)
  );
  const [toDate, setToDate] = useState(
    !hasPassed ? new Date() : addDays(new Date(), 1)
  );

  const [fromTime, setFromTime] = useState(
    new Date(`${fromDate.toISOString().split("T")[0]}T08:00:00`)
  );

  const [toTime, setToTime] = useState(
    new Date(`${toDate.toISOString().split("T")[0]}T08:00:00`)
  );
  //

  const defaultValues = {
    iv: "",
    ivQuantity: "",
    perJt: "",
    perJtQuantity: "",
    infusions: "",
    infusionsQuantity: "",
    inputOther: "",
    inputOtherQuantity: "",
    perOral: "",
    perOralQuantity: "",
    perRt: "",
    perRtQuantity: "",
    boluses: "",
    bolusesQuantity: "",
    inputPdHd: "",
    inputPdHdQuantity: "",
  };
  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  let status;
  if (selected === "input") {
    status = 0;
  } else if (selected === "output") {
    status = 1;
  } else if (selected === "both") {
    status = 2;
  }
  let admissionId = props.displayInfo?.AdmissionId;

  useEffect(() => {
    inputPopulateTable();

    setSelectedRow(null);

    reset(defaultValues);
  }, [admissionId, props.admissionId, status]);

  const inputPopulateTable = () => {
    showSpinner(true);
    getAllInputOutput(admissionId || props.admissionId, status)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setInputDataResult(res.result);
      });
  };

  function postInputData(obj) {
    saveInputOutput(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          getInTakeOutTakeListing();
          inputPopulateTable(admissionId || props.admissionId, status);
          reset(defaultValues);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  const onSubmitDataHandler = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));
    if (props.displayInfo?.AdmissionId || props.admissionId !== null) {
      let postedObj = {
        menuId: props.menuId,
        privilege: props.privilege,
        addedBy: getUserId.userId,
        admissionId: props.displayInfo?.AdmissionId || props.admissionId,
        // type: "input",
        ivDetails: data.iv,
        iv: data.ivQuantity,
        perJtDetails: data.perJt,
        perjt: data.perJtQuantity,
        infusionDetails: data.infusions,
        infusions: data.infusionsQuantity,
        otherDetails: data.inputOther,
        other: data.inputOtherQuantity,
        perOralDetails: data.perOral,
        peroral: data.perOralQuantity,
        perRtDetails: data.perRt,
        perRt: data.perRtQuantity,
        bolisesDetails: data.boluses,
        bolises: data.bolusesQuantity,
        inTakePdDetails: data.inputPdHd,
        inTakePd: data.inputPdHdQuantity,
      };
      if (admissionId !== undefined || props.admissionId) {
        if (isDirty !== false) {
          setOpenPost(true);
          setInputFinalData(postedObj); //final data
        } else {
          warningAlert("Please enter value...!");
        }
      }
    } else if (
      admissionId === null ||
      props.admissionId === undefined ||
      props.admissionId === null
    ) {
      warningAlert("Please Select Patient...!");
    }
  };
  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postInputData(inputFinalData);
    reset(defaultValues);
  }

  const handleSelectedRow = (row, index) => {
    editRow(row, index);
    setSelectedRow(row);
  };

  // event listener function for edit icon
  function editRow(requiredRow, index) {
    let resetObj = {
      iv: requiredRow.IVDetails,
      ivQuantity: Number(requiredRow.IV),
      perJt: requiredRow.PERJTDetails,
      perJtQuantity: Number(requiredRow.PERJT),
      infusions: requiredRow.INFUSIONDetails,
      infusionsQuantity: Number(requiredRow.INFUSIONS),
      inputOther: requiredRow.INTAKEOtherDetails,
      inputOtherQuantity: Number(requiredRow.INTAKEOther),
      perOral: requiredRow.PERORALDetails,
      perOralQuantity: Number(requiredRow.PERORAL),
      perRt: requiredRow.PERRTDetails,
      perRtQuantity: Number(requiredRow.PERRT),
      boluses: requiredRow.BOLUSESDEtails,
      bolusesQuantity: Number(requiredRow.BOLUSES),
      inputPdHd: requiredRow.intakepddetails,
      inputPdHdQuantity: Number(requiredRow.intakepd),
    };
    reset(resetObj);
  }

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deleteInputOutput(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            inputPopulateTable();
            reset(defaultValues);
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true &&
            obj.action === "Delete" &&
            !props.selectedPatient?.AllowToGO ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    let deleteObjVal = {};

                    deleteObjVal.Id = row.Id;

                    deleteObjVal.action = obj.action;

                    setDeleteObj(deleteObjVal);

                    //open the delete confirmation modal
                    handleOpenDeleteConfirm();
                    reset(defaultValues);
                  }}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const [intakeOuttakeKeys, setintakeOuttakeKeys] = React.useState([]);
  function getInTakeOutTakeListing() {
    let searchObj = {
      admissionId: admissionId || props.admissionId,
      fromDate: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      toDate: format(toDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      fromTime: format(fromTime, "HH:mm:ss.000"),
      toTime: format(toTime, "HH:mm:ss.000"),
    };

    console.log("searchObj",searchObj)

    inputOutputViewList(searchObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setintakeOuttakeKeys(res.result);
      });
  }

  useEffect(() => {
    getInTakeOutTakeListing();
  }, [fromDate, toDate, fromTime, toTime, admissionId, props.admissionId]);


  return (
    <Box sx={{ width: "100%" }}>
      <label className="my-1 text-sm font-semibold whitespace-nowrap">
        Input / Output
      </label>

      <div className="grid md:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] lg:grid-cols-[10%_1%_18%_10%_1%_18%_8%_1%_18%] xl:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] 2xl:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] text-sm text-black whitespace-nowrap">
        <div className="font-semibold">Intake Total</div>
        <div className="font-semibold"> : </div>
        <div className="text-customGreen font-semibold">
          {intakeOuttakeKeys.intakeOuttakeTotal?.["Intake Total"]}
        </div>
        <div className="font-semibold">OutPut Total</div>
        <div className="font-semibold"> : </div>
        <div className="text-customBlue font-semibold">
          {intakeOuttakeKeys.intakeOuttakeTotal?.["Outtake Total"]}
        </div>
        <div className="font-semibold">Difference</div>
        <div className="font-semibold">:</div>
        <div className="text-customOrange font-semibold">
          {intakeOuttakeKeys.intakeOuttakeTotal?.["Difference"]}
        </div>
      </div>

      {spinner ? (
        <div className="grid justify-center">
          <LoadingSpinner />
        </div>
      ) : null}

      <div className="grid grid-cols-3 gap-2 w-full">
        <div className="col-span-3 w-full ">
          {inputDataResult.length > 0 && spinner === false ? (
            <>
              <CommonDynamicTableNew
                dataResult={inputDataResult}
                tableClass={"rounded h-[240px] 2xl:h-60 border"}
                highlightRow={true}
                removeHeaders={[
                  "Id",
                  "phlebitis",
                  "visitId",
                  "dateTime",
                  "addedBy",
                  "vipScore",
                  "intakeId",
                ]}
                handleSelectedRow={handleSelectedRow}
                renderActions={renderActions}
              />
            </>
          ) : (
            <h3 className="flex justify-center my-5   font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </div>
      </div>
      <div className="grid justify-center pt-2">
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup
            value={selected}
            exclusive
            onChange={handleChanger}
          >
            <ToggleButton
              sx={{ height: "30px" }}
              selectedColor="#133e87"
              checked={selected === "input"}
              value="input"
            >
              Input
            </ToggleButton>
            <ToggleButton
              sx={{ height: "30px" }}
              selectedColor="#133e87"
              checked={selected === "output"}
              value="output"
            >
              Output
            </ToggleButton>
            <ToggleButton
              sx={{ height: "30px" }}
              selectedColor="#133e87"
              checked={selected === "both"}
              value="both"
            >
              Both
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>
      </div>

      <div className="w-full">
        <div>
          <div hidden={selected !== "input" ? true : false}>
            <div className="w-full">
              <form
              //onSubmit={handleSubmit(onSubmitDataHandler)}
              >
                <div className="flex my-1">
                  <div className="grid grid-cols-4 gap-3 m-2 border-gray-500 border-r-2 pr-4">
                    <div className="col-span-3">
                      <InputField
                        name="iv"
                        variant="outlined"
                        label="IV"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="ivQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />

                    <div className="col-span-3">
                      <InputField
                        name="perJt"
                        variant="outlined"
                        label="Per JT"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="perJtQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />

                    <div className="col-span-3">
                      <InputField
                        name="infusions"
                        variant="outlined"
                        label="Infusions"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="infusionsQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />

                    <div className="col-span-3">
                      <InputField
                        name="inputOther"
                        variant="outlined"
                        label="Other"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="inputOtherQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />
                  </div>

                  <div className="grid grid-cols-4 gap-3 m-2">
                    <div className="col-span-3">
                      <InputField
                        name="perOral"
                        variant="outlined"
                        label="Per Oral"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="perOralQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />

                    <div className="col-span-3">
                      <InputField
                        name="perRt"
                        variant="outlined"
                        label="Per RT"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="perRtQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />

                    <div className="col-span-3">
                      <InputField
                        name="boluses"
                        variant="outlined"
                        label="Boluses"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="bolusesQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />

                    <div className="col-span-3">
                      <InputField
                        name="inputPdHd"
                        variant="outlined"
                        label="PD/HD"
                        control={control}
                      />
                    </div>
                    <InputField
                      name="inputPdHdQuantity"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      control={control}
                    />
                  </div>
                </div>
                <div className=" flex gap-2 justify-end pb-2">
                  <CommonButton
                    type="button"
                    className="bg-customBlue text-white"
                    label="View All"
                    onClick={() => {
                      handleOpen();
                    }}
                  />
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="resetbutton border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                      setSelectedRow(null);
                    }}
                  />

                  {props.userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <>
                          {props.patientInformation?.BedReleased === true ||
                          props.selectedPatient?.BedReleased === true ? null : (
                            <>
                              {selectedRow === null ? (
                                <div
                                  onClick={() => props.setPrivilege(obj.action)}
                                >
                                  <CommonButton
                                    //type="submit"
                                    className="bg-customGreen text-white"
                                    label="Save"
                                    onClick={methods.handleSubmit(
                                      onSubmitDataHandler
                                    )}
                                    
                                  />
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </form>
            </div>

            <CommonBackDrop openBackdrop={openBackdrop} />
          </div>

          <div hidden={selected !== "output" ? true : false}>
            <Output
              open={open}
              setOpen={setOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              inputPopulateTable={inputPopulateTable}
              admissionId={props.displayInfo?.AdmissionId || props.admissionId}
              status={status}
              postOutputData={postInputData}
              openBackdrop={openBackdrop}
              setOpenBackdrop={setOpenBackdrop}
              userActions={props.userActions}
              menuId={props.menuId}
              privilege={props.privilege}
              setPrivilege={props.setPrivilege}
              handleSelectedRow={handleSelectedRow}
              editRow={editRow}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              patientInformation={props.patientInformation}
              selectedPatient={props.selectedPatient}
            />
          </div>

          <div hidden={selected !== "both" ? true : false}>
            <BothInputOutput
              open={open}
              setOpen={setOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              inputPopulateTable={inputPopulateTable}
              admissionId={props.displayInfo?.AdmissionId || props.admissionId}
              status={status}
              postOutputData={postInputData}
              openBackdrop={openBackdrop}
              setOpenBackdrop={setOpenBackdrop}
              userActions={props.userActions}
              menuId={props.menuId}
              privilege={props.privilege}
              setPrivilege={props.setPrivilege}
              handleSelectedRow={handleSelectedRow}
              editRow={editRow}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              patientInformation={props.patientInformation}
              selectedPatient={props.selectedPatient}
            />
          </div>
        </div>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />
        <ConfirmationModal
          confirmationOpen={deleteconfirm}
          confirmationHandleClose={handleCloseDeleteConfirm}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this record ?"
          confirmationButtonMsg="Delete"
        />
      </div>
      {open ? (
        <ViewAllModal
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          admissionId={props.displayInfo?.AdmissionId || props.admissionId}
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromTime={fromTime}
          setFromTime={setFromTime}
          toTime={toTime}
          setToTime={setToTime}
          intakeOuttakeKeys={intakeOuttakeKeys}
          setintakeOuttakeKeys={setintakeOuttakeKeys}
        />
      ) : null}
    </Box>
  );
}

export default InputOutput;
