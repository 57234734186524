import axios from "axios";

export const baseUrl = "http://192.168.1.6:6002/api";
//  export const baseUrl = "http://192.168.0.61:5003/api";
//export const baseUrl = "http://192.168.1.224:5003/api";

//export const baseUrl = "http://192.168.1.206:5003/api"; //old

export default axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
  },
});

export const printersApi = axios.create({
  baseURL: "http://localhost:6004/api",
  // baseURL: "http://192.168.0.230:6004/api",
  // headers: {
  //   "Content-type": "application/json",
  // },
});

