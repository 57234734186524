import React, { useEffect, useState } from "react";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import {
  addNewBioWeastBagDetails,
  fetchAllBioMedicalWasteList,
  getBagTypes,
} from "../../services/bioMedicalWasteServices/BioMedicalWasteServices";
import { format } from "date-fns";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import RadioField from "../../../common/components/FormFields/RadioField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import { EditOnIcon, PrintOnIcon } from "../../../assets/icons/CustomIcons";
import { Tooltip } from "@mui/material";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BioMedicalWasteManagementModal from "./BioMedicalWasteManagementModal";

export default function BioMedicalWasteManagement() {
  const defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
    transactionDate: new Date(),
    covidNonCovid: 1,
    bagType: null,
    quantity: "",
    weight: "",
  };

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const fromDateVal = watch("fromDate");
  const toDateVal = watch("toDate");
  const bagTypeVal = watch("bagType");
  const quantityVal = watch("quantity");
  const weightVal = watch("weight");
  const covidNonCovidVal = watch("covidNonCovid");
  const transactionDateVal = watch("transactionDate");

  const [bagType, setBagType] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [editResponse, setEditResponse] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataResult, setDataResult] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    if (openModal) {
      setOpenModal(false);
    }
  };
  let location = useLocation();

  const populateTable = (forPagination) => {
    let defaultParams = {
      fromDate: format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      toDate: format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };
    setShowSpinner(true);
    fetchAllBioMedicalWasteList(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setShowSpinner(false);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        setShowSpinner(false);
        errorAlert(error.message);
      });
  };

  useEffect(() => {
    populateTable();

    getBagTypes()
      .then((response) => {
        setBagType(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });

    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const renderActions = (row, index) => {
    return (
      <div>
        <Tooltip title="Edit">
          <span
            className="text-black"
            onClick={() => {
              setEditResponse(row);
              setOpenModal(true);
            }}
          >
            <EditOnIcon />
          </span>
        </Tooltip>
        <Tooltip title="Print">
          <span className="text-black" onClick={() => {}}>
            <PrintOnIcon />
          </span>
        </Tooltip>
      </div>
    );
  };
  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Waste Bag Transaction
      </h1>

      <div className="grid grid-cols-3 gap-2 py-2">
        <div className="flex space-x-2">
          <DatePickerFieldNew
            control={control}
            name="fromDate"
            label="From Date"
            value={new Date()}
            inputFormat="dd-MM-yyyy"
          />
          <DatePickerFieldNew
            control={control}
            name="toDate"
            label="To Date"
            value={new Date()}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        <CommonButton
          type="button"
          searchIcon={true}
          className="bg-customBlue text-white"
          onClick={() => {
            populateTable();
          }}
        />
        <div className="grid justify-end">
          <CommonButton
            type="button"
            label="Add New"
            className="bg-customBlue text-white"
            onClick={() => {
              setOpenModal(true);
              setEditResponse(null);
            }}
          />
        </div>
      </div>
      {showSpinner ? (
        <div className="grid justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dataResult.length > 0 && showSpinner === false ? (
            <>
              <CommonDynamicTablePaginationNew
                dataResult={dataResult}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                highlightedRow={false}
                removeHeaders={["Id"]}
                highlightRow={true}
                renderActions={renderActions}
                tableHeading="List Of Bage"
                tableClass=""
              />
            </>
          ) : (
            <h3 className="flex justify-center my-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </>
      )}

      <CommonBackDrop openBackdrop={openBackdrop} />

      <BioMedicalWasteManagementModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleCloseModal={handleCloseModal}
        bagType={bagType}
        populateTable={populateTable}
        privilege={privilege}
        editResponse={editResponse}
        setEditResponse={setEditResponse}
      />
    </div>
  );
}
