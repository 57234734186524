import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { useForm } from "react-hook-form";
import { Box, Checkbox } from "@mui/material";
import { Modal } from "@mui/material";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import PurchaseApprovalTable from "./PurchaseApprovalTable";
import PurchaseApprovalDetailTable from "./PurchaseApprovalDetailTable";
import InputField from "../../../../common/components/FormFields/InputField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import {
  getPOApprovalDetailsList,
  getPOApprovalList,
} from "../../../services/purchase/PurchaseApprovalServices";
import { format } from "date-fns";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

export default function PurchaseApproveModal(props) {
  const defaultValues = {
    storeName: null,
    supplierName: null,
    fromDate: new Date(),
    toDate: new Date(),
    purchaseInvoiceDate: new Date(),
    netapprovedamount: 0,
    changeableNetApprovedamount: 0,
    remark: "",
  };

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    control,
    reset,
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues,
  });

  let changeableNetAppramtVal = watch("changeableNetApprovedamount");

  const [finalData, setFinalData] = useState({});
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [totalSelectedApprovedAmt, setTotalSelectedApprovedAmt] = useState(0);

  let storeNameVal = watch("storeName");
  let supplierNameVal = watch("supplierName");
  let fromDateVal = watch("fromDate");
  let toDateVal = watch("toDate");
  const [selected, setSelected] = React.useState([]);

  const [purchaseApprovalList, setPurchaseApprovalList] = useState([]);
  const [purchaseApprovalDetailList, setPurchaseApprovalDetailList] = useState(
    []
  );
  const [approvalId, setApprovalId] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  function callTableDataApi(forPagination) {
    let stringSearchObj = {
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      fromDate: format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      storeId: storeNameVal !== null ? storeNameVal?.id : null,
      supplierId: supplierNameVal !== null ? supplierNameVal?.id : null,
      toDate: format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };
    getPOApprovalList(stringSearchObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        res.result.forEach((object) => {
          object.isChecked = false;
        });

        if (forPagination) {
          setPurchaseApprovalList((prevData) => [...prevData, ...res.result]);
        } else {
          setPurchaseApprovalList(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  }

  const renderActions = (row, index) => {
    return (
      <div>
        <Checkbox
          className=" w-3 h-3 border border-gray-600 rounded bg-transparent"
          type="checkbox"
          color="primary"
          onChange={(e) => {
            let temp = [...purchaseApprovalList];
            temp[index].isChecked = e.target.checked;
            setPurchaseApprovalList(temp);
            let tempApprovalId = [];
            for (let t = 0; t < temp.length; t++) {
              if (temp[t].isChecked === true) {
                tempApprovalId.push(temp[t].ApprovalId);
              }
              setApprovalId(tempApprovalId);
            }
          }}
        />
      </div>
    );
  };

  function callDetailTableApi() {
    getPOApprovalDetailsList(storeNameVal ? storeNameVal?.id : null, approvalId)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        res.result.forEach((object) => {
          object.isChecked = false;
        });

        setPurchaseApprovalDetailList(res.result);
      });
  }

  const renderPurchaseApprovalDetailsListActions = (row, index) => {
    return (
      <div>
        <Checkbox
          className=" w-3 h-3 border border-gray-600 rounded bg-transparent"
          type="checkbox"
          color="primary"
          onChange={(e) => {
            let totalSelectedNetApprovedAmt = 0;
            if (e.target.checked === true) {
              setValue("changeableNetApprovedamount", row?.["Net Amount"]);
            } else if (e.target.checked === false) {
              setValue("changeableNetApprovedamount", Number(0));
            }

            let temp = [...purchaseApprovalDetailList];
            temp[index].isChecked = e.target.checked;
            setPurchaseApprovalDetailList(temp);
            // let purchaseApprovalDetailsArr = []; // onSubmit
            // let tempGrnIds = [];
            for (let t = 0; t < temp.length; t++) {
              if (temp[t].isChecked === true) {
                totalSelectedNetApprovedAmt += temp[t]?.["Net Amount"];
                // tempGrnIds.push(temp[t].GRNId);
                // purchaseApprovalDetailsArr.push(temp[t]);
              }
              // setGrnIds(tempGrnIds);
              // setPurchaseApprovalDetailsDtoArray(purchaseApprovalDetailsArr);
            }

            setTotalSelectedApprovedAmt(
              Number(totalSelectedNetApprovedAmt).toFixed(2)
            );
          }}
        />
      </div>
    );
  };

  function addRecord() {
    setOpenPost(false);
    props.postPurchaseApproval(finalData);
  }

  function onSubmitDataHandler(data) {
    let purchaseApprovalArr = [];
    for (let i = 0; i < purchaseApprovalDetailList.length; i++) {
      let obj = {
        grnId: purchaseApprovalDetailList[i].GRNId,
        grnType: purchaseApprovalDetailList[i].GRNType,
        supplierId: purchaseApprovalDetailList[i].SupplierId,
        // approvalDetailsId:
      };
      purchaseApprovalArr.push(obj);
    }

    // {
    //   "addedBy": 0,
    //   "approvalId": 0,
    //   "approvalNo": "string",
    //   "isConsignment": true,
    //   "menuId": 0,
    //   "netApprovedAmount": 0,
    //   "privilege": "string",
    //   "purchaseApprovalDetailsDtoList": [
    //     {
    //       "approvalDetailsId": 0,
    //       "grnId": 0,
    //       "grnType": "string",
    //       "supplierId": 0
    //     }
    //   ],
    //   "remark": "string",
    //   "storeId": 0,
    //   "supplierId": 0
    // }

    let postObj = {
      addedBy: userInfo.userId,
      isConsignment: false,
      // approvalId:,
      // approvalNo:,
      menuId: props.menuId,
      netApprovedAmount: Number(changeableNetAppramtVal),
      privilege: props.privilege,
      purchaseApprovalDetailsDtoList: purchaseApprovalArr,
      remark: data.remark,
      storeId: storeNameVal !== null ? storeNameVal?.id : null,
      supplierId: supplierNameVal !== null ? supplierNameVal?.id : null,
    };

    if (purchaseApprovalArr.length > 0) {
      setOpenPost(true);
      setFinalData(postObj);
    }
  }

  useEffect(() => {
    ///Purchase Approval List Table
    callTableDataApi();
  }, []);

  useEffect(() => {
    ///Purchase Approval Details List Table 2
    if (approvalId.length > 0) {
      callDetailTableApi();
    }
  }, [storeNameVal, approvalId]);

  return (
    <div>
      <Modal
        open={props.openPurchApprListModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="h-[85%]">
          <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClosePurchApprListModal();
              }}
            />
          </div>
          <div className="px-2 ml-2 lg:ml-0 font-bold text-gray-800">
            Purchase Approval List
          </div>
          <form
            className="w-full  py-2"
            onSubmit={handleSubmit(onSubmitDataHandler)}
          >
            <div className="grid grid-cols-4 gap-2">
              <DropdownField
                control={control}
                name="storeName"
                placeholder="Store"
                dataArray={props.store}
              />
              <DropdownField
                control={control}
                name="supplierName"
                placeholder="Supplier"
                dataArray={props.supplier}
              />
              <div className="flex gap-3">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("fromDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("toDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div className="flex gap-2">
                <CommonButton
                  type="button"
                  searchIcon={true}
                  onClick={() => {
                    callTableDataApi();
                  }}
                  className="bg-customBlue text-white"
                />
                <CommonButton
                  type="button"
                  // onClick={filterData}
                  label="Reset"
                  className="border border-customRed text-customRed"
                />
              </div>
            </div>

            {/* {PurchaseApprovalList.hasOwnProperty("result") &&
            PurchaseApprovalList.result.length > 0 ? (
              <div>
                <PurchaseApprovalTable
                  data={PurchaseApprovalList}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            ) : null} */}

            {purchaseApprovalList?.length > 0 ? (
              <div className="">
                <CommonDynamicTablePaginationNew
                  dataResult={purchaseApprovalList}
                  tableClass={"rounded h-[200px]"}
                  renderActions={renderActions}
                  populateTable={callTableDataApi}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  // handleSelectedRow={handleSelectedRow}
                  tableHeading={"Purchase Approval"}
                  removeHeaders={["id", "isChecked"]}
                  highlightRow={true}
                />
              </div>
            ) : (
              <h1 className="font-semibold text-sm text-center py-6 text-gray-600">
                No Record Found...!
              </h1>
            )}

            <h1 className="font-semibold text-sm">
              Purchase Appproval Details
            </h1>
            {/* {PurchaseApprovalDetailList.hasOwnProperty("result") &&
            PurchaseApprovalDetailList.result.length > 0 ? (
              <div>
                <PurchaseApprovalDetailTable
                  data={PurchaseApprovalDetailList}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            ) : null} */}

            {purchaseApprovalDetailList.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={purchaseApprovalDetailList}
                renderActions={renderPurchaseApprovalDetailsListActions}
                removeHeaders={[""]}
                highlightRow={true}
                tableClass={"rounded h-[100px] xl:h-[200px] 2xl:h-[25 0px]"}
              />
            ) : (
              <h1 className="font-semibold text-sm text-center py-6 text-gray-600">
                No Record Found...!
              </h1>
            )}

            <div className=" flex gap-3 py-2">
              <div className="flex items-center gap-2">
                <DatePickerFieldNew
                  control={control}
                  name="purchaseGrnDate"
                  label="Party Invoice Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("purchaseGrnDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />

                <span className="font-semibold text-sm whitespace-nowrap">
                  Net Approved Amount
                </span>

                <div className="w-[60%]">
                  <InputField
                    name="changeableNetApprovedamount"
                    variant="outlined"
                    label=""
                    control={control}
                  />
                </div>
              </div>

              <div className="w-[80%]">
                <InputField
                  name="remark"
                  variant="outlined"
                  label="Remark"
                  error={errors.remark}
                  control={control}
                />
              </div>
              {props.userActions.map((action) => (
                <>
                  {!action.isAction && action.action === "Create" ? (
                    <fieldset
                      onClick={() => {
                        props.setPrivilege(action.action);
                      }}
                    >
                      <CommonButton
                        type="submit"
                        label="Change"
                        className="bg-customBlue text-white"
                      />
                    </fieldset>
                  ) : null}
                </>
              ))}
            </div>

            <div className="grid grid-cols-4 gap-2 items-center pt-2">
              <div className="flex gap-2 items-center">
                <span className="font-semibold text-sm whitespace-nowrap">
                  Total Net Approved Amount
                </span>
                <input
                  className="w-52 bg-white text-[#5361e0]"
                  value={Number(totalSelectedApprovedAmt).toFixed(2)}
                  name="netapprovedamount"
                  {...register("netapprovedamount")}
                  disabled={true}
                />
              </div>
            </div>
          </form>
          <ConfirmationModal
            confirmationOpen={openPost}
            confirmationHandleClose={handleClosePost}
            confirmationSubmitFunc={addRecord}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure want to change approved amount ?"
            confirmationButtonMsg="Add"
          />
        </Box>
      </Modal>
    </div>
  );
}
