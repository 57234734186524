import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//autocomplete from ot consent services

//patient Information
export const SaveOtReservation = (obj) => {
  return apiClient.post(`/otReservation/saveOTReservation`, obj, {
    headers: authHeader(),
  });
};

//patient Information
export const fetchPatientList = (obj) => {
  //
  return apiClient.post(`/otReservation/patientList`, obj, {
    headers: authHeader(),
  });
};

// Group
export const fetchGroupOt = () => {
  //
  return apiClient.get(`/otReservation/groups`, {
    headers: authHeader(),
  });
};

//Sub Group
export const fetchSubGroup = (groupId) => {
  //
  return apiClient.get(`/otReservation/subGroup/${groupId}`, {
    headers: authHeader(),
  });
};

//ot Tables
export const fetchOtTables = () => {
  return apiClient.get(`/otReservation/otTables`, {
    headers: authHeader(),
  });
};

//surgery Procedure
export const fetchSurgeryProcedure = (searchString) => {
  return apiClient.get(`/otReservation/surgeryProcedure/${searchString}`, {
    headers: authHeader(),
  });
};

// doctor Type
export const fetchDoctorType = () => {
  return apiClient.get(`/otReservation/doctorType`, {
    headers: authHeader(),
  });
};

// doctor Type
export const fetchDoctorsOfType = (docTypeId) => {
  return apiClient.get(`/otReservation/doctors/${docTypeId}`, {
    headers: authHeader(),
  });
};

// employee Name
export const fetchEmployeeName = () => {
  return apiClient.get(`otReservation/employeeName`);
};

// cssd kit
export const fetchCssdkitItems = (searchString, unitId) => {
  return apiClient.get(`/otReservation/getCSSDKit/${searchString}/${unitId}`, {
    headers: authHeader(),
  });
};

// cssd kit item list
export const fetchCSSDKitItemsList = (kitId) => {
  return apiClient.get(`/otReservation/getCSSDKitItems/${kitId}`, {
    headers: authHeader(),
  });
};

// pre-operative Instructions
export const fetchPreoperativeInstructions = (groupId) => {
  return apiClient.get(`/otReservation/preoperativeInstructions/${groupId}`, {
    headers: authHeader(),
  });
};

// pharmacy Kit
export const fetchPharmacyKitItems = () => {
  return apiClient.get(`/otReservation/pharmacyKitItems`, {
    headers: authHeader(),
  });
};
//pharmacy items against Kit
export const fetchPharmacyKitItemsList = (kitId) => {
  return apiClient.get(`/otReservation/getPharmacyKitItems/${kitId}`, {
    headers: authHeader(),
  });
};

//OT reservation slots
export const fetchReservationSlots = () => {
  return apiClient.get(`/otReservation/otTimeSlots`, {
    headers: authHeader(),
  });
};

//OT preBooking
export const fetchBookingDetails = (date, otTableId) => {
  return apiClient.get(`/otReservation/otSlotsDetails/${date}/${otTableId}`, {
    headers: authHeader(),
  });
};
//edit reservation
export const fetchReservationDetails = (reservationId) => {
  return apiClient.get(
    `/otReservation/getReservationDetails/${reservationId}`,
    {
      headers: authHeader(),
    }
  );
};

//Ot Reserved Patient List
export const fetchOtReservedPatientList = (obj) => {
  return apiClient.post(`/otReservation/otReservedPatientList`, obj, {
    headers: authHeader(),
  });
};

//Un-Reservation
export const fetchUnReservationOtFlag = (
  otReservationId,
  menuId,
  previlege
) => {
  return apiClient.get(
    `/otReservation/unReserveOt/${otReservationId}/${menuId}/${previlege}`,
    {
      headers: authHeader(),
    }
  );
};

///prints
// export const fetchPrintOtReservationList = (printOtResObj) => {
//   return apiClient.post(`/reports/ot/OTReservationList`, printOtResObj, {
//     responseType: "blob",
//     headers: authHeader(),
//   });
// };

export const OTReservationBookinngList = (obj) => {
  return apiClient.post(`/reports/ot/OTReservationList`, obj, {
    headers: authHeader(),
  });
};

export const saveOTReservationLayout = (layoutObj, reservationId) => {
  return apiClient.post(
    `/otReservation/saveOTReservationLayout/${reservationId}`,
    layoutObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchReservedSurgeryLayout = (reservationId) => {
  return apiClient.get(
    `/otReservation/getReservedSurgeryLayout/${reservationId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchSurgeryInstrumentEquipment = (surgeryIds) => {
  return apiClient.get(
    `/otReservation/getSurgeryInstrumentEquipment/${surgeryIds}`,
    {
      headers: authHeader(),
    }
  );
};
