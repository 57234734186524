import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CustomInput from "../../../common/components/FormFields/CustomInput";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import BloodIssueNotePrint from "../../prints/BloodIssueNotePrint";
import { saveBloodIssueNote } from "../../services/bloodIssueNoteServices/BloodIssueNoteServices";

const bloodBagBalanceData = [
  {
    id: 1,
    "BloodBag Desc": "whole Blood",
    Balance: 0,
  },
  {
    id: 1,
    "BloodBag Desc": "PCV",
    Balance: 0,
  },
  {
    id: 1,
    "BloodBag Desc": "FFP",
    Balance: 0,
  },
  {
    id: 1,
    "BloodBag Desc": "RDP",
    Balance: 0,
  },
  {
    id: 1,
    "BloodBag Desc": "SDP",
    Balance: 0,
  },
  {
    id: 1,
    "BloodBag Desc": "CRYOPRECIPITATE",
    Balance: 0,
  },
];

const RequiredBloodFor = [
  {
    id: 0,
    label: "Adult",
    value: "Adult",
  },
  {
    id: 1,
    label: "Pediatric",
    value: "Pediatric",
  },
];

const schema = yup
  .object({
    wholeBloodDtls: yup.string().when("wholeBlood", {
      is: true,
      then: yup.string().required(),
    }),
    pcvDtls: yup.string().when("pcv", {
      is: true,
      then: yup.string().required(),
    }),
    ffpDtls: yup.string().when("ffp", {
      is: true,
      then: yup.string().required(),
    }),
    rdpDtls: yup.string().when("rdp", {
      is: true,
      then: yup.string().required(),
    }),
    sdpDtls: yup.string().when("sdp", {
      is: true,
      then: yup.string().required(),
    }),
    cryoprecipitateDtls: yup.string().when("cryoprecipitate", {
      is: true,
      then: yup.string().required(),
    }),
    specifyAnyOtherDtls: yup.string().when("specifyAnyOther", {
      is: true,
      then: yup.string().required(),
    }),
  })
  .required();

export default function BloodIssueDtlsModal(props) {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, selectedRow, populateTable, menuId, privilege } = props;

  //
  const [bloodBagData, setBloodBagData] = useState(bloodBagBalanceData);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [finalData, setFinalData] = useState("");
  //
  const [customError, setCustomError] = useState(false);

  //Print
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [issueId, setIssueId] = React.useState(null);
  //

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      routine: false,
      emergency: false,
      adult: false,
      pediatric: false,
      pcv: 0,
      ffp: 0,
      rdp: 0,
      sdp: 0,
      wholeBlood: 0,
      cryoprecipitate: 0,
      specifyAnyOther: 0,
      requireBloodFor: 0,
      receivingDate: new Date(),
      receivingTime: new Date(),
    },
  });

  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
  } = methods;

  const wholeBlood = watch("wholeBlood");
  const wholeBlood_Dtls = Number(watch("wholeBloodDtls"));
  const pcv = watch("pcv");
  const pcv_Dtls = watch("pcvDtls");
  const ffp_Dtls = watch("ffpDtls");
  const ffp = watch("ffp");
  const rdp = watch("rdp");
  const rdp_Dtls = watch("rdpDtls");
  const sdp = watch("sdp");
  const sdp_Dtls = watch("sdpDtls");
  const cryoprecipitate = watch("cryoprecipitate");
  const cryoprecipitate_Dtls = watch("cryoprecipitateDtls");
  const specifyAnyOther = watch("specifyAnyOther");
  //const specifyAnyOther_Dtls = watch("specifyAnyOtherDtls");

  useEffect(() => {
    setValue("routine", selectedRow?.Routine);
    setValue("emergency", selectedRow?.Emergency);
    setValue("adult", selectedRow?.Adult);
    setValue("pediatric", selectedRow?.Pediatric);
    setValue(
      "wholeBlood",
      selectedRow && selectedRow["Whole Blood"] === 1 ? true : false
    );
    setValue("wholeBloodDtls", selectedRow && selectedRow["Whole Blood Qty"]);
    setValue("pcvDtls", selectedRow && selectedRow["PCV Qty"]);
    setValue("rdpDtls", selectedRow && selectedRow["RDP Qty"]);
    setValue("sdpDtls", selectedRow && selectedRow["SDP Qty"]);
    setValue("cryoprecipitateDtls", selectedRow && selectedRow["FFP Qty"]);
    setValue("pcv", selectedRow?.PCV === 1 ? true : false);
    setValue("ffpDtls", selectedRow && selectedRow["FFP Qty"]);
    setValue("ffp", selectedRow?.FFP === 1 ? true : false);
    setValue("rdp", selectedRow?.RDP === 1 ? true : false);
    setValue("sdp", selectedRow?.SDP === 1 ? true : false);
    setValue(
      "cryoprecipitate",
      selectedRow?.CRYOPRECIPITATE === 1 ? true : false
    );
    setValue("cryoprecipitateDtls", selectedRow?.CRYOPRECIPITATEQty);
    setValue(
      "specifyAnyOtherDtls",
      selectedRow && Number(selectedRow["Any Other Text"])
    );
    setValue(
      "specifyAnyOther",
      selectedRow && selectedRow["Any Other"] === 1 ? true : false
    );
    setValue("LocationBloodNeeded", selectedRow && selectedRow["Location"]);
    setValue("dignosis", selectedRow && selectedRow["Diagnosis"]);
    setValue("requireBloodFor", selectedRow && selectedRow["Location"]);
    setValue("consultantName/Reason", selectedRow && selectedRow["Consultant"]);
    setValue("rmoName", selectedRow && selectedRow["RMO Name"]);
    setValue("requireBloodFor", selectedRow && selectedRow?.Adult > 0 ? 0 : 1);
  }, [selectedRow]);

  //print related
  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
      props.handleClose();
    }, 1000);

    return (
      <div className="hidden">
        <BloodIssueNotePrint
          admissionId={selectedRow && selectedRow?.admissionid}
          issueId={issueId}
          handleClose={handleClose}
        />
      </div>
    );
  };

  const onSubmit = (Data) => {
    setFinalData(Data);
    !customError
      ? setOpenConfirmationModal(true)
      : warningAlert("Quantity exceeds available Quantity.");
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);

    let finalObj = {};

    finalObj.privilege = privilege;
    finalObj.menuId = menuId;

    finalObj.acceptedBy = "";
    finalObj.acceptedDate = new Date();
    finalObj.acceptedTime = new Date();
    finalObj.adult = Number(data.requireBloodFor) === 0 ? 1 : 0;
    finalObj.anyOther = data.specifyAnyOther ? 1 : 0;
    finalObj.anyOtherText = data.specifyAnyOtherDtls;
    finalObj.consultantReason = data["consultantName/Reason"];
    finalObj.cryoprecipitate = data.cryoprecipitate ? 1 : 0;
    finalObj.cryoprecipitateQty = Number(data.cryoprecipitateDtls) || 0;
    finalObj.diagnosis = data.dignosis;
    finalObj.emergency = data.emergency ? 1 : 0;
    finalObj.ffp = data.ffp ? 1 : 0;
    finalObj.ffpQty = Number(data.ffpDtls) || 0;
    finalObj.location = data.LocationBloodNeeded;
    finalObj.otherInformation = data.other;
    finalObj.pcv = data.pcv ? 1 : 0;
    finalObj.pcvQty = Number(data.pcvDtls) || 0;
    finalObj.pediatric = Number(data.requireBloodFor) === 1 ? 1 : 0;
    finalObj.quantity = 0;
    finalObj.rdp = data.rdp ? 1 : 0;
    finalObj.rdpQty = Number(data.rdpDtls) || 0;
    finalObj.receivedBy = data.receivedBy;
    finalObj.receivingDate = data.receivingDate ?? new Date();
    finalObj.receivingTime = data.receivingTime ?? new Date();
    finalObj.requisitionID = selectedRow.RequisitionID;
    finalObj.rmoName = data.rmoName;
    finalObj.routine = data.routine ? 1 : 0;
    finalObj.sdp = data.sdp ? 1 : 0;
    finalObj.sdpQty = Number(data.sdpDtls) || 0;
    finalObj.unitsNeeded = 0;
    finalObj.wholeBlood = data.wholeBlood ? 1 : 0;
    finalObj.wholeBloodQty = Number(data.wholeBloodDtls) || 0;

    saveBloodIssueNote(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          //  handleClose();
          populateTable();
          setIssueId(res.result);
          setOpenPrintModal(true);

          //  renderPrint(res.result)
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    let temp = [...bloodBagData];
    for (let item of temp) {
      if (item["BloodBag Desc"] === "whole Blood") {
        item.Balance = selectedRow["Whole Blood Qty"];
      }
      if (item["BloodBag Desc"] === "PCV") {
        item.Balance = selectedRow["PCV Qty"];
      }
      if (item["BloodBag Desc"] === "FFP") {
        item.Balance = selectedRow["FFP Qty"];
      }
      if (item["BloodBag Desc"] === "RDP") {
        item.Balance = selectedRow["RDP Qty"];
      }
      if (item["BloodBag Desc"] === "SDP") {
        item.Balance = selectedRow["SDP Qty"];
      }
      if (item["BloodBag Desc"] === "CRYOPRECIPITATE") {
        item.Balance = selectedRow["CRYOPRECIPITATEQty"];
      }
    }
    setBloodBagData(temp);
  }, [selectedRow]);

  useEffect(() => {
    const fieldsToValidate = [
      { name: "wholeBloodDtls", quantity: selectedRow["Whole Blood Qty"] },
      { name: "pcvDtls", quantity: selectedRow["PCV Qty"] },
      { name: "ffpDtls", quantity: selectedRow["FFP Qty"] },
      { name: "rdpDtls", quantity: selectedRow["RDP Qty"] },
      { name: "sdpDtls", quantity: selectedRow["SDP Qty"] },
      {
        name: "cryoprecipitateDtls",
        quantity: selectedRow["CRYOPRECIPITATEQty"],
      },
      { name: "specifyAnyOtherDtls", quantity: selectedRow["Any Other Text"] },
    ];

    const hasCustomError = fieldsToValidate.some((field) => {
      const { name, quantity } = field;
      return Number(watch(name)) > quantity;
    });

    if (hasCustomError) {
      warningAlert("Quantity exceeds available Quantity.");
    }

    setCustomError(hasCustomError);
  }, [
    wholeBlood_Dtls,
    pcv_Dtls,
    ffp_Dtls,
    rdp_Dtls,
    sdp_Dtls,
    cryoprecipitate_Dtls,
  ]);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
                Blood Issue Details
              </div>

              <div className=" pb-2 grid grid-cols-3">
                {/* patientInfo */}
                <fieldset
                  className="grid grid-cols-12 items-center border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
                >
                  <div className="py-2 col-span-11 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Patient Name"]}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">MR. No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.MRNo}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*<div className="text-customGray flex justify-end">
                    <Tooltip title="Print Blood Issue Details">
                      <LocalPrintshopIcon
                        onClick={() => {
                          setOpenPrintModal(true);
                        }}
                      />
                    </Tooltip>
                      </div>*/}
                </fieldset>
              </div>
              <div className="grid lg:grid-cols-8 md:grid-cols-4 gap-3 mt-3">
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="routine"
                    label="Routine "
                  />
                </div>

                <div className="">
                  <CheckBoxField
                    control={control}
                    name="emergency"
                    label="Emergency"
                  />
                </div>

                <div className="col-span-6 ">
                  <TextField
                    id="outlined-multiline-static"
                    name="dignosis"
                    {...register("dignosis")}
                    label="Dignosis"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={1}
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>
              <hr className="border my-2 md:col-span-4 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300" />

              <p className="my-3 font-bold text-red-500 tracking-wide text-sm font-Poppins">
                * If blood has not been reserved pls fill requisition and
                consent alongwith this note.
              </p>

              <div className="flex gap-3 items-center">
                <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                  Required Blood :
                </p>
                <RadioField
                  label=""
                  name="requireBloodFor"
                  control={control}
                  dataArray={RequiredBloodFor}
                />
              </div>
              <div className="grid md:grid-cols-1 lg:grid-cols-5 gap-3">
                <div className="lg:col-span-3">
                  <div className=" gap-x-3 grid md:grid-cols-2 lg:grid-cols-4">
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="wholeBlood"
                          label="Whole Blood "
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="wholeBloodDtls"
                          error={errors.wholeBloodDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!wholeBlood}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="pcv"
                          label="PCV "
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="pcvDtls"
                          error={errors.pcvDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!pcv}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="ffp"
                          label="FFP"
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="ffpDtls"
                          error={errors.ffpDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!ffp}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="rdp"
                          label="RDP"
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="rdpDtls"
                          error={errors.rdpDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!rdp}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="sdp"
                          label="SDP"
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="sdpDtls"
                          error={errors.sdpDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!sdp}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="cryoprecipitate"
                          label="CRYOPRECIPITATE"
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="cryoprecipitateDtls"
                          error={errors.cryoprecipitateDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!cryoprecipitate}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <CheckBoxField
                          control={control}
                          name="specifyAnyOther"
                          label="Any Other"
                        />
                      </div>
                      <div className="pt-2">
                        <CustomInput
                          name="specifyAnyOtherDtls"
                          error={errors.specifyAnyOtherDtls}
                          type="number"
                          variant="outlined"
                          control={control}
                          disabled={!specifyAnyOther}
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </div>

                    <div className="md:col-span-2 lg:col-span-3 mt-3">
                      <TextField
                        id="outlined-multiline-static"
                        name="other"
                        {...register("consultantName/Reason")}
                        label="More than one unit : Consultant Name/Reason"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={1}
                        fullWidth
                        sx={{
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="lg:col-span-2">
                  <CommonDynamicTableNew
                    dataResult={bloodBagData}
                    removeHeaders={["id"]}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-8 gap-3 mt-3">
                <div className="md:col-span-1 lg:col-span-3">
                  <InputField
                    name="LocationBloodNeeded"
                    label="Location where blood will be needed"
                    variant="outlined"
                    // error={errors.representativeAddress}
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>

                <div className=" lg:col-span-2 md:col-span-1">
                  <InputField
                    name="rmoName"
                    label="RMO Name"
                    variant="outlined"
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>

                <div className="md:col-span-2 lg:col-span-3 ">
                  <TextField
                    id="outlined-multiline-static"
                    name="other"
                    {...register("other")}
                    // label="Dignosis"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={1}
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>

              <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                For Blood Storage
              </p>
              <div className="grid md:grid-cols-4 lg:grid-cols-6 gap-3 my-3">
                <div className=" ">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="Receiving Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    disabled={true}
                  />
                </div>
                <div className="">
                  <Controller
                    control={control}
                    name="receivingTime"
                    render={({ field: { value, onChange } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          label="Receiving Time"
                          value={value}
                          onChange={onChange}
                          disabled={true}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              // error={errors.startTime}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                style: {
                                  fontSize: "14px",
                                  position: "absolute",
                                  top: "-2px",
                                },
                              }}
                              sx={{
                                svg: {
                                  color: "#1e3a8a",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",

                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    // border: 0,
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",

                                    borderColor: "  ",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <InputField
                    name="receivedBy"
                    label={"Received By"}
                    defaultValue={token?.userName}
                    variant="outlined"
                    control={control}
                    disabled={true}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                <CommonButton
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {openPrintModal && renderPrint()}
    </div>
  );
}
