import { Box, Modal } from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  fetchOtTemplates,
  saveSurgeryNotes,
} from "../../../services/otDetailsServices/OtDetailsServices";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const SurgeryNotesModal = (props) => {
  const { selectedRowData, privilege, menuId } = props;
  const [templateContent, setTemplateContent] = useState("");
  const [templets, setTemplets] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { control, register, reset } = useForm({ mode: "onChange" });

  const editor = useRef(null);

  const config = {
    readonly: false,
    height: 340,
    allowResizeY: false,
    statusbar: false,
    buttons: [
      "bold",
      "underline",
      "strikethrough",
      "italic",
      "image",
      "indent",
      "outdent",
      "fontsize",
      "paragraph",
      "brush",
      "|",
      "align",
      "ul",
      "ol",
      "table",
      "hr",
      "symbol",
      "eraser",
      "copyformat",
      "superscript",
      "subscript",
      "undo",
      "redo",
      "find",
      "preview",
      "print",
    ],
    imageDefaultWidth: 100,
    uploader: {
      insertImageAsBase64URI: true,
    },
  };

  useEffect(() => {
    fetchOtTemplates()
      .then((response) => response.data)
      .then((res) => {
        setTemplets(res.result);
      });
  }, []);

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const finalObj = {
      headerId: selectedRowData?.OTHeaderID,
      surgeryNoteDesc: templateContent,
      previlage: privilege,
      menuId: menuId,
    };

    saveSurgeryNotes(finalObj)
      .then((response) => response?.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          reset(); // Reset form after successful save
          setTemplateContent(""); // Clear editor content
          props.handleClose(); // Close modal
        }
      })
      .catch((res) => {
        errorAlert(res.message);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    if (selectedRowData) {
      setTemplateContent(selectedRowData?.SurgeryNotes);
    }
  }, [selectedRowData]);

  return (
    <Modal open={props.open}>
      <Box sx={ModalStyle}>
        <div className="mx-3 mb-3 mt-2">
          <div className="p-2 rounded text-black font-semibold">
            Surgery Notes
          </div>
          <CancelPresentationIconButton onClick={props.handleClose} />
          <hr className="border mx-1 border-blue-900" />

          <div className="grid grid-cols-3 mt-3">
            <div>
              <DropdownField
                control={control}
                name="templateName"
                placeholder="Select Template"
                isSearchable={false}
                dataArray={templets}
                inputRef={{
                  ...register("templateName", {
                    onChange: (e) => {
                      setTemplateContent(e.target.value.value);
                    },
                  }),
                }}
              />
            </div>
          </div>

          <div className="mt-2">
            <JoditEditor
              ref={editor}
              value={templateContent}
              config={config}
              onBlur={(newContent) => {
                if (newContent !== "") {
                  setTemplateContent(newContent);
                }
              }}
            />
          </div>

          <div className="flex justify-end my-3 gap-x-3">
            <CommonButton
              label={"Reset"}
              className={"border border-customRed text-customRed"}
              onClick={() => {
                setTemplateContent("");
                reset();
              }}
            />
            <CommonButton
              label={"Save"}
              className={"bg-customGreen text-white"}
              onClick={onSubmit}
            />
          </div>
        </div>

        {/* Backdrop */}
        <CommonBackDrop openBackdrop={openBackdrop} />

        {/* Confirmation Modal */}
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => setOpenConfirmationModal(false)}
          confirmationSubmitFunc={handleFormSubmit}
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure?"
          confirmationButtonMsg="Proceed"
        />
      </Box>
    </Modal>
  );
};

export default SurgeryNotesModal;

