import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonTransactionPaginationTableNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { errorAlert } from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import OrderSheetPhysioPrint from "../../../prints/OrderSheetPhysioPrint";
import OrderSheetPrint from "../../../prints/OrderSheetPrint";
import {
  fetchAllOrderSheetList,
  searchOrderSheetViwe
} from "../../../services/cliinicalChartServices/orderSheetServices/OrderSheetServices";
const RadioArray = [
  {
    id: "Nursing",
    value: "Nursing",
    label: "Nursing",
  },
  {
    id: "Doctor",
    value: "Doctor",
    label: "Doctor",
  },
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Physiotherapy Orders",
    value: "Physiotherapy Orders",
    label: "Physiotherapy Orders",
  },
];

export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "scroll",
  height: "70%",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
};
export default function ViewOrderSheet(props) {
  const defaultValues = {
    searchMRNO: null,
    status: "All",
    fromDate: new Date(),
    toDate: new Date(),
  };
  const {
    control,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [options, setOptions] = useState([]);
  const [searchId, setSearchId] = useState(null);
  const [isPrintAll, setIsPrintAll] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showSpinner, setShowSpinner] = useState(false);
  const [
    viewOrderSheetDataResult,
    setViewOrderSheetDataResult,
  ] = React.useState([]);

  //
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");
  let staus_selected = watch("status");

  const handleChange = (autoSearchString) => {
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      let obj = {
        endDate: toDate,
        searchString: autoSearchString,
        startDate: fromDate,
      };
      searchOrderSheetViwe(obj)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  const filterData = () => {
    populatViewOrderListTable();
  };

  React.useEffect(() => {
    populatViewOrderListTable();
  }, [searchId]);

  // {
  //   "endDate": "2023-10-25T10:31:02.067Z",

  //   "searchString": "string",

  //   "startDate": "2023-10-25T10:31:02.067Z"
  // }

  const populatViewOrderListTable = (forPagination) => {
    console.log(searchId, props?.admissionId);
    setShowSpinner(true);
    let defaultParams = {
      endDate: toDate,
      mrno: "",
      page: page,
      size: rowsPerPage,
      searchId: searchId || props?.admissionId || null, ///this on order id may be
      startDate: fromDate,
      status: staus_selected,
    };
    fetchAllOrderSheetList(defaultParams)
      .then((response) => {
        setCount(response.data.count);
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setViewOrderSheetDataResult((prevData) => [
            ...prevData,
            ...res.result,
          ]);
        } else {
          setViewOrderSheetDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        setShowSpinner(false);
      });
  };

  //Print
  const [orderSheetId, setOrderSheetId] = useState(null);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const renderPrint = () => {
    setTimeout(
      () => {
        setOpenPrintModal(false);
      },
      staus_selected === "Doctor" ? 15000 : 5000
    );

    console.log("openPrintModal",openPrintModal);

    return (
      <div className="hidden">
        {staus_selected === "Physiotherapy Orders" ? (
          <OrderSheetPhysioPrint
            orderSheetDtlsId={orderSheetId}
            searchId={searchId || props?.admissionId || null}
            status={staus_selected}
            isPrintAll={isPrintAll}
            setOpenBackdrop={setOpenBackdrop}
            ptInfo={props.ptInfo}
          />
        ) : (
          <OrderSheetPrint
            orderSheetId={orderSheetId}
            searchId={searchId || props?.admissionId || null}
            status={staus_selected}
            isPrintAll={isPrintAll}
            setOpenBackdrop={setOpenBackdrop}
          />
        )}
      </div>
    );
  };
  //Table
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <div className="flex gap-2 cursor-pointer">
          <>
            <Tooltip title="Print">
              <LocalPrintshopIcon
                sx={{ color: "#4B5563" }}
                onClick={() => {
                  !openPrintModal && setOpenPrintModal(true);
                  //setOpenBackdrop(true);

                  staus_selected === "Physiotherapy Orders"
                    ? setOrderSheetId(row.OrderDtlsId)
                    : setOrderSheetId(row.id);
                  setIsPrintAll(false);
                }}
              />
            </Tooltip>
          </>
        </div>
      </div>
    );
  };
  const renderInput = (row, index, header) => {
    return (
      <div className="flex gap-3">
        {header === "ReferDoctors" && (
          <div className="flex gap-3">
            {!!row.ReferDoctors &&
              JSON.parse(row.ReferDoctors).map((item, index) => {
                return (
                  <div key={index} className="flex gap-2 items-center">
                    <div className="rounded-full bg-slate-600 w-2 h-2"></div>
                    <div>{item.ReferDoctor} </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  console.log("selectedStatus", selectedStatus);

  return (
    <>
      <div className="w-full grid justify-center items-center rounded mt-4">
        <Modal
          open={props.openViewOrder}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Style} className="max-h-[92%] w-[80%] p-4   ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleViewOrderClose();
                reset(defaultValues);
              }}
            />
            <div className="row gap-y-2">
              <legend className="text-center font-bold text-black pt-2">
                View Order Sheet
              </legend>

              <div className="grid grid-cols-3 py-2 gap-2">
                <div className="flex gap-3">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    // onChange={(newValue) => {
                    //   setValue("fromDate", newValue);
                    // }}
                    disablePast={false}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    // onChange={(newValue) => {
                    //   setValue("toDate", newValue);
                    // }}
                    disablePast={false}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="flex gap-2 col-span-2">
                  {/* <SearchDropdown
                    control={control}
                    name="searchMRNO"
                    label="Search By MRNO."
                    placeholder="Search By MRNO."
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    dataArray={options}
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("searchMRNO", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSearchId(e.target.value.id);
                          } else if (e.target.value === null) {
                            setSearchId(null);
                          }
                        },
                      }),
                    }}
                  />*/}
                  <div className="flex gap-2 w-full items-center">
                    <RadioField
                      label=""
                      name="status"
                      control={control}
                      dataArray={RadioArray}
                    />
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      searchIcon={true}
                      onClick={() => {
                        filterData();
                        setSelectedStatus(staus_selected);
                        setViewOrderSheetDataResult([]);
                      }}
                    />
                  </div>
                  <div className="col-span-1 flex justify-end">
                    <CommonButton
                      className="bg-customGray text-white"
                      label="Print All"
                      onClick={() => {
                        // handlePrintAllOrder();
                        setOpenPrintModal(true);
                        setIsPrintAll(true);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="grid justify-end">
                <>
                  <Tooltip title="Print All">
                    <LocalPrintshopIcon
                      sx={{ color: "#E3902F" }}
                      onClick={() => {
                      }}
                    />
                  </Tooltip>
                </>
              </div> */}
              {showSpinner ? (
                <div className="flex justify-center mt-14">
                  <LoadingSpinner />
                </div>
              ) : viewOrderSheetDataResult &&
                viewOrderSheetDataResult.length > 0 ? (
                selectedStatus !== "Physiotherapy Orders" ? (
                  <CommonTransactionPaginationTableNew
                    dataResult={viewOrderSheetDataResult}
                    setDataResult={setViewOrderSheetDataResult}
                    actionsRequired={true}
                    populateTable={populatViewOrderListTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    renderActions={renderActions}
                    renderInput={renderInput}
                    editableColumns={["ReferDoctors"]}
                    highlightRow={false}
                    removeHeaders={[
                      "id",
                      "opd_ipd_externalid",
                      "opd_ipd",
                      "subjective",
                      "temp",
                      "pulse",
                      "bp",
                      "rr",
                      "mews",
                      "cvs",
                      "rs",
                      "pa",
                      "cns",
                      "localexamination",
                      "other",
                      "assesment",
                      "pain",
                      "AdmissionId",
                      "addedby",
                    ]}
                  />
                ) : (
                  <CommonDynamicTableNew
                    dataResult={viewOrderSheetDataResult}
                    renderActions={renderActions}
                    renderInput={renderInput}
                    editableColumns={["ReferDoctors"]}
                    highlightRow={false}
                    removeHeaders={[
                      "id",
                      "opd_ipd_externalid",
                      "opd_ipd",
                      "subjective",
                      "temp",
                      "pulse",
                      "bp",
                      "rr",
                      "mews",
                      "cvs",
                      "rs",
                      "pa",
                      "cns",
                      "localexamination",
                      "other",
                      "assesment",
                      "pain",
                      "AdmissionId",
                      "addedby",
                    ]}
                  />
                )
              ) : (
                <h3 className="flex justify-center my-20 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>
          </Box>
        </Modal>
      </div>
      {!!openPrintModal && renderPrint()}
      {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
    </>
  );
}
