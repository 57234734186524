import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//save config
export const updateConfiguration = (finalObj) => {
  return apiClient.post(`/administration/updateConfiguration`, finalObj, {
    headers: authHeader(),
  });
};
//get config
export const fetchConfigurationDetails = () => {
  return apiClient.get(`/administration/getConfigurationDetails`, {
    headers: authHeader(),
  });
};

//Group
export const fetchGroups = () => {
  return apiClient.get(`/otReservation/groups`, {
    headers: authHeader(),
  });
};


export const fetchClassesApplicableTo = (opdIpdBoth) => {
  return apiClient.get(`/serviceMaster/getClasses/${opdIpdBoth}`, {
    headers: authHeader(),
  });
};

