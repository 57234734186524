import { yupResolver } from "@hookform/resolvers/yup";
import { Print } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import { Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchStoreName } from "../../../services/commonServices/CommonServices";
import {
  isCloseIndent,
  isVerifyIndent,
} from "../../../services/commonServices/StoreIndent/StoreIndentService";
import {
  fetchIndentItemDetailsList,
  fetchIpdIssueListTable,
  fetchPrintPatientIndentList,
} from "../../../services/generalStore/PatientIndentServices/PatientIndentServices";
import { VerifyDisabledIcon } from "../../generalStore/verifyGrn/assets/VerifyDisabledIcon";
import { VerifyIcon } from "../storeIndent/assets/VerifyIcon";
import { Tooltip } from "@mui/material";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "90%",
  maxHeight: "80%",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};
//radio button data
const IndendStatus = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "pending",
    label: "Pending",
  },
  {
    id: 0,
    value: "completed",
    label: "Completed",
  },
];

const IsUrgent = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "yes",
    label: "Yes",
  },
  {
    id: 0,
    value: "no",
    label: "No",
  },
];

const PaymentType = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "cash",
    label: "Cash",
  },
  {
    id: 0,
    value: "credit",
    label: "Credit",
  },
];

export default function IndentListModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    open,
    handleClose,
    menuId,
    privilege,
    setPrivilege,
    userActions,
  } = props;

  const defaultValues = {
    isUrgent: 2,
    indentStatusValue: 2,
    paymentType: 2,
    fromDate: new Date(),
    toDate: new Date(),
    indentFromStore: {
      id: token?.storeId,
      label: token?.storeName,
      value: token?.storeName,
    },
  };
  const {
    formState: { errors },
    control,
    getValues,
    watch,
    register,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues,
  });
  let storeId = token.storeId;
  //indent Item Details List Data
  const [indentItemDetailsListData, setIndentItemDetailsListData] = useState(
    []
  );
  // ipd issue table
  const [indentId, setIndentId] = useState(null);

  const [indentListData, setIndentListData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();

  //store name
  const [storeName, setStoreName] = useState();
  //fromStore
  const [fromStore, setFromStore] = useState(0);
  //ToStore
  const [toStore, setToStore] = useState(0);
  //Actions
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openPost, setOpenPost] = useState(false);
  const [isClosedIndent, setIsClosedIndent] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const isUrgent = watch("isUrgent");
  const indentStatusValue = watch("indentStatusValue");
  const paymentType = watch("paymentType");
  let selectedFromDate = watch("fromDate");
  let selectedToDate = watch("toDate");
  let selectFromDate = getValues("fromDate");
  let selectToDate = getValues("toDate");

  // Store Name
  const getStoreName = () => {
    fetchStoreName()
      .then((response) => response.data)
      .then((res) => {
        setStoreName(res.result);
      });
  };

  const getIpdIssueListTable = (forPagination) => {
    let defaultParams = {
      cashCredit: parseInt(paymentType),
      fromDate: selectFromDate,
      //fromStoreId: fromStore,
      fromStoreId: token?.storeId,
      indentStatus: parseInt(indentStatusValue),
      isApproved: 2,
      isCancelled: 2,
      isForceFullyClosed: 2,
      isUrgent: parseInt(isUrgent),
      loginStoreId: storeId, //storeId,
      searchString: "",
      toDate: selectToDate,
      toStoreId: toStore,
      menuId: props.menuId,
      page: !forPagination ? 0 : page,
      privilege: props.privilege,
      size: rowsPerPage,
    };
    fetchIpdIssueListTable(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIndentListData((prevData) => [
            ...prevData,
            ...res.result.getIndentList,
          ]);
        } else {
          setIndentListData(res.result.getIndentList);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch(() => {});
  };

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleCloseIndent = () => {
    setIsClosedIndent(false);
  };

  const handleClosePost = () => {
    setOpenPost(false);
  };

  const handleOpenPrintModal = (IndentId) => {
    fetchPrintPatientIndentList(IndentId, true).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  const handlerSelectedrow = (row, index) => {
    setIndentId(row.IndentId);
  };

  let verifyObj = {
    // getUserInfo.userId
    indentId: selectedRow?.IndentId,
    menuId: menuId,
    privilege: privilege,
  };
  const verifyIndent = () => {
    isVerifyIndent(verifyObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        getIpdIssueListTable();
        setOpenPost(false);
        setSelectedRow(null);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  let closeObj = {
    // getUserInfo.userId
    indentId: selectedRow?.IndentId,
    menuId: menuId,
    privilege: privilege,
  };
  const CloseIndent = () => {
    isCloseIndent(closeObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        getIpdIssueListTable();
        setIsClosedIndent(false);
        setSelectedRow(null);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const renderIndentListActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "Print" ? (
                    <Tooltip title="Print">
                      <button
                        type="button"
                        className="text-gray-600  cursor-pointer"
                        onClick={() => {
                          setPrivilege(actions.action);
                          handleOpenPrintModal(row.IndentId);
                        }}
                      >
                        {<Print />}
                      </button>
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {actions.action === "Approve" ? (
                    row.IsApproved === false ? (
                      <div className="flex justify-center ">
                        <Tooltip title="Verify Indent">
                          <button
                            type="button"
                            className="cursor-pointer"
                            onClick={() => {
                              setPrivilege(actions.action);
                              setOpenPost(true);
                              setSelectedRow(row);
                            }}
                          >
                            <VerifyIcon className="" />
                          </button>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="">
                        <VerifyDisabledIcon className="pl-3 cursor-pointer" />
                      </div>
                    )
                  ) : null}

                  {actions.action === "Cancel" ? (
                    row.Closed === false ? (
                      <div className="flex items-center space-x-1">
                        <Tooltip title="Close Indent">
                          <button
                            type="button"
                            className="text-gray-600 "
                            onClick={() => {
                              setPrivilege(actions.action);
                              setIsClosedIndent(true);
                              setSelectedRow(row);
                            }}
                          >
                            <HighlightOffIcon />
                          </button>
                        </Tooltip>
                      </div>
                    ) : (
                      <span>
                        <HighlightOffIcon className="text-gray-200" />
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };
  useEffect(() => {
    getStoreName();
  }, []);

  useEffect(() => {
    if (indentStatusValue) {
      setIndentId(null);
    }
  }, [indentStatusValue]);

  useEffect(() => {
    getIpdIssueListTable();
  }, [
    storeId,
    toStore,
    fromStore,
    isUrgent,
    paymentType,
    indentStatusValue,
    // indentId,
    selectedFromDate,
    selectedToDate,
  ]);
  //Indent Item Details List
  useEffect(() => {
    if (indentId !== null) {
      fetchIndentItemDetailsList(indentId)
        .then((response) => response.data)
        .then((res) => {
          setIndentItemDetailsListData(res.result);
        });
    }
  }, [indentId]);
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              reset(defaultValues);
            }}
          />
          <label className="text-lg text-black text-center font-semibold flex justify-center my-1">
            Patient Indent List
          </label>
          <div className="grid gap-2 w-full items-center mt-2">
            <div className="grid grid-cols-4 lg:grid-cols-12 w-full gap-2 items-center">
              <div className="grid grid-cols-2 col-span-3 gap-3">
                <div>
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
                <div>
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="col-span-1 lg:col-span-2">
                <DropdownField
                  control={control}
                  name="indentFromStore"
                  label="From Store"
                  dataArray={storeName}
                  isSearchable={false}
                  isDisabled={true}
                  isClearable={true}
                  placeholder="From Store"
                  inputRef={{
                    ...register("indentFromStore", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          setFromStore(e.target.value.id);
                          setIndentId(0);
                        } else {
                          setFromStore(0);
                          setIndentId(0);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="col-span-1 lg:col-span-2">
                <DropdownField
                  control={control}
                  name="indentToStore"
                  label="To Store"
                  dataArray={storeName}
                  isSearchable={false}
                  isClearable={true}
                  placeholder="To Store"
                  inputRef={{
                    ...register("indentToStore", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          setToStore(e.target.value.id);
                          setIndentId(0);
                        } else {
                          setFromStore(0);
                          setIndentId(0);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="md:col-span-2  flex md:justify-start text-sm  gap-x-3  items-center">
                <p className="text-gray-700 font-semibold whitespace-nowrap">
                  Urgent :
                </p>
                <div className="mt-1">
                  <RadioField
                    label=""
                    name="isUrgent"
                    control={control}
                    dataArray={IsUrgent}
                  />
                </div>
              </div>
              <div className="md:col-span-4 lg:col-span-1 flex md:justify-start text-sm  gap-x-3 items-center">
                <p className="text-gray-700 font-semibold whitespace-nowrap">
                  Status :
                </p>
                <div className="mt-1">
                  <RadioField
                    label=""
                    name="indentStatusValue"
                    control={control}
                    dataArray={IndendStatus}
                  />
                </div>
              </div>
              <div className="md:col-span-4  flex md:justify-start text-sm gap-x-3 items-center">
                <p className="text-gray-700 font-semibold">Payment Type :</p>
                <div className="mt-1">
                  <RadioField
                    label=""
                    name="paymentType"
                    control={control}
                    dataArray={PaymentType}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {indentListData?.length > 0 ? (
              <>
                <div className="">
                  <div className="mt-2">
                    <CommonDynamicTablePaginationNew
                      populateTable={getIpdIssueListTable}
                      dataResult={indentListData}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      count={count}
                      handleSelectedRow={handlerSelectedrow}
                      setRowsPerPage={setRowsPerPage}
                      renderActions={renderIndentListActions}
                      highlightRow={true}
                      tableHeading={"IPD Issue List"}
                      removeHeaders={[
                        "DrId",
                        "FromStoreId",
                        "IndentId",
                        "ToStoreId",
                      ]}
                      tableClass={"h-[300px]"}
                    />
                  </div>
                  {/* <CommonDynamicTablePaginationNew
                    dataResult={indentListData}
                    setDataResult={setIndentListData}
                    actionsRequired={true}
                    populateTable={getIpdIssueListTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    handleSelectedRow={handlerSelectedrow}
                    highlightRow={true}
                    //Function to Show lineItem
                    renderActions={renderIndentListActions}
                    tableHeading={"IPD Issue List"}
                    removeHeaders={[
                      "DrId",
                      "FromStoreId",
                      "IndentId",
                      "ToStoreId",
                    ]}
                    tableClass={"rounded lg:h-60 md:h-44"}
                  /> */}
                </div>
              </>
            ) : (
              <div className="text-center text-sm font-bold">
                Data Not Found...!
              </div>
            )}
            <div className="flex justify-between items-center">
              {indentListData.length > 0 ? (
                <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                  IPD Issue Details
                </div>
              ) : null}
              <div className="flex items-center gap-2 w-full col-span-2 space-x-2 text-base xl:col-span-1 font-semibold py-1 justify-end ">
                <span className=" text-black font-semibold whitespace-nowrap w-32">
                  Number Of Indents
                </span>
                <div className="flex space-x-2 items-center">
                  <span>: </span>
                  <span className="text-gray-700 font-semibold">{count}</span>
                </div>
              </div>
            </div>
            <div className="">
              {indentId !== null &&
              indentListData?.length > 0 &&
              indentItemDetailsListData?.length > 0 ? (
                <CommonDynamicTable
                  dataResult={indentItemDetailsListData}
                  tableClass={"rounded lg:h-60 md:h-44 border"}
                  highlightRow={false}
                />
              ) : null}
            </div>
          </div>
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end ">
            <div>
              <CommonButton
                type="button"
                className="border border-customRed text-customRed"
                label="Close"
                onClick={(e) => {
                  handleClose();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={verifyIndent}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to verify this indent ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={isClosedIndent}
        confirmationHandleClose={handleCloseIndent}
        confirmationSubmitFunc={CloseIndent}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to close this indent ?"
        confirmationButtonMsg="Yes"
      />
    </div>
  );
}
