import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

// /api/supplierAdvance/advanceAgainst
export const getAdvanceAgainst = () => {
  return apiClient.get(`/supplierAdvance/advanceAgainst`, {
    headers: authHeader(),
  });
};
// /api/supplierAdvance/pODetails/{supplierId}
export const getPODetails = (supplierId, advanceAgainstId) => {
  return apiClient.get(
    `/supplierAdvance/pODetails/${supplierId}/${advanceAgainstId}`,
    {
      headers: authHeader(),
    }
  );
};

// /api/supplierAdvance/getPOWONumberDetails/{supplierId}/{advanceAgainstId}/{storeId}
export const getPOWONumber = (supplierId, advanceAgainstId, storeId) => {
  return apiClient.get(
    `/supplierAdvance/getPOWONumberDetails/${supplierId}/${advanceAgainstId}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};

// /api/supplierAdvance/tds
export const getTDS = () => {
  return apiClient.get(`/supplierAdvance/tds`, {
    headers: authHeader(),
  });
};

// /api/supplierAdvance/listOfAdvances/{supplierId}
export const fetchListOfSupplierAdvance = (supplierId) => {
  return apiClient.get(`/supplierAdvance/listOfAdvances/${supplierId}`, {
    headers: authHeader(),
  });
};
// /api/supplierAdvance/saveSupplierAdvance
export const saveSupplierAdvance = (postObj) => {
  return apiClient.post(`/supplierAdvance/saveSupplierAdvance`, postObj, {
    headers: authHeader(),
  });
};
