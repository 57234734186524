import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";

import {
  fetEmergencyBedCategory,
  fetchIpdConsentFormPrint,
  postMyEmergencyData,
} from "../../services/emergencyservice/EmergencyServices";

import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchAdmissionType,
  fetchBirthDetails,
  fetchDoctor,
  fetchGenders,
  fetchPatientsource,
  fetchPrefix,
  fetchReferanceDoctor,
} from "../../../commonServices/miscellaneousServices/MiscServices";
// import MLCModalEdit from "./MLCModalEdit";
import { format, isAfter } from "date-fns";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

export default function IpdNotRegisteredForm(props) {
  const [openModal, setOpenModal] = React.useState(false);
  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);
  const [genders, setGenders] = React.useState([]);
  const [doctor, setDoctor] = useState();
  const [prefixes, setPrefixes] = React.useState();
  const [enteredAge, setEnteredAge] = React.useState();
  const [patientSource, setpatientSource] = React.useState(); //Patient Source
  const [referalConsultant, setReferalConsultant] = React.useState();
  const [classTypeOptions, setClasstypeOptions] = React.useState([]); //Bed Category
  // const [unit, setUnit] = React.useState([]);
  const [finalData, setFinalData] = React.useState({});
  const [mlcModalData, setMlcModalData] = React.useState(null);
  const [admissionType, setAdmissionType] = useState();

  //
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    props.setOpen(false);
  };

  const handleOpenPrintModal = (emergencyId) => {
    props.setOpenBackdrop(true);

    fetchIpdConsentFormPrint(emergencyId).then((response) => {
      if (response) {
        props.setOpenBackdrop(false);

        setUrlforPrint(response);
        setOpenPrintModal(true);
      }
    });
  };

  //

  const schema = yup.object().shape({
    prefix: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Please Select Prefix"),
        label: yup.string().required("Please Select Prefix"),
      }),
    firstName: yup
      .string()
      .required("Required")
      .min(1, "Add First Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),
    lastName: yup
      .string()
      .required("Required")
      .min(1, "Add Last Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),

    bedCategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),

    gender: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),

    doctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),

    dob: yup
      .date()
      .typeError("Please Provide Date of Birth in Valid Format")
      .min("01/01/1902", "You are Not Eligible")
      .max(new Date(), "You can't be born in the future!")
      .required(),

    age: yup.number().max(121).required("Required"),

    admissionType: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Category"),
        label: yup.string().required("Please Select Category"),
      })
      .required("Required"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      opdIpd: 1, //0 for OPD , 1 for IPD
      isEmergency: true,
      isMlc: false,
      admissionAdvice: false,
      unit: null,
      //employee: null,
      referalConsultant: 0,
      bedCategory: null,
      doctor: null,
      admissionType: null,
      //mobileNumber: "",
      remarks: "",
      medicalManagement: false,
      patientSource: 0,
      surgicalManagement: false,
      dob: new Date(),
      age: 0,
      ageInYears: 0,
      ageInMonths: 0,
      ageInDays: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: 0,
      prefix: null,
    },
  });

  const finalObject = {
    admissionType: "",
    opdIpd: 1, //0 for OPD , 1 for IPD
    addedBy: 0,
    isEmergency: true,
    unitId: null,
    patientId: 0,
    prefixId: 0,
    firstName: "",
    genderId: 0,
    lastName: "",
    middleName: "",
    dob: "",
    ageYear: 0,
    ageDay: 0,
    ageMonth: 0,
    mrNo: 0,
    isMlc: false,
    mlcRequestDto: {},
    admissionAdvised: false,
    patientSourceId: null,
    referralConsultantId: null,
    bedCategoryId: null,
    medicalManagement: false,
    surgicalManagement: false,
    services: [0],
    remark: "",
    doctorId: null,
    menuId: "",
    privilege: "",
  };

  //state variable to close the confirmation modal for POST request
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //state variable to close the confirmation modal for PUT request
  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  let isMlc = watch("isMlc");
  let admissionAdvice = watch("admissionAdvice");
  let medicalManagement = watch("medicalManagement");
  let surgicalManagement = watch("surgicalManagement");

  const StateIsMlc = () => {
    setOpenModal(isMlc);
  };

  useEffect(() => {
    if (isMlc !== undefined) {
      StateIsMlc();
    }
  }, [isMlc]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  //get Values of input field on mlc checkbox
  if (isMlc === true) {
    var firstName = getValues("firstName");
    var lastName = getValues("lastName");
    var gender = getValues("gender");
    var age = getValues("age");
    var genderLabel = gender?.label;
    var mrNo = 0;

    {
      // var patientName = prefixLabel + " " + firstName + " " + lastName;
      var patientName = firstName + " " + lastName;
    }
  }

  // onSubmit
  const onSubmit = (data) => {
    setOpenPost(true);
    setFinalData(data);
  };

  // onSubmitDataHandler
  const onSubmitDataHandler = (data) => {
    setOpenPost(false);
    // props.setOpen(false);

    let token = JSON.parse(localStorage.getItem("userInfo"));
    let user = token.userId;
    let unit = token.unitId;

    finalObject.menuId =
      props.location?.state?.menuId && Number(props.location.state.menuId);

    finalObject.privilege = props.privilege;

    finalObject.opdIpd = 1;

    finalObject.mrNo = 0;

    finalObject.patientId = Number(0);

    finalObject.addedBy = Number(user);

    finalObject.isEmergency = false;

    finalObject.isMlc = isMlc;

    finalObject.admissionAdvised = admissionAdvice;

    finalObject.medicalManagement = medicalManagement;

    finalObject.surgicalManagement = surgicalManagement;

    finalObject.unitId = Number(unit);

    finalObject.prefixId = Number(data.prefix.id);

    data.firstName !== null
      ? (finalObject.firstName =
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1))
      : (finalObject.firstName = null);

    data.middleName !== null
      ? (finalObject.middleName =
          data.middleName.charAt(0).toUpperCase() + data.middleName.slice(1))
      : (finalObject.middleName = null);

    data.lastName !== null
      ? (finalObject.lastName =
          data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1))
      : (finalObject.lastName = null);

    data.gender !== null && data.gender !== undefined
      ? (finalObject.genderId = Number(data.gender.id))
      : (finalObject.genderId = Number(0));

    data.remarks !== null
      ? (finalObject.remark = data.remarks)
      : (finalObject.remark = null);

    finalObject.services = [0];
    finalObject.mlcRequestDto = mlcModalData;

    data.ageInDays !== 0 && data.ageInDays !== undefined
      ? (finalObject.ageDay = Number(data.ageInDays))
      : (finalObject.ageDay = Number(0));

    data.ageInMonths !== 0 && data.ageInDays !== undefined
      ? (finalObject.ageMonth = Number(data.ageInMonths))
      : (finalObject.ageMonth = Number(0));

    finalObject.ageYear = Number(data.ageInYears);

    finalObject.dob = format(data.dob, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");

    data.doctor !== null
      ? (finalObject.doctorId = Number(data.doctor.id))
      : (finalObject.doctorId = Number(0));

    data.patientSource !== 0
      ? (finalObject.patientSourceId = Number(data.patientSource.id))
      : (finalObject.patientSourceId = Number(0));

    finalObject.referralConsultantId =
      data?.referalConsultant !== null
        ? Number(data.referalConsultant.id) || Number(0)
        : 0;

    finalObject.referralConsultantName =
      data?.referalConsultant !== null
        ? data?.referalConsultant?.label || ""
        : "";

    data.bedCategory !== null
      ? (finalObject.bedCategoryId = Number(data.bedCategory.id))
      : (finalObject.bedCategoryId = null);

    // finalObject.admissionType = data?.admissionType?.label; //old key not at use in backend
    finalObject.admissionTypeId = data?.admissionType?.id;

    //
    props.setOpenBackdrop(true);

    postMyEmergencyData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);

          methods.reset();
          props.populateTable();
          // navigate("/emergency/registration", {
          //   state: { menuId: location?.state?.menuId },
          // });
          props.setOpenBackdrop(false);
          handleOpenPrintModal(res.result);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenPost(false);
        props.setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());
      });
  };

  //API for Prefix Dropdown
  useEffect(() => {
    //API For Gender dropdown
    fetchGenders()
      .then((response) => {
        setGenders(response.data.result);
      })
      .catch(() => {});

    // Patient Source
    fetchPatientsource()
      .then((response) => {
        setpatientSource(response.data.result);
      })
      .catch(() => {});

    // BedCategory
    fetEmergencyBedCategory()
      .then((response) => {
        setClasstypeOptions(response.data.result);
      })
      .catch(() => {});

    // // unit
    // fetchUnit(unit)
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setUnit(res.result);
    //   });

    // Prefix
    fetchPrefix()
      .then((response) => {
        setPrefixes(response.data.result);
      })
      .catch(() => {});

    //adm type

    fetchAdmissionType()
      .then((response) => response.data)
      .then((res) => {
        setAdmissionType(res.result);
      });
  }, []);

  //  DOB ==1st case [[API Calculate Age Based on DOB]]
  const getAgeDOB = (e) => {
    let dobValue = e;
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    fetchBirthDetails(0, fullDOB)
      .then((response) => {
        setValue("age", response.data.result.years, { shouldValidate: true });
        setValue("ageInYears", response.data.result.years);
        setValue("ageInMonths", response.data.result.months);
        setValue("ageInDays", response.data.result.days);
        // setValue("dob", dobGivenYear, dobGivenMonth, day);
      })
      .catch(() => {});
  };

  //DOB == 2nd case [[API Calculate DOB based on Age]]
  useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA")
        .then((response) => {
          setValue("dob", response.data.result.dob, {
            shouldValidate: true,
          });
          setValue("ageInYears", enteredAge);
          setValue("ageInMonths", response.data.result.months);
          setValue("ageInDays", response.data.result.days);
        })
        .catch(() => {});
    }
  }, [enteredAge]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-2">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-3 my-2">
          {/* prefix */}
          <div className="flex gap-2">
            <div className="w-1/3 lg:w-6/12">
              <DropdownField
                control={control}
                error={errors.prefix}
                name="prefix"
                dataArray={prefixes}
                placeholder="Prefix *"
                isSearchable={false}
                isClearable={false}
                menuPlacement={"bottom"}

                inputRef={{
                  ...register("prefix", {
                    onChange: (e) => {
                      setValue(
                        "gender",
                        {
                          id: e.target.value.SexId,
                          label: e.target.value.gender,
                          value: e.target.value.gender,
                        },
                        { shouldValidate: true }
                      );
                    },
                  }),
                }}
              />
            </div>
            <InputField
              name="firstName"
              variant="outlined"
              label="First Name*"
              error={errors.firstName}
              control={control}
              inputProps={{
                style: { textTransform: "capitalize" },
              }} // use inputProps props for return 1st letter in upper case
            />
          </div>
          {/* middleName */}
          <div>
            <InputField
              name="middleName"
              variant="outlined"
              label="Middle Name"
              error={errors.middleName}
              control={control}
              inputProps={{
                style: { textTransform: "capitalize" },
              }} // use inputProps props for return 1st letter in upper case
            />
          </div>
          {/* lastName */}
          <div>
            <InputField
              name="lastName"
              variant="outlined"
              label="Last Name*"
              error={errors.lastName}
              control={control}
              inputProps={{
                style: { textTransform: "capitalize" },
              }} // use inputProps props for return 1st letter in upper case
            />
          </div>
          {/* gender */}
          <div>
            <DropdownField
              control={control}
              error={errors.gender}
              name="gender"
              label="gender *"
              dataArray={genders}
              isSearchable={false}
              isClearable={false}
              placeholder="Gender *"
              menuPlacement={"bottom"}

            />
          </div>

          <div className="flex gap-2">
            {/* //Date of Birth // */}
            <div className="col-span-2">
              <DatePickerFieldNew
                control={control}
                error={errors.dob}
                name="dob"
                label="Date of Birth"
                value={new Date()}
                inputRef={{
                  ...register("dob", {
                    onChange: (e) => {
                      isAfter(e?.target?.value, new Date(1900, 1, 1)) &&
                        getAgeDOB(e?.target?.value);
                    },
                  }),
                }}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
              {/* 
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      open={openBirthDate}
                      onOpen={() => setOpenBirthDate(true)}
                      onClose={() => setOpenBirthDate(false)}
                      inputProps={{ readOnly: true }}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          type="date"
                          variant="outlined"
                          label="Date of Birth"
                          name="dob"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          size="small"
                          // disabled={true}
                          readOnly={true}
                          onClick={(e) => setOpenBirthDate(true)}
                        />
                      )}
                      PopperProps={{ placement: "auto-end" }}
                      inputFormat="dd/MM/yyyy"
                      disableFuture
                      {...field}
                      onAccept={(e) => {
                        getAgeDOB(e);
                      }}
                      error={Boolean(errors.dob)}
                      helperText={errors.dob?.message}
                    />
                  </LocalizationProvider>
                )}
                name="dob"
              />*/}
            </div>
            {/* //Age// */}
            <div>
              <InputLimit
                maxDigits={3}
                name="age"
                variant="outlined"
                label="Age *"
                error={errors.age}
                control={control}
                //disabled={isDisabled}
                inputRef={{
                  ...register("age", {
                    onChange: (e) => {
                      setEnteredAge(e.target.value);
                    },
                  }),
                }}
                onKeyDown={(e) => {
                  if (
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.code === "ArrowUp" ||
                    e?.code === "ArrowDown" ||
                    e?.code === "NumpadDecimal"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          {/* //Years Months Days // */}
          <div className="flex justify-between gap-2 lg:ml-2">
            <InputField
              name="ageInYears"
              variant="outlined"
              label="Years"
              control={control}
              disabled={true}
            />
            {/* //Months// */}
            <InputField
              name="ageInMonths"
              variant="outlined"
              label="Months"
              control={control}
              disabled={true}
            />
            {/* //Days// */}
            <InputField
              name="ageInDays"
              variant="outlined"
              label="Days"
              control={control}
              disabled={true}
            />
          </div>

          {/* bedCategory */}

          <div className="hidden lg:block">
            <DropdownField
              control={control}
              error={errors.bedCategory}
              name="bedCategory"
              label="Bed Category*"
              dataArray={classTypeOptions}
              isSearchable={false}
              placeholder="Bed Category*"
              isClearable={false}
              maxMenuHeight={150}
              menuPlacement={"bottom"}

            />
          </div>

          {/* Patient Sourse Dropdown */}
          <DropdownField
            control={control}
            //error={errors.patientSource}
            name="patientSource"
            label="Patient Source"
            dataArray={patientSource}
            isSearchable={false}
            placeholder="Patient Source"
            isClearable={false}
            maxMenuHeight={150}
            menuPlacement={"bottom"}

          />

          {/* bedCategory */}
          <div className="lg:hidden">
            <DropdownField
              control={control}
              error={errors.bedCategory}
              name="bedCategory"
              label="Bed Category*"
              dataArray={classTypeOptions}
              isSearchable={false}
              placeholder="Bed category*"
              isClearable={false}
              maxMenuHeight={150}
              menuPlacement={"bottom"}

            />
          </div>
          <div>
            {/* Doctor  */}
            <SearchDropdown
              control={control}
              searchIcon={true}
              isDisabled={false}
              isSerchable={true}
              isClearable={true}
              error={errors.doctor}
              maxMenuHeight={150}
              name="doctor"
              label="Doctor *"
              placeholder="Doctor *"
              isMulti={false}
              dataArray={doctor}
              menuPlacement={"bottom"}

              handleInputChange={(e) => {
                // Doctor
                e &&
                  fetchDoctor(e)
                    .then((response) => {
                      setDoctor(response.data.result);
                    })
                    .catch(() => {});
              }}
            />
          </div>

          {/* referalConsultant */}
          {/*<div>
            <SearchDropdown
              control={control}
              searchIcon={true}
              isDisabled={false}
              isSerchable={true}
              isClearable={true}
              maxMenuHeight={150}
              name="referalConsultant"
              label="Referral Consultant"
              dataArray={referalConsultant}
              placeholder="Referral Consultant"
              isMulti={false}
              handleInputChange={(e) => {
                e &&
                  fetchReferanceDoctor(e)
                    .then((response) => {
                      setReferalConsultant(response.data.result);
                    })
              }}
            />
            </div>*/}

          <div>
            <CreateableSelect
              name="referalConsultant"
              placeholder="Referral Consultant"
              dataArray={referalConsultant}
              onInputChange={(e) => {
                e &&
                  fetchReferanceDoctor(e).then((response) => {
                    setReferalConsultant(response.data.result);
                  });
              }}
              searchIcon={true}
              control={control}
              isClearable={true}
              menuPlacement={"bottom"}

            />
          </div>

          {/* //Admission Type*/}
          <div className="md:mb-3 lg:mb-0">
            <DropdownField
              control={control}
              error={errors.admissionType}
              name="admissionType"
              label="admissionType"
              dataArray={admissionType}
              isSearchable={false}
              isClearable={false}
              placeholder="Admission Type *"
              menuPlacement={"bottom"}
              maxMenuHeight={200}

            />
          </div>
        </div>

        {/* isMlc */}
        <div className="grid lg:grid-cols-2   xl:gap-2">
          <div className="flex lg:mr-3 lg:mt-6 lg:border-r-2 border-slate-500">
            <CheckBoxField
              control={control}
              name="isMlc"
              label="Medico Legal Case"
            />
            <CheckBoxField
              control={control}
              name="admissionAdvice"
              label="Admission Advised"
            />
          </div>

          <div className="">
            <label className="text-sm font-semibold">
              Type Of Care Required
            </label>
            {/* medicalManagement & surgicalManagement */}
            <div className="flex">
              <div className="whitespace-nowrap">
                <CheckBoxField
                  control={control}
                  name="medicalManagement"
                  label="Medical Management"
                />
              </div>
              <div className="whitespace-nowrap">
                <CheckBoxField
                  control={control}
                  name="surgicalManagement"
                  label="Surgical Management"
                />
              </div>
            </div>
          </div>
        </div>

        {/* remarks Field */}
        <div className="w-full my-2">
          <InputField
            name="remarks"
            variant="outlined"
            label="Remark"
            error={errors.remarks}
            control={control}
            inputProps={{
              style: { textTransform: "capitalize" },
            }} // use inputProps props for return 1st letter in upper case
            required
          />
        </div>

        {/* Buttons Add Update Cancel Reset */}
        <div className="flex gap-2 justify-end mb-2">
          {Actions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "save" ? (
                <>
                  <CommonButton
                    label="Reset"
                    onClick={() => {
                      reset(methods);
                    }}
                    className="border border-customRed text-customRed"
                  />

                  {/* <SaveButton /> */}
                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                  />
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>

      {/* print */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* mlc Modal */}
      {openModal ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setMlcModalData={setMlcModalData}
          patientName={patientName}
          gender={genderLabel}
          age={age}
          mrNo={mrNo}
          isEdit={false}
          MLCNo={null} //admissionId,emergencyId
          admissionId={0}
          emergencyId={0}
          opdIpd={1}
          isFromEmergency={true}
          isFromForm={true}
          ipdEmergency={2}
        />
      ) : null}

      {/* Confirmation modal for PUT request */}
      <ConfirmationModal
        confirmationOpen={openPut}
        confirmationHandleClose={handleClosePut}
        // confirmationSubmitFunc={updateRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Update"
      />

      {/* Confirmation modal for POST request */}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={() => onSubmitDataHandler(finalData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
