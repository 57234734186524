import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const getGrnWoAmendmentList = (listObj) => {
  return apiClient.post(`/purchase/getGrnWoAmendmentList`, listObj, {
    headers: authHeader(),
  });
};

export const saveGrnWoAmendment = (postObj) => {
  return apiClient.post(`/purchase/saveGrnWoAmendment`, postObj, {
    headers: authHeader(),
  });
};

export const cancelWOGrn = (grnId, menuId, privilege) => {
  return apiClient.get(
    `/purchase/cancelWOGrn/${grnId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const getStores = () => {
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};

export const getSupplierList = () => {
  return apiClient.get(`/purchase/supplierListForPurchase`, {
    headers: authHeader(),
  });
};
