import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const savePharmacyCompanySettlement = (postObject) => {
  return apiClient.post(
    `/pharmacySettlement/saveCompanySettlement`,
    postObject, {
      headers: authHeader(),
    }
  );
};
