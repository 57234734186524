import React from "react";
import PatientFallAssessmentTable from "./table/PatientFallAssessmentTable";
import { useFormContext } from "react-hook-form";

function PatientFallAssessment(props) {
  const { register, watch } = useFormContext(); // retrieve all hook methods

  const enabledResetButtonStyle =
    "h-[36px] cursor-pointer px-3 border border-customRed bg-white text-customRed rounded text-base font-medium overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out";

  const disabledButtonStyle =
    "h-[36px] cursor-pointer px-3 border  bg-gray-200 text-black rounded text-base font-medium overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out";

  const enabledUpdateButtonStyle =
    "h-[36px] cursor-pointer px-3  bg-customGreen text-white rounded text-base font-medium overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out";

  const enabledOnClickSaveButtonStyle =
    "h-[36px] cursor-pointer px-3  bg-customGreen text-white rounded text-base font-medium overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out";

  const disabledDivStyle = "px-4 py-1 bg-[#e5e7eb]";

  const enabledDivStyle = "px-4 py-1 bg-['#ffffff']";

  let highFallRiskArrayValue = watch("highFallRisk");
  let lowFallRiskArrayValue = watch("lowFallRisk");

  return (
    <div className="mx-2">
      <form className="grid grid-cols-1 w-full gap-x-5 ">
        <div
          className={
            props.localFormDisableFlag ? disabledDivStyle : enabledDivStyle
          }
        >
          <p className="text-black font-semibold text-base">
            If patient has any of the following conditions, check the box and
            apply Fall Risk interventions as indicated.
          </p>
          <div className="flex gap-2 pt-2">
            <p className="text-black font-semibold text-base">
              High Fall Risk &nbsp; - &nbsp;
            </p>
            <span className="text-sm text-gray-600 font-semibold">
              Implement High Fall Risk interventions per protocol
            </span>
          </div>

          <fieldset
            className="flex items-center space-x-3 py-2"
            disabled={
              lowFallRiskArrayValue?.length > 0 || props.localFormDisableFlag
                ? true
                : false
            }
          >
            <input
              type="checkbox"
              name="highFallRisk"
              className="h-4 w-4"
              value="history"
              {...register("highFallRisk")}
            />
            <p className="text-gray-600 text-sm font-semibold">
              &nbsp; History of more than one fall within 6 months before
              admission.
            </p>
          </fieldset>

          <fieldset
            className="flex items-center space-x-3 py-2"
            disabled={
              lowFallRiskArrayValue?.length > 0 || props.localFormDisableFlag
                ? true
                : false
            }
          >
            <input
              type="checkbox"
              name="highFallRisk"
              className="h-4 w-4"
              value="patient"
              {...register("highFallRisk")}
            />
            <p className="text-gray-600 text-sm font-semibold">
              &nbsp; Patient has experienced a fall during this hospitalization.
            </p>
          </fieldset>

          <fieldset
            className="flex items-center space-x-3 py-2"
            disabled={
              lowFallRiskArrayValue?.length > 0 || props.localFormDisableFlag
                ? true
                : false
            }
          >
            <input
              type="checkbox"
              name="highFallRisk"
              className="h-4 w-4"
              value="protocol"
              {...register("highFallRisk")}
            />
            <p className="text-gray-600 text-sm font-semibold">
              &nbsp;Patient is deemed high fall-risk per protocol (e.g., seizure
              precautions).
            </p>
          </fieldset>

          <div className="flex gap-2 pt-2">
            <p className="text-black font-semibold text-base">
              Low Fall Risk &nbsp; - &nbsp;
            </p>
            <span className="text-sm text-gray-600 font-semibold">
              Implement Low Fall Risk interventions per protocol
            </span>
          </div>

          <fieldset
            className="flex items-center  space-x-3 py-2"
            disabled={
              highFallRiskArrayValue?.length > 0 || props.localFormDisableFlag
                ? true
                : false
            }
          >
            <input
              type="checkbox"
              className="h-4 w-4"
              name="lowFallRisk"
              value="complete"
              {...register("lowFallRisk")}
            />
            <p className="text-gray-600 text-sm font-semibold">
              Complete paralysis or completely immobilized.
            </p>
          </fieldset>

          <div className="flex justify-between items-center">
            <p className="text-black font-semibold text-base">
              Do not continue with Fall Risk Score Calculation if any of the
              above conditions are checked.
            </p>
          </div>
        </div>

        <div>
          <PatientFallAssessmentTable
            setLocalFormDisableFlag={props.setLocalFormDisableFlag}
            localFormDisableFlag={props.localFormDisableFlag}
            highFallRiskArrayValue={highFallRiskArrayValue}
            lowFallRiskArrayValue={lowFallRiskArrayValue}
            patchedNursingInfo={props.patchedNursingInfo}
          />
        </div>
      </form>
    </div>
  );
}

export default PatientFallAssessment;
