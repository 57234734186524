import { yupResolver } from "@hookform/resolvers/yup";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Icon, Modal, Tooltip } from "@mui/material";
import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AnesthesiaNotesIcon,
  CancelOnIcon,
  IssueIcon,
  NotesIcon,
} from "../../../../assets/icons/CustomIcons";
import { routeAdded } from "../../../../authentication/slices/auth";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import OtItemIssueListPrint from "../../../prints/OtItemIssueListPrint";
import {
  fetchCancelOTStatus,
  fetchOtDetailsListing,
  fetchPrintOtDetails,
  fetchTheater,
} from "../../../services/otDetailsServices/OtDetailsServices";
import OtLayout from "../../otReservation/OtLayout";
import OtLayoutIcon from "../../otReservation/otLayout/otMachineImages/layout.svg";
import OtDetailPrintModal from "./OtDetailPrintModal";
import ViewOtDetailsModal from "./ViewOtDetailsModal";
import SurgeryNotesModal from "./SurgeryNotesModal";
import AnesthesiaNotesModal from "./AnesthesiaNotesModal";

const PatientType = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "IPD",
    label: "IPD",
  },
  {
    id: 0,
    value: "OPD",
    label: "OPD",
  },
];

const Status = [
  {
    id: 2,
    value: "Both",
    label: "Both",
  },
  {
    id: 1,
    value: "Active",
    label: "Active",
  },
  {
    id: 0,
    value: "Cancelled",
    label: "Cancelled",
  },
];

const OtDetailsList = () => {
  let navigate = useNavigate();

  let location = useLocation();

  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState([]);

  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25); //requird
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = React.useState([]);

  //prints
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  ///
  const [theater, setTheater] = useState();
  //
  const [openViewOtDetailsModal, setOpenViewOtDetailsModal] =
    React.useState(false);
  const handleOpenViewOtDetailsModal = () => setOpenViewOtDetailsModal(true);
  const handleCloseViewOtDetailsModal = () => setOpenViewOtDetailsModal(false);

  //
  const [openNotesModal, setOpenNotesModal] = React.useState(false);
  const [openAnestesiaNotesModal, setOpenAnestesiaNotesModal] =
    React.useState(false);
  const [openCancelOtSheetModal, setOpenCancelOtSheetModal] =
    React.useState(false);
  const [otHeaderId, setOtHeaderId] = React.useState(null);
  // otLayoutModal
  const [openOtLayoutModal, setOpenOtLayoutModal] = React.useState(false);
  const handleOpenOtLayoutModal = () => setOpenOtLayoutModal(true);
  const handleCloseOtLayoutModal = () => setOpenOtLayoutModal(false);
  //
  //

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      patientType: 2,
      status: 2,
      // fromDate: new Date(),
      // toDate: new Date(),
    },
  });
  const { control, watch, setValue } = methods;

  useEffect(() => {
    // Theater
    fetchTheater()
      .then((response) => response.data)
      .then((res) => {
        //
        setTheater(res.result);
      });
  }, []);

  ///
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  let PatientTypeRadio = watch("patientType");
  let TheaterName = watch("theaterName");
  let Status_selected = watch("status");

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, PatientTypeRadio, TheaterName, ToDate, Status_selected]);

  const populateTable = (forPagination) => {
    const otPtListObj = {
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      isCancelled: 0,
      mrno: 0,
      opdIpdExternal: PatientTypeRadio,
      opdIpdExternalId: 0,
      theaterId: TheaterName?.id,
      userId: 0,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      menuId: location?.state?.menuId,
    };

    fetchOtDetailsListing(otPtListObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }

        setCount(res.count);
        setUserActions(res.actions);
        handleRouteAddition(res.actions);
      });
  };

  //Code Added By Omkar//
  const dispatch = useDispatch();
  const handleRouteAddition = (actions) => {
    actions.map((item) => {
      if (item.menuKey) {
        dispatch(routeAdded(item.menuKey));
      }
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // print service
  const handleOpenPrintModal = (row) => {
    fetchPrintOtDetails(row.OTHeaderID).then((response) => {
      setOpenPrintModal(true);

      setUrlforPrint(response);
    });
  };

  const renderActions = (row) => {
    return (
      <div className="flex gap-1">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <div className="flex gap-1 items-center cursor-pointer">
                {actions.action === "OT Issue Item" ? (
                  <IssueIcon
                    title={"Issued Items"}
                    onClick={() => {
                      navigate("/operationTheater/Details/IssuedItems", {
                        state: { row, menuId: actions.menuId },
                      });
                    }}
                  />
                ) : null}

                {actions.action === "Edit" ? (
                  <Tooltip title="Edit OT Details">
                    <DriveFileRenameOutlineIcon
                      sx={{ color: "#1e3a8a" }}
                      onClick={(e) => {
                        {
                          navigate("/operationTheater/Details/create", {
                            state: {
                              row,
                              isEdit: true,
                              menuId: actions.menuId,
                              privilege: actions.action,
                              previousMenuId: location?.state?.menuId,
                            },
                          });
                        }
                      }}
                    />
                  </Tooltip>
                ) : null}

                {actions.action === "View" ? (
                  <Tooltip title="View OT Details">
                    <VisibilityIcon
                      sx={{ color: "#1e3a8a" }}
                      onClick={(e) => {
                        handleOpenViewOtDetailsModal();
                        setSelectedRowData(row);
                      }}
                    />
                  </Tooltip>
                ) : null}

                {actions.action === "Print" ? (
                  <>
                    <Tooltip title="Print OT Details">
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          setOtDetailsId(row);
                          // handleOpenPrintModal(row);
                          handleClickOpen();
                        }}
                      />
                    </Tooltip>
                  </>
                ) : null}

                {actions.action === "OT Layout" && (
                  <Tooltip title={"OT Layout"}>
                    <Icon sx={{ marginTop: "5px" }}>
                      <img
                        src={OtLayoutIcon}
                        onClick={() => {
                          handleOpenOtLayoutModal();
                          setSelectedRowData(row);
                          setPrivilege(actions.action);
                        }}
                      />
                    </Icon>
                  </Tooltip>
                )}

                {actions.action === "Sergery Notes" && (
                  <NotesIcon
                    title={"Surgery Notes"}
                    onClick={() => {
                      setOpenNotesModal(true);
                      setSelectedRowData(row);
                      setPrivilege(actions.action);
                    }}
                  />
                )}

                {actions.action === "Anesthesia Notes" && (
                  <AnesthesiaNotesIcon
                    title={"Anesthesia Notes"}
                    onClick={() => {
                      setOpenAnestesiaNotesModal(true);
                      setSelectedRowData(row);
                      setPrivilege(actions.action);
                    }}
                  />
                )}

                {actions.action === "Cancel" ? (
                  <CancelOnIcon
                    title="Cancel OT Sheet"
                    onClick={(e) => {
                      setOpenCancelOtSheetModal(true);
                      setOtHeaderId(row.OTHeaderID);
                      setPrivilege(actions.action);
                    }}
                  />
                ) : null}
              </div>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {}, [dataResult]);

  //OT All Prints
  const [otDetailsId, setOtDetailsId] = useState("");

  const [openprintModal, setOpenprintModal] = useState(false);
  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenprintModal(false);
    }, 5000);

    return (
      <div className="hidden">
        <OtItemIssueListPrint
          otDetailsId={otDetailsId}
          FromDate={FromDate}
          ToDate={ToDate}
        />
      </div>
    );
  };
  return (
    <div className="mt-12">
      {/* //heading// */}
      <div className="text-center text-black font-bold text-xl mb-3">
        OT Details List
      </div>
      <form>
        {/* //filters */}
        <div className="grid md:grid-cols-2 lg:grid-cols-6 gap-2">
          {/* from Date */}
          <div className="">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>

          {/* to Date */}
          <div className="">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          {/* //patient Type & Status */}
          <div className="md:col-span-2 lg:col-span-4 lg:flex md:justify-start  gap-2 items-center">
            <div className="flex md:justify-start  gap-2 items-center">
              <p className="text-gray-700 font-semibold">Patient Type :</p>
              <div className="mt-1">
                <RadioField
                  label=""
                  name="patientType"
                  control={control}
                  dataArray={PatientType}
                />
              </div>
            </div>
            <div className="flex md:justify-start  gap-2 items-center">
              <p className="text-gray-700 font-semibold">Status:</p>
              <div className="mt-1">
                <RadioField
                  label=""
                  name="status"
                  control={control}
                  dataArray={Status}
                />
              </div>
            </div>
          </div>
          {/* //theater & stage & all patients */}
          <div className="md:col-span-2  lg:col-span-6 grid-cols-6 md:grid     md:justify-start   items-center">
            <div className="md:flex col-span-4 lg:flex md:gap-1 lg:gap-2">
              <div className="w-52">
                <DropdownField
                  control={control}
                  name="theaterName"
                  placeholder="Theater"
                  isSearchable={false}
                  dataArray={theater}
                />
              </div>

              <div>
                <CheckBoxField
                  control={control}
                  name="allPatients"
                  label="All Patients"
                />
              </div>
              {userActions.map((obj) => (
                <div className="mt-2">
                  {obj.action === "Print" ? (
                    <Tooltip title="Item Issue Print">
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          setOpenprintModal(true);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              ))}
            </div>

            <div className="col-span-2 flex justify-end items-center">
              {userActions.map((obj) => (
                <div className="flex gap-3">
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <div>
                        <CommonButton
                          className="bg-customGreen text-white"
                          label="New"
                          onClick={() =>
                            navigate("/operationTheater/Details/create", {
                              state: {
                                menuId: obj.menuId,
                                privilege: obj.action,
                                isEdit: false,
                                previousMenuId: location?.state?.menuId,
                              },
                            })
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          </div>

          <div className="lg:col-span-2"></div>
        </div>

        {/*  Table */}
        <div>
          {dataResult?.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              highlightRow={false}
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              renderActions={renderActions}
              tableClass="2xl:h-[650px]"
            />
          ) : (
            <p className="text-center my-6 ">No Data Found</p>
          )}
        </div>
      </form>

      {/*View Patient Info*/}
      {openViewOtDetailsModal ? (
        <ViewOtDetailsModal
          open={openViewOtDetailsModal}
          setOpen={setOpenViewOtDetailsModal}
          handleOpen={handleOpenViewOtDetailsModal}
          handleClose={handleCloseViewOtDetailsModal}
          selectedRow={selectedRowData}
        />
      ) : null}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openOtLayoutModal ? (
        <OtLayout
          open={openOtLayoutModal}
          setOpen={setOpenOtLayoutModal}
          handleOpen={handleOpenOtLayoutModal}
          handleClose={handleCloseOtLayoutModal}
          // userActions={userActions}
          // privilege={privilege}
          selectedRowData={selectedRowData}
          isFromForm={false}
        />
      ) : null}

      {/* /// modal */}
      <Modal open={openCancelOtSheetModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            // minWidth:"25%",
            // height: "30%",
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              OT Details
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To Cancel OT Sheet ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    setOpenCancelOtSheetModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    className=" bg-customGreen text-white  "
                    label="Ok"
                    onClick={() => {
                      fetchCancelOTStatus(
                        otHeaderId,
                        location?.state?.menuId,
                        privilege
                      )
                        .then((response) => response.data)
                        .then((res) => {
                          successAlert(res.message);
                          populateTable();
                        });

                      setOpenCancelOtSheetModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {openNotesModal && (
        <SurgeryNotesModal
          open={openNotesModal}
          handleClose={() => {
            setOpenNotesModal(false);
          }}
          selectedRowData={selectedRowData}
          privilege={privilege}
          menuId={location?.state?.menuId}
        />
      )}

      {openAnestesiaNotesModal && (
        <AnesthesiaNotesModal
          open={openAnestesiaNotesModal}
          handleClose={() => {
            setOpenAnestesiaNotesModal(false);
          }}
          selectedRowData={selectedRowData}
          privilege={privilege}
          menuId={location?.state?.menuId}
        />
      )}

      <OtDetailPrintModal
        open={open}
        handleClose={handleClose}
        otDetailsId={otDetailsId}
        FromDate={FromDate}
        ToDate={ToDate}
      />

      {openprintModal && renderPrint()}
    </div>
  );
};

export default OtDetailsList;
