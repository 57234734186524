//imports from mui library
import { TextField, Tooltip } from "@mui/material";

//import from react library
import * as React from "react";

//imports from react hook form
import { useForm } from "react-hook-form";

//imports from the yup library
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import InputLimit from "../../../common/components/FormFields/InputLimit";

//  Import FormFields
import InputField from "../../../common/components/FormFields/InputField";

import DropdownField from "../../../common/components/FormFields/DropdownField";

import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import AppointmentFormModal from "./AppointmentFormModal";
import ViewAppointmentsModal from "./ViewAppointmentsModal";

import {
  fetchBirthDetails,
  fetchGenders,
  fetchMaritalStatus,
  fetchPrefix,
  fetchSurgeryInstructions,
} from "../../../commonServices/miscellaneousServices/MiscServices";

import {
  getPatientInfo,
  getPatientInfoById,
  getReferenceDoctor,
  getReferralType,
  postAppointmentData,
} from "./services/AppointmentServices";

import {
  errorAlert,
  errorAlertCustom,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";

import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import { useEffect } from "react";

export default function AppointmentPage(props) {
  const {
    navigateData,
    setNavigateData,
    menuId,
    PatientId,
    isFromOutside,
  } = props;
  const location = useLocation();

  const schema = yup.object().shape({
    // prefix: yup
    //   .object()
    //   .nullable()
    //   .shape({
    //     value: yup.string().required(" "),
    //     label: yup.string().required(" "),
    //   })
    //   .required(" "),

    firstName: yup
      .string()
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),

    lastName: yup
      .string()
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),

    // gender: yup
    //   .object()
    //   .nullable()
    //   .shape({
    //     value: yup.string().required(" "),
    //     label: yup.string().required(" "),
    //   })
    //   .required(" "),

    mobileNumber: yup
      .number()
      .test(
        "len",
        "Must be exactly 10 characters ",
        (val) => val.toString().length === 10
      ),

    age: yup.number().max(121).min(0).integer().required(),

    email: yup
      .string()
      .nullable()
      .email()
      .matches(
        /^$|^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
        "Invalid Email Format"
      )
      .notRequired("Required"),
  });

  const defaultValues = {
    patientInfoSearch: null,
    prefix: null,
    firstName: "",
    middleName: "",
    lastName: "",
    age: 0,
    ageInYears: 0,
    ageInMonths: 0,
    ageInDays: 0,
    gender: null,
    mobileNumber: "",
    maritalStatus: null,
    email: "",
    referEntity: null,
    referredByDoctor: "",
    complaints: "",
    department: null,
    doctorName: null,
    isConfirm: false,
    dateOfBirth: new Date(),
    appointmentDate: new Date(),
    surgeryInstructions: null,
  };

  const {
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    // use mode to specify the event that triggers each input field
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [userActions, setUserActions] = React.useState(null);

  const [
    surgeryInstructionsArray,
    setSurgeryInstructionsArray,
  ] = React.useState([]);

  const [enteredAge, setEnteredAge] = React.useState();

  const [patientAdmissionFlag, setPatientAdmissionflag] = React.useState(false);

  const [salutationOptions, setSalutationOptions] = React.useState([]);
  const [genderOptions, setGenderOptions] = React.useState([]);
  const [maritalStatus, setMaritalStatus] = React.useState([]);

  const [patientSuggestions, setPatientSuggestions] = React.useState([]);
  const [patientInfo, setPatientInfo] = React.useState(null);
  const [timeSlot, setTimeSlot] = React.useState("");
  const [selectedDob, setSelectedDob] = React.useState(new Date());
  const [referralTypes, setReferralTypes] = React.useState([]);

  const [slotId, setSlotId] = React.useState(null);

  const [selectedAppointment, setSelectedAppointment] = React.useState(
    new Date()
  );
  const [referredByDoctorList, setReferredByDoctorList] = React.useState([]);

  const [departmentId, setDepartmentId] = React.useState();
  const [departmentSearchString, setDepartmentSearchString] = React.useState();

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [doctorName, setDoctorName] = React.useState("");
  const [cellInformation, setCellInformation] = React.useState(null);
  const [fromTimeSlot, setFromTimeSlot] = React.useState("");
  const [toTimeSlot, setToTimeSlot] = React.useState("");
  const [departmentList, setDepartmentList] = React.useState([]);
  const [doctorList, setDoctorList] = React.useState([]);
  const [
    openAppointmentListModal,
    setOpenAppointmentListModal,
  ] = React.useState(false);

  React.useEffect(() => {
    fetchPrefix()
      .then((response) => {
        setSalutationOptions(response.data.result);
      })
      .catch((error) => {});

    fetchGenders().then((response) => {
      setGenderOptions(response.data.result);
    });

    fetchMaritalStatus().then((response) => {
      setMaritalStatus(response.data.result);
    });

    getReferralType().then((response) => {
      setReferralTypes(response.data.result);
    });

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    setOpen(true);

    (menuId || location?.state?.menuId) &&
      fetchUserActionsByMenuId(menuId || location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          let tempUserActonsArr = res.result;

          let requiredActionsArr = res.result;

          let tempObj = requiredActionsArr[0];

          requiredActionsArr[0] = requiredActionsArr[1];

          requiredActionsArr[1] = tempObj;

          setUserActions(requiredActionsArr);
        });

    fetchSurgeryInstructions().then((response) => {
      setSurgeryInstructionsArray(response.data.result);
    });

    ///

    //patching patient info when we come from RadiologyWorkOrder
    if (navigateData && navigateData?.patientId) {
      getPatientInfoById(navigateData?.patientId).then((response) => {
        setPatientAdmissionflag(response.data.result.IsAdmitted);

        if (response?.data?.result?.IsAdmitted === true) {
          if (setNavigateData === undefined && navigateData === undefined) {
            errorAlertCustom("This patient is already admitted.");
          }
        }

        setPatientInfo(response.data.result);

        patchPatientInfo(response.data.result);
      });
    }
  }, []);

  useEffect(() => {
    !!PatientId && handlePatientInfo(PatientId);
  }, []);

  ///
  const handlePatientInfo = (ptId) => {
    getPatientInfoById(ptId).then((response) => {
      setPatientAdmissionflag(response.data.result.IsAdmitted);

      if (response?.data?.result?.IsAdmitted === true) {
        if (setNavigateData === undefined && navigateData === undefined) {
          errorAlertCustom("This patient has already been admitted.");
        }
      }

      setPatientInfo(response.data.result);

      patchPatientInfo(response.data.result);
    });
  };

  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  //search patient
  const searchPatientInfo = (patientSearchString) => {
    if (patientSearchString !== "") {
      getPatientInfo(patientSearchString).then((response) => {
        setPatientSuggestions(response.data.result);
      });
    }
  };

  function patchPatientInfo(patientInfoObj) {
    if (patientInfoObj?.dob) {
      fetchBirthDetails(
        0,
        format(new Date(patientInfoObj?.dob), "yyyy-MM-dd")
      ).then((response) => {
        setValue("age", Number(response.data.result.years), {
          shouldValidate: true,
        });
        setValue(
          "ageInYears",
          response?.data?.result?.years > 0 ? response?.data?.result?.years : 0
        );
        setValue(
          "ageInMonths",
          response.data.result.months > 0 ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days > 0 ? response.data.result.days : 0
        );
      });
    }

    let prefix = {
      id: patientInfoObj.prefixId,
      label: patientInfoObj.salutation,
      value: patientInfoObj.salutation,
    };

    let firstName = patientInfoObj.firstName;

    let middleName = patientInfoObj.middleName;

    let lastName = patientInfoObj.lastName;

    let age = patientInfoObj.ageInYears;

    let ageInYears = patientInfoObj.ageInYears;

    let ageInMonths = patientInfoObj.ageInMonths;

    let ageInDays = patientInfoObj.ageInDays;

    let gender = {
      id: patientInfoObj.genderId,
      value: patientInfoObj.gender,
      label: patientInfoObj.gender,
    };

    let mobileNumber = patientInfoObj.mobileNo;

    let maritalStatus = {
      id: "",
      value: "",
      label: "",
    };

    let email = patientInfoObj.email;

    let patchedObj = {
      prefix: prefix,

      firstName: firstName,

      middleName: middleName,

      lastName: lastName,

      age: age,

      ageInYears: ageInYears,

      ageInMonths: ageInMonths,

      ageInDays: ageInDays,

      gender: gender,

      mobileNumber: mobileNumber,

      maritalStatus: maritalStatus,

      email: email,
    };

    reset(patchedObj);

    //Below code is to patch the date coming from the backend.
    let zFormatDateString = patientInfoObj.dob;

    let dateObjInGMT = new Date(zFormatDateString);

    setSelectedDob(dateObjInGMT);

    setValue("dateOfBirth", dateObjInGMT);
  }

  function searchReferredByDoctor(e) {
    if (e !== "") {
      getReferenceDoctor(e)
        .then((response) => {
          setReferredByDoctorList(response.data.result);
        })
        .catch((error) => {});
    }
  }

  function onSubmitDataHandler(data) {
    let dobObject = getValues("dateOfBirth");

    //send patientId as 0 if the patient is not searched and its a new entry
    //send patientId if the patient is searched
    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    let userIdVal = userInfoObj.userId;

    userIdVal = userIdVal.toString();

    let appointmentDate = selectedAppointment;

    let year = appointmentDate.getFullYear();
    let month = String(appointmentDate.getMonth() + 1);
    let day = String(appointmentDate.getDate());

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    let fromTimeArr = fromTimeSlot.split(":");

    let hourInTwentyFourHourFormat = fromTimeArr[0];
    let minutesVal = fromTimeArr[1];

    let newFromTimeObject = new Date(
      year,
      month,
      day,
      hourInTwentyFourHourFormat,
      minutesVal
    );

    let newFromTimeStringInZFormat = newFromTimeObject.toISOString();

    let givenFromTime = `1900-01-01T${hourInTwentyFourHourFormat}:${minutesVal}:00.000Z`;

    let toTimeArr = toTimeSlot.split(":");

    hourInTwentyFourHourFormat = toTimeArr[0];
    minutesVal = toTimeArr[1]?.split(" ")[0];

    // console.log("minutesVal",minutesVal);

    let givenToTime = `1900-01-01T${hourInTwentyFourHourFormat}:${minutesVal}:00.000Z`;
    // console.log("minutesVal",givenToTime);

    let doctorIdVal = cellInformation?.doctorId;

    let departmentIdVal = cellInformation?.departmentId;

    let postObj = {
      ageDay: data.ageInDays,
      ageMonth: data.ageInMonths,
      ageYear: data.ageInYears,
      departmentId: departmentIdVal,
      dob: selectedDob,
      doctorId: doctorIdVal,
      date: selectedAppointment,
      email: data.email,
      firstName: data.firstName,
      fromTime: givenFromTime,
      genderId: data?.gender?.id,
      lastName: data?.lastName,
      maritalStatusId: 0,
      menuId: 0,
      middleName: data?.middleName,
      opdIpd: 0,
      patientId: null, //edited later according to the conditions
      procedures: [],
      phoneNo: data?.mobileNumber,
      prefixId: data?.prefix?.id||0,
      referDoctorId: null, //edited later according to the conditions
      referDoctorName: null, //edited later according to the conditions
      referEntity: null, //edited later according to the conditions
      toTime: givenToTime,
      userName: userIdVal, //taken from localStorage
      remark: data?.complaints,
      isConfirm: data?.isConfirm,
      isFromRadiology: false,
    };

    //when user is coming from RadiologyWorkOrder
    if (!navigateData) {
      postObj.menuId = location.state.menuId;
    } else if (navigateData !== null) {
      postObj.menuId = navigateData?.menuId;
    }

    //when user is coming from RadiologyWorkOrder
    if (navigateData && navigateData?.radiologyRow) {
      postObj.orderDtlsId = navigateData?.radiologyRow?.OrderDtlsId;
    }

    //when user is coming from RadiologyWorkOrder
    if (navigateData && navigateData?.radiologyRow) {
      postObj.isFromRadiology = true;
    } else {
      postObj.isFromRadiology = false;
    }

    //send patientId as 0 if the patient is not searched and its a new entry
    if (patientInfo === null) {
      postObj.patientId = 0;
    } else if (patientInfo !== null) {
      postObj.patientId = patientInfo.id;
    }

    //When the referredByDoctor is null then below code is executed
    if (data.referredByDoctor === null || data.referredByDoctor === "") {
      postObj.referDoctorId = 0;
      postObj.referDoctorName = "";
    } else if (data.referredByDoctor !== null || data.referredByDoctor !== "") {
      postObj.referDoctorId = data.referredByDoctor.id;
      postObj.referDoctorName = data.referredByDoctor.label;
    }

    //when the referEntity is null or is an empty string ; then the below code is executed.
    if (data.referEntity === null || data.referEntity === "") {
      postObj.referEntity = 0;
    } else if (data.referEntity !== null || data.referEntity !== "") {
      postObj.referEntity = data.referEntity.id;
    }

    // setOpenBackdrop(true);
    confirmationHandleClose();

    let privilege = "";

    let tempUserActions = structuredClone(userActions);

    for (let tempUserActionObj of tempUserActions) {
      if (tempUserActionObj.action === "Create") {
        privilege = "Create";
        break;
      }
    }

    postObj.privilege = privilege;

    let surgeryInstructionsArr = data.surgeryInstructions;

    let requiredSurgeryInstructionsArr = [];

    if (surgeryInstructionsArr) {
      if (surgeryInstructionsArr.length !== 0) {
        for (let surgeryInstructionObj of surgeryInstructionsArr) {
          requiredSurgeryInstructionsArr.push(surgeryInstructionObj.label);
        }
      }
    }

    postObj.procedures = requiredSurgeryInstructionsArr;

    postAppointmentData(postObj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          reset(defaultValues);
          setTimeSlot(""); //clear the time slot on the ui
          successAlert(response.data.message);
          setOpenBackdrop(false);
          setSelectedDob(new Date());
          setValue("dateOfBirth", new Date());
          setSelectedAppointment(new Date());

          setOpen(false);
          setFromTimeSlot("");
          setToTimeSlot("");

          setDoctorName("");
          setCellInformation(null);
          setOpen(true);
          setDepartmentSearchString();
          setDepartmentId();
          setDoctorList([]);
          setOpenAppointmentListModal(true);
          setSlotId(null);
          if (navigateData !== undefined) {
            setNavigateData(null);
          }
        }
      })
      .catch((errorObj) => {
        confirmationHandleClose();
        errorAlert(errorObj.message);
        reset(defaultValues);
        setOpenBackdrop(false);
        setSelectedDob(new Date());
        setValue("dateOfBirth", new Date());
        setSelectedAppointment(new Date());
        setTimeSlot("");
        setOpen(false);
        setFromTimeSlot("");
        setToTimeSlot("");
        setTimeSlot("");
        setDoctorName("");
        setCellInformation(null);
        setOpen(true);
        setDepartmentSearchString();
        setDepartmentId();
        setDoctorList([]);
        setOpenAppointmentListModal(true);
        setSlotId(null);
      });
  }

  //  DOB ==1st case [[API Calculate Age Based on DOB]]
  const getAgeDOB = (e) => {
    let dobValue = e;
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    fullDOB.length === 10 &&
      fetchBirthDetails(0, fullDOB).then((response) => {
        setValue("age", Number(response.data.result.years), {
          shouldValidate: true,
        });
        setValue(
          "ageInYears",
          response?.data?.result?.years > 0 ? response?.data?.result?.years : 0
        );
        setValue(
          "ageInMonths",
          response.data.result.months > 0 ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days > 0 ? response.data.result.days : 0
        );
      });
  };

  //DOB == 2nd case [[API Calculate DOB based on Age]]
  React.useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA").then((response) => {
        // setDobState(`${response.data.result.dob}T00:00:00.000Z`);
        setValue("dateOfBirth", `${response.data.result.dob}T00:00:00.000Z`, {
          shouldValidate: true,
        });
        setValue("ageInYears", Number(enteredAge));
        setValue(
          "ageInMonths",
          response.data.result.months > 0 ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days > 0 ? response.data.result.days : 0
        );
      });
    }
  }, [enteredAge]);

  return (
    <>
      <div className=" bg-white px-6">
        <div className="row">
          <fieldset className="border border-gray-300 text-left lg:mx-auto  lg:px-4 md:ml-0 md:mr-0  rounded lg:m-2 ">
            <div
              className={`w-full grid items-center rounded ${
                isFromOutside ? "-mt-2" : navigateData ? "mt-2  " : "mt-10"
              }`}
            >
              <p className="text-center text-2xl my-2 text-black  font-Poppins">
                Book Appointment
              </p>
            </div>

            <form onSubmit={onSubmitDataHandler}>
              {/* Patient Details */}
              <div className="rounded-md bg-white shadow-md px-4 py-1 my-2">
                {/* flexBox One */}
                <div className="flex flex-row gap-3 items-center">
                  <div className="text-lg font-semibold my-1 px-1">
                    Patient Info
                  </div>

                  <div className="lg:w-2/5 md:w-full">
                    <SearchDropdown
                      isDisabled={
                        (navigateData !== undefined &&
                          setNavigateData !== undefined) ||
                        isFromOutside
                      }
                      control={control}
                      name="patientInfoSearch"
                      dataArray={patientSuggestions}
                      searchIcon={true}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Search By Patient Name / MR.No./ Mob No."
                      className="w-96"
                      handleInputChange={searchPatientInfo}
                      inputRef={{
                        ...register("patientInfoSearch", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              handlePatientInfo(e.target.value.id);
                              // getPatientInfoById(e.target.value.id).then(
                              //   (response) => {
                              //     setPatientAdmissionflag(
                              //       response.data.result.IsAdmitted
                              //     );

                              //     if (
                              //       response?.data?.result?.IsAdmitted === true
                              //     ) {
                              //       if (
                              //         setNavigateData === undefined &&
                              //         navigateData === undefined
                              //       ) {
                              //         errorAlertCustom(
                              //           "This patient has already been admitted."
                              //         );
                              //       }
                              //     }

                              //     setPatientInfo(response.data.result);

                              //     patchPatientInfo(response.data.result);
                              //   }
                              // );
                            }
                          },
                        }),
                      }}
                    />
                  </div>

                  {cellInformation && cellInformation.doctorName ? (
                    <div className="my-2 px-1">
                      <span className="text-lg font-semibold my-1 px-1">
                        Dr. Name :
                      </span>
                      {cellInformation && cellInformation.doctorName}
                    </div>
                  ) : null}

                  {cellInformation && cellInformation.departmentName ? (
                    <div className="my-2 px-1">
                      <span className="text-lg font-semibold my-1 px-1">
                        Dept :
                      </span>
                      {cellInformation && cellInformation.departmentName}
                    </div>
                  ) : null}
                </div>

                {/* flexBox Two */}
                <div className="flex flex-row py-3 gap-3">
                  <div className="w-2/12">
                    <DropdownField
                      control={control}
                      // error={errors.prefix}
                      name="prefix"
                      label="Prefix"
                      dataArray={salutationOptions}
                      //dataArray={dummyObj}
                      isSearchable={false}
                      placeholder="Prefix"
                      isClearable={false}
                      inputRef={{
                        ...register("prefix", {
                          onChange: (e) => {
                            setValue(
                              "gender",
                              {
                                id: e.target.value.SexId,
                                label: e.target.value.gender,
                                value: e.target.value.gender,
                              },
                              { shouldValidate: true }
                            );
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="w-1/3">
                    <InputField
                      name="firstName"
                      variant="outlined"
                      label="First Name*"
                      error={errors.firstName}
                      control={control}
                    />
                  </div>
                  <div className="w-1/3">
                    <InputField
                      name="middleName"
                      variant="outlined"
                      label="Middle Name"
                      error={errors.middleName}
                      control={control}
                    />
                  </div>
                  <div className="w-1/3">
                    <InputField
                      name="lastName"
                      variant="outlined"
                      label="Last Name*"
                      error={errors.lastName}
                      control={control}
                    />
                  </div>
                </div>

                {/* flexBox Three */}
                <div className="flex flex-row gap-3">
                  <div>
                    <DatePickerFieldNew
                      error={true}
                      control={control}
                      name="dateOfBirth"
                      label="Date Of Birth"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("dateOfBirth", newValue);
                      }}
                      disablePast={false}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                      inputRef={{
                        ...register("dateOfBirth", {
                          onChange: (e) => {
                            getAgeDOB(e?.target?.value);
                            setSelectedDob(e.target.value);
                            setValue("dateOfBirth", e.target.value);
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="w-1/12">
                    <InputField
                      name="age"
                      label="Age"
                      error={errors.age}
                      control={control}
                      defaultValue={""}
                      type="number"
                      inputRef={{
                        ...register(`age`, {
                          onChange: (e) => {
                            //event listener function to update the date of birth calendar component.

                            setEnteredAge(e.target.value);
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="flex gap-2 w-1/3">
                    <div className="">
                      <InputField
                        disabled={true}
                        name="ageInYears"
                        label="Years"
                        error={errors.ageInYears}
                        control={control}
                        defaultValue={""}
                      />
                    </div>

                    <div className="">
                      <InputField
                        disabled={true}
                        name="ageInMonths"
                        label="Months"
                        error={errors.ageInMonths}
                        control={control}
                        defaultValue={""}
                      />
                    </div>

                    <div className="">
                      <InputField
                        disabled={true}
                        name="ageInDays"
                        label="Days"
                        error={errors.ageInDays}
                        control={control}
                        defaultValue={""}
                      />
                    </div>
                  </div>

                  <div className="w-1/3">
                    <DropdownField
                      control={control}
                      name="gender"
                      label="Title"
                      placeholder="Gender"
                      isSearchable={false}
                      isClearable={false}
                      // error={errors.gender}
                      dataArray={genderOptions}
                    />
                  </div>
                </div>

                {/* flexbox four */}
                <div className="flex flex-row py-3 gap-3">
                  <div className="w-[33rem]">
                    <InputLimit
                      maxDigits={10}
                      name="mobileNumber"
                      label="Mobile Number*"
                      error={errors.mobileNumber}
                      control={control}
                      type="number"
                      onKeyDown={(e) => {
                        if (e.code === "ArrowUp" || e.code === "ArrowDown") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="w-1/3">
                    <DropdownField
                      control={control}
                      error={errors.maritalStatus}
                      name="maritalStatus"
                      label="Marital Status"
                      dataArray={maritalStatus}
                      placeholder="Marital Status"
                      isSearchable={false}
                      isClearable={false}
                    />
                  </div>

                  <div className="w-1/3">
                    <InputField
                      name="email"
                      label="Email"
                      error={errors.email}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              {/* Referral Info */}
              <div className="rounded-md  bg-white shadow-md px-4 py-1 my-2 ">
                <div className=" my-1 px-1 rounded-md text-lg font-semibold mb-4">
                  Referral Info
                </div>

                {/* flexBox One */}
                <div className="flex flex-row gap-3">
                  <div className="w-1/3">
                    <DropdownField
                      control={control}
                      error={errors.referEntity}
                      name="referEntity"
                      label="Referral Entity"
                      dataArray={referralTypes}
                      placeholder="Referral Entity"
                      isSearchable={false}
                      isClearable={false}
                    />
                  </div>

                  <div className="w-1/3">
                    <SearchDropdown
                      control={control}
                      name="referredByDoctor"
                      error={errors.referredByDoctor}
                      dataArray={referredByDoctorList}
                      searchIcon={true}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Referred By Doctor"
                      handleInputChange={searchReferredByDoctor}
                    />
                  </div>
                </div>
              </div>

              <div className="rounded-md  bg-white shadow-md px-4 py-1 my-2">
                <div className=" my-1 px-1 rounded-md text-lg font-semibold mb-4">
                  Appointment Info
                </div>

                {/* flexBox One */}
                <div className="flex flex-row gap-3">
                  <div className="w-1/3">
                    <DropdownField
                      control={control}
                      // error={errors.surgeryInstructions}
                      name="surgeryInstructions"
                      label="Surgery Instructions"
                      dataArray={surgeryInstructionsArray}
                      //dataArray={dummyObj}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      placeholder="Surgery Instructions"
                      inputRef={{
                        ...register("surgeryInstructions", {
                          onChange: (e) => {
                            let surgeryInstructionsArr = e.target.value;

                            let remarksAndComplaintsString = "";

                            if (surgeryInstructionsArr.length > 0) {
                              for (let surgeryInstructionsObj of surgeryInstructionsArr) {
                                if (remarksAndComplaintsString !== "") {
                                  remarksAndComplaintsString = `${remarksAndComplaintsString} , ${surgeryInstructionsObj.value}`;
                                } else if (remarksAndComplaintsString === "") {
                                  remarksAndComplaintsString = `${remarksAndComplaintsString}${surgeryInstructionsObj.value}`;
                                }
                              }

                              setValue(
                                "complaints",
                                remarksAndComplaintsString
                              );
                            } else if (surgeryInstructionsArr.length === 0) {
                              setValue("complaints", "");
                            }
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="w-1/3">
                    <TextField
                      id="outlined-multiline-static"
                      name="complaints"
                      {...register("complaints")}
                      label="Complaints And Remarks"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      rows={1}
                      fullWidth
                    />
                  </div>
                  {/* Please select consultation slot when timeSlot is undefined */}
                  {timeSlot ? (
                    <div className="my-2">{timeSlot}</div>
                  ) : (
                    <div className="my-2 text-red-600">
                      *Please select consultation slot.
                    </div>
                  )}

                  <div className="my-1.5">
                    <Tooltip title="Edit Time Slot">
                      <DriveFileRenameOutlineIcon
                        onClick={() => {
                          setOpen(true);
                        }}
                        className="text-customBlue  mr-1 cursor-pointer"
                      />
                      {/* </a> */}
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="px-4 py-1 my-2">
                {/* flexBox One */}
                <div className="flex flex-row gap-3 justify-end">
                  <div>
                    <CheckBoxField
                      control={control}
                      name="isConfirm"
                      label="Confirm"
                    />
                  </div>

                  {userActions &&
                    userActions?.map((singleActionObj) => {
                      if (
                        singleActionObj?.isAction === false &&
                        singleActionObj?.action === "View Appointments"
                      ) {
                        return (
                          <>
                            <div>
                              <CommonButton
                                label="View Appointments"
                                className="bg-customBlue text-white"
                                onClick={() => {
                                  setOpenAppointmentListModal(true);
                                }}
                              />
                            </div>
                          </>
                        );
                      } else if (
                        singleActionObj?.isAction === false &&
                        singleActionObj?.action === "Create"
                      ) {
                        return (
                          <>
                            <div>
                              <CommonButton
                                label="Reset"
                                className="border border-customRed text-customRed"
                                onClick={() => {
                                  reset(defaultValues);
                                  setTimeSlot("");
                                  setSelectedDob(new Date());
                                  setValue("dateOfBirth", new Date());
                                  setCellInformation(null);
                                  setDoctorList([]);
                                  setSlotId(null);

                                  if (patientAdmissionFlag === true) {
                                    setPatientAdmissionflag(false);
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <CommonButton
                                label="Save"
                                disabled={patientAdmissionFlag}
                                className="bg-customGreen text-white"
                                onClick={() => {
                                  if (isValid === true && timeSlot) {
                                    setConfirmationOpen(true);
                                  } else {
                                    //trigger the usual validations
                                    trigger();
                                  }

                                  if (!timeSlot) {
                                    errorAlertCustom(
                                      "Please select consultation slot."
                                    );
                                  }
                                }}
                              />
                            </div>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>

      <AppointmentFormModal
        open={open}
        setOpen={setOpen}
        setTimeSlot={setTimeSlot}
        timeSlot={timeSlot}
        setDoctorName={setDoctorName}
        setCellInformation={setCellInformation}
        setFromTimeSlot={setFromTimeSlot}
        setToTimeSlot={setToTimeSlot}
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        departmentSearchString={departmentSearchString}
        setDepartmentSearchString={setDepartmentSearchString}
        setDepartmentList={setDepartmentList}
        departmentList={departmentList}
        setDoctorList={setDoctorList}
        doctorList={doctorList}
        setSelectedAppointment={setSelectedAppointment}
        selectedAppointment={selectedAppointment}
        setSlotId={setSlotId}
        slotId={slotId}
      />

      {openAppointmentListModal ? (
        <ViewAppointmentsModal
          open={openAppointmentListModal}
          setOpen={setOpenAppointmentListModal}
          menuId={location?.state?.menuId}
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={confirmationHandleClose}
        confirmationSubmitFunc={() => {
          handleSubmit(onSubmitDataHandler)();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Appointment Details?"
        confirmationButtonMsg="Save Appointment"
      />

      {/* backdrop */}
      {openBackdrop ? <CommonBackDrop openBackdrop={openBackdrop} /> : null}
    </>
  );
}
