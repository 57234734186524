import axios from "axios";
import apiClient from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";

export const getListOfOPDCreditBills = (creditBillObj) => {
  return apiClient.post(`/billSettlement/listOfCreditBill`, creditBillObj, {
    headers: authHeader(),
  });
};

// ​api​/billSettlement​/listOfPayments
export const getListOfOPDSelfPaymentSettlement = (listOfPyamentObj) => {
  return apiClient.post(`/billSettlement/listOfPayments`, listOfPyamentObj, {
    headers: authHeader(),
  });
};

// /api/companySettlement/listOfPendingBill
export const getListOfOPDPedingBills = (listOfPedingBillObj) => {
  return apiClient.post(
    `/companySettlement/listOfPendingBill`,
    listOfPedingBillObj,
    {
      headers: authHeader(),
    }
  );
};

// /api/billSettlement/saveBillSettlementSelf
export const saveSelfBillSettlement = (postObj) => {
  return apiClient.post(`/billSettlement/saveBillSettlementSelf`, postObj, {
    headers: authHeader(),
  });
};

export const opdSelfSettlementPdfPrint = (paymentId) => {
  return apiClient.get(`/reports/bills/selfSettlementReceipt/${paymentId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};
