import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";
import { CurrentStockNonList } from "../../services/inventoryStockServices/InventoryStockService";

export default function CurrentStockNonCosignment() {
  const printRef = useRef(null);

  const [listData, setlistData] = React.useState();

  React.useEffect(() => {
    CurrentStockNonList()
      .then((response) => response.data)
      .then((res) => {
        setlistData(res.result);
      });
  }, []);


  useEffect(() => {
    listData && printRef && GeneratePrint(printRef);
  }, [listData]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <div className=" flex justify-end">{/* <Inamdar /> */}</div>
            <div>
              <h1 className=" font-semibold text-[15px] ">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[11px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" font-semibold text-[13px] mt-2">
                Current Stock Non Consignment (All)
              </h1>
            </div>
          </div>
          <div className=" space-x-2 font-semibold flex justify-end text-[11px]">
            <h1>Print Date & Time </h1>
            <h1>:</h1>
            <div>16-09-2002</div>
          </div>
          <div className=" font-semibold grid grid-cols-[5%_18%_8%_8%_8%_8%_10%_10%_9%_8%_8%] text-[11px] mt-3 border-b-[1px] border-t-[1px] border-black p-1">
            <div>SrNo</div>
            <div>ItemName</div>
            <div>ItemClass</div>
            <div>Bal</div>
            <div>UOM</div>
            <div>Conv.</div>
            <div>UnitMRP</div>
            <div>MRP</div>
            <div>LandedRate</div>
            <div>Purchase Rate</div>
            <div>Total</div>
          </div>
        </thead>
        <tbody className="w-full">
          <div>
            {listData?.map((data) => {
              return (
                <div className=" p-1 grid grid-cols-[5%_18%_8%_8%_8%_8%_10%_10%_9%_8%_8%] text-[11px]">
                  <div>{data?.srno}</div>
                  <div>{data?.["Item Name"]}</div>
                  <div>{data?.["Item Class"]}</div>
                  <div>{data?.Balance}</div>
                  <div>{data?.UMO}</div>
                  <div>{data?.["Conv%"]}</div>
                  <div>{data?.UnitMRP}</div>
                  <div>{data?.MRP}</div>
                  <div>{data?.["Landed Rate"]}</div>
                  <div>{data?.["Purchase Rate"]}</div>
                  <div>{data?.Total}</div>
                </div>
              );
            })}
            {/* <div className=" text-end p-1 text-[11px] border-t-[1px] border-b-[1px] border-black">
       Total : 61,186.00
     </div> */}
          </div>
        </tbody>
      </table>
    </div>
  );
}
