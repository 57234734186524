import React, { useEffect } from "react";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CreateScheduleModal from "./CreateScheduleModal";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchScheduledDoctorsSlotsList } from "../../services/doctor/DoctorScheduleServices";
import CommonDynamicTable from "../../../common/components/CommonTable/CommonDynamicTable";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Tooltip } from "@mui/material";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { useForm } from "react-hook-form";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchDepartment,
  fetchDoctor,
  fetchDoctorListByDepartmentId,
  fetchUnit,
} from "../../../commonServices/miscellaneousServices/MiscServices";

const Actions = [
  {
    id: 0,
    action: "Create",
    isAction: false,
  },
  {
    id: 1,
    action: "Edit",
    isAction: true,
  },
];

const DoctorSchedule = () => {
  const location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const { control, watch, setValue, register, resetField } = useForm({
    mode: "onChange",
    // defaultValues: defaultValues,
  });

  const [scheduledSlotsList, setScheduledSlotsList] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [existingSlotEditRow, setExistingSlotEditRow] = useState(null);
  //
  const [units, setUnits] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  //

  const [openCreateScheduleModal, setOpenCreateScheduleModal] = useState(false);
  const handleOpenCreateScheduleModal = () => {
    setOpenCreateScheduleModal(true);
  };
  const handleCloseCreateScheduleModal = () => {
    setOpenCreateScheduleModal(false);
  };

  ///watch
  const unitWatch = watch("unit");
  const departmentWatch = watch("department");
  const doctorWatch = watch("doctor");

  useEffect(() => {
    getUnit();
    token &&
      setValue("unit", {
        id: token.unitId,
        label: token.unitName,
        value: token.unitName,
      });
  }, []);

  useEffect(() => {
    getScheduledDoctorsSlotsList();
  }, [unitWatch, departmentWatch, doctorWatch]);

  const getScheduledDoctorsSlotsList = () => {
    // departmentId,doctorId,unitId
    fetchScheduledDoctorsSlotsList(
      departmentWatch ? departmentWatch?.id : 0,
      doctorWatch ? doctorWatch?.id : 0,
      unitWatch ? unitWatch?.id : 0
    )
      .then((response) => response.data)
      .then((res) => {
        setScheduledSlotsList(res.result);
      });
  };

  const getUnit = () => {
    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        //
        setUnits(res.result);
      })
      .catch((response) => {
        //
      });
  };

  const getDepartment = (e) => {
    e &&
      fetchDepartment(e)
        .then((response) => response.data)
        .then((res) => {
          //
          setDepartments(res.result);
        });
  };

  // const getDoctor = (e) => {
  //   e &&
  //     fetchDoctor(e)
  //       .then((response) => response.data)
  //       .then((res) => {
  //         setDoctors(res.result);
  //       });
  // };

  // Api to Get Doctor by department id
  const getDoctor = (departmentId) => {
    departmentId &&
      fetchDoctorListByDepartmentId(departmentId, token.unitId).then(
        (response) => {
          setDoctors(response.data.result);
        }
      );
  };

  // actions and print
  ////////table related
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction && (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "Edit" && (
                    <Tooltip title={"Edit Schedule"}>
                      <DriveFileRenameOutlineIcon
                        sx={{ color: "#1e3a8a" }}
                        onClick={() => {
                          setOpenCreateScheduleModal(true);
                          setPrivilege(actions.action);
                          setExistingSlotEditRow(row);
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </>
            )}
          </>
        ))}
      </div>
    );
  };

  return (
    <div className="mt-12">
      <div className="flex justify-center font-semibold text-lg">
        Doctor Schedule
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-5">
        <div className=" md:col-span-2 lg:col-span-4 grid lg:grid-cols-4 md:grid-cols-4 gap-3">
          <div className="lg:col-span-1 md:col-span-2">
            <DropdownField
              control={control}
              name="unit"
              label="Unit"
              dataArray={units}
              isSearchable={false}
              isDisabled={false}
              isClearable={false}
              placeholder="Unit"
            />
          </div>

          <div className="lg:col-span-1 md:col-span-2">
            <SearchDropdown
              control={control}
              name="department"
              label="Department"
              dataArray={departments}
              handleInputChange={(e) => {
                getDepartment(e);
              }}
              isSearchable={true}
              searchIcon={true}
              placeholder="Department *"
              isClearable={true}
              inputRef={{
                ...register("department", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      getDoctor(e.target.value.id);
                    }
                    resetField("doctor");
                  },
                }),
              }}
            />
          </div>

          <div className="lg:col-span-1 md:col-span-2">
            <DropdownField
              control={control}
              //error={errors.doctor}
              name="doctor"
              label="Doctor *"
              dataArray={doctors}
              inputRef={{
                ...register("doctor", {
                  onChange: (e) => {
                    setValue("doctor1", null);
                    setValue("doctor2", null);
                  },
                }),
              }}
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              placeholder="Doctor *"
            />
          </div>

          <div className="lg:col-span-1 md:col-span-2 md:visible lg:hidden flex justify-end">
            {Actions &&
              Actions?.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        label="Create Schedule"
                        className="bg-customGreen text-white"
                        onClick={() => {
                          setOpenCreateScheduleModal(true);
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
          </div>
        </div>

        <div className="justify-end  hidden lg:block">
          {Actions &&
            Actions?.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      label="Create Schedule"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        setOpenCreateScheduleModal(true);
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
        </div>
      </div>
      <div>
        {scheduledSlotsList.length > 0 ? (
          <CommonDynamicTable
            data={scheduledSlotsList}
            tableClass="2xl:h-[750px] lg:h-[480px] md:h-56"
            actionsRequired={true}
            renderActions={renderActions}
            highlightRow={false}
            removeHeaders={["DoctorId", "unitId", "departmentId"]}
          />
        ) : (
          <div className="font-semibold text-center mt-4 ">No Data Found</div>
        )}
      </div>

      {openCreateScheduleModal ? (
        <CreateScheduleModal
          open={openCreateScheduleModal}
          setOpen={setOpenCreateScheduleModal}
          handleOpen={handleOpenCreateScheduleModal}
          handleClose={handleCloseCreateScheduleModal}
          privilege={privilege}
          existingSlotEditRow={existingSlotEditRow}
          setExistingSlotEditRow={setExistingSlotEditRow}
        />
      ) : null}
    </div>
  );
};

export default DoctorSchedule;
