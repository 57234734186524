import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TestCreationModel from "./TestCreationModel";
import CommonDynamicTablePagination from "../../../../common/components/CommonTable/CommonDynamicTablePagination";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import {
  getListAutoSearch,
  getTestCreationList,
} from "../../../services/pathology/Test/TestCreationService";
import CommonMasterPaginationTable from "../../../../common/components/CommonTable/CommonMasterPaginationTable";

export default function TestCreationMaster() {
  const [data, setData] = useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState();
  const [openChild, setOpenChild] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [searchId, setSearchId] = React.useState(null);
  const [searchBar, setSearchBar] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const handelOpenChild = () => setOpenChild(true);
  const handleCloseChild = () => {
    if (openChild) {
      setOpenChild(false);
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  console.log("data result and data is", dataResult);
  // const editRow = (data, index) => {
  //   console.log("editRow", data);
  //   setDataEdit(data);
  //   setEdit(true);
  //   setSelectedIndex(index);
  // };

  const deleteRow = (row) => {
    handelOpenChild();
    console.log("row", row);
    setDeleteId(row);
  };

  const deleteRecord = () => {
    if (deleteId != null) {
      console.log("deleteId", dataResult);
      let numTempleData = [...dataResult];
      numTempleData.splice(deleteId, 1);
      console.log("numTempleData", numTempleData);
      setDataResult(numTempleData);
      handleCloseChild();
    }
  };
  const RenderActions = (row, index) => {
    return (
      <div className="flex ">
        <div>
          <Tooltip title="Edit">
            <a href="##" value="click" className="text-blue-500 mr-1">
              <DriveFileRenameOutlineIcon
               onClick={(e) => {
                setOpen(true);
                setDataEdit(row);
                // props.setSelectedIndex(index);
                setEdit(true);
              }}
              />
            </a>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete">
            <a
              href="##"
              className="text-red-500 mr-3"
              // onClick={() => props.deleteRow(row, index)}
              // onClick={() => props.setDeleteId(index)}
            >
              {<DeleteOutlineOutlinedIcon />}
            </a>
          </Tooltip>
        </div>
      </div>
    );
  };

  // Listing API Function
  const populateTable = (forPagination) => {
    let searchObj = {
      page: !forPagination ? 0 : page,
      pathTestId: searchId ? searchId : null,
      // searchString: searchBar ? searchBar : null,
      size: rowsPerPage,
    };
    getTestCreationList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        console.log("Patient information by id is ", res);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res?.result]);
        } else {
          setDataResult(res?.result);
          setPage(0);
        }
        setCount(res.count);
      });
    // .catch(() => {
    //   showSpinner(false);
    //   showRecordWarning(true);
    // });
  };
   //Autosearch API Function
   const handleChange = (autoSearchString) => {
    setSearchString(autoSearchString);
    console.log("autoSearchString", autoSearchString);
    if (autoSearchString !== "") {
      setSearchBar(autoSearchString);
      getListAutoSearch(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          console.log("res", res.result);
          setOptions(res.result);
        });
    } else if (autoSearchString === "") {
      setSearchBar(null);
    }
  };

  const searchFilde = (value) => {
    console.log("rfx hg", value);
    if (value === null) {
      setSearchString("");
      setSearchId(null);
    } else {
      setSearchString(value);
      setSearchId(value.id);
    }
  };
  useEffect(() => {
    populateTable();
  }, [searchString]);
  return (
    <div>
      <div className="w-full mx-auto mt-14 space-y-2 ">
        <h1 className="text-xl text-black font-Poppins lg:block text-center md:block">
          Pathology Test
        </h1>
        <div>
          <div className="flex justify-between w-full items-center mt-2">
            <div className="flex items-center w-full gap-2">
              <div className=" flex items-center gap-2 text-start w-full xl:w-3/4">
                {/* searchable */}
                <SearchBar
                  type="button"
                  name="SearchableSelect"
                  placeholder="Search By Test Creation Code /Name"
                  className=""
                  isClearable={true}
                  dataArray={options}
                  onChange={searchFilde}
                  handleInputChange={handleChange}
                />
                <div className="flex ">
                  <div className="flex items-center">
                    <CommonButton
                      searchIcon={true}
                      className="bg-blue-800 text-white"
                      type="button"
                      onClick={() => populateTable()}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Modal and table name start */}
            <div className="flex w-full justify-end">
              <CommonButton
                label="+ Add "
                className="bg-customBlue text-white"
                type="submit"
                onClick={() => {
                  handleOpen();
                  setDataEdit({});
                  setEdit(false);
                }}
              />
            </div>

            {/* Backdrop component to disable the screen after submitting the form */}
          </div>
          <hr className="w-full border-t-1 border-[#0B83A5] my-2" />
          {dataResult && dataResult !== undefined && dataResult?.length > 0 ? (
            <CommonMasterPaginationTable
              dataResult={dataResult}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              renderActions={RenderActions}
              removeHeaders={[
                "Id",
                "empBenefits",
                "Previous History Table",
                "empExpDetails",
                "empEducation",
                "empReference",
                "empDependentDetails",
                "empExperienceDetails",
              ]}
              populateTable={populateTable}
              actionsRequired={true}
              highlightRow={false}
              // handleStatusChange={handleStatusChange}
            />
          ) : null}
          <TestCreationModel
            open={open}
            setEdit={setEdit}
            // editRow={editRow}
            setOpen={setOpen}
            handleClose={handleClose}
            handleOpen={handleOpen}
            edit={edit}
            dataResult={dataResult}
            setDataResult={setDataResult}
            dataEdit={dataEdit}
            setDataEdit={setDataEdit}
            selectedIndex={selectedIndex}
            populateTable={populateTable}
          />
        </div>
      </div>
    </div>
  );
}
