import axios from "axios";
import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
//get patient info by id
//hiiii
export const getRefundOfBillPatientInfoById = (patientId) => {
  return apiClient.get(`/patientInfo/registration/${patientId}`, {
    headers: authHeader(),
  });
};

//Bill listing
//
export const billListingService = (patientId) => {
  return apiClient.get(`/refundOfBill/listOfOpdBill/${patientId}`, {
    headers: authHeader(),
  });
};
//Refund listing
// refundOfAdvance/bill/listOfRefund/3065990
export const refundListingService = (billId) => {
  return apiClient.get(`/refundOfAdvance/bill/listOfRefund/${billId}`, {
    headers: authHeader(),
  });
};

//servicce listing
export const serviceListing = (billId) => {
  return apiClient.get(`/refundOfBill/billServiceList/${billId}`, {
    headers: authHeader(),
  });
};
//post refund of bill
export const postRefundOfBillService = (postObj) => {
  return apiClient.post(`/refundOfBill/saveRefundOfBill`, postObj, {
    headers: authHeader(),
  });
};

export const refundOfBillPdfPrint = (advanceId, opdIpd) => {
  return apiClient.get(
    `/reports/bills/getRefundOfBill/${advanceId}/${opdIpd}`,
    {
      headers: authHeader(),

      responseType: "blob",
    }
  );
};
