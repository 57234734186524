import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
// import { Typography } from "antd";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function GcsCalculatorModal(props) {
  const {
    open,
    handleClose,
    poulateData,
    eyeOpeningResponse,
    setEyeOpeningResponse,
    verbalResponse,
    setVerbalResponse,
    motorResponse,
    setMotorResponse,
    setGCSData,
    GCSData,
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
  });
  const { setValue } = methods;

  let calculateGCS;

  // useEffect(() => {
  //   if (poulateData !== null && poulateData !== undefined) {
  //     setValue("eyeOpeningResponse", poulateData.GCS_E, {
  //       shouldValidate: true,
  //     });
  //     setValue("verbalResponse", poulateData.GCS_V, {
  //       shouldValidate: true,
  //     });
  //     setValue("motorResponse", poulateData.GCS_M, {
  //       shouldValidate: true,
  //     });
  //   }
  // }, [poulateData]);

  useEffect(() => {
    calculateGCS =
      parseInt(eyeOpeningResponse) +
      parseInt(verbalResponse) +
      parseInt(motorResponse);
    setGCSData(calculateGCS);
  }, [eyeOpeningResponse, verbalResponse, motorResponse]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <form>
            <div>
              <div className="text-black font-bold text-lg pb-1">
                GCS Calculator
              </div>
              {/* radioFields */}
              <div className="grid grid-cols-3 gap-2">
                {/* //Eye Opening Response// */}
                <div className="border border-slate-500 px-4 rounded-md">
                  <FormControl>
                    <FormLabel
                      id="eyeOpeningResponse"
                      sx={{ color: "black", fontWeight: "bold" }}
                    >
                      Eye Opening Response
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="eyeOpeningResponse"
                      name="eyeOpeningResponse"
                      value={eyeOpeningResponse}
                      onChange={(e) => {
                        setEyeOpeningResponse(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="4"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Spontaneous (4)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            To Varbal Stimuli (3)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">To Pain (2)</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">None (1)</Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {/* //Verbal Response// */}
                <div className="border border-slate-500 px-4 rounded-md">
                  <FormControl>
                    <FormLabel
                      id="verbalResponse"
                      sx={{ color: "black", fontWeight: "bold" }}
                    >
                      Verbal Response
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="verbalResponse"
                      name="verbalResponse"
                      value={verbalResponse}
                      onChange={(e) => {
                        setVerbalResponse(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="5"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">Oriented (5)</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">Confused (4)</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Inappropriate Words (3)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Incoherent (2)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">None (1)</Typography>
                        }
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            ET Tube in Situ (T)
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {/* //Motor Response// */}
                <div className="border border-slate-500 px-4 rounded-md">
                  <FormControl>
                    <FormLabel
                      id="motorResponse"
                      sx={{ color: "black", fontWeight: "bold" }}
                    >
                      Motor Response
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="motorResponse"
                      name="motorResponse"
                      value={motorResponse}
                      onChange={(e) => {
                        setMotorResponse(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="6"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Obeys Commands (6)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Localizes Pain (5)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Withdraws From Pain (4)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Flexion To Pain or Decorticate(3)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Extension to Pain or Decerebrate (2)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">None (1)</Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex gap-3 items-center mt-3">
                <div>
                  <h1 className="font-semibold text text-black">GCS Score</h1>
                </div>

                <div className="w-10 h-10 bg-red-600 rounded-full items-center justify-center flex  ">
                  <div className=" ">{GCSData}</div>
                </div>
              </div>
              <div className="flex justify-end col-span-2">
                <div className="col-span-2 flex justify-end gap-3">
                  <CommonButton
                    label="Close"
                    type="button"
                    onClick={() => handleClose()}
                    className="border border-customRed text-customRed"
                  />

                  <CommonButton
                    label="Save"
                    type="submit"
                    onClick={() => {
                      handleClose();
                      props.setGcsScore(GCSData);
                    }}
                    className="bg-customGreen text-white"
                  />
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
