import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import { saveCompletedTodos } from "../../../services/cliinicalChartServices/ClinicalCareChartService";

const EditToDoTaskModal = ({
  pendingToDos,
  setPendingToDos,
  admissionId,
  handlePenndingToDos,
}) => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState();
  //
  const renderActions = (row) => {
    const handleCheckboxChange = (e) => {
      const isChecked = e.target.checked;
      setPendingToDos((prev) =>
        prev.map((todo) =>
          todo.todoId === row.todoId ? { ...todo, isChecked } : todo
        )
      );
    };

    return (
      <div className="flex gap-2 items-center">
        <div className="flex cursor-pointer items-center">
          <Checkbox checked={row.isChecked} onChange={handleCheckboxChange} />
        </div>
      </div>
    );
  };

  const handleSave = () => {
    let selectedIds = pendingToDos
      .filter((item) => item.isChecked)
      .map((item) => item.todoId);

    setOpenBackdrop(true);

    console.log(selectedIds);

    saveCompletedTodos(selectedIds, admissionId)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert(res.message);
        handlePenndingToDos();
        setOpenConfirmationModal(false)
        setPendingToDos([])
      })
      .catch(() => {
        setOpenBackdrop(false);
        setOpenConfirmationModal(false)
      });
  };

  return (
    <div className="pt-6">
      {pendingToDos?.length > 0 && (
        <div className="bg-white shadow-md mt-3 rounded-lg">
          <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#FEFFD4] px-2 rounded-t-lg">
            Pending To Do List
          </div>
          <div className="p-2 rounded-b-lg">
            <CommonDynamicTableNew
              dataResult={pendingToDos}
              renderActions={renderActions}
              tableClass="max-h-96"
              highlightRow={false}
              removeHeaders={[
                "id",
                "task_service_id",
                "group_id",
                "todoId",
                "id",
                "id",
              ]}
            />
            <div className="py-2 flex justify-end">
              <CommonButton
                label={"Complete Task"}
                className={"bg-customGreen text-white"}
                onClick={() => {
                  setOpenConfirmationModal(true);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {openConfirmationModal && (
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => setOpenConfirmationModal(false)}
          confirmationSubmitFunc={handleSave}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to mark as complete ?"
          confirmationButtonMsg="Proceed"
        />
      )}
    </div>
  );
};

export default EditToDoTaskModal;
