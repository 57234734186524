import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { useEffect } from "react";
import { fetchPreviousConcession } from "../../../../billing/services/ConcessionManagerServices";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "74%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function PreviousConcessionModal(props) {
  var token = JSON.parse(localStorage.getItem("userInfo"));

  const { open, setOpen, handleOpen, handleClose, selectedPatientById } = props;

  const [prevConcList, setPrevConcList] = React.useState([]);

  //   ///\
  useEffect(() => {
    if (selectedPatientById !== null) {
      fetchPreviousConcession(selectedPatientById?.AdmissionId)
        .then((response) => response.data)
        .then((res) => {
          setPrevConcList(res.result);
        });
    }
  }, []);

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />

          <div className={"text-lg font-semibold pt-3"}>
            Previous Concessions
          </div>

          {/* patient Info */}
          <div>
            <fieldset className=" mx-auto border w-full rounded  bg-gray-100">
              <div className="lg:grid lg:grid-cols-8 md:grid md:grid-cols-2  gap-2 p-3">
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-28 font-semibold"> Patient Name</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.patientName}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-28 font-semibold"> OPD No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.OPDNO}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-28 font-semibold"> MR No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.mrNo}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-28 font-semibold"> Gender</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.gender}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-28 font-semibold"> Mobile No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.MobileNo}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                  <span className="w-28 font-semibold"> Unit</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.unit}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                  <span className="w-28 font-semibold"> Company</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.Company}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                  <span className="w-28 font-semibold"> Bed No</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {/* {selectedPatientById && selectedPatientById.PatientName} */}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                  <span className="w-28 font-semibold">Tariff</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.tariff}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                  <span className="w-28 font-semibold">Patient Source</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById && selectedPatientById.patientSource}
                    </span>
                  </div>
                </div>
                <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                  <span className="w-28 font-semibold">Patient Category</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatientById &&
                        selectedPatientById.PatientCategory}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          {prevConcList.length > 0 ? (
            <div className="mt-3">
              {/* <PreviousConcessionListing data={prevConcList} /> */}
              <CommonDynamicTable data={prevConcList} actionsRequired={false} />
            </div>
          ) : (
            <div className="text-center mt-6">No Data Found</div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
