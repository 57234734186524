import { yupResolver } from "@hookform/resolvers/yup";
import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  cancelPatientClearance,
  fetchClearenceDepartment,
  fetchClearenceTableData,
  fetchPatientListForClearanceSearch,
  postPatientClearence,
} from "../../services/patientClearenceServices/PatientClearenceServices";
import BillStatusModal from "./BillStatusModal";
import CheckListModal from "./CheckListModal";
import NursingClearenceModal from "./NursingClearenceModal";
import PharmacyClearenceModal from "./PharmacyClearanceModal";
import ViewDocumentsModal from "./ViewDocumentsModal";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { CancelOnIcon } from "../../../assets/icons/CustomIcons";
import { Tooltip } from "@mui/material";

const PatientClearence = () => {
  const location = useLocation();
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var UserId = token.userId;
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");

  //
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  //dates

  //
  const [floors, setFloors] = useState();
  const [floorId, setFloorId] = useState(0);
  //
  const [clearenceData, setClearenceData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState();
  //
  const [clearenceDept, setClearenceDept] = useState(0);

  //AdmissionId
  const [selctedPatient, setSelctedPatient] = useState(null);
  //Nursing Clearence Modal
  const [openNursingClearenceModal, setOpenNursingClearenceModal] =
    useState(false);
  const handleOpenNursingClearenceModal = () =>
    setOpenNursingClearenceModal(true);
  const handleCloseNursingClearenceModal = () =>
    setOpenNursingClearenceModal(false);

  //View Documents Modal
  const [openViewDocumentsModal, setOpenViewDocumentsModal] = useState(false);
  const handleOpenViewDocumentsModal = () => setOpenViewDocumentsModal(true);
  const handleCloseViewDocumentsModal = () => setOpenViewDocumentsModal(false);

  //Pharmacy Clearence Modal
  const [openPharmacyClearenceModal, setOpenPharmacyClearenceModal] =
    useState(false);
  const handleOpenPharmacyClearenceModal = () =>
    setOpenPharmacyClearenceModal(true);
  const handleClosePharmacyClearenceModal = () =>
    setOpenPharmacyClearenceModal(false);
  //
  //Check List Modal
  const [openCheckListModal, setOpenCheckListModal] = useState(false);
  const handleOpenCheckListModal = () => setOpenCheckListModal(true);
  const handleCloseCheckListModal = () => setOpenCheckListModal(false);
  //
  //Bill Status Modal
  const [openBillStatusModal, setOpenBillStatusModal] = useState(false);
  const handleOpenBillStatusModal = () => setOpenBillStatusModal(true);
  const handleCloseBillStatusModal = () => setOpenBillStatusModal(false);
  //
  //backdrop
  const [showSpinner, setShowSpinner] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isCancelClearence, setIsCancelClearence] = useState(null);

  //
  const [isUpdationCompleted, setIsUpdationCompleted] = useState(false);
  const [isAllowToGo, setIsAllowToGo] = useState(false);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      clearencePanding: true,
      floor: null,
      patientSearch: "",
      toDate: new Date(),
      fromDate: new Date(),
    },
  });
  const { control, watch, register, reset } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  let isPending = watch("clearencePanding");

  {
    isPending === true //|| isPending === undefined
      ? (isPending = 1)
      : (isPending = 0);
  }

  // 3. Floor List API
  useEffect(() => {
    getFloor();
    getClearenceDepartment();
  }, [UserId]);

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [FromDate, ToDate, floorId, isPending, clearenceDept, userActions]);

  const getPatientInfoAutoComplete = (SearchString) => {
    const autoCopmleteObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      searchString: SearchString || "",

      floorId: floorId,
      isPending: isPending,
      loginStore: userActions.some(
        (item) => !item.isAction && item.action === "Pharmacy Clearance"
      )
        ? 2
        : userActions.some(
            (item) => !item.isAction && item.action === "Nursing Clearance"
          )
        ? 1
        : 0,
      // loginStore:
      //   clearenceDept?.Description === "Pharmacy"
      //     ? 2
      //     : clearenceDept?.Description === "Nursing"
      //     ? 1
      //     : 0,
    };

    userActions.length > 0 &&
      fetchPatientListForClearanceSearch(autoCopmleteObj)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  //floor
  const getFloor = () => {
    fetchFloor()
      .then((response) => {
        setFloors(response.data.result);
      })
      .catch((response) => {});
  };

  //clearence dept

  const getClearenceDepartment = () => {
    fetchClearenceDepartment().then((response) => {
      setClearenceDept(response.data.result);
    });
  };

  //

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      getClearanceTableData();
  }, [
    floorId,
    isPending,
    FromDate,
    ToDate,
    selectedPatient,
    clearenceDept,
    userActions,
  ]);

  // useEffect(() => {
  //   getClearanceTableData();
  // }, [
  //   floorId,
  //   isPending,
  //   FromDate,
  //   ToDate,
  //   selectedPatient,
  //   clearenceDept,
  //   userActions,
  // ]);

  const getClearanceTableData = (forPagination) => {
    setShowSpinner(true);

    const clearenceObj = {
      floorId: floorId,
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      isPending: isPending,
      menuId: location?.state?.menuId,
      //"privilege": "string",
      loginStore: userActions.some(
        (item) => !item.isAction && item.action === "Pharmacy Clearance"
      )
        ? //clearenceDept?.Description === "Pharmacy"
          2
        : userActions.some(
            (item) => !item.isAction && item.action === "Nursing Clearance"
          )
        ? //clearenceDept?.Description === "Nursing"
          1
        : 0,
      searchString: "",
      searchId: selectedPatient?.id || null,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    userActions.length > 0
      ? fetchClearenceTableData(clearenceObj)
          .then((response) => response.data)
          .then((res) => {
            if (forPagination) {
              setClearenceData((prevData) => [...prevData, ...res.result]);
            } else {
              setClearenceData(res.result);
              setPage(0);
            }
            setCount(res.count);
            setShowSpinner(false);
          })
          .catch(() => setShowSpinner(false))
      : setShowSpinner(false);
  };

  //
  const handleClearenceNursing = () => {
    if (selctedPatient !== null) {
      if (selctedPatient.isMsgSend === 1) {
        handleOpenNursingClearenceModal();
      } else if (selctedPatient.isMsgSend === 0) {
        warningAlert("Discharge Not Initiated !");
      }
    } else if (selctedPatient === null) {
      warningAlert("Please Select Patient !");
    }
  };
  //
  const handleClearencePharmacy = () => {
    if (selctedPatient !== null) {
      if (selctedPatient?.IsNursingClearanceDone === 0) {
        if (selctedPatient.isMsgSend === 1) {
          handleOpenPharmacyClearenceModal();
        } else if (selctedPatient.isMsgSend === 0) {
          warningAlert("Discharge Not Initiated !");
        }
      } else {
        warningAlert("Nursing clearance is pending!");
      }
    } else if (selctedPatient === null) {
      warningAlert("Please Select Patient !");
    }
  };
  //
  const handleCheckList = () => {
    if (selctedPatient !== null) {
      handleOpenCheckListModal();
    } else if (selctedPatient === null) {
      warningAlert("Please Select Patient !");
    }
  };

  const handleSelectedRow = (row, index) => {
    setSelctedPatient(row);
  };

  //   postPatientClearence
  const finalObj = {
    admissionId: 0,
    departmentId: 0,
    pharmacyClearanceAmount: 0,
    userId: 0,

    patientDocumentClearanceDto: [
      {
        docAddedBy: 0,
        docViewBy: 0,
        filePath: "",
        viewDateTime: "2023-03-17T13:37:46.247Z",
      },
    ],
    saveWithDoc: false,
    isFromTab: false,
    updationCompleted: true,
    allowToGo: true,
  };

  const onSubmit = (e) => {
    // else if (selctedPatient !== null && selctedPatient.Discharged === false) {
    //   warningAlert("Please Discharge Patient !");
    // }
    // else if (selctedPatient.IsBillGenerated === false) {
    //   warningAlert("Please Generate Bill !");
    // }
    if (selctedPatient === null) {
      warningAlert("Please Select Patient !");
    } else if (
      e === "updationCompleted" &&
      selctedPatient.allDoneFromBilling === 0
    ) {
      setIsUpdationCompleted(true);
      setIsAllowToGo(false);
      setOpenConfirmationModal(true);
    } else if (
      e === "updationCompleted" &&
      selctedPatient.allDoneFromBilling === 1
    ) {
      warningAlert("Already All Updation Done From Billing !");
    }
    //allow button conditions
    if (
      e === "allowToGo" &&
      selctedPatient.allDoneFromBilling === 1 &&
      selctedPatient.allowGoTo === 0
    ) {
      setIsUpdationCompleted(false);
      setIsAllowToGo(true);
      handleOpenBillStatusModal();
    } else if (
      e === "allowToGo" &&
      selctedPatient.allDoneFromBilling === 0 &&
      selctedPatient.allowGoTo === 0
    ) {
      warningAlert("Please Done All Updation!");
    } else if (
      e === "allowToGo" &&
      selctedPatient.allDoneFromBilling === 1 &&
      selctedPatient.allowGoTo === 1
    ) {
      warningAlert("Allow to Go Already Given For This Patient!");
    }
  };

  const handleFormSubmit = (dataFromAllowGo) => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    // //
    finalObj.admissionId = selctedPatient.AdmissionId;
    finalObj.departmentId = clearenceDept.Id;
    finalObj.userId = UserId;
    finalObj.updationCompleted = isUpdationCompleted;
    finalObj.allowToGo = isAllowToGo;
    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;
    finalObj.isFromTab = false;
    finalObj.remark = dataFromAllowGo?.remark; //// not save @ backend now

    postPatientClearence(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);

          setOpenBackdrop(false);
          setSelctedPatient(null);
          handleCloseBillStatusModal();
          setClearenceData([]);
          reset();
        }
      })
      .catch((res) => {
        errorAlert(res.response.data.message || res.message);
        setOpenBackdrop(false);
      });
  };

  const handleSubmitAllowToGo = (data) => {
    setOpenConfirmationModal(true);
  };

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const handleViewDoc = () => {
    if (selctedPatient !== null) {
      handleOpenViewDocumentsModal();
    } else {
      warningAlert("Please Select Patient!");
    }
  };

  const renderActions = (row) => {
    console.log(row);

    return (
      <div className="flex items-center">
        {userActions.map((obj) => (
          <div>
            {obj.isAction && (
              <div>
                {obj.action === "Cancel Nursing Clearance" && (
                  <CancelOnIcon
                    title={obj.action}
                    onClick={() => {
                      setOpenConfirmationModal(true);
                      setIsCancelClearence(true);
                      setPrivilege(obj.action);
                    }}
                  />
                )}
                {obj.action === "Cancel Pharmacy Clearance" && (
                  <CancelOnIcon
                    title={obj.action}
                    onClick={() => {
                      setOpenConfirmationModal(true);
                      setIsCancelClearence(true);
                      setPrivilege(obj.action);
                    }}
                  />
                )}
                {obj.action === "Cancel Billing Clearance" && (
                  <CancelOnIcon
                    title={obj.action}
                    onClick={() => {
                      setOpenConfirmationModal(true);
                      setIsCancelClearence(true);
                      setPrivilege(obj.action);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleCancelClearence = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const cancelObj = {
      admissionId: selctedPatient.AdmissionId,
      department: token?.department,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    cancelPatientClearance(cancelObj)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert(res.message);
        getClearanceTableData();
      })
      .catch((res) => {
        setOpenBackdrop(false);
        errorAlert(res?.response?.data?.message || res?.message);
      });
  };
  // allDoneFromBilling
  const renderInput = (row, index, header) => {
    return (
      <>
        <div
          className={
            row.Discharged === true
              ? "text-[#059669]"
              : row.allDoneFromBilling === 1
              ? "text-pink-400"
              : "text-gray-800"
          }
        >
          <Tooltip title={row.allowgotoslip ? "Allowed To Go" : ""}>
            {row[header]}
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-center text-black font-bold text-xl mb-2 ">
          Patient Clearance
        </div>

        <div className="grid grid-cols-12 gap-3">
          {/* //SearchBar// */}
          <div className="col-span-12 lg:col-span-4 z-50">
            <div className="md:col-span-2 lg:col-span-2 mb-3">
              <SearchDropdown
                control={control}
                placeholder="Search By Patient Name / MR.No./Mob No."
                dataArray={autocompleteData}
                name="patientSearch"
                searchIcon={true}
                isClearable={true}
                handleInputChange={getPatientInfoAutoComplete}
                inputRef={{
                  ...register("patientSearch", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        setSelectedPatient(e.target.value);
                      } else {
                        setSelectedPatient("");
                        methods.reset();
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>
          {/* from Date */}
          <div className="lg:col-span-2 md:col-span-4">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          {/* to Date */}
          <div className="lg:col-span-2 md:col-span-4">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          {/* dates */}
          {/*<div className="flex col-span-12 lg:col-span-5">
            <div className="grid grid-cols-2 lg:col-span-3 md:col-span-11 gap-3">
              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
            </div>
              </div>*/}
          {/* //floor// */}
          <div className="md:col-span-4 lg:col-span-2">
            <DropdownField
              control={control}
              name="floor"
              placeholder="Floor"
              dataArray={floors}
              isSearchable={false}
              inputRef={{
                ...register("floor", {
                  onChange: (e) => {
                    setFloorId(e.target.value.id);
                  },
                }),
              }}
            />
          </div>

          <div className="flex col-span-6 lg:col-span-12  items-center justify-between">
            <div className="md:w-[600px]lg:col-span-2 ">
              <CheckBoxField
                control={control}
                name="clearencePanding"
                label="Pending clearance"
              />
            </div>
            {/*<div className="col-span-2 ">
              <div>{"Export To Excel"}</div>
            </div>*/}
          </div>
        </div>
        <div className="flex justify-end space-x-2 items-center mr-5">
          <div className="flex space-x-2 items-center">
            <div className="h-4 w-4 bg-pink-400"></div>
            <label className="font-semibold">Done From Billing</label>
          </div>
          <div className="flex space-x-2 items-center">
            <div className="h-4 w-4 bg-[#4bd8ab]"></div>
            <label className="font-semibold">Discharged</label>
          </div>
        </div>

        {/* //table */}
        <div>
          {showSpinner ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : clearenceData.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={clearenceData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              populateTable={getClearanceTableData}
              setSelctedPatient={setSelctedPatient}
              handleSelectedRow={handleSelectedRow}
              tableClass="xl:h-[550px]"
              renderActions={renderActions}
              renderInput={renderInput}
              editableColumns={["Patient Name", "MRNo"]}
              removeHeaders={["AdmissionId"]}
            />
          ) : (
            <p className="text-center mt-6"> No Data Found</p>
          )}
        </div>

        {/* //buttons */}
        {clearenceData.length > 0 ? (
          <div className="flex gap-3 justify-end">
            <div>
              <CommonButton
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  setSelctedPatient(null);
                  getClearanceTableData();
                  methods.reset();
                }}
              />
            </div>
            {/*userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {
                      clearenceDept.Description === "Nursing" ? (
                      obj.action === "Patient Clearance" && (
                        <div>
                          <CommonButton
                            label="Clearance"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              handleClearenceNursing();
                              setPrivilege(obj.action);
                            }}
                          />
                        </div>
                      )
                    ) : clearenceDept.Description === "Pharmacy" ? (
                      obj.action === "Patient Clearance" && (
                        <div>
                          <CommonButton
                            label="Clearence"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              handleClearencePharmacy();
                              setPrivilege(obj.action);
                            }}
                          />
                        </div>
                      )
                    ) : clearenceDept.Description === "Billing" ? (
                      <>
                        {obj.action === "View Documents" && (
                          <div>
                            <CommonButton
                              label="View Documents"
                              className="bg-customBlue text-white"
                              onClick={() => {
                                handleViewDoc();
                              }}
                            />
                          </div>
                        )}
                        {obj.action === "Check List" && (
                          <div>
                            <CommonButton
                              label="Check List"
                              className="bg-customBlue text-white"
                              onClick={() => {
                                handleCheckList();
                                setPrivilege(obj.action);
                              }}
                            />
                          </div>
                        )}
                        {obj.action === "Updation Completed" && (
                          <div>
                            <CommonButton
                              label="Updation Completed"
                              className="bg-customGreen text-white"
                              onClick={() => {
                                onSubmit("updationCompleted");
                                setPrivilege(obj.action);
                              }}
                            />
                          </div>
                        )}
                        {obj.action === "Allow To Go" && (
                          <div>
                            <CommonButton
                              label="Allow To Go"
                              className="bg-customGreen text-white"
                              onClick={() => {
                                onSubmit("allowToGo");
                                setPrivilege(obj.action);
                              }}
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
                            ))*/}

            {userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {obj.action === "Nursing Clearance" && (
                      <div>
                        <CommonButton
                          label="Clearance"
                          className="bg-customGreen text-white"
                          onClick={() => {
                            handleClearenceNursing();
                            setPrivilege(obj.action);
                            setIsCancelClearence(null);
                          }}
                        />
                      </div>
                    )}

                    {obj.action === "Pharmacy Clearance" && (
                      <div>
                        <CommonButton
                          label="Clearence"
                          className="bg-customGreen text-white"
                          onClick={() => {
                            handleClearencePharmacy();
                            setPrivilege(obj.action);
                            setIsCancelClearence(null);
                          }}
                        />
                      </div>
                    )}

                    {obj.action === "View Documents" && (
                      <div>
                        <CommonButton
                          label="View Documents"
                          className="bg-customBlue text-white"
                          onClick={() => {
                            handleViewDoc();
                            setPrivilege(obj.action);
                            setIsCancelClearence(null);
                          }}
                        />
                      </div>
                    )}
                    {obj.action === "Check List" && (
                      <div>
                        <CommonButton
                          label="Check List"
                          className="bg-customBlue text-white"
                          onClick={() => {
                            handleCheckList();
                            setPrivilege(obj.action);
                            setIsCancelClearence(null);
                          }}
                        />
                      </div>
                    )}
                    {obj.action === "Updation Completed" && (
                      <div>
                        <CommonButton
                          label="Updation Completed"
                          className="bg-customGreen text-white"
                          onClick={() => {
                            onSubmit("updationCompleted");
                            setPrivilege(obj.action);
                            setIsCancelClearence(null);
                          }}
                        />
                      </div>
                    )}
                    {obj.action === "Allow To Go" && (
                      <div>
                        <CommonButton
                          label="Allow To Go"
                          className="bg-customGreen text-white"
                          onClick={() => {
                            onSubmit("allowToGo");
                            setPrivilege(obj.action);
                            setIsCancelClearence(null);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : null}
              </>
            ))}
          </div>
        ) : null}
      </div>
      {/* Nursing Clearence Modal */}
      {openViewDocumentsModal ? (
        <ViewDocumentsModal
          open={openViewDocumentsModal}
          setOpen={setOpenViewDocumentsModal}
          handleOpen={handleOpenViewDocumentsModal}
          handleClose={handleCloseViewDocumentsModal}
          //
          selctedPatient={selctedPatient}
        />
      ) : null}

      {/* Nursing Clearence Modal */}
      {openNursingClearenceModal ? (
        <NursingClearenceModal
          open={openNursingClearenceModal}
          setOpen={setOpenNursingClearenceModal}
          handleOpen={handleOpenNursingClearenceModal}
          handleClose={handleCloseNursingClearenceModal}
          //
          selctedPatient={selctedPatient}
          setSelctedPatient={setSelctedPatient}
          clearenceDept={clearenceDept}
          setClearenceData={setClearenceData}
          getClearanceTableData={getClearanceTableData}
          //

          menuId={location?.state?.menuId}
          privilege={privilege}
          reset={reset}
        />
      ) : null}

      {/* Pharmacy Modal */}
      {openPharmacyClearenceModal ? (
        <PharmacyClearenceModal
          open={openPharmacyClearenceModal}
          setOpen={setOpenPharmacyClearenceModal}
          handleOpen={handleOpenPharmacyClearenceModal}
          handleClose={handleClosePharmacyClearenceModal}
          //
          selctedPatient={selctedPatient}
          setSelctedPatient={setSelctedPatient}
          clearenceDept={clearenceDept}
          setClearenceData={setClearenceData}
          getClearanceTableData={getClearanceTableData}
          //

          //
          menuId={location?.state?.menuId}
          privilege={privilege}
          reset={reset}
        />
      ) : null}

      {/* Pharmacy Modal */}
      {openCheckListModal ? (
        <CheckListModal
          open={openCheckListModal}
          setOpen={setOpenCheckListModal}
          handleOpen={handleOpenCheckListModal}
          handleClose={handleCloseCheckListModal}
          //
          selctedPatient={selctedPatient}
          setSelctedPatient={setSelctedPatient}
          menuId={location?.state?.menuId}
          privilege={privilege}
          reset={reset}
        />
      ) : null}

      {/* Pharmacy Modal */}
      {openBillStatusModal ? (
        <BillStatusModal
          open={openBillStatusModal}
          setOpen={setOpenCheckListModal}
          handleOpen={handleOpenBillStatusModal}
          handleClose={handleCloseBillStatusModal}
          //
          selctedPatient={selctedPatient}
          handleSubmitAllowToGo={handleSubmitAllowToGo} ////handle submit & alow to go submit are different
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() =>
          !!isCancelClearence ? handleCancelClearence() : handleFormSubmit()
        }
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default PatientClearence;
