import HdrAutoOutlinedIcon from "@mui/icons-material/HdrAutoOutlined";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { fetchBarCode } from "../../../commonServices/barCodeServices/BarCodeServices";

function BarCodePrint(props) {
  //
  const [data, setData] = useState("");
  const ref = useRef();

  const pageStyle = ` @page{ size: 5cm 2.5cm;border:2px solid lack; 
    margin:0px; display:block; }; 
    @media print { @page { size: 5cm 2.5cm ; display:block; } } @media all { .pageBreak { display: none } };
     @media print { .pageBreak{ page-break-before:always; } 
    } `;

  const getBarCodeData = () => {
    fetchBarCode(props.barcodeText)
      .then((response) => response.data)
      .then((res) => {
        setData(res.imageBase64);
      });
  };

  useEffect(() => {
    getBarCodeData();
  }, [props?.barcodeText]);

  const barcodeImage = () => {
    return (
      <img
        src={`data:image/png;base64,${data}`}
        style={{ width: "180px", height: "30px" }}
      />
    );
  };

  return (
    <div>
      <div style={{ display: "none" }}>
        <div ref={ref} style={{ margin: "2mm", width: "50mm", height: "25mm" }}>
          {/* barcode for visit listing */}
          {props.template === "PatientVisitListTable" && (
            <div className="text-[10px] text-black font-semibold">
              <div>Reg. No: {props.row.MRNo}</div>
              <div>Name : {props.row["Patient Name"]}</div>
              <div>Date & Time : {props.row.Date}</div>
              {barcodeImage()}
            </div>
          )}

          {/* barcode for admission listing */}
          {props.template === "admissionListCollapseTableBarcode" && (
            <div
              className="text-[8px] text-black font-semibold"
              style={{ margin: "2mm", width: "50mm", height: "25mm" }}
            >
              <div>MRNo : {props.row.MRNo}</div>

              {barcodeImage()}
            </div>
          )}

          {props.template === "admissionListCollapseTableSticker" && (
            <div
              className="text-[8px] text-black font-semibold"
              style={{ margin: "2mm", width: "50mm", height: "25mm" }}
            >
              <div>MRNo : {props.row.MRNo}</div>
              <div>Name : {props.row?.["Patient Name"]}</div>
              <div>
                {`Age : ${props.row?.["AgeYear"]} , Gender : ${props.row?.["Gender"]}`}
              </div>
              <div>DOA : {props.row?.["Admission Date"]}</div>
              <div>Admitted Under : {props.row?.["Admitted Under"]}</div>
              <div>Bed No : {props.row?.["Bed No"]}</div>
              <div>Speed Dial : *7164</div>
            </div>
          )}

          {/* barcode for pathalogy/work order view */}
          {props.template === "labSampleNumberBarCode" && (
            <div
              className="text-[10px] text-black font-semibold"
              style={{ margin: "2mm", width: "50mm", height: "25mm" }}
            >
              <div>MR. No: {props?.row1?.MRNo}</div>
              <div>Name : {props?.row1?.PatientName}</div>
              {barcodeImage()}
              <div>Sample Number : {props.row.SampleNo}</div>
            </div>
          )}

          {/* Diet Order    */}
          {props.template === "DietOrderBarCode" && (
            <div
              className="text-[8px] text-black font-semibold overflow-hidden"
              style={{ margin: "2mm", width: "50mm", height: "25mm" }}
            >
              {props?.row?.IsAllergic && (
                <div className="absolute top-1 right-1">
                  <HdrAutoOutlinedIcon fontSize="small" />
                </div>
              )}
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Name :</p>
                <p className="">{props?.row?.PatientName}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Bed No :</p>
                <p>{props?.row?.Bedno}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Food type :</p>
                <p>{props?.row?.FoodType}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Diet type :</p>
                <p>{props?.row?.DietType}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Diet Plan :</p>
                <p>{props?.row?.DietPlanName}</p>
              </div>
              {!props?.isShowInstruction && (
                <div className="flex  space-x-1">
                  <p className="font-semibold whitespace-nowrap"> Sp.Inst. :</p>
                  <p>{props?.row?.Instructions}</p>
                </div>
              )}
            </div>
          )}
          {props.template === "DietOrderLineBarCode" && (
            <div
              className="text-[8px] text-black font-semibold "
              style={{ margin: "2mm", width: "50mm", height: "25mm" }}
            >
              {props?.row?.IsAllergic && (
                <div className="absolute top-1 right-1">
                  <HdrAutoOutlinedIcon fontSize="small" />
                </div>
              )}
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Name :</p>
                <p className="">{props?.row?.patientName}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Bed No :</p>
                <p>{props?.row?.bedNo}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Food type :</p>
                <p>{props?.row?.["Food Type"]}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Diet type :</p>
                <p>{props?.row?.["Diet Type"]}</p>
              </div>
              <div className="flex items-center space-x-1">
                <p className="font-semibold whitespace-nowrap"> Diet Plan :</p>
                <p>{props?.row?.["Diet Plan Name"]}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactToPrint
        trigger={() => props.RenderBarcodeButton()}
        content={() => ref.current}
        pageStyle={pageStyle}
      />
    </div>
  );
}
export default BarCodePrint;
