import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// fetch All drug list
export const storeStockMinMaxFillDrugList = (postObj) => {
  return apiClient.post(`/storeStockMinMax/fillDrugList`, postObj,{
    headers:authHeader()
  });
};
// update drug list
export const updateStoreStockMinMaxData=(postObj)=>{
  return apiClient.post(`/storeStockMinMax/updateStoreStockMinMax`,postObj,{
    headers:authHeader()
  })
}

