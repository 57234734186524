import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";

const LocalExamination = () => {
  const { control,  } = useFormContext();
  return (
    <div>
      <div className=" grid grid-cols-2 gap-3">
        {/* local Examination */}
        <div className="col-span-2 grid grid-cols-4 gap-3">
          {/*Burn Percentage */}
          <div className="col-span-1 ">
            <InputField
              name="burnPercentage"
              variant="outlined"
              label="Burn Percentage"
              // error={errors.burnPercentage}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="col-span-3">
            <InputArea
              control={control}
              name="localExamination"
              label="Local Examinations "
              placeholder="Local Examinations"
            />
          </div>
        </div>
        {/* injuries Details */}
        <div className=" ">
          <InputArea
            control={control}
            name="injuriesDetails"
            label="Injuries Details "
            placeholder="Injuries Details"
          />
        </div>
        {/* Provisional Dignosis */}
        <div className=" ">
          <InputArea
            control={control}
            name="provisionalDignosis"
            label="Provisional Diagnosis "
            placeholder="Provisional Diagnosis"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-8 md:grid-cols-4">
        <div>
          <CheckBoxField control={control} name="clw" label="CLW" />
        </div>
        <div>
          <CheckBoxField control={control} name="abrasion" label="Abrasion" />
        </div>
        <div>
          <CheckBoxField control={control} name="bruise" label="Bruise" />
        </div>
      </div>
    </div>
  );
};

export default LocalExamination;
