import { Box, Modal, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TrendChartIcon } from "../../assets/CommonAssets";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import { ModalStyle } from "../../common/components/ModalStyle";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import RadiologyWorkOrderPrint from "../Radiology/prints/RadiologyWorkOrderPrint";
import { radiologyWorkOrderPrint } from "../Radiology/Services/WorkOrderViewServices";
// import {
//   generatePdf,
//   getRadiologyTestTemplates,
// } from "../Radiology/services/WorkOrderViewServices";
// import useGeneratePdf from "../../common/components/Custom Hooks/UseGeneratePdf";
// import { getPathologyTestParameters } from "../Pathology/services/WorkOrderViewServices";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LIMSHistoryModal = (props) => {
  const {
    openLIMSHistoryModal,
    setOpenLIMSHistoryModal,
    pathHistoryData,
    setPathHistoryData,
    radioHistoryData,
    setRadioHistoryData,
    patientInfo,
    LIMSType,
  } = props;
  const { control, watch } = useForm();
  const [values, setValues] = useState(0);
  const [selectedPathTestIndex, setSelectedPathTestIndex] = useState(0);
  const [selectedPathTest, setSelectedPathTest] = useState(null);
  const [selectedTestParameters, setSelectedTestParameters] = useState([]);
  const [selectedRadioTestIndex, setSelectedRadioTestIndex] = useState(0);
  const [selectedRadioReportIndex, setSelectedRadioReportIndex] =
    useState(null);
  const [selectedRadioTest, setSelectedRadioTest] = useState(null);
  const [selectedTestTemplates, setSelectedTestTemplates] = useState([]);
  const [reportPdf, setReportPdf] = useState("");
  const [isReport, setIsReport] = useState(false);
  const [radioPrintPreview, setRadioPrintPreview] = useState(null);
  let getDischargeSummary = watch("isDischargeSumary");
  let getUserInfo = JSON.parse(localStorage.getItem("loggedUser"));

  // const handleChange = (event, newValue) => {
  //   setValues(newValue);
  // };

  useEffect(() => {
    if (pathHistoryData?.length > 0) {
      setSelectedPathTest(pathHistoryData?.[0]);
      //   getParametersList(pathHistoryData?.[0]?.testId);
      setSelectedPathTestIndex(0);
    }
  }, [pathHistoryData]);

  useEffect(() => {
    if (radioHistoryData?.length > 0) {
      setSelectedRadioTest(radioHistoryData?.[0]);
      //   getRadiologytemplates(radioHistoryData?.[0]?.testId);
      setSelectedRadioTestIndex(0);
    }
  }, [radioHistoryData]);
  console.log("selectedRadioTest123", selectedRadioTest);
  //   const getParametersList = (testId) => {
  //     getPathologyTestParameters(
  //       patientInfo?.PatientId,
  //       patientInfo?.VisitId,
  //       testId
  //     ).then((response) => {
  //       let newArray = [];
  //       response.data.result?.forEach((jsonObject) => {
  //         newArray.push(JSON.parse(jsonObject));
  //       });
  //       setSelectedTestParameters(newArray);
  //     });
  //   };

  //   const getRadiologytemplates = (testId) => {
  //     getRadiologyTestTemplates(
  //       patientInfo?.PatientId,
  //       patientInfo?.VisitId,
  //       testId
  //     ).then((response) => {
  //       let newArray = [];
  //       response.data.result?.forEach((jsonObject) => {
  //         newArray.push(JSON.parse(jsonObject));
  //       });
  //       console.log("parametersResponse", newArray);
  //       setSelectedTestTemplates(newArray);
  //     });
  //   };

  //   const handlePrintReport = (patientVisitId, orderId, investigationId) => {
  //     setIsReport(true);
  //     generatePdf(
  //       patientVisitId,
  //       orderId,
  //       getUserInfo?.employeeId,
  //       investigationId
  //     )
  //       .then((response) => {
  //         console.log("PDF Response", response);
  //         if (response.status === 200) {
  //           setReportPdf(response);
  //         }
  //       })
  //       .catch((response) => {
  //         console.log("response catch", response);
  //       });
  //   };

  //   const fileURL = useGeneratePdf(reportPdf);

  function getPrintDetails(OrderDtlsId) {
    radiologyWorkOrderPrint(OrderDtlsId)
      .then((response) => response.data)
      .then((res) => {
        setRadioPrintPreview(res.result);
        // setPrintImage("data:image/png;base64," + res.result?.Radiologist1Sign);
        // setIsPrint(true);
        // setOpenPrintModal(true);
        // setOpenBackdrop(false);
      });
    //   .catch((error) => {
    //     setOpenBackdrop(false);
    //   });
  }

  return (
    <>
      <Modal open={openLIMSHistoryModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            height: "95%",
            overflowY: "scroll",
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 20,
            p: 2,
          }}
          className="w-[95%] h-[95%]"
        >
          <div className="flex justify-between">
            <div className="font-semibold text-lg">Investigations</div>
            {/* <CheckBoxField
              control={control}
              name="isDischargeSumary"
              label="Discharge Summary"
            /> */}
            <CancelPresentationIconButton
              onClick={() => {
                setOpenLIMSHistoryModal(false);
                setSelectedPathTest(null);
                setSelectedPathTestIndex(null);
                setValues(0);
                setReportPdf("");
                setSelectedRadioReportIndex(null);
                setSelectedRadioTestIndex(null);
                setRadioPrintPreview(null);
                setPathHistoryData([]);
                setRadioHistoryData([]);
              }}
            />
          </div>
          <div className="py-1">
            <hr className="border-t-[1px] border-[#E3E3E5]" />
          </div>
          <div className="grid grid-cols-3 lg:grid-cols-2 xl:grid-cols-5 px-2 py-1 border bg-[#F7F9FB] rounded-md text-sm my-2">
            <div className="flex items-center space-x-2 col-span-2 lg:col-span-1 xl:col-span-2">
              <span className="font-semibold w-24">Patient Name</span>
              <span>:</span>
              <span className="">
                {patientInfo?.["Patient Name"] || patientInfo?.PatientName}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="font-semibold w-14 xl:w-12">MR No </span>
              <span className="">:&nbsp;{patientInfo?.MRNo}</span>
            </div>
            <div className="flex items-center space-x-2 col-span-2 lg:col-span-1">
              <span className="font-semibold w-24 xl:w-8">Age </span>
              <span className="">:&nbsp;{patientInfo?.Age}</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="font-semibold w-14 xl:w-13">Gender </span>
              <span className="">:&nbsp;{patientInfo?.Gender}</span>
            </div>
          </div>
          <div>
            <Box>
              {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className="text-customBlue bg-gray-100"
                  value={values}
                  onChange={handleChange}
                  textColor="none"
                  aria-label="basic tabs example"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#0B83A5",
                    },
                  }}
                >
                  <Tab label="Pathology" {...a11yProps(0)} />
                  <Tab label="Radiology" {...a11yProps(1)} />
                </Tabs>
              </Box> */}
              {LIMSType === "Pathology" && (
                <CustomTabPanel value={values} index={0}>
                  <div className="grid grid-cols-7 gap-x-2">
                    <div className="col-span-2 border border-b-gray-100 rounded-xl p-2 h-full space-y-2 bg-[#FFFCFA]">
                      <div className="text-customBlue font-semibold">
                        Investigation
                      </div>
                      <div className="py-0.5">
                        <hr className="border-t-[1px] border-black" />
                      </div>
                      <div>
                        <SearchDropdown
                          control={control}
                          name="investigation"
                          placeholder="Investigation"
                          searchIcon={true}
                        />
                      </div>
                      <div>
                        {pathHistoryData?.map((val, index) => (
                          <div
                            className={`${
                              selectedPathTestIndex === index
                                ? "bg-[#FFC44B]"
                                : ""
                            } p-2 rounded-md cursor-pointer text-sm w-full flex space-x-2 items-center`}
                            onClick={() => {
                              setSelectedPathTestIndex(index);
                              setSelectedPathTest(val);
                              //   getParametersList(val?.testId);
                            }}
                          >
                            {getDischargeSummary === true && (
                              <fieldset className="-my-2">
                                <CheckBoxField
                                  control={control}
                                  name={`testCheck${index}`}
                                />
                              </fieldset>
                            )}
                            {val?.testName}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-span-2 border border-b-gray-100 rounded-xl h-full">
                      <div className="bg-customBlue text-white rounded-t-xl flex justify-center items-center h-10 text-sm font-semibold">
                        {selectedPathTest?.testName}
                      </div>
                      <div className="">
                        {selectedPathTest !== null &&
                          selectedPathTest?.investigationDates[0]?.pathParameterValues?.map(
                            (p, pIndex) => (
                              <div className="flex items-center px-2 h-10 bg-[#F1FFF1] border-b-[0.5px] border-[#E3E3E3]">
                                {getDischargeSummary === true && (
                                  <fieldset className="-my-2">
                                    <CheckBoxField
                                      control={control}
                                      name={`parameterCheck${pIndex}`}
                                    />
                                  </fieldset>
                                )}
                                <div className="flex justify-between w-full">
                                  <div className="text-sm px-2">
                                    {p?.ParameterName}
                                  </div>
                                  <button>
                                    <TrendChartIcon />
                                  </button>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <div className="overflow-x-auto col-span-3">
                      <div className="flex space-x-2 w-full">
                        {selectedPathTest?.investigationDates?.map(
                          (pv, pvIndex) => {
                            return (
                              <div
                                className={` ${
                                  pvIndex % 2 === 1 ? "bg-[#EAF6FF]" : ""
                                } border border-b-gray-100 rounded-xl h-full`}
                              >
                                <div className="flex space-x-2 justify-center items-center w-40">
                                  <div className="flex justify-center items-center h-10 text-sm font-semibold">
                                    {pv.date}
                                  </div>
                                  <fieldset className="">
                                    {getDischargeSummary === true && (
                                      <CheckBoxField
                                        control={control}
                                        name={`dateCheck${pvIndex}`}
                                      />
                                    )}
                                  </fieldset>
                                </div>
                                <div className="px-1">
                                  <hr className="border-t-[1px] border-black" />
                                </div>
                                <div>
                                  {pv?.pathParameterValues?.map((v, vIndex) => (
                                    <div
                                      className={`border-b-[0.5px] border-[#E3E3E3] h-10 flex items-center`}
                                    >
                                      <div
                                        className={`${
                                          v.isValid === false
                                            ? "bg-[#FECCCC]"
                                            : ""
                                        } rounded-md mx-2 px-4 w-max`}
                                      >
                                        {v?.["Report Value"]}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
              )}
              {LIMSType === "Radiology" && (
                <CustomTabPanel
                  value={values}
                  index={LIMSType === "Radiology" ? 0 : 1}
                >
                  <div className="grid grid-cols-5 gap-x-2 min-h-[400px] max-h-full">
                    <div className="border border-b-gray-100 rounded-xl p-2 min-h-96 max-h-full space-y-2 bg-[#FFFCFA]">
                      <div className="text-customBlue font-semibold">
                        Investigation
                      </div>
                      <div className="py-0.5">
                        <hr className="border-t-[1px] border-black" />
                      </div>
                      <div>
                        <SearchDropdown
                          control={control}
                          name="investigation"
                          placeholder="Investigation"
                          searchIcon={true}
                        />
                      </div>
                      <div>
                        {radioHistoryData?.map((val, index) => (
                          <div
                            className={`${
                              selectedRadioTestIndex === index
                                ? "bg-[#FFC44B]"
                                : ""
                            } p-2 rounded-md cursor-pointer text-sm w-full flex space-x-2 items-center`}
                            onClick={() => {
                              setSelectedRadioTestIndex(index);
                              setSelectedRadioTest(val);
                              setRadioPrintPreview(null);
                              //   getRadiologytemplates(val?.testId);
                              setSelectedRadioReportIndex(null);
                            }}
                          >
                            {getDischargeSummary === true && (
                              <fieldset className="-my-2">
                                <CheckBoxField
                                  control={control}
                                  name={`testCheck${index}`}
                                />
                              </fieldset>
                            )}
                            {val?.testName}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="h-full space-y-2">
                      {selectedRadioTest?.Tests?.map((r, rIndex) => (
                        <div
                          onClick={() => {
                            setSelectedRadioReportIndex(rIndex);
                          }}
                          className={`${
                            selectedRadioReportIndex === rIndex
                              ? "bg-customBlue text-white"
                              : ""
                          } flex justify-between items-center border border-b-gray-100 rounded-xl p-2`}
                        >
                          <div>{r?.["Investigation Date"]}</div>
                          <button
                            className="bg-[#A1DCFE] text-customBlue h-[31px] w-20 rounded-md"
                            onClick={() => {
                              setIsReport(false);
                              setReportPdf("");
                              getPrintDetails(r.OrderDtlsId);
                            }}
                          >
                            Report
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="col-span-3 h-full border border-b-gray-100 rounded-xl p-2">
                      {radioPrintPreview !== null ? (
                        <RadiologyWorkOrderPrint
                          // selectedRow={selectedRow2}
                          // setOpenBackdrop={setOpenBackdrop}
                          dataResult={radioPrintPreview}
                          // isPrint={isPrint}
                          // setIsPrint={setIsPrint}
                          isWhatsApp={false}
                          isEmail={false}
                          isPreview={true}
                          // images={images}
                          // selectedRowIndex={selectedRowIndex}
                          // setSelectedRowIndex={setSelectedRowIndex}
                        />
                      ) : (
                        <div className="h-full w-full flex items-center justify-center">
                          <p className="font-semibold">Document Preview</p>
                        </div>
                      )}
                    </div>
                  </div>
                </CustomTabPanel>
              )}
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default LIMSHistoryModal;

