import { FormControl, TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { Controller } from "react-hook-form";
function TimePickerFieldNew({
  name,
  label,
  control,
  defaultValue,
  disabled,
  disablePast,
  disableFuture,
  sx,
  variant,
  inputProps,
  type,
  inputRef,
  inputFormat,
  error,
  dontCapitalize,
  color,
  onChange,
  value,
  ...rest
}) {
  
  return (
    <div className="w-full">
      <FormControl className="w-full" sx={sx}>
        <Controller
          sx={{ width: "100%" }}
          name={name}
          control={control}
          defaultValue={defaultValue}
          value={value}
          
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopTimePicker
                label={label}
                value={value}
                onChange={onChange}
                //  inputFormat={inputFormat}
                onBlur={onBlur}
                disabled={disabled}
                disablePast={disablePast}
                disableFuture={disableFuture}
                ampm={true}
                // ampmInClock={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        position: "absolute",
                        top: "-2px",
                      },
                    }}
                    sx={{
                      svg: {
                        color: "#1e3a8a",
                        height: 22,
                        width: "100%",
                        marginRight: "16px",
                      },
                      backgroundColor: "white",
                      overflow: "visible",

                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-input": {
                          // border: 0,
                          fontSize: 14,
                          height: "18px",
                          width: "100%",

                          borderColor: "  ",
                          overflow: "hidden",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  />
                )}
                {...rest}
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
    </div>
  );
}

export default TimePickerFieldNew;


// import { FormControl, TextField } from "@mui/material";
// import { DesktopTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import React from "react";
// import { Controller } from "react-hook-form";

// function TimePickerFieldNew({
//   name,
//   label,
//   control,
//   defaultValue,
//   disabled,
//   sx,
//   variant,
//   inputProps,
//   type,
//   inputRef,
//   error,
//   format24hr, // New prop to indicate whether to use 24-hour format
//   onChange,
//   minTime,
//   maxTime,
//   value,
//   onError,
//   ...rest
// }) {
//   return (
//     <div className="w-full bg-white">
//       <FormControl className="w-full" sx={sx} size="small">
//         <Controller
//           sx={{ width: "100%", height: "40px" }}
//           name={name}
//           control={control}
//           defaultValue={defaultValue}
//           render={({ field: { onChange, onBlur, value, ref } }) => (
//             <LocalizationProvider dateAdapter={AdapterDateFns}>
//               <DesktopTimePicker
//                 label={label}
//                 value={value}
//                 onChange={onChange}
//                 minTime={minTime}
//                 maxTime={maxTime}
//                 onBlur={onBlur}
//                 disabled={disabled}
//                 // viewRenderers={{
//                 //   hours: renderTimeViewClock,
//                 //   minutes: renderTimeViewClock,
//                 //   seconds: renderTimeViewClock,
//                 // }}
//                 renderInput={(params) => (
//                   <TextField
//                     onError={onError}
//                     error={error}
//                     {...params}
//                     size="small"
//                     fullWidth
//                   />
//                 )}
//                 // ampm={!format24hr} // Use 12-hour format if format24hr is false
//                 sx={{
//                   "& .css-1q3qg0t-MuiInputBase-root-MuiOutlinedInput-root": {
//                     height: "36px",
//                   },
//                   "& .css-4jar7x": {
//                     height: "36px",
//                   },
//                   "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
//                     fontSize: " 14px",
//                   },
//                   "& .css-1ayhp4g-MuiFormLabel-root-MuiInputLabel-root": {
//                     fontSize: " 14px",
//                   },
//                   "& .css-wnl09l-MuiFormLabel-root-MuiInputLabel-root": {
//                     top: "-9px",
//                   },
//                   "& .css-yp02p9": {
//                     top: "-9px",
//                   },
//                   "& .css-i4bv87-MuiSvgIcon-root": {
//                     fontSize: " 22px",
//                     color: "#073763",
//                   },
//                   "& .css-vubbuv": {
//                     fontSize: "22px",
//                     color: "#073763",
//                   },
//                 }}
//                 {...rest}
//               />
//             </LocalizationProvider>
//           )}
//         />
//       </FormControl>
//     </div>
//   );
// }

// export default TimePickerFieldNew;
