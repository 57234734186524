import WorkOrderGeneration from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
export const searchPatientList = (searchString, opdIpd) => {
  return WorkOrderGeneration.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const getWorkOrderGenInfo = (selectPatient) => {
  return WorkOrderGeneration.post(`/pathology/patientInfoWOG`, selectPatient, {
    headers: authHeader(),
  });
};

export const fetchAllServices = async (serviceObj) => {
  return WorkOrderGeneration.post(`/services/search`, serviceObj, {
    headers: authHeader(),
  });
};

export const saveNonPathOrderBooking = async (saveFinalObj) => {
  return WorkOrderGeneration.post(`/radiology/saveNonPathOrder`, saveFinalObj, {
    headers: authHeader(),
  });
};
