import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// /api/companySettlement/listOfPendingBill
export const getListOfOPDPedingBills = (listOfPedingBillObj) => {
  return apiClient.post(
    `/companySettlement/listOfPendingBill`,
    listOfPedingBillObj,
    {
      headers: authHeader(),
    }
  );
};

// /api/companySettlement/saveCompanySettlement
export const saveCompanyBillSettlement = (postObj) => {
  return apiClient.post(`/companySettlement/saveCompanySettlement`, postObj, {
    headers: authHeader(),
  });
};

///api/companySettlement/previousReceiptList
export const fetchPreviousReceiptList = (previousReceiptObj) => {
  return apiClient.post(
    `/companySettlement/previousReceiptList`,
    previousReceiptObj,
    {
      headers: authHeader(),
    }
  );
};

// print receipt api

export const ipdCompanySettlementPdfPrint = (paymentId) => {
  return apiClient.get(`/reports/bills/companySettlementReceipt/${paymentId}`, {
    headers: authHeader(),

    responseType: "blob",
  });
};
