import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
//Fields
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioFeild from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
//Table
import PurchaseOrderTable from "../common/PurchaseOrderTable";
//Modal
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import POListModal from "./POListModal";
//Button
import CommonButton from "../../../../common/components/Buttons/CommonButton";
//Services
import {
  addNewPurchaseOrder,
  fetchAllPurchaseOrderList,
  getDeliveryListDropdown,
  getFreightListDropdown,
  getModeOfPaymentDropdown,
  getTaxNatureListDropdown,
  getTermOfPaymentDropdown,
  poItemDetailsSearch,
  searchSupplier,
} from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
//Alert
import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";

const Tax = [
  {
    id: "Before Disc & Tax",
    value: "Before Disc & Tax",
    label: "Before Disc & Tax",
  },
  {
    id: "After Disc & Tax",
    value: "After Disc & Tax",
    label: "After Disc & Tax",
  },
];

const schedule = [
  {
    id: "For Next 3 Months As Per Tel. Confirmation",
    value: "For Next 3 Months As Per Tel. Confirmation",
    label: "For Next 3 Months As Per Tel. Confirmation",
  },
  {
    id: "Immediate",
    value: "Immediate",
    label: "Immediate",
  },
];

export default function PurchaseOrder() {
  //schema
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    schedule: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
  });

  //use form
  const defaultValues = {
    storeName: null,
    supplierName: null,
    freight: null,
    deliveryDate: null,
    paymentMode: null,
    paymentTerms: null,
    taxNature: null,
    searchItemCode: null,
    qty: "",
    uom: "",
    rate: Number(0),
    totalAmount: Number(0),
    discPercentage: Number(0),
    discAmount: Number(0),
    gstPercentage: Number(0),
    gstAmount: Number(0),
    netAmount: Number(0),
    specification: "",
    mrp: Number(0),
    guaranteewarranty: "",
    schedule: null,
    otherTax: 0,
    remark: "",
    tax: "After Disc & Tax",
  };

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    register,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [poListModal, setPOListModal] = useState(false);
  const handlePOListOpen = () => setPOListModal(true);
  const handlePOListClose = () => setPOListModal(false);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [finalData, setFinalData] = React.useState({});
  const [supplier, setSupplier] = React.useState([]);
  const [poListModalSupplierID, setPoListModalSupplierID] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState(null);
  const [freight, setFreight] = React.useState([]);
  const [deliveryDate, setDeliveryDate] = React.useState([]);
  const [modeOfPayment, setModeOfPayment] = React.useState([]);
  const [termOfPayment, setTermOfPayment] = React.useState([]);
  const [taxNature, setTaxNature] = React.useState([]);
  const [itemOptions, setItemOptions] = React.useState();

  const [poData, setPoData] = useState([]);

  //PO Table Main Listing
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [dataResult, setDataResult] = React.useState([]);
  const [selectedPOListFromDate, setSelectedPOListFromDate] = React.useState(
    new Date()
  );
  const [selectedPOListToDate, setSelectedPOListToDate] = React.useState(
    new Date()
  );
  const [isCancelled, setisCancelled] = React.useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = React.useState(2);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [privilege, setPrivilege] = React.useState("");

  const [userActions, setUserActions] = useState([]);

  const location = useLocation();
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  // const getStore = JSON.parse(localStorage.getItem("userInfo"));
  let taxVal = watch("tax");
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  let searchItemCode = watch("searchItemCode");
  let supplierName = watch("supplierName");
  let rate = watch("rate");
  let qty = watch("qty");
  let totalAmount = watch("totalAmount");
  let gstPercentage = watch("gstPercentage");
  let gstAmount = watch("gstAmount");
  let discPercentage = watch("discPercentage");
  let discAmount = watch("discAmount");
  let netAmount = watch("netAmount");
  let itemWatch = watch("searchItemCode");
  // let searchItemRef = useRef();

  const populatePOListTable = (forPagination) => {
    let defaultParams = {
      fromDate: selectedPOListFromDate,
      isAmmendment: false,
      isCancelled: isCancelled,
      isOrderPlaced: isOrderPlaced,
      itemId: 0,
      storeId: getUserInfo.storeId,
      supplierId: poListModalSupplierID,
      toDate: selectedPOListToDate,
      searchString: "",
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      menuId: location?.state?.menuId,
    };
    fetchAllPurchaseOrderList(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  React.useEffect(() => {
    setValue("storeName", getUserInfo.storeName);

    getFreightListDropdown().then((response) => {
      setFreight(response.data.result);
    });

    getDeliveryListDropdown().then((response) => {
      setDeliveryDate(response.data.result);
    });

    getModeOfPaymentDropdown().then((response) => {
      setModeOfPayment(response.data.result);
    });

    getTermOfPaymentDropdown().then((response) => {
      setTermOfPayment(response.data.result);
    });

    getTaxNatureListDropdown().then((response) => {
      setTaxNature(response.data.result);
    });

    populatePOListTable();
  }, []);

  React.useEffect(() => {
    if (searchItemCode !== null) {
      setValue("uom", searchItemCode.StockingUOM);
    } else if (searchItemCode === null) {
      setValue("uom", "");
    }

    if (searchItemCode !== null) {
      setValue("rate", searchItemCode.PurchaseRate);
    } else if (searchItemCode === null) {
      setValue("rate", 0);
    }

    if (searchItemCode !== null) {
      setValue("gstPercentage", searchItemCode.VAT);
    } else if (searchItemCode === null) {
      setValue("gstPercentage", 0);
    }

    if (searchItemCode !== null) {
      setValue("mrp", searchItemCode.MRP);
    } else if (searchItemCode === null) {
      setValue("mrp", 0);
    }

    if (searchItemCode !== null) {
      setValue("qty", 1);
    } else if (searchItemCode === null) {
      setValue("qty", 0);
    }
  }, [searchItemCode]);

  useEffect(() => {
    if (supplierName === null) {
      setSupplierId(null);
    }

    if (supplierName !== null) {
      setSupplierId(supplierName.id);
    } else if (supplierName !== null) {
      setSupplierId(0);
    }
  }, [supplierName]);

  const handleSupplierChange = (autoSearchSupplier) => {
    if (
      autoSearchSupplier !== "" &&
      UseCheckConsecutiveCharacters(autoSearchSupplier)
    ) {
      searchSupplier(autoSearchSupplier)
        .then((response) => response.data)
        .then((res) => {
          setSupplier(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  const handleChange = (autoSearchItemString) => {
    if (
      supplierId !== null &&
      UseCheckConsecutiveCharacters(autoSearchItemString)
    ) {
      let obj = {
        deptStoreId: getUserInfo.storeId,
        itemCode: "%",
        itemName: "%",
        searchString: autoSearchItemString,
        supplierId: supplierId,
      };
      poItemDetailsSearch(obj)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setItemOptions(res.result);
        });
    }
  };

  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.id === val.id);
      if (ind !== -1) {
        errorAlert("Item Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const addItemsDetailsData = () => {
    let searchItemCode = getValues("searchItemCode");
    let uom = getValues("uom");
    let specification = getValues("specification");
    let mrp = getValues("mrp");
    let QtyNumVal = Number(qty);

    if (
      searchItemCode !== null &&
      qty !== "" &&
      QtyNumVal !== 0 &&
      QtyNumVal > 0
    ) {
      let itemObj = {
        searchItemCode: {
          id: searchItemCode.id,
          label: searchItemCode.label,
        },
        quantity: qty,
        uom: uom,
        rate: rate ? rate : 0,
        totalAmount: Number(totalAmount).toFixed(2),
        discPercentage: discPercentage ? Number(discPercentage) : Number(0),
        discAmount: Number(discAmount).toFixed(2),
        gstPercentage: gstPercentage ? Number(gstPercentage) : Number(0),
        gstAmount: Number(gstAmount).toFixed(2),
        id: searchItemCode.id,
        netAmount: Number(netAmount).toFixed(2),
        specification: specification,
        mrp: mrp,
        doQty: qty,
        creditPeriod: "",
        expDeliveryDate: "2023-05-05T11:13:27.340Z",
        freeQty: 0,
        indentDetailsId: 0,
        isOrderPlaced: true,
        itemId: searchItemCode.id,
        itemManufacturedById: 0,
        markForClosed: false,
        pendQty: 0,
      };
      let arr = [...poData];
      arr.push(itemObj);
      setPoData(mergeArray(arr));
    }

    if (searchItemCode === null && qty === "") {
      setError("searchItemCode", { type: "custom", message: "Required" });
      setError("qty", { type: "custom", message: "Required" });
      setError("rate", { type: "custom", message: "Required" });
    } else if (searchItemCode === null) {
      setError("searchItemCode", { type: "custom", message: "Required" });
    } else if (qty === "") {
      setError("qty", { type: "custom", message: "Required" });
    } else if (rate === "") {
      setError("rate", { type: "custom", message: "Required" });
    }
  };

  function postPurchaseOrder(obj) {
    addNewPurchaseOrder(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populatePOListTable();
          reset(defaultValues);
          setPoData([]);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postPurchaseOrder(finalData);
  }

  let purchaseOrderDtlsDto = [];
  for (let poObj of poData) {
    let requiredObj = {
      amount: Number(poObj.totalAmount),
      creditPeriod: poObj.creditPeriod,
      discAmt: Number(poObj.discAmount),
      discPer: poObj.discPercentage,
      doQty: poObj.doQty,
      expDeliveryDate: poObj.expDeliveryDate,
      freeQty: poObj.freeQty,
      indentDetailsId: poObj.indentDetailsId,
      isOrderPlaced: poObj.isOrderPlaced,
      itemId: poObj.itemId,
      itemManufacturedById: poObj.itemManufacturedById,
      markForClosed: poObj.markForClosed,
      mrp: Number(poObj.mrp),
      netAmount: Number(poObj.netAmount),
      pendQty: poObj.pendQty,
      rate: Number(poObj.rate),
      specifications: poObj.specification,
      vatAmt: Number(poObj.gstAmount),
      vatPer: poObj.gstPercentage,
    };

    purchaseOrderDtlsDto.push(requiredObj);
  }

  let finalTotalAmount = 0;
  let finalNetAmount = 0;
  let finalDiscountAmount = 0;
  let finalGstAmount = 0;

  //calculate total amount and total net amount pass to main object
  for (var i = 0; i < purchaseOrderDtlsDto.length; i++) {
    finalTotalAmount += purchaseOrderDtlsDto[i]?.amount;
  }

  for (var i = 0; i < purchaseOrderDtlsDto.length; i++) {
    finalNetAmount += purchaseOrderDtlsDto[i].netAmount;
  }

  for (var i = 0; i < purchaseOrderDtlsDto.length; i++) {
    finalDiscountAmount += purchaseOrderDtlsDto[i].discAmt;
  }

  for (var i = 0; i < purchaseOrderDtlsDto.length; i++) {
    finalGstAmount += purchaseOrderDtlsDto[i].vatAmt;
  }

  const onSubmitDataHandler = (data) => {
    let postObj = {
      menuId: location.state.menuId,
      privilege: privilege,
      addedBy: getUserInfo.userId,
      // amendDate: "1900-01-01T00:00:00.000",
      amendDate: "2023-05-05T11:13:27.340Z",
      amendNo: "",
      closed: false,
      consignment: true,
      deliveryDt: data.deliveryDate ? data.deliveryDate.label : null,
      discAmount: Number(finalDiscountAmount).toFixed(2),
      doType: true,
      freight: data.freight ? data.freight.label : null,
      guaranteeWarranty: data.guaranteewarranty ? data.guaranteewarranty : null,
      isCancelled: false,
      isOld: false,
      isPrinted: false,
      modeOfPayment: data.paymentMode ? data.paymentMode.label : null,
      netAmount: Number(finalNetAmount).toFixed(2),
      octroi: "",
      otherTaxAmount: Number(data.otherTax).toFixed(2),
      purchaseOrderDtlsDto: purchaseOrderDtlsDto,
      remark: data.remark,
      schedule: data.schedule ? data.schedule.label : null,
      storeId: getUserInfo.storeId,
      supplierId: data.supplierName ? data.supplierName.id : null,
      taxNature: data.taxNature ? data.taxNature.label : null,
      termOfPayment: data.paymentTerms ? data.paymentTerms.label : null,
      totalAmount: Number(finalTotalAmount).toFixed(2),
      vatAmount: Number(finalGstAmount).toFixed(2),
      verified: false,
    };
    setOpenPost(true);
    setFinalData(postObj);
  };

  React.useEffect(() => {
    let arr = [];
    if (taxVal === "After Disc & Tax") {
      for (let obj of poData) {
        let discountedValue = Number(obj.totalAmount) - Number(obj.discAmount);
        obj.netAmount = Number(discountedValue) + Number(obj.gstAmount);
        arr.push(obj);
        setPoData(arr);
      }
    } else if (taxVal === "Before Disc & Tax") {
      for (let obj of poData) {
        obj.netAmount = Number(obj.totalAmount);
        arr.push(obj);
        setPoData(arr);
      }
    }
  }, [taxVal]);

  //Calculation
  useEffect(() => {
    let totalAmount = Number(rate) * Number(qty);
    setValue("totalAmount", Number(totalAmount).toFixed(2));

    let discountAmt = Number(totalAmount / 100) * Number(discPercentage);

    setValue("discAmount", Number(discountAmt).toFixed(2));

    let gstAmount = Number(totalAmount / 100) * Number(gstPercentage);
    setValue("gstAmount", Number(gstAmount).toFixed(2));

    let totalAmtMDiscountAmt = Number(totalAmount) - Number(discountAmt);
    let netAmount = totalAmtMDiscountAmt + Number(gstAmount);
    setValue("netAmount", Number(netAmount).toFixed(2));
  }, [qty, rate, discPercentage, gstPercentage]);

  function dontAllowOnKeyUpDownSpecialChar(e) {
    if (
      e?.key === "e" ||
      e?.key === "-" ||
      e?.key === "+" ||
      e?.code === "ArrowUp" ||
      e?.code === "ArrowDown" ||
      e?.code === "NumpadDecimal"
    ) {
      e.preventDefault();
    }
  }
  const supplierElement = useRef(null);
  const serviceElement = useRef(null);
  const qtyElement = useRef(null);
  const rateElement = useRef(null);
  const discPercentElement = useRef(null);
  const gstPercentElement = useRef(null);
  const mrpElement = useRef(null);
  const specificationElement = useRef(null);
  const addBtnElement = useRef(null);

  function focusFields(type) {
    let fieldsetTag;
    if (type === "searchItemCode") {
      fieldsetTag = serviceElement.current;
    }
    if (type === "qty") {
      fieldsetTag = qtyElement.current;
    }
    if (type === "rate") {
      fieldsetTag = rateElement.current;
    }
    if (type === "discPercentage") {
      fieldsetTag = discPercentElement.current;
    }
    if (type === "gstPercentage") {
      fieldsetTag = gstPercentElement.current;
    }
    if (type === "mrp") {
      fieldsetTag = mrpElement.current;
    }
    if (type === "specification") {
      fieldsetTag = specificationElement.current;
    }
    if (type === "addBtn") {
      fieldsetTag = addBtnElement.current;
    }
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  useEffect(() => {
    let fieldsetTag = supplierElement.current;
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }, []);

  return (
    <>
      <div className=" pt-12 px-1 w-full">
        <div className="text-center text-black font-bold text-xl py-2">
          Purchase Order
        </div>
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="grid grid-cols-4 gap-2 pb-3">
            <div className=" w-full">
              <InputField
                name="storeName"
                label="Store*"
                placeholder="Store*"
                control={control}
                error={errors.storeName}
                disabled={true}
              />
            </div>

            <div className=" w-full" ref={supplierElement}>
              <SearchDropdown
                control={control}
                error={errors.supplierName}
                name="supplierName"
                label="Supplier*"
                placeholder="Supplier*"
                searchIcon={true}
                isSearchable={true}
                isClearable={true}
                dataArray={supplier}
                handleInputChange={handleSupplierChange}
                inputRef={{
                  ...register("supplierName", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        focusFields("searchItemCode");
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.drugName}
                name="freight"
                placeholder="Freight"
                dataArray={freight}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.deliveryDate}
                name="deliveryDate"
                placeholder="Delivery Date"
                dataArray={deliveryDate}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.paymentMode}
                name="paymentMode"
                placeholder="Payment Mode"
                dataArray={modeOfPayment}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.paymentTerms}
                name="paymentTerms"
                placeholder="Payment Terms"
                dataArray={termOfPayment}
                isClearable={true}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.taxNature}
                name="taxNature"
                placeholder="Tax Nature"
                dataArray={taxNature}
              />
            </div>

            <div className="flex  justify-end">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Purchase Order" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customBlue text-white"
                        label="Purchase Order List"
                        onClick={() => {
                          handlePOListOpen();
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
          <hr className="w-full border-t-1 border-blue-800" />
          <div className="grid grid-cols-4 lg:grid-cols-7 xl:grid-cols-10 2xl:grid-cols-12 gap-3 mt-4 w-full">
            <div className="col-span-3 lg:col-span-4 flex gap-2 items-center">
              <label className="font-semibold text-lg whitespace-nowrap ">
                Item Details
              </label>
              <fieldset
                className="w-full"
                onClick={() => {
                  if (supplierId === null) {
                    warningAlert("Please select supplier first..!");
                  }
                }}
                ref={serviceElement}
              >
                <SearchDropdown
                  control={control}
                  searchIcon={true}
                  name="searchItemCode"
                  placeholder="Search Item Code"
                  isSearchable={true}
                  isClearable={true}
                  dataArray={itemOptions}
                  handleInputChange={handleChange}
                  error={errors.searchItemCode}
                  inputRef={{
                    ...register("searchItemCode", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          focusFields("qty");
                        }
                      },
                    }),
                  }}
                />
              </fieldset>
            </div>
            <div
              onKeyDown={(e) => {
                dontAllowOnKeyUpDownSpecialChar(e);
              }}
              ref={qtyElement}
            >
              <InputField
                name="qty"
                label=" Qty"
                type="number"
                placeholder="Qty"
                control={control}
                error={errors.qty}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    focusFields("rate");
                  }
                }}
              />
            </div>
            <div>
              <InputField
                name="uom"
                label=" UOM"
                placeholder="UOM"
                control={control}
                error={errors.uom}
                disabled={true}
              />
            </div>
            <div
              className="w-full"
              onKeyDown={(e) => {
                dontAllowOnKeyUpDownSpecialChar(e);
              }}
              ref={rateElement}
            >
              <InputField
                type="number"
                name="rate"
                label=" Rate"
                placeholder="Rate"
                control={control}
                error={errors.rate}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    focusFields("discPercentage");
                  }
                }}
              />
            </div>
            <div className="w-full">
              <InputField
                name="totalAmount"
                label="Total Amount"
                placeholder="Total Amount"
                control={control}
                error={errors.totalAmount}
                disabled={true}
              />
            </div>
            <div
              className=""
              onKeyDown={(e) => {
                dontAllowOnKeyUpDownSpecialChar(e);
              }}
              ref={discPercentElement}
            >
              <InputField
                type="number"
                name="discPercentage"
                label="Disc %"
                placeholder="Disc %"
                control={control}
                error={errors.uom}
                inputRef={{
                  ...register("discPercentage", {
                    onChange: (e) => {
                      if (e.target.value === "") {
                        setValue("discPercentage", Number(0));
                      }
                      if (e.target.value > 100) {
                        setValue("discPercentage", Number(100));
                      }
                    },
                  }),
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    focusFields("gstPercentage");
                  }
                }}
              />
            </div>
            <div className="w-full">
              <InputField
                name="discAmount"
                label="Disc Amount"
                placeholder="Disc Amount"
                control={control}
                error={errors.discAmount}
                disabled={true}
              />
            </div>
            <div
              className=""
              onKeyDown={(e) => {
                dontAllowOnKeyUpDownSpecialChar(e);
              }}
              ref={gstPercentElement}
            >
              <InputField
                type="number"
                name="gstPercentage"
                label="GST %"
                placeholder="GST %"
                control={control}
                error={errors.gstPercentage}
                inputRef={{
                  ...register("gstPercentage", {
                    onChange: (e) => {
                      if (e.target.value === "") {
                        setValue("gstPercentage", Number(0));
                      }
                      if (e.target.value > 100) {
                        setValue("gstPercentage", Number(100));
                      }
                    },
                  }),
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    focusFields("mrp");
                  }
                }}
              />
            </div>
            <div className="w-full">
              <InputField
                name="gstAmount"
                label="GST Amount"
                placeholder="GST Amount"
                control={control}
                error={errors.gstAmount}
                disabled={true}
              />
            </div>
            <div className="w-full">
              <InputField
                name="netAmount"
                label="Net Amount"
                placeholder="Net Amount"
                control={control}
                error={errors.netAmount}
                disabled={true}
              />
            </div>
            <div
              className="w-full"
              onKeyDown={(e) => {
                dontAllowOnKeyUpDownSpecialChar(e);
              }}
              ref={mrpElement}
            >
              <InputField
                type="number"
                name="mrp"
                label="MRP"
                placeholder="MRP"
                control={control}
                error={errors.mrp}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    focusFields("specification");
                  }
                }}
              />
            </div>
            <div
              className="col-span-2 "
              ref={specificationElement}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  addBtnElement.current.focus();
                }
              }}
            >
              <InputField
                name="specification"
                label="Specification"
                placeholder="Specification"
                control={control}
                error={errors.specification}
              />
            </div>
            <div className="">
              <CommonButton
                referance={addBtnElement}
                type="button"
                className="bg-customGreen text-white"
                label="Add"
                onClick={() => {
                  addItemsDetailsData();
                  if (searchItemCode !== null && qty !== "") {
                    setValue("searchItemCode", null);
                    setValue("uom", "");
                    setValue("qty", "");
                    setValue("rate", Number(0));
                    setValue("totalAmount", Number(0));
                    setValue("discPercentage", Number(0));
                    setValue("discAmount", Number(0));
                    setValue("gstPercentage", Number(0));
                    setValue("gstAmount", Number(0));
                    setValue("netAmount", Number(0));
                    setValue("specification", "");
                    setValue("mrp", Number(0));
                  }
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    addItemsDetailsData();
                    if (searchItemCode !== null && qty !== "") {
                      setValue("searchItemCode", null);
                      setValue("uom", "");
                      setValue("qty", "");
                      setValue("rate", Number(0));
                      setValue("totalAmount", Number(0));
                      setValue("discPercentage", Number(0));
                      setValue("discAmount", Number(0));
                      setValue("gstPercentage", Number(0));
                      setValue("gstAmount", Number(0));
                      setValue("netAmount", Number(0));
                      setValue("specification", "");
                      setValue("mrp", Number(0));
                    }
                    e.preventDefault();
                    focusFields("searchItemCode");
                  }
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <PurchaseOrderTable poData={poData} setPoData={setPoData} />
          </div>
          <div className="grid grid-cols-4 gap-2 py-2">
            <div className=" w-full">
              <InputField
                control={control}
                name="guaranteewarranty"
                label="Guarantee / Warranty"
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.schedule}
                name="schedule"
                placeholder="Schedule"
                dataArray={schedule}
              />
            </div>

            <div>
              <InputField
                control={control}
                name="otherTax"
                label="Other Tax"
                type="number"
              />
            </div>
            <div className="">
              <InputField control={control} name="remark" label="Remark" />
            </div>

            <div className="w-full">
              <div className="flex w-full pt-1 justify-center">
                <RadioFeild control={control} name="tax" dataArray={Tax} />
              </div>
            </div>

            <div className="col-span-3 grid grid-cols-3  gap-2 py-2">
              <div className="flex gap-2 text-sm">
                <h1 className="text-black font-semibold flex space-x-2">
                  <span className="">Total Disc Amt </span>
                  <span className=""> : ₹</span>
                </h1>
                <h1 className="font-normal">
                  {Number(finalDiscountAmount).toFixed(2)}
                </h1>
              </div>

              <div className="flex gap-2 text-sm">
                <h1 className="text-black font-semibold flex space-x-2">
                  <span className="">Total GST Amt </span>
                  <span className=""> : ₹</span>
                </h1>

                <h1 className="font-normal">
                  {Number(finalGstAmount).toFixed(2)}
                </h1>
              </div>

              <div className="flex gap-2 text-sm">
                <h1 className="text-black font-semibold flex space-x-2 lg:space-x-2">
                  <span className="whitespace-nowrap">Total Net Amt</span>
                  <span className=""> : ₹</span>
                </h1>
                <h1 className="font-normal">
                  {Number(finalNetAmount).toFixed(2)}
                </h1>
              </div>
            </div>
          </div>
          <div className="flex justify-end py-2 gap-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                        setPoData([]);
                        setSupplierId(null);
                      }}
                    />
                    <CommonButton
                      type="submit"
                      className="bg-customGreen text-white"
                      label="Save"
                      onClick={() => {
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </form>
        <CommonBackDrop openBackdrop={openBackdrop} />
      </div>
      {poListModal === true ? (
        <POListModal
          poListModal={poListModal}
          setPoListModalSupplierID={setPoListModalSupplierID}
          getUserInfo={getUserInfo}
          handlePOListClose={handlePOListClose}
          setisCancelled={setisCancelled}
          setIsOrderPlaced={setIsOrderPlaced}
          populatePOListTable={populatePOListTable}
          selectedPOListFromDate={selectedPOListFromDate}
          setSelectedPOListFromDate={setSelectedPOListFromDate}
          selectedPOListToDate={selectedPOListToDate}
          setSelectedPOListToDate={setSelectedPOListToDate}
          //Table
          dataResult={dataResult}
          setDataResult={setDataResult}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          count={count}
          isCancelled={isCancelled}
          isOrderPlaced={isOrderPlaced}
          poListModalSupplierID={poListModalSupplierID}
          userActions={userActions}
          privilege={privilege}
          setPrivilege={setPrivilege}
          menuId={location?.state?.menuId}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
