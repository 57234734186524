import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { OperationNotesPrintData } from "../services/otDetailsServices/OtDetailsServices";

export default function OtNoteTwo(props) {
  const printRef = useRef(null);
  const [dataResult, setDataResult] = React.useState();

  React.useEffect(() => {
    OperationNotesPrintData(props?.otDetailsId?.OTHeaderID)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" flex justify-end">
            <InamdarHospiLogo />
          </div>
          <div className=" text-center font-semibold border-t-[1px] border-black p-1  text-[13px] ">
            OPERATION NOTES
          </div>
          <div>
            <div className=" border-b-[1px] p-1 border-t-[1px] border-black grid grid-cols-[15%_1%_34%_15%_1%_34%]">
              <div className=" font-semibold text-[11px]">MR No.</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.MRNo}</div>
              <div className=" font-semibold text-[11px]">Patient Name</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px] ">{dataResult?.PatientName}</div>
              <div className=" font-semibold text-[11px]">OT Sheet.No</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.OTSheetNo}</div>
              <div className=" font-semibold text-[11px]">Bed No</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.BedNo}</div>
              <div className=" font-semibold text-[11px]">Admission Date</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.AdmissionDate}</div>
              <div className=" font-semibold text-[11px]">OT Date</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.OTDate}</div>
            </div>
          </div>
        </thead>
        <tbody className="w-full">
          <div className=" text-[11px] w-full">
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
            <p className=" border-b-[1px] border-black pt-10" />
          </div>
        </tbody>
      </table>
    </div>
  );
}
