import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { Divider } from "@mui/material";
import {
  deleteNursingNote,
  getAllNursingNote,
  getNursingNoteDescription,
} from "../../../services/cliinicalChartServices/nursingnoteServices/NursingNoteServices";
import {
  deleteAlert,
  errorAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  DeleteOnIcon,
  PrintOnIcon,
} from "../../../../assets/icons/CustomIcons";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import { format } from "date-fns";

const NursingNoteData = [
  { id: "All", value: "All", label: "All" },
  { id: "Doctor", value: "Doctor", label: "Doctor" },
  { id: "Nursing", value: "Nursing", label: "Nursing" },
];

function NursingNote(props) {
  let admissionId = props.displayInfo?.AdmissionId || props.admissionId;
  const [spinner, showSpinner] = useState(false);
  const [nursingDataResult, setNursingDataResult] = useState([]); // populate table data
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [nrsingDescData, setNrsingDescData] = React.useState(null);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);
  console.log("nrsingDescData", nrsingDescData);
  let orderSheetId = props.noteInfo?.ordersheetId;

  const defaultValues = {
    All: null,
    Doctor: null,
    Nursing: null,
  };
  const { control, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let userType = watch("NursingNoteData");
  let filter = "";

  if (userType === "All") {
    filter = "All";
  } else if (userType === "Doctor") {
    filter = "Doctor";
  } else if (userType === "Nursing") {
    filter = "Nursing";
  }

  useEffect(() => {
    setValue("NursingNoteData", "All");
  }, []);

  useEffect(() => {
    console.log(
      userType,
      props.fromDate,
      props.toDate,
      props.fromTime,
      props.toTime
    );
    !!userType &&
      props.fromDate &&
      props.toDate &&
      props.fromTime &&
      props.toTime &&
      populateTable();
  }, [
    props.displayInfo?.AdmissionId || props.admissionId,
    userType,
    props.fromDate,
    props.toTime,
    props.fromTime,
    props.toDate,
  ]);

  const populateTable = () => {
    console.log("userType", userType);
    setNrsingDescData(null);

    showSpinner(true);

    // fromDateVal={fromDateVal}
    // toDateVal={toDateVal}
    // fromTime={fromTime}
    // toTime={toTime}
    //   admissionId: admissionId || props.admissionId,
    //   fromDate: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    //   toDate: format(toDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    //   fromTime: format(fromTime, "HH:mm:ss.000"),
    //   toTime: format(toTime, "HH:mm:ss.000"),

    let listObj = {
      admissionId: props.displayInfo?.AdmissionId || props.admissionId,
      fromDate: format(props.fromDate, "yyyy-MM-dd"),
      toTime: format(props.toTime, "HH:mm:ss.000"),
      fromTime: format(props.fromTime, "HH:mm:ss.000"),
      toDate: format(props.toDate, "yyyy-MM-dd"),
      type: userType,
      isOldPatient: props.isOldPatientValue,
    };
    // 18:34:24.000
    getAllNursingNote(listObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setNursingDataResult(res.result);
      })
      .catch((error) => {
        errorAlert(error.message);
        showSpinner(false);
      });
  };

  React.useEffect(() => {
    if (orderSheetId && admissionId) {
      nursingNoteDesc(orderSheetId);
    }
  }, [orderSheetId, admissionId]);

  const nursingNoteDesc = (orderSheetId) => {
    getNursingNoteDescription(orderSheetId)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        const updatedResult = Object.entries(res.result).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
        setNrsingDescData(res.result);
      });
  };

  const handleSelectedRow = (row, index) => {
    props.setNoteInfo(row);
  };

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deleteNursingNote(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            populateTable();
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true &&
            obj.action === "Delete"  ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    setNrsingDescData(null);
                    props.setNoteInfo(null);

                    let deleteObjVal = {};

                    deleteObjVal.Id = row.ID;

                    deleteObjVal.action = obj.action;

                    setDeleteObj(deleteObjVal);
                    handleOpenDeleteConfirm();
                  }}
                />

                <PrintOnIcon
                  title={"Print"}
                  onClick={() => {
                    props?.setNursingNoteId(row?.ID)
                    props?.setOpenPrintModal(true)}}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="w-full">
        <h1 className="text-sm font-semibold whitespace-nowrap">
          Nursing Note
        </h1>
        <div className=" flex">
          <RadioField
            label=""
            name="NursingNoteData"
            control={control}
            dataArray={NursingNoteData}
          />
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div
          className={
            !!props.noteInfo && props.noteInfo?.ordersheetId
              ? "mt-9 col-span-1"
              : "mt-9 col-span-3"
          }
        >
          {spinner ? (
            <div className="grid justify-center">
              <LoadingSpinner />
            </div>
          ) : nursingDataResult.length > 0 ? (
            <CommonDynamicTableNew
              dataResult={nursingDataResult}
              tableClass={"rounded h-[240px] 2xl:h-60 border"}
              highlightRow={true}
              handleSelectedRow={handleSelectedRow}
              removeHeaders={[
                "Message",
                "Special Note",
                "Mews",
                "ID",
                "ordersheetId",
                "doctorid",
                "PatientID",
                "UserID",
                "AddmissionID",
                "phlebitis",
                "visitId",
              ]}
              renderActions={renderActions}
            />
          ) : (
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </div>
        {!!props.noteInfo && props.noteInfo?.ordersheetId ? (
          <div className="ml-3  h-[10%] col-span-2">
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="">
              {/*h-[20%] overflow-y-scroll*/}
              <h1 className=" text-lg font-semibold">Notes</h1>
              {!!nrsingDescData && (
                <div className="grid gap-y-3 lg:h-96 2xl:h-[700px] overflow-y-auto">
                  {nrsingDescData.Subjective ? (
                    <div className="border rounded bg-white">
                      <h1
                        className="p-2 text-lg font-semibold rounded "
                        style={{ backgroundColor: "#FFE1C6" }}
                      >
                        Subjective
                      </h1>
                      <p className="p-2 whitespace-pre-wrap break-words">
                        {nrsingDescData?.Subjective}
                      </p>
                    </div>
                  ) : null}

                  {(userType === "All" || userType === "Doctor") &&
                  (nrsingDescData?.temp ||
                    nrsingDescData?.bp ||
                    nrsingDescData?.pulse ||
                    nrsingDescData?.mews ||
                    nrsingDescData?.SPO2 ||
                    nrsingDescData?.CVS ||
                    nrsingDescData?.RS ||
                    nrsingDescData?.PA ||
                    nrsingDescData?.cns ||
                    nrsingDescData?.LocalExamination ||
                    nrsingDescData?.Other) ? (
                    <div className="border rounded bg-white">
                      <h1
                        className="p-2 text-lg font-semibold rounded "
                        style={{ backgroundColor: "#D4EDFF" }}
                      >
                        Objective
                      </h1>

                      <div className="p-2 grid gap-2">
                        <div className="flex space-x-2 items-center">
                          {(nrsingDescData?.temp ||
                            nrsingDescData?.bp ||
                            nrsingDescData?.pulse ||
                            nrsingDescData?.rr) && <h1>Vitals &nbsp; :</h1>}

                          {nrsingDescData?.temp ? (
                            <div className="flex gap-1 text-gray-600 text-sm">
                              <span className="">Temp :</span>
                              <span className="whitespace-pre-wrap break-words">
                                {nrsingDescData?.temp}
                              </span>
                            </div>
                          ) : null}
                          {nrsingDescData?.bp ? (
                            <div className="flex gap-1 text-gray-600 text-sm">
                              <span className="">BP :</span>
                              <span className="whitespace-pre-wrap break-words">
                                {nrsingDescData?.bp}
                              </span>
                            </div>
                          ) : null}
                          {nrsingDescData?.pulse ? (
                            <div className="flex gap-1 text-gray-600 text-sm">
                              <span className="">Pulse :</span>
                              <span className="whitespace-pre-wrap break-words">
                                {nrsingDescData?.pulse}
                              </span>
                            </div>
                          ) : null}
                          {nrsingDescData?.rr ? (
                            <div className="flex gap-1 text-gray-600 text-sm">
                              <span className="">RR :</span>
                              <span className="whitespace-pre-wrap break-words">
                                {nrsingDescData?.rr}
                              </span>
                            </div>
                          ) : null}
                          {nrsingDescData?.mews ? (
                            <div className="flex gap-1 text-gray-600 text-sm">
                              <span className="">MEWS :</span>
                              <span className="whitespace-pre-wrap break-words">
                                {nrsingDescData?.mews}
                              </span>
                            </div>
                          ) : null}
                          {nrsingDescData?.SPO2 ? (
                            <div className="flex gap-1 text-gray-600 text-sm">
                              <span className="">SPO2 :</span>
                              <span className="whitespace-pre-wrap break-words">
                                {nrsingDescData?.SPO2}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        {nrsingDescData?.CVS ? (
                          <div className="flex space-x-2">
                            <div className="w-40">CVS </div>
                            <span className="">:&nbsp;</span>
                            <span className="whitespace-pre-wrap break-words  ">
                              {nrsingDescData?.CVS}
                            </span>
                          </div>
                        ) : null}
                        {nrsingDescData?.RS ? (
                          <div className="flex space-x-2">
                            <h1 className="w-40">RS</h1>
                            <span>:&nbsp;</span>
                            <span className="whitespace-pre-wrap break-words">
                              {nrsingDescData?.RS}
                            </span>
                          </div>
                        ) : null}
                        {nrsingDescData?.PA ? (
                          <div className="flex space-x-2">
                            <h1 className="w-40">P/A</h1>
                            <span>:&nbsp;</span>
                            <span className="whitespace-pre-wrap break-words">
                              {nrsingDescData?.PA}
                            </span>
                          </div>
                        ) : null}
                        {nrsingDescData?.cns ? (
                          <div className="flex space-x-2">
                            <h1 className="w-40">CNS</h1>
                            <span>:&nbsp;</span>
                            <span className="whitespace-pre-wrap break-words">
                              {nrsingDescData?.cns}
                            </span>
                          </div>
                        ) : null}
                        {nrsingDescData?.LocalExamination ? (
                          <div className="flex space-x-2">
                            <h1 className="w-40">Local Examination</h1>
                            <span>:&nbsp;</span>
                            <span className="whitespace-pre-wrap break-words">
                              {nrsingDescData?.LocalExamination}
                            </span>
                          </div>
                        ) : null}
                        {nrsingDescData?.Other ? (
                          <div className="flex space-x-2">
                            <h1 className="w-40">Other</h1>
                            <span>:&nbsp;</span>
                            <span className="whitespace-pre-wrap break-words">
                              {nrsingDescData?.Other}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {nrsingDescData?.Assesment ? (
                    <div className="border rounded bg-white">
                      <h1
                        className="p-2 text-lg font-semibold rounded "
                        style={{ backgroundColor: "#D3F8C6" }}
                      >
                        Assessment
                      </h1>
                      <p className="p-2 whitespace-pre-wrap break-words">
                        {nrsingDescData?.Assesment}
                      </p>
                    </div>
                  ) : null}
                  {nrsingDescData?.pain ? (
                    <div className="border rounded bg-white">
                      <h1
                        className="p-2 text-lg font-semibold rounded "
                        style={{ backgroundColor: "#FFC7DB" }}
                      >
                        Plan
                      </h1>
                      <p className="p-2 ">
                        <p className="p-2  whitespace-pre-wrap break-words">
                          {nrsingDescData?.pain}
                        </p>
                      </p>
                    </div>
                  ) : null}
                  {nrsingDescData?.SpecialNote ? (
                    <div className="border rounded bg-white">
                      <h1
                        className="p-2 text-lg font-semibold rounded "
                        style={{ backgroundColor: "#FFDBC7" }}
                      >
                        CPR Notes
                      </h1>
                      <p className="p-2 ">
                        <p className="p-2  whitespace-pre-wrap break-words">
                          {nrsingDescData?.SpecialNote}
                        </p>
                      </p>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        ) : null}
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={deleteconfirm}
          confirmationHandleClose={handleCloseDeleteConfirm}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this record ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}

export default NursingNote;
