
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  FormControl,
  TableCell,
  Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  pathParameterDropdownSearch,
  templateDropdownSearch,
} from "../../../services/pathology/Test/TestCreationService";
import ParameterTab from "./ParameterTab";
// import ParameterTab from "./ParameterTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const {
    control,
    watch,
    handleSubmit,
    register,
    reset,
    setValue,
    errors,
    getValues,
  } = useFormContext();

  //State
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [templateOption, setTemplateOption] = useState([]);

  // Watch Value
  let templateWatch = watch("template");

  // Tab Change Function
  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  //Template Dropdown AutoSearch Api
  const handleChangeTemplate = (autoSearchString) => {
    console.log("autoSearchString", autoSearchString);
    autoSearchString &&
      templateDropdownSearch(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          console.log("resItem", res.result);
          setTemplateOption(res.result);
        });
  };

  //Path Parameter Dropdown AutoSearch Api


  //Post Obj Parameter


  //Post Obj Parameter And Sub test
  // const handleOnSubTestsubmit = () => {
  //   let obj = {
  //     subTestName: subTestName,
  //     // parameterId: parameterNameWatch?.id,
  //     // parameterName: parameterNameWatch?.label,
  //     Parameters: [],
  //   };
  //   let arr = [];
  //   let parameterObj = {
  //     parameterId: parameterNameWatch?.id,
  //     parameterName: parameterNameWatch?.label,
  //   };
  //   obj["Parameters"].push(parameterObj);
  //   arr.push(obj);
  //   console.log("arr78787", arr);
  //   props.setFinalData(arr);

  //   props.setFinalData((prev) => {
  //     prev.map((item) =>
  //       item.subTestName === obj.subTestName
  //         ? obj["Parameters"].push(parameterObj)
  //         : arr.push(obj)
  //     );
  //   });
  // };





  // Post Obj Template
  const handleSubmitTemplate = () => {
    let arr = [...props.templateData];
    arr.push({
      template: templateWatch?.label,
      id: templateWatch?.id,
    });
    console.log("arrarr123", arr);
    props.setTemplateData(arr);
  };



  //Delete Row Function
  const deleteRecord = (index, header) => {
    let numTempleData = [];
    if (index !== null) {
      if (header === "templateData") {
        numTempleData = [...props.templateData];
        numTempleData.splice(index, 1);
        props.setTemplateData(numTempleData);
      }
    }
  };



  //Render Action Template
  const RenderActionTemplate = (row, index, header) => {
    return (
      <div className="flex ">
        <div>
          <Tooltip title="Delete">
            <div
              className="text-red-500 mr-3"
              onClick={() => {
                deleteRecord(index, "templateData");
              }}
            >
              {<DeleteOutlineOutlinedIcon />}
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };



  //Reset Function  Template
  const handleResetTemplate = () => {
    setValue("template", null);
  };


  console.log("ReportTemplate", props.finalData);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div className="flex justify-between w-full">
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#1e3a8a",
                height: 3,
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#1e3a8a",
              },
              backgroundColor: "#f1f1f1",
            }}
          >
            <Tab
              label={
                <div className="flex space-x-2">
                  <p className="capitalize mt-1"> Details</p>
                </div>
              }
              {...a11yProps(0)}
            ></Tab>

            <Tab
              disabled={props.ReportTemplate === true}
              label={
                <div className="flex space-x-2">
                  <p className="capitalize mt-1">Parameters</p>
                </div>
              }
              {...a11yProps(1)}
            />

            <Tab
              disabled={props.ReportTemplate === false}
              label={
                <div className="flex space-x-2">
                  <p className="capitalize mt-1"> Template Details</p>
                </div>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </div>
      </Box>
      {/* Details Tab */}
      {currentTabIndex === 0 && (
        <div>
          <div className="grid lg:grid-cols-4 md:grid-cols-4 pt-2 gap-3   px-5 lg:px-2">
            <div>
              <InputField
                control={control}
                name="suggestionNote"
                label="Suggestion / Note"
              />
            </div>

            <div>
              <InputField control={control} name="footNote" label="Foot Note" />
            </div>

            <div>
              <DropdownField
                control={control}
                name="machineName"
                placeholder="Machine Name"
              />
            </div>
            <div>
              <InputField
                type="Number"
                control={control}
                name="timeCompletion"
                label="Time Completion (In Min)"
              />
            </div>

            <div>
              <InputField
                control={control}
                name="techniqueUsed"
                label="Technique Used"
              />
            </div>

            <div>
              <CheckBoxField
                control={control}
                name="cultureSensitivityTest"
                label="Culture Sensitivity Test"
              />
            </div>
            <div>
              <CheckBoxField
                control={control}
                name="hasAuthentication"
                label="Has Authentication"
              />
            </div>
            <div>
              <CheckBoxField
                control={control}
                name="isConsent"
                label="Is Consent"
              />
            </div>

            <div>
              <CheckBoxField
                control={control}
                name="uniqueSampleNoRequired"
                label="Unique Sample No Required"
              />
            </div>
            <div>
              <CheckBoxField
                control={control}
                name="isHistopathTest"
                label="Is Histopath Test"
              />
            </div>
          </div>
        </div>
      )}

      {/* Parameter Details Tab */}
      {currentTabIndex === 1 && (
        <ParameterTab
          finalData={props.finalData}
          setFinalData={props.setFinalData}
          parameterData={props.parameterData}
          setParameterData={props.setParameterData}
        />
      )}
      {/* Template Details Tab */}
      {currentTabIndex === 2 && (
        <div>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-2 pt-2">
            <div>
              <SearchDropdown
                handleInputChange={handleChangeTemplate}
                searchIcon={true}
                dataArray={templateOption}
                control={control}
                name="template"
                placeholder="Template"
              />
            </div>
            <div>
              <CommonButton
                label=" Add "
                className="bg-green-500 text-white"
                type="button"
                onClick={() => {
                  handleSubmitTemplate();
                  handleResetTemplate();
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1">
            {props.templateData.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={props.templateData}
                renderActions={RenderActionTemplate}
                highlightRow={false}
                removeHeaders={["id", "value"]}
                tableClass={"h-96"}
              />
            ) : null}
          </div>
        </div>
      )}
    </Box>
  );
}
