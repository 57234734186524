import * as React from "react";

//imports from material ui library
import { Box, Modal, Tooltip } from "@mui/material";

//icon for closing the modal form
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import { useForm } from "react-hook-form";

import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

import CommonButton from "../../../common/components/Buttons/CommonButton";

import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";

import {
  getBillPrint,
  getPatientBillList,
  searchPatientForBillList,
} from "../../services/BillingServices";

import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";

import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import { useLocation } from "react-router-dom";
import ReasonForBillCancellation from "./ReasonForBillCancellation";

import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";

import { CancelOnIcon } from "./../../../assets/icons/CustomIcons";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  // height: "80%",
  overflowY: "scroll",
  overflowX: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

export default function BillListingModal(props) {
  const location = useLocation();
  const defaultValues = {
    opdbillingSearch: null,
    FromDate: new Date(),
    ToDate: new Date(),
  };

  const { control, getValues, setValue, register, watch } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //Below are the props required for the
  //CommonDynamicTablePagination component
  //--------------------------------------------------------------------------------

  const [userActions, setUserActions] = React.useState(null);

  const [count, setCount] = React.useState();

  const [page, setPage] = React.useState(0);

  const [dataResult, setDataResult] = React.useState([]);

  const [searchString, setSearchString] = React.useState("");

  //State variable to get the page size from the child component.
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const [urlforPrint, setUrlforPrint] = React.useState();

  const [billCancellationModal, setBillCancellationModal] = React.useState(
    false
  );

  const [billId, setBillId] = React.useState(null);

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  //function to return the date in yyyy-MM-DD format
  function getRequiredDateVal(inputDateVal) {
    let requiredDate = null;

    let objectDate = new Date(inputDateVal);

    let day = String(objectDate.getDate());

    if (day.length === 1) {
      day = `0${day}`;
    }

    let month = objectDate.getMonth();

    month = month + 1;

    month = String(month);

    if (month.length === 1) {
      month = `0${month}`;
    }

    let year = objectDate.getFullYear();

    requiredDate = `${year}-${month}-${day}`;

    if (requiredDate.length === 10) {
      return requiredDate;
    } else {
      return null;
    }
  }

  //populate the CommonMasterTable using the function populateTable
  const populateTable = (forPagination) => {
    let patientInfoObj = getValues("opdbillingSearch");

    let patientIdVal = 0;

    if (patientInfoObj) {
      patientIdVal = patientInfoObj.id;
    }

    let searchObj = {
      patientId: patientIdVal,
      fromDate: null,
      opdIpd: 0,
      page: !forPagination ? 0 : page,
      searchString: searchString,
      size: rowsPerPage,
      toDate: null,
    };

    let fromDateValue = getValues("FromDate");

    let toDateValue = getValues("ToDate");

    fromDateValue = getRequiredDateVal(fromDateValue);

    toDateValue = getRequiredDateVal(toDateValue);

    if (fromDateValue) {
      let d = new Date(fromDateValue);
      d = d.toISOString();
      searchObj.fromDate = d;
    }

    if (toDateValue) {
      let d = new Date(toDateValue);
      d = d.toISOString();
      searchObj.toDate = d;
    }

    getPatientBillList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setPage(0);
          setDataResult(res.result);
        }
        setCount(res.count);
      })
      .catch((error) => {});
  };

  //------------------------------------------------------------------------------------

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleOpenPrintModal = (billIdVal) => {
    setOpenBackdrop(true);

    getBillPrint(billIdVal)
      .then((response) => {
        setOpenBackdrop(false);

        setOpenPrintModal(true);

        setUrlforPrint(response);
      })
      .catch(() => {
        setOpenBackdrop(false);
      });
  };

  //Below is the renderActions function
  const renderActions = (row, index) => {
    // #4b5563
    return (
      <div className="flex items-center	justify-start gap-1 ">
        {userActions &&
          userActions.map((singleActionObj) => (
            <>
              {singleActionObj.action === "Print" &&
              singleActionObj.isAction === true ? (
                <>
                  <Tooltip title="Print Bill Receipt">
                    <LocalPrintshopIcon
                      sx={
                        row.IsCancelled === true
                          ? { color: "#71797E" }
                          : { color: "#4B5563" }
                      }
                      onClick={() => {
                        let billId = row.BillId;

                        if (row.IsCancelled === false) {
                          handleOpenPrintModal(billId);
                        }
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                ""
              )}

              {singleActionObj.action === "Cancel" &&
              singleActionObj.isAction === true ? (
                <>
                  <CancelOnIcon
                    title="Cancel Bill"
                    onClick={() => {
                      let billIdVal = row.BillId;

                      if (row.IsCancelled === false) {
                        setBillId(billIdVal);
                        setBillCancellationModal(true);
                      }
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </>
          ))}
      </div>
    );
  };

  //---------------------------------------------

  //state variable to populate the "opdbillingSearch" SearchDropdown
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let patientInfoObj = getValues("opdbillingSearch");

    let patientIdVal = 0;

    if (patientInfoObj) {
      patientIdVal = patientInfoObj.id;
    }

    let searchObj = {
      patientId: patientIdVal,
      fromDate: new Date(),
      opdIpd: 0,
      page: 0,
      searchString: searchString,
      size: rowsPerPage,
      toDate: new Date(),
    };

    getPatientBillList(searchObj)
      .then((response) => {
        let tempObj = {};
        tempObj.result = response.data.result;
        tempObj.statusCode = response.data.statusCode;
        setCount(response.data.count);
        setDataResult(response.data.result);
      })
      .catch((error) => {});

    fetchUserActionsByMenuId(
      props.menuId ? props.menuId : location?.state?.menuId
    )
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  //Change handler for "Search By Patient Name / MR No" SearchDropdown.
  const handleChange = (searchString) => {
    let patientObjForBillSearch = {
      fromDate: null,
      opdIpd: 0,
      searchString: searchString,
      toDate: null,
    };

    setSearchString(searchString);

    let FromDateVal = getValues("FromDate");
    let ToDateVal = getValues("ToDate");

    FromDateVal = getRequiredDateVal(FromDateVal);

    ToDateVal = getRequiredDateVal(ToDateVal);

    if (FromDateVal) {
      let d = new Date(FromDateVal);
      d = d.toISOString();
      patientObjForBillSearch.fromDate = d;
    }

    if (ToDateVal) {
      let d = new Date(ToDateVal);
      d = d.toISOString();
      patientObjForBillSearch.toDate = d;
    }

    if (searchString !== "") {
      searchPatientForBillList(patientObjForBillSearch).then((response) => {
        setOptions(response.data.result);
      });
    }
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.setOpen(false);
                }}
                style={{
                  paddingLeft: 10,
                }}
              />
            </div>

            <div className="row">
              <fieldset className="border border-gray-300 text-left  lg:mx-auto lg:px-4 lg:m-2  md:ml-0 md:mr-0  rounded   ">
                <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Bill List
                </legend>

                <div className="grid lg:grid-cols-5 gap-2 md:grid-cols-2 md:p-5 items-center">
                  {/* SearchDropdown field for taking the patient name's string.*/}
                  <div className="lg:col-span-2 lg:w-[95%] md:w-[95%] md:col-span-1">
                    <SearchDropdown
                      control={control}
                      name="opdbillingSearch"
                      dataArray={options}
                      searchIcon={true}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Search By Patient Name / MR.No./ Mobile No."
                      handleInputChange={handleChange}
                      inputRef={{
                        ...register("opdbillingSearch", {
                          onChange: (e) => {},
                        }),
                      }}
                    />
                  </div>

                  {/* This is the div for date picker */}
                  <div>
                    <DatePickerFieldNew
                      name="FromDate"
                      control={control}
                      label="From Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("FromDate", newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>

                  {/* This is the div for date picker. */}
                  <div>
                    <DatePickerFieldNew
                      name="ToDate"
                      control={control}
                      label="To Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("ToDate", newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>

                  {/* This is the div for SearchIcon button. */}
                  <div>
                    <CommonButton
                      // label="Search Icon"
                      searchIcon
                      className="bg-customBlue text-white"
                      onClick={() => {
                        populateTable();
                      }}
                    />
                  </div>
                </div>
              </fieldset>

              {dataResult.length > 0 ? (
                <>
                  <CommonDynamicTablePaginationNew
                    dataResult={dataResult}
                    renderActions={renderActions}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    tableClass={"rounded lg:h-72 2xl:h-96"}
                    removeHeaders={[
                      "BillId",
                      "IsCancelled",
                      "BillNo",
                      "PatientId",
                    ]}
                    // RenderButtons={RenderButtons}
                  />
                </>
              ) : (
                <>
                  <div className="text-center mt-10">No Record Found</div>
                </>
              )}
            </div>
          </Box>
        </Modal>

        {/* backdrop */}
        <CommonBackDrop openBackdrop={openBackdrop} />

        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}

        {billCancellationModal ? (
          <ReasonForBillCancellation
            open={billCancellationModal}
            setOpen={setBillCancellationModal}
            billId={billId}
            populateTable={populateTable}
          />
        ) : null}
      </div>
    </>
  );
}
