import * as React from "react";

import { Box, Modal, TextField } from "@mui/material";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import OkButton from "../../../common/components/Buttons/OkButton";

import { successAlert } from "../../../common/components/Toasts/CustomToasts";

import { cancelBill } from "../../services/BillingServices";

import { useForm } from "react-hook-form";

import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";

//style for modal
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",

  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};

export default function ReasonForBillCancellation(props) {

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    // defaultValues,
  });

  function onSubmitDataHandler(data) {
    setOpenBackdrop(true);
    let reasonForBillCancellationVal = data.reasonForBillCancellation;

    let billIdVal = props.billId;

    cancelBill(billIdVal, reasonForBillCancellationVal)
      .then((response) => {
        //Again populate the updated bill list.
        props.populateTable();
        setOpenBackdrop(false);
        props.setOpen(false);


        successAlert(response.data.message);
      })
      .catch((error) => {
        setOpenBackdrop(false);
      });
  }
  return (
    <>
      <div className="mt-20">
        <Modal
          open={props.open}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full py-2">
              <CancelPresentationIconButton
                onClick={() => {
                  props.setOpen(false);
                }}
              />
            </div>

            <div>Reason For Bill Cancellation.</div>

            <form onSubmit={handleSubmit(onSubmitDataHandler)}>
              <div className=" ">
                <div className=" py-2 w-full">
                  <TextField
                    name="reasonForBillCancellation"
                    id="outlined-multiline-static"
                    label="Reason For Bill Cancellation"
                    multiline
                    fullWidth
                    {...register("reasonForBillCancellation")}
                  />
                </div>
                <div className="flex gap-2 justify-end">
                  <OkButton />
                </div>
              </div>
            </form>
          </Box>
        </Modal>
        {/* backdrop */}
        <CommonBackDrop openBackdrop={openBackdrop} />
      </div>
    </>
  );
}
