import React from "react";

export const CancelIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.94226 5L7.99976 6.9425L6.05726 5L4.99976 6.0575L6.94226 8L4.99976 9.9425L6.05726 11L7.99976 9.0575L9.94226 11L10.9998 9.9425L9.05726 8L10.9998 6.0575L9.94226 5ZM7.99976 0.5C3.85226 0.5 0.499756 3.8525 0.499756 8C0.499756 12.1475 3.85226 15.5 7.99976 15.5C12.1473 15.5 15.4998 12.1475 15.4998 8C15.4998 3.8525 12.1473 0.5 7.99976 0.5ZM7.99976 14C4.69226 14 1.99976 11.3075 1.99976 8C1.99976 4.6925 4.69226 2 7.99976 2C11.3073 2 13.9998 4.6925 13.9998 8C13.9998 11.3075 11.3073 14 7.99976 14Z"
        fill="#D21919"
      />
    </svg>
  );
};

export const DisableCancelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_9917_14990)">
        <path
          d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill="#D7D7D7"
        />
      </g>
      <defs>
        <clipPath id="clip0_9917_14990">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.2253 20.2497H2.75V16.9008C2.75 16.8335 2.77719 16.769 2.8254 16.7219L13.1918 6.60645L16.7502 10.0788L6.39989 20.1786C6.3532 20.2242 6.29054 20.2497 6.2253 20.2497Z"
        stroke="#007EA9"
        stroke-width="1.5"
      />
      <path
        d="M12.1406 20.25H21.2498V17.75H14.6616L12.1406 20.25Z"
        fill="#007EA9"
        stroke="#007EA9"
        stroke-width="1.5"
      />
      <path
        d="M17.8241 9.03109L19.5609 7.33607C19.7411 7.16027 19.7411 6.88578 19.5609 6.70999L16.6703 3.88934C16.4799 3.70354 16.1665 3.70357 15.9761 3.88935L14.2656 5.55874L17.8241 9.03109Z"
        fill="#007EA9"
        stroke="#007EA9"
        stroke-width="1.5"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.02857 19.2133C6.02857 20.328 6.95429 21.24 8.08571 21.24H16.3143C17.4457 21.24 18.3714 20.328 18.3714 19.2133V7.05333H6.02857V19.2133ZM8.08571 9.08H16.3143V19.2133H8.08571V9.08ZM15.8 4.01333L14.7714 3H9.62857L8.6 4.01333H5V6.04H19.4V4.01333H15.8Z"
        fill="#D21919"
      />
    </svg>
  );
};