import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  InputAdornment,
  Modal,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import RadioField from "../../../common/components/FormFields/RadioField";
import { getEstimationListing } from "../../services/billestimation/BillEstimationService";
import InfoTable from "./billestimationtables/InfoTable";

function ViewBillEstimation(props) {
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [estimationListData, setEstimationListData] = React.useState(null);
  const [headers, setHeaders] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const defaultValues = {
    patientType: "OPD",
    // searchField:""
  };
  const { control, watch, setValue, getValues, register } = useForm({
    defaultValues,
  });
  const patientType = [
    {
      id: "OPD",
      label: "OPD",
      value: "OPD",
    },
    {
      id: "IPD",
      label: "IPD",
      value: "IPD",
    },
    {
      id: "Both",
      label: "Both",
      value: "Both",
    },
  ];

  const handleReset = () => {
    setValue("searchField", "");
    let buttonId = document.getElementById("resetButton");
    buttonId.focus();
  };
  let patientTypeWatch = watch("patientType");

  React.useEffect(() => {
    if (props.open) {
      let opdIpdFlag;
      if (patientTypeWatch === "OPD") {
        opdIpdFlag = 0;
      } else if (patientTypeWatch === "IPD") {
        opdIpdFlag = 1;
      } else if (patientTypeWatch === "Both") {
        opdIpdFlag = 2;
      }

      let searchObject = {
        fromDate: selectedFromDate,
        opdIpd: opdIpdFlag,
        searchString: "",
        toDate: selectedToDate,
      };
      getEstimationListing(searchObject)
        .then((response) => response.data)
        .then((res) => {
          if (res.result.length > 0) {
            setEstimationListData(res);
          } else {
            setEstimationListData(null);
          }
        });
    }
  }, [props.open]);

  const handleSearchBillEstimation = (e) => {
    if (e.target.value !== "") {
      setSearchString(e.target.value);
    } else {
      setSearchString("");
    }
  };

  const handleGetEstimationList = () => {
    let opdIpdFlag;
    if (patientTypeWatch === "OPD") {
      opdIpdFlag = 0;
    } else if (patientTypeWatch === "IPD") {
      opdIpdFlag = 1;
    } else if (patientTypeWatch === "Both") {
      opdIpdFlag = 2;
    }

    let searchObject = {
      fromDate: selectedFromDate,
      opdIpd: opdIpdFlag,
      searchString: searchString,
      toDate: selectedToDate,
    };
    getEstimationListing(searchObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.result.length > 0) {
          setEstimationListData(res);
        } else {
          setEstimationListData(null);
        }
      });
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  React.useEffect(() => {
    if (estimationListData?.result?.length > 0) {
      //set rows object to table
      const allHeaders = Object.keys(estimationListData.result[0]);
      setHeaders(removeHeaders(allHeaders, ["Id"]));
    }
  }, [estimationListData]);

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            p: 2,
            borderRadius: 1,
          }}
        >
          <div>
            <CancelPresentationIconButton
              className="text-red-500 cursor-pointer"
              onClick={() => {
                props.close();
              }}
            />
          </div>
          <div className="text-lg font-semibold my-4">Bill Estimation List</div>
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={3.5} sm={7}>
              <div className="flex gap-2 mb-1">
                <div className="font-semibold">Patient Type :</div>
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={patientType}
                />
              </div>
            </Grid>
            <Grid item lg={3.5} sm={6}>
              <TextField
                sx={{ width: 320,height:"36.33px" }}
                size="small"
                label="Search By Patient Name / MR No."
                control={control}
                onChange={(e) => {
                  handleSearchBillEstimation(e);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={2} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // disablePast
                  label="From Date"
                  value={selectedFromDate}
                  name="fromDate"
                  onChange={(newValue) => {
                    setSelectedFromDate(newValue);
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      className="bg-white"
                      fullWidth
                      name="fromDate"
                      size="small"
                      defaultValue=""
                      {...params}
                      sx={{
                        svg: { color: "#0B83A5" },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={2} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disableFuture
                  label="To Date"
                  value={selectedToDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setSelectedToDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="bg-white"
                      name="toDate"
                      fullWidth
                      size="small"
                      {...params}
                      sx={{
                        svg: { color: "#0B83A5" },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={1} sm={3}>
              <CommonButton
              className="px-2 bg-customBlue h-9 rounded text-white"
              searchIcon={true}
              onClick={handleGetEstimationList}
              />
            </Grid>
          </Grid>
          {estimationListData?.result?.length > 0 ? (
            <div className="my-5">
              <InfoTable data={estimationListData} />
            </div>
          ) : (
            <div className="flex justify-center text-lg font-semibold text-gray-400 my-10">
              No Records Found..!
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ViewBillEstimation;
