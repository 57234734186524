import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  fetchDepartmentList,
  fetchUserList,
  postDischargeMessage,
} from "../../../services/initiatDischargeMessage/InitiatDischargeMessageServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  minHeight: "80%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

let showSpinner1 = false;
let showSpinner2 = false;

function InitiatDischargeMessage(props) {
  console.log(props, "userInfo");
  var currentDate = new Date();

  var token = JSON.parse(localStorage.getItem("userInfo"));
  const {
    open,
    handleClose,
    selectedRowData,
    menuId,
    privilege,
    getBedListData,
  } = props;

  //
  const [departmentList, setDepartmentList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  //
  const [selectedUserList, setSelectedUserList] = useState([]);
  //
  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  //
  const [arrData, setArrData] = useState([]);
  //
  const { control } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    getDepartmentList();
    getUserList();
  }, []);

  //department
  const getDepartmentList = () => {
    showSpinner1 = true;
    fetchDepartmentList()
      .then((response) => response.data)
      .then((res) => {
        setDepartmentList(res.result);
        showSpinner1 = false;
      });
  };

  //department
  const getUserList = () => {
    showSpinner2 = true;
    fetchUserList(token.userId)
      .then((response) => response.data)
      .then((res) => {
        setUserList(res.result);
        const trueUsers = res.result.filter((user) => user.IsApplicableForSMS);
        setSelectedUserList(trueUsers);
        showSpinner2 = false;
      });
  };

  //post obj
  const finalObj = {
    admissionId: 0,
    bedNo: "",
    departmentIdDto: [
      {
        checked: true,
        displayed: true,
        msgFromDeptId: 0,
        msgToDeptId: 0,
      },
    ],
    mrNo: 0,
    patientName: "",
    time: "",
    isFromDischargeInitiated: true,
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  useEffect(() => {
    let arr = [];
    let temp = [...selectedUserList];
    for (let i = 0; i < temp.length; i++) {
      let MsgToDeptId = temp[i].UserId;

      let checked = true;
      let displayed = true;
      let msgFromDeptId = token.userId;
      let msgToDeptId = MsgToDeptId;

      arr.push({ checked, displayed, msgFromDeptId, msgToDeptId });
    }

    setArrData(arr);
  }, [selectedUserList]);

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    finalObj.admissionId = selectedRowData.AdmissionId;
    finalObj.departmentIdDto = arrData;
    finalObj.bedNo = selectedRowData.BedId;
    finalObj.mrNo = selectedRowData.MRNo;
    finalObj.patientName = selectedRowData["Patient Name"];
    finalObj.time = new Date();
    finalObj.menuId = menuId;
    finalObj.privilege = privilege;
    finalObj.isFromDischargeInitiated = true;

    //

    postDischargeMessage(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          props.updateRowOfAdmTbl &&
            props.updateRowOfAdmTbl(res?.result?.admList);
          handleClose();

          setOpenBackdrop(false);
          getBedListData && getBedListData();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  ////////table related
  const renderActionsDept = (row, index) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="">
            <CheckBoxField
              control={control}
              name={`selectCheck${index}`}
              defaultValue={row.ApplicableForDeptSMS}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
          </span>
        </div>
      </>
    );
  };

  const renderActionsUser = (row, index) => {
    const handleSelect = (e, row) => {
      let userArr = [...selectedUserList];

      if (e.target.checked === true) {
        userArr.push(row);
        setSelectedUserList(userArr);
      } else if (e.target.checked === false) {
        let temp = [...selectedUserList];

        let filter1 = temp.filter((item) => item.UserId !== row.UserId);
        setSelectedUserList(filter1);
      }
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <span
            className=""
            onClick={(e) => {
              handleSelect(e, row);
            }}
          >
            <CheckBoxField
              control={control}
              name={`selectUser${index}`}
              defaultValue={row.IsApplicableForSMS}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <CancelPresentationIconButton onClick={handleClose} />
            <div>
              <label className="text-xl font-semibold">
                Department Message System
              </label>
            </div>
            <hr className="w-full my-2 border-t-2 border-customBlue" />
            <div className="grid grid-cols-3 gap-5">
              <div>
                <div>
                  <div className="font-semibold ">Department :</div>

                  {showSpinner1 ? (
                    <div className="flex justify-center mt-14">
                      <LoadingSpinner />
                    </div>
                  ) : departmentList.length > 0 ? (
                    // <InitiateDischargeDepartmentTable data={departmentList} />
                    <CommonDynamicTableNew
                      dataResult={departmentList}
                      tableClass="rounded lg:h-52 md:h-44 xl:h-80"
                      renderActions={renderActionsDept}
                    />
                  ) : null}
                </div>
                <div className="">
                  <div className="font-semibold">Message To :</div>

                  {showSpinner2 ? (
                    <div className="flex justify-center mt-14">
                      <LoadingSpinner />
                    </div>
                  ) : userList.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={userList}
                      tableClass="rounded lg:h-52 md:h-44 xl:h-80"
                      renderActions={renderActionsUser}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-span-2 mt-7">
                <fieldset
                  className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
                >
                  <div className="py-6 grid grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 gap-2 p-3 ">
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-28">Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {(selectedRowData &&
                            selectedRowData["Patient Name"]) ||
                            selectedRowData["PatientName"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-28">Mr.No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRowData && selectedRowData.MRNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-28">Bed No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {(selectedRowData && selectedRowData["Bed No"]) ||
                            selectedRowData["bedNo"] ||
                            selectedRowData.BedNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-28">Date</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {currentDate.toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="w-full mt-3">
                  <InputField
                    control={control}
                    name="comment"
                    label="Comment"
                    placeholder="Comment"
                  />
                </div>
                <div className="flex justify-end gap-4 mt-5">
                  <CommonButton
                    label="Cancel"
                    onClick={handleClose}
                    className="border border-customRed text-customRed"
                  />

                  <CommonButton
                    type="submit"
                    label="Send"
                    onClick={onSubmit}
                    className="bg-customGreen text-white"
                  />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}

export default InitiatDischargeMessage;
