import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

// autoComplete
export const fetchDischargeSummarySearchDropDown = (
  patientInfo,
  isAdmitted
) => {
  return apiClient.get(
    `/dischargeSummary/patientInfoAutoComplete/${patientInfo}/${isAdmitted}`,
    {
      headers: authHeader(),
    }
  );
};

// DcumentType
export const fetchDcumentType = () => {
  return apiClient.get(`/misc/documents`, {
    headers: authHeader(),
  });
};
// DcumentType
export const fetchBabySex = () => {
  return apiClient.get(`/dischargeSummary/genderOfBaby`, {
    headers: authHeader(),
  });
};

//drugName
export const fetchDrugName = (drugSearchString) => {
  return apiClient.get(`/dischargeSummary/drugName/${drugSearchString}`, {
    headers: authHeader(),
  });
};
//drugDose
export const fetchDrugDose = () => {
  return apiClient.get(`/dischargeSummary/drugFrequency`, {
    headers: authHeader(),
  });
};
//drugInstruction
export const fetchDrugInstructions = () => {
  return apiClient.get(`/dischargeSummary/drugInstruction`, {
    headers: authHeader(),
  });
};
//drugForm
export const fetchDrugForm = () => {
  return apiClient.get(`/dischargeSummary/drugForm`, {
    headers: authHeader(),
  });
};
//drugRoute
export const fetchDrugRoute = () => {
  return apiClient.get(`/dischargeSummary/drugRoute`, {
    headers: authHeader(),
  });
};
//drugAdvice
export const fetchDischargeAdvice = () => {
  return apiClient.get(`/dischargeSummary/dischargeAdvice`, {
    headers: authHeader(),
  });
};
//drugGenericName
export const fetchDrugGenericName = (genericSearchString) => {
  return apiClient.get(
    `/dischargeSummary/drugGenericName/${genericSearchString}`,
    {
      headers: authHeader(),
    }
  );
};

//Patient Info
export const fetchDishargePatientInfo = (patientId) => {
  //
  return apiClient.get(`/dischargeSummary/patientInfo/${patientId}`, {
    headers: authHeader(),
  });
};

// form post object
export const postDischargeSummaryData = (finalObject) => {
  return apiClient.post(`/dischargeSummary/saveDischargeSummary`, finalObject, {
    headers: authHeader(),
  });
};

// Patch all patient details
export const fetchDischargeSummaryDetails = (admissionId) => {
  return apiClient.get(
    `/dischargeSummary/dischargeSummaryDtls/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

// Retrive drugs
export const fetchRetriveDrug = (admissionId) => {
  return apiClient.get(`/dischargeSummary/retrieveDrugs/${admissionId}`, {
    headers: authHeader(),
  });
};

//print
export const fetchPrintDischargeSummary = (admissionIdForPrint, preview) => {
  return apiClient.get(
    `/reports/Ipd/dischargeSummary?admissionId=${admissionIdForPrint}&preview=${preview}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//historySheetPrint
export const fetchPrintDischargeSummaryHistorySheet = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/admissionHistoryPhysicalAssessment?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//obstetric CaseSheet
export const fetchPrintObstetricCaseSheet = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/obstetricCaseSheet?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//gyneac Case
export const fetchPrintGyneacCase = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/gyneacCase?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//mtp Case Sheet
export const fetchPrintMtpCaseSheet = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/mtpCaseSheet?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//embryo Transfer Case
export const fetchPrintEmbryoTransferCase = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/embryoTransferCase?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//Ovum PICKUP
export const fetchPrintOvumPICKUP = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/ovumPICKUP?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//pediatric Summary
export const fetchPrintPediatricSummary = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/pediatricSummary?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//neonatal
export const fetchPrintNeonatal = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/neonatal?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//deathSummary
export const fetchDeathSummary = (admissionIdForPrint) => {
  return apiClient.get(
    `/reports/Ipd/deathSummary?admissionId=${admissionIdForPrint}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//retrive investigations
export const retriveInvestigations = (admissionId, pathologyRadiology) => {
  return apiClient.get(
    `/dischargeSummary/getInvestigationDetails/${admissionId}/${pathologyRadiology}`,
    {
      headers: authHeader(),
    }
  );
};

//retrive ivestigations
export const saveTentativeDischarge = (tntDischargeObj) => {
  return apiClient.post(`/discharge/addTentativeDischarge`, tntDischargeObj, {
    headers: authHeader(),
  });
};
