import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../../common/components/FormFields/InputField";

import InputLimit from "../../../../../common/components/FormFields/InputLimit";
import {
  fetchArea,
  fetchCity,
  fetchCountries,
  fetchState,
} from "../../../../../commonServices/miscellaneousServices/MiscServices";

const AddressDetails = (props) => {
  const [country, setCountry] = useState();
  const [countryId, setCountryId] = useState(2);
  const [state, setState] = useState();
  const [stateId, setStateId] = useState(2);
  const [city, setCity] = useState();
  const [cityId, setCityId] = useState(2);
  const [area, setArea] = useState();
  const { setIsNri } = props;

  const {
    control,
    register,
    resetField,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const country_selected = watch("country");

  /////Api to get country List
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    !!countryId && getState();
  }, [countryId]);

  // useEffect(() => {
  //   getDistrict();
  // }, [stateId]);

  useEffect(() => {
    getCity();
  }, [stateId]);

  useEffect(() => {
    getArea(cityId);
  }, [cityId]);

  const getCountries = () => {
    fetchCountries()
      .then((response) => response.data)
      .then((res) => {
        setCountry(res.result);
      });
  };

  //Api to get State List

  const getState = () => {
    if (country_selected) {
      fetchState(country_selected.id).then((response) => {
        if (response.data.result?.length > 0) {
          setState(response.data.result);
        } else {
          setState([]);
        }
      });
    }
  };

  const getCity = () => {
    if (typeof stateId === "number") {
      fetchCity(stateId).then((response) => {
        if (response.data.result?.length > 0) {
          setCity(response.data.result);
        } else {
          setCity([]);
        }
      });
    }
  };
  //Api to get Area List

  const getArea = () => {
    if (typeof cityId === "number") {
      fetchArea(cityId).then((response) => {
        setArea(response.data.result);
        if (response.data.result?.length > 0) {
          setArea(response.data.result);
        } else {
          setArea([]);
        }
      });
    }
  };

  return (
    <div>
      <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-3">
        {/* //House No.// */}
        <div>
          <InputField
            name="houseFlatNumber"
            variant="outlined"
            label="House No./Flat No.*"
            error={errors.houseFlatNumber}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        {/* //Street Address// */}
        <div>
          <InputField
            name="society"
            variant="outlined"
            label="Society/House *"
            error={errors.society}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 255 }}
          />
        </div>
        {/* //nearest landmark// */}
        <div>
          <InputField
            name="nearestLandmark"
            variant="outlined"
            label="Nearest Landmark *"
            error={errors.nearestLandmark}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 255 }}
          />
        </div>

        {/* //address// */}
        <div className="col-span-2">
          <InputField
            name="address"
            variant="outlined"
            label="Address"
            // error={errors.address}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 255 }}
          />
        </div>

        {/* //Country// */}
        <div>
          <DropdownField
            control={control}
            error={errors.country}
            name="country"
            label="Country *"
            dataArray={country}
            isSearchable={true}
            placeholder="Country *"
            isClearable={false}
            inputRef={{
              ...register("country", {
                onChange: (e) => {
                  setValue("state", null);
                  setValue("city", null);
                  setValue("area", null);
                  setValue("pinCode", null);
                  setCountryId(e.target.value.id);

                  // //
                  // setState([]);
                  // setCity([]);
                  // setArea([]);

                  e.target.value.value === "India"
                    ? setIsNri(true)
                    : setIsNri(false);
                },
              }),
            }}
          />
        </div>
        {/* //State // */}
        <div>
          <DropdownField
            control={control}
            error={errors.state}
            name="state"
            label="State *"
            dataArray={state}
            isSearchable={true}
            placeholder="State *"
            isClearable={false}
            inputRef={{
              ...register("state", {
                onChange: (e) => {
                  setStateId(e.target.value.id);
                },
              }),
            }}
          />
        </div>

        {/* //District// 
        <div>
          <DropdownField
            control={control}
            error={errors.district}
            name="district"
            label="District *"
            dataArray={district}
            isSearchable={true}
            placeholder="District *"
            isClearable={false}
            inputRef={{
              ...register("district", {
                onChange: (e) => {
                  setDistrictId(e.target.value.id);

                  //
                  resetField("city");
                  resetField("area");
                  resetField("pinCode");
                },
              }),
            }}
          />
        </div>*/}

        {/* //City// */}

        <div>
          <DropdownField
            control={control}
            error={errors.city}
            name="city"
            label="City"
            dataArray={city}
            isSearchable={false}
            placeholder="City *"
            isClearable={false}
            inputRef={{
              ...register("city", {
                onChange: (e) => {
                  setCityId(e.target.value.id);
                  resetField("area");
                  resetField("pinCode");
                },
              }),
            }}
          />
        </div>

        {/* //Area// */}
        <div>
          <DropdownField
            control={control}
            error={errors.area}
            name="area"
            label="Area *"
            dataArray={area}
            placeholder="Area *"
          />
        </div>
        {/* //Pin Code // */}
        <div>
          <InputLimit
            maxDigits={6}
            name="pinCode"
            variant="outlined"
            type="Number"
            label="Pincode"
            //error={errors.pinCode}
            control={control}
            disabled={false}
            onKeyDown={(e) => {
              if (
                e?.key === "-" ||
                e?.key === "+" ||
                e?.code === "ArrowUp" ||
                e?.code === "ArrowDown" ||
                e?.code === "NumpadDecimal"
              ) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
