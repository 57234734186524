import InfoIcon from "@mui/icons-material/Info";
import { FormControlLabel, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DeleteIcon, EditIcon } from "../../../../assets/CommonAssets";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonDivider from "../../../../common/components/divider/CommonDivider";
import { getTemplateAutoComplete } from "../../../../reports/services/feedbackservices/FeedbackServices";
import {
  deleteFeedBackQuestionTemplate,
  getDepartmentDropDown,
  getTemplateList,
  markIsPublishedAndIsBroadcast,
} from "../../../services/feedback/feedbackcreate/FeedBackCreationServices";
import { CustomAntSwitch } from "./CustomeMuiSwitch";
import FeedbackCreationModal from "./FeedbackCreationModal";
import QuestionInfoModal from "./QuestionInfoModal";
function FeedBackQuestionSetCreation() {
  const defaultValues = {
    questionsFor: null,
    department: null,
  };

  const { control, setValue, watch, register } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let questionsForValue = watch("questionsFor");
  let departmentValue = watch("department");

  // state variable
  const [feedbackDataList, setFeedBackDataList] = useState([]);
  const [selectedInfoRow, setSelectedInfoRow] = useState(null);
  const [questionDetailsModal, setQuestionDetailsModal] = useState(false);
  const [openCreateNewFeedbackModal, setOpenCreateNewFeedbackModal] = useState(
    false
  );
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);
  const [finalData, setFinalData] = useState(null);
  const [openPostConfirmationModal, setOpenPostConfirmationModal] = useState(
    false
  );
  const [
    openIsBrodcastConfirmationModal,
    setOpenIsBrodcastConfirmationModal,
  ] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [count, setCount] = useState();
  const [brodcastData, setBrodcastData] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedSearchRecord, setSelectedSearchRecord] = useState(null);

  const [listingObject, setListingObject] = useState({
    searchString: questionsForValue !== null ? questionsForValue.label : "",
    id: questionsForValue === null ? null : questionsForValue.id,
    departmentId: departmentValue !== null ? departmentValue.id : null,
    page: page,
    size: rowsPerPage ? rowsPerPage : 10,
  });
  // close save confirmation modal
  const handleClosePostConfirmationModal = () => {
    if (openPostConfirmationModal) {
      setOpenPostConfirmationModal(false);
    }
  };
  const handleCloseNewFeedbackModal = () =>
    setOpenCreateNewFeedbackModal(false);

  const handleCloseQuestionInfoModal = () => setQuestionDetailsModal(false);

  const handleChangeQuestionType = (e) => {
    if (e.length > 0) {
      if (UseCheckConsecutiveCharacters(e) !== "") {
        getTemplateAutoComplete(e)
          .then((response) => {
            setTemplateOptions(response.data.result);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  useEffect(() => {
    populateTable();
  }, [selectedSearchRecord, departmentValue]);

  function populateTable(forPagination) {
    let tempObj = {
      // searchString: questionsForValue !== null ? questionsForValue.label : "",
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      id: selectedSearchRecord?.id,
      departmentId: departmentValue !== null ? departmentValue.id : null,
      // id: questionsForValue === null ? null : questionsForValue.id,
      // departmentId: departmentValue !== null ? departmentValue.id : null,
    };
    getTemplateList(tempObj)
      .then((response) => response.data)
      .then((res) => {
        console.log("es.result", res.result);
        if (forPagination) {
          const tempArr = res.result.map((obj) => ({
            Id: obj.Id,
            matchString:
              obj.Department + obj["Questions For"] + obj["Is Published"],
            "Questions For": obj["Questions For"],
            "OPD/IPD": obj["OPD/IPD"],
            "Question Type": obj["Question Type"],
            "Template Name": obj["Template Name"],
            "Template Name Hindi": obj["Template Name Hindi"],
            "Template Name Marathi": obj["Template Name Marathi"],
            "Conclusion Question": obj["Conclusion Question"],
            Department: obj.Department,
            "Is Published": obj["Is Published"],
            "Is Brodcast": obj["Is Brodcast"],
            "Question Details": JSON.parse(obj["Question Details"]),
            PreView: "",
            "Question Heading": obj["Question Heading"],
            "Question Heading Hindi": obj["Question Heading Hindi"],
            "Question Heading Marathi": obj["Question Heading Marathi"],
            "Question Footer": obj["Question Footer"],
            "Question Footer Hindi": obj["Question Footer Hindi"],
            "Question Footer Marathi": obj["Question Footer Marathi"],
            "Answer Scale": obj["Answer Scale"],
            Duration: obj["Duration"],
            "Department Id": obj["Department Id"],
            ScaleId: obj["Answer Scale Id"],
            "Conclusion Question": obj["Conclusion Question"],
            "Conclusion Question Hindi": obj["Conclusion Question Hindi"],
            "Conclusion Question Marathi": obj["Conclusion Question Marathi"],
            "Conclusion Question Answer": obj["Conclusion Question Answer"],
            "Conclusion Question Answer Hindi":
              obj["Conclusion Question Answer Hindi"],
            "Conclusion Question Answer Marathi":
              obj["Conclusion Question Answer Marathi"],
            "Has Conclusion Questions": obj["Has Conclusion Questions"],
            isMultiLanguage: obj.isMultiLanguage,
          }));

          setFeedBackDataList((prevData) => [...prevData, ...tempArr]);
        } else {
          const tempArr = res.result.map((obj) => ({
            Id: obj.Id,
            matchString:
              obj.Department + obj["Questions For"] + obj["Is Published"],
            "Questions For": obj["Questions For"],
            "OPD/IPD": obj["OPD/IPD"],
            "Question Type": obj["Question Type"],
            "Template Name": obj["Template Name"],
            "Template Name Hindi": obj["Template Name Hindi"],
            "Template Name Marathi": obj["Template Name Marathi"],
            "Conclusion Question": obj["Conclusion Question"],
            Department: obj.Department,
            "Is Published": obj["Is Published"],
            "Is Brodcast": obj["Is Brodcast"],
            "Question Details": JSON.parse(obj["Question Details"]),
            PreView: "",
            "Question Heading": obj["Question Heading"],
            "Question Heading Hindi": obj["Question Heading Hindi"],
            "Question Heading Marathi": obj["Question Heading Marathi"],
            "Question Footer": obj["Question Footer"],
            "Question Footer Hindi": obj["Question Footer Hindi"],
            "Question Footer Marathi": obj["Question Footer Marathi"],
            "Answer Scale": obj["Answer Scale"],
            Duration: obj["Duration"],
            "Department Id": obj["Department Id"],
            ScaleId: obj["Answer Scale Id"],
            "Conclusion Question": obj["Conclusion Question"],
            "Conclusion Question Hindi": obj["Conclusion Question Hindi"],
            "Conclusion Question Marathi": obj["Conclusion Question Marathi"],
            "Conclusion Question Answer": obj["Conclusion Question Answer"],
            "Conclusion Question Answer Hindi":
              obj["Conclusion Question Answer Hindi"],
            "Conclusion Question Answer Marathi":
              obj["Conclusion Question Answer Marathi"],
            "Has Conclusion Questions": obj["Has Conclusion Questions"],
            isMultiLanguage: obj.isMultiLanguage,
          }));
          setFeedBackDataList(tempArr);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function deleteRow() {
    deleteFeedBackQuestionTemplate(deleteRowId.Id, token.empId)
      .then((response) => {
        successAlert(response.data.message);
        setOpenDeleteConfirmationModal(false);
        setDeleteRowId(null);
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  // api function
  function postFeedBacks() {
    markIsPublishedAndIsBroadcast(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        setOpenPostConfirmationModal(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }
  // calling the  api function
  function addRecord() {
    setOpenPostConfirmationModal(false);
    postFeedBacks();
  }

  function postIsBrodcastData() {
    markIsPublishedAndIsBroadcast(brodcastData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        setOpenIsBrodcastConfirmationModal(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }
  // calling the  api function
  function addIsBrodcastRecord() {
    setOpenIsBrodcastConfirmationModal(false);
    postIsBrodcastData();
  }

  function renderInput(row, index, header) {
    return (
      <>
        {header === "Is Published" ? (
          <fieldset>
            <Controller
              name={`isPublished${index}`}
              control={control}
              defaultValue={row["Is Published"]}
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <CustomAntSwitch
                      checked={row["Is Published"]}
                      // disabled={
                      //   row["Questions For"] === "Patient"
                      //     ? true
                      //     : false || row["Department Id"] === null
                      //     ? true
                      //     : false
                      // }
                      onClick={() => {
                        let sameDepartments = feedbackDataList?.filter(
                          (feedback) => feedback.Department === row?.Department
                        );

                        let trueLength = sameDepartments?.filter((item) =>
                          item.matchString?.includes(true)
                        );
                        // if (trueLength?.length> 0) {
                        let obj = {
                          templateId: row.Id,
                          flag: 1,
                          markValue: !row["Is Published"],
                          depId: row["Department Id"],
                        };
                        setFinalData(obj);
                        setOpenPostConfirmationModal(true);
                        // }
                        // else {
                        //   checkIsSurveyPublished(
                        //     row["Questions For"],
                        //     row["Department Id"]
                        //   ).then((response) => {
                        //     if (
                        //       response.data.result.isSurveyPublished === true &&
                        //       row["Is Published"] === false
                        //     ) {
                        //       errorAlert("Survey Already Published");
                        //       populateTable();
                        //     } else {
                        //       let obj = {
                        //         id: row.Id,
                        //         isPublished: !row["Is Published"],
                        //       };
                        //       setFinalData(obj);
                        //       setOpenPostConfirmationModal(true);
                        //     }
                        //   });
                        // }
                      }}
                    />
                  }
                />
              )}
            />
          </fieldset>
        ) : (
          ""
        )}
        {header === "Is Brodcast" ? (
          <fieldset>
            <Controller
              name={`isBrodcast${index}`}
              control={control}
              defaultValue={row["Is Brodcast"]}
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <CustomAntSwitch
                      checked={row["Is Brodcast"]}
                      disabled={
                        row["Questions For"] === "Patient"
                          ? true
                          : false || row["Department Id"] === null
                          ? true
                          : false
                      }
                      onClick={() => {
                        let sameDepartments = feedbackDataList?.filter(
                          (feedback) => feedback.Department === row?.Department
                        );

                        let trueLength = sameDepartments?.filter((item) =>
                          item.matchString?.includes(true)
                        );
                        // if (trueLength?.length > 0) {
                        let obj = {
                          templateId: row.Id,
                          flag: 2,
                          markValue: !row["Is Brodcast"],
                          depId: row["Department Id"],
                        };
                        setBrodcastData(obj);
                        setOpenIsBrodcastConfirmationModal(true);
                        // }
                      }}
                    />
                  }
                />
              )}
            />
          </fieldset>
        ) : (
          ""
        )}
        {header === "Question Details" ? (
          <button
            type="button"
            className="text-customBlue pt-1"
            onClick={() => {
              setSelectedInfoRow(row);
              setQuestionDetailsModal(true);
            }}
          >
            <InfoIcon />
          </button>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderActions(row, index) {
    return (
      <div className="flex space-x-2 items-center">
        <Tooltip title="Edit">
          <button
            type="button"
            onClick={() => {
              setSelectedInfoRow(row);
              setOpenCreateNewFeedbackModal(true);
            }}
          >
            <EditIcon />
          </button>
        </Tooltip>
        <Tooltip title="Delete">
          <button
            type="button"
            onClick={() => {
              setDeleteRowId(row);
              setOpenDeleteConfirmationModal(true);
            }}
          >
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
    );
  }

  // useMemo(() => {
  //   populateTable();
  // }, [listingObject.searchString, listingObject.departmentId]);

  useEffect(() => {
    getDepartmentDropDown().then((response) => {
      setDepartmentOptions(response.data.result);
    });
  }, []);

  useEffect(() => {
    for (let i = 0; i < feedbackDataList.length; i++) {
      setValue(`isPublished${i}`, feedbackDataList[i]["Is Published"]);
      setValue(`isBrodcast${i}`, feedbackDataList[i]["Is Brodcast"]);
    }
  }, [feedbackDataList]);
  return (
    <div className="pt-16">
      <h1 className="flex justify-center font-semibold text-lg">
        Feedback Question Set Creation
      </h1>
      <div className="flex justify-between items-center pt-3">
        <div className="w-6/12 flex space-x-2 items-center">
          <SearchDropdown
            control={control}
            searchIcon={true}
            name="questionsFor"
            placeholder="Template For"
            dataArray={templateOptions}
            isSearchable={true}
            isClearable={true}
            handleInputChange={handleChangeQuestionType}
            inputRef={{
              ...register("questionsFor", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedSearchRecord(e.target.value);
                  } else {
                    setSelectedSearchRecord(null);
                  }
                },
              }),
            }}
          />
          <DropdownField
            control={control}
            name="department"
            placeholder="Department"
            dataArray={departmentOptions}
            isClearable={true}
          />

          {/*<CommonButton
            searchIcon={true}
            className="bg-[#073763] text-white px-2"
            onClick={() => {
              setListingObject((listingObject) => ({
                ...listingObject,
                searchString:
                  questionsForValue !== null ? questionsForValue.label : null,
                page: page,
                size: rowsPerPage ? rowsPerPage : 10,
                id: questionsForValue !== null ? questionsForValue.id : null,
                departmentId:
                  departmentValue === null ? null : departmentValue.id,
              }));
            }}
          />*/}
        </div>
        <div>
          <CommonButton
            type="button"
            label="+ Add"
            className="bg-[#073763] text-white w-full"
            onClick={() => {
              setOpenCreateNewFeedbackModal(true);
              setSelectedInfoRow(null);
            }}
          />
        </div>
      </div>
      <div className="my-1">
        <CommonDivider />
      </div>
      {feedbackDataList.length > 0 ? (
        <CommonDynamicTablePaginationNew
          dataResult={feedbackDataList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          populateTable={populateTable}
          count={count}
          removeHeaders={[
            "Id",
            "PreView",
            "ScaleId",
            "Has Conclusion Questions",
            "Conclusion Question Answer",
            "Question Type",
            "Department Id",
            "matchString",
            "Conclusion Question",
            "Conclusion Question Answer Marathi",
            "Conclusion Question Answer Hindi",
            "Conclusion Question Marathi",
            "Conclusion Question Hindi",
            "Question Footer Marathi",
            "Question Footer Hindi",
            "Question Heading Marathi",
            "Question Heading Hindi",
            "Template Name Hindi",
            "Template Name Marathi",
            "Question Heading",
            "Question Footer",
            "Duration",
            "isMultiLanguage",
          ]}
          renderInput={renderInput}
          renderActions={renderActions}
          editableColumns={["Is Published", "Question Details", "Is Brodcast"]}
          tableClass={"h-[180px] lg:h-[420px] xl:h-[550px] 2x:h-[600px]"}
          highlightRow={false}
        />
      ) : (
        <h2 className="text-center flex justify-center items-center text-gray-600 font-semibold text-sm py-28">
          No Records Availabale
          <span className="animate-pulse tracking-wider">...</span>
        </h2>
      )}

      {openCreateNewFeedbackModal ? (
        <FeedbackCreationModal
          open={openCreateNewFeedbackModal}
          handleClose={handleCloseNewFeedbackModal}
          populateTable={populateTable}
          selectedInfoRow={selectedInfoRow}
          setSelectedInfoRow={setSelectedInfoRow}
        />
      ) : (
        ""
      )}

      {questionDetailsModal ? (
        <QuestionInfoModal
          open={questionDetailsModal}
          handleClose={handleCloseQuestionInfoModal}
          selectedInfoRow={selectedInfoRow}
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={openDeleteConfirmationModal}
        confirmationHandleClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
        confirmationSubmitFunc={deleteRow}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Proceed"
      />
      <ConfirmationModal
        confirmationOpen={openPostConfirmationModal}
        confirmationHandleClose={handleClosePostConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
      <ConfirmationModal
        confirmationOpen={openIsBrodcastConfirmationModal}
        confirmationHandleClose={() =>
          setOpenIsBrodcastConfirmationModal(false)
        }
        confirmationSubmitFunc={addIsBrodcastRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure Want To BrodCast This Record?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}

export default FeedBackQuestionSetCreation;
