import React from "react";

export default function PrintIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.6 9.16667H6.4C5.072 9.16667 4 10.2833 4 11.6667V16.6667H7.2V20H16.8V16.6667H20V11.6667C20 10.2833 18.928 9.16667 17.6 9.16667ZM15.2 18.3333H8.8V14.1667H15.2V18.3333ZM17.6 12.5C17.16 12.5 16.8 12.125 16.8 11.6667C16.8 11.2083 17.16 10.8333 17.6 10.8333C18.04 10.8333 18.4 11.2083 18.4 11.6667C18.4 12.125 18.04 12.5 17.6 12.5ZM16.8 5H7.2V8.33333H16.8V5Z"
        fill="#4B5563"
      />
    </svg>
  );
}
