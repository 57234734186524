import React, { useEffect, useRef, useState } from "react";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";
import { StoreExpiredItemsPrint } from "../../services/inventoryStockServices/InventoryStockService";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";

export default function DaysPrint(props) {
  const printRef = useRef(null);
  const [listData, setListData] = useState();
  useEffect(() => {
    StoreExpiredItemsPrint(props.storeVal?.id, Number(props.watchDays))
      .then((response) => response.data)
      .then((res) => {
        setListData(res.result);
      });
  }, [props]);

  useEffect(() => {
    listData && printRef && GeneratePrint(printRef);
  }, [listData]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" items-center  grid  grid-cols-3">
            <div></div>
            <div className=" text-center text-[14px] font-semibold ">
              Store Item Expiry Details
            </div>
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
          </div>

          <div className=" p-1 font-semibold text-[11px]  border-t-[1px] border-b-[1px] grid grid-cols-[40%_20%_15%_10%_15%]  border-black">
            <div>Item Name</div>
            <div>Batch Code</div>
            <div>Batch Exp Date</div>
            <div>Bal Qty</div>
            <div>Expire After Days</div>
          </div>
        </thead>
        <tbody className="w-full">
          <div className="">
            {listData?.map?.((data) => {
              return (
                <div className=" text-[11px] grid grid-cols-[40%_20%_15%_10%_15%] p-1 border-b-[1px]">
                  <div>{data?.["Item Name"]}</div>
                  <div>{data?.["Batch Code"]}</div>
                  <div>{data?.["Batch Exp Date"]}</div>
                  <div>{data?.["Balance Qty"]}</div>
                  <div>{data?.["Expire After Days"]}</div>
                </div>
              );
            })}
          </div>
        </tbody>
      </table>
    </div>
  );
}
