import React from "react";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { useForm } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { Tooltip } from "@mui/material";
import {
  CancelIcon,
  DisableCancelIcon,
} from "../../../../assets/icons/CommonIcons";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import { useState } from "react";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { format } from "date-fns";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import {
  getGrnWoAmendmentList,
  getStores,
  saveGrnWoAmendment,
  getSupplierList,
  cancelWOGrn,
} from "../../../services/purchase/GRNAmendmentsServices";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";

const GrnType = [
  {
    id: 0,
    label: "GRN",
    value: 0,
  },
  {
    id: 1,
    label: "WO-GRN",
    value: 1,
  },
];

const GRNAmendments = () => {
  const { control, setValue, watch, register, reset, handleSubmit } = useForm({
    defaultValues: {
      grnType: 0,
      store: null,
      grnNo: "",
      invoiceNo: "",
      supplier: null,
      invoiceDate: new Date(),
      gateEntryNo: "",
    },
  });
  let getGrnType = watch("grnType");
  let getStore = watch("store");
  let getGrnNo = watch("grnNo");
  let getSupplier = watch("supplier");
  let getInvoiceNo = watch("invoiceNo");
  let getInvoiceDate = watch("invoiceDate");
  let getGateEntryNo = watch("gateEntryNo");
  let getUserInfo = JSON.parse(localStorage?.userInfo);
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [cancelWOConfirm, setCancelWOConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [storeList, setStoreList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const [privilege, setPrivilege] = useState("");
  const [invoiceDateVal, setInvoiceDateVal] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [listingObject, setListingObject] = useState({
    grnNo: getGrnNo ? getGrnNo : "",
    grnType: getGrnType,
    storeId: getStore ? getStore?.id : 0,
  });

  function populateTable(forPagination) {
    let listObj = {
      grnNo: getGrnNo ? getGrnNo : "",
      grnType: getGrnType,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      storeId: getStore ? getStore?.id : getUserInfo?.storeId,
    };
    getGrnWoAmendmentList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  }

  function onSubmitData(data) {
    let postObj = {
      getEntry: data?.gateEntryNo ? data?.gateEntryNo : "",
      grnId: selectedRow?.GRNId,
      grnType: selectedRow?.GRNType,
      invoiceDate:
        invoiceDateVal !== null ? `${invoiceDateVal}T00:00:00.000Z` : "",
      invoiceNo: data?.invoiceNo ? data?.invoiceNo : "",
      isGetEntry:
        data?.gateEntryNo !== selectedRow?.["Gate Entry No"] ? true : false,
      isInvoiceDate: invoiceDateVal !== selectedRow?.InvoiceDate ? true : false,
      isInvoiceNo:
        data?.invoiceNo !== selectedRow?.["Invoice No"] ? true : false,
      isSupplier: data.supplier?.id !== selectedRow?.SupplierId ? true : false,
      menuId: location?.state?.menuId,
      privilege: privilege,
      supplierId: data.supplier ? data.supplier?.id : 0,
    };
    if (selectedRow !== null) {
      setUpdateConfirm(true);
      setFinalData(postObj);
    } else {
      warningAlert("Select row...!");
    }
  }

  function updateGrnAmendment() {
    saveGrnWoAmendment(finalData)
      .then((response) => {
        setDataResult([]);
        successAlert(response.data.message);
        setUpdateConfirm(false);
        populateTable();
        setSelectedRow(null);
        reset();
      })
      .catch((error) => {
        errorAlert(error.message);
        setUpdateConfirm(false);
      });
  }

  function cancelWOGRN() {
    cancelWOGrn(selectedRow?.GRNId, location?.state?.menuId, privilege).then(
      (response) => {
        successAlert(response.data.message);
        setCancelWOConfirm(false);
        populateTable();
        setSelectedRow(null);
      }
    );
  }

  function getStoreDropdown() {
    getStores().then((response) => {
      setStoreList(response.data.result);
    });
  }
  function getSupplierDropdown() {
    getSupplierList().then((response) => {
      setSupplierList(response.data.result);
    });
  }

  function handleSelectedRow(row) {
    setSelectedRow(row);
    setValue("supplier", {
      id: row?.SupplierId,
      value: row?.["Supplier Name"],
      label: row?.["Supplier Name"],
    });
    setValue("invoiceNo", row?.["Invoice No"]);
    setValue("invoiceDate", row?.InvoiceDate);
    setInvoiceDateVal(row?.InvoiceDate);
    setValue("gateEntryNo", row?.["Gate Entry No"]);
  }

  function renderActions(row, index) {
    return (
      <div>
        {userActions?.map((action) => {
          return (
            <>
              {action.action === "Cancel" && action.isAction === true && (
                <>
                  {row?.GRNType === 1 ? (
                    <Tooltip title="Cancel WO-GRN">
                      <button
                        type="button"
                        className="mt-1"
                        onClick={() => {
                          setCancelWOConfirm(true);
                          setPrivilege(action.action);
                        }}
                      >
                        <CancelIcon />
                      </button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="">
                      <button type="button" className="mt-1">
                        <DisableCancelIcon />
                      </button>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          );
        })}
      </div>
    );
  }

  useMemo(() => {
    populateTable();
  }, [listingObject?.grnNo, listingObject?.grnType, listingObject?.storeId]);

  useEffect(() => {
    getStoreDropdown();
    getSupplierDropdown();
  }, []);

  useEffect(() => {
    if (getUserInfo) {
      let loggedStore = storeList?.find((store) => {
        return store.id === getUserInfo?.storeId;
      });
      setValue("store", loggedStore);
    }
  }, [storeList, dataResult]);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmitData)} className="space-y-2 py-2">
      <div className="mt-12 mb-2 text-lg font-semibold text-center w-full">
        GRN Amendments
      </div>
      <div className="flex space-x-2 items-center">
        <div>
          <RadioField control={control} name="grnType" dataArray={GrnType} />
        </div>
        <div className="w-full lg:w-72">
          <DropdownField
            control={control}
            name="store"
            placeholder="Store"
            dataArray={storeList}
            // inputRef={{
            //   ...register("store", {
            //     onChange: (e) => {
            //       setListingObject((listingObject) => ({
            //         ...listingObject,
            //         storeId: e.target.value,
            //       }));
            //     },
            //   }),
            // }}
          />
        </div>
        <div className="w-full lg:w-72">
          <InputField control={control} name="grnNo" label="GRN No." />
        </div>
        <CommonButton
          type="button"
          searchIcon={true}
          className="bg-customBlue text-white"
          onClick={() => {
            setListingObject((listingObject) => ({
              ...listingObject,
              storeId: getStore ? getStore?.id : 0,
              grnNo: getGrnNo ? getGrnNo : "",
              grnType: getGrnType,
            }));
          }}
        />
      </div>
      <div className="space-y-2">
        <label className="text-base font-semibold">List of GRN's</label>
        <div>
          {dataResult !== undefined && dataResult?.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              renderActions={renderActions}
              handleSelectedRow={handleSelectedRow}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              // removeHeaders={["Id", "IsParent"]}
              populateTable={populateTable}
            />
          ) : (
            <div className="justify-center items-center font-semibold h-96 w-full flex border-2 border-gray-300 rounded my-2">
              No Records Found...
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-3">
        <div>
          <DropdownField
            control={control}
            name="supplier"
            placeholder="Supllier"
            dataArray={supplierList}
          />
        </div>
        <div>
          <InputField
            control={control}
            name="gateEntryNo"
            label="Gate Entry No"
          />
        </div>
        <div>
          <InputField control={control} name="invoiceNo" label="Invoice No" />
        </div>
        <div className="flex space-x-2">
          <DatePickerFieldNew
            control={control}
            name="invoiceDate"
            label="Invoice Date "
            value={invoiceDateVal}
            size="small"
            inputFormat="dd-MM-yyyy"
            inputRef={{
              ...register("invoiceDate", {
                onChange: (e) => {
                  setInvoiceDateVal(format(e.target.value, "yyyy-MM-dd"));
                },
              }),
            }}
          />
          {userActions?.map((action) => {
            return (
              <>
                {action.action === "Create" && action.isAction === false && (
                  <div className="flex space-x-2">
                    <CommonButton
                      type="button"
                      label="Reset"
                      className="border border-customRed text-customRed"
                      onClick={() => {
                        reset();
                        setSelectedRow(null);
                        setDataResult([]);
                      }}
                    />
                    <CommonButton
                      type="submit"
                      label="Update"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        setPrivilege(action.action);
                      }}
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      <ConfirmationModal
        confirmationOpen={updateConfirm}
        confirmationHandleClose={() => {
          setUpdateConfirm(false);
        }}
        confirmationSubmitFunc={updateGrnAmendment}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update selected row ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={cancelWOConfirm}
        confirmationHandleClose={() => {
          setCancelWOConfirm(false);
        }}
        confirmationSubmitFunc={cancelWOGRN}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to cancel WOGRN of selected row ?"
        confirmationButtonMsg="Yes"
      />
    </form>
  );
};

export default GRNAmendments;
