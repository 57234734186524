import React, { useEffect, useState } from "react";

import { Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import ChangeObjectKeyPosition from "../../../common/components/Custom Hooks/ChangeObjectKeyPosition";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  DrugListing,
  NarcoticDrugGetById,
  getFrequencyDropdown,
  patientInfo,
  postNarcotic,
} from "../../services/narcoticDrugServices/NarcoticDrugServices";
import NarcoticDrugListing from "./NarcoticDrugListing";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
// import { blue } from "@mui/material/colors";

const departmentType = [
  {
    id: 0,
    label: "OPD",
    value: 0,
  },
  {
    id: 1,
    label: "IPD",
    value: 1,
  },
];
export default function NarcoticDrug(props) {
  //
  let location = useLocation();
  console.log("props", props);
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = React.useState("");

  const [options, setOptions] = React.useState([]);
  const [searchString, setSearchString] = React.useState();
  //const [searchId, setSearchId] = React.useState(null);
  const [edit, setEdit] = useState(false);
  const [editRow, setEditRow] = useState();
  const [finalData, setFinalData] = useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const defaultValues = {
    department: 1,
    Diagnosis: "",
    SearchableSelect: null,
  };
  const { control, watch, register, handleSubmit, setValue, reset } = useForm({
    defaultValues,
  });
  const getStore = JSON.parse(localStorage.getItem("userInfo"));
  //

  ///get User Action
  useEffect(() => {
    console.log("props?.menuId", location?.state?.menuId, props?.menuId);
    (props.isFromClinicalCarechart ? props?.menuId : location?.state?.menuId) &&
      fetchUserActionsByMenuId(
        props.isFromClinicalCarechart ? props?.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    //
    setSearchString(props?.selectedPatient);
  }, []);

  let departmentTypesWatch = watch("department");

  //Autosearch API Function
  const handleChange = (autoSearchString) => {
    if (autoSearchString !== "") {
      // setSearchBar(autoSearchString);
      patientInfo(autoSearchString, departmentTypesWatch)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    } else if (autoSearchString === "") {
      // setSearchBar(null);
    }
  };

  const searchFilde = (value) => {
    if (value === null) {
      setSearchString("");
      // setSearchId(null);
    } else {
      setSearchString(value);
      // setSearchId(value.Id);
    }
  };

  let AdmId = 0;
  const [frequencyData, setFrequencyData] = useState([]);
  useEffect(() => {
    getFrequencyDropdown(AdmId)
      .then((response) => response.data)
      .then((res) => {
        setFrequencyData(res.result);
      });
  }, []);

  const [listing, setListing] = useState([]);
  const modifiedObject = ChangeObjectKeyPosition(listing[0], "isChecked", 0);
  console.log("modifiedObject", listing);

  // // Replace the original object with the modified one
  if (modifiedObject !== undefined) {
    listing[0] = modifiedObject;
  }

  function populateTable() {
    DrugListing()
      .then((response) => response.data)
      .then((res) => {
        const newArr = res.result?.map((v) => ({
          ...v,
          NarcoticDrugFormDtlsId: null,
          Frequency: "",
          FrequencyId: null,
          Qty: "",
          Duration: "",
          ReturnAmp: "",
          EmptyAmp: "",
        }));

        setListing(newArr);
      });
  }

  const [filteredData, setFilteredData] = useState([]);
  const [openPost, setOpenPost] = React.useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //findById API Function
  const editData = (row) => {
    console.log("row999999", row);
    setListing([]);

    NarcoticDrugGetById(row.NarcoticDrugFormId).then((response) => {
      console.log("response7777", response.data.result?.NarcoticDrugDetails);

      setEditRow(response.data.result);
      // let existListingrray = [...response.data.result?.NarcoticDrugDetails];
      // let newListingArray = [];
      // for (let n = 0; n < existListingrray?.length; n++) {
      //   let newObj = {
      //     NarcoticDrugFormDtlsId: existListingrray[n].NarcoticDrugFormDtlsId,
      //     Id: existListingrray[n].Id,
      //     "Drug Name": existListingrray[n]?.["Drug Name"],
      //     Frequency: existListingrray[n].Frequency,
      //     FrequencyId: existListingrray[n].FrequencyId,
      //     Qty: existListingrray[n].Qty,
      //     Duration: existListingrray[n].Duration,
      //     ReturnAmp: existListingrray[n].ReturnAmp,
      //     EmptyAmp: existListingrray[n].EmptyAmp,]
      //     IsChecked: existListingrray[n].IsChecked,
      //   };
      //   newListingArray.push(newObj);
      //   setListing(newListingArray);
      // }
      setListing(response.data.result?.NarcoticDrugDetails);
    });
  };

  const onSubmitDataHandler = (data) => {
    let isOneSelected = !!listing && listing?.some((item) => item.isChecked);
    console.log("listing", listing, isOneSelected);

    if (!!editRow || !!searchString) {
      if (isOneSelected) {
        let tempArray = [];
        for (let t = 0; t < listing?.length; t++) {
          if (listing[t]?.isChecked === true) {
            let tempObj = {
              dose: "",
              drugDetailsId:
                listing[t]?.NarcoticDrugFormDtlsId === 0
                  ? 0
                  : listing[t].NarcoticDrugFormDtlsId,
              drugId: listing[t]?.Id,
              drugName: listing[t]?.["Drug Name"],
              duration: listing[t]?.Duration,
              emptyAmp: listing[t]?.EmptyAmp,
              frequency: listing[t]?.Frequency?.label || listing[t]?.Frequency,
              frequencyId: listing[t]?.Frequency?.id || listing[t]?.FrequencyId,
              qty: listing[t]?.Qty,
              returnAmp: listing[t]?.ReturnAmp,
              selStatus: 0,
            };
            tempArray.push(tempObj);
          }
        }

        if (edit === false) {
          let obj = {
            addedBy: getStore.userId,
            addedDateTime: "2023-12-20T12:39:27.391Z",
            address: searchString?.address || searchString?.Address,
            age: searchString?.AgeYear,
            diagnosis: data.Diagnosis,
            gender: searchString?.gender || searchString?.Gender,
            mrNo: searchString?.MRNo,
            privilege: privilege,
            menuId: location?.state?.menuId,
            narcoticDrugDetailsDtoList: tempArray,
            narcoticDrugId: 0,
            opdIpdExternal: departmentTypesWatch,

            opdIpdExternalId:
              departmentTypesWatch === "0"
                ? searchString?.VisitId
                : searchString?.AdmissionId,
            patientId: searchString?.id || props?.PatientId,
            patientName: searchString?.patientName || searchString?.PatientName,
            updatedBy: getStore.userId,
            updatedDateTime: "2023-12-20T12:39:27.391Z",
            updatedDateTimePharmacy: "2023-12-20T12:39:27.391Z",
            updatedPharmacy: 0,
          };

          setFinalData(obj);
          setOpenPost(true);
        } else if (edit === true) {
          let putobj = {
            addedBy: 0,
            addedDateTime: "2023-12-20T12:39:27.391Z",
            address: editRow?.Address,
            age: editRow?.Age,
            diagnosis: data.Diagnosis,
            gender: editRow?.gender,
            mrNo: editRow?.MRNo,
            narcoticDrugDetailsDtoList: tempArray,
            privilege: privilege,
            menuId: location?.state?.menuId,
            narcoticDrugId:
              editRow === undefined ? 0 : editRow.NarcoticDrugFormId,
            opdIpdExternal: editRow?.Opd_Ipd_External,

            opdIpdExternalId: editRow?.Opd_Ipd_External_Id,
            patientId: editRow?.PatientId,
            patientName: editRow?.PatientName,
            updatedBy: 0,
            updatedDateTime: "2023-12-20T12:39:27.391Z",
            updatedDateTimePharmacy: "2023-12-20T12:39:27.391Z",
            updatedPharmacy: 0,
          };

          setFinalData(putobj);
          setOpenPost(true);
        }
      } else {
        warningAlert("Kindly select Drug.");
      }
    } else {
      warningAlert("Please Select Patient...");
    }
  };

  console.log("finalData", finalData);

  //Post / Add API Function
  function addRecord() {
    setOpenBackdrop(true);
    postNarcotic(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        // props.setOpen(false);
        // props.setEdit(false);
        setOpenPost(false);
        setListing([]);
        populateTable();
        reset(defaultValues);
        setEditRow([]);
        // props.populateTable();
        // setOpenPut(false);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  console.log("listing123", listing);

  const renderInput = (row, index, header) => {
    // const handleChange = (e, index) => {
    //   let tempData = [...listing];
    //   tempData[index].IsChecked = e.target.checked;
    //   setListing(tempData);
    // };
    setValue(
      `row${index}`,
      row?.Frequency !== "" && row?.Frequency !== null
        ? {
            id: row?.FrequencyId,
            value: row?.Frequency,
            label: row?.Frequency,
          }
        : null
    );
    setValue(`Qty${index}`, row?.Qty ? row?.Qty : "");
    setValue(`Duration${index}`, row?.Duration !== 0 ? row?.Duration : "");
    setValue(`ReturnAmp${index}`, row?.ReturnAmp !== 0 ? row?.ReturnAmp : "");
    setValue(`EmptyAmp${index}`, row?.EmptyAmp !== 0 ? row?.EmptyAmp : "");

    return (
      <div className="flex ">
        {/* {header === "IsChecked" && (
          <Checkbox
            size="small"
            control={control}
            checked={row?.IsChecked}
            sx={{
              "&..MuiCheckbox-root": {
                height: "10px",
                margin: "0px",
                Padding: 0,
              },
            }}
            className=" border w-4  m-0 border-gray-600 rounded bg-transparent"
            type="checkbox"
            color="primary"
            name={`checkbox${index}`}
            {...register(`checkbox${index}`)}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        )} */}
        {header === "Frequency" ? (
          <DropdownField
            control={control}
            name={`row${index}`}
            placeholder="Frequency"
            dataArray={frequencyData}
            // defaultValue={
            //   row?.Frequency !== "" && row?.Frequency !== null
            //     ? {
            //         id: row?.FrequencyId,
            //         value: row?.Frequency,
            //         label: row?.Frequency,
            //       }
            //     : null
            // }
            inputRef={{
              ...register(`row${index}`, {
                onChange: (e) => {
                  let tempData = [...listing];
                  tempData[index].Frequency = e.target.value?.label;
                  tempData[index].FrequencyId = e.target.value?.id;
                  setListing(tempData);
                },
              }),
            }}
          />
        ) : null}
        {header === "Qty" ? (
          <InputField
            control={control}
            name={`Qty${index}`}
            placeholder="Qty"
            sx={{ width: "100px" }}
            // defaultValue={row?.Qty ? row?.Qty : ""}
            inputRef={{
              ...register(`Qty${index}`, {
                onChange: (e) => {
                  let tempData = [...listing];
                  tempData[index].Qty = e.target.value;
                  setListing(tempData);
                },
              }),
            }}
          />
        ) : null}
        {header === "Duration" ? (
          <InputField
            control={control}
            name={`Duration${index}`}
            placeholder="Duration"
            sx={{ width: "100px" }}
            // defaultValue={row?.Duration !== 0 ? row?.Duration : ""}
            inputRef={{
              ...register(`Duration${index}`, {
                onChange: (e) => {
                  let tempData = [...listing];
                  tempData[index].Duration = e.target.value;
                  setListing(tempData);
                },
              }),
            }}
          />
        ) : null}
        {header === "ReturnAmp" ? (
          <InputField
            control={control}
            name={`ReturnAmp${index}`}
            placeholder="ReturnAmp"
            sx={{ width: "100px" }}
            // defaultValue={row?.ReturnAmp !== 0 ? row?.ReturnAmp : ""}
            inputRef={{
              ...register(`ReturnAmp${index}`, {
                onChange: (e) => {
                  let tempData = [...listing];
                  tempData[index].ReturnAmp = e.target.value;
                  setListing(tempData);
                },
              }),
            }}
          />
        ) : null}
        {header === "EmptyAmp" ? (
          <InputField
            control={control}
            name={`EmptyAmp${index}`}
            placeholder="EmptyAmp"
            sx={{ width: "100px" }}
            // defaultValue={row?.EmptyAmp !== 0 ? row?.EmptyAmp : ""}
            inputRef={{
              ...register(`EmptyAmp${index}`, {
                onChange: (e) => {
                  let tempData = [...listing];
                  tempData[index].EmptyAmp = e.target.value;
                  setListing(tempData);
                },
              }),
            }}
          />
        ) : null}

        {/* :"",
          :"", */}
      </div>
    );
  };

  useEffect(() => {
    populateTable();
  }, []);

  useEffect(() => {
    setValue(
      "Diagnosis",
      props?.selectedPatient?.Diagnosis || searchString?.Diagnosis || ""
    );

    if (editRow !== undefined) {
      setValue("Diagnosis", editRow.Diagnosis);
    }
  }, [editRow, searchString]);

  console.log(
    "props?.selectedPatient"
    // editData
  );

  useEffect(() => {
    for (let obj of listing) {
      console.log("objobjobj",obj);
      
      if (obj.isChecked === false) {
        obj.Frequency = "";
        obj.Qty = "";
        obj.Duration = "";
        obj.ReturnAmp = "";
        obj.EmptyAmp = "";


      }
    }
  }, [listing]);

  return (
    <div className="mt-16">
      <h1 className="text-center font-bold text-xl py-3">
        Narcotic Drug And Pshychotropic Substances Prescrition (NDPS II)
      </h1>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="w-full pt-2 flex justify-between ">
          <div className="flex w-[50%]">
            <div className="w-96">
              <SearchDropdown
                control={control}
                type="button"
                name="SearchableSelect"
                placeholder="Search By Patient Name / MR No"
                searchIcon={true}
                isClearable={true}
                handleInputChange={handleChange}
                dataArray={options}
                isDisabled={props?.isFromClinicalCarechart ? true : false}
                inputRef={{
                  ...register("SearchableSelect", {
                    onChange: (e) => {
                      searchFilde(e.target.value);
                    },
                  }),
                }}
              />
            </div>
            <div className="mt-2 whitespace-nowrap w-[25%] ml-4">
              <RadioField
                control={control}
                name="department"
                dataArray={departmentType}
              />
            </div>
          </div>

          <div className="flex justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {obj.action === "View" ? (
                      <div className="flex gap-2">
                        <CommonButton
                          className="bg-customBlue text-white"
                          type="button"
                          label="View"
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        <div className="w-full flex bg-slate-100 border border-gray-300 rounded px-2 mt-1 text-[14px] py-4 ">
          <div className="w-[30%]">
            <span className="font-semibold">Patient Name</span>
            <span>:</span>
            <span>
              {!editRow
                ? searchString?.patientName || searchString?.PatientName
                : editRow?.PatientName}
            </span>
          </div>
          <div className="w-[20%]">
            <span className="font-semibold">Age</span>
            <span>:</span>
            <span>
              {editRow === undefined ? searchString?.AgeYear : editRow?.Age}
            </span>
          </div>
          <div className="w-[40%]">
            <span className="font-semibold">Patient Address</span>
            <span>:</span>
            <span>
              {editRow === undefined
                ? searchString?.address || searchString?.Address
                : editRow?.Address}
            </span>
          </div>
          <div className="w-[10%]">
            <span className="font-semibold">Gender</span>
            <span>:</span>
            <span>
              {editRow === undefined
                ? searchString?.gender || searchString?.Gender
                : editRow?.Gender}
            </span>
          </div>
        </div>
        <div className="w-full mt-1">
          <InputArea
            rowsMin={5}
            placeholder="Diagnosis"
            label="Diagnosis"
            name={"Diagnosis"}
            control={control}
            disabled={searchString === undefined}
          />
        </div>
        <p className="text-[13px] font-semibold my-3">
          Note: &nbsp;&nbsp;&nbsp;
          <span>
            As Per Gezette Of India No.285 Extrordinary-II (Section-3)PG No-21
            ,Clause 52G IT Mandatory That The Prescription Shall Be In Writing
            ,Dated And Signed By The Practitioner With His Full Name
          </span>
        </p>

        <div>
          {listing?.length > 0 ? (
            <CommonDynamicTableNew
              dataResult={listing}
              setDataResult={setListing}
              removeHeaders={[
                "Id",
                "NarcoticDrugFormDtlsId",
                "FrequencyId",
                "Dose",
                "GroupOrder",
                "isChecked",
                "IsChecked",
              ]}
              editableColumns={[
                "IsChecked",
                "Frequency",
                "Qty",
                "Duration",
                "ReturnAmp",
                "EmptyAmp",
              ]}
              renderInput={renderInput}
              enableSelectAll={true}
              highlightRow={false}
            />
          ) : null}
        </div>
        <div className="flex justify-end gap-2 pt-2">
          {userActions.map((obj) => (
            <>
              {!obj.isAction ? (
                <>
                  {obj.action === "Create" ? (
                    <div className="flex gap-2">
                      <CommonButton
                        label="Reset"
                        className=" border border-red-600 text-red-600"
                        type="button"
                        onClick={() => {
                          reset(defaultValues);
                          setSearchString({});
                          setListing([]);
                          populateTable();
                          setEditRow([]);
                        }}
                      />

                      <CommonButton
                        label="Save"
                        className="bg-customGreen text-white"
                        type="Submit"
                        onClick={() => {
                          setPrivilege(obj.action);
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ))}
        </div>
        {open && (
          <NarcoticDrugListing
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleOpen={handleOpen}
            setEdit={setEdit}
            editData={editData}
            searchString={searchString}
            userActions={userActions}
            setPrivilege={setPrivilege}
            setListing={setListing}
          />
        )}
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />

        {/* backdrop */}
        {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
      </form>
    </div>
  );
}

