import authHeader from "../../../authentication/authservices/auth-header";
import apiClientBilling from "../../../http-common";

export const searchPatientList = (searchString) => {
  return apiClientBilling.get(
    `/patientInfo/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const ipdAutocomplete = (string) => {
  return apiClientBilling.get(`/patientInfo/admissionAutoComplete//${string}`, {
    headers: authHeader(),
  });
};
export const searchPatientInfo = (patientId) => {
  return apiClientBilling.get(`/patientInfo/registration/${patientId}`, {
    headers: authHeader(),
  });
};

export const treatingDoctorList = (searchString) => {
  return apiClientBilling.get(
    `/billEstimation/treatingDoctorList/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const templateList = (searchString) => {
  return apiClientBilling.get(`/billEstimation/templateList/${searchString}`, {
    headers: authHeader(),
  });
};

export const procedureService = (searchString) => {
  return apiClientBilling.get(
    `/billEstimation/procedureService/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const groupSelectionDropDown = async () => {
  return await apiClientBilling.get(`/billEstimation/groupSelection`, {
    headers: authHeader(),
  });
};

export const groupServiceDropDown = async () => {
  return await apiClientBilling.get(`/billEstimation/groupService`, {
    headers: authHeader(),
  });
};

export const bedCategoryList = async () => {
  return await apiClientBilling.get(`/billEstimation/bedCategory`, {
    headers: authHeader(),
  });
};

export const addNewBillEstimation = async (createBillEstimation) => {
  return await apiClientBilling.post(
    `billEstimation/saveBillEstimation`,
    createBillEstimation,
    {
      headers: authHeader(),
    }
  );
};

//get template details
export const getTemplateDetailsById = (templateId) => {
  return apiClientBilling.get(`/billEstimation/templateDetails/${templateId}`, {
    headers: authHeader(),
  });
};

//get bill estimation Listing
export const getEstimationListing = (searchObject) => {
  return apiClientBilling.post(
    `/billEstimation/billEstimationListing`,
    searchObject,
    {
      headers: authHeader(),
    }
  );
};
