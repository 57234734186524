////

import apiClient from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";

//dashbord Menues
export const fetchDashboardMenus = (userId) => {
  return apiClient.get(`/dashboard/usermenus/${userId}`, {
    headers: authHeader(),
  });
};
//

//dashbord Menues
export const fetchIconImg = (iconPath) => {
  return apiClient.get(`/file/${iconPath}`, {
    headers: authHeader(),
  });
};
//

export const fetchDashboardIcon = (icon) => {
  icon.map((item, index) => {
    return apiClient.get(`/file/${item.iconPath}`, {
      headers: authHeader(),
    });
  });
};
