import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Box } from "@mui/material";
import LoadingSpinner from "../loadingspinner/loadingSpinner";
//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CommonDynamicTablePagination(props) {
  const {
    tableApiFunc,
    dataResult,
    setDataResult,
    page,
    setPage,
    tableClass,
    rowsPerPage,
    setRowsPerPage,
    count,
    renderActions,
    defaultParams,
    actionsRequired,
  } = props;

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [rowIndex, setRowIndex] = React.useState();
  const [oldCount, setOldCount] = React.useState();
  //
  const [isLoading, setIsLoading] = React.useState(false);

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //
  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(
    allHeaders,
    props.removeHeaders ? props.removeHeaders : ["id"]
  );

  //   const defaultParams = {
  //     page: page,
  //     size: rowsPerPage,
  //     searchString: searchString,
  //     fromDate: selectedFromDate,
  //     toDate: selectedToDate,
  //     // doctorId,
  //     // cabinId,
  //     // unitId,
  //   };

  React.useEffect(() => {
    if (oldCount !== undefined) {
      checkCallApi(page, rowsPerPage, oldCount);
    }
  }, [page, rowsPerPage, oldCount]);

  React.useEffect(() => {
    if (props.resetCount) {
      setRowIndex(-1);
    }
  }, [props?.resetCount]);

  const checkCallApi = (pageNo, rowsPerPageNo, oldTotal) => {
    pageNo = pageNo + 1;
    let newTotal = pageNo * rowsPerPageNo;
    let arr = [...dataResult];
    let totalDataLength = count;
    let availableDataLength = arr.length;
    if (totalDataLength > availableDataLength) {
      // if i dont have total record to show
      if (newTotal > availableDataLength) {
        //
        let require = newTotal - availableDataLength;
        let toShow = availableDataLength + require;

        // check available vs needed
        // needed is less than count
        let needed;
        if (toShow < totalDataLength) {
          needed = newTotal - oldTotal;
          callAgainTableApi(needed);
        } else if (toShow > totalDataLength) {
          needed = toShow - totalDataLength;
          callAgainTableApi(needed);
        } else {
          needed = rowsPerPageNo;
          callAgainTableApi(needed);
        }
      }
    }
  };
  // const callAgainTableApi = (recordsNeeded) => {
  //   tableApiFunc(defaultParams)
  //     .then((response) => response.data)
  //     .then((res) => {
  //       let incomingData = res.result;
  //       let onlyNeededData = incomingData.slice(-Math.abs(recordsNeeded));

  //       // append needed data
  //       let existData = [...dataResult];
  //       for (let value of onlyNeededData) {
  //         existData.push(value);
  //       }
  //       setDataResult(existData);
  //     });
  // };

  const callAgainTableApi = (recordsNeeded) => {
    setIsLoading(true); // Set loading state before API call

    tableApiFunc(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        let incomingData = res.result;
        let onlyNeededData = incomingData.slice(-Math.abs(recordsNeeded));

        // append needed data
        let existData = [...dataResult];
        for (let value of onlyNeededData) {
          existData.push(value);
        }
        setDataResult(existData);
        setIsLoading(false); // Reset loading state after data is fetched
      });
  };

  // const handlePageChange = (event, newPage) => {
  //   if (newPage > page) {
  //     // Next button clicked, call API
  //     setOldCount((page + 1) * rowsPerPage);
  //     setPage(newPage);
  //   } else if (newPage < page) {
  //     // Previous button clicked, only update the page state
  //     setPage(newPage);
  //   }
  // };

  const handlePageChange = (event, newPage) => {
    if (newPage > page) {
      setOldCount((page + 1) * rowsPerPage);
      setPage(newPage);
    } else if (newPage < page) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setOldCount((page + 1) * rowsPerPage);
    let newRows = parseInt(event.target.value, 10);
    let newTotal = (page + 1) * newRows;
    let additionalRecord = newTotal - count;

    if (additionalRecord > newRows) {
      setPage(page - 1);
    }
    setRowsPerPage(newRows);
  };

  //table start
  //
  return (
    <div className="w-auto grid">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 2, border: "1px solid lightgray" }}>
          {/* pagination */}
          <div className="flex justify-between items-center">
            <div className="font-semibold text-sm">{props?.tableHeading}</div>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              sx={{
                ".MuiTablePagination-toolbar": {
                  // backgroundColor: "lightblue",
                  minHeight: "35px",
                },
              }}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                disabled: isLoading,
              }}
              backIconButtonProps={
                isLoading
                  ? {
                      disabled: isLoading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                isLoading
                  ? {
                      disabled: isLoading,
                    }
                  : undefined
              }
            />
          </div>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "lightBlue",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1e3a8a",
                cursor: "pointer",
              },
            }}
            className={tableClass}
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  {actionsRequired ? (
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                  ) : null}

                  {/* heading of table */}
                  {headers.map((header, index) => (
                    <TableCell
                      sortDirection={orderBy === header ? order : false}
                      className="whitespace-nowrap"
                      key={index}
                    >
                      <TableSortLabel
                        active={false} //arrow for sorting
                        direction={orderBy === header ? order : "asc"}
                        onClick={createSortHandler(header)}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                        {orderBy === header ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(dataResult, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //splice use for show rows upto 5 when splice is not use that time show all rows
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          setRowIndex(index);
                          {
                            props.handleSelectedRow &&
                              props.handleSelectedRow(row, index);
                          }

                          {
                            props.setSelectedRow && props.setSelectedRow(row);
                          }
                        }}
                        sx={{
                          "& td": {
                            paddingY: 0.1,
                          },
                        }}
                        style={{
                          backgroundColor:
                            (props.highlightRow === undefined ||
                              props.highlightRow === true) &&
                            rowIndex === index
                              ? props.customRowBgColor || "#FFC44B"
                              : props.rowBackgroundColor
                              ? props.rowBackgroundColor(row, index)
                              : "",
                        }}
                      >
                        {actionsRequired ? (
                          <TableCell>{renderActions(row, index)}</TableCell>
                        ) : null}
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell
                              className="whitespace-nowrap capitalize"
                              key={i}
                              // onClick={() => {
                              //   props.displayView(row, index);
                              // }}
                            >
                              {row[header] === true
                                ? "Yes"
                                : row[header] === false
                                ? "No"
                                : props.editableColumns &&
                                  props.editableColumns.includes(header)
                                ? props.renderInput(row, index, header)
                                : row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            {isLoading && (
              <div className="flex justify-center text-gray-400 font-semibold my-5">
                <LoadingSpinner />
              </div>
            )}
          </TableContainer>

          {/* //table end */}
        </Paper>
      </Box>
    </div>
  );
}
