import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useRef, useState } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const tableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

export default function CommonDynamicTableNew(props) {
  const {
    dataResult,
    setDataResult, //need to pass only if we want enable SelectAll checkbox or enableSwapRow functionality.
    removeHeaders, //send array of headers which need to remove.  NOTE:send at least one header i.e. id.
    tableClass, //required css for tableContainer.i.e. height ,etc.
    renderActions, //render Actions @1st column i.e.icons,checkboxes,etc.
    highlightRow, //default row highlighted,if not want to highlight set as false.
    customRowBgColor, //usefull when required another bg color of selected row than default.
    rowBackgroundColor, //use this to show conditional row bg color .
    handleSelectedRow, //get row onclick use this fn..
    editableColumns, //array of headers to make column editable.
    renderInput, //actual content to render i.e. input,dropdown,checkbox,icon,etc.
    enableSelectAll, ///to enable select all checkbox functionality.
    enableSwapRow, ///to enable row swap i.e. up-down functionality.
  } = props;

  const [rowIndex, setRowIndex] = useState(-1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const tableRef = useRef(null);

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const removeHeader = (headers, fieldToRemove) => {
    return headers?.filter((v) => !fieldToRemove?.includes(v));
  };

  const allHeaders = Object?.keys(dataResult[0]);
  const headers = removeHeaders
    ? removeHeader(allHeaders, removeHeaders)
    : allHeaders;

  const handleSelectAll = () => {
    const allChecked = dataResult?.every(
      (row) => row?.isChecked || row?.IsChecked
    );
    const updatedDataResult = dataResult?.map((row) => ({
      ...row,
      isChecked: !allChecked,
    }));
    setDataResult && setDataResult(updatedDataResult);
  };

  const handleRowSelect = (index) => {
    const updatedDataResult = [...dataResult];
    updatedDataResult[index] = {
      ...updatedDataResult[index],
      isChecked: !updatedDataResult[index].isChecked,
    };
    setDataResult && setDataResult(updatedDataResult);
  };

  ////

  const handleKeyDown = (event) => {
    // event.preventDefault()///do not write this line here ...
    if (
      isFocused &&
      (highlightRow === undefined || highlightRow === true) &&
      rowIndex >= 0
    ) {
      if (event?.key === "ArrowUp" && rowIndex > 0) {
        const selectedRow = dataResult[rowIndex - 1];

        setRowIndex(rowIndex - 1);
        event?.preventDefault();
        handleSelectedRow(selectedRow, rowIndex - 1);
      } else if (
        event?.key === "ArrowDown" &&
        rowIndex < dataResult?.length - 1
      ) {
        const selectedRow = dataResult[rowIndex + 1];

        setRowIndex(rowIndex + 1);
        handleSelectedRow(selectedRow, rowIndex + 1);

        event?.preventDefault();
      } else if (event?.key === "Enter") {
        const selectedRow = dataResult[rowIndex];
        handleSelectedRow(selectedRow, rowIndex);
        event?.preventDefault();
      }
    }
  };

  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const currentTable = tableRef?.current;
    currentTable?.addEventListener("focus", handleFocus);
    currentTable?.addEventListener("blur", handleBlur);
    document?.addEventListener("keydown", handleKeyDown);

    return () => {
      currentTable?.removeEventListener("focus", handleFocus);
      currentTable?.removeEventListener("blur", handleBlur);
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFocused, rowIndex]);

  ////////

  useEffect(() => {
    if (
      (highlightRow === undefined || highlightRow === true) &&
      tableRef?.current &&
      rowIndex !== null &&
      typeof rowIndex === "number"
    ) {
      const selectedRow = tableRef?.current?.querySelector(
        `tr:nth-child(${rowIndex + 1})`
      );
      if (selectedRow) {
        selectedRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [rowIndex]);

  ///////

  useEffect(() => {
    if (rowIndex !== -1) {
      setRowIndex(-1);
    }
  }, [dataResult]);

  ///////swap row

  const swapAndSet = (index1, index2) => {
    setDataResult &&
      setDataResult((prevData) => {
        const temp = [...prevData];
        [temp[index1], temp[index2]] = [temp[index2], temp[index1]];

        return temp;
      });
  };

  const handleUp = (index) => {
    if (index > 0) swapAndSet(index, index - 1);
  };

  const handleDown = (index) => {
    if (index < dataResult?.length - 1) swapAndSet(index, index + 1);
  };
  ///
  return (
    <div className="grid w-auto">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#ebebeb",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#7393b3",
                borderRadius: 4,
                cursor: "pointer",
              },
            }}
            className={tableClass}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: 1,
                borderColor: "#e0e0e0",
                paddingY: "scroll",
                outline: "none",
              }}
              ref={tableRef}
              tabIndex="0"
              className="MuiTableContainer-root"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  {enableSelectAll && (
                    <TableCell>
                      <Checkbox
                        size="small"
                        checked={dataResult?.every(
                          (row) => row?.isChecked || row?.IsChecked
                        )}
                        onChange={handleSelectAll}
                        style={{ padding: 0 }}
                      />
                    </TableCell>
                  )}
                  {enableSwapRow && (
                    <TableCell className="whitespace-nowrap sticky left-0 z-1000 bg-white">
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Swap
                      </span>
                    </TableCell>
                  )}
                  {renderActions && (
                    <TableCell className="whitespace-nowrap sticky left-0 z-1000 bg-white">
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                  )}
                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      sortDirection={orderBy === header ? order : false}
                      className="whitespace-nowrap"
                    >
                      <TableSortLabel
                        active={orderBy === header}
                        direction={orderBy === header ? order : "asc"}
                        onClick={createSortHandler(header)}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                        {orderBy === header ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableSort(dataResult, getComparator(order, orderBy))?.map(
                  (row, index) => (
                    <TableRow
                      key={index}
                      hover={false}
                      style={{
                        backgroundColor:
                          (highlightRow === undefined ||
                            highlightRow === true) &&
                          rowIndex === index
                            ? customRowBgColor || "#FFC44B"
                            : rowBackgroundColor
                            ? rowBackgroundColor(row, index)
                            : "",
                      }}
                      sx={{
                        "& td": renderActions
                          ? { paddingY: 0.1 }
                          : { paddingY: 0.5 },
                      }}
                      onClick={() => {
                        setRowIndex(index);
                        handleSelectedRow && handleSelectedRow(row, index);
                      }}
                    >
                      {enableSelectAll && (
                        <TableCell>
                          <Checkbox
                            size="small"
                            checked={row?.isChecked || row?.IsChecked || false}
                            onChange={() => handleRowSelect(index)}
                            style={{ padding: 0 }}
                          />
                        </TableCell>
                      )}
                      {enableSwapRow && (
                        <TableCell>
                          <div className="flex">
                            {index > 0 ? (
                              <div>
                                <ArrowUpwardIcon
                                  size="small"
                                  sx={{ color: "#777577" }}
                                  onClick={() => {
                                    handleUp(index);
                                  }}
                                />
                              </div>
                            ) : null}
                            {index < dataResult?.length - 1 ? (
                              <div>
                                <ArrowDownwardIcon
                                  size="small"
                                  sx={{ color: "#777577" }}
                                  onClick={() => {
                                    handleDown(index);
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        </TableCell>
                      )}
                      {renderActions && (
                        <TableCell>{renderActions(row, index)}</TableCell>
                      )}
                      {headers.map((header, i) => (
                        <TableCell className="whitespace-nowrap" key={i}>
                          {editableColumns && editableColumns?.includes(header)
                            ? renderInput(row, index, header)
                            : row[header] === true
                            ? "Yes"
                            : row[header] === false
                            ? "No"
                            : row[header]}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}

// import Box from "@mui/material/Box";
// import Checkbox from "@mui/material/Checkbox";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
// import { visuallyHidden } from "@mui/utils";
// import * as React from "react";
// import { useEffect, useRef, useState } from "react";

// const descendingComparator = (a, b, orderBy) => {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// };

// const getComparator = (order, orderBy) => {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// };

// const tableSort = (array, comparator) => {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// };

// export default function CommonDynamicTableNew(props) {
//   const {
//     dataResult,
//     setDataResult, //need to pass only if we want enable SelectAll checkbox functionality
//     removeHeaders, //send array of headers which need to remove.  NOTE:send at least one header i.e. id
//     tableClass, //required css for tableContainer.i.e. height ,etc.
//     renderActions, //render Actions @1st column i.e.icons,checkboxes,etc.
//     highlightRow, //default row highlighted,if not want to highlight set as false.
//     customRowBgColor, //usefull when required another bg color of selected row than default.
//     rowBackgroundColor, //use this to show conditional row bg color .
//     handleSelectedRow, //get row onclick use this fn..
//     editableColumns, //array of headers to make column editable
//     renderInput, //actual content to render i.e. input,dropdown,checkbox,icon,etc
//     enableSelectAll, ///to enable select all checkbox funtionality
//   } = props;

//   const [rowIndex, setRowIndex] = React.useState(-1);

//   const [order, setOrder] = React.useState("asc");
//   const [upAndDownKey, setUpAndDownKey] = React.useState(true);

//   const [orderBy, setOrderBy] = React.useState();
//   const componentRef = useRef(null);

//   // const [highlightedRowIndex, setHighlightedRowIndex] = React.useState(-1);

//   const createSortHandler = (property) => (event) => {
//     handleSortRequest(event, property);
//   };

//   const handleSortRequest = (event, property) => {
//     const isAsc = orderBy === property && order === "asc";
//     setOrder(isAsc ? "desc" : "asc");
//     setOrderBy(property);
//   };

//   const removeHeader = (headers, fieldToRemove) => {
//     return headers.filter((v) => {
//       return !fieldToRemove.includes(v);
//     });
//   };

//   const allHeaders = Object.keys(dataResult[0]);
//   const headers = removeHeaders
//     ? removeHeader(allHeaders, removeHeaders && removeHeaders)
//     : allHeaders;
//   ///
//   //////

//   const handleSelectAll = () => {
//     const allChecked = dataResult.every((row) => row.isChecked);

//     // Toggle isChecked for all rows
//     const updatedDataResult = dataResult.map((row) => ({
//       ...row,
//       isChecked: !allChecked,
//     }));

//     setDataResult(updatedDataResult);
//   };

//   const handleRowSelect = (index) => {
//     const updatedDataResult = [...dataResult];
//     updatedDataResult[index] = {
//       ...updatedDataResult[index],
//       isChecked: !updatedDataResult[index].isChecked,
//     };

//     setDataResult(updatedDataResult);
//   };
//   //////

//   const handleKeyDown = (event) => {
//     console.log("componentRef", componentRef);
//     const current = componentRef.current;
//     if (
//       current &&
//       (highlightRow === undefined || highlightRow === true) &&
//       rowIndex >= 0 &&
//       upAndDownKey === true
//     ) {
//       if (event.key === "ArrowUp" && rowIndex > 0) {
//         setRowIndex(rowIndex - 1);
//       } else if (
//         event.key === "ArrowDown" &&
//         rowIndex < dataResult.length - 1
//       ) {
//         setRowIndex(rowIndex + 1);
//       }
//     }
//   };

//   React.useEffect(() => {
//     const handleClick = (event) => {
//       if (!event.target.closest(".MuiTableContainer-root")) {
//         setUpAndDownKey(false);
//       } else {
//         setUpAndDownKey(true);
//       }
//     };
//     document.addEventListener("click", handleClick);
//     return () => {
//       document.removeEventListener("click", handleClick);
//     };
//   }, []);

//   React.useEffect(() => {
//     document.addEventListener("keydown", handleKeyDown);
//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//     console.log();
//   }, [rowIndex, upAndDownKey]);

//   useEffect(() => {
//     if (rowIndex !== -1) {
//       setRowIndex(-1);
//     }
//   }, [dataResult]);

//   return (
//     <>
//       <div className="grid w-auto">
//         <Box sx={{ width: "100%", overflow: "hidden" }}>
//           <Paper sx={{ width: "100%", my: 1 }}>
//             <TableContainer
//               sx={{
//                 "&::-webkit-scrollbar": {
//                   width: 7,
//                   height: 10,
//                 },
//                 "&::-webkit-scrollbar-track": {
//                   backgroundColor: "#ebebeb",
//                 },
//                 "&::-webkit-scrollbar-thumb": {
//                   backgroundColor: "#7393b3",
//                   borderRadius: 4,
//                 },
//               }}
//               className={tableClass}
//             >
//               <Table
//                 size="small"
//                 stickyHeader
//                 aria-label="sticky table"
//                 sx={{
//                   border: 1,
//                   borderColor: "#e0e0e0",
//                   paddingY: "scroll",
//                 }}
//                 ref={componentRef}
//                 className="MuiTableContainer-root"
//               >
//                 <TableHead>
//                   <TableRow
//                     sx={{
//                       "& th": {
//                         paddingY: 0.5,
//                         backgroundColor: "#F1F1F1",
//                       },
//                     }}
//                   >
//                     {enableSelectAll && (
//                       <TableCell>
//                         <Checkbox
//                           size="small"
//                           checked={dataResult.every((row) => row.isChecked)}
//                           onChange={handleSelectAll}
//                           style={{ padding: 0 }}
//                         />
//                       </TableCell>
//                     )}
//                     {renderActions && (
//                       <TableCell
//                         className={`whitespace-nowrap ${"sticky left-0 z-1000 bg-white"}`}
//                       >
//                         <span className="text-gray-600 font-bold whitespace-nowrap">
//                           Actions
//                         </span>
//                       </TableCell>
//                     )}
//                     {headers.map((header, index) => {
//                       return (
//                         <TableCell
//                           sortDirection={orderBy === header ? order : false}
//                           className="whitespace-nowrap"
//                         >
//                           <TableSortLabel
//                             active={false}
//                             direction={orderBy === header ? order : "asc"}
//                             onClick={createSortHandler(header)}
//                             key={index}
//                           >
//                             <span className="text-gray-600 font-bold">
//                               {header}
//                             </span>
//                             {orderBy === header ? (
//                               <Box component="span" sx={visuallyHidden}>
//                                 {order === "desc"
//                                   ? "sorted descending"
//                                   : "sorted ascending"}
//                               </Box>
//                             ) : null}
//                           </TableSortLabel>
//                         </TableCell>
//                       );
//                     })}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {tableSort(dataResult, getComparator(order, orderBy)).map(
//                     (row, index) => {
//                       return (
//                         <TableRow
//                           key={index}
//                           hover={false}
//                           style={{
//                             backgroundColor:
//                               (highlightRow === undefined ||
//                                 highlightRow === true) &&
//                               rowIndex === index
//                                 ? customRowBgColor || "#FFC44B"
//                                 : rowBackgroundColor
//                                 ? rowBackgroundColor(row, index)
//                                 : "",
//                           }}
//                           sx={{
//                             "& td": renderActions
//                               ? {
//                                   paddingY: 0.1,
//                                 }
//                               : { paddingY: 0.5 },
//                           }}
//                           onClick={() => {
//                             setRowIndex(index);
//                             handleSelectedRow && handleSelectedRow(row, index);
//                           }}
//                         >
//                           {enableSelectAll && (
//                             <TableCell>
//                               <Checkbox
//                                 size="small"
//                                 checked={row.isChecked ? true : false}
//                                 onChange={() => handleRowSelect(index)}
//                                 style={{ padding: 0 }}
//                               />
//                             </TableCell>
//                           )}
//                           {renderActions && (
//                             <TableCell>{renderActions(row, index)}</TableCell>
//                           )}
//                           {headers &&
//                             headers.map((header, i) => (
//                               <TableCell
//                                 className="whitespace-nowrap"
//                                 key={i}
//                                 onClick={() => {
//                                   //  displayView(row, index);
//                                 }}
//                               >
//                                 {editableColumns &&
//                                 editableColumns.includes(header)
//                                   ? renderInput(row, index, header)
//                                   : row[header] === true
//                                   ? "Yes"
//                                   : row[header] === false
//                                   ? "No"
//                                   : row[header]}
//                               </TableCell>
//                             ))}
//                         </TableRow>
//                       );
//                     }
//                   )}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Paper>
//         </Box>
//       </div>
//     </>
//   );
// }

// import * as React from "react";
// import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
// import Paper from "@mui/material/Paper";
// import { visuallyHidden } from "@mui/utils";

// //set descending sort order
// const descendingComparator = (a, b, orderBy) => {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// };

// //set sort desc
// const getComparator = (order, orderBy) => {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// };

// const tableSort = (array, comparator) => {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// };
// export default function CommonDynamicTableNew(props) {
//   const {
//     dataResult,
//     removeHeaders, //send array of headers which need to remove.  NOTE:send at least one header i.e. id
//     tableClass, //required css for tableContainer.i.e. height ,etc.
//     renderActions, //render Actions @1st column i.e.icons,checkboxes,etc.
//     highlightRow, //default row highlighted,if not want to highlight set as false.
//     customRowBgColor, //usefull when required another bg color of selected row than default.
//     rowBackgroundColor, //use this to show conditional row bg color .
//     handleSelectedRow, //get row onclick use this fn..
//     editableColumns, //array of headers to make column editable
//     renderInput, //actual content to render i.e. input,dropdown,checkbox,icon,etc
//   } = props;
//

//   const [rowIndex, setRowIndex] = React.useState();

//   //state varibale for the table
//   const [order, setOrder] = React.useState("asc");
//   const [orderBy, setOrderBy] = React.useState();
//   const createSortHandler = (property) => (event) => {
//     handleSortRequest(event, property);
//   };

//   //by default asc order
//   const handleSortRequest = (event, property) => {
//     const isAsc = orderBy === property && order === "asc";
//     setOrder(isAsc ? "desc" : "asc");
//     setOrderBy(property);
//   };
//   const removeHeader = (headers, fieldToRemove) => {
//     return headers.filter((v) => {
//       return !fieldToRemove.includes(v);
//     });
//   };

//   //set rows object to table
//   const allHeaders = Object.keys(dataResult[0]);

//   const headers = removeHeaders
//     ? removeHeader(allHeaders, removeHeaders && removeHeaders)
//     : allHeaders;
//   return (
//     <>
//       <div className="grid w-auto">
//         <Box sx={{ width: "100%", overflow: "hidden" }}>
//           <Paper sx={{ width: "100%", my: 1 }}>
//             <TableContainer
//               sx={{
//                 "&::-webkit-scrollbar": {
//                   width: 7,
//                   height: 10,
//                 },
//                 "&::-webkit-scrollbar-track": {
//                   backgroundColor: "#ebebeb",
//                 },
//                 "&::-webkit-scrollbar-thumb": {
//                   backgroundColor: "#7393b3",
//                   borderRadius: 4,
//                 },
//               }}
//               //   className="rounded lg:h-52 md:h-72"
//               className={tableClass}
//             >
//               <Table
//                 size="small"
//                 stickyHeader
//                 aria-label="sticky table"
//                 sx={{
//                   border: 1,
//                   borderColor: "#e0e0e0",
//                   paddingY: "scroll",
//                 }}
//               >
//                 <TableHead>
//                   <TableRow
//                     sx={{
//                       "& th": {
//                         paddingY: 0.5,
//                         backgroundColor: "#F1F1F1",
//                       },
//                     }}
//                   >
//                     {renderActions && (
//                       <TableCell>
//                         <span className="text-gray-600 font-bold whitespace-nowrap">
//                           Actions
//                         </span>
//                       </TableCell>
//                     )}
//                     {headers.map((header, index) => (
//                       <TableCell
//                         sortDirection={orderBy === header ? order : false}
//                         className="whitespace-nowrap"
//                       >
//                         <TableSortLabel
//                           active={false}
//                           direction={orderBy === header ? order : "asc"}
//                           onClick={createSortHandler(header)}
//                           key={index}
//                         >
//                           <span className="text-gray-600 font-bold">
//                             {header}
//                           </span>
//                           {orderBy === header ? (
//                             <Box component="span" sx={visuallyHidden}>
//                               {order === "desc"
//                                 ? "sorted descending"
//                                 : "sorted ascending"}
//                             </Box>
//                           ) : null}
//                         </TableSortLabel>
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {tableSort(dataResult, getComparator(order, orderBy)).map(
//                     (row, index) => {
//                       return (
//                         <TableRow
//                           key={index}
//                           hover={false}
//                           style={{
//                             backgroundColor:
//                               (highlightRow === undefined ||
//                                 highlightRow === true) &&
//                               rowIndex === index
//                                 ? customRowBgColor || "#FFC44B"
//                                 : rowBackgroundColor
//                                 ? rowBackgroundColor(row, index)
//                                 : "",
//                           }}
//                           sx={{
//                             "& td": renderActions
//                               ? {
//                                   paddingY: 0.2,
//                                 }
//                               : { paddingY: 0.5 },
//                           }}
//                           onClick={() => {
//                             setRowIndex(index);
//                             {
//                               handleSelectedRow &&
//                                 handleSelectedRow(row, index);
//                             }
//                           }}
//                         >
//                           {renderActions && (
//                             <TableCell>{renderActions(row, index)}</TableCell>
//                           )}
//                           {headers &&
//                             headers.map((header, i) => (
//                               <TableCell
//                                 className="whitespace-nowrap"
//                                 key={i}
//                                 onClick={() => {
//                                   //  displayView(row, index);
//                                 }}
//                               >
//                                 {row[header] === true
//                                   ? "Yes"
//                                   : row[header] === false
//                                   ? "No"
//                                   : editableColumns &&
//                                     editableColumns.includes(header)
//                                   ? renderInput(row, index, header)
//                                   : row[header]}
//                               </TableCell>
//                             ))}
//                         </TableRow>
//                       );
//                     }
//                   )}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Paper>
//         </Box>
//       </div>
//     </>
//   );
// }
