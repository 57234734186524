import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";

import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchReceivePatientOnBed } from "../../../services/admissionServices/AdmissionServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "40%", lg: "25%" },
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function ReceivePatientModal(props) {
  const {
    open,
    setOpen,
    handleClose,
    selectedRow,
    menuId,
    privilege,
    getBedListData,
    functionalityId,
  } = props;
  console.log("props", props);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const onSubmit = () => {
    let selectedObj = {
      admissionId: selectedRow.AdmissionId,
      bedId: selectedRow.BedId,
      genderId: selectedRow.GenderId,
      menuId: menuId,
      privilege: privilege,
      functionalityId: functionalityId,
    };

    setOpenBackdrop(true);
    fetchReceivePatientOnBed(selectedObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          handleClose();
          setOpen();
          getBedListData && getBedListData();
          props.setReplaceSelectedRow &&
            props.setReplaceSelectedRow(res.result);
          props.updateRowOfAdmTbl && props.updateRowOfAdmTbl(res.result);
          props.getPatientListCCC && props.getPatientListCCC();
        }
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);
        handleClose();
      });
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Receive Patient
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  ">
              Do You Want To Receive Patient On Bed ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <CommonButton
                label="No"
                onClick={() => {
                  handleClose();
                }}
                className="border border-customRed text-customRed "
              />
              <CommonButton
                label="Yes"
                className="bg-customGreen text-white"
                onClick={() => {
                  selectedRow?.isTransferToWard > 0
                    ? onSubmit()
                    : warningAlert("Please Initiate Transfer To Ward.");
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
}
