import { yupResolver } from "@hookform/resolvers/yup";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SaveButton from "../../../common/components/Buttons/SaveButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
// import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  convertToPackage,
  getPackageList,
} from "../../services/charges/IPDCharges";

const ConvertToPackage = (props) => {
  const [packageNameList, setPackageNameList] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const schema = yup.object().shape({
    packageName: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      // isCompany: false,
      // isPackage: false,
      packageName: null,
    },
  });

  function onSubmit(data) {
    setOpenBackdrop(true);

    let selectBillInfoId = [];
    props.selected.map((item) => {
      // let billInfoList = {
      //   chargeIdPackage: item.ChargeId,
      // };
      selectBillInfoId.push(item.ChargeId);
    });
    let packageId, pkgChargeId;

    data.packageName !== null
      ? (packageId = parseInt(data.packageName.ServiceId))
      : (packageId = null);
    data.packageName !== null
      ? (pkgChargeId = data.packageName.id)
      : (pkgChargeId = null);

    let finalObj = {
      admissionId: props.admissionId,
      charges: selectBillInfoId,
      packageId: packageId,
      pkgChargeId: pkgChargeId,
      menuId: props.menuId,
      privilege: props.privilege,
      tariffId: props.tariffId,
    };

    if (selectBillInfoId.length > 0) {
      convertToPackage(finalObj)
        .then((response) => {
          if (response.status === 200) {
            successAlert(response.data.result);
            props.handlePatientInfo();
            props.setOpenCovertToPackage(false);
            setOpenBackdrop(false);
          }
        })
        .catch((res) => {
          errorAlert(res.data.message);
          setOpenBackdrop(false);
        });
    }
  }

  useEffect(() => {
    getPackageList(props.admissionId)
      .then((response) => {
        setPackageNameList(response.data.result);
      })
      .catch((res) => {});
  }, [props.admissionId]);

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-semibold text-base">Convert to Package</h1>
        <div className="-mt-2">
          <CancelPresentationIcon
            className="text-red-600  rounded cursor-pointer"
            onClick={() => {
              props.setOpenCovertToPackage(false);
            }}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          {/* <CheckBoxField control={control} name="isCompany" label="Company" />
          <CheckBoxField control={control} name="isPackage" label="Package" /> */}
        </div>
        <div className="w-full mt-1">
          <DropdownField
            control={control}
            error={errors.packageName}
            name="packageName"
            label="Package"
            dataArray={packageNameList}
            placeholder="Package"
          />
        </div>
        <div className="flex justify-end my-2">
          <SaveButton />
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
};

export default ConvertToPackage;
