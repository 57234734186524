import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

// MLC POST From Listing
export const postMlcData = async (mlcObj) => {
  return await apiClient.post(`/medicoLegalCase/saveMedicoLegalCase`, mlcObj, {
    headers: authHeader(),
  });
};

// fetchMlcType -- mlc modal
export const fetchMlcType = () => {
  return apiClient.get(`/patientEmergencyInfo/mlcTypes`, {
    headers: authHeader(),
  });
};

// fetchMlcPatientInfo -- mlc modal
export const fetchMlcPatientInfo = (patientId, isEmergency) => {
  return apiClient.get(
    `/medicoLegalCase/getPatientInfo/${patientId}/${isEmergency}`,
    {
      headers: authHeader(),
    }
  );
};

// fetchMlcDetails -- mlc modal
export const fetchMlcDetails = (admissionId, emergencyId, opdIpd) => {
  return apiClient.get(
    `/medicoLegalCase/mlcDetails/${admissionId}/${emergencyId}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};
