import React from "react";

function SaveButton({ onClick }) {
  return (
    <div>
      <button
        type="submit"
        className="h-9 px-3  bg-green-600 text-white rounded text-sm font-medium"
        onClick={onClick}
      >
        Save
      </button>
    </div>
  );
}

export default SaveButton;
