import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import GeneralDetails from "./GeneralDetails";
import GynecologyDetails from "./GynecologyDetails";
import GynecologyHistory from "./GynecologyHistory";
import MenstrualHistory from "./MenstrualHistory";

const GynacologyDtlsTab = () => {
  const [expandPanal11, setExpandPanal11] = useState(true);
  const [expandPanal12, setExpandPanal12] = useState(true);
  const [expandPanal13, setExpandPanal13] = useState(true);
  const [expandPanal14, setExpandPanal14] = useState(true);
  ///
  const handleChangePanal11 = () => {
    setExpandPanal11(!expandPanal11);
  };
  const handleChangePanal12 = () => {
    setExpandPanal12(!expandPanal12);
  };
  const handleChangePanal13 = () => {
    setExpandPanal13(!expandPanal13);
  };
  const handleChangePanal14 = () => {
    setExpandPanal14(!expandPanal14);
  };
  //
  return (
    <>
      {/* GynecologyHistory */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal11}
            onChange={handleChangePanal11}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#EAE4C3",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Gynecology History
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* FamilyHistory*/}
              <GynecologyHistory />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      {/* MenstrualHistory */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal12}
            onChange={handleChangePanal12}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#FFD0D0",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Menstrual History
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* MenstrualHistory*/}
              <MenstrualHistory />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* GynecologyDetails */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal13}
            onChange={handleChangePanal13}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#FCE7F3",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Gynecology Details
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* GynecologyDetails*/}
              <GynecologyDetails />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* GeneralDetails */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal14}
            onChange={handleChangePanal14}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#D6FEF4",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Details
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* GeneralDetails*/}
              <GeneralDetails />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      
    </>
  );
};

export default GynacologyDtlsTab;
