import * as React from "react";
// import View from "../../nursingsupervisorreport/nursingSupervisor/View";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Tab, Tabs, TextField } from "@mui/material";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import { fetchUnit } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchAllNursingPatientCount,
  fetchAllPatientInfoListFromWard,
  saveNursingSupervisorDetails,
} from "../../../services/nursingSupervisorDetailsServices/NuersingSupervisorDetailsServices";
import NursingSupervisorTableList from "./NursingSupervisorTableList";

import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import NursingSupervisorTable from "./NursingSupervisorTable";
import ViewModal from "./ViewModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const actions = [""];
export default function PatientCount() {
  const schema = yup.object().shape({
    dama: yup.string(),
    bloodtransfusion: yup.string(),
    patientonventilator: yup.string(),
    mlccases: yup.string(),
    callduty: yup.string(),
    death: yup.string(),
    code: yup.string(),
    bedstore: yup.string(),
    remarkothers: yup.string(),
  });
  const defaultValues = {
    dama: "",
    bloodtransfusion: "",
    patientonventilator: "",
    mlccases: "",
    callduty: "",
    death: "",
    code: "",
    bedstore: "",
    remarkothers: "",
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    register,
    reset,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variables
  const [unitDropdown, setUnitDropdown] = React.useState([]);
  const [patientCount, setPatientCount] = React.useState([]);
  const [patientCountFromWard, setPatientCountFromWard] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [postObject, setPostObject] = React.useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [spinner, setShowSpiner] = React.useState(false);
  const [recordNotFound, setNoRecordFound] = React.useState(false);
  const [countByWardSpinner, setCountByWardSpinner] = React.useState(false);
  const [
    countByWardrecordNotFound,
    setCountByWardNoRecordFound,
  ] = React.useState(false);
  // other variables
  let location = useLocation();

  let unitVal = watch("unit");

  // useEffects
  React.useEffect(() => {}, [errors]);
  React.useEffect(() => {
    // unit dropdown services
    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        setUnitDropdown(res.result);

        setValue("unit", res.result[0]);
      });
  }, [setValue]);

  React.useEffect(() => {
    patientCountList();
    patientCountListByWard();
  }, [unitVal]);

  React.useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  // functions
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };
  //patient,company,packages tab
  const TabhandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //fetch patient count list
  function patientCountList() {
    setShowSpiner(true);
    setNoRecordFound(false);
    let currDate = new Date();
    let dateVal = format(currDate, "yyyy-MM-dd");

    if (unitVal !== undefined) {
      fetchAllNursingPatientCount(unitVal?.id, dateVal)
        .then((response) => response.data)
        .then((res) => {
          setShowSpiner(false);
          setPatientCount(res.result);
        })
        .catch((error) => {
          // errorAlert(error.message);

          setShowSpiner(false);
          setNoRecordFound(true);
        });
    }
  }
  // fetch patient count list against ward
  function patientCountListByWard() {
    setCountByWardSpinner(true);
    setCountByWardNoRecordFound(false);

    if (unitVal?.id) {
      fetchAllPatientInfoListFromWard(unitVal?.id)
        .then((response) => response.data)
        .then((res) => {
          setCountByWardSpinner(false);
          setPatientCountFromWard(res.result);
        })
        .catch((error) => {
          // errorAlert(error.message);

          setCountByWardSpinner(false);
          setCountByWardNoRecordFound(true);
        });
    }
  }

  const handleOpenConfirmation = () => {
    setOpen(true);
  };
  const handleCloseConfirmation = () => {
    setOpen(false);
  };
  // Nursing Supervisor Deatils screen useEffect,function and states

  // function
  function onSubmitDataHandler(data) {
    let userStringObj = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userStringObj);

    // wardcount obj update

    for (let i of patientCount) {
      i.wardId = i.id;
    }

    // floor count obj update
    let floorArr = [];
    for (let i of patientCountFromWard) {
      //   i.floorId = i.clsid;
      let obj = {};

      obj.floor1 = i[4];
      obj.floor10 = i[13];
      obj.floor11 = 0;
      obj.floor1_nibm = 0;
      obj.floor2 = i[5];
      obj.floor2_nibm = 0;
      obj.floor3 = i[6];
      obj.floor4 = i[7];
      obj.floor5 = i[8];
      obj.floor6 = i[9];
      obj.floor7 = i[10];
      obj.floor8 = i[11];
      obj.floor9 = i[12];
      obj.wardId = i.clsid;
      floorArr.push(obj);
    }

    let postObj = {
      addedBy: userInfoObj.userId,
      bedScore: data.bedstore,
      bloodTrans: data.bloodtransfusion,
      callDuty: data.callduty,
      cashCounterId: userInfoObj.cashCounterId,
      code: data.code,
      dama: data.dama,
      death: data.death,
      hkRemark: data.remarkothers,
      mlc: data.mlccases,
      menuId: location?.state?.menuId,
      privilege: "Create",
      patientCountListFloorRequestDto: floorArr,
      patientCountListWardRequestDto: patientCount,
      remark: "",
      unitId: userInfoObj.unitId,
      ventiPat: data.patientonventilator,
    };

    setPostObject(postObj);
    handleOpenConfirmation();
    // setOpenBackdrop(true);
  }
  function addRecord() {
    setOpenBackdrop(true);

    saveNursingSupervisorDetails(postObject)
      .then((response) => response.data)
      .then((res) => {
        handleCloseConfirmation();
        successAlert(res.message);
        setOpenBackdrop(false);
        reset(defaultValues);
        handleCloseConfirmation();
        handleClose();
      })
      .catch((error) => {
        // errorAlert(error.message);
      });
  }
  React.useEffect(() => {}, [spinner, recordNotFound]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="mt-12  ">
          <div className="">
            <div className="text-center text-black font-bold text-xl ">
              Nursing Supervisor
            </div>
          </div>

          <Box
            sx={{ width: "100%", bgcolor: "background.paper" }}
            className="lg:grid lg:grid-cols-3 md:flex md:gap-6 items-center"
          >
            <div className="lg:w-60">
              <DropdownField
                control={control}
                name="unit"
                label="Unit"
                placeholder="Unit"
                dataArray={unitDropdown}
              />
            </div>
            <div className="flex gap-1 w-full md:flex md:gap-1 md:w-full">
              <Tabs
                onChange={TabhandleChange}
                value={tabValue}
                TabIndicatorProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                textColor="#0B83A5"
                variant="centered"
                centered
              >
                <Tab
                  label="Patient Count"
                  {...a11yProps(0)}
                  sx={{
                    fontWeight: "bold",
                  }}
                />

                <Tab
                  label="Supervisor Details"
                  {...a11yProps(1)}
                  sx={{
                    fontWeight: "bold",
                  }}
                />
              </Tabs>
            </div>
            <div className="flex gap-2 justify-end">
              {userActions &&
                userActions.map((actions, i) => (
                  <>
                    {actions.isAction === false ? (
                      <>
                        <div className="flex gap-2 cursor-pointer">
                          {actions.action === "View" ? (
                            <CommonButton
                              label="View"
                              className="text-white bg-customBlue"
                              onClick={() => {
                                handleOpenViewModal();
                              }}
                            />
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
            </div>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <div className="grid grid-cols-3 gap-2 w-full">
              <div className="col-span-3 w-full ">
                <div className="text-black font-bold">Patient Count List</div>
                {spinner ? (
                  <div className="grid justify-center items-center pt-12">
                    <LoadingSpinner />
                  </div>
                ) : null}
                {patientCount.length > 0 && spinner === false ? (
                  <NursingSupervisorTable
                    data={patientCount}
                    actions={actions}
                    tableClass="capitalize"
                  />
                ) : null}
                {recordNotFound === true && spinner === false ? (
                  <div className="flex justify-center">
                    <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                      No Records Found...
                    </h3>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <div className="grid grid-cols-3 gap-2 w-full">
                <div className="col-span-3 w-full ">
                  <div className="text-black font-bold">
                    Patient Count By Ward
                  </div>
                  {countByWardSpinner ? (
                    <div className="grid justify-center items-center pt-14">
                      <LoadingSpinner />
                    </div>
                  ) : null}
                  {patientCountFromWard.length > 0 ? (
                    <NursingSupervisorTableList
                      data={patientCountFromWard}
                      actions={actions}
                    />
                  ) : null}
                  {countByWardrecordNotFound === true &&
                  countByWardSpinner === false ? (
                    <div className="pt-8 text-center">No Record Found</div>
                  ) : null}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="row">
              <fieldset className="border border-gray-300 text-left lg:mx-auto lg:px-4 md:ml-0 md:mr-0 py-4 rounded lg:mt-6 lg:m-2 ">
                <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Nursing Supervisor Details
                </legend>

                <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-2 md:px-2">
                  <TextField
                    id="outlined-multiline-static"
                    name="dama"
                    control={control}
                    error={errors.dama}
                    {...register("dama")}
                    label="DAMA"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="bloodtransfusion"
                    control={control}
                    error={errors.bloodtransfusion}
                    {...register("bloodtransfusion")}
                    label="Blood Transfusion"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="patientonventilator"
                    control={control}
                    error={errors.patientonventilator}
                    {...register("patientonventilator")}
                    label="Patient On Ventilator"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="mlccases"
                    control={control}
                    error={errors.mlccases}
                    {...register("mlccases")}
                    label="MLC Cases"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="callduty"
                    control={control}
                    error={errors.callduty}
                    {...register("callduty")}
                    label="Call Duty"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="death"
                    control={control}
                    error={errors.death}
                    {...register("death")}
                    label="Death"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="code"
                    control={control}
                    error={errors.code}
                    {...register("code")}
                    label="Code"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="bedstore"
                    control={control}
                    error={errors.bedstore}
                    {...register("bedstore")}
                    label="Bed Store"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                  <TextField
                    id="outlined-multiline-static"
                    name="remarkothers"
                    control={control}
                    error={errors.remarkothers}
                    {...register("remarkothers")}
                    label="Remarks / Others"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                  />
                </div>
              </fieldset>
            </div>
          </TabPanel>
          <CommonBackDrop openBackdrop={openBackdrop} />
        </div>
        <div className="flex justify-end gap-2 py-2">
          {tabValue === 1 ? (
            <>
              {userActions &&
                userActions.map((actions, i) => (
                  <>
                    {actions.isAction === false ? (
                      <>
                        <div className="flex gap-2 cursor-pointer">
                          {actions.action === "Create" ? (
                            <>
                              <CommonButton
                                label="Reset"
                                className="border border-customRed text-customRed capitalize"
                                onClick={() => {
                                  reset(defaultValues);
                                }}
                              />
                              <CommonButton
                                label="Save"
                                type="submit"
                                className="bg-customGreen text-white"
                                onClick={() => {}}
                              />
                            </>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
            </>
          ) : null}
        </div>
      </form>
      <ViewModal
        open={openViewModal}
        setOpen={setOpenViewModal}
        handleClose={handleCloseViewModal}
      />
      <ConfirmationModal
        confirmationOpen={open}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          handleClose();
          addRecord();
        }}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
