import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//main function
export default function ConsentTable(props) {
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-48 2xl:h-60"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Sr No.
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Instructions
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.instructionArray.length > 0
                    ? props.instructionArray.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "& th": {
                              paddingY: 0,
                              backgroundColor: "#F1F1F1",
                            },
                          }}
                        >
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row.srNo}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row.instruction}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
