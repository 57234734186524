import authHeader from "../../authentication/authservices/auth-header";
import apiClient, { printersApi } from "../../http-common";

export const fetchBarCode = (barcodeText) => {
  return apiClient.get(`/barcode/generateBarCode/${barcodeText}`, {
    headers: authHeader(),
  });
};

export const routeToPrinterDevice = (obj) => {
  return printersApi.post(`/printerDevices/print`, obj, {
    headers: authHeader(),
  });
};
