// import React from 'react'

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { BedTransferBed, EditOnIcon } from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchAllocatedSecondaryBed,
  releaseSecondaryAllocatedBed,
} from "../../services/bedAllowcationServices/BedAllowcationServices";
import SecBedAddModal from "./SecondaryBedAddModal";

export const Schema = yup.object().shape({
  BedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  ChargesPerBedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),
});

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "70%", lg: "50%" },
  // minWidth:"25%",
  // height: "30%",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function SecondaryBedAllocationModal(props) {
  const {
    open,
    handleClose,
    menuId,
    functionalityId,
    privilege,
    getBedListData,
    AdmissionId,
    bedDetails,
  } = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [allocatedBedsList, setAllocatedBedsList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);

  //
  const [openSecondaryBedAddModal, setOpenSecondaryBedAddModal] = useState(
    false
  );
  const handleOpenSecondaryBedAddModal = () =>
    setOpenSecondaryBedAddModal(true);
  const handleCloseSecondaryBedAddModal = () =>
    setOpenSecondaryBedAddModal(false);
  //

  useEffect(() => {
    getAllocatedSecondaryBed();
  }, [AdmissionId]);

  const getAllocatedSecondaryBed = () => {
    fetchAllocatedSecondaryBed(AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setAllocatedBedsList(res.result);
      });
  };
  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    releaseSecondaryAllocatedBed(selectedRow?.BedId, menuId, privilege)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        // handleClose();
        getAllocatedSecondaryBed();
        getBedListData();
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res.message);
        setOpenBackdrop(false);
        handleClose();
      });
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3 items-center mt-2">
        {/*  // {userActions &&
        //   userActions.map((actions, i) => (
        //     <>
        //       {actions.isAction === false ? (
        //         <>*/}

        <div className="cursor-pointer">
          <EditOnIcon
            title={"Change Secondary Bed"}
            onClick={() => {
              setOpenSecondaryBedAddModal(true);
              setSelectedRow(row);
            }}
          />
        </div>

        <div className="cursor-pointer">
          <BedTransferBed
            title={"Release Secondary Bed"}
            onClick={() => {
              setSelectedRow(row);
              onSubmit();
            }}
          />
        </div>
        {/*  //       </>
          //     ) : null}
          //   </>
          // ))}*/}
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                Allocate Secondary Bed
              </div>

              <hr className="border mx-1  border-blue-900" />

              <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                <div className="grid grid-cols-2  gap-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                    {/* Patient Name , UHID */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-1">
                        <span className="text-sm">Patient Name </span>
                        <span className=""> :</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails?.ptInfo &&
                          bedDetails?.ptInfo["Patient Name"]}
                      </h1>
                    </div>
                    {/* Gender */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                        <span className="text-sm">Gender</span>
                        <span className="lg:pl-7 md:pl-6">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails?.ptInfo && bedDetails?.ptInfo["Gender"]}
                      </h1>
                    </div>
                  </div>

                  {/* MRNo */}
                  <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">MRNo</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails?.ptInfo && bedDetails?.ptInfo["MRNo"]}
                      </h1>
                    </div>
                    {/* age */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">Age</span>
                        <span className="md:pl-4">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails?.ptInfo && bedDetails?.ptInfo["Age"]}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center my-2">
                <p className="text-black font-semibold mt-2">
                  Allocated Secondary Bed List
                </p>
                <div>
                  <CommonButton
                    label="Add New"
                    className="bg-customBlue text-white"
                    onClick={() => {
                      setOpenSecondaryBedAddModal(true);
                      setSelectedRow(null);
                    }}
                  />
                </div>
              </div>

              <div className=" gap-3 mt-3 grid grid-cols-1">
                <div className="lg:col-span-9 md:col-span-7">
                  {allocatedBedsList !== null &&
                  allocatedBedsList?.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={allocatedBedsList}
                      // handleSelectedRow={handleSelectedRow} BedReleaseBed
                      renderActions={renderActions}
                      tableClass="h-72"
                      removeHeaders={[
                        "CategoryId",
                        "RoomId",
                        "Sex",
                        "WadId",
                        "BedId",
                        "Code",
                        "bedAllocationId",
                      ]}
                      highlightRow={false}
                    />
                  ) : (
                    <p className="text-center items-center">
                      Data Not Found...
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-end my-2 gap-3">
                <CommonButton
                  label="Close"
                  onClick={() => {
                    handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
              </div>
            </div>
          </form>
          {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
        </Box>
      </Modal>

      {openSecondaryBedAddModal && (
        <SecBedAddModal
          open={openSecondaryBedAddModal}
          setOpen={setOpenSecondaryBedAddModal}
          handleOpen={handleOpenSecondaryBedAddModal}
          handleClose={handleCloseSecondaryBedAddModal}
          menuId={menuId}
          functionalityId={functionalityId}
          privilege={privilege}
          getAllocatedSecondaryBed={getAllocatedSecondaryBed}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
          getBedListData={getBedListData}
          isEditBed={selectedRow !== null ? true : false}
          selectedRow={selectedRow}
        />
      )}

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Do You Want To Release Secondary Allocated Bed ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
