import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import InputArea from "../../../../common/components/FormFields/InputArea";

const SystematicExamination = () => {
  const { control } = useFormContext();
  return (
    <div className=" grid grid-cols-2 gap-3">
      <div className=" ">
        <InputArea
          name="crt"
          control={control}
          label="CRT"
          placeholder="CRT"
          multiline
        />
      </div>
      <div className=" ">
        <InputArea
          name="localExamination"
          control={control}
          label="Local Examination"
          placeholder="Local Examination"
          multiline
        />
      </div>
      <div className=" ">
        <InputArea
          name="cvs"
          control={control}
          label="CVS"
          placeholder="CVS"
          multiline
        />
      </div>
      <div className=" ">
        <InputArea
          name="rs"
          control={control}
          label="RS"
          placeholder="RS"
          multiline
        />
      </div>
      <div className=" ">
        <InputArea
          name="perAbdomen"
          control={control}
          label="Per Abdomen"
          placeholder="Per Abdomen"
          multiline
        />
      </div>
      <div className="">
        <InputArea
          name="perVaginalExam"
          control={control}
          label="Per Vaginal Examination"
          placeholder="Per Vaginal Examination"
        />
      </div>
      <div className=" ">
        <InputArea
          name="cns"
          control={control}
          label="CNS"
          placeholder="CNS"
          multiline
        />
      </div>
    </div>
  );
};

export default SystematicExamination;
