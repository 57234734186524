export const Rescheduled = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="34" rx="4" fill="#9C40C8" />
        <path
          d="M16.1827 7.01536C14.502 7.18976 12.9376 7.72193 11.6458 8.55818L11.3106 8.77284L11.2838 8.4598C11.2435 7.96789 11.0111 7.7264 10.5731 7.7264C10.2915 7.7264 10.1484 7.79795 9.9741 8.03496C9.8847 8.15124 9.88023 8.21384 9.88023 9.49282C9.88023 10.7718 9.8847 10.8344 9.9741 10.9507C10.0233 11.0178 10.1216 11.1161 10.1886 11.1653C10.3049 11.2548 10.3674 11.2592 11.6458 11.2592C12.9242 11.2592 12.9868 11.2548 13.103 11.1653C13.3399 10.9909 13.4114 10.8478 13.4114 10.5661C13.4114 10.3693 13.389 10.2709 13.3175 10.1815C13.1432 9.94449 13.0091 9.87741 12.6381 9.85058L12.2939 9.82822L12.6515 9.61804C15.995 7.65485 20.2011 8.14676 22.9679 10.8299C23.7546 11.5902 24.2552 12.2789 24.7245 13.2269C26.0118 15.852 25.8867 18.9555 24.3938 21.433C23.8619 22.3139 23.0215 23.2575 22.2438 23.8433C19.6692 25.7931 16.2274 26.1598 13.3533 24.7869C12.4057 24.3353 11.7665 23.8836 10.9932 23.1099C10.1216 22.2469 9.57181 21.4508 9.1293 20.4044C8.7136 19.4206 8.50352 18.4949 8.43647 17.3277C8.39624 16.6927 8.36496 16.6032 8.09677 16.4065C7.92244 16.2768 7.50228 16.2768 7.32796 16.4065C6.99719 16.6524 6.95249 16.8805 7.03742 17.8643C7.24303 20.1898 8.1951 22.2782 9.80871 23.9507C11.3732 25.5695 13.246 26.5399 15.5569 26.9156C16.2319 27.0274 17.765 27.0274 18.4712 26.9201C20.581 26.5891 22.436 25.6724 23.9378 24.2235C25.538 22.6762 26.5169 20.8069 26.8969 18.5709C27.0131 17.8956 27.0354 16.2813 26.9416 15.6865C26.5438 13.2493 25.6006 11.3934 23.9557 9.80586C22.4673 8.37036 20.6704 7.46255 18.6187 7.12268C18.0779 7.02877 16.6386 6.97064 16.1827 7.01536Z"
          fill="white"
        />
        <path
          d="M20.2781 19.9935C20.014 19.772 18.4649 18.7911 18.2074 18.8361C18.0865 18.8575 17.9941 18.9606 17.7466 19.2558C17.6321 19.4012 17.5064 19.5373 17.3706 19.6629C17.1219 19.6028 16.8811 19.5135 16.6532 19.397C15.7597 18.9619 15.0379 18.2399 14.6031 17.3463C14.4865 17.1184 14.3973 16.8776 14.3372 16.6288C14.4628 16.4931 14.5989 16.3674 14.7443 16.2529C15.0392 16.0054 15.1425 15.9135 15.164 15.7921C15.209 15.534 14.2272 13.9855 14.0066 13.7214C13.9141 13.612 13.8301 13.5386 13.7225 13.5386C13.4107 13.5386 12 15.2829 12 15.5089C12 15.5273 12.0302 17.3432 14.3236 19.6765C16.6569 21.9699 18.4728 22.0001 18.4912 22.0001C18.7172 22.0001 20.4615 20.5894 20.4615 20.2776C20.4615 20.17 20.3881 20.086 20.2781 19.9935Z"
          fill="white"
        />
        <path
          d="M19.6902 17.3847H20.4594C20.4583 16.365 20.0266 15.4135 19.3056 14.6924C18.5845 13.9714 17.633 13.5397 16.6133 13.5386V14.3078C17.3781 14.3086 18.3802 14.9208 18.921 15.4616C19.3056 15.8463 19.6894 16.6199 19.6902 17.3847Z"
          fill="white"
        />
        <path
          d="M18.344 17.3848H18.921C18.9203 16.773 18.6769 16.1864 18.2443 15.7538C17.8117 15.3212 17.2251 15.0778 16.6133 15.0771V15.6541C17.0722 15.6545 17.5121 15.837 17.8366 16.1615C18.1611 16.486 18.3436 16.9259 18.344 17.3848Z"
          fill="white"
        />
        <path
          d="M21.1695 17.3846H21.9979C21.9962 15.957 21.4284 14.5884 20.419 13.5789C19.4095 12.5695 18.0409 12.0016 16.6133 12V12.8284C17.8212 12.8298 18.9793 13.3103 19.8334 14.1645C20.6876 15.0186 21.1681 16.1767 21.1695 17.3846Z"
          fill="white"
        />
      </svg>
    );
  };