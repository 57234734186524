import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import { useContext } from "react";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PatientDetails from "./PatientDetails";
import { useEffect } from "react";
import RadioField from "../../../../common/components/FormFields/RadioField";

const MewsRadio = [
  {
    id: "0",
    value: "0",
    label: "A",
  },
  {
    id: "1",
    value: "1",
    label: "V",
  },
  {
    id: "2",
    value: "2",
    label: "P",
  },
  {
    id: "3",
    value: "3",
    label: "U",
  },
];
const PatientdetailsTab = () => {
  const { mewsScoreVitals, setMewsScoreVitals, patientData } = useContext(
    DischargeSummaryContext
  );
  const { control, watch, setValue } = useFormContext();

  //
  const [expandPanal1, setExpandPanal1] = useState(true);

  //
  const handleChangePanal1 = () => {
    setExpandPanal1(!expandPanal1);
  };

  //
  let Avpu = watch("mewsRadioVitals");

  const [temp, pulse, rr] = watch(["tempVitals", "pulseVitals", "respirationVitals"]);

  // to remove slash while considering b/p
  let bpStr = watch("bpVitals");
  let arr = !!bpStr && bpStr?.split("/");
  let bp = arr[0];
  // calculation for mews score calculation
  function SystolicBloodPressure(sbp) {
    if (sbp >= 200) {
      return 2;
    } else if (sbp >= 101 && sbp <= 199) {
      return 0;
    } else if (sbp >= 81 && sbp <= 100) {
      return 2;
    } else if (sbp >= 71 && sbp <= 80) {
      return 2;
    } else if (sbp <= 70) {
      return 3;
    }
  }
  function PulseCalculation(pulse) {
    if (pulse <= 40) {
      return 2;
    } else if (pulse >= 41 && pulse <= 50) {
      return 1;
    } else if (pulse >= 51 && pulse <= 100) {
      return 0;
    } else if (pulse >= 101 && pulse <= 110) {
      return 1;
    } else if (pulse >= 111 && pulse <= 129) {
      return 2;
    } else if (pulse >= 130) {
      return 3;
    }
  }
  function RespiratoryRate(bpm) {
    if (bpm <= 9) {
      return 2;
    } else if (bpm > 9 && bpm <= 14) {
      return 0;
    } else if (bpm >= 15 && bpm <= 20) {
      return 1;
    } else if (bpm >= 21 && bpm <= 29) {
      return 2;
    } else if (bpm >= 30) return 3;
  }
  function Temperature(fahrenheit) {
    if (fahrenheit < 95) {
      return 3;
    } else if (fahrenheit >= 95 && fahrenheit <= 97) {
      return 1;
    } else if (fahrenheit >= 97.1 && fahrenheit <= 100) {
      return 0;
    } else if (fahrenheit >= 100.1 && fahrenheit <= 101.3) {
      return 1;
    } else if (fahrenheit > 101.3) {
      return 2;
    }
  }

  const calculateMews = (bp, temp, pulse, rr) => {
    var mewsVal;

    if (
      Number(bp) > 0 &&
      Number(temp) > 0 &&
      Number(pulse) > 0 &&
      Number(rr) > 0
    ) {
      let bpValue = SystolicBloodPressure(bp);

      let pulseValue = PulseCalculation(pulse);

      let tempValue = Temperature(temp);

      let rrValue = RespiratoryRate(rr);

      mewsVal =
        Number(bpValue) +
        Number(pulseValue) +
        Number(tempValue) +
        Number(rrValue);
    }

    return mewsVal;
  };

  let finalMews;
  useEffect(() => {
    finalMews = calculateMews(bp, temp, pulse, rr);

    let mewsWithAvpu = Number(finalMews) + Number(Avpu);

    // if (mewsWithAvpu >= 5) {
    //   setValue("mewsAlert", "Please Call Doctor For Consultation");
    // } else if (mewsWithAvpu < 5) {
    //   setValue("mewsAlert", "No Need to Call Doctor For Consultation");
    // }

    setMewsScoreVitals(Number(mewsWithAvpu));
    //
    mewsWithAvpu &&
      mewsWithAvpu !== NaN &&
      setValue("mewsScoreVitals", Number(mewsWithAvpu));
  }, [bp, temp, pulse, rr, Avpu]);

  return (
    <div>
      {/* Vitals */}
      <div>
        <div className="flex py-2 gap-3">
          <h1 className="text-black font-semibold text-base">Vitals</h1>
        </div>

        {/* <div className="grid lg:grid-cols-5 md:grid-cols-4 gap-1">
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Temp</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputTempOld"
                  variant="outlined"
                  label="°F"
                  placeholder="°F"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Pulse rate</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputPulserateOld"
                  variant="outlined"
                  label="Pulse Rate"
                  placeholder="Pulse Rate"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">B/P</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputBPOld"
                  variant="outlined"
                  label="mm"
                  placeholder="mm"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">SpO2</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputSpO2Old"
                  variant="outlined"
                  label="%"
                  placeholder="%"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Weight</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputWeightOld"
                  variant="outlined"
                  label="kg"
                  placeholder="kg"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Height</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputHeightOld"
                  variant="outlined"
                  label="cm"
                  placeholder="cm"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Respiration</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputRespirationOld"
                  variant="outlined"
                  label=" kg/m2"
                  placeholder=" kg/m2"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">MEWS Score</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputMEWSScoreOld"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Pain Score</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputPainScoreOld"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">HC</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputHCOld"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
            <span className=" font-semibold w-28">Grade Of Malnutrition</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="inputMalnutritionOld"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>
        </div>*/}
        <div className="grid lg:grid-cols-5 md:grid-cols-4 gap-1">
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Temp</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="tempVitals"
                  variant="outlined"
                  label="°F"
                  placeholder="°F"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Pulse Rate</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="pulseVitals"
                  variant="outlined"
                  label="Pulse Rate/min"
                  placeholder="Pulse Rate/min"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">B/P</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  // type="number"
                  name="bpVitals"
                  variant="outlined"
                  label="mmHg"
                  placeholder="mmHg"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">SpO2</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="spo2Vitals"
                  variant="outlined"
                  label="%"
                  placeholder="%"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Weight</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="weightVitals"
                  variant="outlined"
                  label="Kg"
                  placeholder="Kg"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Height</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="heightVitals"
                  variant="outlined"
                  label="cm"
                  placeholder="cm"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Respiration</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="respirationVitals"
                  variant="outlined"
                  label=" Respiration/min"
                  placeholder=" Respiration/min"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Pain Score</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="painScoreVitals"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">HC</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="hcVitals"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Grade Of Malnutrition</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="malnutritionVitals"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          {patientData?.gender === "Female" || patientData?.age <= 14 ? (
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
              <span className="  w-28">PEWS</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  <InputField
                    type="number"
                    name="pewsScoreVitals"
                    variant="outlined"
                    // error={errors.pewsScore}
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </span>
              </div>
            </div>
          ) : null}

          <div className="flex items-center gap-2 w-full col-span-3 xl:col-span-2  text-sm py-1">
            <span className="  w-28">MEWS Score</span>

            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  type="number"
                  name="mewsScoreVitals"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
            <span className="  w-28">Mews *</span>
            <div className="flex items-center gap-3">
              <span>:</span>
              <span className="w-56">
                <RadioField
                  label=""
                  name="mewsRadioVitals"
                  control={control}
                  dataArray={MewsRadio}
                />
              </span>
             {/*  <span className="text-gray-700 font-normal  w-24">
                <InputField
                  type="number"
                  name="mewsScoreVitals"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>*/}
            </div>
          </div>

          <div className="col-span-4 xl:col-span-2  flex gap-2 items-center">
            {mewsScoreVitals !== undefined && mewsScoreVitals > 0 ? (
              mewsScoreVitals >= 5 ? (
                <>
                  <p> Alert :</p>
                  <p className="text-red-600">
                    Please Call Doctor For Consultation
                  </p>
                </>
              ) : (
                <>
                  <p> Alert :</p>
                  <p className="text-green-600">
                    No Need to Call Doctor For Consultation
                  </p>
                </>
              )
            ) : null}
          </div>
        </div>
      </div>

      {/* Patient Details */}
      <div className="pt-3">
        <Accordion
          expanded={expandPanal1}
          onChange={handleChangePanal1}
          elevation={6}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
            sx={{
              "&.Mui-expanded": {
                marginBottom: "0.3rem",
                minHeight: "40px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0,
              },
              backgroundColor: "#FFD4E3",
            }}
          >
            <p className="font-bold tracking-wide text-sm font-Poppins">
              Patient Details
            </p>
          </AccordionSummary>
          <AccordionDetails>
            {/* PatientDetails*/}
            <PatientDetails />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default PatientdetailsTab;
