import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const getItemListBelowStockLevel = (storeId, page, size) => {
  return apiClient.get(
    `/storeStockMinMax/getItemListBelowStockLevel/${storeId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};



export const getExportToExcel = (storeId, page, size) => {
  return apiClient.get(
    `/storeStockMinMax/exportToExcel/${storeId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};
