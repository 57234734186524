import workOrder from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const getSupplierList = (searchString) => {
  return workOrder.get(`/purchaseOrder/supplierList/${searchString}`, {
    headers: authHeader(),
  });
};

export const getFreightList = () => {
  return workOrder.get(`/purchaseOrder/freightList`, {
    headers: authHeader(),
  });
};

export const getDeliveryList = () => {
  return workOrder.get(`/purchaseOrder/DeliveryList`, {
    headers: authHeader(),
  });
};

export const getModeOfPayment = () => {
  return workOrder.get(`/purchaseOrder/modeOfPayment`, {
    headers: authHeader(),
  });
};

export const getTermOfPayment = () => {
  return workOrder.get(`/purchaseOrder/termOfPayment`, {
    headers: authHeader(),
  });
};

export const getUomList = () => {
  return workOrder.get(`/invWorkOrder/uomList`, {
    headers: authHeader(),
  });
};

export const getListOfName = () => {
  return workOrder.get(`/invWorkOrder/nameList`, {
    headers: authHeader(),
  });
};

export const getTaxNatureList = () => {
  return workOrder.get(`/purchaseOrder/taxNatureList`, {
    headers: authHeader(),
  });
};

export const saveWorkOrder = (saveObj) => {
  return workOrder.post(`/invWorkOrder/saveWorkOrder`, saveObj, {
    headers: authHeader(),
  });
};

export const getWorkOrderList = (postObj) => {
  return workOrder.post(`/invWorkOrder/workOrderList`, postObj, {
    headers: authHeader(),
  });
};

export const getWorkOrderDetailsList = (postObj) => {
  return workOrder.post(`/invWorkOrder/workOrderDetailsList`, postObj, {
    headers: authHeader(),
  });
};

export const saveWorkOrderMultiple = (menuId,privilege,saveObj) => {
  return workOrder.post(`/invWorkOrder/saveWorkOrderMultiple/${menuId}/${privilege}`, saveObj, {
    headers: authHeader(),
  });
};

export const getWorkOrderNoList = (postObj) => {
  return workOrder.post(`/invWorkOrder/workOrderNoSearch`, postObj, {
    headers: authHeader(),
  });
};

export const getworkOrderGRNNoSearch = (workOrderNoObj) => {
  return workOrder.post(
    `/invWorkOrderGrn/workOrderGRNNoSearch`,
    workOrderNoObj,
    {
      headers: authHeader(),
    }
  );
};

export const getTaxType = () => {
  return workOrder.get(`/misc/taxType`, {
    headers: authHeader(),
  });
};

export const materialWorkOrderPrint = (workOrderGrnId) => {
  return workOrder.get(
    `/reports/inventory/MaterialWorkOrderGRN/${workOrderGrnId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    },
  );
};
