import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

export const fetchPostData = (loginIdValue) => {
  return apiClient.get(`/login/loginunits/${loginIdValue}`);
};

export const fetchCashCounterData = (loginIdValue, unitIdValue) => {
  return apiClient.get(
    `/login/logincashcounters/${loginIdValue}/${unitIdValue}`
  );
};

export const fetchCurrentDateTime = () => {
  return apiClient.get(`/login/getCurrentDateTime`);
};

export const authenticateUserLogin = (loginObj) => {
  //
  return apiClient.post(`/login`, loginObj);
};

export const restrictSamePatientToMultiUserActivity = (obj) => {
  return apiClient.post(`/login/restrictSamePatientToMultiUserActivity`, obj, {
    headers: authHeader(),
  });
};

// function updateDepartmentOptions(requiredunitsId) {
//
//   getDepartmentListByUnitId(requiredunitsId)
//     .then((response) => {
//

//       setValue("department", null);

//       setDepartment(response.data.result);
//     })
//     .catch((error) => {
//
//     });
// }
