import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const addNewQuotation = async (createQuotation) => {
  return await apiClient.post(`/purchase/savePurchaseQuotation`, createQuotation, {
    headers: authHeader(),
  });
};

//Enquiry List
export const getEnquiryList = async (fromDate, toDate) => {
  return await apiClient.get(
    `/purchase/quotationDetailsEnquiryList?fromDate=${fromDate}&toDate=${toDate}`,
    {
      headers: authHeader(),
    }
  );
};

// POST List
export const fetchAllQuotation = async (equNumber, fromDate, toDate) => {
  return await apiClient.get(
    `/purchase/quotationDetailsList?equNumber=${equNumber}&fromDate=${fromDate}&toDate=${toDate} `,
    {
      headers: authHeader(),
    }
  );
};

// /api/purchase/quotationDetailsComparativeList/{quoId}
export const fetchAllComparativData = async (quoId) => {
  return await apiClient.get(
    `/purchase/quotationDetailsComparativeList/${quoId}`,
    {
      headers: authHeader(),
    }
  );
};

// Quotation MOdal in Get QId And Call This API on Click of Checkbox n OK Btn and Gettting items Or Showing into Parent
export const getQuotationEnqItemDetailsList = async (qId) => {
  return await apiClient.get(`/purchase/quotationEnqItemDetailsList/${qId}`, {
    headers: authHeader(),
  });
};
