import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import Average from "../../../../assets/scaleEmoji/Average.png";
import DissatisfiedIcon from "../../../../assets/scaleEmoji/Dissatisfied.png";
import HighlySatisfied from "../../../../assets/scaleEmoji/HighlySatisfied.png";
import Poor from "../../../../assets/scaleEmoji/Poor.png";
import Satisfied from "../../../../assets/scaleEmoji/Satisfied.png";
import StarPng from "../../../../assets/scaleEmoji/Star.png";
import { getAnswerPatternScaleForTemplate } from "../../../services/feedback/feedbackcreate/FeedBackCreationServices";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  overflowY: "scroll",
  boxShadow: 24,
  p: 2,
};

const bgColorsForButton = [
  "#C4FFC3",
  "#F0FFC3",
  "#FEFFE0",
  "#FFE1CF",
  "#FFCBCB",
];
const textColor = ["#FF2A2A", "#E99566", "#DDE032", "#AADF12", "#239F21"];
function TemplateModal(props) {
  const [selectedCard, setSelectedCard] = useState(null);
  const [patternList, setPatternList] = useState([]);

  useEffect(() => {
    if (props.isMultiLangauge !== undefined) {
      getAnswerPatternScaleForTemplate(props.isMultiLangauge).then(
        (response) => {
          let tempArr = [];
          for (let index = 0; index < response.data.result.length; index++) {
            let obj = response.data.result[index];
            let tempObj = {
              id: obj.id,
              "Answer Review Type": `${index + 1}) ${obj["ansReviewType"]}`,
              ansType: obj["ansType"],
              PreView: JSON.parse(obj.preView),
            };
            tempArr.push(tempObj);
            setSelectedCard(tempArr[0].id);
            setPatternList(tempArr);
            props.setSelectedScaleType(tempArr[0]);
          }
        }
      );
    }
  }, [props.isMultiLangauge]);

  const handleChange = (event, value) => {
    setSelectedCard(event.target.value);
    props.setSelectedScaleType(value);
  };

  // setting bg color for chips
  const getBackgroundColor = (label) => {
    for (let obj of patternList) {
      const index = obj.PreView.findIndex((item) => item.label === label);
      if (index !== -1) {
        return bgColorsForButton[index];
      }
      return "#FFFFFF";
    }
  };
  console.log("patternList", patternList);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="rounded w-[90%] lg:w-[860px]  2xl:w-[60%] max-h-[90%]"
        >
          <h1 className="font-semibold text-[20px]">Select Scales</h1>
          <CancelPresentationIconButton
            className="bg-[#FFDCDC] rounded-full"
            onClick={() => {
              props.handleClose();
              setSelectedCard(null);
              props.setSelectedScaleType(null);
            }}
          />

          {patternList.length > 0 &&
            patternList.map((value, index) => {
              console.log("patternList", patternList);
              return (
                <div className="flex space-x-2 items-center my-1" key={index}>
                  <h2 className="whitespace-nowrap">
                    <FormControl>
                      <RadioGroup
                        value={selectedCard}
                        onChange={(e) => {
                          handleChange(e, value);
                        }}
                        name="selectStyle"
                      >
                        <FormControlLabel
                          value={value.id}
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </h2>
                  <div
                    className={`border rounded  p-2 mt-2 w-full shadow-lg${
                      Number(selectedCard) === Number(value.id)
                        ? "border border-green-600"
                        : ""
                    }`}
                  >
                    <h3 className="text-[14px] text-[#073763] my-2">
                      {value["Answer Review Type"]}
                    </h3>
                    <>
                      <div className=" w-full">
                        {value["ansType"] === "Chips" ? (
                          <div className=" grid grid-cols-5 gap-2 p-2 text-[12px] px-5 lg:px-12">
                            {value.PreView.map((scaleValue, index) => {
                              const bgColor = getBackgroundColor(
                                scaleValue.label
                              );
                              return (
                                <div
                                  className="border shadow rounded-full border-[#073763] text-[#073763] text-center h-10 flex items-center justify-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  <h4>{scaleValue.label}</h4>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                        {value["ansType"] === "Star" ? (
                          <div className=" grid grid-cols-5 gap-2 text-[12px] px-5 lg:px-20">
                            {value.PreView.map((scaleValue, index) => {
                              const textColors =
                                textColor[index % textColor.length];
                              const bgColor = getBackgroundColor(
                                scaleValue.label
                              );
                              return (
                                <div className="grid gap-1">
                                  <div
                                    className="border shadow rounded text-center  h-[58px] flex items-center justify-center"
                                    style={{ backgroundColor: bgColor }}
                                  >
                                    <img src={StarPng} className="h-7" />
                                  </div>
                                  <div className="text-center">
                                    <h4 style={{ color: textColors }}>
                                      {scaleValue.label}
                                    </h4>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <>
                        {value["ansType"] === "Emoji" ? (
                          <div className=" grid grid-cols-5 gap-2 text-[12px] px-5 lg:px-20">
                            {value.PreView.map((scaleValue, index) => {
                              console.log("12343223", scaleValue);
                              return (
                                <>
                                  {scaleValue.value === "1" ? (
                                    <div>
                                      <div className="border shadow rounded text-center bg-[#C4FFC3] h-[58px] flex items-center justify-center">
                                        <img
                                          src={DissatisfiedIcon}
                                          className="h-7"
                                        />
                                      </div>
                                      <h4 className="text-center text-[#FF2A2A] mt-1">
                                        {scaleValue.label}
                                      </h4>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {scaleValue.value === "2" ? (
                                    <div>
                                      <div className="border shadow rounded text-center bg-[#F0FFC3] h-[58px] flex items-center justify-center">
                                        <img src={Poor} className="h-7" />
                                      </div>
                                      <h4 className="text-center text-[#E99566]  mt-1">
                                        {scaleValue.label}
                                      </h4>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {scaleValue.value === "3" ? (
                                    <div>
                                      <div className="border shadow rounded text-center bg-[#FEFFE0] h-[58px] flex items-center justify-center">
                                        <img src={Average} className="h-7" />
                                      </div>
                                      <h4 className="text-center text-[#DDE032]  mt-1">
                                        {scaleValue.label}
                                      </h4>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {scaleValue.value === "4" ? (
                                    <div>
                                      <div className="border shadow rounded text-center bg-[#FFE1CF] h-[58px] flex items-center justify-center">
                                        <img src={Satisfied} className="h-7" />
                                      </div>
                                      <h4 className="text-center text-[#AADF12]  mt-1">
                                        {scaleValue.label}{" "}
                                      </h4>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {scaleValue.value === "5" ? (
                                    <div>
                                      <div className="border shadow rounded text-center bg-[#FFCBCB] h-[58px] flex items-center justify-center">
                                        <img
                                          src={HighlySatisfied}
                                          className="h-7"
                                        />
                                      </div>
                                      <h4 className="text-center text-[#239F21]  mt-1">
                                        {scaleValue.label}
                                      </h4>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    </>
                  </div>
                </div>
              );
            })}
          <div className="flex justify-end pt-2">
            <CommonButton
              type="button"
              className="bg-customGreen text-white"
              label="Select"
              onClick={() => {
                props.handleClose();
              }}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default TemplateModal;
