function getNursingSynopsis(data) {
  

  // "patientAdmitted": "Fever",
  // "doctorInform": "Rajesh Kumar",
  // "patientSymptoms": "High Temperature , Running nose",
  // "patientSurgery": "New Orders",
  // "patientHandOver": "Ring",
  // "handOverToName": "Jiya",
  // "relationToPatient": "Wife",
  // "assessedByName": "Dr. Ajay Kumar",
  // "reason": "Fever",
  // "reasonForHospitalization": "Fever"

  //Nursing Synopsis corresponding field names
  let initialNursigAssNursingSynopsisDto = {
    admissionConsultant: "", //doctorInform
    admissionSymptons: "", //patientSymptoms
    assessedByName: "", //assessedByName
    handOverName: "", //handOverToName
    personalHandOver: "", //patientHandOver
    reasonsForHospitalization: "", //reasonForHospitalization
    receiversRelationPt: "", //relationToPatient
    scheduledPreOpOrders: "", //patientSurgery
  };

  let doctorInformVal = data.doctorInform;
  let patientSymptomsVal = data.patientSymptoms;
  let assessedByNameVal = data.assessedByName;
  let handOverToNameVal = data.handOverToName;
  let patientHandOverVal = data.patientHandOver;
  let reasonsForHospitalizationVal = data.reasonForHospitalization;
  let relationToPatientVal = data.relationToPatient;
  let patientSurgeryVal = data.patientSurgery;

  // if (
  //   doctorInformVal &&
  //   patientSymptomsVal &&
  //   assessedByNameVal &&
  //   handOverToNameVal &&
  //   patientHandOverVal &&
  //   reasonsForHospitalizationVal &&
  //   relationToPatientVal &&
  //   patientSurgeryVal
  // ) {
    initialNursigAssNursingSynopsisDto.admissionConsultant = data.doctorInform;
    initialNursigAssNursingSynopsisDto.admissionSymptons = data.patientSymptoms;
    initialNursigAssNursingSynopsisDto.assessedByName = data.assessedByName;
    initialNursigAssNursingSynopsisDto.handOverName = data.handOverToName;
    initialNursigAssNursingSynopsisDto.personalHandOver = data.patientHandOver;
    initialNursigAssNursingSynopsisDto.reasonsForHospitalization =
      data.reasonForHospitalization;
    initialNursigAssNursingSynopsisDto.receiversRelationPt =
      data.relationToPatient;
    initialNursigAssNursingSynopsisDto.scheduledPreOpOrders =
      data.patientSurgery;
  // }

  


  return initialNursigAssNursingSynopsisDto;
}

export default getNursingSynopsis;
