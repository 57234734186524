import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { format, isBefore, parseISO, startOfYear } from "date-fns";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import BlankProfile from "../../../../assets/registrationAssets/blankProfile.jpeg";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { baseUrl } from "../../../../http-common";
import {
  fetchPatchDataNew,
  fetchPharmacyOTMedicine,
  fetchPrintAdmissionForm,
  saveAdmissionFormData,
} from "../../../services/admissionServices/AdmissionServices";
import {
  admissionValidationSchema,
  personalValidationSchema,
} from "./AdmissionDetailsFormSchema";
import AdmissionDetails from "./admissionDetails/AdmissionDetails";
import Documents from "./documents/Documents";
import NriDetails from "./nriDetails/NriDetails";
import PatientDetails from "./personalDetails/PatientDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PatientAdmissionDetails = () => {
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  let navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [bedDetailsFromBedModal, setBedDetailsFromBedModal] = useState(null);
  const [privilege, setPrivilege] = useState(null);

  //
  console.log("privilege", privilege);

  //
  const [duplicateAdhar, setDuplicateAdhar] = useState(false);
  //
  const [allFormDetails, setAllFormDetails] = useState({});
  const [firstValid, setFirstValid] = useState([]);
  const [secondValid, setSecondValid] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [profilePic, setProfilePic] = useState(BlankProfile);
  const [profilePicName, setProfilePicName] = React.useState(null);
  const [patientCategoryChange, setPatientCategoryChange] = useState("Self");
  const [openBlacklistCarryModal, setOpenBlacklistCarryModal] =
    React.useState(false);
  const [casualtyDate, setCasualtyDate] = useState(new Date());
  const [casualtyTime, setCasualtyTime] = useState(dayjs(new Date()));
  //
  const [isNri, setIsNri] = useState(true);
  const [uploaded, setUploaded] = useState([]);
  const [selectedBedDetails, setSelectedBedDetails] = useState(0);
  const [searchPatientId, setsearchPatientId] = useState(0);
  const [searchedPatientData, setSearchedPatientData] = useState();
  const [mlcModalData, setMlcModalData] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [isForEdit, setIsForEdit] = useState(false);
  // const [emergencyPatientId, setEmergencyPatientId] = useState();
  const [patchData, setPatchData] = useState(null);
  const [editAdmissionId, setEditAdmissionId] = useState(0);

  //
  const [patientdocs, setPatientdocs] = useState([]);
  const [relativedocs, setRelativedocs] = useState([]);
  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [isAdmPrint, setIsAdmPrint] = useState(null);
  const [generatedAdmId, setGeneratedAdmId] = useState(null);
  const [printCount, setPrintCount] = React.useState("");

  const handleClosePrintModal = () => {
    if (isAdmPrint === null) {
      handleOpenPrintModal(generatedAdmId, "otMedicines");
      setPrintCount("otMedicines");
      setOpenPrintModal(true);
    }

    setIsAdmPrint(false);
    // print count is where i store which print is in processs
    // navigate if the "otMedicines" is closed
    if (printCount !== "admPrint") {
      handlePreviousNavigate();
    }
  };

  const handlePreviousNavigate = () => {
    !location?.state?.previousMenuId
      ? fetchUserActionsByMenuId(location?.state?.menuId)
          .then((response) => response.data)
          .then((res) => {
            const viewObject = res.result.find(
              (item) => item.action === "View"
            );
            navigate(viewObject.menuKey, {
              state: { menuId: viewObject.menuId }, //listing menuId
            });
          })
      : navigate("/ipd/admission", {
          state: { menuId: location?.state?.previousMenuId },
        });
    setPrintCount("");
  };

  const handleOpenPrintModal = (admissionId, type) => {
    type === "admPrint" &&
      fetchPrintAdmissionForm(admissionId).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });

    type === "otMedicines" &&
      fetchPharmacyOTMedicine(admissionId).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });
  };
  ////

  useEffect(() => {
    if (
      location.state !== null &&
      location.state.row !== null &&
      location.state.row !== undefined
    ) {
      setEditAdmissionId(location.state.row.AdmissionId);
      setSearchedPatientData(location.state.row);
    }

    location?.state?.isEdit && setIsForEdit(location?.state?.isEdit);

    location?.state?.privilege && setPrivilege(location?.state?.privilege);
  }, [location]);

  //////

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const methods1 = useForm({
    mode: "onChange",
    resolver: yupResolver(personalValidationSchema),
    defaultValues: {
      SelectEmergencyNo: null,

      /////Personal Info ///////
      searchPatient: "",
      mobileNumber: "",
      altMobileNumber: "",
      email: "",
      noEmail: false,
      prefix: {
        id: 2,
        label: "Mr",
        value: "Mr",
      },
      firstName: "",
      middleName: "",
      lastName: "",
      dobDate: new Date(),
      age: "",
      ageInYears: 0,
      ageInMonths: 0,
      ageInDays: 0,
      gender: {
        id: 2,
        label: "Male",
        value: "Male",
      },
      nationality: {
        id: 1,
        label: "Indian",
        value: "Indian",
      },
      maritalStatus: 0,
      bloodGroup: 0,
      adharNumber: "",
      ///////Address Details////
      houseFlatNumber: "",
      society: "",
      nearestLandmark: "",
      address: "",
      country: {
        id: 2,
        value: "India",
        label: "India",
      },
      state: {
        id: 2,
        label: "Maharashtra",
        value: "Maharashtra",
      },
      district: {
        id: 3,
        label: "Pune",
        value: "Pune",
      },
      city: { id: 2, label: "Pune", value: "Pune" },
      area: null,
      pinCode: "",

      ////////// Representative details
      nameOfRepresentative: "",
      mobileNumberOfRepresentative: "",
      relationshipWithPatient: 0,
      representativeAddress: "",
      ////////////PatientOfficeDetails/////
      occupation: "",
      officeName: "",
      patientOfficeAddress: "",
      // remarkOfBill: "",
      ////other/////
      prefferedClass: "",
      admissionTagColor: 0,
      remarkOfAccount: "",
      remarkOfBill: "",
    },
  });
  const methods2 = useForm({
    mode: "onChange",
    resolver: yupResolver(admissionValidationSchema),
    defaultValues: {
      admittedFor: null,

      department: "",
      doctor: "",
      patientSource: {
        id: 2,
        label: "Hospital",
        value: "Hospital",
      },
      camp: null,
      patientcategory: {
        id: 2,
        label: "Self",
        value: "Self",
      },
      tariff: {
        id: 2,
        label: "Hospital",
        value: "Hospital",
      },
      staffName: "",
      staffDependent: null,
      code: "",
      limit: "",
      used: "",
      //////CompanyDetails////
      company: "",
      assistantCompany: "",
      isPpn: false,
      ppnCompany: null,
      ///////////DoctorsDetails
      referanceDoctor: 0,
      referalEntity: 0,
      internalReferanceDoctor: 0,
      doctor1: 0,
      doctor2: 0,
      ////////BedDetails//////
      admissionType: {
        id: 1,
        label: "Regular",
        value: "Regular",
      },
      BedCategory: "",
      ward: "",
      //floor: "",
      ChargesPerBedCategory: "",
      bedNumber: "",
      ///////////checkboxes///
      lockIndent: false,
      medicoLegalCase: false,
      cashPayment: false,
    },
  });
  const methods3 = useForm({
    mode: "onChange",
    defaultValues: {
      // passport details
      passportNumber: "",
      placeOfIssuePassport: "",
      DateOfIssuePassport: new Date(),
      passportValidTill: new Date(),
      //Visa Details
      visaNumber: "",
      placeOfIssueVisa: "",
      DateOfIssueVisa: new Date(),
      visaValidTill: new Date(),
      ///
      pendingPassportDetails: false,
    },
  });
  const methods4 = useForm({
    mode: "onChange",
    defaultValues: {
      PatientDocumentsType: null,
      PatientDocumentsFileData: "",

      relativeDocumentsType: null,
      relativeDocumentsFileData: "",

      pendingDocuments: false,
      individualAdmitting: 0,
      relative: 0,
      modeOfPayment: 0,
    },
  });

  const finalObject = {
    isEmergency: null,
    /////Personal Info ///////
    admissionId: 0,
    patientId: 0,
    emergencyId: 0,
    patientPhoto: null,
    mobileNumber: null,
    altMobNo: "",
    email: null,
    prefix: null,
    firstName: null,
    middleName: null,
    lastName: null,
    dob: null,
    age: 0,
    ageInYears: 0,
    ageInMonths: 0,
    ageInDays: 0,
    genderId: 0,
    nationality: null,
    maritalStatusId: 0,
    bloodGroup: 0,
    adharNumber: "",
    ///////Address Details////
    houseFlatNumber: "",
    society: "",
    landmark: "",
    country: null,
    state: null,
    district: null,
    city: null,
    area: null,
    pinCode: null,

    ////////// Representative details
    kinName: null,
    kinMobileNo: null,
    relationId: 0,
    kinAddress: null,
    ////////////PatientOfficeDetails/////
    occupation: null,
    officeName: null,
    patientOfficeAddress: null,
    ////other/////
    prefferedClass: null,
    admissionTag: 0,
    remarkForAccount: null,
    remarkForBill: null,
    ////AdmissionInfo///

    casualtyArrDate: new Date(),
    casualtyArrTime: new Date(),

    medicineOperative: null,
    admissionDate: null,
    admissionTime: null,
    unitId: null,
    department: null,
    doctor: null,
    patientSource: null,
    campId: null,
    patientCategory: null,
    employeeId: null,
    dependentId: 0,
    tariff: null,
    // mlcRequestDtos
    mlcRequestDto: null,

    //////CompanyDetails////
    company: null,
    companyAsst: null,
    isPpnPatient: false,
    ppnCompanyId: 0,
    ///////////DoctorsDetails
    refDoctorId: null,
    referEntity: 0,
    internalReferDoctorID: 0,
    doctor1: 0,
    doctor2: 0,
    ////////BedDetails//////
    admType: 0,
    bedCategoryId: null,
    wardId: null,
    //floor: null,
    chargesAsPerBedCategoryId: null,
    bedId: 0,

    ///////////checkboxes///
    lockIndent: false,
    medicoLegalCase: null,
    cashPayment: null,
    // vaccineApplication: null,

    // passport details
    passportNo: "",
    passportIssuePlace: "",
    passportIssueDate: new Date(),
    passportValidDate: new Date(),
    //Visa Details
    visaNo: "",
    visaIssuePlace: "",
    visaIssueDate: new Date(),
    visaValidDate: new Date(),
    ///
    pendingPassport: 0,

    patientDocumentsDtoList: null,
    relativeDocumentDto: null,
    pendingDocuments: false,
    documentId: 0,
    individualAdmPt: 0,
    relativeSpecific: 0,
    modeOfPayment: 0,
  };

  const { watch, setValue, resetField } = methods1;

  const {
    watch: watch2,
    formState: { errors: errors2 },
    setValue: setValue2,
  } = methods2;

  const { setValue: setValue3 } = methods3;

  const { watch: watch4, setValue: setValue4 } = methods4;

  useEffect(() => {
    if (
      location.state !== null &&
      location.state.bedDetails !== null &&
      location.state.bedDetails !== undefined
    ) {
      setBedDetailsFromBedModal(location.state.bedDetails);
      handleResetAdmForm(location.state.bedDetails);
      setTabValue(0);
    }

    if (
      location.state !== null &&
      location.state.row !== null &&
      location.state.row !== undefined
    ) {
      setsearchPatientId(location.state.row.PatientId);
    }
  }, [location]);

  const handlePatientPersonalDetails = (data, event) => {
    // event.preventDefault();
    setAllFormDetails({ ...data });
    setFirstValid(data);
    if (EmergencyNumber !== null && !duplicateAdhar) {
      setTabValue(tabValue + 1);
    } else if (EmergencyNumber === null) {
      warningAlert("Please Select Emergency Number!");
    } else if (duplicateAdhar) {
      warningAlert("Aadhar Number is Already Exists.");
    }
  };

  const handlePatientAdmissionDetails = (data, event) => {
    if (data.admittedFor === undefined || data.admittedFor === null) {
      warningAlert("Please Select Medicine/Operative Type...");
    } else if (!selectedBedDetails) {
      warningAlert("Please Select Bed !");
    } else if (selectedBedDetails) {
      setSecondValid(data);
      setAllFormDetails({ ...allFormDetails, ...data });
      //{
      !isNri && selectedBedDetails !== 0
        ? setTabValue(tabValue + 1)
        : setTabValue(3);
      // }
    }

    //setFirstFormData(data);
  };

  const tariff = watch2("tariff");

  const handleNriDetails = (data) => {
    setTabValue(tabValue + 1);
    setAllFormDetails({ ...allFormDetails, ...data });
  };

  let isEmergency = watch("emergency");
  let EmergencyNumber = watch("SelectEmergencyNo");
  //

  let islockInd = watch2("lockIndent");
  let ismedicoLegal = watch2("medicoLegalCase");
  let iscashPayment = watch2("cashPayment");
  let Self = watch4("self");
  let Relative = watch4("admittedByRelative");
  let OtherIndividual = watch4("admittedByOtherIndividual");
  let blood = watch4("bloodRelative");
  let marital = watch4("maritalRelative");
  let friends = watch4("friendsRelative");
  let otherR = watch4("otherRelative");
  let mediclaim = watch4("mediclaimMode");
  let cash = watch4("cashMode");
  let CardPayment = watch4("CardPaymentMode");
  let carporate = watch4("carporateMode");
  let otherMode = watch4("otherMode");
  let pendingDocuments = watch4("pendingDocuments");

  {
    ismedicoLegal === true ? (ismedicoLegal = 1) : (ismedicoLegal = 0);

    pendingDocuments === true ? (pendingDocuments = 1) : (pendingDocuments = 0);
  }

  const onSubmit = (data) => {
    console.log(data, allFormDetails);
    //for patient document cart -- base64 string insersion

    for (let i = 0; i < patientdocs.length; i++) {
      let fileObj = patientdocs[i].Docfile;

      if (fileObj !== null && fileObj !== undefined) {
        let baseSixtyFourString;
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
          baseSixtyFourString = reader.result;
          patientdocs[i].baseSixtyFourString = baseSixtyFourString;
        };

        // }
      }
    }

    //for relative document cart -- base64 string insersion

    for (let i = 0; i < relativedocs.length; i++) {
      let fileObj = relativedocs[i].Docfile;

      let file;
      if ((fileObj !== null && fileObj) !== undefined) {
        let baseSixtyFourString;
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
          baseSixtyFourString = reader.result;
          relativedocs[i].baseSixtyFourString = baseSixtyFourString;
        };
      }
    }

    setAllFormDetails({ ...allFormDetails, ...data });

    if (allFormDetails?.SelectEmergencyNo !== null) {
      setOpen(true);
    } else {
      warningAlert("Please Select Emergency Number!");
    }
  };

  ///handleSubmit
  const handleFormSubmit = (data) => {
    console.log("location", location);
    setOpen(false);

    let unit = token?.unitId;

    finalObject.menuId = location?.state?.menuId;
    finalObject.privilege = location?.state?.privilege || privilege;

    finalObject.patientId =
      patchData !== null && patchData !== undefined ? patchData.id : Number(0);

    finalObject.admissionId = Number(editAdmissionId);

    finalObject.emergencyId =
      EmergencyNumber !== null && EmergencyNumber !== undefined
        ? Number(EmergencyNumber.id)
        : null;

    finalObject.userId = Number(token.userId);
    finalObject.unitId = Number(unit);
    //checkboxes
    finalObject.pendingPassport = data.pendingPassportDetails ? 1 : 0;
    finalObject.isEmergency = isEmergency;
    finalObject.admittedBySelf = Self;
    finalObject.admittedByRelative = Relative;
    finalObject.admittedByOtherIndividual = OtherIndividual;
    finalObject.bloodRelative = blood;
    finalObject.maritalRelative = marital;
    finalObject.friendsRelative = friends;
    finalObject.otherRelative = otherR;
    finalObject.mediclaimMode = mediclaim;
    finalObject.cashMode = cash;
    finalObject.CardPaymentMode = CardPayment;
    finalObject.carporateMode = carporate;
    finalObject.otherMode = otherMode;
    finalObject.pendingDocuments = pendingDocuments;

    //Basic Info

    finalObject.mobileNumber = Number(data.mobileNumber);
    finalObject.altMobNo = Number(data.altMobileNumber);

    let profilePicString = profilePic.toString().split(",")[1];
    finalObject.patientPhoto = profilePicName;
    // finalObject.imageBase64 = profilePicString;
    finalObject.imageBase64 = profilePicName !== null ? profilePicString : null;

    finalObject.prefix = Number(data.prefix.id);
    finalObject.email = data.email;
    data.firstName !== null
      ? (finalObject.firstName =
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1))
      : (finalObject.firstName = null);

    data.middleName !== null
      ? (finalObject.middleName =
          data.middleName.charAt(0).toUpperCase() + data.middleName.slice(1))
      : (finalObject.middleName = null);

    data.lastName !== null
      ? (finalObject.lastName =
          data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1))
      : (finalObject.lastName = null);

    finalObject.dob = format(data.dobDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
    finalObject.ageInYears = parseInt(data.ageInYears);

    data.ageInMonths !== 0 && data.ageInMonths !== undefined
      ? (finalObject.ageInMonths = parseInt(data.ageInMonths))
      : (finalObject.ageInMonths = parseInt(0));

    data.ageInDays !== 0 && data.ageInDays !== undefined
      ? (finalObject.ageInDays = parseInt(data.ageInDays))
      : (finalObject.ageInDays = parseInt(0));

    finalObject.age = parseInt(data.age);

    data.gender !== 0
      ? (finalObject.genderId = Number(data.gender.id))
      : (finalObject.genderId = Number(0));

    data.nationality !== null
      ? (finalObject.nationality = Number(data.nationality.id))
      : (finalObject.nationality = Number(0));

    if (data.maritalStatus === null || data.maritalStatus === 0) {
      finalObject.maritalStatusId = Number(0);
    } else if (data.maritalStatus !== 0 || data.maritalStatus !== null) {
      finalObject.maritalStatusId = Number(data.maritalStatus.id);
    }

    data.bloodGroup !== 0
      ? (finalObject.bloodGroup = Number(data.bloodGroup.id))
      : (finalObject.bloodGroup = Number(0));

    finalObject.adharNumber = data.adharNumber;

    // //Address Info
    finalObject.houseFlatNumber = data.houseFlatNumber;
    finalObject.society = data.society;
    finalObject.landmark = data.nearestLandmark;
    finalObject.address = data.address;

    data.country !== null
      ? (finalObject.country = Number(data.country.id))
      : (finalObject.country = Number(0));

    data.state !== null
      ? (finalObject.state = Number(data.state.id))
      : (finalObject.state = Number(0));

    data.district !== null
      ? (finalObject.district = Number(data.district.id))
      : (finalObject.district = Number(0));

    data.city !== null
      ? (finalObject.city = Number(data.city.id))
      : (finalObject.city = Number(0));

    data.area !== null
      ? (finalObject.area = Number(data.area.id))
      : (finalObject.area = Number(0));

    finalObject.pinCode = data.pinCode;

    // //Representative Info
    data.nameOfRepresentative !== null
      ? (finalObject.kinName =
          data.nameOfRepresentative.charAt(0).toUpperCase() +
          data.nameOfRepresentative.slice(1))
      : (finalObject.kinName = null);

    finalObject.kinMobileNo = data.mobileNumberOfRepresentative;

    finalObject.relationId =
      data.relationshipWithPatient !== 0 &&
      data.relationshipWithPatient !== undefined &&
      data.relationshipWithPatient !== null
        ? Number(data.relationshipWithPatient.id)
        : Number(0);

    finalObject.kinAddress = data.representativeAddress;

    data.occupation !== null
      ? (finalObject.occupation = Number(data.occupation.id))
      : (finalObject.occupation = Number(0));

    finalObject.officeName = data.officeName;

    finalObject.patientOfficeAddress = data.patientOfficeAddress;
    //other
    data.prefferedClass !== null
      ? (finalObject.prefferedClass = Number(data.prefferedClass.id))
      : (finalObject.prefferedClass = Number(0));

    data.admissionTagColor !== 0
      ? (finalObject.admissionTag = Number(data.admissionTagColor.id))
      : (finalObject.admissionTag = Number(0));

    finalObject.remarkForAccount = data?.remarkOfAccount || "";

    finalObject.remarkForBill = data?.remarkOfBill || "";

    finalObject.casualtyArrTime = format(
      casualtyTime.$d,
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    finalObject.casualtyArrDate = casualtyDate;

    finalObject.mlcRequestDto = mlcModalData;

    finalObject.medicineOperative = Number(data.admittedFor);

    // finalObject.medicineOperative = Number(data.admittedFor);

    data.department !== null
      ? (finalObject.department = Number(data.department.id))
      : (finalObject.department = Number(0));

    data.doctor !== null
      ? (finalObject.doctor = Number(data.doctor.id))
      : (finalObject.doctor = Number(0));

    data.patientSource !== null
      ? (finalObject.patientSource = Number(data.patientSource.id))
      : (finalObject.patientSource = Number(0));

    data.camp !== null
      ? (finalObject.campId = Number(data.camp.id))
      : (finalObject.campId = Number(0));

    data.patientcategory !== null
      ? (finalObject.patientCategory = Number(data.patientcategory.id))
      : (finalObject.patientCategory = Number(0));

    data.tariff !== null
      ? (finalObject.tariff = Number(data.tariff.id))
      : (finalObject.tariff = 0);

    data.staffName !== null
      ? (finalObject.employeeId = Number(data.staffName.id))
      : (finalObject.employeeId = Number(0));

    data.staffDependent !== null
      ? (finalObject.dependentId = Number(data.staffDependent.id))
      : (finalObject.dependentId = Number(0));

    data.company !== null
      ? (finalObject.company = Number(data.company.id))
      : (finalObject.company = Number(0));

    //new isPpn,ppnCompany

    data.ppnCompany !== null
      ? (finalObject.ppnCompanyId = Number(data.ppnCompany.id))
      : (finalObject.ppnCompanyId = Number(0));

    finalObject.isPpnPatient = data?.isPpn || false;

    // data.assistantCompany !== null
    //   ? (finalObject.companyAsst = Number(data.assistantCompany.id))
    //   : (finalObject.companyAsst = Number(0));

    finalObject.companyAsst =
      data?.assistantCompany && data?.assistantCompany !== null
        ? Number(data?.assistantCompany?.id)
        : Number(0);

    data.referanceDoctor !== null && data.referanceDoctor.id
      ? (finalObject.refDoctorId = Number(data.referanceDoctor.id))
      : (finalObject.refDoctorId = Number(0));

    finalObject.refDoctorName = data?.referanceDoctor?.label || "";

    data.referalEntity !== 0 &&
    data.referalEntity !== null &&
    data.referalEntity !== undefined
      ? (finalObject.referEntity = Number(data.referalEntity.id))
      : (finalObject.referEntity = Number(0));

    finalObject.internalReferDoctorID =
      data.internalReferanceDoctor !== 0 &&
      data.internalReferanceDoctor !== null
        ? data.internalReferanceDoctor.id
        : Number(0);

    data.doctor1 !== 0 && data.doctor1 !== null
      ? (finalObject.doctor1 = Number(data.doctor1.id))
      : (finalObject.doctor1 = Number(0));

    data.doctor2 !== 0 && data.doctor2 !== null
      ? (finalObject.doctor2 = Number(data.doctor2.id))
      : (finalObject.doctor2 = Number(0));

    data.admissionType !== null
      ? (finalObject.admType = Number(data.admissionType.id))
      : (finalObject.admType = Number(0));

    data.BedCategory !== null
      ? (finalObject.bedCategoryId = Number(data.BedCategory.id))
      : (finalObject.bedCategoryId = Number(0));

    data.ward !== null
      ? (finalObject.wardId = Number(data.ward.id))
      : (finalObject.wardId = Number(0));

    selectedBedDetails !== 0
      ? (finalObject.bedId = Number(selectedBedDetails.BedId))
      : (finalObject.bedId = Number(0));

    data.ChargesPerBedCategory !== null
      ? (finalObject.chargesAsPerBedCategoryId = Number(
          data.ChargesPerBedCategory.id
        ))
      : (finalObject.chargesAsPerBedCategoryId = Number(0));

    finalObject.code = data.code;
    finalObject.limit = Number(data.limit);
    finalObject.used = Number(data.used);

    finalObject.lockIndent = islockInd || false;
    finalObject.medicoLegalCase = ismedicoLegal;
    finalObject.cashPayment = iscashPayment;
    //nri page

    const year1900 = startOfYear(new Date(1900, 0, 1)); ///acceptebale erliest date

    data.DateOfIssuePassport !== null &&
    !isBefore(data.DateOfIssuePassport, year1900)
      ? (finalObject.passportIssueDate = data.DateOfIssuePassport)
      : (finalObject.passportIssueDate = new Date());

    data.passportValidTill !== null &&
    !isBefore(data.passportValidTill, year1900)
      ? (finalObject.passportValidDate = data.passportValidTill)
      : (finalObject.passportValidDate = new Date());

    data.DateOfIssueVisa !== null && !isBefore(data.DateOfIssueVisa, year1900)
      ? (finalObject.visaIssueDate = data.DateOfIssueVisa)
      : (finalObject.visaIssueDate = new Date());

    data.visaValidTill !== null && !isBefore(data.visaValidTill, year1900)
      ? (finalObject.visaValidDate = data.visaValidTill)
      : (finalObject.visaValidDate = new Date());

    finalObject.passportNo = data.passportNumber;
    finalObject.passportIssuePlace = data.placeOfIssuePassport;
    finalObject.visaNo = data.visaNumber;
    finalObject.visaIssuePlace = data.placeOfIssueVisa;

    //document Page
    finalObject.documentId =
      patchData !== null && patchData.documentId !== null
        ? patchData.documentId
        : 0;
    finalObject.individualAdmPt = Number(data.individualAdmitting);
    finalObject.relativeSpecific = Number(data.relative);
    finalObject.modeOfPayment = Number(data.modeOfPayment);
    //patientDoc arr
    var patientDocArray = [];
    for (let i = 0; i < patientdocs.length; i++) {
      let docId =
        patientdocs[i].id !== null && patientdocs[i].id !== undefined
          ? patientdocs[i].id //Available @ new Doc
          : patientdocs[i].docId; //Available @ Old Doc
      //
      let documentName = patientdocs[i].DocName; //Available @ new Doc
      //
      let arr =
        patientdocs[i].baseSixtyFourString !== undefined
          ? patientdocs[i].baseSixtyFourString.split(",")
          : [];
      let documentBase64 = arr[1]; //Available @ new Doc
      //
      let documentPath =
        patientdocs[i].documentPath !== undefined
          ? patientdocs[i].documentPath //Available @ Old Doc
          : null;

      patientDocArray.push({
        docId,
        documentBase64,
        documentName,
        documentPath,
      });
      data.PatientDocumentCart !== null
        ? (finalObject.patientDocumentsDtoList = patientDocArray)
        : (finalObject.patientDocumentsDtoList = null);
    }

    //RelativeDoc arr
    var relativeDocArray = [];
    for (let i = 0; i < relativedocs.length; i++) {
      let relDocId =
        relativedocs[i].id !== null && relativedocs[i].id !== undefined
          ? relativedocs[i].id
          : relativedocs[i].docId;
      //
      let relDocumentName = relativedocs[i].DocName;
      //
      let arr =
        relativedocs[i].baseSixtyFourString !== undefined
          ? relativedocs[i].baseSixtyFourString.split(",")
          : [];
      let relDocumentBase64 = arr[1];
      //
      let documentPath =
        relativedocs[i].documentPath !== undefined
          ? relativedocs[i].documentPath //Available @ Old Doc
          : null;

      relativeDocArray.push({
        relDocId,
        relDocumentBase64,
        relDocumentName,
        documentPath,
      });
      data.PatientDocumentCart !== null
        ? (finalObject.relativeDocumentDto = relativeDocArray)
        : (finalObject.relativeDocumentDto = null);
    }

    setOpenBackdrop(true);

    saveAdmissionFormData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 204) {
          warningAlert(res.message);
          setOpenBackdrop(false);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setProfilePic(BlankProfile);
          setFirstValid("");
          setSecondValid("");
          methods1.reset();
          methods2.reset();
          methods3.reset();
          methods4.reset();
          setOpenBackdrop(false);
          setBedDetailsFromBedModal(null);
          resetField("searchPatient"); //clear search
          setPrivilege(null);
          if (!isForEdit) {
            handleOpenPrintModal(res.result.generatedAdmissionId, "admPrint");
            setPrintCount("admPrint");
            setGeneratedAdmId(res.result.generatedAdmissionId);
          } else if (isForEdit) {
            //if we are from bed/if have previous menuId
            !location?.state?.previousMenuId
              ? fetchUserActionsByMenuId(location?.state?.menuId)
                  .then((response) => response.data)
                  .then((res) => {
                    const viewObject = res.result.find(
                      (item) => item.action === "View"
                    );
                    navigate(viewObject.menuKey, {
                      state: { menuId: viewObject.menuId }, //listing menuId
                    });
                  })
              : //if dont have previous menuId
                navigate("/ipd/admission", {
                  state: { menuId: location?.state?.previousMenuId },
                });
          }

          setIsForEdit(false);
        }
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res?.response?.data?.message || res?.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB?.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB?.length > 0 && validationAlert(handleValidation());
      });
  };

  ///patch Data
  useEffect(() => {
    getSearchedData();
  }, [searchPatientId, editAdmissionId]);

  const getSearchedData = () => {
    searchPatientId !== null &&
      searchPatientId !== 0 &&
      fetchPatchDataNew(
        searchPatientId,
        editAdmissionId === 0
          ? searchedPatientData !== undefined && searchedPatientData !== null
            ? 1
            : 0
          : 0,
        isForEdit
      )
        .then((response) => response.data)
        .then((res) => {
          setPatchData(res.result);

          if (res.result?.IsBlackListed > 0) {
            warningAlert("Patient is blacklisted kindly contact billing.");
            setOpenBlacklistCarryModal(true);
          }

          // res.result?.IsBlackListed > 0 &&
          // validationAlert("Patient is blacklisted kindly contact billing.");

          // setEmergencyPatientId(res.result.EmergencyPatientId);
          // setSelectedBedDetails(res.result.Bed);
          if (isForEdit) {
            setSelectedBedDetails(res.result);
          }
          // setDob(res.result.DOB);

          //
        })
        .catch((res) => {
          warningAlert(res?.response?.data?.message || res?.message);
        });
  };

  useEffect(() => {
    if (EmergencyNumber && EmergencyNumber !== null) {
      setValue2("medicoLegalCase", EmergencyNumber?.MedicoLegalCase);

      if (
        EmergencyNumber?.AdmissionType &&
        EmergencyNumber?.AdmissionTypeId &&
        EmergencyNumber?.AdmissionType !== null &&
        EmergencyNumber?.AdmissionTypeId !== null
      ) {
        setValue2(
          "admissionType",
          {
            id: EmergencyNumber.AdmissionTypeId,
            label: EmergencyNumber?.AdmissionType,
            value: EmergencyNumber?.AdmissionType,
          },
          {
            shouldValidate: true,
          }
        );
      }
    }
  }, [EmergencyNumber]);

  useEffect(() => {
    if (patchData !== null) {
      patchData?.imgPath &&
        setProfilePic(`${baseUrl}/file/${patchData?.imgPath}`);
      //
      patchData.country !== null && patchData?.country !== "India"
        ? setIsNri(false)
        : setIsNri(true);
      //
      if (patchData.PTCasualityArrDate !== null) {
        setCasualtyDate(parseISO(patchData.PTCasualityArrDate));
      }
      if (patchData.PTCasualityArrTime !== null) {
        let time = parseISO(patchData.PTCasualityArrTime);
        setCasualtyTime(dayjs(time));
      }
      if (patchData.PatientCategory !== null) {
        setPatientCategoryChange(patchData.PatientCategory || "Self");
      }
      setValue("email", patchData.email, { shouldValidate: true });

      if (
        patchData.EmergencyPatientId &&
        patchData.EmergencyPatientId !== null &&
        patchData.label &&
        patchData.label !== null
      ) {
        setValue(
          "SelectEmergencyNo",
          {
            id: patchData.EmergencyPatientId,
            label: patchData.label,
            value: patchData.label,
            MedicoLegalCase: patchData.MedicoLegalCase,
          },
          { shouldValidate: true }
        );
      }

      setValue(
        "prefix",
        {
          id: patchData.prefixId,
          label: patchData.salutation,
          value: patchData.salutation,
        },
        { shouldValidate: true }
      );
      setValue("firstName", patchData.firstName, {
        shouldValidate: true,
      });
      setValue("middleName", patchData.middleName, {
        shouldValidate: true,
      });
      setValue("lastName", patchData.lastName, {
        shouldValidate: true,
      });
      setValue("dobDate", patchData.DOB, { shouldValidate: true });
      setValue("age", patchData.Age, {
        shouldValidate: true,
      });
      setValue("ageInYears", patchData.Age, {
        shouldValidate: true,
      });
      setValue("ageInMonths", patchData.ageInMonths, {
        shouldValidate: true,
      });
      setValue("ageInDays", patchData.ageInDays, {
        shouldValidate: true,
      });
      setValue(
        "gender",
        {
          id: patchData.genderId,
          label: patchData.gender,
          value: patchData.gender,
        },
        { shouldValidate: true }
      );
      // setValue(
      //   "maritalStatus",
      //   {
      //     id: patchData.genderId,
      //     label: patchData.maritalStatus,
      //     value: patchData.maritalStatus,
      //   },
      //   {
      //     shouldValidate: true,
      //   }
      // );
      setValue("mobileNumber", patchData.mobileNo, {
        shouldValidate: true,
      });
      setValue("altMobileNumber", patchData.AltMobNo, {
        shouldValidate: true,
      });
      setValue("adharNumber", patchData.adharNo, {
        shouldValidate: true,
      });
      setValue("houseFlatNumber", patchData.FlatHouseNo, {
        shouldValidate: true,
      });

      setValue("address", patchData.Address, {
        shouldValidate: true,
      });

      setValue("nearestLandmark", patchData.Landmark, {
        shouldValidate: true,
      });

      setValue("society", patchData.Society, {
        shouldValidate: true,
      });

      if (patchData.CountryId !== 0 && patchData.country !== null) {
        setValue(
          "country",
          {
            id: patchData.CountryId,
            label: patchData.country,
            value: patchData.country,
          },
          { shouldValidate: true }
        );
      }
      if (patchData.StateId !== 0 && patchData.State !== null) {
        setValue(
          "state",
          {
            id: patchData.StateId,
            label: patchData.State,
            value: patchData.State,
          },
          { shouldValidate: true }
        );
      }
      if (
        patchData.cityId !== null &&
        patchData.cityId > 1 &&
        patchData.city !== "" &&
        patchData.city !== null
      ) {
        setValue(
          "city",
          {
            id: patchData.cityId,
            label: patchData.city,
            value: patchData.city,
          },
          { shouldValidate: true }
        );
      }

      if (
        patchData.area !== null &&
        patchData.areaId !== null &&
        patchData.areaId > 1
      ) {
        setValue(
          "area",
          {
            id: patchData.areaId,
            label: patchData.area,
            value: patchData.area,
          },
          { shouldValidate: true }
        );
      }
      setValue("pinCode", patchData.pincode, {
        shouldValidate: true,
      });
      setValue("officeName", patchData.officeName, {
        shouldValidate: true,
      });
      setValue("patientOfficeAddress", patchData.officeAddress, {
        shouldValidate: true,
      });
      setValue("nameOfRepresentative", patchData.KinName, {
        shouldValidate: true,
      });
      setValue("mobileNumberOfRepresentative", patchData.KinMobileNo, {
        shouldValidate: true,
      });

      if (patchData.RelationId !== 0 && patchData.Relation !== null) {
        setValue(
          "relationshipWithPatient",
          {
            id: patchData.RelationId,
            label: patchData.Relation,
            value: patchData.Relation,
          },
          {
            shouldValidate: true,
          }
        );
      }

      setValue("representativeAddress", patchData.KinAddress, {
        shouldValidate: true,
      });

      if (patchData.OccupationId !== 0 && patchData.Occupation !== null) {
        setValue(
          "occupation",
          {
            id: patchData.OccupationId,
            label: patchData.Occupation,
            value: patchData.Occupation,
          },
          {
            shouldValidate: true,
          }
        );
      }
      if (patchData.AdmissionTagId !== 0 && patchData.AdmissionTag !== null) {
        setValue(
          "admissionTagColor",
          {
            id: patchData.AdmissionTagId,
            label: patchData.AdmissionTag,
            value: patchData.AdmissionTag,
          },
          {
            shouldValidate: true,
          }
        );
      }
      if (patchData.preferedClassId && patchData.preferedClass !== null) {
        setValue(
          "prefferedClass",
          {
            id: patchData.preferedClassId,
            label: patchData.preferedClass,
            value: patchData.preferedClass,
          },
          {
            shouldValidate: true,
          }
        );
      }

      setValue("remarkOfAccount", patchData.RemarkOfAccount, {
        shouldValidate: true,
      });

      setValue("remarkOfBill", patchData.RemarkForBill, {
        shouldValidate: true,
      });
      //////////////admission details

      setValue2("admittedFor", patchData.MedicineOperative);
      setValue2("lockIndent", patchData.LockIndent);
      // setValue2("medicoLegalCase", EmergencyNumber?.MedicoLegalCase);

      if (
        patchData.DepartmentId &&
        patchData.Department &&
        patchData.DepartmentId !== 0 &&
        patchData.Department !== null
      ) {
        setValue2(
          "department",
          {
            id: patchData.DepartmentId,
            label: patchData.Department,
            value: patchData.Department,
          },
          {
            shouldValidate: true,
          }
        );
      }
      if (
        patchData.AdmittingDocId &&
        patchData.doctorName &&
        patchData.AdmittingDocId !== 0 &&
        patchData.doctorName !== null
      ) {
        setValue2(
          "doctor",
          {
            id: patchData.AdmittingDocId,
            label: patchData.doctorName,
            value: patchData.doctorName,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.PatientSourceId &&
        patchData.PatientSource &&
        patchData.PatientSourceId !== 0 &&
        patchData.PatientSource !== null
      ) {
        setValue2(
          "patientSource",
          {
            id: patchData.PatientSourceId,
            label: patchData.PatientSource,
            value: patchData.PatientSource,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.CampId &&
        patchData.camp &&
        patchData.CampId !== 0 &&
        patchData.camp !== null
      ) {
        setValue2(
          "camp",
          {
            id: patchData.CampId,
            label: patchData.camp,
            value: patchData.camp,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.PatientCategoryId &&
        patchData.PatientCategory &&
        patchData.PatientCategoryId !== 0 &&
        patchData.PatientCategory !== null
      ) {
        setValue2(
          "patientcategory",
          {
            id: patchData.PatientCategoryId,
            label: patchData.PatientCategory,
            value: patchData.PatientCategory,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.CompanyId &&
        patchData.Company &&
        patchData.CompanyId !== 0 &&
        patchData.Company !== null
      ) {
        setValue2(
          "company",
          {
            id: patchData.CompanyId,
            label: patchData.Company,
            value: patchData.Company,
            IsPPN: patchData?.IsPPN || false,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.PPNCompanyId &&
        patchData.PPNCompany &&
        patchData.PPNCompanyId !== 0 &&
        patchData.PPNCompany !== null
      ) {
        setValue2(
          "ppnCompany",
          {
            id: patchData.PPNCompanyId,
            label: patchData.PPNCompany,
            value: patchData.PPNCompany,
          },
          {
            shouldValidate: true,
          }
        );
      }

      setValue2("isPpn", patchData?.IsPPN);

      if (
        patchData.CompanyId_Ass &&
        patchData.AssistantCoompany &&
        patchData.CompanyId_Ass !== 0 &&
        patchData.AssistantCoompany !== null
      ) {
        setValue2(
          "assistantCompany",
          {
            id: patchData.CompanyId_Ass,
            label: patchData.AssistantCoompany,
            value: patchData.AssistantCoompany,
          },
          {
            shouldValidate: true,
          }
        );
      }
      if (
        patchData.TariffId &&
        patchData.Tariff &&
        patchData.TariffId !== 0 &&
        patchData.Tariff !== null
      ) {
        setValue2(
          "tariff",
          {
            id: patchData.TariffId,
            label: patchData.Tariff,
            value: patchData.Tariff,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        // patchData.RefDocId &&
        patchData.ReferDoctor &&
        patchData.ReferDoctor !== null
      ) {
        setValue2(
          "referanceDoctor",
          {
            id: patchData.RefDocId,
            label: patchData.ReferDoctor,
            value: patchData.ReferDoctor,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.ReferEntityId &&
        patchData.ReferEntity &&
        patchData.ReferEntityId !== 0 &&
        patchData.ReferEntity !== null
      ) {
        setValue2(
          "referalEntity",
          {
            id: patchData.ReferEntityId,
            label: patchData.ReferEntity,
            value: patchData.ReferEntity,
          },
          {
            shouldValidate: true,
          }
        );
      }
      if (
        patchData.InternalRefDocID &&
        patchData.internalRefDoc &&
        patchData.InternalRefDocID !== 0 &&
        patchData.internalRefDoc !== null
      ) {
        setValue2(
          "internalReferanceDoctor",
          {
            id: patchData.InternalRefDocID,
            label: patchData.internalRefDoc,
            value: patchData.internalRefDoc,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.Doctor1 &&
        patchData.Doctor1 !== 0 &&
        patchData.Doctor1 !== null &&
        patchData.doctorOne !== null
      ) {
        setValue2(
          "doctor1",
          {
            id: patchData.Doctor1,
            label: patchData.doctorOne,
            value: patchData.doctorOne,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.Doctor2 &&
        patchData.Doctor2 !== 0 &&
        patchData.Doctor2 !== null &&
        patchData.doctorTwo !== null
      ) {
        setValue2(
          "doctor2",
          {
            id: patchData.Doctor2,
            label: patchData.doctorTwo,
            value: patchData.doctorTwo,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.EmployeeId &&
        patchData.EmployeeId !== 0 &&
        patchData.EmployeeName !== null
      ) {
        setValue2(
          "staffName",
          {
            id: patchData.EmployeeId,
            label: patchData.EmployeeName,
            value: patchData.EmployeeName,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.DependentId &&
        patchData.DependentId !== 0 &&
        patchData.employeeDependent !== null
      ) {
        setValue2(
          "staffDependent",
          {
            id: patchData.DependentId,
            label: patchData.employeeDependent,
            value: patchData.employeeDependent,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (
        patchData.AdmissionTypeId !== null &&
        patchData.AdmissionType !== null
      ) {
        setValue2(
          "admissionType",
          {
            id: patchData.AdmissionTypeId,
            label: patchData?.AdmissionType,
            value: patchData?.AdmissionType,
          },
          {
            shouldValidate: true,
          }
        );
      }

      if (isForEdit) {
        if (
          patchData.bedCategoryId !== null &&
          patchData.bedCategoryId !== 0 &&
          patchData.bedCategory !== null
        ) {
          setValue2(
            "BedCategory",
            {
              id: patchData.bedCategoryId,
              label: patchData.bedCategory,
              value: patchData.bedCategory,
            },
            {
              shouldValidate: true,
            }
          );
        }

        if (
          patchData.ChargesAsPerBedCategoryId !== null &&
          patchData.ChargesAsPerBedCategoryId !== 0 &&
          patchData.ChargesAsPerBedCategory !== null
        ) {
          setValue2(
            "ChargesPerBedCategory",
            {
              id: patchData.ChargesAsPerBedCategoryId,
              label: patchData.ChargesAsPerBedCategory,
              value: patchData.ChargesAsPerBedCategory,
            },
            {
              shouldValidate: true,
            }
          );
        }

        if (patchData.bedCategoryId !== 0 && patchData.bedCategory !== null) {
          setValue2(
            "ward",
            {
              id: patchData.Doctor2,
              label: patchData.ward,
              value: patchData.ward,
            },
            {
              shouldValidate: true,
            }
          );
        }
      }

      setValue3("passportNumber", patchData.PassportNo, {
        shouldValidate: true,
      });
      setValue3("placeOfIssuePassport", patchData.PassportIssuePlace, {
        shouldValidate: true,
      });

      ////check date is before today date///

      const year1900 = startOfYear(new Date(1900, 0, 1));
      const PassportIssueDate = isBefore(
        parseISO(patchData.PassportIssueDate),
        year1900
      );
      const PassportValidDate = isBefore(
        parseISO(patchData.PassportValidDate),
        year1900
      );
      const VisaIssueDate = isBefore(
        parseISO(patchData.VisaIssueDate),
        year1900
      );
      const VisaValidDate = isBefore(
        parseISO(patchData.VisaValidDate),
        year1900
      );
      ////
      setValue3(
        "DateOfIssuePassport",
        PassportIssueDate ? new Date() : parseISO(patchData.PassportIssueDate),
        {
          shouldValidate: true,
        }
      );
      setValue3(
        "passportValidTill",
        PassportValidDate ? new Date() : parseISO(patchData.PassportValidDate),
        {
          shouldValidate: true,
        }
      );

      //

      setValue3("visaNumber", patchData.VisaNo, {
        shouldValidate: true,
      });
      setValue3("placeOfIssueVisa", patchData.VisaIssuePlace, {
        shouldValidate: true,
      });
      setValue3(
        "DateOfIssueVisa",
        VisaIssueDate ? new Date() : parseISO(patchData.VisaIssueDate),
        {
          shouldValidate: true,
        }
      );
      setValue3(
        "visaValidTill",
        VisaValidDate ? new Date() : parseISO(patchData.VisaValidDate),
        {
          shouldValidate: true,
        }
      );

      ////////Documents page details
      if (patchData.PendingDocuments !== null) {
        setValue4(
          "pendingDocuments",
          patchData.PendingDocuments === 0 ? false : true
        );
      }

      setValue4("individualAdmitting", patchData.ind_adm_patient);
      setValue4("relative", patchData.patient_relative);
      setValue4("modeOfPayment", patchData.modeofpayment);
    } else if (patchData === null) methods1.reset();
  }, [patchData]);

  const handleResetAdmForm = (bedData) => {
    setsearchPatientId(null);
    setPatientCategoryChange("Self");
    setSearchedPatientData(null);
    setEditAdmissionId(0);
    setProfilePic(BlankProfile);
    setFirstValid("");
    setSecondValid("");
    methods1.reset();
    methods2.reset();
    methods3.reset();
    methods4.reset();
    setOpenBackdrop(false);
    resetField("searchPatient");
    setIsForEdit(false);
    setPatchData(null);
    // setSelectedBedDetails(null);
    setIsDisableSearch(false);
    setPatientdocs([]);
    setRelativedocs([]);
    // setDob(new Date());

    // setBedDetailsFromBedModal(null);

    bedData && setBedDetailsFromBedModal(bedData);
  };

  return (
    <div className="mt-10 bg-white px-3 ">
      <p className="text-center text-black font-bold text-xl py-4">Admission</p>
      <div>
        <>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#dfe6e9",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Personal Details" {...a11yProps(0)} />
                <Tab
                  label="Admission Details"
                  {...a11yProps(1)}
                  disabled={firstValid.length !== 0 ? false : true}
                />
                <Tab
                  label="NRI Details"
                  {...a11yProps(2)}
                  disabled={
                    !isNri &&
                    firstValid.length !== 0 &&
                    secondValid.length !== 0
                      ? false
                      : true
                  }
                />
                <Tab
                  label="Documents "
                  {...a11yProps(3)}
                  disabled={
                    firstValid.length !== 0 && secondValid.length !== 0
                      ? false
                      : true
                  }
                />
              </Tabs>
            </Box>
            <div className="">
              {/* ////////////////////////////////// Patient Personal Details Form /////////////////////////////// */}
              <FormProvider {...methods1}>
                <form
                //onSubmit={methods1.handleSubmit(handlePatientPersonalDetails)}
                >
                  <TabPanel value={tabValue} index={0}>
                    <PatientDetails
                      profilePic={profilePic}
                      setProfilePic={setProfilePic}
                      setProfilePicName={setProfilePicName}
                      setIsNri={setIsNri}
                      searchPatientId={searchPatientId}
                      setsearchPatientId={setsearchPatientId}
                      setSearchedPatientData={setSearchedPatientData}
                      isDisableSearch={isDisableSearch}
                      setIsDisableSearch={setIsDisableSearch}
                      location={location}
                      patchData={patchData}
                      setPatchData={setPatchData}
                      isForEdit={isForEdit}
                      //
                      setCasualtyDate={setCasualtyDate}
                      setCasualtyTime={setCasualtyTime}
                      //
                      setDuplicateAdhar={setDuplicateAdhar}
                      bedDetailsFromBedModal={bedDetailsFromBedModal}
                    />
                  </TabPanel>
                  <div className="flex justify-end">
                    <div className="mr-4  my-2">
                      {tabValue === 0 ? (
                        <>
                          <div className="flex gap-3">
                            <div>
                              <CommonButton
                                className="border border-customRed text-customRed"
                                label="Reset"
                                onClick={() => {
                                  handleResetAdmForm();
                                  setBedDetailsFromBedModal(null);
                                }}
                              />
                            </div>
                            <div>
                              <CommonButton
                                className="bg-customGreen text-white"
                                label="Next"
                                onClick={methods1.handleSubmit(
                                  handlePatientPersonalDetails
                                )}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
            {/* ////////////////////////////////// Patient Admission Details Form /////////////////////////////// */}
            <FormProvider {...methods2}>
              <form>
                <TabPanel value={tabValue} index={1}>
                  <AdmissionDetails
                    selectedBedDetails={selectedBedDetails}
                    setSelectedBedDetails={setSelectedBedDetails}
                    firstValid={firstValid}
                    setMlcModalData={setMlcModalData}
                    mlcModalData={mlcModalData}
                    patientCategoryChange={patientCategoryChange}
                    setPatientCategoryChange={setPatientCategoryChange}
                    //
                    casualtyDate={casualtyDate}
                    setCasualtyDate={setCasualtyDate}
                    //
                    casualtyTime={casualtyTime}
                    setCasualtyTime={setCasualtyTime}
                    //
                    editAdmissionId={editAdmissionId}
                    EmergencyNumber={EmergencyNumber}
                    //
                    isForEdit={isForEdit}
                    //
                    patchData={patchData}
                  />
                </TabPanel>
                {/* //Buttons// */}
                <div className="flex justify-end">
                  <div className="my-2">
                    {tabValue === 1 ? (
                      <CommonButton
                        className="border border-customBlue text-customBlue"
                        label="Previous"
                        onClick={() => {
                          setTabValue(tabValue - 1);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mx-4 my-2">
                    {tabValue === 1 ? (
                      <CommonButton
                        className="bg-customGreen text-white"
                        label="Next"
                        onClick={methods2.handleSubmit(
                          handlePatientAdmissionDetails
                        )}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
            {/* ////////////////////////////////// NRI Details Form /////////////////////////////// */}
            <FormProvider {...methods3}>
              <form>
                <TabPanel value={tabValue} index={2}>
                  <NriDetails />
                </TabPanel>
                {/* //Buttons// */}
                <div className="flex justify-end">
                  <div className="my-2">
                    {tabValue === 2 ? (
                      <CommonButton
                        className="border border-customBlue text-customBlue"
                        label="Previous"
                        onClick={() => {
                          setTabValue(tabValue - 1);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mx-4 my-2">
                    {tabValue === 2 ? (
                      <CommonButton
                        className="bg-customGreen text-white"
                        label="Next"
                        onClick={methods3.handleSubmit(handleNriDetails)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
            {/* ////////////////////////////////// Documents /////////////////////////////// */}
            <FormProvider {...methods4}>
              <form onSubmit={methods4.handleSubmit(onSubmit)}>
                <TabPanel value={tabValue} index={3}>
                  {/* <Documents /> */}
                  <Documents
                    setUploaded={setUploaded}
                    uploaded={uploaded}
                    patientdocs={patientdocs}
                    setPatientdocs={setPatientdocs}
                    relativedocs={relativedocs}
                    setRelativedocs={setRelativedocs}
                    editAdmissionId={editAdmissionId}
                    patchData={patchData}
                    //
                  />
                </TabPanel>
                {/* //Buttons// */}
                <div className="flex justify-end gap-3">
                  <div className="my-2">
                    {tabValue === 3 ? (
                      <CommonButton
                        className="border border-customBlue text-customBlue"
                        label="Previous"
                        onClick={() => {
                          {
                            !isNri ? setTabValue(tabValue - 1) : setTabValue(1);
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mr-4  my-2">
                    {tabValue === 3 && (
                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                      />
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </Box>

          {/* Print Modal */}
          {openPrintModal ? (
            <CommonPrintModal
              open={openPrintModal}
              setOpen={setOpenPrintModal}
              handleOpen={handleOpenPrintModal}
              handleClose={handleClosePrintModal}
              urlforPrint={urlforPrint}
            />
          ) : null}
          {/* backdrop */}
          <CommonBackDrop openBackdrop={openBackdrop} />

          {/* //Confirmation Modal// */}
          <ConfirmationModal
            confirmationOpen={open}
            confirmationHandleClose={handleClose}
            confirmationSubmitFunc={() => handleFormSubmit(allFormDetails)}
            confirmationLabel="Confirmation "
            confirmationMsg="Are You Sure ?"
            confirmationButtonMsg="Proceed"
          />

          <ConfirmationModal
            confirmationOpen={openBlacklistCarryModal}
            confirmationHandleClose={() => {
              setOpenBlacklistCarryModal(false);
              handleResetAdmForm();
              setBedDetailsFromBedModal(null);
            }}
            confirmationSubmitFunc={() => {
              setOpenBlacklistCarryModal(false);
            }}
            confirmationLabel="Confirmation "
            confirmationMsg={"Patient Blacklisted ! Do You Want To Continue?"}
            confirmationButtonMsg="Proceed"
          />
        </>
      </div>
    </div>
  );
};

export default PatientAdmissionDetails;
