import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import {
  fetchPatientRadarVitalsList,
  verifyRadarVitals,
} from "../../../services/cliinicalChartServices/vitalsService/VitalsService";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

export default function VitalsRadar(props) {
  const {
    setOpen,
    privilege,
    admissionId,
    selectedPatient,
    displayInfo,
  } = props;
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [spinner, showSpinner] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [openConfirmatonModal, setOpenConfirmatonModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    (displayInfo?.MRNo || selectedPatient?.MRNo) && populateTable();
  }, [displayInfo, selectedPatient]);

  const populateTable = (forPagination) => {
    showSpinner(true);

    const listObj = {
      mrno: displayInfo?.MRNo || selectedPatient?.MRNo,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchPatientRadarVitalsList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }

        setCount(res.count);
        showSpinner(false);
      })
      .catch((error) => {
        showSpinner(false);
      });
  };

  const onSubmit = () => {
    const recordSelected = dataResult.some((item) => item.isChecked);
    recordSelected
      ? setOpenConfirmatonModal(true)
      : warningAlert("Kindly select vitals record.");
  };

  const verifyRadarVitalsFn = () => {
    setOpenConfirmatonModal(false);
    setOpenBackdrop(true);

    let selectedRecord = dataResult.filter((item) => item.isChecked);
    let selectedRecordIds = selectedRecord.map((item) => item.id);

    verifyRadarVitals(selectedRecordIds)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  return (
    <div>
      <Modal open={props}>
        <Box sx={ModalStyle}>
          <div
            onClick={() => {
              setOpen(false);
            }}
          >
            <CancelPresentationIconButton />
          </div>
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Radar Vitals
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div
              className={`shadow-md mt-2 rounded-md border w-full flex items-center text-sm space-x-1 justify-between flex-wrap bg-gray-100 overflow-hidden p-2  `}
            >
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Patient Name :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.PatientName || selectedPatient?.PatientName}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold">
                  <span className="">MRNo :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.MRNo || selectedPatient?.MRNo}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Dr. Name :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.DoctorName || selectedPatient?.DoctorName}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Department :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.Department || selectedPatient?.Department}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Age :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.AgeYear || selectedPatient?.AgeYear}
                </h1>
              </div>
            </div>
            <div className="">
              {spinner ? (
                <div className="grid justify-center">
                  <LoadingSpinner />
                </div>
              ) : dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  highlightRow={false}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  setDataResult={setDataResult}
                  removeHeaders={[
                    "id",
                    "saturation",
                    "suction",
                    "visitId",
                    "patientId",
                    "isChecked",
                  ]}
                  enableSelectAll={true}
                />
              ) : (
                <h1 className="flex justify-center my-5 font-bold text-gray-600">
                  No Records Found...
                </h1>
              )}
            </div>
            <div className="w-full flex justify-end gap-3">
              <CommonButton
                label="Cancel"
                onClick={() => {
                  setOpen(false);
                }}
                className="border border-customRed text-customRed "
              />
              <CommonButton
                label="Verify"
                className="bg-customGreen text-white"
                onClick={() => onSubmit()}
              />
            </div>
          </div>
          {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
          {openConfirmatonModal && (
            <div>
              <ConfirmationModal
                confirmationOpen={openConfirmatonModal}
                confirmationHandleClose={() => {
                  setOpenConfirmatonModal();
                }}
                confirmationSubmitFunc={verifyRadarVitalsFn}
                confirmationLabel="Confirmation"
                confirmationMsg="Are you want to Verify this record ?"
                confirmationButtonMsg="Confirm"
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
