import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { fetchPatientInfoAutocomplete } from "../../../commonServices/patientInfoServices/PatientInfoServices";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import { Divider } from "@mui/material";

// Define the validation schema
const schema = yup.object().shape({
  height: yup
    .number()
    .typeError("Height must be a number")
    .positive("Height must be a positive number")
    .required("Height is required"),
  weight: yup
    .number()
    .typeError("Weight must be a number")
    .positive("Weight must be a positive number")
    .required("Weight is required"),
});

const schema2 = yup.object().shape({
  syrumCretanine: yup
    .number()
    .typeError("Serum Creatinine must be a number")
    .positive("Serum Creatinine must be a positive number")
    .required("Serum Creatinine is required"),
  gender: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required("Required"),
});

const BmiEgfrCalculator = () => {
  const methods1 = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      height: "",
      weight: "",
      bmiCalculation: "",
    },
  });

  const {
    formState: { errors: errors1 },
    control: control1,
    setValue: setValue1,
    handleSubmit: handleSubmit1,
    reset: reset1,
  } = methods1;

  const methods2 = useForm({
    mode: "onChange",
    resolver: yupResolver(schema2),
    defaultValues: {
      syrumCretanine: "",
      gender: "",
      egfrResult: "",
    },
  });

  const {
    formState: { errors: errors2 },
    control: control2,
    setValue: setValue2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    register: register2,
  } = methods2;

  const [bmiValue, setBmiValue] = useState(null);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [gendersArr, setGendersArr] = useState([
    { id: 1, label: "Male", value: "Male" },
    { id: 1, label: "Female", value: "Female" },
  ]);

  const getPatientInfoAutoComplete = (searchString) => {
    searchString &&
      fetchPatientInfoAutocomplete(searchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  const onSubmit1 = (data) => {
    if (data.height && data.weight) {
      const heightInMeters = data.height / 100;

      const bmiValue = data.weight / Math.pow(heightInMeters, 2);

      setValue1("bmiCalculation", bmiValue.toFixed(2));
      setBmiValue(bmiValue);
    }
  };

  const calculateNewEgfr = (creatinine, age, gender) => {
    const genderMultiplier = gender?.toLowerCase() === "female" ? 0.742 : 1;

    const eGFR =
      175 *
      Math.pow(creatinine * 0.011312, -1.154) *
      Math.pow(age, -0.203) *
      genderMultiplier;

    return eGFR;
  };

  const calculateeGFR = (serumCreatinine, age, gender) => {
    const K = gender.toLowerCase() === "female" ? 0.7 : 0.9;
    const alpha = gender.toLowerCase() === "female" ? -0.241 : -0.302;

    const standardizedScrK = serumCreatinine / K;

    const exp1 = Math.pow(Math.min(standardizedScrK, 1), alpha);
    const exp2 = Math.pow(Math.max(standardizedScrK, 1), -1.2);
    const exp3 = Math.pow(0.9938, age);
    const exp4 = gender.toLowerCase() === "female" ? 1.012 : 1;

    // Calculate eGFR
    const eGFR = 142 * exp1 * exp2 * exp3 * exp4;

    return eGFR;
  };

  const onSubmit2 = (data) => {
    if (selectedPatient !== null) {
      if (data.syrumCretanine && data.gender) {
        const egfrValue = calculateeGFR(
          data.syrumCretanine,
          selectedPatient.AgeYear,
          data.gender.value || selectedPatient.Gender
        );

        setValue2("egfrResult", egfrValue.toFixed(2));
      }
    } else {
      warningAlert("Please Select Patient...");
    }
  };

  return (
    <>
      <div className="mt-16">
        <div className="text-center text-black font-bold text-xl my-3">
          BMI and EGFR Calculator
        </div>
        <div className="grid lg:grid-cols-2 gap-1 mt-5 items-center">
          <FormProvider {...methods1}>
            <form onSubmit={handleSubmit1(onSubmit1)}>
              <div className="flex flex-col items-center">
                <p className="font-bold tracking-wide text-sm font-Poppins mt-2">
                  BMI Calculator
                </p>

                <div className="bg-gray-100 p-8 w-2/4 rounded-md grid gap-y-3">
                  <div>
                    <InputField
                      name="height"
                      label="Height(cm)*"
                      variant="outlined"
                      control={control1}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                      error={errors1.height}
                    />
                  </div>
                  <div>
                    <InputField
                      name="weight"
                      label="Weight(kg)*"
                      variant="outlined"
                      control={control1}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                      error={errors1.weight}
                    />
                  </div>
                  <div>
                    <InputField
                      name="bmiCalculation"
                      label="BMI (kg/m2)"
                      variant="outlined"
                      control={control1}
                      disabled={true}
                    />
                  </div>

                  <div className="flex justify-end gap-3 mt-4">
                    <div>
                      <CommonButton
                        label="Reset"
                        className="border border-customRed text-customRed"
                        onClick={() => {
                          reset1();
                          setBmiValue(null);
                        }}
                      />
                    </div>
                    <div>
                      <CommonButton
                        label="Calculate"
                        type="submit"
                        className="bg-customGreen text-white"
                      />
                    </div>
                  </div>

                  {/* alert based on BMI ranges */}

                  <div className="flex gap-2 justify-center items-center">
                    <div className="flex items-center gap-4">
                      {bmiValue !== null && (
                        <>
                          <p className="font-bold text-gray-600">Alert:</p>
                          <p
                            className={`text-lg font-semibold ${
                              bmiValue < 18.5
                                ? "text-customOrange"
                                : bmiValue >= 18.5 && bmiValue < 25
                                ? "text-customGreen"
                                : "text-customRed"
                            }`}
                          >
                            {bmiValue < 18.5
                              ? "Underweight"
                              : bmiValue >= 18.5 && bmiValue < 25
                              ? "Normal weight"
                              : bmiValue >= 25 && bmiValue < 30
                              ? "Overweight"
                              : "Obese: BMI is 30 or greater"}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>

          <FormProvider {...methods2}>
            <form onSubmit={handleSubmit2(onSubmit2)}>
              <div className="flex flex-col items-center">
                <p className="font-bold tracking-wide text-sm font-Poppins mt-2">
                  EGFR Calculator
                </p>

                <div className="bg-gray-100 p-8 rounded-md grid gap-y-3">
                  <div className="md:col-span-2 lg:col-span-2 ">
                    <SearchDropdown
                      control={control2}
                      placeholder="Search By Patient Name / MR.No./ Mob No."
                      dataArray={autocompleteData}
                      name="patientSearch"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={getPatientInfoAutoComplete}
                      inputRef={{
                        ...register2("patientSearch", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              setSelectedPatient(e.target.value);
                              setValue2(
                                "gender",
                                gendersArr.find(
                                  (item) => item.label === e.target.value.Gender
                                )
                              );
                            } else {
                              setSelectedPatient("");
                              methods2.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="py-1 col-span-2 grid grid-cols-3">
                    {/* patientInfo */}
                    <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2">
                      <div className="py-2 grid grid-cols-2 2xl:grid-cols-4 gap-2 p-3">
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm">
                          <span className="font-semibold w-28">
                            Patient Name
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedPatient &&
                                selectedPatient["patientName"]}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm">
                          <span className="font-semibold w-28">Age</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedPatient && selectedPatient.AgeYear}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm">
                          <span className="font-semibold w-28">Gender</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedPatient && selectedPatient.Gender}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm">
                          <span className="font-semibold w-28">MR. No.</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {selectedPatient && selectedPatient.MRNo}
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="grid grid-cols-2 gap-3 col-span-2 ">
                    <div>
                      <InputField
                        name="syrumCretanine"
                        label="syrumCretanine (mg/dl)*"
                        variant="outlined"
                        control={control2}
                        disabled={false}
                        inputProps={{ maxLength: 50 }}
                        error={errors2.syrumCretanine}
                      />
                    </div>
                    <div>
                      <DropdownField
                        control={control2}
                        error={errors2.gender}
                        name="gender"
                        placeholder="Gender"
                        dataArray={gendersArr}
                        isSearchable={false}
                      />
                    </div>
                    <div>
                      <InputField
                        name="egfrResult"
                        label="Result (ml/min/1.73m2)"
                        variant="outlined"
                        control={control2}
                        disabled={true}
                      />
                    </div>

                    <div className="flex justify-end gap-3">
                      <div>
                        <CommonButton
                          label="Reset"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            reset2();
                          }}
                        />
                      </div>
                      <div>
                        <CommonButton
                          label="Calculate"
                          type="submit"
                          className="bg-customGreen text-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default BmiEgfrCalculator;
