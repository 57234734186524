import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const addNewOrderSheet = async (addNewOrderSheet) => {
  return await apiClient.post(
    `/clinicalCareChart/saveOrderSheet`,
    addNewOrderSheet,
    {
      headers: authHeader(),
    }
  );
};

// /api/reports/nursing/orderSheet/{orderSheetId}
export const fetchPrintOrderSheetForm = (orderSheetId) => {
  return apiClient.get(`/reports/nursing/orderSheet/${orderSheetId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchAllOrderSheetList = async (orderSheetObj) => {
  // /api/clinicalCareChart/orderSheetListing
  return await apiClient.post(
    `/clinicalCareChart/orderSheetListing`,
    orderSheetObj,
    {
      headers: authHeader(),
    }
  );
};

export const searchOrderSheetViwe = async (OrderSheetviewObj) => {
  return await apiClient.post(
    `/clinicalCareChart/orderSheetListingSearch`,
    OrderSheetviewObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchOrderSheetAllList = async (orderSheetAllListObj) => {
  return await apiClient.post(
    `/reports/nursing/orderSheetAllList`,
    orderSheetAllListObj,
    {
      headers: authHeader(),
    }
  );
};

export const OrderSheetPrintData = (orderSheetId) => {
  return apiClient.get(`/reports/nursing/orderSheet/${orderSheetId}`, {
    headers: authHeader(),
  });
};

export const fetchPhysioOrdersPrintData = (admissionId, orderDtlsId) => {
  return apiClient.get(
    `/reports/lapReports/getPhysioOrders/${admissionId}/${orderDtlsId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchOrderSheetTaskDetails = (admissionId) => {
  return apiClient.get(
    `/clinicalCareChart/getOrderSheetTaskDetails/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const deleteTodoTask = (todoId) => {
  return apiClient.delete(`/clinicalCareChart/deleteTodo/${todoId}`, {
    headers: authHeader(),
  });
};
