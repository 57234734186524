import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { Box, Modal, Tooltip } from "@mui/material";
import {
  getDivatoolPostList,
  searchPatientInformation,
} from "../../services/cliinicalChartServices/divatoolservices/DivatoolServices";
import { useForm } from "react-hook-form";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import SearchIconButton from "../../../common/components/Buttons/SearchIconButton";
import { format } from "date-fns";
import CommonDynamicTable from "../../../common/components/CommonTable/CommonDynamicTableNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { EyeOnIcon, PrintOnIcon } from "../../../assets/icons/CustomIcons";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import DivaToolPrint from "../../prints/DvaToolPrint";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
  overflowY: "auto",
};

export default function ViewDivaTool(props) {
  const defaultValues = {
    patientSearch: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const { control, register, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [dataDivaToolList, setDataDivaToolList] = useState([]);
  const [options, setOptions] = useState([]);
  const [patientId, setPatientId] = useState(0);
  const [spinner, showSpinner] = useState(false);
  //
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [printData, setPrintData] = useState(null);
  //

  let fromDate = watch("fromDate");
  let toDate = watch("toDate");

  const filterData = () => {
    populateTable();
  };

  const handleChange = (searchString) => {
    let PatientType = 1;
    if (searchString !== "") {
      searchPatientInformation(searchString, PatientType)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  useEffect(() => {
    populateTable();
  }, []);

  
  

  const populateTable = () => {
    let obj = {
      fromDate: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      patientId:
        patientId !== 0 ? patientId : props.patientInformation?.PatientId,
      toDate: format(toDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    };

    
    showSpinner(true);

    getDivatoolPostList(obj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setDataDivaToolList(res.result);
      })
      .catch((error) => {
        errorAlert(error.message);
        showSpinner(false);
      });
  };

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 500);
    
    return (
      <div className="hidden">
        <DivaToolPrint selectDivaToolViewRow={props.selectDivaToolViewRow} />
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex space-x-1 items-center">
        <Tooltip title="View">
          <button
            type="button"
            onClick={() => {
              props.setSelectDivaToolViewRow(row);
              props.handleViewClose();
              props.setDivaScore(row?.Score);
            }}
          >
            <EyeOnIcon />
          </button>
        </Tooltip>

        <Tooltip title="Print">
          <button
            type="button"
            onClick={() => {
              setOpenPrintModal(true);
              props.setSelectDivaToolViewRow(row);
              setPrintData(row);
            }}
          >
            <PrintOnIcon />
          </button>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <div>
        <Modal
          open={props.viewOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={ModalStyle}
            className="w-[80%] h-[70%] 2xl:h-[60%] px-8 pb-2"
          >
            <div className=" w-full">
              <div className="flex justify-between items-center w-full py-2">
                <div className="w-full font-semibold text-xl mt-1">View</div>
                <CancelPresentationIconButton
                  onClick={() => {
                    props.handleViewClose();
                    props.setDivaScore(0);
                  }}
                />
              </div>
              <div className="grid grid-cols-4 gap-3">
                <div className="flex col-span-2 xl:col-span-1  gap-3">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    disablePast={false}
                    disableFuture={false}
                    inputFormat="dd-MM-yyyy"
                  />
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                    }}
                    disablePast={false}
                    disableFuture={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="flex col-span-2 gap-2">
                  <SearchDropdown
                    name="patientSearch"
                    control={control}
                    searchIcon={true}
                    placeholder="Search By Patient Name / MR.No."
                    handleInputChange={handleChange}
                    isClearable={true}
                    dataArray={options}
                    inputRef={{
                      ...register("patientSearch", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setPatientId(e.target.value.id);
                          } else if (e.target.value === null) {
                            setPatientId(0);
                          }
                        },
                      }),
                    }}
                    isDisabled={
                      props.patientInformation !== undefined ? true : false
                    }
                  />
                  <div className="">
                    <SearchIconButton onClick={filterData} />
                  </div>
                </div>
              </div>

              {spinner ? (
                <div className=" grid justify-center items-center mt-32">
                  <LoadingSpinner />
                </div>
              ) : (
                <div>
                  {dataDivaToolList.length > 0 ? (
                    <CommonDynamicTable
                      dataResult={dataDivaToolList}
                      tableClass={"rounded lg:h-[320px] 2xl:h-[350px]"}
                      highlightRow={false}
                      removeHeaders={[
                        "Id",
                        "Gender",
                        "GenderId",
                        "DoctorName",
                        "AgeYear",
                      ]}
                      renderActions={renderActions}
                    />
                  ) : (
                    <h3 className="flex justify-center my-20 font-bold text-gray-600">
                      No Records Found...
                    </h3>
                  )}
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
      {openPrintModal && renderPrint()}
    </>
  );
}
