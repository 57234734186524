import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import TableDropDownField from "../../../common/components/FormFields/TableDropDownField";
import { getCompany } from "../Services/SampleCollectionServices";

export default function SampleCollectionTestTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowCheckVal, setRowCheckVal] = React.useState("");
  const [agencyList, setAgencyList] = useState([]);
  const [isSourcedValue, setIsSourcedValue] = useState();

  const {
    testData,
    setTestData,
    selectedRow,
    setOrderDtlsIdArray,
    setSelectedTest,
  } = props;

  const { control, register, watch, setValue } = useForm();

  function getAgencyDropdown() {
    getCompany().then((response) => {
      setAgencyList(response.data.result);
    });
  }

  useEffect(() => {
    getAgencyDropdown();
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].SampleNo === 0) {
        testData[i].checked = true;
        testData[i].color = "";
        setValue(`selectTest${i}`, true);
      } else if (testData[i].SampleNo > 0) {
        testData[i].checked = false;
        setValue(`selectTest${i}`, false);
      }
      setValue(`site${i}`, testData[i].site);
      setValue(`isOutSourced${i}`, testData[i].IsOutSourced);
      setValue(`agencyName${i}`, testData[i].ExtAgencyId);
      setValue(`quantity${i}`, testData[i].Quantity);
      setValue(`techName${i}`, testData[i].TechnitionName);
      setValue(`recvBy${i}`, testData[i].OutSourceSampleReceivedBy);
    }
  }, [testData]);

  useEffect(() => {
    for (let i = 0; i < testData.length; i++) {
      let filter = agencyList.filter(
        (agency) => agency.id === testData[i].ExtAgencyId
        //
      );

      setValue(`agencyName${i}`, filter[0]);
    }
  }, [agencyList]);

  useEffect(() => {
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].checked === true) {
        if (
          (testData[i].site === null || testData[i].site === "") &&
          testData[i].IsMicro === true
        ) {
          testData[i].error = true;
        } else if (
          testData[i].site !== "" &&
          testData[i].site !== null &&
          testData[i].IsMicro === true
        ) {
          testData[i].error = false;
        } else if (
          (testData[i].site === null || testData[i].site === "") &&
          testData[i].IsMicro === false
        ) {
          testData[i].error = false;
        }
      } else if (
        testData[i].checked === false ||
        (testData[i].checked === true && testData[i].IsMicro === false)
      ) {
        testData[i].error = false;
      }
    }
  }, [testData, rowCheckVal]);

  const handleChange = (e, row, index) => {
    testData[index].site = e.target.value;
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].checked === true) {
        if (
          (testData[i].site === null || testData[i].site === "") &&
          testData[i].IsMicro === true
        ) {
          testData[i].error = true;
        } else if (testData[i].site !== "" && testData[i].IsMicro === true) {
          testData[i].error = false;
        } else if (
          (testData[i].site === null || testData[i].site === "") &&
          testData[i].IsMicro === false
        ) {
          testData[i].error = false;
        }
      } else if (
        testData[i].checked === false ||
        (testData[i].checked === true && testData[i].IsMicro === false)
      ) {
        testData[i].error = false;
      }
    }
  };

  useEffect(() => {
    let orderDtlsArray = [];
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].checked === true) {
        orderDtlsArray.push(testData[i].OrderDtlsId);
      }
    }

    setOrderDtlsIdArray(orderDtlsArray);
  }, [testData]);

  function handleCheckChange() {
    let orderDtlsArray = [];
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].checked === true) {
        orderDtlsArray.push(testData[i].OrderDtlsId);
      }
    }

    setOrderDtlsIdArray(orderDtlsArray);
  }

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded md:h-60 2xl:h-72 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Select
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Category
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Test / Profile Name
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Test / Profile
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Sample No.
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Site
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Is OutSourced
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Agency Name
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Tech. Name
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      sx={{ background: "#F1F1F1" }}
                    >
                      Recv. By
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {testData.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0.1,
                          },
                        }}
                        onChange={() => {
                          setRowCheckVal(index);
                          setSelectedTest(index);
                        }}
                      >
                        <TableCell className="flex justify-center">
                          <fieldset
                            disabled={row.SampleNo > 0}
                            onChange={(e) => {
                              testData[index].checked = e.target.checked;
                              handleCheckChange();
                              setRowCheckVal(index);
                              let temp = [...testData];
                              for (let i = 0; i < temp.length; i++) {
                                if (temp[i].checked === true) {
                                  if (
                                    (temp[i].site === null ||
                                      temp[i].site === "") &&
                                    temp[i].IsMicro === true
                                  ) {
                                    temp[i].error = true;
                                  } else if (
                                    temp[i].site !== "" &&
                                    temp[i].IsMicro === true
                                  ) {
                                    temp[i].error = false;
                                  }
                                } else if (temp[i].checked === false) {
                                  temp[i].error = false;
                                }
                              }
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              name={`selectTest${index}`}
                            />
                          </fieldset>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.Description}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row?.["Test Name"]}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.IsTest === 1 ? "Test" : "Profile"}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.SampleNo}
                        </TableCell>
                        {row.IsMicro === true ? (
                          <TableCell>
                            <fieldset>
                              <input
                                // className={`border rounded ${
                                //   row.error === true
                                //     ? "border-[#D11A2A]"
                                //     : "border-customBlue"
                                // } px-1 w-60`}
                                className="border rounded border-customBlue px-1 w-60"
                                name={`site${index}`}
                                placeholder="Site"
                                disabled={watch(`selectTest${index}`) === false}
                                defaultValue={row.site}
                                {...register(`site${index}`)}
                                onChange={(e) => {
                                  handleChange(e, row, index);
                                  // let dataArray = [...testData];
                                  // if (e.target.value) {
                                  //   dataArray[index].site = e.target.value;
                                  //   setTestData(dataArray);
                                  //
                                  // }
                                }}
                              />
                            </fieldset>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}

                        <TableCell>
                          <fieldset
                            className="flex justify-center"
                            onClick={(e) => {
                              setIsSourcedValue(index);
                              // let dataArray = [...testData];
                              if (e.target.checked === true) {
                                row.IsOutSourced = true;
                              } else if (e.target.checked === false) {
                                row.IsOutSourced = false;
                                let dataArray = [...testData];
                                dataArray[index].ExtAgencyId = 0;
                                dataArray[index].Quantity = 0;
                                dataArray[index].TechnitionName = null;
                                dataArray[index].OutSourceSampleReceivedBy =
                                  null;
                                setValue(`agencyName${index}`, null);
                                setTestData(dataArray);
                              }
                              // setTestData(dataArray);
                            }}
                            disabled={row.checked === false}
                          >
                            <CheckBoxField
                              control={control}
                              name={`isOutSourced${index}`}
                              defaultValue={row.IsOutSourced}
                            />
                          </fieldset>
                        </TableCell>
                        <TableCell>
                          {/* <DropdownField
                              control={control}
                              name={`agencyName${index}`}
                              placeholder="Agency Name"
                              dataArray={agencyList}
                              isDisabled={!watch(`isOutSourced${index}`)}
                              inputRef={{
                                ...register(`agencyName${index}`, {
                                  onChange: (e) => {
                                    
                                    let dataArray = [...testData];
                                    if (e.target.value) {
                                      dataArray[index].ExtAgencyId =
                                        e.target.value.id;
                                      setTestData(dataArray);
                                      
                                    }
                                  },
                                }),
                              }}
                            /> */}
                          <div>
                            <TableDropDownField
                              control={control}
                              name={`agencyName${index}`}
                              placeholder="Agency Name"
                              isClearable={true}
                              isSearchable={true}
                              dataArray={agencyList}
                              defaultValue={row?.ExtAgencyId === 0 && null}
                              isDisabled={
                                !watch(`isOutSourced${index}`) || !row.checked
                              }
                              menuPlacement="bottom"
                              inputRef={{
                                ...register(`agencyName${index}`, {
                                  onChange: (e) => {
                                    let dataArray = [...testData];
                                    if (e.target.value) {
                                      dataArray[index].ExtAgencyId =
                                        e.target.value.id;
                                      setTestData(dataArray);
                                    } else if (!e.target.value) {
                                      dataArray[index].ExtAgencyId = 0;
                                      setTestData(dataArray);
                                    }
                                  },
                                }),
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {/* <InputField
                              control={control}
                              name={`quantity${index}`}
                              label="Quantity"
                              disabled={!watch(`isOutSourced${index}`) || !row.checked}
                              defaultValue={row.Quantity}
                              inputRef={{
                                ...register(`quantity${index}`, {
                                  onChange: (e) => {
                                    let dataArray = [...testData];
                                    if (e.target.value) {
                                      dataArray[index].Quantity = Number(
                                        e.target.value
                                      );
                                      setTestData(dataArray);
                                      
                                    }
                                  },
                                }),
                              }}
                            /> */}
                            <input
                              className="border rounded border-customBlue px-1 w-20"
                              // style={{ borderColor: `${row.color}` }}
                              name={`quantity${index}`}
                              placeholder="Quantity"
                              disabled={
                                !watch(`isOutSourced${index}`) || !row.checked
                              }
                              value={row.Quantity}
                              type="number"
                              {...register(`quantity${index}`)}
                              onChange={(e) => {
                                let dataArray = [...testData];
                                if (e.target.value) {
                                  dataArray[index].Quantity = e.target.value;
                                  setTestData(dataArray);
                                } else if (e.target.value === "") {
                                  dataArray[index].Quantity = 0;
                                  setTestData(dataArray);
                                }
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="w-40">
                            {/* <InputField
                              control={control}
                              name={`techName${index}`}
                              label="Technician Name"
                              disabled={!watch(`isOutSourced${index}`)}
                              defaultValue={row.TechnitionName}
                              inputRef={{
                                ...register(`techName${index}`, {
                                  onChange: (e) => {
                                    let dataArray = [...testData];
                                    if (e.target.value) {
                                      dataArray[index].TechnitionName =
                                        e.target.value;
                                      setTestData(dataArray);
                                      
                                    }
                                  },
                                }),
                              }}
                            /> */}
                            <input
                              className="border rounded border-customBlue px-1"
                              // style={{ borderColor: `${row.color}` }}
                              name={`techName${index}`}
                              placeholder="Tech. Name"
                              disabled={
                                !watch(`isOutSourced${index}`) || !row.checked
                              }
                              defaultValue={row.TechnitionName}
                              {...register(`techName${index}`)}
                              onChange={(e) => {
                                let dataArray = [...testData];
                                if (e.target.value) {
                                  dataArray[index].TechnitionName =
                                    e.target.value;
                                  setTestData(dataArray);
                                } else if (e.target.value === "") {
                                  dataArray[index].TechnitionName = null;
                                  setTestData(dataArray);
                                }
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="w-40">
                            {/* <InputField
                              control={control}
                              name={`recvBy${index}`}
                              label="Recieved By"
                              disabled={!watch(`isOutSourced${index}`)}
                              defaultValue={row.OutSourceSampleReceivedBy}
                              inputRef={{
                                ...register(`recvBy${index}`, {
                                  onChange: (e) => {
                                    let dataArray = [...testData];
                                    if (e.target.value) {
                                      dataArray[
                                        index
                                      ].OutSourceSampleReceivedBy =
                                        e.target.value;
                                      setTestData(dataArray);
                                      
                                    }
                                  },
                                }),
                              }}
                            /> */}
                            <input
                              className="border rounded border-customBlue px-1"
                              // style={{ borderColor: `${row.color}` }}
                              name={`recvBy${index}`}
                              placeholder="Recv. By"
                              disabled={
                                !watch(`isOutSourced${index}`) || !row.checked
                              }
                              defaultValue={row.OutSourceSampleReceivedBy}
                              {...register(`recvBy${index}`)}
                              onChange={(e) => {
                                let dataArray = [...testData];
                                if (e.target.value) {
                                  dataArray[index].OutSourceSampleReceivedBy =
                                    e.target.value;
                                  setTestData(dataArray);
                                } else if (e.target.value === "") {
                                  dataArray[index].OutSourceSampleReceivedBy =
                                    null;
                                  setTestData(dataArray);
                                }
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
