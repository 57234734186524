import apiClient from "../../.../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const getFloorList = () => {
  return apiClient.get(`/misc/floor`, {
    headers: authHeader(),
  });
};

export const getWardList = () => {
  return apiClient.get(`/misc/ward`, {
    headers: authHeader(),
  });
};

export const getPatientList = (searchObj) => {
  return apiClient.post(`/clinicalCareChart/patientList`, searchObj, {
    headers: authHeader(),
  });
};

export const getPatientInfoData = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/patientInfo/${admissionId}`, {
    headers: authHeader(),
  });
};

export const fetchPrintClinicalCareChartForm = (painScore) => {
  return apiClient.get(`/reports/nursing/clinicalCareChatPrint/${painScore}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};

///new services
export const fetchPatientSearchCCC = (obj) => {
  return apiClient.post(`/clinicalCareChart/getPatientSearch`, obj, {
    headers: authHeader(),
  });
};

export const fetchpatientListCCC = (obj) => {
  return apiClient.post(`/clinicalCareChart/patientList`, obj, {
    headers: authHeader(),
  });
};

///to do on order sheet/ccc

export const fetchOrderSheetPendingTodos = (admissionId) => {
  return apiClient.get(
    `/clinicalCareChart/getOrderSheetPendingTodos/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveCompletedTodos = (todoIds, admissionId) => {
  return apiClient.get(
    `/clinicalCareChart/completeTodos/${todoIds}/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveQsofaDetails = (qsofaObj) => {
  return apiClient.post(`/clinicalCareChart/saveQsofaDetails`, qsofaObj, {
    headers: authHeader(),
  });
};

export const fetchQsofaDetails = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/getQsofaDetails/${admissionId}`, {
    headers: authHeader(),
  });
};

export const saveGcs = (gcsObj) => {
  return apiClient.post(`/clinicalCareChart/saveGcs`, gcsObj, {
    headers: authHeader(),
  });
};

//orderOrderView/saveSymptoms
export const saveSymptoms = (saveObj) => {
  return apiClient.post(`/orderOrderView/saveSymptoms`, saveObj, {
    headers: authHeader(),
  });
};