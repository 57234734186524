import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//patient Information
export const fetchPendingImplantPatientListing = (menuId, page, size) => {
  return apiClient.get(
    `/otDetails/pendingImplantsList/${menuId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};

//patient Information
export const fetchToClearPendingImplants = (obj) => {
  return apiClient.post(`/otDetails/clearPendingImplants`, obj, {
    headers: authHeader(),
  });
};
