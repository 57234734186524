import React from "react";

export const OpenDrugModalIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.045 15.3L11.61 12.7575L9.045 10.215L8.1 11.16L9.0675 12.1275C8.6475 12.1425 8.2389 12.075 7.8417 11.925C7.4439 11.775 7.0875 11.5425 6.7725 11.2275C6.4725 10.9275 6.2436 10.5825 6.0858 10.1925C5.9286 9.8025 5.85 9.4125 5.85 9.0225C5.85 8.7675 5.8839 8.5125 5.9517 8.2575C6.0189 8.0025 6.1125 7.755 6.2325 7.515L5.2425 6.525C4.9875 6.9 4.8 7.2975 4.68 7.7175C4.56 8.1375 4.5 8.565 4.5 9C4.5 9.57 4.6125 10.1325 4.8375 10.6875C5.0625 11.2425 5.3925 11.7375 5.8275 12.1725C6.2625 12.6075 6.75 12.9339 7.29 13.1517C7.83 13.3689 8.385 13.485 8.955 13.5L8.1 14.355L9.045 15.3ZM12.7575 11.475C13.0125 11.1 13.2 10.7025 13.32 10.2825C13.44 9.8625 13.5 9.435 13.5 9C13.5 8.43 13.3914 7.8636 13.1742 7.3008C12.9564 6.7386 12.63 6.24 12.195 5.805C11.76 5.37 11.2689 5.0475 10.7217 4.8375C10.1739 4.6275 9.615 4.5225 9.045 4.5225L9.9 3.645L8.955 2.7L6.39 5.2425L8.955 7.785L9.9 6.84L8.91 5.85C9.315 5.85 9.7275 5.9286 10.1475 6.0858C10.5675 6.2436 10.9275 6.4725 11.2275 6.7725C11.5275 7.0725 11.7564 7.4175 11.9142 7.8075C12.0714 8.1975 12.15 8.5875 12.15 8.9775C12.15 9.2325 12.1161 9.4875 12.0483 9.7425C11.9811 9.9975 11.8875 10.245 11.7675 10.485L12.7575 11.475ZM9 18C7.755 18 6.585 17.7636 5.49 17.2908C4.395 16.8186 3.4425 16.1775 2.6325 15.3675C1.8225 14.5575 1.1814 13.605 0.7092 12.51C0.2364 11.415 0 10.245 0 9C0 7.755 0.2364 6.585 0.7092 5.49C1.1814 4.395 1.8225 3.4425 2.6325 2.6325C3.4425 1.8225 4.395 1.1811 5.49 0.7083C6.585 0.2361 7.755 0 9 0C10.245 0 11.415 0.2361 12.51 0.7083C13.605 1.1811 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8186 4.395 17.2908 5.49C17.7636 6.585 18 7.755 18 9C18 10.245 17.7636 11.415 17.2908 12.51C16.8186 13.605 16.1775 14.5575 15.3675 15.3675C14.5575 16.1775 13.605 16.8186 12.51 17.2908C11.415 17.7636 10.245 18 9 18Z"
        fill="#0B83A5"
      />
    </svg>
  );
};
