import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// nursingSupervisor/nursingNightReportSearchHandover/0/3

export const nursingTakeOverDeatilsPatientByAdmissionId = (
  searchString,
  floorId
) => {
  
  return apiClient.get(
    `/nursingSupervisor/nursingNightReportSearchHandover/${searchString}/${floorId}`,
    {
      headers: authHeader(),
    }
  );
};

//  nursing report view button listing
// /api/nursingSupervisor/nursingNightReportViewList
export const fetchAllNursingNightReportList = (postObj) => {
  
  return apiClient.post(
    `/nursingSupervisor/nursingNightReportViewList`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

// save nursing night report
// /api/nursingSupervisor/saveNursingNightReport
export const saveNursingNightReport = (postObj) => {
  
 
  return apiClient.post(`/nursingSupervisor/saveNursingNightReport`, postObj, {
    headers: authHeader(),
  });
};

export const searchViewlisting = (searchObj) => {
  return apiClient.post(
    `/nursingSupervisor/nursingNightReportViewListSearch`,
    searchObj,
    { headers: authHeader() }
  );
};
