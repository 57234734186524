import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TableDropDownField from "../../../../../common/components/FormFields/TableDropDownField";
import { supplierListForPOMultiple } from "../../../../services/generalStore/pomultipleServices/POMultipleServices";

export default function MultiIndentAgainstPOItemTable(props) {
  const defaultValues = {
    serviceDoctor: null,
  };
  const { control } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [openPost, setOpenPost] = React.useState(false);

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [deleteIndex, setDeleteIndex] = React.useState(null);

  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.requiredTableArr];
      newTemplateData.splice(deleteIndex, 1);
      props.setRequiredTableArr(newTemplateData);
      handleClosePost();
    }
  };

  React.useEffect(() => {
    let temp = [...props.multiIndentAgainstItem];
    let requiredArray = [];
    for (let obj of temp) {
      let requiredObj = {};
      supplierListForPOMultiple(obj.ItemId)
        .then((response) => response.data)
        .then((res) => {
          requiredObj.supplierName = res.result;
        });
      let supplierObj = {
        id: obj.SupplierId,
        value: obj.SupplierId,
        label: obj.Supplier,
      };
      requiredObj.supplierObj = supplierObj;
      requiredObj.creditPeriod = "";
      requiredObj.expDeliveryDate = "2023-05-05T11:13:27.340Z";
      requiredObj.indentDetailsId = 0;
      requiredObj.isOrderPlaced = true;
      requiredObj.itemManufacturedById = 0;
      requiredObj.markForClosed = false;
      requiredObj.ItemName = obj.ItemName;
      requiredObj.PurchaseUOM = obj.PurchaseUOM;
      requiredObj.ReqdQty = obj.ReqdQty;
      requiredObj.freeQty = obj.freeQty ? obj.freeQty : 0;
      requiredObj.Rate = obj.Rate;
      requiredObj.itemId = obj.ItemId;
      requiredObj.pendQty = obj.PendQty ? obj.PendQty : 0;
      requiredObj.POQty = obj.POQty ? obj.POQty : 0;
      requiredObj.pendIssueQty = obj.PendIssueQty ? obj.PendIssueQty : 0;
      requiredObj.mrp = obj.mrp ? obj.mrp : 0;
      requiredObj.specification = obj.specification ? obj.specification : "";
      requiredObj.totalAmount = Number(
        Number(obj.POQty) * Number(obj.Rate)
      ).toFixed(2);
      requiredObj.discPercent = obj.discPercent ? obj.discPercent : Number(0);
      requiredObj.discAmount = Number(requiredObj.discPercent)
        ? (
            (Number(requiredObj.totalAmount) / 100) *
            Number(requiredObj.discPercent)
          ).toFixed(2)
        : 0;

      requiredObj.gstPercent = Number(obj.VAT);
      requiredObj.gstAmount = Number(
        ((Number(requiredObj.totalAmount) - Number(requiredObj.discAmount)) /
          100) *
          Number(requiredObj.gstPercent)
      ).toFixed(2);

      requiredObj.netAmount = Number(
        Number(requiredObj.totalAmount) -
          Number(requiredObj.discAmount) +
          Number(requiredObj.gstAmount)
      ).toFixed(2);

      requiredArray.push(requiredObj);

      props.setRequiredTableArr(requiredArray);
    }
  }, [props.multiIndentAgainstItem]);
  console.log("requiredTableArr123", props.requiredTableArr);
  console.log("multiIndentAgainstItem123", props.multiIndentAgainstItem);

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 12,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-72 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Supplier Name
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Indent Qty
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Free Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        UOM
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Rate
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Total Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Dics(%)
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Dics Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Gst(%)
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Gst Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Net Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        MRP
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Specification
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.requiredTableArr.map((row, index) => {
                    console.log("rowrowrowIndent123", row);

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <div
                            className="text-red-500 mr-3"
                            onClick={() => {
                              setOpenPost(true);
                              setDeleteIndex(index);
                            }}
                          >
                            {<DeleteOutlineOutlinedIcon />}
                          </div>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.ItemName}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {/* {row.supplierName} */}
                          <TableDropDownField
                            control={control}
                            defaultValue={row.supplierObj}
                            name={`supplierName${index}`}
                            dataArray={row.supplierName}
                            isSearchable={true}
                            isClearable={true}
                          />
                        </TableCell>
                        <TableCell>{row.pendIssueQty}</TableCell>

                        <TableCell className="whitespace-nowrap">
                          <input
                            className="border rounded border-blue-900 w-14 h-[25px] px-1"
                            size="small"
                            type="number"
                            name={`POQty${index}`}
                            defaultValue={row.POQty}
                            onChange={(e, index) => {
                              let arr = [...props.requiredTableArr];
                              row.totalAmount =
                                Number(row.Rate) * Number(e.target.value);
                              row.discAmount =
                                (Number(row.totalAmount) / 100) *
                                Number(row.discPercent);
                              row.gstAmount =
                                ((Number(row.totalAmount) - row.discAmount) /
                                  100) *
                                Number(row.gstPercent);

                              //NETAMT
                              let discountedAmount =
                                Number(row.totalAmount) -
                                Number(row.discAmount);
                              let netAmountVal = Number(
                                Number(discountedAmount) + Number(row.gstAmount)
                              ).toFixed(2);

                              row.POQty = Number(e.target.value);
                              row.netAmount = Number(netAmountVal);
                              arr[index] = row;
                              props.setRequiredTableArr(arr);

                              let arrTwo = [...props.multiIndentAgainstItem];
                              arrTwo[index].totalAmount = Number(
                                Number(row.Rate) * Number(e.target.value)
                              );
                              arrTwo[index].discAmount =
                                (Number(row.totalAmount) / 100) *
                                Number(row.discPercent);
                              arrTwo[index].gstAmount =
                                ((Number(row.totalAmount) - row.discAmount) /
                                  100) *
                                Number(row.gstPercent);

                              arrTwo[index].netAmount = Number(netAmountVal);

                              arrTwo[index].POQty = Number(e.target.value);
                              props.setMultiIndentAgainstItem(arrTwo);
                            }}
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          <input
                            className="border rounded border-blue-900 w-14 h-[25px] px-1"
                            size="small"
                            type="number"
                            name={`freeQty${index}`}
                            defaultValue={row.freeQty}
                            onChange={(e, index) => {
                              let arr = [...props.requiredTableArr];
                              row.freeQty = Number(e.target.value);
                              arr[index] = row;
                              props.setRequiredTableArr(arr);

                              let arrTwo = [...props.multiIndentAgainstItem];
                              arrTwo[index].freeQty = Number(e.target.value);
                              props.setMultiIndentAgainstItem(arrTwo);
                            }}
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.PurchaseUOM}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.Rate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.totalAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          <input
                            className="border rounded border-blue-900 w-14 h-[25px] px-1"
                            size="small"
                            name={`discPercent${index}`}
                            type="number"
                            defaultValue={0}
                            onChange={(e) => {
                              let arr = [...props.requiredTableArr];

                              row.discPercent = e.target.value;

                              row.discAmount =
                                (Number(row.totalAmount) / 100) *
                                Number(row.discPercent);

                              //net amount calculation
                              let discountedAmount =
                                Number(row.totalAmount) -
                                Number(row.discAmount);
                              let netAmountVal = Number(
                                Number(discountedAmount) + Number(row.gstAmount)
                              ).toFixed(2);

                              row.netAmount = Number(netAmountVal);
                              arr[index] = row;
                              props.setRequiredTableArr(arr);

                              let arrTwo = [...props.multiIndentAgainstItem];

                              arrTwo[index].discPercent = Number(
                                e.target.value
                              );

                              arrTwo[index].POQty = row.POQty;

                              arrTwo[index].discAmount =
                                (Number(row.POQty * row.Rate) / 100) *
                                Number(row.discPercent);

                              arrTwo[index].netAmount = Number(netAmountVal);
                              props.setMultiIndentAgainstItem(arrTwo);
                            }}
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.discAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          <input
                            className="border rounded border-blue-900 w-14 h-[25px] px-1"
                            size="small"
                            name={`gstPercent${index}`}
                            type="number"
                            defaultValue={row.gstPercent}
                            onChange={(e) => {
                              let arr = [...props.requiredTableArr];

                              row.gstPercent = e.target.value;

                              let discountedAmt =
                                Number(row.POQty * row.Rate) - row.discAmount;

                              let requiredGstAmount =
                                (Number(discountedAmt) / 100) *
                                Number(e.target.value);

                              row.gstAmount =
                                Number(requiredGstAmount).toFixed(2);

                              //net amount calculation

                              let netAmountVal = Number(
                                Number(discountedAmt) +
                                  Number(row.requiredGstAmount)
                              ).toFixed(2);
                              row.netAmount = netAmountVal;
                              arr[index] = row;

                              props.setRequiredTableArr(arr);

                              let arrTwo = [...props.multiIndentAgainstItem];

                              arrTwo[index].VAT = Number(e.target.value);

                              arrTwo[index].POQty = row.POQty;
                              arrTwo[index].netAmount = netAmountVal;

                              arrTwo[index].gstAmount =
                                (Number(discountedAmt) / 100) *
                                Number(e.target.value);

                              props.setMultiIndentAgainstItem(arrTwo);
                            }}
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.gstAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.netAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          <input
                            className="border rounded border-blue-900 w-[70px] h-[25px] px-1"
                            size="small"
                            name={`mrp${index}`}
                            type="number"
                            onChange={(e) => {
                              let arr = [...props.requiredTableArr];

                              row.mrp = e.target.value;

                              arr[index] = row;

                              props.setRequiredTableArr(arr);

                              let arrTwo = [...props.multiIndentAgainstItem];

                              arrTwo[index].mrp = Number(e.target.value);

                              props.setMultiIndentAgainstItem(arrTwo);
                            }}
                            // defaultValue={row.POQty}
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {/* {row.specifications} */}
                          <input
                            className="border rounded border-blue-900 w-[100px] h-[25px] px-1"
                            size="small"
                            name={`specifications${index}`}
                            onChange={(e) => {
                              let arr = [...props.requiredTableArr];

                              row.specification = e.target.value;

                              arr[index] = row;

                              props.setRequiredTableArr(arr);

                              let arrTwo = [...props.multiIndentAgainstItem];

                              arrTwo[index].specification = Number(
                                e.target.value
                              );

                              props.setMultiIndentAgainstItem(arrTwo);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {props.multiIndentAgainstItem.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-9">
                  No Item Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this service ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
