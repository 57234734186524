import React from 'react'

function Completedcallscallmangemennt() {
  return (
    <div> <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" rx="4" fill="#1EBB24" />
    <g clip-path="url(#clip0_3604_32705)">
      <path
        d="M6.54276 20.3948C7.31384 20.3271 11.3309 19.425 11.6682 18.9447C11.8261 18.7187 11.8093 18.4083 11.7335 17.5465C11.6845 17.1341 11.668 16.7184 11.6841 16.3033C12.1745 16.0037 12.6986 15.7631 13.2454 15.5865C15.3548 14.8585 17.6474 14.8588 19.7566 15.5874C20.3034 15.7641 20.8275 16.0047 21.3179 16.3043C21.334 16.7193 21.3175 17.135 21.2685 17.5475C21.1932 18.4088 21.1749 18.7187 21.3338 18.9457C21.672 19.427 25.6896 20.3266 26.4592 20.3957C26.7798 20.4226 27.0297 20.4058 27.2006 20.235C27.6957 19.7398 27.166 14.7305 26.8071 14.3716C26.7778 14.3423 23.8466 11.507 16.5005 11.4437C9.15444 11.507 6.2232 14.3423 6.19393 14.3716C5.83503 14.7305 5.3053 19.7398 5.80048 20.235C5.97129 20.4058 6.22128 20.4226 6.54276 20.3948Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3604_32705">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(5 5)"
        />
      </clipPath>
    </defs>
  </svg></div>
  )
}

export default Completedcallscallmangemennt