import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { updateMediclaimAmount } from "../../../services/admissionServices/AdmissionServices";

const MediclaimAmountModal = (props) => {
  const { menuId, privilege, selectedRow, handleList, functionalityId } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const validationSchema = yup.object().shape({
    mediClaimAmount: yup
      .string()
      .matches(/^[0-9]+$/, "Invalid")
      .nullable()
      .required(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      mediClaimAmount: "",
    },
  });

  useEffect(() => {
    selectedRow && setValue("mediClaimAmount", selectedRow?.Mediclaim_Amount);
  }, [selectedRow]);

  function onSubmit(data) {
    setOpenBackdrop(true);

    const finalObj = {
      amount: data.mediClaimAmount,
      menuId: menuId,
      patientId: selectedRow.PatientId,
      privilege: privilege,
      functionalityId: functionalityId,
    };

    updateMediclaimAmount(finalObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        props.handleClose();
        handleList();
      })
      .catch((res) => {
        errorAlert(res.rmessage || res.response.data.message);
        setOpenBackdrop(false);
      });
  }

  return (
    <div>
      {/* /// modal */}
      <Modal open={props.open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Mediclaim Amount Details
            </div>

            <hr className="border mx-1  border-blue-900" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-2 my-4">
                <div>
                  <InputField
                    name="mediClaimAmount"
                    variant="outlined"
                    label="Medicliam Amount"
                    error={errors.mediClaimAmount}
                    control={control}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end gap-3">
                <CommonButton
                  label="Cancel"
                  onClick={() => {
                    props.handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  type="submit"
                  label="Save"
                  className="bg-customGreen text-white"
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
};

export default MediclaimAmountModal;
