import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//Patient AutoComplete
export const fetchAntibioticPatientAutoComplete = (searchString) => {
  return apiClient.get(
    `/antibioticForm/getPatientAutoComplete/${searchString}`,

    {
      headers: authHeader(),
    }
  );
};

//Antibiotic PatientInfo
export const fetchAntibioticPatientInfo = (admissionId) => {
  return apiClient.get(
    `/antibioticForm/getPatientInfo/${admissionId}`,

    {
      headers: authHeader(),
    }
  );
};

//Sensitive Antibiotic
export const fetchSensitiveAntibiotic = (admissionId) => {
  return apiClient.get(
    `/antibioticForm/getSensitiveAntibiotic`,

    {
      headers: authHeader(),
    }
  );
};

// Antibiotic dropdown list
export const fetchAntibiotics = (searchString) => {
  return apiClient.get(
    `/antibioticForm/getAntibiotics/${searchString}`,

    {
      headers: authHeader(),
    }
  );
};

// Antibiotic form list
export const fetchPendingAntibioticFormList = (listObj) => {
  return apiClient.post(
    `/antibioticForm/getPendingAntibioticFormList`,
    listObj,

    {
      headers: authHeader(),
    }
  );
};

//save Antibiotic form
export const saveAntibioticUseForm = (finalObj) => {
  return apiClient.post(
    `/antibioticForm/saveAntibioticUseForm`,
    finalObj,

    {
      headers: authHeader(),
    }
  );
};
