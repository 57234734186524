import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonMasterPaginationTable from "../../../common/components/CommonTable/CommonMasterPaginationTable";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchConsentTemplatesLstAutoComplete,
  fetchTemplateList,
  fetchTemplateStatus,
} from "../../services/operationTheater/ConsentMasterServices";
import OtConcentMasterModal from "./OtConcentMasterModal";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { successAlert } from "../../../common/components/Toasts/CustomToasts";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

const OtConcentMaster = () => {
  //
  const { control, register, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      templateName: null,
      isCertificate: false,
    },
  });

  //
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState();
  const [dataResult, setDataResult] = React.useState([]);
  const [templateAutoComplete, setTemplateAutoComplete] = useState([]);
  const [templateId, setTemplateId] = useState(0);
  const [editRowData, setEditRowData] = useState(null);
  //
  const [checkedStatusInfo, setCheckedStatusInfo] = useState(null);
  const [statusConfirmation, setStatusConfirmation] = useState(false);
  const handleOpenUpdateConfirmationModal = () => {
    setStatusConfirmation(true);
  };
  const handleCloseStatusConfirmation = () => {
    setStatusConfirmation(false);
  };
  //
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const handleOpenModal = () => {
    setOpenTemplateModal(true);
  };
  const handleCloseModal = () => {
    setOpenTemplateModal(false);
  };

  const getTemplate = (searchString) => {
    if (searchString !== "") {
      fetchConsentTemplatesLstAutoComplete(searchString, getIsCertificate).then(
        (response) => {
          setTemplateAutoComplete(response.data.result);
        }
      );
    }
  };

  //
  const handleStatusChange = (row) => {
    handleOpenUpdateConfirmationModal();
    setEditRowData(row);
  };

  const updateTemplateStatus = () => {
    fetchTemplateStatus(editRowData?.id, editRowData?.Status)
      .then((response) => response.data)
      .then((res) => {
        populateTable();
        successAlert(res.message);
      });
    setStatusConfirmation(false);
  };

  //
  let getIsCertificate = watch("isCertificate");
  //

  useEffect(() => {
    populateTable();
  }, [templateId, getIsCertificate]);

  // const populateTable = () => {
  //
  //   //let pages= !forPagination ? 0 : page;

  //   fetchTemplateList( rowsPerPage, templateId)
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setData(res);
  //       setCount(res.count);
  //       setDataResult(res.result);
  //     });
  // };

  const populateTable = (forPagination) => {
    let pages = !forPagination ? 0 : page;

    fetchTemplateList(pages, rowsPerPage, templateId, getIsCertificate || false)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      })
      .catch(() => {});
  };

  const renderActions = (row, index) => {
    return (
      <>
        <div className="text-customBlue">
          <DriveFileRenameOutlineIcon
            onClick={(e) => {
              setEditRowData(row);
              setOpenTemplateModal(true);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mt-14 space-y-2">
        <label className="flex justify-center font-semibold text-lg">
          Consent Master
        </label>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className="w-80 xl:w-96">
              <SearchDropdown
                control={control}
                name="templateName"
                placeholder="Template Name"
                searchIcon={true}
                isClearable={true}
                dataArray={templateAutoComplete}
                handleInputChange={getTemplate}
                inputRef={{
                  ...register("templateName", {
                    onChange: (e) => {
                      if (e.target.value === null) {
                        setTemplateId(0);
                      } else {
                        setTemplateId(e.target.value.id);
                      }
                    },
                  }),
                }}
              />
            </div>

            <div>
              <CheckBoxField
                control={control}
                name="isCertificate"
                label="Is Certificate"
                style={{ fontSize: "12px" }}
              />
            </div>
            {/*
            <div>
               <SearchIconButton
                onClick={() => {
                  setPage(0);
                  populateTable();
                }}
              /> 

              <CommonButton
                className="bg-customBlue text-white"
                searchIcon
                onClick={() => {
                  setPage(0);
                  populateTable();
                }}
              />
            </div>*/}
          </div>
          <div className="flex justify-end">
            <CommonButton
              label="Add New Template"
              className="bg-customGreen text-white"
              onClick={() => {
                handleOpenModal();
              }}
            />
          </div>
        </div>
        <div className="space-y-2">
          <label className="font-semibold">List of Template</label>
          <div>
            {dataResult.length > 0 ? (
              <CommonMasterPaginationTable
                dataResult={dataResult}
                highlightRow={false}
                renderActions={renderActions}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                handleStatusChange={handleStatusChange}
                tableClass="2xl:h-[650px]"
                removeHeaders={[
                  "Department",
                  "DepartmentID",
                  "FontName",
                  "FontSize",
                  "IsLetterFormat",
                  "Page",
                  "WebConsentEnglish",
                  "WebConsentMarathi",
                  "WebConsentTemplate",
                  "WebMarathiDescription",
                  "id",
                ]}
              />
            ) : (
              <div className="text-center pt-6">No Data Found</div>
            )}
            {/*
            <OtConcentTempleteMasterTable
              populateTable={populateTable}
              setOpenTemplateModal={setOpenTemplateModal}
              templateId={templateId}
              tableApiFunc={fetchTemplateList}
              dataResult={dataResult}
              setDataResult={setDataResult}
              data={data}
              page={page}
              setData={setData}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
              setEditRowData={setEditRowData}
            />*/}
          </div>
        </div>
      </div>
      <OtConcentMasterModal
        openTemplateModal={openTemplateModal}
        setOpenTemplateModal={setOpenTemplateModal}
        handleCloseModal={handleCloseModal}
        setEditRowData={setEditRowData}
        editRowData={editRowData}
        populateTable={populateTable}
      />

      {editRowData?.Status === false ? (
        <ConfirmationModal
          confirmationOpen={statusConfirmation}
          confirmationHandleClose={handleCloseStatusConfirmation}
          confirmationSubmitFunc={updateTemplateStatus}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure to Re-Activate this Template ?"
          confirmationButtonMsg="Add"
        />
      ) : (
        <ConfirmationModal
          confirmationOpen={statusConfirmation}
          confirmationHandleClose={handleCloseStatusConfirmation}
          confirmationSubmitFunc={updateTemplateStatus}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure to De-Activate this Template  ?"
          confirmationButtonMsg="Add"
        />
      )}
    </>
  );
};

export default OtConcentMaster;

