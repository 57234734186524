import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { format, isAfter } from "date-fns";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import {
  fetchOfficePermissionAcceptListAutoComplete,
  fetchOfficePermissionAcceptedList,
} from "../../services/officePermissionServices/OfficePermissionsServices";

export default function AcceptedListModal(props) {
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const { handleClose } = props;
  //
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  //

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      patientSearch: "",
      // toDate: new Date(),
      // fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [FromDate, ToDate]);

  const getPatientInfoAutoComplete = (SerchString) => {
    const autoCopmleteObj = {
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd"),
      searchString: SerchString ? SerchString : "",
      toDate: format(ToDate || new Date(), "yyyy-MM-dd"),
    };

    fetchOfficePermissionAcceptListAutoComplete(autoCopmleteObj)
      .then((response) => response.data)
      .then((res) => {
        setAutocompleteData(res.result);
      });
  };

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, selectedPatient]);

  const populateTable = (forPagination) => {
    const acceptanceObj = {
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      searchId: selectedPatient?.id,
      menuId: location?.state?.menuId,
      cashCounterId: token?.cashCounterId,
      flag: 0,
      mrno: "",
      privilege: "",
    };
    fetchOfficePermissionAcceptedList(acceptanceObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Accepted Office Permissions
            </div>

            <hr className="border mx-1  border-blue-900" />

            <div className=" py-2 grid grid-cols-6 gap-3 mt-3 items-center">
              <div className="md:col-span-2 lg:col-span-2 ">
                <SearchDropdown
                  control={control}
                  placeholder="Search By Patient Name / MR.No./Mob No."
                  dataArray={autocompleteData}
                  name="patientSearch"
                  searchIcon={true}
                  isClearable={true}
                  handleInputChange={getPatientInfoAutoComplete}
                  inputRef={{
                    ...register("patientSearch", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          setSelectedPatient(e.target.value);
                        } else {
                          setSelectedPatient("");
                          methods.reset();
                        }
                      },
                    }),
                  }}
                />
              </div>

              {/* from Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              {/* to Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
            </div>

            <div>
              {dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  highlightRow={false}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  tableClass="h-96 2xl:h-max-[550px]"
                  removeHeaders={[
                    "User Name",
                    "IS_OTReserved",
                    "Request_Flag",
                    "Account_User",
                    "PatientCategoryId",
                    "Office_Detail_ID",
                    "ID",
                    "AdmissionID",
                    "PatientId",
                    "BedId",
                    // "Status",
                  ]}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>

            <div className="gap-3 my-3 flex justify-end">
              <CommonButton
                label="Cancel"
                className="border border-customRed text-customRed"
                onClick={() => handleClose()}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
