import { baseUrl } from "../../http-common";
import apiClient from "../../http-common";

const login = async (loginId, password, unitId, cashCounterId) => {
  const response = await apiClient.post(baseUrl + `/login`, {
    loginId,
    password,
    unitId,
    cashCounterId,
  });
  if (response.data.statusCode === 200) {
    // if (response.data.result.token) {
      localStorage.setItem("userInfo", JSON.stringify(response.data.result));
    // }
    return { userInfo: response.data.result, message: response.data.message };
  } else {
    return response.data;
  }
};

const logout = () => {
  localStorage.removeItem("userInfo");
};

const authService = {
  login,
  logout,
};

export default authService;
