import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import OrderListModal from "./DrugScheduleModal";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { cancelPrescribedDrug } from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import { useState } from "react";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { Tooltip } from "@mui/material";

function DrugAdministrationOrderListTable(props) {
  console.log(props);
  //state varibale for the table
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [row, setRow] = React.useState("");
  const [rowIndex, setRowIndex] = React.useState("");
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [privilege, setPrivilege] = useState(null);

  // Confirmation modal
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  // open modal drug schedule
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = (index, row) => {
    setRow(row);
    setRowIndex(index);
    // PrescriptionDetailId
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "PrescriptionDetailId",
    "DoseId",
    "DrugId",
    "RouteId",
  ]);

  // call cancel drug api
  function addRecord() {
    console.log("props.patientInfo", props.patientInfo, props);
    if (selectedDrug !== null) {
      props.setOpenLoader(true);
      if (props.patientInfo === undefined) {
        console.log("props.patientInfo", props.patientInfo, props.privilege);
        cancelPrescribedDrug(
          selectedDrug?.PrescriptionDetailId,
          props.patientInfo.menuId,
          privilege || props.privilege
        )
          .then((response) => response.data)
          .then((res) => {
            if (res.statusCode === 200) {
              successAlert(res.message);
              setOpenPost(false);
              props.setOpenLoader(false);
              props.populateTable();
            }
          })
          .catch((error) => {
            errorAlert(error.response.data.message || error.message);
            props.setOpenLoader(false);
          });
      } else {
        cancelPrescribedDrug(
          selectedDrug?.PrescriptionDetailId,
          props.patientInfo.menuId || props.menuId,
          privilege || props.patientInfo.privilege || props.privilege
        )
          .then((response) => response.data)
          .then((res) => {
            if (res.statusCode === 200) {
              successAlert(res.message);
              setOpenPost(false);
              props.setOpenLoader(false);
              props.populateTable();
            }
          })
          .catch((error) => {
            errorAlert(error.response.data.message || error.message);
            props.setOpenLoader(false);
          });
      }
    }
  }
  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-32 2xl:h-[250px] border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold">Action</span>
                    </TableCell>
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow
                        key={index.id}
                        hover
                        tabIndex={-1}
                        style={{
                          backgroundColor: rowIndex === index ? "#FFC44B" : "",
                        }}
                      >
                        {props.userActions.map((actions, i) => (
                          <>
                            {actions.isAction === true ? (
                              <>
                                {actions.action === "Cancel" ? (
                                  <>
                                    <TableCell>
                                      <Tooltip title="Cancel Drug">
                                        <span
                                          className="text-gray-600 mr-3"
                                          onClick={() => {
                                            setPrivilege(actions.action);
                                            setOpenPost(true);
                                            setSelectedDrug(row);
                                          }}
                                        >
                                          <CancelIcon />
                                        </span>
                                      </Tooltip>
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : null}
                          </>
                        ))}

                        {headers &&
                          headers.map((header, i) => (
                            <>
                              <TableCell
                                onClick={() => {
                                  const containsCreate = props.userActions.some(
                                    (item) =>
                                      item.action === "Drug Schedule" &&
                                      item.isAction
                                  );
                                  !containsCreate &&
                                    errorAlert("Access Denied");

                                  if (
                                    props.STATValue !== true &&
                                    props.SOSValue !== true &&
                                    containsCreate
                                  ) {
                                    // if (
                                    //   row.Frequency === "STAT" ||
                                    //   row.Frequency === "SOS"
                                    // ) {
                                    // } else {
                                    handleClick(index, row);
                                    handleOpen();
                                    props.setPrivilege("Drug Schedule");
                                    // }
                                  }
                                }}
                                className="whitespace-nowrap cursor-pointer"
                                key={row.id}
                              >
                                {row[header]}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {open ? (
          <OrderListModal
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            row={row}
            setRow={setRow}
            handleClose={handleClose}
            selectedPatientInfo={props.selectedPatientInfo}
            populateTable={props.populateTable}
            getDrugList={props.getDrugList}
            menuId={props.menuId}
            privilege={props.privilege}
            setOpenLoader={props.setOpenLoader}
            patientInfo={props.patientInfo}
          />
        ) : (
          ""
        )}
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure Want To Cancel Drug ?"
          confirmationButtonMsg="Yes"
        />
      </div>
    </>
  );
}
export default React.memo(DrugAdministrationOrderListTable);
