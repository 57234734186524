//imports from react library
import React, { createContext } from "react";

//fromfield control liberary componant import

import SearchIcon from "@mui/icons-material/Search";
import CommonTable from "./CommonTable";
//fromfield control liberary componant import
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import RadioField from "../../common/components/FormFields/RadioField";
import ListOfGroupsDetails from "./ListOfGroupsDetails";
import PatientVisitMarkedListingTable from "./PatientVisitMarkedListingTable";
// import SearchIcon from '@mui/icons-material/Search';

import { useLocation } from "react-router-dom";
import {
  getListOfGroupsAndDetails,
  getPreviousDetails,
  getPreviousDocuments,
  postPatientEngagementList,
  searchPatientEngagementList,
} from "../../PatientEngagement/services/PatientEngagementServices";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../commonServices/functionalityServices/FunctionalityServices";

export const PatientEngagementContext = createContext();

export default function PatientEngagementRegistration() {
  const [opdcoordinator, setOpdCoordinator] = React.useState("");
  const [preDetailsId, setPreDetailsId] = React.useState("");
  const [listOfGroups, setListOfGroups] = React.useState([]);
  const [patientPreDetails, setPatientPreDetails] = React.useState([]);
  const [patientListing, setPatientListing] = React.useState([]);
  const [searchstring, setSearchstring] = React.useState("");
  const [patientVisitId, setPatientVisitId] = React.useState("");
  const [patientAdmissionId, setPatientAdmissionId] = React.useState("");
  const [documentsArray, setDocumentsArray] = React.useState([]);
  const [opdIpdExternal, setOpdIpdExternal] = React.useState(null);
  const [savedGroupDetails, setSavedGroupDetails] = React.useState([]);
  const [selectedID, setSelectedID] = React.useState(null);
  const [userActions, setUserActions] = React.useState([]);

  let location = useLocation();

  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  const schema = yup.object().shape({});

  const defaultValues = {
    patienttype: "Opd",
  };

  const { control, watch, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const fnHandleReset = () => {
    reset(defaultValues);
  };

  const patienttype = [
    {
      id: "Opd",
      value: "Opd",
      label: "OPD",
    },
    {
      id: "Ipd",
      value: "Ipd",
      label: "IPD",
    },
  ];

  let getPatientType = watch("patienttype");

  React.useEffect(() => {
    if (preDetailsId) {
      PopulateTable();
      postPatientEngagementList();
      searchPatientEngagementList();
    }
  }, [preDetailsId]);

  React.useEffect(() => {
    PreviousDetails(opdIpdExternal);
  }, [opdIpdExternal]);

  const PopulateTable = () => {
    getListOfGroupsAndDetails()
      .then((response) => response.data)
      .then((res) => {
        setListOfGroups(res);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    if (savedGroupDetails) {
      for (let object of savedGroupDetails) {
        for (let obj of listOfGroups.result) {
          if (obj.id === object.PTEngGroupId) {
            obj.isChecked = true;
            obj.ReScheduleDate = object.ReScheduleDate;
            obj.PTEngDetailsId = object.PTEngDetailsId;
          }
          setListOfGroups({ statusCode: 200, result: listOfGroups.result });
        }
      }
    }
  }, [savedGroupDetails]);

  React.useEffect(() => {
    !!getPatientType && populateTable();
    setPatientPreDetails([]);
    getListOfGroupsAndDetails()
      .then((response) => response.data)
      .then((res) => {
        setListOfGroups(res);
      })
      .catch(() => {});
  }, [getPatientType]);

  React.useEffect(() => {
    if (getPatientType === "Opd") {
      setOpdIpdExternal(opdcoordinator?.visitId);
    } else if (getPatientType === "Ipd") {
      setOpdIpdExternal(opdcoordinator?.admissionId);
    }
  }, [opdcoordinator]);

  const PreviousDetails = () => {
    if (opdIpdExternal) {
      getPreviousDetails(opdIpdExternal)
        .then((response) => response.data)
        .then((res) => {
          if (res.result?.length > 0) {
            let temp = res.result;
            let array = [];
            for (let obj of temp) {
              let object = {};
              object.ID = obj.ID;
              object.Date = obj.Date;
              object.Time = obj.Time;
              object.PatientType = obj.PatientType;
              object.AddedBy = obj.AddedBy;

              getPreviousDocuments(obj.ID)
                .then((response) => response.data)
                .then((res) => {
                  object.documentSource = res.result;
                  array.push(object);
                  setPatientPreDetails(array);
                });
            }
          } else {
            setPatientPreDetails([]);
          }
        });
    }
  };

  const populateTable = () => {
    let searchObjTwo = {
      type: getPatientType,
      searchString: "",
    };
    // console.log("searchObjTwo",searchObjTwo);

    postPatientEngagementList(searchObjTwo)
      .then((response) => response.data)
      .then((res) => {
        setPatientListing(res);
      });
  };

  function handleChange(string) {
    if (string.target.value !== "" && !!getPatientType) {
      setSearchstring(string.target.value);

      let searchObj = {
        type: getPatientType,
        searchString: searchstring,
      };

      searchPatientEngagementList(searchObj)
        .then((response) => response.data)
        .then((res) => {
          setPatientListing(res);
        });
    } else {
      !!getPatientType && populateTable();
    }
  }

  return (
    <PatientEngagementContext.Provider
      value={{
        preDetailsId,
        patientVisitId,
        patientAdmissionId,
        getPatientType,
        patientPreDetails,
        setPatientPreDetails,
        opdcoordinator,
        documentsArray,
        setDocumentsArray,
        PreviousDetails,
        setSavedGroupDetails,
        setSelectedID,
        setOpdCoordinator,
        fnHandleReset,
        userActions,
      }}
    >
      <div className="mt-12 px-2 w-full">
        <div className="text-center text-slate-900 font-bold text-xl ">
          Patient Engagement Registration
        </div>
        <div className="lg:flex lg:space-x-1  w-full">
          <form className="lg:w-[33%] xl:w-[25%]">
            <div className="  lg:grid-cols-1 grid gap-2 w-full my-2 lg:my-0 ">
              <div className="w-full lg:grid lg:grid-cols-1  md:grid md:grid-cols-3 gap-2 md:items-center">
                <fieldset className="border border-gray-300 col-span-3 md:col-span-1 w-full md:py-0  xl:mt-3 lg:py-2 text-left lg:px-2 rounded ">
                  <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1 lg:px-2 md:px-2 2xl:pt-3">
                    Patient Type
                  </legend>
                  <div className="flex w-full justify-center items-center ">
                    <RadioField
                      style={{ display: "md:flex" }}
                      label=""
                      name="patienttype"
                      control={control}
                      isSearchable={true}
                      dataArray={patienttype}
                    />
                  </div>
                </fieldset>
                {/* searchable */}
                <div className="w-[100%] items-center ">
                  <TextField
                    sx={{ width: "100%" }}
                    className="z-80 "
                    name="SelfbillsttlementSearch"
                    placeholder="Search By Patient Name"
                    size="small"
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="">
                <PatientVisitMarkedListingTable
                  data={patientListing}
                  setOpdCoordinator={setOpdCoordinator}
                  setPreDetailsId={setPreDetailsId}
                  setPatientVisitId={setPatientVisitId}
                  setPatientAdmissionId={setPatientAdmissionId}
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                />
              </div>
            </div>
          </form>
          <div className="lg:border-l-2 w-full lg:grid  lg:grid-cols-1 xl:gap-2 md:mt-2  px-2">
            <div className="2xl:p-0 m-0">
              <fieldset
                className="border border-gray-300 col-span-3 w-full lg:py-1 2xl:py-0 2xl:p-0
               text-left lg:px-2 md:p-2 rounded bg-gray-100"
              >
                <legend className="text-slate-900 pr-4 font-bold text-lg px-2 2xl:px-2">
                  OPD Coordinator
                </legend>
                <fieldset className="col-span-3 w-full lg:py-1 text-left lg:px-2 rounded ">
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 justify-items-start">
                    <div className="flex items-center py-1">
                      <h5 className="text-slate-900 pr-4 font-semibold xl:w-[110px] whitespace-nowrap">
                        Patient Name
                      </h5>
                      <label className="text-slate-700">
                        : &nbsp; {opdcoordinator.PatientName}
                      </label>
                    </div>
                    <div className="flex gap-2 py-1">
                      <h1 className="   text-slate-900 items-center  flex ">
                        <h5 className="text-slate-900 font-semibold xl:w-[70px]">
                          MR No.
                        </h5>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal text-slate-700 lg:space-x-20">
                        {opdcoordinator.MRNo}
                      </h1>
                    </div>

                    <div className="flex items-center  py-1">
                      <h5 className="text-slate-900  pr-6 font-semibold xl:w-[60px]">
                        Gender
                      </h5>
                      <label className="text-slate-700">
                        : {opdcoordinator.Gender}
                      </label>
                    </div>
                    <div className="flex items-center  py-1">
                      <h5 className="text-slate-900 pr-[20px] lg:pr-[22px] xl:w-[110px] font-semibold">
                        Age
                      </h5>
                      <h1 className="text-slate-700">
                        : {opdcoordinator.AgeYear}
                      </h1>
                    </div>

                    <div className="flex items-center  w-full  py-1 ">
                      <h5 className="text-slate-900 pr-[17px] font-semibold xl:w-[70px]">
                        Address
                      </h5>
                      <label className="text-slate-700 ">
                        : &nbsp; {opdcoordinator.Address}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </fieldset>
            </div>
            <div className="w-full">
              <h6 className="text-slate-900 pr-4 font-bold text-lg">
                Previous Details
              </h6>
              <CommonTable
                data={patientPreDetails}
                mrno={opdcoordinator?.MRNo}
              />
            </div>
            <fieldset
              className="w-full"
              disabled={opdcoordinator === "" ? true : false}
              onClick={() => {
                if (opdcoordinator === "") {
                  warningAlert("Please Select Patient First.");
                }
              }}
            >
              <h6 className="text-slate-900 pr-2 font-bold text-lg ">
                List Of Groups and Details
              </h6>
              {listOfGroups.hasOwnProperty("result") &&
              listOfGroups.result.length > 0 &&
              listOfGroups.statusCode === 200 ? (
                <ListOfGroupsDetails
                  data={listOfGroups}
                  PreviousDetails={PreviousDetails}
                  savedGroupDetails={savedGroupDetails}
                />
              ) : (
                ""
              )}
            </fieldset>
          </div>
        </div>
      </div>
    </PatientEngagementContext.Provider>
  );
}
