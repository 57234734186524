import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const fetchListOfMiscellaneousExpense = (object) => {
  return apiClient.post(`/accounting/getListOfMiscellaneousExpense`, object, {
    headers: authHeader(),
  });
};

export const addNewMiscellaneousExpense = (object) => {
  return apiClient.post(`/accounting/saveMiscellaneousExpense`, object ,  {
    headers: authHeader(),
  });
};


export const MiscellaneousExpenceReportData = (obj) => {
  return apiClient.post(`/accountingPrints/getMiscExpensePrint`, obj, {
     headers: authHeader(),
  });
};

export const PaymentDataReceipt = (miscExpenseId) => {
  // /api/accountingPrints/getMiscExpenseReceiptPrint/{miscExpenseId}
  return apiClient.get(`/accountingPrints/getMiscExpenseReceiptPrint/${miscExpenseId}`, {
   headers: authHeader(),
  });
};