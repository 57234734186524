import { Box, Modal, TextField } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckboxField from "../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchAllServices,
  getWorkOrderGenInfo,
  savePathOrderBooking,
  searchPatientList,
} from "../Services/WorkOrderGenerationServices";
import AddServicesTable from "../common/AddServicesTable";
import { current } from "@reduxjs/toolkit";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

const PathWorkOrderGeneration = (props) => {
  const { PatientId, menuId } = props;
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedPatient, setSelectedPatient] = React.useState(null);
  const [patientId, setPatientId] = React.useState({});
  const [serviceSearch, setServiceSearch] = React.useState([]);
  const [serviceArr, setServiceArr] = React.useState([]);
  const [finalData, setFinalData] = React.useState();
  const [openPost, setOpenPost] = React.useState(false);
  const [rowIndex, setRowIndex] = React.useState();
  const [serviceInput, setServiceInput] = useState(null);
  const [privilege, setPrivilege] = React.useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [openInstructionModal, setOpenInstructionModal] = useState(false);
  const handleClosePost = () => {
    setOpenPost(false);
  };

  const searchDropdownElement = useRef("");
  const searchPatientElement = useRef("");
  let addbtnRef = useRef();
  let savebtnRef = useRef();

  const {
    control,
    watch,
    register,
    getValues,
    setValue,
    reset,
    handleSubmit,
  } = useForm();

  let getEmergency = watch("emergency");

  let getUrgent = watch("urgent");
  let getAddSample = watch("addSample");
  let clinicalSelectedPatient = PatientId;

  useEffect(() => {
    (location?.state?.menuId || menuId) &&
      fetchUserActionsByMenuId(location?.state?.menuId || menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, [clinicalSelectedPatient]);

  function handleChange(string) {
    if (string !== "") {
      let patientType = 1;
      searchPatientList(string, patientType)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  }

  let patientObj = {
    patientName: selectedPatient?.patientName,
    MRNo: selectedPatient?.MRNo,
    gender: selectedPatient?.gender,
    AgeYear: selectedPatient?.AgeYear,
    doctorName: selectedPatient?.doctorName,
    unit: selectedPatient?.unit,
    patientSource: selectedPatient?.patientSource,
    patientCategory: selectedPatient?.patientCategory,
    tariff: selectedPatient?.tariff,
    department: selectedPatient?.department,
    doctorName: selectedPatient?.doctorName,
    company: selectedPatient?.company,
  };

  const handleService = (serviceVal) => {
    if (serviceVal !== "" && selectedPatient !== null) {
      setServiceInput(serviceVal);
      let serviceObj = {
        applicableTo: 1,
        classId: selectedPatient?.classId,
        description: serviceVal,
        tariffId: selectedPatient?.TariffId,
        unitId: selectedPatient?.UnitId,
        groupId: 2,
        serviceCode: "%",
        isEmergency: getEmergency === true ? true : false,
      };
      fetchAllServices(serviceObj)
        .then((response) => response.data)
        .then((res) => {
          setServiceSearch(res.result);
        });
    }
  };

  function unDuplicateArraySingleValues(array) {
    // Check if we are dealing with an Array that is not empty
    if (!array || !Array.isArray(array) || array.length === 0) {
      return array;
    }

    // Return a Array of unique values thanks to the Set
    return [...new Set(array)];
  }

  function unDuplicateArrayObjects(array, propertyName) {
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      !propertyName
    ) {
      return array;
    }
    // Create an Array off the values of the keys we want to check
    let objectArrayKeys = array.map((item) => item[propertyName]);

    // Remove duplicate values from those values with our previous function
    let uniqueKeys = unDuplicateArraySingleValues(objectArrayKeys);

    // Return an Array with only unique Objects
    return uniqueKeys.map((key) =>
      array.find((item) => item[propertyName] === key)
    );
  }

  let getService = watch("searchService");
  let getSite = watch("site");

  let concession;
  let emergency;
  let isPackage;
  const addServices = () => {
    {
      getService.Concession === true ? (concession = 1) : (concession = 0);
    }
    {
      getService.Emergency === true ? (emergency = 1) : (emergency = 0);
    }
    {
      getService.IsPackage === true ? (isPackage = 1) : (isPackage = 0);
    }

    let addServiceObj = {
      // chargeId: getService.Id,
      // tariffId: getService.TariffId,
      serviceCode: getService.ServiceCode,
      serviceDiscription: getService.label,
      testCharges: getService.Gross,
      classId: getService.ClassId,
      rate: getService.Rate,
      concession: concession,
      deductionAmt: getService.DeductibleAmt,
      deductionPerc: getService.DeductiblePerc,
      discountAmt: getService.DiscountAmt,
      emergency: emergency,
      isApplicable: "I",
      isPackageService: isPackage,
      netAmt: getService.NetAmt,
      rate: getService.Rate,
      serviceId: getService.ServiceId,
      totalAmount: getService.NetAmt,
      qty: 1,
      site: getSite ? getSite : "",
    };

    let arr = [...serviceArr];
    arr.push(addServiceObj);
    setServiceArr(unDuplicateArrayObjects(arr, "serviceId"));
  };

  const onSubmitData = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));

    let admissionId = selectedPatient?.AdmissionId;
    let visitAdmissionId;
    visitAdmissionId = admissionId;

    let remark = getValues("remark");

    let postObj = {
      menuId: location.state.menuId || menuId,
      privilege: privilege,
      addedBy: getUserId.userId,
      chargesRequestDto: serviceArr,
      opdIpd: 1,
      opdIpdExternalId: visitAdmissionId,
      colourTubeSample: true,
      existSample: getAddSample === true ? true : false,
      flag: 0,
      isApproved: true,
      isCancelled: true,
      isCompleted: true,
      isDelivered: true,
      isOrderGenerated: true,
      isPrinted: true,
      isSynchronised: true,
      testType: true,
      urgent: getUrgent === true ? true : false,
      remark: remark,
      tariffId: selectedPatient?.TariffId,
      date: new Date(),
      // time: dayjs(new Date()),
      time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    };

    setFinalData(postObj);
    if (selectedPatient !== null) {
      if (serviceArr.length > 0) {
        setOpenPost(true);
      } else {
        warningAlert("Add atleast 1 Service...!");
      }
    } else {
      warningAlert("Please Select Patient...!");
    }
  };

  function postData() {
    setOpenBackdrop(true);
    handleClosePost();
    savePathOrderBooking(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleClosePost();
        setOpenBackdrop(false);
        setServiceArr([]);
        setValue("searchService", null);
        setValue("searchPatient", null);
        setValue("remark", "");
        setSelectedPatient(null);
        setOpenPost(false);
        setValue("hivConsent", false);
        setValue("urgent", false);
        setValue("emergency", false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        handleClosePost();
        setOpenBackdrop(false);
      });
  }

  function focusServicesSearchDropdown() {
    let fieldsetTag = searchDropdownElement.current;
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  useEffect(() => {
    let fieldsetTag = searchPatientElement.current;
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }, []);

  useEffect(() => {
    if (clinicalSelectedPatient) {
      let selectObj = {
        patientId: PatientId,
        type: "Ipd",
      };
      getWorkOrderGenInfo(selectObj)
        .then((response) => response.data)
        .then((res) => {
          if (res.result.Discharged === true) {
            warningAlert("Patient already discharged...!");
          } else if (res.result.Discharged === false) {
            setSelectedPatient(res.result);
            focusServicesSearchDropdown();
          }
        });
    }
  }, [clinicalSelectedPatient]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="mt-10 mx-2 space-y-2">
          <div className="text-center">
            <label className="text-xl font-bold">
              Pathology Work Order Generation
            </label>
          </div>
          <div className="flex items-center">
            <div className="w-80 lg:w-96" ref={searchPatientElement}>
              <SearchDropdown
                control={control}
                name="searchPatient"
                placeholder="Search By Patient Name"
                searchIcon={true}
                dataArray={options}
                isSearchable={true}
                isClearable={true}
                handleInputChange={handleChange}
                isDisabled={PatientId}
                inputRef={{
                  ...register("searchPatient", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        let selectObj = {
                          patientId: e.target.value.id,
                          type: "Ipd",
                        };
                        getWorkOrderGenInfo(selectObj)
                          .then((response) => response.data)
                          .then((res) => {
                            if (res.result.Discharged === true) {
                              warningAlert("Patient already discharged...!");
                            } else if (res.result.Discharged === false) {
                              setSelectedPatient(res.result);
                              focusServicesSearchDropdown();
                            }
                          });
                      } else {
                        setSelectedPatient(null);
                        setValue("addSample", false);
                        setServiceArr([]);
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>
          <div className="gap-2 xl:flex">
            <div className="space-y-2 xl:w-[45%]">
              <fieldset className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 px-3 py-1 rounded border bg-[#F1F1F1] border-gray-300 gap-y-2 text-sm whitespace-nowrap">
                <legend className="font-semibold px-2 text-base">
                  Patient Information
                </legend>
                <div className="flex">
                  <label className="w-24 font-semibold">
                    Patient Name &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.patientName}</span>
                </div>
                <div className="flex">
                  <label className="w-12 lg:w-24 xl:w-11 font-semibold">
                    MRNo &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.MRNo}</span>
                </div>
                <div className="flex">
                  <label className="w-24 lg:w-14 xl:w-24 font-semibold">
                    Gender &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.gender}</span>
                </div>
                <div className="flex">
                  <label className="w-12 lg:w-24 xl:w-11 font-semibold">
                    Age &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.AgeYear}</span>
                </div>
                <div className="flex">
                  <label className="w-24 font-semibold">
                    Doctor Name &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.doctorName}</span>
                </div>
              </fieldset>
              <div className="w-full">
                <TextField
                  name="remark"
                  aria-label="minimum height"
                  multiline
                  rows={2}
                  placeholder="Provisional Diagnosis / Remark"
                  fullWidth
                  {...register("remark")}
                  disabled={selectedPatient?.Discharged === true}
                />
              </div>
            </div>
            <div className="xl:w-[55%]">
              <fieldset className="grid lg:grid-cols-2 gap-y-4 p-3 rounded border bg-[#F1F1F1] border-gray-300 w-full whitespace-nowrap">
                <legend className="font-semibold px-2 text-base">
                  Billing Information
                </legend>
                <div className="flex">
                  <label className="w-36 font-semibold">Unit &nbsp;</label>
                  <span>:&nbsp;{patientObj.unit}</span>
                </div>
                <div className="flex">
                  <label className="w-36 xl:w-32 font-semibold">
                    Patient Source &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.patientSource}</span>
                </div>
                <div className="flex">
                  <label className="w-36 font-semibold">
                    Patient Category &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.patientCategory}</span>
                </div>
                <div className="flex">
                  <label className="w-36 xl:w-32 font-semibold">
                    Tariff &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.tariff}</span>
                </div>
                <div className="flex">
                  <label className="w-36 font-semibold">
                    Department &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.department}</span>
                </div>
                <div className="flex">
                  <label className="w-36 xl:w-32 font-semibold">
                    Doctor &nbsp;
                  </label>
                  <span>:&nbsp;{patientObj.doctorName}</span>
                </div>
                <div className="flex col-span-full">
                  <label className="w-36 font-semibold">Company &nbsp;</label>
                  <span>:&nbsp;{patientObj.company}</span>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="flex gap-2">
            <fieldset disabled={selectedPatient === null ? true : false}>
              <CheckboxField
                control={control}
                name="hivConsent"
                label="Is HIV Consent Taken"
              />
            </fieldset>
            <fieldset disabled={selectedPatient === null ? true : false}>
              <CheckboxField control={control} name="urgent" label="Urgent" />
            </fieldset>
            <fieldset disabled={selectedPatient === null ? true : false}>
              <CheckboxField
                control={control}
                name="emergency"
                label="Emergency"
              />
            </fieldset>
          </div>
          <div className="space-y-1">
            <div className="flex justify-between">
              <div className="flex gap-2">
                <fieldset
                  className="w-96"
                  ref={searchDropdownElement}
                  onClick={() => {
                    if (selectedPatient === null) {
                      warningAlert("Please select patient...!");
                    }
                  }}
                >
                  <SearchDropdown
                    onKeyDown={(e) => {
                      if (serviceInput === "" && e.code === "Enter") {
                        e.preventDefault();
                      } else if (e.code === "Tab") {
                        savebtnRef.current.focus();
                      }
                    }}
                    control={control}
                    name="searchService"
                    placeholder="Search Service"
                    dataArray={serviceSearch}
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    handleInputChange={handleService}
                    // isDisabled={selectedPatient === null ? true : false}
                    inputRef={{
                      ...register("searchService", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedService(e.target.value);
                            addbtnRef.current.focus();
                          }
                        },
                      }),
                    }}
                  />
                </fieldset>
                {getService?.IsMicro === true ? (
                  <div>
                    <InputField
                      control={control}
                      name="site"
                      label="Site"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <CommonButton
                  referance={addbtnRef}
                  type="button"
                  label="Add"
                  className="bg-customGreen text-white"
                  onClick={() => {
                    if (getService.id) {
                      addServices();
                      setValue("searchService", null);
                      setValue("site", "");
                      focusServicesSearchDropdown();
                    }
                    if (
                      getService?.Instruction !== "" &&
                      getService?.Instruction !== null
                    ) {
                      setOpenInstructionModal(true);
                    }
                  }}
                />
              </div>
              <div className="flex gap-2 items-center">
                <fieldset
                  className="flex justify-end"
                  disabled={selectedPatient === null ? true : false}
                >
                  <CheckboxField
                    control={control}
                    name="addSample"
                    label="Add With Existing Sample"
                  />
                </fieldset>
              </div>
            </div>
            <div>
              <AddServicesTable
                data={serviceArr}
                setServiceArr={setServiceArr}
              />
            </div>
          </div>
          {userActions.map((action, i) => (
            <>
              {action.action === "Create" && action.isAction === false ? (
                <div className="flex gap-3 justify-end">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      setValue("searchService", null);
                      setValue("searchPatient", null);
                      setValue("remark", "");
                      setServiceArr([]);
                      setSelectedPatient(null);
                      setSelectedService(null);
                      setValue("hivConsent", false);
                      setValue("urgent", false);
                      setValue("emergency", false);
                    }}
                  />
                  <CommonButton
                    referance={savebtnRef}
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                    onClick={() => {
                      setPrivilege(action.action);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={postData}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this Record ?"
          confirmationButtonMsg="Add"
        />
        <Modal open={openInstructionModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 20,
              minWidth: "50%",
              maxWidth: "80%",
              minHeight: "30%",
              maxHeight: "80%",
              p: 3,
              overflowY: "scroll",
              borderRadius: "4px",
            }}
          >
            <CancelPresentationIconButton
              onClick={() => {
                setOpenInstructionModal(false);
                setSelectedService(null);
              }}
            />
            <div className="space-y-2">
              <div className="text-2xl font-semibold">Instruction</div>
              <hr className="border-t-2 border-customBlue" />
              <div className="text-lg">{selectedService?.Instruction}</div>
            </div>
          </Box>
        </Modal>
      </form>
    </>
  );
};

export default PathWorkOrderGeneration;
