import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { baseUrl } from "../../../../http-common";

export default function DocumentsViewModal(props) {
  const { open, setOpen, handleOpen, handleClose, pathForDocView } = props;
  const [pos, setPos] = useState({ x: 0, y: 0, scale: 1 });
  const [active, setActive] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: !active ? "50%" : "100%",
    height: !active ? "60%" : "100%",
    minHeight: "60%",
    overflowY: "scroll",
    overflowX: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    py: 4,
    px: 2,
  };

  const HandleZoomInOut = (e) => {
    const delta = e.deltaY * -0.01;
    const newScale = pos.scale + delta < 1 ? 1 : pos.scale + delta;

    const ratio = 1 - newScale / pos.scale;

    setPos({
      scale: newScale,
      x: pos.x * ratio,
      y: pos.y * ratio,
    });
  };
  const handleToggleSize = () => {
    setActive(!active);
  };



  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <div className="fixed right-0 -top-1 w-full z-10 ">
            <CancelPresentationIconButton
              onClick={() => {
                props.setOpen(false);
              }}
            />
          </div>
          <div className={active ? "h-screen" : "h-[505px] "}>
            <div
              onWheelCapture={HandleZoomInOut}
              onClick={() => {
                handleToggleSize();
              }}
            >
              
              <img
                style={{
                  height: active ? "fit-content" : "505px",
                  transformOrigin: "0 0",
                  transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`,
                }}
                src={`${baseUrl}/getAdmissionDocumentByPath/${pathForDocView}`}

              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
