// App.js
import React, { useState } from "react";
import CommonButton from "../../common/components/Buttons/CommonButton";
import LoadingSpinner from "../../common/components/loadingspinner/loadingSpinner";
import { getUserMenus } from "../../UserManagement/Services/privilegetofunctionalityServices/PrivilegeToFunctionalityServices";
import AddNewPrivilegesModal from "./AddNewPrivilegesModal";
import AddSubMenuModal from "./AddSubMenuModal";
import PrivilegeMapping from "./PrivilegeMapping";

function Privilege() {
  const [open, setOpen] = useState(false);
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const [edit, setEdit] = useState(false);
  const [privileges, setPrivileges] = React.useState([]);
  const [spinner, showSpinner] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenMainMenu = () => setOpenMainMenu(true);
  const handleCloseMainMenu = () => setOpenMainMenu(false);

  React.useEffect(() => {
    fetchAllUserMenu();
  }, []);

  function fetchAllUserMenu() {
    showSpinner(true);
    let menuId = 0;
    getUserMenus(menuId)
      .then((response) => response.data)
      .then((res) => {
        if (res?.result?.length > 0 && res?.statusCode === 200) {
          showSpinner(false);
          setPrivileges(res?.result);
        }
      });
  }
  return (
    <div className="w-full grid bg-white md:rounded-md px-4 pb-4">
      <div className="text-center text-[18px] font-semibold whitespace-nowrap pt-20">
        Add Functionality
      </div>

      <div className="flex gap-2 justify-end">
        {/* <div className="flex w-full lg:grid grid-cols-2 gap-2 items-center ">
          <div className="grid w-full grid-cols-1">
            <SearchBar
              name="SearchableSelect"
              placeholder="Search By Functionality"
                dataArray={options}
              isSearchable={true}
              handleInputChange={handleChange}
              onChange={autoSelectedValue}
            />
          </div>
          <div className="flex justify-between">
            <div className="flex items-center">
              <SearchIconButton
                onClick={() => {
                  //   setTrialSearch(searchString);
                  // populateTable();
                }}
              />
            </div>
          </div>
        </div> */}

        <div className="flex gap-2">
          <div>
            <CommonButton
              type="submit"
              onClick={() => {
                setOpen(true);
              }}
              label="New Privilege"
              className="modalButton bg-customBlue text-white"
            />
          </div>
          <div>
            <CommonButton
              type="submit"
              onClick={() => {
                setOpenMainMenu(true);
                setEdit(false);
              }}
              label=" New  Menu"
              className="modalButton bg-customBlue text-white"
            />
          </div>
        </div>

        <div></div>
      </div>
      {privileges?.length > 0 && (
        <div className="my-2 px-6">
          <PrivilegeMapping
            data={privileges}
            setData={setPrivileges}
            fetchAllUserMenu={fetchAllUserMenu}
          />
        </div>
      )}
      {spinner ? (
        <div className="grid justify-center items-center pt-12">
          <LoadingSpinner />
        </div>
      ) : null}
      {/* <div className="mt-4">
        <PrivilegeModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
        />
      </div> */}
      <AddSubMenuModal
        nodeData={{ level: 0 }}
        open={openMainMenu}
        from="mainMenu"
        handleClose={handleCloseMainMenu}
        edit={edit}
        setEdit={setEdit}
        fetchAllUserMenu={fetchAllUserMenu}
      />
      <AddNewPrivilegesModal
        open={open}
        handleClose={handleClose}
        fetchAllUserMenu={fetchAllUserMenu}
      />
    </div>
  );
}

export default Privilege;
