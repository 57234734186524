import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const getGrnReturnList = async (searchObj) => {
  return await apiClient.post(`/grn/grnReturnList`, searchObj, {
    headers: authHeader(),
  });
};

export const getGrnReturnListItems = async (searchObj) => {
  return await apiClient.post(`/grn/grnReturnItemList`, searchObj, {
    headers: authHeader(),
  });
};

export const postGRNReturnItems = async (obj) => {
  return await apiClient.post(`/grn/saveGrnReturn`, obj, {
    headers: authHeader(),
  });
};
