import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  React.useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function BatchSelectionTable(props) {
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  let enterPressed = useKeyPress("Enter");

  const [rowIndex, setRowIndex] = React.useState("");
  //key press up down

  React.useEffect(() => {
    let rIndex = rowIndex;

    if (arrowUpPressed) {
      if (rowIndex !== 0) {
        rIndex = rIndex - 1;
        setRowIndex(rIndex);
      }
    }
  }, [arrowUpPressed]);

  React.useEffect(() => {
    setRowIndex(0);
  }, []);

  React.useEffect(() => {
    let rIndex = rowIndex;

    if (arrowDownPressed) {
      if (rowIndex !== props.data.length - 1) {
        rIndex = rIndex + 1;

        setRowIndex(rIndex);
      }
    }
  }, [arrowDownPressed]);

  React.useEffect(() => {
    if (props.data[rowIndex] !== undefined) {
      if (enterPressed) {
        props.setSelectedRow(props.data[rowIndex]);
        props.handleClose();
        enterPressed = false;
      }
    }
  }, [enterPressed]);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                  borderRadius: "10px",
                },
              }}
              className="rounded h-56 2xl:h-72 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch No.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Balance Qty.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Expiry Date
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        MRP
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Purchase Rate
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Is Consignment
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Conversion
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        GST(%)
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Landed Rate
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((drugBatchItem, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        onClick={(event) => {
                          setRowIndex(index);
                        }}
                        role="checkbox"
                        style={{
                          backgroundColor: rowIndex === index ? "#FFC44B" : "",
                        }}
                      >
                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.BatchCode}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.BalQty}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.BatchExpDate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.UnitMRP}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.UnitPurchaseRate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.isConsignment ? "1" : "0"}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.Conversion}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.VATPer}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem.UnitLandedRate}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
