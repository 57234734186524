import { Checkbox, TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useContext, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../../common/components/FormFields/InputField";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";

const checklistArr = [
  {
    id: 1,
    label: "OAE",
    done: false,
    notDone: false,
  },
  {
    id: 2,
    label: "Vaccination",
    done: false,
    notDone: false,
  },
  {
    id: 3,
    label: "Neew Born Screening",
    done: false,
    notDone: false,
  },
  {
    id: 4,
    label: "TCB",
    done: false,
    notDone: false,
  },
  {
    id: 5,
    label: "Four Limb Saturation",
    done: false,
    notDone: false,
  },
  {
    id: 6,
    label: "Four Limb BP",
    done: false,
    notDone: false,
  },
  {
    id: 7,
    label: "Red Reflex",
    done: false,
    notDone: false,
  },
];

export default function NeonatalModal(props) {
  const { patchData, neonatalChecklist, setNeonatalChecklist } = useContext(
    DischargeSummaryContext
  );

  console.log("patchData", patchData);

  const methods = useFormContext();
  const { control } = methods;

  const handleCheckboxChange = (index, trueType, falseType) => (e) => {
    setNeonatalChecklist((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [trueType]: e.target.checked,
              [falseType]: !item[trueType]
                ? !e.target.checked
                : item[falseType],
            }
          : item
      )
    );
  };

  return (
    <div>
      <form>
        <div>
          <div className="grid ">
            <div className="grid grid-cols-3 gap-3">
              <div className=" ">
                <Controller
                  control={control}
                  name="timeofbirth"
                  render={({ field: { value, onChange } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopTimePicker
                        label="Birth Time"
                        value={value}
                        onChange={onChange}
                        disabled={false}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            // error={errors.startTime}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                position: "absolute",
                                top: "-2px",
                              },
                            }}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "  ",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <div>
                <CheckBoxField
                  control={control}
                  name="isbreastfeeding"
                  label="Is Breast Feeding "
                />
              </div>
            </div>
            <div className="text-black font-bold text-base py-3">
              Maternal History
            </div>
            <div className="grid grid-cols-6 gap-3">
              <div className="">
                <InputField
                  name="age"
                  variant="outlined"
                  label="Age  "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div>
                <InputField
                  name="bloodGroup"
                  variant="outlined"
                  label=" Blood Group     "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="gravityparity"
                  variant="outlined"
                  label="Gravity parity "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="gastationalIllness"
                  variant="outlined"
                  label="Gastational Illness  "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div>
                <InputField
                  name="FatherBloodGroup"
                  variant="outlined"
                  label="Father Blood Group     "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="obstraticIllness"
                  variant="outlined"
                  label="Obstetric Illness     "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="col-span-6">
                <InputField
                  name="MedicalIllness"
                  variant="outlined"
                  label="Medical Illness   "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="text-black font-bold text-base py-3">
              Birth History
            </div>
            <div className="grid grid-cols-3 gap-3">
              <div className="grid grid-cols-5">
                <div className="col-span-1">APGR :</div>
                <div className="col-span-4">
                  <InputField
                    name="At1Min"
                    variant="outlined"
                    label="At 1 Min   "
                    // error={errors.representativeAddress}
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>

              <div className="">
                <InputField
                  name="At5Min"
                  variant="outlined"
                  label="At 5 Min   "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="resuscitationDetails"
                  variant="outlined"
                  label="Resuscitation Details     "
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3">
              <div>
                <div className="text-black font-bold text-base py-3">
                  Growth Data
                </div>
                <div className="  ">
                  <table className="min-w-full border rounded-md">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider"></th>
                        <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">
                          On Admission
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">
                          On Discharge
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                          Weight
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <InputField
                            name="weightOnAdmission"
                            variant="outlined"
                            label="Weight on Admission"
                            control={control}
                            disabled={false}
                            inputProps={{ maxLength: 50 }}
                            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <InputField
                            name="weightOnDischarge"
                            variant="outlined"
                            label="Weight on Discharge"
                            control={control}
                            disabled={false}
                            inputProps={{ maxLength: 50 }}
                            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                          Height
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <InputField
                            name="lengthOnAdmission"
                            variant="outlined"
                            label="Height on Admission"
                            control={control}
                            disabled={false}
                            inputProps={{ maxLength: 50 }}
                            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <InputField
                            name="lengthOnDischarge"
                            variant="outlined"
                            label="Height on Discharge"
                            control={control}
                            disabled={false}
                            inputProps={{ maxLength: 50 }}
                            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                          Head Circumference
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <InputField
                            name="headCircumferenceAdmission"
                            variant="outlined"
                            label="Head Circumference on Admission"
                            control={control}
                            disabled={false}
                            inputProps={{ maxLength: 50 }}
                            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <InputField
                            name="headCircumferenceOnDischarge"
                            variant="outlined"
                            label="Head Circumference on Discharge"
                            control={control}
                            disabled={false}
                            inputProps={{ maxLength: 50 }}
                            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className="text-black font-bold text-base py-3">
                  4 Limb Saturation
                </div>
                <div className="grid  gap-3 mt-2">
                  <div className="">
                    <InputField
                      name="rightUpperLimb"
                      variant="outlined"
                      label="Right Upper Limb      "
                      // error={errors.representativeAddress}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div className="">
                    <InputField
                      name="rightLowerLimb"
                      variant="outlined"
                      label="Right Lower Limb     "
                      // error={errors.representativeAddress}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div className="">
                    <InputField
                      name="leftUpperLimb"
                      variant="outlined"
                      label="Left Upper Limb  "
                      // error={errors.representativeAddress}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div className="">
                    <InputField
                      name="leftLowerLimb"
                      variant="outlined"
                      label="Left Lower Limb  "
                      // error={errors.representativeAddress}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* new part added */}

            <div>
              <div className="text-black font-bold text-base py-3">
                Checklist
              </div>
              <div className="grid lg:grid-cols-3 gap-2">
                {neonatalChecklist?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center  px-4 border rounded"
                  >
                    <div className="flex-1 font-semibold text-sm">
                      {item.label}
                    </div>
                    <div className="flex items-center space-x-1">
                      <Checkbox
                        id={`done-${index}`}
                        checked={item.done}
                        onChange={handleCheckboxChange(
                          index,
                          "done",
                          "notDone"
                        )}
                      />
                      <label htmlFor={`done-${index}`} className="text-sm">
                        Done
                      </label>
                    </div>
                    <div className="ml-10 flex items-center space-x-1">
                      <Checkbox
                        id={`not-done-${index}`}
                        checked={item.notDone}
                        onChange={handleCheckboxChange(
                          index,
                          "notDone",
                          "done"
                        )}
                      />
                      <label htmlFor={`not-done-${index}`} className="text-sm">
                        Not Done
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

//
