import React from "react";
import { useForm } from "react-hook-form";
import { Box, Checkbox } from "@mui/material";
import { Modal } from "@mui/material";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import { getQuotationItemDetailsList } from "../../../../services/purchase/EnquiryServices";
import InputField from "../../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../../common/components/FormFields/searchDropdown";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { warningAlert } from "../../../../../common/components/Toasts/CustomToasts";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";

const EnquiryModal = (props) => {
  const defaultValues = {
    searchBySupNameEnquiry: "",
    supplier: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    control,
    reset,
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [checkBoxIndex, setCheckBoxIndex] = React.useState(-1);
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");

  if (fromDate) {
    props.setSelectedEnquiryListFromDate(fromDate);
  }
  if (toDate) {
    props.setSelectedEnquiryListToDate(toDate);
  }

  const filterData = () => {
    props.populateTable();
  };

  const QuotationItemDetail = () => {
    getQuotationItemDetailsList(props.enquiryItemRow?.QId)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        let arr = [];
        for (let obj of res.result) {
          let RequiredObj = {
            ItemId: obj.ItemId,
            ItemName: obj.ItemName,
            PurchaseUOM: obj.PurchaseUOM,
            Qty: obj.Qty,
            Rate: obj.Rate,
            Remarks: obj.Remarks,
            amount: 0,
            gstPercentage: 0,
            gstAmount: 0,
            concessionPercentage: 0,
            concessionAmount: 0,
            netAmount: 0,
          };
          arr.push(RequiredObj);
          props.setQuotationData(arr);
        }
      })
      .catch((error) => {
      });
  };

  //Single Select
  const handleChange = (e, row, requiredIndex) => {
    setCheckBoxIndex(requiredIndex);
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <>
          <Checkbox
            size="small"
            className="w-4 h-4 border border-gray-600 rounded"
            type="checkbox"
            color="primary"
            checked={checkBoxIndex === index ? true : false}
            value={index}
            onChange={(e) => {
              handleChange(e, row, index);
              props.setEnquiryItemRow(row);
            }}
            inputProps={{
              "aria-label": "primary checkbox",
            }}
            name={`indentCheckBox${index}`}
          />
        </>
      </div>
    );
  };

  return (
    <>
      <div>
        <Modal
          open={props.openEnquiryModal}
          onClose={() => {
            props.handleEnquiryModalClose();
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "1px solid gray",
              boxShadow: 20,
              p: 4,
            }}
          >
            <div className=" w-full ">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleEnquiryModalClose();
                  props.setDataResultEnquiryList([]);
                  props.setSelectedEnquiryListFromDate(new Date());
                  props.setSelectedEnquiryListToDate(new Date());
                }}
              />
            </div>
            <form>
              <div className="my-2">
                <span className="font-semibold text-lg">Enquiry Listing</span>
                <div className="grid grid-cols-4 pt-2 gap-3">
                  <InputField
                    name="searchBySupNameEnquiry"
                    variant="outlined"
                    label="Search By Supplier Name/Enquiry No"
                    error={errors.searchBySupNameEnquiry}
                    control={control}
                    inputRef={{
                      ...register("searchBySupNameEnquiry", {
                        onChange: (e) => {
                          props.setEnquirySearchString(e.target.value);
                        },
                      }),
                    }}
                  />
                  <SearchDropdown
                    control={control}
                    name="supplier"
                    placeholder="Supplier"
                    error={errors.supplier}
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    dataArray={props.supplierOptions}
                    isMulti={false}
                    handleInputChange={props.handleSupplierChange}
                    inputRef={{
                      ...register("supplier", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            props.setEnquirySupplierId(e.target.value.id);
                          } else if (e.target.value === null) {
                            props.setEnquirySupplierId(0);
                          }
                        },
                      }),
                    }}
                  />
                  <div className=" flex col-span-2 gap-3">
                    <div className="flex gap-3">

                      <DatePickerFieldNew
                        control={control}
                        name="fromDate"
                        label="From Date"
                        value={new Date()}
                        onChange={(newValue) => {
                          setValue("fromDate", newValue);
                        }}
                        disablePast={false}
                        disableFuture={true}
                        inputFormat="dd-MM-yyyy"
                      />
                      <DatePickerFieldNew
                        control={control}
                        name="toDate"
                        label="To Date"
                        value={new Date()}
                        onChange={(newValue) => {
                          setValue("toDate", newValue);
                        }}
                        disablePast={false}
                        disableFuture={true}
                        inputFormat="dd-MM-yyyy"
                      />
                    </div>
                    <CommonButton
                      type="button"
                      searchIcon={true}
                      onClick={filterData}
                      className="bg-customBlue text-white"
                    />
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                        props.setSelectedEnquiryListFromDate(new Date());
                        props.setSelectedEnquiryListToDate(new Date());
                      }}
                    />
                  </div>
                </div>

                <>
                  {props.dataResultEnquiryList.length > 0 ? (
                    <>
                      <CommonDynamicTablePaginationNew
                        dataResult={props.dataResultEnquiryList}
                        populateTable={props.populateTable}
                        page={props.page}
                        setPage={props.setPage}
                        rowsPerPage={props.rowsPerPage}
                        count={props.count}
                        highlightedRow={false}
                        renderActions={renderActions}
                      />
                    </>
                  ) : (
                    <h3 className="flex justify-center my-14 font-bold text-gray-600">
                      No Records Found...
                    </h3>
                  )}
                </>
                {props.userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <>
                        <div className="grid justify-end">
                          <button
                            type="button"
                            className="h-9 px-3 bg-green-600 text-white rounded text-base font-medium"
                            onClick={() => {
                              if (checkBoxIndex === -1) {
                                warningAlert("Please Select Enquiry...!");
                              } else if (checkBoxIndex !== -1) {
                                QuotationItemDetail();
                                props.handleEnquiryModalClose();
                                props.setDataResultEnquiryList([]);
                                props.setSelectedEnquiryListFromDate(
                                  new Date()
                                );
                                props.setSelectedEnquiryListToDate(new Date());
                              }
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EnquiryModal;
