import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchDepartment,
  fetchDoctor,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchConsultantList,
  fetchDepartmentExtension,
  fetchEmployeeList,
  fetchEmployeeSearch,
  fetchVendorSearch,
  fetchVendorsName,
} from "../../services/contactManagementServices/ContactManagementServices";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const dataResultEmergencyCodes = [
  {
    id: 1,
    "sr.": 1,
    CODES: "Code Blue",
    "Nature Of Emergency": "Medical Emergency / Cardiac Arrest",
    DIAL: "300",
    "SPEAK/INFORM": "LOCATION/FLOOR/BED/ NUMBER/ADULT/PEDIATRIC",
  },
  {
    id: 2,
    "sr.": 2,
    CODES: "Code Red",
    "Nature Of Emergency": "FirelGas Leakage/Water Intrusions",
    DIAL: "777",
    "SPEAK/INFORM": "LOCATION & THE NAME OF THE INFORMER",
  },
  {
    id: 3,
    "sr.": 3,
    CODES: "Code Violet",
    "Nature Of Emergency": "Security threates likely between people",
    DIAL: "788",
    "SPEAK/INFORM": "LOCATION & THE NAME OF THE INFORMER",
  },
  {
    id: 4,
    "sr.": 4,
    CODES: "Code Brown",
    "Nature Of Emergency":
      "DAMA PROTOCOL NOT FOLLOWED, PATIENT LEAVING THE HOSPITAL PREMISES/PATIENT ABSCONDING",
    DIAL: "799",
    "SPEAK/INFORM": "LOCATION/BED NUMBER & THE NAME OF THE INFORMER",
  },
  {
    id: 5,
    "sr.": 5,
    CODES: "Code Pink",
    "Nature Of Emergency": "CHILD ABDUCTION/BABY MISSING",
    DIAL: "766",
    "SPEAK/INFORM":
      "LOCATION FLOOR/WARD/ BED/NUMBER & THE NAME OF THE INFORMER",
  },
  {
    id: 6,
    "sr.": 6,
    CODES: "Code Black",
    "Nature Of Emergency": "BOMB HOAX/ATTACK",
    DIAL: "755",
    "SPEAK/INFORM": "LOCATION & THE NAME OF THE INFORMER",
  },
];

const ContactManagement = () => {
  const [valueTab, setTabValue] = useState(0);

  //consultant
  const [consultantList, setConsultantList] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [selectedConsultantRow, setSelectedConsultantRow] = useState(null);

  const [page1, setPage1] = useState(0);
  const [rowsPerPage1] = useState(25);
  const [count1, setCount1] = useState(null);
  //dept Ext

  const [departmentArr, setDepartmentArr] = useState([]);
  const [departmentExtentions, setDepartmentExtentions] = useState([]);
  const [selectedDept, setSelectedDept] = useState(null);
  const [selctedExt, setSelctedExt] = useState(null);

  //emp

  const [empDtlsList, setEmpDtlsList] = useState([]);
  const [empSearch, setEmpSearch] = useState([]);
  const [selectedEmpName, setSelectedEmpName] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState([]);

  const [page2, setPage2] = useState(0);
  const [rowsPerPage2] = useState(25);
  const [count2, setCount2] = useState(null);

  //other
  const [vendorsNameSearch, setVendorsNameSearch] = useState([]);
  const [vendorsNameArr, setVendorsNameArr] = useState([]);
  const [selectedVendorsRow, setSelectedVendorsRow] = useState(null);

  //
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
    resetField,
  } = methods;

  const getConsultantsSearch = (searchString) => {
    searchString &&
      fetchDoctor(searchString)
        .then((response) => response.data)
        .then((res) => {
          setConsultants(res.result);
        });
  };

  const handleDepartmentData = (searchString) => {
    //
    searchString &&
      fetchDepartment(searchString)
        .then((response) => response.data)
        .then((res) => {
          setDepartmentArr(res.result);
        });
  };

  useEffect(() => {
    getDepartmentExtension();
  }, [selectedDept]);

  const getDepartmentExtension = () => {
    //

    fetchDepartmentExtension()
      .then((response) => response.data)
      .then((res) => {
        setDepartmentExtentions(res.result);
      });
  };

  const getEmployeeSearch = (searchString) => {
    searchString &&
      fetchEmployeeSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setEmpSearch(res.result);
        });
  };

  const getVendorSearch = (searchString) => {
    searchString &&
      fetchVendorSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setVendorsNameSearch(res.result);
        });
  };

  useEffect(() => {
    getVendorsName();
  }, []);

  const getVendorsName = (vendorId) => {
    fetchVendorsName(vendorId || 0)
      .then((response) => response.data)
      .then((res) => {
        setVendorsNameArr(res.result);
      });
  };

  useEffect(() => {
    populateTable1();
  }, [selectedConsultant]);

  const populateTable1 = (forPagination) => {
    let doctorId = selectedConsultant.id || 0;
    let page = !forPagination ? 0 : page1;
    let size = rowsPerPage1;

    fetchConsultantList(doctorId, page, size)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setConsultantList((prevData) => [...prevData, ...res.result]);
        } else {
          setConsultantList(res.result);
          setPage1(0);
        }
        setCount1(res.count);
      });
  };

  useEffect(() => {
    populateTable2();
  }, [selectedEmpName]);

  const populateTable2 = (forPagination) => {
    let empId = (selectedEmpName && selectedEmpName.id) || 0;
    let page = !forPagination ? 0 : page2;
    let size = rowsPerPage2;

    fetchEmployeeList(empId, page, size)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setEmpDtlsList((prevData) => [...prevData, ...res.result]);
        } else {
          setEmpDtlsList(res.result);
          setPage2(0);
        }
        setCount2(res.count);
      });
  };

  const renderField = (label, value, colSpan) => (
    <div
      className={`flex items-center gap-2 w-full ${colSpan} font-semibold text-sm`}
    >
      <span className="font-semibold w-28 text-gray-800">{label}</span>
      <span className="text-gray-700 font-normal">:</span>
      <span className="text-gray-700 font-normal">{value}</span>
    </div>
  );

  const renderInputEmgCodes = (row, index, header) => {
    return (
      <>
        {header === "Nature Of Emergency" && (
          <div className="w-60 break-words whitespace-normal">
            {row["Nature Of Emergency"].toUpperCase()}
          </div>
        )}
        {header === "SPEAK/INFORM" && (
          <div className="w-60 break-words whitespace-normal">
            {row["SPEAK/INFORM"].toUpperCase()}
          </div>
        )}
        {header === "CODES" && (
          <div className="w-60 break-words whitespace-normal">
            {row["SPEAK/INFORM"].toUpperCase()}
          </div>
        )}
      </>
    );
  };

  const rowBackgroundColor = (row, index) => {
    return row["CODES"] === "Code Blue"
      ? "#93c5fd"
      : row["CODES"] === "Code Red"
      ? "#fb7185"
      : row["CODES"] === "Code Violet"
      ? "#a78bfa"
      : row["CODES"] === "Code Violet"
      ? "#2e1065"
      : row["CODES"] === "Code Brown"
      ? "#be123c"
      : row["CODES"] === "Code Pink"
      ? "#fbcfe8"
      : row["CODES"] === "Code Black"
      ? "#a3a3a3"
      : "";
  };

  const handleSelectedRowExtentions = (row, index) => {
    setSelctedExt(row);
  };
  const handleSelectedRowEmp = (row, index) => {
    setSelectedEmp(row);
  };
  const handleSelectedRowConsultant = (row, index) => {
    setSelectedConsultantRow(row);
  };
  const handleSelectedRowVendors = (row, index) => {
    setSelectedVendorsRow(row);
  };

  return (
    <div className="mt-12 mb-2">
      <div className="text-center text-black font-bold text-xl ">
        Contact Management
      </div>

      <div>
        <div>
          <div className="bg-gray-200 rounded-md">
            <Tabs
              value={valueTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Consultant" id="tab-0" />
              <Tab label="Department Extation" id="tab-1" />
              <Tab label="Employee" id="tab-2" />
              <Tab label="Other" id="tab-3" />
            </Tabs>
          </div>

          <TabPanel value={valueTab} index={0}>
            <div className="grid grid-cols-6">
              <div className="col-span-6 grid grid-cols-3 gap-3">
                <div>
                  <div className="md:col-span-2 lg:col-span-2">
                    <SearchDropdown
                      control={control}
                      placeholder="Search Consultants"
                      dataArray={consultants}
                      name="consultantsName"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={(e) => getConsultantsSearch(e)}
                      // isMulti={true}
                      inputRef={{
                        ...register("consultantsName", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              setSelectedConsultant(e.target.value);
                            } else {
                              setSelectedConsultant("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  {consultantList.length > 0 ? (
                    <div>
                      <CommonDynamicTablePaginationNew
                        dataResult={consultantList}
                        populateTable={populateTable1}
                        page={page1}
                        setPage={setPage1}
                        rowsPerPage={rowsPerPage1}
                        count={count1}
                        handleSelectedRow={handleSelectedRowConsultant}
                        tableClass="h-96 2xl:h-[500px] lg:h-96"
                        removeHeaders={[
                          "Id",
                          "Address",
                          "EmailId",
                          "LandLineNo",
                          "MahRegNo",
                          "MobileNo",
                          "RegNo",
                          "SpeedDial",
                          "label",
                          "Ext4",
                        ]}
                      />
                    </div>
                  ) : (
                    <p className="text-center my-6 ">No Data Found</p>
                  )}
                </div>

                <div className="col-span-2">
                  <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2">
                    <legend className="text-md font-semibold text-gray-900 px-2 ">
                      Details
                    </legend>
                    <div className=" grid grid-cols-2 2xl:grid-cols-4 gap-2 px-2">
                      {renderField(
                        "Contact Name",
                        selectedConsultantRow && selectedConsultantRow.Name,
                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "Reg. No.",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow && selectedConsultantRow.RegNo,

                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Mah. No.",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow && selectedConsultantRow.MahRegNo,

                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Speed Dial",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow &&
                          selectedConsultantRow.SpeedDial,

                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "Mob No.",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow && selectedConsultantRow.MobileNo,

                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "Email",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow && selectedConsultantRow.EmailId,

                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "Address",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow && selectedConsultantRow.Address,

                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "Landline No.",
                        // selectedRow && selectedRow.MRNo,
                        selectedConsultantRow &&
                          selectedConsultantRow.LandLineNo,

                        "col-span-2 xl:col-span-2"
                      )}
                    </div>
                  </fieldset>

                  <div>
                    <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:p-2 md:px-5 rounded bg-fuchsia-100">
                      <legend className="text-md font-semibold text-customRed px-2 ">
                        In Emergency Kindly Contact
                      </legend>
                      <div className=" grid grid-cols-2 2xl:grid-cols-4 gap-2 px-2">
                        {renderField(
                          "Administrator",

                          "10102",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "Billing",
                          "12033/11303",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "Reception",
                          "10008/9",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "Pathology",
                          "10111/11215/11214",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "X-Ray",
                          "11309",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "Sonography",
                          "11308/11311",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "EDP",
                          "1519/10108",
                          "col-span-2 xl:col-span-1"
                        )}
                        {renderField(
                          "Nursing Office",
                          "10104",
                          "col-span-2 xl:col-span-1"
                        )}
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={1}>
            <div className="grid grid-cols-6">
              <div className="col-span-6 grid grid-cols-3 gap-3">
                <div>
                  <div className="md:col-span-2 lg:col-span-2">
                    <SearchDropdown
                      control={control}
                      placeholder="Search Department"
                      dataArray={departmentArr}
                      name="searchDept"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={(e) => handleDepartmentData(e)}
                      // isMulti={true}
                      inputRef={{
                        ...register("searchDept", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              setSelectedDept(e.target.value);
                            } else {
                              setSelectedDept("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>

                  {departmentExtentions.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={departmentExtentions}
                      handleSelectedRow={handleSelectedRowExtentions}
                      tableClass="h-96 2xl:h-[500px] lg:h-96"
                      removeHeaders={["id", "Ext1", "Ext2", "Ext3", "Ext4"]}
                    />
                  ) : (
                    <p className="text-center my-6 ">No Data Found</p>
                  )}
                </div>
                <div className="col-span-2">
                  <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2">
                    <legend className="text-md font-semibold text-gray-900 px-2 ">
                      Extentions
                    </legend>
                    <div className=" grid grid-cols-2 2xl:grid-cols-4 gap-2 px-2">
                      {renderField(
                        "Extension-1",
                        selctedExt?.Ext1,
                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Extension-2",
                        // selectedRow && selectedRow.MRNo,
                        selctedExt?.Ext2,
                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Extension-3",
                        selctedExt?.Ext3,
                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Extension-4",
                        // selectedRow && selectedRow.MRNo,
                        selctedExt?.Ext4,
                        "col-span-2 xl:col-span-1"
                      )}
                    </div>
                  </fieldset>

                  <div>
                    {dataResultEmergencyCodes.length > 0 ? (
                      <CommonDynamicTableNew
                        dataResult={dataResultEmergencyCodes}
                        removeHeaders={["id", "sr."]}
                        renderInput={renderInputEmgCodes}
                        rowBackgroundColor={rowBackgroundColor}
                        editableColumns={[
                          "Nature Of Emergency",
                          "SPEAK/INFORM",
                        ]}
                        tableClass="h-96 2xl:h-[500px] lg:h-96"
                      />
                    ) : (
                      <p className="text-center my-6 ">No Data Found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={2}>
            <div className="grid grid-cols-6">
              <div className="col-span-6 grid grid-cols-3 gap-3">
                <div>
                  <div className="md:col-span-2 lg:col-span-2">
                    <SearchDropdown
                      control={control}
                      placeholder="Search Employee"
                      dataArray={empSearch}
                      name="empName"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={(e) => getEmployeeSearch(e)}
                      // isMulti={true}
                      inputRef={{
                        ...register("empName", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              setSelectedEmpName(e.target.value);
                            } else {
                              setSelectedEmpName("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  {empDtlsList.length > 0 ? (
                    <div>
                      <CommonDynamicTablePaginationNew
                        dataResult={empDtlsList}
                        populateTable={populateTable2}
                        handleSelectedRow={handleSelectedRowEmp}
                        page={page1}
                        setPage={setPage1}
                        rowsPerPage={rowsPerPage1}
                        count={count1}
                        removeHeaders={[
                          "CUG",
                          "Designation",
                          "MobileNo",
                          "SpeedDial",
                          "id",
                        ]}
                        tableClass="h-96 2xl:h-[500px] lg:h-96"
                      />
                    </div>
                  ) : (
                    <p className="text-center my-6 ">No Data Found</p>
                  )}
                </div>

                <div className="col-span-2">
                  <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2">
                    <legend className="text-md font-semibold text-gray-900 px-2 ">
                      Details
                    </legend>
                    <div className=" grid grid-cols-2 2xl:grid-cols-4 gap-2 px-2">
                      {renderField(
                        "Mobile No.",
                        selectedEmp && selectedEmp.MobileNo,
                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "CUG No.",
                        // selectedRow && selectedRow.MRNo,
                        selectedEmp && selectedEmp.CUG,
                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Speed Dial",
                        // selectedRow && selectedRow.MRNo,
                        selectedEmp && selectedEmp.SpeedDial,
                        "col-span-2 xl:col-span-1"
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={3}>
            <div className="grid grid-cols-6">
              <div className="col-span-6 grid grid-cols-3 gap-3">
                <div>
                  <div className="md:col-span-2 lg:col-span-2">
                    <SearchDropdown
                      control={control}
                      placeholder="Vendors Name"
                      dataArray={vendorsNameSearch}
                      name="vendorsName"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={(e) => getVendorSearch(e)}
                      // isMulti={true}
                      inputRef={{
                        ...register("vendorsName", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              //setSelectedPatient(e.target.value);
                            } else {
                              //setSelectedPatient("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  {vendorsNameArr.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={vendorsNameArr}
                      handleSelectedRow={handleSelectedRowVendors}
                      removeHeaders={["id", "Contact1", "Contact2", "Contact2"]}
                      tableClass="h-96 2xl:h-[550px] lg:h-96"
                    />
                  ) : (
                    <p className="text-center my-6 ">No Data Found</p>
                  )}
                </div>

                <div className="col-span-2">
                  <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2">
                    <legend className="text-md font-semibold text-gray-900 px-2 ">
                      Details
                    </legend>
                    <div className=" grid grid-cols-2 2xl:grid-cols-4 gap-2 px-2">
                      {renderField(
                        "Vendor Name",
                        selectedVendorsRow && selectedVendorsRow.VendorName,
                        "col-span-2 xl:col-span-2"
                      )}
                      {renderField(
                        "Contact1",
                        // selectedRow && selectedRow.MRNo,
                        selectedVendorsRow && selectedVendorsRow.Contact1,
                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Contact2",
                        // selectedRow && selectedRow.MRNo,
                        selectedVendorsRow && selectedVendorsRow.Contact2,
                        "col-span-2 xl:col-span-1"
                      )}
                      {renderField(
                        "Contact3",
                        // selectedRow && selectedRow.MRNo,
                        selectedVendorsRow && selectedVendorsRow.Contact3,
                        "col-span-2 xl:col-span-1"
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default ContactManagement;

