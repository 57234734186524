import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  getLastPurchaseDetails,
  postGrnItems,
} from "../../../services/commonServices/grnService/GrnServices";
import { getSupplierList } from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import ItemDetailsTable from "./ItemDetailsTable";
import StoreGRNModal from "./StoreGRNModal";

const grnType = [
  {
    id: "dailyOrders",
    value: "dailyOrders",
    label: "Daily Orders",
  },
  {
    id: "dc",
    value: "dc",
    label: "D.C.",
  },
];

const paymentType = [
  {
    id: "credit",
    value: "credit",
    label: "Credit",
  },
  {
    id: "cash",
    value: "cash",
    label: "Cash",
  },
];

const discTaxObj = [
  {
    id: "Before Disc & Tax",
    value: "Before Disc & Tax",
    label: "Before Disc & Tax",
  },
  {
    id: "After Disc & Tax",
    value: "After Disc & Tax",
    label: "After Disc & Tax",
  },
];

const StroeGRN = () => {
  //schema
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    invoiceNo: yup
      .string()
      .required("Required")
      .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric are allowed"),
    received: yup
      .string()
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    gateEntryNo: yup.number().required("Required"),
    // grnBatchCode: yup.array().of(
    //   yup.object().shape({
    //     batchCode: yup.string().required("Required"),
    //   })
    // ),
  });

  const defaultValues = {
    grnType: "dailyOrders",
    grnBatchCode: [{ batchCode: "", expiryDate: new Date() }],
    paymentType: "credit",
    store: "",
    discTax: "Before Disc & Tax",
    netPayAmount: "0",
    totalAmount: "0",
    lastDiscAmt: "0",
    invoiceNo: "",
    dcNumber: "",
    gateEntryNo: "",
    supplierName: null,
    grnNumber: "",
    lur: "0",
    ptr: "0",
    gstAmount: "0",
    discAmount: "0",
    mrp: "0",
    batchNumber: "",
    freeQuantity: "0",
    creditNote: "",
    received: "",
    remarks: "",
    otherCharges: "",
    invoiceDate: new Date(),
    dueDate: new Date(),
    lastPurDate: new Date(),
  };

  const methods = useForm({
    shouldFocusError: false,
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    control,
    setValue,
    watch,
    getValues,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const [dailyOrderDetailsData, setDailyOrderDetailsData] = useState([]); // for daily order details
  const [selectedRow, setSelectedRow] = useState([]); // selected items push into items Details table
  const [selectedRowDetails, setSelectedRowDetails] = useState(null); // last pur details
  const [supplierList, setSupplierList] = useState([]);
  const [supplierValue, setSupplierValue] = useState(null);
  const [totalCalculations, setTotalCalculations] = useState(null);
  const [finalData, setFinalData] = useState(null); // post obj
  const [dailyOrderObj, setDailyOrderObj] = useState(); //  get selected po item
  const [openPost, setOpenPost] = useState(false);
  const [isBatchRequired, setIsBatchRequired] = useState(false);
  const [privilege, setPrivilege] = useState("");
  const [userActions, setUserActions] = useState([]);
  const [resetIndex, setResetIndex] = useState(-1);

  // backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // grn modal
  const [openGrnModal, setOpenGrnModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  let discountType = watch("discTax");
  let getGrnType = watch("grnType");
  let gateEntryNo = watch("gateEntryNo");
  let invoiceNo = watch("invoiceNo");
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let storeId = token.storeId;
  let paymentMode = watch("paymentType");
  let payment;

  const OpenStoreGRNModal = () => setOpenGrnModal(true);

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
      setOpenBackdrop(false);
    }
  };

  const handleChangeGetSupplierList = (autoSearchString) => {
    if (autoSearchString !== "") {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  // set value from input fields

  function postGrnItem(obj) {
    postGrnItems(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData(null);
        setSelectedRow([]);
        setSelectedRowDetails(null);
        setTotalCalculations(null);
        setDailyOrderDetailsData([]);
        setSupplierList([]);
        setSupplierValue(null);
        setDailyOrderObj();
        setIsBatchRequired(false);
        setOpenBackdrop(false);
        setValue("totalAmt", "");
        setValue("discAmt", "");
        setValue("gstAmt", "");
        setValue("roundingAmt", "");
        setValue("netPaybaleAmt", "");
        setValue("supplierName", null);
        reset(defaultValues);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postGrnItem(finalData);
  }

  if (paymentMode === "credit") {
    payment = 0;
  } else if (paymentMode === "cash") {
    payment = 1;
  }

  const onSubmitDataHandler = (dataObj) => {
    let selectedInvoiceDate = getValues("invoiceDate");
    let selectedDueDate = getValues("dueDate");
    // BatchesRequired
    let requiredArr = [];
    for (let obj of selectedRow) {
      let tempObj = {};
      let batchExpiryDate = new Date(obj.expiryDate);
      tempObj.acceptQty = Number(obj.FreeQty) + Number(obj.DOQty);
      tempObj.batchCode = obj.batchCode;
      tempObj.batchExpiryDate =
        obj.expiryDate === undefined
          ? format(new Date(), "yyyy-MM-dd'T'hh:mm:ss.000'Z'").slice(0, -2)
          : format(new Date(batchExpiryDate), "yyyy-MM-dd'T'hh:mm:ss.000'Z'");
      tempObj.conversion = obj.Conversion;
      tempObj.BatchesRequired = obj.BatchesRequired;
      tempObj.dcId = 0;
      tempObj.dcQty = 0;
      tempObj.discAmount = Number(obj.DiscAmt);
      tempObj.discountPer = obj.DiscPer;
      tempObj.freeQty = obj.FreeQty;
      tempObj.isConsignment = obj.Consignment;
      tempObj.itemId = obj.ItemId;
      tempObj.landedNetRate = Number(obj.landedRate);
      tempObj.mrp = obj.MRP;
      tempObj.netAmount = Number(obj.NetAmount);
      tempObj.octroiAmt = 0;
      tempObj.octroiPer = 0;
      tempObj.otherTaxAmount = obj.otherCharges;
      tempObj.poId = obj.DOId;
      tempObj.poQty = obj.DOQty;
      tempObj.purchaseRate = obj.Rate;
      tempObj.rectQty = obj.recQty === undefined ? obj?.PendQty : obj.recQty;
      tempObj.remarks = "";
      tempObj.schemeDiscAmt = 0;
      tempObj.schemePer = 0;
      tempObj.specifications = "";
      tempObj.taxAmount = 0;
      tempObj.totalAmount = obj.Amount;
      tempObj.totalReturnQty = 0;
      tempObj.totalUsedQty = 0;
      tempObj.unitPurchaseRate = obj.Rate;
      tempObj.vatAmount = Number(obj.VATAmt);
      tempObj.vatPercentage = obj.VATPer;
      requiredArr.push(tempObj);
    }

    for (let i = 0; i < selectedRow.length; i++) {
      if (requiredArr[i].BatchesRequired === true) {
        if (
          selectedRow[i].batchCode === "" ||
          selectedRow[i].batchCode === " " ||
          selectedRow[i].batchCode === null
        ) {
          selectedRow[i].requiredBatchNumber = "#D11A2A";
        }
      }
    }

    for (let i = 0; i < requiredArr.length; i++) {
      if (
        (requiredArr[i].BatchesRequired && requiredArr[i].batchCode === "") ||
        requiredArr[i].batchCode === " " ||
        requiredArr[i].batchCode === null
      ) {
        setOpenPost(false);
        setOpenBackdrop(false);
        errorAlert("Please Enter Batch Number!");
      } else if (selectedRow.length > 0) {
        const batchExpiryDate = new Date(requiredArr[i].batchExpiryDate);
        let newDateIS = new Date();
        newDateIS.setHours(0, 0, 0, 0);
        if (Number(requiredArr[i].rectQty) > Number(requiredArr[i].poQty)) {
          setOpenPost(false);
          setOpenBackdrop(false);
          errorAlert("Received Quantity Is Not Valid...!");
        } else if (batchExpiryDate < newDateIS) {
          setOpenPost(false);
          setOpenBackdrop(false);
          errorAlert("Select Valid Expiry Date...!");
        } else {
          let postedObj = {
            menuId: location?.state?.menuId,
            privilege: privilege,
            addedBy: token.userId,
            amountPaid: 0,
            appByAccount: 0,
            appByCfo: 0,
            appByPurchase: 0,
            approvedAmount: 0,
            cashCredit: payment,
            computerName: "",
            consignmentGrnId: 0,
            creditNoteAmount: 0,
            dcDate: selectedDueDate, // dcDate date change
            debitNoteAmount: 0,
            doId: dailyOrderObj?.DOId,
            dueDate: selectedDueDate,
            gateEntryNo: Number(gateEntryNo),
            grnDetailsDto: requiredArr,
            grnDiscountAmount: Number(dataObj.discAmount),
            invoiceDate: selectedInvoiceDate,
            invoiceNo: invoiceNo,
            isApproved: true,
            isApprovedByAcc: true,
            isCancelled: true,
            isClosed: true,
            isConsignment: true,
            isConverted: true,
            isDirectPurchase: true,
            isSettled: true,
            narration: "",
            octroi: 0,
            printFlag: true,
            purchaseReturnAmount: 0,
            receivedBy: 0,
            rejByAccount: 0,
            rejByCeo: 0,
            rejByCfo: 0,
            rejByPurchase: 0,
            remark: dataObj.remarks,
            rounding: 0,
            serviceTaxAmt: 0,
            storeId: storeId,
            supplierId: dataObj.supplierName.id,
            totalAmount: dataObj.totalAmount,
            totalAmountPayable: 0,
            totalBalanceAmount: 0,
            totalItemDiscountAmount: Number(dataObj.discAmount),
            totalItemTaxAmount: 0,
            totalOtherTaxAmount: 0,
            totalSchemeDiscountAmount: 0,
            totalVatAmount: Number(dataObj.gstAmount),
            verified: true,
          };
          setOpenPost(true);
          setFinalData(postedObj);
        }
      } else {
        warningAlert("Please Select Item First");
      }
    }
  };

  //StoreGRNTable Actions
  const renderActions = (row, index) => {
    return (
      <>
        <Checkbox
          control={control}
          disabled={row.PendQty > 0 ? false : true}
          value={index}
          name={`selectOptions${row}`}
          sx={{ padding: 0.5 }}
          size="small"
          onChange={(e) => {
            handleSelect(e, row, index);
          }}
        />
      </>
    );
  };

  const handleSelect = (e, row, index) => {
    let requiredArr = [...selectedRow];
    if (e.target.checked) {
      if (Number(row.PendQty).length > 0) {
        row.landedRate = row.landedRate;
      } else {
        row.landedRate = 0;
      }
      row.otherCharges = 0;
      row.Conversion = 1;
      row.FreeQty = 0;
      row.rectQty = 0;
      row.batchCode = "";
      // row.expiryDate = new Date();
      row.color = "";
      requiredArr.push(row);
    } else {
      let filter = requiredArr.filter((item) => item.ItemCode !== row.ItemCode);
      requiredArr = filter;
      setResetIndex(resetIndex + 1);
    }
    setSelectedRowDetails(row);
    setSelectedRow(requiredArr);
  };

  useEffect(() => {
    if (location?.state?.menuId) {
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    }
  }, []);

  useEffect(() => {
    setValue("supplierName", supplierValue);
    setValue("store", token.storeName);

    if (totalCalculations !== null) {
      setValue("netPayAmount", Number(totalCalculations.netAmount).toFixed(2));
      setValue("gstAmount", Number(totalCalculations.gstAmount).toFixed(2));
      setValue("totalAmount", Number(totalCalculations.totalAmount).toFixed(2));
      setValue(
        "discAmount",
        Number(totalCalculations.discountAmount).toFixed(2)
      );
      setValue(
        "otherCharges",
        Number(totalCalculations.otherCharges).toFixed(2)
      );
    }
  }, [totalCalculations, supplierValue]);

  useEffect(() => {
    if (selectedRowDetails !== null) {
      getLastPurchaseDetails(selectedRowDetails.ItemId, storeId)
        .then((response) => response.data)
        .then((res) => {
          setValue("grnNumber", Number(res?.result?.GRNNumber));
          setValue("mrp", Number(res?.result?.MRP));
          setValue("freeQuantity", Number(res?.result?.FreeQty));
          setValue("lastPurDate", res?.result?.Date);
          setValue("batchNumber", res?.result?.BatchCode);
          setValue("lur", res?.result?.LUR);
          setValue("lastDiscAmt", Number(res?.result?.DiscAmount));
        });
    }
  }, [selectedRowDetails]);

  // Use filter to keep only the first occurrence of each ItemCode
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="text-xl font-bold mt-12 text-center">GRN</div>
          <div className="space-y-2">
            <div className="lg:flex w-full gap-2">
              <fieldset className="border-2 border-gray-300 p-2 rounded-md w-full">
                <legend className="mx-2 px-2 font-semibold">GRN Details</legend>

                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <InputField
                      control={control}
                      name="store"
                      label="Store"
                      disabled={true}
                    />
                  </div>
                  <div>
                    <SearchDropdown
                      control={control}
                      searchIcon={true}
                      isDisabled={false}
                      isSearchable={true}
                      isClearable={true}
                      error={errors.supplierName}
                      name="supplierName"
                      placeholder="Supplier Name*"
                      dataArray={supplierList}
                      handleInputChange={handleChangeGetSupplierList}
                    />
                  </div>
                  <div>
                    <InputField
                      control={control}
                      name="invoiceNo"
                      label="Invoice No."
                      error={errors.invoiceNo}
                    />
                  </div>
                  <div>
                    <DatePickerFieldNew
                      control={control}
                      name="invoiceDate"
                      label="Invoice Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("invoiceDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div>
                    <InputField
                      control={control}
                      name="dcNumber"
                      label="D.C. No."
                    />
                  </div>
                  <div>
                    <DatePickerFieldNew
                      control={control}
                      name="dueDate"
                      label="Due Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("dueDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div>
                    <InputField
                      control={control}
                      name="gateEntryNo"
                      label="Get Entry No.*"
                      error={errors.gateEntryNo}
                    />
                  </div>
                  <div>
                    {userActions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "Daily Orders" ? (
                          <>
                            <CommonButton
                              type="button"
                              className="bg-customBlue text-white"
                              label="Daily Orders"
                              onClick={OpenStoreGRNModal}
                            />
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </fieldset>
              <div className="gap-2 w-full">
                <div className="flex gap-2 my-1.5">
                  <div className="flex items-center">
                    <h4 className="font-bold">GRN Type: &nbsp;</h4>
                    <div className="mt-1.5">
                      <RadioField
                        control={control}
                        name="grnType"
                        dataArray={grnType}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <h4 className="font-bold">Payment Type: &nbsp;</h4>
                    <div className="mt-1.5">
                      <RadioField
                        control={control}
                        name="paymentType"
                        dataArray={paymentType}
                      />
                    </div>
                  </div>
                </div>
                <fieldset className="border-2 border-gray-300 p-2 rounded-md">
                  <legend className="mx-2 px-2 font-bold text-base">
                    Last Purchase Details
                  </legend>
                  <div className="grid grid-cols-4 gap-2">
                    <div>
                      <InputField
                        control={control}
                        name="lur"
                        label="L.U.R."
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        control={control}
                        name="ptr"
                        label="P.T.R."
                        disabled={true}
                      />
                    </div>
                    <div>
                      <DatePickerFieldNew
                        control={control}
                        name="lastPurDate"
                        disabled
                        label="Date"
                        // value={new Date()}
                        onChange={(newValue) => {
                          setValue("date", newValue);
                        }}
                        disableFuture={true}
                        inputFormat="dd-MM-yyyy"
                      />
                    </div>
                    <div>
                      <InputField
                        control={control}
                        name="mrp"
                        label="M.R.P."
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        control={control}
                        name="lastDiscAmt"
                        label="Disc"
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        control={control}
                        name="batchNumber"
                        label="Batch"
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        control={control}
                        name="freeQuantity"
                        label="Free Qty"
                        disabled={true}
                      />
                    </div>
                  </div>
                </fieldset>
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "GRN List" ? (
                      <>
                        <div className="flex justify-end mt-2">
                          <CommonButton
                            type="button"
                            className="bg-customBlue text-white"
                            label="GRN List"
                            onClick={() => {
                              navigate(obj.menuKey, {
                                state: { menuId: obj.menuId },
                              });
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
            {getGrnType === "dailyOrders" ? (
              <div>
                <div className="w-full grid">
                  <h4 className="font-bold ">Daily Order Details</h4>
                  {dailyOrderDetailsData.length > 0 ? (
                    <>
                      <CommonDynamicTable
                        dataResult={dailyOrderDetailsData}
                        actionsRequired={true}
                        renderActions={renderActions}
                        highlightRow={false}
                        tableClass={"rounded md:h-52 2xl:h-72 border"}
                        removeHeaders={[
                          "DOId",
                          "ItemId",
                          "batchCode",
                          "BatchesRequired",
                          "Consignmen",
                          "DODetailId",
                          "otherCharges",
                          "rectQty",
                          "color",
                          "landedRate",
                          "requiredBatchNumber",
                        ]}
                      />
                    </>
                  ) : (
                    <h1 className="text-center font-semibold my-5 2xl:my-0 2xl:py-14 text-gray-600">
                      No Records Found
                      <span className="tracking-widest animate-pulse">...</span>
                    </h1>
                  )}
                </div>
                <div className="w-full grid">
                  <l className="font-bold ">Item Details</l>
                  {selectedRow.length > 0 ? (
                    <ItemDetailsTable
                      data={selectedRow}
                      setData={setSelectedRow}
                      setTotalCalculations={setTotalCalculations}
                      discountType={discountType}
                      totalCalculations={totalCalculations}
                      isBatchRequired={isBatchRequired}
                      setOpenPost={setOpenPost}
                      setIsBatchRequired={setIsBatchRequired}
                      resetIndex={resetIndex}
                      dailyOrderDetailsData={dailyOrderDetailsData}
                    />
                  ) : (
                    <h1 className="text-center font-semibold my-5 2xl:my-0 2xl:py-14 text-gray-600">
                      No Records Found
                      <span className="tracking-widest animate-pulse">...</span>
                    </h1>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full">
                <label className="font-bold ">Delivery Challan Details</label>
                {/* <StoreGRNTable data={data} /> */}
              </div>
            )}
            <div className="grid grid-cols-8 gap-2">
              <div className="row-span-2 col-span-2 items-center">
                <RadioField
                  control={control}
                  name="discTax"
                  dataArray={discTaxObj}
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="discAmount"
                  label="C.Disc Amt"
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="schDisc"
                  label="Sch.Disc"
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="octroiAmt"
                  label="Octroi Amt"
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="netPayAmount"
                  label="Net Pay Amount"
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="otherCharges"
                  label="Other Charges"
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="gstAmount"
                  label="GST Amount"
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="creditNote"
                  label="Credit Note"
                />
              </div>
              <div>
                <InputField
                  disabled
                  control={control}
                  name="totalAmount"
                  label="Total Amount"
                />
              </div>

              <div className="col-span-2">
                <InputField
                  control={control}
                  name="received"
                  label="Received"
                  error={errors.received}
                />
              </div>

              <div className="col-span-2">
                <InputField control={control} name="remarks" label="Remarks" />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        setFinalData(null);
                        setSelectedRow([]);
                        setSelectedRowDetails([]);
                        setTotalCalculations(null);
                        setDailyOrderDetailsData([]);
                        setSupplierList([]);
                        setSupplierValue(null);
                        setDailyOrderObj();
                        setIsBatchRequired(true);
                        setOpenBackdrop(false);
                        setValue("totalAmt", "0");
                        setValue("discAmt", "0");
                        setValue("gstAmt", "0");
                        setValue("roundingAmt", "0");
                        setValue("netPaybaleAmt", "0");
                        setValue("supplierName", null);
                        reset(defaultValues);
                      }}
                    />

                    <CommonButton
                      type="submit"
                      className="bg-customGreen text-white"
                      label="Save"
                      onClick={() => {
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </form>
      </FormProvider>
      <CommonBackDrop openBackdrop={openBackdrop} />
      {openGrnModal ? (
        <StoreGRNModal
          openGrnModal={openGrnModal}
          setOpenGrnModal={setOpenGrnModal}
          setSupplierValue={setSupplierValue}
          setDailyOrderDetailsData={setDailyOrderDetailsData}
          dailyOrderDetailsData={dailyOrderDetailsData}
          setDailyOrderObj={setDailyOrderObj}
          setPrivilege={setPrivilege}
          privilege={privilege}
          userActions={userActions}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
};

export default StroeGRN;
