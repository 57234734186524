// /api/patientInfo/admissionAutoComplete/{patientInfo}
import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//autoComplete
export const fetchPatientInfoForIPDFileTransfer = (searchObj) => {
  return apiClient.post(`/ipdFileRegister/ipdFileRegisterListSearch`,searchObj, {
    headers: authHeader(),
  });
};

export const getIPDFileRegisterList = (obj) => {
  return apiClient.post(`/ipdFileRegister/ipdFileRegisterList`, obj, {
    headers: authHeader(),
  });
};

export const saveIpdFileRegister = (obj , menuId , privilege) => {
  return apiClient.post(`/ipdFileRegister/saveIpdFileRegister/${menuId}/${privilege}`, obj, {
    headers: authHeader(),
  });
};

// /api/ipdFileRegister/ipdFileRegisterListSearch