import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { IconButton, Modal, TablePagination, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import AppointmentModal from "../../PatientEngagement/components/AppointmentModal";
import RejectReasonModal from "../../PatientEngagement/components/RejectReasonModal";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import { baseUrl } from "../../http-common";
import { postEngOrderDetails } from "../services/PatientEngagementServices";
import ConfirmCompleteModal from "./ConfirmCompleteModal";
import IncompleteReasonModal from "./IncompleteReasonModal";
import UTIF from "utif";
import { getOldTiffClearanceDocuments } from "../../taskManager/services/patientClearenceServices/PatientClearenceServices";
import loadingImage from "../../taskManager/components/patientClearence/loadingImage.png";

export default function PatientFollowupDetailsTable(props) {
  const {
    tableApiFunc,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
  } = props;

  const [rowId, setRowId] = React.useState(null);
  const [openCompleteModal, setOpenCompleteModal] = React.useState(false);
  const [openIncompleteModal, setOpenIncompleteModal] = React.useState(false);
  const [openApptModal, setApptModal] = React.useState(false);
  const [openRejectModal, setOpenRejectModal] = React.useState(false);
  const [openCalModal, setOpenCalModal] = React.useState(false);
  // const [openReschedule, setOpenReschedule] = React.useState(false);
  const [selectVal, setSelectVal] = React.useState("");
  const [apptReschedule, setApptRescheule] = React.useState("");
  const [incRejectReson, setIncRejectReason] = React.useState("");
  const [pteOrderDetails, setPteOrderDetails] = React.useState();
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [finalArr, setFinalArr] = React.useState([]);

  const [openView, setOpenView] = React.useState(false);
  const [documentData, setDocumentData] = React.useState([]);
  const [documentDimention, setDocumentDimention] = React.useState(1);
  const [selectedHeight, setSelectedHeight] = React.useState("90%");
  const [selectedWidth, setSelectedWidth] = React.useState("60%");
  const [oldCount, setOldCount] = React.useState();
  const [privilege, setPrivilege] = React.useState("");
  const [viewDocuments, setViewDocuments] = React.useState([]);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: selectedHeight,
    width: selectedWidth,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    overflowY: "scroll",
    overflowX: "scroll",
    p: 3,
  };

  React.useEffect(() => {
    if (documentDimention === 0) {
      setDocumentDimention(1);
    }
  }, [documentDimention]);

  const handleCloseChild = () => {
    if (openView === true) {
      setDocumentDimention(1);
      setOpenView(false);
    }
  };

  const defaultValues = {
    selected: null,
  };

  const {
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  React.useEffect(() => {
    handleUserStatus();
  }, [selectVal]);

  const handleUserStatus = () => {
    if (selectVal === "Complete") {
      setOpenCompleteModal(true);
      setSelectVal("");
    } else if (selectVal === "Incomplete") {
      setOpenIncompleteModal(true);
      setSelectVal("");
    } else if (selectVal === "Reject") {
      setOpenRejectModal(true);
      setSelectVal("");
    } else if (selectVal === "Appointment") {
      setOpenCalModal(true);
      setSelectVal("");
    }
    // else if (selectVal === "Reschedule") {
    //   setOpenReschedule(true);
    //   setSelectVal("");
    // }
  };

  const handleCloseAptModal = () => {
    if (openApptModal) {
      setApptModal(false);
    }
  };

  const options = [
    {
      label: "Complete",
      value: "Complete",
    },
    {
      label: "Incomplete",
      value: "Incomplete",
    },
    {
      label: "Reject",
      value: "Reject",
    },
    {
      label: "Appointment",
      value: "Appointment",
    },
    // {
    //   label: "Reschedule",
    //   value: "Reschedule",
    // },
  ];

  React.useEffect(() => {
    checkCallApi(props.page, props.rowsPerPage, oldCount);
  }, [props.page, props.rowsPerPage, oldCount]);

  const checkCallApi = (pageNo, rowsPerPageNo, oldTotal) => {
    pageNo = pageNo + 1;
    let newTotal = pageNo * rowsPerPageNo;

    let arr = [...props.data.result];
    let totalDataLength = props.count;
    let availableDataLength = arr.length;
    if (totalDataLength > availableDataLength) {
      // if i dont have total record to show
      if (newTotal > availableDataLength) {
        //
        let require = newTotal - availableDataLength;
        let toShow = availableDataLength + require;

        // check available vs needed
        // needed is less than count
        let needed = 0;
        if (toShow < totalDataLength) {
          needed = newTotal - oldTotal;
          callAgainTableApi(needed);
        } else if (toShow > totalDataLength) {
          needed = totalDataLength - availableDataLength; // after
          // Before // needed = toShow - totalDataLength
          callAgainTableApi(needed);
        } else {
          needed = rowsPerPageNo;
          callAgainTableApi(needed);
        }
      }
    }
  };

  const callAgainTableApi = (recordsNeeded) => {
    tableApiFunc();
  };

  const handlePageChange = (event, newPage) => {
    setOldCount((page + 1) * props.rowsPerPage);
    props.setPage(parseInt(newPage));
  };

  //5,10.25 change as per the selection
  const handleChangeRowsPerPage = (event) => {
    setOldCount((props.page + 1) * props.rowsPerPage);
    let newRows = parseInt(event.target.value, 10);
    let newTotal = (props.page + 1) * newRows;
    let additionalRecord = newTotal - props.count;
    if (additionalRecord >= newRows) {
      props.setPage(props.page - 1);
    }
    props.setRowsPerPage(newRows);
  };

  const handleClosePost = () => {
    setOpenConfirmationModal(false);
  };

  const handlePostRecord = () => {
    setOpenConfirmationModal(true);

    setPteOrderDetails(finalArr);
  };
  let location = useLocation();
  const postRecord = () => {
    postEngOrderDetails(pteOrderDetails, location?.state?.menuId, privilege)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.populateTable();
      })
      .catch((error) => {
        errorAlert(error.message || error.response.data.message);
      });

    setOpenConfirmationModal(false);
    reset();
  };

  const handleGetDocuments = (data) => {
    if (data) {
      let temp = [...documentData];
      for (let obj of data) {
        let object = {};
        object.fileType = "ptEngDoc";
        // to get the required filePaths and creating strings array from the received path
        object.fileName = obj
          .toString()
          .replaceAll("//", ",")
          .replaceAll("\\", ",")
          .split(",");
        temp.push(object);
        setDocumentData(temp);
      }
    }
  };

  React.useEffect(() => {
    let imageArray = [];
    if (documentData?.length > 0) {
      documentData.forEach((imagePath, index) => {
        getOldTiffClearanceDocuments(
          imagePath?.fileName?.[0],
          imagePath?.fileName?.[1],
          imagePath?.fileName?.[2],
          imagePath?.fileName?.[3]
        )
          .then((response) => response.data)
          .then((res) => {
            var apiResponseImageData = res;

            var ifds = UTIF.decode(apiResponseImageData);
            ifds.map(function (ifd, index) {
              UTIF.decodeImage(apiResponseImageData, ifd);
              var rgba = UTIF.toRGBA8(ifd);
              var canvas = document.createElement("canvas");
              canvas.width = ifd.width;
              canvas.height = ifd.height;
              var ctx = canvas.getContext("2d");
              var img = ctx.createImageData(ifd.width, ifd.height);
              img.data.set(rgba);
              ctx.putImageData(img, 0, 0);
              let image = canvas.toDataURL();
              imageArray.push(image);
              setViewDocuments(imageArray);

              document
                .getElementById(`tiff-inner-container${index}`)
                .appendChild(canvas);
              return canvas;
            });
          });
      });
    }
  }, [documentData]);

  const handleZoomContent = () => {
    if (documentDimention > 0) {
      setDocumentDimention(documentDimention + 1);
    }
  };

  const handleRevertContent = () => {
    if (documentDimention > 0) {
      setDocumentDimention(documentDimention - 1);
    }
  };

  const handleHeightAndWidth = () => {
    if (selectedHeight === "90%" && selectedWidth === "60%") {
      setSelectedHeight("100%");
      setSelectedWidth("100%");
    } else if (selectedHeight === "100%" && selectedWidth === "100%") {
      setSelectedHeight("90%");
      setSelectedWidth("60%");
    }
  };

  return (
    <>
    {documentData.map((item, index) => {
      return (
        <canvas
          id={`tiff-inner-container${index}`}
          className="h-[300px] w-[400px] hidden"
        />
      );
    })}
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={props.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "lightblue",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1E3A8A",
                },
              }}
              className="rounded h-72"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                    className="whitespace-nowrap"
                  >
                    <TableCell>Actions</TableCell>
                    <TableCell>MR No.</TableCell>
                    <TableCell>Patient Name</TableCell>
                    <TableCell>Contact No.</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Reschedule Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Appt/Reschedule</TableCell>
                    <TableCell>Incomplete/Reject Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.result.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0.3,
                          },
                        }}
                      >
                        <TableCell>
                          {row.documentSource?.length > 0 ? (
                            <CommonButton
                              className="h-7 rounded px-2 text-white bg-sky-500 w-[92px]"
                              onClick={() => {
                                setOpenView(true);
                                handleGetDocuments(row.documentSource);
                              }}
                              label="View Docs"
                              type="button"
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.MRNo}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.PatientName}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.ContactNo}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.Description}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.ReScheduleDate}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        {row.ID ? (
                          <TableCell className="whitespace-nowrap" key={row.ID}>
                            <div className="justify-center ">
                              <div className="w-40 z-50">
                                <Select
                                  isClearable={true}
                                  isSearchable={true}
                                  name={`selected${index}`}
                                  options={options}
                                  onChange={(event) => {
                                    setRowId(row.ID);
                                    setSelectVal(event.value);
                                  }}
                                  sx={{ width: 200 }}
                                  size="small"
                                />
                              </div>
                            </div>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell>{apptReschedule}</TableCell>
                        <TableCell>{incRejectReson}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {props?.userActions?.length > 0
          ? props.userActions.map((actions) => {
              return !actions.isAction && actions.action === "Create" ? (
                <div className="py-3 flex justify-end">
                  <CommonButton
                    className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium"
                    onClick={() => {
                      handlePostRecord();
                      setPrivilege(actions.action);
                    }}
                    label="Save"
                    type="button"
                  />
                </div>
              ) : (
                ""
              );
            })
          : ""}

        {/* To stop the re-rendering of the modal we need to use this ternary
        operator */}
        {openRejectModal ? (
          <RejectReasonModal
            setOpenRejectModal={setOpenRejectModal}
            openRejectModal={openRejectModal}
            finalArr={finalArr}
            setFinalArr={setFinalArr}
            rowId={rowId}
          />
        ) : null}

        {openIncompleteModal ? (
          <IncompleteReasonModal
            setOpenIncompleteModal={setOpenIncompleteModal}
            openIncompleteModal={openIncompleteModal}
            finalArr={finalArr}
            setFinalArr={setFinalArr}
            rowId={rowId}
          />
        ) : null}

        {/* To stop the re-rendering of the modal we need to use this ternary
        operator */}
        {openCompleteModal ? (
          <ConfirmCompleteModal
            openCompleteModal={openCompleteModal}
            setOpenCompleteModal={setOpenCompleteModal}
            finalArr={finalArr}
            setFinalArr={setFinalArr}
            rowId={rowId}
          />
        ) : null}
        {openCalModal ? (
          <AppointmentModal
            openCalModal={openCalModal}
            setOpenCalModal={setOpenCalModal}
            finalArr={finalArr}
            setFinalArr={setFinalArr}
            rowId={rowId}
          />
        ) : null}

        {/* {openReschedule ? (
          <RescheduleModal
            openReschedule={openReschedule}
            setOpenReschedule={setOpenReschedule}
            currentApptDate={currentApptDate}
            setCurrentApptDate={setCurrentApptDate}
            finalArr={finalArr}
            setFinalArr={setFinalArr}
            rowId={rowId}
            patientEngagementId={patientEngagementId}
          />
        ) : null} */}

        {/* ConfirmationModal for Reschedule */}
        <ConfirmationModal
          confirmationOpen={openApptModal}
          confirmationHandleClose={handleCloseAptModal}
          confirmationSubmitFunc={handleCloseAptModal}
          confirmationLabel="Confirmation"
          confirmationMsg="Please select appointment first"
          confirmationButtonMsg="OK"
        />

        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={postRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this Record ?"
          confirmationButtonMsg="Add"
        />

        <Modal
          open={openView}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <CancelPresentationIconButton
              onClick={() => {
                handleCloseChild();
                setDocumentData([]);
              }}
            />
            <div>
              <div className="flex justify-between">
                <div className="text-lg font-semibold my-4">Documents :</div>
                <div className="flex gap-2 items-center">
                  <Tooltip title="Zoom In">
                    <span className="border border-gray-400 rounded p-1 bg-gray-200 ">
                      <IconButton
                        className="px-5  h-5  w-6 text-black "
                        onClick={handleZoomContent}
                      >
                        <ZoomInIcon sx={{ color: "#070708" }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Zoom Out">
                    <span className="border border-gray-400 rounded p-1 bg-gray-200 ">
                      <IconButton
                        className="px-5  h-5  w-6 text-black "
                        onClick={handleRevertContent}
                      >
                        <ZoomOutIcon sx={{ color: "#070708" }} />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <CommonButton
                    label="Reset"
                    className="h-9 px-3 border border-customRed text-customRed rounded text-base"
                    type="button"
                    onClick={() => {
                      setDocumentDimention(1);
                    }}
                  />
                </div>
              </div>

              <div
                className="flex gap-2  border border-gray-300 rounded overflow-x-scroll my-5 p-2"
                onClick={handleHeightAndWidth}
              >
                {documentData.map((documents, index) => {
                  let documentId = document.getElementById(
                    `clearanceDocument${index}`
                  );
                  if (documentId) {
                    if (viewDocuments[index]) {
                      documentId.src = viewDocuments[index];
                    } else {
                      documentId.src = loadingImage;
                    }
                  }
                  return (
                    <div
                      className=""
                      // onClick={() => openViewer(index)}
                    >
                      <img
                        id={`clearanceDocument${index}`}
                        className="object-contain h-full w-full"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
