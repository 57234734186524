import { Print } from "@mui/icons-material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  fetchAllPrintGrnList,
  fetchPrintGrnList,
} from "../../../services/generalStore/storeGRN/StoreGRNServices";
import {
  getVerifyGrnItemDetailsList,
  getVerifyGrnList,
  postVerifyGrn,
} from "../../../services/generalStore/verifyGrn/VerifyGrnService";
import { getSupplierList } from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import { VerifyDisabledIcon } from "./assets/VerifyDisabledIcon";
import { VerifyIcon } from "./assets/VerifyIcon";

const verificationObj = [
  {
    id: "All",
    value: "ALl",
    label: "All",
  },
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Completed",
    value: "Completed",
    label: "Completed",
  },
];
const grnTypeObj = [
  {
    id: "All",
    value: "ALl",
    label: "All",
  },
  {
    id: "Daily Orders",
    value: "Daily Orders",
    label: "Daily Orders",
  },
  {
    id: "Direct Purchases",
    value: "Direct Purchases",
    label: "Direct Purchases",
  },
  {
    id: "D.C.",
    value: "D.C.",
    label: "D.C.",
  },
];
const paymentTypeObj = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Cash",
    value: "Cash",
    label: "Cash",
  },
  {
    id: "Credit",
    value: "Credit",
    label: "Credit",
  },
];

const Actions = [
  {
    id: 0,
    action: "Verify Grn",
    isAction: true,
  },
  { id: 1, action: "Print", isAction: true },
  { id: 2, action: "Print All", isAction: false },
];

function VerifyGrn() {
  const defaultValues = {
    store: "",
    verification: "All",
    grnType: "All",
    paymentType: "All",
    searchItem: null,
    supplier: null,
    fromDate: new Date(),
    toDate: new Date(),
    converted: false,
    consignment: false,
    cancelled: false,
  };
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [supplierList, setSupplierList] = useState([]);
  const [supplierId, setSupplierId] = useState(0);
  const [getGrnList, setGetGrnList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [itemsDetailsData, setItemsDetailsData] = useState([]);
  const [urlforPrint, setUrlforPrint] = React.useState(); //table listing
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [urlForAllGrnPrint, setUrlForAllGrnPrint] = React.useState();
  const [openGrnPrintModal, setOpenGrnPrintModal] = React.useState(false);
  const [privilege, setPrivilege] = React.useState("");

  const [tableUserActions, setTableUserActions] = useState([]);

  const location = useLocation();
  // useEffect(() => {
  //   location?.state?.menuId &&
  //     fetchUserActionsByMenuId(location?.state?.menuId)
  //       .then((response) => response.data)
  //       .then((res) => {
  //         setUserActions(res.result);
  //       });
  // }, []);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  // watch veriables
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");
  let verificationType = watch("verification");
  let grnType = watch("grnType");
  let isConverted = watch("converted");
  let consignment = watch("consignment");
  let cancelled = watch("cancelled");
  let paymentType = watch("paymmentType");
  // payment Type
  let paymentMode;
  if (paymentType === "All") {
    paymentMode = 2;
  } else if (paymentType === "Cash") {
    paymentMode = 0;
  } else if (paymentType === "Credit") {
    paymentMode = 1;
  }
  // consignment
  let isConsignment;
  if (consignment === false) {
    isConsignment = 0;
  } else if (consignment === true) {
    isConsignment = 1;
  }
  // isCancelled
  let isCancelled;
  if (cancelled === false) {
    isCancelled = 0;
  } else if (cancelled === true) {
    isCancelled = 1;
  }
  // verfiaction
  let isVerified;
  if (verificationType === "All") {
    isVerified = 2;
  } else if (verificationType === "Pending") {
    isVerified = 1;
  } else if (verificationType === "Completed") {
    isVerified = 0;
  }
  // grnType
  let isDirectPurchase;
  if (grnType === "All") {
    isDirectPurchase = 2;
  } else if (grnType === "Daily Orders") {
    isDirectPurchase = 0;
  } else if (grnType === "Direct Purchase") {
    isDirectPurchase = 1;
  } else if (grnType === "D.C.") {
    isDirectPurchase = 3;
  }

  // supplier
  const handleChangeGetSupplierList = (autoSearchString) => {
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };
  // grnList Table
  const populateTable = () => {
    let obj = {
      cashCredit: paymentMode,
      consignment: isConsignment,
      fromDate: fromDate,
      isCancelled: isCancelled,
      isConverted: isConverted,
      isDirectPurchase: isDirectPurchase,
      isVerified: isVerified,
      itemId: 0,
      storeId: getUserInfo.storeId,
      supplierId: supplierId,
      toDate: toDate,
      menuId: location?.state?.menuId,
    };
    getVerifyGrnList(obj)
      .then((response) => response.data)
      .then((res) => {
        setTableUserActions(res.actions);
        setGetGrnList(res.result);
      });
  };
  // itemDetails Table
  const datailsTable = () => {
    getVerifyGrnItemDetailsList(selectedRow?.GRNId, selectedRow?.StoreId)
      .then((response) => response.data)
      .then((res) => {
        setItemsDetailsData(res.result);
      });
  };

  // searchIcon Filter
  const filterData = () => {
    populateTable();
  };
  // grn list
  useEffect(() => {
    setValue("store", getUserInfo.storeName);
    populateTable();
  }, []);

  //grn details list
  React.useEffect(() => {
    if (selectedRow?.GRNId) {
      datailsTable();
    }
  }, [selectedRow]);

  const handleClickToAllPrint = () => {
    let fDate;
    let tDate;
    let fromFormatedDate = fromDate;
    let toFormatedDate = toDate;
    if (fromFormatedDate) {
      let fromDateStr = new Date(fromFormatedDate)
        .toISOString()
        .replaceAll("/", "-")
        .slice(0, -14);

      fDate = fromDateStr;
    }
    if (toFormatedDate) {
      let fromDateStr = new Date(toFormatedDate)
        .toISOString()
        .replaceAll("/", "-")
        .slice(0, -14);
      tDate = fromDateStr;
    }
    let obj = {
      fromDate: fDate,
      storeId: getUserInfo.storeId,
      toDate: tDate,
    };

    fetchAllPrintGrnList(obj).then((response) => {
      setUrlForAllGrnPrint(response);
    });
    setOpenGrnPrintModal(true);
  };

  //VerifyGRN Actions
  const [finalData, setFinalData] = React.useState({});
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    setOpenPost(false);
  };

  const addRecord = () => {
    postVerifyGrn(location?.state?.menuId, privilege, finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        setItemsDetailsData([]);
        setGetGrnList([]);
        datailsTable();
        setOpenPost(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  };

  React.useEffect(() => {
    let tempArr = [itemsDetailsData];
    let reqArr = [];
    for (let detailsObj of tempArr) {
      for (let obj of detailsObj) {
        let expDate = new Date(obj["Batch Expiry Date"]);

        let reqObj = {};
        reqObj.grnId = selectedRow.GRNId;
        reqObj.storeId = selectedRow.StoreId;
        reqObj.acceptQty = obj["Rect Qty"];
        reqObj.batchCode = obj["Batch Code"];
        reqObj.batchExpiryDate = expDate;
        reqObj.itemId = obj.ItemId;
        reqObj.poQty = obj["PO Qty"];
        reqObj.unitLandedRate = obj["Landed Net Rate"];
        reqObj.unitMrp = obj.MRP;
        reqObj.unitPurchaseRate = obj["Purchase Rate"];
        reqObj.vatPercentage = obj["GST Percentage"];
        reqArr.push(reqObj);
      }
    }
    setFinalData(reqArr);
  }, [selectedRow, itemsDetailsData]);

  const handleCloseGrnPrintModal = () => {
    setOpenGrnPrintModal(false);
  };

  const handleSelectedRow = (row, index) => {
    setSelectedRow(row);
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {tableUserActions.map((actions, i) => (
          <>
            {actions.action === "Verify" ? (
              row.Verified === false ? (
                <Tooltip title="Verify Grn">
                  <div
                    className="text-customBlue cursor-pointer"
                    onClick={() => {
                      setPrivilege(actions.action);
                      setOpenPost(true);
                      setSelectedRow(row);
                    }}
                  >
                    <VerifyIcon className="pl-3" />
                  </div>
                </Tooltip>
              ) : (
                <div className="">
                  <VerifyDisabledIcon className="pl-3 cursor-pointer" />
                </div>
              )
            ) : (
              ""
            )}

            {actions.action === "Print" ? (
              <Tooltip title="Print">
                <div
                  className="text-gray-600 cursor-pointer"
                  onClick={() => {
                    fetchPrintGrnList(row?.GRNId).then((response) => {
                      setUrlforPrint(response);
                    });
                    setOpenPrintModal(true);
                  }}
                >
                  {<Print />}
                </div>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    );
  };
  return (
    <div className="mt-14 overflow-hidden">
      <h1 className="flex justify-center text-lg font-semibold pb-1">
        GRN List
      </h1>
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-8 items-center gap-2 ">
        <div className="flex lg:col-span-3  whitespace-nowrap space-x-2 items-center ">
          <div className="flex items-center space-x-2">
            <p className="font-semibold">Verification :</p>
            <div className="px-2 flex pt-1 whitespace-nowrap ">
              <RadioField
                name="verification"
                className="whitespace-nowrap"
                control={control}
                dataArray={verificationObj}
              />
            </div>
          </div>

          <div className="hidden lg:block">
            <div className="flex items-center space-x-2 ">
              <p className="font-semibold">GRN Type :</p>
              <div className="px-2 flex pt-1  whitespace-nowrap">
                <RadioField
                  name="grnType"
                  control={control}
                  dataArray={grnTypeObj}
                />
              </div>
            </div>
          </div>
          <div className="hidden xl:block 2xl:hidden">
            <div className="flex space-x-2 2xl:col-span-2 ">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("fromDate", newValue);
                }}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("toDate", newValue);
                }}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
            </div>
          </div>
        </div>
        <div className="lg:hidden ">
          <div className="flex items-center space-x-2 ">
            <p className="font-semibold">GRN Type :</p>
            <div className="px-2 flex pt-1  whitespace-nowrap">
              <RadioField
                name="grnType"
                control={control}
                dataArray={grnTypeObj}
              />
            </div>
          </div>
        </div>
        <div className="xl:hidden 2xl:block lg:col-span-2 xl:col-span-1 2xl:col-span-2">
          <div className="flex space-x-2 ">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("fromDate", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("toDate", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
          </div>
        </div>
        <div className=" lg:hidden">
          <InputField control={control} label="Store" name="store" disabled />
        </div>
        <div className="flex lg:space-x-2 ">
          <div className="hidden lg:block w-full xl:w-7/12">
            <InputField control={control} label="Store" name="store" disabled />
          </div>
          <div className="w-full">
            <SearchDropdown
              control={control}
              searchIcon={true}
              isDisabled={false}
              isSearchable={true}
              isClearable={true}
              error={errors.supplierName}
              name="supplierName"
              placeholder="Supplier Name*"
              dataArray={supplierList}
              handleInputChange={handleChangeGetSupplierList}
              inputRef={{
                ...register(`supplierName`, {
                  onChange: (e) => {
                    if (e.target.value === null) {
                      setSupplierId(0);
                    } else {
                      setSupplierId(e.target.value.id);
                    }
                  },
                }),
              }}
            />
          </div>
        </div>

        <div className="flex space-x-2 items-center">
          <div className="flex whitespace-nowrap">
            <CheckBoxField
              control={control}
              name="converted"
              label="Converted"
            />
            <CheckBoxField
              control={control}
              name="consignment"
              label="Consignment"
            />
            <CheckBoxField control={control} name="cancel" label="Cancelled" />
          </div>
          <div className="hidden 2xl:block ">
            <div className="flex space-x-2 items-center">
              <h1 className="font-semibold text-sm xl:text-base whitespace-nowrap">
                Payment Type &nbsp; : &nbsp;
              </h1>
              <div className="pt-1">
                <RadioField
                  name="paymentType"
                  control={control}
                  dataArray={paymentTypeObj}
                />
              </div>
              <div>
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  searchIcon={true}
                  onClick={filterData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:hidden">
          <div className="flex space-x-2 items-center">
            <h1 className="font-semibold text-sm  whitespace-nowrap">
              Payment Type &nbsp; : &nbsp;
            </h1>
            <div className="pt-1">
              <RadioField
                name="paymentType"
                control={control}
                dataArray={paymentTypeObj}
              />
            </div>
            <div>
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={filterData}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {getGrnList.length > 0 ? (
          <div className="flex items-center justify-between w-full py-1">
            <h1 className="font-semibold text-base 2xl:text-lg">GRN List</h1>

            {tableUserActions.map((actions, i) => (
              <>
                {actions.action === "Print All" ? (
                  <Tooltip title="Print All">
                    <LocalPrintshopIcon
                      sx={{ color: "#E3902F", cursor: "pointer" }}
                      onClick={() => {
                        handleClickToAllPrint();
                      }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            ))}
          </div>
        ) : null}

        {getGrnList.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={getGrnList}
            actionsRequired={true}
            renderActions={renderActions}
            handleSelectedRow={handleSelectedRow}
            removeHeaders={[
              "StoreId",
              "SupplierId",
              "IsClosed",
              "IsCancelled",
              "Cash_Credit",
              "GRNId",
              "IsDirectPurchase",
              "ReceivedBy",
            ]}
            highlightRow={true}
            tableClass={"rounded md:h-52 2xl:h-72 border"}
          />
        ) : (
          <h1 className="flex justify-center mt-14 font-semibold">
            No Record Found...
          </h1>
        )}
      </div>
      <div className="mt-2">
        <h1 className="font-semibold text-base 2xl:text-lg">
          GRN Details List
        </h1>

        {itemsDetailsData.length > 0 ? (
          <div>
            {/* <GrnItemDetailsTable data={itemsDetailsData} /> */}
            <CommonDynamicTableNew
              dataResult={itemsDetailsData}
              tableClass={"rounded h-[240px] 2xl:h-60 border"}
              highlightRow={false}
              removeHeaders={["ItemId", "GRNDetailId"]}
            />
          </div>
        ) : (
          <h1 className="flex justify-center mt-14 font-semibold">
            No Record Found...
          </h1>
        )}
      </div>
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          // handleOpen={handleClickToAllPrint}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openGrnPrintModal ? (
        <CommonPrintModal
          open={openGrnPrintModal}
          setOpen={setOpenGrnPrintModal}
          // handleOpen={handleOpenGrnPrintModal}
          handleClose={handleCloseGrnPrintModal}
          urlforPrint={urlForAllGrnPrint}
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to Verify this grn record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}

export default VerifyGrn;
