import React, { useEffect, useRef } from "react";
import { Checkbox } from "@mui/material";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { BloodIssuePrintData } from "../services/bloodIssueNoteServices/BloodIssueNoteServices";

export default function BloodIssueNotePrint(props) {
  const printRef = useRef(null);

  const [dataResult, setDataResult] = React.useState();

  React.useEffect(() => {
    BloodIssuePrintData(props.admissionId, props.issueId)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props]);

  let CheckTrue;

  if (dataResult?.RequisitionDetails?.Routine === 1) {
    CheckTrue = true;
  } else {
    CheckTrue = false;
  }

  let EmergencyTrue;

  if (dataResult?.RequisitionDetails?.Emergency === 1) {
    EmergencyTrue = true;
  } else {
    EmergencyTrue = false;
  }

  let AdultCheck;

  if (dataResult?.RequisitionDetails?.Adult === 1) {
    AdultCheck = true;
  } else {
    AdultCheck = false;
  }

  let PediatricCheck;

  if (dataResult?.RequisitionDetails?.Pediatric === 1) {
    PediatricCheck = true;
  } else {
    PediatricCheck = false;
  }

  let oneReceived;
  let twoAwaited;

  if (dataResult?.RequisitionDetails?.Pediatric === 1) {
    oneReceived = true;
    twoAwaited = false;
  } else {
    oneReceived = false;
    twoAwaited = true;
  }

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <div>
        <h1 className=" text-center font-semibold">BLOOD ISSUE NOTE</h1>
        <div className=" flex justify-between">
          <span className=" text-[11px]">
            Inamdar Hospital,S No. 15,Near KPCT Mall,Fatima Nagar,
            <br /> Pune 411040.Tel.: 020 - 30502244, 66812222/44
          </span>
          <span>
            <InamdarHospiLogo />
          </span>
        </div>
      </div>
      <div className=" border-black border-[1px]  ">
        <div className=" border-[1px] border-black p-2 bg-black  font-semibold text-[15px] text-center">
          BEFORE FILLING THE FORM PLEASE READ THE FOLLOWING INSTRUCTIONS
          CAREFULLY
        </div>
        <p className=" p-1 text-[11px]">
          <span className="font-semibold"> 1.Blood Reservation :</span> To
          Reserve blood in blood storage/ blood bank please fill this form
          correctly and completely. Along with this form send to cc EDTA sample
          of patient. Reservation can be done 48 hrs before planned use and the
          blood / blood product will be reserved in the blood storage/ blood
          bank for the patient for a period of 72 hrs only.
        </p>

        <p className=" p-1 text-[11px] my-2">
          <span className=" font-semibold">2.Blood Issue :</span> To issue blood
          from blood storage/ blood bank an issue note needs to be sent. The
          issue note will authroise release of the blood / blood product from
          the blood storage / blood bank for patients use. Once blood is issued
          for a particular patient it will be used for that patient only and
          cannot be returned for use by someone else
        </p>

        <p className=" p-1  text-[11px]">
          <span className=" font-semibold">3.Cancellation :</span> In case there
          is doubt on the time of transfusion / instructions from consultant,
          blood should not be issued from blood storage / blood bank until
          clarification is obtained. In a situation blood has been issued from
          blood storage / blood bank and the transfusion is cancelled, the
          cancellation note should be sent to the blood storage providing reason
          for cancellation.
        </p>
        <h1 className=" text-center text-[14px] font-semibold">
          AVOID WASTAGE OF BLOOD / BLOOD PRODUCTS. ISSUE SENSIBLY
        </h1>
      </div>

      <div className=" grid grid-cols-[50%_50%] ">
        {/* <div className=" border-r-[1px] border-black border-l-[1px] border-b-[1px] p-1">
          <div className=" flex space-x-3 text-[11px]">
            <span className=" font-semibold">Patient Name</span>
            <span>:</span>
            <span>{dataResult?.PatientName}</span>
          </div>
          <div className=" flex space-x-[17.5px] text-[11px]">
            <span className=" font-semibold">Mr No.</span>
            <span>&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;:</span>
            <span>{dataResult?.MRNo}</span>
          </div>
          <div className=" flex space-x-3 text-[11px]">
            <span className=" font-semibold">Address</span>
            <span>&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;:</span>
            <span>{dataResult?.Address}</span>
          </div>
        </div> */}

        <div className=" border-b-[1px]  border-[1px] border-black  p-1">
          <div className=" flex space-x-3 text-[11px]">
            <span className=" font-semibold">Mr No.</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
            <span>{dataResult?.MRNo}</span>
          </div>
          <div className=" flex space-x-3 text-[11px]">
            <span className=" font-semibold">Patient Name</span>
            <span>:</span>
            <span>{dataResult?.PatientName}</span>
          </div>
          <div className=" flex gap-5">
            <div className=" flex space-x-3 text-[11px]">
              <span className=" font-semibold">Age</span>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
              </span>
              <span>{dataResult?.AgeYear}</span>
            </div>
            <div className=" flex space-x-3 text-[11px]">
              <span className=" font-semibold">Sex</span>
              <span>&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;:</span>
              <span>{dataResult?.Gender}</span>
            </div>
          </div>

          <div className=" flex gap-5">
            <div className=" flex space-x-3 text-[11px]">
              <span className=" font-semibold">Consultant</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
              <span>{dataResult?.DoctorName}</span>
            </div>
            <div className=" flex space-x-3 text-[11px]">
              <span className=" font-semibold">Bed No</span>
              <span>&nbsp;&nbsp;:</span>
              <span>{dataResult?.BedNo}</span>
            </div>
          </div>
        </div>
        <div className="items-center text-[11px] p-1 border-r-[1px] border-black gap-3 ">
          <div className=" flex justify-end">
            <div>
              Routine <Checkbox checked={CheckTrue} />
            </div>
            <div>
              Emergency <Checkbox checked={EmergencyTrue} />
            </div>
          </div>
          <span className=" flex justify-end">
            Remember to take consent while reserving blood
          </span>
        </div>
      </div>
      <div className=" border-black border-r-[1px] border-l-[1px] grid grid-cols-2 p-1">
        <div className=" flex items-center gap-3 text-[11px]">
          <span className=" font-semibold">Required Blood :</span>
          <div>
            Adult <Checkbox checked={AdultCheck} />{" "}
          </div>
          <div>
            Pediatric <Checkbox checke={PediatricCheck} />{" "}
          </div>
        </div>
      </div>
      <div className=" flex p-1 border-l-[1px] border-r-[1px] border-black   gap-2  text-[11px]">
        <span className=" font-semibold">Diagnosis :</span>
        <h1 className="text-black">
          {dataResult?.RequisitionDetails?.Diagnosis}
        </h1>
      </div>
      <div className="  border-black border-r-[1px] border-l-[1px] p-1  grid grid-cols-8 gap-3  text-[11px]">
        <div className=" font-semibold">Whole Blood</div>
        <div>{dataResult?.RequisitionDetails?.RequiedWholeBloodQty}</div>
        <div className=" font-semibold">PCV</div>
        <div>{dataResult?.RequisitionDetails?.PCVQty}</div>
        <div className=" font-semibold">FFP</div>
        <div>{dataResult?.RequisitionDetails?.FFPQty}</div>
        <div className=" font-semibold">OTHER</div>
        <div>{dataResult?.RequisitionDetails?.Other}</div>
        {/* </div>
      <div className="  border-black border-r-[1px] border-l-[1px] p-1  grid grid-cols-7  text-[11px]"> */}
        <div className=" font-semibold">RDP</div>
        <div>{dataResult?.RequisitionDetails?.RDPQty}</div>
        <div className=" font-semibold">SDP</div>
        <div>{dataResult?.RequisitionDetails?.SDPQty}</div>
        <div className=" font-semibold">CRYPRECIPITATE</div>
        <div>{dataResult?.RequisitionDetails?.CRYOPRECIPITATEQty}</div>
      </div>
      <div className="  border-black border-r-[1px] border-l-[1px]">
        <div className="flex">
          <span className="p-1 text-[11px]">
            For more than 10 units,pls mention consultants name and reason for
            multiple
          </span>
          <span className="ml-10 w-60">
            / <hr className="border-black " />
          </span>
        </div>
        <h1 className="  p-1 text-[11px]">
          {dataResult?.RequisitionDetails?.Consultant}
        </h1>
        <div className="p-1   text-[11px] my-2 flex space-x-4">
          <span className="font-semibold">Location</span>
          <span>&nbsp;&nbsp;:&nbsp;</span>
          <span>{dataResult?.RequisitionDetails?.Location}</span>
        </div>
        <div className="  mt-10 grid grid-cols-3 p-1">
          <span className=" font-semibold text-[12px]">
            RMO (Name and Signature)
          </span>
          <span className="  text-[12px]">
            {dataResult?.RequisitionDetails?.RMOName}
          </span>
        </div>
      </div>
      <div className=" p-1 border-l-[1px]  border-black border-r-[1px] ">
        <div className="flex border-black border-[1px] w-52">
          <div className=" text-[11px] w-40">
            <div className="  p-1 border-black border-b-[1px] ">
              Whole Blood
            </div>
            <div className="  p-1 border-black border-b-[1px] ">PCV</div>
            <div className="  p-1 border-black border-b-[1px] ">FFP</div>
            <div className="  p-1 border-black border-b-[1px] ">RDP</div>
            <div className="  p-1 border-black border-b-[1px] ">SDP</div>
            <div className=" p-1 border-black ">CRYO</div>
          </div>
          <div className=" border-black  border-l-[1px] w-full">
            <div className="text-center border-black border-b-[1px] p-[0.3px] ">
              {dataResult?.RequisitionDetails?.RequiedWholeBloodQty}
            </div>
            <div className="text-center border-black border-b-[1px] p-[0.3px] ">
              {dataResult?.RequisitionDetails?.PCVQty}
            </div>
            <div className="text-center border-black border-b-[1px] p-[0.3px] ">
              {dataResult?.RequisitionDetails?.FFPQty}
            </div>
            <div className=" text-center border-black border-b-[1px] p-[0.3px] ">
              {dataResult?.RequisitionDetails?.RDPQty}
            </div>
            <div className="text-center border-black border-b-[1px] p-[0.3px] ">
              &nbsp;
            </div>
            <div className=" border-black text-center ">
              {dataResult?.RequisitionDetails?.SDPQty}
            </div>
          </div>
        </div>
      </div>
      <div className=" text-[11px] p-1 border-black border-[1px] pb-12">
        <span className=" font-semibold  ">For Blood Storage</span>
        <div className=" flex space-x-10">
          <span>Date & Time Reserving this from</span>
          <span>{dataResult?.RequisitionDetails?.ReceivedDateTime}</span>
          <span>Received by</span>
          <span>{dataResult?.RequisitionDetails?.ReceivedBy}</span>
        </div>
        <div className=" flex items-center gap-3 text-[11px]">
          <span className=" font-semibold">Sample Cross For match :</span>
          <div className=" items-center space-x-5">
            <span>Received</span>
            <Checkbox checked={oneReceived} />
          </div>
          <div className=" items-center space-x-[25px]">
            <span>Awaited</span>
            <Checkbox checked={twoAwaited} />
          </div>
        </div>
      </div>
    </div>
  );
}

