import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ResetButton from "../../../../common/components/Buttons//ResetButton";
import SaveButton from "../../../../common/components/Buttons//SaveButton";
import SearchIconButton from "../../../../common/components/Buttons/SearchIconButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  fetchItemCategory,
  fetchItemClass,
  fetchItemType,
  fetchStore,
  fetchUnit,
} from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  storeStockMinMaxFillDrugList,
  updateStoreStockMinMaxData,
} from "../../../services/storeStockMinMaxServices/StoreStockMinMaxServices";
import StoreStockMinMaxTable from "./StoreStockMinMaxTable";
const data = {
  message: "storeData list found ",
  result: [
    {
      itemName: "A TO Z TAB1",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB2",
      minStock: 50,
      maxStock: 80,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB3",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB4",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB5",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB5",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB5",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
    {
      itemName: "A TO Z TAB5",
      minStock: 10,
      maxStock: 20,
      ecoOrder: 4,
      reorderLevel: 4,
      storageArea: 200,
      Rack: "Rack 1",
      Shelf: "Shelf 1",
    },
  ],
};
const actions = ["print"];
function StoreStockMinMax() {
  const defaultValues = {
    itemType: null,
    itemClass: null,
    store: null,
    itemCategory: null,
  };
  const schema = yup.object().shape({
    itemCategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("required"),
  });

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    register,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variables
  const [unit, setUnit] = React.useState();
  const [store, setStore] = React.useState();
  const [itemType, setItemType] = React.useState(null);
  const [itemCategory, setItemCategory] = React.useState(null);
  const [itemClass, setItemClass] = React.useState(null);
  const [storeStockList, setStoreStockList] = React.useState([]);
  const [selectRowData, setSelectRowData] = React.useState([]);
  const [updateStoreStock, setUpdateStoreStock] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  // other variables
  let itemCategoryVal = watch("itemCategory");
  let itemTypeVal = watch("itemType");
  let itemClassVal = watch("itemClass");
  let storeVal = watch("store");

  let storeIdObj = {};
  let tempArr = [];
  // useEffects

  useEffect(() => {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let storeId = userInfoObj.storeId;
    for (let obj of selectRowData) {
      let tempobj = {};
      tempobj.itemId = obj.Id;
      tempobj.maxStockLevel = obj.MaxStockLevel;
      tempobj.minStockLevel = obj.MinStockLevel;
      tempobj.rackId = obj.RackId;
      tempobj.shelfId = obj.ShelfId;
      tempobj.storeId = storeId;
      tempArr.push(tempobj);
    }
  }, [selectRowData, tempArr]);

  useEffect(() => {
    // let userInfoString = localStorage.getItem("userInfo");
    // let userInfoObj = JSON.parse(userInfoString);
    // let storeId = userInfoObj.storeId;

    // storeIdObj.id = storeId;
    // storeIdObj.value = userInfoObj.storeName;
    // storeIdObj.label = userInfoObj.storeName;

    // setValue("store", storeIdObj);

    // store dropdwo n api
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        setStore(res.result);
      });
    //unit dropdwon api
    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);

        setValue("unit", res.result[1]);
      });
    // item type dropdown api
    fetchItemType()
      .then((response) => response.data)
      .then((res) => {
        setItemType(res.result);
      });
    // item category dropdown api
    fetchItemCategory()
      .then((response) => response.data)
      .then((res) => {
        setItemCategory(res.result);
      });
    // item class dropdown api
    fetchItemClass()
      .then((response) => response.data)
      .then((res) => {
        setItemClass(res.result);
      });
  }, []);
  // call rack drowdown api

  useEffect(() => {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let storeId = userInfoObj.storeId;
    let listObj = {
      itemCategoryId: itemCategoryVal?.id ? itemCategoryVal?.id : null,
      itemClassId: itemClassVal?.id ? itemClassVal?.id : null,
      itemTypeId: itemTypeVal?.id ? itemTypeVal?.id : null,
      // storeId: storeIdObj.id,
      storeId: 29,
    };

    if (itemCategoryVal !== undefined && storeVal !== undefined) {
      storeStockMinMaxFillDrugList(listObj)
        .then((response) => response.data)
        .then((res) => {
          setStoreStockList(res.result);
        });
    }
  }, [itemCategoryVal, storeVal]);

  // functions
  const handleOpen = () => {
    if (storeVal !== undefined && itemCategoryVal !== undefined) {
      setOpen(true);
    } else if (storeVal === undefined) {
      errorAlert("Please Select Store");
    } else if (itemCategoryVal === undefined) {
      errorAlert("Please Select Category");
    }
  };

  const handleClose = () => setOpen(false);

  function onSubmitDataHandler() {
    setUpdateStoreStock(tempArr);
    handleOpen();
  }
  function updateRecord(updateStoreStock) {
    updateStoreStockMinMaxData(updateStoreStock)
      .then((response) => response.data)
      .then((res) => {
        handleClose();
        successAlert(res.message);
        setUpdateStoreStock(null);
        setSelectRowData([]);
        setStoreStockList([]);
        setStore([]);
        setItemCategory([]);
      });
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className=" mt-12">
          <div className="text-lg font-semibold text-center">
            Store Stock &nbsp;(Min/Max)
          </div>
          <div className="grid grid-cols-3 space-x-3 ">
            <div className="w-full">
              <DropdownField
                control={control}
                name="store"
                placeholder="Store"
                dataArray={store}
                error={errors.store}
                isClearable={true}
              />
            </div>

            <div className="flex space-x-4 mx-1">
              <div>
                <input type="checkbox" className="h-4 w-4 mt-3" />
              </div>
              <div className="mt-2">
                <label>0 Balance Items</label>
              </div>
            </div>
            <div className="   justify-end">
              <DropdownField
                control={control}
                name="unit"
                placeholder="Unit"
                error={errors.unit}
                dataArray={unit}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 mt-2">
            <div>
              <DropdownField
                control={control}
                error={errors.itemType}
                name="itemType"
                placeholder="Type"
                dataArray={itemType}
                isSearchable={true}
                isClearable={true}
              />
            </div>
            <div>
              <DropdownField
                control={control}
                error={errors.itemCategory}
                name="itemCategory"
                placeholder="Category"
                dataArray={itemCategory}
                isSearchable={true}
                isClearable={true}
              />
            </div>
            <div className="flex space-x-2">
              <DropdownField
                control={control}
                error={errors.itemClass}
                name="itemClass"
                placeholder="Class"
                dataArray={itemClass}
                isSearchable={true}
                isClearable={true}
              />
              <div className="justify-start">
                <SearchIconButton />
              </div>
            </div>
          </div>
          <div className="py-2">
            {/* {storeStockList?.length > 0 ? ( */}
            <StoreStockMinMaxTable
              data={storeStockList}
              actions={actions}
              setStoreStockList={setStoreStockList}
              setSelectRowData={setSelectRowData}
              selectRowData={selectRowData}
            />
            {/* ) : (
              "No Record Found..."
            )} */}
            <div className="flex justify-end space-x-4 py-2 ">
              <ResetButton
                onClick={() => {
                  reset(defaultValues);
                  setStoreStockList([]);
                }}
              />
              <SaveButton />
            </div>
          </div>
        </div>
      </form>
      <ConfirmationModal
        confirmationOpen={open}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          updateRecord(updateStoreStock);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Store Stock Min/Max ?"
        confirmationButtonMsg="Save"
      />
    </>
  );
}

export default StoreStockMinMax;
