import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputField from "../../../../common/components/FormFields/InputField";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import InputArea from "../../../../common/components/FormFields/InputArea";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";

const MenstrualHistory = (props) => {
  const {
    lmpDate,
    setlmpDate,
    eddDate,
    seteddDate,
    pogAccordingDate,
    setPogAccordingDate,
    accordingUsgDate,
    setAccordingUsgDate,
  } = useContext(DischargeSummaryContext);

  const { control } = useFormContext();

  return (
    <div className="gap-3">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-6">
        <div className="grid grid-cols-6">
          <div className="col-span-6">
            <DatePickerFieldNew
              control={control}
              name="lmp"
              label="LMP"
              // value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
        </div>
        <div className="grid grid-cols-6  ">
          <div className="col-span-6 ">
            <DatePickerFieldNew
              control={control}
              name="eddDate"
              label="EDD"
              // value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
        </div>
        <div className="grid grid-cols-6  ">
          <div className="col-span-6 ">
            <DatePickerFieldNew
              control={control}
              name="pog"
              label="POG According Date"
              // value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
        </div>
        <div className="grid grid-cols-6  ">
          <div className="col-span-6 ">
            <DatePickerFieldNew
              control={control}
              name="usg"
              label="According USG"
              // value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
        </div>
      </div>
      <div className="pt-3">
        <InputArea
          name="cycles"
          control={control}
          label="Cycles"
          placeholder="Cycles"
        />
      </div>
      <h1 className="text-black font-semibold text-sm py-2">OBST History</h1>
      <div className="grid grid-cols-5 gap-3">
        <div className="grid grid-cols-9">
          <div className="col-span-1 text-black font-semibold text-base py-2">
            G
          </div>
          <div className="col-span-8">
            <InputArea
              name="g"
              label="G"
              variant="outlined"
              // label="Family History"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>

        <div className="grid grid-cols-9">
          <div className="col-span-1 text-black font-semibold text-base py-2">
            P
          </div>
          <div className="col-span-8">
            <InputArea
              name="p"
              label="P"

              variant="outlined"
              // label="Family History"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
        <div className="grid grid-cols-9">
          <div className="col-span-1 text-black font-semibold text-base py-2">
            L
          </div>
          <div className="col-span-8">
            <InputArea
              name="l"
              label="L"

              variant="outlined"
              // label="Family History"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
        <div className="grid grid-cols-9">
          <div className="col-span-1 text-black font-semibold text-base py-2">
            A
          </div>
          <div className="col-span-8">
            <InputArea
              name="a"
              label="A"

              variant="outlined"
              // label="Family History"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
        <div className="grid grid-cols-9">
          <div className="col-span-1 text-black font-semibold text-base py-2">
            D
          </div>
          <div className="col-span-8">
            <InputArea
              name="d"
              label="D"

              variant="outlined"
              // label="Family History"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-3 pt-3">
        <div className="col-span-1">
          <InputField
            name="marriedSince"
            variant="outlined"
            label="Married Since"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="col-span-4">
          <InputArea
            name="mnstlHistInput"
            label="Menstrual history."
            placeholder="Menstrual history."
            variant="outlined"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
    </div>
  );
};

export default MenstrualHistory;
