import React from 'react'

const SaveTypeButton = ({ onClick }) => {
  return (
    <div>
    <button
      type="button"
      className="h-9 px-3 bg-green-600 text-white rounded text-base font-medium"
      onClick={onClick}
    >
     Save
    </button>
  </div>
  )
}

export default SaveTypeButton