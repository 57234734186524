import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import TableDropDownField from "../../../../common/components/FormFields/TableDropDownField";
import TimePickerFieldNew from "../../../../common/components/FormFields/TimePickerFieldNew";
import {
  fetchDoctorType,
  fetchDoctorsOfType
} from "../../../services/otReservationServices/OtReservationServices";
import { OtDetailsContext } from "./OtDetails";

const DoctorEmpDetails = (props) => {
  const {
    doctorDetailsArr,
    setDoctorDetailsArr,
    setDeletedDoctorData,
    empDetailsArr,
    setEmpDetailsArr,
    setDeletedEmpData,
    reservedSurgeries,
    employeeNameData,
    // setEmployeeNameData,
  } = useContext(OtDetailsContext);

  const [doctorTypes, setDoctorTypes] = useState();
  const [doctors, setDoctors] = useState();

  const {
    control,
    getValues,
    register,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    fetchDoctorType()
      .then((response) => response.data)
      .then((res) => {
        setDoctorTypes(res.result);
      });

  
  }, []);

  // arr Duplication Check
  //

  //add Doctors List
  const addDoctorData = () => {
    let doctorType = getValues("doctorType");
    let doctorName = getValues("doctorName");
    let DoctorCharges = getValues("doctorFees");
    let SurgeryNameDr = getValues("surgeryNameDr");
    let DoctArrivalTime = getValues("doctArrivalTime");
    console.log("DoctArrivalTime", DoctArrivalTime);

    if (
      SurgeryNameDr !== null &&
      doctorType !== null &&
      doctorName !== null &&
      DoctorCharges !== ""
    ) {
      let surgeryDoctorObj = {
        "Surgery Name": SurgeryNameDr.label,
        surgeryId: SurgeryNameDr.id,
        DoctorId: doctorName.map((item) => ({
          id: item.id,
          doctorTypeId: doctorType.id,
        })),
        DoctorTypeId: doctorType.id,
        "Doctor Type": doctorType.label,
        "Doctor Name": doctorName.map((item) => item.label),
        "Doctor Charges": Number(DoctorCharges),
        otDoctorDetailsId: 0,
        "Arrival Time": DoctArrivalTime || null,
        // "Arrival Time": format(DoctArrivalTime, "hh:mm a")||null,
      };

      let arr = [...doctorDetailsArr];
      arr.push(surgeryDoctorObj);
      // setTempArr(arr);
      setDoctorDetailsArr(arr);
      // setValue("surgeryNameDr", "", { shouldValidate: true });
      // setValue("serviceDoctor", null, { shouldValidate: true });
      // setValue("Qty", "", { shouldValidate: true });

      setValue("doctorType", null);
      setValue("doctorName", null);
      setValue("doctorFees", "");
      setValue("surgeryNameDr", null);
      setValue("doctArrivalTime", null);
    }

    if (doctorType === null && doctorName === null) {
      setError("surgeryName", { type: "custom", message: "Required" });
      setError("doctorType", { type: "custom", message: "Required" });
      setError("doctorName", { type: "custom", message: "Required" });
      setError("doctorFees", { type: "custom", message: "Required" });
    } else if (doctorType === null) {
      setError("doctorType", { type: "custom", message: "Required" });
    } else if (doctorName === null) {
      setError("doctorName", { type: "custom", message: "Required" });
    }
  };
  const addEmployeeData = () => {
    let emp = getValues("EmployeeName");
    let SurgeryNameEmp = getValues("surgeryNameEmp");

    if (emp !== null) {
      let empObj = {
        EmployeeId: emp.id,
        "Employee Name": emp.label,
        "Surgery Name": SurgeryNameEmp.label,
        SurgeryId: SurgeryNameEmp.id,
        otEmployeeDetailsId: 0,
      };

      let arr = [...empDetailsArr];
      arr.push(empObj);
      // setTempArr(arr);
      setEmpDetailsArr(arr);
      setValue("surgeryNameEmp", null, { shouldValidate: true });
      setValue("EmployeeName", null, { shouldValidate: true });
    }

    if (emp === null) {
      setError("EmployeeName", { type: "custom", message: "Required" });
    }
  };

  const renderActionsDoct = (row, index) => {
    const handleDeleteRow = () => {
    

      let temp = [...doctorDetailsArr];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedDoctorData((prev) => [...prev, ...deletedItems]);
      setDoctorDetailsArr(notDeletedItems);
    };
    return (
      <>
        <Tooltip title="Delete">
          <div
            className="text-red-500 mr-3"
            onClick={() => handleDeleteRow(row, index)}
          >
            {<DeleteOutlineOutlinedIcon />}
          </div>
        </Tooltip>
      </>
    );
  };

  const renderInputDoct = (row, index, header) => {
    const handleInputUpdate = (index, newValue, header) => {
      const updatedList = [...doctorDetailsArr];
      updatedList[index][header] = newValue;

      setDoctorDetailsArr(updatedList);
    };

    // const handleTableQuantityChange = (index, newValue) => {
    //   const updatedList = [...doctorDetailsArr];
    //   updatedList[index]["Doctor Charges"] = newValue;

    //   setDoctorDetailsArr(updatedList);
    // };

    // const handleChangeSurgery = (newValue) => {
    //   const updatedList = [...doctorDetailsArr];
    //   updatedList[index]["Surgery Name"] = newValue.label;
    //   updatedList[index]["SurguryId"] = newValue.id;

    //   setDoctorDetailsArr(updatedList);
    // };

    // const handleArrivalTimeChange = (newValue) => {
    //   const updatedList = [...doctorDetailsArr];
    //   updatedList[index]["Arrival Time"] = newValue;

    //   setDoctorDetailsArr(updatedList);
    // };

     console.log("doctor row", row);
    setValue(`doctArrivalTime${index}`, row["Arrival Time"]);

    return (
      <>
        {header === "Doctor Charges" && (
          <span>
            <input
              className={
                row.error !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) => {
                // handleTableQuantityChange(index, e.target.value);
                handleInputUpdate(index, e.target.value, header);
              }}
              value={row["Doctor Charges"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </span>
        )}

        {header === "Surgery Name" && (
          <span>
            <TableDropDownField
              control={control}
              defaultValue={{
                id: row["SurguryId"],
                label: row["Surgery Name"],
                value: row["Surgery Name"],
              }}
              name={`reservedSurgeries${index}`}
              // isClearable={true}
              isSearchable={true}
              dataArray={reservedSurgeries}
              inputRef={{
                ...register(`reservedSurgeries${index}`, {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      // handleChangeSurgery(e.target.value);
                      handleInputUpdate(index, e.target.value, header);
                    } else {
                    }
                  },
                }),
              }}
            />
          </span>
        )}

        {header === "Doctor Name" && (
          <div className="flex gap-2 items-center">
            {Array.isArray(row["Doctor Name"])
              ? row["Doctor Name"].map((item, index) => {
                  return <span>{index + 1 + "." + item}</span>;
                })
              : row["Doctor Name"]}
          </div>
        )}

        {header === "Arrival Time" && (
          <div>
            <TimePickerFieldNew
              control={control}
              name={`doctArrivalTime${index}`}
              label=""
              value={row["Arrival Time"] || null}
              inputRef={{
                ...register(`doctArrivalTime${index}`, {
                  onChange: (e) => {
                    console.log("e_doctArrivalTime", e);
                    // handleArrivalTimeChange(e.target.value);
                    handleInputUpdate(index, e.target.value, header);
                  },
                }),
              }}
            />
          </div>
        )}
      </>
    );
  };

  const renderActionsEmp = (row, index) => {
    const handleDeleteRow = () => {
      // setEmpDetailsArr((prevData) =>
      //   prevData.map((item) =>
      //     item["EmployeeId"] === row["EmployeeId"]
      //       ? { ...item, isDelete: true }
      //       : item
      //   )
      // );

      let temp = [...empDetailsArr];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedEmpData((prev) => [...prev, ...deletedItems]);
      setEmpDetailsArr(notDeletedItems);
    };
    return (
      <>
        <Tooltip title="Delete">
          <div
            className="text-red-500 mr-3"
            onClick={() => handleDeleteRow(row, index)}
          >
            {<DeleteOutlineOutlinedIcon />}
          </div>
        </Tooltip>
      </>
    );
  };

  const renderInputEmp = (row, index, header) => {
    const handleChangeSurgery = (newValue) => {
      const updatedList = [...empDetailsArr];
      updatedList[index]["Surgery Name"] = newValue.label;
      updatedList[index]["SurguryId"] = newValue.id;

      setEmpDetailsArr(updatedList);
    };

    return (
      <>
        {header === "Surgery Name" && (
          <span>
            <TableDropDownField
              control={control}
              defaultValue={{
                id: row["SurguryId"],
                label: row["Surgery Name"],
                value: row["Surgery Name"],
              }}
              name={`reservedSurgeries${index}`}
              // isClearable={true}
              isSearchable={true}
              dataArray={reservedSurgeries}
              inputRef={{
                ...register(`reservedSurgeries${index}`, {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      handleChangeSurgery(e.target.value);
                    } else {
                    }
                  },
                }),
              }}
            />
          </span>
        )}
      </>
    );
  };

  return (
    <>
      {/* doctor list */}
      <div className="">
        <div className="my-3">
          <span className=" text-gray-700 font-semibold whitespace-nowrap">
            Doctor List
          </span>
        </div>
        <div className="grid grid-cols-10 gap-3">
          <div className="col-span-2">
            <DropdownField
              control={control}
              error={errors.surgeryNameDr}
              name="surgeryNameDr"
              label="surgery Name"
              searchIcon={true}
              dataArray={reservedSurgeries}
              isSearchable={true}
              isClearable={true}
              placeholder="surgery Name "
            />
          </div>

          <div className="col-span-2">
            {/* <SearchBar doctor */}
            <DropdownField
              control={control}
              error={errors.doctorType}
              name="doctorType"
              label="Doctor Type"
              searchIcon={true}
              dataArray={doctorTypes}
              inputRef={{
                ...register("doctorType", {
                  onChange: (e) => {
                    setValue("doctorName", null);
                    if (e !== null) {
                      fetchDoctorsOfType(e.target.value.id)
                        .then((response) => response.data)
                        .then((res) => {
                          setDoctors(res.result);
                        });
                    }
                  },
                }),
              }}
              isSearchable={true}
              isClearable={false}
              placeholder="Doctor Type "
            />
          </div>

          <div className="col-span-2">
            {/* <SearchBar doctor */}
            <DropdownField
              control={control}
              error={errors.doctorName}
              name="doctorName"
              label="Doctor Name"
              searchIcon={true}
              dataArray={doctors}
              isSearchable={true}
              isClearable={false}
              placeholder="Doctor Name"
              isMulti={true}
            />
          </div>
          <div>
            <InputField
              name="doctorFees"
              variant="outlined"
              label="Doctor Fees"
              error={errors.doctorFees}
              control={control}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="doctArrivalTime"
              label="Arrival Time"
              value={null}
            />
          </div>

          <div className=" flex gap-3">
            <CommonButton
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addDoctorData();
              }}
            />
          </div>
          {/* table */}
          {doctorDetailsArr &&
          doctorDetailsArr.filter((item) => !item.isDelete).length > 0 &&
          doctorDetailsArr.length > 0 ? (
            <div className="w-full mt-2 col-span-10">
              <CommonDynamicTableNew
                dataResult={doctorDetailsArr.filter((item) => !item.isDelete)}
                renderActions={renderActionsDoct}
                removeHeaders={[
                  "otDoctorDetailsId",
                  "DoctorId",
                  "surgeryId",
                  "DoctorTypeId",
                  "SurguryId",
                  "doctorTypeId",
                  "Doctor Details",
                ]}
                editableColumns={[
                  "Doctor Charges",
                  "Surgery Name",
                  "Doctor Name",
                  "Arrival Time",
                ]}
                renderInput={renderInputDoct}
                highlightRow={false}
                tableClass="h-72"
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* employee list */}
      <div className="">
        <div className="mb-3">
          <span className=" text-gray-700 font-semibold whitespace-nowrap">
            Employee List
          </span>
        </div>
        <div className="grid grid-cols-6 gap-3">
          <div className="col-span-2">
            <DropdownField
              control={control}
              error={errors.surgeryNameEmp}
              name="surgeryNameEmp"
              label="surgery Name"
              searchIcon={true}
              dataArray={reservedSurgeries}
              isSearchable={true}
              isClearable={true}
              placeholder="surgery Name "
            />
          </div>
          <div className="col-span-2">
            <DropdownField
              control={control}
              error={errors.EmployeeName}
              name="EmployeeName"
              label="Employee Name"
              searchIcon={true}
              dataArray={employeeNameData}
              isSearchable={true}
              isClearable={true}
              placeholder="Employee Name "
            />
          </div>

          <div className=" flex gap-3 justify-end">
            <CommonButton
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addEmployeeData();
                setValue("services", null);
                setValue("Qty", "");
              }}
            />
          </div>

          {empDetailsArr.length > 0 ? (
            <div className="w-full mt-2 col-span-8">
              <CommonDynamicTableNew
                dataResult={empDetailsArr.filter((item) => !item.isDelete)}
                renderActions={renderActionsEmp}
                removeHeaders={[
                  "otEmployeeDetailsId",
                  "EmployeeId",
                  "SurguryId",
                ]}
                editableColumns={["Surgery Name"]}
                renderInput={renderInputEmp}
                highlightRow={false}
                tableClass="h-72"
              />
            </div>
          ) : null}

          {/* table */}
        </div>
      </div>
    </>
  );
};

export default DoctorEmpDetails;
