import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, TextField } from "@mui/material";
import { parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import InputEnter from "../../../common/components/FormFields/InputEnter";
import InputField from "../../../common/components/FormFields/InputField";
import InputArea from "../../../common/components/FormFields/InputArea";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchPatientDetails,
  fetchPatientList,
} from "../../../inventory/services/commonServices/CommonServices";
import {
  fetchAlternateDrugNameTable,
  fetchDrugNameTable,
  fetchKitList,
  fetchKitListItems,
  fetchPrintInPatientIssue,
  fetchStore,
  postInPatientIssueData,
} from "../../../inventory/services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import AlternateDrugTable from "./AlternateDrugTable";
import DrugTable from "./DrugTable";
import GetIndentModal from "./GetIndentModal";
import ItemDetailsTable from "./ItemDetailsTable";
import InPatientListModal from "./inPatientIssueList/InPatientIssueListModal";
import IPIssuePrint from "../../prints/IPIssuePrint";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { blobToBase64 } from "../../../common/components/printModal/BlobToB64";
import { routeToPrinterDevice } from "../../../commonServices/barCodeServices/BarCodeServices";
import NumberOfCopiesModal from "../../../common/components/printModal/NumberOfCopiesModal";

const InPatientIssue = (props) => {
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = token.cashCounterId;
  //
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [printResponse, setPrintResponse] = useState(null);

  //
  let location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = React.useState("");

  //for show quantity errors
  const [customError, setCustomError] = useState();

  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  //indent modal
  const [openIndentModal, setOpenIndentModal] = React.useState(false);
  const handleOpenIndentModal = () => setOpenIndentModal(true);
  const handleCloseIndentModal = () => setOpenIndentModal(false);
  //get indend tbl--1
  const [indentDtlsTableRow, setIndentDtlsTableRow] = React.useState(null);

  // InPatientListModal
  const [openIssueListModal, setOpenIssueListModal] = React.useState(false);
  const handleOpenIssueListModal = () => setOpenIssueListModal(true);
  const handleCloseIssueListModal = () => setOpenIssueListModal(false);
  //autocomlete
  const [patientList, setPatientList] = React.useState();
  const [patientDetails, setPatientDetails] = React.useState(null);
  //search string of "search by item/input-1" input
  const [drugSearchString, setDrugSearchString] = useState();
  //table data

  const [drugTableData, setDrugTableData] = useState([]);
  const [altDrugTableData, setAltDrugTableData] = useState([]);
  const [selectedGenericId, setSelectedGenericId] = useState();
  const [selectedDrugRowData, setSelectedDrugRowData] = useState(null);
  //last selected index
  const [lastIndex, setLastIndex] = React.useState();
  // itm details drg tbl
  const [selectedItemDtlsID, setSelectedItemDtlsID] = React.useState(null);
  const [selectedDrug, setSelectedDrug] = React.useState(null);
  const [errorToTblInput, setErrorToTblInput] = useState(false);
  //used to substitute
  const [selctedRowData, setSelctedRowData] = React.useState(null);
  const [itemToBeSubstituted, setItemToBeSubstituted] = useState(null);

  //Alt drug tbl Row id
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = React.useState(null);
  //item Kit List
  const [itemKitList, setItemKitList] = React.useState();
  //item against Kit
  const [itemKitListItemsData, setItemKitListItemsData] = React.useState();
  // drug tbl Row id
  const [selectedDrugRowID, setSelectedDrugRowID] = React.useState(0);

  const [selectedDrugData, setSelectedDrugData] = useState([]);
  ///calculations populate
  const [noOfItems, setNoOfItems] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalGstAmount, setTotalGstAmount] = useState(0);

  //empty input on enter setEmptyDrugInput
  const [emptyDrugInput, setEmptyDrugInput] = useState();
  //open batch selection modal
  const [openBatchModal, setOpenBatchModal] = React.useState(false);
  const handleOpenBatchModal = () => setOpenBatchModal(true);
  const handleCloseBatchModal = () => setOpenBatchModal(false);

  /////flag to check batch selected inside table input
  const [batchInsideTableInput, setBatchInsideTableInput] = useState(null);

  // selected row data of batch table inside batch selection modal
  const [batchSelctedRowData, setBatchSelctedRowData] = React.useState(null);

  //prints
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [urlforPrint, setUrlforPrint] = useState();
  const [generatedIssueId, setGeneratedIssueId] = useState(null);

  //Required input focus for open batch modal

  const batchInputFocus = useRef(null);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      SearchPatient: "",
      storeName: "",
      totalAmount: 0,
      netPayableAmount: 0,
      roundedAmount: 0,
      totalQuantity: 0,
      remark: "",

      "input-1": "", //item search
      "input-2": "", //batch no
      "input-3": "", //item qty
      "input-4": null, //kit search
      "input-5": "", //kit qty
    },
  });

  const {
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    register,
    setValue,
    resetField,
    reset,
    watch,
  } = methods;

  const getIndentInfo = () => {
    handleOpenIndentModal();
  };
  const getIssueList = () => {
    handleOpenIssueListModal();
  };

  //use ref hook to shift the focus on batch selection modal
  const FocusBatchModal = useRef();
  const FocusDrugTable = useRef();
  const removeFocusSearch = useRef(null);

  // //The focus batch modal
  const focusBatchSelModal = () => {
    if (FocusBatchModal.current) {
      FocusBatchModal.current.focus();
    }
  };
  // //The focus Drug Table
  const focusDrugTable = () => {
    if (FocusDrugTable.current) {
      FocusDrugTable.current.focus();
    }
  };

  //////// on enter batch input open batch modal
  const handleKeypressForBatch = (e) => {
    //it triggers by pressing the enter key
    // if (e.key === "Enter") {
    if (selectedDrug !== null) {
      setBatchInsideTableInput(false);
      handleOpenBatchModal();
      setSelctedRowData(null);
    }
    //}
  };

  const removeFocusInputSearch = () => {
    if (removeFocusSearch.current) {
      removeFocusSearch.current.blur();
    }
  };

  useEffect(() => {
    if (openBatchModal) {
      focusBatchSelModal();
      // removeFocusItmDtlsTbl();
    }
  }, [openBatchModal]);

  useEffect(() => {
    if (drugTableData.length > 0) {
      focusDrugTable();
    }
  }, [drugTableData]);

  // patient list autocomplet //fetchPatientDetails
  const searchPatientAutoComplete = (e) => {
    if (e && UseCheckConsecutiveCharacters(e)) {
      fetchPatientList(e)
        .then((response) => response.data)
        .then((res) => {
          setPatientList(res.result);
        });
    }
  };

  //fetchPatientDetails

  useEffect(() => {
    getPatientDetails(props?.PatientId);
  }, []);

  const getPatientDetails = (e) => {
    if (e && e !== null) {
      fetchPatientDetails(e)
        .then((response) => response.data)
        .then((res) => {
          if (res.result.IsNursingClearanceDone) {
            resetObj();
            warningAlert("Nursing clearance has been given to this patient.");
          } else {
            setPatientDetails(res.result);
          }
        });
    } else if (e === null) {
      setPatientDetails(null);
      setDrugTableData([]);
      setAltDrugTableData([]);
      setSelectedDrugData([]);
      reset();
    }
  };

  //search by item kit
  const searchByItemKit = (e) => {
    if (e) {
      fetchKitList(e)
        .then((response) => response.data)
        .then((res) => {
          setItemKitList(res.result);
        });
    }
  };

  ///kit list items
  const getKitListItems = (e) => {
    if (e.target.value !== null) {
      fetchKitListItems(e.target.value.id, token.storeId)
        .then((response) => response.data)
        .then((res) => {
          if (res.result.length > 0) {
            setItemKitListItemsData(res.result);
          } else {
            warningAlert("Items not available for this kit...");
          }
        });
    }
  };

  //search by item name/drug name table data
  var watchInput1 = watch("input-1");
  var watchInput2 = watch("input-2");

  useEffect(() => {
    // const searchByItem = (e) => {

    setBatchSelctedRowData(null);

    const drugListObj = {
      deptStore: 1,
      deptStoreId: token.storeId,
      isConsignment: 0,
      itemCode: "0",
      itemName: "%",
      // searchString: watchInput1,
      searchString: drugSearchString,
    };

    if (drugSearchString !== "" && patientDetails) {
      fetchDrugNameTable(drugListObj)
        .then((response) => response.data)
        .then((res) => {
          setDrugTableData(res.result);
        });
    }
    // }
  }, [watchInput1]);

  // alternate drug table object
  if (selectedDrugRowData !== null && selectedDrugRowData !== undefined) {
    var altDrugObj = {
      deptStore: 1,
      deptStoreId: token.storeId,
      genericId: selectedDrugRowData.ItemGenericId,
      isConsignment: selectedDrugRowData.IsConsignment ? 1 : 0,
      itemCode: "%", //selectedDrugRowData.Code,
      itemName: "%", //selectedDrugRowData.Drug,
      itemClassId:
        selectedDrugRowData?.ItemClass === 0
          ? null
          : selectedDrugRowData?.ItemClass,
    };
  }

  //only for empty qty(input -3) when batch no. empty(input-2)
  useEffect(() => {
    if (watchInput2 === "") {
      setValue("input-3", "");
    }
  }, [watchInput2]);

  //
  useEffect(() => {
    //Alternate drug name data altDrugObj totalAmount

    getAlternateDrugNameTable();
    setValue("noOfItems", selectedDrugData.length);
    setValue("totalQuantity", totalQuantity);
    setValue("totalAmount", totalAmount.toFixed(2));
    setValue("gstAmount", totalGstAmount.toFixed(2));
    setValue("netPayableAmount", totalAmount.toFixed(2));
    setValue("roundedAmount", totalAmount.toFixed(0));
    // setValue("gstAmount", totalAmount.toFixed(0));

    //i-2
    if (
      batchSelctedRowData !== null &&
      selectedDrug !== null &&
      !batchInsideTableInput
    ) {
      setValue("input-2", batchSelctedRowData.BatchCode);
    } else if (watchInput1 === "") {
      setValue("input-2", "");
      setValue("input-3", "");
    }

    //i-3
  }, [
    selectedDrugData,
    selectedDrugRowData,
    storeId,
    selectedGenericId,
    noOfItems,
    totalQuantity,
    emptyDrugInput,
    selectedDrug,
    batchSelctedRowData,
    batchInsideTableInput,
  ]);

  useEffect(() => {
    //i-1
    if (selectedDrug !== null) {
      setValue("input-1", selectedDrug && selectedDrug.Drug);
    }
  }, [selectedDrug]);

  //Alternate drug name data altDrugObj
  const getAlternateDrugNameTable = () => {
    if (selectedDrugRowData) {
      fetchAlternateDrugNameTable(altDrugObj)
        .then((response) => response.data)
        .then((res) => {
          setAltDrugTableData(res.result);
        });
    }
  };
  // storename dropdown
  const getStoreNameDropdown = () => {
    fetchStore(storeId)
      .then((response) => response.data)
      .then((res) => {
        setValue("storeName", res.result.StoreName);
      });
  };

  //add items in item details table table

  const getQuantity = (e) => {
    if (e.target.value === "" || e.target.value > selectedDrug.BalanceQty) {
      setCustomError(true);
    } else if (
      e.target.value !== "" ||
      e.target.value <= selectedDrug.BalanceQty
    ) {
      setCustomError(false);
    }
  };

  //to mearge kit & items  itemwise
  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.Id === val.Id);
      if (ind !== -1) {
        acc[ind]["Quantity"] = acc[ind]["Quantity"] + val["Quantity"];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };
  //add items from drug table / alt drug tbl
  const addToItemDetailsTbl = () => {
    //proceed if only patient selcted
    let quantityValue = getValues("input-3");

    if (!customError && patientDetails !== null && quantityValue !== "") {
      const batchValue = getValues("input-2");

      let batchError;
      if (
        (selectedDrug?.BatchesRequired === true ||
          selectedDrug?.BatchesRequired === "Yes") &&
        batchSelctedRowData === null
      ) {
        batchError = true;
      } else if (selectedDrug?.BatchesRequired === "No") {
        batchError = false;
      }

      if (batchError) {
        warningAlert("Please Select Batch Number...");
      } else {
        selectedDrug.BatchNumber = batchValue;
        if (batchSelctedRowData !== null) {
          selectedDrug.BalanceQty = batchSelctedRowData.BalQty;
          selectedDrug.BatchExpDate = batchSelctedRowData.BatchExpDatePatch;
          selectedDrug.UnitMRP = batchSelctedRowData.UnitMRP;
        }

        selectedDrug.Quantity = parseInt(quantityValue);

        let cost =
          quantityValue *
          Number(
            (!!batchSelctedRowData && batchSelctedRowData?.UnitMRP) ||
              selectedDrug.UnitMRP ||
              0
          );
        let gstPerc = Number(selectedDrug.VATPercentage);

        selectedDrug.GstAmount = ((cost * gstPerc) / 100).toFixed(2);

        //calculate with add
        selectedDrug.Amount = Number(quantityValue * selectedDrug.MRP);

        let arr = [...selectedDrugData];

        arr.push(selectedDrug);
        setSelectedDrugData(mergeArray(arr));
        // }

        ///case-1 :: total Quantity calculation
        let quantity = 0;
        //let totalQuantity = 0;

        // for (var i = 0; i < arr.length; i++) {
        //   quantity = arr[i].Quantity;
        //   totalQuantity += quantity;
        // }

        let totalQuantity = props?.data?.reduce(
          (total, item) => Number(total) + Number(item.Quantity || 0),
          0
        );
        setTotalQuantity(totalQuantity);

        // setTotalQuantity(totalQuantity);

        //case-2 :: calculations for amount according to quantity

        let amount = 0;
        let totalAmount = 0;

        for (var i = 0; i < arr.length; i++) {
          quantity = arr[i].Quantity;

          amount = quantity * arr[i].MRP;

          arr[i].Amount = amount;
          totalAmount += amount;
        }

        setTotalAmount(totalAmount);
        setDrugSearchString(null);

        setSelectedDrug(null);

        resetField("input-1", "");
        setValue("input-2", "");
        setValue("input-3", "");
        setSelectedDrug(null);
        setBatchSelctedRowData(null);
      }
      //
    }
    // ///focus input 1 on add button click
    // const e = { target: { name: "input-0" } };
    // onEnterFocusNext(e);
  };

  //add items from kit list dropdown
  const addKitToItemDetailsTable = () => {
    var KitQty = getValues("input-5");
    let arr = [...selectedDrugData];
    if (itemKitListItemsData !== undefined && KitQty !== "") {
      for (let i = 0; i < itemKitListItemsData.length; i++) {
        let existingQty = itemKitListItemsData[i].Quantity;
        let updatedQty = existingQty * KitQty;
        itemKitListItemsData[i].Quantity = updatedQty;

        let cost = updatedQty * Number(itemKitListItemsData[i].UnitMRP || 0);
        let gstPerc = Number(itemKitListItemsData[i].VATPercentage);

        itemKitListItemsData[i].GstAmount = ((cost * gstPerc) / 100).toFixed(2);
      }
      // const newArr = arr.concat(itemKitListItemsData);
      setSelectedDrugData(mergeArray([...arr, ...itemKitListItemsData]));
      if (KitQty !== "") {
        clearErrors(["input-4"]);
        setValue("input-4", null);
        setValue("input-5", "");
      } else if (KitQty === "") {
        setError("input-5", { type: "custom", message: "Required" });
        setValue("input-5", null);
      }
    }
  };

  //finalObj
  const finalObj = {
    menuId: "",
    privilege: "",
    addedBy: 0,
    admissionId: 0,
    cashCounterId: 0,
    inPatientIssueDtlsDtoList: [],
    quantity: 0,
    remark: "string",
    storeId: 0,
    totalAmount: 0,
    totalVatAmount: 0,
    updatedBy: 0,
  };

  ////onSubmit post object below  selectedDrugData

  const onSubmit = (data) => {
    let validBatch = selectedDrugData.some(
      (item) =>
        item.BatchNumber === "" &&
        (item.BatchesRequired === "Yes" || item.BatchesRequired === true)
    );

    if (patientDetails === null) {
      warningAlert("Please Select Patient !");
    } else if (selectedDrugData.length === 0) {
      warningAlert("Please Select Item For Issue!");
    } else if (validBatch) {
      warningAlert("Please Select Batch Number !");
    } else if (errorToTblInput) {
      warningAlert("Please Issue Valid Quantity !");
    } else if (
      !errorToTblInput &&
      selectedDrugData.length > 0 &&
      patientDetails !== null
    ) {
      setOpenConfirmationModal(true);
    }
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    //
    finalObj.menuId = props.menuId ? props.menuId : location?.state?.menuId;
    finalObj.privilege = privilege;
    finalObj.addedBy = token.userId;
    finalObj.admissionId = patientDetails.AdmissionId;
    finalObj.cashCounterId = token.cashCounterId;
    finalObj.quantity = getValues("totalQuantity");
    finalObj.remark = getValues("remark");
    finalObj.storeId = token.storeId;
    finalObj.totalAmount = getValues("totalAmount");
    finalObj.totalVatAmount = getValues("totalAmount");
    finalObj.updatedBy = token.userId;
    finalObj.totalAmountPayable = getValues("netPayableAmount");
    finalObj.indentId =
      indentDtlsTableRow?.IndentId !== null ? indentDtlsTableRow?.IndentId : 0; //new

    // to set data according service obj
    let TblData = [];
    for (let i = 0; i < selectedDrugData.length; i++) {
      let tableObj = {
        batchCode: selectedDrugData[i].BatchNumber,
        conversion: selectedDrugData[i].Conversion,
        expiryDate:
          selectedDrugData[i].BatchesRequired &&
          selectedDrugData[i].BatchNumber !== ""
            ? parseISO(selectedDrugData[i].BatchExpDate) ||
              "1900-01-01T00:00:00.000Z"
            : "1900-01-01T00:00:00.000Z",
        indentDetailId: selectedDrugData[i].IndentDetailId || 0,
        isConsume: true,
        issueQty: Number(selectedDrugData[i].Quantity),
        itemId: selectedDrugData[i].Id,
        kitId: 0,
        kitItemQty: 0,
        mrp: Number(selectedDrugData[i].MRP),
        netAmount: Number(selectedDrugData[i].Amount),
        purchaseRate: Number(selectedDrugData[i].PurchaseRate),
        qtyAvlForReturn: 0,
        totalAmt: Number(selectedDrugData[i].Amount),
        totalForVat: 0,
        unitLandedRate: 0,
        unitMrp: Number(selectedDrugData[i].UnitMRP),
        vatAmount: 0,
        vatInclusive: selectedDrugData[i].VATInClusive,
        vatPercentage: selectedDrugData[i].VATPercentage,
      };
      TblData.push(tableObj);
    }
    const nonZeroItems = TblData.filter(
      (item) => !!item.issueQty && item.issueQty !== 0
    );

    if (nonZeroItems.length > 0) {
      finalObj.inPatientIssueDtlsDtoList = nonZeroItems;

      postInPatientIssueData(finalObj)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 302) {
            warningAlert(res.message);
          } else if (res.statusCode === 200) {
            successAlert(res.message);
            // methods.reset();
            props.handleClose && props.handleClose();
            setOpenBackdrop(false);
            setSelectedDrugData([]);
            setPatientDetails(null);
            setTotalAmount(0);
            setTotalGstAmount(0);
            setTotalQuantity(0);
            setAltDrugTableData([]);
            setDrugTableData([]);
            setValue("remark", "");
            methods.resetField("SearchPatient");
            setOpenIndentModal(true);
            //
            handleOpenPrintModal(res.result?.issueId);

            //setOpenPrintModal(true);
            setGeneratedIssueId(res.result?.issueId);
          }
        })
        .catch((res) => {
          errorAlert(res.message || res.response.data.message);
          setOpenBackdrop(false);
        });
    } else {
      warningAlert("Please Enter Valid Quantity...");
      setOpenBackdrop(false);
      props.handleClose && props.handleClose();
    }

    //
  };

  ////function for :: on enter call function to jump focus on next input

  useEffect(() => {
    ///focus input 1 on add button click
    if (patientDetails !== null) {
      inputRefOne.current.focus();
    }
  }, [patientDetails]);

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);
  const inputRefFive = useRef(null);
  const inputRefaddItem = useRef(null);
  const inputRefAddKit = useRef(null);

  function focusKitSearchDropdown() {
    let fieldsetTag = inputRefFour.current;

    let inputTagCollection = fieldsetTag.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];

    inputTagOne.focus();
  }

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(
        props.menuId ? props.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    getStoreNameDropdown();
  }, []);

  const resetObj = () => {
    setSelectedDrugData([]);
    setPatientDetails(null);
    setTotalAmount(0);
    setTotalGstAmount(0);
    setTotalQuantity(0);
    setAltDrugTableData([]);
    setDrugTableData([]);
    setValue("remark", "");
    methods.resetField("SearchPatient");
    methods.resetField("input-1");
    methods.resetField("input-2");
    methods.resetField("input-3");
    methods.resetField("input-4");
    methods.resetField("input-5");
  };

  // const renderPrint = () => {

  //   const handleClose = () => {
  //     console.log("setOpenPrintModal(false)");

  //     setOpenPrintModal(false);
  //   };

  //   return (
  //     <div className="hidden">
  //       <IPIssuePrint
  //         selectedFromDate={new Date()}
  //         selectedToDate={new Date()}
  //         ipdIssueId={generatedIssueId}
  //         Storeld={token?.cashCounterId}
  //         handleClose={handleClose}
  //       />
  //     </div>
  //   );
  // };

  const handleOpenPrintModal = (issueId) => {
    // print service
    fetchPrintInPatientIssue(
      issueId || generatedIssueId,
      token?.cashCounterId,
      "A5"
    ).then((response) => {
      if (defaultPrinters) {
        setOpenNumberOfCopiesModal(true);
        setPrintResponse(response);
      } else {
        setOpenPrintModal(true);
        setUrlforPrint(response);
      }
    });
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setPrintResponse(null);
    });
  };

  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          In Patient Issue
        </div>
        {/* <form> */}
        {/* //search// */}
        <div className="grid md:grid-cols-5 lg:grid-cols-3 gap-3">
          <div className="w-full md:col-span-5 lg:col-span-1 z-50">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / Indent No."
              dataArray={patientList}
              name="SearchPatient"
              searchIcon={true}
              isClearable={true}
              handleInputChange={searchPatientAutoComplete}
              isDisabled={props?.PatientId ? true : false}
              inputRef={{
                ...register("SearchPatient", {
                  onChange: (e) => {
                    if (e?.target?.value?.id && e?.target?.value?.id !== null) {
                      getPatientDetails(e.target.value.id);
                    } else {
                      setSelectedDrugData([]);
                      setPatientDetails(null);
                      setTotalAmount(0);
                      setTotalGstAmount(0);
                      setTotalQuantity(0);
                      setAltDrugTableData([]);
                      setDrugTableData([]);
                      setValue("remark", "");
                      methods.resetField("SearchPatient");
                      methods.resetField("input-1");
                      methods.resetField("input-3");
                      methods.resetField("input-2");
                      methods.resetField("input-4");
                      methods.resetField("input-5");
                    }
                  },
                }),
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              control={control}
              name="storeName"
              label="Store Name "
              disabled={true}
            />
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {obj.action === "Indent List" ? (
                      <div>
                        <CommonButton
                          label="Get Indent"
                          onClick={() => {
                            getIndentInfo();
                            setPrivilege(obj.action);
                          }}
                          className="bg-customBlue text-white"
                        />
                      </div>
                    ) : null}
                    {obj.action === "View" ? (
                      <div>
                        <CommonButton
                          label="Show Previous Issue"
                          onClick={getIssueList}
                          className="bg-customBlue text-white"
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        {/* patient Details */}
        <fieldset
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
        >
          <div className=" grid  grid-cols-4 2xl:grid-cols-4  ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.patientName}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.mrNo}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">IPD No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.IpdNo}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Issue No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {/* {patientDetails && patientDetails.mrNo} */}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Gender</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.gender}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Age</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.AgeYear}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Doctor</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.doctor}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Bed No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.BedNo}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Ward</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientDetails && patientDetails.Ward}
                </span>
              </div>
            </div>
          </div>
        </fieldset>
        {/* item search */}
        <div className="my-2">
          <div className="my-3">
            <span className=" text-gray-700 font-semibold whitespace-nowrap">
              Item Details
            </span>
          </div>

          <div className="md:grid md:gap-3 md:grid-cols-8 lg:flex lg:gap-2 ">
            <div
              className="col-span-2 lg:w-[300px] md:mb-3 md:col-span-4 lg:mb-0"
              onChange={(e) => setDrugSearchString(e.target.value)}
            >
              <InputEnter
                control={control}
                name="input-1"
                label="Search by Item "
                placeholder="Search by Item "
                disabled={patientDetails === null ? true : false}
                {...register("input-1")}
                inputRef={inputRefOne}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (
                      selectedDrug?.BatchesRequired === false ||
                      selectedDrug?.BatchesRequired === "No"
                    ) {
                      inputRefThree.current.focus();
                    } else {
                      inputRefTwo.current.focus();
                    }
                  }
                }}
              />
            </div>

            {selectedDrug?.BatchesRequired !== "No" &&
              selectedDrug?.BatchesRequired !== false && (
                <div className="md:col-span-2 ">
                  <InputEnter
                    name="input-2"
                    size="small"
                    inputRef={inputRefTwo}
                    disabled={patientDetails === null ? true : false}
                    variant="outlined"
                    label="Batch Number"
                    {...register("input-2")}
                    control={control}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inputRefThree.current.focus();
                        handleKeypressForBatch(e);
                      }
                    }}
                  />
                </div>
              )}

            <div className="lg:w-15">
              <TextField
                inputRef={inputRefThree}
                error={customError}
                size="small"
                type="number"
                color={customError ? "error" : "primary"}
                InputLabelProps={{
                  shrink: watchInput1 !== null ? true : false,
                  style: { fontSize: "14px" },
                }}
                InputProps={{
                  style: {
                    fontSize: "14px",
                    height: "36.3px",
                    background: "white",
                  },
                }}
                sx={{
                  input: {
                    color: customError ? "#d32f2f" : "#212529",
                  },
                  label: {
                    color: customError ? "#d32f2f" : "#212529",
                  },
                }}
                name="input-3"
                variant="outlined"
                label="Qty"
                {...register("input-3")}
                onChange={(e) => {
                  getQuantity(e);
                }}
                disabled={patientDetails === null ? true : false}
                control={control}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    inputRefaddItem.current.focus();
                  }
                }}
              />
            </div>

            <div>
              <CommonButton
                label="Add"
                className="bg-customGreen text-white"
                referance={inputRefaddItem}
                onClick={() => {
                  addToItemDetailsTbl();
                  inputRefOne.current.focus();
                }}
              />
            </div>

            <div className="  bg-gray-500 lg:grid md:hidden ">
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem={true}
              />
            </div>

            <div className=" md:col-span-8 md:grid  md:grid-cols-4 lg:flex gap-2 ">
              <div className="col-span-2 lg:w-[320px] z-40" ref={inputRefFour}>
                <SearchDropdown
                  control={control}
                  // referance={(e) => {
                  //
                  // }}//not working for search dropdown...need to find reason
                  name="input-4"
                  label="Search by Item Kit"
                  placeholder="Search by Item Kit"
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={patientDetails === null ? true : false}
                  handleInputChange={searchByItemKit} //searchString
                  inputRef={{
                    ...register("input-4", {
                      onChange: (e) => {
                        getKitListItems(e);
                        inputRefFive.current.focus();
                      },
                    }),
                  }}
                  dataArray={itemKitList}
                />
              </div>

              <div className="lg:w-15">
                <InputField
                  name="input-5"
                  type="number"
                  variant="outlined"
                  disabled={patientDetails === null ? true : false}
                  label="Qty*"
                  error={errors.input - 5}
                  control={control}
                  inputRef={inputRefFive}
                  onKeyDown={(e) =>
                    e.key === "Enter" && inputRefAddKit?.current?.focus()
                  }
                />
              </div>

              <div>
                <CommonButton
                  label="Add"
                  referance={inputRefAddKit}
                  onClick={() => {
                    addKitToItemDetailsTable();
                    focusKitSearchDropdown();
                    inputRefFour.current.focus();
                  }}
                  className="bg-customGreen text-white"
                />
              </div>
             
            </div>
          </div>
        </div>
        {/* drug name table */}
        <div className="grid grid-cols-2 mt-2 gap-3">
          {/* drug tbl */}
          <div>
            {drugTableData.length > 0 && watchInput1 !== "" ? (
              <>
                <div className="flex gap-2 items-center">
                  <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Drug Name
                  </div>
                </div>
                <div ref={FocusDrugTable}>
                  <DrugTable
                    // drugTableData={drugTableData}
                    data={drugTableData}
                    selectedItemDtlsID={selectedItemDtlsID}
                    setSelectedDrug={setSelectedDrug}
                    setSelectedItemDtlsID={setSelectedItemDtlsID}
                    setDrugTableData={setDrugTableData}
                    setAltDrugTableData={setAltDrugTableData}
                    selectedGenericId={selectedGenericId}
                    setSelectedGenericId={setSelectedGenericId}
                    setSelectedDrugRowData={setSelectedDrugRowData}
                    setSelectedDrugData={setSelectedDrugData}
                    selectedDrugData={selectedDrugData}
                    setEmptyDrugInput={setEmptyDrugInput}
                    //row ids lastIndex, setLastIndex
                    selectedDrugRowID={selectedDrugRowID}
                    setSelectedDrugRowID={setSelectedDrugRowID}
                    //last indx
                    lastIndex={lastIndex}
                    setLastIndex={setLastIndex}
                    //
                    setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                    selectedAltDrugRowID={selectedAltDrugRowID}
                    //
                    // unFocusDrugInputField={unFocusDrugInputField}
                    removeFocusInputSearch={removeFocusInputSearch}
                    //
                    batchInputFocus={batchInputFocus}
                    //
                    inputRefThree={inputRefThree}
                    //
                    setDrugSearchString={setDrugSearchString}
                  />
                </div>
              </>
            ) : null}
          </div>
          {/* alt drug tbl */}
          <div>
            {altDrugTableData.length > 0 &&
            drugTableData.length &&
            watchInput1 !== "" ? (
              <>
                <div>
                  <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Alternate Drug Name
                  </span>
                </div>

                <div>
                  <AlternateDrugTable
                    data={altDrugTableData}
                    setSelectedDrug={setSelectedDrug}
                    //
                    selectedItemDtlsID={selectedItemDtlsID}
                    setSelectedItemDtlsID={setSelectedItemDtlsID}
                    setAltDrugTableData={setAltDrugTableData}
                    setDrugTableData={setDrugTableData}
                    setSelectedDrugData={setSelectedDrugData}
                    selectedDrugData={selectedDrugData}
                    //
                    setSelectedDrugRowData={setSelectedDrugRowData}
                    //row ids
                    selectedAltDrugRowID={selectedAltDrugRowID}
                    setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                    //
                    setSelectedDrugRowID={setSelectedDrugRowID}
                    selectedDrugRowID={selectedDrugRowID}
                    //
                    lastIndex={lastIndex}
                    //
                    setDrugSearchString={setDrugSearchString}
                    //
                    inputRefThree={inputRefThree}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/* To show the user is the drug X-Schedule or H-Schedule */}
        <div className="flex gap-2 items-center ml-2">
                <div className="h-4 rounded w-4 bg-[#99f2b1]"></div>
                <div>X-Schedule</div>
                <div className="h-4 w-4 rounded bg-[#99ddf7]"></div>
                <div>H-Schedule</div>
              </div>
        {/* Selected item details table setNoOfItems, setTotalQuantity */}
        <div className="mt-2">
          <ItemDetailsTable
            data={selectedDrugData}
            selectedDrug={selectedDrug}
            selectedDrugData={selectedDrugData}
            setSelectedDrugData={setSelectedDrugData}
            selectedItemDtlsID={selectedItemDtlsID}
            setSelectedItemDtlsID={setSelectedItemDtlsID}
            setNoOfItems={setNoOfItems}
            setTotalQuantity={setTotalQuantity}
            setTotalAmount={setTotalAmount}
            setTotalGstAmount={setTotalGstAmount}
            setSelectedDrug={setSelectedDrug}
            //
            openBatchModal={openBatchModal}
            setOpenBatchModal={setOpenBatchModal}
            handleOpenBatchModal={handleOpenBatchModal}
            handleCloseBatchModal={handleCloseBatchModal}
            //
            FocusBatchModal={FocusBatchModal}
            FocusDrugTable={FocusDrugTable}
            //
            drugTableData={drugTableData}
            batchSelctedRowData={batchSelctedRowData}
            setBatchSelctedRowData={setBatchSelctedRowData}
            //
            // onEnterFocusNext={onEnterFocusNext}
            // handleInputFocus={handleInputFocus}
            //
            batchInsideTableInput={batchInsideTableInput}
            setBatchInsideTableInput={setBatchInsideTableInput}
            //
            setErrorToTblInput={setErrorToTblInput}
            errorToTblInput={errorToTblInput}
            //
            selctedRowData={selctedRowData}
            setSelctedRowData={setSelctedRowData}
            setItemToBeSubstituted={setItemToBeSubstituted}
            itemToBeSubstituted={itemToBeSubstituted}
            //
          />
        </div>
        {/* remark & other calculations fields */}
        <div className="grid lg:grid-cols-9 mt-3 gap-3">
          {/* injuries Details */}
          <div className="lg:col-span-3 ">
            <InputArea
              control={control}
              name="remark"
              label="Remark"
              placeholder="Remark"
            />
          </div>
          <div className="grid md:grid-cols-4 lg:col-span-6 lg:grid-cols-6 gap-3">
            <div>
              <InputField
                name="noOfItems"
                variant="outlined"
                label="No. Of Items"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div>
              <InputField
                name="totalAmount"
                variant="outlined"
                label="Total Amount"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div>
              <InputField
                name="totalQuantity"
                variant="outlined"
                label="Total Quantity"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div>
              <InputField
                name="gstAmount"
                variant="outlined"
                label="GST Amount"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div>
              <InputField
                name="netPayableAmount"
                variant="outlined"
                label="Net Payable Amount"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div>
              <InputField
                name="roundedAmount"
                variant="outlined"
                label="Rounded Amound"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
        </div>
        {/* buttons */}
        <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <div>
                    <CommonButton
                      label="Reset"
                      onClick={() => {
                        resetObj();
                      }}
                      className="border border-customRed text-customRed"
                    />
                  </div>
                  <div
                    onClick={() => {
                      setPrivilege(obj.action);
                    }}
                  >
                    <CommonButton
                      type="submit"
                      label="Save"
                      onClick={onSubmit}
                      className="bg-customGreen text-white"
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
        {/* </form> */}
      </div>

      {/* get Indent Modal    */}
      {openIndentModal ? (
        <GetIndentModal
          open={openIndentModal}
          setOpen={setOpenIndentModal}
          handleOpen={handleOpenIndentModal}
          handleClose={handleCloseIndentModal}
          patientDetails={patientDetails}
          getPatientDetails={getPatientDetails}
          patientInfo={props.patientInfo}
          //
          setSelectedDrugData={setSelectedDrugData}
          selectedDrugData={selectedDrugData}
          selectedItemDtlsID={selectedItemDtlsID}
          indentDtlsTableRow={indentDtlsTableRow}
          setIndentDtlsTableRow={setIndentDtlsTableRow}
          //
          menuId={props.menuId ? props.menuId : location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {/* InPatientListModal */}
      {openIssueListModal ? (
        <InPatientListModal
          open={openIssueListModal}
          setOpen={setOpenIssueListModal}
          handleOpen={handleOpenIssueListModal}
          handleClose={handleCloseIssueListModal}
          patientDetails={patientDetails}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
      {/* {!!openPrintModal && renderPrint()} */}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={() => setOpenPrintModal(false)}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default InPatientIssue;
