import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonTransactionPaginationTable from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerField from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  getDepartmentAutoComplete,
  getDepartmentWiseReports,
} from "../../services/feedbackservices/FeedbackServices";
import RadioField from "../../../common/components/FormFields/RadioField";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";

const templateForArr = [
  {
    id: "Staff",
    value: "Staff",
    label: "Staff",
  },
  {
    id: "Patient",
    value: "Patient",
    label: "Patient",
  },
  {
    id: "Consultant",
    value: "Consultant",
    label: "Consultant",
  },
];

function DepartmentWiseReport(props) {
  const { selectedReport } = props;

  const defaultValues = {
    departmentName: null,
    fromDate: new Date(),
    toDate: new Date(),
    templateFor: "Staff",
  };

  const { control, watch, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });
  // local variables
  let departmentNameValue = watch("departmentName");
  let fromDateValue = watch("fromDate");
  let toDateValue = watch("toDate");
  let templateFor = watch("templateFor");
  // state variables
  const [departmentListData, setDepartmentListData] = useState([]);
  const [page, setPage] = useState(0);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [listingObject, setListingObject] = useState({
    searchString:
      departmentNameValue !== null ? departmentNameValue.label : null,
    id: departmentNameValue !== null ? departmentNameValue.id : null,
    fromDate:
      fromDateValue !== null
        ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        : null,
    page: page,
    size: rowsPerPage ? rowsPerPage : 10,
    toDate:
      toDateValue !== null
        ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        : null,
    templateFor: templateFor,
  });

  // get template reports list
  const populateTable = (forPagination) => {
    const obj = {
      searchString:
        departmentNameValue !== null ? departmentNameValue.label : null,
      id: departmentNameValue !== null ? departmentNameValue.id : null,
      departmentId: null,
      templateFor: "",
      fromDate:
        fromDateValue !== null
          ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
      page: page ? page : 0,
      size: rowsPerPage ? rowsPerPage : 10,
      toDate:
        toDateValue !== null
          ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
      reportType:
        selectedReport?.name === "Question Wise Report"
          ? "QuestionWise"
          : "DepartmentWise",
      templateFor: templateFor,
    };
    getDepartmentWiseReports(obj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          if (res.result && Array.isArray(res.result)) {
            setDepartmentListData((prevData) => [...prevData, ...res.result]);
          }
        } else {
          setDepartmentListData(res.result || []);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleInputDepartmentName = (value) => {
    console.log("department name", value);
    if (value !== "" && UseCheckConsecutiveCharacters(value)) {
      getDepartmentAutoComplete(value)
        .then((response) => {
          console.log("");
          setDepartmentOptions(response.data.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useMemo(() => {
    populateTable();
  }, [
    listingObject.fromDate,
    listingObject.toDate,
    listingObject.searchString,
    listingObject.templateFor,
  ]);
  console.log("selectedReport", selectedReport);

  return (
    <>
      <div>
        <h1 className=" my-2 font-semibold whitespace-nowrap text-base tracking-wide">
          Department Wise Reports
        </h1>
        <div className="grid grid-cols-2 gap-2 xl:flex xl:space-x-3 items-center w-full">
          <RadioField
            control={control}
            name="templateFor"
            dataArray={templateForArr}
          />
          {selectedReport?.name === "Question Wise Report" && (
            <div className="xl:w-4/12">
              <SearchDropdown
                name="departmentName"
                control={control}
                placeholder="Department Name"
                handleInputChange={handleInputDepartmentName}
                searchIcon={true}
                dataArray={departmentOptions}
                isClearable={true}
              />
            </div>
          )}
          <div>
            <DatePickerField
              name="fromDate"
              control={control}
              label="From Date"
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div>
            <DatePickerField
              name="toDate"
              control={control}
              label="To Date"
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="flex space-x-3">
            <CommonButton
              type="button"
              label="Reset"
              className="border border-customRed text-customRed"
              onClick={() => {
                reset(defaultValues);
              }}
            />
 
            <CommonButton
              searchIcon={true}
              type="button"
              className="bg-customBlue text-white h-9"
              onClick={() => {
                setListingObject((listingObject) => ({
                  ...listingObject,
                  searchString:
                    departmentNameValue !== null
                      ? departmentNameValue.label
                      : null,
                  fromDate:
                    fromDateValue !== null
                      ? format(new Date(fromDateValue), "yyyy-MM-dd")
                      : null,
                  page: page,
                  size: rowsPerPage ? rowsPerPage : 10,
                  toDate:
                    toDateValue !== null
                      ? format(new Date(toDateValue), "yyyy-MM-dd")
                      : null,
                  templateFor: templateFor,
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        {departmentListData?.length > 0 ? (
          <div className="mt-2">
            <CommonTransactionPaginationTable
              populateTable={populateTable}
              dataResult={departmentListData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              setRowsPerPage={setRowsPerPage}
              highlightRow={false}
              removeHeaders={["Id", "Sr No"]}
              tableClass={"h-[370px]"}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <h3 className="flex justify-center my-28 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        )}
      </div>
    </>
  );
}

export default DepartmentWiseReport;

