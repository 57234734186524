import React, { useEffect, useState } from "react";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { useForm } from "react-hook-form";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchPatientInfoForIPDFileTransfer,
  getIPDFileRegisterList,
  saveIpdFileRegister,
} from "../../services/ipdFileRegisterService/IPDFileRegisterServices";
import RadioField from "../../../common/components/FormFields/RadioField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { format, isAfter } from "date-fns";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { Checkbox } from "@mui/material";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { useLocation } from "react-router-dom";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";

export default function IPDFileResgister() {
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const status = [
    {
      id: 0,
      label: "Pending",
      value: 0,
    },
    { id: 1, label: "Recevied", value: 1 },
    { id: 2, label: "All", value: 2 },
  ];

  const defaultValues = {
    patientSearch: null,
    toDate: new Date(),
    fromDate: new Date(),
    floor: null,
    status: 2,
    selectAll: false,
  };

  const { control, watch, register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let patientSearchVal = watch("patientSearch");
  const [floorVal, fromDateVal, toDateVal, statusVal] = watch([
    "floor",
    "fromDate",
    "toDate",
    "status",
  ]);

  const [autocompleteData, setAutocompleteData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [floors, setFloors] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [userActions, setUserActions] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  let location = useLocation();

  /******************************* useEffects**************************************/
  // Dynamic Actions
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const getPatientInfoAutoComplete = (serchString) => {
    const searchObj = {
      fromDate: format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      searchString: serchString,
      status: statusVal,
      toDate: format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };
    fetchPatientInfoForIPDFileTransfer(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setAutocompleteData(res.result);
      });
  };

  const populateTable = (forPagination) => {
    let defaultParams = {
      admissionId: patientSearchVal ? patientSearchVal?.id : null,
      floorId: floorVal ? floorVal?.id : null,
      fromDate: format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      menuId: null,
      page: !forPagination ? 0 : page,
      privilege: "",
      size: rowsPerPage,
      status: Number(statusVal),
      toDate: format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };
    setShowSpinner(true);
    getIPDFileRegisterList(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setShowSpinner(false);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        setShowSpinner(false);
        errorAlert(error.message);
      });
  };

  useEffect(() => {
    //floor
    fetchFloor()
      .then((response) => {
        setFloors(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });

    //
    let logStore = {
      id: userInfo.floorId,
      label: userInfo.floor,
      value: userInfo.floorId,
    };
    if (userInfo.floorId !== null && userInfo.floor !== null) {
      setValue("floor", logStore);
    }
  }, []);

  //save case paper check box true value record
  function postIPDFileRegister(obj) {
    saveIpdFileRegister(obj, location.state.menuId, privilege)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        // setDataResult([]);
        setOpenBackdrop(false);
        setOpenPost(false);
        reset(defaultValues);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postIPDFileRegister(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let arr = [];
    for (let i = 0; i < dataResult.length; i++) {
      let obj = {};
      obj.admissionId = dataResult[i].AdmissionId;
      obj.isFileReceivedByMRD =
        dataResult[i].IsFileReceivedByMRD2 ||
        dataResult[i].IsFileReceivedByMRD ||
        false;
      obj.isFileSendToMRD =
        dataResult[i].IsFileSendToMRD1 ||
        dataResult[i].IsFileSendToMRD ||
        false;

      arr.push(obj);
    }
    setOpenPost(true);
    setFinalData(arr);
  };

  useEffect(() => {
    isAfter(fromDateVal, new Date(1900, 1, 1)) &&
      isAfter(toDateVal, new Date(1900, 1, 1)) &&
      populateTable();
  }, [patientSearchVal, floorVal, fromDateVal, toDateVal, statusVal]);

  /************** IsFileReceivedByMRD ******************/
  const renderInput1 = (row, index, header) => {
    function handleSelectedReceivedFromMRD(row, newVal) {
      setDataResult((prevData) =>
        prevData.map((item) =>
          item["AdmissionId"] === row["AdmissionId"]
            ? { ...item, IsFileReceivedByMRD2: newVal }
            : item
        )
      );
    }
    return (
      <div className="">
        {userActions.map((actions) => (
          <>
            {actions.isAction === true &&
            actions.action === "Receive case Paper" ? (
              <fieldset
                className="m-0 p-0"
                onClick={(e) => {
                  handleSelectedReceivedFromMRD(row, e.target.checked);
                  // let arr = [...dataResult];
                  // arr[index].IsFileReceivedByMRD = e.target.checked;
                  // setDataResult(arr);
                }}
              >
                <CheckBoxField
                  control={control}
                  name={`IsFileReceivedByMRD${index}`}
                  defaultValue={row.IsFileReceivedByMRD}
                  size="small"
                  disabled={!row.IsFileSendToMRD || row.IsFileReceivedByMRD}
                  // IsFileReceivedByMRD
                />
              </fieldset>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  //************** IsFileSendByMRD **************//
  const renderActions = (row, index, header) => {
    function handleSelectedSendToMRD(row, newVal) {
      setDataResult((prevData) =>
        prevData.map((item) =>
          item["AdmissionId"] === row["AdmissionId"]
            ? { ...item, IsFileSendToMRD1: newVal }
            : item
        )
      );
    }
    return (
      <>
        {userActions.map((actions) => (
          <>
            {actions.isAction === true &&
            actions.action === "Send case Paper" ? (
              <fieldset
                onClick={(e) => {
                  handleSelectedSendToMRD(row, e.target.checked);
                  // let arr = [...dataResult];
                  // arr[index].IsFileSendToMRD = e.target.checked;
                  // setDataResult(arr);
                }}
              >
                <CheckBoxField
                  control={control}
                  defaultValue={row?.IsFileSendToMRD}
                  name={`IsFileSendToMRD${index}`}
                  disabled={row?.IsFileSendToMRD}
                />
              </fieldset>
            ) : null}
          </>
        ))}
      </>
    );
  };

  React.useEffect(() => {
    if (dataResult?.length > 0) {
      let dataArr = [...dataResult];
      let lengthArr = [];
      for (let i = 0; i <= dataArr.length; i++) {
        if (dataArr?.isChecked === true) {
          lengthArr.push(dataArr[i]);
        }
        if (lengthArr?.length < dataArr?.length) {
          setValue("selectAll", false);
        } else if (lengthArr?.length === dataArr.length) {
          setValue("selectAll", true);
        }
      }
    }
  }, [dataResult]);

  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold mb-2 text-xl">
        IPD file Register
      </h1>

      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-4 items-center gap-2">
          <SearchDropdown
            control={control}
            placeholder="Search By Patient Name / MR.No./Mob No."
            dataArray={autocompleteData}
            name="patientSearch"
            searchIcon={true}
            isClearable={true}
            handleInputChange={getPatientInfoAutoComplete}
          />

          <div className="flex space-x-2">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>

          <DropdownField
            control={control}
            name="floor"
            placeholder="Floor"
            dataArray={floors}
            isSearchable={true}
            isClearable={true}
          />
          <RadioField
            label=""
            name="status"
            control={control}
            dataArray={status}
          />
        </div>

        <div className="grid grid-cols-2 justify-between">
          <fieldset
            className=""
            onChange={(e) => {
              if (e.target?.checked === true) {
                let dataArr = [...dataResult];
                dataArr.forEach((obj) => {
                  obj.isChecked = e?.target?.checked;
                });
              } else if (e?.target?.checked === false) {
                let dataArr = [...dataResult];
                dataArr.forEach((obj) => {
                  obj.isChecked = e?.target?.checked;
                });
              }

              let dataArr = [...dataResult];
              let lengthArr = [];
              for (let i = 0; i <= dataArr.length; i++) {
                setValue(`IsFileSendToMRD${i}`, dataArr[i]?.isChecked);
                if (dataArr[i]?.isChecked === true) {
                  lengthArr.push(dataArr[i]);
                }
                if (lengthArr?.length < dataArr.length) {
                  setValue("selectAll", false);
                } else if (lengthArr?.length === dataArr.length) {
                  setValue("selectAll", true);
                }
              }
            }}
          >
            <CheckBoxField
              control={control}
              defaultValue={false}
              name="selectAll"
              label="Select All"
            />
          </fieldset>
        </div>

        {showSpinner ? (
          <div className="grid justify-center items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {dataResult.length > 0 && showSpinner === false ? (
              <>
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  highlightedRow={false}
                  removeHeaders={[
                    "Id",
                    "IsFileReceivedByMRD2",
                    "IsFileSendToMRD1",
                    "IsFileSendToMRD",
                  ]}
                  highlightRow={false}
                  renderActions={statusVal !== "1" && renderActions}
                  renderInput={statusVal !== "1" && renderInput1}
                  editableColumns={statusVal !== "1" && ["IsFileReceivedByMRD"]}
                />
              </>
            ) : (
              <h3 className="flex justify-center my-20 font-bold text-gray-600">
                No Records Found...
              </h3>
            )}
          </>
        )}

        <div className="flex justify-end">
          {userActions.map((actions) => (
            <>
              {!actions.isAction && actions.action === "Create" ? (
                <fieldset
                  onClick={() => {
                    setPrivilege(actions.action);
                  }}
                >
                  <CommonButton
                    className="bg-customGreen text-white"
                    label="Save"
                    type="submit"
                  />
                </fieldset>
              ) : null}
            </>
          ))}
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure you want to add this record ?"
        // Do You Want To Save OPD Case Paper Register?
        confirmationButtonMsg="Save"
      />
    </div>
  );
}
