import React from "react";
import { Modal, Box } from "@mui/material";
import BatchSelectionTable from "./BatchSelectionTable";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

const BatchInfoModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 4,
};

export default function BatchSelectionModal(props) {
  const [selected, setSelected] = React.useState([]);
  const [selectedObj, setSelectedObj] = React.useState(null);
  const [batchData, setBatchData] = React.useState({});

  React.useEffect(() => {
    if (props.data !== null) {
      setBatchData(props.data);
    }
  }, [props.data]);

  return (
    <div className="">
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BatchInfoModalStyle} className="max-h-[88%] xl:max-h-[80%]">
          <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
                props.setDrugBatchInfo(null);
              }}
            />
            <div className="row w-full">
              <fieldset className="border border-gray-300 text-left px-2   rounded mt-1  ">
                <legend className="px-2 ml-3 lg:ml-0 font-bold text-gray-600">
                  Item Info
                </legend>
                <div className="w-full">
                  <BatchSelectionTable
                    data={batchData}
                    setBatchData={setBatchData}
                    open={props.open}
                    selected={selected}
                    setSelected={setSelected}
                    selectedRow={props.selectedRow}
                    setSelectedRow={props.setSelectedRow}
                    selectedObj={selectedObj}
                    setSelectedObj={setSelectedObj}
                    handleClose={props.handleClose}
                    indexVal={props.indexVal}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
