import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Cell, Pie, PieChart } from "recharts";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { getDepartmentWiseStatisticsForDashboard } from "../../../services/feedbackdashbordservice/FeedBackDashbbordService";
import IpdPatientFeedBackChart from "./IpdPatientFeedBackChart";
import OpdPatientFeedBackChart from "./OpdPatientFeedBackChart";
import ConsultantFedbackPieChartWithNeedle from "./PatientFedbackPieChartWithNeedle";
import StaffFedbackPieChartWithNeedle from "./StaffFedbackPieChartWithNeedle";

const departmentRadioArr = [
    {
        id: "Consultant",
        label: "Consultant",
        value: "Consultant",
    },
    {
        id: "Staff",
        label: "Staff",
        value: "Staff",
    },
    {
      id: "Patient",
      label: "Patient",
      value: "Patient",
    },
];
function FeedBackDashbord() {
  const defaultValues = {
    departmentValue: "Consultant",
  };
  const { control, watch } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const COLORS = ["#58dc56", "#ff6666", "#E99566", "#DDE032", "#AADF12"];
  const departmentwiseValue = watch("departmentValue");
  // const COLORS = ["green", "red", "skin", "yellow", "yellowgreen"];
  const [departmentWiseData, setDepartmentWiseData] = useState([]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  // department wise api call
  useEffect(() => {
    if (departmentwiseValue !== "") {
      getDepartmentWiseStatisticsForDashboard(departmentwiseValue)
        .then((response) => {
          let tempArr = [];
          if (response.data.statusCode === 200) {
            for (let obj of response.data.result) {
              let tempObj = {
                department: [
                  { name: "Group A", value: obj["Highly Satisfied"] },
                  { name: "Group B", value: obj.Satisfied },
                  { name: "Group C", value: obj.Average },
                  { name: "Group D", value: obj.Poor },
                  { name: "Group E", value: obj.Dissatisfied },
                ].filter((item) => item.value > 0),
                "Department Name": obj["Department Name"],
                "Highly Satisfied": obj["Highly Satisfied"],
                Satisfied: obj.Satisfied,
                Average: obj.Average,
                Poor: obj.Poor,
                Dissatisfied: obj.Dissatisfied,
              };
              tempArr.push(tempObj);
            }
            setDepartmentWiseData(tempArr);
          } else {
            setDepartmentWiseData([]);
          }
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    }
  }, [departmentwiseValue]);


  return (
    <div className="pt-14 overflow-hidden">
      <h1 className="my-2 text-center text-customBlue font-semibold text-lg">
        FeedBack DashBord
      </h1>
      <div className="lg:flex lg:space-x-2 grid gap-2 w-full lg:h-[300px] ">
        <div className="w-full">
          <OpdPatientFeedBackChart />
        </div>
        <div className="w-full">
          <IpdPatientFeedBackChart />
        </div>
      </div>
      <div className="lg:flex my-2 lg:space-x-2 grid gap-2 items-center w-full ">
        <ConsultantFedbackPieChartWithNeedle />
        <StaffFedbackPieChartWithNeedle />
      </div>
      <div>
        <div className="flex space-x-10 items-center ">
          <h2 className="font-semibold my-4 text-center">
            Department - Wise Feedback
          </h2>
          <RadioField
            control={control}
            name="departmentValue"
            dataArray={departmentRadioArr}
          />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2">
          {departmentWiseData?.length > 0 ? (
            departmentWiseData?.map((value, index) => {
              return (
                <div key={value.id} className="border rounded shadow">
                  <div className="flex items-center justify-between ">
                    <div className="border-b-2 w-full bg-green-100">
                      <h3 className="font-semibold px-2 py-1">
                        {value["Department Name"]}
                      </h3>
                    </div>
                    {/* <button className="bg-[#DCEEFF] text-[#073763] border border-[#073763] rounded h-[32px] px-2">
                      More
                    </button> */}
                  </div>
                  <div className="flex items-center justify-between text-sm p-2">
                    <div>
                      <PieChart width={170} height={170}>
                        <Pie
                          data={value.department}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {value.department &&
                            value.department.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS?.length]}
                              />
                            ))}
                        </Pie>
                      </PieChart>
                    </div>
                    <div>
                      <ul className="list-disc whitespace-nowrap text-sm">
                        <li
                          style={{
                            "--tw-text-opacity": 1,
                            color: "#239F21",
                            listStyle: "disc",
                          }}
                          className="marker:text-[24px]"
                        >
                          <h4 className="text-black">Highly Satisfied</h4>
                        </li>
                        <li
                          style={{
                            "--tw-text-opacity": 1,
                            color: "#AADF12",
                            listStyle: "disc",
                          }}
                          className="marker:text-[24px]"
                        >
                          <h4 className="text-black">Satisfied</h4>
                        </li>
                        <li
                          style={{
                            "--tw-text-opacity": 1,
                            color: "#DDE032",
                            listStyle: "disc",
                          }}
                          className="marker:text-[24px]"
                        >
                          <h4 className="text-black">Average</h4>
                        </li>
                        <li
                          style={{
                            "--tw-text-opacity": 1,
                            color: "#E99566",
                            listStyle: "disc",
                          }}
                          className="marker:text-[24px]"
                        >
                          <h4 className="text-black">Poor</h4>
                        </li>
                        <li
                          style={{
                            "--tw-text-opacity": 1,
                            color: "#FF2A2A",
                            listStyle: "disc",
                          }}
                          className="marker:text-[24px]"
                        >
                          <h4 className="text-black">Dissatisfied</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 className="text-center flex justify-center items-center text-gray-600 font-semibold py-24 w-full col-span-4 text-sm">
              Data Is Not Availabale
              <span className="animate-pulse tracking-wider">...</span>
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedBackDashbord;
