import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../.../../../http-common";

export const getDepartmentWiseReports = (postObj) => {
  return apiClient.post(`/feedbackReports/getDepartmentWiseReports`, postObj, {
    headers: authHeader(),
  });
};

export const getIpdWiseReports = (postObj) => {
  return apiClient.post(`/feedbackReports/getIpdWiseReports`, postObj, {
    headers: authHeader(),
  });
};

export const getOpdWiseReports = (postObj) => {
  return apiClient.post(`/feedbackReports/getOpdWiseReports`, postObj, {
    headers: authHeader(),
  });
};
export const getTemplateWiseReports = (postObj) => {
  return apiClient.post(`/feedbackReports/getTemplateWiseReports`, postObj, {
    headers: authHeader(),
  });
};
export const getUserWiseReport = (postObj) => {
  return apiClient.post(`/feedbackReports/getUserWise`, postObj, {
    headers: authHeader(),
  });
};

export const getPatientWiseReport = (postObj) => {
  return apiClient.post(
    `/feedbackReports/getPatientWiseFeedbackReport`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientQuestionWiseReport = (postObj) => {
  return apiClient.post(
    `/feedbackReports/getPatientQuestionWiseFeedbackReport`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

//http://192.168.0.111:5858/api/feedbackReports/getTemplateAutoComplete/tes
export const getTemplateAutoComplete = (searchString) => {
  return apiClient.get(
    `/feedbackReports/getTemplateAutoComplete/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDepartmentAutoComplete = (searchString) => {
  return apiClient.get(
    `/feedbackReports/getDepartmentAutoComplete/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

//

export const getTemplatesForPTQuestionWiseReport = (searchString) => {
  return apiClient.get(
    `/feedbackReports/getTemplatesForPTQuestionWiseReport/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

