import * as React from "react";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Paper, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { updateNonPathTempStatus } from "../../services/radiology/RadiologyTemplateMasterServices";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function RadiologyTemplateMasterTable(props) {
  const {
    dataResult,
    setDataResult,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    templateId,
    tableApiFunc,
    setOpenTemplateModal,
    setEditRowData,
    getTemplateList,
  } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [oldCount, setOldCount] = useState();
  const [checkedStatusInfo, setCheckedStatusInfo] = useState(null);
  const [statusConfirmation, setStatusConfirmarion] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState();
  const [confirmText, setConfirmText] = useState();
  const handleCloseStatusConfirmation = () => {
    setStatusConfirmarion(false);
  };

  React.useEffect(() => {
    checkCallApi(page, rowsPerPage, count);
  }, [page, rowsPerPage, count]);

  const checkCallApi = (pageNo, rowsPerPageNo, oldTotal) => {
    pageNo = pageNo + 1;
    let newTotal = pageNo * rowsPerPageNo;

    let arr = [...dataResult];
    let totalDataLength = count;
    let availableDataLength = arr.length;
    if (totalDataLength > availableDataLength) {
      if (newTotal > availableDataLength) {
        //
        let require = newTotal - availableDataLength;
        let toShow = availableDataLength + require;

        let needed;
        if (toShow < totalDataLength) {
          needed = newTotal - oldTotal;
          callAgainTableApi(needed);
        } else if (toShow > totalDataLength) {
          needed = toShow - totalDataLength;
          callAgainTableApi(needed);
        } else {
          needed = rowsPerPageNo;
          callAgainTableApi(needed);
        }
      }
    }
  };
  const callAgainTableApi = (recordsNeeded) => {
    let getTemplateId = templateId ? templateId : 0;
    let pages = page ? page : 0;
    let size = rowsPerPage;
    tableApiFunc(getTemplateId, pages, size)
      .then((response) => response.data)
      .then((res) => {
        let incomingData = res.result;
        let onlyNeededData = incomingData.slice(-Math.abs(recordsNeeded));

        let existData = [...dataResult];
        for (let value of onlyNeededData) {
          existData.push(value);
        }
        setDataResult(existData);
      });
  };

  const handlePageChange = (event, newPage) => {
    setOldCount((page + 1) * rowsPerPage);
    setPage(parseInt(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setOldCount((page + 1) * rowsPerPage);
    let newRows = parseInt(event.target.value, 10);
    let newTotal = (page + 1) * newRows;
    let additionalRecord = newTotal - count;
    if (additionalRecord > newRows) {
      setPage(page - 1);
    }
    setRowsPerPage(newRows);
  };

  const updateTemplateStatus = () => {
    updateNonPathTempStatus(
      checkedStatusInfo?.templateId,
      checkedStatusInfo?.status
    ).then((response) => {
      setPage(0);
      getTemplateList();
    });
    setStatusConfirmarion(false);
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TablePagination
              sx={{
                ".MuiTablePagination-toolbar": {
                  minHeight: "30px",
                },
              }}
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 20,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-full"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Template Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Template Name
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Status
                      </span>
                    </TableCell>

                    {/* <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Template Id
                      </span>
                    </TableCell> */}

                    {/* <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Template Detld Id
                      </span>
                    </TableCell> */}

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Is Approved
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stableSort(dataResult, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //splice use for show rows upto 5 when splice is not use that time show all rows
                    .map((data, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "& td": {
                              paddingY: 0.5,
                            },
                          }}
                        >
                          <>
                            <TableCell className="flex whitespace-nowrap">
                              <Tooltip title="Edit">
                                <div
                                  value="click"
                                  className="text-blue-900 mr-1"
                                >
                                  <DriveFileRenameOutlineIcon
                                    onClick={(e) => {
                                      setEditRowData(data);
                                      setOpenTemplateModal(true);
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell className="whitespace-nowrap">
                              {data.TemplateCode}
                            </TableCell>
                            <TableCell className="whitespace-nowrap">
                              {data.TemplateName}
                            </TableCell>
                            <TableCell className="whitespace-nowrap">
                              <div
                                onClick={(e) => {
                                  let obj = {};
                                  if (e.target.innerHTML === "InActive") {
                                    obj.status = false;
                                  } else if (e.target.innerHTML === "Active") {
                                    obj.status = true;
                                  }
                                  obj.templateId = data.TemplateId;
                                  setCheckedStatus(e.target.innerHTML);
                                  setCheckedStatusInfo(obj);
                                  setStatusConfirmarion(true);
                                }}
                              >
                                {data.Status === false ||
                                (data.Status === false &&
                                  checkedStatus === "InActive" &&
                                  confirmText === "Add") ? (
                                  <button className="border border-green-800 text-center rounded w-20 text-green-800">
                                    Active
                                  </button>
                                ) : data.Status === true ||
                                  (data.Status === true &&
                                    checkedStatus === "Active" &&
                                    confirmText === "Add") ? (
                                  <button className="border border-red-500 text-center rounded w-20 text-red-500">
                                    InActive
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </TableCell>
                            {/* <TableCell className="whitespace-nowrap">
                              {data.TemplateId}
                            </TableCell> */}
                            {/* <TableCell className="whitespace-nowrap">
                              {0}
                            </TableCell> */}
                            <TableCell className="whitespace-nowrap">
                              {data.IsApproved === true ? "True" : "False"}
                            </TableCell>
                          </>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
      <div
        onClick={(e) => {
          setConfirmText(e.target.innerText);
        }}
      >
        {checkedStatusInfo?.status === false ? (
          <ConfirmationModal
            confirmationOpen={statusConfirmation}
            confirmationHandleClose={handleCloseStatusConfirmation}
            confirmationSubmitFunc={updateTemplateStatus}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure to Re-Activate this Template ?"
            confirmationButtonMsg="Add"
          />
        ) : (
          <ConfirmationModal
            confirmationOpen={statusConfirmation}
            confirmationHandleClose={handleCloseStatusConfirmation}
            confirmationSubmitFunc={updateTemplateStatus}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure to De-Activate this Template  ?"
            confirmationButtonMsg="Add"
          />
        )}
      </div>
    </>
  );
}
