import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//vacant Beds
export const saveBloodRequisitionNote = (finalObj) => {
  return apiClient.post(`/bloodBank/saveBloodRequisitionNote`, finalObj, {
    headers: authHeader(),
  });
};

export const bloodRequisitionNotePrint = (admissionId,requisitionId) => {
  return apiClient.get(`/bloodBank/bloodRequisition/${admissionId}/${requisitionId}`, {
    headers: authHeader(),
  });
};
