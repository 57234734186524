import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

import { Checkbox } from "@mui/material";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchMRDCheckPatientList,
  saveMrdCheckList,
} from "../../services/mrdChecklistServices/MrdChecklistServices";
import MrdChecklistView from "./MrdChecklistView";
import MrdRemarkModal from "./MrdRemarkModal";

const MrdChecklist = () => {
  let location = useLocation();

  //
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  //
  const [openChecklistViewModal, setOpenChecklistViewModal] = useState(false);

  //Remark dtls modal
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const handleOpenRemarkModal = () => setOpenRemarkModal(true);
  const handleCloseRemarkModal = () => setOpenRemarkModal(false);
  //
  const [privilege, setPrivilege] = useState("");
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      // toDate: new Date(),
      // fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  //
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  //
  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate]);

  const populateTable = (forPagination) => {
    const listObj = {
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchMRDCheckPatientList(listObj)
      .then((response) => response.data)
      .then((res) => {
        let updatedRes = res.result.map((item) => ({
          ...item,
          isChecked: false,
        }));
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...updatedRes]);
        } else {
          setDataResult(updatedRes);
          setPage(0);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      });
  };

  ////////table related
  const renderActions = (row, index) => {
    const handleActionCheck = (row) => {
      setDataResult((prevData) =>
        prevData.map((item) =>
          item.AdmissionId === row.AdmissionId
            ? { ...item, isChecked: !item.isChecked }
            : item
        )
      );
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <Checkbox
            label="Read"
            size="small"
            checked={row.isChecked}
            onChange={() => {
              handleActionCheck(row, index);
            }}
          />
        </div>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    const handleInputCheck = (row, index, header, e) => {
      setSelectedHeader(header);
      setSelectedRow(row);

      setDataResult((prevData) =>
        prevData.map((item) =>
          item.AdmissionId === row.AdmissionId
            ? { ...item, [header]: !item[header] }
            : item
        )
      );

      !e.target.checked && setOpenRemarkModal(true);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <Checkbox
            label="Read"
            size="small"
            checked={row[header]}
            onChange={(e) => {
              handleInputCheck(row, index, header, e);
            }}
          />
        </div>
      </>
    );
  };

  const handleSubmit = () => {
    let finalData = dataResult
      .filter((item) => item.isChecked)
      .map((item) => {
        return {
          admDate: item.AdmissionDate + "T00:00:00.000Z",
          admDoc: item["Admission Documents"],
          admissionId: item.AdmissionId,
          billingDoc: item["Billing Sheet"],
          consentDoc: item["Consent Forms"],
          dischargeDoc: item["Discharge Summary"],
          firstName: item.FirstName,
          historyDoc: item["History Sheet"],
          labDoc: item["Lab Report"],
          lastName: item.LastName,
          medicationDoc: item["Medication Chart"],
          middleName: item.MiddleName,
          mrNo: item.MRNo,
          orderDoc: item["order Sheet"],
          otDoc: item["OT Docs"],
          radiologyDoc: item["Radiology Report"],
        };
      });

    setOpenBackdrop(true);

    saveMrdCheckList(finalData, location?.state?.menuId, "Create")
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-center text-black font-bold text-xl col-span-9 mb-2">
          MRD Checklist
        </div>
        <div className="grid grid-cols-6 gap-3 ">
          {/* from Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>

          {/* to Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>

          <div className="md:col-span-2 lg:col-span-4 grid justify-end">
            <CommonButton
              label="View"
              className="bg-customBlue text-white"
              onClick={() => {
                setOpenChecklistViewModal(true);
              }}
            />
          </div>
        </div>
        <div>
          {dataResult.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              tableClass="h-96 lg:h-[580px] 2xl:h-[580px]"
              renderActions={renderActions}
              renderInput={renderInput}
              highlightRow={false}
              removeHeaders={[
                "id",
                "AdmissionDate",
                "AdmissionId",
                "FirstName",
                "LastName",
                "MiddleName",
                "mrdchecklist",
                "mrdchecklist",
                "isChecked",
              ]}
              editableColumns={[
                "Admission Documents",
                "Billing Sheet",
                "Consent Forms",
                "Discharge Summary",
                "History Sheet",
                "Lab Report",
                "Medication Chart",
                "OT Docs",
                "Radiology Report",
                "order Sheet",
              ]}
            />
          ) : (
            <p className="text-center my-6 ">No Data Found</p>
          )}
        </div>
        {dataResult.length > 0 && (
          <div className="col-span-2 flex justify-end gap-2">
            <CommonButton
              label="Reset"
              className="border border-customRed text-customRed"
              onClick={() => {
                populateTable();
              }}
            />
            <CommonButton
              label="Save"
              className="bg-customGreen text-white"
              onClick={() => {
                setOpenConfirmationModal(true);
              }}
            />
          </div>
        )}
      </div>
      {openRemarkModal ? (
        <MrdRemarkModal
          open={openRemarkModal}
          setOpen={setOpenRemarkModal}
          handleOpen={handleOpenRemarkModal}
          handleClose={handleCloseRemarkModal}
          selectedRow={selectedRow}
          populateTable={populateTable}
          selectedHeader={selectedHeader}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {openChecklistViewModal ? (
        <MrdChecklistView
          open={openChecklistViewModal}
          setOpen={setOpenRemarkModal}
          handleOpen={() => setOpenChecklistViewModal(true)}
          handleClose={() => setOpenChecklistViewModal(false)}
          selectedRow={selectedRow}
          populateTable={populateTable}
          selectedHeader={selectedHeader}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default MrdChecklist;
