import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { CancelOnIcon, EditOnIcon } from "../../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import OtBookingListPrint from "../../../prints/OtBookingListPrint";
import {
  fetchOtReservedPatientList,
  fetchOtTables,
  fetchPrintOtReservationList,
  fetchUnReservationOtFlag,
} from "../../../services/otReservationServices/OtReservationServices";
import OtLayoutIcon from "../otLayout/otMachineImages/layout.svg";
import OtLayout from "../OtLayout";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

export default function OtReservationListing(props) {
  const { open, handleClose, userActions, handlePatchData } = props;

  const location = useLocation();
  const [privilege, setPrivilege] = useState();
  //
  const [selectedRowData, setSelectedRowData] = React.useState(null);

  //dates
  // const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  // const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [selctedOtTable, setSelctedOtTable] = React.useState(null);
  const [otTables, setOtTables] = React.useState([]);
  const [
    reservedPatientTableData,
    setReservedPatientTableData,
  ] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState();

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  //
  const [
    openlUnReservationConfirmationModal,
    setOpenlUnReservationConfirmationModal,
  ] = useState(false);

  // otLayoutModal
  const [openOtLayoutModal, setOpenOtLayoutModal] = React.useState(false);
  const handleOpenOtLayoutModal = () => setOpenOtLayoutModal(true);
  const handleCloseOtLayoutModal = () => setOpenOtLayoutModal(false);
  //

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      toDate: new Date(),
      fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  //ipd issue details table data
  // useEffect(() => {
  //   getOtReservedPatientLis();
  // }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    fetchOtTables()
      .then((response) => response.data)
      .then((res) => {
        setOtTables(res.result);
      });
  }, []);

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      getOtReservedPatientLis();
  }, [FromDate, ToDate, selctedOtTable]);

  const getOtReservedPatientLis = (forPagination) => {
    const listObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      otTableId: selctedOtTable || 0,
    };

    fetchOtReservedPatientList(listObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setCount(res.count);
        if (forPagination) {
          setReservedPatientTableData((prevData) => [
            ...prevData,
            ...res.result,
          ]);
        } else {
          setReservedPatientTableData(res.result);
          setPage(0);
        }
      });
  };

  // const getOtReservedPatientLis = () => {
  //   const obj = {
  //     fromDate: selectedFromDate,
  //     otTableId: 0,
  //     toDate: selectedToDate,
  //   };

  //   fetchOtReservedPatientList(obj)
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setReservedPatientTableData(res.result);
  //     });
  // };

  //////print

  //////
  const handleUnReservation = (row) => {
    fetchUnReservationOtFlag(
      row.OTReservationId,
      location?.state?.menuId,
      privilege
    )
      .then((response) => response.data)
      .then(() => {
        successAlert("UnReserved OT Successfully...");
        getOtReservedPatientLis();
      });
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-1 items-center cursor-pointer">
                  {actions.isAction && actions.action === "Cancel" && (
                    <>
                      <CancelOnIcon
                        title="UnReserve"
                        onClick={() => {
                          setOpenlUnReservationConfirmationModal(true);
                          setPrivilege(actions.action);
                          setSelectedRowData(row);
                        }}
                      />
                    </>
                  )}
                  {/*actions.isAction && actions.action === "Cancel" && (
                    <>
                      <EditOnIcon
                        title="Edit"
                        onClick={() => {
                          setPrivilege(actions.action);
                          setSelectedRowData(row);
                          handlePatchData(row, index);
                        }}
                      />
                    </>
                      )*/}
                  {actions.isAction && actions.action === "Cancel" && (
                    <>
                      <Tooltip title={"OT Layout"} className="mt-1">
                        <img
                          src={OtLayoutIcon}
                          onClick={() => {
                            handleOpenOtLayoutModal();
                            setSelectedRowData(row);
                            setPrivilege(actions.action);
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 3000);

    return (
      <div className="hidden">
        <OtBookingListPrint
          fromDate={format(FromDate, "yyyy-MM-dd")}
          toDate={format(ToDate, "yyyy-MM-dd")}
          otTableId={selctedOtTable || 0}
        />
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                OT Booking List
              </span>
            </div>
            <hr className="border my-2 mx-1  border-blue-900" />

            {/* filters */}
            <div className="grid gap-2 w-full items-center mt-4">
              <div className="grid grid-cols-4 lg:grid-cols-12 w-full gap-2 items-center gap-3">
                <div className="grid grid-cols-2 col-span-4 gap-3">
                  {/* From Date */}
                  {/* from Date */}
                  <div className="md:col-span-1 lg:col-span-1">
                    <DatePickerFieldNew
                      control={control}
                      name="fromDate"
                      label="From Date"
                      value={new Date()}
                      disableFuture={false}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>

                  {/* to Date */}
                  <div className="md:col-span-1 lg:col-span-1">
                    <DatePickerFieldNew
                      control={control}
                      name="toDate"
                      label="To Date"
                      value={new Date()}
                      disableFuture={false}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>

                <div className="md:col-span-2 lg:col-span-2">
                  <DropdownField
                    control={control}
                    name="otTable"
                    label="OT Table"
                    placeholder="OT Table"
                    isClearable={true}
                    dataArray={otTables}
                    inputRef={{
                      ...register("otTable", {
                        onChange: (e) => {
                          if (e.target.value) {
                            setSelctedOtTable(e.target.value.id);
                          } else {
                            setSelctedOtTable(null);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                {/* ///print button */}
                <div className="flex justify-end space-x-2 md:grid-cols-2 md:col-span-2 lg:col-span-6 ">
                  <div>
                    {props.userActions.map((actions, i) => (
                      <>
                        {!actions.isAction &&
                          reservedPatientTableData.length > 0 && (
                            <>
                              {actions.action === "Print" && (
                                <Tooltip title={"Print OT Reservation List"}>
                                  <LocalPrintshopIcon
                                    sx={{ color: "#4B5563", marginTop: "7px " }}
                                    onClick={(e) => {
                                      setOpenPrintModal(true);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </>
                          )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* tables */}
            <div className="mt-2">
              {reservedPatientTableData.length > 0 ? (
                <>
                  <CommonDynamicTablePaginationNew
                    dataResult={reservedPatientTableData}
                    populateTable={getOtReservedPatientLis}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    renderActions={renderActions}
                    tableClass=" h-96 2xl:h-[450px]"
                    removeHeaders={[
                      "ProcedureId",
                      "OPIPNO",
                      "OPD_IPD_External_Id",
                      "Type",
                      "UnreserveFlag",
                      "OTReservationId",
                      "Opd_Ipd_External",
                      "SurgeonId",
                      "PatientId",
                      "DoctorName",
                      "",
                    ]}
                    highlightRow={false}
                  />
                </>
              ) : (
                <div className="grid justify-center my-10 mb-96">
                  Data Not Found
                </div>
              )}
            </div>
            {/* buttons */}
            <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  onClick={handleClose}
                  label="Close"
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* ///unreservation modal */}
      <Modal open={openlUnReservationConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              OT UnReservation
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To UnReserve ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setOpenlUnReservationConfirmationModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      handleUnReservation(selectedRowData);
                      setOpenlUnReservationConfirmationModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {openOtLayoutModal ? (
        <OtLayout
          open={openOtLayoutModal}
          setOpen={setOpenOtLayoutModal}
          handleOpen={handleOpenOtLayoutModal}
          handleClose={handleCloseOtLayoutModal}
          // userActions={userActions}
          // privilege={privilege}
          selectedRowData={selectedRowData}
          isFromForm={false}
          isEditLayout={true}
        />
      ) : null}

      {/*  Print Modal */}
      {openPrintModal && renderPrint()}
    </div>
  );
}
