import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const fetchFolderStructureForHrDms = () => {
  return apiClient.get(`/documentManagement/getFolderStructureForHrDms`, {
    headers: authHeader(),
  });
};

export const fetchHrmsDoc = (folderId) => {
  return apiClient.get(`/documentManagement/getDoc/${folderId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};

export const uploadHrdmsDocument = (finalObj) => {
  return apiClient.post(`/documentManagement/uploadDocument`, finalObj, {
    headers: authHeader(),
  });
};