import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import InputArea from "../../../../common/components/FormFields/InputArea";

const OtherHistory = () => {
  const { control } = useFormContext();
  return (
    <div className=" grid grid-cols-2 gap-3">
      <div className=" ">
        <InputArea
          label="Bowel & Bladder"
          placeholder="Bowel & Bladder"
          name="bowelBladder"
          rows={3}
          control={control}
        />
      </div>
      <div className=" ">
        <InputArea
          label="Blood Transfusion History"
          placeholder="Blood Transfusion History"
          name="bloodTransfusionHistory"
          rows={3}
          control={control}
        />
      </div>
      <div className=" ">
        <InputArea
          control={control}
          name="allergy"
          label="Allergy"
          placeholder="Allergy"
          rows={3}
        />
      </div>
      <div className=" ">
        <InputArea
          label="Personal History"
          placeholder="Personal History"
          name="personalHistory"
          rows={3}
          control={control}
        />
      </div>
    </div>
  );
};

export default OtherHistory;
