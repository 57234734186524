import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

// Autocomplete
export const fetchAutocompleteForOtDtls = (
  searchString,
  againstReservation,
  opdIpd
) => {
  //
  return apiClient.get(
    `/otDetails/patientAutoComplete/${searchString}/${againstReservation}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

//patient Information
export const fetchPatientInformationForOtDtls = (patientId, opdIpd) => {
  return apiClient.get(
    `/otDetails/patientInfoForOTDtls/${patientId}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

//fetchTheater
export const fetchTheater = () => {
  return apiClient.get(`/otDetails/otTheater`, {
    headers: authHeader(),
  });
};

//fetchAneas
export const fetchAneas = () => {
  return apiClient.get(`/otDetails/getAnaes`, {
    headers: authHeader(),
  });
};

//fetchProcedureType
export const fetchProcedureType = () => {
  return apiClient.get(`/otDetails/otProcedureType`, {
    headers: authHeader(),
  });
};

//fetchOtResult
export const fetchOtResult = () => {
  return apiClient.get(`/otDetails/otResult`, {
    headers: authHeader(),
  });
};

//fetchOtStatus
export const fetchOtStatus = () => {
  return apiClient.get(`/otDetails/otStatus`, {
    headers: authHeader(),
  });
};

//fetchOtNurse
export const fetchOtNurse = () => {
  return apiClient.get(`/otDetails/getNurse`, {
    headers: authHeader(),
  });
};

//fetchOtPhysician
export const fetchOtPhysician = () => {
  return apiClient.get(`/otDetails/getPhysician`, {
    headers: authHeader(),
  });
};

//fetchOTCategory
export const fetchOTCategory = () => {
  return apiClient.get(`/otDetails/getOTCategory`, {
    headers: authHeader(),
  });
};

//fetchOTCategory
export const fetchOtTemplates = () => {
  return apiClient.get(`/otDetails/otTemplates`, {
    headers: authHeader(),
  });
};

//fetchOtServices
export const fetchOtServices = (otServiceObj) => {
  return apiClient.post(`/services/search`, otServiceObj, {
    headers: authHeader(),
  });
};

//save Ot dtls
export const saveOtDetails = (finalObj) => {
  return apiClient.post(`/otDetails/saveOTDetails`, finalObj, {
    headers: authHeader(),
  });
};

//Listing
export const fetchOtDetailsListing = (selPatientObj) => {
  return apiClient.post(`/otDetails/otPTDetailsList`, selPatientObj, {
    headers: authHeader(),
  });
};

//services regarding patch data

export const fetchOtDoctorList = (otReservationId) => {
  return apiClient.get(`/otDetails/doctorsList/${otReservationId}`, {
    headers: authHeader(),
  });
};

export const fetchOtEmployeeList = (otReservationId) => {
  return apiClient.get(`/otDetails/employeeList/${otReservationId}`, {
    headers: authHeader(),
  });
};

export const fetchSurgeryDetails = (otReservationId, tariffId, classId) => {
  return apiClient.get(
    `/otDetails/surgeryDetails/${otReservationId}/${tariffId}/${classId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchReservedSurgery = (otReservationId) => {
  return apiClient.get(`/otDetails/reservedSurgery/${otReservationId}`, {
    headers: authHeader(),
  });
};

export const fetchOtDetailsViewData = (otHeaderId, opdIpd) => {
  return apiClient.get(`/otDetails/otDetails/${otHeaderId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

export const fetchCancelOTStatus = (otHeaderId, menuId, privilege) => {
  return apiClient.get(
    `/otDetails/cancelOTDetails/${otHeaderId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};
export const deleteOTFiles = (deleteDocObj) => {
  return apiClient.post(`/otDetails/deleteOTFiles`, deleteDocObj, {
    headers: authHeader(),
  });
};

///prints

export const fetchPrintOtDetails = (otHeaderId) => {
  return apiClient.get(`/reports/ot/operationDetails/${otHeaderId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

///issued items services

export const fetchItemsForIssue = (searchString, storeId) => {
  return apiClient.get(
    `/otDetails/getItemsForOTIssue/${searchString}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchItemsForIssueByKit = (kitId, storeId) => {
  return apiClient.get(`/otDetails/getKitItemsForOTIssue/${kitId}/${storeId}`, {
    headers: authHeader(),
  });
};

export const saveOtIssuedItems = (menuId, privilege, IssueItemObj) => {
  return apiClient.post(
    `/otDetails/saveIssuedItems/${menuId}/${privilege}`,
    IssueItemObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchIssuedItemsList = (otHeaderId, surgeryId) => {
  return apiClient.get(
    `/otDetails/getIssuedItemsList/${otHeaderId}/${surgeryId}`,
    {
      headers: authHeader(),
    }
  );
};
export const otReservationDetails = (otReservationId) => {
  return apiClient.get(`/otDetails/otReservationDetails/${otReservationId}`, {
    headers: authHeader(),
  });
};
export const fetchOTDetailsSurgeryList = (otHeaderId) => {
  return apiClient.get(`/otDetails/getOTDetailsSurgeryList/${otHeaderId}`, {
    headers: authHeader(),
  });
};

// Prints
// OT Note Print
// /api/reports/ot/operationNotes/{otDetailsId}   528278
export const OperationNotesPrintData = (otDetailsId) => {
  return apiClient.get(`/reports/ot/operationNotes/${otDetailsId}`, {
    headers: authHeader(),
  });
};

export const ItemIssuedListPrintData = (postObj) => {
  return apiClient.post(`/reports/ot/getOtIssuedItems`, postObj, {
    headers: authHeader(),
  });
};

export const OperationDetailsPrintData = (otDetailsId) => {
  return apiClient.get(`/reports/ot/operationDetails/${otDetailsId}`, {
    headers: authHeader(),
  });
};
export const fetchPendingImplants = (otHeaderId) => {
  return apiClient.get(`/otDetails/getPendingImplants/${otHeaderId}`, {
    headers: authHeader(),
  });
};

export const savePendingImplants = (finalObj) => {
  return apiClient.post(`/otDetails/savePendingImplants`, finalObj, {
    headers: authHeader(),
  });
};

export const fetchAntibiotics = () => {
  return apiClient.get(`/otDetails/getAntibiotics`, {
    headers: authHeader(),
  });
};

export const saveSurgeryNotes = (finalObj) => {
  return apiClient.post(
    `otDetails/saveSurgeryNotes`,finalObj,
    {
      headers: authHeader(),
    }
  );
};

export const saveAnesthesiaNotes = (finalObj) => {
  return apiClient.post(`otDetails/saveAnesthesiaNotes`, finalObj, {
    headers: authHeader(),
  });
};

