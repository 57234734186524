import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchEtuCasePatientInfo,
  fetchEtuCasePatientSearch,
  fetchEtuCaseSheetData,
  fetchEtuCaseSheetPrint,
  postEtuCaseSheetData,
} from "../../services/etuCaseSheetServices/EtuCaseSheetServices";
import GeneralExamination from "./GeneralExamination";
import LocalExamination from "./LocalExaminations";
import MedicationPrescriptionModal from "./MedicationPrescriptionModal";
import MlcAndMedications from "./MlcAndMedications";
import PastHistory from "./PastHistory";
import SystematicExamination from "./SystematicExamination";
// import MLCModalEdit from "../admission/commonModal/MLCModalEdit";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { format, isValid, parseISO } from "date-fns";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import InputArea from "../../../common/components/FormFields/InputArea";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import TestView from "./TestView";

const IpdEmergency = [
  {
    id: "0",
    value: "ipd",
    label: "IPD",
  },
  {
    id: "1",
    value: "emergency",
    label: "Emergency",
  },
];

const EtuCaseSheet = () => {
  let location = useLocation();
  //let navigate = useNavigate();

  const [userActions, setUserActions] = useState([]);
  const [previlage, setPrevilage] = useState("");
  //
  const [generatedEtuCSId, setGeneratedEtuCSId] = useState(null); //@ save

  let a = new Date();
  const [arrivalDate, setArrivalDate] = useState(a);
  const [arrivalTime, setArrivalTime] = useState(new Date());
  const [handOverTime, setHandOverTime] = useState(new Date());

  const handleChangeDate = (newValue) => {
    // let date =format(newValue, "yyyy-MM-dd'T'HH:mm:ss") //newValue.toLocaleDateString();

    setArrivalDate(newValue);
  };

  useEffect(() => {}, [arrivalDate]);

  //patchData

  const [patchData, setPatchData] = React.useState(null);
  //mlc modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [mlcModalData, setMlcModalData] = useState(null);
  //autosearch
  const [autosearch, setAutoSearch] = useState();
  const [searchString, setSearchString] = useState();

  //patientData
  const [patientId, setPatientId] = useState(null); ///used for emergency id
  const [patientData, setPatientData] = useState(null);
  const [admissionId, setAdmissionId] = useState(0);
  const [poulateData, setPoulateData] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);

  //gcsScore
  const [gcsScore, setGcsScore] = React.useState(0);
  const [mewsScore, setMewsScore] = React.useState(0);
  const [gcsEVM, setGcsEVM] = useState();

  //Confirmation modal
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //prescription modal
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const handleOpenPrescriptionModal = () => setOpenPrescriptionModal(true);
  const handleClosePrescriptionModal = () => setOpenPrescriptionModal(false);
  const [allMedicationData, setAllMedicationData] = useState([]);
  const [deletedMedicationData, setDeletedMedicationData] = useState([]);
  const [medicationData, setMedicationData] = useState([]);

  // dto
  const [caseSheetDtoState, setCaseSheetDtoState] = useState();
  const [localExaminationsDtoState, setLocalExaminationsDtoState] = useState();

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = () => {
    patientData !== null &&
      fetchEtuCaseSheetPrint(
        patientData?.AdmissionId !== null ? patientData?.AdmissionId : 0,
        patientData?.EmergencyPatientId
      ).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });
  };
  //
  const [etuCaseSheetData, setEtuCaseSheetData] = React.useState();

  //accordian

  const [expandPanal2, setExpandPanal2] = useState(true);
  const [expandPanal3, setExpandPanal3] = useState(true);
  const [expandPanal4, setExpandPanal4] = useState(true);
  const [expandPanal5, setExpandPanal5] = useState(true);
  const [expandPanal6, setExpandPanal6] = useState(true);
  const [expandPanal7, setExpandPanal7] = useState(true);
  const [expandPanal8, setExpandPanal8] = useState(true);
  const [expandPanal18, setExpandPanal18] = useState(true);

  const handleChangePanal2 = () => {
    setExpandPanal2(!expandPanal2);
  };
  const handleChangePanal3 = () => {
    setExpandPanal3(!expandPanal3);
  };
  const handleChangePanal4 = () => {
    setExpandPanal4(!expandPanal4);
  };
  const handleChangePanal5 = () => {
    setExpandPanal5(!expandPanal5);
  };
  const handleChangePanal6 = () => {
    setExpandPanal6(!expandPanal6);
  };
  const handleChangePanal7 = () => {
    setExpandPanal7(!expandPanal7);
  };
  const handleChangePanal8 = () => {
    setExpandPanal8(!expandPanal8);
  };

  const handleChangePanal18 = () => {
    setExpandPanal18(!expandPanal18);
  };

  const handleSearch = (e) => {
    //
    setSearchString(e);
  };

  const refreshDefaultValues = {
    ipdEmergency: "0",
    isMlc: false,
    // generalExamination
    temp: "",
    pulse: "",
    bp: "",
    spo2: "",
    weight: "",
    height: "",
    respiration: "",
    bsl: "",
    saturationWithO2: "",
    saturationWithoutO2: "",
    oxyRatePerMin: "",
    pews: "",
    gcsScore: "",
    avpu: "",
    MewsRadio: { id: mewsScore, label: mewsScore, value: mewsScore },
    mewsScore: "",
    peripheral: false,
    central: false,
    rt: false,
    foleys: false,
    otherLines: false,
    //past history
    hypothyroidism: false,
    hypothyroidism_dtls: null,
    dm: false,
    dmDtls: "",
    htn: false,
    htnDtls: "",
    heartDisease: false,
    heartDiseaseDtls: "",
    tb: false,
    tbDtls: "",
    copd: false,
    copdDtls: "",
    asthama: false,
    asthamaDtls: "",
    liver: false,
    liverDtls: "",
    other: false,
    otherDtls: "",
    // allergies/chiefComplaints
    allergies: "",
    chiefComplaints: "",
    //surgicalHistory
    surgicalHistory: "",
    //systematic Examination
    rs: "",
    cvs: "",
    cns: "",
    pa: "",
    menstrualhistory: "",
    //local Examination
    localExamination: "",
    injuriesDetails: "",
    provisionalDignosis: "",
    clw: false,
    abrasion: false,
    bruise: false,
    //Mlc & Medications
    handedOverBy: "",
    handedOverTo: "",
    // handOverTime:"",
    ptBelongingDocuments: "",
    specialInstructions: "",
    labSentFromCasualty: "",
    pendingInvestigations: "",
    //
  };

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      // searchEtuPatient: null,
      ipdEmergency: "0",
      isMlc: false,
      // generalExamination
      temp: "",
      pulse: "",
      bp: "",
      spo2: "",
      weight: "",
      height: "",
      respiration: "",
      bsl: "",
      saturationWithO2: "",
      saturationWithoutO2: "",
      oxyRatePerMin: "",
      pews: "",
      gcs: "",
      MewsRadio: "",
      mewsScore: "",
      mewsAlert: "",
      peripheral: false,
      central: false,
      rt: false,
      foleys: false,
      otherLines: false,
      firstDose: false,
      secondDose: false,
      boosterDose: false,
      isOnBloodThinner: false, //newKey
      //past history
      hypothyroidism: false,

      hypothyroidism_dtls: "",
      dm: false,
      dmDtls: "",
      htn: false,
      htnDtls: "",
      heartDisease: false,
      heartDiseaseDtls: "",
      tb: false,
      tbDtls: "",
      copd: false,
      copdDtls: "",
      asthama: false,
      asthamaDtls: "",
      liver: false,
      liverDtls: "",
      other: false,
      otherDtls: "",
      // allergies/chiefComplaints
      allergies: "",
      chiefComplaints: "",
      //surgicalHistory
      surgicalHistory: "",
      //systematic Examination
      rs: "",
      cvs: "",
      cns: "",
      pa: "",
      menstrualhistory: "",
      //local Examination
      burnPercentage: "",
      localExamination: "",
      injuriesDetails: "",
      provisionalDignosis: "",
      clw: false,
      abrasion: false,
      bruise: false,

      //test view
      pendingInvestigations: "",
      labSentFromCasualty: "",
      transferTo: "",
      doctorName: "",
      referance: "",
      informedToConsultant: false,
      informedToConsultantDtls: "",
      notInformedToConsultant: false,
      notInformedToConsultantDtls: "",
      otBooked: false,

      //Mlc & Medications
      handedOverBy: "",
      handedOverTo: "",
      // handOverTime:"",
      ptBelongingDocuments: "",
      specialInstructions: "",
      //
    },
  });
  const {
    formState: { errors },
    control,
    watch,
    register,
    setValue,
    reset,
  } = methods;

  //mlc
  // let IsMlc = watch("isMlc");
  //

  // const StateIsMlc = () => {
  //   if (IsMlc === true) {
  //     setOpenModal(IsMlc);
  //   }
  // };

  // useEffect(() => {
  //   if (IsMlc) {
  //     StateIsMlc();
  //   }
  // }, [IsMlc]);

  ///mewsRadio & score
  let Avpu = watch("mewsRadio");

  let getMews;

  // useEffect(() => {
  //   setMewsScore(MewsScore);
  // }, [MewsScore]);

  const caseSheetDto = {
    gcs_e: 0,
    gcs_v: 0,
    gcs_m: 0,

    hypothyroidism: true,
    hypothyroidism_dtls: null,

    allergicTo: null,
    asthma: null,
    asthma_dtls: null,
    booster: 0,
    bp: null,
    bsl: null,
    central: 0,
    cheifComplaints: null,
    cns: null,
    copd: null,
    copd_dtls: null,
    cvs: null,
    dm: null,
    dm_dtls: null,
    firstDose: 0,
    foleys: 0,
    gcs: null,
    heartDisease: null,
    heartDisease_dtls: null,
    height: 0,
    htn: null,
    htn_dtls: null,
    liver: null,
    liver_dtls: null,
    menstrualHistory: null,
    avpu: 0,
    mews: null,
    other: 0,
    otherLine: 0,
    other_dtls: null,
    oxygenRatePerMin: null,
    pa: null,
    peripheral: 0,
    pews: null,
    pulse: null,
    respRate: null,
    rs: null,
    rt: 0,
    saturationWithO2: null,
    saturationWithoutO2: null,
    secondDose: 0,
    spo2: null,
    surgicalHistory: null,
    tb: null,
    tb_dtls: null,
    temp: null,
    weight: 0,
  };

  const localExaminationsDto = {
    abrasion: 0,
    bruise: 0,
    clw: 0,
    handedOverBy: null,
    burnPercentage: null,
    handedOverTime: new Date(),
    handedOverTo: null,
    injuriesDetails: null,
    localExamination: null,
    provisionalDiagnosis: null,
    ptBelongingDocuments: null,
    specialInstructions: null,
  };

  const finaleObject = {
    emergencyId: 0,
    addedBy: 0,
    addedDate: "",
    admissionId: 0,
    etuCaseSheetId: 0,
    arrivalDate: "",
    arrivalTime: "",
    caseSheetDto: {},
    localExaminationsDto: {},
    //test view
    pendingInvestigation: "",
    specialInstruction: "",
    transferTo: "",
    doctorName: "",
    reference: "",
    informToConsultant: false,
    informToConsultantDtls: "",
    notInformToConsultant: false,
    notInformToConsultantDtls: "",
    otBooked: "",

    //
    mlcAndMedicationsDtoList: [
      {
        date: new Date(),
        dose: null,
        frequency: null,
        instructions: null,
        itemName: null,
        route: null,
        time: new Date(),
      },
    ],
    mlcRequestDto: null,
    updatedBy: 0,
  };

  const onSubmit = (data) => {
    setEtuCaseSheetData(data);

    if (!patientData) {
      warningAlert("Please Select Patient !");
    } else if (patientData) {
      setOpenConfirmationModal(true);
    }
    //caseSheetDto

    //GCS Radio
    caseSheetDto.gcs_e = Number(gcsEVM?.eyeOpeningResponse) !== 0 ? 1 : 0;
    caseSheetDto.gcs_v = Number(gcsEVM?.verbalResponse) !== "" ? 1 : 0;
    caseSheetDto.gcs_m = Number(gcsEVM?.motorResponse) !== 0 ? 1 : 0;

    // GCS Vaues
    caseSheetDto.gcs_eVal = Number(gcsEVM?.eyeOpeningResponse) || 0;
    caseSheetDto.gcs_vVal = Number(gcsEVM?.verbalResponse) || 0;
    caseSheetDto.gcs_mVal = Number(gcsEVM?.motorResponse) || 0;

    caseSheetDto.hypothyroidism = data.hypothyroidism || false;
    caseSheetDto.hypothyroidism_dtls = data.hypothyroidismDtls || "";

    caseSheetDto.allergicTo = data.allergies;
    caseSheetDto.asthma = data.asthama;
    caseSheetDto.asthma_dtls = data.asthamaDtls;
    caseSheetDto.bp = data.bp;
    caseSheetDto.bsl = data.bsl;
    caseSheetDto.central = data.central;
    caseSheetDto.cheifComplaints = data.chiefComplaints;
    caseSheetDto.cns = data.cns;
    caseSheetDto.copd = data.copd;
    caseSheetDto.copd_dtls = data.copdDtls;
    caseSheetDto.cvs = data.cvs;
    caseSheetDto.dm = data.dm;
    caseSheetDto.dm_dtls = data.dmDtls;
    caseSheetDto.firstDose = data.firstDose ? 1 : 0;
    caseSheetDto.secondDose = data.secondDose ? 1 : 0;
    caseSheetDto.booster = data.boosterDose ? 1 : 0;
    caseSheetDto.isOnBloodThinner = data.isOnBloodThinner ? 1 : 0; //new key
    caseSheetDto.foleys = data.foleys;
    caseSheetDto.gcs = gcsScore;
    caseSheetDto.heartDisease = data.heartDisease;
    caseSheetDto.heartDisease_dtls = data.heartDiseaseDtls;
    caseSheetDto.height = data.height;
    caseSheetDto.htn = data.htn;
    caseSheetDto.htn_dtls = data.htnDtls;
    caseSheetDto.liver = data.liver;
    caseSheetDto.liver_dtls = data.liverDtls;
    caseSheetDto.menstrualHistory = data.menstrualhistory;
    caseSheetDto.avpu =
      Avpu !== null && Avpu !== undefined ? Number(Avpu) : Number(0);
    // caseSheetDto.avpu = Avpu;
    caseSheetDto.mews = mewsScore;
    caseSheetDto.other = data.other; // req string-prov boolean
    caseSheetDto.otherLine = data.otherLines;
    caseSheetDto.other_dtls = data.otherDtls;
    caseSheetDto.oxygenRatePerMin = data.oxyRatePerMin;
    caseSheetDto.pa = data.pa;
    caseSheetDto.pews = data.pews;
    caseSheetDto.peripheral = data.peripheral;
    caseSheetDto.pulse = data.pulse;
    caseSheetDto.respRate = data.respiration;
    caseSheetDto.rs = data.rs;
    caseSheetDto.rt = data.rt;
    caseSheetDto.saturationWithO2 = data.saturationWithO2;
    caseSheetDto.saturationWithoutO2 = data.saturationWithoutO2;
    caseSheetDto.spo2 = data.spo2;
    caseSheetDto.surgicalHistory = data.surgicalHistory;
    caseSheetDto.tb = data.tb;
    caseSheetDto.tb_dtls = data.tbDtls;
    caseSheetDto.temp = data.temp;
    caseSheetDto.weight = data.weight;
    //localExaminationsDto
    localExaminationsDto.abrasion = data.abrasion;
    localExaminationsDto.bruise = data.bruise;
    localExaminationsDto.clw = data.clw;
    localExaminationsDto.handedOverBy = data.handedOverBy;

    let DateIsValid = isValid(handOverTime);

    localExaminationsDto.handedOverTime = DateIsValid
      ? format(handOverTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : handOverTime.concat("Z"); //add time state

    localExaminationsDto.burnPercentage = data.burnPercentage; //add time state
    localExaminationsDto.handedOverTo = data.handedOverTo;
    localExaminationsDto.injuriesDetails = data.injuriesDetails;
    localExaminationsDto.localExamination = data.localExamination;
    localExaminationsDto.provisionalDiagnosis = data.provisionalDignosis;
    localExaminationsDto.ptBelongingDocuments = data.ptBelongingDocuments;
    localExaminationsDto.specialInstructions = data.specialInstructions;

    setCaseSheetDtoState(caseSheetDto);
    setLocalExaminationsDtoState(localExaminationsDto);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);

    let token = JSON.parse(localStorage.getItem("userInfo"));
    let userId = token.userId;

    let DateIsValid = isValid(arrivalTime);
    finaleObject.arrivalTime = DateIsValid
      ? //format(arrivalTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        format(arrivalTime, "hh:mm:ss a")
      : arrivalTime.concat("Z");

    finaleObject.emergencyId = patientData?.EmergencyPatientId;
    finaleObject.addedBy = userId;
    finaleObject.addedDate = new Date();
    finaleObject.admissionId =
      admissionId !== null ? Number(admissionId) : Number(0);
    finaleObject.etuCaseSheetId =
      Number(patientData.ETUCaseSheetId) || Number(generatedEtuCSId);
    finaleObject.arrivalDate = format(arrivalDate, "yyyy/MM/dd");
    //finaleObject.arrivalTime = arrivalTime?.toLocaleTimeString("en-US");
    finaleObject.caseSheetDto = caseSheetDtoState;
    finaleObject.localExaminationsDto = localExaminationsDtoState;

    ///
    let prescribedMedications = [
      ...allMedicationData,
      ...deletedMedicationData,
    ];
    let mlcAndMedicationsDtoList = prescribedMedications.map((item) => ({
      ...item,
      isDelete: "isDelete" in item ? item.isDelete : false,
    }));

    finaleObject.mlcAndMedicationsDtoList = mlcAndMedicationsDtoList;
    ///
    finaleObject.mlcRequestDto = mlcModalData;
    finaleObject.updatedBy = 0;
    //test view
    finaleObject.pendingInvestigation = data.pendingInvestigations;
    finaleObject.labSentFromCasualty = data.labSentFromCasualty;
    finaleObject.specialInstruction = data.specialInstructions;
    finaleObject.transferTo = data.transferTo;
    finaleObject.doctorName = data.doctorName;
    finaleObject.reference = data.referance;
    finaleObject.informToConsultant = data.informedToConsultant;
    finaleObject.informToConsultantDtls = data.informedToConsultantDtls;
    finaleObject.notInformToConsultant = data.notInformedToConsultant;
    finaleObject.notInformToConsultantDtls = data.notInformedToConsultantDtls;
    finaleObject.otBooked = data.otBooked;
    finaleObject.menuId = location?.state?.menuId;
    finaleObject.privilege = previlage;
    finaleObject.isMlc = data.isMlc; //newkey added

    setOpenBackdrop(true);

    postEtuCaseSheetData(finaleObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setMedicationData([]);
          setAllMedicationData([]);
          setDeletedMedicationData([]);
          setOpenBackdrop(false);
          setGeneratedEtuCSId(!!res.result && res.result.ptId);
          updateData(res); ///to patch data
        

          //handleOpenPrintModal();
          // methods.reset(refreshDefaultValues);
          // setPatientData(null);
          // // setMedicationData([]);
          // setAllMedicationData([]);
          //methods.setValue("searchEtuPatient",null) //clear patient search

          // setIsDisableSearch(false);
          // setPatientId(null);

          // navigate("/ipd/etuCaseSheet", {
          //   state: { menuId: location.state.menuId },
          // });
        }
      })
      // .catch((res) => {
      //   setOpenBackdrop(false);
      //   errorAlert(res.message || res.response.data.message);
      //   setOpenConfirmationModal(false);
      // });

      .catch((res) => {
        setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());

        setOpenConfirmationModal(false);
      });

    // setTimeout(() => {
    //
    //   setOpenBackdrop(false);
    // }, 5000);
  };

  useEffect(() => {
    if (searchString !== "") {
      getPatientSearch();
    }
  }, [searchString]);

  useEffect(() => {
    getEtuCaseSheetData();
  }, [patientData]);

  const getEtuCaseSheetData = () => {
    patientData !== null &&
      patientData?.EmergencyPatientId &&
      fetchEtuCaseSheetData(
        patientData?.EmergencyPatientId,
        patientData?.AdmissionId !== null ? patientData?.AdmissionId : 0
      ) //patientId means emergency patient id
        .then((response) => response.data)
        .then((res) => {
          updateData(res); ///to patch data

          // setPatchData(res.result);
          //

          // setHandOverTime("1900-01-01T06:00:33.000");
        });
  };

  const updateData = (res) => {
    //patchData

    let a = res?.result?.HandOverTime?.replace("Z", "");
    setPoulateData(res.result);

    let PTID = res.result.ptId;
    // if (PTID !== null) {
    //   fetchMlcAndMedications(PTID)
    //     .then((response) => response.data)
    //     .then((res) => {
    //
    //       setAllMedicationData(res.result);
    //     });
    // }
    setAllMedicationData(res.result.medicationDetails);

    setMewsScore(res.result.MEWS);
    setGcsScore(res.result.GCS);
    // arrivaldate
    //setArrivalDate(parseISO(res.result.addeddate));

    getMews = res.result.MEWS;
    setValue("mewsRadio", getMews);

    setHandOverTime(a);
    reset({
      //ipdEmergency: res.result,
      isMlc: patientData.MedicoLegalCase,
      // generalExamination
      temp: res.result.Temp,
      pulse: res.result.Pulse,
      bp: res.result.BP,
      spo2: res.result.SPO2,
      weight: res.result.Weight,
      height: res.result.Height,
      respiration: res.result.Resp,
      bsl: res.result.BSL,
      saturationWithO2: res.result.SaturationWithO2,
      saturationWithoutO2: res.result.SaturationWithOutO2,
      oxyRatePerMin: res.result.OxygenPerMin,
      pews: res.result.PEWS,
      gcs: res.result.GCS,
      // eyeOpeningResponse: res.result.GCS_E,
      // verbalResponse: res.result.GCS_V,
      // motorResponse: res.result.GCS_M,
      mewsRadio: res.result.AVPU,

      mewsScore: res.result.MEWS,

      peripheral: res.result.PeripheralLine,
      central: res.result.CentralLine,
      rt: res.result.RTLine,
      foleys: res.result.Foleys_Other,
      otherLines: res.result.LineOther,
      firstDose: res.result.Covid19_1stVaccine,
      secondDose: res.result.Covid19_2stVaccine,
      boosterDose: res.result.Covid19_BoosterVaccine,
      isOnBloodThinner: res.result.isOnBloodThinner,

      //past history
      hypothyroidism: res.result.PH_Hypothyroidism,
      hypothyroidismDtls: res.result.PH_Hypothyroidism_Dtl,
      dm: res.result.PH_DM === "0" ? false : true,
      dmDtls: res.result.PH_DM_Dtl,
      htn: res.result.PH_HTN === "0" ? false : true,
      htnDtls: res.result.PH_HTN_Dtl,
      heartDisease: res.result.PH_HD === "0" ? false : true,
      heartDiseaseDtls: res.result.PH_HD_Dtl,
      tb: res.result.PH_TB === "0" ? false : true,
      tbDtls: res.result.PH_TB_Dtl,
      copd: res.result.PH_COPD === "0" ? false : true,
      copdDtls: res.result.PH_COPD_Dtl,
      asthama: res.result.PH_ASHTHMA === "0" ? false : true,
      asthamaDtls: res.result.PH_ASHTHMA_Dtl,
      liver: res.result.PH_LIVER === "0" ? false : true,
      liverDtls: res.result.PH_LIVER_Dtl,
      other: res.result.PH_Other === "0" ? false : true,
      otherDtls: res.result.PH_Other_Dtl,
      // allergies/chiefComplaints
      allergies: res.result.Allergies,
      chiefComplaints: res.result.ChiefCompl,
      //surgicalHistory
      surgicalHistory: res.result.SurgicalHistory,
      //systematic Examination
      rs: res.result.RS,
      cvs: res.result.CVS,
      cns: res.result.CNS,
      pa: res.result.PA,
      menstrualhistory: res.result.MenstrualHistory,
      //local Examination
      burnPercentage: res.result.Burn_Per,
      localExamination: res.result.LocalExamin,
      injuriesDetails: res.result.Enjuries_Dtl,
      provisionalDignosis: res.result.Diagnosis,
      clw: res.result.CLW,
      abrasion: res.result.Abrasion,
      bruise: res.result.Bruise,
      //test view
      pendingInvestigations: res.result.pendinginvestigation,
      labSentFromCasualty: res.result.Spcl_Instr,
      transferTo: res.result.Transfer_To,
      doctorName: res.result.DoctorName,
      referance: res.result.Refernce,
      informedToConsultant: res.result.Informed,
      informedToConsultantDtls: res.result.Informed_Dtl,
      notInformedToConsultant: res.result.Not_Informed,
      notInformedToConsultantDtls: res.result.Not_Informed_Dtl,
      otBooked: res.result.isotbooked,

      //Mlc & Medications
      handedOverBy: res.result.HandOverBy,
      handedOverTo: res.result.HandOverTo,
      ptBelongingDocuments: res.result.PTBelongingDoc,
      specialInstructions: res.result.TransferingRemark,
      //
    });
  };

  useEffect(() => {
    if (location.state !== null) {
      setPatientId(location?.state?.row?.EmergencyPatientId);
      setIsDisableSearch(location.state.isEditInfo);
    }
  }, []);

  // Patient Search
  const getPatientSearch = () => {
    searchString &&
      fetchEtuCasePatientSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setAutoSearch(res.result);
        });
  };

  useEffect(() => {
    // if (patientId !== undefined) {

    patientId && //patientId is Actualy EmergencyId
      patientId !== null &&
      fetchEtuCasePatientInfo(patientId)
        .then((response) => response.data)
        .then((res) => {
          setPatientData(res.result);
          setAdmissionId(res.result.AdmissionId);
          setValue("isMlc", res.result.MedicoLegalCase);
          setArrivalDate(parseISO(res.result.arrivaldate));
        });
    // }
  }, [patientId]);

  // };

  ///get User Action
  useEffect(() => {
    //
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  return (
    <>
      <div className="mt-20">
        <div className="text-center text-black font-bold text-xl ">
          ETU Case Sheet
        </div>
        <div className="grid py-2 grid-cols-3   items-center">
          <div className="col-span-2 lg:col-span-1">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No."
              dataArray={autosearch}
              name="searchEtuPatient"
              isDisabled={isDisableSearch}
              searchIcon={true}
              handleInputChange={handleSearch}
              isClearable={true}
              inputRef={{
                ...register("searchEtuPatient", {
                  onChange: (e) => {
                    if (
                      e.target.value !== null
                      // &&
                      // e.target.value.EmergencyPatientId !== null
                    ) {
                      setAdmissionId(e.target.value.AdmissionId);
                      // setSelectedPatient(e.target.value);
                      setPatientId(e.target.value.EmergencyPatientId);
                      // getPatientInfo(e.target.value.EmergencyPatientId);
                      // getEtuCaseSheetData();
                    } else {
                      // setSelectedPatient("");
                      setPatientData(null);
                      methods.reset(refreshDefaultValues);
                      setGcsScore(0);
                      setMedicationData([]);
                      setAllMedicationData([]);
                      setDeletedMedicationData([]);
                      setAdmissionId(null);
                      setPatientId(null);
                      setGcsScore(0);

                      setPoulateData(null);
                    }
                  },
                }),
              }}
            />
          </div>
          <div className="flex justify-end col-span-1 lg:col-span-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction &&
                  obj.action === "Print" &&
                  patientData &&
                  (patientData.ETUCaseSheetId !== null || generatedEtuCSId) && (
                    <Tooltip title="Print ETU Case Sheet">
                      <IconButton onClick={() => handleOpenPrintModal()}>
                        <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </>
            ))}
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              {/* patient Details */}
              <fieldset
                className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
              >
                <div className="py-2 grid  grid-cols-4 2xl:grid-cols-4 gap-2 p-3  ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Patient Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientData && patientData.patientName}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">MR. No.</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientData && patientData.mrNo}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Gender</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientData && patientData.gender}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Age</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientData && patientData.AgeYear}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Doctor</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientData && patientData.doctor}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Bed</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {
                          patientData && patientData.Bed //||patientData && patientData.patientName
                        }
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Arrival Date</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        <div className="col-span-2 ">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disableFuture
                              label=""
                              value={arrivalDate}
                              inputFormat="dd/MM/yyyy"
                              onChange={handleChangeDate}
                              renderInput={(params) => (
                                <TextField
                                  className="bg-white"
                                  fullWidth
                                  name="arrivalDate"
                                  size="small"
                                  defaultValue=""
                                  disabled={true}
                                  inputFormat="dd/MM/yyyy"
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  sx={{
                                    svg: { color: "#0B83A5" },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Arrival Time</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                              <DesktopTimePicker
                                // label="Arrival Time"
                                value={arrivalTime}
                                onChange={(newValue) => {
                                  // let time = newValue.toLocaleTimeString();

                                  setArrivalTime(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="bg-white"
                                    fullWidth
                                    name="arrivalTime"
                                    size="small"
                                    defaultValue=""
                                    disabled={true}
                                    inputFormat="dd/MM/yyyy"
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      readOnly: true,
                                    }}
                                    sx={{
                                      svg: { color: "#0B83A5" },
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
              {/* radioFilds/Checkboxes */}
              <div className="grid lg:grid-cols-5 md:grid-cols-2">
                <div className="mt-2">
                  <RadioField
                    label=""
                    name="ipdEmergency"
                    control={control}
                    dataArray={IpdEmergency}
                  />
                </div>
                <div
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpenModal(e.target.checked);
                    }
                  }}
                >
                  <CheckBoxField
                    control={control}
                    name="isMlc"
                    label="Medico Legal Case "
                  />
                </div>
              </div>
              <div className="grid  gap-3">
                {/* General Examination */}
                <div className="pt-3">
                  <div>
                    <Accordion
                      expanded={expandPanal5}
                      onChange={handleChangePanal5}
                      elevation={6}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                        sx={{
                          "&.Mui-expanded": {
                            marginBottom: "0.3rem",
                            minHeight: "40px",
                          },
                          "& .MuiAccordionSummary-content.Mui-expanded": {
                            margin: 0,
                          },
                          backgroundColor: "#FCE7F3",
                        }}
                      >
                        <p className="font-bold tracking-wide text-sm font-Poppins">
                          General Examination
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GeneralExamination
                          setGcsScore={setGcsScore}
                          gcsScore={gcsScore}
                          poulateData={poulateData}
                          mewsScore={mewsScore}
                          setMewsScore={setMewsScore}
                          setGcsEVM={setGcsEVM}
                          patientData={patientData}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                {/* Allergies/complaints*/}
                <div className="pt-3">
                  <div>
                    <Accordion
                      expanded={expandPanal3}
                      onChange={handleChangePanal3}
                      elevation={6}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                        sx={{
                          "&.Mui-expanded": {
                            marginBottom: "0.3rem",
                            minHeight: "40px",
                          },
                          "& .MuiAccordionSummary-content.Mui-expanded": {
                            margin: 0,
                          },
                          backgroundColor: "#FCE7F3",
                        }}
                      >
                        <p className="font-bold tracking-wide text-sm font-Poppins">
                          Chief Complaints
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="grid  gap-3">
                          <div className="">
                            {/* // Chief Complaints.// */}

                            <InputArea
                              control={control}
                              name="chiefComplaints"
                              label="Chief Complaints "
                              placeholder="Chief Complaints"
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                {/* Past History */}
                <div className="pt-3">
                  <div>
                    <Accordion
                      expanded={expandPanal2}
                      onChange={handleChangePanal2}
                      elevation={6}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                        sx={{
                          "&.Mui-expanded": {
                            marginBottom: "0.3rem",
                            minHeight: "40px",
                          },
                          "& .MuiAccordionSummary-content.Mui-expanded": {
                            margin: 0,
                          },
                          backgroundColor: "#CFFFCB",
                        }}
                      >
                        <p className="font-bold tracking-wide text-sm font-Poppins">
                          Past History
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* PatientDetails*/}
                        <PastHistory />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>

              {/* Allergies*/}
              <div className="pt-3">
                <div>
                  <Accordion
                    expanded={expandPanal3}
                    onChange={handleChangePanal3}
                    elevation={6}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      sx={{
                        "&.Mui-expanded": {
                          marginBottom: "0.3rem",
                          minHeight: "40px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                        backgroundColor: "#CFFAFE",
                      }}
                    >
                      <p className="font-bold tracking-wide text-sm font-Poppins">
                        Allergies
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="grid  gap-3">
                        <div className="">
                          {/* //Allergies.// */}

                          <InputArea
                            control={control}
                            name="allergies"
                            label="Allergies "
                            placeholder="Allergies"
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              {/* SergiCal History */}
              <div className="pt-3">
                <div>
                  <Accordion
                    expanded={expandPanal4}
                    onChange={handleChangePanal4}
                    elevation={6}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      sx={{
                        "&.Mui-expanded": {
                          marginBottom: "0.3rem",
                          minHeight: "40px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                        backgroundColor: "#CFFAFE",
                      }}
                    >
                      <p className="font-bold tracking-wide text-sm font-Poppins">
                        Surgical History
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="">
                        {/* //Surgical History.// */}

                        <InputArea
                          control={control}
                          name="surgicalHistory"
                          label="Surgical History "
                          placeholder="Surgical History"
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* SystematicExamination */}
              <div className="pt-3">
                <div>
                  <Accordion
                    expanded={expandPanal18}
                    onChange={handleChangePanal18}
                    elevation={6}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      sx={{
                        "&.Mui-expanded": {
                          marginBottom: "0.3rem",
                          minHeight: "40px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                        backgroundColor: "#FCE7F3",
                      }}
                    >
                      <p className="font-bold tracking-wide text-sm font-Poppins">
                        Systematic Examination
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* SystematicExamination*/}
                      <SystematicExamination patientData={patientData} />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* Local Examination */}
              <div className="pt-3">
                <div>
                  <Accordion
                    expanded={expandPanal6}
                    onChange={handleChangePanal6}
                    elevation={6}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      sx={{
                        "&.Mui-expanded": {
                          marginBottom: "0.3rem",
                          minHeight: "40px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                        backgroundColor: "#C6CCFF",
                      }}
                    >
                      <p className="font-bold tracking-wide text-sm font-Poppins">
                        Local Examination
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* LocalExamination*/}
                      <LocalExamination />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* Test View */}
              <div className="pt-3">
                <div>
                  <Accordion
                    expanded={expandPanal7}
                    onChange={handleChangePanal7}
                    elevation={6}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      sx={{
                        "&.Mui-expanded": {
                          marginBottom: "0.3rem",
                          minHeight: "40px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                        backgroundColor: "#C6CCFF",
                      }}
                    >
                      <p className="font-bold tracking-wide text-sm font-Poppins">
                        Test View
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* TestView*/}
                      <TestView />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              {/* MlcAndMedications */}
              <div className="pt-3">
                <div>
                  <Accordion
                    expanded={expandPanal8}
                    // onChange={handleChangePanal8}
                    elevation={6}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      sx={{
                        "&.Mui-expanded": {
                          marginBottom: "0.3rem",
                          minHeight: "40px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                        backgroundColor: "#CFFAFE",
                      }}
                    >
                      <div className="grid grid-cols-12 w-full">
                        <div
                          className="col-span-11 font-bold tracking-wide text-sm font-Poppins"
                          onClick={handleChangePanal8}
                        >
                          Mlc And Medications
                        </div>
                        <div className="flex justify-end">
                          <AddCircleIcon
                            sx={{ color: "#1e3a8a" }}
                            onClick={() => {
                              setOpenPrescriptionModal(true);
                            }}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MlcAndMedications
                        allMedicationData={allMedicationData}
                        setAllMedicationData={setAllMedicationData}
                        //
                        deletedMedicationData={deletedMedicationData}
                        setDeletedMedicationData={setDeletedMedicationData}
                        //
                        medicationData={medicationData}
                        setMedicationData={setMedicationData}
                        handOverTime={handOverTime}
                        setHandOverTime={setHandOverTime}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              <div className="flex gap-3 justify-end lg:col-span-3 pt-6">
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <>
                        <div>
                          <CommonButton
                            label="Reset"
                            className="border border-customRed text-customRed"
                            onClick={() => {
                              methods.reset(refreshDefaultValues);
                              methods.setValue("searchEtuPatient", null); //clear patient search
                              setGcsScore(0);
                              setPatientData("");
                              setOpenBackdrop(false);
                              setMedicationData([]);
                              setAllMedicationData([]);
                              setIsDisableSearch(false);
                              setPoulateData(null);
                            }}
                          />
                        </div>

                        <div>
                          <CommonButton
                            type="submit"
                            label="Save"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              setPrevilage(obj.action);
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {/* //MedicationPrescriptionModal */}
      {openPrescriptionModal ? (
        <MedicationPrescriptionModal
          allMedicationData={allMedicationData}
          setAllMedicationData={setAllMedicationData}
          medicationData={medicationData}
          setMedicationData={setMedicationData}
          open={openPrescriptionModal}
          setOpen={setOpenPrescriptionModal}
          handleOpen={handleOpenPrescriptionModal}
          handleClose={handleClosePrescriptionModal}
          //
          deletedMedicationData={deletedMedicationData}
          setDeletedMedicationData={setDeletedMedicationData}
        />
      ) : null}
      {/* Print Modal */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      {/* mlc Modal */}
      {openModal && patientData && patientData !== null ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          patientInfo={true}
          setMlcModalData={setMlcModalData}
          MLCNo={null}
          //isEdit={false}
          isFromForm={true}
          admissionId={admissionId !== null ? Number(admissionId) : Number(0)}
          emergencyId={patientId} //patientId is Actualy EmergencyId
          opdIpd={1}
          ipdEmergency={admissionId !== 0 ? 1 : 2}
          //
          patientId={patientId}
          isEmergency={true}
          //
        />
      ) : null}
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(etuCaseSheetData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default EtuCaseSheet;
