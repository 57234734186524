import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getServiceDetailsList,
  getServiceNameList,
  updateSiteAndInvestigation,
} from "../../Services/Pathalogy/WorkOrderViewServices";
import QueueModalTable from "../../common/QueueModalTable";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "90%",
  height: "75%",
  p: 3,
  overflowY: "scroll",
};

const PathologyQueueModal = (props) => {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [data, setData] = React.useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = React.useState([]);
  const [serviceNameOptions, setServiceNameOptions] = useState();
  const [serviceDetailsData, setServiceDetailsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPut, setopenPut] = useState(false);
  const { openQueueModal, setOpenQueueModal } = props;

  const defaultValues = {
    searchService: null,
    site: "",
    instruction: "",
  };
  const { control, register, watch, handleSubmit, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let getServiceName = watch("searchService");
  let getInstruction = watch("instruction");

  function handleChange(searchString) {
    if (searchString !== "") {
      getServiceNameList(searchString).then((response) => {
        setServiceNameOptions(response.data.result);
      });
    }
  }

  function displayRowData(row, index) {
    setSelectedRow(row);
  }

  function updateSiteInstruction() {
    let instruction = getInstruction ? getInstruction : "";
    let orderDtlsId = selectedRow?.OrderDtlsId;
    setOpenBackdrop(true);
    updateSiteAndInvestigation(instruction, orderDtlsId)
      .then((response) => {
        setOpenBackdrop(false);

        reset(defaultValues);
        successAlert(response.data.message);
        setopenPut(false);
        serviceDetailsList();
        setSelectedRow(null);
        setPage(0);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  useEffect(() => {
    if (selectedRow?.site !== null) {
      setValue("site", selectedRow?.site);
    } else if (selectedRow?.site === null) {
      setValue("site", "");
    }
    if (selectedRow?.Instruction !== null) {
      setValue("instruction", selectedRow?.Instruction);
    } else if (selectedRow?.Instruction === null) {
      setValue("instruction", "");
    }
  }, [selectedRow]);

  useEffect(() => {
    if (openQueueModal === true) {
      serviceDetailsList();
    }
  }, [openQueueModal, getServiceName]);

  function serviceDetailsList() {
    let getServiceId = getServiceName ? getServiceName?.serviceId : 0;
    let pages = 0;
    let size = rowsPerPage;
    getServiceDetailsList(getServiceId, pages, size)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
        setCount(res.count);
        setDataResult(res.result);
      });
  }

  return (
    <>
      <form>
        <Modal open={openQueueModal}>
          <Box sx={ModalStyle}>
            <div>
              <CancelPresentationIconButton
                onClick={() => {
                  reset(defaultValues);
                  setOpenQueueModal(false);
                  serviceDetailsList();
                  setSelectedRow(null);
                  setPage(0);
                }}
              />
            </div>
            <div className="space-y-2 mt-3">
              <div className="w-80">
                <SearchDropdown
                  control={control}
                  name="searchService"
                  placeholder="Search Service"
                  handleInputChange={handleChange}
                  dataArray={serviceNameOptions}
                  isClearable={true}
                  searchIcon={true}
                />
              </div>
              <div>
                {data.hasOwnProperty("result") &&
                data.result.length > 0 &&
                data.statusCode === 200 ? (
                  <QueueModalTable
                    dataResult={dataResult}
                    setDataResult={setDataResult}
                    data={data}
                    page={page}
                    setData={setData}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    displayRowData={displayRowData}
                    tableApiFunc={getServiceDetailsList}
                    getServiceName={getServiceName}
                  />
                ) : (
                  <div className="justify-center items-center font-semibold h-56 lg:h-96 w-full flex border-2 border-gray-300 rounded my-2">
                    No Records Found...
                  </div>
                )}
              </div>
              <div>
                <div className="flex gap-2 w-full">
                  <div className="w-full">
                    <InputField
                      control={control}
                      className="w-full"
                      name="site"
                      label="Site Information"
                      disabled={true}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      control={control}
                      className="w-full"
                      name="instruction"
                      label="Instruction"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium"
                      onClick={() => {
                        setopenPut(true);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <CommonBackDrop openBackdrop={openBackdrop} />
            </div>
          </Box>
        </Modal>
      </form>
      <ConfirmationModal
        confirmationOpen={openPut}
        confirmationHandleClose={() => {
          setopenPut(false);
        }}
        confirmationSubmitFunc={updateSiteInstruction}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to update the record ?"
        confirmationButtonMsg="Yes"
      />
    </>
  );
};

export default PathologyQueueModal;
