import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { MicrrosoftExceIcon } from "../../../assets/icons/MicrrosoftExceIcon";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getPatientQuestionWiseFeedbackReportExcel } from "../../../opd/services/registrationServices/PatientRegistrationServices_17082023_vikas";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { format } from "date-fns";

export default function PatientQuestionWiseReportTable(props) {
  const {
    dataResult,
    page,
    setPage,
    tableClass,
    rowsPerPage,
    count,
    populateTable,
    renderTableHeader,
    fromDateValue,
    toDateValue,
    patientInfo,
    searchByTemplateNameValue,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  // const handlePageChange = (event, newPage) => {
  //   setNewPageVal(newPage);
  //   setPage(newPage);
  // };

  // useEffect(() => {
  //   if (newPageVal > pagesPassed) {
  //     setPagesPassed(newPageVal);
  //     setIsLoading(true);
  //     populateTable(true);
  //   }
  // }, [newPageVal, pagesPassed, populateTable]);

  useEffect(() => {
    // if (count <= rowsPerPage || dataResult?.length <= rowsPerPage) {
    //   setPagesPassed(0);
    //   setNewPageVal(0);
    // }
    if (dataResult?.length > 0) {
      setIsLoading(false);
    }
  }, [count, rowsPerPage, dataResult]);

  const headers = useMemo(() => {
    if (dataResult.length === 0) return [];
    const staticHeaders = [
      "Sr. No",
      "Created Date",
      "MR No",
      "Patient Name",
      "Avg Rating",
    ];
    const dynamicHeaders = dataResult[0].feedback.map((item) => item.question);
    return [...staticHeaders, ...dynamicHeaders];
  }, [dataResult]);

  const handleExportTtoExcel = () => {
    let tempObj = {
      patientId: patientInfo !== null ? patientInfo.id : null,
      templateId: searchByTemplateNameValue?.id,
      mrNo: null,
      fromDate:
        fromDateValue !== null
          ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
      page: 0,
      size: count,
      toDate:
        toDateValue !== null
          ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
    };
    getPatientQuestionWiseFeedbackReportExcel(tempObj)
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "PatientQuestionWiseFeedbackReport.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const ConfirmExportToExcelRecord = () => {
    setOpenExcelModal(false);
    handleExportTtoExcel();
  };

  return (
    <div className="w-auto grid">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", border: "1px solid lightgray" }}>
          <div className="font-semibold text-sm p-2 flex justify-end">
            {/* {props?.tableHeading} {renderTableHeader && renderTableHeader()} */}
            <div className="flex justify-end">
              <Tooltip title="Export To Excel">
                <button
                  onClick={() => {
                    setOpenExcelModal(true);
                  }}
                >
                  <MicrrosoftExceIcon />
                </button>
              </Tooltip>
            </div>
          </div>
          {/* <TablePagination
              labelRowsPerPage=""
              rowsPerPageOptions={[]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              sx={{
                ".MuiTablePagination-toolbar": {
                  minHeight: "35px",
                },
              }}
            /> */}

          <TableContainer className={tableClass}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow
                  sx={{ "& th": { paddingY: 0.5, backgroundColor: "#ADD8E6" } }}
                >
                  {headers.map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        minWidth: index >= 5 ? 250 : "auto",
                        whiteSpace: index <= 5 ? "nowrap" : "",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataResult?.length > 0 &&
                  dataResult.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${row.patientId}-${index}`}
                      className="whitespace-nowrap"
                    >
                      <TableCell>{row.srno}</TableCell>
                      <TableCell>{row.createdDate}</TableCell>
                      <TableCell>{row.mrNo}</TableCell>
                      <TableCell>{row.patientName}</TableCell>
                      <TableCell>{row.templateAvgRating}</TableCell>
                      {row.feedback.map((feedbackItem, feedbackIndex) => (
                        <TableCell key={feedbackIndex}>
                          {feedbackItem.rating}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {isLoading && (
              <div className="flex justify-center text-gray-400 font-semibold my-5">
                Loading...
              </div>
            )}
          </TableContainer>
        </Paper>
      </Box>

      <ConfirmationModal
        confirmationOpen={openExcelModal}
        confirmationHandleClose={() => setOpenExcelModal(false)}
        confirmationSubmitFunc={ConfirmExportToExcelRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want export to excel this records ?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}

