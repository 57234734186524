import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import CreateNewReviewModal from "./CreateNewReviewModal";
import Information from "../../../../assets/Information.png";
import AnswerCreationInfoModal from "./AnswerCreationInfoModal";
import { DeleteIcon } from "../../../../assets/CommonAssets";
import { Tooltip } from "@mui/material";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import {
  deleteAnswerPatternScale,
  getAnswerPatternList,
  getAnswerReviewTypeAutoComplete,
} from "../../../services/feedback/answerreview/AnswerReviewServices";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";

function AnswerReviewCreation() {
  const defaultValues = {
    answerReviewType: null,
  };

  const { control, watch, register } = useForm({
    mode: "onChange",
    defaultValues,
  });
  // local variable
  let answerReviewTypeValue = watch("answerReviewType");
  let localStorageValue = JSON.parse(localStorage.getItem("userInfo"));

  // state variable
  const [openCreateReviewModal, setOpenCreateReviewModal] = useState(false);
  const [answerCreationData, setAnswerCreationData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [selectedInfoRow, setSelectedInfoRow] = useState(null);
  const [answerCreationDetailsModal, setAnswerCreationDetailsModal] = useState(
    false
  );
  const handleCloseNewFeedbackModal = () => setOpenCreateReviewModal(false);
  const handleCloseAnswerCreationModal = () =>
    setAnswerCreationDetailsModal(false);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [selectedSearchRecord, setSelectedSearchRecord] = useState(null);

  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);
  const [answerReviewTypeOptions, setAnswerReviewTypeOptions] = useState([]);

  const [listingObject, setListingObject] = useState({
    searchString:
      answerReviewTypeValue !== null ? answerReviewTypeValue.label : "",
    id: answerReviewTypeValue === null ? null : answerReviewTypeValue.id,
    page: page,
    size: rowsPerPage ? rowsPerPage : 10,
  });

  useEffect(() => {
    populateTable();
  }, [selectedSearchRecord]);

  function populateTable(forPagination) {
    let tempObj = {
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      id: selectedSearchRecord?.id || null,
    };

    getAnswerPatternList(tempObj)
      .then((response) => response.data)
      .then((res) => {
        let tempArr = [];
        let responseDataIs = [...res.result];

        if (responseDataIs.length > 0) {
          for (let obj of responseDataIs) {
            let tempObj = {
              Id: obj.Id,
              "Answer Review Type": obj["Answer Review Type"],
              "Answer Type": obj["Answer Type"],
              PreViewInfo: JSON.parse(obj.PreView),
              PreView: "",
              "Created By": obj["Created By"],
              "Created Date": obj["Created Date"],
              "Last Modified By": obj["Last Modified By"],
              "Last Modified Date": obj["Last Modified Date"],
            };
            tempArr.push(tempObj);
          }

          if (forPagination) {
            setAnswerCreationData((prevData) => [...prevData, ...tempArr]);
          } else {
            setAnswerCreationData(tempArr);
            setPage(0);
          }
        }
        setCount(res.count);
        setPage(0);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  console.log("answer", listingObject);
  const handleChangeAnswerReviewType = (e) => {
    if (e.length > 0) {
      if (UseCheckConsecutiveCharacters(e) !== "") {
        getAnswerReviewTypeAutoComplete(e)
          .then((response) => {
            setAnswerReviewTypeOptions(response.data.result);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  function deleteRow() {
    deleteAnswerPatternScale(deleteRowId, localStorageValue.userId)
      .then((response) => {
        successAlert(response.data.message);
        setDeleteRowId(null);
        setOpenDeleteConfirmationModal(false);
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  function renderActions(row, index) {
    return (
      <div className="flex space-x-2 items-center">
        {/* <Tooltip title="Edit">
          <button
            type="button"
            onClick={() => {
              setSelectedInfoRow(row);
              setOpenCreateReviewModal(true);
            }}
          >
            <EditIcon />
          </button>
        </Tooltip> */}
        <Tooltip title="Delete">
          <button
            type="button"
            onClick={() => {
              setDeleteRowId(row.Id);
              setOpenDeleteConfirmationModal(true);
            }}
          >
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
    );
  }

  function renderInput(row) {
    return (
      <div>
        <Tooltip title="Prview">
          <button
            type="button"
            className="text-customBlue pt-1"
            onClick={() => {
              setSelectedInfoRow(row);
              setAnswerCreationDetailsModal(true);
            }}
          >
            <img src={Information} className="h-5" />
          </button>
        </Tooltip>
      </div>
    );
  }

  useMemo(() => {
    populateTable();
  }, [listingObject.searchString]);

  return (
    <div className="pt-16">
      <h1 className=" flex justify-center font-semibold text-lg">
        Answer Review Create
      </h1>
      <div className="flex justify-between items-center pt-3">
        <div className="w-4/12 flex space-x-2 items-center">
         

          <SearchDropdown
            control={control}
            searchIcon={true}
            name="answerReviewType"
            placeholder="Answer Review Type"
            dataArray={answerReviewTypeOptions}
            handleInputChange={handleChangeAnswerReviewType}
            isClearable={true}
            inputRef={{
              ...register("answerReviewType", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedSearchRecord(e.target.value);
                  } else {
                    setSelectedSearchRecord(null);
                  }
                },
              }),
            }}
          />

          {/*  <CommonButton
            searchIcon={true}
            className="bg-[#073763] text-white"
            onClick={() => {
              console.log("231");
              setListingObject((listingObject) => ({
                ...listingObject,
                searchString:
                  answerReviewTypeValue !== null
                    ? answerReviewTypeValue.label
                    : null,
                page: page,
                size: rowsPerPage ? rowsPerPage : 10,
                id:
                  answerReviewTypeValue !== null
                    ? answerReviewTypeValue.id
                    : null,
              }));
            }}
          />*/}
        </div>
        <div>
          <CommonButton
            type="button"
            label="+ Add"
            className="bg-[#073763] text-white w-full"
            onClick={() => {
              setOpenCreateReviewModal(true);
              setDeleteRowId(null);
              setSelectedInfoRow(null);
            }}
          />
        </div>
      </div>
      <div>
        {answerCreationData?.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={answerCreationData}
            removeHeaders={[
              "PreViewInfo",
              "Id",
              "Created By",
              "Last Modified By",
              "Last Modified Date",
            ]}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
            editableColumns={["PreView"]}
            renderInput={renderInput}
            renderActions={renderActions}
            tableClass={"h-[400px]"}
            highlightRow={false}
          />
        ) : (
          <h2 className="text-center flex justify-center items-center text-gray-600 font-semibold text-sm py-28">
            No Records Availabale
            <span className="animate-pulse tracking-wider">...</span>
          </h2>
        )}
      </div>
      {openCreateReviewModal ? (
        <CreateNewReviewModal
          open={openCreateReviewModal}
          handleClose={handleCloseNewFeedbackModal}
          populateTable={populateTable}
          selectedInfoRow={selectedInfoRow}
          setSelectedInfoRow={setSelectedInfoRow}
        />
      ) : (
        ""
      )}
      {answerCreationDetailsModal ? (
        <AnswerCreationInfoModal
          open={answerCreationDetailsModal}
          selectedInfoRow={selectedInfoRow}
          setSelectedInfoRow={setSelectedInfoRow}
          handleClose={handleCloseAnswerCreationModal}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={openDeleteConfirmationModal}
        confirmationHandleClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
        confirmationSubmitFunc={deleteRow}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}

export default AnswerReviewCreation;
