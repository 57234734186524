import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DeleteOnIcon, EyeOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  deleteAlert,
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import {
  deleteOxygenVentilion,
  getAllOxygenVantilation,
  getAllVentilationModes,
  saveVentilationOxygenRecords,
} from "../../../services/cliinicalChartServices/oxygenvantilationServices/OxygenVantilationServices";
import { VisitContext } from "../ClinicalCareChart";
import OldRecordModal from "./OldRecordModal";

function formatTimeValue(value) {
  return value < 10 ? "0" + value : value; // Add leading zero if the value is less than 10
}

function OxygenVentilation(props) {
  const [ventilationModesOptions, setVentilationModesOptions] = React.useState(
    []
  );

  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  console.log("openPost", openPost);
  const [spinner, showSpinner] = useState(false);
  const [finalData, setFinalData] = React.useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [dataResult, setDataResult] = useState({ list: [] });
  const [data, setData] = React.useState(null);
  const [findClick, setFindClick] = React.useState("");

  const [patientInfo, setPatientInfo] = React.useState(null);
  const [counterOf, setCounterOf] = React.useState("");
  const [vantilationValue, setvantilationValue] = useState(0);
  const [oxygenValue, setOxygenValue] = useState(0);

  const [ventilationId, setVentilationId] = React.useState(0);
  const [isUpdateOxyVent, setIsUpdateOxyVent] = React.useState(false);

  const patientVisitId = useContext(VisitContext);

  //oxygen start and stop
  const getOxygenSeconds = `0${props.oxygenTimer % 60}`.slice(-2);
  const oxygenMinutes = `${Math.floor(props.oxygenTimer / 60)}`;
  const getOxygenMinutes = `0${oxygenMinutes % 60}`.slice(-2);
  const getOxygenHours = `0${Math.floor(props.oxygenTimer / 3600)}`.slice(-2);

  // ventilation Start And stop
  const getVentilationSeconds = `0${props.ventilationTimer % 60}`.slice(-2);
  const ventilationMinutes = `${Math.floor(props.ventilationTimer / 60)}`;
  const getVentilationMinutes = `0${ventilationMinutes % 60}`.slice(-2);
  const getVentilationHours = `0${Math.floor(
    props.ventilationTimer / 3600
  )}`.slice(-2);

  // const [rowIndex, setRowIndex] = React.useState(null);

  //the object to reset the form to blank values
  const defaultValues = {
    visitId: "",
    mode: null,
    tidolV: "",
    setRate: "",
    ipap: "",
    minuteV: "",
    rateTotal: "",
    epap: "",
    peep: "",
    pc: "",
    mv: "",
    prSup: "",
    fiO2: "",
    ie: "",
    oxygenRatePerMin: "",
    saturationWithO2: "",
    flowTrigger: "",
    ventilationDuration: "",
    oxygenDuration: [{ endDate: "", startDate: "" }],
  };

  let getUserId = JSON.parse(localStorage.getItem("userInfo"));
  let admissionId = props.displayInfo?.AdmissionId;
  const { control, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [openOldRecordModal, setOpenOldRecordModal] = useState(false);
  const [selectedRecordRow, setSelectedRecordRow] = useState(null);

  const [deleteObj, setDeleteObj] = React.useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);

  useEffect(() => {
    //Ventilation Mode Options
    getAllVentilationModes()
      .then((response) => response.data)
      .then((res) => {
        setVentilationModesOptions(res.result);
      });

    reset(defaultValues);
    setFindClick("");
    setPatientInfo(null);
    props.setVentilationIsActive(false);
    props.setVentilationIsPaused(false);
    props.setOxygenIsActive(false);
    props.setOxygenIsPaused(false);

    // setRowIndex(null);
    setVentilationId(0);

    setvantilationValue(0);
    setOxygenValue(0);
  }, [props.displayInfo?.AdmissionId, props.admissionId]);

  useEffect(() => {
    if (props.displayInfo?.AdmissionId || props.admissionId) {
      populateTable();
    }
  }, [props.displayInfo?.AdmissionId || props.admissionId]);

  //populate the CommonMasterTable using the function populateTable
  const populateTable = () => {
    showSpinner(true);
    getAllOxygenVantilation(props.displayInfo?.AdmissionId || props.admissionId)
      .then((response) => {
        return response.data.result;
      })
      .then((res) => {
        setData(res);
        showSpinner(false);
        setDataResult(res.list);
        console.log("res.list", res, res.list);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  //axios request for data post. That is post request
  function postOxygenVentilation(obj) {
    saveVentilationOxygenRecords(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateTable();
          let rowObject = dataResult[dataResult.length - 1];
          if (rowObject?.vention === 0) {
            handlePauseVentilation();
          } else if (rowObject?.oxyon === 0) {
            handlePauseOxygen();
          }
          reset(defaultValues);
          setVentilationId(0);
          setPatientInfo(null);
          props.setHours(0);
          props.setMinutes(0);
          props.setSeconds(0);

          props.setOxygenHours(0);
          props.setOxygenMinutes(0);
          props.setOxygenSeconds(0);

          // setRowIndex(null); /// selected index clr in case of update , stop
          setCounterOf("");

          clearInterval(props.oxygenIncrement.current);
          props.setOxygenTimer(0);
          props.setOxygenIsActive(false);
          props.setOxygenIsPaused(false);
          //ventilation
          clearInterval(props.ventilationIncrement.current);
          props.setVentilationTimer(0);
          props.setVentilationIsActive(false);
          setIsUpdateOxyVent(false);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    if (
      patientVisitId !== undefined ||
      patientVisitId === null ||
      props.admissionId
    ) {
      setOpenPost(false);
      setOpenBackdrop(true);
      //ventilation
      if (counterOf === "Ventilation") {
        if (
          props.ventilationIsActive === false &&
          props.ventilationIsPaused === false
        ) {
          handleStartVentilation();
          handlePauseOxygen();
        } else if (props.ventilationIsPaused === true) {
          props.setVentilationIsActive(false);
          props.setVentilationIsPaused(false);
          handlePauseVentilation();
        }
      } else if (counterOf === "Oxygen") {
        if (props.oxygenIsActive === false && props.oxygenIsPaused === false) {
          handleStartOxygen();
          handlePauseVentilation();
        } else if (props.oxygenIsPause === true) {
          props.setOxygenIsActive(false);
          props.setOxygenIsPaused(false);
          handlePauseOxygen();
        }
      }

      postOxygenVentilation(finalData);
    } else if (
      patientVisitId === undefined ||
      patientVisitId === null ||
      props.admissionId
    ) {
      warningAlert("Please Select Patient...!");
    }
  }

  let modeWatchVal = watch("mode");
  const onSubmitDataHandler = (data) => {
    if (findClick === "Start") {
      let postedObj = {
        menuId: props.menuId,
        privilege: props.privilege,
        addedBy: getUserId.userId,
        admissionId: admissionId || props.admissionId,
        epap: data.epap,
        fio2: data.fiO2,
        flowTrigger: data.flowTrigger,
        ie: data.ie,
        ipap: data.ipap,
        minutv: data.minuteV,
        mode: data.mode.label,
        mv: data.mv,
        oxyRatePerMin: data.oxygenRatePerMin,
        oxySaturationWithO2: data.saturationWithO2,
        oxygen: oxygenValue,
        pc: data.pc,
        peep: data.peep,
        prsup: data.prSup,
        rateTotal: data.rateTotal,
        setRate: data.setRate,
        tidolV: data.tidolV,
        ventilation: vantilationValue,
        ventilationId: 0,
        isUpdate: isUpdateOxyVent,
      };

      //Proper condition CORRECT
      if (vantilationValue === 1) {
        postedObj.startDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.startTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      } else if (oxygenValue === 1) {
        postedObj.oxyStartDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.oxyStartTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      }
      if (vantilationValue === 0) {
        postedObj.endDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.endTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      } else if (oxygenValue === 0) {
        postedObj.oxyEndDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.oxyEndTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      }
      setOpenPost(true);
      setFinalData(postedObj);
    } else if (findClick === "Update" && patientInfo !== null) {
      let postedObj = {
        menuId: props.menuId,
        privilege: props.privilege,
        addedBy: getUserId.userId,
        admissionId: admissionId || props.admissionId,
        epap: data.epap,
        fio2: data.fiO2,
        flowTrigger: data.flowTrigger,
        ie: data.ie,
        ipap: data.ipap,
        minutv: data.minuteV,
        mode: data.mode.label,
        mv: data.mv,
        oxyRatePerMin: data.oxygenRatePerMin,
        oxySaturationWithO2: data.saturationWithO2,
        oxygen: patientInfo.Oxygen,
        pc: data.pc,
        peep: data.peep,
        prsup: data.prSup,
        rateTotal: data.rateTotal,
        setRate: data.setRate,
        tidolV: data.tidolV,
        ventilation: patientInfo.Ventilation,
        ventilationId: ventilationId,
        isUpdate: isUpdateOxyVent,
      };
      setOpenPost(true);
      setFinalData(postedObj);
    } else if (findClick === "Stop" && patientInfo !== null) {
      let postedObj = {
        //using Data obj
        menuId: props.menuId,
        privilege: props.privilege,
        addedBy: getUserId.userId,
        admissionId: admissionId || props.admissionId,
        epap: data?.epap,
        fio2: data?.fiO2,
        flowTrigger: data?.flowTrigger,
        ie: data?.ie,
        ipap: data?.ipap,
        minutv: data?.minuteV,
        mode: data.mode?.label,
        mv: data?.mv,
        oxyRatePerMin: data?.oxygenRatePerMin,
        oxySaturationWithO2: data?.saturationWithO2,
        oxygen: oxygenValue,
        pc: data?.pc,
        peep: data?.peep,
        prsup: data?.prSup,
        rateTotal: data?.rateTotal,
        setRate: data?.setRate,
        tidolV: data?.tidolV,
        ventilation: vantilationValue,
        ventilationId: ventilationId,
        isUpdate: isUpdateOxyVent,

        //using Pinfo Object
        // menuId: props.menuId,
        // privilege: props.privilege,
        // addedBy: getUserId.userId,
        // admissionId: admissionId || props.admissionId,
        // epap: patientInfo?.epap,
        // fio2: patientInfo?.fio2,
        // flowTrigger: patientInfo?.["Flow Trigger"],
        // ie: patientInfo?.ie,
        // ipap: patientInfo?.ipap,
        // minutv: patientInfo?.minutv,
        // mode: patientInfo?.Mode,
        // mv: patientInfo?.mv,
        // oxyRatePerMin: patientInfo?.["Oxygen Rate Per Min"],
        // oxySaturationWithO2: patientInfo?.["Oxygen Saturation With O2"],
        // oxygen: oxygenValue,
        // pc: patientInfo?.pc,
        // peep: patientInfo?.peep,
        // prsup: patientInfo?.prsup,
        // rateTotal: patientInfo?.["Rate total"],
        // setRate: patientInfo?.setrate,
        // tidolV: patientInfo?.tidolv,
        // ventilation: vantilationValue,
        // ventilationId: ventilationId,
      };
      //Proper condition CORRECT
      if (vantilationValue === 1) {
        postedObj.startDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.startTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      } else if (oxygenValue === 1) {
        postedObj.oxyStartDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.oxyStartTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      }
      if (vantilationValue === 0) {
        postedObj.endDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.endTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      } else if (oxygenValue === 0) {
        postedObj.oxyEndDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        postedObj.oxyEndTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      }

      setOpenPost(true);
      setFinalData(postedObj);
    }
  };

  // oxygen start stop
  const handleStartOxygen = () => {
    props.setOxygenIsActive(true);
    props.setOxygenIsPaused(true);
    props.oxygenIncrement.current = setInterval(() => {
      props.setOxygenTimer((oxygenTimer) => oxygenTimer + 1);
    }, 1000);
  };

  const handlePauseOxygen = () => {
    clearInterval(props.oxygenIncrement.current);
  };

  // Vantilation start stop
  const handleStartVentilation = () => {
    props.setVentilationIsActive(true);
    props.setVentilationIsPaused(true);
    props.ventilationIncrement.current = setInterval(() => {
      props.setVentilationTimer((ventilationTimer) => ventilationTimer + 1);
    }, 1000);
  };

  const handlePauseVentilation = () => {
    clearInterval(props.ventilationIncrement.current);
  };

  //Ventilation
  useEffect(() => {
    if (data !== null) {
      const selectedDate = new Date(data?.StartDateTimePatch);
      if (data?.StartDateTimePatch) {
        function updateClock() {
          const currentDate = new Date();
          const timeDifference = currentDate.getTime() - selectedDate.getTime();
          const calculatedHours = Math.floor(timeDifference / (1000 * 60 * 60));
          const calculatedMinutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const calculatedSeconds = Math.floor(
            (timeDifference % (1000 * 60)) / 1000
          );
          props.setHours(calculatedHours);
          props.setMinutes(calculatedMinutes);
          props.setSeconds(calculatedSeconds);
        }
        const intervalId = setInterval(updateClock, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [data]);

  //Oxygen
  useEffect(() => {
    if (data !== null) {
      const selectedDate = new Date(data?.oxystartdatetimePatch);
      if (data?.oxystartdatetimePatch) {
        function updateClock() {
          const currentDate = new Date();
          const timeDifference = currentDate.getTime() - selectedDate.getTime();
          const calculatedHours = Math.floor(timeDifference / (1000 * 60 * 60));
          const calculatedMinutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const calculatedSeconds = Math.floor(
            (timeDifference % (1000 * 60)) / 1000
          );
          props.setOxygenHours(calculatedHours);
          props.setOxygenMinutes(calculatedMinutes);
          props.setOxygenSeconds(calculatedSeconds);
        }
        // Update the clock every second
        const intervalId = setInterval(updateClock, 1000);
        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data !== null) {
      if (data.vention !== 0 || data.oxyon !== 0) {
        if (modeWatchVal?.label !== undefined) {
          let modeVal = {
            id: data.modeId,
            label: data.Mode,
            value: data.modeId,
          };
          setValue("mode", modeVal ? modeVal : null);
        }
      } else {
        setValue("mode", null);
      }
    }
  }, [data]);

  const handleSelectedRow = (row, index) => {
    // setRowIndex(index);
    setPatientInfo(row);
    setVentilationId(row?.ventilationId);
    editRow(row, index);
  };

  //event listener function for edit icon
  function editRow(requiredRow) {
    setVentilationId(requiredRow?.ventilationId);
    let modeVal = {
      id: requiredRow.Mode,
      label: requiredRow.Mode,
      value: requiredRow.Mode,
    };

    let resetObj = {
      mode: modeVal,
      tidolV: requiredRow["tidolv"],
      setRate: requiredRow["setrate"],
      ipap: requiredRow["ipap"],
      minuteV: requiredRow["minutv"],
      rateTotal: requiredRow["Rate total"],
      epap: requiredRow["epap"],
      peep: requiredRow["peep"],
      //prsup: requiredRow["prsup"],
      pc: requiredRow["pc"],
      mv: requiredRow["mv"],
      prSup: requiredRow["prsup"],
      fiO2: requiredRow["fio2"],
      ie: requiredRow["ie"],
      oxygenRatePerMin: requiredRow["Oxygen Rate Per Min"],
      saturationWithO2: requiredRow["Oxygen Saturation With O2"],
      flowTrigger: requiredRow["Flow Trigger"],

      ventilation: requiredRow.Ventilation,
      oxygen: requiredRow.Oxygen,
      ventilationId: requiredRow.ventilationId,
      StartDateTime: requiredRow?.["Ventilation Start DateTime"],
      EndDateTime: requiredRow?.["Ventilation End DateTime"],

      oxystartdatetime: requiredRow?.["Oxygen Start DateTime"],
      oxyenddatetime: requiredRow?.["Oxygen End DateTime"],

      isUpdate: isUpdateOxyVent,
    };
    reset(resetObj);
  }

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deleteOxygenVentilion(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            populateTable();
            handlePauseVentilation();
            // // handlePauseOxygen();

            // // props.setOxygenIsActive(false);
            // // props.setOxygenIsPaused(false);
            // // props.setVentilationIsActive(false);
            // // props.setVentilationIsPaused(false);
            reset(defaultValues);
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }

  const rowBackgroundColor = (row, index) =>
    row?.Oxygen > 0 || row?.Ventilation > 0 ? "#fca5a5" : undefined;

  const renderActions = (row, index) => {
    return (
      <div className="flex items-center">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true && obj.action === "Delete" ? (
              <>
                {!props.selectedPatient?.AllowToGO && (
                  <DeleteOnIcon
                    title="Delete"
                    onClick={() => {
                      // if(data?.StartDateTimePatch !== null || data?.StartDateTimePatch !== undefined){
                      // warningAlert("Kindly Stop Ventilattion...!")
                      // }
                      reset(defaultValues);
                      let deleteObjVal = {};

                      deleteObjVal.Id = row.Id;

                      deleteObjVal.action = obj.action;

                      setDeleteObj(deleteObjVal);

                      // //open the delete confirmation modal
                      handleOpenDeleteConfirm();
                      reset(defaultValues);
                    }}
                  />
                )}

                <EyeOnIcon
                  onClick={() => {
                    setOpenOldRecordModal(true);
                    setSelectedRecordRow(row?.VentilationOxygenDetails);
                  }}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="grid grid-cols-3 gap-4 w-full">
          <div className="col-span-3 w-full ">
            <div className="flex justify-between">
              <h1 className="my-1 text-sm font-semibold whitespace-nowrap">
                Oxygen / Ventilation
              </h1>
              <div className="flex gap-3 items-center">
                <div className="h-5 w-5 border rounded bg-[#fca5a5]"></div>
                <div className="">Oxygen / Ventilation Start</div>
              </div>
            </div>

            {spinner ? (
              <div className="grid justify-center">
                <LoadingSpinner />
              </div>
            ) : null}

            {dataResult.length > 0 && spinner === false ? (
              <CommonDynamicTableNew
                dataResult={dataResult}
                tableClass={"rounded h-56 2xl:h-72 border"}
                highlightRow={true}
                handleSelectedRow={handleSelectedRow}
                renderActions={renderActions}
                rowBackgroundColor={rowBackgroundColor}
                removeHeaders={[
                  "StartDateTimePatch",
                  "oxystartdatetimePatch",
                  "Id",
                  "ventilationId",
                  "oxygenEndDateTime",
                  "oxygenStartDateTime",
                  "oxystartdatetimePatch",
                  "ventilationEndDateTime",
                  "ventilationStartDateTime",
                  "Flow Trigger",
                  "Ventilation",
                  "Oxygen",
                  "tidolv",
                  "minutv",
                  "peep",
                  "prsup",
                  "setrate",
                  "Rate total",
                  "ie",
                  "pc",
                  "fio2",
                  "epap",
                  "ipap",
                  "mv",
                  "Oxygen Saturation With O2",
                  "Oxygen Rate Per Min",
                  "VentilationOxygenDetails",
                ]}
              />
            ) : (
              <h3 className="flex justify-center my-5 font-bold text-gray-600">
                No Records Found...
              </h3>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="grid w-2/3 py-2">
            <DropdownField
              control={control}
              name="mode"
              placeholder="Mode"
              dataArray={ventilationModesOptions}
              isSearchable={false}
              // isDisabled={data?.vention === 1 || data?.oxyon === 1 ? true : false}
            />
          </div>
          <div className="w-full grid grid-cols-3 gap-2">
            <InputField
              name="tidolV"
              variant="outlined"
              label="Tidol V (ml)"
              control={control}
            />
            <InputField
              name="setRate"
              variant="outlined"
              label="Set.Rate(per min)"
              control={control}
            />
            <InputField
              name="ipap"
              variant="outlined"
              label="IPAP"
              control={control}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <InputField
              name="minuteV"
              variant="outlined"
              label="Minute V (lit./min)"
              control={control}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <InputField
              name="rateTotal"
              variant="outlined"
              label="Rate Total"
              control={control}
            />
            <InputField
              name="epap"
              variant="outlined"
              label="EPAP"
              control={control}
            />
            <InputField
              name="peep"
              variant="outlined"
              label="Peep (cm)"
              control={control}
            />
            <InputField
              name="pc"
              variant="outlined"
              label="PC"
              control={control}
            />
            <InputField
              name="mv"
              variant="outlined"
              label="% MV"
              control={control}
            />
            <InputField
              name="prSup"
              variant="outlined"
              label="Pr.Sup.(cm)"
              control={control}
            />
            <InputField
              name="fiO2"
              variant="outlined"
              label="fiO2"
              control={control}
            />
            <InputField
              name="ie"
              variant="outlined"
              label="I : E"
              control={control}
            />
            <InputField
              name="oxygenRatePerMin"
              variant="outlined"
              label="Oxygen Rate Per Min"
              control={control}
            />
            <InputField
              name="saturationWithO2"
              variant="outlined"
              label="Saturation With O2"
              control={control}
            />
            <InputField
              name="flowTrigger"
              variant="outlined"
              label="Flow Trigger"
              control={control}
            />
          </div>

          <div className="grid grid-cols-2 w-5/12 gap-2 items-center mt-2 ">
            {data?.oxyon === 1 ? (
              <div className="flex space-x-2">
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {formatTimeValue(props.oxygenhours)}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {formatTimeValue(props.oxygenminutes)}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {formatTimeValue(props.oxygenseconds)}
                </h1>
              </div>
            ) : (
              <div className="flex space-x-2">
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {getOxygenHours}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {getOxygenMinutes}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {getOxygenSeconds}
                </h1>
              </div>
            )}

            {/* {data?.vention !== 1 ? ( */}
            {props.userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <div className="flex space-x-2">
                      {data?.oxyon !== 1 ? (
                        <>
                          {props.patientInformation?.AllowToGO === true ||
                          props.selectedPatient?.AllowToGO ===
                            true ? null : (
                            <CommonButton
                              type={"submit"}
                              className="bg-customBlue text-white"
                              label="Oxygen Start"
                              disabled={
                                data?.vention === 1 
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                setFindClick("Start");
                                setCounterOf("Oxygen");
                                if (modeWatchVal?.label !== undefined) {
                                  props.setPrivilege(obj.action);
                                  setOxygenValue(1);
                                  setOpenPost(true);
                                  setIsUpdateOxyVent(false);
                                } else if (modeWatchVal?.label === undefined) {
                                  setOpenPost(false);
                                  setOxygenValue(0);
                                  warningAlert("Please Select Mode...!");
                                }
                              }}
                              // disabled={data?.vention === 1}
                            />
                          )}
                        </>
                      ) : (
                        <CommonButton
                          type={
                            patientInfo?.Oxygen > 0 ||
                            patientInfo?.Ventilation > 0
                              ? "submit"
                              : "button"
                          }
                          className="bg-customRed text-white"
                          label="Oxygen Stop"
                         
                          onClick={() => {
                            if (
                              patientInfo?.Oxygen > 0 ||
                              patientInfo?.Ventilation > 0
                            ) {
                              setFindClick("Stop");
                              if (patientInfo !== null) {
                                props.setPrivilege(obj.action);
                                setCounterOf("Oxygen");
                                // setVentilationId(patientInfo?.ventilationId);
                                setOpenPost(true);
                                setIsUpdateOxyVent(false);

                                setIsUpdateOxyVent(false);

                                handlePauseOxygen();
                                setOxygenValue(0);
                              } else if (patientInfo === null) {
                                setOpenPost(false);
                                warningAlert("Please Select Row...!");
                              }
                            } else {
                              warningAlert(
                                "Please select correct record to stop."
                              );
                              setOpenPost(false);
                            }
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ))}
            {/* ) : null} */}
          </div>
          <div className="grid grid-cols-2 w-5/12 gap-2 items-center mt-2 ">
            {data?.vention === 1 ? (
              <div className="flex space-x-2">
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {formatTimeValue(props.hours)}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {formatTimeValue(props.minutes)}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {formatTimeValue(props.seconds)}
                </h1>
              </div>
            ) : (
              <div className="flex space-x-2">
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {getVentilationHours}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {getVentilationMinutes}
                </h1>
                <h1 className="border h-9 text-sm bg-white border-gray-300 p-2 w-56 text-center rounded">
                  {getVentilationSeconds}
                </h1>
              </div>
            )}

            {props.userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    {/* {data?.oxyon !== 1 ? ( */}
                    <div className="flex space-x-2">
                      {data?.vention !== 1 ? (
                        <>
                          {props.patientInformation?.AllowToGO === true ||
                          props.selectedPatient?.AllowToGO ===
                            true ? null : (
                            <CommonButton
                              type="submit"
                              className="bg-customBlue text-white"
                              label="Ventilation Start"
                              onClick={() => {
                                setFindClick("Start");
                                setCounterOf("Ventilation");
                                if (modeWatchVal?.label !== undefined) {
                                  props.setPrivilege(obj.action);
                                  setvantilationValue(1);
                                  setOpenPost(true);
                                  setIsUpdateOxyVent(false);
                                } else if (modeWatchVal?.label === undefined) {
                                  setOpenPost(false);
                                  setvantilationValue(0);
                                  warningAlert("Please Select Mode...!");
                                }
                              }}
                              // disabled={data?.oxyon === 1}
                              disabled={
                                data?.oxyon === 1 
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </>
                      ) : (
                        <CommonButton
                        type={
                          patientInfo?.Oxygen > 0 ||
                          patientInfo?.Ventilation > 0
                            ? "submit"
                            : "button"
                        }                          className="bg-customRed text-white"
                          label="Ventilation Stop"
                         
                          onClick={() => {
                            if (
                              patientInfo?.Oxygen > 0 ||
                              patientInfo?.Ventilation > 0
                            ) {
                              setFindClick("Stop");
                              if (patientInfo !== null) {
                                props.setPrivilege(obj.action);
                                // setVentilationId(patientInfo?.ventilationId);
                                setCounterOf("Ventilation");
                                setOpenPost(true);
                                setIsUpdateOxyVent(false);

                                handlePauseVentilation();
                                setvantilationValue(0);
                              } else if (patientInfo === null) {
                                setOpenPost(false);
                                warningAlert("Please Select Row...!");
                              }
                            } else {
                              warningAlert(
                                "Please select correct record to stop."
                              );
                              setOpenPost(false);
                            }
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>

          {props.userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <div className="flex justify-end space-x-3 py-2">
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        props.setPrivilege(obj.action);
                        reset(defaultValues);
                        setCounterOf("");
                        clearInterval(props.oxygenIncrement.current);
                        setPatientInfo(null);
                        setvantilationValue(0);
                        setOxygenValue(0);

                        setDataResult([...dataResult]);

                        props.setOxygenTimer(0);
                        props.setOxygenIsActive(false);
                        props.setOxygenIsPaused(false);
                        //ventilation
                        clearInterval(props.ventilationIncrement.current);
                        props.setVentilationTimer(0);
                        props.setVentilationIsActive(false);

                        props.setHours(0);
                        props.setMinutes(0);
                        props.setSeconds(0);

                        props.setOxygenHours(0);
                        props.setOxygenMinutes(0);
                        props.setOxygenSeconds(0);
                      }}
                    />
                    {props.patientInformation?.AllowToGO === true ||
                    props.selectedPatient?.AllowToGO === true ? null : (
                      <>
                        {patientInfo !== null ? (
                          <CommonButton
                            type="submit"
                            className="bg-customGreen text-white"
                            label="Update"
                            
                            onClick={() => {
                              if (patientInfo !== null) {
                                setFindClick("Update");
                                props.setPrivilege(obj.action);
                                setIsUpdateOxyVent(true);
                              } else if (patientInfo === null) {
                                setOpenPost(false);
                                warningAlert("Please Select Row");
                              }
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </form>
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />

        <ConfirmationModal
          confirmationOpen={deleteconfirm}
          confirmationHandleClose={handleCloseDeleteConfirm}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this record ?"
          confirmationButtonMsg="Delete"
        />

        {openOldRecordModal ? (
          <OldRecordModal
            open={openOldRecordModal}
            setOpen={setOpenOldRecordModal}
            handleOpen={() => setOpenOldRecordModal(true)}
            handleClose={() => setOpenOldRecordModal(false)}
            selectedRecordRow={selectedRecordRow}
          />
        ) : null}
      </div>
    </>
  );
}

export default OxygenVentilation;
