import { Button } from "@mui/material";
import React from "react";

// for all Forms
// mui version
export function SubmitButton({ onClick }) {
  return (
    // <Button type="submit" variant="contained" color="success">
    //   Submit
    // </Button>

    <button
      type="submit"
      className="h-9  px-3  bg-green-600  text-white rounded text-sm font-medium"
      onClick={onClick}
    >
      Submit
    </button>
  );
}
export function CustomSubmitButton({ label }) {
  return (
    <Button type="submit" variant="contained" color="success">
      {label}
    </Button>
  );
}
export function ResetButton({ onClick }) {
  return (
    <Button
      type="reset"
      variant="outlined"
      color="error"
      sx={{ border: "1px solid" }}
      onClick={onClick}
    >
      Reset
    </Button>
  );
}

export function CancelButton({ onClick }) {
  return (
    <Button
      variant="outlined"
      type="button"
      color="error"
      sx={{
        border: "1px solid",
        textTransform: "none",
        fontSize: "14px",
        height: "36.3px",
      }}
      onClick={onClick}
    >
      Cancel
    </Button>
  );
}
export function ConfirmButton({ onClick, confirmationButtonMsg }) {
  return (
    <Button
      variant="contained"
      color="success"
      onClick={onClick}
      sx={{ textTransform: "none", fontSize: "14px", height: "36.3px" }}
    >
      {confirmationButtonMsg}
    </Button>
  );
}

// for lists
export function AddNewButton({ label }) {
  return (
    <Button
      type="submit"
      variant="contained"
      color="success"
      sx={{ textTransform: "none" }}
    >
      + Add {label}
    </Button>
  );
}

///
// tailwind version

export function AddButton() {
  return (
    <div>
      <button
        type="submit"
        className="h-[38px] px-3 text-base font-medium  bg-green-700 text-white rounded lowercase "
      >
        Add
      </button>
    </div>
  );
}
// export function ConfirmButton({onClick}) {
// return (
//     <div>
//     <button
//         type="button"
//         className="h-[38px] px-3 text-base font-medium  bg-green-700 text-white rounded "
//         onClick={onClick}
//     >
//         Confirm
//     </button>
//     </div>
// );
// }

// export function SubmitButton() {
//     return (
//       <div>
//         <button
//           type="submit"
//           className="h-[38px] px-3 text-base font-medium  bg-green-700 text-white rounded "
//         >
//           Submit
//         </button>
//       </div>
//     );
//   }

export function SaveButton() {
  return (
    <div>
      <button
        type="submit"
        className="h-9 px-3  bg-green-700 text-white rounded text-base font-medium "
      >
        Save
      </button>
    </div>
  );
}

// export function CancelButton({ onClick }) {
// return (
//     <div>
//     <button
//         type="button"
//         className="h-[38px] px-3 border border-red-600 text-red-600 rounded text-base font-medium"
//         onClick={onClick}
//     >
//         Cancel
//     </button>
//     </div>
// );
// }

// export function ResetButton({ onClick }) {
// return (
//     <div>
//     <button
//         type="button"
//         className="h-[38px] px-3 border border-red-600 text-red-600 rounded text-base font-medium"
//         onClick={onClick}
//     >
//         Reset
//     </button>
//     </div>
// );
// }

export function UpdateButton() {
  return (
    <div>
      <button
        type="submit"
        className="h-[38px] px-3  bg-green-700 text-white rounded text-base font-medium"
      >
        Update
      </button>
    </div>
  );
}

// For Lists
// export function AddNewButton({ onClick }) {
// return (
//     <div>
//     <button
//         type="submit"
//         className="h-[38px] px-3 w-full bg-green-700 text-white rounded text-base font-medium"
//         onClick={onClick}
//     >
//         + Add New
//     </button>
//     </div>
// );
// }
