import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Pako from "pako";
import * as React from "react";
import { useEffect, useState } from "react";
import UTIF from "utif";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ImageViewer from "../../../common/components/imageViewer/ImageViewer";
import { baseUrl } from "../../../http-common";
import {
  fetchPatientClearanceDocumentPath,
  getOldTiffClearanceDocuments,
} from "../../services/patientClearenceServices/PatientClearenceServices";
import loadingImage from "./loadingImage.png";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  minHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function ViewDocumentsModal(props) {
  const { handleClose, open, selctedPatient } = props;
  //
  //
  const [viewDocuments, setViewDocuments] = useState([]);
  //
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [canvasPaths, setCanvasPaths] = React.useState([]);
  //

  useEffect(() => {
    getViewDocuments();
  }, []);

  function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  function isDataCompressed(arrayBuffer) {
    try {
      // Try to decompress the data
      const decompressedData = Pako.inflate(arrayBuffer, { to: "string" });
      // If decompression succeeds and the data is valid JSON, it was compressed
      JSON.parse(decompressedData);
      return true;
    } catch (e) {
      // If an error occurs during decompression or JSON parsing, it's not compressed
      return false;
    }
  }

  const getDocument = async (
    pathOne,
    pathTwo,
    pathThree,
    pathFour,
    IsFromNew
  ) => {
    if (
      pathOne !== undefined &&
      pathTwo !== undefined &&
      pathThree !== undefined &&
      pathFour !== undefined
    ) {
      let imageArray = [...viewDocuments];
      try {
        const response = await axios.get(
          `${baseUrl}/getDocument/${pathOne}/${pathTwo}/${pathThree}/${pathFour}`,
          {
            responseType: "arraybuffer",
          }
        );

        if (IsFromNew === true) {
          const compressedArraybuffer = response.data;

          const decompressedArrayBuffer = Pako.inflate(compressedArraybuffer);

          var apiResponseImageData = decompressedArrayBuffer;

          var ifds = UTIF.decode(apiResponseImageData);
          ifds.map(function (ifd, index) {
            UTIF.decodeImage(apiResponseImageData, ifd);
            var rgba = UTIF.toRGBA8(ifd);
            var canvas = document.createElement("canvas");
            canvas.width = ifd.width;
            canvas.height = ifd.height;
            var ctx = canvas.getContext("2d");
            var img = ctx.createImageData(ifd.width, ifd.height);
            img.data.set(rgba);
            ctx.putImageData(img, 0, 0);
            let image = canvas.toDataURL();
            imageArray.push(image);
            setViewDocuments((prev) => [...prev, image]);

            document
              .getElementById(`tiff-inner-container${index}`)
              .appendChild(canvas);
            return canvas;
          });
        } else {
          var ifds = UTIF.decode(response.data);
          ifds.map(function (ifd, index) {
            UTIF.decodeImage(response.data, ifd);
            var rgba = UTIF.toRGBA8(ifd);
            var canvas = document.createElement("canvas");
            canvas.width = ifd.width;
            canvas.height = ifd.height;
            var ctx = canvas.getContext("2d");
            var img = ctx.createImageData(ifd.width, ifd.height);
            img.data.set(rgba);
            ctx.putImageData(img, 0, 0);
            let image = canvas.toDataURL();
            imageArray.push(image);
            setViewDocuments((prev) => [...prev, image]);

            document
              .getElementById(`tiff-inner-container${index}`)
              .appendChild(canvas);
            return canvas;
          });
        }
      } catch {}
    }
  };

  const getViewDocuments = () => {
    let imageArray = [];

    fetchPatientClearanceDocumentPath(selctedPatient?.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        //###################
        setCanvasPaths(res.result);
        if (res?.result?.length > 0) {
          for (let i = 0; i <= res.result.length; i++) {
            let modifiedPath = res.result[i]?.FilePath?.toString()
              ?.replaceAll("\\", ",")
              ?.split(",");

            console.log("res.result", modifiedPath);
            setTimeout(() => {
              getDocument(
                modifiedPath?.[0],
                modifiedPath?.[1],
                modifiedPath?.[2],
                modifiedPath?.[3],
                res.result[i]?.IsFromNew
              );
            }, 100);
          }
        }
      });
  };

  const openViewer = (index) => {
    console.log("openViewer");
    setSelectedImageIndex(index);
    setIsViewerOpen(true);
  };

  console.log("isViewerOpen", isViewerOpen);
  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <>
      {canvasPaths.map((item, index) => {
        return (
          <canvas
            id={`tiff-inner-container${index}`}
            className="h-[300px] w-[400px] hidden"
          />
        );
      })}
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
            <CancelPresentationIconButton
              onClick={(e) => {
                handleClose();
                e.preventDefault();
              }}
            />
            <div>
              <div className="my-2">
                <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                  Nursing Clearance
                </span>
              </div>
              <div className="border border-gray lg:h-60 xl:h-[35rem] overflow-y-auto">
                <div>
                  <div className="flex p-2 px-10 gap-3 items-center flex-wrap overflow-y-auto">
                    {canvasPaths.map((documents, index) => {
                      let documentId = document.getElementById(
                        `clearanceDocument${index}`
                      );
                      if (documentId) {
                        if (viewDocuments[index]) {
                          documentId.src = viewDocuments[index];
                        } else {
                          documentId.src = loadingImage;
                        }
                      }
                      return (
                        <div
                          className="border border-gray-200 h-56 w-44 relative"
                          onClick={() => openViewer(index)}
                        >
                          <img
                            id={`clearanceDocument${index}`}
                            className="object-contain h-full w-full"
                          />
                        </div>
                      );
                    })}
                  </div>

                  {/* Render full image*/}
                  {isViewerOpen && (
                    <ImageViewer
                      open={isViewerOpen}
                      images={viewDocuments}
                      onClose={closeViewer}
                      initialIndex={selectedImageIndex}
                    />
                  )}
                </div>
              </div>

              <div className="flex gap-3 justify-end my-2">
                <div>
                  <CommonButton
                    label="Close"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

