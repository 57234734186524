import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getToStoreDropdown = (stores) => {
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};
export const getItemClassType = () => {
  return apiClient.get(`/patientIndent/itemClass`, {
    headers: authHeader(),
  });
};

export const autoItemSearchStore = (obj) => {
  return apiClient.post(`/patientIndent/itemDetailsSearch`, obj, {
    headers: authHeader(),
  });
};
export const fetchAllIndentStores = async (searchObj) => {
  return await apiClient.post(`/patientIndent/indentList`, searchObj, {
    headers: authHeader(),
  });
};
export const getAllIndentDetailsList = (indentId) => {
  return apiClient.get(`/patientIndent/indentItemDetailsList/${indentId}`, {
    headers: authHeader(),
  });
};

export const isVerifyIndent = (indentId) => {
  return apiClient.post(`/patientIndent/verifyIndent`, indentId, {
    headers: authHeader(),
  });
};
//
export const isCloseIndent = (closeIndentObj) => {
  return apiClient.post(`/patientIndent/closeIndent`, closeIndentObj, {
    headers: authHeader(),
  });
};
//
export const getIndentForEdit = (indentId) => {
  return apiClient.get(`/patientIndent/getIndentForEdit/${indentId}`, {
    headers: authHeader(),
  });
};

export const getIndentListPrint = (obj) => {
  return apiClient.post(`/reports/inventory/getIndentList`, obj, {
    headers: authHeader(),
  });
};

export const getItemForAutoIndent = (autoIndentObj) => {
  return apiClient.post(`/patientIndent/getItemForAutoIndent`, autoIndentObj, {
    headers: authHeader(),
  });
};
