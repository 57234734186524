import * as React from "react";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import OPDConcessionManagerListing from "./OPDConcessionManagerListing";

import { yupResolver } from "@hookform/resolvers/yup";
import RadioField from "../../../../common/components/FormFields/RadioField";

import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  PostOpdConsationInfo,
  concessionManagerAutoSearchPatient,
  concessionManagerPatientInfoById,
  fetchConcessionAuthority,
  fetchConcessionCategory,
  fetchConcessionManagerChargesList,
} from "../../../services/ConcessionManagerServices";
import PreviousConcessionModal from "./PreviousConcessionModal";

const patienttype = [
  {
    id: "Concession",
    value: "Concession",
    label: "Concession",
  },
];
const percentageAmountConcession = [
  {
    id: "Percentage",
    value: "Percentage",
    label: "Percentage",
  },
  {
    id: "Amount",
    value: "Amount",
    label: "Amount",
  },
];


export default function OPDConcessionManager() {
  let location = useLocation();
  //
  const schema = yup.object().shape({
    concession: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, "Amount Should be in numbers only")
      .notRequired(),
    totalBillAmount: yup.string(),
    totalAmountTax: yup.string(),
    totalAmountWithConcession: yup.string(),
    totalConcession: yup.string(),
    totalAmountWithoutConcession: yup.string(),
    netPayableAmount: yup.string(),

    concessioncategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),

    Concessionauthorisedby: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),

    remarks: yup.string(),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      totalBillAmount: "",
      balanceBillAmount: "",
      percentageAmountConcession: "Percentage",
      concession: 0,
      concessioncategory: "",
      Concessionauthorisedby: "",
      remarks: "",
      balanceAmount: 0,
      totalAmountWithConcession: 0,
      totalConcession: "",
      totalAmountWithoutConcession: "",
      netPayableAmount: "",
    },
  });

  const {
    formState: { errors},
    control,
    setError,
    clearErrors,
    watch,
    register,
    setValue,
  } = methods;
  //
  const [userActions, setUserActions] = useState([]);
  //state variable
  const [options, setOptions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientById, setSelectedPatientById] = useState(null);
  // const [tableRowData, setTableRowData] = useState(0);
  const [percentage, setPercentage] = useState();
  const [amount, setAmount] = useState();
  const [concessionCategory, setConcessionCategory] = useState();
  const [concessionAuthority, setConcessionAuthority] = useState();
  const [concessionTableData, setConcessionTableData] = useState([]);
  const [privilege, setPrivilege] = React.useState(null);
  //errors
  const [customError, setCustomError] = useState();
  //FormFields Data
  const [concessionFormData, setConcessionFormData] = useState();
  // confirmation modal
  const [openModal, setOpenModal] = useState(false);
  //Backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //
  //indent modal
  const [openPreviousConcModal, setOpenPreviousConcModal] = React.useState(
    false
  );
  const handleOpenPreviousConcModal = () => setOpenPreviousConcModal(true);
  const handleClosePreviousConcModal = () => setOpenPreviousConcModal(false);
  //

  //other variables
  var concessionCheck = watch("concessionCheck");
  let watchTotalAmount = watch("totalBillAmount");
  var TypeOfConcession = watch("percentageAmountConcession");
  let concessionAmt = watch("concession");

  //autoSearch ...patient list
  const handleChange = (e) => {
    let opdIpd = 0;
    if (e !== undefined && e !== "" && UseCheckConsecutiveCharacters(e)) {
      concessionManagerAutoSearchPatient(e, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };
  //get  selected patient details
  function getPatientInformationById(patientId) {
    let opdIpd = 0;
    concessionManagerPatientInfoById(patientId, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setSelectedPatientById(res.result);
      });
  }
  //get concession services listing data(Table data)
  function fecthConcessionManagerListing(BillId) {
    fetchConcessionManagerChargesList(BillId)
      .then((response) => response.data)
      .then((res) => {
        setConcessionTableData(res.result);
        // setCalNetAmount(res);
      });
  }
  //get table row data
  // function getTableRowData(row, index) {
  //   setTableRowData(row);
  // }

  useEffect(() => {
    //concession category dropdown
    fetchConcessionCategory()
      .then((response) => response.data)
      .then((res) => {
        setConcessionCategory(res.result);
      });

    //concession category dropdown
    fetchConcessionAuthority()
      .then((response) => response.data)
      .then((res) => {
        setConcessionAuthority(res.result);
      });
  }, []);

  useEffect(() => {
    if (selectedPatientById !== null) {
      //1.
      setValue("totalBillAmount", selectedPatientById.TotalAmount);
      //2.
      setValue("balanceBillAmount", selectedPatientById.BalanceAmount);
    }
  }, [selectedPatientById]);

  const handleConcession = (e) => {
    /// case-1:acording to conc percentage
    if (TypeOfConcession === "Percentage") {
      let enteredValue = e.target.value;

      let concAmount = (enteredValue * selectedPatientById.BalanceAmount) / 100;

      //2.
      setValue("totalConcession", concAmount.toFixed(2));
      //3.
      setValue(
        "netPayableAmount",
        (selectedPatientById.BalanceAmount - concAmount).toFixed(2)
      );
      //4.
      setValue(
        "totalAmountWithConcession",
        (selectedPatientById.BalanceAmount - concAmount).toFixed(2)
      );
      //5.
      setValue(
        "totalAmountWithoutConcession",
        selectedPatientById.BalanceAmount.toFixed(2)
      );

      ///errors acoording percentage

      if (enteredValue > 100) {
        setError("concession", {
          type: "custom",
          message: "concession should not be more than 100%",
        });
        setCustomError(true);
      } else if (enteredValue <= 100) {
        clearErrors("concession");
        setCustomError(false);
      }
    }
    /// case-2:acording to conc Amount
    else if (TypeOfConcession === "Amount") {
      let enteredValue = e.target.value;

      setValue(
        "netPayableAmount",
        selectedPatientById.BalanceAmount - Number(enteredValue)
      );
      setValue(
        "totalAmountWithConcession",
        selectedPatientById.BalanceAmount - Number(enteredValue)
      );
      setValue("totalConcession", Number(enteredValue));

      ///errors acoording amount

      if (enteredValue > selectedPatientById.BalanceAmount) {
        setError("concession", {
          type: "custom",
          message: "concession should not be more Total Amount",
        });
        setCustomError(true);
      } else if (enteredValue <= selectedPatientById.BalanceAmount) {
        clearErrors("concession");
        setCustomError(false);
      }
    }
  };
  //final Obj

  const finalObj = {
    addedBy: 0,
    authorisedBy: 0,
    billId: 0,
    // cancellationDate: "",
    // cancellationTime: "",
    concessionCategoryId: 0,
    concessionChargesDto: [],
    concessionType: true,
    isCancelled: true,
    netPayableAfterConcession: 0,
    opdIpd: 0,
    remark: "",
    totalAmount: 0,
    balanceAmount: 0,
    totalConcession: 0,
    visitId: 0,
    newConcession: 0,
  };

  //onSubmit

  const onSubmit = (data) => {
    setConcessionFormData(data);
    {
      !customError && Number(data.concession) > 0
        ? setOpenModal(true)
        : warningAlert("Please Provide Valid Concession");
    }
  };

  //submit on modal confirmation
  const handleFormSubmit = (data) => {
    setOpenModal(false);
    setOpenBackdrop(true);

    let token = JSON.parse(localStorage.getItem("userInfo"));

    finalObj.addedBy = Number(token.userId);
    finalObj.authorisedBy = data.Concessionauthorisedby.id;
    finalObj.authorisedBy = data.Concessionauthorisedby.id;
    finalObj.concessionCategoryId = data.concessioncategory.id;

    //set required conc tabl values
    let concTbl = [];
    for (let i = 0; i < concessionTableData.length; i++) {
      let concObj = {
        chargeId: concessionTableData[i].ChargeId,
        concAmount: concessionTableData[i].concAmount,
        concPercentage: concessionTableData[i].concPercentage,
        rate: concessionTableData[i].Rate,
        tariffServiceId: concessionTableData[i].TariffServiceId,
        totalAmount: concessionTableData[i].TotalAmount,
      };
      concTbl.push(concObj);
    }
    finalObj.concessionChargesDto = concTbl;
    //
    finalObj.newConcession =
      TypeOfConcession === "Amount"
        ? concessionAmt
        : (Number(concessionAmt) * Number(selectedPatientById?.BalanceAmount)) /
          100;

    //
    finalObj.concessionType = concessionCheck === "Concession" ? true : false;
    finalObj.netPayableAfterConcession = data.netPayableAmount;
    finalObj.opdIpd = 0;
    finalObj.remark = data.remarks;
    finalObj.totalAmount = data.totalBillAmount;
    finalObj.totalConcession = data.totalConcession;
    finalObj.visitId = selectedPatientById.VisitId;
    finalObj.billId = selectedPatientById.BillId;
    finalObj.balanceAmount = selectedPatientById.BalanceAmount;
    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;
    PostOpdConsationInfo(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          methods.reset();
          setOpenBackdrop(false);
          setConcessionTableData([]);
          setSelectedPatientById("");
          setValue("concessionManagerSearch", null);
          setSelectedPatient(null);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenModal(false);
        setOpenBackdrop(false);
      });
  };

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  return (
    <>
      <div>
        <div className="w-full grid mt-12 px-6 md:rounded-md ">
          <div className="text-center text-black font-bold text-xl ">
            Concession Manager
          </div>
          <div
            //className="lg:flex md:grid item-center w-full gap-2 "
            className="grid grid-cols-5 gap-3"
          >
            <div className="lg:col-span-2 md:col-span-5 z-50 pt-2">
              <SearchDropdown
                control={control}
                type="button"
                className="z-80"
                name="concessionManagerSearch"
                placeholder="Search By Patient Name / MRNo / Mob.No"
                dataArray={options}
                isSearchable={true}
                isClearable={true}
                searchIcon={true}
                handleInputChange={handleChange}
                inputRef={{
                  ...register("concessionManagerSearch", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        setSelectedPatient(e.target.value);
                        getPatientInformationById(e.target.value.id); //id is billId
                        fecthConcessionManagerListing(e.target.value.id); //id is billId
                      } else {
                        setSelectedPatient("");
                        setSelectedPatientById("");
                        setConcessionTableData([]);
                        setValue("concession", 0);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className=" lg:col-span-2 md:col-span-3 flex gap-2 md:py-2 md:items-center">
              <RadioField
                name="concessionCheck"
                control={control}
                dataArray={patienttype}
              />

              <div>
                <InputField
                  name="Concessionno"
                  control={control}
                  disabled={true}
                  label="Concession No"
                  placehoder="Concession NO"
                />
              </div>
            </div>
            <div className="md:col-span-2 md:whitespace-nowrap md:text-sm lg:col-span-1 pt-2 flex justify-end">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction ? (
                    <>
                      {obj.action === "Previous Concession" ? (
                        <div>
                          {selectedPatient && selectedPatient !== null ? (
                            <CommonButton
                              label="Previous Concession"
                              className="bg-blue-900 text-white"
                              onClick={() => {
                                if (selectedPatientById !== null) {
                                  handleOpenPreviousConcModal();
                                } else {
                                  warningAlert("Please Select Patient!");
                                }
                              }}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>

          {selectedPatientById !== "" && selectedPatientById !== undefined ? (
            <>
              {/* patient data */}
              <div className="lg:grid lg:grid-cols-1 md:grid md:grid-cols-1 gap-2">
                <fieldset className=" mx-auto border w-full rounded  bg-gray-100">
                  <div className="lg:grid lg:grid-cols-8 md:grid md:grid-cols-2  gap-2 p-3">
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-28 font-semibold"> Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById &&
                            selectedPatientById.patientName}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-28 font-semibold"> OPD No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.OPDNO}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-28 font-semibold"> MR No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.mrNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-28 font-semibold"> Gender</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.gender}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-28 font-semibold"> Mobile No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.MobileNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-28 font-semibold"> Unit</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.unit}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-28 font-semibold"> Company</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.Company}
                        </span>
                      </div>
                    </div>

                    {/*
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-28 font-semibold"> Bed No</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {/* {selectedPatientById && selectedPatientById.PatientName} 
                        </span>
                      </div>
                    </div>
                  </div>*/}
                  
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-28 font-semibold">Tariff</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById && selectedPatientById.tariff}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-28 font-semibold">Patient Source</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById &&
                            selectedPatientById.patientSource}
                        </span>
                      </div>
                    </div>
                    <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-28 font-semibold">
                        Patient Category
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedPatientById &&
                            selectedPatientById.PatientCategory}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              {/* concession table */}
              {concessionTableData.length > 0 ? (
                <>
                  <div className="py-4">
                    <OPDConcessionManagerListing
                      data={concessionTableData}
                      setConcessionTableData={setConcessionTableData}
                      //getTableRowData={getTableRowData}
                     // percentage={percentage}
                      amount={amount}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center mt-4 py-4">
                    <p>No Record Found</p>
                  </div>
                </>
              )}
              {/* Calculaion And input Fields */}
              <div className="">
                <fieldset
                  className="border border-gray-300 col-span-3 w-full lg:py-2
               text-left lg:px-2 md:p-2 rounded bg-white"
                >
                  <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1  md:px-2">
                    Concession Information
                  </legend>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="lg:grid lg:grid-cols-2 gap-2">
                      <div className="lg:grid lg:grid-cols-2 md:grid mdf:grid-cols-2 gap-3 ">
                        <div className="pt-2 px-2">
                          <RadioField
                            name="percentageAmountConcession"
                            control={control}
                            dataArray={percentageAmountConcession}
                            inputRef={{
                              ...register("percentageAmountConcession", {
                                onChange: (e) => {
                                  setValue("concession", 0);
                                  setValue("netPayableAmount", 0);
                                  setValue("totalConcession", 0);
                                  setValue("totalAmountWithConcession", 0);
                                },
                              }),
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            size="small"
                            type="number"
                            color={customError ? "error" : "primary"}
                            sx={{
                              input: {
                                color: customError ? "#d32f2f" : "#212529",
                              },
                              label: {
                                color: customError ? "#d32f2f" : "#212529",
                              },
                            }}
                            inputProps={{
                              maxLength:
                                TypeOfConcession === "Percentage" ? 3 : 10,
                            }}
                            name="concession"
                            variant="outlined"
                            label="Concession"
                            {...register("concession")}
                            onChange={(e) => {
                              // getConcession(e);
                              handleConcession(e);
                            }}
                            error={errors.concession}
                            control={control}
                          />
                        </div>
                        <div>
                          <DropdownField
                            control={control}
                            name="concessioncategory"
                            label="Concession Category *"
                            placeholder="Concession Category *"
                            dataArray={concessionCategory}
                            error={errors.concessioncategory}
                            isSearchable={false}
                          />
                        </div>
                        <div>
                          <DropdownField
                            control={control}
                            name="Concessionauthorisedby"
                            label="Concession Authorised By *"
                            placeholder="Concession Authorised By *"
                            dataArray={concessionAuthority}
                            error={errors.Concessionauthorisedby}
                            isSearchable={false}
                          />
                        </div>
                        <div className="col-span-2 md:pb-2">
                          <InputField
                            color={customError ? "error" : "primary"}
                            name="remarks"
                            control={control}
                            label="Remark"
                            placehoder="Remarks"
                          />
                        </div>
                      </div>
                      <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2   gap-1 md:gap-3">
                        <div className="">
                          <InputField
                            name="totalBillAmount"
                            label="Total Amount ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="balanceBillAmount"
                            label="Balance Amount ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="totalAmountWithConcession"
                            label="Total Amount With Concession ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="totalConcession"
                            label="Total Concession ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="totalAmountWithoutConcession"
                            label="Total Amount Without Concession ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="netPayableAmount"
                            label="Net Payable Amount ₹"
                            control={control}
                            errors={errors.netPayableAmount}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end gap-2 px-4 py-2">
                      {userActions.map((obj) => (
                        <>
                          {!obj.isAction && obj.action === "Create" ? (
                            <>
                              <div>
                                <CommonButton
                                  label="Reset"
                                  className="border border-customRed text-customRed"
                                  onClick={() => {
                                    methods.reset();
                                    setConcessionTableData([]);
                                    setSelectedPatientById("");
                                    setValue("concessionManagerSearch", null);
                                  }}
                                />
                              </div>
                              <div>
                                {/* <SaveButton /> */}
                                <CommonButton
                                  type="submit"
                                  label="Save"
                                  className="bg-customGreen text-white"
                                  onClick={() => {
                                    setPrivilege(obj.action);
                                  }}
                                />
                              </div>
                            </>
                          ) : null}
                        </>
                      ))}
                    </div>
                  </form>
                </fieldset>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* get Indent Modal    */}
      {openPreviousConcModal ? (
        <PreviousConcessionModal
          open={openPreviousConcModal}
          setOpen={setOpenPreviousConcModal}
          handleOpen={handleOpenPreviousConcModal}
          handleClose={handleClosePreviousConcModal}
          selectedPatientById={selectedPatientById}

          //
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openModal}
        confirmationHandleClose={() => setOpenModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(concessionFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}
