import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import { errorAlertCustom, warningAlert } from "../../common/components/Toasts/CustomToasts";

function CounterSalesReturnTable(props) {
  const { watch, control, setValue } = useForm();
  React.useEffect(() => {
    let itemsArray = [...props.returnItems];
    for (let object of itemsArray) {
      setValue(`select${object?.["Item Code"]}`, false);
    }
  }, []);

  const handleSelectReturnItems = (row, index) => {
    let rowData = row;
    let arr = [...props.finalData];
    let selectWatch = watch(`select${row?.["Item Code"]}`);
    if (selectWatch === true) {
      if (
        row["returnQuantity"] &&
        row["returnQuantity"] > 0 &&
        row["returnQuantity"] <= row["Qty Available For Return"]
      ) {
        arr.push(row);
        props.setFinalData(arr);
      } else {
        warningAlert("Enter valid return quantity..!");
        setValue(`select${row?.["Item Code"]}`, false);
      }
    } else if (selectWatch === false) {
      let filter = arr.filter(
        (item) => item?.["Item Code"] !== row?.["Item Code"]
      );
      props.setFinalData(filter);
    }
  };

 
  return (
    <div>
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 2 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded max-h-60"
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Select</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Item Code</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Item Name</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Batch Code</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Issue Qty.</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">
                      Qty Available For Ret.
                    </span>
                  </TableCell>

                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Rate</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Return Qty.</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Return Amt.</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">GST (%)</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">GST Amt.</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Disc.(%)</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">Disc Amt.</span>
                  </TableCell>
                  <TableCell className="whitespace-nowrap">
                    <span className="text-gray-600 font-bold">
                      Net Ret Amt.
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {props?.returnItems?.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "& td": {
                          paddingY: 0.3,
                        },
                      }}
                    >
                      <TableCell className="whitespace-nowrap">
                        <fieldset
                          onChange={() => {
                            handleSelectReturnItems(row, index);
                          }}
                        >
                          <CheckBoxField
                            defaultValue={false}
                            control={control}
                            name={`select${row["Item Code"]}`}
                          />
                        </fieldset>
                      </TableCell>

                      <TableCell className="whitespace-nowrap">
                        {row["Item Code"]}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row["Item Name"]}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row["Batch Code"]}
                      </TableCell>

                      <TableCell className="whitespace-nowrap">
                        {row["Issue Qty"]}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row["Qty Available For Return"]}
                      </TableCell>

                      <TableCell className="whitespace-nowrap">
                        {row.Rate}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <input
                          type="number"
                          className="w-16 rounded text-center border border-gray-400"
                          disabled={watch(`select${row.ItemCode}`)}
                          onWheel={(e) => {
                            e.target.blur();
                          }}
                          onChange={(e) => {
                            let arr = [...props.returnItems];
                            let quantityVal;
                            if (e.target.value === "") {
                              quantityVal = 0;
                            } else {
                              quantityVal = Number(e.target.value);
                            }
                            arr[index].returnQuantity = quantityVal;

                            //return amount calculation
                            let returnAmtVal = Number(
                              Number(row.Rate) * quantityVal
                            );
                            arr[index].returnAmount = returnAmtVal;

                            //GST amount Calculation
                            let gstDividend =
                              Number(returnAmtVal) *
                              Number(row["VAT Percentage"]);
                            let GSTAmountVal = Number(gstDividend) / 100;
                            arr[index].vatAmount = Number(GSTAmountVal).toFixed(
                              2
                            );

                            //Discount Amount Calculation
                            let discDividend =
                              Number(returnAmtVal) *
                              Number(row["Discount Percentage"]);
                            let discountAmountVal = Number(discDividend) / 100;
                            arr[index].discountAmount = Number(
                              discountAmountVal
                            ).toFixed(2);

                            //net return amount calculation
                            let netReturnAmountVal =
                              Number(row.Rate) - Number(discountAmountVal);
                            arr[index].netReturnAmount = Number(
                              netReturnAmountVal
                            ).toFixed(2);
                            if (quantityVal > row.IssueQty) {
                              props.setIsValidRecord(false);
                            } else {
                              props.setIsValidRecord(true);
                            }

                            props.setReturnItems(arr);
                          }}
                          style={{
                            border:
                              row.returnQuantity >
                              row["Qty Available For Return"]
                                ? "1px solid red"
                                : "1px solid #3184a6",
                          }}
                        />
                        <br />
                        {row.returnQuantity >
                        row["Qty Available For Return"] ? (
                          <span className="text-[10px] text-red-600 whitespace-nowrap h-6">
                            Quantity exceeds..!!
                          </span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row.returnAmount}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row["VAT Percentage"]}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row.vatAmount}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row["Discount Percentage"]}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row.discountAmount}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {row.netReturnAmount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {props.returnItems.length > 0 ? (
              ""
            ) : (
              <div className="my-4 flex justify-center font-semibold text-sm text-gray-400">
                Please select items to return..!
              </div>
            )}
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}

export default CounterSalesReturnTable;
