import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchEmoloyeeName } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchComplaintStatus,
  saveCompleteComplaintStatus,
} from "../../services/complaintManagementServices/ComplaintManagementServices";

export default function AddNewComplaintModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const { handleClose, selectedRow, menuId, privilege, populateTable } = props;
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  //
  const [complaintStatus, setComplaintStatus] = useState([]);
  const [completedBy, setCompletedBy] = useState([]);

  //

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      lg: "85%",
      sm: "80%",
    },
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      complaintStatus: null,
      completedBy: null,
      complaintStatusRemark: "",
    },
  });

  const { control, watch } = methods;

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };
  const handleFormSubmit = (data) => {
    const finalObj = {
      complaintResolvedBy: data?.completedBy?.label,
      completedBy: data?.completedBy?.id,
      complaintNo:selectedRow?.["Complaint No"],
      departmentId: token?.departmentId,
      departmentName: token?.department,
      //isComplete: true,
      menuId: menuId,
      privilege: privilege,
      toDeptComplaintStatus: data?.complaintStatus?.id,
      toDeptComplaintStatusRemark: data.complaintStatusRemark,
    };
    saveCompleteComplaintStatus(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          methods.reset();
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    fetchComplaintStatus()
      .then((response) => response.data)
      .then((res) => {
        setComplaintStatus(res.result);
      });

    fetchEmoloyeeName()
      .then((response) => response.data)
      .then((res) => {
        setCompletedBy(res.result);
      });
  }, []);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Complaint Status
            </div>

            <hr className="border mx-1  border-customBlue" />
            <form>
              <div className="mt-3 grid grid-cols-3">
                <fieldset
                  className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 "
                >
                  <div className="py-2 grid grid-cols-2 2xl:grid-cols-2 gap-2 p-3 ">
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm">
                      <span className=" w-36">From Department</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["From Department"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm">
                      <span className=" w-36">Is Equipment Complaint</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow &&
                          Number(selectedRow.IsEquipmentComplaint) === 0
                            ? "No"
                            : "yes"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className="w-36">Complaint Date</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.Date}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" w-36">Description</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.Details}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-36">Reported By</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["User Name"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className="  w-36">Attachment</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {/* {selectedRow && selectedRow.MRNo} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className=" py-2 grid grid-cols-4 gap-4 mt-3 items-center">
                <div className="">
                  <DropdownField
                    control={control}
                    // error={errors.MLCType}
                    name="complaintStatus"
                    placeholder="Complaint Status"
                    dataArray={complaintStatus}
                    isSearchable={false}
                  />
                </div>
                <div className="md:col-span-2 lg:col-span-1">
                  <DropdownField
                    control={control}
                    // error={errors.MLCType}
                    name="completedBy"
                    placeholder="Completed/Resolved By"
                    dataArray={completedBy}
                    isSearchable={false}
                    maxMenuHeight={150}
                  />
                </div>
                {/* <div className="">
                <CheckBoxField
                  control={control}
                  name="isEquipmentComplaint"
                  label="Is Equipment Complaint/ Transfer"
                />
              </div> */}
                <div className="col-span-2">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="complaintStatusRemark"
                    label="Complaint Status Remark"
                    placeholder="Complaint Status Remark"
                  />
                </div>
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                <CommonButton
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
