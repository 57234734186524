import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  getTemplateAutoComplete,
  getTemplateDetails,
} from "../../services/radiology/RadiologyTemplateMasterServices";
import RadiologyTemplateMasterModal from "./RadiologyTemplateMasterModal";
import RadiologyTemplateMasterTable from "./RadiologyTemplateMasterTable";

const RadiologyTemplateMaster = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [data, setData] = React.useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = React.useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [radiologyTemplate, setRadiologyTemplate] = useState([]);
  const [templateId, setTemplateId] = useState(0);
  const [editRowData, setEditRowData] = useState(null);
  const { control, register, watch } = useForm();

  const handleOpenModal = () => {
    setOpenTemplateModal(true);
  };
  const handleCloseModal = () => {
    setOpenTemplateModal(false);
  };

  const getTemplate = (searchString) => {
    if (searchString !== "") {
      getTemplateAutoComplete(searchString).then((response) => {
        setRadiologyTemplate(response.data.result);
      });
    }
  };

  const getTemplateList = () => {
    let getTemplateId = templateId ? templateId : 0;
    let pages = 0;
    let size = rowsPerPage;
    getTemplateDetails(getTemplateId, pages, size)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
        setCount(res.count);
        setDataResult(res.result);
      });
  };

  useEffect(() => {
    getTemplateList();
  }, []);

  function editRow(data) {}
  return (
    <>
      <div className="mt-14 space-y-2">
        <label className="flex justify-center font-semibold text-lg">
          Non-Pathology Template Master
        </label>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className="w-80 xl:w-96">
              <SearchDropdown
                control={control}
                name="templateName"
                placeholder="Template Name"
                searchIcon={true}
                isClearable={true}
                dataArray={radiologyTemplate}
                handleInputChange={getTemplate}
                inputRef={{
                  ...register("templateName", {
                    onChange: (e) => {
                      if (e.target.value === null) {
                        setTemplateId(0);
                      } else {
                        setTemplateId(e.target.value.id);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div>
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={() => {
                  setPage(0);
                  getTemplateList();
                }}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <CommonButton
              type="button"
              label="Add New Template"
              className="bg-customGreen text-white"
              onClick={() => {
                handleOpenModal();
              }}
            />
          </div>
        </div>
        <div className="space-y-2">
          <label className="font-semibold">List of Template</label>
          <div>
            <RadiologyTemplateMasterTable
              getTemplateList={getTemplateList}
              setOpenTemplateModal={setOpenTemplateModal}
              templateId={templateId}
              tableApiFunc={getTemplateDetails}
              dataResult={dataResult}
              setDataResult={setDataResult}
              data={data}
              page={page}
              setData={setData}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
              editRow={editRow}
              setEditRowData={setEditRowData}
            />
          </div>
        </div>
      </div>
      <RadiologyTemplateMasterModal
        openTemplateModal={openTemplateModal}
        setOpenTemplateModal={setOpenTemplateModal}
        handleCloseModal={handleCloseModal}
        setEditRowData={setEditRowData}
        editRowData={editRowData}
        getTemplateList={getTemplateList}
      />
    </>
  );
};

export default RadiologyTemplateMaster;
