import * as React from "react";
import { useEffect } from "react";
// importing the CommonMasterTable ; which is common to all
import SearchDropdown from "../../../../../common/components/FormFields/searchDropdown";
//fetching data into ipdBillService.js

import {
  errorAlert,
  successAlert,
} from "../../../../../common/components/Toasts/CustomToasts";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonPrintModal from "../../../../../Pharmacy/CommonPrintModal";
import { getPatientSearchAutocomplete } from "../../../../../Pharmacy/PharmacyServices";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../../common/components/CommonBackDrop/CommonBackDrop";
import RadioField from "../../../../../common/components/FormFields/RadioField";
import { fetchUserActionsByMenuId } from "../../../../../commonServices/functionalityServices/FunctionalityServices";
import ListOfSelfPaymentTable from "../selfSettlement/ListOfSelfPaymentTable";
import BillInformationModal from "./BillInformationModal";
import PharmacySelfSettlementCreditBIllTable from "./PharmacySelfSettlementCreditBIllTable";
import {
  getListOfCreditBills,
  getListOfPayment,
  getPatientAdvanceDetails,
  getPatientInformation,
  getPharmacySettlementPrint,
  savePharmacySelfSettlement,
} from "./services";

const actions = ["cancel", "casepaper", "select"];
const ipdOpd = [
  {
    id: "OPD",
    value: "OPD",
    label: "OPD",
  },
  {
    id: "IPD",
    value: "IPD",
    label: "IPD",
  },
];
export default function PaymentSettlement() {
  const schema = yup.object().shape({});
  const defaultValues = { ipdOpd: "IPD" };
  const {
    register,
    watch,
    control,

    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  //state varibles
  const [selectedPatient, setSelectedPatient] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [patientOptions, setPatientOptions] = React.useState([]);
  const [listOfCreditBills, setListOfCreditBills] = React.useState({});
  const [selectedBill, setSelectedBill] = React.useState(null);
  const [salesId, setSalesId] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(0);
  const [patientAdvanceDetails, setPatientAdvanceDetails] = React.useState(0);
  const [opdIpdExternalId, setOpdIpdExternalId] = React.useState(null);
  const [listOfPayments, setListOfPayments] = React.useState({});
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState("");
  const [printId, setPrintId] = React.useState(null);
  const [billPdf, setBillPdf] = React.useState("");
  const [openCommonPrintModal, setOpenCommonPrintModal] = React.useState(false);

  const handleCloseCommonPrintModal = () => {
    if (openCommonPrintModal) {
      setOpenCommonPrintModal(false);
      setPrintId(null);
    }
  };

  let location = useLocation();

  useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  let patientSearchWatch = watch("SelfbillsttlementSearch");

  React.useEffect(() => {
    if (patientSearchWatch === null) {
      setListOfCreditBills({});
      setSelectedBill(null);
      setOpdIpdExternalId(null);
    }
  }, [patientSearchWatch]);

  const resetFuncion = () => {
    setListOfCreditBills({});
    setSelectedBill(null);
    setOpdIpdExternalId(null);
    setSelectedPatient("");
    setValue("SelfbillsttlementSearch", null);
  };

  let radioWatch = watch("ipdOpd");

  const searchPatientForSettlement = (autoSearchString) => {
    let opdIpd;
    if (radioWatch === "IPD") {
      opdIpd = 1;
    } else if (radioWatch === "OPD") {
      opdIpd = 0;
    }
    autoSearchString &&
      getPatientSearchAutocomplete(autoSearchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setPatientOptions(res.result);
        });
  };

  const closePaymentModal = () => {
    if (open === true) {
      setOpen(false);
    }
  };

  const submitPaymentInfoModal = (
    paymentInfoListObj,
    concessionAuthorisedById,
    isSettledFlagVal,
    companyIdValue,
    patientAdvanceIdValue,
    consumedAdvanceVal
  ) => {
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    let opdIpd;
    if (radioWatch === "IPD") {
      opdIpd = 1;
    } else if (radioWatch === "OPD") {
      opdIpd = 0;
    }

    let paymentArray = [];
    for (let paymentObject of paymentInfoListObj.paymentInfoList) {
      let obj = {
        amount: paymentObject.amount > 0 ? paymentObject.amount : 0,
        bankId: paymentObject.bankId ? paymentObject.bankId : 0,
        paymentDate: paymentObject.paymentDate
          ? paymentObject.paymentDate
          : new Date(),
        paymentType: paymentObject.paymentType
          ? paymentObject.paymentType
          : null,
        referenceNumber: paymentObject.referenceNumber
          ? paymentObject.referenceNumber
          : "",
        remarks: paymentObject.remarks ? paymentObject.remarks : "",
        upiTypeId: paymentObject.upiTypeId ? paymentObject.upiTypeId : 0,
      };
      paymentArray.push(obj);
    }

    let postObj = {
      creditAuthorisedBy: concessionAuthorisedById
        ? concessionAuthorisedById
        : 0,
      addedBy: loggedUser?.userId,
      companyId: companyId,
      consumeAdvance:
        paymentInfoListObj?.consumeAdvance > 0
          ? paymentInfoListObj?.consumeAdvance
          : 0,
      isAdvanceConsume: paymentInfoListObj?.isAdvanceConsume,
      patientId: selectedPatient?.id,
      netBillAmount: selectedBill,
      opdIpd: opdIpd,
      opdIpdExternalId: opdIpdExternalId,
      paymentInfoList: paymentArray,
      salesId: salesId,
      totalAdvanceAmount: patientAdvanceDetails > 0 ? patientAdvanceDetails : 0,
      privilege: privilege,
      menuId: location?.state?.menuId,
    };
    setOpenBackdrop(false);
    savePharmacySelfSettlement(postObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setPrintId(res.result);
        setOpenBackdrop(false);
        resetFuncion();
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  };

  React.useEffect(() => {
    let opdIpd;
    if (radioWatch === "IPD") {
      opdIpd = 1;
    } else if (radioWatch === "OPD") {
      opdIpd = 0;
    }
    if (opdIpdExternalId !== null) {
      getListOfPayment(opdIpdExternalId, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setListOfPayments(res);
        });
    } else {
      setListOfPayments({});
    }
  }, [opdIpdExternalId]);

  const fnPrintSettlement = (printId) => {
    if (printId !== null) {
      getPharmacySettlementPrint(printId).then((response) => {
        if (response.status === 200) {
          //Get Reponse JSON
          const blb = new Blob([response.data], { type: "text/plain" });
          const reader = new FileReader();
          // Start reading the blob as text.
          reader.readAsText(blb);
          //Convert Blob Response to JSON String
          reader.addEventListener("loadend", (e) => {
            if (e.target.result.charAt(0) !== "{") {
              setOpenCommonPrintModal(true);
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              setBillPdf(fileURL);
            } else {
              setBillPdf("");
            }
          });
        }
      });
    }
  };

  React.useEffect(() => {
    if (printId !== null) {
      fnPrintSettlement(printId);
    }
  }, [printId]);

  return (
    <>
      <div className="text center mt-16">
        <div className="text-center text-black font-bold text-lg pb-1 ">
          Pharmacy Self Settlement
        </div>
      </div>
      <div className="w-full grid  px-6 md:rounded-md  ">
        {/* SearchBar */}
        <div className=" flex items-center w-full md:gap-2 lg:gap-5">
          {/*searchable dropdown */}
          <div className="flex lg:w-2/5 md:w-full gap-2 py-1">
            <SearchDropdown
              control={control}
              searchIcon={true}
              className="z-80"
              name="SelfbillsttlementSearch"
              placeholder="Search by MR No / Name / Mob No."
              handleInputChange={searchPatientForSettlement}
              isSearchable={true}
              isClearable={true}
              dataArray={patientOptions}
              inputRef={{
                ...register("SelfbillsttlementSearch", {
                  onChange: (e) => {
                    let opdIpd;
                    if (radioWatch === "IPD") {
                      opdIpd = 1;
                    } else if (radioWatch === "OPD") {
                      opdIpd = 0;
                    }

                    let patientId = e.target.value?.id;

                    patientId &&
                      getListOfCreditBills(patientId, opdIpd)
                        .then((response) => response.data)
                        .then((res) => {
                          setListOfCreditBills(res);
                        });

                    patientId &&
                      getPatientAdvanceDetails(patientId)
                        .then((response) => response.data)
                        .then((res) => {
                          setPatientAdvanceDetails(res.result.PatientAdvance);
                        });

                    patientId &&
                      getPatientInformation(patientId, opdIpd)
                        .then((response) => response.data)
                        .then((res) => {
                          // VisitId
                          // AdmissionId

                          if (radioWatch === "IPD") {
                            setOpdIpdExternalId(e.target.value.AdmissionId);
                          } else if (radioWatch === "OPD") {
                            setOpdIpdExternalId(res.result.VisitId);
                          }
                          setCompanyId(res.result?.CompanyId);
                        });
                    setSelectedPatient(e.target.value);
                  },
                }),
              }}
            />
          </div>
          <div className="flex whitespace-nowrap border rounded items-center">
            <p className="px-2  font-bold">Type :</p>
            <div className="itmes-center pt-1 px-2">
              <RadioField
                control={control}
                name="ipdOpd"
                label=""
                dataArray={ipdOpd}
              />
            </div>
          </div>
        </div>

        {/* {selectedPatient && selectedPatient !== null ? ( */}
        <>
          <div className="border bg-gray-100 border-gray-300 text-left w-full rounded mb-2 pz-2 mt-1">
            <div className="py-2 grid  lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.patientName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">MR No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.MRNo}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.AgeYear}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className=" w-28">Mobile No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.MobileNo}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* List Of Payment Table */}
          <div className=" w-full overflow-hidden">
            <div className="flex justify-between">
              <p className="font-bold text-sm text-gray-700">List Of Payment</p>
            </div>

            {listOfPayments.result && listOfPayments.result.length > 0 ? (
              <>
                <ListOfSelfPaymentTable
                  data={listOfPayments}
                  actions={actions}
                  setPrintId={setPrintId}
                />
              </>
            ) : (
              <>
                <div className="text-center mt-4 p-4">No Record Found</div>
              </>
            )}
          </div>

          {/* List Of Credit Bill Table */}
          <div className=" w-full overflow-hidden">
            <p className="font-bold text-sm py-2 text-gray-700">
              List Of Credit Bills
            </p>

            {listOfCreditBills.result && listOfCreditBills.result.length > 0 ? (
              <>
                <div className="px-0">
                  <PharmacySelfSettlementCreditBIllTable
                    data={listOfCreditBills}
                    actions={actions}
                    setSelectedBill={setSelectedBill}
                    setSalesId={setSalesId}
                    //   displayRow={displayRow}
                    // submitPaymentInfoModal={submitPaymentInfoModal}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="text-center mt-4 p-4">No Record Found</div>
              </>
            )}
          </div>
          <div className="flex justify-end py-2 gap-3">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "SettleBill" ? (
                  <div
                    onClick={() => {
                      setPrivilege(obj.action);
                    }}
                  >
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="Settle Bill"
                      onClick={() => {
                        setPrivilege(obj.action);
                        if (selectedBill > 0) {
                          setOpen(true);
                        } else {
                          errorAlert("Please select Credit Bill");
                        }
                      }}
                    />
                  </div>
                ) : null}
              </>
            ))}
            {/* <CancelButton /> */}
          </div>
        </>

        {/* ServiceTax Ok Cancel Btn */}
      </div>
      <BillInformationModal
        open={open}
        setOpen={setOpen}
        selectedPatient={selectedPatient}
        closePaymentModal={closePaymentModal}
        submitPaymentInfoModal={submitPaymentInfoModal}
        amountPayable={selectedBill}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        companyId={companyId}
        patientAdvanceDetails={patientAdvanceDetails}
      />
      <CommonPrintModal
        open={openCommonPrintModal}
        close={handleCloseCommonPrintModal}
        document={billPdf}
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
    </>
  );
}
