import React from "react";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import { deleteTodoTask } from "../../../services/cliinicalChartServices/orderSheetServices/OrderSheetServices";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { useState } from "react";

const OtherDetails = ({
  pendingToDos,
  ongoingTretment,
  allPatchDetails,
  handleTaskSheets,
  AllowInfo,
  curentIvs,
  pathgologyAwaitedTest,
}) => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState();
  const [selectedRow, setSelectedRow] = useState();
  //

  const handleDeleteTask = () => {
    setOpenBackdrop(true);

    deleteTodoTask(selectedRow?.todoId)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        handleTaskSheets();
        setOpenConfirmationModal(false);
      })
      .catch((err) => {
        setOpenBackdrop(false);
        errorAlert(err.message);
        setOpenConfirmationModal(false);
      });
  };

  const renderActions = (row) => {
    return (
      <div className="flex gap-2 items-center">
        <div className="flex cursor-pointer items-center">
          <DeleteOnIcon
            title="Delete"
            onClick={() => {
              setOpenConfirmationModal(true);
              setSelectedRow(row);
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      {/* ICU Details Card */}
      <div className="bg-white shadow-md mt-3 rounded-lg">
        <div className="font-semibold text-sm text-white py-1 pt-2 bg-[#FF2323] px-2 rounded-t-lg">
          Tubes / Lines
        </div>
        <div className="p-2 grid grid-cols-3 gap-2 bg-[#FFF4F4] rounded-b-lg">
          <div className="flex items-center gap-2 w-full text-sm">
            <span className="w-24 font-semibold">Days of ICU</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="font-normal">{allPatchDetails?.daysOfIcu}</span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full text-sm">
            <span className="w-24 font-semibold">ETT</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="font-normal">{allPatchDetails?.ETT}</span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full text-sm">
            <span className="w-24 font-semibold">Ventilation Days</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="font-normal">
                {allPatchDetails?.ventilationDays}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full text-sm">
            <span className="w-24 font-semibold">Intake</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="font-normal">
                {allPatchDetails?.IntakeTotal}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full text-sm">
            <span className="w-24 font-semibold">Output</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="font-normal">
                {allPatchDetails?.OuttakeTotal}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full text-sm">
            <span className="w-24 font-semibold">Difference</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="font-normal">{allPatchDetails?.difference}</span>
            </div>
          </div>
        </div>
      </div>
      {/* Ongoing Treatment Card */}
      {ongoingTretment.length > 0 && (
        <div className="bg-white shadow-md mt-3 rounded-lg">
          <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#D4FFE6] px-2 rounded-t-lg">
            Ongoing Treatment
          </div>
          <div className="p-2 rounded-b-lg ">
            <CommonDynamicTableNew
              dataResult={ongoingTretment}
              tableClass="h-60"
              removeHeaders={[
                "id",
                "AdminAs",
                "Alternative Day",
                "BalanceQty",
                "DoseId",
                "DrugAdminHeaderId",
                "DrugAdminQty",
                "IsChemoDrug",
                "IsHoldDrugAdmin",
                "IsOutsideMedication",
                "IsStopDrugAdmin",
                "IssueQty",
                "ItemId",
                "Once in Week",
                "Alternative Day",
                "Per Hr. Drug",
                "DrugInstruction",
                "PatchDuration",
                "IsHoldDrugAdmin",
                "PrescriptionDetailId",
                "RouteId",
                "RowNumber",
                "Stop Date Time",
              ]}
            />
          </div>
        </div>
      )}
      {curentIvs.length > 0 && (
        <div className="bg-white shadow-md mt-3 rounded-lg">
          <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#D4FFE6] px-2 rounded-t-lg">
            Current IV
          </div>
          <div className="p-2 rounded-b-lg ">
            <CommonDynamicTableNew
              dataResult={curentIvs}
              tableClass="h-60"
              removeHeaders={[
                "id",
                "ivID",
                "Alternative Day",
                "BalanceQty",
                "DoseId",
                "DrugAdminHeaderId",
              ]}
            />
          </div>
        </div>
      )}
      {pathgologyAwaitedTest.length > 0 && (
        <div className="bg-white shadow-md mt-3 rounded-lg">
          <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#D4FFE6] px-2 rounded-t-lg">
            Pathgology Awaited Test
          </div>
          <div className="p-2 rounded-b-lg ">
            <CommonDynamicTableNew
              dataResult={pathgologyAwaitedTest}
              tableClass="h-60"
              removeHeaders={[
                "Test Code",
                "site",
                "Test Category",
                "IsApproved",
                "TechnitionName",
                "OutSourceSampleReceivedBy",
                "IsSampleCollected",
                "IsCompleted",
                "Quantity",
                "First Level",
                "Second Level",
                "Third Level",
                "ExtAgencyId",
                "IsOutSourced",
                "ReportId",
                "OpdIpd",
                "IsMicro",
                "IsProcedureEnd",
                "IsProcedureStart",
                "FirstLevel",
                "SecondLevel",
                "ThirdLevel",
                "OrderId",
                "OrderDtlsId",
                "HasTemplate",
                "SampleNo",
                "Id",
                "Description",
                "IsCultureSenTest",
                "IsPathologyAuthority",
                "ishideorder",
              ]}
            />
          </div>
        </div>
      )}

      {/* Pending To Do List Card */}
      {pendingToDos?.length > 0 && (
        <div className="bg-white shadow-md mt-3 rounded-lg">
          <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#FEFFD4] px-2 rounded-t-lg">
            Pending To Do List
          </div>
          <div className="p-2 rounded-b-lg">
            <CommonDynamicTableNew
              dataResult={pendingToDos}
              renderActions={AllowInfo ? renderActions : false}
              tableClass="h-60"
              removeHeaders={["id", "task_service_id", "group_id", "todoId"]}
            />
          </div>
        </div>
      )}
      {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
      {openConfirmationModal && (
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => setOpenConfirmationModal()}
          confirmationSubmitFunc={handleDeleteTask}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to mark as complete ?"
          confirmationButtonMsg="Proceed"
        />
      )}
    </div>
  );
};

export default OtherDetails;

