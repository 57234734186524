import React, { useEffect, useState } from "react";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getAllSugarLevel,
  saveSugerLevel,
} from "../../../services/cliinicalChartServices/sugarlevelServices/SugarLevelServices";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { format } from "date-fns";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const intakeMode = [
  { id: 1, value: "PP", label: "PP" },
  { id: 2, value: "F", label: "F" },
  { id: 3, value: "PL", label: "PL" },
  { id: 4, value: "PP1", label: "PP1" },
  { id: 5, value: "PD", label: "PD" },
  { id: 6, value: "PP2", label: "PP2" },
  { id: 7, value: "R", label: "R" },
];

function SugarLevel(props) {
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [dataResult, setDataResult] = useState([]);
  const [finalData, setFinalData] = React.useState({});
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  let userRoles = getUserInfo?.userRoles;
  
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [bslId, setBslId] = useState(null);

  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);

  //  const doctorSchema = yup.object().shape(
  //    {
  //      bsl: yup.string().required(),
  //      urineSugar: yup.string().required(),
  //      urineKetone: yup.string().required(),
  //      bodies: yup.string().required(),
  //      intakeMode: yup.object().required(),
  //      injection: yup
  //        .string()
  //        .nullable()
  //        .when("tablet", (tablet) => {
  //          if (tablet === "") {
  //            return yup.string().nullable().required();
  //          } else if (tablet !== "") {
  //          clearErrors("injection");
  //            clearErrors("injectionDose");
  //          }
  //        }),
  //      tablet: yup
  //        .string()
  //        .nullable()
  //        .when("injection", (injection) => {
  //          if (injection === "") {
  //            return yup.string().nullable().required();
  //          } else if (injection !== "") {
  //            clearErrors("tablet");
  //           clearErrors("tabletDose");
  //          }
  //        }),
  //    },
  //    ["tablet", "injection"]
  //  );

  // const nurseSchema = yup
  //   .object()
  //   .shape({
  //     bsl: yup.string().required(),
  //     urineSugar: yup.string().required(),
  //     urineKetone: yup.string().required(),
  //     bodies: yup.string().required(),
  //     intakeMode: yup.object().required(),
  //     reportedRmo: yup.string().required(),
  //   })
  //   .required();

  const defaultValues = {
    bsl: "",
    urineSugar: "",
    urineKetone: "",
    ettPressure: "",
    bodies: "",
    intakeMode: 1,
    reportedRmo: "",
    informedTo: "",
    informedBy: "",
    injection: "",
    injectionDose: "",
    tablet: "",
    tabletDose: "",
    givenDate: new Date(),
    givenTime: new Date(),
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    //resolver: yupResolver(nurseSchema),
    defaultValues,
  });

  // const [role, setRole] = useState(null);



  // 

  //  function allSchema() {
  //   if (userRoles?.includes((role) => role === "Doctor")) {
  //     return {
  //       mode: "onChange",
  //       resolver: yupResolver(doctorSchema),
  //       defaultValues: defaultValues,
  //     };
  //   } else
  //  if (userRoles?.includes((role) => role === "Nurse")) {
  //    return {
  //      mode: "onChange",
  //      resolver: yupResolver(nurseSchema),
  //      defaultValues: defaultValues,
  //    };
  //  }
  //  }

  const onSubmitDataHandler = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));
    let getpatientVisitId = props.displayInfo?.AdmissionId;
    let getipdAdmissionId = props.admissionId;
    let getVisitId;
    if (getpatientVisitId) {
      getVisitId = getpatientVisitId;
    } else if (getipdAdmissionId) {
      getVisitId = getipdAdmissionId;
    }
    if (edit === false) {
      let postedObj = {
        menuId: props.menuId,
        privilege: props.privilege,
        addedBy: getUserId.userId,
        admissionId: getVisitId,
        bodies: data.bodies,
        bsl: data.bsl,
        bslId: 0,
        doseInj: data.injectionDose,
        doseTab: data.tabletDose,
        givenTime: format(time, "1900-01-01'T'hh:mm:ss.000'Z'"),
        //givenTime: new Date(),
        givenDate: format(date, "yyyy-MM-dd'T'00:00:00.000'Z'"),
        //givenDate: new Date(),
        inTakeMode: data.intakeMode.label,
        // inTakeMode: data.intakeMode,
        infoBy: data.informedBy,
        informTo: data.informedTo,
        injectionId: 0,
        injectionName: data.injection,
        ketone: data.urineKetone,
        rmo: data.reportedRmo,
        // rmo: data.reportedRmo,
        status: 0,
        tabletId: 0,
        tabletName: data.tablet,
        urine: data.urineSugar,
        ettPressure: data.ettPressure,
      };
      setOpenPost(true);
      setFinalData(postedObj);
    } else if (edit === true) {
      let updatedObj = {
        menuId: props.menuId,
        privilege: props.privilege,
        addedBy: getUserId.userId,
        admissionId: getVisitId,
        bodies: data.bodies,
        bsl: data.bsl,
        bslId: bslId,
        doseInj: data.injectionDose,
        doseTab: data.tabletDose,
        givenTime: format(time, "1900-01-01'T'hh:mm:ss.000'Z'"),
        //givenTime: new Date(),
        givenDate: format(date, "yyyy-MM-dd'T'00:00:00.000'Z'"),
        // givenDate: new Date(),
        inTakeMode: 1,
        infoBy: data.informedBy,
        informTo: data.informedTo,
        injectionId: 0,
        injectionName: data.injection,
        ketone: data.urineKetone,
        rmo: 1,
        status: 0,
        tabletId: 0,
        tabletName: data.tablet,
        urine: data.urineSugar,
        ettPressure: data.ettPressure,
      };
      setOpenPost(true);
      setFinalData(updatedObj);
    }
  };

  //event listener function for edit icon
  function editRow(requiredRow) {
    
    setEdit(true);
    let inTakeMode = {
      id: requiredRow["intakemode"],
      label: requiredRow["intakemode"],
      value: requiredRow["intakemode"],
    };
    setBslId(requiredRow.BSLId);
    let resetObj = {
      intakeMode: inTakeMode,
      bsl: requiredRow.BSL,
      urineSugar: requiredRow["Urine"],
      urineKetone: requiredRow["UrinKetone"],
      bodies: requiredRow["Bodies"],
      informedTo: requiredRow["InFormTo"],
      informedBy: requiredRow["InformedBy"],
      injection: requiredRow["Injuction"],
      tablet: requiredRow["Tablet"],
      injectionDose: requiredRow["doseinj"],
      tabletDose: requiredRow["dosetab"],
      reportedRmo: requiredRow["rmo"],
      ettPressure: requiredRow["ettPressure"],
    };

    // Edit Patch Date time
    // if (requiredRow.GivenDate) {
    //   let giveDateString = new Date(requiredRow["GivenDate"]);
    //   
    //   setDate(giveDateString);
    // }

    if (requiredRow.GivenTime) {
      let giveTimeString = new Date(requiredRow["GivenTime"]);
      setTime(giveTimeString);
    }
    reset(resetObj);
  }

  function getConsultant() {}

  function getReportedRMO() {}

  function getIntakeMode() {}

  let tablet = watch("tablet");
  let injection = watch("injection");

  useEffect(() => {
    if (edit === false) {
      if (tablet !== "") {
        setValue("tabletDose", Number(1));
      } else if (tablet === "") {
        setValue("tabletDose", "");
      }
    }
  }, [tablet]);

  useEffect(() => {
    if (edit === false) {
      if (injection !== "") {
        setValue("injectionDose", Number(1));
      } else if (injection === "") {
        setValue("injectionDose", "");
      }
    }
  }, [injection]);

  useEffect(() => {
    populateTable();
    if (props.displayInfo?.AdmissionId || props.admissionId) {
      getConsultant();
      getReportedRMO();
      getIntakeMode();
      reset(defaultValues);
      setDate(new Date());
      setTime(new Date());
    }
  }, [props.displayInfo?.AdmissionId || props.admissionId]);

  const populateTable = () => {
    showSpinner(true);
    getAllSugarLevel(props.displayInfo?.AdmissionId || props.admissionId)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setDataResult(res.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  function postSugerLevel(obj) {
    saveSugerLevel(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateTable();
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postSugerLevel(finalData);
    reset(defaultValues);
    // setDate(null);
    // setTime(null);
    setEdit(false);
  }

  const handleSelectedRow = (row, index) => {
    editRow(row, index);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="col-span-3 w-full ">
          <h1 className="my-1 text-sm font-semibold whitespace-nowrap">
            Suger Level
          </h1>

          {spinner ? (
            <div className="grid justify-center">
              <LoadingSpinner />
            </div>
          ) : null}

          {dataResult.length > 0 && spinner === false ? (
            <CommonDynamicTableNew
              dataResult={dataResult}
              tableClass={"rounded h-56 2xl:h-72 border"}
              highlightRow={true}
              handleSelectedRow={handleSelectedRow}
              removeHeaders={["BSLId", "GivenTime", "GivenDate"]}
            />
          ) : (
            <h3 className="flex justify-center my-5 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </div>
        <div>
          <div className="mt-2 grid grid-cols-3 gap-4">
            <InputField
              control={control}
              name="bsl"
              variant="outlined"
              label="BSL"
              //error={errors.bsl}
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
              inputProps={{ style: { textTransform: "capitalize" } }}
              InputLabelProps={{ shrink: true }}
            />
            <InputField
              name="urineSugar"
              variant="outlined"
              label="Urine Sugar"
              //error={errors.urineSugar}
              control={control}
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
              inputProps={{ style: { textTransform: "capitalize" } }}
              InputLabelProps={{ shrink: true }}
            />
            <InputField
              name="ettPressure"
              variant="outlined"
              label="ETT Pressure"
              //error={errors.ettPressure}
              control={control}
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
              inputProps={{ style: { textTransform: "capitalize" } }}
              InputLabelProps={{ shrink: true }}
            />
            <InputField
              name="urineKetone"
              variant="outlined"
              label="Urine Ketone"
              // error={errors.urineKetone}
              control={control}
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
              inputProps={{ style: { textTransform: "capitalize" } }}
              InputLabelProps={{ shrink: true }}
            />
            <InputField
              name="bodies"
              variant="outlined"
              label="Bodies"
              //error={errors.bodies}
              control={control}
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
              inputProps={{ style: { textTransform: "capitalize" } }}
              InputLabelProps={{ shrink: true }}
            />
            <DropdownField
              control={control}
              error={errors.intakeMode}
              dataArray={intakeMode}
              name="intakeMode"
              isSearchable={false}
              placeholder="Intake Mode "
              isClearable={false}
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
            />
            <InputField
              control={control}
              // error={errors.reportedRmo}
              variant="outlined"
              name="reportedRmo"
              label="Reported To RMO"
              disabled={userRoles?.includes(
                (role) =>
                  role !== "Super Admin" ||
                  role !== "Nurse" ||
                  role !== "Doctor"
              )}
              inputProps={{ style: { textTransform: "capitalize" } }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <div>
              <h1 className="text-md font font-semibold my-2">
                To Be Filled By RMO :
              </h1>
            </div>
            <hr className="border border-t-[1px] border-gray-300" />
            <div className="mt-2 grid grid-cols-3 gap-2 my-2">
              <div className="text-sm font font-semibold my-1 flex">
                <span className="w-56">Doctor / Consultant Info</span>
                <span>:</span>
              </div>
              <InputField
                control={control}
                //  error={errors.informedTo}
                variant="outlined"
                name="informedTo"
                label="Informed To"
                disabled={userRoles?.includes(
                  (role) =>
                    role !== "Super Admin" ||
                    role !== "Nurse" ||
                    role !== "Doctor"
                )}
                inputProps={{ style: { textTransform: "capitalize" } }}
                InputLabelProps={{ shrink: true }}
              />

              <InputField
                control={control}
                // error={errors.informedBy}
                variant="outlined"
                name="informedBy"
                label="Informed By"
                disabled={userRoles?.includes(
                  (role) =>
                    role !== "Super Admin" ||
                    role !== "Nurse" ||
                    role !== "Doctor"
                )}
                inputProps={{ style: { textTransform: "capitalize" } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <hr className="border border-t-[1px] border-gray-300" />
            <div className="grid grid-cols-3 w-full gap-2 my-2 items-center">
              <div className="text-sm font font-semibold my-1 flex">
                <span className="w-56">Injection / Tablet</span>
                <span>:</span>
              </div>
              <div className="col-span-2  gap-2">
                <div className="grid grid-cols-2 gap-x-2 gap-y-3">
                  <InputField
                    control={control}
                    // error={errors.injection}
                    variant="outlined"
                    name="injection"
                    label="Injection"
                    disabled={userRoles?.includes(
                      (role) =>
                        role !== "Super Admin" ||
                        role !== "Nurse" ||
                        role !== "Doctor"
                    )}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <InputField
                    name="injectionDose"
                    type="number"
                    label="Injection Dose"
                    //  error={errors.injectionDose}
                    control={control}
                    disabled={userRoles?.includes(
                      (role) =>
                        role !== "Super Admin" ||
                        role !== "Nurse" ||
                        role !== "Doctor"
                    )}
                    InputLabelProps={{ shrink: true }}
                  />
                  <InputField
                    control={control}
                    // error={errors.tablet}
                    variant="outlined"
                    name="tablet"
                    label="Tablet"
                    disabled={userRoles?.includes(
                      (role) =>
                        role !== "Super Admin" ||
                        role !== "Nurse" ||
                        role !== "Doctor"
                    )}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <InputField
                    name="tabletDose"
                    label="Tablet Dose"
                    type="number"
                    // error={errors.tabletDose}
                    control={control}
                    disabled={userRoles?.includes(
                      (role) =>
                        role !== "Super Admin" ||
                        role !== "Nurse" ||
                        role !== "Doctor"
                    )}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
            </div>
            <hr className="border border-t-[1px] border-gray-300" />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3 mt-3 justify-between">
          <div className="col-span-1 flex justify-between space-x-3 w-full">
            <div className="flex gap-2">
              <Controller
                control={control}
                defaultValue={null}
                name="givenDate"
                render={({ field: { value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      name="givenDate"
                      className="bg-white w-48"
                      label="Given Date"
                      inputFormat="dd/MM/yyyy"
                      disableFuture
                      value={date}
                      // disabled={
                      //   getEmpCategoryId === 14 ||
                      //   (getEmpCategoryId === 1 &&
                      //     (injection === "" || tablet === ""))
                      // }
                      disabled={
                        userRoles?.includes(
                          (role) =>
                            role !== "Super Admin" ||
                            role !== "Nurse" ||
                            role !== "Doctor"
                        ) &&
                        (injection === "" || tablet === "")
                      }
                      onChange={(newValue) => setDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          name="givenDate"
                          {...register("givenDate")}
                          fullWidth
                          size="small"
                          {...params}
                          sx={{
                            svg: {
                              color: "#1E3A8A",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>

            <div className="flex gap-2">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="givenTime"
                defaultValue={null}
                render={({ field: { value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      className="bg-white w-48"
                      label="Given Time"
                      name="givenTime"
                      value={time}
                      // disabled={
                      //   getEmpCategoryId === 14 ||
                      //   (getEmpCategoryId === 1 &&
                      //     (injection === "" || tablet === ""))
                      // }
                      disabled={
                        userRoles?.includes(
                          (role) =>
                            role !== "Super Admin" ||
                            role !== "Nurse" ||
                            role !== "Doctor"
                        ) &&
                        (injection === "" || tablet === "")
                      }
                      onChange={(newValue) => {
                        setTime(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          size="small"
                          name="givenTime"
                          {...params}
                          sx={{
                            svg: {
                              color: "#1E3A8A",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            {props.userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      
                      onClick={() => {
                        reset(defaultValues);
                        setDate(new Date());
                        setTime(new Date());
                        setEdit(false);
                      }}
                    />
                    {props.patientInformation?.AllowToGO === true ||
                    props.selectedPatient?.AllowToGO === true ? null : (
                      <>
                        {edit === false ? (
                          <div onClick={() => props.setPrivilege(obj.action)}>
                            <CommonButton
                              type="submit"
                              className="bg-customGreen text-white"
                              label="Save"
                             
                              onClick={() => {
                                props.setPrivilege(obj.action);
                              }}
                              //onClick={methods.handleSubmit(onSubmitDataHandler)}
                            />
                          </div>
                        ) : (
                          <CommonButton
                            type="submit"
                            className="bg-customGreen text-white"
                            label="Update"
                           
                            onClick={() => {
                              props.setPrivilege(obj.action);
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}

export default SugarLevel;
