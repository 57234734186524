import React from "react";

export const ClearButton = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25417 0.065625C6.85849 0.164063 6.56173 0.332812 6.26026 0.632812C5.95407 0.9375 5.76565 1.27031 5.68086 1.67813C5.64318 1.85156 5.62905 3.16875 5.62905 5.94375V9.96563L6.1001 9.94688C7.09401 9.90469 8.23867 10.1109 9.22787 10.5047C10.2124 10.8938 11.1121 11.4938 11.9034 12.2906L12.3604 12.75H16.2512C20.0573 12.75 20.1468 12.75 20.3258 12.8438C20.5378 12.9516 20.7027 13.2188 20.7027 13.4531C20.7027 13.6875 20.5378 13.9547 20.3258 14.0625C20.1468 14.1563 20.0573 14.1563 16.7081 14.1563H13.2695L13.4485 14.5688C13.75 15.2672 13.9619 16.0266 14.042 16.7016L14.075 16.9688H17.1085C20.0526 16.9688 20.1468 16.9734 20.3258 17.0625C20.5378 17.1703 20.7027 17.4375 20.7027 17.6719C20.7027 17.9062 20.5378 18.1734 20.3258 18.2812C20.1468 18.3703 20.0526 18.375 17.1085 18.375H14.0797L14.0184 18.8016C13.75 20.6953 12.6194 22.6313 11.1215 23.7563L10.7965 24H16.4302C20.3965 24 22.1394 23.9859 22.3136 23.9484C23.1144 23.7797 23.7786 23.1141 23.9482 22.3219C23.9859 22.1438 24 19.7578 24 14.0859V6.09375H18.4322L18.2438 5.99063C18.1307 5.925 18.0177 5.8125 17.9658 5.70938C17.8811 5.54531 17.8764 5.39063 17.8764 2.76563V0L12.6854 0.0046875C8.69558 0.009375 7.43317 0.0234375 7.25417 0.065625ZM20.3258 8.625C20.5378 8.73281 20.7027 9 20.7027 9.23438C20.7027 9.46875 20.5378 9.73594 20.3258 9.84375C20.1421 9.9375 20.062 9.9375 14.8098 9.9375H9.48224L9.29382 9.83438C8.81806 9.56719 8.81335 8.925 9.2844 8.64375L9.46811 8.53125H14.8051C20.062 8.53125 20.1421 8.53125 20.3258 8.625Z"
        fill="#D21919"
      />
      <path
        d="M19.2895 2.55469V4.6875H23.5996L21.4563 2.55469C20.2787 1.38281 19.3083 0.421875 19.3036 0.421875C19.2942 0.421875 19.2895 1.38281 19.2895 2.55469Z"
        fill="#D21919"
      />
      <path
        d="M5.67615 11.3719C4.15937 11.5594 2.89225 12.1734 1.8371 13.2375C0.899706 14.1797 0.362709 15.1828 0.084789 16.5C-0.028263 17.0531 -0.028263 18.2906 0.084789 18.8438C0.362709 20.1609 0.904416 21.1688 1.8371 22.1063C2.78391 23.0578 3.82022 23.6156 5.158 23.8922C5.70913 24.0047 6.94799 24.0047 7.52267 23.8922C8.79451 23.6391 9.84966 23.0766 10.7918 22.1484C11.7433 21.2156 12.3085 20.175 12.5865 18.8438C12.6995 18.2906 12.6995 17.0531 12.5865 16.5C12.4027 15.6328 12.0683 14.8313 11.5878 14.1094C11.2911 13.6641 10.3725 12.7453 9.93445 12.4594C9.17605 11.9578 8.35643 11.6156 7.55093 11.4609C7.16938 11.3859 6.00589 11.3297 5.67615 11.3719ZM4.51266 14.9344C4.59745 14.9766 5.04024 15.3797 5.50186 15.8344L6.33562 16.6641L7.17409 15.8344C8.02669 14.9906 8.20098 14.8594 8.45535 14.8594C8.95466 14.8594 9.30795 15.4172 9.08656 15.8578C9.04416 15.9422 8.63906 16.3828 8.18214 16.8375L7.34838 17.6719L8.18214 18.5016C8.63906 18.9609 9.04416 19.4016 9.08656 19.4859C9.3739 20.0578 8.73327 20.6953 8.15859 20.4094C8.0738 20.3672 7.63101 19.9641 7.17409 19.5094L6.33562 18.6797L5.50186 19.5094C5.04024 19.9641 4.59745 20.3672 4.51266 20.4094C3.93798 20.6953 3.29735 20.0578 3.58469 19.4859C3.62709 19.4016 4.03219 18.9609 4.48911 18.5016L5.32287 17.6719L4.48911 16.8375C4.03219 16.3828 3.62709 15.9422 3.58469 15.8578C3.29735 15.2859 3.93798 14.6484 4.51266 14.9344Z"
        fill="#D21919"
      />
    </svg>
  );
};
