import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, IconButton, Modal, Tooltip } from "@mui/material";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import ResetButton from "../../../../common/components/Buttons/ResetButton";
import SearchIconButton from "../../../../common/components/Buttons/SearchIconButton";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerField from "../../../../common/components/FormFields/DatePickerFieldNew";
import {
  getSupplierList,
  getWorkOrderDetailsList,
  getWorkOrderList,
  getWorkOrderNoList,
  materialWorkOrderPrint,
} from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

const PODetails = {
  result: [
    {
      SrNo: 1,
      ItemName: "Lorem Ipsum",
      POQty: 10,
      UOM: 5,
      PurchaseRate: 10.5,
      TotalAmount: 50000,
      "Disc %": 5,
      DiscAmount: 500,
      "GST %": 5,
      GSTAmount: 500,
      Ne0tAmount: 450000,
    },
  ],
};

const verifiction = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Completed",
    value: "Completed",
    label: "Completed",
  },
];
const Actions = [
  {
    id: 0,
    isAction: true,
    action: "print",
  },
];

export default function PurchaseOrderModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const defaultValues = {
    supplierName: null,
    woNumber: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    setValue,
    trigger,
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { userActions } = props;
  const [selectedRow, setSelectedRow] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [workOrderId, setWorkOrderId] = useState(null);
  const [supplierList, setSupplierList] = useState();
  const [workOrderNoList, setWorkOrderNoList] = useState();
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [urlforPrint, setUrlforPrint] = useState();
  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  let getStoreName = JSON.parse(localStorage.getItem("userInfo"));

  const handleChangeGetSupplierList = (autoSearchString) => {
    if (autoSearchString !== "") {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  const handleChangeGetWorkOrderNoList = (searchWONo) => {
    let obj = {
      fromDate: getFromDate,
      searchString: searchWONo,
      supplierId: supplierId,
      toDate: getToDate,
    };
    getWorkOrderNoList(obj)
      .then((response) => response.data)
      .then((res) => {
        setWorkOrderNoList(res.result);
      });
  };

  function workOrderList() {
    let obj = {
      fromDate: getFromDate,
      isAmmendment: true,
      isCancelled: true,
      isOrderPlaced: 0,
      workOrderId: workOrderId ? workOrderId : 0,
      storeId: getStoreName.storeId,
      supplierId: supplierId ? supplierId : 0,
      toDate: getToDate,
    };
    getWorkOrderList(obj)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
  }

  function displayRowData() {
    let obj = {
      doId: selectedRow ? selectedRow.WorkOrderId : 0,
      storeId: selectedRow ? selectedRow.StoreId : 0,
      supplierId: supplierId ? supplierId : 0,
    };
    getWorkOrderDetailsList(obj)
      .then((response) => response.data)
      .then((res) => {
        setDetailsData(res.result);
      });
  }

  useEffect(() => {
    displayRowData();
  }, [selectedRow]);

  useEffect(() => {
    workOrderList();
  }, []);

  if (getStoreName) {
    let storeName = {
      value: getStoreName.storeName,
      label: getStoreName.storeName,
      id: getStoreName.storeId,
    };

    setValue("storeName", storeName);
  }

  function handleOpenPrintModal(row) {
    materialWorkOrderPrint(row?.WorkOrderId).then((response) => {
      setOpenPrintModal(true);
      setUrlforPrint(response);
    });
  }

  const RenderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                {actions.action === "Print" ? (
                  <>
                    <Tooltip title="Print MLC">
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => {
                          handleOpenPrintModal(row);
                        }}
                      >
                        <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[80%]">
          <CancelPresentationIconButton
            onClick={() => {
              reset(defaultValues);
              props.handleClose();
              setDetailsData([]);
              setData([]);
            }}
          />
          <div className="row">
            <fieldset className="border border-gray-300 text-left lg:px-4 py-2 rounded mt-1 lg:m-2 space-y-2">
              <legend className="px-2 ml-2 lg:ml-0 font-bold text-gray-800">
                Search Work Order
              </legend>
              <form className="w-full md:px-3 lg:px-0 py-2">
                <div className="grid grid-cols-4 lg:grid-cols-5 xl:flex xl:space-x-3 xl:gap-0 gap-3">
                  <div className="w-full">
                    <DropdownField
                      control={control}
                      error={errors.storeName}
                      name="storeName"
                      placeholder="Store Name*"
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-span-2 w-full">
                    <SearchDropdown
                      control={control}
                      searchIcon={true}
                      isSearchable={true}
                      isClearable={true}
                      error={errors.supplierName}
                      name="supplierName"
                      placeholder="Supplier Name*"
                      dataArray={supplierList}
                      handleInputChange={handleChangeGetSupplierList}
                      inputRef={{
                        ...register(`supplierName`, {
                          onChange: (e) => {
                            if (e.target.value === null) {
                              setSupplierId(null);
                            } else {
                              setSupplierId(e.target.value.id);
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SearchDropdown
                      control={control}
                      searchIcon={true}
                      isSearchable={true}
                      isClearable={true}
                      error={errors.woNumber}
                      name="woNumber"
                      placeholder="Work Order No"
                      dataArray={workOrderNoList}
                      handleInputChange={handleChangeGetWorkOrderNoList}
                      inputRef={{
                        ...register(`woNumber`, {
                          onChange: (e) => {
                            if (e.target.value === null) {
                              setWorkOrderId(null);
                            } else {
                              setWorkOrderId(e.target.value.id);
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <DatePickerField
                      defaultValue={new Date()}
                      control={control}
                      name="fromDate"
                      label="From Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("fromDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="w-full">
                    <DatePickerField
                      defaultValue={new Date()}
                      control={control}
                      name="toDate"
                      label="To Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("toDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="">
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      searchIcon={true}
                      onClick={() => {
                        workOrderList();
                        setDetailsData([]);
                      }}
                    />
                  </div>
                </div>
              </form>

              <div className="w-full">
                <div className="grid grid-cols-2 justify-between items-center font-bold">
                  <div>
                    <h1>Work Order List</h1>
                  </div>
                </div>
                <div>
                  {data.length > 0 ? (
                    <CommonDynamicTable
                      dataResult={data}
                      handleSelectedRow={handleSelectedRow}
                      actionsRequired={true}
                      renderActions={RenderActions}
                      tableClass="h-52"
                      removeHeaders={["WorkOrderId", "Id", "StoreId"]}
                    />
                  ) : (
                    <div className="justify-center items-center font-semibold h-48 w-full flex border-2 border-gray-300 rounded">
                      No Records Found...
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full">
                <h1 className="font-bold">Work Order Details</h1>
                <div>
                  {detailsData.length > 0 ? (
                    <CommonDynamicTable
                      dataResult={detailsData}
                      tableClass="h-52"
                    />
                  ) : (
                    <div className="justify-center items-center font-semibold h-48 w-full flex border-2 border-gray-300 rounded">
                      No Records Found...
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-end gap-2">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    reset(defaultValues);
                    setData([]);
                    setDetailsData([]);
                  }}
                />
              </div>
            </fieldset>
          </div>
        </Box>
      </Modal>
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}
