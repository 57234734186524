import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import SearchIconButton from "../../../../common/components/Buttons/SearchIconButton";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePagination from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DesktopDatePickerField from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchPrintGrnReturnList,
  getGrnReturnListing,
  grnReturnListingDetails,
  verifyGrnReturn,
} from "../../../services/generalStore/grnReturnMultipleServices/GRNReturnMultipleService";
import { getSupplierList } from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import { VerifyDisabledIcon } from "../verifyGrn/assets/VerifyDisabledIcon";
import { VerifyIcon } from "../verifyGrn/assets/VerifyIcon";

const verificationObj = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Completed",
    value: "Completed",
    label: "Completed",
  },
];

export default function GrnReturnList(props) {
  const defaultValues = {
    verification: "All",
    fromDate: new Date(),
    toDate: new Date(),
    cancelled: false,
    supplierName: null,
  };

  const {
    control,
    reset,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  // local storage
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  // local variable
  let verificationType = watch("verification");
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");
  let isCancelled = watch("cancelled");
  let supplierId = watch("supplierName");
  const location = useLocation();

  // state varibles
  const [privilege, setPrivilege] = useState("");
  const [userActions, setUserActions] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [returnListData, setReturnListData] = useState([]);
  const [returnListDetailsData, setReturnListDetailsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();

  // handle api using useMemo
  const [listObject, setListObject] = useState({
    dcNumberFrom: "",
    dcNumberTo: "",
    fromDate: fromDate,
    isCancelled: isCancelled ? 1 : 0,
    isVerified:
      verificationType === "All" ? 2 : verificationType === "Pending" ? 0 : 1,
    storeId: getUserInfo.storeId,
    supplierId: supplierId !== null ? supplierId.id : 0,
    toDate: toDate,
    menuId: location?.state?.menuId,
    page: 0,
    size: rowsPerPage,
  });

  // get supplier list
  const handleChangeGetSupplierList = (autoSearchString) => {
    if (autoSearchString !== "") {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  // get retin List
  const populateReturnList = (forPagination) => {
    let obj = {
      dcNumberFrom: "",
      dcNumberTo: "",
      fromDate: fromDate,
      isCancelled: isCancelled ? 1 : 0,
      isVerified:
        verificationType === "All" ? 2 : verificationType === "Pending" ? 0 : 1,
      storeId: getUserInfo.storeId,
      supplierId: supplierId !== null ? supplierId.id : 0,
      toDate: toDate,
      menuId: location?.state?.menuId,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    getGrnReturnListing(obj)
      .then((response) => response.data)
      .then((res) => {
        console.log("grn return information by id is ", res);
        if (forPagination) {
          setReturnListData((prevData) => [...prevData, ...res.result]);
        } else {
          setReturnListData(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch(() => {
        console.log("error");
      });
  };

  // get return list details
  const populateReturnListDetails = () => {
    if (selectedRow !== null) {
      grnReturnListingDetails(getUserInfo.storeId, selectedRow?.GRNReturnId)
        .then((response) => response.data)
        .then((res) => {
          setReturnListDetailsData(res.result);
        });
    }
  };

  // set Selected Row
  const handleSelectedRow = (row) => {
    setSelectedRow(row);
  };

  // verify grn save api function
  const addRecord = () => {
    verifyGrnReturn(
      selectedRow?.GRNReturnId,
      location?.state?.menuId,
      privilege
    )
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateReturnList();
        setSelectedRow(null);
        setOpenConfirmationModal(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  };

  // open print modal
  const handleOpenPrintModal = (row) => {
    fetchPrintGrnReturnList(row?.GRNReturnId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  // render actions for return list data
  const renderActions = (row, index) => {
    return (
      <div className="flex space-x-2 items-center">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex space-x-2 items-center cursor-pointer">
                  {actions.action === "Print" ? (
                    <>
                      <Tooltip title="Print ">
                        <button
                          type="button"
                          className="text-gray-600 cursor-pointer"
                          onClick={() => {
                            handleOpenPrintModal(row);
                          }}
                        >
                          {<LocalPrintshopIcon />}
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}

                  {actions.action === "Verify" ? (
                    <>
                      {row.Verified === false ? (
                        <Tooltip title="Verify Grn Return">
                          <button
                            type="button"
                            className="text-customBlue cursor-pointer"
                            onClick={() => {
                              setPrivilege(actions.action);
                              setOpenConfirmationModal(true);
                              setSelectedRow(row);
                            }}
                          >
                            <VerifyIcon className="" />
                          </button>
                        </Tooltip>
                      ) : (
                        <a className="text-customBlue">
                          <VerifyDisabledIcon className=" cursor-pointer" />
                        </a>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  // handle api
  useMemo(() => {
    populateReturnList();
  }, [listObject.fromDate, listObject.toDate, listObject.supplierId]);

  // grn return details list
  useEffect(() => {
    if (selectedRow !== null) {
      populateReturnListDetails();
    }
  }, [selectedRow]);

  //
  useEffect(() => {
    setValue("storeName", getUserInfo.storeName);
    if (location?.state?.menuId) {
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    }
  }, []);
  return (
    <>
      <div className="mt-14">
        <div className="flex justify-between items-center  w-full ">
          <h1 className="whitespace-nowrap text-gray-600 text-lg font-semibold">
            Supplier Return List
          </h1>
        </div>
        <div className="grid grid-cols-3 2xl:grid-cols-4 gap-2 w-full">
          <div className="flex  whitespace-nowrap space-x-2 items-center">
            <p className="font-semibold text-sm xl:text-base">Verification :</p>
            <div className="px-2 flex pt-2 whitespace-nowrap">
              <RadioField
                name="verification"
                control={control}
                dataArray={verificationObj}
              />
            </div>
          </div>
          <div className="flex space-x-2 items-center">
            <DesktopDatePickerField
              control={control}
              name="fromDate"
              label="From Date"
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />

            <DesktopDatePickerField
              control={control}
              name="toDate"
              label="To Date"
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div>
            <InputField
              control={control}
              name="storeName"
              label="Store"
              disabled
            />
          </div>
          <div>
            <SearchDropdown
              control={control}
              searchIcon={true}
              isDisabled={false}
              isSearchable={true}
              isClearable={true}
              error={errors.supplierName}
              name="supplierName"
              placeholder="Supplier Name"
              dataArray={supplierList}
              handleInputChange={handleChangeGetSupplierList}
            />
          </div>
          <div className="flex space-x-2 items-center">
            <CheckBoxField
              control={control}
              name="cancelled"
              label="Cancelled"
            />
            <SearchIconButton
              onClick={() => {
                setListObject((listObject) => ({
                  ...listObject,
                  fromDate: fromDate,
                  toDate: toDate,
                  supplierId: supplierId !== null ? supplierId.id : 0,
                }));
              }}
            />

            <CommonButton
              type="button"
              className="border border-customRed text-customRed"
              label="Reset"
              onClick={() => {
                reset(defaultValues);
              }}
            />
          </div>
        </div>

        <div>
          <h1 className="text-gray-600 font-semibold my-1">
            Supplier Return List
          </h1>
          {returnListData?.length > 0 ? (
            <CommonDynamicTablePagination
              dataResult={returnListData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
              populateTable={populateReturnList}
              actionsRequired={true}
              renderActions={renderActions}
              highlightRow={true}
              handleSelectedRow={handleSelectedRow}
              tableClass={"rounded h-[270px] 2xl:h-72 border"}
              removeHeaders={["SupplierId", "StoreId", "GRNReturnId"]}
            />
          ) : (
            <h1 className="flex justify-center py-28 font-bold text-gray-600">
              No Record Found...
            </h1>
          )}
        </div>
        <div className="flex justify-end">
          <h1 className="text-gray-600 font-semibold">
            Total Count &nbsp; : &nbsp;
            <span className="text-customBlue">
              {returnListDetailsData.hasOwnProperty("result")
                ? returnListDetailsData.result.length
                : 0}
            </span>
          </h1>
        </div>
        <div>
          <h1 className="text-gray-600 font-semibold">
            Supplier Return Details
          </h1>
          {returnListDetailsData?.length > 0 ? (
            <CommonDynamicTable
              dataResult={returnListDetailsData}
              tableClass={"rounded h-[270px] 2xl:h-72 border"}
              removeHeaders={["ItemId", "GRNReturnId"]}
            />
          ) : (
            <h1 className="flex justify-center py2-8 font-bold text-gray-600">
              No Record Found...
            </h1>
          )}
        </div>

        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={() => setOpenPrintModal(false)}
            urlforPrint={urlforPrint}
          />
        ) : null}

        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => setOpenConfirmationModal(false)}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to Verify this grn record ?"
          confirmationButtonMsg="Add"
        />
      </div>
    </>
  );
}
