import React, { useState, useEffect } from "react";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import InputField from "../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { Box, Divider, Modal } from "@mui/material";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  savePushTool,
  getLengthDropdown,
  getTissueTypeDropdown,
  getExudateAmountDropdown,
  searchPatientAutoComplete,
  getPushToolList,
} from "../../services/pushToolServices/PushToolServices";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import { ModalStyle } from "../../../common/components/ModalStyle";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import PushToolListingModal from "./PushToolListingModal";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";

const PushTool = (props) => {
  const defaultValues = {
    lenghtWidth: "",
    lengthWidthRange: null,
    exudateAmount: null,
    tissueType: null,
    ulcerLocation: "",
  };

  const { control, watch, handleSubmit, reset, register, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const { openPushToolModal, selectedPatient, isFromClinicalCarechart } = props;
  const [addListing, setAddListing] = useState([]);
  const [openListingModal, setOpenListingModal] = useState(false);
  const [lengthOptions, setLengthOptions] = useState([]);
  const [tissueTypeOptions, setTissueTypeOptions] = useState([]);
  const [exudateAmountOptions, setExudateAmountOptions] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [userActions, setUserActions] = useState([]);
  const location = useLocation();

  let getLengthValue = watch("lengthWidthRange");
  let getExudateAmountValue = watch("exudateAmount");
  let getTissueTypeValue = watch("tissueType");

  let totalScore =
    Number(getLengthValue !== null && getLengthValue?.ItemDataValue) +
    Number(
      getExudateAmountValue !== null && getExudateAmountValue?.ItemDataValue
    ) +
    Number(getTissueTypeValue !== null && getTissueTypeValue?.ItemDataValue);

  function onSubmitData(data) {
    let postObj = {
      actualLnWd: data.lenghtWidth,
      admissionId:
        selectedPatient !== null && selectedPatient !== undefined
          ? selectedPatient?.AdmissionId
          : patientInfo?.AdmissionId,
      entryDate: new Date(),
      exudateAmtId: data?.exudateAmount?.id,
      issueTypeId: data?.tissueType?.id,
      lnWdId: data.lengthWidthRange?.id,
      totalScore: totalScore,
      ulcerLocation: data.ulcerLocation,
    };
    if (
      patientInfo !== null ||
      (selectedPatient !== null && selectedPatient !== undefined)
    ) {
      setOpenPost(true);
      setFinalData(postObj);
    } else {
      warningAlert("Please Select Patient...!");
    }
  }

  function saveRecord() {
    setOpenBackdrop(true);
    savePushTool(finalData)
      .then((response) => {
        successAlert(response.data.message);
        reset(defaultValues);
        setOpenBackdrop(false);
        getPushToolListing();
        setPatientInfo(null);
        setOpenPost(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenPost(false);
        setOpenBackdrop(false);
      });
  }

  function handleChange(e) {
    searchPatientAutoComplete(e, 1).then((response) => {
      setSearchOptions(response.data.result);
    });
  }

  function autoSelectedValue(e) {
    if (e) {
      setPatientInfo(e);
    } else {
      setPatientInfo(null);
      setAddListing([]);
    }
  }

  function getPushToolListing() {
    let listObj = {
      admissionId:
        selectedPatient !== null && selectedPatient !== undefined
          ? selectedPatient?.AdmissionId
          : patientInfo?.AdmissionId,
      fromDate: null,
      toDate: null,
    };
    getPushToolList(listObj)
      .then((response) => response.data)
      .then((res) => {
        setAddListing(res.result);
      });
  }

  useEffect(() => {
    if (
      patientInfo !== null ||
      (selectedPatient !== null && selectedPatient !== undefined)
    ) {
      getPushToolListing();
    }
  }, [patientInfo, selectedPatient]);

  useEffect(() => {
    getLengthDropdown().then((response) => {
      setLengthOptions(response.data.result);
    });

    getTissueTypeDropdown().then((response) => {
      setTissueTypeOptions(response.data.result);
    });

    getExudateAmountDropdown().then((response) => {
      setExudateAmountOptions(response.data.result);
    });
  }, []);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  return (
    <div className={`${openPushToolModal === true ? "" : "mt-14"}`}>
      <div className="text-lg font-semibold text-center">Push Tool</div>
      <div className="space-y-2">
        <div className="flex space-x-2 justify-between w-full">
          <fieldset
            className="w-96"
            disabled={isFromClinicalCarechart === true}
          >
            <SearchBar
              name="searchPatient"
              placeholder="Search By MRNo/Patient Name"
              searchIcon={true}
              handleInputChange={handleChange}
              onChange={autoSelectedValue}
              dataArray={searchOptions}
            />
          </fieldset>
          {selectedPatient === null ||
            (selectedPatient === undefined && (
              <div className="flex justify-end">
                <CommonButton
                  type="button"
                  label="PushTool Listing"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    setOpenListingModal(true);
                  }}
                />
              </div>
            ))}
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 rounded bg-[#F1F1F1] px-2 py-2 text-sm">
          <div className="flex items-center">
            <label className="font-semibold">Patient Name&nbsp;</label>
            <label>
              : {patientInfo?.patientName || selectedPatient?.PatientName}
            </label>
          </div>
          <div className="flex items-center">
            <label className="font-semibold">MRNo&nbsp;</label>
            <label>: {patientInfo?.MRNo || selectedPatient?.MRNo}</label>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitData)} className="flex space-x-2">
          <div className="w-60">
            <InputField
              control={control}
              name="lenghtWidth"
              label="LENGTH X WIDTH (in cm²)"
              inputRef={{
                ...register("lenghtWidth", {
                  onChange: (e) => {
                    let enteredValue = e.target.value;
                    lengthOptions.map((val) => {
                      let splitVal = val?.value?.split("-");
                      let splitLessVal = val?.value?.split("<");
                      let splitGreaterVal = val?.value?.split(">");

                      if (
                        (Number(enteredValue) >= Number(splitVal[0]) &&
                          Number(enteredValue) <= Number(splitVal[1])) ||
                        (Number(enteredValue) >= Number(0.1) &&
                          Number(enteredValue) < Number(splitLessVal[1])) ||
                        Number(enteredValue) > Number(splitGreaterVal[1]) ||
                        Number(enteredValue) === Number(splitVal[0])
                      ) {
                        setValue("lengthWidthRange", val);
                      } else if (enteredValue === "") {
                        setValue("lengthWidthRange", null);
                      }
                    });
                  },
                }),
              }}
            />
          </div>
          <div className="space-y-3 items-center">
            <div className="flex space-x-2">
              <div className="w-60">
                <DropdownField
                  control={control}
                  name="lengthWidthRange"
                  placeholder="Length X Width"
                  dataArray={lengthOptions}
                  isDisabled={true}
                />
              </div>
              <div className="text-customRed flex items-center text-sm">
                <label>Sub-Score&nbsp;</label>
                <label>
                  : {getLengthValue !== null && getLengthValue?.ItemDataValue}
                </label>
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="w-60">
                <DropdownField
                  control={control}
                  name="exudateAmount"
                  placeholder="Exudate Amount"
                  dataArray={exudateAmountOptions}
                />
              </div>
              <div className="text-customRed flex items-center text-sm w-40">
                <label>Sub-Score&nbsp;</label>
                <label>
                  :
                  {getExudateAmountValue !== null &&
                    getExudateAmountValue?.ItemDataValue}
                </label>
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="w-60">
                <DropdownField
                  control={control}
                  name="tissueType"
                  placeholder="Tissue Type"
                  dataArray={tissueTypeOptions}
                />
              </div>
              <div className="text-customRed flex items-center text-sm w-40">
                <label>Sub-Score&nbsp;</label>
                <label>
                  :
                  {getTissueTypeValue !== null &&
                    getTissueTypeValue?.ItemDataValue}
                </label>
              </div>
            </div>
          </div>
          <div className="items-center space-y-2">
            <div className="text-customRed flex items-center text-sm w-40">
              <label>Total Score&nbsp;</label>
              <label>: {totalScore}</label>
            </div>
            <div className="w-96">
              <InputField
                control={control}
                name="ulcerLocation"
                label="Ulcer Location"
              />
            </div>
            {userActions?.map((actions) => {
              return (
                <>
                  {actions?.action === "Create" && actions?.isAction === false && (
                    <div className="flex justify-end space-x-2">
                      <CommonButton
                        type="button"
                        label="Reset"
                        className="border border-customRed text-customRed"
                        onClick={() => {
                          reset(defaultValues);
                          setPatientInfo(null);
                        }}
                      />
                      <CommonButton
                        type="submit"
                        label="Save"
                        className="bg-customGreen text-white"
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </form>
        <hr className="border-gray-300 border-[1.5px]" />
        <div>
          {addListing !== undefined && addListing?.length > 0 ? (
            <CommonDynamicTableNew
              dataResult={addListing}
              tableClass={"h-96"}
              removeHeaders={["id", "AdmissionId"]}
            />
          ) : (
            <div className="justify-center items-center font-semibold h-96 w-full flex border-2 border-gray-300 rounded my-2">
              No Records Found...
            </div>
          )}
        </div>
      </div>
      <PushToolListingModal
        openListingModal={openListingModal}
        setOpenListingModal={setOpenListingModal}
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={() => {
          setOpenPost(false);
        }}
        confirmationSubmitFunc={saveRecord}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to add record ?"
        confirmationButtonMsg="Yes"
      />
    </div>
  );
};

export default PushTool;
