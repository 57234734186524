//imports from the react library
import React, { useEffect, useRef, useState } from "react";

//importing the common components
import ConfirmationModal from "../../../common/components/ConfirmationModal";

import {
  errorAlert,
  errorAlertCustom,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";

import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import InputField from "../../../common/components/FormFields/InputField";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

//importing the Charges table
import Charges from "./Charges";

//importing the modals
import BillInformationModal from "./BillInformationModal";
import BillListingModal from "./BillListingModal";

//importing the table PackagedCharges
import PackagedCharges from "./PackagedCharges";

//imports from the material ui library
import { Box, Modal } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

//imports from the react-hook-form library
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";

//importing the services from Billing Services
import {
  fetchAllOPDBillingServices,
  fetchConsultationCharges,
  getBillPrint,
  getConsultationFollowupCharge,
  getOpdPatientInfo,
  getPackageCharges,
  getRegistrationCharges,
  postOPDBillService,
  searchDoctor,
  searchPatientInformation,
} from "../../services/BillingServices";

import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";

import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { restrictSamePatientToMultiUserActivity } from "../../../login/services/LoginServices.js";

//TabPanel function is usefull to switch the tabs between Charges table and PackagedCharges table
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OPDBilling(props) {
  const location = useLocation();
  const token = JSON.parse(localStorage.getItem("userInfo"));

  //yup schema for the Payment Details form.
  const schema = yup.object().shape({
    netPayableAmount: yup.number().typeError("Required").nullable(),

    consessioninpercent: yup
      .number()
      .typeError("you must specify a number")
      .notRequired()
      .min(0, "Enter a discount percentage of at least 0%")
      .max(100, "Enter a discount percentage of at most 100%"),

    consessioninamount: yup
      .number()
      .required("Required")
      .when((consessioninamount) => {
        let totalAmount = sumOfConcessionServices();

        if (totalAmount > consessioninamount) {
          return yup.number().required();
        }
      }),

    paybacktopatient: yup
      .number()
      .typeError("you must specify a number")
      .notRequired(),

    //validation schema for Consume Amount input field
    //apply the validations to the InputField ; if and only
    //consumeAdvanceFlag is true.
    consumeAmount: yup.number().when((consumeAmount) => {
      if (isAdvanceConsumeFlag === true) {
        return yup.number().min(0.1).required("Required");
      } else if (isAdvanceConsumeFlag === false) {
        return yup.string().notRequired();
      }
    }),
  });

  //default values object for the Payment Details form.
  const defaultValues = {
    qty: "",
    rate: "",
    totalAmount: 0,
    netPayableAmount: 0,
    consessioninpercent: 0,
    consessioninamount: 0,
    gstapplicableamount: 0,
    gstamount: 0,
    paidbypatient: 0,
    paybacktopatient: 0,
    isCash: false,
    consumeAmountFlag: false,
    consumeAmount: "",
    inputSearchOPDService: null,
    opdbillingSearch: null,
    doctor: null,
    emergency: false,
    remark: "",
  };

  //getting the functions from useForm hook.
  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [confirmServiceAddition, setConfirmServiceAddition] =
    React.useState(false);

  const [confirmPackagedServiceAddition, setConfirmPackagedServiceAddition] =
    React.useState(false);

  const [isInsertTransaction, setIsInsertTransaction] = React.useState(false);

  const [saveButtonClickCount, setSaveButtonClickCount] = React.useState(0);

  const [currentServiceRateVal, setCurrentServiceRateVal] = React.useState(0);

  const [userActions, setUserActions] = React.useState(null);

  const [packagedCharges, setPackagedCharges] = React.useState([]);

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  //state variable to populate the "opdbillingSearch" SearchDropdown
  const [options, setOptions] = React.useState([]);

  //state variable to populate the options of Doctor SearchDropdown
  const [doctorList, setDoctorList] = React.useState([]);

  //state variable to populate "Search Service Code" SearchDropdown
  const [serviceSearch, setServiceSearch] = useState([]);

  //state variable to disable or enable the "Rate" Input field
  //when isRateDisabled === true then at that time Rate input field could be edited.
  //when isRateDisabled === false then at that time Rate input field could not be edited.
  const [isRateDisabled, setIsRateDisabled] = React.useState(false);

  //state variable to show or hide the OnClickAddButton
  const [showAdd, setShowAdd] = useState(false);

  //state variable to show or hide the tab panels ;
  //The tab panels of Charges and PackagedCharges.
  const [value1, setValueTabs] = React.useState(0);

  //state variable to populate the Charges table
  const [serviceData, setServiceData] = useState([]);

  //state variable to hide or show the doctor SearchDropdown
  const [doctorShareApplicable, setDoctorShareApplicable] = useState(null);

  //state variable to store the totalAmount ; when any of the service is deleted.
  const [totalAmount, setTotalAmount] = useState(0);

  //state variable to store the netPayableAmount ; when any of the service is deleted.
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);

  //state variable to calculate the totalConcessionAmount ; when the service is deleted.
  const [totalConcessionAmount, setTotalConcessionAmount] = React.useState(0);

  //state variable to store the object ; that is patched in the "opdbillingSearch" SearchDropdown.
  //This object contains the patient information.
  const [selectedPatient, setSelectedPatient] = React.useState(null);

  //state variable to open the confirmation modal ; for confirmation of saving payment
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  //state variable to open the BillInformationModal
  const [open, setOpen] = React.useState(false);

  //state variable to open the billListing modal.
  const [billListFlag, setBillListFlag] = React.useState(false);

  //state variable to store the patient advance
  const [patientAdvanceVal, setPatientAdvanceVal] = React.useState(0);

  const [consumeAmountError, setConsumeAmountError] = React.useState("");

  const [consumeTotalError, setConsumeTotalError] = React.useState("");

  const [consumeNetPayError, setConsumeNetPayError] = React.useState("");

  //This state variable is usefull to generate the bill pdf inside a modal.
  const [openBillModal, setOpenBillModal] = React.useState(false);

  //This state variable is usefull to generate the bill pdf inside a modal.

  const [customConsumeError, setCustomConsumeError] = React.useState(false);

  const [chargeId, setChargeId] = React.useState(0);

  const [companyId, setCompanyId] = React.useState(0);

  const [patientAdvanceIdVal, setPatientAdvanceIdVal] = React.useState(0);

  const [currentDoctorList, setCurrentDoctorList] = React.useState([]);

  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const [showRemark, setShowRemark] = useState(false);

  //function to close the bill printing modal
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  //function to open the bill printing modal
  const handleOpenPrintModal = (billId) => {
    setOpenBackdrop(true);

    // print service
    getBillPrint(billId)
      .then((response) => {
        setOpenPrintModal(true);
        setUrlforPrint(response);
        setOpenBackdrop(false);
      })
      .catch(() => {
        setOpenBackdrop(false);
      });
  };

  //disable or enable the Consume Amount input field ; upon checking or unchecking the "Consume Amount" checkbox.
  let isAdvanceConsumeFlag = watch("consumeAmountFlag");

  //the "Total Amount" input field's value is stored in totalAmountVal
  let totalAmountVal = watch("totalAmount");

  //the "Net Payable Amount" input fields value is stored in netPayableAmountVal
  let netPayableAmountVal = watch("netPayableAmount");

  let isCashFlag = watch("isCash");

  let paybacktopatientVal = watch("paybacktopatient");

  const searchDropdownElement = useRef("");

  const rateInputElement = useRef("");

  const doctorShareDropdownElement = useRef("");

  const addServiceButton = useRef("");

  const scrollableDivElement = useRef();

  //Event listener function to swtich between the two tabs.
  //One tab contains the Charges Table and another tab contains the PackagedCharges table.
  const TabhandleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  //useEffect to be executed when the consumeAmount check box is unticked.
  //when the consumeAmount check box is unticked then at that time bring the form to the previous conditions.
  React.useEffect(() => {
    //When the consumeAmount check box is unticked ; then at that time bring the form to the previous conditions.
    if (isAdvanceConsumeFlag === false) {
      if (
        consumeAmountError === "" &&
        consumeTotalError === "" &&
        consumeNetPayError === ""
      ) {
        let currentConsumeAmount = getValues("consumeAmount");

        if (currentConsumeAmount === "") {
          currentConsumeAmount = 0;
        }

        let netPayableAmountVal = getValues("netPayableAmount");

        //bring the paid by patient input field to the previous normal condition.
        //using the netPayableAmountVal because this is not editable.
        let paidByPatientVal =
          Number(netPayableAmountVal) + Number(currentConsumeAmount);

        //bring the netPayableAmount input field to the previous normal condition.
        netPayableAmountVal =
          Number(netPayableAmountVal) + Number(currentConsumeAmount);

        //bring the input fields to the previous conditions.
        setValue("paidbypatient", paidByPatientVal);

        //clear the errors of this editable field
        clearErrors("paidbypatient");

        //bring the input fields to the previous conditions.
        setValue("netPayableAmount", netPayableAmountVal);

        setValue("consumeAmount", "");
        //clear the errors if any
        clearErrors("consumeAmount");
      }

      //if by any chance the consume amount typed exceeds netPayableAmount , paidByPatient amount
      //or if consume amount typed exceeds available balance then
      //set the customConsumeError flag to false ; so that the red color of the Consume Amount input field goes away.
      if (
        consumeAmountError !== "" ||
        consumeTotalError !== "" ||
        consumeNetPayError !== ""
      ) {
        let totalAmountVal = 0;
        let totalPayableAmountVal = 0;
        //calculating the total amounts
        for (let serviceObj of serviceData) {
          totalAmountVal = totalAmountVal + serviceObj.Amount;

          totalPayableAmountVal =
            totalPayableAmountVal +
            (serviceObj.Amount - serviceObj.ConcessionAmount);
        }

        totalAmountVal = Math.round(totalAmountVal);

        totalPayableAmountVal = Math.round(totalPayableAmountVal);

        //make the red color to go away.
        setCustomConsumeError(false);

        //bring the input fields to the previous conditions.
        setValue("paidbypatient", totalPayableAmountVal);

        //bring the input fields to the previous conditions.
        setValue("netPayableAmount", totalPayableAmountVal);

        //bring the input fields to the previous conditions.
        setValue("totalAmount", totalAmountVal);

        setValue("consumeAmount", "");
        //clear the errors if any
        clearErrors("consumeAmount");
      }

      //When the Consume Amount checkbox is unchecked ; then at that time set the appropriate error messages to empty string.
      if (consumeAmountError !== "") {
        setConsumeAmountError("");
      }

      if (consumeTotalError !== "") {
        setConsumeTotalError("");
      }

      if (consumeNetPayError !== "") {
        setConsumeNetPayError("");
      }
    }
  }, [isAdvanceConsumeFlag]);

  //useEffect to reset the consumeAmountFlag when the cash is unticked.
  React.useEffect(() => {
    if (isCashFlag === false) {
      let currentConsumeAmountVal = getValues("consumeAmount");

      if (currentConsumeAmountVal === "") {
        currentConsumeAmountVal = 0;
      }

      let currentNetPayableAmountVal = getValues("netPayableAmount");

      currentNetPayableAmountVal =
        Number(currentNetPayableAmountVal) + Number(currentConsumeAmountVal);

      setValue("netPayableAmount", currentNetPayableAmountVal);
      setValue("paidbypatient", currentNetPayableAmountVal);
      clearErrors("paidbypatient");
      setValue("paybacktopatient", 0);

      setValue("consumeAmountFlag", false);
      setValue("consumeAmount", "");
      //clear the errors if any
      clearErrors("consumeAmount");
    }
  }, [isCashFlag]);

  //useEffect to change the totalAmounts in the Payment Details form ; when the services are added or deleted.
  React.useEffect(() => {
    let totalAmount = 0;
    let totalPayableAmount = 0;
    //calculating the total amounts
    for (let serviceObj of serviceData) {
      totalAmount = totalAmount + serviceObj.Amount;

      totalPayableAmount =
        totalPayableAmount + (serviceObj.Amount - serviceObj.ConcessionAmount);
    }

    totalAmount = Math.round(totalAmount);

    totalPayableAmount = Math.round(totalPayableAmount);

    let consumeAmount = getValues("consumeAmount");

    if (consumeAmount === "") {
      consumeAmount = 0;
    }

    totalPayableAmount = totalPayableAmount - consumeAmount;

    setValue("totalAmount", totalAmount);

    setValue("netPayableAmount", totalPayableAmount);

    setValue("paidbypatient", totalPayableAmount);

    scrollToTheBottomOfDiv();
  }, [serviceData]);

  React.useEffect(() => {
    if (selectedPatient) {
      if (selectedPatient.hasOwnProperty("patientAdvance")) {
        if (selectedPatient.patientAdvance) {
          setPatientAdvanceVal(selectedPatient.patientAdvance);
        } else {
          setPatientAdvanceVal(0);
          setValue("consumeAmount", "");
          //clear the errors if any
          clearErrors("consumeAmount");
        }
      }

      //storing the companyId in the state variable
      if (selectedPatient.hasOwnProperty("CompanyId")) {
        setCompanyId(selectedPatient.CompanyId);
        //When companyId is greater than 0
        // if (selectedPatient.CompanyId > 0) {
        //   setValue("isCash", false);
        // }
      }

      if (selectedPatient.patientCategory === "Self") {
        setValue("isCash", true);
      }

      //storing the patientAdvanceId in the state variable.
      if (selectedPatient.hasOwnProperty("AdvanceID")) {
        if (selectedPatient.AdvanceID) {
          setPatientAdvanceIdVal(selectedPatient.AdvanceID);
        }
      }
    }
  }, [selectedPatient]);

  React.useEffect(() => {
    if (doctorShareApplicable) {
      focusDoctorShareApplicableDropdown();

      let doctorId = selectedPatient.doctorId;
      let doctorName = selectedPatient.doctorName;

      let visitDoctorObj = {
        id: doctorId,
        value: doctorName,
        label: doctorName,
      };

      //set the visit doctor's value into the doctor dropdown field of the service searchdropdown.
      setValue("doctor", visitDoctorObj);
    }
  }, [doctorShareApplicable]);

  React.useEffect(() => {
    fetchUserActionsByMenuId(
      props?.menuId ? props?.menuId : location?.state?.menuId
    )
      .then((response) => response.data)
      .then((res) => {
        let requiredActionsArr = res.result;

        let tempObj = requiredActionsArr[0];

        requiredActionsArr[0] = requiredActionsArr[1];

        requiredActionsArr[1] = tempObj;

        setUserActions(requiredActionsArr);
      });
  }, []);

  //function to scroll to the bottom of the table's parent div
  function scrollToTheBottomOfDiv() {
    let requiredElement = scrollableDivElement.current;

    let tableTagElement = requiredElement.querySelector("table");

    let parentOfTableElement = tableTagElement.parentElement;

    let requiredScrollHeight = parentOfTableElement.scrollHeight;

    //scroll to the bottom
    parentOfTableElement.scrollTo({
      top: requiredScrollHeight,
      behavior: "smooth",
    });
  }

  //function that calculates the sum of all the net payable amounts.
  function sumOfNetPayableInServices() {
    let totalPayableAmount = 0;
    //calculating the total amounts
    for (let serviceObj of serviceData) {
      totalPayableAmount =
        totalPayableAmount + (serviceObj.Amount - serviceObj.ConcessionAmount);
    }

    totalPayableAmount = Math.round(totalPayableAmount);

    return totalPayableAmount;
  }

  //patient information from search field
  const getPatientInfo = (selPatientObj) => {
    let patientIdVal = selPatientObj.id;
    let visitIdVal = selPatientObj.VisitId;

    //call the below function to get the patient information by id.
    getOpdPatientInfo(patientIdVal, visitIdVal)
      .then((response) => response.data)
      .then((res) => {
        let requiredSelectedPatient = res.result;

        setSelectedPatient(res.result);
        checkSimilarTransaction(true, res.result);

        let visitId = res.result.visitId;

        //if by any chance ; the services already exist for the given patient ; at that time , those services are going to be populated using the below function.
        getRegistrationCharges(visitId)
          .then((response) => response.data)
          .then((res) => {
            //store the registrationChargeId into the state variable chargeId
            if (res.result[0]?.ChargeId) {
              setChargeId(res.result[0].ChargeId);
            }

            setServiceData(res.result);
          });
      })
      .catch((error) => {
        errorAlertCustom("Please mark visit for this patient.");
      });
  };

  //Change handler for "Search By Patient Name / MR No" SearchDropdown.
  const handleChange = (searchString) => {
    if (searchString !== "") {
      searchPatientInformation(searchString, 0)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  //Populate the DoctorList through this on change function of "Doctor" SearchDropdown
  const handleDoctorString = (searchString) => {
    if (searchString !== "") {
      searchDoctor(searchString).then((response) => {
        setDoctorList(response.data.result);
      });
    }
  };

  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  //function to close the paymentModal
  function closePaymentModal() {
    if (open === true) {
      setOpen(false);
    }
  }

  //event listener function for onClickSaveButton
  function postPureCashBill() {
    let netPayableAmountValue = Number(getValues("netPayableAmount"));
    let paidbypatientValue = Number(getValues("paidbypatient"));
    let paybacktopatientValue = paidbypatientValue - netPayableAmountValue;

    let concessionPercentVal = getValues("consessioninpercent");
    let isCash = getValues("isCash");

    if (isValid === false) {
      trigger();
    }

    //on the click of save button ; if by chance the user has put the consumeAmountVal ; then in that case we need to set the things to previous conditions.
    if (isCash === false) {
      let currentConsumeAmountVal = getValues("consumeAmount");

      if (currentConsumeAmountVal === "") {
        currentConsumeAmountVal = 0;
      }

      let currentNetPayableAmountVal = getValues("netPayableAmount");

      currentNetPayableAmountVal =
        Number(currentNetPayableAmountVal) + Number(currentConsumeAmountVal);

      setValue("netPayableAmount", currentNetPayableAmountVal);
      setValue("paidbypatient", currentNetPayableAmountVal);

      clearErrors("paidbypatient");
      setValue("consumeAmountFlag", false);
      setValue("consumeAmount", "");
      //clear the errors if any
      clearErrors("consumeAmount");
    }

    let consumeAmountFlagVal = getValues("consumeAmountFlag");
    let consumeAmountVal = getValues("consumeAmount");

    if (consumeAmountVal === "") {
      consumeAmountVal = 0;
    }

    if (serviceData.length > 0) {
      //open the confirmation modal for bill settlement when the below conditions are met.
      //amount is not consumed from advance
      if (
        isCash === true &&
        consumeAmountFlagVal === false &&
        concessionPercentVal <= 100 &&
        isValid === true &&
        consumeAmountError === "" &&
        consumeTotalError === "" &&
        consumeNetPayError === "" &&
        paybacktopatientValue >= 0
      ) {
        if (currentDoctorList.length > 0) {
          const callTheFormSubmitFlag = !currentDoctorList.some(
            (obj) => obj.DoctorShare === "True" && obj.doctorId === null
          );

          if (saveButtonClickCount === 1 && !callTheFormSubmitFlag) {
            errorAlertCustom(
              "Please select a doctor for all packaged services"
            );
          } else {
            setConfirmationOpen(true); // Open the confirmation modal.
          }
        } else {
          setConfirmationOpen(true); // Open the confirmation modal.
        }
      }

      //amount is consumed from advance
      if (
        isCash === true &&
        consumeAmountVal !== "" &&
        consumeAmountFlagVal === true &&
        concessionPercentVal <= 100 &&
        isValid === true &&
        consumeAmountError === "" &&
        consumeTotalError === "" &&
        consumeNetPayError === "" &&
        paybacktopatientValue >= 0
      ) {
        //If the packaged charges exist then check whether each packaged charge has the corresponding doctor name selected or not.
        if (currentDoctorList.length > 0) {
          let callTheFormSubmitFlag = true;

          for (let tempCurrentDoctorObj of currentDoctorList) {
            if (
              tempCurrentDoctorObj.DoctorShare === "True" &&
              tempCurrentDoctorObj.doctorId === null
            ) {
              callTheFormSubmitFlag = false;
              break;
            }
          }

          if (callTheFormSubmitFlag === false) {
            errorAlertCustom("Please select doctor for all packaged services");
          } else {
            //open the confirmation modal.
            setConfirmationOpen(true);
          }
        } else {
          //open the confirmation modal.
          setConfirmationOpen(true);
        }
      }

      //open the mixed payment mode modal
      if (
        isCash === false &&
        concessionPercentVal <= 100 &&
        isValid === true &&
        consumeAmountError === "" &&
        consumeTotalError === "" &&
        consumeNetPayError === "" &&
        customConsumeError === false
      ) {
        //If the packaged charges exist then check whether each packaged charge has the corresponding doctor name selected or not.
        if (currentDoctorList.length > 0) {
          let callTheFormSubmitFlag = true;

          for (let tempCurrentDoctorObj of currentDoctorList) {
            if (
              tempCurrentDoctorObj.DoctorShare === "True" &&
              tempCurrentDoctorObj.doctorId === null
            ) {
              callTheFormSubmitFlag = false;
              break;
            }
          }

          if (callTheFormSubmitFlag === false) {
            errorAlertCustom("Please select doctor for all packaged services");
          } else {
            //open the BillInformation modal.
            setOpen(true);
          }
        } else {
          //open the BillInformation modal.
          setOpen(true);
        }
      }
    } else if (serviceData.length === 0) {
      errorAlertCustom("Please Add At least One Service");
    }
  }

  //To populate "Search Service Code" SearchDropdown ; this function is called on change.
  const handlehOPDBillingService = (e) => {
    if (e !== "") {
      let userInfoString = localStorage.getItem("userInfo");

      let userInfoObj = JSON.parse(userInfoString);

      let isEmergencyFlag = getValues("emergency");

      if (selectedPatient !== null) {
        let serviceObj = {
          applicableTo: 0,
          description: e,
          tariffId: selectedPatient.tariffId,
          serviceCode: "%",
          unitId: userInfoObj.unitId,
          groupId: 0,
          isEmergency: isEmergencyFlag,
          classId: 2,
        };

        //service to get the array of objects of billing services.
        fetchAllOPDBillingServices(serviceObj)
          .then((response) => response.data)
          .then((res) => {
            if (e !== "") {
              // To populate "Search Service Code" SearchDropdown
              setServiceSearch(res.result);
            }

            if (res.statusCode === 200) {
              setShowAdd(true);
            } else {
              setShowAdd(false);
            }
          });
      }
    }
  };

  function addConsultationServiceData() {
    let currentRateVal = Number(getValues("rate"));

    let inputSearchOPDService = getValues("inputSearchOPDService");

    let doctorIdVal = null;
    let patientIdVal = null;

    let doctorObj = getValues("doctor");

    if (selectedPatient) {
      patientIdVal = selectedPatient.patientId;
    }

    if (doctorObj) {
      doctorIdVal = doctorObj.id;
    }

    //Get the data for the service called "Consultation Charges"
    getConsultationFollowupCharge(doctorIdVal, patientIdVal)
      .then((response) => {
        let requiredRowData = response.data.result;

        let qty = getValues("qty");
        let rate = null;
        let inputSearchOPDServiceVal = getValues("inputSearchOPDService");

        if (currentRateVal === currentServiceRateVal) {
          rate = requiredRowData.Rate;
        } else if (currentRateVal !== currentServiceRateVal) {
          rate = currentRateVal;
        }

        let doctor = getValues("doctor");

        if (doctorShareApplicable !== null && doctorShareApplicable === true) {
          setError("doctor", { type: "custom", message: "Required" });
        } else if (
          doctorShareApplicable !== null &&
          doctorShareApplicable !== true
        ) {
          clearErrors(["doctor"]);
        }

        if (qty === "") {
          setError("qty", { type: "custom", message: "Required" });
        }

        if (rate === "") {
          setError("rate", { type: "custom", message: "Required" });
        }

        //The data object is going to be pushed in the Charges table.
        let data = {};

        let Amount = qty * rate;

        let IsPackageFlag = inputSearchOPDService.IsPackage;

        let serviceIdKey = null;

        if (inputSearchOPDService) {
          data.ServiceCode = inputSearchOPDService.ServiceCode;

          data.Description = requiredRowData.ServiceName;

          data.Concession = inputSearchOPDService.Concession;
          data.GroupId = inputSearchOPDService.GroupId;
          data.IsPackageFlag = IsPackageFlag;
          data.Emergency = inputSearchOPDService.Emergency;
          //The serviceIdKey contains the serviceIdKey that is to be searched in the already existing array of objects of Charges Table.
          serviceIdKey = inputSearchOPDService.ServiceId;
          data.classId = inputSearchOPDService.ClassId;
        }

        data.ServiceId = requiredRowData.Id;

        data.id = data.ServiceId;

        data.Quantity = qty;

        data.Rate = rate;

        data.Amount = Amount;

        data.ConcessionAmount = 0;

        data.NetAmount = Amount;

        doctorShareApplicable !== false
          ? (data.doctorName = doctor.label)
          : (data.doctorName = Number(0));

        if (doctor) {
          data.doctorName = doctor.label; //if else
          data.doctorId = doctor.id;
        }

        let serviceDataClone = structuredClone(serviceData);

        //searchResult variable contains the search result. It tells whether the given serviceId stored in "serviceIdKey" variable exists in the array of objects of the charges table or not.
        let searchResult = serviceDataClone.find(searchDuplicate);

        //the below function either returns undefined or an object depending on the result of the discovery.
        //returns undefined when the search is not successfull.
        //returns an object -- (means a truthy value) when the search is successfull.
        function searchDuplicate(singleDrugItem) {
          return singleDrugItem.ServiceId === serviceIdKey;
        }

        //if the searchResult is some object ; then show the warning alert "Drug Name Already Exists".
        //When the searchResult is undefined ; then push the object into the serviceDataClone array.
        if (searchResult) {
          errorAlert("Service Already Exists !");

          setValue("inputSearchOPDService", null);
          setValue("qty", "");
          setValue("rate", "");
          setValue("doctor", null);
        }

        if (!searchResult && qty !== "" && rate !== "" && qty > 0 && rate > 0) {
          serviceDataClone.push(data);
          //set the value of state variable serviceData.
          setServiceData(serviceDataClone);

          setIsRateDisabled(true);
          setValue("inputSearchOPDService", null);
          setValue("qty", "");
          setValue("rate", "");
          setValue("doctor", null);
        }

        setDoctorShareApplicable(false);

        let classIdVal = inputSearchOPDService.ClassId;

        let serviceIdValue = inputSearchOPDService.ServiceId;

        if (inputSearchOPDService.IsPackage === true) {
          addPackageCharges(serviceIdValue, classIdVal);
        }
      })
      .catch((error) => {
        errorAlertCustom(error.response.data.message);
      });
  }

  //Event listener function for the click of "Add" button
  function AddService() {
    setValue("paybacktopatient", 0);

    let inputSearchOPDService = getValues("inputSearchOPDService");

    if (inputSearchOPDService.label === "Consultation Charges") {
      addConsultationServiceData();
    } else {
      let qty = getValues("qty");

      let rate = getValues("rate");

      let doctor = getValues("doctor");

      if (doctorShareApplicable !== null && doctorShareApplicable === true) {
        setError("doctor", { type: "custom", message: "Required" });
      } else if (
        doctorShareApplicable !== null &&
        doctorShareApplicable !== true
      ) {
        clearErrors(["doctor"]);
      }

      if (qty === "") {
        setError("qty", { type: "custom", message: "Required" });
      }

      if (rate === "") {
        setError("rate", { type: "custom", message: "Required" });
      }

      //The data object is going to be pushed in the Charges table.
      let data = {};

      let Amount = qty * rate;

      let IsPackageFlag = inputSearchOPDService.IsPackage;

      let serviceIdKey = null;

      if (inputSearchOPDService) {
        data.ServiceCode = inputSearchOPDService.ServiceCode;
        data.ServiceId = inputSearchOPDService.ServiceId;
        data.Description = inputSearchOPDService.label;
        data.Concession = inputSearchOPDService.Concession;
        data.GroupId = inputSearchOPDService.GroupId;
        data.IsPackageFlag = IsPackageFlag;
        data.Emergency = inputSearchOPDService.Emergency;
        //The serviceIdKey contains the serviceIdKey that is to be searched in the already existing array of objects of Charges Table.
        serviceIdKey = inputSearchOPDService.ServiceId;
        data.classId = inputSearchOPDService.ClassId;
      }

      data.id = data.ServiceId;

      data.Quantity = qty;

      data.Rate = rate;

      data.Amount = Amount;

      data.ConcessionAmount = 0;

      data.NetAmount = Amount;

      doctorShareApplicable !== false
        ? (data.doctorName = doctor.label)
        : (data.doctorName = "");

      if (doctor) {
        data.doctorName = doctor.label; //if else
        data.doctorId = doctor.id;
      }

      let serviceDataClone = structuredClone(serviceData);

      //searchResult variable contains the search result. It tells whether the given serviceId stored in "serviceIdKey" variable exists in the array of objects of the charges table or not.
      let searchResult = serviceDataClone.find(searchDuplicate);

      //the below function either returns undefined or an object depending on the result of the discovery.
      //returns undefined when the search is not successfull.
      //returns an object -- (means a truthy value) when the search is successfull.
      function searchDuplicate(singleDrugItem) {
        return singleDrugItem.ServiceId === serviceIdKey;
      }

      //if the searchResult is some object ; then show the warning alert "Drug Name Already Exists".
      //When the searchResult is undefined ; then push the object into the serviceDataClone array.
      if (searchResult) {
        errorAlert("Service Already Exists !");

        setValue("inputSearchOPDService", null);
        setValue("qty", "");
        setValue("rate", "");
        setValue("doctor", null);
      }

      if (!searchResult && qty !== "" && rate !== "" && qty > 0 && rate > 0) {
        serviceDataClone.push(data);
        //set the value of state variable serviceData.
        setServiceData(serviceDataClone);

        setIsRateDisabled(true);
        setValue("inputSearchOPDService", null);
        setValue("qty", "");
        setValue("rate", "");
        setValue("doctor", null);
      }

      setDoctorShareApplicable(false);
    }
  }

  //function to delete the corresponding services from the PackagedCharges table.
  function deletePackagedCharges(requiredServiceIdVal) {
    let newPackagedCharges = [];
    let newDoctorList = [];

    for (let tempPackagedCharges of packagedCharges) {
      if (
        tempPackagedCharges.correspondingChargeIdValue !== requiredServiceIdVal
      ) {
        newPackagedCharges.push(tempPackagedCharges);
      }
    }

    //The below block of code is used to update the currentDoctorList array.
    if (newPackagedCharges.length > 0) {
      for (let newPackagedChargesObj of newPackagedCharges) {
        let requiredId = newPackagedChargesObj.id;
        let resultantDoctorObj = currentDoctorList.find(
          updateCurrentDoctorList
        );

        function updateCurrentDoctorList(singleDoctorObj) {
          return singleDoctorObj.id === requiredId;
        }

        if (resultantDoctorObj) {
          newDoctorList.push(resultantDoctorObj);
        }
      }

      setCurrentDoctorList(newDoctorList);
    }

    if (newPackagedCharges.length === 0) {
      setCurrentDoctorList([]);
    }

    setPackagedCharges(newPackagedCharges);
  }

  //function to filter and return the packaged charges ; so that they can be sent into the post object.
  function filterPackagedChargesUsingServiceId(requiredServiceIdVal) {
    let requiredChargesPackageDtoList = [];

    for (let tempPackagedCharge of packagedCharges) {
      if (
        tempPackagedCharge.correspondingChargeIdValue === requiredServiceIdVal
      ) {
        requiredChargesPackageDtoList.push(tempPackagedCharge);
      }
    }

    return requiredChargesPackageDtoList;
  }

  //function to take the focus on the AddService button.
  function focusOnAddServiceButton() {
    let requiredAddServiceBtnParent = addServiceButton.current;
    let addServiceButtonTag =
      requiredAddServiceBtnParent.getElementsByTagName("button");

    addServiceButtonTag[0].focus();
  }

  //function to take the focus on services SearchDropdown field.
  function focusServicesSearchDropdown() {
    let fieldsetTag = searchDropdownElement.current;

    let inputTagCollection = fieldsetTag.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];
    let inputTagTwo = inputTagCollection[1];
    inputTagOne.focus();
  }

  //function to take the focus on doctorShareApplicableDropdown field.
  function focusDoctorShareApplicableDropdown() {
    let doctorShareDropdownElementDiv = doctorShareDropdownElement.current;

    let inputTagCollection =
      doctorShareDropdownElementDiv.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];

    inputTagOne.focus();
  }

  //function that is called for submitting the data in Payment Details form in the pure cash mode.
  function onSubmitPaymentDetails(data) {
    let consumeAmountFlagVal = getValues("consumeAmountFlag");

    let consumeAmountVal = getValues("consumeAmount");

    let netPayableAmountVal = getValues("netPayableAmount");
    let todayDateVal = new Date();
    todayDateVal = todayDateVal.toISOString();

    setOpenBackdrop(true);
    confirmationHandleClose();

    let billSevicesDtlsDtoList = [];

    //iterating the state variable serviceData
    for (let formObj of serviceData) {
      let obj = {};

      obj.rate = formObj.Rate;
      obj.qty = formObj.Quantity;
      obj.netAmount = formObj.NetAmount;
      obj.totalAmount = formObj.Amount;
      obj.totalConcessionAmount = formObj.totalConcessionAmount;
      obj.concession = formObj.ConcessionAmount;
      obj.serviceId = formObj.ServiceId;
      obj.doctorId = formObj.doctorId;
      obj.groupId = formObj.GroupId;
      obj.emergency = formObj.Emergency;
      obj.classId = formObj.classId;

      if (formObj.IsPackageFlag === true) {
        obj.isPackageService = true;
        obj.chargeIdPackage = 0;
        //call the function to get the corresponding chargesPackageDtoList
        // formObj.ServiceId send this value to that function

        let correspondingServiceId = formObj.ServiceId;

        let requiredChargesPackageDtoList = filterPackagedChargesUsingServiceId(
          correspondingServiceId
        );

        let finalChargesPackageDtoList = [];

        let userInfoString = localStorage.getItem("userInfo");

        let userInfoObj = JSON.parse(userInfoString);

        let userIdVal = userInfoObj.userId;

        for (let tempPackagesCharge of requiredChargesPackageDtoList) {
          let tempObj = {};

          let tempCurrentDoctorList = currentDoctorList;

          let requiredIdVal = tempPackagesCharge.id;

          let tempCurrentDoctorObj = tempCurrentDoctorList[requiredIdVal];

          if (
            tempCurrentDoctorObj.value !== null &&
            tempCurrentDoctorObj.label !== null
          ) {
            tempObj.doctorId = tempCurrentDoctorObj.doctorId;
          } else {
            tempObj.doctorId = 0;
          }

          tempObj.addedBy = userIdVal;
          tempObj.concession = 0;
          tempObj.discount = 0;
          tempObj.doctorShareAmount = 0;
          tempObj.emergency = true;
          tempObj.groupId = tempPackagesCharge.GroupId;
          tempObj.hospShareAmount = 0;
          tempObj.netAmt = tempPackagesCharge.NetAmt;
          tempObj.qty = 0;
          tempObj.rate = tempPackagesCharge.Rate;
          tempObj.serviceId = tempPackagesCharge.serviceId;
          tempObj.staffFree = 0;
          tempObj.tariffId = 0;
          tempObj.totalAmount = 0;
          tempObj.totalBillAmount = 0;
          tempObj.totalConcessionAmount = 0;
          tempObj.totalDiscountAmount = 0;
          tempObj.emergency = tempPackagesCharge.Emergency;

          finalChargesPackageDtoList.push(tempObj);
        }

        obj.chargesPackageDtoList = finalChargesPackageDtoList;
      } else if (formObj.IsPackageFlag === false) {
        obj.isPackageService = false;
        obj.chargeIdPackage = 0;
      }

      // if the service called registration fees is there in the services table ; then at that time we need to introduce one property called "registrationChargeId" into the object.
      if (formObj.ServiceId === 215) {
        if (chargeId !== 0) {
          obj.registrationChargeId = chargeId;
        }
      }

      billSevicesDtlsDtoList.push(obj);
    }

    let swaggerObj = {
      patientId: 0,

      billSevicesDtlsDtoList: [],

      emergencyId: 0,

      billAmount: 0,

      totalAmount: 0,

      totalConcessionAmount: 0,

      visitId: 0,

      isCash: false,

      unitId: "",

      patientSourceId: "",

      patientCategoryId: "",

      paymentInfoList: [],

      tariffId: "",

      doctorId: "",

      companyId: "",

      cashCounterId: "",

      addedBy: "",

      opdIpd: 0,

      consumeAdvance: 0,

      isAdvanceConsume: false,

      concessionAuthorisedById: 0,

      isSettled: false,

      menuId: 0,

      patientAdvanceAmount: 0,

      patientAdvanceId: 0,

      totalAdvanceAmount: 0,

      concessionRemark: "",
    };

    if (selectedPatient) {
      swaggerObj.emergencyId = selectedPatient.emergencyId;
    }

    if (location && location.state && location.state.menuId) {
      swaggerObj.menuId = location.state.menuId;
    }

    swaggerObj.totalAdvanceAmount = patientAdvanceVal;

    swaggerObj.patientId = selectedPatient.patientId;

    swaggerObj.companyId = companyId;

    swaggerObj.patientAdvanceId = patientAdvanceIdVal;

    // consumeAmountVal
    if (consumeAmountVal === "") {
      swaggerObj.consumeAdvance = 0;
    } else if (consumeAmountVal !== "") {
      swaggerObj.consumeAdvance = consumeAmountVal;
    }

    if (selectedPatient.patientAdvance === null) {
      swaggerObj.patientAdvanceAmount = 0;
    } else if (selectedPatient.patientAdvance !== null) {
      swaggerObj.patientAdvanceAmount = selectedPatient.patientAdvance;
    }

    swaggerObj.isAdvanceConsume = consumeAmountFlagVal;

    swaggerObj.billSevicesDtlsDtoList = billSevicesDtlsDtoList;
    swaggerObj.totalAmount = data.totalAmount;
    swaggerObj.totalConcessionAmount = data.consessioninamount;
    swaggerObj.billAmount = data.totalAmount;

    //send the remark only if the Remark InputField is visible
    if (showRemark) {
      swaggerObj.concessionRemark = data.remark;
    }

    if (data.isCash) {
      swaggerObj.isCash = data.isCash;
    }

    if (selectedPatient) {
      swaggerObj.visitId = selectedPatient.visitId;

      swaggerObj.unitId = selectedPatient.unitId;

      swaggerObj.patientSourceId = selectedPatient.patientSourceId;

      swaggerObj.patientCategoryId = selectedPatient.patientCategoryId;

      swaggerObj.paymentInfoList = [
        {
          amount: netPayableAmountVal,
          bankId: 0,
          paymentDate: todayDateVal,
          paymentType: {
            id: 4,
            label: "CASH",
            value: "CASH",
          },
          referenceNumber: "",
        },
      ];

      swaggerObj.tariffId = selectedPatient.tariffId;

      swaggerObj.doctorId = selectedPatient.doctorId;
    }

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    swaggerObj.cashCounterId = userInfoObj.cashCounterId;

    swaggerObj.addedBy = userInfoObj.userId;

    let privilege = "";

    let tempUserActions = structuredClone(userActions);

    for (let tempUserActionObj of tempUserActions) {
      if (tempUserActionObj.action === "Create") {
        privilege = "Create";
        break;
      }
    }

    swaggerObj.privilege = privilege;

    postOPDBillService(swaggerObj)
      .then((response) => {
        setOpenBackdrop(false);
        checkSimilarTransaction(false, selectedPatient);

        let requiredBillId = response.data.result;
        //open the bill printing modal
        handleOpenPrintModal(requiredBillId);
        closePaymentModal();
        successAlert(response.data.message);
        reset(defaultValues);
        setSelectedPatient(null);
        setServiceData([]);
        setValue("inputSearchOPDService", null);
        setValue("opdbillingSearch", null);
        setValue("consumeAmountFlag", false);
        setValue("isCash", false);
        setPatientAdvanceVal(0);
        setPackagedCharges([]);
        setCurrentDoctorList([]);
        setValueTabs(0);
        setShowRemark(false);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        closePaymentModal();
        errorAlert(error.response.data.error);

        reset(defaultValues);
        setSelectedPatient(null);
        setServiceData([]);
        setValue("inputSearchOPDService", null);
        setValue("opdbillingSearch", null);
        setValue("consumeAmountFlag", false);
        setValue("isCash", false);
        setPatientAdvanceVal(0);
        setPackagedCharges([]);
        setCurrentDoctorList([]);
        setValueTabs(0);
        setShowRemark(false);
      });
  }

  //function that is called for submitting the data in paymentInfoModal
  function submitPaymentInfoModal(
    paymentInfoListObj,
    concessionAuthorizedById,
    isSettledFlagVal,
    companyIdValue,
    patientAdvanceIdValue
  ) {
    let billSevicesDtlsDtoList = [];
    setOpenBackdrop(true);
    //iterating the state variable props.serviceData
    for (let formObj of serviceData) {
      let obj = {};

      obj.rate = formObj.Rate;
      obj.qty = formObj.Quantity;
      obj.netAmount = formObj.NetAmount;
      obj.totalAmount = formObj.Amount;
      obj.totalConcessionAmount = formObj.totalConcessionAmount;
      obj.concession = formObj.ConcessionAmount;
      obj.serviceId = formObj.ServiceId;
      obj.doctorId = formObj.doctorId;
      obj.groupId = formObj.GroupId;
      obj.emergency = formObj.Emergency;
      obj.classId = formObj.classId;

      if (formObj.IsPackageFlag === true) {
        obj.isPackageService = true;
        obj.chargeIdPackage = 0;
        //call the function to get the corresponding chargesPackageDtoList
        // formObj.ServiceId send this value to that function

        let correspondingServiceId = formObj.ServiceId;

        let requiredChargesPackageDtoList = filterPackagedChargesUsingServiceId(
          correspondingServiceId
        );

        let finalChargesPackageDtoList = [];

        let userInfoString = localStorage.getItem("userInfo");

        let userInfoObj = JSON.parse(userInfoString);

        let userIdVal = userInfoObj.userId;

        for (let tempPackagesCharge of requiredChargesPackageDtoList) {
          let tempObj = {};

          let tempCurrentDoctorList = currentDoctorList;

          let requiredIdVal = tempPackagesCharge.id;

          let tempCurrentDoctorObj = tempCurrentDoctorList[requiredIdVal];

          if (
            tempCurrentDoctorObj.value !== null &&
            tempCurrentDoctorObj.label !== null
          ) {
            tempObj.doctorId = tempCurrentDoctorObj.doctorId;
          } else {
            tempObj.doctorId = 0;
          }

          tempObj.addedBy = userIdVal;
          tempObj.concession = 0;
          tempObj.discount = 0;
          tempObj.doctorShareAmount = 0;
          tempObj.emergency = true;
          tempObj.groupId = tempPackagesCharge.GroupId;
          tempObj.hospShareAmount = 0;
          tempObj.netAmt = tempPackagesCharge.NetAmt;
          tempObj.qty = 0;
          tempObj.rate = tempPackagesCharge.Rate;
          tempObj.serviceId = tempPackagesCharge.serviceId;
          tempObj.staffFree = 0;
          tempObj.tariffId = 0;
          tempObj.totalAmount = 0;
          tempObj.totalBillAmount = 0;
          tempObj.totalConcessionAmount = 0;
          tempObj.totalDiscountAmount = 0;
          tempObj.emergency = tempPackagesCharge.Emergency;

          finalChargesPackageDtoList.push(tempObj);
        }
        obj.chargesPackageDtoList = finalChargesPackageDtoList;
      } else if (formObj.IsPackageFlag === false) {
        obj.isPackageService = false;
        obj.chargeIdPackage = 0;
      }

      // if the service called registration fees is there in the services table ; then at that time we need to introduce one property called "registrationChargeId" into the object.
      if (formObj.ServiceId === 215) {
        if (chargeId !== 0) {
          obj.registrationChargeId = chargeId;
        }
      }

      billSevicesDtlsDtoList.push(obj);
    }

    let swaggerObj = {
      patientId: 0,

      billSevicesDtlsDtoList: [],

      emergencyId: 0,

      paymentInfoList: [],

      remarks: "",

      concessionRemark: "",

      totalAmount: 0,

      billAmount: 0,

      totalConcessionAmount: 0,

      visitId: 0,

      isCash: false,

      unitId: "",

      patientSourceId: "",

      patientCategoryId: "",

      tariffId: "",

      doctorId: "",

      companyId: "",

      cashCounterId: "",

      addedBy: "",

      opdIpd: 0,

      consumeAdvance: 0,

      isAdvanceConsume: false,

      concessionAutorisedBy: 0,

      isSettled: true,

      menuId: 0,

      patientAdvanceAmount: 0,

      patientAdvanceId: 0,

      totalAdvanceAmount: 0,
    };

    if (selectedPatient) {
      swaggerObj.emergencyId = selectedPatient.emergencyId;
    }

    swaggerObj.remarks = paymentInfoListObj.remarks;

    if (location && location.state && location.state.menuId) {
      swaggerObj.menuId = location.state.menuId;
    }

    swaggerObj.totalAdvanceAmount = patientAdvanceVal;

    swaggerObj.patientId = selectedPatient.patientId;

    swaggerObj.companyId = companyIdValue;
    swaggerObj.patientAdvanceId = patientAdvanceIdValue;

    if (selectedPatient.patientAdvance === null) {
      swaggerObj.patientAdvanceAmount = 0;
    } else if (selectedPatient.patientAdvance !== null) {
      swaggerObj.patientAdvanceAmount = selectedPatient.patientAdvance;
    }

    //coming from payment info modal
    swaggerObj.isSettled = isSettledFlagVal;

    if (selectedPatient) {
      swaggerObj.visitId = selectedPatient.visitId;
      swaggerObj.unitId = selectedPatient.unitId;
      swaggerObj.patientSourceId = selectedPatient.patientSourceId;
      swaggerObj.patientCategoryId = selectedPatient.patientCategoryId;
      swaggerObj.tariffId = selectedPatient.tariffId;
      swaggerObj.doctorId = selectedPatient.doctorId;
    }

    swaggerObj.concessionAutorisedBy = concessionAuthorizedById;
    swaggerObj.paymentInfoList = paymentInfoListObj.paymentInfoList;
    swaggerObj.billSevicesDtlsDtoList = billSevicesDtlsDtoList;
    swaggerObj.isCash = false;
    swaggerObj.consumeAdvance = paymentInfoListObj.consumeAdvance;
    swaggerObj.isAdvanceConsume = paymentInfoListObj.isAdvanceConsume;

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    swaggerObj.cashCounterId = userInfoObj.cashCounterId;

    swaggerObj.addedBy = userInfoObj.userId;

    let totalAmount = getValues("totalAmount");

    let consessioninamount = getValues("consessioninamount");

    swaggerObj.totalAmount = totalAmount;
    swaggerObj.billAmount = totalAmount;
    swaggerObj.totalConcessionAmount = consessioninamount;

    let privilege = "";

    let tempUserActions = structuredClone(userActions);

    for (let tempUserActionObj of tempUserActions) {
      if (tempUserActionObj.action === "Create") {
        privilege = "Create";
        break;
      }
    }

    swaggerObj.privilege = privilege;

    //send the remark only if the Remark InputField is visible
    if (showRemark) {
      let remarksVal = getValues("remark");
      swaggerObj.concessionRemark = remarksVal;
    }

    postOPDBillService(swaggerObj)
      .then((response) => {
        setOpenBackdrop(false);
        checkSimilarTransaction(false, selectedPatient);

        let requiredBillId = response.data.result;

        //open the bill printing modal
        handleOpenPrintModal(requiredBillId);

        closePaymentModal();
        successAlert(response.data.message);

        reset(defaultValues);
        setSelectedPatient(null);
        setServiceData([]);
        setValue("inputSearchOPDService", null);
        setValue("opdbillingSearch", null);
        setValue("consumeAmountFlag", false);
        setValue("isCash", false);
        setPatientAdvanceVal(0);
        setPackagedCharges([]);
        setCurrentDoctorList([]);
        setValueTabs(0);
        setShowRemark(false);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        closePaymentModal();
        errorAlert(error.response.data.error);

        reset(defaultValues);
        setSelectedPatient(null);
        setServiceData([]);
        setValue("inputSearchOPDService", null);
        setValue("opdbillingSearch", null);
        setValue("consumeAmountFlag", false);
        setValue("isCash", false);
        setPatientAdvanceVal(0);
        setPackagedCharges([]);
        setCurrentDoctorList([]);
        setValueTabs(0);
        setShowRemark(false);
      });
  }

  //Event listener function to update the Charges table ; whenever theere is change in consessioninpercent InputField
  const handleConcessionPercent = (
    concessionPercent,
    callFromInputFieldFlag
  ) => {
    console.log(
      "consessioninamount",
      concessionPercent,
      callFromInputFieldFlag,
      serviceData
    );
    //calculating the total amounts
    if (concessionPercent <= 100 && concessionPercent >= 0) {
      clearErrors(["consessioninpercent"]);
      let updatedServices = [];

      for (let serviceObj of serviceData) {
        if (
          concessionPercent !== ""
          // && serviceObj.Concession === true  //removed as per nilesh sir
        ) {
          serviceObj.ConcessionAmount =
            (Number(concessionPercent) * Number(serviceObj.Amount)) / 100;

          serviceObj.NetAmount =
            serviceObj.Amount - serviceObj.ConcessionAmount;
        }
        updatedServices.push(serviceObj);
      }

      let totalConcessionAmount = 0;

      for (let serviceObj of updatedServices) {
        totalConcessionAmount =
          totalConcessionAmount + serviceObj.ConcessionAmount;
      }

      totalConcessionAmount = Math.round(totalConcessionAmount);

      console.log(
        "consessioninamount",
        concessionPercent,
        callFromInputFieldFlag,
        totalConcessionAmount
      );
      if (callFromInputFieldFlag === true) {
        setValue("consessioninamount", totalConcessionAmount);
      }

      setServiceData(updatedServices);
    }

    if (concessionPercent > 100) {
      setError("consessioninpercent", {
        type: "custom",
        message: "custom message",
      });
    }

    //calculating the total amounts when concession percent is an empty string
    if (concessionPercent === "") {
      let updatedServices = [];
      for (let serviceObj of serviceData) {
        if (
          concessionPercent === ""
          //&& serviceObj.Concession === true //removed as per nilesh sir
        ) {
          serviceObj.ConcessionAmount = 0;

          serviceObj.NetAmount =
            serviceObj.Amount - serviceObj.ConcessionAmount;
        }
        updatedServices.push(serviceObj);
      }

      let totalConcessionAmount = 0;

      setValue("consessioninamount", totalConcessionAmount);

      setServiceData(updatedServices);
    }
  };

  //Event listener function to update the Charges table ; whenever theere is change in consessioninamount InputField
  function handleConcessionAmount(e) {
    let concessionAmount = e.target.value;

    let concessionServicesTotalAmount = sumOfConcessionServices();

    let concessionPercentage =
      (concessionAmount / concessionServicesTotalAmount) * 100;

    concessionPercentage = concessionPercentage.toFixed(2);

    setValue("consessioninpercent", concessionPercentage);

    if (concessionPercentage > 100) {
      trigger("consessioninpercent");
    }

    if (concessionPercentage <= 100 && concessionPercentage >= 0) {
      let callFromInputFieldFlag = false;
      handleConcessionPercent(concessionPercentage, callFromInputFieldFlag);
    }

    //if concessionAmount is empty string ; then set concessionPercent to empty string.
    if (concessionAmount === "") {
      setValue("consessioninpercent", "");
      takeAwayDiscounts();
    }

    //if concessionAmount is 0 ; then set concessionPercent to 0.
    if (concessionAmount === 0) {
      setValue("consessioninpercent", 0);
      takeAwayDiscounts();
    }
  }

  //function that takes away the discount amounts entry from the table
  function takeAwayDiscounts() {
    let updatedServices = [];
    for (let serviceObj of serviceData) {
      // if (serviceObj.Concession === true) { //removed as per nilesh sir
      serviceObj.ConcessionAmount = 0;

      serviceObj.NetAmount = serviceObj.Amount - serviceObj.ConcessionAmount;
      // }
      updatedServices.push(serviceObj);
    }

    setServiceData(updatedServices);
  }

  //function to calculate the sum of all amounts whose concession flag is true.
  function sumOfConcessionServices() {
    //The below variable will contain the sum of all amounts having Concessoin flag as true.
    let totalAmount = 0;
    //iteration to calculate the sum of amounts having Concession property's value as true
    for (let serviceObj of serviceData) {
      if (serviceObj.hasOwnProperty("Concession")) {
        // if (serviceObj.Concession === true) {  //removed as per nilesh sir
        totalAmount = totalAmount + serviceObj.Amount;
        // }
      }
    }

    return totalAmount;
  }

  //function to calculate the sum of all the amounts of the services
  function sumOfAmountsInServices() {
    //The below variable will contain the sum of all amounts having Concessoin flag as true.
    let totalAmount = 0;
    //iteration to calculate the sum of amounts having Concession property's value as true
    for (let serviceObj of serviceData) {
      totalAmount = totalAmount + serviceObj.Amount;
    }

    return totalAmount;
  }

  //Event listener function to update the form ; when consume amount in the consumeAmount InputField changes.
  function handleAdvanceConsumption(consumedVal) {
    if (consumedVal > patientAdvanceVal) {
      setConsumeAmountError("* Consume amount exceeded available advance.");
      setCustomConsumeError(true);
    } else {
      setConsumeAmountError("");
      setCustomConsumeError(false);
    }

    let totalAmount = sumOfAmountsInServices();

    let totalNetPayable = sumOfNetPayableInServices();

    if (consumedVal > totalAmount) {
      setConsumeTotalError("* Consume amount exceeded total amount.");
      setCustomConsumeError(true);
    } else {
      setConsumeTotalError("");
      setCustomConsumeError(false);
    }

    if (Number(consumedVal) > Number(totalNetPayable)) {
      setConsumeNetPayError(
        "* Consume amount exceeded total net payable amount."
      );

      setCustomConsumeError(true);
    } else {
      setConsumeNetPayError("");

      setCustomConsumeError(false);
    }

    if (
      consumedVal !== "" &&
      Number(consumedVal) <= Number(totalAmount) &&
      Number(consumedVal) <= Number(totalNetPayable)
    ) {
      let netPayableAmountVal = Number(totalNetPayable) - Number(consumedVal);

      let paidbypatientVal = Number(totalNetPayable) - Number(consumedVal);

      setValue("netPayableAmount", netPayableAmountVal);

      setValue("paidbypatient", paidbypatientVal);
      clearErrors("paidbypatient");
    } else if (consumedVal === "") {
      //If the consumed amount is an empty string ; then at that time calculate again the totalAmount and netPayableAmount.
      let totalAmount = 0;
      let totalPayableAmount = 0;

      //calculating the total amounts
      for (let serviceObj of serviceData) {
        totalAmount = totalAmount + serviceObj.Amount;
        totalPayableAmount =
          totalPayableAmount +
          (serviceObj.Amount - serviceObj.ConcessionAmount);
      }

      setValue("totalAmount", totalAmount);

      setValue("netPayableAmount", totalPayableAmount);

      setValue("paidbypatient", totalPayableAmount);
      clearErrors("paidbypatient");
    }
  }

  //Event listener function for the click of Dustbin icon in Charges table.
  function deleteService(deleteIndex) {
    setValue("paybacktopatient", 0);
    let addedServices = [...serviceData];
    let serviceDeletionObj = addedServices[deleteIndex];
    if (serviceDeletionObj.IsPackageFlag === true) {
      let requiredServiceId = serviceDeletionObj.ServiceId;
      deletePackagedCharges(requiredServiceId);
    }

    setTotalAmount(totalAmount - addedServices[deleteIndex].Amount);
    setTotalPayableAmount(
      totalPayableAmount - addedServices[deleteIndex].netAmt
    );
    setTotalConcessionAmount(
      totalConcessionAmount - addedServices[deleteIndex].concessionAmount
    );
    addedServices.splice(deleteIndex, 1);
    setServiceData(addedServices);
  }

  //patch the rate field when the "Consultation Charges" service is patched in the Search Service
  //Code SearchDropdown
  function patchRateFromConsultationChargesService() {
    let requiredConsultationChargeObj = {
      departmentId: 0,
      doctorId: 0,
      serviceId: 0,
      tariffId: null,
    };

    if (selectedPatient) {
      requiredConsultationChargeObj.tariffId = selectedPatient.tariffId;
    }

    requiredConsultationChargeObj.doctorId = selectedPatient.doctorId;

    let inputSearchOPDServiceObj = getValues("inputSearchOPDService");

    requiredConsultationChargeObj.departmentId = selectedPatient.DepartmentId;
    requiredConsultationChargeObj.serviceId =
      inputSearchOPDServiceObj.ServiceId;

    fetchConsultationCharges(requiredConsultationChargeObj).then((response) => {
      let requiredResponse = response.data.result;

      setCurrentServiceRateVal(requiredResponse.Rate);

      if (requiredResponse.hasOwnProperty("Rate")) {
        // patch the rate's value into the rate InputField
        setValue("rate", requiredResponse.Rate);
      }
    });
  }

  //patch the rate field when the "Consultation Charges" service is patched in the Search Service
  //Code SearchDropdown and when the doctor dropdown field changes
  function patchRateFromDoctorShareDropdown(e) {
    let inputSearchOPDServiceVal = getValues("inputSearchOPDService");

    let requiredConsultationChargeObj = {
      departmentId: 0,
      doctorId: 0,
      serviceId: 0,
      tariffId: null,
    };

    if (selectedPatient) {
      requiredConsultationChargeObj.tariffId = selectedPatient.tariffId;
    }

    requiredConsultationChargeObj.departmentId = selectedPatient.DepartmentId;

    if (inputSearchOPDServiceVal) {
      if (inputSearchOPDServiceVal.label === "Consultation Charges") {
        if (e.target.value !== null) {
          requiredConsultationChargeObj.doctorId = e.target.value.id;
        } else if (e.target.value === null) {
          requiredConsultationChargeObj.doctorId = selectedPatient.doctorId;
        }
      }

      requiredConsultationChargeObj.serviceId =
        inputSearchOPDServiceVal.ServiceId;

      fetchConsultationCharges(requiredConsultationChargeObj).then(
        (response) => {
          let requiredResponse = response.data.result;

          setCurrentServiceRateVal(requiredResponse.Rate);

          if (requiredResponse.hasOwnProperty("Rate")) {
            // patch the rate's value into the rate InputField
            setValue("rate", requiredResponse.Rate);
          }
        }
      );
    }
  }

  //event listener function for the "Service Code" search dropdown.
  function searchServiceCodeChangeHandler(e) {
    let patchedService = e.target.value;

    if (e.target.value !== null) {
      if (patchedService.label === "Consultation Charges") {
        patchRateFromConsultationChargesService();
      }

      setIsRateDisabled(e.target.value.RateEditable);

      setValue("rate", e.target.value.Rate);
      setValue("qty", 1);

      setShowAdd(true);
      setDoctorShareApplicable(e.target.value.DoctorShareApplicable);

      clearErrors(["qty", "rate"]);

      let requiredRateDivElement = rateInputElement.current;

      let inputTagCollection =
        requiredRateDivElement.getElementsByTagName("input");

      let currentServiceObj = getValues("inputSearchOPDService");

      let rateInputTag = inputTagCollection[0];

      //When Rate is enabled for a particular service then execute the below block of code.
      if (e.target.value.RateEditable === true) {
        rateInputTag.focus();
      }

      //if e.target.value.RateEditable === false and doctorShareApplicable is false then take focus to AddButton
      //or "qty" input field.
      // DoctorShareApplicable
      if (
        e.target.value.RateEditable === false &&
        e.target.value.DoctorShareApplicable === false
      ) {
        focusOnAddServiceButton();
      }
    } else {
      setIsRateDisabled(true);
      setValue("rate", "");
      setValue("qty", "");
      setShowAdd(false);
      clearErrors(["qty", "rate"]);
    }

    if (e.target.value === null) {
      clearErrors(["qty", "rate"]);
      setValue("doctor", null);
      setDoctorShareApplicable(null);
    }
  }

  //function to add the package charges in the package charges table
  function addPackageCharges(serviceIdValue, classIdVal) {
    let tariffIdVal = selectedPatient.tariffId;

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    let unitIdValue = userInfoObj.unitId;

    if (serviceIdValue && tariffIdVal && unitIdValue) {
      getPackageCharges(serviceIdValue, tariffIdVal, unitIdValue, classIdVal)
        .then((response) => response.data)
        .then((res) => {
          let oldPackages = packagedCharges;

          let latestPackages = res.result;

          let newPackages = oldPackages.concat(latestPackages);

          let correspondingChargeIdValue = serviceIdValue;

          for (let tempNewPackage of newPackages) {
            if (
              tempNewPackage.hasOwnProperty("correspondingChargeIdValue") ===
              false
            ) {
              tempNewPackage.correspondingChargeIdValue =
                correspondingChargeIdValue;
            }
          }

          let tempCurrentDoctorList = [];

          for (let index = 0; index < newPackages.length; index++) {
            let tempObj = {};
            newPackages[index].id = index;

            if (newPackages[index].DoctorShare === "True") {
              tempObj.DoctorShare = newPackages[index].DoctorShare;
            } else if (newPackages[index].DoctorShare === "False") {
              tempObj.DoctorShare = "";
              newPackages[index].DoctorShare = "";
            }

            tempObj.id = index;
            tempObj.value = null;
            tempObj.label = null;
            tempObj.doctorId = null;

            tempCurrentDoctorList.push(tempObj);
          }

          setCurrentDoctorList(tempCurrentDoctorList);

          console.log("newPackages", newPackages);

          let updated = newPackages.map((item) => ({
            ...item,
            "Doctor Name": item.DoctorShare,
          }));

          setPackagedCharges(updated);
        });
    }
  }

  //function to trigger the addition of package charges to the package charges table.
  function addPackageChargesToThePackageTable() {
    let inputSearchOPDService = getValues("inputSearchOPDService");

    let classIdVal = inputSearchOPDService.ClassId;

    let serviceIdValue = inputSearchOPDService.ServiceId;

    addPackageCharges(serviceIdValue, classIdVal);
  }

  //function to add charges to the charges table
  function addChargesToTheChargesTable() {
    setValue("paybacktopatient", 0);

    let inputSearchOPDService = getValues("inputSearchOPDService");

    if (inputSearchOPDService.label === "Consultation Charges") {
      addConsultationServiceData();
    } else {
      let qty = getValues("qty");

      let rate = getValues("rate");

      let doctor = getValues("doctor");

      if (doctorShareApplicable !== null && doctorShareApplicable === true) {
        setError("doctor", { type: "custom", message: "Required" });
      } else if (
        doctorShareApplicable !== null &&
        doctorShareApplicable !== true
      ) {
        clearErrors(["doctor"]);
      }

      if (qty === "") {
        setError("qty", { type: "custom", message: "Required" });
      }

      if (rate === "") {
        setError("rate", { type: "custom", message: "Required" });
      }

      //The data object is going to be pushed in the Charges table.
      let data = {};

      let Amount = qty * rate;

      let IsPackageFlag = inputSearchOPDService.IsPackage;

      if (inputSearchOPDService) {
        data.ServiceCode = inputSearchOPDService.ServiceCode;
        data.ServiceId = inputSearchOPDService.ServiceId;
        data.Description = inputSearchOPDService.label;
        data.Concession = inputSearchOPDService.Concession;
        data.GroupId = inputSearchOPDService.GroupId;
        data.IsPackageFlag = IsPackageFlag;
        data.Emergency = inputSearchOPDService.Emergency;
        data.classId = inputSearchOPDService.ClassId;
      }

      data.id = data.ServiceId;

      data.Quantity = qty;

      data.Rate = rate;

      data.Amount = Amount;

      data.ConcessionAmount = 0;

      data.NetAmount = Amount;

      doctorShareApplicable !== false
        ? (data.doctorName = doctor.label)
        : (data.doctorName = "");

      if (doctor) {
        data.doctorName = doctor.label; //if else
        data.doctorId = doctor.id;
      }

      let serviceDataClone = structuredClone(serviceData);

      if (qty !== "" && rate !== "" && qty > 0 && rate > 0) {
        serviceDataClone.push(data);
        //set the value of state variable serviceData.
        setServiceData(serviceDataClone);
      }

      setDoctorShareApplicable(false);
    }
  }

  //function to search duplicate service in the charges table
  function searchDuplicateServiceInChargesTable() {
    let inputSearchOPDService = getValues("inputSearchOPDService");

    let serviceIdKey = null;

    let serviceDataClone = structuredClone(serviceData);

    //The serviceIdKey contains the serviceIdKey that is to be searched in the already existing array of objects of Charges Table.
    if (inputSearchOPDService) {
      serviceIdKey = inputSearchOPDService.ServiceId;
    }

    let searchResult = null;

    //searchResult variable contains the search result. It tells whether the given serviceId stored in "serviceIdKey" variable exists in the array of objects of the charges table or not.
    if (serviceDataClone) {
      searchResult = serviceDataClone.find(searchDuplicate);

      //the below function either returns undefined or an object depending on the result of the discovery.
      //returns undefined when the search is not successfull.
      //returns an object -- (means a truthy value) when the search is successfull.
      function searchDuplicate(singleDrugItem) {
        return singleDrugItem.ServiceId === serviceIdKey;
      }
    }

    return searchResult;
  }

  //function to search duplicate service in the package charges table
  function searchDuplicateServiceInPackageChargesTable() {
    let duplicateServiceWasFound = false;

    //case 1: packagedCharges is existing and we are going to add one service ; already existing in the packaged charges.
    if (packagedCharges.length > 0) {
      let inputSearchOPDService = getValues("inputSearchOPDService");

      if (inputSearchOPDService) {
        //The serviceIdKey contains the serviceIdKey that is to be searched in the already existing array of objects of packagedCharges Table.
        let serviceIdKey = inputSearchOPDService.ServiceId;

        for (let packagedChargesObj of packagedCharges) {
          if (packagedChargesObj.serviceId === serviceIdKey) {
            duplicateServiceWasFound = true;
            break;
          }
        }
      }
    }

    return duplicateServiceWasFound;
  }

  //function to initiate the search of charges data inside incoming package charges
  function initiateSearchOfChargesDataInsideIncomingPackagedCharges(
    incomingPackagedCharges
  ) {
    let duplicateServiceFromIncomingPackagesWasFound = false;

    let serviceDataClone = structuredClone(serviceData);

    for (let serviceObj of serviceDataClone) {
      let serviceIdKey = serviceObj.ServiceId;

      for (let packagedChargeObj of incomingPackagedCharges) {
        if (packagedChargeObj.serviceId === serviceIdKey) {
          duplicateServiceFromIncomingPackagesWasFound = true;
          break;
        }
      }

      if (duplicateServiceFromIncomingPackagesWasFound === true) {
        break;
      }
    }

    return duplicateServiceFromIncomingPackagesWasFound;
  }

  //function to initiate the addition of non cosultation service data
  function initiateAdditionOfNonConsultationServiceData(
    duplicateServiceInPackageTableWasFound,
    duplicateChargesDataInsideIncomingPackageChargesWasFound
  ) {
    let inputSearchOPDService = getValues("inputSearchOPDService");

    //not opening confirmation modal
    if (
      duplicateServiceInPackageTableWasFound === false &&
      duplicateChargesDataInsideIncomingPackageChargesWasFound === false
    ) {
      if (inputSearchOPDService) {
        addChargesToTheChargesTable();

        if (inputSearchOPDService.IsPackage) {
          addPackageChargesToThePackageTable();
        }

        setIsRateDisabled(true);
        setValue("inputSearchOPDService", null);
        setValue("qty", "");
        setValue("rate", "");
        setValue("doctor", null);
      }
    }

    //opening the confirmation modal
    if (duplicateServiceInPackageTableWasFound === true) {
      setConfirmServiceAddition(true);
    }

    //opening the confirmation modal
    if (duplicateChargesDataInsideIncomingPackageChargesWasFound === true) {
      setConfirmPackagedServiceAddition(true);
    }
  }

  //function to add those services that are not consulting charges.
  function addNonConsultationServiceData() {
    let inputSearchOPDService = getValues("inputSearchOPDService");

    let duplicateServiceInChargesTableWasFound =
      searchDuplicateServiceInChargesTable();

    //if the searchResult is some object ; then show the warning alert "Drug Name Already Exists".
    //When the search is successful
    if (duplicateServiceInChargesTableWasFound) {
      errorAlert("Service Already Exists !");

      setValue("inputSearchOPDService", null);
      setValue("qty", "");
      setValue("rate", "");
      setValue("doctor", null);
    }

    //When the searchResult is undefined -- when the search in not successful
    if (!duplicateServiceInChargesTableWasFound) {
      if (inputSearchOPDService.IsPackage === false) {
        let duplicateServiceInPackageTableWasFound = false;

        let duplicateChargesDataInsideIncomingPackageChargesWasFound = false;

        duplicateServiceInPackageTableWasFound =
          searchDuplicateServiceInPackageChargesTable();

        //function call
        initiateAdditionOfNonConsultationServiceData(
          duplicateServiceInPackageTableWasFound,
          duplicateChargesDataInsideIncomingPackageChargesWasFound
        );
      } else if (inputSearchOPDService.IsPackage === true) {
        let duplicateServiceInPackageTableWasFound = false;

        let duplicateChargesDataInsideIncomingPackageChargesWasFound = false;

        duplicateServiceInPackageTableWasFound =
          searchDuplicateServiceInPackageChargesTable();

        let classIdVal = inputSearchOPDService.ClassId;

        let serviceIdValue = inputSearchOPDService.ServiceId;

        let tariffIdVal = selectedPatient.tariffId;

        let userInfoString = localStorage.getItem("userInfo");

        let userInfoObj = JSON.parse(userInfoString);

        let unitIdValue = userInfoObj.unitId;

        if (serviceIdValue && tariffIdVal && unitIdValue) {
          getPackageCharges(
            serviceIdValue,
            tariffIdVal,
            unitIdValue,
            classIdVal
          )
            .then((response) => response.data)
            .then((res) => {
              let incomingPackagedCharges = res.result;

              duplicateChargesDataInsideIncomingPackageChargesWasFound =
                initiateSearchOfChargesDataInsideIncomingPackagedCharges(
                  incomingPackagedCharges
                );

              //function call
              initiateAdditionOfNonConsultationServiceData(
                duplicateServiceInPackageTableWasFound,
                duplicateChargesDataInsideIncomingPackageChargesWasFound
              );
            });
        } else {
          //function call
          initiateAdditionOfNonConsultationServiceData(
            duplicateServiceInPackageTableWasFound,
            duplicateChargesDataInsideIncomingPackageChargesWasFound
          );
        }
      }
    }
  }

  // *********************** similar patient for multiuser ***************************

  const checkSimilarTransaction = (isInsert, patientDtls) => {
    let obj = {
      id: {
        documentId: 0,
        functionalityId: props?.menuId
          ? props?.menuId
          : location?.state?.menuId,
        opdIpd: 0,
        opdIpdId: (!!patientDtls && patientDtls.visitId) || 0,
        patientId: (!!patientDtls && patientDtls.patientId) || 0,
        userId: token?.userId,
      },
      isInsert: isInsert,
    };

    restrictSamePatientToMultiUserActivity(obj)
      .then((response) => response.data)
      .then((res) => {
        const data = {
          initialLoad: true,
          obj: { ...obj, isInsert: false },
        };
      })

      .catch((res) => {
        res.response.data.statusCode === 400 && resetData();
        warningAlert(res.response.data.message);
      });
  };

  // ***********************^^^^^ similar User ^^^^^*************************** //

  const resetData = () => {
    reset(defaultValues);
    setServiceData([]);
    setSelectedPatient(null);
    setValue("inputSearchOPDService", null);
    setValue("opdbillingSearch", null);
    setValue("consumeAmountFlag", false);
    setValue("isCash", false);
    setPatientAdvanceVal(0);
    setConsumeAmountError("");
    setConsumeTotalError("");
    setConsumeNetPayError("");

    setPackagedCharges([]);
    setCurrentDoctorList([]);
    setValueTabs(0);
    setShowRemark(false);
  };
  // .///
  React.useEffect(() => {
    let requiredDoctorName = "";
    let requiredDoctorId = "";

    if (selectedPatient?.doctorName) {
      requiredDoctorName = selectedPatient.doctorName;
    }

    if (selectedPatient?.doctorId) {
      requiredDoctorId = selectedPatient?.doctorId;
    }

    let tempCurrentDoctorList = structuredClone(currentDoctorList);

    if (packagedCharges.length > 0) {
      for (let index = 0; index < packagedCharges.length; index++) {
        if (
          packagedCharges[index]?.["Doctor Name"] === "True" &&
          tempCurrentDoctorList[index].value === null &&
          tempCurrentDoctorList[index].label === null &&
          tempCurrentDoctorList[index].doctorId === null
        ) {
          tempCurrentDoctorList[index].value = requiredDoctorName;
          tempCurrentDoctorList[index].label = requiredDoctorName;
          tempCurrentDoctorList[index].doctorId = requiredDoctorId;
        }
      }
    }

    setCurrentDoctorList(tempCurrentDoctorList);
  }, [packagedCharges]);

  return (
    <>
      <div className="mt-24">
        <form onSubmit={onSubmitPaymentDetails}>
          <div className="px-6">
            <div className="grid grid-cols-6 md:gap-3 lg:gap-0">
              <div className="col-span-6 text-center md:-mt-6 ">
                <p className=" items-center text-black font-bold text-xl ">
                  OPD Billing
                </p>
              </div>

              <div className="col-span-3 gap-2 lg:col-span-2 md:w-full ">
                <SearchDropdown
                  control={control}
                  name="opdbillingSearch"
                  dataArray={options}
                  searchIcon={true}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Search By Patient Name / MR.No./ Mobile No."
                  handleInputChange={handleChange}
                  inputRef={{
                    ...register("opdbillingSearch", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          getPatientInfo(e.target.value);
                          focusServicesSearchDropdown();
                          setIsInsertTransaction(true);
                        }

                        if (e.target.value === null) {
                          checkSimilarTransaction(false, selectedPatient);

                          setIsInsertTransaction(false);
                          setPackagedCharges([]);

                          setServiceData([]);
                          reset(defaultValues);
                          setValue("inputSearchOPDService", null);
                          setPatientAdvanceVal(0);
                          setConsumeAmountError("");
                          setConsumeTotalError("");
                          setConsumeNetPayError("");
                          setCurrentDoctorList([]);
                          setValueTabs(0);
                          setSelectedPatient(null);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <fieldset className="ml-2">
                <CheckBoxField
                  control={control}
                  name="emergency"
                  label="Emergency"
                />
              </fieldset>
            </div>

            <div className="border items-center justify-center bg-gray-100 border-gray-300 text-left w-full rounded-md mb-2 px-2 mt-5">
              <div className="md:grid md:grid-cols-[18%_1.5%_42%_12%_1.5%_25%] lg:grid lg:grid-cols-[10.5%_1.5%_14%_7%_1.5%_17.5%_7%_1.5%_17.5%_10.5%_1.5%_14%] text-sm px-4 py-0.5">
                <div className="font-semibold ">Patient Name</div>
                <div>:</div>
                <div className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.patientName}
                </div>

                <div className="font-semibold ">Gender</div>
                <div>:</div>
                <div className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.gender}
                </div>

                <div className="font-semibold ">Age</div>
                <div>:</div>
                <div className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.ageYear}
                </div>

                <div className="font-semibold ">Patient Source</div>
                <div>:</div>
                <div className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.patientSource}
                </div>

                <div className="font-semibold pt-2">Patient Category</div>
                <div className="pt-2">:</div>
                <div className="text-gray-700 font-normal pt-2">
                  {selectedPatient && selectedPatient.patientCategory}
                </div>

                <div className="font-semibold pt-2">Tariff</div>
                <div className="pt-2">:</div>
                <div className="text-gray-700 font-normal pt-2">
                  {selectedPatient && selectedPatient.tariff}
                </div>

                {selectedPatient &&
                selectedPatient.patientCategory === "Self" ? null : (
                  <>
                    <div className="font-semibold pt-2">Company</div>
                    <div className="pt-2">:</div>
                    <div className="text-gray-700 font-normal pt-2">
                      {selectedPatient && selectedPatient.company}
                    </div>
                  </>
                )}

                <div className="font-semibold pt-2">Doctor</div>
                <div className="pt-2">:</div>
                <div className="text-gray-700 font-normal pt-2">
                  {selectedPatient && selectedPatient.doctorName}
                </div>

                {selectedPatient && selectedPatient.lastVisitDate ? (
                  <>
                    <div className="font-semibold pt-2">Last Visit Date</div>
                    <div className="pt-2">:</div>
                    <div className="text-gray-700 font-normal pt-2">
                      {selectedPatient && selectedPatient.lastVisitDate}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <div className="lg:grid lg:grid-cols-1 md:grid md:grid-cols-1  h-auto gap-2 p-2">
              <Box sx={{ width: "100%" }}>
                <div className=" grid grid-cols-12 gap-3 pl-3 items-center">
                  <div className="col-span-12 lg:col-span-1">
                    <p className="font-bold text-md flex whitespace-nowrap mr-3">
                      Service List
                    </p>
                  </div>

                  <div className="lg:col-span-4 col-span-12">
                    <fieldset ref={searchDropdownElement}>
                      <SearchDropdown
                        control={control}
                        error={errors.inputSearchOPDService}
                        searchIcon={true}
                        name="inputSearchOPDService"
                        placeholder="Search Service Code"
                        dataArray={serviceSearch}
                        isSearchable={true}
                        isClearable={true}
                        handleInputChange={(e) => {
                          handlehOPDBillingService(e);
                        }}
                        inputRef={{
                          ...register("inputSearchOPDService", {
                            onChange: (e) => {
                              searchServiceCodeChangeHandler(e);
                            },
                          }),
                        }}
                      />
                    </fieldset>
                  </div>

                  <div
                    className="lg:col-span-7 col-span-12 md:flex gap-2 items-center"
                    ref={addServiceButton}
                  >
                    <div className="lg:w-16 md:w-16">
                      <InputField
                        name="qty"
                        label=" Qty"
                        placeholder="Qty"
                        control={control}
                        error={errors.qty}
                        type="number"
                      />
                    </div>

                    <div className="lg:w-20 md:w-20" ref={rateInputElement}>
                      <InputField
                        name="rate"
                        label=" Rate"
                        placeholder="Rate"
                        control={control}
                        error={errors.rate}
                        disabled={!isRateDisabled}
                        type="number"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            focusOnAddServiceButton();
                          }
                        }}
                      />
                    </div>

                    {doctorShareApplicable !== null &&
                    doctorShareApplicable === true ? (
                      <div
                        className="lg:w-60  md:w-40"
                        ref={doctorShareDropdownElement}
                      >
                        <SearchDropdown
                          control={control}
                          name="doctor"
                          dataArray={doctorList}
                          error={errors.doctor}
                          searchIcon={false}
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Doctor"
                          handleInputChange={handleDoctorString}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              focusOnAddServiceButton();
                            }
                          }}
                          inputRef={{
                            ...register("doctor", {
                              onChange: (e) => {
                                patchRateFromDoctorShareDropdown(e);
                              },
                            }),
                          }}
                        />
                      </div>
                    ) : null}

                    {showAdd ? (
                      <CommonButton
                        label="Add"
                        className="bg-customGreen text-white"
                        onClick={() => {
                          setValue("paybacktopatient", 0);

                          let inputSearchOPDService = getValues(
                            "inputSearchOPDService"
                          );

                          if (inputSearchOPDService) {
                            if (
                              inputSearchOPDService.label ===
                              "Consultation Charges"
                            ) {
                              addConsultationServiceData();
                            } else {
                              addNonConsultationServiceData();
                            }
                          }

                          focusServicesSearchDropdown();
                        }}
                      />
                    ) : null}
                    <Box
                      sx={{
                        maxWidth: { xs: 320, sm: 480, lg: 600 },
                        textTransform: "capitalize",
                        display: "flex",
                        width: 380,
                        padding: 0,
                        fontWeight: "bold",
                      }}
                      className="w-full rounded"
                    >
                      <Tabs
                        onChange={TabhandleChange}
                        value={value1}
                        TabIndicatorProps={{
                          style: {
                            fontWeight: "bold",
                          },
                        }}
                        textColor="#0B83A5"
                        variant="scrollable"
                        scrollButtons
                      >
                        <Tab
                          label="Charges"
                          className="Charges"
                          {...a11yProps(1)}
                          sx={{
                            fontWeight: "bold",
                          }}
                        />
                        <Tab
                          label="Packaged Charges"
                          sx={{
                            fontWeight: "bold",
                          }}
                          className="PackagedCharges"
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </Box>
                  </div>
                </div>

                <div className="w-full" ref={scrollableDivElement}>
                  <div className="">
                    <TabPanel value={value1} index={0}>
                      <Charges
                        deleteService={deleteService}
                        serviceData={serviceData}
                      />
                    </TabPanel>
                  </div>
                  <div className="w-full">
                    <TabPanel value={value1} index={1}>
                      <PackagedCharges
                        dataArray={packagedCharges}
                        setPackagedCharges={setPackagedCharges}
                        setCurrentDoctorList={setCurrentDoctorList}
                        currentDoctorList={currentDoctorList}
                        selectedPatient={selectedPatient}
                      />
                    </TabPanel>
                  </div>
                </div>
              </Box>
            </div>

            {/* Advance details */}
            <div className="px-6">
              <div className="border px-2  py-1 rounded">
                <div className="lg:grid lg:grid-cols-5 md:grid md:grid-cols-2 gap-2">
                  <div className="flex  items-center  gap-2 w-full font-semibold text-sm whitespace-nowrap">
                    <span className="w-28 font-semibold"> Total Advance</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-semibold w-28 whitespace-nowrap">
                        ₹ {patientAdvanceVal}
                      </span>
                    </div>
                  </div>

                  {patientAdvanceVal === 0 ? null : (
                    <fieldset
                      className="flex space-x-2 items-center"
                      disabled={patientAdvanceVal === 0 ? true : false}
                    >
                      <div className="flex space-x-2 items-center">
                        <span className="text-gray-700 font-normal">
                          <CheckBoxField
                            control={control}
                            name="consumeAmountFlag"
                            label="Consume"
                          />
                        </span>
                      </div>

                      <div className="flex space-x-2 items-center">
                        <span className="text-gray-700 font-normal">
                          <InputField
                            name="consumeAmount"
                            label="Consume Amount"
                            placeholder="Consume Amount"
                            control={control}
                            error={
                              customConsumeError
                                ? { type: "custom", message: "Required" }
                                : errors.consumeAmount
                            }
                            disabled={!isAdvanceConsumeFlag}
                            type="number"
                            inputRef={{
                              ...register("consumeAmount", {
                                onChange: (e) => {
                                  handleAdvanceConsumption(e.target.value);
                                },
                              }),
                            }}
                            defaultValue=""
                          />
                        </span>
                      </div>
                    </fieldset>
                  )}

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap ">
                    <span className="w-28 font-semibold "> Total Charges </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-semibold w-28 whitespace-nowrap">
                        ₹ {totalAmountVal}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                    <span className="w-28 font-semibold">Payable Amount </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-semibold w-28 whitespace-nowrap">
                        ₹ {netPayableAmountVal}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                    <span className="w-28 font-semibold">Total</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-semibold w-28 whitespace-nowrap">
                        ₹ {totalAmountVal}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-1 px-6 h-auto gap-2">
              <fieldset
                className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:p-2 rounded bg-white"
              >
                <div>
                  <span className=" text-red-500">{consumeAmountError}</span>
                </div>
                <div>
                  <span className=" text-red-500">{consumeTotalError}</span>
                </div>
                <div>
                  <span className=" text-red-500">{consumeNetPayError}</span>
                </div>
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1 lg:px-2 md:px-2">
                  Payment Details
                </legend>

                <div className="lg:grid lg:grid-cols-6 md:grid md:grid-cols-3 p-2 px-6 gap-2 items-center">
                  <InputField
                    name="totalAmount"
                    disabled={true}
                    variant="outlined"
                    // placeholder="Total Amount"
                    type="number"
                    control={control}
                    // id="outlined-basic"
                    label="Total Amount ₹"
                    // size="small"
                    defaultValue={totalAmount}
                  />

                  <InputField
                    control={control}
                    disabled={true}
                    label="Net Payable Amount"
                    name="netPayableAmount"
                    type="number"
                    error={errors.netPayableAmount}
                    defaultValue={totalPayableAmount}
                  />

                  <InputField
                    name="consessioninpercent"
                    type="number"
                    label="Concession Percentage"
                    placeholder="Concession Percentage"
                    control={control}
                    error={errors.consessioninpercent}
                    // disabled={!enableConcessionField}

                    id="outlined-basic"
                    inputRef={{
                      ...register("consessioninpercent", {
                        onChange: (e) => {
                          console.log("consessioninpercent");
                          if (e.target.value === "100") {
                            setShowRemark(true);
                          } else {
                            setShowRemark(false);
                            setValue("remark", "");
                          }
                          let percentVal = e.target.value;
                          let callFromInputFieldFlag = true;
                          handleConcessionPercent(
                            percentVal,
                            callFromInputFieldFlag
                          );
                        },
                      }),
                    }}
                  />
                  <InputField
                    name="consessioninamount"
                    type="number"
                    label="Consession Amount ₹"
                    placeholder="Consession Amount"
                    error={errors.consessioninamount}
                    control={control}
                    // disabled={!enableConcessionField}
                    inputRef={{
                      ...register("consessioninamount", {
                        onChange: (e) => {
                          handleConcessionAmount(e);
                        },
                      }),
                    }}
                    defaultValue={totalConcessionAmount}
                  />
                  <InputField
                    name="gstapplicableamount"
                    type="number"
                    disabled={true}
                    label="GST Applicable Amount ₹"
                    placeholder="GST Applicable Amount"
                    control={control}
                  />
                  <InputField
                    name="gstamount"
                    type="number"
                    disabled={true}
                    label="GST Amount ₹"
                    placeholder="GST Amount"
                    control={control}
                  />

                  <InputField
                    name="paidbypatient"
                    type="number"
                    label="Paid By Patient"
                    placeholder="Paid By Patient"
                    control={control}
                    error={
                      paybacktopatientVal < 0
                        ? { message: "Custom Error" }
                        : null
                    }
                    totalPayableAmount={totalPayableAmount}
                    inputRef={{
                      ...register("paidbypatient", {
                        onChange: (e) => {
                          let paidByPatientVal = Number(e.target.value);

                          let netPayableAmountVal =
                            getValues("netPayableAmount");

                          // if (paidByPatientVal >= netPayableAmountVal) {
                          let paidBackToPatientVal =
                            Number(paidByPatientVal) -
                            Number(netPayableAmountVal);

                          paidBackToPatientVal =
                            Math.round(paidBackToPatientVal);

                          setValue("paybacktopatient", paidBackToPatientVal);
                          // }
                        },
                      }),
                    }}
                    disabled={!isCashFlag}
                  />
                  <InputField
                    name="paybacktopatient"
                    type="number"
                    disabled={true}
                    label="Pay Back To Patient ₹"
                    placeholder="Pay Back To Patient ₹ "
                    control={control}
                    error={errors.paybacktopatient}
                  />

                  {showRemark === true ? (
                    <div className="col-span-2">
                      <InputField
                        name="remark"
                        label="Remark"
                        placeholder="Remark"
                        control={control}
                      />
                    </div>
                  ) : null}

                  <fieldset>
                    <CheckBoxField
                      control={control}
                      name="isCash"
                      label="Cash Payment"
                    />
                  </fieldset>

                  <div className=" col-span-3 lg:col-span-6 flex   pt-2 justify-end ">
                    <div className="flex gap-4 ml-10">
                      {userActions &&
                        userActions.map((singleActionObj) => {
                          if (
                            singleActionObj?.isAction === false &&
                            singleActionObj?.action === "View"
                          ) {
                            return (
                              <>
                                <CommonButton
                                  label="View Bills"
                                  className="bg-customBlue text-white"
                                  onClick={() => {
                                    setBillListFlag(true);
                                  }}
                                />
                              </>
                            );
                          } else if (
                            singleActionObj?.isAction === false &&
                            singleActionObj?.action === "Create"
                          ) {
                            return (
                              <>
                                <CommonButton
                                  label="Reset"
                                  className="border border-customRed text-customRed"
                                  onClick={() => {
                                    checkSimilarTransaction(
                                      false,
                                      selectedPatient
                                    );
                                    resetData();
                                  }}
                                />

                                <CommonButton
                                  label="Save"
                                  className="bg-customGreen text-white"
                                  onClick={() => {
                                    //invoke the process to save the bill when there are packaged charges as shown below.
                                    if (
                                      saveButtonClickCount === 0 &&
                                      packagedCharges.length > 0
                                    ) {
                                      setSaveButtonClickCount(1);
                                      warningAlert(
                                        "Please check packaged charges doctor."
                                      );
                                    } else if (
                                      saveButtonClickCount === 1 &&
                                      packagedCharges.length > 0
                                    ) {
                                      postPureCashBill();
                                    }

                                    //invoke the process to save the bill when there are no packaged charges
                                    if (packagedCharges.length === 0) {
                                      postPureCashBill();
                                    }
                                  }}
                                />
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>

      {/* //open this modal when cashpayment is uncheked */}
      {/* //open the confirmationmodal when the cashpayment is cheked */}
      {open ? (
        <BillInformationModal
          open={open}
          setOpen={setOpen}
          selectedPatient={selectedPatient}
          closePaymentModal={closePaymentModal}
          submitPaymentInfoModal={submitPaymentInfoModal}
          getValues={getValues}
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          companyId={companyId}
        />
      ) : null}

      {/* open this modal when ViewBills button is clicked */}
      {billListFlag ? (
        <BillListingModal
          open={billListFlag}
          setOpen={setBillListFlag}
          menuId={props?.menuId}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      <Modal open={openBillModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            overflow: "hidden",
            borderRadius: "0.2rem",
            boxShadow: 24,
            p: 1,
          }}
        >
          <div>
            <CancelPresentationIconButton
              onClick={() => {
                setOpenBillModal(false);
              }}
            />
          </div>
        </Box>
      </Modal>

      <ConfirmationModal
        confirmationOpen={confirmServiceAddition}
        confirmationHandleClose={() => {
          setConfirmServiceAddition(false);
        }}
        confirmationSubmitFunc={() => {
          let inputSearchOPDService = getValues("inputSearchOPDService");
          if (inputSearchOPDService) {
            addChargesToTheChargesTable();

            if (inputSearchOPDService.IsPackage) {
              addPackageChargesToThePackageTable();
            }

            setIsRateDisabled(true);
            setValue("inputSearchOPDService", null);
            setValue("qty", "");
            setValue("rate", "");
            setValue("doctor", null);
          }

          setConfirmServiceAddition(false);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="This service already exists in Package Charges, do you still want to add ?"
        confirmationButtonMsg="Add Service"
      />

      <ConfirmationModal
        confirmationOpen={confirmPackagedServiceAddition}
        confirmationHandleClose={() => {
          setConfirmPackagedServiceAddition(false);
        }}
        confirmationSubmitFunc={() => {
          let inputSearchOPDService = getValues("inputSearchOPDService");
          if (inputSearchOPDService) {
            addChargesToTheChargesTable();

            if (inputSearchOPDService.IsPackage) {
              addPackageChargesToThePackageTable();
            }

            setIsRateDisabled(true);
            setValue("inputSearchOPDService", null);
            setValue("qty", "");
            setValue("rate", "");
            setValue("doctor", null);
          }
          setConfirmPackagedServiceAddition(false);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Package Charges are overlapping, do you still want to add ?."
        confirmationButtonMsg="Add Service"
      />

      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={confirmationHandleClose}
        confirmationSubmitFunc={() => {
          handleSubmit(onSubmitPaymentDetails)();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Payment Details?"
        confirmationButtonMsg="Save Payment"
      />

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}
