import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  autoSearchService,
  chargesRemoveFromAutoCharges,
  getAutoCharges,
  saveSearchService,
} from "../../services/autoChargesServices/AutoChargesServices";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import { Tooltip } from "@mui/material";
import { DeleteOnIcon, EditOnIcon } from "../../../assets/icons/CustomIcons";

export default function AutoCharges() {
  const defaultValues = {
    serviceName: null,
    isApplicableOnce: false,
    isApplicableToOPD: false,
    isApplicableToIpd: false,
  };

  const {
    control,
    watch,
    setValue,
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    // resolver: yupResolver(schema),
  });

  let serviceName = watch("serviceName");
  let isApplicableOnce = watch("isApplicableOnce");
  let isApplicableToOPD = watch("isApplicableToOPD");
  let isApplicableToIpd = watch("isApplicableToIpd");

  let userInfoString = localStorage.getItem("userInfo");
  let userInfoObj = JSON.parse(userInfoString);

  const [finalData, setFinalData] = React.useState({});
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleCloseDelete = () => {
    if (openDelete) {
      setOpenDelete(false);
    }
  };

  const [openAddServiceDelete, setOpenAddServiceDelete] = useState(false);
  const handleOpenAddServiceDelete = () => {
    if (openAddServiceDelete) {
      setOpenAddServiceDelete(false);
    }
  };

  const autoChargesHeader = [
    {
      "Service Name": "",
      "Applicable Once": "",
      "Applicable To Ipd": "",
      "Applicable To Opd": "",
    },
  ];
  const [serviceOptions, setServiceOptions] = useState([]);
  const [autoChargesList, setAutoChargesList] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const callAutoChargesTabel = () => {
    getAutoCharges()
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setAutoChargesList(res.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  // Handle Change Use For Service api
  const handleChange = (autoServceSearchString) => {
    if (autoServceSearchString !== "") {
      // let serviceObj = {
      //   applicableTo: 1,
      //   classId: 0,
      //   description: autoServceSearchString + "%",
      //   groupId: 0,
      //   isConcessionService: 0,
      //   isEmergency: false,
      //   isExternal: false,
      //   isFree: 0,
      //   serviceCode: "%",
      //   tariffId: Number(2),
      //   unitId: Number(userInfoObj.unitId),
      // };
      autoSearchService(autoServceSearchString)
        .then((response) => response.data)
        .then((res) => {
          setServiceOptions(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  const addService = () => {
    if (serviceName === null) {
      setError("serviceName", { type: "custom", message: "required" });
    }

    if (serviceName !== null) {
      let itemObj = {
        "Service Name": serviceName.label,
        "Applicable Once": isApplicableOnce,
        "Applicable To Ipd": isApplicableToIpd,
        "Applicable To Opd": isApplicableToOPD,
        serviceId: serviceName?.id,
        tariffServiceId: serviceName?.TariffId ? serviceName?.TariffId : 0,
        ConfigurationId: serviceName?.ConfigurationId
          ? serviceName?.ConfigurationId
          : 0,
      };

      if (edit !== true) {
        let allList = [...autoChargesList];

        let allreadyGetService = allList?.some(
          (item) => item.ServiceId === itemObj.serviceId
        );

        if (!allreadyGetService) {
          let arr = [...serviceData];

          const alreadyExists = serviceData.some(
            (item) => item.serviceId === itemObj.serviceId
          );
          if (!alreadyExists) {
            arr.push(itemObj);
          } else if (alreadyExists) {
            errorAlert("Service Already Exist...!");
          }
          setServiceData(arr);
        } else {
          errorAlert("Service Already Exist....!");
        }
      } else if (edit === true) {
        let arr = [...serviceData];
        const alreadyExists = serviceData.some(
          (item) => item.serviceId === itemObj.serviceId
        );
        if (!alreadyExists) {
          arr.push(itemObj);
        } else if (alreadyExists) {
          errorAlert("Service Already Exist...!");
        }
        setServiceData(arr);
      }
    }
  };
  function postAutoCharges(obj) {
    saveSearchService(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          callAutoChargesTabel();
          setServiceData([]);
          reset(defaultValues);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postAutoCharges(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let tempArr = [];
    for (let obj of serviceData) {
      let requiredObj = {
        isApplicableOnce: obj?.["Applicable Once"],
        isApplicableToIpd: obj?.["Applicable To Ipd"],
        isApplicableToOpd: obj?.["Applicable To Opd"],
        serviceId: obj?.serviceId,
        tariffServiceId: obj?.tariffServiceId,
        configurationId: obj?.ConfigurationId,
      };
      tempArr.push(requiredObj);
    }

    if (tempArr.length > 0) {
      setOpenPost(true);
      setFinalData(tempArr);
    } else if (tempArr.length === 0) {
      warningAlert("Please Add Service...!");
    }
  };

  //Focus
  const searchServiceElement = useRef("");
  const addBtnElement = useRef("");

  function focusQtyInput(type) {
    let fieldsetTag;
    if (type === "addBtn") {
      fieldsetTag = addBtnElement.current;
    }
    let inputTagCollection = fieldsetTag?.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  const editRow = (requiredObj, index) => {
    let seachObj = {
      id: requiredObj.ServiceId,
      value: requiredObj.ServiceId,
      label: requiredObj?.["Service Name"],
      ConfigurationId: requiredObj?.ConfigurationId,
    };

    setValue("serviceName", seachObj);
    setValue("isApplicableOnce", requiredObj?.["Applicable Once"]);
    setValue("isApplicableToOPD", requiredObj?.["Applicable To OPD"]);
    setValue("isApplicableToIpd", requiredObj?.["Applicable To IPD"]);
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex space-x-2 items-center">
        <Tooltip title="Remove Service">
          <span
            className=""
            onClick={() => {
              setServiceId(row.ServiceId);
              setOpenDelete(true);
            }}
          >
            <DeleteOnIcon />
          </span>
        </Tooltip>
        <Tooltip title="Edit Service">
          <span
            className=""
            onClick={() => {
              setEdit(true);
              editRow(row, index);
            }}
          >
            <EditOnIcon />
          </span>
        </Tooltip>
      </div>
    );
  };

  function handleRemoveCharges() {
    chargesRemoveFromAutoCharges(serviceId)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          handleCloseDelete();
          callAutoChargesTabel();
        }
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }
  useEffect(() => {
    callAutoChargesTabel();
  }, []);

  const renderActionsForAddService = (row, index) => {
    return (
      <Tooltip title="Remove Service">
        <span
          className="text-black"
          onClick={() => {
            setDeleteIndex(index);
            setOpenAddServiceDelete(true);
          }}
        >
          <DeleteOnIcon />
        </span>
      </Tooltip>
    );
  };

  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...serviceData];
      newTemplateData.splice(deleteIndex, 1);
      setServiceData(newTemplateData);
      handleOpenAddServiceDelete();
    }
  };

  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Auto Charges Configuration
      </h1>

      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        {autoChargesList.length > 0 ? (
          <CommonDynamicTableNew
            renderActions={renderActions}
            dataResult={autoChargesList}
            removeHeaders={["ServiceId"]}
            highlightRow={true}
            tableClass={"rounded h-[200px] lg:h-[200px] 2xl:h-[400px]"}
          />
        ) : (
          <h1 className="flex justify-center py-5 font-bold text-gray-600">
            No Record Found...
          </h1>
        )}

        <div className="flex space-x-2" ref={searchServiceElement}>
          <div className="w-5/12">
            <SearchDropdown
              control={control}
              name="serviceName"
              placeholder="Search Service"
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              error={errors.serviceName}
              dataArray={serviceOptions}
              handleInputChange={handleChange}
              isDisabled={edit === true}
            />
          </div>
          <CheckBoxField
            control={control}
            name="isApplicableOnce"
            label="Applicable Once"
          />
          <CheckBoxField
            control={control}
            name="isApplicableToOPD"
            label="Applicable To OPD"
          />
          <CheckBoxField
            control={control}
            name="isApplicableToIpd"
            label="Applicable To Ipd"
          />
          <CommonButton
            referance={addBtnElement}
            type="button"
            label="Add"
            className="bg-customBlue text-white"
            onClick={() => {
              addService();
              reset(defaultValues);
              setEdit(false);
            }}
          />
        </div>

        <CommonDynamicTableNew
          dataResult={serviceData?.length > 0 ? serviceData : autoChargesHeader}
          renderActions={
            serviceData?.length > 0 ? renderActionsForAddService : null
          }
          tableClass={"rounded h-[150px] lg:h-[250px] 2xl:h-[300px]"}
          removeHeaders={["serviceId", "tariffServiceId"]}
          highlightRow={false}
        />

        <div className="flex justify-end items-center space-x-2">
          <CommonButton
            label="Reset"
            className="border border-customRed text-customRed"
            type="button"
            onClick={() => {
              reset(defaultValues);
              setServiceData([]);
            }}
          />
          <CommonButton
            label="Save"
            className="border bg-customGreen text-white"
            type="submit"
          />
        </div>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />

      <ConfirmationModal
        confirmationOpen={openDelete}
        confirmationHandleClose={handleCloseDelete}
        confirmationSubmitFunc={handleRemoveCharges}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to remove this service...!"
        confirmationButtonMsg="Remove"
      />
      <ConfirmationModal
        confirmationOpen={openAddServiceDelete}
        confirmationHandleClose={handleOpenAddServiceDelete}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this service...!"
        confirmationButtonMsg="Remove"
      />
    </div>
  );
}
