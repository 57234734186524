import { TableContainer } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Average from "../assets/scaleEmoji/Average.png";
import DissatisfiedIcon from "../assets/scaleEmoji/Dissatisfied.png";
import HighlySatisfied from "../assets/scaleEmoji/HighlySatisfied.png";
import Poor from "../assets/scaleEmoji/Poor.png";
import Satisfied from "../assets/scaleEmoji/Satisfied.png";
import StarImage from "../assets/scaleEmoji/Star.png";
import CancelPresentationIconButton from "../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../common/components/Buttons/CommonButton";
import ConfirmationModal from "../common/components/ConfirmationModal";
import InputArea from "../common/components/FormFields/InputArea";
import RadioField from "../common/components/FormFields/RadioField";
import {
  ModalStyle,
  ModalStyleFeedback,
} from "../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../common/components/Toasts/CustomToasts";
import {
  getTemplateQuestionsForReviewForWeb,
  saveReviews,
} from "./FeedbackService";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#61C05F" : "#308fe8",
  },
}));

const bgColorsForButton = [
  "#C4FFC3",
  "#F0FFC3",
  "#FEFFE0",
  "#FFE1CF",
  "#FFCBCB",
];

function EmployeeFeedback(props) {
  const defaultValues = {
    questionfor: "",
    patientFrom: "HIMS",
    searchconsultantname: null,
    patientName: "",
    mobileNumber: "",
    reason: "",
    comment: "",
  };
  const {
    control,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  let location = useLocation();
  let reasonInputValue = watch("reason");
  let questionforValue = watch("questionfor");
  let commentValue = watch("comment");
  let navigate = useNavigate("");
  let employeeData = location.state;
  let patientFromValue = watch("patientFrom");
  let searchconsultantnameValue = watch("searchconsultantname");
  let patienNameValue = watch("patientName");
  let mobileNumberValue = watch("mobileNumber");
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [employeeFeedbackData, setEmployeeFeedbackData] = useState([]); // api response based set data
  const [questionArr, setQuestionArr] = useState([]); // calculation based on length
  const [questionData, setQuestionData] = useState([]); // final data for post obj
  const [completedQuestion, setCompletedQuestion] = useState([]); // for calculate to how many question complete
  const [openPostConfirmationModal, setOpenPostConfirmationModal] = useState(
    false
  );
  const [selectedTempalate, setSelectedTempalate] = useState([]);
  const [openDisatisfiedModal, setOpenDisatisfiedModal] = useState(false);

  // setting bg color for chips
  const getBackgroundColor = (reviewLabel) => {
    for (let obj of employeeFeedbackData) {
      const index = obj.AnsPatternScale.findIndex(
        (item) => item.reviewLabel === reviewLabel
      );
      if (index !== -1) {
        return bgColorsForButton[index];
      }
      return "#FFFFFF";
    }
  };

  // close save confirmation modal
  const handleClosePostConfirmationModal = () => {
    if (openPostConfirmationModal) {
      setOpenPostConfirmationModal(false);
    }
  };

  console.log("employeeFeedbackData", employeeFeedbackData, questionforValue);
  // save feedback
  function postFeedBacks() {
    let tempRequiredRatingArr = [];
    if (questionData.length > 0) {
      for (let obj of questionData) {
        let tempObj = {
          questionId: { id: obj.questionId },
          answerRating: Number(obj.answerRating),
          questionDeptId: {
            id: obj.questionDeptId === undefined ? null : obj.questionDeptId,
          },
          isDissatisfied: obj.isDissatisfied,
          messageForDissatisfied: obj.messageForDissatisfied,
        };
        tempRequiredRatingArr.push(tempObj);
      }
    }

    let tempObj = {
      employeeId: token.empId,
      opdIpd: employeeFeedbackData[0]["OPD-IPD"],
      templateDepartmentId: {
        id: token.departmentId,
      },
      templateId: { id: employeeFeedbackData[0].Id },
      questionsReviews: tempRequiredRatingArr,
      createdBy: token.empId,
      lastModifiedBy: token.empId,
      comment: commentValue,
      conclusionAnsId: {
        id: Number(questionforValue) || null,
      },
      scaleLength: employeeFeedbackData[0]?.AnsPatternScale.length,
    };

    saveReviews(tempObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenPostConfirmationModal(false);
        setQuestionData([]);
        setQuestionArr([]);
        setSelectedTempalate([]);
        populateTamplates();
        setEmployeeFeedbackData([]);
        setCompletedQuestion([]);
        reset(defaultValues);
        // navigate("/feedbackResponsePage");
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  // review selection
  // const handleLabelSelect = (questionIndex, question, scale, list) => {
  //   console.log("questionIndex",questionIndex,scale.value,employeeFeedbackData[0]?.AnsPatternScale.length);
  //   if (scale.value === "1") {
  //     setOpenDisatisfiedModal(true);
  //   }
  //   setQuestionData((prevData) => {
  //     const updatedData = [...prevData];
  //     updatedData[questionIndex] = {
  //       ...updatedData[questionIndex],
  //       reviewLabel: scale.reviewLabel,
  //       value: scale.value,
  //       AnswerType: list.AnswerType,
  //       questionId: question.qid,
  //       questionDeptId: question.depId,
  //       answerRating: scale.value,
  //       isDissatisfied: reasonInputValue !== "" ? true : false,
  //       messageForDissatisfied: reasonInputValue,
  //     };
  //     return updatedData;
  //   });
  //   if (scale.value !== "1" && scale.reviewLabel && scale.reviewLabel !== "") {
  //     if (!completedQuestion.includes(questionIndex)) {
  //       setCompletedQuestion((prevReviewedQuestions) => [
  //         ...prevReviewedQuestions,
  //         questionIndex,
  //       ]);
  //     }
  //   }
  // };

  const handleLabelSelect = (questionIndex, question, scale, list) => {
    console.log("questionIndex", questionIndex, scale.value);

    setQuestionData((prevData) => {
      const updatedData = [...prevData];
      updatedData[questionIndex] = {
        ...updatedData[questionIndex],
        reviewLabel: scale.reviewLabel,
        value: scale.value,
        AnswerType: list.AnswerType,
        questionId: question.qid,
        questionDeptId: question.depId,
        answerRating: scale.value,
        isDissatisfied: reasonInputValue !== "" ? true : false,
        messageForDissatisfied: reasonInputValue,
      };
      return updatedData;
    });

    if (scale.value === "1") {
      setError(`Question ${questionIndex + 1} has a scale value of 1.`);
      setOpenDisatisfiedModal(true);
    } else {
      setError(null);
    }

    setCompletedQuestion((prevCompletedQuestion) => {
      if (scale.value === "1") {
        return prevCompletedQuestion.filter((index) => index !== questionIndex);
      }
      if (
        !prevCompletedQuestion.includes(questionIndex) &&
        scale.reviewLabel &&
        scale.reviewLabel !== ""
      ) {
        return [...prevCompletedQuestion, questionIndex];
      }
      return prevCompletedQuestion;
    });
  };

  // useEffect(() => {
  //   getTemplateForFeedBackFormDropDown("Consultant", 6).then(
  //     (response) => {
  //       setSelectedTempalate(response.data.result);
  //     }
  //   );
  // }, []);

  //

  const populateTamplates = () => {
    getTemplateQuestionsForReviewForWeb(
      token.departmentId !== null ? "Staff" : "Consultant",
      token?.departmentId
    ).then((response) => {
      let tempArr = [];
      console.log("response.data.result", response.data.result);
      if (response.data?.result?.length > 0) {
        for (let obj of response.data.result) {
          let tempObj = {
            Id: obj.Id,
            Headers: obj.Headers,
            Footer: obj.Footer,
            "OPD-IPD": obj["OPD-IPD"],
            "Template Name": obj.templateName,
            Questions: JSON.parse(obj.Questions),
            HasConclusionQuestions: obj.HasConclusionQuestions,
            ConclusionQuestion: obj.ConclusionQuestion,
            AnswerType: obj.AnswerType,
            AnsPatternScale: JSON.parse(obj.AnsPatternScale),
            conclusionQuestionAns:
              obj.conclusionQuestionAns !== null
                ? JSON.parse(obj.conclusionQuestionAns)
                : { conclusionAnswers: [""] },
          };
          setQuestionArr(tempObj.Questions);

          tempArr.push(tempObj);
          setEmployeeFeedbackData(tempArr);
          if (tempObj.conclusionQuestionAns?.length > 0) {
            tempObj.conclusionQuestionAns.conclusionAnswers.map(() => {
              setValue(
                "questionfor",
                tempObj.conclusionQuestionAns.conclusionAnswers[0].id
              );
            });
          }
        }
      }
    });
  };
  useEffect(() => {
    populateTamplates();
  }, []);
  return (
    <div className="pt-16">
      {employeeFeedbackData.length > 0 ? (
        <>
          <h2 className="mt-2 text-[13px] 2xl:text-xl font-semibold p-1 text-center">
            {employeeFeedbackData?.length > 0
              ? employeeFeedbackData[0]?.["Template Name"]
              : ""}
          </h2>

          <div className="my-2">
            <div className="md:flex space-x-2 w-full items-center">
              <div className="w-full">
                <BorderLinearProgress
                  variant="determinate"
                  value={(completedQuestion.length / questionArr.length) * 100}
                />
              </div>
              <h5 className="whitespace-nowrap text-customBlue text-end text-xs md:text-sm">
                {completedQuestion.length}/{questionArr.length}
                &nbsp; Questions Remaining
              </h5>
            </div>
          </div>

          <div className="px-1">
            {employeeFeedbackData?.length > 0 &&
              employeeFeedbackData.map((list) => {
                console.log("ConclusionQuestion", list);
                return (
                  <>
                    <div className="border rounded">
                      <ul key={list.Id} className="grid px-2">
                        <div className="w-auto grid">
                          <TableContainer
                            sx={{
                              "&::-webkit-scrollbar": {
                                width: 7,
                                height: 10,
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "#ebebeb",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#7393b3",
                                borderRadius: 4,
                              },
                            }}
                            className="h-[300px] 2xl:h-[500px]"
                          >
                            {list.Questions.map((question, questionIndex) => (
                              <div
                                key={questionIndex}
                                className="text-customBlue lg:grid  grid-cols-2 justify-between border-b-2 text-[14px] items-center "
                              >
                                <h4 className="w-full ">
                                  {`${questionIndex + 1}) ${question.question}`}
                                </h4>
                                <div className="flex flex-wrap gap-1 md:gap-0  md:flex-nowrap md:space-x-3 w-full">
                                  {list.AnswerType === "Star" ? (
                                    <>
                                      {list.AnsPatternScale.map(
                                        (scale, scaleIndex) => {
                                          const bgColor = getBackgroundColor(
                                            scale.reviewLabel
                                          );
                                          return (
                                            <div className="md:w-full text-center">
                                              <div
                                                className={`flex flex-wrap md:flex-nowrap md:space-x-3 border cursor-pointer 
                                                        rounded text-center justify-center px-3 my-2 py-1 text-customBlue 
                                                        md:whitespace-nowrap w-full ${
                                                          questionData[
                                                            questionIndex
                                                          ]?.reviewLabel ===
                                                          scale.reviewLabel
                                                            ? "border bg-customBlue text-white"
                                                            : ` bg-[${bgColor}]`
                                                        }`}
                                                onClick={() => {
                                                  handleLabelSelect(
                                                    questionIndex,
                                                    question,
                                                    scale,
                                                    list
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={StarImage}
                                                  alt={`Review ${
                                                    scaleIndex + 1
                                                  }`}
                                                  style={{ height: "30px" }}
                                                />
                                              </div>
                                              <h1 className="whitespace-nowrap">
                                                {scale.reviewLabel}
                                              </h1>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}

                                  {list.AnswerType === "Emoji" ? (
                                    <>
                                      {list.AnsPatternScale.map(
                                        (scale, scaleIndex) => {
                                          const bgColor = getBackgroundColor(
                                            scale.reviewLabel
                                          );

                                          return (
                                            <div className="w-5/12 md:w-full text-center">
                                              <div
                                                className={`flex flex-wrap md:flex-nowrap md:space-x-3 border cursor-pointer 
                                            rounded text-center justify-center px-3 my-2 py-1 text-customBlue 
                                            md:whitespace-nowrap w-full ${
                                              questionData[questionIndex]
                                                ?.reviewLabel ===
                                              scale.reviewLabel
                                                ? "border bg-customBlue text-white"
                                                : ` bg-[${bgColor}]`
                                            }`}
                                                onClick={() => {
                                                  handleLabelSelect(
                                                    questionIndex,
                                                    question,
                                                    scale,
                                                    list
                                                  );
                                                }}
                                              >
                                                {scale.value === 1 ||
                                                scale.value === "1" ? (
                                                  <img
                                                    src={DissatisfiedIcon}
                                                    alt={`Review ${
                                                      scaleIndex + 1
                                                    }`}
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  />
                                                ) : null}
                                                {scale.value === 2 ||
                                                scale.value === "2" ? (
                                                  <img
                                                    src={Poor}
                                                    alt={`Review ${
                                                      scaleIndex + 1
                                                    }`}
                                                    style={{ height: "30px" }}
                                                  />
                                                ) : null}
                                                {scale.value === 3 ||
                                                scale.value === "3" ? (
                                                  <img
                                                    src={Average}
                                                    alt={`Review ${
                                                      scaleIndex + 1
                                                    }`}
                                                    style={{ height: "30px" }}
                                                  />
                                                ) : null}
                                                {scale.value === 4 ||
                                                scale.value === "4" ? (
                                                  <img
                                                    src={Satisfied}
                                                    alt={`Review ${
                                                      scaleIndex + 1
                                                    }`}
                                                    style={{ height: "30px" }}
                                                  />
                                                ) : null}
                                                {scale.value === 5 ||
                                                scale.value === "5" ? (
                                                  <img
                                                    src={HighlySatisfied}
                                                    alt={`Review ${
                                                      scaleIndex + 1
                                                    }`}
                                                    style={{ height: "30px" }}
                                                  />
                                                ) : null}
                                              </div>
                                              <h1 className="whitespace-nowrap text-xs md:text-sm">
                                                {scale.reviewLabel}
                                              </h1>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}

                                  {list.AnswerType === "Chips" ? (
                                    <>
                                      {list.AnsPatternScale.map(
                                        (scale, scaleIndex) => {
                                          const bgColor = getBackgroundColor(
                                            scale.reviewLabel
                                          );
                                          return (
                                            <button
                                              key={scaleIndex}
                                              type="button"
                                              className={`flex flex-wrap md:flex-nowrap md:space-x-3 border cursor-pointer 
                                          rounded-full text-center justify-center px-3 my-2 py-2 text-customBlue items-center
                                          md:whitespace-nowrap w-full ${
                                            questionData[questionIndex]
                                              ?.reviewLabel ===
                                            scale.reviewLabel
                                              ? "border bg-customBlue text-white"
                                              : ` bg-[${bgColor}]`
                                          }`}
                                              onClick={() => {
                                                handleLabelSelect(
                                                  questionIndex,
                                                  question,
                                                  scale,
                                                  list
                                                );
                                              }}
                                            >
                                              <h1 className="whitespace-nowrap">
                                                {scale.reviewLabel}
                                              </h1>
                                            </button>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </TableContainer>
                        </div>
                      </ul>
                    </div>
                    <div>
                      {list.HasConclusionQuestions && (
                        <>
                          <h5 className="font-semibold text-sm my-2">
                            Conclusion Question
                          </h5>
                          <div className="w-full items-center space-x-2">
                            <div className="border rounded p-2 mt-2 w-full text-xs md:text-sm flex flex-wrap ">
                              <h1> {list.ConclusionQuestion}</h1>

                              <fieldset className="px-4">
                                <RadioField
                                  control={control}
                                  name="questionfor"
                                  dataArray={list.conclusionQuestionAns}
                                />
                              </fieldset>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="flex space-x-2 w-full items-center py-3">
                        <div className=" w-full">
                          <InputArea
                            name="comment"
                            label="Comment"
                            control={control}
                          />
                        </div>
                        <div className="flex justify-end ">
                          <CommonButton
                            type="button"
                            label="Sumbit Your Review"
                            className="bg-customGreen text-white whitespace-nowrap"
                            onClick={() => {
                              if (
                                employeeData?.selectFeedbackType === "Patient"
                              ) {
                                if (patientFromValue === "Non Registered") {
                                  if (
                                    patienNameValue === "" &&
                                    mobileNumberValue === ""
                                  ) {
                                    if (patienNameValue === "") {
                                      setError("patientName", {
                                        type: "custom",
                                        message: "Required",
                                      });
                                    }
                                    if (mobileNumberValue === "") {
                                      setError("mobileNumber", {
                                        type: "custom",
                                        message: "Required",
                                      });
                                    }
                                  } else {
                                    if (
                                      completedQuestion.length >=
                                      questionArr.length
                                    ) {
                                      setOpenPostConfirmationModal(true);
                                    } else {
                                      warningAlert(
                                        "Please Complete All Ratings"
                                      );
                                    }
                                  }
                                } else {
                                  if (
                                    searchconsultantnameValue === null ||
                                    (patientFromValue === "HIMS" &&
                                      patientFromValue === "Local")
                                  ) {
                                    warningAlert("Please Select Patient First");
                                  } else if (
                                    completedQuestion.length >=
                                    questionArr.length
                                  ) {
                                    setOpenPostConfirmationModal(true);
                                  } else {
                                    warningAlert("Please Complete All Ratings");
                                  }
                                }
                              } else {
                                if (
                                  completedQuestion.length >= questionArr.length
                                ) {
                                  setOpenPostConfirmationModal(true);
                                } else {
                                  warningAlert("Please Complete All Ratings");
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            <h4 className="mt-2 text-xs md:text-sm font-semibold">
              <marquee>
                {employeeFeedbackData?.length > 0
                  ? employeeFeedbackData[0].Footer
                  : ""}
              </marquee>
            </h4>
          </div>

          <Modal
            open={openDisatisfiedModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ModalStyleFeedback} className="w-[40%] h-auto">
              <CancelPresentationIconButton
                onClick={() => {
                  setOpenDisatisfiedModal(false);
                  const updatedReviews = questionData.map((review) => {
                    return {
                      ...review,
                      isDissatisfied: false,
                      messageForDissatisfied: "",
                    };
                  });
                  setQuestionData(updatedReviews);
                  setValue("reason", "");
                }}
              />
              <h1 className="text-center font-semibold text-lg text-customBlue">
                Dissatisfied
              </h1>
              <InputArea
                control={control}
                name="reason"
                label="Reason"
                error={reasonInputValue !== "" ? false : true}
              />

              <div className="my-3 text-end">
                <CommonButton
                  type="button"
                  label="Ok"
                  className={"bg-customBlue text-white h-9 "}
                  onClick={() => {
                    if (reasonInputValue !== "") {
                      setOpenDisatisfiedModal(false);
                      const updatedReviews = questionData.map(
                        (review, index) => {
                          if (!completedQuestion.includes(index)) {
                            setCompletedQuestion((prevReviewedQuestions) => [
                              ...prevReviewedQuestions,
                              index,
                            ]);
                          }
                          return {
                            ...review,
                            isDissatisfied:
                              reasonInputValue !== "" ? true : false,
                            messageForDissatisfied:
                              reasonInputValue !== "" ? reasonInputValue : null,
                          };
                        }
                      );

                      setQuestionData(updatedReviews);
                      setValue("reason", "");
                    }
                  }}
                />
              </div>
            </Box>
          </Modal>
          <ConfirmationModal
            confirmationOpen={openPostConfirmationModal}
            confirmationHandleClose={handleClosePostConfirmationModal}
            confirmationSubmitFunc={postFeedBacks}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure you want to Submit Your Review ?"
            confirmationButtonMsg="Proceed"
          />
        </>
      ) : (
        <div className="my-36 text-center ">
          <h3>Currently We Are Not Taking Any FeedBack...</h3>
        </div>
      )}
    </div>
  );
}

export default EmployeeFeedback;
