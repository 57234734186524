import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { ModalStyleFeedback } from "../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { getDrugNameDropdown } from "../../../ipd/services/drugadministratororderservices/DrugAdministratOrorderServices";
import { saveIVFluid } from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import AddIVTable from "./common/AddIVTable";

function AddIVModal(props) {
  const Actions = [
    {
      id: 2,
      action: "Save",
      isAction: false,
    },
  ];

  const schema = yup.object().shape({
    ivFluid: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),
    quantity: yup
      .string()
      .required("Required")
      .matches(/^[+1-9]+[0-9]*$/, "Only numbers are allowed"), //should start with '+' sign
    volume: yup
      .string()
      .required("Required")
      .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric are allowed"),
    rateOfInfusion: yup.string().required("Required"),
  });
  //the object to reset the form to blank values
  const defaultValues = {
    id: "",
    ivFluid: null,
    volume: "",
    rateOfInfusion: "",
    quantity: "",
    selectDrug: null,
    dose: "",
    instruction: "",
    date: new Date(),
  };
  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues,
  });
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const [drugNameOptions, setDrugNameOptions] = useState([]);
  const [infusionData, setInfusionData] = useState([]); // table data
  const [finalData, setFinalData] = useState({});

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleCloseConfirmationModal = () => {
    if (openConfirmationModal) {
      setOpenConfirmationModal(false);
    }
  };

  const handleSearchIVDrug = (e) => {
    if (e !== "") {
      getDrugNameDropdown(e).then((response) => {
        setDrugNameOptions(response.data.result);
      });
    } else {
      setDrugNameOptions([]);
    }
  };

  // get drug Name
  const handleSearchDrugName = (e) => {
    if (e !== "") {
      getDrugNameDropdown(e).then((response) => {
        setDrugNameOptions(response.data.result);
      });
    } else {
      setDrugNameOptions([]);
    }
  };

  const handleAddIVData = () => {
    let addToDrugs = getValues("selectDrug");
    let doseValue = getValues("dose");
    if (addToDrugs !== null && doseValue !== "") {
      const itemObj = {
        id: addToDrugs.id,
        "Drug Name": addToDrugs.label,
        Dose: doseValue,
      };

      // Check if the selected item already exists in the infusionData array
      const itemExists = infusionData.some((item) => item.id === itemObj.id);

      if (itemExists) {
        // Show an alert or error message indicating that the item already exists
        warningAlert("Drug already Exist");
      } else {
        const arr = [...infusionData];
        arr.push(itemObj);
        setInfusionData(arr);
        setValue("selectDrug", null);
        setValue("dose", "");
      }
    }
  };

  function postIVFluid(obj) {
    saveIVFluid(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData({});
        setInfusionData([]);
        reset(defaultValues);
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        handleCloseConfirmationModal();
        props.setOpenLoader(false);
      });
  }

  function addRecord() {
    setOpenConfirmationModal(false);
    postIVFluid(finalData);
    props.setOpenLoader(true);
  }
  const onSubmitDataHandler = (data) => {
    let reqArrForInfusionData = [];
    for (let obj of infusionData) {
      let tempObj = {};
      tempObj.doseOfAddedDrug = obj.Dose;
      tempObj.drugId = Number(obj.id);
      tempObj.drugText = obj["Drug Name"];
      reqArrForInfusionData.push(tempObj);
    }

    let postObj = {
      menuId:
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
      privilege: props.privilege,
      addedBy: token.userId,
      admissionId: props.rowData?.AdmissionId,
      ivFluidDetailsDtoList: reqArrForInfusionData,
      ivFluidDrugId: data.ivFluid.id,
      ivFluidDrugText: data.ivFluid.label,
      ivVol: data.volume,
      quantity: Number(data.quantity),
      rateOfInfusion: data.rateOfInfusion,
      remark: data.instruction,
      ivDate:   format(data.date, "yyyy-MM-dd'T'HH:mm:ss")
    };
    console.log("addIv", postObj);

    setFinalData(postObj);
    setOpenConfirmationModal(true);
  };

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyleFeedback} className="h-[80%] 2xl:h-[65%] w-[80%] p-4 ">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              reset(defaultValues);
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <h1 className="text-lg font-semibold my-2 flex justify-center">
              Intravenous Infusion Therapy
            </h1>
            <div className="w-[25%]">
              <DatePickerFieldNew
                control={control}
                name="date"
                label="Date"
                disableFuture={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <h1 className="text-sm font-semibold my-2">I.V. Fluid</h1>
            <div className="grid grid-cols-2 gap-2 lg:grid-cols-4">
              <div className="flex space-x-2">
                <CreateableSelect
                  control={control}
                  name="ivFluid"
                  placeholder="I.V.Fluid"
                  onInputChange={handleSearchIVDrug}
                  error={errors.ivFluid}
                  dataArray={drugNameOptions}
                />
              </div>
              <div>
                <InputField
                  control={control}
                  label="Volume"
                  name="volume"
                  error={errors.volume}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div>
                <InputField
                  control={control}
                  error={errors.rateOfInfusion}
                  label="Rate Of Infusion"
                  name="rateOfInfusion"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div>
                <InputField
                  control={control}
                  error={errors.quantity}
                  label="Quantity"
                  name="quantity"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>

            <h1 className=" text-gray-700 font-semibold whitespace-nowrap mt-2">
              Add Drug
            </h1>
            <div className="flex space-x-2 w-9/12 items-center mt-2">
              <div className="w-full ">
                <CreateableSelect
                  control={control}
                  name="selectDrug"
                  placeholder="Select Drug"
                  onInputChange={handleSearchDrugName}
                  error={errors.selectDrug}
                  dataArray={drugNameOptions}
                />
              </div>
              <div className="w-8/12">
                <InputField
                  name="dose"
                  control={control}
                  label="Dose"
                  error={errors.dose}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <CommonButton
                  type="button"
                  className="border border-customBlue text-customBlue"
                  label="Add"
                  onClick={handleAddIVData}
                />
              </div>
            </div>
            <h1 className="text-lg font-semibold">Intravenous Infusion List</h1>
            <AddIVTable data={infusionData} setData={setInfusionData} />
            <div className="flex items-center justify-between">
              <div className="w-5/12">
                <InputField
                  name="instruction"
                  label="Instruction"
                  control={control}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {Actions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Save" ? (
                    <div className="flex space-x-2">
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        onClick={() => {
                          reset(defaultValues);
                        }}
                      />
                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
export default React.memo(AddIVModal);
