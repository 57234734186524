import apiClient from "../../../../http-common";

import authHeader from "../../../../authentication/authservices/auth-header";

export const getItemList = (itemSearchString, storeId) => {
  return apiClient.get(
    `/materialConsumption/searchItem/${itemSearchString}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getConsumptionListPrint = (itemConsumptionId) => {
  
 
  return apiClient.get(
    `/reports/inventory/materialConsumption/${itemConsumptionId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

export const getItemBatchNumber = (itemBatchPostObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemBatchNumber`,
    itemBatchPostObj,
    {
      headers: authHeader(),
    }
  );
};

export const postMaterialConsumptionData = (materialConsumptionPostObj) => {
  
 
  return apiClient.post(
    `/materialConsumption/saveMaterialConsumption`,
    materialConsumptionPostObj,
    {
      headers: authHeader(),
    }
  );
};

export const getPreviousConsumptionList = (previousConsumptionListObj) => {
  return apiClient.post(
    `/materialConsumption/previousIssueList`,
    previousConsumptionListObj,
    {
      headers: authHeader(),
    }
  );
};

export const getPreviousIssueDetailsList = (itemConstId) => {
  return apiClient.get(`/materialConsumption/issueDetailsList/${itemConstId}`, {
    headers: authHeader(),
  });
};
