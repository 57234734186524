//imports from react library
import * as React from "react";
//mui componants and icons
import AddNewButton from "../../common/components/Buttons/AddNewButton";
import SearchIconButton from "../../common/components/Buttons/SearchIconButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
//fromfield control liberary componant import
import SearchBar from "../../common/components/FormFields/SearchBar";
//LodingSpinner
import LoadingSpinner from "../../common/components/loadingspinner/loadingSpinner";
import { getRolesList } from "../Services/UserManagementServices";
import RoleModal from "../roles/RoleModal";
import RolesListTable from "./RolesListTable";
//function start
export default function Role() {
  let searchValue = "";

  //state variable to indicate the page number
  const [page, setPage] = React.useState(0);

  //state variable to inidicate rows per page
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //state variable to indicate the total number of records coming from api
  const [count, setCount] = React.useState();

  const [selectedObj, setSelectedObj] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  //The state variable to store the data coming from the api
  const [data, setData] = React.useState({ result: [], actions: [] });

  const [searchString, setSearchString] = React.useState("");
  const [dataResult, setDataResult] = React.useState([]);
  //state variable for showing or not showing spinner
  const [spinner, showSpinner] = React.useState(false);

  const [recordWarning, showRecordWarning] = React.useState(false);
  //add edit update and cancel button
  const [edit, setEdit] = React.useState(false);
  const [searchBar, setSearchBar] = React.useState("");
  const [deleteId, setDeleteId] = React.useState("");
  //state variables to open and close the delete modal
  const [openChild, setOpenChild] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [rolesTableData, setRolesTableData] = React.useState("");
  const [rowToEdit,setRowToEdit]=React.useState("")
  //function to open the confirmation modal
  const handelOpenChild = () => setOpenChild(true);

  //function to close the confirmation modal
  const handleCloseChild = () => {
    if (openChild) {
      setOpenChild(false);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [idValue, setIdValue] = React.useState("");
  // search object for search in tables
  let searchObj = {
    page: 0,
    searchString: searchString?.value ? "" : searchBar ? searchBar : "",
    size: rowsPerPage,
    searchId: searchString?.value ? searchString.value : null,
  };
  //populate the CommonMasterTable using the function populateTable
  function populateTable() {
    showSpinner(true);
    let searchObject = {
      cashCounterId: 0,
      roleId: 0,
    };
    getRolesList(searchObject)
      .then((response) => response.data)
      .then((res) => {
        showSpinner(false);
        let rolesArray = [];
        for (let roleObject of res.result) {
          let roleObjectNew = {
            id: roleObject.id,
            Role: roleObject.Role,
            "Role Description": roleObject["Role Description"],
            "Cash Counter": roleObject?.["Cash Counter"]?.split(","),
          };
          rolesArray.push(roleObjectNew);
        }
        setRolesTableData(rolesArray);
      });
    showRecordWarning(false);
  }

  React.useEffect(() => {
    populateTable();
  }, []);

  //use props forthe DropdownField/ searchbar
  const handleChange = (autoSearchString) => {
    setSearchBar(autoSearchString);
    if (autoSearchString) {
      searchValue = autoSearchString;
    }
  };
  //after search get selected value and set selected value to object i.e (setSelectedObj) from this hook
  const autoSelectedValue = (value) => {
    if (value !== null) {
      setSearchString(value);
    } else if (value === null) {
      setSearchString("");
    }
  };
  //event listener function for edit icon
  function editRow(role) {
    setEdit(true);
    setRowToEdit(role)
    handleOpen();
  }

  function displayView(row) {}
  // function to delete the table data
  function deleteRow(row) {
    handelOpenChild();
    setDeleteId(row.Id);
  }

  //axios request for data deletion. That is delete request
  function deleteRecord() {
    handleCloseChild();
    setOpenBackdrop(true);
  }

  return (
    <>
      {/* <div className="w-full "> */}
      <div className="w-full grid pt-10 px-6 mt-8 md:rounded-md">
        <div className="flex justify-center text-xl">
          <h1 className=" text-gray-700 font-Poppins lg:hidden ">Role</h1>
        </div>

        {/*searchable dropdown */}
        <div className="flex gap-2 w-full items-center mt-2">
          {/* <div className=" flex items-center w-full gap-14"> */}
          <h1 className="text-xl text-gray-700 font-Poppins hidden lg:block">
            Role
          </h1>

          <div className="flex w-full lg:grid grid-cols-2 gap-2 items-center ">
            <div className="grid w-full grid-cols-1">
              <SearchBar
                selectedObj={selectedObj}
                type="button"
                name="SearchableSelect"
                placeholder="Search by Role Code / Name"
                dataArray={options}
                isSearchable={true}
                handleInputChange={handleChange}
                onChange={autoSelectedValue}
              />
            </div>
            <div className="flex justify-between">
              <div className="flex items-center">
                <SearchIconButton
                  onClick={() => {
                    populateTable();
                  }}
                />
              </div>
            </div>
          </div>
          {/* </div> */}

          <div className="grid justify-end xl:col-span-3 w-1/3">
            {/* Modal for Add */}
            <AddNewButton
              onClick={() => {
                handleOpen();
                setEdit(false);
              }}
            />
          </div>
          <CommonBackDrop openBackdrop={openBackdrop} />
        </div>

        {spinner ? (
          <div className="grid justify-center items-center pt-12">
            <LoadingSpinner />
          </div>
        ) : null}

        {/* CommonMasterTable Component */}
        {rolesTableData.length > 0 && spinner === false ? (
          <div className="my-5">
            <RolesListTable data={rolesTableData} setData={setRolesTableData} editRow={editRow}/>
          </div>
        ) : null}

        {recordWarning === true && spinner === false ? (
          <div className="flex justify-center">
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        ) : null}

        <RoleModal
          populateTable={populateTable}
          edit={edit}
          setEdit={setEdit}
          open={open}
          setOpen={setOpen}
          idValue={idValue}
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          handleOpen={handleOpen}
          handleClose={handleClose}
          rowToEdit={rowToEdit}
        />
        <ConfirmationModal
          confirmationOpen={openChild}
          confirmationHandleClose={handleCloseChild}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation "
          confirmationMsg="Are you sure want to delete this record ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    
    </>
  );
  
}

