import React from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { TextField } from "@mui/material";
import InputArea from "../../../../common/components/FormFields/InputArea";

const adviceForMlc = [
  {
    id: "0",
    value: "0",
    label: "No",
  },
  {
    id: "1",
    value: "1",
    label: "Yes",
  },
];
const GeneralDetails = () => {
  const { control, watch, register } = useFormContext();

  const mlc = watch("adviceForMlc");

  return (
    <div>
      <div className=" w-full flex items-center justify-start pb-3">
        <div className=" font-semibold text-sm text-gray-700  lg:ml-4 md:ml-2 pb-1">
          Advice For Mlc
        </div>
        <div className="flex  justify-center ml-4 ">
          <RadioField
            label=""
            name="adviceForMlc"
            control={control}
            dataArray={adviceForMlc}
          />
        </div>
        {/* ///If Yes/// */}
        {mlc === "yes" ? (
          <div className="lg:col-span-2 lg:w-3/12 md:w-5/12">
            <InputField
              name="ifYes"
              variant="outlined"
              label="If Yes"
              // error={errors.nameOfRepresentative}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        ) : null}
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-3">
        {/* ///Document Formalities/// */}
        <div className="lg:col-span-2">
          <InputArea
            name="documentFormalities"
            control={control}
            label="Document Formalities"
            placeholder="Document Formalities"
          />
        </div>
        {/* ///History Of Pain Abdomen/// */}
        <div className="lg:col-span-2">
          <InputArea
            name="painAbdomen"
            control={control}
            label="History Of Pain Abdomen"
            placeholder="History Of Pain Abdomen"
          />
        </div>

        {/* ///History Of Vaginal Bleeding/// */}
        <div className="col-span-2">
          <InputArea
            name="vaginalBleeding"
            control={control}
            label="History Of Vaginal Bleeding"
            placeholder="History Of Vaginal Bleeding"
          />
        </div>
        {/* ///History Of Leaking/// */}
        <div className="lg:col-span-2">
          <InputArea
            name="historyOfLeaking"
            control={control}
            label="History Of Leaking"
            placeholder="History Of Leaking"
          />
        </div>
        {/* ///Per Vaginal  /// */}
        <div className="lg:col-span-2">
          <InputArea
            name="perVaginal"
            control={control}
            label="Per Vaginal"
            placeholder="Per Vaginal"
          />
        </div>
        {/* ///since/// */}
        <div className="col-span-1">
          <InputField
            name="since"
            variant="outlined"
            label="Since"
            // error={errors.nameOfRepresentative}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 100 }}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralDetails;
