import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const saveNeedleStickInjury = (saveObj) => {
  return apiClient.post(`/needleStickInjury/saveNeedleStickInjury`, saveObj, {
    headers: authHeader(),
  });
};

export const getNeedleStickInjuryList = (listObj) => {
  return apiClient.post(
    `/needleStickInjury/getNeedleStickInjuryList`,
    listObj,
    {
      headers: authHeader(),
    }
  );
};

export const getNeedleStickInjuryDetails = (nsiFormId) => {
  return apiClient.get(
    `/needleStickInjury/getNeedleStickInjuryDetails/${nsiFormId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getNatureOfInjury = () => {
  return apiClient.get(`/needleStickInjury/getNatureOfInjury`, {
    headers: authHeader(),
  });
};

export const getPatientCondition = () => {
  return apiClient.get(`/needleStickInjury/getPatientCondition`, {
    headers: authHeader(),
  });
};

export const getPlaceOfIncident = () => {
  return apiClient.get(`/needleStickInjury/getPlaceOfIncident`, {
    headers: authHeader(),
  });
};

export const getReccomendedTests = () => {
  return apiClient.get(`/needleStickInjury/getReccomendedTests`, {
    headers: authHeader(),
  });
};

export const getVaccineHistory = () => {
  return apiClient.get(`/needleStickInjury/getVaccineHistory`, {
    headers: authHeader(),
  });
};

export const getNSIPatientSearch = (searchString) => {
  return apiClient.get(`/needleStickInjury/getPatientSearch/${searchString}`, {
    headers: authHeader(),
  });
};

export const getGender = () => {
  return apiClient.get(`/misc/gender`, {
    headers: authHeader(),
  });
};
