import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { errorAlert, successAlert } from "../../../common/components/Toasts/CustomToasts";
import { saveTransferToWard } from "../../services/emergencyservice/EmergencyServices";
const unit = [
  {
    id: 1,
    value: "Reason",
    label: "Reason",
  },
];
export default function TransferToWardModal(props) {
  const [newHeader, setNewHeader] = React.useState("");
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minHeight: "30%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    py: 4,
    px: 2,
  };
  function onSubmitDataHandler(data) {
    let getPatientInfo = localStorage.getItem("userInfo");

    saveTransferToWard(
      props.emergencyId.EmergencyPatientId,
      data.remark,
      props.menuId,
      props.privilege,
    )
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.populateTable();
        props.handleClose();
        reset();
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <fieldset className="border border-gray-400 w-full text-left px-2 rounded">
              <legend className="font-semibold text-sm text-gray-800 ml-2 lg:ml-1">
                Patient Transfer Remark
              </legend>
              <div className="grid gap-4 w-full py-2">
                <div>
                  
                  <DropdownField
                    control={control}
                    error={errors.unit}
                    name="transferreason"
                    dataArray={unit}
                    placeholder="Select Transfer Reason"
                    isMulti={false}
                    isSearchable={false}
                  />
                </div>
                <div>
                  
                  <TextField
                    id="outlined-multiline-static"
                    name="remark"
                    {...register("remark")}
                    label="Remark "
                    placeholder="Remark"
                    value={newHeader}
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={1}
                    fullWidth
                    onChange={(e) => setNewHeader()}
                  />
                </div>
              </div>
              <div className="grid justify-end pb-2">
                {/* <SaveButton /> */}
                <CommonButton
                  type="submit"
                  label="Save"
                  className="bg-customGreen text-white"                />
              </div>
            </fieldset>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
