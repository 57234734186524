import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//search autocomplet Dropdown API
export const fetchSearchDropDown = (patientInfo, isEmergency) => {
  
  return apiClient.get(
    `admission/admissionAutoComplete/${patientInfo}/${isEmergency}`,
    {
      headers: authHeader(),
    }
  );
};

//search info against patient

export const fetchPatchData = (patientId, isEmergency) => {
  // 
  // return apiClient.get(`/admission/admission/${emergencyId}`);
  return apiClient.get(`/admission/admission/${patientId}/${isEmergency}`, {
    headers: authHeader(),
  });
};

export const fetchPatchDataNew = (patientId, isEmergency,isEdit) => {
  return apiClient.get(`/admission/admissionNew/${patientId}/${isEmergency}/${isEdit}`, {
    headers: authHeader(),
  });
};

// // fetchMlcType -- mlc modal
export const fetchMlcType = () => {
  return apiClient.get(`/patientEmergencyInfo/mlcTypes`, {
    headers: authHeader(),
  });
};

// receive Patient On Bed
export const fetchReceivePatientOnBed = (selectedObj) => {
  
  return apiClient.post(`/admission/receivePatientOnBed`, selectedObj, {
    headers: authHeader(),
  });
};

// Blood Group
export const fetchBloodGroup = () => {
  //
  return apiClient.get(`/misc/bloodGroup`, {
    headers: authHeader(),
  });
};

//prefferdClass
export const fetchPrefferdClass = () => {
  return apiClient.get(`/misc/prefferdClass`, {
    headers: authHeader(),
  });
};

//admissionTag
export const fetchAdmissionTag = () => {
  return apiClient.get(`/misc/admissionTag`, {
    headers: authHeader(),
  });
};

// referenceDoctor //
export const fetchReferEntity = () => {
  //
  return apiClient.get(`/misc/referralEntity`, {
    headers: authHeader(),
  });
};

// referenceDoctor //
export const fetchInternalReferanceDoctor = (refDocSearchString) => {
  //
  return apiClient.get(`/misc/referenceDoctor/${refDocSearchString}`, {
    headers: authHeader(),
  });
};

// doctor1 //
export const fetchDoctor1 = (doctorSearchString1) => {
  // 
  return apiClient.get(`/misc/doctor/${doctorSearchString1}`, {
    headers: authHeader(),
  });
};

// doctor2 //
export const fetchDoctor2 = (doctorSearchString2) => {
  // 
  return apiClient.get(`/misc/doctor/${doctorSearchString2}`, {
    headers: authHeader(),
  });
};

/// relation with patient /////////
export const fetchRelationWithPatient = () => {
  return apiClient.get(`/misc/relation`, {
    headers: authHeader(),
  });
};

//document type
export const fetchDcumentType = () => {
  return apiClient.get(`/misc/documents`, {
    headers: authHeader(),
  });
};

//Old Documents
export const fetchDocuments = (admissionId) => {
  return apiClient.get(`/admission/fetchDocuments/${admissionId}`, {
    headers: authHeader(),
  });
};

//emergency Number
export const fetchEmergencyNumber = () => {
  return apiClient.get(`/admission/emergencyNumber`, {
    headers: authHeader(),
  });
};

// //emergency Number
// export const fetchBarcode = (mrno) => {
//   return apiClient.get(`/getBarcodeFromMessage/${mrno}`);
// };

//post object service
export const saveAdmissionFormData = (finalObject) => {
  
  return apiClient.post(`/admission`, finalObject, {
    headers: authHeader(),
  });
};

//fetch Admission List
export const fetchAdmissionList = (searchStringParams) => {
 
  return apiClient.post(`/admission/admissionPatientList`, searchStringParams, {
    headers: authHeader(),
  });
};

//Cancel Admissions
export const fetchCancelAdmissionStatus = (admissionId, menuId, privilege) => {
  return apiClient.get(
    `/admission/cancelAdmission/${admissionId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

//update Mediclaim Amount
export const updateMediclaimAmount = (finalObj) => {
  return apiClient.post(`/admission/updateMediclaimAmount`, finalObj, {
    headers: authHeader(),
  });
};

//update Mediclaim Amount
export const fetchAdmissionPatientListAutoComplete = (autocompleteObj) => {
  return apiClient.post(`/admission/admissionPatientListSearch`, autocompleteObj, {
    headers: authHeader(),
  });
};

//update Mediclaim Amount
export const deleteAdmissionDocument = (admissionId,documentId,patientRelative) => {
  return apiClient.get(`/admission/deleteAdmissionDocument/${admissionId}/${documentId}/${patientRelative}`, {
    headers: authHeader(),
  });
};

//prints
export const fetchPrintPatientList = (obj) => {
  return apiClient.post(`/reports/patientList`, obj, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchPrintAdmissionForm = (admissionId) => {
  return apiClient.get(`/reports/getAdmissionForm/${admissionId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchPharmacyOTMedicine= (admissionId) => {
  return apiClient.get(`/reports/PharmacyOTMedicine/${admissionId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchAdmissionBarcode= (admissionId) => {
  return apiClient.get(`/barcode/admissionBarcode?admissionId=${admissionId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchAdmissionSticker= (admissionId) => {
  return apiClient.get(`/barcode/admissionSticker?admissionId=${admissionId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const sendToRadar= (sendToRadarObj) => {
  return apiClient.post(`/admission/sendToRadar`,sendToRadarObj, {
    headers: authHeader(),
  });
};

///// MLC-INJURY certificate

export const saveMlcInjuryCertificate= (mlcInjuryCertificateObj) => {
  return apiClient.post(`/admission/saveMlcInjuryCertificate`,mlcInjuryCertificateObj, {
    headers: authHeader(),
  });
};

export const fetchMlcInjuryCertificates= (certificateListObj) => {
  return apiClient.post(`/admission/getMlcInjuryCertificates`,certificateListObj, {
    headers: authHeader(),
  });
};

export const fetchPatientInfoMlcDetails= (admissionId) => {
  return apiClient.get(`/admission/getPatientInfoMlcDetails/${admissionId}`, {
    headers: authHeader(),
  });
};

// Injury Certificate print
export const fetchMlcInjuryCertificatePrint = (certificateId) => {
  return apiClient.get(`/reports/getMlcInjuryCertificate/${certificateId}`,{
     headers: authHeader(),
  });
};


