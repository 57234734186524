import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

// getting Ward against floor //////
export const getWard = (floorId) => {
  return apiClient.get(`/misc/wardFromFloor/${floorId}`, {
    headers: authHeader(),
  });
};

//GET Service API For Pain Assessment Table
export const getAllPainAssessment = (admissionId) => {
  return apiClient.get(
    `clinicalCareChart/assessmentList/${admissionId}`,

    {
      headers: authHeader(),
    }
  );
};

//GET Service API For Pain Assessment Table
export const getAllDailyWeight = (admissionId) => {
  return apiClient.get(
    `clinicalCareChart/dailyWeight/${admissionId}`,

    {
      headers: authHeader(),
    }
  );
};

//Save Pain Assessment
export const addNewPatientPainScore = (createPatientAssessment) => {
  return apiClient.post(
    `clinicalCareChart/savePainAssessment`,
    createPatientAssessment,

    {
      headers: authHeader(),
    }
  );
};

//Save Weight
export const addNewWeight = (createWeight) => {
  return apiClient.post(
    `clinicalCareChart/saveDailyWeight`,
    createWeight,

    {
      headers: authHeader(),
    }
  );
};

export const deletePainAssessment = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deletePainAssessment/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const PainAssessmentFilterPrint = (postObj) => {
  return apiClient.post(`/reports/nursing/cccPainAssessment`, postObj, {
    headers: authHeader(),
  });
};

export const PainAssessmentRowClickPrint = (postObj) => {
  return apiClient.post(`/reports/nursing/cccPainAssessment`, postObj, {
    headers: authHeader(),
  });
};
