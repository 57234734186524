import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerField from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  getPatientQuestionWiseReport,
  getTemplatesForPTQuestionWiseReport,
} from "../../services/feedbackservices/FeedbackServices";
import PatientQuestionWiseReportTable from "./PatientQuestionWiseReportTable";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import {
  fetchRegistrationSearchDropDown,
  fetchSearchedregistrationData,
} from "../../../opd/services/registrationServices/PatientRegistrationServices_17082023_vikas";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";

function PatientQuestionWiseReport() {
  const defaultValues = {
    searchByPatientName: null,
    searchByTemplateName: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  // local variables
  let searchByPatientNameValue = watch("searchByPatientName");
  let searchByTemplateNameValue = watch("searchByTemplateName");

  let fromDateValue = watch("fromDate");
  let toDateValue = watch("toDate");
  // state variables
  const [qusetionWiseReportData, setQusetionWiseReportData] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [searchPatientOptions, setSearchPatientOptions] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();

  const [listingObject, setListingObject] = useState({
    patientId: patientInfo !== null ? patientInfo.id : null,
    templateId: searchByTemplateNameValue?.id,
    mrNo: null,
    fromDate:
      fromDateValue !== null
        ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        : null,
    page: 0,
    size: null,
    toDate:
      toDateValue !== null
        ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        : null,
  });

  // get template reports list
  const populateTable = (forPagination) => {
    const obj = {
      fromDate:
        fromDateValue !== null
          ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
      page: 0,
      size: null,
      toDate:
        toDateValue !== null
          ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
      patientId: patientInfo !== null ? patientInfo.id : null,
      templateId: searchByTemplateNameValue?.id,
      mrNo: null,
    };

    getPatientQuestionWiseReport(obj)
      .then((response) => response.data)
      .then((res) => {
        if (res.result !== undefined) {
          try {
            function safeJSONParse(str) {
              try {
                return JSON.parse(str);
              } catch (e) {
                console.error("Parsing error:", e);
                return null;
              }
            }

            function extractJSONObjects(str) {
              const objects = [];
              let braceCount = 0;
              let startIndex = -1;

              for (let i = 0; i < str.length; i++) {
                if (str[i] === "{") {
                  if (braceCount === 0) startIndex = i;
                  braceCount++;
                } else if (str[i] === "}") {
                  braceCount--;
                  if (braceCount === 0 && startIndex !== -1) {
                    objects.push(str.substring(startIndex, i + 1));
                    startIndex = -1;
                  }
                }
              }

              return objects;
            }
            const combinedString = res.result.join("");

            const jsonObjects = extractJSONObjects(combinedString);

            const parsedData = jsonObjects
              .map(safeJSONParse)
              .filter((item) => item !== null);

            const feedbackArray = parsedData.map((item) => ({
              srno: item.srno,
              createdDate: item.createdDate,
              patientId: item.patientId,
              mrNo: item.mrNo,
              patientName: item.patientName,
              templateAvgRating: item.templateAvgRating,
              feedback: Array.isArray(item.feedback)
                ? item.feedback.map((fb) => ({
                    question: fb.question,
                    rating: fb.rating,
                  }))
                : [],
            }));
            setQusetionWiseReportData(feedbackArray || []);
            setCount(res.count);
          } catch (error) {
            setQusetionWiseReportData([]);
            setCount();
          }
        } else {
          setQusetionWiseReportData([]);
          setCount();
        }
        setPage(0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useMemo(() => {
    populateTable();
  }, [
    listingObject.fromDate,
    listingObject.toDate,
    listingObject.patientId,
    listingObject.templateId,
  ]);

  useEffect(() => {
    if (searchByPatientNameValue !== null) {
      fetchSearchedregistrationData(searchByPatientNameValue?.id, 0)
        .then((response) => response.data)
        .then((res) => {
          setPatientInfo(res.result);
        })
        .catch((error) => error);
    } else {
      setPatientInfo(null);
    }
  }, [searchByPatientNameValue]);
  console.log("qusetionWiseReportData", qusetionWiseReportData);

  return (
    <>
      <div className="2xl:flex 2xl:space-x-3 items-center w-full">
        <h1 className="text-customBlue my-2 font-semibold whitespace-nowrap text-base tracking-wide">
          Patient Question Wise Reports
        </h1>
        <div className="grid grid-cols-3  xl:grid-cols-5 gap-2">
          <div>
            <SearchDropdown
              control={control}
              name="searchByPatientName"
              placeholder="Search By Patient Name/Mr No."
              isClearable={true}
              searchIcon={true}
              dataArray={searchPatientOptions}
              handleInputChange={(e) => {
                if (UseCheckConsecutiveCharacters(e) !== "") {
                  fetchRegistrationSearchDropDown(e, 0)
                    .then((response) => response.data)
                    .then((res) => {
                      setSearchPatientOptions(res.result);
                    })
                    .catch((error) => error);
                } else {
                  setSearchPatientOptions([]);
                }
              }}
            />
          </div>
          <div>
            <SearchDropdown
              control={control}
              name="searchByTemplateName"
              placeholder="Search By Template Name"
              error={errors.searchByPatientNameValue}
              isClearable={true}
              searchIcon={true}
              dataArray={templateOptions}
              handleInputChange={(e) => {
                if (UseCheckConsecutiveCharacters(e) !== "" && e !== "") {
                  getTemplatesForPTQuestionWiseReport(e)
                    .then((response) => response.data)
                    .then((res) => {
                      setTemplateOptions(res.result);
                    })
                    .catch((error) => error);
                } else {
                  setTemplateOptions([]);
                }
              }}
            />
          </div>

          <div>
            <DatePickerField
              name="fromDate"
              control={control}
              label="From Date"
              inputFormat="dd-MM-yyyy"
              disableFuture={true}
            />
          </div>
          <div>
            <DatePickerField
              name="toDate"
              control={control}
              label="To Date"
              inputFormat="dd-MM-yyyy"
              disableFuture={true}
            />
          </div>
          <div className="flex space-x-3 items-center">
            <CommonButton
              type="button"
              label="Reset"
              className="border border-customRed text-customRed"
              onClick={() => {
                reset(defaultValues);
                setQusetionWiseReportData([]);
                setPatientInfo(null);
              }}
            />

            <CommonButton
              searchIcon={true}
              type="button"
              className="bg-customBlue text-white h-9"
              onClick={() => {
                if (searchByTemplateNameValue === null) {
                  errorAlert("Please Select Template Name First!");
                } else {
                  clearErrors("searchByTemplateName");
                  setListingObject((listingObject) => ({
                    ...listingObject,
                    patientId: patientInfo !== null ? patientInfo.id : null,
                    templateId: searchByTemplateNameValue?.id,
                    mrNo: null,
                    fromDate:
                      fromDateValue !== null
                        ? format(
                            new Date(fromDateValue),
                            "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
                          )
                        : null,
                    page: 0,
                    size: null,
                    toDate:
                      toDateValue !== null
                        ? format(
                            new Date(toDateValue),
                            "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
                          )
                        : null,
                  }));
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        {qusetionWiseReportData?.length > 0 ? (
          <div className="mt-2">
            <PatientQuestionWiseReportTable
              populateTable={populateTable}
              dataResult={qusetionWiseReportData}
              count={count}
              highlightRow={false}
              removeHeaders={[
                "Id",
                "Sr No",
                "Admission Id",
                "Patient Id",
                "Bed Id",
              ]}
              tableClass={"h-[370px] 2xl:h-[650px]"}
              fromDateValue={fromDateValue}
              toDateValue={toDateValue}
              patientInfo={patientInfo}
              searchByTemplateNameValue={searchByTemplateNameValue}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <h3 className="flex justify-center my-28 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        )}
      </div>
    </>
  );
}

export default PatientQuestionWiseReport;

