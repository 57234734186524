import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

// form post object
export const postEtuCaseSheetData = (finaleObject) => {
  
 
  return apiClient.post(`/etuCaseSheet/saveEtuCaseSheet`, finaleObject, {
    headers: authHeader(),
  });
};

//autocomplet search
export const fetchEtuCasePatientSearch = (searchString) => {
  return apiClient.get(`/etuCaseSheet/patientAutoComplete/${searchString}`, {
    headers: authHeader(),
  });
};

// patientInfo
export const fetchEtuCasePatientInfo = (emergencyPtId) => {
  return apiClient.get(`/etuCaseSheet/patientInfo/${emergencyPtId}`, {
    headers: authHeader(),
  });
};

//patient data to populate
export const fetchEtuCaseSheetData = (emergencyPatientId,admissionId) => {
  return apiClient.get(`/etuCaseSheet/etuCaseSheetDtls/${emergencyPatientId}/${admissionId}`, {
    headers: authHeader(),
  });
};

//patient data to populate
export const fetchMlcAndMedications = (ptId) => {
  return apiClient.get(`/etuCaseSheet/etuCaseSheetMedications/${ptId}`, {
    headers: authHeader(),
  });
};

export const fetchEtuCaseSheetPrint = (
  admissionIdForPrint,
  emergencyIdForPrint
) => {
  return apiClient.get(
    `/reports/getETUCaseSheet/${
      admissionIdForPrint !== null ? admissionIdForPrint : 0
    }/${emergencyIdForPrint}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};
