import React from "react";
import { useFormContext } from "react-hook-form";
import InputArea from "../../../common/components/FormFields/InputArea";
import "../../../input.css";

const DetailsByRmo = () => {
  const { control } = useFormContext();
  return (
    <>
      <div className="gap-3 grid md:grid-cols-2 lg:grid-cols-4 mt-3">
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="otherNoteDetails"
            label="Other Note Details"
            placeholder="Other Note Details"
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="dietetics"
            label="Dietetics"
            placeholder="Dietetics"
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="comment"
            label="Comment/Other Reference "
            placeholder="Comment/Other Reference "
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="pendingInvestigations"
            label="Pending Investigations"
            placeholder="Pending Investigations"
          />
        </div>
      </div>
    </>
  );
};

export default DetailsByRmo;

