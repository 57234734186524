import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//GET Charges Listing
export const getAllCharges = (opdIpd, admissionId) => {
  return apiClient.get(
    `clinicalCareChart/chargesList/${opdIpd}/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

// search Services of Charges
export const autoSearchService = (searchService) => {
  return apiClient.post(`/services/search`, searchService, {
    headers: authHeader(),
  });
};

//Save Charges
export const addNewCharges = async (createCharges, menuId, privilege) => {
  return await apiClient.post(
    `/clinicalCareChart/saveCharges/${menuId}/${privilege}`,
    createCharges,
    {
      headers: authHeader(),
    }
  );
};
