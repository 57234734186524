import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

//get list of ipd credit bills
export const getListOfIPDCreditBills = (creditBillObj) => {
  return apiClient.post(`/billSettlement/listOfCreditBill`, creditBillObj, {
    headers: authHeader(),
  });
};

export const getListOfIPDSelfPaymentSettlement = (listOfPyamentObj) => {
  return apiClient.post(`/billSettlement/listOfPayments`, listOfPyamentObj, {
    headers: authHeader(),
  });
};

//   self settlement information by id
export const getSelftSettlementInformationById = (patientId, opdIpd) => {
  return apiClient.get(
    `/patientInfo/selfPaymentSettlement/${patientId}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

// /api/billSettlement/saveBillSettlementSelf
export const saveSelfBillSettlement = (postObj) => {
  return apiClient.post(`/billSettlement/saveBillSettlementSelf`, postObj, {
    headers: authHeader(),
  });
};

// /api/companySettlement/patientAdvanceBalance/{patientId}
export const patientAdvanceBalance = (patientId) => {
  return apiClient.get(
    `/companySettlement/patientAdvanceBalance/${patientId}`,
    {
      headers: authHeader(),
    }
  );
};
