//style for model
export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // overflowY:"scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
  outline: "none",
  padding: 2,
  borderRadius: 2,

};
