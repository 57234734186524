import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { saveOfficePermission } from "../../services/officePermissionServices/OfficePermissionsServices";

export default function ActionsConfirmationModal(props) {
  const { handleClose, privilege, selectedRow, menuId, populateTable } = props;
  //

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const handleConfirmStatusChange = () => {
    setOpenBackdrop(true);

    const finalObj = {
      admissionId: selectedRow?.AdmissionID,
      patientName: selectedRow?.["Patient Name"],
      //
      menuId,
      privilege,
      officeDetailID: selectedRow?.Office_Detail_ID ?? 0,
      officeID: selectedRow?.ID,
      remark: selectedRow?.Remark || "",
      requestFlag: 0,
      status:
        privilege === "Delete"
          ? 4
          : privilege === "Reject"
          ? 3
          : privilege === "Hold"
          ? 2
          : privilege === "Accept"
          ? 1
          : 0,
    };

    saveOfficePermission(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Office Permissions
            </div>

            <hr className="border mx-1  border-blue-900" />

            <div>{`Are you sure you want to ${privilege} ?`}</div>

            <div className="gap-3 my-3 flex justify-end">
              <CommonButton
                label="Cancel"
                className="border border-customRed text-customRed"
                onClick={() => handleClose()}
              />

              <CommonButton
                label="Proceed"
                className="bg-customGreen text-white"
                onClick={() => {
                  handleConfirmStatusChange();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
}
