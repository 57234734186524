import {
  Grid,
  Modal,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import NoActionsTable from "../../../../common/components/CommonTable/NoActionsTable";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  getCompanyOptions,
  getSettledCompanyBills,
} from "./PharmacyComapanyServices";

function SettlementModal(props) {
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [settledBillsData, setSettledBillsData] = React.useState("");

  const defaultValues = {
    companyOptions: null,
  };

  const { watch, control, getValues, setValue } = useForm({ defaultValues });

  const fnGetCompanyOptions = () => {
    getCompanyOptions()
      .then((response) => response.data)
      .then((res) => {
        setCompanyOptions(res.result);
      });
  };

  let comapnyWatch = watch("companyOptions");

  const getSettledList = () => {
    let searchObj = {
      companyId: comapnyWatch?.id,
      fromDate: selectedFromDate,
      opdIpdExternalId: 0,
      toDate: selectedToDate,
    };
    getSettledCompanyBills(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setSettledBillsData(res);
      });
  };

  React.useEffect(() => {
    if (props.open) {
      fnGetCompanyOptions();
    }
  }, [props.open]);

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            height: "70%",
            bgcolor: "background.paper",
            p: 2,

            overflow: "scroll",
          }}
        >
          <div className="my-3">
            <CancelPresentationIconButton
              className="text-red-500 cursor-pointer"
              onClick={() => {
                props.close();
              }}
            />
          </div>
          <div className="text-lg my-2 font-semibold">Settled Bills</div>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={8}>
              <DropdownField
                control={control}
                name="companyOptions"
                label="Company"
                placeholder="Company"
                dataArray={companyOptions}
                menuPlacement="bottom"
              />
            </Grid>
            <Grid item lg={3} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // disablePast
                  label="From Date"
                  value={selectedFromDate}
                  name="fromDate"
                  onChange={(newValue) => {
                    setSelectedFromDate(newValue);
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      className="bg-white"
                      fullWidth
                      name="fromDate"
                      size="small"
                      defaultValue=""
                      {...params}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={3} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // disablePast
                  label="To Date"
                  value={selectedToDate}
                  name="toDate"
                  onChange={(newValue) => {
                    setSelectedToDate(newValue);
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      className="bg-white"
                      fullWidth
                      name="toDate"
                      size="small"
                      defaultValue=""
                      {...params}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={1} sm={2}>
              <CommonButton
                searchIcon={true}
                className=" px-2 rounded-md bg-customBlue text-white"
                onClick={getSettledList}
                type="button"
              />
            </Grid>
            <Grid item lg={1} sm={2}>
              <CommonButton
                label="Reset"
                type="button"
                className="px-2 rounded h-9 border border-customRed text-customRed"
                onClick={() => {
                  setSelectedFromDate(new Date());
                  setSelectedToDate(new Date());
                  setValue("companyOptions", null);
                  setSettledBillsData("");
                }}
              />
            </Grid>
          </Grid>
          <div className="my-4">
            {settledBillsData?.result?.length > 0 ? (
              <NoActionsTable data={settledBillsData} />
            ) : (
              <div className="flex justify-center text-lg text-gray-400 my-10 font-semibold">
                No Records Found..!
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SettlementModal;
