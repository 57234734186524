import apiClient from  "../../../../http-common"
import authHeader from "../../../../authentication/authservices/auth-header"
// /api/stockAdjustment/saveExpiryAdjustment
export const postExpiryAdjustment=(postObj)=>{
    return apiClient.post(`/stockAdjustment/saveExpiryAdjustment`,postObj,{
        headers:authHeader()
      })
}
// /api/reports/inventory/expiryDateAdjustment/{expiryAdjId}
export const printExpiryAdjustmentReceipt=(expiryAdjId)=>{
    return apiClient.get(`/reports/inventory/expiryDateAdjustment/${expiryAdjId}`,{
        headers:authHeader()
      })
}


export const expiryAdjustmentPdfPrint=(mrpAdjustmentId)=>{
  return apiClient.get(`/reports/inventory/expiryDateAdjustment/${mrpAdjustmentId}`,{
    headers:authHeader(),
    responseType:"blob"
  })
}