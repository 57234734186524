import React, { useEffect, useRef } from "react";

import { Checkbox } from "@mui/material";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { GetDivaToolPrint } from "../services/cliinicalChartServices/divatoolservices/DivatoolServices";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";

export default function DivaToolPrint(props) {
  const [dataResult, setDataResult] = React.useState();

  let printRef = useRef(null);

  useEffect(() => {
    GetDivaToolPrint(props?.selectDivaToolViewRow?.Id)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
    // }
  }, [props?.selectDivaToolViewRow?.Id]);

  useEffect(() => {
    dataResult && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
            <div className=" -mt-12">
              <h1 className=" font-semibold text-[15px] ">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[11px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" font-semibold text-[13px]">DivaTool</h1>
            </div>
          </div>
          <div>
            <div className=" border-b-[1px] p-1 border-t-[1px] border-black grid grid-cols-[14%_1%_35%_14%_1%_35%] mt-2">
              <div className=" font-semibold text-[11px] ite">MR No.</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.MRNo}</div>
              <div className=" font-semibold text-[11px] ite">Patient Name</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.PatientName}</div>

              <div className=" font-semibold text-[11px]">Age:</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.AgeYear}</div>

              <div className=" font-semibold text-[11px]">Gender</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.Gender}</div>

              <div className=" font-semibold text-[11px]">Doctor Name</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.DoctorName}</div>
            </div>
          </div>
          <div className=" flex space-x-3 text-[12px] my-1  mx-[2px]">
            <h1 className=" font-semibold">Date of IV Cannulation</h1>
            <hi className="font-semibold">:</hi>
            <h1>{dataResult?.CannulationDate}</h1>
          </div>

          <div className=" grid grid-cols-[60%_15%_25%] text-[12px] font-semibold border-b-[1px] border-t-[1px] border-black">
            <div className=" p-[1px]">Parameters</div>
            <div className=" p-[1px]">Score (Yes)</div>
            <div className=" p-[1px]">Remark</div>
          </div>
        </thead>
        <tbody className="w-full">
          {dataResult?.ParameterDetails.map((data) => {
            return (
              <div className=" my-2 grid grid-cols-[60%_15%_25%] text-[12px]  ">
                <div className=" p-[1px]">{data?.ParameterName}</div>
                <div className=" ">
                  <Checkbox
                    size="small"
                    sx={{ padding: 0 }}
                    className=" items-center"
                    checked={data.IsYes}
                  />
                </div>

                <div className=" p-[1px]">{data?.Remark}</div>
              </div>
            );
          })}
        </tbody>
        <div className=" flex gap-3 font-semibold text-[12px] justify-end mr-3">
          <h1>DIVA Score</h1>
          <h1>:</h1>
          <h1>{dataResult?.DIVAScore}</h1>
        </div>
      </table>
    </div>
  );
}
