import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const patientInfo = (OpdIpd, searchString) => {
  return apiClient.get(
    `patientInfo/opdIpdAutoComplete/${OpdIpd}/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const DrugListing = () => {
  return apiClient.get(`/narcoticDrug/narcoticDrugs`, {
    headers: authHeader(),
  });
};

// FindById API
export const getFrequencyDropdown = (AdmId) => {
  return apiClient.get(`/drugAdministrator/getFrequency/${AdmId}`, {
    headers: authHeader(),
  });
};

//Post Object API
export const postNarcotic = (saveObj) => {
  return apiClient.post(`/narcoticDrug/saveNarcoticDrugForm`, saveObj, {
    headers: authHeader(),
  });
};

export const narcoticDrugFormList = (obj) => {
  return apiClient.post(`/narcoticDrug/narcoticDrugFormList `, obj, {
    headers: authHeader(),
  });
};

// FindById API http://192.168.0.61:5003/api/reports/narcoticDrugFormDetails/74
export const NarcoticDrugPrint = (NarcoticDrugId) => {
  return apiClient.get(`reports/narcoticDrugFormDetails/${NarcoticDrugId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};


export const NarcoticDrugGetById = (NarcoticDrugId) => {
  return apiClient.get(`/narcoticDrug/narcoticDrugFormDetails/${NarcoticDrugId}`, {
    headers: authHeader(),
  });
};