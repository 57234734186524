import React from "react";
import { useFormContext } from "react-hook-form";
import InputArea from "../../../../common/components/FormFields/InputArea";

const GynecologyDetails = () => {
  const { control } = useFormContext();
  return (
    <div className="grid grid-cols-3 gap-3">
      <div className="">
        <InputArea
          name="onExamination"
          control={control}
          label="On Examination"
          placeholder="On Examination"
          
        />
      </div>
      <div className="">
        <InputArea
          name="labourNotes"
          control={control}
          label="Labor Notes"
          placeholder="Labor Notes"
          
        />
      </div>
      <div className="">
        <InputArea
          name="tubalLigation"
          control={control}
          label="Tubal Ligation"
          placeholder="Tubal Ligation"
          
        />
      </div>
      <div className="">
        <InputArea
          name="perSpeculam"
          control={control}
          label="Per Speculum"
          placeholder="Per Speculum"
          
        />
      </div>
      <div className="">
        <InputArea
          name="perVaginalExam"
          control={control}
          label="Per Vaginal Examination"
          placeholder="Per Vaginal Examination"
          
        />
      </div>
      <div className="">
        <InputArea
          name="investigation"
          control={control}
          label="Investigation"
          placeholder="Investigation"
          
        />
      </div>
    </div>
  );
};

export default GynecologyDetails;
