function getInitialNursingAssessmentPtInfo(
  data,
  patchedNursingInfoVal,
  admissionIdVal
) {
 
  let userInfoString = localStorage.getItem("userInfo");

  let userInfoObj = JSON.parse(userInfoString);

  
  let addedByVal = userInfoObj.userId;

  let initialNursingAssPTInfoDto = null;

  if (patchedNursingInfoVal) {
    //dto while sending the update request.
    initialNursingAssPTInfoDto = {
      initialNursingAssId: "##", //get this from the fetchInitialNursingAssessment while updating
      dentures: false,
      contactLenses: false,
      artificialLimbs: false,
      implants: false,
      rylesTube: false,
      gastroJejunomstomy: false,
      centralLine: false,
      arterialLine: false, //this property is not available in the swagger
      tracheotomy: false,
      intubates: false,
      fistula: false,
      foleysCatheter: false,
      disability: "",
      height: 0,
      idBand: false,
      knownAllergies: "",
      weight: 0,
      receivingStaff: "",
      addedBy: 0, //no need to send addedBy in the case of update
      admissionId: 0,
    };
  } else {
    // initialNursingAssPTInfoDto object having a different initialNursingAssPTInfoDto
    initialNursingAssPTInfoDto = {
      initialNursingAssId: 0, //send this as 0 when saving for the first time.
      dentures: false,
      contactLenses: false,
      artificialLimbs: false,
      implants: false,
      rylesTube: false,
      gastroJejunomstomy: false,
      centralLine: false,
      arterialLine: false, //this property is not available in the swagger
      tracheotomy: false,
      intubates: false,
      fistula: false,
      foleysCatheter: false,
      disability: "",
      height: 0,
      idBand: false,
      knownAllergies: "",
      weight: 0,
      receivingStaff: "",
      addedBy: 0, //userId from the localStorage
      admissionId: 0,
    };
  }

  //In the case of updating ; we need to get the initialNursingAssId from the state variable patchedNursingInfo.
  if (patchedNursingInfoVal) {
    initialNursingAssPTInfoDto.initialNursingAssId =
      patchedNursingInfoVal.data.result.ptInfoPatchDto.initialNursingAssId;
  }

  let equipmentObj = {};

  if (data.hasOwnProperty("externalEquipments") === true) {
    if (data.externalEquipments.length > 0) {
      let tempExternalEquipments = data.externalEquipments;

      for (let externalEquipmentString of tempExternalEquipments) {
        equipmentObj[`${externalEquipmentString}`] = true;
      }
    }
  }

  

  if (equipmentObj.hasOwnProperty("Denutures")) {
    initialNursingAssPTInfoDto.dentures = true;
  }

  if (equipmentObj.hasOwnProperty("Contact Lenses")) {
    initialNursingAssPTInfoDto.contactLenses = true;
  }

  if (equipmentObj.hasOwnProperty("Artificial Limbs")) {
    initialNursingAssPTInfoDto.artificialLimbs = true;
  }

  if (equipmentObj.hasOwnProperty("Implants")) {
    initialNursingAssPTInfoDto.implants = true;
  }

  if (equipmentObj.hasOwnProperty("Ryle's Tube")) {
    initialNursingAssPTInfoDto.rylesTube = true;
  }

  if (equipmentObj.hasOwnProperty("Gastro Jejunomstomy")) {
    initialNursingAssPTInfoDto.gastroJejunomstomy = true;
  }

  if (equipmentObj.hasOwnProperty("Central Line")) {
    initialNursingAssPTInfoDto.centralLine = true;
  }

  if (equipmentObj.hasOwnProperty("ArterialLine")) {
    initialNursingAssPTInfoDto.arterialLine = true;
  }

  if (equipmentObj.hasOwnProperty("Tracheotomy")) {
    initialNursingAssPTInfoDto.tracheotomy = true;
  }

  if (equipmentObj.hasOwnProperty("Intubated")) {
    initialNursingAssPTInfoDto.intubates = true;
  }

  if (equipmentObj.hasOwnProperty("Fistula")) {
    initialNursingAssPTInfoDto.fistula = true;
  }

  if (equipmentObj.hasOwnProperty("Foleys Catheter")) {
    initialNursingAssPTInfoDto.foleysCatheter = true;
  }

  if (data.hasOwnProperty("disabilities")) {
    initialNursingAssPTInfoDto.disability = data.disabilities;
  }

  if (data.hasOwnProperty("height")) {
    initialNursingAssPTInfoDto.height = Number(data.height);
  }

  if (data.hasOwnProperty("isBandWithCorrectInformation")) {
    initialNursingAssPTInfoDto.idBand = data.isBandWithCorrectInformation;
  }

  if (data.hasOwnProperty("knownAllergies")) {
    initialNursingAssPTInfoDto.knownAllergies = data.knownAllergies;
  }

  if (data.hasOwnProperty("weightInKgs")) {
    initialNursingAssPTInfoDto.weight = Number(data.weightInKgs);
  }

  if (data.hasOwnProperty("staffName")) {
    initialNursingAssPTInfoDto.receivingStaff = data.staffName;
  }

  if (patchedNursingInfoVal) {
    initialNursingAssPTInfoDto.addedBy = null; //when updating ; send the addedBy key as null
  } else {
    initialNursingAssPTInfoDto.addedBy = addedByVal;
  }

  initialNursingAssPTInfoDto.admissionId = admissionIdVal;

  return initialNursingAssPTInfoDto;
}

export default getInitialNursingAssessmentPtInfo;
