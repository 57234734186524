import React from "react";

export default function LIMSIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.12663 8.91338L7.86562 8.81947C7.66172 8.74611 7.55948 8.54034 7.63472 8.35477L9.45605 3.86283C9.53464 3.669 9.90419 3.6923 10.1172 3.76892L10.3782 3.86283C10.5821 3.93619 10.6843 4.14196 10.6091 4.32753L8.65942 8.6992C8.58083 8.89303 8.3396 8.99 8.12663 8.91338Z"
        fill="#4D5358"
      />
      <path
        d="M6.86126 10.0789L5.57418 9.64704L9.35176 0.207978L10.6548 0.634499L6.86126 10.0789Z"
        fill="#66AFB8"
      />
      <path
        d="M6.34236 13.1864H4.72123C4.61777 13.1864 4.55291 13.2864 4.60658 13.3659C5.92071 15.3139 8.29362 16.2151 10.858 15.6237C12.9715 15.1362 14.1773 13.6332 14.5161 12.9427C16.1675 9.19935 13.2024 6.84663 11.4627 6.22352C11.3914 6.198 11.313 6.23388 11.2899 6.29968L10.956 7.25076C10.938 7.30196 10.9602 7.35795 11.0087 7.38929C12.7643 8.5244 12.8994 10.4036 12.6572 11.4399C12.6026 11.6735 12.4866 11.8912 12.34 12.0901C10.3646 14.7698 7.61914 13.9867 6.42248 13.2097C6.39894 13.1944 6.3714 13.1864 6.34236 13.1864Z"
        fill="#32393F"
      />
      <path
        d="M7.22086 9.32688L5.69291 8.82726C5.55244 8.78132 5.47985 8.64175 5.53067 8.51532L7.9199 2.57186C7.97111 2.44447 8.12798 2.37908 8.26915 2.42629L9.92384 2.97965C10.0652 3.02692 10.1364 3.16889 10.0823 3.29551L7.56638 9.18523C7.51339 9.30927 7.3597 9.37228 7.22086 9.32688Z"
        fill="#252D2F"
      />
      <path
        d="M7.60642 12.5162H3.13548C3.06066 12.5162 3 12.5707 3 12.638V13.1255C3 13.1928 3.06066 13.2473 3.13548 13.2473H7.60642C7.68125 13.2473 7.7419 13.1928 7.7419 13.1255V12.638C7.7419 12.5707 7.68125 12.5162 7.60642 12.5162Z"
        fill="#676B6B"
      />
      <path
        d="M10.4766 15.0234L11.3999 17.1591H8.82577V15.2011C8.82577 15.0495 8.88416 14.8983 9.01636 14.8043C9.59923 14.3899 10.19 14.6521 10.4127 14.9143C10.4406 14.9472 10.4599 14.9849 10.4766 15.0234Z"
        fill="#40484A"
      />
      <path
        d="M13.7032 17.0251H3.94838C3.79873 17.0251 3.67741 17.1342 3.67741 17.2688V17.7563C3.67741 17.8909 3.79873 18 3.94838 18H13.7032C13.8528 18 13.9741 17.8909 13.9741 17.7563V17.2688C13.9741 17.1342 13.8528 17.0251 13.7032 17.0251Z"
        fill="#32393F"
      />
      <path
        d="M5.26554 9.46136C5.29255 9.39859 5.37102 9.36742 5.4408 9.39172L7.08325 9.96375C7.15303 9.98805 7.18769 10.0586 7.16068 10.1214L7.11176 10.235C7.08474 10.2978 7.00627 10.329 6.93649 10.3047L5.29404 9.73264C5.22426 9.70834 5.1896 9.63776 5.21662 9.575L5.26554 9.46136Z"
        fill="#252D2F"
      />
      <path
        d="M9.05906 0.0778937C9.08608 0.0151311 9.16455 -0.0160475 9.23432 0.00825425L10.8768 0.580283C10.9466 0.604585 10.9812 0.675165 10.9542 0.737927L10.9053 0.851569C10.8783 0.914331 10.7998 0.94551 10.73 0.921208L9.08756 0.349179C9.01779 0.324878 8.98312 0.254298 9.01014 0.191535L9.05906 0.0778937Z"
        fill="#252D2F"
      />
      <path
        d="M11.5354 6.36207C11.5354 7.06875 10.8985 7.64163 10.1129 7.64163C9.32719 7.64163 8.69028 7.06875 8.69028 6.36207C8.69028 5.65539 9.32719 5.0825 10.1129 5.0825C10.8985 5.0825 11.5354 5.65539 11.5354 6.36207Z"
        fill="#66AFB8"
      />
      <path
        d="M10.7225 6.36207C10.7225 6.66493 10.4496 6.91045 10.1129 6.91045C9.77614 6.91045 9.50318 6.66493 9.50318 6.36207C9.50318 6.0592 9.77614 5.81368 10.1129 5.81368C10.4496 5.81368 10.7225 6.0592 10.7225 6.36207Z"
        fill="#252D2F"
      />
      <path
        d="M10.0451 15.5018C10.0451 15.6701 9.89347 15.8065 9.70641 15.8065C9.51934 15.8065 9.3677 15.6701 9.3677 15.5018C9.3677 15.3335 9.51934 15.1971 9.70641 15.1971C9.89347 15.1971 10.0451 15.3335 10.0451 15.5018Z"
        fill="#66AFB8"
      />
    </svg>
  );
}
