import * as yup from "yup";

export const personalValidationSchema = yup.object().shape({
  mobileNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Provide Valid Mobile No.")
    .min(10, "Provide Valid Mobile No.")
    .max(10, "Provide Valid Mobile No.")
    .required("Provide Valid Mobile No."),

  email: yup
    .string()
    .email("Invalid email format")
    .when("noEmail", {
      is: false,
      then: yup.string().required("Email is required"),
      otherwise: yup.string().notRequired(),
    }),
  prefix: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Prefix"),
      label: yup.string().required("Please Select Prefix"),
    })
    .required("Required"),

  firstName: yup
    .string()
    .nullable()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
      "Please Give Last Name in Proper Format"
    )
    .required("Required")
    .min(1),

  lastName: yup
    .string()
    .nullable()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
      "Please Give Last Name in Proper Format"
    )
    .required("Required")
    .min(1),

  dobDate: yup.date().min("01/01/1902", "You are Not Eligible").required(),

  age: yup
    .string()
    .matches(/^[0-9]+$/, "Not Valid")
    .min(1, "Not Valid")
    .max(3, "Not Valid")
    .required("Required"),

  ///gender//
  gender: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().notRequired("Please Select gender"),
      label: yup.string().notRequired("Please Select gender"),
    })
    .required("Required"),

  adharNumber: yup.string().test((val) => {
    if (val === "0") return val.toString().length === 1;
    if (val !== "0") return val.toString().length === 12;
  }),

  /////////   Address Information /////////
  houseFlatNumber: yup.string().nullable().required("Required"),

  society: yup.string().nullable().required("Required"),

  nearestLandmark: yup.string().nullable().required("Required"),

  ///country
  country: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Country"),
      label: yup.string().required("Please Select Country"),
    })
    .required("Required"),

  ///state
  state: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select State"),
      label: yup.string().required("Please Select State"),
    })
    .required("Required"),

  ///district
  district: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select District"),
      label: yup.string().required("Please Select District"),
    })
    .required("Required"),

  ///city
  city: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select City"),
      label: yup.string().required("Please Select City"),
    })
    .required("Required"),

  ///area
  area: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Area"),
      label: yup.string().required("Please Select Area"),
    })
    .required("Required"),

  /////////////Representative Info/////////////

  nameOfRepresentative: yup.string().nullable().notRequired(),

  mobileNumberOfRepresentative: yup
    .string()
    .nullable()
    .when("nameOfRepresentative", (nameOfRepresentative) => {
      if (nameOfRepresentative !== null) {
        return yup
          .string()
          .nullable()
          .matches(/^$|^[0-9X]{10}$/, "Please Provide Valid Mobile No.")
          .max(10, "Please Provide Valid Mobile No.")
          .notRequired();
      }
    })
    .notRequired(),

  representativeAddress: yup
    .string()
    .nullable()
    .when("nameOfRepresentative", (nameOfRepresentative) => {
      if (nameOfRepresentative !== null) {
        return yup.string().notRequired("Please Provide Address");
      }
    }),

  ///////// patient office details ////////

  //occupation
  occupation: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .notRequired(),

  //Remark for Account
  remarkOfAccount: yup.string().nullable().notRequired(),

  remarkOfBill: yup.string().nullable().notRequired(),
});

export const admissionValidationSchema = yup.object().shape({
  department: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Department"),
      label: yup.string().required("Please Select Department"),
    })
    .required("Required"),

  doctor: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Department"),
      label: yup.string().required("Please Select Department"),
    })
    .required("Required"),

  patientSource: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Source"),
      label: yup.string().required("Please Select Source"),
    })
    .required("Required"),

  patientcategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  tariff: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Camp"),
      label: yup.string().required("Please Select Camp"),
    })
    .required("Required"),

  //Company Details
  company: yup
    .object()
    .nullable()
    .shape({
      value: yup.object().nullable().required("Required"),
      label: yup.object().nullable().required("Required"),
    })

    .when("patientcategory", (patientcategory) => {
      if (patientcategory === null) {
        return yup
          .object()
          .nullable()
          .shape({
            value: yup.string().notRequired(),
            label: yup.string().notRequired(),
          })
          .required("Required");
      } else if (patientcategory !== null) {
        if (typeof patientcategory.label !== "undefined") {
          if (patientcategory.value === "Self") {
            return yup
              .object()
              .nullable()
              .shape({
                value: yup.string().notRequired(),
                label: yup.string().notRequired(),
              })
              .notRequired();
          } else if (
            patientcategory.value !== "Self" &&
            patientcategory.value !== "International" &&
            patientcategory.value !== "Ipf"
          ) {
            return yup
              .object()
              .nullable()
              .shape({
                value: yup.string().notRequired(),
                label: yup.string().notRequired(),
              })
              .required("Required");
          }
        }
      }
    }),
  //new ppnCompany

  ppnCompany: yup.object().nullable().when("isPpn", {
    is: true,
    then: yup
      .object()
      .shape({
        value: yup.string().nullable().required("Value is required"), // Assuming 'value' is a string
        label: yup.string().nullable().required("Label is required"), // Assuming 'label' is a string
      })
      .required("ppnCompany is required"),
  }),
  
  

  assistantCompany: yup
    .object()
    .nullable()
    .shape({
      value: yup.object().nullable().required("Required"),
      label: yup.object().nullable().required("Required"),
    })

    .when("patientcategory", (patientcategory) => {
      if (patientcategory === null) {
        return yup
          .object()
          .nullable()
          .shape({
            value: yup.string().notRequired(),
            label: yup.string().notRequired(),
          })
          .required("Required");
      } else if (patientcategory !== null) {
        if (typeof patientcategory.label !== "undefined") {
          if (patientcategory.value === "Self") {
            return yup
              .object()
              .nullable()
              .shape({
                value: yup.string().notRequired(),
                label: yup.string().notRequired(),
              })
              .notRequired();
          } else if (
            patientcategory.value !== "Self" &&
            patientcategory.value !== "International" &&
            patientcategory.value !== "Ipf"
          ) {
            return yup
              .object()
              .nullable()
              .shape({
                value: yup.string().notRequired(),
                label: yup.string().notRequired(),
              })
              .required("Required");
          }
        }
      }
    }),

  // assistantCompany: yup
  //   .object()
  //   .nullable()
  //   .shape({
  //     value: yup.string().required("Please Select Camp"),
  //     label: yup.string().required("Please Select Camp"),
  //   })
  //   .notRequired(),

  staffName: yup
    .object()
    .nullable()
    .when("patientcategory", (patientcategory) => {
      if (patientcategory !== null) {
        if (typeof patientcategory.label !== "undefined") {
          if (
            patientcategory.value === "Staff" ||
            patientcategory.value === "Staff Dependant"
          ) {
            return yup
              .object()
              .nullable()
              .shape({
                value: yup.string().required("Please Select Staff Name"),
                label: yup.string().required("Please Select Staff Name"),
              })
              .required("Required");
          }
        }
      }
    }),

  //Representative Details
  referanceDoctor: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .notRequired(),

  ////////////////// Bed Details /////////////////

  admissionType: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  BedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  ChargesPerBedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  ward: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  bedNumber: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .notRequired(),
});
