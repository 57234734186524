import { Box, Modal, TextField } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { successAlert } from "../../../common/components/Toasts/CustomToasts";
import { reasonForDischarge } from "../../services/discharge/ReasonForDischargeService";

import OkButton from "../../../common/components/Buttons/OkButton";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",

  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};

export default function ReasonForDischarge(props) {
  const schema = yup.object().shape({});

  const defaultValues = {};
  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  let navigate = useNavigate();

  function onSubmitDataHandler(data) {
    let reasonData = data.reasonForDischargeCancel;

    //reason for discharge api
    reasonForDischarge(props.selectedPatient.AdmissionId, reasonData)
      .then((response) => response.data)
      .then((res) => {
        // if (props.selectedPatient.isBilled === 0) {

        successAlert(res.message);
        props.setOpenDischargeModal(false);
        props.populateTable();
        // } else {
        //   errorAlert("Final Bill already Generated For Selected Patient");
        // }
      });
  }
  return (
    <>
      <div className="mt-20">
        <Modal
          open={props.openDischargeModal}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full py-2">
              <CancelPresentationIconButton
                onClick={() => {
                  props.setOpenDischargeModal(false);
                }}
              />
            </div>
            <div className="">Discharge Cancel</div>
            <form onSubmit={handleSubmit(onSubmitDataHandler)}>
              <div className=" ">
                <div className=" py-2 w-full">
                  <TextField
                    name="reasonForDischargeCancel"
                    id="outlined-multiline-static"
                    label="Reason For Discharge Cancel"
                    multiline
                    fullWidth
                    {...register("reasonForDischargeCancel")}
                  />
                </div>
                <div className="flex gap-2 justify-end">
                  <OkButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white"
                  />
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}
