import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//Blood ReqAcceptance List
export const fetchBloodIssueNoteList = (tableObj) => {
  return apiClient.post(`/bloodBank/bloodIssueNoteList`, tableObj, {
    headers: authHeader(),
  });
};

//autoComplete
export const fetchBloodIssueNoteListSearch = (searchObj) => {
  return apiClient.post(
    `/bloodBank/bloodIssueNoteListSearch
  `,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};

//save
export const saveBloodIssueNote = (finalObj) => {
  return apiClient.post(`/bloodBank/saveBloodIssueNote`, finalObj, {
    headers: authHeader(),
  });
};


export const BloodIssuePrintData = (admissionId , issueId) => {
  return apiClient.get(`/bloodBank/bloodIssue/${admissionId}/${issueId}`, {
   headers: authHeader(),
  });
};


// admId , issueId 1060567/68919