import { Box, Modal } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
//Field
import { Checkbox } from "@mui/material";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
//Button
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
//Services
import { searchSupplier } from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import { getQuotationEnqItemDetailsList } from "../../../services/purchase/QuotationServies";
//Alert
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import {
  errorAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";

export default function EnquiryListModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const defaultValues = {
    searchBySupNameEnquiry: "",
    supplier: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    control,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [supplier, setSupplier] = React.useState([]);
  const [enquiryQId, setEnquiryQId] = React.useState(null);
  const [rowIndex, setRowIndex] = React.useState("");
  const [checkBoxIndex, setCheckBoxIndex] = React.useState(-1);
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");

  if (fromDate) {
    props.setSelectedEnquiryListFromDate(fromDate);
  }
  if (toDate) {
    props.setSelectedEnquiryListToDate(toDate);
  }

  const filterData = () => {
    props.populateTable();
  };

  //Searchable Supplier Api
  const handleSupplierChange = (autoSearchSupplier) => {
    if (autoSearchSupplier !== "") {
      searchSupplier(autoSearchSupplier)
        .then((response) => response.data)
        .then((res) => {
          setSupplier(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  //Single Select
  const handleChange = (e, row, requiredIndex) => {
    setCheckBoxIndex(requiredIndex);
    setRowIndex(requiredIndex);
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <Checkbox
          size="small"
          className="w-4 h-4 border border-gray-600 rounded"
          type="checkbox"
          color="primary"
          checked={checkBoxIndex === index ? true : false}
          value={index}
          onChange={(e) => {
            handleChange(e, row, index);
            setEnquiryQId(row.QId);
            props.setEnquiryItemRow(row);
          }}
          inputProps={{
            "aria-label": "primary checkbox",
          }}
          name={`indentCheckBox${index}`}
        />
      </div>
    );
  };

  return (
    <>
      <Modal
        open={props.postEnquiryListModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[80%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleEnquiryListClose();
              reset(defaultValues);
              props.setSelectedEnquiryListFromDate(new Date());
              props.setSelectedEnquiryListToDate(new Date());
              props.setDataResultEnquiryList({ result: [] });
              props.setDataResultEnquiryList([]);
              setRowIndex("");
            }}
          />
          <div className="row px-4">
            <div className="px-2 ml-2 lg:ml-0 font-bold text-gray-800">
              Enquiry List
            </div>

            <div className="grid grid-cols-4 pt-2 gap-3">
              <InputField
                name="searchBySupNameEnquiry"
                label="Search By Enquiry No"
                error={errors.searchBySupNameEnquiry}
                control={control}
                inputRef={{
                  ...register("searchBySupNameEnquiry", {
                    onChange: (e) => {
                      props.setEnquirySearchString(e.target.value);
                    },
                  }),
                }}
              />

              <SearchDropdown
                control={control}
                name="supplier"
                placeholder="Supplier"
                error={errors.supplier}
                searchIcon={true}
                isSearchable={true}
                isClearable={true}
                dataArray={supplier}
                isMulti={false}
                handleInputChange={handleSupplierChange}
                inputRef={{
                  ...register("supplier", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        props.setEnquirySupplierId(e.target.value.id);
                      } else if (e.target.value === null) {
                        props.setEnquirySupplierId(0);
                      }
                    },
                  }),
                }}
              />

              <div className="flex gap-3">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  // onChange={(newValue) => {
                  //   setValue("fromDate", newValue);
                  // }}
                  disablePast={false}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />

                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  // onChange={(newValue) => {
                  //   setValue("toDate", newValue);
                  // }}
                  disablePast={false}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div className="">
                <CommonButton
                  type="button"
                  searchIcon={true}
                  onClick={filterData}
                  className="bg-customBlue text-white"
                />
              </div>
            </div>
 
            {props.dataResultEnquiryList.length > 0 ? (
              <>
                <CommonDynamicTablePaginationNew
                  dataResult={props.dataResultEnquiryList}
                  populateTable={props.populateTable}
                  page={props.page}
                  setPage={props.setPage}
                  rowsPerPage={props.rowsPerPage}
                  count={props.count}
                  highlightedRow={false}
                  removeHeaders={[
                    "QNo",
                    "Net Amount",
                    "Contact Person",
                    "Header",
                    "QId",
                    "QDate",
                    "SupplierId",
                    "Remarks",
                  ]}
                  renderActions={renderActions}
                />
              </>
            ) : (
              <h3 className="flex justify-center my-20 font-bold text-gray-600">
                No Records Found...
              </h3>
            )}
          </div>
          {props.dataResultEnquiryList.length > 0 ? (
            <div className="flex justify-end">
              <button
                type="button"
                className="h-9 px-3 bg-green-600 text-white rounded text-base font-medium"
                onClick={() => {
                  if (rowIndex === "") {
                    warningAlert("Please Select Enquiry...!");
                  } else if (rowIndex !== "") {
                    getQuotationEnqItemDetailsList(enquiryQId)
                      .then((response) => {
                        return response.data;
                      })
                      .then((res) => {
                        let arr = [];
                        for (let obj of res.result) {
                          let RequiredObj = {
                            itemId: obj.ItemId,
                            itemMaster: { label: obj.ItemName, id: obj.ItemId },
                            uom: obj.PurchaseUOM,
                            quantity: obj.Qty,
                            rate: obj.Rate,
                            remark: obj.Remarks,
                          };
                          arr.push(RequiredObj);
                          props.setItemData(arr);
                          setRowIndex("");
                        }
                      })
                      .catch((error) => {
                        errorAlert(error.message);
                      });

                    props.handleEnquiryListClose();
                    props.setDataResultEnquiryList({ result: [] });
                    props.setDataResultEnquiryList([]);
                    setCheckBoxIndex(-1);
                    reset(defaultValues);
                    props.setSelectedEnquiryListFromDate(new Date());
                    props.setSelectedEnquiryListToDate(new Date());
                  }
                }}
              >
                Ok
              </button>
            </div>
          ) : null}
        </Box>
      </Modal>
    </>
  );
}
