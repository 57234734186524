import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchBloodIssueNoteAcceptanceList,
  fetchBloodIssueNoteAcceptanceListSearch,
} from "../../services/bloodIssueAcceptanceServices.js/BloodIssueAcceptanceServices";
import BloodIssueAcceptanceDetailsModal from "./BloodIssueAcceptanceDetailsModal";
import { format, isAfter } from "date-fns";
import {
  BloodAcceptanceOffIcon,
  BloodAcceptanceOnIcon,
} from "../../../assets/icons/CustomIcons";
import { Tooltip } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import BloodIssueNotePrint from "../../prints/BloodIssueNotePrint";

const BloodIssueAcceptance = () => {
  const location = useLocation();

  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");

  //Print
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  // blood issue dtls modal
  const [
    openBloodIssueAcceptanceDtlsModal,
    setOpenBloodIssueAcceptanceDtlsModal,
  ] = useState(false);
  const handleOpenBloodIssueAcceptanceDtlsModal = () =>
    setOpenBloodIssueAcceptanceDtlsModal(true);
  const handleCloseBloodIssueAcceptanceDtlsModal = () =>
    setOpenBloodIssueAcceptanceDtlsModal(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      patientSearch: "",
      toDate: new Date(),
      fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [FromDate, ToDate]);

  const getPatientInfoAutoComplete = (SearchString) => {
    const autoCopmleteObj = {
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      searchString: SearchString || "",
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };
    fetchBloodIssueNoteAcceptanceListSearch(autoCopmleteObj)
      .then((response) => response.data)
      .then((res) => {
        setAutocompleteData(res.result);
      });
  };

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, selectedPatient]);

  const populateTable = (forPagination) => {
    const acceptanceObj = {
      admissionId: selectedPatient?.id,
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      menuId: location?.state?.menuId,
    };

    fetchBloodIssueNoteAcceptanceList(acceptanceObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setUserActions(res.actions);
        setCount(res.count);
      });
  };

  //print related
  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <BloodIssueNotePrint
          admissionId={selectedRow && selectedRow?.AdmissionId}
          issueId={selectedRow && selectedRow?.IssueID}
        />
      </div>
    );
  };

  ////////table related
  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction ? (
                <>
                  <div className="text-customGray">
                    <Tooltip title="Print">
                      <LocalPrintshopIcon
                        onClick={() => {
                          setSelectedRow(row);
                          setOpenPrintModal(true);
                        }}
                      />
                    </Tooltip>
                  </div>

                  <div className="flex cursor-pointer items-center">
                    {actions.action === "Create" &&
                      (row["accepted By"] == null ||
                      row["accepted By"] === "" ? (
                        <BloodAcceptanceOnIcon
                          title="Blood Issue Acceptance"
                          onClick={() => {
                            handleOpenBloodIssueAcceptanceDtlsModal();
                            setSelectedRow(row);
                            setPrivilege(actions.action);
                          }}
                        />
                      ) : (
                        <BloodAcceptanceOffIcon />
                      ))}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-center text-black font-bold text-xl ">
          Blood Issue Acceptance
        </div>

        <div className=" py-2 grid grid-cols-6 gap-3 mt-3">
          <div className="md:col-span-2 lg:col-span-2 mb-3">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={autocompleteData}
              name="patientSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={getPatientInfoAutoComplete}
              inputRef={{
                ...register("patientSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                    } else {
                      setSelectedPatient("");
                      methods.reset();
                    }
                  },
                }),
              }}
            />
          </div>

          {/* from Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>

          {/* to Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
        </div>

        <div>
          {dataResult.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              tableClass="2xl:h-[650px]"
              removeHeaders={["id"]}
              renderActions={renderActions}
              highlightRow={false}
            />
          ) : (
            <p className="text-center my-6 ">No Data Found</p>
          )}
        </div>
      </div>

      {openBloodIssueAcceptanceDtlsModal ? (
        <BloodIssueAcceptanceDetailsModal
          open={openBloodIssueAcceptanceDtlsModal}
          setOpen={setOpenBloodIssueAcceptanceDtlsModal}
          handleOpen={handleOpenBloodIssueAcceptanceDtlsModal}
          handleClose={handleCloseBloodIssueAcceptanceDtlsModal}
          selectedRow={selectedRow}
          populateTable={populateTable}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {openPrintModal && renderPrint()}
    </>
  );
};

export default BloodIssueAcceptance;

