import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//list
export const fetchComplaintList = (listObj) => {
  return apiClient.post(
    `/complaintManagement/complaintList
    `,
    listObj,
    {
      headers: authHeader(),
    }
  );
};
export const saveNewComplaint = (finalObj) => {
  return apiClient.post(
    `/complaintManagement/addComplaint
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};
export const saveCompleteComplaintStatus = (finalObj) => {
  return apiClient.post(
    `/complaintManagement/completeComplaint
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};
export const fetchComplaintStatus = () => {
  return apiClient.get(
    `/complaintManagement/getComplaintStatus
    `,
    {
      headers: authHeader(),
    }
  );
};
