import authHeader from "../../../authentication/authservices/auth-header";
import { printersApi } from "../../../http-common";

// service to get the printers connected
export const getPrinterOptions = () => {
  return printersApi.get(`/printerDevices/list`, {
    headers: authHeader(),
  });
};

// service to save default printer settings
export const savePrinterSettings = (postArray) => {
  return printersApi.post(`/printerDevices/savePrinters`, postArray, {
    headers: authHeader(),
  });
};

// service to get saved printer settings
export const getSavedPrinterSettings = () => {
  return printersApi.get(`/printerDevices/userPrinters`, {
    headers: authHeader(),
  });
};