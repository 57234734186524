import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

function PreviousBillsModal(props) {
  const [unitsArray, setUnitsArray] = React.useState([]);
  const [profilePic, setProfilePic] = React.useState(null);
  const { control } = useForm();

  const [order, setOrder] = React.useState("asc");
  const [printAction, setPrintAction] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState();
  const [fileUpload, setFileUpload] = React.useState(false);
  const [headers, setHeaders] = React.useState([]);
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  React.useEffect(() => {
    //set rows object to table
    if (props?.data?.result?.length > 0) {
      const allHeaders = Object.keys(props?.data?.result?.[0]);
      setHeaders(removeHeaders(allHeaders, ["Id","GRNId","SuppilerId","PaymentId"]));
    }
  }, [props.data]);

  const handleSelectBills = (e, row, index) => {
    let bills = [...props.selectedBills];
    if (e.target.checked === true) {
      bills.push(row);
      props.setSelectedBills(bills);
    } else if (e.target.checked === false) {
      let newArray = bills.filter((item) => item.GRNNumber !== row.GRNNumber);
      props.setSelectedBills(newArray);
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            bgcolor: "background.paper",
            p: 2,
            overflow: "scroll",
          }}
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.close();
            }}
          />
          <div className="text-lg font-semibold flex justify-start my-3">List of Previous Bills :</div>
          <div className="grid w-auto mt-1">
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <Paper sx={{ width: "100%", my: 0 }}>
                <TableContainer
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: 7,
                      height: 10,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#7393B3",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "lightBlue",
                    },
                  }}
                  className="rounded max-h-72 "
                >
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                      border: 1,
                      borderColor: "#e0e0e0",

                      paddingY: "scroll",
                    }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            paddingY: 0.5,
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        {headers?.map((header, index) => (
                          <TableCell
                            sortDirection={orderBy === header ? order : false}
                            className="whitespace-nowrap"
                          >
                            <span className="text-gray-600 font-bold">
                              {header}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props?.data?.result?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "& td": {
                                paddingY: 0.5,
                              },
                            }}
                          >
                            {headers &&
                              headers.map((header, i) => (
                                <TableCell
                                  className="whitespace-nowrap"
                                  key={i}
                                >
                                  {row[header]}
                                </TableCell>
                              ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PreviousBillsModal;
