import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentDate: "",
  currentTime: "",
  reUpdateComponent: false,
};

const dateTimeSlice = createSlice({
  name: "dateTime",
  initialState,
  reducers: {
    updateDateTime(state, action) {
      const { currentDate, currentTime } = action.payload;
      state.currentDate = currentDate;
      state.currentTime = currentTime;
    },
    setReUpdateComponent(state, action) {
      state.reUpdateComponent = action.payload;
    },
  },
});

export const { updateDateTime, setReUpdateComponent } = dateTimeSlice.actions;
export default dateTimeSlice.reducer;
