import React from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import { TextField, Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useContext } from "react";
import { OtDetailsContext } from "./OtDetails";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { useState } from "react";

const Actions = [
  {
    id: 0,
    action: "delete",
    isAction: true,
  },
];
const PendingImplants = (props) => {
  const { implantData, setImplantData, reservedSurgeries } = useContext(
    OtDetailsContext
  );

  const [implantArray, setImplantArray] = useState(implantData);

  const {
    control,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext({
    defaultValues: {
      implantRate: null,
      implantQty: null,
      surgeryNameDr: null,
    },
  });

  //patient Documents
  const handleAddData = () => {
    let Rate = getValues("implantRate");
    let Qty = getValues("implantQty");
    let Surgery = getValues("surgeryNameDr");
    var implantArr = [...implantData];
    if (Rate && Qty && Surgery) {
      Rate &&
        Qty &&
        Surgery &&
        implantArr.push({
          Rate: parseInt(Rate),
          Quantity: parseInt(Qty),
          surgeryId: Surgery?.id,
          isDelete: false,
        });
      setImplantArray(implantArr);
      setImplantData(implantArr);
      setValue("implantRate", "");
      setValue("implantQty", "");
      setValue("surgeryNameDr", null);
    }
    if (Rate === "" || Rate === undefined) {
      setError("implantRate", { type: "required", message: "Required" });
    } else if (Rate !== "") {
      clearErrors("implantRate");
    }
    if (Qty === "" || Qty === undefined) {
      setError("implantQty", { type: "required", message: "Required" });
    } else if (Qty !== "") {
      clearErrors("implantQty");
    }
    if (Surgery === null) {
      setError("surgeryNameDr", { type: "required", message: "Required" });
    } else if (Surgery !== "") {
      clearErrors("surgeryNameDr");
    }
  };

  const renderActions = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...implantData];
      let newImplantArray = [...implantArray];
      newTemplateData[index].isDelete = true;
      newImplantArray.splice(index, 1);

      setImplantData(newTemplateData);
      setImplantArray(newImplantArray);
    };
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "delete" ? (
                    <Tooltip title={"Delete"}>
                      <DeleteOutlineOutlinedIcon
                        sx={{ color: "#B91C1C" }}
                        onClick={() => {
                          deleteRow(index);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      {/* Patient Documents */}
      <div className="flex gap-3 item-center">
        <div className="font-bold tracking-wide text-sm  font-Poppins mt-2">
          Pending Implants
        </div>
        <div>
          <CheckBoxField
            control={control}
            name="isPendingImplantChecked"
            style={{ paddingRight: 0, paddingLeft: 1 }}
          />
        </div>
      </div>

      <div className="md:col-span-2 lg:col-span-1 flex space-x-2">
        <div className="w-24">
          <InputField
            name="implantRate"
            variant="outlined"
            label="Rate"
            error={errors.implantRate}
            control={control}
            type={"number"}
            onKeyDown={(e) => {
              if (
                e?.key === "-" ||
                e?.key === "+" ||
                e?.code === "ArrowUp" ||
                e?.code === "ArrowDown" ||
                e?.code === "NumpadDecimal"
              ) {
                e.preventDefault();
              }
            }}
          />
        </div>
        <div className="w-20">
          {/* //first Name// */}
          <InputField
            name="implantQty"
            variant="outlined"
            label="Quantity"
            error={errors.implantQty}
            control={control}
            type={"number"}
            onKeyDown={(e) => {
              if (
                e?.key === "-" ||
                e?.key === "+" ||
                e?.code === "ArrowUp" ||
                e?.code === "ArrowDown" ||
                e?.code === "NumpadDecimal"
              ) {
                e.preventDefault();
              }
            }}
          />
        </div>
        <div className="w-72">
          <DropdownField
            control={control}
            error={errors.surgeryNameDr}
            name="surgeryNameDr"
            label="surgery Name"
            searchIcon={true}
            dataArray={reservedSurgeries}
            isSearchable={true}
            isClearable={true}
            placeholder="surgery Name "
          />
        </div>
        <div>
          <CommonButton
            label="Add"
            className="bg-customGreen text-white"
            onClick={handleAddData}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-3 ">
        <div className="">
          {implantArray.length > 0 && (
            <CommonDynamicTable
              data={implantArray}
              tableClass="rounded lg:h-36 md:h-36"
              renderActions={renderActions}
              actionsRequired={true}
              removeHeaders={[
                "ChargeId",
                "PendingImplantRemark",
                "surgeryId",
                "isDelete",
              ]}
            />
          )}
        </div>
        <div>
          <TextField
            id="outlined-multiline-static"
            name="implantRemark"
            {...register("implantRemark")}
            label="Remark"
            placeholder="Remark"
            multiline
            InputLabelProps={{ shrink: true }}
            rows={5}
            fullWidth
            sx={{ backgroundColor: "white" }}
          />
        </div>
      </div>
    </>
  );
};

export default PendingImplants;
