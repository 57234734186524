import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SearchIconButton from "../../../../common/components/Buttons/SearchIconButton";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import {
  getPendingListOfBills,
  getPreviousSettledBillsList,
  getStoreOptions,
  getSupplierBySearch,
  getTdsLedgerOptions,
  savePartySettlement,
} from "../../../services/purchase/PartySettlementServices";
import BillInfoModal from "./BillInfoModal";
import PendingBillsTable from "./PendingBillsTable";
import PreviousBillsModal from "./PreviousBillsModal";

const pendingBills = {
  result: [
    {
      "Sr.No": 1,
      "GRN No": 102354,
      "GRN Date": "22/04/2023",
      "Invoice No": 123456,
      "Cash/Credit": "Cash",
      "Total Amount": 25000,
      Discount: 2000,
      "Net Amount": 25000,
      "Bal. Amount": 2000,
    },
  ],
};
const PartySettlement = () => {
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [supplierOptions, setSupplierOptions] = React.useState([]);
  const [storeOptions, setStoreOptions] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState("");
  const [listOfPendingBills, setListOfPendingBills] = React.useState({});
  const [selectedBills, setSelectedBills] = React.useState([]);
  const [amountPayable, setAmountPayable] = React.useState(0);
  const [tdsAmount, setTdsAmount] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [tdsLedgerOptions, setTdsLedgerOptions] = React.useState([]);
  const [amountToPay, setAmountToPay] = React.useState(0);
  const [grnIdsArray, setGrnIdsArray] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [supplierAdvance, setSupplierAdvance] = React.useState(0);
  const [discountAmountError, setDiscountAmountError] = React.useState("");
  const [previousSettledBills, setPreviousSettledBills] = React.useState({});
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [openPreviousBillsModal, setOpenPreviousBillsModal] =
    React.useState(false);

  const handleClosePreviousBillsModal = () => {
    if (openPreviousBillsModal === true) {
      setOpenPreviousBillsModal(false);
    }
  };

  const schema = yup.object().shape({
    tdsLedger: yup
      .object()
      .nullable()
      .required()
      .shape({
        value: yup.string().required("Please Select Doctor"),
        label: yup.string().required("Please Select Doctor"),
      }),
  });

  const closePaymentModal = () => {
    if (open === true) {
      setOpen(false);
    }
  };

  const defaultValues = {
    tdsLedger: null,
    supplier: null,
    store: null,
    totalAmount: "",
    netAmount: "",
    discAmount: "",
  };
  let selectedPatient = null;
  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleSupplierSearch = (autoSearchString) => {
    getSupplierBySearch(autoSearchString)
      .then((response) => response.data)
      .then((res) => {
        setSupplierOptions(res.result);
      });
  };

  let storeWatch = watch("store");
  let supplierWatch = watch("supplier");

  React.useEffect(() => {
    getStoreOptions()
      .then((response) => response.data)
      .then((res) => {
        setStoreOptions(res.result);
      });
    getTdsLedgerOptions()
      .then((response) => response.data)
      .then((res) => {
        setTdsLedgerOptions(res.result);
      });
  }, []);

  const handleSearchListOfBills = () => {
    setShowSpinner(true);
    let searchObject = {
      fromDate: selectedFromDate,
      storeId: storeWatch?.id,
      supplierId: supplierWatch?.id,
      toDate: selectedToDate,
    };
    getPendingListOfBills(searchObject)
      .then((response) => response.data)
      .then((res) => {
        setListOfPendingBills(res);
        setShowSpinner(false);
      });
  };

  React.useEffect(() => {
    if (selectedBills?.length > 0) {
      let totalPendingBillAmount = 0;
      let grnArray = [];
      for (let bill of selectedBills) {
        grnArray.push(bill.GRNId);
        setGrnIdsArray(grnArray);
        totalPendingBillAmount =
          totalPendingBillAmount + Number(bill.TotalBalanceAmount);
      }
      setAmountPayable(totalPendingBillAmount);
      setValue("totalAmount", totalPendingBillAmount);
    } else {
      setAmountPayable(0);
      setValue("totalAmount", 0);
    }
  }, [selectedBills]);

  let tdsLedgerWatch = watch("tdsLedger");

  const resetFunction = () => {
    reset();
    setListOfPendingBills({});
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
  };

  const saveRecord = (
    paymentInfoListObj,
    concessionAuthorisedById,
    isSettledFlagVal,
    companyIdValue,
    patientAdvanceIdValue,
    consumedAdvanceVal
  ) => {
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));

    let paymentArray = [];
    for (let paymentObject of paymentInfoListObj.paymentInfoList) {
      let obj = {
        amount: paymentObject.amount > 0 ? paymentObject.amount : 0,
        bankId: paymentObject.bankId ? paymentObject.bankId : 0,
        paymentDate: paymentObject.paymentDate
          ? paymentObject.paymentDate
          : new Date(),
        paymentType: paymentObject.paymentType
          ? paymentObject.paymentType
          : null,
        referenceNumber: paymentObject.referenceNumber
          ? paymentObject.referenceNumber
          : "",
        remarks: paymentObject.remarks ? paymentObject.remarks : "",
        upiTypeId: paymentObject.upiTypeId ? paymentObject.upiTypeId : 0,
        chequeAuthorisedBy: paymentObject.chequeAuthorizedById
          ? paymentObject.chequeAuthorizedById
          : 0,
      };
      paymentArray.push(obj);
    }

    let postObject = {
      balanceAdvance: supplierAdvance,
      consumeAdvance: paymentInfoListObj?.consumeAdvance,
      isAdvanceConsume: paymentInfoListObj?.isAdvanceConsume,
      addedBy: loggedUser?.userId,
      cashCounterId: loggedUser?.cashCounterId,
      chequeAuthorisedBy: 0,
      creditAuthorisedBy: concessionAuthorisedById
        ? concessionAuthorisedById
        : 0,
      discountAmount: discountAmount,
      grnId: grnIdsArray,
      paymentInfoList: paymentArray,
      supplierId: selectedSupplier?.id,
      tdsAmount: tdsAmount,
      tdsLedger: tdsLedgerWatch?.label,
    };

    savePartySettlement(postObject)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        resetFunction();
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  React.useEffect(() => {
    if (amountPayable > 0) {
      let netAmountVal =
        Number(amountPayable) - Number(tdsAmount) - Number(discountAmount);
      setValue("netAmount", netAmountVal);
      setAmountToPay(netAmountVal);
    } else {
      setAmountToPay(0);
    }
  }, [tdsAmount, amountPayable, discountAmount]);

  const onSubmitDataHandler = (data) => {
    if (discountAmountError === "") {
      if (amountToPay > 0) {
        setOpen(true);
      } else {
        errorAlert("Please Select Bill.");
      }
    }
  };

  const handlePreviousBills = () => {
    if (storeWatch !== null && selectedSupplier !== null) {
      let searchObject = {
        fromDate: selectedFromDate,
        storeId: storeWatch?.id,
        supplierId: selectedSupplier?.id,
        toDate: selectedToDate,
      };
      getPreviousSettledBillsList(searchObject)
        .then((response) => response.data)
        .then((res) => {
          setPreviousSettledBills(res);
          setOpenPreviousBillsModal(true);
        });
    } else if (selectedSupplier === null) {
      errorAlert("Please Select Supplier.");
    } else if (storeWatch === null) {
      errorAlert("Please Select Store.");
    }
  };
  React.useEffect(() => {
    if (supplierWatch !== null) {
      setSupplierAdvance(supplierWatch.AdvanceBalance);
    } else {
      setSupplierAdvance(0);
    }
    if (supplierWatch !== null) {
      setSelectedSupplier(supplierWatch);
    } else {
      setListOfPendingBills({});
    }
  }, [supplierWatch]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitDataHandler)}
        className="mt-14 space-y-2"
      >
        <div className="text-center w-full">
          <label className="text-xl font-semibold">
            Supplier Payment Settlement
          </label>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:flex gap-2">
          <div className="w-full">
            <SearchDropdown
              control={control}
              name="supplier"
              label="Supplier"
              placeholder="Supplier"
              handleInputChange={handleSupplierSearch}
              isClearable={true}
              searchIcon={true}
              dataArray={supplierOptions}
              defaultValue={null}
            />
          </div>
          <div className="w-full">
            <DropdownField
              control={control}
              name="store"
              placeholder="Store"
              dataArray={storeOptions}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div className="w-full">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                // disablePast
                label="From Date"
                value={selectedFromDate}
                name="fromDate"
                onChange={(newValue) => {
                  setSelectedFromDate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    fullWidth
                    name="fromDate"
                    size="small"
                    defaultValue=""
                    {...params}
                    sx={{
                      svg: {
                        color: "#1e3a8a",
                        height: 22,
                        width: "100%",
                        marginRight: "16px",
                      },
                      backgroundColor: "white",
                      overflow: "visible",

                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-input": {
                          // border: 0,
                          fontSize: 14,
                          height: "18px",
                          width: "100%",

                          borderColor: "  ",
                          overflow: "hidden",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="w-full">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                // disablePast
                label="To Date"
                value={selectedToDate}
                name="toDate"
                onChange={(newValue) => {
                  setSelectedToDate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    fullWidth
                    name="toDate"
                    size="small"
                    defaultValue=""
                    {...params}
                    sx={{
                      svg: {
                        color: "#1e3a8a",
                        height: 22,
                        width: "100%",
                        marginRight: "16px",
                      },
                      backgroundColor: "white",
                      overflow: "visible",

                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-input": {
                          // border: 0,
                          fontSize: 14,
                          height: "18px",
                          width: "100%",

                          borderColor: "  ",
                          overflow: "hidden",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <SearchIconButton onClick={handleSearchListOfBills} />
          </div>
        </div>
        <div>
          {showSpinner === true ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              {listOfPendingBills?.result?.length > 0 ? (
                <>
                  <label className="font-semibold">List Of Pending Bills</label>
                  <div>
                    <PendingBillsTable
                      data={listOfPendingBills}
                      selectedBills={selectedBills}
                      setSelectedBills={setSelectedBills}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="space-y-2">
          <div className="text-sm font-semibold text-customRed my-2">
            {discountAmountError}
          </div>
          {listOfPendingBills?.result?.length > 0 ? (
            <div className="flex gap-2 w-full">
              <div className="w-full">
                <InputField
                  control={control}
                  name="totalAmount"
                  label="Total Amount"
                />
              </div>
              <fieldset
                className="w-full"
                disabled={amountPayable === 0 ? true : false}
              >
                <InputField
                  control={control}
                  name="tdsAmount"
                  label="T.D.S. Amount"
                  inputRef={{
                    ...register("tdsAmount", {
                      onChange: (e) => {
                        if (e.target.value !== "") {
                          setTdsAmount(e.target.value);
                        } else {
                          setTdsAmount(0);
                        }
                        if (e.target.value > amountPayable) {
                          setDiscountAmountError(
                            "TDS Amount Exceeds Total Amount."
                          );
                        } else {
                          setDiscountAmountError("");
                        }
                      },
                    }),
                  }}
                />
              </fieldset>
              <fieldset
                className="w-full "
                disabled={amountPayable === 0 ? true : false}
              >
                <InputField
                  control={control}
                  name="discAmount"
                  label="Discount Amount"
                  inputRef={{
                    ...register("discAmount", {
                      onChange: (e) => {
                        if (e.target.value !== "") {
                          setDiscountAmount(e.target.value);
                        } else {
                          setDiscountAmount(0);
                        }
                        if (e.target.value > amountPayable) {
                          setDiscountAmountError(
                            "Discount Amount Exceeds Total Amount."
                          );
                        } else {
                          setDiscountAmountError("");
                        }
                      },
                    }),
                  }}
                />
              </fieldset>
              <fieldset className="w-full" disabled>
                <InputField
                  control={control}
                  name="netAmount"
                  label="Net Amount"
                />
              </fieldset>
              <div className="w-full">
                <DropdownField
                  control={control}
                  name="tdsLedger"
                  placeholder="TDS Ledger"
                  dataArray={tdsLedgerOptions}
                  error={errors?.tdsLedger}
                />
              </div>
              <div className="">
                <button
                  type="submit"
                  className="h-9 w-max px-3  bg-green-700 text-white rounded text-base font-medium"
                >
                  Settle Bill
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="flex gap-2">
            {/* <div className="w-full">
              <InputField
                control={control}
                name="totalApprovedAmt"
                label="Total Approved Amount"
              />
            </div> */}
            {/* <div className="w-full">
              <InputField
                control={control}
                name="creditAmt"
                label="Credit Amount"
              />
            </div> */}
            {/* <div>
              <button
                type="button"
                className="h-9 w-max px-3  bg-green-700 text-white rounded text-base font-medium"
              >
                Update
              </button>
            </div> */}
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="h-9 w-max px-3 bg-customBlue rounded text-white"
            >
              Print Voucher
            </button>
            <button
              type="button"
              onClick={() => {
                handlePreviousBills();
              }}
              className="h-9 w-max px-3 bg-customBlue rounded text-white "
            >
              Prev. Settlement
            </button>
            <button
              type="button"
              onClick={() => {
                resetFunction();
              }}
              className="h-9 w-max px-3 text-red-600 border-2 border-red-600 rounded text-base font-medium"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
      <BillInfoModal
        open={open}
        setOpen={setOpen}
        selectedPatient={selectedPatient}
        closePaymentModal={closePaymentModal}
        submitPaymentInfoModal={saveRecord}
        amountPayable={amountToPay}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        companyId={0}
        patientAdvanceDetails={supplierAdvance}
      />

      <PreviousBillsModal
        open={openPreviousBillsModal}
        close={handleClosePreviousBillsModal}
        data={previousSettledBills}
      />
    </>
  );
};

export default PartySettlement;
