import { Box, Modal, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";
import RadioField from "../../common/components/FormFields/RadioField";
import SalesDetailsListTable from "./SalesDetailsListTable";
import SalesListTable from "./SalesListTable";
import { getSalesItemDetails, getSalesReturnList } from "./Services";

const patientTypeObj = [
  { id: 3, value: 3, label: "All", name: "All" },
  { id: 0, value: 0, label: "OPD", name: "OPD" },
  { id: 1, value: 1, label: "IPD", name: "IPD" },
  { id: 2, value: 2, label: "Counter", name: "COUNTER" },
];
const defaultValues = {
  patientType: 3,
};

function SalesModal(props) {
  const { watch, getValues, setValue, control, register, reset } = useForm({
    defaultValues: defaultValues,
  });
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [counterSalesList, setCounterSalesList] = React.useState({});
  const [selectedSalesId, setSelectedSalesId] = React.useState(null);
  const [itemDetails, setItemDetails] = React.useState({});
  const [itemsToReturn, setItemsToReturn] = React.useState([]);

  let getPatientType = watch("patientType");

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const populateReturnTable = () => {
    if (props.open === true) {
      let obj = {
        fromDate: selectedFromDate,
        opdIpd: getPatientType,
        storeId: userInfo?.storeId,
        toDate: selectedToDate,
      };
      getSalesReturnList(obj)
        .then((response) => response.data)
        .then((res) => {
          setCounterSalesList(res);
        });
    }
  };
  React.useEffect(() => {
    populateReturnTable();
  }, []);

  const handleFilter = () => {
    setItemDetails({ statusCode: 300, result: [] });
    if (props.open === true) {
      let obj = {
        fromDate: selectedFromDate,
        opdIpd: Number(getPatientType),
        storeId: userInfo?.storeId,
        toDate: selectedToDate,
        menuId: props?.menuId,
        privilege: props?.privilege,
      };

      getSalesReturnList(obj)
        .then((response) => response.data)
        .then((res) => {
          if (res.result.length > 0) {
            setCounterSalesList(res);
          } else {
            setItemDetails({ statusCode: 300, result: [] });
            setCounterSalesList({ statusCode: 300, result: [] });
          }
        });
    }
  };

  React.useEffect(() => {
    if (props.open) {
      handleFilter();
    }
  }, [props.open]);

  React.useEffect(() => {
    if (selectedSalesId !== null) {
      if (props.component === "return") {
        props.setRetSalesId(selectedSalesId);
      }

      getSalesItemDetails(selectedSalesId)
        .then((response) => response.data)
        .then((res) => {
          setItemDetails(res);
        });
    } else {
      setItemDetails({ result: [] });
    }
  }, [selectedSalesId]);

  React.useEffect(() => {
    populateReturnTable();
    setSelectedSalesId(null);
  }, [props.resetCount]);

  return (
    <>
      <>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "85%",
              height: "90%",
              bgcolor: "background.paper",
              p: 2,
              overflow: "scroll",
            }}
          >
            <div>
              <div className="flex items-center justify-between">
                <div>
                  {props.component === "return" ? (
                    <span className="font-semibold text-lg ">Sales Return</span>
                  ) : (
                    <span className="font-semibold text-lg ">
                      Previous Bills :
                    </span>
                  )}
                </div>
                <div>
                  <CancelPresentationIconButton
                    className="text-red-500 cursor-pointer"
                    onClick={() => {
                      setCounterSalesList({ statusCode: 200, result: [] });
                      setItemDetails({ statusCode: 200, result: [] });
                      setSelectedFromDate(new Date());
                      setSelectedToDate(new Date());
                      reset(defaultValues);
                      props.close();
                      props.setResetCount(props.resetCount + 1);
                    }}
                  />
                </div>
              </div>
              <div className="flex whitespace-nowrap gap-2 my-4">
                <div className=" flex space-x-2 whitespace-nowrap lg:w-[32%] items-center">
                  <label className="font-semibold whitespace-nowrap">
                    Patient Type &nbsp; :
                  </label>
                  <div className="pl-2 mt-[4px] ">
                    <RadioField
                      className="whitespace-nowrap"
                      control={control}
                      name="patientType"
                      dataArray={patientTypeObj}
                    />
                  </div>
                </div>
                <div className="flex gap-2 ">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      // disablePast
                      label="From Date"
                      value={selectedFromDate}
                      name="fromDate"
                      onChange={(newValue) => {
                        setSelectedFromDate(newValue);
                      }}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => (
                        <TextField
                          className="bg-white"
                          fullWidth
                          name="fromDate"
                          size="small"
                          defaultValue=""
                          {...params}
                          sx={{
                            svg: { color: "#0B83A5" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disableFuture
                      label="To Date"
                      value={selectedToDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setSelectedToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="bg-white"
                          name="toDate"
                          fullWidth
                          size="small"
                          {...params}
                          sx={{
                            svg: { color: "#0B83A5" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <CommonButton
                    type="button"
                    className="h-9 cursor-pointer w-14 px-2 rounded-md bg-customBlue text-white "
                    searchIcon={true}
                    onClick={handleFilter}
                  />
                </div>
              </div>
              {counterSalesList.statusCode === 200 &&
              counterSalesList.result?.length > 0 ? (
                <div>
                  <SalesListTable
                    data={counterSalesList}
                    setSelectedSalesId={setSelectedSalesId}
                    setPatientName={props.setPatientName}
                    component={props.component}
                    openPrintWindow={props.openPrintWindow}
                  />
                </div>
              ) : (
                <div className="flex justify-center text-lg font-semibold text-gray-400 my-7">
                  No Records Found..!!
                </div>
              )}

              {itemDetails.statusCode === 200 &&
              itemDetails?.result?.length > 0 &&
              counterSalesList.result?.length > 0 ? (
                <div>
                  <SalesDetailsListTable
                    component={props.component}
                    data={itemDetails}
                    setItemDetails={setItemDetails}
                    itemsToReturn={itemsToReturn}
                    setItemsToReturn={setItemsToReturn}
                    returnItems={props.returnItems}
                    setReturnItems={props.setReturnItems}
                    setCounterSalesList={setCounterSalesList}
                    setSelectedSalesId={setItemDetails}
                    setSelectedFromDate={setSelectedFromDate}
                    setSelectedToDate={setSelectedToDate}
                    close={props.close}
                    handleFilter={handleFilter}
                    resetCount={props.resetCount}
                    setResetCount={props.setResetCount}
                  />
                </div>
              ) : (
                ""
                // <div className="flex justify-center font-semibold text-gray-400 my-5">Please Select a Record..!!</div>
              )}
            </div>
          </Box>
        </Modal>
      </>
    </>
  );
}

export default SalesModal;
