export const dummyFunctionalities = [
  {
    id: 1,
    functionality: "Masters",
    isChecked: true,
    subFunction: [
      {
        id: 2,
        functionality: "Administration",
        isChecked: true,
        subFunction: [
          {
            id: 3,
            functionality: "System Management",
            isChecked: true,
            subFunction: [
              {
                id: 4,
                functionality: "Unit",
                isChecked: true,
              },
              {
                id: 5,
                functionality: "Cabin",
                isChecked: true,
              },
              {
                id: 6,
                functionality: "Hospital",
                isChecked: true,
              },
              {
                id: 7,
                functionality: "Department",
                isChecked: true,
              },
              {
                id: 8,
                functionality: "Sub Department",
                isChecked: true,
              },
              {
                id: 9,
                functionality: "Authorization",
                isChecked: true,
              },
              {
                id: 10,
                functionality: "Country",
                isChecked: true,
              },
              {
                id: 11,
                functionality: "State",
                isChecked: true,
              },
              {
                id: 12,
                functionality: "District",
                isChecked: true,
              },
              {
                id: 13,
                functionality: "Taluka",
                isChecked: true,
              },
              {
                id: 14,
                functionality: "City",
                isChecked: true,
              },
              {
                id: 15,
                functionality: "Area",
                isChecked: true,
              },
              {
                id: 16,
                functionality: "Qualification",
                isChecked: true,
              },
              {
                id: 17,
                functionality: "Mother Tongue",
                isChecked: true,
              },
              {
                id: 18,
                functionality: "Salutation",
                isChecked: true,
              },
              {
                id: 19,
                functionality: "Nationality",
                isChecked: true,
              },
              {
                id: 20,
                functionality: "National Holiday",
                isChecked: true,
              },
              {
                id: 21,
                functionality: "Free OPD",
                isChecked: true,
              },
              {
                id: 22,
                functionality: "Camp Master",
                isChecked: true,
              },
              {
                id: 23,
                functionality: "Camp Details",
                isChecked: true,
              },
            ],
          },
          {
            id: 24,
            functionality: "Patient",
            isChecked: true,
            subFunction: [
              {
                id: 25,
                functionality: "Class / Bed Category",
                isChecked: true,
              },
              {
                id: 26,
                functionality: "Patient Source",
                isChecked: true,
              },
              {
                id: 27,
                functionality: "Patient Category",
                isChecked: true,
              },
              {
                id: 28,
                functionality: "Marital Status",
                isChecked: true,
              },
              {
                id: 29,
                functionality: "Blood Group",
                isChecked: true,
              },
              {
                id: 30,
                functionality: "Gender",
                isChecked: true,
              },
              {
                id: 31,
                functionality: "Religion",
                isChecked: true,
              },
              {
                id: 32,
                functionality: "Relation",
                isChecked: true,
              },
              {
                id: 33,
                functionality: "Income",
                isChecked: true,
              },
              {
                id: 34,
                functionality: "Recommended by",
                isChecked: true,
              },
              {
                id: 35,
                functionality: "Admission Tag Master",
                isChecked: true,
              },
              {
                id: 36,
                functionality: "Parameter Master",
                isChecked: true,
              },
              {
                id: 37,
                functionality: "Patient Engagement Group Master",
                isChecked: true,
              },
            ],
          },
          {
            id: 38,
            functionality: "IPD",
            isChecked: true,
            subFunction: [
              {
                id: 39,
                functionality: "Block",
                isChecked: true,
              },
              {
                id: 40,
                functionality: "Floor",
                isChecked: true,
              },
              {
                id: 41,
                functionality: "Room",
                isChecked: true,
              },
              {
                id: 42,
                functionality: "Ward",
                isChecked: true,
              },
              {
                id: 43,
                functionality: "Bed",
                isChecked: true,
              },
              {
                id: 44,
                functionality: "Discharge Type",
                isChecked: true,
              },
              {
                id: 45,
                functionality: "Discharge Destination",
                isChecked: true,
              },
            ],
          },
          {
            id: 46,
            functionality: "Billing",
            isChecked: true,
            subFunction: [
              {
                id: 47,
                functionality: "Group",
                isChecked: true,
              },
              {
                id: 48,
                functionality: "Sub Group",
                isChecked: true,
              },
              {
                id: 49,
                functionality: "Tariff",
                isChecked: true,
              },
              {
                id: 50,
                functionality: "Code Type",
                isChecked: true,
              },
              {
                id: 51,
                functionality: "Service",
                isChecked: true,
              },
              {
                id: 52,
                functionality: "Tariff Service",
                isChecked: true,
              },
              {
                id: 53,
                functionality: "Service Rate Matrix",
                isChecked: true,
              },
              {
                id: 54,
                functionality: "Cash Counter",
                isChecked: true,
              },
              {
                id: 55,
                functionality: "Bank",
                isChecked: true,
              },
              {
                id: 56,
                functionality: "Concession Category",
                isChecked: true,
              },
              {
                id: 57,
                functionality: "Company Type",
                isChecked: true,
              },
              {
                id: 58,
                functionality: "Company",
                isChecked: true,
                permissions: [
                  {
                    id: 5,
                    isChecked: false,
                    isAction: false,
                    permission: "Print",
                  },
                  {
                    permission: "Apply Discount",
                    isChecked: false,
                    id: 30,
                    isAction: false,
                  },
                ],
              },
              {
                id: 59,
                functionality: "Advance Against",
                isChecked: true,
              },
              {
                id: 60,
                functionality: "Credit Note Type",
                isChecked: true,
              },
              {
                id: 61,
                functionality: "Account Head",
                isChecked: true,
              },
              {
                id: 62,
                functionality: "Bill Template Master",
                isChecked: true,
              },
              {
                id: 63,
                functionality: "Refering Group Master",
                isChecked: true,
              },
              {
                id: 64,
                functionality: "Category Service Rate",
                isChecked: true,
              },
              {
                id: 65,
                functionality: "Package Service Schedule Master",
                isChecked: true,
              },
            ],
          },
          {
            id: 66,
            functionality: "Op. Theater",
            isChecked: true,
            subFunction: [
              {
                id: 67,
                functionality: "Theater",
                isChecked: true,
              },
              {
                id: 68,
                functionality: "Anesthesia Type",
                isChecked: true,
              },
              {
                id: 69,
                functionality: "Deposit",
                isChecked: true,
              },
              {
                id: 70,
                functionality: "Procedure Type",
                isChecked: true,
              },
              {
                id: 71,
                functionality: "Operation Result",
                isChecked: true,
              },
              {
                id: 72,
                functionality: "OT Table",
                isChecked: true,
              },
              {
                id: 73,
                functionality: "Pre Operative Instruction",
                isChecked: true,
              },
              {
                id: 74,
                functionality: "Post Operative Instruction",
                isChecked: true,
              },
              {
                id: 75,
                functionality: "Consent Template",
                isChecked: true,
              },
              {
                id: 76,
                functionality: "Operation Status",
                isChecked: true,
              },
              {
                id: 77,
                functionality: "Check List Master",
                isChecked: true,
                subFunction: [
                  {
                    id: 78,
                    functionality: "Check List Category Master",
                    isChecked: false,
                  },
                  {
                    id: 79,
                    functionality: "Check Point Master",
                    isChecked: false,
                  },
                ],
              },
              {
                id: 80,
                functionality: "OT Template Master",
                isChecked: true,
              },
              {
                id: 81,
                functionality: "Vital Master",
                isChecked: true,
              },
            ],
          },
          {
            id: 82,
            functionality: "Clinical Analysis",
            isChecked: true,
            subFunction: [
              {
                id: 83,
                functionality: "Diagnosis",
                isChecked: true,
              },
              {
                id: 84,
                functionality: "Clinical Findings",
                isChecked: true,
              },
              {
                id: 85,
                functionality: "Follow Up Summary",
                isChecked: true,
              },
              {
                id: 86,
                functionality: "Surgery Findings",
                isChecked: true,
              },
              {
                id: 87,
                functionality: "Anesthesia Summary",
                isChecked: true,
              },
              {
                id: 88,
                functionality: "Past History",
                isChecked: true,
              },
              {
                id: 89,
                functionality: "Family History",
                isChecked: true,
              },
              {
                id: 90,
                functionality: "Birth History",
                isChecked: true,
              },
              {
                id: 91,
                functionality: "Developmental History",
                isChecked: true,
              },
              {
                id: 92,
                functionality: "Immunization History",
                isChecked: true,
              },
              {
                id: 93,
                functionality: "Local Examination",
                isChecked: true,
              },
              {
                id: 94,
                functionality: "Drug Route",
                isChecked: true,
              },
              {
                id: 95,
                functionality: "Drug Instruction",
                isChecked: true,
              },
              {
                id: 96,
                functionality: "Drug Dose",
                isChecked: true,
              },
              {
                id: 97,
                functionality: "Image Master",
                isChecked: true,
              },
              {
                id: 98,
                functionality: "Icons Master",
                isChecked: true,
              },
              {
                id: 99,
                functionality: "EMR Form Builder",
                isChecked: true,
              },
              {
                id: 100,
                functionality: "Vaccine Age Master",
                isChecked: true,
              },
              {
                id: 101,
                functionality: "Vaccine Service Master",
                isChecked: true,
              },
              {
                id: 102,
                functionality: "Chemo Sheet Templates",
                isChecked: true,
              },
            ],
          },
        ],
      },
      {
        id: 103,
        functionality: "Doctor",
        isChecked: true,
        subFunction: [
          {
            id: 104,
            functionality: "Type",
            isChecked: true,
          },
          {
            id: 105,
            functionality: "Specialization",
            isChecked: true,
          },
          {
            id: 106,
            functionality: "Share",
            isChecked: true,
          },
          {
            id: 107,
            functionality: "Schedule",
            isChecked: true,
          },
          {
            id: 108,
            functionality: "Roster",
            isChecked: true,
          },
          {
            id: 109,
            functionality: "Doctor",
            isChecked: true,
          },
          {
            id: 110,
            functionality: "Referring Doctor",
            isChecked: true,
          },
          {
            id: 111,
            functionality: "Classification",
            isChecked: true,
          },
          {
            id: 112,
            functionality: "Sub Specialization",
            isChecked: true,
          },
          {
            id: 113,
            functionality: "Refer Entity",
            isChecked: true,
          },
          {
            id: 114,
            functionality: "Interpreter Master",
            isChecked: true,
          },
        ],
      },
      {
        id: 115,
        functionality: "Employee",
        isChecked: true,
        subFunction: [
          {
            id: 116,
            functionality: "Designation",
            isChecked: true,
          },
          {
            id: 117,
            functionality: "Employee Classification",
            isChecked: true,
          },
          {
            id: 118,
            functionality: "Employee",
            isChecked: true,
          },
          {
            id: 119,
            functionality: "Employee Category",
            isChecked: true,
          },
          {
            id: 120,
            functionality: "Employee Facility Master",
            isChecked: true,
          },
          {
            id: 121,
            functionality: "Facility Parameter Master",
            isChecked: true,
          },
          {
            id: 122,
            functionality: "Employee Management",
            isChecked: true,
            subFunction: [
              {
                id: 123,
                functionality: "Facility To Employee",
                isChecked: true,
              },
              {
                id: 124,
                functionality: "Facility Parameter / Measurment and Issue",
                isChecked: true,
              },
              {
                id: 125,
                functionality: "Return Facility",
                isChecked: true,
              },
              {
                id: 126,
                functionality: "Employee Bed Occupancy",
                isChecked: true,
              },
            ],
          },
        ],
      },
      {
        id: 127,
        functionality: "Investigation",
        isChecked: true,
        subFunction: [
          {
            id: 128,
            functionality: "Pathology",
            isChecked: true,
            subFunction: [
              {
                id: 129,
                functionality: "Category/Lab Groups",
                isChecked: true,
              },
              {
                id: 130,
                functionality: "Parameter Units",
                isChecked: true,
              },
              {
                id: 131,
                functionality: "Parameter",
                isChecked: true,
              },
              {
                id: 132,
                functionality: "Templates",
                isChecked: true,
              },
              {
                id: 133,
                functionality: "Sample/Specimen",
                isChecked: true,
              },
              {
                id: 134,
                functionality: "Test",
                isChecked: true,
              },
              {
                id: 135,
                functionality: "Profile/Report Group",
                isChecked: true,
              },
              {
                id: 136,
                functionality: "Antibiotics",
                isChecked: true,
              },
              {
                id: 137,
                functionality: "Pathologist Master",
                isChecked: true,
              },
              {
                id: 138,
                functionality: "Machine Master",
                isChecked: true,
              },
              {
                id: 139,
                functionality: "Machine Parameters",
                isChecked: true,
              },
              {
                id: 140,
                functionality: "Machine Parameter Linking",
                isChecked: true,
              },
              {
                id: 141,
                functionality: "Organism Master",
                isChecked: true,
              },
              {
                id: 142,
                functionality: "Add Color Tube To Test",
                isChecked: true,
              },
            ],
          },
          {
            id: 143,
            functionality: "Radiology",
            isChecked: true,
            subFunction: [
              {
                id: 144,
                functionality: "Category/Radiology Groups",
                isChecked: true,
              },
              {
                id: 145,
                functionality: "Film Size",
                isChecked: true,
              },
              {
                id: 146,
                functionality: "Template Result",
                isChecked: true,
              },
              {
                id: 147,
                functionality: "Templates",
                isChecked: true,
              },
              {
                id: 148,
                functionality: "Test",
                isChecked: true,
              },
              {
                id: 149,
                functionality: "Modality",
                isChecked: true,
              },
              {
                id: 150,
                functionality: "Machine Master",
                isChecked: true,
              },
            ],
          },
        ],
      },
      {
        id: 151,
        functionality: "Inventory",
        isChecked: true,
        subFunction: [
          {
            id: 152,
            functionality: "Currency",
            isChecked: true,
          },
          {
            id: 153,
            functionality: "Generic Name",
            isChecked: true,
          },
          {
            id: 154,
            functionality: "Item Company",
            isChecked: true,
          },
          {
            id: 155,
            functionality: "Store",
            isChecked: true,
          },
          {
            id: 156,
            functionality: "Item Type",
            isChecked: true,
          },
          {
            id: 157,
            functionality: "Item Category",
            isChecked: true,
          },
          {
            id: 158,
            functionality: "Item Class",
            isChecked: true,
          },
          {
            id: 159,
            functionality: "Unit of Measurement",
            isChecked: true,
          },
          {
            id: 160,
            functionality: "Item",
            isChecked: true,
          },
          {
            id: 161,
            functionality: "Supplier",
            isChecked: true,
          },
          {
            id: 162,
            functionality: "Tax",
            isChecked: true,
          },
          {
            id: 163,
            functionality: "Stock Adjustment Type",
            isChecked: true,
          },
          {
            id: 164,
            functionality: "Reason for Stock Adjustment",
            isChecked: true,
          },
          {
            id: 165,
            functionality: "Octroi",
            isChecked: true,
          },
          {
            id: 166,
            functionality: "Freight",
            isChecked: true,
          },
          {
            id: 167,
            functionality: "Tax Nature",
            isChecked: true,
          },
          {
            id: 168,
            functionality: "Mode of Payment",
            isChecked: true,
          },
          {
            id: 169,
            functionality: "Term of Payment",
            isChecked: true,
          },
          {
            id: 170,
            functionality: "Delivery Date",
            isChecked: true,
          },
          {
            id: 171,
            functionality: "Scrap Item",
            isChecked: true,
          },
          {
            id: 172,
            functionality: "Rack List",
            isChecked: true,
          },
          {
            id: 173,
            functionality: "Shelf List",
            isChecked: true,
          },
          {
            id: 174,
            functionality: "Item Kit Master",
            isChecked: true,
          },
          {
            id: 175,
            functionality: "CSSD Masters",
            isChecked: true,
            subFunction: [
              {
                id: 176,
                functionality: "Sterilization Type Master",
                isChecked: true,
              },
              {
                id: 177,
                functionality: "Sterilization Machine Manufacturer",
                isChecked: true,
              },
              {
                id: 178,
                functionality: "Sterilization Machine Master",
                isChecked: true,
              },
              {
                id: 179,
                functionality: "Sterilization Expiry",
                isChecked: true,
              },
              {
                id: 180,
                functionality: "Quality Tests",
                isChecked: true,
              },
            ],
          },
          {
            id: 181,
            functionality: "Drug Category",
            isChecked: true,
          },
          {
            id: 182,
            functionality: "Sub Tax Nature",
            isChecked: true,
          },
        ],
      },
      {
        id: 183,
        functionality: "Misc. Services",
        isChecked: true,
        subFunction: [
          {
            id: 184,
            functionality: "Housekeeping / Maintenance",
            isChecked: true,
            subFunction: [
              {
                id: 185,
                functionality: "Activity",
                isChecked: true,
              },
            ],
          },
          {
            id: 186,
            functionality: "Equipment Management",
            isChecked: true,
            subFunction: [
              {
                id: 187,
                functionality: "Wing",
                isChecked: true,
              },
              {
                id: 188,
                functionality: "Floor",
                isChecked: true,
              },
              {
                id: 189,
                functionality: "Room",
                isChecked: true,
              },
              {
                id: 190,
                functionality: "Gate Pass",
                isChecked: true,
              },
              {
                id: 191,
                functionality: "Complaint Type",
                isChecked: true,
              },
              {
                id: 192,
                functionality: "Equipment Type",
                isChecked: true,
              },
              {
                id: 193,
                functionality: "Equipment Status",
                isChecked: true,
              },
              {
                id: 194,
                functionality: "Complaint Resolution",
                isChecked: true,
              },
              {
                id: 195,
                functionality: "Reason Of Condemnation",
                isChecked: true,
              },
              {
                id: 196,
                functionality: "Equipment Department",
                isChecked: true,
              },
              {
                id: 197,
                functionality: "Service Agent Master",
                isChecked: true,
              },
              {
                id: 198,
                functionality: "Maintenance Type",
                isChecked: true,
              },
              {
                id: 199,
                functionality: "Equipment Spare Part",
                isChecked: true,
              },
              {
                id: 200,
                functionality: "Spare Part Definition",
                isChecked: true,
              },
              {
                id: 201,
                functionality: "Equipment Peripheral",
                isChecked: true,
              },
              {
                id: 202,
                functionality: "Peripheral Defination",
                isChecked: true,
              },
            ],
          },
          {
            id: 203,
            functionality: "Diet and Nutrition",
            isChecked: true,
            subFunction: [
              {
                id: 204,
                functionality: "Food Item",
                isChecked: true,
              },
              {
                id: 205,
                functionality: "Diet Plan",
                isChecked: true,
              },
              {
                id: 206,
                functionality: "Diet Master",
                isChecked: true,
              },
              {
                id: 207,
                functionality: "Diet Type",
                isChecked: true,
              },
              {
                id: 208,
                functionality: "Food Type",
                isChecked: true,
              },
            ],
          },
          {
            id: 209,
            functionality: "PatientFeedbackManagement",
            isChecked: true,
            subFunction: [
              {
                id: 210,
                functionality: "PatientFeedbackCategory",
                isChecked: true,
              },
              {
                id: 211,
                functionality: "PatientActivityMaster",
                isChecked: true,
              },
            ],
          },
          {
            id: 212,
            functionality: "Folder Master",
            isChecked: true,
          },
          {
            id: 213,
            functionality: "BioMedical Waste Management",
            isChecked: true,
            subFunction: [
              {
                id: 214,
                functionality: "Waste Bag Master",
                isChecked: true,
              },
            ],
          },
          {
            id: 215,
            functionality: "Folder Master For HR DMS",
            isChecked: true,
          },
          {
            id: 216,
            functionality: "Tag Master",
            isChecked: true,
          },
        ],
      },
      {
        id: 217,
        functionality: "Canteen",
        isChecked: true,
        subFunction: [
          {
            id: 218,
            functionality: "Canteen Menu Master",
            isChecked: true,
          },
          {
            id: 219,
            functionality: "Dish Master",
            isChecked: true,
          },
          {
            id: 220,
            functionality: "Dish Material",
            isChecked: true,
          },
          {
            id: 221,
            functionality: "Dish Arrangement",
            isChecked: true,
          },
          {
            id: 222,
            functionality: "Cateen Material Purchase",
            isChecked: true,
            subFunction: [
              {
                id: 223,
                functionality: "Canteen Indent",
                isChecked: true,
              },
              {
                id: 224,
                functionality: "Ready Dish",
                isChecked: true,
              },
              {
                id: 225,
                functionality: "Reuse Dish",
                isChecked: true,
              },
              {
                id: 226,
                functionality: "Westage Dish",
                isChecked: true,
              },
            ],
          },
          {
            id: 227,
            functionality: "Canteen Sale",
            isChecked: true,
            subFunction: [
              {
                id: 228,
                functionality: "Sale",
                isChecked: true,
              },
            ],
          },
          {
            id: 229,
            functionality: "Canteen Token Display",
            isChecked: true,
          },
        ],
      },
    ],
  },
  {
    id: 230,
    functionality: "Out Patients",
    isChecked: true,
    subFunction: [
      {
        id: 231,
        functionality: "Phone Appointments",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            permission: "View Appointments",
            isAction: false,
            id: 27,
          },
        ],
      },
      {
        id: 232,
        functionality: "Registration",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            permission: "Blacklist",
            id: 7,
            isChecked: true,
            isAction: false,
          },
          {
            isChecked: true,
            permission: "Whitelist",
            isAction: false,
            id: 64,
          },
        ],
      },
      {
        id: 233,
        functionality: "Visit Details",
        isChecked: true,
        permissions: [
          {
            permission: "Edit",
            isChecked: true,
            isAction: true,
            id: 3,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            isChecked: true,
            id: 8,
            permission: "OPD Bill",
            isAction: false,
          },
          {
            isChecked: true,
            permission: "Patient Visit",
            isAction: false,
            id: 9,
          },
          {
            permission: "Book Appointment",
            isChecked: true,
            isAction: false,
            id: 10,
          },
          {
            isChecked: true,
            isAction: true,
            id: 14,
            permission: "MLC",
          },
        ],
      },
      {
        id: 234,
        functionality: "Medical Records",
        isChecked: true,
      },
      {
        id: 235,
        functionality: "Advance",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 236,
        functionality: "Bill",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            id: 6,
            isChecked: true,
            isAction: true,
            permission: "Cancel",
          },
        ],
      },
      {
        id: 237,
        functionality: "Bill Settlement",
        isChecked: true,
        subFunction: [
          {
            id: 238,
            functionality: "Self",
            isChecked: true,
            permissions: [
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
              {
                isChecked: true,
                isAction: false,
                permission: "SettleBill",
                id: 11,
              },
            ],
          },
          {
            id: 239,
            functionality: "Company",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 2,
                permission: "View",
                isAction: false,
              },
              {
                isChecked: true,
                isAction: false,
                permission: "SettleBill",
                id: 11,
              },
              {
                isChecked: true,
                permission: "Apply Discount",
                id: 30,
                isAction: false,
              },
            ],
          },
          {
            id: 240,
            functionality: "Concession Manager",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Previous Concession",
                isChecked: true,
                isAction: false,
                id: 28,
              },
            ],
          },
        ],
      },
      {
        id: 241,
        functionality: "Refund",
        isChecked: true,
        subFunction: [
          {
            id: 242,
            functionality: "Advance",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 243,
            functionality: "Bill",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
        ],
      },
      {
        id: 244,
        functionality: "Daily Collection",
        isChecked: true,
      },
      {
        id: 245,
        functionality: "Bill Estimation",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 246,
        functionality: "Vaccine Details",
        isChecked: true,
      },
      {
        id: 247,
        functionality: "Certificate",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 248,
        functionality: "Patient Engagement",
        isChecked: true,
        subFunction: [
          {
            id: 249,
            functionality: "Patient Engagement Registration",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                isChecked: true,
                id: 2,
                permission: "View",
                isAction: false,
              },
            ],
          },
          {
            id: 250,
            functionality: "Patient Engagement Order",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                isChecked: true,
                id: 2,
                permission: "View",
                isAction: false,
              },
            ],
          },
        ],
      },
      {
        id: 251,
        functionality: "Patient Enquiry",
        isChecked: true,
      },
      {
        id: 252,
        functionality: "Patient Package Schedule Chart",
        isChecked: true,
      },
    ],
  },
  {
    id: 253,
    functionality: "Emergency",
    isChecked: true,
    subFunction: [
      {
        id: 254,
        functionality: "Registration",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: false,
            isAction: false,
            permission: "Print",
          },
          {
            id: 6,
            isChecked: true,
            isAction: true,
            permission: "Cancel",
          },
          {
            isChecked: true,
            permission: "OPD Treatement Record",
            isAction: true,
            id: 12,
          },
          {
            isChecked: true,
            isAction: true,
            id: 13,
            permission: "ETU Case Sheet",
          },
          {
            isChecked: true,
            isAction: true,
            id: 14,
            permission: "MLC",
          },
          {
            isChecked: true,
            isAction: true,
            id: 29,
            permission: "Transfer To Ward",
          },
        ],
      },
      {
        id: 255,
        functionality: "Flu Patient Registration",
        isChecked: true,
      },
      {
        id: 256,
        functionality: "ETU Registers",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            permission: "Edit",
            isChecked: true,
            id: 3,
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            isChecked: true,
            id: 40,
            isAction: false,
            permission: "Print All",
          },
        ],
      },
      {
        id: 257,
        functionality: "OPD Case Paper Register",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
        ],
      },
      {
        id: 258,
        functionality: "NIH Stroke Scale Scoring Sheet",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
        ],
      },
    ],
  },
  {
    id: 259,
    functionality: "In Patients",
    isChecked: true,
    subFunction: [
      {
        id: 260,
        functionality: "Reception",
        isChecked: true,
        subFunction: [
          {
            id: 261,
            functionality: "Admission",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                isChecked: true,
                isAction: true,
                id: 2,
                permission: "View",
              },
              {
                permission: "Edit",
                isChecked: true,
                isAction: true,
                id: 3,
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
              {
                id: 6,
                isChecked: true,
                isAction: true,
                permission: "Cancel",
              },
              {
                isChecked: true,
                isAction: true,
                id: 14,
                permission: "MLC",
              },
              {
                permission: "Bill",
                isChecked: true,
                isAction: true,
                id: 16,
              },
              {
                permission: "Receive Patient",
                isChecked: true,
                isAction: true,
                id: 31,
              },
              {
                isChecked: true,
                isAction: true,
                permission: "Transfer Bed",
                id: 32,
              },
              {
                permission: "Charges",
                isChecked: true,
                isAction: true,
                id: 33,
              },
              {
                isChecked: true,
                isAction: true,
                id: 35,
                permission: "Barcode",
              },
              {
                isChecked: true,
                isAction: true,
                id: 36,
                permission: "Discharge Initiate",
              },
              {
                id: 37,
                isChecked: true,
                isAction: true,
                permission: "Disc. Summary",
              },
              {
                id: 38,
                permission: "Clinical Chart",
                isChecked: true,
                isAction: true,
              },
            ],
          },
          {
            id: 262,
            functionality: "Bed Transfer",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 263,
            functionality: "Discharge",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 6,
                isChecked: true,
                isAction: true,
                permission: "Cancel",
              },
              {
                id: 39,
                isChecked: true,
                isAction: true,
                permission: "Allow To Go Slip",
              },
            ],
          },
          {
            id: 264,
            functionality: "Discharge Summary",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
              {
                permission: "Finalise",
                isChecked: true,
                isAction: false,
                id: 15,
              },
            ],
          },
          {
            id: 265,
            functionality: "Bed Release",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                isAction: true,
                id: 1,
                permission: "Create",
              },
            ],
          },
          {
            id: 266,
            functionality: "Discharge Cancel",
            isChecked: true,
          },
          {
            id: 267,
            functionality: "Secondary Bed Allocation / Release",
            isChecked: true,
          },
          {
            id: 268,
            functionality: "C Form",
            isChecked: true,
          },
          {
            id: 269,
            functionality: "Care Plan",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                isChecked: true,
                id: 3,
                isAction: false,
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
        ],
      },
      {
        id: 270,
        functionality: "Medical Records",
        isChecked: true,
      },
      {
        id: 271,
        functionality: "Advance",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 272,
        functionality: "Charges",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 4,
            isAction: false,
            permission: "Delete",
          },
          {
            permission: "Bill",
            isChecked: true,
            isAction: false,
            id: 16,
          },
          {
            isChecked: true,
            id: 46,
            permission: "Convert To Package",
            isAction: false,
          },
          {
            isChecked: true,
            id: 56,
            permission: "Move To Charges",
            isAction: false,
          },
        ],
      },
      {
        id: 273,
        functionality: "Bill",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            isChecked: true,
            id: 17,
            permission: "Discharge",
            isAction: false,
          },
          {
            isChecked: true,
            id: 18,
            isAction: false,
            permission: "Advance",
          },
          {
            permission: "View Charges",
            id: 55,
            isChecked: true,
            isAction: false,
          },
          {
            isChecked: true,
            id: 56,
            permission: "Move To Charges",
            isAction: false,
          },
          {
            isChecked: true,
            isAction: false,
            id: 57,
            permission: "Move To Self",
          },
          {
            isChecked: true,
            permission: "Move To Company",
            isAction: false,
            id: 58,
          },
        ],
      },
      {
        id: 274,
        functionality: "Charge Details",
        isChecked: true,
      },
      {
        id: 275,
        functionality: "Bill Settlement",
        isChecked: true,
        subFunction: [
          {
            id: 276,
            functionality: "Self",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                isAction: true,
                id: 1,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: true,
                permission: "Print",
              },
              {
                isChecked: true,
                isAction: false,
                permission: "SettleBill",
                id: 11,
              },
            ],
          },
          {
            id: 277,
            functionality: "Company",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 2,
                permission: "View",
                isAction: false,
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
              {
                isChecked: true,
                isAction: false,
                permission: "SettleBill",
                id: 11,
              },
              {
                isChecked: true,
                permission: "Apply Discount",
                id: 30,
                isAction: false,
              },
            ],
          },
          {
            id: 278,
            functionality: "Concession Manager",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Previous Concession",
                isChecked: true,
                isAction: false,
                id: 28,
              },
            ],
          },
        ],
      },
      {
        id: 279,
        functionality: "Refund",
        isChecked: true,
        subFunction: [
          {
            id: 280,
            functionality: "Advance",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: true,
                permission: "Print",
              },
            ],
          },
          {
            id: 281,
            functionality: "Bill",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: true,
                permission: "Print",
              },
            ],
          },
        ],
      },
      {
        id: 282,
        functionality: "Patient Transfer",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            permission: "Edit",
            isChecked: true,
            isAction: true,
            id: 3,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            permission: "Finalise",
            isChecked: true,
            isAction: false,
            id: 15,
          },
        ],
      },
      {
        id: 283,
        functionality: "IPD Bill Edition",
        isChecked: true,
      },
      {
        id: 284,
        functionality: "ETU Case Sheet",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            permission: "Edit",
            isChecked: true,
            id: 3,
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 285,
        functionality: "Drug Administrator Order",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
        ],
      },
      {
        id: 286,
        functionality: "Chemo Sheet",
        isChecked: true,
      },
      {
        id: 287,
        functionality: "Narcotic Drug Form",
        isChecked: true,
      },
    ],
  },
  {
    id: 288,
    functionality: "Nursing Station",
    isChecked: true,
    subFunction: [
      {
        id: 289,
        functionality: "Admitted Patients",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            isAction: true,
            id: 2,
            permission: "View",
          },
          {
            permission: "Edit",
            isChecked: true,
            isAction: true,
            id: 3,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
          {
            isChecked: true,
            isAction: true,
            id: 14,
            permission: "MLC",
          },
          {
            permission: "Receive Patient",
            isChecked: true,
            isAction: true,
            id: 31,
          },
          {
            isChecked: true,
            isAction: true,
            permission: "Transfer Bed",
            id: 32,
          },
          {
            permission: "Charges",
            isChecked: true,
            isAction: true,
            id: 33,
          },
          {
            isChecked: true,
            isAction: true,
            id: 35,
            permission: "Barcode",
          },
          {
            isChecked: true,
            isAction: true,
            id: 36,
            permission: "Discharge Initiate",
          },
          {
            id: 37,
            isChecked: true,
            isAction: true,
            permission: "Disc. Summary",
          },
          {
            id: 38,
            permission: "Clinical Chart",
            isChecked: true,
            isAction: true,
          },
        ],
      },
      {
        id: 290,
        functionality: "In Patient Issue",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            isChecked: true,
            permission: "Indent List",
            id: 45,
            isAction: false,
          },
        ],
      },
      {
        id: 291,
        functionality: "In Patient Return",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 292,
        functionality: "Material Consumption",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 293,
        functionality: "Medical Records",
        isChecked: true,
      },
      {
        id: 294,
        functionality: "Charges",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 4,
            isAction: false,
            permission: "Delete",
          },
          {
            permission: "Bill",
            isChecked: true,
            isAction: false,
            id: 16,
          },
          {
            isChecked: true,
            id: 46,
            permission: "Convert To Package",
            isAction: false,
          },
          {
            isChecked: true,
            id: 56,
            permission: "Move To Charges",
            isAction: false,
          },
        ],
      },
      {
        id: 295,
        functionality: "File Linking",
        isChecked: true,
      },
      {
        id: 296,
        functionality: "Drug Administrator Chart(Inventory)",
        isChecked: true,
        permissions: [
          {
            permission: "Edit",
            isChecked: true,
            isAction: true,
            id: 3,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
          {
            id: 6,
            isChecked: true,
            isAction: true,
            permission: "Cancel",
          },
          {
            isChecked: true,
            id: 49,
            isAction: false,
            permission: "Drug",
          },
          {
            isChecked: true,
            id: 50,
            isAction: false,
            permission: "IV Fluids",
          },
          {
            isChecked: true,
            id: 51,
            permission: "IV Flow",
            isAction: false,
          },
          {
            isChecked: true,
            permission: "Log File",
            id: 52,
            isAction: false,
          },
          {
            id: 53,
            isChecked: true,
            isAction: true,
            permission: "Stop Drug",
          },
          {
            id: 54,
            isChecked: true,
            isAction: true,
            permission: "Reschedule",
          },
        ],
      },
      {
        id: 297,
        functionality: "Code Blue Form",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
          {
            isChecked: true,
            isAction: false,
            permission: "Order Sheet",
            id: 48,
          },
        ],
      },
      {
        id: 298,
        functionality: "Dietetian Module",
        isChecked: true,
        permissions: [
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            isChecked: true,
            id: 19,
            isAction: false,
            permission: "Reject",
          },
          {
            isChecked: true,
            permission: "Approve",
            id: 20,
            isAction: false,
          },
          {
            isChecked: true,
            isAction: false,
            id: 47,
            permission: "Accept",
          },
        ],
      },
      {
        id: 299,
        functionality: "Diet Chart Details",
        isChecked: true,
      },
      {
        id: 300,
        functionality: "Diet Order",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 301,
        functionality: "Initial Nursing Assessment",
        isChecked: true,
      },
      {
        id: 302,
        functionality: "Admission/Discharge Register",
        isChecked: true,
      },
      {
        id: 303,
        functionality: "Sample Collection  For Color Tube Sample",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
        ],
      },
      {
        id: 304,
        functionality: "Intial Assessment Form In NewBorn",
        isChecked: true,
      },
      {
        id: 305,
        functionality: "Phlebitis Score (VIP Score)",
        isChecked: true,
      },
      {
        id: 306,
        functionality: "Nursing Superwisor",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
        ],
      },
      {
        id: 307,
        functionality: "Nursing Night Report",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
        ],
      },
      {
        id: 308,
        functionality: "Clinical Care Chart",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
          {
            isChecked: true,
            isAction: false,
            permission: "Order Sheet",
            id: 48,
          },
        ],
      },
      {
        id: 309,
        functionality: "Ventilator Bundle",
        isChecked: true,
      },
      {
        id: 310,
        functionality: "Dental Examination Chart",
        isChecked: true,
      },
      {
        id: 311,
        functionality: "MRD Check List",
        isChecked: true,
      },
      {
        id: 312,
        functionality: "DIVA Tool Score",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
        ],
      },
      {
        id: 313,
        functionality: "Needle Stick Injury Form",
        isChecked: true,
      },
    ],
  },
  {
    id: 314,
    functionality: "Op. Theater",
    isChecked: true,
    subFunction: [
      {
        id: 315,
        functionality: "Reservation",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            id: 2,
            permission: "View",
            isAction: false,
          },
          {
            permission: "Edit",
            isChecked: true,
            id: 3,
            isAction: false,
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
          {
            id: 6,
            isChecked: true,
            isAction: true,
            permission: "Cancel",
          },
          {
            permission: "Add Consent",
            isChecked: true,
            isAction: false,
            id: 44,
          },
        ],
      },
      {
        id: 316,
        functionality: "Un Reservation",
        isChecked: true,
      },
      {
        id: 317,
        functionality: "Consent",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            id: 5,
            isChecked: true,
            isAction: false,
            permission: "Print",
          },
        ],
      },
      {
        id: 318,
        functionality: "OT Details",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
          {
            isChecked: true,
            isAction: true,
            id: 2,
            permission: "View",
          },
          {
            permission: "Edit",
            isChecked: true,
            isAction: true,
            id: 3,
          },
          {
            id: 5,
            isChecked: true,
            isAction: true,
            permission: "Print",
          },
          {
            id: 6,
            isChecked: true,
            isAction: true,
            permission: "Cancel",
          },
          {
            permission: "OT Issue Item",
            isChecked: true,
            isAction: true,
            id: 26,
          },
        ],
      },
      {
        id: 319,
        functionality: "Pre-Op Instructions",
        isChecked: true,
      },
      {
        id: 320,
        functionality: "Post-Op Instructions",
        isChecked: true,
      },
      {
        id: 321,
        functionality: "Office Permission",
        isChecked: true,
      },
      {
        id: 322,
        functionality: "Office Permission Status",
        isChecked: true,
      },
      {
        id: 323,
        functionality: "Office Permission OT",
        isChecked: true,
      },
      {
        id: 324,
        functionality: "Pending Implant Patient Details",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
        ],
      },
      {
        id: 325,
        functionality: "OT Reservation Details",
        isChecked: true,
      },
      {
        id: 571,
        functionality: "OT Details Create",
        isChecked: true,
      },
      {
        id: 572,
        functionality: "OT Issued Items",
        isChecked: true,
        permissions: [
          {
            isChecked: true,
            id: 1,
            isAction: false,
            permission: "Create",
          },
        ],
      },
    ],
  },
  {
    id: 326,
    functionality: "Investigations",
    isChecked: true,
    subFunction: [
      {
        id: 327,
        functionality: "Pathology",
        isChecked: true,
        subFunction: [
          {
            id: 328,
            functionality: "Work Order Generation",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 329,
            functionality: "Sample Collection",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 330,
            functionality: "Work Order View",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: true,
                permission: "Print",
              },
              {
                id: 6,
                isChecked: true,
                isAction: true,
                permission: "Cancel",
              },
              {
                id: 21,
                isChecked: true,
                isAction: false,
                permission: "Authorise",
              },
              {
                isChecked: true,
                isAction: true,
                id: 35,
                permission: "Barcode",
              },
              {
                isChecked: true,
                isAction: true,
                permission: "Medicine",
                id: 41,
              },
              {
                isChecked: true,
                permission: "Queue",
                isAction: false,
                id: 42,
              },
            ],
          },
          {
            id: 331,
            functionality: "Report Delivery",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 332,
            functionality: "Work Order Status",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
        ],
      },
      {
        id: 333,
        functionality: "Radiology",
        isChecked: true,
        subFunction: [
          {
            id: 334,
            functionality: "Work Order Generation",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 335,
            functionality: "Work Order View",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: true,
                permission: "Print",
              },
              {
                id: 6,
                isChecked: true,
                isAction: true,
                permission: "Cancel",
              },
              {
                id: 21,
                isChecked: true,
                isAction: false,
                permission: "Authorise",
              },
              {
                isChecked: true,
                permission: "Queue",
                isAction: false,
                id: 42,
              },
              {
                isChecked: true,
                isAction: false,
                permission: "Schedule",
                id: 43,
              },
            ],
          },
          {
            id: 336,
            functionality: "Report Delivery",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
        ],
      },
      {
        id: 337,
        functionality: "Physiotheropy",
        isChecked: true,
        subFunction: [
          {
            id: 338,
            functionality: "Work Order Generation",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 339,
            functionality: "Work Order View",
            isChecked: true,
            permissions: [
              {
                id: 6,
                isChecked: true,
                isAction: false,
                permission: "Cancel",
              },
              {
                permission: "Send Whatsapp",
                id: 23,
                isChecked: true,
                isAction: false,
              },
            ],
          },
          {
            id: 340,
            functionality: "General Assessment",
            isChecked: false,
          },
        ],
      },
      {
        id: 341,
        functionality: "Quick View",
        isChecked: false,
      },
    ],
  },
  {
    id: 342,
    functionality: "Inventory",
    isChecked: true,
    subFunction: [
      {
        id: 343,
        functionality: "General Store",
        isChecked: true,
        subFunction: [
          {
            id: 344,
            functionality: "Opening Balance",
            isChecked: true,
          },
          {
            id: 345,
            isChecked: true,
          },
          {
            id: 346,
            functionality: "Patient Indent",
            isChecked: true,
            permissions: [
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
              {
                permission: "Close",
                isChecked: false,
                isAction: false,
                id: 25,
              },
            ],
          },
          {
            id: 347,
            functionality: "Store Indent",
            isChecked: true,
          },
          {
            id: 348,
            isChecked: true,
          },
          {
            id: 349,
            functionality: "Purchase Order",
            isChecked: true,
            permissions: [
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
              {
                id: 24,
                permission: "Verify",
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 350,
            functionality: "GRN",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 351,
            functionality: "GRN Return",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 352,
            isChecked: true,
          },
          {
            id: 353,
            functionality: "Work Order",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 354,
            functionality: "Work Order GRN",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 355,
            functionality: "Goods Dispatch OUT",
            isChecked: true,
          },
          {
            id: 356,
            functionality: "Goods Dispatch IN",
            isChecked: true,
          },
          {
            id: 357,
            functionality: "Scrap Sale",
            isChecked: true,
          },
          {
            id: 358,
            isChecked: true,
          },
          {
            id: 359,
            functionality: "Issue To Department",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 360,
            functionality: "Return From Department",
            isChecked: true,
          },
          {
            id: 361,
            isChecked: true,
          },
          {
            id: 362,
            functionality: "Material Consumption",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 363,
            isChecked: true,
          },
          {
            id: 364,
            functionality: "Stock Adjustment",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 365,
            functionality: "MRP Adjustment",
            isChecked: true,
          },
          {
            id: 366,
            functionality: "Expiry Adjustment",
            isChecked: true,
          },
          {
            id: 367,
            functionality: "PO (Multiple)",
            isChecked: true,
            permissions: [
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
              {
                id: 6,
                isChecked: false,
                isAction: false,
                permission: "Cancel",
              },
              {
                id: 24,
                permission: "Verify",
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 368,
            functionality: "GRN Return (Multiple)",
            isChecked: true,
          },
          {
            id: 369,
            functionality: "Work Order(Multiple)",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
        ],
      },
      {
        id: 370,
        functionality: "Pharmacy",
        isChecked: true,
        subFunction: [
          {
            id: 371,
            functionality: "Opening Balance",
            isChecked: true,
          },
          {
            id: 372,
            isChecked: true,
          },
          {
            id: 373,
            functionality: "Store Indent",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                permission: "Approve",
                id: 20,
                isChecked: false,
                isAction: false,
              },
              {
                id: 24,
                permission: "Verify",
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 374,
            isChecked: true,
          },
          {
            id: 375,
            functionality: "Issue To Department",
            isChecked: true,
          },
          {
            id: 376,
            functionality: "Return From Department",
            isChecked: true,
            permissions: [
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 377,
            isChecked: true,
          },
          {
            id: 378,
            functionality: "Counter Sale",
            isChecked: true,
            permissions: [
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 379,
            functionality: "Counter Sale Return",
            isChecked: true,
            permissions: [
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 380,
            functionality: "In Patient Issue",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 381,
            functionality: "In Patient Return",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 382,
            functionality: "In Patient Return Approval",
            isChecked: true,
          },
          {
            id: 383,
            isChecked: true,
          },
          {
            id: 384,
            functionality: "Pharmacy Self Settlement",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 385,
            functionality: "Company Settlement",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 386,
            isChecked: true,
          },
          {
            id: 387,
            functionality: "Expired Items Return",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 388,
            isChecked: true,
          },
          {
            id: 389,
            functionality: "Stock Adjustment",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 390,
            functionality: "MRP Adjustment",
            isChecked: true,
          },
          {
            id: 391,
            functionality: "Expiry Adjustment",
            isChecked: true,
          },
          {
            id: 392,
            functionality: "Counter Sale Return New",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 393,
            isChecked: true,
          },
          {
            id: 394,
            functionality: "Store Inventory (Min/Max)",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
            ],
          },
        ],
      },
      {
        id: 395,
        functionality: "Purchase",
        isChecked: true,
        subFunction: [
          {
            id: 396,
            functionality: "Enquiry",
            isChecked: true,
            permissions: [
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 397,
            functionality: "Quotation",
            isChecked: true,
            permissions: [
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 398,
            functionality: "Purchase Approvals",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 399,
            functionality: "Party Settlement",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
            ],
          },
          {
            id: 400,
            functionality: "Supplier Advance",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
            ],
          },
          {
            id: 401,
            functionality: "Amendment (GRN / WO GRN)",
            isChecked: true,
          },
        ],
      },
      {
        id: 402,
        functionality: "BarCode Print",
        isChecked: true,
      },
      {
        id: 403,
        functionality: "QR Barcode Print",
        isChecked: true,
      },
      {
        id: 404,
        functionality: "CSSD",
        isChecked: true,
        subFunction: [
          {
            id: 405,
            functionality: "Kit Formation / Reformation",
            isChecked: true,
          },
          {
            id: 406,
            functionality: "Sterilization",
            isChecked: true,
          },
          {
            id: 407,
            functionality: "Indent",
            isChecked: true,
          },
          {
            id: 408,
            functionality: "Kit Issue",
            isChecked: true,
          },
          {
            id: 409,
            functionality: "Kit Return",
            isChecked: true,
          },
          {
            id: 410,
            functionality: "Kit Dismantling",
            isChecked: true,
          },
          {
            id: 411,
            functionality: "CSSD Material Consumption",
            isChecked: true,
          },
          {
            id: 412,
            functionality: "Test Results",
            isChecked: true,
          },
        ],
      },
    ],
  },
  {
    id: 413,
    functionality: "Misc. Services",
    isChecked: true,
    subFunction: [
      {
        id: 414,
        functionality: "Housekeeping",
        isChecked: true,
        subFunction: [
          {
            id: 415,
            functionality: "Linen Management System",
            isChecked: true,
          },
        ],
      },
      {
        id: 416,
        functionality: "Equipment",
        isChecked: true,
        subFunction: [
          {
            id: 417,
            functionality: "Equipment Information",
            isChecked: true,
            subFunction: [
              {
                id: 418,
                functionality: "Equipment Master",
                isChecked: true,
              },
              {
                id: 419,
                functionality: "Equipment Definition",
                isChecked: true,
              },
              {
                id: 420,
                functionality: "Preventive Maintenance",
                isChecked: true,
              },
            ],
          },
          {
            id: 421,
            functionality: "Equipment Issue To Department",
            isChecked: true,
          },
          {
            id: 422,
            functionality: "Equipment Return From Department",
            isChecked: true,
          },
          {
            id: 423,
            functionality: "Equipment Complaint Information",
            isChecked: true,
            subFunction: [
              {
                id: 424,
                functionality: "Complaint Details",
                isChecked: true,
              },
              {
                id: 425,
                functionality: "Complaint Resolution",
                isChecked: true,
              },
            ],
          },
          {
            id: 426,
            functionality: "Equipment Gate Pass Information",
            isChecked: true,
            subFunction: [
              {
                id: 427,
                functionality: "Equipment Gate Pass Issue",
                isChecked: true,
              },
              {
                id: 428,
                functionality: "Equipment Gate Pass Receipt",
                isChecked: true,
              },
            ],
          },
          {
            id: 429,
            functionality: "Equipment Scrap Information",
            isChecked: true,
          },
          {
            id: 430,
            functionality: "Equipment Check List",
            isChecked: true,
          },
        ],
      },
      {
        id: 431,
        functionality: "PatientFeedbackManagement",
        isChecked: true,
        subFunction: [
          {
            id: 432,
            functionality: "PatientFeedback",
            isChecked: true,
          },
          {
            id: 433,
            functionality: "SearchPatientFeedBack",
            isChecked: true,
          },
        ],
      },
      {
        id: 434,
        functionality: "Document Management System",
        isChecked: true,
      },
      {
        id: 435,
        functionality: "BioMedical Waste Management",
        isChecked: true,
        subFunction: [
          {
            id: 436,
            functionality: "Bag Type Transaction",
            isChecked: true,
          },
        ],
      },
      {
        id: 437,
        functionality: "Parking Management",
        isChecked: true,
      },
    ],
  },
  {
    id: 438,
    functionality: "Accounting",
    isChecked: true,
    subFunction: [
      {
        id: 439,
        functionality: "Tally Interface",
        isChecked: true,
      },
      {
        id: 440,
        functionality: "Misc Income",
        isChecked: true,
      },
      {
        id: 441,
        functionality: "Misc Expense",
        isChecked: true,
      },
      {
        id: 442,
        functionality: "Tally Interface (CFO)",
        isChecked: true,
      },
      {
        id: 443,
        functionality: "Finance Reports",
        isChecked: true,
      },
      {
        id: 444,
        functionality: "Contra Voucher",
        isChecked: true,
      },
      {
        id: 445,
        functionality: "Multiple  Voucher",
        isChecked: true,
      },
    ],
  },
  {
    id: 446,
    functionality: "Reports",
    isChecked: true,
    subFunction: [
      {
        id: 447,
        functionality: "Billing Reports",
        isChecked: true,
      },
      {
        id: 448,
        functionality: "MIS Reports (OPD  IPD)",
        isChecked: true,
      },
      {
        id: 449,
        functionality: "MIS Reports (Inventory)",
        isChecked: true,
      },
      {
        id: 450,
        functionality: "Inventory Reports (General)",
        isChecked: true,
      },
      {
        id: 451,
        functionality: "I.P.D. Reports",
        isChecked: true,
      },
      {
        id: 452,
        functionality: "Equipment Reports",
        isChecked: true,
        subFunction: [
          {
            id: 453,
            functionality: "Equipment Register",
            isChecked: true,
          },
          {
            id: 454,
            functionality: "Equipment AMC Detail Report",
            isChecked: true,
          },
          {
            id: 455,
            functionality: "Equipment Insurance Details",
            isChecked: true,
          },
          {
            id: 456,
            functionality: "Equipment Details Report",
            isChecked: true,
          },
        ],
      },
      {
        id: 457,
        functionality: "Investigation Reports",
        isChecked: true,
        subFunction: [
          {
            id: 458,
            functionality: "Pathology",
            isChecked: true,
          },
        ],
      },
      {
        id: 459,
        functionality: "Inventory Query Lists",
        isChecked: true,
        subFunction: [
          {
            id: 460,
            functionality: "Indent List",
            isChecked: true,
          },
          {
            id: 461,
            isChecked: true,
          },
          {
            id: 462,
            functionality: "Purchase Order",
            isChecked: true,
          },
          {
            id: 463,
            functionality: "Delivery Challan List",
            isChecked: true,
          },
          {
            id: 464,
            functionality: "GRN List",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                id: 3,
                isChecked: false,
                isAction: false,
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
              {
                id: 24,
                permission: "Verify",
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 465,
            functionality: "GRN Return List",
            isChecked: true,
            permissions: [
              {
                id: 1,
                isChecked: false,
                isAction: false,
                permission: "Create",
              },
              {
                id: 5,
                isChecked: false,
                isAction: false,
                permission: "Print",
              },
              {
                id: 24,
                permission: "Verify",
                isChecked: false,
                isAction: false,
              },
            ],
          },
          {
            id: 466,
            functionality: "Expired Items Return List",
            isChecked: true,
          },
          {
            id: 467,
            isChecked: true,
          },
          {
            id: 468,
            functionality: "Issue To Department List",
            isChecked: true,
          },
          {
            id: 469,
            functionality: "Return From Department List",
            isChecked: true,
          },
          {
            id: 470,
            isChecked: true,
          },
          {
            id: 471,
            functionality: "Drug Estimation List",
            isChecked: true,
          },
          {
            id: 472,
            functionality: "Drug Concession List",
            isChecked: true,
          },
          {
            id: 473,
            isChecked: true,
          },
          {
            id: 474,
            functionality: "Counter Sales List",
            isChecked: true,
          },
          {
            id: 475,
            functionality: "Counter Sale Return List",
            isChecked: true,
          },
          {
            id: 476,
            functionality: "InPatient Issue List",
            isChecked: true,
          },
          {
            id: 477,
            functionality: "InPatient Return List",
            isChecked: true,
          },
          {
            id: 478,
            isChecked: true,
          },
          {
            id: 479,
            functionality: "Stock Adjustment List",
            isChecked: true,
          },
          {
            id: 480,
            functionality: "MRP Adjustment List",
            isChecked: true,
          },
          {
            id: 481,
            functionality: "Expiry Adjustment List",
            isChecked: true,
          },
          {
            id: 482,
            isChecked: true,
          },
          {
            id: 483,
            functionality: "Item Factorization List",
            isChecked: true,
          },
        ],
      },
      {
        id: 484,
        functionality: "EMRD Reports",
        isChecked: true,
      },
      {
        id: 485,
        functionality: "CSSD Reports",
        isChecked: true,
        subFunction: [
          {
            id: 486,
            functionality: "Indent List",
            isChecked: true,
          },
          {
            id: 487,
            isChecked: true,
          },
          {
            id: 488,
            functionality: "Issue List",
            isChecked: true,
          },
          {
            id: 489,
            functionality: "Return List",
            isChecked: true,
          },
        ],
      },
      {
        id: 490,
        functionality: "All Pathology Reports",
        isChecked: true,
      },
      {
        id: 491,
        functionality: "NABH Reports",
        isChecked: true,
      },
      {
        id: 492,
        functionality: "MIS Reports(Department)",
        isChecked: true,
      },
      {
        id: 493,
        functionality: "Denomination of Cash",
        isChecked: true,
      },
      {
        id: 494,
        functionality: "Purchase Approval",
        isChecked: true,
      },
      {
        id: 495,
        functionality: "Purchase Approval Distribution",
        isChecked: true,
      },
      {
        id: 496,
        functionality: "Purchase Approval Feedback",
        isChecked: true,
      },
    ],
  },
  {
    id: 497,
    functionality: "Administration",
    isChecked: true,
    subFunction: [
      {
        id: 498,
        functionality: "Right Template",
        isChecked: true,
      },
      {
        id: 499,
        functionality: "Add Change User",
        isChecked: true,
      },
      {
        id: 500,
        functionality: "Change Password",
        isChecked: true,
      },
      {
        id: 501,
        functionality: "Configuration",
        isChecked: true,
      },
      {
        id: 502,
        functionality: "Printer Settings",
        isChecked: true,
      },
      {
        id: 503,
        functionality: "Auto Charges",
        isChecked: true,
      },
      {
        id: 504,
        functionality: "Cancellation",
        isChecked: true,
        subFunction: [
          {
            id: 505,
            functionality: "Bill",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 2,
                permission: "View",
                isAction: false,
              },
              {
                permission: "Edit",
                isChecked: true,
                id: 3,
                isAction: false,
              },
              {
                id: 5,
                isChecked: true,
                isAction: false,
                permission: "Print",
              },
              {
                permission: "Bill",
                isChecked: true,
                isAction: false,
                id: 16,
              },
              {
                isChecked: true,
                id: 17,
                permission: "Discharge",
                isAction: false,
              },
              {
                isChecked: true,
                id: 18,
                isAction: false,
                permission: "Advance",
              },
            ],
          },
          {
            id: 506,
            functionality: "Receipt Cancellation",
            isChecked: true,
          },
        ],
      },
      {
        id: 507,
        functionality: "Pathology Right Template",
        isChecked: true,
      },
      {
        id: 508,
        functionality: "Issue Smart Card",
        isChecked: true,
      },
      {
        id: 509,
        functionality: "SMS",
        isChecked: true,
        subFunction: [
          {
            id: 510,
            functionality: "SMS Template",
            isChecked: true,
          },
          {
            id: 511,
            functionality: "Received SMS List",
            isChecked: true,
          },
          {
            id: 512,
            functionality: "Send SMS From Excel Sheet",
            isChecked: true,
          },
        ],
      },
      {
        id: 513,
        functionality: "Patient Data Merge",
        isChecked: true,
      },
      {
        id: 514,
        functionality: "Email",
        isChecked: true,
        subFunction: [
          {
            id: 515,
            functionality: "Email Template",
            isChecked: true,
          },
          {
            id: 516,
            functionality: "Send Email",
            isChecked: true,
          },
        ],
      },
      {
        id: 517,
        functionality: "OP",
        isChecked: true,
        subFunction: [
          {
            id: 518,
            functionality: "Back Date Registration",
            isChecked: true,
          },
          {
            id: 519,
            functionality: "Back Date Bill",
            isChecked: true,
          },
        ],
      },
      {
        id: 520,
        functionality: "IP",
        isChecked: true,
        subFunction: [
          {
            id: 521,
            functionality: "Back Date Admission",
            isChecked: true,
          },
          {
            id: 522,
            functionality: "Back Date Charges",
            isChecked: true,
          },
          {
            id: 523,
            functionality: "Back Date Bill",
            isChecked: true,
          },
        ],
      },
      {
        id: 524,
        functionality: "Send SMS",
        isChecked: true,
      },
      {
        id: 525,
        functionality: "Doctor Share Review",
        isChecked: true,
      },
      {
        id: 526,
        functionality: "Doctor Share",
        isChecked: true,
        subFunction: [
          {
            id: 527,
            functionality: "Doctor Share",
            isChecked: true,
          },
          {
            id: 528,
            functionality: "Doctor Addition\\Deduction",
            isChecked: true,
          },
          {
            id: 529,
            functionality: "Doctor Bill",
            isChecked: true,
          },
          {
            id: 530,
            functionality: "Doctor Bill settlement",
            isChecked: true,
          },
        ],
      },
      {
        id: 531,
        functionality: "Refering Doctor Share",
        isChecked: true,
        subFunction: [
          {
            id: 532,
            functionality: "Refer Doctor Share",
            isChecked: true,
          },
          {
            id: 533,
            functionality: "Refer Doctor Addition\\Deduction",
            isChecked: true,
          },
          {
            id: 534,
            functionality: "Refer Doctor Bill",
            isChecked: true,
          },
          {
            id: 535,
            functionality: "Refer Doctor Bill Settlement",
            isChecked: true,
          },
          {
            id: 536,
            functionality: "Refer Update (OPD/IPD)",
            isChecked: true,
          },
        ],
      },
      {
        id: 537,
        functionality: "Mediclaim",
        isChecked: true,
      },
      {
        id: 538,
        functionality: "HR",
        isChecked: true,
        subFunction: [
          {
            id: 539,
            functionality: "Employee Mediclaim Benefit",
            isChecked: true,
          },
        ],
      },
      {
        id: 540,
        functionality: "Incident Reporting HOD",
        isChecked: true,
      },
      {
        id: 541,
        functionality: "Doctor Refferal",
        isChecked: true,
      },
      {
        id: 542,
        functionality: "Screen Sever",
        isChecked: true,
      },
      {
        id: 543,
        functionality: "Refer Doctor Chat",
        isChecked: true,
      },
      {
        id: 566,
        functionality: "User Management",
        isChecked: true,
        subFunction: [
          {
            id: 567,
            functionality: "Assign Functionality",
            isChecked: true,
          },
          {
            id: 568,
            functionality: "Role",
            isChecked: true,
          },
          {
            id: 569,
            functionality: "Manage User",
            isChecked: true,
            permissions: [
              {
                isChecked: true,
                id: 1,
                isAction: false,
                permission: "Create",
              },
              {
                permission: "Edit",
                isChecked: true,
                isAction: true,
                id: 3,
              },
            ],
          },
          {
            id: 570,
            functionality: "Patient Admission Details",
            isChecked: true,
          },
        ],
      },
    ],
  },
  {
    id: 544,
    functionality: "Blood Bank",
    isChecked: true,
    subFunction: [
      {
        id: 545,
        functionality: "Blood Requisition Note",
        isChecked: true,
      },
      {
        id: 546,
        functionality: "Blood Issue Note",
        isChecked: true,
      },
      {
        id: 547,
        functionality: "Blood Requisition Acceptance",
        isChecked: true,
      },
      {
        id: 548,
        functionality: "Blood Issue Acceptance",
        isChecked: true,
      },
      {
        id: 549,
        functionality: "Blood Bag Componant Stock",
        isChecked: true,
      },
      {
        id: 550,
        functionality: "Blood Transfusion Reaction",
        isChecked: true,
      },
      {
        id: 551,
        functionality: "Blood Transfusion Reaction (Discard)",
        isChecked: true,
      },
    ],
  },
  {
    id: 552,
    functionality: "IPF Bill",
    isChecked: true,
    subFunction: [
      {
        id: 553,
        functionality: "IPF Bill",
        isChecked: true,
      },
      {
        id: 554,
        functionality: "Free Billing",
        isChecked: true,
      },
      {
        id: 555,
        functionality: "concentipdopd",
        isChecked: true,
      },
      {
        id: 556,
        functionality: "thumb impression",
        isChecked: true,
      },
    ],
  },
  {
    id: 557,
    functionality: "IVF Details",
    isChecked: true,
    subFunction: [
      {
        id: 558,
        functionality: "IVF",
        isChecked: true,
      },
      {
        id: 559,
        functionality: "Anetenatal",
        isChecked: true,
      },
    ],
  },
  {
    id: 560,
    functionality: "TaskManager",
    isChecked: true,
    subFunction: [
      {
        id: 561,
        functionality: "Help Desk",
        isChecked: true,
      },
      {
        id: 562,
        functionality: "Bed Occupancy",
        isChecked: false,
      },
      {
        id: 563,
        functionality: "Messaging Console",
        isChecked: false,
      },
      {
        id: 564,
        functionality: "Patient Clearance Form",
        isChecked: true,
        permissions: [
          {
            permission: "Patient Clearance",
            isChecked: true,
            isAction: false,
            id: 59,
          },
          {
            isChecked: true,
            permission: "View Documents",
            isAction: false,
            id: 60,
          },
          {
            isChecked: true,
            id: 61,
            isAction: false,
            permission: "Check List",
          },
          {
            isChecked: true,
            permission: "Updation Completed",
            id: 62,
            isAction: false,
          },
          {
            isChecked: true,
            isAction: false,
            id: 63,
            permission: "Allow To Go",
          },
        ],
      },
    ],
  },
  {
    id: 565,
    functionality: "Dashboard",
    isChecked: true,
  },
];
