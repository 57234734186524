import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlertCustom,
  successAlert
} from "../../../../common/components/Toasts/CustomToasts";
import BatchInfoModal from "./BatchInfoModal";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchConcessionAuthority,
  fetchStore,
} from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchAdjustmenReason,
  fetchAdjustmentType,
  postStockAdjustment,
  searchStockAdjustmentItem,
} from "../../../services/generalStore/stockAdjustmentServices/stockAdjustmentServices";
import { itemBatchNumber } from "../../../services/stockAdjustment/StockAdjustmentServices";

const Actions = [
  {
    id: 0,
    action: "Add",
    isAction: false,
  },
];
function StockAdjustment(props) {
  const schema = yup.object().shape({
    store: yup.object().nullable().shape({
      value: yup.string(),
      label: yup.string(),
    }),
    aurthorizedBy: yup.object().nullable().required("Required").shape({
      value: yup.string(),
      label: yup.string(),
    }),

    adjQty: yup.number().required("Required"),
    adjMrp: yup.number().required("Required"),
    adjPurchaseRate: yup.number().required("Required"),
    adjLandRate: yup.number().required("Required"),
    adjGST: yup.number().required("Required"),
  });
  const defaultValues = {
    // store: null,
    aurthorizedBy: null,
    isConsignment: true,
    itemBatchNo: 0,
    adjType: null,
    reasons: null,
    expiryDate: "",
    adjQty: 0,
    adjMrp: 0,
    adjPurchaseRate: 0,
    searchStockAdjustItem: "",
    adjLandRate: 0,
    adjGST: 0,
    remarks: "",
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    register,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // State variables
  const [storeDropdown, setStoreDropdown] = React.useState();
  const [authorizedByDropdown, setAuthorizedByDropdown] = React.useState();
  const [adjustmentTypeDropdown, setAdjustmentTypeDropdown] = React.useState();
  const [
    adjustmentReasonDropdown,
    setAdjustmentReasonDropdown,
  ] = React.useState();
  const [searchString, setSearchString] = React.useState([]);
  const [stockSearchResult, setStockSearchResult] = React.useState(null);
  const [openBatchInfoModal, setOpenBatchInfoModal] = React.useState(false);
  const [selectedBatchData, setSelectedBatchData] = React.useState([]);
  const [batchItemInfoObj, setBatchItemInfoObj] = React.useState(null);
  const [itemName, setItemName] = React.useState();
  const [tempArr, setTempArr] = React.useState([]);
  const [itemId, setItemId] = React.useState([]);
  const [postObj, setPostObj] = React.useState([]);
  const [confirmationOpen, setOpenConfirmation] = React.useState(false);
  const [customError, setCustomError] = React.useState("");
  const [customBatchNoError, setCustomBatchNoError] = React.useState("");
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  // other state variables
  let searchStockAdjustItemVal = getValues("searchStockAdjustItem");
  let itemBatchNoVal = getValues("itemBatchNo");
  let isConsignmentVal = getValues("isConsignment");
  console.log("isConsignmentValisConsignmentVal");
  let location = useLocation();
  console.log("locationlocation", location?.state?.menuId);
  // userinfo get from local storage
  let userInfoString = localStorage.getItem("userInfo");
  let userInfoObj = JSON.parse(userInfoString);
  let storeId = userInfoObj.storeId;

  console.log(
    "searchStockAdjustItemsearchStockAdjustItem",
    searchStockAdjustItemVal
  );
  console.log("privilegeprivilege", privilege);
  // useEffects

  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          console.log("getUserActions", res.result);
          setUserActions(res.result);
        });
  }, []);
  React.useEffect(() => {
    console.log("errors in the form are", errors);
    console.log(
      "setBatchItemInfoObjsetBatchItemInfoObjsetBatchItemInfoObj",
      batchItemInfoObj
    );
  }, [errors, batchItemInfoObj]);

  // function move tab in batch selection
  function findNextTabStop(el) {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    return list[6].focus();
  }

  // function move tab in adjustment type
  function findNextTabStopTwo(el) {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    return list[13]?.focus();
  }

  // other variables

  //Call the services to patch the drop down values
  React.useEffect(() => {
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        setStoreDropdown(res.result);
      });

    //Calling the api to polulate the Concession Authority list dropdown
    fetchConcessionAuthority()
      .then((response) => response.data)
      .then((res) => {
        setAuthorizedByDropdown(res.result);
      });

    // api call adjustment Reason
    fetchAdjustmenReason()
      .then((response) => response.data)
      .then((res) => {
        setAdjustmentReasonDropdown(res.result);
      });

    // api call adjustment type
    fetchAdjustmentType()
      .then((response) => response.data)
      .then((res) => {
        setAdjustmentTypeDropdown(res.result);
      });
  }, []);

  // patch login store to dropdwon by create obj in id,value and label format
  useEffect(() => {
    let storeIdValueLabelObj = {
      id: storeId,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };
    setValue("store", storeIdValueLabelObj);
  }, []);

  // useEffect set the values of selected bactch
  useEffect(() => {
    if (batchItemInfoObj) {
      findNextTabStopTwo();
      setStockSearchResult(batchItemInfoObj);
      let itemName = getValues("searchStockAdjustItem");
      setItemName(itemName);
      console.log("itemNameitemNameitemName", itemName.label);
      setValue("itemBatchNo", batchItemInfoObj.BatchCode);
      setValue(
        "expiryDate",
        batchItemInfoObj.BatchExpDate.toString().replaceAll(
          "T18:30:00.000+00:00",
          ""
        )
      );
      setValue("adjQty", batchItemInfoObj.BalQty);
      setValue("adjMrp", batchItemInfoObj.UnitMRP);
      setValue("adjPurchaseRate", batchItemInfoObj.UnitPurchaseRate);
      setValue("adjLandRate", batchItemInfoObj.UnitLandedRate);
      setValue("adjGST", batchItemInfoObj.VATPercentage);
    }
  }, [batchItemInfoObj, setValue]);

  // batch modal open & close enter click in batch modal input field
  const handleOpenBatchModal = () => {
    setOpenBatchInfoModal(true);
  };
  const handleCloseBatchModal = () => {
    setOpenBatchInfoModal(false);
  };

  // search service for item search
  const handleChange = (autoSearchString) => {
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfo);

    let searchObj = {
      deptStoreId: userInfoObj.storeId,
      isConsignment: 1,
      itemCode: "%",
      itemName: "%",
      deptStore: 1,
      searchString: autoSearchString,
    };
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      searchStockAdjustmentItem(searchObj)
        .then((response) => response.data)
        .then((res) => {
          setSearchString(res.result);
        });
    }
  };

  // handle confirmation on add button click
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  // Add updated stock to table
  function addUpdatedStockToTable() {
    let searchStockAdjustItemVal = getValues("searchStockAdjustItem");
    if (searchStockAdjustItemVal === "" || searchStockAdjustItemVal === null) {
      setCustomError("Search Item For Stock Adjustment ");
    }
    if (itemBatchNoVal === 0 || itemBatchNoVal === null) {
      setCustomBatchNoError("No Item For Stock Adjustment *");
    }
    let adjQtyVal = getValues("adjQty");
    let adjMrpVal = getValues("adjMrp");
    let adjPurchaseRateVal = getValues("adjPurchaseRate");
    let adjLandRateVal = getValues("adjLandRate");
    let adjGSTVal = getValues("adjGST");
    let adjTypeVal = getValues("adjType");
    let reasonsVal = getValues("reasons");

    let Obj = {};
    Obj.ItemName = searchStockAdjustItemVal.label;
    Obj.adjustmentQty = adjQtyVal;
    Obj.adjustmentMrp = adjMrpVal;

    Obj.adjustmentPurRate = adjPurchaseRateVal;
    Obj.adjustmentLanRate = adjLandRateVal;
    Obj.adjustmentVatP = adjGSTVal;
    Obj.adjustmentTypeId = adjTypeVal?.id;
    Obj.adjustmentReasonId = reasonsVal?.id;
    Obj.batchCode = batchItemInfoObj.BatchCode;
    Obj.currentConversion = 0;
    Obj.currentLanRate = batchItemInfoObj?.UnitLandedRate;
    Obj.currentMrp = batchItemInfoObj.UnitMRP;
    Obj.currentPurRate = batchItemInfoObj.UnitPurchaseRate;
    Obj.currentQty = batchItemInfoObj.BalQty;
    Obj.currentVatPur = batchItemInfoObj.VATPercentage;
    Obj.expiryDate = batchItemInfoObj.BatchExpDatePatch?.toString()?.replaceAll(
      ".000+00:00",
      ""
    );

    Obj.itemId = itemId;
    Obj.stockAdjId = 0;

    console.log("ObjObjObjObjObjObjObj", Obj);
    let temp = [...tempArr];
    temp.push(Obj);
    setTempArr(temp);
    reset(defaultValues);
    setStockSearchResult(null);
    setItemName();

    let storeIdValueLabelObj = {
      id: storeId,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };
    setValue("store", storeIdValueLabelObj);
  }
  function onSubmitDataHandler(data) {}

  // post object for stock adjustment in general store
  function saveStockAdjustment() {
    let remarksVal = getValues("remarks");
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let postObj = {
      addedBy: userInfoObj.userId,
      remarks: remarksVal,
      stockAdjustmentDetailsDto: tempArr,
      storeId: userInfoObj.storeId,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    let aurthorizedByVal = getValues("aurthorizedBy");
    console.log(
      "aurthorizedByValaurthorizedByValaurthorizedByValaurthorizedByVal",
      aurthorizedByVal
    );

    if (isValid === true) {
      clearErrors();
      setPostObj(postObj);
      setOpenBackdrop(true);
      handleOpenConfirmation();
    }
  }

  // post stock adjustment
  function addRecord() {
    postStockAdjustment(postObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenConfirmation(false);
        setOpenBackdrop(false);
        setTempArr([]);
      })
      .catch((error) => {
        // errorAlert(error.message);
        setOpenBackdrop(false);
        console.log("errorAlerterrorAlert", error);
      });
  }

  return (
    <div className="mt-12 space-y-2">
      <div className="py-2">
        <p className="text-center text-xl text-gray-700 font-Poppins">
          Stock Adjustment
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-3 gap-x-2">
          <div>
            <DropdownField
              control={control}
              error={errors.store}
              name="store"
              placeholder="Store"
              dataArray={storeDropdown}
              isDisabled={true}
            />
          </div>
          <div>
            <DropdownField
              control={control}
              error={errors.aurthorizedBy}
              name="aurthorizedBy"
              placeholder="AuthorizedBy"
              dataArray={authorizedByDropdown}
            />
          </div>
          <div className="flex space-x-2 items-center">
            <fieldset onChange={() => {}}>
              <CheckBoxField
                control={control}
                name="isConsignment"
                label="Consignment Stock"
                placeholder="
              Consignment Stock"
              />
            </fieldset>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row lg:space-x-5 xl:space-x-3 w-full my-2 ">
          <div className="lg:w-[100%] xl:w-[80%] border rounded bg-white p-2  mt-2 lg:mt-0 lg:pb-0 lg:bg-transparent lg:border-none ">
            <div className="px-4 font-bold text-gray-700 font-Poppins">
              Stock Adjustment List
            </div>
            {tempArr && tempArr.length > 0 ? (
              <>
                {/* <NoApiCommonMasterTable data={tempArr} actions={actions} /> */}
                <CommonDynamicTable
                  data={tempArr}
                  requiredActions={false}
                  tableClass={"rounded xl:h-[400px] lg:h-36   md:h-52"}
                />
                <div className="w-full flex justify-end mb-2 lg:mb-0 py-2">
                  {/* <button
                    type="submit"
                    className="h-9 px-3 text-base font-medium bg-green-600 text-white rounded "
                    onClick={() => {
                      saveStockAdjustment();
                    }}
                  >
                    Save
                  </button> */}
                </div>
              </>
            ) : (
              <div className="text-center py-8">
                <p>No Record Found....</p>
              </div>
            )}
            <div className="text-right py-2">
              {tempArr &&
                tempArr.length > 0 &&
                (userActions && userActions).map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white "
                        label="Save"
                        onClick={() => {
                          saveStockAdjustment();
                          setPrivilege(obj.action);
                        }}
                      />
                    ) : null}
                  </>
                ))}
            </div>
          </div>
          <Divider
            sx={{ color: "#0B83A5", borderRightWidth: 2 }}
            orientation="vertical"
            flexItem
          />
          <div className="lg:w-[50%] xl:w-[35%] bg-white rounded border p-2">
            {customError !== "" ? (
              <div className="text-red-500 flex text-sm">
                <span className="text-xs">{customError} *</span>
              </div>
            ) : (
              ""
            )}
            <div>
              <div className=" z-80 my-2 w-full ">
                <SearchDropdown
                  control={control}
                  name="searchStockAdjustItem"
                  placeholder="Search By Item Name"
                  dataArray={searchString}
                  searchIcon={true}
                  isClearable={true}
                  isSearchable={true}
                  handleInputChange={handleChange}
                  inputRef={{
                    ...register("searchStockAdjustItem", {
                      onChange: (e) => {
                        findNextTabStop(document.activeElement);
                        if (e.target.value !== null) {
                          console.log("");
                          setItemId(e.target.value.id);
                        } else {
                          setSearchString([]);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="grid grid-cols-2 text-sm lg:text-xs xl:text-sm font-semibold gap-3">
                <div className="flex space-x-4 lg:space-x-3 xl:space-x-4 lg:col-span-2 whitespace-nowrap">
                  <h1>Item Name</h1>
                  <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                    : &nbsp;{itemName && itemName.label}
                  </label>
                </div>
                <div className="flex space-x-3 whitespace-nowrap">
                  <h1>Current Qty</h1>
                  <label className="pl-[1px] font-normal">
                    
                    : &nbsp; {stockSearchResult && stockSearchResult.BalQty}
                  </label>
                </div>
                <div className="flex space-x-2 lg:space-x-2 xl:space-x-2 whitespace-nowrap">
                  <h1 className="">Current MRP</h1>
                  <label className=" pl-[2px] lg:pl-[4px] xl:pl-[3px] font-normal">
                    : &nbsp; {stockSearchResult && stockSearchResult.UnitMRP}
                  </label>
                </div>
                <div className="flex space-x-9 lg:space-x-8   whitespace-nowrap">
                  <h1>Pur.Rate </h1>
                  <label className="pl-[1px] lg:pl-[1.5px] xl:pl-[4.5px] font-normal">
                    
                    : &nbsp;
                    {stockSearchResult && stockSearchResult.UnitPurchaseRate}
                  </label>
                </div>
                <div className="flex space-x-2  whitespace-nowrap ">
                  <h1 className="">Landed Rate</h1>
                  <label className="pl-[2px] font-normal">
                    
                    : &nbsp;
                    {stockSearchResult && stockSearchResult.UnitLandedRate}
                  </label>
                </div>
                <div className="flex space-x-12 lg:space-x-9 xl:space-x-12 whitespace-nowrap">
                  <h1>GST %</h1>
                  <label className=" pl-[5px] lg:pl-[11.5px] xl:pl-[4.5px] font-normal">
                    : &nbsp;
                    {stockSearchResult && stockSearchResult.VATPercentage}
                  </label>
                </div>
              </div>
              <div className=" grid grid-cols-3 lg:grid-cols-2 gap-2 mt-1">
                <div>
                  <TextField
                    name="itemBatchNo"
                    {...register("itemBatchNo")}
                    size="small"
                    // type="number"
                    variant="outlined"
                    label="Batch No*"
                    error={errors.itemBatchNo}
                    control={control}
                    onKeyDown={(e) => {
                      console.log("on key press event", e);
                      if (e.key === "Enter") {
                        let getStoreVal = getValues("store");
                        let getItemVal = getValues("searchStockAdjustItem");
                        console.log(
                          "getStoreValgetStoreValgetStoreValgetStoreVal",
                          getItemVal
                        );

                        if (getItemVal && getItemVal) {
                          setOpenBatchInfoModal(true);
                          setCustomBatchNoError("");

                          let currentDate = new Date();
                          let batchPostObj = {
                            batchExpDate: currentDate,
                            isConsignment: isConsignmentVal === true ? 1 : 0,
                            itemId: getItemVal.id,
                            storeId: 3,
                          };
                          console.log(
                            "batchPostObjbatchPostObjbatchPostObj",
                            batchPostObj
                          );

                          itemBatchNumber(batchPostObj).then((response) => {
                            console.log(
                              "The response of getItemBatchNumber is ",
                              response.data.result
                            );

                            console.log(
                              "response.data.result inside getItemBatchNumber is ",
                              response.data.result.length
                            );

                            if (response.data.result.length > 0) {
                              setSelectedBatchData(response.data.result);
                            }

                            if (response.data.result.length === 0) {
                              errorAlertCustom(
                                "Batch Items Are Not Available !."
                              );
                              setOpenConfirmation(false);
                            }
                          });
                        }
                      }
                    }}
                  />
                  {customBatchNoError !== "" ? (
                    <span className="text-red-500  text-xs flex whitespace-nowrap ">
                      {customBatchNoError}*
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="w-full ">
                  {/* <DatePickerField
                    control={control}
                    name="date"
                    label="Date"
                    defaultValue={new Date()}
                    onChange={(newValue) => {
                      setValue("date", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="DD-MM-YYYY"
                  /> */}
                  <InputField
                    control={control}
                    error={errors.expiryDate}
                    name="expiryDate"
                    label=" Expiry Date"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    error={errors.adjQty}
                    name="adjQty"
                    label="Adj Qty"
                    type="number"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    error={errors.adjQty}
                    name="adjMrp"
                    tyep="number"
                    label="Adj MRP"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    error={errors.adjPurchaseRate}
                    name="adjPurchaseRate"
                    label="Adj Pur Rate"
                    inputRef={{
                      ...register("adjPurchaseRate", {
                        onChange: (e) => {
                          // setError("adjPurchaseRate", {
                          //   types: {
                          //     required: "This is required",
                          //     minLength: "This is minLength",
                          //   },
                          // });
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    error={errors.adjLandRate}
                    name="adjLandRate"
                    label="Adj Landed Rate"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    error={errors.adjGST}
                    name="adjGST"
                    label="Adj GST"
                  />
                </div>
                <div>
                  {itemBatchNoVal !== 0 ? (
                    <DropdownField
                      control={control}
                      // error={errors.adjType}
                      name="adjType"
                      placeholder="Adj Type"
                      dataArray={adjustmentTypeDropdown}
                    />
                  ) : null}
                </div>
                <div>
                  {itemBatchNoVal !== 0 ? (
                    <DropdownField
                      control={control}
                      // error={errors.reasons}
                      name="reasons"
                      placeholder="Reasons"
                      dataArray={adjustmentReasonDropdown}
                    />
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-1 py-2">
                <TextField
                  id="outlined-multiline-static"
                  name="remarks"
                  {...register("remarks")}
                  label="Remarks"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                />

                <div className="flex space-x-2 justify-end col-span-3 lg:col-span-1 py-2">
                  {Actions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Add" ? (
                        <div>
                          <CommonButton
                            label="Reset"
                            className="border border-customRed text-customRed"
                            onClick={() => {
                              reset(defaultValues);
                              setStockSearchResult(null);
                              setItemName("");
                              setTempArr([]);
                              let userInfoString = localStorage.getItem(
                                "userInfo"
                              );
                              let userInfoObj = JSON.parse(userInfoString);
                              let storeId = userInfoObj.storeId;
                              let storeIdValueLabelObj = {
                                id: storeId,
                                value: userInfoObj.storeName,
                                label: userInfoObj.storeName,
                              };
                              setValue("store", storeIdValueLabelObj);
                            }}
                          />
                        </div>
                      ) : null}
                      {!obj.isAction && obj.action === "Add" ? (
                        <div>
                          <CommonButton
                            label="Add"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              addUpdatedStockToTable();
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop} />
      {selectedBatchData.length > 0 ? (
        <BatchInfoModal
          handleOpen={handleOpenBatchModal}
          handleClose={handleCloseBatchModal}
          open={openBatchInfoModal}
          setOpen={setOpenBatchInfoModal}
          selectedBatchData={selectedBatchData}
          setBatchItemInfoObj={setBatchItemInfoObj}
          tableData={tempArr}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleCloseConfirmation}
        confirmationSubmitFunc={() => {
          addRecord(postObj);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Stock Adjustment ?"
        confirmationButtonMsg="Save"
      />
    </div>
  );
}

export default StockAdjustment;
