import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";

export default function MultipleItemDetails(props) {
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(allHeaders, ["id"]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.data.map((n) => n);
      props.setSelectedDataPOItemList(newSelected);

      return;
    }
    props.setSelectedDataPOItemList([]);
  };

  const handleClick = (e, row) => {
    const selectedIndex = props.selectedDataPOItemList.indexOf(row);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedDataPOItemList, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedDataPOItemList.slice(1));
    } else if (selectedIndex === props.selectedDataPOItemList.length - 1) {
      newSelected = newSelected.concat(
        props.selectedDataPOItemList.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedDataPOItemList.slice(0, selectedIndex),
        props.selectedDataPOItemList.slice(selectedIndex + 1)
      );
    }
    props.setSelectedDataPOItemList(newSelected);
  };

  const isSelected = (name) =>
    props.selectedDataPOItemList.indexOf(name) !== -1;

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded lg:h-52 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <Checkbox
                        size="small"
                        className=" w-3 h-3 border border-gray-600 rounded bg-transparent"
                        type="checkbox"
                        color="primary"
                        checked={
                          props.data.length > 0 &&
                          props.selectedDataPOItemList.length ===
                            props.data.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold ">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            size="small"
                            className="w-3 h-3 border border-gray-600 rounded bg-transparent"
                            type="checkbox"
                            color="primary"
                            name={`checkbox${index}`}
                            onChange={(e) => {
                              handleClick(e, row);
                            }}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>

                        {headers &&
                          headers.map((header, i) => (
                            <TableCell className="whitespace-nowrap" key={i}>
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
