import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../http-common";
import {
  fetchDashboardIcon,
  fetchDashboardMenus,
} from "../services/DashboardMenusServices";
import { useDispatch } from "react-redux";
import { routeAdded } from "../../authentication/slices/auth";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const FnImg = (imgPath) => <img src={imgPath} alt="icon" />;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="right-end"
    describeChild={true}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#f0f0f0",
    // maxWidth: 250,
    fontSize: theme.typography.pxToRem(13),
    borderRadius: "4px",
    background: "#f2f4f5",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
  },
}));

const MenuView = ({ node, onClick, collapsed }) => {
  let navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenMenuHover, setIsOpenMenuHover] = useState(false);
  const location = useLocation();
  const isActive = location.pathname === node.key;

  const handleToggle = () => {
    if (node.children && node.children.length > 0) {
      setIsOpenMenu(!isOpenMenu);
    } else {
      let menuId = node.menuId;
      navigate(node.key, {
        state: { menuId: menuId },
      });
    }
  };

  const MenuViewHover = ({ node, isDescendantActive }) => {
    const isActiveHover = location.pathname === node.key;

    const handleToggleHover = (node) => {
      let menuId = node.menuId;
      navigate(node.key, {
        state: { menuId: menuId },
      });
      setIsOpenMenuHover(!isOpenMenuHover);
    };
    return (
      <>
        <div
          className={`gap-3 bg-white rounded-md  ${
            isActiveHover
              ? "bg-blue-900 border-l-4 rounded-md border-red-500 text-white"
              : isDescendantActive(node)
              ? "bg-[#BFEAFF]"
              : "hover:bg-indigo-100 hover:rounded-md m-1"
          }`}
        >
          <HtmlTooltip title={renderTooltipContent(node)} arrow>
            <div
              className={`grid grid-cols-12 items-center cursor-pointer pl-3 text-ellipsis `}
              onClick={() => {
                handleToggleHover(node);
              }}
            >
              <div
                className={`col-span-11 flex gap-3 px-2 py-3 overflow-hidden hover:bg-gray-${
                  100 + 100 * node.level
                }`}
              >
                {node.iconPath && node.level === 0 && (
                  <div className="w-5 h-5">
                    {FnImg(`${baseUrl}/file/${node.iconPath}`)}
                  </div>
                )}
                {/* <Link to={node.key}> */}
                <div
                  className={`m-0 ${
                    isActiveHover
                      ? "text-white font-bold"
                      : isDescendantActive(node)
                      ? "text-black font-bold"
                      : "text-gray-700 font-normal"
                  } font-roboto  text-sm leading-1.5 tracking-wide text-left whitespace-nowrap text-ellipsis`}
                >
                  {node.label}
                </div>
                {/* </Link> */}
              </div>
              <div className="flex justify-end pr-2">
                {node.children && (
                  <KeyboardArrowRightIcon
                    className=" text-slate-600 "
                    fontSize="small"
                  />
                )}
              </div>
            </div>
          </HtmlTooltip>
        </div>
      </>
    );
  };

  const renderTooltipContent = (node) => {
    if (collapsed && isOpenMenuHover && node.children) {
      return (
        <div className=" max-h-96 xl:max-h-[350px] 2xl:max-h-[650px] overflow-y-auto w-fit">
          {node.children.map((child) => (
            <MenuViewHover
              key={child.menuId}
              node={child}
              isActiveHover={isActive}
              isDescendantActive={isDescendantActive}
            />
          ))}
        </div>
      );
    }
  };

  const isDescendantActive = (childNode) =>
    location.pathname === childNode.key ||
    (childNode.children && childNode.children.some(isDescendantActive));

  return (
    <>
      {!collapsed ? (
        <div
          className={`
       
           ${
             Number(node.level) === 0
               ? "bg-white"
               : node.level === 1
               ? "bg-[#FCFCFC]"
               : node.level === 2
               ? "bg-[#F5F5F5]"
               : node.level === 3
               ? "bg-[#F4F4F4]"
               : "bg-white"
           }`}
        >
          <div
            className={` gap-3  ${
              isActive
                ? "bg-customBlue border-l-4 rounded-md border-red-500 text-white"
                : ""
            }`}
          >
            <div
              className={`grid grid-cols-12 items-center cursor-pointer pl-3 text-ellipsis ${
                !isActive && "hover:bg-indigo-100 hover:rounded-md"
              } transition-transform duration-300 ease-in-out`}
              style={{
                transform: isOpenMenu ? "translateX(-7px)" : "translateX(0px)",
              }}
              onClick={() => {
                handleToggle();
              }}
            >
              <div
                className={`col-span-11 flex gap-3 px-2  overflow-hidden ${
                  isDescendantActive(node) && !isActive
                    ? "border-l-4 border-red-500 rounded my-3 top-[25%] bottom-[25%]"
                    : "py-3"
                }`}
              >
                {node.iconPath && node.level === 0 && (
                  <div className="w-5 h-5 ">
                    {FnImg(`${baseUrl}/file/${node.iconPath}`)}
                  </div>
                )}
                <Link to={node.key}>
                  <div
                    className={`m-0  ${
                      isActive
                        ? "text-white font-semibold"
                        : isDescendantActive(node)
                        ? "text-customBlue font-semibold "
                        : "text-gray-700 font-normal"
                    } font-roboto text-sm leading-1.5 tracking-wide text-left whitespace-nowrap text-ellipsis`}
                  >
                    {node.label}
                  </div>
                </Link>
              </div>
              <div className="flex justify-end pr-2">
                {node.children && (
                  <KeyboardArrowUpIcon
                    className={`transition-transform duration-300 transform ${
                      isOpenMenu ? "rotate-0" : "rotate-180"
                    }`}
                    fontSize="small"
                  />
                )}
              </div>
            </div>
            {isOpenMenu && node.children && (
              <div style={{ marginLeft: "20px" }}>
                {node.children.map((child) => (
                  <MenuView key={child.id} node={child} onClick={onClick} />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <HtmlTooltip title={renderTooltipContent(node)} arrow>
          <div
            className={`${
              collapsed
                ? "transition-opacity duration-3000 ease-in-out opacity-100"
                : "transition-opacity duration-2000 ease-in-out opacity-0"
            } grid grid-cols-12 items-center pl-2 cursor-pointer hover:border-spacing-2 rounded-lg mx-2 my-1 ${
              isDescendantActive(node)
                ? "bg-[#BFEAFF] "
                : "bg-white hover:bg-indigo-100"
            }`}
            onMouseEnter={() => {
              setIsOpenMenuHover(true);
            }}
          >
            <div className="col-span-11 flex gap-3 p-3 ">
              <div className="w-5 h-5 ">
                {node.iconPath &&
                  node.level === 0 &&
                  FnImg(`${baseUrl}/file/${node.iconPath}`)}
              </div>
            </div>
          </div>
        </HtmlTooltip>
      )}
    </>
  );
};

const MenuTreeView = ({ data, onClick, collapsed }) => {
  return (
    <div className={`${!collapsed ? "w-[275px]" : "w-20  "}`}>
      {data.map((node) => (
        <MenuView
          key={node.id}
          node={node}
          onClick={onClick}
          collapsed={collapsed}
        />
      ))}
    </div>
  );
};

const NewMenuList = (props) => {
  const { collapsed, setFavourites, setDashboardMenus, dashboardMenus } = props;
  const dispatch = useDispatch();
  // const [dashboardMenus, setDashboardMenus] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State variable for search query

  let token = JSON.parse(localStorage.getItem("userInfo"));
  let userId = token?.userId;

  const simplifyMenu = (menu) =>
    menu.map((item) => ({
      iconPath: item.iconPath,
      menuId: item.menuId,
      underMenuId: item.underMenuId,
      level: item.level,
      menuKey: item.menuKey,
      label: item.label,
      ...(item.children && item.children.length > 0
        ? { children: simplifyMenu(item.children) }
        : { key: item.key }),
    }));

  useEffect(() => {
    fetchDashboardMenus(userId)
      .then((response) => response.data)
      .then((res) => {
        const imgAddedMenusArr = res.result.map((menu) => ({
          ...menu,
          icon: FnImg(`${baseUrl}/file/${menu.iconPath}`),
        }));

        setDashboardMenus(simplifyMenu(imgAddedMenusArr));
        fetchDashboardIcon(res.result);
        handleRouteValidation(res.result);

        //
        const favouriteMenus = res.result
          .flatMap((item) => (item.children ? item.children : []))
          .filter((child) => !!child.isFavourite && child.isFavourite === true);

        setFavourites(favouriteMenus);
      });
  }, []);

  //Validate Routes
  const handleRouteValidation = (menuItems) => {
    menuItems.forEach((item) => {
      dispatch(routeAdded(item.key));
      if (item.children) {
        handleRouteValidation(item.children);
      }
    });
  };

  //  function to filter menu items based on search query

  const filterMenuItems = (menus, query) =>
    menus.filter(
      (menu) =>
        menu.label.toLowerCase().includes(query.toLowerCase()) ||
        (menu.children && filterMenuItems(menu.children, query).length > 0)
    );

  const extractObjects = (menus, query) => {
    const filteredMenus = filterMenuItems(menus, query);
    const extractedObjects = [];

    const extract = (menu) => {
      if (menu.label.toLowerCase().includes(query.toLowerCase())) {
        extractedObjects.push(menu);
      }
      if (menu.children) {
        menu.children.forEach(extract);
      }
    };

    filteredMenus.forEach(extract);
    return extractedObjects;
  };

  const result =
    searchQuery !== "" && !collapsed
      ? extractObjects(dashboardMenus, searchQuery)
      : dashboardMenus;

  return (
    <div style={{ width: 280 }}>
      {/* Search input field */}
      {!collapsed && (
        <div className=" mb-2 sticky top-16 bg-[#f8fafc] z-50 pt-1">
          <input
            type="text"
            placeholder="Search Menu..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="text-sm py-1 pl-7 pr-6 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:border-blue-300 w-64 ml-2"
          />
          {searchQuery && (
            <button
              className="absolute top-1/2 right-5 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 "
              onClick={() => setSearchQuery("")}
            >
              <CloseIcon fontSize="small" />
            </button>
          )}
          <div className="absolute inset-y-0 mt-1 left-4 flex items-center pointer-events-none">
            <SearchIcon className=" text-gray-400" fontSize="small" />
          </div>
        </div>
      )}

      {result.length > 0 ? (
        <MenuTreeView data={result} collapsed={collapsed} />
      ) : (
        !collapsed && (
          <div className="flex justify-center text-gray-700 font-normal">
            No Menus Found...
          </div>
        )
      )}
    </div>
  );
};

export default NewMenuList;
