import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
// import { searchAlternativeDrugs } from "../../pharmacyServices/pharmaServices";
import GenericDrugList from "./GenericDrugList";
import { getAlternativeDrugList } from "../../PharmacyServices";
import SearchIcon from "@mui/icons-material/Search";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";

export default function GenericDrugModal(props) {
  const {
    setSelectedGenericDrug,
    rowToReplace,
    indexToReplace,
    setAlternateTableList,
    data,
    selectedGenericDrug,
  } = props;

  let genericId = rowToReplace?.genericId;

  const [dataResult, setDataResult] = React.useState([]);
  const [dataTest, setDataTest] = React.useState({ result: [] });
  const [searchedDrug, setSearchedDrug] = React.useState("");
  const [drugsToDisplay, setDrugsToDisplay] = React.useState([]);
  const loggedUser = JSON.parse(localStorage.getItem("userInfo"));
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  React.useEffect(() => {
    if (props?.open === true) {
      setOpenBackdrop(true);
      console.log("The row to replace is :", rowToReplace);
      let searchObject = {
        deptStore: 1,
        deptStoreId: loggedUser?.storeId,
        genericId: genericId,
        isConsignment: 0,
        itemCode: "%",
        itemName: "%",
        searchString: "",
      };
      getAlternativeDrugList(searchObject)
        .then((response) => response.data)
        .then((res) => {
          setOpenBackdrop(false);
          setDataResult(res.result);
          setDrugsToDisplay(res?.result);
          setDataTest({ result: res.result });
        })
        .catch((error) => {
          setOpenBackdrop(false);
        });
    }
  }, [props.open]);

  React.useEffect(() => {
    if (selectedGenericDrug !== "") {
      setDataResult([]);
      setDataTest({ result: [] });
    }
  }, [selectedGenericDrug]);

  React.useEffect(() => {
    if (searchedDrug !== "" && dataResult?.length > 0) {
      let filtered = dataResult?.filter((drugObject) =>
        drugObject?.Drug?.toLowerCase()?.includes(searchedDrug?.toLowerCase())
      );
      setDrugsToDisplay(filtered);
    } else {
      setDrugsToDisplay(dataResult);
    }
  }, [searchedDrug]);

  return (
    <div>
      <Modal
        open={props.open}
        // onClose={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            boxShadow: 24,
            px: 4,
            pb: 4,
            width: "70%",
          }}
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.close();
              setDataResult([]);
              setSearchedDrug("");
              setDrugsToDisplay([]);
            }}
            style={{
              paddingLeft: 10,
            }}
          />
          <div className="grid mt-5">
            <div>
              <label className="text-lg font-semibold whitespace-nowrap">
                Alternate drugs based on the selected drug
              </label>
            </div>
          </div>
          <div className="mt-2">
            <FormControl
              sx={{
                "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "36px !important",
                },
                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  top: "-9px !important",
                },
                width: "25ch",
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Search By Drug Name
              </InputLabel>
              <OutlinedInput
                onChange={(e) => {
                  setSearchedDrug(e.target.value);
                }}
                id="outlined-adornment-password"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon
                      fontSize="small"
                      sx={{
                        color: "#7a7d7b",
                      }}
                    />
                  </InputAdornment>
                }
                label="Search By Drug Name"
              />
            </FormControl>
          </div>
          <div className="my-5 overflow-x-scroll">
            {openBackdrop ? (
              <CommonBackDrop openBackdrop={openBackdrop} />
            ) : (
              <>
                {dataResult.length > 0 ? (
                  <GenericDrugList
                    from="alternateDrugModal"
                    setSelectedGenericDrug={props.setSelectedGenericDrug}
                    dataResult={drugsToDisplay}
                    setDrugsToDisplay={setDrugsToDisplay}
                    setDataResult={setDataResult}
                    searchedDrug={searchedDrug}
                    indexToReplace={indexToReplace}
                    setSearchedDrug={setSearchedDrug}
                    data={dataTest}
                  />
                ) : (
                  <div className="mt-12 text-xl flex justify-center text-gray-600">
                    No Alternate Drugs Found..!
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

