import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { autoSearchPatientAdvance } from "../../../../billing/services/PatientAdvanceServices";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  billListingService,
  getRefundOfBillPatientInfoById,
  postRefundOfBillService,
  refundListingService,
  refundOfBillPdfPrint,
  serviceListing,
} from "../../../services/refund/RefundOfBillServices";
import BillingInformationModal from "./BillingInformationModal";
import RefundOfBillServiceList from "./RefundOfBillServiceList";

const refundType = [
  {
    id: "BillWise",
    value: "BillWise",
    label: "Bill Wise",
  },
  {
    id: "ServiceWise",
    value: "ServiceWise",
    label: "Service Wise",
  },
];

export default function RefundOfBill(props) {
  
  /*************************************useForm***********************************/
  const schema = yup.object().shape({
    // write schema
    paidAmount: yup.number(),
    // write schema
    refundAmount: yup
      .number()
      .min(1, "Required")
      .typeError("Required")
      .required("Required")
      .when("balanceAmount", (balanceAmount, schema) => {
        
        return schema
          .test({
            test: (refundAmount) => {
              if (!refundAmount) return true;
              return refundAmount <= balanceAmount;
            },
            message: "Amount Exceeds Than Total Advance",
          })
          .required("Required");
      }),
  });
  const defaultValues = {
    refundType: "BillWise",
    netBillAmount: 0,
    paidAmount: 0,
    balanceAmount: 0,
    refundedAmount: 0,
    refundAmount: "",
    refundOfBillSearch: null,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
  
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  /*************************************state variables***********************************/
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [searhRefundOfBill, setSearchRefunOfBill] = useState();
  const [refundTypeValue, setRefundTypeValue] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [searchResultById, setSearchResultById] = useState("");
  const [billList, setBillList] = useState([]);
  const[refundTotalAmt,setRefundTotalAmt]=React.useState(0)
  const [refundList, setRefundList] = useState([]);
  const [rowBillData, setRowBillData] = useState("");
  const [open, setOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [postRefundOfBill, setPostRefundOfBill] = useState();
  const [totalOfRefund, setTotalOfRefund] = useState(0);
  const [billPaidAmount, setBillPaidAmount] = useState(0);
  const [sumOfRefundedAmt, setSumOfRefundedAmt] = useState(0);
  const [refundAmount, setRefundAmount] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [userActions, setUserActions] = useState([]);

  /*************************************other variables***********************************/
  let refundTypeVal = watch("refundType");
  let refundAmountVal = watch("refundAmount");
  let getRefundTypeVal = getValues("refundType");
  let balanceAmountVal = getValues("balanceAmount");
  
  let location = useLocation();
  

  /*************************************useEffects***********************************/
  useEffect(() => {
    if (rowBillData) {
      setValue("netBillAmount", rowBillData.PaidAmount);
      setValue("refundAmount", refundAmount);
      setValue("paidAmount", rowBillData.PaidAmount);
      setValue("balanceAmount", totalOfRefund);
      setValue("refundedAmount", sumOfRefundedAmt);
      
    }
  }, [refundAmount, rowBillData, sumOfRefundedAmt, totalOfRefund]);
  useEffect(() => {
    listOfRefund(selectedRow);
    listingofService(selectedRow);
    setRowBillData(selectedRow);
    setBillPaidAmount(selectedRow.PaidAmount);
    
  }, [selectedRow]);

  // calculate total of refundlist
  useEffect(() => {
    let totalAmt = 0;
    let finalRefundAmt = 0;
    let tempRefundAmt = [...refundList];

    for (let obj of tempRefundAmt) {
      totalAmt += obj.RefundAmount;
      setSumOfRefundedAmt(totalAmt);
    }
    finalRefundAmt = billPaidAmount - totalAmt;

    setTotalOfRefund(finalRefundAmt);
  }, [refundList, billPaidAmount]);

  // get menu id and button on components
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          
          setUserActions(res.result);
        });
  }, []);
  useEffect(() => {
    
    
  }, [errors, refundAmount]);

  /*************************************functions***********************************/
  const handleOpen = () => {
    let temp = [...serviceList];
    
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].refundAmount > temp[i].TotalAmount) {
        
       
        setOpen(false);
      } else if (refundAmountVal > balanceAmountVal) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleChange(searchRefundOfBill) {
    if (searchRefundOfBill !== "") {
      let ipdOpd = 0;
      autoSearchPatientAdvance(searchRefundOfBill, ipdOpd)
        .then((response) => response.data)
        .then((res) => {
          
          setSearchRefunOfBill(res.result);
        });
    }
  }
  function getPatientInfomation(seletedPatientById) {
    
    getRefundOfBillPatientInfoById(seletedPatientById.id)
      .then((response) => response.data)
      .then((res) => {
        
        setSearchResult(res.result);
        listOfBills(res.result);
      });
  }

  function listOfBills(selectedPatient) {
    
  

    if (selectedPatient !== null) {
      billListingService(selectedPatient.id)
        .then((response) => response.data)
        .then((res) => {
          setBillList(res.result);
          
          //  listOfRefund();
        });
    } else {
     // setBillList("");
    }
  }
  function listOfRefund(selectedRow) {
    
    if (selectedRow !== null) {
      refundListingService(selectedRow?.BillId)
        .then((response) => response.data)
        .then((res) => {
          setRefundList(res.result);
          
        });
    }
  }

  
  
  //service listing
  function listingofService(selectedRow) {
    
    serviceListing(selectedRow.BillId)
      .then((response) => response.data)
      .then((res) => {
        
        setServiceList(res.result);
      });
    setRowBillData(selectedRow);
  }
  
  
  
  
  
  
  

  function submitRefundOfBillDataHandler(data) {
    
  }
  function submitPaymentInfoModal(paymentInfoList) {
    setOpenBackdrop(true);
    let refundA = getValues("refundAmount");
    let formRemark = getValues("remarks");

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);
    // create sevice wise requird object
    let arr = [];
    for (let obj of serviceList) {
      let object = {};

      object.ChargeId = obj.ChargeId;
      object.chargeRefundAmt = obj.refundAmount;
      arr.push(object);
    }
    let refundOfBillpostObj = {
      addedBy: userInfoObj.userId,
      patientId: searchResultById.id,
      cashCounterId: userInfoObj.cashCounterId,
      billId: rowBillData.BillId,
      refundAmount: refundTotalAmt,
      opdIpdExternal: 0,
      serviceWise: refundTypeVal === "ServiceWise" ? true : false,
      remarks: formRemark,
      visitId: rowBillData.VisitId,
      refundBillServiceDto: refundTypeVal === "ServiceWise" ? arr : null,
      menuId: location?.state?.menuId,
      privilege: "Create",
      type: getRefundTypeVal,
    };

    refundOfBillpostObj.paymentInfoList = paymentInfoList;

    

    postRefundOfBillService(refundOfBillpostObj)
      .then((response) => response.data)
      .then((res) => {
        

        setPostRefundOfBill(res);
        successAlert(res.message);
        setRowBillData("");
        setBillList([]);
        setSearchResult([]);
        setServiceList([]);
        setRefundList([]);
        setSumOfRefundedAmt(0);
        setOpenBackdrop(false);
        setRefundAmount(null);
        setValue("refundOfBillSearch", null);
        reset();
        if (res.statusCode === 200) {
          handleOpenPrintModal(res);
        } else {
          errorAlert(errors.message);
        }
        setSearchResultById("");
        handleClose();
      });
  }
  // print pdf receipts

  //prints print
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (advanceId) => {
    
    let opdIpd = 0;
    refundOfBillPdfPrint(
      advanceId.result ? advanceId?.result : advanceId,
      opdIpd
    ).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  }; 
  useEffect(() => {
    
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].refundAmount > serviceList[i].TotalAmount) {
        
      
        setOpen(false);
      }
    }
  }, [serviceList]);

  // common table action button click function
  const renderActions = (row) => {
    
    return (
      <div className="flex gap-3">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === false ? (
                <>
                  {
                    <div className="flex gap-2 cursor-pointer">
                      {actions.action === "Print" ? (
                        <Tooltip title="Print">
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                              
                              handleOpenPrintModal(row?.RefundId);
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  }
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  function handleSelectedRow(row) {
    setSelectedRow(row);
  }
  return (
    <>
      <div className="mt-10  px-6">
        <div className="text-center text-black font-bold text-xl py-2">
          Refund Of Bill
        </div>
        <fieldset className="  md:grid md:grid-cols-1 gap-4 lg:w-10/12 rounded  content-start  md:py-4">
          <div className=" lg:grid lg:grid-cols-2 md:grid md:grid-cols-1 gap-3">
            <div className="">
              <SearchDropdown
                control={control}
                name="refundOfBillSearch"
                dataArray={searhRefundOfBill}
                searchIcon={true}
                isClearable={true}
                isSearchable={true}
                placeholder="Search By Patient Name / MR No./Mobile No."
                handleInputChange={handleChange}
                inputRef={{
                  ...register("refundOfBillSearch", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        
                        setSearchResultById(e.target.value);
                        getPatientInfomation(e.target.value);
                      } else {
                        setSearchResultById("");
                        setBillList([]);
                        setServiceList([]);
                        setRefundList([]);
                        setSumOfRefundedAmt(0);
                        setOpenBackdrop(false);
                        setRefundAmount(null);
                        setValue("refundOfBillSearch", null);
                        reset();
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className="pt-1 flex">
              <RadioField
                label=""
                name="refundType"
                control={control}
                dataArray={refundType}
                inputRef={{
                  ...register("refundType", {
                    onChange: (e) => {
                      setRefundTypeValue(e.target.value);
                    },
                  }),
                }}
              />
            </div>
          </div>
        </fieldset>
        {searchResultById && searchResultById !== null ? (
          <>
            <fieldset className=" mx-auto border w-full rounded  bg-gray-100">
              {/* <legend className="ml-6 my-2 rounded-"></legend> */}
              <div className="lg:grid lg:grid-cols-5 md:grid md:grid-cols-2 gap-2 p-3">
                <div className="flex col-span-2 items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-28 font-semibold"> Patient Name</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {searchResult && searchResult.patientName}
                    </span>
                  </div>
                </div>
                <div className="flex  items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-20 font-semibold"> MR No. </span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {searchResult && searchResult?.MRNo}
                    </span>
                  </div>
                </div>
                <div className="flex  items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-20 font-semibold">Age</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {searchResult && searchResult?.ageInYears}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                  <span className="w-28 font-semibold">Mobile No</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {searchResult && searchResult?.mobileNo}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
            <form
              onSubmit={handleSubmit(submitRefundOfBillDataHandler)}
              className=""
            >
              <div className="lg:grid lg:grid-cols-2 gap-4 pt-2 ">
                <div className="">
                  <div className="text-md font-semibold">List Of Bill</div>
                  {billList.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={billList}
                      highlightRow={true}
                      handleSelectedRow={handleSelectedRow}
                      tableClass={"rounded lg:h-52 md:h-72"}
                    />
                  ) : (
                    <>
                      <div className="text-center mt-4 p-4">
                        No Record Found
                      </div>
                    </>
                  )}
                </div>

                <div className="">
                  <div className="text-md font-semibold">List Of Refund</div>
                  {refundList.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={refundList}
                      renderActions={renderActions}
                      highlightRow={false}
                      handleSelectedRow={handleSelectedRow}
                      tableClass={"rounded lg:h-52 md:h-72"}
                    />
                  ) : (
                    <>
                      <div className="text-center mt-4 p-4">
                        No Record Found
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="py-2">
                <div className="text-md font-semibold md:visible">
                  Service List
                </div>
                <RefundOfBillServiceList
                  serviceList={serviceList}
                  setServiceList={setServiceList}
                  refundTypeVal={refundTypeVal}
                  setRefundAmount={setRefundAmount}
                  refundAmount={refundAmount}
                  setOpen={setOpen}
                />
              </div>

              <div className="">
                <fieldset className="mx-auto border border-slate-400 w-full rounded  lg:w-auto content-start">
                  <legend className="ml-2  rounded">
                    <p className="text-sm font-semibold tracking-wide mx-2">
                      Add Refund
                    </p>
                  </legend>
                  <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-2 px-4 py-4">
                    <div>
                      <InputField
                        name="netBillAmount"
                        variant="outlined"
                        label="Net Bill Amount ₹"
                        control={control}
                        // type="number"
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        name="paidAmount"
                        variant="outlined"
                        label="Paid Amount ₹"
                        control={control}
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        name="balanceAmount"
                        variant="outlined"
                        label="Balance Amount ₹"
                        control={control}
                        disabled={true}
                      />
                    </div>
                    <div>
                      <InputField
                        name="refundedAmount"
                        variant="outlined"
                        label="Refunded Amount ₹"
                        control={control}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="lg:grid lg:grid-cols-4 md:flex  gap-2 px-4">
                    <div className="md:w-full">
                      <InputField
                        name="refundAmount"
                        variant="outlined"
                        label="Refund Amount ₹"
                        // type="number"
                        defaultValue={0}
                        control={control}
                        error={errors.refundAmount}
                        disabled={refundTypeVal !== "BillWise"}
                      />
                    </div>

                    <div className="lg:col-span-2  md:col-span-1 md:w-full">
                      <InputField
                        name="remarks"
                        variant="outlined"
                        label="Remark"
                        control={control}
                      />
                    </div>
                    <div className="flex lg:col-span-1 justify-end  py-2 gap-2">
                      {userActions &&
                        userActions.map((obj) => (
                          <>
                            {!obj.isAction && obj.action === "Create" ? (
                              <>
                                <CommonButton
                                  label="Reset"
                                  className="border border-customRed text-customRed"
                                  onClick={() => {
                                    setRowBillData("");
                                    setBillList([]);
                                    setSearchResult([]);
                                    setServiceList([]);
                                    setRefundList([]);
                                    setSumOfRefundedAmt(0);
                                    setOpenBackdrop(false);
                                    setRefundAmount(null);
                                    setValue("refundOfBillSearch", null);
                                    reset();
                                  }}
                                />
                                <CommonButton
                                  className="bg-customGreen text-white "
                                  label="Save"
                                  type="submit"
                                  onClick={() => {
                                    if (
                                      rowBillData !== "" &&
                                      watch("refundAmount") > 0
                                    ) {
                                      handleOpen();
                                    } else if (rowBillData === "") {
                                      errorAlert(
                                        "Please Select Bill From List"
                                      );
                                    } else if (watch("refundAmount") <= 0) {
                                      setOpen(false);
                                    } else if (
                                      rowBillData.refundAmount >
                                      rowBillData.TotalAmount
                                    ) {
                                      setOpen(false);
                                    }
                                  }}
                                />
                              </>
                            ) : null}
                          </>
                        ))}
                    </div>
                  </div>
                </fieldset>
              </div>
              <CommonBackDrop openBackdrop={openBackdrop} />
            </form>
          </>
        ) : (
          <></>
        )}
        <BillingInformationModal
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          postRefundOfBill={postRefundOfBill}
          searchResult={searchResult}
          submitPaymentInfoModal={submitPaymentInfoModal}
          getValues={getValues}
          refundAmount={refundAmountVal}
          openBackdrop={openBackdrop}
          setRefundTotalAmt={setRefundTotalAmt}
          setOpenBackdrop={setOpenBackdrop}
        />
        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}
      </div>
    </>
  );
}
