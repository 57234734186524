import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";

import BedScoreTable from "./table/BedScoreTable";

function BedScoreAssessment(props) {
  let isAgeOver75 = false;
  const { control, setValue } = useFormContext(); // retrieve all hook methods

  //useEffect to control the checking of highFallRiskFlag , noFallRiskFlag , lowRiskFlag
  React.useEffect(() => {
    isAgeOver75 = props.selectedPatient.Age > 75;
    const mildRiskRangeLow = isAgeOver75 ? 15 : 15;
    const mildRiskRangeHigh = isAgeOver75 ? 18 : 16;

    if (
      props.bedAssessmentScore >= mildRiskRangeLow &&
      props.bedAssessmentScore <= mildRiskRangeHigh
    ) {
      setValue("mildRiskFlag", true);
    } else {
      setValue("mildRiskFlag", false);
    }

    if (props.bedAssessmentScore >= 12 && props.bedAssessmentScore <= 14) {
      setValue("moderateRiskFlag", true);
    } else {
      setValue("moderateRiskFlag", false);
    }

    if (props.bedAssessmentScore < 12) {
      setValue("highRiskFlag", true);
    } else {
      setValue("highRiskFlag", false);
    }
  }, [props.bedAssessmentScore]);

  console.log("props.bedScoreAssessmentArray", props.bedScoreAssessmentArray);
  return (
    <div className="mx-2">
      <BedScoreTable
        setBedAssessmentScore={props.setBedAssessmentScore}
        patchedNursingInfo={props.patchedNursingInfo}
        bedAssessmentScore={props.bedAssessmentScore}
        bedScoreAssessmentArray={props.bedScoreAssessmentArray}
        setBedScoreAssessmentArray={props.setBedScoreAssessmentArray}
      />

      <div className="grid grid-cols-4 items-center gap-2 text-sm">
        <fieldset disabled={true}>
          <CheckBoxField
            control={control}
            name="mildRiskFlag"
            label={isAgeOver75 ? "15-18 Mild Risk" : "15-16 Mild Risk"}
            value="Mild Risk"
            style={{ color: "green", fontWeight: "bold", fontSize: "13px" }}
            checkBoxStyle={{ color: "green" }}
          />
        </fieldset>

        <div className="whitespace-nowrap">
          <fieldset disabled={true}>
            <CheckBoxField
              control={control}
              name="moderateRiskFlag"
              label="12 - 14 Moderate Risk"
              value="Low Risk"
              style={{ color: "orange", fontWeight: "bold", fontSize: "13px" }}
              checkBoxStyle={{ color: "orange" }}
            />
          </fieldset>
        </div>

        <fieldset disabled={true}>
          <CheckBoxField
            control={control}
            name="highRiskFlag"
            label="< 12 High Risk"
            value="High Risk"
            style={{ color: "red", fontWeight: "bold", fontSize: "13px" }}
            checkBoxStyle={{ color: "red" }}
          />
        </fieldset>

        <div className="flex items-center justify-end gap-2 ml-16">
          <h6 className="font-bold">Total</h6>
          <div className="w-1/2 text-lg font-bold">
            {props.bedAssessmentScore}
          </div>
        </div>
      </div>

      <div className="border border-b border-customBlue mx-2"> </div>
    </div>
  );
}

export default BedScoreAssessment;
