import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import OtLayoutFile from "./otLayout/OtLayoutFile";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "84%",
  height: "93%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

export default function OtLayout(props) {
  const {
    open,
    handleClose,
    userActions,
    layoutEquipmentArr,
    setLayoutEquipmentArr,
    previewLayoutArr,
    setPreviewLayoutArr,
    isFromForm,
    isEditLayout,
    doctorDetailsArr,
    selectedRowData,
    selectedTblRow,
    patientInfo,
  } = props;
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[95%] xl:max-h-[100%]">
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <CancelPresentationIconButton
              onClick={() => {
                handleClose();
              }}
            />
            {/*<div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                OT Layout
              </span>
            </div>
        <hr className="border my-2 mx-1  border-blue-900" />*/}
            <OtLayoutFile
              handleClose={handleClose}
              layoutEquipmentArr={layoutEquipmentArr && layoutEquipmentArr}
              previewLayoutArr={previewLayoutArr && previewLayoutArr}
              setPreviewLayoutArr={setPreviewLayoutArr && setPreviewLayoutArr}
              setLayoutEquipmentArr={
                setLayoutEquipmentArr && setLayoutEquipmentArr
              }
              isFromForm={isFromForm}
              isEditLayout={isEditLayout}
              doctorDetailsArr={doctorDetailsArr && doctorDetailsArr}
              selectedRowData={selectedRowData && selectedRowData||selectedTblRow||0}
              patientInfo={patientInfo}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
