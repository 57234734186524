import React from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerField from "../../../common/components/FormFields/DatePickerField";
import RadioField from "../../../common/components/FormFields/RadioField";
import BillsTable from "../common/SampleCollectionListTable";

const patientType = [
  {
    id: "Pending Reports",
    label: "Pending Reports",
    value: "Pending Reports",
  },
  {
    id: "Completed Reports",
    label: "Completed Reports",
    value: "Completed Reports",
  },
  {
    id: "Collected Reports",
    label: "Collected Reports",
    value: "Collected Reports",
  },
];
const listOfOrders = {
  result: [
    {
      id: 1,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 2,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 3,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 4,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 5,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 6,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 7,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
    {
      id: 8,
      Date: "18/1/2023 00:00:06",
      "Mob No.": "9087654321",
      "Mr.No.": "51423",
      "Patient Name": "Mahadev Patil",
      "Test/Profile List": "lorem",
      "Report Completed": "lorem",
      "Call Done": "Lorem",
      WhatsApp: "lorem",
      "Report Collected": "yes",
      Remark: "lorem",
      "Send SMS": "No",
    },
  ],
};

function WorkOrderStatus() {
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);

  const defaultValues = {
    patientType: "Pending Reports",
  };
  const { control, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });
  return (
    <>
      <div className="pt-12">
        <div className="text-xl font-semibold pb-4 flex justify-center">
          Work Order Status
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-2 items-center">
          <div>
            <DatePickerField
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("fromDate", newValue);
              }}
              disableFuture={true}
              inputFormat="DD-MM-YYYY"
            />
          </div>
          <div>
            <DatePickerField
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("toDate", newValue);
              }}
              disableFuture={true}
              inputFormat="DD-MM-YYYY"
            />
          </div>
          <div className="col-span-2 lg:col-span-full xl:col-span-3 flex flex-wrap items-center">
            <div>
              <RadioField
                control={control}
                name="patientType"
                dataArray={patientType}
              />
            </div>
            <div className="flex justify-end">
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={() => {
                  // getPatientList();
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <BillsTable data={listOfOrders} />
        </div>
        <div className="flex justify-end col-span-2">
          <CommonButton
            type="submit"
            label="Save"
            className="bg-customGreen text-white"
          />
        </div>
      </div>
    </>
  );
}

export default WorkOrderStatus;
