import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useState } from "react";
import CommonButton from "../../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function InstructionsModalIssueNote(props) {
  const { open, setOpen, handleClose } = props;
  const [readCheck, setReadCheck] = useState(false);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              BEFORE FILLING THE FORM PLEASE READ THE FOLLOWING INSTRUCTIONS
              CAREFULLY
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  ">
              <div>
                <span className="font-bold">1. Blood Issue : </span>
                <span>
                  To issue blood from blood storage / blood bank a prior
                  reservation should be made in the blood storage / blood bank.
                  In case of emergencies, the blood requisition and blood issue
                  note should be sent together. Once blood is issued for a
                  particular patient it will be used for that patient only and
                  cannot be returned for use by someone else.
                </span>
              </div>
              <br />
              <div>
                <span className="font-bold">
                  
                  2. Multiple Units of Blood :
                </span>
                <span>
                  Typically only one unit of blood will be issued at one time
                  against one issue note. To request issue of multiple units of
                  blood against one issue note, justification and authorization
                  is needed. The issue form should clearly mention the name of
                  the consultant requesting issue of multiple units and the
                  reason for the same should be mentioned. This is to ensure
                  against wastage of blood / blood products.
                </span>
              </div>
              <br />
              <div>
                <span className="font-bold"> 3. Cancellation : </span>
                <span>
                  In case there is doubt on the time of transfusion/instructions
                  from consultant, blood should not be issued from blood storage
                  / blood bank until clarification is obtained. In a situation
                  blood has been issued from blood storage / blood bank and
                  transfusion is cancelled, the cancellation note should not be
                  sent to the blood storage providing reason for cancellation.
                </span>
              </div>
            </div>

            <div className=" p-2 rounded text-black font-extrabold">
              AVOID WASTAGE OF BLOOD / BLOOD PRODUCTS. ISSUE SENSIBLY
            </div>

            <div className="flex justify-between my-2 gap-3">
              <div className="flex items-center">
                <Checkbox
                  label="Read"
                  size="small"
                  checked={readCheck}
                  onChange={() => {
                    setReadCheck(!readCheck);
                  }}
                />
                <span className="font-bold text-gray-700">Read</span>
              </div>
              <div>
                <CommonButton
                  label="Proceed"
                  className="bg-customGreen text-white"
                  disabled={!readCheck}
                  onClick={() => handleClose()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
