import * as React from "react";

//imports from material ui library
import { Box, Modal, Tooltip } from "@mui/material";

//icon for closing the modal form
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import ConfirmationModal from "../../../common/components/ConfirmationModal";

import { useForm } from "react-hook-form";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import { successAlert } from "../../../common/components/Toasts/CustomToasts";

import {
  getAppointmentList,
  getDeparmentList,
  searchAppointedPatients,
  getDoctorDropdown,
  saveConfirmedAppointmentList,
} from "../AppointmentBooking/services/AppointmentServices";

import { useLocation } from "react-router-dom";

import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import DropdownField from "../../../common/components/FormFields/DropdownField";

import CheckboxField from "../../../common/components/FormFields/CheckBoxField";

import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";

import CommonButton from "../../../common/components/Buttons/CommonButton";

import { CancelOnIcon } from "./../../../assets/icons/CustomIcons";

import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import AppointmentListPrint from "../../prints/AppointmentListPrint";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "fit-content",
  overflowY: "auto",
  overflowX: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

const ModalStyleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "67%",
  overflowY: "auto",
  overflowX: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

function ViewAppointmentsModal(props) {
  const location = useLocation();

  //function to render the non-tabular buttons
  function renderNonTabularButtons() {
    const buttonDisplayOutput =
      userActions &&
      userActions.map((singleActionObj) => {
        if (
          singleActionObj.isAction === false &&
          singleActionObj.action === "Create"
        ) {
          return (
            <>
              <div className="flex gap-2  items-center">
                <div onClick={() => setOpenPrintModal(true)}>
                  <Tooltip title="Print Appointment List">
                    <LocalPrintshopIcon />
                  </Tooltip>
                </div>

                <div>
                  <CommonButton
                    className="bg-customGreen text-white"
                    label="Book Appointment"
                    onClick={() => {
                      props.setOpen(false);
                      setValue("appointmentListSearch", null);
                      setValue("department", null);
                      setValue("fromDate", new Date());
                      setValue("toDate", new Date());
                    }}
                  />
                </div>
              </div>
            </>
          );
        } else {
          return null;
        }
      });

    return buttonDisplayOutput;
  }

  const defaultValues = {
    appointmentListSearch: null,
    departmentDoctor: null,
    fromDate: new Date(),
    toDate: new Date(),
    filterAppointments: {
      id: 0,
      value: "All Appointments",
      label: "All Appointments",
    },
    ConfirmAppointmentFlag: false,
  };

  const appointmentFilterOptions = [
    {
      id: 0,
      value: "All Appointments",
      label: "All Appointments",
    },
    {
      id: 1,
      value: "Pending Appointments",
      label: "Pending Appointments",
    },
    {
      id: 2,
      value: "Confirmed Appointments",
      label: "Confirmed Appointments",
    },
  ];

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  

  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const [doctorList, setDoctorList] = React.useState([]);

  const [departmentList, setDepartmentList] = React.useState([]);

  //state variable to populate the "appointmentListSearch" SearchDropdown
  const [options, setOptions] = React.useState([]);

  //Below are the props required for the
  //CommonDynamicTablePagination component
  //-----------------------------------------------------------------------------------------------
  const [count, setCount] = React.useState();

  const [data, setData] = React.useState({ result: [] });

  const [page, setPage] = React.useState(0);

  const [dataResult, setDataResult] = React.useState([]);

  const [searchString, setSearchString] = React.useState("");

  //State variable to get the page size from the AppointmentListingTable child component.
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [userActions, setUserActions] = React.useState([]);

  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  function saveConfirmedAppointments() {
    let dataResultClone = structuredClone(dataResult);
    
    

    let requiredArr = [];

    if (dataResultClone) {
      for (let dataResultObj of dataResultClone) {
        if (
          dataResultObj.IsConfirm === true &&
          dataResultObj.IsDisabled === false
        ) {
          let obj = {};
          obj.appointmentId = dataResultObj.AppointmentID;

          if (props.menuId) {
            obj.menuId = props.menuId;
          } else {
            obj.menuId = location?.state?.menuId;
          }

          obj.mobileNo = dataResultObj["Contact No"];
          obj.privilege = "ConfirmAppointment";
          obj.token = "";

          requiredArr.push(obj);
        }
      }
    }

    confirmationHandleClose();

    setOpenBackdrop(true);

    saveConfirmedAppointmentList(requiredArr)
      .then((response) => {
        setOpenBackdrop(false);
        successAlert(response.data.message);
        populateAppointmentListTable();
      })
      .catch((error) => {
        setOpenBackdrop(false);
      });
  }

  //function to return the date in yyyy-MM-DD format
  function getRequiredDateVal(inputDateVal) {
    let requiredDate = null;

    let objectDate = new Date(inputDateVal);

    let day = String(objectDate.getDate());

    if (day.length === 1) {
      day = `0${day}`;
    }

    let month = objectDate.getMonth();

    month = month + 1;

    month = String(month);

    if (month.length === 1) {
      month = `0${month}`;
    }

    let year = objectDate.getFullYear();

    requiredDate = `${year}-${month}-${day}`;

    if (requiredDate.length === 10) {
      return requiredDate;
    } else {
      return null;
    }
  }

  //function to dataResult array to disable the checkbox whose appointment is already confirmed at the backend.
  function modifyDataResultForDisabledCheckbox(requiredArrVal) {
    
   
    let inputAppointmentListArray = requiredArrVal;

    for (let inputAppointmentObj of inputAppointmentListArray) {
      if (inputAppointmentObj.IsConfirm === true) {
        inputAppointmentObj.IsDisabled = true;
      } else if (inputAppointmentObj.IsConfirm === false) {
        inputAppointmentObj.IsDisabled = false;
      }
    }

    setDataResult(inputAppointmentListArray);
  }

  //function to prepare the postObj
  function modifyAppointmentList(confirmFlag, index) {
    let dataResultClone = structuredClone(dataResult);

    let tempDataObj = dataResultClone[index];

    tempDataObj.IsConfirm = confirmFlag;

    dataResultClone[index] = tempDataObj;

    

    setDataResult(dataResultClone);
  }

  //populate the CommonMasterTable using the function populateTable
  const populateTable = (forPagination) => {
    
    let searchObj = {
      menuId: props?.menuId ? props?.menuId : location?.state?.menuId,
      doctorId: null,
      appointmentId: 0,
      departmentId: 0,
      // fromDate: fromDateObj,
      fromDate: watch("fromDate"),
      page: !forPagination ? 0 : page,
      searchString: searchString,
      size: rowsPerPage,
      // toDate: toDateObj,
      status: null,
      toDate: watch("toDate"),
    };

    let currentDoctor = getValues("departmentDoctor");

    if (currentDoctor) {
      searchObj.doctorId = currentDoctor.id;
    }

    let filterAppointmentsVal = getValues("filterAppointments");

    

    if (filterAppointmentsVal.value === "All Appointments") {
      searchObj.status = 2;
    }

    if (filterAppointmentsVal.value === "Pending Appointments") {
      searchObj.status = 0;
    }

    if (filterAppointmentsVal.value === "Confirmed Appointments") {
      searchObj.status = 1;
    }

    getAppointmentList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          let prevData = structuredClone(dataResult);
          let requiredArr = [...prevData, ...res.result];

          modifyDataResultForDisabledCheckbox(requiredArr);
        } else {
          let requiredArr = res.result;

          modifyDataResultForDisabledCheckbox(requiredArr);

          setPage(0);
        }
        setCount(res.count);

        setUserActions(res.actions);
      })
      .catch((error) => {});
  };

  //------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    

    let searchObj = {
      menuId: props?.menuId ? props?.menuId : location?.state?.menuId,
      appointmentId: 0,
      doctorId: null,
      departmentId: 0,
      fromDate: new Date(),
      page: 0,
      searchString: "",
      size: rowsPerPage,
      status: 2,
      toDate: new Date(),
    };

    

    

    getAppointmentList(searchObj)
      .then((response) => {
        let tempObj = {};
        tempObj.result = response.data.result;
        tempObj.statusCode = response.data.statusCode;

        setData(tempObj);
        setCount(response.data.count);

        let requiredArr = response.data.result;

        modifyDataResultForDisabledCheckbox(requiredArr);

        setUserActions(response.data.actions);
      })
      .catch((error) => {
        
      });
  }, []);

  React.useEffect(() => {
    

    let countOfConfirmedAppointments = 0;
    let countOfNotConfirmedAppointments = 0;
    for (let appointmentObj of dataResult) {
      if (appointmentObj.IsConfirm === true) {
        countOfConfirmedAppointments = countOfConfirmedAppointments + 1;
      }

      if (appointmentObj.IsConfirm === false) {
        countOfNotConfirmedAppointments = countOfNotConfirmedAppointments + 1;
      }
    }

    if (countOfConfirmedAppointments === dataResult.length) {
      setValue("allAppointmentsConfirmCheck", true);
    }

    if (countOfNotConfirmedAppointments === dataResult.length) {
      setValue("allAppointmentsConfirmCheck", false);
    }

    if (
      countOfNotConfirmedAppointments > 0 &&
      countOfNotConfirmedAppointments !== dataResult.length
    ) {
      setValue("allAppointmentsConfirmCheck", false);
    }
  }, [dataResult]);

  //event listener function to populate the department list.
  function searchDepartmentList(e) {
    
    getDeparmentList(e)
      .then((response) => {
        

        setDepartmentList(response.data.result);
      })
      .catch((error) => {
        
      });
  }

  //Change handler for "Search By Patient Name / MR No" SearchDropdown.
  const handleChange = (searchString) => {
    let appointedPatientObj = {
      fromDate: null,
      searchString: searchString,
      toDate: null,
    };

    setSearchString(searchString);

    let fromDateValue = getValues("fromDate");
    let toDateValue = getValues("toDate");

    fromDateValue = getRequiredDateVal(fromDateValue);
    toDateValue = getRequiredDateVal(toDateValue);

    if (fromDateValue) {
      let d = new Date(fromDateValue);
      d = d.toISOString();
      appointedPatientObj.fromDate = d;
    }

    if (toDateValue) {
      let d = new Date(toDateValue);
      d = d.toISOString();
      appointedPatientObj.toDate = d;
    }

    if (searchString !== "") {
      searchAppointedPatients(appointedPatientObj)
        .then((response) => response.data)
        .then((res) => {
          
          setOptions(res.result);
        });
    }
  };

  //function to populate the appointment list
  function populateAppointmentListTable() {
    setPage(0);

    let patientInfoObj = getValues("appointmentListSearch");

    
   

    let appointmentId = 0;

    if (patientInfoObj) {
      appointmentId = patientInfoObj.id;
    }

    

    let departmentObj = getValues("department");

    let departmentIdVal = 0;

    if (departmentObj) {
      departmentIdVal = departmentObj.id;
    }

    let searchObj = {
      appointmentId: appointmentId,
      menuId: props?.menuId ? props?.menuId : location?.state?.menuId,
      doctorId: null,
      departmentId: departmentIdVal,
      fromDate: null,
      page: 0,
      searchString: "",
      size: rowsPerPage,
      status: null,
      toDate: null,
    };

    let filterAppointmentsVal = getValues("filterAppointments");

    

    if (filterAppointmentsVal.value === "All Appointments") {
      searchObj.status = 2;
    }

    if (filterAppointmentsVal.value === "Pending Appointments") {
      searchObj.status = 0;
    }

    if (filterAppointmentsVal.value === "Confirmed Appointments") {
      searchObj.status = 1;
    }

    let fromDateValue = getValues("fromDate");

    let toDateValue = getValues("toDate");

    let currentDoctor = getValues("departmentDoctor");

    if (currentDoctor) {
      searchObj.doctorId = currentDoctor.id;
    }

    

    fromDateValue = getRequiredDateVal(fromDateValue);
    toDateValue = getRequiredDateVal(toDateValue);

    if (fromDateValue) {
      let d = new Date(fromDateValue);
      d = d.toISOString();
      searchObj.fromDate = d;
    }

    if (toDateValue) {
      let d = new Date(toDateValue);
      d = d.toISOString();
      searchObj.toDate = d;
    }

    

    getAppointmentList(searchObj)
      .then((response) => {
        let tempObj = {};
        tempObj.result = response.data.result;
        tempObj.statusCode = response.data.statusCode;

        setData(tempObj);
        setCount(response.data.count);

        let requiredArr = response.data.result;

        modifyDataResultForDisabledCheckbox(requiredArr);

        setUserActions(response.data.actions);
      })
      .catch((error) => {
        
      });
  }

  //Below is the renderActions function
  const renderActions = (row, index) => {
    

    return (
      <div className="flex items-center	gap-1">
        {
          <fieldset
            onChange={(e) => {
              
              let confirmFlag = e.target.checked;
              modifyAppointmentList(confirmFlag, index);
            }}
          >
            <input
              type="checkbox"
              className="h-4 w-4"
              {...register(`ConfirmAppointmentFlag${index}`)}
              disabled={row.IsDisabled === true ? true : false}
              checked={row.IsConfirm === true ? true : false}
            />
          </fieldset>
        }

        {userActions &&
          userActions.map((singleActionObj) => (
            <div className="items-center flex gap-4">
              {singleActionObj.action === "CancelAppointment" &&
              singleActionObj.isAction === true ? (
                <div className="">
                  <CancelOnIcon title="Cancel Appointment" />
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
    );
  };

  function confirmAllAppointments(confirmAllFlagVal) {
    let dataResultClone = structuredClone(dataResult);

    if (confirmAllFlagVal === true) {
      for (let index = 0; index < dataResultClone.length; index++) {
        if (dataResultClone[index].IsDisabled === false) {
          setValue(`ConfirmAppointmentFlag${index}`, true);
          dataResultClone[index].IsConfirm = true;
        }
      }
    } else if (confirmAllFlagVal === false) {
      for (let index = 0; index < dataResultClone.length; index++) {
        if (dataResultClone[index].IsDisabled === false) {
          setValue(`ConfirmAppointmentFlag${index}`, false);
          dataResultClone[index].IsConfirm = false;
        }
      }
    }

    setDataResult(dataResultClone);
  }

  function renderTableHeader() {
    return (
      <>
        <div className="pl-4 py-1 flex items-center	gap-2">
          <fieldset
            onChange={(e) => {
              let confirmAllFlag = e.target.checked;
              confirmAllAppointments(confirmAllFlag);
            }}
          >
            <input
              type="checkbox"
              className="h-4 w-4 mt-1"
              {...register("allAppointmentsConfirmCheck")}
            />
          </fieldset>

          <span className="font-bold text-gray-700">
            Confirm All Appointments
          </span>
        </div>
      </>
    );
  }

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <AppointmentListPrint dataResult={dataResult} />
      </div>
    );
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={
              dataResult && dataResult.length > 0 ? ModalStyle : ModalStyleTwo
            }
          >
            <div className="grid md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.setOpen(false);
                }}
                style={{
                  paddingLeft: 10,
                }}
              />
            </div>

            <div className="row">
              <h1 className="font-bold text-gray-700 flex items-center">
                Appointment List
              </h1>

              <div className="grid lg:grid-cols-5 gap-3 md:grid-cols-2  items-center mt-2">
                {/* SearchDropdown field for taking the patient name's string.*/}
                <div className="lg:col-span-2 md:col-span-1">
                  <SearchDropdown
                    control={control}
                    name="appointmentListSearch"
                    dataArray={options}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Search By Patient Name / MR.No./ Mobile No."
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("appointmentListSearch", {
                        onChange: (e) => {
                          
                       
                        },
                      }),
                    }}
                  />
                </div>

                {/* This is the div for date picker. */}
                <div className="flex space-x-2 w-full col-span-1">
                  <DatePickerFieldNew
                    name="fromDate"
                    control={control}
                    label="From Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    // disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                  <DatePickerFieldNew
                    name="toDate"
                    control={control}
                    label="To Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                    }}
                    // disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* This is the div for SearchDropdown */}
                <div>
                  <SearchDropdown
                    control={control}
                    name="department"
                    dataArray={departmentList}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Department Name"
                    handleInputChange={searchDepartmentList}
                    error={errors.department}
                    inputRef={{
                      ...register("department", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            
                            

                            let departmentId = e.target.value.id;

                            let userInfoObj = JSON.parse(
                              localStorage.getItem("userInfo")
                            );

                            let unitIdVal = userInfoObj.unitId;

                            getDoctorDropdown(departmentId, unitIdVal).then(
                              (response) => {
                                
                                

                                setDoctorList(response.data.result);
                              }
                            );

                            
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <DropdownField
                    control={control}
                    name="departmentDoctor"
                    label="Department Doctor"
                    dataArray={doctorList}
                    placeholder="Department Doctor"
                    error={errors.departmentDoctor}
                    isSearchable={false}
                  />
                </div>

                <div className="flex gap-2 w-full">
                  <div className="w-full">
                    <DropdownField
                      control={control}
                      name="filterAppointments"
                      label="Filter Appointment"
                      dataArray={appointmentFilterOptions}
                      placeholder="Filter Appointment"
                      isSearchable={false}
                    />
                  </div>
                </div>
                {/* This is the div for SearchIcon button. */}
                <div>
                  <CommonButton
                    searchIcon
                    className="bg-customBlue text-white"
                    onClick={() => {
                      populateAppointmentListTable();
                    }}
                  />
                </div>
                <div className="col-span-3 flex items-center justify-end gap-3">
                  <div>{renderNonTabularButtons()}</div>
                </div>
              </div>

              {data.hasOwnProperty("result") &&
              data.result.length > 0 &&
              data.statusCode === 200 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  removeHeaders={["AppointmentID", "IsConfirm", "IsDisabled"]}
                  renderActions={renderActions}
                  highlightRow={false}
                  renderTableHeader={renderTableHeader}
                  tableClass="h-96"
                />
              ) : (
                <>
                  <div className="text-center mt-10">No Record Found</div>
                </>
              )}

              {dataResult && dataResult.length > 0 ? (
                <div className="float-right">
                  <CommonButton
                    className="bg-customGreen text-white"
                    label="Save List"
                    onClick={() => {
                      let clonedDataResult = structuredClone(dataResult);
                      let existenceOfIsConfirmFlagForPostObj = false;

                      
                     
                      for (let dataObj of clonedDataResult) {
                        if (
                          dataObj.IsConfirm === true &&
                          dataObj.IsDisabled === false
                        ) {
                          existenceOfIsConfirmFlagForPostObj = true;
                          break;
                        }
                      }

                      if (existenceOfIsConfirmFlagForPostObj === true) {
                        setConfirmationOpen(true);
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Box>
        </Modal>
      </div>

      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={confirmationHandleClose}
        confirmationSubmitFunc={() => {
          

          saveConfirmedAppointments();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Confirmed Appointments ?"
        confirmationButtonMsg="Save Appointment List"
      />

      {openBackdrop ? <CommonBackDrop openBackdrop={openBackdrop} /> : null}

      {openPrintModal && renderPrint()}
    </>
  );
}

export default ViewAppointmentsModal;
