import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//list
export const fetchFunctionality = () => {
  return apiClient.get(`/whatsappSms/getFunctionality`, {
    headers: authHeader(),
  });
};

//templates
export const fetchTemplates = (searchString) => {
  return apiClient.get(`/whatsappSms/getTemplates/${searchString}`, {
    headers: authHeader(),
  });
};

//templates
export const fetchFunctionalityTemplates = (functionalityId) => {
  return apiClient.get(
    `/whatsappSms/getFunctionalityTemplates/${functionalityId}`,
    {
      headers: authHeader(),
    }
  );
};

//templates
export const fetchFunctionalityTemplateEmpMobiles = (
  functionalityId,
  smsTemplateId
) => {
  return apiClient.get(
    `/whatsappSms/getFunctionalityTemplateEmpMobiles/${functionalityId}/${smsTemplateId}`,
    {
      headers: authHeader(),
    }
  );
};

//delete templates
export const deleteFunctionalityTemplate = (id) => {
  return apiClient.get(`/whatsappSms/deleteFunctionalityTemplate/${id}`, {
    headers: authHeader(),
  });
};

//save
export const saveSmsInfoInfo = (finalObj) => {
  return apiClient.post(`/whatsappSms/saveSmsInfoInfo`, finalObj, {
    headers: authHeader(),
  });
};
