import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../loadingspinner/loadingSpinner";

//set descending sort order
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

//set sort desc
const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const tableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

export default function CommonDynamicTablePaginationNew(props) {
  const {
    dataResult,
    newDataResult,
    page,
    setPage,
    tableClass, //required css for tableContainer.i.e. height ,etc.
    rowsPerPage,
    count,
    renderActions, //render Actions @1st column i.e.icons,checkboxes,etc.
    removeHeaders, //send array of headers which need to remove.  NOTE:send at least one header i.e. id
    handleSelectedRow, //get row onclick use this fn..
    highlightRow, //default row highlighted,if not want to highlight set as false.
    customRowBgColor, //usefull when required another bg color of selected row than default.
    rowBackgroundColor, //use this to show conditional row bg color .
    editableColumns, //array of headers to make column editable
    renderInput, //actual content to render i.e. input,dropdown,checkbox,icon,etc
    populateTable, //to get data.
    renderTableHeader,
  } = props;

  //state varibale for the table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [rowIndex, setRowIndex] = useState(null);
  //
  const [isLoading, setIsLoading] = useState(false);
  const [pagesPassed, setPagesPassed] = useState(0);
  const [newPageVal, setNewPageVal] = useState(null);
  //
  const [isFocused, setIsFocused] = useState(false);
  const tableRef = useRef(null);
  //

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };
  const removeHeader = (headers, fieldToRemove) => {
    return headers?.filter((v) => {
      return !fieldToRemove?.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object?.keys(dataResult[0]);

  const headers = removeHeaders
    ? removeHeader(allHeaders, removeHeaders && removeHeaders)
    : allHeaders;

  ///////
  const handlePageChange = (event, newPage) => {
    setNewPageVal(newPage);
    if (newPage > page) {
      setPage(newPage);
      setRowIndex(null);
    } else if (newPage < page) {
      setPage(newPage);
      setRowIndex(null);
    }
  };

  useEffect(() => {
    if (newPageVal > pagesPassed) {
      setPagesPassed(newPageVal);
      setIsLoading(true);
      populateTable(true);
    }
  }, [newPageVal]);

  useEffect(() => {
    if (count <= rowsPerPage || dataResult?.length <= rowsPerPage) {
      setPagesPassed(0);
      setNewPageVal(0);
      setRowIndex(null);
    }
  }, [count, rowsPerPage, dataResult]);

  //
  const handleKeyDown = (event) => {
    // event.preventDefault()///do not write this line here ...
    if (
      isFocused &&
      (highlightRow === undefined || highlightRow === true) &&
      rowIndex >= 0
    ) {
      if (event?.key === "ArrowUp" && rowIndex > 0) {
        const selectedRow = dataResult[rowIndex - 1];

        setRowIndex(rowIndex - 1);
        event?.preventDefault();
        handleSelectedRow(selectedRow, rowIndex - 1);
      } else if (
        event?.key === "ArrowDown" &&
        rowIndex < dataResult?.length - 1
      ) {
        const selectedRow = dataResult[rowIndex + 1];

        setRowIndex(rowIndex + 1);
        handleSelectedRow(selectedRow, rowIndex + 1);

        event?.preventDefault();
      } else if (event?.key === "Enter") {
        const selectedRow = dataResult[rowIndex];
        handleSelectedRow(selectedRow, rowIndex);
        event?.preventDefault();
      }
    }
  };
  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const currentTable = tableRef?.current;
    currentTable?.addEventListener("focus", handleFocus);
    currentTable?.addEventListener("blur", handleBlur);
    document?.addEventListener("keydown", handleKeyDown);

    return () => {
      currentTable?.removeEventListener("focus", handleFocus);
      currentTable?.removeEventListener("blur", handleBlur);
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFocused, rowIndex]);

  useEffect(() => {
    if (
      (highlightRow === undefined || highlightRow === true) &&
      tableRef?.current &&
      rowIndex !== null &&
      typeof rowIndex === "number"
    ) {
      const selectedRow = tableRef?.current?.querySelector(
        `tr:nth-child(${rowIndex + 1})`
      );
      if (selectedRow) {
        selectedRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [rowIndex]);

  return (
    <div className="w-auto grid">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1, border: "1px solid lightgray" }}>
          {/* pagination */}
          <div className="flex items-center w-full">
            <div className="font-semibold text-sm pl-2 w-full">
              {props?.tableHeading} {renderTableHeader && renderTableHeader()}
            </div>
            <div className="flex justify-end  w-80">
              <TablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                sx={{
                  ".MuiTablePagination-toolbar": {
                    minHeight: "35px",
                    overflowX: "hidden",
                  },
                }}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                SelectProps={{
                  disabled: isLoading,
                }}
                backIconButtonProps={
                  isLoading
                    ? {
                        disabled: isLoading,
                      }
                    : undefined
                }
                nextIconButtonProps={
                  isLoading ||
                  (newDataResult?.length === 0 && dataResult?.length < count)
                    ? {
                        disabled:
                          isLoading ||
                          (newDataResult?.length === 0 &&
                            dataResult?.length < count),
                      }
                    : undefined
                }
              />
            </div>
          </div>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#ebebeb",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#7393b3",
                borderRadius: 4,
                 cursor:"pointer"
              },
            }}
            className={tableClass}
          >
            <Table
              size="small"
              stickyHeader
              sx={{
                border: 1,
                borderColor: "#e0e0e0",
                paddingY: "scroll",
                outline: "none",
              }}
              ref={tableRef}
              tabIndex="0"
              className="MuiTableContainer-root"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  {renderActions && (
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                  )}

                  {/* heading of table */}
                  {headers?.map((header, index) => (
                    <TableCell
                      sortDirection={orderBy === header ? order : false}
                      className="whitespace-nowrap"
                      key={index}
                    >
                      <TableSortLabel
                        active={false} //arrow for sorting
                        direction={orderBy === header ? order : "asc"}
                        onClick={createSortHandler(header)}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                        {orderBy === header ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {(page > 0 || dataResult?.length > rowsPerPage
                  ? tableSort(dataResult, getComparator(order, orderBy))?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : tableSort(dataResult, getComparator(order, orderBy))
                )?.map((row, index) => {
                  isLoading && setIsLoading(false);
                  const rowKey = page > 0 ? page * rowsPerPage + index : index;
                  return (
                    <TableRow
                      key={rowKey}
                      onClick={() => {
                        setRowIndex(index);
                        handleSelectedRow && handleSelectedRow(row, index);
                      }}
                      sx={{
                        "& td": {
                          paddingY: 0.1,
                        },
                      }}
                      style={{
                        backgroundColor:
                          (highlightRow === undefined ||
                            highlightRow === true) &&
                          rowIndex === index
                            ? customRowBgColor || "#FFC44B"
                            : rowBackgroundColor
                            ? rowBackgroundColor(row, index)
                            : "",
                      }}
                    >
                      {renderActions && (
                        <TableCell>{renderActions(row, index)}</TableCell>
                      )}
                      {headers &&
                        headers?.map((header, i) => (
                          <TableCell
                            className="whitespace-nowrap capitalize"
                            key={i}
                          >
                            {editableColumns &&
                            editableColumns?.includes(header)
                              ? renderInput && renderInput(row, index, header)
                              : row[header] === true
                              ? "Yes"
                              : row[header] === false
                              ? "No"
                              : row[header]}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {isLoading && (
              <div className="flex justify-center text-gray-400 font-semibold my-5">
                <LoadingSpinner />
              </div>
            )}
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}
