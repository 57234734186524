import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, isValid } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  fetchMlcDetails,
  fetchMlcPatientInfo,
  fetchMlcType,
  postMlcData,
} from "../../../commonServices/mlcServices/MlcServices";
import DatePickerFieldNew from "../FormFields/DatePickerFieldNew";

import CancelPresentationIconButton from "../Buttons/CancelPresentationIconButton";
import CommonButton from "../Buttons/CommonButton";
import CommonBackDrop from "../CommonBackDrop/CommonBackDrop";
import CheckBoxField from "../FormFields/CheckBoxField";
import DropdownField from "../FormFields/DropdownField";
import InputField from "../FormFields/InputField";
import { errorAlert, successAlert, warningAlert } from "../Toasts/CustomToasts";
import ConfirmationModal from "../ConfirmationModal";
import InputArea from "../FormFields/InputArea";
import RadioField from "../FormFields/RadioField";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "55%",
  // maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};
const schema = yup.object().shape({
  nameOfAuthority: yup.string().required("Required"),
  authorityBuckleNumber: yup.string().required("Required"),
  policeStation: yup.string().required("Required"),
});
const schemaDischarge = yup.object().shape({
  nameOfAuthorityDischarge: yup.string().required("Required"),
  authorityBuckleNumberDischarge: yup.string().required("Required"),
  policeStationDischarge: yup.string().required("Required"),
});

const MLCTypeArr = [
  {
    id: 0,
    label: "Admission MLC",
    value: "Admission MLC",
  },
  {
    id: 1,
    label: "Discharge MLC",
    value: "Discharge MLC",
  },
];

let isToggleDisc = false;

export default function MLCModalEdit(props) {
  const {
    open,
    // patientData,
    handleClose,
    // setAllMedicationData,
    // allMedicationData,
    setMlcModalData,
    // selectedMlcData,
    // MLCNo,
    ipdEmergency,
    admissionId,
    emergencyId,
    opdIpd,
    isFromEmergency,
    privilege,
    //data used to call patient info
    patientId,
    isEmergency,
    //
    isFromDischargeSummary,
    isFromAdm,
    setPrivilege,
    getPatientInfo, //from discharge sunmmary
  } = props;

  // let navigate = useNavigate();
  const [retrivedMlcData, setRetrivedMlcData] = useState(null);
  const [retrivedDischargeMlcData, setRetrivedDischargeMlcData] = useState(
    null
  );
  const [patientData, setPatientData] = useState();
  //
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [formData, setFormData] = useState();
  const [radioButtonId, setRadioButtonId] = useState(0);

  //
  const [mlcType, setMlcType] = useState();

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(
    //   isFromDischargeSummary || (radioButtonId && radioButtonId === 1)
    //     ? schemaDischarge
    //     : schema
    // ),
    defaultValues: {
      MLCNumber: "",
      nameOfAuthority: "",
      authorityBuckleNumber: "",
      policeStation: "",
      MLCType: 0,
      reportingDate: new Date(),
      reportingTime: new Date(),
      //
      broughtByInfo: "",
      mlcDetails: "",
      inCaseDeath: false,
      bodyHandedoverDate: new Date(),
      bodyHandedOverTime: new Date(),
      authorityBuckleNumber1: "",
      policeStation1: "",
      cmoBodyHandover: "",
      //new added
      identificationMarkOne: "",
      identificationMarkTwo: "",
      nameOfAuthorityDischarge: "",
      authorityBuckleNumberDischarge: "",
      policeStationDischarge: "",
      mlcTypeRadio: 0,
    },
  });
  const {
    formState: { errors },
    control,
    watch,
    register,
    setValue,
  } = methods;

  var InCaseDeath = watch("inCaseDeath");
  let BodyHandoverDate_selected = watch("bodyHandoverDate");
  let mlcTypeRadio_selected = watch("mlcTypeRadio");

  {
    InCaseDeath !== undefined && InCaseDeath !== false
      ? (InCaseDeath = true)
      : (InCaseDeath = false);
  }

  useEffect(() => {
    setPrivilege &&
      setPrivilege(Number(mlcTypeRadio_selected) === 1 ? "MLCView" : "MLCEdit");

    setRadioButtonId(Number(mlcTypeRadio_selected));
  }, [mlcTypeRadio_selected]);

  //   //  Mlc Details

  useEffect(() => {
    !isFromEmergency && getMlcDtlls();
  }, [admissionId, emergencyId]);

  const getMlcDtlls = () => {
    fetchMlcDetails(admissionId, emergencyId, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setRetrivedMlcData(res.result[0]);
        setRetrivedDischargeMlcData(res.result[1]);
        if (
          res.result.length < 1 &&
          (isFromDischargeSummary || Number(mlcTypeRadio_selected) === 1)
        ) {
          warningAlert("Kindly fill the admission MLC");
        }
      });
  };

  useEffect(() => {
    let currentTime = format(new Date(), "HH:mm:ss");
    let currentDate = format(new Date(), "yyyy-MM-dd");
    if (
      retrivedMlcData !== null &&
      (privilege === "MLCEdit" || props?.isFromForm)
    ) {
      setValue("MLCNumber", retrivedMlcData?.MLCNo, { shouldValidate: true });

      setValue(
        "reportingDate",
        new Date(
          `${retrivedMlcData?.ReportingDatePatch || currentDate}T00:00:00.000Z`
        ),
        {
          shouldValidate: true,
        }
      );

      setValue(
        "reportingTime",
        `1900-01-01T${retrivedMlcData?.ReportingTimePath || currentTime}.000`,
        {
          shouldValidate: true,
        }
      );

      setValue("nameOfAuthority", retrivedMlcData?.AuthorityName, {
        shouldValidate: true,
      });
      setValue("authorityBuckleNumber", retrivedMlcData?.AuthorutyBuckleNo, {
        shouldValidate: true,
      });
      setValue("policeStation", retrivedMlcData?.PoliceStation, {
        shouldValidate: true,
      });
      retrivedMlcData?.mlcTypeId &&
        setValue(
          "MLCType",
          {
            id: retrivedMlcData?.mlcTypeId,
            label: retrivedMlcData?.mlcType,
            value: retrivedMlcData?.mlcType,
          },
          { shouldValidate: true }
        );

      setValue("broughtByInfo", retrivedMlcData?.broughtBy, {
        shouldValidate: true,
      });

      setValue("mlcDetails", retrivedMlcData?.mlcDetails, {
        shouldValidate: true,
      });

      setValue("inCaseDeath", retrivedMlcData?.isDeath, {
        shouldValidate: true,
      });

      setValue("policeStation1", retrivedMlcData?.BodyHandOverPoliceStation, {
        shouldValidate: true,
      });

      setValue(
        "authorityBuckleNumber1",
        retrivedMlcData?.BodyHandOverBuckelNo,
        {
          shouldValidate: true,
        }
      );

      setValue("cmoBodyHandover", retrivedMlcData?.cmoHandingOverBody, {
        shouldValidate: true,
      });

      setValue(
        "bodyHandedOverTime",
        `1900-01-01T${retrivedMlcData?.BodyHandOverTimePatch}.000`,
        {
          shouldValidate: true,
        }
      );

      setValue("bodyHandedoverDate", retrivedMlcData?.BodyHandOverDatePatch, {
        shouldValidate: true,
      });

      setValue(
        "identificationMarkOne",
        retrivedMlcData?.identificationMarkOne,
        {
          shouldValidate: true,
        }
      );

      setValue(
        "identificationMarkTwo",
        retrivedMlcData?.identificationMarkTwo,
        {
          shouldValidate: true,
        }
      );
    }

    // if (
    //   retrivedDischargeMlcData !== null &&
    //   (privilege === "MLCEdit" || props?.isFromForm)
    // ) {
    setValue(
      "nameOfAuthorityDischarge",
      retrivedDischargeMlcData?.AuthorityName,
      {
        shouldValidate: true,
      }
    );
    setValue(
      "authorityBuckleNumberDischarge",
      retrivedDischargeMlcData?.AuthorutyBuckleNo,
      {
        shouldValidate: true,
      }
    );
    setValue(
      "policeStationDischarge",
      retrivedDischargeMlcData?.PoliceStation,
      {
        shouldValidate: true,
      }
    );
    // }
  }, [retrivedMlcData]);

  const finalMlcDto = {
    addedBy: null,
    admissionId: 0,
    authorityBuckleNo: null,
    authorityName: null,
    chargedId: null,
    date: new Date(),
    ipdEmergency: 0, //is patient registered via emergency form then true else false
    mlcNo: null,
    mlcType: 0,
    policeStation: null,
    reportingDate: null,
    reportingTime: null,
    time: new Date(),
    emergencyId: 0,
    //
    broughtBy: "",
    mlcDetails: "",
    inCaseDeath: "",
    bodyHandoverDate: null,
    bodyHandoverTime: new Date(),
    bodyHandOverAuthorityBuckleNo: "",
    bodyHandOverPoliceStation: "",
    cmoHandingOverBody: "",
    //bodyHandoverDateTime: null,
  };

  const onSubmitMlc = (data) => {
    //

    finalMlcDto.mlcFromDischarge =
      isFromDischargeSummary || Number(mlcTypeRadio_selected) === 1 || false;
    finalMlcDto.identificationMarkOne = data.identificationMarkOne;
    finalMlcDto.identificationMarkTwo = data.identificationMarkTwo;
    //
    //
    finalMlcDto.menuId = props.menuId;
    finalMlcDto.privilege = privilege;
    //
    finalMlcDto.mlcId =
      !isFromDischargeSummary && Number(mlcTypeRadio_selected) === 0
        ? retrivedMlcData && retrivedMlcData.MLCId
        : 0 || 0;

    let token = JSON.parse(localStorage.getItem("userInfo"));
    // let userId = token.userId;

    finalMlcDto.emergencyId = emergencyId || 0;

    data.authorityBuckleNumber !== null
      ? (finalMlcDto.authorityBuckleNo =
          isFromDischargeSummary || Number(mlcTypeRadio_selected) === 1
            ? data.authorityBuckleNumberDischarge
            : data.authorityBuckleNumber)
      : (finalMlcDto.authorityBuckleNo = "");

    // EmergencyPatientId

    // finalMlcDto.authorityBuckleNo = data.authorityBuckleNumber;
    // finalMlcDto.authorityName = data.nameOfAuthority;
    finalMlcDto.addedBy = token.userId;
    finalMlcDto.ipdEmergency = ipdEmergency;
    finalMlcDto.admissionId = props.admissionId;

    data.nameOfAuthority !== null
      ? (finalMlcDto.authorityName =
          isFromDischargeSummary || Number(mlcTypeRadio_selected) === 1
            ? data.nameOfAuthorityDischarge?.charAt(0)?.toUpperCase() +
              data.nameOfAuthorityDischarge?.slice(1)
            : data.nameOfAuthority?.charAt(0)?.toUpperCase() +
              data.nameOfAuthority?.slice(1))
      : (finalMlcDto.authorityName = "");

    data.MLCNumber !== null
      ? (finalMlcDto.mlcNo = data.MLCNumber)
      : (finalMlcDto.mlcNo = "");

    data.MLCType !== 0
      ? (finalMlcDto.mlcType = Number(data.MLCType.id))
      : (finalMlcDto.mlcType = Number(0));

    data.policeStation !== null
      ? (finalMlcDto.policeStation =
          isFromDischargeSummary || Number(mlcTypeRadio_selected) === 1
            ? data.policeStationDischarge?.charAt(0)?.toUpperCase() +
              data.policeStationDischarge?.slice(1)
            : data.policeStation?.charAt(0)?.toUpperCase() +
              data.policeStation?.slice(1))
      : (finalMlcDto.policeStation = "");

    finalMlcDto.reportingDate = new Date(data.reportingDate);

    // finalMlcDto.reportingTime = format(
    //   data.reportingTime,
    //   "yyyy-MM-dd'T'HH:mm:ss"
    // );

    let DateIsValidReportingTime = isValid(data.reportingTime);

    finalMlcDto.reportingTime = DateIsValidReportingTime
      ? format(data.reportingTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.reportingTime.concat("Z"); //add time state

    finalMlcDto.chargedId = 0;
    finalMlcDto.date = new Date();

    ///Added Fields

    data.broughtByInfo !== null
      ? (finalMlcDto.broughtBy = data.broughtByInfo)
      : (finalMlcDto.broughtBy = "");

    data.mlcDetails !== null
      ? (finalMlcDto.mlcDetails = data.mlcDetails)
      : (finalMlcDto.mlcDetails = "");

    data.policeStation1 !== null && InCaseDeath
      ? (finalMlcDto.bodyHandOverPoliceStation =
          data.policeStation1?.charAt(0)?.toUpperCase() +
          data.policeStation1?.slice(1))
      : (finalMlcDto.bodyHandOverPoliceStation = "");

    data.authorityBuckleNumber1 !== null && InCaseDeath
      ? (finalMlcDto.bodyHandOverAuthorityBuckleNo =
          data.authorityBuckleNumber1)
      : (finalMlcDto.bodyHandOverAuthorityBuckleNo = "");

    data.cmoBodyHandover !== 0 && InCaseDeath
      ? (finalMlcDto.cmoHandingOverBody = data.cmoBodyHandover)
      : (finalMlcDto.cmoHandingOverBody = "");

    finalMlcDto.isDeath = InCaseDeath;

    finalMlcDto.bodyHandoverDate = InCaseDeath
      ? data.bodyHandedoverDate.length === 10
        ? data.bodyHandedoverDate
        : format(data.bodyHandedoverDate, "yyyy-MM-dd")
      : null;

    // finalMlcDto.bodyHandoverTime = InCaseDeath
    //   ? format(data.bodyHandedOverTime, "HH:mm:ss")
    //   : null;

    let DateIsValid = isValid(data.bodyHandedOverTime);

    finalMlcDto.bodyHandoverTime = InCaseDeath
      ? DateIsValid
        ? format(data.bodyHandedOverTime, "HH:mm:ss")
        : retrivedMlcData.BodyHandOverTimePatch
      : null; //add time state

    ///

    if (props.isFromForm) {
      setMlcModalData && setMlcModalData(finalMlcDto);
      handleClose();
    } else if ((privilege === "MLCEdit" || isFromAdm) && !props.isFromForm) {
      setFormData(finalMlcDto);
      setOpenConfirmationModal(true);
    }
  };

  const handleFormSubmit = (finalObj) => {
    setOpenBackdrop(true);

    postMlcData(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenConfirmationModal(false);

          // handleClosePost();
          if (finalObj.mlcNo !== "") {
            handleClose();
          } else {
            getMlcDtlls();
          }
          methods.reset();
          props.populateTable && props.populateTable();

          setOpenBackdrop(false);
          getPatientInfo && getPatientInfo();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        // handleClosePost();
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    getMlcType();
    getMlcPatientInfo();
  }, []);

  //   //   MlcType
  const getMlcType = () => {
    fetchMlcType()
      .then((response) => response.data)
      .then((res) => {
        setMlcType(res.result);
      });
  };

  //   //   MlcPtInfo
  const getMlcPatientInfo = () => {
    console.log("patientId, isEmergency", patientId, isEmergency);

    patientId &&
      fetchMlcPatientInfo(patientId, isEmergency)
        .then((response) => response.data)
        .then((res) => {
          setPatientData(res.result);
        });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form onSubmit={methods.handleSubmit(onSubmitMlc)}>
            <div>
              <div className="text-black font-bold text-lg pb-1">
                Medico Legal Case Details
              </div>
              <div className="grid w-full ">
                {/* Populate Patient Info Fields */}
                <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                  <div className="grid grid-cols-2  gap-2 py-2">
                    <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                      {/* Patient Name , UHID */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-1">
                          <span className="text-sm">Patient Name </span>
                          <span className=""> :</span>
                        </h1>

                        <h1 className="font-normal">
                          {
                            // props.patientInfo
                            // ?
                            patientData?.PatientName ||
                              (patientData && patientData["Patient Name"]) ||
                              props.patientName
                          }
                        </h1>
                      </div>
                      {/* Gender */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                          <span className="text-sm">Gender</span>
                          <span className="lg:pl-7 md:pl-6">:</span>
                        </h1>

                        <h1 className="font-normal">
                          {
                            // props.patientInfo
                            // ?
                            patientData?.gender ||
                              patientData?.Gender ||
                              props?.gender
                          }
                        </h1>
                      </div>
                    </div>

                    {/* MRNo */}
                    <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">MRNo</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="font-normal">
                          {
                            // props.patientInfo
                            // ?
                            patientData?.MRNo ||
                              patientData?.mrNo ||
                              props?.mrNo
                          }
                        </h1>
                      </div>
                      {/* age */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">Age</span>
                          <span className="md:pl-4">:</span>
                        </h1>
                        <h1 className="font-normal">
                          {
                            //props.patientInfo ?
                            patientData?.AgeYear || props.age
                          }
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isFromAdm && (
                <div className="ml-2 mt-2 ">
                  <RadioField
                    label=""
                    name="mlcTypeRadio"
                    control={control}
                    dataArray={MLCTypeArr}
                  />
                </div>
              )}

              {(
                !isFromDischargeSummary
                  ? privilege === "MLCView"
                  : privilege === "MLCEdit"
              ) ? (
                <>
                  <fieldset
                    className="border border-gray-300  w-full
               text-left lg:px-2 md:px-5 rounded p-2  "
                  >
                    <div className="py-6 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-3 ">
                      {/* <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-44">MLC Number</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {retrivedMlcData?.MLCNo}
                        </span>
                      </div>
                    </div> */}
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">
                          Reporting Date And Time
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {(retrivedMlcData?.ReportingDate || "") +
                              " " +
                              (retrivedMlcData?.ReportingTime || "")}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">
                          Name Of Authority
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {retrivedMlcData?.AuthorityName}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">
                          Authority Buckle Number
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {retrivedMlcData?.AuthorutyBuckleNo}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">
                          Police Station
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {retrivedMlcData?.PoliceStation}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">MLC Type</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {retrivedMlcData?.mlcType}
                          </span>
                        </div>
                      </div>
                      {/* ///////////////new added fields */}
                      <div className="flex items-center gap-2 w-full col-span-2  xl:col-span-3 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">
                          Brought By (Name And Address)
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {retrivedMlcData?.broughtBy}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-3 font-semibold text-sm py-1">
                        <span className=" font-semibold w-44">MLC Details</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {retrivedMlcData?.mlcDetails}
                          </span>
                        </div>
                      </div>
                      {/* //////////  In Case Of Death */}
                      {retrivedMlcData?.isDeath ? (
                        <>
                          <hr className="border lg:col-span-3 md:col-span-2 my-2 divide-x-8 bg-slate-300 border-slate-300" />
                          <div className="lg:col-span-3 md:col-span-2 text-black font-bold text-lg">
                            In Case Of Death
                          </div>
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                            <span className=" font-semibold w-44">
                              Body HandOver Date
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                                {retrivedMlcData?.BodyHandOverDate}
                              </span>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                            <span className=" font-semibold w-44">
                              Body HandOver Time
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                                {retrivedMlcData?.BodyHandOverTime}
                              </span>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                            <span className=" font-semibold w-44">
                              Authority Buckle Number
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                                {retrivedMlcData?.BodyHandOverBuckelNo}
                              </span>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                            <span className=" font-semibold w-44">
                              Police Station
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                                {retrivedMlcData?.BodyHandOverPoliceStation}
                              </span>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                            <span className=" font-semibold w-44">
                              CMO Handing Over Body
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                                {retrivedMlcData?.cmoHandingOverBody}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </fieldset>
                  {(isFromDischargeSummary ||
                    Number(mlcTypeRadio_selected) === 1) &&
                    !!retrivedMlcData && (
                      <div className="grid md:grid-cols-6 lg:grid-cols-4 gap-3 pt-4">
                        <hr className="border border-gray-400 mx-1 my-2 lg:col-span-4 md:col-span-6 " />

                        <div className="text-base font-bold lg:col-span-4 md:col-span-6 mb-2">
                          Information While Discharge
                        </div>

                        {/* nameOfAuthority */}
                        <div className="capitalize md:col-span-2 lg:col-span-1">
                          <InputField
                            name="nameOfAuthorityDischarge"
                            variant="outlined"
                            label="Name Of Authority  "
                            error={errors.nameOfAuthorityDischarge}
                            control={control}
                            // disabled={isDisabled}
                            inputProps={{ maxLength: 100 }}
                          />
                        </div>

                        {/* authorityBuckleNumber */}
                        <div className="md:col-span-2 lg:col-span-1">
                          <InputField
                            name="authorityBuckleNumberDischarge"
                            variant="outlined"
                            label="Authority Buckle Number  "
                            error={errors.authorityBuckleNumberDischarge}
                            control={control}
                            // disabled={isDisabled}
                            inputProps={{ maxLength: 100 }}
                          />
                        </div>

                        {/* policeStation */}
                        <div className="capitalize md:col-span-2 lg:col-span-1">
                          <InputField
                            name="policeStationDischarge"
                            variant="outlined"
                            label="Police Station "
                            error={errors.policeStationDischarge}
                            control={control}
                            // disabled={isDisabled}
                            inputProps={{ maxLength: 100 }}
                          />
                        </div>

                        <div className="md:col-span-6 lg:col-span-4 flex justify-end  ">
                          <div className="col-span-2 lg:col-span-3 flex justify-end gap-3">
                            <CommonButton
                              label="Reset"
                              className="border border-customRed text-customRed"
                              onClick={() => methods.reset()}
                            />
                            <CommonButton
                              type="submit"
                              label={"Save"}
                              className="bg-customGreen text-white"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </>
              ) : (
                <div className="grid md:grid-cols-6 lg:grid-cols-4 gap-3 pt-4">
                  <fieldset
                    disabled={isFromDischargeSummary}
                    className={`lg:col-span-4 md:col-span-6 grid md:grid-cols-6 lg:grid-cols-4 gap-3 pt-4 mb-2`}
                  >
                    {/* MLCNumber */}
                    {retrivedMlcData !== null && (
                      <div className="md:col-span-2 lg:col-span-1">
                        <InputField
                          name="MLCNumber"
                          variant="outlined"
                          label="MLC Number * "
                          error={errors.MLCNumber}
                          control={control}
                          disabled={true}
                          inputProps={{ maxLength: 100 }}
                        />
                      </div>
                    )}
                    {/* reporting Date Time*/}

                    <div className="md:col-span-4 lg:col-span-1 grid grid-cols-2 gap-3">
                      <DatePickerFieldNew
                        control={control}
                        name="reportingDate"
                        label="Reporting Date "
                        disableFuture={false}
                        disablePast={false}
                        inputFormat="dd-MM-yyyy"
                      />
                      <Controller
                        control={control}
                        name="reportingTime"
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopTimePicker
                              label="Reporting Time"
                              value={value}
                              onChange={onChange}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  error={errors.reportingTime}
                                  helperText={errors.reportingTime?.message}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: "  ",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>

                    {/* nameOfAuthority */}
                    <div className="capitalize md:col-span-2 lg:col-span-1">
                      <InputField
                        name="nameOfAuthority"
                        variant="outlined"
                        label="Name Of Authority  "
                        error={errors.nameOfAuthority}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>

                    {/* authorityBuckleNumber */}
                    <div className="md:col-span-2 lg:col-span-1">
                      <InputField
                        name="authorityBuckleNumber"
                        variant="outlined"
                        label="Authority Buckle Number  "
                        error={errors.authorityBuckleNumber}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>

                    {/* policeStation */}
                    <div className="capitalize md:col-span-2 lg:col-span-1">
                      <InputField
                        name="policeStation"
                        variant="outlined"
                        label="Police Station "
                        error={errors.policeStation}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>

                    {/* MLCType */}
                    <div className="md:col-span-2 lg:col-span-1">
                      <DropdownField
                        control={control}
                        // error={errors.MLCType}
                        name="MLCType"
                        placeholder="MLC Type"
                        dataArray={mlcType}
                        isSearchable={false}
                        menuPlacement={"bottom"}
                        maxMenuHeight={200}
                      />
                    </div>

                    {/* //////new added field  */}
                    <div
                      className={
                        retrivedMlcData === null
                          ? "md:col-span-6 lg:col-span-3 grid grid-cols-2 gap-3"
                          : "md:col-span-6 lg:col-span-4 grid grid-cols-2 gap-3"
                      }
                    >
                      <div className="">
                        {/* //Name And Address (Brought By).// */}

                        <InputArea
                          control={control}
                          name="broughtByInfo"
                          label="Name And Address (Brought By)"
                          placeholder="Name And Address (Brought By)"
                        />
                      </div>

                      <div>
                        <InputArea
                          control={control}
                          name="mlcDetails"
                          label="MLC Details"
                          placeholder="MLC Details"
                        />
                      </div>
                    </div>

                    <div className="capitalize md:col-span-2 lg:col-span-1">
                      <InputField
                        name="identificationMarkOne"
                        variant="outlined"
                        label="Identification Mark 1"
                        // error={errors.policidentificationMarkOneeStation}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>

                    <div className="capitalize md:col-span-2 lg:col-span-1">
                      <InputField
                        name="identificationMarkTwo"
                        variant="outlined"
                        label="Identification Mark 2"
                        // error={errors.identificationMarkTwo}
                        control={control}
                        // disabled={isDisabled}
                        inputProps={{ maxLength: 100 }}
                      />
                    </div>

                    <div className="col-span-2">
                      <CheckBoxField
                        control={control}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        name="inCaseDeath"
                        label="In Case Of Death"
                      />
                    </div>
                    {InCaseDeath === true ? (
                      <>
                        <hr className="border lg:col-span-4 md:col-span-6 my-2 divide-x-8 bg-slate-300 border-slate-300" />

                        <div className="md:col-span-2 lg:col-span-1 ">
                          <DatePickerFieldNew
                            control={control}
                            name="bodyHandedoverDate"
                            label="Body Handover Date"
                            value={new Date()}
                            disableFuture={true}
                            disablePast={false}
                            inputFormat="dd-MM-yyyy"
                          />
                        </div>

                        <div className="md:col-span-2 lg:col-span-1">
                          <Controller
                            control={control}
                            name="bodyHandedOverTime"
                            render={({ field: { value, onChange } }) => (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopTimePicker
                                  label="Body Handover Time"
                                  value={value}
                                  onChange={onChange}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      error={errors.bodyHandedOverTime}
                                      helperText={
                                        errors.bodyHandedOverTime?.message
                                      }
                                      inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          fontSize: "14px",
                                          position: "absolute",
                                          top: "-2px",
                                        },
                                      }}
                                      sx={{
                                        svg: {
                                          color: "#1e3a8a",
                                          height: 22,
                                          width: "100%",
                                          marginRight: "16px",
                                        },
                                        backgroundColor: "white",
                                        overflow: "visible",

                                        "& .MuiOutlinedInput-root": {
                                          "& .MuiOutlinedInput-input": {
                                            // border: 0,
                                            fontSize: 14,
                                            height: "18px",
                                            width: "100%",

                                            borderColor: "  ",
                                            overflow: "hidden",
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontSize: "14px",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>

                        {/* authorityBuckleNumber */}
                        <div className="md:col-span-2 lg:col-span-1">
                          <InputField
                            name="authorityBuckleNumber1"
                            variant="outlined"
                            label="Authority Buckle Number  "
                            // error={errors.authorityBuckleNumber}
                            control={control}
                            // disabled={isDisabled}
                            inputProps={{ maxLength: 100 }}
                          />
                        </div>

                        {/* policeStation */}
                        <div className="capitalize md:col-span-2 lg:col-span-1">
                          <InputField
                            name="policeStation1"
                            variant="outlined"
                            label="Police Station "
                            // error={errors.policeStation}
                            control={control}
                            // disabled={isDisabled}
                            inputProps={{ maxLength: 100 }}
                          />
                        </div>

                        {/* cmoBodyHandover */}
                        <div className="capitalize md:col-span-2 lg:col-span-1">
                          <InputField
                            name="cmoBodyHandover"
                            variant="outlined"
                            label="CMO Handing Over Body "
                            // error={errors.policeStation}
                            control={control}
                            // disabled={isDisabled}
                            inputProps={{ maxLength: 100 }}
                          />
                        </div>
                      </>
                    ) : null}
                    <div className="md:col-span-6 lg:col-span-4 flex justify-end  ">
                      {
                        // props.patientInfo &&
                        // props.patientInfo["MLC No"] !== null ||
                        // props.patientInfo.MLCNo !== null
                        // props.MLCNo === null
                        privilege === "MLCEdit" || props.isFromForm ? (
                          <div className="col-span-2 lg:col-span-3 flex justify-end gap-3">
                            <CommonButton
                              label="Reset"
                              className="border border-customRed text-customRed"
                              onClick={() => methods.reset()}
                            />
                            <CommonButton
                              type="submit"
                              label={
                                privilege === "MLCEdit" && !props.isFromForm
                                  ? "Save"
                                  : "Proceed"
                              }
                              className="bg-customGreen text-white"
                            />
                          </div>
                        ) : (
                          ""
                        )
                      }
                    </div>
                  </fieldset>
                </div>
              )}
            </div>
          </form>
        </Box>
      </Modal>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(formData)}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />{" "}
    </div>
  );
}
