import Box from "@mui/material/Box";
import React, { useEffect, useMemo, useRef } from "react";
// import CancelPresentationIconButton from "../../../Common Components/Buttons/CancelPresentationIconButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, TextField, Typography, Tooltip } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, isValid } from "date-fns";
import JoditEditor from "jodit-react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { EyeOnIcon, PrintOnIcon } from "../../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getAbnormalList,
  getFilm,
  getGenderInfo,
  getImage,
  getRadiologist,
  getReferenceDoctor,
  getReportDetails,
  getTemplates,
  updateNonPathOrder,
} from "../../Services/WorkOrderViewServices";
import UploadFileViewModal from "./UploadFileViewModal";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { DeleteIcon } from "../../../../assets/CommonAssets";
import { id } from "date-fns/locale";
const patientCondition = [
  {
    id: 1,
    label: "Regular",
    value: "Regular",
  },
  {
    id: 2,
    label: "Emergency",
    value: "Emergency",
  },
  {
    id: 3,
    label: "Critical",
    value: "Critical",
  },
];

const Actions = [
  {
    id: 0,
    action: "authorization",
    isAction: false,
  },
  {
    id: 1,
    action: "save",
    isAction: false,
  },
];

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "90%",
  minHeight: "50%",
  maxHeight: "100%",
  px: 3,
  py: 2,
  // overflowY: "scroll",
};

function TabPanel(props) {
  const { children, values, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={values !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {values === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const wordPreviewConfig = {
  addNewLine: false,
  readonly: false,
  width: 794,
  height: 1123,
  allowResizeY: false,
  statusbar: false,
  allowResizeZ: false,
  disablePlugins: "sticky,drag-and-drop-element,drag-and-drop",
  imageDefaultWidth: 100,
  uploader: {
    insertImageAsBase64URI: true,
  },
  toolbarAdaptive: false,
  buttons: [
    "bold",
    "underline",
    "strikethrough",
    "italic",
    "indent",
    "outdent",
    "image",
    "fontsize",
    "paragraph",
    "brush",
    "|",
    "align",
    "ul",
    "ol",
    "table",
    "hr",
    "symbol",
    "eraser",
    "copyformat",
    "superscript",
    "subscript",
    "undo",
    "redo",
    "find",
    "print",
  ],
};

function RadioWorkOrderViewModal(props) {
  const loggedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log("loggedUserInfo", loggedUserInfo);
  const {
    selectedTestId,
    setSelectedTestId,
    patientInfo,
    listOfTests,
    userActions,
    selectedRow1,
    isView,
    setIsView,
    open,
    setOpen,
    getPrintDetails,
    setIsWhatsApp,
    setIsEmail,
    setOpenBackdrop,
    setCheckBoxIndex,
  } = props;
  const [pathReportDetails, setPathReportDetails] = useState();
  const [pathologistList, setPathologistList] = useState();
  const [doctor, setDoctor] = useState();
  const [doctorSearchString, setDoctorSearchString] = useState();
  const [finalData, setFinalData] = useState();
  const [openPost, setOpenPost] = useState(false);
  const [tabData, setTabData] = useState();
  const [postArray, setPostArray] = React.useState([]);
  const [drugSensitivityArr, setDrugSensitivityArr] = useState([]);
  const [requiredArr, setRequiredArr] = React.useState([]);
  const [finalArr, setFinalArr] = React.useState([]);
  const [getOrganismId, setGetOrganismId] = useState();
  const [fileSrc, setFileSrc] = React.useState(null);
  const [pdfFileBase64, setPdfFileBase64] = React.useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [content1, setContent1] = React.useState("");
  const [radiologistList, setRadiologistList] = useState();
  const [abnormalList, setAbnormalList] = useState();
  const [genderList, setGenderList] = useState();
  const [referenceDoctorList, setReferenceDoctorList] = useState();
  const [filmOptions, setFilmOptions] = useState();
  const [templates, setTemplates] = useState();
  const [reportDetails, setReportDetails] = useState(null);
  const [privilege, setPrivilege] = React.useState("");
  const [timeInformation, setTimeInformation] = React.useState(new Date());
  const [uploadFileData, setUploadFileData] = useState(null);
  const [openWordPreviewModal, setOpenWordPreviewModal] = useState(false);
  const [filmsList, setFilmsList] = useState([]);

  const joditRef = useRef(null);
  const handleClosePost = () => {
    setOpenPost(false);
  };

  // const remarkRef = useRef();

  // const config2 = {
  //   readonly:
  //     (reportDetails !== null &&
  //       reportDetails?.FilePath !== null &&
  //       !reportDetails?.FilePath?.includes("null")) ||
  //     uploadFileData !== null
  //       ? true
  //       : false,
  //   useSearch: false,
  //   spellcheck: false,
  //   enter: "P",
  //   defaultMode: "1",
  //   toolbarAdaptive: false,
  //   toolbarSticky: false,
  //   showCharsCounter: false,
  //   showWordsCounter: false,
  //   showXPathInStatusbar: false,
  //   askBeforePasteHTML: false,
  //   askBeforePasteFromWord: false,
  //   minHeight: 370,
  //   maxHeight: 450,
  //   minWidth: null,
  //   buttons: [
  //     "bold",
  //     "underline",
  //     "strikethrough",
  //     "italic",
  //     "font",
  //     "indent",
  //     "outdent",
  //     "image",
  //     "fontsize",
  //     "paragraph",
  //     "brush",
  //     "|",
  //     "align",
  //     "ul",
  //     "ol",
  //     "table",
  //     "hr",
  //     "symbol",
  //     "eraser",
  //     "copyformat",
  //     "superscript",
  //     "subscript",
  //     "undo",
  //     "redo",
  //     "find",
  //     "preview",
  //     "print",
  //     "fullsize",
  //   ],
  //   editorCssClass: "alic",
  //   placeHolder: "",
  //   controls: {
  //     fontsize: {
  //       list: [
  //         "8",
  //         "9",
  //         "10",
  //         "11",
  //         "12",
  //         "14",
  //         "16",
  //         "18",
  //         "24",
  //         "30",
  //         "36",
  //         "48",
  //         "60",
  //         "72",
  //         "96",
  //         "100",
  //       ],
  //     },
  //     font: {
  //       list: {
  //         "": "Default",
  //         "'Open Sans', sans-serif": "Open Sans",
  //         "Helvetica, sans-serif": "Helvetica",
  //         "Arial, Helvetica, sans-serif": "Arial",
  //         "Georgia, serif": "Georgia",
  //         "Impact, Charcoal, sans-serif": "Impact",
  //         "Tahoma, Geneva, sans-serif": "Tahoma",
  //         "'Times New Roman', Times, serif": "Times New Roman",
  //         "Verdana, Geneva, sans-serif": "Verdana",
  //       },
  //     },
  //   },
  // };

  const config2 = useMemo(
    () => ({
      readonly:
        (reportDetails !== null &&
          reportDetails?.FilePath !== null &&
          reportDetails?.FilePath !== "") ||
        uploadFileData !== null
          ? true
          : false,
      useSearch: false,
      spellcheck: false,
      enter: "P",
      defaultMode: "1",
      toolbarAdaptive: false,
      toolbarSticky: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      minHeight: 370,
      maxHeight: 450,
      minWidth: null,
      buttons: [
        "bold",
        "underline",
        "strikethrough",
        "italic",
        "font",
        "indent",
        "outdent",
        "image",
        "fontsize",
        "paragraph",
        "brush",
        "|",
        "align",
        "ul",
        "ol",
        "table",
        "hr",
        "symbol",
        "eraser",
        "copyformat",
        "superscript",
        "subscript",
        "undo",
        "redo",
        "find",
        "preview",
        "print",
        "fullsize",
      ],
      editorCssClass: "alic",
      placeHolder: "",
      controls: {
        fontsize: {
          list: [
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "16",
            "18",
            "24",
            "30",
            "36",
            "48",
            "60",
            "72",
            "96",
            "100",
          ],
        },
        font: {
          command: "fontname",
          list: {
            "": "Default",
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
          },
        },
      },
    }),
    []
  );

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  React.useEffect(() => {
    if (
      reportDetails?.FilePath !== "" &&
      reportDetails?.FilePath !== undefined &&
      reportDetails?.FilePath !== null
    ) {
      let splitPath = reportDetails?.FilePath?.split("/");

      let imageType = splitPath[splitPath?.length - 1]?.split(".")?.[1];

      // ${baseUrl}/file${reportDetails?.FilePath}
      getImage(splitPath).then((response) => {
        if (imageType === "pdf") {
          //Get Reponse JSON
          const blb = new Blob([response.data], { type: "text/plain" });
          const reader = new FileReader();
          // Start reading the blob as text.
          reader.readAsText(blb);
          //Convert Blob Response to JSON String
          reader.addEventListener("loadend", (e) => {
            if (e.target.result.charAt(0) !== "{") {
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              let fileObject = {
                type: imageType,
                src: fileURL,
              };
              setFileSrc(fileObject);
            }
          });
          let newPdfString = arrayBufferToBase64(response.data);
          setPdfFileBase64(newPdfString);
        } else {
          let newImage =
            "data:image/jpg;base64," + arrayBufferToBase64(response.data);
          let fileObject = {
            type: imageType,
            src: newImage,
          };
          setFileSrc(fileObject);
        }
      });
    }
  }, [reportDetails]);

  const [values, setValues] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  const defaultValues = {
    // radiologist1: null,
    gender: null,
    abnormal: null,
    template: null,
    films: null,
    noOfFilms: "",
    patientCondition: null,
    radiologist: null,
    referredDoctor: null,
    templateDescription: "",
    timeOfInformation: new Date(),
    noOfContrast: "",
    criticalFinding: "",
    uploadDocument: "",
    delayReason: "",
    patientHistory: "",
  };

  const schema = yup.object().shape({
    remark: yup.object().when(() => {
      if (selectedTestId?.IsCompleted === true) {
        return yup.string().required();
      } else {
        return yup.string().notRequired();
      }
    }),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  let getTemplateData = watch("template");
  let getFilms = watch("films");
  let getNoOfFilms = watch("noOfFilms");
  let getDelayReason = watch("delayReason");
  let getPatientHistory = watch("patientHistory");
  let getRemark = watch("remark");

  console.log("getDelayReason123", getDelayReason);

  function radiologistDropdown() {
    getRadiologist().then((response) => {
      setRadiologistList(response.data.result);
    });
  }
  function genderDropdown() {
    getGenderInfo().then((response) => {
      setGenderList(response.data.result);
    });
  }
  function abnormalDropdown() {
    getAbnormalList().then((response) => {
      setAbnormalList(response.data.result);
    });
  }
  function referenceDoctorDropdown(searchString) {
    if (searchString !== "") {
      getReferenceDoctor(searchString).then((response) => {
        setReferenceDoctorList(response.data.result);
      });
    }
  }

  function filmDropdown() {
    getFilm().then((response) => {
      setFilmOptions(response.data.result);
    });
  }

  function templateDropdown() {
    getTemplates(selectedTestId?.OrderDtlsId).then((response) => {
      setTemplates(response.data.result);
    });
  }

  const fetchReportDetails = () => {
    if (
      (selectedTestId?.ThirdLevelDescription !== null &&
        selectedTestId?.ThirdLevelDescription !== "") ||
      (selectedTestId?.SecondLevelDescription !== null &&
        selectedTestId?.SecondLevelDescription !== "") ||
      (selectedTestId?.FirstLevelDescription !== null &&
        selectedTestId?.FirstLevelDescription !== "")
    ) {
      getReportDetails(selectedTestId?.OrderDtlsId).then((response) => {
        setReportDetails(response.data.result);
      });
    }
  };

  console.log("reportDetails123", reportDetails, uploadFileData);
  console.log("uploadFileData123", uploadFileData);

  useEffect(() => {
    if (open === true) {
      radiologistDropdown();
      genderDropdown();
      abnormalDropdown();
      filmDropdown();
      templateDropdown();
      fetchReportDetails();
    }

    {
      !!loggedUserInfo?.doctorId &&
        !!loggedUserInfo?.doctorName &&
        setValue("radiologist", {
          id: loggedUserInfo?.doctorId,
          label: loggedUserInfo?.doctorName,
          value: loggedUserInfo?.doctorName,
        });
    }
  }, [open]);

  const updateNonPathOrderView = () => {
    props.setOpenBackdrop(true);
    updateNonPathOrder(finalData)
      .then((response) => response.data)
      .then((res) => {
        props.setOpenBackdrop(false);
        successAlert(res.message);
        // handleClosePost();
        // props.handleClose();
        // reset(defaultValues);
        listOfTests();
        // setContent1("");
        // setTimeInformation(new Date());
        // setUploadFileData(null);
        // setReportDetails(null);
        // setFilmsList([]);
        setValue(
          "remark",
          selectedTestId?.HistoryOfPresentIllness !== null
            ? selectedTestId?.HistoryOfPresentIllness
            : ""
        );
      })
      .catch((error) => {
        errorAlert(error?.response?.data?.message || error?.message);
        props.setOpenBackdrop(false);
        // handleClosePost();
      });
    setOpenPost(false);
  };

  console.log("filmsList123", errors);
  const onSubmitData = (data) => {
    console.log("datadatadata123", data);
    let fimlIds = [];
    filmsList?.map((val) => {
      let filmOject = {
        filmId: Number(val?.FilmId),
        noOfFilms: Number(val["No Of Films"]),
      };
      fimlIds.push(filmOject);
    });
    let base64String;
    let blob = new Blob([fileSrc?.src], { type: "text/plain" });
    // The full Blob Object can be seen
    // in the Console of the Browser

    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      base64String = reader.result;
    };

    let isFileUpload;
    if (uploadFileData !== null) {
      isFileUpload = true;
    } else {
      isFileUpload = false;
    }

    let updateObj = {
      menuId: props.menuId,
      privilege: privilege,
      doctorId: 0,
      filmList: fimlIds?.length > 0 ? fimlIds : null,
      microbiologist: 0,
      // noOfFilms: data.noOfFilms ? Number(data.noOfFilms) : 0,
      orderDtlsId: props.patientInfo ? props.patientInfo.OrderDtlsId : 0,
      // radiologist1Id: data.radiologist1 ? data.radiologist1.id : 0,
      // radiologist2Id: data.radiologist1 ? data.radiologist1.id : 0,
      radiologistId: data.radiologist ? data.radiologist.id : 0,
      referredBy: data.referredDoctor ? data.referredDoctor.label : 0,
      printTestName: selectedTestId?.["Test Name"],
      tempDocId: 0,
      tempGenderId: 0,
      tempResultId: 2,
      templateId: data.template ? data.template?.id : 0,
      thirdLevelDescription: content1?.replace(
        /<style[^>]*>[\s\S]*?<\/style>/gi,
        " "
      ),
      criticalFinding: data.criticalFinding,
      noOfContrast: data.noOfContrast,
      timeOfInformation: data.timeOfInformation,
      isAuthorised: privilege === "Create" ? false : true,
      authorizedRemark: data.remark ? data.remark : "",
      patientCondition: Number(data.patientCondition),
      orderId: selectedRow1?.OrderId,
      isFileUpload: isFileUpload,
      oldFilePath: uploadFileData === null ? reportDetails?.FilePath : null,
      // filePath:
      //   reportDetails !== null &&
      //   reportDetails?.FilePath?.includes(".pdf") === false
      //     ? fileSrc?.src?.split(",")[1]
      //     : pdfFileBase64 !== ""
      //     ? pdfFileBase64
      //     : uploadFileData !== null
      //     ? uploadFileData?.fileBase64
      //     : "",
      filePath: uploadFileData !== null ? uploadFileData?.fileBase64 : "",
      // fileName:
      //   reportDetails !== null
      //     ? reportDetails?.FilePath?.split("/")[6]
      //     : uploadFileData !== null
      //     ? uploadFileData?.fileName
      //     : null,
      fileName: uploadFileData !== null ? uploadFileData?.fileName : null,
      opdIpdExternalId: selectedRow1?.Opd_Ipd_External_Id,
      opdIpd: selectedRow1?.Opd_Ipd_External,
      mrNo: selectedRow1?.MRNo,
      delayReason: data.delayReason,
      patientHistory: data.patientHistory,
    };
    let DateIsValidReportingTime = isValid(timeInformation);
    updateObj.timeOfInformation = DateIsValidReportingTime
      ? format(timeInformation, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : timeInformation.concat("Z");

    if (data.patientCondition === null) {
      warningAlert("Select the Patient Condition...!");
    }
    if (filmsList?.length === 0) {
      warningAlert("Please select film...!");
    }
    if (data.patientCondition !== null && filmsList?.length > 0) {
      console.log("updateObj123", updateObj);
      setFinalData(updateObj);
      setOpenPost(true);
    }
  };

  const handleFileChange = (event) => {
    let selectedFile = event.target.files;

    let file = "";
    let fileName = "";
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      let fileToLoad = selectedFile[0];
      fileName = fileToLoad.name;
      // FileReader function for read the file.
      let fileReader = new FileReader();
      fileReader.readAsDataURL(fileToLoad);
      // Onload of file read the file content
      fileReader.onload = () => {
        file = fileReader.result;

        setUploadFileData({
          fileBase64: fileReader.result?.split(",")[1],
          fileName: fileName,
        });
      };
    }
  };

  useEffect(() => {
    if (reportDetails !== null) {
      let getFilmName;
      // let getRadiologist1Name;
      let getRadiologistName;
      let getReferredBy;
      let getGender;
      let getAbnormal;
      let getTemplateName;
      let getFilmsArray = [];
      let films = JSON.parse(reportDetails?.Films);
      if (films?.length > 0) {
        films?.map((val) => {
          getFilmsArray.push({
            FilmId: val.filmId,
            Film: val.Films,
            "No Of Films": val.NoOfFilms,
          });
        });
        setFilmsList(getFilmsArray);
      }
      console.log(
        "reportDetails12345",
        JSON.parse(reportDetails?.Films),
        getFilmsArray
      );
      if (
        reportDetails?.TemplateId !== null &&
        reportDetails?.TemplateId > 0 &&
        reportDetails?.Template !== "" &&
        reportDetails?.Template !== null
      ) {
        getTemplateName = {
          id: reportDetails?.TemplateId,
          label: reportDetails?.Template,
          value: reportDetails?.Template,
        };
      }
      if (reportDetails?.FlimId !== null && reportDetails?.Flim !== "") {
        getFilmName = {
          id: reportDetails?.FlimId,
          label: reportDetails?.Flim,
          value: reportDetails?.Flim,
        };
      }
      // if (
      //   reportDetails?.Radiologist1Id !== null &&
      //   reportDetails?.Radiologist1 !== ""
      // ) {
      //   getRadiologist1Name = {
      //     id: reportDetails?.Radiologist1Id,
      //     label: reportDetails?.Radiologist1,
      //     value: reportDetails?.Radiologist1,
      //   };
      // }
      if (
        reportDetails?.RadiologistId !== null &&
        reportDetails?.Radiologist !== ""
      ) {
        getRadiologistName = {
          id: reportDetails?.RadiologistId,
          label: reportDetails?.Radiologist,
          value: reportDetails?.Radiologist,
        };
      }
      if (reportDetails?.ReferredBy !== "") {
        getReferredBy = {
          label: reportDetails?.ReferredBy,
          value: reportDetails?.ReferredBy,
        };
      }
      if (reportDetails?.GenderId !== null && reportDetails?.Gender !== "") {
        getGender = {
          id: reportDetails?.GenderId,
          label: reportDetails?.Gender,
          value: reportDetails?.Gender,
        };
      }
      if (
        reportDetails?.AbnormalId !== null &&
        reportDetails?.Abnormal !== null
      ) {
        getAbnormal = {
          id: reportDetails?.AbnormalId,
          label: reportDetails?.Abnormal,
          value: reportDetails?.Abnormal,
        };
      }

      setValue("template", getTemplateName ? getTemplateName : null);
      // setValue("noOfFilms", reportDetails?.NoOfFlims);
      // setValue("films", getFilmsArray?.length > 0 ? getFilmsArray : null);
      setValue("delayReason", reportDetails?.DelayReason);
      setValue("patientHistory", reportDetails?.PatientHistory);
      // setValue("radiologist1", getRadiologist1Name ? getRadiologist1Name : "");
      setValue("radiologist", getRadiologistName ? getRadiologistName : "");
      setValue("referredDoctor", getReferredBy ? getReferredBy : "");
      // setValue("gender", getGender ? getGender : "");
      // setValue("abnormal", getAbnormal ? getAbnormal : "");
      setValue("noOfContrast", reportDetails?.NoOfContrast);
      setValue("criticalFinding", reportDetails?.CriticalFinding);
      setValue("patientCondition", reportDetails?.patientCondition);
      setContent1(
        reportDetails?.ThirdLevelDescription?.replace(
          /<style[^>]*>[\s\S]*?<\/style>/gi,
          " "
        )
      );
      setTimeInformation(
        reportDetails?.TimeOfInform !== null
          ? `1900-01-01T${reportDetails?.TimeOfInform}.000`
          : new Date()
      );
      setValue(
        "remark",
        selectedTestId?.HistoryOfPresentIllness !== null
          ? selectedTestId?.HistoryOfPresentIllness
          : ""
      );
      // setValue(
      //   "timeOfInformation",
      //   `1900-01-01T${reportDetails?.TimeOfInform}.000`
      // );
    }
  }, [reportDetails]);

  const contentRef = useRef();

  const generatePDF = () => {
    // Clone the content of the div for printing
    const clonedContent = contentRef.current.cloneNode(true);
    const printStyles = `
    body {
      font-family: Arial, sans-serif;
    }
    .print:last-child {
      page-break-after: auto;
    }
    @media screen {
      @page {
        size: landscape !important;
      }
      #pdfContent {
        display: none !important;
      }
    }
    @media print {
      body * {
        
        visibility: hidden;
      }
      #pdfContent, #pdfContent * {
        visibility: visible;
      }
      #pdfContent {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    `;

    const style = document.createElement("style");
    style.innerHTML = printStyles;

    clonedContent.id = "pdfContent";
    document.body.appendChild(style);
    document.body.appendChild(clonedContent);

    window.print();

    document.body.removeChild(style);
    document.body.removeChild(clonedContent);
  };

  const addFilmsData = () => {
    let duplicateFilm = filmsList?.some(
      (film) => film?.FilmId === getFilms?.id
    );
    if (getFilms === null) {
      setError("films", { type: "custom", message: "Required" });
    }
    if (getNoOfFilms === "") {
      setError("noOfFilms", { type: "custom", message: "Required" });
    }
    if (getFilms !== null && getNoOfFilms !== "") {
      let filmsObj = {
        Film: getFilms?.label,
        FilmId: getFilms?.id,
        "No Of Films": getNoOfFilms,
      };
      if (!duplicateFilm) {
        setFilmsList([...filmsList, { ...filmsObj }]);
        setValue("films", null);
        setValue("noOfFilms", "");
      } else {
        warningAlert("Film already exists...!");
      }
    }
  };

  const renderFilmsAction = (row, index) => {
    return (
      <div>
        <button
          type="button"
          onClick={() => {
            let tempFilms = [...filmsList];
            tempFilms?.splice(index, 1);
            setFilmsList(tempFilms);
          }}
        >
          <DeleteIcon />
        </button>
      </div>
    );
  };

  console.log("content123", content1);

  return (
    <>
      <div className="hidden">
        <div ref={contentRef}>
          <img src={fileSrc?.src} />
        </div>
      </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <button
            onClick={() => {
              setUploadFileData(null);
              setIsView(false);
              reset(defaultValues);
              setContent1("");
              setReportDetails(null);
              setOpen(false);
              setFilmsList([]);
              // setSelectedTestId(null);
              // setCheckBoxIndex(-1);
            }}
          >
            <CancelPresentationIconButton />
          </button>
          <form onSubmit={handleSubmit(onSubmitData)}>
            <div className="mb-2">
              <div className="my-2 -mt-2">
                <div className="gap-2 w-full my-2 space-y-2">
                  <div className="grid lg:grid-cols-2 xl:grid-cols-3 bg-[#F1F1F1] border border-gray-400 rounded p-2">
                    <div className="flex">
                      <span className="font-bold w-28 lg:w-28">
                        Patient Name&nbsp;
                      </span>
                      <span>: {patientInfo?.PatientName}</span>
                    </div>
                    {patientInfo?.IPDNO ? (
                      <div className="flex">
                        <span className="whitespace-nowrap font-bold w-28 lg:w-16 xl:w-24">
                          IPD No&nbsp;
                        </span>
                        <span>: {patientInfo?.IPDNO}</span>
                      </div>
                    ) : (
                      <div className="flex">
                        <span className="whitespace-nowrap font-bold w-28 lg:w-16 xl:w-24">
                          OPD No&nbsp;
                        </span>
                        <span>: {patientInfo?.OPDNO}</span>
                      </div>
                    )}
                    <div className="flex">
                      <span className="font-bold w-28 xl:w-10">Age&nbsp;</span>
                      <span>: {patientInfo?.AgeYear}</span>
                    </div>
                    <div className="flex">
                      <span className="font-bold w-28 lg:w-16 xl:w-28">
                        Gender&nbsp;
                      </span>
                      <span className="flex gap-2 items-center">
                        :&nbsp;
                        {patientInfo?.Gender}
                      </span>
                    </div>
                    <div className="flex lg:col-span-2">
                      <span className="font-bold w-28 xl:w-24">
                        Test Name&nbsp;
                      </span>
                      <span className="whitespace-nowrap">
                        :&nbsp;{selectedTestId?.["Test Name"]}
                      </span>
                    </div>
                  </div>
                  <div className="w-full gap-x-2 gap-y-3 grid grid-cols-2 xl:grid-cols-4">
                    <div className="col-span-2 grid grid-cols-2 gap-x-2 gap-y-3 items-center">
                      {/* <div className="w-full">
                        <DropdownField
                          control={control}
                          name="gender"
                          placeholder="Select Gender"
                          dataArray={genderList}
                          // error={errors.gender}
                        />
                      </div>
                      <div className="w-full">
                        <DropdownField
                          control={control}
                          name="abnormal"
                          placeholder="Abnormal"
                          dataArray={abnormalList}
                          // error={errors.abnormal}
                        />
                      </div> */}
                      <div className="w-full">
                        <DropdownField
                          isDisabled={
                            (reportDetails !== null &&
                              reportDetails?.FilePath !== null &&
                              reportDetails?.FilePath !== "") ||
                            uploadFileData !== null
                          }
                          control={control}
                          name="template"
                          placeholder="Template"
                          dataArray={templates}
                          isClearable={true}
                          inputRef={{
                            ...register("template", {
                              onChange: (e) => {
                                if (e.target.value !== null) {
                                  setContent1(
                                    e.target.value?.value?.replace(
                                      /<style[^>]*>[\s\S]*?<\/style>/gi,
                                      " "
                                    )
                                  );
                                } else if (e.target.value === null) {
                                  setContent1("");
                                }
                              },
                            }),
                          }}
                        />
                      </div>
                      <div className="flex space-x-2 items-center">
                        <div className="flex justify-between w-full items-center">
                          <div>
                            <input
                              className="w-60"
                              disabled={
                                (getTemplateData !== null &&
                                  getTemplateData !== undefined) ||
                                (content1 !== "" && content1 !== "<p><br></p>")
                              }
                              type="file"
                              name="uploadDocument"
                              onChange={handleFileChange}
                            />

                            {/*}: !reportDetails?.FilePath?.includes(".pdf") ? (
                          <button
                            type="button"
                            onClick={() => {
                              setOpenImageModal(true);
                            }}
                          >
                            <EyeOnIcon title="View File" />
                          </button>
                          ) : null}*/}
                          </div>
                          <Tooltip title="Word Preview">
                            <button
                              type="button"
                              onClick={() => {
                                setOpenWordPreviewModal(true);
                              }}
                            >
                              <FullscreenIcon />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      <fieldset className="flex border items-center justify-center w-full bg-white px-2 -mt-2">
                        <legend className="font-semibold text-xs px-2">
                          Patient Condition
                        </legend>
                        <div className="-my-1">
                          <RadioField
                            control={control}
                            name="patientCondition"
                            dataArray={patientCondition}
                          />
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-span-2 grid grid-cols-4 gap-x-2 gap-y-3">
                      <div className="w-full">
                        <DropdownField
                          control={control}
                          name="films"
                          placeholder="Films"
                          dataArray={filmOptions}
                          error={errors.films}
                        />
                      </div>
                      <div className="w-full flex space-x-2">
                        <InputField
                          type="Number"
                          control={control}
                          name="noOfFilms"
                          label="No. Of Films"
                          error={errors.noOfFilms}
                        />
                        <CommonButton
                          type="button"
                          label="Add"
                          className="bg-customBlue text-white"
                          onClick={() => {
                            addFilmsData();
                          }}
                        />
                      </div>
                      <div className="col-span-2 -mt-2">
                        {filmsList !== undefined && filmsList?.length > 0 ? (
                          <CommonDynamicTableNew
                            dataResult={filmsList}
                            removeHeaders={["FilmId"]}
                            renderActions={renderFilmsAction}
                            highlightRow={false}
                            tableClass={"max-h-24"}
                          />
                        ) : (
                          <div className="flex mt-2 h-20 justify-center items-center w-full font-semibold border rounded">
                            No Films Found...
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <fieldset
                  ref={joditRef}
                  disabled={
                    (reportDetails !== null &&
                      reportDetails?.FilePath !== null &&
                      reportDetails?.FilePath !== "") ||
                    uploadFileData !== null
                  }
                  onKeyDown={(key) => {
                    console.log("keykeykey123", key);

                    if (key.key === "Tab" || key.key === "Alt") {
                      key.preventDefault();
                    }
                  }}
                >
                  <JoditEditor
                    name="templateDescription"
                    config={config2}
                    value={
                      content1 !== null
                        ? content1
                            ?.replace(
                              /(?:\.\s+)([a-z])/g,
                              (match, char) => `. ${char.toUpperCase()}`
                            )
                            ?.replace(
                              /(?:\.+)([a-z])/g,
                              (match, char) => `. ${char.toUpperCase()}`
                            )
                        : ""
                    }
                    onBlur={(c) => {
                      if (c !== "") {
                        //abhijeet 31102024_20_40
                        const formattedContent = c
                          .replace(
                            /(?:\.\s+)([a-z])/g,
                            (match, char) => `. ${char.toUpperCase()}`
                          )
                          ?.replace(
                            /(?:\.+)([a-z])/g,
                            (match, char) => `. ${char.toUpperCase()}`
                          );
                        setContent1(formattedContent);

                        //  setContent1(c);  //old
                      }
                    }}
                    onChange={() => {
                      joditRef.current.focus();
                    }}
                  />
                </fieldset>
              </div>
              <div className="grid grid-cols-5 w-full gap-x-2 gap-y-4">
                <div className="w-full col-span-2">
                  <InputField
                    name="criticalFinding"
                    label="Brief Emergency Critical Finding"
                    control={control}
                  />
                </div>
                <div className="w-full flex space-x-2">
                  <Controller
                    control={control}
                    // rules={{
                    //   required: true,
                    // }}
                    name="timeOfInformation"
                    defaultValue={new Date()}
                    render={({ field: { value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopTimePicker
                          className="bg-white w-48"
                          label="Time Of Information"
                          name="timeOfInformation"
                          value={timeInformation}
                          onChange={(newValue) => {
                            setTimeInformation(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              size="small"
                              name="timeOfInformation"
                              {...params}
                              sx={{
                                svg: {
                                  color: "#1E3A8A",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",

                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",

                                    borderColor: "  ",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <InputField
                    control={control}
                    name="noOfContrast"
                    label="No. Of Contrast Used"
                  />
                </div>
                <div>
                  <DropdownField
                    control={control}
                    name="radiologist"
                    placeholder="Select Radiologist"
                    dataArray={radiologistList}
                    error={errors.radiologist}
                  />
                </div>
                <div>
                  <SearchDropdown
                    control={control}
                    name="referredDoctor"
                    placeholder="Select Referred Doctor"
                    isSearchable={true}
                    dataArray={referenceDoctorList}
                    handleInputChange={referenceDoctorDropdown}
                    error={errors.referredDoctor}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 my-3">
                <div className="col-span-2">
                  <Tooltip title={getDelayReason} placement="top">
                    <TextField
                      name="delayReason"
                      aria-label="minimum height"
                      multiline
                      maxRows={2}
                      placeholder="Delay Reason"
                      label="Delay Reason"
                      fullWidth
                      size="small"
                      {...register("delayReason")}
                    />
                  </Tooltip>
                </div>
                {selectedTestId?.IsCompleted === true && (
                  <div className="col-span-2">
                    <Tooltip title={getRemark} placement="top">
                      <TextField
                        // ref={remarkRef}
                        name="remark"
                        aria-label="minimum height"
                        multiline
                        maxRows={2}
                        placeholder="Remark"
                        label="Remark"
                        fullWidth
                        size="small"
                        {...register("remark")}
                        error={errors.remark}
                        // onChange={() => {
                        //   remarkRef.current.focus();
                        // }}
                      />
                    </Tooltip>
                  </div>
                )}
                <div className="col-span-2">
                  <Tooltip title={getPatientHistory} placement="top">
                    <TextField
                      name="patientHistory"
                      aria-label="minimum height"
                      multiline
                      maxRows={2}
                      placeholder="Patient History"
                      label="Patient History"
                      fullWidth
                      size="small"
                      {...register("patientHistory")}
                    />
                  </Tooltip>
                </div>
                {isView === false && (
                  <div
                    className={`${
                      selectedTestId?.IsCompleted === true
                        ? "col-span-2"
                        : "col-span-4"
                    } flex justify-end space-x-2 my-1 `}
                  >
                    <div>
                      <CommonButton
                        type="button"
                        label="Close"
                        className="border border-customRed text-customRed"
                        onClick={() => {
                          setUploadFileData(null);
                          setIsView(false);
                          reset(defaultValues);
                          setContent1("");
                          setReportDetails(null);
                          setOpen(false);
                          setFilmsList([]);
                          // setSelectedTestId(null);
                          // setCheckBoxIndex(-1);
                        }}
                      />
                    </div>
                    <div className="flex space-x-2">
                      <div>
                        {userActions?.map((action, i) => (
                          <>
                            {action.isAction === false &&
                            action.action === "Authorise" ? (
                              <div className="flex gap-3">
                                <CommonButton
                                  type="submit"
                                  label="Authorization"
                                  className="border border-customOrange text-customOrange"
                                  disabled={
                                    !selectedTestId?.IsProcedureEnd ||
                                    (selectedTestId?.IsProcedureEnd &&
                                      selectedTestId?.IsCompleted)
                                  }
                                  onClick={() => {
                                    setPrivilege(action.action);
                                  }}
                                />
                              </div>
                            ) : null}
                          </>
                        ))}
                      </div>
                      <CommonButton
                        type="button"
                        label="Print"
                        className="border border-customOrange text-customOrange cursor-pointer"
                        onClick={() => {
                          getPrintDetails(selectedTestId);
                          setIsWhatsApp(false);
                          setIsEmail(false);
                          setOpenBackdrop(true);
                        }}
                      />
                      <div>
                        {userActions?.map((action, i) => (
                          <>
                            {action.isAction === false &&
                            action.action === "Create" ? (
                              <CommonButton
                                type="submit"
                                label="Save"
                                className="bg-customGreen text-white"
                                onClick={() => {
                                  setPrivilege(action.action);
                                }}
                              />
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <UploadFileViewModal
              openImageModal={openImageModal}
              setOpenImageModal={setOpenImageModal}
              fileSrc={fileSrc}
              generatePDF={generatePDF}
            />
            <ConfirmationModal
              confirmationOpen={openPost}
              confirmationHandleClose={handleClosePost}
              confirmationSubmitFunc={updateNonPathOrderView}
              confirmationLabel="Confirmation"
              confirmationMsg="Are you sure want to add this Record ?"
              confirmationButtonMsg="Add"
            />
          </form>
        </Box>
      </Modal>
      <Modal open={openWordPreviewModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 20,
            maxWidth: "100%",
            minHeight: "50%",
            maxHeight: "100%",
            px: 3,
            py: 2,
            overflowY: "scroll",
          }}
        >
          <div className="space-y-5">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setOpenWordPreviewModal(false);
                }}
              >
                <CancelPresentationIconButton />
              </button>
            </div>
            <JoditEditor
              name="templateDescription"
              config={wordPreviewConfig}
              value={
                content1 !== null
                  ? content1?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                  : " "
              }
              onBlur={(c) => {
                if (c !== "") {
                  setContent1(c);
                }
              }}
            />
          </div>
        </Box>
      </Modal>
      <CommonBackDrop openBackdrop={props.openBackdrop} />
    </>
  );
}

export default RadioWorkOrderViewModal;
