import React from "react";
import { Controller, useForm } from "react-hook-form";
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { fetchFloor } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchAllNursingNightReportList,
  searchViewlisting,
} from "../../../services/nursingReportServices/nursingTakeOverDetailsServices";
import CommonTable from "./NursingNightReportList";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "85%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};
const actions = ["print"];
function View(props) {
  
  const schema = {};
  const defaultValues = {};
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    register,
    reset,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [fromTime, setFromTime] = React.useState(new Date());

  const [floorDropdown, setFloorDropdown] = React.useState();
  const [searchString, setSearchString] = React.useState([]);
  const [nursingNightReportList, setNursingNightReportList] = React.useState(
    []
  );
  let floorVal = watch("floor");
  let fromDateVal = getValues("fromDate");
  let searchVisitListVal = getValues("nursingNightReportViewSearrch");
  

  // seatch string
  function handleChange(string) {
    let postObj = {
      floorId: floorVal && floorVal.id,
      fromDate: selectedFromDate ? selectedFromDate : new Date(),
      searchString: string,
      toDate: selectedToDate ? selectedToDate : new Date(),
    };
    

    searchViewlisting(postObj)
      .then((response) => response.data)
      .then((res) => {
        
        setSearchString(res?.result);
      });
  }

  // floor dropdown api
  React.useEffect(() => {
    if(props?.open)
    {
      fetchFloor()
      .then((response) => response.data)
      .then((res) => {
        setFloorDropdown(res.result);
        setValue("floor", res.result[2]);
      });
    }
    
  }, []);

  // fetch nursing suerpervisor list
  React.useEffect(() => {
    fetchListOfNursingNightReport();
  }, []);

  function fetchListOfNursingNightReport() {
    let postObj = {
      floorId: floorVal && floorVal.id,
      fromDate: selectedFromDate,
      searchId: searchVisitListVal?.id,
      searchString: null,
      toDate: selectedToDate,
    };
    
   
    fetchAllNursingNightReportList(postObj)
      .then((response) => response.data)
      .then((res) => {
        
       
        setNursingNightReportList(res.result);
      });
  }
  return (
    <div className=" bg-white px-6">
      <Modal
        open={props.open}
        onClose={() => {
          // props.handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  py-2 w-full">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
                setSelectedFromDate(new Date());
              }}
              style={{
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="grid grid-cols-3 gap-2 py-2">
            <div>
              {/*** <FormControl className="lg:w-full ">
                <OutlinedInput
                  size="small"
                  sx={{ height: 36 }}
                  {...register("searchVisitList")}
                  name="searchVisitList"
                  placeholder="Search By Patient Name / MR.No./ Mobile No."
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl> */}

              <SearchDropdown
                control={control}
                name="nursingNightReportViewSearrch"
                placeholder="Search By Patient Name/ MR No./Mobile No"
                dataArray={searchString}
                searchIcon={true}
                isClearable={true}
                isSearchable={true}
                handleInputChange={handleChange}
                inputRef={{
                  ...register("nursingNightReportViewSearrch", {
                    onChange: (e) => {},
                  }),
                }}
              />
            </div>
            <div className="flex col-span-2 gap-2">
              <div className="w-48">
                <DatePickerFieldNew
                  control={control}
                  name="fromDateCalender"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                  inputRef={{
                    ...register("fromDateCalender", {
                      onChange: (e) => {
                        
                        
                        if (e.target.value.toString().length === 55) {
                          let dateVal = format(e.target.value, "yyyy-MM-dd");
                          let timeVal = dateVal + "T" + "00:00:00.000Z";
                          setSelectedFromDate(timeVal);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-48">
                <DatePickerFieldNew
                  control={control}
                  name="toDateCalender"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                  inputRef={{
                    ...register("toDateCalender", {
                      onChange: (e) => {
                        if (e.target.value.toString().length === 55) {
                          let dateVal = format(e.target.value, "yyyy-MM-dd");
                          let timeVal = dateVal + "T" + "00:00:00.000Z";
                          setSelectedToDate(timeVal);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-48">
                <DropdownField
                  control={control}
                  name="floor"
                  label="Floor"
                  placeholder="Floor"
                  isClearable={true}
                  dataArray={floorDropdown}
                />
              </div>
              <CommonButton
                searchIcon
                className="bg-customBlue text-white"
                onClick={() => {
                  fetchListOfNursingNightReport();
                }}
              />
            </div>
          </div>
          <div className="">
            <p className="text-md font-semibold">Nursing Night Report List</p>
          </div>
          <div className="col-span-3 w-full ">
            {nursingNightReportList.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={nursingNightReportList}
                actions={[]}
                tableClass="h-[500px]"
              />
            ) : (
              <>
                <div className="pt-8 text-center">No Record Found </div>
              </>
            )}
          </div>
          <div className="flex  w-1/2 gap-2">
            <div className="py-2">
              <TextField
                id="outlined-multiline-static"
                name="remaks"
                {...register("remaks")}
                label="Remark"
                InputLabelProps={{ shrink: true }}
                multiline
                cols={1}
                style={{ width: 500 }}
                fullWidth
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default View;
