import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../../common/components/FormFields/InputField";
import {
  fetchCampData,
  fetchDepartment,
  fetchDoctorListByDepartmentId,
  fetchDoctorsForAdm,
  fetchEmoloyeeDependent,
  fetchEmoloyeeName,
  fetchEmployeeDetails,
  fetchPatientCategory,
  fetchPatientsource,
  fetchReferanceDoctor,
  fetchTariffByCompCategory,
} from "../../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchDoctor1,
  fetchDoctor2,
  fetchInternalReferanceDoctor,
  fetchReferEntity,
} from "../../../../services/admissionServices/AdmissionServices";

import { format } from "date-fns";
import SearchDropdown from "../../../../../common/components/FormFields/searchDropdown";
import CreateableSelect from "../../../../../common/components/FormFields/CreateableSelect";

const AdmissionInfo = (props) => {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const [patientSource, setPatientSource] = useState();
  const [patientCategory, setPatientCategory] = useState();
  const [department, setDepartment] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [departmentSearchString, setDepartmentSearchString] = useState();
  const [doctor, setDoctor] = useState();
  const [doctorSearchString, setDoctorSearchString] = useState();
  // const [cabin, setCabin] = useState();
  // const [tariff, setTariff] = useState();
  const [emoloyeeName, setEmoloyeeName] = useState();
  ///doctor details accordian
  const [referralEntity, setReferralEntity] = useState();
  const [referanceDoctor, setReferanceDoctor] = useState();
  const [
    referanceDoctorSearchString,
    setReferanceDoctorSearchString,
  ] = useState();
  const [internalReferanceDoctor, setInternalReferanceDoctor] = useState();
  const [intRefDoctorSearchString, setIntRefDoctorSearchString] = useState();
  const [doctor1, setDoctor1] = useState();
  const [doctorSearchString1, setDoctorSearchString1] = useState();
  const [doctor2, setDoctor2] = useState();
  const [doctorSearchString2, setDoctorSearchString2] = useState();
  const [emoloyeeDependent, setEmoloyeeDependent] = useState();
  const [campData, setCampData] = useState();

  const {
    setPatientCategoryChange,
    patientCategoryChange,
    casualtyDate,
    setCasualtyDate,
    setCasualtyTime,
    casualtyTime,
    handleTariffByCompCategory,
    tariff,
  } = props;

  const {
    control,
    register,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const PatientSource = watch("patientSource");
  // const PatientCategory = watch("patientcategory");
  // const Company = watch("company");
  const empId = watch("staffName");
  const dependantId = watch("staffDependent");
  const Doctor_selected = watch("doctor");
  const Doctor1_selected = watch("doctor1");
  const Doctor2_selected = watch("doctor2");

  ///////////////////////services integration below ///////////////

  useEffect(() => {
    getPatientsource();
    getPatientCategory();
    //getDepartment();
    //getDoctor();
    // getCabin();
    // getTariff();
    getEmoloyeeName();
    // getEmoloyeeDependent();
    getCampData();
  }, []);

  useEffect(() => {
    getEmoloyeeDependent();
  }, [empId]);

  useEffect(() => {
    getDepartment();
  }, [departmentSearchString]);

  useEffect(() => {
    getDoctor();
  }, [departmentId, doctorSearchString]);

  // Api to Get Patient Source

  const getPatientsource = () => {
    fetchPatientsource()
      .then((response) => {
        setPatientSource(response.data.result);
      })
      .catch(() => {});
  };

  // Api to Get Patient Source
  const getCampData = () => {
    fetchCampData()
      .then((response) => {
        setCampData(response.data.result);
      })
      .catch(() => {});
  };

  // Api to Get Patient Category

  const getPatientCategory = () => {
    fetchPatientCategory()
      .then((response) => {
        setPatientCategory(response.data.result);
      })
      .catch(() => {});
  };
  // Api to Get department

  const getDepartment = () => {
    departmentSearchString &&
      fetchDepartment(departmentSearchString)
        .then((response) => {
          setDepartment(response.data.result);
        })
        .catch(() => {});
  };

  // Api to Get Doctor by department id
  const getDoctor = () => {
    departmentId &&
      fetchDoctorListByDepartmentId(departmentId, token.unitId).then(
        (response) => {
          setDoctor(response.data.result);
        }
      );
  };

  useEffect(() => {
    let selectedDoctors = [Doctor_selected?.id];
    doctorSearchString1 &&
      fetchDoctorsForAdm(selectedDoctors, doctorSearchString1).then(
        (response) => {
          setDoctor1(response.data.result);
        }
      );
  }, [Doctor1_selected, Doctor2_selected]);

  // Api to Get cabin

  // const getCabin = () => {
  //   fetchCabin()
  //     .then((response) => {
  //       setCabin(response.data.result);
  //     })
  //     .catch(() => {});
  // };

  // Api to Get Tariff

  // useEffect(() => {
  //   if (PatientCategory !== null) {
  //     fetchTariffByCompCategory(
  //       PatientCategory?.id,
  //       Company?.id !== undefined ? Company?.id : 0
  //     )
  //       .then((response) => {
  //         setTariff(response.data.result);
  //         if (response?.data?.result.length > 0) {
  //           if (PatientCategory?.id === 16) {
  //             setValue("tariff", response?.data?.result[1]);
  //           } else {
  //             setValue("tariff", response?.data?.result[0]);
  //           }
  //         }
  //       })
  //       .catch(() => {});
  //   }
  // }, [PatientCategory, Company]);

  // const handleTariffByCompCategory = () => {
  //   if (PatientCategory !== null) {
  //     fetchTariffByCompCategory(
  //       PatientCategory?.id,
  //       Company?.id !== undefined ? Company?.id : 0
  //     )
  //       .then((response) => {
  //         setTariff(response.data.result);
  //         if (response?.data?.result.length > 0) {
  //           if (PatientCategory?.id === 16) {
  //             setValue("tariff", response?.data?.result[1]);
  //           } else {
  //             setValue("tariff", response?.data?.result[0]);
  //           }
  //         }
  //       })
  //       .catch(() => {});
  //   }
  // };

  // Api to Get Employee name

  const getEmoloyeeName = () => {
    fetchEmoloyeeName()
      .then((response) => {
        setEmoloyeeName(response.data.result);
      })
      .catch(() => {});
  };

  // Api to Get Employee name
  const getEmoloyeeDependent = () => {
    if (empId && empId !== null) {
      fetchEmoloyeeDependent(empId.id)
        .then((response) => {
          setEmoloyeeDependent(response.data.result);
        })
        .catch(() => {});
    }
  };

  //API For referral Entity List
  useEffect(() => {
    getReferEntity();
    getReferanceDoctor();
    getDoctor1();
    getDoctor2();
    getInternalReferanceDoctor();
  }, [
    referanceDoctorSearchString,
    intRefDoctorSearchString,
    doctorSearchString1,
    doctorSearchString2,
  ]);

  const getReferEntity = () => {
    fetchReferEntity()
      .then((response) => {
        setReferralEntity(response.data.result);
      })
      .catch(() => {});
  };
  //API For referance Doctor List

  const getReferanceDoctor = () => {
    referanceDoctorSearchString &&
      fetchReferanceDoctor(referanceDoctorSearchString).then((response) => {
        setReferanceDoctor(response.data.result);
      });
  };
  //API For internal Referance Doctor List

  const getInternalReferanceDoctor = () => {
    intRefDoctorSearchString &&
      fetchInternalReferanceDoctor(intRefDoctorSearchString).then(
        (response) => {
          setInternalReferanceDoctor(response.data.result);
        }
      );
  };

  // Api to Get Doctor
  //fetchDoctorsForAdm
  const getDoctor1 = () => {
    // doctorSearchString1 &&
    //   fetchDoctor1(doctorSearchString1).then((response) => {
    //     setDoctor1(response.data.result);
    //   });

    let selectedDoctors = [Doctor_selected?.id];
    doctorSearchString1 &&
      Doctor_selected &&
      fetchDoctorsForAdm(selectedDoctors, doctorSearchString1).then(
        (response) => {
          setDoctor1(response.data.result);
        }
      );
  };
  // Api to Get Doctor

  const getDoctor2 = () => {
    // doctorSearchString2 &&
    //   fetchDoctor2(doctorSearchString2)
    //     .then((response) => {
    //       setDoctor2(response.data.result);
    //     })

    let selectedDoctors = [Doctor_selected?.id, Doctor1_selected?.id];
    doctorSearchString2 &&
      Doctor1_selected &&
      fetchDoctorsForAdm(selectedDoctors, doctorSearchString2).then(
        (response) => {
          setDoctor2(response.data.result);
        }
      );
  };

  useEffect(() => {
    if (empId !== "" && empId !== undefined) {
      getEmployeeDetails();
    }
  }, [empId, dependantId]);

  // Api to Get limit used code
  const getEmployeeDetails = () => {
    if (empId !== null && empId !== undefined) {
      fetchEmployeeDetails(
        empId.id,
        dependantId !== undefined && dependantId !== null ? dependantId.id : 0
      )
        .then((response) => {
          if (empId !== undefined && empId !== "") {
            setValue("code", response.data.result.Code);
            setValue("limit", response.data.result.PharmacyBalanceAmt);
            setValue("used", response.data.result.PharmacyHMSAmt);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <div>
      <div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-x-3 gap-y-3 ">
          <div className="">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disabled
                disableFuture
                label="Casualty Arrival Date"
                value={casualtyDate}
                inputFormat="dd/MM/yyyy"
                onChange={(newValuee) => {
                  const dateResult = format(newValuee, "yyyy-MM-dd'T'00:00:00");
                  setCasualtyDate(dateResult);
                }}
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    fullWidth
                    name="dobDate"
                    size="small"
                    defaultValue=""
                    disabled={true}
                    inputFormat="dd/MM/yyyy"
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    sx={{
                      svg: { color: "#0B83A5" },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className=" ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                disabled
                label="Casualty Arrival Time"
                value={casualtyTime}
                onChange={(newValue) => {
                  setCasualtyTime(dayjs(newValue));
                }}
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    name="timeOfDeath"
                    fullWidth
                    size="small"
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    sx={{
                      svg: { color: "#0B83A5" },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          {/* //Department// */}

          <div>
            <SearchDropdown
              control={control}
              error={errors.department}
              name="department"
              label="Department"
              searchIcon={true}
              dataArray={department}
              handleInputChange={(e) => {
                setDepartmentSearchString(e);
              }}
              inputRef={{
                ...register("department", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setDepartmentId(e.target.value.id);
                    }
                    resetField("doctor");
                  },
                }),
              }}
              isSearchable={true}
              isClearable={true}
              placeholder="Department *"
            />
          </div>
          {/* //Doctor// */}

          <div>
            <DropdownField
              control={control}
              error={errors.doctor}
              name="doctor"
              label="Doctor *"
              dataArray={doctor}
              // handleInputChange={(e) => {
              //
              //   setDoctorSearchString(e);

              //   setValue("doctor1", null);
              //   setValue("doctor2", null);
              // }}

              inputRef={{
                ...register("doctor", {
                  onChange: (e) => {
                    setValue("doctor1", null);
                    setValue("doctor2", null);
                  },
                }),
              }}
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              placeholder="Doctor *"
            />
          </div>
          {/* doctor1 */}
          <div className="">
            <SearchDropdown
              control={control}
              name="doctor1"
              label=" Doctor 1"
              dataArray={doctor1}
              handleInputChange={(e) => {
                setDoctorSearchString1(e);
                setValue("doctor2", null);
              }}
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              placeholder="Doctor 1"
            />
          </div>
          {/* Doctor 2 */}
          <div className="">
            <SearchDropdown
              control={control}
              name="doctor2"
              label="Doctor 2"
              dataArray={doctor2}
              handleInputChange={(e) => {
                setDoctorSearchString2(e);
              }}
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              placeholder="Doctor 2"
            />
          </div>
          {/* //PateintSource// */}

          <div>
            <DropdownField
              control={control}
              error={errors.patientSource}
              name="patientSource"
              label="Pateint Source"
              dataArray={patientSource}
              isSearchable={false}
              isClearable={false}
              placeholder="Patient Source  *"
              inputRef={{
                ...register("patientSource", {
                  onChange: (e) => {
                    resetField("camp");
                  },
                }),
              }}
            />
          </div>
          {PatientSource.value === "Camp" ? (
            <div>
              <DropdownField
                control={control}
                error={errors.camp}
                name="camp"
                label="Camp"
                dataArray={campData}
                isSearchable={false}
                placeholder="Camp"
                isClearable={false}
              />
            </div>
          ) : null}
          {PatientSource.value === "Referal" ? (
            <>
              {/* //referralDoctor//
              <div>
                
                <SearchDropdown
                  control={control}
                  name="referanceDoctor"
                  label="Adviced Doctor"
                  dataArray={referanceDoctor}
                  handleInputChange={(e) => {
                    setReferanceDoctorSearchString(e);
                  }}
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  placeholder="Adviced Doctor"
                />
              </div> */}
              <div>
                <CreateableSelect
                  name="referanceDoctor"
                  placeholder="Adviced Doctor"
                  dataArray={referanceDoctor}
                  onInputChange={(e) => {
                    setReferanceDoctorSearchString(e);
                  }}
                  searchIcon={true}
                  error={errors.referanceDoctor}
                  control={control}
                  isClearable={true}
                />
              </div>
              <div className="">
                <SearchDropdown
                  control={control}
                  name="internalReferanceDoctor"
                  label="Internal Adviced Doctor"
                  dataArray={internalReferanceDoctor}
                  handleInputChange={(e) => {
                    setIntRefDoctorSearchString(e);
                  }}
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  placeholder="Internal Adviced Doctor"
                />
              </div>
            </>
          ) : null}

          <div>
            <DropdownField
              control={control}
              error={errors.patientcategory}
              name="patientcategory"
              label="Patient Category"
              dataArray={patientCategory}
              isSearchable={false}
              isClearable={false}
              placeholder="Patient Category  *"
              inputRef={{
                ...register("patientcategory", {
                  onChange: (e) => {
                    handleTariffByCompCategory(e.target.value);
                    setPatientCategoryChange(e.target.value.value);
                    resetField("staffName");
                    resetField("staffDependent");
                    resetField("company");
                    resetField("assistantCompany");
                    resetField("code");
                    resetField("limit");
                    resetField("used");
                  },
                }),
              }}
            />
          </div>
          {/* //tariff*/}
          <div className="">
            <DropdownField
              control={control}
              error={errors.tariff}
              name="tariff"
              label="Tariff"
              dataArray={tariff}
              isSearchable={false}
              isClearable={false}
              placeholder="Tariff *"
            />
          </div>
          {/* referEntity */}
          <div>
            <DropdownField
              control={control}
              name="referalEntity"
              dataArray={referralEntity}
              isSearchable={false}
              isClearable={false}
              placeholder="Refer Entity"
            />
          </div>
        </div>
        <div>
          {patientCategoryChange === "Staff" ||
          patientCategoryChange === "Staff Dependant" ? (
            <>
              <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
              <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
                Staff Details
              </p>
              <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-3">
                {/* ///Employee Name /// */}

                <div>
                  <DropdownField
                    control={control}
                    error={errors.staffName}
                    name="staffName"
                    label="Staff Name *"
                    dataArray={emoloyeeName}
                    placeholder=" Staff Name *"
                    isClearable={false}
                    isSearchable={true}
                    searchIcon={false}
                    inputRef={{
                      ...register("staffName", {
                        onChange: (e) => {
                          setValue("staffDependent", null);
                        },
                      }),
                    }}
                  />
                </div>
                {/* //staff dependent */}
                {patientCategoryChange === "Staff Dependant" ? (
                  <div>
                    <DropdownField
                      control={control}
                      name="staffDependent"
                      label="Staff Dependent "
                      dataArray={emoloyeeDependent}
                      isSearchable={false}
                      placeholder=" Staff Dependent "
                      isClearable={false}
                    />
                  </div>
                ) : null}
                {/* ///Code/// */}
                <div>
                  <InputField
                    name="code"
                    variant="outlined"
                    label="Code "
                    control={control}
                    disabled={true}
                    isDisabled={true}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>

                {/* ///Limit/// */}
                <div>
                  <InputField
                    name="limit"
                    variant="outlined"
                    label="Limit "
                    control={control}
                    disabled={true}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                {/* ///Used/// */}
                <div>
                  <InputField
                    name="used"
                    variant="outlined"
                    label="Used "
                    control={control}
                    disabled={true}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdmissionInfo;
