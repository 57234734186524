// import * as React from "react";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import CancelPresentationIconButton from "../Buttons/CancelPresentationIconButton";
// import useGeneratePdf from "./GeneratePdfHook";

// const ModalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "85%",
//   height: "90%",
//   minHeight: "65%",
//   // maxHeight: "80%",
//   overflowY: "scroll",
//   bgcolor: "background.paper",
//   border: "1px solid gray",
//   boxShadow: 20,
//   py: 4,
//   px: 2,
// };

// let loader = false;

// export default function CommonPrintModal(props) {
//   //
//   const {
//     open,
//     setOpen,
//     handleOpen,
//     handleClose,
//     urlforPrint,
//     preview,
//   } = props;
//   loader = true;
//   const fileURL = useGeneratePdf(urlforPrint);
//   if (!!fileURL) {
//     loader = false;
//   }
//   // React.useEffect(() => {
//   //   console.log("loader11", fileURL);

//   //   if (!!fileURL) {
//   //     loader = false;
//   //   }
//   // }, [fileURL]);

//   console.log("loaderl", loader);
//   console.log("loader", fileURL);
//   return (
//     <div>
//       <Modal
//         open={open}
//         // onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
//           <CancelPresentationIconButton
//             onClick={() => {
//               props.setOpen(false);
//               handleClose();
//             }}
//           />
//           {loader && fileURL}

//           {/*!loader && fileURL && fileURL ? (
//             <embed
//               width="100%"
//               height="100%"
//               src={`${fileURL}${preview ? "#toolbar=0&navpanes=0" : ""}`}
//               target="_blank"
//             />
//           ) : (
//             ""
//           )*/}

//           {urlforPrint && urlforPrint.data !== undefined ? (
//             !loader && fileURL && fileURL ? (
//               <embed
//                 type="application/pdf"
//                 width="100%"
//                 height="100%"
//                 src={`${fileURL}${preview ? "#toolbar=0&navpanes=0" : ""}`}
//                 target="_blank"
//               />
//             ) : (
//               <div className="text-center mt-5">Unable To Load Pdf</div>
//             )
//           ) : (
//             <embed
//               type="application/pdf"
//               width="100%"
//               height="100%"
//               src={`${urlforPrint}${preview ? "#toolbar=0&navpanes=0" : ""}`}
//               target="_blank"
//             />
//           )}
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CancelPresentationIconButton from "../Buttons/CancelPresentationIconButton";
import useGeneratePdf from "./GeneratePdfHook";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "90%",
  minHeight: "65%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function CommonPrintModal(props) {
  // console.log(props, "props @mlc modal");
  const { open, handleClose, urlforPrint, preview } = props;

  console.log("urlforPrint", urlforPrint);

  const fileURL = useGeneratePdf(urlforPrint);
  console.log("fileURL", fileURL);

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.setOpen(false);
              handleClose();
            }}
          />

          {urlforPrint && urlforPrint.data !== undefined ? (
            fileURL && fileURL ? (
              <embed
                width="100%"
                height="100%"
                src={`${fileURL}${preview ? "#toolbar=0&navpanes=0" : ""}`}
                target="_blank"
              />
            ) : (
              ""
            )
          ) : (
            <embed
              width="100%"
              height="100%"
              src={`${urlforPrint}${preview ? "#toolbar=0&navpanes=0" : ""}`}
              target="_blank"
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
