import { Box, Checkbox, Modal } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePagination from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import {
  getDailyOrderList,
  getItemDetailsList,
} from "../../../services/generalStore/storeGRN/StoreGRNServices";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  p: 2,
};

const StoreGRNModal = (props) => {
  const Actions = [
    {
      id: 0,
      action: "checkbox",
      isAction: true,
    },
  ];
  const defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
  };
  const { control, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues,
  });
  //state variable to indicate the page number
  const [page, setPage] = useState(0);
  //state variable to inidicate rows per page
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //state variable to indicate the total number of records coming from api
  const [count, setCount] = useState();

  const [dataResult, setDataResult] = useState([]);
  const [selectedOrderListRow, setSelectedOrderListRow] = useState([]);

  // watch variables
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let selectedFromDate = watch("fromDate");
  let selectedToDate = watch("toDate");

  const [listObject, setListObject] = useState({
    fromDate: selectedFromDate,
    searchString: "",
    storeId: token.storeId,
    toDate: selectedToDate,
    page: 0,
    size: rowsPerPage,
  });

  const getDailyOrderListTable = (forPagination) => {
    let defaultParams = {
      fromDate: selectedFromDate,
      searchString: "",
      storeId: token.storeId,
      toDate: selectedToDate,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    getDailyOrderList(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch(() => {});
  };

  const getDOList = async () => {
    let getResult = [...props.dailyOrderDetailsData];
    for (let obj of selectedOrderListRow) {
      let supplierObj = {
        id: obj.SupplierId,
        value: obj.SupplierName,
        label: obj.SupplierName,
      };
      props.setDailyOrderObj(obj);
      props.setSupplierValue(supplierObj);
      try {
        const response = await getItemDetailsList(obj.DOId, obj.SupplierId);
        const res = response.data;
        for (let item of res.result) {
          const isItemCodeExists = getResult.some(
            (existingItem) => existingItem.DODetailId === item.DODetailId
          );
          if (isItemCodeExists) {
            warningAlert("Item Already Exists.");
          } else {
            getResult.push(item);
          }
        }
        props.setDailyOrderDetailsData(getResult);
      } catch (error) {
        console.error("Error fetching item details:", error);
      }
    }
  };

  const handleCheckboxChange = (e, row) => {
    let dataArray = [...dataResult];
    let updatedSelectedItems = [...selectedOrderListRow];
    if (e.target.checked === true) {
      updatedSelectedItems.push(row);
    } else {
      const rowIndex = updatedSelectedItems.findIndex(
        (item) => item.SupplierId === row.SupplierId
      );
      if (rowIndex !== -1) {
        updatedSelectedItems.splice(rowIndex, 1);
      }
    }
    setSelectedOrderListRow(updatedSelectedItems);
    for (let obj of dataArray) {
      obj.isDisabled = updatedSelectedItems.some(
        (item) => item.SupplierId !== obj.SupplierId
      );
    }
    // setDataResult(dataArray);
  };

  // Render Actions
  const renderActions = (row, index) => {
    return (
      <div>
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                {actions.action === "checkbox" ? (
                  <>
                    <fieldset
                      disabled={row.isDisabled}
                      onChange={(e) => handleCheckboxChange(e, row)}
                    >
                      <Checkbox
                        control={control}
                        value={index}
                        name={`select${index}`}
                        sx={{ padding: 0.5 }}
                        size="small"
                      />
                    </fieldset>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  useMemo(() => {
    getDailyOrderListTable();
  }, [listObject.fromDate, listObject.toDate]);
  return (
    <>
      <Modal open={props.openGrnModal}>
        <Box sx={ModalStyle} className="w-[90%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.setOpenGrnModal(false);
            }}
          />

          <h1 className="font-semibold whitespace-nowrap text-xl text-center text-customBlue">
            Order List
          </h1>
          <div className="my-2">
            <div className="flex gap-2">
              <div>
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("fromDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("toDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={() => {
                  setListObject((listObject) => ({
                    ...listObject,
                    fromDate: selectedFromDate,
                    toDate: selectedToDate,
                  }));
                }}
              />
            </div>
            <div className="mt-1">
              {dataResult?.length > 0 ? (
                <CommonDynamicTablePagination
                  dataResult={dataResult}
                  actionsRequired={true}
                  populateTable={getDailyOrderListTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  renderActions={renderActions}
                  highlightRow={false}
                  tableClass={"h-72"}
                  removeHeaders={["DOId", "SupplierId", ""]}
                />
              ) : (
                <h1 className="flex justify-center my-5">No Data Found...</h1>
              )}
            </div>
            <div className="flex justify-end gap-2 mt-2">
              <CommonButton
                type="button"
                className="bg-customGreen text-white"
                label="Ok"
                onClick={() => {
                  getDOList();
                  props.setOpenGrnModal(false);
                }}
              />

              <CommonButton
                type="button"
                className="border text-customRed border-customRed"
                label="Close"
                onClick={() => {
                  props.setOpenGrnModal(false);
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default StoreGRNModal;
