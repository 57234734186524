import React from "react";

function OkButton({ onClick }) {
  return (
    <div>
      <button
        type="submit"
        className="h-9 px-3 text-base font-medium  bg-green-600 text-white rounded "
        onClick={onClick}
      >
        OK
      </button>
    </div>
  );
}

export default OkButton;
