import React from "react";

function SaveScheduleButton({ onClick }) {
  return (
    <div>
      <button
        type="button"
        className="h-9 px-4  bg-customBlue  text-white rounded text-sm font-medium whitespace-nowrap"
        onClick={onClick}
      >
       Save Schedule
      </button>
    </div>
  );
}

export default SaveScheduleButton;
