import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchAllServices,
  getWorkOrderGenInfo,
  searchPatientList,
} from "../../Pathology/Services/WorkOrderGenerationServices";
import AddServicesTable from "../Common/AddServicesTable";
import { savePhysiotherapyWorkOrder } from "../services/PhysioTheropyServices";
import { useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const patientType = [
  {
    id: 0,
    label: "OPD",
    value: "OPD",
  },
  {
    id: 1,
    label: "IPD",
    value: "IPD",
  },
];
const PhysioWorkOrderGeneration = (props) => {
  const { PatientId, menuId } = props;
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedPatient, setSelectedPatient] = React.useState(null);
  const [serviceSearch, setServiceSearch] = React.useState([]);
  const [serviceInput, setServiceInput] = useState("");
  const [serviceArr, setServiceArr] = React.useState([]);
  const [finalData, setFinalData] = React.useState();
  const [openPost, setOpenPost] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = React.useState("");

  const handleClosePost = () => {
    setOpenPost(false);
  };

  const searchPatientElement = useRef(null);
  const searchDropdownElement = useRef(null);
  const frequencyElement = useRef(null);
  const noOfDaysElement = useRef(null);
  let addbtnRef = useRef();
  let savebtnRef = useRef();

  function focusOnFields(type) {
    let fieldsetTag;
    if (type === "searchService") {
      fieldsetTag = searchDropdownElement.current;
    }
    if (type === "frequency") {
      fieldsetTag = frequencyElement.current;
    }
    if (type === "noOfDays") {
      fieldsetTag = noOfDaysElement.current;
    }
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  // const schema = yup.object().shape({
  //   frequency: yup.number().required(),
  //   noOfDays: yup.number().required(),
  // });
  const {
    control,
    handleSubmit,
    watch,
    reset,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      searchService: null,
      patientType: 1,
      searchPatient: null,
      frequency: "",
      noOfDays: "",
    },
  });

  let getEmergency = watch("emergency");
  let clinicalSelectedPatient = PatientId;

  useEffect(() => {
    (location?.state?.menuId || menuId) &&
      fetchUserActionsByMenuId(location?.state?.menuId || menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, [clinicalSelectedPatient]);

  function handleChange(string) {
    if (string !== "") {
      searchPatientList(string, getPatientType)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  }

  const handleService = (serviceVal) => {
    if (serviceVal !== "" && selectedPatient !== null) {
      setServiceInput(serviceVal);
      let serviceObj = {
        applicableTo: 1,
        classId: selectedPatient?.classId,
        description: serviceVal,
        tariffId: selectedPatient?.TariffId,
        unitId: selectedPatient?.UnitId,
        groupId: 9,
        serviceCode: "%",
        isEmergency: getEmergency === true ? true : false,
      };
      fetchAllServices(serviceObj)
        .then((response) => response.data)
        .then((res) => {
          setServiceSearch(res.result);
        });
    }
  };
  let getService = watch("searchService");
  let getPatientType = Number(watch("patientType"));
  let getFrequency = Number(watch("frequency"));
  let getNoOfDays = Number(watch("noOfDays"));

  function unDuplicateArraySingleValues(array) {
    // Check if we are dealing with an Array that is not empty
    if (!array || !Array.isArray(array) || array.length === 0) {
      return array;
    }

    // Return a Array of unique values thanks to the Set
    return [...new Set(array)];
  }

  function unDuplicateArrayObjects(array, propertyName) {
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      !propertyName
    ) {
      return array;
    }
    // Create an Array off the values of the keys we want to check
    let objectArrayKeys = array.map((item) => item[propertyName]);

    // Remove duplicate values from those values with our previous function
    let uniqueKeys = unDuplicateArraySingleValues(objectArrayKeys);

    // Return an Array with only unique Objects
    return uniqueKeys.map((key) =>
      array.find((item) => item[propertyName] === key)
    );
  }

  const addServices = () => {
    let addServiceObj = {};
    addServiceObj.serviceCode = getService.ServiceCode;
    addServiceObj.serviceDiscription = getService.label;
    addServiceObj.frequency = getFrequency;
    addServiceObj.noOfDays = getNoOfDays;
    addServiceObj.serviceId = getService.ServiceId;

    let duplicateObj = serviceArr.some(
      (val) => val?.serviceCode === addServiceObj.serviceCode
    );
    if (!duplicateObj) {
      let arr = [...serviceArr];
      arr.push(addServiceObj);
      setServiceArr(arr);
    } else {
      warningAlert("Service already exists...!");
    }
  };

  const onSubmitData = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));

    let opdIpdExternalId;
    if (selectedPatient?.AdmissionId) {
      opdIpdExternalId = selectedPatient?.AdmissionId;
    } else if (selectedPatient?.VisitId) {
      opdIpdExternalId = selectedPatient?.VisitId;
    }

    let newServiceArr = [];
    for (let i = 0; i < serviceArr.length; i++) {
      let serviceObj = {
        frequency: Number(serviceArr[i].frequency),
        noOfDays: Number(serviceArr[i].noOfDays),
        serviceId: serviceArr[i].serviceId,
      };
      newServiceArr.push(serviceObj);
    }

    let postObj = {
      menuId: location?.state?.menuId || menuId,
      addedBy: getUserId.userId,
      opdIpdExternal: getPatientType,
      opdIpdExternalId: opdIpdExternalId,
      physiotherapyDetailsDtoList: newServiceArr,
      tariffId: selectedPatient?.TariffId,
      testType: 0,
      updatedBy: 0,
      privilege: privilege,
    };

    setFinalData(postObj);
    if (selectedPatient !== null) {
      if (serviceArr.length > 0) {
        setOpenPost(true);
      } else {
        warningAlert("Add atleast 1 Service...!");
      }
    } else {
      warningAlert("Please Select Patient...!");
    }
  };

  function postData() {
    setOpenBackdrop(true);
    handleClosePost();
    savePhysiotherapyWorkOrder(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleClosePost();
        setOpenBackdrop(false);
        setServiceArr([]);
        setValue("searchService", null);
        setValue("searchPatient", null);
        setValue("remark", "");
        setSelectedPatient(null);
        setPrivilege("");
      })
      .catch((error) => {
        errorAlert(error.message);
        handleClosePost();
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    setSelectedPatient(null);
    setValue("searchPatient", null);
    setServiceArr([]);
  }, [getPatientType]);

  useEffect(() => {
    let fieldsetTag = searchPatientElement.current;
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }, []);

  useEffect(() => {
    if (clinicalSelectedPatient) {
      let selectObj = {
        patientId: PatientId,
        type: "Ipd",
      };
      getWorkOrderGenInfo(selectObj)
        .then((response) => response.data)
        .then((res) => {
          if (res.result.Discharged === true) {
            warningAlert("Patient already discharged...!");
          } else if (
            res.result.Discharged === false ||
            res.result.Discharged === undefined
          ) {
            setSelectedPatient(res.result);
            focusOnFields("searchService");
          }
        });
    }
  }, [clinicalSelectedPatient]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="mt-12 mx-2 space-y-2">
          <div className="text-center">
            <label className="text-xl font-bold">
              Physiotherapy Work Order Generation
            </label>
          </div>
          <div className="flex items-center">
            <div className="w-80 lg:w-96" ref={searchPatientElement}>
              <SearchDropdown
                control={control}
                name="searchPatient"
                placeholder="Search By Patient Name"
                searchIcon={true}
                dataArray={options}
                isSearchable={true}
                isClearable={true}
                handleInputChange={handleChange}
                isDisabled={PatientId}
                inputRef={{
                  ...register("searchPatient", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        let selectObj = {
                          patientId: e.target.value.id,
                          type:
                            getPatientType === 0
                              ? "Opd"
                              : getPatientType === 1
                              ? "Ipd"
                              : "",
                        };
                        getWorkOrderGenInfo(selectObj)
                          .then((response) => response.data)
                          .then((res) => {
                            if (res.result.Discharged === true) {
                              warningAlert("Patient already discharged...!");
                            } else if (
                              res.result.Discharged === false ||
                              res.result.Discharged === undefined
                            ) {
                              setSelectedPatient(res.result);
                              focusOnFields("searchService");
                            }
                          });
                      } else if (e.target.value === null) {
                        setSelectedPatient(null);
                        setServiceArr([]);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className="ml-5 flex">
              <label className="font-bold">Patient Type : &nbsp;&nbsp;</label>
              <RadioField
                control={control}
                name="patientType"
                dataArray={patientType}
                defaultValue={"IPD"}
              />
            </div>
          </div>
          <div className="gap-2 xl:flex">
            <div className="space-y-2 xl:w-[45%]">
              <fieldset className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 px-3 py-1 rounded border bg-[#F1F1F1] border-gray-300 gap-y-2 text-sm whitespace-nowrap">
                <legend className="font-semibold px-2 text-base">
                  Patient Information
                </legend>
                <div className="flex">
                  <label className="w-24 font-semibold">
                    Patient Name &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.patientName}</span>
                </div>
                <div className="flex">
                  <label className="w-12 lg:w-24 xl:w-11 font-semibold">
                    MRNo &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.MRNo}</span>
                </div>
                <div className="flex">
                  <label className="w-24 lg:w-14 xl:w-24 font-semibold">
                    Gender &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.gender}</span>
                </div>
                <div className="flex">
                  <label className="w-12 lg:w-24 xl:w-11 font-semibold">
                    Age &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.AgeYear}</span>
                </div>
                <div className="flex">
                  <label className="w-24 font-semibold">
                    Doctor Name &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.doctorName}</span>
                </div>
              </fieldset>
              <div className="w-full">
                <TextField
                  name="remark"
                  aria-label="minimum height"
                  multiline
                  rows={2}
                  placeholder="Provisional Diagnosis / Remark"
                  fullWidth
                  {...register("remark")}
                  disabled={selectedPatient?.Discharged === true}
                />
              </div>
            </div>
            <div className="xl:w-[55%]">
              <fieldset className="grid lg:grid-cols-2 gap-y-4 p-3 rounded border bg-[#F1F1F1] border-gray-300 w-full whitespace-nowrap">
                <legend className="font-semibold px-2 text-base">
                  Billing Information
                </legend>
                <div className="flex">
                  <label className="w-36 font-semibold">Unit &nbsp;</label>
                  <span>:&nbsp;{selectedPatient?.unit}</span>
                </div>
                <div className="flex">
                  <label className="w-36 xl:w-32 font-semibold">
                    Patient Source &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.patientSource}</span>
                </div>
                <div className="flex">
                  <label className="w-36 font-semibold">
                    Patient Category &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.patientCategory}</span>
                </div>
                <div className="flex">
                  <label className="w-36 xl:w-32 font-semibold">
                    Tariff &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.tariff}</span>
                </div>
                <div className="flex">
                  <label className="w-36 font-semibold">
                    Department &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.department}</span>
                </div>
                <div className="flex">
                  <label className="w-36 xl:w-32 font-semibold">
                    Doctor &nbsp;
                  </label>
                  <span>:&nbsp;{selectedPatient?.doctorName}</span>
                </div>
                <div className="flex col-span-full">
                  <label className="w-36 font-semibold">Company &nbsp;</label>
                  <span>:&nbsp;{selectedPatient?.company}</span>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="flex gap-2">
            <fieldset disabled={selectedPatient === null ? true : false}>
              <CheckBoxField
                control={control}
                name="emergency"
                label="Emergency"
              />
            </fieldset>
          </div>
          <div className="space-y-1">
            <div className="flex gap-2">
              <fieldset
                className="w-96"
                ref={searchDropdownElement}
                onClick={(e) => {
                  if (selectedPatient === null) {
                    warningAlert("Please select patient...!");
                  }
                  if (e.code === "Tab") {
                    savebtnRef.current.focus();
                  }
                }}
              >
                <SearchDropdown
                  onKeyDown={(e) => {
                    if (serviceInput === "" && e.code === "Enter") {
                      e.preventDefault();
                    }
                    if (serviceInput === "" && e.code === "Tab") {
                      savebtnRef.current.focus();
                    }
                  }}
                  control={control}
                  name="searchService"
                  placeholder="Search Service"
                  dataArray={serviceSearch}
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  error={errors.searchService}
                  handleInputChange={handleService}
                  inputRef={{
                    ...register("searchService", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          focusOnFields("frequency");
                        }
                      },
                    }),
                  }}
                  // isDisabled={selectedPatient === null}
                />
              </fieldset>
              <div ref={frequencyElement}>
                <InputField
                  control={control}
                  name="frequency"
                  label="Frequency"
                  error={errors.frequency}
                  type="number"
                  disabled={selectedPatient === null}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      focusOnFields("noOfDays");
                    }
                  }}
                />
              </div>
              <div ref={noOfDaysElement}>
                <InputField
                  control={control}
                  name="noOfDays"
                  label="No.Of Days"
                  type="number"
                  error={errors.noOfDays}
                  disabled={selectedPatient === null}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      addbtnRef.current.focus();
                    }
                  }}
                />
              </div>
              <CommonButton
                referance={addbtnRef}
                type="button"
                label="Add"
                className="bg-customGreen text-white"
                onClick={() => {
                  if (
                    getService !== null &&
                    getFrequency !== 0 &&
                    getNoOfDays !== 0
                  ) {
                    addServices();
                    setServiceInput("");
                    setValue("searchService", null);
                    setValue("frequency", "");
                    setValue("noOfDays", "");
                    focusOnFields("searchService");
                  }
                  if (getService === null) {
                    setError("searchService", {
                      type: "custom",
                      message: "required",
                    });
                  } else {
                    clearErrors("searchService");
                  }
                  if (getFrequency === 0) {
                    setError("frequency", {
                      type: "custom",
                      message: "required",
                    });
                  } else {
                    clearErrors("frequency");
                  }
                  if (getNoOfDays === 0) {
                    setError("noOfDays", {
                      type: "custom",
                      message: "required",
                    });
                  } else {
                    clearErrors("noOfDays");
                  }
                }}
              />
            </div>
            <div>
              <AddServicesTable
                serviceArr={serviceArr}
                setServiceArr={setServiceArr}
              />
            </div>
          </div>
          {userActions.map((action, i) => (
            <>
              {action.action === "Create" && action.isAction === false ? (
                <div className="flex gap-3 justify-end">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset();
                      setServiceArr([]);
                      setSelectedPatient(null);
                      setPrivilege("");
                    }}
                  />
                  <CommonButton
                    referance={savebtnRef}
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                    onClick={() => {
                      setPrivilege(action.action);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={postData}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this Record ?"
          confirmationButtonMsg="Add"
        />
      </form>
    </>
  );
};

export default PhysioWorkOrderGeneration;
