import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../../common/components/FormFields/RadioField";
import CommonPrintModal from "../../../../../common/components/printModal/CommonPrintModal";
import { fetchApplicableStores } from "../../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchIpdIssueDetails,
  fetchItemListTableData,
  fetchPrintIssueToDept,
} from "../../../../services/pharmacy/issueToDeptServices/IssueToDeptServices";
import LoadingSpinner from "../../../../../common/components/loadingspinner/loadingSpinner";
import NumberOfCopiesModal from "../../../../../common/components/printModal/NumberOfCopiesModal";
import { blobToBase64 } from "../../../../../common/components/printModal/BlobToB64";
import { routeToPrinterDevice } from "../../../../../commonServices/barCodeServices/BarCodeServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "90%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const CriteriaRadio = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "with Indent",
    label: "with Indent",
  },
  {
    id: 0,
    value: "without Indent",
    label: "without Indent",
  },
];

const IsUrgentRadio = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Accepted",
    label: "Accepted",
  },
  {
    id: 0,
    value: "Pending",
    label: "Pending",
  },
];

const TypeRadio = [
  {
    id: 1,
    value: "Issued",
    label: "Issued",
  },
  {
    id: 0,
    value: "Pending",
    label: "Pending",
  },
];

export default function IssueToDeptListModal(props) {
  const {
    open,

    handleClose,
  } = props;

  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = token.storeId;
  const [stores, setStores] = useState();
  //
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [printResponse, setPrintResponse] = useState(null);
  //ipd issue details table
  const [ipdIssueTableData, setIpdIssueTableData] = React.useState([]);
  // ipd issue table
  const [ipdListData, setIpdListData] = React.useState([]);
  //item
  // show patient data
  const [selectedRow, setSelectedRow] = React.useState();

  //totaAmount
  const [totaAmount, setTotalAmount] = React.useState(0);

  //

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  //

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      isUrgent: 2,
      criteria: 2,
      type: 1,
      fromDate: new Date(),
      toDate: new Date(),
    },
  });
  const { control, watch } = methods;

  const Criteria = watch("criteria");
  const Type = watch("type");
  const IsUrgent = watch("isUrgent");
  var ToStore = watch("ToStore");

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [
    FromDate,
    ToDate,
    storeId,
    ToStore,
    Criteria,
    Type,
    IsUrgent,
    rowsPerPage,
  ]);

  const populateTable = (forPagination) => {
    setShowSpinner(true);
    var IpdIssuelistObj = {
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      criteria: Criteria !== undefined ? Criteria : 0,
      fromStoreId: token.storeId,
      isAccepted: IsUrgent,
      toStoreId: ToStore !== undefined ? ToStore.id : 0,
      type: Type !== undefined ? Type : 0,
    };

    fetchItemListTableData(IpdIssuelistObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIpdListData((prevData) => [...prevData, ...res.result]);
          setIpdIssueTableData([]);
          setSelectedRow(null);
          setShowSpinner(false);
        } else {
          setIpdListData(res.result);
          setPage(0);
          setIpdIssueTableData([]);
          setSelectedRow(null);
          setShowSpinner(false);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      })
      .catch(() => setShowSpinner(false));
  };

  //ipd issue details table data
  useEffect(() => {
    selectedRow?.IssToStoreId &&
      fetchIpdIssueDetails(selectedRow?.IssToStoreId)
        .then((response) => response.data)
        .then((res) => {
          setIpdIssueTableData(res.result);
        });
  }, [selectedRow]);

  useEffect(() => {
    storeId &&
      fetchApplicableStores(storeId)
        .then((response) => response.data)
        .then((res) => {
          setStores(res.result);
        });
  }, []);

  //////print

  const handleOpenPrintModal = (row) => {
    // print service
    row &&
      row?.IssToStoreId &&
      fetchPrintIssueToDept(row.IssToStoreId,"A5").then((response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setPrintResponse(response);
        } else {
          setOpenPrintModal(true);

          setUrlforPrint(response);
        }
      });
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setPrintResponse(null);
    });
  };

  ////////table related
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "Print" ? (
                    <Tooltip title={"Print"}>
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          handleOpenPrintModal(row);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          {/* search */}
          <div className="text-lg  text-gray-700 font-semibold whitespace-nowrap">
            Show Previous Issue
          </div>
          <div className="grid gap-2 w-full items-center mt-2">
            <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center">
              <div className="grid grid-cols-2 col-span-3 gap-3">
                {/* from Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* to Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="lg:col-span-2 md:col-span-1  z-50">
                <DropdownField
                  name="ToStore"
                  label="To Store"
                  control={control}
                  dataArray={stores}
                  isSearchable={false}
                  isClearable={false}
                  placeholder="To Store"
                />
              </div>

              <div className="flex md:justify-start lg:justify-center col-span-2 lg:col-span-3 ">
                <RadioField
                  label=""
                  name="isUrgent"
                  control={control}
                  dataArray={IsUrgentRadio}
                />
              </div>
              <div className="flex md:justify-start lg:justify-center col-span-2 lg:col-span-4 ">
                <p className="mr-3 font-semibold">Criteria :</p>
                <RadioField
                  label=""
                  name="criteria"
                  control={control}
                  dataArray={CriteriaRadio}
                />
              </div>
              <div className="flex justify-startcol-span-2 lg:col-span-5 ">
                <p className="mr-3 font-semibold">Type :</p>

                <RadioField
                  label=""
                  name="type"
                  control={control}
                  dataArray={TypeRadio}
                />
              </div>
            </div>
          </div>

          {/* tables */}
          <div className="mt-2">
            <div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                IPD Issue List
              </span>
            </div>
            {showSpinner ? (
              <div className="flex justify-center mt-20">
                <LoadingSpinner />
              </div>
            ) : ipdListData.length > 0 ? (
              <>
                <div className="">
                  <CommonDynamicTablePaginationNew
                    dataResult={ipdListData}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    renderActions={renderActions}
                    handleSelectedRow={handleSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72 xl:h-80"}
                    removeHeaders={[
                      "FromStoreId",
                      "IsAccepted",
                      "IssToStoreId",
                      "ReceivedBy",
                      "ToStoreId",
                      "TotalPurAmount",
                      "TotalVATAMT",
                    ]}
                  />
                </div>
              </>
            ) : (
              <div className="grid justify-center">
                {/* <LoadingSpinner /> */}
                Data Not Found
              </div>
            )}

            <div className="flex justify-between items-center">
              {ipdIssueTableData.length > 0 ? (
                <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                  IPD Issue Details
                </div>
              ) : null}

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 justify-end">
                <span className=" text-black font-semibold whitespace-nowrap w-28">
                  Total Amount
                </span>
                <div className="flex space-x-2 items-center">
                  <span>: ₹</span>
                  <span className="text-gray-700 font-normal">
                    {totaAmount}
                  </span>
                </div>
              </div>

              {/* <div className="flex gap-2 items-center">
                <span className="mt-2 text-black font-semibold whitespace-nowrap">
                  Total Amount
                </span>
                
                {totaAmount} */}
              {/* <InputField
                  name="totalAmount"
                  variant="outlined"
                  disabled={true}
                  // label="Total Amount"
                  // error={errors.firstName}
                  control={control}
                  inputProps={{
                    style: { textTransform: "capitalize" },
                  }}
                /> */}
              {/* </div> */}
            </div>

            <div className="">
              {ipdIssueTableData.length > 0 ? (
                <CommonDynamicTableNew dataResult={ipdIssueTableData} />
              ) : null}
            </div>
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
            {/* <div>
              <button
                type="submit"
                className="h-9 px-3 text-base font-medium border bg-gray-600  text-white  rounded "
                // onClick={onClick}
              >
                Print
              </button>
            </div> */}

            <div>
              {/* <button
                type="submit"
                className="h-9 px-3 text-base font-medium  bg-red-700 text-white rounded "
                onClick={handleClose}
              >
                Close
              </button> */}

              <CommonButton
                label="Close"
                onClick={handleClose}
                className="border border-customRed text-customRed"
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
}
