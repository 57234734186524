import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const linkTemplatesToTheUser = (saveobj) => {
  return apiClient.post(
    `/feedback_user_management/linkTemplatesToTheUser`,
    saveobj,
    {
      headers: authHeader(),
    }
  );
};
export const autoSearchDatais = (searchobj) => {
  return apiClient.post(
    `/feedback_user_management/getEmployeeForLinkTemplatesToUser`,
    searchobj,
    {
      headers: authHeader(),
    }
  );
};
export const getTemplateListForLinkToUser = (searchobj) => {
  return apiClient.post(
    `/feedback_user_management/getTemplateListForLinkToUser`,
    searchobj,
    {
      headers: authHeader(),
    }
  );
};
export const getDepartmentsForTemplateLinkToUser = (searchobj) => {
  return apiClient.post(
    `/feedback_user_management/getDepartmentsForTemplateLinkToUser`,
    searchobj,
    {
      headers: authHeader(),
    }
  );
};
export const getLinkTemplatesToUserListWeb = (searchobj) => {
  return apiClient.post(
    `/feedback_user_management/getLinkTemplatesToUserListWeb`,
    searchobj,
    {
      headers: authHeader(),
    }
  );
};

export const deleteTemplateLinkToTheUser = (id) => {
  return apiClient.delete(
    `/feedback_user_management/deleteTemplateLinkToTheUser/${id}`,
    {
      headers: authHeader(),
    }
  );
};
export const getLinkTemplatesOfTheEmployee = (employeeId) => {
  return apiClient.get(
    `/feedback_user_management/getLinkTemplatesOfTheEmployee/${employeeId}`,
    {
      headers: authHeader(),
    }
  );
};
export const updateLinkTemplatesToTheUser = (updateobj) => {
  return apiClient.put(
    `/feedback_user_management/updateLinkTemplatesToTheUser`,
    updateobj,
    {
      headers: authHeader(),
    }
  );
};
