import { Box, Modal, TextField, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";

import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";

import {
  getConsumptionListPrint,
  getPreviousConsumptionList,
  getPreviousIssueDetailsList,
} from "./services/materialConsumptionServices";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  overflowY: "scroll",
  p: 2,
};

const defaultValues = {
  fromDate: null,
  toDate: null,
  department: null,
};

function NursingMaterialConsumptionModal(props) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [previousConsumptionList, setPreviousConsumptionList] = React.useState(
    []
  );
  const [previousIssueList, setPreviousIssueList] = React.useState([]);
  const [ItemConstIdValue, setItemConstIdValue] = React.useState(null);

  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  React.useEffect(() => {
    previousConsumptionListSearchBtnHandler();
  }, []);

  React.useEffect(() => {
    
  }, [previousConsumptionList]);

  function previousConsumptionListSearchBtnHandler() {
    let fromDate = selectedFromDate;
    let toDate = selectedToDate;

    let fromDateInZFormat = fromDate.toISOString();
    let toDateInZFormat = toDate.toISOString();

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    

    let storeIdVal = userInfoObj.storeId;

    let storeObj = {
      id: storeIdVal,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };

    setValue("department", storeObj);

    let previousConsumptionListObj = {
      fromDate: fromDateInZFormat,
      storeId: storeIdVal,
      todDate: toDateInZFormat,
    };

    

    setPreviousIssueList([]);
    setItemConstIdValue(null);

    getPreviousConsumptionList(previousConsumptionListObj).then((response) => {
      

      setPreviousConsumptionList(response.data.result);
    });
  }

  function populatePreviousIssueList(itemConstIdVal) {
    
   

    getPreviousIssueDetailsList(itemConstIdVal)
      .then((response) => {
        
        setPreviousIssueList(response.data.result);
      })
      .catch((error) => {
        
      });
  }

  const Actions = [
    {
      id: 0,
      action: "checkbox",
      isAction: true,
    },
    {
      id: 1,
      action: "print",
      isAction: true,
    },
  ];

  //function to close the printing modal
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  //function to open the printing modal
  const handleOpenPrintModal = (itemConsumptionId) => {
    
  
    // print service
    getConsumptionListPrint(itemConsumptionId).then((response) => {
      setOpenPrintModal(true);

      setUrlforPrint(response);
    });
  };

  function renderActions(singleDataObj) {
    return (
      <div className="flex items-center justify-start gap-1 ">
        {Actions.map((singleActionObj) => (
          <>
            {singleActionObj.action === "checkbox" &&
            singleActionObj.isAction === true ? (
              <input
                className="w-4 h-4"
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    let ItemConsIdVal = singleDataObj.ItemConsId;
                    populatePreviousIssueList(ItemConsIdVal);
                    
                   
                    setItemConstIdValue(ItemConsIdVal);
                  }
                }}
                checked={
                  singleDataObj.ItemConsId === ItemConstIdValue ? true : false
                }
              />
            ) : null}

            {singleActionObj.action === "print" &&
            singleActionObj.isAction === true ? (
              <>
                <Tooltip title="Print previous consumption list">
                  <LocalPrintshopIcon
                    className="text-[#4B5563] mr-1 cursor-pointer"
                    onClick={() => {
                      
                     
                      let itemConsumptionId = singleDataObj.ItemConsId;
                      handleOpenPrintModal(itemConsumptionId);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  }

  return (
    <>
      <>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <CancelPresentationIconButton onClick={props.close} />
            <div className="p-2">
              <div>
                <div className="text-normal font-semibold text-center">
                  Consumption List
                </div>
                <form>
                  <div className="flex gap-2 my-2">
                    <div className="flex  gap-3">
                      <div className="xl:w-52">
                        <Controller
                          name="fromDate"
                          control={control}
                          defaultValue={selectedFromDate}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { value } }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                value={selectedFromDate}
                                label="From Date"
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) => {
                                  
                                
                                  setSelectedFromDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="bg-white"
                                    fullWidth
                                    size="small"
                                    {...params}
                                    InputLabelProps={{
                                      style: {
                                        fontSize: "14px",
                                        position: "absolute",
                                        top: "-2px",
                                      },
                                    }}
                                    sx={{
                                      svg: {
                                        color: "#1e3a8a",
                                        height: 22,
                                        width: "100%",
                                        marginRight: "16px",
                                      },
                                      backgroundColor: "white",
                                      overflow: "visible",

                                      "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-input": {
                                          // border: 0,
                                          fontSize: 14,
                                          height: "18px",
                                          width: "100%",

                                          borderColor: "#0B83A5",
                                          overflow: "hidden",
                                        },
                                        "& .MuiFormLabel-root": {
                                          fontSize: "14px",
                                        },
                                      },
                                    }}
                                    isDisabled
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                      <div className="xl:w-52">
                        <Controller
                          name="toDate"
                          control={control}
                          defaultValue={selectedToDate}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { value } }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                value={selectedToDate}
                                label="To Date"
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) => {
                                  
                                
                                  setSelectedToDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="bg-white"
                                    fullWidth
                                    size="small"
                                    {...params}
                                    InputLabelProps={{
                                      style: {
                                        fontSize: "14px",
                                        position: "absolute",
                                        top: "-2px",
                                      },
                                    }}
                                    sx={{
                                      svg: {
                                        color: "#1e3a8a",
                                        height: 22,
                                        width: "100%",
                                        marginRight: "16px",
                                      },
                                      backgroundColor: "white",
                                      overflow: "visible",

                                      "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-input": {
                                          // border: 0,
                                          fontSize: 14,
                                          height: "18px",
                                          width: "100%",

                                          borderColor: "#0B83A5",
                                          overflow: "hidden",
                                        },
                                        "& .MuiFormLabel-root": {
                                          fontSize: "14px",
                                        },
                                      },
                                    }}
                                    isDisabled
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </div>
                    <div className="xl:w-96 lg:w-60">
                      <DropdownField
                        control={control}
                        name="department"
                        label="Department"
                        placeholder="Department"
                      />
                    </div>

                    <div className="flex gap-4">
                      <CommonButton
                        searchIcon
                        className="bg-customBlue text-white"
                        onClick={() => {
                          
                          previousConsumptionListSearchBtnHandler();
                        }}
                      />

                      <CommonButton
                        label="Reset"
                        className="border border-customRed text-customRed"
                        onClick={() => {
                          
                          setSelectedFromDate(new Date());
                          setSelectedToDate(new Date());

                          if (previousConsumptionList.length > 0) {
                            setPreviousConsumptionList([]);
                          }

                          if (previousIssueList.length > 0) {
                            setPreviousIssueList([]);
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
                <hr className="w-full  border-t-2 border-customBlue" />

                {previousConsumptionList.length > 0 ? (
                  <>
                    <div className="flex justify-between items-center">
                      <label className="text-normal font-semibold ">
                        Previous Consumption List
                      </label>
                    </div>

                    <CommonDynamicTableNew
                      dataResult={previousConsumptionList}
                      highlightRow={true}
                      tableClass={"rounded lg:h-52 md:h-72"}
                    />
                  </>
                ) : (
                  <div className="grid justify-center items-center row h-44">
                    <div className=" text-black">Data Not Available.</div>
                  </div>
                )}

                {previousIssueList.length > 0 ? (
                  <>
                    <div className="flex justify-between items-center">
                      <label className="text-normal font-semibold ">
                        Issue Details List
                      </label>
                    </div>

                    <CommonDynamicTableNew
                      dataResult={previousIssueList}
                      highlightRow={true}
                      tableClass={"rounded lg:h-52 md:h-72"}
                      removeHeaders={["ItemConsId"]}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </Box>
        </Modal>

        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}
      </>
    </>
  );
}

export default NursingMaterialConsumptionModal;
