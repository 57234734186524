//function to patch the patient fall assessment form.
function patchPatientFallAssessment(patientFallAssessmentObjVal, methods) {
  
  
  let highRiskArr = [];
  let lowRiskArr = [];

  if (patientFallAssessmentObjVal["compParal_Immobiliz"]) {
    //low fall risk
    lowRiskArr.push("complete");
  }

  if (patientFallAssessmentObjVal["hisFallMorethan1in6Months"]) {
    //high fall risk
    highRiskArr.push("history");
  }

  if (patientFallAssessmentObjVal["ptexpFallDurHospitalization"]) {
    //high fall risk
    highRiskArr.push("patient");
  }

  if (patientFallAssessmentObjVal["ptdeemedHighRiskProtocol"]) {
    //high fall risk
    highRiskArr.push("protocol");
  }

  if (highRiskArr.length > 0) {
    methods.setValue("highFallRisk", highRiskArr);
  }

  if (lowRiskArr.length > 0) {
    methods.setValue("lowFallRisk", lowRiskArr);
  }

  

  if (highRiskArr.length === 0 && lowRiskArr.length === 0) {
    let johnHopkinsScoreArray =
      patientFallAssessmentObjVal.hopkinsScoreHeaderPatchDtoList;

    if (johnHopkinsScoreArray) {
      if (johnHopkinsScoreArray[0].scoreHeaderId === 1) {
        if (johnHopkinsScoreArray[0].selectionStatus) {
          methods.setValue("Age", "60 - 69 years");
        }
      }

      if (johnHopkinsScoreArray[1].scoreHeaderId === 2) {
        if (johnHopkinsScoreArray[1].selectionStatus) {
          methods.setValue("Age", "70 -79 years");
        }
      }

      if (johnHopkinsScoreArray[2].scoreHeaderId === 3) {
        if (johnHopkinsScoreArray[2].selectionStatus) {
          methods.setValue("Age", "Greater than or equal to 80 years");
        }
      }

      if (johnHopkinsScoreArray[3].scoreHeaderId === 4) {
        if (johnHopkinsScoreArray[3].selectionStatus) {
          methods.setValue(
            "FallHistory",
            "One fall within 6 months before admission"
          );
        }
      }

      if (johnHopkinsScoreArray[4].scoreHeaderId === 5) {
        if (johnHopkinsScoreArray[4].selectionStatus) {
          methods.setValue("Elimination", "Incontinence");
        }
      }

      if (johnHopkinsScoreArray[5].scoreHeaderId === 6) {
        if (johnHopkinsScoreArray[5].selectionStatus) {
          methods.setValue("Elimination", "Urgency or frequency");
        }
      }

      if (johnHopkinsScoreArray[6].scoreHeaderId === 7) {
        if (johnHopkinsScoreArray[6].selectionStatus) {
          methods.setValue("Elimination", "Urgency/frequency and incontinence");
        }
      }

      if (johnHopkinsScoreArray[7].scoreHeaderId === 8) {
        if (johnHopkinsScoreArray[7].selectionStatus) {
          methods.setValue("Medications", "On 1 high fall risk drug");
        }
      }

      if (johnHopkinsScoreArray[8].scoreHeaderId === 9) {
        if (johnHopkinsScoreArray[8].selectionStatus) {
          methods.setValue("Medications", "On 2 or more high fall risk drugs");
        }
      }

      if (johnHopkinsScoreArray[9].scoreHeaderId === 10) {
        if (johnHopkinsScoreArray[9].selectionStatus) {
          methods.setValue(
            "Medications",
            "Sedated procedure within past 24 hours"
          );
        }
      }

      if (johnHopkinsScoreArray[10].scoreHeaderId === 11) {
        if (johnHopkinsScoreArray[10].selectionStatus) {
          methods.setValue("PatientCareEquipment", "One present");
        }
      }

      if (johnHopkinsScoreArray[11].scoreHeaderId === 12) {
        if (johnHopkinsScoreArray[11].selectionStatus) {
          methods.setValue("PatientCareEquipment", "Two present");
        }
      }

      if (johnHopkinsScoreArray[12].scoreHeaderId === 13) {
        if (johnHopkinsScoreArray[12].selectionStatus) {
          methods.setValue("PatientCareEquipment", "3 or more present");
        }
      }

      if (johnHopkinsScoreArray[13].scoreHeaderId === 14) {
        if (johnHopkinsScoreArray[13].selectionStatus) {
          methods.setValue("mobilityOne", true);
        }
      }

      if (johnHopkinsScoreArray[14].scoreHeaderId === 15) {
        if (johnHopkinsScoreArray[14].selectionStatus) {
          methods.setValue("mobilityTwo", true);
        }
      }

      if (johnHopkinsScoreArray[15].scoreHeaderId === 16) {
        if (johnHopkinsScoreArray[15].selectionStatus) {
          methods.setValue("mobilityThree", true);
        }
      }

      if (johnHopkinsScoreArray[16].scoreHeaderId === 17) {
        if (johnHopkinsScoreArray[16].selectionStatus) {
          methods.setValue("cognitionOne", true);
        }
      }

      if (johnHopkinsScoreArray[17].scoreHeaderId === 18) {
        if (johnHopkinsScoreArray[17].selectionStatus) {
          methods.setValue("cognitionTwo", true);
        }
      }

      if (johnHopkinsScoreArray[18].scoreHeaderId === 19) {
        if (johnHopkinsScoreArray[18].selectionStatus) {
          methods.setValue("cognitionThree", true);
        }
      }
    }
  }
}

export default patchPatientFallAssessment;
