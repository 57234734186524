import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

export default function SelectBedCategoryTable(props) {
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  const [selectedQutRow, setSelectedQutRow] = React.useState([]);

  //useEffect to create bedCategories array onChnage of checkbox
  React.useEffect(() => {
    setSelectedQutRow(props.bedCategory);
    let groupArray = [];
    for (let group of props.bedCategory) {
      let object = {
        label: group["Group Name"],
        totalCharge: 0,
      };
      groupArray.push(object);
      props.setSelectedGroups(groupArray);
    }
  }, [props.bedCategory]);

  //select bedCategories if selectAll checkbox is true||false
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let arr = [];
      for (let obj of props.bedCategory) {
        let object = {
          label: obj["Group Name"],
          totalCharge: 0,
        };
        arr.push(object);
      }
      props.setSelectedGroups(arr);
      const newSelected = props.bedCategory.map((n) => n);
      setSelectedQutRow(newSelected);
      return;
    } else {
      props.setSelectedGroups([]);
    }
    setSelectedQutRow([]);
  };

  // Function to handle perticular checkbox change for selection
  const handleClick = (event, name) => {
    let newSelected = [];
    const selectedIndex = selectedQutRow.indexOf(name);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedQutRow, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedQutRow.slice(1));
    } else if (selectedIndex === selectedQutRow.length - 1) {
      newSelected = newSelected.concat(selectedQutRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedQutRow.slice(0, selectedIndex),
        selectedQutRow.slice(selectedIndex + 1)
      );
    }
    setSelectedQutRow(newSelected);
    let arr = [...props.selectedGroups];
    if (event.target.checked === true) {
      let obj = {
        label: name["Group Name"],
        totalCharge: 0,
      };
      arr.push(obj);
      props.setSelectedGroups(arr);
    } else if (event.target.checked === false) {
      let filter = arr.filter((item) => item.label !== name["Group Name"]);
      props.setSelectedGroups(filter);
    }
  };

  const isSelected = (name) => selectedQutRow.indexOf(name) !== -1;

  return (
    <div>
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded h-48"
            style={{
              borderLeft: "1px solid lightgray",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="whitespace-nowrap"
                    style={{ background: "#F1F1F1" }}
                  >
                    <div className="flex items-center">
                      <Checkbox
                        size="small"
                        className="border  border-gray-600 rounded bg-transparent"
                        type="checkbox"
                        color="primary"
                        checked={
                          props.bedCategory.length > 0 &&
                          selectedQutRow.length === props.bedCategory.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                      <label className="font-bold">Select All</label>
                    </div>
                  </TableCell>
                  <TableCell
                    className="whitespace-nowrap"
                    style={{ background: "#F1F1F1" }}
                  >
                    <span className="font-bold">Group Name</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {props.bedCategory &&
                  props.bedCategory.map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        sx={{
                          "& td": {
                            paddingY: 0,
                          },
                        }}
                        tabIndex={-1}
                        key={index.id}
                        onClick={() => {
                          handleClick(index, row);
                        }}
                      >
                        <TableCell
                          className="whitespace-nowrap"
                          key={index}
                          onClick={() => {}}
                        >
                          <Checkbox
                            size="small"
                            className="border border-gray-600 rounded bg-transparent"
                            type="checkbox"
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) => handleClick(event, row)}
                          />
                        </TableCell>

                        <TableCell
                          className="whitespace-nowrap capitalize"
                          // key={i}
                        >
                          {row["Group Name"]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}
