import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import InputLimit from "../../../../common/components/FormFields/InputLimit";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  addGrnReturnMultiple,
  getGRNNumber,
} from "../../../services/generalStore/grnReturnMultipleServices/GRNReturnMultipleService";
import { supplierListForPOMultiple } from "../../../services/generalStore/pomultipleServices/POMultipleServices";
import { poItemDetailsSearch } from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import { fetchBatchNumber } from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import BatchSelectionModal from "./BatchSelectionModal";
import GRNReturnMultipleTable from "./GRNReturnMultipleTable";

//radio button
const paymentTypeObj = [
  {
    id: "Cash",
    value: "Cash",
    label: "Cash",
  },
  {
    id: "Credit",
    value: "Credit",
    label: "Credit",
  },
];

export default function IssueWithoutIndentModal(props) {
  const defaultValues = {
    storeName: "",
    paymentType: "Cash",
    searchItemCode: null,
    supplier: null,
    batchNo: null,
    batchBalQty: 0,
    returnQty: 0,
    unitRate: 0,
    amount: 0,
    netPayAmount: 0,
    grnNumber: 0,
    grnBalQty: 0,
    remark: "",
    totalAmount: 0,
    netAmount: 0,
    expDate: new Date(),
  };
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues,
  });
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [indexVal, setIndexVal] = React.useState(""); // batchNo index
  const [finalData, setFinalData] = React.useState({}); // post final object
  const [data, setData] = React.useState([]); // all table items
  const [grnNumberObj, setGrnNumberObj] = useState({}); // grnNumber
  const [itemOptions, setItemOptions] = React.useState();
  const [itemId, setItemId] = useState(null);
  const [batchSelectionData, setBatchSelectionData] = useState([]); // selected batch data
  const [selectedRow, setSelectedRow] = React.useState({}); // selecting row obj add into table
  const [grnReturnQuantity, setGrnReturnQty] = useState({}); // showing totalAmount and netAmount
  const [selectedItems, setSelectedItems] = useState(); // table row selected items
  const [supplierOptions, setSupplierOptions] = React.useState([]);
  const [supplierId, setSupplierId] = React.useState(null);
  const [openPost, setOpenPost] = React.useState(false); // open confirmation modal
  const [grnMultipleData, setGrnMultipleData] = useState([]); // add multiple data into table
  const [openBatchSelectionModal, setOpenBatchSelectionModal] =
    React.useState(false); // batch selction modal
  const batchRefrence = useRef(); // ref for batchnumber field
  const handleOpenBatchSelection = () => setOpenBatchSelectionModal(true);
  const handleCloseBatchSelection = () => setOpenBatchSelectionModal(false);

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  //other variables

  const location = useLocation();

  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  let searchItemCode = watch("searchItemCode");
  let paymentTypeVal = watch("paymentType");
  let paymentType;
  if (paymentTypeVal === "Credit") {
    paymentType = 0;
  } else if (paymentTypeVal === "Cash") {
    paymentType = 1;
  }

  //Calculation Start

  let quantity = watch("returnQty");
  let totalAmount =
    Number(searchItemCode && searchItemCode.PurchaseRate) * Number(quantity);
  setValue("unitRate", searchItemCode && searchItemCode.PurchaseRate);

  let gstAmount =
    (totalAmount / 100) * Number(searchItemCode && searchItemCode.VAT);
  let discPercentage = 0;
  let discAmount = (totalAmount / 100) * Number(discPercentage);

  let amountMconceession = totalAmount - Number(discAmount);

  let netAmount = amountMconceession + gstAmount;

  setValue("amount", totalAmount);
  setValue("netPayAmount", netAmount);

  //Calculation End

  React.useEffect(() => {
    if(searchItemCode !== null){
    supplierListForPOMultiple(searchItemCode?.id)
      .then((response) => response.data)
      .then((res) => {
        setSupplierOptions(res.result);
      });
    }

    if (searchItemCode !== null) {
      setValue("grnBalQty", searchItemCode.BalanceQty);
    } else if (searchItemCode === null) {
      setValue("grnBalQty", "");
    }

    if (searchItemCode === null) {
      setValue("supplier", null);
    }

    setItemId(searchItemCode && searchItemCode.id);
  }, [grnReturnQuantity, selectedItems, searchItemCode]);

  // get batch number number
  let BatchSelObj = {
    batchExpDate: new Date(), //"2023-03-04T10:08:03.766Z",
    isConsignment: searchItemCode && searchItemCode.Consignment ? 1 : 0,
    itemId: searchItemCode && searchItemCode.id,
    storeId: getUserInfo.storeId,
  };
  React.useEffect(() => {
    fetchBatchNumber(BatchSelObj)
      .then((response) => response.data)
      .then((res) => {
        setBatchSelectionData(res);
      })
      
    setValue("storeName", getUserInfo.storeName);

    if (itemId !== null && supplierId !== null) {
      getGRNNumber(
        itemId,
        supplierId,
        selectedRow && selectedRow ? selectedRow.BatchCode : 0
      ).then((response) => {
        setGrnNumberObj(response.data.result);
        setValue("grnNumber", response.data.result.GRNNumber);
      });
    }
    setValue(
      "netAmount",
      Number(grnReturnQuantity && grnReturnQuantity.netAmount).toFixed(2)
    );
    setValue(
      "totalAmount",
      Number(grnReturnQuantity && grnReturnQuantity.totalAmount).toFixed(2)
    );

    if (searchItemCode !== null) {
      setValue("batchNo", selectedRow && selectedRow.BatchCode);
      setValue("batchBalQty", selectedRow && selectedRow.BalQty);
      const formatedDate =
        selectedRow &&
        selectedRow.BatchExpDate?.toString()
          .split("-")
          .reverse()
          .join("-")
          .replaceAll("-", "-");
      setValue("expDate", formatedDate);
    } else {
      setValue("expDate", new Date());
      setValue("batchNo", "");
    }
  }, [getUserInfo.storeName, supplierId, itemId, selectedRow]);

  // search items
  const handleChange = (autoSearchItemString) => {
    if (autoSearchItemString !== "" && UseCheckConsecutiveCharacters(autoSearchItemString)) {
      let obj = {
        deptStoreId: getUserInfo.storeId,
        itemCode: "%",
        itemName: "%",
        searchString: autoSearchItemString,
        supplierId: 0,
      };
      poItemDetailsSearch(obj)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setItemOptions(res.result);
        });
    } else {
      setValue("returnQty", 0);
    }
  };
  // add to table grn multiple items
  let searchItemValue = watch("searchItemCode");
  let supplierValue = watch("supplier");
  const addItemsDetailsData = () => {
    let searchItem = getValues("searchItemCode");
    let supplier = getValues("supplier");
    let batchNo = getValues("batchNo");
    let batchBalQty = getValues("batchBalQty");
    let returnQty = getValues("returnQty");
    let unitPerRate = getValues("unitRate");
    let amount = getValues("amount");
    let netAmt = getValues("netPayAmount");
    let grnNumberValue = getValues("grnNumber");
    let grnBalQty = getValues("grnBalQty");
    let expDate = getValues("expDate");
    let QtyNumVal = Number(returnQty);
    if (
      searchItem !== null &&
      supplier !== null &&
      QtyNumVal !== 0 &&
      QtyNumVal > 0
    ) {
      let itemObj = {
        id: searchItem.id,
        itemName: searchItem.label,
        itemCode: searchItem.ItemStoreCode,
        supplier: supplier.label,
        batchNo: batchNo,
        batchBalQty: batchBalQty,
        returnQty: returnQty,
        isConsignment: searchItem.Consignment,
        Conversion: searchItem.Conversion,
        unitPerRate: unitPerRate,
        vatPercentage: searchItem.VAT,
        amount: amount.toFixed(2),
        id: searchItemCode.id,
        MRP: searchItem.MRP,
        netAmount: netAmt.toFixed(2),
        grnNumber: grnNumberValue,
        expDate: expDate,
        grnBalQty: grnBalQty,
      };
      let arr = [...data];
      arr.push(itemObj);

      setData(arr);
      setGrnMultipleData(arr);
    }
  };
  //  submit data
  const onSubmitDataHandler = (data, e) => {
    let finalObj = [];
    let requiredObj = {};
    if (grnMultipleData.length > 0) {
      for (let grnMultipleObj of grnMultipleData) {
        let batchDate = new Date(grnMultipleObj.expDate);
        requiredObj.menuId = location?.state?.menuId;
        requiredObj.privilege = privilege;
        requiredObj.addedBy = getUserInfo.userId;
        requiredObj.amount = Number(grnMultipleObj.amount);
        requiredObj.batchCode = grnMultipleObj.batchNo;
        requiredObj.batchExpiryDate = batchDate.toISOString();
        requiredObj.cashCredit = paymentType;
        requiredObj.conversion = grnMultipleObj.Conversion;
        requiredObj.grnId = grnNumberObj ? grnMultipleObj.GRNId : 0;
        requiredObj.grnReturnDate = new Date();
        requiredObj.isConsignment =
          grnMultipleObj.isConsignment === 1 ? true : false;
        requiredObj.itemId = grnMultipleObj.id;
        requiredObj.landedRate = 0;
        requiredObj.mrp = grnMultipleObj.MRP;
        requiredObj.netAmount = Number(grnMultipleObj.netAmount);
        requiredObj.octroiAmt = 0;
        requiredObj.octroiPer = 0;
        requiredObj.otherTaxAmount = 0;
        requiredObj.purchaseRate = grnMultipleObj.unitPerRate;
        requiredObj.remarks = data.remark;
        requiredObj.returnQty = Number(grnMultipleObj.returnQty);
        requiredObj.storeId = getUserInfo.storeId;
        requiredObj.supplierId = supplierId;
        requiredObj.taxAmount = 0;
        requiredObj.unitPurchaseRate = grnMultipleObj.unitPerRate;
        requiredObj.vatAmount = gstAmount;
        requiredObj.vatPercentage = grnMultipleObj.vatPercentage;
        finalObj.push(requiredObj);
        setFinalData(finalObj);
        setOpenPost(true);
      }
    } else {
      warningAlert("Please Add Item First");
    }
    setValue("issuedQty", "");
  };

  // post service function
  function postGRNReturnMultiple(obj) {
    addGrnReturnMultiple(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          // populateTable();
          reset(defaultValues);
          setGrnMultipleData([]);
          setData([]);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        handleClosePost();
      });
  }
  // open confirmation modal

  function addRecord() {
    setOpenPost(false);
    postGRNReturnMultiple(finalData);
  }

  // enter key press for batch modal
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleOpenBatchSelection(event);
      event.preventDefault();
    }
  };

  return (
    <div className="w-full justify-center items-center rounded lg:px-0 mt-14  ">
      <label className="text-center flex justify-center text-black font-bold text-lg xl:text-xl py-2 ">
        GRN Return(Multiple)
      </label>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="flex gap-4 items-center justify-between px-2 pb-3 w-full ">
          <div className="flex gap-4 items-center w-full">
            <div className=" w-80">
              <InputField
                name="storeName"
                label="Store*"
                placeholder="Store*"
                control={control}
                disabled
              />
            </div>
            <div className="flex  whitespace-nowrap col-span-2 gap-3 items-center p-0.5">
              <p className="font-semibold text-sm xl:text-base">
                Payment Type :
              </p>
              <div className="px-2 flex pt-2 whitespace-nowrap">
                <RadioField
                  name="paymentType"
                  control={control}
                  dataArray={paymentTypeObj}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 lg:grid-cols-7 xl:grid-cols-9 2xl:grid-cols-12 gap-3 mt-4 w-full">
          <div className="col-span-3 flex space-x-3 items-center">
            <label className="font-semibold text-lg whitespace-nowrap ">
              Item Details
            </label>
            <SearchDropdown
              control={control}
              searchIcon={true}
              name="searchItemCode"
              placeholder="Search Item Code"
              isSearchable={true}
              isClearable={true}
              dataArray={itemOptions}
              handleInputChange={handleChange}
              inputRef={{
                ...register("searchItemCode", {
                  onChange: (e) => {
                    if (e.target.value === null) {
                      setValue("searchItemCode", null);
                      setValue("supplier", null);
                      setValue("batchNo", "");
                      setValue("returnQty", 0);
                      setValue("grnNumber", "");
                      setValue("netPayAmount", "");
                      setValue("grnBalQty", "");
                      setValue("batchBalQty", "");
                      setValue("unitRate", "");
                      setValue("batchNo", "");
                    }
                  },
                }),
              }}
            />
          </div>
          <div className=" w-full col-span-2">
            <DropdownField
              control={control}
              error={errors.supplier}
              name="supplier"
              placeholder="Supplier"
              dataArray={supplierOptions}
              // isClearable={true}
              inputRef={{
                ...register("supplier", {
                  onChange: (e) => {
                    setSupplierId(e.target.value.id);
                  },
                }),
              }}
            />
          </div>
          <div className="">
            <TextField
              fullWidth
              size="small"
              name="batchNo"
              variant="outlined"
              inputRef={batchRefrence}
              label="Batch No*"
              // disabled={searchValue === false ? true : false}
              type="text"
              InputLabelProps={{
                shrink: searchItemCode !== null ? true : false,
                style: { fontSize: "14px" },
              }}
              InputProps={{
                style: {
                  fontSize: "14px",
                  height: "36.3px",
                  background: "white",
                },
              }}
              className="border border-customBlue w-20 text-center rounded "
              {...register("batchNo")}
              onKeyPress={handleKeyPress}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIndexVal(e);
                }
              }}
              control={control}
            />
          </div>
          <div className="">
            <InputField
              name="batchBalQty"
              label=" Batch Bal Qty"
              disabled
              placeholder="Batch Bal Qty"
              control={control}
              error={errors.batchBalQty}
            />
          </div>
          <div className="">
            <InputField
              name="returnQty"
              label="Return Qty"
              placeholder="Return Qty"
              type="number"
              control={control}
              error={errors.returnQty}
            />
          </div>

          <div className="">
            <TextField
              name="unitRate"
              {...register("unitRate")}
              size="small"
              label="Unit Per Rate"
              disabled
              InputLabelProps={{
                shrink:
                  searchItemCode && searchItemCode !== null ? true : false,
              }}
              inputProps={{
                style: {
                  height: "18.5px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                },
              }}
              sx={{
                "& .MuiFormLabel-root": {
                  fontSize: "14px",
                },
              }}
            />
          </div>
          <div className="lg:col-span-2">
            <DatePickerFieldNew
              control={control}
              name="expDate"
              label="Expiry Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("expDate", newValue);
              }}
              disablePast={true}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="">
            <InputField
              name="amount"
              label="Amount"
              placeholder="Amount"
              disabled
              control={control}
            />
          </div>
          <div className="">
            <InputField
              name="netPayAmount"
              label="Net Pay Amount"
              placeholder="Net Pay Amount"
              type="number"
              control={control}
              error={errors.netPayAmount}
              disabled={true}
            />
          </div>

          <div className="col-span-2">
            <InputLimit
              name="grnNumber"
              label="GRN Number"
              placeholder="GRN Number"
              disabled
              control={control}
              error={errors.grnNumber}
            />
          </div>
          <div className="">
            <InputField
              name="grnBalQty"
              label="GRN Bal Qty"
              type="number"
              placeholder="GRN Bal Qty"
              control={control}
              error={errors.grnBalQty}
              disabled={true}
            />
          </div>

          <div className="flex justify-end xl:justify-start col-span-4 lg:col-span-1 ">
            <CommonButton
              type="button"
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addItemsDetailsData();
                if (searchItemValue === null) {
                  warningAlert("Please Select Item");
                } else if (supplierValue === null) {
                  warningAlert("Please Select Supplier");
                } else {
                  setValue("searchItemCode", null);
                  setValue("supplier", null);
                  setValue("batchNo", "");
                  setValue("returnQty", 0);
                  setValue("grnNumber", "");
                  setValue("netPayAmount", "");
                  setValue("grnBalQty", "");
                  setValue("batchBalQty", "");
                  setValue("unitRate", "");
                  setValue("batchNo", "");
                  setGrnNumberObj({});
                }
              }}
            />
          </div>
        </div>
        <GRNReturnMultipleTable
          data={data}
          setData={setData}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          setGrnReturnQty={setGrnReturnQty}
          setSelectedItems={setSelectedItems}
          setGrnMultipleData={setGrnMultipleData}
        />
        <div className="grid grid-cols-4 gap-2 py-2">
          <div className="">
            <InputField control={control} name="remark" label="Remark" />
          </div>
          <div className="">
            <InputField
              control={control}
              name="totalAmount"
              label="Total Amount"
            />
          </div>
          <div className="">
            <InputField control={control} name="netAmount" label="Net Amount" />
          </div>
        </div>
        <div className="flex justify-end py-2 gap-2">
          {userActions.map((obj) => (
            <>
              {!obj.isAction ? (
                <>
                  {obj.action === "Create" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        onClick={() => {
                          reset(defaultValues);
                          setGrnMultipleData([]);
                          setSupplierId(null);
                          setData([]);
                          setFinalData({});
                          setValue("amount", "");
                          setValue("netPayAmount", "");
                        }}
                      />

                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                        onClick={()=>{
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>

      {openBatchSelectionModal ? (
        <div>
          <BatchSelectionModal
            handleClose={handleCloseBatchSelection}
            setOpen={setOpenBatchSelectionModal}
            open={openBatchSelectionModal}
            handelOpen={handleOpenBatchSelection}
            data={batchSelectionData}
            setData={setBatchSelectionData}
            searchItemCode={searchItemCode && searchItemCode.label}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
          />
        </div>
      ) : null}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
