import React, { useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { MiscellaneousIncomeReportData } from "../services/miscellaneousIcomeServices/MiscellaneousIcomeServices";

export default function MiscellaneousIncomeReport(props) {
  const printRef = useRef(null);
  const [dataResult, setdataResult] = React.useState();

  let obj = {
    accountHeadId: null,
    fromDate: props.fromDate,
    toDate: props.toDate,
  };

  React.useEffect(() => {
    MiscellaneousIncomeReportData(obj)
      .then((response) => response.data)
      .then((res) => {
        setdataResult(res.result);
      });
  }, [props]);

  React.useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <h1 className=" font-semibold text-[15px] ">
              Inamdar Multispeciality Hospital
            </h1>
            <h1 className=" text-[11px] ">
              Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
            </h1>
            <h1 className=" mt-2 font-semibold text-[13px]">
              Miscellaneous Income Report
            </h1>
          </div>
          <div>
            <div className=" grid grid-cols-[10%_10%_20%_15%_10%_20%_15%] text-[11px]  p-1 border-t-[1px] border-b-[1px] border-black">
              <div className=" font-semibold">SR.No.</div>
              <div className=" font-semibold">BillNo</div>
              <div className=" font-semibold">Name</div>
              <div className=" font-semibold">Amount</div>
              <div className=" font-semibold">Cash/Credit</div>
              <div className=" font-semibold">AccountHead Name</div>
              <div className=" font-semibold">Remark</div>
            </div>
          </div>
        </thead>
        <tbody className="w-full">
          <div>
            {dataResult?.map((data) => {
              return (
                <div className=" grid grid-cols-[10%_10%_20%_15%_10%_20%_15%] text-[11px] p-1 border-b-[1px]">
                  <div>{data?.SerialNumber}</div>
                  <div>{data?.BillNo}</div>
                  <div>{data?.Name}</div>
                  <div>{data?.Amount}</div>
                  <div>{data?.CashCredit}</div>
                  <div>{data?.AccountHeadName}</div>
                  <div>{data?.Remark}</div>
                </div>
              );
            })}
          </div>
        </tbody>
      </table>
    </div>
  );
}
