import axios from "axios";
import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
//get patient info by id
//hiiii
export const getRefundOfAdvancePatientInfoById = (patientId) => {
  return apiClient.get(`/patientInfo/bill/${patientId}`, {
    headers: authHeader(),
  });
};
//Refund of Advance listing
// /api/refundOfAdvance/advances
export const listOfAdvanceService = (advance) => {
  return apiClient.post(`/refundOfAdvance/advances`, advance, {
    headers: authHeader(),
  });
};

///api/refundOfAdvance/advance/refundOfAdvance/{advanceId}
export const listofRefundService = (advanceId) => {
  return apiClient.get(
    `/refundOfAdvance/advance/refundOfAdvance/${advanceId}`,
    {
      headers: authHeader(),
    }
  );
};

//post refund of advancec
// /api/refundOfAdvance/advance/saveRefundOfAdvance
export const postRefundOfAdvanceService = (postObj) => {
  return apiClient.post(
    `/refundOfAdvance/advance/saveRefundOfAdvance`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

export const opdRefundOfAdvancePdfPrint = (advanceID) => {
  return apiClient.get(
    `/reports/bills/advanceRefundReceipt?refundId=${advanceID}`,
    {
      headers: authHeader(),

      responseType: "blob",
    }
  );
};
