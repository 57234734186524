import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TableCell, TextField, Tooltip } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import {
  fetchDoctorType,
  fetchDoctorsOfType,
  fetchEmployeeName,
  fetchGroupOt,
  fetchOtTables,
  fetchSubGroup,
  fetchSurgeryProcedure,
} from "../../services/otReservationServices/OtReservationServices";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

const SurgeryDetails = (props) => {
  const {
    setGroupId,
    doctorDetailsArr,
    setDoctorDetailsArr,
    operationTime,
    setOperationTime,
    setOperationDate,
    operationDate,
    setEmpDetailsArr,
    empDetailsArr,
  } = props;

  //
  const [groupData, setGroupData] = useState();
  const [subGroupData, setSubGroupData] = useState();
  const [otTablesData, setOtTablesData] = useState();
  const [surgeryProcedureData, setSurgeryProcedureData] = useState();
  const [doctorTypes, setDoctorTypes] = useState();
  const [doctors, setDoctors] = useState();
  const [employeeNameData, setEmployeeNameData] = useState();

  const handleChangeTime = (newValue) => {
    setOperationTime(newValue);
  };

  const {
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    watch,
    register,
    resetField,
    setValue,
  } = useFormContext();

  let Group = watch("group");
  let SubGroup = watch("subGroup");

  function unDuplicateArraySingleValues(array) {
    if (!array || !Array.isArray(array) || array.length === 0) {
      return array;
    }
    return [...new Set(array)];
  }

  function unDuplicateArrayObjects(array, propertyName) {
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      !propertyName
    ) {
      return array;
    }
    let objectArrayKeys = array.map((item) => item[propertyName]);

    let uniqueKeys = unDuplicateArraySingleValues(objectArrayKeys);
    return uniqueKeys.map((key) =>
      array.find((item) => item[propertyName] === key)
    );
  }

  const addDoctorData = () => {
    let surgery = getValues("surgeryName");
    let doctorType = getValues("doctorType");
    let doctorName = getValues("doctorName");

    if (surgery !== null && doctorType !== null && doctorName !== null) {
      let surgeryDoctorObj = {
        id: surgery.id,
        "Surgery Name": surgery.label,
        "Doctor Details": [
          {
            "Doctor Id": doctorName.map((item) => ({
              id: item.id,
              doctorTypeId: doctorType.id,
            })),
            "Doctor Type Id": doctorType.id,
            "Doctor Type": doctorType.label,
            "Doctor Name": doctorName.map((item) => item.label),
          },
        ],
      };

      let arr = [...doctorDetailsArr];
      arr.push(surgeryDoctorObj);
      ///

      const mergedData = arr.reduce((accumulator, currentObject) => {
        const existingObject = accumulator.find(
          (item) =>
            item.id === currentObject.id &&
            item["Surgery Name"] === currentObject["Surgery Name"]
        );

        if (existingObject) {
          // Merge Doctor Details of existing object and current object
          existingObject["Doctor Details"] = [
            ...existingObject["Doctor Details"],
            ...currentObject["Doctor Details"],
          ];
        } else {
          accumulator.push(currentObject);
        }

        return accumulator;
      }, []);

      ///

      setDoctorDetailsArr(
        mergedData
      );
      setValue("services", "", { shouldValidate: true });
      setValue("serviceDoctor", null, { shouldValidate: true });
      setValue("Qty", "", { shouldValidate: true });
      //
      setValue("group", null);
      setValue("subGroup", null);
    }

    if (surgery === null && doctorType === null && doctorName === null) {
      setError("surgeryName", { type: "custom", message: "Required" });
      setError("doctorType", { type: "custom", message: "Required" });
      setError("doctorName", { type: "custom", message: "Required" });
    } else if (surgery === null) {
      setError("surgeryName", { type: "custom", message: "Required" });
    } else if (doctorType === null) {
      setError("doctorType", { type: "custom", message: "Required" });
    } else if (doctorName === null) {
      setError("doctorName", { type: "custom", message: "Required" });
    } else {
      clearErrors("surgeryName");
      clearErrors("doctorType");
      clearErrors("doctorName");
    }
  };
  const addEmployeeData = () => {
    let emp = getValues("EmployeeName");

    if (emp !== null) {
      let empObj = {
        id: emp.id,
        "Employee Name": emp.label,
      };

      let arr = [...empDetailsArr];
      arr.push(empObj);
      setEmpDetailsArr(unDuplicateArrayObjects(arr, "id"));
      // setValue("EmployeeName", null, { shouldValidate: true });
    }

    if (emp === null) {
      // setError("services", { type: "custom", message: "Required" });
      setError("EmployeeName", { type: "custom", message: "Required" });
    } else {
      clearErrors("EmployeeName");
    }
  };

  /////////services

  useEffect(() => {
    // group
    fetchGroupOt()
      .then((response) => response.data)
      .then((res) => {
        setGroupData(res.result);
      });

    // otTables
    fetchOtTables()
      .then((response) => response.data)
      .then((res) => {
        setOtTablesData(res.result);
      });

    // otTables
    fetchDoctorType()
      .then((response) => response.data)
      .then((res) => {
        setDoctorTypes(res.result);
      });

    // EmployeeName
    fetchEmployeeName()
      .then((response) => response.data)
      .then((res) => {
        setEmployeeNameData(res.result);
      });
  }, []);

  const getSurgeryProcedure = (searchString) => {
    if (searchString) {
      fetchSurgeryProcedure(searchString)
        .then((response) => response.data)
        .then((res) => {
          setSurgeryProcedureData(res.result);
        });
    }
  };

  useEffect(() => {
    if (Group && Group !== null) {
      setGroupId(Group.id);

      fetchSubGroup(Group.id)
        .then((response) => response.data)
        .then((res) => {
          setSubGroupData(res.result);
        });
    }
  }, [Group, SubGroup]);

  const renderActions1 = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...doctorDetailsArr];
      newTemplateData.splice(index, 1);
      setDoctorDetailsArr(newTemplateData);
    };
    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <DeleteOutlineOutlinedIcon
            sx={{ color: "#d32f2f " }}
            onClick={() => {
              deleteRow(index);
            }}
          />
        </Tooltip>
      </div>
    );
  };
  const renderInput1 = (row, index, header) => {
    return (
      <>
        {header === "Doctor Name" && (
          <div className="flex gap-2 items-center">
            {row["Doctor Name"].map((item, index) => {
              return <span>{index + 1 + "." + item}</span>;
            })}
          </div>
        )}

        {header === "Surgery Name" && row["Surgery Name"]}
        {header === "Doctor Details" &&
          (row["Doctor Details"].length > 0 ? (
            <div className="w-full mt-2 col-span-8">
              <CommonDynamicTableNew
                dataResult={row["Doctor Details"]}
                // renderActions={renderActions1}
                renderInput={renderInput1}
                editableColumns={[
                  "Doctor Name",
                  "Surgery Name",
                  "Doctor Details",
                ]}
                tableClass="rounded"
                removeHeaders={["id", "Doctor Id", "Doctor Type Id"]}
                highlightRow={false}
              />
            </div>
          ) : null)}
      </>
    );
  };

  const renderActions2 = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...empDetailsArr];
      newTemplateData.splice(index, 1);
      setEmpDetailsArr(newTemplateData);
    };
    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <DeleteOutlineOutlinedIcon
            sx={{ color: "#d32f2f " }}
            onClick={() => {
              deleteRow(index);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-3 mt-3">
        {/*  Date of operation*/}
        <div className="">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              // disableFuture
              label="Date Of Operation"
              value={operationDate}
              name="DateOfOperation"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                let date = format(newValue, "yyyy-MM-dd");
                setOperationDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  className="bg-white"
                  fullWidth
                  name="DateOfOperation"
                  size="small"
                  defaultValue=""
                  disabled={true}
                  inputFormat="dd/MM/yyyy"
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  sx={{
                    svg: { color: "#0B83A5" },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        {/* time of operation */}
        <div className=" ">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Time"
              value={operationTime}
              onChange={handleChangeTime}
              renderInput={(params) => (
                <TextField
                  className="bg-white"
                  name="timeOfDeath"
                  fullWidth
                  size="small"
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  sx={{
                    svg: { color: "#0B83A5" },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        {/* Duration */}
        <div className=" ">
          <InputField
            name="duration"
            variant="outlined"
            label="Duration (In Minutes)"
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/* //Group// */}
        <div className="">
          <DropdownField
            control={control}
            name="group"
            dataArray={groupData}
            isSearchable={false}
            isClearable={false}
            placeholder="Group"
            inputRef={{
              ...register("group", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setGroupId(e.target.value.id);
                  }
                  setValue("subGroup", null);
                },
              }),
            }}
          />
        </div>
        {/* //sub Group// */}
        <div className="">
          <DropdownField
            control={control}
            name="subGroup"
            label="Sub Group"
            dataArray={subGroupData}
            isSearchable={false}
            isClearable={false}
            placeholder="Sub Group"
            inputRef={{
              ...register("subName", {
                onChange: () => {
                  resetField();
                },
              }),
            }}
          />
        </div>
        {/* //ot Table// */}
        <div className="">
          <DropdownField
            control={control}
            name="otTable"
            label="Nationality"
            dataArray={otTablesData}
            isSearchable={false}
            isClearable={false}
            placeholder="OT Table"
          />
        </div>
      </div>
      {/* doctor list */}
      <div className="">
        <div className="my-3">
          <span className=" text-gray-700 font-semibold whitespace-nowrap">
            surgery Details
          </span>
        </div>
        <div className="grid grid-cols-8 gap-3">
          <div className="col-span-2">
            <SearchDropdown
              name="surgeryName"
              label="surgery Name"
              placeholder="Surgery Name "
              isSearchable={true}
              isClearable={true}
              dataArray={surgeryProcedureData}
              handleInputChange={(e) => {
                getSurgeryProcedure(e);
              }}
              searchIcon={true}
              error={errors.surgeryName}
              control={control}
              inputRef={{
                ...register("surgeryName", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setValue("group", {
                        id: e.target.value.subGroupId,
                        label: e.target.value.Groupmaster,
                        value: e.target.value.Groupmaster,
                      });

                      setValue("subGroup", {
                        id: e.target.value.groupId,
                        label: e.target.value.Subgroup,
                        value: e.target.value.Subgroup,
                      });
                    } else {
                      setValue("group", null);
                      setValue("subGroup", null);
                    }
                  },
                }),
              }}
            />
          </div>

          <div className="col-span-2">
            {/* <SearchBar doctor */}
            <DropdownField
              control={control}
              error={errors.doctorType}
              name="doctorType"
              label="Doctor Type"
              searchIcon={true}
              dataArray={doctorTypes}
              inputRef={{
                ...register("doctorType", {
                  onChange: (e) => {
                    setValue("doctorName", null);
                    if (e !== null) {
                      fetchDoctorsOfType(e.target.value.id)
                        .then((response) => response.data)
                        .then((res) => {
                          setDoctors(res.result);
                        });
                    }
                  },
                }),
              }}
              isSearchable={true}
              isClearable={false}
              placeholder="Doctor Type "
            />
          </div>

          <div className="col-span-2">
            {/* <SearchBar doctor */}
            <DropdownField
              control={control}
              error={errors.doctorName}
              name="doctorName"
              label="Doctor Name"
              searchIcon={true}
              dataArray={doctors}
              isSearchable={true}
              isClearable={false}
              placeholder="Doctor Name "
              isMulti={true}
            />
          </div>

          <div className=" flex gap-3">
            <CommonButton
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addDoctorData();
               // setValue("surgeryName", null);
                setValue("doctorType", null);
                setValue("doctorName", null);
              }}
            />
          </div>
          {/* table */}
          {doctorDetailsArr.length > 0 ? (
            <div className="w-full mt-2 col-span-8">
              <CommonDynamicTableNew
                dataResult={doctorDetailsArr}
                renderActions={renderActions1}
                renderInput={renderInput1}
                editableColumns={[
                  "Doctor Name",
                  "Surgery Name",
                  "Doctor Details",
                ]}
                tableClass="rounded h-auto lg:max-h-56 md:h-72"
                removeHeaders={["id", "Doctor Id", "Doctor Type Id"]}
                highlightRow={false}
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* employee list */}
      <div className="">
        <div className="mb-3">
          <span className=" text-gray-700 font-semibold whitespace-nowrap">
            Employee List
          </span>
        </div>
        <div className="grid grid-cols-6 gap-3">
          <div className="col-span-2">
            <DropdownField
              control={control}
              error={errors.EmployeeName}
              name="EmployeeName"
              label="Employee Name"
              searchIcon={true}
              dataArray={employeeNameData}
              isSearchable={true}
              isClearable={true}
              placeholder="Employee Name "
            />
          </div>

          <div className=" flex gap-3 justify-end">
            <CommonButton
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addEmployeeData();
                setValue("EmployeeName", null);
              }}
            />
          </div>

          {empDetailsArr.length > 0 ? (
            <div className="w-full mt-2 col-span-8">
              <CommonDynamicTableNew
                dataResult={empDetailsArr}
                renderActions={renderActions2}
                tableClass="rounded lg:h-52 md:h-72"
                removeHeaders={["id"]}
              />
            </div>
          ) : null}

          {/* table */}
        </div>
      </div>
      <div className="my-3">
        {/* //Other Diseases.// */}

        <TextField
          id="outlined-multiline-static"
          name="operationInstruction"
          {...register("operationInstruction")}
          label="Operation Instruction"
          multiline
          InputLabelProps={{ shrink: true }}
          rows={1}
          fullWidth
        />
      </div>
    </>
  );
};

export default SurgeryDetails;
