import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const addGrnReturnMultiple = async (createGrnMultiple) => {
  return await apiClient.post(`/grn/saveGrnReturnMultiple`, createGrnMultiple, {
    headers: authHeader(),
  });
};

export const getGRNNumber = (batchCode, itemId, supplierId) => {
  return apiClient.get(
    `/grn/grnNumberForGrnReturnMultiple/${batchCode}/${itemId}/${supplierId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getBatchNumberForGRN = (itemId, storeId) => {
  return apiClient.get(`/grn/batchNumberForGRN/${itemId}/${storeId}`, {
    headers: authHeader(),
  });
};
//
export const getGrnReturnListing = async (grnObj) => {
  return await apiClient.post(`/grn/grnReturnListing`, grnObj, {
    headers: authHeader(),
  });
};

//
export const grnReturnListingDetails = (storeId, grnReturnId) => {
  return apiClient.get(
    `/grn/grnReturnListingDetails/${storeId}/${grnReturnId}`,
    {
      headers: authHeader(),
    }
  );
};

export const verifyGrnReturn = (grnReturnId,menuId,privilege) => {
  return apiClient.get(`/grn/verifyGrnReturn/${grnReturnId}/${menuId}/${privilege}`, {
    headers: authHeader(),
  });
};
// reports/inventory/GRNReturn?grnReturnId=1
export const fetchPrintGrnReturnList = (grnId) => {
  return apiClient.get(`/reports/inventory/GRNReturn?grnReturnId=${grnId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};
