import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//
export const fetchOfficePermissionsList = (listObj) => {
  return apiClient.post(
    `/officepermission/billingOfficePermissionList`,
    listObj,
    {
      headers: authHeader(),
    }
  );
};

//
export const fetchOfficePermissionListAutoComplete = (autoCopmleteObj) => {
  return apiClient.post(
    `/officepermission/officePermissionListAutoComplete`,
    autoCopmleteObj,
    {
      headers: authHeader(),
    }
  );
};

//
export const fetchOfficePermissionAcceptListAutoComplete = (
  autoCopmleteObj
) => {
  return apiClient.post(
    `/officepermission/officePermissionAcceptListAutoComplete`,
    autoCopmleteObj,
    {
      headers: authHeader(),
    }
  );
};

//
export const fetchOfficePermissionAcceptedList = (AcceptedListObj) => {
  return apiClient.post(
    `/officepermission/officePermissionAcceptedList`,
    AcceptedListObj,
    {
      headers: authHeader(),
    }
  );
};

//
export const fetchPreviousList = (PreviousListObj) => {
  return apiClient.post(`/officepermission/previousList`, PreviousListObj, {
    headers: authHeader(),
  });
};

//
export const saveOfficePermission = (saveObj) => {
  return apiClient.post(`/officepermission/saveOfficePermission`, saveObj, {
    headers: authHeader(),
  });
};

export const getOfficePermissionSearch = (searchString, floorId) => {
  return apiClient.get(
    `officepermission/getPatientSearch/${searchString}/${floorId}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveNewOfficePermission = (saveObj) => {
  return apiClient.post(
    `/officepermission/createNewOfficePermission`,
    saveObj,
    {
      headers: authHeader(),
    }
  );
};

