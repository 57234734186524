import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { Tooltip } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

export default function PurchaseEnquiryItemTable(props) {
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.itemData];
      newTemplateData.splice(deleteIndex, 1);
      props.setItemData(newTemplateData);
      handleClosePost();
    }
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-56 2xl:h-72"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Actions</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Item Name</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">U.O.M.</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Quantity</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Pack Size</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Remark</span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.itemData.map((enquiry, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="flex gap-2">
                            <Tooltip title="Delete">
                              <div
                                className="text-red-700"
                                onClick={() => {
                                  setOpenPost(true);
                                  setDeleteIndex(index);
                                }}
                              >
                                {<DeleteOutlineOutlinedIcon />}
                              </div>
                            </Tooltip>

                            <Tooltip title="Edit">
                              <div
                                value="click"
                                className="text-[#1e3a8a]"
                              >
                                <DriveFileRenameOutlineIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.editRow(enquiry, index);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {enquiry.itemMaster.label}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {enquiry.uom}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {enquiry.quantity}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {enquiry.packageSize}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {enquiry.remark}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {props.itemData.length > 0 ? null : (
                <div className="flex items-center justify-center text-gray-400 font-semibold mt-9">
                  No Item Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this item ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
