import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../authservices/auth-service";

const user = JSON.parse(localStorage.getItem("userInfo"));

export const login = createAsyncThunk(
  "auth/login",
  async ({ loginId, password, unitId, cashCounterId }, thunkAPI) => {
    try {
      const data = await AuthService.login(
        loginId,
        password,
        unitId,
        cashCounterId
      );
      return { user: data };
    } catch (error) {
      (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage());
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("/auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user, routeList: ["/"] }
  : { isLoggedIn: false, user: null ,routeList: ["/"]};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    routeAdded: (state, action) => {
      
      let checkDuplicateRoutes = state?.routeList.includes(action.payload);
      
      if (!checkDuplicateRoutes) {
        state.routeList.push(action.payload);
      }
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.routeList = [];
    },
  },
});
const { reducer, actions } = authSlice;

export const { routeAdded } = actions;

export default reducer;
