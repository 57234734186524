import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import { postMyVacantBeds } from "../../../../../commonServices/bedServices/BedServices";

import {
  fetchAdmissionType,
  fetchBedCategory,
  fetchChargesPerBedCategory,
  fetchWard,
} from "../../../../../commonServices/miscellaneousServices/MiscServices";
import { useLocation } from "react-router-dom";
import CommonDynamicTableNew from "../../../../../common/components/CommonTable/CommonDynamicTableNew";

const BedDetails = (props) => {
  //
  let location = useLocation();
  //
  const { selectedBedDetails, setSelectedBedDetails } = props;
  const [admissionType, setAdmissionType] = useState();
  const [bedCategory, setBedCategory] = useState([]);
  const [selectedBedCategory, setSelectedBedCategory] = useState(0);
  const [chargesPerBedCategory, setChargesPerBedCategory] = useState([]);
  const [ward, setWard] = useState([]);
  const [selectedWard, setSelectedWard] = useState(0);

  const [vacantBedDetails, setVacantBedDetails] = useState([]);

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  let token = JSON.parse(localStorage.getItem("userInfo"));
  let unitID = token.unitId;

  const BedObj = {
    categoryId: selectedBedCategory,
    unitId: unitID,
    wardId: selectedWard,
  };

  useEffect(() => {
    getVacantBeds();
  }, [selectedBedCategory, unitID, selectedWard]);

  useEffect(() => {
    getAdmissionType();
    // getChargesPerBedCategory();
    getWard();
    getBedCategory();
  }, []);

  const getAdmissionType = () => {
    fetchAdmissionType()
      .then((response) => response.data)
      .then((res) => {
        setAdmissionType(res.result);
      });
  };

  const getBedCategory = () => {
    console.log("fetchBedCategory");
    fetchBedCategory()
      .then((response) => response.data)
      .then((res) => {
        setBedCategory(res.result);
        setChargesPerBedCategory(res.result);
      });
  };

  // const getChargesPerBedCategory = () => {
  //   fetchChargesPerBedCategory()
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setChargesPerBedCategory(res.result);
  //     });
  // };

  const getWard = () => {
    fetchWard()
      .then((response) => response.data)
      .then((res) => {
        setWard(res.result);
      });
  };
  const getVacantBeds = () => {
    postMyVacantBeds(BedObj)
      .then((response) => response.data)
      .then((res) => {
        setVacantBedDetails(res.result);
      })
      .catch(() => {});
  };

  useEffect(() => {
    //
    if (
      location.state !== null &&
      location.state.bedDetails !== undefined &&
      location.state.bedDetails !== null
    ) {
      setSelectedBedDetails(location.state.bedDetails);
      setValue(
        "BedCategory",
        {
          id: location.state.bedDetails.CategoryId,
          label: location.state.bedDetails.Class,
          value: location.state.bedDetails.Class,
        },
        {
          shouldValidate: true,
        }
      );
      setValue(
        "ChargesPerBedCategory",
        {
          id: location.state.bedDetails.CategoryId,
          label: location.state.bedDetails.Class,
          value: location.state.bedDetails.Class,
        },
        {
          shouldValidate: true,
        }
      );
      setValue(
        "ward",
        {
          id: location.state.bedDetails.WardId,
          label: location.state.bedDetails.Ward,
          value: location.state.bedDetails.Ward,
        },
        {
          shouldValidate: true,
        }
      );
    }
  }, []);

  const handleSelectedRow = (row) => {
    props.setSelectedBedDetails(row);

    setValue(
      "BedCategory",
      {
        id: row.CategoryId,
        label: row.Category,
        value: row.Category,
      },
      { shouldValidate: true }
    );
    setValue(
      "ChargesPerBedCategory",
      {
        id: row.CategoryId,
        label: row.Category,
        value: row.Category,
      },
      { shouldValidate: true }
    );
    setValue(
      "ward",
      {
        id: row.WadId,
        label: row.Ward,
        value: row.Ward,
      },
      { shouldValidate: true }
    );
  };
  return (
    <div>
      <div className="grid grid-cols-12 gap-3">
        <div className="lg:grid lg:grid-cols-1 md:grid-cols-1 lg:gap-3 md:gap-3 mt-2 lg:col-span-3 md:col-span-5">
          {/* //Admission Type*/}
          <div className="md:mb-3 lg:mb-0">
            <DropdownField
              control={control}
              error={errors.admissionType}
              name="admissionType"
              label="admissionType"
              dataArray={admissionType}
              isSearchable={false}
              isClearable={false}
              placeholder="Admission Type *"
            />
          </div>
          {/* //Bed Category*/}
          <div className="md:mb-3 lg:mb-0">
            <DropdownField
              control={control}
              error={errors.BedCategory}
              name="BedCategory"
              label="Bed Category"
              dataArray={bedCategory}
              isSearchable={false}
              // isClearable={true}
              isDisabled={props.isForEdit}
              placeholder="Bed Category *"
              inputRef={{
                ...register("BedCategory", {
                  onChange: (e) => {
                    setSelectedBedCategory(e.target.value.id);
                  },
                }),
              }}
            />
          </div>
          {/* //charges Bed Category// */}
          <div className="md:mb-3 lg:mb-0">
            <DropdownField
              control={control}
              error={errors.ChargesPerBedCategory}
              name="ChargesPerBedCategory"
              label="ChargesPerBedCategory"
              dataArray={chargesPerBedCategory}
              isSearchable={false}
              isDisabled={props.isForEdit}
              placeholder="Charges Per Bed Category *"
            />
          </div>
          {/* //Ward */}
          <div className="md:mb-3 lg:mb-0">
            <DropdownField
              control={control}
              error={errors.ward}
              name="ward"
              label="Ward"
              dataArray={ward}
              isSearchable={false}
              isDisabled={props.isForEdit}
              // isClearable={true}
              placeholder="Ward *"
              inputRef={{
                ...register("ward", {
                  onChange: (e) => {
                    setSelectedWard(e.target.value.id);
                  },
                }),
              }}
            />
          </div>

          {/* //BedNumber */}
          <div className="md:mb-3 lg:mb-0 flex gap-3">
            <p className="font-bold"> Bed Number </p>
            {selectedBedDetails !== null ? (
              <p> : {selectedBedDetails.BedNo || selectedBedDetails.Bed}</p>
            ) : (
              <p>Choose Bed</p>
            )}
          </div>
        </div>

        <div className="lg:col-span-9 md:col-span-7">
          {vacantBedDetails.length > 0 &&
          selectedBedCategory !== null &&
          !props.isForEdit ? (
            <CommonDynamicTableNew
              dataResult={vacantBedDetails}
              handleSelectedRow={handleSelectedRow}
              tableClass="h-72"
              removeHeaders={[
                "CategoryId",
                "RoomId",
                "Sex",
                "WadId",
                "BedId",
                "Code",
              ]}
            />
          ) : (
            <p className="text-center items-center">
              Please Select Bed Category
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BedDetails;
