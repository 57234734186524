import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DeleteIcon, EditIcon } from "../../../../assets/CommonAssets";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonTransactionPaginationTable from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  autoSearchDatais,
  deleteTemplateLinkToTheUser,
  getLinkTemplatesOfTheEmployee,
  getLinkTemplatesToUserListWeb,
} from "../../../services/feedback/assignTemplate/AssignTemplateService";
import AssignTemplateModal from "./AssignTemplateModal";

export default function AssignTemplate() {
  const { control } = useForm();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState();
  const [dataResult, setDataResult] = useState([]);
  const [edit, setEdit] = useState(false);
  const [getrowdata, setGetrowdata] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);
  //   Modal
  const [openAssignTemplateModal, setOpenAssignTemplateModal] = useState(false);
  const [selectedSearchRecord, setSelectedSearchRecord] = useState(null);

 
  const [userOptions, setUserOptions] = useState([]);

  const getRowfetchdata = (row) => {
    getLinkTemplatesOfTheEmployee(row?.["Employee Id"])
      .then((response) => response.data)
      .then((res) => {
        setGetrowdata(res.result);
      });
  };

  //Get Row Id Function
  const deleteRow = (row) => {
    setDeleteId(row?.Id);
    setOpenDeleteConfirmationModal(true);
  };

  //Delete Record API Function
  function deleteRecord() {
    deleteTemplateLinkToTheUser(deleteId)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenDeleteConfirmationModal(false);
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  const handleChange = (autoSearchString) => {
    let searchobj = {
      searchString: autoSearchString,
    };

    if (autoSearchString !== "") {
      autoSearchDatais(searchobj)
        .then((response) => response.data)
        .then((res) => {
          setUserOptions(res.result);
        });
    }
  };

  const searchFilde = (value) => {
    setSelectedSearchRecord(value);
  };

  useEffect(() => {
    populateTable();
  }, [selectedSearchRecord]);

  const populateTable = (forPagination) => {
    let searchObj = {
      id: selectedSearchRecord?.id || null,
      // searchString: selectedSearchRecord?.label || null,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    getLinkTemplatesToUserListWeb(searchObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  };

  const renderActions = (row, index) => {
    return (
      <div className=" flex items-center space-x-2">
        <>
          <Tooltip>
            <button
              onClick={() => {
                setEdit(true);
                getRowfetchdata(row);
                setOpenAssignTemplateModal(true);
              }}
            >
              <EditIcon />
            </button>
          </Tooltip>

          <div>
            <button
              onClick={() => {
                deleteRow(row);
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        </>
      </div>
    );
  };

  // useMemo(() => {
  //   populateTable();
  // }, [listingObject?.id, listingObject?.searchString]);

  return (
    <div className="mt-16">
      <h1 className="flex justify-center font-semibold text-lg">
        Assign Template
      </h1>
      <div className=" grid grid-cols-5 gap-3">
        <div className="col-span-2">
          <SearchBar
            control={control}
            name="searchByIndentNumber"
            placeholder="Search By User Name"
            handleInputChange={handleChange}
            dataArray={userOptions}
            onChange={searchFilde}
            isClearable={true}
            searchIcon={true}
          />
        </div>

        {/* <CommonButton
          type="button"
          searchIcon={true}
          className="searchIcon bg-customBlue text-white"
          onClick={() => {
            setListingObject((listingObject) => ({
              ...listingObject,
              id: searchString?.id ? searchString?.id : null,
              searchString: searchBar,
            }));
            populateTable();
          }}
        />*/}
        
        <div className=" col-span-3 flex justify-end">
          <CommonButton
            label="+ Add"
            type="button"
            className="otherButton bg-customBlue text-white"
            onClick={() => {
              setOpenAssignTemplateModal(true);
            }}
          />
        </div>
      </div>
      {dataResult?.length > 0 ? (
        <div className="mt-2">
          <CommonTransactionPaginationTable
            populateTable={populateTable}
            dataResult={dataResult}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            count={count}
            setRowsPerPage={setRowsPerPage}
            renderActions={renderActions}
            highlightRow={false}
            removeHeaders={["Id", "Employee Id"]}
          />
        </div>
      ) : (
        <p className="flex justify-center mt-20 font-bold text-gray-600">
          No Record Found...
        </p>
      )}
      {openAssignTemplateModal && (
        <AssignTemplateModal
          open={openAssignTemplateModal}
          handleClose={() => setOpenAssignTemplateModal(false)}
          populateTable={populateTable}
          getrowdata={getrowdata}
          setGetrowdata={setGetrowdata}
          edit={edit}
          setEdit={setEdit}
        />
      )}

      <ConfirmationModal
        confirmationOpen={openDeleteConfirmationModal}
        confirmationHandleClose={() => setOpenDeleteConfirmationModal(false)}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />
    </div>
  );
}
