import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputArea from "../../../../common/components/FormFields/InputArea";

const FamilyHistory = () => {
  const {
    control,

    register,
  } = useFormContext();
  return (
    <>
      <div className="grid lg:grid-cols-6 md:grid-cols-3">
        <div>
          <CheckBoxField control={control} name="fHtn" label="HTN" />
        </div>
        <div>
          <CheckBoxField control={control} name="fDm" label="DM" />
        </div>
        <div>
          <CheckBoxField
            control={control}
            name="fTuberculosis"
            label="Tuberculosis"
          />
        </div>
        <div>
          <CheckBoxField control={control} name="fIhd" label="IHD" />
        </div>
        <div>
          <CheckBoxField control={control} name="fAsthama" label="Asthma" />
        </div>
        <div>
          <CheckBoxField control={control} name="fStroke" label="Stroke" />
        </div>
      </div>
      <div className="">
        <div className="">
          <InputArea
            name="fOtherDiseases"
            control={control}
            label="Other Diseases"
            placeholder="Other Diseases"
          />
        </div>
      </div>
    </>
  );
};

export default FamilyHistory;
