import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getGender,
  getNSIPatientSearch,
  getNatureOfInjury,
  getPatientCondition,
  getPlaceOfIncident,
  getReccomendedTests,
  getVaccineHistory,
  saveNeedleStickInjury,
} from "../../services/needleStickInjuryServices/NeedleStickInjuryServices";
import NeedleStickInjuryListModal from "./NeedleStickInjuryListModal";
import InputArea from "../../../common/components/FormFields/InputArea";

const HCW = [
  { id: 1, label: "Yes", value: 1 },
  { id: 0, label: "No", value: 0 },
];

const HOD = [
  { id: 1, label: "Yes", value: 1 },
  { id: 0, label: "No", value: 0 },
];

const SourcePatient = [
  { id: 1, label: "Known", value: 1 },
  { id: 0, label: "Unknown", value: 0 },
];

const NeedleStickInjury = () => {
  const defaultValues = {
    diagnosis: "",
    reasonOfNSI: "",
    gender: null,
    HCW: null,
    sourcePatient: null,
    HOD: null,
    healthCareWorkerName: "",
    age: "",
    casualty: "",
    employeeId: "",
    designation: "",
    dateOfInjury: new Date(),
    siteDepthOfInjury: "",
    IMIVInjection: false,
    vacutainer: false,
    needleSyringe: false,
    surgery: false,
    other: false,
    duringOr: false,
    afterIntend: false,
    dateOfInformed: new Date(),
    witness: "",
    folloupDate0: new Date(),
    folloupDate1: new Date(),
    folloupDate2: new Date(),
    natureOfInjury0: false,
    natureOfInjury1: false,
    natureOfInjury2: false,
    natureOfInjury3: false,
    natureOfInjury4: false,
    placeOfIncident0: false,
    placeOfIncident1: false,
    placeOfIncident2: false,
    placeOfIncident3: false,
    placeOfIncident4: false,
    placeOfIncident5: false,
    vaccineDate0: new Date(),
    vaccineDate1: new Date(),
    vaccineDate2: new Date(),
    vaccineDate3: new Date(),
  };
  const { control, watch, setValue, reset, register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const [openListingModal, setOpenListingModal] = useState(false);
  const [natureOfInjury, setNatureOfInjury] = useState([]);
  const [patientCondition, setPatientCondition] = useState([]);
  const [placeOfIncident, setPlaceOfIncident] = useState([]);
  const [recommendedTest, setRecommendedTest] = useState([]);
  const [vaccineHistory, setVaccineHistory] = useState([]);
  const [gender, setGender] = useState([]);
  const [patientOptions, setPatientOptions] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [editData, setEditData] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [openPostConfirm, setOpenPostConfirm] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  function onSubmitData(data) {
    let natureOfInjuryIdArr = [];
    for (let obj of natureOfInjury) {
      if (obj.checked === true) {
        natureOfInjuryIdArr.push(obj.id);
      }
    }

    let placeOfIncidentIdArr = [];
    for (let obj of placeOfIncident) {
      if (obj.checked === true) {
        placeOfIncidentIdArr.push(obj.id);
      }
    }

    let patientConditionArr = [];
    for (let obj of patientCondition) {
      if (obj.positive === true || obj.negative === true) {
        patientConditionArr.push({
          dtlsId: editData ? obj.Id : null,
          description: obj.label,
          id: obj.id,
          negative: obj.negative,
          positive: obj.positive,
        });
      }
    }

    let vaccineHistoryArr = [];
    for (let obj of vaccineHistory) {
      if (obj.yes === true || obj.no === true) {
        vaccineHistoryArr.push({
          date: obj.date,
          dtlsId: editData ? obj.Id : null,
          description: obj.label,
          id: obj.id,
          negative: obj.no,
          positive: obj.yes,
        });
      }
    }

    let recommendedTestArr = [];
    for (let obj of recommendedTest) {
      recommendedTestArr.push({
        date: obj.date
          ? obj.date
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
        dtlsId: editData ? obj.Id : null,
        description: obj.label,
        id: obj.id,
      });
    }

    let postObj = {
      id: editData ? editData?.Id : null,
      actionTakenInCasualty: data.casualty,
      age: data.age,
      dateOfInjury: format(data.dateOfInjury, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      designationDepartment: data.designation,
      diagnosisOfPT: data.diagnosis,
      employeeId: data.employeeId,
      genderId: data.gender?.id,
      healthWorkName: data.healthCareWorkerName,
      informDate: format(data.dateOfInformed, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      informToHOD: Number(data.HOD),
      mrNo: editData ? editData?.MRNo : patientInfo?.MRno,
      natureOfInjury: natureOfInjuryIdArr,
      patientConditionDtoList: patientConditionArr,
      patientName: editData ? editData?.PatientName : patientInfo?.PatientName,
      placeOfIncidence: placeOfIncidentIdArr,
      q6IMorIVInj: data.IMIVInjection === true ? 1 : 0,
      q6NeedleSyringe: data.needleSyringe === true ? 1 : 0,
      q6Other: data.other === true ? 1 : 0,
      q6Surgery: data.surgery === true ? 1 : 0,
      q6Vacutainer: data.vacutainer === true ? 1 : 0,
      q7AfterTheIntended: data.afterIntend === true ? 1 : 0,
      q7DuringOr: data.duringOr === true ? 1 : 0,
      q9KnownUnknown: Number(data.sourcePatient),
      reasonForNSI: data.reasonOfNSI,
      reccomendedTestsDtoList: recommendedTestArr,
      siteAndDepthOfInjury: data.siteDepthOfInjury,
      vaccineHistoryDtoList: vaccineHistoryArr,
      ward: editData ? editData?.Ward : patientInfo?.Ward,
      wasHCW: Number(data.HCW),
      witnessName: data.witness,
    };
    if (postObj?.patientName !== "" && postObj?.patientName !== undefined) {
      setFinalData(postObj);
      setOpenPostConfirm(true);
    } else {
      warningAlert("Please fill the form...!");
    }
  }

  function saveRecord() {
    setOpenBackdrop(true);
    saveNeedleStickInjury(finalData)
      .then((response) => {
        successAlert(response.data.message);
        setOpenBackdrop(false);
        reset(defaultValues);
        setOpenPostConfirm(false);
        setPatientCondition([]);
        setVaccineHistory([]);
        setRecommendedTest([]);
        getPatientConditionList();
        getVaccineHistoryList();
        getRecommendedList();
        setEditData(null);
        setPatientInfo(null);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
        setOpenPostConfirm(false);
      });
  }

  function getPatientConditionList() {
    getPatientCondition().then((response) => {
      response.data.result.forEach((val) => {
        val.positive = false;
        val.negative = false;
      });
      setPatientCondition(response.data.result);
    });
  }

  function getVaccineHistoryList() {
    getVaccineHistory().then((response) => {
      response.data.result.forEach((val) => {
        val.yes = false;
        val.no = false;
        val.date = "";
      });
      setVaccineHistory(response.data.result);
    });
  }

  function getRecommendedList() {
    getReccomendedTests().then((response) => {
      response.data.result.forEach((val) => {
        val.date = "";
      });
      setRecommendedTest(response.data.result);
    });
  }

  function handleChange(autoSearchString) {
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      getNSIPatientSearch(autoSearchString).then((response) => {
        setPatientOptions(response.data.result);
      });
    }
  }

  function autoSelectedValue(value) {
    if (value !== null) {
      setPatientInfo(value);
    } else {
      setPatientInfo(null);
    }
  }

  useEffect(() => {
    getNatureOfInjury().then((response) => {
      response.data.result.forEach((val) => {
        val.checked = false;
      });
      setNatureOfInjury(response.data.result);
    });

    getPlaceOfIncident().then((response) => {
      response.data.result.forEach((val) => {
        val.checked = false;
      });
      setPlaceOfIncident(response.data.result);
    });

    getGender().then((response) => {
      setGender(response.data.result);
    });

    getPatientConditionList();
    getVaccineHistoryList();
    getRecommendedList();
  }, []);

  useEffect(() => {
    if (editData !== null) {
      setValue("healthCareWorkerName", editData.HealthWorkName);
      setValue("age", editData.Age);
      setValue(
        "gender",
        gender.filter((val) => val?.id === editData.GenderId)[0]
      );
      setValue("employeeId", editData.EmployeeId);
      setValue("designation", editData.Designation_Department);
      setValue("siteDepthOfInjury", editData.SiteAndDepthOfInjury);
      setValue("casualty", editData.ActionTakenInCasualty);
      setValue("diagnosis", editData.DiagnosisOfPT);
      setValue("reasonOfNSI", editData.ReasonForNSI);
      setValue("witness", editData.WitnessName);
      setValue("HCW", editData.WasHCW);
      setValue("sourcePatient", editData.Q9_Known_Unknown);
      setValue("HOD", editData.InformToHOD);
      setValue("dateOfInjury", new Date(editData.DateOfInjury));
      setValue("dateOfInformed", new Date(editData.InformDate));
      setValue("IMIVInjection", editData.Q6_IMorIVInj === 1 ? true : false);
      setValue("vacutainer", editData.Q6_Vacutainer === 1 ? true : false);
      setValue(
        "needleSyringe",
        editData.Q6_Needle_Syringe === 1 ? true : false
      );
      setValue("surgery", editData.Q6_Surgery === 1 ? true : false);
      setValue("other", editData.Q6_Other === 1 ? true : false);
      setValue("duringOr", editData.Q7_DuringOr === 1 ? true : false);
      setValue(
        "afterIntend",
        editData.Q7_AfterTheIntended === 1 ? true : false
      );

      for (let n = 0; n < natureOfInjury?.length; n++) {
        for (let c = 0; c < editData?.NatureOfInjury?.length; c++) {
          if (natureOfInjury[n].id === editData?.NatureOfInjury[c]) {
            natureOfInjury[n].checked = true;
            setValue(`natureOfInjury${n}`, true);
          }
        }
      }

      for (let p = 0; p < placeOfIncident?.length; p++) {
        for (let c = 0; c < editData?.PlaceOfIncidence?.length; c++) {
          if (placeOfIncident[p].id === editData?.PlaceOfIncidence[c]) {
            placeOfIncident[p].checked = true;
            setValue(`placeOfIncident${p}`, true);
          }
        }
      }

      let patchPatientCondition = [...patientCondition];
      for (let p = 0; p < patchPatientCondition?.length; p++) {
        for (let c = 0; c < editData.PatientConditions?.length; c++) {
          if (
            patchPatientCondition[p].id ===
            editData.PatientConditions[c].NSISectionId
          ) {
            patchPatientCondition[p].positive =
              editData.PatientConditions[c].Positive === 1 ? true : false;
            patchPatientCondition[p].negative =
              editData.PatientConditions[c].Negative === 1 ? true : false;
            patchPatientCondition[p].Id = editData.PatientConditions[c].Id;
            patchPatientCondition[p].id =
              editData.PatientConditions[c].NSISectionId;
          }
          setPatientCondition(patchPatientCondition);
        }
      }

      let patchVaccineHistory = [...vaccineHistory];
      for (let p = 0; p < patchVaccineHistory?.length; p++) {
        for (let c = 0; c < editData.VaccineHistory?.length; c++) {
          if (
            patchVaccineHistory[p].id ===
            editData.VaccineHistory[c].NSISectionId
          ) {
            patchVaccineHistory[p].yes =
              editData.VaccineHistory[c].Yes === 1 ? true : false;
            patchVaccineHistory[p].no =
              editData.VaccineHistory[c].No === 1 ? true : false;
            patchVaccineHistory[p].date = editData.VaccineHistory[c].Date;
            patchVaccineHistory[p].id = editData.VaccineHistory[c].NSISectionId;
            patchVaccineHistory[p].Id = editData.VaccineHistory[c].Id;
          }
          setVaccineHistory(patchVaccineHistory);
        }
      }

      let patchRecommendedTests = [...recommendedTest];
      for (let p = 0; p < patchRecommendedTests?.length; p++) {
        for (let c = 0; c < editData.ReccomendedTests?.length; c++) {
          if (
            patchRecommendedTests[p].id ===
            editData.ReccomendedTests[c].NSISectionId
          ) {
            patchRecommendedTests[p].date = editData.ReccomendedTests[c].Date;
            patchRecommendedTests[p].id =
              editData.ReccomendedTests[c].NSISectionId;
            patchRecommendedTests[p].Id = editData.ReccomendedTests[c].Id;
          }
          setRecommendedTest(patchRecommendedTests);
        }
      }
    }
  }, [editData]);

  return (
    <div className="mt-10 py-2">
      <div className="text-lg font-semibold text-center">
        Needle Stick Injury
      </div>
      <form onSubmit={handleSubmit(onSubmitData)} className="space-y-2">
        <div className="flex justify-between items-center">
          <label className="font-semibold text-base">Staff Details</label>
          <CommonButton
            type="button"
            label="Needle Stick Injury Listing"
            className="bg-customBlue text-white"
            onClick={() => {
              setOpenListingModal(true);
            }}
          />
        </div>
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-3">
          <InputField
            control={control}
            name="healthCareWorkerName"
            label="Name of the Health Care Worker"
          />
          <div className="flex space-x-2">
            <div className="w-40">
              <InputField
                control={control}
                name="age"
                label="Age"
                type="number"
              />
            </div>
            <div className="w-60">
              <InputField
                control={control}
                name="employeeId"
                label="Employee ID"
              />
            </div>
            <div className="w-full">
              <DropdownField
                control={control}
                name="gender"
                placeholder="Gender"
                dataArray={gender}
              />
            </div>
          </div>
          <InputField
            control={control}
            name="designation"
            label="Designation / Department"
          />
        </div>
        <div className="space-y-2">
          <label className="font-semibold text-base">Injury Information</label>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
            <div className="flex items-center">
              <label className="text-sm font-semibold w-full">
                1. Date of Injury
              </label>
              <DatePickerFieldNew
                control={control}
                name="dateOfInjury"
                label="Date of Injury "
                value={new Date()}
                size="small"
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className="flex items-center lg:col-span-3">
              <label className="text-sm font-semibold w-60">
                2. Site and Depth of Injury
              </label>
              <InputField
                control={control}
                name="siteDepthOfInjury"
                label="Site and Depth of Injury"
              />
            </div>
            <div className="flex space-x-5 lg:col-span-4 items-center">
              <div className="text-sm font-semibold w-40">
                3. Nature of Injury
              </div>
              <div className="flex space-x-2">
                {natureOfInjury?.map((injury, index) => {
                  return (
                    <fieldset
                      className="w-28"
                      onChange={(e) => {
                        let tempData = [...natureOfInjury];
                        tempData[index].checked = e.target.checked;
                        setNatureOfInjury(tempData);
                      }}
                    >
                      <CheckBoxField
                        control={control}
                        name={`natureOfInjury${index}`}
                        label={injury?.label}
                        defaultValue={injury.checked}
                      />
                    </fieldset>
                  );
                })}
              </div>
            </div>
            <div className="flex space-x-5 lg:col-span-4 items-center">
              <div className="text-sm font-semibold w-40">
                4. Place of Incidence
              </div>
              <div className="flex space-x-2">
                {placeOfIncident?.map((place, index) => {
                  return (
                    <fieldset
                      className="w-28"
                      onChange={(e) => {
                        let tempData = [...placeOfIncident];
                        tempData[index].checked = e.target.checked;
                        setPlaceOfIncident(tempData);
                      }}
                    >
                      <CheckBoxField
                        control={control}
                        name={`placeOfIncident${index}`}
                        label={place?.label}
                      />
                    </fieldset>
                  );
                })}
              </div>
            </div>
            <div className="flex items-center lg:col-span-2">
              <div className="text-sm font-semibold w-full">
                5.Was the HCW wearing the gloves ?
              </div>
              <fieldset className="w-full mt-1">
                <RadioField control={control} name="HCW" dataArray={HCW} />
              </fieldset>
            </div>
            <div className="col-span-4 flex items-center space-x-2">
              <div className="text-sm font-semibold w-max">
                6.What was the original intended use of the sharp ?
              </div>
              <div className="flex items-center space-x-4">
                <CheckBoxField
                  control={control}
                  name="IMIVInjection"
                  label="a) IM or IV injection"
                />
                <div className="flex space-x-2 items-center col-span-2">
                  <div className="text-xs">{`b) Drawing of Blood`}</div>
                  <CheckBoxField
                    control={control}
                    name="vacutainer"
                    label="Vacutainer"
                  />
                  <CheckBoxField
                    control={control}
                    name="needleSyringe"
                    label="Needle / Syringe"
                  />
                </div>
                <CheckBoxField
                  control={control}
                  name="surgery"
                  label="c) Surgery"
                />
                <CheckBoxField
                  control={control}
                  name="other"
                  label="d) Other"
                />
              </div>
            </div>
            <div className="flex space-x-2 items-center col-span-2">
              <div className="text-sm font-semibold w-40">
                7.Did the injury occur ?
              </div>
              <CheckBoxField
                control={control}
                name="duringOr"
                label="a) During Or"
              />
              <CheckBoxField
                control={control}
                name="afterIntend"
                label="b) After the intended use of the sharp"
              />
            </div>
            <div className="flex space-x-2 col-span-4">
              <div className="text-sm font-semibold w-64">
                8.Action taken in casualty
              </div>
              <div className="w-full">
                <InputArea
                  control={control}
                  name="casualty"
                  label="Action taken in casualty"
                  {...register("casualty")}
                />
              </div>
            </div>
            <div className="col-span-4 space-y-2">
              <div className="flex items-center space-x-5">
                <div className="text-sm font-semibold w-max">
                  9.Source Patient
                </div>
                <fieldset className="mt-1">
                  <RadioField
                    control={control}
                    name="sourcePatient"
                    dataArray={SourcePatient}
                  />
                </fieldset>
                <div className="w-96">
                  <SearchBar
                    name="searchPatient"
                    placeholder="Search By MRNo/Patient Name"
                    searchIcon={true}
                    handleInputChange={handleChange}
                    onChange={autoSelectedValue}
                    dataArray={patientOptions}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3 rounded bg-[#F1F1F1] px-2 py-2 text-sm">
                <div className="flex items-center">
                  <label className="font-semibold">Patient Name&nbsp;</label>
                  <label>
                    :
                    {editData
                      ? editData?.PatientName
                      : patientInfo?.PatientName}
                  </label>
                </div>
                <div className="flex items-center">
                  <label className="font-semibold">MRNo&nbsp;</label>
                  <label>
                    : {editData ? editData?.MRNo : patientInfo?.MRno}
                  </label>
                </div>
                <div className="flex items-center">
                  <label className="font-semibold">Ward&nbsp;</label>
                  <label>
                    : {editData ? editData?.Ward : patientInfo?.Ward}
                  </label>
                </div>
              </div>
              <div className="w-full">
                <InputArea
                  control={control}
                  name="diagnosis"
                  label="Diagnosis of the Patient"
                  {...register("diagnosis")}
                />
              </div>
            </div>
            <div className="col-span-4 space-y-2 w-full">
              <div className="text-sm font-semibold w-40">10.Reason Of NSI</div>
              <div className="w-full">
                <InputArea
                  control={control}
                  name="reasonOfNSI"
                  label="Reason of NSI"
                  {...register("reasonOfNSI")}
                />
              </div>
            </div>
            <div className="col-span-2 space-y-2 my-2">
              <div className="flex items-center space-x-2">
                <label className="text-sm font-semibold w-52">
                  Informed to concerned HOD
                </label>
                <fieldset className="mt-1">
                  <RadioField control={control} name="HOD" dataArray={HOD} />
                </fieldset>
                <div className="w-40">
                  <DatePickerFieldNew
                    control={control}
                    name="dateOfInformed"
                    label="Date of Informed "
                    value={new Date()}
                    size="small"
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="w-full">
                <InputField
                  control={control}
                  name="witness"
                  label="Witness at the time of incident (if available)"
                />
              </div>
            </div>
            <div className="col-span-2 my-2">
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "0px",
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      <TableCell
                        size="small"
                        className=" text-lg font-semibold"
                      >
                        Patient Condition
                      </TableCell>
                      <TableCell
                        size="small"
                        className=" text-lg font-semibold"
                      >
                        Positive
                      </TableCell>
                      <TableCell
                        size="small"
                        className=" text-lg font-semibold"
                      >
                        Negative
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientCondition?.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "& td": {
                            paddingY: 1,
                          },
                        }}
                      >
                        <TableCell
                          size="small"
                          component="th"
                          scope="row"
                          sx={{
                            paddingY: 0.1,
                          }}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          size="small"
                          component="th"
                          scope="row"
                          sx={{
                            paddingY: 0.1,
                          }}
                        >
                          <Checkbox
                            control={control}
                            name={`${row?.label}positive`}
                            checked={row?.positive}
                            onChange={(e) => {
                              let tempData = [...patientCondition];
                              tempData[index].positive = e.target.checked;
                              tempData[index].negative = false;
                              setPatientCondition(tempData);
                            }}
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          size="small"
                          component="th"
                          scope="row"
                          sx={{
                            paddingY: 0.1,
                          }}
                        >
                          <Checkbox
                            control={control}
                            name={`${row?.label}negative`}
                            checked={row?.negative}
                            onChange={(e) => {
                              let tempData = [...patientCondition];
                              tempData[index].positive = false;
                              tempData[index].negative = e.target.checked;
                              setPatientCondition(tempData);
                            }}
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="col-span-4 grid grid-cols-2 gap-x-4">
              <div>
                <div className="text-sm font-semibold w-full">
                  Vaccination history of the employee if vaccinated or
                  previously tested.
                </div>
                <div className="my-2">
                  <TableContainer
                    component={Paper}
                    sx={{
                      borderRadius: "0px",
                    }}
                  >
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              paddingY: 0.5,
                              backgroundColor: "#F1F1F1",
                            },
                          }}
                        >
                          <TableCell
                            size="small"
                            className=" text-lg font-semibold"
                          ></TableCell>
                          <TableCell
                            size="small"
                            className=" text-lg font-semibold"
                          >
                            Yes
                          </TableCell>
                          <TableCell
                            size="small"
                            className=" text-lg font-semibold"
                          >
                            No
                          </TableCell>
                          <TableCell
                            size="small"
                            className=" text-lg font-semibold"
                          >
                            Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vaccineHistory?.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "& td": {
                                paddingY: 1,
                              },
                            }}
                          >
                            <TableCell
                              size="small"
                              component="th"
                              scope="row"
                              sx={{
                                paddingY: 0.1,
                              }}
                            >
                              {row.label}
                            </TableCell>
                            <TableCell
                              size="small"
                              component="th"
                              scope="row"
                              sx={{
                                paddingY: 0.1,
                              }}
                            >
                              <Checkbox
                                control={control}
                                name={`${row?.label}yes`}
                                checked={row?.yes}
                                onChange={(e) => {
                                  let tempData = [...vaccineHistory];
                                  tempData[index].yes = e.target.checked;
                                  tempData[index].no = false;
                                  if (e.target.checked === true) {
                                    tempData[index].date = format(
                                      new Date(),
                                      "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
                                    );
                                  }
                                  setVaccineHistory(tempData);
                                }}
                                size="small"
                              />
                            </TableCell>
                            <TableCell
                              size="small"
                              component="th"
                              scope="row"
                              sx={{
                                paddingY: 0.1,
                              }}
                            >
                              <Checkbox
                                control={control}
                                name={`${row?.label}no`}
                                checked={row?.no}
                                onChange={(e) => {
                                  let tempData = [...vaccineHistory];
                                  tempData[index].yes = false;
                                  tempData[index].no = e.target.checked;
                                  if (e.target.checked === true) {
                                    tempData[index].date = "";
                                  }
                                  setVaccineHistory(tempData);
                                }}
                                size="small"
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                control={control}
                                defaultValue={new Date()}
                                rules={{
                                  required: true,
                                }}
                                render={({ field, value }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      disabled={row?.yes !== true}
                                      disablePast
                                      label="New Date"
                                      value={row?.date ? row?.date : new Date()}
                                      inputFormat="dd-MM-yyyy"
                                      onChange={(newValue) => {
                                        let tempData = [...vaccineHistory];
                                        tempData[index].date = format(
                                          newValue,
                                          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
                                        );
                                        setVaccineHistory(tempData);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          className="bg-white"
                                          name="fromDateCalender"
                                          fullWidth
                                          size="small"
                                          {...params}
                                          sx={{
                                            svg: {
                                              color: "#1e3a8a",
                                              height: 22,
                                              width: "100%",
                                              marginRight: "16px",
                                            },
                                            backgroundColor: "white",
                                            overflow: "visible",

                                            "& .MuiOutlinedInput-root": {
                                              "& .MuiOutlinedInput-input": {
                                                // border: 0,
                                                fontSize: 14,
                                                height: "18px",
                                                width: "100%",

                                                borderColor: "#0B83A5",
                                                overflow: "hidden",
                                              },
                                              "& .MuiFormLabel-root": {
                                                fontSize: "14px",
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                                name={`vaccineDate${index}`}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div>
                <div className="text-sm font-semibold w-full">
                  Recommended tests for followup
                </div>
                <div className="my-2">
                  <TableContainer
                    component={Paper}
                    sx={{
                      borderRadius: "0px",
                    }}
                  >
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              paddingY: 0.5,
                              backgroundColor: "#F1F1F1",
                            },
                          }}
                        >
                          <TableCell
                            size="small"
                            className=" text-lg font-semibold"
                          ></TableCell>
                          <TableCell
                            size="small"
                            className=" text-lg font-semibold"
                          >
                            Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {recommendedTest?.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "& td": {
                                paddingY: 1,
                              },
                            }}
                          >
                            <TableCell
                              size="small"
                              component="th"
                              scope="row"
                              sx={{
                                paddingY: 1,
                              }}
                            >
                              {row.label}
                            </TableCell>
                            <TableCell
                              size="small"
                              component="th"
                              scope="row"
                              sx={{
                                paddingY: 1,
                              }}
                            >
                              <Controller
                                control={control}
                                defaultValue={new Date()}
                                rules={{
                                  required: true,
                                }}
                                render={({ field, value }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      disablePast
                                      label="New Date"
                                      value={row?.date ? row?.date : new Date()}
                                      inputFormat="dd-MM-yyyy"
                                      onChange={(newValue) => {
                                        let tempData = [...recommendedTest];
                                        tempData[index].date = format(
                                          newValue,
                                          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
                                        );
                                        setRecommendedTest(tempData);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          className="bg-white"
                                          name="fromDateCalender"
                                          fullWidth
                                          size="small"
                                          {...params}
                                          sx={{
                                            svg: {
                                              color: "#1e3a8a",
                                              height: 22,
                                              width: "100%",
                                              marginRight: "16px",
                                            },
                                            backgroundColor: "white",
                                            overflow: "visible",

                                            "& .MuiOutlinedInput-root": {
                                              "& .MuiOutlinedInput-input": {
                                                // border: 0,
                                                fontSize: 14,
                                                height: "18px",
                                                width: "100%",

                                                borderColor: "#0B83A5",
                                                overflow: "hidden",
                                              },
                                              "& .MuiFormLabel-root": {
                                                fontSize: "14px",
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                                name={`followupDate${index}`}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="flex space-x-2 justify-end my-2">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                      setPatientCondition([]);
                      setVaccineHistory([]);
                      setRecommendedTest([]);
                      getPatientConditionList();
                      getVaccineHistoryList();
                      getRecommendedList();
                      setEditData(null);
                      setPatientInfo(null);
                    }}
                  />
                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <NeedleStickInjuryListModal
        openListingModal={openListingModal}
        setOpenListingModal={setOpenListingModal}
        setEditData={setEditData}
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPostConfirm}
        confirmationHandleClose={() => {
          setOpenPostConfirm(false);
        }}
        confirmationSubmitFunc={saveRecord}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to add record ?"
        confirmationButtonMsg="Yes"
      />
    </div>
  );
};

export default NeedleStickInjury;
