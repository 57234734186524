import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import { yupResolver } from "@hookform/resolvers/yup";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CustomInput from "../../../common/components/FormFields/CustomInput";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { saveAcceptIssueNote } from "../../services/bloodIssueAcceptanceServices.js/BloodIssueAcceptanceServices";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { useLocation } from "react-router-dom";
import RadioField from "../../../common/components/FormFields/RadioField";

const schema = yup
  .object({
    wholeBloodDtls: yup.string().when("wholeBlood", {
      is: true,
      then: yup.string().required(),
    }),
    pcvDtls: yup.string().when("pcv", {
      is: true,
      then: yup.string().required(),
    }),
    ffpDtls: yup.string().when("ffp", {
      is: true,
      then: yup.string().required(),
    }),
    rdpDtls: yup.string().when("rdp", {
      is: true,
      then: yup.string().required(),
    }),
    sdpDtls: yup.string().when("sdp", {
      is: true,
      then: yup.string().required(),
    }),
    cryoprecipitateDtls: yup.string().when("cryoprecipitate", {
      is: true,
      then: yup.string().required(),
    }),
    specifyAnyOtherDtls: yup.string().when("specifyAnyOther", {
      is: true,
      then: yup.string().required(),
    }),
  })
  .required();

  
const RequiredBloodFor = [
  {
    id: 0,
    label: "Adult",
    value: "Adult",
  },
  {
    id: 1,
    label: "Pediatric",
    value: "Pediatric",
  },
];

export default function BloodIssueAcceptanceDetailsModal(props) {
  let location = useLocation();
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, selectedRow, populateTable, privilege } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      routine: false,
      emergency: false,
      adult: false,
      pediatric: false,
      pcv: false,
      ffp: false,
      rdp: false,
      sdp: false,
      cryoprecipitate: false,
      specifyAnyOther: false,
    },
  });

  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
  } = methods;

  const WholeBlood = watch("wholeBlood");
  const pcv = watch("pcv");
  const ffp = watch("ffp");
  const rdp = watch("rdp");
  const sdp = watch("sdp");
  const cryoprecipitate = watch("cryoprecipitate");
  const specifyAnyOther = watch("specifyAnyOther");

  useEffect(() => {
    setValue("routine", selectedRow?.Routine);
    setValue("emergency", selectedRow?.Emergency);
    setValue("adult", selectedRow?.Adult);
    setValue("pediatric", selectedRow?.Pediatric);
    setValue(
      "wholeBlood",
      selectedRow && selectedRow["Whole Blood"] === 1 ? true : false
    );
    setValue("wholeBloodDtls", selectedRow && selectedRow["Whole Blood Qty"]);
    setValue("pcvDtls", selectedRow && selectedRow["Pvc Qty"]);
    setValue("rdpDtls", selectedRow && selectedRow["RDP Qty"]);
    setValue("sdpDtls", selectedRow && selectedRow["SDP Qty"]);
    setValue("cryoprecipitateDtls", selectedRow && selectedRow["FFP Qty"]);
    setValue("pcv", selectedRow?.PCV === 1 ? true : false);
    setValue("ffpDtls", selectedRow && selectedRow["FFP Qty"]);
    setValue("ffp", selectedRow?.FFP === 1 ? true : false);
    setValue("rdp", selectedRow?.RDP === 1 ? true : false);
    setValue("sdp", selectedRow?.SDP === 1 ? true : false);
    setValue("cryoprecipitate", selectedRow?.CRYOPRECIPITATE === 1 ? true : false);
    setValue("cryoprecipitateDtls", selectedRow?.CRYOPRECIPITATEQty);
    setValue("dignosis", selectedRow && selectedRow["Diagnosis"]);


    setValue(
      "specifyAnyOtherDtls",
      selectedRow && Number(selectedRow["Any Other Text"])
    );
    setValue(
      "specifyAnyOther",
      selectedRow && selectedRow["AnyOther"] === 1 ? true : false
    );
    setValue("consultantName_Reason", selectedRow && selectedRow["Consultant_Reason"]);
    setValue("LocationBloodNeeded", selectedRow && selectedRow["Location"]);
    setValue("rmoName", selectedRow && selectedRow["RMO Name"]);
    //
    setValue("requireBloodFor", selectedRow && selectedRow?.Adult > 0 ? 0 : 1);

  }, [selectedRow]);

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    saveAcceptIssueNote(
      selectedRow?.IssueID,
      location?.state?.menuId,
      privilege
    )
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          methods.reset();
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
                Blood Issue Acceptance Details
              </div>

              <div className=" py-2 grid grid-cols-3">
                {/* patientInfo */}
                <fieldset
                  className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
                >
                  <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Patient Name"]}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">MR. No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.MRNo}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="grid lg:grid-cols-8 md:grid-cols-4 gap-3 mt-3">
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="routine"
                    label="Routine "
                  />
                </div>

                <div className="">
                  <CheckBoxField
                    control={control}
                    name="emergency"
                    label="Emergency"
                  />
                </div>

                <div className="col-span-6 ">
                  <TextField
                    id="outlined-multiline-static"
                    name="dignosis"
                    {...register("dignosis")}
                    label="Dignosis"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={1}
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>

              <p className="my-3 font-bold text-red-500 tracking-wide text-sm font-Poppins">
                If blood has not been reserved pls fill requisition and consent
                alongwith this note.
              </p>

              <hr className="border my-2 md:col-span-4 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300" />

              <div className="flex gap-3 items-center">
                <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                  Required Blood
                </p>
                {/*
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="adult"
                    label="Adult "
                  />
                </div>

                <div className="">
                  <CheckBoxField
                    control={control}
                    name="pediatric"
                    label="Pediatric"
                  />
                  </div>*/}

                <RadioField
                  label=""
                  name="requireBloodFor"
                  control={control}
                  dataArray={RequiredBloodFor}
                />
              </div>

              <div className="gap-3 grid md:grid-cols-3 lg:flex items-center">
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2 ">
                    <CheckBoxField
                      control={control}
                      checkBoxStyle={{ padding: "0px" }}
                      name="wholeBlood"
                      label="Whole Blood "
                    />
                  </div>
                  <div className=" w-14">
                    <CustomInput
                      name="wholeBloodDtls"
                      error={errors.wholeBloodDtls}
                      type="number"
                      variant="outlined"
                      control={control}
                      disabled={!WholeBlood}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2">
                    <CheckBoxField control={control} name="pcv" label="PCV " />
                  </div>
                  <div className="w-14">
                    <CustomInput
                      name="pcvDtls"
                      type="number"
                      error={errors.pcvDtls}
                      variant="outlined"
                      control={control}
                      disabled={!pcv}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2">
                    <CheckBoxField control={control} name="ffp" label="FFP" />
                  </div>
                  <div className="w-14">
                    <CustomInput
                      name="ffpDtls"
                      error={errors.ffpDtls}
                      type="number"
                      variant="outlined"
                      control={control}
                      disabled={!ffp}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2">
                    <CheckBoxField control={control} name="rdp" label="RDP" />
                  </div>
                  <div className="w-14">
                    <CustomInput
                      name="rdpDtls"
                      error={errors.rdpDtls}
                      type="number"
                      variant="outlined"
                      control={control}
                      disabled={!rdp}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2">
                    <CheckBoxField control={control} name="sdp" label="SDP" />
                  </div>
                  <div className="w-14">
                    <CustomInput
                      name="sdpDtls"
                      type="number"
                      variant="outlined"
                      error={errors.sdpDtls}
                      control={control}
                      disabled={!sdp}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2">
                    <CheckBoxField
                      control={control}
                      name="cryoprecipitate"
                      label="CRYOPRECIPITATE"
                    />
                  </div>
                  <div className="w-14">
                    <CustomInput
                      name="cryoprecipitateDtls"
                      error={errors.cryoprecipitateDtls}
                      type="number"
                      variant="outlined"
                      control={control}
                      disabled={!cryoprecipitate}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:flex items-center">
                  <div className="col-span-2">
                    <CheckBoxField
                      control={control}
                      name="specifyAnyOther"
                      label="Any Other"
                    />
                  </div>
                  <div className="w-14">
                    <CustomInput
                      name="specifyAnyOtherDtls"
                      error={errors.specifyAnyOtherDtls}
                      disabled={!specifyAnyOther}
                      type="number"
                      variant="outlined"
                      control={control}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>
              </div>

              <div className="md:col-span-2 lg:col-span-3 mt-3">
                <TextField
                  id="outlined-multiline-static"
                  name="other"
                  {...register("consultantName_Reason")}
                  label="More than one unit : Consultant Name/Reason"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={1}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                  }}
                />
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-8 gap-3 mt-3">
                <div className="md:col-span-1 lg:col-span-3">
                  <InputField
                    name="LocationBloodNeeded"
                    label="Location where blood will be needed"
                    variant="outlined"
                    // error={errors.representativeAddress}
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>

                <div className=" lg:col-span-2 md:col-span-1">
                  <InputField
                    name="rmoName"
                    label="RMO Name"
                    variant="outlined"
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>

                <div className="md:col-span-2 lg:col-span-3 ">
                  <TextField
                    id="outlined-multiline-static"
                    name="other"
                    {...register("dignosis")}
                    // label="Dignosis"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={1}
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>

              <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                For Blood Storage
              </p>
              <div className="grid md:grid-cols-4 lg:grid-cols-6 gap-3 my-3">
                <div className=" ">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="Receiving Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    disabled={true}
                  />
                </div>
                <div className="">
                  <Controller
                    control={control}
                    name="receivingTime"
                    render={({ field: { value, onChange } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          label="Receiving Time"
                          value={value}
                          onChange={onChange}
                          disabled={true}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              // error={errors.startTime}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                style: {
                                  fontSize: "14px",
                                  position: "absolute",
                                  top: "-2px",
                                },
                              }}
                              sx={{
                                svg: {
                                  color: "#1e3a8a",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",

                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    // border: 0,
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",

                                    borderColor: "  ",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <InputField
                    name="receivedBy"
                    label={"Received By"}
                    defaultValue={token?.userName}
                    variant="outlined"
                    control={control}
                    disabled={true}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                <CommonButton
                  label="Proceed"
                  className="bg-customGreen text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
