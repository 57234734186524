import React, { useEffect, useState } from "react";
import InputField from "../../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  addNewWeight,
  getAllDailyWeight,
} from "../../../services/cliinicalChartServices/painAssesmentService/PainAssesmentService";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

export default function DialyWeight(props) {
  const {weightDataResult, setWeightDataResult}=props
  const schema = yup.object().shape({
    weightInKgs: yup
      .string()
      .required("Required")
      .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  });

  const defaultValues = {
    weightInKgs: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  let userInfoString = localStorage.getItem("userInfo");
  let userInfoObj = JSON.parse(userInfoString);
  let addedBy = userInfoObj?.userId;
  let privilege;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [finalData, setFinalData] = React.useState();
  // Weight Table
  // const [weightDataResult, setWeightDataResult] = useState([]);
  //use for confirmation modal
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    console.log(" populateWeightTable()", props.admissionId);
  
    const admissionId = !!props?.admissionId ;
  
    if (!!admissionId) {
      populateWeightTable();
    }
  }, [props?.admissionId]); 
  
  

  const populateWeightTable = () => {
    if (props.displayInfo?.AdmissionId || props?.admissionId) {
      showSpinner(true);

      getAllDailyWeight(props.displayInfo?.AdmissionId || props?.admissionId)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          showSpinner(false);
          setWeightDataResult(res.result);
        })
        .catch((error) => {
          showSpinner(false);
        });
    }
  };

  // [POST SAVE Weight]
  function postWeight(obj) {
    addNewWeight(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateWeightTable(
            props.displayInfo?.AdmissionId || props.admissionId
          );
          reset(defaultValues);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postWeight(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let getpatientAdmId = props.displayInfo?.AdmissionId;
    let getipdAdmissionId = props.admissionId;
    let admissionId;
    if (getpatientAdmId) {
      admissionId = getpatientAdmId;
    } else if (getipdAdmissionId) {
      admissionId = getipdAdmissionId;
    }
    let postObj = {
      addedBy: addedBy,
      admissionId: admissionId,
      weight: data.weightInKgs,
      menuId: props.menuId,
      privilege: privilege,
    };

    setFinalData(postObj);
    setOpenPost(true);
  };

  return (
    <>
      <form
        className="grid grid-cols-1 w-full gap-x-2"
        onSubmit={handleSubmit(onSubmitDataHandler)}
      >
        <div className="">
          <div className="flex items-center lg:justify-end gap-2 pb-2">
            <h1 className="my-1 text-sm font-semibold  whitespace-nowrap">
              Daily Weight
            </h1>
            <div className="flex space-x-4 ">
              <InputField
                type="number"
                name="weightInKgs"
                variant="outlined"
                label="Weight In KG"
                error={errors.weightInKgs}
                disabled={
                  props.displayInfo?.AdmissionId || props.admissionId
                    ? false
                    : true
                }
                control={control}
              />
              {props.patientInformation?.AllowToGO === true ||
              props.selectedPatient?.AllowToGO === true ? null : (
                <>
                  {props.userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <>
                          {props.displayInfo !== null ||
                          props.admissionId !== undefined ? (
                            <CommonButton
                              type="button"
                              className="bg-customGreen text-white"
                              label="Save"
                              onClick={() => {
                                privilege = obj?.action;
                                handleSubmit(onSubmitDataHandler)();
                              }}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>

          {spinner ? (
            <div className="grid justify-center">
              <LoadingSpinner />
            </div>
          ) : null}

          {weightDataResult?.length > 0 && spinner === false ? (
            <CommonDynamicTableNew
              dataResult={weightDataResult}
              tableClass={"rounded h-44"}
              highlightRow={false}
            />
          ) : (
            <h1 className="flex justify-center my-5 font-bold text-gray-600">
              No Records Found...
            </h1>
          )}
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add weight ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
