import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

//http://192.168.0.111:5858/api/feedbackDashboard/getDepartmentWiseStatisticsForDashboard/Consultant

export const getDepartmentWiseStatisticsForDashboard = (datafor) => {
  return apiClient.get(
    `/feedbackDashboard/getDepartmentWiseStatisticsForDashboard/${datafor}`,
    {
      headers: authHeader(),
    }
  );
};
//http://192.168.0.111:5858/api/feedbackDashboard/getPatientStatisticsForDashboard
export const getPatientStatisticsForDashboard = () => {
  return apiClient.get(`/feedbackDashboard/getPatientStatisticsForDashboard`, {
    headers: authHeader(),
  });
};

export const getStaffStatisticsForDashboard = () => {
  return apiClient.get(`/feedbackDashboard/getStaffStatisticsForDashboard`, {
    headers: authHeader(),
  });
};


export const getIpdPatientDateWiseRating = () => {
  return apiClient.get(`/feedbackDashboard/getIpdWiseStatisticsForDashboard`, {
    headers: authHeader(),
  });
};

//getOpdPatientDateWiseAvgRating
export const getOpdPatientDateWiseAvgRating = () => {
    return apiClient.get(`/feedbackDashboard/getOpdWiseStatisticsForDashboard`, {
      headers: authHeader(),
    });
  };