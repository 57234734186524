//Function to patch the incoming patient information data.
function patchPatientInformation(patientInfoObjVal, methods) {
  


  methods.setValue("height", patientInfoObjVal.height);

  methods.setValue("weightInKgs", patientInfoObjVal.weight);

  if (patientInfoObjVal.idBand === 1) {
    methods.setValue("isBandWithCorrectInformation", true);
  } else if (patientInfoObjVal.idBand === 0) {
    methods.setValue("isBandWithCorrectInformation", false);
  }

  methods.setValue("knownAllergies", patientInfoObjVal.knownAllergies);

  methods.setValue("disabilities", patientInfoObjVal.disability);

  methods.setValue("staffName", patientInfoObjVal.receivingStaff);

  let externalEquipments = [];

  if (patientInfoObjVal.dentures === 1) {
    externalEquipments.push("Denutures");
  }

  if (patientInfoObjVal.contactLenses === 1) {
    externalEquipments.push("Contact Lenses");
  }

  if (patientInfoObjVal.artificialLimbs === 1) {
    externalEquipments.push("Artificial Limbs");
  }

  if (patientInfoObjVal.implants === 1) {
    externalEquipments.push("Implants");
  }

  if (patientInfoObjVal.rylesTube === 1) {
    externalEquipments.push("Ryle's Tube");
  }

  if (patientInfoObjVal.gastroJejunomstomy === 1) {
    externalEquipments.push("Gastro Jejunomstomy");
  }

  if (patientInfoObjVal.centralLine === 1) {
    externalEquipments.push("Central Line");
  }

  if (patientInfoObjVal.tracheotomy === 1) {
    externalEquipments.push("Tracheotomy");
  }

  if (patientInfoObjVal.arterialLine === 1) {
    externalEquipments.push("ArterialLine");
  }

  if (patientInfoObjVal.intubates === 1) {
    externalEquipments.push("Intubated");
  }

  if (patientInfoObjVal.fistula === 1) {
    externalEquipments.push("Fistula");
  }

  if (patientInfoObjVal.foleysCatheter === 1) {
    externalEquipments.push("Foleys Catheter");
  }

  methods.setValue("externalEquipments", externalEquipments);
}

export default patchPatientInformation;
