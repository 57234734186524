import React from "react";
export const InfoIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 0C13.9696 0 18 4.03043 18 9C18 13.9696 13.9696 18 9 18C4.03043 18 0 13.9696 0 9C0 4.03043 4.03043 0 9 0ZM10.4083 5.08871C10.5844 4.91262 10.7018 4.65827 10.7018 4.40393C10.7018 4.14958 10.6039 3.89523 10.4083 3.71914C10.2322 3.54306 9.97785 3.42567 9.7235 3.42567C9.46915 3.42567 9.2148 3.52349 9.03872 3.71914C8.86263 3.89523 8.74524 4.14958 8.74524 4.40393C8.74524 4.65827 8.84307 4.91262 9.03872 5.08871C9.2148 5.2648 9.46915 5.38219 9.7235 5.38219C9.97785 5.38219 10.2322 5.28436 10.4083 5.08871ZM8.47133 14.4409C8.31481 14.4409 8.15828 14.3822 8.04089 14.2844C7.84524 14.1278 7.74741 13.8735 7.80611 13.6191L8.7648 8.72784L8.23654 9.02132C7.90393 9.1974 7.49306 9.09958 7.29741 8.76697C7.12133 8.43436 7.21915 8.02349 7.55176 7.82784L9.37133 6.79088C9.60611 6.65393 9.89959 6.67349 10.1148 6.83001C10.33 6.98654 10.4278 7.26045 10.3887 7.5148L9.39089 12.6018L10.2126 12.2496C10.5648 12.0931 10.9561 12.2496 11.1126 12.6018C11.2692 12.9539 11.1126 13.3452 10.7605 13.5018L8.74524 14.3822C8.66698 14.4213 8.56915 14.4409 8.47133 14.4409Z"
        fill="#007EA9"
      />
    </svg>
  );
};