import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CommonButton from "../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import { savePatientEngFinalObj } from "../services/PatientEngagementServices";
import { PatientEngagementContext } from "./PatientEngagement";

export default function ListOfGroupsDetails(props) {
  const [finalData, setFinalData] = React.useState();
  const {
    fnHandleReset,
    setPatientPreDetails,
    setOpdCoordinator,
    preDetailsId,
    patientVisitId,
    getPatientType,
    opdIpdExternal,
    patientPreDetails,
    setSelectedID,
    userActions,
  } = React.useContext(PatientEngagementContext);

  const [openPost, setOpenPost] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [retrievedId, setRetrievedId] = React.useState();
  const [newDate, setNewDate] = React.useState(null);
  const [privilege, setPrivilege] = React.useState("");

  const schema = Yup.object().shape({
    rescheduledDate: Yup.date(),
  });

  const defaultValue = {
    date: "",
  };

  const { handleSubmit, watch, control, reset, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });

  let location = useLocation();

  function onSubmiDataHandler(data) {
    setRetrievedId(data);

    let groupDetails = [];

    for (let propertyName in data) {
      let requiredObj = {};
      if (data[propertyName] === true) {
        let id = propertyName.replace("selectOptions", "");
        requiredObj.groupId = id;

        let dateString = "datePicker" + id;
        if (data[dateString]) {
          let dateVal = format(data[dateString], "yyyy-MM-dd");
          let timeVal = new Date().toLocaleTimeString();

          requiredObj.reScheduleDate = dateVal + "T" + "00:00:00.000Z";
        }

        if (data) {
          requiredObj.groupSelectionStatus = 1;
        } else {
          requiredObj.groupSelectionStatus = 0;
        }
        let requiredIndex = Number(id) - 1;
        if (props.data.result[requiredIndex].PTEngDetailsId) {
          requiredObj.ptEngDetailsId =
            props.data.result[requiredIndex].PTEngDetailsId;
        } else {
          requiredObj.ptEngDetailsId = 0;
        }
        requiredObj.privilege = privilege;
        requiredObj.menuId = location?.state?.menuId;

        groupDetails.push(requiredObj);

        setOpenPost(true);
        // setNewDate(requiredObj.reScheduleDate);
      }
    }

    let finalPatienType;
    if (getPatientType === "Ipd") {
      finalPatienType = 1;
    } else {
      finalPatienType = 0;
    }

    let userId;
    const items = JSON.parse(localStorage.getItem("userInfo"));
    if (items) {
      userId = items.userId;
    }

    let PostObj = {
      menuId: location?.state?.menuId,
      addedBy: userId,
      groupDtlsList: groupDetails,
      opdIpd: finalPatienType,
      patientId: preDetailsId,
      visitId: patientVisitId,
      privilege: privilege,
    };

    if (patientPreDetails && patientPreDetails.length > 0) {
      PostObj.ptEngId = patientPreDetails[0].ID;
    } else {
      PostObj.ptEngId = 0;
    }

    setFinalData(PostObj);
  }

  const handleClosePost = () => {
    setOpenPost(false);
  };

  function postRecord() {
    savePatientEngFinalObj(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setUserInfo(res.result);
        props.PreviousDetails(opdIpdExternal);
        setOpenPost(false);
        reset();
      })
      .catch((error) => {
        errorAlert(error.message || error.response.data.message);
      });

    setOpenPost(false);
    reset();
  }

  React.useEffect(() => {
    reset();
  }, [preDetailsId]);

  React.useEffect(() => {
    for (let obj of props.data.result) {
      if (obj.isChecked === true) {
        setValue(`selectOptions${obj.id}`, true);
        if (new Date(obj.ReScheduleDate) >= new Date()) {
          setValue(`datePicker${obj.id}`, new Date(obj.ReScheduleDate));
        }
      }
    }
  }, [props.savedGroupDetails, props.data]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmiDataHandler)}>
        <div className="grid w-auto">
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <Paper sx={{ width: "100%", my: 1 }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "lightblue",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1E3A8A",
                  },
                }}
                className="rounded lg:h-52 "
              >
                <Table
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    border: 1,
                    borderColor: "#e0e0e0",
                    paddingY: "scroll",
                  }}
                  className="h-72 "
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.1,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      <TableCell style={{ background: "#F1F1F1" }}>
                        <span className="text-gray-600 font-bold whitespace-nowrap">
                          Serial No.
                        </span>
                      </TableCell>
                      <TableCell style={{ background: "#F1F1F1" }}>
                        <span className="text-gray-600 font-bold whitespace-nowrap">
                          Description
                        </span>
                      </TableCell>
                      <TableCell style={{ background: "#F1F1F1" }}>
                        <span className="text-gray-600 font-bold whitespace-nowrap">
                          Select Options
                        </span>
                      </TableCell>
                      <TableCell style={{ background: "#F1F1F1" }}>
                        <span className="text-gray-600 font-bold whitespace-nowrap">
                          Re-scheduled Date
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.data.result.map((row, index) => {
                      return (
                        <TableRow
                          key={index.id}
                          sx={{
                            "& td": {
                              paddingY: 0.3,
                            },
                          }}
                        >
                          <TableCell className="whitespace-nowrap h-5">
                            {row.id}
                          </TableCell>
                          <TableCell className="whitespace-nowrap h-5">
                            {row.value}
                          </TableCell>
                          <TableCell
                            className="whitespace-nowrap h-5"
                            key={index.id}
                          >
                            <CheckBoxField
                              type="checkbox"
                              control={control}
                              name={`selectOptions${row.id}`}
                              value={index}
                            />
                          </TableCell>
                          <TableCell key={index.id}>
                            <div className="w-52">
                              <Controller
                                control={control}
                                name={`datePicker${row.id}`}
                                defaultValue={null}
                                render={({ field: { value } }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      inputFormat="dd/MM/yyyy"
                                      disablePast
                                      value={value}
                                      onChange={(newValue) => {
                                        setValue(
                                          `datePicker${row.id}`,
                                          newValue
                                        );
                                      }}
                                      disabled={
                                        !watch(`selectOptions${row.id}`)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          name="date"
                                          size="small"
                                          sx={{
                                            svg: {
                                              color: "#0B83A5",
                                              height: 22,
                                            },
                                            backgroundColor: "white",
                                            overflow: "visible",

                                            "& .MuiOutlinedInput-root": {
                                              "& .MuiOutlinedInput-input": {
                                                // border: 0,
                                                fontSize: 14,
                                                height: "19px",
                                                overflow: "hidden",
                                              },
                                            },
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </div>
        <div className="flex gap-2 xl:col-span-3 justify-end pr-2 py-2">
          {/* Modal for Add */}

          <CommonButton
            className="px-3 border border-customRed text-customRed rounded"
            type="button"
            label="Reset"
            onClick={() => {
              reset();
              setSelectedID(-1);
              setPatientPreDetails([]);
              setOpdCoordinator("");
              fnHandleReset();
            }}
          />
          {userActions.length > 0
            ? userActions.map((actions) => {
                return !actions.isAction && actions.action === "Create" ? (
                  <CommonButton
                    onClick={() => {
                      setPrivilege(actions.action);
                    }}
                    label="Save"
                    type="submit"
                    className="px-3 text-base font-medium  bg-green-600 text-white rounded"
                  />
                ) : (
                  ""
                );
              })
            : ""}
        </div>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={postRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this Record ?"
          confirmationButtonMsg="Add"
        />
      </form>
    </>
  );
}
