import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../common/components/Buttons/CommonButton";
import DatePickerField from "../../common/components/FormFields/DatePickerField";
import DropdownField from "../../common/components/FormFields/DropdownField";
import RadioField from "../../common/components/FormFields/RadioField";
import { fetchUserActionsByMenuId } from "../../commonServices/functionalityServices/FunctionalityServices";
import {
  getGroupArr,
  getPTEorderDetailsList,
  getPTEorderSearchList,
  getPreviousDocuments,
} from "../services/PatientEngagementServices";
import PatientFollowupDetailsTable from "./PatientFollowupDetailsTable";

export default function PatientOrderManagementDetails() {
  const [pteOrderList, setPteOrderList] = React.useState([]);
  const [inputSearchArr, setInputSearchArr] = React.useState([]);
  const [searchString, setSearchstring] = React.useState("");
  const [selectedCurrentDate, setSelectedCurrentDate] = React.useState(
    new Date()
  );
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userActions, setUserActions] = React.useState([]);

  let location = useLocation();

  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  const schema = yup.object().shape({});
  const defaultValues = {
    patienttype: "Opd",
    patientStatus: "Pending",
    group: null,
    inputSearch: null,
    searchBar: "",
    currentDate: new Date(),
  };
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  let getPatientType = watch("patienttype");

  let getPatientStatus = watch("patientStatus");

  let groupWatch = watch("group");
  const patientType = [
    {
      id: "Opd",
      value: "Opd",
      label: "OPD",
    },
    {
      id: "Ipd",
      value: "Ipd",
      label: "IPD",
    },
  ];

  const patientStatus = [
    {
      id: "Pending",
      label: "Pending",
      value: "Pending",
    },
    {
      id: "Completed",
      label: "Completed",
      value: "Completed",
    },
    {
      id: "InComplete",
      label: "InComplete",
      value: "InComplete",
    },
    {
      id: "Rejected",
      label: "Rejected",
      value: "Rejected",
    },
    {
      id: "Appointment",
      label: "Appointment",
      value: "Appointment",
    },
  ];

  const [groupNameArr, setGroupNameArr] = React.useState([]);

  React.useEffect(() => {
    getGroupNames();
  }, []);

  const getGroupNames = (obj) => {
    getGroupArr(obj)
      .then((response) => response.data)
      .then((res) => {
        setGroupNameArr(res.result);
      });
  };

  React.useEffect(() => {
    populateTable();
  }, []);

  let searchObjTwo = {
    searchString: "",
    type: getPatientType,
    status: getPatientStatus,
    groupId: 0,
    currentDate: new Date(),
    page: page,
    size: rowsPerPage,
  };

  function populateTable() {
    getPTEorderDetailsList(searchObjTwo)
      .then((response) => response.data)
      .then((res) => {
        setCount(res.count);
        if (res.result.length > 0) {
          let temp = res.result;

          let array = [];
          for (let obj of temp) {
            let object = {};
            object.ID = obj.ID;
            object.MRNo = obj.MRNo;
            object.PatientName = obj.PatientName;
            object.ContactNo = obj.ContactNo;
            object.Description = obj.Description;
            object.ReScheduleDate = obj.ReScheduleDate;
            object.PTEngId = obj.PTEngId;

            getPreviousDocuments(obj.PTEngId)
              .then((response) => response.data)
              .then((res) => {
                object.documentSource = res.result;
                array.push(object);
                setPteOrderList({ statusCode: 200, result: array });
              });
          }
        } else {
          setPteOrderList({ statusCode: 200, result: [] });
        }
      });
  }

  const handleChange = (string) => {
    setSearchstring(string.target.value);
  };

  const handleSearch = () => {
    let searchObj = {
      searchString: searchString,
      type: getPatientType,
      status: getPatientStatus,
      currentDate: dateValue,
      page: page,
      size: rowsPerPage,
    };
    if (groupWatch !== null) {
      searchObj.groupId = groupWatch?.id;
    } else {
      searchObj.groupId = 0;
    }
    getPTEorderSearchList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.result?.length > 0) {
          let temp = res.result;
          let array = [];
          for (let obj of temp) {
            let object = {};
            object.ID = obj.ID;
            object.MRNo = obj.MRNo;
            object.PatientName = obj.PatientName;
            object.ContactNo = obj.ContactNo;
            object.Description = obj.Description;
            object.ReScheduleDate = obj.ReScheduleDate;
            object.PTEngId = obj.PTEngId;

            getPreviousDocuments(obj?.PTEngId)
              .then((response) => response.data)
              .then((res) => {
                object.documentSource = res.result;
                array.push(object);
                setPteOrderList({ statusCode: 200, result: array });
              });
          }
        } else {
          setPteOrderList({ statusCode: 200, result: [] });
        }
      });
  };

  let dateValue = watch("currentDate");

  return (
    <>
      <div className="mt-16 p-1">
        <div className="text-center text-slate-900 font-bold text-xl ">
          Patient Engagement Order Details
        </div>
        <fieldset className="border border-gray-300 col-span-3 md:col-span-1 w-full md:py-0  text-left lg:px-2 rounded mt-5">
          <div className="lg:grid lg:grid-cols-4 grid grid-cols-2  px-2 gap-2 py-2 ">
            <div className="z-50 ">
              <TextField
                sx={{ width: "100%" }}
                className="z-80 "
                name="searchBar"
                {...register("searchBar")}
                placeholder="Search By Patient Name"
                size="small"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="z-50 ">
              <DropdownField
                name="group"
                control={control}
                placeholder="Select Group"
                dataArray={groupNameArr}
                isSearchable={true}
                searchIcon={true}
              />
            </div>
            <div className="flex flex-nowrap  justify-center py-2 md:h-auto">
              <div className="flex gap-2 justify-center text-md">
                <div className="  font-semibold  whitespace-nowrap">
                  <label>Patient Type &nbsp;:&nbsp;</label>
                </div>

                <div className="flex  md:h-auto  ">
                  <RadioField
                    label=""
                    name="patienttype"
                    control={control}
                    dataArray={patientType}
                  />
                </div>
              </div>
            </div>

            <div>
              <DatePickerField
                control={control}
                name="currentDate"
                label="Current Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("currentDate", newValue);
                  setSelectedCurrentDate(newValue);
                }}
                disableFuture={true}
                inputFormat="DD-MM-YYYY"
              />
            </div>
          </div>
          <div className="flex  gap-2">
            <div className="  items-center ">
              <div className="flex gap-2 px-2">
                <div className=" py-2 font-bold text-md whitespace-nowrap ">
                  <label>Status &nbsp;:&nbsp;</label>
                </div>

                <div className="flex py-2 md:h-auto ">
                  <RadioField
                    label=""
                    name="patientStatus"
                    control={control}
                    dataArray={patientStatus}
                  />
                </div>
              </div>
            </div>
            <div className=" flex lg:justify-start justify-end items-center  gap-3 px-2 pb-2">
              <CommonButton
                className="h-9 px-3  bg-blue-900  text-white rounded text-sm font-medium"
                type="button"
                label="Apply"
                onClick={handleSearch}
              />
              <CommonButton
                className="h-9 px-3 border border-customRed text-customRed rounded text-base font-medium"
                type="button"
                onClick={() => {
                  reset(defaultValues);
                }}
                label="Reset"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div className="pt-4">
        <Divider color="#63b7d3" sx={{ height: 1 }} />
      </div>
      <div className="py-4">
        <p className="text-lg font-bold tracking-wide">
          Patient Followup Details
        </p>

        {pteOrderList.hasOwnProperty("result") &&
        pteOrderList.result.length > 0 &&
        pteOrderList.statusCode === 200 ? (
          <PatientFollowupDetailsTable
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            tableApiFunc={populateTable}
            data={pteOrderList}
            setPteOrderList={setPteOrderList}
            handleSearch={handleSearch}
            populateTable={populateTable}
            userActions={userActions}
          />
        ) : (
          <div classNamer=" ">
            <span className=" flex justify-center font-semibold text-gray-400 my-5 text-lg">
              No Records Found..!
            </span>
          </div>
        )}
      </div>
    </>
  );
}
