import React from "react";

import { useEffect } from "react";
import { useRef } from "react";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";
import { getPhysioAssessmentPrint } from "../services/PhysioGeneralAssessmentServices.js";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";

export default function PhysiotherapyGenealAssessment(props) {
  const printRef = useRef(null);
  const [dataResult, setDataResult] = React.useState();
  const [tableData, setTableData] = React.useState();

  React.useEffect(() => {
    getPhysioAssessmentPrint(props.physioAssessmentId)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
        setTableData(res.result.TestNames);
      });
  }, []);

  let CheckTrueOne;

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  if (dataResult?.Type1 === 1) {
    CheckTrueOne = true;
  } else {
    CheckTrueOne = false;
  }

  let CheckTrueTwo;

  if (dataResult?.Type2 === 1) {
    CheckTrueTwo = true;
  } else {
    CheckTrueTwo = false;
  }
  let PatternOne;

  if (dataResult?.Pattern1 === 1) {
    PatternOne = true;
  } else {
    PatternOne = false;
  }
  let PatternTwo;

  if (dataResult?.Pattern2 === 1) {
    PatternTwo = true;
  } else {
    PatternTwo = false;
  }

  let nasCheckListOne;
  let nasCheckListTwo;
  let nasCheckListThree;
  let nasCheckListFour;
  let nasCheckListFaive;
  let nasCheckListSix;
  let nasCheckList7;
  let nasCheckList8;
  let nasCheckList9;
  let nasCheckList10;

  if (dataResult?.NAS === 1) {
    nasCheckListOne = true;
  } else {
    nasCheckListOne = false;
  }
  if (dataResult?.NAS === 2) {
    nasCheckListTwo = true;
  } else {
    nasCheckListTwo = false;
  }
  if (dataResult?.NAS === 3) {
    nasCheckListThree = true;
  } else {
    nasCheckListThree = false;
  }
  if (dataResult?.NAS === 4) {
    nasCheckListFour = true;
  } else {
    nasCheckListFour = false;
  }
  if (dataResult?.NAS === 5) {
    nasCheckListFaive = true;
  } else {
    nasCheckListFaive = false;
  }
  if (dataResult?.NAS === 6) {
    nasCheckListSix = true;
  } else {
    nasCheckListSix = false;
  }
  if (dataResult?.NAS === 7) {
    nasCheckList7 = true;
  } else {
    nasCheckList7 = false;
  }
  if (dataResult?.NAS === 8) {
    nasCheckList8 = true;
  } else {
    nasCheckList8 = false;
  }
  if (dataResult?.NAS === 9) {
    nasCheckList9 = true;
  } else {
    nasCheckList9 = false;
  }
  if (dataResult?.NAS === 10) {
    nasCheckList10 = true;
  } else {
    nasCheckList10 = false;
  }

  let DiabetesCheck;
  if (dataResult?.Diabetes === 1) {
    DiabetesCheck = true;
  } else {
    DiabetesCheck = false;
  }

  let BPCheck;
  if (dataResult?.BP === 1) {
    BPCheck = true;
  } else {
    BPCheck = false;
  }

  let IHDCheck;
  if (dataResult?.IHD === 1) {
    IHDCheck = true;
  } else {
    IHDCheck = false;
  }

  let copdCheck;
  if (dataResult?.COPD === 1) {
    copdCheck = true;
  } else {
    copdCheck = false;
  }

  let CheckCopd;
  if (dataResult?.COPD === 1) {
    CheckCopd = true;
  } else {
    CheckCopd = false;
  }

  let MalignancyCheck;
  if (dataResult?.Malignancy === 1) {
    MalignancyCheck = true;
  } else {
    MalignancyCheck = false;
  }

  let DeformityPositive;
  let DeformityNegative;
  if (dataResult?.Deformity === 1) {
    DeformityPositive = true;
    DeformityNegative = false;
  } else {
    DeformityPositive = false;
    DeformityNegative = true;
  }

  let SwellingPositive;
  let SwellingNegative;
  if (dataResult?.Swelling === 1) {
    SwellingPositive = true;
    SwellingNegative = false;
  } else {
    SwellingPositive = false;
    SwellingNegative = true;
  }

  //
  let ScarPositive;
  let ScarNegative;
  if (dataResult?.Swelling === 1) {
    ScarPositive = true;
    ScarNegative = false;
  } else {
    ScarPositive = false;
    ScarNegative = true;
  }
  //

  let ErythmaPositive;
  let ErythmaNegative;
  if (dataResult?.Erythma === 1) {
    ErythmaPositive = true;
    ErythmaNegative = false;
  } else {
    ErythmaPositive = false;
    ErythmaNegative = true;
  }

  //
  let WoundPositive;
  let WoundNegative;
  if (dataResult?.Wound === 1) {
    WoundPositive = true;
    WoundNegative = false;
  } else {
    WoundPositive = false;
    WoundNegative = true;
  }

  let LyingOne;
  if (dataResult?.Lying1 === 1) {
    LyingOne = true;
  } else {
    LyingOne = false;
  }

  let LyingTwo;
  if (dataResult?.Lying2 === 1) {
    LyingTwo = true;
  } else {
    LyingTwo = false;
  }

  let LyingThree;
  if (dataResult?.Lying3 === 1) {
    LyingThree = true;
  } else {
    LyingThree = false;
  }

  let LyingFore;
  if (dataResult?.Lying4 === 1) {
    LyingFore = true;
  } else {
    LyingFore = false;
  }

  let SittingOne;
  if (dataResult?.Sitting1 === 1) {
    SittingOne = true;
  } else {
    SittingOne = false;
  }
  let SittingTwo;
  if (dataResult?.Sitting2 === 1) {
    SittingTwo = true;
  } else {
    SittingTwo = false;
  }
  let SittingThree;
  if (dataResult?.Sitting3 === 1) {
    SittingThree = true;
  } else {
    SittingThree = false;
  }
  let Sittingfour;
  if (dataResult?.Sitting4 === 1) {
    Sittingfour = true;
  } else {
    Sittingfour = false;
  }

  let WalkingOne;
  if (dataResult?.Walking1 === 1) {
    WalkingOne = true;
  } else {
    WalkingOne = false;
  }
  let WalkingTwo;
  if (dataResult?.Walking2 === 1) {
    WalkingTwo = true;
  } else {
    WalkingTwo = false;
  }

  let WalkingThree;
  if (dataResult?.Walking3 === 1) {
    WalkingThree = true;
  } else {
    WalkingThree = false;
  }
  let WalkingFour;
  if (dataResult?.Walking4 === 1) {
    WalkingFour = true;
  } else {
    WalkingFour = false;
  }
  let StandingOne;
  if (dataResult?.Standing1 === 1) {
    StandingOne = true;
  } else {
    StandingOne = false;
  }
  let StandingTwo;
  if (dataResult?.Standing2 === 1) {
    StandingTwo = true;
  } else {
    StandingTwo = false;
  }
  let StandingThree;
  if (dataResult?.Standing3 === 1) {
    StandingThree = true;
  } else {
    StandingThree = false;
  }

  let StandingFour;
  if (dataResult?.Standing4 === 1) {
    StandingFour = true;
  } else {
    StandingFour = false;
  }

  return (
    <table className="w-full" ref={printRef}>
      <thead
        className=" w-full"
        style={{
          "-fs-table-paginate": "paginate",
        }}
      >
        <div className=" text-center  ">
          <div className=" flex justify-end">
            <InamdarHospiLogo />
          </div>
          <div className=" -mt-5">
            <h1 className=" font-semibold text-[13px]">
              PHYSIOTHERAPY GENERAL ASSESSMENT
            </h1>
          </div>
        </div>
        <div className=" p-1 border-t-[1px] border-b-[1px] border-black grid grid-cols-[15%_5%_30%_15%_5%_30%] text-[11px] ">
          <div className=" font-semibold">MRNO</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.MRNo}</div>
          <div className=" font-semibold">Patient Name</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.PatientName}</div>
          <div className=" font-semibold">Age</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.AgeYear}</div>
        </div>
      </thead>
      <tbody className="w-full">
        <div className=" mt-2 p-1">
          <div className=" border-[1px] w-32 my-1 p-[1px] text-[11px] font-semibold">
            CHIEF COMPLAINTS
          </div>
          <div className=" ml-5 text-[11px]">{dataResult?.CheifComplaints}</div>
        </div>
        <div className=" mt-2 p-1">
          <div className=" border-[1px] w-28 my-1 p-[1px] text-[11px] font-semibold">
            PAIN ASSESSMENT
          </div>
          <div className=" ml-[18px] text-[11px]">
            {dataResult?.PainAssesment}
          </div>
        </div>
        <div className=" space-y-3">
          <div className=" items-center ml-[23px] text-[11px] flex space-x-20">
            <div className=" font-semibold">Type</div>
            <div className=" space-x-3">
              <input type="checkbox" checked={CheckTrueOne} />
              <span>Internittent</span>
            </div>
            <div className=" space-x-3">
              <input type="checkbox" checked={CheckTrueTwo} />
              <span>Continuous</span>
            </div>
          </div>
          <div className=" ml-[23px] text-[11px] flex space-x-16">
            <div className=" font-semibold"> Pattern</div>
            <div className=" space-x-3">
              <input type="checkbox" checked={PatternOne} />
              <span>Localized</span>
            </div>
            <div className=" space-x-3">
              <input type="checkbox" checked={PatternTwo} />
              <span>Radiating</span>
            </div>
          </div>
        </div>
        <div className=" mt-2 p-1">
          <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
            NAS
          </div>
          <div className=" flex items-center ml-36">
            <p>1</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>2</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>3</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>4</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>5</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>6</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>7</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>8</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>9</p>
            <p className=" border-b-[1px] border-black w-10 " />
            <p>10</p>
          </div>
          <div className=" ml-36 flex items-center space-x-2 p-1">
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckListOne} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckListTwo} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckListThree} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckListFour} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckListFaive} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckListSix} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckList7} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckList8} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckList9} />
            </div>
            <div className=" p-[1px] w-10">
              <input type="checkbox" checked={nasCheckList10} />
            </div>
          </div>
          <div className=" text-[11px]">
            <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
              MEDICAL HISTORY
            </div>
            <div>
              <h1 className=" my-2">{dataResult?.MedicalHistory}</h1>
              <div className=" grid grid-cols-5">
                <div className=" flex space-x-2">
                  <input type="checkbox" checked={DiabetesCheck} />
                  <h1>Diabetes</h1>
                </div>
                <div className=" flex space-x-2">
                  <input type="checkbox" checked={BPCheck} />
                  <h1>BP</h1>
                </div>
                <div className=" flex space-x-2">
                  <input type="checkbox" checked={IHDCheck} />
                  <h1>IHD</h1>
                </div>
                <div className=" flex space-x-2">
                  <input type="checkbox" checked={copdCheck} />
                  <h1>COPD</h1>
                </div>
                <div className=" flex space-x-2">
                  <input type="checkbox" checked={MalignancyCheck} />
                  <h1>Malignancy</h1>
                </div>
              </div>
              <div className=" my-2">
                <div className=" border-[1px] w-32 my-2 p-[1px] text-[11px] font-semibold">
                  SURGICAL HISTORY
                </div>
                <div className=" text-[11px]">
                  <h1 className=" ml-5 text-[11px] font-semibold">Implant</h1>
                  <p className="ml-10">{dataResult?.Implant}</p>
                </div>
                <div className=" text-[11px]">
                  <h1 className=" ml-5 text-[11px] font-semibold">Fracture</h1>
                  <p className="ml-10">{dataResult?.Fracture}</p>
                </div>
                <div className=" text-[11px]">
                  <h1 className=" ml-5 text-[11px] font-semibold">Fall</h1>
                  <p className="ml-10">{dataResult?.Fall}</p>
                </div>
                <div className=" text-[11px]">
                  <h1 className=" ml-5 text-[11px] font-semibold">Others</h1>
                  <p className="ml-10">{dataResult?.Others}</p>
                </div>
              </div>
              <div>
                <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
                  ON OBSERVATION
                </div>
                <div className=" grid grid-cols-3">
                  <div className=" space-y-3">
                    <div>Deformity</div>
                    <div>Swelling</div>
                    <div>Scar</div>
                    <div>Erythma</div>
                    <div>Wound</div>
                  </div>
                  <div className=" space-y-3">
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={DeformityPositive} />
                      <p>Positive</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={SwellingPositive} />
                      <p>Positive</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={ScarPositive} />
                      <p>Positive</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={ErythmaPositive} />
                      <p>Positive</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={WoundPositive} />
                      <p>Positive</p>
                    </div>
                  </div>
                  <div className=" space-y-3">
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={DeformityNegative} />
                      <p>Negative</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={SwellingNegative} />
                      <p>Negative</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={ScarNegative} />
                      <p>Negative</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={ErythmaNegative} />
                      <p>Negative</p>
                    </div>
                    <div className=" flex items-center space-x-3">
                      <input type="checkbox" checked={WoundNegative} />
                      <p>Negative</p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
                    REMARK
                  </div>
                  <div className=" ml-10">{dataResult?.ObservationRemark}</div>
                </div>
                <div>
                  <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
                    ON PALPATION
                  </div>
                  <div className=" my-2">
                    <div className=" font-semibold text-[11px] ml-6">
                      Tenderness
                    </div>
                    <div className=" font-normal text-[11px] ml-10">
                      {dataResult?.Tenderness}
                    </div>
                  </div>
                  <div className="my-2">
                    <div className=" font-semibold text-[11px] ml-6">Spasm</div>
                    <div className=" font-normal text-[11px] ml-10">
                      {dataResult?.Spasm}
                    </div>
                  </div>
                  <div className="my-2">
                    <div className=" font-semibold text-[11px] ml-6">
                      Trigger
                    </div>
                    <div className=" font-normal text-[11px] ml-10">
                      {dataResult?.Trigger}
                    </div>
                  </div>
                </div>

                <div className=" ml-6 flex space-x-10 my-2">
                  <div className=" font-semibold">Limb Length</div>
                  <div className=" space-x-3 flex">
                    <div className=" font-semibold">Right</div>
                    <div>{dataResult?.["Limb_Right"]}</div>
                  </div>
                  <div className=" space-x-3 flex">
                    <div className=" font-semibold">Left</div>
                    <div>{dataResult?.["Limb_Left"]}</div>
                  </div>
                </div>
                <div className=" mt-5">
                  <div className=" border-[1px] w-28  p-[1px] text-[11px] font-semibold">
                    REMARK
                  </div>
                  <div className=" ml-10">{dataResult?.PalpationRemark}</div>
                </div>
                <div className=" my-3">
                  <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
                    ON EXAMINATION
                  </div>
                  <div className=" flex space-x-5">
                    <div className=" font-semibold  ml-10">Joint Name</div>
                    <div className=" font-normal">{dataResult?.JointName}</div>
                  </div>
                </div>

                <div className=" grid grid-cols-6 ">
                  <div className=" border-[1px] border-black">
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Joint
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Sensationa
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      ROM
                    </div>
                    <div className=" font-semibold p-[1px] ">MMT</div>
                  </div>
                  <div className=" border-b-[1px] border-t-[1px] border-r-[1px] border-black">
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Flexion/ Flexor
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.Sensations1}
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.ROM1}
                    </div>
                    <div className=" font-semibold p-[1px] ">
                      {dataResult?.MMT1}
                    </div>
                  </div>
                  <div className=" border-b-[1px] border-t-[1px] border-r-[1px] border-black">
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Extension/ Extensor
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.Sensations2}
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.ROM2}
                    </div>
                    <div className=" font-semibold p-[1px] ">
                      {dataResult?.MMT2}
                    </div>
                  </div>
                  <div className=" border-b-[1px] border-t-[1px] border-r-[1px] border-black">
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Abduction/Abductors
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.Sensations3}
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.ROM3}
                    </div>
                    <div className=" font-semibold p-[1px] ">
                      {dataResult?.MMT3}
                    </div>
                  </div>
                  <div className=" border-b-[1px] border-t-[1px] border-r-[1px] border-black">
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Addution/ Adductors
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.Sensations4}
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.ROM4}
                    </div>
                    <div className=" font-semibold p-[1px] ">
                      {dataResult?.MMT4}
                    </div>
                  </div>
                  <div className=" border-b-[1px] border-t-[1px] border-r-[1px] border-black">
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      Other
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.Sensations5}
                    </div>
                    <div className=" font-semibold p-[1px] border-b-[1px] border-black">
                      {dataResult?.ROM5}
                    </div>
                    <div className=" font-semibold p-[1px] ">
                      {dataResult?.MMT5}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" text-[11px] my-3">
          <div className=" font-semibold p-1">Tightness</div>
          <div className=" ml-6">{dataResult?.Tightness}</div>
          <div className=" font-semibold p-1">Contracture</div>
          <div className=" ml-6">{dataResult?.Contracture}</div>
          <div className=" font-semibold p-1">Remarks</div>
          <div className=" ml-6">{dataResult?.OnExaminationRemark}</div>
        </div>

        <div>
          <div className=" border-[1px] w-32 my-2 p-[1px] text-[11px] font-semibold">
            SPECIAL TEST, IF ANY
          </div>
        </div>
        <div className=" pl-1 pr-1">
          <div className=" border-[1px] text-[11px] font-semibold border-black grid grid-cols-[80%_10%_10%]">
            <div className=" border-r-[1px] border-black pl-1">TestName</div>
            <div className=" border-r-[1px] border-black pl-1">Positive</div>
            <div className="pl-1">Negative</div>
          </div>
        </div>
        <div className=" pl-1 pr-1">
          {tableData?.map((data) => {
            let ResultCheckBox;
            let Resultcheck;

            if (data?.Result === 1) {
              ResultCheckBox = true;
            }
            if (data?.Result === 0) {
              Resultcheck = true;
            }

            return (
              <div className=" text-[11px] border-b-[1px] border-r-[1px] border-l-[1px] border-black grid grid-cols-[80%_10%_10%]">
                <div className=" border-r-[1px] border-black pl-1">
                  {data?.TestName}
                </div>
                <div className="pl-1 border-r-[1px] border-black">
                  <input type="checkbox" checked={ResultCheckBox} />
                </div>
                <div className="pl-1 ">
                  <input type="checkbox" checked={Resultcheck} />
                </div>
              </div>
            );
          })}
        </div>

        <div className=" text-[11px]">
          <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
            REMARK
          </div>
          <div className=" pl-6">{dataResult?.TestRemark}</div>
        </div>
        <div className=" p-1 border-b-[1px] border-black">
          <div className=" grid grid-cols-5 text-[11px] border-[1px] border-black">
            <div>
              <div className=" border-b-[1px]  border-r-[1px] border-black">
                &#8209;{" "}
              </div>
              <div className=" border-b-[1px] border-black p-[4.5px] font-semibold">
                Lying
              </div>
              <div className=" border-b-[1px] border-black p-[4.5px] font-semibold">
                Sitting
              </div>
              <div className=" border-b-[1px] border-black p-[4.5px] font-semibold">
                Standing
              </div>
              <div className="  p-1 font-semibold">Walking</div>
            </div>
            <div>
              <div className=" border-b-[1px] border-r-[1px] text-center border-black font-semibold">
                Independent
              </div>
              <div className="border-b-[1px]  text-center  border-black border-l-[1px] p-1">
                <input type="checkbox" checked={LyingOne} />
              </div>
              <div className="border-b-[1px]  text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={SittingOne} />
              </div>
              <div className="border-b-[1px]  text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={StandingOne} />
              </div>
              <div className=" border-black  text-center border-l-[1px] p-1">
                <input type="checkbox" checked={WalkingOne} />
              </div>
            </div>
            <div>
              <div className="border-b-[1px] border-r-[1px] text-center border-black font-semibold">
                Dependent
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={LyingTwo} />
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={SittingTwo} />
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={StandingTwo} />
              </div>
              <div className=" border-black text-center border-l-[1px] p-1">
                <input type="checkbox" checked={WalkingTwo} />
              </div>
            </div>
            <div>
              <div className=" border-b-[1px] border-r-[1px] text-center border-black font-semibold">
                With aid
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={LyingThree} />
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={SittingThree} />
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={StandingThree} />
              </div>
              <div className=" border-black text-center border-l-[1px] p-1">
                <input type="checkbox" checked={WalkingThree} />
              </div>
            </div>
            <div>
              <div className="border-b-[1px] text-center border-black font-semibold">
                Withoutaid
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={LyingFore} />
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={Sittingfour} />
              </div>
              <div className="border-b-[1px] text-center border-black border-l-[1px] p-1">
                <input type="checkbox" checked={StandingFour} />
              </div>
              <div className=" border-l-[1px] text-center border-black p-1">
                <input type="checkbox" checked={WalkingFour} />
              </div>
            </div>
          </div>
          <div className=" mt-2 p-1">
            <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
              REMARKS
            </div>
            <div className=" text-[11px] pl-6">
              {dataResult?.FunctionalRemark}
            </div>
          </div>
          <div className=" mt-1 p-1">
            <div className=" border-[1px] w-28 my-2 p-[1px] text-[11px] font-semibold">
              INVESTIGATIONS
            </div>
            <div>
              <h1 className=" font-semibold text-[11px]">X-Ray</h1>
              <p className=" text-[11px] ml-7">{dataResult?.["X_Ray"]}</p>
            </div>
            <div>
              <h1 className=" font-semibold text-[11px]">MRI</h1>
              <p className=" text-[11px] ml-7">{dataResult?.MRI}</p>
            </div>
            <div>
              <h1 className=" font-semibold text-[11px]">Others</h1>
              <p className=" text-[11px] ml-7">{dataResult?.Other}</p>
            </div>
            <div>
              <h1 className=" font-semibold text-[11px]">Condition</h1>
              <p className=" text-[11px] ml-7">{dataResult?.Condition}</p>
            </div>
            <div>
              <h1 className=" font-semibold text-[11px]">Treatment plan</h1>
              <p className=" text-[11px] ml-7">{dataResult?.TreatmentPlan}</p>
            </div>
          </div>
        </div>
        <div className=" text-[11px] text-end">
          <div className=" font-semibold">Added By</div>
          <div>Shinde Nilesh</div>
        </div>
      </tbody>
    </table>
  );
}
