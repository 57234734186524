import * as React from "react";
// import View from "../../nursingsupervisorreport/nursingSupervisor/View";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { searchPatientInformation } from "../../../../billing/services/BillingServices";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { fetchFloor } from "../../../../commonServices/miscellaneousServices/MiscServices";
import NoApiCommonMasterTable from "./NursingReportTable";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { nursingTakeOverDeatilsPatientByAdmissionId } from "../../../services/nursingReportServices/nursingTakeOverDetailsServices";
import ViewModal from "./ViewModal";

const actions = ["Checkbox"];
// start component
export default function NursingTakeOverDetails() {
  // useFrom Destructuring
  const schema = yup.object().shape({});
  const defaultValues = {
    floor: null,
    nursingTakeOverDetails: null,
    nursingNightReportSearrch: "",
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    register,
    reset,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variable

  const [open, setOpen] = React.useState(false);
  const [floorDropdown, setFloorDropdown] = React.useState([]);
  const [selectedFloorId, setSelectedFloorId] = React.useState(null);
  const [searchString, setSearchString] = React.useState([]);
  const [
    patientInfoByAdmissionId,
    setPatientInfoByAdmissionId,
  ] = React.useState();
  const [userActions, setUserActions] = React.useState([]);
  const [nursingReportList, setNursingReportList] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [noRecordFound, setNoRecordFound] = React.useState(false);
  // other variableslet
  let floorVal = watch("floor");
  
  let location = useLocation();
  
  let loginObj = JSON.parse(localStorage.getItem("userInfo"));
 

  React.useEffect(() => {
    if (loginObj?.floorId !== null) {
      fetchAllNursingNightReportList();
    }
  }, [floorVal]);

  // Add floorVal as a dependency in useEffect

  React.useEffect(() => {
    fetchAllNursingNightReportList();
  }, [floorVal]); // Call the function when floorVal changes

  // useEffects
  React.useEffect(() => {
    if(floorVal!==undefined)
    fetchFloor()
      .then((response) => response.data)
      .then((res) => {
        setFloorDropdown(res.result);
        
        setValue("floor", {
          id: loginObj?.floorId,
          value: loginObj?.floorId,
          label: loginObj?.floor,
        });
      });
  }, []);

  React.useEffect(() => {
    setSelectedFloorId(floorDropdown && floorDropdown[2]?.id);
  }, [floorDropdown]);

  React.useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          
          setUserActions(res.result);
        });
  }, []);
  // functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // search patient

  const handleChange = (searchString) => {
    let opdIpd = 1;
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString))
      searchPatientInformation(searchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          if (res !== null) {
            setSearchString(res.result);
          } else {
            setSearchString(null);
          }
        });
  };
  // select searched patient
  function fetchAllNursingNightReportList() {
    setShowSpinner(true);
    setNoRecordFound(false);
    let admissionIdVal = patientInfoByAdmissionId
      ? patientInfoByAdmissionId
      : 0;
    if (floorVal?.id !== null && floorVal?.id !== undefined) {
      nursingTakeOverDeatilsPatientByAdmissionId(admissionIdVal, floorVal?.id)
        .then((response) => response.data)
        .then((res) => {
          setShowSpinner(false);
          setNursingReportList(res.result);
        })
        .catch((error) => {
          // errorAlert(error.message);
          
          setShowSpinner(false);
          setNoRecordFound(true);
        });
    }
  }

  return (
    <div className="mt-12  px-6">
      <div className="">
        <div className="text-center text-black font-bold text-xl ">
          Nursing Night Report
        </div>
      </div>
      <Box className="w-full grid py-2 md:rounded-md">
        <div className="w-full items-center">
          <div>
            <div className="flex justify-between gap-2">
              <div className="lg:flex lg:w-2/3 md:flex md:w-full gap-2">
                <div className="w-1/2">
                  <DropdownField
                    control={control}
                    name="floor"
                    label="Floor"
                    placeholder="Floor"
                    dataArray={floorDropdown}
                  />
                </div>
                <div className=" lg:w-full md:w-full">
                  <SearchDropdown
                    control={control}
                    name="nursingNightReportSearrch"
                    placeholder="Search By Patient Name / MR No./ Mobile No"
                    dataArray={searchString}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("nursingNightReportSearrch", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            
                          
                            setPatientInfoByAdmissionId(
                              e?.target?.value?.AdmissionId
                            );
                          } else {
                            setPatientInfoByAdmissionId(null);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="flex gap-2">
                  <CommonButton
                    searchIcon
                    className="bg-customBlue text-white"
                    onClick={() => {
                      fetchAllNursingNightReportList();
                    }}
                  />
                  {/*<CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                    }}
                  />*/}
                </div>
              </div>
              <div className=" flex justify-end">
                {userActions &&
                  userActions.map((actions, i) => (
                    <>
                      {actions.isAction === false ? (
                        <>
                          <div className="flex gap-2 cursor-pointer">
                            {actions.action === "View" ? (
                              <>
                                <CommonButton
                                  label="View"
                                  className="bg-customBlue text-white"
                                  onClick={() => {
                                    
                                    handleOpen();
                                  }}
                                />
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </>
                  ))}
              </div>
            </div>
            <div className="w-full py-4">
              <div className="grid grid-cols-3 gap-2 w-full">
                <div className="col-span-3 w-full ">
                  {/* {nursingReportList.length > 0 ? <CommonDynamicTable
                  data={nursingReportList}
                  t
                  />:null} */}
                  {showSpinner ? (
                    <div className="grid justify-center items-center pt-14">
                      <LoadingSpinner />
                    </div>
                  ) : null}
                  {nursingReportList.length > 0 && showSpinner === false ? (
                    <NoApiCommonMasterTable
                      data={nursingReportList}
                      setData={setNursingReportList}
                      actions={actions}
                      floorVal={floorVal}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                    />
                  ) : null}
                  {noRecordFound === true && showSpinner === false ? (
                    <div className="text-center pt-10">
                      {" "}
                      <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                        No Records Found...
                      </h3>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewModal
          open={open}
          handleOpen={handleOpen}
          setOpen={setOpen}
          handleClose={handleClose}
        />
      </Box>
    </div>
  );
}
