import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const getPhysioListOfOrder = (listObj) => {
  return apiClient.post(`/physiotherapy/listOfOrder`, listObj, {
    headers: authHeader(),
  });
};

export const searchPhysioPatientAutoComplete = (postObj) => {
  return apiClient.post(`/physiotherapy/patientAutoComplete`, postObj, {
    headers: authHeader(),
  });
};

export const getPhysioListOfTests = (orderId, orderStatus, admissionId) => {
  return apiClient.get(
    `/physiotherapy/listOfTests/${orderId}/${orderStatus}/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const readPhysioTest = (orderDtlsId, menuId, privilege) => {
  return apiClient.get(
    `/physiotherapy/readPhysioTest/${orderDtlsId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const refusePhysioTest = (
  orderDtlsId,
  refusalNote,
  menuId,
  privilege
) => {
  return apiClient.get(
    `/physiotherapy/refusePhysioTest/${orderDtlsId}/${refusalNote}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const completePhysioTest = (postObj) => {
  return apiClient.post(`/physiotherapy/completeTest`, postObj, {
    headers: authHeader(),
  });
};

export const getTestNoteDetails = (orderDtlsId) => {
  return apiClient.get(`/physiotherapy/getPhysioNotesDetails/${orderDtlsId}`, {
    headers: authHeader(),
  });
};
