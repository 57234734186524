import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistorySheet from "./HistorySheet";
import GeneralExamination from "./GeneralExamination";
import SystematicExamination from "./SystematicExamination";
import FamilyHistory from "./FamilyHistory";
import OtherHistory from "./OtherHistory";

const HistorySheetTab = () => {
  //   const { lastUpdatedVtalDate } = useContext(
  //     DischargeSummaryContext
  //   );

  //
  const [expandPanal2, setExpandPanal2] = useState(true);
  const [expandPanal3, setExpandPanal3] = useState(true);
  const [expandPanal4, setExpandPanal4] = useState(true);
  const [expandPanal5, setExpandPanal5] = useState(true);
  const [expandPanal18, setExpandPanal18] = useState(true);

  const handleChangePanal2 = () => {
    setExpandPanal2(!expandPanal2);
  };
  const handleChangePanal3 = () => {
    setExpandPanal3(!expandPanal3);
  };
  const handleChangePanal4 = () => {
    setExpandPanal4(!expandPanal4);
  };
  const handleChangePanal5 = () => {
    setExpandPanal5(!expandPanal5);
  };
  const handleChangePanal18 = () => {
    setExpandPanal18(!expandPanal18);
  };

  //
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
      {/* HistorySheet */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal2}
            onChange={handleChangePanal2}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#CFFFCB",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                History Sheet
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* PatientDetails*/}
              <HistorySheet />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* General Examination */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal3}
            onChange={handleChangePanal3}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#CFFAFE",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                General Examination
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* General Examination*/}
              <GeneralExamination />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* Systemic Examination */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal4}
            onChange={handleChangePanal4}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#CFFAFE",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Systemic Examination
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* Systemic Examination*/}
              <SystematicExamination />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* FamilyHistory */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal5}
            onChange={handleChangePanal5}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#FCE7F3",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Family History
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* FamilyHistory*/}
              <FamilyHistory />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* OtherHistory */}
      <div className="pt-3">
        <div>
          
          <Accordion
            expanded={expandPanal18}
            onChange={handleChangePanal18}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "0.3rem",
                  minHeight: "40px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "#FCE7F3",
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Other History
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* OtherHistory*/}
              <OtherHistory />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default HistorySheetTab;
