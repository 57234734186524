import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const saveColorTubeTemperature = (finalObj) => {
  return apiClient.post(
    `/pathSampleCollection/saveColorTubeTemperature`,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

// autocomplete
export const fetchSampleCollectionOrderListSearch = (searchObj) => {
  return apiClient.post(
    `/pathSampleCollection/sampleCollectionOrderListSearch`,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchListOfOrders = (OrderObj) => {
  return apiClient.post(
    `/pathSampleCollection/colorTubeSamplePathOrder`,
    OrderObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchColourTubeTests = (orderId) => {
  return apiClient.get(`/pathSampleCollection/colourTubeTests/${orderId}`, {
    headers: authHeader(),
  });
};
