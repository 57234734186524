import { Box, Grid, Modal } from "@mui/material";
import React from "react";

//fromfield control liberary componant import
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import InputField from "../../common/components/FormFields/InputField";

import {
  default as CancelButton,
  default as ResetButton,
} from "../../common/components/Buttons/ResetButton";

import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import { SaveButton } from "../../common/components/Buttons/CommonButtons";
import UpdateButton from "../../common/components/Buttons/UpdateButton";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DropdownField from "../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import {
  getCashCounterOptions,
  saveUserRoleObject,
} from "../Services/UserManagementServices";
//the object to reset the form to blank values

export default function RoleModal(props) {
  // yup Schema validation for form fields
  const schema = yup.object().shape({
    role: yup
      .string()
      .required("Required")
      .matches(
        /^[A-Za-z0-9\s]+$/,
        "Only alphabets are allowed for this field "
      ),
    roleDescription: yup
      .string()

      .required("Required")
      .matches(
        /^[a-zA-Z0-9\s!@#$&()`.+,/"-]*$/,
        "Only alphabets are allowed for this field "
      ),
  });

  const defaultValues = {
    id: "",
    role: "",
    roleDescription: "",
    active: true,
    cashCounter: null,
    isMobileApplicable: false,
  };

  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);
  const [cashCounterOptions, setCashCounterOptions] = React.useState([]);
  const [openChild, setOpenChild] = React.useState(false);
  const [postObject, setPostObject] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  React.useEffect(() => {
    getCashCounterOptions()
      .then((response) => response.data)
      .then((res) => {
        setCashCounterOptions(res.result);
      });
  }, []);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //state variable to close the confirmation modal for PUT request
  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };

  React.useEffect(() => {
    if (props.edit === true && props.rowToEdit !== "") {
      setValue("role", props.rowToEdit?.Role);
      setValue("roleDescription", props?.rowToEdit["Role Description"]);
      let counterOption = [];
      if (props?.rowToEdit["Cash Counter"]?.length > 0)
        for (let obj of props?.rowToEdit["Cash Counter"]) {
          for (let counter of cashCounterOptions) {
            if (counter.label === obj) {
              counterOption.push(counter);
            }
          }
        }
      setValue("cashCounter", counterOption);
    }
  }, [props.edit]);

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //This function is called after clicking on the Update button of modal form and after clicking on the Add button of modal form
  const onSubmitDataHandler = (data) => {
    let cashCounterIds = [];
    for (let cahsCounterObject of data.cashCounter) {
      cashCounterIds.push(cahsCounterObject?.id);
    }

    let postObject = {
      role: data?.role,
      roleDescription: data?.roleDescription,
      active: data?.active,
      deleteFlag: false,
      isMobileAppApplicable: data?.isMobileApplicable,
      cashCounterIds: cashCounterIds,
    };
    if (props.edit) {
      postObject.id = props.rowToEdit.id;
    } else {
      postObject.id = null;
    }
    setPostObject(postObject);

    if (postObject !== "") {
      setOpenChild(true);
    }
  };

  //event listener function for the Add button on the modal form
  function addRecord() {}

  //event listener function for the Update button on the Confirmation modal
  function updateRecord() {}

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    maxHeight: "90%",
    // overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  let cashCounterWatch = watch("cashCounter");

  const handleCloseChild = () => {
    if (openChild === true) {
      setOpenChild(false);
    }
  };

  const saveUserRole = () => {
    setOpenBackdrop(true);
    setOpenChild(false);
    saveUserRoleObject(postObject)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        setOpenChild(false);
        reset(defaultValues);
        props.handleClose();
        props.populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
        setOpenChild(false);
      });
  };
  return (
    <>
      {/* Model and table name start */}
      <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4 p-2">
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleClose();
                  props.setEdit(false);
                  reset(defaultValues);
                }}
              />
            </div>
            <div className="row">
              <fieldset className="border border-gray-300 text-left lg:mx-auto lg:px-4 md:ml-0 md:mr-0 py-4 rounded lg:mt-6 lg:m-2 ">
                <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Role
                </legend>
                <form onSubmit={handleSubmit(onSubmitDataHandler)}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={3} sm={6}>
                      <InputField
                        name="role"
                        variant="outlined"
                        label="Role *"
                        error={errors.role}
                        control={control}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                      <InputField
                        name="roleDescription"
                        variant="outlined"
                        label="Role Description *"
                        error={errors.roleDescription}
                        control={control}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                    <Grid item lg={5} sm={10} zIndex={10}>
                      <DropdownField
                        name="cashCounter"
                        control={control}
                        label="Cash Counter"
                        placeholder="Cash Counter"
                        isSearchable={true}
                        isMulti={true}
                        dataArray={cashCounterOptions}
                        menuPlacement="bottom"
                      />
                    </Grid>

                    <Grid item lg={1} sm={3}>
                      <CheckBoxField
                        control={control}
                        name="active"
                        label="Active"
                      />
                    </Grid>
                    <Grid item lg={1} sm={3}>
                      <CheckBoxField
                        control={control}
                        name="isMobileApplicable"
                        label="Is Mobile Applicable"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    lg={3}
                    sm={6}
                    className="flex gap-4 "
                    justifyContent="right"
                    marginY={1}
                  >
                    {props.edit ? <UpdateButton /> : <SaveButton />}
                    {props.edit ? (
                      <CancelButton
                        onClick={() => {
                          props.handleClose();
                          props.setEdit(false);
                          reset(defaultValues);
                        }}
                      />
                    ) : (
                      <ResetButton
                        onClick={() => reset(defaultValues)} //Reset
                      />
                    )}
                  </Grid>
                </form>
              </fieldset>
              <CommonBackDrop openBackdrop={props.openBackdrop} />
            </div>
          </Box>
        </Modal>
        <CommonBackDrop openBackdrop={openBackdrop} />
        {/* Confirmation modal for PUT request */}
        <ConfirmationModal
          confirmationOpen={openChild}
          confirmationHandleClose={handleCloseChild}
          confirmationSubmitFunc={saveUserRole}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to Save this record ?"
          confirmationButtonMsg="Save"
        />
      </div>
    </>
  );
}
