import { yupResolver } from "@hookform/resolvers/yup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  endOfYesterday,
  format,
  isAfter,
  isBefore,
  isToday,
  startOfDay,
} from "date-fns";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchPatientInformationForOtDtls } from "../../services/otDetailsServices/OtDetailsServices";
import {
  SaveOtReservation,
  fetchBookingDetails,
  fetchOtTables,
  fetchPatientList,
  fetchReservationDetails,
  fetchReservationSlots,
} from "../../services/otReservationServices/OtReservationServices";
import CheckList from "./CheckList";
import OtLayout from "./OtLayout";
import OutsidePatientInfo from "./OutsidePatientInfo";
import SurgeryDetails from "./SurgeryDetails";
import OtLayoutIcon from "./otLayout/otMachineImages/layout.svg";
import OtReservationListing from "./otReservationListing/OtReservationListing";
import OtReservationTable from "./otReservationTable";
import { EditOnIcon } from "../../../assets/icons/CustomIcons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const patientTypeArray = [
  {
    id: 0,
    value: "OPD",
    label: "OPD",
  },
  {
    id: 1,
    value: "IPD",
    label: "IPD",
  },
];

const schema1 = yup.object().shape({
  surgeryName: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  doctorType: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  doctorName: yup
    .array()
    .min(1, "Pick at least 1 tags")
    .of(
      yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required("Required")
    )
    .required("Required"),

  EmployeeName: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
});
const OtReservation = () => {
  let navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("userInfo"));
  //
  let location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState();
  const [outsidePtInfo, setOutsidePtInfo] = useState(null);
  const [openOutsiidePtForm, setOpenOutsiidePtForm] = useState(false);

  //dates
  const [reservationDate, setReservationDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  //
  const [slotToPost, setSlotToPost] = useState(null);
  const [slotsToShow, setSlotsToShow] = useState([]); //used fro UI purpose
  //
  const [patientListArr, setPatientListArr] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [selectedTblRow, setSelectedTblRow] = useState(null);
  //
  const [otTables, setOtTables] = useState([]);
  const [otReservationSlots, setOtReservationSlots] = useState([]);
  const [classArray, setClassArray] = useState([]);
  const [preBookedArray, setPreBookedArray] = useState([]);
  const [selctedOtTable, setSelctedOtTable] = useState(null);
  //
  const [groupId, setGroupId] = useState();
  //
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  //ot layout
  const [layoutEquipmentArr, setLayoutEquipmentArr] = useState([]);
  const [previewLayoutArr, setPreviewLayoutArr] = useState([]);

  //checkList

  const [cssdItemKitArr, setCssdItemKitArr] = useState([]);
  const [deletedCssdItems, setDeletedCssdItems] = useState([]);

  const [preOpInstructionArr, setPreOpInstructionArr] = useState([]);

  const [pharmacyItemArr, setPharmacyItemArr] = useState([]);
  const [deletedPharmacyItems, setDeletedPharmacyItems] = useState([]);

  const [equipmentItemArr, setEquipmentItemArr] = useState([]);
  const [deletedEquipmentItems, setDeletedEquipmentItems] = useState([]);

  //surgery Details
  const [doctorDetailsArr, setDoctorDetailsArr] = useState([]);
  const [operationDate, setOperationDate] = useState(new Date());
  const [operationTime, setOperationTime] = useState(new Date());
  const [empDetailsArr, setEmpDetailsArr] = useState([]);
  // ReservationListModal
  const [openReservationListModal, setOpenReservationListModal] = useState(
    false
  );
  const handleOpenReservationListModal = () =>
    setOpenReservationListModal(true);
  const handleCloseReservationListModal = () =>
    setOpenReservationListModal(false);
  //
  // otLayoutModal
  const [openOtLayoutModal, setOpenOtLayoutModal] = useState(false);
  const handleOpenOtLayoutModal = () => setOpenOtLayoutModal(true);
  const handleCloseOtLayoutModal = () => setOpenOtLayoutModal(false);
  //

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const methods1 = useForm({
    mode: "onChange",
    defaultValues: { patientTypeOpdIpd: 1, duration: "", otTable: null },
  });

  const methods2 = useForm({
    mode: "onChange",
    resolver: yupResolver(schema1),
    defaultValues: {
      duration: 0,

      group: {
        id: 11,
        label: "SURGERY",
        value: "SURGERY",
      },
      subGroup: null,
      otTable: null,
      surgeryName: null,
      doctorType: null,
      doctorName: null,
      EmployeeName: null,
      operationInstruction: "",
    },
  });

  const methods3 = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      cssdItemKit: null,
      cssdQty: "",
      //
      preOpInstruction: null,
      //
      pharmacyItem: null,
      pharmacyItemQty: "",
    },
  });
  const {
    watch,
    control,
    setValue,
    resetField,
    register,

    formState: { errors },
  } = methods1;

  const { setValue: setValue2, getValues: getValues2 } = methods2;

  const {} = methods3;

  let PatientType = watch("patientTypeOpdIpd");
  let searchString = watch("searchPatient");
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  let isOutsidePatient = watch("outsidePatient");

  ///patient list
  // useEffect(() => {
  //   getPatientList();
  // }, [selectedFromDate, selectedToDate,searchString, , PatientType]);

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      getPatientList();
  }, [FromDate, ToDate, searchString, , PatientType]);

  const getPatientList = () => {
    let obj = {
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      searchString: searchString !== undefined ? searchString : "",
      opdIpd: PatientType,
    };

    fetchPatientList(obj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setPatientListArr(res.result);
      });
  };

  ///preBooked slots
  useEffect(() => {
    const yesterday = endOfYesterday();

    let validResDate =
      isToday(startOfDay(reservationDate)) ||
      isAfter(reservationDate, yesterday);

    if (validResDate) {
      getBookingDetails();
    } else {
      warningAlert("Please select valid reservation date");
      setValue("otTable", null);
    }
  }, [reservationDate, selctedOtTable]);

  const getBookingDetails = () => {
    let dateString = format(reservationDate, "yyyy-MM-dd");
    if (selctedOtTable !== null) {
      fetchBookingDetails(dateString, selctedOtTable?.id)
        .then((response) => response.data)
        .then((res) => {
          setPreBookedArray(res.result);
        });
    }
  };

  useEffect(() => {
    if (selectedTblRow !== null) {
      fetchPatientInformationForOtDtls(selectedTblRow.PatientId, PatientType)
        .then((response) => response.data)
        .then((res) => {
          setPatientInfo(res.result);
          setReservationDate(new Date());
          setValue("otTable", null);
          // methods1.reset();
          methods2.reset();
          methods3.reset();
          setClassArray([]);
          setTabValue(0);
          setSlotToPost(null);
          setSlotsToShow(null);
        });
    }
  }, [selectedTblRow]);

  const finalObj = {
    addedBy: 0,
    cssdItemRequestDtoList: [0],
    companyAssId: 0,
    companyId: 0,
    dateOfOperation: new Date(),
    duration: 0,
    employeeId: [0],
    fromTime: new Date(),
    instructionId: [0],
    opdIpd: 0,
    opdIpdExternalId: 0,
    operationNotes: "string",
    //otReservationDoctorDtoList: [0],
    otTableId: 0,
    patientId: 0,
    patientSourceId: 0,
    patientCategoryId: 0,
    pharmacyKitItemsDtoList: [0],
    reservationProcedureDtoList: [0],
    tariffId: 0,
    timeOfOperation: 0,
    toTime: new Date(),
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (patientInfo !== null) {
      setOpenConfirmationModal(true);
    } else {
      warningAlert("Please Select Patient!");
    }
  };

  const handleFormSubmit = (data) => {
    // setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    finalObj.reservationProcedureDtoList = doctorDetailsArr.map((item) => ({
      procedureId: item?.id,
      procedureName: item["Surgery Name"],
      otReservationDoctorDtoList: item["Doctor Details"]
        .map((obj) => obj["Doctor Id"])
        .flat()
        .map((data) => ({
          doctorId: data.id,
          doctorTypeId: data.doctorTypeId,
        })),
    }));

    //  cssd Item Details Arr

    let finalCssdArr = [...cssdItemKitArr, ...deletedCssdItems];
    let cssdArr = finalCssdArr.map(({ Id, Quantity, isDelete, ...item }) => ({
      itemId: Id,
      qty: Quantity,
      remark: "",
      isDelete,
    }));

    //  pre Op Instruction Data Arr
    let preOpInstructionDataArr = [];
    for (let i = 0; i < preOpInstructionArr.length; i++) {
      let id = preOpInstructionArr[i].id;

      preOpInstructionDataArr.push(id);
    }
    //  pharmacy Item Details Arr

    let finalPharmacyArr = [...pharmacyItemArr, ...deletedPharmacyItems];
    let pharmacyItemsArr = finalPharmacyArr.map(
      ({ ItemId, Quantity, kitId, ...item }) => ({
        itemId: ItemId,
        quantity: Quantity,
        kitId: kitId || 0,
      })
    );

    //  empDetails Arr
    let empArr = [];
    for (let i = 0; i < empDetailsArr.length; i++) {
      let id = empDetailsArr[i].id;

      empArr.push(id);
    }

    let opInst = getValues2("operationInstruction");

    finalObj.addedBy = token.userId;
    finalObj.companyAssId = patientInfo?.CompanyId_Ass || 0;
    finalObj.companyId = patientInfo?.CompanyId || 0;
    finalObj.cssdItemRequestDtoList = cssdArr;
    finalObj.dateOfOperation = operationDate;
    finalObj.duration = slotToPost.DurationMin;
    finalObj.employeeId = empArr;
    finalObj.fromTime = slotToPost.fromTime;
    finalObj.instructionId = preOpInstructionDataArr;
    finalObj.operationNotes = opInst;
    // finalObj.otReservationDoctorDtoList = doctorArr;
    finalObj.otTableId = slotToPost.tableId;
    finalObj.patientId = Number(patientInfo.patientId);
    finalObj.patientSourceId = Number(patientInfo.patientSourceId);
    finalObj.patientCategoryId = Number(patientInfo.patientCategoryId);
    finalObj.pharmacyKitItemsDtoList = pharmacyItemsArr;
    // finalObj.reservationProcedureDtoList = ProcedureArr;
    finalObj.tariffId = Number(patientInfo.tariffId);
    finalObj.timeOfOperation = operationTime;
    finalObj.toTime = slotToPost.toTime;
    //
    finalObj.surgeryEquipmentRequestDtoList = equipmentItemArr.map((item) => ({
      equipmentItemId: item.ServiceID,
      quantity: item.Quantity,
      remark:"",
    }));
    //

    finalObj.opdIpd = isOutsidePatient ? 2 : Number(PatientType);
    finalObj.opdIpdExternalId = isOutsidePatient
      ? 0
      : Number(PatientType) === 0
      ? selectedTblRow.VisitId
      : selectedTblRow.AdmissionId;
    ///
    finalObj.otReservationLayoutRequestDto = layoutEquipmentArr;

    //
    finalObj.outsidePatientInfo = !!outsidePtInfo
      ? {
          address: outsidePtInfo?.address || "",
          age: outsidePtInfo?.age || 0,
          firstName: outsidePtInfo?.firstName || "",
          genderId: outsidePtInfo?.gender?.id || 0,
          lastName: outsidePtInfo?.lastName || "",
          mobileNo: outsidePtInfo?.mobileNumber || "",
        }
      : null;
    ///

    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;
    setOpenBackdrop(true);
    console.log("finalObj", finalObj);
    ///////////////
    SaveOtReservation(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          resetField("searchPatient"); //clear search
          resetField(""); //clear search
          setPatientInfo(null);
          methods1.reset();
          methods2.reset();
          methods3.reset();
          setCssdItemKitArr([]);
          setDeletedCssdItems([]);
          setDeletedPharmacyItems([]);
          setPreOpInstructionArr([]);
          setPharmacyItemArr([]);
          //surgery Details
          setDoctorDetailsArr([]);
          setEmpDetailsArr([]);
          setSlotToPost(null);
          setSlotsToShow(null);
          setReservationDate(new Date());
          setSelectedTblRow(null);
          // refresh list
          getPatientList();
          //getBookingDetails();
          setTabValue(0);
          setValue("duration", "");
          setValue2("duration", "");
          setValue("otTable", null);
          setValue2("otTable", null);
          setClassArray([]);
        }
      })
      .catch((res) => {
        errorAlert(res.message || res.response.data.message);
        setOpenBackdrop(false);
      });
  };

  //##################################################### changes by rk202322

  useEffect(() => {
    fetchOtTables()
      .then((response) => response.data)
      .then((res) => {
        setOtTables(res.result);
      });
    fetchReservationSlots()
      .then((response) => response.data)
      .then((res) => {
        setOtReservationSlots(res.result);
      });
  }, [reservationDate, patientInfo, selctedOtTable]);

  useEffect(() => {
    let fromIndex;
    let toIndex;
    let className;

    otReservationSlots.forEach((itemOne, indexOne) => {
      preBookedArray.forEach((itemTwo, indexTwo) => {
        if (itemTwo.fromTime === itemOne.timeHour) {
          fromIndex = indexOne;
        }
        if (itemTwo.toTime === itemOne.timeHour) {
          toIndex = indexOne;
        }
        className = classArray.filter(
          (classObj) => classObj.id === itemTwo.otTableId
        );
        let arr = otReservationSlots.slice(fromIndex, toIndex + 1);
        if (
          itemTwo === undefined ||
          (itemTwo?.date === format(reservationDate, "yyyy-MM-dd") &&
            itemTwo.otTableId === selctedOtTable.id)
        ) {
          for (let selectObj of arr) {
            otReservationSlots.find((item) => {
              if (item === selectObj) {
                // item.isBooked = true;
                item.isPreBooked = true;
                item.class = className[0]?.label;
                //
                item.patientInfo = itemTwo.patientInfo;
                item.duration = itemTwo.duration;
                item.fromTime = itemTwo.fromTime;
              }
            });
          }
          setOtReservationSlots(otReservationSlots);
          setClassArray([...classArray]);
        }
        setOtReservationSlots(otReservationSlots);
      });
    });
  }, [preBookedArray, otReservationSlots, reservationDate, selctedOtTable]);

  const getPreviousDate = () => {
    const currentDayInMilli = new Date(reservationDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);

    const result = isBefore(previousDate, new Date());
    setReservationDate(!result ? previousDate : new Date());
  };

  const getNextDate = () => {
    const currentDayInMilli = new Date(reservationDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);

    setReservationDate(nextDate);
  };

  ///to set surgery details data according to ot schudule

  useEffect(() => {
    setOperationDate(reservationDate);
    if (slotToPost !== null) {
      setOperationTime(slotToPost.fromTime);
    }
    if (slotsToShow !== null) {
      ///set Duration
      setValue("duration", slotsToShow?.durationMin);
      setValue2("duration", slotsToShow?.durationMin);
      setValue2("otTable", {
        id: slotsToShow?.OtTableId,
        label: slotsToShow?.OtTable,
        value: slotsToShow?.OtTable,
      });
    } else if (slotsToShow === null) {
      setValue2("duration", "");
      setValue("duration", "");
    }
  }, [reservationDate, slotToPost, slotsToShow]);

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  ///table (patient info list)  related

  const handleSelectedRow = (row, index) => {
    setSelectedTblRow(row);
    isOutsidePatient && setValue("outsidePatient", false);
    setOpenOutsiidePtForm(false);
  };

  const handlePatchData = (row, index) => {
    row &&
      fetchReservationDetails(row?.OTReservationId)
        .then((response) => response.data)
        .then((res) => {
          // setUserActions(res.result);
          !!res?.result?.Employee &&
            setEmpDetailsArr(
              res?.result?.Employee?.map(
                (EmployeeId, OTEmpDetailId, ...item) => ({
                  id: EmployeeId,
                  OTEmpDetailId,
                })
              )
            );
          // setPharmacyItemArr(res?.result?.PharmacyItems || []);
          setCssdItemKitArr(res?.result?.CssdItems || []);
          // setDoctorDetailsArr(res?.result?.Surgeries || []);
        });
  };

  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          OT Reservation
        </div>
        <div className="grid grid-cols-12 mb-3 gap-2">
          <div className="grid md:col-span-12 lg:col-span-3">
            <div className="gap-2">
              {/* filter fields */}
              <div className="grid grid-cols-2 my-2 gap-3">
                {/* from Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* to Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="ml-3 grid grid-cols-2 items-center gap-3">
                <div>
                  <RadioField
                    control={control}
                    name="patientTypeOpdIpd"
                    dataArray={patientTypeArray}
                  />
                </div>
                <div
                  onClick={(e) => {
                    setPatientInfo(null);
                    setOpenOutsiidePtForm(e.target.checked);
                    getPatientList();
                  }}
                >
                  <CheckBoxField
                    control={control}
                    name="outsidePatient"
                    label="Outside Patient"
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-2">
                <div className=" z-50 col-span-9">
                  <InputField
                    variant="outlined"
                    name="searchPatient"
                    label="Search By Patient Name / MR.No"
                    error={errors.remarks}
                    control={control}
                    inputProps={{
                      style: { textTransform: "capitalize" },
                    }} // use inputProps props for return 1st letter in upper case
                    required
                  />
                </div>
                <div className="col-span-2">
                  <CommonButton
                    className="bg-customBlue text-white "
                    searchIcon
                  />
                </div>
              </div>
              {/* patient list table */}
              <div>
                {patientListArr?.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={patientListArr}
                    handleSelectedRow={handleSelectedRow}
                    tableClass="rounded lg:h-96 md:h-72 2xl:h-[630px]"
                  />
                ) : (
                  <p className="font-semibold  text-gray-600 text-center mt-3">
                    No Record Found
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="grid md:col-span-12 lg:col-span-9">
            <div>
              <div>
                <fieldset
                  className={`border border-gray-300 col-span-3 w-full text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 ${
                    !openOutsiidePtForm && "grid grid-cols-12"
                  }`}
                >
                  <div className="col-span-11">
                    {openOutsiidePtForm ? (
                      <OutsidePatientInfo
                        setPatientInfo={setPatientInfo}
                        setOpenOutsiidePtForm={setOpenOutsiidePtForm}
                        outsidePtInfo={outsidePtInfo}
                        setOutsidePtInfo={setOutsidePtInfo}
                      />
                    ) : (
                      <div className="grid grid-cols-2 2xl:grid-cols-2">
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className="font-semibold w-28">
                            Patient Name
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientInfo && patientInfo.patientName}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className="font-semibold w-28">MR. No.</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientInfo && patientInfo.mrNo}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className="font-semibold w-28">IPD No.</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientInfo &&
                                (patientInfo.ipdNo || patientInfo.OPDNO)}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className="font-semibold w-28">Bed No.</span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientInfo && patientInfo.BedNo}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!openOutsiidePtForm && isOutsidePatient && (
                    <div className="col-span-1 flex justify-end">
                      <EditOnIcon
                        title="Edit Outside Patient Info"
                        onClick={() => {
                          setOpenOutsiidePtForm(true);
                        }}
                      />
                    </div>
                  )}
                </fieldset>
              </div>

              <div className="mt-3">
                <div className="grid grid-cols-6 gap-3">
                  <div className="flex gap-1 items-center col-span-2">
                    <button
                      className="border border-gray-400 rounded px-1 h-9"
                      onClick={getPreviousDate}
                    >
                      <KeyboardArrowLeftIcon />
                    </button>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disablePast
                        label="Date"
                        value={reservationDate}
                        name="reservationDate"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          // let date = format(newValue, "yyyy-MM-dd");
                          setReservationDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            fullWidth
                            name="fromDate"
                            size="small"
                            defaultValue=""
                            inputFormat="dd/MM/yyyy"
                            {...params}
                            sx={{
                              svg: {
                                color: "#0B83A5",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "  ",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <button
                      className="border border-gray-400 rounded px-1 h-9"
                      onClick={getNextDate}
                    >
                      <ChevronRightIcon />
                    </button>
                  </div>

                  <div className="">
                    <InputField
                      disabled={true}
                      control={control}
                      name="duration"
                      label={"Duration(Mins)"}
                    />
                  </div>

                  <div className="">
                    <DropdownField
                      control={control}
                      name="otTable"
                      label="OT Table"
                      placeholder="OT Table"
                      dataArray={otTables}
                      isDisabled={patientInfo !== null ? false : true}
                      inputRef={{
                        ...register("otTable", {
                          onChange: (e) => {
                            setSelctedOtTable(e.target.value);
                            let arr = [e.target.value];
                            if (e.target.value?.label === "CATH LAB 1") {
                              setClassArray(otTables);
                            } else {
                              setClassArray(arr);
                            }
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="col-span-2 flex justify-end gap-3 items-center">
                    {userActions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "Add Consent" && (
                          <>
                            <div>
                              <CommonButton
                                className="bg-customBlue text-white"
                                label="Add Consent"
                                onClick={() =>
                                  navigate("/operationTheater/consent", {
                                    state: {
                                      patientInfo,
                                      isEdit: true,
                                      menuId: obj.menuId,
                                    },
                                  })
                                }
                                disabled={patientInfo !== null ? false : true}
                              />
                            </div>
                          </>
                        )}

                        {!obj.isAction && obj.action === "View" && (
                          <>
                            <div>
                              <CommonButton
                                className="bg-customBlue text-white"
                                onClick={() => {
                                  handleOpenReservationListModal();
                                  setPrivilege(obj.action);
                                }}
                                label="Booking List"
                              />
                            </div>
                          </>
                        )}
                      </>
                    ))}
                  </div>
                </div>

                <div className=" grid  grid-cols-6 mt-2 ">
                  <div className="flex items-center gap-2 w-full col-span-1 xl:col-span-2 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28 text-gray-700">
                      From Time
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {slotsToShow?.fromTime}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28 text-gray-700">
                      To Time
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {slotsToShow?.toTime}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <div className="grid grid-cols-10 items-center">
                      <div className="col-span-9">
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Ot Schedule" {...a11yProps(0)} />
                          <Tab
                            label="Surgery Details"
                            {...a11yProps(1)}
                            disabled={slotToPost !== null ? false : true}
                          />
                          <Tab
                            label="Check List"
                            {...a11yProps(2)}
                            disabled={
                              slotToPost !== null && doctorDetailsArr.length > 0
                                ? false
                                : true
                            }
                          />
                          {/* <Tab label="Bed Reservation" {...a11yProps(3)} /> */}
                        </Tabs>
                      </div>
                      <div className="col-span-1 flex justify-end">
                        {userActions.map((obj) => (
                          <>
                            {!obj.isAction &&
                              obj.action === "OT Layout" &&
                              slotToPost !== null && (
                                <>
                                  <div>
                                    <Tooltip title={"OT Layout"}>
                                      <button
                                        className="border border-customBlue p-2 rounded hover:bg-slate-100"
                                        onClick={() => {
                                          doctorDetailsArr?.length > 0
                                            ? handleOpenOtLayoutModal()
                                            : warningAlert(
                                                "Please add surgeries before creating layout..."
                                              );
                                        }}
                                      >
                                        <img src={OtLayoutIcon} className="" />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  </Box>

                  <FormProvider {...methods1}>
                    <TabPanel value={tabValue} index={0}>
                      <div>
                        <OtReservationTable
                          otReservationSlots={otReservationSlots}
                          setOtReservationSlots={setOtReservationSlots}
                          classArray={classArray}
                          setClassArray={setClassArray}
                          reservationDate={reservationDate}
                          //
                          preBookedArray={preBookedArray}
                          //
                          setSlotToPost={setSlotToPost}
                          slotToPost={slotToPost}
                          selctedOtTable={selctedOtTable}
                          //
                          slotsToShow={slotsToShow}
                          setSlotsToShow={setSlotsToShow}
                          //
                          tabValue={tabValue}
                          setTabValue={setTabValue}
                        />
                      </div>
                    </TabPanel>
                  </FormProvider>

                  <FormProvider {...methods2}>
                    <form>
                      <TabPanel value={tabValue} index={1}>
                        <div>
                          <SurgeryDetails
                            groupId={groupId}
                            setGroupId={setGroupId}
                            doctorDetailsArr={doctorDetailsArr}
                            setDoctorDetailsArr={setDoctorDetailsArr}
                            operationTime={operationTime}
                            setOperationTime={setOperationTime}
                            operationDate={operationDate}
                            setOperationDate={setOperationDate}
                            setEmpDetailsArr={setEmpDetailsArr}
                            empDetailsArr={empDetailsArr}
                            //
                          />
                        </div>
                      </TabPanel>

                      <div className=" col-span-6 flex gap-2 justify-end">
                        <div className="my-2">
                          {tabValue === 1 ? (
                            <CommonButton
                              label="Previous"
                              className="border border-customBlue text-customBlue"
                              onClick={() => {
                                setTabValue(tabValue - 1);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className=" my-2">
                          {tabValue === 1 ? (
                            <CommonButton
                              label="Next"
                              className="bg-customGreen text-white"
                              onClick={() => {
                                if (doctorDetailsArr.length === 0) {
                                  warningAlert("Please Select Doctors.");
                                } else if (doctorDetailsArr.length > 0) {
                                  setTabValue(tabValue + 1);
                                }
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                  <FormProvider {...methods3}>
                    <form>
                      <TabPanel value={tabValue} index={2}>
                        <CheckList
                          groupId={groupId}
                          cssdItemKitArr={cssdItemKitArr}
                          setCssdItemKitArr={setCssdItemKitArr}
                          setDeletedCssdItems={setDeletedCssdItems}
                          preOpInstructionArr={preOpInstructionArr}
                          setPreOpInstructionArr={setPreOpInstructionArr}
                          pharmacyItemArr={pharmacyItemArr}
                          setPharmacyItemArr={setPharmacyItemArr}
                          setDeletedPharmacyItems={setDeletedPharmacyItems}
                          equipmentItemArr={equipmentItemArr}
                          setEquipmentItemArr={setEquipmentItemArr}
                          deletedEquipmentItems={deletedEquipmentItems}
                          setDeletedEquipmentItems={setDeletedEquipmentItems}
                          //
                          doctorDetailsArr={doctorDetailsArr}
                        />
                      </TabPanel>
                      <div className="flex justify-end">
                        <div className="my-2">
                          {tabValue === 2 ? (
                            <CommonButton
                              className="border border-customBlue text-customBlue"
                              label="previous"
                              onClick={() => {
                                setTabValue(tabValue - 1);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mx-2 my-2">
                          {userActions.map((obj) => (
                            <>
                              {!obj.isAction && obj.action === "Create" ? (
                                <>
                                  {tabValue === 2 ? (
                                    <CommonButton
                                      label="Save"
                                      className="bg-customGreen text-white"
                                      type="submit"
                                      onClick={(e) => {
                                        handleOnClick(e);
                                        setPrivilege(obj.action);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : null}
                            </>
                          ))}
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ReservationListModal */}
      {openReservationListModal ? (
        <OtReservationListing
          open={openReservationListModal}
          setOpen={setOpenReservationListModal}
          handleOpen={handleOpenReservationListModal}
          handleClose={handleCloseReservationListModal}
          userActions={userActions}
          privilege={privilege}
          handlePatchData={handlePatchData}
        />
      ) : null}

      {openOtLayoutModal ? (
        <OtLayout
          open={openOtLayoutModal}
          setOpen={setOpenOtLayoutModal}
          handleOpen={handleOpenOtLayoutModal}
          handleClose={handleCloseOtLayoutModal}
          // userActions={userActions}
          // privilege={privilege}
          layoutEquipmentArr={layoutEquipmentArr}
          setLayoutEquipmentArr={setLayoutEquipmentArr}
          previewLayoutArr={previewLayoutArr}
          setPreviewLayoutArr={setPreviewLayoutArr}
          isFromForm={true}
          isEditLayout={true}
          doctorDetailsArr={doctorDetailsArr}
          patientInfo={patientInfo}
          selectedTblRow={selectedTblRow}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default OtReservation;
