import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import React from "react";
import * as yup from "yup";
//fromfield control liberary componant import
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import CancelButton from "../../common/components/Buttons/CancelButton";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import ResetButton from "../../common/components/Buttons/ResetButton";
import UpdateButton from "../../common/components/Buttons/UpdateButton";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DropdownField from "../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
//the object to reset the form to blank values

// import MapUserFunctionality from "./MapUserFunctionality";
import LoadingSpinner from "../../common/components/loadingspinner/loadingSpinner";
import CustomCheckbox from "../AssignFuctinalities/CustomCheckbox";
import {
  getRolesAutocomplete,
  getUnits,
  getUserFunctionalities,
  getUserInfo,
  saveUsersObject,
  searchEmployees,
} from "../Services/UserManagementServices";
import AssignFunctionalityUsers from "./AssignFunctionalityUsers";

const loggedUser = JSON.parse(localStorage.getItem("userInfo"));

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  maxHeight: "100%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 1,
};

const authorityLevelArr = [
  {
    id: 1,
    value: 1,
    label: "Level 1",
  },
  {
    id: 2,
    value: 2,
    label: "Level 2",
  },
  {
    id: 3,
    value: 3,
    label: "Level 3",
  },
];

let usersId = "";
let employeeId = "";
export default function UserModal(props) {
  const { open, userValue } = props;

  // yup Schema validation for form fields

  const schema = yup.object().shape({
    password: yup.string().required(),
    // username: yup.string().optional.required(),
    // userRole: yup
    //   .object()
    //   .shape()
    //   .required("Select Employee Role"),

    userRole: yup
      .array()
      .min(1, "Select Employee Role")
      .of(
        yup.object().shape({
          label: yup.string().required("Select Employee Role"),
          value: yup.string().required("Select Employee Role"),
        })
      )
      .required("Select Employee Role"),
    units: yup
      .array()
      .min(1, "Select Employee Units")
      .of(
        yup.object().shape({
          label: yup.string().required("Select Employee Units"),
          value: yup.string().required("Select Employee Units"),
        })
      )
      .required("Select Employee Units"),
    authorityLevel: yup.object().nullable().shape({
      value: yup.string(),
      label: yup.string(),
    }),
    authorityPasscode: yup.string(),

    employee: yup
      .object()
      .nullable()
      .when("allreadyexist", (value) => {
        if (!value) {
          return;
          yup
            .object()
            .nullable()
            .shape({
              label: yup.string().required("Select units"),
              value: yup.string().required("Select units"),
            })
            .required("Select units");
        } else {
          return yup.object().nullable();
        }
      }),
  });

  const defaultValues = {
    employeeName: true,
    units: null,
    username: "",
    password: "",
    authorityPasscode: "",
    department: null,
    authorityLevel: null,
    employee: "",
    passcode: "",
    roles: null,
    searchStore: null,
    active: true,
    userRole: null,
    searchByDepartment: null,
    searchByUnits: null,
    isDoctor: false,
  };

  const [tableData, setTableData] = React.useState([]);
  const [incomingData, setIncomingData] = React.useState(null);
  const [department, setDepartment] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [searchEmployee, setSearchEmployee] = React.useState();
  const [employeeExist, setEmployeeExist] = React.useState(false);
  const [storeDropdown, setStoreDropdown] = React.useState();
  //state variable to open the confirmation modal for POST request
  const [openPost, setOpenPost] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  //state variable to open the confirmation modal for PUT request
  const [openPut, setOpenPut] = React.useState(false);
  const [radiologistPathologist, setRadiologistPathologist] = React.useState(
    false
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState("");
  const [userRoles, setUserRoles] = React.useState([]);
  const [functionalities, setFunctionalities] = React.useState([]);
  const [finalPost, setFinalPost] = React.useState({});
  const [roleErrorMessage, setRoleErrorMessage] = React.useState("");

  React.useEffect(() => {
    //get units autocomplete
    getUnits()
      .then((response) => response.data)
      .then((res) => {
        setUnitOptions(res.result);
      });

    // get roles autocomplete
    getRolesAutocomplete()
      .then((response) => response.data)
      .then((res) => {
        if (res.result.length > 0) {
          let roleOptions = [];
          for (let roleObject of res.result) {
            let object = {};
            object.id = roleObject.id;
            object.label = roleObject.label;
            object.value = roleObject.id;
            roleOptions.push(object);
          }
          setUserRoles(roleOptions);
        }
      });
  }, []);

  React.useEffect(() => {
    if (unitOptions.length > 0) {
      let loggedUnit = unitOptions.find(
        (result) => result.id === loggedUser?.unitId
      );
      setValue("searchByUnits", loggedUnit);
    }
  }, [unitOptions]);

  const getUserRoles = () => {
    // get roles autocomplete
    getRolesAutocomplete()
      .then((response) => response.data)
      .then((res) => {
        if (res.result.length > 0) {
          let roleOptions = [];
          for (let roleObject of res.result) {
            let object = {};
            object.id = roleObject.id;
            object.label = roleObject.label;
            object.value = roleObject.id;
            roleOptions.push(object);
          }
          setUserRoles(roleOptions);
        }
      });
  };

  const handleShowPassword = () => setShowPassword(!showPassword);
  //state variable to close the confirmation modal for POST request
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //state variable to close the confirmation modal for PUT request
  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };
  const handleClose = () => {
    setOpenReset(false);
  };

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    // errors object for show you the errors in the form
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: false,
  });

  //watching the required fields for values
  let roleWatch = watch("userRole");
  let unitWatch = watch("searchByUnits");
  let isDoctorWatch = watch("isDoctor");
  let departmentWatch = watch("searchByDepartment");
  let passwordWatch = watch("password");
  let userNameWatch = watch("username");
  let employeeWatch = watch("employeeCode");
  let multiUnitsWatch = watch("units");

  React.useEffect(() => {
    setValue("employeeCode", null);
    setSelectedEmployee("");
  }, [isDoctorWatch]);

  React.useEffect(() => {
    if (employeeWatch !== null) {
    } else if (employeeWatch === null) {
      setFunctionalities([]);
      setValue("username", "");
      setValue("password", "");
      setValue("userRole", null);
      setValue("units", null);
      // reset()
    }
  }, [employeeWatch]);

  //This function is called after clicking on the Update button of modal form and after clicking on the Add button of modal form
  const onSubmitDataHandler = (data) => {
    let selectedRoles = roleWatch;
    let rolesArray = [];
    let unitsArray = [];
    if (selectedRoles) {
      for (let role of selectedRoles) {
        let roleObject = {};
        roleObject.id = role.id;
        rolesArray.push(roleObject);
      }
    }

    if (multiUnitsWatch) {
      for (let unit of multiUnitsWatch) {
        unitsArray.push(unit?.id);
      }
    }

    let postObjectNew = {
      assignRoleDto: {
        functionalities: functionalities,
      },
      users: {
        userId: selectedEmployee?.userId,
        active: true,
        empId: selectedEmployee?.value,
        loginName: userNameWatch,
        password: passwordWatch,
        roles: rolesArray,
        units: unitsArray,
      },
    };
    setFinalPost(postObjectNew);
    if (roleErrorMessage === "") {
      setOpenPost(true);
    }
  };

  // Post users final postObject
  function addRecord() {
    props.setOpenBackdrop(true);
    saveUsersObject(finalPost)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.setPage(0);
        props.populateTable();
        setOpenPost(false);
        setFunctionalities([]);
        setShowPassword(false);
        reset();
        if (unitOptions.length > 0) {
          let loggedUnit = unitOptions.find(
            (result) => result.id === loggedUser?.unitId
          );
          setValue("searchByUnits", loggedUnit);
        }

        props.setOpenBackdrop(false);
        setSelectedEmployee("");
        setOpenPost(false);
        props.handleClose();
      })
      .catch((error) => {
        errorAlert(error.message);
        props.setOpenBackdrop(false);
      });
  }

  function updateRecord() {
    handleClosePut();
    props.setOpenBackdrop(true);
  }

  const handleChange = (autoSearchString) => {
    let searchObject = {
      departmentId: departmentWatch?.id,
      isDoctor: isDoctorWatch,
      searchString: autoSearchString,
      unitId: unitWatch?.id,
    };
    searchEmployees(searchObject)
      .then((response) => response.data)
      .then((res) => {
        setSearchEmployee(res.result);
      });
  };

  // check user avalibility
  const handleChangeUserAvalibility = (e) => {};
  React.useEffect(() => {
    if (employeeWatch && props.edit) {
      setSelectedEmployee(employeeWatch);
    }
  }, [employeeWatch]);

  React.useEffect(() => {
    if (props.selectedRow !== "") {
      let employeeObject = {
        id: props.selectedRow?.id,
        label: props.selectedRow?.name,
        userId: props.selectedRow?.id,
        value: props.selectedRow?.empId,
        name: props.selectedRow?.name,
        type: props.selectedRow?.type,
        department: props.selectedRow?.department,
        code: props.selectedRow?.code,
      };
      if (props.edit) {
        setValue("employeeCode", employeeObject);
      }
    }
  }, [props.selectedRow]);

  //useEffect to get the employee information based on employee id
  React.useEffect(() => {
    if (selectedEmployee?.userId !== null) {
      getUserInfo(selectedEmployee?.userId)
        .then((response) => response.data)
        .then((res) => {
          let rolesArray = [];
          let unitsArray = [];
          for (let role of res.result?.roles) {
            let roleObject = {
              id: role.id,
              label: role.label,
              value: role.label,
            };
            rolesArray.push(roleObject);
          }
          for (let unit of res.result?.units) {
            let unitObject = {
              id: unit?.id,
              label: unit?.label,
              value: unit?.id,
            };
            unitsArray.push(unitObject);
          }
          setValue("units", unitsArray);
          setValue("userRole", rolesArray);
          if (res.result.user?.username) {
          }
          setValue("username", res.result.user?.username);
          setValue("password", res.result.user?.password);
        });
    } else {
      let unitsArray = [
        {
          id: selectedEmployee?.unitId,
          label: selectedEmployee?.unit,
          value: selectedEmployee?.unitId,
        },
      ];

      setValue("units", unitsArray);

      // setting value of units by getting from user information
    }
  }, [selectedEmployee]);

  // useEffect to get functionalities as per role changes
  React.useEffect(() => {
    if (roleWatch?.length === 0) {
      setFunctionalities([]);
    }
    if (roleWatch !== null && roleWatch?.length > 0) {
      setShowSpinner(true);
      let selectedRoles = roleWatch;
      let rolesArray = [];
      if (selectedRoles) {
        for (let role of selectedRoles) {
          rolesArray.push(role.id);
        }
      }
      let searchObject = {
        rolesId: rolesArray,
        usersId: selectedEmployee?.userId,
      };
      getUserFunctionalities(searchObject)
        .then((response) => response.data)
        .then((res) => {
          setShowSpinner(false);
          if (res.result.length > 0) {
            setFunctionalities(res.result);
          } else {
            setRoleErrorMessage("No Functionalities Found For This Role.");
          }
        })
        .catch((error) => {
          setShowSpinner(false);
        });
    } else {
      setFunctionalities([]);
    }
    if (roleWatch !== null) {
      if (roleWatch[0] && roleWatch[0]?.label === "Super Admin") {
        setRoleErrorMessage("");
        setUserRoles([]);
      } else {
        getUserRoles();
      }

      if (roleWatch !== null) {
        if (roleWatch?.length === 1) {
          if (roleWatch[0]?.label !== "Super Admin") {
            setRoleErrorMessage("");
          }
        }
      }

      if (roleWatch?.length > 1) {
        for (let role of roleWatch) {
          if (role?.label === "Super Admin") {
            setRoleErrorMessage(
              "Please Unselect Roles Other Than Super Admin."
            );
          } else {
            setRoleErrorMessage("");
          }
        }
      }
    } else {
      setFunctionalities([]);
    }
    if (roleWatch?.length === 0) {
      setFunctionalities([]);
      setShowSpinner(false);
    }
    if (roleWatch === null) {
      getUserRoles();
    }
  }, [roleWatch]);

  //useEffect for setting department null if unit is null
  React.useEffect(() => {
    if (unitWatch === null) {
      setDepartment([]);
    }
  }, [unitWatch]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => {
            props.setEdit(false);
          }}
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1 w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleClose();
                  props.setEdit(false);
                  setStoreDropdown();
                  setDepartment([]);
                  setShowPassword(false);
                  reset(defaultValues);
                  if (unitOptions.length > 0) {
                    let loggedUnit = unitOptions.find(
                      (result) => result.id === loggedUser?.unitId
                    );
                    setValue("searchByUnits", loggedUnit);
                  }
                  setSelectedEmployee("");
                  setIncomingData(null);
                  setTableData([]);
                  setEmployeeExist(false);
                }}
              />
            </div>
            <div className="row">
              <>
                <fieldset className="  text-left lg:mx-auto lg:px-4 md:ml-0 md:mr-0  rounded lg:mt-2 lg:m-1 ">
                  <legend className=" font-bold text-gray-700">User</legend>
                  <>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item lg={1} sm={2}>
                        <CustomCheckbox
                          control={control}
                          name="isDoctor"
                          label="IsDoctor"
                        />
                      </Grid>
                      <Grid item lg={5} sm={10}>
                        <SearchDropdown
                          control={control}
                          error={errors.employeeCode}
                          dataArray={searchEmployee}
                          searchIcon={true}
                          name="employeeCode"
                          label="Employee Code / Name"
                          placeholder="Search by Employee Name / Employee Code / Email / Mobile"
                          isSearchable={true}
                          isClearable={true}
                          handleInputChange={handleChange}
                          inputRef={{
                            ...register("employeeCode", {
                              onChange: (e) => {
                                if (
                                  e.target.value !== null ||
                                  e.target.value !== ""
                                ) {
                                  setSelectedEmployee(e.target.value);
                                } else {
                                  setSelectedEmployee("");
                                }
                              },
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item lg={3} sm={6}>
                        <DropdownField
                          control={control}
                          error={errors.searchByUnits}
                          name="searchByUnits"
                          label="Unit"
                          placeholder="Unit"
                          dataArray={unitOptions}
                          isSearchable={false}
                        />
                      </Grid>
                    </Grid>

                    <>
                      {selectedEmployee ? (
                        <fieldset className=" mx-auto border w-full rounded my-1  bg-gray-100">
                          <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 gap-2 py-2 px-4 ">
                            <div className="flex  items-center  gap-2 w-full font-semibold text-sm ">
                              <span className="w-28 font-semibold whitespace-nowrap text-sm">
                                Employee Name
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>:</span>
                                <span className="text-gray-700 font-normal">
                                  {selectedEmployee?.name}
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center gap-2 w-full font-semibold text-sm ">
                              <span className="w-28 font-semibold">
                                Employee Code
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>:</span>
                                <span className="text-gray-700 font-normal">
                                  {selectedEmployee?.code}
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center gap-2 w-full font-semibold text-sm ">
                              <span className="w-28 font-semibold">
                                Employee Type
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>:</span>
                                <span className="text-gray-700 font-normal">
                                  {selectedEmployee?.type}
                                </span>
                              </div>
                            </div>

                            <div className="flex items-center gap-2 w-full font-semibold text-sm  ">
                              <span className="w-28 font-semibold">
                                Department
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>:</span>
                                <span className="text-gray-700 font-normal">
                                  {selectedEmployee?.department}
                                </span>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      ) : (
                        ""
                      )}
                      <hr className="w-full  border-t-1 border-customBlue my-1" />
                      <>
                        <div className="grid grid-cols-4 items-center gap-2 my-2">
                          <div className=" w-full">
                            <Controller
                              control={control}
                              name="username"
                              defaultValue=""
                              render={({ field: { value } }) => (
                                <TextField
                                  control={control}
                                  value={value}
                                  fullWidth
                                  disabled={props.edit}
                                  label="Username"
                                  {...register("username")}
                                  type="text"
                                  size="small"
                                  error={errors?.username}
                                />
                              )}
                            />
                          </div>

                          <div>
                            <Controller
                              control={control}
                              name="password"
                              defaultValue=""
                              render={({ field: { value } }) => (
                                <TextField
                                  value={value}
                                  fullWidth
                                  label="Password *"
                                  placeholder="Password *"
                                  autoComplete="new-password"
                                  size="small"
                                  error={errors?.password}
                                  type={showPassword ? "text" : "password"}
                                  {...register("password")}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                        <IconButton
                                          onClick={handleShowPassword}
                                        >
                                          {showPassword ? (
                                            <Visibility />
                                          ) : (
                                            <VisibilityOff />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="">
                            <DropdownField
                              control={control}
                              name="units"
                              placeholder="Unit"
                              isClearable={true}
                              isMulti={true}
                              dataArray={unitOptions}
                              error={errors?.units}
                            />
                          </div>

                          <div className="flex items-center gap-2 px-4 whitespace-nowrap">
                            <div className="w-full">
                              <DropdownField
                                isDisabled={
                                  employeeWatch === null ? true : false
                                }
                                control={control}
                                name="userRole"
                                placeholder="Role"
                                dataArray={userRoles}
                                isSearchable={false}
                                isMulti={true}
                                error={errors?.userRole}
                              />
                              <div className="text-sm text-red-500">
                                {roleErrorMessage}
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr className="w-full   border-b-1 border-customBlue" />
                      </>
                    </>
                  </>
                </fieldset>
                {showSpinner ? (
                  <div className="flex justify-center">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div className="w-full px-4">
                    {functionalities?.length > 0 && selectedEmployee !== "" ? (
                      <>
                        <label className="tracking-wider font-bold text-gray-700">
                          Functionalities :
                        </label>
                        <div className=" w-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-slate-50">
                          {showSpinner ? (
                            <div className="flex justify-center">
                              <LoadingSpinner />
                            </div>
                          ) : (
                            <AssignFunctionalityUsers
                              functionalities={functionalities}
                              setFunctionalities={setFunctionalities}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div className="flex gap-4  justify-end mt-2 mr-4">
                  {props.edit ? (
                    <CancelButton
                      onClick={() => {
                        props.handleClose();
                        props.setEdit(false);
                        reset(defaultValues);
                        setIncomingData("");
                        setStoreDropdown();
                      }}
                    />
                  ) : (
                    <ResetButton
                      onClick={() => {
                        reset(defaultValues);
                        setEmployeeExist(false);
                        setStoreDropdown();
                      }}
                    />
                  )}

                  {props.edit ? (
                    <UpdateButton onClick={handleSubmit(onSubmitDataHandler)} />
                  ) : (
                    <button
                      // type="submit"
                      className="rounded px-3 bg-customGreen text-white h-9"
                      onClick={handleSubmit(onSubmitDataHandler)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </>
            </div>
          </Box>
        </Modal>

        <ConfirmationModal
          confirmationOpen={openPut}
          confirmationHandleClose={handleClosePut}
          confirmationSubmitFunc={updateRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to update this record ?"
          confirmationButtonMsg="Update"
        />

        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />
      </form>
    </>
  );
}
