import { Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { savePharmacyCompanySettlement } from "../../../services/pharmacy/pharmacyCompanySettlement/companyServices";
import BillInfoModal from "../../purchase/partySettlement/BillInfoModal";
import ListOfPendingBillsTable from "./ListOfPendingBillsTable";
import {
  getCompanyOptions,
  getCompanyPendingBills
} from "./PharmacyComapanyServices";
import SettlementModal from "./SettlementModal";

const companyType = [
  {
    value: "OPD",
    label: "OPD",
    id: 0,
  },
  {
    value: "IPD",
    label: "IPD",
    id: 1,
  },
  {
    value: "Both",
    label: "Both",
    id: 2,
  },
];

function PharmacyCompanySettlement() {
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [listOfPendingBills, setListOfPendingBills] = React.useState({});
  const [selectedBill, setSelectedBill] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [amountPayable, setAmountPayable] = React.useState(null);
  const [opdIpdExternalId, setOpdIpdExternalId] = React.useState(null);
  const [salesId, setSalesId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [billIdsArray, setBillIdsArray] = React.useState([]);
  const [totalBillAmount, setTotalBillAmount] = React.useState(0);
  const [resetCount, setResetCount] = React.useState(0);
  const [openSettledBillsModal, setOpenSettledBillsModal] =
    React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState("");

  let location = useLocation();
  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
        console.log(
          "the actions object in the company settlement is :",
          res.result
        );
      });
  }, []);

  const handleCloseSettledBillsModal = () => {
    if (openSettledBillsModal === true) {
      setOpenSettledBillsModal(false);
    }
  };

  const closePaymentModal = () => {
    if (open === true) {
      setOpen(false);
    }
  };

  const defaultValues = {
    company: null,
    companyType: 0,
    totalAmount: "",
    tdsAmount: "",
    netAmount: "",
  };
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
  });

  const fnGetCompanyOptions = () => {
    getCompanyOptions()
      .then((response) => response.data)
      .then((res) => {
        setCompanyOptions(res.result);
      });
  };

  React.useEffect(() => {
    fnGetCompanyOptions();
  }, []);

  let companyTypeWatch = watch("companyType");
  let companyWatch = watch("company");
  let tdsAmountWatch = watch("tdsAmount");
  let actions = ["delete"];
  let patientAdvanceDetails = 0;
  let selectedPatient = "";
  let companyId = 0;

  const handleGetPendingBills = () => {
    let searchObject = {
      companyId: companyWatch?.id,
      fromDate: selectedFromDate,
      opdIpdBoth: companyTypeWatch,
      toDate: selectedToDate,
    };
    getCompanyPendingBills(searchObject)
      .then((response) => response.data)
      .then((res) => {
        setListOfPendingBills(res);
      });
  };

  React.useEffect(() => {
    let totalBillAmount = 0;
    let billIds = [...billIdsArray];
    if (selectedBill.length > 0) {
      for (let bill of selectedBill) {
        billIds.push(bill.BillId);
        setBillIdsArray(billIds);
        totalBillAmount =
          Number(totalBillAmount) + Number(bill.BalanceAmountNonSelf);
        setValue("totalAmount", totalBillAmount);
        setValue("netAmount", totalBillAmount);
        setTotalBillAmount(totalBillAmount);
        setAmountPayable(totalBillAmount);
      }
    }
  }, [selectedBill]);

  React.useEffect(() => {
    if (tdsAmountWatch > totalBillAmount) {
      setError("tdsAmount", {
        type: "custom",
        message: "Amount Exceeds Settlement Amount.",
      });
    } else {
      clearErrors("tdsAmount");
    }
  }, [tdsAmountWatch]);

  const handleApplyTdsAmount = () => {
    if (tdsAmountWatch > 0 && tdsAmountWatch <= totalBillAmount) {
      let netAmountVal = Number(totalBillAmount) - Number(tdsAmountWatch);
      setValue("netAmount", netAmountVal);
      setAmountPayable(netAmountVal);
      clearErrors("tdsAmount");
    } else if (tdsAmountWatch === 0 || tdsAmountWatch === "") {
      errorAlert("TDS Amount Is Empty.");
    }
  };

  const handleReset = () => {
    reset();
    setListOfPendingBills({});
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
    setSelectedBill([]);
    setAmountPayable(0);
    setValue("netAmount", "");
    setValue("tdsAmount", "");
    setValue("totalAmount", "");
  };

  const submitPaymentInfoModal = (
    paymentInfoListObj,
    concessionAuthorisedById,
    isSettledFlagVal,
    companyIdValue,
    patientAdvanceIdValue,
    consumedAdvanceVal
  ) => {
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));

    let paymentArray = [];
    for (let paymentObject of paymentInfoListObj.paymentInfoList) {
      let obj = {
        amount: paymentObject.amount > 0 ? paymentObject.amount : 0,
        bankId: paymentObject.bankId ? paymentObject.bankId : 0,
        paymentDate: paymentObject.paymentDate
          ? paymentObject.paymentDate
          : new Date(),
        paymentType: paymentObject.paymentType
          ? paymentObject.paymentType
          : null,
        referenceNumber: paymentObject.referenceNumber
          ? paymentObject.referenceNumber
          : "",
        remarks: paymentObject.remarks ? paymentObject.remarks : "",
        upiTypeId: paymentObject.upiTypeId ? paymentObject.upiTypeId : 0,
        chequeAuthorisedBy: paymentObject.chequeAuthorizedById
          ? paymentObject.chequeAuthorizedById
          : 0,
      };
      paymentArray.push(obj);
    }

    let postObj = {
      privilege: privilege,
      menuId: location?.state?.menuId,
      addedBy: loggedUser?.userId,
      billId: billIdsArray,
      cashCounterId: loggedUser?.cashCounterId,
      paymentInfoList: paymentArray,
      tdsAmount: tdsAmountWatch > 0 ? tdsAmountWatch : 0,
    };

    savePharmacyCompanySettlement(postObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setSelectedBill([]);
        setBillIdsArray([]);
        setListOfPendingBills();
        setTotalBillAmount(0);
        setResetCount(resetCount + 1);
        reset();
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const onSubmitDataHandler = (data) => {
    if (selectedBill !== "" && !errors.tdsAmount && amountPayable > 0) {
      setOpen(true);
    } else if (selectedBill.length === 0) {
      errorAlert("Please Select At Least One Record.");
    }
  };

  return (
    <>
      <from className="mt-14 ">
        <div className="mt-14 text-xl font-semibold flex justify-center">
          Company Settlement
        </div>
        <Grid
          container
          spacing={1}
          sx={{ marginY: 2 }}
          className="items-center"
        >
          <Grid item lg={3} sm={6}>
            <div className="flex gap-2 items-center">
              <label className="text-sm font-semibold">Company Type :</label>
              <div className="mt-[2px]">
                <RadioField
                  control={control}
                  name="companyType"
                  dataArray={companyType}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={3.5} sm={6}>
            <DropdownField
              control={control}
              name="company"
              label="Company"
              placeholder="Company"
              dataArray={companyOptions}
              menuPlacement="bottom"
            />
          </Grid>
          <Grid item lg={2} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                // disablePast
                label="From Date"
                value={selectedFromDate}
                name="fromDate"
                onChange={(newValue) => {
                  setSelectedFromDate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    fullWidth
                    name="fromDate"
                    size="small"
                    defaultValue=""
                    {...params}
                    sx={{
                      svg: {
                        color: "#1e3a8a",
                        height: 22,
                        width: "100%",
                        marginRight: "16px",
                      },
                      backgroundColor: "white",
                      overflow: "visible",

                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-input": {
                          // border: 0,
                          fontSize: 14,
                          height: "18px",
                          width: "100%",

                          borderColor: "  ",
                          overflow: "hidden",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={2} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                // disablePast
                label="To Date"
                value={selectedToDate}
                name="toDate"
                onChange={(newValue) => {
                  setSelectedToDate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    fullWidth
                    name="toDate"
                    size="small"
                    defaultValue=""
                    {...params}
                    sx={{
                      svg: {
                        color: "#1e3a8a",
                        height: 22,
                        width: "100%",
                        marginRight: "16px",
                      },
                      backgroundColor: "white",
                      overflow: "visible",

                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-input": {
                          // border: 0,
                          fontSize: 14,
                          height: "18px",
                          width: "100%",

                          borderColor: "  ",
                          overflow: "hidden",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={0.5} sm={1}>
            
            <CommonButton
            searchIcon={true}
            className=" px-2 rounded-md bg-customBlue text-white"
            onClick={handleGetPendingBills}
            type="button"
            />
          </Grid>
          <Grid item lg={1} sm={1}>
            {userActions.map((actions) => (
              <>
                {!actions.isAction ? (
                  <>
                    {actions.action === "View" ? (
                      <div
                        className=""
                        onClick={() => {
                          setPrivilege(actions.action);
                        }}
                      >
                       
                        <CommonButton
                         type="button"
                         className="px-2 ml-4 rounded h-9 bg-customBlue text-white"
                         onClick={() => {
                           setOpenSettledBillsModal(true);
                         }}
                         label="View"
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </Grid>
        </Grid>
        {listOfPendingBills?.result?.length > 0 ? (
          <div>
            <ListOfPendingBillsTable
              data={listOfPendingBills}
              actions={actions}
              setSelectedBill={setSelectedBill}
              setSalesId={setSalesId}
              setOpdIpdExternalId={setOpdIpdExternalId}
              resetCount={resetCount}
            />
          </div>
        ) : (
          <div className="flex justify-center my-5 text-lg font-semibold text-gray-400">
            No Records Found..!
          </div>
        )}
        {/* {amountPayable > 0 ? ( */}
        <Grid container spacing={2} sx={{ marginY: 2 }}>
          <Grid item lg={2} sm={4}>
            <fieldset disabled>
              <InputField
                control={control}
                name="totalAmount"
                label="Total Amount"
                placeholder="Total Amount"
              />
            </fieldset>
          </Grid>
          <Grid item lg={2} sm={4}>
            <InputField
              control={control}
              name="tdsAmount"
              label="TDS Amount"
              placeholder="TDS Amount"
              error={errors?.tdsAmount}
              type="number"
            />
          </Grid>
          <Grid item lg={1} sm={2}>
          
            <CommonButton
            label="Apply"
            type="button"
            className="px-2 rounded h-9 bg-customBlue text-white"
            onClick={handleApplyTdsAmount}
            />
          </Grid>

          <Grid item lg={2} sm={4}>
            <fieldset disabled>
              <InputField
                control={control}
                name="netAmount"
                label="Net Amount"
                placeholder="Net Amount"
              />
            </fieldset>
          </Grid>

          <Grid item lg={1} sm={2}>
            {userActions.map((actions) => (
              <>
                {!actions.isAction ? (
                  <>
                    {actions.action === "SettleBill" ? (
                      <div
                        className=""
                        onClick={() => {
                          setPrivilege(actions.action);
                        }}
                      >
                        <CommonButton
                          label="Settle Bill"
                          className="px-2 rounded h-9 bg-customBlue text-white"
                          type="button"
                          onClick={onSubmitDataHandler}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </Grid>

          <Grid item lg={1} sm={2}>
            <CommonButton
              className="px-2 rounded h-9 border border-customRed text-customRed"
              type="button"
              label="Reset"
              onClick={handleReset}
            />
          </Grid>
        </Grid>
        {/* ) : (
          ""
        )} */}
      </from>
      <BillInfoModal
        open={open}
        setOpen={setOpen}
        selectedPatient={selectedPatient}
        closePaymentModal={closePaymentModal}
        submitPaymentInfoModal={submitPaymentInfoModal}
        amountPayable={amountPayable}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        companyId={companyId}
        patientAdvanceDetails={patientAdvanceDetails}
        resetCount={resetCount}
      />
      <SettlementModal
        open={openSettledBillsModal}
        close={handleCloseSettledBillsModal}
      />
    </>
  );
}

export default PharmacyCompanySettlement;
