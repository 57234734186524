import React, { useEffect, useState } from "react";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { useForm } from "react-hook-form";
import { fetchPatientInfoForIPDFileTransfer } from "../../../taskManager/services/ipdFileRegisterService/IPDFileRegisterServices";
import {
  fetchPatientForDataMerge,
  postPatientDataMerge,
} from "../../services/patientDataMergeServices/PatientDataMergeServices";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";

export default function PatientDataMerge() {
  const defaultValues = {
    patientSearchNew: null,
    patientSearchOld: null,
  };
  const { control, watch, register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const patientSearchNewVal = watch("patientSearchNew");
  const patientSearchOldVal = watch("patientSearchOld");

  const [autocompleteData, setAutocompleteData] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const location = useLocation();
  const getPatientInfoAutoComplete = (serchString) => {
    serchString &&
      fetchPatientForDataMerge(serchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  function postIPDFileRegister(obj) {
    postPatientDataMerge(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        setOpenPost(false);
        reset(defaultValues);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postIPDFileRegister(finalData);
  }

  const onSubmitDataHandler = (data) => {
    console.log("Data", data);
    const postedObj = {
      menuId: location.state.menuId,
      newPatientId: patientSearchNewVal?.id,
      oldPatientId: patientSearchOldVal?.id,
      privilege: "create",
    };

    console.log(
      "patientSearchNewVal, patientSearchOldVal",
      patientSearchNewVal,
      patientSearchOldVal
    );
    if (patientSearchOldVal === null) {
      warningAlert("Please select old details...!");
    } else if (patientSearchNewVal === null) {
      warningAlert("Please select New details...!");
    } else if (patientSearchNewVal !== null && patientSearchOldVal !== null) {
      setOpenPost(true);
      setFinalData(postedObj);
    }
  };

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  console.log("location si:", userActions);

  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Patient Data Merge
      </h1>

      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-2 gap-2 py-2 pt-6">
          <div className="grid border-r h-full border-gray-600 mx-2">
            <span className="text-center font-semibold">Old Patient Details</span>
            <div className="grid grid-cols-3 gap-2 py-3">
              <div className="col-span-2">
                <SearchDropdown
                  control={control}
                  placeholder="Search By Patient Name / MR.No./Mob No."
                  dataArray={autocompleteData}
                  name="patientSearchOld"
                  searchIcon={true}
                  isClearable={true}
                  handleInputChange={getPatientInfoAutoComplete}
                />
              </div>
            </div>

            {/* Paitent Info */}
            <fieldset className=" border rounded bg-gray-100 mr-4 text-[14px] py-1">
              <div className="flex items-center gap-2 w-full px-3">
                <span className="font-semibold whitespace-nowrap w-24">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span className="text-gray-700 font-normal">
                    : {patientSearchOldVal?.patientName}
                  </span>
                </div>
              </div>
              <div className="flex space-s-2 ">
                <div className="flex items-center gap-2 w-full px-3">
                  <span className="font-semibold w-24">Age</span>
                  <div className="flex space-x-2 items-center">
                    <span className="text-gray-700 font-normal">
                      : {patientSearchOldVal?.AgeYear}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full px-3">
                  <span className="font-semibold w-14">Gender</span>
                  <div className="flex space-x-2 items-center">
                    <span className="text-gray-700 font-normal">
                      : {patientSearchOldVal?.Gender}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full px-3">
                <span className="font-semibold w-24">Address</span>
                <div className="flex space-x-2 items-center">
                  <span className="text-gray-700 font-normal">
                    : {patientSearchOldVal?.address}
                  </span>
                </div>
              </div>
            </fieldset>
          </div>

          <div className="grid">
            <span className="text-center font-semibold">New Patient Details</span>

            <div className="grid grid-cols-3 gap-2 py-3">
              <div className="col-span-2">
                <SearchDropdown
                  control={control}
                  placeholder="Search By Patient Name / MR.No./Mob No."
                  dataArray={autocompleteData}
                  name="patientSearchNew"
                  searchIcon={true}
                  isClearable={true}
                  handleInputChange={getPatientInfoAutoComplete}
                />
              </div>
            </div>
            {/* Paitent Info */}
            <fieldset className=" border rounded bg-gray-100 mr-4 text-[14px] py-1">
              <div className="flex items-center gap-2 w-full px-3">
                <span className="font-semibold whitespace-nowrap w-24">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span className="text-gray-700 font-normal">
                    : {patientSearchNewVal?.patientName}
                  </span>
                </div>
              </div>
              <div className="flex space-s-2 ">
                <div className="flex items-center gap-2 w-full px-3">
                  <span className="font-semibold w-24">Age</span>
                  <div className="flex space-x-2 items-center">
                    <span className="text-gray-700 font-normal">
                      : {patientSearchNewVal?.AgeYear}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full px-3">
                  <span className="font-semibold w-14">Gender</span>
                  <div className="flex space-x-2 items-center">
                    <span className="text-gray-700 font-normal">
                      : {patientSearchNewVal?.Gender}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full px-3">
                <span className="font-semibold w-24">Address</span>
                <div className="flex space-x-2 items-center">
                  <span className="text-gray-700 font-normal">
                    : {patientSearchNewVal?.address}
                  </span>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div className="grid justify-end" >
          <CommonButton
            type="submit"
            label="Merge"
            className="bg-customGreen text-white"
          />
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure you want to merge this patient data ?"
        confirmationButtonMsg="Save"
      />
    </div>
  );
}
