import { Box, Modal } from "@mui/material";
import React from "react";
import { fetchCancelAdmissionStatus } from "../../../services/admissionServices/AdmissionServices";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";

const CancelAdmissionConfirmation = (props) => {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleCancelAdmission = () => {
    setOpenBackdrop(true);

    fetchCancelAdmissionStatus(
      props.selectedRow.AdmissionId,
      props.menuId,
      props.privilege
    )
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);

          props.handleClose();
          props.CancelAdmissionDeleteRow();
          setOpenBackdrop(false);

        }
      });
  };
  return (
    <div>
      {/* /// modal */}
      <Modal open={props.open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Cancel Admission
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To Cancel Admission ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  label="Cancel"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  className="border border-customRed text-customRed "
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    label="Ok"
                    onClick={() => {
                      handleCancelAdmission();
                      props.setOpen(false);
                    }}
                    className="bg-customGreen text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
};

export default CancelAdmissionConfirmation;
