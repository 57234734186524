import { Circle } from "@mui/icons-material";
import { Card, CardContent } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getOpdPatientDateWiseAvgRating } from "../../../services/feedbackdashbordservice/FeedBackDashbbordService";

function OpdPatientFeedBackChart() {
  const [opdFeedbackData, setOpdFeedBackData] = useState([]);
  const color = ["#FF2A2A", "#E99566", "#DDE032", "#AADF12", "#239F21"];
  const yAxisLabelFormatter = (value) => {
    if (value === 0) {
      return "Dissatisfied";
    } else if (value === 1) {
      return "Poor";
    } else if (value === 2) {
      return "Average";
    } else if (value === 3) {
      return "Satisfied";
    } else if (value === 4) {
      return "Highly Satisfied";
    } else {
      return "";
    }
  };

  const getColorForLabel = (value) => {
    switch (value) {
      case 0:
        return "#FF2A2A";
      case 1:
        return "#E99566";
      case 2:
        return "#DDE032";
      case 3:
        return "#AADF12";
      case 4:
        return "#239F21";
      default:
        return "black";
    }
  };

  const CustomTooltip = ({ active, payload, label, avgChartArr }) => {
    if (active && payload && payload?.length) {
      const dataPoint = avgChartArr.find((data) => data.Date === label);
      if (dataPoint && "Total Feedback" in dataPoint) {
        return (
          <div className="custom-tooltip rounded border bg-white p-2 text-xs font-">
            <p className="label">Total Feedback</p>
            <p className="total-feedback text-center">
              {dataPoint["Total Feedback"]}
            </p>
          </div>
        );
      }
    }
    return null;
  };

  const avgChartArr =
    opdFeedbackData?.length > 0 &&
    opdFeedbackData?.map((obj) => {
      const totalFeedback = obj["Total Feedback"];

      const totalAvg =
        (Number(obj["Highly Satisfied"]) * 5 +
          Number(obj.Satisfied) * 4 +
          Number(obj.Average) * 3 +
          Number(obj.Poor) * 2 +
          Number(obj.Dissatisfied) * 1) /
        Number(totalFeedback);

      return {
        Date: obj.Date,
        "Highly Satisfied": obj["Highly Satisfied"],
        Satisfied: obj.Satisfied,
        Average: obj.Average,
        Poor: obj.Poor,
        Dissatisfied: obj.Dissatisfied,
        "Total Feedback": totalFeedback,
        totalAvg: Number(totalAvg.toFixed(2)),
      };
    });

  const CustomLabel = (props) => {
    const { x, y, value, totalFeedback, totalAvg } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fontSize="12px"
          fontFamily="Poppins"
          fill="#666"
        >
          {`${value} - Total Feedback: ${totalFeedback}, Total Avg: ${totalAvg}`}
        </text>
      </g>
    );
  };

  useEffect(() => {
    getOpdPatientDateWiseAvgRating()
      .then((response) => {
        if (response.data.statusCode === 200) {
          setOpdFeedBackData(response.data.result);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  }, []);

  return (
    <div>
      <Card
        elevation={2}
        sx={{
          borderRadius: "10px",
          width: "100%",
          border: "1px solid lightgray",
        }}
      >
        <CardContent>
          <h6 className=" font-bold text-gray-800 text-base font-Poppins">
            OPD Patient Feedback
            <span className="text-[#FF740F]"> (Average day-wise)</span>
          </h6>
          {avgChartArr?.length > 0 ? (
            <ResponsiveContainer width="100%" height={220}>
              {/* <AreaChart
                width={600}
                height={300}
                data={avgChartArr}
                margin={{ top: 1, right: 10, left: 40, bottom: 5 }}
              >
                <defs>
                  <linearGradient id="colorUvIpd" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="60%" stopColor="#3DCA65" stopOpacity={0.5} />
                    <stop offset="100%" stopColor="#8884d8" stopOpacity={0.2} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="Date"
                  axisLine={true}
                  style={{ fontSize: "12px", fontFamily: "Poppins" }}
                />
                <YAxis
                  axisLine={true}
                  allowDecimals={false}
                  style={{
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    whiteSpace: "nowrap",
                  }}
                  tick={(props) => {
                    return (
                      <text
                        y={props.y}
                        fontSize="12px"
                        fontFamily="Poppins"
                        fill={getColorForLabel(props.payload.value)}
                      >
                        {yAxisLabelFormatter(props.payload.value)}
                      </text>
                    );
                  }}
                  tickMargin={2}
                />
                <Tooltip
                  content={(props) => (
                    <CustomTooltip {...props} avgChartArr={avgChartArr} />
                  )}
                />

                <Legend
                  iconType={Circle}
                  iconSize={10}
                  verticalAlign="top"
                  height={36}
                  wrapperStyle={{ fontSize: "1rem", fontFamily: "Poppins" }}
                  content={() => null}
                />
                <Area
                  type="monotone"
                  dataKey="totalAvg"
                  fill="url(#colorUvIpd)"
                  stroke={color}
                  fillOpacity={1}
                  dot={{
                    stroke: { color },
                    fill: { color },
                    r: 4,
                  }}
                  data={avgChartArr.filter(
                    (data) => data["Total Feedback"] > 0
                  )}
                />
                {avgChartArr
                  .filter((data) => data["Total Feedback"] > 0)
                  .map((obj, index) => (
                    <Label
                      key={`label-${index}`}
                      content={(props) => (
                        <CustomLabel
                          {...props}
                          totalFeedback={obj["Total Feedback"]}
                          totalAvg={obj.totalAvg}
                        />
                      )}
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    />
                  ))}
              </AreaChart> */}
              <AreaChart
                width={600}
                height={300}
                data={avgChartArr}
                margin={{ top: 1, right: 10, left: 40, bottom: 5 }}
              >
                <defs>
                  <linearGradient id="colorUvOpd" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3DCA65" stopOpacity={0.5} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0.2} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="Date"
                  axisLine={true}
                  style={{ fontSize: "12px", fontFamily: "Poppins" }}
                />
                <YAxis
                  axisLine={true}
                  allowDecimals={false}
                  style={{
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    whiteSpace: "nowrap",
                  }}
                  tick={(props) => {
                    return (
                      <text
                        y={props.y}
                        fontSize="12px"
                        fontFamily="Poppins"
                        fill={getColorForLabel(props.payload.value)}
                      >
                        {yAxisLabelFormatter(props.payload.value)}
                      </text>
                    );
                  }}
                  tickMargin={2}
                />
                <Tooltip
                  content={(props) => (
                    <CustomTooltip {...props} avgChartArr={avgChartArr} />
                  )}
                />
                <Legend
                  iconType={Circle}
                  iconSize={10}
                  verticalAlign="top"
                  height={36}
                  wrapperStyle={{ fontSize: "1rem", fontFamily: "Poppins" }}
                  content={() => null}
                />
                <Area
                  type="monotone"
                  dataKey="totalAvg"
                  fill="url(#colorUvOpd)"
                  stroke={color}
                  fillOpacity={1}
                  dot={{
                    stroke: { color },
                    fill: { color },
                    r: 4,
                  }}
                  data={avgChartArr.filter(
                    (data) => data["Total Feedback"] > 0
                  )}
                />
                {avgChartArr
                  .filter((data) => data["Total Feedback"] > 0)
                  .map((obj, index) => (
                    <Label
                      key={`label-${index}`}
                      content={(props) => (
                        <CustomLabel
                          {...props}
                          totalFeedback={obj["Total Feedback"]}
                          totalAvg={obj.totalAvg}
                        />
                      )}
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    />
                  ))}
              </AreaChart>
            </ResponsiveContainer>
          ) : (
            <div className=" py-[98px] flex justify-center font-semibold text-gray-600 text-sm">
              <p className="my-auto ">
                Patient Details Are Not Available
                <span className="animate-pulse tracking-wider">...</span>
              </p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default OpdPatientFeedBackChart;
