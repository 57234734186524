import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchReturnDeptIndent,
  fetchReturnDeptIndentList,
} from "../../../services/pharmacy/returnFromDeptServices/ReturnFromDeptServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "70%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const Actions = [
  {
    id: 0,
    action: "checkbox",
    isAction: true,
  },

  {
    id: 1,
    action: "select",
    isAction: false,
  },
];

export default function GetIndentModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const [searchString, setSearchString] = React.useState("");

  //tbl-1
  const [indentList, setIndentList] = React.useState([]);
  const [selectedID, setSelectedID] = useState(null);

  //tbl-2
  const [indentListItems, setIndentListItems] = React.useState([]);
  // //selected from get indent table-2

  //from Store
  const [fromStore, setFromStore] = useState();
  //from selected FromStore id
  const [selectedFromStore, setSelectedFromStore] = useState();

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);

  const {
    open,
    handleClose,
    //
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      isUrgentRadio: 2,
      fromDate: new Date(),
      toDate: new Date(),
    },
  });
  const { control, register, watch } = methods;
  ///watch
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  useEffect(() => {
    getStore();
  }, []);

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, selectedFromStore, searchString]);

  const populateTable = (forPagination) => {
    const indentObj = {
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromStoreId: selectedFromStore,
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      toStoreId: token.storeId,
      searchString: searchString,
      //
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchReturnDeptIndentList(indentObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIndentList((prevData) => [...prevData, ...res.result]);
          setIndentListItems([]);
        } else {
          setIndentList(res.result);
          setPage(0);
          setSelectedID(null);
          setIndentListItems([]);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      });
  };

  // storename dropdown

  // storename dropdown
  const getStore = () => {
    fetchApplicableStores(token.storeId)
      .then((response) => response.data)
      .then((res) => {
        setFromStore(res.result);
      });
  };

  // patientId

  //add selected items in item details table
  const AddSelectedIndentItems = () => {
    const newArr = indentListItems.filter((item) => item.isChecked);

    props.setItemDetails(newArr);

    handleClose();
  };

  const RenderActions = (row, index) => {
    const getIndentList = (row) => {
      if (row !== null) {
        fetchReturnDeptIndent(row.IssToStoreId)
          .then((response) => response.data)
          .then((res) => {
            let updatedResult = res.result.map((item) => ({
              ...item,
              isChecked: true,
            }));
            setIndentListItems(updatedResult);
          });
      }
    };
    return (
      <div className="flex items-center">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "checkbox" ? (
                    <Checkbox
                      size="small"
                      className="border  border-gray-600 rounded bg-transparent"
                      type="checkbox"
                      color="primary"
                      checked={selectedID === index ? true : false}
                      onClick={(e) => {
                        setSelectedID(index);
                        getIndentList(row);
                      }}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          {/* search */}
          <div className="grid gap-2 w-full items-center mt-2">
            <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center">
              <div className="w-full col-span-3 lg:col-span-4">
                <SearchBar
                  // type="button"
                  name="SearchableSelect"
                  placeholder="Search by Indent Number."
                  // dataArray={options}
                  handleInputChange={(e) => setSearchString(e)} //searchString
                  // onChange={autoSelectedValue} // serachId
                />
              </div>

              <div className="grid grid-cols-2 col-span-3 gap-3">
                {/* from Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* to Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="md:col-span-2 lg:col-span-3 grid grid-cols-2 gap-3">
                <div>
                  <DropdownField
                    control={control}
                    // error={errors.prefix}
                    name="fromStore"
                    dataArray={fromStore}
                    placeholder="From Store"
                    isSearchable={false}
                    isClearable={false}
                    inputRef={{
                      ...register("fromStore", {
                        onChange: (e) => {
                          setSelectedFromStore(e.target.value.id);
                        },
                      }),
                    }}
                  />
                </div>
                {/* <div>
                  <InputField
                    name="indentNumber"
                    variant="outlined"
                    label="Indent Number"
                    // error={errors.firstName}
                    control={control}
                    inputProps={{
                      style: { textTransform: "capitalize" },
                    }} // use inputProps props for return 1st letter in upper case
                  />
                </div> */}
              </div>
              {/* <div>
                <InputField
                  name="indentNumber"
                  variant="outlined"
                  label="Indent Number"
                  // error={errors.firstName}
                  control={control}
                  inputProps={{
                    style: { textTransform: "capitalize" },
                  }} // use inputProps props for return 1st letter in upper case
                />
              </div> */}
              {/* is urgent */}
              {/* <div className="flex md:justify-start lg:justify-center col-span-1 lg:col-span-2 ">
                <RadioField
                  label=""
                  name="isUrgentRadio"
                  control={control}
                  dataArray={IsUrgent}
                />
              </div> */}
            </div>
          </div>

          {/* tables */}
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-2">
              {indentList?.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={indentList}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  renderActions={RenderActions}
                  tableClass={"rounded lg:h-96 md:h-72"}
                  // highlightRow={false}
                  removeHeaders={[
                    "AdmNo",
                    "AdmissionId",
                    "Cash_Credit",
                    "DrId",
                    "FromStoreId",
                    "IndentId",
                    "PatientId",
                    "isprinted",
                    "VATPercentage",
                  ]}
                />
              ) : (
                <p className="text-center my-6 ">No Indent Found</p>
              )}
            </div>
            <div className="col-span-2">
              {indentListItems?.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={indentListItems}
                  //renderInput={renderInput}
                  highlightRow={false}
                  enableSelectAll={true}
                  removeHeaders={[""]}
                  tableClass={"rounded lg:h-96 md:h-72"}
                  setDataResult={setIndentListItems}
                />
              ) : (
                <p className="text-center my-6 ">No Items Found</p>
              )}
            </div>
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
            <div></div>
            <div>
              {Actions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "select" ? (
                    <div>
                      {/* <button
                        type="submit"
                        className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium"
                        onClick={AddSelectedIndentItems}
                      >
                        Select
                      </button> */}

                      <CommonButton
                        label="Select"
                        onClick={AddSelectedIndentItems}
                        className="bg-customGreen text-white "
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </div>
            <div>
              {/* <button
                type="submit"
                className="h-9 px-3 text-base font-medium  bg-red-700 text-white rounded "
                onClick={() => handleClose()}
              >
                Close
              </button> */}

              <CommonButton
                type="submit"
                label="Close"
                onClick={() => handleClose()}
                className="border border-customRed text-customRed"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
