import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
//Field
import { TextField } from "@mui/material";
import CreateableSelect from "../../../../common/components/FormFields/CreateableSelect";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import InputLimit from "../../../../common/components/FormFields/InputLimit";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
//Buttons
import CommonButton from "../../../../common/components/Buttons/CommonButton";
//Modal
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import EnquiryModal from "./EnquiryList/EnquiryModal";
import QuoationListModal from "./QuotationList/QuoationListModal";
//Table
import TermsNConditionsTable from "../enquiry/TermsNConditionsTable";
import PurchaseQuotationTable from "./PurchaseQuotationTable";
//Alert
import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { searchSupplier } from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import {
  fetchAllEnquiry,
  fetchStore,
  fetchTermsOfPayment,
  itemDetailsSearch,
} from "../../../services/purchase/EnquiryServices";
import { addNewQuotation } from "../../../services/purchase/QuotationServies";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";

export default function Quotation() {
  const schema = yup.object().shape({
    supplierMasters: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Please Select Supplier"),
        label: yup.string().required("Please Select Supplier"),
      }),
    store: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Please Select Supplier"),
        label: yup.string().required("Please Select Supplier"),
      }),
  });

  const defaultValues = {
    supplierMasters: null,
    enquiry: null,
    store: null,
    itemMaster: null,
    uom: "",
    rate: "",
    quantity: "",
    amount: "",
    taxPer: 0,
    taxAmount: "",
    concessionPer: 0,
    concessionAmount: "",
    netAmount: "",
    remark: "",
    termsAndConditions: "",
    note: "",
    other: 0,
    totalNetAmount: "",
    finalAmount: "",
    itemSearch: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    register,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [itemOptions, setItemOption] = React.useState();
  const [storeOption, setStoreOptions] = React.useState([]);
  const [supplierOptions, setSupplierOptions] = React.useState([]);
  const [termsnConditionsOptions, setTermsnConditionsOptions] = React.useState(
    []
  );
  const [itemMasterVal, setItemMasterVal] = React.useState(null); //getting Stocking UOM Value
  const [finalData, setFinalData] = React.useState({});
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [termsnConditionData, setTermsnConditionData] = React.useState([]);
  const [quotationdata, setQuotationData] = React.useState([]);
  ////////////Enquiry Modal Enquiry List In Quotation)//////////////
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [searchEnquiryString, setEnquirySearchString] = React.useState("");
  const [enquirySupplierId, setEnquirySupplierId] = React.useState(0);
  const [dataResultEnquiryList, setDataResultEnquiryList] = React.useState([]);
  const [selectedEnquiryListFromDate, setSelectedEnquiryListFromDate] =
    React.useState(new Date());
  const [selectedEnquiryListToDate, setSelectedEnquiryListToDate] =
    React.useState(new Date());
  //Enquiry Modal State
  const [openEnquiryModal, setOpenEnquiryModal] = React.useState(false);
  const handleEnquiryModalOpen = () => setOpenEnquiryModal(true);
  const handleEnquiryModalClose = () => setOpenEnquiryModal(false);
  //Quotation Modal State
  const [quotationListModal, setQuotationListModal] = React.useState(false);
  const handleQuotationListOpen = () => setQuotationListModal(true);
  const handleQuotationListClose = () => setQuotationListModal(false);
  /////////////////////State For Getting Row////////////////////
  const [enquiryItemRow, setEnquiryItemRow] = React.useState(null);
  /////////////////////Line Item Edit Enquiry Modal //////////////////
  const [enquiryEdit, setEnquiryEdit] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = React.useState([]);
  const location = useLocation();
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const getStore = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = getStore.storeId;
  let storeVal = watch("store");
  let deptStore1 = storeVal && storeVal.id;
  let itemMaster = watch("itemMaster");
  let uom = watch("uom");
  let rate = watch("rate");
  let amount = watch("amount");
  let quantity = watch("quantity");
  let gstPercentage = watch("taxPer");
  let gstAmount = watch("taxAmount");
  let discPercentage = watch("concessionPer");
  let otherAmount = watch("other");

  const populateTable = (forPagination) => {
    let defaultParams = {
      fromDate: selectedEnquiryListFromDate,
      searchString: searchEnquiryString,
      supplierId: enquirySupplierId,
      toDate: selectedEnquiryListToDate,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    fetchAllEnquiry(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setDataResultEnquiryList((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResultEnquiryList(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  React.useEffect(() => {
    if (enquiryItemRow !== null) {
      let SupplierNameObj = {
        id: enquiryItemRow?.SupplierId,
        label: enquiryItemRow && enquiryItemRow["Supplier Name"],
        value: enquiryItemRow?.SupplierId,
      };

      setValue("supplierMasters", SupplierNameObj);
    }
  }, [enquiryItemRow]);

  React.useEffect(() => {
    if (itemMasterVal !== null) {
      setValue("uom", itemMasterVal.StockingUOM);
    }
  }, [itemMasterVal]);

  React.useEffect(() => {

      fetchApplicableStores(storeId)
      .then((response) => response.data)
      .then((res) => {
        setStoreOptions(res.result);
      });

    fetchTermsOfPayment()
      .then((response) => response.data)
      .then((res) => {
        setTermsnConditionsOptions(res.result);
      });
  }, []);

  //Search Dropdown API
  const handleChange = (autoSearchItemString) => {
    let obj = {
      deptStore: 1,
      deptStoreId: getStore.storeId,
      deptStore1: deptStore1,
      isConsignment: 0,
      itemClass: 0,
      itemCode: "%",
      itemName: "%",
      searchString: autoSearchItemString,
      storePatient: 0,
    };
    if(autoSearchItemString !== "" && UseCheckConsecutiveCharacters(autoSearchItemString)) {
    itemDetailsSearch(obj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setItemOption(res.result);
      });
    }
  };

  const handleItemMasterChange = () => {
    let itemMaster = watch("itemMaster");
    if (itemMaster) {
      setItemMasterVal(itemMaster);
    }
  };

  //Searchable Supplier Api
  const handleSupplierChange = (autoSearchSupplier) => {
    if (autoSearchSupplier !== "" && UseCheckConsecutiveCharacters(autoSearchSupplier)) {
      searchSupplier(autoSearchSupplier)
        .then((response) => response.data)
        .then((res) => {
          setSupplierOptions(res.result);
        })
        .catch((error) => {});
    }
  };

  //////////////////////////EnquiryModal(Enquiry List In Quotation)/////////////////////////
  React.useEffect(() => {
    if (openEnquiryModal === true) {
      populateTable();
    }
  }, [searchEnquiryString, enquirySupplierId]);

  const duplicateTermsCondArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex(
        (item) => item.termsNConditions.id === val.termsNConditions.id
      );
      if (ind !== -1) {
        errorAlert("Terms & Condition Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  /// terms n condition useFied Array add function
  const addTermsNConditionData = () => {
    let termsNConditions = watch("termsNConditions");

    if (termsNConditions !== null && termsNConditions !== undefined) {
      let termsnConditionObj = {
        termsNConditions: termsNConditions,
      };
      let temp = [...termsnConditionData];
      temp.push(termsnConditionObj);
      setTermsnConditionData(duplicateTermsCondArray(temp));
    }

    if (termsNConditions === null) {
      setError("termsNConditions", { type: "custom", message: "Required" });
    } else if (termsNConditions !== null) {
      clearErrors(["termsNConditions"]);
    }
  };

  //Line Item Edit Funtionality of Enquiry Modal
  function editRow(requiredRow, index) {
    setEnquiryEdit(true);
    let itemObj = {
      id: requiredRow["ItemId"] || requiredRow["itemMaster"].id,
      label: requiredRow["ItemName"] || requiredRow["itemMaster"].label,
      value: requiredRow["ItemId"] || requiredRow["itemMaster"].id,
    };

    setValue("itemMaster", itemObj);
    setValue("uom", requiredRow.PurchaseUOM || requiredRow.uom);
    setValue("quantity", requiredRow["Qty"] || requiredRow["quantity"]);
    setValue("rate", requiredRow["Rate"] || requiredRow["rate"]);
    setValue("amount", requiredRow["amount"]);
    setValue("taxPer", requiredRow["gstPercentage"]);
    setValue("taxAmount", requiredRow["gstPercentage"]);
    setValue("taxAmount", requiredRow["gstAmount"]);
    setValue("concessionPer", requiredRow["concessionPercentage"]);
    setValue("concessionAmount", requiredRow["concessionAmount"]);
    setValue("netAmount", requiredRow["netAmount"]);
    setValue("remark", requiredRow["Remarks"] || requiredRow["remark"]);
    setSelectedIndex(index);
  }

  const duplicateArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.id === val.id);
      if (ind !== -1) {
        errorAlert("Item Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const addQuotationItemData = () => {
    let amount = getValues("amount");
    let taxAmount = getValues("taxAmount");
    let concessionPer = getValues("concessionPer");
    let conceAmount = getValues("concessionAmount");
    let netAmount = getValues("netAmount");
    let taxPer = getValues("taxPer");
    let remark = getValues("remark");

    if (itemMaster !== null && quantity !== "" && rate !== "") {
      let itemObj = {
        itemMaster: {
          id: itemMaster.id,
          label: itemMaster.label,
        },
        id: itemMaster.id,
        uom: uom,
        rate: Number(rate).toFixed(2),
        amount: Number(amount).toFixed(2),
        gstPercentage: Number(taxPer).toFixed(2),
        gstAmount: Number(taxAmount).toFixed(2),
        concessionPercentage: Number(concessionPer).toFixed(2),
        concessionAmount: Number(conceAmount).toFixed(2),
        netAmount: Number(netAmount).toFixed(2),
        // mrp: mrp,
        quantity: Number(quantity).toFixed(2),
        remark: remark,
      };

      if (enquiryEdit === false) {
        let arr = [...quotationdata];
        arr.push(itemObj);
        setQuotationData(duplicateArray(arr));
      } else if (enquiryEdit === true) {
        let arr = [...quotationdata];
        arr.splice(selectedIndex, 1, itemObj);
        setQuotationData(arr);
        setEnquiryEdit(false);
      }
    }

    if (itemMaster === null && quantity === "" && rate === "") {
      setError("itemMaster", { type: "custom", message: "Required" });
      setError("quantity", { type: "custom", message: "Required" });
      setError("rate", { type: "custom", message: "Required" });
      // setError("uom", { type: "custom", message: "Required" });
    } else if (itemMaster === null) {
      setError("itemMaster", { type: "custom", message: "Required" });
    } else if (quantity === "") {
      setError("quantity", { type: "custom", message: "Required" });
    } else if (rate === "") {
      setError("rate", { type: "custom", message: "Required" });
    }
    // else if (uom === "") {
    //   setError("uom", { type: "custom", message: "Required" });
    // }
  };

  function postQuotation(obj) {
    addNewQuotation(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setOpenBackdrop(false);
          successAlert(response.data.message);
          // populateTable();
          reset(defaultValues);
          setQuotationData([]);
          setTermsnConditionData([]);
          setEnquiryItemRow(null);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }
  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postQuotation(finalData);
  }

  let purchaseQuotationDetailsDtoList = [];
  for (let obj of quotationdata) {
    let requiredObj = {
      amount: Number(obj.amount),
      concession: Number(obj.concessionAmount),
      excise: Number(0),
      itemId: obj.itemMaster ? obj.itemMaster.id : obj.ItemId,
      netAmount: Number(obj.netAmount),
      quantity: Number(obj.quantity) || Number(obj.Qty),
      rate: Number(obj.rate) || Number(obj.Rate),
      remark: obj.remark || obj.Remarks,
      tax: Number(obj.gstAmount),
    };

    purchaseQuotationDetailsDtoList.push(requiredObj);
  }

  // calculate total amount and total net amount pass to main object
  let finalTotalAmount = 0;
  for (var i = 0; i < purchaseQuotationDetailsDtoList.length; i++) {
    finalTotalAmount += purchaseQuotationDetailsDtoList[i].amount;
  }
  //Calculate Total Net Amount
  let finalNetAmount = 0;
  for (var i = 0; i < purchaseQuotationDetailsDtoList.length; i++) {
    finalNetAmount += purchaseQuotationDetailsDtoList[i].netAmount;
  }

  let finalAmount = Number(finalNetAmount) + Number(otherAmount);

  if (finalAmount !== "") {
    setValue("finalAmount", Number(finalAmount));
  } else if (finalNetAmount === "" && otherAmount === "") {
    setValue("finalAmount", 0);
  }
  useEffect(() => {
    setValue("totalNetAmount", finalNetAmount);
  }, [finalNetAmount]);

  const onSubmitDataHandler = (data) => {
    let postObj = {
      menuId: location?.state?.menuId,
      privilege: privilege,
      addedBy: getStore.userId,
      amount: Number(finalTotalAmount),
      contactPerson: "",
      enquiryNo:
        enquiryItemRow?.EnquiryNo !== null ? enquiryItemRow?.EnquiryNo : 0,
      netAmount: Number(finalNetAmount),
      note: data.note,
      purchaseQuotationDetailsDtoList: purchaseQuotationDetailsDtoList,
      // // qdate:
      storeId: deptStore1,
      supplierId: data.supplierMasters.id,
    };

    setOpenPost(true);
    setFinalData(postObj);
  };

  function dontAllowOnKeyUpDownSpecialChar(e) {
    if (
      e?.key === "e" ||
      e?.key === "-" ||
      e?.key === "+" ||
      e?.code === "ArrowUp" ||
      e?.code === "ArrowDown" ||
      e?.code === "NumpadDecimal"
    ) {
      e.preventDefault();
    }
  }

  React.useEffect(() => {
    let amount = Number(rate) * Number(quantity);
    setValue("amount", Number(amount).toFixed(2));

    let gstTaxAmount = (amount / 100) * Number(gstPercentage);
    setValue("taxAmount", Number(gstTaxAmount).toFixed(2));

    let discAmount = Number(amount / 100) * Number(discPercentage);
    setValue("concessionAmount", Number(discAmount).toFixed(2));

    let amountMconceession = Number(Number(amount) - Number(discAmount));
    let netAmount =
      amountMconceession +
      // Number(exciseVal) +
      Number(gstTaxAmount);
    setValue("netAmount", Number(netAmount).toFixed(2));
  }, [rate, quantity, discPercentage, gstPercentage]);

  return (
    <div className="pt-14">
      <span className="grid justify-center text-center text-black font-bold text-xl my-auto ">
        Quotation
      </span>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-5 gap-3 py-3">
          <div className="col-span-4 flex gap-3">
            <SearchDropdown
              control={control}
              name="supplierMasters"
              placeholder="Supplier*"
              error={errors.supplierMasters}
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              dataArray={supplierOptions}
              isMulti={false}
              handleInputChange={handleSupplierChange}
            />
            <DropdownField
              control={control}
              name="store"
              error={errors.store}
              dataArray={storeOption}
              placeholder="Store"
              isMulti={false}
              isSearchable={false}
              isClearable={false}
            />
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Enquiry" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="Enquiry"
                      onClick={() => {
                        handleEnquiryModalOpen();
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Quotation List" ? (
                <>
                  <div className="grid justify-end ">
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="Quotation List"
                      onClick={() => {
                        handleQuotationListOpen();
                      }}
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>

        <hr className="w-full border-t-1 border-blue-800" />

        <div className="gap-2 grid grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-9 py-3">
          <div className="flex gap-2 col-span-3 xl:col-span-3 text-xl text-gray-700 mb-1 text-center xl:text-left ">
            <span className="font-semibold text-sm whitespace-nowrap pt-2">
              Item Details
            </span>
            <fieldset className="w-full" onClick={handleItemMasterChange}>
              <SearchDropdown
                name="itemMaster"
                placeholder="Search Item"
                dataArray={itemOptions}
                handleInputChange={handleChange}
                searchIcon={true}
                error={errors.itemMaster}
                control={control}
                isClearable={true}
              />
            </fieldset>
          </div>
          <InputField
            name="uom"
            variant="outlined"
            label="U.O.M"
            error={errors.uom}
            control={control}
            disabled={true}
          />
          <div
            onKeyDown={(e) => {
              dontAllowOnKeyUpDownSpecialChar(e);
            }}
          >
            <InputField
              name="quantity"
              type="number"
              variant="outlined"
              label="Quantity"
              error={errors.quantity}
              control={control}
              inputRef={{
                ...register("quantity", {
                  onChange: (e) => {
                    if (e.target.value > 999) {
                      setError("quantity", {
                        type: "custom",
                        message: "Required",
                      });
                    } else if (e.target.value < 1000) {
                      clearErrors(["quantity"]);
                      let amount = Number(rate) * Number(e.target.value);
                      setValue("amount", amount);
                      let gstTaxAmount = (amount / 100) * Number(gstPercentage);
                      setValue("taxAmount", gstTaxAmount);
                      let conceAmount =
                        Number(amount / 100) * Number(discPercentage);
                      setValue("concessionAmount", Number(conceAmount));
                      let amountMconceession = Number(
                        Number(amount) - Number(conceAmount)
                      );
                      let netAmount =
                        amountMconceession +
                        // Number(exciseVal) +
                        Number(gstTaxAmount);
                      setValue("netAmount", Number(netAmount));
                    }
                  },
                }),
              }}
            />
          </div>
          <div
            onKeyDown={(e) => {
              dontAllowOnKeyUpDownSpecialChar(e);
            }}
          >
            <InputField
              name="rate"
              type="number"
              variant="outlined"
              label="Rate(Rs.)"
              error={errors.rate}
              control={control}
              inputRef={{
                ...register("rate", {
                  onChange: (e) => {
                    clearErrors(["rate"]);
                    let amount = Number(e.target.value) * Number(quantity);
                    setValue("amount", Number(amount).toFixed(2));
                    let gstTaxAmount = (amount / 100) * Number(gstPercentage);
                    setValue("taxAmount", Number(gstTaxAmount).toFixed(2));
                    let conceAmount =
                      Number(amount / 100) * Number(discPercentage);
                    setValue(
                      "concessionAmount",
                      Number(conceAmount).toFixed(2)
                    );
                    let amountMconceession = Number(
                      Number(amount) - Number(conceAmount)
                    );
                    let netAmount =
                      amountMconceession +
                      // Number(exciseVal) +
                      Number(gstTaxAmount);
                    setValue("netAmount", Number(netAmount).toFixed(2));
                  },
                }),
              }}
            />
          </div>
          <InputField
            name="amount"
            variant="outlined"
            label="Amount(Rs.)"
            control={control}
            disabled={true}
          />
          <div
            onKeyDown={(e) => {
              dontAllowOnKeyUpDownSpecialChar(e);
            }}
          >
            <InputLimit
              maxDigits={3}
              name="taxPer"
              type="number"
              variant="outlined"
              label="GST(%)"
              error={errors.taxPer}
              control={control}
              inputRef={{
                ...register("taxPer", {
                  onChange: (e) => {
                    if (e.target.value < 101) {
                      let gstTaxAmount =
                        (amount / 100) * Number(e.target.value);
                      setValue("taxAmount", Number(gstTaxAmount).toFixed(2));
                      let conceAmount =
                        Number(amount / 100) * Number(discPercentage);
                      setValue(
                        "concessionAmount",
                        Number(conceAmount).toFixed(2)
                      );
                      let amountMconceession = Number(
                        Number(amount) - Number(conceAmount)
                      );
                      let netAmount =
                        amountMconceession +
                        // Number(exciseVal) +
                        Number(gstTaxAmount);
                      setValue("netAmount", Number(netAmount).toFixed(2));
                    } else if (e.target.value > 100) {
                      setValue("taxPer", 100);
                    }
                  },
                }),
              }}
            />
          </div>
          <InputField
            name="taxAmount"
            variant="outlined"
            label="GST Amount"
            disabled={true}
            control={control}
          />
          <div
            onKeyDown={(e) => {
              dontAllowOnKeyUpDownSpecialChar(e);
            }}
          >
            <InputLimit
              maxDigits={3}
              name="concessionPer"
              variant="outlined"
              label="Conc.(%)"
              error={errors.concessionPer}
              control={control}
              type="number"
              inputRef={{
                ...register("concessionPer", {
                  onChange: (e) => {
                    if (e.target.value < 101) {
                      let conceAmount =
                        Number(amount / 100) * Number(e.target.value);
                      setValue(
                        "concessionAmount",
                        Number(conceAmount).toFixed(2)
                      );
                      let amountMconceession = Number(
                        Number(amount) - Number(conceAmount)
                      );
                      let netAmount =
                        amountMconceession +
                        // Number(exciseVal) +
                        Number(gstAmount);
                      setValue("netAmount", Number(netAmount).toFixed(2));
                    } else if (e.target.value > 100) {
                      setValue("concessionPer", 100);
                    }
                  },
                }),
              }}
            />
          </div>
          <InputField
            name="concessionAmount"
            variant="outlined"
            label="Conc. Amount"
            error={errors.concessionAmount}
            control={control}
            disabled={true}
          />
          <InputField
            name="netAmount"
            variant="outlined"
            label="Net Amount(Rs.)"
            error={errors.amount}
            control={control}
            disabled={true}
          />
          <div className="flex col-span-2 gap-2 ">
            <InputField
              name="remark"
              variant="outlined"
              label="Remark"
              error={errors.remark}
              control={control}
            />

            <CommonButton
              type="button"
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addQuotationItemData();
                if (
                  itemMaster !== null &&
                  uom !== "" &&
                  quantity !== "" &&
                  rate !== ""
                ) {
                  setValue("itemMaster", null);
                  setValue("uom", "");
                  setValue("quantity", "");
                  setValue("rate", "");
                  setValue("amount", "");
                  setValue("excise", "");
                  setValue("taxPer", "");
                  setValue("taxAmount", "");
                  setValue("concessionPer", "");
                  setValue("concessionAmount", "");
                  setValue("netAmount", "");
                  setValue("remark", "");
                }
              }}
            />
          </div>
        </div>
        <div className="w-full px-4">
          <PurchaseQuotationTable
            quotationdata={quotationdata}
            setQuotationData={setQuotationData}
            editRow={editRow}
            setItemMasterVal={setItemMasterVal}
          />
        </div>

        <div className="my-2 px-4">
          <div className="flex gap-4 my-2">
            <div className="w-full">
              <InputField
                name="totalNetAmount"
                variant="outlined"
                label="Total Net Amount(Rs.)"
                control={control}
                disabled={true}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </div>
            <div className="w-full">
              <InputField
                name="other"
                variant="outlined"
                label="Other(Rs.)"
                control={control}
                type="number"
              />
            </div>
            <div className="w-full">
              <InputField
                name="finalAmount"
                variant="outlined"
                label="Final Amount(Rs.)"
                control={control}
                disabled={true}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </div>
          </div>

          <div className="my-2 grid grid-cols-2 xl:grid-cols-6 gap-4">
            <div className="xl:col-span-3   w-full">
              <div className="flex gap-2">
                <CreateableSelect
                  control={control}
                  error={errors.termsNConditions}
                  name="termsNConditions"
                  placeholder="Terms & Conditions"
                  dataArray={termsnConditionsOptions}
                  onInputChange={handleChange}
                  inputRef={{
                    ...register("termsNConditions", {
                      onChange: (e) => {},
                    }),
                  }}
                />

                <CommonButton
                  type="button"
                  className="bg-customGreen text-white"
                  label="Add"
                  onClick={() => {
                    addTermsNConditionData();
                    setValue("termsNConditions", null);
                  }}
                />
              </div>
              <div className="py-2">
                <TermsNConditionsTable
                  termsnConditionData={termsnConditionData}
                  setTermsnConditionData={setTermsnConditionData}
                />
              </div>
            </div>
            <div className="xl:col-span-2">
              <TextField className="bg-white"
                id="outlined-multiline-static"
                name="note"
                {...register("note")}
                label="Note"
                InputLabelProps={{ shrink: true }}
                multiline
                fullWidth
              />
            </div>

            <div className="flex justify-end items-center gap-2 col-span-2 xl:col-span-1">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        onClick={() => {
                          reset(defaultValues);
                          setQuotationData([]);
                          setTermsnConditionData([]);
                          setEnquiryItemRow(null);
                        }}
                      />
                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                        onClick={() => {
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      {openEnquiryModal === true ? (
        <EnquiryModal
          openEnquiryModal={openEnquiryModal}
          handleEnquiryModalClose={handleEnquiryModalClose}
          setEnquirySupplierId={setEnquirySupplierId}
          supplierOptions={supplierOptions}
          setEnquirySearchString={setEnquirySearchString}
          handleSupplierChange={handleSupplierChange}
          //Use For Getting Item
          quotationdata={quotationdata}
          enquiryItemRow={enquiryItemRow}
          setEnquiryItemRow={setEnquiryItemRow}
          setQuotationData={setQuotationData}
          //Enquiry List table
          populateTable={populateTable}
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          dataResultEnquiryList={dataResultEnquiryList}
          setDataResultEnquiryList={setDataResultEnquiryList}
          setSelectedEnquiryListFromDate={setSelectedEnquiryListFromDate}
          setSelectedEnquiryListToDate={setSelectedEnquiryListToDate}
          userActions={userActions}
        />
      ) : null}

      {quotationListModal === true ? (
        <QuoationListModal
          quotationListModal={quotationListModal}
          setQuotationListModal={setQuotationListModal}
          handleQuotationListOpen={handleQuotationListOpen}
          handleQuotationListClose={handleQuotationListClose}
          setEnquiryItemRow={setEnquiryItemRow}
          setQuotationData={setQuotationData}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
