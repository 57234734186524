import React from "react";
import CancelPresentationIconButton from "../common/components/Buttons/CancelPresentationIconButton";
import { Box, Modal } from "@mui/material";

function CommonPrintModal(props) {
  return (
    <>
      <Modal open={props.open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            overflow: "hidden",
            borderRadius: "0.2rem",
            boxShadow: 24,
            p: 1,
          }}
        >
          <div>
            <CancelPresentationIconButton
              onClick={() => {
                props.close();
              }}
            />
          </div>
          {props.document !== "" ? (
            <embed
              width="100%"
              height="100%"
              src={props.document}
              target="_blank"
            />
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </>
  );
}

export default CommonPrintModal;
