// import {
//   Checkbox,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import Box from "@mui/material/Box";
// import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";

// export default function DivaToolTable(props) {
//   const {
//     control,
//     setValue,
//     register,
//     formState: { errors },
//   } = useForm({
//     mode: "onChange",
//     // defaultValues,
//   });
//   const [index, setIndex] = useState(null);

//   React.useEffect(() => {
//     if (props.divalToolData) {
//       for (let obj of props.divalToolData) {
//         for (let objDiva of obj.divaToolParaDetailDtoList) {
//           setIndex(objDiva.detailId);
//           if (props.resetFlag === true) {
//             setValue(`yes${objDiva.detailId}`, false);
//             setValue(`no${objDiva.detailId}`, false);
//             setValue(`name${objDiva.detailId}`, null);
//           }
//         }
//       }
//     }
//   }, [props.divalToolData, props.resetFlag]);

//   useEffect(() => {
//     if (props.divalToolData) {
//       let divaScoreVal = 0;
//       for (let divaObject of props.divalToolData) {
//         for (let obj of divaObject.divaToolParaDetailDtoList) {
//           if (obj.isYes === 1 || obj.isNo === 1) {
//             divaScoreVal = Number(divaScoreVal) + Number(obj.sumValue);
//             props.setDivaScore(divaScoreVal);
//           } else if (obj.isYes === 0 || obj.isNo === 0) {
//             divaScoreVal = Number(divaScoreVal) - Number(obj.sumValue);
//             props.setDivaScore(divaScoreVal);
//           }
//         }
//       }
//     }
//   }, [props.divalToolData]);

//   return (
//     <div className="w-auto  grid">
//       <Box sx={{ width: "100%", overflow: "hidden" }}>
//         <Paper sx={{ my: 1 }}>
//           <TableContainer
//             sx={{
//               "&::-webkit-scrollbar": {
//                 width: 7,
//                 height: 10,
//               },
//               "&::-webkit-scrollbar-track": {
//                 backgroundColor: "#7393B3",
//               },
//               "&::-webkit-scrollbar-thumb": {
//                 backgroundColor: "lightBlue",
//               },
//             }}
//             className="rounded border-gray-300 lg:h-[320px] 2xl:h-[550px]"
//           >
//             <Table size="small" stickyHeader aria-label="sticky table">
//               <TableHead>
//                 <TableRow
//                   sx={{
//                     "& td": {
//                       backgroundColor: "#F1F1F1",
//                     },
//                   }}
//                 >
//                   <TableCell
//                     className="whitespace-nowrap"
//                     style={{
//                       background: "#F1F1F1",
//                       border: "1px solid lightGray",
//                       fontWeight: "bold",
//                       fontSize: "16px",
//                     }}
//                   >
//                     Sr No.
//                   </TableCell>
//                   <TableCell
//                     className="whitespace-nowrap "
//                     style={{
//                       background: "#F1F1F1",
//                       border: "1px solid lightGray",
//                       fontWeight: "bold",
//                       fontSize: "16px",
//                     }}
//                   >
//                     Parameters
//                   </TableCell>
//                   <TableCell
//                     className="whitespace-nowrap "
//                     style={{
//                       background: "#F1F1F1",
//                       border: "1px solid lightGray",
//                       fontWeight: "bold",
//                       fontSize: "16px",
//                       textAlign: "center",
//                     }}
//                   >
//                     Score (Yes)
//                   </TableCell>
//                   <TableCell
//                     className="whitespace-nowrap"
//                     style={{
//                       background: "#F1F1F1",
//                       border: "1px solid lightGray",
//                       fontWeight: "bold",
//                       fontSize: "16px",
//                       textAlign: "center",
//                     }}
//                   >
//                     Score (No)
//                   </TableCell>

//                   <TableCell
//                     className="whitespace-nowrap "
//                     style={{
//                       background: "#F1F1F1",
//                       border: "1px solid lightGray",
//                       fontWeight: "bold",
//                       fontSize: "16px",
//                     }}
//                   >
//                     Remark
//                   </TableCell>
//                 </TableRow>
//               </TableHead>

//               <TableBody>
//                 {props.divalToolData.map((row, index) => {
//                   return (
//                     <>
//                       <TableRow key={index}>
//                         <TableCell
//                           className="whitespace-nowrap"
//                           key={index}
//                           style={{
//                             background: "#F1F1F1",
//                             border: "1px solid lightGray",
//                             fontWeight: "bold",
//                             width: "7%",
//                           }}
//                         >
//                           {row.headerId}
//                         </TableCell>
//                         <TableCell
//                           className="whitespace-nowrap"
//                           key={index}
//                           style={{
//                             background: "#F1F1F1",
//                             border: "1px solid lightGray",
//                             fontWeight: "bold",
//                             width: "35%",
//                           }}
//                         >
//                           {row.description}
//                         </TableCell>
//                         <TableCell
//                           className="whitespace-nowrap "
//                           key={index}
//                           style={{
//                             background: "#F1F1F1",
//                             border: "1px solid lightGray",
//                           }}
//                         ></TableCell>
//                         <TableCell
//                           className="whitespace-nowrap "
//                           key={index}
//                           style={{
//                             background: "#F1F1F1",
//                             border: "1px solid lightGray",
//                           }}
//                         ></TableCell>
//                         <TableCell
//                           className="whitespace-nowrap "
//                           key={index}
//                           style={{
//                             background: "#F1F1F1",
//                             border: "1px solid lightGray",
//                           }}
//                         ></TableCell>
//                       </TableRow>
//                       {row.divaToolParaDetailDtoList.map((item, i) => {
//                         return (
//                           <TableRow onClick={() => {}}>
//                             <TableCell
//                               style={{
//                                 border: "1px solid lightGray ",
//                                 // width: "1%"
//                               }}
//                             ></TableCell>
//                             <TableCell
//                               style={{
//                                 border: "1px solid lightGray",
//                                 width: "35%",
//                               }}
//                             >
//                               {item.detailDescription}
//                             </TableCell>
//                             <TableCell
//                               style={{
//                                 border: "1px solid lightGray",
//                               }}
//                               padding="checkbox"
//                               key={i}
//                             >
//                               <div className="flex justify-center ">
//                                 <fieldset
//                                   onChange={(e) => {
//                                     props.setResetFlag(false);
//                                     let dataArray = [...props.divalToolData];
//                                     if (e.target.checked === true) {
//                                       setValue(`no${item.detailId}`, false);

//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].isNo = 0;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].isYes = 1;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].sumValue =
//                                         dataArray[
//                                           index
//                                         ].divaToolParaDetailDtoList[
//                                           i
//                                         ].yesScoreValue;

//                                       props.setDivaToolData(dataArray);
//                                     } else if (e.target.checked === false) {
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].isYes = 0;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[
//                                         i
//                                       ].sumValue = 0;
//                                       props.setDivaToolData(dataArray);
//                                     }
//                                   }}
//                                 >
//                                   <Checkbox
//                                     size="small"
//                                     checked={item?.isYes}
//                                     // control={control}
//                                     name={`yes${item.detailId}`}
//                                     label=""
//                                     value="checkBox"
//                                   />
//                                 </fieldset>
//                               </div>
//                             </TableCell>
//                             <TableCell
//                               style={{
//                                 border: "1px solid lightGray",
//                               }}
//                               padding="checkbox"
//                             >
//                               <div className="flex justify-center">
//                                 <fieldset
//                                   onChange={(e) => {
//                                     props.setResetFlag(false);
//                                     let dataArray = [...props.divalToolData];
//                                     if (e.target.checked === true) {
//                                       setValue(`yes${item.detailId}`, false);
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].isNo = 1;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].isYes = 0;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].sumValue =
//                                         dataArray[
//                                           index
//                                         ].divaToolParaDetailDtoList[
//                                           i
//                                         ].noScoreValue;

//                                       props.setDivaToolData(dataArray);
//                                     } else if (e.target.checked === false) {
//                                       // dataArray[index].divaToolParaDetailDtoList[
//                                       //   i
//                                       // ].isYes = 0;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[i].isNo = 0;
//                                       dataArray[
//                                         index
//                                       ].divaToolParaDetailDtoList[
//                                         i
//                                       ].sumValue = 0;
//                                       props.setDivaToolData(dataArray);
//                                     }
//                                   }}
//                                 >
//                                   <Checkbox
//                                     size="small"
//                                     // control={control}
//                                     checked={item?.isNo}
//                                     name={`no${item.detailId}`}
//                                     label=""
//                                     value="checkBox"
//                                   />
//                                 </fieldset>
//                               </div>
//                             </TableCell>
//                             <TableCell
//                               style={{
//                                 border: "1px solid lightGray",
//                               }}
//                               padding="checkbox"
//                             >
//                               <input
//                                 className="w-full border border-customBlue rounded px-4 text-center"
//                                 name={`name${item.detailId}`}
//                                 label=""
//                                 value={item.remark}
//                                 // control={control}
//                                 {...register(`name${item.detailId}`)}
//                                 onChange={(e) => {
//                                   props.setResetFlag(false);
//                                   let dataArray = [...props.divalToolData];
//                                   if (e.target.value) {
//                                     dataArray[index].divaToolParaDetailDtoList[
//                                       i
//                                     ].remark = e.target.value;
//                                     props.setDivaToolData(dataArray);
//                                   }
//                                 }}
//                               />

//                               {/* <Controller
//                                 name={`name${item.detailId}`}
//                                 control={control}
//                                 value={item.remark}
//                                 render={({ field }) => (
//                                   <input
//                                     // value={item.remark}
//                                     {...field}
//                                     type="text"
//                                     className=" w-full text-center placeholder-slate-300 bg-white rounded text-sm  text-[#141414] outline-none focus:outline-none border border-black"
//                                     placeholder="Remark"
//                                   />
//                                 )}
//                                 onChange={(e) => {
//                                   props.setResetFlag(false);
//                                   let dataArray = [...props.divalToolData];
//                                   if (e.target.value) {
//                                     dataArray[index].divaToolParaDetailDtoList[
//                                       i
//                                     ].remark = e.target.value;
//                                     props.setDivaToolData(dataArray);
//                                   }
//                                 }}
//                               /> */}
//                             </TableCell>
//                           </TableRow>
//                         );
//                       })}
//                     </>
//                   );
//                 })}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Paper>
//       </Box>
//     </div>
//   );
// }

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function DivaToolTable(props) {
  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // defaultValues,
  });
  const [index, setIndex] = useState(null);

  React.useEffect(() => {
    if (props.divalToolData) {
      for (let obj of props.divalToolData) {
        for (let objDiva of obj.divaToolParaDetailDtoList) {
          setIndex(objDiva.detailId);
          if (props.resetFlag === true) {
            setValue(`yes${objDiva.detailId}`, false);
            setValue(`no${objDiva.detailId}`, false);
            setValue(`name${objDiva.detailId}`, null);
          }
        }
      }
    }
  }, [props.divalToolData, props.resetFlag]);

  useEffect(() => {
    if (props.divalToolData) {
      let divaScoreVal = 0;
      for (let divaObject of props.divalToolData) {
        for (let obj of divaObject.divaToolParaDetailDtoList) {
          if (obj.isYes === 1 || obj.isNo === 1) {
            divaScoreVal = Number(divaScoreVal) + Number(obj.sumValue);
            props.setDivaScore(divaScoreVal);
          } else if (obj.isYes === 0 || obj.isNo === 0) {
            divaScoreVal = Number(divaScoreVal) - Number(obj.sumValue);
            props.setDivaScore(divaScoreVal);
          }
        }
      }
    }
  }, [props.divalToolData]);

  return (
    <div className="w-auto  grid">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ my: 1 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded border-gray-300 h-[65%]"
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{
                    "& td": {
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell
                    className="whitespace-nowrap"
                    style={{
                      background: "#F1F1F1",
                      border: "1px solid lightGray",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Sr No.
                  </TableCell>
                  <TableCell
                    className="whitespace-nowrap "
                    style={{
                      background: "#F1F1F1",
                      border: "1px solid lightGray",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Parameters
                  </TableCell>
                  <TableCell
                    className="whitespace-nowrap "
                    style={{
                      background: "#F1F1F1",
                      border: "1px solid lightGray",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Score (Yes)
                  </TableCell>
                  <TableCell
                    className="whitespace-nowrap"
                    style={{
                      background: "#F1F1F1",
                      border: "1px solid lightGray",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Score (No)
                  </TableCell>

                  <TableCell
                    className="whitespace-nowrap "
                    style={{
                      background: "#F1F1F1",
                      border: "1px solid lightGray",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.divalToolData.map((row, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell
                          className="whitespace-nowrap"
                          key={index}
                          style={{
                            background: "#F1F1F1",
                            border: "1px solid lightGray",
                            fontWeight: "bold",
                            width: "7%",
                          }}
                        >
                          {row.headerId}
                        </TableCell>
                        <TableCell
                          className="whitespace-nowrap"
                          key={index}
                          style={{
                            background: "#F1F1F1",
                            border: "1px solid lightGray",
                            fontWeight: "bold",
                            width: "35%",
                          }}
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          className="whitespace-nowrap "
                          key={index}
                          style={{
                            background: "#F1F1F1",
                            border: "1px solid lightGray",
                          }}
                        ></TableCell>
                        <TableCell
                          className="whitespace-nowrap "
                          key={index}
                          style={{
                            background: "#F1F1F1",
                            border: "1px solid lightGray",
                          }}
                        ></TableCell>
                        <TableCell
                          className="whitespace-nowrap "
                          key={index}
                          style={{
                            background: "#F1F1F1",
                            border: "1px solid lightGray",
                          }}
                        ></TableCell>
                      </TableRow>
                      {row.divaToolParaDetailDtoList.map((item, i) => {
                        return (
                          <TableRow onClick={() => {}}>
                            <TableCell
                              style={{
                                border: "1px solid lightGray ",
                                // width: "1%"
                              }}
                            ></TableCell>
                            <TableCell
                              style={{
                                border: "1px solid lightGray",
                                width: "35%",
                              }}
                            >
                              {item.detailDescription}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid lightGray",
                              }}
                              padding="checkbox"
                              key={i}
                            >
                              <div className="flex justify-center ">
                                <fieldset
                                  onChange={(e) => {
                                    props.setResetFlag(false);
                                    let dataArray = [...props.divalToolData];
                                    if (e.target.checked === true) {
                                      setValue(`no${item.detailId}`, false);

                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].isNo = 0;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].isYes = 1;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].sumValue =
                                        dataArray[
                                          index
                                        ].divaToolParaDetailDtoList[
                                          i
                                        ].yesScoreValue;

                                      props.setDivaToolData(dataArray);
                                    } else if (e.target.checked === false) {
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].isYes = 0;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[
                                        i
                                      ].sumValue = 0;
                                      props.setDivaToolData(dataArray);
                                    }
                                  }}
                                >
                                  <Checkbox
                                    size="small"
                                    checked={item?.isYes}
                                    // control={control}
                                    name={`yes${item.detailId}`}
                                    label=""
                                    value="checkBox"
                                  />
                                </fieldset>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid lightGray",
                              }}
                              padding="checkbox"
                            >
                              <div className="flex justify-center">
                                <fieldset
                                  onChange={(e) => {
                                    props.setResetFlag(false);
                                    let dataArray = [...props.divalToolData];
                                    if (e.target.checked === true) {
                                      setValue(`yes${item.detailId}`, false);
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].isNo = 1;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].isYes = 0;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].sumValue =
                                        dataArray[
                                          index
                                        ].divaToolParaDetailDtoList[
                                          i
                                        ].noScoreValue;

                                      props.setDivaToolData(dataArray);
                                    } else if (e.target.checked === false) {
                                      // dataArray[index].divaToolParaDetailDtoList[
                                      //   i
                                      // ].isYes = 0;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[i].isNo = 0;
                                      dataArray[
                                        index
                                      ].divaToolParaDetailDtoList[
                                        i
                                      ].sumValue = 0;
                                      props.setDivaToolData(dataArray);
                                    }
                                  }}
                                >
                                  <Checkbox
                                    size="small"
                                    // control={control}
                                    checked={item?.isNo}
                                    name={`no${item.detailId}`}
                                    label=""
                                    value="checkBox"
                                  />
                                </fieldset>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid lightGray",
                              }}
                              padding="checkbox"
                            >
                              <input
                                className="w-full border border-customBlue rounded px-4 text-center"
                                name={`name${item.detailId}`}
                                label=""
                                value={item.remark}
                                // control={control}
                                {...register(`name${item.detailId}`)}
                                onChange={(e) => {
                                  props.setResetFlag(false);
                                  let dataArray = [...props.divalToolData];
                                  if (e.target.value) {
                                    dataArray[index].divaToolParaDetailDtoList[
                                      i
                                    ].remark = e.target.value;
                                    props.setDivaToolData(dataArray);
                                  }
                                }}
                              />

                              {/* <Controller
                                name={`name${item.detailId}`}
                                control={control}
                                value={item.remark}
                                render={({ field }) => (
                                  <input
                                    // value={item.remark}
                                    {...field}
                                    type="text"
                                    className=" w-full text-center placeholder-slate-300 bg-white rounded text-sm  text-[#141414] outline-none focus:outline-none border border-black"
                                    placeholder="Remark"
                                  />
                                )}
                                onChange={(e) => {
                                  props.setResetFlag(false);
                                  let dataArray = [...props.divalToolData];
                                  if (e.target.value) {
                                    dataArray[index].divaToolParaDetailDtoList[
                                      i
                                    ].remark = e.target.value;
                                    props.setDivaToolData(dataArray);
                                  }
                                }}
                              /> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}
