import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const addNewContraVoucher = (object) => {
  return apiClient.post(`/accounting/saveContraVoucher`, object ,  {
    headers: authHeader(),
  });
};

export const contraVoucherPrint = (contraVoucherId) => {
  return apiClient.get(`/accountingPrints/getContraVoucherPrint/${contraVoucherId}`,  {
    headers: authHeader(),
  });
};
