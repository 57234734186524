import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as React from "react";
import { useEffect, useState } from "react";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { format } from "date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";

import { yupResolver } from "@hookform/resolvers/yup";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import Divider from "@mui/material/Divider";
import { Controller, useFieldArray } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  getBankList,
  getPaymentMode,
  getUpiType,
} from "../../../billing/services/BillingServices";
import {
  autoSearchPatientAdvance,
  fetchPatientAdvanceDetails,
  getPatientInfo,
  getPaymentTypes,
  patientAdvancePdfPrint,
  savePaymentAdvance,
} from "../../../billing/services/PatientAdvanceServices";
import useDidMountEffect from "../../../common/components/Custom Hooks/useDidMountEffect";
import InputField from "../../../common/components/FormFields/InputField";

import { useRef } from "react";
// import { SubmitButton } from "../../../Common Components/Buttons/CommonButtons";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTable from "../../../common/components/CommonTable/CommonDynamicTable";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
let inputSearchid = "";

const Actions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
  {
    id: 1,
    action: "submit",
    isAction: false,
  },
];
const removeHeaders = ["Advance No", "PatientAdvanceDetails", "AdvanceID"];
let amountDetails;
let paymentTypeDetails;
let paymentRef;

const incomingTableData = [
  {
    amount: "",
    paymentMethod: "",
  },
];
const regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const actions = ["Print"];
export default function PatientAdvance(props) {
  console.log("props from IPD Bill is", props);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleOpen = () => {
    setConfirmationOpen(true);
  };
  const handleClose = () => {
    setConfirmationOpen(false);
  };
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const [searchString, setSearchString] = useState("");
  const [dataArr, setDataArr] = useState([]);
  const [comingData, setComingData] = useState({});
  const [showPage, setShowPage] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [vistiPdf, setVisitPdf] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [admissionId, setAdmissionId] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState();
  const [showPanCard, setShowPanCard] = useState(false);
  const [userActions, setUserActions] = useState([]);
  const [menuIdVal, setMenuIdVal] = useState(null);
  const [source, setSource] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());

  let paymentSchema = {
    paymentDetails: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()

            .typeError("Amount Required")
            .min(1, "Min value 1.")
            .required("Amount Required"),
          pancard: yup.string().when(() => {
            if (showPanCard) {
              return yup
                .string()
                .matches(
                  /[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                  "Please Provide Valid Pan No. "
                )
                .min(10, "Please Provide Valid Pan No.")
                .max(10, "Please Provide Valid Pan No.")
                .required("Please Provide Valid Pan No.");
            }
          }),

          type: yup
            .object()
            .nullable()
            .shape({
              value: yup.string().required("Required"),
              label: yup.string().required("Required"),
            })
            .required("Required"),

          upiname: yup
            .object()
            .nullable()
            .shape({
              value: yup.string(),
              label: yup.string(),
            })
            .when("type", (type) => {
              console.log("The paymentMode in schema is ", type);

              let paymentModeName;

              if (type) {
                paymentModeName = type.label;
              }

              if (paymentModeName === "UPI") {
                console.log("if block of bankName schema");

                return yup
                  .object()
                  .required(" ")
                  .nullable()
                  .shape({
                    value: yup.string().required(" "),
                    label: yup.string().required(" "),
                  });
              } else {
                console.log("else block of bankName schema");

                return yup
                  .object()
                  .notRequired()
                  .nullable()
                  .shape({
                    value: yup.string().notRequired(),
                    label: yup.string().notRequired(),
                  })
                  .notRequired();
              }
            }),
          bankname: yup
            .object()
            .nullable()
            .typeError("Required")
            .when("type", (type) => {
              if (type !== null) {
                if (type.label !== "CASH" && type.label !== "UPI") {
                  return yup
                    .object()
                    .nullable()
                    .shape({
                      value: yup.string().required("Select Bank"),
                      label: yup.string().required("Select Bank"),
                    })
                    .required("Select Bank");
                }
              }
            }),

          referrenceNo: yup.string().when("type", (type) => {
            let paymentModeName;

            if (type) {
              paymentModeName = type.label;
            }
            if (
              paymentModeName === "CREDIT CARD" ||
              paymentModeName === "DEBIT CARD"
            ) {
              return yup
                .string()
                .required("Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(4)
                .max(4);
            } else if (paymentModeName === "UPI") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(20);
              // .length(20, "Only 6 Digits allowed");
            } else if (paymentModeName === "CHEQUE") {
              return yup
                .string()
                .required("Reference Number Required")
                .length(6, "Only 6 Digits allowed");
            } else if (paymentModeName === "ECS") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(20);
            } else if (paymentModeName === "ONLINE") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(20);
              // .length(20, "Only 6 Digits allowed");
            } else {
              return yup.string().notRequired();
            }
          }),
        })
      )
      .min(1, "Please Add amount Details"),
    mobileNo: yup
      .string()
      .required("Required")
      .matches(phoneRegExp, "Please Enter Valid Contact No")
      .min(10, "Min value 1.")
      .max(10, "Min value 1.")
      .required("Amount Required"),
    remarks: yup.string(),
  };
  const paymentAppointValidation = yup.object().shape(paymentSchema).required();
  const paymentSchemaObj = paymentAppointValidation;

  let today = new Date();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(paymentSchemaObj),
    defaultValues: {
      remarks: "",
      mobileNo: "",
      paymentDetails: [
        {
          type: "",
          amount: "",
          referrenceNo: "",
          paymentDate: today,
          bankname: null,
          upiname: null,
          pancard: "",
        },
      ],
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    getValues,
    formState: { errors },
    control,
    clearErrors,
    setValue,
    watch,
  } = methods;

  const {
    fields: paymentFields,
    append: append,
    remove: remove,
  } = useFieldArray({
    control: control,
    name: "paymentDetails",
  });
  let paymentDetails = watch("paymentDetails");

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [filterdPaymentTypes, setFilterdPaymentTypes] = useState([]);
  const [paymentTableData, setPaymentTableData] = useState(incomingTableData);
  const [savePayment, setSavePayment] = useState();
  // const [paymentagainest, setPaymentAgainest] = useState([]);
  const [Bank, setBank] = useState();
  const [upiTypes, setUpiTypes] = useState();
  const [verfiedFilteredPayments, setVerfiedFilteredPayments] = useState();
  const [typeString,setTypeString]=React.useState([])

  // get menu id
  let location = useLocation();
  console.log("locationlocation", location?.state?.menuId);

  let payments = ""
  React.useEffect(() => {
    if (paymentDetails.length > 0) {
      for (let paymentObj of paymentDetails) {
        if (paymentObj?.type?.label) {
          payments=payments+paymentObj?.type?.label
        }
      }
    }
    
    let newPaymentArr=[]
    for(let payment of paymentTypes)
    {
      if(!payments?.toLocaleLowerCase().includes(payment?.label?.toLocaleLowerCase()))
      {
        newPaymentArr.push(payment)
        console.log("newPaymentArrnewPaymentArr",newPaymentArr);
        setPaymentTypes(newPaymentArr)
      }
    }
  }, [paymentDetails]);
console.log("typestring",typeString);
  useEffect(() => {
    getPaymentTypes()
      .then((response) => response.data)
      .then((res) => {
        setPaymentTypes(res.result);
        setTypeString(res.resut)
        let defaultType=res.result.find((item)=>item.label==="CASH")
        setValue(`paymentDetails.0.type`,defaultType)
        let newData = [...paymentTableData];
        
        setPaymentTableData(newData);
        //setValue(`paymentDetails[${0}].type`, obj);
        PaymentTypeResult(res.result);
      });
    getBankList()
      .then((response) => response.data)
      .then((res) => {
        setBank(res.result);
      });
    getUpiType()
      .then((response) => response.data)
      .then((res) => {
        setUpiTypes(res.result);
      });
  }, []);

  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          console.log("getUserActions", res.result);
          setUserActions(res.result);
        });
  }, []);
  useEffect(() => {
    console.log("paymentDetails", paymentDetails);
    console.log("errors", errors);
  }, [errors]);
  const settingPaymentDefault = () => {
    let gotPaymentTypes = [...paymentTypes];
    let newData = [...incomingTableData];
    console.log("gotPaymentTypes obj", gotPaymentTypes);
    let obj = gotPaymentTypes.find((o) => o.default === true);
    console.log("default obj", obj);
    newData[0].paymentMethod = obj;
    setPaymentTableData(newData);
    setValue(`paymentDetails[${0}].type`, obj);
    PaymentTypeResult(gotPaymentTypes);
  };
  const PaymentTypeResult = (allPaymentTypes) => {
    let object = [];
    for (let i = 0; i < paymentTableData.length; i++) {
      let innerObj = paymentTableData[i].paymentMethod;
      object = [...object, innerObj];
    }
    let uniqueResultOne = allPaymentTypes.filter(function (obj) {
      return !object.some(function (obj2) {
        return obj == obj2;
      });
    });

    // let difference = paymentTypes.filter(x => !paymentTableData.types.includes(x));
    // console.log("object",uniqueResultOne)
    setFilterdPaymentTypes(uniqueResultOne);
  };
  const AddRefferenceNo = (index, PaymentTypeIs) => {
    let newData = [...paymentTableData];
    newData[index].referrenceNo = "";
    newData[index].paymentMethod = PaymentTypeIs;
    setPaymentTableData(newData);
  };
  const RemoveRefferenceNo = (index, PaymentTypeIs) => {
    let newData = [...paymentTableData];
    delete newData[index].referrenceNo;
    newData[index].paymentMethod = PaymentTypeIs;
    setPaymentTableData(newData);
  };
  useEffect(() => {
    console.log("comming data", comingData);
  }, [comingData]);
  const AddRowPayment = () => {
    setPaymentTableData([
      ...paymentTableData,
      {
        paymentMethod: "",
        referrenceNo: "",
        amount: "",
      },
    ]);
  };

  let fullRegDate;
  const getNewRegDate = (e, index) => {
    let regYear = String(e.getFullYear());
    let regMonth = String(e.getMonth() + 1).padStart(2, "0");
    let regDay = String(e.getDate()).padStart(2, "0");
    fullRegDate = [regYear, regMonth, regDay].join("-");
    let finalDate = [regYear, regMonth, regDay].join("-");
    console.log("fullRegDate", fullRegDate);
    // setValue("paymentDate", fullRegDate, { shouldValidate: true });

    let tempPayments = [...paymentTableData];
    tempPayments[index].paymentDate = fullRegDate;

    setValue(`paymentDetails[${index}].paymentDate`, finalDate);

    return fullRegDate;
  };
  let TotalAmt = 0;


  const resetPaymentModes = () => {
    getPaymentMode().then((response) => {
      setPaymentTypes(response.data.result);
      setValue(`paymentDetails.0.type`, response.data.result[1]);
    });
  };

  const onSubmitPayment = (data) => {
    console.log("datadatadatadata", data.pancard);
    console.log("Payment", data.paymentDetails);
    console.log("table Data", paymentTableData);
    console.log("bank name", Bank);
    let DataTable = data.paymentDetails;
    let finalData = {};

    let payments = [];
    let cashCounterObj = JSON.parse(localStorage.getItem("userInfo"));

    console.log("cash counter id", cashCounterObj.cashCounterId);
    let loginId = JSON.parse(localStorage.getItem("loginId key"));
    // let cashCounterID = cashCounterObj.id;
    for (let i = 0; i < DataTable.length; i++) {
      let obj = {};
      console.log("DataTable[i].type", DataTable[i].type);
      if (
        DataTable[i].type.label !== "CASH" &&
        DataTable[i].type.label !== "UPI"
      ) {
        // if (DataTable[i].type.label !== "UPI") {
        obj.bankId = DataTable[i].bankname.id;
        obj.paymentDate = selectedFromDate;
        // }
        obj.referenceNumber = DataTable[i].referrenceNo;
      }

      if (DataTable[i].type.label === "UPI") {
        obj.upiTypeId = DataTable[i].upiname.id;
      }

      obj.paymentType = DataTable[i].type;
      obj.remarks = data.remarks;
      obj.amount = Number(DataTable[i].amount);
      TotalAmt = TotalAmt + Number(DataTable[i].amount);
      payments = [...payments, obj];
    }

    finalData.pancardNo = data.pancard ? data?.pancard : "";
    finalData.mobileNumber = data.mobileNo;
    finalData.remarks = data.remarks;
    finalData.paymentInfoList = payments;
    finalData.patientId = comingData.patientId;
    finalData.loginId = loginId;
    finalData.patientAdvance = Number(TotalAmt);
    finalData.opdIpd = 1;
    finalData.menuId = location?.state?.menuId;
    finalData.privilege = menuIdVal?.action;
    finalData.cashCounterId = cashCounterObj.cashCounterId;
    console.log("Final Data", finalData);
    setSavePayment(finalData);
    handleOpen();
  };

  const submitDataApit = () => {
    setOpenBackdrop(true);

    console.log("props.totalInfoprops.totalInfo", props.totalInfo);
    savePaymentAdvance(savePayment)
      .then((response) => response.data)
      .then((res) => {
        console.log("getreposeform api for advance is", res);
        if (props.from === "Billing") {
          console.log(
            "savePaymentsavePaymentsavePayment",
            savePayment?.patientAdvance
          );
          
          handleOpenPrint(res.result.patientAdvanceDetailsId);
          // props.handleCloseAdvance();
          let object = props.totalInfo;
          object.TotalAdvance =
            object.TotalAdvance + savePayment?.patientAdvance;
          console.log("objectobjectobject", object);
          props.setTotalInfo(object);
        } else {
          handleOpenPrint(res.result.patientAdvanceDetailsId);
        }
        resetPaymentModes()
        successAlert(res.message);
        resetForm();
        setShowPage(false);

        fetchPatientDetails();
        setOpenBackdrop(false);
        setValue("opdbillingSearch", null);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  };

  // print receipt of advance payment
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrint, setOpenPrint] = useState(false);
  const handleClosePrint = () => {
    setOpenPrint(false);
  };
  const handleOpenPrint = (patientAdvanceDetailsId) => {
    setOpenPrint(true);
    patientAdvancePdfPrint(patientAdvanceDetailsId)
      .then((response) => response)
      .then((res) => {
        console.log("patientAdvancennnnnnnnnnnnn", patientAdvanceDetailsId);
        setUrlforPrint(res);
      });
  };

  useEffect(() => {
    console.log("The openPrintModal is ", openPrint);
  }, [openPrint]);

  useEffect(() => {
    let menuID = 0;
    console.log("userActionsuserActionsuserActions", props.userActions);
    if (props.userActions !== undefined) {
      let tempArr = [...props.userActions];
      for (let Obj of tempArr) {
        if (Obj.action === "Advance") {
          console.log("objectofpropsis", Obj);
          menuID = Obj?.menuId;
          console.log("newmenuidis", menuID);

          break;
        }
      }
    }
    console.log("setMenuIdsetMenuIdsetMenuId", menuID);
    setMenuIdVal(menuID);
  }, [props.userActions]);

  useEffect(() => {
    console.log("propspatientIdpatientIdpatientId", props.patientId);

    if (props.patientId) {
      fetchPatientDetails(props.patientId);
    }
  }, [props.patientId]);

  console.log("dataArrdataArrdataArrdataArr", dataArr);

  const fetchPatientDetails = (patientId) => {
    console.log("patientIdpatientIdpatientIdpatientId", patientId);

    let menuId = location?.state?.menuId;
    console.log("menuidis", menuId);
    patientId &&
      menuId &&
      fetchPatientAdvanceDetails(patientId, menuId).then((response) => {
        console.log("responseis", response);
        let receivedData = response.data.result;
        let obj = {
          id: receivedData.id,
          patientName: receivedData.patientName,
          MoblieNo: receivedData.MobileNo,
          LastName: receivedData.LastName,
          FirstName: receivedData.FirstName,
          MiddleName: receivedData.MiddleName,

          MRNo: receivedData.MRNo,
        };
        console.log("objobjobjobjobjobjobjobjobj", obj);
        console.log("advance history", receivedData);
        if (!showPage) {
          setShowPage(true);
        }
        setDataArr(receivedData);

        console.log("comming data set", obj);
      });
  };

  const resetForm = () => {
    reset();
    setPaymentTableData(incomingTableData);

    settingPaymentDefault();
  };
  const clearPage = () => {
    setFilterdPaymentTypes(paymentTypes);
    setPaymentTableData(incomingTableData);

    setDataArr([]);
    setComingData({});
    setShowPage(false);
    settingPaymentDefault();
  };

  const validData = useRef(true);
  const addToPaymentArr = () => {
    console.log("paymentDetails", paymentDetails);
    let amountDetails, paymentTypeDetails, paymentRef;
    validData.current = true;
    let newTemplateData = [...paymentTableData];
    for (let item of paymentDetails) {
      if (item.type.label !== "CASH") {
        paymentRef = item.referrenceNo;
      } else {
        paymentRef = "notValidforCash";
      }

      console.log("paymentTypeDetails", item.paymentMethod);
      console.log("amountDetails", item.amount);
      console.log("paymentRef", paymentRef);
      if (
        item.paymentMethod !== null &&
        item.paymentMethod !== "" &&
        item.amount !== null &&
        item.amount !== "" &&
        paymentRef !== ""
      ) {
      } else {
        validData.current = false;
      }
    }
    if (validData.current) {
      console.log("adding to arr", validData.current);
      append({ type: "", amount: "" });
      AddRowPayment();
    } else {
      console.log("should not add to arr", validData.current);
      trigger(["paymentDetails"]);
    }
  };

  const deleteTableRow = (index, rowPaymentType) => {
    remove(index);

    if (index !== paymentDetails.length - 1) {
      for (let i = index; i < paymentTableData.length - 1; i++) {
        setValue(`paymentDetails[${i}].type`, paymentDetails[i + 1].type);
        setValue(`paymentDetails[${i}].amount`, paymentDetails[i + 1].amount);

        if ("referrenceNo" in paymentTableData[i + 1]) {
          console.log("refNo +1", paymentTableData[i + 1].referrenceNo);
          if (paymentTableData[i + 1].referrenceNo !== "") {
            setValue(
              `paymentDetails[${i}].referrenceNo`,
              paymentTableData[i + 1].referrenceNo
            );
          } else {
            setValue(`paymentDetails[${i}].referrenceNo`, "");
          }
        } else {
          RemoveRefferenceNo(i, paymentDetails[i + 1].type);
        }
      }
    }
    deleteRowPayment(index);
    // setFilteredData

    let paymentFiltered = [...filterdPaymentTypes];
    let addType = rowPaymentType;
    setFilterdPaymentTypes([...paymentFiltered, addType]);
    setPaymentTypes([...paymentFiltered, addType])
  };

  const deleteRowPayment = (index) => {
    let newTemplateData = [...paymentTableData];
    newTemplateData.splice(index, 1);
    setPaymentTableData(newTemplateData);
  };

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  useDidMountEffect(() => {
    if (
      filterdPaymentTypes.length > 0 &&
      paymentTypes.length > 0 &&
      paymentDetails.length > 0
    ) {
      let arr = [...filterdPaymentTypes];
      let arrLength = arr.length;
      let tableLength = paymentTableData.length;
      let paymentTypesLength = paymentTypes.length;

      if (arrLength !== paymentTypesLength - tableLength) {
        console.log("filter Error");
      }
      let AvailablePaymentTypes = [];
      let duplicates = hasDuplicates(arr);
      //
      let tableArr = [...paymentDetails];
      let usedTypes = [];
      for (let obj of tableArr) {
        let type = obj.type;
        if (type === "CASH") {
          usedTypes.push(type);
        }
      }
      let paymentTypesArr = [...paymentTypes];
      let typesArr = [];
      for (let obj of paymentTypesArr) {
        let exists = usedTypes.find((o) => {
          if (o == obj.label) {
            return true;
          } else {
            return false;
          }
        });
        if (!exists) {
          typesArr.push(obj);
        }
      }
      console.log("typesArr", typesArr);
      setVerfiedFilteredPayments(typesArr);
    }
  }, [filterdPaymentTypes]);
  const [options, setOptions] = React.useState([]);

  function handleChange(searchString) {
    let opdIpd = 1;
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString)) {
      autoSearchPatientAdvance(searchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          console.log("response form patinet search", res);
          setOptions(res.result);
        });
    }
  }
  function getPatientInfoById(patientId) {
    let opdIpd = 1;
    console.log("response from patient is basepatientId", patientId);
    getPatientInfo(patientId, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        console.log("response from patient is base", res);

        setComingData(res.result);
        setAdmissionId(res.result.admissionId);
        if (!showPage) {
          setShowPage(true);
        }
      });
  }
  console.log("commingdata", comingData);
  useEffect(() => {
    if (props.patientId && props.patientId != null) {
      getPatientInfo(props.patientId, 1)
        .then((response) => response.data)
        .then((res) => {
          console.log("response from patient is base", res);
          setComingData(res.result);

          if (!showPage) {
            setShowPage(true);
          }
        });
    }
  }, [props.patientId, showPage]);

  // get selected Row data
  function handleSelectedRow() {}
  // actions and print
  const renderActions = (row, index) => {
    console.log("renderActionsrenderActions", row);
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "print" ? (
                    <Tooltip title={"Print"}>
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          handleOpenPrint(row.PatientAdvanceDetails);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };
  console.log("printstate", openPrint);
  return (
    <>
      <div className="mt-10 px-6 ">
        <div className="text-center text-black font-bold text-xl py-4">
          Patient Advance
        </div>
        <div className="lg:flex md:grid md:grid-cols-5 gap-2">
          {props.openAdvance === true ? null : (
            <div className="lg:w-5/12 md:col-span-4 z-50">
              <SearchDropdown
                control={control}
                placeholder="Search By Patient Name / MRNo / Mob No."
                dataArray={options}
                name="opdbillingSearch"
                searchIcon={true}
                isClearable={true}
                handleInputChange={handleChange}
                inputRef={{
                  ...register("opdbillingSearch", {
                    onChange: (e) => {
                      console.log("hiiii", e.target.value);
                      console.log("The patched object is ", e);
                      if (e.target.value !== null) {
                        // setSearchString(e.target.value);
                        getPatientInfoById(e.target.value.id);
                        fetchPatientDetails(e.target.value.id);
                      } else {
                        resetForm();
                        clearPage();
                        setSearchString("");
                      }
                    },
                  }),
                }}
              />
            </div>
          )}
        </div>
        {showPage ? (
          <>
            {/* Paitent Inf0 */}
            <fieldset className="mx-auto border w-full rounded my-3 bg-gray-100">
              <legend className="ml-6 my-2 rounded-xl">
                <p className="text-sm font-semibold tracking-wide mx-2">
                  Patient Details
                </p>
              </legend>
              {comingData ? (
                <>
                  <div className="">
                    <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-4 py-1 px-10 gap-2">
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-28">
                          Patient Name
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {`${comingData.patientName}`}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-28">Mr No.</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {`${comingData.mrNo}`}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-28">Mobile No.</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {`${comingData.MobileNo}`}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-28">Age</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {`${comingData.ageYear}`}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-28">
                          Cash Balance
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {(comingData && comingData.patientAdvance) || "0"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-4 py-2 px-10 gap-2"></div>
                  </div>
                </>
              ) : (
                <p className="mx-auto">"Data Not Found"</p>
              )}
            </fieldset>

            {/* Payment Form */}
            <div className="w-full">
              {/* Payment Inf0 */}

              <form onSubmit={handleSubmit(onSubmitPayment)} className="">
                <fieldset className="mx-auto border w-full rounded my-3">
                  <legend className="ml-6 my-2 rounded-xl">
                    <p className="text-sm font-semibold tracking-wide mx-2">
                      Payment Details
                    </p>
                  </legend>
                  <div className=" px-10 py-2">
                    {paymentTableData.map((item, index) => {
                      return (
                        <div
                          className="lg:grid lg:grid-cols-6 md:grid md:grid-cols-2 gap-2 py-2"
                          key={item.id}
                        >
                          <div className="whitespace-nowrap">
                            <DropdownField
                              control={control}
                              error={errors.paymentDetails?.[index]?.type}
                              name={`paymentDetails[${index}].type`}
                              label="Payment Type *"
                              dataArray={paymentTypes}
                              isSearchable={false}
                              placeholder="Payment Type*"
                              inputRef={{
                                ...register(`paymentDetails[${index}].type`, {
                                  onChange: (e) => {
                                    console.log(e.target.value);
                                    let PaymentTypeIs = e.target.value;
                                    let newData = [...paymentTableData];

                                    newData[index].paymentMethod =
                                      e.target.value;
                                    setPaymentTableData(newData);
                                    PaymentTypeResult(paymentTypes);
                                    if (e.target.value.label !== "CASH") {
                                      if (
                                        "referrenceNo" in
                                        paymentTableData[index]
                                      ) {
                                      } else {
                                        AddRefferenceNo(index, PaymentTypeIs);
                                      }
                                    } else {
                                      if (
                                        "referrenceNo" in
                                        paymentTableData[index]
                                      ) {
                                        RemoveRefferenceNo(
                                          index,
                                          PaymentTypeIs
                                        );
                                      }
                                    }
                                  },
                                }),
                              }}
                            />
                          </div>

                          {paymentDetails[index].type &&
                          paymentDetails[index].type.label === "UPI" ? (
                            <>
                              <div>
                                <DropdownField
                                  control={control}
                                  error={
                                    errors.paymentDetails?.[index]?.upiname
                                  }
                                  name={`paymentDetails[${index}].upiname`}
                                  label="Payment Type *"
                                  dataArray={upiTypes}
                                  isSearchable={false}
                                  placeholder="UPI Type*"
                                  inputRef={{
                                    ...register(
                                      `paymentDetails[${index}].upiname`,
                                      {
                                        onChange: (e) => {},
                                      }
                                    ),
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="">
                            <InputField
                              name={`paymentDetails[${index}].amount`}
                              variant="outlined"
                              label="Amount *"
                              type="number"
                              inputRef={{
                                ...register(`paymentDetails[${index}].amount`, {
                                  onChange: (e) => {
                                    if (
                                      e.target.value !== "" &&
                                      e.target.value !== "e" &&
                                      e.target.value !== "E" &&
                                      e.target.value !== "+" &&
                                      e.target.value !== "-" &&
                                      e.target.value > 0
                                    ) {
                                      let amountValue = e.target.value;
                                      let newData = [...paymentTableData];
                                      newData[index].amount = amountValue;

                                      setPaymentTableData(newData);
                                    }
                                    console.log(
                                      "payment type get",
                                      paymentDetails[index].type.label
                                    );

                                    if (
                                      e.target.value >= 200000 &&
                                      paymentDetails[index].type.label ===
                                        "CASH"
                                    ) {
                                      console.log("show pan card true");

                                      setShowPanCard(true);
                                    } else {
                                      setShowPanCard(false);
                                      console.log("show pan card false");
                                    }
                                  },
                                }),
                              }}
                              error={errors.paymentDetails?.[index]?.amount}
                              control={control}
                            />
                          </div>
                          {showPanCard ? (
                            <div className="">
                              <InputField
                                control={control}
                                name={`paymentDetails[${index}].pancard`}
                                variant="outlined"
                                label=" Pan Card *"
                                error={errors.paymentDetails?.[index]?.pancard}
                              />
                            </div>
                          ) : null}
                          {/* { "referrenceNo" in  item ? ( */}
                          {paymentDetails[index].type &&
                          paymentDetails[index].type.label !== "CASH" ? (
                            <>
                              <div sx={{ marginY: "0.5rem" }}>
                                <InputField
                                  // type="number"
                                  name={`paymentDetails[${index}].referrenceNo`}
                                  error={
                                    errors.paymentDetails?.[index]?.referrenceNo
                                  }
                                  control={control}
                                  label="Reference Number *"
                                  inputRef={{
                                    ...register(
                                      `paymentDetails[${index}].referrenceNo`,
                                      {
                                        onChange: (e) => {
                                          if (e.target.value !== "") {
                                            let newData = [...paymentTableData];
                                            newData[index].referrenceNo =
                                              e.target.value;
                                            setPaymentTableData(newData);
                                          }
                                        },
                                      }
                                    ),
                                  }}
                                />
                              </div>
                              {(paymentDetails[index].type &&
                                paymentDetails[index].type.label ===
                                  "DEBIT CARD") ||
                              paymentDetails[index].type.label ===
                                "CREDIT CARD" ||
                              paymentDetails[index].type.label === "ECS" ||
                              paymentDetails[index].type.label === "ONLINE" ||
                              paymentDetails[index].type.label === "CHEQUE" ||
                              paymentDetails[index].type.label === "DD" ? (
                                <>
                                  <div>
                                    <DropdownField
                                      control={control}
                                      error={
                                        errors.paymentDetails?.[index]?.bankname
                                      }
                                      name={`paymentDetails[${index}].bankname`}
                                      label="Bank Name"
                                      dataArray={Bank}
                                      isSearchable={true}
                                      placeholder="Bank Name *"
                                      searchIcon={true}
                                    />
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {(paymentDetails[index].type &&
                                paymentDetails[index].type.label ===
                                  "DEBIT CARD") ||
                              paymentDetails[index].type.label ===
                                "CREDIT CARD" ||
                              paymentDetails[index].type.label === "ECS" ||
                              paymentDetails[index].type.label === "CHEQUE" ||
                              paymentDetails[index].type.label === "DD" ? (
                                <>
                                  <div>
                                  <DatePickerFieldNew
                                    control={control}
                                    name={`paymentDetails[${index}].paymentDate`}
                                    label="Date"
                                    value={new Date()}
                                    disableFuture={true}
                                    disablePast={false}
                                    inputFormat="dd-MM-yyyy"
                                    inputRef={{
                                      ...register(
                                        `paymentDetails[${index}].paymentDate`,
                                        {
                                          onChange: (e) => {
                                            if (
                                              e.target.value.toString()
                                                .length === 55
                                            ) {
                                              let dateVal = format(
                                                e.target.value,
                                                "yyyy-MM-dd"
                                              );
                                              let timeVal =
                                                dateVal + "T" + "00:00:00:000Z";
                                              setSelectedFromDate(timeVal);
                                            }
                                          },
                                        }
                                      ),
                                    }}
                                  />
                                    
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          <div className="flex">
                            {paymentTableData.length !== 1 && (
                              <RemoveOutlinedIcon
                                className="mt-2 rounded-full border-2 border-red-600"
                                onClick={() => {
                                  deleteTableRow(
                                    index,
                                    paymentDetails[index].type
                                  );
                                }}
                              />
                            )}
                            {paymentTableData.length !== 4  ? (
                              <>
                                {paymentTableData.length - 1 === index && (
                                  <AddOutlinedIcon
                                    className="mt-2 mx-1 rounded-full border-2 border-cyan-600"
                                    onClick={()=>{
                                      addToPaymentArr(index)
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </fieldset>
                <div className="ml-10 my-2 grid grid-cols-6 gap-2">
                  <div>
                    <InputLimit
                      maxDigits={10}
                      name="mobileNo"
                      variant="outlined"
                      label="Mobile No *"
                      error={errors.mobileNo}
                      control={control}
                      //disabled={isDisabled}
                      inputRef={{
                        ...register("age", {
                          onChange: (e) => {
                            //

                            console.log(e.target);
                            // setEnteredAge(e.target.value);
                            // getBirthDetails(e);
                          },
                        }),
                      }}
                      onKeyDown={(e) => {
                        if (
                          e?.key === "-" ||
                          e?.key === "+" ||
                          e?.code === "ArrowUp" ||
                          e?.code === "ArrowDown" ||
                          e?.code === "NumpadDecimal"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="">
                    <InputField
                      name="remarks"
                      control={control}
                      error={errors.remarks}
                      label="Remarks"
                    />
                  </div>
                </div>
                <div className="flex col-span-1 justify-end py-2 gap-2">
                  {/* <CancelButton
                    onClick={() => {
                      props.handleCloseAdvance();
                    }}
                  /> */}
                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <CommonButton
                          label="Reset"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            reset();
                            resetForm();
                            clearPage();
                            setSearchString("");
                            setValue("opdbillingSearch", null);
                            setOptions([]);
                            resetPaymentModes()
                          }}
                        />
                      ) : null}
                    </>
                  ))}

                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <CommonButton
                          type="submit"
                          label="Save"
                          className="bg-green-600 text-white"
                          onClick={() => {
                            setMenuIdVal(obj);
                          }}
                        />
                      ) : null}
                    </>
                  ))}
                </div>
              </form>
              <CommonBackDrop openBackdrop={openBackdrop} />
            </div>

            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "secondary.light",
                },
              }}
            />
            {dataArr && dataArr.length > 0 ? (
              <>
                <div className="py-4">
                  <p className="text-lg font-semibold tracking-wide mx-0">
                    Advance Payment History
                  </p>
                </div>
                <div className="px-0">
                  {/* <PatientAdvanceTable
                  
                  data={dataArr}
                  
                  actions={actions}
                  
                  /> */}
                  <CommonDynamicTable
                    data={dataArr}
                    actions={actions}
                    actionsRequired={true}
                    renderActions={renderActions}
                    setSelectedRow={setSelectedRow}
                    handleSelectedRo={handleSelectedRow}
                    highlightRow={false}
                    tableClass={"rounded lg:h-52 md:h-72"}
                    removeHeaders={removeHeaders}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="text-center mt-10">No Record Found</div>
              </>
            )}
          </>
        ) : (
          ""
        )}

        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleClose}
          confirmationSubmitFunc={() => {
            if (props.openAdvance) {
              props?.setAdvanceAmount(
                Number(props.OldAdvanceAmount) +
                  Number(savePayment?.patientAdvance)
              );
            }
            handleClose();
            submitDataApit();
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Payment Details?"
          confirmationButtonMsg="Save Payment"
        />

        <CommonPrintModal
          open={openPrint}
          setOpen={setOpenPrint}
          handleOpen={handleOpenPrint}
          handleClose={handleClosePrint}
          urlforPrint={urlforPrint}
        />
      </div>
    </>
  );
}
