// useBase64Converter.js
import { useState } from "react";

const useBase64Converter = () => {
  const [base64Data, setBase64Data] = useState([]);

  const convertFilesToBase64 = (files) => {
    const base64DataArr = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i] !== undefined) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = function () {
          const base64Str = reader.result.split(",")[1];
          const convertedFile = {
            base64String: base64Str,
            fileName: file.name,
          };

          base64DataArr.push(convertedFile);

          // If all files are processed, update the state with the new base64Data
          // if (base64DataArr.length === files.length) {
          setBase64Data(base64DataArr);
          // }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  return { base64Data, convertFilesToBase64 };
};

export default useBase64Converter;
