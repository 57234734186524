import React, { useEffect, useState } from "react";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import InputField from "../../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import SelectableChagreTable from "./SelectableChagreTable";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  successAlert,
  errorAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  addNewCharges,
  autoSearchService,
  getAllCharges,
} from "../../../services/cliinicalChartServices/chargesServices/ChargesServices";
import { doctorDropdown } from "../../../../opd/services/patientappointmentservices/PatientVisitListServices";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import { useRef } from "react";
// import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";

export default function Charges(props) {
  const defaultValues = {
    serviceName: null,
    quantity: "",
    rate: "",
    doctor: null,
  };
  const checkboxVisible = false; // for table checkbox
  const [serviceSearch, setServiceSearch] = React.useState([]);
  const [data, setData] = React.useState([]); //use for Service data
  const [doctorOptions, setDoctorOptions] = useState();
  const [finalData, setFinalData] = React.useState();
  const [chargesData, setChargesData] = React.useState([]);
  const [openPost, setOpenPost] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);

  
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let opdIpd = 1;
  let admissionId = props.displayInfo?.AdmissionId || props.admissionId;
  
 

  let userInfoString = localStorage.getItem("userInfo");
  let userInfoObj = JSON.parse(userInfoString);
  let addedBy = userInfoObj.userId;
  let updatedBy = userInfoObj.userId;
  let serviceName = watch("serviceName");

  useEffect(() => {
    populateTable();
  }, [opdIpd, admissionId]);

  //populate the PainAssemssmetTable using the function populateTable
  const populateTable = () => {
    showSpinner(true);
    getAllCharges(opdIpd, admissionId)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setChargesData(res.result);
      });
  };

  // Handle Change Use For Service api
  const handleChange = (autoServceSearchString) => {
    
    let ClassId;
    // let CompanyId;

    if (props.displayInfo !== undefined) {
      ClassId = props.displayInfo?.ClassId;
      // CompanyId = props.displayInfo?.CompanyId;
    } else if (props.selectedPatient !== null) {
      ClassId = props.selectedPatient?.ClassId;
      // CompanyId = props.selectedPatient?.CompanyId;
    }

    if (
      autoServceSearchString !== ""
      // &&
      // UseCheckConsecutiveCharacters(autoServceSearchString)
    ) {
      let serviceObj = {
        applicableTo: 1,
        classId: ClassId,
        description: autoServceSearchString + "%",
        groupId: 0,
        isConcessionService: 0,
        isEmergency: false,
        isExternal: false,
        isFree: 0,
        serviceCode: "%",
        tariffId: Number(props.displayInfo?.TariffId || props?.tariffId),
        unitId: Number(userInfoObj.unitId),
        // companyId: CompanyId,
      };
      autoSearchService(serviceObj)
        .then((response) => response.data)
        .then((res) => {
          setServiceSearch(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.ServiceId === val.ServiceId);
      if (ind !== -1) {
        errorAlert("Service Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const addServiceData = () => {
    let serviceName = getValues("serviceName");
    let quantity = getValues("quantity");
    let rate = getValues("rate");
    let QtyNumVal = Number(quantity);
    let doctor = getValues("doctor");
    
    
    if (quantity === "" && serviceName === null && doctor === null) {
      setError("quantity", { type: "custom", message: "Required" });
      setError("serviceName", { type: "custom", message: "Required" });
      setError("doctor", { type: "custom", message: "Required" });
    } else if (quantity !== "" && serviceName !== null && doctor !== null) {
      clearErrors(["quantity"]);
      clearErrors(["serviceName"]);
      clearErrors(["doctor"]);
    } else if (quantity === "") {
      setError("quantity", { type: "custom", message: "Required" });
    } else if (serviceName === null) {
      setError("serviceName", { type: "custom", message: "Required" });
    }

    if (QtyNumVal > 9999 && QtyNumVal === 0) {
      setError("quantity", { type: "custom", message: "Required" });
    } else if (QtyNumVal < 9999 && QtyNumVal !== 0) {
      clearErrors(["quantity"]);
    }

    if (rate === 0 || rate === "") {
      setError("rate", { type: "custom", message: "Required" });
    }

    if (
      serviceName !== null &&
      quantity !== "" &&
      QtyNumVal !== 0 &&
      QtyNumVal > 0 &&
      QtyNumVal <= 9999 &&
      rate !== 0 &&
      rate !== ""
    ) {
      let serviceObj = {
        doctor: {
          _id: serviceName?.DoctorShare === true ? doctor.id : null,
          label: serviceName?.DoctorShare === true ? doctor.label : "-",
        },
        ServiceId: serviceName.ServiceId,
        quantity: Number(quantity),
        rate: Number(rate),
        serviceName: {
          label: serviceName.label,
        },
        groupId: Number(serviceName.GroupId),
      };

      let arr = [...data];
      arr.push(serviceObj);
      setData(mergeArray(arr));
    }
  };

  useEffect(() => {
    if (serviceName !== null) {
      setValue("quantity", 1);
      setValue("rate", serviceName.Rate);
      clearErrors(["quantity"]);
    } else if (serviceName === null) {
      setValue("quantity", "");
    }
  }, [serviceName]);

  //function to call dropdown doctor
  const getDoctorDropdownList = (searchDoctorString) => {
    if (
      searchDoctorString !== ""
      // &&
      // UseCheckConsecutiveCharacters(searchDoctorString)
    ) {
      doctorDropdown(searchDoctorString)
        .then((response) => response.data)
        .then((res) => {
          if (searchDoctorString !== null) {
            setDoctorOptions(res.result);
          }
        });
    }
  };

  function postCharges(obj) {
    addNewCharges(obj, props.menuId, props.privilege)
      .then((response) => {
        if (response.data.statusCode === 200 && admissionId !== null) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateTable(opdIpd, admissionId);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }
  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postCharges(finalData);
    setData([]);
  }

  
  const onSubmitDataHandler = () => {
    let finalObj = [];
    data.map((item) => {
      
      let amount = item.quantity * item.rate;
      let netAmount = Number(item.quantity) * item.rate;
      let myObj = {
        addedBy: addedBy,
        chargeId: 0, ////
        chargeIdPackage: 0,
        concession: 0,
        discount: 0,
        doctorId: item.doctor["_id"] !== null ? item.doctor["_id"] : Number(0),
        doctorShareAmount: 0,
        emergency: false,
        hospShareAmount: 0,
        isCancelled: 0, ///
        isPackageService: serviceName?.IsPackage,
        netAmount: netAmount,
        opd_Ipd_External: 1,
        opd_Ipd_External_Id: admissionId,
        originalChargeId: 0, ///
        quantity: Number(item.quantity),
        rate: Number(item.rate),
        serviceId: Number(item.ServiceId),
        sponsorType: false,
        staffFree: 0,
        tariffId: Number(props.displayInfo?.TariffId || props?.tariffId),
        totalAmount: amount,
        updatedBy: updatedBy,
        groupId: item?.groupId,
      };
      finalObj.push(myObj);
    });
    setFinalData(finalObj);

    if (data.length > 0) {
      setOpenPost(true);
    } else if (props.admissionId) {
      errorAlert("Please Enter Charges");
    }
  };

  useEffect(() => {
    if (
      props.patientInformation !== undefined ||
      props.patientInformation !== "" ||
      props.selectedPatient !== null
    ) {
      let loginDoctior = {
        id:
          props.patientInformation?.DoctorId || props.selectedPatient?.DoctorId,
        vallue:
          props.patientInformation?.DoctorId || props.selectedPatient?.DoctorId,
        label:
          props.patientInformation?.DoctorName ||
          props.selectedPatient?.DoctorId,
      };
      
      if (serviceName?.DoctorShare !== null) {
        setValue("doctor", loginDoctior);
      }
    }
  }, [serviceName]);

  const searchServiceElement = useRef("");
  const qtyElement = useRef("");
  const rateElement = useRef("");
  const doctorElement = useRef("");
  const addBtnElement = useRef("");

  function focusQtyInput(type) {
    let fieldsetTag;
    if (type === "qty") {
      fieldsetTag = qtyElement.current;
    }
    if (type === "rate") {
      fieldsetTag = rateElement.current;
    }
    if (type === "doctor") {
      fieldsetTag = doctorElement.current;
    }
    if (type === "addBtn") {
      fieldsetTag = addBtnElement.current;
    }
    let inputTagCollection = fieldsetTag?.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  // useEffect(() => {
  //   
  //     "searchServiceElement",
  //     props.displayInfo?.Discharged,
  //     searchServiceElement
  //   );
  //   if (props.displayInfo?.Discharged || props.selectedPatient?.Discharged) {
  //     let fieldsetTag = searchServiceElement.current;
  //     let inputTagCollection = fieldsetTag?.getElementsByTagName("input");
  //     let inputTagOne = inputTagCollection[0];
  //     inputTagOne.focus();
  //   }
  // }, [props]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <label className="text-sm font-semibold whitespace-nowrap">
          Charges
        </label>

        {spinner ? (
          <div className="grid justify-center">
            <LoadingSpinner />
          </div>
        ) : null}

        <div>
          {chargesData.length > 0 && spinner === false ? (
            <CommonDynamicTableNew
              dataResult={chargesData}
              tableClass={"rounded h-56 xl:h-72 border"}
              highlightRow={false}
              removeHeaders={[
                "ChargeId",
                "Service Code",
                "Opd_Ipd_External_Id",
                "LoginName",
                "Doctor",
                "OTSheetNo",
                "Concession",
                "Discount",
                "StaffFree",
                "DoctorId",
                "RateEditable",
                "IsPackage",
                "SponsorType",
                "ServiceId",
                "deductible",
                "DiscountPerc",
                "DiscountAmt",
                "DeductionPerc",
                "DeductionAmt",
                "NetAmt",
                "AdminCharges",
                "IsServiceTaxApp",
                "visitId",
              ]}
            />
          ) : (
            <h3 className="flex justify-center my-12 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </div>

        {props.displayInfo?.Discharged === false ||
        props.selectedPatient?.Discharged === false ? (
          <>
            <div className="flex gap-2">
              <div className="grid grid-cols-3 gap-2 pt-1 pb-2">
                <div className="flex  gap-2 " ref={searchServiceElement}>
                  <SearchDropdown
                    control={control}
                    name="serviceName"
                    placeholder="Search by Service"
                    label="Search by Services"
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    error={errors.serviceName}
                    dataArray={serviceSearch}
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("serviceName", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            focusQtyInput("qty");
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="flex gap-2 ">
                  <div ref={qtyElement}>
                    <InputField
                      name="quantity"
                      type="number"
                      error={errors.quantity}
                      variant="outlined"
                      label="Qty"
                      control={control}
                      onKeyDown={(e) => {
                        if (e.code === "Enter") {
                          e.preventDefault();
                          focusQtyInput("rate");
                        }
                      }}
                    />
                  </div>
                  <div ref={rateElement}>
                    <InputField
                      name="rate"
                      label=" Rate"
                      placeholder="Rate"
                      control={control}
                      error={errors.rate}
                      disabled={
                        serviceName?.RateEditable === false ||
                        serviceName?.Rate > 0
                          ? true
                          : false
                      }
                      type="number"
                      onKeyDown={(e) => {
                        if (e.code === "Enter") {
                          e.preventDefault();
                          focusQtyInput("doctor");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  {serviceName?.DoctorShare === true ? (
                    <div
                      className="w-full"
                      ref={doctorElement}
                      onKeyDown={(e) => {
                        
                        if (e.code === "Enter") {
                          e.preventDefault();
                          addBtnElement.current.focus();
                        }
                      }}
                    >
                      <SearchDropdown
                        control={control}
                        name="doctor"
                        placeholder="Doctor"
                        label="Doctor"
                        isSearchable={true}
                        searchIcon={true}
                        isClearable={true}
                        error={errors.doctor}
                        dataArray={doctorOptions}
                        handleInputChange={getDoctorDropdownList}
                        inputRef={{
                          ...register("doctor", {
                            onChange: (e) => {
                              if (e.target.value !== null) {
                                addBtnElement.current.focus();
                              }
                            },
                          }),
                        }}
                      />
                    </div>
                  ) : null}
                  {props.patientInformation?.AllowToGO === true ||
                  props.selectedPatient?.AllowToGO === true ? null : (
                    <CommonButton
                      referance={addBtnElement}
                      type="button"
                      className="bg-customGreen text-white"
                      label="Add"
                      
                      onClick={() => {
                        if (admissionId) {
                          addServiceData();
                          setValue("serviceName", null);
                          setValue("quantity", "");
                          setValue("rate", "");
                          setValue("doctor", null);
                        } else {
                          addServiceData();
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <SelectableChagreTable
                data={data}
                setData={setData}
                checkboxVisible={checkboxVisible}
                serviceName={serviceName}
              />
            </div>
            <div className="flex justify-end space-x-3 items-center">
              {props.userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        
                        onClick={() => {
                          reset(defaultValues);
                          setData([]);
                        }}
                      />
                      {props.patientInformation?.AllowToGO === true ||
                      props.selectedPatient?.AllowToGO === true ? null : (
                        <CommonButton
                          type="submit"
                          className="bg-customGreen text-white"
                          label="Save"
                          
                          onClick={() => {
                            props.setPrivilege(obj.action);
                          }}
                        />
                      )}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </>
        ) : null}
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
