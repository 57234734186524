import React from "react";
import { useForm } from "react-hook-form";
import RadioField from "../../common/components/FormFields/RadioField";
// import {
//   getCounterReturnPatients,
//   getItemDetailsById,
//   savePharmacyReturn,
// } from "../pharmacyServices/pharmaServices";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import CommonButton from "../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import {
  errorAlert,
  errorAlertCustom,
  successAlert,
  warningAlert,
} from "../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../commonServices/functionalityServices/FunctionalityServices";
import CommonPrintModal from "../CommonPrintModal";
import CounterSalesReturnTable from "./CounterSalesReturnTable";
import SalesModal from "./SalesModal";
import { printCounterSalesReturn, saveCounterSalesReturn } from "./Services";
import { routeToPrinterDevice } from "../../commonServices/barCodeServices/BarCodeServices";
import { blobToBase64 } from "../../common/components/printModal/BlobToB64";

const patientTypeObj = [
  { id: "OPD", value: "OPD", label: "OPD", name: "OPD" },
  { id: "IPD", value: "IPD", label: "IPD", name: "IPD" },
  { id: "COUNTER", value: "COUNTER", label: "Counter", name: "COUNTER" },
];

function CounterSalesReturn() {
  let printerSettings=JSON.parse(localStorage.getItem("defaultPrinters"))
  const [openSalesModal, setOpenSalesModal] = React.useState(false);
  const [returnItems, setReturnItems] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [totalGstAmount, setTotalGstAmount] = React.useState(0);
  const [netReturnAmount, setNetReturnAmount] = React.useState(0);
  const [finalData, setFinalData] = React.useState([]);
  const [patientName, setPatientName] = React.useState("");
  const [retSalesId, setRetSalesId] = React.useState(null);
  const [postObject, setPostObject] = React.useState({});
  const [openChild, setOpenChild] = React.useState(false);
  const [isValidRecord, setIsValidRecord] = React.useState(true);
  const [resetCount, setResetCount] = React.useState(0);
  const [billPdf, setBillPdf] = React.useState("");
  const [openCommonPrintModal, setOpenCommonPrintModal] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = React.useState([]);
  let location = useLocation();

  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  const handleClosePrintModal = () => {
    if (openCommonPrintModal === true) {
      setOpenCommonPrintModal(false);
      setBillPdf("");
    }
  };

  const handleCloseChild = () => {
    if (openChild === true) {
      setOpenChild(false);
    }
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table

  const handleCloseSalesModal = () => {
    if (openSalesModal === true) {
      setOpenSalesModal(false);
    }
  };

  const defaultValues = {
    counterSalesReturn: "single",
    patientType: "OPD",
  };

  const {
    setValue,
    control,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  React.useEffect(() => {
    let Amount = 0;
    let discount = 0;
    let gstVal = 0;
    let netReturnVal = 0;
    if (finalData.length > 0) {
      for (let object of finalData) {
        Amount = Amount + Number(object.Rate);
        setTotalAmount(Amount);
        discount = discount + Number(object.discountAmount);
        setDiscountAmount(discount);
        gstVal = gstVal + Number(object.vatAmount);
        setTotalGstAmount(gstVal);
        netReturnVal = netReturnVal + Number(object.netReturnAmount);
        setNetReturnAmount(netReturnVal);
      }
    } else {
      setTotalAmount(0);
      setDiscountAmount(0);
      setTotalGstAmount(0);
      setNetReturnAmount(0);
    }
  }, [finalData]);

  let userInfoObj = JSON.parse(localStorage.getItem("userInfo"));

  const onSubmitDataHandler = () => {
    if (finalData.length > 0) {
      console.log("The final data for return is :", finalData);

      let returnArray = [];
      // AvailableQtyForReturn
      for (let object of finalData) {
        let itemObject = {
          amount: Number(object.returnAmount),
          batchCode: object["Batch Code"],
          concessionAmt: 0,
          concessionPercentage: 0,
          conversion: 0,
          discountAmt: Number(object.discountAmount),
          discountPercentage: object["Discount Percentage"],
          expiryDate: new Date(object["Expiry Date"]),
          itemId: Number(object["Item Code"]),
          mrp: object.Rate,
          otherTax: 0,
          purchaseRate: 0,
          returnQty: Number(object.returnQuantity),
          saleReturnRate: Number(object.netReturnAmount),
          salesId: retSalesId,
          availableQtyForReturn:
            Number(object?.["Qty Available For Return"]) -
            Number(object.returnQuantity),
          staffFreeAmt: 0,
          staffFreePercentage: 0,
          taxAmount: 0,
          totalAmount: Number(object.netReturnAmount),
          totalForVat: 0,
          unitLandedRate: 0,
          vatAmount: Number(object.vatAmount),
          vatPercentage: object["VAT Percentage"],
        };
        returnArray.push(itemObject);
      }

      let finalObj = {
        privilege: privilege,
        menuId: location?.state?.menuId,
        addedBy: 0,
        advanceId: 0,
        cashCountId: userInfoObj?.cashCounterId,
        cashCreditStaff: 0,
        cashReturn: 0,
        concession: 0,
        discountAmount: discountAmount,
        isBlankSalesReturn: true,
        isFree: true,
        isPrinted: true,
        patientName: patientName,
        remark: "",
        salesReturnDetailsDtoList: returnArray,
        serChargeAmt: 0,
        staffFree: 0,
        storeId: userInfoObj?.storeId,
        totalAmount: totalAmount,
        totalOtherTaxAmt: 0,
        totalPaidAmount: netReturnAmount,
        totalTaxAmt: 0,
        totalVatAmount: totalGstAmount,
        // patientName:
      };
      setPostObject(finalObj);
      if (isValidRecord === true) {
        setOpenChild(true);
      }
    } else {
      warningAlert("Please select at least one item..!");
    }
  };

  const handleDirectPrint = async (printResponse) => {
    let b64 = await blobToBase64(printResponse);
    console.log("The print response to base64 :",b64);

    const obj = [
      {
        printerName: printerSettings?.defaultPrinter,
        pdf: b64,
        numberCopies: Number(printerSettings?.printPages),
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      successAlert("Record Printed !");
    }).catch(() => {
      errorAlert("Error while printing !")
    })
  };

  function openPrintWindow(billId) {
    if (billId) {
      let requiredBillId = billId;

      printCounterSalesReturn(requiredBillId).then((response) => {
        if (Number(printerSettings?.printPages) > 0) {
          handleDirectPrint(response)
        } else {
          if (response.status === 200) {
            //Get Reponse JSON
            const blb = new Blob([response.data], { type: "text/plain" });
            const reader = new FileReader();
            // Start reading the blob as text.
            reader.readAsText(blb);
            //Convert Blob Response to JSON String
            reader.addEventListener("loadend", (e) => {
              if (e.target.result.charAt(0) !== "{") {
                setOpenCommonPrintModal(true);
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);

                setBillPdf(fileURL);
              } else {
                setBillPdf("");
              }
            });
          }
        }
      });
    }
  }

  const postRecord = () => {
    setOpenBackdrop(true);
    setOpenChild(false);
    saveCounterSalesReturn(postObject)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert(res.message);
        openPrintWindow(res.result);
        setResetCount(resetCount + 1);
        setReturnItems([]);
        handleCloseChild();
        setTotalAmount(0);
        setDiscountAmount(0);
        setTotalGstAmount(0);
        setNetReturnAmount(0);
        setFinalData([]);
      })
      .catch((error) => {
        errorAlert(error.message || error.response.data.message);
        setOpenBackdrop(false);
      });
  };

  const handleReset = () => {
    setReturnItems([]);
    setTotalAmount(0);
    setDiscountAmount(0);
    setNetReturnAmount(0);
    setTotalGstAmount(0);
    setFinalData([]);
  };
  let component = "return";

  return (
    <div className="grid ">
      <div className="mt-12 px-2 max-w-[99%] overflow-hidden">
        <div className="text-lg font-semibold flex justify-center my-2">
          Counter Sales Return
        </div>
        <div className=" grid lg:flex gap-2 w-full ">
          <Grid container spacing={2}>
            <Grid item lg={4} sm={8}>
              {/*<div className=" flex space-x-2 whitespace-nowrap lg:w-[32%] items-center">
                <label className="font-semibold whitespace-nowrap">
                  Patient Type &nbsp; :
                </label>
                <div className="pl-2 ">
                  <RadioField
                    className="whitespace-nowrap"
                    control={control}
                    name="patientType"
                    dataArray={patientTypeObj}
                  />
                </div>
              </div>*/}
            </Grid>
            <Grid item lg={8} sm={8}>
              <div className="flex justify-end">
                {userActions.map((actions) => (
                  <>
                    {!actions.isAction ? (
                      <>
                        {actions.action === "View" ? (
                          <div className="flex space-x-3 justify-end">
                            <button
                              type="button"
                              className="h-9 px-3 bg-customBlue text-white rounded "
                              onClick={() => {
                                setOpenSalesModal(true);
                                setPrivilege(actions.action);
                              }}
                            >
                              View List of Bills
                            </button>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="">
          <CounterSalesReturnTable
            returnItems={returnItems}
            setReturnItems={setReturnItems}
            finalData={finalData}
            setFinalData={setFinalData}
            setIsValidRecord={setIsValidRecord}
            resetCount={resetCount}
          />
        </div>
        <div className="grid lg:grid-cols-5 gap-2 pb-2">
          <div className="flex gap-2 items-center">
            <div className="text-lg font-semibold">Total Price :</div>
            <label className="text-gray-600">
              {Number(totalAmount).toFixed(2)}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-lg font-semibold">Disc Amt. :</div>
            <label className="text-gray-600">
              {Number(discountAmount).toFixed(2)}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-lg font-semibold">GST Amt. :</div>
            <label className="text-gray-600">
              {Number(totalGstAmount).toFixed(2)}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-lg font-semibold">Net Return Amt :</div>
            <label className="text-gray-600">
              {Number(netReturnAmount).toFixed(2)}
            </label>
          </div>
          <div>
            {userActions.map((actions) => (
              <>
                {!actions.isAction ? (
                  <>
                    {actions.action === "Create" ? (
                      <div
                        onClick={() => {
                          setPrivilege(actions.action);
                        }}
                        className="flex space-x-3 justify-end"
                      >
                        <CommonButton
                          label={"Reset"}
                          type="button"
                          onClick={handleReset}
                          className="h-9 px-3 border border-customRed text-customRed rounded text-base font-medium"
                        />

                        <CommonButton
                          label={"Save"}
                          type="button"
                          className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium"
                          onClick={onSubmitDataHandler}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        <SalesModal
          open={openSalesModal}
          close={handleCloseSalesModal}
          setReturnItems={setReturnItems}
          returnItems={returnItems}
          setPatientName={setPatientName}
          setRetSalesId={setRetSalesId}
          resetCount={resetCount}
          setResetCount={setResetCount}
          component={component}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />

        <ConfirmationModal
          confirmationOpen={openChild}
          confirmationHandleClose={handleCloseChild}
          confirmationSubmitFunc={postRecord}
          confirmationLabel="Confirmation "
          confirmationMsg="Are you sure want to return items ?"
          confirmationButtonMsg="Save"
        />
        <CommonPrintModal
          open={openCommonPrintModal}
          close={handleClosePrintModal}
          document={billPdf}
        />
        <CommonBackDrop openBackdrop={openBackdrop} />
      </div>
    </div>
  );
}

export default CounterSalesReturn;

