import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerField from "../../../common/components/FormFields/DatePickerField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import SampleCollectionListTable from "../../Pathology/common/SampleCollectionListTable";

const listOfOrders = {
  message: "List of Orders found",
  result: [
    {
      Date: "12-01-2023",
      "Order No": "115695",
      "Patient Name": "Vikrant Pawar",
    },
    {
      Date: "12-01-2023",
      "Order No": "115695",
      "Patient Name": "Vikrant Pawar",
    },
    {
      Date: "12-01-2023",
      "Order No": "115695",
      "Patient Name": "Vikrant Pawar",
    },
    {
      Date: "12-01-2023",
      "Order No": "115695",
      "Patient Name": "Vikrant Pawar",
    },
    {
      Date: "12-01-2023",
      "Order No": "115695",
      "Patient Name": "Vikrant Pawar",
    },
  ],
};

const tests = {
  message: "List of Orders found",
  result: [
    {
      Category: "BIOCHEMIST",
      "Test/Profile Code": "012345",
      "Test/Profile Name": "ACETONE/KETONE BLOOD",
      "Test/Profile": "Test",
      "Received By": "Jeevan Sasane",
    },
    {
      Category: "BIOCHEMIST",
      "Test/Profile Code": "012345",
      "Test/Profile Name": "ACETONE/KETONE BLOOD",
      "Test/Profile": "Test",
      "Received By": "Jeevan Sasane",
    },
    {
      Category: "BIOCHEMIST",
      "Test/Profile Code": "012345",
      "Test/Profile Name": "ACETONE/KETONE BLOOD",
      "Test/Profile": "Test",
      "Received By": "Jeevan Sasane",
    },
    {
      Category: "BIOCHEMIST",
      "Test/Profile Code": "012345",
      "Test/Profile Name": "ACETONE/KETONE BLOOD",
      "Test/Profile": "Test",
      "Received By": "Jeevan Sasane",
    },
    {
      Category: "BIOCHEMIST",
      "Test/Profile Code": "012345",
      "Test/Profile Name": "ACETONE/KETONE BLOOD",
      "Test/Profile": "Test",
      "Received By": "Jeevan Sasane",
    },
  ],
};
const RadioReportDelivery = () => {
  const [sampleCategory, setSampleCategory] = useState();
  const [unit, setUnit] = useState();
  const [searchString, setSearchString] = useState("");
  const defaultValues = {
    patientType: "All",
    orders: "Pending",
    searchBar: "",
    fromDate: null,
    toDate: null,
    category: null,
    testType: null,
    unit: null,
  };

  const schema = yup.object().shape({});
  const { control, reset, handleSubmit, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const patientType = [
    {
      id: "All",
      value: "All",
      label: "All",
    },
    {
      id: "Opd",
      value: "Opd",
      label: "OPD",
    },
    {
      id: "Ipd",
      value: "Ipd",
      label: "IPD",
    },
    {
      id: "Emergency",
      value: "Emergency",
      label: "Emergency",
    },
    {
      id: "External",
      value: "External",
      label: "External",
    },
  ];

  const orders = [
    { id: "Pending", label: "Pending", value: "Pending" },
    { id: "Delivered", label: "Delivered", value: "Delivered" },
  ];
  const testType = [
    {
      id: "All",
      value: "All",
      label: "All",
    },
    {
      id: "Normal",
      value: "Normal",
      label: "Normal",
    },
    {
      id: "Retest",
      value: "Retest",
      label: "Retest",
    },
    {
      id: "Dummy",
      value: "Dummy",
      label: "Dummy",
    },
  ];

  const handleChange = (string) => {
    setSearchString(string);
  };

  const onSubmitData = (data) => {};
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="mt-12 space-y-2">
          <div className="text-center">
            <label className="text-lg font-bold">
              Radiology Report Delivery
            </label>
          </div>
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
            <div className="col-span-2 xl:col-span-2">
              <SearchBar
                control={control}
                name="searchBar"
                placeholder="Search by Name/MRNo"
                searchIcon={true}
                isSearchable={true}
                isClearable={true}
                onInputChange={handleChange}
              />
            </div>
            <div>
              <DatePickerField
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("fromDate", newValue);
                }}
                disableFuture={true}
                inputFormat="DD-MM-YYYY"
              />
            </div>
            <div>
              <DatePickerField
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("toDate", newValue);
                }}
                disableFuture={true}
                inputFormat="DD-MM-YYYY"
              />
            </div>
            <div className="w-full">
              <DropdownField
                control={control}
                name="testType"
                placeholder="Test Type"
                dataArray={testType}
                isSearchable={true}
              />
            </div>
            <div className="col-span-full lg:col-span-3 flex gap-2 items-center">
              <label className="font-semibold text-sm lg:hidden">
                Patient Type :&nbsp;
              </label>
              <div className="items-center mt-1.5 lg:hidden">
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={patientType}
                />
              </div>
            </div>
            <div className="col-span-2 flex gap-2 items-center">
              <label className="font-semibold text-sm lg:hidden">
                Orders :&nbsp;
              </label>
              <div className="items-center mt-1 lg:hidden">
                <RadioField
                  control={control}
                  name="orders"
                  dataArray={orders}
                />
              </div>
            </div>
            <div className="flex justify-end lg:hidden">
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={() => {
                  // getPatientList();
                }}
              />
            </div>
            <div className="flex flex-wrap col-span-full">
              <div className="col-span-full lg:col-span-3 flex gap-2 items-center">
                <label className="font-semibold text-sm hidden lg:block">
                  Patient Type :&nbsp;
                </label>
                <div className="items-center mt-1.5 hidden lg:block">
                  <RadioField
                    control={control}
                    name="patientType"
                    dataArray={patientType}
                  />
                </div>
              </div>
              <div className="col-span-2 flex gap-2 items-center">
                <label className="font-semibold text-sm hidden lg:block">
                  Orders :&nbsp;
                </label>
                <div className="items-center mt-1 hidden lg:block">
                  <RadioField
                    control={control}
                    name="orders"
                    dataArray={orders}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <div className="hidden lg:block">
                  <CommonButton
                    type="button"
                    className="bg-customBlue text-white"
                    searchIcon={true}
                    onClick={() => {
                      // getPatientList();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label className="text-lg font-bold mx-2">List Of Orders</label>
            <SampleCollectionListTable
              data={listOfOrders}
              dataResult={listOfOrders.result}
            />
          </div>
          <div>
            <label className="text-lg font-bold mx-2">Tests</label>
            <SampleCollectionListTable data={tests} dataResult={tests.result} />
          </div>
          <div className="flex justify-end gap-3">
            <CommonButton
              type="submit"
              label="Save"
              className="border border-customRed text-customRed"
              onClick={() => {
                reset(defaultValues);
              }}
            />
            <CommonButton
              type="submit"
              label="Save"
              className="bg-customGreen text-white"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default RadioReportDelivery;
