import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Textarea from "@mui/joy/Textarea";
import { styled } from "@mui/joy/styles";
import React from "react";
import { Controller } from "react-hook-form";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from "@mui/material/styles";

const materialTheme = materialExtendTheme();

const StyledTextarea = styled(TextareaAutosize)({
  resize: "none",
  border: "none",
  minWidth: 0,
  outline: 0,
  padding: 0,
  paddingBlockStart: "1em",
  paddingInlineEnd: `var(--Textarea-paddingInline)`,
  flex: "auto",
  alignSelf: "stretch",
  color: "inherit",
  backgroundColor: "transparent",
  fontFamily: "inherit",
  fontSize: "inherit",
  fontStyle: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  "&::placeholder": {
    opacity: 0,
    transition: "0.1s ease-out",
  },
  "&:focus::placeholder": {
    opacity: 1,
  },
  "&:focus + textarea + label, &:not(:placeholder-shown) + textarea + label": {
    top: "0.5rem",
    fontSize: "0.75rem",
  },
  "&:focus + textarea + label": {
    color: "var(--Textarea-focusedHighlight)",
  },
});

const StyledLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  lineHeight: 1,
  top: "calc((var(--Textarea-minHeight) - 1em) / 2)",
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
}));

const InnerTextarea = React.forwardRef(function InnerTextarea(
  { name, label, placeholder, defaultValue, minRows, maxRows, ...props },
  ref
) {
  const id = React.useId();

  return (
    <React.Fragment>
      <StyledTextarea
        {...props}
        {...{ name, id }}
        ref={ref} // Forward the ref here
        placeholder={placeholder}
        minRows={minRows}
        maxRows={maxRows}
      />
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </React.Fragment>
  );
});

const InputArea = React.forwardRef(function InputArea(
  {
    name,
    label,
    placeholder,
    defaultValue,
    control,
    minRows,
    maxRows,
    size,
    value,
    disabled,
    slotProps,
    ...props
  },
  ref
) {
  return (
    <MaterialCssVarsProvider
      theme={{
        [MATERIAL_THEME_ID]: materialTheme,
      }}
    >
      <JoyCssVarsProvider>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Textarea
              slots={{ textarea: InnerTextarea }}
              slotProps={{
                textarea: {
                  ...field,
                  placeholder,
                  name,
                  label,
                  defaultValue,
                  minRows,
                  maxRows,
                  ...slotProps,
                  ref,
                },
              }}
              sx={{
                borderRadius: "6px",
                backgroundColor: disabled ? "#f0f0f0" : "white",
              }}
              minRows={minRows}
              maxRows={maxRows}
              size={size}
              {...props}
              value={value}
              disabled={disabled}
              onKeyDown={(e) => {
                if (e.altKey) {
                  e.preventDefault();
                }
              }}
            />
          )}
        />
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
});

export default InputArea;
