import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../../common/components/FormFields/InputField";
import { fetchBedCategory } from "../../../../../commonServices/miscellaneousServices/MiscServices";
import { fetchAdmissionTag } from "../../../../services/admissionServices/AdmissionServices";

const Other = () => {
  const [bedCategory, setBedCategory] = useState();
  const [admissionTag, setAdmissionTag] = useState();
  const { control } = useFormContext();

  useEffect(() => {
    PrefferedClass();
    AdmissionTag();
  }, []);

  const PrefferedClass = () => {
    fetchBedCategory()
      .then((response) => response.data)
      .then((res) => {
        setBedCategory(res.result);
      });
  };

  const AdmissionTag = () => {
    fetchAdmissionTag()
      .then((response) => response.data)
      .then((res) => {
        setAdmissionTag(res.result);
      });
  };
  return (
    <div className="grid grid-cols-3 gap-3">
      <div>
        <DropdownField
          control={control}
          name="prefferedClass"
          label="Preferred Class"
          dataArray={bedCategory}
          isSearchable={false}
          isClearable={false}
          placeholder="Preferred Class"
        />
      </div>
      <div>
        <DropdownField
          control={control}
          name="admissionTagColor"
          label="Admission Tag Color"
          dataArray={admissionTag}
          isSearchable={false}
          isClearable={false}
          placeholder="Admission Tag Color"
        />
      </div>

      <div>
        <InputField
          name="remarkOfAccount"
          variant="outlined"
          label="Remark"
          control={control}
          disabled={false}
          inputProps={{ maxLength: 100 }}
        />
      </div>
      <div>
        <InputField
          name="remarkOfBill"
          variant="outlined"
          label="Remark Of Bill "
          control={control}
          disabled={false}
          inputProps={{ maxLength: 100 }}
        />
      </div>
    </div>
  );
};

export default Other;
