import React from "react";
import { FormControl, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { AccountCircle } from "@mui/icons-material";

const InputFieldForSearch = ({
  sx,
  variant,
  defaultValue,
  inputProps,
  type,
  disabled,
  inputRef,
  name,
  label,
  error,
  control,
  dontCapitalize,
  onFocus,
  autoFocus,
  ref,
  focused,
  autoComplete,
  isFocused,
  InputProps
  
}) => {
  // InputProps={{
  //   startAdornment: (
  //     <InputAdornment position="end">
  //       <AccountCircle />
  //     </InputAdornment>
  //   ),
  // }}
  return (
    <FormControl fullWidth size="small" sx={sx}>
      <Controller
        render={({ field }) => (
          <TextField
          isFocused={isFocused}
          autoComplete={autoComplete}
            focused={focused}
            onFocus={onFocus}
            autoFocus={autoFocus}
            className="h-10 bg-white"
            inputRef={inputRef}
            ref={ref}
            // inputProps={
            //   dontCapitalize
            //     ? inputProps
            //     : (inputProps, { style: { textTransform: "capitalize" } })
            // }
            InputProps={InputProps}
            type={type}
            disabled={disabled}
            error={!!error?.message}
            variant={variant}
            label={label}
            placeholder={label}
            name={name}
            fullWidth
            {...field}
            size="small"
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {/* <FormHelperText style={{ color: "#d32f2f" }}>
        {error?.message}
      </FormHelperText> */}
    </FormControl>
  );
};

export default InputFieldForSearch;
