import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { Box, Divider, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { EyeOnIcon } from "../../../../../assets/icons/CustomIcons";
import CommonDynamicTableNew from "../../../../../common/components/CommonTable/CommonDynamicTableNew";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../../common/components/FormFields/RadioField";
import {
  deleteAdmissionDocument,
  fetchDcumentType,
  fetchDocuments,
} from "../../../../services/admissionServices/AdmissionServices";
import DocumentsViewModal from "../../commonModal/DocumentsViewModal";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import {
  errorAlert,
  successAlert,
} from "../../../../../common/components/Toasts/CustomToasts";

const IndividualAdmitting = [
  {
    id: 1,
    value: "Self",
    label: "Self",
  },
  {
    id: 2,
    value: "Relative",
    label: "Relative",
  },
  {
    id: 3,
    value: "Other",
    label: "Other",
  },
];

const Relative = [
  {
    id: 1,
    value: "Blood",
    label: "Blood",
  },
  {
    id: 2,
    value: "Marrital",
    label: "Marrital",
  },
  {
    id: 3,
    value: "Friends",
    label: "Friends",
  },
  {
    id: 4,
    value: "Other",
    label: "Other",
  },
];

const ModeOfPayment = [
  {
    id: 1,
    value: "Mediclaim",
    label: "Mediclaim",
  },
  {
    id: 2,
    value: "Cash",
    label: "Cash",
  },
  {
    id: 3,
    value: "Debit/Credit Card",
    label: "Debit/Credit Card",
  },
  {
    id: 4,
    value: "Corporate",
    label: "Corporate",
  },
  {
    id: 5,
    value: "Other",
    label: "Other",
  },
];

const Documents = (props) => {
  const [documentsType, setDocumentsType] = useState();
  //
  const [pathForDocView, setPathForDocView] = useState();
  //
  const [openDocumentsViewModal, setOpenDocumentsViewModal] = React.useState(
    false
  );
  const handleOpenDocumentsViewModal = () => setOpenDocumentsViewModal(true);
  const handleCloseDocumentsViewModal = () => setOpenDocumentsViewModal(false);
  //
  const [docRowIndex, setDocRowIndex] = useState(null);

  //
  const [
    openlDeleteDocConfirmationModal,
    setOpenlDeleteDocConfirmationModal,
  ] = useState(false);
  //
  const {
    patientdocs,
    setPatientdocs,
    relativedocs,
    setRelativedocs,
    editAdmissionId,
    patchData,
  } = props;

  const {
    control,
    register,
    getValues,
    resetField,
    setError,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    getDcumentType();
    getOldDocuments();
  }, []);

  const getDcumentType = () => {
    fetchDcumentType()
      .then((response) => response.data)
      .then((res) => {
        setDocumentsType(res.result);
      });
  };

  const getOldDocuments = () => {
    editAdmissionId > 0 &&
      fetchDocuments(editAdmissionId)
        .then((response) => response.data)
        .then((res) => {
          setPatientdocs(
            res.result.fetchPatientDocDtoList ||
              patchData?.admissionDocuments?.fetchPatientDocDtoList //old doc
          );
          setRelativedocs(
            res.result.fetchRelDocDtoList ||
              patchData?.admissionDocuments?.fetchRelDocDtoList //old doc
          );
        });
  };

  //patient Documents
  const handleAddPatientDocuments = () => {
    let DocType = getValues("PatientDocumentsType");
    let DocData = getValues("PatientDocumentsFileData");

    if (DocType === null) {
      setError("PatientDocumentsType", { type: "custom", message: "Required" });
      setError("PatientDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    } else if (DocType === null) {
      setError("PatientDocumentsType", { type: "custom", message: "Required" });
    } else if (DocData === "") {
      setError("PatientDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    }

    let serviceObj = {
      id: DocType.id,
      documentType: DocType.label,
      Doc: DocData,
      DocName: DocData[0].name,
      Docfile: DocData[0],
    };

    let arr = [...patientdocs];
    arr.push(serviceObj);
    setPatientdocs(arr);

    resetField("PatientDocumentsType");
    resetField("PatientDocumentsFileData");
  };
  //Relative Documents
  const handleAddRelativeDocuments = () => {
    let DocType = getValues("relativeDocumentsType"); //relativedocs, setRelativedocs
    let DocData = getValues("relativeDocumentsFileData");

    if (DocType === null) {
      setError("relativeDocumentsType", {
        type: "custom",
        message: "Required",
      });
      setError("relativeDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    } else if (DocType === null) {
      setError("relativeDocumentsType", {
        type: "custom",
        message: "Required",
      });
    } else if (DocData === "") {
      setError("relativeDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    }

    let serviceObj = {
      id: DocType.id,
      documentType: DocType.label,
      Doc: DocData,
      DocName: DocData[0].name,
      Docfile: DocData[0],
    };

    let arr = [...relativedocs];
    arr.push(serviceObj);
    setRelativedocs(arr);

    resetField("relativeDocumentsType");
    resetField("relativeDocumentsFileData");
  };

  //
  const handleDeleteDoc = (docRow) => {
    if (docRow) {
      deleteAdmissionDocument(editAdmissionId, docRow.docId, docRow.type)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          setOpenlDeleteDocConfirmationModal(false);
          if (docRow.type === "Patient") {
            let newTemplateData = [...patientdocs];
            newTemplateData.splice(docRow.index, 1);
            setPatientdocs(newTemplateData);
          } else if (docRow.type === "Relative") {
            let newTemplateData = [...relativedocs];
            newTemplateData.splice(docRow.index, 1);
            setRelativedocs(newTemplateData);
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          setOpenlDeleteDocConfirmationModal(false);
        });
    }
  };
  ////////table related
  const renderActionsPatient = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...patientdocs];
      newTemplateData.splice(index, 1);
      setPatientdocs(newTemplateData);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="text-red-500">
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                if (row.documentPath) {
                  setDocRowIndex({ ...row, index, type: "Patient" });
                  setOpenlDeleteDocConfirmationModal(true);
                } else {
                  deleteRow(index);
                }
              }}
            />
          </span>
        </div>
      </>
    );
  };
  const renderInputPatient = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.documentPath);
      handleOpenDocumentsViewModal(true);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          {row.documentPath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  const renderActionsRelative = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...relativedocs];
      newTemplateData.splice(index, 1);
      setRelativedocs(newTemplateData);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="text-red-500">
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                if (row.documentPath) {
                  setDocRowIndex({ ...row, index, type: "Relative" });
                  setOpenlDeleteDocConfirmationModal(true);
                } else {
                  deleteRow(index);
                }
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const renderInputRelative = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.documentPath);
      handleOpenDocumentsViewModal(true);
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          {row.documentPath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {/* Patient Documents */}
      <div>
        <p className="font-bold tracking-wide text-sm mb-3 -mt-9 font-Poppins">
          Patient Documents
        </p>
        <div className="grid grid-cols-12 gap-3 items-center ">
          <div className="col-span-4 lg:col-span-3">
            <DropdownField
              control={control}
              label="Document Type"
              dataArray={documentsType}
              isSearchable={false}
              isClearable={false}
              placeholder="Document Type"
              name="PatientDocumentsType"
              error={errors.PatientDocumentsType}
            />
          </div>

          <div className="col-span-7 lg:col-span-3 ">
            <input type="file" {...register(`PatientDocumentsFileData`)} />
          </div>

          <div className="justify-end">
            <Tooltip title="Add">
              <AddCircleIcon
                sx={{ color: "#1e3a8a" }}
                onClick={() => {
                  handleAddPatientDocuments();
                }}
              />
            </Tooltip>
          </div>
        </div>
        {patientdocs.length > 0 ? (
          <div className="w-full mt-2">
            {/* <DocumentsTable data={patientdocs} setState={setPatientdocs} /> */}
            <CommonDynamicTableNew
              dataResult={patientdocs}
              renderActions={renderActionsPatient}
              removeHeaders={["Docfile", "id", "Doc", "docId"]}
              editableColumns={[
                "documentPath",
                "DocName",
                "baseSixtyFourString",
              ]}
              renderInput={renderInputPatient}
              tableClass="rounded h-36"
              highlightRow={false}
            />
          </div>
        ) : null}
      </div>
      {/* Relative Documents */}
      <div>
        <p className="font-bold tracking-wide text-sm mb-3 font-Poppins">
          Relative Documents
        </p>
        <div className="grid grid-cols-12 gap-3 items-center ">
          <div className="col-span-4 lg:col-span-3">
            <DropdownField
              control={control}
              label="Document Type"
              dataArray={documentsType}
              isSearchable={false}
              isClearable={false}
              placeholder="Document Type"
              name="relativeDocumentsType"
              error={errors.relativeDocumentsType}
            />
          </div>

          <div className="col-span-7 lg:col-span-3 ">
            <input type="file" {...register(`relativeDocumentsFileData`)} />
          </div>

          <div className="justify-end">
            <Tooltip title="Add">
              <AddCircleIcon
                sx={{ color: "#1e3a8a" }}
                onClick={() => {
                  handleAddRelativeDocuments();
                }}
              />
            </Tooltip>
          </div>
        </div>
        {relativedocs.length > 0 ? (
          <div className="w-full mt-2">
            {/* <DocumentsTable data={relativedocs} setState={setRelativedocs} /> */}
            <CommonDynamicTableNew
              dataResult={relativedocs}
              renderActions={renderActionsRelative}
              removeHeaders={["Docfile", "id", "Doc", "docId"]}
              editableColumns={[
                "documentPath",
                "DocName",
                "baseSixtyFourString",
              ]}
              renderInput={renderInputRelative}
              tableClass="rounded h-36"
              highlightRow={false}
            />
          </div>
        ) : null}
      </div>
      {/* panding Documents */}
      <div className="">
        <CheckBoxField
          control={control}
          name="pendingDocuments"
          label={
            <Typography
              variant="body3"
              sx={{ fontWeight: 700, color: " #676060" }}
            >
              Pending Documents
            </Typography>
          }
        />
      </div>
      {/* checkboxes */}
      <div className="font">
        <Divider orientation="horizontal" variant="middle" color="#FFFFFF" />
      </div>
      {/* <div className="mt-3 gap-y-6 "> */}
      <div className="flex  gap-2 w-full col-span-2 xl:col-span-1 font-semibold my-3">
        <span className=" font-semibold w-44">Individual Admitting</span>
        <div className="flex space-x-3 items-center">
          <span>:</span>
          <span className="text-gray-700 font-normal ">
            <RadioField
              label=""
              name="individualAdmitting"
              control={control}
              dataArray={IndividualAdmitting}
            />
          </span>
        </div>
      </div>
      <div className="flex  gap-2 w-full col-span-2 xl:col-span-1 font-semibold my-3">
        <span className=" font-semibold w-44">Relative</span>
        <div className="flex space-x-3 ">
          <span>:</span>
          <span className="text-gray-700 font-normal ">
            <RadioField
              label=""
              name="relative"
              control={control}
              dataArray={Relative}
            />
          </span>
        </div>
      </div>
      <div className="flex gap-2 w-full col-span-2 xl:col-span-1 font-semibold my-3">
        <span className=" font-semibold w-44">Mode Of Payment</span>
        <div className="flex space-x-3 items-center">
          <span>:</span>
          <span className="text-gray-700 font-normal ">
            <RadioField
              label=""
              name="modeOfPayment"
              control={control}
              dataArray={ModeOfPayment}
            />
          </span>
        </div>
      </div>

      {/* Documents view modal */}
      {openDocumentsViewModal ? (
        <DocumentsViewModal
          open={openDocumentsViewModal}
          setOpen={setOpenDocumentsViewModal}
          handleOpen={handleOpenDocumentsViewModal}
          handleClose={handleCloseDocumentsViewModal}
          pathForDocView={pathForDocView}
        />
      ) : null}

      {/* /// modal */}
      <Modal open={openlDeleteDocConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Confirmation
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To Delete Document ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setOpenlDeleteDocConfirmationModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      handleDeleteDoc(docRowIndex);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Documents;
