import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { saveSymptoms } from "../../../services/cliinicalChartServices/ClinicalCareChartService";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";

export default function ClinicalIconModal({
  open,
  handleClose,
  menuId,
  privilege,
  admissionId,
}) {
  const defaultValues = {
    // mentalStatus
    alert: false,
    confusion: false,
    confused: false,
    unResponsive: false,

    //respiratory distress
    none: false,
    mild: false,
    moderate: false,
    severe: false,

    // skincolor
    normal: false,
    pale: false,
    cyanosis: false,
    cyanotic: false,

    // mobilty
    independant: false,
    slightDifficulty: false,
    assistanceNeeded: false,
    bedridden: false,
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [finalSaveObj, setFinalSaveObj] = useState(null);
  const [openSaveConfirmationModal, setOpenSaveConfirmationModal] =
    useState(false);

  let token = JSON.parse(localStorage.getItem("userInfo"));

  function handleFormSubmit(dataObj) {
    console.log("saveObj", dataObj);
    let tempObj = {
      mentalStatusAlert: dataObj.alert,
      mentalStatusConfused: dataObj.confused,
      mentalStatusMildConfusion: dataObj.confusion,
      mentalStatusUnResponsive: dataObj.unResponsive,
      respiratoryDistressNone: dataObj.none,
      respiratoryDistressMild: dataObj.mild,
      respiratoryDistressModerate: dataObj.moderate,
      respiratoryDistressSevere: dataObj.severe,
      skinColorNormal: dataObj.normal,
      skinColorCyanotic: dataObj.cyanotic,
      skinColorCyanosis: dataObj.cyanosis,
      skinColorPale: dataObj.pale,
      mobilityIndependent: dataObj.independant,
      mobilitySlightDifficulty: dataObj.slightDifficulty,
      mobilityAssistanceNeeded: dataObj.assistanceNeeded,
      mobilityBedridden: dataObj.bedridden,
      menuId: menuId,
      admissionId: admissionId,
      privilege: "",
    };
    console.log("postObjis", tempObj);

    setFinalSaveObj(tempObj);
    setOpenSaveConfirmationModal(true);
  }

  function SaveSymptoms() {
    saveSymptoms(finalSaveObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleClose();
        reset(defaultValues);
        setOpenSaveConfirmationModal(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenSaveConfirmationModal(false);
      });
  }

  return (
    <div>
      <Modal open={open}>
        <Box
          sx={ModalStyleFeedback}
          className="w-[80%] xl:w-[55%] 2xl:w-[50%] max-w-[90%]  h-[20%] lg:h-[28%]  xl:h-[45%] 2xl:h-[28%] max-h-[60%] overflow-y-auto"
        >
          <div
            onClick={() => {
              handleClose();
            }}
          >
            <h1 className="text-center font-semibold text-lg">Symptoms</h1>
            <CancelPresentationIconButton />
          </div>

          <div className="">
            <form onSubmit={handleSubmit(handleFormSubmit)} className="  gap-2">
              <div className="flex space-x-3 items-center w-full">
                <h3 className="font-semibold flex space-x-[59px] whitespace-nowrap">
                  <span>Mental Status</span>
                  <span>:</span>
                </h3>
                <div className="grid grid-cols-4 gap-x-6 w-full">
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("confused", false);
                        setValue("unResponsive", false);
                        setValue("confusion", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="alert"
                      label="Alert"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("confused", false);
                        setValue("unResponsive", false);
                        setValue("alert", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="confusion"
                      label="Mild Confusion"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("alert", false);
                        setValue("unResponsive", false);
                        setValue("confusion", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="confused"
                      label="Confused"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("alert", false);
                        setValue("confused", false);
                        setValue("confusion", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="unResponsive"
                      label="UnResponsive"
                    />
                  </fieldset>
                </div>
              </div>
              <div className="flex space-x-3 items-center w-full">
                <h3 className="font-semibold flex space-x-3 whitespace-nowrap">
                  <span>Respiratory Distress</span>
                  <span> :</span>
                </h3>

                <div className="grid grid-cols-4 gap-x-6 w-full">
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("mild", false);
                        setValue("severe", false);
                        setValue("moderate", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField control={control} name="none" label="None" />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("none", false);
                        setValue("severe", false);
                        setValue("moderate", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField control={control} name="mild" label="Mild" />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("none", false);
                        setValue("mild", false);
                        setValue("severe", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="moderate"
                      label="Moderate"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("none", false);
                        setValue("mild", false);
                        setValue("moderate", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="severe"
                      label="Severe"
                    />
                  </fieldset>
                </div>
              </div>
              <div className="flex space-x-3 items-center w-full">
                <h3 className="font-semibold flex space-x-[85px] items-center whitespace-nowrap">
                  <span>Skin Color </span>
                  <span>:</span>
                </h3>
                <div className="grid grid-cols-4 gap-x-6 w-full">
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("pale", false);
                        setValue("cyanotic", false);
                        setValue("cyanosis", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="normal"
                      label="Normal"
                    />
                  </fieldset>

                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("normal", false);
                        setValue("cyanotic", false);
                        setValue("cyanosis", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField control={control} name="pale" label="Pale" />
                  </fieldset>

                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("normal", false);
                        setValue("pale", false);
                        setValue("cyanotic", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="cyanosis"
                      label="Cyanosis (Extremities)"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("normal", false);
                        setValue("pale", false);
                        setValue("cyanosis", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="cyanotic"
                      label="Cyanotic"
                    />
                  </fieldset>
                </div>
              </div>
              <div className="flex space-x-3 items-center w-full">
                <h3 className="font-semibold flex space-x-[101.5px]  items-center">
                  <span>Mobility</span>
                  <span>:</span>
                </h3>
                <div className="grid grid-cols-4 gap-x-6 w-full">
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("assistanceNeeded", false);
                        setValue("bedridden", false);
                        setValue("slightDifficulty", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="independant"
                      label="Independant"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("independant", false);
                        setValue("assistanceNeeded", false);
                        setValue("bedridden", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="slightDifficulty"
                      label="Slight Difficulty"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("independant", false);
                        setValue("bedridden", false);
                        setValue("slightDifficulty", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="assistanceNeeded"
                      label="Assistance Needed"
                    />
                  </fieldset>
                  <fieldset
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("independant", false);
                        setValue("assistanceNeeded", false);
                        setValue("slightDifficulty", false);
                      }
                    }}
                    className="w-full"
                  >
                    <CheckBoxField
                      control={control}
                      name="bedridden"
                      label="Bedridden"
                    />
                  </fieldset>
                </div>
              </div>
              <div className="flex justify-end pt-2 space-x-2 items-center ">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="text-customRed border border-customRed"
                  onClick={() => {
                    reset(defaultValues);
                  }}
                />
                <CommonButton
                  type="submit"
                  label="Save"
                  className="text-white bg-customGreen"
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openSaveConfirmationModal}
        confirmationHandleClose={() => setOpenSaveConfirmationModal(false)}
        confirmationSubmitFunc={SaveSymptoms}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this Symptoms ?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}

