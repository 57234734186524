import React from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { Modal } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { ResetButton } from "../../../../common/components/Buttons/CommonButtons";
import SaveTypeButton from "../../../../common/components/Buttons/SaveTypeButton";

const HeaderModal = (props) => {
  const defaultValues = {
    headers:
      "Dear Sir, \n Please quote your lowest rates for the following as per the Terms & Conditions mentioned here below.",
  };

  const {
    reset,
    register,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  return (
    <>
      <div>
        <Modal
          open={props.openHeader}
          onClose={() => {
            props.handleHeaderClose();
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              bgcolor: "background.paper",
              border: "1px solid gray",
              borderRadius: 1,
              boxShadow: 20,
              p: 4,
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleHeaderClose();
                }}
              />
            </div>
            <form>
              <div className="my-4">
                <div className="w-full">
                  <TextareaAutosize
                    {...register("headers")}
                    minRows={4}
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows"
                    style={{
                      width: "100%",
                      border: "1px solid gray",
                      padding: "1rem",
                    }}
                    name="headers"
                  />
                </div>
                <div className="flex justify-end my-2 gap-4">
                  <ResetButton
                    onClick={() => {
                      reset(defaultValues);
                    }}
                  />
                  <SaveTypeButton
                    onClick={() => {
                      let val = getValues("headers");
                      props.setHeaderStore(val);
                      props.handleHeaderClose();
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default HeaderModal;
