import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//get supplier options
export const getSupplierBySearch = (searchString) => {
  return apiClient.get(`/purchaseOrder/supplierList/${searchString}`, {
    headers: authHeader(),
  });
};

//get stores
export const getStoreOptions = () => {
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};

//get list of pending bills
export const getPendingListOfBills = (postObj) => {
  return apiClient.post(`/partySettlement/listOfPendingBills`, postObj, {
    headers: authHeader(),
  });
};

//get TDS ledger options
export const getTdsLedgerOptions = () => {
  return apiClient.get(`/partySettlement/tdsLedger`, {
    headers: authHeader(),
  });
};

//save party settlement
export const savePartySettlement = (postObject) => {
  return apiClient.post(`/partySettlement/savePartySettlement`, postObject, {
    headers: authHeader(),
  });
};

//get previous settled bills
export const getPreviousSettledBillsList = (postObject) => {
  return apiClient.post(`/partySettlement/previousSettlementList`, postObject, {
    headers: authHeader(),
  });
};
