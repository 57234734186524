import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchDoctor } from "../../../../commonServices/miscellaneousServices/MiscServices";
import PainAssessmentScale from "../../../../emergency/components/registration/opdTretmentRecord/assets/PainAssessmentScale.svg";
import { addNewOrderSheet } from "../../../services/cliinicalChartServices/orderSheetServices/OrderSheetServices";
import ViewOrderSheet from "./ViewOrderSheet";

import { styled } from "@mui/material/styles";

export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
};

const avpuArray = [
  { id: 0, value: "Alert", label: "Alert" },
  { id: 1, value: "Respond to Voice", label: "Respond to Voice" },
  { id: 2, value: "Pain", label: "Pain" },
  { id: 3, value: "Unresponsive", label: "Unresponsive" },
];

const schema = yup
  .object({
    dietPlan: yup.string().when("dietPlanCheck", {
      is: false,
      then: yup.string().required(),
    }),
    // referanceDoctor: yup
    //   .object()
    //   .nullable()
    //   .when("referanceDoctorCheck", (referanceDoctorCheck) => {
    //     if (!referanceDoctorCheck) {
    //       return yup
    //         .object()
    //         .nullable()
    //         .shape({
    //           value: yup.string().required(""),
    //           label: yup.string().required(""),
    //         })
    //         .required("Required");
    //     }
    //   }),
    referanceDoctor: yup
      .array()
      .of(
        yup
          .object()
          .nullable()
          .shape({
            value: yup.string().required("Value is required"),
            label: yup.string().required("Label is required"),
          })
      )
      .nullable()
      .when("referanceDoctorCheck", {
        is: (referanceDoctorCheck) => !referanceDoctorCheck,
        then: yup
          .array()
          .min(1, "At least one doctor must be selected")
          .required("Required"),
      }),
  })
  .required();

const schemaDoct = yup
  .object({
    referanceDoctor: yup
      .object()
      .nullable()
      .when("referanceDoctorCheck", (referanceDoctorCheck) => {
        if (!referanceDoctorCheck) {
          return yup
            .object()
            .nullable()
            .shape({
              value: yup.string().required(""),
              label: yup.string().required(""),
            })
            .required("Required");
        }
      }),
  })
  .required();

//////pain score/assessment
function valueText(value) {
  return `${value}°C`;
}
const CustomSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  padding: 7,

  "& .MuiSlider-mark": {
    border: "none",
    color: "gray",
    height: 7,
    // marginLeft: "2px",
  },
  "& .MuiSlider-rail": {
    border: "none",
    backgroundImage:
      "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundImage:
      "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid blue",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "black",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
const sliderMarks = [
  {
    value: 0,
    scaledValue: 0,
  },
  {
    value: 1,
    scaledValue: 1,
  },
  {
    value: 2,
    scaledValue: 2,
  },
  {
    value: 3,
    scaledValue: 3,
  },
  {
    value: 4,
    scaledValue: 4,
  },
  {
    value: 5,
    scaledValue: 5,
  },
  {
    value: 6,
    scaledValue: 6,
  },
  {
    value: 7,
    scaledValue: 7,
  },
  {
    value: 8,
    scaledValue: 8,
  },
  {
    value: 9,
    scaledValue: 9,
  },
  {
    value: 10,
    scaledValue: 10,
  },
];

export default function OrderSheetModal(props) {
  const getUserRole = JSON.parse(localStorage.getItem("userInfo")); //cashCounter==="C. M. O."
  // let AllowInfo = getUserRole?.userRoles?.some(
  //   (item) => item === "Super Admin" || item === "Doctor"
  // );
  let AllowInfo =
    getUserRole.cashCounter === "C. M. O." ||
    getUserRole?.userRoles?.some((item) => item === "Super Admin")
      ? true
      : false;

  const defaultValues = {
    subjective: "",
    temp: "",
    bp: "",
    pulse: "",
    rr: "",
    cvp: "",
    mews: "",
    spO2: "",
    peripheralpulse: "",
    cvs: "",
    rs: "",
    pa: "",
    pv: "",
    cns: "",
    localexamation: "",
    other: "",
    assessment: "",
    cprNotes: "",
    plan: "",
    avpu: 0,
    referanceDoctor: null,
    //new
    dietPlan: "",
    dietPlanCheck: false,
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm(allSchema());

  function allSchema() {
    return {
      mode: "onChange",
      resolver: AllowInfo && yupResolver(schema),
      defaultValues: defaultValues,
    };
  }
  let noReferDoct = watch("referanceDoctorCheck");
  let admissionId = props.displayInfo?.AdmissionId || props.admissionId;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [referanceDoctor, setReferanceDoctor] = useState();
  const [
    referanceDoctorSearchString,
    setReferanceDoctorSearchString,
  ] = useState();
  //
  const [sliderReset, setSliderReset] = React.useState();
  const [mewsScore, setMewsScore] = React.useState();
  const [finalData, setFinalData] = React.useState();
  //
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  //View OrderSheet Modal
  const [openViewOrder, setViewOrderOpen] = React.useState(false);
  const handleViewOrderOpen = () => setViewOrderOpen(true);
  const handleViewOrderClose = () => setViewOrderOpen(false);

  let finalMews;

  const [temp, pulse, rr] = watch(["temp", "pulse", "rr"]);

  const getReferanceDoctor = () => {
    referanceDoctorSearchString &&
      //fetchReferanceDoctor(referanceDoctorSearchString)
      fetchDoctor(referanceDoctorSearchString).then((response) => {
        setReferanceDoctor(response.data.result);
      });
  };

  useEffect(() => {
    getReferanceDoctor();
  }, [referanceDoctorSearchString]);

  const avpuVal = watch("avpu");
  let bpStr = watch("bp");
  let arr = !!bpStr && bpStr?.split("/");
  let bp = arr && arr[0];
  function SystolicBloodPressure(sbp) {
    if (sbp >= 200) {
      return 2;
    } else if (sbp >= 101 && sbp <= 199) {
      return 0;
    } else if (sbp >= 81 && sbp <= 100) {
      return 2;
    } else if (sbp >= 71 && sbp <= 80) {
      return 2;
    } else if (sbp <= 70) {
      return 3;
    }
  }
  function PulseCalculation(pulse) {
    if (pulse <= 40) {
      return 2;
    } else if (pulse >= 41 && pulse <= 50) {
      return 1;
    } else if (pulse >= 51 && pulse <= 100) {
      return 0;
    } else if (pulse >= 101 && pulse <= 110) {
      return 1;
    } else if (pulse >= 111 && pulse <= 129) {
      return 2;
    } else if (pulse >= 130) {
      return 3;
    }
  }
  function RespiratoryRate(bpm) {
    if (bpm <= 9) {
      return 2;
    } else if (bpm > 9 && bpm <= 14) {
      return 0;
    } else if (bpm >= 15 && bpm <= 20) {
      return 1;
    } else if (bpm >= 21 && bpm <= 29) {
      return 2;
    } else if (bpm >= 30) return 3;
  }
  function Temperature(fahrenheit) {
    if (fahrenheit < 95) {
      return 3;
    } else if (fahrenheit >= 95 && fahrenheit <= 97) {
      return 1;
    } else if (fahrenheit >= 97.1 && fahrenheit <= 100) {
      return 0;
    } else if (fahrenheit >= 100.1 && fahrenheit <= 101.3) {
      return 1;
    } else if (fahrenheit > 101.3) {
      return 2;
    }
  }

  const calculateMews = (bp, temp, pulse, rr) => {
    let mewsVal;

    if (
      Number(bp) > 0 &&
      Number(temp) > 0 &&
      Number(pulse) > 0 &&
      Number(rr) > 0
    ) {
      let bpValue = SystolicBloodPressure(bp);

      let pulseValue = PulseCalculation(pulse);

      let tempValue = Temperature(temp);

      let rrValue = RespiratoryRate(rr);

      mewsVal =
        Number(bpValue) +
        Number(pulseValue) +
        Number(tempValue) +
        Number(rrValue);
    }

    return mewsVal;
  };

  //useEffect to set the displayof mews score
  useEffect(() => {
    finalMews = calculateMews(bp, temp, pulse, rr);

    if (
      (bp === "" ||
        temp === "" ||
        pulse === "" ||
        rr === "" ||
        avpuVal === "") &&
      props.open === true
    ) {
      //  mewsDivElement.current.innerHTML = "";
    }
    if (finalMews === undefined) {
      //mewsDivElement.current.innerHTML = `<p> ${finalMews} </p>`
    }

    if (avpuVal !== "") {
      finalMews = finalMews + Number(avpuVal);
    }

    setMewsScore(finalMews);
    // if (finalMews && finalMews !== NaN && finalMews >= 5 && avpuVal !== "") {
    //   mewsDivElement.current.innerHTML = `

    //   <div class="flex  items-center py-1">
    //   <p  class=" text-gray-700 px-2 font-bold">MEWS  </p>
    //      <div class="w-8 h-8 rounded-full border-2 border-red-500 flex justify-center  bg-red-500 items-center text-white">

    //     <p
    //       class=" text-white text-sm "
    //     >
    //     ${finalMews}
    //     </p>

    //   </div>
    //   <p class="px-2 font-bold text-red-500">Please Call Doctor For Consultation</p>
    //   `;
    // } else if (
    //   finalMews &&
    //   finalMews !== NaN &&
    //   finalMews < 5 &&
    //   avpuVal !== ""
    // ) {
    //   mewsDivElement.current.innerHTML = `<div class="flex  items-center py-1">
    //   <p  class=" text-gray-700 px-2 font-bold">MEWS</p>
    //      <div class="w-8 h-8 rounded-full border-2 border-green-500 flex justify-center  bg-green-500 items-center ">

    //     <p
    //       class=" text-white text-sm"
    //     >
    //     ${finalMews}
    //     </p>

    //   </div>
    //   <p class="px-2 font-bold text-green-500">No Need to Call Doctor For Consultation</p>`;
    // }
  }, [bp, temp, pulse, rr, avpuVal, props.open]);

  function postOrderSheet(obj) {
    addNewOrderSheet(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          props.setOpen(false);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postOrderSheet(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));

    let ordersheetPostObj = {
      menuId: props.menuId,
      privilege: props.privilege,
      // referenceDoctorId: {id: data.referanceDoctor?.id},
      referenceDoctorId: data.referanceDoctor?.map((item) => item.id),
      admissionId:
        admissionId ||
        (props?.displayInfo?.AdmissionId > 0
          ? props?.displayInfo?.AdmissionId
          : props?.displayInfo?.EmergencyPatientId),
      addedBy: getUserId.userId,
      assessment: data.assessment,
      bp: data.bp,
      cns: data.cns,
      cvp: data.cvp,
      cvs: data.cvs,
      patientId: props.isFromEmergency
        ? props.patientId
        : props.displayInfo?.PatientId || props?.admissionPatientId,

      // "givenDate": "2023-04-14T10:41:30.215Z",
      // "givenTime": "2023-04-14T10:41:30.215Z",
      isCancel: false,
      localExamination: data.localexamation,
      mews: mewsScore,
      opdIpd:
        props?.displayInfo?.AdmissionId > 0
          ? 1
          : props?.displayInfo?.EmergencyPatientId
          ? 2
          : 1,
      other: data.other,
      pa: data.pa,
      pain: data.plan,
      painScore: sliderReset,
      cprNotes: data.cprNotes,
      dietPlan: data.dietPlan,
      peripheral: data.peripheralpulse,
      pulse: data.pulse,
      pv: data.pv,
      // remark:
      rr: data.rr,
      rs: data.rs,
      spo2: data.spO2,
      subjective: data.subjective,

      message: `
      subjective:${data.subjective}\n
      bp:${data.bp}\n
      cns:${data.cns}\n
      cvp:${data.cvp}\n
      cvs:${data.cvs}\n
      other:${data.other}\n
      pa:${data.pa}\n
      pain:${data.pain}\n
      cprNotes:${data.cprNotes}\n
      peripheral:${data.peripheralpulse}\n
      pulse:${data.pulse}\n
      pv:${data.pv}\n
      rr:${data.rr}\n
      rs:${data.rs}\n
      spo2:${data.spO2}\n
      other:${data.other}\n
      localExamination:${data.localexamation}\n
      assessment:${data.assessment}
      dietPlan:${data.dietPlan}
      
      `,
      temp: data.temp,
      // crt: data.crt,
      // "updatedDate": "2023-04-14T10:41:30.215Z",
      // "updatedTime": "2023-04-14T10:41:30.215Z"
    };

    if (data.avpu === "") {
      warningAlert("Please select AVPU Value");
    } else if (data.avpu !== "") {
      setOpenPost(true);
      setFinalData(ordersheetPostObj);
    }
  };

  return (
    <>
      {/* Model open on add button  start */}
      <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4">
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Style} className="max-h-[92%] w-[80%] p-4   ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
                reset(defaultValues);
              }}
            />
            <div className="row gap-y-2">
              <h1 className="text-lg font-semibold text-center text-gray-700">
                Order Sheet
              </h1>

              {!props.isFromEmergency && (
                <div className="bg-gray-100 px-2 mx-3 rounded-md  border border-gray-300 mt-2">
                  <div className="grid grid-cols-2 lg:grid-cols-3 text-black  text-sm items-center w-full gap-1">
                    <div className="flex  items-center  gap-2 w-full font-semibold text-sm">
                      <span className="w-24 font-semibold"> Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="font-normal">
                          {props.displayInfo?.PatientName ||
                            props.selectedPatient?.PatientName}
                        </span>
                      </div>
                    </div>
                    <div className="flex space-x-12 items-center  gap-2 w-full font-semibold text-sm py-1">
                      <span className="font-semibold"> MR No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className=" font-normal">
                          {props.displayInfo?.MRNo ||
                            props.selectedPatient?.MRNo}
                        </span>
                      </div>
                    </div>

                    <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-16 font-semibold"> Age</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="font-normal">
                          {props.displayInfo?.AgeYear ||
                            props.selectedPatient?.AgeYear}
                        </span>
                      </div>
                    </div>

                    <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-24 font-semibold">Department</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="font-normal">
                          {props.displayInfo?.Department ||
                            props.selectedPatient?.Department}
                        </span>
                      </div>
                    </div>

                    <div className="flex space-x-1 items-center  gap-2 w-full font-semibold text-sm py-1">
                      <span className="font-semibold whitespace-nowrap">
                        Doctore Name
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="font-normal">
                          {props.displayInfo?.DoctorName ||
                            props.selectedPatient?.DoctorName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <form
                // onSubmit={handleSubmit(onSubmitDataHandler)}
                className="grid grid-cols-1 md:grid-cols-1 px-3"
              >
                <>
                  <div className="grid grid-cols-4 pt-2">
                    <div className="col-span-3">
                      <InputArea
                        control={control}
                        className="bg-white"
                        id="outlined-multiline-static"
                        name="subjective"
                        {...register("subjective")}
                        label="Subjective"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </div>

                    <div className="col-span-1 flex justify-end">
                      <CommonButton
                        type="button"
                        className="bg-customBlue text-white"
                        label="View"
                        onClick={() => {
                          handleViewOrderOpen();
                        }}
                      />
                    </div>
                  </div>
                  <h1 className="font-semibold text-sm text-gray-700 py-1 pt-2">
                    Objective
                  </h1>
                  <div className="row">
                    <div className="grid py-3">
                      <div className="grid grid-cols-4 w-full gap-2">
                        <div className="flex gap-2">
                          <legend className="font-semibold text-sm whitespace-nowrap grid items-center text-gray-700">
                            Vitals :
                          </legend>
                          <InputField
                            name="temp"
                            type="number"
                            className="appearance-none"
                            variant="outlined"
                            label="Temp"
                            error={errors.temp}
                            control={control}
                          />
                        </div>

                        <InputField
                          name="bp"
                          // type="number"
                          variant="outlined"
                          label="BP "
                          error={errors.bp}
                          control={control}
                        />

                        <InputField
                          name="pulse"
                          type="number"
                          variant="outlined"
                          label="Pulse "
                          error={errors.pulse}
                          control={control}
                        />
                        <InputField
                          name="rr"
                          type="number"
                          variant="outlined"
                          label="Respiration Rate (RR)"
                          error={errors.rr}
                          control={control}
                        />

                        <div className="col-span-2 pl-2 py-2">
                          <RadioField
                            label=""
                            name="avpu"
                            control={control}
                            dataArray={avpuArray}
                          />
                        </div>
                        {mewsScore > 0 &&
                          (mewsScore && mewsScore <= 5 ? (
                            <div className="flex items-center col-span-2 text-sm ">
                              <p className=" text-gray-700 px-2 font-bold">
                                MEWS
                              </p>
                              <div className="w-8 h-8 rounded-full border-2 border-green-500 flex justify-center  bg-green-500 items-center text-white">
                                <p className=" text-white text-sm ">
                                  {mewsScore}
                                </p>
                              </div>
                              <p class="px-2 font-bold text-green-500">
                                No Need To Call Doctor For Consultation
                              </p>
                            </div>
                          ) : (
                            <div className="flex items-center col-span-2 text-sm">
                              <p className=" text-gray-700 px-2 font-bold">
                                MEWS
                              </p>
                              <div className="w-8 h-8 rounded-full border-2 border-red-500 flex justify-center  bg-red-500 items-center text-white">
                                <p className=" text-white text-sm ">
                                  {mewsScore}
                                </p>
                              </div>
                              <p class="px-2 font-bold text-red-500">
                                Please Call Doctor For Consultation
                              </p>
                            </div>
                          ))}
                      </div>

                      <div className="grid grid-cols-5 gap-2 ">
                        <InputField
                          name="spO2"
                          // type="number"
                          variant="outlined"
                          label="spO2 "
                          error={errors.spO2}
                          control={control}
                        />

                        <InputField
                          name="peripheralpulse"
                          // type="number"
                          variant="outlined"
                          label="Peripheral Pulse "
                          error={errors.peripheralpulse}
                          control={control}
                        />

                        <InputField
                          name="cvp"
                          variant="outlined"
                          // type="number"
                          label="CVP "
                          error={errors.cvp}
                          control={control}
                        />
                        {AllowInfo && (
                          <div className="col-span-2 grid grid-cols-12 gap-1">
                            <div className="col-span-4 ">
                              <CheckBoxField
                                control={control}
                                name="referanceDoctorCheck"
                                label="No Doctor"
                              />
                            </div>
                            {!noReferDoct && (
                              <div className="col-span-8">
                                <SearchDropdown
                                  control={control}
                                  name="referanceDoctor"
                                  label="Other Refer Doctor"
                                  dataArray={referanceDoctor}
                                  isMulti={true}
                                  handleInputChange={(e) => {
                                    setReferanceDoctorSearchString(e);
                                  }}
                                  searchIcon={true}
                                  isSearchable={true}
                                  isClearable={true}
                                  placeholder="Other Refer Doctor"
                                  error={errors.referanceDoctor}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h1 className="font-semibold text-sm text-gray-700 py-1 pt-2">
                    Pain Score
                  </h1>
                  <div className="flex space-x-2   ">
                    <div className="flex flex-col space-y-1 lg:space-y-2 xl:space-y-4 xl:pt-2 2xl:space-y-8 2xl:pt-12 w-[25%] xl:w-[20%] text-xs xl:text-sm text-gray-600">
                      <h1>Verbal Description Scale</h1>
                      <h1>Wong-Baker Facial Grimace Scale</h1>
                      <h1>Activity Tolerance Scale</h1>
                    </div>

                    <div className="w-[70%]  justify-center">
                      <div className="w-full">
                        <img className="w-full" src={PainAssessmentScale} />
                      </div>
                      <div className="flex w-full justify-start mt-2">
                        <div className="w-[92%] xl:w-[90%] 2xl:w-[91%] ml-3 lg:ml-5 2xl:ml-9">
                          <CustomSlider
                            className="painScore"
                            control={control}
                            {...register("painScore")}
                            name="painScore"
                            onChange={(value) => {
                              // selectedPainScore = value.target.value;
                              setSliderReset(value.target.value);
                            }}
                            aria-label="Custom marks"
                            value={sliderReset !== null ? sliderReset : 0}
                            getAriaValueText={valueText}
                            min={0}
                            step={1}
                            max={10}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            style={{ height: "14px" }}
                            marks={sliderMarks}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {!props.isFromEmergency && AllowInfo && (
                    <legend className="font-semibold text-sm text-gray-700 py-2 pt-2">
                      Systemic Examination :
                    </legend>
                  )}

                  <div className="grid grid-cols-1 gap-y-2 gap-x-2 ">
                    {/*!props.isFromEmergency && AllowInfo && (*/}
                    <fieldset
                      disabled={
                        !props.isFromEmergency && AllowInfo ? false : true
                      }
                      onClick={() =>
                        !AllowInfo && warningAlert("Allowed to view only.")
                      }
                    >
                      <div className="grid grid-cols-2 gap-3 pb-3">
                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="cvs"
                          {...register("cvs")}
                          label="CVS"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                          disabled={AllowInfo ? false : true}
                        />

                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="rs"
                          {...register("rs")}
                          label="RS"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                          disabled={AllowInfo ? false : true}
                        />
                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="pa"
                          {...register("pa")}
                          label="P/A"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                          disabled={AllowInfo ? false : true}
                        />

                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="cns"
                          {...register("cns")}
                          label="CNS"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                          disabled={AllowInfo ? false : true}
                        />

                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="pv"
                          {...register("pv")}
                          label="P/V"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                          disabled={AllowInfo ? false : true}
                        />

                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="localexamation"
                          {...register("localexamation")}
                          label="Local Examination"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                          disabled={AllowInfo ? false : true}
                        />
                      </div>
                    </fieldset>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="other"
                          {...register("other")}
                          label="Other"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                        />
                      </div>
                      <div className="w-full ">
                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="assessment"
                          {...register("assessment")}
                          label="Assessment"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                        />
                      </div>
                      <div className="w-full ">
                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="plan"
                          {...register("plan")}
                          label="Plan"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                        />
                      </div>

                      <div className="w-full">
                        <InputArea
                          control={control}
                          className="bg-white"
                          id="outlined-multiline-static"
                          name="cprNotes"
                          {...register("cprNotes")}
                          label="CPR Notes"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={3}
                          fullWidth
                        />
                      </div>
                      {/*AllowInfo && (*/}

                      <div
                        className="w-full grid grid-cols-6 gap-2 col-span-2"
                        onClick={() =>
                          !AllowInfo && warningAlert("Allowed to view only.")
                        }
                      >
                        {AllowInfo && (
                          <div className="col-span-1">
                            <CheckBoxField
                              control={control}
                              name="dietPlanCheck"
                              label="No Diet Plan"
                            />
                          </div>
                        )}
                        <div className="col-span-5">
                          <InputArea
                            control={control}
                            className="bg-white"
                            id="outlined-multiline-static"
                            name="dietPlan"
                            {...register("dietPlan")}
                            label="Diet Plan"
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={3}
                            fullWidth
                            error={errors.dietPlan}
                            disabled={AllowInfo ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                {getUserRole === "Admin" ? (
                  ""
                ) : (
                  <div className="flex gap-2 justify-end pt-2">
                    {props.displayInfo !== null ||
                    props.admissionId !== undefined ? (
                      <>
                        <CommonButton
                          type="button"
                          className="border border-customRed text-customRed"
                          label="Reset"
                          onClick={() => {
                            reset(defaultValues);
                          }}
                        />

                        <CommonButton
                          type="button"
                          className="bg-customGreen text-white"
                          label="Save"
                          onClick={handleSubmit(onSubmitDataHandler)}
                        />
                      </>
                    ) : null}
                  </div>
                )}
              </form>

              <CommonBackDrop openBackdrop={openBackdrop} />
            </div>
          </Box>
        </Modal>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />

        {openViewOrder && (
          <ViewOrderSheet
            openViewOrder={openViewOrder}
            handleViewOrderClose={handleViewOrderClose}
            admissionId={admissionId}
            ptInfo={props.displayInfo || props.selectedPatient}
          />
        )}
      </div>
    </>
  );
}
