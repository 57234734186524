import React, { useEffect, useState } from "react";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
//imports from react hook form
import { useForm } from "react-hook-form";
//imports from the yup library
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import MedicationErrorTable from "./MedicationErrorTable";
import InputField from "../../../../../common/components/FormFields/InputField";
import {
  fetchAllMedicationErrorList,
  getAllErrorTypes,
  getAllMultiSelectErrors,
  saveMedicationError,
} from "../../../../services/cliinicalChartServices/medicationerrorServices/MedicationErrorServices";
import { format } from "date-fns";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import CommonDynamicTableNew from "../../../../../common/components/CommonTable/CommonDynamicTableNew";
import LoadingSpinner from "../../../../../common/components/loadingspinner/loadingSpinner";

//style for model
const ModalStyleReportMedication = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // height: "75%",
  width: "80%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
  overflowY: "auto",
};

function MedicationErrorRepModal(props) {
  let admissionId = props.displayInfo?.AdmissionId;

  const schema = yup.object().shape({
    selectErrors: yup
      .object()
      .nullable()
      .shape({
        label: yup.string().required("Please Select Service"),
        value: yup.string().required("Please Select Service"),
      }),
  });

  const defaultValues = {
    selectErrors: null,
    remark: "",
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //1st table
  const [errorListingData, setErrorListingData] = useState([]);
  const [selectedMedcationRow, setSelectedMedcationRow] = useState(null);
  //2nd table
  const [errorListData, setErrorListData] = useState([]);
  const [errorIds, setErrorIds] = useState([]);
  const [finalData, setFinalData] = useState();
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errorTypeOption, setErrorTypeOptions] = useState("");
  let error = watch("selectErrors");
  let medErrorTypeId = error?.ID;
  let getUserId = JSON.parse(localStorage.getItem("userInfo"));

  let fromDate = watch("fromDate");
  let toDate = watch("toDate");

  useEffect(() => {
    getAllErrorTypes()
      .then((response) => response.data)
      .then((res) => {
        setErrorTypeOptions(res.result);
      });
  }, []);

  useEffect(() => {
    if (medErrorTypeId) {
      populateErrorTable(medErrorTypeId);
    }
  }, [error]);

  useEffect(() => {
    populateMedicationErrorList();
  }, []);

  const filterData = () => {
    populateMedicationErrorList();
  };

  //1st Medication List Table
  const populateMedicationErrorList = () => {
    let obj = {
      fromDate: format(fromDate, "yyyy-MM-dd'T'hh:mm:ss.000'Z'"),
      toDate: format(toDate, "yyyy-MM-dd'T'hh:mm:ss.000'Z'"),
      admissionId: props.displayInfo?.AdmissionId || props.admissionId,
    };
    showSpinner(true);
    fetchAllMedicationErrorList(obj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setErrorListingData(res.result);
      });
  };

  //2nd table
  const populateErrorTable = (medErrorTypeId) => {
    getAllMultiSelectErrors(medErrorTypeId)
      .then((response) => {
        return response.data.result;
      })
      .then((res) => {
        setErrorListData(res);
      })
      .catch(() => {});
  };

  //Patch 2nd table value
  useEffect(() => {
    if (selectedMedcationRow !== null) {
      let selectedRowErrorObj = {
        ID: 1,
        label: "Medication Error",
        value: "Medication Error",
      };
      setValue("selectErrors", selectedRowErrorObj);

      setValue("remark", selectedMedcationRow.Remark);
    }
  }, [selectedMedcationRow]);

  function postMedicationError(obj) {
    saveMedicationError(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateMedicationErrorList();
          setErrorIds([]);
          reset(defaultValues);
          setErrorListData([]);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postMedicationError(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let postObj = {
      menuId: props.menuId,
      privilege: props.privilege,
      addedBy: getUserId.userId,
      admissionId: props.displayInfo?.AdmissionId || props.admissionId,
      errorCount: Number(errorIds.length),
      errorIds: errorIds,
      remark: data.remark,
    };
    if (props.displayInfo?.AdmissionId || props.admissionId) {
      if (errorIds.length > 0) {
        setOpenPost(true);
        setFinalData(postObj);
      }
    } else if (props.displayInfo?.AdmissionId || props.admissionId) {
      warningAlert("Please Select Patient...!");
    }
  };

  const handleSelectedRow = (row, index) => {
    setSelectedMedcationRow(row);
  };

  return (
    <div className=" backdrop-blur-0">
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleReportMedication}
          className="w-[80%] h-[85%] xl:h-[75%] px-4 pb-2"
        >
          <div className="sticky top-0 bg-white z-50 w-full">
            <div className=" py-2">
              <div className="flex items-center w-full ">
                <div className="w-full flex justify-center">
                  <h1 className="text-sm font-semibold whitespace-nowrap">
                    Medication Error Reporting
                  </h1>
                </div>
                <div className="flex justify-end">
                  <CancelPresentationIconButton
                    onClick={() => {
                      props.handleClose();
                      reset(defaultValues);
                    }}
                  />
                </div>
              </div>
              <div className="bg-gray-100 px-2 rounded-md  border border-gray-300 mt-2">
                <div className="grid grid-cols-2 lg:grid-cols-3 text-black  text-sm items-center w-full gap-1">
                  <div className="flex  items-center  gap-2 w-full font-semibold text-sm">
                    <span className="w-24 font-semibold"> Patient Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-normal">
                        {props.displayInfo?.PatientName ||
                          props.selectedPatient?.PatientName}
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-12 items-center  gap-2 w-full font-semibold text-sm py-1">
                    <span className="font-semibold"> MR No.</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className=" font-normal">
                        {props.displayInfo?.MRNo || props.selectedPatient?.MRNo}
                      </span>
                    </div>
                  </div>

                  <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-16 font-semibold"> Age</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-normal">
                        {props.displayInfo?.AgeYear ||
                          props.selectedPatient?.AgeYear}
                      </span>
                    </div>
                  </div>

                  <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 font-semibold">Department</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-normal">
                        {props.displayInfo?.Department ||
                          props.selectedPatient?.Department}
                      </span>
                    </div>
                  </div>

                  <div className="flex space-x-1 items-center  gap-2 w-full font-semibold text-sm py-1">
                    <span className="font-semibold whitespace-nowrap">
                      Doctore Name
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="font-normal">
                        {props.displayInfo?.DoctorName ||
                          props.selectedPatient?.DoctorName}
                      </span>
                    </div>
                  </div>

                  <div className="text-customRed text-sm font-semibold">
                    {errorIds.length} Medication Error Selected
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            className="w-full  mt-2"
            onSubmit={handleSubmit(onSubmitDataHandler)}
          >
            <div className="lg:flex lg:space-x-2  w-full">
              <div className="lg:w-[40%] border-r-2 pr-2">
                <div className="flex space-x-3 items-center">
                  <DropdownField
                    control={control}
                    error={errors.selectErrors}
                    name="selectErrors"
                    label="Select Error Type"
                    dataArray={errorTypeOption}
                    isSearchable={false}
                    placeholder="Select Error Type"
                    isClearable={false}
                  />
                </div>
                <div>
                  <MedicationErrorTable
                    data={errorListData}
                    setErrorListData={setErrorListData}
                    errorIds={errorIds}
                    setErrorIds={setErrorIds}
                    selectedMedcationRow={selectedMedcationRow}
                  />
                </div>
                <div className="flex space-x-2">
                  <InputField
                    control={control}
                    name="remark"
                    label="Remarks"
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="mt-2 flex justify-end w-full space-x-2">
                  {props.userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <>
                          {props.displayInfo !== null ||
                          props.admissionId !== undefined ? (
                            <>
                              <CommonButton
                                type="button"
                                className="border border-customRed text-customRed"
                                label="Reset"
                                
                                onClick={() => {
                                  reset(defaultValues);
                                  setErrorListData([]);
                                  setErrorIds([]);
                                }}
                              />
                              {props.patientInformation?.AllowToGO === true ||
                              props.selectedPatient?.AllowToGO ===
                                true ? null : (
                                <CommonButton
                                  type="submit"
                                  className="bg-customGreen text-white"
                                  label="Save"
                                  onClick={() => {
                                    props.setPrivilege(obj.action);
                                  }}
                                />
                              )}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
              <div className="lg:w-[60%] pt-2 lg:pt-0">
                <div className="flex space-x-3  ">
                  <div className="w-full">
                    <DatePickerFieldNew
                      control={control}
                      name="fromDate"
                      label="From Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("fromDate", newValue);
                      }}
                      disablePast={false}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="w-full">
                    <DatePickerFieldNew
                      control={control}
                      name="toDate"
                      label="To Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("toDate", newValue);
                      }}
                      disablePast={false}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      searchIcon={true}
                      onClick={() => {
                        filterData();
                      }}
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <span className="font-semibold text-sm">Medication List</span>
                </div>

                {spinner ? (
                  <div className="grid justify-center">
                    <LoadingSpinner />
                  </div>
                ) : null}
                {errorListingData.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={errorListingData}
                    tableClass={"rounded h-[200px] 2xl:h-72"}
                    handleSelectedRow={handleSelectedRow}
                    highlightRow={true}
                    removeHeaders={["Id", "visitId", "errorType", "errors"]}
                  />
                ) : (
                  <h1 className="flex justify-center my-5 font-bold text-gray-600">
                    No Records Found...
                  </h1>
                )}
              </div>
            </div>
          </form>
          <CommonBackDrop openBackdrop={openBackdrop} />
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}

export default MedicationErrorRepModal;
