import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const saveHumanBody = async (addNewHumanBody) => {
  return await apiClient.post(
    `/clinicalCareChart/saveHumanBody`,
    addNewHumanBody,
    {
      headers: authHeader(),
    }
  );
};

// ​/api​/clinicalCareChart​/getHumanBodyList​/{admissionId}

export const getAllHumanBodyList = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/getHumanBodyList/${admissionId}`, {
    headers: authHeader(),
  });
};

export const deleteHumanBody = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteHumanBody/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const getBodyEquipmentList = () => {
  return apiClient.get(`/clinicalCareChart/getHumanBodyEquipments`, {
    headers: authHeader(),
  });
};
