import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { fetchOpdCertificatePrint } from "../components/AppointmentBooking/services/certificateservice/CertificateServices";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";

export default function CertificatePrint({ printId }) {
  const printRef = useRef(null);

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetchOpdCertificatePrint(printId)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
    //
  }, [printId]);

  useEffect(() => {
    !!data && GeneratePrint(printRef);
  }, [data]);

  return (
    <div ref={printRef}>
      <table>
        <div className="mt-2 ">
          <thead className="pb-3" style={{ "-fs-table-paginate": "paginate" }}>
            <table className="w-full ">
              <div className=" justify-end flex ">
                <div>
                  <InamdarHospiLogo />
                </div>
              </div>
            </table>

            <div className="font-semibold text-[13px] border-t-[1px] text-center p-1 border-black border-b-[1px]">
              {data?.certificatename}
            </div>
          </thead>
          <tbody>
            <h1 className=" text-center text-[13px] my-2">
              (For Registraton & Extension of Medical Visa of Foreign Natonals)
            </h1>

            <div
              className="whitespace-pre-wrap	pt-5"
              dangerouslySetInnerHTML={{ __html: data?.TempalteDescription }}
            ></div>
          </tbody>
        </div>
      </table>
    </div>
  );
}
