import React, { useEffect, useRef } from "react";
import { Box, Modal, Checkbox, FormControlLabel } from "@mui/material";
import AddButton from "../../../../common/components/Buttons/AddButton";
import CancelButton from "../../../../common/components/Buttons/CancelButton";
import { Controller, useForm } from "react-hook-form";
import InputArea from "../../../../common/components/FormFields/InputArea";

export default function ConfirmationModalForSpecialInstructions({
  confirmationOpen,
  confirmationHandleClose,
  instructionsValue,
  AddSpecialInstruction,
  setInstructionBillChecked,
  setSpecialInstructionValue,
  specialInstructionValue,
}) {
  const defaultValues = {
    instructions: "",
  };
  const { watch, register, control, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let instructionRef = useRef();
  let instructionInputValue = watch("instructions");

  useEffect(() => {
    setValue("instructions", instructionsValue?.label);
  }, []);

  return (
    <div>
      <Modal
        open={confirmationOpen}
        onClose={confirmationHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            borderRadius: "0.5rem",
            boxShadow: 24,
            px: 2,
            pb: 2,
          }}
        >
          <div className=" justify-center align-middle py-2">
            <h1 className="text-center text-lg font-medium text-gray-600">
              Instructions
            </h1>
            <div className="p-1 border border-blue-300 bg-gray-100 rounded">
              <h1 className="text-gray-600 text-sm font-medium ml-1">
                {instructionsValue?.label}
              </h1>
            </div>
            <fieldset className="py-2" ref={instructionRef}>
              <InputArea
                control={control}
                name="instructions"
                label="Instructions"
                inputRef={{
                  ...register("instructions", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        setSpecialInstructionValue(e.target.value);
                      }
                    },
                  }),
                }}
              />
            </fieldset>

            <div className="mt-3 flex items-center  justify-end space-x-4">
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={(event) =>
                      setInstructionBillChecked(event.target.checked)
                    }
                  />
                }
                label="Instruction Continue Till Discharge"
              />
              <CancelButton
                onClick={() => {
                  confirmationHandleClose();
                }}
              />
              <AddButton
                onClick={() => {
                  AddSpecialInstruction();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
