// import React from 'react'

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useState } from "react";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  releaseSecondaryAllocatedBed,
  unReservedBed,
} from "../../services/bedAllowcationServices/BedAllowcationServices";

export const Schema = yup.object().shape({
  BedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  ChargesPerBedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),
});

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "95%", lg: "50%" },
  // minWidth:"25%",
  // height: "30%",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function BedUnReserveModal(props) {
  const {
    open,
    handleClose,
    menuId,
    privilege,
    getBedListData,
    bedDetails,
    populateTable,
  } = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);
  //

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);
  //

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);
    const finalObj = {
      bedId: bedDetails?.BedId,
      bedReservationId:
        bedDetails?.ptInfo?.bedReservationId || bedDetails?.bedReservationId,
      menuId: menuId,
      privilege: privilege,
    };
    unReservedBed(finalObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        handleClose();
        getBedListData();
        populateTable && populateTable();
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res.message);
        setOpenBackdrop(false);
        handleClose();
      });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                UnReserve Bed
              </div>

              <hr className="border mx-1  border-blue-900" />

              <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                <div className="grid grid-cols-2  gap-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                    {/* Patient Name ,  */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-1">
                        <span className="text-sm">Patient Name </span>
                        <span className=""> :</span>
                      </h1>

                      <h1 className="font-normal">
                        {(bedDetails?.ptInfo &&
                          bedDetails?.ptInfo["PatientName"]) ||
                          (bedDetails && bedDetails["Patient Name"])}
                      </h1>
                    </div>
                    <div className="flex gap-2 items-center">
                      <h1 className="text-black font-semibold flex space-x-1 lg:space-x-1">
                        <span className="text-sm">Bed No.</span>
                        <span className="lg:pl-0 md:pl-1">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {(bedDetails && bedDetails["BedNo"]) ||
                          (bedDetails && bedDetails["Bed No"])}
                      </h1>
                    </div>
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-0 lg:space-x-1">
                        <span className="text-sm"> Bed Category</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails && bedDetails["BedCategory"]}
                      </h1>
                    </div>
                  </div>

                  <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                    <div className="flex gap-2 items-center">
                      <h1 className="text-black font-semibold flex space-x-1 lg:space-x-1">
                        <span className="text-sm">Admission Date</span>
                        <span className="lg:pl-0 md:pl-1">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {(bedDetails?.ptInfo &&
                          bedDetails.ptInfo["AdmissionDate"]) ||
                          (bedDetails && bedDetails["Admission Date"])}
                      </h1>
                    </div>

                    <div className="flex gap-2 items-center">
                      <h1 className="text-black font-semibold flex space-x-1 lg:space-x-1">
                        <span className="text-sm">Admission Under Doctor</span>
                        <span className="lg:pl-0 md:pl-1">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {(bedDetails?.ptInfo &&
                          bedDetails.ptInfo["DoctorName"]) ||
                          (bedDetails && bedDetails["Doctor Name"])}
                      </h1>
                    </div>

                    <div className="flex gap-2 items-center">
                      <h1 className="text-black font-semibold flex space-x-1 lg:space-x-1">
                        <span className="text-sm">Ward</span>
                        <span className="lg:pl-0 md:pl-1">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails && bedDetails["Ward"]}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" p-2 rounded  text-black  ">
                Do You Want To UnReserve Bed ?
              </div>

              <div className="flex justify-end my-2 gap-3">
                <CommonButton
                  label="Cancel"
                  onClick={() => {
                    handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  label="Proceed"
                  onClick={() => {
                    onSubmit();
                  }}
                  className="bg-customGreen text-white "
                />
              </div>
            </div>
          </form>
          {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}

        </Box>
      </Modal>
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />{" "}
    </div>
  );
}
