import apiClient from "../../../http-common";

import authHeader from "../../../authentication/authservices/auth-header";

// post item batch number
// /api/stockAdjustment/itemBatchNumber
export const itemBatchNumber = (batchNoPostObj) => {
  return apiClient.post(`/stockAdjustment/itemBatchNumber`, batchNoPostObj, {
    headers: authHeader(),
  });
};
