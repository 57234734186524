import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { Tooltip } from "@mui/material";

export default function TermsNConditionsTable(props) {
  
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.termsnConditionData];
      newTemplateData.splice(deleteIndex, 1);
      props.setTermsnConditionData(newTemplateData);
      handleClosePost();
    }
  };

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 20,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                },
              }}
              className="rounded h-36"
              style={{
                border: "1px solid lightgray",
              }}
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Terms & Conditions
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.termsnConditionData &&
                    props?.termsnConditionData?.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Tooltip title="Delete Terms n Condition">
                              <div
                                className="text-red-700"
                                onClick={() => {
                                  setOpenPost(true);
                                  setDeleteIndex(index);
                                }}
                              >
                                {<DeleteOutlineOutlinedIcon />}
                              </div>
                            </Tooltip>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {row?.termsNConditions?.label}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {props.termsnConditionData &&
              props?.termsnConditionData?.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-9">
                  No Terms And Conditions Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this Identification Mark ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
