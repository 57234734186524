import React, { useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { getIndentListPrint } from "../services/commonServices/StoreIndent/StoreIndentService";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { useRef } from "react";
import { useEffect } from "react";

export default function StoreIndentPrint({ Object }) {
  const printRef = useRef(null);
  const [dataResult, setDataResult] = useState();

  // let obj = {
  //   fromDate: "2024-01-31T10:48:41.980Z",
  //   fromStoreId: null,
  //   indentStatus: 2,
  //   isUrgent: 2,
  //   loginStoreId: null,
  //   page: 0,
  //   size: 10,
  //   toDate: "2024-01-31T10:48:41.980Z",
  //   toStoreId: null,
  // };

  useEffect(() => {
    getIndentListPrint(Object)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [Object]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);
  return (
    <>
      <table className="w-full" ref={printRef}>
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center">
            <h1 className=" font-semibold text-[15px] ">
              Inamdar Multispeciality Hospital
            </h1>
            <h1 className=" text-[11px] ">
              Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
            </h1>
            <h1 className=" font-semibold text-[13px]">Indent List</h1>
          </div>
          <div className=" text-[11px] font-semibold border-t-[1px] border-b-[1px] border-black grid grid-cols-[11%_7%_11%_17%_17%_10%_10%_10%_8%]">
            <div className=" p-[1px]">IndentNo</div>
            <div className=" p-[1px]">Date</div>
            <div className=" p-[1px]">From</div>
            <div className=" p-[1px]">ToStore</div>
            <div className=" p-[1px]">ItemName</div>
            <div className=" p-[1px]">Con</div>
            <div className=" p-[1px]">IndentQty</div>
            <div className=" p-[1px]">IssuedQty</div>
            <div className=" p-[1px]">PendingQty</div>
          </div>
        </thead>
        <tbody className="w-full">
          <div>
            {dataResult?.map((data) => {
              return (
                <div className=" text-[11px]  border-b-[1px] grid grid-cols-[11%_7%_11%_17%_17%_10%_10%_10%_8%]">
                  <div className=" p-[2px]">{data?.IndentNo}</div>
                  <div className=" p-[2px]">{data?.Date}</div>
                  <div className=" p-[2px]">{data?.FromStore}</div>
                  <div className=" p-[2px]">{data?.ToStore}</div>
                  <div className=" p-[2px]">{data?.itemName}</div>
                  <div className=" p-[2px]">{data?.Conversion}</div>
                  <div className=" p-[2px]">{data?.IndentQty}</div>
                  <div className=" p-[2px]">{data?.IssuedQty}</div>
                  <div className=" p-[2px]">{data?.PendingQty}</div>
                </div>
              );
            })}
          </div>
          <div className=" font-semibold text-[12px] text-end my-3 mx-3">
            Total Indent Value = {dataResult?.[0]?.TotalValue}
          </div>
        </tbody>
      </table>
    </>
  );
}
