import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import TimePickerFieldNew from "../../../../common/components/FormFields/TimePickerFieldNew";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { saveAnesthesiaNotes } from "../../../services/otDetailsServices/OtDetailsServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const AnesthesiaNotesModal = (props) => {
  const { selectedRowData } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [formData, setFormData] = useState(null); // Changed to null

  const { control, reset, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      anesthesiaNoteDesc: "",
      startingTime: null,
      endingTime: null,
      bp: "",
      pulseType: "",
      anaesthesiaRelatedMortility: false,
      planOfAnaesthesiaModified: false,
      unplannedVentilationAnaesthesia: false,
      adverseAnaesthesiaEvents: false,
      reasonOfAnaesthesiaRelatedMortility: "",
      reasonOfModificationOfAnaesthesia: "",
      reasonOfUnplannedVentilationAnaesthesia: "",
      adverseAnaesthesiaEventsReason: "",
    },
  });

  const onSubmit = (data) => {
    // Prepare final object for submission
    const finalObj = {
      anesthesiaNoteDesc: `anesthesiaType-${data.anesthesiaNoteDesc || ""},bp-${
        data.bp || ""
      },pulseType-${data["pulseType"] || ""},startingTime-${
        data.startingTime || new Date()
      },endingTime-${data.endingTime || new Date()}`,
      isAnesthesiaRelatedMortality: data.anaesthesiaRelatedMortility,
      anesthesiaRelatedMortality: data.reasonOfAnaesthesiaRelatedMortility,
      isPlanOfAnesthesiaModified: data.planOfAnaesthesiaModified,
      planOfAnesthesiaModified: data.reasonOfModificationOfAnaesthesia,
      isUnplannedVentilationAnaesthesia: data.unplannedVentilationAnaesthesia,
      unplannedVentilationAnaesthesia:
        data.reasonOfUnplannedVentilationAnaesthesia,
      isAdverseAnesthesiaEvents: data.adverseAnaesthesiaEvents,
      adverseAnesthesiaEvents: data.adverseAnaesthesiaEventsReason,
      headerId: props.selectedRowData?.OTHeaderID,
    };
    console.log("finalObj123", finalObj);

    setFormData(finalObj); // Store form data
    setOpenConfirmationModal(true); // Open confirmation modal
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    saveAnesthesiaNotes(formData)
      .then((response) => response?.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          reset(); // Reset form after successful save
          props.handleClose(); // Close modal
        }
      })
      .catch((res) => {
        errorAlert(res.message);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    if (selectedRowData) {
      let anesthesiaRecord = selectedRowData?.AnesthesiaRecord?.split(",");
      console.log("anesthesiaRecord123", anesthesiaRecord);
      reset({
        anesthesiaNoteDesc: anesthesiaRecord[0]?.split("-")[1],
        bp: anesthesiaRecord[1]?.split("-")[1],
        pulseType: anesthesiaRecord[2]?.split("-")[1],
        startingTime: anesthesiaRecord[3]?.split("-")[1],
        endingTime: anesthesiaRecord[4]?.split("-")[1],
        anaesthesiaRelatedMortility:
          selectedRowData?.AnaesthesiaRelatedMortility === true ? true : false,
        planOfAnaesthesiaModified:
          selectedRowData?.PlanOfAnaesthesiaModified === true ? true : false,
        unplannedVentilationAnaesthesia:
          selectedRowData?.UnplannedVentilationAnaesthesia === true
            ? true
            : false,
        adverseAnaesthesiaEvents:
          selectedRowData?.IsAdverseAnaesthesiaEvents === true ? true : false,
        reasonOfAnaesthesiaRelatedMortility:
          selectedRowData?.ReasonOfAnaesthesiaRelatedMortility,
        reasonOfModificationOfAnaesthesia:
          selectedRowData?.ReasonOfModificationOfAnaethesia,
        reasonOfUnplannedVentilationAnaesthesia:
          selectedRowData?.ReasonOfUnplannedVentilationAnaesthesia,
        adverseAnaesthesiaEventsReason:
          selectedRowData?.AdverseAnaesthesiaEvents,
      });
    }
  }, [selectedRowData]);

  return (
    <Modal open={props.open}>
      <Box sx={ModalStyle}>
        <div className="mx-3 mb-3 mt-2">
          <div className="p-2 rounded text-black font-semibold">
            Anesthesia Notes
          </div>
          <CancelPresentationIconButton onClick={props.handleClose} />
          <hr className="border mx-1 border-blue-900" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3 grid grid-cols-6 gap-3">
              <div className="col-span-2">
                <InputArea
                  name="anesthesiaNoteDesc"
                  label="Anesthesia Note Description"
                  placeholder="Description"
                  control={control}
                />
              </div>
              <div>
                <TimePickerFieldNew
                  control={control}
                  name="startingTime"
                  label="Start Time"
                  value={new Date()}
                />
              </div>
              <div>
                <TimePickerFieldNew
                  control={control}
                  name="endingTime"
                  label="End Time"
                  value={new Date()}
                />
              </div>
              <div>
                <InputArea
                  name="bp"
                  variant="outlined"
                  label="BP"
                  control={control}
                />
              </div>
              <div>
                <InputArea
                  name="pulseType"
                  variant="outlined"
                  label="Pulse Type-A"
                  control={control}
                />
              </div>
            </div>

            <hr className="col-span-6 border my-2 divide-x-8 bg-slate-300 border-slate-300" />

            <div className="grid grid-cols-4 gap-3">
              <div>
                <CheckBoxField
                  name="anaesthesiaRelatedMortility"
                  label="Anaesthesia Related Mortality"
                  control={control}
                />
              </div>
              <div className="col-span-3">
                <InputArea
                  name="reasonOfAnaesthesiaRelatedMortility"
                  label="Reason Of Anaesthesia Related Mortality"
                  placeholder="Reason"
                  control={control}
                />
              </div>
              <div>
                <CheckBoxField
                  name="planOfAnaesthesiaModified"
                  label="Plan Of Anaesthesia Modified"
                  control={control}
                />
              </div>
              <div className="col-span-3">
                <InputArea
                  name="reasonOfModificationOfAnaesthesia"
                  label="Reason Of Modification Of Anaesthesia"
                  placeholder="Reason"
                  control={control}
                />
              </div>
              <div>
                <CheckBoxField
                  name="unplannedVentilationAnaesthesia"
                  label="Unplanned Ventilation Anaesthesia"
                  control={control}
                />
              </div>
              <div className="col-span-3">
                <InputArea
                  name="reasonOfUnplannedVentilationAnaesthesia"
                  label="Reason Of Unplanned Ventilation Anaesthesia"
                  placeholder="Reason"
                  control={control}
                />
              </div>
              <div>
                <CheckBoxField
                  name="adverseAnaesthesiaEvents"
                  label="Adverse Anaesthesia Events"
                  control={control}
                />
              </div>
              <div className="col-span-3">
                <InputArea
                  name="adverseAnaesthesiaEventsReason"
                  label="Adverse Anaesthesia Events Reason"
                  placeholder="Reason"
                  control={control}
                />
              </div>
            </div>
            <div className="flex justify-end my-3 gap-x-3">
              <CommonButton
                label={"Reset"}
                className={"border border-customRed text-customRed"}
                onClick={() => {
                  reset(); // Reset form
                }}
              />
              <CommonButton
                label={"Save"}
                className={"bg-customGreen text-white"}
                type="submit"
              />
            </div>
          </form>
        </div>

        {/* Backdrop */}
        <CommonBackDrop openBackdrop={openBackdrop} />

        {/* Confirmation Modal */}
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => setOpenConfirmationModal(false)}
          confirmationSubmitFunc={handleFormSubmit} // Call the form submit function
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure?"
          confirmationButtonMsg="Proceed"
        />
      </Box>
    </Modal>
  );
};

export default AnesthesiaNotesModal;

