import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import OPDBilling from "../../../billing/components/opdbilling/OPDBilling";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  minHeight: "50%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function OpdBillModal(props) {
  const { open, handleClose, menuId } = props;

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="-mt-14">
            <OPDBilling menuId={menuId} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
