import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { Tooltip } from "@mui/material";

export default function SelectableChagreTable(props) {
  const { data, setData } = props;
  const [rowIndex, setRowIndex] = React.useState("");

  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [deleteIndex, setDeleteIndex] = React.useState(null);

  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.data];
      newTemplateData.splice(deleteIndex, 1);
      props.setData(newTemplateData);
      handleClosePost();
    }
  };

  const handleClick = (index, row) => {
    setRowIndex(index);
  };

  // const handleCheckClick = (event, index, row) => {
  //

  //   //

  //   if (event.target.checked === true) {
  //     setRowIndex(index);
  //   } else if (event.target.checked === false) {
  //     setRowIndex("");
  //   }
  // };
  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 20,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-36"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* {props.serviceheaders.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))} */}
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Service Name
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Quantity</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Rate</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Doctor Name
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((charge, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Tooltip title="Delete Service">
                            <div
                              className="text-red-500 mr-3"
                              onClick={() => {
                                setOpenPost(true);
                                setDeleteIndex(index);
                              }}
                            >
                              {<DeleteOutlineOutlinedIcon />}
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {charge.serviceName.label}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {charge.quantity}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {charge.rate}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {charge.doctor.label}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              {props.data.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-9">
                  No Service Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>

        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this service ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
