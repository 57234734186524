import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//Store Name
export const fetchReceivedBy = () => {
  //
  return apiClient.get(`/issueToDepartment/receivedBy`, {
    headers: authHeader(),
  });
};

//Store Name
export const fetchItemsByItemId = (itemId, storeId) => {
  //
  return apiClient.get(
    `/issueToDepartment/itemDetailsList/${itemId}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};
