import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import BatchSelectionModal from "./BatchSelectionModal";
// import SubstituteDrugModal from "./SubstituteDrugModal";

export default function ItemDetailsTable(props) {
  const { batchInputFocus } = props;
  //state varibale for the table

  const [selctedRowData, setSelctedRowData] = React.useState();
  //batch table data
  const [batchData, setBatchData] = useState(null);

  //for error input

  // selected row data of batch table inside batch selection modal
  const [batchSelctedRowData, setBatchSelctedRowData] = React.useState(null);

  //
  //open batch selection modal
  const [openBatchModal, setOpenBatchModal] = React.useState(false);
  const handleOpenBatchModal = () => setOpenBatchModal(true);
  const handleCloseBatchModal = () => setOpenBatchModal(false);

  //////////////////////

  /////

  var arrLength = props.data.length;
  //add required field in arr
  useEffect(() => {
    let updatedDtaResult = props.data.map((item) => ({
      ...item,
      Quantity: !!item?.Quantity ? item.Quantity : 0,
    }));

    props.setItemDetails(updatedDtaResult);
  }, [arrLength]);
  //
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };
  //

  //set rows object to table
  const [headers, setHeaders] = useState([]);
  React.useEffect(() => {
    if (props.data.length > 0) {
      const allHeaders = Object.keys(props.data[0]);

      const headersArr = removeHeaders(allHeaders, ["id"]);
      setHeaders(headersArr);
    }
  }, [props.data]);

  //
  const FocusQuantityInput = useRef(null);

  useEffect(() => {
    // case:3 total gst amount
    let gst = 0;
    let totalgst = 0;
    for (var i = 0; i < props.data.length; i++) {
      //
      gst = props.data[i].GstAmount !== undefined ? props.data[i].GstAmount : 0;
      totalgst += Number(gst);
    }
    props.setTotalGst(totalgst.toFixed(2));

    ///case:4 total return amount && net return amt

    let returnAmt = 0;
    let totalReturnAmt = 0;
    for (var i = 0; i < props.data.length; i++) {
      //
      returnAmt =
        props.data[i].returnPrice !== undefined ? props.data[i].returnPrice : 0;
      totalReturnAmt += Number(returnAmt);
    }

    props.setTotalReturnAmt(totalReturnAmt.toFixed(2));
  }, [props.data]);

  // quantity onchange
  const handleTableInputChange = (e, index) => {
    let temp = [...props.data];
    //add return qty label in arr
    temp[index].Quantity = e?.target?.value;

    //  calculations
    let onChangeQty = parseInt(e.target.value !== "" ? e.target.value : 0);
    if (onChangeQty !== undefined && onChangeQty !== null) {
      for (let item of temp) {
        let minimumValue = Math.min(item.BalQty, item["Issue Quantity"]);

        if (Number(item.Quantity) < 1 || item.Quantity > minimumValue) {
          item.error = true;
          props.setErrorToTblInput(true);
        } else {
          item.error = false;
          props.setErrorToTblInput(false);
        }
      }
    }
    props.setItemDetails(temp);
  };

  //
  const deleteRow = (index) => {
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    props.setItemDetails(newTemplateData);

    handleTableInputChange();
  };

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.2,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Action
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch Number
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Expiry Date
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Return Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Bal Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Issue Quantity
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data &&
                    props.data.map((items, index) => {
                      //
                      return (
                        <TableRow
                          ref={batchInputFocus}
                          key={index}
                          // tabIndex={-1}
                          hover={false}
                          style={
                            {
                              // backgroundColor:
                              //   selectedItemDtlsID === index ? "#FFC44B" : "",
                              // height: selectedItemDtlsID === index ? "32px" : "",
                            }
                          }
                          sx={{
                            "& td": {
                              paddingY: 0.2,
                            },
                          }}
                          // onClick={() => {
                          //   setSelectedItemDtlsID(index);
                          // }}
                        >
                          <TableCell>
                            <div className="flex">
                              <Tooltip title="Delete">
                                <div
                                  className="text-customRed"
                                  onClick={() => {
                                    deleteRow(index);
                                  }}
                                >
                                  {<DeleteOutlineOutlinedIcon />}
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items["Item Code"]}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items["Item Name"]}
                          </TableCell>

                          {/* //Batch no// */}
                          <TableCell className="whitespace-nowrap">
                            {items["Batch Code"]}
                          </TableCell>

                          {/* //ExpDate// */}
                          <TableCell className="whitespace-nowrap">
                            {items["Batch Exp Date"]}
                          </TableCell>

                          {/* //qty// */}
                          <TableCell className="whitespace-nowrap">
                            <span ref={FocusQuantityInput}>
                              <input
                                className={
                                  items.BalQty === 0
                                    ? "bg-slate-300 border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                    : items.Quantity > 0 && items.error !== true
                                    ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                    : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                                }
                                disabled={items.BalQty === 0 ? true : false}
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                                value={items.Quantity}
                                type="number"
                                min="0"
                                onWheel={(event) => event.currentTarget.blur()}
                              />
                            </span>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.BalQty}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items["Issue Quantity"]}
                          </TableCell>

                          {/* <TableCell className="whitespace-nowrap">
                            {items.MRP}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.MRP}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.BatchExpiryDate}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.VATPercentage}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.BalQty}
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>

      {/* Batch Modal */}
      {openBatchModal ? (
        <BatchSelectionModal
          open={openBatchModal}
          setOpen={setOpenBatchModal}
          handleOpen={handleOpenBatchModal}
          handleClose={handleCloseBatchModal}
          selctedRowData={selctedRowData}
          setSelctedRowData={setSelctedRowData}
          //
          batchSelctedRowData={batchSelctedRowData}
          setBatchSelctedRowData={setBatchSelctedRowData}
          batchData={batchData}
          setBatchData={setBatchData}

          //

          //
        />
      ) : null}
    </>
  );
}
