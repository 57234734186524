import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  TableSortLabel
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function CommonTable(props) {
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [rescheduleAction, setrescheduleAction] = React.useState(false);
  const [cancelAction, setcancelAction] = React.useState(false);
  const [CheckboxAction, setCheckboxAction] = React.useState(false);
  const [billingAction, setBillingAction] = React.useState(false);
  const [rowIndex, setRowIndex] = React.useState();

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };
  function handleClick(index) {
    setRowIndex(index);
  }

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);
  const headers = removeHeaders(allHeaders, ["Id","BillId","Opd_Ipd_External_Id"]);

  React.useEffect(() => {
    props.actions.forEach((action) => {
      if (action === "cancel") {
        setcancelAction(true);
      }
      if (action === "casepaper") {
        setCheckboxAction(true);
      }
      if (action === "rescheduled") {
        setrescheduleAction(true);
      }
      if (action === "billing") {
        setBillingAction(true);
      }
    });
  }, []);
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 0 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 5,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-48 2xl:h-40"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold">Select</span>
                    </TableCell>

                    {headers.map((header, index) => (
                      <TableCell
                        sortDirection={orderBy === header ? order : false}
                        className="whitespace-nowrap"
                      >
                        <TableSortLabel
                          active={false}
                          direction={orderBy === header ? order : "asc"}
                          onClick={createSortHandler(header)}
                          key={index}
                        >
                          <span className="text-gray-600 font-bold">
                            {header}
                          </span>
                          {orderBy === header ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.result.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          
                        }}
                       
                      >
                        <TableCell>
                          <Checkbox
                            onChange={(e) => {
                              props.setSelectedBill(row.BalanceAmountSelf);
                              props.setSalesId(row.BillId);
                              handleClick(index, row);
                            }}
                            checked={rowIndex === index}
                            size="small"
                          />
                        </TableCell>

                        {headers &&
                          headers.map((header, i) => (
                            <TableCell
                              className="whitespace-nowrap"
                              key={index.id}
                            >
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {/* {props.data.length > 0 ? (
               null
              ) : (
                <div className="flex items-center justify-center text-red-400 font-semibold mt-20">
                  No Item Added
                </div>
              )} */}
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
