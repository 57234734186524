import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import { ModalStyleFeedback } from "../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  saveIVFluid,
  updateIvFluid,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";

function IVFluidEditModal(props) {
  const schema = yup.object().shape({
    volume: yup
      .string()
      .required("Required")
      .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric are allowed"),
    rateOfInfusion: yup.string().required("Required"),
  });

  const defaultValues = {
    volume: "",
    rateOfInfusion: "",
    dose: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues,
  });

  const [finalData, setFinalData] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [newDoseChangeArr, setNewDoseChangeArr] = useState([]);

  const handleCloseConfirmationModal = () => {
    if (openConfirmationModal) {
      setOpenConfirmationModal(false);
    }
  };

  function postIVFluid(obj) {
    updateIvFluid(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData(null);
        reset(defaultValues);
        props.setOpenLoader(false);
        props.populateTable();
        props.handleClose();
      })
      .catch((error) => {
        errorAlert(error.message);
        handleCloseConfirmationModal();
        props.setOpenLoader(false);
      });
  }

  function addRecord() {
    setOpenConfirmationModal(false);
    postIVFluid(finalData);
    props.setOpenLoader(true);
  }
  const onSubmitDataHandler = (data) => {
    const result = newDoseChangeArr.filter((item) => item.Dose === "");

    let reqArrForInfusionData = [];
    for (let obj of newDoseChangeArr) {
      let tempObj = {
        ivFluidDetailsId: Number(obj.ivFluidDetailsID),
        dose: obj.Dose,
      };
      reqArrForInfusionData.push(tempObj);
    }

    let postObj = {
      menuId: props.menuId,
      privilege: props.privilege,
      updateIvFluidDetailsDtoList: reqArrForInfusionData,
      ivFluidId: props.selectedRow.ivID,
      ivVol: data.volume,
      rateOfInfusion: data.rateOfInfusion,
    };

    console.log("addIv", result);
    if (result?.length === 0) {
      setFinalData(postObj);
      setOpenConfirmationModal(true);
    }else{
        errorAlert("Please Add Dose")
    }
  };

  const handleChangeDoseQty = (e, index, row) => {
    let temp = [...newDoseChangeArr];
    const selectedItemRow = temp[index];
    selectedItemRow.ivFluidDetailsID = row.ivFluidDetailsID;
    selectedItemRow.Dose = e.target.value;
    selectedItemRow["Drug Name"] = row.drugAdded || row["Drug Name"];
    selectedItemRow.color = "";
    let quantityVal = e.target.value;

    console.log("quantityVal", quantityVal);
    if (quantityVal === "") {
      selectedItemRow.color = "#D11A2A";
      setOpenConfirmationModal(false);
    } else {
      selectedItemRow.color = "#0B83A5";
    }

    temp[index] = selectedItemRow;
    setNewDoseChangeArr(temp);
  };
  console.log("newDoseChangeArr", newDoseChangeArr);

  const renderInput = (row, index, header) => {
    return (
      <>
        <input
          type="text"
          name={`dose${index}`}
          defaultValue={row.Dose}
          {...register(`dose${index}`)}
          control={control}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            handleChangeDoseQty(e, index, row);
          }}
          className="w-20 rounded border border-customBlue text-center"
          style={{ borderColor: `${row.color}` }}
        />
      </>
    );
  };

  useEffect(() => {
    if (props.selectedRow !== null) {
      setValue("volume", props.selectedRow?.vol);
      setValue("rateOfInfusion", props.selectedRow?.rateOfInfusion);

      let reqArrForInfusionData = [];
      for (let obj of props.selectedRow?.intravenousInfusionDetailsDtoList) {
        let tempObj = {
          ivFluidDetailsID: Number(obj.ivFluidDetailsID),
          "Drug Name": obj.drugAdded,
          Dose: obj.doseOfAdded,
        };
        reqArrForInfusionData.push(tempObj);
      }
      setNewDoseChangeArr(reqArrForInfusionData);
    }
  }, [props.selectedRow]);

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="h-[44%] w-[60%] p-2 overflow-y-auto rounded"
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              reset(defaultValues);
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <h1 className="text-lg font-semibold  flex justify-center">
              Intravenous Infusion Therapy
            </h1>
            <div className="bg-gray-100 rounded p-2  w-full text-sm  flex justify-between px-2 font-semibold  text-gray-600   my-2">
              <div>
                <h3>IV Fluid &nbsp; : &nbsp; {props.selectedRow?.ivFluid}</h3>
              </div>
              <div>
                <h3>Date &nbsp; : &nbsp; {props.selectedRow.date} </h3>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-2">
              <div>
                <InputField
                  control={control}
                  error={errors.rateOfInfusion}
                  label="Rate Of Infusion"
                  name="rateOfInfusion"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div>
                <InputField
                  control={control}
                  error={errors.volume}
                  label="Volume"
                  name="volume"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            {props.selectedRow !== null && newDoseChangeArr.length > 0 && (
              <CommonDynamicTableNew
                dataResult={newDoseChangeArr}
                tableClass={"h-[200px]"}
                removeHeaders={["ivFluidDetailsID", "color"]}
                editableColumns={["Dose"]}
                renderInput={renderInput}
                highlightRow={false}
              />
            )}

            <div className="flex items-center justify-end pt-2 pb-1">
              <div className="flex space-x-2">
                <CommonButton
                  type="button"
                  className="border border-customRed text-customRed"
                  label="Reset"
                  onClick={() => {
                    reset(defaultValues);
                  }}
                />
                <CommonButton
                  type="submit"
                  className="bg-customGreen text-white"
                  label="Save"
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}
export default React.memo(IVFluidEditModal);
