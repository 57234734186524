import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import InputArea from "../../../../common/components/FormFields/InputArea";
import NeonatalModal from "./NeonatalModal";
import InputField from "../../../../common/components/FormFields/InputField";

const PediatricDetails = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const { control } = useFormContext();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  //
  return (
    <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-3 pt-3">
      {/* ///Per Vaginal  /// */}
      <div className="lg:col-span-2 md:col-span-2  grid grid-cols-5">
        <div className="col-span-5">
          <InputArea
            name="birthHistoryPediatric"
            control={control}
            placeholder="Birth History"
            label="Birth History"
          />
        </div>
      </div>
      {/* ///Immunisation History /// */}
      <div className="lg:col-span-2">
        <InputArea
          name="immunisationHistory"
          control={control}
          placeholder="Immunisation History"
          label="Immunisation History"
        />
      </div>
      {/* ///Dietary History  /// */}
      <div className="lg:col-span-2">
        <InputArea
          name="dietaryHistory"
          control={control}
          placeholder="Dietary History"
          label="Dietary History"
        />
      </div>
      {/* ///Development History  /// */}
      <div className="lg:col-span-3">
        <InputArea
          name="developmentHistory"
          control={control}
          placeholder="Development History"
          label="Development History"
        />
      </div>
      {/* ///Social History  /// */}
      <div className="lg:col-span-3">
        <InputArea
          name="socialHistory"
          control={control}
          placeholder="Social History"
          label="Social History"
        />
      </div>

      <div className=" col-span-3 p-3 ">
        <table className="min-w-full border rounded-md ">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider"></th>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">
                On Admission
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">
                On Discharge
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr>
              <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                Weight
              </td>
              <td className="px-6 py-2 whitespace-nowrap">
                <InputField
                  name="weightOnAdmission"
                  variant="outlined"
                  label="Weight on Admission"
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                  className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </td>
              <td className="px-6 py-2 whitespace-nowrap">
                <InputField
                  name="weightOnDischarge"
                  variant="outlined"
                  label="Weight on Discharge"
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                  className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </td>
            </tr>
            <tr>
              <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
              Height
              </td>
              <td className="px-6 py-2 whitespace-nowrap">
                <InputField
                  name="lengthOnAdmission"
                  variant="outlined"
                  label="Height on Admission"
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                  className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </td>
              <td className="px-6 py-2 whitespace-nowrap">
                <InputField
                  name="lengthOnDischarge"
                  variant="outlined"
                  label="Height on Discharge"
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                  className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {openModal ? (
        <NeonatalModal
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setStateNeonatalDto={props.setStateNeonatalDto}
          patchData={props.patchData}
        />
      ) : null}
    </div>
  );
};

export default PediatricDetails;
