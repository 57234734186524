import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { BedAllocationModalContext } from "./BedAllowModal";

export default function BedListView(props) {
  const dispatch = useDispatch();
  const { userActions } = useContext(BedAllocationModalContext);
  console.log("userActions@bedlist", userActions);

  const [openModal, setOpenModal] = React.useState(false);
  const [bedData, setBedData] = React.useState([]);
  const [bedDetails, setBedDetails] = React.useState();
  const [checked, setChecked] = React.useState([]);
  const [radioCheckbox, setRadioCheckbox] = React.useState(props.data);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.bedListData !== null) {
      setBedData(props.bedListData);
    }
  }, [props]);

  //open modal to confirm add bed
  const handelOpenModal = (selectedData) => {
   
    setOpenModal(true);
    setBedDetails(selectedData);
    handleRouteAddition();
  };
  //state variable to close the confirmation modal for bed  request
  const handleCloseModal = () => {
    if (openModal) {
      setOpenModal(false);
    }
  };

  //Code Added By Omkar

  const handleRouteAddition = (userActions) => {
    userActions?.map((item) => {
      if (item.menuKey) {
        // dispatch(routeAdded(item.menuKey));
      }
    });
  };
  //

  //event listener function for the Add button on the modal form
  function addRecord(bedDetails) {
    // console.log("A new bed  has been added", bedDetails);

    // handleRouteAddition("/admission/PatientAdmissionDetails");
    navigate("/admission/PatientAdmissionDetails", {
      state: {
        bedDetails: bedDetails,
        menuId: 261,
        privilege: "Create",
        // isFromBed: true,
      },
    });
    setOpenModal(false);
    props.handleCloseBed();
  }

  //
  const isSelected = (name) => checked.indexOf(name) !== -1;

  const handleToggle = (value, id) => () => {
    // console.log("Current", value, id);
    const selectedIndex = checked.indexOf(id);
    // const currentIndex = checked.indexOf(value);
    let newChecked = [];

    newChecked = newChecked.concat(id);

    if (selectedIndex === 0) {
      let newSelected = [];
      setChecked(null);
    }
    setChecked(newChecked);

    //value.allocatedflag === false ? handelOpenModal(value) : null;
  };

  const [rowIndex, setRowIndex] = React.useState("");

  const handleClick = (index, row) => {
    // console.log("Selected row object is " + JSON.stringify(row));
    setRowIndex(index);
  };

  const handleCheckClick = (event, index, row) => {
    // console.log("Checkbox value is " + event.target.checked);

    // console.log("Selected row object is " + JSON.stringify(row));

    if (event.target.checked === true) {
      setRowIndex(index);
    } else if (event.target.checked === false) {
      setRowIndex("");
    }
  };

  const deleteRow = (index) => {
    // console.log("deleteRow", index);
    // console.log(...props.data);
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    // console.log(newTemplateData);
    props.setMedicationData(newTemplateData);
  };

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded lg:h-96 xl:h-[700px]"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead className="">
                  <TableRow>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Status
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Bed Code
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Bed Category
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Room
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Block
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Ward
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.bedListData.map((item, index) => {
                    // console.log("Medication Data is", Medication);
                    return (
                      <TableRow
                        key={index}
                        role="checkbox"
                        // button
                        onClick={handleToggle(item, item.bedid)}
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        // selected={isItemSelected}
                      >
                        <TableCell>
                          <Checkbox
                            color="primary"
                            // checked={isItemSelected}
                            inputProps={
                              {
                                // "aria-labelledby": labelId,
                              }
                            }
                            size="small"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            checked={radioCheckbox === index ? true : false}
                            onChange={() => setRadioCheckbox(index)}
                            disabled={item.Occupied}
                            onClick={() => {
                              userActions.map((actions) => {
                                if (actions.action === "Create") {
                                  handelOpenModal(item, index);
                                }
                              });
                            }}
                          />
                        </TableCell>

                        {item.Occupied ? (
                          <TableCell>
                            <p className="text-red-500 font-bold rounded">
                              Unavailable
                            </p>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <p className="text-green-500 font-bold rounded">
                              Available
                            </p>
                          </TableCell>
                        )}

                        <TableCell>{item.BedNo}</TableCell>
                        <TableCell>{item.Class}</TableCell>
                        <TableCell>{item.Room}</TableCell>
                        <TableCell>{item.floor}</TableCell>
                        <TableCell>{item.block}</TableCell>
                        <TableCell>{item.Ward}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
      <ConfirmationModal
        confirmationOpen={openModal}
        confirmationHandleClose={handleCloseModal}
        confirmationSubmitFunc={() => addRecord(bedDetails)}
        confirmationLabel="Confirmation"
        confirmationMsg="Click to add this bed ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
