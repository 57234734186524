import axios from "axios";
import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const ipdDischargeSearch = (patientName) => {
  return apiClient.get(`/discharge/dischargeSearch/${patientName}`, {
    headers: authHeader(),
  });
};

export const fetchAllDischargeList = (dischargeObj) => {
  return apiClient.post(`/discharge/dischargeList`, dischargeObj, {
    headers: authHeader(),
  });
};
export const searchDischargeList = (searchObj) => {
  return apiClient.post(`/discharge/dischargeListSearch`, searchObj, {
    headers: authHeader(),
  });
};
// /api/discharge/patientInfoForDischarge/{patientId}
export const getDischargePatientInfoById = (patientId) => {
  return apiClient.get(`/discharge/patientInfoForDischarge/${patientId}`, {
    headers: authHeader(),
  });
};
export const getDischargeType = () => {
  return apiClient.get(`/discharge/dischargeType`, {
    headers: authHeader(),
  });
};
export const getDischargeDestination = () => {
  return apiClient.get(`/discharge/dischargeDestination`, {
    headers: authHeader(),
  });
};
export const postPatientDischarge = (postObj) => {
  return apiClient.post(`/discharge/saveDischarge`, postObj, {
    headers: authHeader(),
  });
};

// /api/discharge/allowToGo/{admissionId}
export const patientAllowToGoToSleep = (admId, menuId) => {
  return apiClient.get(`/discharge/allowToGo/${admId}/${menuId}`, {
    headers: authHeader(),
  });
};
