import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

//############  Assign Funtionality Services ###########

//get role functionalities array
export const getUserFuctionalitiesByRole = (roleId) => {
  return apiClient.get(`/role/getFunctionalities/${roleId}`, {
    headers: authHeader(),
  });
};

// save functionalities for role
export const saveRoleFunctionalities = (postObject) => {
  return apiClient.post(
    `/functionality/assignFunctionalityToRole`,
    postObject,
    {
      headers: authHeader(),
    }
  );
};

//############  Users Services ###############

//get units AutoComplete
export const getUnits = () => {
  return apiClient.get(`/misc/unit`, {
    headers: authHeader(),
  });
};

//get Departments Autocomplete
export const getDepartments = (unitId, searchString) => {
  return apiClient.get(`/misc/departmentForAppt/${unitId}/${searchString}`, {
    headers: authHeader(),
  });
};

export const getUserInfo = (userId) => {
  return apiClient.get(`/employee/getUserDetails/${userId}`, {
    headers: authHeader(),
  });
};

//search employee for users
export const searchEmployees = (searchObject) => {
  return apiClient.post(`/employee/searchEmployee`, searchObject, {
    headers: authHeader(),
  });
};

//get roles autocomplete
export const getRolesAutocomplete = () => {
  return apiClient.get(`/role/dropdown`, {
    headers: authHeader(),
  });
};

//getEmployeeListing
export const getEmployeeListing = (searchObject) => {
  return apiClient.post(`/userManagement/getUserList`, searchObject, {
    headers: authHeader(),
  });
};

//get employee autoComplete
export const getEmployeeOptions = (searchString, unitId) => {
  return apiClient.get(`/userManagement/searchUser/${searchString}/${unitId}`, {
    headers: authHeader(),
  });
};

export const getUserFunctionalities = (postObject) => {
  return apiClient.post(
    `/functionality/getassignedfunctionalities`,
    postObject,
    {
      headers: authHeader(),
    }
  );
};

export const getEmployeeRoles = (employeeId) => {
  return apiClient.get(`/employee/getEmpRoles/${employeeId}`, {
    headers: authHeader(),
  });
};

export const saveUsersObject = (postObject) => {
  return apiClient.post(`/userManagement/saveUser`, postObject, {
    headers: authHeader(),
  });
};

//############ User Roles Services ###########

//get Cash Counter Autocomplete
export const getCashCounterOptions = () => {
  return apiClient.get(`/userManagement/getCashCounter`, {
    headers: authHeader(),
  });
};

//save user roles object
export const saveUserRoleObject = (postObject) => {
  return apiClient.post(`/role`, postObject, {
    headers: authHeader(),
  });
};

//get roles list
export const getRolesList = (searchObj) => {
  return apiClient.post(`/role/roleList`, searchObj, {
    headers: authHeader(),
  });
};

//dummy services for adding previleges
export const getUsrePrivileges = () => {
  return apiClient.get(`/functionality/getPrivileges`, {
    headers: authHeader(),
  });
};

export const saveFunctionPrivilege = (postObject) => {
  return apiClient.post(`/functionality/saveFunctionPrivilege`, postObject, {
    headers: authHeader(),
  });
};

export const saveAction=(action)=>{
  return  apiClient.get(`/privilege/${action}`,{
    headers:authHeader()
  })
}
