import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";

//custom hook to detect the key presses in the component.
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  //custom hook that detects the key presses.
  React.useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function BatchInfoTable(props) {
  //state variable to change the background color of the row ; whenever the value of rowIndex state variable changes.
  const [rowIndex, setRowIndex] = React.useState("");

  //defining the variables that store the result of the key presses.
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");

  //useEffect to set the rowIndex to 0 ; before the component is mounted ; to highlight the first row.
  React.useEffect(() => {
    setRowIndex(0);
  }, []);

  //useEffect that executes ; whenever up arrow key is pressed.
  //Whenever the up arrow key is pressed ; the rowIndex state variable changes.
  React.useEffect(() => {
    let rIndex = rowIndex;

    if (arrowUpPressed) {
      if (rowIndex !== 0) {
        rIndex = rIndex - 1;

        setRowIndex(rIndex);
      }
    }
  }, [arrowUpPressed]);

  //useEffect that executes ; whenever down arrow key is pressed.
  //Whenever the down arrow key is pressed ; the rowIndex state variable changes.
  React.useEffect(() => {
    let rIndex = rowIndex;

    if (arrowDownPressed) {
      if (rowIndex !== props.data.result.length - 1) {
        rIndex = rIndex + 1;

        setRowIndex(rIndex);
      }
    }
  }, [arrowDownPressed]);

  //useEffect that executes ; whenever enter key is pressed.
  React.useEffect(() => {
    if (enterPressed === true) {
      //selectedRow state variable belongs to the PrescriptionTable component.
      //selectedRow state variable transports data from batchInfoTable to the PrescriptionTable component.
      props.setIsFocusedBatchModal && props?.setIsFocusedBatchModal(true);
      if (props.data.result[rowIndex]?.isBatchExpired === true) {
        warningAlert("Batch Expired..!");
      } else {
        props.setSelectedRow(props.data.result[rowIndex]);
          props.handleClose();
      }
    }
  }, [enterPressed, rowIndex]);

  //event listener function for the click of the TableRow.
  const handleClick = (row, id, index) => {
    props.setSelectedRow(props.data.result[index]);
  };

  //event listener function for the click of the TableRow
  //The row's index is stored in the state variable rowIndex.
  const handleClickRow = (index, row) => {
    setRowIndex(index);
  };

  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 20,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-56 2xl:h-72"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch No.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Balance Qty.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Expiry Date
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        MRP
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Purchase Rate
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.result.map((drugBatchItem, index, i) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        onClick={(event) => {
                          handleClick(event, drugBatchItem, index);
                          handleClickRow(index, drugBatchItem);
                        }}
                        role="checkbox"
                        style={{
                          backgroundColor: drugBatchItem?.isBatchExpired
                            ? "#e88ba2"
                            : rowIndex === index
                            ? "#FFC44B"
                            : "",
                        }}
                      >
                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem["Batch Code"]}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem["Balance Qty"]}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem["Batch Exp Date"]}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem["UnitMRP"]}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {drugBatchItem["Unit Purchase Rate"]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}

