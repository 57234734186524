import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const fetchAllBioMedicalWasteList = (object) => {
  return apiClient.post(`/MiscServices/getWasteBagsList`, object,{
    headers: authHeader(),
  });
};

export const getBagTypes = () => {
  return apiClient.get(`/MiscServices/getBagTypes`, {
    headers: authHeader(),
  });
};

export const addNewBioWeastBagDetails = async (addWeasteBag) => {
  return await apiClient.post(`/MiscServices/saveWastBagTransaction`, addWeasteBag, {
    headers: authHeader(),
  });
};