import * as React from "react";
import { useEffect, useState } from "react";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Modal, Tooltip } from "@mui/material";
import { format } from "date-fns";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SearchIconButton from "../../../common/components/Buttons/SearchIconButton";
import AmbulanceCall from "./AmbulanceCall";
import BodyHandover from "./BodyHandover";
import HomeVisit from "./HomeVisit";
import VaccineChart from "./VaccineChart";

import { useLocation } from "react-router-dom";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchETURegisterList,
  getPatientInfoForETURegister,
  printETURegisterAmbulanceCallReceipt,
  printETURegisterBodyHandoverReceipt,
  printETURegisterHomevisitReceipt,
  printETURegisterVaccineChartReceipt,
  searchPatient,
} from "../../services/etuRegisterServices/etuRegisterServices";
import IPIssuePrint from "../../prints/IPIssuePrint";
import BodyHandOverPrint from "../../prints/BodyHandOverPrint";
import BodyHandedOverPrintAll from "../../prints/BodyHandoverPrintAll";

// ETU Type dropdown array of object
const vaccineArr = [
  {
    id: 1,
    value: "HomeVisit",
    label: "HomeVisit",
  },
  {
    id: 2,
    value: "AmbulanceCall",
    label: "AmbulanceCall",
  },
  {
    id: 3,
    value: "BodyHandover",
    label: "BodyHandover",
  },
  {
    id: 4,
    value: "Vaccine",
    label: "Vaccine",
  },
];
const EtuRegister = (props) => {
  const { updateComponent } = props;

  /************************** useForm ************************************/

  const schema = yup.object().shape({
    advanceAvailable: yup.number(),
    refundAmount: yup
      .number()
      .typeError("Required")
      .min(1, "Required")
      .required("Required")
      .when("advanceAvailable", (advanceAvailable, schema) => {
        return schema
          .test({
            test: (refundAmount) => {
              if (!refundAmount) return true;
              return refundAmount <= advanceAvailable;
            },
            message: "Amount Exceeds Than Total Advance",
          })
          .required("Required");
      }),
    remark: yup.string(),
  });
  const defaultValues = {
    FromDate: new Date(),
    toDate: new Date(),
    totalAdvance: 0,
    totalConsumed: 0,
    totalRefund: 0,
    advanceAvailable: 0,
    refundAmount: "",
    remark: "",
    opdIpd: 0,
    etuRegisterSearch: null,
  };
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  /****************************** state variables ************************************/

  const [searchString, setSearchString] = React.useState([]);
  const [patientInfoById, setPatientInfoById] = React.useState("");
  const [patientId, setPatientId] = React.useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [dataList, setDataList] = React.useState([]);
  const [showInfo, setShowInfo] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [vistiPdf, setVisitPdf] = React.useState(null);
  const [fromDatePrint, setFromDatePrint] = React.useState(new Date());
  const [toDatePrint, setToDatePrint] = React.useState(new Date());
  const [etuType, setEtuType] = React.useState(null);
  const [userActions, setUserActions] = useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [etuRegisterId, setEtuRegisterId] = useState(null);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  /****************************** other variables ************************************/

  let fromDateVal = watch("FromDate");
  let toDateVal = watch("toDate");

  // get menu id
  let location = useLocation();

  /****************************** useEffects ************************************/
  //used for fetch apdateed data at midnight
  useEffect(() => {
    if (updateComponent) {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
    }
  }, [updateComponent]);

  useEffect(() => {
    if (etuType?.label !== null) {
      populateTable();
    }
  }, [etuType, selectedFromDate, selectedToDate]);

  function populateTable() {
    let postObj = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      trnType: etuType?.label,
    };
    setShowSpinner(true);
    fetchETURegisterList(postObj)
      .then((response) => response.data)
      .then((res) => {
        setShowSpinner(false);
        setDataList(res.result);
      })
      .catch((error) => {});
  }

  /*************************** Dynamic Button  **********************************/
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  /********************************* functions ************************************/

  //auto complete search patient
  function handleChange(searchString) {
    if (searchString !== "") {
      searchPatient(searchString)
        .then((response) => response.data)
        .then((res) => {
          setSearchString(res.result);
        });
    } else {
      setSearchString([]);
    }
  }
  function getPatientInfoById(patientId) {
    getPatientInfoForETURegister(patientId)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfoById(res.result);
      });
  }

  //print Home visit data
  function printHomeReceipt(etuRegisterId) {
    let printObjDate = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
    };
    let printObjMRNo = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
      etuRegisterId: etuRegisterId ? etuRegisterId : null,
    };

    printETURegisterHomevisitReceipt(
      etuRegisterId ? printObjMRNo : printObjDate
    )
      .then((response) => {
        if (response.status === 200) {
          //Get Reponse JSON
          const blb = new Blob([response.data], { type: "text/plain" });
          const reader = new FileReader();
          // Start reading the blob as text.
          reader.readAsText(blb);
          //Convert Blob Response to JSON String
          reader.addEventListener("loadend", (e) => {
            if (e.target.result.charAt(0) !== "") {
              setOpen(true);
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              setVisitPdf(fileURL);
            }
          });
        }
      })
      .catch((response) => {});
  }

  // print Ambulance call data
  function printAmbulanceCallReceipt(etuRegisterId) {
    setOpen(true);
    let printObjDate = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
    };
    let printObjMRNo = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
      etuRegisterId: etuRegisterId ? etuRegisterId : null,
    };

    printETURegisterAmbulanceCallReceipt(
      etuRegisterId ? printObjMRNo : printObjDate
    ).then((response) => {
      if (response.status === 200) {
        //Get Reponse JSON
        const blb = new Blob([response.data], { type: "text/plain" });
        const reader = new FileReader();
        // Start reading the blob as text.
        reader.readAsText(blb);
        //Convert Blob Response to JSON String
        reader.addEventListener("loadend", (e) => {
          if (e.target.result.charAt(0) !== "") {
            setOpen(true);
            const file = new Blob([response.data], {
              type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);

            setVisitPdf(fileURL);
          }
        });
      }
    });
  }

  function printBodyHandoverReceipt(etuRegisterId) {
    let printObj = {};
    if (etuRegisterId) {
    }
    // setOpen(true);
    let printObjDate = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
    };
    let printObjMRNo = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
      etuRegisterId: etuRegisterId ? etuRegisterId : null,
    };

    printETURegisterBodyHandoverReceipt(
      etuRegisterId ? printObjMRNo : printObjDate
    ).then((response) => {
      if (response.status === 200) {
        //Get Reponse JSON
        const blb = new Blob([response.data], { type: "text/plain" });
        const reader = new FileReader();
        // Start reading the blob as text.
        reader.readAsText(blb);
        //Convert Blob Response to JSON String
        reader.addEventListener("loadend", (e) => {
          if (e.target.result.charAt(0) !== "") {
            setOpen(true);
            const file = new Blob([response.data], {
              type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);

            setVisitPdf(fileURL);
          }
        });
      }
    });
  }
  // print Vaccine Chart Data
  function printVaccineChartReceipt(etuRegisterId) {
    let printObjDate = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
    };
    let printObjMRNo = {
      fromDate: format(fromDatePrint, "yyyy-MM-dd")
        ? format(fromDatePrint, "yyyy-MM-dd")
        : new Date(),

      toDate: format(toDatePrint, "yyyy-MM-dd")
        ? format(toDatePrint, "yyyy-MM-dd")
        : new Date(),
      etuRegisterId: etuRegisterId ? etuRegisterId : null,
    };

    printETURegisterVaccineChartReceipt(
      etuRegisterId ? printObjMRNo : printObjDate
    ).then((response) => {
      if (response.status === 200) {
        const blb = new Blob([response.data], { type: "text/plain" });
        const reader = new FileReader();

        reader.readAsText(blb);

        reader.addEventListener("loadend", (e) => {
          if (e.target.result.charAt(0) !== "") {
            setOpen(true);
            const file = new Blob([response.data], {
              type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);

            setVisitPdf(fileURL);
          }
        });
      }
    });
  }

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 2000);

    return (
      <div className="hidden">
        {etuType.value === "BodyHandover" ? (
          <BodyHandedOverPrintAll
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
          />
        ) : (
          <BodyHandOverPrint
            // etuRegisterId={props.etuRegisterId}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
          />
        )}
      </div>
    );
  };
  return (
    <div className="mt-12   min-h-screen">
      <div className="py-2">
        <p className="text-center text-2xl text-gray-700 font-Poppins">
          ETU Register
        </p>
      </div>
      <div className="flex gap-2">
        <div className="w-[40%]">
          <SearchDropdown
            control={control}
            name="etuRegisterSearch"
            placeholder="Search By Patient Name / MR No./ Mobile No"
            dataArray={searchString}
            searchIcon={true}
            isClearable={true}
            isSearchable={true}
            handleInputChange={handleChange}
            inputRef={{
              ...register("etuRegisterSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setPatientInfoById(e.target.value);
                    getPatientInfoById(e.target.value.id);
                    setPatientId(e.target.value.id);
                    setShowInfo(true);
                  } else {
                    setPatientInfoById("");
                    setSearchString([]);
                    setShowInfo(false);
                  }
                },
              }),
            }}
          />
        </div>
        <div className="w-40">
          {/* {patientInfoById && patientInfoById !== null ? ( */}
          <DropdownField
            name="etutype"
            control={control}
            label="ETU Type"
            placeholder="ETU Type"
            isSearchable={false}
            dataArray={vaccineArr}
            inputRef={{
              ...register("etutype", {
                onChange: (e) => {
                  setEtuType(e.target.value);
                },
              }),
            }}
            error={errors.etutype}
          />
        </div>
        <div className="">
          <DatePickerFieldNew
            name="FromDate"
            value={new Date()}
            control={control}
            label="From Date"
            disableFuture={true}
            inputFormat="dd-MM-yyyy"
            inputRef={{
              ...register("FromDate", {
                onChange: (e) => {
                  setFromDatePrint(e.target.value);

                  if (e.target.value.toString().length === 55) {
                    let dateVal = format(e.target.value, "yyyy-MM-dd");

                    let timeVal = dateVal + "T" + "00:00:00.000Z";
                    setSelectedFromDate(timeVal);
                  }
                },
              }),
            }}
          />
        </div>
        <div className="">
          <DatePickerFieldNew
            name="toDate"
            control={control}
            label="To Date"
            value={new Date()}
            inputFormat="dd-MM-yyyy"
            disableFuture={true}
            inputRef={{
              ...register("toDate", {
                onChange: (e) => {
                  setToDatePrint(e.target.value);

                  if (e.target.value.toString().length === 55) {
                    let dateVal = format(e.target.value, "yyyy-MM-dd");

                    let timeVal = dateVal + "T" + "00:00:00.000Z";
                    setSelectedToDate(timeVal);
                  }
                },
              }),
            }}
          />
        </div>
        <div className=" ">
          <SearchIconButton onClick={() => populateTable()} />
        </div>
        <div className="flex items-center">
          {userActions &&
            userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Print All" ? (
                  <>
                    <Tooltip title="Print All">
                      <LocalPrintshopIcon
                        className="cursor-pointer"
                        onClick={() => {
                          if (etuType?.label === "HomeVisit") {
                            printHomeReceipt();
                          } else if (etuType?.label === "AmbulanceCall") {
                            printAmbulanceCallReceipt();
                          } else if (etuType?.label === "BodyHandover") {
                            setOpenPrintModal(true);
                            //setOpenPrintModal(true);
                          } else if (etuType?.label === "Vaccine") {
                            printVaccineChartReceipt();
                          }
                        }}
                      />
                    </Tooltip>
                  </>
                ) : null}
              </>
            ))}
        </div>
      </div>

      <>
        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",

              p: 4,
            }}
          >
            <div>
              <CancelPresentationIconButton
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            {vistiPdf !== null ? (
              <embed
                width="100%"
                height="100%"
                src={vistiPdf}
                target="_blank"
              />
            ) : (
              ""
            )}
          </Box>
        </Modal>
        {etuType?.label === "HomeVisit" ? (
          <HomeVisit
            etuType={etuType?.label}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            eturegisteroptionVal={etuType}
            setValue={setValue}
            patientInfoById={patientInfoById}
            setPatientInfoById={setPatientInfoById}
            patientId={patientId}
            etuRegisterSearchVal={defaultValues}
            dataResult={dataList}
            setSearchString={setSearchString}
            searchString={searchString}
            setDataResult={setDataList}
            populateTable={populateTable}
            printHomeReceipt={printHomeReceipt}
            userActions={userActions}
            setUserActions={setUserActions}
          />
        ) : (
          ""
        )}
        {etuType?.label === "AmbulanceCall" ? (
          <AmbulanceCall
            etuType={etuType?.label}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            eturegisteroptionVal={etuType}
            setValue={setValue}
            patientInfoById={patientInfoById}
            setPatientInfoById={setPatientInfoById}
            patientId={patientId}
            etuRegisterSearchVal={defaultValues}
            dataResult={dataList}
            setSearchString={setSearchString}
            searchString={searchString}
            setDataResult={setDataList}
            populateTable={populateTable}
            printAmbulanceCallReceipt={printAmbulanceCallReceipt}
            userActions={userActions}
            setUserActions={setUserActions}
          />
        ) : null}
        {etuType?.label === "BodyHandover" ? (
          <BodyHandover
            etuType={etuType?.label}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            setValue={setValue}
            eturegisteroptionVal={etuType}
            patientInfoById={patientInfoById}
            setPatientInfoById={setPatientInfoById}
            patientId={patientId}
            etuRegisterSearchVal={defaultValues}
            dataResult={dataList}
            setSearchString={setSearchString}
            searchString={searchString}
            setDataResult={setDataList}
            populateTable={populateTable}
            printBodyHandoverReceipt={printBodyHandoverReceipt}
            userActions={userActions}
            setUserActions={setUserActions}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
            etuRegisterId={etuRegisterId}
            setEtuRegisterId={setEtuRegisterId}
          />
        ) : null}

        {etuType?.label === "Vaccine" ? (
          <VaccineChart
            showInfo={showInfo}
            etuType={etuType?.label}
            setShowInfo={setShowInfo}
            setValue={setValue}
            eturegisteroptionVal={etuType}
            patientInfoById={patientInfoById}
            setPatientInfoById={setPatientInfoById}
            patientId={patientId}
            etuRegisterSearchVal={defaultValues}
            dataResult={dataList}
            setSearchString={setSearchString}
            searchString={searchString}
            setDataResult={setDataList}
            populateTable={populateTable}
            printVaccineChartReceipt={printVaccineChartReceipt}
            userActions={userActions}
            setUserActions={setUserActions}
          />
        ) : null}
      </>
      {/* // ) : null} */}

      {openPrintModal && renderPrint()}
    </div>
  );
};

export default EtuRegister;
