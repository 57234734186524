import React from "react";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";
import { useEffect } from "react";
import { useRef } from "react";
import { format } from "date-fns";

const ANCSchedulerPrint = (props) => {
  const { patientInfo, ANCSchedulerData, selectedRow1, lmpDate } = props;
  const printRef = useRef(null);
  console.log("ANCSchedulerData123", ANCSchedulerData, printRef);
  useEffect(() => {
    ANCSchedulerData && printRef && GeneratePrint(printRef);
  }, [ANCSchedulerData]);

  const calculateRowSpan = (index) => {
    if (printRef) {
      let count = 1;
      for (let i = index + 1; i < ANCSchedulerData?.length; i++) {
        if (
          ANCSchedulerData[i].comment !== null &&
          ANCSchedulerData[i].comment !== "" &&
          ANCSchedulerData[index].comment !== null &&
          ANCSchedulerData[index].comment !== ""
        ) {
          if (ANCSchedulerData[i].comment === ANCSchedulerData[index].comment) {
            ANCSchedulerData[i].comment = null;
            count++;
          } else {
            break;
          }
        }
      }
      return count;
    }
  };

  return (
    <>
      <div ref={printRef} className="space-y-2">
        <div className="grid grid-cols-6">
          <div className="grid justify-end col-span-4 text-center">
            <h1 className="text-[15px] font-semibold">
              Inamdar Multispeciality Hospital
            </h1>
            <h1 className="text-[11px]">
              Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040
            </h1>
            <h1 className="text-[14px] font-semibold">
              Phone No : 020-66812222/44
            </h1>
          </div>
          <div className="flex justify-end col-span-2">
            <InamdarHospiLogo />
          </div>
        </div>
        <div className="text-center text-[15px] font-semibold py-[2px] border-black border-t-[1px]  border-b-[1px]">
          ANC Scheduler
        </div>
        <div className="grid grid-cols-3 border-black border-b-[1px] pb-[2px]">
          <div className="flex col-span-2">
            <span className="font-bold w-28">Patient Name&nbsp;</span>
            <span>: {patientInfo?.PatientName}</span>
          </div>
          <div className="flex">
            <span className="font-bold w-16">MR No.&nbsp;</span>
            <span>: {selectedRow1?.MRNo}</span>
          </div>
          <div className="flex col-span-2">
            <span className="font-bold w-28">Age&nbsp;</span>
            <span>: {patientInfo?.AgeYear}</span>
          </div>
          <div className="flex">
            <span className="font-bold w-16">Gender&nbsp;</span>
            <span className="flex gap-2 items-center">
              : {patientInfo?.Gender}
            </span>
          </div>
          <div className="flex col-span-2">
            <span className="font-bold w-28">Referred By&nbsp;</span>
            <span className="flex gap-2 items-center">
              : {patientInfo?.ReferedBy}
            </span>
          </div>
        </div>
        <div className="flex space-x-5">
          <div className="flex text-base items-center">
            <div className="font-semibold">LMP Date&nbsp;</div>
            <div>
              :&nbsp;
              {lmpDate}
            </div>
          </div>
          <div className="flex text-base items-center">
            <div className="font-semibold">EDD Date&nbsp;</div>
            <div>
              :&nbsp;
              {ANCSchedulerData[ANCSchedulerData?.length - 1]?.date}
            </div>
          </div>
        </div>
        <div>
          <table className="w-[100%] border border-black border-collapse text-base">
            <tr>
              <th className="border border-black border-collapse w-28">Date</th>
              <th className="border border-black border-collapse w-20">
                Weeks
              </th>
              <th className="border border-black border-collapse w-36">
                Services
              </th>
              <th className="border border-r-0 border-l-0 border-b-0 border-black border-collapse">
                Comment
              </th>
            </tr>
            {ANCSchedulerData?.map((row, index) => {
              let services = [];
              if (row?.services !== null && row?.services?.length > 0) {
                for (let obj of row?.services) {
                  services.push(obj.label);
                }
              }
              const rowspan = calculateRowSpan(index);
              console.log("rowspan123", rowspan, index % rowspan === 0);
              return (
                <tr key={index} className="text-center">
                  <td className="border border-black border-collapse">
                    {row?.date}
                  </td>
                  <td className="border border-black border-collapse">
                    {row?.week}
                  </td>
                  <td className="border border-black border-collapse">
                    {services.join(" , ")}
                  </td>
                  <td
                    rowSpan={rowspan}
                    className="border border-r-0 border-l-0 border-b-0  border-black border-collapse"
                  >
                    {row?.comment}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default ANCSchedulerPrint;
