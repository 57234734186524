import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import BlankProfile from "../../../assets/registrationAssets/blankProfile.jpeg";
import UploadProfileModal from "./UploadProfileModal";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import useFileUpload from "../../../common/components/hooks/useFileUpload";

import {
  fetchBirthDetails,
  fetchGenders,
  fetchPrefix,
} from "../../../commonServices/miscellaneousServices/MiscServices";

import { format, isAfter } from "date-fns";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import {
  fetchAdharStatus,
  fetchEmergencyNo,
  fetchPatientStatus,
  fetchRegistrationSearchDropDown,
} from "../../services/registrationServices/PatientRegistrationServices";

const BasicInfo = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const [genders, setGenders] = useState([]);
  const [enteredAge, setEnteredAge] = React.useState();
  // const [nationality, setNationality] = useState();
  const [searchData, setSearchData] = useState();
  const [prefix, setPrefix] = useState();
  const [emergencyNumber, setEmergencyNumber] = useState();

  const fileData = useFileUpload();
  const { fileName } = fileData;

  const {
    setsearchPatientId,
    setRegisterOnly,
    profilePic,
    setProfilePic,
    setProfilePicName,

    isDisabled,

    // dobState,
    // setDobState,
    searchedPatientDetails,
  } = props;

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    setError,
    resetField,
  } = useFormContext();

  let isFromAppointment = watch("fromAppointment");

  /////////////// to set check or uncheck////////////////
  let valcheck = watch("check");
  var AdharNo = watch("adharNumber");
  var FirstName = watch("firstName");
  var LastName = watch("lastName");
  var newBorn = watch("newbornBaby");
  var sex = watch("gender");
  var selected_country = watch("country");

  useEffect(() => {
    StateReg();
    getPrefix();
    getGenders();
    // getNationality();
    getEmergencyNumber();
  }, [valcheck, fileName]);

  useEffect(() => {
    if (AdharNo !== undefined && AdharNo !== null) {
      if (AdharNo.length === 1 && AdharNo !== 0) {
        setError("adharNumber");
      }
    }
  }, [AdharNo]);

  useEffect(() => {
    newBorn && sex.label === "Female"
      ? setValue("prefix", {
          id: 13,
          label: "B/o 1 Mrs.(f)",
          value: "B/o 1 Mrs.(f)",
        })
      : newBorn && sex.label === "Male"
      ? setValue("prefix", {
          id: 18,
          label: "B/o 2 Mrs(male)",
          value: "B/o 2 Mrs(male)",
        })
      : resetField("prefix");
  }, [newBorn]);

  ///////////////////////////////////////

  const StateReg = () => {
    setRegisterOnly(valcheck);
  };

  //

  const handleChange = (e) => {
    let search = e;

    search !== "" &&
      fetchRegistrationSearchDropDown(search, isFromAppointment ? 1 : 0)
        .then((response) => {
          setSearchData(response.data.result);
        })
        .catch(() => {});
  };

  const getPrefix = () => {
    fetchPrefix()
      .then((response) => response.data)
      .then((res) => {
        //
        setPrefix(res.result);
      })
      .catch(() => {});
  };

  //  DOB ==1st case [[API Calculate Age Based on DOB]]
  const getAgeDOB = (e) => {
    let dobValue = e;
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    fullDOB.length === 10 &&
      fetchBirthDetails(0, fullDOB).then((response) => {
        setValue("age", Number(response.data.result.years), {
          shouldValidate: true,
        });
        setValue(
          "ageInYears",
          response?.data?.result?.years ? response?.data?.result?.years : 0
        );
        setValue(
          "ageInMonths",
          response.data.result.months ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days ? response.data.result.days : 0
        );
      });
  };

  //DOB == 2nd case [[API Calculate DOB based on Age]]
  useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA").then((response) => {
        // setDobState(`${response.data.result.dob}T00:00:00.000Z`);
        setValue("dobDate", `${response.data.result.dob}T00:00:00.000Z`, {
          shouldValidate: true,
        });
        setValue("ageInYears", Number(enteredAge));
        setValue(
          "ageInMonths",
          response.data.result.months ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days ? response.data.result.days : 0
        );
      });
    }
  }, [enteredAge]);

  //API For Gender
  const getGenders = () => {
    fetchGenders().then((response) => {
      setGenders(response.data.result);
    });
  };

  //API For adhar status
  const getAdharStatus = (aadharNo) => {
    Number(aadharNo) === 0 && props.setDuplicateAdhar(false); ///if zero allow to submit
    fetchAdharStatus(aadharNo).then((response) => {
      if (response.data.result) {
        props.setDuplicateAdhar(true);
        warningAlert(response.data.message); //checked also @ onsubmit
      } else if (!response.data.result) {
        props.setDuplicateAdhar(false);
      }
    });
  };

  // check patient is already regisrtered or not

  const getPatientStatus = (mob) => {
    //
    fetchPatientStatus(FirstName, LastName, mob)
      .then((response) => {
        if (response.data.result) {
          props.setDuplicatePatient(true);
          warningAlert(response.data.message); //checked also @ onsubmit
        } else if (!response.data.result) {
          props.setDuplicatePatient(false);
        }
      })
      .catch((response) => {
        //
      });
  };

  //API For Nationality List
  // const getNationality = () => {
  //   fetchNationality().then((response) => {
  //     setNationality(response.data.result);
  //   });
  // };
  //emergency number
  const getEmergencyNumber = () => {
    fetchEmergencyNo().then((response) => {
      setEmergencyNumber(response.data.result);
    });
  };

  useEffect(() => {
    if (searchedPatientDetails) {
      // format(new Date(searchedPatientDetails?.dob), "yyyy-MM-dd")
      //
      fetchBirthDetails(
        0,
        format(new Date(searchedPatientDetails?.dob), "yyyy-MM-dd")
      ).then((response) => {
        // setDobState(`${response.data.result.dob}T00:00:00.000Z`);
        setValue("dobDate", new Date(searchedPatientDetails?.dob), {
          shouldValidate: true,
        });
        setValue("ageInYears", Number(response.data.result?.years));
        setValue(
          "ageInMonths",
          response.data.result.months ? response.data.result.months : 0
        );
        setValue(
          "ageInDays",
          response.data.result.days ? response.data.result.days : 0
        );
      });
    }
  }, [searchedPatientDetails]);

  useEffect(() => {
    if (
      searchedPatientDetails !== null &&
      searchedPatientDetails?.adharNo !== null
    ) {
      const isAadharNumberValid = /^(\d)\1{11}$/.test(
        searchedPatientDetails?.adharNo
      );
      isAadharNumberValid && getAdharStatus(searchedPatientDetails?.adharNo);
    }
  }, [searchedPatientDetails]);

  return (
    <div>
      <div className="grid lg:grid-cols-10 md:grid-cols-5 sm:grid-cols-3  gap-x-3 ">
        <div className=" lg:col-span-9 md:col-span-4 sm:col-span-2 grid lg:grid-cols-6 md:grid-cols-4 gap-x-3 gap-y-3 ">
          {/* //checkboxes // */}
          <div className="md:col-span-4 lg:col-span-3 flex gap-2">
            {/* // checkBox for registered // */}
            <div
              className="ml-3"
              onClick={(e) => {
                if (e.target.checked) {
                  setValue("fromAppointment", false);
                  setValue("newbornBaby", false);
                }
              }}
            >
              <CheckBoxField
                control={control}
                name="check"
                label="Register Only"
                style={{ paddingRight: 0, paddingLeft: 1 }}
              />
            </div>
            {/* // From Appointment// */}
            <div
              className=""
              onClick={(e) => {
                if (e.target.checked) {
                  setValue("check", false);
                  setValue("newbornBaby", false);
                }
              }}
            >
              <CheckBoxField
                control={control}
                name="fromAppointment"
                label="From Appointment"
                style={{ paddingRight: 0, paddingLeft: 1 }}
              />
            </div>
            {/* //Newborn Baby// */}
            <div
              className=""
              onClick={(e) => {
                if (e.target.checked) {
                  setValue("check", false);
                  setValue("fromAppointment", false);
                }
              }}
            >
              <CheckBoxField
                control={control}
                name="newbornBaby"
                label="Newborn Baby"
                style={{ paddingRight: 0, paddingLeft: 1 }}
              />
            </div>
          </div>
          {/* //Patient Search // */}
          <div className="lg:col-span-2 md:col-span-4 ">
            <div className="">
              <SearchDropdown
                name="searchPatient"
                label="Search By Patient Name/MR.No./Mobile No."
                searchIcon={true}
                dataArray={searchData}
                placeholder="Search By Patient Name/MR.No./Mobile No."
                isSearchable={true}
                isClearable={true}
                isDisabled={props.isForEdit}
                handleInputChange={(e) => handleChange(e)}
                inputRef={{
                  ...register("searchPatient", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        setsearchPatientId(e.target.value.id);
                        getEmergencyNumber();
                      } else {
                        props.handleReset();
                        props.setPrivilege("");
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>
          {/* //Select Emergency// */}
          <div className="lg:col-span-1 md:col-span-2">
            <DropdownField
              control={control}
              name="selectEmergencyNo"
              label="Emergency"
              dataArray={emergencyNumber}
              isSearchable={false}
              isDisabled={false}
              isClearable={false}
              placeholder="Emergency"
              inputRef={{
                ...register("SelectEmergencyNo", {
                  onChange: (e) => {},
                }),
              }}
            />
          </div>

          {/* //Title & First Name//// */}
          <div className="grid grid-cols-3 md:col-span-2 lg:col-span-2 gap-3">
            {/* //Title// */}
            <div className="col-span-1 ">
              <DropdownField
                control={control}
                error={errors.prefix}
                name="prefix"
                label="Prefix *"
                dataArray={prefix}
                //dataArray={dummyObj}
                isSearchable={false}
                placeholder="Prefix *"
                isClearable={false}
                inputRef={{
                  ...register("prefix", {
                    onChange: (e) => {
                      // setSelectedPrefix(e.target.value.label);
                      //
                      setValue(
                        "gender",
                        {
                          id: e.target.value.SexId,
                          label: e.target.value.gender,
                          value: e.target.value.gender,
                        },
                        { shouldValidate: true }
                      );
                    },
                  }),
                }}
              />
            </div>

            <div className="col-span-2">
              {/* //first Name// */}
              <InputField
                name="firstName"
                variant="outlined"
                label="First Name *"
                error={errors.firstName}
                control={control}
                disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
              />
            </div>
          </div>

          {/* //Middle Name// */}
          <div className="lg:col-span-2 md:col-span-2">
            <InputField
              name="middleName"
              variant="outlined"
              label="Middle Name "
              // error={errors.middleName}
              control={control}
              disabled={isDisabled}
              inputProps={{ maxLength: 100 }}
            />
          </div>

          {/* //last Name// */}
          <div className="md:col-span-2 lg:col-span-2">
            <InputField
              name="lastName"
              variant="outlined"
              label="Last Name *"
              error={errors.lastName}
              control={control}
              disabled={isDisabled}
              inputProps={{ maxLength: 100 }}
            />
          </div>

          {/* //Date of Birth and Age // */}
          <div className="lg:col-span-2 md:col-span-2">
            <div className="grid grid-cols-3 gap-3">
              {/* //Date of Birth // */}

              <div className="col-span-2 ">
                <DatePickerFieldNew
                  control={control}
                  name="dobDate"
                  label="Date of Birth"
                  value={new Date()}
                  inputRef={{
                    ...register("dobDate", {
                      onChange: (e) => {
                        isAfter(e?.target?.value, new Date(1900, 1, 1)) &&
                          getAgeDOB(e?.target?.value);
                      },
                    }),
                  }}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              {/* //Age// */}
              <div>
                <InputLimit
                  maxDigits={3}
                  name="age"
                  type="number"
                  variant="outlined"
                  label="Age *"
                  error={errors.age}
                  control={control}
                  inputRef={{
                    ...register("age", {
                      onChange: (e) => {
                        setEnteredAge(e.target.value);
                      },
                    }),
                  }}
                  onKeyDown={(e) => {
                    if (
                      e?.key === "-" ||
                      e?.key === "+" ||
                      e?.code === "ArrowUp" ||
                      e?.code === "ArrowDown" ||
                      e?.code === "NumpadDecimal"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {/* //Years Months Days // */}
          <div className=" md:col-span-2 lg:col-span-2">
            <div className="grid grid-cols-3 gap-3">
              {/* //Years// */}

              <InputField
                name="ageInYears"
                variant="outlined"
                label="Years"
                disabled={true}
              />
              {/* //Months// */}
              <InputField
                name="ageInMonths"
                variant="outlined"
                label="Months"
                disabled={true}
              />
              {/* //Days// */}
              <InputField
                name="ageInDays"
                variant="outlined"
                label="Days"
                disabled={true}
              />
            </div>
          </div>

          {/* //Gender// */}
          <div className="md:col-span-2 lg:col-span-1">
            <DropdownField
              control={control}
              error={errors.gender}
              name="gender"
              label="genders"
              dataArray={genders}
              isSearchable={false}
              isClearable={false}
              placeholder="Gender *"
            />
          </div>

          {/* //mob// */}
          <div className="md:col-span-2 lg:col-span-1">
            <InputLimit
              maxDigits={10}
              name="mobileNumber"
              variant="outlined"
              label="Mobile *"
              type={"number"}
              error={errors.mobileNumber}
              control={control}
              disabled={isDisabled}
              inputRef={{
                ...register("mobileNumber", {
                  onChange: (e) => {
                    if (e.target.value.length === 10) {
                      //check duplicate adhar number
                      if (searchedPatientDetails === null) {
                        getPatientStatus(e.target.value);
                      }
                    }
                  },
                }),
              }}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </div>

          <div className="md:col-span-2 lg:col-span-2">
            <InputLimit
              maxDigits={10}
              name="altMobileNumber"
              // error={errors.altMobileNumber}
              variant="outlined"
              label="Alternate/WhatsApp Mobile "
              control={control}
              disabled={isDisabled}
              type={"number"}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </div>

          <div className="md:col-span-2 lg:col-span-2 grid grid-cols-4 gap-3">
            <div>
              <CheckBoxField
                control={control}
                name="noEmail"
                label="No Email"
                style={{ paddingRight: 0, paddingLeft: 1 }}
              />
            </div>
            <div className="col-span-3">
              <InputField
                name="email"
                variant="outlined"
                label="Email Id"
                error={errors.email}
                control={control}
                disabled={isDisabled}
                inputProps={{ maxLength: 100 }}
                dontCapitalize={true}
              />
            </div>
          </div>

          {/* //Adhar Card Number */}
          <div className="md:col-span-2 lg:col-span-1">
            <InputLimit
              maxDigits={12}
              name="adharNumber"
              variant="outlined"
              label="Aadhar Card Number *"
              type={"number"}
              error={errors.adharNumber}
              control={control}
              disabled={isDisabled}
              inputProps={{ maxLength: 100 }}
              inputRef={{
                ...register("adharNumber", {
                  onChange: (e) => {
                    if (e.target.value.length === 12) {
                      //check duplicate adhar number
                      if (
                        searchedPatientDetails !== null &&
                        searchedPatientDetails.adharNo !== e.target.value
                      ) {
                        getAdharStatus(e.target.value);
                      } else if (searchedPatientDetails === null) {
                        getAdharStatus(e.target.value);
                      }
                    } else {
                      props.setDuplicateAdhar(false);
                    }
                  },
                }),
              }}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </div>

          <div className="md:col-span-2 lg:col-span-1">
            <InputField
              name="passportNumber"
              variant="outlined"
              label="Passport Number"
              // error={errors.passportNumber}
              control={control}
              disabled={selected_country.label === "India" ? true : false}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </div>

        {/* //Profile Pic// */}
        <div className=" row-span-2  ">
          <div className="flex justify-center -mt-2 border border-slate-300 rounded-md">
            {profilePic !== BlankProfile ? (
              <CancelOutlinedIcon
                onClick={() => {
                  setProfilePic(BlankProfile);
                }}
                sx={{
                  position: "absolute",
                  right: "16px",
                  zIndex: 10,
                  color: "#b91c1c",
                }}
              />
            ) : null}

            <Button onClick={handleOpen} disabled={isDisabled}>
              <div className="flex flex-col  ">
                <img
                  src={profilePic}
                  alt="Profile Pic"
                  className="h-28 w-32 mx-auto object-scale-down"
                />
                {profilePic === BlankProfile ? (
                  <p className="text-xs md:row-start-6 md:col-span-2 lg:row-start-5 lg:col-span-1">
                    Upload Profile
                  </p>
                ) : (
                  <p className="text-xs md:row-start-6 md:col-span-2 lg:row-start-5 lg:col-span-1">
                    Change Profile
                  </p>
                )}
              </div>
            </Button>
          </div>
        </div>
      </div>

      {/* //Patient Profile Pic Modal // */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            width: { lg: "30%", md: "50%" },
            borderRadius: "0.5rem",
          }}
        >
          <UploadProfileModal
            setProfilePic={setProfilePic}
            setOpen={setOpen}
            setProfilePicName={setProfilePicName}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default BasicInfo;
