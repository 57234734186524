import * as React from "react";
import { Box, Modal } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import ItemBatchInfoTable from "./ItemBatchInfoTable";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  // minHeight: "90%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

function ItemBatchInfoModal(props) {
  console.log(
    "ItemBatchInfoModalItemBatchInfoModal",
    props
  );
  return (
    <>
      <div>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
            <CancelPresentationIconButton
              onClick={() => {
                props.setOpen(false);
              }}
            />

            {props.selectedBatchData.length > 0 ? (
              <ItemBatchInfoTable
                dataArray={props.selectedBatchData}
                setOpen={props.setOpen}
                setBatchItemInfoObj={props.setBatchItemInfoObj}
              />
            ) : null}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ItemBatchInfoModal;
