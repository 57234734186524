import React, { useEffect, useRef } from "react";
import { CreditReturnVoucherData } from "../../services/pharmacy/inPatientReturnServices/InPatientReturnServices";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";

export default function IpdCreditReturnVoucherPrint(props) {
  const printRef = useRef(null);
  const [dataResult, setdataResult] = React.useState();

  useEffect(() => {
    CreditReturnVoucherData(props.ipdReturnId)
      .then((response) => response.data)
      .then((res) => {
        setdataResult(res.result);
      });
  }, [props]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <div>
              <h1 className=" font-semibold text-[15px] ">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[11px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" font-semibold text-[13px]">
                IPD Credit Return Voucher
              </h1>
            </div>
          </div>
          <div>
            <div className=" border-b-[1px] p-1 border-t-[1px] border-black grid grid-cols-[14%_1%_35%_14%_1%_35%] mt-2">
              <div className=" font-semibold text-[11px] ite">
                Return Bill No
              </div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">
                {dataResult?.["Return Bill No"]}
              </div>
              <div className=" font-semibold text-[11px]">Bill No.</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.["Bill No"]}</div>
              <div className=" font-semibold text-[11px]">Date</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.Date}</div>
              <div className=" font-semibold text-[11px]">Patient Name</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.PatientName}</div>
              <div className=" font-semibold text-[11px]">Doctoe Name</div>
              <div className="font-semibold text-[11px]">:</div>
              <div className="text-[11px]">{dataResult?.DoctorName}</div>
            </div>
            <div className=" grid grid-cols-[9%_20%_8%_12%_8%_20%_10%_12%] text-[11px] font-semibold border-b-[1px] border-black p-1">
              <div>Sr.No</div>
              <div>Item Name</div>
              <div>Qty</div>
              <div>Batch</div>
              <div>Expiry</div>
              <div>Company</div>
              <div>MRP</div>
              <div>Total Amt</div>
            </div>
          </div>
        </thead>
        <tbody className="w-full">
          <div>
            {dataResult?.ItemList?.map((data) => {
              return (
                <div>
                  <div className=" grid grid-cols-[9%_20%_8%_12%_8%_20%_10%_12%] text-[11px] p-1 border-b-[1px] ">
                    <div>{data?.SrNo}</div>
                    <div>{data?.["Item Name"]}</div>
                    <div>{data?.Qty}</div>
                    <div>{data?.Batch}</div>
                    <div>{data?.Expiry}</div>
                    <div>{data?.Company}</div>
                    <div>{data?.MRP}</div>
                    <div>{data?.["Total Amount"]}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </tbody>
      </table>
    </div>
  );
}
