import * as React from "react";
import { useEffect } from "react";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { getDietChartDetails } from "../../../services/diet/dietchartdetails/DietChartDetailsServices";

export default function DietChartDetails() {
  const [DietChartData, setDietChartData] = React.useState([]);
  useEffect(() => {
    callTableApi();
  }, []);

  const callTableApi = () => {
    getDietChartDetails()
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setDietChartData(res.result);
      });
  };

  return (
    <>
      <div className="w-full grid mt-14 px-6  md:rounded-md overflow-hidden">
        <div className="text-center text-black font-bold text-xl ">
          Diet Chart Details
        </div>

        {DietChartData.length > 0 ? (
          <CommonDynamicTable
            dataResult={DietChartData}
            tableClass={"rounded lg:h-96 2xl:min-h-[480px]"}
            highlightRow={false}
            removeHeaders={["Id"]}
          />
        ) : (
          <h3 className="flex justify-center my-20 font-bold text-gray-600">
            No Records Found...
          </h3>
        )}
      </div>
    </>
  );
}
