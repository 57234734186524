import { Checkbox, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import TableDropDownField from "../../../common/components/FormFields/TableDropDownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchDepartment,
  fetchEmoloyeeName,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  deleteFunctionalityTemplate,
  fetchFunctionality,
  fetchFunctionalityTemplateEmpMobiles,
  fetchFunctionalityTemplates,
  fetchTemplates,
  saveSmsInfoInfo,
} from "../../services/notificationManagementServices/NotificationManagementServices";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { DeleteOnIcon } from "../../../assets/icons/CustomIcons";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

let functionalitMenus = [];
const NotificationManagement = () => {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
    defaultValues: {
      templateSearch: null,
      Apply_To_Patient: false,
      doctor: false,
      advised_Doctor: false,
      web: false,
      isAutoSchedule: false,
      WebForEmp: false,
      searchFunctionality: "",
    },
  });
  const { control, register, getValues, setValue } = methods;

  const [departmentArr, setDepartmentArr] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [functionalityArr, setFunctionalityArr] = useState([]);
  const [empArr, setEmpArr] = useState([]);
  const [templatesSearchArr, setTemplatesSearchArr] = useState([]);
  const [templatesArr, setTemplatesArr] = useState([]);
  const [selectedFunctionalityId, setSelectedFunctionalityId] = useState(null);
  const [contactDetailsArr, setContactDetailsArr] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //

  const handleDepartmentData = (searchString) => {
    //
    searchString &&
      fetchDepartment(searchString)
        .then((response) => response.data)
        .then((res) => {
          setDepartmentArr(res.result);
        });
  };

  const getFunctionality = () => {
    fetchFunctionality()
      .then((response) => response.data)
      .then((res) => {
        setFunctionalityArr(res.result);
        functionalitMenus = res.result;
      });
  };

  const getEmoloyeeName = () => {
    fetchEmoloyeeName()
      .then((response) => response.data)
      .then((res) => {
        setEmpArr(res.result);
      });
  };

  const getTemplates = (searchString) => {
    searchString &&
      fetchTemplates(searchString)
        .then((response) => response.data)
        .then((res) => {
          setTemplatesSearchArr(res.result);
        });
  };
  const getFunctionalityTemplates = (functionalityId) => {
    functionalityId &&
      fetchFunctionalityTemplates(functionalityId)
        .then((response) => response.data)
        .then((res) => {
          setContactDetailsArr([]);
          setTemplatesArr(res.result);
        });
  };

  useEffect(() => {
    getFunctionality();
    getEmoloyeeName();
  }, []);

  const renderInputTemplate = (row, index, header) => {
    const handleCheckboxChange = (e, row, header, index) => {
      let temp = [...templatesArr];
      temp[index][header] = e;
      setTemplatesArr(temp);
    };

    console.log("header", header);
    return (
      <>
        {header !== "Template Description" ? (
          <div className="gap-3 flex m-1">
            <Checkbox
              size="small"
              onChange={(e) => {
                handleCheckboxChange(e.target.checked, row, header, index);
              }}
              checked={row[header] ? true : false}
              style={{ padding: 0 }}
            />
          </div>
        ) : (
          <Tooltip title={row?.["Template Description"]} placement="right">
            <div className="w-44 overflow-hidden">
              {row?.["Template Description"]}
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  useEffect(() => {
    let temp = [...contactDetailsArr];
    for (let t = 0; t < temp?.length; t++) {
      setValue(`employee${t}`, {
        id: temp[t]?.EmployeeId,
        label: temp[t]?.EmployeeName,
        value: temp[t]?.EmployeeName,
      });
    }
  }, [contactDetailsArr]);

  const renderInputUser = (row, index, header) => {
    let temp = [...contactDetailsArr];
    const handleCheckboxChange = (e, row, header, index) => {
      temp[index][header] = e;
      setContactDetailsArr(temp);
    };

    const hanndleInputChange = (e, row, header, index) => {
      temp[index][header] = e.target.value;
      setContactDetailsArr(temp);
    };
    return (
      <>
        {(header === "Mobile No" || header === "EmailId") && (
          <div className="">
            <input
              className="border border-gray-400 rounded px-1 focus:outline-none  focus:border-sky-600"
              // placeholder="Mob No."
              value={row[header]}
              onChange={(e) => hanndleInputChange(e, row, header, index)}
            ></input>
          </div>
        )}

        {header === "EmployeeName" && (
          <div className="w-96">
            <TableDropDownField
              control={control}
              // error={errors.pharmacyStore}
              name={`employee${index}`}
              searchIcon={true}
              dataArray={empArr}
              isClearable={true}
              // handleInputChange={handleServiceData}
              // defaultValue={{
              //   id: row.EmployeeId,
              //   label: row.EmployeeName,
              //   value: row.EmployeeName,
              // }}
            />
          </div>
        )}

        {(header === "Web Applicable" ||
          header === "Email Applicable" ||
          header === "Whatsapp Applicable") && (
          <div className="gap-3 flex m-1">
            <Checkbox
              size="small"
              onChange={(e) => {
                handleCheckboxChange(e.target.checked, row, header, index);
              }}
              checked={row[header] ? true : false}
              style={{ padding: 0 }}
            />
          </div>
        )}
      </>
    );
  };

  const renderActionsTemplate = (row, index) => {
    const handleCheckboxChange = (checked, index, row) => {
      let updatedTemp = templatesArr.map((template, i) => ({
        ...template,
        isChecked: i === index ? checked : false,
      }));
      setTemplatesArr(updatedTemp);

      setContactDetailsArr([]);

      fetchFunctionalityTemplateEmpMobiles(
        selectedFunctionalityId,
        row.SMSTemplateId
      )
        .then((response) => response.data)
        .then((res) => {
          setContactDetailsArr(res.result);
        });
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <Checkbox
            size="small"
            onChange={(e) => {
              handleCheckboxChange(e.target.checked, index, row);
            }}
            checked={!!row.isChecked ? true : false}
            style={{ padding: 0 }}
          />

          <DeleteOnIcon
            title={"Delete Template"}
            onClick={() => {
              if (row.Id > 0) {
                setOpenConfirmationModal(true);
                setIsDelete(true);
                setSelectedTemplate(row);
              } else {
                let newTemplateData = [...templatesArr];
                newTemplateData.splice(index, 1);
                setTemplatesArr(newTemplateData);
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderActionsUser = (row, index) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          <DeleteOnIcon
            title={"Delete Mobile Number"}
            onClick={() => {
              if (row.Id > 0) {
                setOpenConfirmationModal(true);
                setIsDelete(true);
                setSelectedTemplate(row);
              } else {
                let newTemplateData = [...contactDetailsArr];
                newTemplateData.splice(index, 1);
                setContactDetailsArr(newTemplateData);
              }
            }}
          />
        </div>
      </>
    );
  };

  const handleSortFunctionality = (searchString) => {
    let temp = [...functionalitMenus];
    let filteredData =
      searchString !== ""
        ? temp.filter(
            (item) =>
              !!item.MenuName &&
              item?.MenuName?.toLowerCase().includes(
                searchString?.toLowerCase()
              )
          )
        : temp;

    setFunctionalityArr(filteredData);
  };

  const handleAddTemlates = (data) => {
    let newObj = {
      "Template Name": data.templateSearch.label,
      "Template Description": data.templateSearch.SMSTemplate,
      "Auto Schedule": data.isAutoSchedule,
      "Doctor Applicable": data.doctor,
      FunctionalityId: selectedFunctionalityId,
      Id: 0,
      "Patient Applicable": data.Apply_To_Patient,
      "Refer Doctor Applicable": data.advised_Doctor,
      SMSTemplateId: data.templateSearch.id,
      "Web Applicable": data.web || false,
      "Email Applicable": data.email || false,
      "Whatsapp Applicable": data.email || false,
    };

    if (!!data.templateSearch) {
      setTemplatesArr((prev) => [...prev, newObj]);
      methods.reset();
    }
  };

  const handleAddContactDtls = () => {
    let mob = getValues("contactNumber");
    let Emaill = getValues("emaillForEmp");
    let emp = getValues("employee");
    let WebForEmp = getValues("webForEmp");
    let WhatsappForEmp = getValues("whatsappForEmp");
    let EmailForEmp = getValues("emailForEmp");

    let newObj = {
      Id: 0,
      "Mobile No": mob,
      FunctionalityId: selectedFunctionalityId,
      EmployeeId: emp?.id,
      EmployeeName: emp?.label,
      "Web Applicable": WebForEmp || false,
      "Email Applicable": EmailForEmp || false,
      EmailId: Emaill || "",
      "Whatsapp Applicable": WhatsappForEmp || false,
    };

    if (!!mob) {
      setContactDetailsArr((prev) => [...prev, newObj]);
      setValue("contactNumber", "");
      setValue("employee", null);
      setValue("webForEmp", false);
      setValue("whatsappForEmp", false);
      setValue("emailForEmp", false);
    
    }
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
    setIsDelete(false);
  };
  const handleFormSubmit = () => {
    let smsEmployeeMobileDtlsDtoList = contactDetailsArr.map((item) => ({
      employeeId: item.EmployeeId,
      mobileNo: item["Mobile No"],
      isWebApplicable: item?.["Web Applicable"] || false,
      isWhatsappApplicable: item?.["Whatsapp Applicable"] || false,
      emailId: item?.["EmailId"],
      isEmailApplicable: item?.["Email Applicable"] || false,
    }));

    let smsTemplatesDtoList = templatesArr.map((item) => ({
      id: item.Id > 0 ? item.Id : null, //not want 0
      isAutoSchedule: item["Auto Schedule"] || false,
      isDoctorApplicable: item["Doctor Applicable"] || false,
      // isEmployeeApplicable: item["Auto Schedule"]||false,
      isPatientApplicable: item["Patient Applicable"] || false,
      referDoctorApplicable: item["Refer Doctor Applicable"] || false,
      smsEmployeeMobileDtlsDtoList: item.isChecked
        ? smsEmployeeMobileDtlsDtoList
        : [],
      smsTemplateId: item.SMSTemplateId,
      isWebApplicable: item?.["Web Applicable"] || false,
      isWhatsappApplicable: item?.["Whatsapp Applicable"] || false,
      emailId: item?.["EmailId"],
      isEmailApplicable: item?.["Email Applicable"] || false,
    }));

    let finalObj = {
      functionalityId: selectedFunctionalityId,
      smsTemplatesDtoList: smsTemplatesDtoList,
    };
    setOpenConfirmationModal(false);

    setOpenBackdrop(true);
    saveSmsInfoInfo(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setContactDetailsArr([]);
          setTemplatesArr([]);
          setSelectedFunctionalityId(null);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  const handleDelete = () => {
    setOpenConfirmationModal(false);

    setOpenBackdrop(true);
    deleteFunctionalityTemplate(selectedTemplate.Id)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          getFunctionalityTemplates(selectedFunctionalityId);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <>
      <div className="mt-12 mb-2">
        <div className="text-center text-black font-bold text-xl col-span-9 mb-2">
          Notification Management
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="md:col-span-4 lg:col-span-2">
            <div className="py-1">
              <InputField
                name="searchFunctionality"
                label="Search Functionality"
                variant="outlined"
                control={control}
                inputProps={{ maxLength: 50 }}
                inputRef={{
                  ...register("searchFunctionality", {
                    onChange: (e) => {
                      handleSortFunctionality(e.target.value);
                    },
                  }),
                }}
              />
            </div>

            <div className="border rounded border-gray-200 overflow-y-auto lg:h-[570px] xl-h-[700px]">
              {functionalityArr.map((item) => (
                <div
                  className={`p-1 gap-1 m-1 px-2 rounded  bg-white text-slate-600 hover:bg-slate-100 ${
                    item?.MenuId === selectedFunctionalityId
                      ? "bg-slate-300 hover:bg-slate-300"
                      : ""
                  }`}
                  onClick={() => {
                    getFunctionalityTemplates(item?.MenuId);
                    setSelectedFunctionalityId(item?.MenuId);
                    setContactDetailsArr([]);
                  }}
                >
                  {item.MenuName}
                </div>
              ))}
            </div>
          </div>
          <fieldset
            className="md:col-span-8 lg:col-span-10"
            disabled={selectedFunctionalityId !== null ? false : true}
            onClick={() => {
              selectedFunctionalityId === null &&
                warningAlert("Please select a functionality");
            }}
          >
            <div>
              {/* <div className="font-semibold my-3">Templates </div> */}
              <form>
                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="md:col-span-12 lg:col-span-3">
                    <SearchDropdown
                      control={control}
                      placeholder="Template Search"
                      dataArray={templatesSearchArr}
                      name="templateSearch"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={getTemplates}
                      inputRef={{
                        ...register("templateSearch", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              // setSelectedPatient(e.target.value);
                            } else {
                              // setSelectedPatient("");
                              // methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="md:col-span-12 lg:col-span-8 flex gap-1 items-center ml-2">
                    <div className="flex gap-0 flex-wrap">
                      <div>
                        <CheckBoxField
                          control={control}
                          name="Apply_To_Patient"
                          label="Apply To Patient "
                        />
                      </div>
                      <div>
                        <CheckBoxField
                          control={control}
                          name="doctor"
                          label="Doctor "
                        />
                      </div>
                      <div>
                        <CheckBoxField
                          control={control}
                          name="advised_Doctor"
                          label="Advised Doctor "
                        />
                      </div>

                      <div>
                        <CheckBoxField
                          control={control}
                          name="isAutoSchedule"
                          label="Auto Schedule "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 md:col-span-11 lg:col-span-11 items-center flex-wrap">
                    <div className=" font-semibold">Send To : </div>
                    <div>
                      <CheckBoxField
                        control={control}
                        name="web"
                        label="Web "
                      />
                    </div>
                    <div>
                      <CheckBoxField
                        control={control}
                        name="email"
                        label="Email "
                      />
                    </div>
                    <div>
                      <CheckBoxField
                        control={control}
                        name="whatsApp"
                        label="WhatsApp "
                      />
                    </div>
                  </div>
                  <div className=" flex justify-end">
                    <CommonButton
                      onClick={methods.handleSubmit(handleAddTemlates)}
                      className="bg-customBlue text-white"
                      label={"Add"}
                    />
                  </div>
                </div>
              </form>
              {templatesArr.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={templatesArr}
                  tableClass="rounded lg:h-52 md:h-44"
                  removeHeaders={[
                    "Id",
                    "FunctionalityId",
                    "SMSTemplateId",
                    "isChecked",
                    "EmailId",
                  ]}
                  renderInput={renderInputTemplate}
                  renderActions={renderActionsTemplate}
                  highlightRow={false}
                  editableColumns={[
                    "Auto Schedule",
                    "Doctor Applicable",
                    "Patient Applicable",
                    "Refer Doctor Applicable",
                    "Template Description",
                    "Web Applicable",
                    "Email Applicable",
                    "Whatsapp Applicable",
                  ]}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>

            <hr className="border my-4 md:col-span-3 lg:col-span-7 divide-x-8 bg-slate-300 border-slate-300" />
            {templatesArr.some((item) => item?.isChecked) && (
              <div className="">
                <div className="grid grid-cols-8 gap-3">
                  <div className="md:col-span-2 lg:col-span-2">
                    <InputField
                      name="contactNumber"
                      label="Template Contact Number"
                      variant="outlined"
                      control={control}
                      type={"number"}
                      // inputProps={{ maxLength: 10 }}
                      maxLength={10}
                      inputRef={{
                        ...register("contactNumber", {
                          onChange: (e) => {
                            // handleSortFunctionality(e.target.value);
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="md:col-span-2 lg:col-span-2">
                    <DropdownField
                      control={control}
                      name="employee"
                      placeholder="Employee"
                      searchIcon={true}
                      dataArray={empArr}
                      isClearable={true}
                      // handleInputChange={handleServiceData}
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      control={control}
                      name="webForEmp"
                      label="Web "
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      control={control}
                      name="emailForEmp"
                      label="Email "
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      control={control}
                      name="whatsappForEmp"
                      label="WhatsApp "
                    />
                  </div>
                  <div>
                    <CommonButton
                      onClick={() => {
                        handleAddContactDtls();
                      }}
                      className="bg-customBlue text-white"
                      label={"Add"}
                    />
                  </div>
                </div>
                {contactDetailsArr.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={contactDetailsArr}
                    tableClass="rounded lg:h-52 md:h-44"
                    renderActions={renderActionsUser}
                    removeHeaders={["Id", "EmployeeId", "FunctionalityId"]}
                    highlightRow={false}
                    renderInput={renderInputUser}
                    editableColumns={[
                      "Mobile No",
                      "EmployeeName",
                      "Web Applicable",
                      "Email Applicable",
                      "Whatsapp Applicable",
                      "EmailId",
                    ]}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>
            )}

            <div className="col-span-4 grid justify-end">
              {/* {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <> */}

              <div className="flex justify-end gap-2">
                <CommonButton
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    setContactDetailsArr([]);
                    setTemplatesArr([]);
                    setSelectedFunctionalityId(null);
                    methods.reset();
                    setValue("contactNumber", "");
                    setValue("employee", null);
                  }}
                />
                <CommonButton
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={() => onSubmit()}
                />
              </div>
              {/* </>
              ) : null}
            </>
          ))} */}
            </div>
          </fieldset>
        </div>
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() =>
          isDelete ? handleDelete() : handleFormSubmit()
        }
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default NotificationManagement;
