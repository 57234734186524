import { Box, Modal, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import * as React from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { doctorDropdown } from "../../../opd/services/patientappointmentservices/PatientVisitListServices";

import { useLocation } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputField from "../../../common/components/FormFields/InputField";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getDischargePatientInfoById,
  getDischargeType,
  ipdDischargeSearch,
  postPatientDischarge,
} from "../../services/discharge/DischargeServices";
import ReasonForDischarge from "./ReasonForDischarge";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",

  // overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};

export default function DischargeModal(props) {
  const schema = yup.object().shape({
    remark: yup.string(),
    dischargeDoctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),
    dischargeType: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),
  });

  const defaultValues = {
    remark: "",
    dischargeDoctor: null,
    dischargeType: null,
    opdbillingSearch: null,
    fromDateCalender: " ",
    fromTime: " ",
  };
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {}, [errors]);
  const [searchOptions, setsearchOptions] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState("");
  const [doctorList, setDoctorList] = React.useState([]);
  const [dischargeType, setDischargeType] = React.useState([]);

  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [fromTime, setFromTime] = React.useState(new Date());
  const [postDischarge, setPostDischarge] = React.useState("");
  const [privilege, setPrivilege] = React.useState(null);
  //other variables

  let dischargeDoctorVal = watch("dischargeDoctor");
  let dischargeDestinationVal = watch("dischargeDestination");
  let dischargeTypeVal = watch("dischargeType");

  let fromDate = watch("fromDate");
  let toDate = watch("toDate");
  let location = useLocation();

  //useEffects
  useEffect(() => {
    getDoctroDropdown();
    getDischargeTypeDropdown();
    // getDischargeDestinationDropdown();
  }, [errors]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirmation = () => setConfirmationOpen(true);
  const handleCloseConfirmation = () => setConfirmationOpen(false);
  //search patient
  const handleChange = (searchString) => {
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString))
      ipdDischargeSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setsearchOptions(res.result);
        });
  };
  //get patient informtion by id
  function getPatientInfo(searchPatientById) {
    getDischargePatientInfoById(searchPatientById)
      .then((response) => response.data)
      .then((res) => {
        setSelectedPatient(res.result);
      });
  }

  // DischargeDoctroDropdown
  function getDoctroDropdown(searchDoctorString) {
    if (searchDoctorString !== "") {
      doctorDropdown(searchDoctorString)
        .then((response) => response.data)
        .then((res) => {
          setDoctorList(res.result);
        });
    }
  }
  // DischargeTypeDropdown api
  function getDischargeTypeDropdown() {
    getDischargeType()
      .then((response) => response.data)
      .then((res) => {
        setDischargeType(res.result);
      });
  }

  useEffect(() => {
    if (props.admissionId != null) {
      getDischargePatientInfoById(props.admissionId, 1)
        .then((response) => response.data)
        .then((res) => {
          setSelectedPatient(res.result);
        });
    }
  }, [props.admissionId]);
  //save discharge
  function onSubmitDataHandler(data) {
    let useInfoObj = JSON.parse(localStorage.getItem("userInfo"));

    handleOpenConfirmation();
    let dischargeSaveObj = {
      addedBy: useInfoObj.userId,
      admissionId: selectedPatient.AdmissionId
        ? selectedPatient.AdmissionId
        : selectedPatient.AdmissionId,
      bedId: selectedPatient.BedId ? selectedPatient.BedId : null,
      doctorId: dischargeDoctorVal ? dischargeDoctorVal.id : null,
      genderId: selectedPatient.genderId ? selectedPatient.genderId : null,
      remark: data.remark,
      menuId: props?.menuId,
      privilege: props?.privilege ? props?.privilege : privilege,
      allowGotoSlip: true,

      typeId: dischargeTypeVal ? dischargeTypeVal.id : null,
    };
    console.log("dischargeSaveObj", dischargeSaveObj);

    setPostDischarge(dischargeSaveObj);
  }

  function saveDischargeByConfirmation(dischargeSaveObj) {
    props.setOpenBackdrop(true);
    handleCloseConfirmation();

    if (
      selectedPatient.isNursingClearanceDone === 1 &&
      selectedPatient.isPharmacyClearanceDone === 1
    ) {
      postPatientDischarge(dischargeSaveObj)
        .then((response) => response.data)
        .then((res) => {
          props.setOpenBackdrop(false);
          setConfirmationOpen(false);

          successAlert(res.message);
          handleClose();
          if (props.from === "IPDBilling" && props.admissionId != null) {
            // Discharged
            let object = props.patientInfoList;
            object.Discharged = true;
            props.setPatientInfoList(object);
          } else {
            props.populateTable();
          }

          props.handleClose();
          reset(defaultValues);
          setDischargeType([]);
          setDoctorList([]);
          reset(defaultValues);
          setSelectedPatient([]);
          props?.setMenuId(null);
        })
        .catch((res) => {
          // props.setOpenBackdrop(false);
          props.setOpenBackdrop(false);
          setConfirmationOpen(false);
          errorAlert(res?.response?.data?.message);
        });
    } else if (
      selectedPatient.isNursingClearanceDone === 0 &&
      selectedPatient.isPharmacyClearanceDone === 1
    ) {
      errorAlert("Nursing Clearance is Pending");
      props.setOpenBackdrop(false);
    } else if (
      selectedPatient.isNursingClearanceDone === 1 &&
      selectedPatient.isPharmacyClearanceDone === 0
    ) {
      errorAlert("Pharmacy Clearance is Pending");
      props.setOpenBackdrop(false);
    } else {
      errorAlert("Nursing & Pharmacy Clearance is pending");
      props.setOpenBackdrop(false);
    }
    // } else {
    //   errorAlert("Patient already Discharge");
    // }
  }
  let fromDateCalenderVal = watch("fromDateCalender");

  return (
    <>
      <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4 p-2">
        <Modal
          open={props.open ? props.open : props.openDischarge}
          onClose={() => {
            // props.handleClose();

            reset(defaultValues);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  if (props.from === "IPDBilling") {
                    props.handleClose();
                    setDischargeType([]);
                    props.handleClose();
                    reset(defaultValues);
                    //setSelectedPatient([]);
                    setDoctorList([]);
                    setFromTime(new Date());

                    setSelectedFromDate(new Date());
                  } else {
                    setDischargeType([]);
                    props.handleClose();
                    reset(defaultValues);
                    //setSelectedPatient([]);
                    setDoctorList([]);
                    setSelectedFromDate(new Date());
                    setSelectedPatient([]);
                    setFromTime(new Date());
                  }
                }}
              />
            </div>
            <div className="text-center text-black font-bold text-xl py-4">
              Discharge
            </div>
            <div className="row">
              {props.openDischarge === true ? null : (
                <div className="lg:w-6/12 md:w-7/12">
                  <SearchDropdown
                    control={control}
                    name="opdbillingSearch"
                    dataArray={searchOptions}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Search By Patient Name / MR.No./ Mobile No."
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("opdbillingSearch", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedPatient(e.target.value);
                            getPatientInfo(e.target.value.id);
                            setDischargeType([]);
                            setDoctorList([]);
                          } else {
                            setSelectedPatient("");
                            setDischargeType([]);
                            setDoctorList([]);
                            reset(defaultValues);
                          }
                        },
                      }),
                    }}
                  />
                </div>
              )}
              <fieldset className="border border-gray-300 text-left lg:mx-auto lg:px-2 md:px-2 md:ml-0 md:mr-0 py- rounded lg:mt-2 lg:m-2 ">
                <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Patient Discharge
                </legend>

                <div className="p-2 grid w-full">
                  <div className="">
                    <fieldset
                      className="border border-gray-300 col-span-3 w-full lg:py-0
           text-left lg:px-2 md:p-2 rounded bg-gray-100"
                    >
                      <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1 lg:px-2 md:px-2">
                        Patient Information
                      </legend>

                      <div
                        className="md:grid md:grid-cols-[20%_0.5%_29.5%_22%_0.5%_27.5%] md:gap-1 lg:grid-cols-[12%_1%_20%_12%_1%_20%_12%_1%_20%] 
                      2xl:grid-cols-[8%_1%_16%_8%_1%_16%_8%_1%_16%_8%_1%_16%] whitespace-nowrap text-sm rounded  px-4 "
                      >
                        <div className="font-semibold ">Patient Name</div>
                        <div>:</div>
                        <div>
                          {selectedPatient && selectedPatient.patientName}
                        </div>
                        <div className="font-semibold "> MR No.</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.mrNo}</div>
                        <div className="font-semibold ">Age</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.AgeYear}</div>
                        <div className="font-semibold ">Gender</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.gender}</div>
                        <div className="font-semibold ">D.O.B</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.dob}</div>
                        <div className="font-semibold ">Doctor</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.doctor}</div>
                        <div className="font-semibold ">Bed Category</div>
                        <div>:</div>
                        <div>
                          {selectedPatient && selectedPatient.BedCategory}
                        </div>
                        <div className="font-semibold ">Admission Date</div>
                        <div>:</div>
                        <div>
                          {selectedPatient && selectedPatient.admissionDate}
                        </div>
                        <div className="font-semibold ">Ward</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.Ward}</div>
                        <div className="font-semibold ">IPD No</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.IpdNo}</div>
                        <div className="font-semibold ">Unit</div>
                        <div>:</div>
                        <div>{selectedPatient && selectedPatient.unit}</div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="grid lg:grid-cols-1 gap-2 2xl:grid-cols-1 pt-2 md:grid md:grid-cols-1">
                    <form onSubmit={handleSubmit(onSubmitDataHandler)}>
                      <div className="grid">
                        {(selectedPatient && selectedPatient !== null) ||
                        (selectedPatient && selectedPatient !== "") ? (
                          <>
                            <fieldset className="border border-gray-300 text-left lg:mx-auto lg:px-2 md:px-2 md:ml-0 md:mr-0 py- rounded lg:mt-2 lg:m-2 ">
                              <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                                Discharge Details
                              </legend>

                              <div className="md:grid md:grid-cols-2 2xl:grid 2xl:grid-cols-4 lg:grid lg:grid-cols-5 gap-2 items-center py-2">
                                <div className=" lg:col-span-1 2xl:col-span-1">
                                  <SearchDropdown
                                    name="dischargeDoctor"
                                    control={control}
                                    error={errors.dischargeDoctor}
                                    label="Discharge Doctor"
                                    placeholder="Discharge Doctor"
                                    isSearchable={true}
                                    searchIcon={true}
                                    isClearable={true}
                                    dataArray={doctorList}
                                    // dataArray={dropdown}
                                    handleInputChange={getDoctroDropdown}
                                    inputRef={{
                                      ...register("dischargeDoctor", {
                                        onChange: (e) => {},
                                      }),
                                    }}
                                  />
                                </div>
                                <div className="">
                                  <DropdownField
                                    name="dischargeType"
                                    control={control}
                                    label="Discharge Type"
                                    placeholder="Discharge Type"
                                    isSearchable={false}
                                    dataArray={dischargeType}
                                    // dataArray={dropdown}
                                    error={errors.dischargeType}
                                  />
                                </div>

                                <div className="grid grid-cols-2 lg:col-span-2 gap-2">
                                  <div className="">
                                    <Controller
                                      control={control}
                                      defaultValue={new Date()}
                                      rules={{
                                        required: true,
                                      }}
                                      render={({ field }) => (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DesktopDatePicker
                                            disableFuture
                                            label="To Date"
                                            value={selectedFromDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                              setSelectedFromDate(newValue);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                className="bg-white"
                                                name="fromDateCalender"
                                                fullWidth
                                                size="small"
                                                {...params}
                                                sx={{
                                                  svg: {
                                                    color: "#1e3a8a",
                                                    height: 22,
                                                    width: "100%",
                                                    marginRight: "16px",
                                                  },
                                                  backgroundColor: "white",
                                                  overflow: "visible",

                                                  "& .MuiOutlinedInput-root": {
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        // border: 0,
                                                        fontSize: 14,
                                                        height: "18px",
                                                        width: "100%",

                                                        borderColor: "#0B83A5",
                                                        overflow: "hidden",
                                                      },
                                                    "& .MuiFormLabel-root": {
                                                      fontSize: "14px",
                                                    },
                                                  },
                                                }}
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      )}
                                      name="fromDateCalender"
                                    />
                                  </div>
                                  <div className="">
                                    <Controller
                                      control={control}
                                      defaultValue={new Date()}
                                      rules={{
                                        required: true,
                                      }}
                                      render={({ field }) => (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DesktopTimePicker
                                            label="From Time"
                                            name="fromTime"
                                            value={fromTime}
                                            onChange={(newValue) => {
                                              setFromTime(newValue);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                className="bg-white"
                                                fullWidth
                                                size="small"
                                                {...params}
                                                sx={{
                                                  svg: {
                                                    color: "#1e3a8a",
                                                    height: 22,
                                                    width: "100%",
                                                    marginRight: "16px",
                                                  },
                                                  backgroundColor: "white",
                                                  overflow: "visible",

                                                  "& .MuiOutlinedInput-root": {
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        // border: 0,
                                                        fontSize: 14,
                                                        height: "18px",
                                                        width: "100%",

                                                        borderColor: "#0B83A5",
                                                        overflow: "hidden",
                                                      },
                                                    "& .MuiFormLabel-root": {
                                                      fontSize: "14px",
                                                    },
                                                  },
                                                }}
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      )}
                                      name="dischargeTime"
                                    />
                                  </div>
                                </div>

                                <div className="">
                                  <InputField
                                    name="remark"
                                    variant="outlined"
                                    label="Remarks"
                                    control={control}
                                    error={errors.remark}
                                  />
                                </div>
                              </div>
                            </fieldset>
                          </>
                        ) : null}
                        <div className="flex  gap-2 pt-2 justify-end ">
                          <div className="flex gap-2">
                            <CommonButton
                              label="Cancel"
                              className="border border-customRed text-customRed"
                              onClick={() => {
                                if (props.from === "IPDBilling") {
                                  props.handleClose();
                                  setDischargeType([]);
                                  setDoctorList([]);
                                  props.handleClose();
                                  reset(defaultValues);
                                  setSelectedFromDate(new Date());
                                  setFromTime(new Date());

                                  //setSelectedPatient([]);
                                } else {
                                  setDischargeType([]);
                                  props.handleClose();
                                  reset(defaultValues);
                                  setSelectedPatient([]);
                                  setDoctorList([]);
                                  setSelectedFromDate(new Date());
                                  setFromTime(new Date());
                                }
                              }}
                            />

                            {selectedPatient?.Discharged === false ? (
                              <CommonButton
                                label="Save"
                                type="submit"
                                className="bg-customGreen text-white"
                                onClick={() => {
                                  setPrivilege(props?.menuIdVal?.action);
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </fieldset>
              <CommonBackDrop openBackdrop={props.openBackdrop} />
            </div>
          </Box>
        </Modal>
        {open ? (
          <ReasonForDischarge
            open={open}
            setOpen={setOpen}
            handleClose={props.handleClose}
            selectedPatient={selectedPatient}
            populateTable={props.populateTable}
          />
        ) : null}
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={() => {
            saveDischargeByConfirmation(postDischarge);
            handleCloseConfirmation();
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Do You Want To Discharge Patient?"
          confirmationButtonMsg="Discharge"
        />
      </div>
    </>
  );
}

