import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";

import { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

import {
  fetchDocuments,
  fetchPatchData,
} from "../../../services/admissionServices/AdmissionServices";

import { EyeOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import DocumentsViewModal from "../commonModal/DocumentsViewModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function ViewPatientDetailsModal(props) {
  const { open, handleClose, selectedRow } = props;

  const [patientData, setPatientData] = useState();
  const [pathForDocView, setPathForDocView] = useState();

  //
  const [patientdocs, setPatientdocs] = useState([]);
  const [relativedocs, setRelativedocs] = useState([]);

  const [openDocumentsViewModal, setOpenDocumentsViewModal] = React.useState(
    false
  );
  const handleOpenDocumentsViewModal = () => setOpenDocumentsViewModal(true);
  const handleCloseDocumentsViewModal = () => setOpenDocumentsViewModal(false);
  //

  useEffect(() => {
    getSearchedData();
    getOldDocuments();
  }, []);

  ///patch Data
  const getSearchedData = () => {
    fetchPatchData(selectedRow.PatientId, 0)
      .then((response) => response.data)
      .then((res) => {
        setPatientData(res.result);
      })
      .catch(() => {});
  };

  const getOldDocuments = () => {
    fetchDocuments(selectedRow?.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setPatientdocs(res.result.fetchPatientDocDtoList);
        setRelativedocs(res.result.fetchRelDocDtoList);
      });
  };

  const renderInputPatient = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.documentPath);
      handleOpenDocumentsViewModal(true);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          {row.documentPath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };
  const renderInputRelative = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.documentPath);
      handleOpenDocumentsViewModal(true);
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          {row.documentPath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <form>
            <div>
              <div className="text-black font-bold text-lg pb-1">
                Admitted Patient Information
              </div>
              <div className="grid w-full ">
                {/* Populate Patient Info Fields */}
                <div className="grid border border-gray-300 bg-gray-100 px-3 rounded-md mt-2">
                  <div className="grid grid-cols-2  gap-2 py-2">
                    <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                      {/* Patient Name , UHID */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-1">
                          <span className="text-sm">Patient Name </span>
                          <span className=""> :</span>
                        </h1>

                        <h1 className="font-normal">
                          {patientData ? patientData.patientName : null}
                        </h1>
                      </div>
                      {/* Gender */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                          <span className="text-sm">Gender</span>
                          <span className="lg:pl-7 md:pl-6">:</span>
                        </h1>

                        <h1 className="font-normal">
                          {patientData ? patientData.gender : null}
                        </h1>
                      </div>
                    </div>

                    {/* MRNo */}
                    <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">MRNo</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="font-normal">
                          {patientData ? patientData.MRNo : null}
                        </h1>
                      </div>
                      {/* age */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">Age</span>
                          <span className="md:pl-4">:</span>
                        </h1>
                        <h1 className="font-normal">
                          {patientData ? patientData.Age : null}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ///// Personal Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Personal Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Mobile Number</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.mobileNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">
                        Alternate/WhatsApp Number
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.AltMobNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Email</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.email}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Date Of Birth</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.DOB}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Aadhar Number</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.adharNo}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Address Details*/}
              <div className="  border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900  text-base font-bold px-2 py-1">
                  Address Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">
                        House No./Flat No.
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.FlatHouseNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Society/House</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.Society}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Country</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.country}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40"> State</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.State}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">City</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.city}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Area</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.area}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Pincode</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.pincode}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Patient Office Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Patient Office Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Occupation</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.Occupation}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Office Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.officeName}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Address</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.officeAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Represerntative Details*/}
              <div className="  border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Represerntative Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.KinName}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Mobile Number</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.KinMobileNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Relation with Patient
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.Relation}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Address</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.KinAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Other Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Other
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Preffered Tag</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.preferedClass}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Admission Tag Color
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.AdmissionTag}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Remark Of account
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.RemarkOfAccount}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Remark Of Bill
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.RemarkForBill}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Admission Details*/}
              <div className="  border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Admission Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Department</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.Department}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Doctor</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.doctorName}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Patient Source
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.PatientSource}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Patient Category
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.PatientCategory}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Tariff</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.Tariff}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              {/* ///// Staff Details*/}
              {(patientData && patientData.PatientCategory === "Staff") ||
              (patientData &&
                patientData.PatientCategory === "Staff Dependant") ? (
                <div className=" border border-gray-300 px-3 rounded-md mt-1">
                  <div className="text-blue-900 text-base font-bold px-2 py-1">
                    Staff Details
                  </div>
                  <fieldset className="">
                    <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                        <span className=" font-semibold w-40">Staff Name</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {/* {patientData && patientData.MLCNo} */}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">Code</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {/* {patientData && patientData.ReportingDate + */}"
                            " +{/* patientData && patientData.ReportingTime} */}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">Limit</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {/* {patientData && patientData.AuthorityName} */}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">Used</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {/* {patientData && patientData.AuthorityName} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              ) : null}

              {/* ///// Company Details*/}
              {patientData && patientData.PatientCategory !== "Self" ? (
                <div className="  border border-gray-300 px-3 rounded-md mt-1">
                  <div className="text-blue-900 text-base font-bold px-2 py-1">
                    Company Details
                  </div>
                  <fieldset className="">
                    <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                        <span className=" font-semibold w-40">Company</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.Company}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">
                          Company Assistant
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.AssistantCoompany}
                          </span>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              ) : null}

              {/* ///// Doctor Details*/}
              <div className="  border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Doctor Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Refer Entity</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.ReferEntity}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Referance Doctor
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.ReferDoctor}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Internal Adviced Doctor
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.internalRefDoc}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Doctor 1</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.doctorOne}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Doctor 2</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.doctorTwo}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Bed Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Bed Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Bed Category</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.bedCategory}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Charges Per Bed Category
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.ChargesAsPerBedCategory}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Ward</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.ward}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Bed Number</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patientData && patientData.Bed}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// MLC Details*/}
              {patientData && patientData.MedicoLegalCase !== false ? (
                <div className=" border border-gray-300 px-3 rounded-md mt-1">
                  <div className="text-blue-900 text-base font-bold px-2 py-1">
                    MLC Details
                  </div>
                  <fieldset className="">
                    <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                        <span className=" font-semibold w-40">MLC Number</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.HospitalMLCNo}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">
                          Reporting Date And Time
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData &&
                              patientData.ReportingDate + " " + patientData &&
                              patientData.ReportingTime}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">
                          Name Of Authority
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.AuthorityName}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">
                          Authority Buckle Number
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.AuthorutyBuckleNo}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">
                          Police Station
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.PoliceStation}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                        <span className=" font-semibold w-40">Mlc Type</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {patientData && patientData.MLCType}
                          </span>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              ) : null}

              {patientData && patientData.country !== "India" ? (
                <>
                  {/* ///// Passport Details*/}
                  <div className="  border border-gray-300 px-3 rounded-md mt-1">
                    <div className="text-blue-900 text-base font-bold px-2 py-1">
                      Passport Details
                    </div>
                    <fieldset className="">
                      <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                          <span className=" font-semibold w-40">
                            Passport Number
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.PassportNo}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-40">
                            Place Of Issue
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.PassportIssuePlace}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-40">
                            Date Of Issue
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.PassportIssueDate}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-40">
                            Valid Till
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.PassportValidDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  {/* ///// Visa Details*/}
                  <div className="  border border-gray-300 px-3 rounded-md mt-1">
                    <div className="text-blue-900 text-base font-bold px-2 py-1">
                      Visa Details
                    </div>
                    <fieldset className="">
                      <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                          <span className=" font-semibold w-40">
                            Visa Number
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.VisaNo}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-40">
                            Place Of Issue
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.VisaIssuePlace}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-40">
                            Date Of Issue
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.VisaIssueDate}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-40">
                            Valid Till
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {patientData && patientData.VisaValidDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </>
              ) : null}

              <div className="grid lg:grid-cols-2 gap-3">
                {/* ///// Address Details*/}
                {patientdocs.length > 0 ? (
                  <div className="  border border-gray-300 px-3 rounded-md mt-1">
                    <div className="text-blue-900  text-base font-bold px-2 py-1">
                      Patient Documents
                    </div>
                    <fieldset className="">
                      <div className="py-1">
                        <div className="w-full mt-2">
                          <CommonDynamicTableNew
                            dataResult={patientdocs}
                            removeHeaders={["Docfile", "id", "Doc", "docId"]}
                            renderInput={renderInputPatient}
                            editableColumns={["documentPath", "DocName"]}
                            tableClass="rounded h-36"
                            highlightRow={false}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                ) : null}

                {/* ///// Address Details*/}
                {relativedocs.length > 0 ? (
                  <div className="  border border-gray-300 px-3 rounded-md mt-1">
                    <div className="text-blue-900  text-base font-bold px-2 py-1">
                      Relative Documents
                    </div>
                    <fieldset className="">
                      <div className="py-1">
                        <div className="w-full mt-2">
                          {/* <DocumentsTable data={relativedocs} setState={setRelativedocs} /> */}
                          <CommonDynamicTableNew
                            dataResult={relativedocs}
                            removeHeaders={["Docfile", "id", "Doc", "docId"]}
                            editableColumns={["documentPath", "DocName"]}
                            renderInput={renderInputRelative}
                            tableClass="rounded h-36"
                            highlightRow={false}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                ) : null}
              </div>

              <div className="flex gap-3 justify-end mt-3">
                <div>
                  <CommonButton
                    label="Cancel"
                    onClick={() => {
                      handleClose();
                    }}
                    className="border border-customRed text-customRed "
                  />
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      {/* Documents view modal */}
      {openDocumentsViewModal ? (
        <DocumentsViewModal
          open={openDocumentsViewModal}
          setOpen={setOpenDocumentsViewModal}
          handleOpen={handleOpenDocumentsViewModal}
          handleClose={handleCloseDocumentsViewModal}
          pathForDocView={pathForDocView}
        />
      ) : null}
    </div>
  );
}
