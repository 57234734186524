import axios from "axios";
import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const getItemStockList = (obj) => {
  return apiClient.post(`/stockAdjustment/getItemStock`, obj, {
    headers: authHeader(),
  });
};
export const getItemName = (searchString) => {
  return apiClient.get(`/drugAdministrator/drugName/${searchString}`, {
    headers: authHeader(),
  });
};

export const getGenericName = (searchString) => {
  return apiClient.get(`/dischargeSummary/drugGenericName/${searchString}`, {
    headers: authHeader(),
  });
};

export const CurrentStoreStockPrint = (storeId) => {
  return apiClient.get(`/reports/inventory/currentStoreStockPrint/${storeId}`, {
    headers: authHeader(),
  });
};

export const StoreExpiredItemsPrint = (storeId, days) => {
  return apiClient.get(
    `/reports/inventory/storeExpiredItemsPrint/${storeId}/${days}`,
    {
      headers: authHeader(),
    }
  );
};
// /api/reports/inventory/storeExpiredItemsPrint/{storeId}/{days}

export const CurrentStockNonList = () => {
  return apiClient.get(`/reports/inventory/currentStockNonConsignment`, {
    headers: authHeader(),
  });
};
