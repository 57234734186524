export const cardsData = [
  {
    new: 0,
  },
  {
    new: 40,
  },
  {
    new: 70,
  },
  {
    new: 45,
  },
  {
    new: 42,
  },
  {
    new: 85,
  },
];

