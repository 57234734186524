import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  errorAlert,
  successAlert,
  updateAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { DeleteIcon } from "../../../../assets/CommonAssets";
import {
  autoSearchDatais,
  deleteTemplateLinkToTheUser,
  getDepartmentsForTemplateLinkToUser,
  getTemplateListForLinkToUser,
  linkTemplatesToTheUser,
  updateLinkTemplatesToTheUser,
} from "../../../services/feedback/assignTemplate/AssignTemplateService";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonTransactionTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let feedbacktype = [
  {
    id: "IPD",
    label: "IPD",
    value: "IPD",
  },
  {
    id: "OPD",
    label: "OPD",
    value: "OPD",
  },
  {
    id: "General",
    label: "General",
    value: "General",
  },
  {
    id: "Department",
    label: "Department",
    value: "Department",
  },
];

let feedbackfor = [
  {
    id: "Consultant",
    label: "Consultant",
    value: "Consultant",
  },
  {
    id: "Staff",
    label: "Staff",
    value: "Staff",
  },
  {
    id: "Patient",
    label: "Patient",
    value: "Patient",
  },
];

let forgeneralanddep = [
  {
    id: "General",
    label: "General",
    value: "General",
  },
  {
    id: "Department",
    label: "Department",
    value: "Department",
  },
];

export default function AssignTemplateModal(props) {
  const validationSchema = yup.object().shape({
    searchbyusername: yup
      .array()
      .required("Required")
      .of(
        yup.object().shape({
          label: yup.string().required("Required"),
          value: yup.string().required("Required"),
        })
      )
      .nullable(false, "Required")
      .min(1, "Requird")
      .required("Required"),
  });

  const defaultValues = {
    consultant: false,
    staff: false,
    patient: false,
    OPD: false,
    General: false,
    Both: false,
    searchbyusername: null,
    feedbackfor: "Consultant",
    department: null,
    template: null,
    feedbacktype: null,
    forgeneralanddep: null,
    singleuserName: null,
  };
  const {
    control,
    watch,
    reset,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleClose,
    open,
    populateTable,
    getrowdata,
    edit,
    setGetrowdata,
    setEdit,
  } = props;

  let gettemplate = watch("template");
  let getdepartment = watch("department");
  let getfeedbackfor = watch("feedbackfor");
  let getfeedbacktype = watch("feedbacktype");
  let getforgeneralanddep = watch("forgeneralanddep");

  const [datailsListSave, setDatailsListSave] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [finalData, setFinalData] = useState();
  const [searchString, setSearchString] = useState("");
  const [searchdataArry, setSearchdataArry] = useState([]);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  const [openPut, setOpenPut] = useState(false);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);

  const renderActions = (row, index) => {
    return (
      <div className=" flex space-x-2">
        <button
          type="button"
          onClick={() => {
            deleteRow(index, row);
          }}
        >
          <DeleteIcon />
        </button>
      </div>
    );
  };

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };

  const handleChange = (autoSearchString) => {
    if (autoSearchString !== "") {
      let searchobj = {
        id: searchString?.id ? searchString?.id : null,
        searchString: searchString ? "" : autoSearchString,
      };
      autoSearchDatais(searchobj)
        .then((response) => response.data)
        .then((res) => {
          setSearchdataArry(res.result);
        });
    }
  };

  const autoSelectedValue = (value) => {
    if (value === null) {
      setSearchString("");
    } else {
      setSearchString(value);
    }
  };

  const addRecordlistintable = () => {
    if (gettemplate !== undefined || gettemplate !== null) {
      let temp = [...datailsListSave];
      let duplicateObj = datailsListSave.some(
        (val) => val.id === gettemplate.id
      );

      if (gettemplate !== null) {
        if (!duplicateObj) {
          temp.push({
            id: gettemplate?.id ? gettemplate?.id : null,
            ["Template Name"]: gettemplate?.label ? gettemplate?.label : "",
            "Template For": getfeedbackfor,
            ["Department Name"]:
              getdepartment !== null ? getdepartment?.label : null,
            depId: getdepartment !== null ? getdepartment?.id : null,
            FeedbackTyp: getfeedbacktype,
            forgeneralanddep: getforgeneralanddep,
          });
        } else {
          warningAlert("Record Already Exists...!");
        }
      }
      if (gettemplate === null) {
        setError("template", { type: "custom", message: "Required" });
        warningAlert("Select Template...");
      } else {
        clearErrors("template");
      }
      setDatailsListSave(temp);
      setValue("department", null);
      setValue("template", null);
    } else if (gettemplate === undefined || gettemplate === null) {
      setError("template", { type: "custom", message: "Required" });
      warningAlert("Select Template...");
    }
  };

  function deleteRow(index, row) {
    let tempArr = [...datailsListSave];
    tempArr.splice(index, 1);
    setDatailsListSave(tempArr);
    if (row?.["Feedback User Management Id"] !== undefined) {
      deleteTemplateLinkToTheUser(row?.["Feedback User Management Id"])
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
        });
    }
  }

  // function addRecord() {
  //   saveAssignTemplate();
  // }

  function addRecord() {
    setOpenPost(false);

    linkTemplatesToTheUser(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleClose();
        reset(defaultValues);
        populateTable();
        setDatailsListSave([]);
        setEdit(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  function updateRecord() {
    updateLinkTemplatesToTheUser(finalData)
      .then((response) => response.data)
      .then((res) => {
        updateAlert(res.message);
        setEdit(false);
        reset(defaultValues);
        setOpenPut(false);
        handleClose();
        setGetrowdata([]);
        populateTable();
        setFinalData(null);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  console.log("datailsListSave", datailsListSave);
  const onSubmiteData = (data) => {
    if (props.edit === false) {
      let getsearchbyusername = getValues("searchbyusername");
      let temp = [];
      if (getsearchbyusername instanceof Array) {
        temp = [...getsearchbyusername];
      }
      let requiredUnitArray = [];
      for (let obj of temp) {
        let requiredObj = {};
        requiredObj.id = obj.id;
        requiredUnitArray.push(requiredObj);
      }
      let tempArr = [];
      for (let obj of datailsListSave) {
        let tempObj = {
          templateId: { id: obj.id },
          templateFor: obj["Template For"],
          feedbackFor: obj.FeedbackTyp ? obj.FeedbackTyp : obj.forgeneralanddep,
          departmentId: obj.depId,
        };
        console.log("tempObj", tempObj);
        tempArr.push(tempObj);
      }

      let saveobj = {
        employeeId: requiredUnitArray,
        feedbackManageReqDtoList: tempArr,
      };
      if (requiredUnitArray.length > 0) {
        if (tempArr.length > 0) {
          setOpenPost(true);
          setFinalData(saveobj);
        } else {
          warningAlert("Select Template...");
        }
      } else {
        warningAlert("Select User...");
      }
    }

    if (props.edit === true) {
      let tempArr = [];
      for (let obj of datailsListSave) {
        let feedbackFor =
          obj?.["Patient Type"] === "Opd" || obj?.["Patient Type"] === "Ipd"
            ? obj?.["Patient Type"]
            : obj?.["Department Id"] === null
            ? "General"
            : obj?.["Department Id"] !== null
            ? obj.FeedbackTyp
              ? obj.FeedbackTyp
              : obj.forgeneralanddep
            : null;

        let tempObj = {
          templateId: { id: obj.id },
          templateFor: obj["Template For"]
            ? obj["Template For"]
            : obj?.["Template For"],
          feedbackFor,
          departmentId:
            obj?.["Department Id"] === undefined
              ? obj.depId
              : obj?.["Department Id"] || obj.depId === null
              ? obj?.["Department Id"]
              : obj.depId,
        };
        tempArr.push(tempObj);
        let objUpdate = {
          employeeId: [{ id: getrowdata?.[0]?.["Employee Id"] }],
          feedbackManageReqDtoList: tempArr,
        };
        setOpenPut(true);
        setFinalData(objUpdate);
      }
    }
  };

  const dataOfTempletdropdown = () => {
    let searchobj = {
      isTemplateFor: getfeedbackfor ? getfeedbackfor : null,
      feedbackType: getfeedbacktype
        ? getfeedbacktype
        : null || getforgeneralanddep
        ? getforgeneralanddep
        : null,
      departmentId: getdepartment?.id ? getdepartment?.id : null,
    };
    if (getfeedbacktype !== null || getforgeneralanddep !== null) {
      getTemplateListForLinkToUser(searchobj)
        .then((response) => response.data)
        .then((res) => {
          setTemplateDropdown(res.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const dataOfDepartment = () => {
    let searchobj = {
      isTemplateFor: getfeedbackfor,
    };
    if (getfeedbackfor !== "") {
      getDepartmentsForTemplateLinkToUser(searchobj)
        .then((response) => response.data)
        .then((res) => {
          setDepartmentDropdown(res.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  console.log("gettemplate", getfeedbacktype, getforgeneralanddep);

  useEffect(() => {
    dataOfTempletdropdown();
  }, [getfeedbackfor, getfeedbacktype, getdepartment, getforgeneralanddep]);

  useEffect(() => {
    if (
      getfeedbacktype === "Department" ||
      getforgeneralanddep === "Department"
    ) {
      dataOfDepartment();
    }
  }, [getfeedbacktype, getforgeneralanddep]);

  useEffect(() => {
    setValue("feedbacktype", null);
    setValue("forgeneralanddep", null);
    setValue("department", null);
    setValue("template", null);
  }, [getfeedbackfor, forgeneralanddep]);

  useEffect(() => {
    if (getrowdata?.length > 0) {
      let objusername = {
        id: getrowdata?.[0]?.["Employee Id"],
        label: getrowdata?.[0]?.["Employee Name"],
        value: getrowdata?.[0]?.["Employee Name"],
      };
      setDatailsListSave(getrowdata);
      setValue("singleuserName", objusername);
    }
  }, [getrowdata]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="min-w-[85%] max-w-[90%] min-h-[60%] max-h-[80%]"
        >
          <form onSubmit={handleSubmit(onSubmiteData)}>
            <CancelPresentationIconButton
              onClick={() => {
                handleClose();
                setDatailsListSave([]);
                setGetrowdata([]);
                reset(defaultValues);
                setEdit(false);
                populateTable();
              }}
            />
            <h1 className="flex justify-center font-semibold text-lg">
              Assign Template 
            </h1>
            <div className=" mt-3 w-96">
              {edit === false ? (
                <SearchDropdown
                  isMulti={true}
                  control={control}
                  name="searchbyusername"
                  label="Search By User Name"
                  placeholder="Search By User Name"
                  searchIcon={true}
                  isClearable={true}
                  handleInputChange={handleChange}
                  onChange={autoSelectedValue}
                  dataArray={searchdataArry}
                  error={errors.searchbyusername}
                  menuPlacement={"bottom"}
                />
              ) : (
                <DropdownField
                  control={control}
                  name="singleuserName"
                  placeholder="User Name"
                  isDisabled={true}
                />
              )}
            </div>
            {edit === true ? (
              <div className="border border-[#AFAFAF] mt-2 mb-2 bg-[#EBEBEB] py-2 px-2 rounded-lg  grid grid-cols-[7%_2%_11%_15%_5%_25%] text-[13px] border-[] ">
                <h1 className=" font-semibold">User Name</h1>
                <h1 className=" font-semibold">:</h1>
                <h1>{getrowdata?.[0]?.["Employee Name"]}</h1>
              </div>
            ) : (
              ""
            )}

            {/* CheckBox */}
            <div className=" grid grid-cols-[45%_55%]">
              <div className=" flex items-center gap-3">
                <h2 className=" text-[13px] font-semibold">Feedback For :</h2>
                <RadioField
                  control={control}
                  name="feedbackfor"
                  dataArray={feedbackfor}
                />
              </div>
              <div className=" flex items-center gap-3">
                <h2 className=" text-[13px] font-semibold whitespace-nowrap">
                  Feedback Type :
                </h2>
                {getfeedbackfor === "Patient" ? (
                  <div>
                    <RadioField
                      control={control}
                      name="feedbacktype"
                      dataArray={feedbacktype}
                    />
                  </div>
                ) : (
                  ""
                )}

                {getfeedbackfor === "Consultant" ||
                getfeedbackfor === "Staff" ? (
                  <RadioField
                    control={control}
                    name="forgeneralanddep"
                    dataArray={forgeneralanddep}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <p className=" border-[#007EA9] border-b-[1px]" />

            <div className=" mt-5 my-2 flex gap-3">
              {getfeedbacktype === "Department" ||
              getforgeneralanddep === "Department" ? (
                <div className=" w-[40%]">
                  <DropdownField
                    name="department"
                    placeholder="Department"
                    control={control}
                    dataArray={departmentDropdown}
                    error={errors.template}
                    isSearchable={true}
                  />
                </div>
              ) : null}
              <div className=" w-[40%]">
                <DropdownField
                  name="template"
                  placeholder="Search Template"
                  control={control}
                  dataArray={templateDropdown}
                  error={errors.template}
                  isSearchable={true}
                />
              </div>
              <div className=" w-[20%] ">
                <CommonButton
                  label="Add"
                  type="button"
                  className="otherButton border border-customBlue text-customBlue"
                  onClick={addRecordlistintable}
                />
              </div>
            </div>
            {/* getrowdata */}
            <div className="">
              {datailsListSave?.length > 0 ? (
                <CommonTransactionTable
                  dataResult={datailsListSave}
                  renderActions={renderActions}
                  highlightRow={false}
                  tableClass={"h-[200px]"}
                  removeHeaders={[
                    "id",
                    "Department Id",
                    "Patient Type",
                    "Employee Id",
                    "depId",
                    "Feedback User Management Id",
                    "forgeneralanddep",
                    "FeedbackTyp",
                    "Employee Name",
                  ]}
                />
              ) : null}
            </div>
            <div className=" flex justify-end">
              {edit === false ? (
                <CommonButton
                  label="Save"
                  className="saveButton bg-customGreen text-white"
                  type="submit"
                />
              ) : (
                <CommonButton
                  label="Update"
                  className="updateButton bg-customGreen text-white"
                  type="submit"
                />
              )}
            </div>
          </form>
          <ConfirmationModal
            confirmationOpen={openPost}
            confirmationHandleClose={handleClosePost}
            confirmationSubmitFunc={addRecord}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure want to add this Template To User ?"
            confirmationButtonMsg="Add"
          />
          <ConfirmationModal
            confirmationOpen={openPut}
            confirmationHandleClose={handleClosePut}
            confirmationSubmitFunc={updateRecord}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure want to update this record ?"
            confirmationButtonMsg="Update"
          />
        </Box>
      </Modal>
    </div>
  );
}
