import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Checkbox, Tooltip } from "@mui/material";

export default function CommonTable(props) {
  const { selected, setSelected, setCheckIsPackage } = props;
  const [deleteAction, setDeleteAction] = React.useState(false);
  const [editAction, setEditAction] = React.useState(false);
  const [serviceLength, setServiceLength] = React.useState(0);
  // const [confirmAction, setconfirmAction] = React.useState(false);
  // const [rescheduleAction, setrescheduleAction] = React.useState(false);
  // const [cancelAction, setcancelAction] = React.useState(false);
  //state varibale for the table

  React.useEffect(() => {
    if (props.actions) {
      props.actions.forEach((action) => {
        if (action === "Edit") {
          setEditAction(true);
        }
        if (action === "Delete") {
          setDeleteAction(true);
        }
        // if (action === "Confirm") {
        //   setconfirmAction(true);
        // }
        // if (action === "Reschedule") {
        //   setrescheduleAction(true);
        // }
        // if (action === "Cancel") {
        //   setcancelAction(true);
        // }
      });
    }
  }, [props.actions]);

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "doctorId",
    "serviceId",
    "rateEditAllowed",
    "discountApplicable",
    "doctorShareApplicable",
    "billInfoId",
    "DiscountPerc",
    "DiscountAmt",
    "ChargeId",
    "ServiceCode",
    "Opd_Ipd_External_Id",
    "LoginName",
    "OTSheetNo",
    "RateEditable",
    "DoctorId",
    "StaffFree",
    "IsPackage",
    "SponsorType",
    "ServiceId",
    "DiscountPerc",
    "AdminCharges",
    "IsServiceTaxApp",
    "IsGSTApplicable",
    "deductible",
    "NetAmt"
  ]);

  let serviceList = [];
  React.useEffect(() => {
    props.data.map((item) => {
      if (item.IsPackage === false) {
        serviceList.push(item);
      }
    });
  }, [props]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = serviceList.map((n) => {
        if (n.IsPackage === false) {
          setCheckIsPackage(false);
          return n;
        }
      });

      setSelected(newSelected);
      setServiceLength(serviceList.length);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    let obj = newSelected.find((o) => o.IsPackage === true);
    if (typeof obj !== "undefined") {
      setCheckIsPackage(true);
    } else {
      setCheckIsPackage(false);
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer className="rounded lg:h-[16rem] 2xl:h-[16rem] border">
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {props.actions && props.actions.length > 0 ? (
                      <TableCell
                        className="whitespace-nowrap"
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">Actions</span>
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {/* {props.charges.toLowerCase() == "all" ? ( */}
                    <TableCell
                      padding="checkbox"
                      style={{ background: "#F1F1F1" }}
                    >
                      <Checkbox
                        color="primary"
                        checked={
                          serviceLength > 0 && selected.length === serviceLength
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    {/* ) : (
                      ""
                    )} */}

                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={index}
                        hover
                        // onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {props.actions && props.actions.length > 0 ? (
                          <TableCell className="px-4 py-1 flex whitespace-nowrap ">
                            <div className="flex">
                              {editAction ? (
                                <Tooltip title="Edit">
                                  <button className="h-9 px-3 w-full  bg-blue-900 text-white rounded text-sm font-medium ">
                                    <DriveFileRenameOutlineIcon
                                      fontSize="small"
                                      onClick={(e) => {
                                        props.editCharge(row, row.billInfoId);
                                        props.setPreviousChargesAction(true);
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              ) : (
                                ""
                              )}

                              {deleteAction ? (
                                <Tooltip title="Delete">
                                  <button
                                    className="text-red-500 mr-3"
                                    onClick={() => {
                                      props.deleteCharge(index, true, row);
                                    }}
                                  >
                                    {
                                      <DeleteOutlineOutlinedIcon fontSize="small" />
                                    }
                                  </button>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {/* {props.charges.toLowerCase() !== "all" ? ( */}
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            // disabled={row.IsPackage}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) => handleClick(event, row)}
                          />
                        </TableCell>
                        {/* ) : (
                          ""
                        )} */}
                        {headers &&
                          headers.map((header, i) => (
                            <>
                              {row[header] !== null ? (
                                <TableCell
                                  key={i}
                                  className="whitespace-nowrap"
                                >
                                  {row[header]}
                                </TableCell>
                              ) : (
                                <TableCell
                                  className="whitespace-nowrap"
                                  key={i}
                                >
                                  -
                                </TableCell>
                              )}
                            </>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
