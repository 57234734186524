import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
//care plan post services
// /api/carePlan/saveCarePlan
export const saveCarePlan = (postObj) => {
  return apiClient.post(`/carePlan/saveCarePlan`, postObj, {
    headers: authHeader(),
  });
};

export const fetchCarePlanDetails = (AdmissionId) => {
  return apiClient.get(`/carePlan/getCarePlanDetails/${AdmissionId}`, {
    headers: authHeader(),
  });
};
// fetch list of care plan
// /api/carePlan/carePlanViewList

export const fetchAllCarePlan = (listObj) => {
  return apiClient.post(`/carePlan/carePlanViewList`, listObj, {
    headers: authHeader(),
  });
};

export const carePlanPrint = (carePlanId) => {
  return apiClient.get(`/reports/nursing/carePlan/${carePlanId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

// search patient from care plan list
export const fetchAllCarePlanList = (searchObj) => {
  return apiClient.post(`/carePlan/carePlanViewListSearch`, searchObj, {
    headers: authHeader(),
  });
};

