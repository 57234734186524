import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Tooltip } from "@mui/material";

export default function PurchaseQuotationTable(props) {
  // const { quotationdata, setDataResult } = props;

  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.quotationdata];
      newTemplateData.splice(deleteIndex, 1);
      props.setQuotationData(newTemplateData);
      handleClosePost();
    }
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                },
              }}
              className="rounded h-56"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Action</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Item Name</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">U.O.M.</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Quantity</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Rate/Quantity
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Amount</span>
                    </TableCell>

                    {/* <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Excise</span>
                    </TableCell> */}

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">GST(%)</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        GST Amount
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Conc. (%)</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Conc. Amount
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Net Amount
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Remark</span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.quotationdata.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="flex gap-2">
                            <Tooltip title="Delete">
                              <div
                                className="text-red-700"
                                onClick={() => {
                                  setOpenPost(true);
                                  setDeleteIndex(index);
                                }}
                              >
                                {<DeleteOutlineOutlinedIcon />}
                              </div>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <div
                                value="click"
                                className="text-[#1e3a8a]"
                              >
                                <DriveFileRenameOutlineIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.editRow(row, index);
                                    props.setItemMasterVal(null);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.itemMaster?.label || row.ItemName}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.uom || row.PurchaseUOM}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.quantity || row.Qty}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.rate || row.Rate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.amount}
                        </TableCell>

                        {/* <TableCell className="whitespace-nowrap">
                          {row.exciseAmount}
                        </TableCell> */}

                        <TableCell className="whitespace-nowrap">
                          {row.gstPercentage}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.gstAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.concessionPercentage}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.concessionAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.netAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.remark || row.Remarks}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {props.quotationdata.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-12">
                  No Items Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this service ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
