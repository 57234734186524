import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../common/components/FormFields/InputField";
import InputArea from "../../../common/components/FormFields/InputArea";

const PastHistory = () => {
  const { control, register } = useFormContext();

  return (
    <>
      <div>
        <div className="grid grid-cols-2  gap-3 pt-3">
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="dm" label="DM" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="dmDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="htn" label="HTN" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="htnDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField
                control={control}
                name="heartDisease"
                label="Heart Disease"
              />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="heartDiseaseDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="tb" label="TB" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="tbDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="copd" label="COPD" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="copdDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="asthama" label="Asthama" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="asthamaDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="liver" label="Liver" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="liverDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField
                control={control}
                name="hypothyroidism"
                label="Hypothyroidism"
              />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="hypothyroidismDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="other" label="Other" />
            </div>
            <div className="col-span-2 ">
              {/* //OtherInput.// */}

              <InputArea
                control={control}
                name="otherDtls"
                // label="Other "
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PastHistory;
