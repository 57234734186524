import React from "react";

function CancelButton({ onClick }) {
  return (
    <div>
      <button
        type="button"
        className="h-9  px-2 border border-red-700 text-red-700 rounded text-sm 2xl:text-base font-medium"
        onClick={onClick}
      >
        Cancel
      </button>
    </div>
  );
}

export default CancelButton;
