import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import GrnSeachDropdownField from "./GrnSearchDropdownField";

export default function WorkOrderGrnItemTable(props) {
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();
  //state varibale for the table

  //table start
  const calculateTotalAmount = (row, index, Qty) => {
    let totalAmount = Qty * Number(row.Rate);
    let netAmt = Qty * row.Rate - row.DiscAmt + Number(row.VATAmt);
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.Qty = Qty;

    tempObj.Rate = row.Rate;
    tempObj.Amount = totalAmount.toFixed(2);
    tempObj.DiscPer = row.DiscPer;
    tempObj.DiscAmt = row.DiscAmt;
    tempObj.NetAmount = netAmt.toFixed(2);
    temp[index] = tempObj;
    props.setData(temp);
  };

  const calculateDiscountAmount = (row, index, Qty, Rate, DiscPer) => {
    let discountAmt = (Number(DiscPer) * row.Amount) / 100;
    let netAmt = row.Qty * row.Rate - discountAmt + Number(row.VATAmt);
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.Qty = Qty;

    tempObj.Rate = row.Rate;
    tempObj.Amount = row.Amount;
    tempObj.DiscPer = DiscPer;
    tempObj.DiscAmt = discountAmt.toFixed(2);
    tempObj.NetAmount = netAmt.toFixed(2);
    temp[index] = tempObj;
    setValue(`DiscAmt${index}`, discountAmt);

    props.setData(temp);
  };
  const calculateDiscountPercent = (row, index, Qty, Rate, DiscAmt) => {
    let totalAmount = row.Qty * Number(row.Rate);
    let discountPer = (Number(DiscAmt) * 100) / totalAmount;
    let netAmt = row.Qty * row.Rate - DiscAmt + Number(row.VATAmt);
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.Qty = Qty;

    tempObj.Rate = row.Rate;
    tempObj.Amount = totalAmount;
    tempObj.DiscPer = discountPer.toFixed(2);
    tempObj.DiscAmt = DiscAmt;
    tempObj.NetAmount = netAmt.toFixed(2);
    temp[index] = tempObj;
    setValue(`DiscPer${index}`, discountPer.toFixed(2));
    props.setData(temp);
  };
  const calculateGSTAmount = (
    row,
    index,
    Qty,
    Rate,
    DiscPer,
    DiscAmt,
    VATPer
  ) => {
    let totalAmount = row.Qty * Number(row.Rate);
    let gstAmt = (Number(totalAmount) * Number(VATPer)) / 100;
    let netAmt = row.Qty * row.Rate - DiscAmt + Number(row.VATAmt);
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.Qty = Qty;
    tempObj.Rate = row.Rate;
    tempObj.Amount = totalAmount.toFixed(2);
    tempObj.DiscPer = DiscPer;
    tempObj.VATPer = VATPer;
    tempObj.VATAmt = gstAmt.toFixed(2);
    tempObj.DiscAmt = DiscAmt;
    tempObj.NetAmount = netAmt.toFixed(2);
    temp[index] = tempObj;
    setValue(`VATAmt${index}`, gstAmt.toFixed(2));
    props.setData(temp);
  };


  React.useEffect(() => {
    let temp = [...props.data];
    let totalAmount = 0;
    let netAmount = 0;
    for (let obj of temp) {
      let tempObj = {};
      tempObj.totalAmount = totalAmount += Number(obj.Amount);
      tempObj.netAmount = netAmount += Number(obj.NetAmount);
      props.setTotalCalculations(tempObj);
    }

    for (let i = 0; i <= props.data?.length; i++) {
      setValue(
        `workOrderTable.[${i}].accountHead`,
        props.data?.[i]?.accountHead
      );
    }
  }, [props.data]);

  const handleCheckbox = (index) => {
    const newArray = [...props.data];
    newArray[index].Id = !newArray[index].Id;
    props.setSelectedGrnItems(newArray.filter((obj) => obj.Id));
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1, border: "1px solid lightgray" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                  borderRadius:2
                },
              }}
              className="rounded lg:h-[350px]  2xl:h-[450px] overflow-hidden"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">Select</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">
                          Item Code
                        </span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">
                          Item Name
                        </span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">Qty</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">UOM</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">Price</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">Amount</span>
                      </TableCell>

                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">Disc %</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">
                          Disc Amt
                        </span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">GST %</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">GST Amt</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">Net Amt</span>
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        <span className="text-gray-600 font-bold">
                          Acc Head
                        </span>
                      </TableCell>
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => (
                    <TableRow key={index} onClick={() => {}}>
                      <TableCell
                        className="text-gray-600 font-bold text-center whitespace-nowrap"
                        key={row.Id}
                      >
                        <input
                          type="checkbox"
                          className="w-4 h-4 "
                          name={`workOrderTable.[${index}].select`}
                          {...register(`workOrderTable.[${index}].select`)}
                          checked={row.Id}
                          onChange={() => handleCheckbox(index)}
                        />
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.ItemCode}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.ItemName}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        <input
                          className="border rounded border-customBlue w-20 h-[25px] text-center"
                          type="number"
                          size="small"
                          defaultValue={row.Qty}
                          name={`Qty${index}`}
                          {...register(`Qty${index}`)}
                          onChange={(e) => {
                            let Qty = e.target.value;

                            calculateTotalAmount(row, index, Qty);
                          }}
                        />
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.UOM}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.Rate}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.Amount}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        <input
                          className="border rounded border-customBlue w-20 h-[25px] text-center"
                          type="number"
                          size="small"
                          defaultValue={row.DiscPer}
                          name={`DiscPer${index}`}
                          {...register(`DiscPer${index}`)}
                          onChange={(e) => {
                            let DiscPer = e.target.value;
                            let Rate = getValues(`Rate${index}`);
                            let Qty = getValues(`Qty${index}`);

                            calculateDiscountAmount(
                              row,
                              index,
                              Qty,
                              Rate,
                              DiscPer
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        <input
                          className="border rounded border-customBlue w-20 h-[25px] text-center"
                          type="number"
                          size="small"
                          defaultValue={row.DiscAmt}
                          name={`DiscAmt${index}`}
                          {...register(`DiscAmt${index}`)}
                          onChange={(e) => {
                            let DiscAmt = e.target.value;
                            let Rate = getValues(`Rate${index}`);

                            let Qty = getValues(`Qty${index}`);

                            calculateDiscountPercent(
                              row,
                              index,
                              Qty,
                              Rate,
                              DiscAmt
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        <input
                          className="border rounded border-customBlue w-20 h-[25px] text-center"
                          type="number"
                          size="small"
                          defaultValue={row.VATPer}
                          name={`VATPer${index}`}
                          {...register(`VATPer${index}`)}
                          onChange={(e) => {
                            let VATPer = e.target.value;
                            let Rate = getValues(`Rate${index}`);

                            let Qty = getValues(`Qty${index}`);
                            let DiscPer = getValues(`DiscPer${index}`);
                            let DiscAmt = getValues(`DiscAmt${index}`);

                            calculateGSTAmount(
                              row,
                              index,
                              Qty,
                              Rate,
                              DiscPer,
                              DiscAmt,
                              VATPer
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.VATAmt}
                      </TableCell>

                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.NetAmount}
                      </TableCell>

                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap  w-[200px]">
                        <GrnSeachDropdownField
                          control={control}
                          error={errors?.workOrderTable?.[index]?.accountHead}
                          name={`workOrderTable.[${index}].accountHead`}
                          placeholder="Acc Head *"
                          dataArray={props.accountHeadOptions}
                          inputRef={{
                            ...register(
                              `workOrderTable.[${index}].accountHead`,
                              {
                                onChange: (e) => {
                                  let accountHead = e.target.value.id;
                                  let dataArray = [...props.data];
                                  dataArray[index].accountHead = e.target.value;
                                  props.setData(dataArray);
                                  // grnDropdownfiled(row, index, accountHead);
                                },
                              }
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
