import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";
import { fetchTaxType } from "../../../services/pharmacy/inPatientReturnServices/InPatientReturnServices";
import {
  fetchPrintInDepartmentReturn,
  postReturnFromDeptData,
} from "../../../services/pharmacy/returnFromDeptServices/ReturnFromDeptServices";
import GetIndentModal from "./GetIndentModal";
import ItemDetailsTable from "./ItemDetailsTable";

const GsReturnFromDept = () => {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [stores, setStores] = useState();
  //

  //patient details
  const [patientDetails, setPatientDetails] = React.useState(null);
  //item details
  const [itemDetails, setItemDetails] = React.useState([]);
  const [errorToTblInput, setErrorToTblInput] = useState(false);

  //gst
  const [totalGst, setTotalGst] = React.useState();
  //total Return Amt
  const [totalReturnAmt, setTotalReturnAmt] = React.useState();
  //item taxType
  const [taxType, setTaxType] = React.useState([]);
  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  //indent modal
  const [openIndentModal, setOpenIndentModal] = React.useState(false);
  const handleOpenIndentModal = () => setOpenIndentModal(true);
  const handleCloseIndentModal = () => setOpenIndentModal(false);

  //
  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  //

  const location = useLocation();

  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      indentCondition: 0,
      SearchPatient: "",
      storeName: "",
      netReturnAmount: 0,
      totalAmount: 0,
      totalGstAmount: 0,
      serChargeAmount: 0,
      remark: "",
    },
  });

  const {
    formState: { errors },
    control,
    getValues,
    register,
    setValue,
  } = methods;

  //

  //
  const handleGetIssuedItems = () => {
    handleOpenIndentModal();
  };

  useEffect(() => {
    setValue("storeName", token.storeName);
  }, []);

  useEffect(() => {
    setValue("noOfItems", itemDetails.length);
  }, [, itemDetails]);

  useEffect(() => {
    // tax type
    fetchTaxType()
      .then((response) => response.data)
      .then((res) => {
        setTaxType(res.result);
      });
  }, []);

  //
  useEffect(() => {
    setValue("toStore", {
      id: token.storeId,
      label: token.storeName,
      value: token.storeName,
    });
  }, [token]);

  useEffect(() => {
    fetchApplicableStores(token.storeId)
      .then((response) => response.data)
      .then((res) => {
        setStores(res.result);
      });
  }, []);

  //final obj
  const finalObj = {
    addedBy: 0,
    fromStoreId: 0,
    remarks: "string",
    returnFromDepartmentDetailsDto: [
      {
        batchCode: "string",
        batchExpDate: "2023-05-16T10:40:42.429Z",
        conversion: true,
        isConsignment: true,
        issueId: 0,
        itemId: 0,
        mrp: 0,
        netAmount: 0,
        purchaseRate: 0,
        quantity: 0,
        totalForVat: 0,
        totalMrpAmount: 0,
        totalPurAmount: 0,
        totalVatAmount: 0,
        unitLandedAmount: 0,
        unitLandedRate: 0,
        vatAmount: 0,
        vatPercentage: 0,
      },
    ],
    toStoreId: 0,
    totalItems: 0,
    totalMrpAmount: 0,
    totalPurAmount: 0,
    totalVatAmt: 0,
    type: true,
  };

  ////onSubmit post object below  selectedDrugData

  const onSubmit = (data) => {
    let nonZeroItems = itemDetails.some((item) => item.Quantity < 1);
    if (itemDetails.length < 1) {
      warningAlert("Please Select Items !");
    } else if (errorToTblInput || nonZeroItems) {
      warningAlert("Please Issue Valid Quantity !");
    } else if (!errorToTblInput) {
      setOpenConfirmationModal(true);
    }
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    //
    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;
    finalObj.addedBy = token.userId;
    finalObj.fromStoreId = token.cashCounterId; //
    finalObj.toStoreId = token.storeId; //
    finalObj.toStoreId = token.storeId; //

    finalObj.remarks = getValues("remark");
    finalObj.totalItems = getValues("noOfItems");
    finalObj.totalMrpAmount = getValues("totalAmount");
    finalObj.totalPurAmount = getValues("totalAmount");
    finalObj.totalVatAmt = getValues("noOfItems");
    finalObj.type = true;

    // to set data according service obj
    let TblData = [];
    for (let i = 0; i < itemDetails.length; i++) {
      let tableObj = {
        avlQtyForApproval: itemDetails[i].QtyAvlForReturn,
        batchCode: itemDetails[i].BatchCode,
        batchExpiryDate: new Date(),
        conversion: itemDetails[i].Conversion,
        discPercentage: itemDetails[i].QtyAvlForReturn, ///
        discount: itemDetails[i].QtyAvlForReturn, ///
        ipdIssueId: itemDetails[i].IPDIssueId,
        ipdReturnRate: itemDetails[i].QtyAvlForReturn, //
        isConsignment: true, //
        itemId: itemDetails[i].ItemId,
        mrp: itemDetails[i].MRP,
        netAmount: itemDetails[i].QtyAvlForReturn, //
        otherTax: itemDetails[i].OtherTaxAmount,
        purchaseRate: itemDetails[i].PurchaseRate,
        returnQty: itemDetails[i].Quantity,
        taxAmount: itemDetails[i].TaxAmount,
        totalAmount: itemDetails[i].returnPrice, //
        totalForVat: itemDetails[i].QtyAvlForReturn, //
        unitLandedRate: itemDetails[i].QtyAvlForReturn, //
        unitMrp: itemDetails[i].UnitMRP,
        vatAmount: itemDetails[i].QtyAvlForReturn, //
        vatInclusive: itemDetails[i].VATInclusive,
        vatPercentage: itemDetails[i].VATPercentage,
      };
      TblData.push(tableObj);
    }

    const nonZeroItems = TblData.filter(
      (item) => item.returnQty !== undefined && item.returnQty !== 0
    );
    //

    finalObj.returnFromDepartmentDetailsDto = nonZeroItems;

    //

    postReturnFromDeptData(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          methods.reset();

          setOpenBackdrop(false);
          setPatientDetails(null);
          setItemDetails([]);

          methods.resetField("SearchPatient");
          // setTotalAmount(0);
          // setTotalQuantity(0);
          handleOpenPrintModal(res.result);
        }
      })
      .catch((res) => {
        // errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  // print service

  const handleOpenPrintModal = (returnFromdeptId) => {
    fetchPrintInDepartmentReturn(returnFromdeptId,"A5").then((response) => {
      setOpenPrintModal(true);
      setUrlforPrint(response);
    });
  };

  return (
    <div className="mt-12">
      {/* //heading// */}
      <div className="text-center text-black font-bold text-xl ">
        Return From Department
      </div>
      <form>
        {/* //search// */}

        {/* //filters// */}
        <div className="grid grid-cols-6 lg:grid-cols-11 gap-3">
          {/* //Indent From Store// */}
          <div className="col-span-2 lg:col-span-2">
            <DropdownField
              control={control}
              // error={errors.nationality}
              name="FromStore"
              label="From Store"
              dataArray={stores}
              isSearchable={false}
              isClearable={false}
              placeholder=" From Store"
            />
          </div>

          {/* //Indent To Store// */}
          <div className="col-span-3 lg:col-span-2">
            <DropdownField
              name="toStore"
              label="To Store"
              control={control}
              dataArray={stores}
              isSearchable={false}
              isClearable={false}
              placeholder="To Store"
              isDisabled={true}
            />
          </div>

          {/* buttons */}
          <div className="flex gap-3 col-span-7 justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction ? (
                  <>
                    {obj.action === "Get Issued Items" ? (
                      <div>
                        <CommonButton
                          label="Get Issued Items"
                          onClick={handleGetIssuedItems}
                          className="bg-customBlue text-white"
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>

        {/* Selected item details table setNoOfItems, setTotalQuantity */}
        <div className="mt-2">
          <ItemDetailsTable
            data={itemDetails}
            setItemDetails={setItemDetails}
            setTotalGst={setTotalGst}
            setTotalReturnAmt={setTotalReturnAmt}
            setErrorToTblInput={setErrorToTblInput}
          />
        </div>

        {/* formfields */}
        {/* Remark & Buttons */}
        <div className="grid lg:grid-cols-12 mt-3 gap-3">
          {/* Remark */}
          <div className="col-span-4">
            <TextField
              id="outlined-multiline-static"
              name="remark"
              {...register("remark")}
              label="Remark"
              multiline
              InputLabelProps={{ shrink: true }}
              rows={1}
              fullWidth
            />
          </div>
          {/* ///no of items/// */}
          <div className="col-span-2">
            <InputField
              // sx={{backgroundColor: '#808080'}}
              name="noOfItems"
              variant="outlined"
              label="No of Items "
              // error={errors.code}
              control={control}
              disabled={true}
              isDisabled={true}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          {/* Buttons */}

          <div className="flex gap-3 col-span-6  justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <div>
                      <CommonButton
                        label="Reset"
                        onClick={() => {
                          setItemDetails([]);
                        }}
                        className="border border-customRed text-customRed"
                      />
                    </div>
                    <div>
                      <CommonButton
                        label="Save"
                        onClick={() => {
                          setPrivilege(obj.action);
                          onSubmit();
                        }}
                        className="bg-customGreen text-white"
                      />
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </form>

      {/* get Indent Modal    */}
      {openIndentModal ? (
        <GetIndentModal
          open={openIndentModal}
          setOpen={setOpenIndentModal}
          handleOpen={handleOpenIndentModal}
          handleClose={handleCloseIndentModal}
          patientDetails={patientDetails}
          // selectedIndentListItems={selectedIndentListItems}
          // setSelectedIndentListItems={setSelectedIndentListItems}

          //
          setItemDetails={setItemDetails}
          itemDetails={itemDetails}
          //
        />
      ) : null}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default GsReturnFromDept;
