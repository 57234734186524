import React from "react";
import { useFormContext } from "react-hook-form";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

export default function BedScoreTable(props) {
  const { register } = useFormContext(); // retrieve all hook methods

 

  //useEffect to Re-Calculate the bedScoreAssessment on change event.
  React.useEffect(() => {
    let totalBedScoreAssessmentScore = 0;

    let tempSensoryPerceptionArray = structuredClone(
      props.bedScoreAssessmentArray
    );

    for (let tempSensoryPerceptionObj of tempSensoryPerceptionArray) {
      if (tempSensoryPerceptionObj.flagValue === true) {
        totalBedScoreAssessmentScore =
          totalBedScoreAssessmentScore + tempSensoryPerceptionObj.score;
      }
    }

    //calculating the total bedScoreAssessmentScore
    props.setBedAssessmentScore(totalBedScoreAssessmentScore);
  }, [props.bedScoreAssessmentArray]);

  //event listener function for the click of checkboxes in the table.
  function modifyBedScoreAssessmentArray(index, flag) {
    
    let tempSensoryPerceptionArray = structuredClone(
      props.bedScoreAssessmentArray
    );

    
    

    tempSensoryPerceptionArray[index].flagValue = flag;

    props.setBedScoreAssessmentArray(tempSensoryPerceptionArray);
  }

  console.log("bedScoreAssessmentArray",props.bedScoreAssessmentArray);

  return (
    <>
      <div className="w-auto grid">
        <form
          className="grid grid-cols-1 w-full gap-2 "
          // onSubmit={handleSubmit(onSubmitDataHandler)}
        >
          <TableContainer sx={{ marginTop: "0rem" }} className="rounded ">
            <Table size="small">
              <TableHead>
                <TableRow
                  className="bg-gray-100"
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold whitespace-nowrap">
                      Braden Scale
                    </span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      textAlign: "center",
                    }}
                  >
                    <span className="font-bold whitespace-nowrap">Scoring</span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                      width: "12rem",
                      textAlign: "center",
                    }}
                  >
                    <span className="font-bold whitespace-nowrap">Mark</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* 1 Sensory Perception Heading */}
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold">Sensory / Mental</span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGray",
                    }}
                  ></TableCell>
                </TableRow>

                {/* 1 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">1. Totaly Limited</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset
                      disabled={
                        props.bedScoreAssessmentArray[1].flagValue === true ||
                        props.bedScoreAssessmentArray[2].flagValue === true ||
                        props.bedScoreAssessmentArray[3].flagValue === true
                      }
                    >
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="1"
                        name="sensoryPerCompletelyLimited"
                        {...register("sensoryPerCompletelyLimited")}
                        onChange={(e) => {
                          const index = 0;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 2 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">2. Very Limited </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                   2
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="2"
                        name="sensoryPerVeryLimited"
                        {...register("sensoryPerVeryLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[0].flagValue === true ||
                          props.bedScoreAssessmentArray[2].flagValue === true ||
                          props.bedScoreAssessmentArray[3].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 1;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 3 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3"> 3. Slightly Limited</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="3"
                        name="sensoryPerSlightlyLimited"
                        {...register("sensoryPerSlightlyLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[0].flagValue === true ||
                          props.bedScoreAssessmentArray[1].flagValue === true ||
                          props.bedScoreAssessmentArray[3].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 2;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 4 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3"> 4. No Impairment</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="4"
                        name="sensoryPerNoImpairment"
                        {...register("sensoryPerNoImpairment")}
                        disabled={
                          props.bedScoreAssessmentArray[0].flagValue === true ||
                          props.bedScoreAssessmentArray[1].flagValue === true ||
                          props.bedScoreAssessmentArray[2].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 3;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/*Moisture Heading*/}
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold">Moisture</span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGray",
                    }}
                  ></TableCell>
                </TableRow>

                {/* 1 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">1. Constantly Moist</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="1"
                        name="moistureCompletelyLimited"
                        {...register("moistureCompletelyLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[5].flagValue === true ||
                          props.bedScoreAssessmentArray[6].flagValue === true ||
                          props.bedScoreAssessmentArray[7].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 4;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 2 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">2. Very Moist </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="2"
                        name="moistureVeryLimited"
                        {...register("moistureVeryLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[4].flagValue === true ||
                          props.bedScoreAssessmentArray[6].flagValue === true ||
                          props.bedScoreAssessmentArray[7].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 5;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 3 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">3. Occasionally Moist </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="3"
                        name="moistureOccasionallyLimited"
                        {...register("moistureOccasionallyLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[4].flagValue === true ||
                          props.bedScoreAssessmentArray[5].flagValue === true ||
                          props.bedScoreAssessmentArray[7].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 6;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 4 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">4. Dry</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="4"
                        name="rarelyMoist"
                        {...register("rarelyMoist")}
                        disabled={
                          props.bedScoreAssessmentArray[4].flagValue === true ||
                          props.bedScoreAssessmentArray[5].flagValue === true ||
                          props.bedScoreAssessmentArray[6].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 7;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* Activity Heading */}
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold">Activity</span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGray",
                    }}
                  ></TableCell>
                </TableRow>

                {/* 1 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">1. Bedfast </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="1"
                        name="activeBedLying"
                        {...register("activeBedLying")}
                        disabled={
                          props.bedScoreAssessmentArray[9].flagValue === true ||
                          props.bedScoreAssessmentArray[10].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[11].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 8;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 2 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">2. Chairfast </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="2"
                        name="activeChairDependent"
                        {...register("activeChairDependent")}
                        disabled={
                          props.bedScoreAssessmentArray[8].flagValue === true ||
                          props.bedScoreAssessmentArray[10].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[11].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 9;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 3 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">3. Walks W/assistant </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                   3
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="3"
                        name="activeWalksOccasionally"
                        {...register("activeWalksOccasionally")}
                        disabled={
                          props.bedScoreAssessmentArray[8].flagValue === true ||
                          props.bedScoreAssessmentArray[9].flagValue === true ||
                          props.bedScoreAssessmentArray[11].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 10;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 4 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">4. Walks W/Out Assistant</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="4"
                        name="activeWalksFrequency"
                        {...register("activeWalksFrequency")}
                        disabled={
                          props.bedScoreAssessmentArray[8].flagValue === true ||
                          props.bedScoreAssessmentArray[9].flagValue === true ||
                          props.bedScoreAssessmentArray[10].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 11;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* Mobility Heading */}
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold">Mobility </span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGray",
                    }}
                  ></TableCell>
                </TableRow>

                {/* 1 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">1. 100% Immobile </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="1"
                        name="mobilityCompletelyLimited"
                        {...register("mobilityCompletelyLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[13].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[14].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[15].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 12;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 2 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">2. Very Limited </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="2"
                        name="mobilityVeryLimited"
                        {...register("mobilityVeryLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[12].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[14].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[15].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 13;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 3 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">3. Slightly Limited </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="3"
                        name="mobilitySlightlyLimited"
                        {...register("mobilitySlightlyLimited")}
                        disabled={
                          props.bedScoreAssessmentArray[13].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[12].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[15].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 14;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 4 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">4. Full Mobility</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="4"
                        name="mobilityNoImpairment"
                        {...register("mobilityNoImpairment")}
                        disabled={
                          props.bedScoreAssessmentArray[13].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[12].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[14].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 15;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/*Nutrition Heading*/}
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold">Nutrition </span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGray",
                    }}
                  ></TableCell>
                </TableRow>

                {/* 1 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">1. Very Poor </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="1"
                        name="nutritionVeryPoor"
                        {...register("nutritionVeryPoor")}
                        disabled={
                          props.bedScoreAssessmentArray[17].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[18].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[19].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 16;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 2 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                  <span className="pl-3">2. Less than 1/2 Daily Portion</span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="2"
                        name="nutritionProbablyInadequate"
                        {...register("nutritionProbablyInadequate")}
                        disabled={
                          props.bedScoreAssessmentArray[16].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[18].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[19].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 17;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 3 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">3. Most of portion </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="3"
                        name="nutritionAdequate"
                        {...register("nutritionAdequate")}
                        disabled={
                          props.bedScoreAssessmentArray[16].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[17].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[19].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 18;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 4 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">4. Eats everything </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                   4
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="4"
                        name="nutritionExcellent"
                        {...register("nutritionExcellent")}
                        disabled={
                          props.bedScoreAssessmentArray[16].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[18].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[17].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 19;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* Friction And Shear Heading*/}
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="font-bold">Friction And Shear </span>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid lightGray",
                      borderLeft: "1px solid lightGray",
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGray",
                    }}
                  ></TableCell>
                </TableRow>

                {/* 1 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">1. Frequent sliding </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="1"
                        name="frictionAndShearProblem"
                        {...register("frictionAndShearProblem")}
                        disabled={
                          props.bedScoreAssessmentArray[21].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[22].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 20;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 2 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">2. Feeble corrections </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="2"
                        name="frictionAndShearPotentialProblem"
                        {...register("frictionAndShearPotentialProblem")}
                        disabled={
                          props.bedScoreAssessmentArray[20].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[22].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 21;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>

                {/* 3 */}
                <TableRow
                  sx={{
                    "& td": {
                      paddingY: 0,
                    },
                  }}
                >
                  <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                    <span className="pl-3">3. Independent corrections </span>
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeft: "1px solid lightGray",
                      borderRight: "1px solid lightGrey",
                      textAlign: "center",
                    }}
                  >
                    <fieldset>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value="4"
                        name="frictionAndShearNoApparentProblem"
                        {...register("frictionAndShearNoApparentProblem")}
                        disabled={
                          props.bedScoreAssessmentArray[20].flagValue ===
                            true ||
                          props.bedScoreAssessmentArray[21].flagValue === true
                        }
                        onChange={(e) => {
                          const index = 22;
                          let flag = e.target.checked;
                          modifyBedScoreAssessmentArray(index, flag);
                        }}
                      />
                    </fieldset>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      </div>
    </>
  );
}
