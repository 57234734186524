import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonTransactionTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { ModalStyle, ModalStyleFeedback } from "../../../../common/components/ModalStyle";


function QuestionInfoModal(props) {
  const [questionInfoData, setQuestionInfoData] = useState([]);

  const handleRowReorder = (startIndex, endIndex) => {
    const updatedRows = [...questionInfoData];
    const [movedRow] = updatedRows.splice(startIndex, 1);
    updatedRows.splice(endIndex, 0, movedRow);
    setQuestionInfoData(updatedRows);
  };

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (index) => (event) => {
    event.preventDefault();
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const startIndex = Number(event.dataTransfer.getData("text/plain"));
    handleRowReorder(startIndex, index);
  };

  function renderInput(row, index, header) {
    return (
      <>
        <button
          type="button"
          draggable
          onDragStart={handleDragStart(index)}
          onDragOver={handleDragOver(index)}
          onDrop={handleDrop(index)}
        >
          <DragIndicatorIcon className="text-[#BDBDBD]" />
        </button>
      </>
    );
  }

  useEffect(() => {
    let tempArr = [];
    if (props.selectedInfoRow !== null) {
      for (let obj of props.selectedInfoRow["Question Details"]) {
        let tempObj = {
          Arrange: "",
          depId: obj.depId,
          // Department: obj.depName,
          Question: obj.question,
        };
        tempArr.push(tempObj);
        setQuestionInfoData(tempArr);
      }
    }
  }, [props.selectedInfoRow["Question Details"]]);
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="rounded w-[860px] max-h-[70%] overflow-y-auto"
        >
          <h1 className="font-semibold text-[20px] text-center">Question Info</h1>
          <CancelPresentationIconButton
            className="bg-[#FFDCDC] rounded-full"
            onClick={() => {
              props.handleClose();
            }}
          />
          <div>
            <h2 className="text-sm border p-1 rounded bg-gray-100">
              <span className="font-semibold">
                Question Head&nbsp; : &nbsp;
              </span>
              {props.selectedInfoRow?.["Question Heading"]}
            </h2>
            {questionInfoData.length > 0 ? (
              <CommonTransactionTable
                dataResult={questionInfoData}
                // renderInput={renderInput}
                // editableColumns={["Arrange"]}
                removeHeaders={["qid", "depId","Arrange"]}
                highlightRow={false}
                tableClass={"h-[180px] lg:h-[300px] rounded border"}

              />
            ) : (
              ""
            )}
      
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default QuestionInfoModal;