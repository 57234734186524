import React, { useRef, useEffect, useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  deleteAlert,
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import { Box } from "@mui/system";
import { Modal } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import {
  deleteVitals,
  getAllVitals,
  savePatientVitals,
} from "../../../services/cliinicalChartServices/vitalsService/VitalsService";
import CareNPosition from "../painassessment/carenposition/CareNPosition";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  DeleteOnIcon,
  VitalsRadarIcon,
} from "../../../../assets/icons/CustomIcons";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import VitalsRadar from "./VitalsRadar";

function Vital(props) {
  // const schema = yup
  //   .object()
  //   .shape({
  //     temperature: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     pulseRate: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     respiration: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     bloodPressureSystolic: yup
  //       .string()
  //       .required()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow"),
  //     cvp: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     peep: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     arterialBloodPressure: yup
  //       .string()
  //       .required()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow"),
  //     papressureReading: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     brady: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     apnea: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     abdominalGirth: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     desaturation: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     fiO2: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     pao2: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     saturationWithO2: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     saturationWithoutO2: yup
  //       .string()
  //       .required()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow"),
  //     pfRatio: yup.string().required(),
  //   })
  //   .required();

  const avpuArray = [
    { id: 0, value: "Alert", label: "Alert" },
    { id: 1, value: "Response to Voice", label: "Response to Voice" },
    { id: 2, value: "Pain", label: "Pain" },
    { id: 3, value: "Unresponsive", label: "Unresponsive" },
  ];

  const defaultValues = {
    temperature: "",
    pulseRate: "",
    respiration: "",
    cvp: "",
    peep: "",
    arterialBloodPressure: "",
    bloodPressureSystolic: "",
    // bloodPressureDiastolic: "",
    papressureReading: "",
    brady: "",
    apnea: "",
    abdominalGirth: "",
    desaturation: "",
    fiO2: "",
    pao2: "",
    pfRatio: "",
    saturationWithO2: "",
    saturationWithoutO2: "",
    avpu: 0,
    mews: "",
    tracheostomy: false,
    nasal: false,
    ett: false,
    oral: false,
    finalMews: "",
  };

  let finalMews;
  const mewsDivElement = useRef();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues,
  });

  const [openRadarVitals, setOpenRadarVitals] = useState(false);
  const [openPost, setOpenPost] = useState(false);

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataResult, setDataResult] = useState([]);
  const [finalData, setFinalData] = useState({});
  const [openView, setOpenView] = useState(false);
  const [openCareNPostionModal, setOpenCareNPostionModal] = useState(false);
  const handleOpenCareNPostionModal = () => setOpenCareNPostionModal(true);
  const handleCloseCareNPostionModal = () => setOpenCareNPostionModal(false);
  const [mewsScore, setMewsScore] = useState();

  const [deleteObj, setDeleteObj] = useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);

  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);

  const [temp, pulse, rr] = watch(["temperature", "pulseRate", "respiration"]);
  const avpuVal = watch("avpu");
  // to remove slash while considering b/p
  let bpStr = watch("bloodPressureSystolic");
  let arr = bpStr?.split("/");
  let bp = arr && arr[0];
  function SystolicBloodPressure(sbp) {
    if (sbp >= 200) {
      return 2;
    } else if (sbp >= 101 && sbp <= 199) {
      return 0;
    } else if (sbp >= 81 && sbp <= 100) {
      return 2;
    } else if (sbp >= 71 && sbp <= 80) {
      return 2;
    } else if (sbp <= 70) {
      return 3;
    }
  }
  function PulseCalculation(pulse) {
    if (pulse <= 40) {
      return 2;
    } else if (pulse >= 41 && pulse <= 50) {
      return 1;
    } else if (pulse >= 51 && pulse <= 100) {
      return 0;
    } else if (pulse >= 101 && pulse <= 110) {
      return 1;
    } else if (pulse >= 111 && pulse <= 129) {
      return 2;
    } else if (pulse >= 130) {
      return 3;
    }
  }
  function RespiratoryRate(bpm) {
    if (bpm <= 9) {
      return 2;
    } else if (bpm > 9 && bpm <= 14) {
      return 0;
    } else if (bpm >= 15 && bpm <= 20) {
      return 1;
    } else if (bpm >= 21 && bpm <= 29) {
      return 2;
    } else if (bpm >= 30) return 3;
  }
  function Temperature(fahrenheit) {
    if (fahrenheit < 95) {
      return 3;
    } else if (fahrenheit >= 95 && fahrenheit <= 97) {
      return 1;
    } else if (fahrenheit >= 97.1 && fahrenheit <= 100) {
      return 0;
    } else if (fahrenheit >= 100.1 && fahrenheit <= 101.3) {
      return 1;
    } else if (fahrenheit > 101.3) {
      return 2;
    }
  }

  const calculateMews = (bp, temp, pulse, rr) => {
    let mewsVal;

    if (
      Number(bp) > 0 &&
      Number(temp) > 0 &&
      Number(pulse) > 0 &&
      Number(rr) > 0
    ) {
      let bpValue = SystolicBloodPressure(bp);

      let pulseValue = PulseCalculation(pulse);

      let tempValue = Temperature(temp);

      let rrValue = RespiratoryRate(rr);

      mewsVal =
        Number(bpValue) +
        Number(pulseValue) +
        Number(tempValue) +
        Number(rrValue);
    }

    return mewsVal;
  };

  useEffect(() => {
    finalMews = calculateMews(bp, temp, pulse, rr);

    if (
      (bp === "" ||
        temp === "" ||
        pulse === "" ||
        rr === "" ||
        avpuVal === "") &&
      props.open === true
    ) {
      //mewsDivElement.current.innerHTML = "";
    }

    if (avpuVal !== "") {
      finalMews = finalMews + Number(avpuVal);
    }

    setMewsScore(finalMews);
    // if (finalMews >= 5 && avpuVal !== "") {
    //   mewsDivElement.current.innerHTML = `

    //   <div class="flex  items-center">
    //   <p  class=" text-gray-700 px-2 font-bold">MEWS  </p>
    //      <div class="w-10 h-10 rounded-full border-2 border-red-500 flex justify-center  bg-red-500 items-center text-white">

    //     <p
    //       class=" text-white py-2"
    //     >
    //     ${mewsScore}
    //     </p>

    //   </div>
    //   <p class="px-2 font-bold text-red-500">Please Call Doctor For Consultation</p>
    //   `;
    // } else if (finalMews < 5 && avpuVal !== "") {
    //   mewsDivElement.current.innerHTML = `     <div class="flex  items-center">
    //   <p  class=" text-gray-700 px-2 font-bold">MEWS</p>
    //      <div class="w-10 h-10 rounded-full border-2 border-green-500 flex justify-center  bg-green-500 items-center">

    //     <p
    //       class=" text-white py-2"
    //     >
    //     ${mewsScore}
    //     </p>

    //   </div>
    //   <p class="px-2 font-bold text-green-500">No Need to Call Doctor For Consultation</p>`;
    // }
  }, [bp, temp, pulse, rr, avpuVal, props.open, mewsScore]);

  useEffect(() => {
    if (props.displayInfo?.AdmissionId || props.admissionId) {
      populateTable();
    }
    reset(defaultValues);
  }, [props.displayInfo?.AdmissionId || props.admissionId]);

  const populateTable = (obj) => {
    showSpinner(true);
    setRecordWarning(false);
    getAllVitals(props.displayInfo?.AdmissionId || props.admissionId)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setRecordWarning(true);
        setDataResult(res.result);
      })
      .catch((error) => {
        setRecordWarning(true);
      });
  };

  useEffect(() => {
    if (mewsDivElement === undefined) {
      mewsDivElement.current.innerHTML = "";
      setValue("avpu", null);
    }
  }, [bp, temp, pulse, rr]);

  useEffect(() => {
    if (mewsDivElement === undefined && avpuVal === null) {
      mewsDivElement.current.innerHTML = "";
    }
  }, [avpuVal]);

  useEffect(() => {
    if (
      errors.hasOwnProperty("temperature") ||
      errors.hasOwnProperty("pulseRate") ||
      errors.hasOwnProperty("respiration") ||
      errors.hasOwnProperty("bloodPressureSystolic") === true
    ) {
      mewsDivElement.current.innerHTML = "";
    }
  }, []);

  function postVitals(obj) {
    savePatientVitals(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateTable(props.displayInfo?.AdmissionId || props.admissionId);
          props?.handlePatientInfo()
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postVitals(finalData);
    reset(defaultValues);
    mewsDivElement.current && reset((mewsDivElement.current.innerHTML = ``));
  }

  let getFio2 = getValues("fiO2");
  let getPio2 = getValues("pao2");
  let getPFRatio = getPio2 / getFio2;
  useEffect(() => {
    if (getFio2 !== "") setValue("pfRatio", getPFRatio);
  }, [getFio2]);

  const onSubmitDataHandler = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));
    let getpatientVisitId = props.displayInfo?.AdmissionId;
    let getipdAdmissionId = props.admissionId;
    let getVisitId;
    if (getpatientVisitId) {
      getVisitId = getpatientVisitId;
    } else if (getipdAdmissionId) {
      getVisitId = getipdAdmissionId;
    }

    if (getpatientVisitId !== undefined || getipdAdmissionId !== null) {
      let postedObj = {
        menuId: props.menuId,
        privilege: props.privilege,
        abdominal: data.abdominalGirth,
        addedBy: getUserId.userId,
        admissionId: getVisitId,
        apnea: data.apnea,
        arterialBp: data.arterialBloodPressure,
        avpu: data.avpu,
        bp: data.bloodPressureSystolic,
        brady: data.brady,
        cvp: data.cvp,
        desaturation: data.desaturation,
        oral: data.oral || false,
        ett: data.ett || false,
        nasal: data.nasal || false,
        fio2: data.fiO2,
        po2: data.pao2,
        isCancel: 0,
        // isSynchronised: false,
        mewsScore: mewsScore,
        paPressure: data.papressureReading,
        peep: data.peep,
        pulse: data.pulseRate,
        respiration: data.respiration,
        saturationWithO2: data.saturationWithO2,
        saturationWithoutO2: data.saturationWithoutO2,
        status: false,
        teacheostomy: data.tracheostomy || false,
        temperature: data.temperature,

        // bloodPressureDiastolic: data.bloodPressureDiastolic,
        // pfRatio: data.pfRatio,
        givenTime: new Date(),
        givenDate: new Date(),
      };

      if (isDirty !== false) {
        if (data.avpu === "" || data.avpu === undefined) {
          warningAlert("Please Select AVPU Value");
        } else if (data.avpu !== "" || data.avpu !== undefined) {
          setOpenPost(true);
          setFinalData(postedObj);
        }
      } else if (isDirty === false) {
        warningAlert("please enter value...!");
      } else if (
        getpatientVisitId === undefined ||
        getipdAdmissionId === null
      ) {
        warningAlert("Please Select Patient...!");
      }
    }
  };

  const handleSelectedRow = (row, index) => {
    console.log("rowIndex", index);
    editRow(row, index);
  };

  //event listener function for edit icon
  function editRow(requiredRow, index) {
    //   {
    //     "Date": "06-01-2024",
    //     "Time": "02:42:17 PM",
    //     "Temperature": "5",
    //     "Pulse": "5",
    //     "Respiration": "5",
    //     "BP": null,
    //     "MewsScore": null,
    //     "AVPU": null,
    //     "TakenBy": null,
    //     "cvp": null,
    //     "Arterial_bp": null,
    //     "PA_Prassure": null,
    //     "abdominal": null,
    //     "Brady": null,
    //     "Apnea": null,
    //     "Desaturation": null,
    //     "Peep": null,
    //     "FiO2": null,
    //     "PO2": null,
    //     "saturationwitho2": null,
    //     "saturationwithouto2": null,
    //     "oral": null,
    //     "Ett": null,
    //     "tracheostomy": null,
    //     "Nasal": null,
    //     "Id": 1750010
    // }

    console.log("requiredRow",requiredRow);
    
    let resetObj = {
      temperature: requiredRow.Temperature ? requiredRow.Temperature : "",
      pulseRate: requiredRow.Pulse ? requiredRow.Pulse : "",
      respiration: requiredRow.Respiration ? requiredRow.Respiration : "",
      cvp: requiredRow.cvp ? requiredRow.cvp : "",
      peep: requiredRow.Peep ? requiredRow.Peep : "",
      arterialBloodPressure: requiredRow["Arterial_bp"]
        ? requiredRow["Arterial_bp"]
        : "",
      bloodPressureSystolic: requiredRow.BP ? requiredRow.BP : "",
      // bloodPressureDiastolic: requiredRow.,
      papressureReading: requiredRow["PA_Prassure"]
        ? requiredRow["PA_Prassure"]
        : "",
      brady: requiredRow.Brady ? requiredRow.Brady : "",
      apnea: requiredRow.Apnea ? requiredRow.Apnea : "",
      abdominalGirth: requiredRow.abdominal ? requiredRow.abdominal : "",
      desaturation: requiredRow.Desaturation ? requiredRow.Desaturation : "",
      fiO2: requiredRow.FiO2 ? requiredRow.FiO2 : "",
      pao2: requiredRow.PO2 ? requiredRow.PO2 : "",
      // pfRatio: requiredRow.,
      saturationWithO2: requiredRow?.saturationwitho2
        ? Number(requiredRow?.saturationwitho2)
        : "",
      saturationWithoutO2: requiredRow?.saturationwithouto2
        ? Number(requiredRow?.saturationwithouto2)
        : "",
      avpu: requiredRow.AVPU ? requiredRow.AVPU : null,
      mews: requiredRow.MewsScore ? requiredRow.MewsScore : "",
      tracheostomy: requiredRow.tracheostomy ? requiredRow.tracheostomy : "",
      nasal: requiredRow.Nasal ? requiredRow.Nasal : "",
      ett: requiredRow.Ett ? requiredRow.Ett : "",
      oral: requiredRow.oral ? requiredRow.oral : "",
      // finalMews: requiredRow.,
    };
    reset(resetObj);
  }

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deleteVitals(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            populateTable();
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true &&
            obj.action === "Delete" &&
            !props.selectedPatient?.AllowToGO ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    let deleteObjVal = {};

                    deleteObjVal.Id = row.Id;
                    deleteObjVal.action = obj.action;
                    setDeleteObj(deleteObjVal);
                    //open the delete confirmation modal
                    handleOpenDeleteConfirm();
                  }}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <label className="my-1 text-sm font-semibold whitespace-nowrap">
          Vitals
        </label>
        <div className="cursor-pointer">
          <VitalsRadarIcon
            title={"Radar Vitals"}
            onClick={() => {
              setOpenRadarVitals(true);
            }}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid col-span-3 gap-4 w-full">
          {spinner ? (
            <div className="grid justify-center">
              <LoadingSpinner />
            </div>
          ) : null}

          {dataResult.length > 0 && spinner === false ? (
            <CommonDynamicTableNew
              dataResult={dataResult}
              tableClass={"rounded h-56 2xl:h-72 border"}
              highlightRow={true}
              removeHeaders={[
                "id",
                "saturation",
                "suction",
                "visitId",
                "patientId",
                "isChecked",
              ]}
              handleSelectedRow={handleSelectedRow}
              renderActions={renderActions}
            />
          ) : (
            <h1 className="flex justify-center my-5 font-bold text-gray-600">
              No Records Found...
            </h1>
          )}
        </div>
        <div className="bg-white ">
          <div className="grid grid-cols-4 gap-2 py-2">
            <div
              className="w-full"
              // onKeyDown={(e) => {
              //   e.preventDefault();
              // }}
            >
              <InputField
                type="number"
                name="temperature"
                variant="outlined"
                label="Temperature (°F)"
                control={control}
                error={errors.temperature}
              />
            </div>
            <div className="w-full">
              <InputField
                type="number"
                name="pulseRate"
                variant="outlined"
                label="Pulse"
                control={control}
                error={errors.pulseRate}
              />
            </div>
            <div className="w-full">
              <InputField
                type="number"
                name="respiration"
                variant="outlined"
                label="Respiration"
                control={control}
                error={errors.respiration}
              />
            </div>
            <div className="w-full">
              <InputField
                // type="number"
                name="bloodPressureSystolic"
                variant="outlined"
                label="Blood Pressure"
                control={control}
                error={errors.bloodPressureSystolic}
              />
            </div>
            <div className="lg:flex py-2 col-span-full pl-2">
              <RadioField
                label=""
                name="avpu"
                control={control}
                dataArray={avpuArray}
              />
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-1 gap-2">
            {mewsScore > 0 &&
              (mewsScore && mewsScore <= 5 ? (
                <div className="flex items-center col-span-2 text-sm">
                  <p className=" text-gray-700 px-2 font-bold">MEWS</p>
                  <div className="w-8 h-8 rounded-full border-2 border-green-500 flex justify-center  bg-green-500 items-center text-white">
                    <p className=" text-white text-sm ">{mewsScore}</p>
                  </div>
                  <p class="px-2 font-bold text-green-500">
                    No Need To Call Doctor For Consultation
                  </p>
                </div>
              ) : (
                <div className="flex items-center col-span-2 text-sm ">
                  <p className=" text-gray-700 px-2 font-bold">MEWS</p>
                  <div className="w-8 h-8 rounded-full border-2 border-red-500 flex justify-center  bg-red-500 items-center text-white">
                    <p className=" text-white text-sm ">{mewsScore}</p>
                  </div>
                  <p class="px-2 font-bold text-red-500">
                    Please Call Doctor For Consultation
                  </p>
                </div>
              ))}
          </div>
          <div className="grid lg:grid-cols-2 gap-2 lg:gap-4 py-2">
            <div className="grid md:grid-cols-4 lg:grid-cols-2 gap-2">
              <InputField
                name="cvp"
                type="number"
                variant="outlined"
                label="CVP"
                control={control}
                error={errors.cvp}
              />
              <InputField
                name="peep"
                type="number"
                variant="outlined"
                label="Peep"
                control={control}
                error={errors.peep}
              />
              <InputField
                name="arterialBloodPressure"
                variant="outlined"
                //type="number"
                label="Arterial Blood Pressure"
                control={control}
                error={errors.arterialBloodPressure}
              />
              <InputField
                name="papressureReading"
                variant="outlined"
                type="number"
                label="PA Pressure Reading"
                control={control}
                error={errors.papressureReading}
              />
              <InputField
                name="brady"
                type="number"
                variant="outlined"
                label="Brady"
                control={control}
                error={errors.brady}
              />
              <InputField
                name="apnea"
                type="number"
                variant="outlined"
                label="Apnea"
                control={control}
                error={errors.apnea}
              />
              <InputField
                name="abdominalGirth"
                type="number"
                variant="outlined"
                label="Abdominal Girth"
                control={control}
                error={errors.abdominalGirth}
              />
              <InputField
                name="desaturation"
                type="number"
                variant="outlined"
                label="Desaturation"
                control={control}
                error={errors.desaturation}
              />
              <div className="hidden lg:block">
                <InputField
                  name="pao2"
                  type="number"
                  variant="outlined"
                  label="Po2"
                  control={control}
                  error={errors.pao2}
                />
              </div>
              <div className="hidden lg:block">
                <InputField
                  name="fiO2"
                  type="number"
                  variant="outlined"
                  label="Fio2"
                  control={control}
                  error={errors.fiO2}
                />
              </div>
            </div>
            <div className="grid">
              <p className="text-base font-bold ">Suction</p>
              <div className="grid md:grid-cols-4 lg:grid-cols-5 items-center text-sm">
                <div className="flex items-center">
                  <CheckBoxField
                    control={control}
                    name="oral"
                    label="Oral"
                    value="oral"
                  />
                </div>
                <div className="flex items-center">
                  <CheckBoxField
                    control={control}
                    value="medicolegalcase"
                    name="ett"
                    label="ETT"
                  />
                </div>
                <div className="flex items-center col-span-2">
                  <CheckBoxField
                    control={control}
                    value="tracheostomy"
                    name="tracheostomy"
                    label="Tracheostomy"
                  />
                </div>
                <div className="flex items-center">
                  <CheckBoxField
                    control={control}
                    value="nasal"
                    name="nasal"
                    label="Nasal"
                  />
                </div>
              </div>
              <p className="text-base font-bold items-end pt-2">Saturation</p>
              <div className="grid md:grid-cols-2 lg:grid-cols-1 items-end gap-2">
                <div className="grid grid-cols-2 gap-2 pb-2">
                  <InputField
                    name="saturationWithO2"
                    variant="outlined"
                    label="Saturation With o2"
                    control={control}
                    type="number"
                    error={errors.saturationWithO2}
                    inputProps={{ style: { textTransform: "capitalize" } }} // use inputProps props for return 1st letter in upper case
                  />

                  <InputField
                    name="saturationWithoutO2"
                    variant="outlined"
                    type="number"
                    label="Saturation WithOut o2"
                    control={control}
                    error={errors.saturationWithoutO2}
                    inputProps={{ style: { textTransform: "capitalize" } }} // use inputProps props for return 1st letter in upper case
                    defaultValue=""
                  />
                </div>
                <div className="lg:hidden">
                  <div className="grid grid-cols-2 gap-2">
                    <InputField
                      name="pao2"
                      variant="outlined"
                      type="number"
                      label="Po2"
                      control={control}
                      error={errors.pao2}
                    />
                    <InputField
                      name="fiO2"
                      variant="outlined"
                      label="Fio2"
                      type="number"
                      control={control}
                      error={errors.fiO2}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 items-center gap-2">
                <div className="">
                  <InputField
                    name="pfRatio"
                    variant="outlined"
                    label="P:F Ratio"
                    type="number"
                    error={errors.pfRatio}
                    control={control}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    disabled={true}
                  />
                </div>
                <div className="flex gap-5">
                  <div className="w-full">
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label=" Care And Position "
                      onClick={() => {
                        handleOpenCareNPostionModal();
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <CommonButton
                      type="button"
                      className="border border-orange-500 text-orange-500"
                      label="Print P:F Ratio"
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="" />
          <div className=" flex gap-2 justify-end py-2">
            {props.userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                        mewsDivElement.current.innerHTML = ``;
                      }}
                    />
                    {props.patientInformation?.BedReleased &&
                      props.selectedPatient?.BedReleased? null : (
                      <div onClick={() => props.setPrivilege(obj.action)}>
                        <CommonButton
                          type="submit"
                          //  type="button"
                          className="bg-customGreen text-white"
                          
                          label="Save"
                          // onClick={methods.handleSubmit(onSubmitDataHandler)}
                        />
                      </div>
                    )}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </form>
      
      {openRadarVitals && (
        <VitalsRadar
        open={openRadarVitals}
        setOpen={setOpenRadarVitals}
        selectedPatient={props?.selectedPatient}
        displayInfo={props?.displayInfo}
        />
        )}

        
        <CommonBackDrop openBackdrop={openBackdrop} />

      <div>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />
      </div>

      <ConfirmationModal
        confirmationOpen={deleteconfirm}
        confirmationHandleClose={handleCloseDeleteConfirm}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />
      <Modal
        open={openView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            height: "auto",
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <div className="flex justify-between mb-2">
            <p className="font-bold">Vitals</p>
            <CancelPresentationIconButton
              onClick={() => {
                setOpenView(false);
              }}
            />
          </div>
        </Box>
      </Modal>
      {openCareNPostionModal ? (
        <CareNPosition
          open={openCareNPostionModal}
          setOpen={setOpenCareNPostionModal}
          handleOpen={handleOpenCareNPostionModal}
          handleClose={handleCloseCareNPostionModal}
          selectedPatient={props.selectedPatient}
          patientInformation={props.patientInformation}
          admissionId={props.admissionId}
          displayInfo={props.displayInfo}
          userActions={props.userActions}
          setPrivilege={props.setPrivilege}
          privilege={props.privilege}
          menuId={props.menuId}
        />
      ) : null}
    </div>
  );
}

export default Vital;
