import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { fetchPrintCodeBlueForm } from "../services/codeBlueFormServices/CodeBlueFormServices";

export default function CodeBlueFormPrint({ codeBlueFormId }) {
  const printRef = useRef(null);
  const [dataBlue, setDataBlue] = React.useState();

  useEffect(() => {
    if (codeBlueFormId !== null) {
      fetchPrintCodeBlueForm(codeBlueFormId)
        .then((response) => response.data)
        .then((res) => {
          setDataBlue(res.result);
        });
    }
  }, [codeBlueFormId]);

  useEffect(() => {
    dataBlue && printRef && GeneratePrint(printRef);
  }, [dataBlue]);

  let getMedicalEmergency = dataBlue?.MedicalEmergency;

  let MedicalEmergencyData;

  if (getMedicalEmergency === 1) {
    MedicalEmergencyData = true;
  }

  if (getMedicalEmergency === 0) {
    MedicalEmergencyData = false;
  }

  let getAdult = dataBlue?.Adult;

  let AdultData;

  if (getAdult === 1) {
    AdultData = true;
  }

  if (getAdult === 0) {
    AdultData = false;
  }
  let getPaediatric = dataBlue?.Paediatric;

  let PaediatricData;

  if (getPaediatric === 1) {
    PaediatricData = true;
  }

  if (getPaediatric === 0) {
    PaediatricData = false;
  }
  let getBasicLifeSupport = dataBlue?.BasicLifeSupport;

  let BasicLifeSupportData;

  if (getBasicLifeSupport === 1) {
    BasicLifeSupportData = true;
  }

  if (getBasicLifeSupport === 0) {
    BasicLifeSupportData = false;
  }

  let getAdvancedLifeSupport = dataBlue?.AdvancedLifeSupport;

  let AdvancedLifeSupportData;

  if (getAdvancedLifeSupport === 1) {
    AdvancedLifeSupportData = true;
  }

  if (getAdvancedLifeSupport === 0) {
    AdvancedLifeSupportData = false;
  }

  let getCodeBlueProceduresFollowed = dataBlue?.CodeBlueProceduresFollowed;

  let CodeBlueProceduresFollowedData;

  if (getCodeBlueProceduresFollowed === 1) {
    CodeBlueProceduresFollowedData = true;
  }

  if (getCodeBlueProceduresFollowed === 0) {
    CodeBlueProceduresFollowedData = false;
  }

  let getResuscitationSuccessful = dataBlue?.ResuscitationSuccessful;

  let ResuscitationSuccessfulData;

  if (getResuscitationSuccessful === 1) {
    ResuscitationSuccessfulData = true;
  }

  if (getResuscitationSuccessful === 0) {
    ResuscitationSuccessfulData = false;
  }

  let getNotRevived = dataBlue?.NotRevived;

  let NotRevivedData;

  if (getNotRevived === 1) {
    NotRevivedData = true;
  }

  if (getNotRevived === 0) {
    NotRevivedData = false;
  }

  let getShiftedToICU = dataBlue?.ShiftedToICU;

  let ShiftedToICUData;

  if (getShiftedToICU === 1) {
    ShiftedToICUData = true;
  }

  if (getShiftedToICU === 0) {
    ShiftedToICUData = false;
  }

  let getStabilizedInWARD = dataBlue?.StabilizedInWARD;

  let StabilizedInWARDData;

  if (getStabilizedInWARD === 1) {
    StabilizedInWARDData = true;
  }

  if (getStabilizedInWARD === 0) {
    StabilizedInWARDData = false;
  }

  return (
    <>
      <div ref={printRef}>
        <table className=" w-full">
          <thead
            className=" w-full"
            style={{
              "-fs-table-paginate": "paginate",
            }}
          >
            <div className=" ">
              <div className=" flex justify-end text-[15px] ">
                <InamdarHospiLogo />
              </div>
              <h1 className=" text-center font-semibold text-[15px]">
                CODE BLUE FORM (DOCUMENTS RECORD)
              </h1>

              <div className="text-center border-t-[1px] border-black font-semibold text-[13px]">
               
                Filled By Operator
              </div>

              <div
                className=" grid grid-cols-[18%_1%_31%_18%_1%_31%]
              border-b-[1px]  p-2 gap-1 border-black "
              >
                <div className=" font-semibold text-[13px] ">Form No</div>
                <div className="font-semibold text-[13px]">:</div>
                <div className="text-[13px]">{dataBlue?.FormNo}</div>
                <div className=" font-semibold text-[13px]">
                  Name Of The Caller
                </div>
                <div className="font-semibold text-[13px]">:</div>
                <div className="text-[11px]">{dataBlue?.NameOfTheCaller}</div>
                <div className=" font-semibold text-[13px]">
                  Caller Location
                </div>
                <div className="font-semibold text-[11px]">:</div>
                <div className="text-[11px]">{dataBlue?.CallerLocation}</div>
                <div className=" font-semibold text-[13px]">Operator Name</div>
                <div className="font-semibold text-[11px]">:</div>
                <div className="text-[11px]">{dataBlue?.NameOfOperator}</div>
                <div className=" font-semibold text-[13px]">Date</div>
                <div className="font-semibold text-[11px]">:</div>
                <div className="text-[11px]">{dataBlue?.Date}</div>
                <div className=" font-semibold text-[13px]">Time</div>
                <div className="font-semibold text-[11px]">:</div>
                <div className="text-[11px]">{dataBlue?.Time}</div>
              </div>
            </div>
            <div className="  "></div>
          </thead>
          <tbody className="w-full">
            <div>
              <h1 className=" font-semibold  text-center text-[13px] border-b-[1px] border-black">
                Filled By Code Blue Team
              </h1>
              <div className=" space-x-[39px]">
                <span className=" font-semibold text-[12px]">Victim Name</span>
                <span className=" font-semibold text-[12px]">:</span>
                <span className=" text-[12px]">{dataBlue?.VictimName}</span>
              </div>
              <div className=" grid grid-cols-3">
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">
                    Medical Emergency
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={MedicalEmergencyData} />
                  </span>
                </div>
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">Adult</span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={AdultData} />
                  </span>
                </div>
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">Paediatric</span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={PaediatricData} />
                  </span>
                </div>
              </div>
              <div className="space-x-3">
                <span className="text-[11px] font-semibold">
                  Time of Arrival of Code Team
                </span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px]">
                  {dataBlue?.TimeofArrivalofCodeTeam}
                </span>
              </div>
              <div className="space-x-[60px]">
                <span className="text-[11px] font-semibold">Diagnosis</span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px] whitespace-pre-wrap break-words">{dataBlue?.Diagnosis}</span>
              </div>
            </div>
            <div>
              <h1 className=" font-semibold mt-5 text-center text-[13px] ">
                Indications For Code Blue
              </h1>
              <div className="space-x-[11px]">
                <span className="text-[11px] font-semibold">
                  1. Was CPR initiated in the ward
                </span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px]">
                  {dataBlue?.CPRinitiatedintheward}
                </span>
              </div>
              <div className=" space-x-5">
                <span className="text-[11px] font-semibold my-5">
                  If No Explain
                </span>
                <spann className="text-[11px] font-semibold">:</spann>
                <span className="text-[11px]">{dataBlue?.Explaination}</span>
              </div>

              {/* BasicLifeSupport */}
              <div className=" grid grid-cols-3">
                <div className=" space-x-[17px] items-center">
                  <span className="text-[11px] font-semibold">
                    2. Was Basic Life Support &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={BasicLifeSupportData} />
                  </span>
                </div>
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">
                    Advanced Life Support
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={AdvancedLifeSupportData} />
                  </span>
                </div>
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">
                    Code Blue Procedures Followed
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input
                      type="checkbox"
                      checked={CodeBlueProceduresFollowedData}
                    />
                  </span>
                </div>
              </div>

              <div className="space-x-[28px]">
                <span className="text-[11px] font-semibold">
                  3. Short Summary of Case
                </span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px] whitespace-pre-wrap break-words">{dataBlue?.ShortSummary}</span>
              </div>

              <span className="text-[11px] font-semibold">
                4. Outcome of Code Blue
              </span>
              <div className=" grid grid-cols-3">
                <div className="  items-center flex gap-2">
                  <span className="text-[11px] font-semibold">
                    Resuscitation Successful
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input
                      type="checkbox"
                      checked={ResuscitationSuccessfulData}
                    />
                  </span>
                </div>
                <div className=" flex gap-2 items-center">
                  <span className="text-[11px] font-semibold">
                    Not Revived
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={NotRevivedData} />
                  </span>
                </div>
              </div>

              <span className="text-[11px] font-semibold">
                5. Current Location of Patient
              </span>
              <div className=" grid grid-cols-3 items-center">
                <div className=" space-x-[40px] flex items-center">
                  <span className="text-[11px] font-semibold">
                    Shifted To ICU
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={ShiftedToICUData} />
                  </span>
                </div>
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">
                    Stabilised in Ward
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={StabilizedInWARDData} />
                  </span>
                </div>
                <div className=" space-x-3 items-center">
                  <span className="text-[11px] font-semibold">
                    Treated On OPD
                  </span>
                  <span className="text-[11px] font-semibold">:</span>
                  <span>
                    <input type="checkbox" checked={dataBlue?.treatedOnOpd} />
                  </span>
                </div>
              </div>
              <div className=" space-x-10">
                <span className="text-[11px] font-semibold">
                  6. Code Team Members
                </span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px] whitespace-pre-wrap break-words">{dataBlue?.CodeTeamMembers}</span>
              </div>
              <div className=" space-x-10">
                <span className="text-[11px] font-semibold">
                  7. Any dificulties encountered while attending to Code Blue
                </span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px] whitespace-pre-wrap break-words">{dataBlue?.Difficulties}</span>
              </div>
              <div className=" space-x-[53px]">
                <span className="text-[11px] font-semibold">
                  8. Overall Comments
                </span>
                <span className="text-[11px] font-semibold">:</span>
                <span className="text-[11px] whitespace-pre-wrap break-words">{dataBlue?.OverallComments}</span>
              </div>
            </div>
          </tbody>
        </table>
      </div>
    </>
  );
}
