import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";

import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonTransactionTable from "../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
import { successAlert } from "../../common/components/Toasts/Toasts";
import {
  DeleteOnIcon,
  EditOnIcon,
 
} from "../../assets/icons/CustomIcons";
import CommonButton from "../../common/components/Buttons/CommonButton";

import {
  getAllSystemMenu,
  savePrivilegeToFunctionality,
  searchPrivilegeByName,
} from "../Services/privilegetofunctionalityServices/PrivilegeToFunctionalityServices";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "65%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};

// Dynamic Actions
const userActions = [
  {
    id: 0,
    permission: "Edit",
    isAction: false,
  },
  {
    id: 1,
    permission: "Delete",
    isAction: true,
  },
  {
    id: 2,
    permission: "Add",
    isAction: false,
  },
];
export default function AddNewFunctinalityToPrivilege(props) {

  /*************** useform schema and destrecutring *****************/
  const schema = yup.object().shape({
    newPrivilege: yup.object().nullable().shape({
      label: yup.string(),
      value: yup.string(),
    }),
    linkWith: yup.object().nullable().shape({
      label: yup.string(),
      value: yup.string(),
    }),
  });

  const defaultValues = {
    newPrivilege: null,
    linkWith: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  let dropdownOptionsObject = {
    linkwith: [],
  };
  /***************state variables *****************/

  const [searchPrivilege, setSearchPrivilege] = React.useState([]);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(
    false
  );
  const [deleteId, setDeleteId] = React.useState("");
  const [finalData, setFinalData] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const [dropdownOptions, setDropdownOptions] = React.useState([]);
  /*****************    other variables ******************/
  let newPrivilegeVal = watch("newPrivilege");
  let linkWithVal = watch("linkWith");
  
  let tempArr = [];
  /*************** useEffects *****************/

  /*************** functions *****************/

  const handleOpenConnfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handlOpenDeleteConfirmation = () => setOpenDeleteConfirmation(true);
  const handleCloseDeleteConfirmatoin = () => setOpenDeleteConfirmation(false);

  function searchNewPrivilege(searchString) {
    
    if (searchString !== "" && searchString !== undefined) {
      searchPrivilegeByName(props?.nodeData?.menuId, searchString)
        .then((response) => response.data)
        .then((res) => {
          setSearchPrivilege(res.result);
        })
        .catch((error) => {
          
        });
    }
  }

  function getSystemMenuDropdownOptions(searchString) {
    
    if (searchString !== undefined && searchString !== "") {
      getAllSystemMenu(searchString)
        .then((response) => response.data)
        .then((res) => {
          
          setDropdownOptions(res.result);
        });
    }
  }

  function newPrivilegeToFunctonality() {
    let obj = {};
    obj.privilege_id =
      newPrivilegeVal?.id !== null ? newPrivilegeVal?.id : null;
    obj.privilege = newPrivilegeVal?.label;
    obj.menuId = linkWithVal?.id !== null ? linkWithVal?.id : null;
    tempArr = [...props?.privilegeToFunctionality];
    tempArr.push(obj);

    
    props?.setPrivilegeToFunctionality(tempArr);
    setValue("newPrivilege", null);
    setValue("linkWith", null);
  }

  const onSubmitDataHandler = (data) => {
    let finalObject = {
      id: props?.nodeData?.menuId,
      privileges: props?.privilegeToFunctionality,
    };
    
    
    handleOpenConnfirmation();
    setFinalData(finalObject);
  };

  function addRecord() {
    savePrivilegeToFunctionality(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleCloseConfirmation();
        props?.handleClose();
        reset();
        props?.fetchAllUserMenu();
        setValue("newPrivilege", null);
        setValue("linkWith", null);
      });
  }

  function editRow(row) {
    
    //   {
    //     "privilege_id": 6,
    //     "privilege": "Cancel",
    //     "menu_id": null
    // }
    setValue("newPrivilege", {
      id: row?.privilege_id,
      value: row?.privilege_id,
      label: row?.privilege,
    });
    setValue("linkWith", {
      id: row?.privilege_id,
      value: row?.privilege_id,
      label: row?.privilege,
    });
  }

  function deleteRow(row) {
    
    let deleteObj = {
      functionalityId: props?.nodeData?.menuId,
      menuId: 0,
      privilege: row?.privilege,
      privilegeId: row?.privilege_id,
    };
    const updateArr = props?.privilegeToFunctionality.filter((item) => {
      

      return item?.privilege_id !== row?.privilege_id;
    });
    
    handleCloseDeleteConfirmatoin();
    props?.setPrivilegeToFunctionality(updateArr);
  }
  
 
  
  function renderActions(row, index, header) {
    return (
      <>
        <div className="flex gap-0 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction === true ? (
                <div className="flex gap-2 cursor-pointer">
                  {actions.permission === "Edit" ? (
                    <EditOnIcon
                      type="button"
                      sx={{ padding: 0 }}
                      onClick={() => {
                        setEdit(true);
                        editRow(row);
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
              {actions.isAction === true ? (
                <div className="flex gap-2 cursor-pointer">
                  {" "}
                  {actions.permission === "Delete" ? (
                    <DeleteOnIcon
                      type="button"
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handlOpenDeleteConfirmation();
                        setDeleteId(row);
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      {/* Model and table name start */}
      <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4 p-2">
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props?.handleClose();
                  props?.previousPrivilegeList();
                  reset();
                }}
              />
            </div>
            <div className="row">
              <h1 className="px-2 py-2 font-bold text-center">
                {props?.nodeData?.label} Privileges
              </h1>
              <form
                onSubmit={handleSubmit(onSubmitDataHandler)}
                className="grid grid-cols-2 md:grid-cols-1 gap-2 md:px-2"
              >
                <div className="py-2 lg:flex md:grid-cols-2 lg:grid-cols-4 gap-3">
                  <div className="w-[400px]">
                    <SearchDropdown
                      control={control}
                      name="newPrivilege"
                      label="Search New Privilege*"
                      placeholder="Search New Privilege*"
                      dataArray={searchPrivilege}
                      isSearchable={true}
                      isClearable={true}
                      searchIcon={true}
                      onChange={searchNewPrivilege}
                      handleInputChange={searchNewPrivilege}
                      error={errors?.newPrivilege}
                    />
                  </div>
                  <div className="w-[400px]">
                    <SearchDropdown
                      menuPlacement="bottom"
                      control={control}
                      name="linkWith"
                      label="Link With*"
                      placeholder="Link With *"
                      searchIcon={true}
                      isSearchable={true}
                      isClearable={true}
                      error={errors.linkWith}
                      dataArray={dropdownOptions}
                      handleInputChange={getSystemMenuDropdownOptions}
                    />
                  </div>

                  <div className="flex gap-4 justify-end">
                    <CommonButton
                      label="Add"
                      type="button"
                      variant="outlined"
                      className="saveButton border    border-[#0B83A5]  text-[#0B83A5]"
                      onClick={() => {
                        if (
                          newPrivilegeVal !== null &&
                          newPrivilegeVal !== ""
                        ) {
                          newPrivilegeToFunctonality();
                        } else {
                          setError("newPrivilege", {
                            type: "custom",
                            message: "Requied",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                {props?.privilegeToFunctionality?.length > 0 ? (
                  <CommonTransactionTable
                    dataResult={props?.privilegeToFunctionality}
                    removeHeaders={[
                      "Id",
                      "id",
                      "active",
                      "privilege_id",
                      "menuId",
                    ]}
                    tableClass="lg:h-250  2xl:h-[250px]  capitalize"
                    highlightRow={false}
                    renderActions={renderActions}
                    populateTable={props?.previousPrivilegeList}
                  />
                ) : (
                  <div className="flex justify-center">
                    <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                      No Records Found...
                    </h3>
                  </div>
                )}

                <div className="flex gap-4 justify-end">
                  {userActions &&
                    userActions.map((actions) => {
                      
                      if (
                        actions.permission === "Add" &&
                        actions.isAction === false
                        // && props?.privilegeToFunctionality?.length > 0
                      ) {
                        return (
                          <>
                            <CommonButton
                              label="Save"
                              type="submit"
                              className="saveButton bg-customGreen text-white"
                            />
                          </>
                        );
                      }
                    })}
                </div>
              </form>

              <CommonBackDrop openBackdrop={props.openBackdrop} />
            </div>
          </Box>
        </Modal>
        {/* Confirmation modal for POST request */}
        <ConfirmationModal
          confirmationOpen={openDeleteConfirmation}
          confirmationHandleClose={handleCloseDeleteConfirmatoin}
          confirmationSubmitFunc={() => {
            deleteRow(deleteId);
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure Want To Delete Privilege  ?"
          confirmationButtonMsg="Confirm"
        />

        {/* Confirmation modal for POST request */}
        <ConfirmationModal
          confirmationOpen={openConfirmation}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg={
            edit === true
              ? "Are You Sure Want To Update Privilege  ?"
              : "Are You Sure Want To Create New Privilege  ?"
          }
          confirmationButtonMsg="Confirm"
        />
      </div>
    </>
  );
}
