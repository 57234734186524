import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//list
export const fetchConsultantList = (doctorId, page, size) => {
  return apiClient.get(
    `/contactManagement/getConsultant/${doctorId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};

//list
export const fetchEmployeeList = (empId, page, size) => {
  return apiClient.get(
    `/contactManagement/getEmployee/${empId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};
//Search
export const fetchEmployeeSearch = (searchString) => {
  return apiClient.get(`/contactManagement/getEmployeeSearch/${searchString}`, {
    headers: authHeader(),
  });
};
//Search
export const fetchDepartmentExtension = () => {
  return apiClient.get(`/contactManagement/getDepartmentExtension`, {
    headers: authHeader(),
  });
};

export const fetchVendorSearch = (searchString) => {
  return apiClient.get(
    `/contactManagement/getVendorSearch/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchVendorsName = (vendorId) => {
  return apiClient.get(`/contactManagement/getVendor/${vendorId}`, {
    headers: authHeader(),
  });
};
