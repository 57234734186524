function getPatientFallAssessment(data, admissionIdVal, patchedNursingInfoVal) {
  //below is the required post object
  let initialNursingAssPTFallAssDto = {
    //local form data
    compParal_Immobiliz: 0, //low fall risk
    hisFallMorethan1in6Months: 0, //high fall risk
    ptexpFallDurHospitalization: 0, //high fall risk
    ptdeemedHighRiskProtocol: 0, //high fall risk
    // addedDate: null,
    //table form data
    johnsHopkinsScoreHeaderOpDtoList: null,

    fallAssId: 0,

    // initNurAssmtId: 0, //need to ask its meaning to backend developer
    score: 0,
    addedBy: 0,
    updatedBy: 0,
    admissionId: 0,
  };

  initialNursingAssPTFallAssDto.admissionId = admissionIdVal;

  let userInfoString = localStorage.getItem("userInfo");

  let userInfoObj = JSON.parse(userInfoString);

  
  let addedByVal = userInfoObj.userId;

  let johnsHopkinsScoreArr = [];

  if (patchedNursingInfoVal !== null) {
    //function for updating
    prepareFallAssessmentObjectForUpdating();
  } else if (patchedNursingInfoVal === null) {
    //function to save data for the first time.
    prepareFallAssessmentObjectForSavingFirstTime();
  }

  //function to prepare the object when updating the patient fall assessment
  function prepareFallAssessmentObjectForUpdating() {
    //send the fallAssId received from the get request.
    initialNursingAssPTFallAssDto.fallAssId =
      patchedNursingInfoVal.data.result.ptFallAssPatchDto.fallAssId;

   

    let requiredHopkinsScoreIdArray =
      patchedNursingInfoVal.data.result.ptFallAssPatchDto
        .hopkinsScoreHeaderPatchDtoList;

    

    // response.data.result.ptFallAssPatchDto.hopkinsScoreHeaderPatchDtoList
    initialNursingAssPTFallAssDto.addedBy = null; //when updating send this as null

    initialNursingAssPTFallAssDto.updatedBy = addedByVal; //send this key when updating.

    //When the tabular data is filled
    // ; then what should be done.
    if (data.highFallRisk.length === 0 && data.lowFallRisk.length === 0) {
      //Age --- scoreHeaderId: 1
      if (data.Age === "60 - 69 years") {
        let obj = {
          scoreHeaderId: 1,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[0].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 1,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[0].id;
        johnsHopkinsScoreArr.push(obj);
      }

      // --- scoreHeaderId: 2
      if (data.Age === "70 -79 years") {
        let obj = {
          scoreHeaderId: 2,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[1].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 2,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[1].id;
        johnsHopkinsScoreArr.push(obj);
      }

      // --- scoreHeaderId: 3
      if (data.Age === "Greater than or equal to 80 years") {
        let obj = {
          scoreHeaderId: 3,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[2].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 3,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[2].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 4
      if (data.FallHistory === "One fall within 6 months before admission") {
        let obj = {
          scoreHeaderId: 4,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[3].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 4,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[3].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 5
      if (data.Elimination === "Incontinence") {
        let obj = {
          scoreHeaderId: 5,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[4].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 5,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[4].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 6
      if (data.Elimination === "Urgency or frequency") {
        let obj = {
          scoreHeaderId: 6,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[5].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 6,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[5].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 7
      if (data.Elimination === "Urgency/frequency and incontinence") {
        let obj = {
          scoreHeaderId: 7,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[6].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 7,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[6].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 8
      if (data.Medications === "On 1 high fall risk drug") {
        let obj = {
          scoreHeaderId: 8,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[7].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 8,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[7].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 9
      if (data.Medications === "On 2 or more high fall risk drugs") {
        let obj = {
          scoreHeaderId: 9,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[8].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 9,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[8].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 10
      if (data.Medications === "Sedated procedure within past 24 hours") {
        let obj = {
          scoreHeaderId: 10,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[9].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 10,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[9].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //Patient Care Equipment
      //--- scoreHeaderId: 11
      if (data.PatientCareEquipment === "One present") {
        let obj = {
          scoreHeaderId: 11,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[10].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 11,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[10].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 12
      if (data.PatientCareEquipment === "Two present") {
        let obj = {
          scoreHeaderId: 12,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[11].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 12,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[11].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 13
      if (data.PatientCareEquipment === "3 or more present") {
        let obj = {
          scoreHeaderId: 13,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[12].id;
        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 13,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[12].id;
        johnsHopkinsScoreArr.push(obj);
      }

      //mobility checkboxes
      //--- scoreHeaderId: 14,15,16
      //for scoreHeaderId 14
      if (data.mobilityOne) {
        let obj = {
          scoreHeaderId: 14,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[13].id;

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 14,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[13].id;

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeaderId 15
      if (data.mobilityTwo) {
        let obj = {
          scoreHeaderId: 15,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[14].id;

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 15,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[14].id;

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeaderId 16
      if (data.mobilityThree) {
        let obj = {
          scoreHeaderId: 16,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[15].id;

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 16,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[15].id;

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 17,18,19
      //for scoreHeaderId 17
      if (data.cognitionOne) {
        let obj = {
          scoreHeaderId: 17,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[16].id;

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 17,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[16].id;

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeaderId 18
      if (data.cognitionTwo) {
        let obj = {
          scoreHeaderId: 18,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[17].id;

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 18,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[17].id;

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeader 19
      if (data.cognitionThree) {
        let obj = {
          scoreHeaderId: 19,
          selectionStatus: true,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[18].id;

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 19,
          selectionStatus: false,
          id: null, //take this from patchedNursingInfoVal state variable
        };

        obj.id = requiredHopkinsScoreIdArray[18].id;

        johnsHopkinsScoreArr.push(obj);
      }

      initialNursingAssPTFallAssDto.johnsHopkinsScoreHeaderOpDtoList =
        johnsHopkinsScoreArr;

      let totalFallAssessmentScore =
        calculateFallAssessmentScore(johnsHopkinsScoreArr);

      initialNursingAssPTFallAssDto.score = totalFallAssessmentScore;
    }

    //When the tabular form is not filled ; then what should be done.
    if (data.highFallRisk.length > 0 || data.lowFallRisk.length > 0) {
      //the tabular form data is sent as null
      initialNursingAssPTFallAssDto.johnsHopkinsScoreHeaderOpDtoList = null;

      //creating the object for low fall risk.
      //if else statement for local form data
      let lowFallRiskArr = data.lowFallRisk;
      if (lowFallRiskArr.length > 0) {
        if (lowFallRiskArr[0] === "complete") {
          initialNursingAssPTFallAssDto["compParal_Immobiliz"] = 1;
        }
      }

      //creating the object for high fall risk.
      //if else statement for local form data
      let highRiskArr = data.highFallRisk;
      let highRiskObj = {};

      if (highRiskArr.length > 0) {
        for (let highRiskString of highRiskArr) {
          highRiskObj[highRiskString] = true;
        }

        if (highRiskObj.hasOwnProperty("history")) {
          initialNursingAssPTFallAssDto.hisFallMorethan1in6Months = 1;
        }

        if (highRiskObj.hasOwnProperty("patient")) {
          initialNursingAssPTFallAssDto.ptexpFallDurHospitalization = 1;
        }

        if (highRiskObj.hasOwnProperty("protocol")) {
          initialNursingAssPTFallAssDto.ptdeemedHighRiskProtocol = 1;
        }

        
      }
    }
  }

  //function to prepare the object when saving for the first time
  function prepareFallAssessmentObjectForSavingFirstTime() {
    //When saving for the first time ; send initialNursingAssPTFallAssDto as 0
    initialNursingAssPTFallAssDto.fallAssId = 0;

    //When saving for the first time
    initialNursingAssPTFallAssDto.addedBy = addedByVal;

    
  

    //When the tabular form is filled ; then what should be done.
    if (data.highFallRisk.length === 0 && data.lowFallRisk.length === 0) {
      //Age --- scoreHeaderId: 1
      if (data.Age === "60 - 69 years") {
        let obj = {
          scoreHeaderId: 1,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 1,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      // --- scoreHeaderId: 2
      if (data.Age === "70 -79 years") {
        let obj = {
          scoreHeaderId: 2,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 2,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      // --- scoreHeaderId: 3
      if (data.Age === "Greater than or equal to 80 years") {
        let obj = {
          scoreHeaderId: 3,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 3,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 4
      if (data.FallHistory === "One fall within 6 months before admission") {
        let obj = {
          scoreHeaderId: 4,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 4,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 5
      if (data.Elimination === "Incontinence") {
        let obj = {
          scoreHeaderId: 5,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 5,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 6
      if (data.Elimination === "Urgency or frequency") {
        let obj = {
          scoreHeaderId: 6,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 6,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 7
      if (data.Elimination === "Urgency/frequency and incontinence") {
        let obj = {
          scoreHeaderId: 7,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 7,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 8
      if (data.Medications === "On 1 high fall risk drug") {
        let obj = {
          scoreHeaderId: 8,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 8,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 9
      if (data.Medications === "On 2 or more high fall risk drugs") {
        let obj = {
          scoreHeaderId: 9,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 9,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 10
      if (data.Medications === "Sedated procedure within past 24 hours") {
        let obj = {
          scoreHeaderId: 10,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 10,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 11
      if (data.PatientCareEquipment === "One present") {
        let obj = {
          scoreHeaderId: 11,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 11,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 12
      if (data.PatientCareEquipment === "Two present") {
        let obj = {
          scoreHeaderId: 12,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 12,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 13
      if (data.PatientCareEquipment === "3 or more present") {
        let obj = {
          scoreHeaderId: 13,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 13,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //mobility checkboxes
      //--- scoreHeaderId: 14,15,16
      //for scoreHeaderId 14
      if (data.mobilityOne) {
        let obj = {
          scoreHeaderId: 14,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 14,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeaderId 15
      if (data.mobilityTwo) {
        let obj = {
          scoreHeaderId: 15,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 15,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeaderId 16
      if (data.mobilityThree) {
        let obj = {
          scoreHeaderId: 16,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 16,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //--- scoreHeaderId: 17,18,19
      //for scoreHeaderId 17
      if (data.cognitionOne) {
        let obj = {
          scoreHeaderId: 17,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 17,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeaderId 18
      if (data.cognitionTwo) {
        let obj = {
          scoreHeaderId: 18,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 18,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

      //for scoreHeader 19
      if (data.cognitionThree) {
        let obj = {
          scoreHeaderId: 19,
          selectionStatus: true,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      } else {
        let obj = {
          scoreHeaderId: 19,
          selectionStatus: false,
          id: 0,
        };

        johnsHopkinsScoreArr.push(obj);
      }

     
      initialNursingAssPTFallAssDto.johnsHopkinsScoreHeaderOpDtoList =
        johnsHopkinsScoreArr;

      let totalFallAssessmentScore =
        calculateFallAssessmentScore(johnsHopkinsScoreArr);

      initialNursingAssPTFallAssDto.score = totalFallAssessmentScore;
    }

    //When the tabular form is not fill.ed ; then what should be done.
    if (data.highFallRisk.length > 0 || data.lowFallRisk.length > 0) {
      initialNursingAssPTFallAssDto.johnsHopkinsScoreHeaderOpDtoList = null;

      //creating the object for low fall risk.
      //if else statement for local form data
      let lowFallRiskArr = data.lowFallRisk;
      if (lowFallRiskArr.length > 0) {
        if (lowFallRiskArr[0] === "complete") {
          initialNursingAssPTFallAssDto["compParal_Immobiliz"] = 1;
        }
      }

      //creating the object for high fall risk.
      //if else statement for local form data
      let highRiskArr = data.highFallRisk;
      let highRiskObj = {};

      if (highRiskArr.length > 0) {
        for (let highRiskString of highRiskArr) {
          highRiskObj[highRiskString] = true;
        }

        if (highRiskObj.hasOwnProperty("history")) {
          initialNursingAssPTFallAssDto.hisFallMorethan1in6Months = 1;
        }

        if (highRiskObj.hasOwnProperty("patient")) {
          initialNursingAssPTFallAssDto.ptexpFallDurHospitalization = 1;
        }

        if (highRiskObj.hasOwnProperty("protocol")) {
          initialNursingAssPTFallAssDto.ptdeemedHighRiskProtocol = 1;
        }

        
      }
    }
  }

  function calculateFallAssessmentScore(johnsHopkinsScoreArrVal) {
   

    let totalScoreVal = 0;

    let calculationReference = [
      {
        scoreHeaderId: 1,
        selectionStatus: false,
        id: 0,
        points: 1,
      },
      {
        scoreHeaderId: 2, //true
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 3,
        selectionStatus: false,
        id: 0,
        points: 3,
      },
      {
        scoreHeaderId: 4, //true
        selectionStatus: false,
        id: 0,
        points: 5,
      },
      {
        scoreHeaderId: 5,
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 6, //true
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 7,
        selectionStatus: false,
        id: 0,
        points: 4,
      },
      {
        scoreHeaderId: 8,
        selectionStatus: false,
        id: 0,
        points: 3,
      },
      {
        scoreHeaderId: 9, //true
        selectionStatus: false,
        id: 0,
        points: 5,
      },
      {
        scoreHeaderId: 10,
        selectionStatus: false,
        id: 0,
        points: 7,
      },
      {
        scoreHeaderId: 11,
        selectionStatus: false,
        id: 0,
        points: 1,
      },
      {
        scoreHeaderId: 12, //true
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 13,
        selectionStatus: false,
        id: 0,
        points: 3,
      },
      {
        scoreHeaderId: 14,
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 15, //true
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 16,
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 17,
        selectionStatus: false,
        id: 0,
        points: 1,
      },
      {
        scoreHeaderId: 18, //true
        selectionStatus: false,
        id: 0,
        points: 2,
      },
      {
        scoreHeaderId: 19, //true
        selectionStatus: false,
        id: 0,
        points: 4,
      },
    ];

    if (johnsHopkinsScoreArrVal[0].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[0].points;
    }

    if (johnsHopkinsScoreArrVal[1].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[1].points;
    }

    if (johnsHopkinsScoreArrVal[2].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[2].points;
    }

    if (johnsHopkinsScoreArrVal[3].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[3].points;
    }

    if (johnsHopkinsScoreArrVal[4].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[4].points;
    }

    if (johnsHopkinsScoreArrVal[5].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[5].points;
    }

    if (johnsHopkinsScoreArrVal[6].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[6].points;
    }

    if (johnsHopkinsScoreArrVal[7].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[7].points;
    }

    if (johnsHopkinsScoreArrVal[8].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[8].points;
    }

    if (johnsHopkinsScoreArrVal[9].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[9].points;
    }

    if (johnsHopkinsScoreArrVal[10].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[10].points;
    }

    if (johnsHopkinsScoreArrVal[11].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[11].points;
    }

    if (johnsHopkinsScoreArrVal[12].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[12].points;
    }

    if (johnsHopkinsScoreArrVal[13].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[13].points;
    }

    if (johnsHopkinsScoreArrVal[14].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[14].points;
    }

    if (johnsHopkinsScoreArrVal[15].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[15].points;
    }

    if (johnsHopkinsScoreArrVal[16].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[16].points;
    }

    if (johnsHopkinsScoreArrVal[17].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[17].points;
    }

    if (johnsHopkinsScoreArrVal[18].selectionStatus) {
      totalScoreVal = totalScoreVal + calculationReference[18].points;
    }

    return totalScoreVal;
  }

  return initialNursingAssPTFallAssDto;
}

export default getPatientFallAssessment;
