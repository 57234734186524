import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../common/components/FormFields/RadioField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { addNewBioWeastBagDetails } from "../../services/bioMedicalWasteServices/BioMedicalWasteServices";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
export default function BioMedicalWasteManagementModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const schema = yup.object().shape({
    weight: yup
      .string()
      .required("Required")
      .matches(/^\d{1,5}(\.\d{1,2})?$/, "Only Digits Allow"),

    bagType: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    quantity: yup
      .string()
      .required("Required")
      .matches(/^\d{1,5}(\.\d{1,2})?$/, "Only Digits Allow"),
  });

  const covidNonCovid = [
    { id: 1, label: "Covid", value: 1 },
    { id: 2, label: "Non Covid", value: 2 },
  ];
  const defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
    transactionDate: new Date(),
    covidNonCovid: 1,
    bagType: null,
    quantity: "",
    weight: "",
  };

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const bagTypeVal = watch("bagType");
  const quantityVal = watch("quantity");
  const weightVal = watch("weight");
  const covidNonCovidVal = watch("covidNonCovid");
  const transactionDateVal = watch("transactionDate");

  const location = useLocation();
  const [finalData, setFinalData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  function postBioMedicalWeaste(obj) {
    addNewBioWeastBagDetails(obj, location.state.menuId, props.privilege)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.populateTable();
        props.handleCloseModal();
        props.setEditResponse(null);
        handleClosePost();
        reset(defaultValues);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postBioMedicalWeaste(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let isCovidVal;
    if (covidNonCovidVal === "1" || covidNonCovidVal === 1) {
      isCovidVal = true;
    } else if (covidNonCovidVal === "2" || covidNonCovidVal === 2) {
      isCovidVal = false;
    }

    let postedObj = {
      bagTypeId: bagTypeVal?.id,
      isCovid: isCovidVal,
      menuId: location.state.menuId,
      privilege: "Create",
      qty: quantityVal,
      weight: weightVal,
      transactionDate: format(
        transactionDateVal,
        "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
      ),
    };
    if (props.editResponse !== null) {
      postedObj.transactionId = data.Id;
    }

    setOpenPost(true);
    setFinalData(postedObj);
  };

  useEffect(() => {
    if (props.editResponse !== null) {
      let isCovid;
      if (props.editResponse?.IsCovid === 1) {
        isCovid = 1;
      }
      if (props.editResponse?.IsNonCovid === 1) {
        isCovid = 2;
      }

      let bagType = {
        id: props.editResponse?.BagTypeId,
        label: props.editResponse?.["Bag Name"],
        value: props.editResponse?.["Bag Name"],
      };

      let defaultValues = {
        Id: props.editResponse?.Id,
        date: props.editResponse?.["Trans Date"],
        BagTypeId: props.editResponse?.BagTypeId,
        bagType: bagType,
        quantity: props.editResponse?.Quantity,
        weight: props.editResponse?.Weight,
        covidNonCovid: isCovid,
        transactionDate: new Date(props.editResponse?.["Trans Date"]),
      };
      reset(defaultValues);
    }
  }, [props.editResponse]);

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[80%] rounded-lg">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleCloseModal();
              reset(defaultValues);
              props.setEditResponse(null);
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <div className="px-2 ml-2 lg:ml-0 font-bold text-gray-800">
              {props.editResponse === null
                ? "Add Bags Detail"
                : "Update Bags Details"}
            </div>
            <hr className="border lg:col-span-3 md:col-span-2 my-2 border-customBlue" />

            <div className="grid grid-cols-3 gap-2 py-2">
              <div className="flex space-x-4 items-center">
                <DatePickerFieldNew
                  control={control}
                  name="transactionDate"
                  label="Transaction Date"
                  value={new Date()}
                  inputFormat="dd-MM-yyyy"
                />
                <div className="w-full">
                  <RadioField
                    label=""
                    name="covidNonCovid"
                    control={control}
                    dataArray={covidNonCovid}
                  />
                </div>
              </div>

              <div className="col-span-2">
                <div className="grid grid-cols-4 items-center space-x-2 ">
                  <div className="col-span-2">
                    <DropdownField
                      control={control}
                      name="bagType"
                      placeholder="Bag Type"
                      dataArray={props.bagType}
                      isSearchable={true}
                      isClearable={true}
                      error={errors.bagType}
                    />
                  </div>
                  <div className="col-span-2 flex space-x-2">
                    <InputField
                      label="Quantity"
                      name="quantity"
                      type="number"
                      variant="outlined"
                      control={control}
                      error={errors.quantity}
                    />

                    <InputField
                      label="Weight"
                      name="weight"
                      type="number"
                      variant="outlined"
                      control={control}
                      error={errors.weight}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex space-x-2 justify-end ">
              <CommonButton
                className="border border-customRed text-customRed"
                label="Reset"
                type="button"
                onClick={() => {
                  reset(defaultValues);
                  props.setEditResponse(null);
                }}
              />
              {props.editResponse === null ? (
                <CommonButton
                  className="bg-customGreen text-white"
                  label="Save"
                  type="submit"
                />
              ) : (
                <CommonButton
                  className="bg-customGreen text-white"
                  label="Update"
                  type="submit"
                />
              )}
            </div>
          </form>
          {/* <CommonBackDrop openBackdrop={openBackdrop} /> */}
        </Box>
      </Modal>

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure you want to add this record ?"
        // Do You Want To Save OPD Case Paper Register?
        confirmationButtonMsg="Save"
      />
    </div>
  );
}
