import React, { useEffect } from "react";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import RadioField from "../../../../common/components/FormFields/RadioField";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { useState } from "react";
import {
  getPhysioListOfOrder,
  searchPhysioPatientAutoComplete,
  getPhysioListOfTests,
  readPhysioTest,
  refusePhysioTest,
} from "../../services/PhysioWorkOrderViewServices";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { Box, Modal, TextField, Tooltip } from "@mui/material";
import { ModalStyle } from "../../../../common/components/ModalStyle";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import PhysioNoteModal from "./PhysioNoteModal";
import {
  InCompleteIcon,
  CompleteIcon,
  RefuseIcon,
  NotRefuseIcon,
  NoteIcon,
  NoteDisableIcon,
} from "../../../../assets/icons/InvestigationIcon";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { useLocation } from "react-router-dom";
const PatientType = [
  { id: 0, value: "OPD", label: "OPD" },
  { id: 1, value: "IPD", label: "IPD" },
  { id: 2, value: "All", label: "All" },
];

const Status = [
  { id: 0, value: "Pending", label: "Pending" },
  { id: 1, value: "Completed", label: "Completed" },
  { id: 2, value: "All", label: "All" },
];

const TestStatus = [
  { id: 1, value: "Completed", label: "Completed" },
  { id: 0, value: "Pending", label: "Pending" },
  { id: 2, value: "Refuse", label: "Refuse" },
  { id: 3, value: "All", label: "All" },
];
const PhysioWorkOrderView = (props) => {
  const { updateComponent } = props;
  const { control, watch, setValue } = useForm({
    defaultValues: {
      patientType: 2,
      orderStatus: 2,
      testStatus: 3,
      cancelledOrders: false,
      admittedPatients: true,
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");
  let getPatientType = Number(watch("patientType"));
  let getOrderStatus = Number(watch("orderStatus"));
  let getTestStatus = Number(watch("testStatus"));
  let getCancelledOrders = watch("cancelledOrders");
  let getAdmittedPatients = watch("admittedPatients");

  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [testList, setTestList] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchId, setSearchId] = useState(null);
  const [readConfirmation, setReadConfirmation] = useState(false);
  const [refuseConfirmation, setRefuseConfirmation] = useState(false);
  const [openRefuseModal, setOpenRefuseModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [refuseReason, setRefuseReason] = useState("");
  const [viewNoteDetails, setViewNoteDetails] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [testLoadingSpinner, setTestLoadingSpinner] = useState(false);
  const [privilege, setPrivilege] = useState("");

  function populateTable(forPagination) {
    let listObj = {
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      admittedPatients: getAdmittedPatients === true ? 1 : 0,
      fromDate: getFromDate,
      isViewCancelOrders: getCancelledOrders === true ? 1 : 0,
      opd_Ipd_External: getPatientType === 0 ? 0 : getPatientType === 1 ? 1 : 2,
      orderStatus: getOrderStatus,
      patientId: searchId ? searchId : null,
      toDate: getToDate,
    };
    setLoadingSpinner(true);
    getPhysioListOfOrder(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res?.result]);
          setLoadingSpinner(false);
        } else {
          setDataResult(res?.result);
          setPage(0);
          setLoadingSpinner(false);
        }
        setCount(res.count);
        // setUserActions(res.actions);
      });
  }

  function handleSelectedRow(row) {
    setValue("testStatus", 3);
    setSelectedRow(row);
    getPhysioListOfTests(row?.OrderId, 3, 0).then((response) => {
      setTestList(response.data.result);
    });
  }

  function physioTestListTable(testStatus) {
    setTestLoadingSpinner(true);
    getPhysioListOfTests(
      selectedRow?.OrderId,
      testStatus ? testStatus : getTestStatus,
      0
    ).then((response) => {
      setTestList(response.data.result);
      setTestLoadingSpinner(false);
    });
  }

  function handleChange(autoSearchString) {
    let searchObj = {
      fromDate: getFromDate,
      opdIpd: getPatientType,
      searchString: autoSearchString,
      toDate: getToDate,
    };
    searchPhysioPatientAutoComplete(searchObj).then((response) => {
      setOptions(response.data.result);
    });
  }

  function autoSelectedValue(value) {
    if (value !== null) {
      setSearchId(value?.id);
    } else {
      setSearchId(null);
    }
  }

  function readPhysiotherapyTest() {
    readPhysioTest(selectedTest?.OrderDtlsId, 339, privilege).then(
      (response) => {
        physioTestListTable();
        successAlert(response.data.message);
        setSelectedTest(null);
        setReadConfirmation(false);
      }
    );
  }

  function refusePhysiotherapyTest() {
    refusePhysioTest(
      selectedTest?.OrderDtlsId,
      refuseReason,
      339,
      privilege
    ).then((response) => {
      physioTestListTable();
      successAlert(response.data.message);
      setSelectedTest(null);
      setRefuseConfirmation(false);
      setOpenRefuseModal(false);
    });
  }

  function renderTestInput(row, index, header) {
    return (
      <fieldset>
        {userActions?.map((action) => {
          return (
            <>
              {action.action === "Test Read" && action.isAction === true && (
                <>
                  {header === "Test Read" && (
                    <button
                      type="button"
                      disabled={row["Test Read"] === 1}
                      onClick={() => {
                        if (selectedRow?.IsDischargeInitiated !== true) {
                          if (
                            testList.some(
                              (val) =>
                                val["Test Read"] === 1 &&
                                val["Test Complete"] === 0 &&
                                val["IsTestRefused"] === 0
                            )
                          ) {
                            warningAlert("Complete First Running Test...!");
                          } else {
                            setSelectedTest(row);
                            setReadConfirmation(true);
                            setPrivilege(action.action);
                          }
                        } else {
                          warningAlert(
                            "Discharge initiated contact billing department"
                          );
                        }
                      }}
                    >
                      {row["Test Read"] === 1 ? (
                        <CompleteIcon />
                      ) : (
                        <InCompleteIcon />
                      )}
                    </button>
                  )}
                </>
              )}
              {action.action === "Test Complete" &&
                action.isAction === true && (
                  <>
                    {header === "Test Complete" && (
                      <button
                        type="button"
                        disabled={row["Test Complete"] === 1}
                        onClick={() => {
                          if (selectedRow?.IsDischargeInitiated !== true) {
                            if (
                              row["Test Read"] === 1 &&
                              row["IsTestRefused"] === 0
                            ) {
                              setSelectedTest(row);
                              setOpenNoteModal(true);
                              setPrivilege(action.action);
                            } else if (row["Test Read"] === 0) {
                              warningAlert("Please Read Test First...!");
                            } else {
                              warningAlert("Test Refused...!");
                            }
                          } else {
                            warningAlert(
                              "Discharge initiated contact billing department"
                            );
                          }
                        }}
                      >
                        {row["Test Complete"] === 1 ? (
                          <CompleteIcon />
                        ) : (
                          <InCompleteIcon />
                        )}
                      </button>
                    )}
                  </>
                )}
              {action.action === "Test Refused" && action.isAction === true && (
                <>
                  {header === "IsTestRefused" && (
                    <button
                      type="button"
                      disabled={row["IsTestRefused"] === 1}
                      onClick={() => {
                        if (
                          row["Test Read"] === 1 &&
                          row["Test Complete"] === 0
                        ) {
                          setSelectedTest(row);
                          setOpenRefuseModal(true);
                          setPrivilege(action.action);
                        } else if (row["Test Read"] === 0) {
                          warningAlert("Please Read Test First...!");
                        } else {
                          warningAlert("Test Completed...!");
                        }
                      }}
                    >
                      {row["IsTestRefused"] === 1 ? (
                        <RefuseIcon />
                      ) : (
                        <NotRefuseIcon />
                      )}
                    </button>
                  )}
                </>
              )}
              {action.action === "Note Exist" && action.isAction === true && (
                <>
                  {header === "IsNoteExist" && (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedTest(row);
                        setOpenNoteModal(true);
                        setViewNoteDetails(true);
                      }}
                      disabled={row["IsNoteExist"] !== 1}
                    >
                      {row["IsNoteExist"] === 1 ? (
                        <NoteIcon />
                      ) : (
                        <NoteDisableIcon />
                      )}
                    </button>
                  )}
                </>
              )}
            </>
          );
        })}
      </fieldset>
    );
  }

  useEffect(() => {
    populateTable();
  }, []);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    if (updateComponent === true) {
      console.log("updateComponent123", updateComponent);
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
      populateTable();
    }
  }, [updateComponent]);

  return (
    <form className="space-y-2">
      <div className="mt-16 mb-2 text-lg font-semibold text-center w-full">
        Physiotherapy Work Order View
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-3 gap-x-2">
        <div>
          <SearchBar
            placeholder="Search By Patient Name / MRNo"
            name="searchBar"
            searchIcon={true}
            handleInputChange={handleChange}
            onChange={autoSelectedValue}
            dataArray={options}
          />
        </div>
        <div className="flex space-x-2 items-center">
          <label className="text-md font-semibold">Patient Type :&nbsp;</label>
          <div className="mt-1.5">
            <RadioField
              control={control}
              name="patientType"
              dataArray={PatientType}
            />
          </div>
        </div>
        <div className="flex space-x-2 items-center -ml-2">
          <label className="text-md font-semibold">Status :&nbsp;</label>
          <div className="mt-1.5">
            <RadioField
              control={control}
              name="orderStatus"
              dataArray={Status}
            />
          </div>
        </div>
        <div className="flex space-x-2">
          <DatePickerFieldNew
            control={control}
            name="fromDate"
            label="From Date "
            value={new Date()}
            size="small"
            inputFormat="dd-MM-yyyy"
          />
          <DatePickerFieldNew
            control={control}
            name="toDate"
            label="To Date "
            value={new Date()}
            size="small"
            inputFormat="dd-MM-yyyy"
          />
        </div>
        <div className="flex justify-between">
          <CheckBoxField
            control={control}
            name="cancelledOrders"
            label="Cancelled Orders"
          />
          <CheckBoxField
            control={control}
            name="admittedPatients"
            label="Admitted Patients"
          />
        </div>
        <CommonButton
          searchIcon={true}
          className="bg-customBlue text-white"
          onClick={() => {
            populateTable();
          }}
        />
      </div>
      <div>
        <label className="text-md font-semibold">List Of Orders</label>
        <div>
          {!loadingSpinner ? (
            <>
              {dataResult !== undefined && dataResult?.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={dataResult}
                  populateTable={populateTable}
                  // page={page}
                  // setPage={setPage}
                  // rowsPerPage={rowsPerPage}
                  // count={count}
                  handleSelectedRow={handleSelectedRow}
                  tableClass={"h-80"}
                  removeHeaders={[
                    "OrderId",
                    "Time",
                    "Opd_Ipd_External",
                    "Opd_Ipd_External_Id",
                  ]}
                />
              ) : (
                <div className="justify-center items-center font-semibold md:h-52 2xl:h-72 w-full flex border-2 border-gray-300 rounded my-2">
                  No Records Found...
                </div>
              )}
            </>
          ) : (
            <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="flex space-x-10 items-center">
          <label className="text-md font-semibold">List Of Tests</label>
          <div className="flex space-x-2 items-center">
            <label className="text-sm font-semibold">Test Status :&nbsp;</label>
            <fieldset
              className="mt-1.5"
              onChange={(e) => {
                physioTestListTable(e.target.value);
              }}
            >
              <RadioField
                control={control}
                name="testStatus"
                dataArray={TestStatus}
              />
            </fieldset>
          </div>
        </div>
        <div>
          {!testLoadingSpinner ? (
            <>
              {testList !== undefined && testList?.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={testList}
                  renderInput={renderTestInput}
                  editableColumns={[
                    "Test Read",
                    "Test Complete",
                    "IsTestRefused",
                    "IsNoteExist",
                  ]}
                  highlightRow={false}
                  tableClass={"h-72"}
                  removeHeaders={[
                    "OrderId",
                    "ChargeId",
                    "Test Charges",
                    "OrderDtlsId",
                  ]}
                />
              ) : (
                <div className="justify-center items-center font-semibold md:h-52 2xl:h-72 w-full flex border-2 border-gray-300 rounded my-2">
                  No Records Found...
                </div>
              )}
            </>
          ) : (
            <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
      <Modal open={openRefuseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            maxHeight: "50%",
            overflowY: "scroll",
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 20,
            p: 2,
          }}
        >
          <div className="">
            <div>
              <button
                onClick={() => {
                  setOpenRefuseModal(false);
                  setPrivilege("");
                }}
              >
                <CancelPresentationIconButton />
              </button>
            </div>
            <div className="w-full space-y-2">
              <div className="w-full">
                <TextField
                  className="w-full"
                  name="refuseReason"
                  label="Refuse Reason"
                  minRows={2}
                  maxRows={2}
                  size="small"
                  multiline
                  onChange={(e) => {
                    setRefuseReason(e.target.value);
                  }}
                />
              </div>
              <div className="flex justify-end">
                <CommonButton
                  type="button"
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={() => {
                    setRefuseConfirmation(true);
                  }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <PhysioNoteModal
        openNoteModal={openNoteModal}
        setOpenNoteModal={setOpenNoteModal}
        physioTestListTable={physioTestListTable}
        selectedTest={selectedTest}
        setSelectedTest={setSelectedTest}
        viewNoteDetails={viewNoteDetails}
        setViewNoteDetails={setViewNoteDetails}
        privilege={privilege}
        setPrivilege={setPrivilege}
        getPatientType={getPatientType}
        selectedRow={selectedRow}
      />
      <ConfirmationModal
        confirmationOpen={readConfirmation}
        confirmationHandleClose={() => {
          setReadConfirmation(false);
        }}
        confirmationSubmitFunc={readPhysiotherapyTest}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to read test ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={refuseConfirmation}
        confirmationHandleClose={() => {
          setRefuseConfirmation(false);
        }}
        confirmationSubmitFunc={refusePhysiotherapyTest}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to refuse test ?"
        confirmationButtonMsg="Yes"
      />
    </form>
  );
};

export default PhysioWorkOrderView;

