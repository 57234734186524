import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DeleteOnIcon, EditOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import CreateableSelect from "../../../../common/components/FormFields/CreateableSelect";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  fetchDrugDose,
  fetchDrugInstructions,
  fetchDrugName,
  fetchDrugRoute,
} from "../../../../ipd/services/dischargeSummary/DischargeSummaryServices";
// import { fetchDrugDose } from "../../../../ipd/services/dischargeSummary/DischargeSummaryServices";

let medicationHeaders = [
  {
    "Item Name": "",
    Dose: "",
    Route: "",
    Frequency: "",
    Instructions: "",
  },
];

const DrugType = [
  {
    id: "1",
    value: "brandName",
    label: "Brand Name",
  },
  {
    id: "2",
    value: "generic",
    label: "Generic",
  },
];

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "65%",
  // maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 2,
  px: 2,
};
const schema = yup.object().shape({
  // drugName
  drugName: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  // dosage
  dosage: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  // instructions
  // instructions: yup
  //   .object()
  //   .nullable()
  //   .shape({
  //     value: yup.string().required("Required"),
  //     label: yup.string().required("Required"),
  //   })
  //   .required("Required"),

  // route
  route: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  // duration
  duration: yup.string().nullable().required("Required"),
});
export default function MedicationPrescriptionModal(props) {
  const {
    open,
    handleClose,
    setDeletedMedicationData,
    allMedicationData,
    setAllMedicationData,
  } = props;
  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
  const [drugName, setDrugName] = useState();
  const [drugDose, setDrugDose] = useState();
  const [drugInstruction, setDrugInstrucForm] = useState();
  const [drugRoute, setDrugRoute] = useState();
  const [editIndex, setEditIndex] = useState(null);

  const [drugSearchString, setDrugSearchString] = useState();
  const [serviceheaders, setServiceHeaders] = React.useState([
    // "id",
    "Item Name",
    "Dose",
    "Route",
    "Frequency",
    "Instructions",
  ]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      // drugType: "",
      drugName: "",
      dosage: "",
      duration: "",
      genericName: "",
      quantity: "",
      refill: "",
      form: "",
      instructions: "",
      route: "",
      remark: "",
      startDate: "",
      isCurrentMedicine: false,
    },
  });
  const {
    formState: { errors },
    control,
  } = methods;

  const onsubmit = (data) => {
    let itemObj = {
      ItemName: data.drugName.label,
      Frequency: data.dosage.label,
      Dose: data.duration,
      Route: data.route.label,
      Instruction: data.instructions,
      IsCurrentMedicine: data.isCurrentMedicine,
      opdMedicationId: 0,
      isDelete: false,
    };

    let updatedData = [...allMedicationData];
    if (editIndex !== null) {
      let opdMedicationId = updatedData[editIndex].opdMedicationId;
      itemObj.opdMedicationId = opdMedicationId;
      updatedData[editIndex] = itemObj;
      setEditIndex(null);
    } else {
      updatedData.push(itemObj);
    }

    setAllMedicationData(updatedData);
    methods.reset();
  };

  useEffect(() => {
    getDrugDose();
    getDrugName();
    getDrugInstruction();
    getDrugRoute();
  }, [drugSearchString]);

  //   DrugName
  const getDrugName = () => {
    fetchDrugName(drugSearchString)
      .then((response) => response.data)
      .then((res) => {
        setDrugName(res.result);
      });
  };
  // drugDose
  const getDrugDose = () => {
    fetchDrugDose().then((response) => {
      setDrugDose(response.data.result);
    });
  };

  //   drugInstruction
  const getDrugInstruction = () => {
    fetchDrugInstructions()
      .then((response) => response.data)
      .then((res) => {
        setDrugInstrucForm(res.result);
      });
  };

  //   drugRoute
  const getDrugRoute = () => {
    fetchDrugRoute()
      .then((response) => response.data)
      .then((res) => {
        setDrugRoute(res.result);
      });
  };

  const renderActions = (row, index) => {
    const handleEditRow = (row, index) => {
      methods.setValue("drugName", {
        value: row.ItemName,
        label: row.ItemName,
      });
      methods.setValue("dosage", {
        value: row.Frequency,
        label: row.Frequency,
      });
      methods.setValue("duration", row.Dose);
      methods.setValue("route", { value: row.Route, label: row.Route });
      methods.setValue("instructions", row.Instruction);
      methods.setValue("isCurrentMedicine", row.IsCurrentMedicine);
      setEditIndex(index);
    };

    const handleDeleteRow = (row, rowIndex) => {
      let temp = [...allMedicationData];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === rowIndex ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedMedicationData((prev) => [...prev, ...deletedItems]);
      setAllMedicationData(notDeletedItems);
    };

    return (
      <>
        <div>
          {allMedicationData.length > 0 ? (
            <>
              <EditOnIcon
                title={"Edit"}
                onClick={() => handleEditRow(row, index)}
              />
              <DeleteOnIcon
                title={"Delete"}
                onClick={() => handleDeleteRow(row, index)}
              />
            </>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form onSubmit={methods.handleSubmit(onsubmit)}>
            <div>
              <div className="text-black font-bold text-lg pb-3">
                Prescription
              </div>
              <div className="grid md:grid-cols-6 lg:grid-cols-4 gap-3">
                {/* drugName */}
                <div className="md:col-span-3 lg:col-span-1">
                  <CreateableSelect
                    name="drugName"
                    placeholder="Search Item"
                    dataArray={drugName}
                    onInputChange={(e) => {
                      setDrugSearchString(e);
                    }}
                    menuPlacement={"bottom"}
                    searchIcon={true}
                    error={errors.drugName}
                    control={control}
                    isClearable={true}
                  />
                </div>

                {/* dosage */}
                <div className="md:col-span-3 lg:col-span-1">
                  <DropdownField
                    control={control}
                    error={errors.dosage}
                    name="dosage"
                    placeholder="Frequency *"
                    dataArray={drugDose}
                    isSearchable={false}
                    menuPlacement={"bottom"}
                  />
                </div>

                {/* duration/frequncy */}
                <div className="md:col-span-3 lg:col-span-1">
                  <InputField
                    name="duration"
                    variant="outlined"
                    label="Dosage * "
                    error={errors.duration}
                    control={control}
                    // disabled={isDisabled}
                    inputProps={{ maxLength: 100 }}
                  />
                </div>

                {/* route */}
                <div className="md:col-span-3 lg:col-span-1">
                  <DropdownField
                    control={control}
                    error={errors.route}
                    name="route"
                    placeholder="Route*"
                    dataArray={drugRoute}
                    isSearchable={false}
                    menuPlacement={"bottom"}
                  />
                </div>

                {/* instructions */}
                <div className="md:col-span-3 lg:col-span-1">
                  <InputField
                    control={control}
                    // error={errors.instructions}
                    label="Instructions"
                    name="instructions"
                    isSearchable={false}
                  />
                </div>

                <div className="md:col-span-2 lg:col-span-1">
                  <CheckBoxField
                    control={control}
                    name="isCurrentMedicine"
                    label="Is Current Medicine"
                  />
                </div>

                <div className="flex justify-end lg:col-span-2 md:col-span-1">
                  <div>
                    <CommonButton
                      label={editIndex !== null ? "Update" : "Add"}
                      type="submit"
                      // onClick={() => handleClose()}
                      className="bg-customGreen text-white"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <CommonDynamicTableNew
                  dataResult={
                    allMedicationData.length > 0
                      ? allMedicationData
                      : medicationHeaders
                  }
                  setAllMedicationData={setAllMedicationData}
                  renderActions={renderActions}
                  removeHeaders={[
                    "ptMedicationId",
                    "isDelete",
                    "opdMedicationId",
                  ]}
                  tableClass="h-52"
                  highlightRow={false}
                />
              </div>
              <div className="flex justify-end py-3">
                <CommonButton
                  label="Save"
                  type="submit"
                  onClick={() => {
                    handleClose();
                  }}
                  className="bg-customGreen text-white"
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
