function getBedScoreAssesment(data) {
  

  //Bed score assessment
  let initialNursingAssBedScoreAssDto = {
    //sensoryPerception
    sensoryPerCompletelyLimited: false,
    sensoryPerNoImpairment: false,
    sensoryPerSlightlyLimited: false,
    sensoryPerVeryLimited: false,

    //moisture
    moistureCompletelyLimited: false,
    moistureOccasionallyLimited: false,
    moistureVeryLimited: false,
    rarelyMoist: false,

    //activity
    activeBedLying: false,
    activeChairDependent: false,
    activeWalksFrequency: false,
    activeWalksOccasionally: false,

    //mobility
    mobilityCompletelyLimited: false,
    mobilityNoImpairment: false,
    mobilitySlightlyLimited: false,
    mobilityVeryLimited: false,

    //nutrition
    nutritionAdequate: false,
    nutritionExcellent: false,
    nutritionProbablyInadequate: false,
    nutritionVeryPoor: false,

    //frictionAndShear
    frictionAndShearNoApparentProblem: false,
    frictionAndShearPotentialProblem: false,
    frictionAndShearProblem: false,

    highRisk: false,
    lowRisk: false,
    noRisk: false,
    totalScore: 0,
    painAssessment: "",
  };

  calculateBedScoreAssesment(data);

  //function to calculate the bedscoreAssessment.
  function calculateBedScoreAssesment(data) {
    

    let totalBedAssessmentScore = 0;

    //sensory perception
    if (
      typeof data.sensoryPerCompletelyLimited === "string" ||
      data.sensoryPerCompletelyLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 1;

      initialNursingAssBedScoreAssDto.sensoryPerCompletelyLimited = true;
    }

    if (
      typeof data.sensoryPerNoImpairment === "string" ||
      data.sensoryPerNoImpairment === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 4;

      initialNursingAssBedScoreAssDto.sensoryPerNoImpairment = true;
    }

    if (
      typeof data.sensoryPerSlightlyLimited === "string" ||
      data.sensoryPerSlightlyLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 3;

      initialNursingAssBedScoreAssDto.sensoryPerSlightlyLimited = true;
    }

    if (
      typeof data.sensoryPerVeryLimited === "string" ||
      data.sensoryPerVeryLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 2;

      initialNursingAssBedScoreAssDto.sensoryPerVeryLimited = true;
    }

    //moisture options
    if (
      typeof data.moistureCompletelyLimited === "string" ||
      data.moistureCompletelyLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 1;

      initialNursingAssBedScoreAssDto.moistureCompletelyLimited = true;
    }

    if (
      typeof data.moistureOccasionallyLimited === "string" ||
      data.moistureOccasionallyLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 3;

      initialNursingAssBedScoreAssDto.moistureOccasionallyLimited = true;
    }

    if (
      typeof data.moistureVeryLimited === "string" ||
      data.moistureVeryLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 2;

      initialNursingAssBedScoreAssDto.moistureVeryLimited = true;
    }

    if (typeof data.rarelyMoist === "string" || data.rarelyMoist === true) {
      totalBedAssessmentScore = totalBedAssessmentScore + 4;

      initialNursingAssBedScoreAssDto.rarelyMoist = true;
    }

    //active options
    if (
      typeof data.activeBedLying === "string" ||
      data.activeBedLying === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 1;

      initialNursingAssBedScoreAssDto.activeBedLying = true;
    }

    if (
      typeof data.activeChairDependent === "string" ||
      data.activeChairDependent === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 2;

      initialNursingAssBedScoreAssDto.activeChairDependent = true;
    }

    if (
      typeof data.activeWalksFrequency === "string" ||
      data.activeWalksFrequency === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 4;

      initialNursingAssBedScoreAssDto.activeWalksFrequency = true;
    }

    if (
      typeof data.activeWalksOccasionally === "string" ||
      data.activeWalksOccasionally === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 3;

      initialNursingAssBedScoreAssDto.activeWalksOccasionally = true;
    }

    //mobility options
    if (
      typeof data.mobilityCompletelyLimited === "string" ||
      data.mobilityCompletelyLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 1;

      initialNursingAssBedScoreAssDto.mobilityCompletelyLimited = true;
    }

    if (
      typeof data.mobilityNoImpairment === "string" ||
      data.mobilityNoImpairment === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 4;

      initialNursingAssBedScoreAssDto.mobilityNoImpairment = true;
    }

    if (
      typeof data.mobilitySlightlyLimited === "string" ||
      data.mobilitySlightlyLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 3;

      initialNursingAssBedScoreAssDto.mobilitySlightlyLimited = true;
    }

    if (
      typeof data.mobilityVeryLimited === "string" ||
      data.mobilityVeryLimited === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 2;

      initialNursingAssBedScoreAssDto.mobilityVeryLimited = true;
    }

    //nutrition options
    if (
      typeof data.nutritionAdequate === "string" ||
      data.nutritionAdequate === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 3;

      initialNursingAssBedScoreAssDto.nutritionAdequate = true;
    }

    if (
      typeof data.nutritionExcellent === "string" ||
      data.nutritionExcellent === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 4;

      initialNursingAssBedScoreAssDto.nutritionExcellent = true;
    }

    if (
      typeof data.nutritionProbablyInadequate === "string" ||
      data.nutritionProbablyInadequate === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 2;

      initialNursingAssBedScoreAssDto.nutritionProbablyInadequate = true;
    }

    if (
      typeof data.nutritionVeryPoor === "string" ||
      data.nutritionVeryPoor === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 1;

      initialNursingAssBedScoreAssDto.nutritionVeryPoor = true;
    }

    //friction options
    if (
      typeof data.frictionAndShearNoApparentProblem === "string" ||
      data.frictionAndShearNoApparentProblem === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 4;

      initialNursingAssBedScoreAssDto.frictionAndShearNoApparentProblem = true;
    }

    if (
      typeof data.frictionAndShearPotentialProblem === "string" ||
      data.frictionAndShearPotentialProblem === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 2;

      initialNursingAssBedScoreAssDto.frictionAndShearPotentialProblem = true;
    }

    if (
      typeof data.frictionAndShearProblem === "string" ||
      data.frictionAndShearProblem === true
    ) {
      totalBedAssessmentScore = totalBedAssessmentScore + 1;

      initialNursingAssBedScoreAssDto.frictionAndShearProblem = true;
    }

    
    initialNursingAssBedScoreAssDto.totalScore = totalBedAssessmentScore;

    if (totalBedAssessmentScore >= 0 && totalBedAssessmentScore <= 60) {
      initialNursingAssBedScoreAssDto.painAssessment = "No Risk";

      initialNursingAssBedScoreAssDto.noRisk = true;
    }

    if (totalBedAssessmentScore >= 61 && totalBedAssessmentScore <= 100) {
      initialNursingAssBedScoreAssDto.painAssessment = "Low Risk";

      initialNursingAssBedScoreAssDto.lowRisk = true;
    }

    if (totalBedAssessmentScore >= 100) {
      initialNursingAssBedScoreAssDto.painAssessment = "High Risk";

      initialNursingAssBedScoreAssDto.highRisk = true;
    }
  }

  return initialNursingAssBedScoreAssDto;
}

export default getBedScoreAssesment;
