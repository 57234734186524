import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const saveAnswerReviewScale = (postObj) => {
  return apiClient.post(`/masters/saveAnswerPatternScale`, postObj, {
    headers: authHeader(),
  });
};

export const getAnswerPatternList = (postObj) => {
  return apiClient.post(`/masters/getAnswerPatternList`, postObj, {
    headers: authHeader(),
  });
};

export const deleteAnswerPatternScale = (id, employeeId) => {
  return apiClient.delete(
    `/masters/deleteAnswerPatternScale/${id}/${employeeId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getAnswerReviewTypeAutoComplete = (searchString) => {
  return apiClient.get(
    `/masters/getAnswerReviewTypeAutocomplete/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};