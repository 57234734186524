import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getWorkOrderList = (supplierId) => {
  return apiClient.get(`/invWorkOrderGrn/workOrderList/${supplierId}`, {
    headers: authHeader(),
  });
};
export const getAccHeadList = () => {
  return apiClient.get(`/invWorkOrderGrn/accountHead`, {
    headers: authHeader(),
  });
};

export const getWorkOrderItemList = (obj) => {
  return apiClient.post(`/invWorkOrderGrn/itemDetailsList`, obj, {
    headers: authHeader(),
  });
};

export const postWorkOrderGrn = async (searchObj) => {
  return await apiClient.post(`/invWorkOrderGrn/saveWorkOrderGRN`, searchObj, {
    headers: authHeader(),
  });
};

export const getWorkOrderGRNList = (postObj) => {
  return apiClient.post(`/invWorkOrderGrn/workOrderGRNList`, postObj, {
    headers: authHeader(),
  });
};

export const getWorkOrderGRNDetailsList = (postObj) => {
  return apiClient.post(`/invWorkOrderGrn/workOrderGRNDetailsList`, postObj, {
    headers: authHeader(),
  });
};

export const fetchPrintWorkOrderList = (workOrderGrnId) => {
  return apiClient.get(`/reports/inventory/WorkOrderGRN/${workOrderGrnId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};
