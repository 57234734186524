export const NextIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="rotate(-180 12 12)"
          fill="#0B83A5"
        />
        <path
          d="M10 17L14.3382 11.9768C14.7024 11.5552 14.6517 10.9172 14.2255 10.5583L10 7"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    );
  };