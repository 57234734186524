import React, { useState } from "react";
import { pathParameterDropdownSearch } from "../../../services/pathology/Test/TestCreationService";
import { useFormContext } from "react-hook-form";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import InputField from "../../../../common/components/FormFields/InputField";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import { FormControl, TableCell, Tooltip } from "@mui/material";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
export default function ParameterTab(props) {
  const {
    control,
    watch,
    handleSubmit,
    register,
    reset,
    setValue,
    errors,
    getValues,
  } = useFormContext();
  const [pathParameterOption, setpathParameterOption] = useState([]);
  const [hoverIndexes, setHoverIndexes] = useState({});

  let parameterNamehasWatch = watch("parameterName");
  let parameterNameWatch = watch("parameterNamehas");
  let watchSubTest = watch("hasSubTest");
  let subTestName = watch("subTestName");

  const handleChange = (autoSearchString) => {
    console.log("autoSearchString", autoSearchString);
    autoSearchString &&
      pathParameterDropdownSearch(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          console.log("resItem", res.result);
          setpathParameterOption(res.result);
        });
  };
  const handleOnsubmit = () => {
    let obj = {
      isParameter: true,
      parameterId: parameterNamehasWatch?.id,
      parameterName: parameterNamehasWatch?.label,
    };
    let arr = [];
    arr.push(obj);
    props.setParameterData(arr);
  };
  const handleOnSubTestsubmit = () => {
    let obj = {
      subTestName: subTestName,
      Parameters: [],
    };

    let parameterObj = {
      parameterId: parameterNameWatch?.id,
      parameterName: parameterNameWatch?.label,
    };

    props.setFinalData((prev) => {
      let exists = false;

      const updatedData = prev.map((item) => {
        if (item.subTestName === obj.subTestName) {
          exists = true;
          return {
            ...item,
            Parameters: [...item.Parameters, parameterObj],
          };
        }
        return item;
      });

      if (!exists) {
        updatedData.push({
          subTestName: obj.subTestName,
          Parameters: [parameterObj],
        });
      }

      console.log("updatedData", updatedData);
      return updatedData;
    });
  };

  const handleRenderInputSubTestTable = (row, index, header) => {
    const swapAndSet = (index1, index2) => {
      if (row.Parameters) {
        const updatedParameters = [...row.Parameters];
        [updatedParameters[index1], updatedParameters[index2]] = [
          updatedParameters[index2],
          updatedParameters[index1],
        ];

        const updatedRow = {
          ...row,
          Parameters: updatedParameters,
        };

        props.setFinalData((prevData) => {
          return prevData.map((item, i) => (i === index ? updatedRow : item));
        });
      }
    };

    const swapAndSetSubTest = (index1, index2, parmIndex) => {
      if (row) {
        const updatedRow = [...props.finalData];
        const parameterToMove = updatedRow[index1].Parameters[parmIndex];

        // Remove parameter from source subtest
        updatedRow[index1].Parameters.splice(parmIndex, 1);

        // Add parameter to target subtest
        updatedRow[index2].Parameters.push(parameterToMove);

        props.setFinalData((prevData) => {
          return prevData.map((item, i) => {
            if (i === index1 || i === index2) {
              return updatedRow[i];
            }
            return item;
          });
        });
      }
    };

    const handleUp = (parmIndex) => {
      if (parmIndex > 0) swapAndSet(parmIndex, parmIndex - 1);
    };

    const handleDown = (parmIndex) => {
      if (parmIndex < row.Parameters?.length - 1)
        swapAndSet(parmIndex, parmIndex + 1);
    };

    const handleUpsubTest = (index, parmIndex) => {
      if (index > 0) swapAndSetSubTest(index, index - 1, parmIndex);
    };

    const handleDownsubTest = (index, parmIndex) => {
      if (index < props.finalData.length - 1)
        swapAndSetSubTest(index, index + 1, parmIndex);
    };

    return (
      header === "Parameters" && (
        <div className="grid ">
          {row["Parameters"]?.map((item, parmIndex) => (
            <div
              key={parmIndex}
              className={`grid grid-cols-7 items-center px-3 transition-all duration-150 ${
                hoverIndexes?.subIndex === parmIndex &&
                hoverIndexes?.mainIndex === index
                  ? "bg-gray-100 rounded-lg shadow-md"
                  : "bg-white"
              }`}
              onMouseEnter={() =>
                setHoverIndexes({ mainIndex: index, subIndex: parmIndex })
              }
              onMouseLeave={() => setHoverIndexes({})}
            >
              {/* Swap Icons: show when hovered */}
              <div className="col-span-1 flex justify-center">
                {hoverIndexes?.mainIndex === index && (
                  <>
                    {parmIndex > 0 && (
                      <ArrowUpwardIcon
                        size="small"
                        sx={{ color: "#777577", cursor: "pointer" }}
                        onClick={() => handleUp(parmIndex)}
                      />
                    )}
                    {parmIndex < row.Parameters?.length - 1 && (
                      <ArrowDownwardIcon
                        size="small"
                        sx={{ color: "#777577", cursor: "pointer" }}
                        onClick={() => handleDown(parmIndex)}
                      />
                    )}
                  </>
                )}
              </div>

              {/* Parameter Name */}
              <div className="col-span-5  py-1  truncate">
                {item.parameterName}
              </div>

              {/* Subtest Swap Icons: show when hovered */}
              <div className="col-span-1 flex justify-center">
                {hoverIndexes?.subIndex === parmIndex &&
                  hoverIndexes?.mainIndex === index && (
                    <>
                      {index > 0 && (
                        <Tooltip title="Move Above Group">
                          <ArrowUpwardIcon
                            size="small"
                            sx={{ color: "#777577", cursor: "pointer" }}
                            onClick={() => handleUpsubTest(index, parmIndex)}
                          />
                        </Tooltip>
                      )}
                      {index < props.finalData.length - 1 && (
                        <Tooltip title="Move Below Group">
                          <ArrowDownwardIcon
                            size="small"
                            sx={{ color: "#777577", cursor: "pointer" }}
                            onClick={() => handleDownsubTest(index, parmIndex)}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  //Reset Function parameter
  const handleReset = () => {
    setValue("parameterName", null);
  };

  //Reset Function parameter and Sub Test
  const handleResetParameter = () => {
    setValue("parameterNamehas", null);
    // setValue("subTestName", "");
  };
  const deleteRecord = (index, header) => {
    let numTempleData = [];
    if (index !== null) {
      if (header === "paraData") {
        numTempleData = [...props.parameterData];
        numTempleData.splice(index, 1);
        console.log("numTempleData", numTempleData);
        props.setParameterData(numTempleData);
      } else if (header === "subTest") {
        numTempleData = [...props.finalData];
        numTempleData.splice(index, 1);
        props.setFinalData(numTempleData);
      }
    }
  };
  //Render Action Functon Parameter
  const RenderActions = (row, index, header) => {
    return (
      <div className="flex ">
        <DeleteOnIcon
          title="Delete"
          onClick={() => {
            deleteRecord(index, "paraData");
          }}
        />
      </div>
    );
  };

  //Render Action sub Test and Parameter
  const RenderActionsSubTest = (row, index, header) => {
    return (
      <DeleteOnIcon
        title="Delete"
        onClick={() => {
          deleteRecord(index, "subTest");
        }}
      />
    );
  };
  return (
    <div>
      {" "}
      <div>
        <div className="grid grid-cols-1 md:grid-cols-1 pt-2  px-5 lg:px-2 ">
          <div>
            <CheckBoxField
              control={control}
              name="hasSubTest"
              label="Has Sub Test"
            />
          </div>
        </div>

        <div>
          {watchSubTest ? (
            <>
              <div className="grid grid-cols-3 md:grid-cols-3 gap-3">
                <div>
                  <InputField
                    control={control}
                    label="Sub Test Name"
                    name="subTestName"
                  />
                </div>
                <div>
                  <SearchDropdown
                    control={control}
                    name="parameterNamehas"
                    label="Parameter Name"
                    searchIcon={true}
                    dataArray={pathParameterOption}
                    placeholder="Parameter Name"
                    handleInputChange={handleChange}
                    isClearable={true}
                  />
                </div>
                <div>
                  <CommonButton
                    label=" Add "
                    className="bg-green-500 text-white"
                    type="button"
                    onClick={() => {
                      handleOnSubTestsubmit();
                      handleResetParameter();
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className=" grid grid-cols-2 md:grid-cols-2 gap-3">
              <div>
                <FormControl fullWidth>
                  <SearchDropdown
                    control={control}
                    name="parameterName"
                    searchIcon={true}
                    placeholder="Parameter Name"
                    dataArray={pathParameterOption}
                    handleInputChange={handleChange}
                    isClearable={true}
                  />
                </FormControl>
              </div>
              <div>
                <CommonButton
                  label=" Add "
                  className="bg-green-500 text-white"
                  type="button"
                  onClick={() => {
                    handleOnsubmit();
                    handleReset();
                  }}
                />
              </div>
            </div>
          )}
          <div className="pt-2">
            {watchSubTest === false ? (
              <div>
                {props.parameterData.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={props.parameterData}
                    highlightRow={false}
                    renderActions={RenderActions}
                    removeHeaders={["parameterId", "isParameter"]}
                    tableClass={"h-96"}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          {watchSubTest === true ? (
            <>
              {props.finalData.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={props.finalData}
                  highlightRow={false}
                  renderActions={RenderActionsSubTest}
                  removeHeaders={["parameterId", "isParameter"]}
                  tableClass={"h-96"}
                  editableColumns={["Parameters"]}
                  renderInput={handleRenderInputSubTestTable}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
