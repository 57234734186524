import { yupResolver } from "@hookform/resolvers/yup";
import { Chip, Divider, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchKitList } from "../../../../inventory/services/commonServices/CommonServices";
import {
  fetchItemsForIssue,
  fetchItemsForIssueByKit,
  fetchOTDetailsSurgeryList,
  saveOtIssuedItems,
} from "../../../services/otDetailsServices/OtDetailsServices";
import ItemDetailsTable from "./ItemDetailsTable";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  minHeight: "50%",
  // maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};
const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

export default function IssuedItemsModal(props) {
  const {
    open,
    handleClose,
    patientInfo,
    privilege,
    menuId,
    getIssuedItemsList,
  } = props;
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));

  //
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //for show quantity errors
  const [customError, setCustomError] = useState();
  const [selectedDrugData, setSelectedDrugData] = useState([]);
  const [itemsDropdown, setItemsDropdown] = useState([]);
  const [selectedDrug, setSelectedDrug] = React.useState(null);
  //
  const [otSurgeries, setOtSurgeries] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState();
  const [errorToTblInput, setErrorToTblInput] = useState(false);

  //item Kit List
  const [itemKitList, setItemKitList] = React.useState();
  //
  const [batchSelctedRowData, setBatchSelctedRowData] = React.useState(null);
  //item against Kit
  const [itemKitListItemsData, setItemKitListItemsData] = React.useState();
  //
  //open batch selection modal
  const [openBatchModal, setOpenBatchModal] = React.useState(false);
  const handleOpenBatchModal = () => setOpenBatchModal(true);
  const handleCloseBatchModal = () => setOpenBatchModal(false);
  /////flag to check batch selected inside table input
  const [batchInsideTableInput, setBatchInsideTableInput] = useState(null);
  const [selctedRowData, setSelctedRowData] = React.useState(null);
  const [selectedItemDtlsID, setSelectedItemDtlsID] = React.useState(null);
  //
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const defaultValues = {
    "input-1": null,
    "input-2": "",
    "input-3": "",
    "input-4": null,
    "input-5": "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: defaultValues,
  });
  const {
    formState: { errors },
    control,
    getValues,
    setError,
    register,
    resetField,
    reset,
    setValue,
    watch,
  } = methods;
  var watchInput1 = watch("input-1");

  useEffect(() => {
    if (openBatchModal) {
      focusBatchSelModal();
    }
  }, [openBatchModal]);

  //to mearge kit & items  itemwise
  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.id === val.id);
      if (ind !== -1) {
        acc[ind]["Quantity"] = acc[ind]["Quantity"] + val["Quantity"];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);
  const inputRefFive = useRef(null);
  const FocusBatchModal = useRef();
  const inputRefaddItem = useRef(null);
  const inputRefAddKit = useRef(null);

  function focusItemSearchDropdown() {
    let fieldsetTag = inputRefOne.current;

    let inputTagCollection = fieldsetTag.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];

    inputTagOne.focus();
  }

  function focusKitSearchDropdown() {
    let fieldsetTag = inputRefFour.current;

    let inputTagCollection = fieldsetTag.getElementsByTagName("input");

    let inputTagOne = inputTagCollection[0];

    inputTagOne.focus();
  }

  //
  useEffect(() => {
    fetchOTDetailsSurgeryList(location?.state?.row?.OTHeaderID).then(
      (response) => {
        setOtSurgeries(response.data.result);
        setSelectedSurgery(response.data.result[0]); ///patch default value to chip
      }
    );
  }, []);

  //
  useEffect(() => {
    //i-2
    if (
      batchSelctedRowData !== null &&
      selectedDrug !== null &&
      !batchInsideTableInput
    ) {
      setValue("input-2", batchSelctedRowData.BatchCode);
    } else if (watchInput1 === "") {
      setValue("input-2", "");
      setValue("input-3", "");
    }

    //i-3
  }, [selectedDrug, batchSelctedRowData, batchInsideTableInput]);

  const addToItemDetailsTbl = () => {
    //proceed if only patient selcted
    var item = getValues("input-1");
    let quantityValue = getValues("input-3");
    let batchValue = getValues("input-2");

    if (item === null) {
      setError("input-1", { type: "custom", message: "Required" });
    }
    if (item?.BatchesRequired && batchValue === "") {
      setError("input-2", { type: "custom", message: "Required" });
    }
    if (quantityValue === "") {
      setError("input-3", { type: "custom", message: "Required" });
    }
    if (quantityValue === "" && item?.BatchesRequired) {
      setCustomError(true);
    } else if (quantityValue !== "" && item?.BatchesRequired) {
      setCustomError(false);
    }

    let batchError;
    if (batchValue === "" && item?.BatchesRequired) {
      batchError = true;
    } else {
      batchError = false;
    }

    item.BatchNumber = batchValue;

    if (batchError) {
      warningAlert("Please Select Batch Number...");
    } else if (Number(batchSelctedRowData?.BalQty) < Number(quantityValue)) {
      warningAlert("Please Enter Valid Quantity...");
    } else if (item !== null && quantityValue !== "" && !batchError) {
      if (batchSelctedRowData !== null) {
        item.BalanceQty = Number(batchSelctedRowData?.BalQty);
        item.BatchExpDate = batchSelctedRowData?.BatchExpDatePatch;
        item.UnitMRP = batchSelctedRowData?.UnitMRP;
      }

      item.Quantity = parseInt(quantityValue);
      item.isConsumed = true;
      //calculate with add
      item.Amount = quantityValue * (item.UnitMRP || item.MRP);
      item.surgeryId = selectedSurgery?.SurgeryId;

      let arr = [...selectedDrugData];
      arr.push(item);

      setSelectedDrugData(mergeArray(arr));
      // setSelectedDrugData(arr);

      resetField("input-1");
      resetField("input-2");
      resetField("input-3");
      setSelectedDrug(null);
      setBatchSelctedRowData(null);
      focusItemSearchDropdown();
      //
    }
  };

  //add items from kit list dropdown
  const addKitToItemDetailsTable = () => {
    var KitQty = getValues("input-5");

    let arr = [...selectedDrugData];

    if (KitQty === "") {
      setError("input-5", { type: "custom", message: "Required" });
    }

    if (itemKitListItemsData !== undefined && KitQty !== "") {
      for (let i = 0; i < itemKitListItemsData.length; i++) {
        let existingQty = itemKitListItemsData[i].Quantity;
        let updatedQty = existingQty * KitQty;
        itemKitListItemsData[i].Quantity = updatedQty;
        itemKitListItemsData[i].surgeryId = selectedSurgery?.SurgeryId;
        itemKitListItemsData[i].isConsumed = true;
      }
      const newArr = arr.concat(itemKitListItemsData);

      // setSelectedDrugData(mergeArray(newArr));
      resetField("input-4");
      resetField("input-5");

      focusKitSearchDropdown();
      setSelectedDrugData(newArr);
    }
  };

  // //The focus batch modal
  const focusBatchSelModal = () => {
    if (FocusBatchModal.current) {
      FocusBatchModal.current.focus();
    }
  };

  //////// on enter batch input open batch modal
  const handleKeypressForBatch = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      if (selectedDrug !== null) {
        setBatchInsideTableInput(false);
        handleOpenBatchModal();
        setSelctedRowData(null);
      }
    }
  };

  const handleOnSubmit = () => {
    let validBatch = selectedDrugData.some(
      (item) =>
        item.BatchNumber === "" &&
        (item.BatchesRequired === "Yes" || item.BatchesRequired === true)
    );
    if (selectedDrugData.length === 0) {
      warningAlert("Please Select Item For Issue!");
    } else if (validBatch) {
      warningAlert("Please Select Batch Number !");
    } else if (errorToTblInput) {
      warningAlert("Please Issue Valid Quantity !");
    } else if (!errorToTblInput && selectedDrugData.length > 0) {
      setOpenConfirmationModal(true);
    }
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    let postArrFinale = otSurgeries.map((item) => ({
      companyId: patientInfo.CompanyId,
      opdIpdExternalId:
        patientInfo.OpdIpd === 1
          ? patientInfo.admissionId
          : patientInfo.visitId,
      opdIpdExternal: patientInfo.OpdIpd,
      otHeaderId: location.state.row.OTHeaderID,
      surgeryId: item.SurgeryId,
      storeId: token.storeId,
      tariffId: patientInfo?.tariffId,
      otDetailsIssuedItemsDtoList: selectedDrugData
        .filter((filterObj) => filterObj.surgeryId === item.SurgeryId)
        .map((obj) => ({
          amount: obj.Amount,
          batchCode: obj.BatchNumber,
          chargeId: 0,
          concession: 0,
          concessionPercentage: 0,
          consumed: obj.isConsumed,
          conversion: obj.Conversion,
          discount: 0,
          discountPercentage: 0,
          expiryDate: obj.BatchNumber
            ? `${obj.BatchExpDate}T00:00:00.000Z`
            : "1900-01-01T00:00:00.000Z",
          isConsignment: obj.IsConsignment,
          isPackaged: obj.isPackaged,
          isIssue: true,
          issueQty: obj.Quantity,
          itemId: obj.id,
          mrp: obj.UnitMRP || obj.MRP,
          otHeaderId: location.state.row.OTHeaderID,
          otMaterialDetailsId: 0,
          otherTaxAmount: 0,
          purchaseRate: obj.PurchaseRate,
          qtyAvlForReturn: obj.Quantity,
          sellingPrice: 0,
          staffFree: 0,
          staffFreePercentage: 0,
          taxAmount: 0,
          totalAmount: 0,
          totalForVAT: 0,
          unitLandedRate: obj.PurchaseRate,
          vatAmount: 0,
          vatInclusive: obj.VATInClusive,
          vatPercentage: obj.VATPercentage,
          //new added by g1
          // opdIpdExternal: patientInfo.opdIpdExternalId,
          opdIpdExternalId:
            patientInfo.OpdIpd === 1
              ? patientInfo.admissionId
              : patientInfo.visitId,
          opdIpdExternal: patientInfo.OpdIpd,

          companyId: patientInfo.CompanyId,
          addedBy: token.userId,

          //new keys
          surgeryId: obj.surgeryId,
          storeId: token.storeId,
          tariffId: patientInfo?.tariffId,
        })),
    }));

    const validItems = postArrFinale.filter((item) => {
      const quantity = item.issueQty;

      // Check if quantity is a valid number and not undefined, null, or NaN
      return (
        typeof quantity === "number" &&
        !isNaN(quantity) &&
        quantity !== null &&
        quantity > 0
      );
    });

    saveOtIssuedItems(menuId, privilege, postArrFinale)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          // methods.reset();
          setSelectedSurgery(otSurgeries[0]);

          setOpenBackdrop(false);
          setSelectedDrugData([]);
          getIssuedItemsList(location?.state?.row?.OTHeaderID, 0);
        }
      })
      .catch((res) => {
        // errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form
          //   onSubmit={methods.handleSubmit(onSubmitMlc)}
          >
            <div>
              <div className="text-black font-bold text-lg pb-1">
                Issued Items
              </div>

              <div className="flex gap-2 flex-wrap">
                {otSurgeries &&
                  otSurgeries.map((data) => {
                    return (
                      <Chip
                        label={data["Surgery Name"]}
                        onClick={() => setSelectedSurgery(data)}
                        variant={
                          selectedSurgery["Surgery Name"] ===
                          data["Surgery Name"]
                            ? "filled"
                            : "outlined"
                        }
                        color={"primary"}
                      />
                    );
                  })}
              </div>

              <hr className="border my-4 divide-x-8 bg-slate-300 border-slate-300" />

              <div>
                {/* item search */}
                <div className="mt-2">
                  <div className="md:grid md:gap-3 md:grid-cols-8 lg:flex lg:gap-2">
                    <div
                      className="col-span-2 lg:w-[300px] md:mb-3 md:col-span-4 lg:mb-0"
                      ref={inputRefOne}
                    >
                      <SearchDropdown
                        control={control}
                        error={errors["input-1"]}
                        name="input-1"
                        label="Search Items"
                        dataArray={itemsDropdown}
                        handleInputChange={(e) => {
                          e &&
                            fetchItemsForIssue(e, token.storeId).then(
                              (response) => {
                                setItemsDropdown(response.data.result);
                              }
                            );
                        }}
                        inputRef={{
                          ...register("input-1", {
                            onChange: (e) => {
                              if (e.target.value !== null) {
                                setSelectedDrug(e.target.value);
                                if (
                                  e.target.value?.BatchesRequired === false ||
                                  e.target.value?.BatchesRequired === "No"
                                ) {
                                  inputRefThree.current.focus();
                                } else {
                                  inputRefTwo.current.focus();
                                  setValue("input-2", "");
                                  // setSelectedDrug(null)
                                }
                              }
                            },
                          }),
                        }}
                        isSearchable={true}
                        placeholder="Search Items "
                        isClearable={true}
                        searchIcon={true}
                      />
                    </div>

                    {selectedDrug?.BatchesRequired !== "No" &&
                      selectedDrug?.BatchesRequired !== false && (
                        <div className="md:col-span-2 ">
                          <InputField
                            name="input-2"
                            size="small"
                            inputRef={inputRefTwo}
                            variant="outlined"
                            label="Batch Number"
                            {...register("input-2")}
                            error={errors["input-2"]}
                            control={control}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                inputRefThree.current.focus();
                                handleKeypressForBatch(e);
                              }
                            }}
                          />
                        </div>
                      )}

                    <div className="lg:w-15">
                      <TextField
                        inputRef={inputRefThree}
                        error={customError}
                        size="small"
                        type="number"
                        color={customError ? "error" : "primary"}
                        InputLabelProps={{
                          // shrink: watchInput1 !== null ? true : false,
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            height: "36.3px",
                            background: "white",
                          },
                        }}
                        sx={{
                          input: {
                            color: customError ? "#d32f2f" : "#212529",
                          },
                          label: {
                            color: customError ? "#d32f2f" : "#212529",
                          },
                        }}
                        name="input-3"
                        variant="outlined"
                        label="Qty"
                        {...register("input-3")}
                        onChange={(e) => {
                          // getQuantity(e);
                        }}
                        // disabled={patientDetails === null ? true : false}
                        control={control}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            inputRefaddItem.current.focus();
                          }
                        }}
                      />
                    </div>

                    <div>
                      <CommonButton
                        label="Add"
                        className="bg-customGreen text-white"
                        referance={inputRefaddItem}
                        onClick={() => {
                          addToItemDetailsTbl();
                          inputRefOne.current.focus();
                          focusItemSearchDropdown();
                        }}
                      />
                    </div>

                    <div className="  bg-gray-500 lg:grid md:hidden ">
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem={true}
                      />
                    </div>

                    <div className=" md:col-span-8 md:grid  md:grid-cols-4 lg:flex gap-2 ">
                      <div
                        className="col-span-2 lg:w-[320px] z-40"
                        ref={inputRefFour}
                      >
                        <SearchDropdown
                          control={control}
                          name="input-4"
                          label="Search by Item Kit"
                          placeholder="Search by Item Kit"
                          searchIcon={true}
                          isSearchable={true}
                          isClearable={true}
                          // isDisabled={patientDetails === null ? true : false}
                          handleInputChange={(e) => {
                            if (e) {
                              fetchKitList(e)
                                .then((response) => response.data)
                                .then((res) => {
                                  setItemKitList(res.result);
                                });
                            }
                          }} //searchString
                          inputRef={{
                            ...register("input-4", {
                              onChange: (e) => {
                                e?.target?.value?.id &&
                                  fetchItemsForIssueByKit(
                                    e.target.value.id,
                                    token.storeId
                                  )
                                    .then((response) => response.data)
                                    .then((res) => {
                                      setItemKitListItemsData(res.result);
                                    });
                                inputRefFive.current.focus();
                              },
                            }),
                          }}
                          dataArray={itemKitList}
                        />

                        {/* <p className="text-customRed text-sm">{serviceErrorMessage}</p>      fetchKitList*/}
                      </div>

                      <div className="lg:w-15">
                        <InputField
                          name="input-5"
                          type="number"
                          variant="outlined"
                          label="Qty*"
                          error={errors["input-5"]}
                          control={control}
                          inputRef={inputRefFive}
                          onKeyDown={(e) =>
                            e.key === "Enter" &&
                            inputRefAddKit?.current?.focus()
                          }
                        />
                      </div>

                      <div>
                        <CommonButton
                          label="Add"
                          referance={inputRefAddKit}
                          onClick={() => {
                            addKitToItemDetailsTable();
                            focusKitSearchDropdown();
                            inputRefFour.current.focus();
                          }}
                          className="bg-customGreen text-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Selected item details table setNoOfItems, setTotalQuantity */}
                <div
                  className="mt-2"
                  // ref={FocusItemDetailsTable}
                  // onMouseOut={() => handleMouseOut(FocusItemDetailsTable.current)}
                >
                  <ItemDetailsTable
                    data={selectedDrugData}
                    selectedDrug={selectedDrug}
                    setSelectedDrug={setSelectedDrug}
                    selectedDrugData={selectedDrugData}
                    setSelectedDrugData={setSelectedDrugData}
                    selectedItemDtlsID={selectedItemDtlsID}
                    setSelectedItemDtlsID={setSelectedItemDtlsID}
                    // //
                    openBatchModal={openBatchModal}
                    setOpenBatchModal={setOpenBatchModal}
                    handleOpenBatchModal={handleOpenBatchModal}
                    handleCloseBatchModal={handleCloseBatchModal}
                    // //
                    FocusBatchModal={FocusBatchModal}
                    // //
                    batchSelctedRowData={batchSelctedRowData}
                    setBatchSelctedRowData={setBatchSelctedRowData}
                    // //
                    batchInsideTableInput={batchInsideTableInput}
                    setBatchInsideTableInput={setBatchInsideTableInput}
                    // //
                    setErrorToTblInput={setErrorToTblInput}
                    // errorToTblInput={errorToTblInput}
                    // //
                    selctedRowData={selctedRowData}
                    setSelctedRowData={setSelctedRowData}
                  />
                </div>
              </div>
              <div className="flex justify-end my-2 gap-2">
                {Actions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "save" ? (
                      <>
                        <CommonButton
                          label="Reset"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            reset(defaultValues);
                            setSelectedDrugData([]);
                          }}
                        />
                        <CommonButton
                          label="Save"
                          className="bg-customGreen text-white"
                          onClick={() => {
                            handleOnSubmit();
                          }}
                        />
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
