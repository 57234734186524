/////////////////////////////////////////////////////////////////////////////////////////////////

import DescriptionIcon from "@mui/icons-material/Description";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import UTIF from "utif";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import ImageViewer from "../../../common/components/imageViewer/ImageViewer";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import useGeneratePdf from "../../../common/components/printModal/GeneratePdfHook";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchPatientInfoAutocomplete } from "../../../commonServices/patientInfoServices/PatientInfoServices";
import {
  fetchPatientDocuments,
  getDocumentByPath,
  savePatientDocuments,
} from "../../services/patientDocumentSystemServices/patientDocumentSystemServices";

const PatientDocumentSystem = () => {
  let location = useLocation();
  //
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let userId = token.userId;
  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      uploadFileDailyTips: null,
      //
    },
  });

  const { control, register, getValues } = methods;

  const [folderStructure, setFolderStructure] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedfolder, setSelectedfolder] = useState(null);
  // const [opdIpdId, setOpdIpdId] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [docToView, setDocToView] = useState(null);
  const fileURL = useGeneratePdf(docToView);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [base64String, setBase64String] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [openNodes, setOpenNodes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState(null);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [isFoldersVisible, setIsFoldersVisible] = useState(true);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSectionVisibility = () => {
    setIsFoldersVisible(!isFoldersVisible);
  };

  const getPatientInfoAutoComplete = (e) => {
    e &&
      fetchPatientInfoAutocomplete(e)
        .then((res) => res.data)
        .then((res) => {
          setAutoCompleteData(res.result);
        });
  };

  useEffect(() => {
    /////get User Action
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, [selectedPatient]);

  console.log("previewUrls", previewUrls);

  const getFolderStructure = (ptId) => {
    ptId &&
      fetchPatientDocuments(ptId)
        .then((response) => response.data)
        .then((res) => {
          console.log("Folder structure", res.result);

          const { OPD, IPD } = res.result;

          // Creating an array of folder objects
          const temp = [
            { label: "OPD", Folder: OPD },
            { label: "IPD", Folder: IPD },
          ];

          const addUID = (temp, parentUID) => {
            return temp.map(({ Folder, ...item }, index) => {
              const currentUID = `${index}${parentUID || 100}${
                item.VisitId || item.AdmissionId || item.Id || 0
              }`;

              if (Folder && Folder.length > 0) {
                const updatedFolder = addUID(Folder, currentUID);
                return { ...item, Folder: updatedFolder, UID: currentUID };
              } else {
                return { ...item, UID: currentUID };
              }
            });
          };

          const updatedTemp = addUID(temp);

          setFolderStructure(updatedTemp);
        });
  };

  const TreeNode = ({ node }) => {
    const isOpen = openNodes.includes(node.UID);

    const handleToggle = (folderId, documents, nodes) => {
      console.log("folderId, documents, nodes", folderId, documents, nodes);
      setPreviewUrls([]);
      setSelectedDocuments([]);
      if (documents) {
        setSelectedDocuments([...documents]);
        setIsLoading(true);
      }

      if (openNodes.includes(folderId)) {
        setOpenNodes(openNodes.filter((id) => id !== folderId));
      } else {
        setOpenNodes([...openNodes, folderId]);

        if (nodes?.Id || nodes?.DocMgmId) {
          setSelectedFolderId(nodes?.Id || nodes?.VisitId);
        }
      }

      setSelectedfolder(
        node.Folder === undefined
          ? node.VisitId
            ? { VisitId: node.VisitId }
            : { id: node.Id, AdmissionId: node.AdmissionId }
          : 0
      );

      if (node.Folder === undefined) {
        setSelectedNodes(folderId);
      }

      // if (node.VisitId || node.AdmissionId) {
      //   setOpdIpdId(node.VisitId || node.AdmissionId);
      // }
    };

    const getRandomColorClass = () => {
      const colors = [
        "text-red-700",
        "text-blue-700",
        "text-green-700",
        "text-yellow-700",
        "text-indigo-700",
        "text-purple-700",
        "text-pink-700",
      ];
      const randomIndex = Math.floor(Math.random() * colors?.length);
      return colors[randomIndex];
    };

    const nodeStyle =
      node.folderId === selectedFolderId
        ? "bg-red-200 text-white"
        : !!node.Documents
        ? "text-base font-bold bg-slate-100	"
        : "";

    return (
      <div
        className={`border-l-2 border-dashed border-slate-600 left-[25%] ml-2 ${nodeStyle}  ${
          selectedNodes === node.UID && "bg-orange-300 "
        }`}
      >
        <div
          onClick={(e) => {
            handleToggle(node.UID, node.Documents, node);
            e.preventDefault();
            console.log("node", node);
            // setPreviewUrls([]);
          }}
        >
          <div className="grid grid-cols-12">
            <p className="col-span-1 lg:text-xs 2xl:text-lg text-nowrap">---</p>
            <div className={"col-span-11 flex"}>
              <div className="-mt-1">
                {node?.Folder?.length > 0 ? (
                  isOpen ? (
                    <FolderOpenIcon className={``} fontSize="small" />
                  ) : (
                    <FolderIcon className={``} fontSize="small" />
                  )
                ) : !!node.Documents ? (
                  <DescriptionIcon className={``} fontSize="small" />
                ) : (
                  <DescriptionOutlinedIcon className={``} fontSize="small" />
                )}
              </div>
              <p
                //
                className={`${getRandomColorClass()} ml-2 text-sm align-text-bottom hover:bg-slate-400 hover:text-white ${nodeStyle}  ${
                  selectedNodes === node.UID && "bg-orange-300 "
                }`}
              >
                {node.label ||
                  node.VisitDtls ||
                  node.AdmissionDtls ||
                  node.FolderName}
              </p>
            </div>
          </div>
        </div>

        {isOpen && node.Folder && (
          <div style={{ marginLeft: "20px" }}>
            {node.Folder.map((child) => (
              <TreeNode key={child.id} node={child} />
            ))}
          </div>
        )}
      </div>
    );
  };

  const TreeView = ({ data }) => {
    return (
      <div className={"cursor-pointer bg-white"}>
        {data.map((node) => (
          <TreeNode key={node.id} node={node} />
        ))}
      </div>
    );
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files && files?.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        const imageName = file.name;
        setBase64String(base64String);
        setImageName(imageName);
      };

      reader.readAsDataURL(file);
    }
  };

  const onsubmit = () => {
    console.log("selectedFolderId", selectedFolderId);
    if (selectedfolder !== 0) {
      if (!!base64String) {
        setOpenConfirmationModal(true);
      } else {
        warningAlert("Please select a Document");
      }
    } else {
      warningAlert("Please select a folder location");
    }
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    // const finalObj = {
    //   documentBase64: base64String,
    //   documentName: imageName,
    //   folderId: selectedFolderId,
    //   menuId: location?.state?.menuId,
    //   privilege: privilege,
    // };

    const finalObj = {
      folderId:
        selectedfolder.VisitId || selectedfolder.id || selectedfolder.Id,
      imageDetails: [
        {
          base64: base64String,
          imageName: imageName,
          isDelete: false,
          path: "",
        },
      ],
      mrno: selectedPatient.MRNo,
      opdIpd: selectedfolder.VisitId
        ? 0
        : selectedfolder.id || selectedfolder.Id
        ? 1
        : 0,
      opdIpdId: selectedfolder.VisitId || selectedfolder.AdmissionId || 0,
      patientId: selectedPatient.id,
    };
    console.log("finalObj", finalObj);

    savePatientDocuments(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          setSelectedFolderId(null);
          setSelectedDocuments([]);
          getFolderStructure(selectedPatient.id);
          setPreviewUrls([]);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  console.log("selectedDocuments", selectedDocuments);

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  useEffect(() => {
    selectedDocuments.forEach((item, index) => {
      const path = item?.FilePath?.split("/");
      console.log("path", path);
      setPreviewUrls([]);
      if (selectedfolder?.AdmissionId) {
        getDocumentByPath(path[0], path[1], path[2], path[3], path[4])
          .then((response) => {
            if (path[4]?.includes("tif")) {
              var apiResponseImageData = response.data;

              var ifds = UTIF.decode(apiResponseImageData);
              ifds.map(function (ifd, index) {
                UTIF.decodeImage(apiResponseImageData, ifd);
                var rgba = UTIF.toRGBA8(ifd);
                var canvas = document.createElement("canvas");
                canvas.width = ifd.width;
                canvas.height = ifd.height;
                var ctx = canvas.getContext("2d");
                var img = ctx.createImageData(ifd.width, ifd.height);
                img.data.set(rgba);
                ctx.putImageData(img, 0, 0);
                let image = canvas.toDataURL();
                setPreviewUrls((prev) => [...prev, image]);
                let imageId = document.getElementById(`retrieved${index}`);
                if (imageId) {
                  imageId.src = image;
                }

                document
                  .getElementById(`tiff-inner-container${index}`)
                  .appendChild(canvas);
                return canvas;
              });
            } else {
              let newImage =
                "data:image/jpeg;base64," + arrayBufferToBase64(response?.data);
              let imageId = document.getElementById(`retrieved${index}`);
              if (imageId) {
                imageId.src = newImage;
              }
              setPreviewUrls((prev) => [...prev, newImage]);

              console.log("The newImage retrieved", newImage);
            }

            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            errorAlert(error);
          });
      } else if (selectedfolder?.VisitId) {
        getDocumentByPath(0, path[0], path[1], path[2], path[3])
          .then((response) => {
            if (path[3]?.includes("tif")) {
              var apiResponseImageData = response.data;

              var ifds = UTIF.decode(apiResponseImageData);
              ifds.map(function (ifd, index) {
                UTIF.decodeImage(apiResponseImageData, ifd);
                var rgba = UTIF.toRGBA8(ifd);
                var canvas = document.createElement("canvas");
                canvas.width = ifd.width;
                canvas.height = ifd.height;
                var ctx = canvas.getContext("2d");
                var img = ctx.createImageData(ifd.width, ifd.height);
                img.data.set(rgba);
                ctx.putImageData(img, 0, 0);
                let image = canvas.toDataURL();
                setPreviewUrls((prev) => [...prev, image]);
                let imageId = document.getElementById(`retrieved${index}`);
                if (imageId) {
                  imageId.src = image;
                }

                document
                  .getElementById(`tiff-inner-container${index}`)
                  .appendChild(canvas);
                return canvas;
              });
            } else {
              let newImage =
                "data:image/jpeg;base64," + arrayBufferToBase64(response?.data);
              let imageId = document.getElementById(`retrieved${index}`);
              if (imageId) {
                imageId.src = newImage;
              }
              setPreviewUrls((prev) => [...prev, newImage]);

              console.log("The newImage retrieved", newImage);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            errorAlert(error);
          });
      }
    });
  }, [selectedDocuments]);

  const openViewer = (index) => {
    setSelectedImageIndex(index);
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < previewUrls.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  return (
    <>
      <div className="mt-12 mb-2">
        <div className="grid grid-cols-12">
          <div className="mb-2">
            <Tooltip
              title={!isFoldersVisible ? "Show Folders" : "Hide Folders"}
            >
              <Button
                onClick={() => {
                  toggleSectionVisibility();
                }}
              >
                {!isFoldersVisible ? <MenuIcon /> : <MenuOpenIcon />}
              </Button>
            </Tooltip>
          </div>
          <div className="text-center text-black font-bold text-xl mt-2 col-span-11">
            Document Management System
          </div>
        </div>

        <div className="grid grid-cols-5 gap-3">
          <div className="md:col-span-2 lg:col-span-2 ">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./ Mob No."
              dataArray={autoCompleteData}
              name="patientSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={getPatientInfoAutoComplete}
              inputRef={{
                ...register("patientSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                      getFolderStructure(e.target.value.id);
                    } else {
                      setSelectedPatient("");
                      setFolderStructure([]);
                      setOpenNodes([]);
                      setSelectedDocuments([]);
                      setPreviewUrls([]);
                      setSelectedImageIndex(0);
                    }
                  },
                }),
              }}
            />
          </div>
        </div>
        <div className="my-2">
          <fieldset
            className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
          >
            <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm ">
                <span className=" font-semibold w-28">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient["patientName"]}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">MR. No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.MRNo}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">Gender</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.Gender}
                  </span>
                </div>
              </div>
              {/* <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.AgeYear}
                  </span>
                </div>
              </div> */}
            </div>
          </fieldset>
        </div>

        <div className=" py-2 grid md:grid-cols-1 lg:grid-cols-4 gap-3">
          {isFoldersVisible && (
            <>
              <div className="">
                <div className=" md:h-[200px] lg:h-[300px] xl:h-[300px]  2xl:h-[610px] overflow-y-scroll">
                  {folderStructure?.length > 0 ? (
                    <TreeView data={folderStructure} />
                  ) : (
                    <div className="text-center pt-16">No Folders Found...</div>
                  )}
                </div>
                <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-1 md:px-5 rounded bg-gray-100">
                  <div className="grid gap-3 grid-cols-7 my-2">
                    <legend className="text-md font-semibold text-gray-900 px-2 col-span-7">
                      Upload New Document
                    </legend>
                    <div className="col-span-7 pl-1 overflow-clip">
                      <input
                        type="file"
                        accept="image/*"
                        {...register("uploadFileDailyTips")}
                        onChange={handleFileChange}
                        name="uploadFileDailyTips"
                      />
                    </div>

                    <div className="col-span-7 flex gap-3 justify-end">
                      <div>
                        <CommonButton
                          label="Reset"
                          className={"border border-customRed text-customRed"}
                          onClick={() => methods.reset()}
                        />
                      </div>
                      <div>
                        <CommonButton
                          label="Add"
                          className="bg-customGreen text-white"
                          onClick={() => onsubmit()}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </>
          )}
          {/* document view */}

          <div
            className={`md:h-[200px] lg:h-[350px] xl:h-[350px]  2xl:h-[740px] overflow-y-auto ${
              isFoldersVisible ? "col-span-3" : "col-span-4"
            } flex flex-wrap gap-3 ${
              (isLoading || previewUrls.length < 1) && "justify-center"
            }`}
          >
            {/*isLoading ? (
              <div className="flex justify-center text-gray-400 font-semibold my-5">
                <LoadingSpinner />
              </div>
            ) : previewUrls && previewUrls?.length > 0 ? (
              previewUrls.map((doc, docIndex) => {
                return (
                  <div className="h-44 w-44 border  rounded-lg p-1 flex  gap-3">
                    <div
                      className=""
                      onClick={() => {
                        openViewer(docIndex);
                      }}
                    >
                      <img
                        src={doc}
                        alt={`Image ${docIndex + 1}`}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className=" mt-24 ">No Document Found</div>
            )*/}
            <div>
              {isLoading ? (
                <div className="flex justify-center text-gray-400 font-semibold my-5">
                  <LoadingSpinner />
                </div>
              ) : previewUrls && previewUrls?.length > 0 ? (
                <>
                  <div className="h-full w-full border rounded-lg p-1 flex gap-3">
                    <div className="h-full w-full">
                      <img
                        src={previewUrls[selectedImageIndex]}
                        // alt={`Image ${docIndex + 1}`}
                        className="h-full w-full object-contain"
                      />
                    </div>
                  </div>
                  <div className="fixed bottom-4 right-4 flex items-center">
                    <div>
                      {previewUrls?.length > 0 && selectedImageIndex > 0 && (
                        <button
                          onClick={handlePrevImage}
                          className="btn-nav border rounded-full bg-customBlue text-white"
                        >
                          <Tooltip title={"Previous"}>
                            <KeyboardArrowLeftOutlinedIcon
                              style={{
                                fontSize: "40px",
                                width: "40px",
                                height: "40px",
                              }}
                            />
                          </Tooltip>
                        </button>
                      )}
                    </div>
                    <div>
                      {previewUrls?.length !== selectedImageIndex + 1 && (
                        <button
                          onClick={handleNextImage}
                          className="btn-nav border rounded-full bg-customBlue text-white"
                        >
                          <Tooltip title={"Next"}>
                            <ChevronRightOutlinedIcon
                              style={{
                                fontSize: "40px",
                                width: "40px",
                                height: "40px",
                              }}
                            />
                          </Tooltip>
                        </button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-24">No Document Found</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Render full image*/}
      {isViewerOpen && (
        <ImageViewer
          open={isViewerOpen}
          images={previewUrls}
          onClose={closeViewer}
          initialIndex={selectedImageIndex}
        />
      )}

      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default PatientDocumentSystem;
