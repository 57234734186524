import React from "react";

export default function CallRejectedrow() {
  return (
    <button>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" fill="#F87676" />
        <path
          d="M11.51 11C11.04 11 10.5988 10.7952 10.2664 10.4242L5 6.26428L6.13123 5L11.398 9.15991C11.4752 9.24575 11.5452 9.24575 11.622 9.15991L16.8688 5.02143L18 6.2857L12.7537 10.4242C12.4213 10.7957 11.98 11 11.51 11Z"
          fill="white"
        />
        <path
          d="M14.6948 14.5189L14.4868 16.1482C14.4395 16.5193 14.6417 16.8733 14.9624 16.9807L17.9028 17.9679C18.2017 18.0684 18.5231 17.9268 18.6813 17.6257L19.8595 15.3779C20.1595 14.8057 19.9624 14.068 19.4282 13.7807C18.1206 13.0788 15.5366 12 11.9999 12C8.4636 12 5.87927 13.0783 4.57165 13.7807C4.03747 14.0676 3.84075 14.8057 4.14038 15.3779L5.31855 17.6257C5.47636 17.9268 5.79818 18.0684 6.09708 17.9679L9.03741 16.9807C9.35813 16.8729 9.56031 16.5189 9.51304 16.1482L9.30468 14.5176C9.25123 14.0981 9.51377 13.7087 9.88976 13.6574C10.5439 13.5683 11.2483 13.515 11.9996 13.5154C12.7501 13.5154 13.4552 13.5691 14.1101 13.659C14.4861 13.7103 14.7486 14.0993 14.6948 14.5189Z"
          fill="white"
        />
        <path
          d="M19.408 3.29413L19.1281 7.6166L15.2598 3L19.408 3.29413Z"
          fill="white"
        />
      </svg>
    </button>
  );
}
