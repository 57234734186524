import CommonButton from "../../../../common/components/Buttons/CommonButton";

import { yupResolver } from "@hookform/resolvers/yup";
import ControlPointDuplicateRoundedIcon from "@mui/icons-material/ControlPointDuplicateRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import FlipIcon from "@mui/icons-material/Flip";
import Forward30Icon from "@mui/icons-material/Forward30";
import { Tooltip, tooltipClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchPatientInformationForOtDtls } from "../../../services/otDetailsServices/OtDetailsServices";
import {
  fetchReservedSurgeryLayout,
  saveOTReservationLayout,
} from "../../../services/otReservationServices/OtReservationServices";
import machine from "./Group-3.png";
import OtLayoutPrint from "./OtLayoutPrint";
import BringFront from "./otMachineImages/Bring_To_Front.svg";
import Door from "./otMachineImages/Door.svg";
import Img1 from "./otMachineImages/Group 1000004530.png";
import Img2 from "./otMachineImages/Group 1000004531.png";
import Img3 from "./otMachineImages/Group 1000004532.png";
import Img4 from "./otMachineImages/Group 1000004533.png";
import Img5 from "./otMachineImages/Group 1000004534.png";
import Img8 from "./otMachineImages/Group 1000004536.png";
import Img9 from "./otMachineImages/Group 1000004537.png";
import Img10 from "./otMachineImages/Group 1000004538.png";
import Img11 from "./otMachineImages/Group 1000004539.png";
import {
  default as Img12,
  default as Img7,
} from "./otMachineImages/Group 1000004541.png";
import Img13 from "./otMachineImages/Group 1000004542.png";
import Img14 from "./otMachineImages/Group 1000004543.png";
import Img16 from "./otMachineImages/Group 1000004544.png";
import Img17 from "./otMachineImages/Group 1000004545.png";
import OTPatient from "./otMachineImages/OTpatient.svg";
import BringBack from "./otMachineImages/Send_Back.svg";
import Short from "./otMachineImages/Short _1.svg";
import Wide from "./otMachineImages/Wide_1.svg";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    describeChild={true}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#f0f0f0",
    borderRadius: "4px",
    background: "#f9fafb",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
  },
}));

function OtLayoutFile(props) {
  const {
    LayoutEquipmentArr,
    setLayoutEquipmentArr,
    previewLayoutArr,
    setPreviewLayoutArr,
    isFromForm,
    isEditLayout,
    handleClose,
    doctorDetailsArr,
    selectedRowData,
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      reservedSergeries: null,
    },
  });
  const {
    watch,
    control,
    formState: { errors },
  } = methods;

  const [dragging, setDragging] = useState(false);
  const [initialPositions, setInitialPositions] = useState({});
  const [positions, setPositions] = useState({});
  const [selectedEquipmentArr, setSelectedEquipmentArr] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); ///is for selction highlight
  const [surgeries, setSurgeries] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  //const [previewLayoutArr, setPreviewLayoutArr] = useState([]);
  //

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [equipmentArr, setEquipmentArr] = useState([
    {
      id: 1,
      img: Img8,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "1",
    },
    {
      id: 2,
      img: Img1,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "2",
    },
    {
      id: 3,
      img: Img2,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "3",
    },
    {
      id: 4,
      img: Img3,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "4",
    },
    {
      id: 5,
      img: Img4,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "5",
    },
    {
      id: 6,
      img: machine,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "6",
    },
    {
      id: 7,
      img: Img5,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "7",
    },
    {
      id: 8,
      img: Img17,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "8",
    },
    {
      id: 9,
      img: Img7,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "9",
    },
    {
      id: 10,
      img: Img8,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "10",
    },
    {
      id: 11,
      img: Img9,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "11",
    },
    {
      id: 12,
      img: Img10,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "12",
    },
    {
      id: 13,
      img: Img11,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "13",
    },
    {
      id: 14,
      img: Img12,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "14",
    },
    {
      id: 15,
      img: Img13,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "15",
    },
    {
      id: 16,
      img: Img14,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "16",
    },
    {
      id: 17,
      img: Img16,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "17",
    },
    {
      id: 18,
      img: Img16,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "18",
    },
    {
      id: 19,
      img: Img11,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "19",
    },
    {
      id: 20,
      img: OTPatient,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "20",
    },
    {
      id: 21,
      img: Door,
      equipmentName: "Equipment",
      width: 80,
      height: 80,
      zindex: 100,
      rotate: 0,
      mirrorX: false,
      equipmentCode: "21",
    },
  ]);

  const selected_surgery = watch("reservedSergeries");

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <OtLayoutPrint
          selectedEquipmentArr={selectedEquipmentArr}
          positions={positions}
          patientInfo={patientInfo}
        />
      </div>
    );
  };

  const handleDragStart = (id, e) => {
    e.preventDefault();
    setDragging(true);
    const lastPosition = positions[id] || { x: 0, y: 0 };

    setInitialPositions({
      ...initialPositions,
      [id]: {
        x: e.clientX - lastPosition.x,
        y: e.clientY - lastPosition.y,
      },
    });
  };

  const handleDragEnd = () => {
    setDragging(false);
    setInitialPositions({});
  };

  const handleDrag = (id, e) => {
    if (dragging) {
      const deltaX = e.clientX - initialPositions[id]?.x;
      const deltaY = e.clientY - initialPositions[id]?.y;
      setPositions({
        ...positions,
        [id]: {
          x: deltaX,
          y: deltaY,
        },
      });
    }
  };

  useEffect(() => {
    if (dragging) {
      const handleDragMove = (e) => {
        Object.keys(initialPositions).forEach((id) => {
          handleDrag(parseInt(id), e);
        });
      };

      const handleDragEndOutside = () => {
        setDragging(false);
      };

      document.addEventListener("mousemove", handleDragMove);
      document.addEventListener("mouseup", handleDragEndOutside);

      return () => {
        document.removeEventListener("mousemove", handleDragMove);
        document.removeEventListener("mouseup", handleDragEndOutside);
      };
    }
  }, [dragging, initialPositions]);

  const handleEquipmentSelection = (newEqp) => {
    setSelectedItems((prevSelectedItems) => {
      const index = prevSelectedItems.indexOf(newEqp.equipmentCode);
      if (index === -1) {
        return [...prevSelectedItems, newEqp.equipmentCode];
      } else {
        const newSelectedItems = [...prevSelectedItems];
        newSelectedItems.splice(index, 1);
        return newSelectedItems;
      }
    });

    setSelectedEquipmentArr((prev) => {
      const index = prev.findIndex(
        (item) => item.equipmentCode === newEqp.equipmentCode
      );
      if (index === -1) {
        return [...prev, newEqp];
      } else {
        const newArr = [...prev];
        newArr.splice(index, 1);
        return newArr;
      }
    });
  };

  const handleImgOrientation = (type, item, index) => {
    let temp = [...selectedEquipmentArr];
    if (type === "add") {
      temp[index].width += 5;
      temp[index].height += 5;
    }
    if (type === "minus") {
      temp[index].width -= 5;
      temp[index].height -= 5;
    }
    if (type === "addZindex") {
      temp[index].zindex += 10;
    }
    if (type === "minusZindex") {
      temp[index].zindex -= 10;
    }
    if (type === "rotateClock") {
      temp[index].rotate += 30;
    }
    if (type === "rotateAntiClock") {
      temp[index].rotate -= 30;
    }
    if (type === "mirrorX") {
      temp[index].mirrorX = !temp[index].mirrorX;
    }
    if (type === "mirrorY") {
      temp[index].mirrorY = !temp[index].mirrorY;
    }
    if (type === "duplicate") {
      const newId = Number(`${item.id}0${item.equipmentCode}${temp.length}`);
      const duplicatedItem = {
        ...item,
        id: newId,
        x: undefined,
        y: undefined,
      };
      temp.push(duplicatedItem);
      setSelectedItems((prev) => [...prev, item.equipmentCode]);
    }

    if (type === "delete") {
      const deletedItemId = item.id;
      temp = temp.filter((equipment) => equipment.id !== deletedItemId);
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) =>
          temp.some((equipment) => equipment.equipmentCode === selectedItem)
        )
      );
    }
    setSelectedEquipmentArr(temp);
  };

  // Save the initial device size
  const initialScreenWidth = window.screen.width;
  const initialScreenHeight = window.screen.height;

  const onSubmitLayout = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    const currentScreenWidth = window.screen.width;
    const currentScreenHeight = window.screen.height;
    const widthRatio = currentScreenWidth / initialScreenWidth;
    const heightRatio = currentScreenHeight / initialScreenHeight;

    const prevewArr = selectedEquipmentArr.map(({ img, ...item }) => ({
      ...item,

      // eqpCode: equipmentCode,
      // eqpName: equipmentName,
      // modifySurgeryLayout: false,

      x: (positions[item.id]?.x || 0) * widthRatio,
      y: (positions[item.id]?.y || 0) * heightRatio,
    }));
    isFromForm && setPreviewLayoutArr(prevewArr);

    // Create final array without images and apply ratio
    const finalArrWithoutImage = selectedEquipmentArr.map(
      ({ img, equipmentCode, equipmentName, reservationId, id, ...item }) => ({
        ...item,
        id: reservationId && reservationId > 0 ? id : 0, // first Time 0
        eqpCode: equipmentCode,
        eqpName: equipmentName,
        reservationId: reservationId || 0,
        modifySurgeryLayout: false,
        //
        x: (positions[id]?.x || 0) * widthRatio,
        y: (positions[id]?.y || 0) * heightRatio,
      })
    );

    isFromForm &&
      setLayoutEquipmentArr &&
      setLayoutEquipmentArr(finalArrWithoutImage);

    // localStorage.setItem("layout", JSON.stringify(finalArrWithoutImage));

    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    ///////////////
    !isFromForm &&
      saveOTReservationLayout(
        finalArrWithoutImage,
        selectedRowData?.OTReservationId
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 302) {
            warningAlert(res.message);
            setOpenBackdrop(false);
          } else if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            setSelectedEquipmentArr([]);
            setSelectedItems([]);
            setInitialPositions({});
            setPositions({});
            handleClose();
          }
        })
        .catch((res) => {
          errorAlert(res.message || res.response.data.message);
          setOpenBackdrop(false);
        });
  };

  // Retrieve layout from local storage and adjust positions based on ratio

  useEffect(() => {}, [previewLayoutArr]);

  const retrieveLayout = (retrivedLayout) => {
    if (retrivedLayout) {
      // const parsedLayout = JSON.parse(retrivedLayout);
      const parsedLayout = retrivedLayout;

      // Calculate ratio of current device size to initial device size
      const widthRatio = window.screen.width / initialScreenWidth;
      const heightRatio = window.screen.height / initialScreenHeight;

      let updatedPositions = {};
      let updatedSelectedEquipmentArr = [];
      let updatedSelectedItems = [];

      parsedLayout?.forEach((item) => {
        updatedPositions[item.id] = {
          x: (item.x || 0) * widthRatio,
          y: (item.y || 0) * heightRatio,
        };

        const equipment = equipmentArr.find(
          (eqp) => eqp.equipmentCode === item.equipmentCode
        );
        if (equipment) {
          updatedSelectedEquipmentArr.push({
            ...item,
            img: equipment.img,
          });
          updatedSelectedItems.push(item.equipmentCode);
        }
      });

      setPositions(updatedPositions);
      setSelectedEquipmentArr(updatedSelectedEquipmentArr);
      setSelectedItems(updatedSelectedItems);
    }
  };

  useEffect(() => {
    !!selectedRowData?.OTReservationId &&
      fetchReservedSurgeryLayout(selectedRowData.OTReservationId)
        .then((response) => response.data)
        .then((res) => {
          // let savedLayout = res.result;
          // const savedLayout = localStorage.getItem("layout");
          retrieveLayout(res.result);
          res.result.length < 1 && warningAlert("Layout Not Found...");
        });
    retrieveLayout(previewLayoutArr);
  }, [selectedRowData, LayoutEquipmentArr, previewLayoutArr]);

  useEffect(() => {
    if (isFromForm && doctorDetailsArr?.length > 0) {
      const reservationSergeries = doctorDetailsArr.map((item) => ({
        id: item?.id,
        label: item["Surgery Name"],
        value: item["Surgery Name"],
      }));

      setSurgeries(reservationSergeries);
    }
  }, [doctorDetailsArr]);

  // const handleLayoutPrint = (contentRef1, contentRef2) => {
  //   const clonedContent2 = contentRef2.current.cloneNode(true);

  //   // Create a container element to hold the cloned content of contentRef1
  //   const container = document.createElement("div");
  //   container.appendChild(contentRef1.current.cloneNode(true));

  //   // Create a hidden iframe
  //   const iframe = document.createElement("iframe");
  //   iframe.style.display = "none";
  //   document.body.appendChild(iframe);

  //   // Write the content into the iframe document
  //   const iframeDocument = iframe.contentWindow.document;
  //   iframeDocument.open();
  //   iframeDocument.write(`
  //   <!DOCTYPE html>
  //   <html>
  //     <head>
  //       <title>Print</title>
  //       <style>
  //         /* Include computed styles of contentRef1 */
  //         ${getComputedStyle(contentRef1.current).cssText}
  //         /* Define additional print styles here */
  //         /* For example: */
  //         body {
  //           font-family: Arial, sans-serif;
  //         }
  //         /* Add a class for the patient info container */
  //         .patient-info-container {
  //           border: 1px solid #b5b2b1;
  //           padding: 15px;
  //           margin-bottom: 10px;
  //           display: grid;
  //           grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  //           background-color: #f2f2f2; /* Gray background color */
  //         }

  //         /* Add a class for the patient info fields */
  //         .patient-info-field {
  //           display: flex;
  //           padding-left: 14px;
  //           flex: 1;
  //           font-size: 24px; /* Increased font size */
  //           line-height: 24px;
  //         }

  //       </style>
  //     </head>
  //     <body>
  //       <div id="pdfContent">
  //         <!-- Patient information container -->
  //         <div class="patient-info-container">
  //           <!-- Patient information fields -->
  //           <div class="patient-info-field">
  //             Patient Name : ${patientInfo && patientInfo["patientName"]}
  //           </div>
  //           <div class="patient-info-field">
  //             MR No.  : ${patientInfo && patientInfo.mrNo}
  //           </div>
  //         </div>

  //         <!-- Cloned content from contentRef2 -->
  //         <div style="margin: 10px;">
  //           ${clonedContent2.innerHTML}
  //         </div>
  //       </div>
  //       <script>
  //         // Print the content
  //         window.print();
  //         // Close the current window after printing
  //         window.close();
  //       </script>
  //     </body>
  //   </html>
  // `);
  //   iframeDocument.close();
  // };

  useEffect(() => {
    if (selectedRowData !== null) {
      fetchPatientInformationForOtDtls(selectedRowData.PatientId, 1)
        .then((response) => response.data)
        .then((res) => {
          setPatientInfo(res.result);
        });
    }
  }, [selectedRowData]);

  return (
    <div className="bg-white p-6 mx-2 justify-center w-[100%]  lg:h-[100%] xl:h-[550px] 2xl:h-[750px]">
      <div className="grid grid-cols-10 gap-2 ">
        <div className=" md:col-span-10 lg:col-span-3 text-black font-bold text-lg pb-3">
          Design Operation Theater Layout
        </div>
        <div className="md:col-span-5 lg:col-span-3 flex justify-end">
          {selectedRowData?.OTHeaderID === undefined && (
            <DropdownField
              control={control}
              error={errors.doctorType}
              name="reservedSergeries"
              // label="Surgery"
              searchIcon={true}
              dataArray={surgeries}
              // inputRef={{
              //   ...register("reservationSurgeries", {
              //     onChange: (e) => {
              //       setValue("doctorName", null);
              //       if (e !== null) {
              //         fetchDoctorsOfType(e.target.value.id)
              //           .then((response) => response.data)
              //           .then((res) => {
              //             setDoctors(res.result);
              //           });
              //       }
              //     },
              //   }),
              // }}
              isSearchable={true}
              isClearable={false}
              placeholder="Surgery"
            />
          )}
        </div>
        <div className="md:col-span-5 lg:col-span-4 flex gap-2 justify-end">
          {selectedRowData?.OTHeaderID === undefined && (
            <CommonButton
              onClick={() => {
                setSelectedEquipmentArr([]);
                setSelectedItems([]);
                setInitialPositions({});
                setPositions({});
              }}
              className="border border-customRed text-customRed hover:bg-red-100 hover:text-gray-600"
              label="Reset"
            />
          )}
          <CommonButton
            onClick={() => {
              // GeneratePrint(layoutRef1, layoutRef2);
              // handleLayoutPrint(layoutRef1, layoutRef2);
              setOpenPrintModal(true);
            }}
            className="bg-sky-500 text-white "
            label="Print Layout"
          />
          {isEditLayout && (
            <CommonButton
              onClick={() => {
                if (isFromForm) {
                  handleFormSubmit();
                  handleClose();
                } else {
                  onSubmitLayout();
                }
              }}
              className="bg-customGreen text-white "
              label={isFromForm ? "Add Layout" : "Update Layout"}
            />
          )}
        </div>
      </div>
      <div>
        <fieldset
          // ref={layoutRef1}
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
        >
          <div className="py-2 grid  grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm ">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfo && patientInfo["patientName"]}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfo && patientInfo.mrNo}
                </span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div className="my-2 border flex border-slate-200 p-2 w-[100%]  overflow-x-auto bg-slate-50 rounded">
        <div className="flex gap-2 ">
          {equipmentArr.map((item) => (
            // <Tooltip title={item.equipmentName} placement="top" key={item.id}>
            <div>
              <div
                className={`h-32 w-32 border rounded-xl p-2 hover:bg-slate-200 items-center justify-center  ${
                  selectedItems.includes(item.equipmentCode)
                    ? "bg-blue-50 border-2 border-blue-500"
                    : ""
                }`}
                onClick={() => {
                  selectedRowData?.OTHeaderID === undefined &&
                    handleEquipmentSelection(item);
                }}
              >
                <img src={item.img} className="h-20 w-20 mx-auto mt-2 " />
              </div>
              <p className="text-center text-gray-500 font-semibold">
                {item.equipmentName}
              </p>
            </div>
            // </Tooltip>
          ))}
        </div>
      </div>
      <div>
        <div
          className="overflow-auto w-full  bg-white shadow-lg lg:h-[200px] xl:h-[400px] 2xl:h-[600px]"
          onMouseUp={selectedRowData?.OTHeaderID === undefined && handleDragEnd}
          onMouseLeave={() => {
            selectedRowData?.OTHeaderID === undefined && setDragging(false);
          }}
        >
          <div className="w-full h-full">
            {selectedEquipmentArr.map((item, index) => {
              return (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      {selectedRowData?.OTHeaderID === undefined ? (
                        <div className=" text-slate-900 cursor-pointer items-center flex gap-3 flex-wrap ">
                          <div
                            onClick={() =>
                              handleImgOrientation("add", item, index)
                            }
                          >
                            <img src={Wide} className="" alt="Wide" />
                          </div>
                          <div
                            onClick={() =>
                              handleImgOrientation("minus", item, index)
                            }
                          >
                            <img src={Short} alt="Short" />
                          </div>
                          <div
                            onClick={() =>
                              handleImgOrientation("addZindex", item, index)
                            }
                          >
                            <img src={BringFront} alt="BringFront" />
                          </div>
                          <div
                            onClick={() =>
                              handleImgOrientation("minusZindex", item, index)
                            }
                          >
                            <img src={BringBack} alt="BringBack" />
                          </div>
                          <div
                            onClick={() =>
                              handleImgOrientation("rotateClock", item, index)
                            }
                          >
                            <Forward30Icon />
                          </div>
                          <div
                            className="transform scale-x-[-1]"
                            onClick={() =>
                              handleImgOrientation(
                                "rotateAntiClock",
                                item,
                                index
                              )
                            }
                          >
                            <Forward30Icon />
                          </div>
                          <div
                            onClick={() =>
                              handleImgOrientation("mirrorX", item, index)
                            }
                          >
                            <FlipIcon />
                          </div>
                          <div
                            className="transform rotate-90"
                            onClick={() =>
                              handleImgOrientation("mirrorY", item, index)
                            }
                          >
                            <FlipIcon />
                          </div>
                          <div
                            onClick={() =>
                              handleImgOrientation("duplicate", item, index)
                            }
                          >
                            <ControlPointDuplicateRoundedIcon />
                          </div>
                          <div
                            className="text-customRed"
                            onClick={() =>
                              handleImgOrientation("delete", item, index)
                            }
                          >
                            <DeleteForeverRoundedIcon />
                          </div>
                        </div>
                      ) : (
                        <div className="text-gray-600">View Only</div>
                      )}
                    </React.Fragment>
                  }
                >
                  <span
                    key={item.id}
                    className={`draggable text-white rounded cursor-grab ${
                      dragging ? "pointer-events-none" : ""
                    }`}
                    style={{
                      position: "absolute",
                      transform: `translate(${positions[item.id]?.x}px, ${
                        positions[item.id]?.y
                      }px)`,
                      zIndex: item.zindex,
                    }}
                    onMouseDown={(e) =>
                      selectedRowData?.OTHeaderID === undefined &&
                      handleDragStart(item.id, e)
                    }
                  >
                    <img
                      src={item.img}
                      style={{
                        height: `${item.height}px`,
                        width: `${item.width}px`,
                        transform: `rotate(${item.rotate}deg) ${
                          item.mirrorX ? "scaleX(-1)" : ""
                        } ${item.mirrorY ? "scaleY(-1)" : ""}`,
                      }}
                    />

                    <p className="text-slate-600 text-center">
                      {item.equipmentName}
                    </p>
                  </span>
                </HtmlTooltip>
              );
            })}
          </div>
        </div>
      </div>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />

      {/*  Print Modal */}

      {openPrintModal && renderPrint()}
    </div>
  );
}

export default OtLayoutFile;
