import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { ModalStyle } from "../../../common/components/ModalStyle";
import {
  getNeedleStickInjuryDetails,
  getNeedleStickInjuryList,
} from "../../services/needleStickInjuryServices/NeedleStickInjuryServices";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";

const NeedleStickInjuryListModal = (props) => {
  const {
    openListingModal,
    setOpenListingModal,
    editData,
    setEditData,
  } = props;
  const [needleStickInjuryListing, setNeedleStickInjuryListing] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(null);

  const { control, watch, reset } = useForm({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");

  function getNeedleStickInjuryListing(forPagination) {
    let listObj = {
      fromDate: getFromDate,
      page: !forPagination ? page : 0,
      size: rowsPerPage,
      toDate: getToDate,
    };
    getNeedleStickInjuryList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setNeedleStickInjuryListing((prevData) => [
            ...prevData,
            ...res.result,
          ]);
        } else {
          setNeedleStickInjuryListing(res.result);
        }
        setCount(res.count);
      });
  }

  useEffect(() => {
    {
      openListingModal && getNeedleStickInjuryListing();
    }
  }, [openListingModal]);

  function handleSelectedRow(row) {
    getNeedleStickInjuryDetails(row?.Id).then((response) => {
      setEditData(response.data.result);
      setOpenListingModal(false);
    });
  }

  return (
    <div>
      <Modal open={openListingModal}>
        <Box sx={ModalStyle}>
          <div
            onClick={() => {
              setOpenListingModal(false);
              reset();
            }}
          >
            <CancelPresentationIconButton />
          </div>
          <div>
            <div className="font-semibold text-center">
              Needle Stick Injury List
            </div>
            <div className="flex space-x-2">
              <div className="w-40">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date "
                  value={new Date()}
                  size="small"
                  inputFormat="yyyy-MM-dd"
                />
              </div>
              <div className="w-40">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date "
                  value={new Date()}
                  size="small"
                  inputFormat="yyyy-MM-dd"
                />
              </div>
              <div className="flex space-x-2">
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  searchIcon={true}
                  onClick={() => {
                    getNeedleStickInjuryListing();
                  }}
                />
                <div className="cursor-pointer">
                  <PrintOnIcon title="Print" onClick={() => {}} />
                </div>
              </div>
            </div>
            <div>
              {needleStickInjuryListing !== undefined &&
              needleStickInjuryListing?.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={needleStickInjuryListing}
                  populateTable={getNeedleStickInjuryListing}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  setCount={setCount}
                  handleSelectedRow={handleSelectedRow}
                  removeHeaders={["Id", "AdmissionId"]}
                />
              ) : (
                <div className="justify-center items-center font-semibold h-96 w-full flex border-2 border-gray-300 rounded my-2">
                  No Records Found...
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NeedleStickInjuryListModal;
