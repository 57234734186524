import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  fetchPendingImplants,
  savePendingImplants,
} from "../../../services/otDetailsServices/OtDetailsServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    lg: "50%",
    sm: "80%",
  },
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

const Actions = [
  {
    id: 0,
    action: "delete",
    isAction: true,
  },
];

export default function PendingImplantModal(props) {
  const {
    open,
    handleClose,
    userActions,
    patientInfo,
    selectedRow,
    otSurgeries,
  } = props;
  const [implantArray, setImplantArray] = useState([]);
  const [delImplantArray, setdelImplantArray] = useState([]);

  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //

  const {
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      isPendingImplantChecked: true,
      implantRemark: "",
    },
  });

  useEffect(() => {
    !!selectedRow?.OTHeaderID && getPendingImplants();
  }, []);

  function getPendingImplants() {
    fetchPendingImplants(selectedRow?.OTHeaderID)
      .then((response) => response.data)
      .then((res) => {
        setImplantArray(res.result);

        if (res.result?.length > 0) {
          setValue("isPendingImplantChecked", true);
          setValue("implantRemark", res.result[0].PendingImplantRemark);
        }
      });
  }

  //patient Documents
  const handleAddData = () => {
    let Rate = getValues("implantRate");
    let Qty = getValues("implantQty");
    let Surgery = getValues("surgeryNameDr");

    var implants = [...implantArray];
    Rate &&
      Qty &&
      Surgery &&
      implants.push({
        Rate: parseInt(Rate),
        Quantity: parseInt(Qty),
        surgeryId: Surgery?.id,
        "Surgery Name": Surgery?.label,
        isDelete: false,
      });
    setImplantArray(implants);
    //setImplantData(implantArr);
    setValue("implantRate", "");
    setValue("implantQty", "");
    setValue("surgeryNameDr", null);
  };

  const renderActions = (row, index) => {
    // const deleteRow = (index) => {
    //   let newTemplateData = [...implantData];
    //   newTemplateData.splice(index, 1);
    //   setImplantData(newTemplateData);
    // };

    const deleteRow = (index) => {
      let temp = [...implantArray];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });


      // Update the state with deleted items
      setdelImplantArray((prev) => [...deletedItems, ...prev]);
      setImplantArray(notDeletedItems);
    };

    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "delete" ? (
                    <Tooltip title={"Delete"}>
                      <DeleteOutlineOutlinedIcon
                        sx={{ color: "#B91C1C" }}
                        onClick={() => {
                          deleteRow(index);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const finalObj = {
      classId: patientInfo?.classId,
      isCheck: getValues("isPendingImplantChecked"),
      isPendingImplants: true,
      opdIpdExternal: selectedRow?.OPD_IPD_External,
      opdIpdExternalId: selectedRow?.OPD_IPD_External_ID,
      otHeaderId: selectedRow?.OTHeaderID,

      otPendingImplantsListDtoList: [...delImplantArray, ...implantArray].map(
        (item) => ({
          rate: parseInt(item.Rate),
          qty: parseInt(item.Quantity),
          chargeId: item.ChargeId || 0,

          surgeryId: item?.SurgeryId || item?.surgeryId,
          isDelete: item?.isDelete ? item?.isDelete : false,
        })
      ),

      pendingImplantsRemark: getValues("implantRemark"),
      tariffId: selectedRow?.TariffId,
    };
    savePendingImplants(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          reset();
          handleClose();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };


  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                Pending Implant
              </span>
            </div>
            <hr className="border my-2 mx-1  border-blue-900" />

            {/* filters */}
            <div className="grid gap-2 w-full items-center ">
              {/* Patient Documents */}
              <div className="flex  items-center">
                <div className="ml-2">
                  <CheckBoxField
                    control={control}
                    name="isPendingImplantChecked"
                  />
                </div>
                <div className="-ml-2 font-bold tracking-wide text-sm  font-Poppins">
                  Pending Implants
                </div>
              </div>

              <div className="md:col-span-2 lg:col-span-1 grid grid-cols-8 gap-3">
                <div className="md:col-span-2 lg:col-span-1">
                  <InputField
                    name="implantRate"
                    variant="outlined"
                    label="Rate"
                    error={errors.implantRate}
                    control={control}
                    type={"number"}
                    onKeyDown={(e) => {
                      if (
                        e?.key === "-" ||
                        e?.key === "+" ||
                        e?.code === "ArrowUp" ||
                        e?.code === "ArrowDown" ||
                        e?.code === "NumpadDecimal"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="md:col-span-2 lg:col-span-1">
                  {/* //first Name// */}
                  <InputField
                    name="implantQty"
                    variant="outlined"
                    label="Quantity"
                    error={errors.implantRate}
                    control={control}
                    type={"number"}
                    onKeyDown={(e) => {
                      if (
                        e?.key === "-" ||
                        e?.key === "+" ||
                        e?.code === "ArrowUp" ||
                        e?.code === "ArrowDown" ||
                        e?.code === "NumpadDecimal"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="md:col-span-3 lg:col-span-4">
                  <DropdownField
                    control={control}
                    error={errors.surgeryNameDr}
                    name="surgeryNameDr"
                    label="surgery Name"
                    searchIcon={true}
                    dataArray={
                      otSurgeries?.length > 0
                        ? otSurgeries
                            ?.filter((item) => item["Surgery Name"] !== "All")
                            .map((item) => ({
                              id: item.SurgeryId,
                              label: item["Surgery Name"],
                              value: item["Surgery Name"],
                              ...item,
                            }))
                        : []
                    }
                    isSearchable={true}
                    isClearable={true}
                    placeholder="surgery Name"
                    menuPlacement={"bottom"}
                  />
                </div>
                <div>
                  <CommonButton
                    label="Add"
                    className="bg-customGreen text-white"
                    onClick={handleAddData}
                  />
                </div>
              </div>
            </div>
            <div className="grid ">
              <div className="">
                {implantArray.length > 0 && (
                  <CommonDynamicTableNew
                    dataResult={implantArray}
                    tableClass="rounded lg:h-36 md:h-36"
                    renderActions={renderActions}
                    actionsRequired={true}
                    removeHeaders={[
                      "ChargeId",
                      "PendingImplantRemark",
                      "surgeryId",
                      "isDelete",
                    ]}
                    highlightRow={false}
                  />
                )}
              </div>
            </div>
            <div className="mt-3">
              <InputArea
                control={control}
                name="implantRemark"
                label="Remark"
                placeholder="Remark"
              />
            </div>

            {/* buttons */}
            <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end mt-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  onClick={handleClose}
                  label="Close"
                />
              </div>
              <div>
                <CommonButton
                  className="bg-customGreen text-white"
                  onClick={() => onSubmit()}
                  label="Save"
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
