import React, { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import { fetchBabySex } from "../../../services/dischargeSummary/DischargeSummaryServices";
import { TextField } from "@mui/material";
import InputArea from "../../../../common/components/FormFields/InputArea";

const GynecologyHistory = () => {
  const [babySex, setBabySex] = useState();

  const { control, register } = useFormContext();

  useEffect(() => {
    fetchBabySex()
      .then((response) => response.data)
      .then((res) => setBabySex(res.result));
  }, []);

  return (
    <div className="grid lg:grid-cols-6 md:grid-cols-2 gap-3">
      <div className="lg:col-span-3 md:col-span-2">
        <InputArea
          name="obstHistory"
          control={control}
          label="Obstetric History"
          placeholder="Obstetric History"
        />
      </div>
      <div className="lg:col-span-3 md:col-span-2">
        <InputArea
          name="historyOfIllness"
          control={control}
          label="History Of Illness"
          placeholder="History Of Illness"
        />
      </div>

      <div className="lg:col-span-1 md:col-span-1">
        <InputField
          name="noOfDeliveries"
          variant="outlined"
          label="No Of Deliveries"
          control={control}
        />
      </div>
      <div className="lg:col-span-1 md:col-span-1">
        <InputField
          name="routeofDeliveries"
          variant="outlined"
          label="Route Of Deliveries "
          control={control}
        />
      </div>

      <div className="lg:col-span-1 md:col-span-1">
        <InputField
          name="lastChildBirth"
          variant="outlined"
          label="Last Child Birth "
          control={control}
          disabled={false}
          inputProps={{ maxLength: 50 }}
        />
      </div>
      <div className="lg:col-span-1 md:col-span-1">
        <InputField
          name="placeOfDeliveries"
          variant="outlined"
          label="Place Of Delivery"
          control={control}
          disabled={false}
          inputProps={{ maxLength: 50 }}
        />
      </div>

      <div className="lg:col-span-2 md:col-span-1">
        <DropdownField
          control={control}
          // error={errors.gender}
          name="sexOfBody"
          label="Baby Info Sex Of Body"
          dataArray={babySex}
          isSearchable={false}
          isClearable={false}
          placeholder="Baby Info Sex Of Body "
        />
      </div>
    </div>
  );
};

export default GynecologyHistory;
