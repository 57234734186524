import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../../common/components/FormFields/InputField";
import { fetchOccupation } from "../../../../../commonServices/miscellaneousServices/MiscServices";

const PatientOfficeDetails = () => {
  const [occupation, setOccupation] = useState();
  const {
    control,
  } = useFormContext();

  useEffect(() => {
    getOccupation();
  }, []);

  // Api to Get Employee name
  const getOccupation = () => {
    fetchOccupation()
      .then((response) => {
        setOccupation(response.data.result);
      })
      .catch(() => {
      });
  };

  return (
    <div>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-3">
        {/* ///Occupation /// */}

        <div>
          <DropdownField
            control={control}
            name="occupation"
            label="Occupation"
            dataArray={occupation}
            isSearchable={false}
            placeholder="Occupation"
            isClearable={false}
          />
        </div>
        {/* ///Office Name/// */}
        <div>
          <InputField
            name="officeName"
            variant="outlined"
            label="Office Name "
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        {/* ///Address/// */}
        <div className="lg:col-span-2">
          <InputField
            name="patientOfficeAddress"
            variant="outlined"
            label="Address "
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientOfficeDetails;
