import { Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
  p: 3,
};

function ConfirmCompleteModal(props) {
  let requiredObj = {};
  let tempArr = props.finalArr;

  const onSubmitDataHandler = () => {
    requiredObj.appointmentDate = "";
    requiredObj.inCompleteReason = "";
    requiredObj.inCompleteReasonId = "";
    requiredObj.ptEngDtlsId = props.rowId;
    requiredObj.reScheduleDate = "";
    requiredObj.reasonId = "";
    requiredObj.rejectReason = "";
    requiredObj.status = "Completed";
    requiredObj.InCompleteReason = "";

    tempArr.push(requiredObj);
    props.setFinalArr(tempArr);

    props.setOpenCompleteModal(false);
  };

  return (
    <form>
      <Modal
        open={props.openCompleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              props.setOpenCompleteModal(false);
            }}
          />

          <form className="">
            <div className="w-full mt-2 border border-gray-300 rounded p-2 ">
              <div className="font-semibold text-lg flex justify-center">
                Do you really want to mark this appointment as "Complete" ??
              </div>
              <div className="flex gap-4 justify-center mt-4">
                <div>
                  <Button
                    onClick={() => {
                      props.setOpenCompleteModal(false);
                    }}
                    variant="outlined"
                    type="button"
                    color="error"
                    sx={{
                      border: "1px solid",
                      textTransform: "none",
                      fontSize: "14px",
                      height: "36.3px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <CommonButton
                    className=" px-3  bg-green-700 text-white rounded text-base"
                    label="Confirm"
                    type="button"
                    onClick={onSubmitDataHandler}
                  />
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </form>
  );
}

export default ConfirmCompleteModal;
