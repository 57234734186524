import apiClient from "../../../http-common";

// get item list
export const fetchAllExpiredItemReturn = (postObj) => {
  return apiClient.post(`/expiredItemsReturn/getItems`, postObj);
};

export const saveExpiredItemReturnData = (postObj) => {
  return apiClient.post(`/expiredItemsReturn/saveExpiredItemReturn`, postObj);
};
