import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";

import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import ApplyButton from "../../../../common/components/Buttons/ApplyButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchCompany } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  getListOfOPDPedingBills,
  saveCompanyBillSettlement,
} from "../../../../ipd/services/billSettlement/IPDCompanySettlementServices";
import { patientAdvanceBalance } from "../../../../ipd/services/billSettlement/IPDSelfBillSettlementServices";
import { opdCompanySettlementPdfPrint } from "../../../services/companySettlementServices";
import BillInformtionModal from "./BillInformtionModal";
import PreviousCompSettlementModal from "./PreviousCompSettlementModal";

const Actions = [
  {
    id: 0,
    action: "checkbox",
    isAction: true,
  },
  {
    id: 1,
    action: "view",
    isAction: false,
  },
  {
    id: 2,
    action: "settleBill",
    isAction: false,
  },
];
const ipdOpdObj = [
  {
    id: "OPD",
    value: "OPD",
    label: "OPD",
  },
];
export default function CompanySettlement() {
  const schema = yup
    .object()
    .typeError("Please Select Company")
    .shape({
      company: yup
        .object()
        .shape({
          label: yup.string().required("Please Select Company"),
          value: yup.string().required("Please Select Company"),
        })
        .required("Please Select Company"),
      tdsAmount: yup
        .number()
        .typeError("Vaild No. is required")
        .max(100, "Invalid"),
    });
  const defaultValues = {
    fromDateCalender: new Date(),
    toDateCalender: new Date(),
    totalAmount: 0,
    tdsAmount: 0,
    netAmount: 0,
    company: null,
  };
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    watch,
    register,
    handleSubmit,
    reset,
    getValues,

    formState: { errors, isValid },
    control,
    setValue,
  } = methods;

  //state varibales

  const [data, setData] = React.useState();
  const [openChild, setOpenChild] = React.useState(false);
  const [company, setCompany] = React.useState(null);
  const [openPreviourReceipt, setOpenPreviourReceipt] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [tdsAmt, setTdsAmt] = React.useState(0);
  const [billIdArr, setBillIdArr] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  const [netBillAmount, setNetBillAmount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [advanceBalance, setAdvanceBalance] = React.useState("");
  const [userActions, setUserActions] = React.useState([]);
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  //function to open the confirmation modal
  const handleOpenChild = () => setOpen(true);
  const handleCloseChild = () => setOpen(false);
  const handleOpenReceipt = () => setOpenPreviourReceipt(true);
  const handleCloseReceipt = () => setOpenPreviourReceipt(false);
  //other variablelet
  let companyDropDownValue = watch("company");
  let netAmountVal = watch("netAmount");
  let tdsAmountVal = watch("tdsAmount");

  //useEffects
  useEffect(() => {
    fetchCompany()
      .then((response) => response.data)
      .then((res) => {
        setCompany(res.result);
      });
    setValue("company", null);
  }, []);

  // set amount to field
  useEffect(() => {
    setValue("totalAmount", amount.toFixed(2));
    setValue("netAmount", amount.toFixed(2));
  }, [amount]);

  // get menu id
  let location = useLocation();

  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  React.useEffect(() => {
    fethCompanyList();
  }, [companyDropDownValue, selectedFromDate, selectedToDate]);

  // company peding list api call fun
  function fethCompanyList() {
    let compPostObj = {
      companyId: companyDropDownValue ? companyDropDownValue.id : null,
      opdIpd: 0,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
    };

    getListOfOPDPedingBills(compPostObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
  }

  // get table row data information

  function displayRowData(e, row) {
    let amt = row["Balance Amount Non Self"];
    let netBillAmt = row["Net Bill Amount"];
    if (e === true) {
      setAmount(amount + Number(amt));
      setNetBillAmount(netBillAmount + Number(netBillAmt));
    } else if (e === false) {
      setAmount(amount - Number(amt));
      setNetBillAmount(netBillAmount - Number(netBillAmt));
    }
  }

  // get patient advance
  function getPatientAdvance(PatientId) {
    patientAdvanceBalance(PatientId)
      .then((response) => response.data)
      .then((res) => {
        setAdvanceBalance(res.result.PatientAdvance);
      });
  }

  // calculate tds and set tds amount
  function tdsCalculation(e) {
    let tdsAmt = parseInt(e.target.value);
    if (tdsAmt === 100) {
      setValue("netAmount", 0);
    } else if (tdsAmt < 100) {
      let finalTds = 0;
      finalTds = (amount / 100) * tdsAmt;

      let netAmt = amount - finalTds;
      setTdsAmt(netAmt);
    }
  }

  function getNetAmount(e) {
    if (tdsAmt === 0) {
      setValue("netAmount", amount.toFixed(2));
    } else if (tdsAmt > 0) {
      setValue("netAmount", tdsAmt.toFixed(2));
    }
  }

  function handleSelectedRow(row) {
    setSelectedRow(row);
  }
  // on submit data handler
  function submitPaymentInfoModal(paymentInfoList) {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);

    let tds = getValues("tdsAmount");

    let companyPostObj = {
      addedBy: userInfoObj.userId,
      balanceBill: selectedRow["Balance Amount Non Self"],
      billId: billIdArr,
      cashCounterId: userInfoObj.cashCounterId,
      isCash: true,
      opdIpd: 0,
      opdIpdExternalId: selectedRow["Opd_Ipd_External_Id"],
      paidAmount: 0,
      paymentId: 0,
      paymentType: 0,
      menuId: location?.state?.menuId,
      privilege: "SettleBill",
      totalAmt: netBillAmount,
      //totalBill: selectedRow["Total Bill Amount"],
      tdsPerAmount: Number(tds),
      netBillAmount: Number(netAmountVal),
    };
    companyPostObj.paymentInfoList = paymentInfoList;
    setOpenBackdrop(true);
    saveCompanyBillSettlement(companyPostObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        handleOpenPrintModal(res.result);
        setData("");
        setTdsAmt(0);
        setValue("toDateCalender", new Date());
        setValue("fromDateCalender", new Date());
        setAmount(0);
        setBillIdArr([]);
        setNetBillAmount(0);
        setValue("netAmount", null);
        reset(defaultValues);

        handleCloseChild();
      });
  }

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (paymentId) => {
    opdCompanySettlementPdfPrint(paymentId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  // print and action mgmt
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "checkbox" ? (
                    <fieldset
                      className="m-0 p-0"
                      onChange={(e, index) => {
                        let tempArr = [...billIdArr];
                        if (e.target.checked === true) {
                          tempArr.push(row.BillId);
                          setBillIdArr(tempArr);
                        } else if (e?.target?.checked === false) {
                          let filteredArr = tempArr.filter(
                            (item) => item !== row.BillId
                          );

                          setBillIdArr(filteredArr);
                        }

                        displayRowData(e?.target?.checked, row);
                      }}
                    >
                      <CheckBoxField
                        control={control}
                        name={`selectedOptions${index}`}
                      />
                    </fieldset>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  function onSubmitDataHandler(data) {}
  return (
    <>
      <div className="w-full grid mt-16 px-6  md:rounded-md ">
        <div>
          <div className="text-center text-2xl text-gray-700 font-Poppins">
            Company Bill Settlement
          </div>
        </div>
        <div className="py-2 ">
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <fieldset className="mx-auto border border-slate-400 w-full rounded z-0 lg:w-auto content-start py-2 items-center">
              <div className=" lg:flex md:grid md:grid-cols-3  px-4 gap-2 items-center">
                <div className="w-full mt-2">
                  <DropdownField
                    control={control}
                    error={errors.company}
                    name="company"
                    dataArray={company}
                    placeholder="Select Company"
                    isClearable={true}
                    isSearchable={true}
                    inputRef={{
                      ...register("company", {
                        onChange: (e) => {
                          if (
                            e.target.value !== "" ||
                            e.target.value !== null
                          ) {
                            setValue("company", e.target.value);
                          } else {
                            setValue("company", null);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="w-full mt-2">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDateCalender"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("fromDateCalender", {
                        onChange: (e) => {
                          if (e.target.value.toString().length === 55) {
                            let dateVal = format(e.target.value, "yyyy-MM-dd");
                            let timeVal = dateVal + "T" + "00:00:00.000Z";
                            setSelectedFromDate(timeVal);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="w-full mt-2">
                  <DatePickerFieldNew
                    control={control}
                    name="toDateCalender"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("toDateCalender", {
                        onChange: (e) => {
                          if (e.target.value.toString().length === 55) {
                            let dateVal = format(e.target.value, "yyyy-MM-dd");
                            let timeVal = dateVal + "T" + "00:00:00:000Z";
                            setSelectedToDate(timeVal);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="mt-2">
                  <CommonButton
                    searchIcon={true}
                    className="bg-customBlue text-white"
                    onClick={() => {
                      fethCompanyList();
                    }}
                  />
                </div>
              </div>
            </fieldset>
          </form>
        </div>

        {companyDropDownValue !== null ? (
          <>
            <div className="flex justify-between my-2 items-center">
              <div className="w-full text-base text-black font-semibold">
                List Of Pending Bills
              </div>

              <div className="">
                {userActions &&
                  userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "View" ? (
                        <CommonButton
                          label="View"
                          className="bg-[#1e3a8a] text-white "
                          onClick={() => {
                            handleOpenReceipt();
                          }}
                        />
                      ) : null}
                    </>
                  ))}
              </div>
            </div>
            {data && data.length > 0 ? (
              <>
                <div className="px-0">
                  <CommonDynamicTableNew
                    dataResult={data}
                    setData={setData}
                    renderActions={renderActions}
                    handleSelectedRow={handleSelectedRow}
                    highlightRow={false}
                    tableClass={"rounded lg:h-52 md:h-80"}
                    removeHeaders={["Expr1","Opd_Ipd_External_Id","Opd_Ipd_External","BillId","PatientId"]}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="text-center mt-4 p-4">No Record Found</div>
              </>
            )}
            <div className="place-items-end">
              <form onSubmit={handleSubmit(submitPaymentInfoModal)}>
                <div className="flex items-center justify-between">
                  <div className="flex gap-8 mt-2 items-center py-4 md:px-1">
                    <div className="flex gap-3 md:text-sm lg:text-sm items-center">
                      <InputField
                        name="totalAmount"
                        disabled={true}
                        variant="outlined"
                        control={control}
                        label="Total Amount ₹"
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </div>
                    {userActions &&
                      userActions.map((obj) => (
                        <>
                          {!obj.isAction && obj.action === "Apply Discount" ? (
                            <>
                              <div className="w-2/12">
                                <InputField
                                  name="tdsAmount"
                                  variant="outlined"
                                  label="T.D.S (Tax)  %"
                                  error={errors.tdsAmount}
                                  control={control}
                                  onWheel={(e) => e.target.blur()}
                                  inputRef={{
                                    ...register("tdsAmount", {
                                      onChange: (e) => {
                                        tdsCalculation(e);
                                      },
                                    }),
                                  }}
                                />
                              </div>
                              <div className="">
                                <ApplyButton
                                  onClick={(e) => {
                                    getNetAmount(e);
                                    // setValue("netAmount", tdsAmount);
                                  }}
                                />
                              </div>
                              <div className="w-2/12">
                                <InputField
                                  name="netAmount"
                                  variant="outlined"
                                  label="Net Amount ₹"
                                  error={errors.netamount}
                                  disabled={true}
                                  control={control}
                                  inputProps={{
                                    style: { textTransform: "capitalize" },
                                  }}
                                />
                              </div>
                            </>
                          ) : null}
                        </>
                      ))}
                  </div>
                  <div className="flex justify-end gap-2 md:pt-1">
                    {userActions &&
                      userActions.map((obj) => (
                        <>
                          {!obj.isAction && obj.action === "SettleBill" ? (
                            <div className="w-auto">
                              <CommonButton
                                label="Reset"
                                className="border border-customRed text-customRed"
                                onClick={() => {
                                  reset(defaultValues);
                                  setValue("toDateCalender", new Date());
                                  setValue("fromDateCalender", new Date());
                                  setTdsAmt(0);
                                  setAmount(0);

                                  setData(null);
                                }}
                              />
                            </div>
                          ) : null}
                          {!obj.isAction && obj.action === "SettleBill" ? (
                            <div className="whitespace-nowrap">
                              <CommonButton
                                className="bg-blue-900  text-white"
                                label="Settle Bill"
                                onClick={() => {
                                  if (
                                    netAmountVal === null &&
                                    netAmountVal > 0
                                  ) {
                                    errorAlert("Please enter a net amount");
                                  } else if (isValid === false) {
                                  } else if (
                                    netAmountVal !== null &&
                                    netAmountVal > 0 &&
                                    isValid === true
                                  ) {
                                    setOpen(true);
                                  }
                                }}
                              />
                            </div>
                          ) : null}
                        </>
                      ))}
                  </div>
                </div>
              </form>
              <CommonBackDrop openBackdrop={openBackdrop} />
            </div>
          </>
        ) : (
          ""
        )}
        <BillInformtionModal
          open={open}
          setOpen={setOpen}
          getValues={getValues}
          handleClose={handleCloseChild}
          submitPaymentInfoModal={submitPaymentInfoModal}
          refundAmount={tdsAmt}
          advanceAmount={advanceBalance}
          rowData={rowData}
          handleCloseChild={handleCloseChild}
          amount={amount}
          setOpenBackdrop={setOpenBackdrop}
        />
        <PreviousCompSettlementModal
          openPreviourReceipt={openPreviourReceipt}
          handleCloseReceipt={handleCloseReceipt}
        />
        <ConfirmationModal
          confirmationOpen={openChild}
          confirmationHandleClose={handleCloseChild}
          confirmationSubmitFunc={data}
          confirmationLabel="Confirmation "
          confirmationMsg="Are you sure want to delete this record ?"
          confirmationButtonMsg="Delete"
        />
      </div>
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}
