import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  EditOnIcon,
  EyeOnIcon,
  PostFumigationOnIcon,
  PreFumigationOnIcon,
  PrePostFumigationOnIcon,
} from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { getBedsByFloor } from "../../../commonServices/bedServices/BedServices";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchFumigationDetails,
  fetchFumigationOrderList,
  saveFumigationChart,
} from "../../services/fumigationChartServices/FumigationChartServices";
import PrePostSwabModal from "./PrePostSwabModal";
import { Tooltip } from "@mui/material";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";

const statusArr = [
  { id: 0, label: "Pending", value: "Pending" },
  { id: 1, label: "Completed", value: "Completed" },
  { id: 2, label: "All", value: "All" },
];

const floor_bedArr = [
  { id: 0, label: "Floor", value: "Floor" },
  { id: 1, label: "Bed No", value: "Bed No" },
];

const Sterilization_Arr = [
  { id: 0, label: "EO", value: "EO" },
  { id: 1, label: "Steam", value: "Steam" },
];

const Water_Arr = [
  { id: 0, label: "Tap Water", value: "Tap Water" },
  { id: 1, label: "RO Water", value: "RO Water" },
];

const FumigationChart = () => {
  let location = useLocation();

  const token = JSON.parse(localStorage.getItem("userInfo"));
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  const [fumigationOrderList, setFumigationOrderList] = useState([]);
  const [fumigationDetails, setFumigationDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [floors, setFloors] = useState([]);
  const [bedsByFloor, setBedsByFloor] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  //
  const [openPrePostSwabModal, setOpenPrePostSwabModal] = useState(false);
  const handleOpenPrePostSwabModal = () => setOpenPrePostSwabModal(true);
  const handleCloenPrePostSwabModal = () => setOpenPrePostSwabModal(false);
  ///

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
    defaultValues: {
      toDate: new Date(),
      fromDate: new Date(),
      status: 2,
      //
      location: true,
      locationDtls: "",
      floor_bed: 0,
      bedNumber: null,
      preSwap: false,
      postSwap: false,
      diagnosis: "",
      UVLight: false,
      fogging: false,
      deepCleaning: false,
      pestControl: false,
      remark: "",
      //new added
      sterilization: 0,
      water: 0,
      controlFor: 0,
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate", new Date());
  let Status = watch("status");
  let Location = watch("location");
  let floor_bed_selected = watch("floor_bed");
  let postSwap_selected = watch("postSwap");

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      getFumigationOrderList();
  }, [FromDate, ToDate, Status]);

  const getFumigationOrderList = (forPaginantion) => {
    const listObj = {
      page: !forPaginantion ? 0 : page,
      size: rowsPerPage,
      cashCounterId: token.cashCounterId,
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      searchType: Number(Status) || 0,
    };
    fetchFumigationOrderList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPaginantion) {
          setFumigationOrderList((prevData) => [...prevData, ...res.result]);
        } else {
          setFumigationOrderList(res.result);
        }
        setCount(res.count);
      });
  };

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    fetchFloor()
      .then((response) => response.data)
      .then((res) => {
        setFloors(res.result);
      });

    //
    getBedsByFloor(token.floorId)
      .then((response) => response.data)
      .then((res) => {
        setBedsByFloor(res.result);
      });
  }, []);

  const getFumigationDetails = (row) => {
    fetchFumigationDetails(row.FID)
      .then((response) => response.data)
      .then((res) => {
        setFumigationDetails(res.result);

        const typeOfLocation = res.result.Location.split(":");

        typeOfLocation[0] === "Floor"
          ? setValue(
              "floor",
              floors?.find((item) => item.label === typeOfLocation[1]),
              {
                shouldValidate: true,
              }
            )
          : typeOfLocation[0] === "Bed No."
          ? setValue(
              "bedNumber",
              bedsByFloor?.find((item) => item.label === typeOfLocation[1]),
              {
                shouldValidate: true,
              }
            )
          : setValue("locationDtls", typeOfLocation[0], {
              shouldValidate: true,
            });

        setValue("location", typeOfLocation[1] ? false : true, {
          shouldValidate: true,
        });

        setValue("floor_bed", typeOfLocation[0].trim() === "Floor" ? 0 : 1, {
          shouldValidate: true,
        });

        setValue("preSwap", res.result.IsPreSwab, {
          shouldValidate: true,
        });
        setValue("postSwap", res.result.IsPostSwab, {
          shouldValidate: true,
        });
        setValue("diagnosis", res.result.Diagnosis, {
          shouldValidate: true,
        });
        setValue("UVLight", res.result.IsUVLight, {
          shouldValidate: true,
        });
        setValue("fogging", res.result.IsFogging, {
          shouldValidate: true,
        });
        setValue("deepCleaning", res.result.IsDeepCleaning, {
          shouldValidate: true,
        });
        setValue("pestControl", res.result.IsPestControl, {
          shouldValidate: true,
        });
        setValue("remark", res.result.remark, {
          shouldValidate: true,
        });
        setValue("sterilization", res.result.sterilization);
        setValue("water", res.result.water);
        setValue("controlFor", res.result.controlFor);
      });
  };

  // const handleSelectedRow = (row) => {
  //   setSelectedRow(row);
  //   getFumigationDetails(row);
  // };

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    const finalObj = {
      addedBy: Number(token.userId),
      cashCounterId: token.cashCounterId,
      completedBy: Number(token.userId),
      dateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      diagnosis: data.diagnosis,
      fid: Number(selectedRow?.FID) || 0,
      hkRemark: "",
      isCompleted: 0,
      isDeepCleaning: Number(data.deepCleaning),
      isFogging: Number(data.fogging),
      isPestControl: Number(data.pestControl),
      isPostSwab: Number(data.postSwap),
      isPreSwab: Number(data.preSwap),
      isUVLight: Number(data.UVLight),
      location: data.location
        ? data.locationDtls
        : !data.location
        ? Number(data.floor_bed) === 0
          ? `Floor:${data.floor.value}`
          : `Bed No.:${data.bedNumber.value}`
        : "",
      postSwabTakenBy: 0,
      remark: data.remark,
      //
      privilege: privilege,
      menuId: location?.state?.menuId,
      //
      sterilization: Number(data.sterilization),
      water: Number(data.water),
      controlFor: Number(data.controlFor),
    };
    saveFumigationChart(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          setFumigationOrderList([]);
          methods.reset();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  const handlePostSwapSubmit = () => {
    const finalObj = {
      fid: Number(selectedRow?.FID) || 0,
      isPostSwab: Number(postSwap_selected),
    };

    saveFumigationChart(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          methods.reset();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction ? (
                <>
                  <div className="flex cursor-pointer items-center gap-2">
                    {actions.action === "PreSwab" && (
                      <PreFumigationOnIcon
                        title="Pre Swab"
                        onClick={() => {
                          setSelectedRow(row);
                          setPrivilege(actions.action);
                          handleOpenPrePostSwabModal();
                        }}
                      />
                    )}
                    {actions.action === "PostSwab" && (
                      <PostFumigationOnIcon
                        title="Post Swab"
                        onClick={() => {
                          setSelectedRow(row);
                          setPrivilege(actions.action);
                          handleOpenPrePostSwabModal();
                        }}
                      />
                    )}
                    {actions.action === "Edit" && (
                      <EyeOnIcon
                        title="View"
                        onClick={() => {
                          setSelectedRow(row);
                          getFumigationDetails(row);
                        }}
                      />
                    )}
                    {/* {actions.action === "Print" && (
                      <div className="text-customGray">
                        <Tooltip title="Print">
                          <LocalPrintshopIcon onClick={() => {}} />
                        </Tooltip>
                      </div>
                    )} */}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="mt-12 mb-2">
      <div className="text-center text-black font-bold text-xl col-span-9 mb-2">
        Fumigation Chart
      </div>
      <form>
        <div className="grid grid-cols-6 gap-3">
          {/* from Date */}
          <div className="">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          {/* from Date */}
          <div className="">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="flex lg:col-span-3 md:col-span-2 gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Status :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="status"
                control={control}
                dataArray={statusArr}
              />
            </div>
          </div>
        </div>
        <p className="mr-3 font-bold tracking-wide text-sm font-Poppins mt-2">
          Fumigation Order List
        </p>
        <div className="">
          {fumigationOrderList.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={fumigationOrderList}
              populateTable={getFumigationOrderList}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              renderActions={renderActions}
              tableClass="lg:h-[350px] 2xl:h-[250px]"
              removeHeaders={[
                "FID",
                "IsCompleted",
                "IsDeepCleaning",
                "IsPestControl",
                "isAuthorized",
                "PreSwabTemplateResult",
                "PostSwabTemplateResult",
              ]}
              // handleSelectedRow={handleSelectedRow}
            />
          ) : (
            <p className="text-center my-6 ">No Data Found</p>
          )}
        </div>
        <div className="grid lg:grid-cols-10 md:grid-cols-4 items-center gap-3 mt-3">
          <div
            className=""
            onClick={() => {
              setValue("bedNumber", null);
              setValue("floor", null);
            }}
          >
            <CheckBoxField control={control} name="location" label="Location" />
          </div>
          <div className="flex lg:col-span-2 md:col-span-2 gap-3  items-center">
            {!Location && (
              <div className="mt-1">
                <fieldset
                  disabled={Location}
                  onClick={() => {
                    setValue("bedNumber", "");
                    setValue("floor", null);
                  }}
                >
                  <RadioField
                    label=""
                    name="floor_bed"
                    control={control}
                    dataArray={floor_bedArr}
                  />
                </fieldset>
              </div>
            )}
            {!Location ? (
              Number(floor_bed_selected) === 1 ? (
                <div className="w-52">
                  <DropdownField
                    control={control}
                    // error={errors.MLCType}
                    name="bedNumber"
                    placeholder="bed number"
                    dataArray={bedsByFloor}
                    isSearchable={false}
                  />
                </div>
              ) : (
                <div className="w-52">
                  <DropdownField
                    control={control}
                    // error={errors.MLCType}
                    name="floor"
                    placeholder="Floor"
                    dataArray={floors}
                    isSearchable={false}
                  />
                </div>
              )
            ) : (
              <div className="">
                <InputField
                  name="locationDtls"
                  label={"Location"}
                  variant="outlined"
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            )}
          </div>
          <div className="">
            <CheckBoxField control={control} name="preSwap" label="Pre Swab" />
          </div>
          <div
            className="flex col-span-5 items-center"
            onClick={(e) => {
              e.target.checked &&
                selectedRow !== null &&
                setOpenConfirmationModal(true);
            }}
          >
            <CheckBoxField
              control={control}
              name="postSwap"
              label="Post Swab"
              disabled={
                (selectedRow === null && true) || fumigationDetails?.IsPostSwab
              }
            />
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins text-customRed">
              ( Click me after complete the fumigation for mark post swab is
              taken )
            </p>
          </div>

          <div className="col-span-5 lg:col-span-10">
            <InputArea
              control={control}
              //{...register("otherDtls")}
              name="diagnosis"
              label="Diagnosis"
              placeholder="Diagnosis"
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <p className="mr-3 font-bold tracking-wide text-sm font-Poppins mt-2">
            Fumigation Type
          </p>
          <div className="">
            <CheckBoxField control={control} name="UVLight" label="UV Light" />
          </div>
          <div className="">
            <CheckBoxField control={control} name="fogging" label="Fogging" />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="deepCleaning"
              label="Deep Cleaning"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="pestControl"
              label="Pest Control"
            />
          </div>
        </div>

        <div className="flex gap-5 flex-wrap">
          <div className="flex lg:col-span-3 md:col-span-2 gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Sterilization :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="sterilization"
                control={control}
                dataArray={Sterilization_Arr}
              />
            </div>
          </div>
          <div className="flex lg:col-span-3 md:col-span-2 gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Water :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="water"
                control={control}
                dataArray={Water_Arr}
              />
            </div>
          </div>
          <div className="flex lg:col-span-3 md:col-span-2 gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Control For :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="controlFor"
                control={control}
                dataArray={Sterilization_Arr}
              />
            </div>
          </div>
        </div>
        <div className="">
          <InputArea
            control={control}
            //{...register("otherDtls")}
            name="remark"
            label="Remark"
            placeholder="Remark"
          />
        </div>

        <div className="gap-3 my-3 flex justify-end">
          {userActions.map((actions) => (
            <>
              {!actions.isAction ? (
                <>
                  <div className="flex cursor-pointer items-center gap-3">
                    {actions.action === "Create" && (
                      <>
                        <div className="gap-3 my-3 flex justify-end">
                          <div>
                            <CommonButton
                              label="Reset"
                              className="border border-customRed text-customRed"
                              onClick={() => {
                                methods.reset();
                                setSelectedRow(null);
                              }}
                            />
                          </div>
                          {selectedRow === null && (
                            <div onClick={() => setPrivilege(actions.action)}>
                              <CommonButton
                                label="Save"
                                className="bg-customGreen text-white"
                                onClick={methods.handleSubmit(onSubmit)}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>

      {openPrePostSwabModal ? (
        <PrePostSwabModal
          open={openPrePostSwabModal}
          setOpen={setOpenPrePostSwabModal}
          handleOpen={handleOpenPrePostSwabModal}
          handleClose={handleCloenPrePostSwabModal}
          selectedRow={selectedRow}
          populateTable={getFumigationOrderList}
          menuId={location?.state?.menuId}
          privilege={privilege}
          userActions={userActions}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => {
          selectedRow !== null
            ? handlePostSwapSubmit()
            : handleFormSubmit(allFormData);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default FumigationChart;
