import React from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

function CancelPresentationIconButton({ onClick }) {
  return (
    <div className="grid grid-cols-1 w-full">
      <CancelPresentationIcon
        className="absolute top-2 right-8 lg:right-4 text-red-700  rounded cursor-pointer"
        onClick={onClick}
      />
    </div>
  );
}

export default CancelPresentationIconButton;
