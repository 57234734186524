import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { blobToBase64 } from "../../../common/components/printModal/BlobToB64";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import NumberOfCopiesModal from "../../../common/components/printModal/NumberOfCopiesModal";
import { routeToPrinterDevice } from "../../../commonServices/barCodeServices/BarCodeServices";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  acceptDietOrder,
  addChargesData,
  addNewDietOrder,
  fetchAllergies,
  fetchDietPlan,
  fetchDietServices,
  fetchDietTypes,
  fetchDitician,
  fetchFoodTypes,
} from "../../services/diet/dietetianmoduleservices/DietetianModuleServices";
import {
  fetchBarcodedietOrderInfo,
  getDietOrderPatientList,
} from "../../services/diet/dietorder/DietChartOrderServices";
import DietOrderTable from "./dietorder/DietOrderTable";
import DietetianModule from "./dietorder/dietetianmodule/DietetianModule";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  overflowY: "scroll",
  height: "85%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  px: 2,
};

const schema = yup.object().shape({
  // dieOptions: yup.string().required(),
  // dieService: yup
  //   .object()
  //   .nullable()
  //   .when("dieOptions", (option, schema) => {
  //     if (
  //       option &&
  //       option.value &&
  //       (option.value === "Lunch" || option.value === "Dinner")
  //     ) {
  //       return schema
  //         .shape({
  //           value: yup.string().required("Please Select Staff Name"),
  //           label: yup.string().required("Please Select Staff Name"),
  //         })
  //         .required("Required");
  //     } else {
  //       return schema;
  //     }
  //   }),
});

export default function DietOrder() {
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  const defaultValues = {
    floor: {
      id: getUserInfo?.floorId,
      label: getUserInfo?.floor,
      value: getUserInfo?.floor,
    },
    dieOptions: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [dietOrderIdArray, setDietOrderIdArray] = useState([]);
  const [ditician, setDitician] = useState(null);
  const [floor, setFloors] = useState([]);
  const [floorId, setFloorID] = useState(getUserInfo?.floorId);
  const [data, setData] = useState({ result: [], actions: [] });
  const [patientCount, setPatientCount] = useState(null);
  const [dataDietOrderList, setDataDietOrderList] = useState([]);
  //
  const [dietationModuleList, setDietatianModuleList] = useState([]);
  const [finalDataDietOrder, setFinalDataDietOrder] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [count, setCount] = useState();
  const [dietOptionId, setDietOpionId] = useState(null);
  const [privilege, setPrivilege] = useState("");

  const [userActions, setUserActions] = useState([]);
  const [viewOrderMenuId, setViewOrderMenuId] = useState(null);
  const [listActions, setListActions] = useState([]);

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const handleClosecharges = () => {
    if (chargesConfirmation) {
      setChargesConfirmation(false);
    }
  };

  //DietetianModule Modal

  const [populateDietTale, setPopulateDietTale] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(false);
  const [toDate, setToDate] = React.useState(false);
  const [selectedUnit, setSelectedUnit] = React.useState(null);
  const [selectAllFlag, setSelectAllFlag] = React.useState(false);
  const [viewOrderModal, setViewOrderModal] = useState(false);
  const handleViewOrderModalOpen = () => setViewOrderModal(true);
  const handleViewOrderModalClose = () => setViewOrderModal(false);

  //Accept Modal
  const [acceptOpenModal, setAcceptOpenModal] = useState(false);
  const handleAcceptModalOpen = () => setAcceptOpenModal(true);
  const handleAcceptModalClose = () => setAcceptOpenModal(false);
  const [spinner, setSpinner] = useState(false);

  //Input Fields
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [instructon, setInstructions] = useState();
  const [inputInstructionValue, setInputInstructonValue] = useState("");
  const [openInstructonModal, setOpenInstructonModal] = useState(false);
  const handleCloseInstructonModal = () => {
    setOpenInstructonModal(false);
  };

  const [selectedSpecialRowIndex, setSelectedSpecialRowIndex] = useState(null);
  const [openSpecialInstModal, setOpenSpecialInstModal] = useState(false);
  const [openallergicto, setOpenallergicto] = useState(false);

  const handleCloseSpecialInstModal = () => {
    setOpenSpecialInstModal(false);
  };

  const handaleAllergictoModal = () => {
    setOpenallergicto(false);
  };

  const [specialInstruction, setSpecialInstruction] = useState("");
  const [inputSpecialInstValue, setInputSpecialInstValue] = useState("");
  const [dietServices, setDietServices] = useState();
  //barcode Related

  const [row_selected, setRow_selected] = useState(null);
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [barcodeResponse, setBarcodeResponse] = useState(null);

  const [dietPlan, setDietPlan] = useState(null);
  const [dietTypes, setDietTypes] = useState(null);
  const [allergiesTo, setAllergiesTo] = useState(null);
  const [foodTypes, setFoodTypes] = useState(null);
  const [dietOptions, setDietOptions] = useState(null);
  ///

  const [checkLength, setCheckLength] = useState([]);

  const location = useLocation();
  let dieOptions = watch("dieOptions");
  let getdieService = watch("dieService");

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    fetchFloor().then((response) => {
      setFloors(response.data.result);
    });

    fetchDitician().then((response) => {
      setDitician(response.data.result);
    });

    fetchDietServices().then((response) => {
      setDietServices(response.data.result);
    });

    getUserInfo?.floorId !== null &&
      getUserInfo?.floorId > 1 &&
      getUserInfo?.floor &&
      setValue("floor", {
        id: getUserInfo?.floorId,
        label: getUserInfo?.floor,
        value: getUserInfo?.floor,
      });
  }, []);

  const filterData = () => {
    !!floorId ? populateTable() : warningAlert("Kindly select floor.");
  };

  // useEffect(() => {
  //   if (floorId !== null) {
  //     populateTable();
  //   }
  // }, []);

  useEffect(() => {
    if (dieOptions !== null) {
      setDietOpionId(dieOptions?.id);
    }
  }, [dieOptions]);

  const populateTable = (forPagination) => {
    const defaultObj = {
      dietOptionId: dieOptions?.id,
      floorId: floorId,
      page: !forPagination ? 0 : page,
      searchString: "",
      size: rowsPerPage,
    };
    !forPagination && setSpinner(true);
    getDietOrderPatientList(defaultObj)
      .then((response) => {
        setSpinner(false);
        setCount(response.data.count);
        return response.data;
      })

      .then((res) => {
        res.result.list.forEach((object) => {
          object.isChecked = false;
        });

        setData(res);

        setPatientCount(res.result.patientCount);
        setSpinner(false);

        let updatedResult = res.result.list.map((item) => ({
          ...item,
          "Allergic To":
            item?.["Allergic To"] !== null
              ? JSON.parse(item?.["Allergic To"])?.map((item) => ({
                  id: item.id,
                  label: item.description,
                  value: item.description,
                }))
              : [],
        }));

        setDataDietOrderList((prevData) => [...updatedResult]);
        // if (forPagination) {
        //   setDataDietOrderList((prevData) => [...prevData, ...updatedResult]);
        // } else {
        //   setDataDietOrderList(updatedResult);
        //   setPage(0);
        // }
      })
      .catch((error) => {
        errorAlert(error.message);
        handleClosePost();
        setSpinner(false);
      });
  };

  function postDietOrder(obj) {
    addNewDietOrder(location?.state?.menuId, privilege, obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          populateTable();
          setValue("dieService", null);
          //setOpenPrintModal(true)
          handleOpenPrintModal("barcode");
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    postDietOrder(finalData);
  }

  function onSubmitInstructions() {
    let tempArr = [...dataDietOrderList];

    let updated = tempArr.map((item) =>
      item.admissionId === selectedRowIndex.admissionId
        ? { ...item, Instructions: inputInstructionValue }
        : { ...item }
    );

    handleCloseInstructonModal();
    setDataDietOrderList(updated);
  }

  function onSubmitSpecialInstructions() {
    let tempArr = [...dataDietOrderList];

    let updated = tempArr.map((item) =>
      item.admissionId === selectedSpecialRowIndex.admissionId
        ? { ...item, "Special Instructions": inputSpecialInstValue }
        : { ...item }
    );

    handleCloseSpecialInstModal();
    setDataDietOrderList(updated);
  }

  const onSubmitDataHandler = () => {
    if (!!dieOptions) {
      let CheckedArr =
        dataDietOrderList?.filter((item) => item.isChecked) || [];
      setRow_selected(CheckedArr);

      let finalArr = CheckedArr?.map((item) => ({
        addedBy: getUserInfo.userId,
        admissionId: item.admissionId,
        calories: item?.Calories,
        cho: item.Cho,
        diagnosis: item.diagnosis,
        dietOptionId: dieOptions?.id || item.dietOptionId,
        dietPlanId: item.dietPlanMasterId,
        dietTypeId: item.dietPlanTypeId,
        doctorId: item.admittingDocId,
        fat: item.Fat,
        foodTypeId: item.foodTypeId,
        instruction: item.Instructions,
        specialInstructions: item?.["Special Instructions"],
        opdIpd: 1,
        protein: item.Protein,
        allergicTo:
          item !== null || (item !== undefined && !!item?.["Allergic To"])
            ? item?.["Allergic To"]?.map((item) => ({
                description: item.value,
                id: item.id,
              }))
            : null,
      }));

      setOpenPost(true);

      setFinalData(finalArr);
    } else {
      warningAlert("Kindly select Diet Option.");
    }
  };

  const onSubmitDitetianModule = () => {
    let CheckedArr = dietationModuleList?.filter((item) => item.checked) || [];

    let finalArr = CheckedArr?.map((item) => ({
      addedBy: getUserInfo.userId,
      dietChartId: item?.DietChartId,
      admissionId: item.AdmissionId,
      diagnosis: item.Diagnosis,
      dietOptionId: item?.DietOptionID,
      dietPlanId: item.DietPlanId,
      dietTypeId: item.DietTypeId,
      // doctorId: item.admittingDocId,
      // fat: item.Fat,
      foodTypeId: item.FoodTypeid,
      instruction: item.Instructions,
      opdIpd: 1,
      // protein: item.Protein,
      allergicTo:
        item !== null || (item !== undefined && !!item?.AllergicTo)
          ? item?.AllergicTo?.map((item) => ({
              description: item.label,
              id: item.id,
            }))
          : null,
    }));

    setOpenPost(true);
    setFinalData(finalArr);
  };

  console.log("dataResult123", dietationModuleList);

  function addAcceptRecord() {
    ///in all case accept,reject and approve same service is calling and it distinngushed with thee hellp of privilege
    setAcceptOpenModal(false);

    const acceptObj = {
      fromDate: fromDate,
      toDate: toDate,
      dietOrderId: dietOrderIdArray,
      menuId: viewOrderMenuId ? viewOrderMenuId : location.state.menuId,
      privilege: privilege,
      isSelectAll: selectAllFlag,
      unitId: selectedUnit,
    };

    acceptDietOrder(acceptObj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          populateTable();
          setPopulateDietTale(true);
        }
      })
      .catch((error) => {
        errorAlert(error?.response?.data?.message || error.message);
      });
  }

  const [chargesConfirmation, setChargesConfirmation] = useState(false);

  const postCharges = (objDatta) => {
    addChargesData(objDatta)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setChargesConfirmation(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const addChargesDite = () => {
    let tempArr = dataDietOrderList.filter((item) => item.isChecked);

    let objData = {
      admissionId: tempArr.map((list) => list.admissionId),
      menuId: location.state.menuId,
      privilege: "Charges",
      serviceId: getdieService?.id,
    };

    postCharges(objData);
    setChargesConfirmation(true);
  };

  // const renderPrint = () => {
  //   setTimeout(() => {
  //     setOpenPrintModal(false);
  //   }, 1000);

  //   return (
  //     <div className="hidden">
  //       <DietOrderBarcode row={row_selected} />
  //     </div>
  //   );
  // };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(barcodeResponse);

    const obj = [
      {
        printerName: defaultPrinters?.barCodePrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: defaultPrinters?.barcodeSize,
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setBarcodeResponse(null);
    });
  };

  const handleOpenPrintModal = (type, obj) => {
    if (type === "barcode") {
      let selectedData = row_selected?.map((item) => ({
        admissionId: item.admissionId,
        dietChartId: item.dietChartId,
        dietOptionId: dieOptions?.id,
      }));
      let barcodeObj =
        selectedData?.length > 0 ? [...(selectedData || [])] : [...(obj || [])];

      // CheckedArr.map(({ admissionId }) => admissionId)

      fetchBarcodedietOrderInfo(obj || barcodeObj).then((response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setBarcodeResponse(response);
        } else {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });
    }
  };

  useEffect(() => {
    fetchDietPlan().then((response) => {
      setDietPlan(response.data.result);
    });

    fetchDietTypes().then((response) => {
      setDietTypes(response.data.result);
    });

    fetchAllergies().then((response) => {
      setAllergiesTo(response.data.result);
    });

    fetchFoodTypes().then((response) => {
      setFoodTypes(response.data.result);
    });

    fetchDitician().then((response) => {
      setDietOptions(response.data.result);
    });
  }, []);

  return (
    <div>
      <div className="text-center text-black font-bold text-lg pt-12">
        Diet Order
      </div>
      <Box className="w-full grid py-2 ">
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="w-full">
            <div className="grid grid-cols-4 gap-2 py-2">
              <div>
                <DropdownField
                  control={control}
                  name="floor"
                  label="Floor"
                  placeholder="Floor"
                  dataArray={floor}
                  isClearable={true}
                  inputRef={{
                    ...register("floor", {
                      onChange: (e) => {
                        if (e.target.value) {
                          setFloorID(e.target.value.id);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div>
                <DropdownField
                  control={control}
                  name="dieOptions"
                  label="Diet Options"
                  placeholder="Diet Options"
                  dataArray={ditician}
                  isClearable={true}
                />
              </div>
              <div className="flex gap-2">
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  searchIcon={true}
                  onClick={filterData}
                />
              </div>
              <div className="grid justify-end">
                {userActions.map((obj) => (
                  <>
                    {obj.action === "View" ? (
                      <>
                        <CommonButton
                          type="button"
                          className="bg-customBlue text-white"
                          label="View Orders"
                          onClick={() => {
                            handleViewOrderModalOpen();
                            setViewOrderMenuId(obj.menuId);
                          }}
                        />
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>

            {spinner ? (
              <div className=" grid justify-center items-center my-32">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="w-full">
                  <div className="grid grid-cols-3 gap-2 w-full">
                    <div className="col-span-3 w-full ">
                      {dataDietOrderList?.length > 0 ? (
                        <DietOrderTable
                          dieOptions={dieOptions}
                          dataDietOrderList={dataDietOrderList?.map((item) => ({
                            "Diet Plan Name": item["Diet Plan Name"],
                            "Diet Type": item["Diet Type"],
                            "Food Type": item["Food Type"],
                            Remark: item["Instructions"],
                            "Special Instructions":
                              item["Special Instructions"],
                            "Allergic To": item["Allergic To"],
                            "Diet Option": item["Diet Option"],
                            Diagnosis: item["diagnosis"],
                            ...item,
                          }))}
                          setDataDietOrderList={setDataDietOrderList}
                          setFinalDataDietOrder={setFinalDataDietOrder}
                          data={data}
                          setData={setData}
                          populateTable={populateTable}
                          //  tableApiFunc={getDietOrderPatientList}
                          page={page}
                          setPage={setPage}
                          rowsPerPage={rowsPerPage}
                          setRowsPerPage={setRowsPerPage}
                          count={count}
                          dietOptionId={dieOptions?.id}
                          floorId={floorId}
                          ///Inpuut Fields
                          selectedIndex={selectedIndex}
                          setSelectedIndex={setSelectedIndex}
                          selectedRowIndex={selectedRowIndex}
                          setSelectedRowIndex={setSelectedRowIndex}
                          instructon={instructon}
                          setInstructions={setInstructions}
                          inputInstructionValue={inputInstructionValue}
                          setInputInstructonValue={setInputInstructonValue}
                          openInstructonModal={openInstructonModal}
                          setOpenInstructonModal={setOpenInstructonModal}
                          handleCloseInstructonModal={
                            handleCloseInstructonModal
                          }
                          onSubmitInstructions={onSubmitInstructions}
                          ///
                          selectedSpecialRowIndex={selectedSpecialRowIndex}
                          setSelectedSpecialRowIndex={
                            setSelectedSpecialRowIndex
                          }
                          specialInstruction={specialInstruction}
                          setSpecialInstruction={setSpecialInstruction}
                          inputSpecialInstValue={inputSpecialInstValue}
                          setInputSpecialInstValue={setInputSpecialInstValue}
                          openSpecialInstModal={openSpecialInstModal}
                          setOpenSpecialInstModal={setOpenSpecialInstModal}
                          handleCloseSpecialInstModal={
                            handleCloseSpecialInstModal
                          }
                          onSubmitSpecialInstructions={
                            onSubmitSpecialInstructions
                          }
                          handaleAllergictoModal={handaleAllergictoModal}
                          openallergicto={openallergicto}
                          setOpenallergicto={setOpenallergicto}
                          patientCount={patientCount}
                          setCheckLength={setCheckLength}
                          checkLength={checkLength}
                          //barcode
                          setRow_selected={setRow_selected}
                          handleOpenPrintModal={handleOpenPrintModal}
                          dietPlan={dietPlan}
                          dietTypes={dietTypes}
                          allergiesTo={allergiesTo}
                          foodTypes={foodTypes}
                          dietOptions={dietOptions}
                        />
                      ) : (
                        <div className="flex justify-center my-28 font-bold text-gray-600">
                          No Record Found...
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex justify-between items-center">
              <div className="w-[45%]">
                {dataDietOrderList?.length > 0 &&
                dataDietOrderList?.some(
                  (item) =>
                    item.isChecked === true &&
                    (item["Diet Option"] || dieOptions?.label) &&
                    (item["Diet Option"] === "Dinner" ||
                      item["Diet Option"] === "Lunch" ||
                      dieOptions?.label === "Dinner" ||
                      dieOptions?.label === "Lunch")
                ) ? (
                  <div className="w-[100%]">
                    <div className="flex gap-3">
                      <DropdownField
                        control={control}
                        name="dieService"
                        label="Diet Service"
                        placeholder="Diet Service"
                        isClearable={true}
                        dataArray={dietServices}
                        error={errors.dieService}
                      />

                      {userActions.map((obj) => (
                        <>
                          {!obj.isAction && obj.action === "Charges" ? (
                            <>
                              {dataDietOrderList?.length > 0 &&
                              dataDietOrderList?.some(
                                (item) => item.isChecked
                              ) ? (
                                <>
                                  {/* {checkLength.length > 0 ? ( */}
                                  <CommonButton
                                    type="button"
                                    className="bg-customGreen text-white whitespace-nowrap"
                                    label="Add Charges"
                                    onClick={() => {
                                      let bedNumbers = dataDietOrderList
                                        ?.filter(
                                          (val) =>
                                            val?.isChecked === true &&
                                            val?.isDischargeInitiated === true
                                        )
                                        ?.map((obj) => obj?.bedNo);
                                      console.log("bedNumbers123", bedNumbers);

                                      let restrictCharges =
                                        dataDietOrderList?.some(
                                          (val) =>
                                            val?.isChecked === true &&
                                            val?.isDischargeInitiated === true
                                        );
                                      if (!restrictCharges) {
                                        if (!!getdieService) {
                                          setChargesConfirmation(true);
                                        } else {
                                          setError("dieService", {
                                            type: "custom",
                                            message: "required",
                                          });
                                        }
                                      } else {
                                        warningAlert(
                                          `Discharge initiated against ${bedNumbers} this bed No.`
                                        );
                                      }
                                    }}
                                  />
                                  {/* ) : null} */}
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="flex justify-end gap-2">
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <>
                        {dataDietOrderList?.length > 0 &&
                        dataDietOrderList?.some(
                          (item) => item.isChecked === true
                        ) ? (
                          <>
                            {/* {checkLength.length > 0 ? ( */}
                            <CommonButton
                              type="submit"
                              className="bg-customGreen text-white"
                              label="Give Order"
                              onClick={() => {
                                setPrivilege(obj.action);
                              }}
                            />
                            {/* ) : null} */}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </div>
        </form>
      </Box>

      <Modal open={viewOrderModal}>
        <Box sx={ModalStyle}>
          <div
            className={`${
              viewOrderModal ? "mt-0" : ""
            } grid grid-cols-1 md:grid-cols-1 w-full`}
          >
            <CancelPresentationIconButton
              onClick={() => {
                handleViewOrderModalClose();
              }}
            />
          </div>
          <DietetianModule
            viewOrderModal={viewOrderModal}
            dietOrderIdArray={dietOrderIdArray}
            setDietOrderIdArray={setDietOrderIdArray}
            viewOrderMenuId={viewOrderMenuId}
            listActions={listActions}
            setListActions={setListActions}
            isFromDietOrder={true}
            dataDietOrderList={dataDietOrderList}
            selectAllFlag={selectAllFlag}
            setSelectAllFlag={setSelectAllFlag}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            setFromDate={setFromDate}
            setToDate={setToDate}
            populateDietTale={populateDietTale}
            setPopulateDietTale={setPopulateDietTale}
            dieOptions={dieOptions}
            dietPlan={dietPlan}
            dietTypes={dietTypes}
            allergiesTo={allergiesTo}
            foodTypes={foodTypes}
            dietOptions={dietOptions}
            dietationModuleList={dietationModuleList}
            setDietatianModuleList={setDietatianModuleList}
          />
          {dietOrderIdArray.length > 0 ? (
            <div className="flex justify-end gap-2">
              {userActions?.map((action) => (
                <>
                  {!action.isAction && action.action === "Edit" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customGreen text-white"
                        label="Update"
                        onClick={() => {
                          setPrivilege(action.action);
                          onSubmitDitetianModule();
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
              {listActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Reject" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customBlue text-white"
                        label="Reject"
                        onClick={() => {
                          setPrivilege(obj.action);
                          // handleRejectModalOpen();
                          handleAcceptModalOpen();
                        }}
                      />
                    </>
                  ) : null}
                  {!obj.isAction && obj.action === "Approve" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customGreen text-white"
                        label="Approve"
                        onClick={() => {
                          setPrivilege(obj.action);
                          // handleApproveModalOpen();
                          handleAcceptModalOpen();
                        }}
                      />
                    </>
                  ) : null}
                  {/*!obj.isAction && obj.action === "Accept" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customGreen text-white"
                        label="Accept"
                        onClick={() => {
                          setPrivilege(obj.action);
                          handleAcceptModalOpen();
                        }}
                      />
                    </>
                      ) : null*/}
                </>
              ))}
            </div>
          ) : null}
        </Box>
      </Modal>

      {acceptOpenModal ? (
        <ConfirmationModal
          confirmationOpen={acceptOpenModal}
          confirmationHandleClose={handleAcceptModalClose}
          confirmationSubmitFunc={addAcceptRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure ?"
          confirmationButtonMsg="Confirm"
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this records ?"
        confirmationButtonMsg="Add"
      />
      <ConfirmationModal
        confirmationOpen={chargesConfirmation}
        confirmationHandleClose={handleClosecharges}
        confirmationSubmitFunc={addChargesDite}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this records ?"
        confirmationButtonMsg="Add"
      />

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={() => {
            setOpenPrintModal(true);
          }}
          handleClose={() => {
            setOpenPrintModal(false);
          }}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}
    </div>
  );
}
