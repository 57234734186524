import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Box, Checkbox, Modal, TextField, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BarcodeOnIcon } from "../../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CreateableSelect from "../../../../common/components/FormFields/CreateableSelect";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import TableDropDownField from "../../../../common/components/FormFields/TableDropDownField";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import {
  fetchAllergies,
  fetchDietPlan,
  fetchDietTypes,
  fetchDitician,
  fetchFoodTypes,
} from "../../../services/diet/dietetianmoduleservices/DietetianModuleServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 1,
};
const ModalStyleAllergiesTo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 1,
};
//set descending sort order
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

//set sort desc
const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export default function DietOrderTable(props) {
  const {
    dataDietOrderList,
    setDataDietOrderList,

    setSelectedIndex,
    selectedIndex,
    setSelectedSpecialIndex,

    page,
    setPage,
    rowsPerPage,
    count,
    populateTable, //to get data.
    dieOptions,
    dietPlan,
    dietTypes,
    allergiesTo,
    foodTypes,
    dietOptions,
  } = props;

  const { watch, register, handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [rowIndex, setRowIndex] = React.useState(null);
  //
  const [isLoading, setIsLoading] = React.useState(false);
  const [pagesPassed, setPagesPassed] = React.useState(0);
  const [newPageVal, setNewPageVal] = React.useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  // const [row_selected,setRow_selected]=useState(null)
  //
  // const [openPrintModal, setOpenPrintModal] = React.useState(false);

  ///
  let dietPlanName = watch(`DietPlanName${props.selectedIndex}`);
  let dietTypeName = watch(`DietTypeName${props.selectedIndex}`);
  let foodTypeName = watch(`FoodTypeName${props.selectedIndex}`);
  let dietOptionName = watch(`DietOptionName${props.selectedIndex}`);
  let Instructions = watch(`Instructions${props.selectedIndex}`);

  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(dataDietOrderList[0]);

  const headers = removeHeaders(allHeaders, [
    "dietPlanMasterId",
    "dietPlanTypeId",
    "foodTypeId",
    "dietOptionId",
    "patientName",
    "doctorName",
    "age",
    "admissionId",
    "patientId",
    "bedId",
    "bedNo",
    "mrNo",
    "admittingDocId",
    "isTransfer",
    "admissionDate",
    "investigation",
    "isChecked",
    "bedId",
    "bedId",
    "companyId",
    "companyName",
    "Diet Option",
    "Instructions",
    "diagnosis",
    "dietChartId",
  ]);

  ///////
  const handlePageChange = (event, newPage) => {
    setNewPageVal(newPage);
    if (newPage > page) {
      setPage(newPage);
      setRowIndex(null);
    } else if (newPage < page) {
      setPage(newPage);
      setRowIndex(null);
    }
  };

  useEffect(() => {
    if (newPageVal > pagesPassed) {
      setPagesPassed(newPageVal);
      setIsLoading(true);
      populateTable(true);
    }
  }, [newPageVal]);

  useEffect(() => {
    if (count <= rowsPerPage || dataDietOrderList?.length <= rowsPerPage) {
      setPagesPassed(0);
      setNewPageVal(0);
      setRowIndex(null);
    }
  }, [count, rowsPerPage, dataDietOrderList]);

  ///

  function handleChange(Tabledata) {
    let orderDtlsArr = [];

    let data = Tabledata;

    const arr = data.filter((item) => item.isChecked);

    // for (let i = 0; i < props.dataDietOrderList.length; i++) {
    //   if (props.dataDietOrderList[i].isChecked === true) {
    //     orderDtlsArr.push(props.dataDietOrderList[i]);
    //   }
    // }
    props.setFinalDataDietOrder(arr);
  }

  useEffect(() => {
    let temp = [...props.dataDietOrderList];

    if (
      Instructions !== undefined ||
      dietPlanName !== undefined ||
      dietTypeName !== undefined ||
      dietOptionName !== undefined ||
      (temp.length > 0 && selectedRow !== null)
    ) {
      let updated = temp.map((item) =>
        item.admissionId === selectedRow?.admissionId
          ? {
              ...item,
              // Instructions: Instructions,
              "Diet Plan Name": dietPlanName?.label,
              "Diet Type": dietTypeName?.label,
              "Food Type": foodTypeName?.label,
              "Diet Option": dietOptionName?.label,
              //
              dietOptionId: dietOptionName?.id,
              dietPlanMasterId: dietPlanName?.id,
              dietPlanTypeId: dietTypeName?.id,
              foodTypeId: foodTypeName?.id,
            }
          : { ...item }
      );
      // for (let i = 0; i < temp.length; i++) {
      //   if (temp[i]?.mrNo === selectedRow?.mrNo) {
      //     //Instructions
      //     temp[i].Instructions = Instructions;

      //     //DietPlanName
      //     temp[i].dietPlanMasterId = dietPlanName?.id;
      //     temp[i]["Diet Plan Name"] = dietPlanName?.label;

      //     //DietTypeName
      //     temp[i].dietPlanTypeId = dietTypeName?.id;
      //     temp[i]["Diet Type"] = dietTypeName?.label;

      //     //FoodTypeName
      //     temp[i].foodTypeId = foodTypeName?.id;
      //     temp[i]["Food Type"] = foodTypeName?.label;

      //     //DietOptionName
      //     temp[i].dietOptionId = dietOptionName?.id;
      //     temp[i]["Diet Option"] = dietOptionName?.label;
      //   }
      // }
      setDataDietOrderList(updated);
    }
  }, [
    props.selectedIndex,
    selectedRow,
    Instructions,
    dietPlanName,
    dietTypeName,
    foodTypeName,
    dietOptionName,
  ]);

  const handleInstruction = () => {
    props.setOpenInstructonModal(true);
    props.inputInstructionValue && props.inputInstructionValue("");
    props.setInstructions(props.inputInstructionValue);
  };

  const handleSpecialInstruction = () => {
    props.setOpenSpecialInstModal(true);
    props.inputSpecialInstValue("");
    props.setSpecialInstruction(props.inputSpecialInstValue);
  };

  //const renderPrint = () => {
  //   setTimeout(() => {
  //     setOpenPrintModal(false);
  //   }, 1000);

  //   return (
  //     <div className="hidden">
  //       <DietOrderBarcode row={row_selected} />
  //     </div>
  //   );
  // };

  return (
    <>
      <div className="w-full ">
        <Paper sx={{ my: 1 }}>
          <>
            <div className="flex justify-between items-center text-sm">
              <div className="flex space-x-2">
                <span className="flex items-center ml-3  space-x-3">
                  <SquareRoundedIcon
                    className="text-[#f59842]"
                    fontSize="small"
                  />
                  <p className="mr-4">Admitted Today</p>
                </span>
                <span className="flex items-center ml-3  space-x-3">
                  <SquareRoundedIcon
                    className="text-[#f542e0]"
                    fontSize="small"
                  />
                  <p className="mr-4">Bed Transfer</p>
                </span>
                <span className="flex items-center ml-3  space-x-3">
                  <SquareRoundedIcon
                    className="text-emerald-500"
                    fontSize="small"
                  />
                  <p className="mr-4">PMC</p>
                </span>
              </div>

              <div className="flex space-x-3 items-center justify-center">
                <div className=" leading-normal tracking-wide flex xl:space-x-3 md:space-x-3">
                  <h1>BreakFast Count</h1>
                  <div className="text-[ #676767] font-medium flex space-x-2 ">
                    <h1>: &nbsp; {props.patientCount?.BreakFastPatient}</h1>
                  </div>
                </div>
                <div className=" leading-normal tracking-wide flex xl:space-x-3 md:space-x-3">
                  <h1>Lunch Count</h1>
                  <div className="text-[ #676767] font-medium flex space-x-2 ">
                    <h1>: &nbsp; {props.patientCount?.LunchPatient}</h1>
                  </div>
                </div>
                <div className=" leading-normal tracking-wide flex xl:space-x-3 md:space-x-3">
                  <h1>Dinner Count</h1>
                  <div className="text-[ #676767] font-medium flex space-x-2 ">
                    <h1>: &nbsp; {props.patientCount?.DinnerPatient}</h1>
                  </div>
                </div>
                {/* <TablePagination
                  labelRowsPerPage=""
                  rowsPerPageOptions={[]}
                  sx={{
                    ".MuiTablePagination-toolbar": {
                      minHeight: "35px",
                      overflowX: "hidden",
                    },
                  }}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  SelectProps={{
                    disabled: isLoading,
                  }}
                  backIconButtonProps={
                    isLoading
                      ? {
                          disabled: isLoading,
                        }
                      : undefined
                  }
                  nextIconButtonProps={
                    isLoading
                      ? {
                          disabled: isLoading,
                        }
                      : undefined
                  }
                /> */}
              </div>
            </div>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#ebebeb",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#7393b3",
                  borderRadius: 6,
                },
              }}
              className="rounded h-[650px] overflow-y-auto "
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",
                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.4,
                        paddingX: 0.4,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell className="">
                      <div className="text-gray-600 font-bold">Action</div>
                    </TableCell>

                    <TableCell className="whitespace-nowrap">
                      <div className="text-gray-600 font-bold">
                        Patient Name | Mr No <br />
                        Age | Bed No. | Doctor Name
                      </div>
                    </TableCell>

                    {/*<TableCell className="whitespace-nowrap">
                      <div className="text-gray-600 font-bold">Mr No</div>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <div className="text-gray-600 font-bold">
                        Patient Name
                      </div>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <div className="text-gray-600 font-bold">Age</div>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <div className="text-gray-600 font-bold">Bed No.</div>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <div className="text-gray-600 font-bold">Doctor Name</div>
                  </TableCell>*/}

                    {headers.map((header, index) => (
                      <>
                        <TableCell
                          sortDirection={orderBy === header ? order : false}
                          className="whitespace-nowrap"
                        >
                          <TableSortLabel
                            active={false}
                            direction={orderBy === header ? order : "asc"}
                            onClick={createSortHandler(header)}
                            key={index}
                          >
                            <span className="text-gray-600 font-bold">
                              {header}
                            </span>
                            {orderBy === header ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td": {
                      paddingX: 0.4,
                    },
                  }}
                >
                  {
                    //   (page > 0 || dataDietOrderList.length > rowsPerPage
                    //   ? tableSort(
                    //       dataDietOrderList,
                    //       getComparator(order, orderBy)
                    //     ).slice(
                    //       page * rowsPerPage,
                    //       page * rowsPerPage + rowsPerPage
                    //     )
                    //   : tableSort(
                    //       dataDietOrderList,
                    //       getComparator(order, orderBy)
                    //     )
                    // )
                    dataDietOrderList?.map((row, index) => {
                      isLoading && setIsLoading(false);
                      const rowKey =
                        page > 0 ? page * rowsPerPage + index : index;

                      let dietPlanNameObj = {
                        id: row?.dietPlanMasterId,
                        value: row?.dietPlanMasterId,
                        label: row?.["Diet Plan Name"],
                      };

                      let dietTypeObj = {
                        id: row?.dietPlanTypeId,
                        value: row?.dietPlanTypeId,
                        label: row?.["Diet Type"],
                      };

                      let foodTypeObj = {
                        id: row?.foodTypeId,
                        value: row?.foodTypeId,
                        label: row?.["Food Type"],
                      };

                      let dietOptionObj = {
                        id: row?.dietOptionId,
                        value: row?.dietOptionId,
                        label: row?.["Diet Option"],
                      };

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "& td": {
                              paddingY: 2,
                            },
                          }}
                          // onClick={() => {
                          //   props?.setRow_selected([row?.admissionId]);
                          // }}
                        >
                          <TableCell padding="checkbox">
                            <div className="flex items-center gap-2">
                              <Checkbox
                                size="small"
                                className="w-4 h-4 border border-gray-600 rounded"
                                type="checkbox"
                                color="primary"
                                checked={row?.isChecked ? true : false}
                                onChange={(e) => {
                                  let temp = [...props.dataDietOrderList];

                                  let updatdData = temp.map((item) =>
                                    item.admissionId === row.admissionId
                                      ? { ...item, isChecked: e.target.checked }
                                      : { ...item }
                                  );

                                  // temp[index].isChecked = e.target.checked;
                                  setDataDietOrderList(updatdData);

                                  // handleChange(updatdData);
                                }}
                                name={`CheckBox${index}`}
                              />
                              <span className="font-semibold">
                                <BarcodeOnIcon
                                  title={"Barcode"}
                                  onClick={() => {
                                    // props?.setOpenPrintModal(true);

                                    let barcodeObj = [
                                      {
                                        admissionId: row.admissionId,
                                        dietChartId: row.dietChartId,
                                        dietOptionId: dieOptions?.id,
                                      },
                                    ];

                                    props?.handleOpenPrintModal(
                                      "barcode",
                                      barcodeObj
                                    );
                                    // props?.handleOpenPrintModal("barcode", [
                                    //   row?.admissionId,
                                    // ]);
                                  }}
                                />
                              </span>
                            </div>
                          </TableCell>

                          {/* Green f3ffd0 Brown Faint E99566 f5b998 purpule eabdfc*/}

                          <TableCell className="">
                            <Tooltip
                              title={
                                <span>
                                  Patient: {row.patientName} <br />
                                  MR No: {row.mrNo} <br />
                                  Age: {row.age} <br />
                                  Bed No: {row.bedNo} <br />
                                  Doctor: {row.doctorName}
                                </span>
                              }
                            >
                              <div
                                className={
                                  row.companyName === "Pmc" ||
                                  row.companyName === "Covid (pmc)" ||
                                  row.companyName === "Pmc Staff"
                                    ? "text-emerald-500 w-56"
                                    : !!row.admissionDate
                                    ? "text-[#f59842] w-56"
                                    : row.isTransfer
                                    ? "text-[#f542e0] w-56"
                                    : "text-gray-800 w-56"
                                }
                              >
                                {` ${row.patientName} | ${row.mrNo} `} <br />
                                {` ${row.age} | ${row.bedNo} | ${row.doctorName}`}
                              </div>
                            </Tooltip>
                          </TableCell>

                          {/* <TableCell className="whitespace-nowrap">
                          <div
                            className={
                              row.companyName === "Pmc" ||
                              row.companyName === "Covid (pmc)" ||
                              row.companyName === "Pmc Staff"
                                ? "text-emerald-500"
                                : !!row.admissionDate
                                ? "text-[#f59842]"
                                : row.isTransfer
                                ? "text-[#f542e0] "
                                : "text-gray-800"
                            }
                          >
                            {row.mrNo}
                          </div>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          <div
                            className={
                              row.companyName === "Pmc" ||
                              row.companyName === "Covid (pmc)" ||
                              row.companyName === "Pmc Staff"
                                ? "text-emerald-500"
                                : !!row.admissionDate
                                ? "text-[#f59842]"
                                : row.isTransfer
                                ? "text-[#f542e0] "
                                : "text-gray-800"
                            }
                          >
                            {row.patientName}
                          </div>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.age}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.bedNo}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.doctorName}
                          </TableCell>*/}

                          <TableCell
                            className=" "
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedRow(row);
                            }}
                            onChange={handleChange}
                          >
                            <TableDropDownField
                              width={60}
                              control={control}
                              defaultValue={
                                dietPlanNameObj.label !== null
                                  ? dietPlanNameObj
                                  : null
                              }
                              name={`DietPlanName${index}`}
                              isClearable={true}
                              isSearchable={true}
                              dataArray={dietPlan}
                            />
                          </TableCell>

                          <TableCell
                            className="whitespace-nowrap"
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedRow(row);
                            }}
                            onChange={handleChange}
                          >
                            <TableDropDownField
                              width={60}
                              control={control}
                              defaultValue={
                                dietTypeObj.id !== null ? dietTypeObj : null
                              }
                              name={`DietTypeName${index}`}
                              isClearable={true}
                              isSearchable={true}
                              dataArray={dietTypes}
                            />
                          </TableCell>

                          <TableCell
                            className="whitespace-nowrap"
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedRow(row);
                            }}
                            onChange={handleChange}
                          >
                            <TableDropDownField
                              width={60}
                              control={control}
                              defaultValue={
                                foodTypeObj?.id !== null ? foodTypeObj : null
                              }
                              name={`FoodTypeName${index}`}
                              isClearable={true}
                              isSearchable={true}
                              dataArray={foodTypes}
                            />
                          </TableCell>

                          <TableCell className="">
                            <Tooltip
                              title={<span>{row?.["Remark"] || ""}</span>}
                            >
                              <input
                                type="text"
                                // {...register(`Instructions${index}`)}
                                className="border rounded border-[#6e7070]  h-[30px] px-1"
                                size="small"
                                name={`Instructions${index}`}
                                value={row?.Instructions || ""}
                                // onChange={(e) =>
                                // props.setInstructions(e.target.value)
                                // }
                                onChange={handleChange}
                                onClick={(e) => {
                                  props.setSelectedRowIndex(row);
                                  props.setInputInstructonValue(row?.Remark);

                                  handleInstruction();
                                  setSelectedIndex(index);
                                  setSelectedRow(row);
                                }}
                              />
                            </Tooltip>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            <Tooltip
                              title={
                                <span>
                                  {row?.["Special Instructions"] || ""}
                                </span>
                              }
                            >
                              <input
                                type="text"
                                {...register(`SpecialInstructions${index}`)}
                                className="border rounded border-[#6e7070] w-25 h-[30px] px-1"
                                size="small"
                                name={`SpecialInstructions${index}`}
                                value={row?.["Special Instructions"] || ""}
                                onChange={handleChange}
                                onClick={(e) => {
                                  props.setSelectedSpecialRowIndex(row);
                                  props.setInputSpecialInstValue(
                                    row?.["Special Instructions"]
                                  );

                                  handleSpecialInstruction();
                                  setSelectedSpecialIndex &&
                                    setSelectedSpecialIndex(index);
                                  setSelectedRow(row);
                                }}
                              />
                            </Tooltip>
                          </TableCell>

                          <TableCell className="whitespace-nowrap ">
                            <div
                              className="w-48"
                              onClick={() => {
                                setSelectedIndex(index);
                                setSelectedRow(row);
                              }}
                              onChange={handleChange}
                            >
                              {
                                <>
                                  <CreateableSelect
                                    control={control}
                                    defaultValue={row["Allergic To"]}
                                    name={`AllergicTo${index}`}
                                    menuPlacement={"bottom"}
                                    isClearable={true}
                                    isSearchable={true}
                                    dataArray={allergiesTo}
                                    placeholder={""}
                                    isMulti={true}
                                    maxMenuHeight={200}
                                    inputRef={{
                                      ...register(`AllergicTo${index}`, {
                                        onChange: (e) => {
                                          if (e.target.value) {
                                            let temp = [
                                              ...props.dataDietOrderList,
                                            ];
                                            temp[index]["Allergic To"] =
                                              e.target.value;
                                            // temp[
                                            // selectedIndex
                                            // ].AllergicTo =
                                            // e.target.value.map(
                                            // (item) => item.value
                                            // );
                                            setDataDietOrderList(temp);
                                          }
                                        },
                                      }),
                                    }}
                                  />
                                </>
                              }
                            </div>
                          </TableCell>

                          {/*<TableCell
                          onClick={() => {
                            setSelectedIndex(index);
                            setSelectedRow(row);
                          }}
                          onChange={handleChange}
                        >
                          <div className="w-full">
                            <TableDropDownField
                              control={control}
                              defaultValue={props.dieOptions}
                              name={`DietOptionName${index}`}
                              isClearable={true}
                              isSearchable={true}
                              dataArray={dietOptions}
                            />
                          </div>
                        </TableCell>*/}

                          <TableCell
                            className="whitespace-nowrap w-full"
                            key={index}
                          >
                            <Tooltip
                              title={<span>{row?.["Diagnosis"] || ""}</span>}
                            >
                              <input
                                className="border rounded border-[#6e7070] bg-white w-[80%] h-[30px] px-1"
                                size="small"
                                name={`Diagnosis${index}`}
                                disabled={true}
                                defaultValue={row["Diagnosis"]}
                                onChange={handleChange}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>

              {isLoading && (
                <div className="flex justify-center text-gray-400 font-semibold my-5">
                  <LoadingSpinner />
                </div>
              )}
            </TableContainer>
          </>
        </Paper>

        {props.openInstructonModal && (
          <Modal open={props.openInstructonModal}>
            <Box sx={ModalStyle} className=" max-h-[80%] ">
              <>
                <div className=" grid grid-cols-1 md:grid-cols-1 w-full">
                  <CancelPresentationIconButton
                    onClick={() => {
                      props.handleCloseInstructonModal();
                    }}
                  />
                </div>

                <div className="mt-6 px-z">
                  <div className="grid grid-cols-1 w-[100%] py-2">
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Remark"
                      name="instructions"
                      value={props.inputInstructionValue}
                      onChange={(e) => {
                        // setSelectedIndex(index);
                        props.setInputInstructonValue(e.target.value);
                      }}
                      defaultValue=""
                      multiline
                      inputProps={{
                        style: { overflow: "hidden" },
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="flex gap-2 justify-end">
                    <CommonButton
                      type="button"
                      label="Ok"
                      className="bg-customGreen text-white "
                      onClick={() => {
                        props.onSubmitInstructions();
                      }}
                    />
                  </div>
                </div>
              </>
            </Box>
          </Modal>
        )}

        {props.openSpecialInstModal && (
          <Modal open={props.openSpecialInstModal}>
            <Box sx={ModalStyle} className=" max-h-[80%] ">
              <div className="grid grid-cols-1 md:grid-cols-1 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    props.handleCloseSpecialInstModal();
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="mt-6 px-2">
                <div className="grid grid-cols-1 w-[100%] py-2">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Special Instructions"
                    name="SpecialInstructions"
                    value={props.inputSpecialInstValue}
                    onChange={(e) => {
                      // setSelectedSpecialIndex(index);
                      props.setInputSpecialInstValue(e.target.value);
                    }}
                    defaultValue=""
                    multiline
                    inputProps={{
                      style: { overflow: "hidden" },
                      width: "100%",
                    }}
                  />
                </div>
                <div className="flex gap-2 justify-end">
                  <CommonButton
                    type="button"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      props.onSubmitSpecialInstructions();
                    }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
        )}

        {props.openallergicto && (
          <Modal open={props.openallergicto}>
            <Box sx={ModalStyleAllergiesTo} className=" max-h-[80%] ">
              <div className="grid grid-cols-1 md:grid-cols-1 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    props.handaleAllergictoModal();
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="mt-6 px-2">
                <div className="grid grid-cols-1 w-[100%] py-2">
                  {/* <TextField
 id="outlined-multiline-flexible"
 label="Allergic To"
 name="allergiesTo"
 value={props.allergiesTo}
 onChange={(e) => {
 // setSelectedSpecialIndex(index);
 props.setInputSpecialInstValue(e.target.value);
 }}
 defaultValue=""
 multiline
 inputProps={{
 style: { overflow: "hidden" },
 width: "100%",
 }}
 /> */}

                  <DropdownField
                    control={control}
                    name={`AllergicTo`}
                    menuPlacement={"bottom"}
                    isClearable={true}
                    isSearchable={true}
                    dataArray={dietTypes}
                    isMulti={true}
                    maxMenuHeight={200}
                    inputRef={{
                      ...register(`AllergicTo`, {
                        onChange: (e) => {
                          if (e.target.value) {
                            let temp = [...props.dataDietOrderList];
                            temp[selectedIndex]["Allergic To"] = e.target.value;
                            temp[selectedIndex].AllergicTo = e.target.value.map(
                              (item) => item.value
                            );
                            setDataDietOrderList(temp);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="flex gap-2 justify-end">
                  <CommonButton
                    type="button"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      props.handaleAllergictoModal();
                    }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
}
