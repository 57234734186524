import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import CodeBlueFormPrint from "../../../prints/CodeBlueFormPrint";
import { fetchCBFormListing } from "../../../services/codeBlueFormServices/CodeBlueFormServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  maxHeight: "74%",
  maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 2,
  px: 2,
};
let showSpinner = true;

export default function CBFormListing(props) {
  const { open, handleClose, userActions, setSelectedRow } = props;

  //
  const [dataResult, setDataResult] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [count, setCount] = useState();

  const [codeBlueFormId, setCodeBlueFormId] = useState(null);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      // toDate: new Date(),
      // fromDate: new Date(),
    },
  });

  const { control, watch } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <CodeBlueFormPrint codeBlueFormId={codeBlueFormId} />
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction && (
              <>
                <div className="flex gap-1 items-center cursor-pointer">
                  {actions.action === "Print" && (
                    <Tooltip title=" Print Code Blue Form">
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          setCodeBlueFormId(row.ID);
                          setOpenPrintModal(true);
                        }}
                      />
                    </Tooltip>
                  )}

                  {actions.action === "Edit" && (
                    <>
                      <Tooltip title="Edit Code Blue Form">
                        <DriveFileRenameOutlineIcon
                          className={`text-customBlue`}
                          onClick={() => {
                            setSelectedRow(row);
                            handleClose();
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate]);

  const populateTable = (forPagination) => {
    showSpinner = true;

    const listObj = {
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchCBFormListing(listObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setCount(res.count);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        showSpinner = false;
      });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />

          <div className={"text-lg font-semibold py-3"}>
            View Code Blue Form
          </div>

          <div className="grid grid-cols-5  gap-3">
            {/* from Date */}
            <div className="md:col-span-2 lg:col-span-1">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>

            {/* to Date */}
            <div className="md:col-span-2 lg:col-span-1">
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
          </div>

          {showSpinner ? (
            <div className="flex justify-center mt-14">
              <LoadingSpinner />
            </div>
          ) : dataResult.length > 0 ? (
            <>
              <CommonDynamicTablePaginationNew
                dataResult={dataResult}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                renderActions={renderActions}
                removeHeaders={["ID"]}
                highlighRow={false}
                //
                // selectedFromDate={selectedFromDate}
                // selectedToDate={selectedToDate}
                // handleClose={handleClose}
              />
            </>
          ) : (
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </Box>
      </Modal>
      {/*  Print Modal */}

      {openPrintModal && renderPrint()}
    </div>
  );
}
