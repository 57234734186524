import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import { Tooltip } from "@mui/material";
import { useContext } from "react";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { fetchOtServices } from "../../../services/otDetailsServices/OtDetailsServices";
import {
  fetchGroupOt,
  fetchSubGroup,
} from "../../../services/otReservationServices/OtReservationServices";
import { OtDetailsContext } from "./OtDetails";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import InputArea from "../../../../common/components/FormFields/InputArea";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";

const SurgeryDetails = () => {
  const {
    sugeryDetailsData,
    setSugeryDetailsData,
    patientInfo,
    PatientTypeRadio,
    // reservedSurgeries,
    //setReservedSurgeries,
    totalOtCharges,

    setDeletedSergeryData,
    //
    // doctorDetailsArr,
    // setDoctorDetailsArr,
    // setDeletedDoctorData,
  } = useContext(OtDetailsContext);

  //
  const [groupData, setGroupData] = useState();
  const [groupId, setGroupId] = useState();
  const [subGroupData, setSubGroupData] = useState();
  const [searchString, setSearchString] = useState();
  const [serviceInfo, setServiceInfo] = useState();
  const [selectedSergeryProcedure, setSelectedSergeryProcedure] = useState(
    null
  );
  const [surgeryDetailsArray, setSurgeryDetailsArray] = useState([]);
  const [showOtCharges, setShowOtCharges] = useState(null);

  useEffect(() => {
    const uniqueArr = sugeryDetailsData.filter((obj, index) => {
      return (
        index ===
        sugeryDetailsData.findIndex(
          (item) => obj["Service Code"] === item["Service Code"]
        )
      );
    });
    setSurgeryDetailsArray(uniqueArr);

    let sumOtCharges = 0;
    for (let s = 0; s < uniqueArr?.length; s++) {
      sumOtCharges += Number(uniqueArr[s]["Net Amt"]);
    }

    setShowOtCharges(sumOtCharges);
  }, [sugeryDetailsData]);

  useEffect(() => {
    // group
    fetchGroupOt()
      .then((response) => response.data)
      .then((res) => {
        setGroupData(res.result);
      });
  }, []);

  // fetchOtServices

  const {
    control,
    register,
    setValue,
    setError,
    resetField,
    formState: { errors },
  } = useFormContext();

  //////to get unitId from local starage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let unitId = token.unitId;

  useEffect(() => {
    const otServiceObj = {
      applicableTo: PatientTypeRadio||1,
      classId: patientInfo ? patientInfo?.classId : 0,
      description: searchString,
      groupId: groupId ? groupId : 0,
      isConcessionService: 0,
      isEmergency: false,
      isExternal: false,
      isFree: 0,
      serviceCode: "%",
      tariffId: patientInfo ? patientInfo?.tariffId : 0,
      unitId: unitId,
    };

    fetchOtServices(otServiceObj)
      .then((response) => response.data)
      .then((res) => {
        setServiceInfo(res.result);
      });
  }, [PatientTypeRadio, patientInfo, searchString, groupId]);
  ////////////////

  //add Doctors List

  const addOtServices = () => {

    if (selectedSergeryProcedure !== null) {
      let serviceListData = {
        "Service Code": selectedSergeryProcedure.ServiceCode,
        ServiceId: selectedSergeryProcedure.ServiceId,
        tariffId: selectedSergeryProcedure.TariffId,
        "Surgery Name": selectedSergeryProcedure.label,
        Rate: selectedSergeryProcedure.Rate,
        classId: selectedSergeryProcedure.ClassId,
        Qty: 1,
        otSurgeryDetailsId: 0,
        "Total Amount": Number(selectedSergeryProcedure?.Rate),
        aneasAmount: 0,
        "Net Amt": Number(selectedSergeryProcedure.Rate),
        isDelete: false,
      };

      let isItemAlreadyExist = false;
      isItemAlreadyExist = sugeryDetailsData.some(
        (item) => !item.isDelete && item.ServiceId === serviceListData.ServiceId
      );

      if (isItemAlreadyExist === true) {
        warningAlert("Service Already Exists...");
      } else {
        setSugeryDetailsData((prev) => [...prev, serviceListData]);
        isItemAlreadyExist = false;
        serviceListData = {};
        setValue("searchService", null, { shouldValidate: true });
        setValue("group", null, { shouldValidate: true });
        setValue("subGroup", "", { shouldValidate: true });
        setSelectedSergeryProcedure(null);
      }

      // setValue("searchService", null, { shouldValidate: true });
      // setValue("group", null, { shouldValidate: true });
      // setValue("subGroup", "", { shouldValidate: true });
    } else {
      setError("searchService", { type: "custom", message: "Required" });
    }
  };

  const renderActions = (row, index) => {
    const handleDeleteRow = () => {
      let temp = [...sugeryDetailsData];
      // tempDoc = [...doctorDetailsArr];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items
      setDeletedSergeryData((prev) => [...prev, ...deletedItems]);
      setSugeryDetailsData(notDeletedItems);

      /// if sugeery delete doctors against this surgery also has been deleted
      // let updatedDoc = temp.map((item) =>
      //   row?.SurguryId === item?.SurguryId ? { ...item, isDelete: true } : item
      // );

      // let deletedDoctItems = [];
      // let notDeletedDoctItems = [];

      // updatedDoc.forEach((item) => {
      //   if (item.isDelete === true) {
      //     deletedDoctItems.push(item);
      //   } else {
      //     notDeletedDoctItems.push(item);
      //   }
      // });

      // // Update the state with deleted items and allMedicationData
      // setDeletedDoctorData((prev) => [...prev, ...deletedDoctItems]);
      // setDoctorDetailsArr(notDeletedDoctItems);
    };
    return (
      <>
        <Tooltip title="Delete">
          <div
            className="text-red-500 mr-3"
            onClick={() => handleDeleteRow(row, index)}
          >
            {<DeleteOutlineOutlinedIcon />}
          </div>
        </Tooltip>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...sugeryDetailsData];
      updatedList[index]["Qty"] = Number(newValue);

      updatedList[index]["Net Amt"] = Number(
        (newValue * updatedList[index]["Rate"]).toFixed(2)
      );

      updatedList[index]["Total Amount"] = Number(
        (newValue * updatedList[index]["Rate"]).toFixed(2)
      );

      setSugeryDetailsData(updatedList);
    };

    const handleTableRateChange = (index, newValue) => {
      const updatedList = [...sugeryDetailsData];
      updatedList[index]["Rate"] = Number(newValue);
      updatedList[index]["Total Amount"] = Number(
        (newValue * updatedList[index]["Qty"]).toFixed(2)
      );
      updatedList[index]["Net Amt"] = Number(
        (newValue * updatedList[index]["Qty"]).toFixed(2)
      );
      setSugeryDetailsData(updatedList);
    };

    return (
      <>
        {header === "Qty" && (
          <span>
            <input
              className={
                row.error !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) => handleTableQuantityChange(index, e.target.value)}
              value={row["Qty"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </span>
        )}
        {header === "Rate" && (
          <span>
            <input
              className={
                row.error !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) => handleTableRateChange(index, e.target.value)}
              value={row["Rate"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            />
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <div className=" text-gray-700 font-semibold whitespace-nowrap flex gap-10">
        <div>Surgery Details </div>
        {totalOtCharges ? (
          <div className="flex">
            <span className="w-[100px] text-[#0b83a5]">OT Charges</span>
            <span className="text-[#0b83a5]">&nbsp;:&nbsp;</span>
            <span
              className={
                totalOtCharges >= 0
                  ? "text-customGray text-xl"
                  : "text-customRed text-xl"
              }
            >
              ₹ {totalOtCharges}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* ///Doctors List */}

      <div className="grid grid-cols-12 gap-3 mt-3">
        {/* //Group// */}
        <div className="col-span-2">
          <DropdownField
            control={control}
            name="group"
            dataArray={groupData}
            isSearchable={false}
            isClearable={false}
            placeholder="Group"
            inputRef={{
              ...register("group", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setGroupId(e.target.value.id);
                    fetchSubGroup(e.target.value.id)
                      .then((response) => response.data)
                      .then((res) => {
                        //
                        setSubGroupData(res.result);
                      });
                  }
                  setValue("subGroup", null);
                },
              }),
            }}
          />
        </div>
        {/* //sub Group// */}
        <div className="col-span-2">
          <DropdownField
            control={control}
            name="subGroup"
            label="Sub Group"
            dataArray={subGroupData}
            isSearchable={false}
            isClearable={false}
            placeholder="Sub Group"
            inputRef={{
              ...register("subName", {
                onChange: () => {
                  resetField();
                },
              }),
            }}
          />
        </div>
        <div className="col-span-4" onClick={(e) => e.preventDefault()}>
          <SearchDropdown
            name="searchService"
            placeholder="Search Service"
            dataArray={serviceInfo}
            handleInputChange={(e) => {
              setSearchString(e);
            }}
            searchIcon={true}
            error={errors.searchService}
            control={control}
            isClearable={true}
            inputRef={{
              ...register("searchService", {
                onChange: (e) => {
                  setSelectedSergeryProcedure(e.target.value);
                  // e.preventDefault();
                },
              }),
            }}
          />
        </div>

        <div className=" flex gap-3 col-span-4">
          <CommonButton
            className="bg-customGreen text-white"
            label="Add"
            onClick={() => {
              addOtServices();
            }}
          />
        </div>
        {/* table */}
        {sugeryDetailsData &&
          sugeryDetailsData.filter((item) => !item.isDelete).length > 0 &&
          sugeryDetailsData.length > 0 && (
            <div className="w-full mt-2 col-span-12">
              <CommonDynamicTableNew
                dataResult={sugeryDetailsData.filter((item) => !item.isDelete)}
                renderActions={renderActions}
                renderInput={renderInput}
                editableColumns={["Qty", "Rate"]}
                highlightRow={false}
                removeHeaders={[
                  "otSurgeryDetailsId",
                  "ChargeId",
                  "Procedure Type",
                  "ProcedureTypeId",
                  "ServiceId",
                  "SurgeryId",
                  "TariffServiceId",
                  "otSurgeryDetailsId",
                  "isDelete",
                  "tariffId",
                  "classId",
                  "aneasAmount",
                  "Service Code",
                ]}
              />
            </div>
          )}
      </div>

      <div className="grid grid-cols-4 gap-3 mt-3">
        <div>
          <CheckBoxField
            name="intraOpChangesOfSurgicalPlan"
            label="Intra Op Changes Of Surgical Plan"
          />
        </div>

        <div className="col-span-3">
          <InputArea
            name="intraOpChangesOfSurgicalPlanReason"
            label="Intra Op Changes Of Surgical Plan Reason"
            placeholder="Intra Op Changes Of Surgical Plan Reason"
          />
        </div>
        <div>
          <CheckBoxField
            name="surgicalReschedule"
            label="Surgical Reschedule"
          />
        </div>
        <div className="col-span-3">
          <InputArea
            name="reasonOfSurgeryReschedule"
            label="Reason Of Surgical Reschedule"
            placeholder="Reason Of Surgical Reschedule"
          />
        </div>
        <div>
          <CheckBoxField
            name="unplannedReturnToOt"
            label="Unplanned Return To OT"
          />
        </div>

        <div className="col-span-3">
          <InputArea
            name="unplannedReturnToOtReason"
            label="Reason Of Unplanned Return To Ot"
            placeholder="Reason Of Unplanned Return To Ot"
          />
        </div>
        <div>
          <CheckBoxField name="reExploration" label="Re-Exploration" />
        </div>
        <div className="col-span-3">
          <InputArea
            name="reExplorationReason"
            label="Re-Exploration Reason"
            placeholder="Re-Exploration Reason"
          />
        </div>
      </div>
    </>
  );
};

export default SurgeryDetails;
