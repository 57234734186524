import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import {
  fetEmergencyBedCategory,
  fetchIpdConsentFormPrint,
  postMyEmergencyData,
} from "../../services/emergencyservice/EmergencyServices";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import {
  fetchAdmissionType,
  fetchDoctor,
  fetchPatientsource,
  fetchReferanceDoctor,
} from "../../../commonServices/miscellaneousServices/MiscServices";

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

export default function IpdRegisterForm(props) {
  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);
  const [patientSource, setpatientSource] = React.useState(); //Patient Source
  const [referalConsultant, setReferalConsultant] = React.useState();
  // const [unit, setUnit] = React.useState([]);
  const [doctor, setDoctor] = useState();
  const [classTypeOptions, setClasstypeOptions] = React.useState([]); //Bed Category
  const [finalData, setFinalData] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [mlcModalData, setMlcModalData] = React.useState(null);
  const [admissionType, setAdmissionType] = useState();

  //
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    props.setOpen(false);
  };

  const handleOpenPrintModal = (emergencyId) => {
    props.setOpenBackdrop(true);

    fetchIpdConsentFormPrint(emergencyId).then((response) => {
      if (response) {
        props.setOpenBackdrop(false);

        setUrlforPrint(response);
        setOpenPrintModal(true);
      }
    });
  };

  //

  // schema
  const schema = yup.object().shape({
    bedCategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),

    doctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),

    admissionType: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Category"),
        label: yup.string().required("Please Select Category"),
      })
      .required("Required"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      opdIpd: 1, //0 for OPD , 1 for IPD
      isEmergency: false,
      unit: null,
      isMlc: false,
      admissionAdvice: false,
      patientSource: null,

      referalConsultant: 0,
      bedCategory: null,
      // mobileNumber: "",
      medicalManagement: false,
      surgicalManagement: false,
      admissionType: null,
      remarks: "",
      doctor: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const finalObject = {
    admissionType: "",
    opdIpd: 1, //0 for OPD , 1 for IPD
    addedBy: 0,
    isEmergency: true,
    unitId: null,
    patientId: 0,
    prefixId: 0,
    firstName: "",
    genderId: "",
    lastName: "",
    middleName: "",
    ageYear: "",
    mrNo: 0,
    isMlc: false,
    mlcRequestDto: {},
    admissionAdvised: false,
    patientSourceId: null,
    referralConsultantId: 0,
    bedCategoryId: null,
    medicalManagement: false,
    surgicalManagement: false,
    services: [0],
    remark: "",
    doctorId: null,
    menuId: "",
    privilege: "",
  };

  //state variable to close the confirmation modal for POST request
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //state variable to close the confirmation modal for PUT request
  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };

  let isMlc = watch("isMlc");
  let admissionAdvice = watch("admissionAdvice");
  let medicalManagement = watch("medicalManagement");
  let surgicalManagement = watch("surgicalManagement");

  // const StateIsMlc = () => {
  //   setOpenModal(isMlc);
  // };

  // useEffect(() => {
  //   if (isMlc !== undefined) {
  //     StateIsMlc();
  //   }
  // }, [isMlc]);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  ////reset values if we cut selected patient
  useEffect(() => {
    if (!props.patientInfo && props.patientData === null) {
      methods.reset();
    }
  }, [props.patientInfo, props.patientData]);

  const onSubmit = (data) => {
    if (props.patientInfo && props.patientData !== null) {
      setOpenPost(true);
      setFinalData(data);
    } else warningAlert("Please Select Patient !");
  };

  const onSubmitDataHandler = (data) => {
    setOpenPost(false);
    // props.setOpen(false);

    methods.reset();
    let token = JSON.parse(localStorage.getItem("userInfo"));
    let user = token.userId;
    let unit = token.unitId;

    finalObject.menuId =
      props.location?.state?.menuId && Number(props.location.state.menuId);

    finalObject.privilege = props.privilege;

    finalObject.opdIpd = 1;

    finalObject.emergencyPatientId = props?.patientData?.EmergencyPatientId;

    finalObject.mrNo = props?.patientData?.MRNo;

    finalObject.dob = props?.patientData?.dob || props?.patientData?.DOB;

    finalObject.patientId = props?.patientData?.id;

    finalObject.addedBy = Number(user);

    finalObject.isEmergency = false;

    finalObject.isMlc = isMlc;

    finalObject.admissionAdvised = admissionAdvice;

    finalObject.medicalManagement = medicalManagement;

    finalObject.surgicalManagement = surgicalManagement;

    finalObject.unitId = Number(unit);

    finalObject.prefixId = Number(
      props?.patientData?.prefixId || props?.patientData?.PrefixId
    );

    finalObject.firstName = props?.patientData?.firstName;

    finalObject.middleName = props?.patientData?.middleName;

    finalObject.lastName = props?.patientData?.lastName;
    finalObject.genderId = Number(
      props?.patientData?.genderId || props?.patientData?.GenderId
    );

    data.remarks !== null
      ? (finalObject.remark = data.remarks)
      : (finalObject.remark = null);

    finalObject.services = [0];

    finalObject.mlcRequestDto = mlcModalData;

    finalObject.ageYear = parseInt(
      props?.patientData?.ageInYears || props?.patientData?.Age
    );

    data.doctor !== null
      ? (finalObject.doctorId = Number(data.doctor.id))
      : (finalObject.doctorId = Number(0));

    data.patientSource !== null
      ? (finalObject.patientSourceId = Number(data.patientSource.id))
      : (finalObject.patientSourceId = Number(0));

    finalObject.referralConsultantId =
      data?.referalConsultant !== null
        ? Number(data.referalConsultant.id) || Number(0)
        : 0;

    finalObject.referralConsultantName =
      data?.referalConsultant !== null
        ? data?.referalConsultant?.label || ""
        : "";

    data.bedCategory !== null
      ? (finalObject.bedCategoryId = Number(data.bedCategory.id))
      : (finalObject.bedCategoryId = null);

    // finalObject.admissionType = data?.admissionType?.label; //old key not at use in backend
    finalObject.admissionTypeId = data?.admissionType?.id;

    props.setOpenBackdrop(true);

    postMyEmergencyData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          handleClosePost();

          methods.reset();
          props.populateTable();
          //props.setPatientData("");
          //props.setSelectedRow("");

          // navigate("/emergency/registration", {
          //   state: { menuId: location?.state?.menuId },
          // });
          props.setOpenBackdrop(false);
          handleOpenPrintModal(res.result);
        }
      })
      .catch((res) => {
        handleClosePost();
        props.setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());
      });
  };

  useEffect(() => {
    // Patient Source
    fetchPatientsource()
      .then((response) => {
        setpatientSource(response.data.result);
      })
      .catch(() => {});

    getCategoryList();

    //API For unit dropdown list

    // fetchUnit()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setUnit(res.result);
    //   });

    //adm tyype

    fetchAdmissionType()
      .then((response) => response.data)
      .then((res) => {
        setAdmissionType(res.result);
      });
  }, []);

  // Bed Category
  function getCategoryList() {
    fetEmergencyBedCategory()
      .then((response) => {
        setClasstypeOptions(response.data.result);
      })
      .catch(() => {});
  }

  ///patchdata
  useEffect(() => {
    if (props.privilege === "Edit") {
      setValue("doctor", {
        id: props?.patientData?.DoctorId,
        label: props?.patientData?.DoctorName,
        value: props?.patientData?.DoctorName,
      });

      setValue("patientSource", {
        id: props?.patientData?.PatientSourceID,
        label: props?.patientData?.PatientSource,
        value: props?.patientData?.PatientSource,
      });

      props?.patientData?.RefDoctorId > 0 &&
        setValue("referalConsultant", {
          id: props?.patientData?.RefDoctorId,
          label: props?.patientData?.RefDoctorText,
          value: props?.patientData?.RefDoctorText,
        });

      setValue("bedCategory", {
        id: props?.patientData?.BedCategoryId,
        label: props?.patientData?.BedCategory,
        value: props?.patientData?.BedCategory,
      });

      setValue("admissionType", {
        id: props?.patientData?.AdmissionTypeId,
        label: props?.patientData?.AdmissionType,
        value: props?.patientData?.AdmissionType,
      });

      setValue("remarks", props?.patientData?.Remarks);

      setValue("isMlc", props?.patientData?.MedicoLegalCase);
      setValue("admissionAdvice", props?.patientData?.AdmissionAdvised);
      setValue("medicalManagement", props?.patientData?.ChkMedicalMgnt);
      setValue("surgicalManagement", props?.patientData?.ChkSurgicalMgnt);
    }
  }, [props?.patientData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-2">
        <div className="grid w-full ">
          {props.patientInfo && props.patientData !== null && (
            <>
              {/* Populate Patient Info Fields */}
              <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                    {/* Patient Name , UHID */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-1">
                        <span className="text-sm">Patient Name </span>
                        <span className=""> :</span>
                      </h1>
                      {/* <h1 className="font-normal">Ram Sham Rao</h1> */}
                      <h1 className="font-normal">
                        {props?.patientData?.patientName}
                      </h1>
                    </div>
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                        <span className="text-sm">Gender</span>
                        <span className="lg:pl-7 md:pl-6">:</span>
                      </h1>
                      {/* <h1 className="font-normal">Male</h1> */}
                      <h1 className="font-normal">
                        {props?.patientData?.gender}
                      </h1>
                    </div>
                  </div>

                  {/* Gender , Age */}
                  <div className="grid gap-2 lg:border-r-2 pl-4 border-slate-500 my-1 ">
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">MR No</span>
                        <span className="">:</span>
                      </h1>
                      {/* <h1 className="font-normal">124584</h1> */}
                      <h1 className="font-normal">
                        {props?.patientData?.MRNo}
                      </h1>
                    </div>

                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex md:space-x-7 space-x-1">
                        <span className="text-sm">Mobile No</span>
                        <span className="lg:pl-3 md:pl-3">:</span>
                      </h1>
                      {/* <h1 className="font-normal">Dr.Gupte</h1> */}
                      <h1 className="font-normal">
                        {props?.patientData?.mobileNo}
                      </h1>
                    </div>
                  </div>

                  {/* Bed No */}
                  <div className="lg:pl-4">
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-11 lg:space-x-10">
                        <span className="text-sm">Age</span>
                        <span className="md:pl-6">:</span>
                      </h1>
                      {/* <h1 className="font-normal">23Year 02Months.</h1> */}
                      <h1 className="font-normal">{props?.patientData?.Age}</h1>
                    </div>
                  </div>
                </div>
              </div>

              {/* Patient Source Dropdown */}
              <div className="grid md:grid-cols-2 tab:grid-cols-2 lg:grid-cols-4 py-2 gap-2">
                {/* bedCategory */}
                <DropdownField
                  control={control}
                  error={errors.bedCategory}
                  name="bedCategory"
                  label="Bed Category*"
                  dataArray={classTypeOptions}
                  isSearchable={false}
                  placeholder="Bed Category*"
                  isClearable={false}
                  maxMenuHeight={200}

                />

                <DropdownField
                  control={control}
                  error={errors.patientSource}
                  name="patientSource"
                  label="Patient Source"
                  dataArray={patientSource}
                  isSearchable={false}
                  placeholder="Patient Source"
                  isClearable={false}
                  maxMenuHeight={200}

                />

                {/* Doctor input field */}
                <SearchDropdown
                  control={control}
                  searchIcon={true}
                  isDisabled={false}
                  isSerchable={true}
                  isClearable={true}
                  error={errors.doctor}
                  name="doctor"
                  label="Doctor *"
                  placeholder="Doctor *"
                  isMulti={false}
                  dataArray={doctor}
                  maxMenuHeight={200}

                  handleInputChange={(e) => {
                    // Doctor
                    e &&
                      fetchDoctor(e)
                        .then((response) => {
                          setDoctor(response.data.result);
                        })
                        .catch(() => {});
                  }}
                />

            

                <div>
                  <CreateableSelect
                    name="referalConsultant"
                    placeholder="Referral Consultant"
                    dataArray={referalConsultant}
                    onInputChange={(e) => {
                      e &&
                        fetchReferanceDoctor(e).then((response) => {
                          setReferalConsultant(response.data.result);
                        });
                    }}
                    searchIcon={true}
                    control={control}
                    isClearable={true}
                    maxMenuHeight={200}

                  />
                </div>

                {/* //Admission Type*/}
                <div className="md:mb-3 lg:mb-0">
                  <DropdownField
                    control={control}
                    error={errors.admissionType}
                    name="admissionType"
                    label="admissionType"
                    dataArray={admissionType}
                    isSearchable={false}
                    isClearable={false}
                    placeholder="Admission Type *"
                    maxMenuHeight={200}

                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-2   xl:gap-2">
                {/* isMlc & admissionAdvice */}
                <div className="flex lg:mr-3 lg:mt-6 lg:border-r-2 border-slate-500">
                  <div onClick={(e) => e.target.checked && setOpenModal(true)}>
                    <CheckBoxField
                      control={control}
                      name="isMlc"
                      label="Medico Legal Case"
                      disabled={
                        props?.selectedRow &&
                        (props?.selectedRow?.MLCNo !== null ||
                          props?.selectedRow?.AdmissionId > 0)
                          ? true
                          : false
                      }
                    />
                  </div>
                  <CheckBoxField
                    control={control}
                    name="admissionAdvice"
                    label="Admission Advised"
                  />
                </div>
                <div className="">
                  {/* medicalManagement & surgicalManagement */}
                  <label className="text-sm font-semibold">
                    Type Of Care Required
                  </label>
                  <div className="flex">
                    <div className="whitespace-nowrap">
                      <CheckBoxField
                        control={control}
                        name="medicalManagement"
                        label="Medical Management"
                      />
                    </div>
                    <div className="whitespace-nowrap">
                      <CheckBoxField
                        control={control}
                        name="surgicalManagement"
                        label="Surgical Management"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* remarks Field */}
              <div className="my-2">
                <InputField
                  name="remarks"
                  variant="outlined"
                  label="Remark"
                  error={errors.remarks}
                  control={control}
                  inputProps={{
                    style: { textTransform: "capitalize" },
                  }}
                  required
                />
              </div>
              {/* Buttons Add Update Cancel Reset */}
              <div className="flex gap-2 justify-end mb-2">
                {Actions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "save" ? (
                      <>
                        <CommonButton
                          label="Reset"
                          onClick={() => {
                            // props.setResetIPDBtnFlag(true);
                            props.setValue("patientInfo", null);
                            props.setPatientData("");
                            props.setPatientInfoId("");
                            reset(methods);
                          }}
                          className="border border-customRed text-customRed"
                        />

                        <CommonButton
                          type="submit"
                          label="Save"
                          className="bg-customGreen text-white"
                        />
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </form>

      {/* print */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* mlc Modal */}
      {openModal ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setMlcModalData={setMlcModalData}
          patientInfo={props.patientInfo}
          patientData={props.patientData}
          isEdit={false}
          MLCNo={null}
          admissionId={0}
          emergencyId={props?.patientData?.EmergencyPatientId}
          opdIpd={1}
          isFromEmergency={true}
          isFromForm={true}
          //
          patientId={
            props?.patientData?.id
              ? props?.patientData?.id
              : props?.patientData?.EmergencyPatientId
          }
          isEmergency={props?.patientData?.id ? false : true}
          ipdEmergency={2}

          //
        />
      ) : null}
      {/* Confirmation modal for PUT request */}
      <ConfirmationModal
        confirmationOpen={openPut}
        confirmationHandleClose={handleClosePut}
        // confirmationSubmitFunc={updateRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Update"
      />
      {/* Confirmation modal for POST request */}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={() => onSubmitDataHandler(finalData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
