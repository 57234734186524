import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// /api/opdCasePaper/opdCasePaperRegList
export const fetchAllOPDCasePaperRegList = (postObj) => {
  return apiClient.post(`/opdCasePaper/opdCasePaperRegList`, postObj, {
    headers: authHeader(),
  });
};

// /api/opdCasePaper/saveOpdCasePaper
export const saveCasePaperRegister = (postObj, menuId, privilege) => {
  return apiClient.post(
    `/opdCasePaper/saveOpdCasePaper/${menuId}/${privilege}`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

export const searchOPDCasePaper = (searchObj) => {
  return apiClient.post(`/opdCasePaper/opdCasePaperRegListSearch`, searchObj, {
    headers: authHeader(),
  });
};
