import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  fetchMrdCheckList,
  fetchMrdCheckListRemark,
  saveMrdRemarks,
} from "../../services/mrdChecklistServices/MrdChecklistServices";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { getValue } from "@testing-library/user-event/dist/utils";

export default function MrdRemarkModal(props) {
  let location = useLocation();
  const token = JSON.parse(localStorage.getItem("userInfo"));
  //
  const [dataResult, setDataResult] = useState([]);
  const [checklistArr, setChecklistArr] = useState([]);

  //
  const {
    handleClose,
    selectedHeader,
    selectedRow,
    populateTable,
    menuId,
    privilege,
  } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      selectChecklist: "",
    },
  });

  const { control, getValues } = methods;

  useEffect(() => {
    selectedHeader &&
      fetchMrdCheckListRemark(selectedHeader)
        .then((response) => response.data)
        .then((res) => {
          setDataResult(res.result);
        });

    fetchMrdCheckList()
      .then((response) => response.data)
      .then((res) => {
        setChecklistArr(res.result);
      });
  }, []);

  const handleRemarkSubmit = () => {
    let selectedMrdCheckListid = getValues("selectChecklist")?.id;

    const finalObj = {
      admissionId: selectedRow?.AdmissionId,
      menuId: menuId,
      mrdListId: selectedMrdCheckListid || 0,
      mrdRemarkIds: dataResult
        .filter((item) => item.isChecked)
        .map((item) => {
          return item.id;
        }),
      privilege: privilege,
    };

    setOpenBackdrop(true);

    saveMrdRemarks(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          //   methods.reset();
          handleClose();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
            MRD Checklist Remarks
          </div>
          <hr className="border mx-1  border-customBlue" />

          <div className="grid grid-cols-2 gap-3 p-3">
            <div>
              {dataResult.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={dataResult}
                  setDataResult={setDataResult}
                  removeHeaders={["id", "isChecked"]}
                  enableSelectAll={true}
                  highlightRow={false}
                  tableClass={"h-96"}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>
            <div className="mt-2">
              <DropdownField
                control={control}
                // error={errors.nonPathGroup}
                name="selectChecklist"
                placeholder="Select Checklist"
                searchIcon={true}
                dataArray={checklistArr}
                isClearable={true}
                // handleInputChange={handleServiceData}
              />
            </div>
            <div className="col-span-2 grid justify-end">
              <div className="flex justify-end gap-2">
                <CommonButton
                  label="Close"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    // handlePatchData();
                  }}
                />
                <CommonButton
                  label="Add To List"
                  className="bg-customGreen text-white"
                  onClick={() => {
                    setOpenConfirmationModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleRemarkSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
