import { Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
  p: 3,
};

function AppointmentModal(props) {
  const [appointmentDateVal, setAppointmentDateVal] = useState(new Date());

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
  });

  let requiredObj = {};
  let tempArr = props.finalArr;

  const handleAppointmentDate = () => {
    requiredObj.appointmentDate = appointmentDateVal;
    requiredObj.inCompleteReason = "";
    requiredObj.inCompleteReasonId = "";
    requiredObj.ptEngDtlsId = props.rowId;
    requiredObj.reScheduleDate = "";
    requiredObj.reasonId = "";
    requiredObj.rejectReason = "";
    requiredObj.status = "Appointment";
    requiredObj.InCompleteReason = "";

    tempArr.push(requiredObj);
    props.setFinalArr(tempArr);

    props.setOpenCalModal(false);
  };

  return (
    <form>
      <Modal
        open={props.openCalModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="my-1">
            <CancelPresentationIconButton
              onClick={() => {
                props.setOpenCalModal(false);
                props.setCurrentApptDate(null);
              }}
            />
          </div>

          <fieldset className="border border-gray-300 text-left rounded">
            <legend className="px-2 font-bold text-gray-700 ml-3">
              Appointment Date
            </legend>
            <div className="flex items-center justify-between mx-10 my-5">
              <div className="lg:w-80">
                <Controller
                  control={control}
                  name="appointmentDate"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disablePast
                        defaultValue={new Date()}
                        name="appointmentDate"
                        value={appointmentDateVal}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setAppointmentDateVal(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            name="appointmentDate"
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              svg: { color: "#0B83A5" },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <div className="">
                <CommonButton
                  className="px-3  bg-green-700 text-white rounded text-base"
                  onClick={handleAppointmentDate}
                  label="Save"
                  type="button"
                />
              </div>
            </div>
          </fieldset>
        </Box>
      </Modal>
    </form>
  );
}

export default AppointmentModal;
