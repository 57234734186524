import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchScannedDocument,
  postPatientClearence,
} from "../../services/patientClearenceServices/PatientClearenceServices";
import UTIF from "utif";
import ImageViewer from "../../../common/components/imageViewer/ImageViewer";
import axios from "axios";
import Pako from "pako";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  minHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function NursingClearenceModal(props) {
  //
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var UserId = token.userId;
  //
  //
  const [amount, setAmount] = useState(0);
  const [scanedImges, setScanedImges] = useState([]);
  const [scanedImgesPost, setScanedImgesPost] = useState([]);
  const [checkScanning, setCheckScanning] = useState(false);
  //
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  //backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const {
    open,
    handleClose,
    selctedPatient,
    setSelctedPatient,
    clearenceDept,
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      amount: 0,
    },
  });
  const { control, register } = methods;

  function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const fetchDoc = async () => {
    try {
      fetchScannedDocument().then((response) => {
        const arrayBuffer = response.data;
        const byteArray = new Uint8Array(arrayBuffer);

        const compressed = Pako.deflate(byteArray);
        let compressedBase64 = arrayBufferToBase64(compressed);
        setScanedImgesPost((prev) => [...prev, compressedBase64]);

        var apiResponseImageData = response.data;

        var ifds = UTIF.decode(apiResponseImageData);
        ifds.map(function (ifd, index) {
          UTIF.decodeImage(apiResponseImageData, ifd);
          var rgba = UTIF.toRGBA8(ifd);
          var canvas = document.createElement("canvas");
          canvas.width = ifd.width;
          canvas.height = ifd.height;
          var ctx = canvas.getContext("2d");
          var img = ctx.createImageData(ifd.width, ifd.height);
          img.data.set(rgba);
          ctx.putImageData(img, 0, 0);
          let image = canvas.toDataURL();
          setScanedImges((prev) => [...prev, image]);
          setCheckScanning(false);
          let imageId = document.getElementById(`retrieved${index}`);
          if (imageId) {
            imageId.src = image;
          }

          document
            .getElementById(`tiff-inner-container${index}`)
            ?.appendChild(canvas);
          return canvas;
        });
      });
    } catch {
      errorAlert("Error While Scanning..!");
      setCheckScanning(false);
    }
  };

  //scanned Document
  const getScannedDocument = () => {
    fetchDoc();
    setCheckScanning(true);
  };

  //   postPatientClearence
  const finalObj = {
    admissionId: 0,
    departmentId: 0,
    pharmacyClearanceAmount: 0,
    userId: 0,

    patientDocumentClearanceDto: [
      {
        admissionId: 0,
        documentBase64: "",
        documentName: "",
        mrno: "",
      },
    ],
    pharmacyClearanceAmount: 0,
    saveWithDoc: false,
    userId: 0,
    isFromTab: false,
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    const docArr = scanedImgesPost.map((B64) => ({
      admissionId: selctedPatient?.AdmissionId,
      documentBase64: B64, //.split(",")[1],
      documentName: selctedPatient?.AdmissionId,
      mrno: selctedPatient?.MRNo,
    }));
    //
    finalObj.admissionId = selctedPatient.AdmissionId;
    finalObj.departmentId = clearenceDept.Id;
    finalObj.pharmacyClearanceAmount = amount;
    finalObj.patientDocumentClearanceDto = docArr;
    finalObj.userId = UserId;
    finalObj.menuId = props.menuId;
    finalObj.privilege = props.privilege;
    finalObj.saveWithDoc = docArr.length > 0;
    finalObj.isFromTab = false;

    //

    postPatientClearence(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setSelctedPatient(null);
          setScanedImges([]);
          props.reset();
          handleClose();
        }
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);
      });
  };

  const handleDeleteScannedImages = (index) => {
    let temp = [...scanedImges];
    temp.splice(index, 1);
    setScanedImges(temp);

    let temp1 = [...scanedImgesPost];
    temp1.splice(index, 1);
    setScanedImgesPost(temp1);
  };

  const openViewer = (index) => {
    setSelectedImageIndex(index);
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
            <CancelPresentationIconButton
              onClick={(e) => {
                !checkScanning && handleClose();
                e.preventDefault();
                //   setEdit(false);
                //   reset(defaultValues);
              }}
            />
            <div>
              <div className="my-2">
                <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                  Nursing Clearance
                </span>
              </div>

              <div className="flex gap-3 items-center">
                <div className="w-64 my-2">
                  <InputField
                    control={control}
                    name="amount"
                    placeholder="Document Number "
                    inputRef={{
                      ...register("amount", {
                        onChange: (e) => {
                          setAmount(e.target.value);
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <CommonButton
                    label={checkScanning ? `Scanning...` : `Scan Document`}
                    className=" bg-gray-600 text-white"
                    disabled={checkScanning}
                    onClick={() => {
                      getScannedDocument();
                    }}
                  />
                </div>
              </div>
              <div className="border border-gray lg:h-60 xl:h-[35rem] overflow-y-auto">
                <div className="flex p-2 px-10 gap-3 items-center flex-wrap">
                  {scanedImges.length > 0 &&
                    scanedImges.map((base64DataUri, index) => (
                      <div key={index} className="relative p-4">
                        <div className="absolute z-50 top-[2px] right-[2px]  cursor-pointer">
                          <CancelPresentationIconButton
                            onClick={() => {
                              handleDeleteScannedImages();
                            }}
                          />
                        </div>
                        <div
                          className="border border-gray-200 h-56 w-48 relative"
                          onClick={() => openViewer(index)}
                        >
                          <img
                            // src={`data:image/png;base64,${base64DataUri}`}
                            src={base64DataUri}
                            alt="img"
                          />
                        </div>

                        {/* Render full image*/}
                        {/*isViewerOpen && (
                          <ImageViewer
                            images={scanedImges}
                            onClose={closeViewer}
                            initialIndex={selectedImageIndex}
                          />
                        )*/}
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex gap-3 justify-end my-2">
                <div>
                  <CommonButton
                    label="Close"
                    disabled={checkScanning}
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>

                <div className="flex justify-end">
                  <CommonButton
                    label="Add"
                    className="bg-customGreen text-white"
                    onClick={onSubmit}
                    disabled={checkScanning}
                  />
                </div>
              </div>
            </div>
            {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
          </Box>
        </Modal>
        {isViewerOpen && (
          <ImageViewer
            open={isViewerOpen}
            images={scanedImges}
            onClose={closeViewer}
            initialIndex={selectedImageIndex}
          />
        )}
        {/* backdrop */}

        {/* //Confirmation Modal// */}
        {openConfirmationModal && (
          <ConfirmationModal
            confirmationOpen={openConfirmationModal}
            confirmationHandleClose={() => setOpenConfirmationModal(false)}
            confirmationSubmitFunc={() => handleFormSubmit()}
            confirmationLabel="Confirmation "
            confirmationMsg="Are You Sure ?"
            confirmationButtonMsg="Proceed"
          />
        )}
      </div>
    </>
  );
}
