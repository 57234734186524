import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

//main function
export default function RefundOfBillServiceList(props) {
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    handleSubmit,
    reset,
    register,
    methods,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const setRefundValue = (refundAmt, row, index) => {
    let obj = {
      Description: row.Description,
      ServiceCode: row.ServiceCode,
      Quantity: row.Quantity,
      Rate: row.Rate,
      NetAmount: row.NetAmount,
      Concession: row.Concession,
      Discount: row.Discount,
      StaffFree: row.StaffFree,
      TotalAmount: row.TotalAmount,
      DoctorName: row.DoctorName,
      CompanyRate: row.CompanyRate,
      DiscountPerc: row.DiscountPerc,
      DiscountAmt: row.DiscountAmt,
      DeductionPerc: row.DeductionPerc,
      DeductionAmt: row.DeductionAmt,
      Deductable: row.Deductable,
      NetAmt: row.NetAmt,
      IsAdminChargeApp: row.IsAdminChargeApp,
      IsServiceTaxApplicabel: row.IsServiceTaxApplicabel,
      ChargeId: row.ChargeId,
      refundAmount: Number(refundAmt),
    };
    let temp = [...props.serviceList];
    temp[index] = obj;
    props.setServiceList(temp);
  };

  useEffect(() => {
    let temp = [...props.serviceList];

    let totalRefund = 0;
    for (let obj of temp) {
      if (obj["refundAmount"]) {
        totalRefund = Number(totalRefund) + Number(obj.refundAmount);
        props.setRefundAmount(Number(totalRefund));
      }
    }
  }, [props]);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-48 2xl:h-56"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Service Name
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Qty
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Rate
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Total Amount
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className="text-gray-600 font-bold tex
                      t-center"
                      >
                        Refunded Amount
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className="text-gray-600 font-bold tex
                      t-center"
                      >
                        Refund Amount
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.serviceList.map((row, index) => {
                    return (
                      <TableRow
                        key={index.id}
                        sx={{
                          "& th": {
                            paddingY: 0,
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                        // onClick={() => {
                        //   props.displayRowData(row, index);
                        // }}
                      >
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.Description}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.Quantity}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.Rate}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.TotalAmount}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.Refunded}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {props.refundTypeVal === "BillWise" ? (
                            0
                          ) : (
                            <>
                              <div className="w-18">
                                <input
                                  type="number"
                                  name={`input${index}`}
                                  className="border rounded border-customBlue w-20 h-[25px] text-center"
                                  size="small"
                                  {...register(`input${index}`)}
                                  style={{
                                    border:
                                      row.refundAmount > row.TotalAmount
                                        ? "1px solid red"
                                        : "1px solid #3184a6",
                                  }}
                                  onChange={(e) => {
                                    setRefundValue(
                                      Number(e.target.value),
                                      row,
                                      index
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
