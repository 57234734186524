import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import SearchIconButton from "../../../../common/components/Buttons/SearchIconButton";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { fetchUnit } from "../../../../commonServices/miscellaneousServices/MiscServices";
import { fetchNursingSupervisorViewList } from "../../../services/nursingSupervisorDetailsServices/NuersingSupervisorDetailsServices";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "85%",

  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

const ViewModal = (props) => {
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables

  const [open, setOpen] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedRow, setSelectedRow] = React.useState();
  const [nursingSupervisorList, setNursingSupervisorList] = useState([]);
  const [unitDropdown, setUnitDropdown] = React.useState([]);
  //useEffects
  useEffect(() => {}, []);
  //functions
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // unit dropdown services
    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        setUnitDropdown(res.result);

        setValue("unit", res.result[0]);
      })
      .catch((error) => {
        // errorAlert(error.message);
      });
  }, []);

  useEffect(() => {
    fetchNursingSupervisorListing();
  }, [props?.open]);
  // Nursing supervisor view button listing
  function fetchNursingSupervisorListing() {
    let finalDate = selectedFromDate
      ? selectedFromDate.toISOString().slice(0, 10) + " 00:00:00.000"
      : null;

    fetchNursingSupervisorViewList(finalDate)
      .then((response) => response.data)
      .then((res) => {
        setNursingSupervisorList(res.result);
      })
      .catch((error) => {
        // errorAlert(error.message);
      });
  }
  function onSubmitDataHandler() {}
  return (
    <div className="w-full grid justify-center items-center rounded ">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              onClick={() => {
                reset();
                props.handleClose();
                setValue("unit", unitDropdown[0]);
                setSelectedFromDate(new Date());
              }}
            />
          </div>
          <div className="">
            <div className="">
              <p className="text-center text-xl text-gray-700 font-Poppins pb-2">
                Nursing Supervisor List
              </p>
            </div>
            <div className="flex gap-2 justify-between">
              <div className="grid grid-cols-2 gap-2">
                <div className="w-full">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    //defaultValue={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    disablePast={false}
                    disableFuture={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("fromDate", {
                        onChange: (e) => {
                          let finalDate = e?.target?.value
                            ? e?.target?.value.toISOString().slice(0, 10) +
                              " 00:00:00.000"
                            : null;

                          setValue("fromDate", finalDate);
                          setSelectedFromDate(e?.target?.value);
                        },
                      }),
                    }}
                  />
                </div>
                <div className="flex gap-2">
                  <SearchIconButton
                    onClick={() => {
                      fetchNursingSupervisorListing();
                    }}
                  />
                  <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      setSelectedFromDate(new Date());
                    }}
                  />
                </div>
              </div>
              <div className="w-[29%]">
                <DropdownField
                  control={control}
                  name="unit"
                  label="Unit"
                  placeholder="Unit"
                  dataArray={unitDropdown}
                />
              </div>
            </div>
            <div className="py-2">
              {nursingSupervisorList.length > 0 ? (
                <CommonDynamicTable
                  data={nursingSupervisorList}
                  highlightRow={true}
                  setSelectedRow={setSelectedRow}
                  tableClass={"rounded lg:h-40 md:h-72"}
                />
              ) : (
                <>
                  <div className="text-center mt-4 p-4">No Record Found</div>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {/* model and table name button end */}
      {/* Confirmation modal for PUT request */}
    </div>
  );
};

export default ViewModal;
