import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import { Controller } from "react-hook-form";
const CustomCheckbox = ({
  name,
  label,
  control,
  defaultValue,
  isFunction,
  isSubFunction,
  isDisabled,
}) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value = false, ...field } }) => {
            return (
              <Checkbox
                size="small"
                {...field}
                checked={!!value}
                disabled={isDisabled}
              />
            );
          }}
        />
      }
      label={
        isFunction ? (
          <span className="font-semibold text-[#0369a1]">{label}</span>
        ) : isSubFunction ? (
          <span className=" text-customBlue">{label}</span>
        ) : (
          <span className="truncate">{label}</span>
        )
      }
      sx={{ label: { fontWeight: 6 } }}
    />
  );
};

export default CustomCheckbox;
