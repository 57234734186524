import React from "react";

export default function FillDetailsrow() {
  return (
    <button>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" fill="#329DFF" />
        <path
          d="M19.6531 16.2057C19.1537 15.7869 16.2246 13.932 15.7377 14.0171C15.5091 14.0577 15.3343 14.2526 14.8663 14.8109C14.6499 15.0856 14.4122 15.343 14.1554 15.5806C13.685 15.4669 13.2297 15.2982 12.7989 15.0777C11.1093 14.2551 9.74436 12.8898 8.92229 11.2C8.70185 10.7692 8.53306 10.3138 8.41943 9.84343C8.65699 9.58668 8.91435 9.349 9.18914 9.13257C9.74686 8.66457 9.94229 8.49086 9.98286 8.26114C10.068 7.77314 8.21143 4.84514 7.79429 4.34571C7.61943 4.13886 7.46057 4 7.25714 4C6.66743 4 4 7.29829 4 7.72571C4 7.76057 4.05714 11.1943 8.39371 15.6063C12.8057 19.9429 16.2394 20 16.2743 20C16.7017 20 20 17.3326 20 16.7429C20 16.5394 19.8611 16.3806 19.6531 16.2057Z"
          fill="white"
        />
        <path
          d="M16 10.5H17C17 9 16.4165 7.91652 16 7.5C14.719 6.21895 14 6 12.5 6V7C13.4943 7.00099 14.297 7.29695 15 8C15.5 8.5 15.999 9.50574 16 10.5Z"
          fill="white"
        />
        <path
          d="M14.25 11H15C14.9991 10.2046 14.6827 9.44208 14.1203 8.87967C13.5579 8.31725 12.7954 8.00089 12 8V8.75C12.5966 8.7506 13.1685 8.98784 13.5903 9.40967C14.0122 9.8315 14.2494 10.4034 14.25 11Z"
          fill="white"
        />
        <path
          d="M17.989 10.0884H18.9121C18.9103 8.49764 18.2775 6.97258 17.1527 5.84776C16.0279 4.72294 14.5028 4.09021 12.9121 4.08838V5.01146C14.2581 5.01304 15.5485 5.54844 16.5003 6.50021C17.452 7.45197 17.9874 8.74238 17.989 10.0884Z"
          fill="white"
        />
      </svg>
    </button>
  );
}