import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputField from "../../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";

export default function PharmacyChargesTable(props) {
  const [openChild, setOpenChild] = React.useState(false);
  const [deleteGroup, setDeleteGroup] = React.useState("");

  const handleCloseChild = () => {
    if (openChild === true) {
      setOpenChild(false);
    }
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    trigger,
    setFocus,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    // defaultValues,
  });

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  const [chargesArray, setChargesArray] = React.useState([]);
  const [totalPharmacyCharges, setTotalPharmacyCharges] = React.useState([]);

  //function to get all the classes with rates in single array
  React.useEffect(() => {
    setChargesArray([]);
    if (props.pharmacyTableData.length > 0) {
      let dataArray = [...props.pharmacyTableData];
      let classData = [];
      for (let dataObject of dataArray) {
        for (let classObject of dataObject.selectedClasses) {
          classData.push(classObject);
          setChargesArray(classData);
        }
      }
    }
  }, [props.pharmacyTableData]);

  //function to get sum of the rates of the classes having same label
  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.label === val.label);
      if (ind !== -1) {
        acc[ind]["totalCharge"] = acc[ind]["totalCharge"] + val["totalCharge"];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  //passing the classes array to the getSum function
  React.useEffect(() => {
    let charges = structuredClone(chargesArray);
    let calculatedArray = mergeArray(charges);
    setTotalPharmacyCharges(calculatedArray);
    props.setParentCalculations([])
  }, [chargesArray]);

  React.useEffect(() => {
    props.setPharmacyTotal(totalPharmacyCharges);
  }, [totalPharmacyCharges]);

  const handleDeleteEstimation = (selectGroup) => {
    setOpenChild(true);
    setDeleteGroup(selectGroup);
  };

  const deleteRecord = () => {
    let afterDeleteArray = props.pharmacyTableData.filter(
      (item) => item.selectGroup.label !== deleteGroup.label
    );
    props.setPharmacyTableData(afterDeleteArray);
    handleCloseChild();
    setDeleteGroup("");
  };

  return (
    <div className="w-auto grid">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1 }}>
          <div className="flex gap-2 items-center">
            <span className="font-semibold pl-3 py-1">Pharmacy Charges</span>
          </div>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 7,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded h-48"
            style={{
              borderLeft: "1px solid lightgray",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="whitespace-nowrap"
                    style={{ background: "#F1F1F1" }}
                  >
                    <span className="font-bold">Action</span>
                  </TableCell>
                  <TableCell
                    className="whitespace-nowrap"
                    style={{ background: "#F1F1F1" }}
                  >
                    <span className="font-bold">Group Name</span>
                  </TableCell>
                  {props.pharmacyTableData[0]?.selectedClasses?.map((item) => {
                    return (
                      <TableCell
                        className="whitespace-nowrap"
                        style={{ background: "#F1F1F1" }}
                        sx={{ textAlign: "center" }}
                      >
                        {props.selectedNameString
                          .toString()
                          .includes(item.label) ? (
                          <span className="font-bold">{item.label}</span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {props.pharmacyTableData &&
                  props.pharmacyTableData.map((row, index) => {
                    return (
                      <TableRow
                        sx={{
                          "& td": {
                            paddingY: 0,
                          },
                        }}
                        tabIndex={-1}
                        key={index.id}
                        onClick={() => {
                          // handleClick(index, row);
                        }}
                      >
                        <TableCell>
                          <div
                            className="text-red-500 mr-3"
                            onClick={() => {
                              handleDeleteEstimation(row.selectGroup);
                            }}
                          >
                            {<DeleteOutlineOutlinedIcon />}
                          </div>
                        </TableCell>

                        <TableCell
                          className="whitespace-nowrap overflow-ellipsis capitalize "
                          sx={{ maxWidth: 5, overflow: "hidden" }}
                        >
                          {row.selectGroup?.label}
                        </TableCell>
                        {row?.selectedClasses?.map((classObject, i) => {
                          return (
                            <TableCell
                              className="whitespace-nowrap capitalize"
                              sx={{ textAlign: "center" }}
                            >
                              {props.selectedNameString
                                .toString()
                                .includes(classObject.label) ? (
                                <fieldset onChange={() => {}}>
                                  <input
                                    className="text-center w-24 rounded border border-gray-400"
                                    name={`pharmacyCharges${
                                      classObject.label + index
                                    }`}
                                    label="Charges"
                                    control={control}
                                    type="number"
                                    min={0}
                                    onKeyDown={(e) => {
                                      if (e.key === "-" || e.key === "+") {
                                        e.target.blur();
                                      }
                                      if (e.key === "ArrowDown") {
                                        e.preventDefault();
                                      } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onWheel={(e) => {
                                      e.target.blur();
                                    }}
                                    defaultValue={classObject.totalCharge}
                                    onChange={(e) => {
                                      if (e.target.value !== "") {
                                        let dataArray = [
                                          ...props.pharmacyTableData,
                                        ];
                                        dataArray[index].selectedClasses[
                                          i
                                        ].totalCharge = Number(e.target.value);
                                        props.setPharmacyTableData(dataArray);
                                      } else {
                                        let dataArray = [
                                          ...props.pharmacyTableData,
                                        ];
                                        dataArray[index].selectedClasses[
                                          i
                                        ].totalCharge = 0;
                                        props.setPharmacyTableData(dataArray);
                                      }
                                    }}
                                  />
                                </fieldset>
                              ) : (
                                ""
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}

                <TableRow
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#fcf9e6",
                  }}
                >
                  <TableCell></TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>
                    <span className="font-semibold">Total :</span>
                  </TableCell>
                  {totalPharmacyCharges.length > 0
                    ? totalPharmacyCharges.map((item) => {
                        return (
                          <TableCell sx={{ textAlign: "center" }}>
                            {props.selectedNameString
                              .toString()
                              .includes(item.label) ? (
                              <span className="font-bold">
                                {item.totalCharge}
                              </span>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        );
                      })
                    : ""}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <ConfirmationModal
        confirmationOpen={openChild}
        confirmationHandleClose={handleCloseChild}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />
    </div>
  );
}
