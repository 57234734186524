import React from "react";

export const MicrrosoftExceIcon = () =>{
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4557 2H13.8047V3.8618C16.0772 3.8618 18.3496 3.86646 20.6245 3.85461C21.0175 3.81741 21.4127 3.89486 21.7645 4.07803C21.9469 4.44045 22.0242 4.84879 21.9872 5.25477C21.9759 9.29094 21.9745 13.326 21.9831 17.3599C22.021 18.0393 21.9952 18.7208 21.9058 19.3952C21.8149 19.8788 21.2472 19.8907 20.8679 19.9067C18.5153 19.9135 16.1602 19.9021 13.8051 19.9067V22H12.3946C8.93344 21.3577 5.46527 20.7623 2 20.1412V3.8618C5.48593 3.24191 8.97186 2.63047 12.4557 2Z"
          fill="#207245"
        />
        <path
          d="M13.8047 4.55762H21.2992V19.21H13.8047V17.8137H15.6226V16.1867H13.8047V15.2558H15.6226V13.6284H13.8047V12.6975H15.6226V11.0697H13.8047V10.1388H15.6226V8.51141H13.8047V7.58305H15.6226V5.95312H13.8047V4.55762Z"
          fill="white"
        />
        <path
          d="M16.5303 5.9541H19.7117V7.58318H16.5303V5.9541Z"
          fill="#207245"
        />
        <path
          d="M8.72685 8.13232C9.24001 8.09508 9.75482 8.06334 10.2713 8.03711C9.6653 9.31047 9.05449 10.5812 8.43887 11.8492C9.06358 13.1516 9.70152 14.4447 10.3283 15.7471C9.78154 15.7149 9.23505 15.6792 8.68884 15.64C8.25695 14.6898 7.87856 13.715 7.55551 12.7204C7.24687 13.6648 6.80601 14.5555 6.45192 15.4814C5.95612 15.4742 5.45701 15.4534 4.95996 15.4323C5.54336 14.2623 6.10693 13.083 6.7085 11.9202C6.19741 10.7223 5.63674 9.54771 5.10953 8.357C5.60919 8.32653 6.10885 8.29706 6.60852 8.26857C6.9469 9.17789 7.31669 10.0758 7.59641 11.0084C7.89596 10.0199 8.34342 9.08987 8.72726 8.13358L8.72685 8.13232Z"
          fill="white"
        />
        <path
          d="M16.5303 8.51172H19.7117V10.14H16.5303V8.51172ZM16.5303 11.07H19.7117V12.6982H16.5303V11.07ZM16.5303 13.6283H19.7117V15.2565H16.5303V13.6283ZM16.5303 16.1866H19.7117V17.8148H16.5303V16.1866Z"
          fill="#207245"
        />
      </svg>
    </div>
  );
}
