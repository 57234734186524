import React from "react";
import { useNavigate } from "react-router-dom";
import clockImg from "./clockImg.jpg";

const DateTimeMismatchErrorPage = () => {
  let navigate = useNavigate();
  const handleRefresh = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <div className="h-screen  flex flex-col items-center justify-center">
      <div className="container mx-auto px-6 py-8 bg-white shadow-lg rounded-lg md:flex md:flex-row md:items-center md:justify-between">
        <div className="md:w-1/2 text-center md:text-left">
          <img
            src="https://img.icons8.com/ios-filled/100/000000/clock.png"
            alt="Clock Icon"
            className="mx-auto md:mx-0 md:mr-4 mb-4"
          />
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            Date/Time Error
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-8">
            The system date and time appear to be out of sync or not updated.
            Please check your settings.
          </p>
          <button
            onClick={handleRefresh}
            className="inline-block px-6 py-3 text-sm font-medium leading-5 shadow-sm text-white bg-blue-600 hover:bg-blue-700 rounded-lg focus:outline-none transition duration-300"
          >
            Refresh
          </button>
        </div>
        <div className="mt-8 md:mt-0 md:w-1/2 text-center">
          <img
            // src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg"
            src={clockImg}
            alt="Date/Time Error"
            className="mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default DateTimeMismatchErrorPage;
