import { Divider, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import CustomCheckbox from "../AssignFuctinalities/CustomCheckbox";

function AssignFunctionalityUsers({ functionalities, setFunctionalities }) {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedModule, setSelectedModule] = React.useState(functionalities[0]["subFunction"]);
  const [userRoles, setUserRoles] = React.useState([]);
  const [selectedFunctionality, setSelectedFunctionality] = React.useState("");
  const [showSubModules, setShowSubModules] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);

  const {
    watch,
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
    register,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  //useEffect for settting value to the checkboxes depending on changes
  React.useEffect(() => {
    if (selectedModule.length > 0) {
      let subFunctionOne = selectedModule;
      for (let object of subFunctionOne) {
        setValue(
          `Module${object.functionality + selectedFunctionality + object.id}`,
          object.isChecked
        );

        //if there are permissions
        if (object["permissions"]) {
          let permissionOne = object["permissions"];
          for (let permissionObject of permissionOne) {
            setValue(
              `Module${
                permissionObject.permission + selectedFunctionality + object.id
              }`,
              permissionObject.isChecked
            );
          }
        }

        //level two
        if (object["subFunction"]) {
          let subFunctionTwo = object["subFunction"];
          for (let object of subFunctionTwo) {
            setValue(
              `Module${
                object.functionality + selectedFunctionality + object.id
              }`,
              object.isChecked
            );

            //if there are permissions
            if (object["permissions"]) {
              let permissionTwo = object["permissions"];
              for (let permissionObject of permissionTwo) {
                setValue(
                  `Module${
                    permissionObject.permission +
                    selectedFunctionality +
                    object.id
                  }`,
                  permissionObject.isChecked
                );
              }
            }

            //level Three
            if (object["subFunction"]) {
              let subFunctionThree = object["subFunction"];
              for (let object of subFunctionThree) {
                setValue(
                  `Module${
                    object.functionality + selectedFunctionality + object.id
                  }`,
                  object.isChecked
                );

                //if there are permissions
                if (object["permissions"]) {
                  let permissionThree = object["permissions"];
                  for (let permissionObject of permissionThree) {
                    setValue(
                      `Module${
                        permissionObject.permission +
                        selectedFunctionality +
                        object.id
                      }`,
                      permissionObject.isChecked
                    );
                  }
                }
              }
            }
          }
        }
        setShowSubModules(true);
      }
    }
  }, [selectedModule]);

  //useEffect to handle isChecked = false values if all the flags are false.
  React.useEffect(() => {
    let dataArray = [...functionalities];
    //######### handeling isChecked flag if all the values at this level are false then making parent false

    let falseArray = [];

    if (selectedModule?.length > 0) {
      for (let obj of selectedModule) {
        if (obj.isChecked === false) {
          falseArray.push(obj);
        }
      }
      if (falseArray.length === selectedModule?.length) {
        setValue(`Module${selectedFunctionality}`, false);
        dataArray[selectedIndex].isChecked = false;
      }
    }

    //################
  }, [functionalities]);

  //function to select the subFunctions for mapping
  const handleSelectModule = (item, itemIndex) => {
    if (item["subFunction"]) {
      setSelectedFunctionality(item.functionality);
      setSelectedModule(item.subFunction);
    } else {
      setSelectedModule([]);
      setSelectedFunctionality("");
    }
  };

  //function to allow or do not allow main module
  const handleMainModuleChange = (e, item, itemIndex) => {
    if (item["subFunction"]) {
      setSelectedModule(item["subFunction"]);
      setSelectedFunctionality(item.functionality);
    }
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      dataArray[selectedIndex].isChecked = true;

      //level one
      if (dataArray[selectedIndex]["subFunction"]) {
        let subFunctionOne = dataArray[selectedIndex]["subFunction"];
        for (let object of subFunctionOne) {
          object.isChecked = true;

          //if there are permissions
          if (object["permissions"]) {
            let permissionOne = object["permissions"];
            for (let permissionObject of permissionOne) {
              permissionObject.isChecked = true;
            }
          }

          //level two
          if (object["subFunction"]) {
            let subFunctionTwo = object["subFunction"];
            for (let object of subFunctionTwo) {
              object.isChecked = true;

              //if there are permissions
              if (object["permissions"]) {
                let permissionTwo = object["permissions"];
                for (let permissionObject of permissionTwo) {
                  permissionObject.isChecked = true;
                }
              }

              //level Three
              if (object["subFunction"]) {
                let subFunctionThree = object["subFunction"];
                for (let object of subFunctionThree) {
                  object.isChecked = true;

                  //if there are permissions
                  if (object["permissions"]) {
                    let permissionThree = object["permissions"];
                    for (let permissionObject of permissionThree) {
                      permissionObject.isChecked = true;
                    }
                  }
                }
              }
            }
          }
        }
      }

      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      dataArray[selectedIndex].isChecked = false;

      //level one
      if (dataArray[selectedIndex]["subFunction"]) {
        let subFunctionOne = dataArray[selectedIndex]["subFunction"];
        for (let object of subFunctionOne) {
          object.isChecked = false;

          //if there are permissions
          if (object["permissions"]) {
            let permissionOne = object["permissions"];
            for (let permissionObject of permissionOne) {
              permissionObject.isChecked = false;
            }
          }

          //level two
          if (object["subFunction"]) {
            let subFunctionTwo = object["subFunction"];
            for (let object of subFunctionTwo) {
              object.isChecked = false;

              //if there are permissions
              if (object["permissions"]) {
                let permissionTwo = object["permissions"];
                for (let permissionObject of permissionTwo) {
                  permissionObject.isChecked = false;
                }
              }

              //level Three
              if (object["subFunction"]) {
                let subFunctionThree = object["subFunction"];
                for (let object of subFunctionThree) {
                  object.isChecked = false;

                  //if there are permissions
                  if (object["permissions"]) {
                    let permissionThree = object["permissions"];
                    for (let permissionObject of permissionThree) {
                      permissionObject.isChecked = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
      setFunctionalities(dataArray);
    }
  };

  //fuction to allow or do not allow subFunction
  const handleSubFunctionChange = (e, subItem, subItemIndex) => {
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;
      dataArray[selectedIndex]["subFunction"][subItemIndex].isChecked = true;

      //permission level one
      if (
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"]
      ) {
        let permissionsOne =
          dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"];
        for (let permissionObject of permissionsOne) {
          permissionObject.isChecked = true;
        }
      }

      //level one
      let subFunctionOne =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"];
      if (subFunctionOne) {
        for (let object of subFunctionOne) {
          object.isChecked = true;

          //permission level two
          if (object["permissions"]) {
            let permissionsTwo = object["permissions"];
            for (let permissionObject of permissionsTwo) {
              permissionObject.isChecked = true;
            }
          }

          // level two
          let subFunctionTwo = object["subFunction"];
          if (subFunctionTwo) {
            for (let object of subFunctionTwo) {
              object.isChecked = true;
            }
          }
        }
      }

      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];

      dataArray[selectedIndex]["subFunction"][subItemIndex].isChecked = false;

      //permission level one
      if (
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"]
      ) {
        let permissionsOne =
          dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"];
        for (let permissionObject of permissionsOne) {
          permissionObject.isChecked = false;
        }
      }

      //level one
      let subFunctionOne =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"];
      if (subFunctionOne) {
        for (let object of subFunctionOne) {
          object.isChecked = false;

          //permission level two
          if (object["permissions"]) {
            let permissionsTwo = object["permissions"];
            for (let permissionObject of permissionsTwo) {
              permissionObject.isChecked = false;
            }
          }

          // level two
          let subFunctionTwo = object["subFunction"];
          if (subFunctionTwo) {
            for (let object of subFunctionTwo) {
              object.isChecked = false;
            }
          }
        }
      }
      setFunctionalities(dataArray);
    }
  };

  //function to handle main module permissions change
  const handleMainPermissionsChange = (
    e,
    mainPermission,
    subItemIndex,
    mainPermissionIndex
  ) => {
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;
      let mainPermission =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"][
          mainPermissionIndex
        ];
      mainPermission.isChecked = true;

      let parent = dataArray[selectedIndex]["subFunction"][subItemIndex];
      parent.isChecked = true;
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      let mainPermission =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"][
          mainPermissionIndex
        ];
      mainPermission.isChecked = false;

      let falseArray = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falseArray.push(obj);
          }
        }
        if (falseArray.length === selected.length) {
          let parent = dataArray[selectedIndex]["subFunction"][subItemIndex];
          parent.isChecked = false;
        }
      }

      setFunctionalities(dataArray);
    }
  };

  //function to allow or do not allow subFunctionTwo
  const handleSubFunctionTwo = (
    e,
    subItemTwo,
    subItemIndex,
    subItemTwoIndex
  ) => {
    let dataArray = [...functionalities];

    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;

      let parent = dataArray[selectedIndex]["subFunction"][subItemIndex];
      parent.isChecked = true;

      let subFunction =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ];
      subFunction.isChecked = true;
      if (subFunction["permissions"]) {
        let permissionOne = subFunction["permissions"];
        for (let permissionObject of permissionOne) {
          permissionObject.isChecked = true;
        }
      }

      //handling permissions in subFunction
      if (subFunction["subFunction"]) {
        let functionsTwo = subFunction["subFunction"];
        if (functionsTwo) {
          for (let functionObject of functionsTwo) {
            functionObject.isChecked = true;
          }
        }
      }
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];

      let falseArray = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falseArray.push(obj);
          }
        }
        if (falseArray?.length + 1 === selected.length) {
          let falseObject =
            dataArray[selectedIndex]["subFunction"][subItemIndex];
          falseObject.isChecked = false;
        }
      }

      let subFunction =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ];
      subFunction.isChecked = false;
      if (subFunction["permissions"]) {
        let permissionOne = subFunction["permissions"];
        for (let permissionObject of permissionOne) {
          permissionObject.isChecked = false;
        }
      }
      if (subFunction["subFunction"]) {
        let functionsTwo = subFunction["subFunction"];
        if (functionsTwo) {
          for (let functionObject of functionsTwo) {
            functionObject.isChecked = false;
          }
        }
      }
      setFunctionalities(dataArray);
    }
  };

  const handleSubItemThree = (
    e,
    subItemIndex,
    subItemTwoIndex,
    subItemThreeIndex
  ) => {
    let dataArray = [...functionalities];
    let subFunctionThreeParent =
      dataArray[selectedIndex]["subFunction"][subItemTwoIndex]["subFunction"][
        subItemThreeIndex
      ];
    let parentTwo = dataArray[selectedIndex]["subFunction"][subItemTwoIndex];
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      let subFunctionThree =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["subFunction"][subItemThreeIndex];

      subFunctionThree.isChecked = true;
      //parent
      subFunctionThreeParent.isChecked = true;
      //subParent
      parentTwo.isChecked = true;
      //main module
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      let subFunctionThree =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["subFunction"][subItemThreeIndex];
      subFunctionThree.isChecked = false;

      let falseArray = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["subFunction"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falseArray.push(obj);
          }
        }
        if (falseArray?.length === selected?.length) {
          subFunctionThree.isChecked = false;
          //parent
          subFunctionThreeParent.isChecked = false;
          //subParent
          parentTwo.isChecked = false;
          //main module
          setValue(`Module${selectedFunctionality}`, false);
          dataArray[selectedIndex].isChecked = false;
        }
      }

      setFunctionalities(dataArray);
    }
  };

  //function to handle permission selection change
  const handlePermissionChange = (
    e,
    subItemIndex,
    subItemTwoIndex,
    subPermissionIndex
  ) => {
    let countOfFlase = [];
    if (e.target.checked === true) {
      //setting isChecked=true for permissions
      let dataArray = [...functionalities];

      let firstParent =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ];

      firstParent.isChecked = true;

      let secondParent = dataArray[selectedIndex]["subFunction"][subItemIndex];
      secondParent.isChecked = true;
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;

      let permissions =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"];
      if (permissions) {
        permissions[subPermissionIndex].isChecked = true;
      }
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      //setting isChecked=false for permissions
      let dataArray = [...functionalities];
      let permissions =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"];
      if (permissions) {
        permissions[subPermissionIndex].isChecked = false;
      }

      let falsePermissions = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falsePermissions.push(obj);
          }
        }

        //handle false flag if all the permissions are false
        if (falsePermissions?.length === selected?.length) {
          let firstParent =
            dataArray[selectedIndex]["subFunction"][subItemIndex][
              "subFunction"
            ][subItemTwoIndex];
          let secondParent =
            dataArray[selectedIndex]["subFunction"][subItemIndex];
          firstParent.isChecked = false;
          secondParent.isChecked = false;
          setValue(`Module${selectedFunctionality}`, true);
          dataArray[selectedIndex].isChecked = true;
        }
      }

      setFunctionalities(dataArray);
    }
  };

  const selectMainModule = (item, itemIndex) => {
    let dataArray = [...functionalities];
    dataArray[itemIndex].isChecked = true;

    //level one
    if (dataArray[itemIndex]["subFunction"]) {
      let subFunctionOne = dataArray[itemIndex]["subFunction"];
      for (let object of subFunctionOne) {
        object.isChecked = true;

        //if there are permissions
        if (object["permissions"]) {
          let permissionOne = object["permissions"];
          for (let permissionObject of permissionOne) {
            permissionObject.isChecked = true;
          }
        }

        //level two
        if (object["subFunction"]) {
          let subFunctionTwo = object["subFunction"];
          for (let object of subFunctionTwo) {
            object.isChecked = true;

            //if there are permissions
            if (object["permissions"]) {
              let permissionTwo = object["permissions"];
              for (let permissionObject of permissionTwo) {
                permissionObject.isChecked = true;
              }
            }

            //level Three
            if (object["subFunction"]) {
              let subFunctionThree = object["subFunction"];
              for (let object of subFunctionThree) {
                object.isChecked = true;

                //if there are permissions
                if (object["permissions"]) {
                  let permissionThree = object["permissions"];
                  for (let permissionObject of permissionThree) {
                    permissionObject.isChecked = true;
                  }
                }
              }
            }
          }
        }
      }
    }

    setFunctionalities(dataArray);
  };
  return (
    <>
      <div>
        <div className="flex justify-center"></div>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item lg={12} sm={12}>
            {/* mapping functionalities if array is available */}
            {functionalities?.length > 0 ? (
              <div className="flex">
                <div className="w-[17%] px-6 rounded ml-[1%] overflow-scroll h-[250px] lg:h-[280px] xl:h-[380px] 2xl:h-[480px] 3xl:h-[600px] bg-white">
                  {functionalities?.length > 0
                    ? functionalities?.map((item, itemIndex) => {
                        setValue(
                          `Module${item.functionality + selectedFunctionality}`,
                          item?.isChecked
                        );

                        return (
                          <div
                            className={`${
                              itemIndex === selectedIndex
                                ? `bg-yellow-400 rounded p-1 my-1 px-2 flex text-sm items-center  font-bold`
                                : `rounded  my-2 px-2 flex text-sm items-center `
                            }?`}
                            onClick={() => {
                              setSelectedIndex(itemIndex);
                              handleSelectModule(item, itemIndex);
                              // selectMainModule(item, itemIndex);
                            }}
                          >
                            <fieldset
                              onChange={(e) => {
                                handleMainModuleChange(e, item, itemIndex);
                              }}
                            >
                              <CustomCheckbox
                                control={control}
                                name={`Module${
                                  item.functionality + selectedFunctionality
                                }`}
                                defaultValue={item?.isChecked}
                              />
                            </fieldset>
                            <span>{item?.functionality}</span>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{
                    border: 0.5,
                    marginX: 2,
                    maxHeight: "600px",
                    borderColor: "#c8c9cc",
                  }}
                />
                <div
                  className={`${
                    selectedModule["subFuction"]?.length > 0
                      ? `w-[75%] rounded h-[250px] lg:h-[280px] xl:h-[380px] 2xl:h-[480px] 3xl:h-[600px] overflow-scroll`
                      : `w-[75%] rounded h-[250px] lg:h-[280px] xl:h-[380px] 2xl:h-[480px] 3xl:h-[600px] overflow-scroll`
                  }`}
                >
                  {selectedModule.length > 0 && selectedModule.length > 0 ? (
                    selectedModule.map((subItem, subItemIndex) => {
                      if (selectedModule?.length > 0) {
                        setValue(
                          `Module${
                            subItem.funtionality +
                            selectedFunctionality +
                            subItem.id
                          }`,
                          subItem?.isChecked
                        );
                      }
                      return (
                        <div className="p-2">
                          <fieldset
                            onChange={(e) => {
                              handleSubFunctionChange(e, subItem, subItemIndex);
                            }}
                          >
                            <CustomCheckbox
                              isFunction={true}
                              control={control}
                              name={`Module${
                                subItem.funtionality +
                                selectedFunctionality +
                                subItem.id
                              }`}
                              label={subItem.functionality}
                            />
                          </fieldset>
                          <div className="grid gap-2 ">
                            {subItem["permissions"] ? (
                              <div className="grid grid-cols-4 bg-[#F1F1F1] rounded border border-gray-400 p-1 px-2 mx-[2.5%]">
                                {subItem["permissions"].map(
                                  (mainPermission, mainPermissionIndex) => {
                                    setValue(
                                      `Module${
                                        mainPermission.permission +
                                        selectedFunctionality +
                                        subItem.id
                                      }`,
                                      mainPermission.isChecked
                                    );
                                    return (
                                      <fieldset
                                        onChange={(e) => {
                                          handleMainPermissionsChange(
                                            e,
                                            mainPermission,
                                            subItemIndex,
                                            mainPermissionIndex
                                          );
                                        }}
                                      >
                                        <CustomCheckbox
                                          control={control}
                                          name={`Module${
                                            mainPermission.permission +
                                            selectedFunctionality +
                                            subItem.id
                                          }`}
                                          label={mainPermission.permission}
                                        />
                                      </fieldset>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {subItem["subFunction"] ? (
                              subItem.subFunction.map(
                                (subItemTwo, subItemTwoIndex) => {
                                  if (selectedModule?.length > 0) {
                                    setValue(
                                      `Module${
                                        subItemTwo.functionality +
                                        selectedFunctionality +
                                        subItemTwo.id
                                      }`,
                                      subItemTwo.isChecked
                                    );
                                  }
                                  return (
                                    <>
                                      <div className="bg-[#F1F1F1] rounded border border-gray-400 p-1 px-2 mx-[2.5%]">
                                        <fieldset
                                          onChange={(e) => {
                                            handleSubFunctionTwo(
                                              e,
                                              subItemTwo,
                                              subItemIndex,
                                              subItemTwoIndex
                                            );
                                          }}
                                        >
                                          <CustomCheckbox
                                            control={control}
                                            name={`Module${
                                              subItemTwo.functionality +
                                              selectedFunctionality +
                                              subItemTwo.id
                                            }`}
                                            label={subItemTwo.functionality}
                                            ischildren={true}
                                          />
                                        </fieldset>
                                        {subItemTwo["permissions"] ? (
                                          <div className="grid grid-cols-4 p-1 ml-2">
                                            {subItemTwo?.permissions.map(
                                              (
                                                subPermission,
                                                subPermissionIndex
                                              ) => {
                                                setValue(
                                                  `Module${
                                                    subPermission.permission +
                                                    selectedFunctionality +
                                                    subItemTwo.id
                                                  }`,
                                                  subPermission.isChecked
                                                );

                                                return (
                                                  <fieldset
                                                    onChange={(e) => {
                                                      handlePermissionChange(
                                                        e,
                                                        subItemIndex,
                                                        subItemTwoIndex,
                                                        subPermissionIndex
                                                      );
                                                    }}
                                                  >
                                                    <CustomCheckbox
                                                      control={control}
                                                      name={`Module${
                                                        subPermission.permission +
                                                        selectedFunctionality +
                                                        subItemTwo.id
                                                      }`}
                                                      label={
                                                        subPermission?.permission
                                                      }
                                                    />
                                                  </fieldset>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {subItemTwo["subFunction"] ? (
                                          <div className="grid p-1  mx-6">
                                            {subItemTwo["subFunction"]
                                              ? subItemTwo.subFunction.map(
                                                  (
                                                    permission,
                                                    permissionIndex
                                                  ) => {
                                                    if (
                                                      selectedModule?.length > 0
                                                    ) {
                                                      setValue(
                                                        `Module${
                                                          permission.functionality +
                                                          selectedFunctionality +
                                                          permission.id
                                                        }`,
                                                        permission.isChecked
                                                      );
                                                    }
                                                    return (
                                                      <fieldset
                                                        onChange={(e) => {
                                                          handleSubItemThree(
                                                            e,
                                                            subItemIndex,
                                                            subItemTwoIndex,
                                                            permissionIndex
                                                          );
                                                        }}
                                                      >
                                                        <CustomCheckbox
                                                          control={control}
                                                          name={`Module${
                                                            permission.functionality +
                                                            selectedFunctionality +
                                                            permission.id
                                                          }`}
                                                          label={
                                                            permission.functionality
                                                          }
                                                        />
                                                      </fieldset>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  );
                                }
                              )
                            ) : subItem["subFunction"] ? (
                              <div className="grid grid-cols-4 mx-[2.5%] border border-gray-400 bg-[#F1F1F1] p-1  rounded">
                                {subItem.subFunction.map(
                                  (permissionTwo, permissionTwoIndex) => {
                                    if (selectedModule?.length > 0) {
                                      setValue(
                                        `Module${
                                          permissionTwo.permission +
                                          selectedFunctionality +
                                          permissionTwo.id
                                        }`,
                                        permissionTwo.isChecked
                                      );
                                    }
                                    return (
                                      <fieldset>
                                        <CustomCheckbox
                                          name={`Module${
                                            permissionTwo.permission +
                                            selectedFunctionality +
                                            permissionTwo.id
                                          }`}
                                          control={control}
                                          label={permissionTwo.permission}
                                        />
                                      </fieldset>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : selectedModule["children"] ? (
                    <div className="text-xl text-gray-400 font-semibold flex justify-center my-10">
                      Please Select Module.
                    </div>
                  ) : (
                    <div className="text-xl text-gray-400 font-semibold flex justify-center my-10">
                      No Functionalities
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-lg font-semibold flex justify-center text-gray-400">
                No Records Found.
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default AssignFunctionalityUsers;
