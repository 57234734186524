import React, { useEffect, useState } from "react";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { fetchStore } from "../../../commonServices/miscellaneousServices/MiscServices";
import { useForm } from "react-hook-form";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getExportToExcel,
  getItemListBelowStockLevel,
} from "../../services/showItemListBelowStockLevelService/ShowItemListBelowStockLevelServices";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { MicrrosoftExceIcon } from "../../../assets/icons/MicrrosoftExceIcon";
import { Tooltip } from "@mui/material";

export default function ShowItemListBelowStockLevel() {
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const defaultValues = {
    store: {
      id: getUserInfo.storeId,
      label: getUserInfo.storeName,
      value: getUserInfo.storeId,
    },
  }; 

  const { control, watch } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  let storeVal = watch("store");
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataResult, setDataResult] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [page, setPage] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(null);
  const populateTable = (forPagination) => {
    setShowSpinner(true);
    getItemListBelowStockLevel(
      storeVal?.id ? storeVal?.id : 0,
      !forPagination ? 0 : page,
      rowsPerPage
    )
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setShowSpinner(false);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        setShowSpinner(false);
        errorAlert(error.message);
      });
  };

  const handleExportTtoExcel = () => {
    getExportToExcel(storeVal?.id ? storeVal?.id : 0, page, rowsPerPage)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          const decodedData = atob(response.data.result);
          const arrayBuffer = new ArrayBuffer(decodedData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          window.open(url);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const ConfirmExportToExcelRecord = () => {
    setOpenConfirm(false);
    handleExportTtoExcel();
  };

  useEffect(() => {
    //
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        setStoreOptions(res.result);
      });
  }, []);

  useEffect(() => {
    populateTable();
  }, [storeVal]);

  return (
    <div className="mt-12 mb-2 px-3">
      <h1 className="text-center text-black font-bold text-xl">
        Item List Below Stock Level
      </h1>

      <div className="grid grid-cols-4 items-center gap-3">
        <DropdownField
          control={control}
          name="store"
          placeholder="Store"
          dataArray={storeOptions}
          isClearable={true}
          isSearchable={true}
        />

        <div className="">
          <Tooltip title="Export To Excel">
            <button
              onClick={() => {
                setOpenConfirm(true);
              }}
            >
              <MicrrosoftExceIcon />
            </button>
          </Tooltip>
        </div>
      </div>

      {showSpinner ? (
        <div className="flex pt-6 justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dataResult.length > 0 ? (
            <>
              <CommonDynamicTablePaginationNew
                dataResult={dataResult}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                highlightedRow={false}
                removeHeaders={["Id"]}
                //renderActions={renderActions}
                highlightRow={false}
                tableHeading="Item List"
              />
            </>
          ) : (
            <h1 className="flex justify-center py-6 font-bold text-gray-600">
              No Record Found...
            </h1>
          )}
        </>
      )}

      <ConfirmationModal
        confirmationOpen={openConfirm}
        confirmationHandleClose={handleCloseConfirm}
        confirmationSubmitFunc={ConfirmExportToExcelRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want export to excel this records ?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}
