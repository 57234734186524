import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//  ward on floor
export const fetchWard = (floorId) => {
  return apiClient.get(`/bedOccupancy/wardList/${floorId}`, {
    headers: authHeader(),
  });
};

//  ward on floor
export const fetchBedList = (postedObj) => {
  return apiClient.post(`/bedOccupancy/bedList`, postedObj, {
    headers: authHeader(),
  });
};

//  ward on floor
export const changeChargesPerBedCategory = (postedObj) => {
  return apiClient.post(
    `/bedOccupancy/changeChargesPerBedCategory`,
    postedObj,
    {
      headers: authHeader(),
    }
  );
};

//for Authorization : bed images
export const fetchBedlistIcon = (icon) => {
  icon.map((item) => {
    return apiClient.get(`/file/${item.ImgPath}`, {
      headers: authHeader(),
    });
  });
};

///secondary bed allocation  VVVV //

//save Secondary Bed
export const saveAllocateSecondaryBed = (postedObj) => {
  return apiClient.post(`bedOccupancy/allocateSecondaryBed`, postedObj, {
    headers: authHeader(),
  });
};

//allocated Secondary Bed
export const fetchAllocatedSecondaryBed = (admissionId) => {
  return apiClient.get(
    `/bedOccupancy/getSecondaryAllocatedBeds/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

//release Secondary Bed
export const releaseSecondaryAllocatedBed = (bedId, menuId, privilege) => {
  return apiClient.get(
    `/bedOccupancy/releaseSecondaryAllocatedBed/${bedId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

// Book Bed
export const saveBedBooking = (finalObj) => {
  return apiClient.post(`/bedOccupancy/bedBooking`, finalObj, {
    headers: authHeader(),
  });
};

// UnBook Bed
export const unReservedBed = (finalObj) => {
  return apiClient.post(`/bedOccupancy/unreservedBed`, finalObj, {
    headers: authHeader(),
  });
};

// UnBook Bed
export const fetchBookingList = (page, size) => {
  return apiClient.get(`/bedOccupancy/getBookingList/${page}/${size}`, {
    headers: authHeader(),
  });
};
