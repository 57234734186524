import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";

import { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { fetchOtDetailsViewData } from "../../../services/otDetailsServices/OtDetailsServices";

import { EyeOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import DocumentsViewModal from "../../../../ipd/components/admission/commonModal/DocumentsViewModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function ViewOtDetailsModal(props) {
  const { open, handleClose, selectedRow } = props;

  const [patchData, setPatchData] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [pathForDocView, setPathForDocView] = useState();
  //

  const [openDocumentsViewModal, setOpenDocumentsViewModal] = React.useState(
    false
  );
  const handleOpenDocumentsViewModal = () => setOpenDocumentsViewModal(true);
  const handleCloseDocumentsViewModal = () => setOpenDocumentsViewModal(false);
  //

  useEffect(() => {
    getSearchedData();
  }, []);

  ///patch Data
  const getSearchedData = () => {
    fetchOtDetailsViewData(selectedRow.OTHeaderID, selectedRow.opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setPatchData(res.result);
        // setEnteredAge(res.result.Age)
      })
      .catch((response) => {});
  };

  const SergeryDetailsHeaders = [
    {
      otSurgeryDetailsId: "",
      SurgeryName: "",
      SurgeonFees: "",
      ProcedureType: "",
      Rate: "",
      Qty: "",
      SurgeryId: "",
      ServiceCode: "",
      TariffServiceId: "",
    },
  ];

  const doctorsDetailsHeaders = [
    {
      otDoctorDetailsId: "",
      DoctorName: "",
      DoctorShare: "",
      "Surgery Name": "",
      "Doctor Type": "",
      DoctorId: "",
      surgeryId: "",
    },
  ];

  const empDtlsHeaders = [
    { otEmployeeDetailsId: "", "Employee Name": "", EmployeeId: "" },
  ];

  const pendingImplantDtlsHeaders = [
    {
      Rate: "",
      Quantity: "",
    },
  ];

  const ottherDtlsHeaders = [
    {
      "Service Name": "",
      TotalAmt: "",
      Qty: "",
      Rate: "",
    },
  ];

  const renderInputDocView = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.FilePath);
      handleOpenDocumentsViewModal(true);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          {row.FilePath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form
          //   onSubmit={methods.handleSubmit(onSubmitMlc)}
          >
            <div>
              <div className="text-black font-bold text-lg pb-1">
                Admitted Patient Information
              </div>
              <div className="grid w-full ">
                {/* Populate Patient Info Fields */}
                <div className="grid border border-gray-300 bg-gray-100 px-3 rounded-md mt-2">
                  <div className="grid grid-cols-2  gap-2 py-2">
                    <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                      {/* Patient Name , UHID */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-1">
                          <span className="text-sm">Patient Name </span>
                          <span className=""> :</span>
                        </h1>

                        <h1 className="font-normal">
                          {patchData ? patchData.PatientName : null}
                        </h1>
                      </div>
                      {/* Gender */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                          <span className="text-sm">Gender</span>
                          <span className="lg:pl-7 md:pl-6">:</span>
                        </h1>

                        <h1 className="font-normal">
                          {patchData ? patchData.Gender : null}
                        </h1>
                      </div>
                    </div>

                    {/* MRNo */}
                    <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">MRNo</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="font-normal">
                          {patchData ? patchData.MRNo : null}
                        </h1>
                      </div>
                      {/* age */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">Age</span>
                          <span className="md:pl-4">:</span>
                        </h1>
                        <h1 className="font-normal">
                          {patchData ? patchData.AgeYear : null}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ///// Personal Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  OT Sheet Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">
                        Opereation Date
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.Date}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Start time</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.StartTime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">End Time</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {
                            // format(
                            //   new Date(
                            //     patchData && patchData.DOB,
                            //     "yyyy-MM-dd'T'HH:mm:ss"
                            //   )
                            // )

                            patchData && patchData.EndTime
                          }
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40"> Total Hrs</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.TotalTime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Theater</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.Theator}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Aneas</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.AnaesType}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Procedure Type
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.ProcedureType}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">OT Result</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.OTResult}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">OT Status</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.OTStatus}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Nurse</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.Nurse}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Physician</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.Nurse}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">OT Type</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.OTType}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">OT Category</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.OTCategory}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Man Power</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.ManPower}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* /////  Operator CheckList*/}
              <div className="  border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900  text-base font-bold px-2 py-1">
                  Operator CheckList
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">
                        Surgeon Arrival Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.SurgeonArrivaltime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Anesthesist Arrival Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.AnasthetistArrivaltime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Patient Arrival Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.PatientArrivaltime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Time Of Administration Anesthesia
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData &&
                            patchData.TimeOfAdministrationAnesthesia}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Wheel In</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.TimeOfWheelIn}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Wheel Out</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.TimeOfWheelOut}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        OT Cleaning Start Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.OTCleaningStartTime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        OT Cleaning End Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.OTCleaningEndTime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Antibiotic Given At Ward
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.pincode}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Antibiotic Given At Ward Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.AntibioticGivenAtWardTime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Antibiotic Given At OT
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.pincode}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Antibiotic Given At OT Time
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {patchData && patchData.AntibioticGivenAtOTTime}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* ///// Surgery Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Surgery Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid  ">
                    {/* {patchData && patchData?.surgeryDetails.length > 0 ? ( */}
                    <CommonDynamicTableNew
                      dataResult={
                        patchData && patchData?.surgeryDetails.length > 0
                          ? patchData?.surgeryDetails
                          : SergeryDetailsHeaders
                      }
                      removeHeaders={[
                        "Service Code",
                        "ServiceId",
                        "SurgeryId",
                        "TariffServiceId",
                        "otSurgeryDetailsId",
                        "ChargeId",
                        "ProcedureTypeId",
                      ]}
                      tableClass="h-52"
                    />
                    {/* // ) : null} */}
                  </div>
                </fieldset>
              </div>

              {/* ///// Doctor Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Doctor Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid ">
                    <CommonDynamicTableNew
                      dataResult={
                        patchData && patchData?.doctorDetails.length > 0
                          ? patchData?.doctorDetails
                          : doctorsDetailsHeaders
                      }
                      removeHeaders={[
                        "DoctorId",
                        "surgeryId",
                        "otDoctorDetailsId",
                      ]}
                      tableClass="h-52"
                    />
                  </div>
                </fieldset>
              </div>

              {/* ///// Employee Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Employee Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid ">
                    <CommonDynamicTableNew
                      dataResult={
                        patchData && patchData?.employeeDetails.length > 0
                          ? patchData?.employeeDetails
                          : empDtlsHeaders
                      } //
                      removeHeaders={["EmployeeId", "otEmployeeDetailsId"]}
                      tableClass="h-52"
                    />
                  </div>
                </fieldset>
              </div>

              {/* ///// Other Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Other Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid ">
                    <CommonDynamicTableNew
                      dataResult={
                        patchData && patchData?.otherDetails.length > 0
                          ? patchData?.otherDetails
                          : ottherDtlsHeaders
                      }
                      removeHeaders={[
                        "ChargeId",
                        "ServiceId",
                        "TariffServiceId",
                        "isDelete",
                        "otOtherDetailsId",
                      ]}
                      tableClass="h-52"
                    />
                  </div>
                </fieldset>
              </div>
              {/* ///// pending implant Details*/}
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Pending Implant Details
                </div>
                <fieldset className="">
                  <div className="py-1 grid ">
                    <CommonDynamicTableNew
                      dataResult={
                        patchData && patchData?.pendingImplants.length > 0
                          ? patchData?.pendingImplants
                          : pendingImplantDtlsHeaders
                      }
                      removeHeaders={["ChargeId"]}
                      tableClass="h-52"
                    />
                  </div>
                </fieldset>
              </div>

              {/* ///// uploadedFiles Details*/}
              {patchData && patchData?.uploadedFiles.length > 0 ? (
                <div className=" border border-gray-300 px-3 rounded-md mt-1">
                  <div className="text-blue-900 text-base font-bold px-2 py-1">
                    Uploaded Documents
                  </div>
                  <fieldset className="">
                    <div className="py-1">
                      <div className="w-full mt-2">
                        <CommonDynamicTableNew
                          dataResult={patchData && patchData?.uploadedFiles}
                          removeHeaders={["Docfile", "id", "Doc", "docId"]}
                          renderInput={renderInputDocView}
                          editableColumns={["FilePath", "DocName"]}
                          tableClass="rounded h-36"
                          highlightRow={false}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              ) : null}

              <div className="flex gap-3 justify-end mt-3">
                <div>
                  <CommonButton
                    label="Cancel"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* Documents view modal */}
      {openDocumentsViewModal ? (
        <DocumentsViewModal
          open={openDocumentsViewModal}
          setOpen={setOpenDocumentsViewModal}
          handleOpen={handleOpenDocumentsViewModal}
          handleClose={handleCloseDocumentsViewModal}
          pathForDocView={pathForDocView}
        />
      ) : null}
    </div>
  );
}
