import { Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
// importing the CommonMasterTable ; which is common to all
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
//fetching data into ipdBillService.js
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import BillInformtionModal from "./BillInformtionModal";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { opdSelfSettlementPdfPrint } from "../../../../billing/services/BillSettlementServices";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchSearchDropDown } from "../../../../commonServices/patientInfoServices/PatientInfoServices";
import {
  getListOfIPDCreditBills,
  getListOfIPDSelfPaymentSettlement,
  getSelftSettlementInformationById,
  patientAdvanceBalance,
  saveSelfBillSettlement,
} from "../../../services/billSettlement/IPDSelfBillSettlementServices";
const actions = ["cancel", "casepaper"];

export default function PaymentSettlement() {
  const schema = yup.object().shape({});
  const defaultValues = { SelfbillsttlementSearch: "" };
  const {
    register,
    watch,
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [selectedPatient, setSelectedPatient] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [listOfPaymentData, setListOfPaymentData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const [selfBillSettlement, setSelfBillSettlement] = React.useState();
  const [balanceAmount, setBalanceAmount] = React.useState(0);
  const [
    openSettlementPrintModal,
    setOpenSettlementPrintModal,
  ] = React.useState(false);
  const [vistiPdf, setVisitPdf] = React.useState("");
  const [advanceBalance, setAdvanceBalance] = React.useState(0);
  const [isAdvanceConsumeFlag, setIsAdvanceConsumeFlag] = React.useState();
  const [advanceConsumeAmount, setAdvanceConsumeAmount] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState();
  const [userActions, setUserActions] = React.useState([]);
  const [menuIdVal, setMenuIdVal] = React.useState(null);
  // get menu id
  let location = useLocation();
  
  let patientWatch = watch("SelfbillsttlementSearch");
  

  // useEffect
  useEffect(() => {
    fetchListOfCreditBill();
    fetchListOfPayment();
  }, []);

  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          
          setUserActions(res.result);
        });
  }, []);
  //functions
  const handleOpenChild = () => setOpen(true);
  const handleCloseChild = () => setOpen(false);

  const handleChange = (searchString) => {
    let opdIpd = 1;
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString)) {
      fetchSearchDropDown(searchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          if (res !== null) {
            
            setOptions(res.result);
          } else {
            setOptions([]);
          }
        });
    }
  };

  const getSelfSettlementInfo = (selectedPatient) => {
    let opdIpd = 1;
    getSelftSettlementInformationById(selectedPatient.id, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        fetchListOfCreditBill(res.result.PatientId);
        
        fetchListOfPayment(res.result.PatientId);
        
        setSelectedPatient(res.result);
      });
  };
  function fetchListOfCreditBill(PatientId) {
    let creditBillObj = {
      opdIpd: 1,
      patientId: PatientId,
    };
    getPatientAdvance(PatientId);
    getListOfIPDCreditBills(creditBillObj)
      .then((response) => response.data)
      .then((res) => {
        
        setData(res.result);
      });
  }

  function getPatientAdvance(PatientId) {
    
    patientAdvanceBalance(PatientId)
      .then((response) => response.data)
      .then((res) => {
        
        setAdvanceBalance(res.result.PatientAdvance);
      });
  }

  function fetchListOfPayment(PatientId) {
    
    let listOfPyamentObj = {
      opdIpd: 1,
      patientId: PatientId,
    };

    getListOfIPDSelfPaymentSettlement(listOfPyamentObj)
      .then((response) => response.data)
      .then((res) => {
        setListOfPaymentData(res.result);
      });
  }

  function displayRow(row) {
    
    setRowData(row);
    setBalanceAmount(row.BalanceAmount);
  }

  function submitPaymentInfoModal(paymentInfoList) {
    
  
    
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);

    var refarr = [];
    var lastTotal;
    for (let i = 0; i < paymentInfoList.length; i++) {
      let a = paymentInfoList[i].amount;
      refarr.push(a);
      
      lastTotal = refarr[refarr.length - 1];
      
    }

    let postObj = {
      addedBy: userInfoObj.userId,

      balanceBill: selectedRow && selectedRow.BalanceAmount,
      billId: selectedRow && selectedRow.BillId,
      cashCounterId: userInfoObj.cashCounterId,
      isCash: false,
      opdIpd: 1,
      opdIpdExternalId: selectedRow && selectedRow.Opd_Ipd_External_Id,
      menuId: location?.state?.menuId,
      privilege: menuIdVal?.action,
      paidAmount: selectedRow && selectedRow.PaidAmount,
      totalBill: selectedRow && selectedRow.TotalBillAmount,
      totalConcessionAmount: selectedRow && selectedRow.TotalConcessionAmount,
      consumeAdvance: advanceConsumeAmount,
      isAdvanceConsume: isAdvanceConsumeFlag,
      patientId: patientWatch?.id,
    };

    postObj.totalAmt = 0;
    postObj.paymentInfoList = paymentInfoList;
    setOpenBackdrop(true);
    saveSelfBillSettlement(postObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        fetchListOfPayment();
        fetchListOfCreditBill();
        setSelectedPatient(null);
        setOptions([]);
        setAdvanceConsumeAmount(0);
        setIsAdvanceConsumeFlag();
        setSelfBillSettlement(res.result);
        handleOpenPrintModal(res.result);
        setOpenBackdrop(false);
        setAdvanceBalance(0);
        handleCloseChild();
        reset(defaultValues);
        paymentInfoList = [];
      })
      .catch(() => {
        errorAlert(errors.message);
        setOpenBackdrop(false);
      });
  }

  //prints print
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (paymentId) => {
    // admissionId,formId
    opdSelfSettlementPdfPrint(paymentId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  // table action and button visibility mgmt
  // pervious bill actions
  const renderActions = (row, index) => {
    

    return (
      <div className="flex gap-3">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-2 cursor-pointer">
                    {actions.action === "Print" ? (
                      <Tooltip title={"Print"}>
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            displayRow(row);
                            handleOpenPrintModal(row?.PaymentId);
                            setOpenSettlementPrintModal(true);
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  return (
    <>
      <div className="text center mt-10">
        <div className="text-center text-black font-bold text-xl py-2 ">
          Self Bill Settlement
        </div>
      </div>
      <div className="w-full grid  px-2 md:rounded-md  ">
        {/* SearchBar */}
        <div className=" flex items-center w-full md:gap-2 lg:gap-5  ">
          {/*searchable dropdown */}
          <div className="flex lg:w-2/5 md:w-full gap-2 py-1">
            <SearchDropdown
              control={control}
              searchIcon={true}
              className="z-80"
              name="SelfbillsttlementSearch"
              placeholder="Search By Patient Name / MR No./ Mobile No"
              dataArray={options}
              isSearchable={true}
              isClearable={true}
              handleInputChange={handleChange}
              inputRef={{
                ...register("SelfbillsttlementSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                    
                      getSelfSettlementInfo(e.target.value);
                    } else {
                      setSelectedPatient("");
                      setOptions([]);
                    }
                  },
                }),
              }}
            />

            {/* <Button
              className=" h-9 w-9 px-2 rounded-md text-gray-500"
              type="button"
              variant="outlined"
              size="small"
              sx={{ borderColor: "grey.500", color: "gray" }}
            >
              <SearchIconButton className="cursor-pointer" />
            </Button> */}
          </div>
        </div>

        {selectedPatient && selectedPatient !== null ? (
          <>
            <fieldset className=" mx-auto border w-full rounded bg-gray-100">
              {/* <legend className=" rounded">
                <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Patient Information
                </p>
              </legend> */}
              <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 font-semibold">Patient Name</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.patientName}
                  </span>
                </div>
                <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                    MR No.
                  </span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.mrNo}
                  </span>
                </div>

                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 xl:w-8 font-semibold">Age</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.age}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                  <span className="w-24 lg:w-20 font-semibold">Mobile No</span>

                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.MobileNo}
                  </span>
                </div>
              </div>
            </fieldset>
            {/* List Of Payment Table */}
            <div className=" w-full overflow-hidden">
              <div className="flex justify-between">
                <p className="font-bold text-sm text-gray-700">
                  List Of Payment
                </p>
              </div>

              {listOfPaymentData && listOfPaymentData.length > 0 ? (
                <>
                  <CommonDynamicTable
                    data={listOfPaymentData}
                    actions={actions}
                    actionsRequired={true}
                    renderActions={renderActions}
                    setSelectedRow={setSelectedRow}
                    highlightRow={false}
                    tableClass={"rounded lg:h-52 md:h-72"}
                    removeHeaders={["Opd_Ipd_External_Id","PaymentId","BillId"]}

                  />
                </>
              ) : (
                <>
                  <div className="text-center mt-4 p-4">No Record Found</div>
                </>
              )}
            </div>

            {/* List Of Credit Bill Table */}
            <div className=" w-full overflow-hidden">
              <p className="font-bold text-sm py-2 text-gray-700">
                List Of Credit Bills
              </p>

              {data && data.length > 0 ? (
                <>
                  <div className="px-0">
                    <CommonDynamicTable
                      data={data}
                      setData={setData}
                      actionsRequired={false}
                      setSelectedRow={setSelectedRow}
                      tableClass={"rounded lg:h-52 md:h-72"}
                      removeHeaders={["Opd_Ipd_External_Id","BillId","IsSettled","CNAmount","DBAmount"]}

                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center mt-4 p-4">No Record Found</div>
                </>
              )}
            </div>
            <div className="flex justify-end py-2 gap-3">
              {userActions &&
                userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "SettleBill" ? (
                      <CommonButton
                        className="bg-blue-900  text-white"
                        label="Settle Bill"
                        onClick={() => {
                          if (
                            options.length > 0 &&
                            selectedRow &&
                            selectedRow.BalanceAmount > 0
                          ) {
                            handleOpenChild();
                            setMenuIdVal(obj);
                          } else {
                            errorAlert("Please Select Credit Bill");
                          }
                        }}
                      />
                    ) : null}
                  </>
                ))}
            </div>
          </>
        ) : (
          ""
        )}
        {/* ServiceTax Ok Cancel Btn */}
      </div>
      <BillInformtionModal
        open={open}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        setOpen={setOpen}
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
        advanceAmount={advanceBalance}
        setAdvanceBalance={setAdvanceBalance}
        handleClose={handleCloseChild}
        getValues={getValues}
        refundAmount={selectedRow?.BalanceAmount}
        setIsAdvanceConsumeFlag={setIsAdvanceConsumeFlag}
        handleCloseChild={handleCloseChild}
        submitPaymentInfoModal={submitPaymentInfoModal}
        setAdvanceConsumeAmount={setAdvanceConsumeAmount}
        advanceConsumeAmount={advanceConsumeAmount}
        setOptions={setOptions}
      />

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}
