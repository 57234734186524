import React from "react";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NotificationToast.css";
import { CloseOutlined } from "@mui/icons-material";
const loggedUser = JSON.parse(localStorage.getItem("userInfo"));

const CloseButton = ({ closeToast }) => (
  <i
    className="material-icons rounded-lg pr-1 h-9 w-9 bg-[#dec2ed]"
    onClick={closeToast}
  >
    <CloseOutlined fontSize="small" sx={{ color: "red", padding: 0 }} />
  </i>
);

const NotificationToast = (message) => {
  toast.info(message, {
    autoClose: false,
    position: "bottom-right",
    icon: false,
    closeButton: CloseButton,
    transition: Zoom,
    className: "toastContainer",
    bodyClassName: "toastBody",
    toastClassName: "toastClass",
    style: { backgroundColor: "#dec2ed" },
  });
};

export { NotificationToast };
