import React, { useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { IncidentReportingData } from "../services/incidentReportingServices/IncidentReportingServices";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { useEffect } from "react";

export default function IncidentReportingPrint(props) {
  const printRef = useRef(null);

  const [data, setData] = React.useState();

  React.useEffect(() => {
    IncidentReportingData(props.incidentId)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
  }, []);

  useEffect(() => {
    data && printRef && GeneratePrint(printRef);
  }, [data]);

  let getCommunicationError = data?.CommunicationError;
  let getCommunication;
  if (getCommunicationError === 0) {
    getCommunication = false;
  }
  if (getCommunicationError !== 0) {
    getCommunication = true;
  }

  let getAdministrationError = data?.AdministrationError;
  let getAdministration;
  if (getAdministrationError === 0) {
    getAdministration = false;
  }
  if (getAdministrationError !== 0) {
    getAdministration = true;
  }

  let getBillingError = data?.BillingError;
  let getBilling;
  if (getBillingError === 0) {
    getBilling = false;
  }
  if (getBillingError !== 0) {
    getBilling = true;
  }

  let getHRError = data?.HRError;
  let getHR;
  if (getHRError === 0) {
    getHR = false;
  }
  if (getHRError !== 0) {
    getHR = true;
  }

  let getHKError = data?.HKError;
  let getHK;
  if (getHKError === 0) {
    getHK = false;
  }
  if (getHKError !== 0) {
    getHK = true;
  }

  let getMedicationError = data?.MedicationError;
  let getMedication;
  if (getMedicationError === 0) {
    getMedication = false;
  }
  if (getMedicationError !== 0) {
    getMedication = true;
  }

  let getPatientCareError = data?.PatientCareError;
  let getPatientCare;
  if (getPatientCareError === 0) {
    getPatientCare = false;
  }
  if (getPatientCareError !== 0) {
    getPatientCare = true;
  }

  let getDocumentationError = data?.DocumentationError;
  let Documentation;
  if (getDocumentationError === 0) {
    Documentation = false;
  }
  if (getDocumentationError !== 0) {
    Documentation = true;
  }

  let getProcessError = data?.ProcessError;
  let ProcessError;
  if (getProcessError === 0) {
    ProcessError = false;
  }
  if (getProcessError !== 0) {
    ProcessError = true;
  }

  let getOtherErrors = data?.OtherErrors;
  let OtherErrors;
  if (getOtherErrors === 0) {
    OtherErrors = false;
  }
  if (getOtherErrors !== 0) {
    OtherErrors = true;
  }

  return (
    <>
      <div ref={printRef} style={{ width: "210mm" }}>
        <div className=" my-2 flex justify-between border-b-[1px] border-black">
          <div></div>
          <div className=" text-center">
            <h1 className=" mt-3">INCIDENT REPORTING FORM</h1>
            <h1 className=" text-[11px] font-normal">
              (Please maintain confidentiality of the document)
            </h1>
          </div>
          <div>
            <InamdarHospiLogo />
          </div>
        </div>
        <div className=" border-[1px] border-black p-1 flex justify-between">
          <div className=" space-x-3">
            <span className=" text-[12px] font-semibold">Form NO</span>
            <span className=" text-[12px] font-semibold">:</span>
            <span className=" text-[12px]">{data?.FormNo}</span>
          </div>
          <div className=" space-x-3">
            <span className=" text-[12px] font-semibold">Printed On</span>
            <span className=" text-[12px] font-semibold">:</span>
            <span className=" text-[12px]">{data?.PrintedOn}</span>
          </div>
        </div>
        <div className=" grid grid-cols-[25%_5%_70%] space-y-3">
          <div className=" font-semibold text-[12px] mt-3">
            Who was harmed or nearly harmed
          </div>
          <div className=" font-semibold text-[12px]">:</div>
          <div className=" text-[12px]">{data?.harmedName}</div>
          <div className=" font-semibold text-[12px]">Reporting Employee</div>
          <div className=" font-semibold text-[12px]">:</div>
          <div className=" text-[12px]">{data?.ReportingName}</div>
          <div className=" font-semibold text-[12px]">Employee Department</div>
          <div className=" font-semibold text-[12px]">:</div>
          <div className=" text-[12px]">{data?.EmployeeDepartment}</div>
          <div className=" font-semibold text-[12px]">Place Of Occurance</div>
          <div className=" font-semibold text-[12px]">:</div>
          <div className=" text-[12px]">{data?.PlaceOfOccurence}</div>
          <div className=" font-semibold text-[12px]">Date Of Incident </div>
          <div className=" font-semibold text-[12px]">:</div>
          <div className=" text-[12px]">{data?.DateOfIncident}</div>
        </div>
        <h1 className=" font-semibold my-3 border-b-[2px] text-[15px] border-black w-[100px] border-r-[2px] shadow-xl">
          Event Details
        </h1>
        <p className=" text-[12px] my-2">{data?.EventDetails}</p>
        <div>
          <h1 className=" font-semibold my-3 border-b-[2px] text-[15px] border-black w-32 border-r-[2px] shadow-xl">
            Type Of Incident
          </h1>
          <div className=" grid grid-cols-[33.4%_2%_18%_25%_2%_19%]">
            <div className=" font-semibold text-[12px]">
              Communication Error
            </div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getCommunication} />
            </div>
            <div className=" font-semibold text-[12px]">Medication Error</div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getMedication} />
            </div>
            <div className=" font-semibold text-[12px]">
              Administration Related Error
            </div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getAdministration} />
            </div>
            <div className=" font-semibold text-[12px]">Patient Care Error</div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getPatientCare} />
            </div>
            <div className=" font-semibold text-[12px]">
              Billing Related Error
            </div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getBilling} />
            </div>
            <div className=" font-semibold text-[12px]">
              Documentation Error
            </div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={Documentation} />
            </div>
            <div className=" font-semibold text-[12px]">HR related Error</div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getHR} />
            </div>
            <div className=" font-semibold text-[12px]">Process Error</div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={ProcessError} />
            </div>
            <div className=" font-semibold text-[12px]">HK Related Error</div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={getHK} />
            </div>
            <div className=" font-semibold text-[12px]">Other Errors</div>
            <div className=" text-[12px] font-semibold">:</div>
            <div>
              <input type="checkbox" checked={OtherErrors} />
            </div>
          </div>
          <h1 className=" font-semibold my-3 border-b-[2px] text-[15px] border-black w-36 border-r-[2px] shadow-xl">
            Remark By Quality
          </h1>
          <p className=" text-[12px] my-2">{data?.Remark}</p>
        </div>
      </div>
    </>
  );
}
