import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../common/components/CommonTable/CommonDynamicTable";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  width: "60%",

  border: "1px solid gray",
};
const PerviousBillsModal = (props) => {
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variables

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4 p-2">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
                props.setEdit(false);
                reset(defaultValues);
              }}
            />
          </div>
          <div className="row">
            <fieldset className=" text-left lg:mx-auto lg:px-2 md:ml-0 md:mr-0 py-2 rounded lg:mt-6 lg:m-2 ">
              <legend className="md:mx-2 md:px-2 font-bold text-gray-700">
                Previous Bills List
              </legend>
              {props?.pervioutBillList?.result?.length > 0 ? (
                <CommonDynamicTable
                  data={props?.pervioutBillList?.result}
                  setData={props.setData}
                  actionsRequired={props.actionsRequired}
                  dataResult={props.dataResult}
                  setDataResult={props.setDataResult}
                  populateTable={props.populateTable}
                  tableApiFunc={props.fetchPerviousBillsList}
                  defaultParams={props.defaultParams}
                  page={props.page}
                  setPage={props.setPage}
                  rowsPerPage={props.rowsPerPage}
                  setRowsPerPage={props.setRowsPerPage}
                  count={props.count}
                  //Function to Show lineItem
                  renderActions={props.renderActions}
                  setSelectedRow={props.setSelectedRow}
                  tableClass={"rounded lg:h-52 md:h-72"}
                />
              ) : (
                <div className="text-center py-10">
                  <span>No Record Found...</span>
                </div>
              )}
            </fieldset>
            <CommonBackDrop openBackdrop={props.openBackdrop} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PerviousBillsModal;
