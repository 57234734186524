import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import { fetchStoreDropdown } from "../../../services/commonServices/CommonServices";
import { fetchItemsByItemId } from "../../../services/commonServices/issueToDept/IssueToDept";
import {
  fetchIndentItems,
  fetchIndentList,
} from "../../../services/pharmacy/issueToDeptServices/IssueToDeptServices";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "70%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const Actions = [
  {
    id: 0,
    action: "checkbox",
    isAction: true,
  },

  {
    id: 1,
    action: "select",
    isAction: false,
  },
];

export default function GetIndentModal(props) {
  var token = JSON.parse(localStorage.getItem("userInfo"));
  const [showSpinner, setShowSpinner] = useState(false);

  const [searchString, setSearchString] = React.useState("");

  //tbl-1
  const [indentList, setIndentList] = React.useState([]);
  const [selectedID, setSelectedID] = useState(null);

  //tbl-2
  const [indentListItems, setIndentListItems] = React.useState([]);
  // //selected from get indent table-2

  //from Store
  const [fromStore, setFromStore] = useState();
  //from selected FromStore id
  const [selectedFromStore, setSelectedFromStore] = useState();

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [fromDateVal, setFromDateVal] = useState(null);
  const [toDateVal, setToDateVal] = useState(null);
  ///
  //
  const {
    open,
    handleClose,
    //
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      isUrgentRadio: 2,
      // fromDate: new Date(),
      // toDate: new Date(),
    },
  });
  const { control, register, watch } = methods;
  ///watch
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  useEffect(() => {
    getStore();
  }, []);

  // storename dropdown
  const getStore = () => {
    // fetchStoreDropdown()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setFromStore(res.result);
    //   });

    fetchApplicableStores(token?.storeId)
      .then((response) => response.data)
      .then((res) => {
        setFromStore(res.result);
      });
  };

  // patientId

  //add selected items in item details table
  const AddSelectedIndentItems = () => {
    const newArr = indentListItems.filter((item) => item.isChecked);

    //arr.concat(indentListItems);

    if (newArr.length > 0) {
      for (let i = 0; i < newArr.length; i++) {
        newArr[i].IndentQty = Number(newArr[i].ReqdQty);
        newArr[i].Quantity = Number(newArr[i].ReqdQty);
      }
    }

    // props.setSelectedDrugData(newArr);
    handleClose();

    let temp = [...newArr];
    let itemIds = [];
    for (let i = 0; i < temp.length; i++) {
      let ItemId = temp[i].ItemId;
      itemIds.push(ItemId);
    }

    let idString = itemIds.toString();
    fetchItemsByItemId(idString, token.storeId)
      .then((response) => response.data)
      .then((res) => {
        let updatedResult = res.result.map((item) => ({
          ...item,
          IndentQty: temp.find((item1) => item.Id === item1.ItemId).ReqdQty,
          PendingIssueQty: temp.find((item1) => item.Id === item1.ItemId)
            .PendIssueQty,
          IndentId: temp.find((item1) => item.Id === item1.ItemId).IndentId,
          IndentDetailId: temp.find((item1) => item.Id === item1.ItemId)
            .IndentDetailId,
          BatchNumber: "",
          Quantity: temp.find((item1) => item.Id === item1.ItemId).PendIssueQty,
        }));

        props.setSelectedDrugData(updatedResult);
      });

    handleClose();
  };

  useEffect(() => {
    isAfter(fromDateVal || new Date(), new Date(1900, 1, 1)) &&
      isAfter(toDateVal || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [selectedFromStore, searchString, fromDateVal, toDateVal]);

  const populateTable = (forPagination) => {
    setShowSpinner(true);

    const indentObj = {
      fromDate: format(
        fromDateVal || new Date(),
        "yyyy-MM-dd'T'00:00:00.000'Z'"
      ),
      toDate: format(toDateVal || new Date(), "yyyy-MM-dd'T'00:00:00.000'Z'"),
      fromStoreId: selectedFromStore,
      isApproved: 1,
      toStoreId: token.storeId,
      //
      page: !forPagination ? 0 : page,
      size: rowsPerPage,

      searchString: searchString,
    };

    fetchIndentList(indentObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIndentList((prevData) => [...prevData, ...res.result]);
          setSelectedID(null); ///used to unselect previousl selected checkbox
          setIndentListItems([]);
        } else {
          setIndentList(res.result);
          setPage(0);
          setSelectedID(null);
          setIndentListItems([]);
        }
        // setUserActions(res.actions);
        setCount(res.count);
        setShowSpinner(false);
      })
      .catch((res) => {
        setShowSpinner(false);
      });
  };

  const renderActions = (row, index) => {

    const getIndentList = (row) => {
      if (row !== null) {
        fetchIndentItems(row.IndentId)
          .then((response) => response.data)
          .then((res) => {
            let updatedResult = res.result.map((item) => ({
              ...item,
              isChecked: true,
            }));
            setIndentListItems(updatedResult);
          })
          .catch((res) => {
            setShowSpinner(false);
          });
      }
    };
    return (
      <div className="flex items-center">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "checkbox" ? (
                    <Checkbox
                      size="small"
                      className="border  border-gray-600 rounded bg-transparent"
                      type="checkbox"
                      color="primary"
                      checked={selectedID === index ? true : false}
                      onClick={(e) => {
                        setSelectedID(index);
                        getIndentList(row);
                        props.setSelectedIndentTbleRow(row);

                      }}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
              props.setSelectedIndentTbleRow(null);
            }}
          />

          <div className=" flex flex-wrap p-2 rounded text-lg  text-black font-semibold">
            Get Indent
          </div>
          {/* search */}
          <div className="grid gap-2 w-full items-center my-2">
            <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center">
              <div className="w-full col-span-3 lg:col-span-4">
                <SearchBar
                  type="button"
                  name="SearchableSelect"
                  placeholder="Search by Indent Number."
                  // dataArray={options}
                  handleInputChange={(e) => setSearchString(e)} //searchString
                  // onChange={autoSelectedValue} // serachId
                />
              </div>
              <div className="grid grid-cols-2 col-span-3 gap-3">
                {/* from Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("fromDate", {
                        onChange: (e) => {
                          console.log(e);
                          setFromDateVal(e?.target?.value);
                        },
                      }),
                    }}
                  />
                </div>

                {/* to Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("toDate", {
                        onChange: (e) => {
                          console.log(e);
                          setToDateVal(e?.target?.value);
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="md:col-span-2 lg:col-span-3 grid grid-cols-2 gap-3">
                <DropdownField
                  control={control}
                  name="fromStore"
                  dataArray={fromStore}
                  placeholder="From Store"
                  isSearchable={false}
                  isClearable={false}
                  inputRef={{
                    ...register("fromStore", {
                      onChange: (e) => {
                        setSelectedFromStore(e.target.value.id);
                      },
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-2">
              <p className="font-semibold pl-3">Indent Table</p>

              {showSpinner ? (
                <div className="flex justify-center mt-20">
                  <LoadingSpinner />
                </div>
              ) : indentList?.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={indentList}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  renderActions={renderActions}
                  tableClass={"rounded lg:h-96 md:h-72"}
                  // highlightRow={false}
                  removeHeaders={[
                    "AdmNo",
                    "AdmissionId",
                    "Cash_Credit",
                    "DrId",
                    "FromStoreId",
                    "IndentId",
                    "PatientId",
                    "isprinted",
                    "VATPercentage",
                  ]}
                />
              ) : (
                <p className="text-center my-6 ">No Indent Found</p>
              )}
            </div>
            <div className="col-span-2">
              <p className="font-semibold">Items Table</p>

              {indentListItems?.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={indentListItems}
                  //renderInput={renderInput}
                  highlightRow={false}
                  enableSelectAll={true}
                  removeHeaders={[""]}
                  tableClass={"rounded lg:h-96 md:h-72"}
                  setDataResult={setIndentListItems}
                />
              ) : (
                <p className="text-center my-6 ">No Items Found</p>
              )}
            </div>
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
            <div></div>
            <div>
              {Actions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "select" ? (
                    <div>
                      {/* <button
                        type="submit"
                        className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium"
                        onClick={AddSelectedIndentItems}
                      >
                        Select
                      </button> */}
                      <CommonButton
                        label="Select"
                        onClick={AddSelectedIndentItems}
                        className="bg-customGreen text-white"
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </div>
            <div>
              {/* <button
                type="submit"
                className="h-9 px-3 text-base font-medium  bg-red-700 text-white rounded "
                onClick={() => handleClose()}
              >
                Close
              </button> */}
              <CommonButton
                label="Close"
                onClick={() => {
                  handleClose();
                  props.setSelectedIndentTbleRow(null);
                }}
                className="border border-customRed text-customRed"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
