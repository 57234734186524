import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../common/components/FormFields/InputField";
import InputArea from "../../../common/components/FormFields/InputArea";

const TestView = () => {
  const { control, register, setValue } = useFormContext();
  return (
    <div>
      <div className=" grid grid-cols-2 gap-3">
        {/* local Examination */}

        <div className="">
          <InputArea
            control={control}
            name="pendingInvestigations"
            label="Pending Investigations"
            placeholder="Pending Investigations"
          />
        </div>

        {/* injuries Details */}
        <div className=" ">
          <InputArea
            control={control}
            name="labSentFromCasualty"
            label="Lab Sent From Casualty"
            placeholder="Lab Sent From Casualty"
          />
        </div>

        <div className="col-span-1 ">
          <InputField
            name="transferTo"
            variant="outlined"
            label="Transfer To"
            // error={errors.burnPercentage}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        <div className="col-span-1 ">
          <InputField
            name="doctorName"
            variant="outlined"
            label="Doctor Name"
            // error={errors.burnPercentage}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        <div className="col-span-1 ">
          <InputField
            name="referance"
            variant="outlined"
            label="Referance"
            // error={errors.burnPercentage}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-6  gap-3 mt-3">
        <div className="lg:col-span-2 grid md:grid-cols-2 lg:grid-cols-3">
          <div
            className="lg:col-span-2 "
            onClick={(e) => {
              if (e.target.checked) {
                setValue("notInformedToConsultant", false);
              }
            }}
          >
            <CheckBoxField
              control={control}
              name="informedToConsultant"
              label="Informed To Consultant"
            />
          </div>
          <div>
            <InputField
              name="informedToConsultantDtls"
              variant="outlined"
              // label="Referance"
              // error={errors.burnPercentage}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
        <div className="lg:col-span-2 grid md:grid-cols-2 lg:grid-cols-3">
          <div
            className="lg:col-span-2"
            onClick={(e) => {
              if (e.target.checked) {
                setValue("informedToConsultant", false);
              }
            }}
          >
            <CheckBoxField
              control={control}
              name="notInformedToConsultant"
              label="Not Informed To Consultant"
            />
          </div>
          <div>
            <InputField
              name="notInformedToConsultantDtls"
              variant="outlined"
              // label="Referance"
              // error={errors.burnPercentage}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
        <div className="lg:col-span-2 grid md:grid-cols-2 lg:grid-cols-3">
          <div className="col-span-2">
            <CheckBoxField
              control={control}
              name="otBooked"
              label="OT Booked"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestView;
