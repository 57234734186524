import { yupResolver } from "@hookform/resolvers/yup";
import { format, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  saveCodeForm,
  saveCodeOrangeForm,
  saveCodeYellowForm,
} from "../../services/codeFormsServices/CodeFormsServices";
import CodeFormsListing from "./CodeFormsListing";
import OrangeForm from "./OrangeForm";
import OtherForms from "./OtherForms";
import YellowForm from "./YellowForm";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { useLocation } from "react-router-dom";
//import CodeFormsListingList from "./CodeFormsListingList";

const codeTypeArr = [
  {
    id: 0,
    label: "Red",
    value: "Red",
  },

  { id: 1, label: "Violet", value: "Violet" },

  { id: 2, label: "Pink", value: "Pink" },

  { id: 3, label: "Brown", value: "Brown" },

  { id: 4, label: "Yellow", value: "Yellow" },

  { id: 5, label: "Orange", value: "Orange" },

  { id: 6, label: "Black", value: "Black" },

  //{ id: 7, label: "Blue", value: "Blue" },
];

const validationSchema = yup.object().shape({
  codeType: yup.object().shape({
    value: yup.string().required("Required"),
    label: yup.string(),
  }),

  strokeWithinWindowPeriod: yup.boolean(),

  windowTime: yup.string().when(["codeType", "strokeWithinWindowPeriod"], {
    is: ({ codeType, strokeWithinWindowPeriod }) =>
      codeType && codeType.value === "Orange" && !!strokeWithinWindowPeriod,
    then: yup
      .string()
      .required("Required")
      .matches(
        /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, // Regular expression for HH:mm format
        "Invalid time format (HH:mm)"
      ),
    otherwise: yup.string(),
  }),
});

const CodeForms = () => {
  let location = useLocation();

  const token = JSON.parse(localStorage.getItem("userInfo"));
  //
  const [selectedRow, setSelectedRow] = useState(null);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  ///
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);

  //

  const [openCodeFormsListingModal, setOpenCodeFormsListingModal] = useState(
    false
  );
  const handleOpenCodeFormsListingModal = () =>
    setOpenCodeFormsListingModal(true);
  const handleCloseCodeFormsListingModal = () =>
    setOpenCodeFormsListingModal(false);

  ///

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      codeType: null,
      //
      outcomeOfCode: 0,
      ifUnresolved: 0,
      cprGiven: 0,
      currentLocation: 1,
      outcomeOrange: 0,
      patientType: 1,
      //
      lifeSupport: 0,
      radiologyMriCt: 0,
      yellowCodeTeamArrivalTime: new Date(),
      codeTimeYellow: new Date(),
      codeDateYellow: new Date(),
      cprGivenCount: "",
      //
      codeDateOrange: new Date(),
      codeTimeOrange: new Date(),
      codeDateYellow: new Date(),
      //
      codeDate: new Date(),
      codeTime: new Date(),
      timeOfArrival: new Date(),
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
  } = methods;

  const codeType_selected = watch("codeType");

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };
  const handleFormSubmit = (data) => {
    let fianlObjOtherCode = {};
    let fianlObjYellowCode = {};
    let finalObjOrangeCode = {};

    let TimeOrangeValid = isValid(data.codeTimeOrange);

    if (data.codeType.id === Number(5)) {
      finalObjOrangeCode = {
        advLife: data.lifeSupport === Number(1) ? 1 : 0,
        basicLife: data.lifeSupport === Number(0) ? 1 : 0,
        callerName: data.patientMRNO || "",
        codeDifficulties: data.anyDifficultiesToCO || "",
        codeOrangeId: 0,
        codeOutCome: Number(data.outcomeOrange) || 0,
        codeTeam: data.codeOrangeTeam || "",
        creationDate: format(
          data.codeDateOrange,
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        ),
        creationTime: TimeOrangeValid
          ? format(data.codeTimeOrange, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
          : data.codeTimeOrange
          ? `${data.codeTimeOrange}Z`
          : null,
        currentLocation: data.currentLocation || 0,
        date: format(data.codeDateOrange, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
        diagnosis: data.diagnosis,
        isMockDrill: data.mockDrillOrange || false,
        isStrockTime: Number(data.strokeWithinWindowPeriod) || 0,
        mriCt: data.radiologyMriCt || 0,
        operatorName: data.patientName || "",
        stortSummery: data.shortSummaryOrange,
        strockTime: format(
          data?.strokeTime || new Date(),
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        ),
        time: TimeOrangeValid
          ? format(data.codeTimeOrange, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
          : data.codeTimeOrange
          ? `${data.codeTimeOrange}Z`
          : null,

        menuId: location?.state?.menuId,
        privilege: privilege,
      };

      saveCodeOrangeForm(finalObjOrangeCode)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            setOpenConfirmationModal(false);
            methods.reset();
          }
        })
        .catch((res) => {
          errorAlert(res.message);
          setOpenConfirmationModal(false);
          setOpenBackdrop(false);
        });
    } else if (data.codeType.id === Number(4)) {
      fianlObjYellowCode = {
        addedBy: 0,
        addedDateTime: format(
          data.codeDateYellow,
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        ),
        adult: data.patientType === Number(1) ? 1 : 0,
        advancedLifeSupport: data.lifeSupport === Number(1) ? 1 : 0,
        anyDifficulties: data.anyDifficultiesToCY || "",
        basicLifeSupport: data.lifeSupport === Number(0) ? 1 : 0,
        callerLocation: data.callerLocation || "",
        callerName: data.callerName || "",
        codeNo: "",
        codeTeamArrivalTime: format(
          data?.yellowCodeTeamArrivalTime || new Date(),
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        ),
        codeYellowId: 0,
        cprPTCount: Number(data.cprGivenCount) || 0,
        date: format(data.codeDateYellow, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
        greenBandPTCount: Number(data.greenBandsDtls) || 0,
        isCPRGiven: Number(data.cprGiven) || 0,
        isGreenBand: Number(data.greenBands) || 0,
        isOrangeBand: Number(data.orangeBands) || 0,
        isRedBand: Number(data.redBands) || 0,
        isYellowBand: Number(data.yellowBands) || 0,
        massCasualtyNoOfPT: Number(data.massCasNoOfPts) || 0,
        medicalEmergency: Number(data.medicalEmergency) || 0,
        nameOfOperator: data.operatorName || "",
        noOfPTAdmitted: Number(data.ptAdmitted) || 0,
        noOfPTExpired: Number(data.ptExpired) || 0,
        noOfPTShefiedToICU: Number(data.ptShiftedToIcu) || 0,
        noOfPTShefiedToWard: Number(data.ptStabilizedInWard) || 0,
        noOfPTTransferToOtherHospital:
          Number(data.ptTransferedToOtherHospi) || 0,
        noOfPTTreatedOnOPD: Number(data.ptTreatedInOpd) || 0,
        orangeBandPTCount: Number(data.orangeBandsDtls) || 0,
        overallComments: data.yellowOverallComments || "",
        pediatric: data.patientType === Number(0) ? 1 : 0,
        redBandPTCount: Number(data.redBandsDtls) || 0,
        shortSummary: data.yellowShortSummary || "",
        time: new Date(),
        updatedBy: 0,
        updatedDateTime: new Date(),
        yellowBandPTCount: Number(data.yellowBandsDtls) || 0,
        menuId: location?.state?.menuId,
        privilege: privilege,
      };
      saveCodeYellowForm(fianlObjYellowCode)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            setOpenConfirmationModal(false);
            methods.reset();
          }
        })
        .catch((res) => {
          errorAlert(res.message);
          setOpenConfirmationModal(false);
          setOpenBackdrop(false);
        });
    } else {
      fianlObjOtherCode = {
        area: data.area || "",
        briefNarrative: data.briefNarative || "",
        cause: data.cause || "",
        codeFormId: 0,
        codeType: 0,
        date: format(data.codeDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
        escalatedDetails: data.escalatedToAdministartionDetails || "",
        escalatedToAdmin: Number(data.ifUnresolved),
        floor: data.floor || "",
        isMockDrill: data.mockDrillOther || false,
        outcomeOfCode: Number(data.outcomeOfCode),
        room: data.room || "",
        teamLeader: data.teamLead || "",
        teamMembers: data.respondingTeamMember || "",
        time: format(
          data?.codeTime || new Date(),
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        ),
        timeOfArrival: format(
          data?.timeOfArrival || new Date(),
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        ),
        tlComments: data.comments || "",
        menuId: location?.state?.menuId,
        privilege: privilege,
      };
      saveCodeForm(fianlObjOtherCode)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            setOpenConfirmationModal(false);
            methods.reset();
          }
        })
        .catch((res) => {
          errorAlert(res.message);
          setOpenConfirmationModal(false);
          setOpenBackdrop(false);
        });
    }
  };

  // patch on edit
  useEffect(() => {
    patchIncidentDate();
  }, [selectedRow]);

  const patchIncidentDate = () => {
    if (selectedRow) {
      setValue(
        "codeType",
        codeTypeArr.find((item) => item.id === selectedRow["Code Type"])
      );
      ///code other form
      if ([0, 1, 2, 3, 6].includes(selectedRow["Code Type"])) {
        setValue("codeDate", new Date(selectedRow["Date"]));
        setValue("codeTime", `1900-01-01T${selectedRow["timeForPatch"]}.000`);
        setValue("floor", selectedRow["Floor"]); //
        setValue("room", selectedRow["Room"]);
        setValue("area", selectedRow["Area"]);
        setValue("mockDrillOther", selectedRow["Is Mock Drill"]);
        setValue("cause", selectedRow["Cause"]);
        setValue(
          "timeOfArrival",
          `1900-01-01T${selectedRow["Time Of Arrival"]}.000`
        ); //////
        setValue("teamLead", selectedRow["Team Leader"]);
        setValue("respondingTeamMember", selectedRow["Team Members"]);
        setValue("outcomeOfCode", selectedRow["Outcome Of Code"]);
        setValue("ifUnresolved", selectedRow["Escalated To Admin"]);
        setValue(
          "escalatedToAdministartionDetails",
          selectedRow["Escalated Details"]
        );
        setValue("briefNarative", selectedRow["Brief Narrative"]);
        setValue("comments", selectedRow["TL Comments"]);
      } else if (selectedRow["Code Type"] === 4) {
        setValue("codeDateYellow", new Date(selectedRow["Date"]));
        setValue(
          "codeTimeYellow",
          `1900-01-01T${selectedRow["TimeForPatch"]}.000`
        );
        setValue("callerName", selectedRow["CallerName"]);
        setValue("callerLocation", selectedRow["CallerLocation"]);
        setValue("operatorName", selectedRow["NameOfOperator"]);
        setValue("massCasNoOfPts", selectedRow["MassCasulatyNoOfPT"]);
        setValue(
          "yellowCodeTeamArrivalTime",
          `1900-01-01T${selectedRow["CodeTeamArrivalTime"]}.000`
        );
        setValue("medicalEmergency", selectedRow["MedicalEmergency"]);
        setValue("yellowShortSummary", selectedRow["ShortSummary"]);
        setValue("cprGiven", selectedRow["IsCPRGiven"]);
        setValue("cprGivenCount", selectedRow["CPRPTCount"]);
        setValue("lifeSupport", selectedRow["BasicLifeSupport"] === 1 ? 0 : 1);
        setValue("redBands", selectedRow["IsRedBand"]);
        setValue("redBandsDtls", selectedRow["RedBandPTCount"]);
        setValue("yellowBands", selectedRow["IsYellowBand"]);
        setValue("yellowBandsDtls", selectedRow["YellowBandPTCount"]);
        setValue("orangeBands", selectedRow["IsOrangeBand"]);
        setValue("orangeBandsDtls", selectedRow["OrangeBandPTCount"]);
        setValue("greenBands", selectedRow["IsGreenBand"]);
        setValue("greenBandsDtls", selectedRow["GreenBandPTCount"]);
        setValue("ptShiftedToIcu", selectedRow["NoOfPTShefiedToICU"]);
        setValue("ptStabilizedInWard", selectedRow["NoOfPTShefiedToWard"]);
        setValue("ptAdmitted", selectedRow["NoOfPTAdmitted"]);
        setValue(
          "ptTransferedToOtherHospi",
          selectedRow["NoOfPTTransferToOtherHospital"]
        );
        setValue("ptTreatedInOpd", selectedRow["NoOfPTTreatedOnOPD"]); //

        setValue("ptExpired", selectedRow["NoOfPTExpired"]);

        setValue("anyDifficultiesToCY", selectedRow["AnyDifficulties"]);

        setValue("yellowOverallComments", selectedRow["OverallComments"]);
      } else if (selectedRow["Code Type"] === 5) {
        setValue("codeDateOrange", new Date(selectedRow["Date"]));
        setValue(
          "codeTimeOrange",
          `1900-01-01T${selectedRow["timeForPatch"]}.000`
        );
        setValue("patientName", selectedRow["Operator Name"]); ///
        setValue("patientMRNO", selectedRow["Caller Name"]); ///
        setValue("strokeWithinWindowPeriod", selectedRow["Is Strock Time"]);
        setValue("strokeTime", `1900-01-01T${selectedRow["Strock Time"]}.000`);
        setValue("mockDrillOrange", selectedRow["Is Mock Drill"]);
        setValue("diagnosis", selectedRow["Diagnosis"]);
        setValue("shortSummaryOrange", selectedRow["Stort Summery"]);
        setValue("lifeSupport", selectedRow["BasicLifeSupport"] === 1 ? 0 : 1);
        setValue("radiologyMriCt", selectedRow["MRI_CT"]);
        setValue("outcomeOrange", selectedRow["Code Out Come"]);
        setValue("currentLocation", selectedRow["Current Location"]);
        setValue("codeOrangeTeam", selectedRow["Code Team"]);
        setValue("anyDifficultiesToCO", selectedRow["Code Difficulties"]);
      }
    }
  };

  return (
    <div className="mt-12 mb-2">
      <div className="text-center text-black font-bold text-xl my-3">
        Code Form
      </div>

      <FormProvider {...methods}>
        <form
        // onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-6 mb-3 gap-3">
            <div className="">
              <DropdownField
                control={control}
                // error={errors.MLCType}
                name="codeType"
                placeholder="Code Type"
                dataArray={codeTypeArr}
                isSearchable={false}
                inputRef={{
                  ...register("codeType", {
                    onChange: () => {
                      setSelectedRow(null);
                    },
                  }),
                }}
              />
            </div>
            <div className={`${"col-span-5"} flex justify-end `}>
              {userActions.map((actions) => (
                <>
                  {!actions.isAction ? (
                    <>
                      <div className={`${"col-span-5"} flex justify-end `}>
                        {actions.action === "Edit" && (
                          <CommonButton
                            label="Code Forms List"
                            className="bg-customBlue text-white"
                            onClick={() => handleOpenCodeFormsListingModal()}
                          />
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>

          {codeType_selected &&
            codeType_selected?.label !== "Yellow" &&
            codeType_selected?.label !== "Orange" &&
            codeType_selected?.label !== "Blue" && <OtherForms />}

          {/* code orange form */}
          {codeType_selected && codeType_selected?.label === "Orange" && (
            <OrangeForm />
          )}

          {/* code Yellow form */}
          {codeType_selected && codeType_selected?.label === "Yellow" && (
            <YellowForm />
          )}
          {userActions.map((actions) => (
            <>
              {!actions.isAction ? (
                <>
                  {actions.action === "Create" && codeType_selected && (
                    <div className="gap-3 my-3 flex justify-end">
                      <div>
                        <CommonButton
                          label="Reset"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            methods.reset();
                            setSelectedRow(null);
                          }}
                        />
                      </div>
                      <div onClick={() => setPrivilege(actions.action)}>
                        {selectedRow === null && (
                          <CommonButton
                            label="Save"
                            className="bg-customGreen text-white"
                            onClick={methods.handleSubmit(onSubmit)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </>
          ))}
        </form>
      </FormProvider>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {openCodeFormsListingModal ? (
        <CodeFormsListing
          open={openCodeFormsListingModal}
          setOpen={setOpenCodeFormsListingModal}
          handleOpen={handleOpenCodeFormsListingModal}
          handleClose={handleCloseCodeFormsListingModal}
          setSelectedRow={setSelectedRow}
        />
      ) : null}
    </div>
  );
};

export default CodeForms;
