import React from "react";

function AddButton({ onClick }) {
  return (
    <div>
      <button
        type="button"
        className="h-9 px-3 text-sm 2xl:text-base font-medium bg-green-600 text-white rounded "
        onClick={onClick}
      >
        Add
      </button>
    </div>
  );
}

export default AddButton;
