import React from "react";

function AddNewButton({ onClick }) {
  return (
    <div>
      <button
        type="submit"
        className="h-9 px-3 w-full bg-green-600 text-white rounded text-sm font-medium"
        onClick={onClick}
      >
        + Add New
      </button>
    </div>
  );
}

export default AddNewButton;
