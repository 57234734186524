import React, { useEffect, useState } from "react";
import WorkOrderGRNModal from "./WorkOrderGRNModal";
// import View from "../../nursingsupervisorreport/nursingSupervisor/View";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  getAccHeadList,
  getWorkOrderItemList,
  getWorkOrderList,
  postWorkOrderGrn,
} from "../../../services/generalStore/workOrderGrnServices/WorkOrderGrnServices";
import { getSupplierList } from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import { fetchTaxType } from "../../../services/pharmacy/inPatientReturnServices/InPatientReturnServices";
import WorkOrderGrnItemTable from "./WorkOrderGrnItemTable";
export default function PurchaseOrder() {
  //schema
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    workOrderNo: yup.object().nullable().required("Required").shape({
      value: yup.string(),
      label: yup.string(),
    }),
    workOrderTable: yup.array().of(
      yup.object().shape({
        accountHead: yup
          .object()
          .nullable()
          .when("select", (select) => {
            if (select === true) {
              return yup
                .object()
                .nullable()
                .shape({
                  value: yup.string().required("Please Mention accountHead"),
                  label: yup.string().required("Please Mention accountHead"),
                })
                .required("Please Mention accountHead ");
            }
          }),
      })
    ),
    dcNumber: yup
      .string()
      .required("Required")
      .matches(/^[+1-9]+[0-9]*$/, "Only numbers are allowed"), //should start with '+' sign
  });

  //use form
  const defaultValues = {
    search: "",
    Tax: "After Disc & Tax",
    quiz: true,
    totalAmount: "0",
    netAmount: "0",
    invoiceDate: new Date(),
    workOrderDate: new Date(),
    remark: "",
    dcNumber: "",
    supplierName: null,
    workOrderNo: null,
    taxType: null,
    workOrderTable: [{ accountHead: null, select: false }],
  };
  // const schema = {};
  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    control,
    watch,
    trigger,
    setValue,
  } = methods;
  // localhost user Info
  let getStoreName = JSON.parse(localStorage.getItem("userInfo"));

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [supplierList, setSupplierList] = useState();
  const [workOrderList, setWorkOrderList] = useState();

  const [workOrderListItemsData, setWorkOrderListItemsData] = useState([]);
  const [accountHeadOptions, setAccHeadOptions] = useState([]);
  const [totalCalculations, setTotalCalculations] = useState(null);
  const [selectedGrnItems, setSelectedGrnItems] = useState([]);
  const [taxTypeOptions, setTaxTypeOptions] = useState([]);

  const [finalData, setFinalData] = React.useState({});
  const [openPost, setOpenPost] = React.useState(false);

  // local variables
  let supplierId = watch("supplierName");
  let workOrderId = watch("workOrderNo");

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [openWorkOrderGrnModal, setOpenWorkOrderGrnModal] = useState(false);
  const handleOpen = () => setOpenWorkOrderGrnModal(true);
  const handleClose = () => setOpenWorkOrderGrnModal(false);

  const location = useLocation();

  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  let storeName = {
    value: getStoreName.storeName,
    label: getStoreName.storeName,
  };

  const handleChangeGetSupplierList = (autoSearchString) => {
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  const getWorkOrderTable = () => {
    let obj = {
      storeId: getStoreName.storeId,
      supplierId: supplierId?.id,
      workOrderId: workOrderId?.id,
    };
    if (supplierId !== null && workOrderId !== null) {
      getWorkOrderItemList(obj).then((response) => {
        const modifiedData = response.data.result.map((item) => ({
          ...item,
          accountHead: null,
        }));
        setWorkOrderListItemsData(modifiedData);
      });
    }
    getAccHeadList().then((response) => {
      setAccHeadOptions(response.data.result);
    });
  };

  useEffect(() => {
    // work order table
    getWorkOrderTable();

    fetchTaxType()
      .then((response) => response.data)
      .then((res) => {
        setTaxTypeOptions(res.result);
      });

    if (supplierId !== null) {
      getWorkOrderList(supplierId?.id)
        .then((response) => response.data)
        .then((res) => {
          setWorkOrderList(res.result);
        });
    }
    if (supplierId === null) {
      setValue("workOrderNo", null);
    }
  }, [supplierId, workOrderId]);

  React.useEffect(() => {
    setValue("storeName", storeName);

    if (totalCalculations !== null) {
      setValue(
        "totalAmount",
        Number(totalCalculations?.totalAmount).toFixed(2)
      );
      setValue("netAmount", Number(totalCalculations?.netAmount).toFixed(2));
    }
  }, [totalCalculations, getStoreName]);

  // post function for work order
  function postStoreNewIndent(obj) {
    postWorkOrderGrn(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData({});
        setSelectedGrnItems([]);
        setWorkOrderListItemsData([]);
        setTotalCalculations({});
        reset(defaultValues);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        handleClosePost();
      });
  }
  // call the function for confirmation modal
  function addRecord() {
    setOpenBackdrop(true);
    postStoreNewIndent(finalData);
    setOpenPost(false);
  }

  const onSubmitDataHandler = (dataObj) => {
    let selectedInvoiceDate = getValues("invoiceDate");
    let selectedWorkOrderDate = getValues("workOrderDate");
    let modifiedArr = [...selectedGrnItems];
    let reqworkOrderGrnDtlsDtoArr = [];
    for (let obj of modifiedArr) {
      let reqObj = {
        acHead: obj.accountHead?.id,
        amount: Number(obj.Amount),
        discAmt: Number(obj.DiscAmt),
        discPer: Number(obj.DiscPer),
        itemCode: obj.ItemCode,
        itemName: obj.ItemName,
        netAmt: Number(obj.NetAmount),
        otherTaxAmount: 0,
        qty: Number(obj.Qty),
        rate: Number(obj.Rate),
        remarks: obj.Remark,
        taxAmount: 0,
        uom: obj.UOM,
        vatAmt: Number(obj.VATAmt),
        vatPer: Number(obj.VATPer),
      };
      reqworkOrderGrnDtlsDtoArr.push(reqObj);
    }

    if (selectedGrnItems.length > 0) {
      let postedObj = {
        menuId: location?.state?.menuId,
        privilege: privilege,
        addedBy: getStoreName.cashCounterId,
        amountPaid: 0,
        appByAccount: 0,
        appByCeo: 0,
        appByCfo: 0,
        appByPurchase: 0,
        convertCashCreditBy: 0,
        creditCash: true,
        dcDate: selectedWorkOrderDate,
        dcNo: dataObj.dcNumber,
        discAmount: 0,
        invoiceDate: selectedInvoiceDate,
        isApprovedByAcc: 0,
        isCashVoucher: true,
        isDirectPurchase: true,
        isSettled: true,
        netAmount: totalCalculations.netAmount,
        netApprovedAmt: 0,
        printFlag: true,
        rejByAccount: 0,
        rejByCeo: 0,
        rejByCfo: 0,
        rejByPurchase: 0,
        remark: dataObj.remark,
        serviceTaxAmt: 0,
        storeId: getStoreName.storeId,
        supplierId: supplierId.id,
        totalAmount: totalCalculations.totalAmount,
        totalAmountPayable: 0,
        totalBalanceAmount: 0,
        vatAmount: 0,
        workOrderGrnDtlsDto: reqworkOrderGrnDtlsDtoArr,
        workOrderHeaderId: workOrderId.id,
      };

      setOpenPost(true);
      setFinalData(postedObj);
    } else {
      warningAlert("Please Select Item First");
    }
  };
  // set error for account head
  React.useEffect(() => {
    if (selectedGrnItems.length > 0) {
      if (isValid === false) {
        errorAlert("please select account head");
      }
    }
  }, [errors]);
  return (
    <>
      <FormProvider {...methods}>
        <div className="mt-14 px-2 w-full">
          <div className="text-center text-black font-bold text-xl py-2 w-full">
            Work Order GRN
          </div>
          <form onSubmit={handleSubmit(onSubmitDataHandler)} className="mt-2">
            <div className="grid grid-cols-3 lg:grid-cols-4 gap-2">
              <div className=" w-full">
                <DropdownField
                  control={control}
                  error={errors.storeName}
                  name="storeName"
                  placeholder="Store Name"
                  isDisabled={true}
                />
              </div>

              <div className=" w-full">
                <SearchDropdown
                  control={control}
                  searchIcon={true}
                  isDisabled={false}
                  isSearchable={true}
                  isClearable={true}
                  error={errors.supplierName}
                  name="supplierName"
                  placeholder="Supplier Name"
                  dataArray={supplierList}
                  handleInputChange={handleChangeGetSupplierList}
                />
              </div>
              <div className=" w-full">
                <DropdownField
                  control={control}
                  error={errors.workOrderNo}
                  name="workOrderNo"
                  placeholder="Work Order No."
                  dataArray={workOrderList}
                  isDisabled={supplierId !== null ? false : true}
                />
              </div>
              <div className=" w-full">
                <DatePickerFieldNew
                  control={control}
                  name="workOrderDate"
                  label="Work Order Date"
                  value={new Date()}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                  disabled={
                    supplierId?.id && workOrderId?.id !== null ? false : true
                  }
                />
              </div>
              <div className=" w-full">
                <DatePickerFieldNew
                  control={control}
                  name="invoiceDate"
                  label="Invoice Date"
                  value={new Date()}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                  disabled={
                    supplierId?.id && workOrderId?.id !== null ? false : true
                  }
                />
              </div>
              <div className=" w-full">
                <InputField
                  control={control}
                  name="dcNumber"
                  label="DC Number"
                />
              </div>
              <div className=" w-full">
                <InputField
                  type="text"
                  control={control}
                  name="remark"
                  label="Remark"
                />
              </div>
              <div className=" whitespace-nowrap flex justify-end">
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction ? (
                      <>
                        {obj.action === "Search W.O GRN" ? (
                          <>
                            <CommonButton
                              type="button"
                              className="bg-customBlue text-white"
                              label="Search Work Order GRN"
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
            <div className="w-full">
              <WorkOrderGrnItemTable
                data={workOrderListItemsData}
                accountHeadOptions={accountHeadOptions}
                setData={setWorkOrderListItemsData}
                setTotalCalculations={setTotalCalculations}
                setSelectedGrnItems={setSelectedGrnItems}
                selectedGrnItems={selectedGrnItems}
              />
            </div>

            <div className="flex justify-between gap-2 mt-2">
              <div className="w-4/12 flex space-x-2 items-center">
                <div className="w-full">
                  <DropdownField
                    control={control}
                    error={errors.taxType}
                    name="taxType"
                    placeholder="Tax Type"
                    dataArray={taxTypeOptions}
                  />
                </div>
                <div className="flex space-x-3 ">
                  <InputField
                    control={control}
                    name="totalAmount"
                    label="Total Amount"
                    disabled
                  />
                  <InputField
                    control={control}
                    name="netAmount"
                    label="Net Amount"
                    disabled
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction ? (
                      <>
                        {obj.action === "Create" ? (
                          <>
                            <CommonButton
                              type="button"
                              className="border border-customRed text-customRed"
                              label="Reset"
                              onClick={() => {
                                reset(defaultValues);
                              }}
                            />
                            <CommonButton
                              type="submit"
                              className="bg-customGreen text-white"
                              label="Save"
                              onClick={() => {
                                setPrivilege(obj.action);
                              }}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </form>
        </div>
        {openWorkOrderGrnModal}
        {openWorkOrderGrnModal ? (
          <WorkOrderGRNModal
            open={openWorkOrderGrnModal}
            setOpen={setOpenWorkOrderGrnModal}
            handleOpen={handleOpen}
            handleClose={handleClose}
            userActions={userActions}
          />
        ) : (
          ""
        )}
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Add"
        />
      </FormProvider>
    </>
  );
}
