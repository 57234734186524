
export const PreviousIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="12" fill="#0B83A5" />
        <path
          d="M14 7L9.66182 12.0232C9.29765 12.4448 9.34833 13.0828 9.77451 13.4417L14 17"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    );
  };
  