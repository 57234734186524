import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
export const doctorDropdown = (searchString) => {
  return apiClient.get(`/misc/doctor/${searchString}`, {
    headers: authHeader(),
  });
};
export const unitDropdown = () => {
  return apiClient.get(`/misc/unit`, {
    headers: authHeader(),
  });
};
export const cabinDropdown = () => {
  return apiClient.get(`/misc/cabin`, {
    headers: authHeader(),
  });
};
export const casePaperReportPrint = (visitId) => {
  return apiClient.get(`/reports/opd/caseSummary?visitId=${visitId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};

export const visitDetailsBarcode = (visitId) => {
  return apiClient.get(`barcode/visitDetailsBarcode?visitId=${visitId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};

export const fetchAllVisitList = (searchObj) => {
  return apiClient.post(`/patientVisitDetails/patientVisitList`, searchObj, {
    headers: authHeader(),
  });
};

export const searchVisitList = (searchObj) => {
  return apiClient.post(
    `/patientVisitDetails/patientVisitListSearch`,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};
