import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const searchSupplier = async (createSupplier) => {
  return await apiClient.get(`/purchaseOrder/supplierList/${createSupplier}`, {
    headers: authHeader(),
  });
};

export const getFreightListDropdown = async () => {
  return await apiClient.get(`/purchaseOrder/freightList`, {
    headers: authHeader(),
  });
};

export const getDeliveryListDropdown = async () => {
  return await apiClient.get(`/purchaseOrder/DeliveryList`, {
    headers: authHeader(),
  });
};

export const getModeOfPaymentDropdown = async () => {
  return await apiClient.get(`/purchaseOrder/modeOfPayment`, {
    headers: authHeader(),
  });
};

export const getTermOfPaymentDropdown = async () => {
  return await apiClient.get(`/purchaseOrder/termOfPayment`, {
    headers: authHeader(),
  });
};

export const getTaxNatureListDropdown = async () => {
  return await apiClient.get(`/purchaseOrder/taxNatureList`, {
    headers: authHeader(),
  });
};

export const poItemDetailsSearch = (itemSearch) => {
  return apiClient.post(`/purchaseOrder/searchItemDetails`, itemSearch, {
    headers: authHeader(),
  });
};

export const addNewPurchaseOrder = async (createPurchaseOrder) => {
  return await apiClient.post(
    `/purchaseOrder/savePurchaseOrder`,
    createPurchaseOrder,
    {
      headers: authHeader(),
    }
  );
};

export const fetchAllPurchaseOrderList = async (purchaseOrderObj) => {
  return await apiClient.post(
    `/purchaseOrder/purchaseOrderList`,
    purchaseOrderObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchAllPurchaseOrderItemDetailList = async (
  purchaseOrderItemObj
) => {
  return await apiClient.post(
    `/purchaseOrder/purchaseOrderDetailsList`,
    purchaseOrderItemObj,
    {
      headers: authHeader(),
    }
  );
};

export const searchPONumber = async (searchString) => {
  return await apiClient.get(`/purchaseOrder/searchPONumber/${searchString}`, {
    headers: authHeader(),
  });
};

// /api/purchaseOrder/verifyPurchaseOrder/{poId}/{status}
export const cancelVerifyPurchaseOrder = async (poId, status, menuId, privilege) => {
  return await apiClient.get(
    `/purchaseOrder/verifyPurchaseOrder/${poId}/${status}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

// http://192.168.0.130:5002/api/reports/inventory/purchaseOrder/1477143
export const fetchPrintPOForm = (doId) => {
  return apiClient.get(
    `/reports/inventory/purchaseOrder/${doId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    },
  );
};
