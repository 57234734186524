import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputEnter from "../../../../common/components/FormFields/InputEnter";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";
import { fetchKitList } from "../../../services/commonServices/CommonServices";
import {
  getIndentForEdit,
  getItemForAutoIndent,
} from "../../../services/commonServices/StoreIndent/StoreIndentService";
import {
  fetchAlternateDrugNameTable,
  fetchDrugNameTable,
  postPatientIndent,
} from "../../../services/generalStore/PatientIndentServices/PatientIndentServices";
import { fetchItemKitData } from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import GetIndentModal from "../../common/storeIndent/GetIndentModal";
import ItemDetailsTable from "../patientIndent/ItemDetailsTable";
import AlternateDrugTable from "./AlternateDrugTable";
import DrugTable from "./DrugTable";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const IsUrgentRadio = [
  {
    id: "Yes",
    value: "Yes",
    label: "Yes",
  },
  {
    id: "No",
    value: "No",
    label: "No",
  },
];

const StoreIndent = () => {
  const schema = yup.object().shape({
    indentToStore: yup
      .object()
      .required("Required")
      .nullable()
      .when("indentTo", (indentTo) => {
        if (indentTo === "Store") {
          return yup.object().nullable().required("Required").shape({
            value: yup.string(),
            label: yup.string(),
          });
        } else if (indentTo === "Unit") {
          return yup.object().nullable().notRequired().shape({
            value: yup.string(),
            label: yup.string(),
          });
        }
      }),
  });

  const defaultValues = {
    fromStore: "",
    totalAmount: 0,
    itemkitValue: null,
    netPayableAmount: 0,
    itemClass: null,
    indentToStore: null,
    roundedAmount: 0,
    "input-1": "",
    "input-2": "",
    "input-3": "",
    itemKitQty: "",
    isUrgentRadio: "No",
  };
  const {
    watch,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //indent modal
  const [openIndentModal, setOpenIndentModal] = useState(false);
  const handleOpenIndentModal = () => setOpenIndentModal(true);
  const handleCloseIndentModal = () => setOpenIndentModal(false);
  //
  //table data
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [drugTableData, setDrugTableData] = useState([]);
  const [altDrugTableData, setAltDrugTableData] = useState([]);
  const [selectedGenericId, setSelectedGenericId] = useState();
  const [selectedDrugRowData, setSelectedDrugRowData] = useState(null);
  //last selected index
  const [lastIndex, setLastIndex] = useState();
  // itm drg tbl
  const [selectedItemDtlsID, setSelectedItemDtlsID] = useState(null);
  //Alt drug tbl Row id
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = useState(null);
  // drug tbl Row id
  const [selectedDrugRowID, setSelectedDrugRowID] = useState(0);
  const [selectedDrugData, setSelectedDrugData] = useState([]);

  //item Kit List
  const [itemKitList, setItemKitList] = useState();
  const [searchItemKitValue, setSearchItemKitValue] = useState(null);
  const [itemKitData, setItemKitData] = useState([]); // selected ItemKitData
  ///calculations populate
  const [noOfItems, setNoOfItems] = useState(0);
  //empty input on enter setEmptyDrugInput
  const [emptyDrugInput, setEmptyDrugInput] = useState();
  //
  const [drugSearchString, setDrugSearchString] = useState();

  //store name
  const [storeName, setStoreName] = useState();
  //backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  // edit indent
  const [editIndentRow, setEditIndentRow] = useState(null);
  const [indentId, setIndentId] = useState(0);

  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");

  // local variable
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let storeId = token.storeId;
  let location = useLocation();
  let deptStore1 = watch("indentToStore");
  let itemKit = watch("itemkitValue");
  let watchInput1 = watch("input-1");
  let itemKitQty = watch("itemKitQty");
  let quantityValue = watch("input-2");
  let quantityValueKit = watch("itemKitQty");

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefItemKitQty = useRef(null);
  let isUrgent = watch("isUrgentRadio");
  let isUrgentType;
  const AddClickRefrence = useRef(null);
  const FocusDrugTable = useRef();
  const removeFocusSearch = useRef(null);

  // set Value for is urgent
  if (isUrgent === "Yes") {
    isUrgentType = true;
  } else if (isUrgent === "No") {
    isUrgentType = false;
  }
  //

  const getIndentInfo = () => {
    handleOpenIndentModal();
  };

  //search by item kit
  const searchByItemKit = (autoSearchString) => {
    if (autoSearchString !== "") {
      fetchKitList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setItemKitList(res.result);
        });
    }
  };

  // Alternate drug name data altDrugObj
  const getAlternateDrugNameTable = () => {
    if (selectedDrugRowData !== null && selectedDrugRowData !== undefined) {
      let altDrugObj = {
        deptStore: 1,
        deptStoreId: deptStore1?.id,
        genericId: selectedDrugRowData.ItemGenericId,
        isConsignment: selectedDrugRowData.IsConsignment ? 1 : 0,
        itemCode: "%",
        itemName: "%",
      };

      fetchAlternateDrugNameTable(altDrugObj)
        .then((response) => response.data)
        .then((res) => {
          setAltDrugTableData(res.result);
        });
    }
  };

  const removeFocusInputSearch = () => {
    if (removeFocusSearch.current) {
      removeFocusSearch.current.blur();
    }
  };

  // update item and item kit
  const mergeArray = (arr) => {
    const map = new Map();
    arr.forEach((val) => {
      const existing = map.get(val.Id);
      if (existing) {
        existing["Indent Qty"] += val["Indent Qty"];
      } else {
        map.set(val.Id, { ...val });
      }
    });
    return Array.from(map.values());
  };

  const addToItemDetailsTbl = () => {
    if (quantityValue !== "") {
      selectedDrug["Indent Qty"] = parseInt(quantityValue);
      selectedDrug.Amount = quantityValue * selectedDrug.MRP;
      let arr = [...selectedDrugData];
      const existingItem = arr.find((item) => item.Id === selectedDrug.Id);
      if (existingItem) {
        warningAlert("Drug already exists!");
        setValue("input-1", "");
        setValue("input-2", "");
        return;
      }
      arr.push(selectedDrug);
      setSelectedDrugData(mergeArray(arr));
      setValue("input-1", "");
      setValue("input-2", "");
      setSelectedDrug(null);
      inputRefOne.current.focus();
    }
  };

  const addToItemKitTbl = () => {
    for (let obj of itemKitData) {
      if (quantityValueKit !== "") {
        let qty = Number(quantityValueKit);
        if (!isNaN(qty)) {
          obj["Indent Qty"] = obj.Quantity * qty;
          obj.Amount = qty * obj.MRP;
          let arr = [...selectedDrugData];
          const newArr = arr.concat(itemKitData);
          setSelectedDrugData(mergeArray(newArr));
          setValue("itemkitValue", null);
          setValue("itemKitQty", "");
        } else {
          console.error("quantityKitQty is not a valid number");
        }
      }
    }
  };

  // itemKit forcus
  function focusServicesSearchDropdown() {
    let fieldsetTag = AddClickRefrence.current;
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  const onSubmit = (data) => {
    if (selectedDrugData.length > 0) {
      setOpenConfirmationModal(true);
    }
  };

  //finalObj
  const finalObj = {
    menuId: location?.state?.menuId || "",
    privilege: privilege,
    addedBy: 0,
    admissionId: 0,
    cashCounterId: 0,
    patientIndentDtlsDto: [],
    quantity: 0,
    remark: "string",
    totalAmount: 0,
    totalVatAmount: 0,
    updatedBy: 0,
    fromStoreId: 0,
    toStoreId: 0,
    drId: 0,
    indentId: 0,
    isUrgent: isUrgentType,
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);
    //

    finalObj.addedBy = token.userId;
    finalObj.admissionId = 0;
    finalObj.drId = 0;
    finalObj.indentId = indentId||null;
    finalObj.isUrgent = isUrgentType;
    finalObj.cashCounterId = token.cashCounterId;
    finalObj.quantity = getValues("totalQuantity");
    finalObj.remark = getValues("remark");
    finalObj.totalValue = getValues("totalAmount");
    finalObj.totalVatAmount = 0;
    finalObj.updatedBy = token.userId;
    finalObj.fromStoreId = token.storeId;
    finalObj.toStoreId = deptStore1 && deptStore1.id;
    finalObj.totalAmountPayable = getValues("netPayableAmount");

    //
    let TblData = [];
    for (let i = 0; i < selectedDrugData.length; i++) {
      let tableObj = {
        isClosed: false,
        itemId: selectedDrugData[i].Id,
        indentDetailsId: selectedDrugData[i].IndentDetailId
          ? selectedDrugData[i].IndentDetailId
          : 0,
        pendIssueQty: selectedDrugData[i]["Indent Qty"],
        pendQty: selectedDrugData[i]["Indent Qty"],
        rate: selectedDrugData[i].MRP,
        reqdQty: selectedDrugData[i]["Indent Qty"],
        totalCost: selectedDrugData[i].Amount,
      };
      TblData.push(tableObj);
    }

    finalObj.patientIndentDtlsDto = TblData;
    postPatientIndent(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setValue("totalAmount", "");
          setValue("itemkitValue", null);
          setValue("itemKitQty", "");
          setValue("itemClass", "");
          setValue("netPayableAmount", "");
          setValue("indentToStore", null);
          setValue("remark", "");
          setValue("input-2", "");
          setValue("isUrgentRadio", "No");
          setOpenBackdrop(false);
          setSelectedDrugData([]);
          setAltDrugTableData([]);
          setDrugTableData([]);
          setIndentId(0);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const drugListObj = {
      deptStore: 1,
      deptStoreId: deptStore1?.id,
      isConsignment: 0,
      itemCode: "%",
      itemName: "%",
      searchString: drugSearchString,
      deptStore1: storeId, //to store
      itemClass: 0,
      storePatient: 0,
    };

    if (deptStore1 !== null) {
      fetchDrugNameTable(drugListObj)
        .then((response) => response.data)
        .then((res) => {
          setDrugTableData(res.result);
        });
    }
    // }
  }, [watchInput1]);

  useEffect(() => {
    if (location?.state?.menuId) {
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    }
  }, []);

  useEffect(() => {
    getAlternateDrugNameTable();
    if (selectedDrug !== null) {
      setValue(
        "input-1",
        selectedDrug && selectedDrug["Item Name"]
          ? selectedDrug["Item Name"]
          : selectedDrug.Drug
      );
      inputRefTwo?.current?.focus();
    }
  }, [selectedDrug]);

  useEffect(() => {
    if (searchItemKitValue !== null) {
      fetchItemKitData(
        searchItemKitValue && searchItemKitValue.id,
        token.storeId
      )
        .then((response) => response.data)
        .then((res) => {
          setItemKitData(res.result);
        });
    }
    //Alternate drug name data altDrugObj
    getAlternateDrugNameTable();
  }, [
    selectedDrugRowData,
    storeId,
    selectedGenericId,
    noOfItems,
    emptyDrugInput,
    searchItemKitValue,
  ]);

  useEffect(() => {
    setValue("fromStore", token.storeName);
    fetchApplicableStores(token.storeId)
      .then((response) => response.data)
      .then((res) => {
        setStoreName(res.result);
      });
  }, []);

  useEffect(() => {
    if (itemKit !== null) {
      inputRefItemKitQty.current?.focus();
    }
  }, [itemKit]);

  useEffect(() => {
    ///focus input 1 on add button click
    if (deptStore1 !== null) {
      inputRefOne.current.focus();
    }
  }, [deptStore1]);

  // edit row
  useEffect(() => {
    if (editIndentRow !== null) {
      if (editIndentRow?.IndentId) {
        handleCloseIndentModal();
      }
      getIndentForEdit(editIndentRow.IndentId)
        .then((response) => response.data)
        .then((res) => {
          let toStoreObj = {
            id: res.result.ToStoreId,
            value: res.result.ToStore,
            label: res.result.ToStore,
          };
          setIndentId(res.result.IndentId);
          setValue("indentToStore", toStoreObj);
          setValue("isUrgentRadio", res.result.IsUrgent ? "Yes" : "No");

          const updateSelectedDrugData = () => {
            let tempArr = [...selectedDrugData];
            for (let tempObj of res.result.itemDetailList) {
              let obj = {
                Id: tempObj.ItemId,
                Code: tempObj.Code,
                "Item Name": tempObj["Item Name"],
                "Balance Qty": tempObj["Balance Qty"],
                StockingUOM: "",
                PurchaseRate: tempObj.Rate,
                BatchesRequired: "",
                MRP: tempObj.Rate,
                GenericName: "",
                PurchaseUOM: "",
                CompanyName: null,
                IndentDetailId: tempObj.IndentDetailId,
                Conversion: 1,
                StorePurchaseRate: "",
                ItemCategory: "",
                ItemForm: "",
                VATPercentage: "",
                VATInClusive: "",
                Consignment: "",
                "Hospital Bal Qty": tempObj["Hospital Bal Qty"],
                ItemGenericId: "",
                "Indent Qty": tempObj["Indent Qty"],
                Amount: "",
              };
              const isItemCodeExists = tempArr.some(
                (existingItem) =>
                  existingItem.IndentDetailId === tempObj.IndentDetailId
              );
              if (isItemCodeExists) {
                warningAlert("Item Already Exists.");
              } else {
                tempArr.push(obj);
              }
            }
            setSelectedDrugData(tempArr);
          };
          updateSelectedDrugData();
        });
    }
  }, [editIndentRow]);

  console.log("selectedDrugData", selectedDrugData);

  const handleAutoIndent = () => {
    if (deptStore1) {
      const autoIndentObj = {
        fromStoreId: token.storeId,
        toStoreId: deptStore1.id,
      };
  
      getItemForAutoIndent(autoIndentObj)
        .then((response) => response.data)
        .then((res) => {
          let updatedData = res.result.map(
            ({
              "Item Name": Drug,
              "Item Code":code,
              ItemId,
              Mrp: MRP,
              "Required Qty":indentQty,
            }) => ({
              Id: ItemId,
              Code: code,
              Drug,
              BalanceQty: 0,
              "Indent Qty": indentQty,
              BatchesRequired: false,
              ItemCategory: "",
              ItemForm: "",
              VATInClusive: false,
              CompanyName: "",
              VATPercentage: 0,
              ConcessionApp: false,
              FreeApplicable: false,
              IsConsignment: false,
              Onco: "",
              OncoDist: 0,
              ItemType: 0,
              MRP,
              PurchaseRate: 0,
              Conversion: 0,
              ItemGenericId: 0,
              ItemClass: 0,
              "Hospital Bal Qty": 0,
              UnitMRP: 0,
            })
          );
  
          setSelectedDrugData(updatedData);
        });
    } else {
      warningAlert("Please select to store.");
    }
  };
  

  return (
    <>
      <div className="mt-12">
        <label className="text-center text-black font-bold text-xl flex justify-center my-2">
          Store Indent
        </label>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3">
            <div className="">
              <InputField
                control={control}
                name="fromStore"
                disabled={token.storeId ? true : false}
                label="Indent From Store *"
              />
            </div>

            <div className=" ">
              <DropdownField
                control={control}
                name="indentToStore"
                error={errors.indentToStore}
                placeholder="To Store"
                isSearchable={true}
                isClearable={true}
                dataArray={storeName}
                inputRef={{
                  ...register(`indentToStore`, {
                    onChange: (e) => {
                      if (e.target.value === null) {
                        setValue("indentToStore", null);
                      }
                    },
                  }),
                }}
              />
            </div>

            <div className=" flex md:justify-start  gap-2 items-center  lg:col-span-1">
              <p className="text-gray-700 font-semibold">
                Is Urgent &nbsp;: &nbsp;
              </p>
              <div className="mt-1">
                <RadioField
                  label=""
                  name="isUrgentRadio"
                  control={control}
                  dataArray={IsUrgentRadio}
                />
              </div>
            </div>

            <div className="flex gap-3 xl:col-span-2 justify-end">
              <CommonButton
                className=" border border-customBlue text-customBlue hover:bg-blue-200"
                label="Auto Indent"
                onClick={() => {
                  handleAutoIndent();
                }}
              />
              {userActions.map((obj) => (
                <>
                  {/* {!obj.isAction && obj.action === "View" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customBlue text-white"
                        label="View Indent"
                        onClick={() => {
                          getIndentInfo();
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null} */}
                  {!obj.isAction && obj.action === "View" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customBlue text-white"
                        label="View Indent"
                        onClick={() => {
                          getIndentInfo();
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>

          {/* item search */}
          <div className="mt-2">
            <div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                Item Details
              </span>
            </div>

            <div className=" grid lg:grid-cols-2 gap-3 ">
              <div className="flex space-x-2 w-full ">
                <fieldset
                  className="w-full"
                  onChange={(e) => setDrugSearchString(e.target.value)}
                  // ref={inputRefOne}
                >
                  <InputEnter
                    onKeyDown={handleKeyPress}
                    control={control}
                    name="input-1"
                    label="Search by Item "
                    placeholder="Search by Item "
                    disabled={deptStore1 === null ? true : false}
                    {...register("input-1")}
                    inputRef={inputRefOne}
                  />
                </fieldset>

                <div className="lg:w-40 xl:w-32">
                  <Controller
                    control={control}
                    name="input-2"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            addToItemDetailsTbl();
                          }
                          if (e.key === "-" || e.key === "+") {
                            e.target.blur();
                          }
                        }}
                        inputRef={inputRefTwo}
                        size="small"
                        type="number"
                        InputLabelProps={{
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            height: "36.3px",
                            background: "white",
                          },
                        }}
                        name="input-2"
                        variant="outlined"
                        label="Qty"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setValue("input-2", newValue);
                          if (newValue === "0") {
                            setValue("input-2", "1");
                          }
                        }}
                        disabled={deptStore1 === null ? true : false}
                      />
                    )}
                  />
                </div>

                <div>
                  <CommonButton
                    type="button"
                    className="bg-customBlue text-white"
                    label="+ Add"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        addToItemDetailsTbl();
                      }
                    }}
                    onClick={addToItemDetailsTbl}
                  />
                </div>
                <div className="  bg-gray-500 lg:grid md:hidden ">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem={true}
                  />
                </div>
              </div>

              <div className="w-full flex space-x-2 items-center">
                <div className="w-full" ref={AddClickRefrence}>
                  <SearchDropdown
                    control={control}
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={deptStore1 === null ? true : false}
                    error={errors.supplierName}
                    name="itemkitValue"
                    placeholder="Search by Item Kit"
                    dataArray={itemKitList}
                    handleInputChange={searchByItemKit}
                    inputRef={{
                      ...register(`itemkitValue`, {
                        onChange: (e) => {
                          if (e.target.value === null) {
                            setSearchItemKitValue(null);
                            setValue("itemKitQty", "");
                          } else {
                            setSearchItemKitValue(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div className="lg:w-40 xl:w-32">
                  <Controller
                    control={control}
                    name="itemKitQty"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            addToItemKitTbl();
                            focusServicesSearchDropdown();
                          }
                          if (e.key === "-" || e.key === "+") {
                            e.target.blur();
                          }
                        }}
                        inputRef={inputRefItemKitQty}
                        size="small"
                        type="number"
                        InputLabelProps={{
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            height: "36.3px",
                            background: "white",
                          },
                        }}
                        name="itemKitQty"
                        variant="outlined"
                        label="Qty"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // Use setValue from react-hook-form to update the value
                          setValue("itemKitQty", newValue);
                          if (newValue === "0") {
                            setValue("itemKitQty", "1");
                          }
                        }}
                        disabled={deptStore1 === null ? true : false}
                      />
                    )}
                  />
                </div>
                <div>
                  <CommonButton
                    type="button"
                    className="bg-customBlue text-white"
                    label="+ Add"
                    onClick={() => {
                      addToItemKitTbl();
                      if (itemKit !== null && itemKitQty !== "") {
                        setValue("itemkitValue", null);
                        setValue("itemKitQty", "");
                        setSearchItemKitValue(null);
                      }
                      focusServicesSearchDropdown();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* drug name table */}
          <div className="grid grid-cols-2 mt-2 gap-3">
            {/* drug tbl */}
            <div>
              {drugTableData.length > 0 && watchInput1 !== "" ? (
                <>
                  <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Drug Name
                  </span>

                  <div ref={FocusDrugTable}>
                    <DrugTable
                      data={drugTableData}
                      selectedItemDtlsID={selectedItemDtlsID}
                      setSelectedDrug={setSelectedDrug}
                      setSelectedItemDtlsID={setSelectedItemDtlsID}
                      setDrugTableData={setDrugTableData}
                      setAltDrugTableData={setAltDrugTableData}
                      selectedGenericId={selectedGenericId}
                      setSelectedGenericId={setSelectedGenericId}
                      setSelectedDrugRowData={setSelectedDrugRowData}
                      setSelectedDrugData={setSelectedDrugData}
                      selectedDrugData={selectedDrugData}
                      setEmptyDrugInput={setEmptyDrugInput}
                      selectedDrugRowID={selectedDrugRowID}
                      setSelectedDrugRowID={setSelectedDrugRowID}
                      lastIndex={lastIndex}
                      setLastIndex={setLastIndex}
                      setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                      selectedAltDrugRowID={selectedAltDrugRowID}
                      setDrugSearchString={setDrugSearchString}
                      removeFocusInputSearch={removeFocusInputSearch}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {/* alt drug tbl */}
            <div>
              {altDrugTableData.length > 0 &&
              drugTableData.length &&
              watchInput1 !== "" ? (
                <>
                  <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Alternate Drug Name
                  </span>

                  <div>
                    <AlternateDrugTable
                      data={altDrugTableData}
                      setSelectedDrug={setSelectedDrug}
                      selectedItemDtlsID={selectedItemDtlsID}
                      setSelectedItemDtlsID={setSelectedItemDtlsID}
                      setAltDrugTableData={setAltDrugTableData}
                      setDrugTableData={setDrugTableData}
                      setSelectedDrugData={setSelectedDrugData}
                      selectedDrugData={selectedDrugData}
                      setSelectedDrugRowData={setSelectedDrugRowData}
                      selectedAltDrugRowID={selectedAltDrugRowID}
                      setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                      setSelectedDrugRowID={setSelectedDrugRowID}
                      selectedDrugRowID={selectedDrugRowID}
                      setDrugSearchString={setDrugSearchString}
                      lastIndex={lastIndex}
                      removeFocusInputSearch={removeFocusInputSearch}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="mt-2">
            <ItemDetailsTable
              data={selectedDrugData}
              setData={setSelectedDrug}
              selectedDrug={selectedDrug}
              setSelectedDrugData={setSelectedDrugData}
              selectedItemDtlsID={selectedItemDtlsID}
              setSelectedItemDtlsID={setSelectedItemDtlsID}
              setNoOfItems={setNoOfItems}
              FocusDrugTable={FocusDrugTable}
              drugTableData={drugTableData}
              // setErrorToTblInput={setErrorToTblInput}
              // errorToTblInput={errorToTblInput}
              setOpenConfirmationModal={setOpenConfirmationModal}
            />
          </div>

          <div className="grid grid-cols-2 mt-3">
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="remark"
                {...register("remark")}
                label="Remark"
                multiline
                InputLabelProps={{ shrink: true }}
                rows={1}
                fullWidth
              />
            </div>
            {/* buttons */}
            <div className="flex gap-3   justify-end">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        onClick={() => {
                          setValue("totalAmount", "");
                          setValue("itemkitValue", null);
                          setValue("itemKitQty", "");
                          setValue("itemClass", "");
                          setValue("netPayableAmount", "");
                          setValue("indentToStore", null);
                          setValue("remark", "");
                          setValue("input-1", "");
                          setValue("input-2", "");
                          setValue("input-3", "");
                          setOpenBackdrop(false);
                          setSelectedDrugData([]);
                          setAltDrugTableData([]);
                          setDrugTableData([]);
                        }}
                      />

                      <CommonButton
                        type="button"
                        className="bg-customGreen text-white"
                        label="Save"
                        onClick={() => {
                          onSubmit();
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </form>
      </div>
      {/* View Order Modal */}
      {openIndentModal ? (
        <GetIndentModal
          open={openIndentModal}
          setOpen={setOpenIndentModal}
          handleOpen={handleOpenIndentModal}
          handleClose={handleCloseIndentModal}
          setEditIndentRow={setEditIndentRow}
          userActions={userActions}
          privilege={privilege}
          setPrivilege={setPrivilege}
          menuId={location?.state?.menuId}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default StoreIndent;
