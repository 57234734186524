import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  getGrnReturnListItems,
  postGRNReturnItems,
} from "../../../services/commonServices/grnReturnService/GRNReturnService";
import { getSupplierList } from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import GRNReturnModal from "./GRNReturnModal";

const paymentTypeObj = [
  {
    id: "Credit",
    value: "Credit",
    label: "Credit",
  },
  {
    id: "Cash",
    value: "Cash",
    label: "Cash",
  },
];

const GRNReturn = () => {
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),
  });
  const defaultValues = {
    id: "",
    store: null,
    supplierName: null,
    paymentType: "Credit",
    returnDate: new Date(),
    mobile: "",
    totalAmount: "0",
    netAmount: "0",
    remarks: "",
    itemTax: "",
    otherTax: "",
  };

  const {
    control,
    setValue,
    watch,
    getValues,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [supplierList, setSupplierList] = useState([]);
  const [supplierId, setSupplierId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTableItems, setSelectedTableItems] = useState([]);
  const [selectedTableModifiedValue, setSelectedTableModifiedValue] = useState(
    []
  );
  const [finalData, setFinalData] = useState({});

  const [privilege, setPrivilege] = useState("");
  const [userActions, setUserActions] = useState([]);
  const [openStoreGRNReturn, setOpenStoreGRNReturn] = useState(false);
  const OpenStoreGRNReturnModal = () => setOpenStoreGRNReturn(true);

  // confirmationModal
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  // local storage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let storeId = token.storeId;
  let navigate = useNavigate();
  const location = useLocation();
  let paymentType = watch("paymentType");

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  // set value for store
  setValue("store", token.storeName);

  const handleChangeGetSupplierList = (autoSearchString) => {
    if (autoSearchString !== "") {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  const getAllReturnItems = () => {
    // table selected fetch items
    let paymentMode;
    if (paymentType === "Credit") {
      paymentMode = false;
    } else if (paymentType === "Cash") {
      paymentMode = true;
    }
    let grnId;
    for (let obj of selectedItems) {
      grnId = obj.GRNId;
    }
    let tableObj = {
      cashCredit: paymentMode,
      grnId: grnId,
      storeId: storeId,
    };
    getGrnReturnListItems(tableObj)
      .then((response) => response.data)
      .then((res) => {
        let tempArr = [...res.result];
        let reqArr = [];
        for (let obj of tempArr) {
          let reqObj = {};
          reqObj.GRNId = obj.GRNId;
          reqObj.GRNNumber = obj.GRNNumber;
          reqObj.ItemId = obj.ItemId;
          reqObj.ItemCode = obj.ItemCode;
          reqObj.ItemName = obj.ItemName;
          reqObj.GRNQty = obj.GRNQty;
          reqObj.BatchCode = obj.BatchCode;
          reqObj["Return Quantity"] = "";
          reqObj.BatchExpiryDate = obj.BatchExpiryDate;
          reqObj.UnitMRP = obj.UnitMRP;
          reqObj.Conversion = obj.Conversion;
          reqObj.PurchaseRate = obj.PurchaseRate;
          reqObj.UnitPurchaseRate = obj.UnitPurchaseRate;
          reqObj.OctroiPer = obj.OctroiPer;
          reqObj.OctroiAmt = obj.OctroiAmt;
          reqObj.VatPercentage = obj.VatPercentage;
          reqObj.VatAmount = obj.VatAmount;
          reqObj.TaxAmount = obj.TaxAmount;
          reqObj.OtherTaxAmount = obj.OtherTaxAmount;
          reqObj.IsConsignment = obj.IsConsignment;
          reqObj.BatchesApplicable = obj.BatchesApplicable;
          reqObj.GRNDetailId = obj.VatPercentage;
          reqObj.UnitLandedNetRate = obj.UnitLandedNetRate;
          reqObj.BalanceQty = obj.BalanceQty;
          reqObj.DiscAmount = obj.DiscAmount;
          reqObj.DiscPercent = obj.DiscPercent;
          reqObj.NetAmount = 0;
          reqObj.TotalAmount = 0;
          reqArr.push(reqObj);
        }
        setSelectedTableItems(reqArr);
      })
      .catch((error) => {});
  };

  function postReturnitem(obj) {
    postGRNReturnItems(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setSupplierId(null);
        reset(defaultValues);
        setFinalData({});
        setSelectedTableItems([]);
        setSelectedTableModifiedValue([]);
        setSelectedItems([]);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        handleClosePost();
      });
  }
  let paymentMode;
  if (paymentType === "Credit") {
    paymentMode = 0;
  } else if (paymentType === "Cash") {
    paymentMode = 1;
  }

  function addRecord() {
    setOpenPost(false);
    postReturnitem(finalData);
  }

  const onSubmitDataHandler = (dataObj) => {
    let temp = [...selectedTableItems];
    let requiredArr = [];
    for (let obj of temp) {
      let expiryDate = obj.BatchExpiryDate;
      let date;
      if (expiryDate) {
        let fromDateStr = expiryDate.replaceAll("/", "-").slice(0, -6);
        date = fromDateStr;
      }
      let tempObj = {
        amount: obj.UnitMRP,
        batchCode: obj.BatchCode,
        batchExpiryDate: date,
        conversion: obj.Conversion,
        GRNQty: obj.GRNQty,
        BalanceQty: obj.BalanceQty,
        grnId: obj.GRNId,
        isConsignment: obj.IsConsignment,
        itemId: obj.ItemId,
        landedRate: obj.UnitLandedNetRate,
        mrp: obj.UnitMRP,
        netAmount: obj.NetAmount,
        octroiAmt: obj.OctroiAmt,
        octroiPer: obj.OctroiPer,
        otherTaxAmount: obj.OtherTaxAmount,
        purchaseRate: obj.PurchaseRate,
        remarks: "",
        returnQty: obj["Return Quantity"],
        taxAmount: obj.TaxAmount,
        unitPurchaseRate: obj.UnitPurchaseRate,
        vatAmount: obj.VatAmount,
        vatPercentage: obj.VatPercentage,
      };
      requiredArr.push(tempObj);
    }
    let tempReqArr = [];
    for (let i = 0; i < requiredArr.length; i++) {
      if (requiredArr[i].returnQty > 0) {
        tempReqArr.push(requiredArr[i]);
      }
    }

    for (let i = 0; i < temp.length; i++) {
      if (
        temp[i].returnQty === "" ||
        temp[i].returnQty > temp[i].GRNQty ||
        temp[i].returnQty > temp[i].BalanceQty
      ) {
        temp[i].color = "#D11A2A";
      } else {
        temp[i].color = "#0B83A5";
      }
    }

    let selectedReturnDate = getValues("returnDate");
    for (let obj of requiredArr) {
      if (obj.returnQty === "") {
        setOpenPost(false);
        warningAlert("Please Enter Return Quantity");
      } else if (obj.returnQty > obj.GRNQty || obj.returnQty > obj.BalanceQty) {
        setOpenPost(false);
        errorAlert("Return quantity Exceed!");
      } else {
        const postObj = {
          menuId: location?.state?.menuId,
          privilege: privilege,
          addedBy: token.userId,
          cash_credit: paymentMode,
          docNo: "",
          grnReturnAmount: 0,
          grnReturnDate: selectedReturnDate,
          grnReturnDetailsDto: tempReqArr,
          isCancelled: true,
          isVerified: true,
          narration: "",
          storeId: token.storeId,
          supplierId: supplierId,
          totalAmount: dataObj.totalAmount,
          totalApprovedAmt: 0,
          totalItemTaxAmount: 0,
          totalOctroiAmount: 0,
          totalOtherTaxAmount: 0,
          totalVatAmount: 0,
        };
        setFinalData(postObj);
        setOpenPost(true);
      }
    }
  };

  useEffect(() => {
    // fetch table
    getAllReturnItems();
  }, [selectedItems, paymentType]);

  useEffect(() => {}, [selectedTableModifiedValue]);

  const handleReturnQty = (e, index, row) => {
    let temp = [...selectedTableItems];
    const selectedItemRow = temp[index];
    let totalAmount = e.target.value * Number(row.PurchaseRate);
    let discountAmt = (Number(row.DiscPercent) * totalAmount) / 100;
    let discountedAmt = totalAmount - discountAmt;
    let gstAmt = (Number(discountedAmt) * Number(row.VatPercentage)) / 100;
    let netAmt = totalAmount - discountAmt + Number(gstAmt);

    selectedItemRow.ItemName = row.ItemName;
    selectedItemRow.BatchCode = row.BatchCode;
    selectedItemRow.BatchExpiryDate = row.BatchExpiryDate;
    selectedItemRow.GRNQty = row.GRNQty;
    selectedItemRow.BalanceQty = row.BalanceQty;
    selectedItemRow["Return Quantity"] = e.target.value;
    selectedItemRow.DiscPercent = row.DiscPercent;
    selectedItemRow.DiscAmount = discountAmt.toFixed(2);
    selectedItemRow.PurchaseRate = row.PurchaseRate;
    selectedItemRow.VatPercentage = row.VatPercentage;
    selectedItemRow.VatAmount = gstAmt.toFixed(2);
    selectedItemRow.TotalAmount = totalAmount.toFixed(2);
    selectedItemRow.UnitMRP = totalAmount.toFixed(2);
    selectedItemRow.NetAmount = netAmt.toFixed(2);
    selectedItemRow.color = "";
    let quantityVal = e.target.value;

    if (quantityVal) {
      if (
        quantityVal === "" ||
        quantityVal > row.GRNQty ||
        quantityVal > row.BalanceQty
      ) {
        selectedItemRow.color = "#D11A2A";
        setOpenPost(false);
      } else {
        selectedItemRow.color = "#0B83A5";
      }
    }
    temp[index] = selectedItemRow;
    setSelectedTableItems(temp);
  };
  function renderInput(row, index, header) {
    return (
      <>
        <div>
          <input
            type="number"
            defaultValue={row.returnQty}
            className="w-20 h-5 border rounded border-customBlue text-center"
            name={`returnQty${index}`}
            {...register(`returnQty${index}`)}
            onChange={(e) => {
              handleReturnQty(e, index, row);
              if (e.target.value === "0") {
                setValue(`returnQty${index}`, "");
              } else {
                setValue(`returnQty${index}`, e.target.value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            style={{ borderColor: `${row.color}` }}
          />
          <h1>
            {row.color === "#D11A2A" && row.returnQty !== "" ? (
              <span className="text-customRed text-[11px]">
                Quantity Exceed
              </span>
            ) : (
              ""
            )}
          </h1>
        </div>
      </>
    );
  }
  useEffect(() => {
    let temp = [...selectedTableItems];

    let totalAmount = 0;
    let netAmount = 0;

    for (let obj of temp) {
      let tempObj = {};
      tempObj.totalAmount = totalAmount += Number(obj.TotalAmount);
      tempObj.netAmount = netAmount += Number(obj.NetAmount);

      setValue("totalAmount", totalAmount);
      setValue("netAmount", netAmount);
    }
  }, [selectedTableItems]);
  return (
    <>
      <h1 className="text-xl font-bold mt-12 text-center">GRN Return</h1>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
          <div>
            <InputField
              control={control}
              name="store"
              label="Store"
              disabled={true}
            />
          </div>
          <div>
            <SearchDropdown
              control={control}
              searchIcon={true}
              isDisabled={false}
              isSearchable={true}
              isClearable={true}
              error={errors.supplier}
              name="supplierName"
              placeholder="Supplier Name*"
              dataArray={supplierList}
              handleInputChange={handleChangeGetSupplierList}
              inputRef={{
                ...register(`supplierName`, {
                  onChange: (e) => {
                    if (e.target.value === null) {
                      setSupplierId(null);
                    } else {
                      setSupplierId(e.target.value.id);
                    }
                  },
                }),
              }}
            />
          </div>

          <div className="flex items-center">
            <label className="font-bold text-sm xl:text-base 2xl:text-lg">
              Payment Type: &nbsp;
            </label>
            <div className="mt-1.5">
              <RadioField
                control={control}
                name="paymentType"
                dataArray={paymentTypeObj}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            <DatePickerFieldNew
              control={control}
              name="returnDate"
              label="Return Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("returnDate", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />

            <div className="whitespace-nowrap">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction ? (
                    <>
                      {obj.action === "Get GRN" ? (
                        <CommonButton
                          type="button"
                          className="bg-customBlue text-white"
                          label="Get GRN"
                          onClick={() => {
                            if (supplierId === null) {
                              warningAlert("Please Select Supplier First");
                            } else {
                              OpenStoreGRNReturnModal();
                            }
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              ))}
            </div>

            {/* </div> */}
          </div>
        </div>
        {selectedTableItems.length > 0 ? (
          <div>
            <CommonDynamicTableNew
              dataResult={selectedTableItems}
              highlightRow={false}
              removeHeaders={["ItemId", "GRNId", "GRNDetailId"]}
              editableColumns={["Return Quantity"]}
              renderInput={renderInput}
              tableClass={"h-[300px]"}
            />
          </div>
        ) : (
          <h1 className="font-semibold flex justify-center my-24 ">
            No Records Found...
          </h1>
        )}

        <div className="grid grid-cols-7 gap-2">
          <div>
            <InputField control={control} name="itemTax" label="Item Tax" />
          </div>
          <div>
            <InputField control={control} name="otherTax" label="Other" />
          </div>
          <div>
            <InputField
              control={control}
              name="totalAmount"
              label="Total Amount"
              disabled
            />
          </div>
          <div>
            <InputField
              control={control}
              name="netAmount"
              label="Net Amount"
              disabled
            />
          </div>
          <div className="col-span-3">
            <InputField control={control} name="remarks" label="Remarks" />
          </div>
        </div>
        <div className="mt-2 flex justify-end gap-2">
          {userActions.map((obj) => (
            <>
              {!obj.isAction ? (
                <>
                  {obj.action === "GRN Return List" ? (
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="GRN Return List"
                      onClick={() => {
                        navigate(obj?.menuKey, {
                          state: { menuId: obj.menuId },
                        });
                      }}
                    />
                  ) : null}

                  {obj.action === "Create" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        onClick={() => {
                          setValue("supplierName", null);
                          setValue("paymentType", "Credit");
                          setValue("itemTax", "");
                          setValue("otherTax", "");
                          setValue("totalAmount", "");
                          setValue("netAmount", "");
                          setValue("remarks", "");
                          setSelectedItems([]);
                          setSelectedTableItems([]);
                          setSelectedTableModifiedValue([]);
                        }}
                      />

                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                        onClick={() => {
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>
      {openStoreGRNReturn ? (
        <GRNReturnModal
          openStoreGRNReturn={openStoreGRNReturn}
          setOpenStoreGRNReturn={setOpenStoreGRNReturn}
          supplierId={supplierId}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          paymentMode={paymentMode}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure Want To Add This Record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
};

export default GRNReturn;
