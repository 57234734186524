import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import {
  deletePews,
  getAllPewsScore,
  pewsScoreService,
} from "../../../services/cliinicalChartServices/pewsServices/PewsServices";

import CheckBoxField from "./CheckboxField";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  deleteAlert,
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";

export default function Pews(props) {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const defaultValues = {
    playingappropriate: false,
    sleeping: false,
    irritable: false,
    reducedresponsetopain: false,
    lethargicConfused: false,
    pink: false,
    capillaryrefill12sec: false,
    paledusky: false,
    capillaryrefill3sec: false,
    graycyanotic: false,
    capillaryrefill4sec: false,
    tachycardianormal20abovenormalrate: false,
    grayccyanoticandmolted: false,
    capillaryrefill5secabove: false,
    tachycardianormal30abovenormalrate: false,
    bradcardia: false,
    withnormalparameters: false,
    normal10parameters: false,
    usingaccessorymuscles: false,
    Fio302Or3Litersmin: false,
    normal20parameters: false,
    retractions: false,
    Fio402Or6Litersmin: false,
    blownormalparametersretractions: false,
    Fio502Or8Liters: false,
  };

  const [behaviorScore, setBehaviorScore] = useState(null);
  const [cardiovascularScore, setcardiovascularScore] = useState(null);
  const [respiratoryScore, setRespiratoryScore] = useState(null);
  const [finalData, setFinalData] = useState();
  const [openPost, setOpenPost] = useState(false);
  const [requiredArrBehaviour, setRequiredArrBehaviour] = useState([]);

  const [deleteObj, setDeleteObj] = useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);

  const [
    requiredArrcardiovascularScore,
    setRequiredArrcardiovascularScore,
  ] = React.useState([]);
  const [
    requiredArrRespiatoryScore,
    setRequiredArrRespiratoryScore,
  ] = React.useState([]);
  const [pewsList, setPewsList] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  let finalPews;
  let alertMsg = "";

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const pewsDivElement = useRef();

  let admissionId = props.displayInfo?.AdmissionId;

  //calculate BehaviorScore
  const handleCheckBehaviorScore = (checkBoxVal) => {
    let behaviorScoreFlag = watch(checkBoxVal);

    if (behaviorScoreFlag === true) {
      let temp = [...requiredArrBehaviour];
      temp.push(`${checkBoxVal}`);
      setRequiredArrBehaviour(temp);
    }
    if (behaviorScoreFlag === false) {
      let temp = [...requiredArrBehaviour];
      let filterArr = temp.filter((item) => item !== checkBoxVal);

      setRequiredArrBehaviour(filterArr);
    }
  };

  //calculate cardiovascular Score
  const handleCheckCardiovascularScore = (checkBoxVal) => {
    let cardiovascularScoreFlag = watch(checkBoxVal);

    if (cardiovascularScoreFlag === true) {
      let temp = [...requiredArrcardiovascularScore];
      temp.push(`${checkBoxVal}`);
      setRequiredArrcardiovascularScore(temp);
    }
    if (cardiovascularScoreFlag === false) {
      let temp = [...requiredArrcardiovascularScore];
      let filterArr = temp.filter((item) => item !== checkBoxVal);
      setRequiredArrcardiovascularScore(filterArr);
    }
  };

  // calculate RespiatoryScore
  const handleCheckRespiratoryScore = (checkBoxVal) => {
    let RespiratoryScoreFlag = watch(checkBoxVal);
    if (RespiratoryScoreFlag === true) {
      let temp = [...requiredArrRespiatoryScore];
      temp.push(`${checkBoxVal}`);
      setRequiredArrRespiratoryScore(temp);
    }
    if (RespiratoryScoreFlag === false) {
      let temp = [...requiredArrRespiatoryScore];
      let filterArr = temp.filter((item) => item !== checkBoxVal);
      setRequiredArrRespiratoryScore(filterArr);
    }
  };

  // const onSubmit = () => {
  //   if (
  //     props.displayInfo?.AdmissionId === undefined ||
  //     props.displayInfo?.AdmissionId === null || props.admissionId === null || props.admissionId === undefined
  //   ) {
  //     warningAlert("Please Select Patient...!");
  //     // setOpenPost(false);
  //   } else if (behaviorScore === null) {
  //     warningAlert("Please Add Behavior Score...!");
  //   }
  // };

  const onSubmitDataHandler = (data) => {
    let playingappropriate = getValues("playingappropriate");
    let sleeping = getValues("sleeping");
    let irritable = getValues("irritable");
    let lethargicConfused = getValues("lethargicConfused");
    let reducedresponsetopain = getValues("reducedresponsetopain");
    let pink = getValues("pink");
    let capillaryrefill12sec = getValues("capillaryrefill12sec");
    let paledusky = getValues("paledusky");
    let capillaryrefill3sec = getValues("capillaryrefill3sec");
    let graycyanotic = getValues("graycyanotic");
    let capillaryrefill4sec = getValues("capillaryrefill4sec");
    let tachycardianormal20abovenormalrate = getValues(
      "tachycardianormal20abovenormalrate"
    );
    let grayccyanoticandmolted = getValues("grayccyanoticandmolted");
    let capillaryrefill5secabove = getValues("capillaryrefill5secabove");
    let tachycardianormal30abovenormalrate = getValues(
      "tachycardianormal30abovenormalrate"
    );
    let bradcardia = getValues("bradcardia");
    let withnormalparameters = getValues("withnormalparameters");
    let normal10parameters = getValues("normal10parameters");
    let usingaccessorymuscles = getValues("usingaccessorymuscles");
    let Fio302Or3Litersmin = getValues("Fio302Or3Litersmin");
    let normal20parameters = getValues("normal20parameters");
    let retractions = getValues("retractions");
    let Fio402Or6Litersmin = getValues("Fio402Or6Litersmin");
    let blownormalparametersretractions = getValues(
      "blownormalparametersretractions"
    );
    let Fio502Or8Liters = getValues("Fio502Or8Liters");

    if (finalPews >= 5) {
      alertMsg = "Please Call Doctor For Consultation";
    } else {
      alertMsg = "No Need to Call Doctor For Consultation";
    }
    if (props.displayInfo?.AdmissionId !== null || props.admissionId) {
      let postPewsObj = {
        // addedBy: getUserId.userId,
        menuId: props.menuId,
        privilege: props.privilege,
        admissionId: admissionId || props.admissionId,
        playingAppropriate: playingappropriate,
        sleeping: sleeping,
        irritable: irritable,
        reducedResponseToPlain: reducedresponsetopain,
        lethargicConfused: lethargicConfused,
        pink: pink,
        capillaryRefill2Sec: capillaryrefill12sec,
        paleOrDusky: paledusky,
        capillaryRefill3Sec: capillaryrefill3sec,
        greyOrCyanotic: graycyanotic,
        capillaryRefill4Sec: capillaryrefill4sec,
        tachycardiaOf20: tachycardianormal20abovenormalrate,
        greyOrCcyanoticAndMotled: grayccyanoticandmolted,
        capillaryRefill5Sec: capillaryrefill5secabove,
        tachycardiaOf30: tachycardianormal30abovenormalrate,
        bradycardia: bradcardia,
        withinNormalParameters: withnormalparameters,
        above10NormalParameter: normal10parameters,
        usingAccessoryMuscles: usingaccessorymuscles,
        fiO2OrLitersMin30: Fio302Or3Litersmin,
        above20NormalParameter: normal20parameters,
        retractions: retractions,
        fiO2OrLitersMin40: Fio402Or6Litersmin,
        belowNormalParameters: blownormalparametersretractions,
        fiO2OrLitersMin50: Fio502Or8Liters,

        behaviour: behaviorScore,
        cardioVascular: cardiovascularScore,
        respiratoryScore: respiratoryScore,
      };
      setOpenPost(true);
      setFinalData(postPewsObj);
    } else if (
      props.displayInfo?.AdmissionId === null ||
      (props.admissionId &&
        behaviorScore === null &&
        cardiovascularScore === null)
    ) {
      setOpenPost(false);
    }
  };

  function postPewsScore(postObj) {
    pewsScoreService(postObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          getPewsListing(props.displayInfo?.AdmissionId || props.admissionId);
          reset(defaultValues);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postPewsScore(finalData);
  }

  useEffect(() => {
    getPewsListing();

    reset(defaultValues);
  }, [props.displayInfo?.AdmissionId || props.admissionId]);

  const getPewsListing = () => {
    getAllPewsScore(props.displayInfo?.AdmissionId || props.admissionId)
      .then((response) => response.data)
      .then((res) => {
        setPewsList(res.result);
      });
  };
  useEffect(() => {
    const subscription = watch((data) => {
      if (data.playingappropriate === true) {
        setBehaviorScore(0);
      } else if (data.sleeping === true) {
        setBehaviorScore(1);
      } else if (data.irritable === true) {
        setBehaviorScore(2);
      } else if (
        data.reducedresponsetopain === true ||
        data.lethargicConfused === true
      ) {
        setBehaviorScore(3);
      } else {
        setBehaviorScore(null);
      }

      if (data.pink === true || data.capillaryrefill12sec === true) {
        setcardiovascularScore(0);
      } else if (data.paledusky === true || data.capillaryrefill3sec === true) {
        setcardiovascularScore(1);
      } else if (
        data.graycyanotic === true ||
        data.capillaryrefill4sec === true ||
        data.tachycardianormal20abovenormalrate === true
      ) {
        setcardiovascularScore(2);
      } else if (
        data.grayccyanoticandmolted === true ||
        data.capillaryrefill5secabove === true ||
        data.tachycardianormal30abovenormalrate === true ||
        data.bradcardia
      ) {
        setcardiovascularScore(3);
      } else {
        setcardiovascularScore(null);
      }

      if (data.withnormalparameters === true) {
        setRespiratoryScore(0);
      } else if (
        data.normal10parameters === true ||
        data.usingaccessorymuscles === true ||
        data.Fio302Or3LitersminFlag === true
      ) {
        setRespiratoryScore(1);
      } else if (
        data.normal20parameters === true ||
        data.retractions === true ||
        data.Fio402Or6Litersmin === true
      ) {
        setRespiratoryScore(2);
      } else if (
        data.blownormalparametersretractions === true ||
        data.Fio502Or8Liters === true
      ) {
        setRespiratoryScore(3);
      } else {
        setRespiratoryScore(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    calculatePews(behaviorScore, cardiovascularScore, respiratoryScore);
  }, [behaviorScore, cardiovascularScore, respiratoryScore]);

  function calculatePews(behaviorScore, cardiovascularScore, respiratoryScore) {
    if (
      Number(behaviorScore) < 5 &&
      Number(cardiovascularScore) < 5 &&
      Number(respiratoryScore) < 5
    ) {
      setBehaviorScore(behaviorScore);
      setcardiovascularScore(cardiovascularScore);
      setRespiratoryScore(respiratoryScore);
      finalPews =
        Number(behaviorScore) +
        Number(cardiovascularScore) +
        Number(respiratoryScore);

      pewsDivElement.current.innerHTML = `Final Mews is ===0 ${finalPews}`;
    } else if (
      Number(behaviorScore) > 0 &&
      Number(cardiovascularScore) > 0 &&
      Number(respiratoryScore) > 0
    ) {
      finalPews =
        Number(behaviorScore) +
        Number(cardiovascularScore) +
        Number(respiratoryScore);

      pewsDivElement.current.innerHTML = `Final Mews is > 0 ${finalPews}`;
    }

    if (finalPews >= 5) {
      pewsDivElement.current.innerHTML = ` <div class="flex  items-center">
      <p  class=" text-gray-700 px-2 font-bold">PEWS  </p>
         <div class="w-8 h-8 rounded-full border-2 border-red-500 flex justify-center  bg-red-500 items-center text-white">

        <p
          class=" text-white py-2"
        >
        ${finalPews}
        </p>

       
      </div>
      <p class="px-2 font-bold text-red-500">Please Call Doctor For Consultation</p>
      `;
    } else if (finalPews < 5) {
      pewsDivElement.current.innerHTML = `
      <div class="flex  items-center">
        <p  class=" text-gray-700 px-2 font-bold">PEWS</p>
           <div class="w-8 h-8 rounded-full border-2 border-green-500 flex justify-center  bg-green-500 items-center">

          <p
            class=" text-white py-2"
          >
          ${finalPews}
          </p>

         
        </div>
        <p class="px-2 font-bold text-green-500">No Need to Call Doctor For Consultation</p>
      `;
    }
  }

  //Behavior score watch
  let playingAppropriateFlag =
    watch("sleeping") ||
    watch("irritable") ||
    watch("reducedresponsetopain") ||
    watch("lethargicConfused");

  let sleepingFlag =
    watch("playingappropriate") ||
    watch("irritable") ||
    watch("reducedresponsetopain") ||
    watch("lethargicConfused");

  let irritableFlag =
    watch("playingappropriate") ||
    watch("sleeping") ||
    watch("reducedresponsetopain") ||
    watch("lethargicConfused");

  let reducedresponsetopainFlag =
    watch("playingappropriate") || watch("sleeping") || watch("irritable");

  let lethargicConfusedFlag =
    watch("playingappropriate") || watch("sleeping") || watch("irritable");

  //Cardiovascula score watch

  let pinkFlag =
    watch("paledusky") ||
    watch("capillaryrefill3sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");
  let capillaryrefill12secFlag =
    watch("paledusky") ||
    watch("capillaryrefill3sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");

  let paleduskyFlag =
    watch("capillaryrefill12sec") ||
    watch("pink") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");

  let capillaryrefill3secFlag =
    watch("pink") ||
    watch("capillaryrefill12sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");

  let graycyanoticFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");
  let capillaryrefill4secFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");
  let tachycardianormal20abovenormalrateFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("grayccyanoticandmolted") ||
    watch("capillaryrefill5secabove") ||
    watch("tachycardianormal30abovenormalrate") ||
    watch("bradcardia");

  let grayccyanoticandmoltedFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate");

  let capillaryrefill5secaboveFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate");

  let tachycardianormal30abovenormalrateFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate");

  let bradcardiaFlag =
    watch("pink") ||
    watch("paledusky") ||
    watch("capillaryrefill12sec") ||
    watch("capillaryrefill3sec") ||
    watch("graycyanotic") ||
    watch("capillaryrefill4sec") ||
    watch("tachycardianormal20abovenormalrate");
  let withnormalparametersFlag =
    watch("normal10parameters") ||
    watch("usingaccessorymuscles") ||
    watch("normal20parameters") ||
    watch("Fio302Or3Litersmin") ||
    watch("Fio402Or6Litersmin") ||
    watch("retractions") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");

  let normal10parametersFlag =
    watch("withnormalparameters") ||
    watch("normal20parameters") ||
    watch("Fio402Or6Litersmin") ||
    watch("retractions") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");
  let usingaccessorymusclesFlag =
    watch("withnormalparameters") ||
    watch("normal20parameters") ||
    watch("Fio402Or6Litersmin") ||
    watch("retractions") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");
  let Fio302Or3LitersminFlag =
    watch("withnormalparameters") ||
    watch("normal20parameters") ||
    watch("Fio402Or6Litersmin") ||
    watch("retractions") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");

  let normal20parametersFlag =
    watch("withnormalparameters") ||
    watch("normal10parameters") ||
    watch("usingaccessorymuscles") ||
    watch("Fio302Or3Litersmin") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");
  let retractionsFlag =
    watch("withnormalparameters") ||
    watch("normal10parameters") ||
    watch("usingaccessorymuscles") ||
    watch("Fio302Or3Litersmin") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");

  let Fio402Or6LitersminFlag =
    watch("withnormalparameters") ||
    watch("normal10parameters") ||
    watch("usingaccessorymuscles") ||
    watch("Fio302Or3Litersmin") ||
    watch("blownormalparametersretractions") ||
    watch("Fio502Or8Liters");
  let blownormalparametersretractionsFlag =
    watch("normal20parameters") ||
    watch("Fio402Or6Litersmin") ||
    watch("retractions") ||
    watch("withnormalparameters") ||
    watch("normal10parameters") ||
    watch("usingaccessorymuscles") ||
    watch("Fio302Or3Litersmin");
  let Fio502Or8LitersFlag =
    watch("normal20parameters") ||
    watch("Fio402Or6Litersmin") ||
    watch("retractions") ||
    watch("withnormalparameters") ||
    watch("normal10parameters") ||
    watch("usingaccessorymuscles") ||
    watch("Fio302Or3Litersmin");

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deletePews(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            getPewsListing();
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true && obj.action === "Delete" ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    let deleteObjVal = {};

                    deleteObjVal.Id = row.Id;

                    deleteObjVal.action = obj.action;

                    setDeleteObj(deleteObjVal);

                    //open the delete confirmation modal
                    handleOpenDeleteConfirm();
                  }}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };
  return (
    <div className="w-full pt-1">
      <h1 class="text-sm font-semibold whitespace-nowrap"> PEWS</h1>
      <div className="grid grid-cols-1 gap-4 w-full ">
        {pewsList.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={pewsList}
            tableClass={"rounded h-56 xl:h-72 border"}
            highlightRow={false}
            removeHeaders={["visitId","Id"]}
            renderActions={renderActions}
          />
        ) : (
          <h3 className="flex justify-center my-5 font-bold text-gray-600">
            No Records Found...
          </h3>
        )}
      </div>
      <form
        onSubmit={handleSubmit(onSubmitDataHandler)}
        className="grid grid-cols-1 md:grid-cols-1  gap-1"
      >
        <fieldset className="border border-gray-300 text-left lg:px-4 mt-1 md:px-4 md:ml-0 md:mr-0 py-2 rounded  bg-white">
          <legend className="md:mx-2 md:px-2 lg:px-2 font-bold  text-base text-gray-700">
            <div className="flex flex-nowrap items-center">
              Behavior Score
              {playingAppropriateFlag ||
              sleepingFlag ||
              irritableFlag ||
              reducedresponsetopainFlag ||
              lethargicConfusedFlag ? (
                <p className="w-5 h-5 ml-2 rounded-full   flex justify-center  bg-[#0081a8] items-center text-white text-xs">
                  {behaviorScore}
                </p>
              ) : null}
            </div>
          </legend>

          <div className="grid grid-cols-4 w-full ">
            <div>
              <div className="font-base ">0</div>
              <fieldset
                disabled={playingAppropriateFlag}
                className="font-base "
                onChange={() => {
                  let checkBox = "playingappropriate";
                  handleCheckBehaviorScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="playingappropriate"
                  label="Playing / Appropriate"
                  style={
                    playingAppropriateFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className=" w-full">
              <div className="font-base">1</div>
              <fieldset
                disabled={sleepingFlag}
                onChange={() => {
                  let checkBox = "sleeping";
                  handleCheckBehaviorScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="sleeping"
                  label="Sleeping"
                  value="Sleeping"
                  error={errors.sleeping}
                  style={
                    sleepingFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className=" w-full">
              <div className="font-base">2</div>
              <fieldset
                disabled={irritableFlag}
                onChange={() => {
                  let checkBox = "irritable";
                  handleCheckBehaviorScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="irritable"
                  label="Irritable"
                  value="Irritable"
                  error={errors.irrritable}
                  style={
                    irritableFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className="">
              <div className="font-base">3</div>
              <fieldset
                disabled={reducedresponsetopainFlag}
                onChange={() => {
                  let checkBox = "reducedresponsetopain";
                  handleCheckBehaviorScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="reducedresponsetopain"
                  label="Reduced Response To Pain"
                  error={errors.reducedresponsetopain}
                  style={
                    reducedresponsetopainFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <fieldset
                disabled={lethargicConfusedFlag}
                onChange={() => {
                  let checkBox = "lethargicConfused";
                  handleCheckBehaviorScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="lethargicConfused"
                  label="Lethargic / Confused"
                  error={errors.lethargicConfused}
                  style={
                    lethargicConfusedFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
          </div>
        </fieldset>
        <fieldset className="border border-gray-300 text-left lg:px-4 mt-1 md:px-4 md:ml-0 md:mr-0 py-2 rounded  bg-white">
          <legend className="md:mx-2 md:px-2 lg:px-2 font-bold  text-base text-gray-700">
            <div className="flex flex-nowrap items-center">
              Cardiovascular Score
              {pinkFlag ||
              capillaryrefill12secFlag ||
              paleduskyFlag ||
              capillaryrefill3secFlag ||
              graycyanoticFlag ||
              capillaryrefill4secFlag ||
              tachycardianormal20abovenormalrateFlag ||
              grayccyanoticandmoltedFlag ||
              capillaryrefill5secaboveFlag ||
              tachycardianormal30abovenormalrateFlag ||
              bradcardiaFlag ? (
                <p className="w-5 h-5 ml-2 rounded-full   flex justify-center  bg-[#0081a8] items-center text-white text-xs">
                  {cardiovascularScore}
                </p>
              ) : null}
            </div>
          </legend>

          <div className="grid grid-cols-4 w-full ">
            <div className="">
              <div className="font-base ">0</div>
              <fieldset
                disabled={pinkFlag}
                onChange={() => {
                  let checkBox = "pink";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="pink"
                  label="Pink"
                  value="Pink"
                  style={
                    pinkFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>

              <fieldset
                disabled={capillaryrefill12secFlag}
                onChange={() => {
                  let checkBox = "capillaryrefill12sec";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="capillaryrefill12sec"
                  label="Capillary Refill 1-2 sec"
                  value="Capillary Refill 1-2 sec"
                  style={
                    capillaryrefill12secFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className="">
              <div className="font-base">1</div>
              <fieldset
                disabled={paleduskyFlag}
                onChange={() => {
                  let checkBox = "paledusky";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="paledusky"
                  label="Pale or Dusky"
                  value="Pale or Dusky"
                  style={
                    paleduskyFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>

              <fieldset
                disabled={capillaryrefill3secFlag}
                onChange={() => {
                  let checkBox = "capillaryrefill3sec";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="capillaryrefill3sec"
                  label="Capillary Refill 3 sec"
                  style={
                    capillaryrefill3secFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className="">
              <div className="font-base">2</div>
              <fieldset
                disabled={graycyanoticFlag}
                onChange={() => {
                  let checkBox = "graycyanotic";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="graycyanotic"
                  label="Grey Or Cyanotic"
                  style={
                    graycyanoticFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>

              <fieldset
                disabled={capillaryrefill4secFlag}
                onChange={() => {
                  let checkBox = "capillaryrefill4sec";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="capillaryrefill4sec"
                  label="Capillary Refill 4 sec"
                  style={
                    capillaryrefill4secFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>

              <fieldset
                disabled={tachycardianormal20abovenormalrateFlag}
                onChange={() => {
                  let checkBox = "tachycardianormal20abovenormalrate";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="tachycardianormal20abovenormalrate"
                  label="Tachycardia of 20 Above Normal Rate"
                  value="Tachycardia of 20 Above Normal Rate"
                  style={
                    tachycardianormal20abovenormalrateFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>

            <div className="">
              <div className="font-base">3</div>
              <fieldset
                disabled={grayccyanoticandmoltedFlag}
                onChange={() => {
                  let checkBox = "grayccyanoticandmolted";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="grayccyanoticandmolted"
                  label="Gray Ccyanotic and Molted"
                  value="Gray Ccyanotic and Molted"
                  style={
                    grayccyanoticandmoltedFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <div className="py-2 whitespace-wrap">
                <fieldset
                  disabled={capillaryrefill5secaboveFlag}
                  onChange={() => {
                    let checkBox = "capillaryrefill5secabove";
                    handleCheckCardiovascularScore(checkBox);
                  }}
                >
                  <CheckBoxField
                    control={control}
                    name="capillaryrefill5secabove"
                    label="Capillary Refill 5 sec and Above"
                    value="Capillary Refill 5 sec and Above"
                    style={
                      capillaryrefill5secaboveFlag
                        ? {
                            color: "#818589",
                            fontSize: "13px",
                            alignItems: "flex-start !important",
                            paddingRight: 0,
                            marginRight: 0,
                          }
                        : {
                            fontSize: "13px",
                            alignItems: "flex-start !important",
                            paddingRight: 0,
                            marginRight: 0,
                          }
                    }
                  />
                </fieldset>
              </div>
              <div className="py-2">
                <fieldset
                  disabled={tachycardianormal30abovenormalrateFlag}
                  onChange={() => {
                    let checkBox = "tachycardianormal30abovenormalrate";
                    handleCheckCardiovascularScore(checkBox);
                  }}
                >
                  <CheckBoxField
                    control={control}
                    name="tachycardianormal30abovenormalrate"
                    label="Tachycardia of 30 Above Normal Rate"
                    value="Tachycardia of 30 Above Normal Rate"
                    style={
                      tachycardianormal30abovenormalrateFlag
                        ? {
                            color: "#818589",
                            fontSize: "13px",
                            alignItems: "flex-start !important",
                            paddingRight: 0,
                            marginRight: 0,
                          }
                        : {
                            fontSize: "13px",
                            alignItems: "flex-start !important",
                            paddingRight: 0,
                            marginRight: 0,
                          }
                    }
                  />
                </fieldset>
              </div>
              <fieldset
                disabled={bradcardiaFlag}
                onChange={() => {
                  let checkBox = "bradcardia";
                  handleCheckCardiovascularScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="bradcardia"
                  label="Bradcardia"
                  value="Bradcardia"
                  style={
                    bradcardiaFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
          </div>
        </fieldset>
        <fieldset className="border border-gray-300 text-left mb-2  lg:mx-auto md:px-4 lg:px-4 md:ml-0 md:mr-0 py-2 rounded    bg-white">
          <legend className="md:mx-2 md:px-2 lg:px-2 font-bold  text-base text-gray-700">
            <div className="flex flex-nowrap items-center">
              Respiratory Score
              {withnormalparametersFlag ||
              normal10parametersFlag ||
              usingaccessorymusclesFlag ||
              Fio302Or3LitersminFlag ||
              normal20parametersFlag ||
              retractionsFlag ||
              Fio402Or6LitersminFlag ||
              blownormalparametersretractionsFlag ||
              Fio502Or8LitersFlag ? (
                <p className="w-5 h-5 ml-2 rounded-full   flex justify-center  bg-[#0081a8] items-center text-white text-xs">
                  {respiratoryScore}
                </p>
              ) : null}
            </div>
          </legend>

          <div className="grid grid-cols-4 w-full gap-2">
            <div className="">
              <div className="font-base">0</div>
              <fieldset
                disabled={withnormalparametersFlag}
                onChange={() => {
                  let checkBox = "withnormalparameters";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="withnormalparameters"
                  label="With Normal Parameters"
                  value="With Normal Parameters"
                  style={
                    withnormalparametersFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className="">
              <div className="font-base">1</div>
              <fieldset
                disabled={normal10parametersFlag}
                onChange={() => {
                  let checkBox = "normal10parameters";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="normal10parameters"
                  label="> 10 Normal Parameters"
                  value="> 10 Normal Parameters"
                  style={
                    normal10parametersFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <fieldset
                disabled={usingaccessorymusclesFlag}
                onChange={() => {
                  let checkBox = "usingaccessorymuscles";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="usingaccessorymuscles"
                  label="Using Accessory Muscles"
                  value="Using Accessory Muscles"
                  style={
                    usingaccessorymusclesFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <fieldset
                className="whitespace-nowrap "
                disabled={Fio302Or3LitersminFlag}
                onChange={() => {
                  let checkBox = "Fio302Or3Litersmin";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="Fio302Or3Litersmin"
                  label="30 + %Fio2 Or 3 + Liters / min"
                  value="30 + %Fio2 Or 3 + Liters / min"
                  style={
                    Fio302Or3LitersminFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className="">
              <div className="font-base">2</div>
              <fieldset
                disabled={normal20parametersFlag}
                onChange={() => {
                  let checkBox = "normal20parameters";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="normal20parameters"
                  label="> 20 Normal Parameters"
                  value="> 20 Normal Parameters"
                  style={
                    normal20parametersFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <fieldset
                disabled={retractionsFlag}
                onChange={() => {
                  let checkBox = "retractions";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="retractions"
                  label="Retractions"
                  value="Retractions"
                  style={
                    retractionsFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <fieldset
                disabled={Fio402Or6LitersminFlag}
                onChange={() => {
                  let checkBox = "Fio402Or6Litersmin";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="Fio402Or6Litersmin"
                  label="40 + %Fio2 Or 6 + Liters/min"
                  value="40 + %Fio2 Or 6 + Liters/min"
                  style={
                    Fio402Or6LitersminFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
            </div>
            <div className="w-full ">
              <div className="font-base">3</div>
              <fieldset
                disabled={blownormalparametersretractionsFlag}
                onChange={() => {
                  let checkBox = "blownormalparametersretractions";
                  handleCheckRespiratoryScore(checkBox);
                }}
              >
                <CheckBoxField
                  control={control}
                  name="blownormalparametersretractions"
                  label="b>= Normal Parameters With Retractions"
                  value="b>= Normal Parameters With Retractions"
                  style={
                    blownormalparametersretractionsFlag
                      ? {
                          color: "#818589",
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                      : {
                          fontSize: "13px",
                          alignItems: "flex-start !important",
                          paddingRight: 0,
                          marginRight: 0,
                        }
                  }
                />
              </fieldset>
              <div className="py-3 whitespace-wrap">
                <fieldset
                  disabled={Fio502Or8LitersFlag}
                  onChange={() => {
                    let checkBox = "Fio502Or8Liters";
                    handleCheckRespiratoryScore(checkBox);
                  }}
                >
                  <CheckBoxField
                    control={control}
                    name="Fio502Or8Liters"
                    label="50 + %Fio2 Or 8 + Liters / min"
                    value="50 + %Fio2 Or 8 + Liters / min"
                    style={
                      Fio502Or8LitersFlag
                        ? {
                            color: "#818589",
                            fontSize: "13px",
                            alignItems: "flex-start !important",
                            paddingRight: 0,
                            marginRight: 0,
                          }
                        : {
                            fontSize: "13px",
                            alignItems: "flex-start !important",
                            paddingRight: 0,
                            marginRight: 0,
                          }
                    }
                  />
                </fieldset>
              </div>
              <div className=" w-1/3 "></div>
            </div>
          </div>
        </fieldset>
        <div className="flex gap-2 justify-between">
          <div className="lg:grid lg:grid-cols-1 gap-2 py-2">
            <div name="pews" id="pews" ref={pewsDivElement}></div>
          </div>

          <div className="flex justify-end gap-2">
            {props.userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                      }}
                    />
                    {props.patientInformation?.AllowToGO === true ||
                    props.selectedPatient?.AllowToGO === true ? null : (
                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                        onClick={() => {
                          props.setPrivilege(obj.action);
                          // onSubmit();
                        }}
                      />
                    )}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />

      <ConfirmationModal
        confirmationOpen={deleteconfirm}
        confirmationHandleClose={handleCloseDeleteConfirm}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />
    </div>
  );
}
