import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import { fetchKitList } from "../../../inventory/services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import {
  fetchCSSDKitItemsList,
  fetchCssdkitItems,
  fetchPharmacyKitItemsList,
  fetchPreoperativeInstructions,
  fetchSurgeryInstrumentEquipment,
} from "../../services/otReservationServices/OtReservationServices";
import { fetchServicesSearch } from "../../../commonServices/miscellaneousServices/MiscServices";

const CheckList = (props) => {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    cssdItemKitArr,
    setCssdItemKitArr,
    preOpInstructionArr,
    setPreOpInstructionArr,
    pharmacyItemArr,
    setPharmacyItemArr,
    setDeletedCssdItems,
    setDeletedPharmacyItems,
    equipmentItemArr,
    setEquipmentItemArr,
    deletedEquipmentItems,
    setDeletedEquipmentItems,
    doctorDetailsArr,
  } = props;

  const [cssdkitItems, setCssdkitItems] = useState([]);
  const [cssdListItems, setCssdListItems] = useState([]);
  const [equpimentsListArr, setEqupimentsListArr] = useState([]);
  //
  const [preOpInstructionData, setPreOpInstructionData] = useState([]);
  //
  const [pharmacyKitItems, setPharmacyKitItems] = useState([]);
  const [pharmacyListItems, setPharmacyListItems] = useState([]);

  //

  const {
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    setValue,
    register,
  } = useFormContext();

  function unDuplicateArraySingleValues(array) {
    if (!array || !Array.isArray(array) || array.length === 0) {
      return array;
    }
    return [...new Set(array)];
  }

  function unDuplicateArrayObjects(array, propertyName) {
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      !propertyName
    ) {
      return array;
    }
    let objectArrayKeys = array.map((item) => item[propertyName]);

    let uniqueKeys = unDuplicateArraySingleValues(objectArrayKeys);
    return uniqueKeys.map((key) =>
      array.find((item) => item[propertyName] === key)
    );
  }

  //add CssdItem Data

  const addCssdItemData = () => {
    let ItemKitCssd = getValues("cssdItemKit");
    let QtyCssd = getValues("cssdQty");

    let QtyNumVal = Number(QtyCssd);

    if (ItemKitCssd === null && QtyCssd === "") {
      setError("cssdItemKit", { type: "custom", message: "Required" });
      setError("cssdQty", { type: "custom", message: "Required" });
    } else if (ItemKitCssd === null) {
      setError("cssdItemKit", { type: "custom", message: "Required" });
    } else if (QtyCssd === "") {
      setError("cssdQty", { type: "custom", message: "Required" });
    } else if (ItemKitCssd !== null && QtyCssd !== "") {
      clearErrors("cssdItemKit");
      clearErrors("cssdQty");
    }

    if (ItemKitCssd !== null && QtyCssd !== "") {
      let updateKitQty = cssdListItems.map(({ Qty, ...item }) => ({
        ...item,
        Quantity: Qty * QtyNumVal,
        isDelete: false,
      }));

      let isItemAlreadyExist = false;
      isItemAlreadyExist = cssdItemKitArr.some(
        (item) => !item?.isDelete && item?.Id === updateKitQty[0].Id
      );

      updateKitQty.length < 1 &&
        warningAlert("Items not available for this kit...");

      if (isItemAlreadyExist) {
        warningAlert("Service Already Exists...");
      } else {
        setCssdItemKitArr((prev) => [...prev, ...updateKitQty]);
      }
      setValue("cssdItemKit", "", { shouldValidate: true });
      setValue("cssdQty", "", { shouldValidate: true });
    }
  };

  ////
  const addpreOpInstructionData = () => {
    let PreOpInst = getValues("preOpInstruction");

    if (PreOpInst === null) {
      setError("preOpInstruction", { type: "custom", message: "Required" });
    } else if (PreOpInst !== null) {
      clearErrors("preOpInstruction");
    }

    if (PreOpInst !== null) {
      let PreOpInstObj = {
        id: PreOpInst.id,
        "Item Name": PreOpInst.label,
      };

      let arr = [...preOpInstructionArr];
      arr.push(PreOpInstObj);
      setPreOpInstructionArr(unDuplicateArrayObjects(arr, "id"));
    }
  };
  ////
  const addpharmacyItemData = () => {
    let PharmacyItem = getValues("pharmacyItem");
    let PharmacyQty = getValues("pharmacyItemQty");

    if (PharmacyItem === null && PharmacyQty === "") {
      setError("pharmacyItem", { type: "custom", message: "Required" });
      setError("pharmacyItemQty", { type: "custom", message: "Required" });
    } else if (PharmacyItem === null) {
      setError("pharmacyItem", { type: "custom", message: "Required" });
    } else if (PharmacyQty === "") {
      setError("pharmacyItemQty", { type: "custom", message: "Required" });
    } else if (PharmacyItem !== null && PharmacyQty !== "") {
      clearErrors("pharmacyItem");
      clearErrors("pharmacyItemQty");
    }

    if (PharmacyItem !== null && PharmacyQty !== "") {
      let updateKitQty = pharmacyListItems.map(({ Quantity, ...item }) => ({
        ...item,
        Quantity: Quantity * Number(PharmacyQty),
        isDelete: false,
        kitId: PharmacyItem?.id,
      }));

      let isItemAlreadyExist = false;
      isItemAlreadyExist = pharmacyItemArr.some(
        (item) =>
          !item?.isDelete && item?.ItemId === pharmacyListItems[0].ItemId
      );

      updateKitQty.length < 1 &&
        warningAlert("Items not available for this kit...");

      if (isItemAlreadyExist) {
        warningAlert("Service Already Exists...");
      } else {
        setPharmacyItemArr((prev) => [...prev, ...updateKitQty]);
      }
    }
  };
  ////
  const addEquipmentItemData = () => {
    let EquipmentItem = getValues("equipmentItem");
    let EquipmentQty = getValues("equipmentItemQty");
    console.log("addEquipmentItemData", EquipmentItem, EquipmentQty);

    if (EquipmentItem === null && EquipmentQty === "") {
      setError("equipmentItem", { type: "custom", message: "Required" });
      setError("equipmentItemQty", { type: "custom", message: "Required" });
    } else if (EquipmentItem === null) {
      setError("equipmentItem", { type: "custom", message: "Required" });
    } else if (EquipmentQty === "") {
      setError("equipmentItemQty", { type: "custom", message: "Required" });
    } else if (EquipmentItem !== null && EquipmentQty !== "") {
      clearErrors("equipmentItem");
      clearErrors("equipmentItemQty");
    }

    if (EquipmentItem !== null && EquipmentQty !== "") {
      let updateKitQty = {
        Quantity: Number(EquipmentQty),
        isDelete: false,
        ServiceID: EquipmentItem?.id,
        "Equipment Name": EquipmentItem?.label,
      };

      let isItemAlreadyExist = false;
      isItemAlreadyExist = equipmentItemArr.some(
        (item) => !item?.isDelete && item?.ServiceID === updateKitQty.ServiceID
      );

      if (isItemAlreadyExist) {
        warningAlert("Service Already Exists...");
      } else {
        setEquipmentItemArr((prev) => [...prev, updateKitQty]);
      }
    }
  };
  console.log("equipmentItemArr", equipmentItemArr);
  //cssd kit
  const getCssdkitItems = (e) => {
    if (!!e) {
      fetchCssdkitItems(e, token?.unitId)
        .then((response) => response.data)
        .then((res) => {
          setCssdkitItems(res.result);
        });
    }
  };
  //items against cssd kit
  const getCssdItemist = (kitId) => {
    if (!!kitId) {
      fetchCSSDKitItemsList(kitId)
        .then((response) => response.data)
        .then((res) => {
          setCssdListItems(res.result);
        });
    }
  };
  //items against cssd kit
  const getServicesSearch = (searchString) => {
    if (!!searchString) {
      fetchServicesSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setEqupimentsListArr(res.result);
        });
    }
  };

  useEffect(() => {
    fetchPreoperativeInstructions(props.groupId)
      .then((response) => response.data)
      .then((res) => {
        setPreOpInstructionData(res.result);
      });
  }, [props.groupId]);

  const getPharmacyKit = (searchString) => {
    !!searchString &&
      fetchKitList(searchString)
        .then((response) => response.data)
        .then((res) => {
          setPharmacyKitItems(res.result);
        });
  };
  //items against cssd kit
  const getKitListItems = (kitId) => {
    if (!!kitId) {
      fetchPharmacyKitItemsList(kitId)
        .then((response) => response.data)
        .then((res) => {
          setPharmacyListItems(res.result);
        });
    }
  };

  const renderActions1 = (row, index) => {
    const deleteRow = (index) => {
      let temp = [...cssdItemKitArr];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedCssdItems((prev) => [...prev, ...deletedItems]);
      setCssdItemKitArr(notDeletedItems);
    };

    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <DeleteOutlineOutlinedIcon
            sx={{ color: "#d32f2f " }}
            onClick={() => {
              deleteRow(index);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderInput1 = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...cssdItemKitArr];
      updatedList[index]["Quantity"] = Number(newValue);
      setCssdItemKitArr(updatedList);
    };

    return (
      <>
        {header === "Quantity" ? (
          <span>
            <input
              className={
                row.error !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) => handleTableQuantityChange(index, e.target.value)}
              value={row["Quantity"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
              name={`Quantity${[index]}`}
            />
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderActions2 = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...preOpInstructionArr];
      newTemplateData.splice(index, 1);
      setPreOpInstructionArr(newTemplateData);
    };
    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <DeleteOutlineOutlinedIcon
            sx={{ color: "#d32f2f " }}
            onClick={() => {
              deleteRow(index);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderActions3 = (row, index) => {
    const deleteRow = (index) => {
      // let newTemplateData = [...pharmacyItemArr];
      // newTemplateData.splice(index, 1);
      // setPharmacyItemArr(newTemplateData);

      let temp = [...pharmacyItemArr];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedPharmacyItems((prev) => [...prev, ...deletedItems]);
      setPharmacyItemArr(notDeletedItems);
    };

    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <DeleteOutlineOutlinedIcon
            sx={{ color: "#d32f2f " }}
            onClick={() => {
              deleteRow(index);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderInput3 = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...pharmacyItemArr];
      updatedList[index]["Quantity"] = Number(newValue);
      setPharmacyItemArr(updatedList);
    };

    return (
      <>
        {header === "Quantity" ? (
          <span>
            <input
              className={
                row.error !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) => handleTableQuantityChange(index, e.target.value)}
              value={row["Quantity"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
              name={`Quantity${[index]}`}
            />
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderActions4 = (row, index) => {
    const deleteRow = (index) => {
      let temp = [...equipmentItemArr];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === index ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedEquipmentItems((prev) => [...prev, ...deletedItems]);
      setEquipmentItemArr(notDeletedItems);
    };

    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <DeleteOutlineOutlinedIcon
            sx={{ color: "#d32f2f " }}
            onClick={() => {
              deleteRow(index);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderInput4 = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...equipmentItemArr];
      updatedList[index]["Quantity"] = Number(newValue);
      setEquipmentItemArr(updatedList);
    };

    return (
      <>
        {header === "Quantity" ? (
          <span>
            <input
              className={
                row.error !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) => handleTableQuantityChange(index, e.target.value)}
              value={row["Quantity"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
              name={`Quantity${[index]}`}
            />
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  useEffect(() => {
    if (doctorDetailsArr?.length > 0) {
      const surgeryIds = doctorDetailsArr.map((item) => item.id);

      fetchSurgeryInstrumentEquipment(surgeryIds)
        .then((response) => response.data)
        .then((res) => {
          setEquipmentItemArr(res.result);
        });
    }
  }, []);

  return (
    <>
      <div>
        {/* ///cssd item */}
        <div className="">
          <div className="my-3">
            <span className=" text-gray-700 font-semibold whitespace-nowrap">
              CSSD Item Kit
            </span>
          </div>
          <div className="grid grid-cols-8 gap-3">
            <div className="col-span-2">
              <SearchDropdown
                name="cssdItemKit"
                placeholder="CSSD Item Kit"
                dataArray={cssdkitItems}
                handleInputChange={(e) => {
                  e && getCssdkitItems(e);
                }}
                searchIcon={true}
                error={errors.cssdItemKit}
                control={control}
                isClearable={true}
                inputRef={{
                  ...register("cssdItemKit", {
                    onChange: (e) => {
                      getCssdItemist(e?.target?.value?.id);
                    },
                  }),
                }}
              />
            </div>

            <div className="col-span-2">
              {/* cssdQty */}

              <InputField
                name="cssdQty"
                type="number"
                variant="outlined"
                label="Qty*"
                error={errors.cssdQty}
                control={control}
              />
            </div>

            <div className=" flex gap-3 col-span-4">
              <CommonButton
                className="bg-customGreen text-white"
                onClick={() => {
                  addCssdItemData();
                }}
                label="Add"
              />

              <CommonButton
                className="bg-customGray text-white "
                label="CSSD Print"
              />
            </div>
            {/* table */}
            {cssdItemKitArr.length > 0 ? (
              <div className="w-full mt-2 col-span-8">
                <CommonDynamicTableNew
                  dataResult={cssdItemKitArr}
                  renderActions={renderActions1}
                  renderInput={renderInput1}
                  editableColumns={"Quantity"}
                  tableClass="rounded lg:h-52 md:h-72"
                  removeHeaders={["id", "Image", "Id", "isDelete"]}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* ///Pre Operative Instructions */}
        <div className="">
          <div className="my-3">
            <span className=" text-gray-700 font-semibold whitespace-nowrap">
              Pre Operative Instructions
            </span>
          </div>
          <div className="grid grid-cols-8 gap-3">
            <div className="col-span-2">
              <SearchDropdown
                name="preOpInstruction"
                placeholder="Pre Operative Instructions"
                dataArray={preOpInstructionData}
                handleInputChange={(e) => {}}
                searchIcon={true}
                error={errors.preOpInstruction}
                control={control}
                isClearable={true}
              />
            </div>

            <div className=" flex gap-3 col-span-4">
              <CommonButton
                className="bg-customGreen text-white"
                label="Add"
                onClick={() => {
                  addpreOpInstructionData();
                  setValue("preOpInstruction", null);
                }}
              />
            </div>
            {/* table */}
            {preOpInstructionArr.length > 0 ? (
              <div className="w-full mt-2 col-span-8">
                <CommonDynamicTableNew
                  dataResult={preOpInstructionArr}
                  renderActions={renderActions2}
                  tableClass="rounded lg:h-52 md:h-72"
                  removeHeaders={["id"]}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* ///Pharmacy Item Kit */}
        <div className="">
          <div className="my-3">
            <span className=" text-gray-700 font-semibold whitespace-nowrap">
              Pharmacy Item Kit
            </span>
          </div>
          <div className="grid grid-cols-8 gap-3">
            <div className="col-span-2">
              <SearchDropdown
                name="pharmacyItem"
                placeholder="Pharmacy Kit Item "
                dataArray={pharmacyKitItems}
                handleInputChange={(e) => {
                  getPharmacyKit(e);
                }}
                searchIcon={true}
                error={errors.pharmacyItem}
                control={control}
                isClearable={true}
                inputRef={{
                  ...register("pharmacyItem", {
                    onChange: (e) => {
                      getKitListItems(e?.target?.value?.id);
                    },
                  }),
                }}
              />
            </div>

            <div className="col-span-2">
              {/* cssdQty */}

              <InputField
                name="pharmacyItemQty"
                type="number"
                variant="outlined"
                label="Qty*"
                error={errors.pharmacyItemQty}
                control={control}
              />
            </div>

            <div className=" flex gap-3 col-span-4">
              <CommonButton
                className="bg-customGreen text-white"
                label="Add"
                onClick={() => {
                  addpharmacyItemData();
                  setValue("pharmacyItem", null);
                  setValue("pharmacyItemQty", "");
                }}
              />

              <CommonButton
                className="bg-customGray text-white "
                label="Pharmacy Print"
              />
            </div>
            {/* table */}
            {pharmacyItemArr.length > 0 ? (
              <div className="w-full mt-2 col-span-8">
                <CommonDynamicTableNew
                  dataResult={pharmacyItemArr}
                  renderActions={renderActions3}
                  renderInput={renderInput3}
                  tableClass="rounded lg:h-52 md:h-72"
                  removeHeaders={[
                    "id",
                    "Kit Name",
                    "isDelete",
                    "ItemId",
                    "kitId",
                  ]}
                  editableColumns={"Quantity"}
                />
              </div>
            ) : null}
          </div>
        </div>

        {/* ///Equipment  Kit */}
        <div className="">
          <div className="my-3">
            <span className=" text-gray-700 font-semibold whitespace-nowrap">
              Equipment Items
            </span>
          </div>
          <div className="grid grid-cols-8 gap-3">
            <div className="col-span-2">
              <SearchDropdown
                name="equipmentItem"
                placeholder="Equipment Item "
                dataArray={equpimentsListArr}
                handleInputChange={(e) => {
                  getServicesSearch(e);
                }}
                searchIcon={true}
                error={errors.equipmentItem}
                control={control}
                isClearable={true}
                // inputRef={{
                //   ...register("equipmentItem", {
                //     onChange: (e) => {
                //       // getKitListItems(e?.target?.value?.id);
                //     },
                //   }),
                // }}
              />
            </div>

            <div className="col-span-2">
              {/* cssdQty */}

              <InputField
                name="equipmentItemQty"
                type="number"
                variant="outlined"
                label="Qty*"
                error={errors.equipmentItemQty}
                control={control}
              />
            </div>

            <div className=" flex gap-3 col-span-4">
              <CommonButton
                className="bg-customGreen text-white"
                label="Add"
                onClick={() => {
                  addEquipmentItemData();
                  setValue("equipmentItem", null);
                  setValue("equipmentItemQty", "");
                }}
              />
            </div>
            {/* table */}
            {equipmentItemArr.length > 0 ? (
              <div className="w-full mt-2 col-span-8">
                <CommonDynamicTableNew
                  dataResult={equipmentItemArr}
                  renderActions={renderActions4}
                  renderInput={renderInput4}
                  tableClass="rounded lg:h-52 md:h-72"
                  removeHeaders={[
                    "ServiceID",
                    
                  ]}
                  editableColumns={"Quantity"}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckList;
