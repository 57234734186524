import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import {
  fetchCompany,
  fetchCompanyAsst,
  fetchCompanyByPatientCategory,
} from "../../../../../commonServices/miscellaneousServices/MiscServices";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";

const CompanyDetails = (props) => {
  const [company, setCompany] = useState(false);
  const [companyAsst, setCompanyAsst] = useState([]);
  const [companyPpn, setCompanyPpn] = useState([]);

  const {
    control,
    register,
    watch,
    formState: { errors },
    resetField,
    setValue,
  } = useFormContext();

  const PatientCategory = watch("patientcategory");
  const selectedCompany = watch("company");

  // Api to Get Company
  useEffect(() => {
    fetchCompany().then((response) => {
      setCompanyPpn(response.data.result);
    });
  }, [PatientCategory]);

  // Api to Get Company
  useEffect(() => {
    fetchCompanyByPatientCategory(PatientCategory && PatientCategory.id).then(
      (response) => {
        setCompany(response.data.result);
      }
    );
  }, [PatientCategory]);

  useEffect(() => {
    // Api to Get Asst Company

    if (!!selectedCompany) {
      fetchCompanyAsst(selectedCompany.id).then((response) => {
        setCompanyAsst(response.data.result);
      });
    }
  }, [selectedCompany]);

  return (
    <div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-x-3 gap-y-3 ">
        {/* //Company// */}
        <div className="">
          <DropdownField
            control={control}
            error={errors.company}
            name="company"
            label="Company"
            dataArray={company}
            placeholder="Company *"
            isClearable={true}
            isSearchable={true}
            searchIcon={true}
            inputRef={{
              ...register("company", {
                onChange: (e) => {
                  if (e?.target?.value) {
                    props?.handleTariffByCompCategory();
                    resetField("assistantCompany");
                    resetField("ppnCompany");
                    resetField("isPpn");
                    setCompanyAsst([]);
                    e?.target?.value?.IsPPN && setValue("isPpn", true);
                  }
                },
              }),
            }}
          />
        </div>
        {selectedCompany?.IsPPN && (
          <div className="grid grid-cols-4 gap-3">
            <div>
              <CheckBoxField
                control={control}
                name="isPpn"
                label="Is PPN"
                disabled={true}
              />
            </div>

            <div className="col-span-3">
              <DropdownField
                control={control}
                error={errors.ppnCompany}
                name="ppnCompany"
                dataArray={companyPpn}
                placeholder="PPN Company *"
                isClearable={true}
                isSearchable={true}
                searchIcon={true}
              
              />
            </div>
          </div>
        )}
        {/* // Assistant Company */}
        <div className="">
          <DropdownField
            control={control}
            error={errors.assistantCompany}
            name="assistantCompany"
            label="Assistant Company *"
            dataArray={companyAsst}
            placeholder="Assistant Company *"
            isClearable={true}
            isSearchable={true}
            searchIcon={true}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
