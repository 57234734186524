import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import BatchSelectionModal from "./BatchSelectionModal";
import { useState } from "react";
import { fetchBatchNumber } from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";

export default function GRNReturnMultipleTable(props) {
  // const { poData, setDataResult } = props;
  const methods = useForm({
    mode: "onChange",
  });
  const { register, watch, setValue, getValues, handleSubmit, control } =
    methods;

  const [indexVal, setIndexVal] = React.useState("");
  const [data, setData] = React.useState([]);
  const [itemList, setItemList] = React.useState([]);
  const [itemName, setItemName] = React.useState("");
  //batch selection modal
  const [openBatchSelectionModal, setOpenBatchSelectionModal] =
    React.useState(false);
  const handleOpenBatchSelection = () => setOpenBatchSelectionModal(true);
  const handleCloseBatchSelection = () => setOpenBatchSelectionModal(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  // handle change return qty
  const handleReturnQty = (e, index, row) => {
    if (index !== "" && props.selectedRow !== "") {
      let temp = [...props.data];
      const selectedItemRow = temp[index];
      let totalAmount = e.target.value * Number(row.unitPerRate);
      let discountAmt = (0 * totalAmount) / 100;
      let discountedAmt = totalAmount - discountAmt;
      let gstAmt = (Number(discountedAmt) * Number(row.vatPercentage)) / 100;
      let netAmt = totalAmount - discountAmt + Number(gstAmt);

      selectedItemRow["itemName"] = row["itemName"];
      selectedItemRow["batchNo"] = row["batchNo"];
      selectedItemRow["expDate"] = row["expDate"];
      selectedItemRow["grnBalQty"] = row["grnBalQty"];
      selectedItemRow["Conversion"] = row["Conversion"];
      selectedItemRow["MRP"] = row["MRP"];
      selectedItemRow["amount"] = totalAmount.toFixed(2);
      selectedItemRow["batchBalQty"] = row["batchBalQty"];
      selectedItemRow["grnNumber"] = row["grnNumber"];
      selectedItemRow["isConsignment"] = row["isConsignment"];
      selectedItemRow["itemCode"] = row["itemCode"];
      selectedItemRow["itemName"] = row["itemName"];
      selectedItemRow["netAmount"] = netAmt.toFixed(2);
      selectedItemRow["supplier"] = row["supplier"];
      selectedItemRow["unitPerRate"] = row["unitPerRate"];
      selectedItemRow["vatPercentage"] = row["vatPercentage"];
      selectedItemRow["returnQty"] = e.target.value;

      selectedItemRow.color = "";
      let quantityVal = e.target.value;

      if (quantityVal) {
        if (
          quantityVal > row["batchBalQty "] ||
          quantityVal > row["grnBalQty"]
        ) {
          selectedItemRow.color = "#D11A2A";
        } else {
          selectedItemRow.color = "#0B83A5";
        }
      }
      let tempArr = [...itemList];
      let selectedItemIndex = temp.findIndex((item) => item.id === row.id);
      let selectedItem = itemList[selectedItemIndex];
      // selectedItem.expiryDate = selectedItem.expiryDate.replaceAll("/", "-");
      temp[selectedItemIndex] = selectedItem;
      setItemList(tempArr);
      props.setGrnMultipleData(tempArr); //for post Obj


      setItemList(temp);
      temp[index] = selectedItemRow;
      props.setReturnQuantityChangeObj(temp);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleOpenBatchSelection(event);
    }
  };

  React.useEffect(() => {
    if (indexVal !== "" && props.selectedRow !== "") {
      let temp = [...props.data];
      const selectedItemRow = temp[indexVal];
      selectedItemRow["batchNo"] = props.selectedRow.BatchCode;
      selectedItemRow.expDate = props.selectedRow.BatchExpDate;
      selectedItemRow.grnBalQty = props.selectedRow.grnBalQty;
      selectedItemRow.returnQty = 0;
      selectedItemRow.Conversion = props.selectedRow.Conversion;
      selectedItemRow.MRP = props.selectedRow.UnitMRP;
      selectedItemRow.batchBalQty = props.selectedRow.BalQty;
      selectedItemRow.isConsignment = props.selectedRow.IsConsignment;

      temp[indexVal] = selectedItemRow;

      setItemList(temp);
      setData([]);
    }
    let totalAmountValue = 0;
    let netAmount = 0;
    let tempOne = [...props.data];
    let totalCalculation={}
    tempOne.forEach((item) => {
      totalCalculation.totalAmount=totalAmountValue += Number(item["amount"]);
      totalCalculation.netAmount=netAmount += Number(item["netAmount"]);
    });
    props.setGrnReturnQty(totalCalculation);
  }, [props.selectedRow,props.data]);

  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleClick = (index, row) => {
    setItemName(row.itemName);
    let BatchSelObj = {
      batchExpDate: new Date(), //"2023-03-04T10:08:03.766Z",
      isConsignment: row && row.Consignment ? 1 : 0,
      itemId: row && row.id,
      storeId: getUserInfo.storeId,
    };

    fetchBatchNumber(BatchSelObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
      })
      
  };

  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.data];
      newTemplateData.splice(deleteIndex, 1);
      props.setData(newTemplateData);
      handleClosePost();
    }
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 12,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-56"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Action</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Item Code</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Item Discription
                      </span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Supplier Name
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Batch No</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Bat Bal.Qty
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Return Qty
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Exp Date</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Unit Pur.Rate
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Amount</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Net Pay Amt
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">GRN No.</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        GRN Bal Qty
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          handleClick(index, row);
                        }}
                      >
                        <TableCell>
                          <div
                            className="text-red-500 mr-3"
                            onClick={() => {
                              setOpenPost(true);
                              setDeleteIndex(index);
                            }}
                          >
                            {<DeleteOutlineOutlinedIcon />}
                          </div>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.itemCode}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.itemName}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.supplier}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {/* {row.batchNo} */}
                          <input
                            value={
                              row.hasOwnProperty("batchNo") ? row.batchNo : ""
                            }
                            type="text"
                            className="border border-customBlue w-20 text-center rounded "
                            name={`batchNo${index}`}
                            onClick={(e) => {
                              setSelectedRow(row);
                            }}
                            {...register(`batchNo${index}`)}
                            onKeyPress={handleKeyPress}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                setIndexVal(index);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.batchBalQty}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {/* {row.returnQty} */}
                          <input
                            type="number"
                            name={`returnQuantity${index}`}
                            defaultValue={row.returnQty}
                            onChange={(e) => {
                              handleReturnQty(e, index, row);
                            }}
                            style={{ borderColor: `${row.color}` }}
                            className="border border-customBlue rounded w-20 h-[26px] text-center appearance-none"
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.expDate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.unitPerRate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.amount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.netAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.grnNumber}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.grnBalQty}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {props.data.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-12">
                  No Items Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        {openBatchSelectionModal ? (
          <BatchSelectionModal
            handleClose={handleCloseBatchSelection}
            setOpen={setOpenBatchSelectionModal}
            open={openBatchSelectionModal}
            handelOpen={handleOpenBatchSelection}
            data={data}
            setData={setData}
            selectedRow={props.selectedRow}
            setSelectedRow={props.setSelectedRow}
            itemName={itemName}
          />
        ) : (
          ""
        )}
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this service ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
