import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

//GET Apache Score Listing
export const getApacheScore = (admissionId) => {
  return apiClient.get(`clinicalCareChart/apacheScore/${admissionId}`, {
    headers: authHeader(),
  });
};

export const postApacheScore = async (addNewApacheScore) => {
  return await apiClient.post(
    `/clinicalCareChart/saveApacheScore`,
    addNewApacheScore,
    {
      headers: authHeader(),
    }
  );
};

export const deleteApacheScore = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteApacheScore/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};