import React from "react";
import { useFormContext } from "react-hook-form";
import InputArea from "../../../../common/components/FormFields/InputArea";

const MedicationAdvices = () => {
  const { control } = useFormContext();
  return (
    <div className=" grid gap-3 min-h-56 max-h-max	">
      <div className="">
        <InputArea
          name="MedicationAdvices"
          control={control}
          label="Advices"
          placeholder="Advices"
          rows={3}
          disabled={true}
        />
      </div>
      <div className="">
        <InputArea
          control={control}
          label="Follow-up Instructions"
          placeholder="Follow-up Instructions"
          name="FollowUpInstructions"
          rows={3}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default MedicationAdvices;
