import { yupResolver } from "@hookform/resolvers/yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  //addNewEmergency,
  autoSearchService,
  fetchEmergencyRegPatchDetails,
  fetchPrintOpdChargingSlip,
  fetchUnit,
  postMyEmergencyData,
} from "../../services/emergencyservice/EmergencyServices";

import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";

import { fetchDoctor } from "../../../commonServices/miscellaneousServices/MiscServices";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

export default function OpdRegisteredForm(props) {
  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [service, setService] = React.useState([]); //use for service
  const [serviceData, setServiceData] = React.useState([]); // use for service data show
  const [doctor, setDoctor] = useState();
  const [unit, setUnit] = React.useState([]);
  const [finalData, setFinalData] = React.useState({});
  const [serviceErrorMessage, setServiceErrorMessage] = React.useState("");
  const [qtyErrorMessage, setQtyErrorMessage] = React.useState("");
  const [mlcModalData, setMlcModalData] = React.useState(null);

  // print
  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    props.setOpen(false);
  };
  const handleOpenPrintModal = (emergencyId) => {
    props.setOpenBackdrop(true);

    fetchPrintOpdChargingSlip(emergencyId).then((response) => {
      if (response) {
        props.setOpenBackdrop(false);
        setUrlforPrint(response);
        setOpenPrintModal(true);
      }
    });
  };

  const schema = yup.object().shape({
    doctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),
  });

  //state variable to close the confirmation modal for POST request
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //state variable to close the confirmation modal for PUT request
  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      opdIpd: 0, //0 for OPD , 1 for IPD
      isEmergency: false,
      patientInfo: "",
      unit: "",
      services: null,
      doctor: "",
      remarks: "",
      department: "",
      Qty: "",
    },
  });

  const {
    control,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  let ServiceInfo = watch("services");

  let isMlc = watch("isMlc");
  // const StateIsMlc = () => {
  //   setOpenModal(isMlc);
  // };

  // useEffect(() => {
  //   if (isMlc !== undefined) {
  //     StateIsMlc();
  //   }
  // }, [isMlc]);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  ////reset values if we cut selected patient
  useEffect(() => {
    if (!props.patientInfo && props?.patientData === null) {
      methods.reset();
    }
  }, [props.patientInfo, props?.patientData]);

  const finalObject = {
    opdIpd: 0, //0 for OPD , 1 for IPD
    addedBy: 0,
    isEmergency: false,
    unitId: null,
    patientId: 0,
    prefixId: 0,
    firstName: "",
    genderId: "",
    lastName: "",
    middleName: "",
    ageYear: "",
    mrNo: 0,
    isMlc: false,
    admissionAdvised: false,
    patientSourceId: null,
    referralConsultantId: null,
    bedCategoryId: null,
    medicalManagement: false,
    surgicalManagement: false,
    emergencyServicesDto: [0],
    remark: "",
    doctorId: null,
    menuId: "",
    privilege: "",
  };

  const onSubmit = (data) => {
    setOpenPost(true);
    setFinalData(data);
  };
  ////reset values if we cut selected patient
  useEffect(() => {
    if (!props.patientInfo && props?.patientData === null) {
      methods.reset();
    }
  }, [props.patientInfo, props?.patientData]);

  useEffect(() => {
    // unit
    fetchUnit(unit)
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);
      });
  }, []);

  //API For unit dropdown list

  // Handle Change Use For Service api
  const handleChange = (autoServceSearchString) => {
    if (autoServceSearchString !== "") {
      autoSearchService(autoServceSearchString)
        .then((response) => response.data)
        .then((res) => {
          // setValue("Qty", 1);
          setService(res.result);
          setServiceErrorMessage("");
        })
        .catch(() => {});
    }
  };

  const addServiceData = () => {
    let services = getValues("services");
    let doctor = getValues("serviceDoctor");
    let Qty = getValues("Qty");

    if (services !== null) {
      let serviceObj = {
        ServiceId: services.id,
        "Service Name": services.label,
        "service code": services.value,
        Quantity: Qty,
        doctorId: doctor !== undefined && doctor !== null ? doctor.id : 0,
        "Doctor Name":
          doctor !== undefined && doctor !== null ? doctor.label : "",
        isDelete: false,
      };

      let isItemAlreadyExist = false;
      isItemAlreadyExist = serviceData.some(
        (item) => !item.isDelete && item.ServiceId === serviceObj.ServiceId
      );

      if (isItemAlreadyExist) {
        warningAlert("Service Already Exists...");
      } else {
        setServiceData((prev) => [...prev, serviceObj]);
      }

      // let arr = [...serviceData];
      // arr.push(serviceObj);
      // setServiceData(unDuplicateArrayObjects(arr, "id"));
      setValue("services", "", { shouldValidate: true });
      setValue("serviceDoctor", null, { shouldValidate: true });
      setValue("Qty", "", { shouldValidate: true });
    }

    if (services === null && services === "") {
      setServiceErrorMessage("Required");
    }

    if (services === null && Qty === "") {
      setError("services", { type: "custom", message: "Required" });
      setError("Qty", { type: "custom", message: "Required" });
    } else if (services === null) {
      setError("services", { type: "custom", message: "Required" });
    } else if (Qty === "") {
      setError("Qty", { type: "custom", message: "Required" });
    }

    if (services !== null) {
      setQtyErrorMessage("");
      setServiceErrorMessage("");
    }
  };

  //We select service quantity value bedefault set 1
  //let services = watch("services");
  useEffect(() => {
    if (ServiceInfo !== null) {
      setValue("Qty", 1);
      setQtyErrorMessage("");
    } else if (ServiceInfo === null) {
      setValue("Qty", "");
      setQtyErrorMessage("");
    }
  }, [ServiceInfo]);

  const onSubmitDataHandler = (data) => {
    setOpenPost(false);
    methods.reset();

    let token = JSON.parse(localStorage.getItem("userInfo"));
    let user = token.userId;
    let unit = token.unitId;

    finalObject.menuId =
      props.location?.state?.menuId && Number(props.location.state.menuId);

    finalObject.privilege = props.privilege;

    finalObject.opdIpd = 0;

    finalObject.emergencyPatientId = props?.patientData?.EmergencyPatientId;

    finalObject.patientId = props?.patientData?.id;

    finalObject.mrNo = props?.patientData?.MRNo;

    finalObject.dob = props?.patientData?.dob || props?.patientData?.DOB;

    finalObject.addedBy = Number(user);

    finalObject.isEmergency = false;

    finalObject.admissionAdvised = false;

    finalObject.medicalManagement = false;

    finalObject.surgicalManagement = false;

    finalObject.unitId = Number(unit);

    finalObject.prefixId = Number(
      props?.patientData?.prefixId || props?.patientData?.PrefixId
    );

    finalObject.firstName = props?.patientData?.firstName;

    finalObject.middleName = props?.patientData?.middleName;
    finalObject.lastName = props?.patientData?.lastName;

    finalObject.genderId = Number(
      props?.patientData?.genderId || props?.patientData?.GenderId
    );

    data.remarks !== null
      ? (finalObject.remark = data.remarks)
      : (finalObject.remark = null);

    let servicesArrey = [];
    for (let i = 0; i < serviceData.length; i++) {
      if (serviceData[i] !== null) {
        let serviceId = serviceData[i].ServiceId;
        let doctorId = serviceData[i].doctorId;
        let quantity = Number(serviceData[i].Quantity);
        let emergencyServiceId =
          Number(serviceData[i]?.EmergencyServiceId) || 0;
        let isDelete = serviceData[i]?.isDelete || false;

        servicesArrey.push({
          serviceId,
          doctorId,
          quantity,
          emergencyServiceId,
          isDelete,
        });
      }
    }
    finalObject.emergencyServicesDto = servicesArrey;

    finalObject.ageYear = parseInt(props?.patientData?.Age);

    finalObject.patientSourceId = Number(0);

    data.doctor !== null
      ? (finalObject.doctorId = Number(data.doctor.id))
      : (finalObject.doctorId = Number(0));

    finalObject.referralConsultantId = Number(0);

    finalObject.bedCategoryId = Number(0);

    finalObject.isMlc = isMlc;

    finalObject.mlcRequestDto = mlcModalData;

    props.setOpenBackdrop(true);

    postMyEmergencyData(finalObject)
      .then((response) => response.data)

      .then((res) => {
        props.setOpenBackdrop(true);
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          handleClosePost();
          setServiceData([]);
          props.setOpenBackdrop(false);
          // reset(methods);
          props.populateTable();
          methods.reset();
          // navigate("/emergency/registration");
          props.setOpenBackdrop(false);
          //
          handleOpenPrintModal(res.result);
          props.setPatientData("");
          props.setSelectedRow("");
        }
      })
      .catch((res) => {
        props.setOpenBackdrop(false);
        handleClosePost();
        props.setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());
      });
  };

  ///patchdata
  useEffect(() => {
    if (props.privilege === "Edit") {
      setValue("doctor", {
        id: props?.patientData?.DoctorId,
        label: props?.patientData?.DoctorName,
        value: props?.patientData?.DoctorName,
      });

      setValue("isMlc", props?.patientData?.MedicoLegalCase);
      setValue("remarks", props?.patientData?.Remarks);

      setServiceData(props?.patientData?.serviceInfo);
    }
  }, [props?.patientData]);

  ////////table related
  const renderActions = (row, index) => {
    const deleteRow = (index) => {
      // let newTemplateData = [...serviceData];
      // newTemplateData.splice(index, 1);
      // setServiceData(newTemplateData);

      setServiceData((prevData) =>
        prevData.map((item) =>
          item["ServiceId"] === row["ServiceId"]
            ? { ...item, isDelete: true }
            : item
        )
      );
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon
              sx={{ color: "#ef4444" }}
              onClick={() => deleteRow(index)}
            />
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mx-2">
        <div className="grid w-full">
          {props?.patientInfo ? (
            <>
              {/* Populate Patient Info Fields */}
              <div className="grid border bg-gray-100 border-gray-300 px-3 rounded mt-1">
                {props?.patientData !== null ? (
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 py-2">
                    <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                      {/* Patient Name , UHID */}
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-1">
                          <span className="text-sm">Patient Name </span>
                          <span className=""> :</span>
                        </h1>
                        {/* <h1 className="font-normal">Ram Sham Rao</h1> */}
                        <h1 className="font-normal">
                          {props?.patientData?.patientName}
                        </h1>
                      </div>
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                          <span className="text-sm">Gender</span>
                          <span className="lg:pl-7 md:pl-6">:</span>
                        </h1>
                        {/* <h1 className="font-normal">Male</h1> */}
                        <h1 className="font-normal">
                          {props?.patientData?.gender}
                        </h1>
                      </div>
                    </div>

                    {/* Gender , Age */}
                    <div className="grid gap-2 lg:border-r-2 pl-4 border-slate-500 my-1 ">
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                          <span className="text-sm">MR No</span>
                          <span className="">:</span>
                        </h1>
                        {/* <h1 className="font-normal">124584</h1> */}
                        <h1 className="font-normal">
                          {props?.patientData?.MRNo}
                        </h1>
                      </div>
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex md:space-x-7 lg:space-x-1">
                          <span className="text-sm ">Mobile No</span>
                          <span className="lg:pl-9 md:pl-3">:</span>
                        </h1>
                        {/* <h1 className="font-normal">9484280404</h1> */}
                        <h1 className="font-normal">
                          {props?.patientData?.mobileNo}
                        </h1>
                      </div>
                    </div>

                    {/* Bed No */}
                    <div className="lg:pl-4">
                      <div className="flex gap-2 ">
                        <h1 className="text-black font-semibold flex space-x-11 lg:space-x-10">
                          <span className="text-sm">Age</span>
                          <span className="md:pl-6">:</span>
                        </h1>
                        {/* <h1 className="font-normal">23Year 02Months.</h1> */}
                        <h1 className="font-normal">
                          {props?.patientData?.Age}
                        </h1>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Doctor input field */}
              <div className="grid grid-cols-3 mt-2 gap-2">
                <div className="">
                  <SearchDropdown
                    control={control}
                    searchIcon={true}
                    isDisabled={false}
                    isSerchable={true}
                    isClearable={true}
                    error={errors.doctor}
                    maxMenuHeight={200}
                    name="doctor"
                    label="Doctor *"
                    placeholder="Doctor *"
                    isMulti={false}
                    dataArray={doctor}
                    handleInputChange={(e) => {
                      // Doctor
                      e &&
                        fetchDoctor(e)
                          .then((response) => {
                            setDoctor(response.data.result);
                          })
                          .catch(() => {});
                    }}
                  />
                </div>
                <div onClick={(e) => e.target.checked && setOpenModal(true)}>
                  <CheckBoxField
                    control={control}
                    name="isMlc"
                    label="Medico Legal Case"
                    disabled={
                      props?.selectedRow &&
                      (props?.selectedRow?.MLCNo !== null ||
                        props?.selectedRow?.AdmissionId > 0)
                        ? true
                        : false
                    }
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Service Information
                  </span>
                </div>
                <div className="grid grid-cols-6 gap-3 mt-3">
                  <div className="col-span-2">
                    <SearchDropdown
                      control={control}
                      name="services"
                      label="Search by Services"
                      placeholder="Search by Service"
                      searchIcon={true}
                      isSearchable={true}
                      isClearable={true}
                      dataArray={service}
                      handleInputChange={handleChange}
                      error={errors.services}
                      maxMenuHeight={200}

                    />

                    <p className="text-customRed text-sm">
                      {serviceErrorMessage}
                    </p>
                  </div>

                  <div className="">
                    <InputField
                      name="Qty"
                      type="number"
                      variant="outlined"
                      label="Qty"
                      error={errors.Qty}
                      control={control}
                    />
                    <p className="text-customRed text-sm">{qtyErrorMessage}</p>
                  </div>

                  {ServiceInfo && ServiceInfo.DoctorShare ? (
                    <>
                      {/* <SearchBar doctor */}
                      <div className="col-span-2">
                        <SearchDropdown
                          control={control}
                          searchIcon={true}
                          isDisabled={false}
                          isSerchable={true}
                          isClearable={true}
                          error={errors.doctor}
                          name="serviceDoctor"
                          label="Doctor"
                          placeholder="Doctor"
                          maxMenuHeight={200}

                          isMulti={false}
                          dataArray={doctor}
                          handleInputChange={(e) => {
                            // Doctor
                            fetchDoctor(e)
                              .then((response) => {
                                setDoctor(response.data.result);
                              })
                              .catch(() => {});
                          }}
                        />
                      </div>
                    </>
                  ) : null}

                  <div>
                    <CommonButton
                      label="Add"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        addServiceData();
                        setValue("services", null);
                        setValue("Qty", "");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full mt-2">
                  {serviceData &&
                    serviceData.filter((item) => !item.isDelete).length > 0 &&
                    serviceData?.length > 0 && (
                      <CommonDynamicTableNew
                        dataResult={serviceData.filter(
                          (item) => !item.isDelete
                        )}
                        renderActions={renderActions}
                        highlightRow={false}
                        removeHeaders={["id", "doctorId", "ServiceId"]}
                        tableClass="max-h-72"
                      />
                    )}
                </div>
                {/* table */}
              </div>

              <div className="w-full mt-2">
                <InputField
                  name="remarks"
                  variant="outlined"
                  label="Remark"
                  error={errors.remarks}
                  control={control}
                  inputProps={{
                    style: { textTransform: "capitalize" },
                  }} // use inputProps props for return 1st letter in upper case
                  required
                />
              </div>

              {/* Buttons Add Update Cancel Reset */}
              <div className="flex gap-2 justify-end my-4">
                {Actions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "save" ? (
                      <>
                        <CommonButton
                          label="Reset"
                          onClick={() => {
                            setServiceData([]);
                            props.setValue("patientInfo", null);
                            props.setPatientData("");
                            props.setPatientInfoId("");
                            reset(methods);
                          }}
                          className="border border-customRed text-customRed"
                        />

                        <CommonButton
                          type="submit"
                          label="Save"
                          className="bg-customGreen text-white"
                          // searchIcon={true}
                        />
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </form>

      {/* mlc Modal */}
      {openModal ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setMlcModalData={setMlcModalData}
          patientInfo={props.patientInfo}
          patientData={props?.patientData}
          isEdit={false}
          MLCNo={null}
          admissionId={0}
          emergencyId={props?.patientData?.EmergencyPatientId}
          opdIpd={1}
          isFromEmergency={true}
          isFromForm={true}
          //
          patientId={
            props?.patientData?.id
              ? props?.patientData?.id
              : props?.patientData?.EmergencyPatientId
          }
          isEmergency={props?.patientData?.id ? false : true}
          ipdEmergency={2}

          //
        />
      ) : null}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* Confirmation modal for PUT request */}
      <ConfirmationModal
        confirmationOpen={openPut}
        confirmationHandleClose={handleClosePut}
        // confirmationSubmitFunc={updateRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Update"
      />
      {/* Confirmation modal for POST request */}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={() => onSubmitDataHandler(finalData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
