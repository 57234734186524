import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Modal, Checkbox } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import { getGrnReturnList } from "../../../services/commonServices/grnReturnService/GRNReturnService";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePagination from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

const GRNReturnModal = (props) => {
  const Actions = [
    {
      id: 0,
      action: "checkbox",
      isAction: true,
    },
  ];

  const defaultValues = {
    id: "",
    fromDate: new Date(),
    toDate: new Date(),
  };

  const { control, setValue, watch, getValues, reset, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [page, setPage] = React.useState(0);
  //state variable to inidicate rows per page
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //state variable to indicate the total number of records coming from api
  const [count, setCount] = useState();
  const [data, setData] = useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  //Table
  const [selectedItem, setSelectedItem] = useState([]);
  // local storage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let storeId = token.storeId;

  // return listObj
  let selectedFromDate = watch("fromDate");
  let selectedToDate = watch("toDate");

  const getTableData = () => {
    let defaultParams = {
      cashCredit: props?.paymentMode,
      fromDate: selectedFromDate,
      searchString: "",
      storeId: storeId,
      supplierId: props?.supplierId,
      toDate: selectedToDate,
      page: page,
      size: rowsPerPage,
    };

    getGrnReturnList(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
        setCount(res.count);
        setDataResult(res.result);
      });
  };
  // Add data into main component
  const getSelectedItemsData = () => {
    props.setSelectedItems(selectedId);
  };

  const handleCheckboxChange = (
    GRNId,
    GRNNumber,
    Date,
    SupplierName,
    StoreName
  ) => {
    if (
      selectedItem.some(
        (item) => item.GRNId === GRNId && item.SupplierName === SupplierName
      )
    ) {
      setSelectedItem((prevItems) =>
        prevItems.filter(
          (item) =>
            item.StoreName !== StoreName ||
            item.GRNId !== GRNId ||
            item.GRNNumber !== GRNNumber ||
            item.Date !== Date ||
            item.SupplierName !== SupplierName
        )
      );
    } else {
      setSelectedItem((prevItems) => [
        ...prevItems,
        {
          GRNId,
          GRNNumber,
          Date,
          SupplierName,
          StoreName,
        },
      ]);
    }
  };

  const isCheckboxSelected = (GRNId, SupplierName) => {
    return selectedItem.some(
      (item) => item.GRNId === GRNId && item.SupplierName === SupplierName
    );
  };

  const isCheckboxDisabled = (SupplierName) => {
    return (
      selectedItem.length > 0 &&
      !selectedItem.some((item) => item.SupplierName === SupplierName)
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex pt-1 justify-center gap-2 cursor-pointer">
                  {actions.action === "checkbox" ? (
                    <>
                      <Checkbox
                        sx={{ margin: 0, padding: 0 }}
                        checked={isCheckboxSelected(
                          row.GRNId,
                          row.SupplierName
                        )}
                        onChange={() =>
                          handleCheckboxChange(
                            row.GRNId,
                            row.GRNNumber,
                            row.Date,
                            row.SupplierName,
                            row.StoreName
                          )
                        }
                        disabled={isCheckboxDisabled(row.SupplierName)}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    setSelectedId(selectedItem);
  }, [selectedItem]);
  return (
    <>
      <Modal open={props.openStoreGRNReturn}>
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              props.setOpenStoreGRNReturn(false);
            }}
          />
          <h1 className="flex justify-center text-black  font-semibold">
            Get GRN
          </h1>
          <div className="my-5">
            <div className="grid grid-cols-4 gap-2 ">
              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("fromDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("toDate", newValue);
                  }}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  label="Display"
                  onClick={() => {
                    getTableData();
                  }}
                />
              </div>
            </div>
            {data.hasOwnProperty("result") &&
            data.result.length > 0 &&
            data.statusCode === 200 ? (
              <div>
                {/* <GRNReturnModalTable
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  setDoDetails={setDoDetails}
                  dataResult={dataResult}
                  setDataResult={setDataResult}
                  tableApiFunc={getGrnReturnList}
                  data={data}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  count={count}
                  selectedFromDate={selectedFromDate}
                  selectedToDate={selectedToDate}
                  cashCredit={props.paymentMode}
                  supplierId={props?.supplierId}
                /> */}

                <CommonDynamicTablePagination
                  data={data}
                  dataResult={dataResult}
                  setDataResult={setDataResult}
                  actionsRequired={true}
                  populateTable={getTableData}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  count={count}
                  //Function to Show lineItem
                  renderActions={renderActions}
                  highlightRow={true}
                  tableHeading={"Grn Return List"}
                  tableClass={"h-[250px]"}
                />
              </div>
            ) : (
              <h3 className="text-center mt-12 mb-10 text-sm font-semibold">
                No Records Found...
              </h3>
            )}
            <div className="flex justify-end">
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                label="+ Add"
                onClick={() => {
                  if (selectedId.length > 0) {
                    props.setOpenStoreGRNReturn(false);
                    getSelectedItemsData();
                  } else {
                    warningAlert("Please Select Items First");
                  }
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default GRNReturnModal;
