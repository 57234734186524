import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";
import { contraVoucherPrint } from "../../services/contraVoucherServices/ContraVoucherServices";

export default function ContravoucherPrint(props) {
  const [dataResult, setdataResult] = React.useState();
  const printRef = useRef(null);

  useEffect(() => {
    contraVoucherPrint(props.contraVoucherId)
      .then((response) => response.data)
      .then((res) => {
        setdataResult(res.result);
      });
  }, [props]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef} className=" rounded-xl border-[1px] border-black p-3">
      <div className=" p-1 flex justify-between ">
        <div className=" text-[14px] font-semibold">
          THE CRESCENT INDIA MEDICAL
          <br />
          EDUCATION TRUST
        </div>
        <div className=" text-[11px] font-semibold ">BANK PAYMENT VOUCHER</div>
      </div>
      <div className="p-1 flex justify-between text-[11px]">
        <div className="font-normal">
          Daulat Building.963, Nana Peth,Ardeshir Baugh, Next to St Vincent's
          <br />
          School,M.A.Parvez Road,Pune411002
        </div>
        <div>Date: {dataResult?.Date}</div>
      </div>
      <div className=" p-1 text-[11px] ml-5 mt-5 mb-2 font-semibold">
        ACCOUNT NO
      </div>
      <div className="  flex border-[1px] border-black w-52">
        <div className=" border-r-[1px] border-black w-10">&#160;</div>
        <div className=" border-r-[1px] border-black w-10">&#160;</div>
        <div className=" border-r-[1px] border-black w-10">&#160;</div>
        <div className=" border-r-[1px] border-black w-10">&#160;</div>
        <div className=" border-r-[1px] border-black w-10">&#160;</div>
        <div className=" w-10">&#160;</div>
      </div>
      <div className="p-1 mt-3 text-[11px] grid grid-cols-[20%_5%_75%]">
        <div className=" font-semibold">Paid To</div>
        <div className=" font-semibold">:</div>
        <div>{dataResult?.PainTo}</div>
        <div className=" font-semibold">Towards</div>
        <div className=" font-semibold">:</div>
        <div>{dataResult?.Towards}</div>
        <div className=" font-semibold">Total Amount</div>
        <div className=" font-semibold">:</div>
        <div>{dataResult?.TotalAmount}</div>
        <div className=" font-semibold">Rupees</div>
        <div className=" font-semibold">:</div>
        <div>{dataResult?.Rupees}</div>
        <div className=" font-semibold">Drawn On Bank</div>
        <div className=" font-semibold">:</div>
        <div className=" w-36 border-[1px]  border-black">
          {dataResult?.DownOnBank}
        </div>
      </div>
      <div className=" p-1 flex justify-between mt-10 text-[11px] font-semibold">
        <div>Authorised By</div>
        <div>Prepared By</div>
        <div>A/c's Manager</div>
        <div>Receiver's Signature</div>
      </div>
    </div>
  );
}
