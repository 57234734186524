import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllOxygenVantilation = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/ventilationStatus/${admissionId}`,
 
  {
    headers: authHeader(),
  });
};

// /api/clinicalCareChart/ventilationStatus/{admissionId}

export const saveVentilationOxygenRecords = async (addNewOxygenVantilation) => {
  return await apiClient.post(
    `/clinicalCareChart/saveVentilationOxygen`,
    addNewOxygenVantilation,
   
    {
      headers: authHeader(),
    }
  );
};

//Get VentilationMode Dropdown Options
export const getAllVentilationModes = () => {
  return apiClient.get("/clinicalCareChart/ventilationModes",
 
  {
    headers: authHeader(),
  });
}

export const getVentilationStatus = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/ventilationStatus/${admissionId}`,
 
  {
    headers: authHeader(),
  });
};


export const deleteOxygenVentilion = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteVentilationOxygen/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};


export const oxyventiPrintData = (postObj) => {
  return apiClient.post(`/reports/nursing/cccVentilationOxygen`, postObj, {
   headers: authHeader(),
  });
};

