import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  let navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="-mx-4 -px-2 overflow-x-hidden">
      <section>
        <div class="bg-white text-black">
          <div class="flex h-screen">
            <div class="m-auto text-center">
              <div>
                <svg
                  width="631"
                  height="300"
                  viewBox="0 0 631 379"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M317.524 379C488.625 379 627.33 368.857 627.33 356.346C627.33 343.834 488.625 333.692 317.524 333.692C146.423 333.692 7.71875 343.834 7.71875 356.346C7.71875 368.857 146.423 379 317.524 379Z"
                      fill="#F2F2F2"
                    />
                    <path
                      d="M305.65 108.342C255.175 108.342 218.934 144.112 218.934 227.575C218.934 322.166 255.175 347.205 305.65 347.205C356.126 347.205 394.526 319.781 394.526 227.575C394.526 129.009 356.126 108.342 305.65 108.342ZM305.982 315.807C271.021 315.807 252.757 293.152 252.757 227.636C252.757 169.827 271.769 139.342 306.73 139.342C341.691 139.342 360.703 159.367 360.703 227.636C360.703 291.5 340.943 315.807 305.982 315.807Z"
                      fill="#2F2E41"
                      className="animate-pulse"
                    />
                    <path
                      d="M188.708 281.229H167.839V213.348C167.839 209.743 166.542 206.285 164.234 203.735C161.926 201.186 158.795 199.754 155.531 199.754H150.641C149.025 199.754 147.424 200.105 145.931 200.788C144.438 201.472 143.081 202.473 141.938 203.735C140.796 204.998 139.889 206.496 139.27 208.146C138.652 209.795 138.334 211.563 138.334 213.348V281.229H79.4676C78.2566 281.229 77.0661 280.884 76.0117 280.226C74.9573 279.568 74.0748 278.62 73.4497 277.474C72.8246 276.329 72.4783 275.024 72.4442 273.687C72.4101 272.35 72.6895 271.026 73.2552 269.843L135.558 139.594C136.335 137.97 136.806 136.189 136.942 134.358C137.079 132.528 136.878 130.686 136.352 128.944C135.826 127.201 134.986 125.595 133.883 124.221C132.779 122.847 131.435 121.734 129.932 120.95L126.254 119.032C123.398 117.542 120.133 117.322 117.134 118.417C114.136 119.513 111.633 121.839 110.144 124.916L34.1574 281.926C32.8635 284.6 32.1865 287.585 32.1865 290.616C32.1865 293.037 32.6182 295.434 33.4569 297.671C34.2956 299.907 35.5249 301.939 37.0746 303.651C38.6243 305.363 40.4641 306.721 42.4888 307.647C44.5136 308.573 46.6838 309.05 48.8754 309.05H138.334V351.974C138.334 354.114 138.715 356.233 139.457 358.21C140.198 360.187 141.285 361.983 142.655 363.496C144.024 365.009 145.651 366.21 147.441 367.029C149.23 367.848 151.149 368.269 153.086 368.269H153.086C155.024 368.269 156.942 367.848 158.732 367.029C160.522 366.21 162.148 365.009 163.518 363.496C164.888 361.983 165.974 360.187 166.716 358.21C167.457 356.233 167.839 354.114 167.839 351.974V309.05H188.708C192.049 309.05 195.252 307.585 197.614 304.976C199.975 302.367 201.302 298.829 201.302 295.14C201.302 291.45 199.975 287.912 197.614 285.304C195.252 282.695 192.049 281.229 188.708 281.229Z"
                      fill="#1e3a8a"
                    />
                    <path
                      d="M575.157 281.229H554.287V213.348C554.287 209.743 552.99 206.285 550.682 203.735C548.374 201.186 545.244 199.754 541.98 199.754H537.089C535.473 199.754 533.873 200.105 532.379 200.788C530.886 201.472 529.529 202.473 528.387 203.735C527.244 204.998 526.337 206.496 525.719 208.146C525.1 209.795 524.782 211.563 524.782 213.348V281.229H465.916C464.705 281.229 463.514 280.884 462.46 280.226C461.406 279.568 460.523 278.62 459.898 277.474C459.273 276.329 458.926 275.024 458.892 273.687C458.858 272.35 459.138 271.026 459.703 269.843L522.007 139.594C522.783 137.97 523.254 136.189 523.391 134.358C523.527 132.528 523.326 130.686 522.8 128.944C522.275 127.201 521.435 125.595 520.331 124.221C519.228 122.847 517.883 121.734 516.38 120.95L512.702 119.032C509.846 117.542 506.581 117.322 503.583 118.417C500.584 119.513 498.081 121.839 496.593 124.916L420.606 281.926C419.312 284.6 418.635 287.585 418.635 290.616C418.635 295.505 420.393 300.194 423.523 303.651C426.653 307.108 430.897 309.05 435.324 309.05H524.782V351.974C524.782 356.296 526.336 360.44 529.103 363.496C531.869 366.552 535.622 368.269 539.534 368.269C543.447 368.269 547.199 366.552 549.966 363.496C552.733 360.44 554.287 356.296 554.287 351.974V309.05H575.157C578.497 309.05 581.7 307.585 584.062 304.976C586.423 302.367 587.75 298.829 587.75 295.14C587.75 291.45 586.423 287.912 584.062 285.304C581.7 282.695 578.497 281.229 575.157 281.229Z"
                      fill="#1e3a8a"
                    />
                    <path
                      d="M194.466 273.28H173.597V205.399C173.597 203.614 173.278 201.846 172.66 200.197C172.041 198.547 171.135 197.049 169.992 195.786C168.849 194.524 167.492 193.523 165.999 192.84C164.506 192.156 162.905 191.805 161.289 191.805H156.399C153.135 191.805 150.004 193.237 147.696 195.786C145.388 198.336 144.091 201.794 144.091 205.399V273.28H85.2254C84.0144 273.28 82.8239 272.935 81.7695 272.277C80.7151 271.619 79.8326 270.671 79.2075 269.525C78.5825 268.38 78.2361 267.075 78.202 265.738C78.1679 264.401 78.4473 263.077 79.013 261.894L141.316 131.645C142.093 130.021 142.564 128.24 142.7 126.41C142.837 124.579 142.636 122.737 142.11 120.995C141.584 119.252 140.744 117.646 139.641 116.272C138.537 114.898 137.193 113.786 135.69 113.001L132.012 111.083C129.156 109.593 125.891 109.373 122.892 110.469C119.894 111.564 117.391 113.89 115.902 116.967L39.9153 273.977C38.6213 276.651 37.9443 279.636 37.9443 282.668V282.668C37.9443 287.556 39.7026 292.245 42.8324 295.702C45.9622 299.159 50.207 301.101 54.6332 301.101H144.091V344.025C144.091 346.165 144.473 348.284 145.214 350.261C145.956 352.238 147.042 354.034 148.412 355.547C149.782 357.061 151.409 358.261 153.198 359.08C154.988 359.899 156.907 360.32 158.844 360.32H158.844C162.757 360.32 166.509 358.603 169.276 355.547C172.042 352.492 173.597 348.347 173.597 344.025V301.101H194.466C196.12 301.101 197.758 300.742 199.286 300.042C200.814 299.343 202.202 298.319 203.371 297.027C204.541 295.735 205.468 294.202 206.101 292.514C206.734 290.827 207.06 289.018 207.06 287.191V287.191C207.06 285.364 206.734 283.555 206.101 281.868C205.468 280.18 204.541 278.646 203.371 277.355C202.202 276.063 200.814 275.038 199.286 274.339C197.758 273.64 196.12 273.28 194.466 273.28V273.28Z"
                      stroke="#3F3D56"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M585.951 273.28H565.081V205.399C565.081 201.794 563.784 198.336 561.476 195.786C559.168 193.237 556.038 191.805 552.773 191.805H547.883C546.267 191.805 544.667 192.156 543.173 192.84C541.68 193.523 540.323 194.524 539.181 195.786C538.038 197.049 537.131 198.547 536.513 200.197C535.894 201.846 535.576 203.614 535.576 205.399V273.28H476.71C475.499 273.28 474.308 272.935 473.254 272.277C472.2 271.619 471.317 270.671 470.692 269.525C470.067 268.38 469.72 267.075 469.686 265.738C469.652 264.401 469.932 263.077 470.497 261.894L532.8 131.645C533.577 130.021 534.048 128.24 534.185 126.41C534.321 124.579 534.12 122.737 533.594 120.995C533.069 119.252 532.228 117.646 531.125 116.272C530.021 114.898 528.677 113.786 527.174 113.001L523.496 111.083C520.64 109.593 517.375 109.373 514.377 110.469C511.378 111.564 508.875 113.89 507.386 116.967L431.4 273.977C430.106 276.651 429.429 279.636 429.429 282.668C429.429 287.556 431.187 292.245 434.317 295.702C437.447 299.159 441.691 301.101 446.118 301.101H535.576V344.025C535.576 348.347 537.13 352.492 539.897 355.547C542.663 358.603 546.416 360.32 550.328 360.32V360.32C554.241 360.32 557.993 358.603 560.76 355.547C563.527 352.492 565.081 348.347 565.081 344.025V301.101H585.951C587.604 301.101 589.242 300.742 590.77 300.042C592.298 299.343 593.686 298.319 594.856 297.027C596.025 295.735 596.953 294.202 597.586 292.514C598.219 290.827 598.544 289.018 598.544 287.191V287.191C598.544 283.502 597.217 279.963 594.856 277.355C592.494 274.746 589.291 273.28 585.951 273.28V273.28Z"
                      stroke="#3F3D56"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M317.165 101.188C266.689 101.188 230.448 136.958 230.448 220.421C230.448 315.012 266.689 340.051 317.165 340.051C367.641 340.051 406.041 312.627 406.041 220.421C406.041 121.855 367.641 101.188 317.165 101.188ZM317.497 308.653C282.536 308.653 264.271 285.998 264.271 220.482C264.271 162.673 283.284 132.188 318.245 132.188C353.205 132.188 372.218 152.213 372.218 220.482C372.218 284.346 352.457 308.653 317.497 308.653V308.653Z"
                      stroke="#3F3D56"
                      stroke-miterlimit="10"
                    />

                    <path
                      d="M282.464 221.287C287.978 221.287 292.448 214.897 292.448 207.015C292.448 199.133 287.978 192.744 282.464 192.744C276.95 192.744 272.479 199.133 272.479 207.015C272.479 214.897 276.95 221.287 282.464 221.287Z"
                      fill="#2F2E41"
                      className="animate-pulse"
                    />
                    <path
                      d="M336.79 221.611C342.304 221.611 346.774 215.222 346.774 207.34C346.774 199.458 342.304 193.068 336.79 193.068C331.276 193.068 326.806 199.458 326.806 207.34C326.806 215.222 331.276 221.611 336.79 221.611Z"
                      fill="#2F2E41"
                      className="animate-pulse"
                    />
                    <path
                      d="M329.155 249.506C329.155 255.954 321.003 261.183 310.949 261.183C300.894 261.183 292.742 255.954 292.742 249.506C292.742 243.921 298.856 239.25 307.026 238.108C308.326 237.919 309.637 237.826 310.949 237.829C313.147 237.822 315.339 238.083 317.485 238.608C324.31 240.288 329.155 244.537 329.155 249.506Z"
                      fill="#2F2E41"
                      className="animate-pulse"
                    />
                    <path
                      d="M284.813 218.044C290.328 218.044 294.798 211.654 294.798 203.772C294.798 195.89 290.328 189.5 284.813 189.5C279.299 189.5 274.829 195.89 274.829 203.772C274.829 211.654 279.299 218.044 284.813 218.044Z"
                      stroke="#3F3D56"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M339.139 218.368C344.653 218.368 349.123 211.978 349.123 204.096C349.123 196.214 344.653 189.824 339.139 189.824C333.624 189.824 329.154 196.214 329.154 204.096C329.154 211.978 333.624 218.368 339.139 218.368Z"
                      stroke="#3F3D56"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M313.297 257.94C323.353 257.94 331.504 252.712 331.504 246.263C331.504 239.814 323.353 234.586 313.297 234.586C303.242 234.586 295.091 239.814 295.091 246.263C295.091 252.712 303.242 257.94 313.297 257.94Z"
                      stroke="#3F3D56"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M317.702 239.581C317.707 240.397 317.43 241.184 316.927 241.781C316.689 242.068 316.401 242.297 316.079 242.454C315.758 242.611 315.411 242.693 315.059 242.695H309.186C308.462 242.667 307.778 242.324 307.283 241.74C306.788 241.157 306.522 240.38 306.543 239.581C306.54 238.948 306.708 238.329 307.025 237.803C308.324 237.601 309.636 237.501 310.948 237.505C313.149 237.497 315.343 237.776 317.485 238.335C317.63 238.731 317.704 239.154 317.702 239.581Z"
                      fill="white"
                    />
                    <path
                      d="M284.814 203.123C286.435 203.123 287.75 201.671 287.75 199.879C287.75 198.088 286.435 196.636 284.814 196.636C283.192 196.636 281.877 198.088 281.877 199.879C281.877 201.671 283.192 203.123 284.814 203.123Z"
                      fill="white"
                    />
                    <path
                      d="M339.433 203.123C341.054 203.123 342.369 201.671 342.369 199.879C342.369 198.088 341.054 196.636 339.433 196.636C337.811 196.636 336.496 198.088 336.496 199.879C336.496 201.671 337.811 203.123 339.433 203.123Z"
                      fill="white"
                    />
                    <path
                      opacity="0.3"
                      d="M342.369 233.613C343.991 233.613 345.306 232.16 345.306 230.369C345.306 228.578 343.991 227.126 342.369 227.126C340.747 227.126 339.433 228.578 339.433 230.369C339.433 232.16 340.747 233.613 342.369 233.613Z"
                      fill="#E7D040"
                    />
                    <path
                      opacity="0.3"
                      d="M270.718 233.613C272.34 233.613 273.654 232.16 273.654 230.369C273.654 228.578 272.34 227.126 270.718 227.126C269.096 227.126 267.781 228.578 267.781 230.369C267.781 232.16 269.096 233.613 270.718 233.613Z"
                      fill="#E7D040"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="631" height="379" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p class="text-sm md:text-base text-customBlue p-2 mb-4">
                The stuff you were looking for doesn't exist
              </p>
              <button
                className="bg-transparent hover:bg-customBlue text-customBlue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-customBlue hover:border-transparent"
                onClick={() => {
                  handleBack();
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageNotFound;
