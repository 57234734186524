import axios from "axios";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonTransactionPaginationTable from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerField from "../../../common/components/FormFields/DatePickerFieldNew";
import {
  getPatientWiseReport,
  getUserWiseReport,
} from "../../services/feedbackservices/FeedbackServices";

function PatientWiseReport() {
  const defaultValues = {
    userName: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const { control, watch,reset } = useForm({
    mode: "onChange",
    defaultValues,
  });
  
  // local variables
  let userNameValue = watch("userName");
  let fromDateValue = watch("fromDate");
  let toDateValue = watch("toDate");
  // state variables
  const [ipdListData, setIPDListData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [listingObject, setListingObject] = useState({
    searchString: userNameValue,
    fromDate:
      fromDateValue !== null
        ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        : null,
    page: page ? page : 0,
    size: rowsPerPage ? rowsPerPage : 10,
    toDate:
      toDateValue !== null
        ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
        : null,
  });

  // get template reports list
  const populateTable = (forPagination) => {
    const obj = {
      searchString: userNameValue,
      id: null,
      departmentId: null,
      templateFor: "",
      fromDate:
        fromDateValue !== null
          ? format(new Date(fromDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
      page: page ? page : 0,
      size: rowsPerPage ? rowsPerPage : 10,
      toDate:
        toDateValue !== null
          ? format(new Date(toDateValue), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
          : null,
    };
    getPatientWiseReport(obj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          if (res.result && Array.isArray(res.result)) {
            setIPDListData((prevData) => [...prevData, ...res.result]);
          }
        } else {
          setIPDListData(res.result || []);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useMemo(() => {
    populateTable();
  }, [listingObject.fromDate, listingObject.toDate]);
  return (
    <>
      <div className="flex space-x-3 items-center w-full">
        <h1 className="text-customBlue my-2 font-semibold whitespace-nowrap text-base tracking-wide">
          Patient Wise Reports
        </h1>

        <div>
          <DatePickerField
            name="fromDate"
            control={control}
            label="From Date"
            inputFormat="dd-MM-yyyy"
            disableFuture={true}
          />
        </div>
        <div>
          <DatePickerField
            name="toDate"
            control={control}
            label="To Date"
            inputFormat="dd-MM-yyyy"
            disableFuture={true}
          />
        </div>
        <div>
          <CommonButton
            type="button"
            label="Reset"
            className="border border-customRed text-customRed"
            onClick={()=>{
              reset(defaultValues)
            }}
          />
        </div>
        <div>
          <CommonButton
            searchIcon={true}
            type="button"
            className="bg-customBlue text-white h-9"
            onClick={() => {
              setListingObject((listingObject) => ({
                ...listingObject,
                searchString: userNameValue,
                fromDate:
                  fromDateValue !== null
                    ? format(new Date(fromDateValue), "yyyy-MM-dd")
                    : null,
                page: page ? page : 0,
                size: rowsPerPage ? rowsPerPage : 10,
                toDate:
                  toDateValue !== null
                    ? format(new Date(toDateValue), "yyyy-MM-dd")
                    : null,
              }));
            }}
          />
        </div>
      </div>
      <div className="w-full">
        {ipdListData?.length > 0 ? (
          <div className="mt-2">
            <CommonTransactionPaginationTable
              populateTable={populateTable}
              dataResult={ipdListData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              setRowsPerPage={setRowsPerPage}
              highlightRow={false}
              removeHeaders={[
                "Id",
                "Sr No",
                "Admission Id",
                "Patient Id",
                "Bed Id",
              ]}
              tableClass={"h-[370px]"}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <h3 className="flex justify-center my-28 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        )}
      </div>
    </>
  );
}

export default PatientWiseReport;
