import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// patient count list on unit id api
// /api/nursingSupervisor/patientCountList/{unitId}/{date}
export const fetchAllNursingPatientCount = (unitId, date) => {
  return apiClient.get(
    `/nursingSupervisor/patientCountList/${unitId}/${date}`,
    {
      headers: authHeader(),
    }
  );
};

// count list of ward
// api​/nursingSupervisor​/patientCountListWard​/{unitId}
export const fetchAllPatientInfoListFromWard = (unitId) => {
  return apiClient.get(`/nursingSupervisor/patientCountListWard/${unitId}`, {
    headers: authHeader(),
  });
};
// /api/nursingSupervisor/saveNursingSupervisor
export const saveNursingSupervisorDetails = (postObj) => {
  return apiClient.post(`/nursingSupervisor/saveNursingSupervisor`, postObj, {
    headers: authHeader(),
  });
};

// /api/nursingSupervisor/nursingSupervisorViewList/{date}
export const fetchNursingSupervisorViewList = (selectedDate) => {
  return apiClient.get(
    `/nursingSupervisor/nursingSupervisorViewList/${selectedDate}`,
    {
      headers: authHeader(),
    }
  );
};
