import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";

import * as yup from "yup";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CancelOnIcon,
  DeleteOnIcon,
  EditOnIcon,
} from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchTariff,
  fetchUnit,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  createNewService,
  fetchClassesApplicableTo,
  fetchGroups,
  fetchPatchDetails,
  fetchServices,
  fetchSubGroups,
} from "../../services/billingServices/BillingServices";

const ChargesType = [
  { id: 1, label: "Amount", value: "Amount" },
  { id: 2, label: "Percentage", value: "Percentage" },
];

const AplicableToData = [
  { id: "O", label: "OPD", value: "OPD" },
  { id: "I", label: "IPD", value: "IPD" },
  { id: "B", label: "Both", value: "Both" },
];

const PriceSet = [
  {
    id: 1,
    label: "Group - Wise",
    value: "Group - Wise",
  },
  {
    id: 2,
    label: "Service - wise",
    value: "Service - wise",
  },
];

const schema = yup.object().shape({
  group: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
  subGroup: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
  serviceName: yup.string().required("Required"),
  serviceCode: yup.string().required("Required"),
  applicableTo: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
  tariff: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
    )
    .min(1, "Required")
    .required(),
});

const ServiceMasterForm = () => {
  const location = useLocation();
  const token = JSON.parse(localStorage.getItem("userInfo"));
  let navigate = useNavigate();

  //
  
  //
  const [servicesACData, setServicesACData] = useState([]);
  const [Group, setGroup] = useState([]);
  const [subGroup, setSubGroup] = useState([]);
  const [tariff, setTariff] = useState([]);
  const [unit, setUnit] = useState([]);

  // const [isPackageCreation, setIsPackageCreation] = useState(false);
  //package creation related
  const [dataResultHealthPlan, setDataResultHealthPlan] = useState([]);
  const [dataResultGroupWise, setDataResultGroupWise] = useState([]);
  const [dataResultServiceWise, setDataResultServiceWise] = useState([]);
  //

  const [classesAplicableTo, setClassesAplicableTo] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isHealthPlan, setIsHealthPlan] = useState(true);

  const [dataResultNri, setDataResultNri] = useState([]);
  const [dataResultHospi, setDataResultHospi] = useState([]);

  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [finalData, setFinalData] = useState("");
  //
  const [patchData, setPatchData] = useState(null);
  //
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      healthPlan: true,
      emgChrgDtls: "",
      appAllDrDtls: "",
      group: null,
      subGroup: null,
      tariff: null,
      applicableTo: { id: "B", label: "Both", value: "Both" },
      priceSet: 2,
      appDrChargeType: { id: 1, label: "Amount", value: "Amount" },
      emgChargeType: { id: 1, label: "Amount", value: "Amount" },
      active: true,
      unit: {
        id: token.unitId,
        label: token.unitName,
        value: token.unitName,
      },
      searchService: null,
      serviceQty: "",

      searchGroup: null,
      groupAmount: "",
      serviceCode: "",
      serviceName: "",
    },
  });
  const {
    formState: { errors },
    control,
    watch,
    register,
    reset,
    getValues,
    setError,
    resetField,
    setValue,
  } = methods;

  const group_selected = watch("group");
  const appDrChargeType_selected = watch("appDrChargeType");
  const emgChargeType_selected = watch("emgChargeType");
  let Price_Set = Number(watch("priceSet"));
  let tariff_selected = watch("tariff");
  let applicableTo_selected = watch("applicableTo");
  let isPackageCreation = watch("isPackage");
  //

  useEffect(() => {
    Price_Set === 2 || isHealthPlan
      ? setDataResultGroupWise([])
      : setDataResultServiceWise([]);
  }, [Price_Set, isHealthPlan]);

  const renderInputNri = (row, index, header) => {
    const handleInputChangeNri = (e, row, index) => {
      let tempN = [...dataResultNri];
      tempN[index] = { ...tempN[index], Rate: e.target.value };
      setDataResultNri([...tempN]);
    };

    return (
      <>
        {header === "Rate" && (
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-600">
              ₹
            </span>
            <input
              className="border border-customBlue rounded w-72 text-center pl-8 appearance-none px-2"
              placeholder=""
              value={row["Rate"]}
              onChange={(e) => {
                handleInputChangeNri(e, row, index);
              }}
            />
          </div>
        )}
      </>
    );
  };

  const renderInputHospi = (row, index, header) => {
    const handleInputChangeHospi = (e, row, index) => {
      let tempH = [...dataResultHospi];
      tempH[index] = { ...tempH[index], Rate: e.target.value };
      setDataResultHospi([...tempH]);
    };

    return (
      <>
        {header === "Rate" && (
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-600">
              ₹
            </span>
            <input
              className="border border-customBlue rounded w-72 text-center pl-8 appearance-none px-2"
              placeholder=""
              value={row.Rate}
              onChange={(e) => {
                handleInputChangeHospi(e, row, index);
              }}
            />
          </div>
        )}
      </>
    );
  };

  //////
  useEffect(() => {
    getGroups();
    getTariff();
    getUnit();
  }, []);

  useEffect(() => {
    group_selected && getSubGroups();
  }, [group_selected]);

  useEffect(() => {
    

    !location?.state?.isEdit &&
      applicableTo_selected !== null &&
      getClassesApplicableTo();
  }, [applicableTo_selected]);

  useEffect(() => {
    getPatchDetails();
  }, []);
  //////

  //tariff
  const getTariff = () => {
    fetchTariff()
      .then((response) => {
        setTariff(response.data.result);
      })
      .catch(() => {});
  };

  //unit
  const getUnit = () => {
    fetchUnit()
      .then((response) => {
        setUnit(response.data.result);
      })
      .catch(() => {});
  };

  //Groups
  const getGroups = () => {
    fetchGroups()
      .then((response) => {
        setGroup(response.data.result);
      })
      .catch(() => {});
  };

  //sub Groups
  const getSubGroups = () => {
    fetchSubGroups(group_selected?.id)
      .then((response) => {
        setSubGroup(response.data.result);
      })
      .catch(() => {});
  };

  //Patch data
  const getPatchDetails = () => {
    fetchPatchDetails(location?.state?.row?.ServiceId)
      .then((response) => {
        setPatchData(response.data.result);
      })
      .catch(() => {});
  };

  //Groups
  const getClassesApplicableTo = () => {
    
    let opdIpdBoth =
      applicableTo_selected?.id === "B"
        ? 2
        : applicableTo_selected?.id === "I"
        ? 1
        : 0;

    applicableTo_selected &&
      fetchClassesApplicableTo(opdIpdBoth).then((response) => {
        // setClassesAplicableTo(response.data.result);  "Rate"

        let newResponse = response.data.result.map((item) => ({
          ...item,
          Rate: "",
        }));

        setDataResultNri(newResponse);
        setDataResultHospi(newResponse);
      });
  };

  ///////package creation related
  const getServicesData = (serchString) => {
    serchString &&
      fetchServices(serchString)
        .then((response) => response.data)
        .then((res) => {
          setServicesACData(res.result);
        });
  };

  const renderActionsHealthPlan = () => {
    return (
      <>
        <div className="">
          <EditOnIcon title={"Edit"} onClick={() => {}} />
          <DeleteOnIcon title={"Delete"} onClick={() => {}} />
        </div>
      </>
    );
  };
  const renderActionsGroupWise = (row, index) => {
    const handleDelete = (index) => {
      let tempG = [...dataResultGroupWise];
      tempG.splice(index, 1);
      setDataResultGroupWise(tempG);
    };
    return (
      <>
        <div className="">
          {/* <EditOnIcon title={"Edit"} onClick={() => {}} /> */}
          <DeleteOnIcon
            title={"Delete"}
            onClick={() => {
              handleDelete(index);
            }}
          />
        </div>
      </>
    );
  };
  const renderActionsServiceWise = (row, index) => {
    // const handleDelete = (row) => {
    //   let temp = [...dataResultServiceWise];
    //   const serviceCodeToDelete = row["Service Code"];
    //   const item = temp.find(
    //     (item) => item["Service Code"] === serviceCodeToDelete
    //   );
    //   if (item) item.isDelete = true;
    //   
    //   setDataResultServiceWise(temp);
    // };

    const handleDelete = (row) => {
      setDataResultServiceWise((prevData) =>
        prevData.map((item) =>
          item["ServiceId"] === row["ServiceId"]
            ? { ...item, isDelete: true }
            : item
        )
      );
    };

    const handleEdit = (row) => {
      
      setValue("searchService", {
        id: row["ServiceId"],
        label: row["Service Name"],
        label: row["Service Name"],
      });
      setValue("serviceQty", row["Service Qty"]);
    };

    return (
      <>
        <div className="">
          <EditOnIcon
            title={"Edit"}
            onClick={() => {
              handleEdit(row);
            }}
          />
          <DeleteOnIcon
            title={"Delete"}
            onClick={() => {
              handleDelete(row);
            }}
          />
        </div>
      </>
    );
  };

  // const renderInputHealthPlan = (row, index, header) => {
  //   const handleInputChange = (e, row, index) => {
  //     let temp = [...dataResultHealthPlan];
  //     temp[index][header] = e.target.value;
  //     setDataResultHealthPlan([...temp]);
  //   };

  //   return (
  //     <>
  //       <div className="">
  //         <input
  //           className="border border-customBlue rounded w-24 text-center pl-8 appearance-none px-2"
  //           placeholder=""
  //           onChange={(e) => {
  //             handleInputChange(e, row, index, header);
  //           }}
  //         />
  //       </div>
  //     </>
  //   );
  // };
  // const renderInputServiceWise = (row, index, header) => {
  //   const handleInputChange = (e, row, index) => {
  //     let temp = [...dataResultHealthPlan];
  //     temp[index][header] = e.target.value;
  //     setDataResultHealthPlan([...temp]);
  //   };
  //   return (
  //     <>
  //       <div className="">
  //         <input
  //           className="border border-customBlue rounded w-24 text-center pl-8 appearance-none px-2"
  //           placeholder=""
  //           defaultValue={row[header]}
  //           onChange={(e) => {
  //             handleInputChange(e, row, index, header);
  //           }}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  // Function to exclude keys dynamically
  // const getEditableColumnsHealthPlan = (data) => {
  //   const keysToExclude = ["Service Code", "Service Name", "Qty"];
  //   let keys = [];
  //   if (data?.length > 0) {
  //     const keys = Object.keys(data[0]);
  //   }

  //   return keys.filter((key) => !keysToExclude.includes(key));
  // };

  // const getEditableColumnsServiceWise = (data) => {
  //   const keysToExclude = ["Service Code", "Service Name"];
  //   let keys = [];
  //   if (data?.length > 0) {
  //     const keys = Object.keys(data[0]);
  //   }

  //   return keys.filter((key) => !keysToExclude.includes(key));
  // };

  // const editableColumnsHealthPlan =
  //   getEditableColumnsHealthPlan(dataResultHealthPlan);
  // const editableColumnsServiceWise =
  //   getEditableColumnsServiceWise(dataResultHealthPlan);

  /////////////Add data in table

  const handleAddServices = () => {
    const service_selected = getValues("searchService");
    const qty_Entered = getValues("serviceQty");

    if (service_selected === null) {
      setError("searchService", { type: "custom", message: "required" });
    }
    if (qty_Entered === "") {
      setError("serviceQty", { type: "custom", message: "required" });
    }

    if (service_selected !== null && qty_Entered !== "") {
      const newObj = {
        "Service Name": service_selected?.label,
        ServiceId: service_selected?.id,
        "Service Qty": Number(qty_Entered),
        isDelete: false,
      };

      let serviceAlreadyExists = false;

      serviceAlreadyExists = dataResultServiceWise.some(
        (item) => !item.isDelete && item["ServiceId"] === newObj["ServiceId"]
      );

      if (serviceAlreadyExists) {
        warningAlert("Service Already Exists...");
      } else {
        setDataResultServiceWise((prevData) => [...prevData, newObj]);
      }

      resetField("searchService");
      resetField("serviceQty");
    }
  };

  const handleAddGroups = () => {
    const searchGroup_selected = getValues("searchGroup");
    const amt_Entered = getValues("groupAmount");

    if (searchGroup_selected === null) {
      setError("searchGroup", { type: "custom", message: "required" });
    }
    if (amt_Entered === "") {
      setError("groupAmount", { type: "custom", message: "required" });
    }

    if (searchGroup_selected !== null && amt_Entered !== "") {
      const newObj = {
        "Group Name": searchGroup_selected?.label,
        "Group Code": searchGroup_selected?.id,
        Amount: Number(amt_Entered),
        isDelete: false,
      };

      let groupAlreadyExists = false;

      groupAlreadyExists = dataResultGroupWise.some(
        (item) => !item.isDelete && item["Group Code"] === newObj["Group Code"]
      );

      if (groupAlreadyExists) {
        warningAlert("Group Already Exists...");
      } else {
        setDataResultGroupWise((prevData) => [...prevData, newObj]);
      }

      resetField("searchGroup");
      resetField("groupAmount");
    }
  };

  /////////////final object and save //////////
  // createNewService;

  const onSubmit = (data) => {
    setFinalData(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);
    ///confusion about tariff,nonBillable,Apply Sub Group Rate,tds,emergencyPercentAmountFlag

    
    
    //make tariff dto
    let tariffDto = tariff_selected.map((tariff) => {
      
      let dataResult =
        tariff?.label === "Hospital" ? dataResultHospi : dataResultNri;

      let classRateDtoList = dataResult.map((item) => ({
        amount: item["Rate"],
        classId: item.classId || item.ClassId,
        classRateId: item.classRateId || 0,
      }));

      return {
        classRateDtoList,
        tariffId: tariff.id,
        tariffServiceId: tariff.TariffServiceId || 0, ///new
      };
    });

    //make Group dto
    let packageGroupDtoList = dataResultGroupWise.map((item) => {
      return {
        amount: item.Amount,
        groupId: item["Group Code"],
        groupServiceId: item.groupServiceId,
        isDelete: false, //
      };
    });

    ///make service dto
    let serviceDto = dataResultServiceWise.map((item) => {
      return {
        isHealthPlan: isHealthPlan,
        qty: item["Service Qty"],
        serviceId: item.ServiceId,
        isDelete: false,
        packageServiceId: item.packageServiceId || 0,
      };
    });

    ///////
    let finalObj = {
      addedBy: token.userId,
      adminCharges: data.adminChargesApplicable || false,
      appForAllDoctors: data.applicableAllDr || false,
      applicable: true,
      applicableFrom: "2023-10-04T04:36:11.502Z",
      applicablePer: 0,
      applicableTo:
        data?.applicableTo?.label === "OPD"
          ? "O"
          : data?.applicableTo?.label === "IPD"
          ? "I"
          : data?.applicableTo?.label === "Both"
          ? "B"
          : "",
      approvedBy: 0,
      billable: true,
      categoryId: 0,
      classIds: [0],
      code: "string",
      codeType: 0,
      compId: 0,
      concesHoliday: data.concessionOnHoliday || false,
      concesSenior: data.concessionToSeniorCitizen || false,
      concession: data.concessionApplicable || false,
      cost: 0,
      description: "string",
      doctorAmount:
        data.appDrChargeType?.label === "Amount" ? data.appAllDrDtls || 0 : 0,
      doctorPercent:
        data.appDrChargeType?.label === "Percentage"
          ? data.appAllDrDtls || 0
          : 0,
      doctorShare: data.doctorShareApplicable || false,
      emergency: data.emergencyChargesApplicable || false,
      emergencyPercentAmount: data.emgChrgDtls || 0,
      emergencyPercentAmountFlag: true,
      firstOccurance: true,
      free: data.free || 0,
      generatePatientPackageSchedule: true,
      groupId: data?.group?.id || 0,
      gstAmountLimit: data.gstPecentage || 0,
      healthplanPackage: true,
      holidayAppTo: "", ////////
      installment: true,
      instruction: data.instructions || "",
      ipfPercentage: 0,
      isAppService: true,
      isApproved: true,
      isDisplayOnDetailBill: data.displayOnDetailBill || 0,
      isExternal: data?.isExternal || false,
      isGSTApplicable: data.gst || false,
      isMultipleServiceQuantity: true,
      isOTProcedure: data.oTProcedure || false,
      isPackage: data?.isPackage || false,
      isServiceTaxApp: data.serviceTax || false,
      isStandardPackage: true,
      isSynchronised: true,
      menuId: 0,
      opPackageApp: data.oPPackage || false,
      outSource: data.outSource || false,
      packageActivityDays: 0,
      packageGroupDtoList: packageGroupDtoList, ///group dto
      packageServiceIds: serviceDto, //service dto
      printOrder: 0,
      privilege: "", /////
      rateEditable: data.rateEditable || false,
      referingGroupId: 0,
      serviceCode: data.serviceCode || "",
      serviceName: data.serviceName || "", //key not from backend
      serviceId: location?.state?.row?.ServiceId || 0,
      staffDD: data.staffDependentDisc || false,
      staffDDPer: data.staffDependentPerc || 0,
      staffPDD: data.staffParentDependentDisc || false,
      staffPDDPer: data.staffParentDependentperc || 0,
      status: true,
      subGroupId: data?.subGroup?.id || 0,
      tariffRateDtoList: tariffDto,
      tdsPer: data.tdsPecentage || 0,
      transApp: true,
      unitId: data?.unit?.id || 0,
      updatedBy: 0,
      validDateForPack: "2023-10-04T04:36:11.502Z",
      websidelink: "", /////
    };

    setOpenBackdrop(true);

    createNewService(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);

          methods.reset();
          setDataResultGroupWise(null);
          setDataResultNri(null);
          setDataResultServiceWise(null);
          location?.state?.isEdit && navigate("/masters/billing/service");
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    handlePatchData();
  }, [patchData]);

  const handlePatchData = () => {
    if (patchData) {
      setValue("group", {
        id: patchData.GroupId,
        label: patchData["Group Name"],
        value: patchData["Group Name"],
      });

      setValue("subGroup", {
        id: patchData.SubGroupId,
        label: patchData["Sub Group"],
        value: patchData["Sub Group"],
      });

      setValue("serviceName", patchData["Service Name"]);
      setValue("serviceCode", patchData.ServiceCode);

      let aplTo = AplicableToData.find((item) => {
        item.id = patchData["ApplicableTo"];
      });

      setValue(
        "applicableTo",

        {
          id: patchData.ApplicableTo,
          label:
            patchData.ApplicableTo === "B"
              ? "Both"
              : patchData.ApplicableTo === "O"
              ? "OPD"
              : "IPD",
          value:
            patchData.ApplicableTo === "B"
              ? "Both"
              : patchData.ApplicableTo === "O"
              ? "OPD"
              : "IPD",
        }
      );
      // setValue("tariff", patchData.active); //////missing

      setValue("unit", {
        id: patchData.UnitId,
        label: patchData["Unit Name"],
        value: patchData["Unit Name"],
      });
      setValue("active", patchData.active); ///// "Status"=Active
      setValue("isPackage", patchData.IsPackage);
      setValue("isExternal", patchData.IsExternal);
      setValue("applySubGroupRate", patchData.active);
      setValue("oPPackage", patchData.OPPackageApp);
      setValue("oTProcedure", patchData.IsOTProcedure);
      setValue("nonBillable", patchData.Billable);
      setValue("serviceTax", patchData.IsServiceTaxApp);
      setValue("doctorShareApplicable", patchData.DoctorShare);
      setValue("adminChargesApplicable", patchData.AdminCharges);
      setValue("rateEditable", patchData.RateEditable);
      setValue("free", patchData.Free);
      setValue("displayOnDetailBill", patchData.IsDisplayOnDetailBill);
      setValue("concessionToSeniorCitizen", patchData.ConcesSenior);
      setValue("concessionOnHoliday", patchData.ConcesHoliday);
      setValue("outSource", patchData.OutSource);
      setValue("tds", Number(patchData.TDSPer) > 0 ? true : false); ///////
      setValue("tdsPecentage", patchData.TDSPer);
      setValue("tdsPecentage", patchData.TDSPer);
      setValue("gst", patchData.IsGSTApplicable); //////
      setValue("concessionApplicable", patchData.Concession);
      setValue("concessionApplicablePecentage", patchData.active); ////
      setValue("staffParentDependentDisc", patchData.StaffPDD);
      setValue("staffParentDependentperc", patchData.StaffDDPer);
      setValue("staffDependentDisc", patchData.StaffDD);
      setValue("applicableAllDr", patchData.AppForAllDoctors);

      setValue(
        "appDrChargeType",
        patchData.DoctorAmount > 0
          ? { id: 1, label: "Amount", value: "Amount" }
          : { id: 2, label: "Percentage", value: "Percentage" }
      ); ///////

      setValue(
        "appAllDrDtls",
        patchData.DoctorAmount > 0
          ? patchData.DoctorAmount
          : patchData.DoctorPercent > 0
          ? patchData.DoctorPercent
          : ""
      ); ////

      setValue(
        "emergencyChargesApplicable",
        patchData.Emergency_Percent_Amount_Flag
      );

      setValue("emgChargeType", patchData.Emergency);
      setValue(
        "emgChargeType",
        patchData.Emergency_Percent_Amount_Flag
          ? { id: 1, label: "Amount", value: "Amount" }
          : { id: 2, label: "Percentage", value: "Percentage" }
      );
      setValue("emgChrgDtls", patchData.Emergency_Percent_Amount);
      setValue("instructions", patchData.Instruction);

      //tariff dropdown
      let retrived_Tariff = patchData.TariffInfo.map((item) => {
        return {
          id: item.TariffId,
          label: item["Tariff Name"],
          value: item["Tariff Name"],
          TariffServiceId: item["TariffServiceId"], //need for update record
        };
      });

      setValue("tariff", retrived_Tariff);

      //tariff table
      let nriTariffData = patchData.TariffInfo.find((item) => {
        return item["Tariff Name"] === "Nri";
      });
      let hospiTariffData = patchData.TariffInfo.find((item) => {
        return item["Tariff Name"] === "Hospital";
      });

      setDataResultNri(nriTariffData?.ClassRateInfo);
      setDataResultHospi(hospiTariffData?.ClassRateInfo);

      //package service

      setDataResultServiceWise(patchData.PackageServices);

      //groupwise service

      setDataResultGroupWise(patchData.PackageGroups);

      if (patchData?.PackageGroups?.length > 0) {
        setIsHealthPlan(false);
        setValue("healthPlan", false);
        setValue("priceSet", 1);
      }
    }
  };

  
  return (
    <div className="mt-12">
      <div className="text-center text-black font-bold text-xl ">
        Service Creation
      </div>

      <form>
        <div className="mt-3 grid lg:grid-cols-4 gap-3 md:grid-cols-2">
          <div className="">
            <DropdownField
              control={control}
              name="group"
              error={errors.group}
              placeholder="Group*"
              dataArray={Group}
              isSearchable={false}
              inputRef={{
                ...register("group", {
                  onChange: (e) => {
                    getSubGroups();
                  },
                }),
              }}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              error={errors.subGroup}
              name="subGroup"
              placeholder="Sub Group*"
              dataArray={subGroup}
              isSearchable={false}
            />
          </div>

          <div className="">
            <InputField
              name="serviceName"
              error={errors.serviceName}
              label="Service Name*"
              variant="outlined"
              control={control}
              disabled={false}
              // inputProps={{ maxLength: 5 }}
              // maxLength={5}
            />
          </div>

          <div className="">
            <InputField
              name="serviceCode"
              error={errors.serviceCode}
              label="Service Code*"
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              name="applicableTo"
              error={errors.applicableTo}
              placeholder="Applicable To*"
              dataArray={AplicableToData}
              isSearchable={false}
              inputRef={{
                ...register("applicableTo", {
                  onChange: (e) => {
                    getClassesApplicableTo(e.target.value);
                  },
                }),
              }}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              error={errors.tariff}
              name="tariff"
              placeholder="Tariff*"
              isMulti={true}
              dataArray={tariff}
              isSearchable={false}
              inputRef={{
                ...register("tariff", {
                  onChange: (e) => {
                    getClassesApplicableTo();
                  },
                }),
              }}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              name="unit"
              placeholder="Unit"
              dataArray={unit}
              isSearchable={false}
            />
          </div>
          <div className="">
            <CheckBoxField control={control} name="active" label="Active" />
          </div>
          <div className="">
            <div
            // onClick={(e) => {
            //   setIsPackageCreation(e.target.checked);
            // }}
            >
              <CheckBoxField
                control={control}
                name="isPackage"
                label="Is Package "
              />
            </div>
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="isExternal"
              label="Is External "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="applySubGroupRate"
              label="Apply Sub Group Rate "
            />
          </div>

          <div className="">
            <CheckBoxField
              control={control}
              name="oPPackage"
              label="OP Package "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="oTProcedure"
              label="OT Procedure "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="nonBillable"
              label="Non Billable "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="serviceTax"
              label="Service Tax  "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="doctorShareApplicable"
              label="Doctor Share Applicable "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="adminChargesApplicable"
              label="Admin Charges Applicable"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="rateEditable"
              label="Rate Editable "
            />
          </div>
          <div className="">
            <CheckBoxField control={control} name="free" label="Free" />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="displayOnDetailBill"
              label="Display on Detail Bill"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="concessionToSeniorCitizen"
              label="Concession To Senior Citizen"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="concessionOnHoliday"
              label="Concession On Holiday"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="outSource"
              label="Out Source "
            />
          </div>

          <div className="flex justify-between">
            <div>
              <CheckBoxField control={control} name="tds" label="TDS" />
            </div>
            <div className="w-16">
              <InputLimit
                name="tdsPecentage"
                label="%"
                type={"number"}
                maxDigits={3}
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="flex justify-between">
            <CheckBoxField control={control} name="gst" label="GST%" />
            <div className="w-16">
              <InputLimit
                name="gstPecentage"
                label="%"
                type={"number"}
                maxDigits={3}
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <CheckBoxField
              control={control}
              name="concessionApplicable"
              label="Concession Applicable"
            />

            <div className="w-16">
              <InputLimit
                name="concessionApplicablePecentage"
                label="%"
                type={"number"}
                maxDigits={3}
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="flex justify-between">
            <CheckBoxField
              control={control}
              name="staffParentDependentDisc."
              label="Staff Parent Dependent Disc. "
            />
            <div className="w-16">
              <InputLimit
                name="staffParentDependentperc"
                label="%"
                type={"number"}
                maxDigits={3}
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <CheckBoxField
              control={control}
              name="staffDependentDisc."
              label="Staff Dependent Disc. "
            />
            <div className=" w-16">
              <InputLimit
                name="staffDependentPerc"
                label="%"
                type={"number"}
                maxDigits={3}
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="md:col-span-2 lg:col-span-4 grid md:grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="flex">
              <div className="">
                <CheckBoxField
                  control={control}
                  name="applicableAllDr."
                  label="Applicable All Dr. "
                />
              </div>

              <div className=" grid grid-cols-2 gap-3">
                <div className="">
                  <DropdownField
                    control={control}
                    name="appDrChargeType"
                    placeholder="Amount/Percentage"
                    dataArray={ChargesType}
                    isSearchable={false}
                    // inputRef={{
                    //   ...register("appDrChargeType", {
                    //     // onChange: (e) => {
                    //     //   getSubGroups();
                    //     // },
                    //   }),
                    // }}
                  />
                </div>
                <div className="">
                  <InputField
                    name="appAllDrDtls"
                    label={appDrChargeType_selected?.label}
                    type={"number"}
                    maxDigits={3}
                    variant="outlined"
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="">
                <CheckBoxField
                  control={control}
                  name="emergencyChargesApplicable"
                  label="Emergency Charges Applicable  "
                />
              </div>

              <div className="lg:col-span-2 grid grid-cols-2 gap-3">
                <div className="">
                  <DropdownField
                    control={control}
                    name="emgChargeType"
                    placeholder="Amount/Percentage"
                    dataArray={ChargesType}
                    isSearchable={false}
                    // inputRef={{
                    //   ...register("appDrChargeType", {
                    //     // onChange: (e) => {
                    //     //   getSubGroups();
                    //     // },
                    //   }),
                    // }}
                  />
                </div>
                <div className="">
                  <InputField
                    name="emgChrgDtls"
                    label={emgChargeType_selected?.label}
                    type={"number"}
                    maxDigits={3}
                    variant="outlined"
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:mt-3">
          <InputField
            name="instructions"
            label="Instructions"
            variant="outlined"
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        {/* /////////if package creation//////// */}

        {isPackageCreation && (
          <>
            <hr className=" mx-1 h-[0px] border border-gray-300 my-2" />
            <div className=" mt-2 items-center align-middle">
              <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
                Package Creation
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-3 my-3">
                <div className="">
                  <InputField
                    name="activityDays"
                    label="Activity Days"
                    variant="outlined"
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div
                  className=""
                  onChange={() => {
                    setIsHealthPlan(!isHealthPlan);
                  }}
                >
                  <CheckBoxField
                    control={control}
                    name="healthPlan"
                    label="Health Plan"
                    disabled={location?.state?.isEdit}
                    disableColor
                  />
                </div>

                {!isHealthPlan && (
                  <fieldset
                    className="col-span-2 flex gap-3 items-center"
                    disabled={location?.state?.isEdit}
                  >
                    <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                      Set Price :
                    </p>
                    <RadioField
                      label=""
                      name="priceSet"
                      control={control}
                      dataArray={PriceSet}
                    />
                  </fieldset>
                )}
              </div>

              <hr className=" mx-1 h-[0px] border border-gray-300 my-2" />
              {(isHealthPlan || Number(Price_Set) === 2) && (
                <div className=" py-2 grid grid-cols-8 gap-3">
                  <div className="md:col-span-2 lg:col-span-3 mb-3">
                    <SearchDropdown
                      control={control}
                      placeholder="Service"
                      dataArray={servicesACData}
                      name="searchService"
                      searchIcon={true}
                      isClearable={true}
                      error={errors.searchService}
                      handleInputChange={getServicesData}
                      //   isDisabled={isDisableSearch}
                      inputRef={{
                        ...register("searchService", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              setSelectedPatient(e.target.value);
                              setValue("serviceQty", 1);
                            } else {
                              setSelectedPatient("");
                              methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="">
                    <InputField
                      name="serviceQty"
                      error={errors.serviceQty}
                      label="Quantity"
                      variant="outlined"
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div>
                    <CommonButton
                      label="Add"
                      className="bg-customBlue text-white"
                      onClick={() => {
                        handleAddServices();
                      }}
                      disabled={location?.state?.isEdit}
                    />
                  </div>
                </div>
              )}
              {!isHealthPlan && Number(Price_Set) === 1 && (
                <div className=" py-2 grid grid-cols-8 gap-3">
                  <div className="md:col-span-2 lg:col-span-3 mb-3">
                    <DropdownField
                      control={control}
                      name="searchGroup"
                      error={errors.searchGroup}
                      placeholder="Group"
                      dataArray={Group}
                      isSearchable={false}
                    />
                  </div>
                  <div className="">
                    <InputField
                      name="groupAmount"
                      label="Amount"
                      error={errors.groupAmount}
                      variant="outlined"
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div>
                    <CommonButton
                      label="Add"
                      className="bg-customBlue text-white"
                      onClick={() => {
                        handleAddGroups();
                        
                      }}
                      disabled={location?.state?.isEdit}
                    />
                  </div>
                </div>
              )}

              {/* {isHealthPlan && dataResultHealthPlan?.length > 0 && (
                <div className=" ">
                  <CommonDynamicTableNew
                    dataResult={dataResultHealthPlan}
                    // renderInput={renderInputHealthPlan}
                    // editableColumns={editableColumnsHealthPlan}
                    highlightRow={false}
                    renderActions={renderActionsHealthPlan}
                  />
                </div>
              )} */}

              {!isHealthPlan &&
                Number(Price_Set) === 1 &&
                dataResultGroupWise?.length > 0 && (
                  <div className=" ">
                    <CommonDynamicTableNew
                      dataResult={dataResultGroupWise}
                      renderActions={
                        !location?.state?.isEdit
                          ? renderActionsGroupWise
                          : false
                      }
                      highlightRow={false}
                      removeHeaders={[
                        "isDelete",
                        "ServiceId",
                        "GroupId",
                        "GroupServiceId",
                        "TariffServiceId",
                      ]}
                    />
                  </div>
                )}

              {(isHealthPlan || Number(Price_Set) === 2) &&
                dataResultServiceWise?.length > 0 &&
                dataResultServiceWise.filter((item) => !item.isDelete).length >
                  0 && (
                  <div className=" ">
                    <CommonDynamicTableNew
                      //dataResult={dataResultServiceWise}
                      dataResult={dataResultServiceWise.filter(
                        (item) => !item.isDelete
                      )}
                      // renderInput={renderInputServiceWise}
                      // editableColumns={editableColumnsServiceWise}
                      renderActions={
                        !location?.state?.isEdit
                          ? renderActionsServiceWise
                          : false
                      }
                      highlightRow={false}
                      removeHeaders={[
                        "isDelete",
                        "ServiceId",
                        "PackageServiceId",
                      ]}
                    />
                  </div>
                )}
            </div>
          </>
        )}

        {/* //////////////////////////// */}

        <>
          {tariff_selected?.length > 0 && (
            <hr className=" mx-1 h-[0px] border border-gray-300 my-2" />
          )}

          <div className="grid grid-cols-2 gap-3">
            {tariff_selected?.length > 0 && (
              <>
                {tariff_selected.map((tariff) => {
                  return (
                    <>
                      {tariff?.label === "Hospital" &&
                        dataResultHospi?.length > 0 && (
                          <div
                            className="border shadow rounded my-3"
                            key={tariff.id}
                          >
                            <div className="flex justify-between items-center bg-[#FFF6E1] py-2 px-3">
                              <h1 className="w-[59px] h-[15px] text-black text-[17px] font-semibold">
                                Hospital
                              </h1>

                              <CancelOnIcon
                              // onClick={(e) => {
                              //   handleClose();
                              //   e.preventDefault();
                              // }}
                              />
                            </div>

                            <div className="h">
                              <div className=" ">
                                <CommonDynamicTableNew
                                  dataResult={dataResultHospi}
                                  renderInput={renderInputHospi}
                                  editableColumns={["Rate"]}
                                  removeHeaders={[
                                    "ClassId",
                                    "classId",
                                    "classRateId",
                                  ]}
                                  highlightRow={false}
                                  tableClass="h-52"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {tariff?.label === "Nri" && dataResultNri?.length > 0 && (
                        <div
                          className="border shadow rounded my-3"
                          key={tariff.id}
                        >
                          <div className="flex justify-between items-center bg-[#a7fcc9] py-2 px-3">
                            <h1 className="w-[59px] h-[15px] text-black font-semibold">
                              NRI
                            </h1>

                            <CancelOnIcon
                            // onClick={(e) => {
                            //   handleClose();
                            //   e.preventDefault();
                            // }}
                            />
                          </div>
                          <div className="">
                            <div className=" ">
                              <CommonDynamicTableNew
                                dataResult={dataResultNri}
                                renderInput={renderInputNri}
                                editableColumns={["Rate"]}
                                removeHeaders={[
                                  "ClassId",
                                  "classId",
                                  "classRateId",
                                ]}
                                highlightRow={false}
                                tableClass="h-52"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </div>
        </>

        <div className="flex justify-end my-3">
          <CommonButton
            label="Save"
            className="bg-customGreen text-white"
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
          />
        </div>
      </form>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default ServiceMasterForm;
