import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

export default function EligibilityCriteriaTable(props) {
  const [checkedValue, setCheckedValue] = useState();
  const [newEligibilityTableData, setNewEligibilityTableData] = useState([]);
  const {
    EligibilityTableData,
    setEligibilityTableData,
    NIHStrokeSheetDetails,
    getNIHStrokeId,
    patientInfo,
    patientDetails,
  } = props;
  const { control, setValue, register } = useForm();

  useEffect(() => {
    let temp = [...EligibilityTableData];
    for (let i = 0; i < temp.length; i++) {
      setValue(`yes${i}`, temp[i].yes);
      setValue(`no${i}`, temp[i].no);
    }
    setNewEligibilityTableData([...temp]);
  }, [EligibilityTableData]);

  // useEffect(() => {
  //   let merged = [];

  //   for (let i = 0; i < EligibilityTableData.length; i++) {
  //     merged.push({
  //       ...EligibilityTableData[i],
  //       ...NIHStrokeSheetDetails?.eligibleCriteria[i],
  //     });
  //   }
  //   for (let i = 0; i < merged.length; i++) {
  //     if (merged[i].YesNo === 1) {
  //       merged[i].yes = "true";
  //       merged[i].no = "false";
  //     } else if (merged[i].YesNo === 2) {
  //       merged[i].yes = "false";
  //       merged[i].no = "true";
  //     } else if (merged[i].YesNo === 0) {
  //       merged[i].yes = "false";
  //       merged[i].no = "false";
  //     }
  //   }
  //   setEligibilityTableData(merged);
  // }, [NIHStrokeSheetDetails]);


  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Sr.No
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Description
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Yes
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        No
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newEligibilityTableData?.map((data, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0.0,
                          },
                        }}
                      >
                        <>
                          <TableCell className="whitespace-nowrap">
                            {data.srNo}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.header}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            <fieldset
                              onChange={(e) => {
                                setCheckedValue(e);
                                if (e.target.checked === true) {
                                  data.yes = true;
                                  data.no = false;
                                  setValue(`no${index}`, false);
                                }
                              }}
                              disabled={
                                patientInfo === null && patientDetails === null
                              }
                            >
                              <CheckBoxField
                                control={control}
                                name={`yes${index}`}
                                defaultValue={data?.yes}
                                {...register(`yes${index}`)}
                              />
                            </fieldset>
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            <fieldset
                              onChange={(e) => {
                                setCheckedValue(e);
                                if (e.target.checked === true) {
                                  data.no = true;
                                  data.yes = false;
                                  setValue(`yes${index}`, false);
                                }
                              }}
                              disabled={
                                patientInfo === null && patientDetails === null
                              }
                            >
                              <CheckBoxField
                                control={control}
                                name={`no${index}`}
                                defaultValue={data?.no}
                                {...register(`no${index}`)}
                              />
                              {/* <input
                                type="checkbox"
                                name={`no${index}`}
                                checked={data.no}
                              /> */}
                            </fieldset>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
