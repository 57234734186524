import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { Tooltip } from "@mui/material";

export default function PurchaseOrderTable(props) {
  // const { poData, setDataResult } = props;
  const methods = useForm({
    mode: "onChange",
  });
  const { register, watch, setValue, getValues, handleSubmit, control } =
    methods;

  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.poData];
      newTemplateData.splice(deleteIndex, 1);
      props.setPoData(newTemplateData);
      handleClosePost();
    }
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                },
              }}
              className="rounded h-56"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Action</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Item Name</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Quantity</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">U.O.M.</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Rate/Quantity
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Total Amount
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Disc. (%)</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Dics. Amount
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">GST(%)</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        GST Amount
                      </span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Net Amount
                      </span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">MRP</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">
                        Specification
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.poData.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Tooltip title="Delete">
                            <span
                              className="text-red-500 mr-3"
                              onClick={() => {
                                setOpenPost(true);
                                setDeleteIndex(index);
                              }}
                            >
                              {<DeleteOutlineOutlinedIcon />}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.searchItemCode.label}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.quantity}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.uom}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.rate}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.totalAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.discPercentage}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.discAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.gstPercentage}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.gstAmount}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {row.netAmount}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.mrp}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.specification}
                        </TableCell>
                       
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {props.poData.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-12">
                  No Items Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this item ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
