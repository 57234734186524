//function to patch the bed score assessment table
function patchBedScoreTable(bedScoreAssPatchDtoObjVal, methods) {
  
   

  let tempbedScoreAssessmentArray = [
    {
      sensoryPerCompletelyLimited: false,
      score: 1,
      flagValue: false,
    },
    {
      sensoryPerVeryLimited: false,
      score: 2,
      flagValue: false,
    },
    {
      sensoryPerSlightlyLimited: false,
      score: 3,
      flagValue: false,
    },
    {
      sensoryPerNoImpairment: false,
      score: 4,
      flagValue: false,
    },

    {
      moistureCompletelyLimited: false,
      score: 1,
      flagValue: false,
    },
    {
      moistureVeryLimited: false,
      score: 2,
      flagValue: false,
    },
    {
      moistureOccasionallyLimited: false,
      score: 3,
      flagValue: false,
    },
    {
      rarelyMoist: false,
      score: 4,
      flagValue: false,
    },

    {
      activeBedLying: false,
      score: 1,
      flagValue: false,
    },
    {
      activeChairDependent: false,
      score: 2,
      flagValue: false,
    },
    {
      activeWalksOccasionally: false,
      score: 3,
      flagValue: false,
    },
    {
      activeWalksFrequency: false,
      score: 4,
      flagValue: false,
    },

    {
      mobilityCompletelyLimited: false,
      score: 1,
      flagValue: false,
    },
    {
      mobilityVeryLimited: false,
      score: 2,
      flagValue: false,
    },
    {
      mobilitySlightlyLimited: false,
      score: 3,
      flagValue: false,
    },
    {
      mobilityNoImpairment: false,
      score: 4,
      flagValue: false,
    },

    {
      nutritionVeryPoor: false,
      score: 1,
      flagValue: false,
    },
    {
      nutritionProbablyInadequate: false,
      score: 2,
      flagValue: false,
    },

    {
      nutritionAdequate: false,
      score: 3,
      flagValue: false,
    },

    {
      nutritionExcellent: false,
      score: 4,
      flagValue: false,
    },

    {
      frictionAndShearProblem: false,
      score: 1,
      flagValue: false,
    },
    {
      frictionAndShearPotentialProblem: false,
      score: 2,
      flagValue: false,
    },
    {
      frictionAndShearNoApparentProblem: false,
      score: 4,
      flagValue: false,
    },
  ];

  //looping statement to modify the state variable bedScoreAssessmentArray inside the InitialNursingModal.
  for (let requiredPropertyName in bedScoreAssPatchDtoObjVal) {
    
    

    //required key values that are to be found.
    let keyObject = null;
    let keyObjectIndex = null;

    for (let index = 0; index < tempbedScoreAssessmentArray.length; index++) {
      let tempbedScoreAssessmentObj = tempbedScoreAssessmentArray[index];

      if (tempbedScoreAssessmentObj.hasOwnProperty(requiredPropertyName)) {
        keyObject = tempbedScoreAssessmentObj;
        keyObjectIndex = index;

        if (bedScoreAssPatchDtoObjVal[requiredPropertyName]) {
          keyObject.flagValue = true;
        } else {
          keyObject.flagValue = false;
        }
        break;
      }
    }

    if (keyObjectIndex) {
      tempbedScoreAssessmentArray[keyObjectIndex] = keyObject;
    }
  }

  
 

  //sensory perception
  if (bedScoreAssPatchDtoObjVal.sensoryPerCompletelyLimited) {
    methods.setValue("sensoryPerCompletelyLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.sensoryPerNoImpairment) {
    methods.setValue("sensoryPerNoImpairment", true);
  }

  if (bedScoreAssPatchDtoObjVal.sensoryPerSlightlyLimited) {
    methods.setValue("sensoryPerSlightlyLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.sensoryPerVeryLimited) {
    methods.setValue("sensoryPerVeryLimited", true);
  }

  //moisture options
  if (bedScoreAssPatchDtoObjVal.moistureCompletelyLimited) {
    methods.setValue("moistureCompletelyLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.moistureOccasionallyLimited) {
    methods.setValue("moistureOccasionallyLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.moistureVeryLimited) {
    methods.setValue("moistureVeryLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.rarelyMoist) {
    methods.setValue("rarelyMoist", true);
  }

  //active options
  if (bedScoreAssPatchDtoObjVal.activeBedLying) {
    methods.setValue("activeBedLying", true);
  }

  if (bedScoreAssPatchDtoObjVal.activeChairDependent) {
    methods.setValue("activeChairDependent", true);
  }

  if (bedScoreAssPatchDtoObjVal.activeWalksFrequency) {
    methods.setValue("activeWalksFrequency", true);
  }

  if (bedScoreAssPatchDtoObjVal.activeWalksOccasionally) {
    methods.setValue("activeWalksOccasionally", true);
  }

  //mobility options
  if (bedScoreAssPatchDtoObjVal.mobilityCompletelyLimited) {
    methods.setValue("mobilityCompletelyLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.mobilityNoImpairment) {
    methods.setValue("mobilityNoImpairment", true);
  }

  if (bedScoreAssPatchDtoObjVal.mobilitySlightlyLimited) {
    methods.setValue("mobilitySlightlyLimited", true);
  }

  if (bedScoreAssPatchDtoObjVal.mobilityVeryLimited) {
    methods.setValue("mobilityVeryLimited", true);
  }

  //nutrition options
  if (bedScoreAssPatchDtoObjVal.nutritionAdequate) {
    methods.setValue("nutritionAdequate", true);
  }

  if (bedScoreAssPatchDtoObjVal.nutritionExcellent) {
    methods.setValue("nutritionExcellent", true);
  }

  if (bedScoreAssPatchDtoObjVal.nutritionProbablyInadequate) {
    methods.setValue("nutritionProbablyInadequate", true);
  }

  if (bedScoreAssPatchDtoObjVal.nutritionVeryPoor) {
    methods.setValue("nutritionVeryPoor", true);
  }

  //friction options
  if (bedScoreAssPatchDtoObjVal.frictionAndShearNoApparentProblem) {
    methods.setValue("frictionAndShearNoApparentProblem", true);
  }

  if (bedScoreAssPatchDtoObjVal.frictionAndShearPotentialProblem) {
    methods.setValue("frictionAndShearPotentialProblem", true);
  }

  if (bedScoreAssPatchDtoObjVal.frictionAndShearProblem) {
    methods.setValue("frictionAndShearProblem", true);
  }

  return tempbedScoreAssessmentArray
}

export default patchBedScoreTable;
