import * as React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  React.useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function ItemBatchInfoTable(props) {
  const [selectedItemRowId, setSelectedItemRowId] = React.useState(null);

  //keypress fuctionality code
  const enterPressed = useKeyPress("Enter");
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  let arrLength = props.dataArray.length;

  React.useEffect(() => {
    setSelectedItemRowId(0);
  }, []);

  React.useEffect(() => {

    if (enterPressed === true && selectedItemRowId !== null) {
      props.setOpen(false);
      let tempBatchList = props.dataArray;
      let tempBatchItemObj = tempBatchList[selectedItemRowId];
      props.setBatchItemInfoObj(tempBatchItemObj);
      props.setItemBatchesArray([]);
      setSelectedItemRowId(null);
    }
  }, [enterPressed, selectedItemRowId]);

  React.useEffect(() => {

    if (arrowUpPressed) {
      if (selectedItemRowId <= 0) {
        setSelectedItemRowId(arrLength - 1);
      } else if (selectedItemRowId !== 0) {
        setSelectedItemRowId(selectedItemRowId - 1);
      }
    }
  }, [arrowUpPressed]);

  React.useEffect(() => {

    if (arrowDownPressed) {
      if (selectedItemRowId >= arrLength - 1) {
        setSelectedItemRowId(0);
      } else if (selectedItemRowId !== arrLength) {
        setSelectedItemRowId(selectedItemRowId + 1);
      }
    }
  }, [arrowDownPressed]);

  //allHeaders is an object which contains the title of all the columns of the table
  let allHeaders = null;

  let newHeaders = [];

  if (props.dataArray.length > 0) {
    //allHeaders is an object which contains the title of all the columns of the table
    allHeaders = Object.keys(props.dataArray[0]);

    for (let headerName of allHeaders) {
      if (headerName !== "correspondingChargeIdValue") {
        newHeaders.push(headerName);
      }
    }

    allHeaders = newHeaders;
  }
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
          {/* Display the table ; if and only if the dataArray's length is greater than 0 */}
          {props.dataArray.length > 0 ? (
            <Paper sx={{ width: "100%", my: 1 }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-48 2xl:h-60"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      {allHeaders &&
                        allHeaders.map((singleHeader) => (
                          <TableCell
                            // style={{ width: 160 }}
                            // align="left"
                            key={singleHeader}
                          >
                            <span className="text-gray-600  whitespace-nowrap">
                              {singleHeader}
                            </span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {props.dataArray.map((singleDataObj, index) => {
                      return (
                        <>
                          <TableRow
                            style={{
                              backgroundColor:
                                selectedItemRowId === index ? "#FFC44B" : "",
                            }}
                            onClick={() => {

                              let uniqueBatchCode = singleDataObj.BatchCode;

                              let tempArray = props.dataArray;
                              let requiredIndex = 0;

                              for (
                                let index = 0;
                                index < tempArray.length;
                                index++
                              ) {
                                if (
                                  tempArray[index].BatchCode === uniqueBatchCode
                                ) {
                                  requiredIndex = index;
                                  break;
                                }
                              }
                              setSelectedItemRowId(requiredIndex);
                            }}
                          >
                            {allHeaders &&
                              allHeaders.map((header) => (
                                <TableCell key={header}>
                                  <span className="text-gray-600  whitespace-nowrap">
                                    {singleDataObj[header]}
                                  </span>
                                </TableCell>
                              ))}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Paper sx={{ width: "100%", my: 1 }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-48 2xl:h-60"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    ></TableRow>
                  </TableHead>

                  <TableBody>
                    <div className="row">
                      <div className="grid justify-center text-black">
                        Data Not Available.
                      </div>
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </div>
    </>
  );
}
