import React from "react";
export const UploadFileIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.184 2.84212H16.15C16.9059 2.84212 17.6308 3.14156 18.1653 3.67456C18.6997 4.20755 19 4.93045 19 5.68423V15.1579C19 15.9117 18.6997 16.6346 18.1653 17.1676C17.6308 17.7006 16.9059 18 16.15 18H2.85C2.09413 18 1.36922 17.7006 0.834746 17.1676C0.300267 16.6346 0 15.9117 0 15.1579V2.84212C0 2.08835 0.300267 1.36545 0.834746 0.832454C1.36922 0.299456 2.09413 2.11297e-05 2.85 2.11297e-05H7.182C7.77324 -0.0022518 8.35055 0.178902 8.83396 0.518383C9.31736 0.857865 9.68292 1.33887 9.88 1.89476L10.184 2.84212ZM10.1726 7.84916C10.0824 7.76285 9.97604 7.6952 9.85962 7.65007C9.62871 7.55525 9.36971 7.55525 9.1388 7.65007C9.02238 7.6952 8.91602 7.76285 8.82582 7.84916L6.92895 9.74525C6.75035 9.92377 6.65002 10.1659 6.65002 10.4184C6.65002 10.6708 6.75035 10.9129 6.92895 11.0915C7.10754 11.27 7.34977 11.3703 7.60234 11.3703C7.85491 11.3703 8.09713 11.27 8.27573 11.0915L8.55077 10.8071V13.2625C8.55077 13.5139 8.6507 13.7551 8.82856 13.9329C9.00643 14.1106 9.24767 14.2105 9.49921 14.2105C9.75075 14.2105 9.99199 14.1106 10.1699 13.9329C10.3477 13.7551 10.4476 13.5139 10.4476 13.2625V10.8071L10.7227 11.0915C10.8109 11.1803 10.9158 11.2509 11.0313 11.299C11.1469 11.3471 11.2709 11.3719 11.3961 11.3719C11.5213 11.3719 11.6453 11.3471 11.7608 11.299C11.8764 11.2509 11.9813 11.1803 12.0695 11.0915C12.1584 11.0033 12.2289 10.8985 12.2771 10.783C12.3252 10.6674 12.35 10.5435 12.35 10.4184C12.35 10.2932 12.3252 10.1693 12.2771 10.0538C12.2289 9.93824 12.1584 9.83338 12.0695 9.74525L10.1726 7.84916Z"
        fill="#1E3A8A"
      />
    </svg>
  );
};
