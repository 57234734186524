import { isAfter } from "date-fns";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { fetchEmoloyeeName } from "../../../commonServices/miscellaneousServices/MiscServices";

import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputArea from "../../../common/components/FormFields/InputArea";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchDepartmentList,
  fetchListOfMessages,
  fetchUserList,
  postDischargeMessage,
} from "../../../ipd/services/initiatDischargeMessage/InitiatDischargeMessageServices";
import { Checkbox } from "@mui/material";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices.js";

const MessagingConsole = () => {
  const location = useLocation();
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      searchOn: new Date(),
      selectUser: null,
      messageDtlsCompose: "",
      messageDtls: "",
    },
  });

  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    register,
    watch,
  } = methods;

  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [selectedTab, setSelectedTab] = useState("inbox");
  const [messageSentFor, setMessageSentFor] = useState(null);
  const [messageSentFrom, setMessageSentFrom] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [empArr, setEmpArr] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const SearchOnDate = watch("searchOn");
  const selected_User = watch("selectUser");

  useEffect(() => {
    getDepartmentList();
    getUserList();
    getEmoloyeeName();
  }, []);

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    isAfter(SearchOnDate, new Date(1900, 1, 1)) && populateTable();
  }, [SearchOnDate, selectedTab, selected_User]);

  const getDepartmentList = () => {
    fetchDepartmentList()
      .then((response) => response.data)
      .then((res) => {
        setDepartmentList(res.result);
      });
  };

  const getEmoloyeeName = () => {
    fetchEmoloyeeName()
      .then((response) => response.data)
      .then((res) => {
        setEmpArr(res.result);
      });
  };

  const getUserList = () => {
    fetchUserList(token.userId)
      .then((response) => response.data)
      .then((res) => {
        setUserList(res.result);
        const trueUsers = res.result.filter((user) => user.IsApplicableForSMS);
        setSelectedUserList(trueUsers);
      });
  };

  const populateTable = (forPagination) => {
    const listObj = {
      date: SearchOnDate,
      fromUserId: (selected_User !== null && selected_User.id) || null,
      isInbox: selectedTab === "inbox" ? true : false,
      userId: token?.userId,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchListOfMessages(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setMessagesList((prevData) => [...prevData, ...res.result]);
        } else {
          setMessagesList(res.result);
          setPage(0);
        }
        setCount(res.count);
        //setUserActions(res.actions);
      });
  };

  // const getDepartments = (searchString) => {
  //   searchString &&
  //     fetchDepartment(searchString)
  //       .then((response) => response.data)
  //       .then((res) => {
  //         setDepartments(res.result);
  //       });
  // };

  // const renderActions = (row) => {
  //   return (
  //     <>
  //       <div className="flex gap-2 items-center">
  //         {userActions.map((actions) => (
  //           <Fragment key={actions.action}>
  //             {actions.isAction && actions.action === "Status" && (
  //               <div className="">
  //                 <ComplaintStatusOnIcon
  //                   onClick={() => {
  //                     setPrivilege(actions.action);
  //                     setSelectedRow(row);
  //                   }}
  //                   title="Complaint Status"
  //                 />
  //               </div>
  //             )}
  //           </Fragment>
  //         ))}
  //       </div>
  //     </>
  //   );
  // };

  const renderActionsDept = (row, index) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="">
            <CheckBoxField
              control={control}
              name={`selectCheck${index}`}
              defaultValue={row.ApplicableForDeptSMS}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
          </span>
        </div>
      </>
    );
  };

  const renderActionsUser = (row, index) => {
    const handleSelect = (e, row) => {
      let userArr = [...selectedUserList];

      if (e.target.checked === true) {
        userArr.push(row);
        setSelectedUserList(userArr);
      } else if (e.target.checked === false) {
        let temp = [...selectedUserList];
        let filter1 = temp.filter((item) => item.UserId !== row.UserId);
        setSelectedUserList(filter1);
      }
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="">
            <Checkbox
              size="small"
              checked={row.IsApplicableForSMS ? true : false}
              onChange={(e) => {
                handleSelect(e, row);
              }}
              style={{ padding: 0 }}
            />
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (messageSentFor === "reply") {
      let replyUser = selectedUserList.find(
        (item) => item.UserId === selectedRow.MsgFrom
      );

      if (replyUser) {
        setUserList([{ ...replyUser, IsApplicableForSMS: true }]);
        setSelectedUserList([{ ...replyUser, IsApplicableForSMS: true }]);
      } else {
        setUserList([]);
        setSelectedUserList([]);
      }
    } else if (messageSentFor === "forward") {
      //  let replyUser = selectedUserList.find(
      //    (item) => item.UserId === selectedRow.MsgFrom
      //  );
      setValue(
        "messageDtlsCompose",
        selectedRow?.MsgDetails || selectedRow?.Details
      );
    }
  }, [selectedRow, messageSentFor, selectedRow]);

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
    setValue("messageDtls", row?.MsgDetails || row?.Details);
  };

  const onSubmit = () => {
    selectedUserList.length > 0
      ? setOpenConfirmationModal(true)
      : warningAlert("Please select User...");
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);

    const finalObj = {
      departmentIdDto: selectedUserList.map(({ UserId }) => ({
        checked: true,
        displayed: true,
        msgFromDeptId: token.userId,
        msgToDeptId: UserId,
      })),
      time: new Date(),
      isFromDischargeInitiated: false,
      menuId: location?.state?.menuId,
      privilege: privilege || "Create",
      messageDetails: getValues("messageDtlsCompose"),
    };
    setOpenBackdrop(true);

    postDischargeMessage(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          handleCancelReply();
          methods.reset();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  const handleCancelReply = () => {
    if (messageSentFor === "reply" || messageSentFor === "forward") {
      setSelectedTab(messageSentFrom);
      setMessageSentFor(null);
      setSelectedRow(null);
      getUserList();
    } else {
      methods.reset();
    }
  };

  return (
    <div className="mt-12">
      <div className="grid grid-cols-7 items-center">
        <div className="text-center text-black font-bold text-xl col-span-7">
          Departmental Messaging System
        </div>
      </div>

      <div className="flex  mt-2 space-x-4 border rounded-lg bg-indigo-200 p-2">
        {["inbox", "sent", "compose"].map((tab) => (
          <button
            key={tab}
            className={`${
              selectedTab === tab
                ? "bg-customBlue text-white border-solid border-transparent"
                : "bg-white text-gray-700 border-solid border-transparent hover:border-solid hover:border-zinc-700 hover:drop-shadow-lg hover:shadow-zinc-700"
            } py-2 px-4 rounded-full transition-all duration-300`}
            onClick={() => {
              setSelectedTab(tab);
              methods.reset();
            }}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      <div className="p-4 bg-white shadow-md rounded-lg border mt-2">
        {selectedTab === "inbox" || selectedTab === "sent" ? (
          <div className="grid lg:grid-cols-7 md:grid-cols-2 mb-3 gap-3">
            <div className="">
              <DropdownField
                control={control}
                name="selectUser"
                dataArray={empArr}
                placeholder="Select User"
                isSearchable={false}
                isClearable={true}
                // inputRef={{
                //   ...register("selectUser", {
                //     onChange: (e) => {},
                //   }),
                // }}
              />
            </div>
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="searchOn"
                label="Search On "
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
          </div>
        ) : null}

        {selectedTab === "inbox" || selectedTab === "sent" ? (
          <div>
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins ">
              List Of Messages
            </p>
            <div>
              <div className="">
                {messagesList.length > 0 ? (
                  <CommonDynamicTablePaginationNew
                    dataResult={messagesList}
                    populateTable={populateTable}
                    handleSelectedRow={handleSelectedRow}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    tableClass="lg:h-[350px] 2xl:h-[350px]"
                    removeHeaders={[
                      "ID",
                      "Checked",
                      "LoginName",
                      "MsgFrom",
                      "MsgID",
                      "MsgTo",
                    ]}
                    //renderActions={renderActions}
                    // highlightRow={false}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>

              <div>
                <div>
                  <InputArea
                    control={control}
                    name="messageDtls"
                    label="Message Details"
                    placeholder="Message Details"
                    minRows={4}
                  />
                </div>
              </div>

              <div className="">
                <div className="flex justify-end gap-4 mt-5">
                  <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      setSelectedRow(null);
                      methods.reset();
                    }}
                  />

                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction &&
                        obj.action === "Reply" &&
                        selectedRow !== null && (
                          <div>
                            <CommonButton
                              label="Reply"
                              onClick={() => {
                                setMessageSentFrom(selectedTab);
                                setSelectedTab("compose");
                                setMessageSentFor("reply");
                                setPrivilege(obj.action);
                              }}
                              className="border border-customGreen text-customGreen"
                            />
                          </div>
                        )}
                      {!obj.isAction &&
                        obj.action === "Forward" &&
                        selectedRow !== null && (
                          <div>
                            <CommonButton
                              label="Forward"
                              onClick={() => {
                                setMessageSentFrom(selectedTab);
                                setSelectedTab("compose");
                                setMessageSentFor("forward");
                                setPrivilege(obj.action);
                              }}
                              className="bg-customBlue text-white"
                            />
                          </div>
                        )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : selectedTab === "compose" ? (
          <div>
            <div className="grid grid-cols-2 gap-5">
              <div>
                <div className="font-semibold ">Department :</div>
                {departmentList.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={departmentList}
                    tableClass="rounded lg:h-52 md:h-44"
                    renderActions={renderActionsDept}
                    removeHeaders={["Id", "Status", "ApplicableForDeptSMS"]}
                    highlightRow={false}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>
              <div className="">
                <div className="font-semibold">Message To :</div>
                {userList.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={userList}
                    tableClass="rounded lg:h-52 md:h-44"
                    renderActions={renderActionsUser}
                    removeHeaders={["id", "UserId", "IsApplicableForSMS"]}
                    highlightRow={false}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>
              <div className="col-span-2 mt-1">
                <InputArea
                  control={control}
                  name="messageDtlsCompose"
                  label="Message Details"
                  placeholder="Message Details"
                  minRows={4}
                />
              </div>
              <div className="col-span-2 mt-7">
                <div className="flex justify-end gap-4 mt-5">
                  <CommonButton
                    label={
                      messageSentFor === "reply" || messageSentFor === "forward"
                        ? "Cancel"
                        : "Reset"
                    }
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      handleCancelReply();
                    }}
                  />
                  <CommonButton
                    label="Send"
                    onClick={onSubmit}
                    className="bg-customGreen text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={handleFormSubmit}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default MessagingConsole;
