import React from "react";

export default function Reschedulehiden() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" fill="#CCCCCC" />
        <path
          d="M11.2644 3.01382C9.75183 3.17079 8.34383 3.64973 7.18123 4.40237L6.87952 4.59555L6.85538 4.31382C6.81917 3.8711 6.60999 3.65376 6.21575 3.65376C5.96231 3.65376 5.83358 3.71816 5.67669 3.93147C5.59623 4.03611 5.59221 4.09246 5.59221 5.24354C5.59221 6.39462 5.59623 6.45097 5.67669 6.55561C5.72094 6.61599 5.80944 6.70453 5.86978 6.7488C5.97438 6.8293 6.0307 6.83332 7.18123 6.83332C8.33177 6.83332 8.38808 6.8293 8.49268 6.7488C8.70589 6.59184 8.77026 6.46304 8.77026 6.20948C8.77026 6.0324 8.75014 5.94385 8.68578 5.86335C8.52888 5.65004 8.4082 5.58967 8.0743 5.56552L7.76454 5.5454L8.08637 5.35623C11.0955 3.58936 14.881 4.03209 17.3711 6.44695C18.0791 7.13116 18.5297 7.75097 18.9521 8.60422C20.1107 10.9668 19.998 13.7599 18.6544 15.9897C18.1757 16.7825 17.4194 17.6318 16.7194 18.159C14.4022 19.9138 11.3046 20.2438 8.71796 19.0082C7.86511 18.6017 7.28985 18.1952 6.59389 17.499C5.80944 16.7222 5.31463 16.0058 4.91637 15.064C4.54224 14.1785 4.35317 13.3454 4.29283 12.2949C4.25662 11.7234 4.22846 11.6429 3.98709 11.4658C3.8302 11.3491 3.45205 11.3491 3.29516 11.4658C2.99747 11.6872 2.95724 11.8925 3.03367 12.7779C3.21873 14.8708 4.07559 16.7503 5.52784 18.2556C6.93584 19.7126 8.62141 20.5859 10.7012 20.924C11.3087 21.0246 12.6885 21.0246 13.3241 20.928C15.2229 20.6302 16.8924 19.8051 18.2441 18.5011C19.6842 17.1085 20.5652 15.4262 20.9072 13.4138C21.0118 12.8061 21.0319 11.3531 20.9474 10.8178C20.5894 8.62434 19.7406 6.95407 18.2602 5.52527C16.9205 4.23333 15.3034 3.4163 13.4569 3.11042C12.9701 3.0259 11.6748 2.97357 11.2644 3.01382Z"
          fill="white"
        />
        <path
          d="M16.1061 15.7927C15.8155 15.549 14.1115 14.47 13.8283 14.5195C13.6953 14.5431 13.5936 14.6565 13.3214 14.9812C13.1954 15.1411 13.0572 15.2908 12.9078 15.429C12.6342 15.3629 12.3693 15.2647 12.1186 15.1365C11.1358 14.658 10.3417 13.8637 9.86349 12.8807C9.73525 12.63 9.63706 12.3651 9.57096 12.0915C9.70915 11.9421 9.85887 11.8039 10.0187 11.678C10.3432 11.4057 10.4569 11.3046 10.4805 11.171C10.53 10.8871 9.44996 9.18379 9.20729 8.89325C9.10557 8.77292 9.01315 8.69214 8.89481 8.69214C8.55175 8.69214 7 10.6109 7 10.8595C7 10.8798 7.03324 12.8773 9.556 15.444C12.1226 17.9667 14.1202 18 14.1404 18C14.3891 18 16.3078 16.4482 16.3078 16.1052C16.3078 15.9868 16.2271 15.8944 16.1061 15.7927Z"
          fill="white"
        />
        <path
          d="M15.4589 12.923H16.3051C16.3038 11.8013 15.829 10.7546 15.0358 9.96139C14.2426 9.16823 13.1959 8.6934 12.0742 8.69214V9.53831C12.9155 9.53915 14.0178 10.2127 14.6127 10.8076C15.0358 11.2306 15.458 12.0817 15.4589 12.923Z"
          fill="white"
        />
        <path
          d="M13.9781 12.9233H14.6127C14.612 12.2502 14.3443 11.605 13.8684 11.1291C13.3925 10.6532 12.7472 10.3855 12.0742 10.3848V11.0194C12.579 11.0199 13.063 11.2206 13.4199 11.5776C13.7768 11.9345 13.9776 12.4185 13.9781 12.9233Z"
          fill="white"
        />
        <path
          d="M17.0861 12.9232H17.9974C17.9956 11.3528 17.371 9.84727 16.2605 8.73685C15.1501 7.62644 13.6446 7.00181 12.0742 7V7.91126C13.403 7.91282 14.6769 8.44137 15.6164 9.38094C16.556 10.3205 17.0846 11.5944 17.0861 12.9232Z"
          fill="white"
        />
      </svg>
    </div>
  );
}