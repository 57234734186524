import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";
import DropdownField from "../../common/components/FormFields/DropdownField";
import { getPTErejectResons } from "../services/PatientEngagementServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
  p: 3,
};

function IncompleteReasonModal(props) {
  const [incompleteReasons, setIncompleteReasons] = React.useState([]);

  const defaultValues = { rejReason: null };

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const { control, handleSubmit, reset, setValue, watch } = useForm({
    // use mode to specify the event that triggers each input field
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    setValue("Reason", "");
  }, []);

  useEffect(() => {
    IncompleteReason();
  }, []);

  const IncompleteReason = (obj) => {
    getPTErejectResons(obj)
      .then((response) => response.data)
      .then((res) => {
        setIncompleteReasons(res.result);
      });
  };

  let Reason = watch("rejReason");

  let requiredObj = {};
  let tempArr = props.finalArr;

  const onSubmitDataHandler = () => {
    requiredObj.appointmentDate = "";
    requiredObj.inCompleteReason = Reason.value;
    requiredObj.inCompleteReasonId = Reason.Id;
    requiredObj.ptEngDtlsId = props.rowId;
    requiredObj.reScheduleDate = "";
    requiredObj.reasonId = "";
    requiredObj.rejectReason = "";
    requiredObj.status = "InComplete";

    tempArr.push(requiredObj);
    props.setFinalArr(tempArr);

    props.setOpenIncompleteModal(false);
  };

  return (
    <form>
      <Modal
        open={props.openIncompleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              props.setOpenIncompleteModal(false);
            }}
          />
          <fieldset className="border border-gray-300 text-left   lg:mx-auto lg:px-4 md:ml-0 md:mr-0  rounded   lg:m-2 ">
            <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
              Incomplete Reason
            </legend>
            <form
              onSubmit={handleSubmit(onSubmitDataHandler)}
              className="grid grid-cols-1 md:grid-cols-1  gap-2"
            >
              <div className="w-full grid grid-cols-2 gap-4">
                <div>
                  <DropdownField
                    dataArray={incompleteReasons}
                    isSearchable={true}
                    name="rejReason"
                    placeholder="Select Reason"
                    //error={errors.Reason}
                    control={control}
                  />
                </div>
                <div className="flex justify-center py-2">
                  <CommonButton
                  className="px-3  bg-green-700 text-white rounded text-base"
                  onClick={onSubmitDataHandler}
                  label="Save"
                  type="button"
                />
                </div>
              </div>
            </form>
          </fieldset>
        </Box>
      </Modal>
    </form>
  );
}

export default IncompleteReasonModal;
