//import "./App.css";
// import Dashboard from "./dashboard/components/Dashboard";

// function App() {
//   return (
//     <>
//       <Dashboard />
//       {/* <PositionedSnackbar/> */}
//     </>
//   );
// }

// export default App;

// previous Jodit Version
// "jodit-react": "^1.3.35",

import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import "./App.css";
import Dashboard from "./dashboard/components/Dashboard";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  typography: {
    // fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tab: 1200,
      lg: 1260,
      xl: 1536,
    },
  },
});

const App = () => {
  // const channel = new BroadcastChannel("tab");

  // channel.postMessage("another-tab");

  // channel.addEventListener("message", (msg) => {
  //   if (msg.data === "another-tab") {
  //     // alert("Cannot open multiple instances");
  //     window.location.href = 'http://www.google.com'
  //   }
  // });

  function noConsole() { }
  
  if (process.env.NODE_ENV !== "development") {
    console.log = noConsole;
    console.warn = noConsole;
    console.error = noConsole;
  }
  
  
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="">
          <Dashboard />
        </div>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ThemeProvider>
  );
};
export default App;
