import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// /api/discharge/cancelDischarge/{admissionId}/{reasonForCancel}
export const reasonForDischarge = (admissionId, reasonForCancel) => {
  return apiClient.get(
    `discharge/cancelDischarge/${admissionId}/${reasonForCancel}`,
    {
      headers: authHeader(),
    }
  );
};
