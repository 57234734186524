import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { ipdgetPatientInformationById } from "../../../../billing/services/BillingServices";
import { autoSearchPatientAdvance } from "../../../../billing/services/PatientAdvanceServices";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  ipdRefundOfAdvancePdfPrint,
  listOfAdvanceService,
  listofRefundService,
  postRefundOfAdvanceService
} from "../../../services/refund/RefundOfAdvanceServices";
import BillingInformationModal from "./BillingInformationModal";

const serviceList = {
  message: "Refund list found ",
  result: [
    {
      SrNo: 1,
      "Service Name": "101",
      Qty: "20/10/2022",
      Rate: "Male",
      Amount: "8000.00",
      Refunded: "8000.00",
      "Refund Amount": "8000.00",
    },
    {
      SrNo: 2,
      "Service Name": "102",
      Qty: "20/10/2022",
      Rate: "Female",
      Amount: "8000.00",
      Refunded: "8000.00",
      "Refund Amount": "8000.00",
    },
    {
      SrNo: 3,
      "Service Name": "103",
      Qty: "20/10/2022",
      Rate: "Male",
      Amount: "8000.00",
      Refunded: "8000.00",
      "Refund Amount": "8000.00",
    },
    {
      SrNo: 4,
      "Service Name": "104",
      Qty: "20/10/2022",
      Rate: "Female",
      Amount: "8000.00",
      Refunded: "8000.00",
      "Refund Amount": "8000.00",
    },
    {
      SrNo: 5,
      "Service Name": "105",
      Qty: "20/10/2022",
      Rate: "Male",
      Amount: "8000.00",
      Refunded: "8000.00",
      "Refund Amount": "8000.00",
    },
  ],
};
const advanceList1 = {
  result: [
    {
      id: "1",
      Date: "10/20/2022",
      "Total Ammount": 20002,
      Remark: "Lorem ipsum dolor sit amet, consectetur adipiscing el",
      "Balance Ammount": 4000,
      "Comp/pat Name": "Comp/Patient Name",
      Refunded: "8000.00",
    },
    {
      id: "2",
      Date: "10/20/2022",
      "Total Ammount": 20002,
      Remark: "Lorem ipsum dolor sit amet, consectetur adipiscing el",
      "Balance Ammount": 4000,
      "Comp/pat Name": "Comp/Patient Name",
      Refunded: "8000.00",
    },

    {
      id: "3",
      Date: "10/20/2022",
      "Total Ammount": 20002,
      Remark: "Lorem ipsum dolor sit amet, consectetur adipiscing el",
      "Balance Ammount": 4000,
      "Comp/pat Name": "Comp/Patient Name",
      Refunded: "8000.00",
    },
    {
      id: "4",
      Date: "10/20/2022",
      "Total Ammount": 20002,
      Remark: "Lorem ipsum dolor sit amet, consectetur adipiscing el",
      "Balance Ammount": 4000,
      "Comp/pat Name": "Comp/Patient Name",
      Refunded: "8000.00",
    },
    {
      id: "5",
      Date: "10/20/2022",
      "Total Ammount": 20002,
      Remark: "Lorem ipsum dolor sit amet, consectetur adipiscing el",
      "Balance Ammount": 4000,
      "Comp/pat Name": "Comp/Patient Name",
      Refunded: "8000.00",
    },
    {
      id: "6",
      Date: "10/20/2022",
      "Total Ammount": 20002,
      Remark: "Lorem ipsum dolor sit amet, consectetur adipiscing el",
      "Balance Ammount": 4000,
      "Comp/pat Name": "Comp/Patient Name",
      Refunded: "8000.00",
    },
  ],
};
const actions = ["print"];
const Actions = [
  {
    id: 0,
    action: "Print",
    isAction: true,
  },
];
const RefundOfAdvance = () => {
  const schema = yup.object().shape({
    // remark: yup.string(),
    advanceAvailable: yup.number(),
    refundAmount: yup
      .number()
      .typeError("Required")
      .min(1, "Required")
      .required("Required")
      .when("advanceAvailable", (advanceAvailable, schema) => {
        return schema
          .test({
            test: (refundAmount) => {
              if (!refundAmount) return true;
              return refundAmount <= advanceAvailable;
            },
            message: "Amount Exceeds Than Total Advance",
          })
          .required("Required");
      }),
    remark: yup.string(),
  });
  const defaultValues = {
    totalAdvance: 0,
    totalConsumed: 0,
    totalRefund: 0,
    advanceAvailable: 0,
    refundAmount: "",
    remark: "",
    opdIpd: 0,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables
  const [refundSearchOptions, setRefundSearchOptions] = useState([]);
  const [searchResultById, setSearchResultById] = useState("");
  const [advanceList, setAdvanceList] = useState([]);
  const [refundList, setRefundList] = useState([]);
  const [rowAdvanceData, setRowAdvanceData] = useState(0);
  const [open, setOpen] = useState(false);
  const [openAdvanceModel, setOpenAdvanceModel] = useState(false);
  const [patientInfoById, setPatientInfoById] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [postRefundOfAdvance, setPostRefundOfAdvance] = useState([]);
  const [vistiPdf, setVisitPdf] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [userActions, setUserActions] = React.useState([]);
  const [menuIdVal, setMenuIdVal] = React.useState(null);
  const[refundTotalAmt,setRefundTotalAmt]=React.useState(0)

  //other variables
  let refundAmount = watch("refundAmount");
  let totalAdvanceVal = watch("totalAdvance");
  // get menu id
  let location = useLocation();
  

  

  //useEffects
  useEffect(() => {
    setValue("totalAdvance", selectedRow?.Amount);
    // setValue("totalAdvance", 10000);
    setValue("totalConsumed", 0);
    setValue("totalRefund", selectedRow?.RefundAmount);
    setValue("advanceAvailable", selectedRow?.BalanceAmount);
    
  }, [selectedRow, setValue]);

  //functions
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  //auto complete search patient
  function handleChange(searchRefundOfAdvance) {
    let opdIpd = 1;
    if (searchRefundOfAdvance !== ""&&UseCheckConsecutiveCharacters(searchRefundOfAdvance)) {
      autoSearchPatientAdvance(searchRefundOfAdvance, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setRefundSearchOptions(res.result);
        });
    }
  }

  // patient info by id
  function getPatientInfomation(seletedPatientid) {
    let opdIpd = 1;
    ipdgetPatientInformationById(seletedPatientid, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfoById(res.result);
        listOfAdvance(res.result);
      });
  }
  useEffect(() => {
    listOfRefund(selectedRow);
    
  }, [selectedRow]);

  // get menu id and button on components
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          
          setUserActions(res.result);
        });
  }, []);

  //advance listing
  function listOfAdvance(advance) {
    let advanceObj = {
      opdIpd: 1,
      patientId: advance.patientId,
      unitId: advance.unitId,
      visitId: advance.admissionId,
    };
    
    listOfAdvanceService(advanceObj)
      .then((response) => response.data)
      .then((res) => {
        
       

        if (res !== null) {
          setAdvanceList(res.result);
          listOfRefund(selectedRow);
        } else {
          errorAlert(res.result.message);
          setAdvanceList("");
        }
      });
  }

  //Refund Listing
  function listOfRefund(selectedRow) {
    
    
    listofRefundService(selectedRow?.PatientAdvanceDetails)
      .then((response) => response.data)
      .then((res) => {
        setRefundList(res.result);
        
      });
  }

  //row data from table
  function displayRowData(row) {
    // setRowAdvanceData(row);
    // listOfRefund(row);
  }
  //

  function submitPaymentInfoModal(paymentInfoList) {
    if (totalAdvanceVal !== 0) {
      setOpen(true);
    } else {
      setOpen(false);
      errorAlert("Please Select Advance from List");
    }

    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let remarksVal = getValues("remark");
    let refundAmountVal = getValues("refundAmount");

    let refundAdvancePostObj = {
      addedBy: userInfoObj.userId,
      cashCounterId: userInfoObj?.cashCounterId,
      menuId: location?.state?.menuId,
      privilege:"Create",
      advanceId: selectedRow?.PatientAdvanceDetails,
      patientId: searchResultById.id,
      // refundAmount: Number(refundAmountVal),
      refundAmount:refundTotalAmt,
      remark: remarksVal,
      visitId: patientInfoById && patientInfoById.admissionId,
      oopdIpdExternal: 1,
     
      
    };
    refundAdvancePostObj.paymentInfoList = paymentInfoList;
    setPostRefundOfAdvance(refundAdvancePostObj);
    handleOpenConfirmation();
    setOpenBackdrop(true);
    postRefundOfAdvanceService(refundAdvancePostObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setPostRefundOfAdvance(res.result);
        setOpenBackdrop(false);
        reset();
        if (res.statusCode === 200) {
          handleOpenPrintModal(res);
        } else {
          errorAlert(errors.message);
        }
        setPostRefundOfAdvance(null);
        setPatientInfoById("");
        setAdvanceList([]);
        setSearchResultById("");
        setRefundList([]);
        setValue("refundOfAdvanceSearch", null);
        handleClose();
      })
      .catch(() => {
        errorAlert(errors.message);
        setOpenBackdrop(false);
      });
  }
  // const patientAdvanceReciptpdf = (advanceId) => {
  //   
  //   let advanceID = advanceId.result ? advanceId.result : advanceId;

  //   let url = `${baseUrl}/reports/bills/advanceRefundReceipt?refundId=${advanceID}`;
  //   
  //   axios.get(url).then((response) => {
  //     setVisitPdf(url);
  //     setOpenAdvanceModel(true);
  //   });
  // };
   //prints print
   const [urlforPrint, setUrlforPrint] = useState();
   const [openPrintModal, setOpenPrintModal] = useState(false);
   const handleClosePrintModal = () => {
     setOpenPrintModal(false);
   };
   const handleOpenPrintModal = (advanceId) => {
     let advanceID = advanceId.result ? advanceId.result : advanceId; 
     ipdRefundOfAdvancePdfPrint(advanceID).then((response) => {
       setUrlforPrint(response);
     });
     setOpenPrintModal(true); 
   }; 

  const renderActions = (row, index) => {
    
    return (
      <div className="flex gap-3">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  {
                    <div className="flex gap-2 cursor-pointer">
                      {actions.action === "Print" ? (
                        <Tooltip title="Print">
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                              handleOpenPrintModal(row?.RefundId);
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  }
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };
  function handleSelectedRow() {}
  return (
    <div className="mt-12  px-6 ">
      <div className="text-center text-black font-bold text-xl py-4">
        Refund Of Advance
      </div>
      <div className=" flex gap-3 md:flex md:w-full">
        <div className=" lg:w-2/5 md:w-full">
          <SearchDropdown
            control={control}
            name="refundOfAdvanceSearch"
            placeholder="Search By Patient Name/ MR No./Mobile No"
            dataArray={refundSearchOptions}
            searchIcon={true}
            isClearable={true}
            isSearchable={true}
            handleInputChange={handleChange}
            inputRef={{
              ...register("refundOfAdvanceSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSearchResultById(e.target.value);
                    getPatientInfomation(e.target.value.id);
                  } else {
                    setAdvanceList([]);
                    setSearchResultById("");
                    setRefundList([]);
                  }
                },
              }),
            }}
          />
        </div>
      </div>
      {searchResultById && searchResultById !== null ? (
        <>
          <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
            <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                <span className="w-24 font-semibold">Patient Name</span>
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById.patientName && patientInfoById.patientName}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 xl:w-8 font-semibold">MRNo.</span>
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById && patientInfoById.mrNo}
                </span>
              </div>

              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 xl:w-8 font-semibold">Age</span>
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById && patientInfoById.ageYear}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                <span className="w-24 lg:w-20 font-semibold">Mobile No</span>

                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById && patientInfoById.MobileNo}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap ">
                <span className="w-24 lg:w-20 font-semibold">Balance Amt </span>

                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedRow && selectedRow?.BalanceAmount}
                </span>
              </div>
            </div>
          </fieldset>
          <form onSubmit={handleSubmit(submitPaymentInfoModal)}>
            <div className="lg:grid lg:grid-cols-2 gap-2 ">
              <div>
                <div className="text-md font-semibold">List Of Advance</div>
                {advanceList.length > 0 ? (
                  <CommonDynamicTable
                    data={advanceList}
                    actionsRequired={false}
                    highlightRow={true}
                    setData={setAdvanceList}
                    setSelectedRow={setSelectedRow}
                    handleSelectedRow={handleSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72"}
                  />
                ) : (
                  <>
                    <div className="text-center mt-4 p-4">No Record Found</div>
                  </>
                )}
               
              </div>
              <div>
                <div className="text-md font-semibold">List Of Refund</div>
                
                {refundList.length > 0 ? (
                  <CommonDynamicTable
                    data={refundList}
                    actionsRequired={true}
                    renderActions={renderActions}
                    setData={setRefundList}
                    setSelectedRow={setSelectedRow}
                    highlightRow={false}
                    handleSelectedRow={handleSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72"}
                  />
                ) : (
                  <>
                    <div className="text-center mt-4 p-4">No Record Found</div>
                  </>
                )}
              </div>
            </div>
            <fieldset className="  mx-auto border w-full rounded my-1">
              <legend className="ml-2 my-1 rounded-xl ">
                <p className="text-sm font-semibold tracking-wide mx-2">
                  Add Refund
                </p>
              </legend>

              <div className="grid lg:grid-cols-4 md:grid-cols-2  gap-3 p-3">
                <div>
                  <InputField
                    name="totalAdvance"
                    variant="outlined"
                    type="number"
                    label="Total Advance ₹"
                    error={errors.totalAdvance}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  
                  <InputField
                    name="totalConsumed"
                    variant="outlined"
                    label=" Advance Consumed ₹"
                    error={errors.totalConsumed}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  
                  <InputField
                    name="totalRefund"
                    variant="outlined"
                    label="Total Refund ₹"
                    error={errors.totalRefund}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  
                  <InputField
                    name="advanceAvailable"
                    variant="outlined"
                    label="Advance Available ₹"
                    error={errors.advanceAvailable}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  
                  <InputField
                    name="refundAmount"
                    variant="outlined"
                    label="Refund Amount ₹"
                    error={errors.refundAmount}
                    control={control}
                    type="number"
                    disabled={false}
                  />
                </div>
                <div className="lg:col-span-2">
                  
                  <InputField
                    name="remark"
                    variant="outlined"
                    label="Remarks"
                    control={control}
                    disabled={false}
                  />
                </div>
                <div className="flex justify-end  py-2 gap-2 lg:col-span-1 md:col-span-2">
                  {userActions &&
                    userActions.map((actions, i) => (
                      <>
                        {actions.isAction === false ? (
                          <>
                            <div className="flex gap-2 cursor-pointer">
                              {actions.action === "Create" ? (
                                <CommonButton
                                  type="button"
                                  label="Reset"
                                  className="border border-customRed text-customRed capitalize"
                                  onClick={() => {
                                    reset();
                                    setSearchResultById(null);
                                    setValue("refundOfAdvanceSearch", null);
                                    setAdvanceList([]);

                                    setRefundList([]);
                                  }}
                                />
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </>
                    ))}

                  {userActions &&
                    userActions.map((actions, i) => (
                      <>
                        {actions.isAction === false ? (
                          <>
                            <div className="flex gap-2 cursor-pointer">
                              {actions.action === "Create" ? (
                                <CommonButton
                                  label="Save"
                                  type="submit"
                                  className=" bg-green-600 text-white"
                                  onClick={() => {
                                    if (selectedRow === 0) {
                                      errorAlert("Select Advance From List");
                                    } else if (getValues("refundAmount") <= 0) {
                                      // errorAlert("Refund Amount Must Be Greater Than 1");
                                    } else if (
                                      selectedRow > 0 &&
                                      getValues("refundAmount") >= 1
                                    ) {
                                      handleOpen();
                                      // setMenuIdVal(actions)
                                    }
                                  }}
                                />
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </>
                    ))}
                </div>
              </div>
            </fieldset>
          </form>
        </>
      ) : null}

      <CommonBackDrop openBackdrop={openBackdrop} />
      <BillingInformationModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        refundAmount={refundAmount}
        postRefundOfAdvance={postRefundOfAdvance}
        submitPaymentInfoModal={submitPaymentInfoModal}
        searchResultById={searchResultById}
        setSearchResultById={setSearchResultById}
        getValues={getValues}
        patientInfoById={patientInfoById}
        setRefundTotalAmt={setRefundTotalAmt}
      />

{openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
};

export default RefundOfAdvance;
