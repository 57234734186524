import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

// /api/drugAdministrator/patientList/{floorId}/{searchString}
export const drugAdminPatientList = (defaultObj) => {
  return apiClient.post(`/drugAdministrator/patientList`, defaultObj, {
    headers: authHeader(),
  });
};
// /api/drugAdministrator/orderedDrugList/{admissionId}
export const getOrderedDrugList = (obj) => {
  return apiClient.post(`/drugAdministrator/orderedDrugList`, obj, {
    headers: authHeader(),
  });
};
///api/drugAdministrator/drugList/{prescriptionId}
export const getDrugList = (PrescriptionId) => {
  return apiClient.get(`/drugAdministrator/drugList/${PrescriptionId}`, {
    headers: authHeader(),
  });
};
// /api/dischargeSummary/drugRoute
export const getRouteDropdown = () => {
  return apiClient.get(`/dischargeSummary/drugRoute`, {
    headers: authHeader(),
  });
};

export const getDrugGenericNameDropdown = (searchGenericName) => {
  return apiClient.get(
    `/dischargeSummary/drugGenericName/${searchGenericName}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDrugNameDropdown = (searchString) => {
  return apiClient.get(`/drugAdministrator/drugName/${searchString}`, {
    headers: authHeader(),
  });
};

// ​/api​/dischargeSummary​/drugInstruction
export const getDrugInstructionDropdown = () => {
  return apiClient.get(`/dischargeSummary/drugInstruction`, {
    headers: authHeader(),
  });
};
// ​/api​/dischargeSummary​/drugFrequency
export const getDrugFrequencyDropdown = () => {
  return apiClient.get(`/dischargeSummary/drugFrequency`, {
    headers: authHeader(),
  });
};
// /api/dischargeSummary/drugForm
export const getdrugFormDropdown = () => {
  return apiClient.get(`/dischargeSummary/drugForm`, {
    headers: authHeader(),
  });
};
// /api/drugAdministrator/saveDrugAdmOrder
export const saveDrugAdminstratorOrder = (postObjDrugAdminOrder) => {
  return apiClient.post(
    `/drugAdministrator/saveDrugAdmOrder`,
    postObjDrugAdminOrder,
    {
      headers: authHeader(),
    }
  );
};
