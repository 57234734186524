import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import { acceptBloodRequisition } from "../../services/bloodReqAcceptanceServices/BloodReqAcceptanceServices";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import { Controller, useForm } from "react-hook-form";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputField from "../../../common/components/FormFields/InputField";

export default function ConfirmationBloodAcceptance(props) {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, selectedRow, populateTable, menuId, privilege } = props;

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      routine: false,
      emergency: false,
      adult: false,
      pediatric: false,
      pcv: false,
      ffp: false,
      rdp: false,
      sdp: false,
      cryoprecipitate: false,
      specifyAnyOther: false,
    },
  });

  const { control, setValue } = methods;

  const getAcceptBloodReq = () => {
    acceptBloodRequisition(selectedRow?.RequisitionID, menuId, privilege)
      .then((response) => response.data)
      .then((res) => {
        populateTable();
        handleClose();
      });
  };
  useEffect(() => {
    setValue("routine", selectedRow?.Routine);
    setValue("emergency", selectedRow?.Emergency);
    setValue("adult", selectedRow?.Adult);
    setValue("pediatric", selectedRow?.Pediatric);
    setValue(
      "wholeBlood",
      selectedRow["Whole Blood"] && selectedRow["Whole Blood"]
    );
    setValue("pcv", selectedRow?.PCV);
    setValue("ffp", selectedRow?.FFP);
    setValue("rdp", selectedRow?.RDP);
    setValue("sdp", selectedRow?.SDP);
    setValue("cryoprecipitate", selectedRow?.CRYOPRECIPITATE);
    setValue("specifyAnyOther", selectedRow && selectedRow["Any Other"]);
  }, [selectedRow]);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Blood Acceptance
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className="my-3">
              Do You Want To Confirm Blood Acceptance Requisition?
            </div>

            {/* patientInfo */}
            <fieldset
              className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
            >
              <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                  <span className=" font-semibold w-28">Patient Name</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedRow && selectedRow["Patient Name"]}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                  <span className=" font-semibold w-28">MR. No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedRow && selectedRow.MRNo}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
            <div>
              <div className="flex gap-3">
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="routine"
                    label="Routine "
                    disabled={true}
                    disableColor
                  />
                </div>
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="emergency"
                    label="Emergency"
                    disabled={true}
                    disableColor
                  />
                </div>

                <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                  <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                    <span className=" font-semibold w-16">Dignosis</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedRow && selectedRow.Diagnosis}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  History Of Previous Transfusion
                </div>
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">Date</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Transfusion Date"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Name Of Institution
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Institution Name"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Blood/Plasma/Pletletes
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Blood Plasma"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40"> ABO Group</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["ABO Group"]}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">Rh</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.RH}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Known By/Verbal
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.knownByReportVerbal === 0
                            ? "Known By Report"
                            : "Verbal"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Any Transfusion Reaction
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Transfusion Reaction"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div>
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  Require Blood
                </div>
                <div className="flex">
                  <div className="">
                    <CheckBoxField
                      control={control}
                      name="adult"
                      label="Adult "
                      disabled={true}
                      disableColor
                    />
                  </div>

                  <div className="">
                    <CheckBoxField
                      control={control}
                      name="pediatric"
                      label="Pediatric"
                      disabled={true}
                      disableColor
                    />
                  </div>
                </div>
                <fieldset className="">
                  <div className="grid grid-cols-2 lg:grid-cols-5 2xl:grid-cols-5">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-40">
                        <CheckBoxField
                          control={control}
                          name="wholeBlood"
                          label="Whole Blood "
                          disabled={true}
                          disableColor="#29b6f6"
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Whole Blood Qty"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        <CheckBoxField
                          control={control}
                          name="pcv"
                          label="PCV "
                          disabled={true}
                          disableColor
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["PCV Qty"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        <CheckBoxField
                          control={control}
                          name="ffp"
                          label="FFP"
                          disabled={true}
                          disableColor
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["FFP Qty"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        
                        <CheckBoxField
                          control={control}
                          name="rdp"
                          label="RDP"
                          disabled={true}
                          disableColor
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["RDP Qty"]}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        
                        <CheckBoxField
                          control={control}
                          name="sdp"
                          label="SDP"
                          disabled={true}
                          disableColor
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["SDP Qty"]}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        <CheckBoxField
                          control={control}
                          name="cryoprecipitate"
                          label="CRYOPRECIPITATE"
                          disabled={true}
                          disableColor
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.CRYOPRECIPITATEQty}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        <CheckBoxField
                          control={control}
                          name="specifyAnyOther"
                          label="Specify Any Other"
                          disabled={true}
                          disableColor
                        />
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["Any Other Text"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div>
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <fieldset className="">
                  <div className="py-1 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-2 ">
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                      <span className=" font-semibold w-40">On Date</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow?.OnDate}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">At Time</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.AtTime}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        Location where blood will be needed
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.Location}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">
                        
                        Consultant authorizing blood transfusion
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow.Consultant}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-0 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-40">RMO Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selectedRow && selectedRow["RMO Name"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div>
              <div className=" border border-gray-300 px-3 rounded-md mt-1">
                <div className="text-blue-900 text-base font-bold px-2 py-1">
                  For Blood Storage
                </div>
                <fieldset className="">
                  <div className="grid md:grid-cols-4 lg:grid-cols-6 gap-3 my-3">
                    <div className=" ">
                      <DatePickerFieldNew
                        control={control}
                        name="fromDate"
                        label="Receiving Date"
                        value={new Date()}
                        disableFuture={true}
                        disablePast={false}
                        inputFormat="dd-MM-yyyy"
                        disabled={true}
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        name="receivingTime"
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              label="Receiving Time"
                              value={value}
                              onChange={onChange}
                              disabled={true}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  // error={errors.startTime}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: "  ",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                    <div className="col-span-2">
                      <InputField
                        name="receivedBy"
                        label={"Received By"}
                        defaultValue={token?.userName}
                        variant="outlined"
                        control={control}
                        disabled={true}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="gap-3 my-3 flex justify-end">
              <CommonButton
                label="Cancel"
                className="border border-customRed text-customRed"
                onClick={() => handleClose()}
              />
              <CommonButton
                label="Proceed"
                className="bg-customGreen text-white"
                onClick={getAcceptBloodReq}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
