import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

// POST List
export const fetchAllPurchaseApprovalList = async (purchaseApprovalObj) => {
  return await apiClient.post(
    `/purchase/pOInvoicesApprovalList`,
    purchaseApprovalObj,
    {
      headers: authHeader(),
    }
  );
};

export const getApprovedOrRejectPurchase = async ( menuId, privilege, object) => {
  return await apiClient.post(
    `purchase/approvedOrRejectPurchase/${menuId}/${privilege}`, object,
    {
      headers: authHeader(),
    }
  );
};

export const savePurchaseApproval = async (postObj) => {
  return await apiClient.post(`/purchase/savePurchaseApproval`, postObj, {
    headers: authHeader(),
  });
};

//Modal 1st Tabel
export const getPOApprovalList = async (modalListObj) => {
  return await apiClient.post(`/purchase/getPOApprovalList`, modalListObj, {
    headers: authHeader(),
  });
};

// Modal 2nd tabel
export const getPOApprovalDetailsList = async (storeId, approvalId) => {
  return await apiClient.get(`/purchase/getPOApprovalDetailsList/${storeId}/${approvalId}`,  {
    headers: authHeader(),
  });
};