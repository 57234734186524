import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";
// ​/api​/stockAdjustment​/saveMrpAdjustment

export const postMrpAdjustment = (postMrpObj) => {
  return apiClient.post(`/stockAdjustment/saveMrpAdjustment`, postMrpObj, {
    headers: authHeader(),
  });
};

// /api/reports/inventory/mrpAdjustment/{mrpAdjustmentId}

export const printMRPAdjustmentReceipt = (mrpAdjustmentId) => {
  return apiClient.get(`/reports/inventory/mrpAdjustment/${mrpAdjustmentId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};
