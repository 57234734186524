import React from "react";

export default function Callhistory() {
  return (
    <div>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1956 25.2791C16.3396 25.3152 16.4935 25.2709 16.5984 25.1657L18.6179 23.1461C19.3681 22.396 20.3321 22.2224 21.1388 22.9113C22.6316 24.1862 24.9155 26.4387 26.0552 27.5776C26.4438 27.9659 26.4393 28.5921 26.0508 28.9805L23.6504 31.3809C22.3685 32.6628 14.3247 31.5647 8.68622 25.9262C3.33483 20.5748 2.00498 12.2996 3.28685 11.0178C3.28685 11.0178 4.1023 10.2023 4.92858 9.37604C5.72748 8.57715 7.02906 8.5981 7.80182 9.4223L11.6338 13.5094C12.3722 14.2969 12.3523 15.5282 11.589 16.2916L9.50956 18.371C9.39763 18.4829 9.35746 18.6473 9.40469 18.7987C9.73902 19.8693 11.337 24.0586 16.1956 25.2791Z"
          fill="#4A95D0"
        />
        <path
          d="M23.4111 16.9878H19.2311C18.3918 16.9878 17.709 16.305 17.709 15.4657V11.3225C17.709 10.7591 18.1657 10.3028 18.7287 10.3028C19.2921 10.3028 19.7484 10.7595 19.7484 11.3225V14.9484H23.4107C23.9741 14.9484 24.4304 15.4051 24.4304 15.9681C24.4308 16.5311 23.9741 16.9878 23.4111 16.9878Z"
          fill="#C16B2B"
        />
        <path
          d="M26.0689 23.9389C25.7818 23.9389 25.4947 23.8292 25.2757 23.6102C24.8376 23.1721 24.8376 22.4618 25.2757 22.0238C26.9804 20.319 27.9194 18.0517 27.9194 15.6404C27.9194 13.2291 26.9804 10.9621 25.2757 9.25702C22.1236 6.10495 17.059 5.73716 13.4952 8.4017C12.9991 8.77246 12.2959 8.67131 11.9251 8.17485C11.554 7.67875 11.6555 6.9759 12.152 6.60476C16.603 3.27715 22.9269 3.73569 26.8622 7.67095C28.9908 9.79923 30.163 12.6296 30.163 15.6404C30.163 18.6512 28.9908 21.4815 26.8622 23.6102C26.6431 23.8292 26.356 23.9389 26.0689 23.9389Z"
          fill="#F4912E"
        />
        <path
          d="M16.3586 10.3874C16.2437 10.3874 16.127 10.3696 16.012 10.3324L11.9425 9.01109C11.6034 8.90585 11.3531 8.7054 11.204 8.41794L10.9883 8.0018L11.1322 7.54662L12.4107 3.60913C12.6023 3.01969 13.2348 2.69764 13.8243 2.88842C14.4137 3.07994 14.7361 3.71251 14.545 4.30194L13.6063 7.19259L16.7052 8.19853C17.2947 8.39005 17.6171 9.02262 17.4259 9.61205C17.2712 10.0858 16.8317 10.3874 16.3586 10.3874Z"
          fill="#F4912E"
        />
      </svg>
    </div>
  );
}
