import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const saveTemplate = (postObj) => {
  return apiClient.post(`/masters/saveTemplate`, postObj, {
    headers: authHeader(),
  });
};

export const getTemplateList = (postObj) => {
  return apiClient.post(`/masters/getTemplateList`, postObj, {
    headers: authHeader(),
  });
};

export const deleteAnswerPatternScale = (id, employeeId) => {
  return apiClient.delete(
    `/masters/deleteAnswerPatternScale/${id}/${employeeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDepartmentDropDown = () => {
  return apiClient.get(`/masters/getDepartmentDropDown`, {
    headers: authHeader(),
  });
};

//masters/getAnswerPatternScaleForTemplate
export const getAnswerPatternScaleForTemplate = (isMultiLang) => {
  return apiClient.get(`/masters/getAnswerPatternScaleForTemplate/${isMultiLang}`, {
    headers: authHeader(),
  });
};

export const deleteFeedBackQuestionTemplate = (deleteRowId,empId) => {
  return apiClient.delete(
    `/masters/deleteFeedbackTemplate/${deleteRowId}/${empId}`,
    {
      headers: authHeader(),
    }
  );
};

export const checkIsSurveyPublished = (questionFor,depId) => {
  return apiClient.get(`/questionsCreations/checkIsSurveyPublished/${questionFor}/${depId}`, {
    headers: authHeader(),
  });
};

export const createAndSaveFeedBackIsPublishedTemplate = (postObj) => {
  return apiClient.post(`/questionsCreations/createAndSaveFeedBackTemplate`, postObj, {
    headers: authHeader(),
  });
};

export const markIsPublishedAndIsBroadcast = (postObj) => {
  return apiClient.post(`/reviews/markIsPublishedAndIsBroadcast`, postObj, {
    headers: authHeader(),
  });
};