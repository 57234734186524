import { yupResolver } from "@hookform/resolvers/yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import {
  fetchBirthDetails,
  fetchDoctor,
  fetchGenders,
  fetchPrefix,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  autoSearchService,
  fetchPrintOpdChargingSlip,
  fetchUnit,
  postMyEmergencyData,
} from "../../services/emergencyservice/EmergencyServices";
import { format, isAfter } from "date-fns";

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

export default function OpdNotRegisteredForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = React.useState(false);
  const [service, setService] = React.useState([]);
  const [serviceData, setServiceData] = React.useState([]);
  const [unit, setUnit] = React.useState([]);
  const [doctor, setDoctor] = React.useState();
  const [finalData, setFinalData] = React.useState({});
  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);
  const [genders, setGenders] = React.useState([]);
  const [prefixes, setPrefixes] = React.useState();
  //const [openBirthDate, setOpenBirthDate] = React.useState(false);
  const [enteredAge, setEnteredAge] = React.useState();
  const [serviceErrorMessage, setServiceErrorMessage] = React.useState("");
  const [qtyErrorMessage, setQtyErrorMessage] = React.useState("");
  const [mlcModalData, setMlcModalData] = React.useState(null);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    props.setOpen(false);
  };

  const handleOpenPrintModal = (emergencyId) => {
    props.setOpenBackdrop(true);

    fetchPrintOpdChargingSlip(emergencyId).then((response) => {
      if (response) {
        props.setOpenBackdrop(false);
        setUrlforPrint(response);
        setOpenPrintModal(true);
      }
    });
  };

  const schema = yup.object().shape({
    prefix: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Please Select Prefix"),
        label: yup.string().required("Please Select Prefix"),
      }),
    firstName: yup
      .string()
      .required("Required")
      .min(1, "Add First Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),
    lastName: yup
      .string()
      .required("Required")
      .min(1, "Add Last Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),

    dob: yup
      .date()
      .typeError("Please Provide Date of Birth in Valid Format")
      .min("01/01/1902", "You are Not Eligible")
      .max(new Date(), "You can't be born in the future!")
      .required(),

    age: yup
      .string()
      .matches(/^(?:10[0-9]|110|[0-9][0-9]|[2-9][0-9]|[0-9])$/, "Invalid")
      .required("Required"),

    doctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),

    gender: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      opdIpd: 0, //0 for OPD , 1 for IPD
      isEmergency: true,
      unit: null,
      services: null,
      prefix: null,
      firstName: "",
      middleName: "",
      lastName: "",
      mobileNumber: "",
      gender: null,
      employee: null,
      dob: new Date(),
      age: 0,
      ageInYears: 0,
      ageInMonths: 0,
      ageInDays: 0,
      remarks: "",
      doctor: "",
      Qty: "",
    },
  });

  const finalObject = {
    opdIpd: 0, //0 for OPD , 1 for IPD
    addedBy: 0,
    isEmergency: false,
    unitId: null,
    patientId: 0,
    prefixId: 0,
    firstName: "",
    genderId: 0,
    lastName: "",
    middleName: "",
    dob: "",
    ageYear: "",
    ageDay: "",
    ageMonth: "",
    mrNo: 0,
    isMlc: false,
    admissionAdvised: false,
    patientSourceId: null,
    referralConsultantId: null,
    bedCategoryId: null,
    medicalManagement: false,
    surgicalManagement: false,
    emergencyServicesDto: [0],
    remark: "",
    doctorId: null,
    menuId: "",
    privilege: "",
  };

  //state variable to close the confirmation modal for POST request
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  //state variable to close the confirmation modal for PUT request
  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };

  const {
    control,
    reset,
    register,
    watch,
    getValues,
    setValue,
    setError,

    formState: { errors },
  } = methods;

  let ServiceInfo = watch("services");
  let isMlc = watch("isMlc");

  const StateIsMlc = () => {
    setOpenModal(isMlc);
  };

  useEffect(() => {
    if (isMlc !== undefined) {
      StateIsMlc();
    }
  }, [isMlc]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  //get Values of input field on mlc checkbox
  if (isMlc === true) {
    var firstName = getValues("firstName");
    var lastName = getValues("lastName");
    var gender = getValues("gender");
    var age = getValues("age");
    var genderLabel = gender?.label;
    var mrNo = 0;

    {
      // var patientName = prefixLabel + " " + firstName + " " + lastName;
      var patientName = firstName + " " + lastName;
    }
  }

  // setValue("services", null);
  const onSubmit = (data) => {
    setOpenPost(true);
    setFinalData(data);
    // props.setOpenBackdrop(true);
  };

  const onSubmitDataHandler = (data) => {
    setOpenPost(false);
    // props.setOpen(false);

    methods.reset();
    let token = JSON.parse(localStorage.getItem("userInfo"));

    let user = token.userId;
    let unit = token.unitId;

    finalObject.menuId =
      props.location?.state?.menuId && Number(props.location.state.menuId);

    finalObject.privilege = props.privilege;

    finalObject.opdIpd = 0;

    finalObject.patientId = Number(0);

    finalObject.mrNo = 0;

    finalObject.addedBy = Number(user);

    finalObject.isEmergency = false;

    finalObject.admissionAdvised = false;

    finalObject.medicalManagement = false;

    finalObject.surgicalManagement = false;

    finalObject.unitId = Number(unit);

    finalObject.prefixId = Number(data.prefix.id);

    data.firstName !== null
      ? (finalObject.firstName =
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1))
      : (finalObject.firstName = null);

    data.middleName !== null
      ? (finalObject.middleName =
          data.middleName.charAt(0).toUpperCase() + data.middleName.slice(1))
      : (finalObject.middleName = null);

    data.lastName !== null
      ? (finalObject.lastName =
          data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1))
      : (finalObject.lastName = null);

    data.gender !== null && data.gender !== undefined
      ? (finalObject.genderId = Number(data.gender.id))
      : (finalObject.genderId = Number(0));

    //finalObject.genderId = Number(data.gender.id);

    data.remarks !== null
      ? (finalObject.remark = data.remarks)
      : (finalObject.remark = null);

    let servicesArrey = [];
    for (let i = 0; i < serviceData.length; i++) {
      if (serviceData[i] !== null) {
        let serviceId = serviceData[i].id;
        let doctorId = serviceData[i].DoctorId;
        let quantity = Number(serviceData[i].Quantity);
        servicesArrey.push({ serviceId, doctorId, quantity });
      }
    }
    finalObject.emergencyServicesDto = servicesArrey;

    data.ageInDays !== 0 && data.ageInDays !== undefined
      ? (finalObject.ageDay = Number(data.ageInDays))
      : (finalObject.ageDay = Number(0));

    data.ageInMonths !== 0 && data.ageInDays !== undefined
      ? (finalObject.ageMonth = Number(data.ageInMonths))
      : (finalObject.ageMonth = Number(0));

    finalObject.ageYear = Number(data.ageInYears);

    finalObject.dob = format(data.dob, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");

    finalObject.patientSourceId = Number(0);

    data.doctor !== null
      ? (finalObject.doctorId = Number(data.doctor.id))
      : (finalObject.doctorId = Number(0));

    finalObject.referralConsultantId = Number(0);

    finalObject.bedCategoryId = Number(0);

    finalObject.isMlc = isMlc;

    finalObject.mlcRequestDto = mlcModalData;

    props.setOpenBackdrop(true);
    postMyEmergencyData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
          props.setOpenBackdrop(false);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          handleClosePost();
          setServiceData([]);
          methods.reset();
          props.populateTable();
          navigate("/emergency/registration", {
            state: { menuId: location?.state?.menuId },
          });
          props.setOpenBackdrop(false);
          props.setOpenBackdrop(false);
          //
          handleOpenPrintModal(res.result);
        }
      })
      .catch((res) => {
        handleClosePost();
        props.setOpenBackdrop(false);
        props.setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());
      });
  };

  //API for Prefix Dropdown
  useEffect(() => {
    getPrefixList();
  }, []);
  //axios get request to have all the list of gender.
  function getPrefixList() {
    fetchPrefix()
      .then((response) => {
        setPrefixes(response.data.result);
      })
      .catch(() => {});
  }

  //API For Gender dropdown
  useEffect(() => {
    fetchGenders()
      .then((response) => {
        setGenders(response.data.result);
      })
      .catch(() => {});
  }, []);

  //  DOB ==1st case [[API Calculate Age Based on DOB]]
  const getAgeDOB = (e) => {
    let dobValue = e;
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    fetchBirthDetails(0, fullDOB)
      .then((response) => {
        setValue("age", response.data.result.years, { shouldValidate: true });
        setValue("ageInYears", response.data.result.years);
        setValue("ageInMonths", response.data.result.months);
        setValue("ageInDays", response.data.result.days);
        // setValue("dob", dobGivenYear, dobGivenMonth, day);
      })
      .catch(() => {});
  };

  //DOB == 2nd case [[API Calculate DOB based on Age]]
  useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA")
        .then((response) => {
          setValue("dob", response.data.result.dob, {
            shouldValidate: true,
          });
          setValue("ageInYears", enteredAge);
          setValue("ageInMonths", response.data.result.months);
          setValue("ageInDays", response.data.result.days);
        })
        .catch(() => {});
    }
  }, [enteredAge]);

  useEffect(() => {
    //unit
    fetchUnit(unit)
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);
      });
  }, []);

  //We select service quantity value bedefault set 1
  //let services = watch("services");
  useEffect(() => {
    if (ServiceInfo !== null) {
      setValue("Qty", 1);
      setQtyErrorMessage("");
    } else if (ServiceInfo === null) {
      setValue("Qty", "");
      setQtyErrorMessage("");
    }
  }, [ServiceInfo]);

  // Handle Change Use For Service api
  const handleChange = (autoServceSearchString) => {
    if (autoServceSearchString !== "") {
      autoSearchService(autoServceSearchString)
        .then((response) => response.data)
        .then((res) => {
          // setValue("Qty", 1);
          setService(res.result);
          setServiceErrorMessage("");
        })
        .catch(() => {});
    }
  };

  ///

  function unDuplicateArraySingleValues(array) {
    if (!array || !Array.isArray(array) || array.length === 0) {
      return array;
    }
    return [...new Set(array)];
  }

  function unDuplicateArrayObjects(array, propertyName) {
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      !propertyName
    ) {
      return array;
    }
    let objectArrayKeys = array.map((item) => item[propertyName]);

    let uniqueKeys = unDuplicateArraySingleValues(objectArrayKeys);
    return uniqueKeys.map((key) =>
      array.find((item) => item[propertyName] === key)
    );
  }

  const addServiceData = () => {
    let services = getValues("services");
    let doctor = getValues("serviceDoctor");
    let Qty = getValues("Qty");

    if (services === null && Qty === "") {
      setError("services", { type: "custom", message: "Required" });
      setError("Qty", { type: "custom", message: "Required" });
    } else if (services === null) {
      setError("services", { type: "custom", message: "Required" });
    } else if (Qty === "") {
      setError("Qty", { type: "custom", message: "Required" });
    }

    if (services !== null) {
      let serviceObj = {
        id: services.id,
        servicename: services.label,
        serviceccode: services.value,
        Quantity: Qty,

        DoctorId: doctor !== undefined && doctor !== null ? doctor.id : 0,
        DoctorName: doctor !== undefined && doctor !== null ? doctor.label : "",
      };

      let arr = [...serviceData];
      arr.push(serviceObj);
      setServiceData(unDuplicateArrayObjects(arr, "id"));
      setValue("services", "", { shouldValidate: true });
      setValue("serviceDoctor", null, { shouldValidate: true });
      setValue("Qty", "", { shouldValidate: true });
    }

    if (services !== null) {
      setQtyErrorMessage("");
      setServiceErrorMessage("");
    }
  };

  ////////table related
  const renderActions = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...serviceData];
      newTemplateData.splice(index, 1);
      setServiceData(newTemplateData);
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon
              sx={{ color: "#ef4444" }}
              onClick={() => deleteRow(index)}
            />
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <div className="my-2">
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mx-2">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2 my-2">
          <div className="flex gap-2">
            <div className="w-1/3 lg:w-6/12">
              <DropdownField
                control={control}
                error={errors.prefix}
                name="prefix"
                dataArray={prefixes}
                placeholder="Prefix *"
                isSearchable={false}
                isClearable={false}
                maxMenuHeight={200}

                inputRef={{
                  ...register("prefix", {
                    onChange: (e) => {
                      setValue(
                        "gender",
                        {
                          id: e.target.value.SexId,
                          label: e.target.value.gender,
                          value: e.target.value.gender,
                        },
                        { shouldValidate: true }
                      );
                    },
                  }),
                }}
              />
            </div>
            <InputField
              name="firstName"
              variant="outlined"
              label="First Name*"
              error={errors.firstName}
              control={control}
              inputProps={{
                style: { textTransform: "capitalize" },
              }} // use inputProps props for return 1st letter in upper case
            />
          </div>
          <div>
            <InputField
              name="middleName"
              variant="outlined"
              label="Middle Name"
              error={errors.middleName}
              control={control}
              inputProps={{
                style: { textTransform: "capitalize" },
              }} // use inputProps props for return 1st letter in upper case
            />
          </div>
          <div>
            <InputField
              name="lastName"
              variant="outlined"
              label="Last Name*"
              error={errors.lastName}
              control={control}
              inputProps={{
                style: { textTransform: "capitalize" },
              }} // use inputProps props for return 1st letter in upper case
            />
          </div>
          {/* gender */}
          <div>
            <DropdownField
              control={control}
              error={errors.gender}
              name="gender"
              label="gender *"
              dataArray={genders}
              isSearchable={false}
              isClearable={false}
              placeholder="Gender *"
              maxMenuHeight={200}

            />
          </div>

          <div className="flex gap-2">
            {/* //Date of Birth // */}
            <div className="col-span-2">
              <DatePickerFieldNew
                control={control}
                error={errors.dob}
                name="dob"
                label="Date of Birth"
                value={new Date()}
                inputRef={{
                  ...register("dob", {
                    onChange: (e) => {
                      isAfter(e?.target?.value, new Date(1900, 1, 1)) &&
                        getAgeDOB(e?.target?.value);
                    },
                  }),
                }}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
              {/*
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      open={openBirthDate}
                      onOpen={() => setOpenBirthDate(true)}
                      onClose={() => setOpenBirthDate(false)}
                      inputProps={{ readOnly: true }}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          type="date"
                          variant="outlined"
                          label="Date of Birth"
                          name="dob"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          size="small"
                          // disabled={true}
                          readOnly={true}
                          onClick={(e) => setOpenBirthDate(true)}
                        />
                      )}
                      PopperProps={{ placement: "auto-end" }}
                      inputFormat="dd/MM/yyyy"
                      disableFuture
                      {...field}
                      onAccept={(e) => {
                        getAgeDOB(e);
                      }}
                      error={Boolean(errors.dob)}
                      helperText={errors.dob?.message}
                    />
                  </LocalizationProvider>
                )}
                name="dob"
              />*/}
            </div>
            {/* //Age// */}
            <div>
              <InputLimit
                maxDigits={3}
                name="age"
                variant="outlined"
                label="Age *"
                error={errors.age}
                control={control}
                inputRef={{
                  ...register("age", {
                    onChange: (e) => {
                      setEnteredAge(e.target.value);
                    },
                  }),
                }}
                onKeyDown={(e) => {
                  if (
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.code === "ArrowUp" ||
                    e?.code === "ArrowDown" ||
                    e?.code === "NumpadDecimal"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          {/* //Years Months Days // */}
          <div className="flex justify-between gap-2 lg:ml-2">
            <InputField
              name="ageInYears"
              variant="outlined"
              label="Years"
              control={control}
              disabled={true}
            />
            {/* //Months// */}
            <InputField
              name="ageInMonths"
              variant="outlined"
              label="Months"
              control={control}
              disabled={true}
            />
            {/* //Days// */}
            <InputField
              name="ageInDays"
              variant="outlined"
              label="Days"
              control={control}
              disabled={true}
            />
          </div>
          <div>
            {/* <Search doctor */}
            <SearchDropdown
              control={control}
              searchIcon={true}
              maxMenuHeight={200}

              isDisabled={false}
              isSerchable={true}
              isClearable={true}
              error={errors.doctor}
              name="doctor"
              label="Doctor *"
              placeholder="Doctor *"
              isMulti={false}
              dataArray={doctor}
              handleInputChange={(e) => {
                // Doctor
                e &&
                  fetchDoctor(e)
                    .then((response) => {
                      setDoctor(response.data.result);
                    })
                    .catch(() => {});
              }}
            />
          </div>
          <div>
            <CheckBoxField
              control={control}
              name="isMlc"
              label="Medico Legal Case"
            />
          </div>
        </div>

        <div className="">
          <div>
            <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
              Service Information
            </span>
          </div>
          <div className="grid grid-cols-6 gap-3 mt-3">
            <div className="col-span-2">
              <SearchDropdown
                control={control}
                maxMenuHeight={200}

                name="services"
                label="Search by Services"
                placeholder="Search by Service"
                searchIcon={true}
                isSearchable={true}
                isClearable={true}
                dataArray={service}
                handleInputChange={handleChange}
                error={errors.services}
              />

              <p className="text-customRed text-sm">{serviceErrorMessage}</p>
            </div>

            <div className="">
              <InputField
                name="Qty"
                type="number"
                variant="outlined"
                label="Qty"
                error={errors.Qty}
                control={control}
              />
              <p className="text-customRed text-sm">{qtyErrorMessage}</p>
            </div>

            {ServiceInfo && ServiceInfo.DoctorShare ? (
              <>
                {/* <SearchBar doctor */}
                <div className="col-span-2">
                  <SearchDropdown
                    control={control}
                    maxMenuHeight={200}

                    searchIcon={true}
                    isDisabled={false}
                    isSerchable={true}
                    isClearable={true}
                    error={errors.doctor}
                    name="serviceDoctor"
                    label="Doctor"
                    placeholder="Doctor"
                    isMulti={false}
                    dataArray={doctor}
                    handleInputChange={(e) => {
                      // Doctor
                      fetchDoctor(e)
                        .then((response) => {
                          setDoctor(response.data.result);
                        })
                        .catch(() => {});
                    }}
                  />
                </div>
              </>
            ) : null}
            <div>
              <CommonButton
                label="Add"
                className="bg-customGreen text-white"
                onClick={() => {
                  addServiceData();
                  setValue("services", null);
                  setValue("Qty", "");
                }}
              />
            </div>
          </div>
          {/* table */}
          <div className="w-full mt-2">
            {serviceData.length > 0 && (
              <CommonDynamicTableNew
                dataResult={serviceData}
                renderActions={renderActions}
                highlightRow={false}
                removeHeaders={["id", "DoctorId"]}
                tableClass="max-h-72"
              />
            )}
          </div>
        </div>

        {/* remarks */}
        <div className="w-full my-2">
          <InputField
            name="remarks"
            variant="outlined"
            label="Remark"
            error={errors.remarks}
            control={control}
            inputProps={{
              style: { textTransform: "capitalize" },
            }} // use inputProps props for return 1st letter in upper case
            required
          />
        </div>

        {/* Buttons Add Update Cancel Reset */}
        <div className="flex gap-2 justify-end mb-2">
          {Actions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "save" ? (
                <>
                  <CommonButton
                    label="Reset"
                    onClick={() => (
                      reset(methods),
                      setValue("dob", new Date(), {
                        shouldValidate: true,
                      }),
                      setServiceData([])
                    )}
                    className="border border-customRed text-customRed"
                  />

                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                  />
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>

      {/* mlc Modal */}
      {openModal ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setMlcModalData={setMlcModalData}
          patientName={patientName}
          gender={genderLabel}
          age={age}
          mrNo={mrNo}
          isEdit={false}
          MLCNo={null} //admissionId,emergencyId
          admissionId={0}
          emergencyId={0}
          opdIpd={1}
          isFromEmergency={true}
          isFromForm={true}
          ipdEmergency={2}
        />
      ) : null}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* Confirmation modal for PUT request */}
      <ConfirmationModal
        confirmationOpen={openPut}
        confirmationHandleClose={handleClosePut}
        // confirmationSubmitFunc={updateRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Update"
      />
      {/* Confirmation modal for POST request */}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={() => onSubmitDataHandler(finalData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
