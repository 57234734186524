import React from "react";

function SearchIndentButton({ onClick }) {
  return (
    <div className="">
      <button
        type="button"
        className="h-9 w-full px-3  bg-blue-900  text-white rounded text-sm font-medium"
        onClick={onClick}
      >
        Search Indent
      </button>
    </div>
  );
}

export default SearchIndentButton;
