import React, { useEffect } from "react";

import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import CommonDynamicTable from "../../../common/components/CommonTable/CommonDynamicTable";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import {
  fetchAllDischargeList,
  releaseBed,
} from "../../services/bedReleaseServices/BedReleaseServices";
import { BedReleaseIcon } from "./BedReleaseIcon";
let actions = ["releaseBed"];
const Actions = [
  {
    id: 0,
    action: "releaseBed",
    isAction: true,
  },
];
const removeHeaders = [
  "IsClosed",
  "DischargeId",
  "GenderId",
  "allowgotoslip",
  "CategoryId",
  "AdmissionId",
  "BedId",
];
export default function BedRelease() {
  //state variables
  const [data, setData] = React.useState([]);

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [releaseSelectedBed, setReleaseSelectedBed] = React.useState();
  const [loadingSpinner, setLoadingSpinner] = React.useState(false);
  const [allowGotoSlip, setAllowGotoSlip] = React.useState();
  const [selectedRow, setSelectedRow] = React.useState();
  const [recordWarning, showRecordWarning] = React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [menuIdVal, setMenuIdVal] = React.useState(null);
  //   other variables
  let location = useLocation();
  console.log("locationmenuidis", location?.state?.menuId);
  // useEffects
  useEffect(() => {
    populateDischargeListing();
  }, []);

  const handleOpenConfirmatoin = () => {
    setConfirmationOpen(true);
  };
  const handleCloseConfirmatoin = () => {
    setConfirmationOpen(false);
  };

  // functions
  function populateDischargeListing() {
    setLoadingSpinner(true);
    showRecordWarning(false);
    let menuId = location.state.menuId;
    fetchAllDischargeList(menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.actions);
        console.log("dischargee listing is", res);
        setLoadingSpinner(false);
        setData(res.result);
      });
  }

  //functions
  function releasePatientBed() {
    let releaseBedPostObj = {
      admissionId: allowGotoSlip && allowGotoSlip.AdmissionId,
      bedId: allowGotoSlip && allowGotoSlip.BedId,
      genderID: allowGotoSlip && allowGotoSlip.GenderId,
      menuId: location?.state?.menuId,
      privilege: menuIdVal?.action,
    };

    if (allowGotoSlip && allowGotoSlip.allowgotoslip !== false) {
      releaseBed(releaseBedPostObj)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          setReleaseSelectedBed(res.result);
          populateDischargeListing();
        });
    } else {
      errorAlert("Please Discharge This Patient !");
    }
  }

  function displayTableReleaseData(row) {
    console.log("displayTableReleaseDatadisplayTableReleaseData", row);
    setAllowGotoSlip(row);
  }

  function handleSelectedRow() {}

  // actions and print
  const renderActions = (row, index) => {
    console.log("renderActionsrenderActions", userActions);
    return (
      <div className="flex gap-3">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-2 cursor-pointer">
                    {actions.action === "Create" ? (
                      <Tooltip title="Release Bed">
                        {/* <div onClick={()=>{
                         
                      }}>
                        <img src={bedReleaseimg} alt={"Release Bed"} />
                      </div> */}
                        <button
                          onClick={() => {
                            handleOpenConfirmatoin();

                            displayTableReleaseData(row);
                            setMenuIdVal(actions);
                          }}
                        >
                          <BedReleaseIcon />
                        </button>
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  return (
    <>
      <div className="pt-12">
        <div className="text-center text-black font-bold text-xl ">
          Release Bed
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center lg:gap-10 md:gap-10 2xl:gap-10">
            <div>
              <p className="font-bold text-sm py-2 px-1 text-gray-700">
                Discharge Bed List
              </p>
            </div>
          </div>
        </div>
        {loadingSpinner ? (
          <div className="grid justify-center items-center pt-12">
            <LoadingSpinner />
          </div>
        ) : null}
        <div>
          {/* {data.hasOwnProperty("result") &&
          data.result.length > 0 &&
          data.statusCode === 200 ? ( */}
          {data.length > 0 && loadingSpinner === false ? (
            <CommonDynamicTable
              data={data}
              actionsRequired={true}
              highlightRow={true}
              renderActions={renderActions}
              handleSelectedRow={handleSelectedRow}
              setSelectedRow={setSelectedRow}
              removeHeaders={removeHeaders}
              tableClass={"rounded lg:h-72 md:h-72 2xl:80"}
            />
          ) :  <>
          <div className="text-center pt-24 p-4 items-center">No Record Found</div>
        </>}
        </div>
        {recordWarning === true && loadingSpinner === false ? (
          <div className="flex justify-center">
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        ) : null}
        {/* <div className="flex justify-end py-2">
          <CancelButton />
        </div> */}
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmatoin}
          confirmationSubmitFunc={() => {
            handleCloseConfirmatoin();
            releasePatientBed();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to Release Bed ?"
          confirmationButtonMsg="Release"
        />
      </div>
    </>
  );
}
