import * as React from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function WorkOrderTable(props) {
  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                },
              }}
              className="rounded h-56"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        UOM
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Rate
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Total Amount
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Disc(%)
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Disc.Amount
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        GST(%)
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        GST Amount
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Net Amount
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Remark
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.storeData.map((data, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0.5,
                          },
                        }}
                      >
                        <>
                          <TableCell className="flex whitespace-nowrap">
                            <Tooltip title="Edit">
                              <div
                                value="click"
                                className="text-blue-900 mr-1"
                              >
                                <DriveFileRenameOutlineIcon
                                  onClick={(e) => {
                                    // e.preventDefault();
                                    props.editRow(data, index);
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <div
                                className="text-red-500 mr-3"
                                onClick={() => {
                                  props.deleteRecord(index);
                                  // props.setDeleteIndex(index);
                                }}
                              >
                                {<DeleteOutlineOutlinedIcon />}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.itemCode}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.itemName}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.qty}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.uom}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.rate}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.amount}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.discPer}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.discAmt}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.vatPer}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.vatAmt}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.netAmount}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {data.remark}
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
