import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import {
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  getAccountHead,
  saveContraVoucherMultiple,
} from "../../services/multipleVoucherServices/MultipleVoucherServices";

const PaymentOption = [
  { id: "Journal", label: "Journal", value: "Journal" },
  { id: "Cheque", label: "Cheque", value: "Cheque" },
  { id: "Cash", label: "Cash", value: "Cash" },
];

const MultipleVoucher = () => {
  const [voucher, setVoucher] = useState("Cash");
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [multipleVoucherList, setMultipleVoucherList] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [finalData, setFinalData] = useState(null);
  const [accountHeadList, setAccountHeadList] = useState(null);

  const defaultValues = {
    dr: null,
    cr: null,
    chequeDate: new Date(),
    inFavour: "",
    amount: "",
    chequeNo: "",
    narration: "",
    drAmount: "",
    crAmount: "",
    totalDr: "",
    totalCr: "",
    paymentOption: voucher,
  };

  const schema = yup.object().shape({
    dr: yup.object().nullable().required(),
    cr: yup.object().nullable().required(),
    drAmount: yup.number().required(),
    crAmount: yup.number().required(),
    inFavour: yup.string().when("paymentOption", (paymentOption) => {
      if (paymentOption === "Cheque") {
        return yup.string().required();
      }
    }),
    chequeNo: yup.string().when("paymentOption", (paymentOption) => {
      if (paymentOption === "Cheque") {
        return yup.string().required();
      }
    }),
  });
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  let getUserInfo = JSON.parse(localStorage?.userInfo);
  let getPaymentOption = watch("paymentOption");
  let getDr = watch("dr");
  let getCr = watch("cr");
  let getDrAmount = watch("drAmount");
  let getCrAmount = watch("crAmount");
  let getNarration = watch("narration");
  let getChequeNo = watch("chequeNo");
  let getChequeDate = watch("chequeDate");
  let getInFavour = watch("inFavour");
  let getTotalCr = watch("totalCr");
  let getTotalDr = watch("totalDr");

  function addMultipleVoucher() {
    let tempMultipleVoucherList = [...multipleVoucherList];
    let drObj = {
      DrId: getDr?.id,
      CrId: null,
      Description: getDr?.label,
      DrAmount: getDrAmount,
      CrAmount: "",
      Narration: getNarration,
      ChequeNo: getChequeNo,
      ChequeDate: format(getChequeDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      InFavour: getInFavour,
    };
    let crObj = {
      DrId: null,
      CrId: getCr?.id,
      Description: getCr?.label,
      DrAmount: "",
      CrAmount: getCrAmount,
      Narration: getNarration,
      ChequeNo: getChequeNo,
      ChequeDate: format(getChequeDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      InFavour: getInFavour,
    };
    tempMultipleVoucherList.push(drObj, crObj);
    reset(defaultValues);
    setValue("paymentOption", voucher);
    matchDrCrAmount(tempMultipleVoucherList);
    setMultipleVoucherList(tempMultipleVoucherList);
  }

  function matchDrCrAmount(arr) {
    let sumDr = 0;
    let sumCr = 0;
    for (let a = 0; a < arr?.length; a++) {
      sumDr += Number(arr[a]?.DrAmount);
      sumCr += Number(arr[a]?.CrAmount);
    }
    setValue("totalDr", sumDr);
    setValue("totalCr", sumCr);
  }

  function onSubmitData(data) {
    let tempMultipleList = [];
    for (let obj of multipleVoucherList) {
      let tempObj = {
        accountType: obj?.Description,
        amount: Number((getTotalCr + getTotalDr) / 2),
        checkDate: obj?.ChequeDate,
        checkNo: obj?.ChequeNo,
        crBankId: obj?.CrId ? obj?.CrId : 0,
        drBankId: obj?.DrId ? obj?.DrId : 0,
        inFavour: obj?.InFavour,
        journalEntry: getPaymentOption === "Journal" ? true : false,
        narration: obj?.Narration,
        unitId: getUserInfo?.unitId,
      };
      if (getTotalCr === getTotalDr) {
        tempMultipleList.push(tempObj);

        setFinalData(tempMultipleList);
        setOpenPost(true);
      } else {
        warningAlert("Transaction value mismathced...!");
      }
    }
  }

  function saveMultipleVoucher() {
    let saveMultipleVoucherList = [...dataResult];
    saveMultipleVoucherList.push();
    saveContraVoucherMultiple(finalData).then((response) => {
      setDataResult([...saveMultipleVoucherList, ...multipleVoucherList]);
      setMultipleVoucherList([]);
      reset(defaultValues);
      successAlert(response.data.message);
      setOpenPost(false);
    });
  }

  useEffect(() => {
    getAccountHead().then((response) => {
      setAccountHeadList(response.data.result);
    });
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  return (
    <form className="mt-12 mb-2" onSubmit={handleSubmit(addMultipleVoucher)}>
      <div className="text-center text-black font-bold text-xl">
        Multiple Ledger Voucher
      </div>
      <h1 className="font-semibold text-lg">List of Ledgers</h1>
      {/* <CommonButton
          type="button"
          label="Add New"
          className="bg-customBlue text-white"
          onClick={() => {
            setOpen(true);
          }}
        /> */}
      <div>
        {dataResult !== undefined && dataResult?.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={dataResult}
            removeHeaders={["id"]}
            tableClass="max-h-40"
            highlightRow={false}
          />
        ) : (
          <div className="justify-center items-center font-semibold h-40 w-full flex border-2 border-gray-300 rounded my-2">
            No Records Found...
          </div>
        )}
      </div>
      <h1 className="px-2 ml-2 lg:ml-0 text-base font-semibold">
        Add Multiple Voucher
      </h1>
      <div className="grid grid-cols-3 lg:grid-cols-6 xl:grid-cols-8 gap-x-2 gap-y-3 items-center">
        <div className="col-span-2">
          <DropdownField
            control={control}
            error={errors.dr}
            name="dr"
            placeholder="Dr."
            dataArray={accountHeadList}
            isClearable={true}
            isSearchable={true}
          />
        </div>
        <div className="">
          <InputField
            label="Amount"
            name="drAmount"
            type="number"
            control={control}
            error={errors.drAmount}
          />
        </div>
        <div className="col-span-2">
          <DropdownField
            control={control}
            error={errors.cr}
            name="cr"
            placeholder="Cr."
            dataArray={accountHeadList}
            isClearable={true}
            isSearchable={true}
          />
        </div>
        <div className="">
          <InputField
            label="Amount"
            name="crAmount"
            type="number"
            control={control}
            error={errors.crAmount}
          />
        </div>
        <fieldset
          className="lg:col-span-2"
          disabled={multipleVoucherList?.length > 0 || dataResult?.length > 0}
          onChange={(e) => {
            setVoucher(e.target.value);
          }}
        >
          <RadioField
            dataArray={PaymentOption}
            name="paymentOption"
            control={control}
          />
        </fieldset>
        {getPaymentOption === "Cheque" && (
          <div className="col-span-2 lg:col-span-3 flex space-x-2">
            <div className="w-56">
              <DatePickerFieldNew
                control={control}
                name="chequeDate"
                label="Cheque Date "
                value={new Date()}
                size="small"
                inputFormat="yyyy-MM-dd"
              />
            </div>
            <div className="w-full">
              <InputField
                label="In Favour"
                name="inFavour"
                control={control}
                error={errors.inFavour}
              />
            </div>
          </div>
        )}
        {getPaymentOption === "Cheque" && (
          <div className="xl:col-span-2">
            <InputField
              label="Cheque No"
              name="chequeNo"
              control={control}
              error={errors.chequeNo}
            />
          </div>
        )}
        <div className="col-span-2 lg:col-span-3 flex space-x-2">
          <div className="w-full">
            <InputArea
              control={control}
              name="narration"
              label="Narration"
              placeholder="Narration"
              minRows={2}
              maxRows={2}
              size="md"
            />
          </div>
          <CommonButton
            type="submit"
            label="Add"
            className="border border-customBlue text-customBlue"
            // onClick={() => {
            //   addMultipleVoucher();
            // }}
          />
        </div>
      </div>
      <div>
        {multipleVoucherList !== undefined &&
        multipleVoucherList?.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={multipleVoucherList}
            tableClass="max-h-60"
            highlightRow={false}
            handleSelectedRow={false}
            SelectCheckbox={false}
            removeHeaders={["DrId", "CrId"]}
            // renderActions={renderActions}
          />
        ) : (
          <div className="justify-center items-center font-semibold h-40 w-full flex border-2 border-gray-300 rounded my-2">
            No Records Found...
          </div>
        )}
      </div>
      <div className="flex justify-end py-2">
        <div className="flex space-x-3">
          <InputField
            label="Dr"
            name="totalDr"
            control={control}
            disabled={true}
          />
          <InputField
            label="Cr"
            name="totalCr"
            control={control}
            disabled={true}
          />
          <CommonButton
            label="Reset"
            type="button"
            className="resetButton border border-customRed text-customRed"
            onClick={() => {
              reset(defaultValues);
              setMultipleVoucherList([]);
              setDataResult([]);
              setVoucher("Cash");
            }}
          />
          {getPaymentOption === "Journal" ? (
            <CommonButton
              label="Save Journal"
              type="button"
              className="saveButton whitespace-nowrap bg-customBlue text-white"
              onClick={() => {
                onSubmitData();
              }}
            />
          ) : (
            <CommonButton
              label="Save"
              type="button"
              className="saveButton whitespace-nowrap bg-customBlue text-white"
              onClick={() => {
                onSubmitData();
              }}
            />
          )}
        </div>
      </div>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={() => {
          setOpenPost(false);
        }}
        confirmationSubmitFunc={saveMultipleVoucher}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add record ?"
        confirmationButtonMsg="Yes"
      />
    </form>
  );
};

export default MultipleVoucher;
