import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

//get user menus
export const getUserMenus = (loginId) => {
  return apiClient.get(`/dashboard/usermenus/${loginId}`, {
    headers: authHeader(),
  });
};

//create menu  and  submenu
export const newMenuOrSubMenu = (postObj) => {
  return apiClient.post(`/functionality/createSystemMenu`, postObj, {
    headers: authHeader(),
  });
};

//create new privilege
export const createNewPrivilege = (postObj) => {
  return apiClient.post(`/privilege/savePrivilege`, postObj, {
    headers: authHeader(),
  });
};

//get listing of  previous privilege
export const fetchAllPreviousPrivilege = (page, size) => {
  return apiClient.get(`privilege/getPrivilegeList/${page}/${size}`, {
    headers: authHeader(),
  });
};

//delete  privilege from list
export const deletePrivilegeById = (privilegeId) => {
  return apiClient.delete(`/privilege/deletePrivilege/${privilegeId}`, {
    headers: authHeader(),
  });
};

//get  list of  functionality privilege
export const fetchAllFunctinalityPrivilege = (menuId) => {
  return apiClient.get(`/functionality/getFunctionalityPrivileges/${menuId}`, {
    headers: authHeader(),
  });
};

//search  privilige    by  name
export const searchPrivilegeByName = (menuId, searchString) => {
  return apiClient.get(
    `/functionality/getPrivilegeForAssignToFun/${menuId}/${searchString}`,
    { headers: authHeader() }
  );
};

//save privilege    to functionality
export const savePrivilegeToFunctionality = (postObj) => {
  return apiClient.post(`/functionality/saveFunctionPrivilege`, postObj, {
    headers: authHeader(),
  });
};

//delete privilege    to functionality
export const deletePrivilegeToFunctionality = (postObj) => {
  return apiClient.post(
    `/functionality/deleteAssignedPrivilegeOfFunctionality`,
    postObj,
    { headers: authHeader() }
  );
};

//get system menu
export const getAllSystemMenu = (searchString) => {
  return apiClient.get(`/functionality/getSystemMenu/${searchString}`, {
    headers: authHeader(),
  });
};

//active  inactive  menu

export const activDeactiveMenu = (postObj) => {
  return apiClient.post(`/functionality/activeInactiveMenu`, postObj, {
    headers: authHeader(),
  });
};

//get image ur
export const getImageUrl = (filePath) => {
  return apiClient.get(`/file/${filePath}`, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
};
