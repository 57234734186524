import React from "react";

export const CompleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M20 3.18L8.59 14.6L4.35 10.36L5.76 8.95L8.59 11.78L18.59 1.78L20 3.18ZM17.79 8.22C17.92 8.79 18 9.39 18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C11.58 2 13.04 2.46 14.28 3.25L15.72 1.81C14.1 0.67 12.13 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 8.81 19.78 7.67 19.4 6.61L17.79 8.22Z"
        fill="#43AA27"
      />
    </svg>
  );
};

export const InCompleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 5.18L10.59 16.6L6.35 12.36L7.76 10.95L10.59 13.78L20.59 3.78L22 5.18ZM19.79 10.22C19.92 10.79 20 11.39 20 12C20 16.42 16.42 20 12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C13.58 4 15.04 4.46 16.28 5.25L17.72 3.81C16.1 2.67 14.13 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 10.81 21.78 9.67 21.4 8.61L19.79 10.22Z"
        fill="#515151"
      />
    </svg>
  );
};

export const RefuseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_11755_9178)">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM18.31 16.9L7.1 5.69C8.45 4.63 10.15 4 12 4C16.42 4 20 7.58 20 12C20 13.85 19.37 15.55 18.31 16.9Z"
          fill="#D21919"
        />
      </g>
      <defs>
        <clipPath id="clip0_11755_9178">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NotRefuseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_11755_9191)">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM18.31 16.9L7.1 5.69C8.45 4.63 10.15 4 12 4C16.42 4 20 7.58 20 12C20 13.85 19.37 15.55 18.31 16.9Z"
          fill="#515151"
        />
      </g>
      <defs>
        <clipPath id="clip0_11755_9191">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 18H8V16H16V18ZM16 14H8V12H16V14ZM13 9V3.5L18.5 9H13Z"
        fill="#1E3A8A"
      />
    </svg>
  );
};

export const NoteDisableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 18H8V16H16V18ZM16 14H8V12H16V14ZM13 9V3.5L18.5 9H13Z"
        fill="#D7D7D7"
      />
    </svg>
  );
};

export const PrintIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.3 8.72222H5.7C4.206 8.72222 3 9.98778 3 11.5556V17.2222H6.6V21H17.4V17.2222H21V11.5556C21 9.98778 19.794 8.72222 18.3 8.72222ZM15.6 19.1111H8.4V14.3889H15.6V19.1111ZM18.3 12.5C17.805 12.5 17.4 12.075 17.4 11.5556C17.4 11.0361 17.805 10.6111 18.3 10.6111C18.795 10.6111 19.2 11.0361 19.2 11.5556C19.2 12.075 18.795 12.5 18.3 12.5ZM17.4 4H6.6V7.77778H17.4V4Z"
        fill="#1E3A8A"
      />
    </svg>
  );
};

export const PrintDisableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.3 8.72222H5.7C4.206 8.72222 3 9.98778 3 11.5556V17.2222H6.6V21H17.4V17.2222H21V11.5556C21 9.98778 19.794 8.72222 18.3 8.72222ZM15.6 19.1111H8.4V14.3889H15.6V19.1111ZM18.3 12.5C17.805 12.5 17.4 12.075 17.4 11.5556C17.4 11.0361 17.805 10.6111 18.3 10.6111C18.795 10.6111 19.2 11.0361 19.2 11.5556C19.2 12.075 18.795 12.5 18.3 12.5ZM17.4 4H6.6V7.77778H17.4V4Z"
        fill="#D7D7D7"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.2253 20.25H2.75V16.9011C2.75 16.8338 2.77719 16.7693 2.8254 16.7222L13.1918 6.60669L16.7502 10.0791L6.39989 20.1789C6.3532 20.2245 6.29054 20.25 6.2253 20.25Z"
        stroke="#007EA9"
        stroke-width="1.5"
      />
      <path
        d="M12.1406 20.25H21.2498V17.75H14.6616L12.1406 20.25Z"
        fill="#007EA9"
        stroke="#007EA9"
        stroke-width="1.5"
      />
      <path
        d="M17.8241 9.03109L19.5609 7.33607C19.7411 7.16027 19.7411 6.88578 19.5609 6.70999L16.6703 3.88934C16.4799 3.70354 16.1665 3.70357 15.9761 3.88935L14.2656 5.55874L17.8241 9.03109Z"
        fill="#007EA9"
        stroke="#007EA9"
        stroke-width="1.5"
      />
    </svg>
  );
};

export const EditIconDisabled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.2253 20.25H2.75V16.9011C2.75 16.8338 2.77719 16.7693 2.8254 16.7222L13.1918 6.60669L16.7502 10.0791L6.39989 20.1789C6.3532 20.2245 6.29054 20.25 6.2253 20.25Z"
        stroke="lightgray"
        stroke-width="1.5"
      />
      <path
        d="M12.1406 20.25H21.2498V17.75H14.6616L12.1406 20.25Z"
        fill="lightgray"
        stroke="lightgray"
        stroke-width="1.5"
      />
      <path
        d="M17.8241 9.03109L19.5609 7.33607C19.7411 7.16027 19.7411 6.88578 19.5609 6.70999L16.6703 3.88934C16.4799 3.70354 16.1665 3.70357 15.9761 3.88935L14.2656 5.55874L17.8241 9.03109Z"
        fill="lightgray"
        stroke="lightgray"
        stroke-width="1.5"
      />
    </svg>
  );
};

export const EnableANCSchedulerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.42357 4.15295C3.1765 6.58128 4.42967 8.08287 4.16728 9.10058C4.12646 9.25883 4.30702 9.38349 4.45449 9.29927C5.59256 8.64924 6.33941 7.32864 6.35782 6.227L8.09832 2.66507C7.192 1.35748 3.70978 1.34004 3.42357 4.15295Z"
        fill="#D88D82"
      />
      <path
        d="M13.0756 6.12264L13.6709 4.82383L10.1871 3.39636L7.6928 3.86103L7.85405 7.46713L7.18457 10.7521L9.59127 11.1902L10.1001 8.69393C10.1146 8.62295 10.1967 8.58454 10.2652 8.61672L11.409 9.15404C11.6225 9.25438 11.8816 9.19151 12.0177 9.00645L13.2086 7.38627C13.2611 7.3149 13.2576 7.22045 13.2025 7.15084C12.9703 6.85741 12.9182 6.46536 13.0756 6.12264Z"
        fill="#FFCEBF"
      />
      <path
        d="M7.58748 8.08233C6.84108 8.01448 5.28069 6.77952 6.1186 3.64439C7.3224 -0.862256 16.2487 0.872908 14.4017 5.23767C14.263 5.56544 13.1128 6.48037 11.6319 5.4459C11.5178 5.36623 11.3647 5.35528 11.2408 5.42046C10.6973 5.70629 10.1681 5.84254 10.1681 5.84254C10.0077 4.70379 8.81992 4.75694 8.58349 5.55603C8.41506 6.12498 8.75846 6.70768 9.2351 6.88282C8.83019 7.59588 8.20063 8.13866 7.58748 8.08233Z"
        fill="#CC7262"
      />
      <path
        d="M12.0544 14.1577C12.0071 14.1213 11.996 14.0576 12.0292 14.0092C12.5554 13.2414 12.3316 12.2165 11.5269 11.7083L8.33748 9.69388C7.83493 9.37647 7.15917 9.47435 6.78023 9.91938L6.65844 10.0624C6.40169 10.364 6.26172 10.7394 6.26172 11.1265V22.7436C6.26172 22.8852 6.38314 23 6.53297 23H13.0004C13.1502 23 13.2716 22.8852 13.2716 22.7436V22.0381C13.2716 21.8894 13.3565 21.7573 13.4849 21.6712C17.4669 19 15.9029 15 12.0544 14.1577Z"
        fill="#0ED2B3"
      />
      <path
        d="M15.2574 17.0139C15.2881 17.1221 15.2966 17.2379 15.2798 17.3558C15.2108 17.8316 14.7474 18.1651 14.2436 18.1L8.00572 17.2968C7.13336 17.1841 6.54208 16.399 6.71604 15.5831L7.30672 12.8073L7.92075 12.1837L9.20705 12.4991L9.74329 13.2706L9.29798 15.3626C9.25928 15.5445 9.39062 15.7198 9.58534 15.7447L12.3974 16.1069C12.4574 16.1147 12.5187 16.102 12.5701 16.0703L13.804 15.3149C13.8424 15.2912 13.8832 15.2742 13.9258 15.2634C14.0654 15.2281 14.2185 15.2617 14.3285 15.3599C14.4757 15.4909 14.505 15.7016 14.3994 15.864L14.1887 16.1873C14.1464 16.2527 14.1888 16.3378 14.2693 16.348L14.4921 16.377C14.8711 16.4257 15.1643 16.6856 15.2574 17.0139Z"
        fill="#FFCEBF"
      />
      <path
        d="M9.42245 14.7791L9.85347 12.7538C9.98884 12.1178 9.55309 11.4986 8.88029 11.3706C8.20749 11.2427 7.55231 11.6545 7.41695 12.2905L6.98592 14.3158L9.42245 14.7791Z"
        fill="#15A491"
      />
      <path
        d="M17.8494 15.2859C17.7454 15.3684 17.5966 15.3684 17.4926 15.2858C15.875 14.0009 15.0958 13.4934 14.8111 12.6244C14.54 11.7967 15.0009 10.9091 15.8405 10.6418C16.5178 10.4262 17.2344 10.68 17.6286 11.2206C17.6497 11.2496 17.6924 11.2496 17.7135 11.2206C18.1077 10.6801 18.8245 10.4263 19.5015 10.6418C20.3411 10.909 20.802 11.7965 20.5309 12.6242C20.2414 13.5084 19.417 14.0419 17.8494 15.2859Z"
        fill="#FE76A8"
      />
      <path
        d="M18.7041 12.5624H18.1733C18.1329 12.5624 18.1002 12.531 18.1002 12.4922V11.9831C18.1002 11.9444 18.0674 11.913 18.0271 11.913H17.4421C17.4017 11.913 17.369 11.9444 17.369 11.9831V12.4922C17.369 12.531 17.3362 12.5624 17.2958 12.5624H16.765C16.7246 12.5624 16.6919 12.5938 16.6919 12.6325V13.1935C16.6919 13.2323 16.7246 13.2637 16.765 13.2637H17.2958C17.3362 13.2637 17.369 13.2951 17.369 13.3338V13.8429C17.369 13.8816 17.4017 13.913 17.4421 13.913H18.0271C18.0674 13.913 18.1002 13.8816 18.1002 13.8429V13.3338C18.1002 13.2951 18.1329 13.2637 18.1733 13.2637H18.7041C18.7445 13.2637 18.7772 13.2323 18.7772 13.1936V12.6325C18.7773 12.5938 18.7445 12.5624 18.7041 12.5624Z"
        fill="#E5EEF9"
      />
    </svg>
  );
};

export const DisableANCSchedulerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.42357 4.15295C3.1765 6.58128 4.42967 8.08287 4.16728 9.10058C4.12646 9.25883 4.30702 9.38349 4.45449 9.29927C5.59256 8.64924 6.33941 7.32864 6.35782 6.227L8.09832 2.66507C7.192 1.35748 3.70978 1.34004 3.42357 4.15295Z"
        fill="#D7D7D7"
      />
      <path
        d="M13.0756 6.12264L13.6709 4.82383L10.1871 3.39636L7.6928 3.86103L7.85405 7.46713L7.18457 10.7521L9.59127 11.1902L10.1001 8.69393C10.1146 8.62295 10.1967 8.58454 10.2652 8.61672L11.409 9.15404C11.6225 9.25438 11.8816 9.19151 12.0177 9.00645L13.2086 7.38627C13.2611 7.3149 13.2576 7.22045 13.2025 7.15084C12.9703 6.85741 12.9182 6.46536 13.0756 6.12264Z"
        fill="#D7D7D7"
      />
      <path
        d="M7.58748 8.08233C6.84108 8.01448 5.28069 6.77952 6.1186 3.64439C7.3224 -0.862256 16.2487 0.872908 14.4017 5.23767C14.263 5.56544 13.1128 6.48037 11.6319 5.4459C11.5178 5.36623 11.3647 5.35528 11.2408 5.42046C10.6973 5.70629 10.1681 5.84254 10.1681 5.84254C10.0077 4.70379 8.81992 4.75694 8.58349 5.55603C8.41506 6.12498 8.75846 6.70768 9.2351 6.88282C8.83019 7.59588 8.20063 8.13866 7.58748 8.08233Z"
        fill="#B8B5B5"
      />
      <path
        d="M12.0544 14.1577C12.0071 14.1213 11.996 14.0576 12.0292 14.0092C12.5554 13.2414 12.3316 12.2165 11.5269 11.7083L8.33748 9.69388C7.83493 9.37647 7.15917 9.47435 6.78023 9.91938L6.65844 10.0624C6.40169 10.364 6.26172 10.7394 6.26172 11.1265V22.7436C6.26172 22.8852 6.38314 23 6.53297 23H13.0004C13.1502 23 13.2716 22.8852 13.2716 22.7436V22.0381C13.2716 21.8894 13.3565 21.7573 13.4849 21.6712C17.4669 19 15.9029 15 12.0544 14.1577Z"
        fill="#D7D7D7"
      />
      <path
        d="M15.2574 17.0139C15.2881 17.1221 15.2966 17.2379 15.2798 17.3558C15.2108 17.8316 14.7474 18.1651 14.2436 18.1L8.00572 17.2968C7.13336 17.1841 6.54208 16.399 6.71604 15.5831L7.30672 12.8073L7.92075 12.1837L9.20705 12.4991L9.74329 13.2706L9.29798 15.3626C9.25928 15.5445 9.39062 15.7198 9.58534 15.7447L12.3974 16.1069C12.4574 16.1147 12.5187 16.102 12.5701 16.0703L13.804 15.3149C13.8424 15.2912 13.8832 15.2742 13.9258 15.2634C14.0654 15.2281 14.2185 15.2617 14.3285 15.3599C14.4757 15.4909 14.505 15.7016 14.3994 15.864L14.1887 16.1873C14.1464 16.2527 14.1888 16.3378 14.2693 16.348L14.4921 16.377C14.8711 16.4257 15.1643 16.6856 15.2574 17.0139Z"
        fill="white"
      />
      <path
        d="M9.42245 14.7791L9.85347 12.7538C9.98884 12.1178 9.55309 11.4986 8.88029 11.3706C8.20749 11.2427 7.55231 11.6545 7.41695 12.2905L6.98592 14.3158L9.42245 14.7791Z"
        fill="#B8B5B5"
      />
      <path
        d="M17.8494 15.2859C17.7454 15.3684 17.5966 15.3684 17.4926 15.2858C15.875 14.0009 15.0958 13.4934 14.8111 12.6244C14.54 11.7967 15.0009 10.9091 15.8405 10.6418C16.5178 10.4262 17.2344 10.68 17.6286 11.2206C17.6497 11.2496 17.6924 11.2496 17.7135 11.2206C18.1077 10.6801 18.8245 10.4263 19.5015 10.6418C20.3411 10.909 20.802 11.7965 20.5309 12.6242C20.2414 13.5084 19.417 14.0419 17.8494 15.2859Z"
        fill="#B8B5B5"
      />
      <path
        d="M18.7041 12.5624H18.1733C18.1329 12.5624 18.1002 12.531 18.1002 12.4922V11.9831C18.1002 11.9444 18.0674 11.913 18.0271 11.913H17.4421C17.4017 11.913 17.369 11.9444 17.369 11.9831V12.4922C17.369 12.531 17.3362 12.5624 17.2958 12.5624H16.765C16.7246 12.5624 16.6919 12.5938 16.6919 12.6325V13.1935C16.6919 13.2323 16.7246 13.2637 16.765 13.2637H17.2958C17.3362 13.2637 17.369 13.2951 17.369 13.3338V13.8429C17.369 13.8816 17.4017 13.913 17.4421 13.913H18.0271C18.0674 13.913 18.1002 13.8816 18.1002 13.8429V13.3338C18.1002 13.2951 18.1329 13.2637 18.1733 13.2637H18.7041C18.7445 13.2637 18.7772 13.2323 18.7772 13.1936V12.6325C18.7773 12.5938 18.7445 12.5624 18.7041 12.5624Z"
        fill="#E5EEF9"
      />
    </svg>
  );
};

export const EnableUploadDocumentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0047 12.0007V19.0035M12.0047 12.0007L15.276 15.113M12.0047 12.0007L8.73106 15.113M18.8674 16.9727C19.6654 16.5591 20.2956 15.9043 20.659 15.1121C21.0223 14.3198 21.0977 13.435 20.8736 12.5974C20.6495 11.7597 20.1383 11.0169 19.4211 10.4861C18.7037 9.95552 17.821 9.66714 16.9122 9.66648H15.8814C15.6337 8.75558 15.1721 7.90991 14.5314 7.19296C13.8907 6.47614 13.0874 5.90672 12.1819 5.52763C11.2765 5.14846 10.2925 4.96949 9.30379 5.00413C8.31519 5.03877 7.34777 5.28612 6.47403 5.72758C5.60043 6.16909 4.84338 6.79307 4.25981 7.55297C3.67625 8.31274 3.28145 9.18842 3.10488 10.1143C2.92845 11.0401 2.97501 11.9918 3.24092 12.8982C3.50697 13.8044 3.98549 14.6415 4.64058 15.3466"
        stroke="#1E3A8A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const DisableUploadDocumentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0047 12.0007V19.0035M12.0047 12.0007L15.276 15.113M12.0047 12.0007L8.73106 15.113M18.8674 16.9727C19.6654 16.5591 20.2956 15.9043 20.659 15.1121C21.0223 14.3198 21.0977 13.435 20.8736 12.5974C20.6495 11.7597 20.1383 11.0169 19.4211 10.4861C18.7037 9.95552 17.821 9.66714 16.9122 9.66648H15.8814C15.6337 8.75558 15.1721 7.90991 14.5314 7.19296C13.8907 6.47614 13.0874 5.90672 12.1819 5.52763C11.2765 5.14846 10.2925 4.96949 9.30379 5.00413C8.31519 5.03877 7.34777 5.28612 6.47403 5.72758C5.60043 6.16909 4.84338 6.79307 4.25981 7.55297C3.67625 8.31274 3.28145 9.18842 3.10488 10.1143C2.92845 11.0401 2.97501 11.9918 3.24092 12.8982C3.50697 13.8044 3.98549 14.6415 4.64058 15.3466"
        stroke="#D7D7D7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
