import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { PainAssessmentFilterPrint } from "../services/cliinicalChartServices/painAssesmentService/PainAssesmentService";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";

export default function PrintPainAsseUseFilter(props) {
  const headers = [
    "Date",
    "Pain Assessment",
    "Given Date",
    "Given Time",
    "Added By",
  ];

  const [
    painAsseFilterPrintDataResult,
    setPainAssFilterPrintDataResult,
  ] = React.useState();

  const printRef = useRef(null);

  useEffect(() => {
    const postObj = {
      admissionId: props.admissionId,
      fromDate: props.fromDate,
      toDate: props.toDate,
      fromTime: props.fromTime,
      toTime: props.toTime,
    };
    PainAssessmentFilterPrint(postObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.result.PainAssessment.length === 0) {
          warningAlert("Please Select Valid Date And Time..!");
        } else {
          setPainAssFilterPrintDataResult(res.result);
          
        }
      });
  }, [props]);

  useEffect(() => {
    painAsseFilterPrintDataResult && printRef && GeneratePrint(printRef);
  }, [painAsseFilterPrintDataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <div className=" w-full">
          <div className="  flex justify-end">
            <InamdarHospiLogo />
          </div>

          <div className=" grid grid-cols-[18%_1%_31%_18%_1%_31%]">
            <div className=" font-semibold text-[11px] ite">MR No.</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.MRNo}
            </div>
            <div className=" font-semibold text-[11px]">Patient Name</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.PatientName}
            </div>
            <div className=" font-semibold text-[11px]">Consultant</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.DoctorName}
            </div>
            <div className=" font-semibold text-[11px]">Bed No</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.BedNo}
            </div>
            <div className=" font-semibold text-[11px]">Admission Date</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.AdmissionDate}
            </div>
            <div className=" font-semibold text-[11px]">From Date</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.FromDate}
            </div>
            <div className=" font-semibold text-[11px]">To Date</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">
              {painAsseFilterPrintDataResult?.ToDate}
            </div>
          </div>

          <div className=" text-[14px] font-semibold border-t-[1px] border-b-[1px] border-black text-center">
            Pain Assessment
          </div>

          <div className=" mt-5 bg-gray-300">
            <div className=" border-[1px] border-black grid grid-cols-5">
              <div className=" border-r-[1px] border-black text-center">
                Date
              </div>
              <div className=" border-r-[1px] border-black text-center">
                Pain Assessment
              </div>
              <div className=" border-r-[1px] border-black text-center">
                Given Date
              </div>
              <div className=" border-r-[1px] border-black text-center">
                Given Time
              </div>
              <div className=" text-center">Added By</div>
            </div>
          </div>
          <div>
            {painAsseFilterPrintDataResult?.PainAssessment.map((dataTable) => {
              return (
                <div className=" border-b-[1px] border-l-[1px]  border-black grid grid-cols-5">
                  <div className=" border-r-[1px] border-black text-center">
                    {dataTable["Date"]}
                  </div>
                  <div className=" border-r-[1px] border-black text-center">
                    {dataTable["Pain Assessment"]}
                  </div>
                  <div className=" border-r-[1px] border-black text-center">
                    {dataTable["Given Date"]}
                  </div>
                  <div className=" border-r-[1px] border-black text-center">
                    {dataTable["Given Time"]}
                  </div>
                  <div className=" border-r-[1px] border-black text-center">
                    {dataTable["Added By"]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </table>
    </div>
  );
}
