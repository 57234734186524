import React from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../../common/components/FormFields/InputField";
import RadioField from "../../../../../common/components/FormFields/RadioField";

function PatientInformation() {
  const { control, register } = useFormContext(); // retrieve all hook methods

  // Context
  const bandWithArray = [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ];

  return (
    <div className=" px-3">
      <form
        className="grid grid-cols-1 w-full gap-2 "
        // onSubmit={handleSubmit(onSubmitDataHandler)}
      >
        <div className="grid grid-cols-2 gap-7 items-center">
          <div className="flex gap-2 items-center">
            <InputField
              name="height"
              variant="outlined"
              label="Height in cm"
              control={control}
              type="number"
            />
            <InputField
              name="weightInKgs"
              variant="outlined"
              label="Weight In Kgs"
              control={control}
              type="number"
            />
          </div>

          <div className="flex gap-6 items-center">
            <h6 className="font-bold md:text-sm lg:text-base">
              ID Band on Wrist With Correct Information
            </h6>
            <RadioField
              label=""
              name="isBandWithCorrectInformation"
              control={control}
              dataArray={bandWithArray}
            />
          </div>
        </div>

        <div className="grid  gap-2">
          <div className="grid grid-cols-2 gap-2">
            <InputField
              name="knownAllergies"
              variant="outlined"
              label="Known Allergies"
              control={control}
            />
            <InputField
              name="disabilities"
              variant="outlined"
              label="Disabilities If Any"
              control={control}
            />
          </div>

          {/* grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 */}

          <div className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Denutures"
                {...register("externalEquipments")}
              />

              <p>Denutures</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Contact Lenses"
                {...register("externalEquipments")}
              />
              <p>Contact Lenses</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Artificial Limbs"
                {...register("externalEquipments")}
              />
              <p>Artificial Limbs</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Implants"
                {...register("externalEquipments")}
              />
              <p>Implants</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Ryle's Tube"
                {...register("externalEquipments")}
              />
              <p>Ryle's Tube</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Gastro Jejunomstomy"
                {...register("externalEquipments")}
              />
              <p>Gastro Jejunomstomy</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Central Line"
                {...register("externalEquipments")}
              />
              <p>Central Line</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="ArterialLine"
                {...register("externalEquipments")}
              />
              <p>ArterialLine</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Tracheotomy"
                {...register("externalEquipments")}
              />
              <p>Tracheotomy</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Intubated"
                {...register("externalEquipments")}
              />
              <p>Intubated</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Fistula"
                {...register("externalEquipments")}
              />
              <p>Fistula</p>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4"
                value="Foleys Catheter"
                {...register("externalEquipments")}
              />
              <p>Foleys Catheter</p>
            </div>
          </div>
          <InputField
            name="staffName"
            variant="outlined"
            label="Staff Name"
            control={control}
            // placeholder="Receiving Staff Name"
          />
        </div>
      </form>
    </div>
  );
}

export default PatientInformation;
