import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const fetchPatientInfoAutoComplete = (patientInfo) => {
  return apiClient.get(`/patientInfo/admissionAutoComplete/${patientInfo}`, {
    headers: authHeader(),
  });
};

export const fetchVentilatorBundleList = (admissionId) => {
  return apiClient.get(
    `/ventilatorBundle/getVentilatorBundleList/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchVentilatorBundleDtls = (vapId) => {
  return apiClient.get(
    `/ventilatorBundle/getVentilatorBundleDetails/${vapId}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveVentilatorBundle = (finalObj) => {
  return apiClient.post(`/ventilatorBundle/saveVentilatorBundle`, finalObj, {
    headers: authHeader(),
  });
};
