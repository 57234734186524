import * as React from "react";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import IPDConcessionManagerListing from "./IPDConcessionManagerListing";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { useForm } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  concessionManagerAutoSearchPatient,
  concessionManagerPatientInfoById,
  concessionManagerListing,
  fetchConcessionCategory,
  fetchConcessionAuthority,
  PostOpdConsationInfo,
} from "../../../../billing/services/ConcessionManagerServices";
import PreviousConcessionModal from "./PreviousConcessionModal";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const patienttype = [
  {
    id: "Concession",
    value: "Concession",
    label: "Concession",
  },
];
const percentageAmountConcession = [
  {
    id: "Percentage",
    value: "Percentage",
    label: "Percentage",
  },
  {
    id: "Amount",
    value: "Amount",
    label: "Amount",
  },
];

const Actions = [
  {
    id: 0,
    action: "previouConcession",
    isAction: false,
  },

  {
    id: 1,
    action: "save",
    isAction: false,
  },
];

let showSpinner1 = false;

export default function OPDConcessionManager() {
  const schema = yup.object().shape({
    concession: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, "Amount Should be in numbers only")
      .notRequired(),
    totalBillAmount: yup.string(),
    totalAmountTax: yup.string(),
    totalAmountWithConcession: yup.string(),
    totalConcession: yup.string(),
    totalAmountWithoutConcession: yup.string(),
    netPayableAmount: yup.string(),

    concessioncategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),

    Concessionauthorisedby: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .required("Required"),

    remarks: yup.string(),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      totalBillAmount: "",
      balanceBillAmount: "",
      percentageAmountConcession: "Percentage",
      concession: 0,
      concessioncategory: "",
      Concessionauthorisedby: "",
      remarks: "",
      balanceAmount: 0,
      totalAmountWithConcession: 0,
      totalConcession: "",
      totalAmountWithoutConcession: "",
      netPayableAmount: "",
    },
  });

  const {
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    watch,
    register,
    reset,
    handleSubmit,
    setValue,
  } = methods;

  //state variable
  const [options, setOptions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientById, setSelectedPatientById] = useState(null);
  const [concessionListingData, setConcessionListingData] = useState([]);
  const [tableRowData, setTableRowData] = useState(0);
  const [percentage, setPercentage] = useState();
  const [amount, setAmount] = useState();
  const [concessionCategory, setConcessionCategory] = useState();
  const [concessionAuthority, setConcessionAuthority] = useState();
  const [concessionTableData, setConcessionTableData] = useState([]);
  const [userActions, setUserActions] = useState([]);
  //errors
  const [customError, setCustomError] = useState();
  //FormFields Data
  const [concessionFormData, setConcessionFormData] = useState();
  // confirmation modal
  const [openModal, setOpenModal] = useState(false);
  //Backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [privilege, setPrivilege] = React.useState(null);
  //indent modal
  const [openPreviousConcModal, setOpenPreviousConcModal] = React.useState(
    false
  );
  const handleOpenPreviousConcModal = () => setOpenPreviousConcModal(true);
  const handleClosePreviousConcModal = () => setOpenPreviousConcModal(false);
  //

  //other variables
  var concessionCheck = watch("concessionCheck");
  let watchTotalAmount = watch("totalBillAmount");
  var TypeOfConcession = watch("percentageAmountConcession");
  let location = useLocation();
  
  // 

  // 
  let concessionAmt = watch("concession");

  

  useEffect(() => {
    
    
  }, [selectedPatientById, concessionTableData]);
  ///get User Action
  useEffect(() => {
    
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);
  //autoSearch ...patient list
  const handleChange = (e) => {
    
    let ipdOpd = 1;
    if (e !== undefined && e !== "") {
      concessionManagerAutoSearchPatient(e, ipdOpd)
        .then((response) => response.data)
        .then((res) => {
          
          setOptions(res.result);
        });
    }
  };
  //get  selected patient details
  function getPatientInformationById(patientId) {
    
    let ipdOpd = 1;
    concessionManagerPatientInfoById(patientId, ipdOpd)
      .then((response) => response.data)
      .then((res) => {
        
        setSelectedPatientById(res.result);
        ///passed visit id
      });
  }
  //get concession services listing data(Table data)
  function fecthConcessionManagerListing(VisitId) {
    
    showSpinner1 = true;
    concessionManagerListing(VisitId)
      .then((response) => response.data)
      .then((res) => {
        setConcessionTableData(res.result);
        showSpinner1 = false;
        // setCalNetAmount(res);
      });
  }
  //get table row data
  function getTableRowData(row, index) {
    
    
    setTableRowData(row);
  }

  useEffect(() => {
    // concession category dropdown
    fetchConcessionCategory()
      .then((response) => response.data)
      .then((res) => {
        setConcessionCategory(res.result);
      });

    // concession category dropdown
    fetchConcessionAuthority()
      .then((response) => response.data)
      .then((res) => {
        setConcessionAuthority(res.result);
      });
  }, []);

  // function onSubmitConcessionManagerDataHandler(data) {
  //   
  // }

  ////////calculations///////

  useEffect(() => {
    if (selectedPatientById !== null) {
      //1.
      setValue("totalBillAmount", selectedPatientById.TotalAmount);
      //2.
      setValue("balanceBillAmount", selectedPatientById.BalanceAmount);
    }
  }, [selectedPatientById]);

  const handleConcession = (e) => {
    /// case-1:acording to conc percentage
    if (TypeOfConcession === "Percentage") {
      
      
     
      let enteredValue = e.target.value;

      ////// to reflect calculations to tble line
      for (let i = 0; i < concessionTableData.length; i++) {
        /// case-1:acording to conc percentage

        

        let enteredValue = e.target.value;

        let totalAmount = concessionTableData[i].TotalAmount;
        //
        concessionTableData[i].concPercentage = enteredValue;
        //
        const concAmt =
          (enteredValue * totalAmount) / 100 +
          concessionTableData[i].Concession;
        concessionTableData[i].concAmount = concAmt;

        concessionTableData[i].netAmount = totalAmount - concAmt;
      }

      //////

      let concAmount = (enteredValue * selectedPatientById.BalanceAmount) / 100;

      //2.
      setValue("totalConcession", concAmount.toFixed(2));
      //3.
      setValue(
        "netPayableAmount",
        (selectedPatientById.BalanceAmount - concAmount).toFixed(2)
      );
      //4.
      setValue(
        "totalAmountWithConcession",
        (selectedPatientById.BalanceAmount - concAmount).toFixed(2)
      );
      //5.
      setValue(
        "totalAmountWithoutConcession",
        selectedPatientById.BalanceAmount.toFixed(2)
      );

      ///errors acoording percentage

      if (enteredValue > 100) {
        setError("concession", {
          type: "custom",
          message: "concession should not be more than 100%",
        });
        setCustomError(true);
      } else if (enteredValue <= 100) {
        clearErrors("concession");
        setCustomError(false);
      }
    }
    /// case-2:acording to conc Amount
    else if (TypeOfConcession === "Amount") {
      let enteredValue = e.target.value;
      let netPayableAmt =
        selectedPatientById.BalanceAmount - Number(enteredValue);
      
      

      ////// to reflect calculations to tble line
      for (let i = 0; i < concessionTableData.length; i++) {
        /// case-1:acording to conc percentage

        let enteredValue = e.target.value;
        let concInPerc =
          (enteredValue * 100) / selectedPatientById.BalanceAmount;
        let totalAmount = concessionTableData[i].TotalAmount;
        //
        const concAmt =
          (concInPerc * totalAmount) / 100 + concessionTableData[i].Concession;

        concessionTableData[i].concAmount = concAmt.toFixed(2);
        //
        concessionTableData[i].concPercentage = concInPerc.toFixed(2); //2 decimals only

        concessionTableData[i].netAmount = (totalAmount - concAmt).toFixed(2);
      }

      setValue(
        "netPayableAmount",
        selectedPatientById.BalanceAmount - Number(enteredValue)
      );
      setValue(
        "totalAmountWithConcession",
        selectedPatientById.BalanceAmount - Number(enteredValue)
      );
      setValue("totalConcession", Number(enteredValue));

      ///errors acoording amount

      if (enteredValue > selectedPatientById.BalanceAmount) {
        setError("concession", {
          type: "custom",
          message: "concession should not be more Total Amount",
        });
        setCustomError(true);
      } else if (enteredValue <= selectedPatientById.BalanceAmount) {
        clearErrors("concession");
        setCustomError(false);
      }
    }
  };

  //final Obj

  const finalObj = {
    addedBy: 0,
    authorisedBy: 0,
    billId: 0,
    // cancellationDate: "",
    // cancellationTime: "",
    concessionCategoryId: 0,
    concessionChargesDto: [],
    concessionType: true,
    isCancelled: true,
    netPayableAfterConcession: 0,
    opdIpd: 1,
    remark: "",
    totalAmount: 0,
    totalConcession: 0,
    visitId: 0,
    newConcession: 0,
  };

  //onSubmit

  const onSubmit = (data) => {
    
    
    
  

    setConcessionFormData(data);
    {
      !customError
        ? setOpenModal(true)
        : errorAlert("Please Provide Valid Concession");
    }
  };

  //submit on modal confirmation
  const handleFormSubmit = (data) => {
    

    setOpenModal(false);
    setOpenBackdrop(true);

    let token = JSON.parse(localStorage.getItem("userInfo"));

    finalObj.addedBy = Number(token.userId);
    finalObj.authorisedBy = data.Concessionauthorisedby.id;
    finalObj.authorisedBy = data.Concessionauthorisedby.id;
    finalObj.concessionCategoryId = data.concessioncategory.id;
    finalObj.balanceAmount = selectedPatientById.BalanceAmount;
    //set required conc tabl values
    let concTbl = [];
    for (let i = 0; i < concessionTableData.length; i++) {
      let concObj = {
        chargeId: concessionTableData[i].ChargeId,
        concAmount: concessionTableData[i].concAmount,
        concPercentage: concessionTableData[i].concPercentage,
        rate: concessionTableData[i].Rate,
        tariffServiceId: concessionTableData[i].TariffServiceId,
        totalAmount: concessionTableData[i].TotalAmount,
      };
      concTbl.push(concObj);
    }
    finalObj.concessionChargesDto = concTbl;
    //
    finalObj.newConcession =
      TypeOfConcession === "Amount"
        ? concessionAmt
        : (Number(concessionAmt) * Number(selectedPatientById?.BalanceAmount)) /
          100;

    //
    finalObj.concessionType = concessionCheck === "Concession" ? true : false;
    finalObj.netPayableAfterConcession = data.netPayableAmount;
    finalObj.opdIpd = 1;
    finalObj.remark = data.remarks;
    finalObj.totalAmount = data.totalBillAmount;
    finalObj.totalConcession = data.totalConcession;
    finalObj.visitId = selectedPatientById.AdmissionId;
    finalObj.billId = selectedPatientById.BillId;
    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;
    
    PostOpdConsationInfo(finalObj)
      .then((response) => response.data)
      .then((res) => {
        
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          methods.reset();
          setOpenBackdrop(false);
          setConcessionTableData([]);
          setSelectedPatientById("");
          setValue("concessionManagerSearch", null);
        }
      })
      .catch(
        (res) => {
          
          errorAlert(res.message);
          setOpenModal(false);
          setOpenBackdrop(false);
        },

        
      );
  };

  return (
    <>
      <div>
        <div className="w-full grid mt-12 px-6 md:rounded-md ">
          <div className="text-center text-black font-bold text-xl ">
            Concession Manager
          </div>
          <div
            //className="lg:flex md:grid item-center w-full gap-2 "
            className="grid grid-cols-5 gap-3"
          >
            <div className="lg:col-span-2 md:col-span-5 z-50 pt-2">
              <SearchDropdown
                control={control}
                type="button"
                className="z-80"
                name="concessionManagerSearch"
                placeholder="Search By Patient Name / MRNo / Mob.No"
                dataArray={options}
                isSearchable={true}
                isClearable={true}
                searchIcon={true}
                handleInputChange={handleChange}
                inputRef={{
                  ...register("concessionManagerSearch", {
                    onChange: (e) => {
                      
                      if (e.target.value !== null) {
                        
                     
                        setSelectedPatient(e.target.value);
                        getPatientInformationById(e.target.value.id);
                        fecthConcessionManagerListing(e.target.value.id);
                      } else {
                        setSelectedPatient("");
                        setSelectedPatientById("");
                        setConcessionTableData([]);
                        setValue("concession", 0);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className=" lg:col-span-2 md:col-span-3 flex gap-2 md:py-2 md:items-center">
              <RadioField
                name="concessionCheck"
                control={control}
                dataArray={patienttype}
              />

              <div>
                <InputField
                  name="Concessionno"
                  control={control}
                  disabled={true}
                  label="Concession No"
                  placehoder="Concession NO"
                />
              </div>

              {/* <div className="w-auto justify-end">
                <PerviousConcession />
              </div> */}
            </div>
            <div className="md:col-span-2 md:whitespace-nowrap md:text-sm lg:col-span-1 pt-2 flex justify-end">
              {Actions.map((obj) => (
                <>
                  {!obj.isAction ? (
                    <>
                      {obj.action === "previouConcession" ? (
                        <div>
                          {selectedPatient !== null ? (
                            <CommonButton
                              label="Previous Concession"
                              className="bg-blue-900 text-white"
                              onClick={() => {
                                if (selectedPatientById !== null) {
                                  handleOpenPreviousConcModal();
                                } else {
                                  warningAlert("Please Select Patient!");
                                }
                              }}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>

          {selectedPatientById !== "" && selectedPatientById !== undefined ? (
            <>
              {/* patient data */}
              <div className="lg:grid lg:grid-cols-1 md:grid md:grid-cols-1 gap-2">
                <fieldset className=" mx-auto  w-full   ">
                  <div className="border bg-gray-100 rounded border-gray-300 text-left w-full reounded mb-2 py-2 px-4 mt-1 md:grid md:grid-cols-[18%_1.5%_37%_12%_1.5%_25%] lg:grid lg:grid-cols-[10%_1%_20%_6%_1%_16%_6%_1%_16%_6%_1%_16%] text-sm ">
                    <div className="font-semibold  py-1">Patient Name</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.patientName}
                    </div>
                    <div className="font-semibold  py-1"> IPD No.</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.IpdNo}
                    </div>
                    <div className="font-semibold py-1 ">MR No.</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.mrNo}
                    </div>
                    <div className="font-semibold py-1">Gender</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.gender}
                    </div>
                    <div className="font-semibold" py-1>
                      Mobile No.
                    </div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.MobileNo}
                    </div>
                    <div className="font-semibold py-1">Unit</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.unit}
                    </div>
                    <div className="font-semibold py-1">Company</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.Company}
                    </div>
                    <div className="font-semibold py-1">Tariff</div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.tariff}
                    </div>
                    <div className="font-semibold whitespace-nowrap py-1">
                      Patient Source
                    </div>
                    <div className="py-1">:</div>
                    <div className="text-gray-700 font-normal py-1">
                      {selectedPatientById && selectedPatientById.patientSource}
                    </div>
                  </div>
                </fieldset>
              </div>
              {/* concession table */}
              {showSpinner1 ? (
                <div className="flex justify-center mt-14">
                  <LoadingSpinner />
                </div>
              ) : concessionTableData.length > 0 ? (
                <>
                  <div className="py-4">
                    <IPDConcessionManagerListing
                      data={concessionTableData}
                      setConcessionTableData={setConcessionTableData}
                      getTableRowData={getTableRowData}
                      percentage={percentage}
                      amount={amount}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center mt-4 py-4">
                    <p>No Record Found</p>
                  </div>
                </>
              )}
              {/* Calculaion And input Fields */}
              <div className="">
                <fieldset
                  className="border border-gray-300 col-span-3 w-full lg:py-2
               text-left lg:px-2 md:p-2 rounded bg-white"
                >
                  <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1  md:px-2">
                    Concession Information
                  </legend>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="lg:grid lg:grid-cols-2 gap-2">
                      <div className="lg:grid lg:grid-cols-2 md:grid mdf:grid-cols-2 gap-3 ">
                        <div className="pt-2 px-2">
                          <RadioField
                            name="percentageAmountConcession"
                            control={control}
                            dataArray={percentageAmountConcession}
                            inputRef={{
                              ...register("percentageAmountConcession", {
                                onChange: (e) => {
                                  
                                  setValue("concession", 0);
                                  setValue("netPayableAmount", 0);
                                  setValue("totalConcession", 0);
                                  setValue("totalAmountWithConcession", 0);
                                },
                              }),
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            size="small"
                            type="number"
                            color={customError ? "error" : "primary"}
                            sx={{
                              input: {
                                color: customError ? "#d32f2f" : "#212529",
                              },
                              label: {
                                color: customError ? "#d32f2f" : "#212529",
                              },
                            }}
                            inputProps={{
                              maxLength:
                                TypeOfConcession === "Percentage" ? 3 : 10,
                            }}
                            name="concession"
                            variant="outlined"
                            label="Concession"
                            {...register("concession")}
                            onChange={(e) => {
                              handleConcession(e);
                            }}
                            error={errors.concession}
                            control={control}

                            // inputRef={{
                            //   ...register("concession", {
                            //     onChange: (e) => {
                            //       getConcession(e);
                            //     },
                            //   }),
                            // }}
                          />
                        </div>
                        <div>
                          <DropdownField
                            control={control}
                            name="concessioncategory"
                            label="Concession Category *"
                            placeholder="Concession Category *"
                            dataArray={concessionCategory}
                            error={errors.concessioncategory}
                            isSearchable={false}
                          />
                        </div>
                        <div>
                          <DropdownField
                            control={control}
                            name="Concessionauthorisedby"
                            label="Concession Authorised By *"
                            placeholder="Concession Authorised By *"
                            dataArray={concessionAuthority}
                            error={errors.Concessionauthorisedby}
                            isSearchable={false}
                          />
                        </div>
                        <div className="col-span-2 md:pb-2">
                          <InputField
                            color={customError ? "error" : "primary"}
                            name="remarks"
                            control={control}
                            label="Remark"
                            placehoder="Remarks"
                          />
                        </div>
                      </div>
                      <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2   gap-1">
                        <div className="">
                          <InputField
                            name="totalBillAmount"
                            label="Total Amount ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="balanceBillAmount"
                            label="Balance Amount ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="totalAmountWithConcession"
                            label="Total Amount With Concession ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="totalConcession"
                            label="Total Concession ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="totalAmountWithoutConcession"
                            label="Total Amount Without Concession ₹"
                            control={control}
                            disabled={true}
                          />
                        </div>
                        <div className="">
                          <InputField
                            name="netPayableAmount"
                            label="Net Payable Amount ₹"
                            control={control}
                            errors={errors.netPayableAmount}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end gap-2 px-4 py-2">
                      {userActions &&
                        userActions.map((obj) => (
                          <>
                            {!obj.isAction && obj.action === "Create" ? (
                              <>
                                <div>
                                  <CommonButton
                                    label="Reset"
                                    className="border border-customRed text-customRed"
                                    onClick={() => {
                                      methods.reset();
                                      setConcessionTableData([]);
                                      setSelectedPatientById("");
                                      setValue("concessionManagerSearch", null);
                                    }}
                                  />
                                </div>
                                <div>
                                  <CommonButton
                                    type="submit"
                                    label="Save"
                                    className="bg-customGreen text-white"
                                    onClick={() => {
                                      setPrivilege(obj.action);
                                    }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}
                    </div>
                  </form>
                </fieldset>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {openPreviousConcModal ? (
        <PreviousConcessionModal
          open={openPreviousConcModal}
          setOpen={setOpenPreviousConcModal}
          handleOpen={handleOpenPreviousConcModal}
          handleClose={handleClosePreviousConcModal}
          selectedPatientById={selectedPatientById}

          //
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openModal}
        confirmationHandleClose={() => setOpenModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(concessionFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}
