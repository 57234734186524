import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme } from "@mui/material/styles";
import * as React from "react";
import Average from "../../../../assets/scaleEmoji/Average.png";
import DissatisfiedIcon from "../../../../assets/scaleEmoji/Dissatisfied.png";
import HighlySatisfied from "../../../../assets/scaleEmoji/HighlySatisfied.png";
import Poor from "../../../../assets/scaleEmoji/Poor.png";
import Satisfied from "../../../../assets/scaleEmoji/Satisfied.png";
import StarImage from "../../../../assets/scaleEmoji/Star.png";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { ModalStyle, ModalStyleFeedback } from "../../../../common/components/ModalStyle";

const rainbowGradient =
  "linear-gradient(to right, red, orange, yellow, yellowgreen, skyblue)";

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          "& .MuiSlider-thumb": {
            backgroundImage: "white",
            color: "white",
          },
          "& .MuiSlider-rail": {
            backgroundImage: rainbowGradient,
            height: "10px",
          },
          "& .MuiSlider-track": {
            backgroundImage: rainbowGradient,
            height: "10px",
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: "lightgray",
            color: "black",
            borderRadius: "8px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // Add your custom styles for the tooltip here
          backgroundColor: "green",
          color: "white",
          borderRadius: "8px",
        },
      },
    },
  },
});
const bgColorsForButton = [
  "#C4FFC3",
  "#F0FFC3",
  "#FEFFE0",
  "#FFE1CF",
  "#FFCBCB",
];
export default function PreviewModal(props) {
  const [sliderValue, setSliderValue] = React.useState(0);
  const textColor = ["#FF2A2A", "#E99566", "#DDE032", "#AADF12", "#239F21"];
  React.useEffect(() => {
    if (props.reviewCreateData.length > 0) {
      for (let obj of props.reviewCreateData) {
        setSliderValue(obj.sliderValue);
      }
    }
  }, [props.reviewCreateData]);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyleFeedback} className="rounded w-[60%] ">
          <CancelPresentationIconButton
            className="bg-[#FFDCDC] rounded-full"
            onClick={() => {
              props.handleClose();
            }}
          />
          <h1 className="font-Poppins font-semibold text-lg grid items-center justify-center text-[#073763] pb-2">
            Review Scale Preview
          </h1>
          <div className="grid grid-cols-5 gap-2">
            {props.reviewCreateData.length > 0 &&
              props.reviewCreateData.map((value, index) => {
                const textColors = textColor[index % textColor.length];
                const bgColor =
                  bgColorsForButton[index % bgColorsForButton.length];
                return (
                  <div>
                    {props.selectedAnswerType === "Star" ? (
                      <div
                        className="border shadow rounded text-center h-20 flex items-center justify-center"
                        style={{ backgroundColor: bgColor }}
                      >
                        <img
                          src={StarImage}
                          alt={`Review ${index + 1}`}
                          style={{ height: "40px" }}
                        />
                      </div>
                    ) : null}

                    {props.selectedAnswerType === "Emoji" ? (
                      <div
                        className="border shadow rounded text-center  h-20 flex items-center justify-center"
                        style={{ backgroundColor: bgColor }}
                      >
                        {value.Rating === "1" || value.Rating === 1 ? (
                          <img
                            src={DissatisfiedIcon}
                            alt={`Review ${index + 1}`}
                            style={{ height: "40px" }}
                          />
                        ) : null}
                        {value.Rating === "2" || value.Rating === 2 ? (
                          <img
                            src={Poor}
                            alt={`Review ${index + 1}`}
                            style={{ height: "40px" }}
                          />
                        ) : null}
                        {value.Rating === "3" || value.Rating === 3 ? (
                          <img
                            src={Average}
                            alt={`Review ${index + 1}`}
                            style={{ height: "40px" }}
                          />
                        ) : null}
                        {value.Rating === "4" || value.Rating === 4 ? (
                          <img
                            src={Satisfied}
                            alt={`Review ${index + 1}`}
                            style={{ height: "40px" }}
                          />
                        ) : null}
                        {value.Rating === "5" || value.Rating === 5 ? (
                          <img
                            src={HighlySatisfied}
                            alt={`Review ${index + 1}`}
                            style={{ height: "40px" }}
                          />
                        ) : null}
                      </div>
                    ) : null}

                    {props.selectedAnswerType === "Chips" ? (
                      <div
                        className="border shadow rounded text-center h-20 flex items-center justify-center"
                        style={{ backgroundColor: bgColor }}
                      >
                        {value.Rating}
                      </div>
                    ) : null}
                    <h1 className="text-center" style={{ color: textColors }}>
                      {value["Review Label"]}
                    </h1>
                  </div>
                );
              })}
          </div>
          {/* <ThemeProvider theme={theme}>
            <Slider
              aria-label="Temperature"
              value={sliderValue}
              //   onChange={handleChange}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={5}
            />
          </ThemeProvider> */}
        </Box>
      </Modal>
    </div>
  );
}