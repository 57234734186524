import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const saveCodeBlueForm = (finalObj) => {
  return apiClient.post(
    `/codeBlueForm/saveCodeBlueForm`,
    finalObj,

    {
      headers: authHeader(),
    }
  );
};

export const fetchCBFormListing = (listingObj) => {
  return apiClient.post(
    `/codeBlueForm/codeBlueFormList`,
    listingObj,

    {
      headers: authHeader(),
    }
  );
};

//patch
export const fetchCodeBlueFormDetails = (codeBlueFormId) => {
  return apiClient.get(`/codeBlueForm/codeBlueFormDetails/${codeBlueFormId}`, {
     headers: authHeader(),
  });
};

//print
export const fetchPrintCodeBlueForm = (codeBlueFormId) => {
  return apiClient.get(`/taskManager/codeBlueForm/${codeBlueFormId}`, {
    headers: authHeader(),
  });
};

