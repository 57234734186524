import React from "react";

import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchPendingImplantPatientListing,
  fetchToClearPendingImplants,
} from "../../services/pendingImplantListServices/PendingImplantListServices";

const PendingImplantPatientList = () => {
  let location = useLocation();

  //
  const [userActions, setUserActions] = useState([]);
  //
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [dataResult, setDataResult] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);
  ///
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);

  const [privilege, setPrivilege] = React.useState("");

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
  });
  const { control } = methods;

  useEffect(() => {
    getImplantListing();
  }, [location?.state?.menuId]);

  const getImplantListing = (forPagination) => {
    fetchPendingImplantPatientListing(
      location?.state?.menuId,
      page,
      rowsPerPage
    )
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          let temp = [...res.result];
          for (let item of temp) {
            item.checked = false;
          }
          setDataResult(temp);
          setPage(0);
        }
        setCount(res.count);
        setUserActions(res.actions);
      });
  };

  //
  const handleCheckChange = (e, row, index) => {
    var arr = [...selectedIds];
    if (e.target.checked) {
      arr.push(row.OTHeaderID);
      setSelectedIds(arr);
    } else if (!e.target.checked) {
      let flt = arr.filter((val) => val !== row.OTHeaderID);
      setSelectedIds(flt);
    }

    let temp = [...dataResult];
    temp[index].checked = e.target.checked;
    setDataResult([...temp]);
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1">
        <Checkbox
          control={control}
          checked={row.checked}
          name={`selectedOptions${index}`}
          onChange={(e) => {
            handleCheckChange(e, row, index);
          }}
        />
      </div>
    );
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    let obj = {
      otHeaderId: selectedIds,
      privilege: privilege,
      menuId: location?.state?.menuId,
    };

    fetchToClearPendingImplants(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        getImplantListing();
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  };

  return (
    <>
      <div className="mt-12 ">
        <div className="text-center text-black font-bold text-xl ">
          Pending Implant Patient List
        </div>
        <div>
          {dataResult?.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              populateTable={getImplantListing}
              renderActions={renderActions}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              tableClass="2xl:max-h-[650px]"
              highlightRow={false}
              removeHeaders={["checked", "OTHeaderID"]}
            />
          ) : (
            <div className="text-center my-6 ">No Data Found</div>
          )}
        </div>
        {userActions.map((obj) => (
          <>
            {!obj.isAction && obj.action === "Create" ? (
              <>
                <div className="flex justify-end gap-2">
                  <CommonButton
                    className="border border-customRed text-customRed"
                    label="Reset"
                    onClick={() => {
                      setPrivilege("");
                      getImplantListing();
                    }}
                  />
                  <CommonButton
                    className="bg-customGreen text-white"
                    label="Save"
                    onClick={() => {
                      if (selectedIds?.length > 0) {
                        setPrivilege(obj.action);
                        onSubmit();
                      } else if (selectedIds.length === 0) {
                        warningAlert("Please select at least one patient...!");
                      }
                    }}
                  />
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default PendingImplantPatientList;
