import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Callhistory from "../assets/icons/Callhistory";
import { NextIcon } from "../assets/icons/NextIcon";
import { PreviousIcon } from "../assets/icons/PreviousIcon";
import CancelPresentationIconButton from "../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../common/components/Buttons/CommonButton";
import ConfirmationModal from "../common/components/ConfirmationModal";
import CheckBoxField from "../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../common/components/FormFields/DatePickerFieldNew";
import InputField from "../common/components/FormFields/InputField";
import InputArea from "../common/components/FormFields/InputArea";
import RadioField from "../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
} from "../common/components/Toasts/CustomToasts";
import {
  addCallDetails,
  disconnectCall,
  getPreviousCallDetails,
} from "./services/callingsearvices";
import PostDischargeBookAppoinmentModal from "./PostDischargeBookAppoinmentModal";

const RadioButton = [
  {
    id: "Patient Notes & Appointment",
    label: "Patient Notes & Appointment",
    value: "Patient Notes & Appointment",
  },
  {
    id: "Patient Died",
    label: "Patient Died",
    value: "Patient Died",
  },
  {
    id: "Leakage",
    label: "Leakage",
    value: "Leakage",
  },
  {
    id: "Re-Admitted",
    label: "Re-Admitted",
    value: "Re-Admitted",
  },
];

const appointmentradibutton = [
  {
    id: "At Private Clinic",
    label: "At Private Clinic",
    value: "At Private Clinic",
  },
  {
    id: "At IMH",
    label: "At IMH",
    value: "At IMH",
  },
];
const atimhradibutton = [
  {
    id: "At IMH",
    label: "At IMH",
    value: "At IMH",
  },
];
const expectedandUnexpectedredio = [
  {
    id: "Expected",
    label: "Expected",
    value: "Expected",
  },
  {
    id: "Unexpected",
    label: "Unexpected",
    value: "Unexpected",
  },
];
const AdmittedAtOtherHospitalRadi = [
  {
    id: "Admitted At Other Hospital",
    label: "Admitted At Other Hospital",
    value: "Admitted At Other Hospital",
  },
  {
    id: "Advise For Re-Admission",
    label: "Advise For Re-Admission",
    value: "Advise For Re-Admission",
  },
];

const PlannedUnplanned = [
  {
    id: "Planned",
    label: "Planned",
    value: "Planned",
  },
  {
    id: "Unplanned",
    label: "Unplanned",
    value: "Unplanned",
  },
];

const PatientAdmitted = [
  {
    id: "Yes",
    label: "Yes",
    value: "Yes",
  },
  {
    id: "No",
    label: "No",
    value: "No",
  },
];

export default function Filldetailsmodal(props) {
  let navigate = useNavigate();
  const {
    openfilldetails,
    handleClosefilldetails,
    pashenthistre,
    rowdata,
    userActions,
    populateTable,
  } = props;

  const [openPost, setOpenPost] = useState(false);
  const [openBookApptModal, setOpenBookApptModal] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [callHistory, setCallHistory] = useState(false);
  const [pashentinfo, setpashentinfo] = useState([]);
  const [cardleftandright, setCardleftandright] = useState(0);
  const [menuId, setMenuId] = useState(null);

  const defaultValues = {
    callreceivedby: "",
    // appointmentradibutton: "At Private Clinic",
    atIMH: true,
    privateClinic: false,
    donotcallagain: false,
    calldayradiofield: "Patient Notes & Appointment",
    physicianrequest: false,
    appointmentdate: new Date(),
    tentativeadmdate: new Date(),
    doctorname: "",
    admission: false,
    surgery: false,
    investigations: false,
    remarksforadmission: "",
    doctornameTwo: "",
    tentativesurgerydate: new Date(),
    surgerydetailsremarks: "",
    feedback: "",
    complaints: "",
    mildsymptoms: "",
    expectedandUnexpected: "Expected",
    AdmittedAtOtherHospitalRadi: "Admitted At Other Hospital",
    isInStation: false,
    InstationoutStation: null,
    PlannedUnplanned: "Planned",
  };

  const { control, watch, setValue, register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  function postdataFilldetails(obj) {
    addCallDetails(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        reset(defaultValues);
        handleClosefilldetails();
        setCallHistory(false);
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    postdataFilldetails(finalData);
  }

  let getcalldayradiofield = watch("calldayradiofield");
  let getAdmittedAtOtherHospitalRadi = watch("AdmittedAtOtherHospitalRadi");
  let getexpectedandUnexpected = watch("expectedandUnexpected");
  let getPatientAdmitted = watch("PatientAdmitted");

  let yestrue;

  if (getPatientAdmitted == "Yes") {
    yestrue = true;
  }
  {
    yestrue = false;
  }

  let trueExpected;

  if (getexpectedandUnexpected === "Expected") {
    trueExpected = true;
  }
  if (getexpectedandUnexpected === "Unexpected") {
    trueExpected = false;
  }

  let getPlannedUnplanned = watch("PlannedUnplanned");

  let plannedtrue;

  if (plannedtrue === "Planned") {
    plannedtrue = true;
  }
  if (plannedtrue === "Unplanned") {
    plannedtrue = false;
  }

  let AdmittedAtHospital;
  let AdviseForReAdmission;

  if (getAdmittedAtOtherHospitalRadi === "Admitted At Other Hospital") {
    AdmittedAtHospital = true;
    AdviseForReAdmission = false;
  }
  if (getAdmittedAtOtherHospitalRadi === "Advise For Re-Admission") {
    AdviseForReAdmission = true;
    AdmittedAtHospital = false;
  }

  //let getappointmentradibutton = watch("appointmentradibutton");
  let atIMH_sel = watch("atIMH");
  let privateClinic_sel = watch("privateClinic");
  let getatimhradibutton = watch("atimhradibutton");

  let PrivateClinic;

  let AtIMHget;

  // if (getappointmentradibutton === "At Private Clinic") {
  //   PrivateClinic = true;
  //   AtIMHget = false;
  // }

  // if (getappointmentradibutton === "At IMH") {
  //   PrivateClinic = false;
  //   AtIMHget = true;
  // }

  const onSumitsavedata = (data) => {
    let saveobj = {
      admissionId: rowdata?.AdmissionId,
      postDischargeId: rowdata?.id,
      postDischargeDetailsId: rowdata?.["post_discharge_details_id"],
      callReceivedBy: data.callreceivedby,
      doNotCallAgain: data.donotcallagain,
      // calldayradiofield: data.calldayradiofield,
      physicianrequest: data.physicianrequest,
      appointmentDate: data.appointmentdate,
      tentativeAdmissionDate: data.tentativeadmdate,
      admissionAdviceDoctorName: data.doctorname,
      isAdmissionAdvice: data.admission,
      isSurgeryAdvice: data.surgery,
      isInvestigationAdvice: data.investigations,
      admissionAdviceDetails: data.remarksforadmission,
      surgeryAdviceDoctorName: data.doctornameTwo,
      tentativeSurgeryDate: data.tentativesurgerydate,
      surgeryAdviceDetails: data.surgerydetailsremarks,
      patientFeedback: data.feedback,
      complaints: data.complaints,
      mildSymptoms: data.mildsymptoms,
      remarks: data.remarksone,
      isApptPrivateClinic: privateClinic_sel, //PrivateClinic,
      appointmentdate: data.appointmentdate,
      isApptSelf: atIMH_sel, //AtIMHget,
      reasonOfDeath: data.reasonForDeath,
      isDeathExpected: trueExpected,
      isInStation: data.isInStation,
      isOutStation: data.isOutStation,
      InstationoutStation: data.InstationoutStation,
      mildSymptoms: data.symptoms,
      isAdmittedAtOtherHospital: AdmittedAtHospital,
      isReadmissionAdvice: AdviseForReAdmission,
      otherHospitalName: data.AdmittedHospitalName,
      isPlanned: plannedtrue,
      otherHospitalName: data.hospitalName,
      readmissionDate: data.readmittedDate,
      readmissionReason: data.reasonofreAdmission,
      yestrue: yestrue,
    };
    setFinalData(saveobj);
    setOpenPost(true);
  };

  function Cancelcalling() {
    disconnectCall(rowdata?.["post_discharge_details_id"])
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
      });
  }

  function PreviousCallDetails() {
    getPreviousCallDetails(rowdata?.id)
      .then((response) => response.data)
      .then((res) => {
        setpashentinfo(res.result);

        successAlert(res.message);
      });
  }

  console.log("pashenthistre", pashenthistre, rowdata);

  return (
    <Modal
      open={openfilldetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxHeight: "95%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
          overflow: "auto",
        }}
      >
        <div>
          <CancelPresentationIconButton
            onClick={() => {
              handleClosefilldetails();
              Cancelcalling();
              setCallHistory(false);
              reset();
            }}
          />
          <form className=" mb-2 " onSubmit={handleSubmit(onSumitsavedata)}>
            <div className=" text-[18px] font-bold mb-2">Call Details</div>

            <div className="bg-[#F1F1F1] border-[1px] p-3 rounded-md">
              <div className="   text-[13px] grid grid-cols-[16%_1%_23%_8%_1%_26%_7%_1%_10%]">
                <div className=" font-semibold">Patient Name</div>
                <div>:</div>
                <div>{pashenthistre?.["Patient Name"]}</div>
                <div className=" font-semibold">MR No</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.MRNo}
                </div>
                <div className=" font-semibold">Age</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.AgeYear}
                </div>
                <div className=" font-semibold">Contact No.</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.ContactNo}
                </div>
                <div className=" font-semibold">Adm. Date.</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.AdmissionDate}
                </div>
                <div className=" font-semibold">Disc. Date</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.DischargeDate}
                </div>
                <div className=" font-semibold">Department</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.DepartmentName}
                </div>
                <div className=" font-semibold">Disc. Doctor</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.DoctorName}
                </div>
                <div className=" font-semibold">Bed No. </div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.BedNo}
                </div>
                <div className=" font-semibold">Follow-up Date</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium">
                  {pashenthistre?.FollowupdDate}
                </div>
                <div className=" font-semibold">Address</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className=" text-[#676767] font-medium grid col-span-4">
                  {pashenthistre?.Address}
                </div>
                <div className=" font-semibold">Surgery's Done</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className="text-[#676767] font-medium grid col-span-7">
                  {pashenthistre?.SurgeryDone}
                </div>
                <div className=" font-semibold">Adm. Reason/ Complaints</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className="text-[#676767] font-medium grid col-span-7">
                  {pashenthistre?.AdmissionReasonComplaints}
                </div>
                <div className=" font-semibold">Course In hospital</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className="text-[#676767] font-medium grid col-span-7">
                  {pashenthistre?.CourseInHospital}
                </div>
                <div className=" font-semibold">Disc. Type</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className="text-[#676767] font-medium grid col-span-7">
                  {pashenthistre?.DischargeType}
                </div>
                <div className=" font-semibold	">Follow-up Investigation</div>
                <div className=" text-[#676767] font-medium">:</div>
                <div className="text-[#676767] font-medium grid col-span-7">
                  {pashenthistre?.FollowUpInvestigations}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div
                className={`${
                  callHistory === true ? "col-span-2" : "col-span-3"
                }`}
              >
                <div>
                  <div>
                    {getcalldayradiofield === "Patient Notes & Appointment" ? (
                      <div className="  my-2 flex justify-between border-[1px] rounded-md pl-2 pr-1 pt-1 pb-1 border-[#D9D9D9]  bg-[#FAFAFF] ">
                        <div className=" items-center flex space-x-3">
                          <div className=" flex space-x-3">
                            <div className=" whitespace-nowrap">Call Day</div>
                            <div>:</div>
                            <div className=" whitespace-nowrap">
                              {rowdata?.["Call Day"]}
                            </div>
                          </div>
                          <InputField
                            control={control}
                            name="callreceivedby"
                            label="Call Received By"
                          />
                          <div className=" flex items-center">
                            {/* <CheckBoxField
                              control={control}
                              name="donotcallagain"
                            />
                            <div className=" whitespace-nowrap font-Poppins">
                              Do Not Call Again
                    </div>*/}

                            <CheckBoxField
                              control={control}
                              name="donotcallagain"
                              label={"Do Not Call Again"}
                            />
                          </div>
                        </div>
                        <div
                          onClick={(row) => {
                            setCallHistory(true);
                            PreviousCallDetails(row);
                          }}
                        >
                          <Callhistory />
                        </div>
                      </div>
                    ) : null}

                    <div className=" bg-[#D3FFD8] pl-4 pr-1 pt-2 my-2 rounded-md">
                      <RadioField
                        control={control}
                        name="calldayradiofield"
                        dataArray={RadioButton}
                      />
                    </div>
                    {getcalldayradiofield === "Patient Notes & Appointment" ? (
                      <div>
                        <div>
                          <div className=" space-y-2 mt-2 my-2">
                            <InputField
                              control={control}
                              name="complaints"
                              label="Complaints"
                            />
                            <InputField
                              control={control}
                              name="mildsymptoms"
                              label="Mild Symptoms"
                            />
                            <div className=" flex space-x-3 ">
                              <InputField
                                control={control}
                                name="remarksone"
                                label="Remarks"
                              />
                              <div className=" items-center flex">
                                <CheckBoxField
                                  control={control}
                                  name="physicianrequest"
                                  label={"Physician Request"}
                                />
                                {/*<h1 className=" font-semibold whitespace-nowrap">
                                  Physician Request
                    </h1>*/}
                              </div>
                            </div>
                          </div>
                          <div className=" border-[1px] rounded-l-md  rounded-r-md bg-white  shadow-sm my-2 ">
                            <div className=" rounded-t-md p-1 bg-[#FEE7D6] font-semibold">
                              Appointment
                            </div>
                            <div className="pl-4 pr-1 pt-1 pb-1   my-2 flex items-center space-x-3">
                              <div
                                className=" flex items-center"
                                onClick={(e) => {
                                  setValue("atIMH", !e.target.checked);
                                }}
                              >
                                <CheckBoxField
                                  control={control}
                                  name="privateClinic"
                                  label={"At Private Clinic"}
                                />
                                {/*<RadioField
                                  control={control}
                                  name="appointmentradibutton"
                                  dataArray={appointmentradibutton}
                  />*/}
                              </div>
                              <div className=" w-48">
                                <DatePickerFieldNew
                                  control={control}
                                  name="appointmentdate"
                                  label="Appointment Date"
                                  value={new Date()}
                                  inputFormat="dd-MM-yyyy"
                                />
                              </div>
                              <div
                                onClick={(e) => {
                                  setValue("privateClinic", !e.target.checked);
                                }}
                              >
                                <CheckBoxField
                                  control={control}
                                  name="atIMH"
                                  label={"At IMH"}
                                />
                              </div>

                              {userActions.map((actions, i) => (
                                <>
                                  {!actions.isAction &&
                                    actions.action === "Book Appointment" && (
                                      <button
                                        onClick={() => {
                                          // navigate(actions.menuKey, {
                                          //   state: {
                                          //     menuId: actions.menuId,
                                          //     PatientId: rowdata?.PatientId,
                                          //     fromOutside: true,
                                          //   },
                                          // });
                                          setMenuId(actions.menuId);
                                          setOpenBookApptModal(true);
                                        }}
                                        type="button"
                                        className=" border-[#007EA9] border-[1px] p-1 text-sm font-Poppins rounded-md text-[#007EA9]"
                                      >
                                        Book Appointment
                                      </button>
                                    )}
                                </>
                              ))}
                            </div>
                          </div>
                          <div className=" rounded-l-md  rounded-r-md bg-white border-[1px]  shadow-sm my-2 ">
                            <div className=" space-x-7  flex justify-between items-center rounded-t-md p-1 bg-[#D6FEF4] font-semibold">
                              <div>Advise For</div>

                              <div className=" flex items-center">
                                <CheckBoxField
                                  control={control}
                                  name="admission"
                                  label="Admission"
                                />
                                <CheckBoxField
                                  control={control}
                                  name="surgery"
                                  label="Surgery"
                                />
                                <CheckBoxField
                                  control={control}
                                  name="investigations"
                                  label="Investigations"
                                />
                              </div>
                            </div>
                            <div className="pl-2 pr-1 pt-1 pb-1 my-2 ">
                              <h1>Admission Advise Details</h1>
                              <div className="grid grid-cols-3  gap-3 my-2">
                                <InputField
                                  control={control}
                                  name="doctorname"
                                  label="Doctor Name"
                                />
                                <DatePickerFieldNew
                                  control={control}
                                  name="tentativeadmdate"
                                  label="Tentative Adm. Date"
                                  value={new Date()}
                                  disablePast={true}
                                  inputFormat="dd-MM-yyyy"
                                />
                                <InputField
                                  control={control}
                                  name="remarksforadmission"
                                  label="Remarks For Admission"
                                />
                              </div>

                              <hr className=" my-2 border-t-[1px] border-[#ABABAB] " />
                              <div className=" ">
                                <h1>Surgery Advise Details</h1>
                                <div className=" grid grid-cols-3 my-2 gap-3">
                                  <InputField
                                    control={control}
                                    name="doctornameTwo"
                                    label="Doctor Name"
                                  />
                                  <DatePickerFieldNew
                                    control={control}
                                    name="tentativesurgerydate"
                                    label="Tentative Surgery Date"
                                    value={new Date()}
                                    inputFormat="dd-MM-yyyy"
                                    disablePast={true}
                                  />
                                  <InputField
                                    control={control}
                                    name="surgerydetailsremarks"
                                    label="Surgery Details/ Remarks"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" border-[1px] rounded-l-md  rounded-r-md bg-white  shadow-sm my-2 ">
                          <div className=" rounded-t-md p-1 bg-[#CFE8FF] font-semibold">
                            Patient Feedback
                          </div>
                          <div className=" my-2 p-2">
                            <InputArea
                              control={control}
                              name="feedback"
                              label="Patient Feedback"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {getcalldayradiofield === "Patient Died" ? (
                      <div className=" grid grid-cols-6 gap-3 mt-3">
                        <div className="col-span-4">
                          <InputArea
                            name="reasonForDeath"
                            label="Reason For Death"
                            control={control}
                          />
                        </div>

                        <div className="col-span-2">
                          <RadioField
                            control={control}
                            name="expectedandUnexpected"
                            dataArray={expectedandUnexpectedredio}
                          />
                        </div>
                      </div>
                    ) : null}

                    {getcalldayradiofield === "Leakage" ? (
                      <div>
                        <div className=" grid grid-cols-12 gap-3 mt-3">
                          <div className="col-span-12">
                            <InputArea
                              label="Reason Of Leakage"
                              control={control}
                              name="reasonofLeakage"
                            />
                          </div>

                          <CheckBoxField
                            control={control}
                            name="isInStation"
                            label="In Station"
                          />
                          <CheckBoxField
                            control={control}
                            name="isOutStation"
                            label="Out Station"
                          />

                          <div className="col-span-10">
                            <InputArea
                              label="Symptoms"
                              name="symptoms"
                              control={control}
                            />
                          </div>
                          <div className="col-span-4 pl-2">
                            <RadioField
                              control={control}
                              name="AdmittedAtOtherHospitalRadi"
                              dataArray={AdmittedAtOtherHospitalRadi}
                            />
                          </div>
                          <div className="col-span-4">
                            <fieldset
                              className=" "
                              disabled={
                                getAdmittedAtOtherHospitalRadi ===
                                "Advise For Re-Admission"
                                  ? true
                                  : false
                              }
                            >
                              <InputField
                                control={control}
                                name="AdmittedHospitalName"
                                label="Admitted Hospital Name"
                              />
                            </fieldset>
                          </div>
                          <div className="col-span-4 pl-2">
                            <RadioField
                              control={control}
                              name="PlannedUnplanned"
                              dataArray={PlannedUnplanned}
                            />
                          </div>
                        </div>

                        <div className=" space-x-3 grid grid-cols-[37%_60%] my-2"></div>
                      </div>
                    ) : null}

                    {getcalldayradiofield == "Re-Admitted" ? (
                      <div className="grid grid-cols-6 gap-3 mt-3">
                        <div className="col-span-2">
                          <InputField
                            label="Hospital Name"
                            name="hospitalName"
                            control={control}
                          />
                        </div>
                        <div className="ml-2">
                          <RadioField
                            control={control}
                            name="PlannedUnplanned"
                            dataArray={PlannedUnplanned}
                          />
                        </div>
                        <div>
                          <DatePickerFieldNew
                            control={control}
                            name="readmittedDate"
                            label="Re-Admitted Date"
                            value={new Date()}
                            inputFormat="dd-MM-yyyy"
                          />
                        </div>

                        <div className=" col-span-6">
                          <InputArea
                            control={control}
                            name="reasonofreAdmission"
                            label="Reason Of Re-Admission"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className=" my-2 flex justify-end gap-3">
                  <CommonButton
                    type="button"
                    label="Cancel"
                    className="resetButton border border-customRed text-customRed"
                    onClick={() => {
                      handleClosefilldetails();
                      Cancelcalling();
                    }}
                  />
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="resetButton border border-customRed text-customRed"
                    onClick={() => {
                      // reset(defaultValues);
                    }}
                  />

                  <CommonButton
                    type="submite"
                    label="Save"
                    className="saveButton bg-customGreen text-white"
                  />
                </div>
              </div>

              {callHistory === true ? (
                <>
                  <div>
                    <div className=" mx-2 rounded-[8px] border-[1px] border-gray-300 bg-white shadow-[2px 2px 8px 0] my-2">
                      <div className="bg-[#DFD3FF]  rounded-t-md p-2  flex justify-between">
                        <h1 className="  whitespace-nowrap text-[16px] font-[600] font-Poppins">
                          Previous Call Detail
                        </h1>
                        <CancelPresentationIconButton
                          onclick={() => {
                            setCallHistory(false);
                          }}
                        />
                      </div>
                      <div className=" mx-2  ">
                        <div className="bg-[#FFDBDB] p-2 my-2 rounded-md justify-between">
                          <div className=" flex text-[13px] space-x-2">
                            <h1 className=" text-[#4B34DD]">Call Date :</h1>
                            <h1 className=" font-semibold">
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]?.callDate
                                : null}
                            </h1>
                          </div>
                          <div className=" flex text-[13px] space-x-3">
                            <h1 className=" text-[#4B34DD]">Call Day :</h1>
                            <h1 className=" font-semibold">
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]?.callDay
                                : null}
                            </h1>
                          </div>
                        </div>
                        <div className=" text-[13px] space-y-jodit_col-lg-3-4">
                          <div className=" grid grid-cols-[25%_5%_70%] space-x-2">
                            <h1 className=" text-[#EF5B07]">Complaints</h1>
                            <h1>:</h1>
                            <h1>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]?.complaints
                                : null}
                            </h1>
                          </div>
                          <div className=" grid grid-cols-[25%_5%_70%] space-x-2">
                            <h1 className=" text-[#EF5B07]">Symptoms</h1>
                            <h1>:</h1>
                            <h1>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]?.mildSymptoms
                                : null}
                            </h1>
                          </div>
                          <div className=" grid grid-cols-[25%_5%_70%] space-x-2">
                            <h1 className=" text-[#EF5B07]">Remarks</h1>
                            <h1>:</h1>
                            <h1>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]?.remarks
                                : null}
                            </h1>
                          </div>
                        </div>
                        <hr className=" border-t-[1px] border-[#969696]" />
                        <div className=" p-2 text-[13px] grid grid-cols-[35%_5%_60%]">
                          <h1 className="whitespace-nowrap text-[#95784D]">
                            Phys. Requested
                          </h1>
                          <h1>:</h1>
                          <h1>
                            {pashentinfo?.length > 0
                              ? pashentinfo[cardleftandright]
                                  ?.isPhysicianRequest
                              : null}
                          </h1>
                        </div>
                        <hr className=" border-t-[1px] border-[#969696]" />
                        <div className=" p-2 text-[13px] grid grid-cols-[28%_5%_67%]">
                          <h1 className="whitespace-nowrap text-[#95784D]">
                            Appointment
                          </h1>
                          <h1>:</h1>
                          <h1>
                            At IMH, Date :{" "}
                            {pashentinfo?.length > 0
                              ? pashentinfo[cardleftandright]?.appointmentDate
                              : null}
                          </h1>
                        </div>
                        <h1 className=" border-t-[1px] border-[#969696]" />

                        <div>
                          <h1 className=" my-2 mx-1 text-[#22987A] text-[13px] font-Poppins">
                            Advise Surgery
                          </h1>
                          <div className=" space-y-2 text-[13px] mx-1 grid grid-cols-[25%_5%_70%]">
                            <div className=" font-semibold">Doctor</div>
                            <div>:</div>
                            <div>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]
                                    ?.surgeryAdviceDoctorName
                                : null}
                            </div>
                            <div className=" font-semibold">Tent. Date</div>
                            <div>:</div>
                            <div>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]
                                    ?.tentativeSurgeryDate
                                : null}
                            </div>
                            <div className=" font-semibold">Remarks</div>
                            <div>:</div>
                            <div>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]
                                    ?.admissionAdviceDetails
                                : null}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1 className=" my-2 mx-1 text-[#22987A] text-[13px] font-Poppins">
                            Advise Admission
                          </h1>
                          <div className=" space-y-2 text-[13px] mx-1 grid grid-cols-[25%_5%_70%]">
                            <div className=" font-semibold">Doctor</div>
                            <div>:</div>
                            <div>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]
                                    ?.admissionAdviceDoctorName
                                : null}
                            </div>
                            <div className=" font-semibold">Tent. Date</div>
                            <div>:</div>
                            <div>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]
                                    ?.tentativeAdmissionDate
                                : null}
                            </div>
                            <div className=" font-semibold">Remarks </div>
                            <div>:</div>
                            <div>
                              {pashentinfo?.length > 0
                                ? pashentinfo[cardleftandright]
                                    ?.surgeryAdviceDetails
                                : null}
                            </div>
                          </div>
                        </div>
                        <div className=" my-3 text-[13px]   flex space-x-3">
                          <h1 className="text-[#22987A] ">
                            Investigations Advised
                          </h1>
                          <h1>:</h1>
                          <h1>
                            {pashentinfo?.length > 0
                              ? pashentinfo[cardleftandright]
                                  ?.isInvestigationAdvice
                              : null}
                          </h1>
                        </div>
                        <div className=" my-3 text-[13px] flex space-x-3">
                          <h1 className=" text-[#3D49B4]">Feedback</h1>
                          <h1>:</h1>
                          <h1>
                            {pashentinfo?.length > 0
                              ? pashentinfo[cardleftandright]?.patientFeedBack
                              : null}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className=" flex justify-between mx-2">
                      <button
                        type="button"
                        onClick={() => {
                          if (cardleftandright !== 0) {
                            setCardleftandright(cardleftandright - 1);
                          }
                        }}
                      >
                        <PreviousIcon />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (cardleftandright !== pashentinfo?.length - 1) {
                            setCardleftandright(cardleftandright + 1);
                          }
                        }}
                      >
                        <NextIcon />
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </form>
          <ConfirmationModal
            confirmationOpen={openPost}
            confirmationHandleClose={handleClosePost}
            confirmationSubmitFunc={addRecord}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure want to add this record ?"
            confirmationButtonMsg="Add"
          />

          {openBookApptModal ? (
            <PostDischargeBookAppoinmentModal
              open={openBookApptModal}
              setOpen={setOpenBookApptModal}
              handleOpen={() => setOpenBookApptModal(true)}
              handleClose={() => setOpenBookApptModal(false)}
              menuId={menuId}
              PatientId={rowdata?.PatientId}
            />
          ) : null}
        </div>
      </Box>
    </Modal>
  );
}
