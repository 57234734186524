import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const fetchListOfMiscellaneousIncome = (object) => {
  return apiClient.post(`/accounting/getListOfMiscellaneousIncome`, object, {
    headers: authHeader(),
  });
};

export const getAccountHead = () => {
  return apiClient.get(`/invWorkOrderGrn/accountHead`, {
    headers: authHeader(),
  });
};

export const addNewMiscellaneousIncome = (object) => {
  return apiClient.post(`/accounting/saveMiscellaneousIncome`, object ,  {
    headers: authHeader(),
  });
};


export const MiscellaneousIncomeReportData = (object) => {
  return apiClient.post(`/accountingPrints/getMiscIncomePrint`, object , {
   headers: authHeader(),
  });
};

export const miscIncomeLineItemPrint = (miscExpenseId) => {
  return apiClient.get(`/accountingPrints/getMiscIncomeReceiptPrint/${miscExpenseId}`, {
   headers: authHeader(),
  });
};