import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import DrugScheduleModal from "./DrugScheduleModal";
import {
  closePrescribedInstruction,
  updateSpecialInstructions,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { useState } from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CancelIcon from "@mui/icons-material/Cancel";

function SpecialInstructionTable(props) {
  //local storage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  // show coplete
  const [isComplete, setIsComplete] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  // confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleCloseConfirmationModal = () => {
    if (openConfirmationModal) {
      setOpenConfirmationModal(false);
    }
  };
  const [selectedDrug, setSelectedDrug] = useState(null);

  // Confirmation modal
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  // orderList modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "DrugInstrId",
    "SpclInstrID",
    "AddedById"
  ]);
  //is complete date time function
  const handleCheckboxComplete = (row, event, index) => {
    setIsComplete(event.target.checked);
    setOpenConfirmationModal(true);
    setSelectedRow(row);
    setConfirmationMsg("Do You Really Want To Complete Selected Instruction");
  };

  const handleCheckboxReject = (row, event, index) => {
    setIsReject(event.target.checked);
    setOpenConfirmationModal(true);
    setSelectedRow(row);
    setConfirmationMsg(
      "Do You Really Want To Reject Or Cancel Selected Instruction"
    );
  };

  const updateInstruction = () => {
    let obj = {
      addedBy: token.userId,
      admissionId: props.rowData?.AdmissionId,
      id: selectedRow.Id,
      isComplete: isComplete,
      isRejectedOrCancel: isReject,
      menuId:
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
      privilege: props.privilege,
    };
    props.setOpenLoader(true);
    updateSpecialInstructions(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleCloseConfirmationModal();
        props.populateTable();
        setIsComplete(false);
        setIsReject(false);
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        props.setOpenLoader(false);
      });
  };

  // call cancel drug api
  function addRecord() {
    if (selectedDrug !== null) {
      props.setOpenLoader(true);
      closePrescribedInstruction(
        selectedDrug?.Id,
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
        props.privilege
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenPost(false);
            props.populateTable();
            props.setOpenLoader(false);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          props.setOpenLoader(false);
        });
    }
  }
  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-56 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {props.userActions.map((actions, i) => (
                      <>
                        {actions.action === "Cancel" ? (
                          <>
                            <TableCell
                              className="whitespace-nowrap"
                              style={{ background: "#F1F1F1" }}
                            >
                              <span className="text-gray-600 font-bold">
                                Actions
                              </span>
                            </TableCell>
                          </>
                        ) : null}
                      </>
                    ))}
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data.result.map((row, index, i) => {
                    return (
                      <TableRow key={index}>
                        {props.userActions.map((actions, i) => (
                          <>
                            {actions.isAction === true ? (
                              <>
                                {actions.action === "Cancel" ? (
                                  <>
                                    <TableCell>
                                      <Tooltip title="Cancel Instruction">
                                        <button
                                          type="button"
                                          disabled={props.instructionValue}
                                          className="text-gray-600 mr-3"
                                          onClick={() => {
                                            props.setPrivilege(actions.action);
                                            setOpenPost(true);
                                            setSelectedDrug(row);
                                          }}
                                        >
                                          <CancelIcon />
                                        </button>
                                      </Tooltip>
                                    </TableCell>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ))}

                        {headers &&
                          headers.map((header, i) => (
                            <TableCell
                              className="whitespace-nowrap"
                              key={i}
                              padding="checkbox"
                            >
                              {header === "Complete" ? (
                                <>
                                  {props.userActions.map((obj) => (
                                    <>
                                      {!obj.isAction &&
                                      obj.action === "Create" ? (
                                        <>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                type="checkbox"
                                                disabled={
                                                  row["Reject or Cancel"] ===
                                                  true
                                                    ? true
                                                    : false ||
                                                      props.instructionValue ===
                                                        true
                                                    ? true
                                                    : false
                                                }
                                                checked={row.Complete}
                                                name={`Complete${index}`}
                                                onChange={(event) => {
                                                  props.setPrivilege(
                                                    obj.action
                                                  );
                                                  handleCheckboxComplete(
                                                    row,
                                                    event,
                                                    index
                                                  );
                                                }}
                                              />
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </>
                                  ))}
                                </>
                              ) : header === "Reject or Cancel" ? (
                                <>
                                  {props.userActions.map((obj) => (
                                    <>
                                      {!obj.isAction &&
                                      obj.action === "Create" ? (
                                        <>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                disabled={
                                                  row.Complete === true
                                                    ? true
                                                    : false ||
                                                      props.instructionValue ===
                                                        true
                                                    ? true
                                                    : false
                                                }
                                                checked={
                                                  row["Reject or Cancel"]
                                                }
                                                name={`Reject or Cancel${index}`}
                                                onChange={(event) => {
                                                  props.setPrivilege(
                                                    obj.action
                                                  );
                                                  handleCheckboxReject(
                                                    row,
                                                    event,
                                                    index
                                                  );
                                                }}
                                              />
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <span>{row[header]}</span>
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {open ? (
          <DrugScheduleModal
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            setOpenLoader={props.setOpenLoader}
          />
        ) : (
          ""
        )}
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={handleCloseConfirmationModal}
          confirmationSubmitFunc={updateInstruction}
          confirmationLabel="Confirmation"
          confirmationMsg={confirmationMsg}
          confirmationButtonMsg="Yes"
        />
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Do You Really Want To Close Instruction Permanently...???"
          confirmationButtonMsg="Yes"
        />
      </div>
    </>
  );
}
export default React.memo(SpecialInstructionTable);
