import * as React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MuiAutoCompleteSmall from "../../../common/components/FormFields/MuiAutoCompleteSmall";

import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import { searchDoctor } from "../../services/BillingServices";
//imports from the react-hook-form library
import { useForm } from "react-hook-form";

export default function PackagedCharges(props) {
  const { control } = useForm({ mode: "onChange" });

  //state variable to populate the options of Doctor SearchDropdown
  const [doctorList, setDoctorList] = React.useState([]);

  //allHeaders is an object which contains the title of all the columns of the table
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //
  //set rows object to table
  // const allHeaders = Object.keys(props.data.result[0]);

  // const headers = removeHeaders(
  //   allHeaders,
  //   props.removeHeaders ? props.removeHeaders : ["id"]
  // );

  ////
  let allHeaders = null;

  let newHeaders = [];

  if (props.dataArray.length > 0) {
    //allHeaders is an object which contains the title of all the columns of the table
    allHeaders = Object.keys(props.dataArray[0]);

    for (let headerName of allHeaders) {
      if (
        headerName !== "correspondingChargeIdValue" &&
        headerName !== "id" &&
        headerName !== "Id" &&
        headerName !== "GroupId"
      ) {
        newHeaders.push(headerName);
      }
    }

    //allHeaders = newHeaders;
    allHeaders = removeHeaders(
      newHeaders,
      props.removeHeaders
        ? props.removeHeaders
        : ["id", "serviceId", "DoctorShare"]
    );
  }

  //Populate the DoctorList through this on change function of "Doctor" SearchDropdown
  const handleDoctorString = (searchString, doctorValArrIndex) => {
    if (searchString !== "") {
      searchDoctor(searchString).then((response) => {
        setDoctorList(response.data.result);
      });
    }

    let tempCurrentDoctorList = props.currentDoctorList;

    let drString = searchString[0] + searchString[1];

    if (drString === "Dr") {
      let searchId = doctorValArrIndex;
      let requiredObj = tempCurrentDoctorList[searchId];

      let tempDoctorList = doctorList;

      let requiredDoctorObj = tempDoctorList.find(getRequiredDoctorId);

      function getRequiredDoctorId(singleDoctorObj) {
        if (singleDoctorObj.value === searchString) {
          return singleDoctorObj;
        }
      }

      if (requiredDoctorObj) {
        requiredObj.doctorId = requiredDoctorObj.id;
        requiredObj.value = requiredDoctorObj.value;
        requiredObj.label = requiredDoctorObj.label;

        tempCurrentDoctorList[searchId] = requiredObj;

        props.setCurrentDoctorList(tempCurrentDoctorList);
      }
    }

    if (searchString === "") {
      let searchId = doctorValArrIndex;
      let requiredObj = tempCurrentDoctorList[searchId];

      requiredObj.doctorId = null;
      requiredObj.value = null;
      requiredObj.label = null;

      tempCurrentDoctorList[searchId] = requiredObj;

      props.setCurrentDoctorList(tempCurrentDoctorList);
    }
  };

  //Below useEffect is to patch the doctor into the searchDropdown given in the patient info.
  React.useEffect(() => {
    let requiredDoctorName = "";
    let requiredDoctorId = "";

    if (props?.selectedPatient?.doctorName) {
      requiredDoctorName = props.selectedPatient.doctorName;
    }

    if (props?.selectedPatient?.doctorId) {
      requiredDoctorId = props?.selectedPatient?.doctorId;
    }

    let tempCurrentDoctorList = structuredClone(props.currentDoctorList);

    if (props.dataArray.length > 0) {
      for (let index = 0; index < props.dataArray.length; index++) {
        if (
          props.dataArray[index]?.["Doctor Name"] === "True" &&
          tempCurrentDoctorList[index].value === null &&
          tempCurrentDoctorList[index].label === null &&
          tempCurrentDoctorList[index].doctorId === null
        ) {
          tempCurrentDoctorList[index].value = requiredDoctorName;
          tempCurrentDoctorList[index].label = requiredDoctorName;
          tempCurrentDoctorList[index].doctorId = requiredDoctorId;
        }
      }
    }

    props.setCurrentDoctorList(tempCurrentDoctorList);
  }, []);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
          {/* Display the table ; if and only if the dataArray's length is greater than 0 */}
          {props.dataArray.length > 0 ? (
            <form>
              <Paper sx={{ width: "100%", my: 1 }}>
                <TableContainer
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: 7,
                      height: 7,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#7393B3",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "lightBlue",
                    },
                  }}
                  className="rounded h-48 2xl:h-60"
                >
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            paddingY: 0.5,
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        {allHeaders &&
                          allHeaders.map((singleHeader) => (
                            <TableCell
                              // style={{ width: 160 }}
                              // align="left"
                              key={singleHeader}
                            >
                              <span className="text-gray-600  whitespace-nowrap">
                                {singleHeader}
                              </span>
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {props.dataArray.map((singleDataObj, index) => {
                        return (
                          <>
                            {/* <form> */}
                            <TableRow
                              sx={{
                                "& td": {
                                  paddingY: 1.6,
                                  // backgroundColor: "#F1F1F1",
                                },
                                "& .MuiTableCell-root": { paddingTop: 0 },
                              }}
                            >
                              {allHeaders &&
                                allHeaders.map((header) => (
                                  <TableCell key={header}>
                                    {header === "Doctor Name" &&
                                    singleDataObj["Doctor Name"] === "True" ? (
                                      <div className="lg:w-60  md:w-40">
                                        <MuiAutoCompleteSmall
                                          options={doctorList}
                                          name={`Doctor${singleDataObj["id"]}`}
                                          label="Select Doctor"
                                          defaultValue={
                                            // singleDataObj.selectedDoctor
                                            props.currentDoctorList[index]?.[
                                              "Doctor Name"
                                            ] === "True" &&
                                            props.currentDoctorList[index]
                                              .doctorId
                                              ? props.currentDoctorList[index]
                                              : {
                                                  "Doctor Name": "True",
                                                  id: index,
                                                  value:
                                                    props.selectedPatient
                                                      .doctorName,
                                                  label:
                                                    props.selectedPatient
                                                      .doctorName,
                                                  doctorId:
                                                    props.selectedPatient
                                                      .doctorId,
                                                }
                                          }
                                          handleInputChange={(
                                            searchStringVal
                                          ) => {
                                            handleDoctorString(
                                              searchStringVal,
                                              singleDataObj["id"]
                                            );
                                          }}
                                          control={control}
                                        />
                                      </div>
                                    ) : (
                                      <span className="text-gray-600  whitespace-nowrap">
                                        {singleDataObj[header]}
                                      </span>
                                    )}
                                  </TableCell>
                                ))}
                            </TableRow>
                            {/* </form> */}
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </form>
          ) : (
            <Paper sx={{ width: "100%", my: 1 }}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-48 2xl:h-60"
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    ></TableRow>
                  </TableHead>

                  <TableBody>
                    <div className="row">
                      <div className="grid justify-center text-black">
                        Data Not Available.
                      </div>
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </div>
    </>
  );
}
