import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { baseUrl } from "../../../http-common";

export default function ViewImageModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, ImagePath } = props;

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "50%",
    width: {
      lg: "80%",
      sm: "80%",
    },
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Documents
            </div>

            <hr className="border mx-1  border-customBlue" />
            <div>
              <img
                src={ImagePath}
                alt={`Image`}
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
