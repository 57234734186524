import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//Vitals
export const getAllVitals = (admissionId) => {
  return apiClient.get(
    `/clinicalCareChart/vitalsList/${admissionId}`,

    {
      headers: authHeader(),
    }
  );
};

export const savePatientVitals = async (addNewVital) => {
  return await apiClient.post(`/clinicalCareChart/saveVitals`, addNewVital, {
    headers: authHeader(),
  });
};

//Care N Position
export const getAllCarePosition = (admissionId, page, size) => {
  return apiClient.get(
    `/clinicalCareChart/careAndPositionList/${admissionId}/${page}/${size}`,

    {
      headers: authHeader(),
    }
  );
};

export const addNewcareNPosition = async (addNewCareNPosition) => {
  return await apiClient.post(
    `/clinicalCareChart/saveCareAndPosition`,
    addNewCareNPosition,

    {
      headers: authHeader(),
    }
  );
};

export const deleteVitals = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteVitals/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const tpiChartData = (postObj) => {
  return apiClient.post(`/reports/nursing/cccVitals`, postObj, {
    headers: authHeader(),
  });
};

export const fetchPatientRadarVitalsList = (listObj) => {
  return apiClient.post(
    `/clinicalCareChart/getPatientRadarVitalsList`,
    listObj,
    {
      headers: authHeader(),
    }
  );
};

export const verifyRadarVitals = (radarVitalIds) => {
  return apiClient.get(
    `/clinicalCareChart/verifyRadarVitals/${radarVitalIds}`,
    {
      headers: authHeader(),
    }
  );
};
