import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../common/components/FormFields/InputField";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import ContraVoucherModal from "./ContraVoucherModal";
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { TextareaAutosize } from "@mui/material";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import { addNewContraVoucher } from "../../services/contraVoucherServices/ContraVoucherServices";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getAccountHead } from "../../services/miscellaneousIcomeServices/MiscellaneousIcomeServices";
import ContravoucherPrint from "../../prints/contraVoucherPrint/ContravoucherPrint";

const ContraVoucher = () => {
  const schema = yup.object().shape({
    chequeNo: yup
      .string()
      .required("Required")
      .matches(/^[0-9\s]+$/, "Only alphanumeric are allowed")
      .max(10),
  });
  const defaultValues = {
    drInput: "",
    crInput: "",
    dr: null,
    cr: null,
    chequeDate: new Date(),
    inFavour: "",
    amount: "",
    chequeNo: "",
    narration: "",
  };

  const {
    control,
    reset,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const [contraVoucherData, setContraVoucherData] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let chequeDateVal = watch("chequeDate");
  let chequeNoVal = watch("chequeNo");
  let inFavourVal = watch("inFavour");
  let drVal = watch("dr");
  let crVal = watch("cr");
  let narrationVal = watch("narration");
  let amountVal = watch("amount");

  const [accountHead, setAccountHead] = useState([]);
  const [finalData, setFinalData] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [contraVoucherId, setContraVoucherId] = useState(null);
  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 500);

    return (
      <div className="hidden">
        <ContravoucherPrint contraVoucherId={contraVoucherId} />
      </div>
    );
  };

  const addContraVoucher = () => {
    if (chequeNoVal !== "") {
      let drObj = {
        // Description: inFavourVal,
        "Dr.": drVal?.label,
        "Cr.": "-",
        Narration: narrationVal,
        Amount: amountVal,
        "Chque Node.": chequeNoVal,
      };

      let crObj = {
        // Description: inFavourVal,
        "Dr.": "-",
        "Cr.": crVal?.label,
        Narration: narrationVal,
        Amount: amountVal,
        "Chque Node.": chequeNoVal,
      };

      let arr = [...contraVoucherData];

      arr.push(drObj, crObj);

      setContraVoucherData(arr);
    }
  };

  function postContraVoucher(obj) {
    addNewContraVoucher(obj)
      .then((response) => {
        setOpenBackdrop(false);
        successAlert(response.data.message);
        reset(defaultValues);
        setContraVoucherId(response.data.result);
        handleClose();
        setOpenPrintModal(true);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postContraVoucher(finalData);
    addContraVoucher();
  }

  const onSubmitDataHandler = (data) => {
    let postedArr = {
      amount: Number(data.amount),
      checkDate: format(chequeDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      checkNo: data.chequeNo,
      crBankId: data.cr?.id,
      drBankId: data.dr?.id,
      inFavour: data.inFavour,
      isCancelled: false,
      journalEntry: false,
      narration: data.narration,
      unitId: userInfo?.unitId,
    };

    setOpenPost(true);
    setFinalData(postedArr);
  };

  useEffect(() => {
    getAccountHead()
      .then((response) => {
        setAccountHead(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }, []);

  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Contra Voucher
      </h1>
      <div className=" flex justify-between mt-2">
        <h1 className="font-semibold text-lg">List of Ledgers</h1>
      </div>
      {contraVoucherData.length > 0 ? (
        <CommonDynamicTableNew
          dataResult={contraVoucherData}
          removeHeaders={["id"]}
          tableClass="h-[100]"
          highlightRow={false}
        />
      ) : (
        <p className="text-gray-600 font-semibold text-sm text-center py-5">
          No Record Found...!
        </p>
      )}
      <div className=" flex justify-end gap-2 mb-2">
        <div className="grid md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-2">
          <InputField
            label="Dr"
            name="drInput"
            type="number"
            control={control}
            disabled={true}
          />
          <InputField
            label="Cr"
            name="crInput"
            type="number"
            control={control}
            disabled={true}
          />
        </div>
      </div>

      <hr className="border border-customBlue mx-1 my-2 " />

      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 py-2">
          <div className="lg:flex lg:col-span-2 space-y-2 lg:space-y-0 lg:space-x-2">
            <div className="lg:w-5/12">
              <DatePickerFieldNew
                control={control}
                name="chequeDate"
                label="Cheque Date"
                value={new Date()}
                size="small"
                disablePast={true}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <InputField control={control} name="inFavour" label="In Favour" />
          </div>
          <DropdownField
            control={control}
            name="dr"
            placeholder="Dr."
            dataArray={accountHead}
            isSearchable={true}
            isClearable={true}
          />
          <DropdownField
            control={control}
            name="cr"
            placeholder="Cr."
            dataArray={accountHead}
            isSearchable={true}
            isClearable={true}
          />
          <div className="col-span-2 w-full">
            <TextareaAutosize
              {...register("narration")}
              minRows={2}
              className="rounded"
              placeholder="Narration"
              style={{
                width: "100%",
                border: "1px solid gray",
                padding: "1rem",
              }}
              name="narration"
            />
          </div>
          <InputField
            control={control}
            name="amount"
            label="Amount"
            type="number"
            inputRef={{
              ...register("amount", {
                onChange: (e) => {
                  if (e.target.value !== "") {
                    setValue("drInput", Number(e.target?.value));
                    setValue("crInput", Number(e.target?.value));
                  } else {
                    setValue("drInput", "");
                    setValue("crInput", "");
                  }
                },
              }),
            }}
          />

          <InputField
            control={control}
            name="chequeNo"
            label="Cheque No."
            type="number"
            error={errors.chequeNo}
          />
        </div>

        <div className="flex justify-end space-x-3">
          <CommonButton
            type="button"
            label="Reset"
            className="border border-customRed text-customRed"
            onClick={() => {
              reset(defaultValues);
              setContraVoucherData([]);
            }}
          />

          <CommonButton
            type="submit"
            label="Save"
            className="bg-customGreen text-white"
          />
        </div>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* <ContraVoucherModal
        handleClose={handleClose}
        open={open}
        edit={edit}
        setEdit={setEdit}
        contraVoucherData={contraVoucherData}
        setContraVoucherData={setContraVoucherData}
      /> */}

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
      {openPrintModal && renderPrint()}
    </div>
  );
};

export default ContraVoucher;
