import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { InPatientIssuePrintData } from "../../inventory/services/pharmacy/inPatientIssueServices/InPatientIssueServices";

export default function AppointmentListPrint({ dataResult }) {
  const printRef = useRef(null);
  // const [dataResult, setDataResult] = React.useState();

  // React.useEffect(() => {
  //   InPatientIssuePrintData(props.ipdIssueId, props.Storeld)
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setDataResult(res.result);
  //     });
  // }, [props]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  // useEffect(() => {
  //   printRef && GeneratePrint(printRef);
  // }, []);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
            <div className=" -mt-10 border-b-[1px] border-black">
              <h1 className=" font-semibold text-[15px] ">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[11px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" font-semibold text-[14px] my-1 border-t-[1px] border-black mt-[1px]">
                Appointment List
              </h1>
            </div>
          </div>
          <div className=" grid grid-cols-[20%_16%_16%_16%_16%_16%] text-[11px] font-semibold my-1 border-b-[1px] border-black">
            <div>Patient Name</div>
            <div>Contact Number</div>
            <div>Appointment Date</div>
            <div>From Time</div>
            <div>To Time</div>
            <div>Doctor Name</div>
          </div>
        </thead>
        <tbody className="w-full">
          {dataResult?.map((data) => {
            return (
              <div className="  grid grid-cols-[20%_16%_16%_16%_16%_16%] text-[11px]">
                <div>{data?.["Patient Name"]}</div>
                <div>{data?.["Contact No"]}</div>
                <div>{data?.["Appointment Date"]}</div>
                <div>{data?.["From Time"]}</div>
                <div>{data?.["To Time"]}</div>
                <div>{data?.["Doctor Name"]}</div>
              </div>
            );
          })}
        </tbody>
      </table>
      {/*<table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" grid grid-cols-1 ">
            <div className="mr-2 ">
              <h6 className="font-semibold text-[10px]  text-center">
                Inamdar Multispeciality Hospital
              </h6>
              <p className=" text-[8px]  text-center">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </p>
              <h6 className="font-semibold text-[9px]  text-center">
                Phone No : 020-66812222/44
              </h6>
              <div className=" flex justify-end gap-2 -mt-[50px]">
                <InamdarHospiLogo />
              </div>
            </div>
          </div>
          <div className="border-t-[1px] border-black">
            <h6 className="font-semibold text-[10px]  text-center">
              Appointment List
            </h6>
          </div>
        </thead>

        <tbody className="w-full">
            <table className="w-full text-[11px]  ">
              <thead className=" border-t-[1px] border-gray-800 border-b-[1px] ">
                <tr className="text-start">
                  <th className="text-[11px]">Patient Name</th>
                  <th className="text-[11px]">Contact Number</th>
                  <th className="text-[11px]">Appointment Date</th>
                  <th className="text-[11px]">From Time</th>
                  <th className="text-[11px]">To Time</th>
                  <th className="text-[11px]">Doctor Name</th>
                </tr>
              </thead>
              <tbody className="text-start pt-[3px]">
                {dataResult?.map((row) => (
                  <tr className="pt-[3px]">
                    <td className="text-[11px] pl-3">{row?.["Patient Name"]}</td>
                    <td className="text-[11px]">{row?.["Contact No"]}</td>
                    <td className="text-[11px]">{row?.["Appointment Date"]}</td>
                    <td className="text-[11px]">{row?.["From Time"]}</td>
                    <td className="text-[11px]">{row?.["To Time"]}</td>
                    <td className="text-[11px]">{row?.["Doctor Name"]}</td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
        </tbody>
        
                </table>*/}
    </div>
  );
}
