import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//list
export const fetchListOfDailyTips = (listObj) => {
  return apiClient.post(`/administration/getListOfDailyTips`, listObj, {
    headers: authHeader(),
  });
};

//save
export const saveDailyTips = (finalObj) => {
  return apiClient.post(`/administration/saveDailyTips`, finalObj, {
    headers: authHeader(),
  });
};

//change status
export const changeDailyTipsStatus = (changeStatusObj) => {
  return apiClient.post(
    `/administration/changeDailyTipsStatus`,
    changeStatusObj,
    {
      headers: authHeader(),
    }
  );
};

//Autocomplete
export const fetchListOfDailyTipsSearch = (searchString) => {
  return apiClient.get(
    `/administration/getListOfDailyTipsSearch/${searchString}`,

    {
      headers: authHeader(),
    }
  );
};
