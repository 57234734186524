import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { IconButton, Slider, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerField from "../../../../common/components/FormFields/DatePickerField";
import InputField from "../../../../common/components/FormFields/InputField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import MLCModalEdit from "../../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchDoctor } from "../../../../commonServices/miscellaneousServices/MiscServices";
import { fetchMlcPatientInfo } from "../../../../commonServices/mlcServices/MlcServices";
import {
  autoSearchService,
  fetchOpdTreatmentDetails,
  fetchPrintOpdTrtRecord,
  saveOPDTreatmentRecord,
} from "../../../services/emergencyservice/EmergencyServices";
import ClinicalFindings from "./ClinicalFindings";
import MedicationPrescriptionModal from "./MedicationPrescriptionModal";
import PainAssessmentScale from "./assets/PainAssessmentScale.svg";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "65%",
  // maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const Actions = [
  {
    id: 0,
    action: "new",
    isAction: false,
  },
];

let medicationHeaders = [
  {
    "Item Name": "",
    Dose: "",
    Route: "",
    Frequency: "",
    Instructions: "",
  },
];

export default function OpdTreatmentRecordModal(props) {
  const { open, handleClose, populateTable } = props;
  // const [bloodGroup, setBloodGroup] = useState();

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      services: null,
      Qty: "",
      //
      opdDate: new Date(),
      opdTrtRecTime: new Date(),
      mlcFlag: false,
      allergyHistory: "",
      chiefComplaints: "",
      painScore: "",
      investigationsDoneAdviced: "",
      tretmentGivenInEmergency: "",
      temp: "",
      pulse: "",
      bp: "",
      spo2: "",
      respiration: "",
      bsl: "",
      pews: "",
      gcsScore: "",
      mewsRadio: "",
      rs: "",
      cvs: "",
      cns: "",
      pa: "",
      provisionalDignosis: "",
      otherAdvices: "",
      // mewsScore: "",
    },
  });
  const {
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
    register,
    setError,
  } = methods;

  /////////////////////////////////////////////////////////////////////////////////////

  const [reportingTime, setReportingTime] = React.useState(new Date());
  const [patientData, setPatientData] = React.useState();

  //
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //gcs score related
  const [gcsScore, setGcsScore] = React.useState(0);
  const [mewsScore, setMewsScore] = React.useState(0);
  const [eyeOpeningResponse, setEyeOpeningResponse] = React.useState("0");
  const [verbalResponse, setVerbalResponse] = React.useState("0");
  const [motorResponse, setMotorResponse] = React.useState("0");
  const [GCSData, setGCSData] = React.useState(0);

  //
  const [service, setService] = React.useState([]);
  const [serviceData, setServiceData] = React.useState([]);
  const [doctor, setDoctor] = useState();

  //pain score related
  const [opdTreatmentId, setOpdTreatmentId] = React.useState();
  const [sliderReset, setSliderReset] = React.useState();
  //mlc modal
  const [mlcModalData, setMlcModalData] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  //prescription modal
  const [deletedMedicationData, setDeletedMedicationData] = useState([]);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const handleOpenPrescriptionModal = () => setOpenPrescriptionModal(true);
  const handleClosePrescriptionModal = () => setOpenPrescriptionModal(false);
  const [allMedicationData, setAllMedicationData] = useState([]);
  ///Confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [formData, setFormData] = React.useState();
  ///
  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    // handleClose();
  };
  const handleOpenPrintModal = () => {
    fetchPrintOpdTrtRecord(props.EmergencyPatientId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  ////watch
  let ServiceInfo = watch("services");

  // const StateIsMlc = () => {
  //   setOpenModal(isMlc);
  // };

  // React.useEffect(() => {
  //   if (isMlc) {
  //     StateIsMlc();
  //   }
  // }, [isMlc]);
  ///mewsRadio & score

  var InCaseDeath = watch("inCaseDeath");

  {
    InCaseDeath !== undefined && InCaseDeath !== false
      ? (InCaseDeath = true)
      : (InCaseDeath = false);
  }

  // //
  // const addServiceData = () => {
  //   let services = getValues("services");

  //   if (services !== null) {
  //     let serviceObj = {
  //       serviceId: services.id,
  //       serviceName: services.label,
  //     };

  //     let arr = [...serviceData];
  //     arr.push(serviceObj);
  //     setServiceData(unDuplicateArrayObjects(arr, "serviceId"));
  //     setValue("services", "", { shouldValidate: true });
  //   }
  // };

  const addServiceData = () => {
    let services = getValues("services");
    let doctor = getValues("serviceDoctor");
    let Qty = getValues("Qty");

    if (services && services !== null && Qty !== "") {
      let serviceObj = {
        serviceId: services?.id,
        "Service Name": services?.label,
        serviceccode: services?.value,
        Quantity: Qty,
        DoctorId: doctor !== undefined && doctor !== null ? doctor.id : 0,
        "Doctor Name":
          doctor !== undefined && doctor !== null ? doctor.label : "",
        EmergencyServiceId: null,
      };

      let isItemAlreadyExist = false;
      isItemAlreadyExist = serviceData.some(
        (item) => !item.isDelete && item.serviceId === serviceObj.serviceId
      );

      if (isItemAlreadyExist) {
        warningAlert("Service Already Exists...");
      } else {
        setServiceData((prev) => [...prev, serviceObj]);
        setValue("services", "", { shouldValidate: true });
        setValue("serviceDoctor", null, { shouldValidate: true });
        setValue("Qty", "", { shouldValidate: true });
      }
    }

    if (services === null) {
      setError("services", { type: "custom", message: "Required" });
    }
    if (Qty === "") {
      setError("Qty", { type: "custom", message: "Required" });
    }
  };

  //////pain score/assessment
  function valueText(value) {
    return `${value}°C`;
  }
  const CustomSlider = styled(Slider)({
    color: "#52af77",
    height: 8,
    padding: 7,

    "& .MuiSlider-mark": {
      border: "none",
      color: "gray",
      height: 7,
      // marginLeft: "2px",
    },
    "& .MuiSlider-rail": {
      border: "none",
      backgroundImage:
        "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
    },
    "& .MuiSlider-track": {
      border: "none",
      backgroundImage:
        "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid blue",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "black",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  const sliderMarks = [
    {
      value: 0,
      scaledValue: 0,
    },
    {
      value: 1,
      scaledValue: 1,
    },
    {
      value: 2,
      scaledValue: 2,
    },
    {
      value: 3,
      scaledValue: 3,
    },
    {
      value: 4,
      scaledValue: 4,
    },
    {
      value: 5,
      scaledValue: 5,
    },
    {
      value: 6,
      scaledValue: 6,
    },
    {
      value: 7,
      scaledValue: 7,
    },
    {
      value: 8,
      scaledValue: 8,
    },
    {
      value: 9,
      scaledValue: 9,
    },
    {
      value: 10,
      scaledValue: 10,
    },
  ];

  /////////
  const finalObj = {
    privilege: "",
    menuId: "",
    opdTreatmentId: 0,
    addedBy: 0,
    allergyHistory: "",
    avpu: "",
    bp: "",
    bsl: "",
    cheifComplaints: "",
    cns: "",
    cvs: "",
    date: "",
    emergencyId: 0,
    gcs: "",
    gcsE: "",
    gcsEVal: "",
    gcsM: "",
    gcsMVal: "",
    gcsV: "",
    gcsVEtTube: "",
    gcsVVal: "",
    investigationDoneAdvised: "",
    opdTreatmentServiceDtoList: [],
    mews: "",
    otherAdvice: "",
    pa: "",
    painScore: "",
    pews: "",
    provisionalDiagnosis: "",
    pulse: "",
    resp: "",
    rs: "",
    serviceId: "",
    spo2: "",
    temp: "",
    time: "",
    treatementInEmergency: "",
    mlcRequestDto: {},
  };

  const onSubmit = (data) => {
    setOpenConfirmationModal(true);
    setFormData(data);
  };

  const handleFormSubmit = (data) => {
    setOpenBackdrop(true);
    //   handleClose();
    let token = JSON.parse(localStorage.getItem("userInfo"));
    let userId = token.userId;
    // finalObj.menuId = props.location?.state?.menuId;
    finalObj.menuId = props.menuId;
    finalObj.privilege = props.privilege;
    //
    finalObj.mlcRequestDto = mlcModalData;
    //
    finalObj.opdTreatmentId = opdTreatmentId || 0;
    finalObj.addedBy = userId;
    finalObj.allergyHistory = data.allergyHistory;
    finalObj.avpu = data.mewsRadio;
    finalObj.bp = data.bp;
    finalObj.bsl = data.bsl;
    finalObj.cheifComplaints = data.chiefComplaints;
    finalObj.cns = data.cns;
    finalObj.cvs = data.cvs;
    finalObj.date = data.opdDate;
    finalObj.emergencyId = props.EmergencyPatientId; //
    finalObj.gcs = gcsScore;

    finalObj.gcsE = eyeOpeningResponse > 0 ? 1 : 0;
    finalObj.gcsEVal =
      Number(eyeOpeningResponse) > 0 ? Number(eyeOpeningResponse) : 0;
    finalObj.gcsM = motorResponse > 0 ? 1 : 0;
    finalObj.gcsMVal = Number(motorResponse) > 0 ? Number(motorResponse) : 0;
    finalObj.gcsV = verbalResponse !== "" ? 1 : 0;
    finalObj.gcsVVal = Number(verbalResponse) > 0 ? Number(verbalResponse) : 0;
    finalObj.gcsVEtTube = 0;
    finalObj.investigationDoneAdvised = data.investigationsDoneAdviced;

    ///
    let prescribedMedications = [
      ...allMedicationData,
      ...deletedMedicationData,
    ];

    let opdTreatmentServiceDto = prescribedMedications?.map((item) => {
      return {
        dose: item?.dose || item.Dose,
        frequency: item?.frequency || item["Frequency"],
        instructions: item?.Instruction || item?.Instruction,
        isCurrentMedicine: item?.isCurrentMedicine || item["IsCurrentMedicine"],
        isDelete: item?.isDelete || false,
        itemName: item.itemName || item["Drug"] || item.ItemName,
        opdMedicationId: item?.opdMedicationId || 0,
        route: item?.route || item.Route,
      };
    });

    finalObj.opdTreatmentServiceDtoList = opdTreatmentServiceDto;
    finalObj.mews = mewsScore;
    finalObj.otherAdvice = data.otherAdvices; //
    finalObj.pa = data.pa;
    finalObj.painScore = sliderReset;
    finalObj.pews = data.pews;
    finalObj.provisionalDiagnosis = data.provisionalDignosis;
    finalObj.pulse = data.pulse;
    finalObj.resp = data.respiration;
    finalObj.rs = data.rs;

    var refarr = [];
    for (let i = 0; i < serviceData?.length; i++) {
      let a = serviceData[i].serviceId;
      refarr.push(a);
      let arrToString = refarr.toString(); //conversion arrey to string
      finalObj.serviceId = arrToString;
    }

    let servicesAdded = serviceData?.map((item) => {
      return {
        doctorId: item?.DoctorId || 0,
        quantity: item?.Quantity,
        serviceId: item.serviceId,
        emergencyServiceId: item?.advisedId || 0,
        //advisedId: item?.advisedId || 0,
      };
    });

    finalObj.emergencyServicesDto = servicesAdded;
    // finalObj.serviceId = serviceData; //
    finalObj.spo2 = data.spo2;
    finalObj.temp = data.temp;
    finalObj.time = data.opdTrtRecTime;
    finalObj.treatementInEmergency = data.tretmentGivenInEmergency; //
    finalObj.isMlc = data.mlcFlag || false; //

    saveOPDTreatmentRecord(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          // handleClose();
          methods.reset();
          setServiceData([]);
          setAllMedicationData([]);
          setDeletedMedicationData([]);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          handleOpenPrintModal();
          setOpdTreatmentId(0);
          populateTable();
          patchData();
        }
      })
      .catch((res) => {
        errorAlert(res.response.data.message || res.message);
        setOpenBackdrop(false);
      });
  };

  ///patch data

  const patchData = () => {
    fetchOpdTreatmentDetails(props.EmergencyPatientId)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          setSliderReset(res.result.PainScore);
          setOpdTreatmentId(res.result.OpdTreatmentId);
          setGcsScore(res.result.GCS);

          setValue("allergyHistory", res.result.AllergyHistory);
          setValue(
            "tretmentGivenInEmergency",
            res.result.TreatementInEmergency
          );
          setValue("chiefComplaints", res.result.CheifComplaints);
          // setValue("painScore", res.result.PainScore);
          setValue(
            "investigationsDoneAdviced",
            res.result.InvestigationsDoneAdvised
          );
          setValue("temp", res.result.Temp);
          setValue("pulse", res.result.Pulse);
          setValue("bp", res.result.BP);
          setValue("spo2", res.result.SPO2);
          setValue("respiration", res.result.Resp);
          setValue("bsl", res.result.BSL);
          setValue("pews", res.result.PEWS);
          setValue("gcsScore", res.result.GCS);
          setValue("mewsRadio", res.result.AVPU);
          setValue("rs", res.result.RS);
          setValue("cvs", res.result.CVS);
          setValue("cns", res.result.CNS);
          setValue("pa", res.result.PA);
          setValue("provisionalDignosis", res.result.ProvisionalDiagnosis);
          setValue("otherAdvices", res.result.OtherAdvice);
          // "GCS_E_Val": 3,
          // "GCS_V_Val": 2,
          // "GCS_M_Val": 5,
          setEyeOpeningResponse(
            res.result?.GCS_E_Val ? res.result?.GCS_E_Val : "0"
          );
          setVerbalResponse(
            res.result?.GCS_V_Val ? res.result?.GCS_V_Val : "0"
          );
          setMotorResponse(res.result?.GCS_M_Val ? res.result?.GCS_M_Val : "0");

          // setServiceData(res.result.ServiceInfo || []);
          setAllMedicationData(res.result.ServiceInfo || []);
          setServiceData(res.result.advisecServices || []);
        }
        // //to fetch medication details table data
        // fetchOpdTreatmentMedicationDetails(res.result.OpdTreatmentId)
        //   .then((response) => response.data)
        //   .then((res) => {
        //     // setAllMedicationData(res.result);
        //   });

        // //to fetch medication details table data
        // fetchAdvisedServices(res.result.OpdTreatmentId)
        //   .then((response) => response.data)
        //   .then((res) => {
        //     // setServiceData(res.result);
        //   });
      });
  };

  useEffect(() => {
    getMlcPatientInfo();

    setValue("mlcFlag", props.isMlcPatient);
    patchData();
    //setOpdTreatmentId(0);
  }, []);

  const getMlcPatientInfo = () => {
    props.EmergencyPatientId &&
      fetchMlcPatientInfo(props.EmergencyPatientId, true)
        .then((response) => response.data)
        .then((res) => {
          setPatientData(res.result);
        });
  };

  // Handle Change Use For Service api
  const handleChange = (autoServceSearchString) => {
    if (autoServceSearchString !== "") {
      autoSearchService(autoServceSearchString)
        .then((response) => response.data)
        .then((res) => {
          setValue("Qty", 1);
          setService(res.result);
        })
        .catch(() => {});
    }
  };

  ////////table related
  const renderActions = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...serviceData];
      newTemplateData.splice(index, 1);
      setServiceData(newTemplateData);
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon
              sx={{ color: "#ef4444" }}
              onClick={() => deleteRow(index)}
            />
          </Tooltip>
        </div>
      </>
    );
  };

  const renderActionsMedication = (row, index) => {
    const handleDeleteRow = (row, rowIndex) => {
      let temp = [...allMedicationData];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === rowIndex ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });

      // Update the state with deleted items and allMedicationData
      setDeletedMedicationData((prev) => [...prev, ...deletedItems]);
      setAllMedicationData(notDeletedItems);
    };

    return (
      <>
        <div>
          {allMedicationData.length > 0 ? (
            //&&
            //allMedicationData.filter((item) => !item.isDelete).length
            <DeleteOnIcon
              title={"Delete"}
              onClick={() => handleDeleteRow(row, index)}
            />
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <FormProvider {...methods}>
            <form>
              <div>
                <div className="flex justify-between items-center">
                  <div className="text-black font-bold text-lg pb-1">
                    OPD Treatment Record
                  </div>
                  <div>
                    {opdTreatmentId > 0 && (
                      <Tooltip title="Print OPD Treatment Record">
                        <IconButton onClick={() => handleOpenPrintModal()}>
                          <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="grid w-full ">
                  {/* Populate Patient Info Fields */}
                  <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 py-2">
                      <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                        {/* Patient Name , UHID */}
                        <div className="flex gap-2 ">
                          <h1 className="text-black font-semibold flex space-x-1">
                            <span className="text-sm">Patient Name </span>
                            <span className=""> :</span>
                          </h1>

                          <h1 className="font-normal">
                            {patientData?.PatientName}
                          </h1>
                        </div>
                        {/* Gender */}
                        <div className="flex gap-2 ">
                          <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                            <span className="text-sm">Gender</span>
                            <span className="lg:pl-7 md:pl-6">:</span>
                          </h1>

                          <h1 className="font-normal">{patientData?.Gender}</h1>
                        </div>
                      </div>

                      {/* MRNo */}
                      <div className="grid gap-2 lg:border-r-2 pl-4 border-slate-500 my-1 ">
                        <div className="flex gap-2 ">
                          <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                            <span className="text-sm">MRNo</span>
                            <span className="">:</span>
                          </h1>
                          <h1 className="font-normal">{patientData?.MRNo}</h1>
                        </div>
                      </div>

                      {/* Bed No */}
                      <div className="lg:pl-4">
                        <div className="flex gap-2 ">
                          <h1 className="text-black font-semibold flex space-x-11 lg:space-x-10">
                            <span className="text-sm">Age</span>
                            <span className="md:pl-6">:</span>
                          </h1>
                          <h1 className="font-normal">
                            {patientData?.AgeYear}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-3 pt-4">
                  {/* reporting Date Time*/}
                  <div className=" ">
                    <DatePickerField
                      control={control}
                      name="opdDate"
                      label=" Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("opdDate", newValue);
                      }}
                      disabled={false}
                      disableFuture={true}
                      inputFormat="DD-MM-YYYY"
                    />
                  </div>

                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopTimePicker
                          label="Time"
                          value={reportingTime}
                          onChange={(newValue) => {
                            // let time = newValue.toLocaleTimeString();
                            setReportingTime(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="bg-white"
                              fullWidth
                              name="opdTrtRecTime"
                              size="small"
                              defaultValue=""
                              disabled={true}
                              inputFormat="dd/MM/yyyy"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                style: {
                                  fontSize: "14px",
                                  position: "absolute",
                                  top: "-2px",
                                },
                              }}
                              sx={{
                                svg: {
                                  color: "#1e3a8a",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",

                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    // border: 0,
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",

                                    borderColor: "#0B83A5",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>

                  <div
                    className="col-span-2"
                    onClick={(e) => e.target.checked && setOpenModal(true)}
                  >
                    <CheckBoxField
                      control={control}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      name="mlcFlag"
                      label="Medico Legal Case"
                      // defaultChecked={props.isMlcPatient}
                    />
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3 mt-3">
                  <div className="">
                    {/* //Allergies.// */}

                    <InputArea
                      control={control}
                      name="allergyHistory"
                      label="Allergy History "
                      placeholder="Allergy History"
                    />
                  </div>
                  <div className="">
                    {/* // Chief Complaints.// */}

                    <InputArea
                      control={control}
                      name="chiefComplaints"
                      label=" Chief Complaints "
                      placeholder=" Chief Complaints"
                    />
                  </div>
                  <hr className="border my-1 col-span-2 divide-x-8 bg-slate-300 border-slate-300" />
                  {/* //pain score// */}
                  <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    <h1>Pain Score</h1>
                  </div>
                  <div className="flex space-x-2 w-full  col-span-2">
                    <div className="flex flex-col space-y-1 lg:space-y-2 xl:space-y-4 xl:pt-2 2xl:space-y-8 2xl:pt-12 w-[25%] xl:w-[20%] text-xs xl:text-sm text-gray-600">
                      <h1>Verbal Description Scale</h1>
                      <h1>Wong-Baker Facial Grimace Scale</h1>
                      <h1>Activity Tolerance Scale</h1>
                    </div>

                    <div className="w-[70%]  justify-center">
                      <div className="w-full">
                        <img className="w-full" src={PainAssessmentScale} />
                      </div>
                      <div className="flex w-full justify-start mt-2">
                        <div className="w-[92%] xl:w-[90%] 2xl:w-[91%] ml-3 lg:ml-5 2xl:ml-9">
                          <CustomSlider
                            className="painScore"
                            control={control}
                            {...register("painScore")}
                            name="painScore"
                            onChange={(value) => {
                              // selectedPainScore = value.target.value;
                              setSliderReset(value.target.value);
                            }}
                            aria-label="Custom marks"
                            value={sliderReset !== null ? sliderReset : 0}
                            getAriaValueText={valueText}
                            min={0}
                            step={1}
                            max={10}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            style={{ height: "14px" }}
                            marks={sliderMarks}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="border my-1 col-span-2 divide-x-8 bg-slate-300 border-slate-300" />

                  {/* Investigations Done/Advised */}
                  <div className="col-span-2">
                    <div>
                      <span className=" text-gray-700 font-semibold whitespace-nowrap my-2">
                        Investigations Done/Advised
                      </span>
                    </div>
                    {/* 
                    <div className="grid grid-cols-6 gap-3 ">
                      <div className="col-span-5">
                        <SearchDropdown
                          control={control}
                          name="services"
                          label="Search by Services"
                          placeholder="Search by Service"
                          error={errors.services}
                          searchIcon={true}
                          isSearchable={true}
                          isClearable={true}
                          dataArray={service}
                          handleInputChange={handleChangeSearch}
                          inputRef={{
                            ...register("services", {}),
                          }}
                        />
                      </div>

                      <div>
                        <CommonButton
                          label="Add"
                          type="button"
                          onClick={() => {
                            addServiceData();
                            setValue("services", null);
                          }}
                          className="bg-customGreen text-white"
                        />
                      </div>
                    </div>*/}

                    <div className="">
                      <div className="grid grid-cols-6 gap-3 mt-3">
                        <div className="col-span-2">
                          <SearchDropdown
                            control={control}
                            name="services"
                            label="Search by Services"
                            placeholder="Search by Service"
                            searchIcon={true}
                            isSearchable={true}
                            isClearable={true}
                            dataArray={service}
                            handleInputChange={handleChange}
                            error={errors.services}
                          />
                        </div>

                        <div className="">
                          <InputField
                            name="Qty"
                            type="number"
                            variant="outlined"
                            label="Qty"
                            error={errors.Qty}
                            control={control}
                          />
                        </div>

                        {ServiceInfo && ServiceInfo.DoctorShare ? (
                          <>
                            {/* <SearchBar doctor */}
                            <div className="col-span-2">
                              <SearchDropdown
                                control={control}
                                searchIcon={true}
                                isDisabled={false}
                                isSerchable={true}
                                isClearable={true}
                                error={errors.doctor}
                                name="serviceDoctor"
                                label="Doctor"
                                placeholder="Doctor"
                                isMulti={false}
                                dataArray={doctor}
                                handleInputChange={(e) => {
                                  // Doctor
                                  fetchDoctor(e)
                                    .then((response) => {
                                      setDoctor(response.data.result);
                                    })
                                    .catch(() => {});
                                }}
                              />
                            </div>
                          </>
                        ) : null}

                        <div>
                          <CommonButton
                            label="Add"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              addServiceData();
                              setValue("services", null);
                              setValue("Qty", "");
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full mt-2">
                        {serviceData?.length > 0 && (
                          <CommonDynamicTableNew
                            dataResult={serviceData}
                            renderActions={renderActions}
                            highlightRow={false}
                            removeHeaders={[
                              "serviceId",
                              "DoctorId",
                              "serviceccode",
                              "id",
                              "EmergencyServiceId",
                            ]}
                            tableClass="max-h-72"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <InputArea
                      control={control}
                      name="investigationsDoneAdviced"
                      label="Investigations Done/Advised "
                      placeholder="Investigations Done/Advised"
                    />
                  </div>
                  <div className="">
                    {/* //treatmentGivenInEmergency.// */}

                    <InputArea
                      control={control}
                      name="tretmentGivenInEmergency"
                      label="Treatment Given In Emergency "
                      placeholder="Treatment Given In Emergency"
                    />
                  </div>
                  <hr className="border my-1 col-span-2 divide-x-8 bg-slate-300 border-slate-300" />
                  {/* clinical findings */}
                  <div className="col-span-2">
                    <div>
                      <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                        Clinical Findings
                      </span>
                    </div>
                    <div>
                      <ClinicalFindings
                        setGcsScore={setGcsScore}
                        gcsScore={gcsScore}
                        mewsScore={mewsScore}
                        setMewsScore={setMewsScore}
                        //
                        eyeOpeningResponse={eyeOpeningResponse}
                        setEyeOpeningResponse={setEyeOpeningResponse}
                        verbalResponse={verbalResponse}
                        setVerbalResponse={setVerbalResponse}
                        motorResponse={motorResponse}
                        setMotorResponse={setMotorResponse}
                        setGCSData={setGCSData}
                        GCSData={GCSData}
                        patientData={patientData}
                      />
                    </div>
                  </div>

                  {/* RS */}
                  <div className=" ">
                    <InputArea
                      control={control}
                      name="rs"
                      label="RS "
                      placeholder="RS"
                    />
                  </div>
                  {/* CVS */}
                  <div className=" ">
                    <InputArea
                      control={control}
                      name="cvs"
                      label="CVS "
                      placeholder="CVS"
                    />
                  </div>
                  {/* CNS */}
                  <div className=" ">
                    <InputArea
                      control={control}
                      name="cns"
                      label="CNS "
                      placeholder="CNS"
                    />
                  </div>
                  {/* PA */}
                  <div className=" ">
                    <InputArea
                      control={control}
                      name="pa"
                      label="PA "
                      placeholder="PA"
                    />
                  </div>
                  {/* Provisional Diagnosis */}
                  <div className="col-span-2 ">
                    <InputArea
                      control={control}
                      name="provisionalDignosis"
                      label="Provisional Diagnosis "
                      placeholder="Provisional Diagnosis"
                    />
                  </div>

                  <hr className="border my-1 col-span-2 divide-x-8 bg-slate-300 border-slate-300" />
                  {/* //  Medication Details/Adviced */}
                  <div className="col-span-2">
                    <div>
                      <div className="flex justify-between items-center">
                        <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                          Medication Details/Advised
                        </span>
                        <span className="ml-3">
                          <Tooltip title="Add Medication">
                            <AddCircleIcon
                              sx={{ color: "#1e3a8a" }}
                              onClick={() => {
                                setOpenPrescriptionModal(true);
                              }}
                            />
                          </Tooltip>
                        </span>
                      </div>

                      <div>
                        {/*
                        <MedicationDetails
                          allMedicationData={allMedicationData}
                          setAllMedicationData={setAllMedicationData}
                          />*/}

                        <CommonDynamicTableNew
                          dataResult={
                            allMedicationData.length > 0 &&
                            allMedicationData.filter((item) => !item.isDelete)
                              .length > 0
                              ? allMedicationData.filter(
                                  (item) => !item.isDelete
                                )
                              : medicationHeaders
                          }
                          setAllMedicationData={setAllMedicationData}
                          renderActions={renderActionsMedication}
                          removeHeaders={[
                            "ptMedicationId",
                            "isDelete",
                            "opdMedicationId",
                          ]}
                          tableClass="h-52"
                          highlightRow={false}
                        />
                      </div>

                      {/* PT belonging Documents */}
                      <div className="my-3 ">
                        <InputArea
                          control={control}
                          name="otherAdvices"
                          label="Other Advices "
                          placeholder="Other Advices"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-span-4 flex justify-end ">
                  <div className="col-span-2 flex justify-end gap-3">
                    {Actions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "new" ? (
                          <>
                            <CommonButton
                              label="Reset"
                              onClick={() => {
                                methods.reset();
                                setAllMedicationData([]);
                                setServiceData([]);
                                setSliderReset(0);
                                setGcsScore(0);
                                setEyeOpeningResponse(0);
                                setVerbalResponse(0);
                                setMotorResponse(0);
                              }}
                              className="border border-customRed text-customRed"
                            />

                            <CommonButton
                              label="Save"
                              onClick={methods.handleSubmit(onSubmit)}
                              className="bg-customGreen text-white"
                            />
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
                {openPrescriptionModal ? (
                  <MedicationPrescriptionModal
                    allMedicationData={allMedicationData}
                    setAllMedicationData={setAllMedicationData}
                    open={openPrescriptionModal}
                    setOpen={setOpenPrescriptionModal}
                    handleOpen={handleOpenPrescriptionModal}
                    handleClose={handleClosePrescriptionModal}
                    setDeletedMedicationData={setDeletedMedicationData}
                  />
                ) : null}
              </div>
            </form>
          </FormProvider>
        </Box>
      </Modal>

      {/* mlc Modal */}
      {openModal ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setMlcModalData={setMlcModalData}
          patientName={patientData?.PatientName}
          gender={patientData?.Gender}
          age={patientData?.AgeYear}
          mrNo={patientData?.MRNo}
          //isEdit={false}
          MLCNo={null}
          //
          admissionId={props.admissionId || 0}
          emergencyId={props.EmergencyPatientId}
          opdIpd={0}
          isFromForm={true}
          //
          //patientId={props.EmergencyPatientId}
          // isEmergency={true}
          //
          ipdEmergency={2}
        />
      ) : null}

      {/* Print Modal */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(formData)}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
