import apiClient from "../../../../http-common";

import authHeader from "../../../../authentication/authservices/auth-header";

export const saveConfirmedAppointmentList = (postObj) => {
  return apiClient.post(`/phoneAppointment/confirmAppointment`, postObj, {
    headers: authHeader(),
  });
};

export const getReferralType = () => {
  return apiClient.get(`/misc/referralEntity`, {
    headers: authHeader(),
  });
};

export const getDoctorDropdown = (departmentId, unitId) => {
  return apiClient.get(
    `/phoneAppointment/doctorList/${departmentId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getReferenceDoctor = (searchString) => {
  return apiClient.get(`/misc/referenceDoctor/${searchString}`, {
    headers: authHeader(),
  });
};

export const getDoctorList = (searchString) => {
  return apiClient.get(`/misc/doctor/${searchString}`, {
    headers: authHeader(),
  });
};

export const getDoctorListByDepartmentId = (departmentId) => {
  return apiClient.get(`/phoneAppointment/doctorList/${departmentId}`, {
    headers: authHeader(),
  });
};

export const getPatientInfo = (patientSearchString) => {
  return apiClient.get(`/patientInfo/${patientSearchString}`, {
    headers: authHeader(),
  });
};

export const getPatientInfoById = (patientId) => {
  return apiClient.get(`/patientInfo/appt/${patientId}`, {
    headers: authHeader(),
  });
};

export const getDeparmentList = (departmentSearchString) => {
  return apiClient.get(`/misc/department/${departmentSearchString}`, {
    headers: authHeader(),
  });
};

//function to post the appointment data
export const postAppointmentData = (postObj) => {
  return apiClient.post(`/phoneAppointment/appointment`, postObj, {
    headers: authHeader(),
  });
};

//function to get the appointment list
export const getAppointmentList = (postObj) => {
  return apiClient.post(`/phoneAppointment/appointmentList`, postObj, {
    headers: authHeader(),
  });
};

export const getBookedSlotsList = (bookedSlotObj) => {
  return apiClient.post(`/phoneAppointment/bookedSlotsList`, bookedSlotObj, {
    headers: authHeader(),
  });
};

//function to search the appointed patients
export const searchAppointedPatients = (appointedPatientObj) => {
  return apiClient.post(
    `/phoneAppointment/appointmentListAutoComplete`,
    appointedPatientObj,
    {
      headers: authHeader(),
    }
  );
};

export const getDoctorTimeSlotsByDepartmentIdAndUnitId = (
  appointmentDate,
  departmentId,
  unitId
) => {
  return apiClient.get(
    `/phoneAppointment/timeSlots?date=${appointmentDate}&departmentId=${departmentId}&unitId=${unitId}`,
    {
      headers: authHeader(),
    }
  );
};
