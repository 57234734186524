import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Pic from "../../assets/loginAssets/BGImageNew.jpg";
import hospitalLogo from "../../assets/loginAssets/hospitalLogo.png";

// import "./style.css";

import { Box, Modal } from "@mui/material";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import { successAlert } from "../../common/components/Toasts/CustomToasts";
import {
  authenticateUserLogin,
  fetchCashCounterData,
  fetchPostData,
} from "../../login/services/LoginServices.js.js";

const schema = yup.object().shape({
  loginId: yup.string().max(80).required("Login is required"),
  password: yup.string().required("Password is required"),
  // unitId: yup.string().required("Required"),
  // cashCounterId: yup.string().required("Required"),
});

export default function TimoutLoginModal(props) {
  const [unit, setUnit] = useState([]);
  const [loginIdValue, setLoginIdValue] = useState("");
  const [unitIdValue, setUnitIdValue] = useState("");
  const [cashCounter, setCashCounter] = useState([]);
  const [err, setErr] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  var token = JSON.parse(localStorage.getItem("userInfo"));

  //// To collect values from input field////
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  ////get data on submit///

  const onSubmit = (data, event) => {
    console.log(getValues("cashCounterId"));
    console.log(data);
    setOpenBackdrop(true);
    authenticateUserLogin(data)
      .then((response) => response.data)
      .then((res) => {
        console.log("The response is ", res);
        setErr(false);
        props.handleClose();
        setOpenBackdrop(false);
        successAlert(res.message);
      })
      .catch((error) => {
        setErr(true);
        setOpenBackdrop(false);
      });
  };
  //

  const CancelLogin = (e) => {
    e.preventDefault();
    reset();
  };

  ///to get value entered in login //////
  const unitFunction = (e, key) => {
    if (e.target.value !== "") {
      clearErrors(["loginId"]);
    } else if (e.target.value === "") {
      setError("loginId", { type: "custom", message: "Login is required" });
    }

    const entry = e.target.value;
    console.log(entry);
    setLoginIdValue(entry);
  };
  const SelectedUnit = (e) => {
    console.log("The selected unit is ", e.target.value);

    if (e.target.value !== "") {
      clearErrors(["unitId"]);
    } else if (e.target.value === "") {
      setError("loginId", { type: "custom", message: "Required" });
    }
    const selection = e.target.value;
    // console.log("selected unit value", selection);
    setUnitIdValue(selection);
    console.log("setunitid @ login page", unitIdValue);
  };

  /////to show unit dropdown ./////

  useEffect(() => {
    loginIdValue === ""
      ? setUnit([])
      : fetchPostData(loginIdValue)
          .then((response) => response.data)
          .then((res) => {
            //console.log("unit dropdown called", res);
            setUnit(res.result);
            if (res.result.length < 2) {
              setUnitIdValue(res.result[0].id);
              setValue("unitId", res.result[0].id);
            } else {
              setUnitIdValue(res.result[0].id);
            }
          });
  }, [loginIdValue]);
  /////to show cash Counter Dropdown ////
  useEffect(() => {
    if (unitIdValue === "" || loginIdValue === "") {
      setCashCounter([]);
    } else if (unitIdValue !== "") {
      fetchCashCounterData(loginIdValue, unitIdValue)
        .then((response) => response.data)
        .then((res) => {
          // console.log("CashCounter dropdown called", res);
          clearErrors(["cashCounterId"]);
          let cashCounterArr = res.result;
          // let firstObj = { value: "", label: "" };
          // cashCounterArr.unshift(firstObj);
          console.log("cashCounterArr is ", cashCounterArr);
          setCashCounter(res.result);
          if (res.result.length < 2) {
            setValue("cashCounterId", res.result[0].id);
          }
        });
    }
  }, [unitIdValue, loginIdValue]);

  useEffect(() => {
    let cashCounterVal = getValues("cashCounterId");
    console.log("cashCounterVal is ", cashCounterVal);
  }, [loginIdValue, unitIdValue, cashCounter]);

  useEffect(() => {
    setValue("loginId", token.loginName);
    setValue("cashCounterId", token.cashCounterId);
    setValue("unitId", token.unitId);
  }, []);

  useEffect(() => {
    // Focus on loginId input field on component mount
    !!document.getElementById("passIdRef") &&
      document.getElementById("passIdRef").focus();
  }, []);

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // maxHeight: "90%",
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: 2,
          overflowY: "auto",
          border: "none",
          "&:focus": {
            outline: "none",
          },
        }}
        className="max-h-[88%] xl:max-h-[100%]"
      >
        {/*<CancelPresentationIconButton
          onClick={() => {
            props.handleClose();
          }}
        />*/}

        <div className=" w-full h-screen overflow-hidden">
          <div
            className="bg-cover  w-full h-full overflow-hidden bg-no-repeat  inline-flex md:items-center md:justify-center lg:items-start lg:justify-start lg:flex-col-reverse"
            style={{ backgroundImage: `url(${Pic})` }}
          >
            <div className="grid lg:grid-cols-2 overflow-hidden">
              <div>
                <div className="logo flex justify-center py-1 md:pb-5 lg:pb-5 lg:ml-28 xl:ml-20 2xl:ml-32 2xl:pb-16 overflow-hidden">
                  <img
                    className="h-24 lg:16 2xl:h-28 rounded-sm hover:cursor-pointer "
                    src={hospitalLogo}
                    alt="Hospital Logo"
                  />
                </div>
                <div className="w-[30rem] lg:h-[28rem] 2xl:w-[32rem] md:h-[34.25rem] 2xl:h-[38rem] bg-sky-600 mx-auto lg:ml-[9rem]  pt-5 justify-center overflow-hidden  ">
                  <h1 className="text-center text-2xl font-bold text-white">
                    LOGIN
                  </h1>
                  <form
                    className="text-right mr-14 space-y-10 text-white text-sm font-medium"
                    // onSubmit={handleSubmit(onSubmit)}
                  >
                    <label className="mt-6 text-sm ">
                      Login :
                      <input
                        disabled={true}
                        id="loginIdRef"
                        className="mt-6 ml-3 bg-gray-400 w-64 text-base pb-1 pl-2 text-black"
                        type="text"
                        {...register("loginId")}
                        onChange={unitFunction}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            document.getElementById("passIdRef").focus();
                          }
                        }}
                      />
                      <p className="text-red-600 mb-0 ">
                        {errors.loginId?.message}
                      </p>
                    </label>
                    <br />
                    {/*/////  Password/////*/}
                    <label className="mt-6">
                      Password :
                      <input
                        id="passIdRef"
                        className="mt-1 ml-3  bg-sky-300 w-64 pl-2 text-base pb-1  text-black"
                        type="password"
                        {...register("password")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (cashCounter.length > 1) {
                              e.preventDefault();
                              document.getElementById("cashCounterRef").focus();
                            } else {
                              handleSubmit(onSubmit)();
                            }
                          }
                        }}
                      />
                      <p className="text-red-600 mb-0">
                        {errors.password?.message}
                      </p>
                    </label>
                    <br />
                    {/*/////  unit  dropDown /////*/}
                    {unit.length > 1 ? (
                      <>
                        <label className="mt-1">
                          Units :
                          <select
                            className="mt-1 ml-3 pl-2  bg-sky-300 w-64 text-sm py-1  text-black"
                            {...register("unitId")}
                            onChange={SelectedUnit}
                          >
                            {unit.map((unit) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.label}
                              </option>
                            ))}
                          </select>
                          <p className="text-red-600 mb-0">
                            {errors.unitId?.message}
                          </p>
                        </label>
                        <br />
                      </>
                    ) : null}
                    {/*//// cashCounter  dropDown ////*/}
                    {cashCounter.length > 1 ? (
                      <>
                        <label className="mt-1">
                          Cash Counter :
                          <select
                            className="mt-1 ml-3 pl-2  bg-sky-300 w-64 text-sm py-1  text-black"
                            {...register("cashCounterId")}
                            id="cashCounterRef"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSubmit(onSubmit)();
                              }
                            }}
                          >
                            {cashCounter.map((counter, index) => (
                              <option key={index} value={counter.id}>
                                {counter.label}
                              </option>
                            ))}
                          </select>
                          <p className="text-red-600 mb-0">
                            {errors.cashCounterId?.message}
                          </p>
                        </label>
                      </>
                    ) : null}
                    {err === true ? (
                      <p className="text-red-600 mb-0">
                        Invalid Login Credintials
                      </p>
                    ) : null}
                    {/* <br />
                  <br /> */}

                    <button
                      // type="submit"
                      className="bg-slate-100 text-black w-24 mr-4 h-6 rounded-lg capital uppercase text-sm "
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                      }}
                    >
                      ok
                    </button>
                    {/*<button
                      className="bg-slate-100 text-black w-24 h-6 rounded-lg uppercase text-sm "
                      onClick={CancelLogin}
                    >
                      Cancel
                    </button>*/}
                    <button
                      className="  w-24 h-6 rounded-lg uppercase text-sm ml-2 border border-red-500 text-red-500 bg-slate-100"
                      onClick={() => {
                        props.handleLogoutModalSubmit();
                        props.handleClose();
                      }}
                    >
                      Logout
                    </button>
                  </form>
                  <div className="justify-center">
                    <div className="w-72 h-2 bg-slate-200 mt-36 lg:mt-16 2xl:mt-56 mx-auto"></div>
                    <div className="w-96 h-2 bg-slate-200 mt-2  mx-auto"></div>
                    <div className="w-80 h-2 bg-slate-200 mt-2 mx-auto mb-4"></div>
                  </div>
                </div>
              </div>
              <div className="flex  lg:justify-start lg:items-end  xl:mb-5  md:pt-2 lg:mt-5 xl:pt-2 lg:ml-10">
                <div className="">
                  <div className="text-[#d9616d] lg:text-white font-semibold text-[14px] drop-shadow-lg ">
                    Designed And Developed By,
                  </div>
                  <div className="text-[#d9616d] lg:text-white font-bold h-11 text-xl lg:text-2xl xl:mt-[-5px]  drop-shadow-xl shadow-red-500">
                    AppyStack Private Ltd.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommonBackDrop openBackdrop={openBackdrop} />
      </Box>
    </Modal>
  );
}
