import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllSugarLevel = (admissionId) => {
  return apiClient.get(
    `/clinicalCareChart/sugarLevel/${admissionId}`,
    
    {
      headers: authHeader(),
    }
  );
};

export const saveSugerLevel = async (addNewSugerLevel) => {
  return await apiClient.post(
    `/clinicalCareChart/saveSugarLevel`,
    addNewSugerLevel,
    
    {
      headers: authHeader(),
    }
  );
};

export const sugerLevelPrintData = (postObj) => {
  return apiClient.post(`/reports/nursing/cccSugarLevel`, postObj, {
   headers: authHeader(),
  });
};
