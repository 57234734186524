import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// /api/bedTransfer/dischargedBedList
export const fetchAllDischargeList = (menuId) => {
  return apiClient.get(`/bedTransfer/dischargedBedList/${menuId}`, {
    headers: authHeader(),
  });
};

// /api/bedTransfer/releaseBed
export const releaseBed = (releaseBedObj) => {
  return apiClient.post(`/bedTransfer/releaseBed`, releaseBedObj, {
    headers: authHeader(),
  });
};
