import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CustomInput from "../../../common/components/FormFields/CustomInput";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
//import PreviousReportsList from "./PreviousReportsList";

const lifeSupportArr = [
  {
    id: 0,
    label: "Basic",
    value: "Basic",
  },

  { id: 1, label: "Advanced", value: "Advanced" },
];

const ptType = [
  {
    id: 0,
    label: "Pediatric",
    value: "Pediatric",
  },

  { id: 1, label: "Adult", value: "Adult" },
];

const RadiologyMriCTArr = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },

  { id: 0, label: "No", value: "No" },
];

const YellowForm = () => {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const isCprGivenChecked = watch("cprGiven", false);
  const isRedBands = watch("redBands", false);
  const isYellowBands = watch("yellowBands", false);
  const isOrangeBands = watch("orangeBands", false);
  const isGreenBands = watch("greenBands", false);

  return (
    <div className=" mb-2">
      {/* code orange form */}
      <div>
        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3">
          <div className="grid col-span-2 grid-cols-2 gap-3">
            {/* from Date */}
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="codeDateYellow"
                label="Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>

            <div className="">
              <Controller
                control={control}
                name="codeTimeYellow"
                render={({ field: { value, onChange } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      label="Time"
                      value={value}
                      onChange={onChange}
                      disabled={false}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          // error={errors.codeTime}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: "14px",
                              position: "absolute",
                              top: "-2px",
                            },
                          }}
                          sx={{
                            svg: {
                              color: "#1e3a8a",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                // border: 0,
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>
          {/* <div className="">
            <InputField
              name="codeYellowNumber"
              label="Code Yellow Number"
              variant="outlined"
              error={errors.codeYellowNumber}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div> */}
          <div className="">
            <InputField
              name="callerName"
              label="Name Of The Caller"
              variant="outlined"
              // error={errors.callerName}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="callerLocation"
              label="Location Of The Caller"
              variant="outlined"
              // error={errors.callerLocation}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="operatorName"
              label="Name Of The Operator"
              variant="outlined"
              // error={errors.operatorName}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="massCasNoOfPts"
              label="Mass Casulty (Number Of Patients)"
              variant="outlined"
              // error={errors.massCasNoOfPts}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="yellowCodeTeamArrivalTime"
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    label="Time Of Code Team Arrival"
                    value={value}
                    onChange={onChange}
                    disabled={false}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        // error={errors.codeTime}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                            position: "absolute",
                            top: "-2px",
                          },
                        }}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              width: "100%",

                              borderColor: "  ",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="medicalEmergency"
              label="Medical Emergency"
            />
          </div>
          <div className="flex gap-3 items-center col-span-2">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Patient Type :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="patientType"
                control={control}
                dataArray={ptType}
              />
            </div>
          </div>
        </div>
        <div className="my-2">
          <InputArea
            control={control}
            name="yellowShortSummary"
            label="Short Summary Of Case"
            placeholder="Short Summary Of Case"
            //error={errors.cause}
          />
        </div>

        <hr className="my-3 bg-red-500" />

        <p className="mr-3 my-2 font-bold tracking-wide text-sm font-Poppins">
          Indications For Code Yellow
        </p>

        <div className="flex gap-x-10 flex-wrap items-center">
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Was CPR Given :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="cprGiven"
                control={control}
                dataArray={RadiologyMriCTArr}
              />
            </div>
            {Number(isCprGivenChecked) === 1 && (
              <div>
                <InputField
                  name="cprGivenCount"
                  label="Patient count"
                  variant="outlined"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            )}
          </div>
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Life Support Given :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="lifeSupport"
                control={control}
                dataArray={lifeSupportArr}
              />
            </div>
          </div>
        </div>

        <div>
          <p className="mr-3 mt-2 font-bold tracking-wide text-sm font-Poppins">
            Triage Bonds
          </p>
          <div className="flex flex-wrap gap-x-3 ">
            <div className="flex items-center">
              <div className="">
                <CheckBoxField
                  control={control}
                  name="redBands"
                  label="Red Bands"
                />
              </div>
              <div className="w-16">
                <CustomInput
                  name="redBandsDtls"
                  error={errors.redBandsDtls}
                  type="number"
                  variant="outlined"
                  control={control}
                  disabled={!isRedBands}
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="">
                <CheckBoxField
                  control={control}
                  name="yellowBands"
                  label="Yellow Bands"
                />
              </div>
              <div className="w-16">
                <CustomInput
                  name="yellowBandsDtls"
                  error={errors.yellowBandsDtls}
                  type="number"
                  variant="outlined"
                  control={control}
                  disabled={!isYellowBands}
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="">
                <CheckBoxField
                  control={control}
                  name="orangeBands"
                  label="Black Bands"
                />
              </div>
              <div className="w-16">
                <CustomInput
                  name="orangeBandsDtls"
                  error={errors.orangeBandsDtls}
                  type="number"
                  variant="outlined"
                  control={control}
                  disabled={!isOrangeBands}
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="">
                <CheckBoxField
                  control={control}
                  name="greenBands"
                  label="Green Bands"
                />
              </div>
              <div className="w-16">
                <CustomInput
                  name="greenBandsDtls"
                  error={errors.greenBandsDtls}
                  type="number"
                  variant="outlined"
                  control={control}
                  disabled={!isGreenBands}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="mr-3 my-3 font-bold tracking-wide text-sm font-Poppins">
            Current Location Of Patients
          </p>
          <div className="flex flex-wrap gap-x-10">
            <div className="flex items-center">
              <p className="mr-3 my-3 tracking-wide text-sm font-Poppins">
                No. Of Patients Shifted To Icu :
              </p>
              <div className="w-16">
                <CustomInput
                  name="ptShiftedToIcu"
                  variant="outlined"
                  type="number"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="flex items-center">
              <p className="mr-3 my-3 tracking-wide text-sm font-Poppins">
                No. Of Patients Stabilized In Ward :
              </p>
              <div className="w-16">
                <CustomInput
                  name="ptStabilizedInWard"
                  variant="outlined"
                  type="number"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="flex items-center">
              <p className="mr-3 my-3 tracking-wide text-sm font-Poppins">
                No. Of Patients Admitted :
              </p>
              <div className="w-16">
                <CustomInput
                  name="ptAdmitted"
                  variant="outlined"
                  type="number"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="flex items-center">
              <p className="mr-3 my-3 tracking-wide text-sm font-Poppins">
                No. Of Patients Transfered To Other Hospital :
              </p>
              <div className="w-16">
                <CustomInput
                  name="ptTransferedToOtherHospi"
                  variant="outlined"
                  type="number"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="flex items-center">
              <p className="mr-3 my-3 tracking-wide text-sm font-Poppins">
                No. Of Patients Treated On OPD :
              </p>
              <div className="w-16">
                <CustomInput
                  name="ptTreatedInOpd"
                  variant="outlined"
                  type="number"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="flex items-center">
              <p className="mr-3 my-3 tracking-wide text-sm font-Poppins">
                No. Of Patients Expired :
              </p>
              <div className="w-16">
                <CustomInput
                  name="ptExpired"
                  variant="outlined"
                  type="number"
                  // error={errors.massCasNoOfPts}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <InputArea
            control={control}
            name="anyDifficultiesToCY"
            label="Any difficulties encountered while attending to Code Yellow"
            placeholder="Any difficulties encountered while attending to Code Yellow"
            //error={errors.cause}
          />
        </div>

        <div className="mt-3">
          <InputArea
            control={control}
            name="yellowOverallComments"
            label="Overall comments"
            placeholder="Overall comments"
            //error={errors.cause}
          />
        </div>

        {/* <div className="gap-3 my-3 flex justify-end">
            <CommonButton
              label="Reset"
              className="border border-customRed text-customRed"
              //onClick={() => handleClose()}
            />
            <CommonButton
              label="Save"
              className="bg-customGreen text-white"
             // onClick={methods.handleSubmit(onSubmit)}
            />
          </div> */}
      </div>
    </div>
  );
};

export default YellowForm;
