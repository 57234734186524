import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getIVFlowList,
  saveIsCancel,
  saveIsEnd,
  saveIsHold,
  saveIsStart,
  saveIsStop,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import IVFlowTable from "./common/IVFlowTable";
import { format } from "date-fns";

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

function IVFlowModal(props) {
  //the object to reset the form to blank values
  const defaultValues = {
    id: "",
    ivFluid: null,
    volume: "",
    rateOfInfusion: "",
    quantity: "",
    selectDrug: null,
    dose: "",
    instruction: "",
  };
  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const { reset } = useForm({
    mode: "onChange",
    defaultValues,
  });
  let localStorageValue = JSON.parse(localStorage.getItem("userInfo"));
  const [ivFlowData, setIvFlowData] = useState([]); // table data
  const [ivId, setIvId] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null);
  const [status, setStatus] = useState(null);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleCloseConfirmationModal = () => {
    if (openConfirmationModal) {
      setOpenConfirmationModal(false);
    }
  };

  const populateTable = () => {
    getIVFlowList(props.rowData?.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setIvFlowData(res.result);
      });
  };

  useEffect(() => {
    if (selectedFieldName === "hold") {
      setConfirmationMsg(
        "Do You Really Want To Hold This IV Drug Details...??"
      );
    } else if (selectedFieldName === "stop") {
      setConfirmationMsg("Do You Really Want To Stop This IV  Details...??");
    } else if (selectedFieldName === "cancel") {
      setConfirmationMsg("Do You Really Want To Cancel This IV  Details...??");
    } else if (selectedFieldName === "start") {
      setConfirmationMsg("Do You Really Want To Start IV  Drug...!!!");
    } else if (selectedFieldName === "end") {
      setConfirmationMsg("Do You Really Want To End IV  Drug...!!!");
    }
  }, [selectedFieldName]);

  const addRecord = () => {
    if (selectedFieldName === "hold") {
      props.setOpenLoader(true);
      saveIsHold(
        status,
        ivId,
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
        props.privilege
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenConfirmationModal(false);
            setSelectedFieldName(null);
            populateTable();
            setStatus(null);
            setIvId(null);
            props.setOpenLoader(false);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          props.setOpenLoader(false);
        });

      setIvId(null);
      setSelectedFieldName(null);
    } else if (selectedFieldName === "stop") {
      props.setOpenLoader(true);
      saveIsStop(
        status,
        ivId,
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
        props.privilege
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenConfirmationModal(false);
            setSelectedFieldName(null);
            populateTable();
            setStatus(null);
            setIvId(null);
            props.setOpenLoader(false);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          props.setOpenLoader(false);
        });

      setIvId(null);
      setSelectedFieldName(null);
    } else if (selectedFieldName === "cancel") {
      props.setOpenLoader(true);
      saveIsCancel(
        status,
        ivId,
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
        props.privilege
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenConfirmationModal(false);
            setSelectedFieldName(null);
            populateTable();
            setStatus(null);
            setIvId(null);
            props.setOpenLoader(false);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          props.setOpenLoader(false);
        });

      setIvId(null);
      setSelectedFieldName(null);
    } else if (selectedFieldName === "start") {
      props.setOpenLoader(true);
      saveIsStart(
        status,
        ivId,
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
        props.privilege
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenConfirmationModal(false);
            setSelectedFieldName(null);
            populateTable();
            setStatus(null);
            setIvId(null);
            props.setOpenLoader(false);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          props.setOpenLoader(false);
        });

      setIvId(null);
      setSelectedFieldName(null);
    } else if (selectedFieldName === "end") {
      props.setOpenLoader(true);
      saveIsEnd(
        status,
        ivId,
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
        props.privilege
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenConfirmationModal(false);
            setSelectedFieldName(null);
            populateTable();
            setStatus(null);
            setIvId(null);
            props.setOpenLoader(false);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          props.setOpenLoader(false);
        });

      setIvId(null);
      setSelectedFieldName(null);
    }
  };

  useEffect(() => {
    populateTable();
  }, [props.rowData]);
  console.log("propsUsers", props);

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[80%] w-[85%] overflow-y-auto">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              reset(defaultValues);
            }}
          />

          <h1 className="text-lg font-semibold my-2 flex justify-center">
            Intravenous Infusion Chart
          </h1>
          {ivFlowData.length > 0 ? (
            <IVFlowTable
              data={ivFlowData}
              setData={setIvFlowData}
              populateTable={populateTable}
              setIvId={setIvId}
              setStatus={setStatus}
              setSelectedFieldName={setSelectedFieldName}
              setOpenConfirmationModal={setOpenConfirmationModal}
              userActions={props.userActions}
              menuId={props.menuId}
              privilege={props.privilege}
              setOpenLoader={props.setOpenLoader}
              openConfirmationModal={openConfirmationModal}
            />
          ) : (
            <h3 className="text-center font-bold text-gray-600 py-28">
              No Record Found
              <span className="tracking-widest animate-pulse">...</span>
            </h3>
          )}
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg={confirmationMsg}
        confirmationButtonMsg="Yes"
      />
    </div>
  );
}
export default React.memo(IVFlowModal);
