import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import InputField from "../../common/components/FormFields/InputField";

import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { successAlert } from "../../common/components/Toasts/Toasts";

import { DeleteOnIcon, EditOnIcon } from "../../assets/icons/CustomIcons";
import CommonButton from "../../common/components/Buttons/CommonButton";
import CommonMasterPaginationTable from "../../common/components/CommonTable/CommonMasterPaginationTable";
import {
  createNewPrivilege,
  deletePrivilegeById,
  fetchAllPreviousPrivilege,
  getAllSystemMenu,
} from "../Services/privilegetofunctionalityServices/PrivilegeToFunctionalityServices";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "75%",
  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};

// Dynamic Actions
const userActions = [
  {
    id: 0,
    permission: "Delete",
    isAction: true,
  },
  {
    id: 1,
    permission: "Edit",
    isAction: true,
  },
  {
    id: 2,
    permission: "Update",
    isAction: false,
  },
];
export default function AddNewPrivilegeToFunctinality(props) {
  /*************** useform schema and destrecutring *****************/
  const schema = yup.object().shape({
    newPrivilege: yup.string().required("Required"),
  });

  const defaultValues = {
    newPrivilege: "",
    linkWith: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  /***************state variables *****************/
  const [openPost, setOpenPost] = React.useState(false);
  const [privilegeConfirmation, setPrivilegeConfirmation] = React.useState(
    false
  );
  const [finalData, setFinalData] = React.useState();
  const [openPut, setOpenPut] = React.useState(false);
  const [subMenuData, setSubMenuData] = React.useState({
    result: [],
    actions: [],
  });
  const [edit, setEdit] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [previousPrivilege, setPreviousPrivilege] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [deletePrivilegeId, setDeletePrivilegeId] = React.useState("");
  const [dropdownOptions, setDropdownOptions] = React.useState([]);
  const [rowId, setRowId] = React.useState(null);
  let newPrivilegeVal = watch("newPrivilege");
  let linkWithVal = watch("linkWith");

  /*************** useEffects *****************/
  React.useEffect(() => {
    previousPrivilegeList(page, rowsPerPage);
  }, []);

  /*************** functions *****************/
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const handleClosePut = () => {
    if (openPut) {
      setOpenPut(false);
    }
  };
  const handleOpenPrivilegeDeleteConfirmation = () =>
    setPrivilegeConfirmation(true);
  const handleClosePrivilegeDeleteConfirmation = () =>
    setPrivilegeConfirmation(false);

  function getSystemMenuDropdownOptions(searchString) {
    if (searchString !== undefined && searchString !== "") {
      getAllSystemMenu(searchString)
        .then((response) => response.data)
        .then((res) => {
          setDropdownOptions(res.result);
        });
    }
  }
  function previousPrivilegeList(forPagination) {
    fetchAllPreviousPrivilege(page, rowsPerPage)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setPreviousPrivilege((prevData) => [...prevData, ...res.result]);
        } else {
          setPreviousPrivilege(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {});
  }

  function addSubMenu() {
    let tempArr = [...subMenuData?.result];
    let obj = {
      Privilege: newPrivilegeVal,
      functionalityId: linkWithVal?.id !== null ? linkWithVal?.id : null,
    };
    tempArr.push(obj);
    // let tableData = { result: tempArr, actions: ["Edit", "Delete"] }

    setPreviousPrivilege(tempArr);
    setValue("submenu", "");
  }

  const onSubmitDataHandler = (data) => {
    let obj = {};
    if (edit === true && rowId !== null) {
      obj = {
        id: rowId?.id,
        privilege: data?.newPrivilege,
        functionalityId: linkWithVal?.id !== null ? linkWithVal?.id : null,
      };
    } else if (edit === false) {
      obj = {
        id: 0,
        privilege: data?.newPrivilege,
        functionalityId: linkWithVal?.id !== null ? linkWithVal?.id : null,
      };
    }

    setOpenPost(true);
    setFinalData(obj);
  };

  function addRecord() {
    createNewPrivilege(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.mesaage);
        previousPrivilegeList();
        setOpenPost(false);
        props?.handleClose();
        setValue("newPrivilege", "");
        setValue("linkWith", null);
      });
  }

  function deletePrivilegeRow(rowId) {
    handleOpenPrivilegeDeleteConfirmation();
    setDeletePrivilegeId(rowId);
  }

  function deleteRecord() {
    handleClosePrivilegeDeleteConfirmation();

    deletePrivilegeById(deletePrivilegeId)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        previousPrivilegeList();
      });
  }

  function editRow(row) {
    //   {
    //     "functionality_id": 183,
    //     "id": 161,
    //     "active": true,
    //     "Privilege": "test",
    //     "functionalityName": "Misc. Services"
    // }

    setValue("newPrivilege", row?.Privilege);
    setValue("linkWith", {
      id: row?.functionality_id,
      value: row?.functionality_id,
      label: row?.functionalityName,
    });
  }

  function renderActions(row, index, header) {
    return (
      <>
        <div className="flex gap-0 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction === true ? (
                <div className="flex gap-2 cursor-pointer">
                  {actions.permission === "Edit" ? (
                    <EditOnIcon
                      type="button"
                      sx={{ padding: 0 }}
                      onClick={() => {
                        setEdit(true);
                        setRowId(row);
                        editRow(row);
                      }}
                    />
                  ) : null}
                </div>
              ) : null}

              {actions.isAction === true ? (
                <div className="flex gap-2 cursor-pointer">
                  {actions.permission === "Delete" ? (
                    <DeleteOnIcon
                      type="button"
                      sx={{ padding: 0 }}
                      onClick={() => {
                        deletePrivilegeRow(row?.id);
                        setPrivilegeConfirmation(true);
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  }

  console.log("userActions", userActions);

  return (
    <>
      {/* Model and table name start */}
      <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4 p-2">
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleClose();
                  setSubMenuData({ result: [], actions: [] });
                  reset();
                }}
              />
            </div>
            <div className="row">
              <h1 className="px-2 font-semibold">Create New Privileges</h1>
              <form
                onSubmit={handleSubmit(onSubmitDataHandler)}
                className="grid grid-cols-2 md:grid-cols-1 gap-2 md:px-2"
              >
                <div className="py-2 lg:flex md:grid-cols-2 lg:grid-cols-4 gap-3">
                  <div className="w-[400px]">
                    <InputField
                      name="newPrivilege"
                      variant="outlined"
                      label="Add New Privilege*"
                      error={errors.newPrivilege}
                      control={control}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                  </div>
                  <div className="w-[400px]">
                    <SearchDropdown
                      control={control}
                      name="linkWith"
                      label="Link With"
                      placeholder="Link With"
                      searchIcon={true}
                      isSearchable={true}
                      isClearable={true}
                      error={errors.linkWith}
                      dataArray={dropdownOptions}
                      handleInputChange={getSystemMenuDropdownOptions}
                    />
                  </div>
                  <div className="flex gap-4 justify-end">
                    {userActions &&
                      userActions.map((actions) => {
                        if (
                          actions.permission === "Create" &&
                          actions.isAction === false &&
                          edit === false
                        ) {
                          return (
                            <>
                              <CommonButton
                                type="button"
                                label="Reset"
                                onClick={() => {
                                  reset(defaultValues);

                                  setEdit(false);
                                }}
                                className="resetButton border border-customRed text-customRed"
                              />
                              <CommonButton
                                label="Create"
                                type="submit"
                                variant="outlined"
                                className="saveButton border  border-[#0B83A5]  text-[#0B83A5]"
                                onClick={() => {}}
                              />
                            </>
                          );
                        } else if (
                          actions.permission === "Update" &&
                          actions.isAction === false
                          //  &&
                          // edit === true
                        ) {
                          return (
                            <>
                              <CommonButton
                                type="button"
                                label="Reset"
                                onClick={() => {
                                  reset(defaultValues);
                                  setEdit(false);
                                }}
                                className="resetButton border border-customRed text-customRed"
                              />
                              <CommonButton
                                label="Update"
                                type="submit"
                                variant="outlined"
                                className="saveButton border  border-[#0B83A5]  text-[#0B83A5]"
                                onClick={() => {}}
                              />
                            </>
                          );
                        }
                      })}
                  </div>
                </div>
                {previousPrivilege?.length > 0 ? (
                  <CommonMasterPaginationTable
                    dataResult={previousPrivilege}
                    removeHeaders={["Id", "id", "active"]}
                    tableClass="2xl:h-[350px]"
                    highlightRow={false}
                    renderActions={renderActions}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    populateTable={previousPrivilegeList}
                  />
                ) : (
                  <div className="flex justify-center">
                    <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                      No Records Found...
                    </h3>
                  </div>
                )}
              </form>

              <CommonBackDrop openBackdrop={props.openBackdrop} />
            </div>
          </Box>
        </Modal>
        <ConfirmationModal
          confirmationOpen={privilegeConfirmation}
          confirmationHandleClose={handleClosePrivilegeDeleteConfirmation}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure Want To Delete Privilege  ?"
          confirmationButtonMsg="Confirm"
        />

        {/* Confirmation modal for POST request */}
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg={
            edit === true
              ? "Are You Sure Want To Update Privilege  ?"
              : "Are You Sure Want To Create New Privilege  ?"
          }
          confirmationButtonMsg="Confirm"
        />
      </div>
    </>
  );
}
