import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { successAlert } from "../../../common/components/Toasts/CustomToasts";
import { fetchDepartment } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchETURegisterList,
  getETURegisterVacinneName,
  saveETURegister,
} from "../../services/etuRegisterServices/etuRegisterServices";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

const removeHeaders = [
  "patchDate",
  "Address",
  "AgeYear",
  "ArrivalTime",
  "BatchNo",
  "BillId",
  "BillNo",
  "BodyHandedOverBy",
  "BodyHandedOverTo",
  "BodyHandoverRemark",
  "DepartmentId",
  "DepartureTime",
  "Diagnosis",
  "Gender",
  "GenderId",
  "ID",
  "IsDC",
  "IsDoctorVisit",
  "IsMLC",
  "IsNurseVisit",
  "IsPM",
  "IsSingleDose",
  "IsVaccineDateEdited",
  "MortuaryInTime",
  "MortuaryOutTime",
  "NextVaccineDate",
  "PatientId",
  "ReceivedCallDateTime",
  "StaffIds",
  "Staffs",
  "Treatement",
  "TrnType",
  "VaccineDoseDurationID",
  "VaccineId",
  ,
  "VisitedLocation",
];
const actions = ["print"];
const VaccineChart = (props) => {
  const schema = yup.object().shape({
    department: yup
      .object()
      .nullable()
      .shape({
        value: yup.string("Required"),
        label: yup.string("Required"),
      })
      .required("Required"),
  });
  const defaultValues = {
    vaccinedose: false,
    department: null,
    batchno: "",
    isSingleDose: "",
    isVaccineDateEdited: false,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables

  const [departmentList, setDepartmentList] = React.useState([]);
  const [vaccine, setVaccine] = React.useState();
  const [department, setDepartment] = React.useState();
  const [vaccineChartList, setVaccineChartList] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [resetConfirmationOpen, setResetConfirmationOpen] = React.useState(
    false
  );
  const [privilege, setPrivilege] = React.useState(null);
  const [vaccineChart, setETUVaccineChartObject] = React.useState(null);
  const [cellInfo, setCellInfo] = React.useState();
  const [rowIndex, setRowIndex] = React.useState();
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");
  let departmentVal = getValues("department");
  let vaccinedoseVal = watch("vaccinedose");

  let isVaccineDateEditedVal = watch("isVaccineDateEdited");

  let location = useLocation();
  //useEffects
  // set the value on every row change to particular field
  useEffect(() => {
    // patch dept dropdown value
    let deptDropdownObj = {};
    deptDropdownObj.id = cellInfo?.DepartmentId;
    deptDropdownObj.value = cellInfo?.Department;
    deptDropdownObj.label = cellInfo?.Department;
    setValue("department", deptDropdownObj);
    // patch vaccine dropdwon value
    let vaccineDropdownObj = {};
    vaccineDropdownObj.id = cellInfo?.VaccineId;
    vaccineDropdownObj.label = cellInfo?.Vaccine;
    vaccineDropdownObj.label = cellInfo?.Vaccine;
    setValue("vaccinedose", vaccineDropdownObj);
    // set batch no
    setValue("batchno", cellInfo?.BatchNo);
    setValue("isSingleDose", cellInfo?.IsSingleDose === 1 ? true : false);
    // set  Vaccine Date

    const dateString = cellInfo?.patchDate;
    setSelectedFromDate(new Date(dateString));
  }, [cellInfo]);

  useEffect(() => {
    if (props.showInfo === true) {
      setValue("fromDate", new Date());
      setValue("vaccinedose", "");
      setValue("department", null);
      setValue("isSingleDose", false);
      setValue("nextVaccineDate", new Date());
      setValue("isVaccineDateEdited", new Date());
      setValue("batchno", "");
      setValue("isVaccineDateEdited", false);
    }
  }, [props.showInfo]);
  useEffect(() => {
    if (
      vaccinedoseVal?.label === "Chicken Pox" ||
      vaccinedoseVal?.label === "H1N1"
    ) {
      setValue("isVaccineDateEdited", true);
      setValue("isSingleDose", true);
    } else {
      setValue("isVaccineDateEdited", false);
      setValue("isSingleDose", false);
    }
  }, [vaccinedoseVal]);
  //functions
  const handleOpen = () => {
    setConfirmationOpen(true);
  };
  const handleClose = () => {
    setConfirmationOpen(false);
  };

  const handleResetOpenConfirmation = () => {
    setResetConfirmationOpen(true);
  };

  const handleResetCloseConfirmation = () => {
    if (resetConfirmationOpen === true) {
      setResetConfirmationOpen(false);
    }
  };

  // vaccine and department dropdown api
  useEffect(() => {
    getETURegisterVacinneName()
      .then((Response) => Response.data)
      .then((res) => {
        setVaccine(res.result);
      });

    fetchDepartment()
      .then((Response) => Response.data)
      .then((res) => {
        setDepartment(res.result);
      });
  }, []);
  // fetch listing of etu register home visit
  useEffect(() => {
    fetchETURegisterList(props.eturegisteroptionVal.label)
      .then((response) => response.data)
      .then((res) => {
        setVaccineChartList(res);
      });
  }, []);

  function searchDepartmentList(e) {
    fetchDepartment(e).then((response) => {
      setDepartmentList(response.data.result);
    });
  }
  function onSubmitDataHandler(data) {
    let getPatientInfo = localStorage.getItem("userInfo");
    let getPatientInfoObj = JSON.parse(getPatientInfo);

    let postETURegisterObj = {
      arrivalTime: "",

      batchNo: data.batchno ? data.batchno : null,

      billId: 0,
      addedBy: getPatientInfoObj.userId,
      billNo: data.billNo ? data.billNo : null,
      bodyHandOverBy: data.bodyHandOverBy ? data.bodyHandOverBy : null,
      bodyHandOverRemark: data.bodyHandOverRemark
        ? data.bodyHandOverRemark
        : null,
      bodyHandOverTo: getValues(""),
      departmentId: departmentVal?.id ? departmentVal?.id : null,
      departureTime: "",
      diagnosis: data.diagnosis ? data.diagnosis : null,
      isDc: data.isDc ? data.isDc : null,
      isDoctorVisit: data.isDoctorVisit ? data.isDoctorVisit : null,
      isMlc: data.isMlc ? data.isMlc : null,
      isNurseVisit: data.isNurseVisit ? data.isNurseVisit : null,
      isPm: data.isPm ? data.isPm : null,
      isSingleDose: data.isSingleDose ? data.isSingleDose : null,
      isVaccineDateEdited: data.isVaccineDateEdited
        ? data.isVaccineDateEdited
        : null,
      isVccRemainderSMSSend: data.isVccRemainderSMSSend
        ? data.isVccRemainderSMSSend
        : null,
      mortuaryInTime: "",
      mortuaryOutTime: "",
      nextVaccineDate: nextVaccineDateVal,
      patientId: props.patientId,
      receivedCallDateTime: "",

      tmType: props.eturegisteroptionVal.label,
      treatment: data.treatment,
      vaccineDoseDurationId: data.vaccineDoseDurationId
        ? data.vaccineDoseDurationId
        : null,
      vaccineId: vaccinedoseVal?.id ? vaccinedoseVal?.id : null,
      visitedLocation: data.visitedLocation ? data.visitedLocation : null,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    setETUVaccineChartObject(postETURegisterObj);
    handleOpen();
  }

  function addRecord() {
    let etuObj = vaccineChart;
    saveETURegister(vaccineChart)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        reset(defaultValues, props.etuRegisterSearchVal);
        props.populateTable();
      });
  }
  let nextVaccineDateVal = watch("nextVaccineDate");

  function displayRowData(row) {}
  function resetVaccineChart() {
    handleResetCloseConfirmation();
    setSelectedFromDate(new Date());
    reset(defaultValues);
    setRowIndex();
    props.setShowInfo(true);
  }

  // get row action and row data
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1 items-center cursor-pointer">
        {props.userActions &&
          props.userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-1 items-center cursor-pointer">
                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title={"Print"}>
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                              if (props.etuType === "HomeVisit") {
                                props.printHomeReceipt(Number(row?.ID));
                              } else if (props.etuType === "AmbulanceCall") {
                                props.printAmbulanceCallReceipt(
                                  Number(row?.ID)
                                );
                              } else if (props.etuType === "BodyHandover") {
                                props.printBodyHandoverReceipt(Number(row?.ID));
                              } else if (props.etuType === "Vaccine") {
                                props.printVaccineChartReceipt(Number(row?.ID));
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  // get selected Row Data
  function handleSelectedRow(row, index) {
    displayRowData(row, index);
    setCellInfo(row);
    if (props.showInfo === true) {
      props.setShowInfo(false);
    }
  }
  return (
    <div className=" pb-4">
      {props.showInfo === true ? (
        <>
          {props.patientInfoById && props.patientInfoById !== "" ? (
            <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
              <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 font-semibold">Patient Name</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById.PatientName &&
                      props.patientInfoById.PatientName}
                  </span>
                </div>
                <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                    MR No.
                  </span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById && props.patientInfoById.MRNo}
                  </span>
                </div>

                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 xl:w-8 font-semibold">Age</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById && props.patientInfoById.AgeYear}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                  <span className="w-24 lg:w-20 font-semibold">Address </span>

                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById && props.patientInfoById.Address}
                  </span>
                </div>
              </div>
            </fieldset>
          ) : null}
        </>
      ) : null}
      <div className="w-full py-2">
        {props.dataResult.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={props.dataResult}
            highlightRow={true}
            renderActions={renderActions}
            handleSelectedRow={handleSelectedRow}
            tableClass="lg:h-[300px] 2xl:h-[450px]"
            removeHeaders={removeHeaders}
          />
        ) : (
          <div className="text-center py-12">
            <span>Records Not Found...</span>
          </div>
        )}
        {/* {props.data.length > 0 ? (
          <EtuRegisterTable
            showInfo={props.showInfo}
            rowIndex={rowIndex}
            setRowIndex={setRowIndex}
            setShowInfo={props.setShowInfo}
            actions={actions}
            data={props.data}
            setData={props.setData}
            displayRowData={displayRowData}
            setCellInfo={setCellInfo}
            etuType={props?.etuType}
            printVaccineChartReceipt={props.printVaccineChartReceipt}
          />
        ) : (
          <div className="text-center py-12">
            <span>Records Not Found...</span>
          </div>
        )} */}
      </div>
      {props.showInfo === false ? (
        <>
          {cellInfo && cellInfo !== "" ? (
            <>
              <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 font-semibold">Patient Name</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo?.PatientName && cellInfo?.PatientName}
                    </span>
                  </div>
                  <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                      MR No.
                    </span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.MRNo}
                    </span>
                  </div>

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 xl:w-8 font-semibold">Age</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.AgeYear}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                    <span className="w-24 lg:w-20 font-semibold">Address</span>

                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.Address}
                    </span>
                  </div>
                </div>
              </fieldset>
            </>
          ) : null}
        </>
      ) : null}
      {(props.patientInfoById && props.patientInfoById !== "") ||
      (cellInfo && cellInfo !== "") ? (
        <>
          <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-1 items-center">
            <div className="text-md font-semibold">Vaccine Chart</div>
            <div className=" flex justify-end">
              {props.showInfo === true ? null : (
                <CommonButton
                  label="New"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    handleResetOpenConfirmation();
                  }}
                />
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <div className="">
              <div className="flex gap-2 pt-2">
                <DatePickerFieldNew
                  name="toDate"
                  control={control}
                  label="To Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("toDate", newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                />

                {/*<Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disableFuture
                        label="Vaccine Date"
                        value={selectedFromDate}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          
                          setSelectedFromDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "#0B83A5",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  name="fromDate"
                          />*/}

                <DropdownField
                  name="vaccinedose"
                  control={control}
                  label="Vaccine"
                  placeholder="Vaccine"
                  isSearchable={false}
                  dataArray={vaccine}
                  // dataArray={dropdown}
                  error={errors.vaccinedose}
                  inputRef={{
                    ...register("vaccinedose", {
                      onChange: (e) => {},
                    }),
                  }}
                />

                <SearchDropdown
                  control={control}
                  name="department"
                  dataArray={departmentList}
                  searchIcon={true}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Department Name*"
                  handleInputChange={searchDepartmentList}
                  error={errors.department}
                />

                <InputField control={control} name="batchno" label="Batch No" />
              </div>
              <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                <legend className="px-4">Vaccination Details</legend>
                <div className="flex gap-2 items-center py-2 px-4">
                  <div>
                    <CheckBoxField
                      name="isSingleDose"
                      label="Single Dose Vaccine"
                      disabled={
                        vaccinedoseVal?.label === "H1N1" ||
                        vaccinedoseVal?.label === "Chicken Pox"
                          ? true
                          : false
                      }
                      control={control}
                    />
                  </div>

                  <div>
                    <DatePickerFieldNew
                      control={control}
                      disabled={
                        vaccinedoseVal?.label === "H1N1" ||
                        vaccinedoseVal?.label === "Chicken Pox"
                          ? true
                          : false
                      }
                      name="nextVaccineDate"
                      label="Next Vaccination Date"
                      defaultValue={new Date()}
                      onChange={(newValue) => {
                        setValue("nextVaccineDate", newValue);
                      }}
                      disableFuture={
                        watch("isVaccineDateEdited") ? false : true
                      }
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      name="isVaccineDateEdited"
                      label="Edit Vaccine Date"
                      disabled={
                        vaccinedoseVal?.label === "H1N1" ||
                        vaccinedoseVal?.label === "Chicken Pox"
                          ? true
                          : false
                      }
                      control={control}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="flex gap-2 justify-end">
              {props.showInfo === true ? (
                <>
                  <>
                    {props.userActions &&
                      props.userActions.map((actions, i) => (
                        <>
                          {actions.isAction === false ? (
                            <>
                              <div className="flex gap-2 cursor-pointer">
                                {actions.action === "Create" ? (
                                  <>
                                    {props.showInfo === true ? (
                                      <>
                                        <div>
                                          <CommonButton
                                            label="Reset"
                                            type="button"
                                            className="border border-customRed text-customRed"
                                            onClick={() => {
                                              reset(defaultValues);
                                              props.setPatientInfoById("");
                                              props.setValue(
                                                "etuRegisterSearch",
                                                null
                                              );
                                              setCellInfo("");
                                            }}
                                          />
                                        </div>

                                        <div>
                                          <CommonButton
                                            type="submit"
                                            label="Save"
                                            className="bg-customGreen text-white"
                                            onClick={() => {
                                              setPrivilege(actions?.action);
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                        </>
                      ))}
                  </>
                </>
              ) : // <div>
              //   <CommonButton
              //     type="submit"
              //     label="Update"
              //     className="bg-customGreen text-white"
              //   />
              // </div>
              null}
            </div>
          </form>
        </>
      ) : null}
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          handleClose();
          addRecord();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save ETU Details?"
        confirmationButtonMsg="Save"
      />
      <ConfirmationModal
        confirmationOpen={resetConfirmationOpen}
        confirmationHandleClose={handleResetCloseConfirmation}
        confirmationSubmitFunc={() => {
          handleResetCloseConfirmation();
          resetVaccineChart();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure to Reset Vaccine Chart?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default VaccineChart;
