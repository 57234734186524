import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { saveTentativeDischarge } from "../../../services/dischargeSummary/DischargeSummaryServices";

const TentativeDischargeModal = (props) => {
  const {
    admissionId,
    getPatientInfo,
    patientId,
    tentativediscDate,
    
  } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  console.log(props);

  const { handleSubmit, control, setValue } = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
    defaultValues: {
      dischargeTime: new Date(),
      dischargeDate: new Date(),
    },
  });

  function onSubmit(data) {
    setOpenBackdrop(true);

    const tntDischargeObj = {
      admissionId: admissionId,
      dateTime: data.dischargeDate,
      isTentative: true,
    };
    saveTentativeDischarge(tntDischargeObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        props.handleClose();
         getPatientInfo(patientId);
   
      })
      .catch((res) => {
        errorAlert(res?.rmessage || res?.response?.data?.message);
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    setValue("dischargeDate", tentativediscDate);
  }, [tentativediscDate]);

  return (
    <div>
      {/* /// modal */}
      <Modal open={props.open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Tentative Discharge Details
            </div>

            <hr className="border mx-1  border-blue-900" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-2 my-4">
                <DatePickerFieldNew
                  control={control}
                  name="dischargeDate"
                  label="Discharge Date"
                  disableFuture={false}
                  disablePast={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div className="w-full flex justify-end gap-3">
                <CommonButton
                  label="Cancel"
                  onClick={() => {
                    props.handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  type="submit"
                  label="Save"
                  className="bg-customGreen text-white"
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
};

export default TentativeDischargeModal;
