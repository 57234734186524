// import React from 'react'

import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchDoctor } from "../../../commonServices/miscellaneousServices/MiscServices";
import { getPatientInfo } from "../../../opd/components/AppointmentBooking/services/AppointmentServices";
import { saveBedBooking } from "../../services/bedAllowcationServices/BedAllowcationServices";

export const Schema = yup.object().shape({
  patientName: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  doctor: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  contactNumber: yup.string().required("Required"),
});

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "95%", lg: "50%" },
  // minWidth:"25%",
  // height: "30%",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function BedReserveModal(props) {
  const {
    open,
    handleClose,
    menuId,
    privilege,
    getBedListData,
    bedDetails,
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
    defaultValues: {
      patientName: null,
      contactNumber: "",
      doctor: null,
      expectedAdmissionDate: new Date(),
      remark: "",
    },
  });
  const {
    formState: { errors },
    control,
    register,
  } = methods;

  const [openBackdrop, setOpenBackdrop] = useState(false);
  //

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);
  const [patientsArr, setPatientsArr] = useState([]);
  const [doctor, setDoctor] = useState();
  const [allFormData, setAllFormData] = useState();

  //

  //doctor Name
  const getRegistrationPatientInfo = (SearchString) => {
    if (SearchString !== null && SearchString !== "") {
      getPatientInfo(SearchString)
        .then((response) => response.data)
        .then((res) => {
          setPatientsArr(res.result);
        });
    }
  };

  //doctor Name
  const getDoctor = (doctorSearchString) => {
    if (doctorSearchString !== null && doctorSearchString !== "") {
      fetchDoctor(doctorSearchString)
        .then((response) => response.data)
        .then((res) => {
          setDoctor(res.result);
        });
    }
  };

  const onSubmit = (data) => {
    setOpenConfirmationModal(true);
    setAllFormData(data);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const finalObj = {
      admissionDate: data?.expectedAdmissionDate,
      bedId: bedDetails?.BedId,
      contactNo: data.contactNo,
      doctorName: data.doctor.value,
      menuId: menuId,
      patientName: data.patientName.patientName || data.patientName.value,
      privilege: privilege,
      remark: data.remark,
    };

    saveBedBooking(finalObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        handleClose();
        getBedListData();
        successAlert(res.message)
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res.message);
        setOpenBackdrop(false);
        handleClose();
      });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              !openBackdrop && handleClose();
            }}
          />
          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                Reserve Bed
              </div>

              <hr className="border mx-1  border-blue-900" />

              <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                <div className="grid grid-cols-2  gap-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                    <div className="flex gap-2 items-center">
                      <h1 className="text-black font-semibold flex space-x-1 lg:space-x-1">
                        <span className="text-sm">Bed No.</span>
                        <span className="lg:pl-0 md:pl-1">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails && bedDetails["BedNo"]}
                      </h1>
                    </div>
                    <div className="flex gap-2 items-center">
                      <h1 className="text-black font-semibold flex space-x-1 lg:space-x-1">
                        <span className="text-sm">floor</span>
                        <span className="lg:pl-0 md:pl-1">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails && bedDetails["floor"]}
                      </h1>
                    </div>
                  </div>

                  <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-0 lg:space-x-1">
                        <span className="text-sm"> Bed Category</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails && bedDetails["BedCategory"]}
                      </h1>
                    </div>
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-0 lg:space-x-1">
                        <span className="text-sm"> Ward</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails && bedDetails["Ward"]}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 mt-3">
                <div className="">
                  <CreateableSelect
                    name="patientName"
                    placeholder="Patient Name *"
                    dataArray={patientsArr}
                    onInputChange={(e) => {
                      getRegistrationPatientInfo(e);
                    }}
                    error={errors.patientName}
                    control={control}
                    isClearable={true}
                  />
                </div>
                <div>
                  <InputLimit
                    maxDigits={10}
                    name="contactNumber"
                    error={errors.contactNumber}
                    variant="outlined"
                    label="Contact Number *"
                    control={control}
                    type={"number"}
                    onKeyDown={(e) => {
                      if (
                        e?.key === "-" ||
                        e?.key === "+" ||
                        e?.code === "ArrowUp" ||
                        e?.code === "ArrowDown" ||
                        e?.code === "NumpadDecimal"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className=" ">
                  <SearchDropdown
                    control={control}
                    name="doctor"
                    error={errors.doctor}
                    dataArray={doctor}
                    handleInputChange={(e) => {
                      getDoctor(e);
                    }}
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    placeholder="Admission Under Doctor *"
                  />
                </div>
                <div className="">
                  <DatePickerFieldNew
                    control={control}
                    name="expectedAdmissionDate"
                    label="Expected Admission Date"
                    value={new Date()}
                    disableFuture={false}
                    disablePast={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="lg:col-span-2 md:col-span-1">
                  <TextField
                    id="outlined-multiline-static"
                    name="remark"
                    {...register("remark")}
                    label="Remark"
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={3}
                    fullWidth
                  />
                </div>
              </div>

              <div className="flex justify-end my-2 gap-3">
                <CommonButton
                  label="Cancel"
                  onClick={() => {
                    handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  label={"Proceed"}
                  onClick={methods.handleSubmit(onSubmit)}
                  className="bg-customGreen text-white "
                  disabled={openBackdrop}
                />
              </div>
            </div>
          </form>
          {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
        </Box>
      </Modal>
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />{" "}
    </div>
  );
}
