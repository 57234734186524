import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../common/components/FormFields/InputField";
import InputLimit from "../../../common/components/FormFields/InputLimit";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import {
  fetchBirthDetails,
  fetchGenders,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { isAfter } from "date-fns";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(100, "First Name must be at most 100 characters")
    .required("First Name is required"),

  lastName: Yup.string()
    .max(100, "Last Name must be at most 100 characters")
    .required("Last Name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
    .required("Mobile Number is required"),
  //   address: Yup.string().required(),
  dobDate: Yup.date(),
  age: Yup.number().nullable(),
  ageInYears: Yup.number().nullable(),
  ageInMonths: Yup.number().nullable(),
  ageInDays: Yup.number().nullable(),
  gender: Yup.object()
    .nullable()
    .shape({
      value: Yup.string().notRequired("Please Select gender"),
      label: Yup.string().notRequired("Please Select gender"),
    })
    .required("Required"),
});

const defaultValues = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  address: "",
  dobDate: null,
  age: "",
  ageInYears: "",
  ageInMonths: "",
  ageInDays: "",
  gender: "",
};

const OutsidePatientInfo = ({
  setPatientInfo,
  setOpenOutsiidePtForm,
  outsidePtInfo,
  setOutsidePtInfo,
}) => {
  const [genders, setGenders] = useState([]);
  const [enteredAge, setEnteredAge] = useState();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const getGenders = () => {
    fetchGenders().then((response) => {
      setGenders(response.data.result);
    });
  };

  const getAgeDOB = (dobValue) => {
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    if (fullDOB.length === 10) {
      fetchBirthDetails(0, fullDOB).then((response) => {
        setValue("age", Number(response.data.result.years), {
          shouldValidate: true,
        });
        setValue("ageInYears", response.data.result.years || 0);
        setValue("ageInMonths", response.data.result.months || 0);
        setValue("ageInDays", response.data.result.days || 0);
      });
    }
  };

  useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA").then((response) => {
        setValue("dobDate", `${response.data.result.dob}T00:00:00.000Z`, {
          shouldValidate: true,
        });
        setValue("ageInYears", Number(enteredAge));
        setValue("ageInMonths", response.data.result.months || 0);
        setValue("ageInDays", response.data.result.days || 0);
      });
    }
  }, [enteredAge]);

  useEffect(() => {
    getGenders();
  }, []);

  const onSubmit = (data) => {

    let patientName = `${data.firstName} ${data.lastName}`;

    let capitalizedPatientName = patientName.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );

    let obj = {
      patientName: capitalizedPatientName,
      gender: data?.gender.label,
      ageYear: `${data?.ageInYears} Y ${data?.ageInMonths} M ${data?.ageInDays} D `,
      MobileNo: data?.mobileNumber,
      dob: data?.dobDate,
    };
    setOutsidePtInfo(data);
    setPatientInfo(obj);
    setOpenOutsiidePtForm(false);
  };

  useEffect(() => {
    if (outsidePtInfo) {
      setValue("firstName", outsidePtInfo?.firstName);
      setValue("lastName", outsidePtInfo?.lastName);
      setValue("age", outsidePtInfo?.age);
      setValue("ageInYears", outsidePtInfo?.ageInYears);
      setValue("ageInMonths", outsidePtInfo?.ageInMonths);
      setValue("ageInDays", outsidePtInfo?.ageInDays);
      setValue("dobDate", outsidePtInfo?.dobDate);
      setValue("gender", outsidePtInfo?.gender);
      setValue("address", outsidePtInfo?.address || "");
      setValue("mobileNumber", outsidePtInfo?.mobileNumber);
    }
  }, [outsidePtInfo, setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-3 gap-3 mt-2"
    >
      <div>
        <InputField
          name="firstName"
          variant="outlined"
          label="First Name *"
          error={errors.firstName}
          control={control}
          inputProps={{ maxLength: 100 }}
        />
      </div>

      <div>
        <InputField
          name="lastName"
          variant="outlined"
          label="Last Name *"
          error={errors.lastName}
          control={control}
          inputProps={{ maxLength: 100 }}
        />
      </div>

      <div className="grid grid-cols-3 gap-3">
        <div className="col-span-2">
          <DatePickerFieldNew
            control={control}
            name="dobDate"
            label="Date of Birth"
            value={new Date()}
            inputRef={{
              ...register("dobDate", {
                onChange: (e) => {
                  if (isAfter(new Date(e.target.value), new Date(1900, 1, 1))) {
                    getAgeDOB(new Date(e.target.value));
                  }
                },
              }),
            }}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>

        <div>
          <InputLimit
            maxDigits={3}
            name="age"
            type="number"
            variant="outlined"
            label="Age *"
            error={errors.age}
            control={control}
            inputRef={{
              ...register("age", {
                onChange: (e) => setEnteredAge(e.target.value),
              }),
            }}
            onKeyDown={(e) => {
              if (
                e?.key === "-" ||
                e?.key === "+" ||
                e?.code === "ArrowUp" ||
                e?.code === "ArrowDown" ||
                e?.code === "NumpadDecimal"
              ) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>

      <div className="md:col-span-2 lg:col-span-2">
        <div className="grid grid-cols-4 gap-3">
          <InputField
            name="ageInYears"
            variant="outlined"
            label="Years"
            disabled={true}
            control={control}
          />
          <InputField
            name="ageInMonths"
            variant="outlined"
            label="Months"
            disabled={true}
            control={control}
          />
          <InputField
            name="ageInDays"
            variant="outlined"
            label="Days"
            disabled={true}
            control={control}
          />
          <DropdownField
            control={control}
            error={errors.gender}
            name="gender"
            label="Gender *"
            dataArray={genders}
            isSearchable={false}
            isClearable={false}
            placeholder="Gender *"
          />
        </div>
      </div>

      <div>
        <InputLimit
          maxDigits={10}
          name="mobileNumber"
          // error={errors.altMobileNumber}
          variant="outlined"
          label="Mobile *"
          control={control}
          disabled={false}
          type={"number"}
          onKeyDown={(e) => {
            if (
              e?.key === "-" ||
              e?.key === "+" ||
              e?.code === "ArrowUp" ||
              e?.code === "ArrowDown" ||
              e?.code === "NumpadDecimal"
            ) {
              e.preventDefault();
            }
          }}
        />
      </div>

      <div className="col-span-2">
        <InputField
          name="address"
          variant="outlined"
          label="Address"
          //   error={errors.address}
          control={control}
        />
      </div>

      <div className="col-span-1 flex justify-end">
        <CommonButton
          label={"Proceed"}
          className={"bg-customGreen text-white"}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

export default OutsidePatientInfo;
