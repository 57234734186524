import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// import { Table } from "antd";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  fetchAntibioticsList,
  fetchOrganismList,
  fethcPathReportDtlsTable,
  getTestTemplates,
} from "../../Services/Pathalogy/WorkOrderViewServices";
import Editor from "../Editor";
import AntiBioticsTable from "./AntiBioticsTable";
import ParametersTable from "./ParametersTable";
import JoditEditor from "jodit-react";

const config2 = {
  useSearch: false,
  spellcheck: false,
  enter: "P",
  defaultMode: "1",
  toolbarAdaptive: false,
  toolbarSticky: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  minHeight: 200,
  maxHeight: 500,
  minWidth: null,
  buttons: [
    "bold",
    "underline",
    "strikethrough",
    "italic",
    "indent",
    "outdent",
    "image",
    "fontsize",
    "paragraph",
    "brush",
    "|",
    "align",
    "ul",
    "ol",
    "table",
    "hr",
    "symbol",
    "eraser",
    "copyformat",
    "superscript",
    "subscript",
    "undo",
    "redo",
    "find",
    "preview",
    "print",
  ],
  editorCssClass: "alic",
  placeHolder: "",
  controls: {
    fontsize: {
      list: [
        "8",
        "9",
        "10",
        "11",
        "12",
        "14",
        "16",
        "18",
        "24",
        "30",
        "36",
        "48",
        "60",
        "72",
        "96",
        "100",
      ],
    },
    font: {
      command: "fontname",
      list: {
        "": "Default",
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
      },
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportDetailTabs(props) {
  const [pathReportDetails, setPathReportDetails] = useState();
  const [requiredTableArr, setRequiredTableArr] = React.useState([]);
  const [newRequiredTableArr, setNewRequiredTableArr] = React.useState([]);
  const [rowId, setRowId] = useState();
  const [antibioticList, setAntibioticList] = useState([]);
  const [organismData, setOrganismData] = React.useState([]);
  const [organismArr, setOrganismArr] = React.useState([]);
  const [antibioticsDataObj, setAntibioticsDataObj] = React.useState({});
  const [checked, setChecked] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const [organismId, setOrganismId] = React.useState();
  const [toggle, setToggle] = React.useState(false);
  const [submitAntibioticsArr, setSubmitAntibioticsArr] = React.useState([]);
  const [templateList, setTemplateList] = useState();

  const {
    antibioticsData,
    setAntibioticsData,
    checkedArr,
    content1,
    setContent1,
    setTemplateDetails,
    values,
    setValues,
    machineParameterValues,
    isView,
    authorizationArr,
  } = props;

  let getHasTempalate = props.selectedTestId?.HasTemplate;
  let getIsCultureSenTest = props.selectedTestId?.IsCultureSenTest;
  let getReportId = props.selectedTestId?.ReportId;

  console.log("getIsCultureSenTest123", getIsCultureSenTest);

  const {
    control,
    watch,
    getValues,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  let getTemplateData = watch("selectTemplate");

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  const handleToggle = (value) => {
    if (checked.length > 0) {
      let a = antibioticsData?.map((item) => item.organismId);
      let b = checked.map((item) => item);
    }
    setOrganismId(value && value.id);
    props.setGetOrganismId(value && value.id);
    getAntibiotics(value.id, value.label);

    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const filterOrganisms = (e) => {
    setSearchVal(e.target.value);
  };

  useEffect(() => {
    let filtered = organismArr.filter((item) => item.label.includes(searchVal));
    // filtered.forEach((val) => {
    //   val.checked = false;
    // });
    setOrganismData(filtered);
  }, [searchVal, organismArr]);

  const pathReportDetailsTable = () => {
    fethcPathReportDtlsTable(checkedArr)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setPathReportDetails(res.result);
        setTemplateDetails(res.result);
      });
  };

  console.log("pathReportDetails", pathReportDetails);

  function templateDropdown() {
    getTestTemplates(props.selectedTestId.OrderDtlsId).then((response) => {
      setTemplateList(response.data.result);
    });
  }

  useEffect(() => {
    if (getIsCultureSenTest === 1) {
      fetchOrganismList().then((response) => {
        organismArr.push(response.data.result);
        setOrganismArr(response.data.result);
      });
    }
  }, []);

  useEffect(() => {
    if (
      (getHasTempalate === 0 && getIsCultureSenTest === 1) ||
      (getHasTempalate === 1 && getIsCultureSenTest === 0) ||
      (getHasTempalate === 1 && getIsCultureSenTest === 1)
    ) {
      templateDropdown();
    }
    pathReportDetailsTable();
  }, [rowId, checkedArr]);

  const getAntibiotics = (id, name) => {
    fetchAntibioticsList(id)
      .then((response) => response.data)
      .then((res) => {
        let antibioticArr = [];
        res.result.map((item) => {
          let antibioticObj = {
            antiBioticId: item.AntibioticId,
            antiBioTicName: item.AntibioticName,
            value: "",
            result: "",
            organismId: id,
          };
          antibioticArr.push(antibioticObj);
        });
        let filter = antibioticsData?.filter((item) => item.organism.id === id);
        if (filter.length > 0) {
          setAntibioticsData(
            antibioticsData?.filter((item, index) => item.organism.id !== id)
          );
        } else {
          let obj = {
            organism: {
              id: id,
              name: name,
            },
            antiBioTicsList: [...antibioticArr],
          };
          setAntibioticsData([
            ...antibioticsData,
            {
              ...obj,
            },
          ]);
        }
      });
  };

  const handleCheckChange = (e, row, index) => {
    let tempArr = [...organismData];
    tempArr[index].checked = e.target.checked;
    setOrganismData(tempArr);
    if (e.target.checked === false) {
      let filterData = props.finalArr.filter(
        (item) => item.organismId !== row.id
      );
      props.setFinalArr(filterData);
    }
  };

  useEffect(() => {
    setNewRequiredTableArr(requiredTableArr);
    if (values === 0) {
      setRequiredTableArr(newRequiredTableArr);
      // setNewRequiredTableArr([]);
    }
  }, [values]);

  useEffect(() => {
    for (let ip = 0; ip < pathReportDetails?.length; ip++) {
      let antibioticTable = pathReportDetails[ip]?.drugSensitivityDtoList;
      for (let ic = 0; ic < antibioticTable?.length; ic++) {
        for (let io = 0; io < organismData?.length; io++) {
          if (organismData[io]?.id === antibioticTable[ic]?.organismId) {
            organismData[io].checked = true;
          }
        }
      }
    }
  }, [pathReportDetails, organismData]);

  useEffect(() => {
    for (let ip = 0; ip < machineParameterValues?.length; ip++) {
      let antibioticTable = machineParameterValues[ip]?.drugSensitivityDtoList;
      for (let ic = 0; ic < antibioticTable?.length; ic++) {
        for (let io = 0; io < organismData?.length; io++) {
          if (organismData[io]?.id === antibioticTable[ic]?.organismId) {
            organismData[io].checked = true;
          }
        }
      }
    }
  }, [machineParameterValues, organismData]);

  console.log("organismData", organismData);

  useEffect(() => {
    let tempAntibioticArr = [];
    let antibioticTable = pathReportDetails?.[0]?.drugSensitivityDtoList;
    for (let i = 0; i < antibioticTable?.length; i++) {
      let antibioticList = antibioticTable[i]?.drugAntibioticListDtoList;

      fetchAntibioticsList(antibioticTable[i]?.organismId)
        .then((response) => response.data)
        .then((res) => {
          let antibioticArr = [];
          res.result.map((item) => {
            let defaultValue = antibioticList?.find(
              (val) => val?.antibioticId === item?.AntibioticId
            );

            let antibioticObj = {
              antiBioticId: item.AntibioticId,
              antiBioTicName: item.AntibioticName,
              value: defaultValue ? defaultValue?.sensitivity : "",
              result: defaultValue ? defaultValue?.mlcValue : "",
              pathTestSensivityNormalDetailId:
                defaultValue?.pathTestSensivityNormalDetailId,
              organismId: antibioticTable[i]?.organismId,
              // micValue: defaultValue?.micValue,
            };
            antibioticArr.push(antibioticObj);
          });
          let obj = {
            organism: {
              id: antibioticTable[i]?.organismId,
              name: antibioticTable[i]?.organism,
            },
            pathTestSensivityNormalId:
              antibioticTable[i]?.pathTestSensivityNormalId,
            antiBioTicsList: [...antibioticArr],
          };
          tempAntibioticArr.push(obj);

          setAntibioticsData(tempAntibioticArr);
        });
    }
  }, [pathReportDetails]);

  useEffect(() => {
    let tempAntibioticArr = [];
    let antibioticTable = machineParameterValues?.[0]?.drugSensitivityDtoList;
    for (let i = 0; i < antibioticTable?.length; i++) {
      let antibioticList = antibioticTable[i]?.drugAntibioticListDtoList;

      fetchAntibioticsList(antibioticTable[i]?.organismId)
        .then((response) => response.data)
        .then((res) => {
          let antibioticArr = [];
          res.result.map((item) => {
            let defaultValue = antibioticList?.find(
              (val) => val?.antibioticId === item?.AntibioticId
            );

            let antibioticObj = {
              antiBioticId: item.AntibioticId,
              antiBioTicName: item.AntibioticName,
              value: defaultValue ? defaultValue?.sensitivity : "",
              result: defaultValue ? defaultValue?.mlcValue : "",
              pathTestSensivityNormalDetailId:
                defaultValue?.pathTestSensivityNormalDetailId,
              organismId: antibioticTable[i]?.organismId,
              // micValue: defaultValue?.micValue,
            };
            antibioticArr.push(antibioticObj);
          });
          let obj = {
            organism: {
              id: antibioticTable[i]?.organismId,
              name: antibioticTable[i]?.organism,
            },
            pathTestSensivityNormalId:
              antibioticTable[i]?.pathTestSensivityNormalId,
            antiBioTicsList: [...antibioticArr],
          };
          tempAntibioticArr.push(obj);
          setAntibioticsData(tempAntibioticArr);
        });
    }
  }, [machineParameterValues]);

  // useEffect(() => {
  //   if (machineParameterValues?.length > 0) {
  //     setPathReportDetails(machineParameterValues);
  //   }
  // }, [machineParameterValues]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {getHasTempalate === 0 && getIsCultureSenTest === 1 ? (
            <>
              {props.getNormalTemplate === "Normal" ? (
                <Tabs
                  value={values}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Parameters" {...a11yProps(0)} />
                  <Tab label="Drug Sensitivity" {...a11yProps(1)} />
                  {/* <Tab label="Template" {...a11yProps(2)} /> */}
                </Tabs>
              ) : (
                <Tabs
                  value={values}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {/* <Tab label="Parameters" {...a11yProps(0)} />
                  <Tab label="Drug Sensitivity" {...a11yProps(1)} /> */}
                  <Tab label="Template" {...a11yProps(2)} />
                </Tabs>
              )}
            </>
          ) : (
            ""
          )}
          {/* {getHasTempalate === 0 && getIsCultureSenTest === 0 ? (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Parameters" {...a11yProps(0)} />
            </Tabs>
          </>
        ) : (
          ""
        )} */}
          {(getHasTempalate === 1 && getIsCultureSenTest === 0) ||
          (getHasTempalate === 1 && getIsCultureSenTest === 1) ? (
            <>
              <Tabs
                value={values}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Template" {...a11yProps(2)} />
              </Tabs>
            </>
          ) : (
            ""
          )}
        </Box>
        {getHasTempalate === 0 && getIsCultureSenTest === 0 ? (
          <TabPanel value={values} index={0}>
            <ParametersTable
              requiredTableArr={requiredTableArr}
              setRequiredTableArr={setRequiredTableArr}
              setPostArray={props.setPostArray}
              pathReportDetails={pathReportDetails}
              setPathReportDetails={setPathReportDetails}
              selectedTestId={props.selectedTestId}
              getUnit={props.getUnit}
              getNormalTemplate={props.getNormalTemplate}
              values={values}
              machineParameterValues={machineParameterValues}
              authorizationArr={authorizationArr}
            />
          </TabPanel>
        ) : (
          ""
        )}
        {getHasTempalate === 0 && getIsCultureSenTest === 1 ? (
          <div>
            {props.getNormalTemplate === "Normal" ? (
              <div>
                <TabPanel value={values} index={0}>
                  <ParametersTable
                    requiredTableArr={requiredTableArr}
                    setRequiredTableArr={setRequiredTableArr}
                    setPostArray={props.setPostArray}
                    pathReportDetails={pathReportDetails}
                    setPathReportDetails={setPathReportDetails}
                    selectedTestId={props.selectedTestId}
                    getUnit={props.getUnit}
                    getNormalTemplate={props.getNormalTemplate}
                    values={values}
                    machineParameterValues={machineParameterValues}
                    authorizationArr={authorizationArr}
                  />
                </TabPanel>
                <TabPanel value={values} index={1}>
                  <>
                    <div className="flex gap-2 w-full">
                      <div className="space-y-2 w-full">
                        <div className="col-span-2">
                          <TextField
                            size="small"
                            label="Search Organism"
                            fullWidth
                            placeholder="Search Organism"
                            onChange={(e) => filterOrganisms(e)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            ReportDetailTabs
                          />
                        </div>
                        <div className="w-full h-[500px] overflow-scroll">
                          {/* <OrganismListTable data={organismArr} setRowId={setRowId} /> */}
                          <List
                            sx={{
                              height: "100%",
                              bgcolor: "background.paper",
                            }}
                            disablePadding={true}
                          >
                            {organismData &&
                              organismData.map((value, index) => {
                                const labelId = `checkbox-list-label-${value}`;

                                return (
                                  <ListItem
                                    key={value.id}
                                    size="small"
                                    disablePadding
                                    dense
                                  >
                                    <ListItemButton
                                      disablePadding
                                      role={undefined}
                                      // onClick={() => handleToggle(value)}
                                      dense
                                      size="small"
                                      disableRipple
                                      sx={{ height: 30 }}
                                      // disabled={toggle}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          edge="start"
                                          // checked={
                                          //   checked.indexOf(value.id) !== -1
                                          // }
                                          checked={
                                            value?.checked === true
                                              ? true
                                              : false
                                          }
                                          name={`checkbox${index}`}
                                          tabIndex={1}
                                          disableRipple
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                          size="small"
                                          onChange={(e) => {
                                            handleCheckChange(e, value, index);
                                          }}
                                          onClick={() => handleToggle(value)}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        id={labelId}
                                        primary={`${value.label}`}
                                        size="small"
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </div>
                      </div>
                      <div className="space-y-2 w-full">
                        <div className="font-bold flex items-center space-x-4 px-2 py-1.5 rounded-sm border border-gray-400 text-gray-500">
                          <span>S - Sensitive </span>
                          <span>R - Ressistant</span>
                          <span>M - Moderate</span>
                        </div>
                        <div className="h-[500px] overflow-scroll w-full space-y-4">
                          {antibioticsData?.length > 0 &&
                            antibioticsData?.map((item) => {
                              return (
                                <div className="flex flex-col space-y-2">
                                  <span className="text-gray-600 font-semibold text-sm">
                                    {item?.organism?.name}
                                  </span>
                                  <AntiBioticsTable
                                    antibioticsData={antibioticsData}
                                    rows={item.antiBioTicsList}
                                    requiredArr={props.requiredArr}
                                    setFinalArr={props.setFinalArr}
                                    finalArr={props.finalArr}
                                    setRequiredArr={props.setRequiredArr}
                                    item={item}
                                    setGetOrganismId={props.setGetOrganismId}
                                    getOrganismId={props.getOrganismId}
                                    selectedTestId={props.selectedTestId}
                                    // onSubmit={onSubmitAntibiotics}
                                    // authObj={authObj}
                                    // setAuthObj={setAuthObj}
                                    // reportEntryDetails={reportEntryDetails}
                                    machineParameterValues={
                                      machineParameterValues
                                    }
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <div className="">
                          {submitAntibioticsArr &&
                            submitAntibioticsArr.length > 0 && (
                              <TableContainer component={Paper} elevation={2}>
                                <Table
                                  sx={{ height: "100%" }}
                                  aria-label="simple table"
                                  size="small"
                                >
                                  <TableHead
                                    sx={{ backgroundColor: "lightgrey" }}
                                  >
                                    <TableRow>
                                      <TableCell>Antibiotics Name</TableCell>
                                      <TableCell>Result</TableCell>
                                      <TableCell>Values</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {submitAntibioticsArr.map((row, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell>
                                          <div className="flex flex-col space-y-1">
                                            {row.antiBioTicsList.map((item) => {
                                              return (
                                                <span className=" p-0.5">
                                                  {item.antiBioTicName}
                                                </span>
                                              );
                                            })}
                                          </div>
                                        </TableCell>
                                        <TableCell>
                                          <div className="flex flex-col space-y-1">
                                            {row.antiBioTicsList.map((item) => {
                                              return (
                                                <span className=" p-0.5">
                                                  {item.result}
                                                </span>
                                              );
                                            })}
                                          </div>
                                        </TableCell>
                                        <TableCell>
                                          <div className="flex flex-col space-y-1">
                                            {row.antiBioTicsList.map((item) => {
                                              return (
                                                <span className=" p-0.5">
                                                  {item.value}
                                                </span>
                                              );
                                            })}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                </TabPanel>
              </div>
            ) : (
              <TabPanel value={values} index={0}>
                <div>
                  <div className="w-[40%]">
                    <DropdownField
                      control={control}
                      name="selectTemplate"
                      label="Select Template"
                      placeholder="Select Template"
                      dataArray={templateList}
                      inputRef={{
                        ...register("selectTemplate", {
                          onChange: (e) => {
                            setContent1(
                              e.target.value?.value
                                ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                                .replaceAll("\n", "")
                            );
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="my-2">
                    <JoditEditor
                      name="template"
                      config={config2}
                      value={
                        content1 !== null
                          ? content1
                              ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                              .replaceAll("\n", "")
                          : ""
                      }
                      onBlur={(c) => {
                        if (c !== "") {
                          setContent1(c);
                        }
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
            )}
          </div>
        ) : (
          ""
        )}
        {(getHasTempalate === 1 && getIsCultureSenTest === 0) ||
        (getHasTempalate === 1 && getIsCultureSenTest === 1) ? (
          <TabPanel value={2} index={2}>
            <>
              <div>
                <div className="w-[40%]">
                  <DropdownField
                    control={control}
                    name="selectTemplate"
                    label="Select Template"
                    placeholder="Select Template"
                    dataArray={templateList}
                    inputRef={{
                      ...register("selectTemplate", {
                        onChange: (e) => {
                          setContent1(e.target.value?.value);
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <div className="my-2">
                    <JoditEditor
                      name="template"
                      config={config2}
                      value={
                        content1 !== null
                          ? content1
                              ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                              .replaceAll("\n", "")
                          : ""
                      }
                      onBlur={(c) => {
                        if (c !== "") {
                          setContent1(c);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          </TabPanel>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
