import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

export default function BothInputOutput(props) {
  // const schema = yup
  //   .object()
  //   .shape({
  //     iv: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     ivQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     perJt: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     perJtQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     infusions: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     infusionsQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     inputOther: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     inputOtherQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     perOral: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     perOralQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     perRt: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     perRtQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     boluses: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     bolusesQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     inputPdHd: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     inputPdHdQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     //OutPut
  //     urine: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     urineQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     drainage: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     drainageQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     stool: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     stoolQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     ngAspiratic: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     ngAspiraticQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     outputOther: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     outputOtherQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //     outputPdHd: yup
  //       .string()
  //       .matches(/^[a-zA-Z0-9\s/]+$/, "Space & Special Char Not Allow")
  //       .required(),
  //     outputPdHdQuantity: yup
  //       .string()
  //       .matches(/^\d{1,3}(\.\d{1,2})?$/, "Only Digits Allow")
  //       .required(),
  //   })
  //   .required();

  const [bothFinalData, setBothFinalData] = React.useState(); //input Save final data
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => setOpenPost(false);
  const [bothPrivilege, setBothPrivilege] = useState("");
  const defaultValues = {
    //Input
    iv: "",
    ivQuantity: "",
    perJt: "",
    perJtQuantity: "",
    infusions: "",
    infusionsQuantity: "",
    inputOther: "",
    inputOtherQuantity: "",

    perOral: "",
    perOralQuantity: "",
    perRt: "",
    perRtQuantity: "",
    boluses: "",
    bolusesQuantity: "",
    inputPdHd: "",
    inputPdHdQuantity: "",

    //Output
    urine: "",
    urineQuantity: "",
    drainage: "",
    drainageQuantity: "",
    stool: "",
    stoolQuantity: "",
    ngAspiratic: "",
    ngAspiraticQuantity: "",
    outputOther: "",
    outputOtherQuantity: "",
    outputPdHd: "",
    outputPdHdQuantity: "",
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = methods;

  let getUserId = JSON.parse(localStorage.getItem("userInfo"));
  const onSubmitData = (data) => {
    if (props.admissionId !== null) {
      if (props.admissionId !== undefined) {
        if (isDirty !== false) {
          setOpenPost(true);
          setBothFinalData(data); //final data
        } else {
          warningAlert("Please enter value...!");
        }
      }
    } else if (props.admissionId === undefined || props.admissionId === null) {
      warningAlert("Please Select Patient...!");
    }
  };

  function addRecordBoth(data) {
    setOpenPost(false);
    props.setOpenBackdrop(true);
    let postedObj = {
      menuId: props.menuId,
      privilege: props.privilege,
      addedBy: getUserId.userId,
      admissionId: props.admissionId,
      // INPUT
      ivDetails: data.iv,
      iv: data.ivQuantity,
      perJtDetails: data.perJt,
      perjt: data.perJtQuantity,
      infusionDetails: data.infusions,
      infusions: data.infusionsQuantity,
      otherDetails: data.inputOther,
      other: data.inputOtherQuantity,
      perOralDetails: data.perOral,
      peroral: data.perOralQuantity,
      perRtDetails: data.perRt,
      perRt: data.perRtQuantity,
      bolisesDetails: data.boluses,
      bolises: data.bolusesQuantity,
      inTakePdDetails: data.inputPdHd,
      inTakePd: data.inputPdHdQuantity,
      //OUTPUT
      outTakeUrine: data.urineQuantity,
      outTakeUrineDetails: data.urine,
      outTakeDrane: data.drainageQuantity,
      outTakeDraneDetails: data.drainage,
      outTakeStool: data.stoolQuantity,
      outTakeStoolDetails: data.stool,
      outTakeNg: data.ngAspiraticQuantity,
      outTakeEngDetails: data.ngAspiratic,
      outTakeOther: data.outputOtherQuantity,
      outTakeOtherDetails: data.outputOther,
      outTakePd: data.outputPdHdQuantity,
      outTakePdDetails: data.outputPdHd,
    };
    props.postOutputData(postedObj); //post final output data
    reset(defaultValues);
  }

  useEffect(() => {
    let resetObj = {
      iv: props.selectedRow?.IVDetails,
      ivQuantity: Number(props.selectedRow?.IV),
      perJt: props.selectedRow?.PERJTDetails,
      perJtQuantity: Number(props.selectedRow?.PERJT),
      infusions: props.selectedRow?.INFUSIONDetails,
      infusionsQuantity: Number(props.selectedRow?.INFUSIONS),
      inputOther: props.selectedRow?.INTAKEOtherDetails,
      inputOtherQuantity: Number(props.selectedRow?.INTAKEOther),
      perOral: props.selectedRow?.PERORALDetails,
      perOralQuantity: Number(props.selectedRow?.PERORAL),
      perRt: props.selectedRow?.PERRTDetails,
      perRtQuantity: Number(props.selectedRow?.PERRT),
      boluses: props.selectedRow?.BOLUSESDEtails,
      bolusesQuantity: Number(props.selectedRow?.BOLUSES),
      inputPdHd: props.selectedRow?.intakepddetails,
      inputPdHdQuantity: Number(props.selectedRow?.intakepd),

      //OutPut
      urine: props.selectedRow?.UrinDetails,
      urineQuantity: Number(props.selectedRow?.URINE),
      drainage: props.selectedRow?.DRANGEDetails,
      drainageQuantity: Number(props.selectedRow?.DRANGE),
      stool: props.selectedRow?.StoolDetails,
      stoolQuantity: Number(props.selectedRow?.Stool),
      ngAspiratic: props.selectedRow?.outttakengdetails,
      ngAspiraticQuantity: Number(props.selectedRow?.outttakeng),
      outputOther: props.selectedRow?.OUTTAKEOtherDetails,
      outputOtherQuantity: Number(props.selectedRow?.OUTTAKEOther),
      outputPdHd: props.selectedRow?.outtakepddetails,
      outputPdHdQuantity: Number(props.selectedRow?.outtakepd),
    };
    reset(resetObj);
  }, [props.selectedRow]);

  return (
    <>
      <form
      //onSubmit={handleSubmit(onSubmitData)}
      >
        <div>
          <span className="text-sm font-semibold whitespace-nowrap">Input</span>
          <div className="flex">
            <div className="grid grid-cols-4 gap-2 p-2  pr-2">
              <div className="col-span-3">
                <InputField
                  name="iv"
                  variant="outlined"
                  label="IV"
                  control={control}
                  error={errors.iv}
                />
              </div>
              <InputField
                name="ivQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.ivQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="perJt"
                  variant="outlined"
                  label="Per JT"
                  control={control}
                  error={errors.perJt}
                />
              </div>
              <InputField
                name="perJtQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.perJtQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="infusions"
                  variant="outlined"
                  label="Infusions"
                  control={control}
                  error={errors.infusions}
                />
              </div>
              <InputField
                name="infusionsQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.infusionsQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="inputOther"
                  variant="outlined"
                  label="Other"
                  control={control}
                  error={errors.inputOther}
                />
              </div>
              <InputField
                name="inputOtherQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.inputOtherQuantity}
              />
            </div>

            <div className="grid grid-cols-4 gap-2 p-2">
              <div className="col-span-3">
                <InputField
                  name="perOral"
                  variant="outlined"
                  label="Per Oral"
                  control={control}
                  error={errors.perOral}
                />
              </div>
              <InputField
                name="perOralQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.perOralQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="perRt"
                  variant="outlined"
                  label="Per RT"
                  control={control}
                  error={errors.perRt}
                />
              </div>
              <InputField
                name="perRtQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.perRtQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="boluses"
                  variant="outlined"
                  label="Boluses"
                  control={control}
                  error={errors.boluses}
                />
              </div>
              <InputField
                name="bolusesQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.bolusesQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="inputPdHd"
                  variant="outlined"
                  label="PD/HD"
                  control={control}
                  error={errors.inputPdHd}
                />
              </div>
              <InputField
                name="inputPdHdQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.inputPdHdQuantity}
              />
            </div>
          </div>

          <span className="text-sm font-semibold whitespace-nowrap">
            Output
          </span>

          <div className="flex">
            <div className="grid grid-cols-4 gap-2 m-2 ">
              <div className="col-span-3">
                <InputField
                  name="urine"
                  variant="outlined"
                  label="Urine"
                  control={control}
                  error={errors.urine}
                />
              </div>
              <InputField
                name="urineQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.urineQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="drainage"
                  variant="outlined"
                  label="Drange"
                  control={control}
                  error={errors.drainage}
                />
              </div>
              <InputField
                name="drainageQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.drainageQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="stool"
                  variant="outlined"
                  label="Stool"
                  control={control}
                  error={errors.stool}
                />
              </div>
              <InputField
                name="stoolQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.stoolQuantity}
              />
            </div>
            <div className="grid grid-cols-4 gap-2 m-2">
              <div className="col-span-3">
                <InputField
                  name="ngAspiratic"
                  variant="outlined"
                  label="NG Aspiratic"
                  control={control}
                  error={errors.ngAspiratic}
                />
              </div>
              <InputField
                name="ngAspiraticQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.ngAspiraticQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="outputOther"
                  variant="outlined"
                  label="Other"
                  control={control}
                  error={errors.outputOther}
                />
              </div>
              <InputField
                name="outputOtherQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.outputOtherQuantity}
              />

              <div className="col-span-3">
                <InputField
                  name="outputPdHd"
                  variant="outlined"
                  label="PD/HD"
                  control={control}
                  error={errors.outputPdHd}
                />
              </div>
              <InputField
                name="outputPdHdQuantity"
                type="number"
                variant="outlined"
                label="Qty"
                control={control}
                error={errors.outputPdHdQuantity}
              />
            </div>
          </div>

          <div className=" flex gap-2 justify-end pb-2">
            <CommonButton
              type="button"
              className="bg-customBlue text-white"
              label="View All"
              onClick={() => {
                props.handleOpen();
              }}
            />
            <CommonButton
              type="button"
              label="Reset"
              className="resetbutton border border-customRed text-customRed"
              onClick={() => {
                reset(defaultValues);
                props.setSelectedRow(null);
              }}
            />
            {props.userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <div
                    onClick={() => {
                      props.setPrivilege(obj?.action);
                    }}
                  >
                    {props.patientInformation?.BedReleased === true ||
                    props.selectedPatient?.BedReleased === true ? null : (
                      <>
                        {props.selectedRow === null ? (
                          <div>
                            <CommonButton
                              type="button"
                              className="bg-customGreen text-white"
                              label="Save"
                              onClick={methods.handleSubmit(onSubmitData)}
                              
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </form>

      <CommonBackDrop openBackdrop={props.openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={() => {
          addRecordBoth(bothFinalData);
        }}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
