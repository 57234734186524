import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { postMyVacantBeds } from "../../../commonServices/bedServices/BedServices";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchBedCategory,
  fetchUnit,
  fetchWard,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { getBedTransferPatientInfo } from "../../../commonServices/patientInfoServices/PatientInfoServices";
import {
  fetchBedTransferAutoComplete,
  fetchListOfTransfer,
  postBedTransferData,
} from "../../services/bedTransfer/BedTransferService";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

const schema = yup.object().shape({
  BedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  unit: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  ward: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
});

let showSpinner1 = false;
let showSpinner2 = false;

export default function BedTransfer(props) {
  let location = useLocation();
  console.log("props", props, location);

  let token = JSON.parse(localStorage.getItem("userInfo"));
  let userId = token.userId;
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState([]);

  const [options, setOptions] = useState([]);
  const [unit, setUnit] = useState();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [vacantBedDetails, setVacantBedDetails] = useState([]);
  const [selectedWard, setSelectedWard] = useState(0);
  const [bedCategory, setBedCategory] = useState();
  const [selectedBedCategory, setSelectedBedCategory] = useState(0);
  const [ward, setWard] = useState();
  const [selectedBedDetails, setSelectedBedDetails] = useState(null);
  const [bedTransferListData, setBedTransferListData] = useState([]);
  const [time, setTime] = useState(dayjs());
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      unit: "",
      BedCategory: "",
      ward: "",
    },
  });
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  const Unit = watch("unit");
  const FromDate = watch("fromDate");

  useEffect(() => {
    getBedCategory();
    getUnit();
    getWard();
    handleSetUnit();
  }, []);

  useEffect(() => {
    if (selectedBedDetails !== null) {
      setValue(
        "ward",
        {
          id: selectedBedDetails.WadId,
          label: selectedBedDetails.Ward,
          value: selectedBedDetails.Ward,
        },
        { shouldValidate: true }
      );
    }
  }, [selectedBedDetails, selectedPatient]);

  const handleSetUnit = () => {
    setValue(
      "unit",
      {
        id: token.unitId,
        label: token.unitName,
        value: token.unitName,
      },
      { shouldValidate: true }
    );
  };
  ///////

  useEffect(() => {
    selectedPatient !== null && getVacantBeds();
  }, [selectedBedCategory, Unit, selectedWard, selectedPatient]);

  ////////////

  const getUnit = () => {
    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);
      });
  };

  const getBedCategory = () => {
    fetchBedCategory()
      .then((response) => response.data)
      .then((res) => {
        setBedCategory(res.result);
      });
  };

  const getWard = () => {
    fetchWard()
      .then((response) => response.data)
      .then((res) => {
        setWard(res.result);
      });
  };

  const getVacantBeds = () => {
    showSpinner1 = true;
    const BedObj = {
      categoryId: selectedBedCategory,
      unitId: Unit?.id,
      wardId: selectedWard,
    };

    postMyVacantBeds(BedObj)
      .then((response) => response.data)
      .then((res) => {
        setVacantBedDetails(res.result);

        showSpinner1 = false;
      })
      .catch(() => {
        showSpinner1 = false;
      });
  };

  const bedTransferList = (AdmissionId) => {
    if (AdmissionId) {
      showSpinner2 = true;
      fetchListOfTransfer(AdmissionId)
        .then((response) => response.data)
        .then((res) => {
          setBedTransferListData(res.result);
          showSpinner2 = false;
        });
    }
  };

  const handleChange = (searchString) => {
    //fetchSearchDropDown(searchString)//old service ... ?
    searchString &&
      fetchBedTransferAutoComplete(searchString)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
  };

  useEffect(() => {
    console.log("location", location, location?.state?.params?.PatientId);
    if (location.state !== null) {
      getPatientInfo(location?.state?.params?.PatientId);
    }
  }, [location]);

  const getPatientInfo = (patientId) => {
    patientId &&
      getBedTransferPatientInfo(patientId)
        .then((response) => response.data)
        .then((res) => {
          setSelectedPatient(res.result);
          bedTransferList(res.result.AdmissionId);
        });
  };

  const onSubmitDataHandler = (data) => {
    if (selectedPatient !== null) {
      if (!selectedBedDetails) {
        warningAlert("Please Select Bed !");
      } else if (selectedBedDetails) {
        setOpenModal(true);
        setFormData(data);
      }
    } else if (selectedPatient === null) {
      warningAlert("Please Select Patient !");
    }
  };

  const finalObject = {
    addedBy: 0,
    admissionId: 0,
    genderId: 0,
    bedCategoryId: 0,
    fromBedId: 0,
    fromDate: "",
    fromTime: "",
    toBedId: 0,
    toDate: "",
    toTime: "",
    unitId: 0,
    wardId: 0,
    transferId: "",
  };

  //////to get loginId adn unitId from local starage

  const handleFormSubmit = (data) => {
    setOpenModal(false);
    setOpenBackdrop(true);

    finalObject.addedBy = userId;
    finalObject.admissionId = selectedPatient.AdmissionId;
    finalObject.genderId = Number(selectedPatient.genderId);
    data.BedCategory !== 0
      ? (finalObject.bedCategoryId = Number(data.BedCategory.id))
      : (finalObject.bedCategoryId = Number(0));

    data.unit !== 0
      ? (finalObject.unitId = Number(data.unit.id))
      : (finalObject.unitId = Number(0));

    data.ward !== 0
      ? (finalObject.wardId = Number(data.ward.id))
      : (finalObject.wardId = Number(0));

    finalObject.fromBedId = Number(selectedPatient.BedId);
    finalObject.transferId = Number(selectedPatient.TransferID);
    finalObject.fromDate = FromDate
      ? format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      : new Date();
    finalObject.fromTime = time;
    finalObject.toBedId = Number(selectedBedDetails.BedId);

    finalObject.menuId = location?.state?.menuId;
    finalObject.privilege = privilege;
    postBedTransferData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          bedTransferList(res.result.admissionId);
          setVacantBedDetails([]);
          setSelectedBedDetails(null);
          methods.reset();
          setOpenBackdrop(false);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenModal(false);
        setOpenBackdrop(false);
      });
  };
  const handleReset = () => {
    bedTransferList();
    handleSetUnit();
    setVacantBedDetails([]);
    setSelectedBedDetails(null);
    setSelectedPatient(null);
    methods.reset();
    setValue("bedTransferSearch", null);
  };

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const handleSelectedRow = (row) => {
    setSelectedBedDetails(row);

    setValue(
      "BedCategory",
      {
        id: row.CategoryId,
        label: row.Category,
        value: row.Category,
      },
      { shouldValidate: true }
    );
    setValue(
      "ward",
      {
        id: row.WadId,
        label: row.Ward,
        value: row.Ward,
      },
      { shouldValidate: true }
    );
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-center text-black font-bold text-xl ">
          Bed Transfer
        </div>
        <div>
          <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-3 pb-3 pt-5">
            <div className="lg:col-span-3 md:col-span-2">
              <SearchDropdown
                control={control}
                placeholder="Search By Patient Name/MR.No./Mobile No."
                dataArray={options}
                name="bedTransferSearch"
                searchIcon={true}
                isClearable={true}
                handleInputChange={handleChange}
                isDisabled={location?.state?.params ? true : false}
                inputRef={{
                  ...register("bedTransferSearch", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        bedTransferList(e.target.value.AdmissionId);
                        getPatientInfo(e.target.value.id);
                        handleSetUnit();
                      } else {
                        setSelectedPatient("");
                        setSelectedBedDetails(null);
                        setSelectedPatient(null);
                        setBedTransferListData([]);
                        handleSetUnit();
                        setVacantBedDetails([]);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className=" ">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <DesktopDatePicker
                  disableFuture
                  label="Date "
                  value={selectedToDate}
                  onChange={(newValue) => {
                    setSelectedToDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="bg-white"
                      name="toDate"
                      fullWidth
                      size="small"
                      {...params}
                      sx={{
                        svg: { color: "#0B83A5" },
                      }}
                    />
                  )}
                /> */}
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={false}
                  disablePast={true}
                  inputFormat="dd-MM-yyyy"
                />
              </LocalizationProvider>
            </div>
            {/* <div className=" col-span-1">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Time"
                  value={time}
                  onChange={handleTimeChange}
                  renderInput={(params) => (
                    <TextField
                      className="bg-white"
                      name="toTime"
                      fullWidth
                      size="small"
                      {...params}
                      sx={{
                        svg: { color: "#0B83A5" },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div> */}
          </div>
          <form>
            <div>
              <fieldset
                className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
              >
                <div className="py-2 grid  grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Patient Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.patientName}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">IPD No</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.IpdNo}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Gender</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.gender}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Age</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.AgeYear}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">D.O.B</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.dob}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Doctor</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.doctor}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Bed</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.BedNo}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Ward</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.Ward}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">ADM Date</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.admissionDate}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Bed Category</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.BedCategory}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Unit</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.unit}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset
                className="border border-gray-300 col-span-3 w-full 
               text-left lg:px-2 md:px-5 rounded pb-2"
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1 lg:px-2 md:px-2 py-2">
                  Transfer To
                </legend>
                <div className="grid lg:grid-cols-3 md:grid-cols-7 gap-2 ">
                  <div className="md:col-span-2 lg:col-span-1">
                    <div className="py-2">
                      <DropdownField
                        name="unit"
                        control={control}
                        isDisabled={selectedPatient !== null ? false : true}
                        label="Select Unit *"
                        error={errors.unit}
                        placeholder="Select Unit *"
                        dataArray={unit}
                        isSearchable={false}
                      />
                    </div>
                    <div className="py-2">
                      <DropdownField
                        control={control}
                        isDisabled={selectedPatient !== null ? false : true}
                        error={errors.BedCategory}
                        name="BedCategory"
                        label="Bed Category"
                        dataArray={bedCategory}
                        isSearchable={false}
                        placeholder="Bed Category *"
                        inputRef={{
                          ...register("BedCategory", {
                            onChange: (e) => {
                              setSelectedBedCategory(e.target.value.id);
                            },
                          }),
                        }}
                      />
                    </div>
                    <div className="py-2">
                      <DropdownField
                        control={control}
                        isDisabled={selectedPatient !== null ? false : true}
                        error={errors.ward}
                        name="ward"
                        label="Ward"
                        dataArray={ward}
                        isSearchable={false}
                        placeholder="Ward *"
                        inputRef={{
                          ...register("ward", {
                            onChange: (e) => {
                              setSelectedWard(e.target.value.id);
                            },
                          }),
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-2  lg:w-40 py-2 items-center px-1">
                      <h1 className="font-semibold text-sm">Bed No.</h1>
                      <div className="flex space-x-2 items-center whitespace-nowrap w-full">
                        <span>:</span>
                        {selectedBedDetails !== null ? (
                          <span> {selectedBedDetails.BedNo}</span>
                        ) : (
                          <span>Choose Bed</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-2 md:col-span-5">
                    {showSpinner1 ? (
                      <div className="flex justify-center mt-14">
                        <LoadingSpinner />
                      </div>
                    ) : vacantBedDetails.length > 0 ? (
                      <CommonDynamicTableNew
                        dataResult={vacantBedDetails}
                        handleSelectedRow={handleSelectedRow}
                        tableClass="h-56"
                        removeHeaders={[
                          "CategoryId",
                          "RoomId",
                          "Sex",
                          "WadId",
                          "BedId",
                          "Code",
                        ]}
                      />
                    ) : (
                      <p className="text-center items-center">
                        Please Select Bed Category
                      </p>
                    )}
                  </div>
                </div>
              </fieldset>
              <div className="flex justify-end gap-2 pt-1">
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <>
                        <CommonButton
                          label="Reset"
                          onClick={handleReset}
                          className="border border-customRed text-customRed"
                        />
                        <div onClick={() => setPrivilege(obj.action)}>
                          <CommonButton
                            label="Save"
                            onClick={methods.handleSubmit(onSubmitDataHandler)}
                            className="bg-customGreen text-white"
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
              <div className="py-0">
                <div>
                  <p className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1 lg:px-0 md:px-2">
                    List Of Transfer
                  </p>
                </div>
                {showSpinner2 ? (
                  <div className="flex justify-center mt-14">
                    <LoadingSpinner />
                  </div>
                ) : selectedPatient !== null &&
                  bedTransferListData.length > 0 ? (
                  <>
                    <CommonDynamicTableNew
                      dataResult={bedTransferListData}
                      removeHeaders={["Expr1", "TransferID", "AdmissionId"]}
                      highlightRow={false}
                    />
                  </>
                ) : (
                  <p className="text-center items-center">No Data Found</p>
                )}
              </div>
            </div>
          </form>
        </div>

        {/* backdrop */}
        <CommonBackDrop openBackdrop={openBackdrop} />

        {/* //Confirmation Modal// */}
        <ConfirmationModal
          confirmationOpen={openModal}
          confirmationHandleClose={() => setOpenModal(false)}
          confirmationSubmitFunc={() => handleFormSubmit(formData)}
          confirmationLabel="Confirmation "
          confirmationMsg="Are You Sure ?"
          confirmationButtonMsg="Proceed"
        />
      </div>
    </>
  );
}
