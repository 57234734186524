import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Divider, Modal, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonTransactionTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { DeleteIcon } from "../../../../assets/CommonAssets";
import Average from "../../../../assets/scaleEmoji/Average.png";
import StarPng from "../../../../assets/scaleEmoji/Star.png";
import TemplateModal from "./TemplateModal";
import {
  ModalStyle,
  ModalStyleFeedback,
} from "../../../../common/components/ModalStyle";
import {
  getAnswerPatternScaleForTemplate,
  getDepartmentDropDown,
  saveTemplate,
} from "../../../services/feedback/feedbackcreate/FeedBackCreationServices";
import { CapitalizeStatement } from "../../../../common/components/Custom Hooks/CapitalizeStatement";

const answerTypeData = [
  {
    id: "Consultant",
    label: "Consultant",
    value: "Consultant",
  },
  {
    id: "Staff",
    label: "Staff",
    value: "Staff",
  },
  {
    id: "Patient",
    label: "Patient",
    value: "Patient",
  },
];

const patientTypeData = [
  {
    id: "OPD",
    label: "OPD",
    value: "OPD",
  },
  {
    id: "IPD",
    label: "IPD",
    value: "IPD",
  },
];

const setTypeData = [
  {
    id: "General",
    label: "General",
    value: "General",
  },
  {
    id: "Department-wise",
    label: "Department-wise",
    value: "Department-wise",
  },
];
// durationOption

const durationInOption = [
  {
    value: "1",
    label: "Days",
  },
  {
    value: "2",
    label: "Months",
  },
  {
    value: "3",
    label: "Years",
  },
];

function FeedbackCreationModal(props) {
  const schema = yup.object().shape({
    templateName: yup.string().required("Required"),

    templateNameHindi: yup
      .string()
      .when("isMultiLangauge", (isMultiLangauge) => {
        if (isMultiLangauge === true) {
          console.log("isMultiLangauge123", isMultiLangauge);
          return yup.string().required("Required");
        } else {
          return yup.string().notRequired("Required");
        }
      }),
    templateNameMarathi: yup
      .string()
      .when("isMultiLangauge", (isMultiLangauge) => {
        if (isMultiLangauge === true) {
          return yup.string().required("Required");
        } else {
          return yup.string().notRequired("Required");
        }
      }),
    questionSetHeading: yup.string().required("Required"),

    questionSetHeadingHindi: yup
      .string()
      .when("isMultiLangauge", (isMultiLangauge) => {
        if (isMultiLangauge === true) {
          return yup.string().required("Required");
        } else {
          return yup.string().notRequired("Required");
        }
      }),
    questionSetHeadingMarathi: yup
      .string()
      .when("isMultiLangauge", (isMultiLangauge) => {
        if (isMultiLangauge === true) {
          return yup.string().required("Required");
        } else {
          return yup.string().notRequired("Required");
        }
      }),

    questionSetFootNote: yup.string().required("Required"),

    questionSetFootNoteHindi: yup
      .string()
      .when("isMultiLangauge", (isMultiLangauge) => {
        console.log("isMultiLangauge", isMultiLangauge);
        if (isMultiLangauge === true) {
          return yup.string().required("Required");
        } else {
          return yup.string().notRequired("Required");
        }
      }),
    questionSetFootNoteMarathi: yup
      .string()
      .when("isMultiLangauge", (isMultiLangauge) => {
        if (isMultiLangauge === true) {
          return yup.string().required("Required");
        } else {
          return yup.string().notRequired("Required");
        }
      }),

    conclusion: yup
      .array()
      .when("hasConclusionQuestion", (hasConclusionQuestion, schema) => {
        return hasConclusionQuestion === true
          ? schema.of(
              yup.object().shape({
                conclusionAnswers: yup
                  .string()
                  .required("Required")
                  .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric are allowed"),
              })
            )
          : schema;
      }),
    conclusionQuestion: yup
      .string()
      .when("hasConclusionQuestion", (hasConclusionQuestion) => {
        if (hasConclusionQuestion === true) {
          return yup.string().required("Required");
        }
      }),
    department: yup.object().when(["setType"], (setType) => {
      if (setType !== "General") {
        return yup.object().nullable().required("Required").shape({
          value: yup.string(),
          label: yup.string(),
        });
      } else if (setType === "General") {
        return yup.object().nullable().notRequired("").shape({
          value: yup.string(),
          label: yup.string(),
        });
      }
    }),
  });

  const defaultValues = {
    templateName: "",
    templateNameHindi: "",
    templateNameMarathi: "",
    questionsetfor: "Consultant",
    reviewLabel: "",
    createQuestion: "",
    createQuestionHindi: "",
    createQuestionMarathi: "",
    conclusion: [
      {
        conclusionAnswers: "",
        conclusionAnswersHindi: "",
        conclusionAnswersMarathi: "",
      },
    ],
    patientType: "OPD",
    setType: "General",
    department: null,
    questionsDepartment: null,
    durationIn: null,
    hasConclusionQuestion: false,
    templateValidityDuration: "",
    questionSetHeading: "",
    questionSetHeadingHindi: "",
    questionSetHeadingMarathi: "",
    questionSetFootNote: "",
    questionSetFootNoteHindi: "",
    questionSetFootNoteMarathi: "",
    conclusionQuestion: "",
    conclusionQuestionHindi: "",
    conclusionQuestionMarathi: "",
    isPublished: false,
    isMultiLangauge: false,
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "conclusion",
  });

  // local variables
  let token = JSON.parse(localStorage.getItem("userInfo"));

  let questionsetValue = watch("questionsetfor");
  let hasConclusionQuestionValue = watch("hasConclusionQuestion");
  let createQuestionValue = watch("createQuestion");
  let templateValidityDurationValue = watch("templateValidityDuration");
  let setTypeValue = watch("setType");
  let isMultiLangaugeValue = watch("isMultiLangauge");
  let isSurveyPublished = watch("isPublished");
  // state variables
  const [openTeplateModal, setOpenTemplateModal] = useState(false);
  const [createQuestionsData, setCreateQuestionsData] = useState([]);
  const [deleteRow, setDeleteRow] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);
  const [selectedScaleType, setSelectedScaleType] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [openPostConfirmationModal, setOpenPostConfirmationModal] = useState(
    false
  );
  // functions
  const hanldleCloseTemplateModal = () => setOpenTemplateModal(false);

  const handleQustionAdd = () => {
    let questionDepartment = getValues("questionsDepartment");
    let questionForMarathi = getValues("createQuestionMarathi");
    let questionForHindi = getValues("createQuestionHindi");
    let tempArr = [...createQuestionsData];
    if (isMultiLangaugeValue === false && createQuestionValue !== "") {
      const isDuplicate = tempArr.some(
        (Questions) => Questions.Questions === createQuestionValue
      );

      if (isDuplicate) {
        warningAlert(
          "Question is already exists. Please choose different Question."
        );
        return;
      }
      let obj = {
        id: tempArr.length + 1,
        Arrange: "",
        Department:
          questionDepartment !== null ? questionDepartment.label : null,
        depId: questionDepartment !== null ? questionDepartment.id : null,
        Questions: CapitalizeStatement(createQuestionValue),
      };

      // if (questionDepartment !== null) {
      //   obj.Department = questionDepartment.label;
      //   obj.depId = questionDepartment.id;
      // } else {
      //   // obj.Department = questionDepartment.label;
      //   obj.depId = null;
      // }
      tempArr.push(obj);
      tempArr.sort((a, b) => a - b);
      setCreateQuestionsData(tempArr);
      setValue("createQuestion", "");
      setValue("questionsDepartment", null);
    } else if (
      createQuestionValue !== "" &&
      questionForMarathi !== "" &&
      questionForHindi !== ""
    ) {
      const isDuplicate = tempArr.some(
        (Questions) => Questions.Questions === createQuestionValue
      );

      const isDuplicateHindi = tempArr.some(
        (Questions) => Questions.QuestionsHindi === questionForHindi
      );
      const isDuplicateMarathi = tempArr.some(
        (Questions) => Questions.QuestionsMarathi === questionForMarathi
      );
      if (isDuplicateHindi) {
        warningAlert(
          "Question is already exists. Please choose different Question."
        );
        return;
      }
      if (isDuplicateMarathi) {
        warningAlert(
          "Question is already exists. Please choose different Question."
        );
        return;
      }
      console.log(
        "isDuplicate",
        isDuplicate,
        isDuplicateHindi,
        isDuplicateMarathi
      );
      let obj = {
        id: tempArr.length + 1,
        Arrange: "",
        Department:
          questionDepartment !== null ? questionDepartment.label : null,
        depId: questionDepartment !== null ? questionDepartment.id : null,
        Questions: CapitalizeStatement(createQuestionValue),
        QuestionsHindi: CapitalizeStatement(questionForHindi),
        QuestionsMarathi: CapitalizeStatement(questionForMarathi),
      };

      // if (questionDepartment !== null) {
      //   obj.Department = questionDepartment.label;
      //   obj.depId = questionDepartment.id;
      // } else {
      //   // obj.Department = questionDepartment.label;
      //   obj.depId = null;
      // }
      tempArr.push(obj);
      tempArr.sort((a, b) => a - b);
      setCreateQuestionsData(tempArr);
      setValue("createQuestion", "");
      setValue("createQuestionHindi", "");
      setValue("createQuestionMarathi", "");
      setValue("questionsDepartment", null);
    }
    if (isMultiLangaugeValue === true && questionForHindi !== "") {
      clearErrors(["createQuestionHindi"]);
    } else {
      setError("createQuestionHindi", { type: "custom", message: "Required" });
    }
    if (isMultiLangaugeValue === true && questionForMarathi !== "") {
      clearErrors(["createQuestionMarathi"]);
    } else {
      setError("createQuestionMarathi", {
        type: "custom",
        message: "Required",
      });
    }

    if (createQuestionValue !== "") {
      clearErrors(["createQuestion"]);
    } else if (createQuestionValue === "") {
      setError("createQuestion", { type: "custom", message: "Required" });
    }
  };

  const deleteRecord = () => {
    if (deleteRow !== null) {
      let newTemplateData = [...createQuestionsData];
      newTemplateData.splice(deleteRow, 1);
      setCreateQuestionsData(newTemplateData);
      handleCloseDeletConfirmationModal();
    }
  };
  // close save confirmation modal
  const handleClosePostConfirmationModal = () => {
    if (openPostConfirmationModal) {
      setOpenPostConfirmationModal(false);
    }
  };

  const handleCloseDeletConfirmationModal = () => {
    if (openDeleteConfirmationModal) {
      setOpenDeleteConfirmationModal(false);
    }
  };

  //table arrange sequence
  const handleRowReorder = (startIndex, endIndex) => {
    const updatedRows = [...createQuestionsData];
    const [movedRow] = updatedRows.splice(startIndex, 1);
    updatedRows.splice(endIndex, 0, movedRow);
    setCreateQuestionsData(updatedRows);
  };

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (index) => (event) => {
    event.preventDefault();
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const startIndex = Number(event.dataTransfer.getData("text/plain"));
    handleRowReorder(startIndex, index);
  };

  function transformArray(inputArray) {
    const groupedQuestions = {};

    inputArray.forEach((item) => {
      const departmentId = item.departmentId ? item.departmentId.id : "default";
      const question = item.question;
      const questionMarathiValue = item?.questionMarathi;
      const questionHindiValue = item?.questionHindi;

      if (!groupedQuestions[departmentId]) {
        groupedQuestions[departmentId] = {
          departmentId:
            departmentId === "default" ? undefined : { id: departmentId },
          question: [],
          questionMarathi: [],
          questionHindi: [],
        };
      }

      groupedQuestions[departmentId].question.push(question);
      if (isMultiLangaugeValue) {
        groupedQuestions[departmentId].questionMarathi.push(
          questionMarathiValue
        );
        groupedQuestions[departmentId].questionHindi.push(questionHindiValue);
      }
    });

    for (const depId of Object.keys(groupedQuestions)) {
      groupedQuestions[depId].question.sort();
      if (isMultiLangaugeValue) {
        groupedQuestions[depId].questionMarathi.sort();
        groupedQuestions[depId].questionHindi.sort();
      }
    }

    const resultArray = Object.values(groupedQuestions);
    console.log("resultArray", resultArray);
    return resultArray;
  }

  console.log("isDirty1234567", errors, isDirty);
  console.log("selectedInfo", props);
  const onSubmit = (dataObj) => {
    // conclusion question set
    let tempConclusionQuestionSet = [];
    if (hasConclusionQuestionValue) {
      for (let obj of dataObj.conclusion) {
        let tempObj = {
          // id: obj.conclusionAnswers,
          label: obj.conclusionAnswers,
          value: obj.conclusionAnswers,
          labelMarathi: obj.conclusionAnswersMarathi,
          labelHindi: obj.conclusionAnswersHindi,
        };
        tempConclusionQuestionSet.push(tempObj);
      }
    }
    // createQuestionsData set of questions
    let tempCreateQuestionsSet = [];
    // scale type
    let tempScaleArr = [];

    if (selectedScaleType !== null) {
      console.log("selectedScaleType", selectedScaleType);
      for (let obj of selectedScaleType?.PreView ||
        selectedScaleType?.preView ||
        JSON.parse(selectedScaleType?.preView)) {
        let tempObj = {
          reviewLabel: obj.label,
          value: obj.value,
        };
        tempScaleArr.push(tempObj);
      }

      if (createQuestionsData.length > 0) {
        console.log("createQuestionsData", createQuestionsData);
        for (let obj of createQuestionsData) {
          let tempObj = {
            departmentId: { id: obj.depId },
            question: CapitalizeStatement(obj.Questions),
            questionHindi: CapitalizeStatement(obj.QuestionsHindi),
            questionMarathi: CapitalizeStatement(obj.QuestionsMarathi),
          };
          tempCreateQuestionsSet.push(tempObj);
        }
        const transformedArray = transformArray(tempCreateQuestionsSet);
        let requiredObj = {
          templateFor: CapitalizeStatement(dataObj.questionsetfor),
          templateName: CapitalizeStatement(dataObj.templateName),
          templateNameMarathi: CapitalizeStatement(dataObj.templateNameMarathi),
          templateNameHindi: CapitalizeStatement(dataObj.templateNameHindi),
          heading: CapitalizeStatement(dataObj.questionSetHeading),
          headingMarathi: CapitalizeStatement(
            dataObj.questionSetHeadingMarathi
          ),
          headingHindi: CapitalizeStatement(dataObj.questionSetHeadingHindi),
          footer: CapitalizeStatement(dataObj.questionSetFootNote),
          footerMarathi: CapitalizeStatement(
            dataObj.questionSetFootNoteMarathi
          ),
          footerHindi: CapitalizeStatement(dataObj.questionSetFootNoteHindi),
          isPublished: isSurveyPublished,
          conclusionQuestion: CapitalizeStatement(dataObj.conclusionQuestion),
          conclusionQuestionMarathi: CapitalizeStatement(
            dataObj.conclusionQuestionMarathi
          ),
          conclusionQuestionHindi: CapitalizeStatement(
            dataObj.conclusionQuestionHindi
          ),
          createdBy: token.empId,
          departmentId:
            dataObj.setType === "Department-wise"
              ? {
                  id: dataObj.department?.id,
                }
              : null,
          // not clear from manik
          answerPatternId: {
            id: selectedScaleType.id,
          },
          isMultiLanguage: isMultiLangaugeValue,
          //
          questionList: transformedArray,
          hasConclusionQues: dataObj.hasConclusionQuestion,
          isGeneral: dataObj.setType,
        };

        if (dataObj.hasConclusionQuestion) {
          requiredObj.conclusionAnswersList = tempConclusionQuestionSet;
        }
        if (props?.selectedInfoRow !== null) {
          requiredObj.id = props?.selectedInfoRow.Id;
          requiredObj.updatedBy = token.empId;
        }
        if (dataObj.questionsetfor === "Patient") {
          requiredObj.patientType = dataObj.patientType === "OPD" ? "O" : "I";
        }

        if (dataObj.setType === "Department-wise") {
          requiredObj.isGeneral = false;
        } else if (dataObj.setType === "General") {
          requiredObj.isGeneral = true;
        } else if (dataObj.setType === "-") {
          requiredObj.isGeneral = false;
        }
        console.log("requiredObj", requiredObj);

        // if (dataObj.questionsetfor !== "Patient") {
        //   if (dataObj.durationIn.label === "Months") {
        //     requiredObj.templateDurationInDays =
        //       Number(dataObj.templateValidityDuration) * 30;
        //   } else if (dataObj.durationIn.label === "Years") {
        //     requiredObj.templateDurationInDays =
        //       Number(dataObj.templateValidityDuration) * 365;
        //   } else {
        //     requiredObj.templateDuration = Number(
        //       dataObj.templateValidityDuration
        //     );
        //   }
        // }
        setFinalData(requiredObj);
        setOpenPostConfirmationModal(true);
      } else {
        warningAlert("Please Create At Least One Question!");
      }
    } else {
      warningAlert("Please Select Scale Template!");
    }
  };
  // api function
  function postFeedBacks(obj) {
    saveTemplate(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props?.handleClose();
        reset();
        props?.populateTable();
        setOpenPostConfirmationModal(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }
  // calling the  api function
  function addRecord() {
    setOpenPostConfirmationModal(false);
    postFeedBacks();
  }

  function renderActions(row, index) {
    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <button
            type="button"
            onClick={() => {
              setDeleteRow(index);
              setOpenDeleteConfirmationModal(true);
            }}
            className="cursor-pointer"
          >
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
    );
  }

  function renderInput(row, index, header) {
    return (
      <>
        <button
          type="button"
          draggable
          onDragStart={handleDragStart(index)}
          onDragOver={handleDragOver(index)}
          onDrop={handleDrop(index)}
        >
          <DragIndicatorIcon className="text-[#BDBDBD]" />
        </button>
      </>
    );
  }

  useEffect(() => {
    setValue("department", null);
  }, [questionsetValue, setTypeValue]);

  useEffect(() => {
    getDepartmentDropDown().then((response) => {
      setDepartmentOptions(response.data.result);
    });
  }, []);

  // useEffect(() => {
  //   if (templateValidityDurationValue === "") {
  //     setValue("durationIn", null);
  //   } else if (Number(templateValidityDurationValue) >= 365) {
  //     setValue("durationIn", { value: "Years", label: "Years" });
  //   } else if (Number(templateValidityDurationValue) >= 30) {
  //     setValue("durationIn", { value: "Month", label: "Month" });
  //   } else if (Number(templateValidityDurationValue) < 30) {
  //     setValue("durationIn", { value: "Days", label: "Days" });
  //   }
  // }, [templateValidityDurationValue]);

  useEffect(() => {
    if (createQuestionValue !== "") {
      clearErrors(["createQuestion"]);
    }
  }, [createQuestionValue]);

  // edit
  console.log("props?.selectedInfoRow", selectedScaleType);
  useEffect(() => {
    let tempArr = [];
    if (props?.selectedInfoRow !== null) {
      for (let obj of props?.selectedInfoRow["Question Details"]) {
        let findDepartmentOption = departmentOptions.find(
          (value) => value.id === obj.department_id
        );
        let departmentLabel = findDepartmentOption
          ? findDepartmentOption.label
          : "";
        console.log("findDepartmentOption", findDepartmentOption);
        let tempObj = {
          id: obj.qid,
          Arrange: "",
          depId: obj.id,
          Questions: obj.question,
          Department: departmentLabel,
        };
        if (props?.selectedInfoRow?.isMultiLanguage) {
          if (obj.question_hindi !== null) {
            tempObj.QuestionsHindi = obj.question_hindi;
          }
          if (obj.question_marathi !== null) {
            tempObj.QuestionsMarathi = obj.question_marathi;
          }
        }

        tempArr.push(tempObj);
      }

      let findDepartmentOption =
        departmentOptions.length > 0 &&
        departmentOptions.find(
          (value) => value.value === props?.selectedInfoRow?.["Department"]
        );

      setCreateQuestionsData(tempArr);
      setValue("templateName", props?.selectedInfoRow["Template Name"]);
      setValue(
        "templateNameHindi",
        props?.selectedInfoRow["Template Name Hindi"] !== null
          ? props?.selectedInfoRow["Template Name Hindi"]
          : ""
      );
      setValue(
        "templateNameMarathi",
        props?.selectedInfoRow["Template Name Marathi"] !== null
          ? props?.selectedInfoRow["Template Name Marathi"]
          : ""
      );
      setValue(
        "conclusionQuestionHindi",
        props?.selectedInfoRow["Question Heading Hindi"] !== null
          ? props?.selectedInfoRow["Question Heading Hindi"]
          : ""
      );
      setValue(
        "conclusionQuestionMarathi",
        props?.selectedInfoRow["Question Heading Marathi"] !== null
          ? props?.selectedInfoRow["Question Heading Marathi"]
          : ""
      );
      setValue("questionsetfor", props?.selectedInfoRow["Questions For"]);
      setValue(
        "questionSetHeading",
        props?.selectedInfoRow["Question Heading"]
      );
      setValue(
        "questionSetFootNote",
        props?.selectedInfoRow["Question Footer"]
      );
      setValue("patientType", props?.selectedInfoRow["OPD/IPD"]);
      setValue("isMultiLangauge", props?.selectedInfoRow.isMultiLanguage);
      setValue(
        "questionSetFootNoteHindi",
        props?.selectedInfoRow["Question Footer Hindi"] !== null
          ? props?.selectedInfoRow["Question Footer Hindi"]
          : ""
      );
      setValue(
        "questionSetFootNoteMarathi",
        props?.selectedInfoRow["Question Footer Marathi"] !== null
          ? props?.selectedInfoRow["Question Footer Marathi"]
          : ""
      );
      setValue(
        "questionSetHeadingHindi",
        props?.selectedInfoRow["Question Heading Hindi"] !== null
          ? props?.selectedInfoRow["Question Heading Hindi"]
          : ""
      );
      setValue(
        "questionSetHeadingMarathi",
        props?.selectedInfoRow["Question Heading Marathi"] !== null
          ? props?.selectedInfoRow["Question Heading Marathi"]
          : ""
      );
      setValue(
        "setType",
        props?.selectedInfoRow["Question Type"] === true
          ? "General"
          : "Department-wise"
      );
      setValue("department", findDepartmentOption);
      setValue(
        "templateValidityDuration",
        props?.selectedInfoRow.Duration !== null
          ? props?.selectedInfoRow.Duration
          : ""
      );
      setValue(
        "hasConclusionQuestion",
        props?.selectedInfoRow["Has Conclusion Questions"]
      );
      setValue("answerType", props?.selectedInfoRow["Answer Review Type"]);
      let conclusionQueAnswersObj =
        !!props?.selectedInfoRow["Conclusion Question Answer"] &&
        JSON.parse(props?.selectedInfoRow["Conclusion Question Answer"]);
      let conclusionQueAnswersHindiObj =
        !!props?.selectedInfoRow["Conclusion Question Answer Hindi"] &&
        JSON.parse(props?.selectedInfoRow["Conclusion Question Answer Hindi"]);
      let conclusionQueAnswersMarathiObj =
        !!props?.selectedInfoRow["Conclusion Question Answer Marathi"] &&
        JSON.parse(
          props?.selectedInfoRow["Conclusion Question Answer Marathi"]
        );
      if (props?.selectedInfoRow["Has Conclusion Questions"]) {
        setValue(
          "conclusionQuestion",
          props?.selectedInfoRow["Conclusion Question"]
        );
        setValue("conclusion", conclusionQueAnswersObj?.conclusion_answers);
        for (let i = 0; i < conclusionQueAnswersObj?.length; i++) {
          append();
          setValue(
            `conclusion[${i}].conclusionAnswers`,
            conclusionQueAnswersObj[i]?.conclusion_answers
          );
        }
        for (let i = 0; i < conclusionQueAnswersHindiObj?.length; i++) {
          setValue(
            `conclusion[${i}].conclusionAnswersHindi`,
            conclusionQueAnswersObj[i]?.conclusion_answers
          );
        }
        for (let i = 0; i < conclusionQueAnswersMarathiObj?.length; i++) {
          setValue(
            `conclusion[${i}].conclusionAnswersMarathi`,
            conclusionQueAnswersObj[i]?.conclusion_answers
          );
        }
      }
      getAnswerPatternScaleForTemplate(
        props?.selectedInfoRow?.isMultiLanguage
      ).then((response) => {
        let responseTeemplates = JSON.parse(response.data.result[0].preView);
        let findAnsPatternList = response.data.result.find(
          (value) => (value.id = props?.selectedInfoRow.ScaleId)
        );
        console.log("emoji122", findAnsPatternList);
        setSelectedScaleType(findAnsPatternList);
      });
    }
  }, [props?.selectedInfoRow, departmentOptions]);

  // <div className=" w-full ">
  //   <InputField
  //     control={control}
  //     name="templateValidityDuration"
  //     label="Template Validity Duration"
  //   />
  // </div>
  // <div className=" w-full">
  //   <DropdownField
  //     control={control}
  //     name="durationIn"
  //     placeholder="Duration In"
  //     dataArray={durationInOption}
  //   />
  // </div>
  return (
    <>
      <Modal
        open={props?.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="w-[95%] lg:w-[90%] xl:w-[90%] 2xl:w-[95%] h-[95%] 2xl:h-[90%]  overflow-y-auto"
        >
          <CancelPresentationIconButton
            className="bg-[#FFDCDC] rounded-full"
            onClick={() => {
              props?.handleClose();
              setCreateQuestionsData([]);
            }}
          />
          <h1 className="font-Poppins font-semibold text-lg grid items-center justify-center text-[#073763] pb-2">
            New Set Of Questions
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={props?.selectedInfoRow !== null ? true : false}>
              <div className="grid grid-cols-4 xl:grid-cols-3 gap-3">
                <div className="flex space-x-2 items-center w-full col-span-2">
                  <CheckBoxField
                    control={control}
                    name="isMultiLangauge"
                    label="Multi Langauge"
                  />
                  <InputField
                    disabled={props?.selectedInfoRow !== null ? true : false}
                    control={control}
                    name="templateName"
                    label="Template Name *"
                    error={errors.templateName}
                  />
                </div>
                {isMultiLangaugeValue && (
                  <div>
                    <InputField
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      control={control}
                      name="templateNameHindi"
                      label="Template Name Hindi*"
                      error={errors.templateNameHindi}
                    />
                  </div>
                )}
                {isMultiLangaugeValue && (
                  <div>
                    <InputField
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      control={control}
                      name="templateNameMarathi"
                      label="Template Name Marathi*"
                      error={errors.templateNameMarathi}
                    />
                  </div>
                )}
                <div className="flex space-x-2 col-span-4 lg:col-span-2 xl:col-span-1  whitespace-nowrap">
                  <h3 className="whitespace-nowrap text-sm font-semibold pt-1.5">
                    Question Set For &nbsp; : &nbsp;
                  </h3>
                  <fieldset
                    disabled={props?.selectedInfoRow !== null ? true : false}
                    className="pt-1"
                  >
                    <RadioField
                      control={control}
                      name="questionsetfor"
                      dataArray={answerTypeData}
                    />
                  </fieldset>
                </div>
                <div className="flex space-x-4 col-span-2 xl:col-span-1 items-center whitespace-nowrap ">
                  <h4 className="text-sm  font-semibold whitespace-nowrap">
                    Set Type &nbsp; :&nbsp;
                  </h4>
                  <fieldset
                    disabled={props?.selectedInfoRow !== null ? true : false}
                  >
                    <RadioField
                      control={control}
                      name="setType"
                      dataArray={setTypeData}
                    />
                  </fieldset>
                </div>

                <div className="w-full col-span-2 xl:col-span-1">
                  <TextField
                    control={control}
                    disabled={props?.selectedInfoRow !== null ? true : false}
                    name="questionSetHeading"
                    label="Question Set Heading"
                    {...register("questionSetHeading")}
                    error={errors.questionSetHeading}
                    rows={3}
                    fullWidth
                    multiline
                    size="small"
                    sx={{
                      "& .MuiFormLabel-root": {
                        fontSize: "14px",
                        position: "absolute",
                        top: "-2px",
                      },
                      fontSize: "14px",
                    }}
                  />
                </div>

                {isMultiLangaugeValue && (
                  <div className="col-span-2 xl:col-span-1 w-full">
                    <TextField
                      control={control}
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      name="questionSetHeadingHindi"
                      label="Question Set Heading Hindi"
                      {...register("questionSetHeadingHindi")}
                      error={errors.questionSetHeadingHindi}
                      rows={3}
                      fullWidth
                      multiline
                      size="small"
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                        fontSize: "14px",
                      }}
                    />
                  </div>
                )}
                {isMultiLangaugeValue && (
                  <div className="col-span-2 xl:col-span-1 w-full">
                    <TextField
                      control={control}
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      name="questionSetHeadingMarathi"
                      label="Question Set Heading Marathi"
                      {...register("questionSetHeadingMarathi")}
                      error={errors.questionSetHeadingMarathi}
                      rows={3}
                      fullWidth
                      multiline
                      size="small"
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                        fontSize: "14px",
                      }}
                    />
                  </div>
                )}
                <div className="w-full col-span-2 xl:col-span-1">
                  <TextField
                    name="questionSetFootNote"
                    disabled={props?.selectedInfoRow !== null ? true : false}
                    label="Question Set Footnote"
                    {...register("questionSetFootNote")}
                    error={errors.questionSetFootNote}
                    rows={3}
                    fullWidth
                    multiline
                    size="small"
                    sx={{
                      "& .MuiFormLabel-root": {
                        fontSize: "14px",
                        position: "absolute",
                        top: "-2px",
                      },
                      fontSize: "14px",
                    }}
                  />
                </div>

                {isMultiLangaugeValue && (
                  <div className="w-full col-span-2 xl:col-span-1">
                    <TextField
                      name="questionSetFootNoteHindi"
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      label="Question Set Footnote Hindi"
                      {...register("questionSetFootNoteHindi")}
                      error={errors.questionSetFootNoteHindi}
                      rows={3}
                      fullWidth
                      multiline
                      size="small"
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                        fontSize: "14px",
                      }}
                    />
                  </div>
                )}
                {isMultiLangaugeValue && (
                  <div className="w-full col-span-2 xl:col-span-1">
                    <TextField
                      name="questionSetFootNoteMarathi"
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      label="Question Set Footnote Marathi"
                      {...register("questionSetFootNoteMarathi")}
                      error={errors.questionSetFootNoteMarathi}
                      rows={3}
                      fullWidth
                      multiline
                      size="small"
                      sx={{
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                        fontSize: "14px",
                      }}
                    />
                  </div>
                )}
                {questionsetValue === "Patient" ? (
                  <div className="flex space-x-4 col-span-2 xl:col-span-1">
                    <h4 className="text-sm pt-0.5 font-semibold">
                      Patient Type&nbsp;:&nbsp;
                    </h4>
                    <fieldset
                      disabled={props?.selectedInfoRow !== null ? true : false}
                    >
                      <RadioField
                        control={control}
                        name="patientType"
                        dataArray={patientTypeData}
                      />
                    </fieldset>
                  </div>
                ) : (
                  ""
                )}

                {setTypeValue === "Department-wise" ? (
                  <div className=" flex space-x-2 w-full items-center col-span-2 xl:col-span-1">
                    <DropdownField
                      isDisabled={
                        props?.selectedInfoRow !== null ? true : false
                      }
                      control={control}
                      name="department"
                      placeholder="Department"
                      dataArray={departmentOptions}
                      error={errors.department}
                    />
                    <>
                      <CheckBoxField
                        control={control}
                        name="isPublished"
                        label="Is Published"
                      />
                    </>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-span-2 xl:col-span-1 flex justify-between items-center">
                  <button
                    type="button"
                    className="border border-[#073763] text-[#073763] rounded-full p-1 px-2 text-[13px] text-center whitespace-nowrap"
                    onClick={() => {
                      setOpenTemplateModal(true);
                    }}
                  >
                    Select Scale that you want to apply to template
                  </button>
                  <div className="flex space-x-2 items-center">
                    <div>
                      {props?.selectedInfoRow?.["Answer Scale"] === "Star" ||
                      selectedScaleType?.ansType === "Star" ||
                      selectedScaleType === "Star" ? (
                        <button
                          type="button"
                          className="px-5 rounded py-[5px] bg-[#D0E9FF] text-center flex space-x-2 text-sm"
                        >
                          <img src={StarPng} className="h-6 text-center" />
                        </button>
                      ) : props?.selectedInfoRow?.["Answer Scale"] ===
                          "Chips" ||
                        selectedScaleType?.ansType === "Chips" ||
                        selectedScaleType === "Chips" ? (
                        <button
                          type="button"
                          className="px-5 rounded py-[5px] bg-[#D0E9FF] text-center flex space-x-2 text-sm"
                        >
                          <h5 className="border px-2 rounded bg-white">1</h5>
                          <h5 className="border px-2 rounded bg-white">2</h5>
                          <h5 className="border px-2 rounded bg-white">3</h5>
                        </button>
                      ) : props?.selectedInfoRow?.["Answer Scale"] ===
                          "Emoji" ||
                        selectedScaleType?.ansType === "Emoji" ||
                        selectedScaleType === "Emoji" ? (
                        <button
                          type="button"
                          className="px-5 rounded py-[5px] bg-[#D0E9FF] text-center flex space-x-2 text-sm"
                        >
                          <img src={Average} className="h-6" />
                        </button>
                      ) : null}
                    </div>

                    {/*  <div>
                      {props?.selectedInfoRow?.["Answer Scale"]=== "Emoji"||selectedScaleType?.ansType === "Star" ||
                      selectedScaleType === "Star"||props?.selectedInfoRow?.["Answer Scale"]=== "Star" ? (
                        <button
                          type="button"
                          className={`px-5 rounded py-[5px] bg-[#D0E9FF] text-center flex space-x-2 text-sm}`}
                        >
                          <img src={StarPng} className="h-6 text-center" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {props?.selectedInfoRow?.["Answer Scale"]=== "Emoji" ||selectedScaleType?.ansType === "Emoji" ||
                      selectedScaleType === "Emoji"? (
                        <button
                          type="button"
                          className={`px-5 rounded py-[5px] bg-[#D0E9FF] text-center flex space-x-2 text-sm}`}
                        >
                          <img src={Average} className="h-6" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {props?.selectedInfoRow?.["Answer Scale"]=== "Chips"||selectedScaleType?.ansType === "Chips" ||
                      selectedScaleType === "Chips" ? (
                        <button
                          type="button"
                          className={`px-5 rounded py-[5px] bg-[#D0E9FF] text-center flex space-x-2 text-sm}`}
                        >
                          <h5 className="border px-2 rounded bg-white">1</h5>
                          <h5 className="border px-2 rounded bg-white">2</h5>
                          <h5 className="border px-2 rounded bg-white">3</h5>
                        </button>
                      ) : (
                        ""
                      )}
                      </div>*/}
                  </div>
                </div>
                <div className="">
                  <CheckBoxField
                    disabled={props?.selectedInfoRow !== null ? true : false}
                    control={control}
                    name="hasConclusionQuestion"
                    label="Has Conclusion Question"
                  />
                </div>
              </div>
            </fieldset>

            <Divider
              sx={{ margin: "4px", background: "black" }}
              variant="middle"
            />
            <div className="flex justify-between items-center mt-3">
              <div className="flex items-center space-x-2 w-full ">
                <div className="w-6/12">
                  <DropdownField
                    control={control}
                    name="questionsDepartment"
                    placeholder="Department"
                    dataArray={departmentOptions}
                    error={errors.questionsDepartment}
                  />
                </div>
                <div className=" w-6/12">
                  <InputField
                    control={control}
                    name="createQuestion"
                    label="Create Question"
                    error={errors.createQuestion}
                  />
                </div>

                {isMultiLangaugeValue && (
                  <div className=" w-6/12">
                    <InputField
                      control={control}
                      name="createQuestionHindi"
                      label="Create Question Hindi"
                      error={errors.createQuestionHindi}
                    />
                  </div>
                )}
                {isMultiLangaugeValue && (
                  <div className=" w-6/12">
                    <InputField
                      control={control}
                      name="createQuestionMarathi"
                      label="Create Question Marathi"
                      error={errors.createQuestionMarathi}
                    />
                  </div>
                )}

                <CommonButton
                  type="button"
                  label="Add"
                  className="bg-[#073763] text-white w-full"
                  onClick={() => {
                    handleQustionAdd();
                  }}
                />
              </div>
            </div>
            {createQuestionsData?.length > 0 ? (
              <CommonTransactionTable
                dataResult={createQuestionsData}
                renderInput={renderInput}
                editableColumns={["Arrange"]}
                renderActions={renderActions}
                highlightRow={false}
                removeHeaders={["id", "depId"]}
                tableClass={"h-[180px] lg:h-[200px] 2xl:h-[250px]"}
              />
            ) : (
              <h3 className="text-center my-24 text-gray-600">
                No Questions Added
                <span className="animate-pulse">...</span>
              </h3>
            )}
            {hasConclusionQuestionValue ? (
              <div className="p-2 border rounded mt-2">
                <h4 className="font-semibold text-sm mb-2">
                  Conclusion Question
                </h4>
                <div className="flex space-x-2 items-center">
                  <InputField
                    control={control}
                    disabled={props?.selectedInfoRow !== null ? true : false}
                    name="conclusionQuestion"
                    label="Conclusion Question"
                    error={errors.conclusionQuestion}
                  />
                  {isMultiLangaugeValue && (
                    <InputField
                      control={control}
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      name="conclusionQuestionHindi"
                      label="Conclusion Question Hindi"
                      error={errors.conclusionQuestionHindi}
                    />
                  )}
                  {isMultiLangaugeValue && (
                    <InputField
                      control={control}
                      disabled={props?.selectedInfoRow !== null ? true : false}
                      name="conclusionQuestionMarathi"
                      label="Conclusion Question Marathi"
                      error={errors.conclusionQuestionMarathi}
                    />
                  )}
                </div>

                <div className="flex flex-wrap gap-2 mt-3 w-full items-center">
                  {fields.map((item, index) => (
                    <div
                      key={item.id}
                      className={
                        isMultiLangaugeValue
                          ? "flex gap-2  w-[95%]"
                          : "flex gap-2  w-[47%]"
                      }
                    >
                      <div className="w-full flex space-x-2 items-center">
                        <InputField
                          disabled={
                            props?.selectedInfoRow !== null ? true : false
                          }
                          control={control}
                          name={`conclusion.[${index}].conclusionAnswers`}
                          label="Conclusion Question Answer "
                          error={errors.conclusion?.[index]?.conclusionAnswers}
                        />
                        {isMultiLangaugeValue && (
                          <InputField
                            disabled={
                              props?.selectedInfoRow !== null ? true : false
                            }
                            control={control}
                            name={`conclusion.[${index}].conclusionAnswersHindi`}
                            label="Conclusion Question Answer Hindi"
                            error={
                              errors.conclusion?.[index]?.conclusionAnswersHindi
                            }
                          />
                        )}
                        {isMultiLangaugeValue && (
                          <InputField
                            disabled={
                              props?.selectedInfoRow !== null ? true : false
                            }
                            control={control}
                            name={`conclusion.[${index}].conclusionAnswersMarathi`}
                            label="Conclusion Question Answer Marathi"
                            error={
                              errors.conclusion?.[index]
                                ?.conclusionAnswersMarathi
                            }
                          />
                        )}
                      </div>
                      {fields.length > 1 && (
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="text-customRed"
                        >
                          <RemoveCircleIcon />
                        </button>
                      )}
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={() => {
                      append({ conclusionAnswers: "" });
                    }}
                    className="text-[#3B82F6]"
                  >
                    <AddCircleIcon />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex space-x-2 justify-end mt-2">
              <CommonButton
                type="button"
                label="Reset"
                className="text-customRed border border-customRed"
                onClick={() => {
                  reset();
                  setCreateQuestionsData([]);
                }}
              />
              <CommonButton
                type="submit"
                label={props?.selectedInfoRow === null ? "Save" : "Update"}
                className="text-white bg-customGreen"
              />
            </div>
          </form>
        </Box>
      </Modal>

      {openTeplateModal ? (
        <TemplateModal
          open={openTeplateModal}
          handleClose={hanldleCloseTemplateModal}
          setSelectedScaleType={setSelectedScaleType}
          isMultiLangauge={isMultiLangaugeValue}
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={openDeleteConfirmationModal}
        confirmationHandleClose={handleCloseDeletConfirmationModal}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure Want To Delete This Item ?"
        confirmationButtonMsg="Delete"
      />
      <ConfirmationModal
        confirmationOpen={openPostConfirmationModal}
        confirmationHandleClose={handleClosePostConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}

export default FeedbackCreationModal;
