import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const fetchConsentTemplatesLstAutoComplete = (
  searchString,
  isCertificate
) => {
  return apiClient.get(
    `/opTheaterMaster/getConsentTemplatesSearch/${searchString}/${isCertificate}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchTemplateList = (page, size, templateId, isCertificate) => {
  return apiClient.get(
    `/opTheaterMaster/getConsentTemplates/${page}/${size}/${templateId}/${isCertificate}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchTemplateStatus = (consentTempId, status) => {
  return apiClient.get(
    `/opTheaterMaster/updateConsentTempStatus/${consentTempId}/${status}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveConsentTemplete = (finalObj) => {
  return apiClient.post(`/opTheaterMaster/saveConsentTemplate`, finalObj, {
    headers: authHeader(),
  });
};

