import React, { useRef } from "react";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";
import { CurrentStoreStockPrint } from "../../services/inventoryStockServices/InventoryStockService";

export default function CurrentStockPrint(props) {
  const printRef = useRef(null);

  const [dataIs, setDataIs] = React.useState();

  React.useEffect(() => {
    CurrentStoreStockPrint(props.storeVal?.id)
      .then((response) => response.data)
      .then((res) => {
        setDataIs(res.result);
      });
  }, [props]);

  React.useEffect(() => {
    dataIs && printRef && GeneratePrint(printRef);
  }, [dataIs]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center ">
            <h1 className=" font-semibold text-[15px] ">
              Inamdar Multispeciality Hospital
            </h1>
            <h1 className=" text-[11px] ">
              Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
            </h1>
          </div>
          <div className=" text-center text-[14px] my-3 border-b-[1px]  font-semibold border-black p-">
            Current Stock
          </div>
          <h1 className=" text-[11px]"> 10 TH FLOOR</h1>
          <div className=" grid grid-cols-[40%_8%_8%_8%_5%_5%_5%_12%_9%] mt-2 text-[11px] font-semibold border-[1px] border-black">
            <div className=" border-r-[1px] border-black p-1">Item Name</div>
            <div className=" border-r-[1px] border-black p-1">Balance</div>
            <div className=" border-r-[1px] border-black p-1">Min</div>
            <div className=" border-r-[1px] border-black p-1">Max</div>
            <div className=" border-r-[1px] border-black p-1">M</div>
            <div className=" border-r-[1px] border-black p-1">E</div>
            <div className=" border-r-[1px] border-black p-1">N</div>
            <div className=" border-r-[1px] border-black p-1">Rack</div>
            <div className=" border-r-[1px] border-black p-1">Difference</div>
          </div>
        </thead>
        <tbody className="w-full">
          <div>
            {dataIs?.map((data) => {
              return (
                <div className="  grid grid-cols-[40%_8%_8%_8%_5%_5%_5%_12%_9%] border-b-[1px] border-l-[1px] border-black text-[11px]">
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.["Item Name"]}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.Balance}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.Min}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.Max}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.M}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.E}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.N}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black p-1">
                    {data?.Rack}
                  </div>
                  <div className=" ml-1 border-r-[1px] border-black">
                    {data?.Difference}
                  </div>
                </div>
              );
            })}
          </div>
        </tbody>
      </table>
    </div>
  );
}
