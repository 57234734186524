import React, { useEffect, useRef, useState } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { NursesNotePrint } from "../services/cliinicalChartServices/nursingnoteServices/NursingNoteServices";

export default function NursesNotesPrint(props) {
  const printRef = useRef(null);

  //Nnursing Note
  const [nursingNoteData, setNursingNoteData] = useState(null);

  useEffect(() => {
    const postObj = {
      admissionId: props.admissionId,
      fromDate: props.fromDate,
      toDate: props.toDate,
      fromTime: props.fromTime,
      toTime: props.toTime,
      id: props?.ID,
    };
    NursesNotePrint(postObj)
      .then((response) => response.data)
      .then((res) => {
        setNursingNoteData(res.result);
      });
  }, [props]);

  useEffect(() => {
    !!nursingNoteData && GeneratePrint(printRef);
  }, [nursingNoteData]);

  return (
    <div ref={printRef}>
      <table className=" w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" flex justify-between">
            <div className=" grid grid-cols-2 ">
              <div className=" font-semibold text-[11px]">
                MR No.
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                :
              </div>
              <div className="text-[11px]">{nursingNoteData?.MRNo}</div>
              <div className=" font-semibold text-[11px]">
                Patient Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
              </div>
              <div className="text-[11px]">{nursingNoteData?.PatientName}</div>
              <div className=" font-semibold text-[11px]">
                Age
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                :
              </div>
              <div className="text-[11px]">{nursingNoteData?.AgeYear}</div>
              <div className=" font-semibold text-[11px]">
                Admission Date &nbsp;&nbsp;:
              </div>
              <div className="text-[11px]">
                {nursingNoteData?.AdmissionDate}
              </div>
            </div>
            <div className="font-semibold text-[15px] ">
              <InamdarHospiLogo />
            </div>
          </div>

          <div className="w-full font-semibold text-center border-t-[1px] border-b-[1px] border-black myy-1 text-[18px]  whitespace-nowrap">
            Nurses Note
          </div>
        </thead>
        <tbody className="w-full">
          <div>
            <div className=" flex text-center gap-24 pyy-2 border-b-[1px]  border-black">
              <div className=" font-semibold text-[13px] ">Date</div>
              <div className=" font-semibold text-[13px] ml-3 ">Time</div>
              <div className=" font-semibold text-[13px] ">Note</div>
            </div>
            <div className=" grid grid-cols-[12%_10%_88%] ">
              {nursingNoteData?.NursingNote.length > 0 &&
                nursingNoteData?.NursingNote?.map((data) => {
                  return (
                    <>
                      <div className="  text-[13px]">{data.Date}</div>
                      <div className="  text-[13px]">{data.Time}</div>
                      <div
                        className="whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{
                          __html: data?.Note,
                        }}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}
