import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchColourTubeTests,
  fetchListOfOrders,
  fetchSampleCollectionOrderListSearch,
  saveColorTubeTemperature,
} from "../../services/sampleCollectionForColourTubeService/SampleCollectionForColourTubeServices";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { format, isAfter } from "date-fns";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

const SampleType = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Collected",
    label: "Collected",
  },
  {
    id: 0,
    value: "Not Collected",
    label: "Not Collected",
  },
];

let spiner1 = false;
let spiner2 = false;
const SampleCollectionForColourTube = (props) => {
  let location = useLocation();
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var UnitId = token.unitId;

  const [privilege, setPrivilege] = useState("");
  const [userActions, setUserActions] = useState([]);

  //dates
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [options, setOptions] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  //1st table (listOfOrders)
  const [listOfOrdersTableData, setListOfOrdersTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [selectedID, setSelectedID] = useState(null);

  //2nd table (tests)
  const [testsTableData, setTestsTableData] = useState([]);
  const [sumByColourTube, setSumByColourTube] = useState([]);

  ///
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      sampleType: 0,
      totalML: "",
      patientListSearch: "",
      isUrgent: false,
    },
  });

  const { control, watch, resetField, register } = methods;

  let sampleTypeValue = watch("sampleType");
  let PatientListSearch = watch("patientListSearch");
  let isUrgent_selected = watch("isUrgent");

  //handleChange
  const handleChangeSearch = (searchString) => {
    const searchObj = {
      fromDate: selectedFromDate,
      searchString: searchString,
      toDate: selectedToDate,
    };
    setSearchString(searchString);
    searchString && getListOfOrdersTableData();

    fetchSampleCollectionOrderListSearch(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setOptions(res.result);
      });
  };

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(
        props.menuId ? props.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    ///
    setSearchString(props?.MRNo);
  }, []);

  ///order details

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      getListOfOrdersTableData(); ///rquired to pass false and zero
  }, [selectedFromDate, sampleTypeValue, selectedToDate, PatientListSearch]);

  const getListOfOrdersTableData = (forPagination) => {
    setTestsTableData([]);
    spiner1 = true;
    const OrderObj = {
      category: 0,
      toDate: format(
        selectedToDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ),
      fromDate: format(
        selectedFromDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ),
      opdIpd: props?.opdIpd ? props?.opdIpd : 4,
      sampleType: Number(sampleTypeValue),
      searchString: searchString,
      admissionId: PatientListSearch?.id || props?.admissionId,
      testType: 0,
      unitId: UnitId,
      size: rowsPerPage,
      page: !forPagination ? 0 : page,
    };

    fetchListOfOrders(OrderObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setListOfOrdersTableData((prevData) => [...prevData, ...res.result]);
        } else {
          setListOfOrdersTableData(res.result);
          setPage(0);
        }
        spiner1 = false;

        setCount(res.count);
      })
      .catch((res) => {
        spiner1 = false;
      });
  };
  ///tests

  const getTestsTableData = (e, row) => {
    spiner2 = true;
    if (row !== null) {
      setSelectedOrderId(row?.OrderId);
      fetchColourTubeTests(row?.OrderId)
        .then((response) => response.data)
        .then((res) => {
          setTestsTableData(res.result);
          spiner2 = false;
        });
    }
  };

  const renderActionsListOfOrdersTable = (row, index) => {
    return (
      <>
        <div className="flex gap-1 items-center cursor-pointer">
          <Checkbox
            size="small"
            type="checkbox"
            checked={selectedID === index ? true : false}
            onClick={(e) => {
              setSelectedID(index);
              getTestsTableData(e, row);
            }}
          />
        </div>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...testsTableData];
      updatedList[index]["Test Limit"] = Number(newValue);

      setTestsTableData(updatedList);
    };

    return (
      <>
        {header === "Test Limit" && (
          <span>
            <input
              className={
                "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
              }
              onChange={(e) => handleTableQuantityChange(index, e.target.value)}
              value={row["Test Limit"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
              name={`Quantity${[index]}`}
            />
          </span>
        )}
      </>
    );
  };

  useEffect(() => {
    const sumByTube = {};

    for (const item of testsTableData) {
      const { "Tube Name": tubeName, "Test Limit": testLimit } = item;
      if (!sumByTube[tubeName]) {
        sumByTube[tubeName] = 0;
      }
      sumByTube[tubeName] += testLimit;
    }

    const sumByTubeArr = Object.keys(sumByTube)
      .map((tubeName) => ({
        "Tube Name": tubeName,
        "Sum TestLimit": sumByTube[tubeName],
      }))
      .filter((item) => item["Tube Name"] !== "null");

    console.log("sumByTubeArr", sumByTubeArr);

    setSumByColourTube(sumByTubeArr);
  }, [testsTableData]);

  const onSubmit = () => {
    let containsTrue = testsTableData.some((item) => {
      return item.isChecked === true && item.isChecked !== undefined;
    });
    containsTrue
      ? setOpenConfirmationModal(true)
      : warningAlert("Please Select Test...");
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    let postArrey = [];
    for (let item of testsTableData) {
      item.isChecked &&
        postArrey.push({
          atFloorCollectBy: 0,
          atLabCollectBy: 0,
          collectAtFloor_DateTime: new Date(),
          collectAtLab_DateTime: "1900-01-01T00:00:00.000Z",
          colorTubeId: item.id,
          isCollectFromLab: false,
          id: 0,
          isNurse: true,
          menuId: props.menuId ? props.menuId : location?.state?.menuId,
          orderID: selectedOrderId,
          privilege: privilege,
          temperatureCollectAtFloor: item["Test Limit"],
          temperatureCollectAtLab: "",
          //
          isUrgent: isUrgent_selected || false,
        });
    }

    saveColorTubeTemperature(postArrey)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.handleClose && props.handleClose();
        setOpenBackdrop(false);
        setSelectedID(null);
        setTestsTableData([]);
        resetField("patientListSearch");
        resetField("isUrgent");
        setSelectedFromDate(new Date());
        setSelectedToDate(new Date());
        resetField("totalML");
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
      });
  };

  const renderTableHeader = () => (
    <>
      <div className="flex gap-3 items-center">
        <div className="h-5 w-5 border rounded bg-[#f87171]"></div>
        <div className="">Is Urgent</div>
      </div>
    </>
  );

  const rowBackgroundColor = (row, index) =>
    row?.Urgent ? "#f87171" : undefined;

  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          Sample Collection For Colour Tube
        </div>
        {/* filters */}
        <div className="grid md:grid-cols-2 lg:grid-cols-7 gap-3 pt-2">
          <div className=" col-span-2 z-50">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No."
              dataArray={options}
              name="patientListSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={handleChangeSearch}
              isDisabled={props?.MRNo ? true : false}
            />
          </div>

          {/* from Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              inputRef={{
                ...register("fromDate", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedFromDate(e.target.value);
                    }
                  },
                }),
              }}
            />
          </div>

          {/* to Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              inputRef={{
                ...register("toDate", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedToDate(e.target.value);
                    }
                  },
                }),
              }}
            />
          </div>

          {/* Sample Type */}
          <div className="flex justify-start  col-span-2 lg:col-span-3 ">
            <p className="pr-3 text-base font-semibold">Sample Type :</p>
            <div>
              <RadioField
                label=""
                name="sampleType"
                control={control}
                dataArray={SampleType}
              />
            </div>
          </div>
        </div>
        {(props.displayInfo || props.selectedPatient) && (
          <div className="bg-gray-100 px-2 mx-3 rounded-md  border border-gray-300 my-2">
            <div className="grid grid-cols-2 lg:grid-cols-3 text-black  text-sm items-center w-full gap-1">
              <div className="flex  items-center  gap-2 w-full font-semibold text-sm">
                <span className="w-24 font-semibold"> Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.PatientName ||
                      props.selectedPatient?.PatientName}
                  </span>
                </div>
              </div>
              <div className="flex space-x-12 items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold"> MR No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className=" font-normal">
                    {props.displayInfo?.MRNo || props.selectedPatient?.MRNo}
                  </span>
                </div>
              </div>

              <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="w-16 font-semibold"> Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.AgeYear ||
                      props.selectedPatient?.AgeYear}
                  </span>
                </div>
              </div>

              <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 font-semibold">Department</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.Department ||
                      props.selectedPatient?.Department}
                  </span>
                </div>
              </div>

              <div className="flex space-x-1 items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold whitespace-nowrap">
                  Doctore Name
                </span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.DoctorName ||
                      props.selectedPatient?.DoctorName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Tables */}
        <div className="">
          {spiner1 ? (
            <div className="flex justify-center mt-14">
              <LoadingSpinner />
            </div>
          ) : listOfOrdersTableData?.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={listOfOrdersTableData}
              highlightRow={false}
              renderActions={renderActionsListOfOrdersTable}
              populateTable={getListOfOrdersTableData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              tableClass="h-96"
              renderTableHeader={renderTableHeader}
              rowBackgroundColor={rowBackgroundColor}
              removeHeaders={[
                "id",
                "IsApproved",
                "IsCompleted",
                "IsDelivered",
                "IsOrderGenerated",
                "IsPrinted",
                "OpdIpd",
                "Opd_Ipd_External",
                "Opd_Ipd_External_Id",
                "OrderId",
                "PatientId",
                "Urgent",
                "existsample",
              ]}
            />
          ) : (
            <p className="text-center my-6 ">No Data Found</p>
          )}
        </div>
        {testsTableData.length > 0 && (
          <div className="">
            {spiner2 ? (
              <div className="flex justify-center mt-14">
                <LoadingSpinner />
              </div>
            ) : testsTableData?.length > 0 ? (
              <>
                <CommonDynamicTableNew
                  highlightRow={false}
                  dataResult={testsTableData}
                  setDataResult={setTestsTableData}
                  tableClass="h-64"
                  //  renderActions={renderActionsTestsTable}
                  renderInput={renderInput}
                  editableColumns={["Test Limit"]}
                  removeHeaders={["id", "checked"]}
                  enableSelectAll={true}
                />
              </>
            ) : (
              <p className="text-center my-6 ">No Items Found</p>
            )}
          </div>
        )}

        <div className=" flex gap-3 my-3 justify-between">
          <div className="gap-5 flex">
            {sumByColourTube.map((obj) =>
              !!obj["Tube Name"] !== "null" ? (
                <div key={obj["Tube Name"]}>
                  <div className="flex">
                    <span className=" text-customGray">{`${obj["Tube Name"]}`}</span>
                    <span className="">&nbsp;:&nbsp;</span>
                    <span className="font-semibold">
                      {obj["Sum TestLimit"]}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
          </div>

          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <div className="col-span-6 flex justify-end gap-3">
                    <CheckBoxField
                      control={control}
                      name="isUrgent"
                      label="Is Urgent"
                    />
                    <CommonButton
                      label="Add"
                      type="submit"
                      className=" bg-customGreen text-white"
                      onClick={() => {
                        onSubmit();
                        setPrivilege(obj.action);
                      }}
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default SampleCollectionForColourTube;
