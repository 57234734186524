import apiClientBilling from "../../http-common";
import baseUrl from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";
export const fetchPatientAdvanceDetails = (patientId, menuId) => {
  return apiClientBilling.get(`/patientadvance/${patientId}/${menuId}`, {
    headers: authHeader(),
  });
};

//Post request to save the modal form data into the database.
export const savePaymentAdvance = (savePaymentAdvanceObj) => {
  return apiClientBilling.post(`/patientadvance/save`, savePaymentAdvanceObj, {
    headers: authHeader(),
  });
};

//Search ICon API

export const autoSearchPatientAdvance = (searchString, opdIpd) => {
  return apiClientBilling.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientInfo = (patientId, opdIpd) => {
  return apiClientBilling.get(`/patientInfo/bill/${patientId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

export const getPatientInfoForOPD = (patientId) => {
  return apiClientBilling.get(`/patientadvance/getPatientForOPD/${patientId}`, {
    headers: authHeader(),
  });
};

export const getPaymentTypes = (id) => {
  return apiClientBilling.get(`/misc/paymentType`, {
    headers: authHeader(),
  });
};

export const getPaymentAgainest = (id) => {
  return apiClientBilling.get(`/misc/AdvAgainst`, {
    headers: authHeader(),
  });
};

export const getBankDropdownList = () => {
  return apiClientBilling.get(`/misc/bank`, {
    headers: authHeader(),
  });
};

export const patientAdvancePdfPrint = (patientAdvanceDetailsId) => {
  return apiClientBilling.get(
    `/reports/bills/advanceReceipt?patientAdvanceDetailsId=${patientAdvanceDetailsId}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};
