import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

//vacant Beds
export const postMyVacantBeds = (bedObj) => {
  return apiClient.post(`/bed/vacantBeds`, bedObj, {
    headers: authHeader(),
  });
};

//Beds By Floor
export const getBedsByFloor = (floorId) => {
  return apiClient.get(
    `/bed/getBeds/${floorId}
    `,
    {
      headers: authHeader(),
    }
  );
};
