import React, { useEffect, useRef, useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { OTReservationBookinngList } from "../services/otReservationServices/OtReservationServices";

export default function OtBookingListPrint(props) {
  const printRef = useRef(null);

  const [dataResult, setDataResult] = React.useState();

  let obj = {
    fromDate: props?.fromDate,
    toDate: props?.toDate,
    otTableId: props?.otTableId,
  };

  useEffect(() => {
    OTReservationBookinngList(obj)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
            <div className=" -mt-10 border-b-[1px] border-black">
              <h1 className=" font-semibold text-[15px] ">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[11px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" font-semibold text-[14px] my-1 border-t-[1px] border-black mt-[1px]">
                OPERATION THEATER
              </h1>
            </div>
          </div>
          <div className=" grid grid-cols-[9%_8%_8%_15%_6%_13%_10%_10%_11%_10%] text-[11px] font-semibold my-1 border-b-[1px] border-black">
            <div>Date</div>
            <div>From time</div>
            <div>To Time</div>
            <div>Patient Name</div>
            <div>Gender</div>
            <div>Age</div>
            <div>Ward</div>
            <div>Surgery</div>
            <div>Doctor Name</div>
            <div>Doctor Type</div>
          </div>
        </thead>
        <tbody className="w-full">
          {dataResult?.map((data) => {
            return (
              <div className=" grid grid-cols-[9%_8%_8%_15%_6%_10%_10%_13%_11%_10%] text-[11px]">
                <div>{data?.date}</div>
                <div>{data?.fromTime}</div>
                <div>{data?.toTime}</div>
                <div>{data?.patientName}</div>
                <div>{data?.Gender}</div>
                <div>{data?.AgeYear}</div>
                <div>{data?.ward}</div>
                <div className=" break-words pr-2  whitespace-normal ">
                  {data?.surgery}
                </div>
                <div>{data?.doctor}</div>
                <div>{data?.docType}</div>
              </div>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
