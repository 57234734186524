import React, { useContext, useState } from "react";

import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";

import { useForm } from "react-hook-form";

//imports from the yup library
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "../../../../common/components/FormFields/InputField";

import {
  getApacheScore,
  postApacheScore,
  deleteApacheScore,
} from "../../../services/cliinicalChartServices/apachescoreServices/ApacheScoreServices";
import { VisitContext } from "../ClinicalCareChart";

import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";

import {
  deleteAlert,
  errorAlert,
  errorAlertCustom,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";

import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

function ApacheScore(props) {
  const patientVisitId = useContext(VisitContext);
  const { setScoreBlocks } = props;

  const [apacheScoreData, setApacheScoreData] = React.useState([]);

  const [openPost, setOpenPost] = React.useState(false);

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  const [deleteObj, setDeleteObj] = React.useState(null);

  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);

  //Yup schema
  const schema = yup.object().shape({
    pulse: yup.string().required("required"),

    respiration: yup.string().required("required"),

    meanArterialPressure: yup.string().required("required"),

    temperatureRectal: yup.string().required("required"),

    arterialPH: yup.string().required("required"),

    oxygenation: yup.string().required("required"),

    serumHCO3: yup.string().required("required"),

    serumSodium: yup.string().required("required"),

    hematocrit: yup.string().required("required"),

    serumCreatinine: yup.string().required("required"),

    serumPotassium: yup.string().required("required"),

    wbc: yup.string().required("required"),
  });

  //the object to reset the form to blank values
  const defaultValues = {
    pulse: "",
    respiration: "",
    meanArterialPressure: "",
    temperatureRectal: "",
    arterialPH: "",
    oxygenation: "",
    serumHCO3: "",
    serumSodium: "",
    hematocrit: "",
    serumCreatinine: "",
    serumPotassium: "",
    wbc: "",
    apacheScore: "",
    deathRate: "",
    id: "",
    eyeOpeningResponse: "",
    motorResponse: "",
    verbalResponse: "",
    chronicHealthPoints: "",
    a: "",
    b: "",
    c: "",
  };

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    register,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    // use mode to specify the event that triggers each input field
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {}, [errors]);

  function openDeleteConfirmation() {
    setDeleteConfirm(true);
  }

  function closeDeleteConfirmation() {
    if (deleteConfirm) {
      setDeleteConfirm(false);
    }
  }

  //The onSubmitDataHandler function
  const onSubmitDataHandler = (data) => {
    setScoreBlocks((prev) =>
      prev.map((item) =>
        item.label === "APACHE" ? { ...item, score: apacheScore } : item
      )
    );
    ////
    setOpenBackdrop(true);

    let userInfoVal = localStorage.getItem("userInfo");
    userInfoVal = JSON.parse(userInfoVal);
    let calculatedDeathRate = getValues("deathRate");
    let calculatedApacheScore = getValues("apacheScore");

    //object of docITHealth's Apache Score

    //expectedObj is
    let expectedObj = {
      addedBy: 0,
      admissionId: 0,
      artirial: "string",
      cronic: "string",
      eyeOpening: "string",
      gcs: "string",
      gcsscore: "string",
      givenDate: "2023-05-24T09:06:44.445Z",
      givenTime: "2023-05-24T09:06:44.445Z",
      hematocrit: "string",
      isApache: true,
      mean_artirial: "string",
      motorResponse: "string",
      oxigenation: "string",
      pulse: "string",
      respiration: "string",
      serum: "string",
      serumCreatinine: "string",
      serumPotassium: "string",
      serumSodium: "string",
      temperature: "string",
      temprectal: "string",
      verbalResponse: "string",
      whiteBloodCount: "string",
      privilege: "string",
      menuId: 0,
    };

    if (props.displayInfo === null) {
      expectedObj.admissionId = props.admissionId;
    } else if (props.displayInfo !== null) {
      expectedObj.admissionId = props.displayInfo.AdmissionId;
    }

    let dateObj = new Date();

    let dateObjVal = dateObj.toISOString();

    expectedObj.menuId = props.menuId;

    let privilege = "";

    let tempUserActions = structuredClone(props.userActions);

    for (let tempUserActionObj of tempUserActions) {
      if (tempUserActionObj.action === "Create") {
        privilege = "Create";
        break;
      }
    }

    expectedObj.privilege = privilege;

    expectedObj.temperature = data.temperatureRectal;

    expectedObj.givenDate = dateObjVal;
    expectedObj.givenTime = dateObjVal;

    expectedObj.addedBy = userInfoVal.userId;

    expectedObj.artirial = data.arterialPH;
    expectedObj.cronic = data.chronicHealthPoints;
    expectedObj.eyeOpening = data.eyeOpeningResponse;
    expectedObj.gcs = data.totalGCS;
    expectedObj.gcsscore = data.totalGCS;
    expectedObj.hematocrit = data.hematocrit;
    expectedObj["mean_artirial"] = data.meanArterialPressure;
    expectedObj.motorResponse = data.motorResponse;
    expectedObj.oxigenation = data.oxygenation;
    expectedObj.pulse = data.pulse;
    expectedObj.respiration = data.respiration;
    expectedObj.serum = data.serumHCO3;
    expectedObj.serumCreatinine = data.serumCreatinine;
    expectedObj.serumPotassium = data.serumPotassium;
    expectedObj.serumSodium = data.serumSodium;

    expectedObj.temprectal = data.temperatureRectal;
    expectedObj.verbalResponse = data.verbalResponse;
    expectedObj.whiteBloodCount = data.wbc;

    //new
    expectedObj.deathRate = calculatedDeathRate;
    expectedObj.apacheScore = calculatedApacheScore;

    postApacheScore(expectedObj)
      .then((response) => {
        successAlert(response.data.message);
        reset(defaultValues);
        setOpenPost(false);
        setOpenBackdrop(false);
        props?.handlePatientInfo();

        getApacheScore(
          props.displayInfo?.AdmissionId || props.admissionId
        ).then((response) => {
          setApacheScoreData(response.data.result);
        });
      })
      .catch((error) => {
        errorAlertCustom(error.response.data.message);
        setOpenBackdrop(false);
        setOpenPost(false);
      });
  };

  //Calculate temparatureRectal
  function calculateTemperatureRectal() {
    let temperatureRectalVal = null;

    //calculate the temperatureRectal
    if (Number(getValues("temperatureRectal")) >= 41) {
      //calculating the value of temperatureRectal
      temperatureRectalVal = -4;
    } else if (
      Number(getValues("temperatureRectal")) >= 39 &&
      Number(getValues("temperatureRectal")) <= 40.9
    ) {
      temperatureRectalVal = -3;
    } else if (
      Number(getValues("temperatureRectal")) >= 38.5 &&
      Number(getValues("temperatureRectal")) <= 38.9
    ) {
      temperatureRectalVal = -1;
    } else if (
      Number(getValues("temperatureRectal")) >= 36 &&
      Number(getValues("temperatureRectal")) <= 38.4
    ) {
      // 36-38.4
      temperatureRectalVal = 0;
    } else if (
      Number(getValues("temperatureRectal")) >= 34 &&
      Number(getValues("temperatureRectal")) <= 35.9
    ) {
      // 34-35.9
      temperatureRectalVal = 1;
    } else if (
      Number(getValues("temperatureRectal")) >= 32 &&
      Number(getValues("temperatureRectal")) <= 33.9
    ) {
      // 32-33.9
      temperatureRectalVal = 2;
    } else if (
      Number(getValues("temperatureRectal")) >= 30 &&
      Number(getValues("temperatureRectal")) <= 31.9
    ) {
      // 30-31.9
      temperatureRectalVal = 3;
    } else if (Number(getValues("temperatureRectal")) <= 29.9) {
      // ≤ 29.9
      temperatureRectalVal = 4;
    }

    return temperatureRectalVal;
  }

  //Calculate meanArterialPressure --value of A
  function calculateMeanArterialPressure() {
    let meanArterialPressureVal = null;
    //calculating the value of meanArterialPressure
    if (Number(getValues("meanArterialPressure")) >= 160) {
      meanArterialPressureVal = -4;
    } else if (
      Number(getValues("meanArterialPressure")) >= 130 &&
      Number(getValues("meanArterialPressure")) <= 159
    ) {
      meanArterialPressureVal = -3;
    } else if (
      Number(getValues("meanArterialPressure")) >= 110 &&
      Number(getValues("meanArterialPressure")) <= 129
    ) {
      meanArterialPressureVal = -2;
    } else if (
      Number(getValues("meanArterialPressure")) >= 70 &&
      Number(getValues("meanArterialPressure")) <= 109
    ) {
      meanArterialPressureVal = 0;
    } else if (
      Number(getValues("meanArterialPressure")) >= 50 &&
      Number(getValues("meanArterialPressure")) <= 69
    ) {
      meanArterialPressureVal = 2;
    } else if (Number(getValues("meanArterialPressure")) <= 49) {
      meanArterialPressureVal = 4;
    }

    return meanArterialPressureVal;
  }

  //Calculate arterialPH
  function calculateArterialPH() {
    let arterialPHVal = null;
    //calculating the value of arterialPH
    if (Number(getValues("arterialPH")) >= 7.7) {
      arterialPHVal = -4;
    } else if (
      Number(getValues("arterialPH")) >= 7.6 &&
      Number(getValues("arterialPH")) <= 7.69
    ) {
      arterialPHVal = -3;
    } else if (
      Number(getValues("arterialPH")) >= 7.5 &&
      Number(getValues("arterialPH")) <= 7.59
    ) {
      arterialPHVal = -1;
    } else if (
      Number(getValues("arterialPH")) >= 7.33 &&
      Number(getValues("arterialPH")) <= 7.49
    ) {
      arterialPHVal = 0;
    } else if (
      Number(getValues("arterialPH")) >= 7.25 &&
      Number(getValues("arterialPH")) <= 7.32
    ) {
      arterialPHVal = 2;
    } else if (
      Number(getValues("arterialPH")) >= 7.15 &&
      Number(getValues("arterialPH")) <= 7.24
    ) {
      arterialPHVal = 3;
    } else if (Number(getValues("arterialPH")) <= 7.15) {
      arterialPHVal = 4;
    }

    return arterialPHVal;
  }

  //calculating the value of pulse(means heartrate)
  function calculatePulse() {
    let pulseVal = null;

    if (Number(getValues("pulse")) >= 180) {
      pulseVal = -4;
    } else if (
      Number(getValues("pulse")) >= 140 &&
      Number(getValues("pulse")) <= 179
    ) {
      pulseVal = -3;
    } else if (
      Number(getValues("pulse")) >= 110 &&
      Number(getValues("pulse")) <= 139
    ) {
      pulseVal = -2;
    } else if (
      Number(getValues("pulse")) >= 70 &&
      Number(getValues("pulse")) <= 109
    ) {
      pulseVal = 0;
    } else if (
      Number(getValues("pulse")) >= 55 &&
      Number(getValues("pulse")) <= 69
    ) {
      pulseVal = 2;
    } else if (
      Number(getValues("pulse")) >= 40 &&
      Number(getValues("pulse")) <= 54
    ) {
      pulseVal = 3;
    } else if (Number(getValues("pulse")) <= 39) {
      pulseVal = 4;
    }

    return pulseVal;
  }

  //calculating the value of respiration
  function calculateRespiration() {
    let respirationVal = null;
    if (Number(getValues("respiration")) >= 50) {
      respirationVal = -4;
    } else if (
      Number(getValues("respiration")) >= 35 &&
      Number(getValues("respiration")) <= 49
    ) {
      respirationVal = -3;
    } else if (
      Number(getValues("respiration")) >= 110 &&
      Number(getValues("respiration")) <= 139
    ) {
      respirationVal = -2;
    } else if (
      Number(getValues("respiration")) >= 25 &&
      Number(getValues("respiration")) <= 34
    ) {
      respirationVal = -1;
    } else if (
      Number(getValues("respiration")) >= 12 &&
      Number(getValues("respiration")) <= 24
    ) {
      respirationVal = 0;
    } else if (
      Number(getValues("respiration")) >= 10 &&
      Number(getValues("respiration")) <= 11
    ) {
      respirationVal = 1;
    } else if (Number(getValues("respiration")) <= 39) {
      respirationVal = 4;
    }

    return respirationVal;
  }

  //caculating the value of oxygentation
  function calculateOxygentation() {
    let oxygenationVal = null;
    //calculating the value of oxygenation
    if (Number(getValues("oxygenation")) >= 500) {
      oxygenationVal = -4;
    } else if (
      Number(getValues("oxygenation")) >= 350 &&
      Number(getValues("oxygenation")) <= 499
    ) {
      oxygenationVal = -3;
    } else if (
      Number(getValues("oxygenation")) >= 200 &&
      Number(getValues("oxygenation")) <= 349
    ) {
      oxygenationVal = -2;
    } else if (Number(getValues("oxygenation")) < 200) {
      oxygenationVal = 0;
    }

    return oxygenationVal;
  }

  //calculating the serumHCO3
  function calculateSerumHCO3() {
    let serumHCO3Val = null;
    if (Number(getValues("serumHCO3")) >= 52) {
      serumHCO3Val = -4;
    } else if (
      Number(getValues("serumHCO3")) >= 41 &&
      Number(getValues("serumHCO3")) <= 51.9
    ) {
      serumHCO3Val = -3;
    } else if (
      Number(getValues("serumHCO3")) >= 32 &&
      Number(getValues("serumHCO3")) <= 40.9
    ) {
      serumHCO3Val = -1;
    } else if (
      Number(getValues("serumHCO3")) >= 22 &&
      Number(getValues("serumHCO3")) <= 31.9
    ) {
      serumHCO3Val = 0;
    } else if (
      Number(getValues("serumHCO3")) >= 18 &&
      Number(getValues("serumHCO3")) <= 21.9
    ) {
      serumHCO3Val = 1;
    } else if (
      Number(getValues("serumHCO3")) >= 15 &&
      Number(getValues("serumHCO3")) <= 17.5
    ) {
      serumHCO3Val = 2;
    } else if (Number(getValues("serumHCO3")) <= 15) {
      serumHCO3Val = 4;
    }

    return serumHCO3Val;
  }

  //calculating the wbc
  function calculateWbc() {
    let wbcVal = null;
    if (Number(getValues("wbc")) >= 40) {
      wbcVal = -4;
    } else if (
      Number(getValues("wbc")) >= 20 &&
      Number(getValues("wbc")) <= 39.9
    ) {
      wbcVal = -2;
    } else if (
      Number(getValues("wbc")) >= 15 &&
      Number(getValues("wbc")) <= 19.9
    ) {
      wbcVal = -1;
    } else if (
      Number(getValues("wbc")) >= 3 &&
      Number(getValues("wbc")) <= 14.9
    ) {
      wbcVal = 0;
    } else if (
      Number(getValues("wbc")) >= 1 &&
      Number(getValues("wbc")) <= 2.9
    ) {
      wbcVal = 2;
    } else if (Number(getValues("wbc")) < 1) {
      wbcVal = 4;
    }

    return wbcVal;
  }

  //calculating the hematocrit
  function calculateHematocrit() {
    let hematocritVal = null;
    if (Number(getValues("hematocrit")) >= 60) {
      hematocritVal = -4;
    } else if (
      Number(getValues("hematocrit")) >= 50 &&
      Number(getValues("hematocrit")) <= 59.9
    ) {
      hematocritVal = -2;
    } else if (
      Number(getValues("hematocrit")) >= 46 &&
      Number(getValues("hematocrit")) <= 49.9
    ) {
      hematocritVal = -1;
    } else if (
      Number(getValues("hematocrit")) >= 30 &&
      Number(getValues("hematocrit")) <= 45.9
    ) {
      hematocritVal = 0;
    } else if (
      Number(getValues("hematocrit")) >= 20 &&
      Number(getValues("hematocrit")) <= 29.9
    ) {
      hematocritVal = 2;
    } else if (Number(getValues("hematocrit")) < 20) {
      hematocritVal = 4;
    }

    return hematocritVal;
  }

  //calculating the serumCreatinine
  function calculateSerumCreatinine() {
    let serumCreatinineVal = null;
    if (Number(getValues("serumCreatinine")) >= 3.5) {
      serumCreatinineVal = -4;
    } else if (
      Number(getValues("serumCreatinine")) >= 2 &&
      Number(getValues("serumCreatinine")) <= 3.4
    ) {
      serumCreatinineVal = -3;
    } else if (
      Number(getValues("serumCreatinine")) >= 1.5 &&
      Number(getValues("serumCreatinine")) <= 1.9
    ) {
      serumCreatinineVal = -2;
    } else if (
      Number(getValues("serumCreatinine")) >= 0.6 &&
      Number(getValues("serumCreatinine")) <= 1.4
    ) {
      serumCreatinineVal = 0;
    } else if (Number(getValues("serumCreatinine")) < 0.6) {
      serumCreatinineVal = 2;
    }

    return serumCreatinineVal;
  }

  //calculating the serumPotassium
  function calculateSerumPotassium() {
    let serumPotassiumVal = null;
    if (Number(getValues("serumPotassium")) >= 7) {
      serumPotassiumVal = -4;
    } else if (
      Number(getValues("serumPotassium")) >= 6 &&
      Number(getValues("serumPotassium")) <= 6.9
    ) {
      serumPotassiumVal = -3;
    } else if (
      Number(getValues("serumPotassium")) >= 5.5 &&
      Number(getValues("serumPotassium")) <= 5.9
    ) {
      serumPotassiumVal = -1;
    } else if (
      Number(getValues("serumPotassium")) >= 3.5 &&
      Number(getValues("serumPotassium")) <= 5.4
    ) {
      serumPotassiumVal = 0;
    } else if (
      Number(getValues("serumPotassium")) >= 3 &&
      Number(getValues("serumPotassium")) <= 3.4
    ) {
      serumPotassiumVal = 1;
    } else if (
      Number(getValues("serumPotassium")) >= 2.5 &&
      Number(getValues("serumPotassium")) <= 2.9
    ) {
      serumPotassiumVal = 2;
    } else if (Number(getValues("serumPotassium")) <= 2.5) {
      serumPotassiumVal = 4;
    }

    return serumPotassiumVal;
  }

  //Calculating the serumSodium
  function calculateSerumSodium() {
    let serumSodiumVal = null;
    if (Number(getValues("serumSodium")) >= 180) {
      serumSodiumVal = -4;
    } else if (
      Number(getValues("serumSodium")) >= 160 &&
      Number(getValues("serumSodium")) <= 179
    ) {
      serumSodiumVal = -3;
    } else if (
      Number(getValues("serumSodium")) >= 155 &&
      Number(getValues("serumSodium")) <= 159
    ) {
      serumSodiumVal = -2;
    } else if (
      Number(getValues("serumSodium")) >= 150 &&
      Number(getValues("serumSodium")) <= 154
    ) {
      serumSodiumVal = -1;
    } else if (
      Number(getValues("serumSodium")) >= 130 &&
      Number(getValues("serumSodium")) <= 149
    ) {
      serumSodiumVal = 0;
    } else if (
      Number(getValues("serumSodium")) >= 120 &&
      Number(getValues("serumSodium")) <= 129
    ) {
      serumSodiumVal = 2;
    } else if (
      Number(getValues("serumSodium")) >= 111 &&
      Number(getValues("serumSodium")) <= 119
    ) {
      serumSodiumVal = 3;
    } else if (Number(getValues("serumSodium")) <= 110) {
      serumSodiumVal = 4;
    }

    return serumSodiumVal;
  }

  //Calculate the value of A and set it into the input field
  function CalculateSumOfA() {
    let pulse = calculatePulse();

    let respiration = calculateRespiration();

    let temperatureRectal = calculateTemperatureRectal();

    let meanArterialPressure = calculateMeanArterialPressure();

    let arterialPH = calculateArterialPH();

    let oxygenation = calculateOxygentation();

    let serumHCO3 = calculateSerumHCO3();

    let wbc = calculateWbc();

    let hematocrit = calculateHematocrit();

    let serumCreatinine = calculateSerumCreatinine();

    let serumPotassium = calculateSerumPotassium();

    let serumSodium = calculateSerumSodium();

    let summationOfA = null;

    if (
      pulse !== null &&
      respiration !== null &&
      temperatureRectal !== null &&
      meanArterialPressure !== null &&
      arterialPH !== null &&
      oxygenation !== null &&
      serumHCO3 !== null &&
      wbc !== null &&
      hematocrit !== null &&
      serumCreatinine !== null &&
      serumPotassium !== null &&
      serumSodium !== null
    ) {
      //calculating the value of A
      summationOfA =
        Number(pulse) +
        Number(respiration) +
        Number(temperatureRectal) +
        Number(meanArterialPressure) +
        Number(arterialPH) +
        Number(oxygenation) +
        Number(serumHCO3) +
        Number(wbc) +
        Number(hematocrit) +
        Number(serumCreatinine) +
        Number(serumPotassium) +
        Number(serumSodium);
    }

    //displaying the value of A
    if (summationOfA !== null) {
      setValue("a", summationOfA);
    }
  }

  //Calculate the value of B and set it into the input field
  function CalculateSumOfB(eyeVal, verbalVal, motorVal) {
    let summationOfB = null;

    summationOfB = Number(eyeVal) + Number(verbalVal) + Number(motorVal);

    if (summationOfB !== null) {
      setValue("totalGCS", summationOfB);
      setValue("b", summationOfB);
    }
  }

  let deathRate = null;
  let apacheScore = null;

  //watching the values of A , B and C in order to calculate the Apache Score.
  let totalA = watch("a");
  let totalB = watch("b");
  let totalC = watch("c");

  //Calculation of Apache Score
  if (totalA !== "" && totalB !== "" && totalC !== "") {
    apacheScore = Number(totalA) + Number(totalB) + Number(totalC);
  }

  //Conditions to calculate Death Rate
  if (apacheScore === 0 && apacheScore <= 4) {
    deathRate = "4%";
  } else if (apacheScore === 5 && apacheScore <= 9) {
    deathRate = "8%";
  } else if (apacheScore === 10 && apacheScore <= 14) {
    deathRate = "15%";
  } else if (apacheScore === 15 && apacheScore <= 19) {
    deathRate = "25%";
  } else if (apacheScore === 20 && apacheScore <= 24) {
    deathRate = "40%";
  } else if (apacheScore === 25 && apacheScore <= 29) {
    deathRate = "55%";
  } else if (apacheScore === 30 && apacheScore <= 34) {
    deathRate = "75%";
  } else if (apacheScore > 34) {
    deathRate = "80%";
  }

  //set the value of deathRate ; when it is calculated.
  if (deathRate !== null) {
    setValue("deathRate", deathRate);
  }

  //set the value of apacheScore ; when it is calculated.
  if (apacheScore !== null) {
    setValue("apacheScore", apacheScore);
  }

  // React.useEffect(() => {
  //   showSpinner(true);
  //   getApacheScore(props.displayInfo?.AdmissionId || props.admissionId).then(
  //     (response) => {
  //       showSpinner(false);
  //       setApacheScoreData(response.data.result);
  //     }
  //   );
  // }, []);

  React.useEffect(() => {
    //If the is available then call the service to get the apache score data and populate in the
    //ApacheScoreTable.
    if (props.displayInfo?.AdmissionId || props.admissionId) {
      getApacheScore(props.displayInfo?.AdmissionId || props.admissionId).then(
        (response) => {
          setApacheScoreData(response.data.result);
        }
      );
    }
  }, [props.displayInfo?.AdmissionId || props.admissionId]);

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true && obj.action === "Delete" ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    let deleteObjVal = {};

                    deleteObjVal.Id = row.Id;

                    deleteObjVal.action = obj.action;

                    setDeleteObj(deleteObjVal);

                    //open the delete confirmation modal
                    openDeleteConfirmation();
                  }}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  function deleteTheApacheScoreRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);
      deleteApacheScore(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            closeDeleteConfirmation();
            //calling the service to re-populate the apache score table.
            getApacheScore(
              props.displayInfo?.AdmissionId || props.admissionId
            ).then((response) => {
              setOpenBackdrop(false);
              setApacheScoreData(response.data.result);
            });
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          closeDeleteConfirmation();
        });
    }
  }

  return (
    <>
      <div>
        <label className="text-sm font-semibold whitespace-nowrap">
          Apache Score
        </label>
        {spinner ? (
          <div className="grid justify-center">
            <LoadingSpinner />
          </div>
        ) : null}
        <div>
          {apacheScoreData.length > 0 && spinner === false ? (
            <>
              <CommonDynamicTableNew
                dataResult={apacheScoreData}
                highlightRow={true}
                tableClass=""
                renderActions={renderActions}
                removeHeaders={["AdmissionId", "Id"]}
              />
            </>
          ) : (
            <div className="text-center mt-10">No Record Found</div>
          )}
        </div>

        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="border-b-2 border-customBlue py-2">
            <h1 className="h-7 w-7 text-center flex items-center justify-center bg-orange-500 text-white rounded-full mt-2">
              A
            </h1>
            <div className="grid grid-cols-4 gap-4 mt-2">
              <div className="w-full">
                <InputField
                  name="pulse"
                  type="number"
                  variant="outlined"
                  label="Pulse*"
                  error={errors.pulse}
                  control={control}
                  inputProps={{
                    style: { textTransform: "capitalize" },
                  }}
                  inputRef={{
                    ...register(`pulse`, {
                      onChange: (e) => {
                        let pulseVal = e.target.value;

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="respiration"
                  type="number"
                  variant="outlined"
                  label="Respiration*"
                  error={errors.respiration}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`respiration`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = e.target.value;

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="temperatureRectal"
                  type="number"
                  variant="outlined"
                  label="Temp. Rectal (C°)"
                  error={errors.temperatureRectal}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`temperatureRectal`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal = e.target.value;

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="meanArterialPressure"
                  type="number"
                  variant="outlined"
                  label="Mean Arterial Pressure*"
                  error={errors.meanArterialPressure}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`meanArterialPressure`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = e.target.value;

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="arterialPH"
                  type="number"
                  variant="outlined"
                  label="Arterial PH*"
                  error={errors.arterialPH}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`arterialPH`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = e.target.value;

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="oxygenation"
                  type="number"
                  variant="outlined"
                  label="Oxygenation*"
                  error={errors.oxygenation}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`oxygenation`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = e.target.value;

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="serumHCO3"
                  type="number"
                  variant="outlined"
                  label="Serum HCO3*"
                  error={errors.serumHCO3}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`serumHCO3`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = e.target.value;

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="wbc"
                  type="number"
                  variant="outlined"
                  label="WBC*"
                  error={errors.wbc}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`wbc`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = e.target.value;

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="hematocrit"
                  type="number"
                  variant="outlined"
                  label="Hematocrit*"
                  error={errors.hematocrit}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`hematocrit`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = e.target.value;

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="serumCreatinine"
                  type="number"
                  variant="outlined"
                  label="Serum Creatinine*"
                  error={errors.serumCreatinine}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`serumCreatinine`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = e.target.value;

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="serumPotassium"
                  type="number"
                  variant="outlined"
                  label="Serum Potassium*"
                  error={errors.serumPotassium}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`serumPotassium`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = getValues("serumSodium");

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = e.target.value;

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="w-full">
                <InputField
                  name="serumSodium"
                  type="number"
                  variant="outlined"
                  label="Serum Sodium*"
                  error={errors.serumSodium}
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  inputRef={{
                    ...register(`serumSodium`, {
                      onChange: (e) => {
                        let pulseVal = getValues("pulse");

                        let respirationVal = getValues("respiration");

                        let meanArterialPressureVal = getValues(
                          "meanArterialPressure"
                        );

                        let temperatureRectalVal =
                          getValues("temperatureRectal");

                        let arterialPHVal = getValues("arterialPH");

                        let oxygenationVal = getValues("oxygenation");

                        let serumHCO3Val = getValues("serumHCO3");

                        let serumSodiumVal = e.target.value;

                        let hematocritVal = getValues("hematocrit");

                        let serumCreatinineVal = getValues("serumCreatinine");

                        let serumPotassiumVal = getValues("serumPotassium");

                        let wbcVal = getValues("wbc");

                        if (
                          pulseVal !== "" &&
                          respirationVal !== "" &&
                          meanArterialPressureVal !== "" &&
                          temperatureRectalVal !== "" &&
                          arterialPHVal !== "" &&
                          oxygenationVal !== "" &&
                          serumHCO3Val !== "" &&
                          serumSodiumVal !== "" &&
                          hematocritVal !== "" &&
                          serumCreatinineVal !== "" &&
                          serumPotassiumVal !== "" &&
                          wbcVal !== ""
                        ) {
                          CalculateSumOfA();
                        } else {
                          setValue("a", "");
                        }
                      },
                    }),
                  }}
                />
              </div>
            </div>

            <div>
              <h1 className="h-7 w-7 text-center flex items-center justify-center bg-orange-500 text-white rounded-full mt-2">
                B
              </h1>
              <div className="grid grid-cols-4 gap-3 ">
                <fieldset
                  className="grid justify-between border border-gray-400
                w-full text-left mt-1 px-4 py-3 rounded text-sm"
                >
                  <legend className="font-semibold text-sm text-gray-700 ">
                    Eye Opening Response
                  </legend>

                  <div className="grid gap-3">
                    <div className="items-center flex gap-2 ">
                      <div>
                        <input
                          type="radio"
                          name="eyeOpeningResponse"
                          value="1"
                          {...register("eyeOpeningResponse")}
                          onChange={(e) => {
                            let eyeOpeningResponseVal = e.target.value;
                            let verbalResponseVal = getValues("verbalResponse");
                            let motorResponseVal = getValues("motorResponse");

                            if (eyeOpeningResponseVal) {
                              if (verbalResponseVal && motorResponseVal) {
                                CalculateSumOfB(
                                  eyeOpeningResponseVal,
                                  verbalResponseVal,
                                  motorResponseVal
                                );
                              }
                            }
                          }}
                        />
                      </div>

                      <div>
                        <label>None(1)</label>
                      </div>
                    </div>

                    <div className="items-center flex gap-2 ">
                      <div>
                        <input
                          type="radio"
                          name="eyeOpeningResponse"
                          value="2"
                          {...register("eyeOpeningResponse")}
                          onChange={(e) => {
                            let eyeOpeningResponseVal = e.target.value;
                            let verbalResponseVal = getValues("verbalResponse");
                            let motorResponseVal = getValues("motorResponse");

                            if (eyeOpeningResponseVal) {
                              if (verbalResponseVal && motorResponseVal) {
                                CalculateSumOfB(
                                  eyeOpeningResponseVal,
                                  verbalResponseVal,
                                  motorResponseVal
                                );
                              }
                            }
                          }}
                        />
                      </div>

                      <div>
                        <label>Pain(2)</label>
                      </div>
                    </div>

                    <div className="items-center flex gap-2 whitespace-nowrap">
                      <input
                        type="radio"
                        name="eyeOpeningResponse"
                        value="3"
                        {...register("eyeOpeningResponse")}
                        onChange={(e) => {
                          let eyeOpeningResponseVal = e.target.value;
                          let verbalResponseVal = getValues("verbalResponse");
                          let motorResponseVal = getValues("motorResponse");

                          if (eyeOpeningResponseVal) {
                            if (verbalResponseVal && motorResponseVal) {
                              CalculateSumOfB(
                                eyeOpeningResponseVal,
                                verbalResponseVal,
                                motorResponseVal
                              );
                            }
                          }
                        }}
                      />
                      <div className="items-center flex gap-2 whitespace-nowrap">
                        <label>Verbal Stimuli(3)</label>
                      </div>
                    </div>

                    <div className="items-center flex gap-2 whitespace-nowrap">
                      <div>
                        <input
                          type="radio"
                          name="eyeOpeningResponse"
                          value="4"
                          {...register("eyeOpeningResponse")}
                          onChange={(e) => {
                            let eyeOpeningResponseVal = e.target.value;
                            let verbalResponseVal = getValues("verbalResponse");
                            let motorResponseVal = getValues("motorResponse");

                            if (eyeOpeningResponseVal) {
                              if (verbalResponseVal && motorResponseVal) {
                                CalculateSumOfB(
                                  eyeOpeningResponseVal,
                                  verbalResponseVal,
                                  motorResponseVal
                                );
                              }
                            }
                          }}
                        />
                      </div>

                      <div>
                        <label>Spontaneous(4)</label>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="col-span-3 ">
                  <div className="grid grid-cols-2 gap-3">
                    <fieldset className="grid justify-between border border-gray-400 w-full text-left mt-1 px-4 py-3 rounded text-sm">
                      <legend className="font-semibold text-sm text-gray-700 ">
                        Verbal Response
                      </legend>
                      <div className="grid gap-3">
                        <div className="grid py-2 gap-2">
                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="verbalResponse"
                                {...register("verbalResponse")}
                                value="1"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal = e.target.value;
                                  let motorResponseVal =
                                    getValues("motorResponse");

                                  if (verbalResponseVal) {
                                    if (
                                      eyeOpeningResponseVal &&
                                      motorResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>None(1)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="verbalResponse"
                                {...register("verbalResponse")}
                                value="2"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal = e.target.value;
                                  let motorResponseVal =
                                    getValues("motorResponse");

                                  if (verbalResponseVal) {
                                    if (
                                      eyeOpeningResponseVal &&
                                      motorResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Incoherent (2)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="verbalResponse"
                                {...register("verbalResponse")}
                                value="3"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal = e.target.value;
                                  let motorResponseVal =
                                    getValues("motorResponse");

                                  if (verbalResponseVal) {
                                    if (
                                      eyeOpeningResponseVal &&
                                      motorResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label className="">
                                Inappropriate Words (3)
                              </label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="verbalResponse"
                                {...register("verbalResponse")}
                                value="4"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal = e.target.value;
                                  let motorResponseVal =
                                    getValues("motorResponse");

                                  if (verbalResponseVal) {
                                    if (
                                      eyeOpeningResponseVal &&
                                      motorResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Confused (4)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="verbalResponse"
                                {...register("verbalResponse")}
                                value="5"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal = e.target.value;
                                  let motorResponseVal =
                                    getValues("motorResponse");

                                  if (verbalResponseVal) {
                                    if (
                                      eyeOpeningResponseVal &&
                                      motorResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Oriented (5)</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="flex justify-between border border-gray-400 w-full text-left mt-1 px-4 py-3 rounded text-sm">
                      <legend className="font-semibold text-sm text-gray-700 ">
                        Motor Response
                      </legend>

                      <div className="grid gap-3">
                        <div className="grid py-2 gap-2">
                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="motorResponse"
                                {...register("motorResponse")}
                                value="1"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal =
                                    getValues("verbalResponse");
                                  let motorResponseVal = e.target.value;

                                  if (motorResponseVal) {
                                    if (
                                      verbalResponseVal &&
                                      eyeOpeningResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>None(1)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="motorResponse"
                                {...register("motorResponse")}
                                value="2"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal =
                                    getValues("verbalResponse");
                                  let motorResponseVal = e.target.value;

                                  if (motorResponseVal) {
                                    if (
                                      verbalResponseVal &&
                                      eyeOpeningResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>
                                Extension To Pain or Decerebrate (2)
                              </label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="motorResponse"
                                {...register("motorResponse")}
                                value="3"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal =
                                    getValues("verbalResponse");
                                  let motorResponseVal = e.target.value;

                                  if (motorResponseVal) {
                                    if (
                                      verbalResponseVal &&
                                      eyeOpeningResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Flexion To Pain Or Decorticate (3)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="motorResponse"
                                {...register("motorResponse")}
                                value="4"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal =
                                    getValues("verbalResponse");
                                  let motorResponseVal = e.target.value;

                                  if (motorResponseVal) {
                                    if (
                                      verbalResponseVal &&
                                      eyeOpeningResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Withdraws From Pain (4)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="motorResponse"
                                {...register("motorResponse")}
                                value="5"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal =
                                    getValues("verbalResponse");
                                  let motorResponseVal = e.target.value;

                                  if (motorResponseVal) {
                                    if (
                                      verbalResponseVal &&
                                      eyeOpeningResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Localizes Pain (5)</label>
                            </div>
                          </div>

                          <div className="items-center flex gap-2 whitespace-nowrap">
                            <div>
                              <input
                                type="radio"
                                name="motorResponse"
                                {...register("motorResponse")}
                                value="6"
                                onChange={(e) => {
                                  let eyeOpeningResponseVal =
                                    getValues("eyeOpeningResponse");
                                  let verbalResponseVal =
                                    getValues("verbalResponse");
                                  let motorResponseVal = e.target.value;

                                  if (motorResponseVal) {
                                    if (
                                      verbalResponseVal &&
                                      eyeOpeningResponseVal
                                    ) {
                                      CalculateSumOfB(
                                        eyeOpeningResponseVal,
                                        verbalResponseVal,
                                        motorResponseVal
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Obeys Commands (6)</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h1 className="h-7 w-7 text-center flex items-center justify-center bg-orange-500 text-white rounded-full mt-2">
                C
              </h1>
              <div className="xl:flex items-center gap-2 w-full">
                <div>
                  <fieldset
                    className=" border border-gray-300
                     w-full text-left mt-1 px-4 rounded text-sm"
                  >
                    <legend className="font-semibold text-sm text-gray-700 ">
                      Chronic Health Points*
                    </legend>
                    <h1 className="my-1 text-gray-500">
                      If the patient has a history of severe order system
                      insufficiency or is immunocompromised assign points as
                      following :
                    </h1>
                    <div className="grid gap-2">
                      <div className="grid py-2 gap-3">
                        <div className="items-center flex gap-2 ">
                          <div>
                            <input
                              type="radio"
                              name="chronicHealthPoints"
                              value="2"
                              {...register("chronicHealthPoints")}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setValue("c", e.target.value);
                                }
                              }}
                            />
                          </div>

                          <div>
                            <label>
                              For Elective Postoperative Patients (2)
                            </label>
                          </div>
                        </div>

                        <div className="items-center flex gap-2 ">
                          <div>
                            <input
                              type="radio"
                              name="chronicHealthPoints"
                              value="5"
                              {...register("chronicHealthPoints")}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setValue("c", e.target.value);
                                }
                              }}
                            />
                          </div>

                          <div>
                            <label>For Non Operation / Emergency (5)</label>
                          </div>
                        </div>

                        <div className="items-center flex gap-2 ">
                          <div>
                            <input
                              type="radio"
                              name="chronicHealthPoints"
                              value="0"
                              {...register("chronicHealthPoints")}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setValue("c", e.target.value);
                                }
                              }}
                            />
                          </div>

                          <div>
                            <label>None (0)</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="mt-2 xl:mt-0 gap-2 ">
                  <div className="flex items-center space-x-2 w-full">
                    <h1 className="w-3/12 xl:w-full">Total GCS =</h1>
                    <div className="w-3/12 xl:w-full">
                      <fieldset disabled={true}>
                        <InputField
                          className="w-4/12"
                          name="totalGCS"
                          variant="outlined"
                          label="Total GCS"
                          defaultValue={""}
                          // error={errors.wbc}
                          control={control}
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          sx={{ input: { color: "#718096" } }}
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 w-full pt-3">
                    <h1 className="w-3/12 xl:w-full">Death Rate =</h1>
                    <div className="w-3/12 xl:w-full">
                      <fieldset disabled={true}>
                        <InputField
                          className=""
                          name="deathRate"
                          variant="outlined"
                          label="Death Rate"
                          // error={errors.wbc}
                          control={control}
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          sx={{ input: { color: "#718096" } }}
                        />
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-2 items-center w-full xl:w-[71%] mt-4">
              <h1 className="w-3/12">Apache Score :</h1>
              <div className="grid gap-1 grid-cols-4 w-full">
                <div className="flex items-center">
                  <fieldset disabled={true}>
                    <InputField
                      name="a"
                      variant="outlined"
                      label="A"
                      // error={errors.wbc}
                      control={control}
                      inputProps={{
                        style: { textTransform: "capitalize" },
                      }}
                      defaultValue={""}
                      sx={{ input: { color: "#718096" } }}
                    />
                  </fieldset>

                  <h1 className="font-semibold">+</h1>
                </div>
                <div className="flex items-center">
                  <fieldset disabled={true}>
                    <InputField
                      name="b"
                      variant="outlined"
                      label="B"
                      // error={errors.wbc}
                      control={control}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      defaultValue={""}
                      sx={{ input: { color: "#718096" } }}
                    />
                  </fieldset>
                  <h1 className="font-semibold">+</h1>
                </div>
                <div className="flex items-center">
                  <fieldset disabled={true}>
                    <InputField
                      name="c"
                      variant="outlined"
                      label="C"
                      // error={errors.wbc}
                      control={control}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      defaultValue={""}
                      sx={{ input: { color: "#718096" } }}
                    />
                  </fieldset>
                  <h1 className="font-semibold">=</h1>
                </div>
                <div>
                  <fieldset disabled={true}>
                    <InputField
                      // className="text-sm"
                      name="apacheScore"
                      variant="outlined"
                      label="Apache Score"
                      // error={errors.wbc}
                      control={control}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      defaultValue={""}
                      sx={{ input: { color: "#718096" } }}
                    />
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div className="flex space-x-2 justify-end w-full items-center mt-2">
            {props.userActions &&
              props.userActions.map((singleActionObj) => {
                if (
                  singleActionObj.isAction === false &&
                  singleActionObj.action === "Create"
                ) {
                  return (
                    <>
                      <CommonButton
                        label="Reset"
                        className="border border-customRed text-customRed"
                        onClick={(e) => {
                          reset(defaultValues);
                        }}
                      />
                      {props.patientInformation?.BedReleased &&
                      props.selectedPatient?.BedReleased ? null : (
                        <CommonButton
                          label="Add"
                          className="bg-customGreen text-white"
                          onClick={(e) => {
                            trigger();

                            let A = getValues("a");
                            let B = getValues("b");
                            let C = getValues("c");

                            //open the confirmation modal if the below conditions are satisfied.
                            if (
                              patientVisitId &&
                              isValid &&
                              A !== "" &&
                              B !== "" &&
                              C !== "" &&
                              props.displayInfo
                            ) {
                              setOpenPost(true);
                            }

                            //open the confirmation modal if the below conditions are satisfiied.
                            if (
                              props.displayInfo === null &&
                              isValid &&
                              A !== "" &&
                              B !== "" &&
                              C !== "" &&
                              props.admissionId
                            ) {
                              setOpenPost(true);
                            }

                            if (
                              (patientVisitId && props.displayInfo !== null) ===
                              false
                            ) {
                              errorAlert("Please Select Patient.");
                            }

                            if (A === "") {
                              errorAlert("Evaluate value of A.");
                            }

                            if (B === "") {
                              errorAlert("Evaluate value of B.");
                            }

                            if (C === "") {
                              errorAlert("Evaluate value of C.");
                            }
                          }}
                        />
                      )}
                    </>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </form>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={() => {
            setOpenBackdrop(false);
            setOpenPost(false);
          }}
          confirmationSubmitFunc={() => {
            handleSubmit(onSubmitDataHandler)();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure you want to save this Apache score ?"
          confirmationButtonMsg="Save"
        />

        <ConfirmationModal
          confirmationOpen={deleteConfirm}
          confirmationHandleClose={() => {
            closeDeleteConfirmation();
          }}
          confirmationSubmitFunc={() => {
            deleteTheApacheScoreRecord();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure you want to delete this Apache score ?"
          confirmationButtonMsg="Delete"
        />
      </div>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
    </>
  );
}

export default ApacheScore;

