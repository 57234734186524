import authHeader from "../authentication/authservices/auth-header";
import apiClient from "../http-common";

export const getPatientSearchAutocomplete = (searchString, opdIpd) => {
  return apiClient.get(
    `counterSale/getPatientSearch/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientInfoByPatientId = (patientId, opdIpd, storeId) => {
  return apiClient.get(
    `/counterSale/patientInfo/${patientId}/${opdIpd}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDruglist = (postObj) => {
  return apiClient.post(`/counterSale/itemDetailsList`, postObj, {
    headers: authHeader(),
  });
};

export const getItemBatchNumber = (postObj) => {
  return apiClient.post(`/counterSale/batchNumberForCounterSale`, postObj, {
    headers: authHeader(),
  });
};

export const getAlternativeDrugList = (postObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemDetailListByGenericId`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

export const getItemKitDropdown = (searchString) => {
  return apiClient.get(
    `/inventory/inPatientIssue/itemKitList/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const getKitItemsById = (kitId, storeId) => {
  return apiClient.get(
    `/inventory/inPatientIssue/kitItems/${kitId}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPaymentModeList = () => {
  return apiClient.get(`/misc/paymentType`, {
    headers: authHeader(),
  });
};

export const saveCounterSales = (postObj) => {
  return apiClient.post(`/counterSale/saveCounterSale`, postObj, {
    headers: authHeader(),
  });
};

export const getEmployeeDetails = (employeeId, departmentId) => {
  return apiClient.get(
    `/patientInfo/employeeDetails/${employeeId}/${departmentId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDoctorsDropdownForCounter = (searchString) => {
  return apiClient.get(`/misc/doctor/${searchString}`, {
    headers: authHeader(),
  });
};

//print the pharmacy sales
// export const printCounterSales = (salesId) => {
//   return apiClient.get(`/reports/inventory/counterSales/${salesId}`);
// };

export const printCounterSales = (salesId,printType) => {
  return apiClient.get(`/reports/inventory/counterSales/${salesId}/${printType}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

// services to get inPatientIssue indent details for counter sales
export const getIndentItemsForCounterSales = (searchObject) => {
  return apiClient.post(
    `/inventory/inPatientIssue/getPatientIndent`,
    searchObject,
    {
      headers: authHeader(),
    }
  );
};

export const getIndentDetailsByIndentId = (storeId, IndentId) => {
  return apiClient.get(
    `/inventory/inPatientIssue/patientIndentListOfItems/${storeId}/${IndentId}`,
    {
      headers: authHeader(),
    }
  );
};
