import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { format, isAfter, isValid } from "date-fns";
import { useDispatch } from "react-redux";
import { routeAdded } from "../../../../authentication/slices/auth";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  fetchFloor,
  fetchUnit,
} from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchAdmissionList,
  fetchAdmissionPatientListAutoComplete,
  fetchPrintPatientList,
} from "../../../services/admissionServices/AdmissionServices";
import AdmissionListCollapseTable from "./AdmissionListCollapseTable";
import AdmissionListFilter from "./AdmissionListFilter";

const AdmissionList = (props) => {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const { updateComponent } = props;
  //
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);

  const location = useLocation();
  const [userActions, setUserActions] = useState([]);

  const [data, setData] = useState({ result: [], privileges: [] });
  const [list, setList] = useState();
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [searchString, setSearchString] = useState("");
  const [dataResult, setDataResult] = useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [units, setUnits] = useState([]);
  const [unitId, setUnitId] = useState(2);

  const [floors, setFloors] = useState([]);
  const [floorId, setFloorId] = useState({ id: token.floorId } || null);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const handleClosePrintModal = () => {
    navigate("/ipd/admission", { state: { menuId: location?.state?.menuId } });
  };

  //////////////
  let navigate = useNavigate();

  const { control, register, watch, resetField, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      unit: {
        id: 2,
        label: "IMH(FATIMA NAGAR)",
        value: "IMH(FATIMA NAGAR)",
      },
      currentAdmissionList: true,
      cancelledAdmissions: false,
      pendingPassportDetails: false,
      OtherThanIndian: false,
      pendingDocuments: false,
      // fromDate: new Date(),
      // toDate: new Date(),
    },
  });

  let CurrentAdmission = watch("currentAdmissionList");
  let CancelledAdmissions = watch("cancelledAdmissions");
  let PendingPassportDetails = watch("pendingPassportDetails");
  let OtherThanIndian = watch("otherThanIndian");
  let isPendingDocument = watch("pendingDocuments");
  let PresentOnBed = watch("presentOnBed");
  // let floorId = watch("floor");

  //used for fetch apdateed data at midnight
  useEffect(() => {
    console.log("updateComponent 12 Am", updateComponent);
    if (updateComponent) {
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
    }
  }, [updateComponent]);

  // API to Get Search Options in Dropdown Menu
  const onInputChange = (enteredData) => {
    let searchString = enteredData;

    const autocompleteObj = {
      currentAdmission: CurrentAdmission !== true ? 0 : 1,
      floorId: (token.floorId > 1 && token.floorId) || floorId?.id,
      fromDate: !!selectedFromDate
        ? format(selectedFromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        : new Date(),
      isCancelled: CancelledAdmissions,
      otherThanIndia: OtherThanIndian !== true ? 0 : 1,
      isPendingDocument: isPendingDocument !== true ? 0 : 1,
      pendingPassport: PendingPassportDetails !== true ? 0 : 1,
      presentOnBed: 1,
      searchString: searchString,
      toDate: !!selectedToDate
        ? format(selectedToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        : new Date(),
      unitId: unitId,
    };

    if (enteredData.length > 0) {
      fetchAdmissionPatientListAutoComplete(autocompleteObj)
        .then((response) => {
          setList(response.data.result);
        })
        .catch(() => {});
    }
  };

  // 1. Unit List API
  useEffect(() => {
    fetchUnit()
      .then((response) => {
        setUnits(response.data.result);
      })
      .catch(() => {});
    //
    token.floorId !== null &&
      token.floorId > 1 &&
      token.floor &&
      setValue("floor", {
        id: token.floorId,
        label: token.floor,
        value: token.floor,
      });
  }, []);

  // 3. Floor List API
  useEffect(() => {
    fetchFloor()
      .then((response) => {
        setFloors(response.data.result);
      })
      .catch(() => {});
  }, []);

  //API to get Patient List
  useEffect(() => {
    setSpinner(true);
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      handleList();
  }, [
    unitId,
    searchString,
    selectedFromDate,
    selectedToDate,
    CurrentAdmission,
    CancelledAdmissions,
    PendingPassportDetails,
    OtherThanIndian,
    isPendingDocument,
    PresentOnBed,
    floorId,
  ]);

  const handleList = (forPagination) => {
    const searchStringParams = {
      currentAdmission: CurrentAdmission !== true ? 0 : 1,
      unitId: unitId,
      floorId: floorId?.id,
      menuId: location?.state?.menuId,
      searchString: "",
      searchId: searchString,

      toDate: !!selectedToDate
        ? format(selectedToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        : new Date(),
      fromDate: !!selectedFromDate
        ? format(selectedFromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        : new Date(),

      size: rowsPerPage,
      page: !forPagination ? 0 : page,
      ///
      isCancelled: CancelledAdmissions,
      otherThanIndia: OtherThanIndian !== true ? 0 : 1,
      isPendingDocument: isPendingDocument !== true ? 0 : 1,
      pendingPassport: PendingPassportDetails !== true ? 0 : 1,
      presentOnBed: 1,
    };

    fetchAdmissionList(searchStringParams)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSpinner(false);

          if (forPagination) {
            setDataResult((prevData) => [
              ...prevData,
              ...response.data.result.list,
            ]);
          } else {
            setDataResult(response.data.result.list);
            setPage(0);
          }
          setData(response.data);

          setUserActions(response.data.actions);
          setCount(response.data.count);
          handleRouteAddition(response.data.actions);
        }
      })
      .catch(() => {
        setSpinner(false);
      });
  };

  const dispatch = useDispatch();
  const handleRouteAddition = (actions) => {
    actions.map((item) => {
      if (item.menuKey) {
        dispatch(routeAdded(item.menuKey));
      }
    });
  };

  const handleOpenPrintModal = () => {
    const printObj = {
      date: new Date(),
      floorId: floorId?.id,
      currentAdmission: CurrentAdmission,
    };
    // print service
    fetchPrintPatientList(printObj).then((response) => {
      if (response) {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      }
    });
  };

  return (
    <div className="py-1 mt-8 mx-4 w-full">
      <p className="text-center text-2xl my-2 text-gray-700 font-Poppins">
        Admission List
      </p>
      <form>
        <div className="grid lg:grid-cols-9 md:grid-cols-8 lg:gap-0 lg:gap-x-2  md:gap-3 mr-6">
          {/* //SearchBar// */}
          <div className="md:col-span-8 lg:col-span-3 z-50">
            <SearchBar
              name="searchableSelect"
              placeholder="Search By Patient Name/MR.No./Mobile No."
              isSearchable={true}
              searchIcon={true}
              handleInputChange={onInputChange}
              onChange={(e) => {
                if (e !== null) {
                  setSearchString(e.id);
                } else {
                  setSearchString("");
                  setPage(0);
                }
              }}
              dataArray={list}
            />
          </div>

          {/* from Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              disabled={CurrentAdmission ? true : false}
              inputRef={{
                ...register("fromDate", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedFromDate(e.target.value);
                    }
                  },
                }),
              }}
            />
          </div>

          {/* to Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              disabled={CurrentAdmission ? true : false}
              inputRef={{
                ...register("toDate", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedToDate(e.target.value);
                    }
                  },
                }),
              }}
            />
          </div>

          {/* //Unit// */}
          <div className=" w-full col-span-2 lg:col-span-1">
            <DropdownField
              control={control}
              name="unit"
              placeholder="Unit"
              dataArray={units}
              isSearchable={false}
              inputRef={{
                ...register("unit", {
                  onChange: (e) => {
                    setUnitId(e.target.value.id);
                  },
                }),
              }}
            />
          </div>

          <div className=" w-full col-span-2 lg:col-span-1">
            <DropdownField
              control={control}
              name="floor"
              placeholder="Floor"
              dataArray={floors}
              isSearchable={false}
              isClearable={true}
              inputRef={{
                ...register("floor", {
                  onChange: (e) => {
                    setFloorId(e.target.value);
                  },
                }),
              }}
            />
          </div>
          {/* // Current Admission// */}
          <div className="col-span-2  ">
            <div
              onClick={(e) => {
                if (e.target.checked) {
                  setValue("cancelledAdmissions", false);
                }
              }}
            >
              <CheckBoxField
                control={control}
                name="currentAdmissionList"
                label="Current Admission"
                style={{ fontSize: "12px" }}
              />
            </div>
          </div>
          {/* //checkbox filters */}
          <div className="grid md:col-span-8 grid-cols-12 lg:col-span-9  ">
            <div className="flex md:flex-wrap col-span-9">
              <div
                onClick={(e) => {
                  if (e.target.checked) {
                    setValue("currentAdmissionList", false);
                  }
                }}
              >
                <CheckBoxField
                  control={control}
                  name="cancelledAdmissions"
                  label="Cancelled Admissions"
                  style={{ fontSize: "12px" }}
                />
              </div>
              <div>
                <CheckBoxField
                  control={control}
                  name="pendingPassportDetails"
                  label="Pending Passport Details"
                  style={{ fontSize: "12px" }}
                />
              </div>
              <div>
                <CheckBoxField
                  control={control}
                  name="otherThanIndian"
                  label="Other Than Indian"
                  style={{ fontSize: "12px" }}
                />
              </div>
              <div>
                <CheckBoxField
                  control={control}
                  name="pendingDocuments"
                  label="Pending Documents"
                  style={{ fontSize: "12px" }}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2 md:grid-cols-2 col-span-3 ">
              <div>
                <CommonButton
                  onClick={(e) => {
                    setPage(0);
                  }}
                  className="bg-customBlue text-white"
                  searchIcon
                />
              </div>
              <div className="">
                <button
                  className="border border-gray-500 h-[2.3rem] rounded-md  w-16 cursor-pointer p-1 bg-white"
                  onClick={(e) => {
                    e.preventDefault();
                    resetField("fromDate");
                    resetField("toDate");
                    handleList();
                  }}
                >
                  <RefreshIcon />
                </button>
              </div>
              {/* ///print button */}
              <div className="flex justify-end space-x-2 md:grid-cols-2 lg:col-span-2 ">
                <div>
                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Print" ? (
                        <>
                          <Tooltip title={"Print Patient List"}>
                            <LocalPrintshopIcon
                              sx={{ color: "#4B5563", marginTop: "7px " }}
                              onClick={(e) => {
                                floorId && floorId?.id !== null
                                  ? handleOpenPrintModal()
                                  : warningAlert("Please Select Floor..");
                              }}
                            />
                          </Tooltip>
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-8 lg:col-span-9">
            <hr className="border my-2 divide-x-8 border-slate-300" />
          </div>

          <div className="lg:grid md:grid lg:justify-between item-center w-full lg:grid-cols-6 md:grid-cols-2 md:col-span-8 lg:col-span-9 ml-2">
            {/* //New Admission// */}
            <div className="">
              <p className="text-black text-xs  lg:text-sm my-auto lg:pl-0.5 flex">
                New Admissions :
                <span className="text-sm lg:text-lg text-green-500 font-semibold pl-1 -mt-0.5">
                  {data.result.countInfo && data.result.countInfo.NewAdmissions}
                </span>
              </p>
            </div>

            {/* //Total Admission// */}
            <div className="">
              <p className="text-black text-xs lg:text-sm my-auto pl-0.5 flex">
                Total Admissions :
                <span className="text-sm lg:text-lg text-blue-400 font-semibold pl-1 -mt-0.5">
                  {data.result.countInfo &&
                    data.result.countInfo.TotalAdmissions}
                </span>
              </p>
            </div>

            {/* //Total Discharge// */}
            <div className="">
              <p className="text-black text-xs lg:text-sm my-auto  flex">
                Discharge Initiated :
                <span className="text-sm lg:text-lg text-rose-400 font-semibold pl-1 -mt-0.5 ">
                  {data.result.countInfo &&
                    data.result.countInfo.DischargeInitiated}
                </span>
              </p>
            </div>
            {/* //Total Discharge// */}
            <div className="">
              <p className="text-black text-xs lg:text-sm my-auto  flex">
                Today's Discharges :
                <span className="text-sm lg:text-lg text-rose-400 font-semibold pl-1 -mt-0.5 ">
                  {data.result.countInfo &&
                    data.result.countInfo.TotalDischarge}
                </span>
              </p>
            </div>
            {/* //Bed Released// */}
            <div className="">
              <p className="text-black text-xs lg:text-sm my-auto  flex">
                Bed Released :
                <span className="text-sm lg:text-lg text-rose-400 font-semibold pl-1 -mt-0.5 ">
                  {data.result.countInfo && data.result.countInfo.BedReleased}
                </span>
              </p>
            </div>

            <div className="flex md:col-span-2 lg:col-span-1 justify-end ">
              <div>
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" && (
                      <CommonButton
                        label="New Admission"
                        onClick={() =>
                          navigate("/admission/PatientAdmissionDetails", {
                            state: {
                              menuId: obj.menuId,
                              previousMenuId: location?.state?.menuId,
                              isedit: false,
                              privilege: obj.action,
                            },
                          })
                        }
                        className="bg-customGreen text-white h-8 px-3 text-sm font-medium"
                      />
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
      {spinner ? (
        <div className="grid justify-center mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <div className=" mt-2 ml-3">
          {dataResult.length > 0 ? (
            <AdmissionListCollapseTable
              populateTable={handleList}
              dataResult={dataResult}
              setDataResult={setDataResult}
              userActions={userActions}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              handleList={handleList}
            />
          ) : (
            <div className="grid justify-center">
              <div className="container w-full grid lg:grid-cols-1 pt-20 md:w-full mt-8 md:rounded-md mx-auto lg:px-24 md:px-10">
                <div className="">
                  <div className="row ">
                    <div className="w-[100%] flex justify-between items-center  mt-4 rounded">
                      <div className="text-gray-500 font-bold text-center ">
                        <h1 className="text-base">No Records Found</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            // ""
          )}
        </div>
      )}

      {/* Print Modal */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* //Filter Modal// */}
      <Modal
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "48%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <AdmissionListFilter />
        </Box>
      </Modal>
    </div>
  );
};

export default AdmissionList;
