import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";

export default function AddIVTable(props) {
  //actions
  const [openPost, setOpenPost] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(null);

  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const deleteRecord = () => {
    if (deleteIndex !== null) {
      let newTemplateData = [...props.data];
      newTemplateData.splice(deleteIndex, 1);
      props.setData(newTemplateData);
      handleClosePost();
    }
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-72 2xl:h-96 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Actions</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Drug Name</span>
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Dose</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="px-4 py-1 flex whitespace-nowrap">
                          <Tooltip title="Delete Item">
                            <div
                              className="text-customRed mr-3"
                              onClick={() => {
                                setOpenPost(true);
                                setDeleteIndex(row.Id);
                              }}
                            >
                              {<DeleteOutlineOutlinedIcon />}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="whitespace-nowrap flex text-center">
                          {row["Drug Name"]}
                        </TableCell>
                        <TableCell className="whitespace-nowrap flex text-center">
                          {row.Dose}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {props.data.length > 0 ? null : (
                <div className="flex justify-center text-gray-400 font-semibold mt-9">
                  No Drug Added
                </div>
              )}
            </TableContainer>
          </Paper>
        </Box>
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={deleteRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure Want To Delete This Item ?"
          confirmationButtonMsg="Delete"
        />
      </div>
    </>
  );
}
