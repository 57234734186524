import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

import { useEffect, useState } from "react";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchCheckList,
  fetchCheckListDetails,
  postsavedCheckList,
} from "../../services/patientClearenceServices/PatientClearenceServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  minHeight: "80%",
  // maxHeight: "80%",
  // overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function CheckListModal(props) {
  //
  var token = JSON.parse(localStorage.getItem("userInfo"));

  //
  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const [checkListArr, setCheckListArr] = useState([]);
  // past Details
  const [checkListDetails, setCheckListDetails] = useState([]);

  const { open, handleClose, selctedPatient, reset } = props;


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      amount: 0,
    },
  });
  const { control } = methods;

  const checklistObj = {
    addedBy: 0,
    admissionId: 0,
    checkListId: [0],
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);
    handleClose();

    //
    checklistObj.addedBy = token.userId;
    checklistObj.admissionId = selctedPatient.AdmissionId;
    let checkId = [];
    for (let obj of checkListDetails) {
      checkId.push(obj.CheckListId);
    }
    checklistObj.checkListId = checkId;

    checklistObj.menuId = props.menuId;
    checklistObj.privilege = props.privilege;

    //

    postsavedCheckList(checklistObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          reset();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  // fetchCheckList
  useEffect(() => {
    getCheckList();
    getCheckListDetails();
  }, []);

  const getCheckList = () => {
    fetchCheckList()
      .then((response) => response.data)
      .then((res) => {
        setCheckListArr(res.result);
      });
  };

  const getCheckListDetails = () => {
    fetchCheckListDetails(selctedPatient.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setCheckListDetails(res.result);
      });
  };

  //
  const handleCheck = (e, item, i) => {
    let arr = [...checkListDetails];
    if (e.target.checked) {
      let CheckListId = item.id;
      arr.push({ CheckListId });
      setCheckListDetails(arr);
    } else if (!e.target.checked) {
      let filtered = arr.filter((data) => data.CheckListId !== item.id);
      setCheckListDetails(filtered);
    }

  };

  return (
    <>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
            <CancelPresentationIconButton
              onClick={(e) => {
                handleClose();
                e.preventDefault();
              }}
            />
            <div>
              <div className="my-2">
                <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                  Patient Clearance Check List
                </span>
              </div>
              <div>
                <fieldset
                  className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
                >
                  <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-28">Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selctedPatient && selctedPatient["Patient Name"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                      <span className=" font-semibold w-28">MR. No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {selctedPatient && selctedPatient.MRNo}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="border border-gray h-80">
                {checkListArr.map((item, i) => {
                  return (
                    <>
                      <div className="my-2" key={i}>
                        <span className="m-2 text-gray-700 font-semibold whitespace-nowrap">
                          {item.label}
                        </span>
                      </div>
                      <div
                        className="pl-2"
                        onChange={(e) => {
                          handleCheck(e, item, i);
                        }}
                      >
                        <CheckBoxField
                          control={control}
                          name={`ckeckList${i}`}
                          label={item?.label}
                          defaultValue={
                            checkListDetails[i]?.CheckListId === item.id
                              ? true
                              : false
                          }
                        />
                      </div>
                    </>
                  );
                })}
              </div>
              <div></div>
              <div className="flex gap-3 justify-end my-2">
                <div>
                  <CommonButton
                    label="Close"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>

                <div className="flex justify-end">
                  <CommonButton
                    label="Save Check List"
                    className="bg-customGreen text-white"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}
