import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//kkkkkk
export default function CommonTable(props) {
  //state varibale for the table

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  let allHeaders = [];
  let headers = [];

  if (props.apacheList.result.length > 0) {
    allHeaders = Object.keys(props.apacheList.result[0]);
  }

  if (props.apacheList.result.length === 0) {
    headers = ["dateTime", "addedBy", "vipScore"];
  } else if (props.apacheList.result.length > 0) {
    headers = removeHeaders(allHeaders, ["Id", "phlebitis", "visitId"]);
  }
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-[240px] 2xl:h-60 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.apacheList.result.length > 0
                    ? props.apacheList.result.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            {headers &&
                              headers.map((header, i) => (
                                <TableCell
                                  className="whitespace-nowrap"
                                  key={i}
                                  onClick={() => {
                                    props.displayView(row, index);
                                  }}
                                >
                                  {header.toLowerCase() === "status" ? (
                                    row[header] === true ? (
                                      <button className="border border-green-800 text-center rounded px-5 text-green-800">
                                        Active
                                      </button>
                                    ) : (
                                      <button className="border border-red-500 text-center rounded px-3 text-red-500">
                                        InActive
                                      </button>
                                    )
                                  ) : (
                                    row[header]
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
