import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllNursingNote = (listObj) => {
  return apiClient.post(`/clinicalCareChart/nursingNote`,listObj,
  {
    headers: authHeader(),
  });
};


// /api/clinicalCareChart/nursingNoteDesc/{orderSheetId}
export const getNursingNoteDescription = (orderSheetId) => {
  return apiClient.get(`clinicalCareChart/nursingNoteDesc/${orderSheetId}`,
  {
    headers: authHeader(),
  });
};
export const deleteNursingNote = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteNursingNote/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const NursesNotePrint = (postObj) => {
  return apiClient.post(`/reports/nursing/cccNursingNotes`, postObj, {
    headers: authHeader(),
  });
};
