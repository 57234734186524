import { Box, Modal, TextField, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import RadioField from "../../../common/components/FormFields/RadioField";
import InputField from "../../../common/components/FormFields/InputField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { format } from "date-fns";
import { addNewMiscellaneousExpense } from "../../services/miscellaneousExpenseServices/MiscellaneousExpenseServices";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function MiscellaneousExpenseModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "55%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const paymentMode = [
    { id: 0, label: "Cash", value: 0 },
    { id: 1, label: "Cheuqe", value: 1 },
  ];

  const defaultValues = {
    paymentMode: "0",
    name: "",
    amount: "",
    accountHead: null,
    billNo: "",
    billDate: new Date(),
    remark: "",
    draftChequeNo: "",
    chequeDate: new Date(),
    ledger: "",
  };

  const schema = yup.object({
    name: yup
      .string()
      .required("Required")
      .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric are allowed"),
    amount: yup
      .string()
      .required("Required")
      .matches(/^[0-9\s]+$/, "Only alphanumeric are allowed")
      .max(10),

    draftChequeNo: yup
      .string()
      .nullable()
      .when("paymentMode", (paymentModeVal) => {
        if (paymentModeVal === "1") {
          return yup
            .string()
            .nullable()
            .matches(/^[0-9\s]+$/, "Numbers & Special Characters Not Allow")
            .required("Required");
        } else {
          clearErrors("draftChequeNo");
        }
      }),
    chequeDate: yup.date().required("Required").required(),
  });

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  let loggedUser = JSON.parse(localStorage.getItem("userInfo"));

  let paymentModeVal = watch("paymentMode");
  let chequeDateVal = watch("chequeDate");
  let billDateVal = watch("billDate");

  const [finalData, setFinalData] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [privilege, setPrivilege] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  function postMiscellaneousIncome(obj) {
    addNewMiscellaneousExpense(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.populateTable();
        setOpenBackdrop(false);
        props.handleClose();
        reset(defaultValues);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postMiscellaneousIncome(finalData);
  }

  const onSubmitDataHandler = (data) => {
    // swagger{
    //   {
    //   "accountHead": 0,
    //   "amount": 0,
    //   "billNo": 0,
    //   "cashCredit": true,
    //   "chequeDate": "2024-01-06T10:20:25.908Z",
    //   "date": "2024-01-06T10:20:25.908Z",
    //   "draftChequeNo": 0,
    //   "ledger": "string",
    //   "name": "string",
    //   "remark": "string",
    //   "unitId": 0,
    // }

    let cashCredit;
    if (paymentModeVal === "0") {
      cashCredit = false;
    } else if (paymentModeVal === "1") {
      cashCredit = true;
    }
    const postedObj = {
      accountHead: data.accountHead?.id,
      amount: Number(data.amount),
      billNo: data.billNo,
      cashCredit: cashCredit,
      name: data.name,
      remark: data.remark,
      unitId: loggedUser.unitId,
      date: format(billDateVal, "yyyy-MM-dd'T'HH:mm:ss.921'Z'"),
      draftChequeNo: paymentModeVal === "1" ? data.draftChequeNo : "",
      ledger: data.ledger,
      chequeDate: format(chequeDateVal, "yyyy-MM-dd'T'HH:mm:ss.921'Z'"),
    };

    setFinalData(postedObj);
    setOpenPost(true);
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <div className="grid md:grid-cols-1  w-full">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              reset(defaultValues);
            }}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="m-4 items-center align-middle">
            <h1 className=" flex whitespace-nowrap rounded text-black font-semibold">
              Add Miscellaneous Expense
            </h1>
            <hr className="border border-customBlue mx-1 my-2 " />
            <div className="flex space-x-3 items-center pt-3">
              <h1 className="text-sm font-semibold pb-1">Payment Mode :</h1>
              <RadioField
                dataArray={paymentMode}
                name="paymentMode"
                control={control}
              />
            </div>

            <div className="grid grid-cols-4 gap-2">
              <div className="col-span-3">
                <InputField
                  control={control}
                  name="name"
                  label="Name*"
                  error={errors.name}
                />
              </div>
              <InputField
                control={control}
                name="amount"
                label="Amount*"
                error={errors.amount}
              />
              <DropdownField
                control={control}
                name="accountHead"
                placeholder="Account Head"
                isSearchable={true}
                isClearable={true}
                dataArray={props.accountHead}
                menuPlacement="bottom"
                maxMenuHeight={150}
              />
              {/* <InputField control={control} name="billNo" label="Bill No" /> */}

              <div className="col-span-3">
                <Controller
                  name="remark"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-multiline-flexible"
                      label="Remark"
                      multiline
                      minRows={1}
                      className="w-full"
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <InputField
                type="number"
                name="billNo"
                control={control}
                label="Bill No"
              />
              <DatePickerFieldNew
                control={control}
                name="billDate"
                label="Bill Date"
                value={new Date()}
                size="small"
                inputFormat="yyyy-MM-dd"
              />

              {paymentModeVal !== "0" ? (
                <>
                  <InputField
                    control={control}
                    name="draftChequeNo"
                    label="Draft/Cheque No."
                    error={errors.draftChequeNo}
                    type="number"
                  />
                  <DatePickerFieldNew
                    control={control}
                    name="chequeDate"
                    label="Date"
                    value={new Date()}
                    size="small"
                    inputFormat="yyyy-MM-dd"
                    error={errors.chequeDate}
                    disablePast={true}
                  />

                  <div className="col-span-4">
                    <InputField
                      control={control}
                      name="ledger"
                      label="Drwan Or"
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className="flex justify-end space-x-3 pt-2">
              <CommonButton
                type="button"
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  reset(defaultValues);
                }}
              />

              <CommonButton
                type="submit"
                label="Save"
                className="bg-customGreen text-white"
              />
            </div>
          </div>
        </form>

        <CommonBackDrop openBackdrop={openBackdrop} />

        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure you want to add this record ?"
          confirmationButtonMsg="Save"
        />
      </Box>
    </Modal>
  );
}
