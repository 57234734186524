import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import {
  fetchAdmissionRefusal,
  saveAdmissionDenial,
} from "../../services/emergencyservice/EmergencyServices";
const unit = [
  {
    id: 1,
    value: "Reason",
    label: "Reason",
  },
];
export default function AdmissionDenialModal(props) {
 
  const [newHeader, setNewHeader] = React.useState("");

  //prints print
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    props.handleCloseAdmissionDenialModal();
  };
  const handleOpenPrintModal = (emergencyId) => {
    // admissionId,formId
    fetchAdmissionRefusal(emergencyId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
    // setUrlforPrint(`${baseUrl}/reports/getAdmissionForm/${admissionId}`);
  };
  ////
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minHeight: "30%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    py: 4,
    px: 2,
  };

  function onSubmitDataHandler(data) {
    // setOpenPrint(true);

    let getPatientInfo = localStorage.getItem("userInfo");
    let getPatientInfoObj = JSON.parse(getPatientInfo);
    saveAdmissionDenial(
      // getPatientInfoObj.userId,
      data.remark,
      props.emergencyId.EmergencyPatientId,
      props.menuId,
      props.privilege
    )
      .then((response) => response.data)
      .then((res) => {

        handleOpenPrintModal(props.emergencyId.EmergencyPatientId);

        successAlert(res.message);
        reset();
        props.populateTable();
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose();

          //   reset(defaultValues);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleCloseAdmissionDenialModal();
              //   reset(defaultValues);
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <fieldset className="border border-gray-400 w-full text-left px-2 rounded">
              <legend className="font-semibold text-sm text-gray-800 ml-2 lg:ml-1">
                Denial Remark
              </legend>
              <div className="grid gap-4 w-full py-2">
                <div>
                  
                  <DropdownField
                    control={control}
                    error={errors.unit}
                    name="delayreason"
                    dataArray={unit}
                    placeholder="Select Denial Reason"
                    isMulti={false}
                    isSearchable={false}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-multiline-static"
                    name="remark"
                    {...register("remark")}
                    label="Transfer Reason "
                    placeholder="Transfer Reason"
                    value={newHeader}
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={1}
                    fullWidth
                    onChange={(e) => setNewHeader()}
                  />
                </div>
              </div>
              <div className="grid justify-end pb-2">
                {/* <SaveButton /> */}
                <CommonButton
                  type="submit"
                  label="Save"
                  className="bg-customGreen text-white"
                />
              </div>
            </fieldset>
          </form>
        </Box>
      </Modal>

      {/* Print Modal */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
}
