import React, { useContext } from "react";
import "../../../input.css";

import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchDepartment,
  fetchWard,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { fetchbeds } from "../../services/patientTransfer/PatientTransferServices";
import { PatientTransferContext } from "./PatientTransfer";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";

const isTrumaPatient = [
  {
    id: 0,
    value: "Yes",
    label: "Yes",
  },
  {
    id: 1,
    value: "NO",
    label: "No",
  },
];

const MewsRadio = [
  {
    id: "0",
    value: "0",
    label: "A",
  },
  {
    id: "1",
    value: "1",
    label: "V",
  },
  {
    id: "2",
    value: "2",
    label: "P",
  },
  {
    id: "3",
    value: "3",
    label: "U",
  },
];

const PatientTransferDetails = () => {
  const { emoloyeeName, getEmoloyeeName } = useContext(PatientTransferContext);
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [department, setDepartment] = useState();
  const [departmentSearchString, setDepartmentSearchString] = useState();
  const [ward, setWard] = useState();
  const [bedsFrom, setBedsFrom] = useState();
  const [bedsTo, setBedsTo] = useState();
  const [mewsScore, setMewsScore] = useState(0);

  // const [doctor, setDoctor] = useState();

  useEffect(() => {
    getDepartment();
  }, [departmentSearchString]);

  useEffect(() => {
    getWard();

    setValue("rmoName", {
      id: token?.userId,
      label: token?.rmoName,
      value: token?.rmoName,
    });
  }, []);

  // Api to Get department
  const getDepartment = () => {
    departmentSearchString &&
      fetchDepartment(departmentSearchString).then((response) => {
        setDepartment(response.data.result);
      });
  };

  const getWard = () => {
    fetchWard()
      .then((response) => response.data)
      .then((res) => {
        setWard(res.result);
      });
  };

  // Api to Get beds

  const getBedsFrom = (wardId, occupiedStatus) => {
    fetchbeds(wardId, occupiedStatus).then((response) => {
      setBedsFrom(response.data.result);
    });
  };

  const getBedsTo = (wardId, occupiedStatus) => {
    fetchbeds(wardId, occupiedStatus).then((response) => {
      setBedsTo(response.data.result);
    });
  };

  /////mews calculations

  let Avpu = watch("mewsRadio");

  useEffect(() => {
    mewsScore !== undefined &&
      mewsScore > 0 &&
      setValue("mews", mewsScore, {
        shouldValidate: true,
      });
  }, [mewsScore]);

  ///////////////
  const [temp, pulse, rr] = watch(["temp", "pulse", "respiration"]);

  // to remove slash while considering b/p
  let bpStr = watch("bp");
  let arr = !!bpStr && bpStr?.split("/");
  let bp = arr && arr[0];
  // calculation for mews score calculation
  function SystolicBloodPressure(sbp) {
    if (sbp >= 200) {
      return 2;
    } else if (sbp >= 101 && sbp <= 199) {
      return 0;
    } else if (sbp >= 81 && sbp <= 100) {
      return 2;
    } else if (sbp >= 71 && sbp <= 80) {
      return 2;
    } else if (sbp <= 70) {
      return 3;
    }
  }
  function PulseCalculation(pulse) {
    if (pulse <= 40) {
      return 2;
    } else if (pulse >= 41 && pulse <= 50) {
      return 1;
    } else if (pulse >= 51 && pulse <= 100) {
      return 0;
    } else if (pulse >= 101 && pulse <= 110) {
      return 1;
    } else if (pulse >= 111 && pulse <= 129) {
      return 2;
    } else if (pulse >= 130) {
      return 3;
    }
  }
  function RespiratoryRate(bpm) {
    if (bpm <= 9) {
      return 2;
    } else if (bpm > 9 && bpm <= 14) {
      return 0;
    } else if (bpm >= 15 && bpm <= 20) {
      return 1;
    } else if (bpm >= 21 && bpm <= 29) {
      return 2;
    } else if (bpm >= 30) return 3;
  }
  function Temperature(fahrenheit) {
    if (fahrenheit < 95) {
      return 3;
    } else if (fahrenheit >= 95 && fahrenheit <= 97) {
      return 1;
    } else if (fahrenheit >= 97.1 && fahrenheit <= 100) {
      return 0;
    } else if (fahrenheit >= 100.1 && fahrenheit <= 101.3) {
      return 1;
    } else if (fahrenheit > 101.3) {
      return 2;
    }
  }

  const calculateMews = (bp, temp, pulse, rr) => {
    var mewsVal;

    if (
      Number(bp) > 0 &&
      Number(temp) > 0 &&
      Number(pulse) > 0 &&
      Number(rr) > 0
    ) {
      let bpValue = SystolicBloodPressure(bp);

      let pulseValue = PulseCalculation(pulse);

      let tempValue = Temperature(temp);

      let rrValue = RespiratoryRate(rr);

      mewsVal =
        Number(bpValue) +
        Number(pulseValue) +
        Number(tempValue) +
        Number(rrValue);
    }

    return mewsVal;
  };

  let finalMews;
  useEffect(() => {
    finalMews = calculateMews(bp, temp, pulse, rr);

    let mewsWithAvpu = Number(finalMews) + Number(Avpu);

    if (mewsWithAvpu >= 5) {
      setValue("mewsAlert", "Please Call Doctor For Consultation");
    } else if (mewsWithAvpu < 5) {
      setValue("mewsAlert", "No Need to Call Doctor For Consultation");
    }

    setMewsScore(Number(mewsWithAvpu));
  }, [bp, temp, pulse, rr, Avpu]);

  return (
    <div>
      <div className="grid grid-cols-5 gap-3 my-3">
        <div className="flex md:col-span-5  lg:col-span-3">
          <p className="mr-3 font-semibold">
            Trauma Patient(For Casulty Only) :
          </p>
          <RadioField
            label=""
            name="criteria"
            control={control}
            dataArray={isTrumaPatient}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-9 gap-3 mt-2">
        <div>
          <DatePickerFieldNew
            control={control}
            name="transferDate"
            label="Date"
            value={new Date()}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        {/* time */}
        <div>
          <Controller
            control={control}
            name="transferTime"
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Time"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.transferTime}
                      helperText={errors.transferTime?.message}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                      }}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </div>

        <div className="col-span-2">
          <SearchDropdown
            control={control}
            // error={errors.transferAuthorisedBy}
            name="transferAuthorisedBy"
            label="Transfer Authorised By"
            dataArray={emoloyeeName}
            placeholder="Transfer Authorised By"
            isClearable={false}
            isSearchable={true}
            searchIcon={false}
            handleInputChange={(e) => {
              getEmoloyeeName(e);
            }}
          />
        </div>

        <div className="col-span-3">
          <InputField
            name="knownAllergies"
            label="Known Allergies"
            variant="outlined"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        <div className="md:col-span-2  lg:col-span-2  justify-end">
          <DropdownField
            control={control}
            error={errors.rmoName}
            name="rmoName"
            label="RMO Name"
            dataArray={emoloyeeName}
            placeholder="RMO Name"
            isClearable={true}
            isSearchable={true}
            searchIcon={false}
          />
        </div>
      </div>

      <hr className="border my-4 col-span-2 divide-x-8 bg-slate-300 border-slate-300" />

      {/* Transfer from */}
      <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-3 my-4">
        <div className="flex md:justify-start  md:col-span-3 lg:col-span-1 ">
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Transfer From</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
            </div>
          </div>
        </div>

        {/* ///Department /// */}
        <div>
          <CreateableSelect
            control={control}
            // error={errors.departmentFrom}
            name="departmentFrom"
            label="Department"
            dataArray={department}
            handleInputChange={(e) => {
              setDepartmentSearchString(e);
            }}
            isSearchable={true}
            placeholder="Department"
            isClearable={true}
            searchIcon={true}
          />
        </div>

        {/* //Ward */}
        <div className="">
          <DropdownField
            control={control}
            error={errors.ward}
            name="wardFrom"
            label="Ward"
            dataArray={ward}
            isSearchable={false}
            // isClearable={true}
            placeholder="Ward" //getBeds
            inputRef={{
              ...register("wardFrom", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    getBedsFrom(e.target.value.id, 2);
                    setValue("bedFrom", null);
                  }
                },
              }),
            }}
          />
        </div>

        {/* //bed */}
        <div className="">
          <DropdownField
            control={control}
            error={errors.ward}
            name="bedFrom"
            label="Bed Number"
            dataArray={bedsFrom}
            isSearchable={false}
            // isClearable={true}
            placeholder="Bed Number"
          />
        </div>
      </div>

      {/* Transfer To */}
      <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-3 my-4">
        <div className="flex md:justify-start  col-span-3 lg:col-span-1 ">
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Transfer To</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
            </div>
          </div>
        </div>

        {/* ///Department /// */}
        <div>
          <CreateableSelect
            control={control}
            // error={errors.departmentFrom}
            name="departmentTo"
            label="Department"
            dataArray={department}
            handleInputChange={(e) => {
              setDepartmentSearchString(e);
            }}
            isSearchable={true}
            placeholder="Department"
            isClearable={true}
            searchIcon={true}
          />
        </div>

        {/* //Ward */}
        <div className="">
          <DropdownField
            control={control}
            error={errors.ward}
            name="wardTo"
            label="Ward"
            dataArray={ward}
            isSearchable={false}
            // isClearable={true}
            placeholder="Ward"
            inputRef={{
              ...register("wardTo", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    getBedsTo(e.target.value.id, 0);
                    setValue("bedTo", null);
                  }
                },
              }),
            }}
          />
        </div>

        {/* //bed */}
        <div className="">
          <DropdownField
            control={control}
            error={errors.ward}
            name="bedTo"
            label="Bed Number"
            dataArray={bedsTo}
            isSearchable={false}
            // isClearable={true}
            placeholder="Bed Number"
          />
        </div>
      </div>

      <hr className="border my-4 col-span-2 divide-x-8 bg-slate-300 border-slate-300" />

      <div className="grid grid-cols-4 gap-3 mt-2">
        <div className="col-span-2 ">
          <InputArea
            name="dignosisAtTheTimeOfTransfer"
            label="Dignosis At The Time Of Transfer"
            placeholder="Dignosis At The Time Of Transfer"
            control={control}
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            name="reasonForTransfer"
            label="Reason For Transfer"
            placeholder="Reason For Transfer"
            control={control}
          />
        </div>

        <div className="col-span-4 grid md:grid-cols-4 lg:grid-cols-10 gap-3 items-center">
          <div className="">
            <InputField
              name="temp"
              label="Temperature"
              variant="outlined"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="bp"
              label="B/P"
              variant="outlined"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="pulse"
              label="Pulse"
              variant="outlined"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="respiration"
              label="Respiration"
              variant="outlined"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          {/* mews */}
          <div className="flex flex-wrap items-center gap-2 w-full md:col-span-4 lg:col-span-6   text-sm py-1">
            <span className="  w-20 ">Mews *</span>
            <div className="flex items-center gap-3">
              <span>:</span>
              <span className="">
                <RadioField
                  label=""
                  name="mewsRadio"
                  control={control}
                  dataArray={MewsRadio}
                />
              </span>
              <span className="text-gray-700 font-normal  w-24">
                <InputField
                  name="mews"
                  label="Mews"
                  variant="outlined"
                  control={control}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
            <div className=" flex gap-2 items-center">
              {mewsScore !== undefined && mewsScore > 0 ? (
                mewsScore >= 5 ? (
                  <>
                    <p> Alert :</p>
                    <p className="text-red-600">
                      Please Call Doctor For Consultation
                    </p>
                  </>
                ) : (
                  <>
                    <p> Alert :</p>
                    <p className="text-green-600">
                      No Need to Call Doctor For Consultation
                    </p>
                  </>
                )
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-span-4 ">
          <InputArea
            name="noteOnSurgeryProcedures"
            label="Brief Note On Surgery/Procedure"
            placeholder="Brief Note On Surgery/Procedure"
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientTransferDetails;

