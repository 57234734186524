import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Modal, TablePagination, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CancelOnIcon,
  EyeOnIcon,
  ReceivePtOffIcon,
  ReceivePtOnIcon,
} from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import MLCModalEdit from "../../../../common/components/mlcDetails/MLCModalEdit";
import BarCodePrint from "../../../../common/components/printBarcode/BarCodePrint";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchEmergencyMlcPrint } from "../../../../emergency/services/emergencyservice/EmergencyServices";
import {
  fetchAdmissionBarcode,
  fetchAdmissionSticker,
  fetchPharmacyOTMedicine,
  fetchPrintAdmissionForm,
  sendToRadar,
} from "../../../services/admissionServices/AdmissionServices";
import CancelAdmissionConfirmation from "../commonModal/CancelAdmissionConfirmation";
import EditAdmissionModal from "./EditAdmissionModal";
import InitiatDischargeMessage from "./InitiatDischargeModal";
import MediclaimAmountModal from "./MediclaimAmountModal";
import ReceivePatientModal from "./ReceivePatientModal";
import ViewPatientDetailsModal from "./ViewPatientDetailsModal";
import MlcInjuryCertificatesModal from "./MlcInjuryCertificatesModal";
import { routeToPrinterDevice } from "../../../../commonServices/barCodeServices/BarCodeServices";
import { blobToBase64 } from "../../../../common/components/printModal/BlobToB64";
import NumberOfCopiesModal from "../../../../common/components/printModal/NumberOfCopiesModal";

function Row(props) {
  let navigate = useNavigate();

  const {
    row,
    headers,
    handleList,
    setReplaceSelectedRow,
    updateRowOfAdmTbl,
    CancelAdmissionDeleteRow,
    setPage,
    selectedRowData,
    setSelectedRowData,
    userActions,
    location,
    containstatus,
    containsMlc,
  } = props;

  const defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  const [openDischargeModal, setOpenDischargeModal] = useState(false);
  const [privilege, setPrivilege] = useState();
  const [functionalityId, setFunctionalityId] = useState(null);
  //
  const [isAdmPrint, setIsAdmPrint] = useState(null);
  const [isMlcPrint, setIsMlcPrint] = useState(null);
  const [printCount, setPrintCount] = React.useState("");
  const [printType, setPrintType] = React.useState("");

  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  //Mediclaim Amt Modal modal
  const [openMediclaimAmtModal, setOpenMediclaimAmtModal] = useState(false);
  const handleOpenMediclaimAmtModal = () => setOpenMediclaimAmtModal(true);
  const handleCloseMediclaimAmtModal = () => setOpenMediclaimAmtModal(false);

  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [barcodeResponse, setBarcodeResponse] = useState(null);
  //Receive modal
  const [openReceivePatientModal, setOpenReceivePatientModal] = useState(false);
  const handleOpenReceivePatientModal = () => setOpenReceivePatientModal(true);
  const handleCloseReceivePatientModal = () =>
    setOpenReceivePatientModal(false);

  //injury-mlc certificate
  const [openCertificatesModal, setOpenCertificatesModal] = useState(false);
  const handleOpenCertificatesModal = () => setOpenCertificatesModal(true);
  const handleCloseCertificatesModal = () => setOpenCertificatesModal(false);

  //mlc modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [selectedMlcData, setSelectedMlcData] = React.useState(false);

  //prints print
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  // const handleClosePrintModal = () => {
  //   setOpenPrintModal(false);
  // };
  const handleClosePrintModal = () => {
    if (isAdmPrint === null && isMlcPrint === null && printType === "") {
      handleOpenPrintModal(row, "otMedicines");
      setPrintCount("otMedicines");
      setOpenPrintModal(true);
      setIsMlcPrint(null);
    }

    setIsAdmPrint(null);
    setPrintType("");

    // print count is where i store which print is in processs
    // navigate if the "otMedicines" is closed
    if (printCount === "otMedicines") {
      setIsAdmPrint(null);
      setOpenPrintModal(false);
      setPrintCount("");
    }
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(barcodeResponse);
    console.log("Base64 String:", defaultPrinters);

    const obj = [
      {
        printerName: defaultPrinters?.barCodePrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "Barcode",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setBarcodeResponse(null);
    });
  };

  const handleOpenBarcodePrintModal = (row, type) => {
    console.log("barcode", defaultPrinters);

    if (type === "barcode") {
      fetchAdmissionBarcode(row.AdmissionId).then((response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setBarcodeResponse(response);
        } else {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });
    }

    if (type === "sticker") {
      fetchAdmissionSticker(row.AdmissionId).then((response) => {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      });
    }
  };

  const handleOpenPrintModal = (row, type) => {
    let id = row.AdmissionId > 0 ? row.AdmissionId : row.EmergencyPatientId;
    let formId = row.AdmissionId > 0 ? 1 : 2;
    setIsMlcPrint(null);

    // admissionId,formId
    type === "mlc" &&
      fetchEmergencyMlcPrint(id, formId).then((response) => {
        setUrlforPrint(response);
        setIsMlcPrint(true);
      });
    // type !== "mlc" && setIsMlcPrint(null);

    type === "admForm" &&
      fetchPrintAdmissionForm(row.AdmissionId).then((response) => {
        setUrlforPrint(response);
      });

    type === "otMedicines" &&
      isMlcPrint === null &&
      fetchPharmacyOTMedicine(row.AdmissionId).then((response) => {
        setUrlforPrint(response);
      });

    setOpenPrintModal(true);
  };
  ////

  //initiate discharge modal
  const [openInitiateDischargeModal, setOpenInitiateDischargeModal] =
    React.useState(false);
  const handleOpenInitiateDischargeModal = () =>
    setOpenInitiateDischargeModal(true);
  const handleCloseInitiateDischargeModal = () =>
    setOpenInitiateDischargeModal(false);

  //
  const [openViewPatientDetailsModal, setOpenViewPatientDetailsModal] =
    React.useState(false);
  const handleOpenViewPatientDetailsModal = () =>
    setOpenViewPatientDetailsModal(true);
  const handleCloseViewPatientDetailsModal = () =>
    setOpenViewPatientDetailsModal(false);

  //Cancel Adm
  const [openCancelAdmModal, setOpenCancelAdmModal] = React.useState(false);
  const handleOpenCancelAdmModal = () => setOpenCancelAdmModal(true);
  const handleCloseCancelAdmModal = () => setOpenCancelAdmModal(false);

  //Edit Adm
  const [openEditAdmModal, setOpenEditAdmModal] = React.useState(false);
  const handleOpenEditAdmModal = () => setOpenEditAdmModal(true);
  const handleCloseEditAdmModal = () => setOpenEditAdmModal(false);

  //function for print mlc

  const params = {
    PatientName: row.PatientName,
    PatientId: row.PatientId,
    AdmissionId: row.AdmissionId,
    DisableSearch: true,
    IPDNo: row.AdmNo,
    Gender: row.Gender,
    Age: row.AgeYear,
    DOB: row.Age,
    Doctor: row.AdmittedUnder,
    Bed: row.BedNo,
    Ward: row.Ward,
    ADMDate: row.AdmDate,
    BedCategory: row.BedCategory,
    Unit: row.Unit,
    floorId: row.floorId,
    TariffId: row?.TariffId,
  };

  const handleCharges = (rowDetails, actions) => {
    let billParams = {
      admissionId: rowDetails.AdmissionId,
      bedCategory: rowDetails.bcategoryId,
      tariff: rowDetails.tariff,
      patientId: rowDetails.PatientId,
    };
    navigate("/ipd/charges", {
      state: { billParams, menuId: actions.menuId, from: "admission" },
    });
  };

  const handleSendToRadar = (row) => {
    setOpenConfirmationModal(false);
    const sendToRadarObj = {
      admissionId: row.AdmissionId,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    setOpenBackdrop(true);

    sendToRadar(sendToRadarObj)
      .then((response) => response.data)
      .then((res) => {
        setSelectedRowData(null);
        setOpenBackdrop(false);
        successAlert(res.message);
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            marginLeft: "1rem",
            padding: "0px",
          }}
        >
          <div className="flex ml-1 items-center gap-2">
            <>
              {userActions.map((actions, i) => {
                return (
                  <>
                    {actions.action === "Edit" && actions.isAction && (
                      <>
                        {props.row.IsCancelled !== true ? (
                          <Tooltip title="Edit">
                            <DriveFileRenameOutlineIcon
                              className={
                                !row.Discharged
                                  ? `text-customGray`
                                  : `text-gray-300`
                              }
                              onClick={() => {
                                !row.Discharged &&
                                  navigate(
                                    "/admission/PatientAdmissionDetails",
                                    {
                                      state: {
                                        row: row,
                                        menuId: actions.menuId,
                                        previousMenuId: location?.state?.menuId,
                                        isEdit: true,
                                        privilege: actions.action,
                                      },
                                    }
                                  );
                              }}
                            />
                          </Tooltip>
                        ) : null}
                      </>
                    )}

                    {actions.action === "Document Edit" && actions.isAction && (
                      <>
                        {props.row.IsCancelled !== true ? (
                          <Tooltip title="Edit">
                            <DriveFileRenameOutlineIcon
                              className={
                                !row.Discharged
                                  ? `text-customGray`
                                  : `text-gray-300`
                              }
                              onClick={() => {
                                !row.Discharged && handleOpenEditAdmModal();
                                setPrivilege(actions.action);
                              }}
                            />
                          </Tooltip>
                        ) : null}
                      </>
                    )}

                    {actions.action === "Receive Patient" &&
                      actions.isAction && (
                        <>
                          {!props.row.isrecievebed &&
                          props.row.IsCancelled !== true ? (
                            <ReceivePtOnIcon
                              title="Receive Patient"
                              onClick={() => {
                                if (props.row.isTransferToWard === 1) {
                                  setOpenReceivePatientModal(true);
                                  props.setSelectedRowData(props.row);
                                } else if (props.row.isTransferToWard === 0) {
                                  warningAlert(
                                    "Please Initiate Transfer To Ward"
                                  );
                                }
                                setPrivilege(actions.action);
                                setFunctionalityId(actions.functionalityId);
                              }}
                            />
                          ) : (
                            <ReceivePtOffIcon />
                          )}
                        </>
                      )}

                    {actions.action === "View" && actions.isAction ? (
                      <>
                        <EyeOnIcon
                          title="View Admission"
                          onClick={(e) => {
                            setOpenViewPatientDetailsModal(true);
                          }}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {actions.action === "Cancel" && actions.isAction && (
                      <>
                        {props.row.IsCancelled !== true ? (
                          <CancelOnIcon
                            title="Cancel Admission"
                            onClick={() => {
                              props.setSelectedRowData(props.row);
                              setPrivilege(actions.action);
                              handleOpenCancelAdmModal();
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  </>
                );
              })}

              <Tooltip title="Print Admission Form">
                <LocalPrintshopIcon
                  sx={{ color: "#4B5563" }}
                  onClick={() => {
                    handleOpenPrintModal(row, "admForm");
                  }}
                />
              </Tooltip>

              <div className="ml-0.5">
                <Tooltip>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      props.onClick(props.open ? "" : row.PatientId)
                    }
                  >
                    {props.open ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </>
          </div>
        </TableCell>
        {containsMlc && (
          <TableCell>
            {row.MLC === true &&
            userActions.some(
              (item) =>
                (item.action === "MLCEdit" || item.action === "MLCView") &&
                item.isAction === true
            ) ? (
              <>
                <div className="flex gap-1">
                  <Tooltip title="Edit MLC">
                    <DriveFileRenameOutlineIcon
                      // sx={{ color: "#1e3a8a" }}
                      className={
                        !row.IsCancel ? `text-customGray` : `text-gray-300`
                      }
                      onClick={(e) => {
                        handleOpenModal();
                        setSelectedMlcData(row);
                        setPrivilege("MLCEdit");
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Print MLC">
                    <LocalPrintshopIcon
                      className={
                        row.MLCNo !== null
                          ? `text-customGray`
                          : `text-[#d7d7d7]`
                      }
                      onClick={() => {
                        if (row.MLCNo !== null) {
                          handleOpenPrintModal(row, "mlc");
                          setIsMlcPrint(true);
                        }
                      }}
                    />
                  </Tooltip>
                </div>
              </>
            ) : row.MLC &&
              userActions.some(
                (item) => item.action === "MLCView" && item.isAction === true
              ) ? (
              <>
                <div className="flex gap-1">
                  <EyeOnIcon
                    title="View MLC"
                    onClick={(e) => {
                      handleOpenModal();
                      setSelectedMlcData(row);
                      setPrivilege("MLCView");
                    }}
                  />
                  <Tooltip title="Print MLC">
                    <LocalPrintshopIcon
                      className={
                        row.MLCNo !== null
                          ? `text-customGray`
                          : `text-[#d7d7d7]`
                      }
                      onClick={() => {
                        if (row.MLCNo !== null) {
                          handleOpenPrintModal(row, "mlc");
                          setIsMlcPrint(true);
                        }
                      }}
                    />
                  </Tooltip>
                </div>
              </>
            ) : (
              <div className="ml-2">No</div>
            )}
          </TableCell>
        )}
        {containstatus && (
          <TableCell>
            <p
              className={`${
                row?.Status?.toLowerCase() === "open"
                  ? `whitespace-nowrap text-red-500 font-semibold`
                  : row?.Status?.toLowerCase() === "discharged"
                  ? `whitespace-nowrap text-violet-500 font-semibold`
                  : row?.Status?.toLowerCase() === "discharge initiated"
                  ? `whitespace-nowrap text-lime-400 font-semibold`
                  : row?.Status?.toLowerCase() === "mlc"
                  ? `whitespace-nowrap text-orange-900 font-semibold`
                  : row?.Status === "Tentative Discharged"
                  ? `whitespace-nowrap text-orange-300 font-semibold`
                  : row?.Status?.toLowerCase() === "admitted"
                  ? `whitespace-nowrap text-green-900 font-semibold`
                  : `whitespace-nowrap text-sky-500 font-semibold`
              }`}
            >
              {row.Status}
            </p>
          </TableCell>
        )}

        <TableCell
          className="whitespace-nowrap "
          sx={{
            color: row.AllowToGO
              ? "#059669"
              : row.IsPPN
              ? "#e879f9"
              : row.CompanyId > 1
              ? "#6635F3"
              : row?.["Deposit Amount"] < row.BillAmount || 0
              ? "#FF5733"
              : "#000000",
          }}
        >
          {row["Patient Name"]}
        </TableCell>

        {headers &&
          headers.map((header, i) => (
            <>
              <TableCell key={i} className="w-auto whitespace-nowrap">
                {row[header]}
              </TableCell>
            </>
          ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
          <Collapse
            in={props.open}
            timeout={{ enter: 200, exit: 100 }}
            unmountOnExit
            easing={{ enter: "linear", exit: "linear" }}
          >
            <Box sx={{ margin: 1 }}>
              <div className="flex pt-12 lg:pt-14 ">
                <div className="relative -mt-14 mx-10  flex flex-wrap">
                  {row.Status !== "open" ? (
                    <>
                      {userActions.map((actions, i) => (
                        <>
                          {actions.isAction === true && (
                            <>
                              <div className="flex cursor-pointer">
                                {actions.action === "Bill" && (
                                  <CommonButton
                                    label="Bill"
                                    onClick={() => {
                                      navigate("/ipd/bill", {
                                        state: {
                                          row,
                                          menuId: actions.menuId,
                                        },
                                      });
                                    }}
                                    className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                  />
                                )}
                                {actions.action === "MLC_Injury Certificate" &&
                                  row.MLCNo && (
                                    <CommonButton
                                      label="MLC/Injury Certificate"
                                      onClick={() => {
                                        handleOpenCertificatesModal();
                                        setSelectedMlcData(row);
                                      }}
                                      className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                    />
                                  )}

                                {actions.action === "Barcode" && (
                                  <>
                                    <CommonButton
                                      label="Barcode"
                                      className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      onClick={() => {
                                        handleOpenBarcodePrintModal(
                                          row,
                                          "barcode"
                                        );
                                        setPrintType("barcode");
                                      }}
                                    />

                                    <CommonButton
                                      label="Sticker"
                                      className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      onClick={() => {
                                        handleOpenBarcodePrintModal(
                                          row,
                                          "sticker"
                                        );
                                        setPrintType("sticker");
                                      }}
                                    />
                                  </>
                                )}

                                {props.row.isrecievebed && (
                                  <>
                                    {actions.action === "Send To Radar" && (
                                      <CommonButton
                                        label="Send To Radar"
                                        onClick={() => {
                                          setOpenConfirmationModal(true);
                                          // handleSendToRadar(row);
                                          setSelectedRowData(row);
                                          setPrivilege(actions.action);
                                        }}
                                        className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      />
                                    )}
                                    {actions.action ===
                                      "Discharge From Radar" && (
                                      <CommonButton
                                        label="Discharge From Radar"
                                        onClick={() => {
                                          // setOpenConfirmationModal(true);
                                          // // handleSendToRadar(row);
                                          // setSelectedRowData(row);
                                          // setPrivilege(actions.action);
                                        }}
                                        className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      />
                                    )}

                                    {actions.action === "Transfer Bed" && (
                                      <CommonButton
                                        label=" Transfer Bed"
                                        onClick={() =>
                                          navigate("/ipd/bedTransfer", {
                                            state: {
                                              params,
                                              menuId: actions.menuId,
                                            },
                                          })
                                        }
                                        className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      />
                                    )}

                                    {actions.action === "Charges" && (
                                      <CommonButton
                                        label=" Charges"
                                        onClick={() => {
                                          handleCharges(row, actions);
                                        }}
                                        className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      />
                                    )}

                                    {actions.action === "Discharge Initiate" &&
                                      row.isDischargeInitiated === 0 && (
                                        <CommonButton
                                          label="Discharge Adv.By"
                                          onClick={() => {
                                            handleOpenInitiateDischargeModal();
                                            setPrivilege(actions.action);
                                          }}
                                          className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                        />
                                      )}

                                    {actions.action === "Disc. Summary" &&
                                      row.isDischargeInitiated === 1 && (
                                        <CommonButton
                                          label="Disc. Summary"
                                          onClick={() => {
                                            navigate("/ipd/dischargeSummary", {
                                              state: {
                                                params,
                                                menuId: actions.menuId,
                                              },
                                            });
                                          }}
                                          className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                        />
                                      )}

                                    {actions.action === "Clinical Chart" && (
                                      <CommonButton
                                        label="Clinical Chart"
                                        onClick={() => {
                                          navigate(
                                            "/nursing/clinicalCareChart",
                                            {
                                              state: {
                                                params,
                                                menuId: actions.menuId,
                                              },
                                            }
                                          );
                                        }}
                                        className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                      />
                                    )}

                                    {actions.action === "Mediclaim" &&
                                      row.CompanyId > 1 && (
                                        <CommonButton
                                          label="Mediclaim"
                                          onClick={() => {
                                            handleOpenMediclaimAmtModal();
                                            setPrivilege(actions.action);
                                            setFunctionalityId(
                                              actions.functionalityId
                                            );
                                          }}
                                          className="bg-transparent mx-1 my-2 hover:bg-slate-500 text-black hover:text-white h-8 px-4 border border-black hover:border-transparent rounded"
                                        />
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* //// */}
      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {/* //Receive Patient Modal// */}
      {openReceivePatientModal ? (
        <ReceivePatientModal
          open={openReceivePatientModal}
          setOpen={setOpenReceivePatientModal}
          handleOpen={handleOpenReceivePatientModal}
          handleClose={handleCloseReceivePatientModal}
          selectedRow={row}
          handleList={handleList}
          setReplaceSelectedRow={setReplaceSelectedRow}
          updateRowOfAdmTbl={updateRowOfAdmTbl}
          menuId={location?.state?.menuId}
          privilege={privilege}
          functionalityId={functionalityId}
        />
      ) : null}

      {openCertificatesModal ? (
        <MlcInjuryCertificatesModal
          open={openCertificatesModal}
          setOpen={setOpenCertificatesModal}
          handleOpen={handleOpenCertificatesModal}
          handleClose={handleCloseCertificatesModal}
          //

          selectedMlcData={selectedMlcData}
          populateTable={handleList}
          //
          privilege={privilege}
          menuId={location?.state?.menuId}
        />
      ) : null}

      {/* mlc Modal */}
      {openModal ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          registered={selectedMlcData?.Date}
          //
          admissionId={selectedMlcData?.AdmissionId}
          emergencyId={selectedMlcData?.emergencyId}
          opdIpd={1}
          ipdEmergency={selectedMlcData.AdmissionId !== 0 ? 1 : 2}
          //
          isEdit={true}
          isFromForm={false}
          selectedMlcData={selectedMlcData}
          MLCNo={selectedMlcData?.MLCNo}
          populateTable={handleList}
          //
          privilege={privilege}
          setPrivilege={setPrivilege}
          menuId={location?.state?.menuId}
          //
          patientId={selectedMlcData?.PatientId}
          isEmergency={false}
          //
          isFromAdm={selectedMlcData?.isDischargeInitiated === 1 ? true : false}
        />
      ) : null}

      {/* Print mlc Modal */}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* openInitiateDischargeModal */}
      {openInitiateDischargeModal ? (
        <InitiatDischargeMessage
          open={openInitiateDischargeModal}
          setOpen={setOpenInitiateDischargeModal}
          handleOpen={handleOpenInitiateDischargeModal}
          handleClose={handleCloseInitiateDischargeModal}
          selectedRowData={selectedRowData}
          handleList={handleList}
          setPage={setPage}
          updateRowOfAdmTbl={updateRowOfAdmTbl}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {/*View Patient Info*/}
      {openViewPatientDetailsModal ? (
        <ViewPatientDetailsModal
          open={openViewPatientDetailsModal}
          setOpen={setOpenViewPatientDetailsModal}
          handleOpen={handleOpenViewPatientDetailsModal}
          handleClose={handleCloseViewPatientDetailsModal}
          selectedRow={row}
        />
      ) : null}

      {/* Cancel Admission Confirmation Modal*/}

      {openCancelAdmModal ? (
        <CancelAdmissionConfirmation
          open={openCancelAdmModal}
          setOpen={setOpenCancelAdmModal}
          handleOpen={handleOpenCancelAdmModal}
          handleClose={handleCloseCancelAdmModal}
          selectedRow={row}
          CancelAdmissionDeleteRow={CancelAdmissionDeleteRow}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {/* Edit Admission Modal*/}

      {openEditAdmModal ? (
        <EditAdmissionModal
          open={openEditAdmModal}
          setOpen={setOpenEditAdmModal}
          handleOpen={handleOpenEditAdmModal}
          handleClose={handleCloseEditAdmModal}
          selectedRow={row}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {/* Mediclaim Amount Modal*/}

      {openMediclaimAmtModal ? (
        <MediclaimAmountModal
          open={openMediclaimAmtModal}
          setOpen={setOpenMediclaimAmtModal}
          handleOpen={handleOpenMediclaimAmtModal}
          handleClose={handleCloseMediclaimAmtModal}
          selectedRow={row}
          handleList={handleList}
          menuId={location?.state?.menuId}
          privilege={privilege}
          functionalityId={functionalityId}
        />
      ) : null}

      {/* //Dicharge Modal// */}
      <Modal
        open={openDischargeModal}
        onClose={() => {
          setOpenDischargeModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        ></Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={() => handleSendToRadar(selectedRowData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}

const AdmissionListCollapseTable = (props) => {
  const location = useLocation();

  const {
    dataResult,
    setDataResult,
    setData,
    page,
    setPage,
    rowsPerPage,
    count,
    handleList,
    userActions,
    populateTable,
  } = props;

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  //
  const [openRow, setOpenRow] = React.useState("");
  //
  const [selectedRowData, setSelectedRowData] = React.useState();
  //
  const [replaceSelectedRow, setReplaceSelectedRow] = React.useState();
  //
  const [isLoading, setIsLoading] = React.useState(false);
  const [pagesPassed, setPagesPassed] = React.useState(0);
  const [newPageVal, setNewPageVal] = React.useState(null);

  //set descending sort order
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  //set sort desc
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const tableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  //////

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };
  //set rows object to table
  let allHeaders = Object.keys(dataResult[0]);
  const headers = removeHeaders(allHeaders, [
    "id",
    "floorId",
    "Address",
    "AdmNo",
    "AdmissionId",
    "AdmittedUnder",
    "BedCategory",
    "BedId",
    "Block",
    "ClassId",
    "ContactNo",
    "CreditLimit",
    "DepositAmt",
    "DischargeId",
    "Discharged",
    "Floor",
    "InvcreditLimit",
    "IsCancelled",
    "IsClosed",
    "KinMobileNo",
    "Mediclaim_Amount",
    "MobileNo",
    "PatientId",
    "PresentOnBed",
    "SponsorType",
    "TariffId",
    "Unit",
    "Ward",
    "WardId",
    "WardCode",
    "admitted_count",
    "istentative",
    "tentativedate",
    "AgeYear",
    "GenderId",
    "Gender",
    "ReportingTime",
    "PoliceStation",
    "ReportingDate",
    "AuthorityName",
    "AuthorutyBuckleNo",
    "MLCNo",
    "mlcTypeId",
    "mlcType",
    "broughtBy",
    "isDeath",
    "mlcDetails",
    "BodyHandOverBuckelNo",
    "BodyHandOverDate",
    "BodyHandOverTime",
    "BodyHandOverPoliceStation",
    "cmoHandingOverBody",
    "isrecievebed",
    "isTransferToWard",
    "ReferenceDoctorMaster",
    "RefDocName",
    "isDischargeInitiated",
    "MLC",
    "Status",
    "CompanyId",
    "Patient Name",
    "EstimateAmount",
    "Refering Remark",
    "Bed",
    "PatientCategoryId",
    "PatientSourceId",
    "UnitId",
    "DepartmentId",
    "emergencyId",
  ]);

  const handlePageChange = (event, newPage) => {
    setNewPageVal(newPage);
    if (newPage > page) {
      setPage(newPage);
    } else if (newPage < page) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    if (newPageVal > pagesPassed) {
      setPagesPassed(newPageVal);
      setIsLoading(true);
      populateTable(true);
    }
  }, [newPageVal]);

  useEffect(() => {
    if (count <= rowsPerPage || dataResult?.length <= rowsPerPage) {
      setPagesPassed(0);
      setNewPageVal(0);
    }
  }, [count, rowsPerPage, dataResult]);

  /////////////////update row //
  const updateRowOfAdmTbl = (newData) => {
    ////to find index of obj from dataResult arrey
    const foundindex = dataResult
      .map((i) => i.MRNo)
      .indexOf(selectedRowData.MRNo);

    ////update row
    const newRow = dataResult.map((obj, id) => {
      if (id === foundindex) {
        return {
          ...obj,
          isrecievebed: newData.isrecievebed,
          isDischargeInitiated: newData.isDischargeInitiated,
          Status: newData.Status,
        };
      }

      return obj;
    });
    setDataResult(newRow);
  };

  const CancelAdmissionDeleteRow = () => {
    ////to find index of obj from dataResult arrey
    const foundindex = dataResult
      .map((i) => i.MRNo)
      .indexOf(selectedRowData.MRNo);

    let newTemplateData = [...dataResult];
    newTemplateData.splice(foundindex, 1);
    setDataResult(newTemplateData);
  };

  let containstatus = dataResult.some((item) => item.Status);
  let containsMlc = dataResult.some((item) => item.MLC);

  return (
    <div className="grid w-auto mx-auto mr-7 ">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%" }}>
          <div className="flex items-center w-full">
            <div className=" text-sm pl-2 w-full flex gap-2 items-center">
              <div className="flex gap-2 items-center">
                <div className="h-4 w-4 rounded bg-[#6635F3]"></div>
                <div className="">Company Patient</div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="h-4 w-4 rounded bg-[#FF5733]"></div>
                <div className="">Bill amount exceeds deposit amount</div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="h-4 w-4 rounded bg-[#059669]"></div>
                <div className="">Allowed To Go</div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="h-4 w-4 rounded bg-[#e879f9]"></div>
                <div className="">PPN Patient</div>
              </div>
            </div>
            <div className="flex justify-end  w-60">
              <TablePagination
                rowsPerPageOptions={[]}
                labelRowsPerPage=""
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                SelectProps={{
                  disabled: isLoading,
                }}
                backIconButtonProps={
                  isLoading
                    ? {
                        disabled: isLoading,
                      }
                    : undefined
                }
                nextIconButtonProps={
                  isLoading
                    ? {
                        disabled: isLoading,
                      }
                    : undefined
                }
              />
            </div>
          </div>

          <TableContainer
            component={Paper}
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#ebebeb",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#7393b3",
                borderRadius: 4,
              },
            }}
            className="rounded h-80 lg:96 2xl:h-[600px] "
          >
            <Table aria-label="collapsible table" size="small" stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell>
                    <span className="text-gray-600 font-bold">Actions</span>
                  </TableCell>
                  {containsMlc && (
                    <TableCell>
                      <span className="text-gray-600 font-bold">MLC</span>
                    </TableCell>
                  )}
                  {containstatus && (
                    <TableCell>
                      <span className="text-gray-600 font-bold">Status</span>
                    </TableCell>
                  )}

                  <TableCell>
                    <span className="text-gray-600 font-bold">
                      Patient Name
                    </span>
                  </TableCell>

                  {/* heading of table */}
                  {headers.map((header, index) => (
                    <TableCell
                      sortDirection={orderBy === header ? order : false}
                      className="whitespace-nowrap"
                      key={index}
                    >
                      <TableSortLabel
                        active={false} //arrow for sorting
                        direction={orderBy === header ? order : "asc"}
                        onClick={createSortHandler(header)}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                        {orderBy === header ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {(page > 0 || dataResult.length > rowsPerPage
                    ? tableSort(
                        dataResult,
                        getComparator(order, orderBy)
                      ).slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : tableSort(dataResult, getComparator(order, orderBy))
                  ).map((row, index) => {
                    isLoading && setIsLoading(false);
                    return (
                      <Row
                        dataArray={dataResult}
                        headers={headers}
                        allHeaders={allHeaders}
                        key={row.id}
                        row={row}
                        setData={setData}
                        open={row.PatientId == openRow}
                        onClick={(id, e) => {
                          setOpenRow(id);
                          setSelectedRowData(row);
                        }}
                        selectedRowData={selectedRowData}
                        setSelectedRowData={setSelectedRowData}
                        replaceSelectedRow={replaceSelectedRow}
                        handleList={handleList}
                        setReplaceSelectedRow={setReplaceSelectedRow}
                        updateRowOfAdmTbl={updateRowOfAdmTbl}
                        CancelAdmissionDeleteRow={CancelAdmissionDeleteRow}
                        setPage={setPage}
                        //
                        userActions={userActions}
                        location={location}
                        containsMlc={containsMlc}
                        containstatus={containstatus}
                      />
                    );
                  })}
                </>
              </TableBody>
            </Table>

            {isLoading && (
              <div className="flex justify-center text-gray-400 font-semibold my-5">
                <LoadingSpinner />
              </div>
            )}
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export default AdmissionListCollapseTable;
