import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import * as React from "react";

import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";

//main function
export default function CommonMasterTable(props) {

  const deleteAction = true;

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-48 2xl:h-60"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Actions
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Code
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Description
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Qty
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Rate
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center">
                        Amount
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Cons. Amount
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Net Amount
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Doctor Name
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.serviceData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "& th": {
                          paddingY: 0,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {deleteAction ? (
                          <Tooltip title="Delete Service">
                            <div
                              className="text-red-500 mr-3"
                              onClick={(e) => props.deleteService(index)}
                            >
                              {<DeleteOutlineOutlinedIcon />}
                            </div>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.ServiceCode}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.Description}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.Quantity}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.Rate}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.Amount}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {Math.round(row.ConcessionAmount)}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.NetAmount}
                      </TableCell>
                      <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                        {row.doctorName}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
