import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { useForm } from "react-hook-form";
import "./PriviligeMapping.css";
// import {
//   AddFilledIcon,
//   AddOutlineIcon,
//   CutIcon,
//   DeleteIcon,
//   EditIcon,
//   PasteIcon,
// } from "../../../../Common Components/assets/commonassets/CommonAssets";
import {
  AddFilledIcon,
  AddOutlineIcon,
  deleteOn,
  editOn,
} from "../../assets/icons/CustomIcons";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import AddSubMenuModal from "./AddSubMenuModal";
import AddNewPrivilegeToFunctinality from "./AddNewPrivilegeToFunctinality";
import { CustomAntSwitch } from "./CustomAntSwitch";
import {
  activDeactiveMenu,
  fetchAllFunctinalityPrivilege,
} from "../../UserManagement/Services/privilegetofunctionalityServices/PrivilegeToFunctionalityServices";
import { successAlert } from "../../common/components/Toasts/Toasts";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
const tooltipArr = [
  {
    privilege_id: 1,
    privilege: "Create",
  },
  {
    privilege: "View Appointments",
    privilege_id: 27,
  },
  {
    privilege: "ConfirmAppointment",
    privilege_id: 102,
  },
  {
    privilege: "CancelAppointment",
    privilege_id: 103,
  },
];
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="right" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    width: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #AAAAAA",
  },
}));

const TreeNode = (props) => {
  /************************ useFrom **********************************/

  const { setValue, watch, control } = useForm();
  /************************ state Varibles**********************************/

  const [isOpen, setIsOpen] = useState(false);
  const [openAddChild, setOpenAddChild] = React.useState(false);

  /************************ functions**********************************/
  //add confirmation
  const handleCloseAddChild = () => {
    setOpenAddChild(false);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  //############## setting child value as parent  ###############

  const handleValues = (array, value) => {
    for (let object of array) {
      object.isChecked = value;
      if (object?.["children"] && object?.children?.length > 0) {
        object.children = handleValues(object.children, value);
      } else if (object?.["permissions"] && object.permissions.length > 0) {
        object.permissions = handleValues(object.permissions, value);
      }
    }
    return array;
  };

  const handleRecursiveMapping = (array, node, value) => {
    let dataArray = [...array];
    let checkObject = {
      children:
        node?.["functionality"] && node.functionality !== ""
          ? node.functionality
          : "",
      permission:
        node?.["permission"] && node.permission !== "" ? node.permission : "",
      id: node.id,
      parentId: node.parentId > 0 ? node.parentId : null,
    };

    // seeking throught the array to find functionality or permission matching with the checked or unChecked object.
    for (let dataObject of dataArray) {
      let newDataObject = {
        functionality:
          dataObject?.["functionality"] && dataObject.functionality !== ""
            ? dataObject.functionality
            : "",
        permission:
          dataObject?.["permission"] && dataObject.permission !== ""
            ? dataObject.permission
            : "",
        id: dataObject.id,
        parentId: dataObject.parentId > 0 ? dataObject.parentId : null,
      };

      // if the object in the main array matches to the childObject then calling function to set value as per value got from checked/unChecked object
      if (
        newDataObject.functionality === checkObject.functionality &&
        newDataObject.permission === checkObject.permission &&
        newDataObject.id === checkObject.id &&
        newDataObject.parentId === checkObject.parentId
      ) {
        dataObject.isChecked = value;
        if (dataObject?.["children"] && dataObject?.children?.length > 0) {
          dataObject.children = handleValues(dataObject.children, value);
        } else if (
          dataObject?.["permissions"] &&
          dataObject.permissions.length > 0
        ) {
          dataObject.permissions = handleValues(dataObject.permissions, value);
        }
      } else if (
        // if the checked/unChecked object does not matches ...seeking further for the matching object.
        dataObject?.["children"] &&
        dataObject?.children?.length > 0
      ) {
        handleRecursiveMapping(dataObject.children, node, value);
      } else if (
        dataObject?.["permissions"] &&
        dataObject.permissions.length > 0
      ) {
        handleRecursiveMapping(dataObject.permissions, node, value);
      }
    }
    props.setData(array);
  };

  //############## setting child value as parent  ###############
  function updateNestedArray(array, key, newValue, compareObject, parent) {
    array.forEach((element) => {
      let newElement = {
        id: element.id,
        permission: element?.["permission"] ? element.permission : "",
        functionality: element?.["functionality"] ? element.functionality : "",
      };

      let newCompareObject = {
        id: compareObject.id,
        permission: compareObject?.["permission"]
          ? compareObject.permission
          : "",
        functionality: compareObject?.["functionality"]
          ? compareObject.functionality
          : "",
      };

      if (
        newElement.functionality === newCompareObject.functionality &&
        newElement.permission === newCompareObject.permission &&
        newElement.id === newCompareObject.id &&
        newElement.parentId === newCompareObject.parentId
      ) {
        element[key] = newValue;
      } else if (element["children"] && element["children"]?.length > 0) {
        updateNestedArray(
          element["children"],
          "isChecked",
          newValue,
          compareObject,
          props.parent
        );
      } else if (element["permissions"] && element["permissions"]?.length > 0) {
        updateNestedArray(
          element["permissions"],
          "isAction",
          newValue,
          compareObject,
          props.parent
        );
      }
    });
    console.log("issssaaaccctttiiiooonnn,", array);
    props.setData(array);
  }

  return (
    <>
      <div>
        <div
          //className={`node-label flex items-center  `}
          className="flex items-center"
        >
          <div
            //border border-[#475467] border-t-0 border-l-0 border-r-1 border-b-0 border-collapse
            className=" px-2 cursor-pointer py-1"
            onClick={() => {
              if (props?.node.children?.length > 0) {
                handleToggle();
              }
            }}
          >
            <div className="w-[450px] flex justify-between items-center gap-5">
              {/**** ${
          props?.node.children?.length > 0 || !props.node["parentId"]
            ? `font-normal text-sm text-gray`
            : ``
        } ` */}
              <div className="flex items-center ">
                <span className=" rounded px-2">
                  <span className=" rounded px-2" onClick={() => {}}>
                    <ArrowRightIcon
                      sx={{ padding: 0, margin: 0 }}
                      fontSize="small"
                    />
                  </span>
                </span>

                <span className="flex items-center whitespace-nowrap">
                  <span
                  // onClick={() => {
                  //   if (props?.node.children?.length > 0) {
                  //     handleToggle();
                  //   }
                  // }}
                  >
                    {props?.node.label
                      ? props.node.label
                      : props?.node.permission}
                  </span>
                </span>
              </div>
              <div className="">
                {props?.node?.["children"] ||
                props?.node.children?.length > 0 ? (
                  <ExpandMoreIcon
                    sx={{
                      width: 15,
                      height: 15,
                      color: "gray",
                      padding: 0,
                      margin: 0,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div>
            {/* {props.isPermission === true ? ( */}
            <fieldset>
              <div className="flex items-center gap-1  cursor-pointer">
                <AddOutlineIcon
                  title={"Add New Menu"}
                  type="button"
                  onClick={() => {
                    console.log("nodenodenodenodenodenode", props?.node?.label);
                    props?.setOpenSubMenu(true);
                    props?.setNodeData(props?.node);
                  }}
                />
                {/* {props?.node?.label === "Dashboard" || props?.node?.label === "FeedBack" ? null : <AddOutlineIcon type="button" onClick={() => {
                  console.log("nodenodenodenodenodenode", props?.node?.
                    label
                  );
                  props?.setOpenSubMenu(true)
                  props?.setNodeData(props?.node)

                }} />} */}
                <Tooltip title="Edit">
                  <DriveFileRenameOutlineIcon
                    //  title={"Edit"}
                    // type="button"
                    onClick={() => {
                      props?.setEdit(true);
                      props?.setOpenSubMenu(true);
                      props?.setNodeData(props?.node);
                    }}
                  />
                </Tooltip>
                <deleteOn
                  title={"Delete"}
                  type="button"
                  onClick={() => {
                    console.log("hiiiii boy");
                    props?.setDeleteConfirmation(true);
                  }}
                />

                {/* {(!props?.node?.["children"] ||
                  props?.node.children?.length === 0) &&
                props?.node?.label !== "Dashboard" &&
                props?.node?.label !== "FeedBack" &&
                props?.cut === true ? (
                  <PasteIcon
                    title={"Paste"}
                    type={"button"}
                    onClick={() => {}}
                  />
                ) : null} */}
                
                {(!props?.node?.["children"] ||
                  props?.node.children?.length === 0) &&
                props?.node?.label !== "Dashboard" &&
                props?.node?.label !== "FeedBack" ? (
                  // <HtmlTooltip
                  //   title={
                  //     <>
                  //       <div className="text-black  border border-[#475467] border-t-0 border-l-0 border-r-0 border-b-1  font-semibold">
                  //         Privilege
                  //       </div>
                  //       <React.Fragment>
                  //         <div className="text-sm font-normal ">
                  //           {tooltipArr &&
                  //             tooltipArr.map((item) => (
                  //               <span className="grid grid-cols-1">
                  //                 {item?.privilege}
                  //               </span>
                  //             ))}
                  //         </div>
                  //       </React.Fragment>
                  //     </>
                  //   }
                  // >
                    <div className=" flex w-full">
                      <AddFilledIcon
                        onClick={() => {
                          props?.setOpenFunToPrivilege(true);
                          props?.setNodeData(props?.node);
                        }}
                      />
                    </div>
                 // </HtmlTooltip>
                ) : null}

                <CustomAntSwitch
                  title="Active / Inactive"
                  defaultChecked={props?.node?.isActive ? true : false}
                  onClick={(e) => {
                  
                    props?.setActiveDeactiveMenu(e?.target?.checked);
                    props?.handleChangeActiveDeactiveMenuSubMenu(props?.node);

                    // activDeactiveMenu
                  }}
                />

                {/* {!props?.node?.["children"] || props?.node.children?.length === 0 ?
                  < AddFilledIcon type="button" onClick={() => {
                    props?.setOpenFunToPrivilege(true)
                    props?.setNodeData(props?.node)
                  }} />
                  : null} */}
              </div>
            </fieldset>
            {/* ) : (
               ""
            )} */}
          </div>
        </div>
        {isOpen && props?.node.children && (
          <div className="node-children">
            {props.node.children.map((child) => {
              setValue(
                "functionality" + `${child?.parentId}` + " " + `${child?.id}`,
                child.isChecked
              );
              return (
                <TreeNode
                  key={child.id}
                  node={child}
                  data={props.data}
                  parent={props.node}
                  setData={props.setData}
                  isPermission={false}
                  setOpenSubMenu={props.setOpenSubMenu}
                  setEdit={props?.setEdit}
                  setNodeData={props?.setNodeData}
                  setOpenFunToPrivilege={props?.setOpenFunToPrivilege}
                  setActiveDeactiveMenu={props?.setActiveDeactiveMenu}
                  activeDeactiveMenu={props?.activeDeactiveMenu}
                  handleChangeActiveDeactiveMenuSubMenu={
                    props?.handleChangeActiveDeactiveMenuSubMenu
                  }
                  deleteConfirmation={props?.deleteConfirmation}
                  setDeleteConfirmation={props?.setDeleteConfirmation}
                  paste={props?.paste}
                  handleChangeCloseCutConfirmation={
                    props?.handleChangeCloseCutConfirmation
                  }
                  setOpenBackdrop={props?.setOpenBackdrop}
                />
              );
            })}
          </div>
        )}
        {props?.node.permissions && (
          <div className="node-children shadow-lg grid grid-cols-4">
            {props.node.permissions.map((child) => (
              <TreeNode
                key={child.id}
                node={child}
                data={props.data}
                parent={props.node}
                setData={props.setData}
                isPermission={true}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const TreeView = (props) => {
  console.log("getnode of all treee", props);
  return (
    <div className="tree-view">
      {props?.data.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          data={props.data}
          setData={props.setData}
          isPermission={false}
          setOpenSubMenu={props.setOpenSubMenu}
          setEdit={props?.setEdit}
          setNodeData={props?.setNodeData}
          setOpenFunToPrivilege={props?.setOpenFunToPrivilege}
          setActiveDeactiveMenu={props?.setActiveDeactiveMenu}
          activeDeactiveMenu={props?.activeDeactiveMenu}
          handleChangeActiveDeactiveMenuSubMenu={
            props?.handleChangeActiveDeactiveMenuSubMenu
          }
          deleteConfirmation={props?.deleteConfirmation}
          setDeleteConfirmation={props?.setDeleteConfirmation}
          paste={props?.paste}
          handleChangeCloseCutConfirmation={
            props?.handleChangeCloseCutConfirmation
          }
          setOpenBackdrop={props?.setOpenBackdrop}
        />
      ))}
    </div>
  );
};

export function PrivilegeMapping(props) {
  const [modifiedDocuments, setModifiedDocuments] = React.useState([]);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [openFunToPrivilege, setOpenFunToPrivilege] = React.useState(false);
  const [nodeData, setNodeData] = React.useState(null);
  const [activeDeactiveMenu, setActiveDeactiveMenu] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const [paste, setPaste] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [
    privilegeToFunctionality,
    setPrivilegeToFunctionality,
  ] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleOpenSubMenu = () => setOpenSubMenu(true);
  const handleCloseSubMenu = () => setOpenSubMenu(false);
  const handleOpenFunToPrivilege = () => setOpenFunToPrivilege(true);
  const handleCloseFunToPrivilege = () => setOpenFunToPrivilege(false);
  const handleChangeOpenCutConfirmation = () => setDeleteConfirmation(true);
  const handleChangeCloseCutConfirmation = () => setDeleteConfirmation(false);

  const display = (person, parentId, level) => {
    if (person?.children) {
      person.parentId = parentId;
      person.level = level;
      person.children.forEach((child) => display(child, person.id, level + 1));
    }
    return person;
  };

  React.useEffect(() => {
    previousPrivilegeList(nodeData?.menuId);
  }, [nodeData?.menuId]);

  React.useEffect(() => {
    let dataArray = [...modifiedDocuments];
    if (props.dataResult?.length > 0) {
      for (let i = 0; i <= props.dataResult?.length; i++) {
        dataArray.push(
          display(props.dataResult[i], props.dataResult[i]?.id, 0)
        );
        setModifiedDocuments(dataArray);
      }
    }
  }, [props.dataResult]);

  function handleChangeActiveDeactiveMenuSubMenu(nodeInfo) {
    setOpenBackdrop(true);
    let postObj = {
      menuId: nodeInfo?.menuId,
      status: nodeInfo?.isActive,
    };
    console.log("postObjpostObjpostObjpostObj", postObj);
    activDeactiveMenu(postObj)
      .then((response) => response.data)
      .then((res) => {
        if (res?.statusCode === 200) {
          setOpenBackdrop(false);
          successAlert(res.message);
          props?.fetchAllUserMenu();
        }
      });
  }

  //Cut   menu from list
  function handleCutMenuSubMenu() {
    handleChangeCloseCutConfirmation();
  }

  function previousPrivilegeList() {
    if (nodeData?.menuId !== undefined) {
      fetchAllFunctinalityPrivilege(nodeData?.menuId)
        .then((response) => response.data)
        .then((res) => {
          console.log(
            "fetchAllFunctinalityPrivilegefetchAllFunctinalityPrivilegefetchAllFunctinalityPrivilege",
            res
          );

          setPrivilegeToFunctionality(res.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }
  console.log(
    "privilegeToFunctionalityprivilegeToFunctionalityprivilegeToFunctionality",
    privilegeToFunctionality
  );
  return (
    <>
      <div className="tree">
        {props.data?.length > 0 && (
          <div className="h-[670px] overflow-scroll">
            <TreeView
              data={props.data}
              setData={props.setdata}
              setOpenSubMenu={setOpenSubMenu}
              setNodeData={setNodeData}
              handleClose={handleCloseSubMenu}
              setOpenFunToPrivilege={setOpenFunToPrivilege}
              setEdit={setEdit}
              activeDeactiveMenu={activeDeactiveMenu}
              setActiveDeactiveMenu={setActiveDeactiveMenu}
              handleChangeActiveDeactiveMenuSubMenu={
                handleChangeActiveDeactiveMenuSubMenu
              }
              deleteConfirmation={deleteConfirmation}
              setDeleteConfirmation={setDeleteConfirmation}
              paste={paste}
              handleChangeCloseCutConfirmation={
                handleChangeCloseCutConfirmation
              }
              setOpenBackdrop={props?.setOpenBackdrop}
            />
          </div>
        )}
      </div>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <AddSubMenuModal
        nodeData={nodeData}
        open={openSubMenu}
        handleClose={handleCloseSubMenu}
        edit={edit}
        setEdit={setEdit}
        fetchAllUserMenu={props?.fetchAllUserMenu}
      />
      <AddNewPrivilegeToFunctinality
        nodeData={nodeData}
        open={openFunToPrivilege}
        handleClose={handleCloseFunToPrivilege}
        fetchAllUserMenu={props?.fetchAllUserMenu}
        privilegeToFunctionality={privilegeToFunctionality}
        setPrivilegeToFunctionality={setPrivilegeToFunctionality}
      />

      <ConfirmationModal
        confirmationOpen={deleteConfirmation}
        confirmationHandleClose={handleChangeCloseCutConfirmation}
        confirmationSubmitFunc={handleCutMenuSubMenu}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure Want To Remove This Menu ?"
        confirmationButtonMsg="Remove"
      />
    </>
  );
}

export default PrivilegeMapping;
