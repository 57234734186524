import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import OtSheetDetails from "./OtSheetDetails";
import SurgeryDetails from "./SurgeryDetails";
import DoctorEmpDetails from "./DoctorEmpDetails";
import Instruments from "./Instruments";
import SurgeryNotes from "./SurgeryNotes";
import AnesthetiaNotes from "./AnesthetiaNotes";
import PendingImplants from "./PendingImplants";
import PropTypes from "prop-types";
import { useContext } from "react";
import { OtDetailsContext } from "./OtDetails";
import UploadFileDetails from "./UploadFileDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const OtDetailsTabs = () => {
  const { tabValue, setTabValue, isDisable } = useContext(OtDetailsContext);

  //

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <div className="col-span-12">
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#dfe6e9",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
            >
              <Tab label="OT Sheet Details" {...a11yProps(0)} />
              <Tab
                label="Surgery Details"
                disabled={isDisable}
                {...a11yProps(1)}
              />
              <Tab label="Doctor/Emp" disabled={isDisable} {...a11yProps(2)} />
              <Tab
                label="Instruments "
                disabled={isDisable}
                {...a11yProps(3)}
              />
              <Tab
                label="Surgery Notes"
                disabled={isDisable}
                {...a11yProps(4)}
              />
              <Tab
                label="Anesthesia Notes "
                disabled={isDisable}
                {...a11yProps(5)}
              />
              <Tab
                label="File Upload "
                disabled={isDisable}
                {...a11yProps(6)}
              />
              <Tab
                label="Pending Implants "
                disabled={isDisable}
                {...a11yProps(7)}
              />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <OtSheetDetails />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <SurgeryDetails />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <DoctorEmpDetails />
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            {/* <Documents /> */}
            <Instruments />
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            {/* <Documents /> */}
            <SurgeryNotes />
          </TabPanel>

          <TabPanel value={tabValue} index={5}>
            {/* ansthesia */}
            <AnesthetiaNotes />
          </TabPanel>

          <TabPanel value={tabValue} index={6}>
            {/* <Documents /> */}
            <UploadFileDetails />
          </TabPanel>

          <TabPanel value={tabValue} index={7}>
            <PendingImplants />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default OtDetailsTabs;
