import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";
// adjustment type  services
// /api/stockAdjustment/adjustTypes}
export const fetchAdjustmentType = () => {
  return apiClient.get(`/stockAdjustment/adjustTypes`, {
    headers: authHeader(),
  });
};

// adjustment reason list
// /api/stockAdjustment/reasonList}
export const fetchAdjustmenReason = () => {
  return apiClient.get(`/stockAdjustment/reasonList`, {
    headers: authHeader(),
  });
};

// stock adjustment search Item
// api​/stockAdjustment​/itemDetailsList
export const searchStockAdjustmentItem = (stockAdjObj) => {
  return apiClient.post(`/stockAdjustment/itemDetailsList`, stockAdjObj, {
    headers: authHeader(),
  });
};

// post stock adjustment
// /api/stockAdjustment/saveStockAdjustment
export const postStockAdjustment = (postObj) => {
  return apiClient.post(`/stockAdjustment/saveStockAdjustment`, postObj, {
    headers: authHeader(),
  });
};

export const stockAdjustmentPdfPrint = (stockAdjustmentId) => {
  return apiClient.get(
    `/reports/inventory/expiryDateAdjustment/${stockAdjustmentId}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};
