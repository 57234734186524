import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getPhlebitiesScore = (admissionId) => {
  return apiClient.get(`clinicalCareChart/phlebitisLit/${admissionId}`,
 
  {
    headers: authHeader(),
  });
};

export const postPhlebitiesScore = (phlebitiesScoreObj) => {
  return apiClient.post(`clinicalCareChart/savePhlebitis`, phlebitiesScoreObj ,
  
  {
    headers: authHeader(),
  });
};
