import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useForm } from "react-hook-form";
import SearchIconButton from "../../../../../common/components/Buttons/SearchIconButton";
import CommonTable from "../../common/CommonTable";
const data = {
  result: [
    {
      Id: 1,
      AdmissionId: 1048510,
      MRNO: 305214,
      Date: "22/02/2022",
      PatientName: "DEEPTHI JITENDRA",
      KnowAllergies: "NOT KNOWN",
      NoRisk: "No",
      LowRisk: "No",
      HightRisk: "No",
      HistoryOfPrevious: 0,
    },
    {
      Id: 2,
      AdmissionId: 1048510,
      MRNO: 305214,
      Date: "22/02/2022",
      PatientName: "JAY Patil",
      KnowAllergies: "NOT KNOWN",
      NoRisk: "No",
      LowRisk: "No",
      HightRisk: "No",
      HistoryOfPrevious: 0,
    },
  ],
};
const actions = ["assessmentprint", "print"];
//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function View(props) {
  const defaultValues = {};
  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [fromDate, setFromDate] = React.useState(new Date());

  const [toDate, setToDate] = React.useState(new Date());

  function onSubmitDataHandler() {}
  return (
    <>
      <div className=" px-3">
        <form
          className="grid grid-cols-1 w-full gap-2 "
          onSubmit={handleSubmit(onSubmitDataHandler)}
        >
          <div className=" gap-7 items-center  pt-6">
            <div className="flex gap-2 items-center">
              <div className="flex space-x-3 items-center ">
                <div>
                  <span className="whitespace-nowrap">
                    Initial Nursing Assessment List
                  </span>
                </div>
                <div className="w-full">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Form Date"
                      name="fromDate"
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="bg-white"
                          fullWidth
                          size="small"
                          {...params}
                          sx={{
                            svg: {
                              color: "#0B83A5",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                // border: 0,
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="w-full">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="To Date"
                      name="toDate"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="bg-white"
                          fullWidth
                          size="small"
                          {...params}
                          sx={{
                            svg: {
                              color: "#0B83A5",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                // border: 0,
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <SearchIconButton />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="mx-auto">
          <CommonTable data={data} actions={actions} />
        </div>
      </div>
    </>
  );
}
