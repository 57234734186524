import { Box, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { ModalStyle } from "../../../../common/components/ModalStyle";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../../common/components/FormFields/RadioField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { useForm } from "react-hook-form";
import { getPhysioAssessmentList } from "../../services/PhysioGeneralAssessmentServices";
import { useEffect } from "react";
import PhysioAssessmentPrint from "../../prints/PhysioAssessmentPrint";
import {
  EditIcon,
  PrintIcon,
} from "../../../../assets/icons/InvestigationIcon";

const ListType = [
  {
    id: 0,
    label: "OPD",
    value: 0,
  },
  {
    id: 1,
    label: "IPD",
    value: 1,
  },
  {
    id: 2,
    label: "All",
    value: 2,
  },
];

const GenerralAssessmentListingModal = (props) => {
  const {
    openGenAsseListModal,
    setOpenGenAsseListModal,
    selectedRow,
    setSelectedRow,
  } = props;
  const { control, watch, reset } = useForm({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      listType: 2,
    },
  });
  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");
  let getListType = watch("listType");
  const [generalAssessmentList, setGeneralAssessmentList] = useState([]);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [rowId, setRowId] = useState(null);

  function getAssessmentList() {
    let listObj = {
      fromDate: getFromDate,
      toDate: getToDate,
      opdIpdAll: getListType,
    };
    getPhysioAssessmentList(listObj).then((response) => {
      setGeneralAssessmentList(response.data.result);
    });
  }

  function renderActions(row, index) {
    return (
      <div className="flex space-x-2">
        <Tooltip title="Print">
          <button
            type="button"
            onClick={() => {
              setRowId(row);
              setOpenPrintModal(true);
            }}
          >
            <PrintIcon />
          </button>
        </Tooltip>
        <Tooltip title="Edit">
          <button
            onClick={() => {
              setSelectedRow(row);
            }}
          >
            <EditIcon />
          </button>
        </Tooltip>
      </div>
    );
  }

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
      // setSelectedPatient(null);
    }, 500);

    return (
      <div className="hidden">
        <PhysioAssessmentPrint physioAssessmentId={rowId?.ID} />
      </div>
    );
  };

  useEffect(() => {
    if (openGenAsseListModal === true) {
      getAssessmentList();
    }
  }, [openGenAsseListModal]);
  return (
    <Modal open={openGenAsseListModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "85%",
          height: "85%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          border: "1px solid gray",
          boxShadow: 20,
          p: 2,
        }}
      >
        <div>
          <button
            onClick={() => {
              setOpenGenAsseListModal(false);
              setGeneralAssessmentList([]);
              reset();
            }}
          >
            <CancelPresentationIconButton />
          </button>
        </div>
        <div className="space-y-2">
          <div className="flex space-x-2 items-center">
            <div className="w-36">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className="w-36">
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className="mx-2">
              <RadioField
                control={control}
                name="listType"
                dataArray={ListType}
              />
            </div>
            <CommonButton
              type="button"
              searchIcon={true}
              className="bg-customBlue text-white"
              onClick={() => {
                setGeneralAssessmentList([]);
                getAssessmentList();
              }}
            />
          </div>
          <div>
            {generalAssessmentList !== undefined &&
            generalAssessmentList?.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={generalAssessmentList}
                tableClass={""}
                removeHeaders={["ID"]}
                renderActions={renderActions}
                highlightRow={false}
              />
            ) : (
              <div className="justify-center items-center font-semibold h-96 w-full flex border-2 border-gray-300 rounded my-2">
                No Records Found...
              </div>
            )}
          </div>
        </div>
        {openPrintModal && renderPrint()}
      </Box>
    </Modal>
  );
};

export default GenerralAssessmentListingModal;
