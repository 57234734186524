import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePagination from "../../../common/components/CommonTable/CommonDynamicTablePagination";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerField from "../../../common/components/FormFields/DatePickerField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  getEligibleCriteriaHeader,
  getNihStrokeScaleSheet,
  getPatientInfoAutoCompleteForOpdAndIpd,
  getPatientList,
  getScaleScoringSheetHeader,
  saveNihStrokeScale,
} from "../../services/NIHStrokeScaleScoringSheetServices/NIHStrokeScaleScoringSheetServices";
import EligibilityCriteriaTable from "./EligibilityCriteriaTable";
import NIHStrokeScaleScoringSheetTable from "./NIHStrokeScaleScoringSheetTable";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { useMemo } from "react";

const patientType = [
  {
    id: 0,
    value: "OPD",
    label: "OPD",
  },
  {
    id: 1,
    value: "IPD",
    label: "IPD",
  },
];

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
  {
    id: 1,
    action: "print",
    isAction: true,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NIHStrokeScaleScoringSheet = () => {
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [values, setValues] = React.useState(0);
  const [EligibilityTableData, setEligibilityTableData] = useState([]);
  const [eligibilityCriteria, setEligibilityCriteria] = useState([]);
  const [NIHStrokeTableData, setNIHStrokeTableData] = useState([]);
  const [NIHStrokeSheetDetails, setNIHStrokeSheetDetails] = useState();
  const [data, setData] = React.useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [patientDetails, setPatientDetails] = useState(null);
  const [autoComplete, setAutoComplete] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [NIHStrokeDateTimeData, setNIHStrokeDateTimeData] = useState();
  const [finalData, setFinalData] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [urlforPrint, setUrlforPrint] = useState();
  const [resetCount, setResetCount] = React.useState(0);
  const [preThombolysCheck, setPreThombolysCheck] = useState(false);
  const [hour1Check, setHour1Check] = useState(false);
  const [hour24Check, setHour24Check] = useState(false);
  const [days7Check, setDays7Check] = useState(false);
  const [onDischargeCheck, setOnDischargeCheck] = useState(false);
  const [listingObject, setListingObject] = useState({
    fromDate: new Date(),
    searchString: "",
    toDate: new Date(),
  });

  //   {
  //     "Date Time": "21/01/2024 12:06 PM",
  //     "MRNo": "2393",
  //     "Patient Name": "Mr. SASTE SAMPAT SAMBAJI",
  //     "Added Updated By": "Shinde Nilesh",
  //     "Gender": "Male",
  //     "Age": "1 Y 4 M 19 D ",
  //     "Opd_Ipd_External": 1,
  //     "Opd_Ipd_External_Id": 1060634,
  //     "NIhStrockScaleId": 185,
  //     "dob": "2022-09-02",
  //     "nihStrokeTime": "2024-01-21"
  // }

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  let getNIHStrokeId = patientDetails?.NIhStrockScaleId;
  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  const defaultValues = {
    searchPatient: null,
    patientType: 1,
    age18Yes: false,
    age18No: false,
    clinicalYes: false,
    clinicalNo: false,
    timeOnsetYes: false,
    timeOnsetNo: false,
    fromDate: new Date(),
    toDate: new Date(),
  };
  const {
    control,
    watch,
    reset,
    getValues,
    handleSubmit,
    setValue,
    register,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  let getSearchPatient = watch("searchPatient");
  let getPatientType = Number(watch("patientType"));
  let getTimeOfOnset = watch("timeOfOnset");
  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  React.useEffect(() => {
    if (patientDetails !== null && patientDetails?.MRNo) {
      getPatientInfoAutoCompleteForOpdAndIpd(
        patientDetails?.MRNo,
        getPatientType
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.result?.length > 0) {
            setValue("searchPatient", res.result[0]);
            setPatientInfo(res.result[0]);
          }
        });
    }
  }, [patientDetails]);

  function handleInputChange(searchString) {
    if (searchString !== "")
      getPatientInfoAutoCompleteForOpdAndIpd(searchString, getPatientType)
        .then((response) => response.data)
        .then((res) => {
          setAutoComplete(res.result);
        });
  }

  function eligibleCriteriaData() {
    getEligibleCriteriaHeader(getNIHStrokeId ? getNIHStrokeId : 0).then(
      (response) => {
        setEligibilityCriteria(response.data.result);
        setEligibilityTableData(
          response.data.result.eligibleCriteriaHeadersDtoList
        );
      }
    );
  }

  function nihStrokeData() {
    getScaleScoringSheetHeader(getNIHStrokeId ? getNIHStrokeId : 0).then(
      (response) => {
        setNIHStrokeSheetDetails(response.data.result);
        setNIHStrokeTableData(response.data.result.scaleScoringHeadersDtoList);
      }
    );
  }

  function patientListTable() {
    let listObj = {
      fromDate: getFromDate?.$d !== undefined ? getFromDate.$d : new Date(),
      searchString: "",
      toDate: getToDate?.$d !== undefined ? getToDate.$d : new Date(),
    };

    getPatientList(listObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
        setDataResult(res.result);
      });
  }

  // useEffect(() => {
  //   setValue("searchPatient", null);
  //   setPatientDetails(null);
  //   setPatientInfo(null);
  //   patientListTable();
  // }, [getFromDate, getToDate]);

  useMemo(() => {
    setValue("searchPatient", null);
    setPatientDetails(null);
    setPatientInfo(null);
    patientListTable();
  }, [listingObject?.fromDate, listingObject?.toDate]);

  useEffect(() => {
    setValues(0);
    eligibleCriteriaData();
    nihStrokeData();
  }, [patientDetails]);

  useEffect(() => {
    if (eligibilityCriteria?.age18 === 1) {
      setValue("age18Yes", true);
      setValue("age18No", false);
    } else if (eligibilityCriteria?.age18 === 2) {
      setValue("age18No", true);
      setValue("age18Yes", false);
    } else if (eligibilityCriteria?.age18 === 0) {
      setValue("age18No", false);
      setValue("age18Yes", false);
    }
    if (eligibilityCriteria?.clinicalDiagnosisDeficit === 1) {
      setValue("clinicalYes", true);
      setValue("clinicalNo", false);
    } else if (eligibilityCriteria?.clinicalDiagnosisDeficit === 2) {
      setValue("clinicalNo", true);
      setValue("clinicalYes", false);
    } else if (eligibilityCriteria?.clinicalDiagnosisDeficit === 0) {
      setValue("clinicalNo", false);
      setValue("clinicalYes", false);
    }
    if (eligibilityCriteria?.timeOfOnsetOfSymptom === 1) {
      setValue("timeOnsetYes", true);
      setValue("timeOnSetNo", false);
    } else if (eligibilityCriteria?.timeOfOnsetOfSymptom === 2) {
      setValue("timeOnsetNo", true);
      setValue("timeOnsetYes", false);
    } else if (eligibilityCriteria?.timeOfOnsetOfSymptom === 0) {
      setValue("timeOnsetNo", false);
      setValue("timeOnsetYes", false);
    }
  }, [eligibilityCriteria]);

  const onSubmitData = (data) => {
    let age18Val;
    if (data.age18Yes === true) {
      age18Val = 1;
    } else if (data.age18No === true) {
      age18Val = 2;
    } else {
      age18Val = 0;
    }

    let clinicalVal;
    if (data.clinicalYes === true) {
      clinicalVal = 1;
    } else if (data.clinicalNo === true) {
      clinicalVal = 2;
    } else {
      clinicalVal = 0;
    }

    let timeOnsetVal;
    if (data.timeOnsetYes === true) {
      timeOnsetVal = 1;
    } else if (data.timeOnsetNo === true) {
      timeOnsetVal = 2;
    } else {
      timeOnsetVal = 0;
    }

    let tempScaleScoringSheet = [];
    let temp = NIHStrokeTableData;
    for (let ih = 0; ih < temp.length; ih++) {
      let tempDescriptionData = temp[ih].scaleScoringHeaderDescriptionsDtoList;
      for (let id = 0; id < tempDescriptionData.length; id++) {
        let obj = {};
        obj.is1HourScore = tempDescriptionData[id].hour1;
        obj.is24HourScore = tempDescriptionData[id].hour24;
        obj.is7DaysScore = tempDescriptionData[id].days7;
        obj.isOnDischargeScore = tempDescriptionData[id].onDischarge;
        obj.isPreThrombolysisScore = tempDescriptionData[id].preThombolys;
        obj.scaleScoringSheetId = tempDescriptionData[id].scaleScoringHeaderId;
        obj.id = tempDescriptionData[id].id;
        tempScaleScoringSheet.push(obj);
      }
    }

    let tempEligibilityData = [];
    for (let i = 0; i < EligibilityTableData.length; i++) {
      let yesNoVal;
      if (EligibilityTableData[i].yes === true) {
        yesNoVal = 1;
      } else if (EligibilityTableData[i].no === true) {
        yesNoVal = 2;
      } else {
        yesNoVal = 0;
      }
      let obj = {};
      obj.contradictionId = EligibilityTableData[i].contradictionId;
      obj.eligibleCriteriaId = EligibilityTableData[i].id;
      obj.yesNoBoth = yesNoVal;
      tempEligibilityData.push(obj);
    }

    // setNIHStrokeTableData([...temp]);

    let externalId;
    if (getPatientType === 1) {
      externalId = patientInfo?.AdmissionId;
    } else if (getPatientType === 0) {
      externalId = patientInfo?.VisitId;
    }
    if (patientDetails !== null) {
      externalId = patientDetails?.Opd_Ipd_External_Id;
    }

    let getUserId = JSON.parse(localStorage.getItem("userInfo"));
    let postObj = {
      addedBy: getUserId.userId,
      dateTimeOnSet:
        getTimeOfOnset !== ""
          ? format(getTimeOfOnset, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      preThrombolysisScore:
        NIHStrokeDateTimeData?.preThrombolysScore !== null
          ? NIHStrokeDateTimeData?.preThrombolysScore
          : NIHStrokeSheetDetails?.preThrombolysScore !== ""
          ? NIHStrokeSheetDetails?.preThrombolysScore
          : "",
      preThrombolysisDateTime:
        NIHStrokeDateTimeData?.preThrombolysScore !== null
          ? NIHStrokeDateTimeData?.preThrombolysDate
          : NIHStrokeSheetDetails?.preThrombolysScore !== null
          ? new Date(NIHStrokeSheetDetails?.preThrombolysDate)
          : "1900-01-01T00:00:00.000Z",
      hourScore1:
        NIHStrokeDateTimeData?.hour1Score !== null
          ? NIHStrokeDateTimeData?.hour1Score
          : NIHStrokeSheetDetails?.hour1Score !== null
          ? NIHStrokeSheetDetails?.hour1Score
          : "",
      hourScoreDateTime1:
        NIHStrokeDateTimeData?.hour1Score !== null
          ? NIHStrokeDateTimeData?.hour1Date
          : NIHStrokeSheetDetails?.hour1Score !== null
          ? new Date(NIHStrokeSheetDetails?.hour1Date)
          : "1900-01-01T00:00:00.000Z",
      hourScore24:
        NIHStrokeDateTimeData?.hour24Score !== null
          ? NIHStrokeDateTimeData?.hour24Score
          : NIHStrokeSheetDetails?.hour24Score !== null
          ? NIHStrokeSheetDetails?.hour24Score
          : "",
      hourScoreDateTime24:
        NIHStrokeDateTimeData?.hour24Score !== null
          ? NIHStrokeDateTimeData?.hour24Date
          : NIHStrokeSheetDetails?.hour24Score !== null
          ? new Date(NIHStrokeSheetDetails?.hour24Date)
          : "1900-01-01T00:00:00.000Z",
      daysScore7:
        NIHStrokeDateTimeData?.days7Score !== null
          ? NIHStrokeDateTimeData?.days7Score
          : NIHStrokeSheetDetails?.day7Score !== null
          ? NIHStrokeSheetDetails?.day7Score
          : "",
      daysScoreDateTime7:
        NIHStrokeDateTimeData?.days7Score !== null
          ? NIHStrokeDateTimeData?.day7Date
          : NIHStrokeSheetDetails?.days7Score !== null
          ? new Date(NIHStrokeSheetDetails?.day7Date)
          : "1900-01-01T00:00:00.000Z",
      onDischargeScore:
        NIHStrokeDateTimeData?.onDischargeScore !== null
          ? NIHStrokeDateTimeData?.onDischargeScore
          : NIHStrokeSheetDetails?.onDischargeScore !== null
          ? NIHStrokeSheetDetails?.onDischargeScore
          : "",
      onDischargeScoreDateTime:
        NIHStrokeDateTimeData?.onDischargeScore !== null
          ? NIHStrokeDateTimeData?.onDischargeDate
          : NIHStrokeSheetDetails?.onDischargeScore !== null
          ? new Date(NIHStrokeSheetDetails?.onDischargeDate)
          : "1900-01-01T00:00:00.000Z",
      ecriteriaAgeGreater18: age18Val,
      ecriteriaClinicalDiagOfisChemic: clinicalVal,
      ecriteriaIsTimeOfSymptom: timeOnsetVal,
      opdIpd: getPatientType,
      opdIpdExternalId: externalId,
      nihStrokeId: patientDetails?.NIhStrockScaleId
        ? patientDetails?.NIhStrockScaleId
        : 0,
      scaleScoringSheetDtoList: tempScaleScoringSheet,
      nihStokeEligibleCriteriaDtoList: tempEligibilityData,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    setOpenConfirmation(true);

    setFinalData(postObj);
  };

  function postData() {
    setOpenBackdrop(true);
    saveNihStrokeScale(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        reset(defaultValues);
        setPatientDetails(null);
        setPatientInfo(null);
        eligibleCriteriaData();
        nihStrokeData();
        setValues(0);
        setNIHStrokeDateTimeData();
        setOpenBackdrop(false);
        setPreThombolysCheck(false);
        setHour1Check(false);
        setHour24Check(false);
        setDays7Check(false);
        setOnDischargeCheck(false);
      })
      .catch((error) => {
        setOpenBackdrop(true);
        errorAlert(error.message);
        // handleClosePost();
        setOpenBackdrop(false);
      });
    setOpenConfirmation(false);
  }

  function handleOpenPrintModal(row) {
    getNihStrokeScaleSheet(row?.NIhStrockScaleId).then((response) => {
      setOpenPrintModal(true);
      setUrlforPrint(response);
    });
  }

  const RenderActions = (row, index) => {
    return (
      <div>
        {userActions.map((action, i) => (
          <>
            {action.isAction === true && action.action === "Print" ? (
              <>
                <Tooltip title="Print">
                  <IconButton
                    onClick={() => {
                      handleOpenPrintModal(row);
                    }}
                  >
                    <LocalPrintshopIcon
                      sx={{ color: "#4B5563", height: "20px" }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    );
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="space-y-2">
          <div className="mt-14 text-xl font-bold flex justify-center">
            NIH Stroke Scale Scoring Sheet
          </div>
          <div className="xl:flex space-y-2 xl:gap-2">
            <div className="xl:w-[60%] space-y-3">
              <div className="flex gap-2">
                <div
                  className="w-full"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <SearchDropdown
                    control={control}
                    name="searchPatient"
                    placeholder="Search By Patient Name / MRNo."
                    searchIcon={true}
                    isClearable={true}
                    dataArray={autoComplete}
                    handleInputChange={handleInputChange}
                    inputRef={{
                      ...register("searchPatient", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setPatientInfo(e.target.value);
                          } else {
                            patientListTable();
                            setDataResult([]);
                            setPatientDetails(null);
                            setPatientInfo(null);
                            reset(defaultValues);
                            setValues(0);
                            setResetCount(resetCount + 1);
                            setNIHStrokeDateTimeData();
                          }
                        },
                      }),
                    }}
                    // isDisabled={patientDetails}
                  />
                </div>
                <div className="flex items-center w-full">
                  <label className="font-semibold">Patient Type :&nbsp;</label>
                  <fieldset
                    className="mx-2 mt-1"
                    disabled={patientInfo !== null}
                  >
                    <RadioField
                      control={control}
                      name="patientType"
                      dataArray={patientType}
                      defaultValue={"OPD"}
                    />
                  </fieldset>
                </div>
              </div>
              {patientDetails !== null ? (
                <div className="grid lg:flex lg:justify-between w-full bg-[#F1F1F1] border border-gray-400 rounded p-2">
                  <div className="flex">
                    <span className="font-bold w-32">
                      Patient Name&nbsp;:&nbsp;
                    </span>
                    <span>
                      {patientDetails ? patientDetails?.["Patient Name"] : null}
                    </span>
                  </div>
                  <div className="flex w-28">
                    <span className="font-bold">Age&nbsp;:&nbsp;</span>
                    <span>{patientDetails ? patientDetails?.Age : null}</span>
                  </div>
                  <div className="flex w-36">
                    <span className="font-bold">Gender&nbsp;:&nbsp;</span>
                    <span>
                      {patientDetails ? patientDetails?.Gender : null}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="grid lg:flex lg:justify-between w-full bg-[#F1F1F1] border border-gray-400 rounded p-2">
                  <div className="flex">
                    <span className="font-bold w-32">
                      Patient Name&nbsp;:&nbsp;
                    </span>
                    <span>{patientInfo ? patientInfo?.patientName : null}</span>
                  </div>
                  <div className="flex">
                    <span className="font-bold w-12">Age&nbsp;:&nbsp;</span>
                    <span>{patientInfo ? patientInfo?.AgeYear : null}</span>
                  </div>
                  <div className="flex w-36">
                    <span className="font-bold w-20">Gender&nbsp;:&nbsp;</span>
                    <span>{patientInfo ? patientInfo?.gender : null}</span>
                  </div>
                </div>
              )}
              <div className="flex items-center">
                <div className="font-semibold w-full">
                  Eligibilty Criteria for thrombolysis with TNK-t-PA in Acute
                  Ischemic Stroke
                </div>
                <div className="w-72 flex justify-end">
                  {/* <DatePickerField
                    control={control}
                    name="timeOfOnset"
                    label="Time Of Onset"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("timeOfOnset", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="DD-MM-YYYY"
                  /> */}
                  <Controller
                    control={control}
                    name="timeOfOnset"
                    defaultValue={new Date()}
                    render={({ field: { value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          value={value}
                          inputFormat="dd/MM/yyyy hh:mm aa"
                          // ampm={true}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              size="small"
                              sx={{
                                svg: {
                                  color: "#0B83A5",
                                  height: 22,
                                },
                                backgroundColor: "white",
                                overflow: "visible",
                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    fontSize: 14,
                                    height: "19px",
                                    overflow: "hidden",
                                  },
                                },
                              }}
                            />
                          )}
                          label="Date And Time"
                          onChange={(newValue) => {
                            setValue("timeOfOnset", newValue);
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="-space-y-2 text-[14px]">
                  <div className="flex justify-between items-center">
                    <div className="text-orange-700">{`1) Age :> 18`}</div>
                    <div className="flex gap-3">
                      <fieldset
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue("age18No", false);
                          }
                        }}
                        disabled={
                          patientInfo === null && patientDetails === null
                        }
                      >
                        <CheckBoxField
                          control={control}
                          name="age18Yes"
                          label="Yes"
                        />
                      </fieldset>
                      <fieldset
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue("age18Yes", false);
                          }
                        }}
                        disabled={
                          patientInfo === null && patientDetails === null
                        }
                      >
                        <CheckBoxField
                          control={control}
                          name="age18No"
                          label="No"
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-orange-700">
                      {`2) Clinical dignosis of ischemic stroke causing a measurable
                    neurological deficit`}
                    </div>
                    <div className="flex gap-3">
                      <fieldset
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue("clinicalNo", false);
                          }
                        }}
                        disabled={
                          patientInfo === null && patientDetails === null
                        }
                      >
                        <CheckBoxField
                          control={control}
                          name="clinicalYes"
                          label="Yes"
                        />
                      </fieldset>
                      <fieldset
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue("clinicalYes", false);
                          }
                        }}
                        disabled={
                          patientInfo === null && patientDetails === null
                        }
                      >
                        <CheckBoxField
                          control={control}
                          name="clinicalNo"
                          label="No"
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-orange-700">
                      {`3) Time of onset of symptom is established to be less than
                    3.0 hours before beginning treatment`}
                    </div>
                    <div className="flex gap-3">
                      <fieldset
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue("timeOnsetNo", false);
                          }
                        }}
                        disabled={
                          patientInfo === null && patientDetails === null
                        }
                      >
                        <CheckBoxField
                          control={control}
                          name="timeOnsetYes"
                          label="Yes"
                        />
                      </fieldset>
                      <fieldset
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue("timeOnsetYes", false);
                          }
                        }}
                        disabled={
                          patientInfo === null && patientDetails === null
                        }
                      >
                        <CheckBoxField
                          control={control}
                          name="timeOnsetNo"
                          label="No"
                        />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div className="text-red-700 justify-center flex font-semibold">
                  All above answer should be 'Yes' for thrombolytic therapy
                </div>
              </div>
            </div>
            <div className="xl:w-[40%]">
              <div className="flex gap-2">
                <div>
                  <DatePickerField
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="DD-MM-YYYY"
                    inputRef={{
                      ...register("fromDate", {
                        onChange: (e) => {
                          setListingObject((listingObject) => ({
                            ...listingObject,
                            fromDate: e.target.value.$d,
                          }));
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <DatePickerField
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="DD-MM-YYYY"
                    inputRef={{
                      ...register("toDate", {
                        onChange: (e) => {
                          setListingObject((listingObject) => ({
                            ...listingObject,
                            toDate: e.target.value.$d,
                          }));
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              {data.hasOwnProperty("result") && data?.result.length > 0 ? (
                <CommonDynamicTablePagination
                  resetCount={resetCount}
                  handleOpenPrintModal={handleOpenPrintModal}
                  data={data}
                  dataResult={dataResult}
                  setDataResult={setDataResult}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  count={dataResult.length}
                  setSelectedRow={setPatientDetails}
                  setValues={setValues}
                  actionsRequired={true}
                  renderActions={RenderActions}
                  removeHeaders={[
                    "Opd_Ipd_External_Id",
                    "NIhStrockScaleId",
                    "Opd_Ipd_External",
                    "Gender",
                    "Age",
                  ]}
                  tableClass={"h-52"}
                />
              ) : (
                <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
                  No Records Found...
                </div>
              )}
            </div>
          </div>
          <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <>
                <Tabs
                  value={values}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Eligibility Criteria" {...a11yProps(0)} />
                  <Tab
                    label="NIH Stroke Scale Scoring Sheet"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </>
            </Box>
            <TabPanel value={values} index={0}>
              <EligibilityCriteriaTable
                EligibilityTableData={EligibilityTableData}
                setEligibilityTableData={setEligibilityTableData}
                NIHStrokeSheetDetails={NIHStrokeSheetDetails}
                getNIHStrokeId={getNIHStrokeId}
                values={values}
                patientInfo={patientInfo}
                patientDetails={patientDetails}
              />
            </TabPanel>
            <TabPanel value={values} index={1}>
              <NIHStrokeScaleScoringSheetTable
                NIHStrokeTableData={NIHStrokeTableData}
                setNIHStrokeTableData={setNIHStrokeTableData}
                NIHStrokeDateTimeData={NIHStrokeDateTimeData}
                setNIHStrokeDateTimeData={setNIHStrokeDateTimeData}
                NIHStrokeSheetDetails={NIHStrokeSheetDetails}
                getNIHStrokeId={getNIHStrokeId}
                patientInfo={patientInfo}
                patientDetails={patientDetails}
                preThombolysCheck={preThombolysCheck}
                setPreThombolysCheck={setPreThombolysCheck}
                hour1Check={hour1Check}
                setHour1Check={setHour1Check}
                hour24Check={hour24Check}
                setHour24Check={setHour24Check}
                days7Check={days7Check}
                setDays7Check={setDays7Check}
                onDischargeCheck={onDischargeCheck}
                setOnDischargeCheck={setOnDischargeCheck}
              />
            </TabPanel>
          </div>
          <div className="justify-center flex font-semibold">
            All above answer should be 'No' for thrombolytic therapy
          </div>
          <div>
            <div className="font-bold">Warning :</div>
            <div className="grid">
              <label className="text-orange-700">
                It is unnecessary invasive examination of the patient should be
                avoided for at least few hours after treatment with TNK-t-PA.
              </label>
              <label className="text-orange-700">
                It is predicted from the literature that the risks of
                thrombolytic therapy would be higher in patients with the
                following conditions
              </label>
              <label className="text-orange-700">
                1.Pregnancy or lactating women
              </label>
              <label className="text-orange-700">2.Geriatrics</label>
              <label className="text-orange-700">
                3.Patients on anticoagulants such as warfarin sodium,
                glycoprotien (GP) IIb/IIIa inhibitors
              </label>
            </div>
          </div>
          {userActions.map((action, i) => (
            <>
              {action.action === "Create" && action.isAction === false ? (
                <div className="flex justify-end gap-2">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                      setPatientDetails(null);
                      setPatientInfo(null);
                      // patientListTable();
                      // eligibleCriteriaData();
                      setEligibilityTableData([]);
                      setNIHStrokeTableData([]);
                      setNIHStrokeDateTimeData();
                      // nihStrokeData();
                      setValues(0);
                      setPreThombolysCheck(false);
                      setHour1Check(false);
                      setHour24Check(false);
                      setDays7Check(false);
                      setOnDischargeCheck(false);
                    }}
                  />
                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    onClick={() => {
                      setPrivilege(action.action);
                      if (patientInfo === null && patientDetails === null) {
                        warningAlert("Please Select Patient...!");
                      }
                    }}
                  />
                </div>
              ) : null}
            </>
          ))}
        </div>
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={openConfirmation}
          confirmationHandleClose={() => {
            setOpenConfirmation(false);
          }}
          confirmationSubmitFunc={() => {
            postData();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this Record ?"
          confirmationButtonMsg="Add"
        />
      </form>
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
};

export default NIHStrokeScaleScoringSheet;
