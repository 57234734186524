import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";
import DropdownField from "../../common/components/FormFields/DropdownField";
import InputField from "../../common/components/FormFields/InputField";
import { getPTErejectResons } from "../services/PatientEngagementServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
  p: 3,
};

function RejectResonModal(props) {
  const [rejectReasons, setRejectReasons] = React.useState([]);

  const schema = yup.object().shape({
    rejReason: yup
      .object()
      .nullable()
      .required("Add Medi Claim Amount"),
  });

  const defaultValues = { rejReason: null, reasonField: "" };

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    // use mode to specify the event that triggers each input field
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    setValue("Reason", "");
  }, []);

  useEffect(() => {
    rejectReason();
  }, []);

  const rejectReason = (obj) => {
    getPTErejectResons(obj)
      .then((response) => response.data)
      .then((res) => {
        setRejectReasons(res.result);
      });
  };

  let Reason = watch("rejReason");
  let reasonField = watch("reasonField")


  let requiredObj = {};
  let tempArr = props.finalArr;

  const onSubmitDataHandler = () => {
    requiredObj.appointmentDate = "";
    requiredObj.inCompleteReason = "";
    requiredObj.inCompleteReasonId = "";
    requiredObj.ptEngDtlsId = props.rowId;
    requiredObj.reScheduleDate = "";
    requiredObj.reasonId = Reason.Id;
    requiredObj.status = "Rejected";
    requiredObj.InCompleteReason = "";

    if (Reason.label === "Other") {
      requiredObj.rejectReason = reasonField
    } else {
      requiredObj.rejectReason = Reason.value
    }
    tempArr.push(requiredObj);
    props.setFinalArr(tempArr);

    props.setOpenRejectModal(false);
  };

  return (
    <div>
      <Modal
        open={props.openRejectModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              props.setOpenRejectModal(false);
            }}
          />
          <fieldset className="border border-gray-300 text-left   lg:mx-auto lg:px-4 md:ml-0 md:mr-0  rounded   lg:m-2 ">
            <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
              Reject Reason
            </legend>
            <form
              onSubmit={handleSubmit(onSubmitDataHandler)}
              className="grid grid-cols-1 md:grid-cols-1  gap-2"
            >
              <div className="w-full grid grid-cols-3 gap-4">
                <div>
                  <DropdownField
                    dataArray={rejectReasons}
                    isSearchable={true}
                    name="rejReason"
                    placeholder="Select Reason"
                    error={errors.rejReason}
                    control={control}
                  />
                </div>
                <div>
                  {Reason?.label === "Other" ? (
                    <InputField
                      control={control}
                      name="reasonField"
                      label="Reason"
                      placeholder="Reason"
                    />
                  ) : ""}
                </div>

                <div className="flex justify-center py-2">
                  <CommonButton
                  className="px-3  bg-green-700 text-white rounded text-base"
                  onClick={onSubmitDataHandler} 
                  label="Save"
                  type="button"
                />
                </div>
              </div>
            </form>
          </fieldset>
        </Box>
      </Modal>
    </div>
  );
}

export default RejectResonModal;
