import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import InputField from "../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import {
  fetchQsofaDetails,
  saveGcs,
  saveQsofaDetails,
} from "../../services/cliinicalChartServices/ClinicalCareChartService";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";

export default function QSofaCalcModal(props) {
  const {
    open,
    handleClose,
    GCSData,
    setGCSData,
    setScoreBlocks,
    admissionId,
    menuId,
    scoreType,
  } = props;

  const [openBackdrop, setOpenBackdrop] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(null);
  //
  const [eyeOpeningResponse, setEyeOpeningResponse] = useState("1");
  const [verbalResponse, setVerbalResponse] = useState("1");
  const [motorResponse, setMotorResponse] = useState("1");
  //   const [GCSData, setGCSData] =useState(0);
  const [respiratoryRate, setRespiratoryRate] = useState("");
  const [systolicBP, setSystolicBP] = useState("");
  const [qsofaScore, setQsofaScore] = useState(null);

  const { control, watch, register, setValue } = useForm({
    defaultValues: {
      respiratoryRate: "",
      systolicBP: "",
    },
  });
  let calculateGCS;

  let resp = watch("respiratoryRate");
  let syto = watch("systolicBP");

  useEffect(() => {
    calculateGCS =
      parseInt(eyeOpeningResponse) +
      parseInt(verbalResponse) +
      parseInt(motorResponse);
    setGCSData(calculateGCS);

    updateQSOFA(respiratoryRate, systolicBP, calculateGCS);

    // setScoreBlocks((prev) =>
    //   prev.map((item) =>
    //     item.label === "GCS" ? { ...item, score: calculateGCS } : item
    //   )
    // );
  }, [eyeOpeningResponse, verbalResponse, motorResponse]);

  // qsofaCalculator.js
  function calculateQSOFA(respiratoryRate, systolicBP, gcs) {
    let score = 0;

    if (respiratoryRate >= 22) {
      score += 1;
    }

    if (systolicBP <= 100) {
      score += 1;
    }

    if (gcs < 15) {
      score += 1;
    }

    return score;
  }

  const handleRespiratoryRateChange = (e) => {
    const value = e.target.value;
    setRespiratoryRate(value);
  };

  const handleSystolicBPChange = (e) => {
    const value = e.target.value;
    setSystolicBP(value);
  };

  useEffect(() => {
    updateQSOFA();
  }, [respiratoryRate, systolicBP, GCSData]);

  const updateQSOFA = () => {
    console.log("respiratoryRaterespiratoryRate09", respiratoryRate);
    const score = calculateQSOFA(
      parseInt(respiratoryRate),
      parseInt(systolicBP),
      parseInt(GCSData)
    );
    setQsofaScore(score);
  };
  console.log("qsofaScore", systolicBP);

  const handleSaveScore = () => {
    // setQsofaScorefinal(qsofaScore);
    setScoreBlocks((prev) =>
      prev.map((item) =>
        item?.label === "QSOFA" ? { ...item, score: qsofaScore } : item
      )
    );

    // setGcsScrore(GCSData);
    // handleClose();
    setOpenConfirmation(true);
  };

  useEffect(() => {
    fetchQsofaDetails(admissionId)
      .then((response) => response.data)
      .then((res) => {
        //console.log("fetchQsofaDetails", res.result);
        setEyeOpeningResponse(res.result["Eye Open Response"] || "1");
        setMotorResponse(res.result["Motor Response"] || "1");
        setVerbalResponse(res.result["Verbal Response"] || "1");
        setValue("respiratoryRate", res.result?.respiratory_rate);
        setValue("systolicBP", res.result?.systolic_blood_pressure);
        setQsofaScore(res.result?.qsofa_score);
        setSystolicBP(res.result?.systolic_blood_pressure);
        setRespiratoryRate(res.result?.respiratory_rate);
      });
  }, []);

  const handleSaveQsofa = () => {
    setOpenBackdrop(true);
    setOpenConfirmation(false);
    let qsofaObj = {
      admissionId: admissionId,
      eyeOpenResponse: parseInt(eyeOpeningResponse),
      menuId: menuId,
      motorResponse: parseInt(motorResponse),
      privilege: "Create",
      verbalResponse: parseInt(verbalResponse),
      respiratoryRate: resp,
      systolicBloodPressure: syto,
      qsofaScore: qsofaScore,
    };

    let gcsObj = {
      admissionId: admissionId,
      eyeOpenResponse: parseInt(eyeOpeningResponse),
      menuId: menuId,
      motorResponse: parseInt(motorResponse),
      privilege: "Create",
      //  qsofaScore: 0,
      // respiratoryRate: 0,
      // systolicBloodPressure: 0,
      verbalResponse: parseInt(verbalResponse),
    };

    scoreType === "QSOFA" &&
      saveQsofaDetails(qsofaObj)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          handleClose();
          setOpenBackdrop(false);
        })
        .catch((err) => {
          setOpenBackdrop(false);
          errorAlert(err.message);
        });

    scoreType === "GCS" &&
      saveGcs(gcsObj)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          handleClose();
          setOpenBackdrop(false);
          setScoreBlocks((prev) =>
            prev.map((item) =>
              item.label === "GCS" ? { ...item, score: GCSData } : item
            )
          );
        })
        .catch((err) => {
          setOpenBackdrop(false);
          errorAlert(err.message);
        });
  };

  console.log("calculateGCS", GCSData);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: 5 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "85%", lg: "75%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            overflowY: "auto",
            maxHeight: "90%",
          }}
        >
          <div>
            <CancelPresentationIconButton onClick={handleClose} />
          </div>
          <div className=" px-4 py-2 rounded  text-black font-semibold whitespace-nowrap">
            {scoreType === "QSOFA" ? "qSOFA Details" : "GCS Details"}
          </div>
          <hr className="border mx-1  border-blue-900 px-4" mb-3 />

          <div className="w-full gap-2 px-4">
            <div className="">
              <div className="grid grid-cols-4 border-b p-1 my-2 border-gray-400 ">
                <div className="col-span-3  p-1">
                  <div className="">
                    <h1 className="text-base font-semibold py-2">
                      Altered mental status
                    </h1>
                  </div>
                  <div className="">
                    <div className="grid grid-cols-3 gap-2 pb-2">
                      {/* //Eye Opening Response// */}
                      <div className="border border-slate-500 px-4 rounded-md">
                        <FormControl>
                          <FormLabel
                            id="eyeOpeningResponse"
                            sx={{ color: "black", fontWeight: "bold" }}
                          >
                            Eye Opening Response
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="eyeOpeningResponse"
                            name="eyeOpeningResponse"
                            value={eyeOpeningResponse}
                            onChange={(e) => {
                              setEyeOpeningResponse(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="4"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Spontaneous (4)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  To Verbal Stimuli (3)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  To Pain (2)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  None (1)
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {/* //Verbal Response// */}
                      <div className="border border-slate-500 px-4 rounded-md">
                        <FormControl>
                          <FormLabel
                            id="eyeOpeningResponse"
                            sx={{ color: "black", fontWeight: "bold" }}
                          >
                            Verbal Response
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="eyeOpeningResponse"
                            name="eyeOpeningResponse"
                            value={verbalResponse}
                            onChange={(e) => {
                              setVerbalResponse(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="5"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Oriented (5)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="4"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Confused (4)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Inappropriate Words (3)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Incoherent (2)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  None (1)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  ET Tube in Situ (T)
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {/* //Motor Response// */}
                      <div className="border border-slate-500 px-4 rounded-md">
                        <FormControl>
                          <FormLabel
                            id="eyeOpeningResponse"
                            sx={{ color: "black", fontWeight: "bold" }}
                          >
                            Motor Response
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="eyeOpeningResponse"
                            name="eyeOpeningResponse"
                            value={motorResponse}
                            onChange={(e) => {
                              setMotorResponse(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="6"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Obeys Commands (6)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="5"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Localizes Pain (5)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="4"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Withdraws From Pain (4)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Flexion To Pain or Decorticate(3)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  Extension to Pain or Decerebrate (2)
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">
                                  None (1)
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                {scoreType === "QSOFA" ? (
                  <div className=" mt-[110px]  my-2 p-[20px] text-center">
                    {GCSData < 15 ? (
                      <div className="text-[18px] font-semibold">Yes</div>
                    ) : (
                      <div className="text-[18px] font-semibold">No</div>
                    )}
                  </div>
                ) : (
                  <div className=" items-center flex justify-center  text-center text-2xl font-semibold ">
                    <div className=" rounded-full p-3 bg-red-300 shadow-favShadow">
                      {GCSData && GCSData}
                    </div>
                  </div>
                )}
              </div>
              {scoreType === "QSOFA" && (
                <>
                  <div className="grid grid-cols-4 p-1 border-b my-2 border-gray-400 ">
                    <div className="flex gap-2 my-2    col-span-3">
                      <div className="w-[40%]">
                        <h1 className="text-base font-semibold">
                          Respiratory Rate (breaths/min) :
                        </h1>
                      </div>
                      <div className="w-[40%]">
                        <InputField
                          control={control}
                          type="number"
                          name="respiratoryRate"
                          value={respiratoryRate}
                          inputRef={{
                            ...register(`respiratoryRate`, {
                              onChange: (e) => {
                                handleRespiratoryRateChange(e);
                                //   if (e.target.value !== "") {
                                //     let value = Number(e.target.value);
                                //     console.log("e9090", value);
                                //     setRespiratoryRate(value);
                                //     updateQSOFA(value, systolicBP, GCSData);
                                //   } else if (e.target.value !== "") {
                                //     setRespiratoryRate("");
                                //     updateQSOFA("", systolicBP, GCSData);
                                //   }
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="  my-2  text-center">
                      {respiratoryRate >= 22 ? (
                        <div className="text-[18px] font-semibold">Yes</div>
                      ) : (
                        <div className="text-[18px] font-semibold">No</div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-4 my-2 border-b border-gray-400 p-1  ">
                    <div className="flex gap-2  col-span-3">
                      <div className="w-[40%]">
                        <h1 className="text-base font-semibold">
                          Systolic Blood Pressure (mm Hg):
                        </h1>
                      </div>
                      <div className="w-[40%]">
                        <InputField
                          control={control}
                          type="number"
                          name="systolicBP"
                          value={systolicBP}
                          inputRef={{
                            ...register(`systolicBP`, {
                              onChange: (e) => {
                                handleSystolicBPChange(e);
                                //   if (e.target.value !== "") {
                                //     const value = Number(e.target.value);
                                //     setSystolicBP(value);
                                //     updateQSOFA(respiratoryRate, value, GCSData);
                                //   } else if (e.target.value !== "") {
                                //     setSystolicBP("");
                                //     updateQSOFA(respiratoryRate, "", GCSData);
                                //   }
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className=" text-center">
                      {Number(systolicBP) <= 100 && Number(systolicBP) > 0 ? (
                        <div className="text-[18px] font-semibold">Yes</div>
                      ) : (
                        <div className="text-[18px] font-semibold">No</div>
                      )}
                    </div>
                  </div>
                  <div className="w-full flex ">
                    <div className="w-[20%]">
                      {qsofaScore !== null && (
                        <div className="flex gap-2 items-center mt-8">
                          <h2 className="text-lg font-semibold">
                            qSOFA Score :
                          </h2>
                          <div
                            className={` w-[45px] h-[45px] p-[2px] text-center text-4xl font-bold  rounded-full ${
                              qsofaScore === Number(0) ||
                              qsofaScore === Number(1)
                                ? "bg-green-600 text-white"
                                : qsofaScore === Number(2) ||
                                  qsofaScore === Number(3)
                                ? "bg-red-600 text-white"
                                : "text-bold text-xl"
                            }`}
                          >
                            {qsofaScore}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-[80%]">
                      {qsofaScore === 0 || qsofaScore === 1 ? (
                        <div
                          className={` text-sm font-bold  ${
                            qsofaScore === Number(0) || qsofaScore === Number(1)
                              ? "text-green-600 "
                              : ""
                          }`}
                        >
                          <div className="text-xl py-4">Not High Risk</div>
                          <div className="text-sm">
                            If sepsis is still suspected, continue to monitor,
                            evaluate, and initiate treatment as appropriate,
                            including serial qSOFA assessments.
                          </div>
                        </div>
                      ) : qsofaScore === 2 || qsofaScore === 3 ? (
                        <div
                          className={`  font-bold  ${
                            qsofaScore === Number(2) || qsofaScore === Number(3)
                              ? "text-red-600 "
                              : ""
                          }`}
                        >
                          <div className="text-xl py-4">High Risk</div>
                          <div className="text-sm">
                            qSOFA Scores 2-3 are associated with a 3- to 14-fold
                            increase in in-hosdivital mortality. Assess for
                            evidence of organ dysfunction with blood testing
                            including serum lactate and calculation of the full
                            SOFA Score. Patients meeting these qSOFA criteria
                            should have infection considered even if it was
                            previously not.
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end my-2 px-4">
            <CommonButton
              label="Save"
              className="bg-customGreen text-white"
              type="button"
              onClick={() => {
                handleSaveScore();
              }}
            />
          </div>
        </Box>
      </Modal>
      {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}

      {openConfirmation && (
        <ConfirmationModal
          confirmationOpen={openConfirmation}
          confirmationHandleClose={() => setOpenConfirmation(false)}
          confirmationSubmitFunc={handleSaveQsofa}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this record ?"
          confirmationButtonMsg="Save"
        />
      )}
    </div>
  );
}
