import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const savePushTool = (saveObj) => {
  return apiClient.post(`/pushTool/savePushTool`, saveObj, {
    headers: authHeader(),
  });
};

export const getPushToolList = (listObj) => {
  return apiClient.post(`/pushTool/getPushToolList`, listObj, {
    headers: authHeader(),
  });
};

export const getLengthDropdown = () => {
  return apiClient.get(`/pushTool/getLength`, {
    headers: authHeader(),
  });
};

export const getExudateAmountDropdown = () => {
  return apiClient.get(`/pushTool/getExudateAmount`, {
    headers: authHeader(),
  });
};

export const getTissueTypeDropdown = () => {
  return apiClient.get(`/pushTool/getTissueType`, {
    headers: authHeader(),
  });
};

export const searchPatientAutoComplete = (searchString, opdIpd) => {
  return apiClient.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};
