import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import InstructionModal from "./InstructionsModal";
import { fetchPatientInfoAutoComplete } from "../../../nursingStation/services/ventilatorBundleServices/VentilatorBundleServices";
import CustomInput from "../../../common/components/FormFields/CustomInput";
import { saveBloodRequisitionNote } from "../../services/bloodAquisitionServices/BloodAquisitionServices";
import { format } from "date-fns";
import BloodRequisitionNotePrint from "../../prints/BloodRequisitionNotePrint";

const isReport_verbal = [
  {
    id: 0,
    value: "Known By Report",
    label: "Known By Report",
  },
  {
    id: 1,
    value: "Verbal",
    label: "Verbal",
  },
];

const RequiredBloodFor = [
  {
    id: "0",
    label: "Adult",
    value: "Adult",
  },
  {
    id: "1",
    label: "Pediatric",
    value: "Pediatric",
  },
];

const schema = yup
  .object({
    wholeBloodDtls: yup.string().when("wholeBlood", {
      is: true,
      then: yup.string().required(),
    }),
    pcvDtls: yup.string().when("pcv", {
      is: true,
      then: yup.string().required(),
    }),
    ffpDtls: yup.string().when("ffp", {
      is: true,
      then: yup.string().required(),
    }),
    rdpDtls: yup.string().when("rdp", {
      is: true,
      then: yup.string().required(),
    }),
    sdpDtls: yup.string().when("sdp", {
      is: true,
      then: yup.string().required(),
    }),
    cryoprecipitateDtls: yup.string().when("cryoprecipitate", {
      is: true,
      then: yup.string().required(),
    }),
    specifyAnyOtherDtls: yup.string().when("specifyAnyOther", {
      is: true,
      then: yup.string().required(),
    }),
  })
  .required();

const BloodRequisitionNote = (props) => {
  let location = useLocation();

  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [finalData, setFinalData] = useState("");
  const [reqId, setReqId] = useState(null);

  //
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(
    props.selectedPatient || null
  );
  //
  //Instructions modal
  const [openInstructionsModal, setOpenInstructionsModal] = useState(true);
  const handleOpenInstructionsModal = () => setOpenInstructionsModal(true);
  const handleCloseInstructionsModal = () => setOpenInstructionsModal(false);
  //Print
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  //

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      patientSearch: "",
      requireBloodFor: "0",
      report_verbal: 0,
    },
  });

  const {
    formState: { errors },
    control,
    register,
    watch,
  } = methods;

  const wholeBlood = watch("wholeBlood");
  const pcv = watch("pcv");
  const ffp = watch("ffp");
  const rdp = watch("rdp");
  const sdp = watch("sdp");
  const cryoprecipitate = watch("cryoprecipitate");
  const specifyAnyOther = watch("specifyAnyOther");

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(props.menuId || location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const getPatientInfoAutoComplete = (serchString) => {
    serchString &&
      fetchPatientInfoAutoComplete(serchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  //print related
  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
      setSelectedPatient(null);
    }, 500);

    return (
      <div className="hidden">
        <BloodRequisitionNotePrint
          admissionId={selectedPatient?.AdmissionId}
          requisitionId={reqId}
        />
      </div>
    );
  };

  const onSubmit = (Data) => {
    if (selectedPatient !== null) {
      setFinalData(Data);
      setOpenConfirmationModal(true);
    } else {
      warningAlert("Please Select Patient!");
    }
  };

  const handleFormSubmit = (data) => {
    let finalObj = {};
    //
    finalObj.privilege = privilege;
    finalObj.menuId = location?.state?.menuId;
    //
    finalObj.abogroup = data.aboGroup;
    finalObj.admissionId = selectedPatient?.AdmissionId; ///////
    finalObj.adult = Number(data.requireBloodFor) === 0 ? 1 : 0;
    finalObj.anyOther = data.specifyAnyOther ? 1 : 0;
    finalObj.anyOtherText = data.specifyAnyOtherDtls || 0;
    finalObj.atTime = data?.atTime
      ? format(data?.atTime, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.sss'Z'");
    finalObj.blood_Plasma = data.blood_Plasma_Platelets;
    finalObj.consultant = data.consultantAuthorizingBloodTransfusion;
    // finalObj.creationdate = data.date ? data.date : new Date();

    finalObj.creationdate = data?.date
      ? format(data?.date, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.sss'Z'");

    finalObj.creationtime = data?.date
      ? format(data?.date, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.sss'Z'");

    // finalObj.creationtime = data.date ? data.date : new Date();
    finalObj.cryoprecipitate = data.cryoprecipitate ? 1 : 0;
    finalObj.cryoprecipitate_BalQty = 0;
    finalObj.cryoprecipitateqty = data.cryoprecipitateDtls
      ? Number(data.cryoprecipitateDtls)
      : 0;
    finalObj.diagnosis = data.dignosis;
    finalObj.emergency = data.emergency ? 1 : 0;
    finalObj.ffp = data.ffp ? 1 : 0;
    finalObj.ffp_BalQty = 0; ///
    finalObj.ffpqty = Number(data.ffpDtls) || 0;
    finalObj.institutionName = data.nameOfInstitution;
    finalObj.knownByReportVerbal = Number(data.report_verbal); ////////////
    finalObj.location = data.LocationBloodNeeded;
    finalObj.onDate = data?.onDate
      ? format(data?.onDate, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.sss'Z'");
    finalObj.pcv = data.pcv ? 1 : 0;
    finalObj.pcv_BalQty = 0; ///
    finalObj.pcvqty = Number(data.pcvDtls) || 0;
    finalObj.pediatric = Number(data.requireBloodFor) === 1 ? 1 : 0;
    finalObj.quantity = 0; ////////
    finalObj.rdp = data.rdp ? 1 : 0;
    finalObj.rdp_BalQty = 0; ////
    finalObj.rdpqty = Number(data.rdpDtls) || 0;
    finalObj.receivedBy = 0; ////////////
    finalObj.receivingDate = new Date(); /////////////
    finalObj.receivingTime = new Date(); //////////////
    finalObj.rh = data.rh;
    finalObj.rmoName = data.rmoName;
    finalObj.routine = data.routine ? 1 : 0;
    finalObj.sdp = data.sdp ? 1 : 0;
    finalObj.sdp_BalQty = 0; ///
    finalObj.sdpqty = Number(data.sdpDtls) || 0;
    finalObj.status = 0;
    finalObj.transfusionDate = new Date(); ////////
    finalObj.transfusionReaction = data.anyTransfusionReaction;
    finalObj.unitsNeeded = 0; ///////////////
    finalObj.wholeBlood = data.wholeBlood ? 1 : 0;
    finalObj.wholeBloodQty = data.wholeBloodDtls || 0;
    finalObj.wholeBlood_BalQty = 0; ///

    saveBloodRequisitionNote(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          setReqId(res.result);
          setOpenPrintModal(true);
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          methods.reset();
          // setSelectedPatient(null);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <div className="mt-12">
      <form>
        <div className="text-center text-black font-bold text-xl ">
          Blood Requisition Note
        </div>
        <div className=" py-2 grid grid-cols-3 ">
          <div className="md:col-span-2 lg:col-span-1 mb-3">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={autocompleteData}
              name="patientSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={getPatientInfoAutoComplete}
              isDisabled={props.isFromClinicalCarechart}
              inputRef={{
                ...register("patientSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                    } else {
                      setSelectedPatient("");
                      methods.reset();
                    }
                  },
                }),
              }}
            />
          </div>
        </div>
        {/* patientInfo */}
        <fieldset
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
        >
          <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedPatient &&
                    (selectedPatient?.patientName ||
                      selectedPatient?.PatientName)}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.MRNo}
                </span>
              </div>
            </div>
          </div>
        </fieldset>

        <p className="text-center text-red-500">
          Remember to take consent while taking the blood*
        </p>

        <div className="grid lg:grid-cols-8 md:grid-cols-4 gap-3 mt-3">
          <div className="">
            <CheckBoxField control={control} name="routine" label="Routine " />
          </div>

          <div className="">
            <CheckBoxField
              control={control}
              name="emergency"
              label="Emergency"
            />
          </div>

          <div className="col-span-6 ">
            <TextField
              id="outlined-multiline-static"
              name="dignosis"
              {...register("dignosis")}
              label="Diagnosis"
              InputLabelProps={{ shrink: true }}
              multiline
              rows={1}
              fullWidth
              sx={{
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
        <hr className="border my-2 md:col-span-4 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300" />

        <p className="mr-3 mt-3 font-bold tracking-wide text-sm font-Poppins">
          Previous History Transfusion
        </p>
        <div className="grid md:grid-cols-3 lg:grid-cols-7 gap-3 mt-3">
          <div className="">
            <DatePickerFieldNew
              control={control}
              name="date"
              label="Date"
              value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="col-span-3">
            <InputField
              name="nameOfInstitution"
              label="Name of institution"
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="blood_Plasma_Platelets"
              label="Blood/Plasma/Platelets etc"
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="aboGroup"
              label="ABO Group "
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="rh"
              label="Rh"
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="flex my-3 md:col-span-3 lg:col-span-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Was CPR Initiated In The Ward :
            </p>
            <RadioField
              label=""
              name="report_verbal"
              control={control}
              dataArray={isReport_verbal}
            />
          </div>
          <div className="col-span-4 ">
            <TextField
              id="outlined-multiline-static"
              name="anyTransfusionReaction"
              {...register("anyTransfusionReaction")}
              label="Any Transfusion Reaction"
              InputLabelProps={{ shrink: true }}
              multiline
              rows={1}
              fullWidth
              sx={{
                backgroundColor: "white",
              }}
            />
          </div>
        </div>

        <hr className="border my-2 md:col-span-3 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300 " />
        <div className="flex gap-3 items-center">
          <div className="flex my-3 md:col-span-3 lg:col-span-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Required Blood :
            </p>
            <RadioField
              label=""
              name="requireBloodFor"
              control={control}
              dataArray={RequiredBloodFor}
            />
          </div>
        </div>
        <div className="gap-3 grid md:grid-cols-3 lg:flex items-center flex-wrap">
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2 ">
              <CheckBoxField
                control={control}
                checkBoxStyle={{ padding: "0px" }}
                name="wholeBlood"
                label="Whole Blood"
              />
            </div>
            <div className=" w-14">
              <CustomInput
                name="wholeBloodDtls"
                error={errors.wholeBloodDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!wholeBlood}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2">
              <CheckBoxField control={control} name="pcv" label="PCV " />
            </div>
            <div className="w-14">
              <CustomInput
                name="pcvDtls"
                error={errors.pcvDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!pcv}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2">
              <CheckBoxField control={control} name="ffp" label="FFP" />
            </div>
            <div className="w-14">
              <CustomInput
                name="ffpDtls"
                error={errors.ffpDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!ffp}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2">
              <CheckBoxField control={control} name="rdp" label="RDP" />
            </div>
            <div className="w-14">
              <CustomInput
                name="rdpDtls"
                error={errors.rdpDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!rdp}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2">
              <CheckBoxField control={control} name="sdp" label="SDP" />
            </div>
            <div className="w-14">
              <CustomInput
                name="sdpDtls"
                error={errors.sdpDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!sdp}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2">
              <CheckBoxField
                control={control}
                name="cryoprecipitate"
                label="CRYOPRECIPITATE"
              />
            </div>
            <div className="w-14">
              <CustomInput
                name="cryoprecipitateDtls"
                error={errors.cryoprecipitateDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!cryoprecipitate}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:flex items-center">
            <div className="col-span-2">
              <CheckBoxField
                control={control}
                name="specifyAnyOther"
                label="Any Other"
              />
            </div>
            <div className="w-14">
              <CustomInput
                name="specifyAnyOtherDtls"
                error={errors.specifyAnyOtherDtls}
                type="number"
                variant="outlined"
                control={control}
                disabled={!specifyAnyOther}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
        </div>

        <hr className="border my-2 md:col-span-3 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300 " />
        <div className="mt-3 grid md:grid-cols-3 lg:grid-cols-7 gap-3">
          <div className="">
            <DatePickerFieldNew
              control={control}
              name="onDate"
              label="On Date"
              value={new Date()}
              disableFuture={false}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div>
            <Controller
              control={control}
              name="surgeonArrivalTime"
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    label="At Time"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        error={errors.startTime}
                        helperText={errors.startTime?.message}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                            position: "absolute",
                            top: "-2px",
                          },
                        }}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              width: "100%",

                              borderColor: "  ",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div className="md:col-span-3 lg:col-span-3">
            <InputField
              name="LocationBloodNeeded"
              label="Location where blood will be needed"
              variant="outlined"
              // error={errors.representativeAddress}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="lg:col-span-2 md:col-span-3">
            <InputField
              name="consultantAuthorizingBloodTransfusion"
              label="Consultant authorizing blood transfusion"
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="lg:col-span-2 md:col-span-3">
            <InputField
              name="rmoName"
              label="RMO Name"
              variant="outlined"
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>
        <div className="flex gap-3 justify-end my-3 ">
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <div>
                    <CommonButton
                      label=" Reset"
                      onClick={() => {
                        methods.reset();
                      }}
                      className="border border-customRed text-customRed"
                    />
                  </div>
                  <div
                    onClick={() => {
                      setPrivilege(obj.action);
                    }}
                  >
                    <CommonButton
                      label="Save"
                      onClick={methods.handleSubmit(onSubmit)}
                      className="bg-customGreen text-white"
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>
      {/* //Instruction Modal// */}
      {openInstructionsModal && (
        <InstructionModal
          open={openInstructionsModal}
          setOpen={setOpenInstructionsModal}
          handleOpen={handleOpenInstructionsModal}
          handleClose={handleCloseInstructionsModal}
        />
      )}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {openPrintModal && renderPrint()}
    </div>
  );
};

export default BloodRequisitionNote;
