import apiClientBilling from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";

export const searchDoctor = (searchString) => {
  return apiClientBilling.get(`/misc/doctor/${searchString}`, {
    headers: authHeader(),
  });
};

export const fetchConsultationCharges = async (consultationChargeObj) => {
  return apiClientBilling.post(
    `/services/consultationCharges`,
    consultationChargeObj,
    {
      headers: authHeader(),
    }
  );
};

//autocomplete
export const searchPatientInformation = (searchString, opdIpd) => {
  return apiClientBilling.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

//autocomplete
export const searchPatientForBillList = (patientObjForBillSearch) => {
  return apiClientBilling.post(
    `/billing/patientSearchForBillList`,
    patientObjForBillSearch,
    {
      headers: authHeader(),
    }
  );
};

//registration charges
export const getRegistrationCharges = (visitId) => {
  return apiClientBilling.get(`/billing/registrationCharges/${visitId}`, {
    headers: authHeader(),
  });
};

export const searchPatientBillInfo = (patientString) => {
  return apiClientBilling.get(`/billing/searchPatient/${patientString}`, {
    headers: authHeader(),
  });
};

export const getPatientInformationById = (patientId, opdIpd) => {
  return apiClientBilling.get(`/patientInfo/bill/${patientId}/0`, {
    headers: authHeader(),
  });
};

export const ipdgetPatientInformationById = (patientId) => {
  return apiClientBilling.get(`/patientInfo/bill/${patientId}/1`, {
    headers: authHeader(),
  });
};

export const getIPDPatientInformationById = (patientId) => {
  return apiClientBilling.get(`/patientInfo/charges/${patientId}`, {
    headers: authHeader(),
  });
};

export const getSelftSettlementInformationById = (patientId, opdIpd) => {
  return apiClientBilling.get(
    `/patientInfo/selfPaymentSettlement/${patientId}/0`,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientBillInfoById = (billId) => {
  return apiClientBilling.get(`/billing/patientBillList/${billId}`, {
    headers: authHeader(),
  });
};
//services for bill
export const fetchAllOPDBillingServices = async (serviceObj) => {
  return apiClientBilling.post(`/services/search`, serviceObj, {
    headers: authHeader(),
  });
};

export const postOPDBillService = async (finalObj) => {
  return apiClientBilling.post(`/billing/saveOpdBill`, finalObj, {
    headers: authHeader(),
  });
};

export const getAdvancedDropdown = async () => {
  return apiClientBilling.get(`/misc/AdvAgainst`, {
    headers: authHeader(),
  });
};

export const getBankList = async () => {
  return apiClientBilling.get(`/misc/bank`, {
    headers: authHeader(),
  });
};

export const getPaymentMode = async () => {
  return apiClientBilling(`/misc/paymentType`, {
    headers: authHeader(),
  });
};

export const getUpiType = () => {
  return apiClientBilling.get(`/misc/upiTypes`, {
    headers: authHeader(),
  });
};

export const getDoctorList = async () => {
  return apiClientBilling(`/misc/doctor`, {
    headers: authHeader(),
  });
};

export const getPatientBillList = async (billSearchObj) => {
  return apiClientBilling.post(`/billing/patientBillList`, billSearchObj, {
    headers: authHeader(),
  });
};

export const getPackageCharges = async (
  serviceIdValue,
  tariffIdVal,
  unitIdValue,
  classIdVal
) => {
  return apiClientBilling.get(
    `/billing/packageCharges/${serviceIdValue}/${tariffIdVal}/${unitIdValue}/${classIdVal}`,
    {
      headers: authHeader(),
    }
  );
};

export const getBillPrint = (billId) => {
  return apiClientBilling.get(`/reports/opd/BillReceipt?billId=${billId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const cancelBill = (billId, reasonForBillCancellation) => {
  return apiClientBilling.get(
    `/billing/cancelOpdBill/${billId}/${reasonForBillCancellation}`,
    {
      headers: authHeader(),
    }
  );
};

export const getConsultationFollowupCharge = (doctorIdVal, patientIdVal) => {
  return apiClientBilling.get(
    `/services/consultationFollowupCharge/${doctorIdVal}/${patientIdVal}`,
    {
      headers: authHeader(),
    }
  );
};

export const getOpdPatientInfo = (patientIdVal, visitIdVal) => {
  return apiClientBilling.get(
    `/billing/getOpdPatient/${patientIdVal}/${visitIdVal}`,
    {
      headers: authHeader(),
    }
  );
};
