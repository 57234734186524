import { Divider } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import ConfirmationModal from "../../../../common/components/ConfirmationModal";

import InputEnter from "../../../../common/components/FormFields/InputEnter";
import {
  errorAlertCustom,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { itemBatchNumber } from "./../../../../inventory/services/stockAdjustment/StockAdjustmentServices";
import ConsumedItemListTable from "./ConsumedItemListTable";
import InventoryMaterialConsumptionModal from "./InventoryMaterialConsumptionModal";
import ItemBatchInfoModal from "./ItemBatchInfoModal";
import {
  getItemList,
  postMaterialConsumptionData,
} from "./services/materialConsumptionServices";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";

import { useLocation } from "react-router-dom";

const actions = ["Edit", "Delete"];
function InventoryMaterialConsumption() {
  const location = useLocation();

  const Actions = [
    {
      id: 0,
      action: "Add",
      isAction: false,
    },
    {
      id: 1,
      action: "PreviousConsumptionList",
      isAction: false,
    },
  ];

  const schema = yup.object().shape({
    balanceQty: yup.number().positive().integer(),
    usedQty: yup
      .number()
      .positive()
      .integer()
      // .typeError("Required")
      .min(1, "Required")
      .required("Required")
      .when("balanceQty", (balanceQty, schema) => {
        return schema
          .test({
            test: (usedQty) => {
              if (!usedQty) return true;
              return usedQty <= balanceQty;
            },
            message: "Amount Exceeds Than Total Advance",
          })
          .required("Required");
      }),
  });

  const [userActions, setUserActions] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);

  const [itemSearchString, setItemSearchString] = React.useState("");
  const [itemList, setItemList] = React.useState([]);

  const [searchItemSuggestions, setSearchItemSuggestions] = React.useState([]);
  const [listItemObj, setListItemObj] = React.useState(null);
  const [itemBatchesArray, setItemBatchesArray] = React.useState([]);
  const [openItemBatchInfoModal, setOpenItemBatchInfoModal] =
    React.useState(false);
  const [batchItemInfoObj, setBatchItemInfoObj] = React.useState(null);
  const [editableDrugObj, setEditableDrugObj] = React.useState(null);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [batchesRequiredFlag, setBatchesRequiredFlag] = React.useState(false);

  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  const defaultValues = {
    storedepartment: null,
    search: null,
    batchNo: "",
    expiryDate: "",
    uom: "",
    balanceQty: "",
    usedQty: "",
    remark: "",
  };

  const {
    control,
    setValue,
    register,
    getValues,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  let searchObj = watch("search");


  //useEffect to populate the "Search Item" SearchDropdown.
  React.useEffect(() => {
    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    let storeIdVal = userInfoObj.storeId;

    if (itemSearchString !== "") {
      getItemList(itemSearchString, storeIdVal).then((response) => {
        setSearchItemSuggestions(response.data.result);
      });
    }
  }, [itemSearchString]);

  React.useEffect(() => {
    if (batchItemInfoObj) {
      setValue("batchNo", batchItemInfoObj.BatchCode);
      focusOnUsedQtyInputField();
     

      let batchExpDateString = batchItemInfoObj.BatchExpDate;

      setValue("expiryDate", batchExpDateString);
      setValue("balanceQty", batchItemInfoObj.BalQty);
      
    }
  }, [batchItemInfoObj]);

  React.useEffect(() => {
    if (editableDrugObj) {

      let searchObj = {
        id: editableDrugObj.ItemId,
        ItemId: editableDrugObj.ItemId,
        value: editableDrugObj["Item Name"],
        label: editableDrugObj["Item Name"],
        BatchesRequired: false,
      };

      searchObj.BatchesRequired = editableDrugObj.BatchesRequired;

      setValue("search", searchObj);
      setValue("batchNo", editableDrugObj["Batch No"]);
      setValue("expiryDate", editableDrugObj["Expiry Date"]);
      setValue("uom", editableDrugObj.UOM);
      setValue("balanceQty", editableDrugObj["Balanced Quantity"]);
      setValue("usedQty", editableDrugObj["Used Quantity"]);
      setValue("remark", editableDrugObj.Remark);
    }
  }, [editableDrugObj]);

  React.useEffect(() => {
    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    let storeIdVal = userInfoObj.storeId;

    let storeObj = {
      id: storeIdVal,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };

    setValue("storedepartment", storeObj);

    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {

        let tempUserActonsArr = res.result;

        setUserActions(tempUserActonsArr);
      });
  }, []);

  //function to save the material consumption data.
  function saveMaterialConsumptionData() {
    //the postObj is

    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    let storeIdVal = userInfoObj.storeId;

    let userIdVal = userInfoObj.userId;

    let tempItemListStructuredClone = structuredClone(itemList);

    let tempMaterialConsumptionDtlsDtoList = [];


    for (let tempItemListObj of tempItemListStructuredClone) {
      let requiredObj = {};


      if (tempItemListObj["Expiry Date"] !== "NA") {
        let requiredDateString = tempItemListObj["Expiry Date"];

        // new Date(year, monthIndex, day)

        let dateArray = requiredDateString.split("-");

        // ["31", "01", "2023"]

        let year = dateArray[2];

        let monthIndex = Number(dateArray[1]) - 1;

        let day = dateArray[0];

        let requiredDateObj = new Date(year, monthIndex, day);

        let dateStringInZFormat = requiredDateObj.toISOString();

        requiredObj.batchExpDate = dateStringInZFormat;
      } else {
        requiredObj.batchExpDate = "";
      }

      requiredObj.batchNo = tempItemListObj["Batch No"];
      requiredObj.itemId = tempItemListObj.ItemId;
      requiredObj.remarks = tempItemListObj.Remark;
      requiredObj.usedQty = tempItemListObj["Used Quantity"];

      tempMaterialConsumptionDtlsDtoList.push(requiredObj);
    }

    let postObj = {
      addedBy: userIdVal,
      byDeptStore: true,
      materialConsumptionDtlsDtoList: tempMaterialConsumptionDtlsDtoList,
      storeId: storeIdVal,
      privilege: "",
      menuId: 0,
    };

    if (location && location.state && location.state.menuId) {
      postObj.menuId = location.state.menuId;
    }

    let privilege = "";

    let tempUserActions = structuredClone(userActions);

    for (let tempUserActionObj of tempUserActions) {
      if (tempUserActionObj.action === "Create") {
        privilege = "Create";
        break;
      }
    }

    postObj.privilege = privilege;


    postMaterialConsumptionData(postObj)
      .then((response) => {
        successAlert(response.data.message);
        confirmationHandleClose();

        setValue("search", null);
        setValue("batchNo", "");
        setValue("expiryDate", "");
        setValue("uom", "");
        setValue("balanceQty", "");
        setValue("usedQty", "");
        setValue("remark", "");
        setError("usedQty", "");
        setItemList([]);
        setEditableDrugObj(null);
      })
      .catch((error) => {
        errorAlertCustom(error.response.data.message);
        confirmationHandleClose();
      });
  }

  //function to set the values inside the fields. These values are found inside the patched object.
  function patchTheConsumptionMaterialForm(listItemObjVal) {
    
    if (listItemObjVal !== null) {
      setValue("uom", listItemObjVal.StockingUMO);
      setValue("balanceQty", listItemObjVal.BalQty);

      let batchesRequiredFlagVal = !listItemObjVal.BatchesRequired;

      setBatchesRequiredFlag(batchesRequiredFlagVal);
    }

    if (listItemObjVal === null) {
      setBatchesRequiredFlag(false);
      setValue("batchNo", "");
      setValue("expiryDate", "");
      setValue("uom", "");
      setValue("balanceQty", "");
      setValue("usedQty", "");
      setValue("remark", "");
    }
  }

  //event listener function to add the new item in the table.
  function addConsumedItemToTheListTable() {
    let batchNoVal = getValues("batchNo");
    let expiryDateVal = getValues("expiryDate");
    let uomVal = getValues("uom");
    let balanceQtyVal = getValues("balanceQty");
    let usedQtyVal = getValues("usedQty");
    let remarkVal = getValues("remark");
    let itemNameObj = getValues("search");
    let itemNameVal = null;
    let itemIdVal = null;

    if (itemNameObj) {
      itemNameVal = itemNameObj.label;
      itemIdVal = itemNameObj.ItemId;
    }

    let usedQtyValNumber = Number(usedQtyVal);
    let balanceQtyValNumber = Number(balanceQtyVal);

    if (usedQtyValNumber > balanceQtyValNumber) {
      errorAlertCustom("Used Quantity exceeded available balance quantity.");
    }

    if (usedQtyValNumber <= balanceQtyValNumber && balanceQtyValNumber > 0) {
      //if block to add the new item to the table ; which has batchRequired flag as true.

      if (
        itemNameObj &&
        itemNameObj.BatchesRequired === true &&
        batchNoVal !== "" &&
        expiryDateVal !== "" &&
        uomVal !== "" &&
        balanceQtyVal !== "" &&
        usedQtyVal !== "" &&
        itemNameObj !== null &&
        itemNameVal !== null &&
        itemIdVal !== null &&
        editableDrugObj === null
      ) {
        let itemListObjVal = {
          "Item Name": itemNameVal,
          UOM: uomVal,
          "Batch No": batchNoVal,
          "Expiry Date": expiryDateVal,
          "Balanced Quantity": balanceQtyVal,
          "Used Quantity": usedQtyVal,
          Remark: remarkVal,
          ItemId: itemIdVal,
          BatchesRequired: true,
        };

        itemListObjVal.BatchesRequired = listItemObj.BatchesRequired;

        //code to find the duplicate in the list
        let itemListClone = structuredClone(itemList);

        //The searchResult variable contains the result of the find function.
        let searchResult = itemListClone.find(searchDuplicate);

        function searchDuplicate(singleDrugItem) {
        
          return singleDrugItem.ItemId === itemIdVal;
        }


        if (searchResult) {
          errorAlertCustom("Drug already exists in the list !");
        } else {
          itemListClone.push(itemListObjVal);
          setItemList(itemListClone);

          setBatchesRequiredFlag(false);

          setValue("search", null);
          setValue("batchNo", "");
          setValue("expiryDate", "");
          setValue("uom", "");
          setValue("balanceQty", "");
          setValue("usedQty", "");
          setValue("remark", "");
        }
      }

      //if block to add the new item to the table ; which has batchRequired flag as false.
      if (
        itemNameObj &&
        itemNameObj.BatchesRequired === false &&
        usedQtyVal !== "" &&
        editableDrugObj === null
      ) {
        let itemListObjVal = {
          "Item Name": itemNameVal,
          UOM: uomVal,
          "Batch No": "NA",
          "Expiry Date": "NA",
          "Balanced Quantity": balanceQtyVal,
          "Used Quantity": usedQtyVal,
          Remark: remarkVal,
          ItemId: itemIdVal,
          BatchesRequired: false,
        };

        itemListObjVal.BatchesRequired = listItemObj.BatchesRequired;

        //code to find the duplicate in the list
        let itemListClone = structuredClone(itemList);

        let searchResult = itemListClone.find(searchDuplicate);

        function searchDuplicate(singleDrugItem) {
          
          return singleDrugItem.ItemId === itemIdVal;
        }
        if (searchResult) {
          errorAlertCustom("Drug already exists in the list !");
        } else {
          itemListClone.push(itemListObjVal);
          setItemList(itemListClone);

          setBatchesRequiredFlag(false);

          setValue("search", null);
          setValue("batchNo", "");
          setValue("expiryDate", "");
          setValue("uom", "");
          setValue("balanceQty", "");
          setValue("usedQty", "");
          setValue("remark", "");
        }
      }
    }

    focusOnSearchItemField();
  }

  //event listener function to modify the existing row in the table
  function editConsumedItemFromTheList() {
    let batchNoVal = getValues("batchNo");
    let expiryDateVal = getValues("expiryDate");
    let uomVal = getValues("uom");
    let balanceQtyVal = getValues("balanceQty");
    let usedQtyVal = getValues("usedQty");
    let remarkVal = getValues("remark");
    let itemNameObj = getValues("search");
    let itemNameVal = null;
    let itemIdVal = null;

    if (itemNameObj) {
      itemNameVal = itemNameObj.label;
      itemIdVal = itemNameObj.ItemId;
    }


    let usedQtyValNumber = Number(usedQtyVal);
    let balanceQtyValNumber = Number(balanceQtyVal);

    if (usedQtyValNumber > balanceQtyValNumber) {
      errorAlertCustom("Used Quantity exceeded available balance quantity.");
    }

    if (usedQtyValNumber <= balanceQtyValNumber && balanceQtyValNumber > 0) {
      //if block to add the new item to the table ; which has batchRequired flag as true.

      if (
        batchNoVal !== "" &&
        expiryDateVal !== "" &&
        uomVal !== "" &&
        balanceQtyVal !== "" &&
        usedQtyVal !== "" &&
        itemNameObj !== null &&
        itemNameVal !== null &&
        itemIdVal !== null &&
        editableDrugObj !== null
      ) {
        let itemListClone = structuredClone(itemList);


        let requiredIndex = null;

        for (let index = 0; index < itemListClone.length; index++) {
          if (itemListClone[index].ItemId === itemIdVal) {
            requiredIndex = index;
          }
        }

        let itemListObjVal = {
          "Item Name": itemNameVal,
          UOM: uomVal,
          "Batch No": batchNoVal,
          "Expiry Date": expiryDateVal,
          "Balanced Quantity": balanceQtyVal,
          "Used Quantity": usedQtyVal,
          Remark: remarkVal,
          ItemId: itemIdVal,
          BatchesRequired: true,
        };

        itemListClone[requiredIndex] = itemListObjVal;

        //update the state variable to update the UI.
        setItemList(itemListClone);

        //Set the editableDrugObj state variable to null. So that , next time the table can be either in edit mode or add mode.
        setEditableDrugObj(null);

        setValue("search", null);
        setValue("batchNo", "");
        setValue("expiryDate", "");
        setValue("uom", "");
        setValue("balanceQty", "");
        setValue("usedQty", "");
        setValue("remark", "");
      }
    }

    // If the editableDrugObj is not null
    // clear the editableDrugObj to null when the itemList is modified
    // modify that item which was added by patching through the batch information modal.
    if (
      itemNameObj &&
      itemNameObj.BatchesRequired === false &&
      usedQtyVal !== "" &&
      editableDrugObj !== null
    ) {
      let itemListClone = structuredClone(itemList);


      let requiredIndex = null;

      for (let index = 0; index < itemListClone.length; index++) {
        if (itemListClone[index].ItemId === itemIdVal) {
          requiredIndex = index;
        }
      }

      let itemListObjVal = {
        "Item Name": itemNameVal,
        UOM: uomVal,
        "Batch No": "NA",
        "Expiry Date": "NA",
        "Balanced Quantity": balanceQtyVal,
        "Used Quantity": usedQtyVal,
        Remark: remarkVal,
        ItemId: itemIdVal,
        BatchesRequired: false,
      };

      itemListClone[requiredIndex] = itemListObjVal;
      setItemList(itemListClone);
      setEditableDrugObj(null);

      setValue("search", null);
      setValue("batchNo", "");
      setValue("expiryDate", "");
      setValue("uom", "");
      setValue("balanceQty", "");
      setValue("usedQty", "");
      setValue("remark", "");
    }
  }

  //event listener function to delete the particular row in the table.
  function deleteFromConsumedItemListTable(drugItemObj) {

    let itemIdKey = drugItemObj.ItemId;
    let tempDrugItemList = structuredClone(itemList);

    let updatedDrugItemList = [];

    for (let tempDrugItemObj of tempDrugItemList) {

      if (tempDrugItemObj.ItemId !== itemIdKey) {
        updatedDrugItemList.push(tempDrugItemObj);
      }
    }

    setItemList(updatedDrugItemList);
  }

  const batchNoInputField = React.useRef("");

  const usedQtyInputField = React.useRef("");

  const addButtonAddress = React.useRef("");

  const searchItemAddress = React.useRef("");

  function focusOnBatchNoInputField() {
    let requiredBatchNoDivElement = batchNoInputField.current;

    let inputTagCollection =
      requiredBatchNoDivElement.getElementsByTagName("input");

    let batchNoInputTag = inputTagCollection[0];

    batchNoInputTag.focus();

    //below piece of code is to open the batchInfoModal
    let searchObj = getValues("search");
    let storeObj = getValues("storedepartment");

    if (searchObj && storeObj) {
      //open the BatchInfoModal
      setOpenItemBatchInfoModal(true);

      let itemIdVal = searchObj.ItemId;
      let storeIdVal = storeObj.id;

      let batchExpDateVal = new Date();

      let itemBatchPostObj = {
        isConsignment: 0,
        itemId: itemIdVal,
        storeId: storeIdVal,
        batchExpDate: batchExpDateVal,
      };

      itemBatchNumber(itemBatchPostObj).then((response) => {

        if (response.data.result.length > 0) {
          setItemBatchesArray(response.data.result);
        }

        if (response.data.result.length === 0) {
          errorAlertCustom("Batch items are not available !.");
        }
      });
    }
  }

  function focusOnUsedQtyInputField() {
    let requiredUsedQtyDivElement = usedQtyInputField.current;

    let inputTagCollection =
      requiredUsedQtyDivElement.getElementsByTagName("input");

    let usedQtyInputTag = inputTagCollection[0];

    usedQtyInputTag.focus();
  }

  function focusOnAddButton() {
    let requiredAddButtonDivElement = addButtonAddress.current;


    let buttonTagCollection =
      requiredAddButtonDivElement.getElementsByTagName("button");


    let addButtonTag = buttonTagCollection[1];
    addButtonTag.focus();
    addButtonClickEventHandler();
  }

  //function to focus on Search Item searchdropdown.
  function focusOnSearchItemField() {
    let requiredSearchItemDivElement = searchItemAddress.current;


    let inputTagCollectionForSearch =
      requiredSearchItemDivElement.getElementsByTagName("input");


    inputTagCollectionForSearch[0].focus();
  }

  function addButtonClickEventHandler() {
    if (editableDrugObj === null) {
      addConsumedItemToTheListTable();
    } else if (editableDrugObj !== null) {
      editConsumedItemFromTheList();
    }
  }

  //event listener function for the onKeydown event of the BatchNo. InputField.
  function handleOnKeydownForBatchNoField(e) {
    e.preventDefault();
    if (e.key === "Enter") {
      let searchObj = getValues("search");
      let storeObj = getValues("storedepartment");


      if (searchObj && storeObj) {
        //open the BatchInfoModal
        setOpenItemBatchInfoModal(true);
        let itemIdVal = searchObj.ItemId;
        let storeIdVal = storeObj.id;


        let batchExpDateVal = new Date();

        let itemBatchPostObj = {
          isConsignment: 0,
          itemId: itemIdVal,
          storeId: storeIdVal,
          batchExpDate: batchExpDateVal,
        };

        itemBatchNumber(itemBatchPostObj).then((response) => {

          if (response.data.result.length > 0) {
            setItemBatchesArray(response.data.result);
          }

          if (response.data.result.length === 0) {
            errorAlertCustom("Batch items are not available !.");
          }
        });
      }

      if (searchObj === null) {
        errorAlertCustom("Please search the item");
      }
      if (storeObj === null) {
        errorAlertCustom("Please select store/department name");
      }
    }
  }

  return (
    <>
      <div className="mt-12 space-y-2">
        <div className="py-2">
          <p className="text-center text-xl text-gray-700 font-Poppins">
            Material Consumption
          </p>
        </div>

        <form>
          <div className="grid grid-cols-2 justify-between gap-2 my-2">
            <div className="flex gap-5">
              <div className="lg:w-72 xl:w-72">
                <DropdownField
                  control={control}
                  name="storedepartment"
                  label="Store / Department"
                  placeholder="Store / Department"
                  isDisabled={true}
                />
              </div>
            </div>
            <div className="flex justify-end">
              {userActions &&
                userActions.map((singleActionObj) => {
                  if (
                    singleActionObj.isAction === false &&
                    singleActionObj.action === "Previous Consumption List"
                  ) {
                    return (
                      <>
                        <button
                          className="h-9 px-4  bg-blue-900 rounded text-white"
                          onClick={handleOpen}
                          type="button"
                        >
                          Previous Consumption List
                        </button>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <hr className="w-full my-2 border-t-2 border-customBlue" />

          <div className="flex flex-col-reverse lg:flex-row lg:space-x-5 xl:space-x-3 w-full my-2 ">
            <div className="lg:w-[100%] xl:w-[80%] border rounded bg-white p-2  mt-2 lg:mt-0 lg:pb-0 lg:bg-transparent lg:border-none">
              <p className="text-center text-xl text-gray-700 font-Poppins">
                Material Consumption Table
              </p>
              <div className="pt-2">
                <ConsumedItemListTable
                  setBatchesRequiredFlag={setBatchesRequiredFlag}
                  dataArray={itemList}
                  actions={actions}
                  setEditableDrugObj={setEditableDrugObj}
                  deleteFromConsumedItemListTable={
                    deleteFromConsumedItemListTable
                  }
                  setConfirmationOpen={setConfirmationOpen}
                  userActions={userActions}
                />
              </div>
            </div>

            <Divider
              sx={{ color: "#0B83A5", borderRightWidth: 2 }}
              orientation="vertical"
              flexItem
            />

            <div className="lg:w-[50%] xl:w-[35%] bg-white rounded border p-2">
              <div>
                <div className=" z-80 my-2 w-full " ref={searchItemAddress}>
                  <SearchDropdown
                    control={control}
                    name="search"
                    label="Search Item"
                    isClearable={true}
                    placeholder="Search Item"
                    isSearchable={true}
                    searchIcon={true}
                    handleInputChange={(e) => {
                    
                      setItemSearchString(e);
                    }}
                    inputRef={{
                      ...register("search", {
                        onChange: (e) => {
                          let listItemObjVal = e.target.value;

                         
                          if (listItemObjVal) {
                         

                            if (listItemObjVal.BatchesRequired === true) {
                              focusOnBatchNoInputField();
                            } else if (
                              listItemObjVal.BatchesRequired === false
                            ) {
                              focusOnUsedQtyInputField();
                            }
                          }

                          setListItemObj(listItemObjVal);

                          patchTheConsumptionMaterialForm(listItemObjVal);
                        },
                      }),
                    }}
                    dataArray={searchItemSuggestions}
                  />
                </div>

                <div className=" grid grid-cols-3 lg:grid-cols-2 gap-2 mt-1">
                  <div ref={batchNoInputField}>
                    <InputEnter
                      control={control}
                      name="batchNo"
                      label="Batch No."
                      placeholder="Batch No."
                      variant="outlined"
                      disabled={batchesRequiredFlag}
                      onKeyDown={(e) => {
                        handleOnKeydownForBatchNoField(e);
                      }}
                    />
                  </div>

                  <div className="w-full ">
                    <InputField
                      control={control}
                      name="expiryDate"
                      label="Expiry Date"
                      placeholder="Expiry Date"
                      disabled={true}
                    />
                  </div>

                  <div>
                    <InputField
                      control={control}
                      name="uom"
                      label="UOM"
                      placeholder="UOM"
                      disabled={listItemObj !== null ? true : false}
                    />
                  </div>

                  <div>
                    <InputField
                      control={control}
                      name="balanceQty"
                      label="Balance Qty."
                      placeholder="Balance Qty."
                      type="number"
                      disabled={true}
                    />
                  </div>

                  <div ref={usedQtyInputField}>
                    <InputField
                      control={control}
                      name="usedQty"
                      type="number"
                      label="Used Qty."
                      placeholder="Used Qty."
                      error={errors.usedQty}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          let balanceQtyVal = Number(getValues("balanceQty"));

                          let usedQtyVal = Number(e.target.value);

                          if (usedQtyVal !== 0 && usedQtyVal <= balanceQtyVal) {
                            focusOnAddButton();
                          }
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 py-2">
                  <div>
                    <InputField
                      control={control}
                      name="remark"
                      label="Remark"
                      placeholder="Remark"
                    />
                  </div>
                </div>

                <div
                  className="flex space-x-2 justify-end col-span-3 lg:col-span-1 py-2 mt-7"
                  ref={addButtonAddress}
                >
                  {Actions.map((singleActionObj) => {
                    if (
                      singleActionObj.action === "Add" &&
                      singleActionObj.isAction === false
                    ) {
                      return (
                        <>
                          <CommonButton
                            label="Reset"
                            className="border border-customRed text-customRed"
                            onClick={() => {
                              setValue("search", null);
                              setValue("batchNo", "");
                              setValue("expiryDate", "");
                              setValue("uom", "");
                              setValue("balanceQty", "");
                              setValue("usedQty", "");
                              setValue("remark", "");
                              setError("usedQty", "");
                              setItemList([]);
                              setEditableDrugObj(null);
                            }}
                          />

                          <CommonButton
                            label="Add"
                            className="bg-customGreen text-white"
                            onClick={() => {
                              addButtonClickEventHandler();
                            }}
                          />
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>

        {openModal ? (
          <InventoryMaterialConsumptionModal
            open={openModal}
            close={handleClose}
          />
        ) : null}

        {itemBatchesArray.length > 0 ? (
          <ItemBatchInfoModal
            open={openItemBatchInfoModal}
            setOpen={setOpenItemBatchInfoModal}
            itemBatchesArray={itemBatchesArray}
            setItemBatchesArray={setItemBatchesArray}
            setBatchItemInfoObj={setBatchItemInfoObj}
          />
        ) : null}
      </div>

      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={confirmationHandleClose}
        confirmationSubmitFunc={() => {
          saveMaterialConsumptionData();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Material Consumption Details?"
        confirmationButtonMsg="Save"
      />
    </>
  );
}
export default InventoryMaterialConsumption;
