import { yupResolver } from "@hookform/resolvers/yup";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
  tooltipClasses
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import SubmitButton from "../../common/components/Buttons/SubmitButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DropdownField from "../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert
} from "../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../commonServices/functionalityServices/FunctionalityServices";
import {
  getRolesAutocomplete,
  getUserFuctionalitiesByRole,
  saveRoleFunctionalities,
} from "../Services/UserManagementServices";
import "./AssignFunctionality.css";
import { CustomAntSwitch } from "./CustomAntSwitch";
import CustomCheckbox from "./CustomCheckbox";

function AssignFuntionalities() {
  const [functionalities, setFunctionalities] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [selectedModule, setSelectedModule] = React.useState("");
  const [userRoles, setUserRoles] = React.useState([]);
  const [selectedFunctionality, setSelectedFunctionality] = React.useState("");
  const [showSubModules, setShowSubModules] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [dataToArrange, setDataToArrange] = React.useState([]);
  const [toolIndex, setToolIndex] = React.useState(-1);
  const [userActions, setUserActions] = React.useState([]);

  let location = useLocation();

  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  const handleCloseChild = () => {
    if (openChild === true) {
      setOpenChild(false);
    }
  };
  const schema = yup.object().shape({
    // role: yup
    //   .object()
    //   .nullable()
    //   .shape({
    //     label: yup.string().required("Select units"),
    //     value: yup.string().required("Select units"),
    //   })
    //   .required("Select units"),
  });

  const defaultValues = {
    role: null,
  };

  const {
    watch,
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
    register,
    handleSubmit,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  //function to get user roles autoComplete
  React.useEffect(() => {
    getRolesAutocomplete()
      .then((response) => response.data)
      .then((res) => {
        setUserRoles(res.result);
      });
    setValue("mainAnt", true);
  }, []);

  let roleWatch = watch("role");

  //useEffect to get the user fuctionalities using roleId
  React.useEffect(() => {
    if (roleWatch !== null) {
      setShowSpinner(true);
      getUserFuctionalitiesByRole(roleWatch?.id)
        .then((response) => response.data)
        .then((res) => {
          setFunctionalities(res.result);
          setShowSpinner(false);
        })
        .catch((error) => {
          if (error) {
            setShowSpinner(false);
          }
        });
    } else {
      setShowSpinner(false);
      setFunctionalities([]);
    }
  }, [roleWatch]);

  //useEffect for settting value to the checkboxes depending on changes
  React.useEffect(() => {
    if (selectedModule.length > 0) {
      let subFunctionOne = selectedModule;
      for (let object of subFunctionOne) {
        setValue(
          `Module${object.functionality + selectedFunctionality + object.id}`,
          object.isChecked
        );

        //if there are permissions
        if (object["permissions"]) {
          let permissionOne = object["permissions"];
          for (let permissionObject of permissionOne) {
            setValue(
              `Module${
                permissionObject.permission + selectedFunctionality + object.id
              }`,
              permissionObject.isChecked
            );
            setValue(
              `Switch${
                permissionObject.permission + selectedFunctionality + object.id
              }`,
              permissionObject.isAction
            );
          }
        }

        //level two
        if (object["subFunction"]) {
          let subFunctionTwo = object["subFunction"];
          for (let object of subFunctionTwo) {
            setValue(
              `Module${
                object.functionality + selectedFunctionality + object.id
              }`,
              object.isChecked
            );

            //if there are permissions
            if (object["permissions"]) {
              let permissionTwo = object["permissions"];
              for (let permissionObject of permissionTwo) {
                setValue(
                  `Module${
                    permissionObject.permission +
                    selectedFunctionality +
                    object.id
                  }`,
                  permissionObject.isChecked
                );
                setValue(
                  `Switch${
                    permissionObject.permission +
                    selectedFunctionality +
                    object.id
                  }`,
                  permissionObject.isAction
                );
              }
            }

            //level Three
            if (object["subFunction"]) {
              let subFunctionThree = object["subFunction"];
              for (let object of subFunctionThree) {
                setValue(
                  `Module${
                    object.functionality + selectedFunctionality + object.id
                  }`,
                  object.isChecked
                );

                //if there are permissions
                if (object["permissions"]) {
                  let permissionThree = object["permissions"];
                  for (let permissionObject of permissionThree) {
                    setValue(
                      `Module${
                        permissionObject.permission +
                        selectedFunctionality +
                        object.id
                      }`,
                      permissionObject.isChecked
                    );
                  }
                }
              }
            }
          }
        }
        setShowSubModules(true);
      }
    }
  }, [selectedModule]);

  //useEffect to handle isChecked = false values if all the flags are false.
  React.useEffect(() => {
    let dataArray = [...functionalities];
    //######### handeling isChecked flag if all the values at this level are false then making parent false

    let falseArray = [];

    if (selectedModule?.length > 0) {
      for (let obj of selectedModule) {
        if (obj.isChecked === false) {
          falseArray.push(obj);
        }
      }
      if (falseArray.length === selectedModule?.length) {
        setValue(`Module${selectedFunctionality}`, false);
        dataArray[selectedIndex].isChecked = false;
      }
    }

    //################
  }, [functionalities]);

  //function to select the subFunctions for mapping
  const handleSelectModule = (item, itemIndex) => {
    if (item["subFunction"]) {
      setSelectedFunctionality(item.functionality);
      setSelectedModule(item.subFunction);
    } else {
      setSelectedModule([]);
      setSelectedFunctionality("");
    }
  };

  //function to allow or do not allow main module
  const handleMainModuleChange = (e, item, itemIndex) => {
    if (item["subFunction"]) {
      setSelectedModule(item["subFunction"]);
      setSelectedFunctionality(item.functionality);
    }
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      dataArray[selectedIndex].isChecked = true;

      //level one
      if (dataArray[selectedIndex]["subFunction"]) {
        let subFunctionOne = dataArray[selectedIndex]["subFunction"];
        for (let object of subFunctionOne) {
          object.isChecked = true;

          //if there are permissions
          if (object["permissions"]) {
            let permissionOne = object["permissions"];
            for (let permissionObject of permissionOne) {
              permissionObject.isChecked = true;
            }
          }

          //level two
          if (object["subFunction"]) {
            let subFunctionTwo = object["subFunction"];
            for (let object of subFunctionTwo) {
              object.isChecked = true;

              //if there are permissions
              if (object["permissions"]) {
                let permissionTwo = object["permissions"];
                for (let permissionObject of permissionTwo) {
                  permissionObject.isChecked = true;
                }
              }

              //level Three
              if (object["subFunction"]) {
                let subFunctionThree = object["subFunction"];
                for (let object of subFunctionThree) {
                  object.isChecked = true;

                  //if there are permissions
                  if (object["permissions"]) {
                    let permissionThree = object["permissions"];
                    for (let permissionObject of permissionThree) {
                      permissionObject.isChecked = true;
                    }
                  }
                }
              }
            }
          }
        }
      }

      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      dataArray[selectedIndex].isChecked = false;

      //level one
      if (dataArray[selectedIndex]["subFunction"]) {
        let subFunctionOne = dataArray[selectedIndex]["subFunction"];
        for (let object of subFunctionOne) {
          object.isChecked = false;

          //if there are permissions
          if (object["permissions"]) {
            let permissionOne = object["permissions"];
            for (let permissionObject of permissionOne) {
              permissionObject.isChecked = false;
            }
          }

          //level two
          if (object["subFunction"]) {
            let subFunctionTwo = object["subFunction"];
            for (let object of subFunctionTwo) {
              object.isChecked = false;

              //if there are permissions
              if (object["permissions"]) {
                let permissionTwo = object["permissions"];
                for (let permissionObject of permissionTwo) {
                  permissionObject.isChecked = false;
                }
              }

              //level Three
              if (object["subFunction"]) {
                let subFunctionThree = object["subFunction"];
                for (let object of subFunctionThree) {
                  object.isChecked = false;

                  //if there are permissions
                  if (object["permissions"]) {
                    let permissionThree = object["permissions"];
                    for (let permissionObject of permissionThree) {
                      permissionObject.isChecked = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
      setFunctionalities(dataArray);
    }
  };

  //fuction to allow or do not allow subFunction
  const handleSubFunctionChange = (e, subItem, subItemIndex) => {
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;
      dataArray[selectedIndex]["subFunction"][subItemIndex].isChecked = true;

      //permission level one
      if (
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"]
      ) {
        let permissionsOne =
          dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"];
        for (let permissionObject of permissionsOne) {
          permissionObject.isChecked = true;
        }
      }

      //level one
      let subFunctionOne =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"];
      if (subFunctionOne) {
        for (let object of subFunctionOne) {
          object.isChecked = true;

          //permission level two
          if (object["permissions"]) {
            let permissionsTwo = object["permissions"];
            for (let permissionObject of permissionsTwo) {
              permissionObject.isChecked = true;
            }
          }

          // level two
          let subFunctionTwo = object["subFunction"];
          if (subFunctionTwo) {
            for (let object of subFunctionTwo) {
              object.isChecked = true;
            }
          }
        }
      }

      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];

      dataArray[selectedIndex]["subFunction"][subItemIndex].isChecked = false;

      //permission level one
      if (
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"]
      ) {
        let permissionsOne =
          dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"];
        for (let permissionObject of permissionsOne) {
          permissionObject.isChecked = false;
        }
      }

      //level one
      let subFunctionOne =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"];
      if (subFunctionOne) {
        for (let object of subFunctionOne) {
          object.isChecked = false;

          //permission level two
          if (object["permissions"]) {
            let permissionsTwo = object["permissions"];
            for (let permissionObject of permissionsTwo) {
              permissionObject.isChecked = false;
            }
          }

          // level two
          let subFunctionTwo = object["subFunction"];
          if (subFunctionTwo) {
            for (let object of subFunctionTwo) {
              object.isChecked = false;
            }
          }
        }
      }
      setFunctionalities(dataArray);
    }
  };

  //function to handle main module permissions change
  const handleMainPermissionsChange = (
    e,
    mainPermission,
    subItemIndex,
    mainPermissionIndex
  ) => {
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;
      let mainPermission =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"][
          mainPermissionIndex
        ];
      mainPermission.isChecked = true;

      let parent = dataArray[selectedIndex]["subFunction"][subItemIndex];
      parent.isChecked = true;
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      let mainPermission =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"][
          mainPermissionIndex
        ];
      mainPermission.isChecked = false;

      let falseArray = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falseArray.push(obj);
          }
        }
        if (falseArray.length === selected.length) {
          let parent = dataArray[selectedIndex]["subFunction"][subItemIndex];
          parent.isChecked = false;
        }
      }

      setFunctionalities(dataArray);
    }
  };

  //function to allow or do not allow subFunctionTwo
  const handleSubFunctionTwo = (
    e,
    subItemTwo,
    subItemIndex,
    subItemTwoIndex
  ) => {
    let dataArray = [...functionalities];

    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;

      let parent = dataArray[selectedIndex]["subFunction"][subItemIndex];
      parent.isChecked = true;

      let subFunction =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ];
      subFunction.isChecked = true;
      if (subFunction["permissions"]) {
        let permissionOne = subFunction["permissions"];
        for (let permissionObject of permissionOne) {
          permissionObject.isChecked = true;
        }
      }

      //handling permissions in subFunction
      if (subFunction["subFunction"]) {
        let functionsTwo = subFunction["subFunction"];
        if (functionsTwo) {
          for (let functionObject of functionsTwo) {
            functionObject.isChecked = true;
          }
        }
      }
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];

      let falseArray = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falseArray.push(obj);
          }
        }
        if (falseArray?.length + 1 === selected.length) {
          let falseObject =
            dataArray[selectedIndex]["subFunction"][subItemIndex];
          falseObject.isChecked = false;
        }
      }

      let subFunction =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ];
      subFunction.isChecked = false;
      if (subFunction["permissions"]) {
        let permissionOne = subFunction["permissions"];
        for (let permissionObject of permissionOne) {
          permissionObject.isChecked = false;
        }
      }
      if (subFunction["subFunction"]) {
        let functionsTwo = subFunction["subFunction"];
        if (functionsTwo) {
          for (let functionObject of functionsTwo) {
            functionObject.isChecked = false;
          }
        }
      }
      setFunctionalities(dataArray);
    }
  };

  const handleSubItemThree = (
    e,
    subItemIndex,
    subItemTwoIndex,
    subItemThreeIndex
  ) => {
    let dataArray = [...functionalities];
    let subFunctionThreeParent =
      dataArray[selectedIndex]["subFunction"][subItemTwoIndex]["subFunction"][
        subItemThreeIndex
      ];
    let parentTwo = dataArray[selectedIndex]["subFunction"][subItemTwoIndex];
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      let subFunctionThree =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["subFunction"][subItemThreeIndex];

      subFunctionThree.isChecked = true;
      //parent
      subFunctionThreeParent.isChecked = true;
      //subParent
      parentTwo.isChecked = true;
      //main module
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      let subFunctionThree =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["subFunction"][subItemThreeIndex];
      subFunctionThree.isChecked = false;

      let falseArray = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["subFunction"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falseArray.push(obj);
          }
        }
        if (falseArray?.length === selected?.length) {
          subFunctionThree.isChecked = false;
          //parent
          subFunctionThreeParent.isChecked = false;
          //subParent
          parentTwo.isChecked = false;
          //main module
          setValue(`Module${selectedFunctionality}`, false);
          dataArray[selectedIndex].isChecked = false;
        }
      }

      setFunctionalities(dataArray);
    }
  };
  //function to handle permission selection change
  const handlePermissionChange = (
    e,
    subItemIndex,
    subItemTwoIndex,
    subPermissionIndex
  ) => {
    let countOfFlase = [];
    if (e.target.checked === true) {
      //setting isChecked=true for permissions
      let dataArray = [...functionalities];

      let firstParent =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ];

      firstParent.isChecked = true;

      let secondParent = dataArray[selectedIndex]["subFunction"][subItemIndex];
      secondParent.isChecked = true;
      setValue(`Module${selectedFunctionality}`, true);
      dataArray[selectedIndex].isChecked = true;

      let permissions =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"];
      if (permissions) {
        permissions[subPermissionIndex].isChecked = true;
      }
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      //setting isChecked=false for permissions
      let dataArray = [...functionalities];
      let permissions =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"];
      if (permissions) {
        permissions[subPermissionIndex].isChecked = false;
      }

      let falsePermissions = [];
      let selected =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"];
      if (selected?.length > 0) {
        for (let obj of selected) {
          if (obj.isChecked === false) {
            falsePermissions.push(obj);
          }
        }

        //handle false flag if all the permissions are false
        if (falsePermissions?.length === selected?.length) {
          let firstParent =
            dataArray[selectedIndex]["subFunction"][subItemIndex][
              "subFunction"
            ][subItemTwoIndex];
          let secondParent =
            dataArray[selectedIndex]["subFunction"][subItemIndex];
          firstParent.isChecked = false;
          secondParent.isChecked = false;
          setValue(`Module${selectedFunctionality}`, true);
          dataArray[selectedIndex].isChecked = true;
        }
      }

      setFunctionalities(dataArray);
    }
  };

  const onSubmitDataHandler = (data) => {
    setOpenChild(true);
  };

  const saveFunctionalities = () => {
    let postObject = {
      functionalities: functionalities,
      role: {
        id: roleWatch?.id,
      },
    };
    if (postObject) {
      setOpenBackdrop(true);
      setOpenChild(false);
      saveRoleFunctionalities(postObject)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          errorAlert(error.message);
          setOpenBackdrop(false);
        });
    }
  };

  // function to handle top permission isAction flag
  const handleFirstSwitch = (
    e,
    mainPermission,
    subItemIndex,
    mainPermissionIndex
  ) => {
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      let switchObject =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"][
          mainPermissionIndex
        ];
      switchObject.isAction = true;

      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      let switchObject =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["permissions"][
          mainPermissionIndex
        ];
      switchObject.isAction = false;
      setFunctionalities(dataArray);
    }
  };

  // function to handle second permission isAction flag
  const handleSecondSwitch = (
    e,
    subPermission,
    subItemIndex,
    subItemTwoIndex,
    subPermissionIndex
  ) => {
    if (e.target.checked === true) {
      let dataArray = [...functionalities];
      let switchObject =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"][subPermissionIndex];
      switchObject.isAction = true;
      setFunctionalities(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...functionalities];
      let switchObject =
        dataArray[selectedIndex]["subFunction"][subItemIndex]["subFunction"][
          subItemTwoIndex
        ]["permissions"][subPermissionIndex];
      switchObject.isAction = false;
      setFunctionalities(dataArray);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      placement="right"
      open={openTooltip}
      // onClose={setOpenTooltip(false)}
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      borderColor: openTooltip ? "gray" : "",
      border: openTooltip ? 0.5 : 0,
      color: "black",
      width: 330,
      fontSize: theme.typography.pxToRem(13),
      // border: "1px solid #7c7978",
    },
  }));

  const checkRepeat = (event) => {
    const inputValue = event.target.value;
    let hasThreeConsecutive = false;

    if (inputValue.length >= 3) {
      for (let i = 2; i < inputValue.length; i++) {
        if (
          inputValue[i] === inputValue[i - 1] &&
          inputValue[i] === inputValue[i - 2]
        ) {
          hasThreeConsecutive = true;
          break;
        }
      }
    }
    return hasThreeConsecutive;
  };

  return (
    <form onSubmit={handleSubmit(onSubmitDataHandler)}>
      <div className="mt-16">
        <label className="flex justify-center font-semibold text-lg my-4">
          Assign Fuctionalities To Role
        </label>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <label className="font-semibold ">Select Role :</label>
          </Grid>
          <Grid item lg={3} sm={6}>
            <DropdownField
              control={control}
              name="role"
              label="Role*"
              placeholder="Role*"
              isSearchable={true}
              isClearable={true}
              searchIcon={true}
              dataArray={userRoles}
            />
          </Grid>
          {/* <input type="text" className="w-32 border border-gray-400" onChange={checkRepeat}/> */}
          {functionalities.length > 0 ? (
            <Grid item lg={7} sm={7}>
              <div className="flex justify-end gap-2 ml-4 items-center">
                <SubmitButton />
              </div>
            </Grid>
          ) : (
            ""
          )}
          {/* {userActions?.length > 0
            ? userActions.map((actions) => {
                !actions.isAction &&
                actions.action === "Create" &&
                functionalities.length > 0 ? (
                  <Grid item lg={7} sm={7}>
                    <div className="flex justify-end gap-2 ml-4 items-center">
                      <SubmitButton />
                    </div>
                  </Grid>
                ) : (
                  ""
                );
              })
            : ""} */}
        </Grid>
        <div className="flex justify-center">
          {showSpinner ? <LoadingSpinner /> : ""}
        </div>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item lg={12} sm={12}>
            {functionalities.length > 0 ? (
              <div className="flex">
                <div className="w-[17%] px-6 rounded ml-[1%] overflow-scroll max-h-[500px] bg-white">
                  {functionalities.length > 0
                    ? functionalities.map((item, itemIndex) => {
                        setValue(
                          `Module${item.functionality + selectedFunctionality}`,
                          item?.isChecked
                        );

                        return (
                          <div
                            className={`${
                              itemIndex === selectedIndex
                                ? `bg-yellow-400 rounded p-1 my-1 px-2 flex text-sm items-center  font-bold`
                                : `rounded  my-2 px-2 flex text-sm items-center `
                            }?`}
                            onClick={() => {
                              setSelectedIndex(itemIndex);
                              handleSelectModule(item, itemIndex);
                            }}
                          >
                            <fieldset
                              onChange={(e) => {
                                handleMainModuleChange(e, item, itemIndex);
                              }}
                            >
                              <CustomCheckbox
                                control={control}
                                name={`Module${item.functionality}`}
                                defaultValue={item?.isChecked}
                              />
                            </fieldset>
                            <span> {item?.functionality}</span>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{
                    border: 0.5,
                    marginX: 2,
                    height: "500px",
                    borderColor: "#c8c9cc",
                  }}
                />
                <div
                  className={`${
                    selectedModule["subFuction"]?.length > 0
                      ? `w-[75%] rounded max-h-[500px] overflow-scroll`
                      : `w-[75%] rounded max-h-[500px] overflow-scroll`
                  }`}
                >
                  {selectedModule.length > 0 && selectedModule.length > 0 ? (
                    selectedModule.map((subItem, subItemIndex) => {
                      if (selectedModule?.length > 0) {
                        setValue(
                          `Module${
                            subItem.funtionality +
                            selectedFunctionality +
                            subItem.id
                          }`,
                          subItem?.isChecked
                        );
                      }
                      return (
                        <div className="p-2">
                          <fieldset
                            onChange={(e) => {
                              handleSubFunctionChange(e, subItem, subItemIndex);
                            }}
                            className="flex items-center gap-2"
                          >
                            <div>
                              <CustomCheckbox
                                isFunction={true}
                                control={control}
                                name={`Module${
                                  subItem.funtionality +
                                  selectedFunctionality +
                                  subItem.id
                                }`}
                                label={subItem.functionality}
                              />
                            </div>
                            {subItem["permissions"] &&
                            subItem["permissions"]?.length > 1 ? (
                              <div>
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      {subItem["permissions"]?.length > 1 &&
                                      toolIndex === subItemIndex
                                        ? subItem["permissions"].map(
                                            (permission, permissionIndex) => {
                                              return (
                                                <>
                                                  <div className="grid px-2 ">
                                                    <div className="flex gap-2 items-center text-md">
                                                      <div className="w-[70%]">
                                                        {permission?.permission}
                                                      </div>
                                                      <div>
                                                        <button
                                                          className={
                                                            permissionIndex > 0
                                                              ? "bg-white h-[20px] w-[20px] mx-2 my-2 visible justify-end rounded"
                                                              : "invisible ml-3 mx-2"
                                                          }
                                                        >
                                                          <ArrowUpwardIcon
                                                            fontSize="small"
                                                            className="text-customBlue"
                                                          />
                                                        </button>
                                                        <button
                                                          className={
                                                            permissionIndex <
                                                            subItem[
                                                              "permissions"
                                                            ]?.length -
                                                              1
                                                              ? "bg-white h-[20px] w-[20px] my-2 visible justify-end rounded-md"
                                                              : "invisible w-[20px] ml-[12px]"
                                                          }
                                                        >
                                                          <ArrowDownwardIcon
                                                            fontSize="small"
                                                            className="text-customOrange"
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )
                                        : ""}
                                    </React.Fragment>
                                  }
                                >
                                  <button
                                    className={`px-2 h-7 mb-2 ${
                                      toolIndex === subItemIndex && openTooltip
                                        ? `bg-sky-100 border border-gray-400 rounded-md`
                                        : ``
                                    }`}
                                    onClick={() => {
                                      setOpenTooltip(!openTooltip);
                                      if (
                                        subItem["permissions"] &&
                                        subItem["permissions"]?.length > 1
                                      ) {
                                        setToolIndex(subItemIndex);
                                        setDataToArrange(
                                          subItem["permissions"]
                                        );
                                      }
                                    }}
                                  >
                                    <SwapVertIcon fontSize="medium" />
                                  </button>
                                </HtmlTooltip>
                              </div>
                            ) : (
                              ""
                            )}
                          </fieldset>
                          <div className="grid gap-2 ">
                            {subItem["permissions"] ? (
                              <div className="grid grid-cols-4 bg-[#F1F1F1] rounded border border-gray-400 p-1 px-2 mx-[2.5%]">
                                {subItem["permissions"].map(
                                  (mainPermission, mainPermissionIndex) => {
                                    setValue(
                                      `Module${
                                        mainPermission.permission +
                                        selectedFunctionality +
                                        subItem.id
                                      }`,
                                      mainPermission.isChecked
                                    );
                                    setValue(
                                      `Switch${
                                        mainPermission.permission +
                                        selectedFunctionality +
                                        subItem.id
                                      }`,
                                      mainPermission.isAction
                                    );
                                    return (
                                      <fieldset className="flex gap-3 items-center">
                                        {mainPermissionIndex % 4 !== 0 ? (
                                          <span className="h-6 border border-r-1 border-[#42b4f1] mr-2 ml-2"></span>
                                        ) : (
                                          ""
                                        )}
                                        <fieldset
                                          onChange={(e) => {
                                            handleMainPermissionsChange(
                                              e,
                                              mainPermission,
                                              subItemIndex,
                                              mainPermissionIndex
                                            );
                                          }}
                                          className="w-[60%]"
                                        >
                                          <CustomCheckbox
                                            control={control}
                                            name={`Module${
                                              mainPermission.permission +
                                              selectedFunctionality +
                                              subItem.id
                                            }`}
                                            label={mainPermission.permission}
                                          />
                                        </fieldset>

                                        <fieldset
                                          // disabled={
                                          //   !functionalities[selectedIndex]?.[
                                          //     "subFunction"
                                          //   ]?.[subItemIndex]["permissions"][
                                          //     mainPermissionIndex
                                          //   ]?.isChecked
                                          // }
                                          onChange={(e) => {
                                            handleFirstSwitch(
                                              e,
                                              mainPermission,
                                              subItemIndex,
                                              mainPermissionIndex
                                            );
                                          }}
                                        >
                                          <Controller
                                            name={`Switch${
                                              mainPermission.permission +
                                              selectedFunctionality +
                                              subItem.id
                                            }`}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field: { value } }) => (
                                              <CustomAntSwitch
                                                defaultChecked={value}
                                              />
                                            )}
                                          />
                                        </fieldset>
                                      </fieldset>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {subItem["subFunction"] ? (
                              subItem.subFunction.map(
                                (subItemTwo, subItemTwoIndex) => {
                                  if (selectedModule?.length > 0) {
                                    setValue(
                                      `Module${
                                        subItemTwo.functionality +
                                        selectedFunctionality +
                                        subItemTwo.id
                                      }`,
                                      subItemTwo.isChecked
                                    );
                                  }
                                  return (
                                    <>
                                      <div className="bg-[#F1F1F1] rounded border border-gray-400 p-1 px-2 mx-[2.5%]">
                                        <fieldset
                                          onChange={(e) => {
                                            handleSubFunctionTwo(
                                              e,
                                              subItemTwo,
                                              subItemIndex,
                                              subItemTwoIndex
                                            );
                                          }}
                                        >
                                          <CustomCheckbox
                                            control={control}
                                            name={`Module${
                                              subItemTwo.functionality +
                                              selectedFunctionality +
                                              subItemTwo.id
                                            }`}
                                            label={subItemTwo.functionality}
                                            ischildren={true}
                                          />
                                        </fieldset>
                                        {subItemTwo["permissions"] ? (
                                          <div className="grid grid-cols-4 p-1 ml-2">
                                            {subItemTwo?.permissions.map(
                                              (
                                                subPermission,
                                                subPermissionIndex
                                              ) => {
                                                setValue(
                                                  `Module${
                                                    subPermission.permission +
                                                    selectedFunctionality +
                                                    subItemTwo.id
                                                  }`,
                                                  subPermission.isChecked
                                                );
                                                setValue(
                                                  `Switch${
                                                    subPermission.permission +
                                                    selectedFunctionality +
                                                    subItemTwo.id
                                                  }`,
                                                  subPermission.isAction
                                                );

                                                return (
                                                  <fieldset className="flex gap-3 items-center">
                                                    {subPermissionIndex % 4 !==
                                                    0 ? (
                                                      <span className="h-6 border border-r-1 border-[#42b4f1] mr-2"></span>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <fieldset
                                                      onChange={(e) => {
                                                        handlePermissionChange(
                                                          e,
                                                          subItemIndex,
                                                          subItemTwoIndex,
                                                          subPermissionIndex
                                                        );
                                                      }}
                                                      className="w-[60%]"
                                                    >
                                                      <CustomCheckbox
                                                        control={control}
                                                        name={`Module${
                                                          subPermission.permission +
                                                          selectedFunctionality +
                                                          subItemTwo.id
                                                        }`}
                                                        label={
                                                          subPermission?.permission
                                                        }
                                                      />
                                                    </fieldset>

                                                    <fieldset
                                                      onChange={(e) => {
                                                        handleSecondSwitch(
                                                          e,
                                                          subPermission,
                                                          subItemIndex,
                                                          subItemTwoIndex,
                                                          subPermissionIndex
                                                        );
                                                      }}
                                                    >
                                                      <Controller
                                                        name={`Switch${
                                                          subPermission.permission +
                                                          selectedFunctionality +
                                                          subItemTwo.id
                                                        }`}
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({
                                                          field: { value },
                                                        }) => (
                                                          <FormControlLabel
                                                            control={
                                                              <CustomAntSwitch
                                                                defaultChecked={
                                                                  value
                                                                }
                                                              />
                                                            }
                                                          />
                                                        )}
                                                      />
                                                    </fieldset>
                                                  </fieldset>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {subItemTwo["subFunction"] ? (
                                          <div className="grid p-1  mx-6">
                                            {subItemTwo["subFunction"]
                                              ? subItemTwo.subFunction.map(
                                                  (
                                                    permission,
                                                    permissionIndex
                                                  ) => {
                                                    if (
                                                      selectedModule?.length > 0
                                                    ) {
                                                      setValue(
                                                        `Module${
                                                          permission.functionality +
                                                          selectedFunctionality +
                                                          permission.id
                                                        }`,
                                                        permission.isChecked
                                                      );
                                                    }
                                                    return (
                                                      <fieldset
                                                        onChange={(e) => {
                                                          handleSubItemThree(
                                                            e,
                                                            subItemIndex,
                                                            subItemTwoIndex,
                                                            permissionIndex
                                                          );
                                                        }}
                                                      >
                                                        <CustomCheckbox
                                                          control={control}
                                                          name={`Module${
                                                            permission.functionality +
                                                            selectedFunctionality +
                                                            permission.id
                                                          }`}
                                                          label={
                                                            permission.functionality
                                                          }
                                                        />
                                                      </fieldset>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  );
                                }
                              )
                            ) : subItem["subFunction"] ? (
                              <div className="grid grid-cols-4 mx-[2.5%] border border-gray-400 bg-[#F1F1F1] p-1  rounded">
                                {subItem.subFunction.map(
                                  (permissionTwo, permissionTwoIndex) => {
                                    if (selectedModule?.length > 0) {
                                      setValue(
                                        `Module${
                                          permissionTwo.permission +
                                          selectedFunctionality +
                                          permissionTwo.id
                                        }`,
                                        permissionTwo.isChecked
                                      );
                                    }
                                    return (
                                      <fieldset>
                                        <CustomCheckbox
                                          name={`Module${
                                            permissionTwo.permission +
                                            selectedFunctionality +
                                            permissionTwo.id
                                          }`}
                                          control={control}
                                          label={permissionTwo.permission}
                                        />
                                      </fieldset>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : selectedModule["children"] ? (
                    <div className="text-xl text-gray-400 font-semibold flex justify-center my-10">
                      Please Select Module.
                    </div>
                  ) : (
                    <div className="text-xl text-gray-400 font-semibold flex justify-center my-10">
                      No Functionalities
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-lg font-semibold flex justify-center text-gray-400">
                Please Select Role.
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <ConfirmationModal
        confirmationOpen={openChild}
        confirmationHandleClose={handleCloseChild}
        confirmationSubmitFunc={saveFunctionalities}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to Save this record ?"
        confirmationButtonMsg="Save"
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
    </form>
  );
}

export default AssignFuntionalities;
