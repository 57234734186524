import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ComplaintStatusOffIcon,
  ComplaintStatusOnIcon,
  EditOnIcon,
  EyeOnIcon,
  PrintOnIcon,
} from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { fetchPrevIncidentReportList } from "../../services/incidentReportingServices/IncidentReportingServices";
import IncidentReportingPrint from "../../prints/IncidentReportingPrint";
import ResponsibleDeptModal from "./ResponsibleDeptModal";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  minHeight: "70%",
  maxHeight: "95%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

const incidentStatus = [
  {
    id: 0,
    label: "0 Forward to HR for action",
    value: "0 Forward to HR for action",
  },
  { id: 1, label: "Hold", value: "Hold" },
  { id: 2, label: "Incident not clear", value: "Incident not clear" },
  { id: 3, label: "Resolved", value: "Resolved" },
  {
    id: 4,
    label: "To meet and discuss with concerned",
    value: "To meet and discuss with concerned",
  },
  {
    id: 5,
    label: "Response pending from HOD",
    value: "Response pending from HOD",
  },
];

let showSpinner = false;

export default function PreviousReportsList(props) {
  const {
    handleClose,
    setSelectedRow,
    selectedRow,
    userActions,
    setIsFormView,
  } = props;

  const token = JSON.parse(localStorage.getItem("userInfo"));

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);

  //
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  //
  ///
  const [openResponsibleDeptModal, setOpenResponsibleDeptModal] = useState(
    false
  );
  const handleOpenResponsibleDeptModal = () =>
    setOpenResponsibleDeptModal(true);
  const handleCloseResponsibleDeptModal = () =>
    setOpenResponsibleDeptModal(false);

  ///

  ///
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      patientSearch: "",
      toDate: new Date(),
      fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  let IncidentNumber = watch("incidentNumber");

  //

  // setUserActions(res.actions);

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, IncidentNumber]);

  const populateTable = (forPagination) => {
    showSpinner = true;
    const acceptanceObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      incidentId: Number(IncidentNumber),
      userId: 0,
      departmentId: token.departmentId,
      isQualityDept: token.department === "Quality",
    };

    fetchPrevIncidentReportList(acceptanceObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        // setUserActions(res.actions);
        setCount(res.count);
        showSpinner = false;
      });
  };

  ////////table related
  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction && actions.action === "Edit" ? (
                <>
                  {token.department === "Quality" ? (
                    <div className="flex cursor-pointer items-center">
                      <EditOnIcon
                        title="Edit"
                        onClick={() => {
                          setSelectedRow(row);
                          // setPrivilege(actions.action);
                          handleClose();
                          setIsFormView(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <EyeOnIcon
                        title="View"
                        onClick={() => {
                          setSelectedRow(row);
                          // setPrivilege(actions.action);
                          handleClose();
                          setIsFormView(true);
                        }}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </>
          ))}
          <div className="">
            <PrintOnIcon
              title="Print"
              onClick={() => {
                setOpenPrintModal(true);
                setSelectedRow(row);

                // setPrivilege(actions.action);
                // handleClose();
              }}
            />
          </div>
          {(!token.department === "Quality" || row.isQualityAssign > 0) &&
          row.Status !== 3 &&
          !row?.IsClosed ? (
            <ComplaintStatusOnIcon
              title="Resp Dept Details"
              onClick={() => {
                setSelectedRow(row);

                // setPrivilege(actions.action);
                handleOpenResponsibleDeptModal();
              }}
            />
          ) : (
            <ComplaintStatusOffIcon />
          )}
        </div>
      </>
    );
  };
  const renderInput = (row, index, header) => {
    let status = incidentStatus.find((item) => item.id === row["Status"]);
    return (
      <>
        {header === "Status" && (
          <div className="flex gap-2 items-center">
            {!!status && status.label}
          </div>
        )}

        {header === "Severity" && (
          <div
            className={
              row?.Severity === "A"
                ? "text-red-400"
                : row?.Severity === "B"
                ? "text-orange-400"
                : row?.Severity === "C"
                ? "text-lime-400"
                : row?.Severity === "D"
                ? "text-blue-400"
                : "text-black"
            }
          >
            {row["Severity"]}
          </div>
        )}
      </>
    );
  };
  const renderTableHeader = (row, index, header) => {
    return (
      <>
        <div className="flex gap-3">
          <div className="flex gap-3">
            <div className="h-5 w-5 bg-red-400 rounded"></div>
            <div>A</div>
          </div>
          <div className="flex gap-3">
            <div className="h-5 w-5 bg-orange-400 rounded"></div>
            <div>B</div>
          </div>
          <div className="flex gap-3">
            <div className="h-5 w-5 bg-lime-400 rounded"></div>
            <div>C</div>
          </div>
          <div className="flex gap-3">
            <div className="h-5 w-5 bg-blue-400 rounded"></div>
            <div>D</div>
          </div>
        </div>
      </>
    );
  };

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 500);

    return (
      <div className="hidden">
        <IncidentReportingPrint incidentId={selectedRow?.IncidentId} />
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Previous Reports
            </div>

            <hr className="border mx-1  border-customBlue" />
            <div className="grid grid-cols-6 mt-3 gap-3">
              {/* from Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              {/* to Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              {/*<div className="">
                <InputField
                  name="incidentNumber"
                  label="Incident Number"
                  variant="outlined"
                  type={"number"}
                  // error={errors.incidentNumber}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
          </div>*/}
            </div>
            <div>
              {showSpinner ? (
                <div className="flex justify-center">
                  <LoadingSpinner />
                </div>
              ) : !!dataResult && dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  editableColumns={["Status", "Severity"]}
                  renderInput={renderInput}
                  tableClass="lg:h-[350px] 2xl:h-[500px]"
                  renderTableHeader={renderTableHeader}
                  removeHeaders={[
                    "id",
                    "Incident Departments",
                    "Visitor",
                    "Event Description",
                    "Occurence Place",
                    "Employee",
                    "reportingEmpId",
                    "IncidentId",
                    "ADR Med Error",
                    "Administration Error",
                    "Administration Errormed",
                    "Administration Med Error",
                    "Bed Sore",
                    "Bed Sore Error",
                    "Billing Error",
                    "Communication Error",
                    "Dispensing Error",
                    "Dispensing Med Error",
                    "Documentation Error",
                    "HK Error",
                    "HR Error",
                    "Hospital",
                    "Incident Departments",
                    "IncidentCategoryId",
                    "Indent No",
                    "Medication Error",
                    "Needle Injury",
                    "Other Errors",
                    "Other Responsible Department",
                    "Patient Care Error",
                    "Patient Fall",
                    "Patient Name",
                    "Process Error",
                    "Report Person Name",
                    "Transcription Med Error",
                    "timePatch",
                    "ResponsibleDepartments",
                    "IsClosed",
                    "IsCreatedByYou",
                    "EntryDate",
                    "entryTime",
                    "ErrorCategoryId",
                    "ErrorSubCategoryId",
                    "IncidentSubCategory",
                    "IncidentCaregory",
                    "isQualityAssign",
                    "isQualityAssign",
                    "isQualityAssign",
                    "isQualityAssign",
                  ]}
                  renderActions={renderActions}
                  highlightRow={false}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>

            <div className="gap-3 my-1 flex justify-end">
              <CommonButton
                label="Cancel"
                className="border border-customRed text-customRed"
                onClick={() => handleClose()}
              />
              {/* <CommonButton
                label="Proceed"
                className="bg-customGreen text-white"
                //onClick={addInvestigationsToDischargeSummary}
              /> */}
            </div>
          </div>
        </Box>
      </Modal>

      {openResponsibleDeptModal ? (
        <ResponsibleDeptModal
          open={openResponsibleDeptModal}
          setOpen={setOpenResponsibleDeptModal}
          handleOpen={handleOpenResponsibleDeptModal}
          handleClose={handleCloseResponsibleDeptModal}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          populateTable={populateTable}
        />
      ) : null}

      {/*  Print Modal */}

      {openPrintModal && renderPrint()}
    </div>
  );
}
