import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputArea from "../../../common/components/FormFields/InputArea";
import RadioField from "../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { savePrePostSwab } from "../../services/fumigationChartServices/FumigationChartServices";

// const schema = yup.object().shape({
//   description: yup.string().required("Required"),
// });

const SwabType = [
  {
    id: 0,
    label: "Pre-Swab",
    value: "Pre-Swab",
  },
  {
    id: 1,
    label: "Post-Swab",
    value: "Post-Swab",
  },
];

export default function PrePostSwabModal(props) {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    handleClose,
    selectedRow,
    populateTable,
    menuId,
    userActions,
    privilege,
  } = props;

  //   const [privilege, setPrivilege] = useState("");
  const [allFormData, setAllFormData] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      swabType: 0,
      description: "",
    },
  });

  const { control, setValue, getValues, watch } = methods;

  let swbType_selected = watch("swabType");

  useEffect(() => {
    setValue(
      "description",
      privilege === "PreSwab"
        ? selectedRow?.PreSwabTemplateResult || ""
        : selectedRow?.PostSwabTemplateResult || ""
    );
  }, [swbType_selected]);

  const onSubmit = (data) => {
    setOpenConfirmationModal(true);
    setAllFormData(data);
  };
  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const finalObj = {
      fumigationId: selectedRow?.FID,
      isAuthorized: isAuth,
      isPreSwab: Number(getValues("swabType")) === 0 ? true : false,
      menuId: menuId,
      privilege: privilege,
      templateDescription: getValues("description"),
    };
    savePrePostSwab(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              {privilege}
            </div>

            <hr className="border mx-1  border-customBlue" />
            <form>
              <div className="grid  my-3">
                <div className="">
                  <InputArea
                    control={control}
                    name="description"
                    label="Description"
                    placeholder="Description"
                  />
                </div>
              </div>

              <div className="gap-3 my-3 flex justify-end">
                {userActions.map((actions) => (
                  <>
                    <div className="flex cursor-pointer items-center ">
                      {actions.isAction && actions.action === privilege && (
                        <div onClick={() => setIsAuth(false)}>
                          <CommonButton
                            disabled={selectedRow?.IsCompleted}
                            label={"Save"}
                            className="bg-customGreen text-white"
                            onClick={() =>
                              selectedRow?.IsCompleted !== 1 && onSubmit()
                            }
                          />
                        </div>
                      )}
                      {!actions.isAction && actions.action === "Authorise" && (
                        <div onClick={() => setIsAuth(true)}>
                          <CommonButton
                            label="Authorise"
                            disabled={selectedRow?.IsCompleted}
                            className="border border-customBlue text-customBlue"
                            onClick={() =>
                              selectedRow?.IsCompleted !== 1 && onSubmit()
                            }
                          />
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
