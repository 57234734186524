import * as React from "react";
import { Box, Modal, Tooltip } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useState } from "react";
import CurrentStockPrint from "../../../prints/inventorystock/CurrentStockPrint";
import ItemExpirydetailsPrint from "../../../prints/inventorystock/ItemExpirydetailsPrint";
import DaysPrint from "../../../prints/inventorystock/DaysPrint";
import CurrentStockNonCosignment from "../../../prints/inventorystock/CurrentStockNonCosignment";
import { Watch } from "@mui/icons-material";

const InventoryStockPrits = [
  { id: 1, Name: "All Expiry Items" },
  {
    id: 2,
    Name: "Days",
  },
  {
    id: 3,
    Name: "Print Stock",
  },
  {
    id: 4,
    Name: "Non Consignment Print",
  },
];

export default function InventoryStockPrintmodal(props) {
  const [printClick, setPrintClick] = useState("");
  const [openprintModal, setOpenprintModal] = useState(false);

  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenprintModal(false);
    }, 5000);

    return (
      <div className="hidden">
        {printClick === "All Expiry Items" && (
          <ItemExpirydetailsPrint storeVal={props.storeVal}  />
        )}

        {printClick === "Days" && <DaysPrint storeVal={props.storeVal} />}

        {printClick === "Print Stock" && (
          <CurrentStockPrint storeVal={props.storeVal} />
        )}

        {printClick === "Non Consignment Print" && (
          <CurrentStockNonCosignment />
        )}
      </div>
    );
  };

  return (
    <div>
      <Modal open={props.open} aria-labelledby="modal-modal-title" className="">
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              width: 7,
              height: 10,
              marginY: "4px",
              overflowY: "auto",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#d1d5db",
              borderRadius: "0.25rem",
              padding: "2px !important",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f8fafc",
              borderRadius: "0.25rem",
              padding: "2px !important",
            },
          }}
          className=" h-auto w-[30%]  overflow-clip rounded-lg  overflow-y-auto   absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-75 bg-background-paper border bg-white border-gray-300   p-4"
        >
          <div className="">
            <div className="flex  font-bold justify-center px-2">
              Inventory Stock Prints
            </div>
            <div className="justify-end">
              <CancelPresentationIconButton onClick={props.handleClose} />
            </div>
            <div className="divide-y w-full divide-slate-200">
              {InventoryStockPrits.map((row, index) => (
                <div
                  key={index}
                  className="divide-y w-full divide-slate-300 group/item hover:bg-slate-100 rounded-lg  py-1  flex gap-2 items-center justify-between px-2"
                >
                  <span className="">{row.Name}</span>

                  <div className="group/edit  hover:bg-white group-hover/item:visible">
                    <button
                      type="button"
                      onClick={() => {
                        setPrintClick(row?.Name);
                        setOpenprintModal(true);
                      }}
                      className="text-xs group-hover/edit:text-gray-700"
                    >
                      <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>

      {openprintModal && renderPrint()}
    </div>
  );
}
