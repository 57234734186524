import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";

export default function ConsentPrintPreview(props) {
  let printRef = useRef(null);
  const {
    handleClose,
    patientInfo,
    templateContent,
    TemplateName,
    consentCode,
  } = props;

  const DynamicHTMLRenderer = ({ htmlContent }) => {
    return (
      <div
        // className="whitespace-pre-wrap break-words"
        className="break-words"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  useEffect(() => {
    templateContent !== null && printRef && GeneratePrint(printRef);
  }, [templateContent]);

  let consentDate = new Date().toLocaleDateString().split("/");
  const patchConsentDate =
    consentDate[1] + "/" + consentDate[0] + "/" + consentDate[2];
  console.log("consentDate", consentDate);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div>
            <div className="flex items-center">
              <div className="text-center w-full mt-5 ml-16">
                <h1 className="text-lg font-semibold">
                  Inamdar Multispeciality Hospital
                </h1>
                <h1 className="">
                  Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040
                </h1>
              </div>
              <div className="flex justify-end">
                <InamdarHospiLogo />
              </div>
            </div>
            <div className=" border-t-[1px] border-b-[1px] font-semibold text-[15px] border-black text-center">
              OT CONSENT
            </div>
            {/* <div className=" grid grid-cols-[20%_5%_25%_20%_5%_25%] border-b-[1px] border-t-[1px] p-1 border-black ">
              <div className=" text-[13px] font-semibold  ">PatientName</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] whitespace-nowrap ">
                {patientInfo?.patientName}
              </div>
              <div className=" text-[13px] font-semibold ">MR No</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.mrNo}</div>
              <div className=" text-[13px] font-semibold ">Gender</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.gender}</div>
              <div className=" text-[13px] font-semibold ">Consent Date</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.ConsentDate}</div>
              <div className=" text-[13px]  font-semibold">Admission Date</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">
                {patientInfo?.admVisitPatchDate}
              </div>
              <div className=" text-[13px] font-semibold ">Adviced By</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">
                {patientInfo?.doctorName ? patientInfo?.doctorName : "-"}
              </div>
              <div className=" text-[13px] font-semibold ">Age</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.ageYear}</div>
              <div className=" text-[13px] font-semibold ">DOB</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.dob}</div>
            </div> */}
            <div className="grid grid-cols-2 text-[13px]">
              <div className="flex items-center">
                <label className="font-semibold w-28">Patient Name</label>
                <label>&nbsp;:&nbsp;{patientInfo?.patientName}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-24">MR No.</label>
                <label>&nbsp;:&nbsp;{patientInfo?.mrNo}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-28">Gender</label>
                <label>&nbsp;:&nbsp;{patientInfo?.gender}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-24">Consent Date</label>
                <label>&nbsp;:&nbsp;{patchConsentDate}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-28">Admission Date</label>
                <label>&nbsp;:&nbsp;{patientInfo?.admVisitPatchDate}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-24">Adviced By</label>
                <label>
                  &nbsp;:&nbsp;
                  {patientInfo?.doctorName ? patientInfo?.doctorName : "-"}
                </label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-28">Age</label>
                <label>&nbsp;:&nbsp;{patientInfo?.ageYear}</label>
              </div>
              <div className="flex items-center">
                <label className="font-semibold w-24">DOB</label>
                <label>&nbsp;:&nbsp;{patientInfo?.dob}</label>
              </div>
            </div>
            <div className="border-t-[1px] border-b-[1px] font-semibold p-1 text-[13px] border-black text-center">
              {TemplateName?.label}
            </div>
          </div>
        </thead>
        <tbody>
          <tr>
            {/*<div
              className="pt-5 text-wrap flex flex-wrap  "
              // dangerouslySetInnerHTML={{ __html: data?.TempalteDescription }}
            >
              <h1> {data?.TempalteDescription}</h1>
        </div>*/}
            <DynamicHTMLRenderer htmlContent={templateContent} />
          </tr>
        </tbody>
        <tfoot
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          {consentCode !== null && (
            <div>Consent Code :&nbsp;{consentCode} </div>
          )}
        </tfoot>
      </table>
    </div>
  );
}

