import * as React from "react";
import { useEffect, useState } from "react";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useForm } from "react-hook-form";
import InputField from "../../../common/components/FormFields/InputField";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import ETURegisterStaffTable from "./ETURegisterStaffTable.js";

import { TextField, Tooltip } from "@mui/material";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";

import {
  getETURegisterStaffName,
  saveETURegister,
} from "../../services/etuRegisterServices/etuRegisterServices";

import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { successAlert } from "../../../common/components/Toasts/CustomToasts";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
const removeHeaders = [
  "patchDate",
  "Address",
  "AgeYear",
  "ArrivalTime",
  "BatchNo",
  "BillId",
  "BillNo",
  "BodyHandedOverBy",
  "BodyHandedOverTo",
  "BodyHandoverRemark",
  "DepartmentId",
  "DepartureTime",
  "Diagnosis",
  "Gender",
  "GenderId",
  "ID",
  "IsDC",
  "IsDoctorVisit",
  "IsMLC",
  "IsNurseVisit",
  "IsPM",
  "IsSingleDose",
  "IsVaccineDateEdited",
  "MortuaryInTime",
  "MortuaryOutTime",
  "NextVaccineDate",
  "PatientId",
  "ReceivedCallDateTime",
  "StaffIds",
  "Staffs",
  "Treatement",
  "TrnType",
  "VaccineDoseDurationID",
  "VaccineId",

  "VisitedLocation",
];
const actions = ["print"];
const HomeVisit = (props) => {
  const schema = yup.object().shape({});
  const defaultValues = {
    homevisitdate: new Date(),
    doctorvisit: "",
    nursevisit: "",
    diagnosis: "",
    treatment: "",
    employeename: "",
    staffs: "",
    billNo: "",
  };
  const {
    control,
    handleSubmit,
    reset,
    register,

    watch,
    getValues,
    setValue,
    formState: {},
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables

  const [open, setOpen] = useState(false);

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());

  const [etuHomeObject, setETUHomeObject] = React.useState(null);
  const [employeeName, setEmployeeName] = React.useState([]);

  const [employeeArr, setEmployeeArr] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [resetConfirmationOpen, setResetConfirmationOpen] = React.useState(
    false
  );
  const [cellInfo, setCellInfo] = React.useState("");
  const [rowIndex, setRowIndex] = React.useState();
  const [privilege, setPrivilege] = React.useState(null);
  let homevisitdateVal = getValues("homevisitdate");
  let staffsVal = watch("staffs");
  let location = useLocation();

  //useEffects
  useEffect(() => {}, []);
  //functions
  const handleOpen = () => {
    setOpen(true);
    setConfirmationOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setConfirmationOpen(false);
  };
  const handleResetOpenConfirmation = () => {
    setResetConfirmationOpen(true);
  };

  const handleResetCloseConfirmation = () => {
    if (resetConfirmationOpen === true) {
      setResetConfirmationOpen(false);
    }
  };

  useEffect(() => {
    getETURegisterStaffName()
      .then((Response) => Response.data)
      .then((res) => {
        setEmployeeName(res.result);
      });
  }, []);

  // setValue to all field
  useEffect(() => {
    let staffObj = {};
    staffObj.id = 0;
    staffObj.value = cellInfo?.Staffs;
    staffObj.label = cellInfo?.Staffs;
    if (cellInfo) {
      setValue("diagnosis", cellInfo?.Diagnosis);
      setValue("treatment", cellInfo?.Treatement);
      setValue("staffs", staffObj);
      setValue("isDoctorVisit", cellInfo?.IsDoctorVisit);
      setValue("isNurseVisit", cellInfo?.IsNurseVisit);
      setValue("billNo", cellInfo?.BillNo);
      let homeVisitDate = cellInfo?.patchDate;
      setSelectedFromDate(new Date(homeVisitDate));
    }
  }, [cellInfo]);

  useEffect(() => {
    if (props.showInfo === true) {
      setValue("isDoctorVisit", null);
      setValue("isNurseVisit", null);
      setValue("diagnosis", null);
      setValue("treatment", null);
      setValue("staffs", null);
      setValue("billNo", "");
    }
  }, [props.showInfo]);

  function addStaffNameToTable() {
    if (staffsVal !== null) {
      let requiredArr = [];
      for (let empObj of staffsVal) {
        let Obj = {};
        Obj = empObj.label;
        requiredArr.push(Obj);

        setEmployeeArr(requiredArr);
      }
    }
  }

  function onSubmitDataHandler(data) {
    let getPatientInfo = localStorage.getItem("userInfo");
    let getPatientInfoObj = JSON.parse(getPatientInfo);

    let nameArr = [];
    let idArr = [];
    let arrToStringID = "";
    let arrToStringName = "";
    for (let i = 0; i < staffsVal.length; i++) {
      let id = staffsVal[i].id;
      idArr.push(id);
      arrToStringID = idArr.toString(); //conversion arrey to string
    }
    for (let i = 0; i < staffsVal.length; i++) {
      let label = staffsVal[i].label;
      nameArr.push(label);
      arrToStringName = nameArr.toString(); //conversion arrey to string
    }
    let postETURegisterObj = {
      arrivalTime: "",

      batchNo: data.batchNo ? data.batchNo : null,

      billId: 0,

      addedBy: getPatientInfoObj.userId,
      billNo: data.billNo ? data.billNo : null,
      bodyHandOverBy: data.bodyHandOverBy ? data.bodyHandOverBy : null,
      bodyHandOverRemark: data.bodyHandOverRemark
        ? data.bodyHandOverRemark
        : null,
      bodyHandOverTo: data.bodyHandOverTo ? data.bodyHandOverTo : null,
      departmentId: data.departmentId ? data.departmentId : null,
      departureTime: "",
      diagnosis: data.diagnosis ? data.diagnosis : null,
      isDc: data.isDc ? data.isDc : null,
      isDoctorVisit: data.isDoctorVisit ? data.isDoctorVisit : null,
      isMlc: data.isMlc ? data.isMlc : null,
      isNurseVisit: data.isNurseVisit ? data.isNurseVisit : null,
      isPm: data.isPm ? data.isPm : null,
      isSingleDose: data.isSingleDose ? data.isSingleDose : null,
      isVaccineDateEdited: data.isVaccineDateEdited
        ? data.isVaccineDateEdited
        : null,
      isVccRemainderSMSSend: data.isVccRemainderSMSSend
        ? data.isVccRemainderSMSSend
        : null,
      mortuaryInTime: "",
      mortuaryOutTime: "",
      nextVaccineDate: "",
      patientId: props.patientId,
      receivedCallDateTime: "",
      staffIds: arrToStringID,
      staffs: arrToStringName,
      tmType: props.eturegisteroptionVal.label,
      treatment: data.treatment,
      vaccineDoseDurationId: data.vaccineDoseDurationId
        ? data.vaccineDoseDurationId
        : null,
      vaccineId: data.vaccineId ? data.vaccineId : null,
      visitedLocation: data.visitedLocation ? data.visitedLocation : null,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    setETUHomeObject(postETURegisterObj);
    handleOpen();
  }

  function addRecord() {
    let etuObj = etuHomeObject;
    saveETURegister(etuHomeObject)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        reset(defaultValues, props.etuRegisterSearchVal);
        props.populateTable();
        setEmployeeArr([]);
      });
  }

  function displayRowData(row, index) {}
  function resetHomeVisit() {
    handleResetCloseConfirmation();
    setSelectedFromDate(new Date());
    reset(defaultValues);
    setRowIndex();
    props.setShowInfo(true);
  }

  // get row action and row data
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1 items-center cursor-pointer">
        {props.userActions &&
          props.userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-1 items-center cursor-pointer">
                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title={"Print"}>
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                              if (props.etuType === "HomeVisit") {
                                props.printHomeReceipt(Number(row?.ID));
                              } else if (props.etuType === "AmbulanceCall") {
                                props.printAmbulanceCallReceipt(
                                  Number(row?.ID)
                                );
                              } else if (props.etuType === "BodyHandover") {
                                props.printBodyHandoverReceipt(Number(row?.ID));
                              } else if (props.etuType === "Vaccine") {
                                props.printVaccineChartReceipt(Number(row?.ID));
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  // get selected Row Data
  function handleSelectedRow(row, index) {
    displayRowData(row, index);
    setCellInfo(row);
    if (props.showInfo === true) {
      props.setShowInfo(false);
    }
  }
  return (
    <div className=" pb-4">
      {props.showInfo === true ? (
        <>
          {props.patientInfoById && props.patientInfoById !== "" ? (
            <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
              <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 font-semibold">Patient Name</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById?.PatientName &&
                      props?.patientInfoById?.PatientName}
                  </span>
                </div>
                <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                    MR No.
                  </span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById && props?.patientInfoById.MRNo}
                  </span>
                </div>

                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 xl:w-8 font-semibold">Age</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById && props?.patientInfoById.AgeYear}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                  <span className="w-24 lg:w-20 font-semibold">Address </span>

                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById && props?.patientInfoById.Address}
                  </span>
                </div>
              </div>
            </fieldset>
          ) : null}
        </>
      ) : null}
      <div className="w-full py-2">
        {props.dataResult.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={props.dataResult}
            highlightRow={true}
            renderActions={renderActions}
            handleSelectedRow={handleSelectedRow}
            tableClass="lg:h-[300px] 2xl:h-[450px]"
            removeHeaders={removeHeaders}
          />
        ) : (
          <div className="text-center py-12">
            <span>Records Not Found...</span>
          </div>
        )}
      </div>
      {props.showInfo === false ? (
        <>
          {cellInfo && cellInfo !== "" ? (
            <>
              <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 font-semibold">Patient Name</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo?.PatientName && cellInfo?.PatientName}
                    </span>
                  </div>
                  <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                      MR No.
                    </span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo?.MRNo}
                    </span>
                  </div>

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 xl:w-8 font-semibold">Age</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.AgeYear}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                    <span className="w-24 lg:w-20 font-semibold">Address</span>

                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.Address}
                    </span>
                  </div>
                </div>
              </fieldset>
            </>
          ) : null}
        </>
      ) : null}
      {(props.patientInfoById && props.patientInfoById !== "") ||
      (cellInfo && cellInfo !== "") ? (
        <>
          <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-1 items-center">
            <div className="text-md font-semibold">
              Patient Home Visit Details
            </div>
            <div className=" flex justify-end">
              {props.showInfo === true ? null : (
                <CommonButton
                  label="New"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    handleResetOpenConfirmation();
                  }}
                />
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <div className="">
              <div className="flex gap-2 pt-2">
                <div className="">
                  <DatePickerFieldNew
                    control={control}
                    name="homevisitdate"
                    label="Home Visit Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      let getDateVal = watch("homevisitdate");

                      setValue("homevisitdate", newValue);
                    }}
                    // disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
                <CheckBoxField
                  name="isDoctorVisit"
                  label="Doctor Visit"
                  control={control}
                />
                <CheckBoxField
                  name="isNurseVisit"
                  label="Nurse Visit"
                  control={control}
                />
              </div>
              <div className="flex gap-2 py-4 items-center">
                <TextField
                  id="outlined-multiline-static"
                  name="diagnosis"
                  {...register("diagnosis")}
                  label="Diagnosis"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                />

                <TextField
                  id="outlined-multiline-static"
                  name="treatment"
                  {...register("treatment")}
                  label="Treatment"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                />
              </div>
              <div className="lg:grid lg:grid-cols-2 gap-2">
                <div>
                  <div className="w-36 py-2">
                    <InputField
                      control={control}
                      name="billNo"
                      label="Bill No"
                      type="number"
                    />
                  </div>
                  <div className="py-2 flex gap-2">
                    <div className="w-full ">
                      <DropdownField
                        control={control}
                        style={{ height: "auto" }}
                        name="staffs"
                        placeholder="Search By Employee Name"
                        dataArray={employeeName}
                        searchIcon={true}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={true}
                        inputRef={{
                          ...register("staffs", {
                            onChange: (e) => {},
                          }),
                        }}
                      />
                    </div>
                    <div>
                      {props.showInfo === true ? (
                        <CommonButton
                          label="Add"
                          type="button"
                          className="border bg-customGreen text-white"
                          onClick={() => {
                            addStaffNameToTable();
                          }}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    {employeeArr.length ? (
                      <ETURegisterStaffTable
                        data={employeeArr}
                        actions={actions}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <div className="pt-2 ">
              <EmployeeTable data={empTableData} />
            </div> */}
              </div>
            </div>
            <div className="flex gap-2 justify-end">
              {props.showInfo === true ? (
                <>
                  {props.userActions &&
                    props.userActions.map((actions, i) => (
                      <>
                        {actions.isAction === false ? (
                          <>
                            <div className="flex gap-2 cursor-pointer">
                              {actions.action === "Create" ? (
                                <>
                                  {props.showInfo === true ? (
                                    <>
                                      <div>
                                        <CommonButton
                                          label="Reset"
                                          type="button"
                                          className="border border-customRed text-customRed"
                                          onClick={() => {
                                            reset(defaultValues);
                                            setEmployeeArr([]);
                                          }}
                                        />
                                      </div>

                                      <div>
                                        <CommonButton
                                          type="submit"
                                          label="Save"
                                          className="bg-customGreen text-white"
                                          onClick={() => {
                                            setPrivilege(actions?.action);
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </>
                    ))}
                </>
              ) : null}
            </div>
          </form>
        </>
      ) : null}
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          handleClose();
          addRecord();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save ETU Details?"
        confirmationButtonMsg="Save"
      />
      <ConfirmationModal
        confirmationOpen={resetConfirmationOpen}
        confirmationHandleClose={handleResetCloseConfirmation}
        confirmationSubmitFunc={() => {
          resetHomeVisit();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure to Reset Home Visit?"
        confirmationButtonMsg="Reset"
      />
    </div>
  );
};

export default HomeVisit;
