//imports from material ui library
import { Box, Modal } from "@mui/material";

import * as React from "react";

import { useForm } from "react-hook-form";

import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import {
  errorAlertCustom,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

import RightArrowButton from "../../../common/components/Buttons/RightArrowButton";

import {
  fetchDepartmentForAppointment,
  fetchUnit,
} from "../../../commonServices/miscellaneousServices/MiscServices";

import { getDoctorTimeSlotsByDepartmentIdAndUnitId } from "./services/AppointmentServices";

const selectedSlotStyle =
  "border border-[#1e3a8a] py-1 w-20 rounded text-center bg-[#1e3a8a] text-white text-xs";

const notSelectedSlotStyle =
  "border border-[#1e3a8a] py-1 w-20 rounded text-center text-xs";

const previouslyBookedSlotStyle =
  "border border-slate-400 py-1  w-20 items-center rounded text-center bg-slate-300 text-xs text-neutral-600";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  overflowY: "scroll",
  p: 2,
  height: "90%",
};

function AppointmentFormModal(props) {
  

  const searchDropdownElement = React.useRef("");

  const [unitId, setUnitId] = React.useState(null);

  //state variable to display or hide the loading spinner.
  const [spinner, setSpinner] = React.useState(false);

  const [slotInformationObj, setSlotInformationObj] = React.useState(null);

  const [doctorInfoObj, setDoctorInfoObj] = React.useState(null);

  const [unitNamesArray, setUnitNamesArray] = React.useState([]);

  const [
    allDoctorSlotsNotAvailable,
    setAllDoctorSlotsNotAvailable,
  ] = React.useState(false);

  const defaultValues = {
    appointmentDate: new Date(),
    unit: null,
    department: null,
  };

  const {
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let currentAppointmentDateVal = watch("appointmentDate");

  

  React.useEffect(() => {
    
  
    getDepartment();
  }, [props.departmentSearchString, unitId]);

  React.useEffect(() => {
    
    

    //when the appointment is booked
    if (props.doctorList?.length === 0) {
      setValue("department", null);
      setValue("appointmentDate", new Date());
      props.setSelectedAppointment(new Date());
      setAllDoctorSlotsNotAvailable(false);
    }

    if (props.doctorList?.length > 0) {
      let totalDoctorList = props.doctorList;

      let visitSlotsCount = 0;

      for (let doctorObj of totalDoctorList) {
        visitSlotsCount = doctorObj.visitSlots.length;

        if (visitSlotsCount !== 0) {
          break;
        }
      }

      if (visitSlotsCount === 0) {
        setAllDoctorSlotsNotAvailable(true);
      } else if (visitSlotsCount > 0) {
        setAllDoctorSlotsNotAvailable(false);
      }
    }
  }, [props.doctorList]);

  React.useEffect(() => {
    props.setSelectedAppointment(currentAppointmentDateVal);
  }, [currentAppointmentDateVal]);

  React.useEffect(() => {
    fetchUnit().then((response) => {
      
      setUnitNamesArray(response.data.result);
      setValue("unit", response.data.result[0]);
      setUnitId(response.data.result[0].id);
    });
  }, []);

  //Modify the doctorList
  function modifyDoctorList(doctorTimeSlotArr) {
    for (let doctorTimeSlotObj of doctorTimeSlotArr) {
      let tempDoctorId = doctorTimeSlotObj.doctorId;

      let tempVisitSlots = doctorTimeSlotObj.visitSlots;

      for (let index = 0; index < tempVisitSlots.length; index++) {
        let uniqueId = `${index}+${tempDoctorId}`;
        tempVisitSlots[index].uniqueId = uniqueId;
      }

      

      doctorTimeSlotObj.visitSlots = tempVisitSlots;
    }

    //below loop is to select the first available time slot by default
    for (let index = 0; index < doctorTimeSlotArr.length; index++) {
      if (doctorTimeSlotArr[index].visitSlots.length > 0) {
        let requiredVisitSlots = doctorTimeSlotArr[index].visitSlots;

        for (let slotObj of requiredVisitSlots) {
          if (slotObj.isBooked === false) {
            //below three lines are from the onclick event listener of <div> tag of the time slot.
            props.setSlotId(slotObj.uniqueId);
            setSlotInformationObj(slotObj);
            setDoctorInfoObj(doctorTimeSlotArr[index]);

            break;
          }
        }

        break;
      }
    }

    

    if (doctorTimeSlotArr.length === 0) {
      errorAlertCustom("Doctor's time slot is not available.");
      props.setDoctorList([]);
    } else if (doctorTimeSlotArr.length > 0) {
      props.setDoctorList(doctorTimeSlotArr);
    }
  }

  let appointmentDateValue = watch("appointmentDate");

  // Api to Get Doctor by department id
  const getDoctorTimeSlots = (departmentIdVal, unitIdVal) => {
    setSpinner(true);
    let requiredDate = null;

    let objectDate = new Date(appointmentDateValue);

    let day = String(objectDate.getDate());

    if (day.length === 1) {
      day = `0${day}`;
    }

    let month = objectDate.getMonth();

    month = month + 1;

    month = String(month);

    if (month.length === 1) {
      month = `0${month}`;
    }

    let year = objectDate.getFullYear();

    requiredDate = `${year}-${month}-${day}`;

    if (requiredDate.length === 10) {
      getDoctorTimeSlotsByDepartmentIdAndUnitId(
        requiredDate,
        departmentIdVal,
        unitIdVal
      )
        .then((response) => {
          setSpinner(false);
          
        
          let tempDoctorSlotArray = response.data.result;
          
          modifyDoctorList(tempDoctorSlotArray);
        })
        .catch((response) => {
          setSpinner(false);
          
        });
    } else {
      errorAlertCustom("Please enter valid date.");
      setValue("department", null);
      setValue("appointmentDate", new Date());
      props.setSelectedAppointment(new Date());
      props.setDoctorList([]);
      setAllDoctorSlotsNotAvailable(false);
    }
  };

  // Api to Get department list
  const getDepartment = () => {
    if (unitId && props.departmentSearchString) {
      fetchDepartmentForAppointment(unitId, props.departmentSearchString)
        .then((response) => {
         
          props.setDepartmentList(response.data.result);
        })
        .catch((response) => {
          
        });
    }
  };

  function clickHandlerForTimeSlotRectangle(singleObj, doctorObj) {
    

    

    let timeSlotVal = `${singleObj.fromTime} To ${singleObj.toTime}`;

    props.setTimeSlot(timeSlotVal);

    props.setFromTimeSlot(singleObj.fromTwoFourTime);

    props.setToTimeSlot(singleObj.toTwoFourTime);

    

    props.setDoctorName(doctorObj.doctorName);

    let doctorIdVal = doctorObj.doctorId;

    let departmentNameObjVal = getValues("department");

    let departmentNameVal = departmentNameObjVal.label;

    let departmentIdVal = departmentNameObjVal.id;

    let doctorNameVal = doctorObj.doctorName;

    let reqiredObj = {
      doctorId: doctorIdVal,
      departmentName: departmentNameVal,
      doctorName: doctorNameVal,
      departmentId: departmentIdVal,
    };

    

    

    props.setCellInformation(reqiredObj);

    props.setOpen(false);
  }

  function searchButtonClickHandler() {
    
   
    let departmentObj = getValues("department");
    let unitObj = getValues("unit");

    

    if (departmentObj && unitObj) {
      let departmentIdVal = departmentObj.id;
      let unitIdVal = unitObj.id;
      getDoctorTimeSlots(departmentIdVal, unitIdVal);
    }

    if (departmentObj === null || unitObj === null) {
      warningAlert("Please Select Department...!");
    }
  }

  return (
    <>
      <div className=" bg-white px-6">
        <Modal
          open={props.open}
          // onClose={() => {
          //   props.closePaymentModal();
          // }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <CancelPresentationIconButton
              onClick={() => {
                props.setOpen(false);
                setValue("department", null);
                setValue("unit", null);
                setValue("appointmentDate", new Date());
                props.setSelectedAppointment(new Date());
                props.setTimeSlot("");
                props.setCellInformation(null);
                props.setDoctorList([]);
                props.setSlotId(null);
                setAllDoctorSlotsNotAvailable(false);
              }}
              style={{
                paddingLeft: 10,
              }}
            />

            {/* <div className="w-full grid items-center rounded  mt-10"> */}
            <p className="text-center text-2xl my-2 text-black  font-Poppins">
              Select Consultation Slot
            </p>
            {/* </div> */}

            <div className="flex gap-2 w-full">
              <div className="w-full">
                <DatePickerFieldNew
                  control={control}
                  name="appointmentDate"
                  label="Appointment Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("appointmentDate", newValue);
                  }}
                  disablePast={true}
                  disableFuture={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              <div className="w-full">
                <DropdownField
                  control={control}
                  name="unit"
                  label="*Unit"
                  placeholder="Unit"
                  isSearchable={false}
                  isClearable={false}
                  dataArray={unitNamesArray}
                  error={errors.unit}
                  inputRef={{
                    ...register(`unit`, {
                      onChange: (e) => {
                        
                        
                      

                        setUnitId(e.target.value.id);
                      },
                    }),
                  }}
                />
              </div>

              <div className="w-full">
                <fieldset ref={searchDropdownElement}>
                  <SearchDropdown
                    control={control}
                    error={errors.department}
                    name="department"
                    label="Department"
                    dataArray={props.departmentList}
                    handleInputChange={(e) => {
                      
                      props.setDepartmentSearchString(e);
                    }}
                    inputRef={{
                      ...register("department", {
                        onChange: (e) => {
                          if (e.target.value === null) {
                            props.setDoctorList([]);
                            props.setTimeSlot("");
                            props.setCellInformation(null);
                            props.setSlotId(null);
                          }
                        },
                      }),
                    }}
                    isClearable={true}
                    placeholder="Department Name*"
                    isSearchable={true}
                    searchIcon={true}
                  />
                </fieldset>
              </div>

              <div className="flex gap-2">
                <div className="w-full">
                  <CommonButton
                    // label="Search Icon"
                    searchIcon
                    className="bg-customBlue text-white"
                    onClick={() => {
                      searchButtonClickHandler();
                    }}
                  />
                </div>

                {props.slotId !== null ? (
                  <div className="w-full">
                    <RightArrowButton
                      onClick={() => {
                        clickHandlerForTimeSlotRectangle(
                          slotInformationObj,
                          doctorInfoObj
                        );
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            {spinner && props.doctorList.length === 0 ? (
              <div className="grid justify-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div>
                {props.doctorList?.map((doctorObj) => {
                  return (
                    <>
                      {/* parent container */}
                      {doctorObj.visitSlots &&
                      doctorObj.visitSlots.length > 0 ? (
                        <fieldset className="border border-gray-300 text-left lg:mx-auto lg:px-4 py-3 rounded mt-3 lg:m-2 ">
                          <legend className="px-2 ml-3 lg:ml-0 font-bold text-gray-600 text-sm">
                            {doctorObj.doctorName}
                          </legend>

                          <div
                            id="main"
                            className="grid grid-cols-11 gap-y-3 text-center items-center"
                          >
                            {doctorObj.visitSlots.map((singleObj) => {
                           
                           
                              if (singleObj.isBooked === false) {
                                return (
                                  <>
                                    <div
                                      className={
                                        props.slotId !== singleObj.uniqueId
                                          ? notSelectedSlotStyle
                                          : selectedSlotStyle
                                      }
                                      key={singleObj.uniqueId}
                                      onClick={(e) => {
                                        props.setSlotId(singleObj.uniqueId);
                                        setSlotInformationObj(singleObj);
                                        setDoctorInfoObj(doctorObj);
                                      }}
                                    >
                                      <span>{singleObj.fromTime}</span>
                                    </div>
                                  </>
                                );
                              } else if (singleObj.isBooked === true) {
                                return (
                                  <>
                                    <div
                                      className={previouslyBookedSlotStyle}
                                      key={singleObj.uniqueId}
                                    >
                                      <span>{singleObj.fromTime}</span>
                                    </div>
                                  </>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </fieldset>
                      ) : null}
                    </>
                  );
                })}
              </div>
            )}

            {allDoctorSlotsNotAvailable === true ? (
              <>
                <div className="flex justify-center items-center h-24">
                  <div>Slots Not Available</div>
                </div>
              </>
            ) : null}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AppointmentFormModal;
