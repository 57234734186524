import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import InputArea from "../../../common/components/FormFields/InputArea";

const SystematicExamination = (props) => {
  const { control } = useFormContext();
  return (
    <div className=" grid grid-cols-2 gap-3">
      {/* RS */}
      <div className=" ">
        <InputArea control={control} name="rs" label="RS " placeholder="RS" />
      </div>
      {/* CVS */}
      <div className=" ">
        <InputArea
          control={control}
          name="cvs"
          label="CVS "
          placeholder="CVS"
        />
      </div>
      {/* CNS */}
      <div className=" ">
        <InputArea
          control={control}
          name="cns"
          label="CNS "
          placeholder="CNS"
        />
      </div>
      {/* PA */}
      <div className=" ">
        <InputArea control={control} name="pa" label="PA " placeholder="PA" />
      </div>
      {/* Menstrual history */}
      {props.patientData && props.patientData.gender === "Female" ? (
        <div className=" ">
          <InputArea
            control={control}
            name="menstrualhistory"
            label="Menstrual History "
            placeholder="Menstrual History"
          />
        </div>
      ) : null}
    </div>
  );
};

export default SystematicExamination;
