import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import {
  fetchRack,
  fetchShelf,
} from "../../../../commonServices/miscellaneousServices/MiscServices";

import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

//main function
export default function StoreStockMinMaxTable(props) {
  const [rack, setRack] = React.useState([]);
  const [shelf, setShelf] = React.useState([]);

  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleSelectData = (index, row, e) => {
    let storeInventoryMinMaxVal = watch(`storeInventoryMinMax${index}`);
    let selectedRow = [...props.selectRowData];
    if (storeInventoryMinMaxVal === true) {
      selectedRow.push(row);

      props.setSelectRowData(selectedRow);
    } else if (storeInventoryMinMaxVal === false) {
    }
  };

  function handleRackChange(autoSearchString) {
    // if (autoSearchString !== "") {
    fetchRack(autoSearchString)
      .then((response) => response.data)
      .then((res) => {
        setRack(res.result);
      });
    // }
  }

  // call shelf dropdown api
  function handleShelfChange(searchString) {
    if (searchString !== "") {
      fetchShelf(searchString)
        .then((response) => response.data)
        .then((res) => {
          setShelf(res.result);
        });
    }
  }

  return (
    <>
      <div className="grid w-auto text-center">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-72 2xl:h-56"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                className="border "
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.2,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center  whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>

                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Min Stock
                      </span>
                    </TableCell>

                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Max Stock
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Eco Order
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Reorder Level
                      </span>
                    </TableCell>
                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                       Storage Area
                      </span>
                    </TableCell> */}
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Rack
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Shelf
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className="border ">
                  {props?.data?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {}}
                        sx={{
                          "& td": {
                            paddingY: 0,
                            // backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          <fieldset
                            className="m-0 p-0"
                            disabled={true}
                            onChange={(e) => {
                              handleSelectData(index, row, e);
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              defaultValue={true}
                              name={`storeInventoryMinMax${index}`}
                            />
                          </fieldset>
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.Description}
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          <input
                            type="number"
                            name={`minStockInput${index}`}
                            defaultValue={row.MinStockLevel}
                            className="border rounded border-gray-400 w-20"
                            onChange={(e) => {
                              let dataArray = [...props.data];
                              let rowData = row;
                              rowData.MinStockLevel = Number(e.target.value);
                              dataArray[index] = rowData;
                              props.setStoreStockList(dataArray);
                            }}
                          />
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          <input
                            type="number"
                            name={`maxStockInput${index}`}
                            defaultValue={row.MaxStockLevel}
                            className="border rounded border-gray-400 w-20"
                            onChange={(e) => {
                              let dataArray = [...props.data];
                              let rowData = row;
                              rowData.MaxStockLevel = Number(e.target.value);
                              dataArray[index] = rowData;
                              props.setStoreStockList(dataArray);
                            }}
                          />
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.EconomicOrderQuantity}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.reorder}
                        </TableCell>
                        {/* <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.storageArea}
                        </TableCell> */}
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          <div className="w-36">
                            <SearchDropdown
                              control={control}
                              error={errors.rackName}
                              name={`rowRack${index}`}
                              label="Rack"
                              defaultValue={{
                                id: row.RackId,
                                value: row.RackId,
                                label: row.Rack,
                              }}
                              placeholder="Rack"
                              searchIcon={true}
                              isSearchable={true}
                              isClearable={true}
                              dataArray={rack}
                              handleInputChange={handleRackChange}
                              inputRef={{
                                ...register(`rowRack${index}`, {
                                  onChange: (e) => {
                                    if (e.target.value !== null) {
                                      let dataArray = [...props.data];
                                      let rowData = row;
                                      rowData.Rack = e.target.value;
                                      rowData.RackId = e.target.value.id;
                                      dataArray[index] = rowData;
                                      props.setStoreStockList(dataArray);
                                    } else {
                                      e.target.value = "";
                                    }
                                  },
                                }),
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          <div className="w-36">
                            <SearchDropdown
                              control={control}
                              error={errors.shelfName}
                              name={`rowShelf${index}`}
                              label="Shelf"
                              defaultValue={{
                                id: row.ShelfId,
                                label: row.Shelf,
                                value: row.Shelf,
                              }}
                              placeholder="Shelf"
                              searchIcon={true}
                              isSearchable={true}
                              isClearable={true}
                              dataArray={shelf}
                              handleInputChange={handleShelfChange}
                              inputRef={{
                                ...register(`rowShelf${index}`, {
                                  onChange: (e) => {
                                    if (e.target.value !== null) {
                                      let dataArray = [...props.data];
                                      let rowData = row;
                                      rowData.Shelf = e.target.value;
                                      rowData.ShelfId = e.target.value.id;
                                      dataArray[index] = rowData;
                                      props.setStoreStockList(dataArray);
                                    } else {
                                      e.target.value = "";
                                    }
                                  },
                                }),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
