import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackdrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  addNewBillEstimation,
  bedCategoryList,
  getTemplateDetailsById,
  groupSelectionDropDown,
  groupServiceDropDown,
  ipdAutocomplete,
  procedureService,
  searchPatientInfo,
  searchPatientList,
  templateList,
  treatingDoctorList,
} from "../../services/billestimation/BillEstimationService";
import ViewBillEstimation from "./ViewBillEstimation";
import AdministrationChargesTable from "./billestimationtables/AdministrationChargesTable";
import HospitalizationTable from "./billestimationtables/HospitalizationTable";
import OperationTheaterTable from "./billestimationtables/OperationTheaterTable";
import PharmacyChargesTable from "./billestimationtables/PharmacyChargesTable";
import SelectBedCategoryTable from "./billestimationtables/SelectBedCategoryTable";

const patientType = [
  {
    id: "OPD",
    value: "OPD",
    label: "OPD",
  },
  {
    id: "IPD",
    value: "IPD",
    label: "IPD",
  },
];

export default function BillEstimation() {
  const schema = yup.object().shape({
    treatingDr: yup
      .object()
      .nullable()
      .required()
      .shape({
        value: yup.string().required("Please Select Doctor"),
        label: yup.string().required("Please Select Doctor"),
      }),
    selectTemplate: yup
      .object()
      .nullable()
      .required()
      .shape({
        value: yup.string().required("Please Select Doctor"),
        label: yup.string().required("Please Select Doctor"),
      }),
    // approxDays: yup.number().required(),
  });

  const defaultValues = {
    treatingDr: null,
    selectTemplate: null,
    patientType: "OPD",
    tentativeDate: new Date(),
    grpService: false,
    approxDays: "",
    procedureService: null,
    groupSection: null,
    groupGroup: null,
    selectService: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  let templateWatch = watch("selectTemplate");

  const [finalData, setFinalData] = React.useState({});
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [selectedTentativeDate, setSelectedTentativeDate] = React.useState(
    new Date()
  );

  const [options, setOptions] = React.useState([]);
  const [treatingDrOptions, setTreatingDrOptions] = React.useState([]);
  const [templateOptions, setTemplateOptions] = React.useState([]);
  const [procedureOptions, setProcedureOptions] = React.useState([]);
  const [gupSection, setGrpSection] = React.useState([]);
  const [group, setGroup] = React.useState([]);
  const [bedCategory, setBedCategory] = React.useState([]);
  const [patientInfoId, setPatientInfoId] = React.useState(null);
  const [patientData, setPatientData] = React.useState(null);
  const [hospitalization, setHospitalization] = React.useState([]);
  const [hospitalizationTotal, setHospitalizationTotal] = React.useState([]);
  const [otTableData, setOtTableData] = React.useState([]);
  const [otTotal, setOtTotal] = React.useState([]);
  const [pharmacyTableData, setPharmacyTableData] = React.useState([]);
  const [pharmacyTotal, setPharmacyTotal] = React.useState([]);
  const [adminTableData, setAdminTableData] = React.useState([]);
  const [adminTotal, setAdminTotal] = React.useState([]);
  const [templateData, setTemplateData] = React.useState([]);
  const [templateId, setTemplateId] = React.useState(null);
  const [selectedNameString, setSelectedNameString] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState("");
  const [parentCalculations, setParentCalculations] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState([]);
  const [menuId, setMenuId] = React.useState(null);

  const mergeFinalArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.label === val.label);
      if (ind !== -1) {
        acc[ind]["totalCharge"] = acc[ind]["totalCharge"] + val["totalCharge"];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  React.useEffect(() => {
    setGrandTotal([]);
    let hospitalDataTotal = structuredClone(hospitalizationTotal);
    let otDataTotal = structuredClone(otTotal);
    let pharmacyDataTotal = structuredClone(pharmacyTotal);
    let adminDataTotal = structuredClone(adminTotal);
    setGrandTotal(
      mergeFinalArray([
        ...hospitalDataTotal,
        ...otDataTotal,
        ...pharmacyDataTotal,
        ...adminDataTotal,
      ])
    );
  }, [hospitalizationTotal, otTotal, pharmacyTotal, adminTotal]);

  let location = useLocation();

  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  const handleCloseViewModal = () => {
    if (openViewModal === true) {
      setOpenViewModal(false);
    }
  };

  const [selectedGroups, setSelectedGroups] = React.useState([]);
  let approxDaysWatch = watch("approxDays");

  React.useEffect(() => {
    if (templateWatch !== null) {
      setShowSpinner(true);
      setTemplateId(templateWatch?.id);
      setTemplateData([]);
      setHospitalization([]);
      setOtTableData([]);
      setPharmacyTableData([]);
      setAdminTableData([]);
      getTemplateDetailsById(templateWatch?.id)
        .then((response) => response.data)
        .then((res) => {
          setTemplateData(res.result);
          setShowSpinner(false);
        });
    } else if (templateWatch === null) {
      setTemplateData([]);
    }
  }, [templateWatch]);

  const getStore = JSON.parse(localStorage.getItem("userInfo"));
  let grpService = watch("grpService");

  let pType = watch("patientType");
  let patientTypeVal;

  if (pType === "OPD") {
    patientTypeVal = 0;
  } else if (pType === "IPD") {
    patientTypeVal = 1;
  }

  //AutoSearch Patient
  function handleChange(string) {
    if (pType === "OPD") {
      string &&
        searchPatientList(string)
          .then((response) => response.data)
          .then((res) => {
            setOptions(res.result);
          });
    } else {
      string &&
        ipdAutocomplete(string)
          .then((response) => response.data)
          .then((res) => {
            setOptions(res.result);
          });
    }
  }

  //Treating Doctor API
  function handleTreatingDrChange(string) {
    treatingDoctorList(string)
      .then((response) => response.data)
      .then((res) => {
        setTreatingDrOptions(res.result);
      });
  }

  //Template LIst API
  function handleTemplateChange(string) {
    templateList(string)
      .then((response) => response.data)
      .then((res) => {
        setTemplateOptions(res.result);
      });
  }

  //Procedure Service API
  function handleProcedureChange(string) {
    procedureService(string)
      .then((response) => response.data)
      .then((res) => {
        setProcedureOptions(res.result);
      });
  }

  React.useEffect(() => {
    groupSelectionDropDown().then((response) => {
      setGrpSection(response.data.result);
    });

    groupServiceDropDown().then((response) => {
      setGroup(response.data.result);
    });
    callenquiryVisibleTableApi();
  }, []);

  React.useEffect(() => {
    searchPatientInfo(patientInfoId).then((response) => {
      setPatientData(response.data.result);
    });
  }, [patientInfoId]);

  const callenquiryVisibleTableApi = () => {
    bedCategoryList()
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setBedCategory(res.result);
      });
  };

  // Function to post bill estimation final object
  function postBillEstimation(obj) {
    addNewBillEstimation(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          reset(defaultValues);
          setTemplateData([]);
          setHospitalization([]);
          setOtTableData([]);
          setPharmacyTableData([]);
          setAdminTableData([]);
          setValue("SearchableSelect", null);
          setSelectedTentativeDate(new Date());
          setValue("selectGroup", null);
          setValue("selectService", null);
          setPatientData(null);
          callenquiryVisibleTableApi();
        }
      })
      .catch((error) => {
        errorAlert(error.message || error.response.data.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postBillEstimation(finalData);
  }

  let tentativeDate = format(selectedTentativeDate, "dd-MM-yyyy'T'HH:mm:ss'Z'");
  let treatingDoctor = watch("treatingDr");
  let patient = watch("SearchableSelect");

  React.useEffect(() => {
    if (patient === null) {
      setPatientData(null);
    }
  }, [patient]);

  const onSubmitDataHandler = (data) => {
    let billEstimationDetailsArray = [];

    //creating objects to save : Hospitalization group
    for (let hospitalObject of hospitalization) {
      for (let classObject of hospitalObject.selectedClasses) {
        if (selectedNameString.toString().includes(classObject.label)) {
          let object = {};
          object.groupId = hospitalObject?.selectGroup?.id;
          object.classId = classObject.classId;
          object.rate = classObject.totalCharge;
          object.quantity = 0;
          billEstimationDetailsArray.push(object);
        }
      }
    }

    //creating object to save : Operation Theater Group
    for (let otObject of otTableData) {
      for (let classObject of otObject.selectedClasses) {
        if (selectedNameString.toString().includes(classObject.label)) {
          let object = {};
          object.groupId = otObject?.selectGroup?.id;
          object.classId = classObject.classId;
          object.rate = classObject.totalCharge;
          object.quantity = 0;
          billEstimationDetailsArray.push(object);
        }
      }
    }

    //creating object to save : Pharmacy Group
    for (let pharmacyObject of pharmacyTableData) {
      for (let classObject of pharmacyObject.selectedClasses) {
        if (selectedNameString.toString().includes(classObject.label)) {
          let object = {};
          object.groupId = pharmacyObject?.selectGroup?.id;
          object.classId = classObject.classId;
          object.rate = classObject.totalCharge;
          object.quantity = 0;
          billEstimationDetailsArray.push(object);
        }
      }
    }

    //creating object to save : Admin Group
    for (let adminObject of adminTableData) {
      for (let classObject of adminObject.selectedClasses) {
        if (selectedNameString.toString().includes(classObject.label)) {
          let object = {};
          object.groupId = adminObject?.selectGroup?.id;
          object.classId = classObject.classId;
          object.rate = classObject.totalCharge;
          object.quantity = 0;
          billEstimationDetailsArray.push(object);
        }
      }
    }

    let opdIpdExternal;

    if (pType === "OPD") {
      opdIpdExternal = patient?.VisitId;
    } else if (pType === "IPD") {
      opdIpdExternal = patient.AdmissionId;
    }

    // The final post object craetion for Bill Estimation
    let ageYears = Number(patientData?.ageInYears.split("Y")[0]);
    let saveObj = {
      menuId: menuId,
      addedBy: getStore.userId,
      address:
        patientData?.FlatHouseNo !== null
          ? patientData?.FlatHouseNo
          : "" + "" + patientData?.Society !== null
          ? patientData?.Society !== null
          : "" + "" + patientData?.city !== null
          ? patientData?.city
          : "" + "" + patientData?.area !== null
          ? patientData?.area
          : "" + "" + patientData?.pincode !== null
          ? patientData?.pincode
          : "",
      ageInDay: patientData?.ageInDays,
      ageInMonth: patientData?.ageInMonths,
      ageInYear: ageYears,
      approximateDays: approxDaysWatch > 0 ? approxDaysWatch : 0,
      billEstimationDetailsDtoList: billEstimationDetailsArray,
      billTemplateId: templateId,
      contactNo: patientData?.mobileNo,
      firstName: patientData?.firstName,
      middleName: patientData?.middleName,
      lastName: patientData?.lastName,
      gender: patientData?.gender,
      opdIpd: patientTypeVal,
      opdIpdExternalId:
        pType === "OPD" ? patientData?.visitMarked : patient.AdmissionId,
      relativeContactNo: 0,
      relativeName: 0,
      serviceId: 1,
      tentativeAdmDate: selectedTentativeDate,
      totalAmount: 0,
      treatingDocId: treatingDoctor?.id,
      menuId: location?.state?.menuId,
      privilege: privilege.toString(),
    };
    setOpenPost(true);
    setFinalData(saveObj);
  };

  let getGroupSection = watch("groupSection");
  let getProceService = watch("proceService");
  let getSelectService = watch("selectService");
  let getSelectGroup = watch("selectGroup");

  //function for categorizing data as per data.label matches to any table
  const handleAddGroups = () => {
    let estimationObj = {
      groupSection: getGroupSection,
      proceService: getProceService,
    };

    if (grpService === false) {
      estimationObj.selectGroup = getSelectGroup;
    } else if (grpService === true) {
      estimationObj.selectGroup = getSelectService;
    }

    let categoryArray = [];

    for (let categoryObject of bedCategory) {
      if (categoryObject["Group Name"] !== " ") {
        let object = {
          label: categoryObject["Group Name"],
          totalCharge: 0,
          classId: categoryObject.Id,
        };
        categoryArray.push(object);
        estimationObj.selectedClasses = categoryArray;
      }
    }

    if (getGroupSection?.label.includes("A)")) {
      setHospitalization([...hospitalization, estimationObj]);
    } else if (getGroupSection?.label.includes("B)")) {
      setOtTableData([...otTableData, estimationObj]);
    } else if (getGroupSection?.label.includes("C)")) {
      setPharmacyTableData([...pharmacyTableData, estimationObj]);
    } else if (getGroupSection?.label.includes("D)")) {
      setAdminTableData([...adminTableData, estimationObj]);
    }
    setValue("procedureService", null);
    setValue("selectService", null);
    setValue("selectGroup", null);
    setValue("groupSection", null);
  };

  //useEffect to differentiate template data received as per group tables
  React.useEffect(() => {
    if (templateData.length > 0) {
      for (let templateObject of templateData) {
        if (templateObject.groupSelection.includes("A)")) {
          let groupArray = [];

          for (let groupObject of templateObject.sectionGroupsDtoList) {
            let object = {
              groupSection: {
                id: templateObject.sectionId,
                label: templateObject.groupSelection,
                value: templateObject.groupSelection,
              },
              selectGroup: {
                id: groupObject.groupId,
                label: groupObject.group,
                value: groupObject.group,
              },
            };

            let classArray = [];
            for (let classObject of groupObject.classRateDetailsDtoList) {
              let objectClass = {
                label: classObject.className,
                totalCharge: classObject.rate,
                classId: classObject.classID,
              };
              classArray.push(objectClass);
              object.selectedClasses = classArray;
            }
            groupArray.push(object);
            setHospitalization(groupArray);
          }
        } else if (templateObject.groupSelection.includes("B)")) {
          let groupArray = [];

          for (let groupObject of templateObject.sectionGroupsDtoList) {
            let object = {
              groupSection: {
                id: templateObject.sectionId,
                label: templateObject.groupSelection,
                value: templateObject.groupSelection,
              },
              selectGroup: {
                id: groupObject.groupId,
                label: groupObject.group,
                value: groupObject.group,
              },
            };

            let classArray = [];
            for (let classObject of groupObject.classRateDetailsDtoList) {
              let objectClass = {
                label: classObject.className,
                totalCharge: classObject.rate,
                classId: classObject.classID,
              };
              classArray.push(objectClass);
              object.selectedClasses = classArray;
            }
            groupArray.push(object);
            setOtTableData(groupArray);
          }
        } else if (templateObject.groupSelection.includes("C)")) {
          let groupArray = [];

          for (let groupObject of templateObject.sectionGroupsDtoList) {
            let object = {
              groupSection: {
                id: templateObject.sectionId,
                label: templateObject.groupSelection,
                value: templateObject.groupSelection,
              },
              selectGroup: {
                id: groupObject.groupId,
                label: groupObject.group,
                value: groupObject.group,
              },
            };

            let classArray = [];
            for (let classObject of groupObject.classRateDetailsDtoList) {
              let objectClass = {
                label: classObject.className,
                totalCharge: classObject.rate,
                classId: classObject.classID,
              };
              classArray.push(objectClass);
              object.selectedClasses = classArray;
            }
            groupArray.push(object);
            setPharmacyTableData(groupArray);
          }
        } else if (templateObject.groupSelection.includes("D)")) {
          // Handeling groupArray and classArray in the estimation component
          let groupArray = [];

          for (let groupObject of templateObject.sectionGroupsDtoList) {
            let object = {
              groupSection: {
                id: templateObject.sectionId,
                label: templateObject.groupSelection,
                value: templateObject.groupSelection,
              },
              selectGroup: {
                id: groupObject.groupId,
                label: groupObject.group,
                value: groupObject.group,
              },
            };

            let classArray = [];
            for (let classObject of groupObject.classRateDetailsDtoList) {
              let objectClass = {
                label: classObject.className,
                totalCharge: classObject.rate,
                classId: classObject.classID,
              };
              classArray.push(objectClass);
              object.selectedClasses = classArray;
            }
            groupArray.push(object);
            setAdminTableData(groupArray);
          }
        }
      }
    }
  }, [templateData]);

  //making a selected group names string to adjust the table columns
  React.useEffect(() => {
    let nameString = "";
    if (selectedGroups.length > 0) {
      for (let group of selectedGroups) {
        nameString = nameString + group.label;
      }
      setSelectedNameString(nameString);
    } else {
      setSelectedNameString("");
    }
  }, [selectedGroups]);

  return (
    <div className="px-4 mt-12 w-full ">
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <p className="text-center items-center text-black font-bold text-xl ">
          Bill Estimation
        </p>
        <div className="flex gap-2 w-full py-2">
          <div className="w-[70%] border-r-[1px] border-customBlue pr-2">
            <div className="flex gap-2">
              <div className="flex items-center space-x-2">
                <label className="font-semibold whitespace-nowrap">
                  Patient Type:
                </label>

                <span className="pt-1">
                  <RadioField
                    label=""
                    name="patientType"
                    control={control}
                    dataArray={patientType}
                    onChange={(e) => {
                      setValue("SearchableSelect", null);
                    }}
                  />
                </span>
              </div>

              <div className="w-[40%]">
                <SearchDropdown
                  control={control}
                  searchIcon={true}
                  isDisabled={false}
                  name="SearchableSelect"
                  label="Search By Patient Name / MRNo."
                  placeholder="Search By Patient Name / MRNo."
                  isMulti={false}
                  dataArray={options}
                  isClearable={true}
                  handleInputChange={handleChange}
                  inputRef={{
                    ...register("SearchableSelect", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          setPatientInfoId(e.target.value.id);
                        } else if (e.target.value === null) {
                          setPatientInfoId(null);
                          reset(defaultValues);
                          setTemplateData([]);
                          setHospitalization([]);
                          setOtTableData([]);
                          setPharmacyTableData([]);
                          setAdminTableData([]);
                          setValue("SearchableSelect", null);
                          setSelectedTentativeDate(new Date());
                          setValue("selectGroup", null);
                          setValue("selectService", null);
                          setPatientData(null);
                        }
                      },
                    }),
                  }}
                />
              </div>
              {userActions.length > 0
                ? userActions.map((actions) => {
                    return !actions.isAction && actions.action === "View" ? (
                      <CommonButton
                        className="bg-customBlue px-2 rounded h-9 text-white ml-28"
                        type="button"
                        onClick={() => {
                          setOpenViewModal(true);
                        }}
                        label="View"
                      />
                    ) : (
                      ""
                    );
                  })
                : ""}
            </div>

            <fieldset className=" my-3 px-1 grid grid-cols-2 py-2 rounded border bg-[#F1F1F1] border-gray-300 whitespace-nowrap">
              <div className="flex items-center gap-2 w-full font-semibold ">
                <span className="font-semibold w-28"> Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientData?.patientName}
                  </span>
                </div>
              </div>
              <div className="flex  items-center gap-2 w-full font-semibold">
                <span className="font-semibold w-16">Gender</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientData?.gender}
                  </span>
                </div>
              </div>

              <div className="flex  items-center gap-2 w-full font-semibold">
                <span className="font-semibold w-28">Address</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientData?.officeAddress !== ""
                      ? patientData?.officeAddress
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex  items-center gap-2 w-full font-semibold">
                <span className="font-semibold w-16">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientData?.ageInYears}
                  </span>
                </div>
              </div>
            </fieldset>

            <div className="grid grid-cols-2 gap-3">
              <div className="grid grid-cols-2 gap-3 border-r-[1px] border-customBlue pr-3">
                <fieldset disabled={patientData === null ? true : false}>
                  <SearchDropdown
                    control={control}
                    name="treatingDr"
                    error={errors.treatingDr}
                    placeholder="Treating Doctor"
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    dataArray={treatingDrOptions}
                    handleInputChange={handleTreatingDrChange}
                  />
                </fieldset>
                <fieldset disabled={patientData === null ? true : false}>
                  <SearchDropdown
                    control={control}
                    name="selectTemplate"
                    placeholder="Select Template"
                    error={errors.selectTemplate}
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    dataArray={templateOptions}
                    handleInputChange={handleTemplateChange}
                  />
                </fieldset>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Tentative Adm Date"
                    name="tentativeDate"
                    value={selectedTentativeDate}
                    onChange={(newValue) => {
                      setSelectedTentativeDate(newValue);
                    }}
                    disableFuture={true}
                    renderInput={(params) => (
                      <TextField
                        className="bg-white"
                        fullWidth
                        size="small"
                        inputFormat="dd/MM/yyyy"
                        {...params}
                        sx={{
                          svg: { color: "#0B83A5" },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <fieldset disabled={patientData === null ? true : false}>
                  <InputField
                    name="approxDays"
                    variant="outlined"
                    label="Approx. Days"
                    control={control}
                    error={errors.approxDays}
                    type="number"
                  />
                </fieldset>
              </div>
              <div className="grid grid-cols-2 gap-3 pr-2">
                <SearchDropdown
                  control={control}
                  name="procedureService"
                  placeholder="Procedure Service"
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  dataArray={procedureOptions}
                  handleInputChange={handleProcedureChange}
                />
                <DropdownField
                  control={control}
                  error={errors.groupSection}
                  name="groupSection"
                  placeholder="Group Section"
                  dataArray={gupSection}
                />

                <div className="flex col-span-2 gap-3 ">
                  <CheckBoxField
                    control={control}
                    name="grpService"
                    label="Group/Service"
                  />

                  {grpService === false ? (
                    <DropdownField
                      control={control}
                      error={errors.selectGroup}
                      name="selectGroup"
                      placeholder="Select Group"
                      dataArray={group}
                      isClearable={true}
                    />
                  ) : (
                    <SearchDropdown
                      control={control}
                      name="selectService"
                      placeholder="Select Service"
                      searchIcon={true}
                      isSearchable={true}
                      isClearable={true}
                      dataArray={procedureOptions}
                      handleInputChange={handleProcedureChange}
                    />
                  )}
                  <CommonButton
                    type="button"
                    className="h-9 px-3 text-sm 2xl:text-base font-medium bg-green-700 text-white rounded "
                    onClick={handleAddGroups}
                    label="Add"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[30%] pl-2">
            <span className="font-semibold">Selected Bed Category</span>
            <SelectBedCategoryTable
              bedCategory={bedCategory}
              selectedGroups={selectedGroups}
              setSelectedGroups={setSelectedGroups}
            />
          </div>
        </div>

        {showSpinner === true && templateWatch !== null ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {templateData?.length > 0 ? (
              <>
                <div className="overflow-scroll w-full h-60">
                  {/* Hospitalization Table */}
                  <HospitalizationTable
                    selectedGroups={selectedGroups}
                    selectedNameString={selectedNameString}
                    setSelectedGroups={setSelectedGroups}
                    hospitalization={hospitalization}
                    setHospitalization={setHospitalization}
                    setHospitalizationTotal={setHospitalizationTotal}
                    setParentCalculations={setParentCalculations}
                  />
                  {/* Opration Theater Table */}
                  <OperationTheaterTable
                    selectedGroups={selectedGroups}
                    selectedNameString={selectedNameString}
                    otTableData={otTableData}
                    setOtTableData={setOtTableData}
                    setOtTotal={setOtTotal}
                    setParentCalculations={setParentCalculations}
                  />
                  {/* Pharmacy Charges */}
                  <PharmacyChargesTable
                    selectedGroups={selectedGroups}
                    selectedNameString={selectedNameString}
                    pharmacyTableData={pharmacyTableData}
                    setPharmacyTableData={setPharmacyTableData}
                    setPharmacyTotal={setPharmacyTotal}
                    setParentCalculations={setParentCalculations}
                  />
                  {/* Administration Charges */}
                  <AdministrationChargesTable
                    selectedGroups={selectedGroups}
                    selectedNameString={selectedNameString}
                    adminTableData={adminTableData}
                    setAdminTableData={setAdminTableData}
                    setAdminTotal={setAdminTotal}
                    setParentCalculations={setParentCalculations}
                  />
                </div>

                <div className="w-auto grid">
                  <TableRow
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "#fcf9e6",
                      zIndex: 1,
                      width: "100%",
                    }}
                  ></TableRow>
                </div>
                <div className="w-auto grid mt-5">
                  <Box sx={{ width: "100%", overflow: "hidden" }}>
                    <Paper sx={{ width: "100%", my: 1 }}>
                      <TableContainer
                        sx={{
                          "&::-webkit-scrollbar": {
                            width: 7,
                            height: 7,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#7393B3",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "lightBlue",
                            cursor: "pointer",
                          },
                        }}
                        className="rounded"
                        style={{
                          borderLeft: "1px solid lightgray overflow-hidden",
                        }}
                      >
                        <Table
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className="whitespace-nowrap"
                                style={{ background: "#F1F1F1" }}
                              ></TableCell>
                              <TableCell
                                className="whitespace-nowrap"
                                style={{ background: "#F1F1F1" }}
                              ></TableCell>
                              <TableCell
                                className="whitespace-nowrap"
                                style={{ background: "#F1F1F1" }}
                              >
                                <span className="font-bold w-32">
                                  Class&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                              </TableCell>
                              {hospitalization[0]?.selectedClasses?.map(
                                (item) => {
                                  return (
                                    <TableCell
                                      className="whitespace-nowrap"
                                      style={{ background: "#F1F1F1" }}
                                      sx={{ textAlign: "center" }}
                                    >
                                      {selectedNameString
                                        .toString()
                                        .includes(item.label) ? (
                                        <span className="font-bold">
                                          {item.label}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow
                              sx={{
                                "& td": {
                                  paddingY: 0.5,
                                },
                              }}
                              tabIndex={-1}
                              // key={index.id}
                              onClick={() => {
                                // handleClick(index, row);
                              }}
                            >
                              <TableCell className="whitespace-nowrap overflow-ellipsis capitalize "></TableCell>
                              <TableCell className="whitespace-nowrap"></TableCell>
                              <TableCell className="whitespace-nowrap overflow-ellipsis capitalize ">
                                <span className="font-semibold w-32 ">
                                  Total :
                                </span>
                              </TableCell>
                              {grandTotal &&
                                grandTotal.map((row, index) => {
                                  return (
                                    <TableCell className="whitespace-nowrap overflow-ellipsis capitalize ">
                                      <span className="w-24 flex justify-center">
                                        {row.totalCharge}
                                      </span>
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        )}

        <div className="flex gap-3 my-1 justify-end">
          <CommonButton
            className="px-2 rounded bg-white border border-customRed text-customRed h-9"
            type="button"
            label="Reset"
            onClick={() => {
              reset(defaultValues);
              setTemplateData([]);
              setHospitalization([]);
              setOtTableData([]);
              setPharmacyTableData([]);
              setAdminTableData([]);
              setValue("SearchableSelect", null);
              setSelectedTentativeDate(new Date());
              setPatientData(null);
              callenquiryVisibleTableApi();
            }}
          />
          {userActions.length > 0
            ? userActions.map((actions) => {
                return !actions.isAction && actions.action === "Create" ? (
                  <fieldset
                    onClick={() => {
                      setPrivilege(actions.action);
                      setMenuId(actions.id);
                    }}
                  >
                    <CommonButton
                      className="h-9 px-2 text-white bg-customGreen rounded "
                      label="Save"
                      type="Submit"
                    />
                  </fieldset>
                ) : (
                  ""
                );
              })
            : ""}
        </div>
      </form>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
      <ViewBillEstimation open={openViewModal} close={handleCloseViewModal} />
      <CommonBackdrop openBackdrop={openBackdrop} />
    </div>
  );
}

