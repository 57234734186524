//function to patch the data in patient vulerability form.
function patchPatientVulnerability(vulnerabilityPatchDtoObjVal, methods) {
  
 

  if (vulnerabilityPatchDtoObjVal.historyOfPreviousFalls) {
    methods.setValue("History of Previous Falls", true);
  }

  if (vulnerabilityPatchDtoObjVal.patientsAbove65Years) {
    methods.setValue("Patient Who Are Above 65 years.", true);
  }

  if (vulnerabilityPatchDtoObjVal.belowAgeOf16Years) {
    
    methods.setValue("Below Age of 16 years.", true);
  }

  
  

  if (vulnerabilityPatchDtoObjVal.physicalLimitationBothVisualAndMobility) {
    methods.setValue(
      "Patients with Physical Limitations :- Both Visual and Mobility.",
      true
    );
  }

  if (vulnerabilityPatchDtoObjVal.cerebralVascularAccident) {
    methods.setValue("Patients With Cerebral vasculr Accident (CVA).", true);
  }

  if (vulnerabilityPatchDtoObjVal.posturalHypotension) {
    methods.setValue(
      "Patients Who Have Postural Hypotension and Dizziness / Vertigo.",
      true
    );
  }

  if (vulnerabilityPatchDtoObjVal.emotionallyDisturbed) {
    methods.setValue("Emotionally Disturbed / psychiatric Pts.", true);
  }

  if (vulnerabilityPatchDtoObjVal.visualHearingDefects) {
    methods.setValue("Patients With Visual/ hearing Defects.", true);
  }

  if (vulnerabilityPatchDtoObjVal.postOperative) {
    methods.setValue("Post Operative in the last 24 hours.", true);
  }

  if (vulnerabilityPatchDtoObjVal.terminallyIII) {
    methods.setValue("Terminally ill.", true);
  }

  if (vulnerabilityPatchDtoObjVal.experiencingPain) {
    methods.setValue("Experiencing pain.", true);
  }

  if (vulnerabilityPatchDtoObjVal.womanInLabour) {
    methods.setValue("Women in labour.", true);
  }

  if (vulnerabilityPatchDtoObjVal.suspectedDrug) {
    methods.setValue("Victim of Suspected drug/ Substance Abuse.", true);
  }

  if (vulnerabilityPatchDtoObjVal.assessmentScore) {
    methods.setValue("Patient Fall Assessment score of >13.", true);
  }
}

export default patchPatientVulnerability;
