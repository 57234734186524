import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
//Fields
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
//Button
import CommonButton from "../../../../common/components/Buttons/CommonButton";
//Modal
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import EnquiryListModal from "./EnquiryListModal";
import HeaderModal from "./HeaderModal";
//Table
import PurchaseEnquiryItemTable from "./PurchaseEnquiryItemTable";
import TermsNConditionsTable from "./TermsNConditionsTable";
//Alert
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
//Services
import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { searchSupplier } from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import {
  addNewEnquiry,
  fetchAllEnquiry,
  fetchTermsOfPayment,
  itemDetailsSearch,
} from "../../../services/purchase/EnquiryServices";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";

export default function Enquiry() {
  const schema = yup.object().shape({
    supplierMasters: yup
      .array()
      .nullable()
      .min(1, "Required")
      .of(
        yup.object().shape({
          label: yup.string().required("Required"),
          value: yup.string().required("Required"),
        })
      )
      .required("Required"),
    store: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Please Select Supplier"),
        label: yup.string().required("Please Select Supplier"),
      }),
  });

  const defaultValues = {
    supplierMasters: null,
    store: null,
    itemMaster: null,
    uom: "",
    quantity: "",
    packSize: "",
    remarks: "",
    note: "",
    termsNConditions: null,
  };

  const resetFields = () => {
    setValue("itemMaster", null);
    setValue("uom", "");
    setValue("packSize", "");
    setValue("quantity", "");
    setValue("remarks", "");
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    getValues,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //State Variables
  const [itemOptions, setItemOption] = React.useState();
  const [storeOptions, setStoreOptions] = React.useState([]);
  const [supplierOptions, setSupplierOptions] = React.useState([]);
  const [termsnConditionsOptions, setTermsnConditionsOptions] = React.useState(
    []
  );
  const [finalData, setFinalData] = React.useState({});
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [itemData, setItemData] = React.useState([]);
  const [termsnConditionData, setTermsnConditionData] = React.useState([]);
  //Modal For HeaderModal
  const [openHeader, setOpenHeader] = React.useState(false);
  const handleHeaderOpen = () => setOpenHeader(true);
  const handleHeaderClose = () => setOpenHeader(false);
  const [headerStore, setHeaderStore] = React.useState(
    "Dear Sir, \n Please quote your lowest rates for the following as per the Terms & Conditions mentioned here below."
  );
  //Modal For EnquiryListModal
  const [postEnquiryListModal, setOpenEnquiryModal] = React.useState(false);
  const handleEnquiryListOpen = () => setOpenEnquiryModal(true);
  const handleEnquiryListClose = () => setOpenEnquiryModal(false);
  //Enquiry Listing against patch Supplier ... & getting selected row
  const [enquiryItemRow, setEnquiryItemRow] = React.useState(null);
  //Table
  const [supplierMaster, setSupplierMaster] = React.useState([]); // Table DefaultParams
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [searchEnquiryString, setEnquirySearchString] = React.useState("");
  const [enquirySupplierId, setEnquirySupplierId] = React.useState(0);
  const [dataResultEnquiryList, setDataResultEnquiryList] = React.useState([]);
  const [
    selectedEnquiryListFromDate,
    setSelectedEnquiryListFromDate,
  ] = React.useState(new Date());
  const [
    selectedEnquiryListToDate,
    setSelectedEnquiryListToDate,
  ] = React.useState(new Date());
  /////////////////////Line Item Edit Enquiry Modal //////////////////
  const [enquiryEdit, setEnquiryEdit] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [privilege, setPrivilege] = React.useState("");
  const [userActions, setUserActions] = useState([]);

  const location = useLocation();
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  // variable
  const getStore = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = getStore.storeId;
  let storeVal = watch("store");
  let deptStore1 = storeVal && storeVal.id;
  let itemMaster = watch("itemMaster");
  let quantity = watch("quantity");
  let termsNConditions = watch("termsNConditions");
  let uom = watch("uom");
  let packSize = watch("packSize");
  let remarks = watch("remarks");

  const populateTable = (forPagination) => {
    let defaultParams = {
      fromDate: selectedEnquiryListFromDate,
      searchString: searchEnquiryString,
      supplierId: enquirySupplierId,
      toDate: selectedEnquiryListToDate,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    if (postEnquiryListModal === true) {
      fetchAllEnquiry(defaultParams)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (forPagination) {
            setDataResultEnquiryList((prevData) => [
              ...prevData,
              ...res.result,
            ]);
          } else {
            setDataResultEnquiryList(res.result);
            setPage(0);
          }
          setCount(res.count);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  const handleSupplierChange = (autoSearchSupplier) => {
    if (
      autoSearchSupplier !== "" &&
      UseCheckConsecutiveCharacters(autoSearchSupplier)
    ) {
      searchSupplier(autoSearchSupplier)
        .then((response) => response.data)
        .then((res) => {
          setSupplierOptions(res.result);
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    }
  };

  //Search Dropdown API
  const handleChange = (autoSearchItemString) => {
    let obj = {
      deptStore: 1,
      deptStoreId: getStore.storeId,
      deptStore1: deptStore1,
      isConsignment: 0,
      itemClass: 0,
      itemCode: "%",
      itemName: "%",
      searchString: autoSearchItemString,
      storePatient: 0,
    };
    if (
      autoSearchItemString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchItemString)
    ) {
      itemDetailsSearch(obj)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setItemOption(res.result);
        });
    }
  };

  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.id === val.id);
      if (ind !== -1) {
        errorAlert("Item Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  //Set Error
  const requiredFields = ["itemMaster", "quantity", "uom"];
  const validateFields = (fields, setError) => {
    let hasError = false;
    const checkAndSetError = (field) => {
      if (eval(field) === null || eval(field) === "") {
        setError(field, { type: "custom", message: "required" });
        hasError = true;
      }
    };
    fields.forEach(checkAndSetError);
    return hasError;
  };

  //Add Items Data
  const addItemsData = () => {
    let amountVal;
    if (itemMaster !== null) {
      amountVal = Number(quantity) * Number(itemMaster.PurchaseRate);
    }

    const hasError = validateFields(requiredFields, setError);
    if (!hasError) {
      if (itemMaster !== null && uom !== "" && quantity <= 999) {
        let itemObj = {
          itemMaster: {
            id: itemMaster.id,
            label: itemMaster.label,
          },
          id: itemMaster.id,
          uom: uom,
          packageSize: packSize ? packSize : "-",
          quantity: quantity,
          remark: remarks ? remarks : "-",
          specification: "",
          rate: itemMaster.PurchaseRate,
          amount: amountVal,
        };

        if (enquiryEdit === false) {
          let arr = [...itemData];
          arr.push(itemObj);
          setItemData(mergeArray(arr));
        } else if (enquiryEdit === true) {
          let arr = [...itemData];
          arr.splice(selectedIndex, 1, itemObj);
          setItemData(arr);
          setEnquiryEdit(false);
        }
        resetFields();
      }
    }
  };

  const duplicateTermsCondArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex(
        (item) => item.termsNConditions.id === val.termsNConditions.id
      );
      if (ind !== -1) {
        errorAlert("Terms & Condition Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  /// terms n condition useFied Array add function
  const addTermsNConditionData = () => {
    let termsnConditionObj = {
      termsNConditions: termsNConditions,
    };
    let temp = [...termsnConditionData];
    temp.push(termsnConditionObj);
    setTermsnConditionData(duplicateTermsCondArray(temp));
  };

  //Line Item Edit Funtionality of Enquiry Modal
  function editRow(requiredRow, index) {
    setEnquiryEdit(true);

    setValue("itemMaster", requiredRow?.itemMaster);
    setValue("uom", requiredRow?.uom);
    setValue("quantity", requiredRow?.quantity);
    setValue("packSize", requiredRow?.packageSize);
    setValue("remarks", requiredRow?.remark);
    setSelectedIndex(index);
  }

  function postEnquiry(obj) {
    addNewEnquiry(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populateTable();
          setItemData([]);
          setTermsnConditionData([]);
          reset(defaultValues);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postEnquiry(finalData);
  }

  let purchaseDetailsDtoList = [];
  for (let obj of itemData) {
    let requiredObj = {
      amount: obj.amount,
      itemId: obj.itemMaster.id,
      packageSize: obj.packageSize,
      quantity: obj.quantity,
      rate: obj.rate,
      remark: obj.remark,
      specification: obj.specification,
    };

    purchaseDetailsDtoList.push(requiredObj);
  }

  //calculate total amount and total net amount pass to main object
  let totalAmount = 0;
  for (var i = 0; i < purchaseDetailsDtoList.length; i++) {
    totalAmount += purchaseDetailsDtoList[i].amount;
  }

  let purchaseTermAndConditionDtoList = [];
  for (let obj of termsnConditionData) {
    let termsObj = {
      remark: obj?.termsNConditions?.label,
      termId: obj?.termsNConditions?.id,
    };

    purchaseTermAndConditionDtoList.push(termsObj);
  }

  const onSubmitDataHandler = (data) => {
    let postObj = {
      menuId: location?.state?.menuId,
      privilege: privilege,
      addedBy: getStore.userId,
      amount: totalAmount,
      contactPerson: "",
      header: headerStore,
      netAmount: totalAmount,
      purchaseDetailsDtoList: purchaseDetailsDtoList,
      purchaseTermAndConditionDtoList: purchaseTermAndConditionDtoList,
      remark: data.note,
      storeId: deptStore1,
      supplierId: supplierMaster,
    };
    if (purchaseDetailsDtoList.length > 0) {
      setOpenPost(true);
      setFinalData(postObj);
    } else {
      warningAlert("Add atleast one item...!");
    }
  };

  function dontAllowOnKeyUpDownSpecialChar(e) {
    if (
      e?.key === "e" ||
      e?.key === "-" ||
      e?.key === "+" ||
      e?.code === "ArrowUp" ||
      e?.code === "ArrowDown" ||
      e?.code === "NumpadDecimal"
    ) {
      e.preventDefault();
    }
  }

  React.useEffect(() => {
    populateTable();
  }, [searchEnquiryString, enquirySupplierId]);

  React.useEffect(() => {
    if (itemMaster !== null && enquiryEdit === false) {
      setValue("uom", itemMaster.StockingUOM);
    }
  }, [itemMaster]);

  React.useEffect(() => {
    fetchApplicableStores(storeId)
      .then((response) => response.data)
      .then((res) => {
        setStoreOptions(res.result);
      });

    fetchTermsOfPayment()
      .then((response) => response.data)
      .then((res) => {
        setTermsnConditionsOptions(res.result);
      });
  }, []);

  React.useEffect(() => {
    if (enquiryItemRow !== null) {
      let SupplierNameObj = {
        id: enquiryItemRow?.SupplierId,
        label: enquiryItemRow && enquiryItemRow["Supplier Name"],
        value: enquiryItemRow?.SupplierId,
      };

      setValue("supplierMasters", SupplierNameObj);
    }
  }, [enquiryItemRow]);

  const serviceElement = useRef(null);
  const quantityElement = useRef(null);
  const packSizeElement = useRef(null);
  const remarkElement = useRef(null);

  const focusFields = (type) => {
    let fieldsetTag;
    if (type === "itemMaster") {
      fieldsetTag = serviceElement.current;
    }
    if (type === "quantity") {
      fieldsetTag = quantityElement.current;
    }
    if (type === "packSize") {
      fieldsetTag = packSizeElement.current;
    }
    if (type === "remarks") {
      fieldsetTag = remarkElement.current;
    }
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  };

  return (
    <div className="pt-14 px-3">
      <span className="grid justify-center text-center text-black font-bold text-xl ">
        Enquiry
      </span>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-3 gap-3 py-3">
          <div className="">
            <SearchDropdown
              control={control}
              name="supplierMasters"
              placeholder="Suppliers*"
              error={errors.supplierMasters}
              dataArray={supplierOptions}
              isMulti={true}
              searchIcon={true}
              isSearchable={true}
              isClearable={true}
              handleInputChange={handleSupplierChange}
              inputRef={{
                ...register("supplierMasters", {
                  onChange: (e) => {
                    let supplier = [];
                    for (let obj of e.target.value) {
                      let supplierObj = obj.id;

                      supplier.push(supplierObj);
                    }
                    setSupplierMaster(supplier);
                  },
                }),
              }}
            />
          </div>
          <DropdownField
            control={control}
            name="store"
            dataArray={storeOptions}
            placeholder="Store"
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            error={errors.store}
          />
          <div className="flex gap-3 items-center justify-between w-full underline text-blue-500 text-base">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Show Header" ? (
                  <>
                    <button
                      type="button"
                      onClick={(value) => {
                        handleHeaderOpen();
                      }}
                    >
                      Show Header
                    </button>
                  </>
                ) : null}
              </>
            ))}

            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Enquiry List" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="Enquiry List"
                      onClick={() => {
                        handleEnquiryListOpen();
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>

        <hr className="w-full border-t-1 border-customBlue" />

        <div className="grid grid-cols-4 lg:grid-cols-5 xl:grid-cols-9 gap-2 py-3">
          <div className="col-span-3 flex items-center gap-2 w-full">
            <div className="font-semibold text-center whitespace-nowrap">
              Item Info
            </div>
            <div className="w-full" ref={serviceElement}>
              <SearchDropdown
                name="itemMaster"
                placeholder="Search Item"
                dataArray={itemOptions}
                handleInputChange={handleChange}
                searchIcon={true}
                error={errors.itemMaster}
                control={control}
                isClearable={true}
                inputRef={{
                  ...register("itemMaster", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        focusFields("quantity");
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <InputField
              name="uom"
              label="U.O.M"
              disabled={true}
              error={errors.uom}
              control={control}
            />
          </div>
          <div
            className=""
            // onKeyDown={(e) => {
            //   dontAllowOnKeyUpDownSpecialChar(e);
            // }}
            ref={quantityElement}
          >
            <InputField
              name="quantity"
              label="Qty"
              type="number"
              error={errors.quantity}
              control={control}
              inputRef={{
                ...register("quantity", {
                  onChange: (e) => {
                    if (e.target.value) {
                      focusFields("packSize");
                    }
                  },
                }),
              }}
            />
          </div>
          <div
            className=""
            // onKeyDown={(e) => {
            //   dontAllowOnKeyUpDownSpecialChar(e);
            // }}
            ref={packSizeElement}
          >
            <InputField
              name="packSize"
              label="Pack Size"
              error={errors.packSize}
              control={control}
              inputRef={{
                ...register("packSize", {
                  onChange: (e) => {
                    if (e.target.value) {
                      focusFields("remarks");
                    }
                  },
                }),
              }}
            />
          </div>
          <div
            className="w-full flex gap-2 col-span-2 lg:col-span-3"
            ref={remarkElement}
          >
            <InputField
              name="remarks"
              variant="outlined"
              label="Remark"
              control={control}
              inputProps={{ style: { textTransform: "capitalize" } }}
              // inputRef={{
              //   ...register("remarks", {
              //     onChange: (e) => {
              //       if (e.target.value !== null) {
              //         e.preventDefault();
              //         //addBtnElement.current.focus();
              //       }
              //     },
              //   }),
              // }}
            />
            <CommonButton
              type="button"
              className="bg-customGreen text-white"
              label="Add"
              onClick={() => {
                addItemsData();
              }}
            />
          </div>
        </div>

        <div className="w-full 2xl:px-4">
          <PurchaseEnquiryItemTable
            itemData={itemData}
            setItemData={setItemData}
            editRow={editRow}
          />
        </div>

        <div className="my-2 2xl:px-4">
          <div className="grid grid-cols-2 xl:grid-cols-6 gap-4">
            <div className="xl:col-span-3   w-full">
              <div className="flex gap-2">
                <DropdownField
                  control={control}
                  error={errors.termsNConditions}
                  name="termsNConditions"
                  placeholder="Terms & Conditions"
                  dataArray={termsnConditionsOptions}
                  onInputChange={handleChange}
                  inputRef={{
                    ...register("termsNConditions", {
                      onChange: (e) => {},
                    }),
                  }}
                />

                <CommonButton
                  type="button"
                  className="bg-customGreen text-white"
                  label="Add"
                  onClick={() => {
                    if (termsNConditions !== null) {
                      addTermsNConditionData();
                    } else if (termsNConditions === null) {
                      setError("termsNConditions", {
                        type: "custom",
                        message: "Required",
                      });
                    }
                    setValue("termsNConditions", null);
                  }}
                />
              </div>

              <div className="py-2">
                <TermsNConditionsTable
                  termsnConditionData={termsnConditionData}
                  setTermsnConditionData={setTermsnConditionData}
                />
              </div>
            </div>

            <div className="xl:col-span-2">
              <TextField
                className="bg-white"
                id="outlined-multiline-static"
                name="note"
                {...register("note")}
                label="Note"
                InputLabelProps={{ shrink: true }}
                multiline
                fullWidth
              />
            </div>

            <div className="flex justify-end items-start gap-2 col-span-2 xl:col-span-1">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="border border-customRed text-customRed"
                        label="Reset"
                        onClick={() => {
                          reset(defaultValues);
                          setItemData([]);
                          setTermsnConditionData([]);
                        }}
                      />
                      <CommonButton
                        type="submit"
                        className="bg-customGreen text-white"
                        label="Save"
                        onClick={() => {
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop} />

      <HeaderModal
        headerStore={headerStore}
        setHeaderStore={setHeaderStore}
        openHeader={openHeader}
        setOpenHeader={setOpenHeader}
        handleHeaderOpen={handleHeaderOpen}
        handleHeaderClose={handleHeaderClose}
      />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
      {postEnquiryListModal ? (
        <EnquiryListModal
          postEnquiryListModal={postEnquiryListModal}
          setOpenEnquiryModal={setOpenEnquiryModal}
          handleEnquiryListOpen={handleEnquiryListOpen}
          handleEnquiryListClose={handleEnquiryListClose}
          setEnquiryItemRow={setEnquiryItemRow}
          //Table
          setEnquirySearchString={setEnquirySearchString}
          setEnquirySupplierId={setEnquirySupplierId}
          populateTable={populateTable}
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          dataResultEnquiryList={dataResultEnquiryList}
          setDataResultEnquiryList={setDataResultEnquiryList}
          setSelectedEnquiryListFromDate={setSelectedEnquiryListFromDate}
          setSelectedEnquiryListToDate={setSelectedEnquiryListToDate}
          setItemData={setItemData}
        />
      ) : null}
    </div>
  );
}
