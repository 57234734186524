import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//
export const fetchMRDCheckPatientList = (tableObj) => {
  return apiClient.post(`/mrd/getMRDCheckPatientList`, tableObj, {
    headers: authHeader(),
  });
};

//
export const fetchMrdCheckListRemark = (mrdCheckListId) => {
  return apiClient.get(`/mrd/getMrdCheckListRemark/${mrdCheckListId}`, {
    headers: authHeader(),
  });
};

//
export const saveMrdRemarks = (remarkObj) => {
  return apiClient.post(`/mrd/saveMrdRemarks`, remarkObj, {
    headers: authHeader(),
  });
};

//
export const fetchMrdCheckList = () => {
  return apiClient.get(`/mrd/getMrdCheckList`, {
    headers: authHeader(),
  });
};

//
export const saveMrdCheckList = (finalData, menuId, privilege) => {
  return apiClient.post(
    `/mrd/saveMrdCheckList/${menuId}/${privilege}`,
    finalData,
    {
      headers: authHeader(),
    }
  );
};

//
export const fetchMRDCheckView = (viewListObj) => {
  return apiClient.post(`/mrd/getMRDCheckView`, viewListObj, {
    headers: authHeader(),
  });
};
