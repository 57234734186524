import apiClient from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";
//autosearch
export const concessionManagerAutoSearchPatient = (searchString,opdIpd) => {
  return apiClient.get(`/concessionManager/searchPatient/${searchString}/${opdIpd}`,{
    headers:authHeader()
  }); //zero is for opd
};

//patient details
export const concessionManagerPatientInfoById = (patientId,opdIpd) => {

  return apiClient.get(
    `/concessionManager/concessionManagerPTInfo/${patientId}/${opdIpd}`,{
      headers:authHeader()
    }
  ); //zero is for opd
};

//concession serviceslisting(table data)
export const concessionManagerListing = (visitId) => {
  return apiClient.get(`/concessionManager/chargesList/${visitId}`,{
    headers:authHeader()
  });
};

//concession serviceslisting(table data)
export const fetchConcessionManagerChargesList= (BillId) => {
  return apiClient.get(`/concessionManager/chargesList/${BillId}`,{
    headers:authHeader()
  });
};

//concession category
export const fetchConcessionCategory = () => {
  return apiClient.get(`/concessionManager/concessionCategory`,{
    headers:authHeader()
  });
};

//concession Authority
export const fetchConcessionAuthority = () => {
  return apiClient.get(`/concessionManager/concessionAuthority`,{
    headers:authHeader()
  });
};

//concession Previous Concession
export const fetchPreviousConcession = (opdIpdExtId) => {
  return apiClient.get(`/concessionManager/previousConcessionList/${opdIpdExtId}`,{
    headers:authHeader()
  });
};

//concession Authority
export const PostOpdConsationInfo = (finalObj) => {
  return apiClient.post(`/concessionManager/saveConcessionManager`, finalObj,{
    headers:authHeader()
  });
};
