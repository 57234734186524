import { yupResolver } from "@hookform/resolvers/yup";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Checkbox, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { CancelOnIcon } from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchEmoloyeeName,
  fetchFloor,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  cancelAssigndStaff,
  fetchPatientListForStaffVsPatientRatio,
  fetchStaffAssignHistoryList,
  saveAssignStaff,
  staffVsPatientRatio,
} from "../../services/staffVsPatientRatioServices/StaffVsPatientRatioServices";
import InputArea from "../../../common/components/FormFields/InputArea";

export default function StaffVsPatientRatio() {
  const schema = yup.object({
    searchStaff: yup
      .array()
      .nullable()
      .min(1, "Required")
      .of(
        yup.object().shape({
          label: yup.string().required("Required"),
          value: yup.string().required("Required"),
        })
      )
      .required("Required"),
    assignTo: yup.string().required("Please select an options"),
  });

  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const logFloorPatch = {
    id: userInfo?.floorId,
    label: userInfo?.floor,
    value: userInfo?.floorId,
  };

  const assignToOption = [
    { id: 1, label: "Morning", value: 1 },
    { id: 2, label: "Afternoon", value: 2 },
    { id: 3, label: "Night", value: 3 },
  ];

  const defaultValues = {
    floor: null,
    searchStaff: null,
    remark: "",
    assignTo: "",
    mrningRatio: "",
    afternoonRatio: "",
    nightRatio: "",
  };

  const afterReset = () => {
    setValue("searchStaff", null);
    setValue("remark", "");
    setValue("assignTo", "");
  };

  const {
    control,
    watch,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const getStore = JSON.parse(localStorage.getItem("userInfo"));

  const [patientList, setPatientList] = useState([]);
  const [staffHistoryList, setStaffHistoryList] = useState([]);
  const [finalData, setFinalData] = React.useState({});
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [floors, setFloors] = useState([]);
  const [staff, setStaff] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [selectedPInfo, setSelectedPInfo] = useState([]);
  const [admissionIds, setAdmissionIds] = useState([]);

  const location = useLocation();
  let floorVal = watch("floor");
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const toggleDrawerOpen = () => {
    setIsOpenFilter((prevIsOpen) => !prevIsOpen);
  };

  // cancel Staff Asssign
  const [assignStaffRowId, setAssignStaffRowId] = useState(null);
  const [openCancelPost, setOpenCancelPost] = React.useState(false);
  const handleCancelClosePost = () => {
    if (openCancelPost) {
      setOpenCancelPost(false);
    }
  };

  const populateTable = (forPagination) => {
    let defaultParams = {
      floorId: floorVal?.id,
      searchString: "",
      wardId: 0,
    };
    fetchPatientListForStaffVsPatientRatio(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        let newArray = res.result?.map((val) => ({
          ...val,
          checked: false,
        }));
        if (forPagination) {
          setPatientList((prevData) => [...prevData, ...newArray]);
        } else {
          setPatientList(newArray);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const populateStaffAssignHistoryTable = (forPagination) => {
    fetchStaffAssignHistoryList(admissionIds)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setStaffHistoryList((prevData) => [...prevData, ...res.result]);
        } else {
          setStaffHistoryList(res.result);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  function postAssignStaff(obj) {
    saveAssignStaff(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setStaffHistoryList([]);
          handleRatio();
          setOpenBackdrop(false);
          populateStaffAssignHistoryTable();
          afterReset();
          let newArray = patientList?.map((val) => ({
            ...val,
            checked: false,
          }));
          setPatientList(newArray);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postAssignStaff(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let assignTo;
    if (data.assignTo === "1") {
      assignTo = Number(1);
    } else if (data.assignTo === "2") {
      assignTo = Number(2);
    } else if (data.assignTo === "3") {
      assignTo = Number(3);
    }

    let staffIds = [];
    for (let obj of data?.searchStaff) {
      staffIds.push(obj.id);
    }

    let staffVsPatientRatioDetailsDtoList = [];
    for (let obj of selectedPInfo) {
      let selectedPInfo = {
        admissionId: obj?.AdmissionId,
        bedId: obj?.BedId,
        isCancelAssign: false,
      };

      staffVsPatientRatioDetailsDtoList.push(selectedPInfo);
    }

    let postObj = {
      addedBy: getStore.userId,
      assignTo: data.assignTo === null ? Number(0) : assignTo,
      cashCounterId: userInfo.cashCounterId,
      floorId: data.floor !== null ? data.floor.id : Number(0),
      hkStaffCount: 0,
      menuId: location?.state?.menuId,
      privilege: "Create",
      remark: data.remark,
      staffIds: staffIds,
      staffVsPatientRatioDetailsDtoList: staffVsPatientRatioDetailsDtoList,
    };

    if (selectedPInfo.length > 0) {
      setOpenPost(true);
      setFinalData(postObj);
    } else {
      warningAlert("Please select patient...!");
    }
  };

  useEffect(() => {
    //floor
    fetchFloor()
      .then((response) => {
        setFloors(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });

    if (userInfo?.floorId !== null && userInfo?.floor !== null) {
      setValue("floor", logFloorPatch);
    }

    //Staff
    fetchEmoloyeeName()
      .then((response) => {
        setStaff(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });

    // USER ACTIONS
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const handleRatio = () => {
    if (floorVal !== null) {
      staffVsPatientRatio(floorVal?.id)
        .then((response) => {
          // return response.data.result;
          let result = response.data.result;
          setValue("mrningRatio", result?.MorningStaff.toFixed(2));
          setValue("afternoonRatio", result?.AfternoonStaff.toFixed(2));
          setValue("nightRatio", result?.NightStaff.toFixed(2));
        })
        .catch((error) => {
          errorAlert(error.message);
        });
    } else if (floorVal === null) {
      setValue("mrningRatio", "");
      setValue("afternoonRatio", "");
      setValue("nightRatio", "");
    }
  };

  useEffect(() => {
    //Patient List
    populateTable();

    handleRatio();
  }, [floorVal]);

  useEffect(() => {
    if (selectedPInfo.length > 0) {
      populateStaffAssignHistoryTable();
    } else {
      setStaffHistoryList([]);
    }
  }, [selectedPInfo]);

  //Patient List Table
  const renderActions = (row, index) => {
    return (
      <div>
        <Checkbox
          className=" w-3 h-3 border border-gray-600 rounded bg-transparent"
          type="checkbox"
          color="primary"
          checked={row.checked}
          onChange={(e) => {
            let admissionIds = [];
            let admIds = [];
            let tempData = [...patientList];
            tempData[index].checked = e.target.checked;
            setPatientList(tempData);
            for (let t = 0; t < tempData?.length; t++) {
              if (tempData[t].checked === true) {
                admissionIds.push(tempData[t]);
                admIds.push(tempData[t]?.AdmissionId);
              }
              setSelectedPInfo(admissionIds);
              setAdmissionIds(admIds);
            }
          }}
        />
      </div>
    );
  };

  function handleAssignStaffCancel() {
    cancelAssigndStaff(assignStaffRowId)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          handleCancelClosePost();
          populateStaffAssignHistoryTable();
        }
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  //Render Actions Staff Assign History Table
  const renderActionsForStaffHistory = (row, index) => {
    return (
      <>
        {row?.["Assign Status"] === true ? (
          <Tooltip title="Cancel Assign Staff">
            <button
              type="button"
              onClick={() => {
                setAssignStaffRowId(row?.Id);
                setOpenCancelPost(true);
              }}
            >
              <CheckCircleOutlineRoundedIcon />
            </button>
          </Tooltip>
        ) : (
          <CancelOnIcon />
        )}
      </>
    );
  };
  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Staff Vs Patient Ratio
      </h1>

      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="lg:flex  space-x-3">
          {isOpenFilter === true ? (
            <div className="md:w-full lg:w-[23%] 2xl:w-[20%]">
              {isOpenFilter === true ? (
                <>
                  <DropdownField
                    control={control}
                    name="floor"
                    placeholder="Floor"
                    dataArray={floors}
                    isSearchable={true}
                    isClearable={true}
                  />
                  {patientList.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={patientList}
                      removeHeaders={[
                        "id",
                        "checked",
                        // "MRNo",
                        "DepartmentId",
                        "TariffId",
                        "CompanyId",
                        "DoctorId",
                        "BedId",
                        "IsBillGenerated",
                        "ClassId",
                        "AdmissionId",
                        "Discharged",
                        "PatientId",
                        "dob",
                      ]}
                      renderActions={renderActions}
                      highlightRow={false}
                      tableClass={
                        "rounded h-[300px] xl:h-[500px] 2xl:h-[700px]"
                      }
                    />
                  ) : (
                    <h1 className="flex justify-center py-5 font-bold text-gray-600">
                      No Record Found...
                    </h1>
                  )}
                </>
              ) : null}
            </div>
          ) : null}

          {isOpenFilter === false ? (
            <div className="grid lg:w-[16%] 2xl:w-[20%]">
              {patientList.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={patientList}
                  removeHeaders={["id"]}
                  renderActions={renderActions}
                  highlightRow={false}
                  tableClass={"rounded h-[300px] xl:h-[500px] 2xl:h-[700px]"}
                />
              ) : (
                <h1 className="flex justify-center py-5 font-bold text-gray-600">
                  No Record Found...
                </h1>
              )}
            </div>
          ) : null}

          <div className="align-middle items-center  invisible md:hidden lg:visible lg:flex justify-end">
            {isOpenFilter === true ? (
              <button
                className="h-10 w-[0.80rem] flex items-center justify-center rounded-t rounded-b bg-customBlue text-white"
                onClick={toggleDrawerOpen}
              >
                <ArrowLeftIcon />
              </button>
            ) : (
              <button
                className="h-10 w-[0.80rem] flex items-center justify-center rounded-t rounded-b bg-customBlue text-white"
                onClick={toggleDrawerOpen}
              >
                <ArrowRightIcon />
              </button>
            )}
          </div>

          <div
            className={`${
              isOpenFilter === true
                ? "w-auto lg:w-[75%] 2xl:w-[90%]" //incase of open Open
                : "w-full lg:w-[83%] 2xl:w-[92%]"
            } `}
          >
            <span className="font-semibold text-[16px]">
              Staff Assign History
            </span>
            <div className="">
              {staffHistoryList.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={staffHistoryList}
                  removeHeaders={["Id"]}
                  renderActions={renderActionsForStaffHistory}
                  tableClass="h-56 rounded-md"
                />
              ) : (
                <h1 className="flex justify-center py-24 font-bold text-gray-600">
                  No Record Found...
                </h1>
              )}
            </div>

            <div className="flex space-x-2 py-2 items-center">
              <span className="font-semibold text-sm">Ratio</span>
              <InputField
                control={control}
                name="mrningRatio"
                label="Morning Ratio"
                disabled={true}
              />
              <InputField
                control={control}
                name="afternoonRatio"
                label="Afternoon Ratio"
                disabled={true}
              />
              <InputField
                control={control}
                name="nightRatio"
                label="Night Ratio"
                disabled={true}
              />
            </div>

            <hr className="w-full my-2 border-t-2 border-customBlue" />

            <span className="font-semibold  text-[16px]">
              Assign Staff Details
            </span>
            <div className="flex space-x-2 items-center w-full py-2">
              <div className="w-full space-x-4">
                <span className="text-sm font-semibold">Assign To :</span>
                <RadioField
                  control={control}
                  name="assignTo"
                  dataArray={assignToOption}
                />
                {errors.assignTo && (
                  <p className="text-red-700 text-sm">
                    {errors.assignTo.message}
                  </p>
                )}
              </div>
              <DropdownField
                control={control}
                error={errors.searchStaff}
                name="searchStaff"
                dataArray={staff}
                placeholder="Select Staff Name"
                isMulti={true}
                isSearchable={true}
                isClearable={true}
              />
            </div>

            {/* <Controller
              name="remark"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-multiline-flexible"
                  label="Remark"
                  multiline
                  minRows={1}
                  className="w-full"
                  variant="outlined"
                />
              )}
            /> */}

            <InputArea
              control={control}
              name="remark"
              label="Remark"
              placeholder="Remark"
            />

            <div className="flex space-x-3 justify-end pt-2">
              <CommonButton
                type="button"
                className="resetButton border border-red-700 text-red-700"
                label="Reset"
                onClick={() => {
                  reset(defaultValues);
                  setValue("floor", null);
                  setStaffHistoryList([]);
                  let newArray = patientList?.map((val) => ({
                    ...val,
                    checked: false,
                  }));
                  setPatientList(newArray);
                }}
              />

              <CommonButton
                type="submit"
                className="saveButton bg-green-600 text-white"
                label="Save"
              />
            </div>
          </div>
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />

      <ConfirmationModal
        confirmationOpen={openCancelPost}
        confirmationHandleClose={handleCancelClosePost}
        confirmationSubmitFunc={handleAssignStaffCancel}
        confirmationLabel="Confirmation"
        confirmationMsg="Do you want to cancel assign staff ?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}
