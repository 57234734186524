import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { patientAdvanceBalance } from "../../../ipd/services/billSettlement/IPDSelfBillSettlementServices";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import PatientAdvance from "../../components/advance/PatientAdvance";
import IPDCharges from "../../components/charges/IPDCharges";
import DischargeModal from "../../components/discharge/DischargeModal";

import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

import { Box, Modal, TextField, Tooltip } from "@mui/material";
import CompanyBillTable from "./CompanyBillTable";
import PackageBillTable from "./PackageBillTable";
import PatientBillTable from "./PatientBillTable";

import { yupResolver } from "@hookform/resolvers/yup";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import PrintButton from "../../../common/components/Buttons/PrintButton";
import ResetButton from "../../../common/components/Buttons/ResetButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchAllIPDBillingChargeList,
  fetchPerviousBillsList,
  ipFinalBillPdfPrint,
  ipdDraftBillPdfPrint,
  ipdPatientInfoAutoComplete,
  moveServiceCompanyToPatient,
  postIPDBill,
} from "../../services/billingServices/Billing";
import PerviousBillsModal from "./PerviousBillsModal";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: "80%",
  height: "80%",
  border: "1px solid gray",
};
const draftFinalBill = [
  {
    id: "DraftBill",
    value: "DraftBill",
    label: "Draft Bill",
  },
  {
    id: "FinalBill",
    value: "FinalBill",
    label: "Final Bill",
  },
];
const Actions = [
  {
    id: 0,
    action: "checkbox",
    isAction: true,
  },
  {
    id: 1,
    action: "ok",
    isAction: false,
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const actions = ["Delete", "Checkbox", "Print"];

export default function IPDBilling(props) {
  const location = useLocation();

  //useForm Destrecturing
  const schema = yup.object().shape({});
  const defaultValues = {
    draftFinalBill: "DraftBill",
    consolidated: true,
    consolidatedFlag: true,
    // withPharmacyFlag: true,
    remaksFromAdmission: "",
    remaksFromPRO: "",
    totalBill: "",
    refundOfAdvanceSearch: "",
    serviceTaxApplicacbleAmount: "",
    adminChargeApplicableAmount: 0,

    gstAmount: 0,
    adminChargeAmount: 0,
    netPayableAmount: 0,
    paidAmount: 0,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  //state variable
  const [searchResult, setSearchResult] = useState();
  const [searchResultById, setSearchResultById] = useState(null);
  const [tab, setTab] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [patientInfoList, setPatientInfoList] = React.useState(null);
  const [admissionId, setAdmissionId] = React.useState(null);
  const [patientId, setPatientId] = React.useState(null);
  const [category, setCategory] = React.useState("Patient");
  const [rowData, setRowData] = React.useState(null);
  const [payableAmount, setPayableAmount] = React.useState(0);
  const [openAdvance, setOpenAdvance] = useState(false);
  const [openDischarge, setOpenDischarge] = useState(false);
  const [openCharges, setOpenCharges] = useState(false);
  const [openPerviousBills, setOpenPerviousBills] = useState(false);
  const [chargeId, setChargeId] = useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [ipdBillReciptPdf, setIpdBillReciptPdf] = React.useState("");
  const [openBillRecipt, setOpenBillRecipt] = React.useState(false);
  const [companyId, setCompanyId] = React.useState(null);
  const [advanceAmount, setAdvanceAmount] = React.useState(0);
  const [activeRow, setActiveRow] = React.useState([]);
  const [patientTotal, setPatientTotal] = React.useState(0);
  const [companyTotal, setCompanyTotal] = React.useState(0);
  const [packageTotal, setPackageTotal] = React.useState(0);
  const [patientCompanyTotal, setPatientCompanyTotal] = React.useState(0);
  const [advanceBalance, setAdvanceBalance] = React.useState(0);
  const [tabServiceData, setTabServicesData] = React.useState([]);
  const [dataResult, setDataResult] = React.useState([]);
  const [finalBillPayment, setFinalBillPyament] = React.useState();
  const [patientCharges, setPatientCharges] = React.useState([]);
  const [companyCharges, setCompanyCharges] = React.useState([]);
  const [packageCharges, setPackageCharges] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();
  const [pervioutBillList, setPrevioutBillList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalInfo, setTotalInfo] = React.useState("");
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState(null);
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [resetCount, setResetCount] = React.useState(0);
  const [menuId, setMenuId] = React.useState(null);

  //other variables
  let consolidatedVal = watch("consolidated");
  let draftBillVal = watch("draftBill");
  let gstAmountVal = watch("gstAmount");
  let adminChargeAmountVal = watch("adminChargeAmount");
  let currentAdmittedVal = watch("currentAdmitted");

  let consolidatedFlagVal = watch("consolidatedFlag");

  let noAdminFlagVal = watch("noAdmin");

  let finalBillVal = watch("finalBill");

  let defaultParams = {
    searchString: "",

    page: page,
    size: rowsPerPage,
  };
  // paymentinfolist object for pay 0 Rs credit bill
  let requiredInfoList = [
    {
      amount: 0,
      bankId: 0,
      paymentDate: "",
      paymentType: {
        id: 4,
        label: "CASH",
        value: "CASH",
      },
    },
  ];

  useEffect(() => {
    // (location?.state?.menuId || props.menuId) &&
    fetchUserActionsByMenuId(
      props.menuId ? props.menuId : location?.state?.menuId
    )
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      })
      .catch((error) => {});
  }, [props.menuId]);

  useEffect(() => {
    let payableAmt = 0;
    if (
      (patientInfoList && patientInfoList.PatientAdvanceAmount) >
      (patientCompanyTotal && patientCompanyTotal)
    ) {
      setValue("netPayableAmount", patientCompanyTotal && patientCompanyTotal);
    } else {
      payableAmt = patientCompanyTotal + gstAmountVal + adminChargeAmountVal;

      setValue("netPayableAmount", payableAmt.toFixed(2));
    }
    setPayableAmount(payableAmt);
    setValue(
      "totalBill",
      patientCompanyTotal && patientCompanyTotal.toFixed(2)
    );
  }, [patientCompanyTotal, patientInfoList, setValue, payableAmount]);

  useEffect(() => {
    if (patientId > 0) {
      fetchPerviousBillsList(patientId)
        .then((response) => response.data)
        .then((res) => {
          setDataResult(res.result);
          setPrevioutBillList(res);
        });
    }
  }, [openPerviousBills, patientId]);

  useEffect(() => {
    if (location.state !== null) {
      setPatientId(location?.state?.row?.PatientId);
      setAdmissionId(location?.state?.row?.AdmissionId);
    }
  }, []);

  //functions start
  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  //function to close the paymentModal
  function closePaymentModal() {
    if (open === true) {
      setOpen(false);
    }
  }

  const handleOpenAdvance = () => setOpenAdvance(true);
  const handleCloseAdvance = () => setOpenAdvance(false);

  const handleOpenDischarge = () => setOpenDischarge(true);
  const handleCloseDischarge = () => setOpenDischarge(false);

  const handleOpenCharge = () => setOpenCharges(true);
  const handleCloseCharge = () => setOpenCharges(false);

  const handleOpenPreviousBill = () => setOpenPerviousBills(true);
  const handleClosePreviousBill = () => setOpenPerviousBills(false);

  //auto complete search patient
  function handleChange(searchRefundOfAdvance) {
    if (searchRefundOfAdvance !== "") {
      ipdPatientInfoAutoComplete(
        searchRefundOfAdvance,
        currentAdmittedVal ? currentAdmittedVal : false
      )
        .then((response) => response.data)
        .then((res) => {
          setSearchResult(res.result);
        })
        .catch((error) => {});
    }
  }

  //patient,company,packages tab
  const TabhandleChange = (event, newValue) => {
    setTab(newValue);
  };

  // function call charges depend on categories
  function getChargesBasedOnCategory() {
    if (admissionId > 0) {
      let postObj = {
        admissionId: admissionId,
        consolidate: consolidatedVal,
        opdIpd: 1,
      };
      fetchAllIPDBillingChargeList(postObj)
        .then((response) => response.data)
        .then((res) => {
          setValue("noAdmin", res?.result?.ptInfo?.NoAdmin);
          setTotalInfo(res.result.totalInfo);
          setPatientCharges([]);
          setCompanyCharges([]);
          setPackageCharges([]);

          setPatientInfoList(res?.result?.ptInfo);

          setCompanyId(res?.result?.ptInfo?.CompanyId);

          let tempArr = [...res.result.chargesList];
          getPatientAdvance(res?.result?.ptInfo?.PatientId);

          let patientArr1 = [];
          let companyArr1 = [];
          let packageArr1 = [];
          let totalChargeAmount = 0;
          let packageTotal = 0;

          for (let obj of tempArr) {
            // if (obj?.IsPackaged === false) {
            //   totalChargeAmount = totalChargeAmount + obj?.NetAmount;
            //   setPatientCompanyTotal(totalChargeAmount);
            // }

            if (companyId > 1) {
              if (obj.SponsorType === false && obj.IsPackaged === false) {
                patientArr1.push(obj);
                setPatientCharges(patientArr1);
              } else if (obj.SponsorType === true && obj.IsPackaged === false) {
                companyArr1.push(obj);
                setCompanyCharges(companyArr1);
              } else {
                packageArr1.push(obj);
                setPackageCharges(packageArr1);
              }
            } else if (companyId <= 1) {
              if (obj.IsPackaged === false) {
                patientArr1.push(obj);

                setPatientCharges(patientArr1);
              } else {
                packageArr1.push(obj);

                setPackageCharges(packageArr1);
              }
            }
          }
        })
        .catch((error) => {});
    }
  }
  useEffect(() => {}, [packageCharges]);
  React.useEffect(() => {
    if (admissionId !== null) {
      getChargesBasedOnCategory();
    }
  }, [admissionId, companyId]);

  const getServicesList = () => {
    if (props.admissionId > 0) {
      let postObj = {
        admissionId: props.admissionId,
        consolidate: consolidatedVal,
        opdIpd: 1,
      };

      fetchAllIPDBillingChargeList(postObj)
        .then((response) => response.data)
        .then((res) => {
          setValue("noAdmin", res?.result?.ptInfo?.NoAdmin);
          setTotalInfo(res.result.totalInfo);
          setPatientInfoList(res?.result?.ptInfo);

          setCompanyId(res?.result?.ptInfo?.CompanyId);
          let tempArr = [...res.result.chargesList];
          getPatientAdvance(res?.result?.ptInfo?.PatientId);

          let patientArr = [];
          let companyArr = [];
          let packageArr = [];
          let totalChargeAmount = 0;
          let packageTotal = 0;

          for (let obj of tempArr) {
            if (obj?.IsPackaged === false) {
              totalChargeAmount = totalChargeAmount + obj?.NetAmount;
              setPatientCompanyTotal(totalChargeAmount);
            }
            if (companyId > 1) {
              if (obj.SponsorType === false && obj.IsPackaged === false) {
                patientArr.push(obj);

                setPatientCharges(patientArr);
              } else if (obj.SponsorType === true && obj.IsPackaged === false) {
                companyArr.push(obj);

                setCompanyCharges(companyArr);
              } else if (obj.IsPackaged === true) {
                packageArr.push(obj);

                setPackageCharges(packageArr);
              }
            } else if (companyId <= 1) {
              if (obj.SponsorType === false && obj.IsPackaged === false) {
                patientArr.push(obj);
                setPatientCharges(patientArr);
              } else if (obj.SponsorType === true && obj.IsPackaged === false) {
                companyArr.push(obj);

                setCompanyCharges(companyArr);
              } else if (obj.IsPackaged === true) {
                packageArr.push(obj);

                setPackageCharges(packageArr);
              }
            }
          }
        })
        .catch((error) => {});
    }
  };

  React.useEffect(() => {
    getServicesList();
  }, [props.admissionId, consolidatedVal]);

  //get table row data
  function displayRowData(row, index) {
    setRowData(row);
  }

  // Patient Advance amount api
  function getPatientAdvance(PatientId) {
    if (PatientId > 0 || PatientId !== null || PatientId !== "") {
      patientAdvanceBalance(PatientId)
        .then((response) => response.data)
        .then((res) => {
          setAdvanceBalance(res.result.PatientAdvance);
        })
        .catch((error) => {});
    }
  }

  // IPD Bill Save Object
  function onSubmitDataHandler() {
    let addedByData = localStorage.getItem("userInfo");
    let addByObj = JSON.parse(addedByData);
    let ipdBillSaveObj = {
      addedBy: addByObj.userId,
      opdIpd: 1,
      cashCounterId: addByObj.cashCounterId,
      paymentInfoList: requiredInfoList,
      consumeAdvance: 0,
      totalAmount: totalInfo?.["PayableAmount"],
      billAmount: 0,
      menuId: location?.state?.menuId,
      privilege: "Create",
      chargesRequestDto: chargeId,
      visitId: patientInfoList && patientInfoList.AdmissionId,
      tariffId: patientInfoList && patientInfoList.TariffId,
      patientSourceId: patientInfoList && patientInfoList.PatientSourceId,
      patientCategoryId: patientInfoList && patientInfoList.PatientCategoryId,
      companyId: patientInfoList && patientInfoList.CompanyId,
      concessionAuthorisedBy: 0,
      isSettled: patientInfoList && patientInfoList.isBilled,
      patientAdvanceId: 0,
      unitId: addByObj.unitId,

      patientAdvanceUsed: advanceBalance,
    };

    setFinalBillPyament(ipdBillSaveObj);
  }

  // IPD Bill Payment api call
  function finalIPDBillPayment(finalBillPayment) {
    setOpenBackdrop(true);
    setConfirmationOpen(false);

    postIPDBill(finalBillPayment)
      .then((response) => response.data)
      .then((res) => {
        if (props.from === "IpdCharges") {
          props.handlCloseIpdBillAfterFinalSave();
        }
        successAlert(res.message);

        setOpenBackdrop(false);

        printIpdFinalBill(
          patientInfoList && patientInfoList?.AdmissionId,
          res.result
        );
        setSearchResultById("");
        reset(defaultValues);
        setPatientInfoList(null);
        setSearchResultById(null);
        setPatientCompanyTotal(0);
        setPayableAmount(0);
        setPatientTotal(0);
        setCompanyTotal(0);
        setPackageTotal(0);
        setAdmissionId(null);
        setPatientId(null);
        setPatientCharges([]);
        setCompanyCharges([]);
        setOpenBackdrop(false);
        reset();
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  //function call when click on print icon button

  function printIconButtonFinalBill(admissionId, billId) {
    let isFinal = 1;
    let noAdmin = noAdminFlagVal ? noAdminFlagVal : false;
    ipFinalBillPdfPrint(admissionId, billId, false, isFinal, noAdmin).then(
      (response) => {
        setOpenPrintModal(true);
        setUrlforPrint(response);
      }
    );
  }

  // function call on click of save button and final Bill radio button click
  function printIpdFinalBill(admissionId, billId) {
    let admissionID = admissionId ? admissionId : props.admissionId;
    let noAdmin = noAdminFlagVal ? noAdminFlagVal : false;
    let isFinal = 1;

    ipFinalBillPdfPrint(
      admissionID,
      billId,
      consolidatedFlagVal,
      isFinal,
      noAdmin
    ).then((response) => {
      setOpenPrintModal(true);
      setUrlforPrint(response);
    });
  }

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = () => {
    let admissionID = admissionId ? admissionId : props.admissionId;
    let billId = 0;
    let isFinal = 0;
    let isConsolidated = consolidatedFlagVal;
    let noAdmin = noAdminFlagVal ? noAdminFlagVal : false;

    ipdDraftBillPdfPrint(
      admissionID,
      billId,
      isFinal,
      isConsolidated,
      noAdmin
    ).then((response) => {
      setOpenPrintModal(true);
      setUrlforPrint(response);
    });
  };
  // service to move company to patient bill table
  function serviceMoveToSelfOrComapny() {
    let chargesIds = [];

    if (chargeId?.length > 0) {
      for (let charge of chargeId) {
        chargesIds.push(charge.ChargeId);
      }

      let chargesMoveObject = {
        chargeIds: chargesIds,
        sponsorType:
          category === "Patient" ? true : category === "Company" ? false : null,
      };
      moveServiceCompanyToPatient(chargesMoveObject)
        .then((response) => response.data)
        .then((res) => {
          getChargesBasedOnCategory();
          setResetCount(resetCount - 1);
          setChargeId([]);
          setPatientCharges([]);
          setCompanyCharges([]);
          getServicesList();
        });
    } else {
    }
  }

  useEffect(() => {
    if (consolidatedVal === true) {
      getChargesBasedOnCategory();
    } else {
      getChargesBasedOnCategory();
    }
  }, [consolidatedVal]);

  // pervious bill actions
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "checkbox" ? (
                    <Tooltip title={"Print"}>
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          printIconButtonFinalBill(
                            row?.AdmissionId,
                            row?.BillId,
                            false,
                            1
                          );
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="mt-10 px-2">
        <div className="text-center text-black font-bold text-xl py-4">
          IPD Billing
        </div>
        <div className="grid grid-cols-5 items-center">
          <div className=" col-span-4">
            <div className=" flex gap-3 md:flex md:w-full">
              {(props.open === true && props.admissionId) ||
              location?.state?.["row"] ? null : (
                <>
                  <div className=" lg:w-3/5 md:w-full">
                    <SearchDropdown
                      control={control}
                      placeholder="Search By Patient Name/ MR No./Mobile No"
                      dataArray={searchResult}
                      name="refundOfAdvanceSearch"
                      searchIcon={true}
                      isClearable={true}
                      // isSearchable={true}
                      handleInputChange={handleChange}
                      inputRef={{
                        ...register("refundOfAdvanceSearch", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              setSearchResultById(e.target.value);
                              setAdmissionId(e.target.value.id);

                              // setConsolidateFlag(e.target.value.consolidate);
                              setPatientId(e.target?.value?.PatientId);
                            } else {
                              reset(defaultValues);
                              setPatientInfoList(null);
                              setSearchResultById(null);
                              setPatientCompanyTotal(0);
                              setPayableAmount(0);
                              setPatientTotal(0);
                              setCompanyTotal(0);
                              setPackageTotal(0);
                              setAdmissionId(null);
                              setPatientId(null);
                              setPatientCharges([]);
                              setCompanyCharges([]);
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      control={control}
                      name="currentAdmitted"
                      defaultValue={true}
                      label="Current Admitted"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end items-center gap-2 ">
            {patientInfoList && patientInfoList?.isBilled === true ? (
              <div>
                <Tooltip title="Print" className="cursor-pointer">
                  <LocalPrintshopIcon
                    sx={{ color: "#4B5563" }}
                    onClick={() => {
                      printIconButtonFinalBill(
                        patientInfoList && patientInfoList?.AdmissionId,
                        patientInfoList && patientInfoList?.BillId
                      );
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {searchResultById && searchResultById !== null ? (
              <>
                <div>
                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "View" ? (
                        <CommonButton
                          label="Previous Bill"
                          className="bg-customBlue text-white"
                          onClick={() => {
                            handleOpenPreviousBill();
                          }}
                        />
                      ) : null}
                    </>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>

        {(searchResultById && searchResultById !== null) ||
        patientInfoList !== null ? (
          <>
            <div className="lg:grid lg:grid-cols-2 gap-2">
              <div className="">
                <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                  <legend className="ml-2 my-1 rounded-xl ">
                    <p className="text-sm font-semibold tracking-wide mx-2">
                      Patient Information
                    </p>
                  </legend>

                  <div className="md:grid md:grid-cols-[18%_1.5%_42%_12%_1.5%_25%] lg:grid lg:grid-cols-[18%_1.5%_42%_12%_1.5%_25%] text-sm px-4 py-0.5">
                    <div className="font-semibold ">Patient Name</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.patientName}
                    </div>
                    <div className="font-semibold "> MR No.</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.mrNo}
                    </div>
                    <div className="font-semibold ">IPD No</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.IpdNo}
                    </div>
                    <div className="font-semibold">Adm Date</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.admissionDate}
                    </div>
                    <div className="font-semibold ">Gender</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.gender}
                    </div>
                    <div className="font-semibold ">Age</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.AgeYear}
                    </div>
                    {patientInfoList?.CompanyName !== null &&
                      patientInfoList?.CompanyName !== "" && (
                        <>
                          <div className="font-semibold ">Company Name</div>
                          <div>:</div>
                          <div className="text-gray-700 font-normal">
                            {patientInfoList && patientInfoList.CompanyName}
                          </div>
                        </>
                      )}
                    {patientInfoList?.AssistentCompany !== null &&
                      patientInfoList?.AssistentCompany !== "" && (
                        <>
                          <div className="font-semibold ">
                            Assistant Company
                          </div>
                          <div>:</div>
                          <div className="text-gray-700 font-normal">
                            {patientInfoList &&
                              patientInfoList.AssistentCompany}
                          </div>
                        </>
                      )}
                  </div>
                </fieldset>
              </div>
              <div className="">
                <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                  <legend className="ml-2 my-1 rounded-xl ">
                    <p className="text-sm font-semibold tracking-wide mx-2">
                      Bill Information
                    </p>
                  </legend>

                  <div className="md:grid md:grid-cols-[18%_1.5%_42%_12%_1.5%_25%] lg:grid lg:grid-cols-[20%_1.5%_42%_12%_1.5%_25%] text-sm px-4 pb-1 h-16">
                    <div className="font-semibold ">Doctor</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.doctor}
                    </div>
                    <div className="font-semibold "> Bed No</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.BedNo}
                    </div>
                    <div className="font-semibold ">Patient Category</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.PatientCategory}
                    </div>
                    <div className="font-semibold">Tariff</div>
                    <div>:</div>
                    <div className="text-gray-700 font-normal">
                      {patientInfoList && patientInfoList.tariff}
                    </div>
                    <div className="font-semibold"></div>
                    <div></div>
                    <div className="text-gray-700 font-normal"></div>
                    <div className="font-semibold"></div>
                    <div></div>
                    <div className="text-gray-700 font-normal"></div>
                  </div>
                </fieldset>
              </div>
            </div>
            <Box
              sx={{ width: "100%", bgcolor: "background.paper" }}
              className="items-center"
            >
              <div className="lg:grid lg:grid-cols-3 md:flex justify-between">
                <div className="content-start items-center pt-2">
                  <CheckBoxField
                    control={control}
                    name="consolidated"
                    defaultValue={true}
                    label="Consolidated"
                  />
                </div>
                <div className="flex gap-2 items-center justify-center w-full md:w-full">
                  <Tabs
                    onChange={TabhandleChange}
                    value={tab}
                    TabIndicatorProps={{
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    textColor="#0B83A5"
                    variant="centered"
                    centered
                  >
                    <Tab
                      label="Patient"
                      {...a11yProps(0)}
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        setCategory("Patient");
                      }}
                    />
                    {companyId > 1 ? (
                      <Tab
                        label="Company"
                        {...a11yProps(1)}
                        sx={{
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setCategory("Company");
                        }}
                        // disabled={
                        // patientInfoList &&
                        // patientInfoList.PatientCategory === "Self"
                        // }
                      />
                    ) : null}

                    <Tab
                      label="Package"
                      {...a11yProps(2)}
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        setCategory("Package");
                      }}
                    />
                  </Tabs>
                </div>
                <div className="lg:grid lg:grid-cols-2 pt-2">
                  <div></div>
                  <div className="justify-end items-center flex ">
                    {patientInfoList && patientInfoList.CompanyId > 1 ? (
                      <>
                        {category === "Patient" || category === "Company" ? (
                          <>
                            {patientInfoList &&
                            patientInfoList.isBilled === false ? (
                              <div className="border border-blue-800 px-2 rounded-md bg-[#1d3b8a] text-white">
                                <button
                                  onClick={() => {
                                    serviceMoveToSelfOrComapny();
                                  }}
                                  className="py-1"
                                >
                                  {category === "Patient" ? (
                                    <span className="font-normal text-xs ">
                                      Move To Company
                                    </span>
                                  ) : category === "Company" ? (
                                    <span className="font-normal text-xs">
                                      Move To Patient
                                    </span>
                                  ) : category === "Package" ? null : null}
                                  {category === "Patient" &&
                                  category === "Company" ? (
                                    <ArrowRightAltIcon
                                      className="text-white "
                                      onClick={() => {}}
                                    />
                                  ) : null}
                                </button>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </Box>

            <div className="w-full md:w-full">
              <TabPanel value={tab} index={0}>
                {patientCharges !== null &&
                patientCharges &&
                patientCharges.length > 0 ? (
                  <>
                    <PatientBillTable
                      data={patientCharges}
                      actions={actions}
                      chargeId={chargeId}
                      setCharged={setChargeId}
                      consolidatedVal={consolidatedVal}
                      displayRowData={displayRowData}
                      setData={setTabServicesData}
                      setPatientTotal={setPatientTotal}
                      resetCount={resetCount}
                    />
                  </>
                ) : (
                  <div className="text-center py-8">
                    <p>No Record Found</p>
                  </div>
                )}
              </TabPanel>
              {companyId > 1 ? (
                <TabPanel value={tab} index={1}>
                  {companyCharges !== null &&
                  companyCharges &&
                  companyCharges.length > 0 ? (
                    <>
                      <CompanyBillTable
                        data={companyCharges}
                        actions={actions}
                        consolidatedVal={consolidatedVal}
                        setActiveRow={setActiveRow}
                        activeRow={activeRow}
                        displayRowData={displayRowData}
                        setData={setTabServicesData}
                        setCompanyTotal={setCompanyTotal}
                        patientTotal={patientTotal}
                        chargeId={chargeId}
                        setCharged={setChargeId}
                        setPatientCompanyTotal={setPatientCompanyTotal}
                        resetCount={resetCount}
                      />
                    </>
                  ) : (
                    <div className="text-center py-8">
                      <p>No Record Found</p>
                    </div>
                  )}
                </TabPanel>
              ) : null}
              <TabPanel value={tab} index={companyId > 1 ? 2 : 1}>
                {packageCharges !== null &&
                packageCharges &&
                packageCharges.length > 0 ? (
                  <>
                    <PackageBillTable
                      data={packageCharges}
                      actions={actions}
                      consolidatedVal={consolidatedVal}
                      setActiveRow={setActiveRow}
                      activeRow={activeRow}
                      chargeId={chargeId}
                      setCharged={setChargeId}
                      displayRowData={displayRowData}
                      setData={setTabServicesData}
                      setPackageTotal={setPackageTotal}
                      resetCount={resetCount}
                    />
                  </>
                ) : (
                  <div className="text-center py-8">
                    <p>No Record Found</p>
                  </div>
                )}
              </TabPanel>
            </div>
            {/* )} */}
            <div className="text-center"></div>

            <form onSubmit={handleSubmit(onSubmitDataHandler)}>
              <div className="w-full ">
                <div className="border px-2 py-1 rounded">
                  <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-2">
                    <div className="flex items-center gap-2 w-full font-semibold text-sm ">
                      <span className="w-28 font-semibold"> Total Advance</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-bold">
                          ₹ {Math.round(totalInfo?.TotalAdvance).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-28 font-semibold">Total Charges</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-bold">
                          ₹ {Math.round(totalInfo?.TotalCharges).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                      <span className="w-32 font-semibold">
                        <p> Payable Amount</p>
                      </span>

                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-bold">
                          ₹ {Math.round(totalInfo?.PayableAmount)?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                      <span className="lg:w-12 md:w-28 font-semibold">
                        Total
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-bold">
                          ₹
                          {category === "Patient" ? (
                            <span className="px-2">
                              {Math.round(totalInfo?.PatientTotal).toFixed(2)}
                            </span>
                          ) : category === "Company" ? (
                            <span className="px-2">
                              {Math.round(totalInfo?.CompanyTotal).toFixed(2)}
                            </span>
                          ) : category === "Package" ? (
                            <span className="px-2">
                              {Math.round(totalInfo?.PackageTotal).toFixed(2)}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {patientInfoList && patientInfoList.isBilled === false ? (
                <>
                  <div className="lg:grid lg:grid-cols-6 md:grid md:grid-cols-1 py-2 gap-2">
                    <div className="col-span-3 flex gap-2">
                      <div className="w-80">
                        <TextField
                          id="outlined-multiline-static"
                          name="remaksFromAdmission"
                          {...register("remaksFromAdmission")}
                          label="Remark From Admission"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={1}
                          fullWidth
                        />
                      </div>
                      <div className="w-80">
                        <TextField
                          id="outlined-multiline-static"
                          name="remaksFromPRO"
                          {...register("remaksFromPRO")}
                          label="Remark From PRO"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={1}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="lg:col-span-3 md:grid md:grid-cols-2 flex gap-2 items-center w-full justify-end">
                      <div className="lg:flex md:flex md:w-full gap-2 lg:pr-10 md:pr-0">
                        <div className="flex items-center">
                          <input
                            {...register("draftBill")}
                            type="radio"
                            name="draftBill"
                            placeholder="Draft Bill"
                            id="name"
                            defaultChecked={true}
                            className="w-6 h-4"
                            onClick={() => {
                              setValue("finalBill", false);
                            }}
                          />
                          <span>Draft Bill</span>
                        </div>
                        <div className="flex items-center">
                          <input
                            {...register("finalBill")}
                            type="radio"
                            className="w-6 h-4"
                            name="finalBill"
                            label="Final Bill"
                            id="name"
                            defaultChecked={false}
                            onClick={() => {
                              setValue("draftBill", false);
                            }}
                            disabled={
                              patientInfoList &&
                              patientInfoList.Discharged === false
                                ? true
                                : false
                            }
                          />
                          <span
                            disabled={
                              patientInfoList &&
                              patientInfoList.Discharged === true
                                ? { color: "red" }
                                : { color: "blue" }
                            }
                          >
                            Final Bill
                          </span>
                        </div>
                      </div>

                      <div className="lg:flex md:flex">
                        <div className="">
                          <CheckBoxField
                            control={control}
                            name="consolidatedFlag"
                            label="Consolidated"
                          />
                        </div>

                        <div className="">
                          {/* <CheckBoxField
                              control={control}
                              name="withPharmacyFlag"
                              label="With Pharmacy"
                            /> */}
                          <CheckBoxField
                            control={control}
                            name="noAdmin"
                            label="No Admin"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <fieldset className=" mx-auto border w-full rounded ">
                    <legend className="ml-2 my-1 rounded-xl ">
                      <p className="text-sm font-semibold tracking-wide mx-2">
                        Payment Details
                      </p>
                    </legend>
                    {/* <div className="lg:grid lg:grid-cols-5 md:grid md:grid-cols-3 gap-2 px-4 py-2 ">
                      <div className="">
                        <InputField
                          control={control}
                          name="totalBill"
                          label="Total Bill(₹)"
                          type="number"
                          disabled={true}
                        />
                      </div>
                      <div className="">
                        <InputField
                          control={control}
                          name="gstAmount"
                          label="GST Amount(₹)"
                          type="number"
                          disabled={true}
                        />
                      </div>
                      <div className="">
                        <InputField
                          control={control}
                          name="adminChargeApplicableAmount"
                          label="Admin Charge Applicable Amount(₹)"
                          type="number"
                          disabled={true}
                        />
                      </div>
                      <div className="">
                        <InputField
                          control={control}
                          name="adminChargeAmount"
                          label="Admin Charge Amount(₹)"
                          type="number"
                          disabled={true}
                        />
                      </div>
                      <div className="">
                        <InputField
                          control={control}
                          name="netPayableAmount"
                          label="Payable Amount(₹)"
                          type="number"
                          disabled={true}
                        />
                      </div>
                    </div> */}
                    <div className="w-full ">
                      <div className=" px-2 py-1 rounded">
                        <div className="lg:grid lg:grid-cols-5 md:grid md:grid-cols-2 gap-2">
                          <div className="flex items-center gap-2 w-full font-semibold text-sm ">
                            <span className="w-16 font-semibold">
                              Total Bill
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-bold">
                                ₹{" "}
                                {Math.round(totalInfo?.TotalCharges).toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1 ">
                            <span className="w-28 font-semibold">
                              GST Amount
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-bold">
                                ₹ {Math.round(totalInfo?.GSTAmt).toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                            <span className="w-52 font-semibold">
                              Admin Charge App. Amt.
                            </span>
                            <div className="flex space-x-2 items-center whitespace-nowrap">
                              <span>:</span>
                              <span className="text-gray-700 font-bold">
                                ₹
                                {Math.round(
                                  totalInfo?.AdminChargeAppAmt
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                            <span className="w-32 font-semibold">
                              Admin Charge Amt
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-bold">
                                ₹ {Math.round(totalInfo?.AdminAmt).toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                            <span className="w-32 font-semibold">
                              {totalInfo?.["PayableAmount"] !== null ? (
                                <p> Payable Amount</p>
                              ) : totalInfo?.["RefundableAmount"] !== null ? (
                                <p> Refundable Amount</p>
                              ) : (
                                ""
                              )}
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-bold">
                                ₹
                                {totalInfo?.["PayableAmount"] !== null ? (
                                  <>
                                    {Math.round(
                                      totalInfo?.["PayableAmount"]
                                    ).toFixed(2)}
                                  </>
                                ) : totalInfo?.["RefundableAmount"] !== null ? (
                                  <>
                                    {Math.round(
                                      totalInfo?.["RefundableAmount"]
                                    ).toFixed(2)}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="flex justify-end gap-2 pt-1 md:flex md:py-2">
                    <div className="">
                      {props.open === true ? null : (
                        <>
                          {userActions.map((obj) => (
                            <>
                              {!obj.isAction && obj.action === "Create" ? (
                                <ResetButton
                                  onClick={() => {
                                    reset(defaultValues);
                                    setPatientInfoList(null);
                                    setSearchResultById(null);
                                    setPatientCompanyTotal(0);
                                    setPayableAmount(0);
                                    setPatientTotal(0);
                                    setCompanyTotal(0);
                                    setPackageTotal(0);
                                    setAdmissionId(null);
                                    setPatientId(null);
                                    setPatientCharges([]);
                                    setCompanyCharges([]);
                                  }}
                                />
                              ) : null}
                            </>
                          ))}
                        </>
                      )}
                    </div>
                    {draftBillVal === "on" ? (
                      <>
                        {userActions.map((obj) => (
                          <>
                            {!obj.isAction && obj.action === "Advance" ? (
                              <CommonButton
                                label="Advance"
                                className="bg-customBlue text-white"
                                onClick={() => {
                                  setOpenAdvance(true);
                                  setPrivilege(obj.action);
                                }}
                              />
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}

                    {(draftBillVal === "on" &&
                      patientInfoList?.Discharged === false) ||
                    location?.state?.row?.Discharged === false ? (
                      <>
                        {userActions &&
                          userActions.map((obj) => (
                            <>
                              {!obj.isAction && obj.action === "Discharge" ? (
                                <CommonButton
                                  label="Discharge"
                                  className="bg-customGreen text-white"
                                  onClick={() => {
                                    setOpenDischarge(true);
                                    setPrivilege(obj.action);
                                    setMenuId(obj?.menuId);
                                  }}
                                />
                              ) : null}
                            </>
                          ))}
                      </>
                    ) : null}

                    {/* {props.ipdChargesWindow === false ? null : ( */}
                    {userActions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "View Charges" ? (
                          <>
                            {props.from === "IpdCharges" ? null : (
                              <CommonButton
                                label="View Charges"
                                className="bg-customGreen text-white"
                                onClick={() => {
                                  if (props.open === true) {
                                    props.handlCloseIpdBill();
                                  } else {
                                    setOpenCharges(true);
                                    setPrivilege(obj.action);
                                    setMenuId(obj?.menuId);
                                  }
                                }}
                              />
                            )}
                          </>
                        ) : null}
                      </>
                    ))}

                    {/* )} */}
                    <div className="">
                      {patientInfoList && patientInfoList?.isBilled === true ? (
                        <PrintButton />
                      ) : (
                        // <SaveButton
                        // onClick={() => {
                        // if (
                        // patientInfoList.list &&
                        // patientInfoList.list.length > 0 &&
                        // watch("draftBill") === false
                        // ) {
                        // setConfirmationOpen(false);
                        // } else {
                        // setOpen(false);
                        // printDraftBill();
                        // }
                        // }}
                        // />
                        <>
                          {userActions.map((obj) => (
                            <>
                              {!obj.isAction && obj.action === "Create" ? (
                                <CommonButton
                                  label="Save"
                                  className="bg-customGreen text-white"
                                  type="button"
                                  onClick={() => {
                                    if (watch("draftBill") === false) {
                                      setConfirmationOpen(true);

                                      // printIpdFinalBill();
                                      onSubmitDataHandler();
                                      setPrivilege(obj?.action);
                                    } else {
                                      setConfirmationOpen(false);
                                      handleOpenPrintModal();
                                    }
                                  }}
                                />
                              ) : null}
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </form>
            <CommonBackDrop openBackdrop={openBackdrop} />
          </>
        ) : null}

        <div className=" bg-white px-6">
          <Modal
            open={openAdvance}
            onClose={() => {
              // handleCloseAdvance();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ModalStyle}>
              <div className="grid grid-cols-1 md:grid-cols-1 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    handleCloseAdvance();
                    // getChargesBasedOnCategory();
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>

              <PatientAdvance
                openAdvance={openAdvance}
                handleOpenAdvance={handleOpenAdvance}
                handleCloseAdvance={handleCloseAdvance}
                admissionId={admissionId ? admissionId : props.admissionId}
                patientId={patientId ? patientId : props.patientId}
                category={"All"}
                userActions={userActions}
                from="Billing"
                setAdvanceAmount={setAdvanceAmount}
                advanceAmount={advanceAmount}
                setTotalInfo={setTotalInfo}
                totalInfo={totalInfo}
                patientInfoList={patientInfoList}
                OldAdvanceAmount={patientInfoList?.PatientAdvanceAmount}
              />
            </Box>
          </Modal>
        </div>

        <div className=" bg-white px-8">
          <Modal
            open={openCharges}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ModalStyle}>
              <div className="grid grid-cols-1 md:grid-cols-1 w-full">
                <CancelPresentationIconButton
                  onClick={() => {
                    handleCloseCharge();
                    getChargesBasedOnCategory();
                  }}
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </div>

              <div className="-mt-16">
                <IPDCharges
                  handleCloseCharge={handleCloseCharge}
                  admissionId={admissionId ? admissionId : props.admissionId}
                  patientId={patientId ? patientId : props.patientId}
                  setCompanyId={setCompanyId}
                  fetchAllIPDBillingChargeList={fetchAllIPDBillingChargeList}
                  from="Billing"
                  menuId={menuId}
                  // menuId={location?.state?.menuId}
                  privilege={privilege}
                />
              </div>
            </Box>
          </Modal>
        </div>

        {/* {openDischarge ? (
 <> */}
        <DischargeModal
          openDischarge={openDischarge}
          handleClose={handleCloseDischarge}
          admissionId={admissionId ? admissionId : props.admissionId}
          // patientId={patientId ? patientId : props.patientId}
          setPatientInfoList={setPatientInfoList}
          patientInfoList={patientInfoList}
          fetchAllIPDBillingChargeList={fetchAllIPDBillingChargeList}
          handleOpenDischarge={handleOpenDischarge}
          getChargesBasedOnCategor={getChargesBasedOnCategory}
          setOpenBackdrop={setOpenBackdrop}
          from="IPDBilling"
          privilege={privilege}
          menuId={menuId}
          setMenuId={setMenuId}
        />
        {openPerviousBills ? (
          <PerviousBillsModal
            open={openPerviousBills}
            setOpen={setOpenPerviousBills}
            handleClose={handleClosePreviousBill}
            handleOpen={handleOpenPreviousBill}
            dataResult={dataResult}
            setDataResult={setDataResult}
            pervioutBillList={pervioutBillList}
            fetchPerviousBillsList={fetchPerviousBillsList}
            defaultParams={defaultParams}
            ipdBillGeneratePdf={printIpdFinalBill}
            renderActions={renderActions}
            selectedRow={selectedRow}
            actionsRequired={true}

            //
          />
        ) : null}
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={() => {
            confirmationHandleClose();
            setOpenBackdrop(false);
          }}
          confirmationSubmitFunc={() => {
            finalIPDBillPayment(finalBillPayment);
          }}
          confirmationLabel="Confirmation "
          TotalAdvance
          confirmationMsg="Do You Want To Save Credit Bill?"
          confirmationButtonMsg="Save"
        />

        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}
      </div>
    </>
  );
}

