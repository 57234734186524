import authHeader from "../../../../authentication/authservices/auth-header";
import MasterApi from "../../../../http-common";
// //Listing Api
// export const testCreationListing= (saveObj) => {
//     return country.post(`/employee/employeeList`, saveObj,{
//     headers: {
//       authorization:
//         "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJNciBOaWxlc2ggU2hpbmRlIiwiZW1wSWQiOjY4LCJlbXBsb3llZVR5cGUiOiJIb3VzZWtlZXBpbmcgSW5jaGFyZ2UiLCJjcmVhdGVkIjoxNjkyNjc2NjU3Nzc2LCJleHAiOjI3NzI2NzY2NTcsInVzZXIiOjE1NCwic3RhdHVzIjp0cnVlLCJ1c2VybmFtZSI6Ik1yIE5pbGVzaCBTaGluZGUifQ.jgfQKryiLdGRm1cHZ8HzJo9fGST-0VRxFDoFzqc_n2gwb_SaE606BNURAAneU4aItK_7ZT0obrslPtFvxsBIMw",
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },})
//   }

// Autosearch API
export const templateDropdownSearch = (searchString) => {
  return MasterApi.get(`/pathMasters/templateAutoComplete/${searchString}`, {
     headers: authHeader(),
  });
};

//Post Object API
export const postTestCreation = (saveObj) => {
  return MasterApi.post(`/pathMasters/createTest`, saveObj, {
    headers: authHeader(),
  });
};

export const serviceDropdownSearch = (searchString) => {
  return MasterApi.get(`/serviceMaster/searchService/${searchString}`, {
    headers: authHeader(),
  });
};

export const pathCategoryDropdown = () => {
  return MasterApi.get(`/pathMasters/getPathCategory`, {
    headers: authHeader(),
  });
};

export const pathParameterDropdownSearch = (searchString) => {
  return MasterApi.get(`/pathMasters/getPathParameters/${searchString}`, {
    headers: authHeader(),
  });
};

export const getTestCreationList = (obj) => {
  return MasterApi.post(`/pathMasters/getTestsList`, obj, {
    headers: authHeader(),
  });
};

export const getListAutoSearch = (searchString) => {
  return MasterApi.get(`/pathMasters/getPathTestListSearch/${searchString}`, {
    headers: authHeader(),
  });
};

export const getPathTestdata = (pathTestId) => {
  return MasterApi.get(`/pathMasters/getTestDetails/${pathTestId}`, {
    headers: authHeader(),
  });
};
