import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllSuccsionSaturation = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/succionSaturation/${admissionId}`,
 
  {
    headers: authHeader(),
  });
};

export const deleteSuccionSaturation = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteSuccionSaturation/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const suctionSaturationPrintData = (postObj) => {
  return apiClient.post(`/reports/nursing/cccSuctionSaturation`, postObj, {
   headers: authHeader(),
  });
};
