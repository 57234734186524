import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CancelOnIcon } from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { baseUrl } from "../../../http-common";
import { saveDailyTips } from "../../services/ScreenSaverServices/ScreenSaverServices";
import ViewImageModal from "./ViewImageModal";

const schema = yup.object().shape({
  description: yup.string().required("Required"),
});

export default function AddDailyTipsModal(props) {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, selectedRow, populateTable, menuId, privilege } = props;

  const [selectedDocuments, setSelectedDocuments] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [base64String, setBase64String] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [allFormData, setAllFormData] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //
  const [openViewImageModal, setOpenViewImageModal] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      description: "",
      message: "",
      uploadFileDailyTips: null,
    },
  });

  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = methods;

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedDocuments(files);

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string
        const imageName = file.name;

        setPreviewUrl(reader.result);
        setBase64String(base64String);
        setImageName(imageName);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setPreviewUrl(null);
    setValue("uploadFileDailyTips", null);
  };

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };
  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const finalObj = {
      applicableFrom: "2024-01-06T10:26:12.663Z",
      code: "",
      dailyTipsId: selectedRow?.Id || 0,
      date: "2024-01-06T10:26:12.663Z",
      description: data.description,
      fromDate: data.fromDate,
      imageBase64: base64String,
      imageName: imageName,
      // imgPath: "",
      imgPath: !!selectedRow ? selectedRow?.ImagePath : "",
      menuId: 0,
      messageText: data.message,
      time: new Date(),
      toDate: data.toDate,
    };
    saveDailyTips(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    if (!!selectedRow) {
      setValue("description", selectedRow.Name);
      setValue("fromDate", new Date(selectedRow["fromDatePatch"]));
      setValue("toDate", new Date(selectedRow["todatePatch"]));
      setValue("message", selectedRow["Message Text"]);
      setPreviewUrl(`${baseUrl}/file/${selectedRow?.ImagePath}`);
    }
  }, [selectedRow]);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Add Daily Tips
            </div>

            <hr className="border mx-1  border-customBlue" />
            <form>
              <div className="grid md:grid-cols-4 lg:grid-cols-6 gap-3 my-3">
                <div className=" ">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={false}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                <div className=" ">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={false}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="col-span-2">
                  <InputField
                    name="description"
                    label="Description"
                    // defaultValue={token?.userName}
                    variant="outlined"
                    control={control}
                    inputProps={{ maxLength: 50 }}
                    error={errors.description}
                  />
                </div>

                <div className="col-span-2">
                  <InputArea
                    control={control}
                    {...register("message")}
                    name="message"
                    label="Message Text"
                    placeholder="Message Text"
                  />
                </div>

                <div className="col-span-2 gap-3">
                  {/* Use HTML input for file upload */}
                  <label htmlFor="fileInput" className="mr-2">
                    Select Documents:
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".pdf,.doc,.docx,.jpg, .jpeg, .png, .gif"
                    {...register("uploadFileDailyTips")}
                    onChange={handleFileChange}
                    // multiple
                    name="uploadFileDailyTips"
                  />
                </div>
                <div className="relative">
                  {previewUrl && (
                    <>
                      <div className="absolute top-2 right-2 cursor-pointer">
                        <CancelOnIcon onClick={handleDeleteImage} />
                      </div>
                      <img
                        src={previewUrl}
                        alt="Image Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          marginTop: "10px",
                        }}
                        onClick={() => setOpenViewImageModal(true)}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                <CommonButton
                  label={!!selectedRow ? "Update" : "Save"}
                  className="bg-customGreen text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {openViewImageModal ? (
        <ViewImageModal
          open={openViewImageModal}
          setOpen={setOpenViewImageModal}
          handleOpen={() => setOpenViewImageModal(true)}
          handleClose={() => setOpenViewImageModal(false)}
          ImagePath={previewUrl}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
