//imports from react library
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import AddNewButton from "../../common/components/Buttons/AddNewButton";
import SearchIconButton from "../../common/components/Buttons/SearchIconButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePagination from "../../common/components/CommonTable/CommonDynamicTablePagination";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DropdownField from "../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import { dummyFunctionalities } from "../AssignFuctinalities/DummyData";
import {
  getEmployeeListing,
  getEmployeeOptions,
  getUnits,
  getUsrePrivileges,
  saveAction,
  saveFunctionPrivilege,
} from "../Services/UserManagementServices";
import UsersModal from "./UsersModal";
const loggedUser = JSON.parse(localStorage.getItem("userInfo"));
export default function Users() {
  const defaultValues = {
    unit: null,
    SearchableSelect: null,
  };
  const {
    setValue,
    watch,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  let searchValue = "";
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [selectedObj, setSelectedObj] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [employeeList, setEmployeeList] = React.useState();
  const [dataResult, setDataResult] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [openChild, setOpenChild] = React.useState(false);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState("");
  const searchObj = {
    page: page,
    size: rowsPerPage,
    searchString: searchString,
  };

  React.useEffect(() => {
    getUnits()
      .then((response) => response.data)
      .then((res) => {
        setUnitOptions(res.result);
      });
  }, []);

  React.useEffect(() => {
    if (unitOptions.length > 0) {
      let loggedUnit = unitOptions.find(
        (result) => result.id === loggedUser?.unitId
      );
      setValue("unit", loggedUnit);
    }
  }, [unitOptions]);

  //function to open the confirmation modal
  const handelOpenChild = () => setOpenChild(true);

  //function to close the confirmation modal
  const handleCloseChild = () => {
    if (openChild) {
      setOpenChild(false);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedRow("");
    setOpen(false);
  };
  const [idValue, setIdValue] = React.useState("");
  const [userValue, setUserValue] = React.useState();
  const [Actions, setActions] = React.useState([]);
  const [menulist, setMenulist] = React.useState([]);

  const [privilegeList, setPrivilegeList] = React.useState([]);

  //event listener function for edit icon
  function editRow(user) {
    setUserValue(user);
    setEdit(true);
    setIdValue(user.id);
    handleOpen();
  }

  //event listener function for the magnifying glass icon of the search bar
  const filterData = () => {
    populateTable();
  };

  //use props forthe DropdownField/ searchbar
  const handleChange = (autoSearchString) => {
    if (autoSearchString !== "") {
      getEmployeeOptions(autoSearchString, unitWatch?.id).then((response) => {
        setOptions(response.data.result);
      });
    }
  };

  let dummyData = dummyFunctionalities;
  let systemMenu = [];

  React.useEffect(() => {
    if (dummyData.length > 0) {
      for (let menu of dummyData) {
        let firstObj = {
          id: menu.id,
          label: menu.functionality,
          value: menu.id,
        };
        systemMenu.push(firstObj);
        if (menu["subFunction"]) {
          if (menu["subFunction"].length > 0) {
            for (let secondObject of menu["subFunction"]) {
              let Object = {
                id: secondObject.id,
                label: secondObject.functionality,
                value: secondObject.id,
              };
              systemMenu.push(Object);
              if (secondObject["subFunction"]) {
                if (secondObject["subFunction"].length > 0) {
                  for (let thirdObject of secondObject["subFunction"]) {
                    let menu = {
                      id: thirdObject.id,
                      label: thirdObject.functionality,
                      value: thirdObject.id,
                    };
                    systemMenu.push(menu);
                  }
                }
              }
            }
          }
        }
      }
      setMenulist(systemMenu);
    }
  }, [dummyData]);

  let employeeWatch = watch("SearchableSelect");

  React.useEffect(() => {
    if (employeeWatch !== null) {
      setPage(0);
      setSearchString(employeeWatch?.label);
    } else {
      setPage(0);
      setSearchString("");
    }
  }, [employeeWatch]);
  //after search get selected value and set selected value to object i.e (setSelectedObj) from this hook
  const autoSelectedValue = (value) => {
    setPage(0);
    setSearchString(value.label);
  };

  let unitWatch = watch("unit");
  let location = useLocation();

  //populate the CommonMasterTable using the function populateTable
  function populateTable() {
    const defaultParams = {
      page: page,
      searchString: searchString,
      size: rowsPerPage,
      unitId: unitWatch?.id,
      menuId: location?.state?.menuId,
    };
    getEmployeeListing(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        setEmployeeList(res);
        setCount(res.count);
        setDataResult(res.result);
        setActions(res.actions);
      });
  }

  const getprivilege = () => {
    getUsrePrivileges()
      .then((response) => response.data)
      .then((res) => {
        setPrivilegeList(res);
        let arr = [];
        for (let obj of res) {
          let object = {
            id: obj.id,
            label: obj.privilege,
            value: obj.id,
          };
          arr.push(object);
          setPrivilegeList(arr);
        }
      });
  };

  // useEffect to call the populate table function
  React.useEffect(() => {
    populateTable();
    getprivilege();
  }, []);

  // the searchObject for the user listing
  const defaultParams = {
    page: page,
    searchString: searchString,
    size: rowsPerPage,
    unitId: unitWatch?.id,
    menuId: location?.state?.menuId,
  };

  // function to render actions in table if actions isAction flag ===true
  const renderActions = (row) => {
    return (
      <div className="flex gap-1">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-1 items-center cursor-pointer">
                  {actions.action === "Edit" ? (
                    <Tooltip title="Edit ">
                      <IconButton
                        onClick={() => {
                          setSelectedRow(row);
                          setEdit(true);
                          setOpen(true);
                        }}
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{ color: "#1e3a8a" }}
                          onClick={(e) => {
                            // navigate("/operationTheater/Details/Form", {
                            //   state: { row, isEditInfo: true },
                            // });
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  let menuWatch = watch("menuSearch");
  let privilegeWatch = watch("privileges");
  let actionWatch = watch("action");

  const handleAddPrivileges = () => {
    if (privilegeWatch !== undefined) {
      let privilegeArray = [];
      if (privilegeWatch) {
        for (let obj of privilegeWatch) {
          let priObject = {
            id: obj.id,
          };
          privilegeArray.push(priObject);
        }
      }

      let obj = {
        id: menuWatch?.id,
        permissions: privilegeArray,
      };
      saveFunctionPrivilege(obj)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res);
        });
    }
  };

  const handleAddAction = () => {
    if (actionWatch !== undefined && actionWatch !== "") {
      let action = actionWatch;
      if (action !== "") {
        saveAction(action)
          .then((response) => response.data)
          .then((res) => {
            successAlert(res);
            getprivilege();
          })
          .catch((error) => {
            errorAlert(error);
          });
      }
    }
  };

  return (
    <div className="w-full grid pt-10 px-6 mt-8 md:rounded-md">
      <div className="flex justify-center text-xl">
        <h1 className=" text-gray-700 font-Poppins lg:hidden ">User</h1>
      </div>

      {/*searchable dropdown */}
      <div className="flex gap-2 w-full items-center mt-2">
        {/* <div className=" flex items-center w-full gap-14"> */}
        <h1 className="text-xl text-gray-700 font-Poppins hidden lg:block">
          User
        </h1>
        <div className="flex w-full lg:grid grid-cols-3 gap-2 items-center">
          <div className="grid w-full grid-cols-1">
            <SearchDropdown
              selectedObj={selectedObj}
              type="button"
              name="SearchableSelect"
              placeholder="Search by Employee Name / Mobile Number"
              dataArray={options}
              isSearchable={true}
              handleInputChange={handleChange}
              isClearable={true}
              onChange={autoSelectedValue}
              control={control}
              searchIcon={true}
            />
          </div>
          <div>
            <DropdownField
              control={control}
              error={errors.searchByUnits}
              name="unit"
              label="Unit"
              placeholder="Unit"
              dataArray={unitOptions}
              isSearchable={false}
            />
          </div>
          <div className="flex justify-between">
            <div className="flex items-center">
              <SearchIconButton onClick={filterData} />
            </div>
          </div>
        </div>

        {Actions.length > 0
          ? Actions.map((actions) => {
              return !actions.isAction && actions.action === "Create" ? (
                <div className="grid justify-end xl:col-span-3 w-1/3">
                  {/* Modal for Add */}
                  <AddNewButton
                    onClick={() => {
                      handleOpen();
                    }}
                  />
                </div>
              ) : (
                ""
              );
            })
          : ""}

        <CommonBackDrop openBackdrop={openBackdrop} />
      </div>
      {/* <div className="grid grid-cols-3 gap-2 my-2">
        <div className="">
          <DropdownField
            control={control}
            name="menuSearch"
            placeholder="Menu"
            isSearchable={true}
            searchIcon={true}
            isClearable={true}
            dataArray={menulist}
          />
        </div>
        <div className="">
          <DropdownField
            control={control}
            name="privileges"
            placeholder="Privilege"
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            dataArray={privilegeList}
          />
        </div>
        <div>
          <CommonButton
            label="Add Privilege"
            className="bg-customGreen text-white"
            type="button"
            onClick={handleAddPrivileges}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2 my-2">
        <div className="">
          <InputField control={control} label="Action" name="action" />
        </div>

        <div>
          <CommonButton
            label="Add Action"
            className="bg-customGreen text-white"
            type="button"
            onClick={handleAddAction}
          />
        </div>
      </div> */}
      <div className=" grid h-[250px]">
        {employeeList?.result?.length > 0 ? (
          <CommonDynamicTablePagination
            highlightRow={false}
            actionsRequired={true}
            data={employeeList}
            dataResult={dataResult}
            setDataResult={setDataResult}
            renderActions={renderActions}
            defaultParams={defaultParams}
            // setSelectedRow={setSelectedRow}
            populateTable={populateTable}
            tableApiFunc={getEmployeeListing}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
          />
        ) : (
          <p className="text-center my-10 font-semibold text-gray-400 text-xl ">
            No Data Found
          </p>
        )}
      </div>

      <ConfirmationModal
        confirmationOpen={openChild}
        confirmationHandleClose={handleCloseChild}
        // confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />
      {openBackdrop ? (
        <CommonBackDrop openBackdrop={openBackdrop} />
      ) : (
        <UsersModal
          userValue={userValue}
          populateTable={populateTable}
          searchObj={searchObj}
          edit={edit}
          setEdit={setEdit}
          open={open}
          setOpen={setOpen}
          idValue={idValue}
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          handleOpen={handleOpen}
          handleClose={handleClose}
          selectedRow={selectedRow}
          setPage={setPage}
        />
      )}
    </div>
  );
}
