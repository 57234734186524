import * as React from "react";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

export default function NIHStrokeScaleScoringSheetTable(props) {
  const getNewDate = new Date();
  const { control, watch, setValue } = useForm();
  const [checkedValue, setCheckedValue] = useState(null);
  // const [preThomolysScore, setPreThomolysScore] = useState();
  // const [hour1Score, sethour1Score] = useState();
  // const [hour24Score, sethour24Score] = useState();
  // const [days7Score, setDays7Score] = useState();
  // const [onDischargeScore, setOnDischargeScore] = useState();

  const [rowData, setRowData] = useState();
  const {
    NIHStrokeTableData,
    setNIHStrokeTableData,
    NIHStrokeDateTimeData,
    setNIHStrokeDateTimeData,
    NIHStrokeSheetDetails,
    getNIHStrokeId,
    patientInfo,
    patientDetails,
    preThombolysCheck,
    setPreThombolysCheck,
    hour1Check,
    setHour1Check,
    hour24Check,
    setHour24Check,
    days7Check,
    setDays7Check,
    onDischargeCheck,
    setOnDischargeCheck,
  } = props;

  useEffect(() => {
    let temp = NIHStrokeTableData;
    var preThombolysVal = null;
    var totalPreThombolysVal = null;
    var hour1Val = null;
    var totalhour1Val = null;
    var hour24Val = null;
    var totalHour24Val = null;
    var days7Val = null;
    var totalDays7Val = null;
    var onDischargeVal = null;
    var totalOnDischargeVal = null;
    for (let ih = 0; ih < temp?.length; ih++) {
      for (
        let id = 0;
        id < temp[ih]?.scaleScoringHeaderDescriptionsDtoList?.length;
        id++
      ) {
        let vp = temp[ih]?.scaleScoringHeaderDescriptionsDtoList[id];
        if (vp.preThombolys === true && vp.score !== "(N/A)") {
          preThombolysVal = vp.score;
          totalPreThombolysVal += Number(preThombolysVal);
          // setPreThomolysScore(totalPreThombolysVal);
        }
        if (vp.hour1 === true && vp.score !== "(N/A)") {
          hour1Val = vp.score;
          totalhour1Val += Number(hour1Val);
          // sethour1Score(totalhour1Val);
        }
        if (vp.hour24 === true && vp.score !== "(N/A)") {
          hour24Val = vp.score;
          totalHour24Val += Number(hour24Val);
          // sethour24Score(totalHour24Val);
        }
        if (vp.days7 === true && vp.score !== "(N/A)") {
          days7Val = vp.score;
          totalDays7Val += Number(days7Val);
          // setDays7Score(totalDays7Val);
        }
        if (vp.onDischarge === true && vp.score !== "(N/A)") {
          onDischargeVal = vp.score;
          totalOnDischargeVal += Number(onDischargeVal);
          // setOnDischargeScore(totalOnDischargeVal);
        }
        let obj = {};
        obj.preThrombolysScore =
          totalPreThombolysVal !== null
            ? totalPreThombolysVal
            : NIHStrokeSheetDetails?.preThrombolysScore !== null
            ? NIHStrokeSheetDetails?.preThrombolysScore
            : null;
        obj.hour1Score =
          totalhour1Val !== null
            ? totalhour1Val
            : NIHStrokeSheetDetails?.hour1Score !== null
            ? NIHStrokeSheetDetails?.hour1Score
            : null;
        obj.hour24Score =
          totalHour24Val !== null
            ? totalHour24Val
            : NIHStrokeSheetDetails?.hour24Score !== null
            ? NIHStrokeSheetDetails?.hour24Score
            : null;
        obj.days7Score =
          totalDays7Val !== null
            ? totalDays7Val
            : NIHStrokeSheetDetails?.day7Score !== null
            ? NIHStrokeSheetDetails?.day7Score
            : null;
        obj.onDischargeScore =
          totalOnDischargeVal !== null
            ? totalOnDischargeVal
            : NIHStrokeSheetDetails?.onDischargeScore !== null
            ? NIHStrokeSheetDetails?.onDischargeScore
            : null;
        obj.preThrombolysDate =
          preThombolysCheck === true
            ? getNewDate
            : new Date(NIHStrokeSheetDetails?.preThrombolysDate);
        obj.hour1Date =
          hour1Check === true
            ? getNewDate
            : new Date(NIHStrokeSheetDetails?.hour1Date);
        obj.hour24Date =
          hour24Check === true
            ? getNewDate
            : new Date(NIHStrokeSheetDetails?.hour24Date);
        obj.day7Date =
          days7Check === true
            ? getNewDate
            : new Date(NIHStrokeSheetDetails?.day7Date);
        obj.onDischargeDate =
          onDischargeCheck === true
            ? getNewDate
            : new Date(NIHStrokeSheetDetails?.onDischargeDate);
        setNIHStrokeDateTimeData(obj);
      }
    }
  }, [checkedValue]);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", borderBottomWidth: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                      }}
                      rowSpan={3}
                    >
                      Header
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                      }}
                      rowSpan={3}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                      }}
                    >
                      Initials
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        width: 145,
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                        textAlign: "center",
                      }}
                    >
                      Pre-Thombolysis
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        width: 145,
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                        textAlign: "center",
                      }}
                    >
                      1 Hour
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        width: 145,
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                        textAlign: "center",
                      }}
                    >
                      24 Hour
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        width: 145,
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                        textAlign: "center",
                      }}
                    >
                      7 Days
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        width: 145,
                        borderLeftWidth: 2,
                        borderTopWidth: 2,
                        textAlign: "center",
                      }}
                    >
                      On Discharge
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 39,
                        textAlign: "center",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 39,
                        textAlign: "center",
                      }}
                    >
                      {NIHStrokeDateTimeData?.preThrombolysScore !== null
                        ? NIHStrokeDateTimeData?.preThrombolysDate?.toLocaleDateString()
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 39,
                        textAlign: "center",
                      }}
                    >
                      {NIHStrokeDateTimeData?.hour1Score !== null
                        ? NIHStrokeDateTimeData?.hour1Date?.toLocaleDateString()
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 39,
                        textAlign: "center",
                      }}
                    >
                      {NIHStrokeDateTimeData?.hour24Score !== null
                        ? NIHStrokeDateTimeData?.hour24Date?.toLocaleDateString()
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 39,
                        textAlign: "center",
                      }}
                    >
                      {NIHStrokeDateTimeData?.days7Score !== null
                        ? NIHStrokeDateTimeData?.day7Date?.toLocaleDateString()
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 39,
                        textAlign: "center",
                      }}
                    >
                      {NIHStrokeDateTimeData?.onDischargeScore !== null
                        ? NIHStrokeDateTimeData?.onDischargeDate?.toLocaleDateString()
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        background: "#F1F1F1",
                        color: "#4b5563",
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 76,
                        textAlign: "center",
                      }}
                    >
                      Initial
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 76,
                      }}
                    >
                      <div className="text-center">
                        {NIHStrokeDateTimeData?.preThrombolysScore}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 76,
                      }}
                    >
                      <div className="text-center">
                        {NIHStrokeDateTimeData?.hour1Score}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 76,
                      }}
                    >
                      <div className="text-center">
                        {NIHStrokeDateTimeData?.hour24Score}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 76,
                      }}
                    >
                      <div className="text-center">
                        {NIHStrokeDateTimeData?.days7Score}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        borderLeftWidth: 2,
                        position: "sticky",
                        top: 76,
                      }}
                    >
                      <div className="text-center">
                        {NIHStrokeDateTimeData?.onDischargeScore}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {NIHStrokeTableData?.map((data, index) => {
                    return (
                      <Fragment>
                        <TableRow
                          key={index}
                          sx={{
                            "& td": {
                              paddingY: 0.0,
                              borderLeftWidth: 2,
                            },
                          }}
                        >
                          <TableCell
                            rowSpan={
                              data.scaleScoringHeaderDescriptionsDtoList
                                .length + 1
                            }
                            sx={{ width: 200 }}
                          >
                            {data.headers}
                          </TableCell>
                        </TableRow>
                        {data.scaleScoringHeaderDescriptionsDtoList.map(
                          (val, ind) => {
                            return (
                              <TableRow
                                key={ind}
                                sx={{
                                  "& td": {
                                    paddingY: 0.0,
                                    borderLeftWidth: 2,
                                  },
                                }}
                                onClick={() => {
                                  setRowData(val);
                                }}
                              >
                                <TableCell>{val.description}</TableCell>
                                <TableCell>
                                  <div className="text-center">{val.score}</div>
                                </TableCell>
                                <TableCell>
                                  <fieldset
                                    className="text-center"
                                    onChange={(e) => {
                                      val.preThombolys = e.target.checked;
                                      setCheckedValue(e);
                                      setPreThombolysCheck(true);
                                    }}
                                    disabled={
                                      patientInfo === null &&
                                      patientDetails === null
                                    }
                                  >
                                    <CheckBoxField
                                      control={control}
                                      name={`data${index}preThombolys${ind}`}
                                      defaultValue={val.preThombolys}
                                    />
                                  </fieldset>
                                </TableCell>
                                <TableCell>
                                  <fieldset
                                    className="text-center"
                                    onChange={(e) => {
                                      val.hour1 = e.target.checked;
                                      setCheckedValue(e);
                                      setHour1Check(true);
                                    }}
                                    disabled={
                                      patientInfo === null &&
                                      patientDetails === null
                                    }
                                  >
                                    <CheckBoxField
                                      control={control}
                                      name={`data${index}hour1${ind}`}
                                      defaultValue={val.hour1}
                                    />
                                  </fieldset>
                                </TableCell>
                                <TableCell>
                                  <fieldset
                                    className="text-center"
                                    onChange={(e) => {
                                      val.hour24 = e.target.checked;
                                      setCheckedValue(e);
                                      setHour24Check(true);
                                    }}
                                    disabled={
                                      patientInfo === null &&
                                      patientDetails === null
                                    }
                                  >
                                    <CheckBoxField
                                      control={control}
                                      name={`data${index}hour24${ind}`}
                                      defaultValue={val.hour24}
                                    />
                                  </fieldset>
                                </TableCell>
                                <TableCell>
                                  <fieldset
                                    className="text-center"
                                    onChange={(e) => {
                                      val.days7 = e.target.checked;
                                      setCheckedValue(e);
                                      setDays7Check(true);
                                    }}
                                    disabled={
                                      patientInfo === null &&
                                      patientDetails === null
                                    }
                                  >
                                    <CheckBoxField
                                      control={control}
                                      name={`data${index}days7${ind}`}
                                      defaultValue={val.days7}
                                    />
                                  </fieldset>
                                </TableCell>
                                <TableCell>
                                  <fieldset
                                    className="text-center"
                                    onChange={(e) => {
                                      val.onDischarge = e.target.checked;
                                      setCheckedValue(e);
                                      setOnDischargeCheck(true);
                                    }}
                                    disabled={
                                      patientInfo === null &&
                                      patientDetails === null
                                    }
                                  >
                                    <CheckBoxField
                                      control={control}
                                      name={`data${index}onDischarge${ind}`}
                                      defaultValue={val.onDischarge}
                                    />
                                  </fieldset>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <Box>
          <Paper>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell style={{ background: "#F1F1F1" }}>
                    <div className="flex justify-end gap-10 text-red-500">
                      <span>Total Score</span>
                      <span>0 = Best , 42 = Worst</span>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      width: 145,
                      borderLeftWidth: 2,
                      borderRightWidth: 2,
                    }}
                  >
                    <div className="text-center font-semibold">
                      {NIHStrokeDateTimeData?.preThrombolysScore}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 145, borderRightWidth: 2 }}>
                    <div className="text-center font-semibold">
                      {NIHStrokeDateTimeData?.hour1Score}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 145, borderRightWidth: 2 }}>
                    <div className="text-center font-semibold">
                      {NIHStrokeDateTimeData?.hour24Score}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 145, borderRightWidth: 2 }}>
                    <div className="text-center font-semibold">
                      {NIHStrokeDateTimeData?.days7Score}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 150, borderRightWidth: 2 }}>
                    <div className="text-center font-semibold">
                      {NIHStrokeDateTimeData?.onDischargeScore}
                    </div>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
