import axios from "axios";
import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

/////registration autocomplte
export const fetchRegistrationSearchDropDown = (
  searchString,
  isAppointment
) => {
  return apiClient.get(
    `/patientInfo/registrationAutoComplete/${searchString}/${isAppointment}`,
    {
      headers: authHeader(),
    }
  );
};

///fetch data
export const fetchSearchedregistrationData = (PatientId, isAppointment) => {
  return apiClient.get(
    `/patientInfo/regPatientInfo/${PatientId}/${isAppointment}`,
    {
      headers: authHeader(),
    }
  );
};

///fetch data
export const fetchBlacklistAproval = (obj) => {
  return apiClient.post(`/patientInfo/addPTToBlackList`, obj, {
    headers: authHeader(),
  });
};

////fetch Source
export const fetchSource = () => {
  //
  return apiClient.get(`/misc/source`, {
    headers: authHeader(),
  });
};

////check adhar number duplication
export const fetchAdharStatus = (aadharNo) => {
  //
  return apiClient.get(`/admission/checkAadharNo/${aadharNo}`, {
    headers: authHeader(),
  });
};

////check Patient duplication
export const fetchPatientStatus = (firstName, lastName, mobileNo) => {
  //
  return apiClient.get(
    `/patientInfo/checkPatientAlreadyExists/${firstName}/${lastName}/${mobileNo}`,
    {
      headers: authHeader(),
    }
  );
};

///visit status
export const fetchVisitStatus = (patientId, departmentId, doctorId) => {
  //
  return apiClient.get(
    `/patientInfo/visitStatus/${patientId}/${departmentId}/${doctorId}`,
    {
      headers: authHeader(),
    }
  );
};

///visit status
export const fetchEmergencyNo = () => {
  //
  return apiClient.get(`/patientInfo/emergencyNumberForOPD`, {
    headers: authHeader(),
  });
};

// submit data //
export const PostMyPatientRegistrationInfo = (finalObject) => {
  //
  return apiClient.post(`/patientInfo`, finalObject, {
    headers: authHeader(),
  });
};

//http://192.168.0.61:5003/api/feedbackReports/getPatientQuestionWiseFeedbackReportExcel

export const getPatientQuestionWiseFeedbackReportExcel = (postObj) => {
  return apiClient.post(
    `/feedbackReports/getPatientQuestionWiseFeedbackReportExcel`,
    postObj,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};

