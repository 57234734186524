import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../../common/components/FormFields/RadioField";
import InjuryCertificatePrint from "../../../prints/InjuryCertiificatePrint";
import MlcCertificatePrint from "../../../prints/MlcCertificatePrint";
import { fetchMlcInjuryCertificates } from "../../../services/admissionServices/AdmissionServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

const Actions = [
  {
    id: 0,
    action: "Print",
    isAction: true,
  },
];

const CertificateTypeArr = [
  {
    id: 0,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "Injury Certificate",
    value: "Injury Certificate",
  },
  {
    id: 2,
    label: "MLC Certificate",
    value: "MLC Certificate",
  },
];

export default function MlcInjuryCertificatesListModal(props) {
  const {
    open,
    handleClose,
    //userActions,
    //  setSelectedRow,
    selectedCertificateType,
  } = props;

  //
  const [dataResult, setDataResult] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState();

  const [selectedRow, setSelectedRow] = useState(null);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      toDate: new Date(),
      fromDate: new Date(),
      certificateType: 0,
    },
  });

  const { control, watch } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  let selected_certificate = watch("certificateType");

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 500);

    return (
      <div className="hidden">
        {selectedRow?.CertificateType === 1 ? (
          <InjuryCertificatePrint certificateId={selectedRow?.Id} />
        ) : (
          <MlcCertificatePrint certificateId={selectedRow?.Id} />
        )}
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction && (
              <>
                <div className="flex gap-1 items-center cursor-pointer">
                  {actions.action === "Print" && (
                    <Tooltip title=" Print Certificate">
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          setSelectedRow(row);
                          setOpenPrintModal(true);
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </>
            )}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, selected_certificate]);

  const populateTable = (forPagination) => {
    const listObj = {
      certificateType:
        Number(selected_certificate) === 0
          ? null
          : Number(selected_certificate),
      opdIpd: 1,
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchMlcInjuryCertificates(listObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setCount(res.count);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
      });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%] ">
          <div className="m-2">
            <CancelPresentationIconButton
              onClick={() => {
                handleClose();
              }}
            />

            <div className={"text-lg font-semibold p-2"}>View Certificates</div>

            <hr className="border mx-1  border-blue-900" />

            <div className="grid grid-cols-5 mt-5 gap-3 items-center">
              {/* from Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              {/* to Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              <div className="ml-1">
                <RadioField
                  label=""
                  name="certificateType"
                  control={control}
                  dataArray={CertificateTypeArr}
                />
              </div>
            </div>
            <div>
              {dataResult.length > 0 ? (
                <>
                  <CommonDynamicTablePaginationNew
                    dataResult={dataResult}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    renderActions={renderActions}
                    removeHeaders={[
                      "Id",
                      "Opd_Ipd_External",
                      "Opd_Ipd_External_Id",
                      "AdmDoctorrId",
                      "AgeDay",
                      "AgeMonth",
                      "BreifHistory",
                      "AddedUpdatedBy",
                      "AgeOfInjury",
                      "BuckleNo",
                      "GenderId",
                      "InjuriesExaminByDr",
                      "PT Arrival DateTime",
                      "NatureOfInjury",
                      "PoliceStation",
                      "SimpleGraviousDangerous",
                      "Treat From Time",
                      "Treat To Time",
                      "WeaponUsed",
                      "Duration",
                      "DateTime Of Accident Assault",
                      "DateTime Of Examin",
                      "CauseOfInjury",
                      "Constable",
                    ]}
                    highlighRow={false}
                    tableClass={"rounded lg:h-52 md:h-72 xl:h-96"}

                    //
                    // selectedFromDate={selectedFromDate}
                    // selectedToDate={selectedToDate}
                    // handleClose={handleClose}
                  />
                </>
              ) : (
                <h3 className="flex justify-center my-20 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {/*  Print Modal */}

      {openPrintModal && renderPrint()}
    </div>
  );
}
