import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useContext } from "react";
import PhlebitiesScoreTable from "./PhlebitiesScoreTable";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
//imports from react hook form
import { useForm } from "react-hook-form";

//imports from the yup library
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { VisitContext } from "../ClinicalCareChart";
import {
  postPhlebitiesScore,
  getPhlebitiesScore,
} from "../../../services/cliinicalChartServices/painAssesmentService/PhlebitiesServices";

import {
  errorAlertCustom,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";

import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

const ModalStyleReportMedication = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  border: "1px solid gray",
};

//the object to reset the form to blank values
const defaultValues = {
  ivSiteHealthy: false,
  painNearIv: false,
  rednessNearIv: false,
  painAtIvSite: false,
  redness: false,
  swelling: false,
  painatAlongCannula: false,
  rednessAroundSite: false,
  swellingFour: false,
  painatAlongCannulaFive: false,
  rednessAroundSiteFive: false,
  swellingFive: false,
  phalableVenousCordFive: false,
  painatAlongCannulaSix: false,
  rednessAroundSiteSix: false,
  swellingSix: false,
  phalableVenousCordSix: false,
  pyrexia: false,
};

function PhlebitiesModal(props) {
  const Actions = [
    {
      id: 0,
      action: "Save",
      isAction: false,
    },
  ];

  const {
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm({
    // use mode to specify the event that triggers each input field
    mode: "onChange",
    defaultValues,
  });

  const admissionId = useContext(VisitContext);

  const [phlebitiesData, setPhlebitiesData] = React.useState([]);

  const [incomingPhlebitiesScore, setIncomingPhlebitiesScore] = React.useState(
    null
  );

  //state variable to open the post obj confirmation
  const [
    phlebitiesSaveConfirmation,
    setPhelbitiesSaveConfirmation,
  ] = React.useState(false);

  //state variable to open the update obj confirmation
  const [
    phlebitiesUpdateConfirmation,
    setPhlebitiesUpdateConfirmation,
  ] = React.useState(false);

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  useEffect(() => {
    let admissionIdVal = null;

    //If the admissionId is available through visitContext.
    //Means when the admissionId is not undefined
    if (admissionId) {
      admissionIdVal = admissionId;
    }

    //If the admissionId is not available through visitContext.
    //Means when the admissionId is undefined
    if (!admissionId) {
      admissionIdVal = props.admissionId;
    }

    getPhlebitiesScore(admissionIdVal).then((response) => {
      if (response.data.result.length !== 0) {
        setPhlebitiesData(response.data.result);
      }
    });
  }, []);

  let vipScore = null;

  let ivSiteHealthy = watch("ivSiteHealthy");

  let painNearIv = watch("painNearIv");
  let rednessNearIv = watch("rednessNearIv");

  let painAtIvSite = watch("painAtIvSite");
  let redness = watch("redness");
  let swelling = watch("swelling");

  let painatAlongCannula = watch("painatAlongCannula");
  let rednessAroundSite = watch("rednessAroundSite");
  let swellingFour = watch("swellingFour");

  let painatAlongCannulaFive = watch("painatAlongCannulaFive");
  let rednessAroundSiteFive = watch("rednessAroundSiteFive");
  let swellingFive = watch("swellingFive");
  let phalableVenousCordFive = watch("phalableVenousCordFive");

  let painatAlongCannulaSix = watch("painatAlongCannulaSix");
  let rednessAroundSiteSix = watch("rednessAroundSiteSix");
  let swellingSix = watch("swellingSix");
  let phalableVenousCordSix = watch("phalableVenousCordSix");
  let pyrexia = watch("pyrexia");

  let fieldSetOneFlag =
    painNearIv ||
    rednessNearIv ||
    painAtIvSite ||
    redness ||
    swelling ||
    painatAlongCannula ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSiteFive ||
    swellingFive ||
    phalableVenousCordFive ||
    painatAlongCannulaSix ||
    rednessAroundSiteSix ||
    swellingSix ||
    phalableVenousCordSix ||
    pyrexia;

  let fieldSetTwoFlag =
    ivSiteHealthy ||
    painAtIvSite ||
    redness ||
    swelling ||
    painatAlongCannula ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSiteFive ||
    swellingFive ||
    phalableVenousCordFive ||
    painatAlongCannulaSix ||
    rednessAroundSiteSix ||
    swellingSix ||
    phalableVenousCordSix ||
    pyrexia;

  let fieldSetThreeFlag =
    ivSiteHealthy ||
    painNearIv ||
    rednessNearIv ||
    painatAlongCannula ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSiteFive ||
    swellingFive ||
    phalableVenousCordFive ||
    painatAlongCannulaSix ||
    rednessAroundSiteSix ||
    swellingSix ||
    phalableVenousCordSix ||
    pyrexia;

  let fieldSetFourFlag =
    ivSiteHealthy ||
    painNearIv ||
    rednessNearIv ||
    painAtIvSite ||
    redness ||
    swelling ||
    painatAlongCannulaFive ||
    painatAlongCannulaFive ||
    rednessAroundSiteFive ||
    swellingFive ||
    phalableVenousCordFive ||
    painatAlongCannulaSix ||
    rednessAroundSiteSix ||
    swellingSix ||
    phalableVenousCordSix ||
    pyrexia;

  let fieldSetFiveFlag =
    ivSiteHealthy ||
    painNearIv ||
    rednessNearIv ||
    painAtIvSite ||
    redness ||
    swelling ||
    painatAlongCannula ||
    rednessAroundSite ||
    swellingFour ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaSix ||
    rednessAroundSiteSix ||
    swellingSix ||
    phalableVenousCordSix ||
    pyrexia;

  let fieldSetSixFlag =
    ivSiteHealthy ||
    painNearIv ||
    rednessNearIv ||
    painAtIvSite ||
    redness ||
    swelling ||
    painatAlongCannula ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSite ||
    swellingFour ||
    painatAlongCannulaFive ||
    rednessAroundSiteFive ||
    swellingFive ||
    phalableVenousCordFive;

  if (ivSiteHealthy) {
    vipScore = 0;
  }

  if (painNearIv || rednessNearIv) {
    vipScore = 1;
  }

  if (painAtIvSite || redness || swelling) {
    vipScore = 2;
  }

  if (painatAlongCannula || rednessAroundSite || swellingFour) {
    vipScore = 3;
  }

  if (
    painatAlongCannulaFive ||
    rednessAroundSiteFive ||
    swellingFive ||
    phalableVenousCordFive
  ) {
    vipScore = 4;
  }

  if (
    painatAlongCannulaSix ||
    rednessAroundSiteSix ||
    swellingSix ||
    phalableVenousCordSix ||
    pyrexia
  ) {
    vipScore = 5;
  }

  function savePhlebitiesScore() {
    let userNameVal = localStorage.getItem("userInfo");
    userNameVal = JSON.parse(userNameVal);

    let requiredPostObj = {
      ivSite: false,

      slightPainIv: false,
      slightRednessIv: false,

      painAtIv: false,
      redness: false,
      swelling: false,

      painCannula: false,
      rednessAroundSite: false,
      extensiveSwelling: false,

      extensive1PainCannula: false,
      extensive1RednessAroundSite: false,
      extensive1Swelling: false,
      extensive1PalpableCord: false,

      extensivePainCannula: false,
      extensiveRednessAroundSite: false,
      signEvidentSwelling: false,
      extensivePalpableCord: false,
      extensive1Pyrexia: false,

      addedBy: 0,
      admissionId: 0,
      totalScore: 0,
      vipId: 0,
      privilege: "",
      menuId: 0,
    };

    requiredPostObj.menuId = props.menuId;

    let privilege = "";

    let tempUserActions = structuredClone(props.userActions);

    for (let tempUserActionObj of tempUserActions) {
      if (tempUserActionObj.action === "Create") {
        privilege = "Create";
        break;
      }
    }

    requiredPostObj.privilege = privilege;

    if (!admissionId) {
      requiredPostObj.admissionId = props.admissionId; //value coming from AdmissionListCollapsibleTable.js
    } else {
      requiredPostObj.admissionId = admissionId; //value coming from useContext
    }

    requiredPostObj.totalScore = vipScore;
    requiredPostObj.addedBy = userNameVal.userId;

    if (vipScore === 0) {
      let ivSiteHealthyVal = getValues("ivSiteHealthy");
      if (ivSiteHealthyVal) {
        requiredPostObj.ivSite = true;
      }
    }

    if (vipScore === 1) {
      let painNearIvVal = getValues("painNearIv");
      let rednessNearIvVal = getValues("rednessNearIv");

      if (painNearIvVal) {
        requiredPostObj.slightPainIv = true;
      }

      if (rednessNearIvVal) {
        requiredPostObj.slightRednessIv = true;
      }
    }

    if (vipScore === 2) {
      let painAtIvSiteVal = getValues("painAtIvSite");
      let rednessVal = getValues("redness");
      let swellingVal = getValues("swelling");

      if (painAtIvSiteVal) {
        requiredPostObj.painAtIv = true;
      }

      if (rednessVal) {
        requiredPostObj.redness = true;
      }

      if (swellingVal) {
        requiredPostObj.swelling = true;
      }
    }

    if (vipScore === 3) {
      let painatAlongCannulaVal = getValues("painatAlongCannula");
      let rednessAroundSiteVal = getValues("rednessAroundSite");
      let swellingFourVal = getValues("swellingFour");

      if (painatAlongCannulaVal) {
        requiredPostObj.painCannula = true;
      }

      if (rednessAroundSiteVal) {
        requiredPostObj.rednessAroundSite = true;
      }

      if (swellingFourVal) {
        requiredPostObj.extensiveSwelling = true;
      }
    }

    if (vipScore === 4) {
      let painatAlongCannulaFiveVal = getValues("painatAlongCannulaFive");
      let rednessAroundSiteFiveVal = getValues("rednessAroundSiteFive");
      let swellingFiveVal = getValues("swellingFive");
      let phalableVenousCordFiveVal = getValues("phalableVenousCordFive");

      if (painatAlongCannulaFiveVal) {
        requiredPostObj.extensive1PainCannula = true;
      }

      if (rednessAroundSiteFiveVal) {
        requiredPostObj.extensive1RednessAroundSite = true;
      }

      if (swellingFiveVal) {
        requiredPostObj.extensive1Swelling = true;
      }

      if (phalableVenousCordFiveVal) {
        requiredPostObj.extensive1PalpableCord = true;
      }
    }

    if (vipScore === 5) {
      let painatAlongCannulaSixVal = getValues("painatAlongCannulaSix");
      let rednessAroundSiteSixVal = getValues("rednessAroundSiteSix");
      let swellingSixVal = getValues("swellingSix");
      let phalableVenousCordSixVal = getValues("phalableVenousCordSix");
      let pyrexiaVal = getValues("pyrexia");

      if (painatAlongCannulaSixVal) {
        requiredPostObj.extensivePainCannula = true;
      }

      if (rednessAroundSiteSixVal) {
        requiredPostObj.extensiveRednessAroundSite = true;
      }

      if (swellingSixVal) {
        requiredPostObj.signEvidentSwelling = true;
      }

      if (phalableVenousCordSixVal) {
        requiredPostObj.extensivePalpableCord = true;
      }

      if (pyrexiaVal) {
        requiredPostObj.extensive1Pyrexia = true;
      }
    }

    if (incomingPhlebitiesScore !== null) {
      requiredPostObj.vipId = incomingPhlebitiesScore.VIPId;
    }

    setOpenBackdrop(true);

    postPhlebitiesScore(requiredPostObj)
      .then((response) => {
        setOpenBackdrop(false);

        if (phlebitiesSaveConfirmation) {
          setPhelbitiesSaveConfirmation(false);
        }

        if (phlebitiesUpdateConfirmation) {
          setPhlebitiesUpdateConfirmation(false);
        }

        successAlert(response.data.message);
        props.handleClose();
      })
      .catch((error) => {
        setOpenBackdrop(false);
        if (phlebitiesSaveConfirmation) {
          setPhelbitiesSaveConfirmation(false);
        }
        errorAlertCustom(error.response.data.message);
      });
  }

  function updatePhlebitiesScore() {
    if (isDirty === true) {
      setPhlebitiesUpdateConfirmation(true);
    } else {
      errorAlertCustom("Please select at least one symptom.");
    }
  }

  return (
    <>
      <div className=" backdrop-blur-0">
        <Modal
          open={props.open}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={ModalStyleReportMedication}
            className="w-[90%] h-[88%] xl:h-[80%] mt-2 xl:max-h-[90%]"
          >
            <div className="sticky top-0 bg-white z-50">
              <div className="px-4">
                <div className="flex items-center w-full mt-2">
                  <div className="w-full flex justify-center items-center">
                    <div className="w-full text-normal font-semibold text-center">
                      Phlebities Score
                    </div>
                  </div>

                  <div className="mb-2">
                    <CancelPresentationIconButton
                      onClick={() => {
                        props.handleClose();
                        setIncomingPhlebitiesScore(null);
                      }}
                    />
                  </div>
                </div>
                <div className="grid px-3 rounded mt-1">
                  <div className="grid grid-cols-2 lg:grid-cols-3 bg-gray-100 border-gray-300 gap-x-2 py-2">
                    <div className="grid gap-2 border-r-2 border-slate-500 my-1">
                      <div className="hidden lg:block">
                        <div className="flex gap-2 text-sm">
                          <h1 className="text-black font-semibold flex items-center lg:space-x-2">
                            <span>Patient Name </span>
                            <span className=""> :</span>
                          </h1>
                          <h1 className="text-black font-normal">
                            {props.displayInfo?.PatientName ||
                              props.selectedPatient?.PatientName}
                          </h1>
                        </div>
                      </div>
                      <div className="flex gap-2 text-sm">
                        <h1 className="text-black font-semibold flex space-x-9 lg:space-x-9">
                          <span>Dr. Name</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.DoctorName ||
                            props.selectedPatient?.DoctorName}
                        </h1>
                      </div>
                      <div className="lg:hidden">
                        <div className="flex gap-2 text-sm">
                          <h1 className="text-black items-center font-semibold flex space-x-16 lg:space-x-16">
                            <span>Age</span>
                            <span className="">:</span>
                          </h1>
                          <h1 className="text-black font-normal">
                            {props.displayInfo?.AgeYear ||
                              props.selectedPatient?.AgeYear}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-2  lg:border-r-2 pl-4 border-slate-500 my-1">
                      <div className="flex gap-2 text-sm">
                        <h1 className="text-black items-center font-semibold flex space-x-14 lg:space-x-16">
                          <span>MR NO.</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.MRNo ||
                            props.selectedPatient?.MRNo}
                        </h1>
                      </div>
                      <div className="flex gap-2 text-sm">
                        <h1 className="text-black items-center font-semibold flex space-x-16 lg:space-x-9">
                          <span>Department</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.Department ||
                            props.selectedPatient?.Department}
                        </h1>
                      </div>
                      <div className="lg:hidden">
                        <div className="flex gap-2 text-sm">
                          <h1 className="text-black font-semibold flex items-center space-x-3 lg:space-x-1">
                            <span>PatientName </span>
                            <span className=""> :</span>
                          </h1>
                          <h1 className="text-black font-normal">
                            {props.displayInfo?.PatientName ||
                              props.selectedPatient?.PatientName}
                          </h1>
                        </div>
                      </div>

                      <div className="lg:hidden">
                        <div className="flex gap-2 text-sm">
                          <h1 className="flex items-center text-black font-semibold space-x-11 lg:space-x-11">
                            <span>VIP Score</span>
                            <span className="">:</span>
                          </h1>
                          {ivSiteHealthy ? (
                            <label
                              className="font-semibold text-white md:w-7 pt-1 lg:pt-2 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-yellow-500 flex justify-center items-center  bg-yellow-500"
                              name="vipScoreSix"
                            >
                              0
                            </label>
                          ) : (
                            ""
                          )}

                          {painNearIv || rednessNearIv ? (
                            <label
                              className="font-semibold text-white md:w-7 pt-1 lg:pt-2 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center  items-center bg-red-500"
                              name="vipScoreSix"
                            >
                              1
                            </label>
                          ) : (
                            ""
                          )}

                          {painAtIvSite || redness || swelling ? (
                            <label
                              className="font-semibold text-white md:w-7 pt-1 lg:pt-2 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center  items-center bg-red-500"
                              name="vipScoreSix"
                            >
                              2
                            </label>
                          ) : (
                            ""
                          )}

                          {painatAlongCannula ||
                          rednessAroundSite ||
                          swellingFour ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 pt-1 lg:pt-2 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center items-center  bg-red-500"
                              name="vipScoreSix"
                            >
                              3
                            </label>
                          ) : (
                            ""
                          )}

                          {painatAlongCannulaFive ||
                          rednessAroundSiteFive ||
                          swellingFive ||
                          phalableVenousCordFive ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 pt-1 lg:pt-2 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center items-center  bg-red-500"
                              name="vipScoreSix"
                            >
                              4
                            </label>
                          ) : (
                            ""
                          )}

                          {painatAlongCannulaSix ||
                          rednessAroundSiteSix ||
                          swellingSix ||
                          phalableVenousCordSix ||
                          pyrexia ? (
                            <label
                              className="font-semibold text-white md:w-7 pt-1 lg:pt-2 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center  items-center bg-red-500"
                              name="vipScoreSix"
                            >
                              5
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Age VIP Score Show lg Device */}
                    <div className="grid gap-2 pl-3">
                      <div className="hidden lg:block">
                        <div className="flex gap-2 ">
                          <h1 className="text-black items-center font-semibold flex space-x-6 lg:space-x-14">
                            <span>Age</span>
                            <span className="">:</span>
                          </h1>
                          <h1 className="text-black font-normal">
                            {props.displayInfo?.AgeYear ||
                              props.selectedPatient?.AgeYear}
                          </h1>
                        </div>
                      </div>
                      <div className="hidden lg:block">
                        <div className="flex gap-2 items-center">
                          <h1 className="flex items-center text-black font-semibold space-x-9 lg:space-x-3">
                            <span>VIP Score</span>
                            <span className="">:</span>
                          </h1>
                          {ivSiteHealthy ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-yellow-500 flex justify-center items-center  bg-yellow-500"
                              name="vipScoreSix"
                            >
                              0
                            </label>
                          ) : (
                            ""
                          )}

                          {painNearIv || rednessNearIv ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center  items-center bg-red-500"
                              name="vipScoreSix"
                            >
                              1
                            </label>
                          ) : (
                            ""
                          )}

                          {painAtIvSite || redness || swelling ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center  items-center bg-red-500"
                              name="vipScoreSix"
                            >
                              2
                            </label>
                          ) : (
                            ""
                          )}

                          {painatAlongCannula ||
                          rednessAroundSite ||
                          swellingFour ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center items-center  bg-red-500"
                              name="vipScoreSix"
                            >
                              3
                            </label>
                          ) : (
                            ""
                          )}

                          {painatAlongCannulaFive ||
                          rednessAroundSiteFive ||
                          swellingFive ||
                          phalableVenousCordFive ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center items-center  bg-red-500"
                              name="vipScoreSix"
                            >
                              4
                            </label>
                          ) : (
                            ""
                          )}

                          {painatAlongCannulaSix ||
                          rednessAroundSiteSix ||
                          swellingSix ||
                          phalableVenousCordSix ||
                          pyrexia ? (
                            <label
                              className="font-semibold text-white md:w-7 md:h-7 lg:w-10 lg:h-10 rounded-full border-2 border-red-500 flex justify-center  items-center bg-red-500"
                              name="vipScoreSix"
                            >
                              5
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <fieldset className="flex justify-between col-span-3 w-auto  rounded">
                        {ivSiteHealthy ||
                        painNearIv ||
                        rednessNearIv ||
                        painAtIvSite ||
                        redness ||
                        swelling ||
                        painatAlongCannula ||
                        rednessAroundSite ||
                        swellingFour ||
                        painatAlongCannulaFive ||
                        rednessAroundSiteFive ||
                        swellingFive ||
                        phalableVenousCordFive ||
                        painatAlongCannulaSix ||
                        rednessAroundSiteSix ||
                        swellingSix ||
                        phalableVenousCordSix ||
                        pyrexia ? (
                          <ul>
                            {ivSiteHealthy && (
                              <li className="text-yellow-500 text-sm font-semibold  border border-yellow-500 rounded p-2 ">
                                No Sign Of Phlebits
                              </li>
                            )}

                            {painNearIv || rednessNearIv ? (
                              <li className="text-red-600 text-sm font-semibold  border border-red-600 rounded p-2">
                                Possibly first phlebits OBSERVE CANNULA
                              </li>
                            ) : (
                              ""
                            )}

                            {painAtIvSite || redness || swelling ? (
                              <li className="text-red-600 text-sm font-semibold  border border-red-600 rounded p-2">
                                Early stage of phlebitis RESITE CANNULA
                              </li>
                            ) : (
                              ""
                            )}

                            {painatAlongCannula ||
                            rednessAroundSite ||
                            swellingFour ? (
                              <li className="text-red-600 text-sm font-semibold  border border-red-600 rounded p-2">
                                Medium stage of phlebitis RESITE CANNULA
                                CONSIDER TREATMENT
                              </li>
                            ) : (
                              ""
                            )}

                            {painatAlongCannulaFive ||
                            rednessAroundSiteFive ||
                            swellingFive ||
                            phalableVenousCordFive ? (
                              <li className="text-red-600 text-sm font-semibold  border border-red-600 rounded p-2">
                                Advance stage of phlebits or the start of
                                thrombophlebitis RESITE CANNULA CONSIDER
                                TREATMENT
                              </li>
                            ) : (
                              ""
                            )}

                            {painatAlongCannulaSix ||
                            rednessAroundSiteSix ||
                            swellingSix ||
                            phalableVenousCordSix ||
                            pyrexia ? (
                              <li className="text-red-600 text-sm  font-semibold  border border-red-600 rounded p-2">
                                Advance stage of thrombophlebitis INITIATE
                                TREATMENT RESITE CANNULA
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex space-x-2 items-center px-4"> */}
            <div className="w-full px-4">
              <PhlebitiesScoreTable
                dataArray={phlebitiesData}
                setIncomingPhlebitiesScore={setIncomingPhlebitiesScore}
                setValue={setValue}
                defaultValues={defaultValues}
                reset={reset}
              />
            </div>
            {/* </div> */}

            <form className="w-full px-4 ">
              {/* <div> */}
              <h6 className="text-base font-bold mt-2">VIP</h6>
              {/* 1 */}
              <fieldset
                className="flex justify-between border  border-gray-300 col-span-3 w-full text-left mt-1 px-4 rounded"
                disabled={fieldSetOneFlag}
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1">
                  IV site appears healthy
                </legend>
                <CheckBoxField
                  control={control}
                  name="ivSiteHealthy"
                  label="IV site appears healthy"
                  value="IV site appears healthy"
                  style={fieldSetOneFlag ? { color: "lightGray" } : null}
                />
              </fieldset>
              {/* 2 */}
              <fieldset
                className="flex justify-between border border-gray-300 col-span-3 w-full text-left mt-3 px-4 rounded"
                disabled={fieldSetTwoFlag}
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1">
                  One of the following signs are evident and extensive
                </legend>
                <div className="flex gap-2">
                  <CheckBoxField
                    control={control}
                    name="painNearIv"
                    label="Slight pain near IV site OR"
                    value="Slight pain near IV site OR"
                    style={fieldSetTwoFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="rednessNearIv"
                    label="Slight redness near IV site"
                    value="Slight redness near IV site"
                    style={fieldSetTwoFlag ? { color: "lightGray" } : null}
                  />
                </div>
              </fieldset>
              {/* 3 */}
              <fieldset
                className="flex justify-between border border-gray-300 col-span-3 w-full text-left mt-3 px-4 rounded"
                disabled={fieldSetThreeFlag}
                // style={fieldSetThreeFlag?{backgroundColor:"grey"}:null}
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1">
                  Two of the following signs are evident and extensive
                </legend>
                <div className="flex gap-2">
                  <CheckBoxField
                    control={control}
                    name="painAtIvSite"
                    label="Pain at IV site"
                    value="Pain at IV site"
                    style={fieldSetThreeFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="redness"
                    label="Redness"
                    value="Redness"
                    style={fieldSetThreeFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="swelling"
                    label="Swelling"
                    value="Swelling"
                    style={fieldSetThreeFlag ? { color: "lightGray" } : null}
                  />
                </div>
              </fieldset>
              {/* 4 */}
              <fieldset
                className="flex justify-between border border-gray-300 col-span-3 w-full text-left mt-3 px-4 rounded  "
                disabled={fieldSetFourFlag}
                //  style={fieldSetFourFlag?{backgroundColor:"grey"}:null}
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1">
                  All of the following signs are evident
                </legend>
                <div className="flex gap-2">
                  <CheckBoxField
                    control={control}
                    name="painatAlongCannula"
                    label="Pain along path of cannula"
                    value="Pain along path of cannula"
                    style={fieldSetFourFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="rednessAroundSite"
                    label="Redness around site"
                    value="Redness around site"
                    style={fieldSetFourFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="swellingFour"
                    label="Swelling"
                    value="Swelling"
                    style={fieldSetFourFlag ? { color: "lightGray" } : null}
                  />
                </div>
              </fieldset>
              {/* 5 */}
              <fieldset
                className="flex justify-between border border-gray-300 col-span-3 w-full text-left mt-3 px-4 rounded"
                disabled={fieldSetFiveFlag}
                //  style={fieldSetFiveFlag?{backgroundColor:"grey"}:null}
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1">
                  All of the following signs are evident and extensive
                </legend>
                <div className="flex gap-2">
                  <CheckBoxField
                    control={control}
                    name="painatAlongCannulaFive"
                    label="Pain along path of cannula"
                    value="Pain along path of cannula"
                    style={fieldSetFiveFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="rednessAroundSiteFive"
                    label="Redness around site"
                    value="Redness around site"
                    style={fieldSetFiveFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="swellingFive"
                    label="Swelling"
                    value="Swelling"
                    style={fieldSetFiveFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="phalableVenousCordFive"
                    label="Phalable venous cord"
                    value="Phalable venous cord"
                    style={fieldSetFiveFlag ? { color: "lightGray" } : null}
                  />
                </div>
              </fieldset>
              {/* 6 */}
              <fieldset
                className="flex justify-between border border-gray-300 col-span-3 w-full text-left mt-3 px-4 rounded"
                disabled={fieldSetSixFlag}
                //  style={fieldSetSixFlag?{backgroundColor:"grey"}:null}
              >
                <legend className="font-semibold text-sm text-gray-700 ml-2 lg:ml-1">
                  All of the following signs are evident and extensive
                </legend>
                <div className="flex gap-2">
                  <CheckBoxField
                    control={control}
                    name="painatAlongCannulaSix"
                    label="Pain along path of cannula"
                    value="Pain along path of cannula"
                    style={fieldSetSixFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="rednessAroundSiteSix"
                    label="Redness around site"
                    value="Redness around site"
                    style={fieldSetSixFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="swellingSix"
                    label="Swelling"
                    value="Swelling"
                    style={fieldSetSixFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="phalableVenousCordSix"
                    label="Phalable Venous Cord"
                    value="Phalable Venous Cord"
                    style={fieldSetSixFlag ? { color: "lightGray" } : null}
                  />

                  <CheckBoxField
                    control={control}
                    name="pyrexia"
                    label="Pyrexia"
                    value="Pyrexia"
                    style={fieldSetSixFlag ? { color: "lightGray" } : null}
                  />
                </div>
              </fieldset>
              <div className="flex justify-end my-1">
                {props.userActions &&
                  props.userActions.map((singleActionObj) => {
                    if (
                      singleActionObj.action === "Create" &&
                      singleActionObj.isAction === false &&
                      incomingPhlebitiesScore === null
                    ) {
                      return (
                        <>
                          <div className="mt-2 flex justify-end space-x-2">
                            <CommonButton
                              label="Reset"
                              
                              className="border border-customRed text-customRed"
                              onClick={() => {
                                reset(defaultValues);
                                setIncomingPhlebitiesScore(null);
                              }}
                            />

                            {props.patientInformation?.AllowToGO === true ||
                            props.selectedPatient?.AllowToGO === true ? null : (
                              <CommonButton
                                label="Save"
                                className="bg-customGreen text-white"
                                onClick={() => {
                                  if (isDirty === true) {
                                    setPhelbitiesSaveConfirmation(true);
                                  } else {
                                    errorAlertCustom(
                                      "Please select at least one symptom."
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        </>
                      );
                    } else if (
                      singleActionObj.action === "Create" &&
                      singleActionObj.isAction === false &&
                      incomingPhlebitiesScore !== null
                    ) {
                      return (
                        <>
                          <div className="mt-2 flex justify-end space-x-2">
                            <CommonButton
                              label="Reset"
                              
                              className="border border-customRed text-customRed"
                              onClick={() => {
                                reset(defaultValues);
                                setIncomingPhlebitiesScore(null);
                              }}
                            />

                            <CommonButton
                              label="Update"
                              className="bg-customGreen text-white"
                              onClick={() => {
                                updatePhlebitiesScore();
                              }}
                             
                            />
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </form>
          </Box>
        </Modal>
        {/* Confirmation modal for POST request */}
        <ConfirmationModal
          confirmationOpen={phlebitiesSaveConfirmation}
          confirmationHandleClose={() => {
            setPhelbitiesSaveConfirmation(false);
          }}
          confirmationSubmitFunc={() => {
            savePhlebitiesScore();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure you want to save this phlebities score ?"
          confirmationButtonMsg="Save"
        />

        {/* Confirmation modal for UPDATE request */}
        <ConfirmationModal
          confirmationOpen={phlebitiesUpdateConfirmation}
          confirmationHandleClose={() => {
            setPhlebitiesUpdateConfirmation(false);
          }}
          confirmationSubmitFunc={() => {
            savePhlebitiesScore();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure you want to update this phlebities score ?"
          confirmationButtonMsg="Update"
        />
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
    </>
  );
}

export default PhlebitiesModal;
