import React, { useEffect, useState, useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { sugerLevelPrintData } from "../services/cliinicalChartServices/sugarlevelServices/SugarLevelServices";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";

const SugarLevelPrint = (props) => {
  //SugerLeveel
  const [patientSugerInfo, setPatientSugerInfo] = useState({});
  const [patientInfoSugerDate, setPatientInfoSugerDate] = useState({});
  const [resultSuger, setResultSuger] = useState([]);

  const headers = [
    "Date",
    "Time",
    "BSL",
    "Dose",
    "Informed To",
    "Insulin GivenBy",
  ];
  const printRef = useRef(null);

  useEffect(() => {
    const postObj = {
      admissionId: props.admissionId,
      fromDate: props.fromDate,
      toDate: props.toDate,
      fromTime: props.fromTime,
      toTime: props.toTime,
    };
    sugerLevelPrintData(postObj)
      .then((response) => response.data.result)
      .then((res) => {
        if (res.sugarLevelList.length === 0) {
          warningAlert("Please Select Valid Date And Time..!");
        } else {
          const obj = {
            "Mr No": res.MRNo,
            "Patient Name": res.PatientName,
            "Doctor Name": res.DoctorName,
            "Admission Date": res.AdmissionDate,
          };
          setPatientSugerInfo(obj);
          const patientInfoDate = {
            "From Date": res.FromDate,
            "To Date": res.ToDate,
          };
          setPatientInfoSugerDate(patientInfoDate);
          const patientObj = res.sugarLevelList.map((data) => {
            return {
              Date: data.Date,
              Time: data.Time,
              BSL: data.BSL || 0,
              Dose: data.dosetab || 0,
              "Informed To": data.InFormTo,
              "Insulin GivenBy": data.GivenBy,
            };
          });

          setResultSuger([...patientObj]);
        }
      });
  }, [props]);

  useEffect(() => {
    resultSuger.length > 0 && printRef && GeneratePrint(printRef);
  }, [resultSuger]);

  return (
    <div ref={printRef} className="w-full">
      <table className="w-full">
        <table className="w-full ">
          <thead
            style={{
              "-fs-table-paginate": "paginate",
            }}
          >
            <tr>
              <th colSpan={headers?.length + 1}>
                <div className="flex justify-between gap-10 w-full">
                  <div className="space-y-2  text-left justify-start font-normal">
                    {Object.entries(patientSugerInfo).map(([key, value]) => (
                      <div key={key} className="flex items-center text-[11px]">
                        <p className=" font-semibold text-[11px]   w-[95px]">
                          {key}
                        </p>
                        <p className="font-semibold text-[11px] mx-2">:</p>
                        <p className="text-[11px] whitespace-nowrap">{value}</p>
                      </div>
                    ))}
                  </div>

                  <div className="flex  justify-end">
                    <InamdarHospiLogo />
                  </div>
                </div>
                <div className="flex  justify-end items-end gap-2 -mt-3">
                  {Object.entries(patientInfoSugerDate).map(([key, value]) => (
                    <div key={key} className="flex items-center">
                      <p className="text-[11px] font-semibold">{key}</p>
                      <p className="font-semibold text-[11px]  mx-2">:</p>
                      <p className="text-[11px]  whitespace-nowrap">{value}</p>
                    </div>
                  ))}
                </div>
                <div className="font-semibold  border-t-[1px] border-black pt-1 text-center xl:text-xl sm:text-sm md:text-md whitespace-nowrap">
                  Blood Sugar Level Chart
                </div>
              </th>
            </tr>
            <tr className="border-b-[1px]   border-black  border-t-[1px] w-full ">
              {headers?.map((header, index) => (
                <td key={index} className="text-center py-1 gap-5">
                  <td className=" font-semibold flex items-center truncate gap-2 text-[11px] justify-center">
                    {header}
                  </td>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {resultSuger.map((row, rowIndex) => (
              <tr key={rowIndex} className=" text-center border-black ">
                {headers.map((header, columnIndex) => (
                  <td
                    key={columnIndex}
                    className="px-2 text-[11px]  whitespace-nowrap"
                  >
                    {row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </table>
    </div>
  );
};

export default SugarLevelPrint;
