import React, { useState } from "react";
import { useEffect } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { oxyventiPrintData } from "../services/cliinicalChartServices/oxygenvantilationServices/OxygenVantilationServices";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";

export default function OxyVentiPrint(props) {
  const printRef = useRef(null);

  const [patientInfo, setPatientInfo] = useState({});
  const [result, setResult] = useState([]);
  const [patientInfoDate, setPatientInfoDate] = useState({});

  //Oxyventi
  const [dataVentilatorChart, setDataVentilatorChart] = React.useState();

  useEffect(() => {
    //OxyVentilation
    const postObj = {
      admissionId: props.admissionId,
      fromDate: props.fromDate,
      toDate: props.toDate,
      fromTime: props.fromTime,
      toTime: props.toTime,
    };

    oxyventiPrintData(postObj)
      .then((response) => response.data.result)
      .then((res) => {
        if (res.ventilationOxygenList.length === 0) {
          warningAlert("Please Select Valid Date And Time..!");
        } else {
          setDataVentilatorChart(res);

          const obj = {
            "Mr No": res.MRNo,
            "Patient Name": res.PatientName,
            "Doctor Name": res.DoctorName,
            "Admission Date": res.AdmissionDate,
          };
          setPatientInfo(obj);
          const patientInfoDate = {
            "From Date": res.FromDate,
            "To Date": res.ToDate,
          };
          setPatientInfoDate(patientInfoDate);
          const patientObj = res.ventilationOxygenList.map((data) => {
            return {
              Date: data.Date,
              Time: data.Time,
              Mode: data.Mode,
              tidolv: data.tidolv || 0,
              minutv: data.minutv || 0,
              peep: data.peep || 0,
              prsup: data.prsup || 0,
              setrate: data.setrate || 0,
              "Rate total": data?.["Rate total"] || 0,
              pc: data?.fio2 || 0,
              fio2: data?.pc || 0,
              ipap: data?.ipap || 0,
              epap: data?.epap || 0,
              mv: data?.ie || 0,
              ie: data?.mv || 0,
              "Flow Trigger": data?.["Flow Trigger"] || 0,
            };
          });

          setResult(patientObj);
        }
      });
  }, [props]);

  useEffect(() => {
    dataVentilatorChart && printRef && GeneratePrint(printRef);
  }, [dataVentilatorChart]);

  const headers = [
    "Date",
    "Time",
    "Mode",
    "tidolv",
    "minutv",
    "peep",
    "prsup",
    "setrate",
    "Rate total",
    "pc",
    "fio2",
    "ipap",
    "epap",
    "mv",
    "ie",
    "Flow Trigger",
  ];

  return (
    <div ref={printRef}>
      <div>
        <div className="mt-2 ">
          <table className="w-full ">
            <thead
              style={{
                "-fs-table-paginate": "paginate",
              }}
            >
              <tr>
                <th colSpan={headers?.length + 1}>
                  <div className="flex justify-between gap-5 w-full">
                    <div className="space-y-2  text-left justify-start font-normal">
                      {Object.entries(patientInfo).map(([key, value]) => (
                        <div
                          key={key}
                          className="flex items-center text-[11px]"
                        >
                          <p className=" font-semibold  w-[100px]">{key}</p>
                          <p className="font-semibold mx-2">:</p>
                          <p className=" text-[11px] whitespace-nowrap">
                            {value}
                          </p>
                        </div>
                      ))}
                    </div>

                    <div className=" justify-end  flex ">
                      <div className="justify-end">
                        <div className="ml-20">
                          <InamdarHospiLogo />
                        </div>
                        <div className="flex items-end gap-2 pt-2">
                          {Object.entries(patientInfoDate).map(
                            ([key, value]) => (
                              <div key={key} className="flex items-center">
                                <p className="text-[11px] font-semibold w-30">
                                  {key}
                                </p>
                                <p className=" text-[11px] mx-2">:</p>
                                <p className="text-[11px] whitespace-nowrap">
                                  {value}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full font-semibold text-center border-t-[1px] border-black mt-1 text-[18px]  whitespace-nowrap">
                    Ventilator Chart
                  </div>
                </th>
              </tr>

              <tr className="border-b-[1px]   border-black  border-t-[1px] w-full ">
                {headers?.map((header, index) => (
                  <th key={index} className="text-center py-1 gap-5">
                    <td className=" font-semibold flex items-center truncate gap-2 text-[11px] justify-center">
                      {header}
                    </td>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="w-full">
              {result?.map((row, rowIndex) => (
                <tr key={rowIndex} className=" text-center border-black ">
                  {headers.map((header, columnIndex) => (
                    <td
                      key={columnIndex}
                      className="px-2   text-[11px] whitespace-nowrap"
                    >
                      {row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
