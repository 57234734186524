import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import SearchIconButton from "../../../../common/components/Buttons/SearchIconButton";
import { fetchAllExpiredItemReturn } from "../../../services/expiredItemReturn/ExpiredItemReturnServices";
import ExpiredItemReturnTable from "./ExpiredItemReturnTable";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

const actions = ["Checkbox"];

const ExpiredItemsReturnModal = (props) => {
  //
  // useform destructuring
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .nullable()
      .shape({ label: yup.string(), value: yup.string() }),
  });
  const defaultValues = { supplierName: null, totalAmount: 0, netAmt: 0 };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    register,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  // state variables

  const [childData, setChildData] = React.useState([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  // other variables
  // useEffects
  useEffect(() => {}, [childData]);

  // functions
  function fetchAllExpiredItemReturnList() {
    let postObj = {
      fromDate: selectedFromDate,
      isConsignment: true,
      storeId: props.storeId,
      size: rowsPerPage,
      page: 0,
      supplierId: props.supplierId,
      toDate: selectedToDate,
    };

    fetchAllExpiredItemReturn(postObj)
      .then((response) => response.data)
      .then((res) => {
        props.setExpiredItemList(res);
      })
      .catch((error) => {
        // errorAlert(error.message);
      });
  }

  function passDataToParent() {
    props.setParentData(childData);
    props.handleClose();
  }
  return (
    <>
      <Modal open={props.openModal}>
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
            }}
          />
          <div className="space-y-2 my-10">
            <div className="lg:grid lg:grid-cols-4  gap-2">
              <div className="w-full">
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disableFuture
                        label="From Date"
                        value={selectedFromDate}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setSelectedFromDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            name="fromDateCalender"
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "#0B83A5",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  name="fromDateCalender"
                />
              </div>
              <div className="w-full">
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disableFuture
                        label="To Date"
                        value={selectedToDate}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setSelectedToDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            name="toDateCalender"
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "#0B83A5",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  name="toDateCalender"
                />
              </div>
              <div>
                <SearchIconButton onClick={fetchAllExpiredItemReturnList} />
              </div>
            </div>
            <div>
              <div className="text-lg">Expired Item List</div>

              {props.expiredItem && props.expiredItem?.result?.length > 0 ? (
                <>
                  <ExpiredItemReturnTable
                    data={props.expiredItem}
                    actions={actions}
                    childData={childData}
                    setChildData={setChildData}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                    setPage={setPage}
                  />

                  <div className="flex justify-end gap-2 pt-2">
                    <button
                      onClick={() => {
                        passDataToParent();
                      }}
                      className="h-9 w-max text-white bg-[#133e87] px-3 py-2 rounded"
                    >
                      OK
                    </button>
                  </div>
                </>
              ) : (
                <div className="py-20 text-center">
                  <span> No Record Found...</span>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ExpiredItemsReturnModal;
