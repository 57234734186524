import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  changeDailyTipsStatus,
  fetchListOfDailyTips,
  fetchListOfDailyTipsSearch,
} from "../../services/ScreenSaverServices/ScreenSaverServices";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import AddDailyTipsModal from "./AddDailyTipsModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { EditOnIcon, EyeOnIcon } from "../../../assets/icons/CustomIcons";
import ViewImageModal from "./ViewImageModal";
import { baseUrl } from "../../../http-common";

const ScreenSaver = (props) => {
  let location = useLocation();

  const token = JSON.parse(localStorage.getItem("userInfo"));
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [formData, setFormData] = useState(null);

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  //
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);

  //
  const [userActions, setUserActions] = useState([
    {
      id: 1,
      action: "Create",
      isAction: false,
      menuId: null,
      menuKey: null,
    },
  ]);
  const [privilege, setPrivilege] = useState("");
  ///
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      listSearch: "",
    },
  });
  // modal
  const [openViewImageModal, setOpenViewImageModal] = useState(false);
  const [openAddDailyTipsModal, setOpenAddDailyTipsModal] = useState(false);
  const handleOpenAddDailyTipsModal = () => setOpenAddDailyTipsModal(true);
  const handleCloseAddDailyTipsModal = () => setOpenAddDailyTipsModal(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  //

  const { control, register } = methods;

  ///get User Action
  //   useEffect(() => {
  //     location?.state?.menuId &&
  //       fetchListOfDailyTipsSearch()
  //         .then((response) => response.data)
  //         .then((res) => {
  //           setUserActions(res.result);
  //         });
  //   }, []);

  const getDailyTipsSearchAutoComplete = (searchString) => {
    searchString &&
      fetchListOfDailyTipsSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  //
  useEffect(() => {
    populateTable();
  }, [selectedRecord]);

  const populateTable = (forPagination) => {
    const listObj = {
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      searchId: selectedRecord?.id || null,
    };

    fetchListOfDailyTips(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      });
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);

    setOpenBackdrop(true);
    const changeStatusObj = {
      dailyTipsId: selectedRow.Id,
      menuId: 0,
      privilege: "string",
      status: !selectedRow.Status,
    };

    changeDailyTipsStatus(changeStatusObj)
      .then((response) => response.data)
      .then((res) => {
        setAutocompleteData(res.result);
        setOpenBackdrop(false);
        populateTable();
      });
  };
  ///table related

  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {/* {userActions.map((actions) => ( */}
          <>
            {/* {actions.isAction ? ( */}
            <>
              {/* <div className="text-customGray">
                    <Tooltip title="Print">
                      <LocalPrintshopIcon onClick={() => {}} />
                    </Tooltip>
                  </div> */}

              <div className="flex cursor-pointer items-center">
                {
                  // actions.action === "Create" &&
                  <EditOnIcon
                    title="Edit"
                    onClick={() => {
                      setSelectedRow(row);
                      // setPrivilege(actions.action);
                      handleOpenAddDailyTipsModal();
                    }}
                  />
                }
              </div>
            </>
            {/* ) : null} */}
          </>
          {/* ))} */}
        </div>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    const handleStatusChange = (row, index) => {
      setOpenConfirmationModal(true);
      setSelectedRow(row);
    };
    //
    const handleViewImage = (row) => {
      let url = `${baseUrl}/file/${row?.ImagePath}`;
      setPreviewUrl(`${baseUrl}/file/${row?.ImagePath}`);
      setOpenViewImageModal(true);
    };
    return (
      <>
        {header === "Status" ? (
          row[header] === true ? (
            <button
              onClick={() => handleStatusChange(row)}
              className="border border-green-500 text-center rounded px-5 text-green-500"
            >
              Active
            </button>
          ) : (
            <button
              onClick={() => handleStatusChange(row)}
              className="border border-red-500 text-center rounded px-3 text-red-500"
            >
              InActive
            </button>
          )
        ) : (
          ""
        )}
        {header === "ImagePath" && (
          <EyeOnIcon
            title={"View"}
            onClick={() => {
              handleViewImage(row);
              setSelectedRow(row);
            }}
          />
        )}
      </>
    );
  };

  return (
    <div className="">
      <div className="text-center text-black font-bold text-xl col-span-9 mb-2 mt-14">
        Daily Tips
      </div>
      <div className="grid grid-cols-6">
        <div className="md:col-span-2 lg:col-span-2 mb-3">
          <SearchDropdown
            control={control}
            placeholder="Search Daily Tips"
            dataArray={autocompleteData}
            name="listSearch"
            searchIcon={true}
            isClearable={true}
            handleInputChange={getDailyTipsSearchAutoComplete}
            inputRef={{
              ...register("listSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedRecord(e.target.value);
                  } else {
                    setSelectedRecord("");
                    methods.reset();
                  }
                },
              }),
            }}
          />
        </div>
        <div className="col-span-4 grid justify-end">
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <div>
                    <CommonButton
                      label="Add Daily Tips"
                      className="bg-customBlue text-white"
                      onClick={() => {
                        handleOpenAddDailyTipsModal();
                        setSelectedRow(null);
                      }}
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </div>
      <div>
        <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
          List Of Daily Tips
        </p>
        {dataResult.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            populateTable={populateTable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            count={count}
            tableClass="2xl:h-[550px]"
            removeHeaders={[
              "Id",
              "fromDatePatch",
              "todatePatch",
              "IsApproved",
              "Code",
            ]}
            renderActions={renderActions}
            renderInput={renderInput}
            editableColumns={["Status", "ImagePath"]}
            highlightRow={false}
          />
        ) : (
          <p className="text-center my-6 ">No Data Found</p>
        )}
      </div>

      {openAddDailyTipsModal ? (
        <AddDailyTipsModal
          open={openAddDailyTipsModal}
          setOpen={setOpenAddDailyTipsModal}
          handleOpen={handleOpenAddDailyTipsModal}
          handleClose={handleCloseAddDailyTipsModal}
          selectedRow={selectedRow}
          populateTable={populateTable}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}

      {openViewImageModal ? (
        <ViewImageModal
          open={openViewImageModal}
          setOpen={setOpenViewImageModal}
          handleOpen={() => setOpenViewImageModal(true)}
          handleClose={() => setOpenViewImageModal(false)}
          ImagePath={previewUrl}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg={
          selectedRow?.Status === true
            ? "Do You Want To De-Activate ?"
            : "Do You Want To Re-Activate ?"
        }
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default ScreenSaver;
