import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import BedAllocation from "../bedallocation/BedAllocation";
import { Style } from "./Style";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import DropdownField from "../../../common/components/FormFields/DropdownField";

import { useState } from "react";
import {
  fetchBedCategory,
  fetchFloor,
  fetchUnit,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchBedList,
  fetchBedlistIcon,
  fetchWard,
} from "../../services/bedAllowcationServices/BedAllowcationServices";
import RadioField from "../../../common/components/FormFields/RadioField";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { createContext } from "react";

import { routeAdded } from "../../../authentication/slices/auth";
import { useDispatch } from "react-redux";
import BedReservationListModal from "./BedReservationListModal";

const ShowBeds = [
  {
    id: 0,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Available",
    label: "Available",
  },
  {
    id: 2,
    value: "NotAvailable",
    label: "NotAvailable",
  },
];

export const BedAllocationModalContext = createContext("");

function BedModal(props) {
  const { handleCloseBed } = props;
  const schema = yup.object().shape({
    bedCategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Bed Category"),
        label: yup.string().required("Please Select Bed Category"),
      }),
    ward: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Ward"),
        label: yup.string().required("Please Select Ward"),
      }),
    floor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Floor"),
        label: yup.string().required("Please Select Floor"),
      }),
    unit: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Unit"),
        label: yup.string().required("Please Select Unit"),
      }),
  });

  let token = JSON.parse(localStorage.getItem("userInfo"));

  const defaultValues = {
    id: "",
    bedCategory: null,
    bedType: 0,
    ward: null,
    floor:
      (token.floorId !== null &&
        token.floorId > 1 &&
        token.floor && {
          id: token.floorId,
          label: token.floor,
          value: token.floor,
        }) ||
      null,
    unit: { id: 2, label: "IMH(FATIMA NAGAR)", value: "IMH(FATIMA NAGAR)" },
  };
  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const { control, register, reset, watch } = useForm({
    // use mode to specify the event that triggers each input field
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [checkedLabel, setCheckedLabel] = React.useState("all");

  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState();
  const [menuId, setMenuId] = useState();

  // STATES FOR BED MODAL APIS
  const [units, setUnits] = useState();
  const [unitId, setUnitId] = useState(2);
  const [floors, setFloors] = useState();
  const [floorId, setFloorId] = useState(token.floorId || null);
  const [wards, setWards] = useState();
  const [wardId, setWardId] = useState(null);
  const [bedCategoryList, setBedCategoryList] = useState();
  const [bedCategoryListId, setBedCategoryListId] = useState(null);
  const [bedListData, setBedListData] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [spinner, setSpinner] = React.useState(false);
  //
  const [
    openBedReservationListModal,
    setOpenBedReservationListModal,
  ] = useState(false);

  const handleOpenBedReservationListModal = () =>
    setOpenBedReservationListModal(true);
  const handleCloseBedReservationListModal = () =>
    setOpenBedReservationListModal(false);
  //
  const BedsByType = watch("bedType");

  //toggle button

  // API INTEGRATION FOR BED MODAL//
  // 1. Unit List API
  useEffect(() => {
    fetchUnit()
      .then((response) => {
        setUnits(response.data.result);
      })
      .catch((response) => {});
  }, []);

  // // 3. Floor List API
  useEffect(() => {
    // if (unitId !== null && blockId !== null) {
    fetchFloor()
      .then((response) => {
        setFloors(response.data.result);
      })
      .catch((response) => {});
    // }
  }, [unitId]);

  // 4. Ward List API
  useEffect(() => {
    if (floorId !== null) {
      fetchWard(floorId)
        .then((response) => {
          setWards(response.data.result);
        })
        .catch((response) => {});
    }
  }, [floorId]);

  // 4. Ward List API
  useEffect(() => {
    fetchBedCategory()
      .then((response) => {
        setBedCategoryList(response.data.result);
      })
      .catch((response) => {});
  }, []);

  //6. Infinite Scroll
  const getBeds = (e) => {
    var bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 20;
    if (bottom) {
      let pg = pageNo + 1;
      setPageNo(pg);
    }
  };

  ///get beds data
  const getBedListData = () => {
    let postedObj = {
      bedCategoryId: bedCategoryListId !== null ? bedCategoryListId : 0,
      bedStatus: BedsByType,

      floorId: floorId !== null ? floorId : 0,
      unitId: unitId !== null ? unitId : 0,
      wardId: wardId !== null ? wardId : 0,
    };

    fetchBedList(postedObj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          ///
          setBedListData(response.data.result);
          fetchBedlistIcon(response.data.result); //for Authorization
          const ShowSpinner = setTimeout(() => {
            setSpinner(false);
          }, 1000);
          return () => clearTimeout(ShowSpinner);
          ///
        } else {
          setBedListData([]);
        }
      })
      .catch((res) => {
        setSpinner(false);
      });
  };
  //

  useEffect(() => {
    setSpinner(true);
    getBedListData();
    // postedObj

    //
  }, [unitId, floorId, wardId, bedCategoryListId, checkedLabel, BedsByType]);

  ///get User Action
  useEffect(() => {
    token?.bedAllocationId &&
      fetchUserActionsByMenuId(token?.bedAllocationId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
          handleRouteAddition(res.result);
        });
  }, []);

  const dispatch = useDispatch();
  const handleRouteAddition = (actions) => {
    actions.map((item) => {
      if (item.menuKey) {
        dispatch(routeAdded(item.menuKey));
      }
    });
  };

  return (
    <div className="bg-white ">
      <Modal
        open={props.open}
        // onClose={props.handleCloseBed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={Style}
          className=" w-[95%] h-[80%] xl:h-[90%]"
          onScroll={getBeds}
        >
          <div className="sticky top-0 z-50 bg-white  w-full ">
            <div className=" w-full flex justify-end">
              <CancelPresentationIcon
                className=" text-red-600  rounded cursor-pointer"
                onClick={() => {
                  props.handleCloseBed();
                  reset(defaultValues);
                  setUnitId(2);
                  setFloorId(null);
                  setWardId(null);
                  setBedCategoryListId(null);
                  setCheckedLabel();
                }}
              />
            </div>
            <div className="z-50 bg-white mt-2 w-full h-auto sticky top-0">
              <form
              // onSubmit={handleSubmit(onSubmitDataHandler)}
              >
                <div className=" grid md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-9 gap-2 px-6 my-2 border-b-2 pb-4 items-center ">
                  {/* //Unit// */}
                  <div className=" w-full md:col-span-1 lg:col-span-2">
                    <DropdownField
                      control={control}
                      isSearchable={false}
                      name="unit"
                      placeholder="Unit"
                      dataArray={units}
                      inputRef={{
                        ...register("unit", {
                          onChange: (e) => {
                            setUnitId(e.target.value.id);
                          },
                        }),
                      }}
                    />
                  </div>

                  {/* //Floor// */}
                  <div className=" w-full">
                    <DropdownField
                      control={control}
                      isSearchable={false}
                      name="floor"
                      placeholder="Floor"
                      dataArray={floors}
                      inputRef={{
                        ...register("floor", {
                          onChange: (e) => {
                            setFloorId(e.target.value.id);
                          },
                        }),
                      }}
                    />
                  </div>
                  {/* //Ward// */}
                  <div className=" w-full">
                    <DropdownField
                      control={control}
                      isSearchable={false}
                      name="ward"
                      placeholder="Ward"
                      dataArray={wards}
                      inputRef={{
                        ...register("ward", {
                          onChange: (e) => {
                            setWardId(e.target.value.id);
                          },
                        }),
                      }}
                    />
                  </div>
                  {/* //Bed Category// */}
                  <div className=" w-full ">
                    <DropdownField
                      control={control}
                      isSearchable={false}
                      name="bedCategory"
                      placeholder="Bed Category"
                      dataArray={bedCategoryList}
                      inputRef={{
                        ...register("bedCategory", {
                          onChange: (e) => {
                            setBedCategoryListId(e.target.value.id);
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="ml-3 col-span-4 grid grid-cols-3 items-center">
                    <div className="col-span-2">
                      <RadioField
                        label=""
                        name="bedType"
                        control={control}
                        dataArray={ShowBeds}
                      />
                    </div>
                    <div className="flex gap-2">
                      <div className=" ">
                        <CommonButton
                          label="Reset"
                          onClick={() => {
                            // getBedListData(defaultObj);
                            reset(defaultValues);
                            setUnitId(2);
                            setFloorId(null);
                            setWardId(null);
                            setBedCategoryListId(null);
                            setCheckedLabel();
                          }}
                          className="border border-customRed text-customRed"
                        />
                      </div>
                      <div>
                        {userActions.map((obj) => (
                          <>
                            {!obj.isAction &&
                            obj.action === "Reserved Bed List" ? (
                              <>
                                <CommonButton
                                  label="Bed Booking List"
                                  onClick={() => {
                                    handleOpenBedReservationListModal();
                                  }}
                                  className="bg-customBlue text-white"
                                />
                              </>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "View" && (
                <> */}
          <div className="w-full">
            {spinner ? (
              <div className="grid justify-center">
                <LoadingSpinner />
              </div>
            ) : bedListData && bedListData.length > 0 ? (
              <BedAllocationModalContext.Provider
                value={{ userActions, handleCloseBed, getBedListData }}
              >
                <BedAllocation
                  bedListData={bedListData}
                  handleCloseBed={props.handleCloseBed}
                />
              </BedAllocationModalContext.Provider>
            ) : (
              <div className="text-gray-500 font-bold text-center ">
                <h1 className="text-base text-center">Bed Is Not Available</h1>
              </div>
            )}
          </div>
          {/* </>
              )}
            </>
          ))} */}
        </Box>
      </Modal>

      {openBedReservationListModal && (
        <BedReservationListModal
          open={openBedReservationListModal}
          setOpen={setOpenBedReservationListModal}
          handleOpen={handleOpenBedReservationListModal}
          handleClose={handleCloseBedReservationListModal}
          privilege={privilege}
          getBedListData={getBedListData}
        />
      )}
    </div>
  );
}

export default BedModal;
