import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import GcsCalculatorModal from "./GcsCalculatorModal";
import CommonButton from "../../../common/components/Buttons/CommonButton";

const MewsRadio = [
  {
    id: "0",
    value: "0",
    label: "A",
  },
  {
    id: "1",
    value: "1",
    label: "V",
  },
  {
    id: "2",
    value: "2",
    label: "P",
  },
  {
    id: "3",
    value: "3",
    label: "U",
  },
];

const GeneralExamination = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const { control, setValue, watch } = useFormContext();

  ///mewsRadio
  let Avpu = watch("mewsRadio");

  useEffect(() => {
    setValue("gcsScore", props.gcsScore, {
      shouldValidate: true,
    });

    setValue("mewsScore", props.mewsScore, {
      shouldValidate: true,
    });
  }, [props.gcsScore, props.mewsScore]);

  ///////////////
  const [temp, pulse, rr] = watch(["temp", "pulse", "respiration"]);

  // to remove slash while considering b/p
  let bpStr = watch("bp");
  let arr = !!bpStr && bpStr?.split("/");
  let bp = arr[0];
  // calculation for mews score calculation
  function SystolicBloodPressure(sbp) {
    if (sbp >= 200) {
      return 2;
    } else if (sbp >= 101 && sbp <= 199) {
      return 0;
    } else if (sbp >= 81 && sbp <= 100) {
      return 2;
    } else if (sbp >= 71 && sbp <= 80) {
      return 2;
    } else if (sbp <= 70) {
      return 3;
    }
  }
  function PulseCalculation(pulse) {
    if (pulse <= 40) {
      return 2;
    } else if (pulse >= 41 && pulse <= 50) {
      return 1;
    } else if (pulse >= 51 && pulse <= 100) {
      return 0;
    } else if (pulse >= 101 && pulse <= 110) {
      return 1;
    } else if (pulse >= 111 && pulse <= 129) {
      return 2;
    } else if (pulse >= 130) {
      return 3;
    }
  }
  function RespiratoryRate(bpm) {
    if (bpm <= 9) {
      return 2;
    } else if (bpm > 9 && bpm <= 14) {
      return 0;
    } else if (bpm >= 15 && bpm <= 20) {
      return 1;
    } else if (bpm >= 21 && bpm <= 29) {
      return 2;
    } else if (bpm >= 30) return 3;
  }

  function Temperature(fahrenheit) {
    if (fahrenheit < 95) {
      return 3;
    } else if (fahrenheit >= 95 && fahrenheit <= 97) {
      return 1;
    } else if (fahrenheit >= 97.1 && fahrenheit <= 100) {
      return 0;
    } else if (fahrenheit >= 100.1 && fahrenheit <= 101.3) {
      return 1;
    } else if (fahrenheit > 101.3) {
      return 2;
    }
  }

  const calculateMews = (bp, temp, pulse, rr) => {
    var mewsVal;

    if (
      Number(bp) > 0 &&
      Number(temp) > 0 &&
      Number(pulse) > 0 &&
      Number(rr) > 0
    ) {
      let bpValue = SystolicBloodPressure(bp);

      let pulseValue = PulseCalculation(pulse);

      let tempValue = Temperature(temp);

      let rrValue = RespiratoryRate(rr);

      mewsVal =
        Number(bpValue) +
        Number(pulseValue) +
        Number(tempValue) +
        Number(rrValue);
    }

    return mewsVal;
  };

  let finalMews;
  useEffect(() => {
    finalMews = calculateMews(bp, temp, pulse, rr);

    let mewsWithAvpu = Number(finalMews) + Number(Avpu);

    // if (mewsWithAvpu >= 5) {
    //   setValue("mewsAlert", "Please Call Doctor For Consultation");
    // } else if (mewsWithAvpu < 5) {
    //   setValue("mewsAlert", "No Need to Call Doctor For Consultation");
    // }

    props.setMewsScore(Number(mewsWithAvpu));
  }, [bp, temp, pulse, rr, Avpu]);

  return (
    <div>
      <div className="grid lg:grid-cols-5 md:grid-cols-4 gap-3">
        {/* Temp */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Temp</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="temp"
                variant="outlined"
                label="°F"
                placeholder="°F"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Pulse rate */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Pulse Rate</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="pulse"
                variant="outlined"
                label="Pulse Rate/min"
                placeholder="Pulse Rate/min"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* B/P */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">B/P</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                // type="number"
                name="bp"
                variant="outlined"
                label="mmHg"
                placeholder="mmHg"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* SpO2 */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">SpO2</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="spo2"
                variant="outlined"
                label="%"
                placeholder="%"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Weight */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Weight</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="weight"
                variant="outlined"
                label="kg"
                placeholder="kg"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Height */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Height</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="height"
                variant="outlined"
                label="cm"
                placeholder="cm"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Respiration */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Respiration</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="respiration"
                variant="outlined"
                label=" Respiration/min"
                placeholder=" Respiration/min"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* BSL */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">BSL</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="bsl"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Saturation With O2 */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Saturation With O2</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="saturationWithO2"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Saturation Without O2 */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Saturation Without O2</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="saturationWithoutO2"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
        {/* Oxygen Rate Per Minute */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">Oxygen Rate Per Minute</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="oxyRatePerMin"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>

        {/* pews */}
        {props.patientData?.Age > 14 ? null : (
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
            <span className="  w-28">Pews</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal w-24">
                <InputField
                  type="number"
                  name="pews"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>
        )}

        {/* gcs */}
        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1  text-sm py-1">
          <span className="  w-28">GCS</span>
          <div className="flex space-x-2 items-center">
            <span>:</span>
            <span className="text-gray-700 font-normal w-24">
              <InputField
                type="number"
                name="gcsScore"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </span>
            <span className="text-gray-700 font-normal w-24">
              {/* <button
                type="button"
                className="h-9 px-3 text-base font-medium  bg-blue-900 text-white rounded "
                onClick={handleOpenModal}
              >
                Calc
              </button> */}
              <CommonButton
                label="Calc"
                className="bg-customBlue text-white"
                onClick={handleOpenModal}
              />
            </span>
          </div>
        </div>
        {/* mews */}
        <div className="flex items-center gap-2 w-full md:col-span-4 lg:col-span-2   text-sm py-1">
          <span className="  w-28">Mews *</span>
          <div className="flex  items-center">
            <span>:</span>
            <span className="w-56">
              <RadioField
                label=""
                name="mewsRadio"
                control={control}
                dataArray={MewsRadio}
              />
            </span>
            <span className="text-gray-700 font-normal  w-24">
              <InputField
                type="number"
                name="mewsScore"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-8 md:grid-cols-4 items-center">
        <div>
          <CheckBoxField
            control={control}
            name="peripheral"
            label="Peripheral"
          />
        </div>
        <div>
          <CheckBoxField control={control} name="central" label="Central" />
        </div>
        <div>
          <CheckBoxField control={control} name="rt" label="RT" />
        </div>
        <div>
          <CheckBoxField control={control} name="foleys" label="Foleys" />
        </div>
        <div>
          <CheckBoxField control={control} name="otherLines" label="Other" />
        </div>
        <div className="col-span-3 flex gap-2 items-center">
          {props.mewsScore !== undefined && props.mewsScore > 0 ? (
            props.mewsScore >= 5 ? (
              <>
                <p> Alert :</p>
                <p className="text-red-600">
                  Please Call Doctor For Consultation
                </p>
              </>
            ) : (
              <>
                <p> Alert :</p>
                <p className="text-green-600">
                  No Need to Call Doctor For Consultation
                </p>
              </>
            )
          ) : null}
        </div>
      </div>
      <div className="grid lg:grid-cols-8 md:grid-cols-4 items-center">
        <p className="font-semibold tracking-wide text-sm">Covid 19 vaccine</p>
        <div>
          <CheckBoxField
            control={control}
            name="firstDose"
            label="First Dose"
          />
        </div>
        <div>
          <CheckBoxField
            control={control}
            name="secondDose"
            label="Second Dose"
          />
        </div>
        <div>
          <CheckBoxField
            control={control}
            name="boosterDose"
            label="Booster Dose"
          />
        </div>
        <div>
          <CheckBoxField
            control={control}
            name="isOnBloodThinner"
            label="Patient Is On Blood Thinner"
          />
        </div>
      </div>

      {/* Gcs Calculator Modal */}
      {openModal ? (
        <GcsCalculatorModal
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          setGcsScore={props.setGcsScore}
          poulateData={props.poulateData}
          setGcsEVM={props.setGcsEVM}
        />
      ) : null}
    </div>
  );
};

export default GeneralExamination;
