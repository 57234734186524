import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";

///key press action
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function ItemDetailsTable(props) {
  const {
    setSelectedDrugData,
    FocusBatchModal,
    FocusDrugTable,
    selectedItemDtlsID,
    setSelectedItemDtlsID,
  } = props;

  const { register, watch, setValue } = useForm({
    mode: "onChange",
  });
  //state varibale for the table
  const [indexVal, setIndexVal] = useState(null);
  //for error input
  let arrLength = props.data.length;

  // useEffect(() => {
  //   setSelectedDrugData(props.data);
  // }, [props.data.length]);

  //
  const FocusQuantityInput = useRef(null);

  ///keypress fuctionality code
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");

  //up
  useEffect(() => {
    if (arrowUpPressed) {
      if (
        FocusDrugTable.current === undefined ||
        FocusDrugTable.current === null
      ) {
        if (selectedItemDtlsID <= 0) {
          setSelectedItemDtlsID(arrLength - 1);
        } else if (selectedItemDtlsID !== 0) {
          setSelectedItemDtlsID(selectedItemDtlsID - 1);
        }
      }
    }
  }, [arrowUpPressed, FocusBatchModal]);

  //down
  useEffect(() => {
    if (arrowDownPressed) {
      if (
        FocusDrugTable.current === undefined ||
        FocusDrugTable.current === null
      ) {
        if (selectedItemDtlsID >= arrLength - 1) {
          setSelectedItemDtlsID(0);
        } else if (selectedItemDtlsID !== arrLength) {
          setSelectedItemDtlsID(selectedItemDtlsID + 1);
        }
      }
    }
  }, [arrowDownPressed, FocusBatchModal]);
  const handleTableInputChange = (e, row, index) => {
    let temp = [...props.data];
    const selectedItemRow = temp[index];
    selectedItemRow.Id = row.Id;
    selectedItemRow.Code = row.Code;
    selectedItemRow["Item Name"] = row["Item Name"] || row.Drug;
    selectedItemRow.BatchesRequired = row.BatchesRequired;
    selectedItemRow["Balance Qty"] = row["Balance Qty"] || row.BalanceQty;
    selectedItemRow.ItemCategory = row.ItemCategory;
    selectedItemRow.ItemForm = row.ItemForm;
    selectedItemRow.VATInClusive = row.VATInClusive;
    selectedItemRow.CompanyName = row.CompanyName;
    selectedItemRow.VATPercentage = row.VATPercentage;
    selectedItemRow.ConcessionApp = row.ConcessionApp;
    selectedItemRow.FreeApplicable = row.FreeApplicable;
    selectedItemRow.IsConsignment = row.IsConsignment;
    selectedItemRow.Onco = row.Onco;
    selectedItemRow.OncoDist = row.OncoDist;
    selectedItemRow.ItemType = row.ItemType;
    selectedItemRow.MRP = row.MRP;
    selectedItemRow.PurchaseRate = row.PurchaseRate;
    selectedItemRow.Conversion = row.Conversion;
    selectedItemRow.ItemGenericId = row.ItemGenericId;
    selectedItemRow.rackid = row.rackid;
    selectedItemRow.shelfid = row.shelfid;
    selectedItemRow.Rack = row.Rack;
    selectedItemRow.Shelf = row.Shelf;
    selectedItemRow.concessionper = row.concessionper;
    selectedItemRow.Quantity = row.Quantity;
    selectedItemRow["Hospital Bal Qty"] = row["Hospital Bal Qty"];
    selectedItemRow.Amount = row.Amount;
    selectedItemRow["Indent Qty"] = e.target.value;
    selectedItemRow.color = "";
    let quantityVal = e.target.value;

    if (quantityVal) {
      selectedItemRow.color = "#0B83A5";
    }
    temp[index] = selectedItemRow;
    props.setData(temp);
  };

  // React.useEffect(() => {
  //   let temp = [...props.data];
  //   for (let selectedItemRow of temp) {
  //     if (selectedItemRow["Indent Qty"] > selectedItemRow["Balance Qty"]) {
  //       selectedItemRow.color = "#D11A2A";
  //       props.setIsValidQuantity(false);
  //     } else {
  //       selectedItemRow.color = "#0B83A5";
  //       props.setIsValidQuantity(true);
  //     }
  //   }
  // }, [props.data]);

  const deleteRow = (index) => {
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    props.setSelectedDrugData(newTemplateData);
  };

  React.useEffect(() => {
    for (let i = 0; i < props.data.length; i++) {
      setValue(`indentQuantity${i}`, props.data[i]["Indent Qty"]);
    }
  }, [props.data]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-80 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Action
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Hospital Balance
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Indent Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Current Balance
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data &&
                    props.data.map((row, index) => {
                      return (
                        <TableRow hover key={index}>
                          <TableCell>
                            <Tooltip title="Delete">
                              <div
                                className="text-red-500 mr-3"
                                onClick={() => {
                                  deleteRow(index);
                                  setIndexVal(index);
                                }}
                              >
                                {<DeleteOutlineOutlinedIcon />}
                              </div>
                            </Tooltip>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {row.Code}
                          </TableCell>

                          <TableCell className="whitespace-nowrap w-2">
                            {row["Item Name"] || row.Drug}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {row["Hospital Bal Qty"]}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            <span ref={FocusQuantityInput}>
                              <div>
                                <input
                                  className="border text-black border-gray-500 rounded w-24 px-2 "
                                  name={`indentQuantity${index}`}
                                  {...register(`indentQuantity${index}`)}
                                  onChange={(e) => {
                                    handleTableInputChange(e, row, index);
                                  }}
                                  onKeyPress={handleKeyPress}
                                  defaultValue={row["Indent Qty"]}
                                  type="number"
                                  style={{ borderColor: `${row.color}` }}
                                />
                              
                              </div>
                            </span>
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {row["Balance Qty"] || row.BalanceQty}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
