// import React from 'react'

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  CancelOnIcon,
  UnReserveBedIcon,
} from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { fetchBookingList } from "../../services/bedAllowcationServices/BedAllowcationServices";
import BedUnReserveModal from "./BedUnReserveModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "80%", lg: "50%" },
  // minWidth:"25%",
  // height: "30%",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function BedReservationListModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const { open, handleClose, privilege, getBedListData } = props;

  //

  ///related to table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = React.useState([]);
  //
  const [bedDetails, setBedDetails] = React.useState();
  //
  const [openBedUnReserveModal, setOpenBedUnReserveModal] = useState(false);

  const handleOpenBedUnReserveModal = () => setOpenBedUnReserveModal(true);
  const handleCloseBedUnReserveModal = () => setOpenBedUnReserveModal(false);
  //

  useEffect(() => {
    populateTable();
  }, []);

  const populateTable = (forPagination) => {
    fetchBookingList(page, rowsPerPage)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch(() => {});
  };

  const renderActions = (row) => {
    return (
      <>
        <div>
          <UnReserveBedIcon
            title={"UnReserve Bed"}
            onClick={() => {
              handleOpenBedUnReserveModal();
              setBedDetails(row);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                Bed Booking List
              </div>

              <hr className="border mx-1  border-blue-900" />
              <div>
                {dataResult?.length > 0 ? (
                  <CommonDynamicTablePaginationNew
                    dataResult={dataResult}
                    highlightRow={false}
                    renderActions={renderActions}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    tableClass="2xl:h-[650px]"
                    removeHeaders={["BookedBy", "Gender", "Unit"]}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>

              <div className="flex justify-end my-2 gap-3">
                <CommonButton
                  label="Close"
                  onClick={() => {
                    handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      {openBedUnReserveModal && (
        <BedUnReserveModal
          open={openBedUnReserveModal}
          setOpen={setOpenBedUnReserveModal}
          handleOpen={handleOpenBedUnReserveModal}
          handleClose={handleCloseBedUnReserveModal}
          // menuId={menuId}
          menuId={token?.bedAllocationId}
          privilege={privilege || "UnReserve Bed"}
          getBedListData={getBedListData}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
          populateTable={populateTable}
        />
      )}
    </div>
  );
}
