import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//
export const postPatientTransfer = (finalObj) => {
  return apiClient.post(`/patientTransfer/savePatientTransfer`, finalObj, {
    headers: authHeader(),
  });
};

//
export const fetchPatientTransferList = (listObj) => {
  return apiClient.post(`/patientTransfer/patientTransferList`, listObj, {
    headers: authHeader(),
  });
};

//
export const fetchbeds = (wardId, occupiedStatus) => {
  return apiClient.get(`/patientTransfer/getBeds/${wardId}/${occupiedStatus}`, {
    headers: authHeader(),
  });
};

//
export const fetchPatchData = (ptID) => {
  return apiClient.get(`/patientTransfer/patientTransferDetails/${ptID}`, {
    headers: authHeader(),
  });
};

//prints
export const fetchPrintPatientTransfer = (patientTransferId) => {
  return apiClient.get(`/reports/Ipd/patientTransfer/${patientTransferId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};
