import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import { fetchAllComparativData } from "../../../../services/purchase/QuotationServies";
const ComparartiveModal = (props) => {
  const defaultValues = {};

  const {
    control,
    reset,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    for (let i = 0; i < props.selectQuotationData.length; i++) {
      let qid = props.selectQuotationData[i].QId;

      fetchAllComparativData(qid)
        .then((response) => response.data)
        .then((res) => {
          //   setData(res);
          //   setDataResult(res.result);
          //   showSpinner(false);
        })
        .catch((error) => {
          //
          //   showSpinner(false);
          //   showRecordWarning(true);
        });
    }
  }, [props.selectQuotationData]);
  return (
    <>
      <div>
        <Modal
          open={props.openComparativeModal}
          onClose={() => {
            props.handleComparativeModalClose();
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "1px solid gray",
              borderRadius: 1,
              boxShadow: 20,
              p: 4,
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleComparativeModalClose();
                }}
              />
            </div>
            <form>
              <div className="my-4">
                <span className="grid justify-start text-center text-black font-bold text-xl my-auto ">
                  Comparative Settlement
                </span>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ComparartiveModal;
