import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

//diticianModule List API
export const diticianModuleList = async (obj) => {
  return await apiClient.post(`/diticianModule/diticianModuleList`, obj, {
    headers: authHeader(),
  });
};
// diticianModule/dietOrderViewList

export const dietOrderViewModule = async (obj) => {
  return await apiClient.post(`/diticianModule/dietOrderViewList`, obj, {
    headers: authHeader(),
  });
};

//fetchDitician
export const fetchDitician = () => {
  return apiClient.get(`/diticianModule/dietOptions`, {
    headers: authHeader(),
  });
};

//DietPlan Option
export const fetchDietPlan = () => {
  return apiClient.get(`/diticianModule/dietPlan`, {
    headers: authHeader(),
  });
};

//DietType Allergy
export const fetchAllergies = () => {
  return apiClient.get(`/diticianModule/getAllergy`, {
    headers: authHeader(),
  });
};

//DietType Option
export const fetchDietTypes = () => {
  return apiClient.get(`/diticianModule/dietTypes`, {
    headers: authHeader(),
  });
};

//Food Type Option
export const fetchFoodTypes = () => {
  return apiClient.get(`/diticianModule/foodTypes`, {
    headers: authHeader(),
  });
};
export const fetchDietServices = () => {
  return apiClient.get(`/diticianModule/getDietServices`, {
    headers: authHeader(),
  });
};

export const addNewDietOrder = async (menuId, privilege, createDietOrder) => {
  return await apiClient.post(
    `/diticianModule/saveDietOrder/${menuId}/${privilege}`,
    createDietOrder,
    {
      headers: authHeader(),
    }
  );
};

export const fetchPrinDietOrderOForm = (printObj) => {
  return apiClient.post(
    `/reports/nursing/dietOrder`,
    printObj,

    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

export const fetchBarcodeDietationInfo = (barcodeObj) => {
  return apiClient.post(
    `/barcode/dietetionInfo`,
    barcodeObj,

    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//Dietatian Modal Accept
export const acceptDietOrder = (acceptObj) => {
  return apiClient.post(`/diticianModule/acceptDietOrder`, acceptObj, {
    headers: authHeader(),
  });
};

export const approvedDietOrder = (dietOrderId, menuId, privilege) => {
  return apiClient.get(
    `/diticianModule/approvedDietOrder/${dietOrderId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const rejectDietOrder = (dietOrderId, menuId, privilege) => {
  return apiClient.get(
    `/diticianModule/rejectDietOrder/${dietOrderId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

// /api/

export const addChargesData = async (obj) => {
  return await apiClient.post(`/diticianModule/saveDietServices`, obj, {
    headers: authHeader(),
  });
};

export const updateDietitianModuleStatus = (dietChartId, menuId, privilege) => {
  return apiClient.get(
    `/diticianModule/updateDietitianModuleStatus/${dietChartId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};
