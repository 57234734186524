import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import React, { useEffect, useRef, useState } from "react";
import { fetchPhysioOrdersPrintData } from "../services/cliinicalChartServices/orderSheetServices/OrderSheetServices";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
// import { fetchPhysioOrdersPrintData } from "./Services";

const CheckUncheckIcon = ({ value }) => {
  return (
    <div className="">
      {Number(value) > 0 ? (
        <CheckBoxOutlinedIcon />
      ) : (
        <DisabledByDefaultOutlinedIcon />
      )}
    </div>
  );
};

const UncheckIcon = () => {
  return (
    <div className="">
      <DisabledByDefaultOutlinedIcon />
    </div>
  );
};

export default function OrderSheetPhysioPrint(props) {
  const printRef = useRef(null);
  const [printDataResult, setPrintDataResult] = useState();

  console.log(props);
  const handlePrintAllOrder = () => {
    let defaultParams = {
      searchId: props?.searchId,
      status: props?.status,
    };
    // fetchOrderSheetAllList(defaultParams)
    // .then((response) => {
    // return response.data;
    // })
    // .then((res) => {
    // setPrintDataResult([...res.result]);
    // props.setOpenBackdrop(false);
    // })
    // .catch((res) => props.setOpenBackdrop(false));
  };

  useEffect(() => {
    fetchPhysioOrdersPrintData(
      props?.searchId,
      !props?.isPrintAll ? props?.orderSheetDtlsId : 0
    )
      .then((response) => response.data)
      .then((res) => {
        setPrintDataResult(res.result);
        props.setOpenBackdrop(false);
      })
      .catch((res) => props.setOpenBackdrop(false));
    // props?.isPrintAll && handlePrintAllOrder();
  }, [props]);

  useEffect(() => {
    printDataResult && printRef && GeneratePrint(printRef);
  }, [printDataResult]);
  console.log(props?.ptInfo);

  return (
    <div ref={printRef} className="ml-14">
      <div className=" text-xs ">
        <div className="grid grid-cols-6 gap-3">
          <div className="col-span-6 ">
            {!!printDataResult &&
              printDataResult.length > 0 &&
              printDataResult?.map((item) => {
                return (
                  <>
                    <div className="col-span-6 border-b-[1px] border-black flex justify-end">
                      <InamdarHospiLogo />
                    </div>

                    <div className="col-span-6 border-b-[1px] p-[1px] border-black grid grid-cols-[15%_4%_31%_15%_4%_31%]">
                      <div className=" font-semibold">MRNo</div>
                      <div className=" font-semibold">:</div>
                      <div className="">{props?.ptInfo?.MRNo}</div>

                      <div className=" font-semibold">DOA</div>
                      <div className=" font-semibold">:</div>
                      <div className="">{item.DOA}</div>

                      <div className=" font-semibold">Patient Name</div>
                      <div className=" font-semibold">:</div>
                      <div className="">
                        {props?.ptInfo?.PatientName ||
                          props?.ptInfo?.["Patient Name"]}
                      </div>

                      <div className=" font-semibold">Age</div>
                      <div className=" font-semibold">:</div>
                      <div className="">{props?.ptInfo?.AgeYear}</div>

                      <div className=" font-semibold">Consultant Name</div>
                      <div className=" font-semibold">:</div>
                      <div className="">{props?.ptInfo?.DoctorName}</div>

                      <div className=" font-semibold">Bed No.</div>
                      <div className=" font-semibold">:</div>
                      <div className="">
                        {props?.ptInfo?.BedNo || props?.ptInfo?.["Bed No"]}
                      </div>
                    </div>
                    <div className="grid gap-3 ">
                      <div className="font-semibold text-sm underline underline-offset-4">
                        Chest Physiotherapy :
                      </div>

                      <div className="grid grid-cols-2 gap-x-16 ml-5">
                        <div className="flex justify-between">
                          <div>Deep Breathing Exercise</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsDeepBreath} />
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>Pursed Lip Breathing</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsPursedLip} />
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>Thoracic Expansion Exercise</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsThoracExpan} />
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <div>Diaphragmatic Breathing</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsDiaphBreath} />
                          </div>
                        </div>

                        <div className="flex justify-between ">
                          <div>Chest Percussions and Vibrations</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsChestPercus} />
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>Spirometer</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsSpirometer} />
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                    <div className="col-span-6 flex gap-x-10 my-3">
                      <div className="font-semibold text-sm underline underline-offset-4">
                        Limb Physiotherapy :
                      </div>
                      <div className="flex justify-between gap-3 items-center">
                        <div>Upper Limb</div>
                        <div>
                          <CheckUncheckIcon value={item?.IsUpperLimb} />
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>Lower Limb</div>
                        <div>
                          <CheckUncheckIcon value={item?.IsLowerLimb} />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 flex gap-x-5 my-3 ">
                      {item?.IsLowerLimb > 0 ? (
                        <table class="border border-black">
                          <thead class=" ">
                            <tr className="">
                              <th class="px-3 text-center "></th>
                              <th class="px-3 text-center "></th>
                              <th class="px-3 text-center ">Right Side</th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-center ">Left Side</th>
                            </tr>
                            <tr className="">
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-center border border-black">
                                Active
                              </th>
                              <th class="px-3 text-center border border-black">
                                Active Assisted
                              </th>
                              <th class="px-3 text-center border border-black">
                                Passive
                              </th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-center border border-black">
                                Active
                              </th>
                              <th class="px-3 text-center border border-black">
                                Active Assisted
                              </th>
                              <th class="px-3 text-center border border-black">
                                Passive
                              </th>
                            </tr>
                          </thead>
                          <tbody class="divide-y divide-black">
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                Hip Flexion/Extension
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexPassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                Knee Flexion / Extension
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsKneeFlexActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsKneeFlexActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsKneeFlexPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsKneeFlexActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsKneeFlexActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsKneeFlexPassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                Ankle Toe Movements
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsAnkleActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsAnkleActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsAnklePassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsAnkleActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsAnkleActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsAnklePassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                Dynamic Quadriceps
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsDynamicActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsDynamicActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsDynamicPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsDynamicActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsDynamicActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsDynamicPassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                Static Quadriceps
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticQActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticQActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticQPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticQActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticQActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticQPassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                Static Hamstrings
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticHActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticHActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticHPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticHActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticHActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsStaticHPassive_Lft}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table class="border border-black">
                          <thead class=" ">
                            <tr className="">
                              <th class="px-3 text-center "></th>
                              <th class="px-3 text-center "></th>
                              <th class="px-3 text-center ">Right Side</th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-center ">Left Side</th>
                            </tr>
                            <tr className="">
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-center border border-black">
                                Active
                              </th>
                              <th class="px-3 text-center border border-black">
                                Active Assisted
                              </th>
                              <th class="px-3 text-center border border-black">
                                Passive
                              </th>
                              <th class="px-3 text-left "></th>
                              <th class="px-3 text-center border border-black">
                                Active
                              </th>
                              <th class="px-3 text-center border border-black">
                                Active Assisted
                              </th>
                              <th class="px-3 text-center border border-black">
                                Passive
                              </th>
                            </tr>
                          </thead>
                          <tbody class="divide-y divide-black">
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                SHOULDER FLEXION/EXTENSION
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsSholderFlexActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsSholderFlexActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsSholderFlexPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHipFlexActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsSholderFlexActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsSholderFlexPassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                ELBOW FLEXION/EXTENSION
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsElbowFlexActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsElbowFlexActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsElbowFlexPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsElbowFlexActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsElbowFlexActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsElbowFlexPassive_Lft}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="px-3 py-1 whitespace-nowrap">
                                HANDLPUMPS
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHandpActive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHandpActiveAsst_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHandpPassive_Rgt}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black"></td>

                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHandpActive_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHandpActiveAsst_Lft}
                                />
                              </td>
                              <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                                <CheckUncheckIcon
                                  value={item?.IsHandpPassive_Lft}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>

                    <div className="col-span-6">
                      <div className="font-semibold text-sm underline underline-offset-4">
                        Bed Side Activity :
                      </div>
                      <table class="border border-black mt-3 ">
                        <thead class=" ">
                          <tr className="">
                            <th class="px-3 text-left ">Dynamic Quadriceps</th>
                            <th class="px-3 text-center border border-black">
                              Independent
                            </th>
                            <th class="px-3 text-center border border-black">
                              Mild Assistance
                            </th>
                            <th class="px-3 text-center border border-black">
                              Max Assistance
                            </th>
                          </tr>
                        </thead>
                        <tbody class="divide-y divide-black">
                          <tr className="border border-black">
                            <td class="px-3 py-1 whitespace-nowrap font-bold">
                              Dynamic Quadriceps
                            </td>
                            <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                              <CheckUncheckIcon value={item?.IsBedSideIndep} />
                            </td>
                            <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                              <CheckUncheckIcon
                                value={item?.IsBedSideMldAsst}
                              />
                            </td>
                            <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                              <CheckUncheckIcon
                                value={item?.IsBedSideMaxAsst}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td class="px-3 py-1 whitespace-nowrap font-bold">
                              Dynamic Quadriceps
                            </td>
                            <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                              <CheckUncheckIcon value={item?.IsBedStandIndep} />
                            </td>
                            <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                              <CheckUncheckIcon
                                value={item?.IsBedStandMaxAsst}
                              />
                            </td>
                            <td class="px-3 py-1 whitespace-nowrap text-center border border-black">
                              <CheckUncheckIcon
                                value={item?.IsStaticHPassive_Lft}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-span-6 gap-3">
                      <div className="font-semibold text-sm underline underline-offset-4 my-3">
                        Ambulation :
                      </div>
                      <div className="flex gap-3 ml-5">
                        <div className="font-semibold text-sm w-64">
                          Ambulation Given in 24 Hrs
                        </div>
                        <div>:</div>
                        <div className="flex justify-between gap-3">
                          <div className="w-24">Yes</div>
                          <div>
                            <CheckUncheckIcon
                              value={item?.IsAmbGivenin24Hrs_Yes}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between gap-3">
                          <div className="w-24">No</div>
                          <div>
                            <CheckUncheckIcon
                              value={item?.IsAmbGivenin24Hrs_No}
                            />
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          <div className="font-semibold text-sm ">Remarks:</div>
                          <div className="text-sm ">
                            {item?.AmbGivenin24Hrs_YesRemark}
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-3 ml-5">
                        <div className="font-semibold text-sm w-64">
                          Ambulation With Walker
                        </div>
                        <div>:</div>

                        <div className="flex justify-between gap-3">
                          <div className="w-24">FWB</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsFWB} />
                          </div>
                        </div>
                        <div className="flex justify-between gap-3">
                          <div className="w-24">PWB</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsPWB} />
                          </div>
                        </div>
                        <div className="flex justify-between gap-3">
                          <div className="w-24">NWB</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsNWB} />
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-3 ml-5">
                        <div className="font-semibold text-sm w-64">
                          Ambulation Without Walker
                        </div>
                        <div>:</div>

                        <div className="flex justify-between gap-3">
                          <div className="w-24">Independent</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsAmbIndep} />
                          </div>
                        </div>
                        <div className="flex justify-between gap-3">
                          <div className="w-24">Mild Support</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsAmbMildSupp} />
                          </div>
                        </div>
                        <div className="flex justify-between gap-3">
                          <div className="w-24">Max Support</div>
                          <div>
                            <CheckUncheckIcon value={item?.IsAmbMaxSupp} />
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-3 ml-5">
                        <div className="font-semibold text-sm w-64">
                          Wheelchair Ambulation:
                        </div>
                        <div>:</div>
                        <div className="flex justify-between gap-3">
                          <div>
                            <CheckUncheckIcon value={item?.IsWheelAmb} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 flex gap-x-10 items-center my-3">
                      <div className="font-semibold text-sm underline underline-offset-4">
                        Machine :
                      </div>
                      <div className="flex justify-between gap-3">
                        <div>IRR</div>
                        <div>
                          <CheckUncheckIcon value={item?.IsIRR} />
                        </div>
                      </div>
                      <div className="flex justify-between gap-3">
                        <div>IFT</div>
                        <div>
                          <CheckUncheckIcon value={item?.IsIFT} />
                        </div>
                      </div>
                      <div className="flex justify-between gap-3">
                        <div>HCP</div>
                        <div>
                          <CheckUncheckIcon value={item?.IsHCP} />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 flex gap-x-10 my-3">
                      <div className="font-semibold text-sm underline underline-offset-4">
                        Remarks :
                      </div>
                      <div> {item?.Remark}</div>
                    </div>
                    <div className="col-span-6 border border-black my-3 "></div>

                    <div className="col-span-6 gap-x-10 grid grid-cols-3 mb-5">
                      <div className="flex gap-3">
                        <div className="text-black font-semibold">
                          Created Date :
                        </div>
                        <div className="text-black ">{item?.CreatedDate}</div>
                        <div></div>
                      </div>
                      <div className="flex gap-3">
                        <div className="text-black font-semibold">
                          Created Time :
                        </div>
                        <div className="">{item?.CreatedTime}</div>
                        <div></div>
                      </div>
                      <div className="flex gap-3">
                        <div className="text-black font-semibold">
                          Created By :
                        </div>
                        <div className="text-black ">{item?.AddedBy}</div>
                        <div></div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
