import React, { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import {
  fetchArea,
  fetchCity,
  fetchCountries,
  fetchState,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import InputLimit from "../../../common/components/FormFields/InputLimit";

const AddressInfo = () => {
  const [country, setCountry] = useState();
  const [countryId, setCountryId] = useState(2);
  const [state, setState] = useState();
  const [stateId, setStateId] = useState(2);
  const [city, setCity] = useState();
  const [cityId, setCityId] = useState(2);
  const [area, setArea] = useState();

  const {
    control,
    register,
    resetField,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  //const country_selected = watch("country");

  /////Api to get country List
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getState(2);
  }, []);

  // useEffect(() => {
  //   getDistrict();
  // }, [stateId]);

  useEffect(() => {
    getCity();
  }, [stateId]);

  useEffect(() => {
    getArea(cityId);
  }, [cityId]);

  const getCountries = () => {
    fetchCountries()
      .then((response) => response.data)
      .then((res) => {
        setCountry(res.result);
      });
  };

  //Api to get State List

  const getState = (countryid) => {
    if (countryid) {
      fetchState(countryid).then((response) => {
        if (response.data.result?.length > 0) {
          setState(response.data.result);
        } else {
          setState([]);
        }
      });
    }
  };
  //Api to get District  List

  // const getDistrict = () => {
  //   if (typeof stateId === "number") {
  //     fetchDistrict(stateId).then((response) => {
  //       if (response.data.result?.length > 0) {
  //         setDistrict(response.data.result);
  //       } else {
  //         setDistrict([]);
  //       }
  //     });
  //   }
  // };
  //Api to get City List

  const getCity = () => {
    if (typeof stateId === "number") {
      fetchCity(stateId).then((response) => {
        if (response.data.result?.length > 0) {
          setCity(response.data.result);
        } else {
          setCity([]);
        }
      });
    }
  };
  //Api to get Area List

  const getArea = () => {
    if (typeof cityId === "number") {
      fetchArea(cityId).then((response) => {
        setArea(response.data.result);
        if (response.data.result?.length > 0) {
          setArea(response.data.result);
        } else {
          setArea([]);
        }
      });
    }
  };

  return (
    <div>
      <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-3">
        {/* //House No.// */}
        <div>
          <InputField
            name="houseFlatNumber"
            variant="outlined"
            label="House No./Flat No.*"
            error={errors.houseFlatNumber}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        {/* //Street Address// */}
        <div>
          <InputField
            name="society"
            variant="outlined"
            label="Society/House*"
            error={errors.society}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 255 }}
          />
        </div>

        {/* //nearest landmark// */}
        <div>
          <InputField
            name="nearestLandmark"
            variant="outlined"
            label="Nearest Landmark*"
            error={errors.nearestLandmark}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 255 }}
          />
        </div>

        {/* //address// */}
        <div className="col-span-2">
          <InputField
            name="address"
            variant="outlined"
            label="Address"
            // error={errors.address}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 255 }}
          />
        </div>

        {/* //Country// */}
        <div>
          <DropdownField
            control={control}
            error={errors.country}
            name="country"
            label="Country *"
            dataArray={country}
            isSearchable={false}
            placeholder="Country *"
            isClearable={false}
            inputRef={{
              ...register("country", {
                onChange: (e) => {
                  if (e.target.value.id) {
                    setCountryId(e.target.value.id);
                    getState(e.target.value.id)
                  }
                  setValue("state", null);
                  setValue("city", null);
                  setValue("area", null);
                  setValue("pinCode", null);
                  if (e.target.value.label === "India") {
                    setValue("passportNumber", "");
                  }
                  //
                  setState([]);
                  setCity([]);
                  setArea([]);
                },
              }),
            }}
          />
        </div>
        {/* //State // */}
        <div>
          <DropdownField
            control={control}
            error={errors.state}
            name="state"
            label="State *"
            dataArray={state}
            isSearchable={false}
            placeholder="State *"
            isClearable={false}
            inputRef={{
              ...register("state", {
                onChange: (e) => {
                  setStateId(e.target.value.id);
                },
              }),
            }}
          />
        </div>

        {/* //District and PinCode// */}

        {/* //District// 
        <div>
          <DropdownField
            control={control}
            error={errors.district}
            name="district"
            label="District *"
            dataArray={district}
            isSearchable={false}
            placeholder="District *"
            isClearable={false}
            inputRef={{
              ...register("district", {
                onChange: (e) => {
                  
                  setDistrictId(e.target.value.id);
                  //
                  resetField("city");
                  resetField("area");
                  resetField("pinCode");
                },
              }),
            }}
          />
        </div>*/}

        {/* //City// */}

        <div>
          <DropdownField
            control={control}
            error={errors.city}
            name="city"
            label="City"
            dataArray={city}
            isSearchable={false}
            placeholder="City *"
            isClearable={false}
            inputRef={{
              ...register("city", {
                onChange: (e) => {
                  setCityId(e.target.value.id);
                  //
                  resetField("area");
                  resetField("pinCode");
                },
              }),
            }}
          />
        </div>

        {/* //Area// */}
        <div>
          <DropdownField
            control={control}
            error={errors.area}
            name="area"
            label="Area"
            dataArray={area}
            placeholder="Area *"
            //isClearable={true}
            // isSearchable={true}
            //searchIcon={true}
          />
        </div>
        {/* //Pin Code // */}
        <div>
          <InputLimit
            maxDigits={6}
            name="pinCode"
            variant="outlined"
            label="Pincode"
            type="number"
            error={errors.pinCode}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 6 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
