import { useState } from "react";
import { useForm } from "react-hook-form";
import SearchIcon from "../../../../../docithealth_front/src/assets/icons/SearchIcon.svg";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchPatientInformationById,
  searchPatientInformation,
} from "../../services/otConsentServices/OtConsentServices ";
//imports fromn the mui library
import IconButton from "@mui/material/IconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import InstructionsTable from "../commonInstructionTable/InstructionsTable";

function PreOpInstructions() {
  const defaultValues = {
    patientType: "ipd",
  };

  const { control, register, watch } = useForm({
    defaultValues,
  });

  const patientTypeArray = [
    {
      id: "opd",
      value: "OPD",
      label: "OPD",
    },
    {
      id: "ipd",
      value: "IPD",
      label: "IPD",
    },
    {
      id: "emergency",
      value: "Emergency",
      label: "Emergency",
    },
  ];

  let opdIpd;
  let patientTypeVal = watch("patientType");
  

  if (patientTypeVal === "opd") {
    opdIpd = 0;
  } else if (patientTypeVal === "ipd") {
    opdIpd = 1;
  } else if (patientTypeVal === "emergency") {
    opdIpd = 2;
  }

  const [instructionArray, setInstructionArray] = useState([]);

  const [options, setOptions] = useState([]);

  const [patientInfo, setPatientInfo] = useState(null);

  function onSubmitDataHandler(data) {
    
  }

  //Change handler for "Search By Patient Name / MR No" SearchDropdown.
  const handleChange = (searchString) => {
    
    if (searchString !== "") {
      searchPatientInformation(searchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  function getPatientInfo(selPatientObj) {
    //call the below function to get the patient information by id.
    fetchPatientInformationById(selPatientObj.id, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        
        setPatientInfo(res.result);
      });
  }

  //   {
  //     "patientId": 2007341,
  //     "patientName": "Miss. KAVYA PATIL",
  //     "admissionId": 1060579,
  //     "mrNo": "306080",
  //     "gender": "Female",
  //     "ageYear": "28 Y 2 M 11 D ",
  //     "patientCategory": "Corporate",
  //     "unitId": 2,
  //     "tariffId": 2,
  //     "doctorName": "Sachin Bodhale",
  //     "doctorId": 415,
  //     "patientCategoryId": 3,
  //     "tariff": "Hospital",
  //     "company": "93 Avenue",
  //     "Department": "Opthalmology",
  //     "CompanyId": 340,
  //     "patientSource": "Hospital",
  //     "patientSourceId": 2,
  //     "MobileNo": "9767846456",
  //     "DepartmentId": 15,
  //     "patientAdvance": null,
  //     "AdvanceID": null,
  //     "Cash Pay Amount": null,
  //     "dob": "1995-10-11",
  //     "admVisitPatchDate": "2023-12-22"
  // }
  return (
    <>
      <form onSubmit={onSubmitDataHandler}>
        <div className="mt-12 text-xl font-semibold flex justify-center">
          Pre Operative Instructions
        </div>

        {/* the search bar's parent div */}
        <div className="grid grid-cols-3 gap-3 justify-evenly mt-4">
          <div>
            <div className="mt-1.5">
              <SearchDropdown
                name="patientSearch"
                control={control}
                searchIcon={true}
                placeholder="Search By Patient Name / MR.No./ Mobile No."
                handleInputChange={handleChange}
                isClearable={true}
                dataArray={options}
                inputRef={{
                  ...register("patientSearch", {
                    onChange: (e) => {
                      
                      
                     

                      if (e.target.value !== null) {
                        getPatientInfo(e.target.value);
                      } else {
                        setPatientInfo(null);
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>

          <div className="col-span-2">
            <div className="flex">
              <div className="mt-2">
                <div className="mt-1">
                  <RadioField
                    control={control}
                    name="patientType"
                    dataArray={patientTypeArray}
                  />
                </div>
              </div>
              <div>
                <IconButton>
                  <img
                    className="rounded-sm hover:cursor-pointer w-[35px] h-[35px]"
                    src={SearchIcon}
                    alt="React Logo"
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        {/* div for patient information */}
        <div className="border items-center justify-center bg-gray-100 border-gray-300 text-left w-full rounded-md mb-2 px-2 mt-8">
          <div className="py-2 grid  lg:grid-cols-3 2xl:grid-cols-3 gap-2 p-3 ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
              <span className=" font-semibold w-28">Registration No</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">----</span>
              </div>
            </div>
            <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
              <span className=" w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfo?.patientName}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
              <span className=" w-28">OPD No</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">----</span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 mt-8">
          <div>
            <DropdownField
              control={control}
              name="group"
              placeholder="Select Group"
              isSearchable={false}
            />
          </div>

          <div>
            <DropdownField
              control={control}
              name="subGroup"
              placeholder="Select Sub Group"
              isSearchable={false}
            />
          </div>

          <div>
            <DropdownField
              control={control}
              name="procedures"
              placeholder="Select Procedure"
              isSearchable={false}
            />
          </div>
        </div>

        <div className="mt-8 text-xl font-semibold">List Of Instructions</div>
        <div>
          <InstructionsTable instructionArray={instructionArray} />
        </div>

        {/* div for print and cancel button  */}
        <div className="flex flex-row gap-3 justify-end mt-2">
          <div>
            <CommonButton label="Print" className="bg-customGray text-white" />
          </div>

          <div>
            <CommonButton
              label="Cancel"
              className="border border-customRed text-customRed"
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default PreOpInstructions;
