import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  getDeliveryList,
  getFreightList,
  getListOfName,
  getModeOfPayment,
  getSupplierList,
  getTaxNatureList,
  getTaxType,
  getTermOfPayment,
  getUomList,
  saveWorkOrderMultiple,
} from "../../../services/generalStore/workOrderServices/WorkOrderServices";
import WorkOrderMultipleTable from "../common/WorkOrderMultipleTable";
import WorkOrderMultipleModal from "./WorkOrderMultipleModal";
const scheduleData = [
  {
    id: 0,
    value: "For Next 3 Months As Per Tel. Confirmation",
    label: "For Next 3 Months As Per Tel. Confirmation",
  },
  {
    id: 1,
    value: "Immediate",
    label: "Immediate",
  },
];

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

const actions = ["assessmentPrint"];
export default function WorkOrderMultiple() {
  const schema = yup.object().shape({
    taxType: yup.object().required(),
  });
  //schema
  //use form
  let defaultDeliveryDate = {
    id: 2,
    label: "1 WEEK",
    value: "1 WEEK",
  };
  let defaultModeOfPayment = {
    id: 4,
    label: "CASH",
    value: "CASH",
  };
  let defaultTermOfPayment = {
    id: 13,
    label: "10% ADVANCE & REM AFTER DELIVERY",
    description: "10% ADVANCE & REM AFTER DELIVERY",
  };
  const defaultValues = {
    supplierName: null,
    freight: null,
    deliveryDate: defaultDeliveryDate,
    paymentMode: defaultModeOfPayment,
    paymentTerms: defaultTermOfPayment,
    taxNature: null,
    itemCode: "",
    itemName: null,
    qty: "",
    rate: "",
    uom: "",
    totalAmount: 0,
    discPercent: "",
    discAmount: 0,
    gstPercent: "",
    gstAmount: 0,
    netAmount: 0,
    remark: "",
    guaranteewarranty: "",
    schedule: null,
    taxType: null,
    finalTotalAmount: "",
    finalDiscAmount: "",
    finalGstAmount: "",
    finalNetAmount: "",
    taxNature: "",
    remarks: "",
    cashVoucher: false,
  };
  // const schema = {};
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    register,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [supplierList, setSupplierList] = useState();
  const [supplierId, setSupplierId] = useState(null);

  const [freightList, setFreightList] = useState();
  const [deliveryDate, setDeliveryList] = useState();
  const [modeOfPaymentList, setModeOfPaymentList] = useState();
  const [termOfPaymentList, setTermOfPaymentList] = useState();
  const [uomList, setUomList] = useState();
  const [nameList, setNameList] = useState();
  const [taxNatureList, setTaxNatureList] = useState();
  const [taxType, setTaxType] = useState();
  const [storeData, setStoreData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [edit, setEdit] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [finalData, setFinalData] = useState();
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    setOpenPost(false);
  };
  const [discVal, setDiscVal] = useState(0);
  const [gstVal, setGstVal] = useState(0);
  const [customError, setCustomError] = useState();

  let getSupplier = watch("supplierName");

  let getItemCode = watch("itemCode");
  let getItemName = watch("itemName");
  let getQty = watch("qty");
  let getRate = watch("rate");
  let getUom = watch("uom");
  let getTotalAmt = watch("totalAmount");
  let getDiscPer = watch("discPercent");
  let getDiscAmt = watch("discAmount");
  let getGstPer = watch("gstPercent");
  let getGstAmt = watch("gstAmount");
  let getNetAmt = watch("netAmount");
  let getRemark = watch("remark");

  const inputRefItemCode = useRef(null);
  const inputRefItemName = useRef(null);
  const inputRefQty = useRef(null);
  const inputRefRate = useRef(null);
  const inputRefUom = useRef(null);
  const inputRefTotalAmt = useRef(null);
  const inputRefDiscPer = useRef(null);
  const inputRefDiscAmt = useRef(null);
  const inputRefGstPer = useRef(null);
  const inputRefGstAmt = useRef(null);
  const inputRefRemark = useRef(null);

  let getStoreName = JSON.parse(localStorage.getItem("userInfo"));

  let storeName = {
    value: getStoreName.storeName,
    label: getStoreName.storeName,
  };
  setValue("storeName", storeName);

  const handleChangeGetSupplierList = (autoSearchString) => {
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  function freightDropdown() {
    getFreightList().then((response) => {
      setFreightList(response.data.result);
    });
  }
  function deliveryDropdown() {
    getDeliveryList().then((response) => {
      setDeliveryList(response.data.result);
    });
  }
  function modeOfPaymentDropdown() {
    getModeOfPayment().then((response) => {
      setModeOfPaymentList(response.data.result);
    });
  }
  function termOfPaymentDropdown() {
    getTermOfPayment().then((response) => {
      setTermOfPaymentList(response.data.result);
    });
  }
  function uomDropdown() {
    getUomList().then((response) => {
      setUomList(response.data.result);
    });
  }
  function nameDropdown() {
    getListOfName().then((response) => {
      setNameList(response.data.result);
    });
  }
  function taxNatureDropdown() {
    getTaxNatureList().then((response) => {
      setTaxNatureList(response.data.result);
    });
  }

  function taxTypeDropdown() {
    getTaxType().then((response) => {
      setTaxType(response.data.result);
    });
  }

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    freightDropdown();
    deliveryDropdown();
    modeOfPaymentDropdown();
    termOfPaymentDropdown();
    uomDropdown();
    nameDropdown();
    taxNatureDropdown();
    taxTypeDropdown();
  }, []);

  useEffect(() => {
    let totalAmt = Number(getQty ? getQty : 0) * Number(getRate ? getRate : 0);
    setValue("totalAmount", totalAmt ? totalAmt : 0);

    let discAmt;
    if (getDiscPer !== "") {
      discAmt =
        Number(totalAmt ? totalAmt : 0) * (Number(discVal ? discVal : 0) / 100);
      setValue("discAmount", discAmt ? discAmt : 0);
    }

    let gstAmt;
    if (getGstPer !== "") {
      gstAmt =
        (Number(totalAmt ? totalAmt : 0) - Number(discAmt ? discAmt : 0)) *
        (Number(gstVal ? gstVal : 0) / 100);
      setValue("gstAmount", gstAmt ? gstAmt : 0);
    }

    let netAmt =
      Number(totalAmt ? totalAmt : 0) -
      (Number(discAmt ? discAmt : 0) - Number(gstAmt ? gstAmt : 0));
    setValue("netAmount", netAmt ? netAmt : 0);
  }, [getQty, getRate, discVal, gstVal]);

  function maxVal(e) {
    if (Number(e) > Number(100)) {
      setError("discPercent", { type: "custom", message: "custom message" });
      setCustomError(true);
    } else if (Number(e) <= Number(100)) {
      clearErrors("discPercent");
      setCustomError(false);
    }
  }

  function addService() {
    if (edit === false) {
      let saveItemObj = {
        itemCode: getItemCode ? getItemCode : "",
        itemName: getItemName ? getItemName.label : "",
        qty: getQty ? Number(getQty) : 0,
        pendQty: 0,
        rate: getRate ? Number(getRate) : 0,
        uom: getUom ? getUom.label : "",
        amount: getTotalAmt ? getTotalAmt : 0,
        discPer: getDiscPer ? Number(getDiscPer) : 0,
        discAmt: getDiscAmt ? getDiscAmt : 0,
        vatPer: getGstPer ? Number(getGstPer) : 0,
        vatAmt: getGstAmt ? getGstAmt : 0,
        netAmount: getNetAmt ? getNetAmt : 0,
        supplierName: getSupplier ? getSupplier : null,
        remark: getRemark ? getRemark : "",
      };
      let itemArr = [...storeData];
      itemArr.push(saveItemObj);

      setStoreData(itemArr);
    } else if (edit === true) {
      let updateItemObj = {
        itemCode: getItemCode ? getItemCode : "",
        itemName: getItemName ? getItemName.label : "",
        qty: getQty ? Number(getQty) : 0,
        pendQty: 0,
        rate: getRate ? Number(getRate) : 0,
        uom: getUom ? getUom.label : "",
        amount: getTotalAmt ? getTotalAmt : 0,
        discPer: getDiscPer ? Number(getDiscPer) : 0,
        discAmt: getDiscAmt ? getDiscAmt : 0,
        vatPer: getGstPer ? Number(getGstPer) : 0,
        vatAmt: getGstAmt ? getGstAmt : 0,
        netAmount: getNetAmt ? getNetAmt : 0,
        supplierName: getSupplier ? getSupplier : null,
        remark: getRemark ? getRemark : "",
      };
      let arr = [...storeData];
      arr.splice(selectedIndex, 1, updateItemObj);
      setStoreData(arr);
      setEdit(false);
    }
  }

  const onSubmitData = (data) => {
    let dataArr = [];
    for (let obj of storeData) {
      let dataObj = {
        addedBy: getStoreName.userId,
        amount: obj.amount,
        deliveryDt: data.deliveryDate.label,
        discAmt: obj.discAmt,
        discPer: obj.discPer,
        freight: data.freight ? data.freight.label : "",
        guaranteeWarranty: data.guaranteewarranty,
        isCashVoucher: data.cashVoucher,
        itemCode: obj.itemCode,
        itemName: obj.itemName,
        modeOfPayment: data.paymentMode.label,
        netAmount: obj.netAmount,
        octroi: "",
        pendQty: obj.pendQty,
        qty: obj.qty,
        rate: obj.rate,
        remark: obj.remark,
        schedule: data.schedule ? data.schedule.label : "",
        storeId: getStoreName.storeId,
        supplierId: obj.supplierName.id,
        taxNature: data.taxNature ? data.taxNature.label : "",
        termOfPayment: data.paymentTerms.label,
        uom: obj.uom,
        vatAmt: obj.vatAmt,
        vatPer: obj.vatPer,
      };
      dataArr.push(dataObj);
    }

    if (storeData.length > 0) {
      setOpenPost(true);
      setFinalData(dataArr);
    } else {
      setOpenPost(false);
    }
  };

  function postData() {
    setOpenBackdrop(true);
    saveWorkOrderMultiple(location?.state?.menuId, privilege, finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenPost(false);
        setOpenBackdrop(false);
        reset(defaultValues);
        setStoreData([]);
      });
  }

  useEffect(() => {
    let finalTotalAmt = 0;
    for (var i = 0; i < storeData.length; i++) {
      finalTotalAmt = finalTotalAmt + storeData[i].amount;
    }
    setValue("finalTotalAmount", finalTotalAmt);
    let finalDiscAmt = 0;
    for (var i = 0; i < storeData.length; i++) {
      finalDiscAmt = finalDiscAmt + storeData[i].discAmt;
    }
    setValue("finalDiscAmount", finalDiscAmt);
    let finalGstAmt = 0;
    for (var i = 0; i < storeData.length; i++) {
      finalGstAmt = finalGstAmt + storeData[i].vatAmt;
    }
    setValue("finalGstAmount", finalGstAmt);
    let finalNetAmt = 0;
    for (var i = 0; i < storeData.length; i++) {
      finalNetAmt = finalNetAmt + storeData[i].netAmount;
    }
    setValue("finalNetAmount", finalNetAmt);
  }, [storeData]);

  function editRow(data, index) {
    setEdit(true);
    let getItemName = {
      label: data.itemName,
      value: data.itemName,
    };
    let getUom = {
      label: data.uom,
      value: data.uom,
    };
    // let getSupplier = {
    //   label: data.supplierName,
    //   value: data.supplierName,
    // };

    setValue("itemCode", data.itemCode);
    setValue("itemName", getItemName);
    setValue("qty", data.qty);
    setValue("rate", data.rate);
    setValue("uom", getUom);
    setValue("totalAmount", data.amount);
    setValue("discAmount", data.discAmt);
    setValue("discPercent", data.discPer);
    setValue("gstAmount", data.vatAmt);
    setValue("gstPercent", data.vatPer);
    setValue("netAmount", data.netAmount);
    setValue("supplierName", data.supplierName);
    setValue("remark", data.remark);
    setSelectedIndex(index);
  }

  const deleteRecord = (index) => {
    if (index !== null) {
      let newTemplateData = [...storeData];
      // newTemplateData[deleteIndex].deleteFlag = true;
      newTemplateData.splice(index, 1);
      setStoreData(newTemplateData);
    }
  };

  const onEnterFocusNext = (e) => {
    if (e.target.name === "itemCode" && e.key === "Enter" && e.target.value) {
      inputRefItemName.current.focus();
    } else if (
      e.target.id === "react-select-17-input" &&
      e.key === "Enter" &&
      e.target.value
    ) {
      //
      inputRefQty.current.focus();
    } else if (e.target.name === "qty" && e.key === "Enter" && e.target.value) {
      inputRefRate.current.focus();
    } else if (
      e.target.name === "rate" &&
      e.key === "Enter" &&
      e.target.value
    ) {
      inputRefUom.current.focus();
    } else if (
      e.target.id === "react-select-19-input" &&
      e.key === "Enter" &&
      e.target.value
    ) {
      inputRefDiscPer.current.focus();
    } else if (
      e.target.name === "discPercent" &&
      e.key === "Enter" &&
      e.target.value
    ) {
      inputRefGstPer.current.focus();
    } else if (
      e.target.name === "gstPercent" &&
      e.key === "Enter" &&
      e.target.value
    ) {
      inputRefRemark.current.focus();
    } else if (
      e.target.textContent === "Add" &&
      e.key === "Enter" &&
      e.target.value
    ) {
      inputRefItemCode.current.focus();
    }
    // if (e.target.name === "input-1") {
    //   inputRefTwo.current.focus();
    // }
    // if (e.target.name === "input-4") {
    //   inputRefFive.current.focus();
    // }
    // if (e.target.name === "input-3") {
    //   inputRefFour.current.focus();
    // }

    // if (e.keyCode === 13) {
    //
    // const name = e.target.name;
    // const [fieldName, fieldIndex] = name.split("-");
    // // Check if it's not the last input field
    //
    // if (parseInt(fieldIndex, 10) < 6) {
    //   // Get the next input field
    //   const nextSibling = document.querySelector(
    //     `input[name=input-${parseInt(fieldIndex, 10) + 1}]`
    //   );
    //   var number = parseInt(fieldIndex, 10) + 1;
    //   // If found, focus the next field
    //   if (nextSibling !== null) {
    //     nextSibling.focus();
    //   }
    //
    // }
    // }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="mt-10 px-2 w-full space-y-2">
          <div className="text-center text-black font-bold text-xl py-2">
            Work Order (Multiple)
          </div>
          <div className="grid grid-cols-5 gap-3">
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.storeName}
                name="storeName"
                placeholder="Store Name*"
                isDisabled={true}
              />
            </div>

            {/* <div className=" w-full">
              <SearchDropdown
                control={control}
                searchIcon={true}
                isDisabled={false}
                isSearchable={true}
                isClearable={true}
                error={errors.supplierName}
                name="supplierName"
                placeholder="Supplier Name*"
                dataArray={supplierList}
                handleInputChange={handleChangeGetSupplierList}
                inputRef={{
                  ...register(`supplierName`, {
                    onChange: (e) => {
                      if (e.target.value === null) {
                        setSupplierId(null);
                      } else {
                        setSupplierId(e.target.value.id);
                      }
                    },
                  }),
                }}
              />
            </div> */}
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.drugName}
                name="freight"
                placeholder="Freight"
                dataArray={freightList}
                // isDisabled={props.edit}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.deliveryDate}
                name="deliveryDate"
                placeholder="Delivery Date"
                dataArray={deliveryDate}
                // isDisabled={props.edit}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.paymentMode}
                name="paymentMode"
                placeholder="Payment Mode"
                dataArray={modeOfPaymentList}
                // isDisabled={props.edit}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.paymentTerms}
                name="paymentTerms"
                placeholder="Payment Terms"
                dataArray={termOfPaymentList}
                // isDisabled={props.edit}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.taxNature}
                name="taxNature"
                placeholder="Tax Nature"
                dataArray={taxNatureList}
              />
            </div>
            <div className=" w-full flex">
              <CheckBoxField
                name="cashVoucher"
                control={control}
                label="Cash Voucher"
              />
            </div>
            {/* <div className="w-full"> <SearchPOButton /></div> */}
            <div className="flex justify-end col-span-3">
              {userActions.map((action, i) => (
                <>
                  {action.action === "Work Order List" &&
                  action.isAction === false ? (
                    <CommonButton
                      type="button"
                      label="Work Order List"
                      className="bg-customBlue text-white"
                      onClick={() => {
                        handleOpen();
                      }}
                    />
                  ) : null}
                </>
              ))}
            </div>
          </div>
          <hr className="border-t-[1px] border-blue-900 py-1" />
          <div className="grid grid-cols-5 lg:grid-cols-9 gap-3 mt-4 w-full">
            <div className="col-span-3 lg:col-span-4">
              <div className=" flex space-x-3 items-center">
                <label className="font-semibold text-lg whitespace-nowrap ">
                  Item Details
                </label>

                <div className="">
                  <InputField
                    onKeyDown={(e) => {
                      onEnterFocusNext(e);
                    }}
                    name="itemCode"
                    label=" Item Code"
                    control={control}
                    error={errors.itemCode}
                    inputRef={inputRefItemCode}
                  />
                </div>
                <div
                  className="w-full"
                  onKeyDown={(e) => {
                    onEnterFocusNext(e);
                  }}
                >
                  <DropdownField
                    control={control}
                    name="itemName"
                    placeholder="Item Name"
                    isSearchable={true}
                    dataArray={nameList}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <InputField
                onKeyDown={(e) => {
                  onEnterFocusNext(e);
                  if (
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.code === "ArrowUp" ||
                    e?.code === "ArrowDown" ||
                    e?.code === "NumpadDecimal"
                  ) {
                    e.preventDefault();
                  }
                }}
                type="Number"
                name="qty"
                label=" Qty"
                placeholder="Qty"
                control={control}
                error={errors.qty}
                inputRef={inputRefQty}
                autoComplete="off"
              />
            </div>
            <div className="">
              <InputField
                onKeyDown={(e) => {
                  onEnterFocusNext(e);
                  if (
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.code === "ArrowUp" ||
                    e?.code === "ArrowDown"
                  ) {
                    e.preventDefault();
                  }
                }}
                type="Number"
                name="rate"
                label=" Rate"
                placeholder="Rate"
                control={control}
                error={errors.rate}
                inputRef={inputRefRate}
                autoComplete="off"
                // disabled={isDisabled}
              />
            </div>
            <div
              className=""
              onKeyDown={(e) => {
                onEnterFocusNext(e);
              }}
            >
              <DropdownField
                name="uom"
                placeholder="UOM"
                control={control}
                error={errors.uom}
                dataArray={uomList}
                inputRef={inputRefUom}
                // disabled={isDisabled}
              />
            </div>
            <div className="">
              <InputField
                name="totalAmount"
                label="Total Amount"
                placeholder="Total Amount"
                control={control}
                error={errors.totalAmount}
                disabled={true}
              />
            </div>
            <div
              className=""
              onChange={(e) => {
                // getDiscountAmt(e.target.value);
                setDiscVal(e.target.value);
                maxVal(e.target.value);
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            >
              <TextField
                autoComplete="off"
                size="small"
                type="string"
                color={customError ? "error" : "primary"}
                inputProps={{
                  maxLength: 3,
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "14px",
                    position: "absolute",
                    top: "-2px",
                  },
                }}
                sx={{
                  input: {
                    color: customError ? "#d32f2f" : "#212529",
                  },
                  svg: {
                    color: "#1e3a8a",
                    height: 22,
                    width: "100%",
                    marginRight: "16px",
                  },
                  backgroundColor: "white",
                  overflow: "visible",

                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-input": {
                      // border: 0,
                      fontSize: 14,
                      height: "18px",
                      width: "100%",

                      borderColor: "  ",
                      overflow: "hidden",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "14px",
                    },
                  },
                }}
                name="discPercent"
                variant="outlined"
                label="Disc %"
                {...register("discPercent")}
                error={errors.discPercent}
                control={control}
              />
            </div>
            <div className="">
              <InputField
                name="discAmount"
                label="Disc Amount"
                placeholder="Disc Amount"
                control={control}
                error={errors.discAmount}
                disabled={true}
              />
            </div>
            <div
              className=""
              onChange={(e) => {
                // getGstDiscount(e.target.value);
                setGstVal(e.target.value);
                maxVal(e.target.value);
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
            >
              <TextField
                autoComplete="off"
                size="small"
                type="string"
                color={customError ? "error" : "primary"}
                inputProps={{
                  maxLength: 3,
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "14px",
                    position: "absolute",
                    top: "-2px",
                  },
                }}
                sx={{
                  input: {
                    color: customError ? "#d32f2f" : "#212529",
                  },
                  svg: {
                    color: "#1e3a8a",
                    height: 20,
                    width: "100%",
                    marginRight: "16px",
                  },
                  backgroundColor: "white",
                  overflow: "visible",

                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-input": {
                      // border: 0,
                      fontSize: 14,
                      height: "18px",
                      width: "100%",

                      borderColor: "  ",
                      overflow: "hidden",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "14px",
                    },
                  },
                }}
                name="gstPercent"
                variant="outlined"
                label="GST %"
                {...register("gstPercent")}
                // onChange={(e) => {
                //   getConcession(e);
                // }}
                error={errors.gstPercent}
                control={control}
              />
            </div>
            <div className="">
              <InputField
                name="gstAmount"
                label="GST Amount"
                placeholder="GST Amount"
                control={control}
                error={errors.gstAmount}
                disabled={true}
              />
            </div>
            <div className="">
              <InputField
                name="netAmount"
                label="Net Amount"
                placeholder="Net Amount"
                control={control}
                // error={errors.netAmount}
                disabled={true}
              />
            </div>
            <div className=" w-full col-span-2">
              <SearchDropdown
                control={control}
                searchIcon={true}
                isDisabled={false}
                isSearchable={true}
                isClearable={true}
                error={errors.supplierName}
                name="supplierName"
                placeholder="Supplier Name*"
                dataArray={supplierList}
                handleInputChange={handleChangeGetSupplierList}
                inputRef={{
                  ...register(`supplierName`, {
                    onChange: (e) => {
                      if (e.target.value === null) {
                        setSupplierId(null);
                      } else {
                        setSupplierId(e.target.value.id);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className="col-span-2">
              <InputField
                onKeyDown={(e) => {
                  onEnterFocusNext(e);
                }}
                name="remark"
                label="Remark"
                control={control}
                inputRef={inputRefRemark}
                // disabled={isDisabled}
              />
            </div>

            <div
              className=""
              onKeyDown={(e) => {
                onEnterFocusNext(e);
              }}
            >
              <CommonButton
                type="button"
                label="Add"
                className="bg-customBlue text-white"
                onClick={() => {
                  if (getSupplier === null) {
                    setError("supplierName", {
                      type: "required",
                      message: "Select Supplier",
                    });
                  } else if (getSupplier !== null) {
                    clearErrors("supplierName");
                  }
                  if (
                    getItemName !== null &&
                    getQty > 0 &&
                    getRate &&
                    getSupplier !== null
                  ) {
                    addService();
                    setValue("itemCode", "");
                    setValue("itemName", null);
                    setValue("qty", "");
                    setValue("rate", "");
                    setValue("uom", null);
                    setValue("totalAmount", "");
                    setValue("discPercent", "");
                    setValue("discAmount", "");
                    setValue("gstPercent", "");
                    setValue("gstAmount", "");
                    setValue("netAmount", "");
                    setValue("remark", "");
                    setValue("supplierName", null);
                  }
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <WorkOrderMultipleTable
              storeData={storeData}
              setStoreData={setStoreData}
              editRow={editRow}
              deleteRecord={deleteRecord}
            />
          </div>

          <div className="grid grid-cols-5 gap-2">
            <div className=" w-full col-span-2">
              <InputField
                control={control}
                name="guaranteewarranty"
                label="Guarantee / Warranty"
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.schedule}
                name="schedule"
                placeholder="Schedule"
                dataArray={scheduleData}
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                error={errors.taxType}
                name="taxType"
                placeholder="Tax Type"
                dataArray={taxType}
              />
            </div>
            <div className=" w-full">
              <InputField
                control={control}
                name="finalTotalAmount"
                label="Total Amount"
                disabled={true}
              />
            </div>
            <div className=" w-full">
              <InputField
                control={control}
                name="finalDiscAmount"
                label="Disc Amount"
                disabled={true}
              />
            </div>
            <div className=" w-full">
              <InputField
                control={control}
                name="finalGstAmount"
                label="GST Amount"
                disabled={true}
              />
            </div>
            <div className=" w-full">
              <InputField
                control={control}
                name="finalNetAmount"
                label="Net Amount"
                disabled={true}
              />
            </div>
            <div className="">
              <InputField control={control} name="remarks" label="Remarks" />
            </div>
          </div>
          {userActions.map((action, i) => (
            <>
              {action.action === "Create" && action.isAction === false ? (
                <div className="flex justify-end py-2 gap-2">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                      setStoreData([]);
                    }}
                  />
                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                    onClick={() => {
                      setPrivilege(action.action);
                    }}
                  />
                </div>
              ) : null}
            </>
          ))}
        </div>
      </form>
      {open && (
        <WorkOrderMultipleModal
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          userActions={userActions}
        />
      )}

      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={postData}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this Record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
