import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { ModalStyle } from "../../../common/components/ModalStyle";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { getPushToolList } from "../../services/pushToolServices/PushToolServices";

const PushToolListingModal = (props) => {
  const { openListingModal, setOpenListingModal } = props;
  const { control, watch, reset } = useForm({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });
  const [pushToolListing, setPushToolListing] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(null);

  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");

  function getPushToolListing(forPagination) {
    let listObj = {
      admissionId: null,
      fromDate: getFromDate,
      toDate: getToDate,
    };
    getPushToolList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setPushToolListing((prevData) => [...prevData, ...res.result]);
        } else {
          setPushToolListing(res.result);
        }
        setCount(res.count);
      });
  }

  useEffect(() => {
    getPushToolListing();
  }, []);
  return (
    <div>
      <Modal open={openListingModal}>
        <Box sx={ModalStyle}>
          <div
            onClick={() => {
              setOpenListingModal(false);
              reset();
            }}
          >
            <CancelPresentationIconButton />
          </div>
          <div>
            <div className="flex space-x-2 w-96">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date "
                value={new Date()}
                size="small"
                inputFormat="yyyy-MM-dd"
              />
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date "
                value={new Date()}
                size="small"
                inputFormat="yyyy-MM-dd"
              />
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={() => {
                  getPushToolListing();
                }}
              />
            </div>
            <div>
              {pushToolListing !== undefined && pushToolListing?.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={pushToolListing}
                  populateTable={getPushToolListing}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  setCount={setCount}
                  removeHeaders={["id", "AdmissionId"]}
                />
              ) : (
                <div className="justify-center items-center font-semibold h-96 w-full flex border-2 border-gray-300 rounded my-2">
                  No Records Found...
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PushToolListingModal;
