import React, { useEffect, useState } from "react";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { useForm } from "react-hook-form";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { format, isAfter } from "date-fns";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import { getAccountHead } from "../../services/miscellaneousIcomeServices/MiscellaneousIcomeServices";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { Tooltip } from "@mui/material";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";
import { fetchListOfMiscellaneousExpense } from "../../services/miscellaneousExpenseServices/MiscellaneousExpenseServices";
import MiscellaneousExpenseModal from "./MiscellaneousExpenseModal";
import MiscellaneousExpenceReport from "../../prints/MiscellaneousExpensePrint";
import MiscellaneousExpenseLineItemPrint from "../../prints/MiscellaneousExpenseLineItemPrint";

export default function MiscellaneousExpense() {
  const defaultValues = {
    accountHead: null,
    fromDate: new Date(),
    toDate: new Date(),
    cancelled: false,
  };
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [accountHead, setAccountHead] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [privilege, setPrivilege] = useState(null);
  const [userActions, setUserActions] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [page, setPage] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // MiscellaneousExpenceReport
  let location = useLocation();
  let fromDateVal = watch("fromDate");
  let toDateVal = watch("toDate");
  let cancelledVal = watch("cancelled");
  let accountHeadVal = watch("accountHead");

  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [miscExpenseId, setMiscExpenseId] = useState(null);
  let newFromDate =
    isAfter(fromDateVal, new Date(1900, 1, 1)) &&
    format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  let newToDate =
    isAfter(toDateVal, new Date(1900, 1, 1)) &&
    format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 500);

    return (
      <div className="hidden">
        {miscExpenseId === null ? (
          <MiscellaneousExpenceReport
            fromDate={newFromDate}
            toDate={newToDate}
          />
        ) : null}

        {miscExpenseId ? (
          <MiscellaneousExpenseLineItemPrint miscExpenseId={miscExpenseId} />
        ) : null}
      </div>
    );
  };

  const populateTable = (forPagination) => {
    const defaultParams = {
      accountId: accountHeadVal !== null ? accountHeadVal?.id : 0,
      fromDate: format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.593'Z'"),
      isCancelled: cancelledVal,
      toDate: format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.593'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    setShowSpinner(true);
    fetchListOfMiscellaneousExpense(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setShowSpinner(false);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        setShowSpinner(false);
        errorAlert(error.message);
      });
  };

  const renderActions = (row, index) => {
    return (
      <Tooltip title="Print">
        <button
          type="button"
          onClick={() => {
            setMiscExpenseId(row.Id);
            setOpenPrintModal(true);
          }}
        >
          <PrintOnIcon />
        </button>
      </Tooltip>
    );
  };

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    /********* Table **********/
    populateTable();

    /******* Account Head *******/
    getAccountHead()
      .then((response) => {
        setAccountHead(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }, []);

  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Miscellaneous Expense
      </h1>

      {/********* Filters  ***********/}
      <div className="grid grid-cols-4 gap-2 py-2">
        <DropdownField
          control={control}
          name="accountHead"
          placeholder="Account Head"
          dataArray={accountHead}
          isSearchable={true}
          isClearable={true}
        />
        <div className="flex space-x-2">
          <DatePickerFieldNew
            control={control}
            name="fromDate"
            label="From Date"
            value={new Date()}
            inputFormat="dd-MM-yyyy"
          />
          <DatePickerFieldNew
            control={control}
            name="toDate"
            label="To Date"
            value={new Date()}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        <div className="flex space-x-2">
          <CheckBoxField control={control} name="cancelled" label="Cancelled" />
          <CommonButton
            type="button"
            searchIcon={true}
            className="bg-customBlue text-white"
            onClick={() => {
              populateTable();
            }}
          />
        </div>

        <div className="flex justify-end space-x-3">
          <CommonButton
            type="button"
            label="Add Miscellaneous Expense"
            className="bg-customBlue text-white"
            onClick={() => {
              setOpen(true);
            }}
          />

          <Tooltip title="Print List">
            <button
              type="button"
              onClick={() => {
                setMiscExpenseId(null);
                setOpenPrintModal(true);
              }}
            >
              <PrintOnIcon />
            </button>
          </Tooltip>
        </div>
      </div>

      {/********** Listing ************/}
      {showSpinner ? (
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dataResult.length > 0 ? (
            // <CommonDynamicTableNew
            //   dataResult={dataResult}
            //   removeHeaders={["Id"]}
            //   // renderActions={renderActions}
            //   highlightRow={false}
            //   tableClass={"rounded"}
            // />

            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              highlightedRow={false}
              removeHeaders={["Id"]}
              highlightRow={true}
              renderActions={renderActions}
              tableHeading="List Of Miscellaneous Expense"
              tableClass=""
            />
          ) : (
            <h1 className="flex justify-center py-5 font-bold text-gray-600">
              No Record Found...
            </h1>
          )}
        </>
      )}

      <MiscellaneousExpenseModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        populateTable={populateTable}
        accountHead={accountHead}
      />

      {openPrintModal && renderPrint()}
    </div>
  );
}
