import { Divider, Slider, styled, Tooltip } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../../common/components/FormFields/InputArea";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getGeneralAssessmentDetails,
  getPhysioAssessmentList,
  saveGeneralAssessment,
} from "../../services/PhysioGeneralAssessmentServices.js";
import { searchPhysioPatientAutoComplete } from "../../services/PhysioWorkOrderViewServices";
import { PrintIcon } from "../../../../assets/icons/InvestigationIcon";
import PhysioAssessmentPrint from "../../prints/PhysioAssessmentPrint";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { useLocation } from "react-router-dom";
import GenerralAssessmentListingModal from "./GenerralAssessmentListingModal";

const PatientType = [
  {
    id: 0,
    label: "OPD",
    value: 0,
  },
  {
    id: 1,
    label: "IPD",
    value: 1,
  },
];

const MedicalCheck = [
  {
    id: "Diabetes",
    label: "Diabetes",
    value: "Diabetes",
  },
  {
    id: "BP",
    label: "BP",
    value: "BP",
  },
  {
    id: "IHD",
    label: "IHD",
    value: "IHD",
  },
  {
    id: "COPD",
    label: "COPD",
    value: "COPD",
  },
  {
    id: "Malignancy",
    label: "Malignancy",
    value: "Malignancy",
  },
];

const SurgicalHistory = [
  { id: "Implant", value: "Implant", label: "Implant" },
  { id: "Fracture", value: "Fracture", label: "Fracture" },
  { id: "Fall", value: "Fall", label: "Fall" },
  { id: "Others", value: "Others", label: "Others" },
];

const OnPalpation = [
  { id: "Tenderness", value: "Tenderness", label: "Tenderness" },
  { id: "Spasm", value: "Spasm", label: "Spasm" },
  { id: "Trigger", value: "Trigger", label: "Trigger" },
];

const ObservationCheck = [
  {
    type: "Deformity",
    positive: "Positive",
    negative: "Negative",
  },
  {
    type: "Swelling",
    positive: "Positive",
    negative: "Negative",
  },
  {
    type: "Scar",
    positive: "Positive",
    negative: "Negative",
  },
  {
    type: "Erythma",
    positive: "Positive",
    negative: "Negative",
  },
  {
    type: "Wound",
    positive: "Positive",
    negative: "Negative",
  },
];

const OnExamination = [
  {
    label: "Joint",
    type: "text",
  },
  {
    label: "Flexion/Flexors",
    type: "text",
  },
  {
    label: "Extension/Extensors",
    type: "text",
  },
  {
    label: "Abduction/Abductors",
    type: "text",
  },
  {
    label: "Adduction/Adductors",
    type: "text",
  },
  {
    label: "Others",
    type: "text",
  },
  {
    label: "Sensations",
    type: "text",
  },
  {
    name: "SensationFle",
    type: "input",
    label: "Flexion/Flexors",
  },
  {
    name: "SensationExt",
    type: "input",
    label: "Extension/Extensors",
  },
  {
    name: "SensationAbd",
    type: "input",
    label: "Abduction/Abductors",
  },
  {
    name: "SensationAdd",
    type: "input",
    label: "Adduction/Adductors",
  },
  {
    name: "SensationOth",
    type: "input",
    label: "Others",
  },
  {
    label: "ROM",
    type: "text",
  },
  {
    name: "ROMFle",
    type: "input",
    label: "Flexion/Flexors",
  },
  {
    name: "ROMExt",
    type: "input",
    label: "Extension/Extensors",
  },
  {
    name: "ROMAbd",
    type: "input",
    label: "Abduction/Abductors",
  },
  {
    name: "ROMAdd",
    type: "input",
    label: "Adduction/Adductors",
  },
  {
    name: "ROMOth",
    type: "input",
    label: "Others",
  },
  {
    label: "MMT",
    type: "text",
  },
  {
    name: "MMTFle",
    type: "input",
    label: "Flexion/Flexors",
  },
  {
    name: "MMTExt",
    type: "input",
    label: "Extension/Extensors",
  },
  {
    name: "MMTAbd",
    type: "input",
    label: "Abduction/Abductors",
  },
  {
    name: "MMTAdd",
    type: "input",
    label: "Adduction/Adductors",
  },
  {
    name: "MMTOth",
    type: "input",
    label: "Others",
  },
];

const OnExaminationInput = [
  { id: "Tightness", value: "Tightness", label: "Tightness" },
  { id: "Contracture", value: "Contracture", label: "Contracture" },
  { id: "OnExamination", value: "OnExamination", label: "Remarks" },
];

const FunctionalityIDCheck = [
  {
    type: "IDHeading",
    label: "Independent",
  },
  {
    type: "IDHeading",
    label: "Dependent",
  },
  {
    type: "WWHeading",
    label: "With Aid",
  },
  {
    type: "WWHeading",
    label: "Without Aid",
  },
  {
    label: "Lying",
    independent: "Independent",
    dependent: "Dependent",
    withAid: "WithAid",
    withoutAid: "WithoutAid",
    type: "rowHeading",
  },
  {
    label: "Sitting",
    independent: "Independent",
    dependent: "Dependent",
    withAid: "WithAid",
    withoutAid: "WithoutAid",
    type: "rowHeading",
  },
  {
    label: "Standing",
    independent: "Independent",
    dependent: "Dependent",
    withAid: "WithAid",
    withoutAid: "WithoutAid",
    type: "rowHeading",
  },
  {
    label: "Walking",
    independent: "Independent",
    dependent: "Dependent",
    withAid: "WithAid",
    withoutAid: "WithoutAid",
    type: "rowHeading",
  },
];

const FunctionalActivity = [
  {
    label: "Lying",
    type: "rowHeading",
  },
  {
    label: "Sitting",
    type: "rowHeading",
  },
  {
    label: "Standing",
    type: "rowHeading",
  },
  {
    label: "Walking",
    type: "rowHeading",
  },
  {
    label: "Independent",
    type: "IDColumn",
  },
  {
    label: "Dependent",
    type: "IDColumn",
  },
  {
    label: "With Aid",
    type: "WWColumn",
  },
  {
    label: "Without Aid",
    type: "WWColumn",
  },
  {
    type: "Lying",
    independent: "Independent",
    dependent: "Dependent",
  },
  {
    type: "Sitting",
    independent: "Independent",
    dependent: "Dependent",
  },
  {
    type: "Standing",
    independent: "Independent",
    dependent: "Dependent",
  },
  {
    type: "Walking",
    independent: "Independent",
    dependent: "Dependent",
  },
  {
    type: "Lying",
    widhAid: "WithAid",
    withoutAid: "withoutAid",
  },
  {
    type: "Sitting",
    widhAid: "WithAid",
    withoutAid: "withoutAid",
  },
  {
    type: "Standing",
    widhAid: "WithAid",
    withoutAid: "withoutAid",
  },
  {
    type: "Walking",
    widhAid: "WithAid",
    withoutAid: "withoutAid",
  },
];

const InvestigationInput = [
  { id: "XRay", value: "XRay", label: "X-Ray" },
  { id: "MRI", value: "MRI", label: "MRI" },
  { id: "OtherTest", value: "OtherTest", label: "OtherTest" },
  { id: "Condition", value: "Condition", label: "Condition" },
  { id: "TreatmentPlan", value: "TreatmentPlan", label: "Treatment Plan" },
];

function valueText(value) {
  return `${value}°C`;
}

const CustomSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  padding: 5,

  "& .MuiSlider-mark": {
    border: "none",
    color: "gray",
    height: 7,
    // marginLeft: "2px",
  },
  "& .MuiSlider-rail": {
    height: 8,
    border: "none",
    // backgroundImage:
    //   "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
    backgroundColor: "grey",
  },
  "& .MuiSlider-track": {
    height: 8,
    border: "none",
    // backgroundImage:
    //   "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
    backgroundColor: "#1E3A8A",
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid #1E3A8A",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 20,
    height: 20,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#1E3A8A",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const sliderMarks = [
  {
    value: 0,
    scaledValue: 0,
    label: 0,
  },
  {
    value: 1,
    scaledValue: 1,
    label: 1,
  },
  {
    value: 2,
    scaledValue: 2,
    label: 2,
  },
  {
    value: 3,
    scaledValue: 3,
    label: 3,
  },
  {
    value: 4,
    scaledValue: 4,
    label: 4,
  },
  {
    value: 5,
    scaledValue: 5,
    label: 5,
  },
  {
    value: 6,
    scaledValue: 6,
    label: 6,
  },
  {
    value: 7,
    scaledValue: 7,
    label: 7,
  },
  {
    value: 8,
    scaledValue: 8,
    label: 8,
  },
  {
    value: 9,
    scaledValue: 9,
    label: 9,
  },
  {
    value: 10,
    scaledValue: 10,
    label: 10,
  },
];

const painScore = [
  {
    id: 0,
    score: 0,
    value: "No Pain",
  },
  {
    id: 1,
    score: 1,
    value: "Mild Pain",
  },
  {
    id: 2,
    score: 2,
    value: "Mild Pain",
  },
  {
    id: 3,
    score: 3,
    value: "Mild Pain",
  },
  {
    id: 4,
    score: 4,
    value: "Moderate Pain",
  },
  {
    id: 5,
    score: 5,
    value: "Moderate Pain",
  },
  {
    id: 6,
    score: 6,
    value: "Severe Pain",
  },
  {
    id: 7,
    score: 7,
    value: "Severe Pain",
  },
  {
    id: 8,
    score: 8,
    value: "Very Severe Pain",
  },
  {
    id: 9,
    score: 9,
    value: "Very Severe Pain",
  },
  {
    id: 10,
    score: 10,
    value: "Worst Pain Possible",
  },
];

const GeneralAssessment = () => {
  const defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
    listType: 2,
    patientType: 0,
    painScore: "0",
    chiefComplaints: "",
    painAssessment: "",
    intermittent: false,
    continuous: false,
    localized: false,
    radiating: false,
    medicalHistory: "",
    Diabetes: false,
    BP: false,
    IHD: false,
    COPD: false,
    Malignancy: false,
    Implant: "",
    Fracture: "",
    Fall: "",
    Others: "",
    DeformityPositive: false,
    DeformityNegative: false,
    SwellingPositive: false,
    SwellingNegative: false,
    ScarPositive: false,
    ScarNegative: false,
    ErythmaPositive: false,
    ErythmaNegative: false,
    WoundPositive: false,
    WoundNegative: false,
    observationRemarks: "",
    Tenderness: "",
    Spasm: "",
    Trigger: "",
    rightLimb: "",
    leftLimb: "",
    palpationRemarks: "",
    jointName: "",
    SensationFle: "",
    SensationExt: "",
    SensationAbd: "",
    SensationAdd: "",
    SensationOth: "",
    ROMFle: "",
    ROMExt: "",
    ROMAbd: "",
    ROMAdd: "",
    ROMOth: "",
    MMTFle: "",
    MMTExt: "",
    MMTAbd: "",
    MMTAdd: "",
    MMTOth: "",
    Tightness: "",
    Contracture: "",
    Remarks: "",
    testName: "",
    positiveTest: false,
    negativeTest: false,
    specialTestRemark: "",
    IndependentLying: false,
    DependentLying: false,
    IndependentSitting: false,
    DependentSitting: false,
    IndependentStanding: false,
    DependentStanding: false,
    IndependentWalking: false,
    DependentWalking: false,
    WithAidLying: false,
    WithoutAidLying: false,
    WithAidSitting: false,
    WithoutAidSitting: false,
    WithAidStanding: false,
    WithoutAidStanding: false,
    WithAidWalking: false,
    WithoutAidWalking: false,
    functionalActivityRemark: "",
    XRay: "",
    MRI: "",
    OtherTest: "",
    Condition: "",
    TreatmentPlan: "",
  };

  const { control, watch, setValue, register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });
  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");
  let getListType = watch("listType");
  let getPatientType = Number(watch("patientType"));
  let getTestName = watch("testName");
  let getPositiveTest = watch("positiveTest");
  let getNegativeTest = watch("negativeTest");
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [generalAssessmentList, setGeneralAssessmentList] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchId, setSearchId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sliderReset, setSliderReset] = React.useState(0);
  const [testList, setTestList] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deformity, setDeformity] = useState("");
  const [swelling, setSwelling] = useState("");
  const [scar, setScar] = useState("");
  const [erythma, setErythma] = useState("");
  const [wound, setWound] = useState("");
  const [lyingID, setLyingID] = useState("");
  const [sittingID, setSittingID] = useState("");
  const [standingID, setStandingID] = useState("");
  const [walkingID, setWalkingID] = useState("");
  const [lyingWW, setLyingWW] = useState("");
  const [sittingWW, setSittingWW] = useState("");
  const [standingWW, setStandingWW] = useState("");
  const [walkingWW, setWalkingWW] = useState("");
  const [specialTestCheck, setSpecialTestCheck] = useState("");
  const [typeCheck, setTypeCheck] = useState("");
  const [patternCheck, setPatternCheck] = useState("");
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [privilege, setPrivilege] = useState("");
  const [openGenAsseListModal, setOpenGenAsseListModal] = useState(false);

  function handleChange(autoSearchString) {
    let searchObj = {
      fromDate: getFromDate,
      opdIpd: getPatientType,
      searchString: autoSearchString,
      toDate: getToDate,
    };
    searchPhysioPatientAutoComplete(searchObj).then((response) => {
      setOptions(response.data.result);
    });
  }

  function autoSelectedValue(value) {
    if (value !== null) {
      setSearchId(value);
    } else {
      setSearchId(null);
    }
  }

  console.log("searchId123", searchId, selectedRow);

  function getAssessmentList() {
    let listObj = {
      fromDate: getFromDate,
      toDate: getToDate,
      opdIpdAll: getListType,
    };
    getPhysioAssessmentList(listObj).then((response) => {
      setGeneralAssessmentList(response.data.result);
    });
  }

  function handleSelectedRow(row) {
    setSelectedRow(row);
    getGeneralAssessmentDetails(row?.ID).then((response) => {
      let patchData = response.data.result;
      setValue("chiefComplaints", patchData?.CheifComplaints);
      setValue("painAssessment", patchData?.PainAssesment);
      setTypeCheck(patchData?.Type1 === 1 ? "Intermittent" : "Continuous");
      setPatternCheck(patchData?.Pattern1 === 1 ? "Localized" : "Radiating");
      setSliderReset(patchData?.NAS);
      setValue("medicalHistory", patchData?.MedicalHistory);
      setValue("Diabetes", patchData?.Diabetes === 1 ? true : false);
      setValue("BP", patchData?.BP === 1 ? true : false);
      setValue("IHD", patchData?.IHD === 1 ? true : false);
      setValue("COPD", patchData?.COPD === 1 ? true : false);
      setValue("Malignancy", patchData?.Malignancy === 1 ? true : false);
      setValue("Implant", patchData?.Implant);
      setValue("Fracture", patchData?.Fracture);
      setValue("Fall", patchData?.Fall);
      setValue("Others", patchData?.Others);
      setDeformity(patchData?.Deformity === 1 ? "Positive" : "Negative");
      setSwelling(patchData?.Swelling === 1 ? "Positive" : "Negative");
      setScar(patchData?.Scar === 1 ? "Positive" : "Negative");
      setErythma(patchData?.Erythma === 1 ? "Positive" : "Negative");
      setWound(patchData?.Wound === 1 ? "Positive" : "Negative");
      setValue("Tenderness", patchData?.Tenderness);
      setValue("Spasm", patchData?.Spasm);
      setValue("Trigger", patchData?.Trigger);
      setValue("rightLimb", patchData?.Limb_Right);
      setValue("leftLimb", patchData?.Limb_Left);
      setValue("observationRemarks", patchData?.ObservationRemark);
      setValue("palpationRemarks", patchData?.PalpationRemark);
      setValue("jointName", patchData?.JointName);
      setValue("SensationFle", patchData?.Sensations1);
      setValue("SensationExt", patchData?.Sensations2);
      setValue("SensationAbd", patchData?.Sensations3);
      setValue("SensationAdd", patchData?.Sensations4);
      setValue("SensationOth", patchData?.Sensations5);
      setValue("ROMFle", patchData?.ROM1);
      setValue("ROMExt", patchData?.ROM2);
      setValue("ROMAbd", patchData?.ROM3);
      setValue("ROMAdd", patchData?.ROM4);
      setValue("ROMOth", patchData?.ROM5);
      setValue("MMTFle", patchData?.MMT1);
      setValue("MMTExt", patchData?.MMT2);
      setValue("MMTAbd", patchData?.MMT3);
      setValue("MMTAdd", patchData?.MMT4);
      setValue("MMTOth", patchData?.MMT5);
      setValue("Tightness", patchData?.Tightness);
      setValue("Contracture", patchData?.Contracture);
      setValue("OnExamination", patchData?.OnExaminationRemark);
      setValue("specialTestRemark", patchData?.TestRemark);
      setLyingID(
        patchData?.Lying1 === 1
          ? "Independent"
          : patchData?.Lying2 === 1
          ? "Dependent"
          : ""
      );
      setSittingID(
        patchData?.Sitting1 === 1
          ? "Independent"
          : patchData?.Sitting2 === 1
          ? "Dependent"
          : ""
      );
      setStandingID(
        patchData?.Standing1 === 1
          ? "Independent"
          : patchData?.Standing2 === 1
          ? "Dependent"
          : ""
      );
      setWalkingID(
        patchData?.Walking1 === 1
          ? "Independent"
          : patchData?.Walking2 === 1
          ? "Dependent"
          : ""
      );
      setLyingWW(
        patchData?.Lying3 === 1
          ? "WithAid"
          : patchData?.Lying4 === 1
          ? "WithoutAid"
          : ""
      );
      setSittingWW(
        patchData?.Sitting3 === 1
          ? "WithAid"
          : patchData?.Sitting4 === 1
          ? "WithoutAid"
          : ""
      );
      setStandingWW(
        patchData?.Standing3 === 1
          ? "WithAid"
          : patchData?.Standing4 === 1
          ? "WithoutAid"
          : ""
      );
      setWalkingWW(
        patchData?.Walking3 === 1
          ? "WithAid"
          : patchData?.Walking4 === 1
          ? "WithoutAid"
          : ""
      );
      setValue("functionalActivityRemark", patchData?.FunctionalRemark);
      setValue("XRay", patchData?.X_Ray);
      setValue("MRI", patchData?.MRI);
      setValue("OtherTest", patchData?.OtherTest);
      setValue("Condition", patchData?.Condition);
      setValue("TreatmentPlan", patchData?.TreatmentPlan);
      let testDetailsArr = [];
      for (let obj of patchData?.TestDetils) {
        let testDetailsObj = {
          TestName: obj?.TestName,
          generalAssDtlsId: obj?.Id,
          Positive: obj?.Result === 1 ? true : false,
          Negative: obj?.Result === 0 ? true : false,
        };
        testDetailsArr.push(testDetailsObj);
      }
      setTestList(testDetailsArr);
    });
  }

  useEffect(() => {
    if (selectedRow !== null) {
      getGeneralAssessmentDetails(selectedRow?.ID).then((response) => {
        let patchData = response.data.result;
        setValue("chiefComplaints", patchData?.CheifComplaints);
        setValue("painAssessment", patchData?.PainAssesment);
        setTypeCheck(patchData?.Type1 === 1 ? "Intermittent" : "Continuous");
        setPatternCheck(patchData?.Pattern1 === 1 ? "Localized" : "Radiating");
        setSliderReset(patchData?.NAS);
        setValue("medicalHistory", patchData?.MedicalHistory);
        setValue("Diabetes", patchData?.Diabetes === 1 ? true : false);
        setValue("BP", patchData?.BP === 1 ? true : false);
        setValue("IHD", patchData?.IHD === 1 ? true : false);
        setValue("COPD", patchData?.COPD === 1 ? true : false);
        setValue("Malignancy", patchData?.Malignancy === 1 ? true : false);
        setValue("Implant", patchData?.Implant);
        setValue("Fracture", patchData?.Fracture);
        setValue("Fall", patchData?.Fall);
        setValue("Others", patchData?.Others);
        setDeformity(patchData?.Deformity === 1 ? "Positive" : "Negative");
        setSwelling(patchData?.Swelling === 1 ? "Positive" : "Negative");
        setScar(patchData?.Scar === 1 ? "Positive" : "Negative");
        setErythma(patchData?.Erythma === 1 ? "Positive" : "Negative");
        setWound(patchData?.Wound === 1 ? "Positive" : "Negative");
        setValue("Tenderness", patchData?.Tenderness);
        setValue("Spasm", patchData?.Spasm);
        setValue("Trigger", patchData?.Trigger);
        setValue("rightLimb", patchData?.Limb_Right);
        setValue("leftLimb", patchData?.Limb_Left);
        setValue("observationRemarks", patchData?.ObservationRemark);
        setValue("palpationRemarks", patchData?.PalpationRemark);
        setValue("jointName", patchData?.JointName);
        setValue("SensationFle", patchData?.Sensations1);
        setValue("SensationExt", patchData?.Sensations2);
        setValue("SensationAbd", patchData?.Sensations3);
        setValue("SensationAdd", patchData?.Sensations4);
        setValue("SensationOth", patchData?.Sensations5);
        setValue("ROMFle", patchData?.ROM1);
        setValue("ROMExt", patchData?.ROM2);
        setValue("ROMAbd", patchData?.ROM3);
        setValue("ROMAdd", patchData?.ROM4);
        setValue("ROMOth", patchData?.ROM5);
        setValue("MMTFle", patchData?.MMT1);
        setValue("MMTExt", patchData?.MMT2);
        setValue("MMTAbd", patchData?.MMT3);
        setValue("MMTAdd", patchData?.MMT4);
        setValue("MMTOth", patchData?.MMT5);
        setValue("Tightness", patchData?.Tightness);
        setValue("Contracture", patchData?.Contracture);
        setValue("OnExamination", patchData?.OnExaminationRemark);
        setValue("specialTestRemark", patchData?.TestRemark);
        setLyingID(
          patchData?.Lying1 === 1
            ? "Independent"
            : patchData?.Lying2 === 1
            ? "Dependent"
            : ""
        );
        setSittingID(
          patchData?.Sitting1 === 1
            ? "Independent"
            : patchData?.Sitting2 === 1
            ? "Dependent"
            : ""
        );
        setStandingID(
          patchData?.Standing1 === 1
            ? "Independent"
            : patchData?.Standing2 === 1
            ? "Dependent"
            : ""
        );
        setWalkingID(
          patchData?.Walking1 === 1
            ? "Independent"
            : patchData?.Walking2 === 1
            ? "Dependent"
            : ""
        );
        setLyingWW(
          patchData?.Lying3 === 1
            ? "WithAid"
            : patchData?.Lying4 === 1
            ? "WithoutAid"
            : ""
        );
        setSittingWW(
          patchData?.Sitting3 === 1
            ? "WithAid"
            : patchData?.Sitting4 === 1
            ? "WithoutAid"
            : ""
        );
        setStandingWW(
          patchData?.Standing3 === 1
            ? "WithAid"
            : patchData?.Standing4 === 1
            ? "WithoutAid"
            : ""
        );
        setWalkingWW(
          patchData?.Walking3 === 1
            ? "WithAid"
            : patchData?.Walking4 === 1
            ? "WithoutAid"
            : ""
        );
        setValue("functionalActivityRemark", patchData?.FunctionalRemark);
        setValue("XRay", patchData?.X_Ray);
        setValue("MRI", patchData?.MRI);
        setValue("OtherTest", patchData?.OtherTest);
        setValue("Condition", patchData?.Condition);
        setValue("TreatmentPlan", patchData?.TreatmentPlan);
        let testDetailsArr = [];
        for (let obj of patchData?.TestDetils) {
          let testDetailsObj = {
            TestName: obj?.TestName,
            generalAssDtlsId: obj?.Id,
            Positive: obj?.Result === 1 ? true : false,
            Negative: obj?.Result === 0 ? true : false,
          };
          testDetailsArr.push(testDetailsObj);
        }
        setTestList(testDetailsArr);
      });
      setOpenGenAsseListModal(false);
    }
  }, [selectedRow]);

  //print related
  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
      // setSelectedPatient(null);
    }, 500);

    return (
      <div className="hidden">
        <PhysioAssessmentPrint physioAssessmentId={selectedRow?.ID} />
      </div>
    );
  };

  function addSpecialTest() {
    let tempTestArr = [...testList];
    let testObj = {
      TestName: getTestName,
      Positive: specialTestCheck === "Positive" ? true : false,
      Negative: specialTestCheck === "Negative" ? true : false,
      generalAssDtlsId: null,
    };
    let duplicateTest = testList.some(
      (val) => val?.TestName === testObj?.TestName
    );
    if (!duplicateTest) {
      tempTestArr.push(testObj);
      setTestList(tempTestArr);
    } else {
      warningAlert("Test already exists...!");
    }
  }

  function renderInput(row, index, header) {
    return (
      <div>
        {header === "Positive" && (
          <fieldset disabled={true}>
            <CheckBoxField
              control={control}
              name={`positiveCheck${index}`}
              defaultValue={row?.Positive}
            />
          </fieldset>
        )}
        {header === "Negative" && (
          <fieldset disabled={true}>
            <CheckBoxField
              control={control}
              name={`negativeCheck${index}`}
              defaultValue={row?.Negative}
            />
          </fieldset>
        )}
      </div>
    );
  }

  const onSubmitData = (data) => {
    let postTempList = [];
    for (let obj of testList) {
      let testObj = {
        isPositive: obj?.Positive === true ? true : false,
        generalAssDtlsId: obj?.generalAssDtlsId ? obj?.generalAssDtlsId : null,
        testName: obj?.TestName,
      };
      postTempList.push(testObj);
    }

    let postObj = {
      bp: data.BP === true ? 1 : 0,
      chiefComplaints: data.chiefComplaints,
      condition: data.Condition,
      contracture: data.Contracture,
      copd: data.COPD === true ? 1 : 0,
      deformity: deformity === "Positive" ? 1 : 0,
      diabetes: data.Diabetes === true ? 1 : 0,
      erythma: erythma === "Positive" ? 1 : 0,
      fall: data.Fall,
      fracture: data.Fracture,
      functionalRemark: data.functionalActivityRemark,
      generalAssessmentDetailsDtoList: postTempList,
      generalAssessmentId: selectedRow ? selectedRow?.ID : null,
      ihd: data.IHD === true ? 1 : 0,
      implant: data.Implant,
      jointName: data.jointName,
      limbLeft: data.leftLimb,
      limbRight: data.rightLimb,
      lying1: lyingID === "Independent" ? 1 : 0,
      lying2: lyingID === "Dependent" ? 1 : 0,
      lying3: lyingWW === "WithAid" ? 1 : 0,
      lying4: lyingWW === "WithoutAid" ? 1 : 0,
      malignancy: data.Malignancy === true ? 1 : 0,
      medicalHistory: data.medicalHistory,
      mmt1: data.MMTFle,
      mmt2: data.MMTExt,
      mmt3: data.MMTAbd,
      mmt4: data.MMTAdd,
      mmt5: data.MMTOth,
      mri: data.MRI,
      nas: sliderReset,
      observationRemark: data.observationRemarks,
      onExaminationRemark: data.OnExamination,
      opdIpdExternal: selectedRow
        ? selectedRow?.OPD_IPD_External
        : getPatientType,
      opdIpdExternalId: selectedRow
        ? selectedRow?.OPD_IPD_External_ID
        : getPatientType === 0
        ? searchId?.VisitId
        : searchId?.AdmissionId,
      otherTest: data.OtherTest,
      others: data.Others,
      painAssessment: data.painAssessment,
      palpationRemark: data.palpationRemarks,
      pattern1: patternCheck === "Localized" ? 1 : 0,
      pattern2: patternCheck === "Radiating" ? 1 : 0,
      rom1: data.ROMFle,
      rom2: data.ROMExt,
      rom3: data.ROMAbd,
      rom4: data.ROMAdd,
      rom5: data.ROMOth,
      scar: scar === "Positive" ? 1 : 0,
      sensations1: data.SensationFle,
      sensations2: data.SensationExt,
      sensations3: data.SensationAbd,
      sensations4: data.SensationAdd,
      sensations5: data.SensationOth,
      sitting1: sittingID === "Independent" ? 1 : 0,
      sitting2: sittingID === "Dependent" ? 1 : 0,
      sitting3: sittingWW === "WithAid" ? 1 : 0,
      sitting4: sittingWW === "WithoutAid" ? 1 : 0,
      spasm: data.Spasm,
      standing1: standingID === "Independent" ? 1 : 0,
      standing2: standingID === "Dependent" ? 1 : 0,
      standing3: standingWW === "WithAid" ? 1 : 0,
      standing4: standingWW === "WithoutAid" ? 1 : 0,
      swelling: swelling === "Positive" ? 1 : 0,
      tenderness: data.Tenderness,
      testRemark: data.specialTestRemark,
      tightness: data.Tightness,
      treatmentPlan: data.TreatmentPlan,
      trigger: data.Trigger,
      type1: typeCheck === "Intermittent" ? 1 : 0,
      type2: typeCheck === "Continuous" ? 1 : 0,
      walking1: walkingID === "Independent" ? 1 : 0,
      walking2: walkingID === "Dependent" ? 1 : 0,
      walking3: walkingWW === "WithAid" ? 1 : 0,
      walking4: walkingWW === "WithoutAid" ? 1 : 0,
      wound: wound === "Positive" ? 1 : 0,
      xray: data.XRay,
      privilege: privilege,
    };

    if (searchId !== null || selectedRow !== null) {
      setFinalData(postObj);
      setOpenConfirmation(true);
    } else {
      warningAlert("Select Patient...!");
    }
  };

  function postData() {
    saveGeneralAssessment(finalData).then((response) => {
      successAlert(response.data.message);
      setTestList([]);
      setSliderReset(0);
      setOpenConfirmation(false);
      reset(defaultValues);
      setDeformity("");
      setSwelling("");
      setScar("");
      setErythma("");
      setWound("");
      setLyingID("");
      setSittingID("");
      setStandingID("");
      setWalkingID("");
      setLyingWW("");
      setSittingWW("");
      setStandingWW("");
      setWalkingWW("");
      setSearchId(null);
      setSelectedRow(null);
      setGeneralAssessmentList([]);
      getAssessmentList();
      setPrivilege("");
    });
  }

  useEffect(() => {
    getAssessmentList();
  }, []);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const [gender, setGender] = React.useState("male");
  return (
    <div className="mt-12 mb-2 space-y-2">
      <div className="text-lg font-semibold text-center w-full">
        General Assessment
      </div>
      <div className="flex space-x-3">
        <div className="space-y-4 w-full">
          <div className="flex justify-between items-center">
            <div className="flex space-x-4 items-center">
              <fieldset className="w-96" disabled={selectedRow !== null}>
                <SearchBar
                  name="searchPatient"
                  placeholder="Search By MRNo/Patient Name"
                  searchIcon={true}
                  handleInputChange={handleChange}
                  onChange={autoSelectedValue}
                  dataArray={options}
                />
              </fieldset>
              <fieldset disabled={searchId !== null}>
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={PatientType}
                />
              </fieldset>
            </div>
            <CommonButton
              type="buttton"
              label="Listing"
              className="bg-customBlue text-white"
              onClick={() => {
                setOpenGenAsseListModal(true);
              }}
            />
          </div>
          <div className="p-2 bg-[#F1F1F1] rounded-md grid lg:grid-cols-2 xl:grid xl:grid-cols-5">
            <div className="flex items-center xl:col-span-2">
              <div className="text-base font-semibold w-28">
                Patient Name &nbsp;
              </div>
              <span>
                :&nbsp;{searchId?.patientName || selectedRow?.["Patient Name"]}
              </span>
            </div>
            <div className="flex">
              <span className="text-base font-semibold w-28 lg:w-20">
                Address &nbsp;
              </span>
              <span>:&nbsp;{searchId?.Address || selectedRow?.Address}</span>
            </div>
            <div className="flex">
              <span className="text-base font-semibold w-28 xl:w-10">
                Age &nbsp;
              </span>
              <span>:&nbsp;{searchId?.AgeYear || selectedRow?.AgeYear}</span>
            </div>
            <div className="flex">
              <span className="text-base font-semibold w-28 lg:w-20 xl:w-16">
                Gender &nbsp;
              </span>
              <span>
                :&nbsp;
                {getPatientType === 0
                  ? searchId?.Gender
                  : searchId?.gender || selectedRow?.Gender}
              </span>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="grid lg:grid-cols-2 gap-x-2 gap-y-2">
          <div>
            <InputArea
              control={control}
              name="chiefComplaints"
              label="Chief Complaints"
              placeholder="Chief Complaints"
              minRows={2}
              maxRows={2}
              size="md"
            />
          </div>
          <div>
            <InputArea
              control={control}
              name="painAssessment"
              label="Pain Assessment"
              placeholder="Pain Assessment"
              minRows={2}
              maxRows={2}
              size="md"
            />
          </div>
          <div className="space-y-2">
            <div className="flex space-x-5 items-center">
              <label className="text-sm font-semibold w-16">Type</label>
              <div className="flex space-x-1 w-36 items-center">
                <CheckBox
                  name="intermittent"
                  checked={typeCheck === "Intermittent"}
                  onChange={() => setTypeCheck("Intermittent")}
                  size="small"
                />
                <label>Intermittent</label>
              </div>
              <div className="flex space-x-1 w-36 items-center">
                <CheckBox
                  name="continuous"
                  checked={typeCheck === "Continuous"}
                  onChange={() => setTypeCheck("Continuous")}
                  size="small"
                />
                <label>Continuous</label>
              </div>
            </div>
            <div className="flex space-x-5 items-center">
              <label className="text-sm font-semibold w-16">Pattern</label>
              <div className="flex space-x-1 w-36 items-center">
                <CheckBox
                  name="localized"
                  checked={patternCheck === "Localized"}
                  onChange={() => setPatternCheck("Localized")}
                  size="small"
                />
                <label>Localized</label>
              </div>
              <div className="flex space-x-1 w-36 items-center">
                <CheckBox
                  name="radiating"
                  checked={patternCheck === "Radiating"}
                  onChange={() => setPatternCheck("Radiating")}
                  size="small"
                />
                <label>Radiating</label>
              </div>
            </div>
          </div>
          <div className="space-y-1">
            <label className="text-base font-semibold">NAS</label>
            <div className="mx-5">
              <CustomSlider
                className="painScore"
                control={control}
                {...register("painScore")}
                name="painScore"
                onChange={(value) => {
                  if (searchId || selectedRow) {
                    setSliderReset(value.target.value);
                  } else {
                    warningAlert("Please Select Patient...!");
                  }
                }}
                aria-label="Custom marks"
                value={sliderReset !== null ? sliderReset : 0}
                getAriaValueText={valueText}
                min={0}
                step={1}
                max={10}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                style={{ height: "14px" }}
                marks={sliderMarks}
              />
            </div>
          </div>
          <div className="space-y-2">
            <InputArea
              control={control}
              name="medicalHistory"
              label="Medical History"
              placeholder="Medical History"
              minRows={2}
              maxRows={2}
              size="md"
            />
          </div>
          <div className="grid grid-cols-3 -mt-2.5">
            {MedicalCheck.map((val) => {
              return (
                <fieldset>
                  <CheckBoxField
                    control={control}
                    name={val.id}
                    label={val.label}
                  />
                </fieldset>
              );
            })}
          </div>
          <div className="space-y-1 lg:col-span-2">
            <label className="text-base font-semibold">Surgical History</label>
            {SurgicalHistory.map((val) => {
              return (
                <div className="w-full">
                  <InputArea
                    control={control}
                    name={val?.id}
                    label={val?.label}
                    placeholder={val?.label}
                    minRows={2}
                    maxRows={2}
                    size="sm"
                  />
                </div>
              );
            })}
          </div>
          <div className="space-x-2 w-full col-span-2 grid grid-cols-3">
            <div className="space-y-1 ">
              <label className="text-base font-semibold">On Observation</label>
              {ObservationCheck.map((val) => {
                return (
                  <div className="flex items-center space-x-2">
                    <label className="w-24 text-sm">{val.type}</label>
                    <div>
                      {val.positive === "Positive" && (
                        <fieldset className="flex space-x-2 items-center">
                          {/* <CheckBoxField
                            control={control}
                            name={`${val.type}Positive`}
                            label={val.positive}
                          /> */}
                          <CheckBox
                            name={`${val.type}Positive`}
                            size="small"
                            checked={
                              val.type === "Deformity"
                                ? deformity === "Positive"
                                : val.type === "Swelling"
                                ? swelling === "Positive"
                                : val.type === "Scar"
                                ? scar === "Positive"
                                : val.type === "Erythma"
                                ? erythma === "Positive"
                                : val.type === "Wound"
                                ? wound === "Positive"
                                : ""
                            }
                            onChange={() =>
                              val.type === "Deformity"
                                ? setDeformity("Positive")
                                : val.type === "Swelling"
                                ? setSwelling("Positive")
                                : val.type === "Scar"
                                ? setScar("Positive")
                                : val.type === "Erythma"
                                ? setErythma("Positive")
                                : val.type === "Wound"
                                ? setWound("Positive")
                                : ""
                            }
                          />
                          <label>{val.positive}</label>
                        </fieldset>
                      )}
                    </div>
                    <div>
                      {val.negative === "Negative" && (
                        <fieldset className="flex space-x-2 items-center">
                          {/* <CheckBoxField
                            control={control}
                            name={`${val.type}Negative`}
                            label={val.negative}
                          /> */}
                          <CheckBox
                            name={`${val.type}Negative`}
                            size="small"
                            checked={
                              val.type === "Deformity"
                                ? deformity === "Negative"
                                : val.type === "Swelling"
                                ? swelling === "Negative"
                                : val.type === "Scar"
                                ? scar === "Negative"
                                : val.type === "Erythma"
                                ? erythma === "Negative"
                                : val.type === "Wound"
                                ? wound === "Negative"
                                : ""
                            }
                            onChange={() =>
                              val.type === "Deformity"
                                ? setDeformity("Negative")
                                : val.type === "Swelling"
                                ? setSwelling("Negative")
                                : val.type === "Scar"
                                ? setScar("Negative")
                                : val.type === "Erythma"
                                ? setErythma("Negative")
                                : val.type === "Wound"
                                ? setWound("Negative")
                                : ""
                            }
                          />
                          <label>{val.negative}</label>
                        </fieldset>
                      )}
                    </div>
                  </div>
                );
              })}
              <div>
                <InputArea
                  control={control}
                  name="observationRemarks"
                  label="Remarks"
                  placeholder="Remarks"
                  minRows={2}
                  maxRows={2}
                  size="sm"
                />
              </div>
            </div>
            <div className="space-y-2 col-span-2">
              <label className="text-base font-semibold">On Palpation</label>
              {OnPalpation.map((val) => {
                return (
                  <div className="w-full">
                    <InputArea
                      control={control}
                      name={val?.id}
                      label={val?.label}
                      placeholder={val?.label}
                      minRows={2}
                      maxRows={2}
                      size="sm"
                    />
                  </div>
                );
              })}
              <div className="flex space-x-3 items-center w-full">
                <label className="text-sm font-semibold w-40">
                  Limb Length
                </label>
                <div className="flex space-x-3 items-center">
                  <label className="text-sm">Right</label>
                  <div className="w-24">
                    <InputField
                      control={control}
                      name="rightLimb"
                      label="Right Limb"
                    />
                  </div>
                  <label className="text-sm">Left</label>
                  <div className="w-24">
                    <InputField
                      control={control}
                      name="leftLimb"
                      label="Left Limb"
                    />
                  </div>
                </div>
              </div>
              <div className="-mt-4">
                <InputArea
                  control={control}
                  name="palpationRemarks"
                  label="Remarks"
                  placeholder="Remarks"
                  minRows={2}
                  maxRows={2}
                  size="sm"
                />
              </div>
            </div>
          </div>
          <div className="space-y-2 col-span-2">
            <div className="flex space-x-2 items-center">
              <label className="text-base font-semibold w-36">
                On Examination :
              </label>
              <label>Joint Name &nbsp;&nbsp;</label>
              <div className="w-96">
                <InputField
                  control={control}
                  name="jointName"
                  label="Joint Name"
                />
              </div>
            </div>
            <div>
              <div className="grid grid-cols-6 gap-x-2 gap-y-3">
                {OnExamination.map((val) => {
                  return (
                    <div>
                      {val.type === "text" && (
                        <label className="text-sm font-semibold">
                          {val?.label}
                        </label>
                      )}
                      {val.type === "input" && (
                        <InputArea
                          control={control}
                          name={val?.name}
                          label={val?.label}
                          placeholder={val?.label}
                          minRows={2}
                          maxRows={2}
                          size="sm"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="space-y-2">
              {OnExaminationInput.map((val) => {
                return (
                  <div className="w-full">
                    <InputArea
                      control={control}
                      name={val?.id}
                      label={val?.label}
                      placeholder={val?.label}
                      minRows={2}
                      maxRows={2}
                      size="sm"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="space-y-2">
            <label className="text-base font-semibold">
              Special Tests, If Any
            </label>
            <div className="flex space-x-2">
              <div className="w-full">
                <InputField
                  control={control}
                  name="testName"
                  label="Test Name"
                />
              </div>
              <div className="flex space-x-2">
                <div className="flex items-center">
                  <CheckBox
                    name="positiveTest"
                    checked={specialTestCheck === "Positive"}
                    onChange={() => setSpecialTestCheck("Positive")}
                    size="small"
                  />
                  <label>Positive</label>
                </div>
                <div className="flex items-center">
                  <CheckBox
                    name="negativeTest"
                    checked={specialTestCheck === "Negative"}
                    onChange={() => setSpecialTestCheck("Negative")}
                    size="small"
                  />
                  <label>Negative</label>
                </div>
                <CommonButton
                  type="button"
                  label="Add"
                  className="border border-customBlue text-customBlue"
                  onClick={() => {
                    if (getTestName !== "" && specialTestCheck !== "") {
                      addSpecialTest();
                      setValue("testName", "");
                      setSpecialTestCheck("");
                    }
                  }}
                />
              </div>
            </div>
            {testList !== undefined && testList?.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={testList}
                tableClass={"h-40"}
                renderInput={renderInput}
                editableColumns={["Positive", "Negative"]}
                highlightRow={false}
                removeHeaders={["Id", "generalAssDtlsId"]}
              />
            ) : (
              <div className="justify-center items-center font-semibold md:h-40 2xl:h-72 w-full flex border-2 border-gray-300 rounded my-2">
                No Records Found...
              </div>
            )}
            <div>
              <InputArea
                control={control}
                name="specialTestRemark"
                label="Remark"
                placeholder="Remark"
                minRows={2}
                maxRows={2}
                size="sm"
              />
            </div>
          </div>
          <div className="space-y-1 ml-4">
            <label className="text-base font-semibold">
              Functional Activites
            </label>
            <div className="">
              <div className="flex space-x-10">
                <div className="flex space-x-2 relative left-20">
                  {FunctionalityIDCheck.map((val) => {
                    return (
                      <div className="flex space-x-2">
                        {val?.type === "IDHeading" && (
                          <label className="text-sm font-semibold">
                            {val?.label}
                          </label>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex space-x-2">
                  {FunctionalityIDCheck.map((val) => {
                    return (
                      <div className="flex">
                        {val?.type === "WWHeading" && (
                          <label className="text-sm font-semibold">
                            {val?.label}
                          </label>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex ">
                <div className="space-x-1 space-y-1.5">
                  {FunctionalityIDCheck.map((val) => {
                    return (
                      <div className="flex space-x-10 items-center">
                        {val?.type === "rowHeading" && (
                          <label className="text-sm font-semibold w-16">
                            {val?.label}
                          </label>
                        )}
                        <div className="flex space-x-10">
                          <div>
                            {val?.independent === "Independent" && (
                              <CheckBox
                                name={`${val?.type}Independent`}
                                size="small"
                                checked={
                                  val?.label === "Lying"
                                    ? lyingID === "Independent"
                                    : val?.label === "Sitting"
                                    ? sittingID === "Independent"
                                    : val?.label === "Standing"
                                    ? standingID === "Independent"
                                    : val?.label === "Walking"
                                    ? walkingID === "Independent"
                                    : ""
                                }
                                onChange={() =>
                                  val?.label === "Lying"
                                    ? setLyingID("Independent")
                                    : val?.label === "Sitting"
                                    ? setSittingID("Independent")
                                    : val?.label === "Standing"
                                    ? setStandingID("Independent")
                                    : val?.label === "Walking"
                                    ? setWalkingID("Independent")
                                    : ""
                                }
                              />
                            )}
                          </div>
                          <div>
                            {val?.dependent === "Dependent" && (
                              <CheckBox
                                name={`${val?.type}Dependent`}
                                size="small"
                                checked={
                                  val?.label === "Lying"
                                    ? lyingID === "Dependent"
                                    : val?.label === "Sitting"
                                    ? sittingID === "Dependent"
                                    : val?.label === "Standing"
                                    ? standingID === "Dependent"
                                    : val?.label === "Walking"
                                    ? walkingID === "Dependent"
                                    : ""
                                }
                                onChange={() =>
                                  val?.label === "Lying"
                                    ? setLyingID("Dependent")
                                    : val?.label === "Sitting"
                                    ? setSittingID("Dependent")
                                    : val?.label === "Standing"
                                    ? setStandingID("Dependent")
                                    : val?.label === "Walking"
                                    ? setWalkingID("Dependent")
                                    : ""
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex space-x-10 pl-5 justify-center">
                          <div>
                            {val?.withAid === "WithAid" && (
                              <CheckBox
                                name={`${val?.type}WithAid`}
                                size="small"
                                checked={
                                  val?.label === "Lying"
                                    ? lyingWW === "WithAid"
                                    : val?.label === "Sitting"
                                    ? sittingWW === "WithAid"
                                    : val?.label === "Standing"
                                    ? standingWW === "WithAid"
                                    : val?.label === "Walking"
                                    ? walkingWW === "WithAid"
                                    : ""
                                }
                                onChange={() =>
                                  val?.label === "Lying"
                                    ? setLyingWW("WithAid")
                                    : val?.label === "Sitting"
                                    ? setSittingWW("WithAid")
                                    : val?.label === "Standing"
                                    ? setStandingWW("WithAid")
                                    : val?.label === "Walking"
                                    ? setWalkingWW("WithAid")
                                    : ""
                                }
                              />
                            )}
                          </div>
                          <div>
                            {val?.withoutAid === "WithoutAid" && (
                              <CheckBox
                                name={`${val?.type}WithoutAid`}
                                size="small"
                                checked={
                                  val?.label === "Lying"
                                    ? lyingWW === "WithoutAid"
                                    : val?.label === "Sitting"
                                    ? sittingWW === "WithoutAid"
                                    : val?.label === "Standing"
                                    ? standingWW === "WithoutAid"
                                    : val?.label === "Walking"
                                    ? walkingWW === "WithoutAid"
                                    : ""
                                }
                                onChange={() =>
                                  val?.label === "Lying"
                                    ? setLyingWW("WithoutAid")
                                    : val?.label === "Sitting"
                                    ? setSittingWW("WithoutAid")
                                    : val?.label === "Standing"
                                    ? setStandingWW("WithoutAid")
                                    : val?.label === "Walking"
                                    ? setWalkingWW("WithoutAid")
                                    : ""
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="">
              <InputArea
                control={control}
                name="functionalActivityRemark"
                label="Remarks"
                placeholder="Remarks"
                minRows={2}
                maxRows={2}
                size="sm"
              />
            </div>
          </div>
          <div className="col-span-2 space-y-2">
            <label className="text-base font-semibold">Investigation</label>
            {InvestigationInput.map((val) => {
              return (
                <div className="w-full">
                  <InputArea
                    control={control}
                    name={val?.id}
                    label={val?.label}
                    placeholder={val?.label}
                    minRows={2}
                    maxRows={2}
                    size="sm"
                  />
                </div>
              );
            })}
          </div>
          {userActions?.map((action) => (
            <>
              {action.action === "Create" && action.isAction === false && (
                <div className="space-x-2 flex justify-end col-span-2">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                      setSliderReset(0);
                      setDeformity("");
                      setSwelling("");
                      setScar("");
                      setErythma("");
                      setWound("");
                      setLyingID("");
                      setSittingID("");
                      setStandingID("");
                      setWalkingID("");
                      setLyingWW("");
                      setSittingWW("");
                      setStandingWW("");
                      setWalkingWW("");
                      setGeneralAssessmentList([]);
                      getAssessmentList();
                      setTestList([]);
                      setSearchId(null);
                      setSelectedRow(null);
                      setSpecialTestCheck("");
                      setPatternCheck("");
                      setTypeCheck("");
                      setPrivilege("");
                    }}
                  />
                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                    onClick={() => {
                      setPrivilege(action.action);
                    }}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      </form>
      <GenerralAssessmentListingModal
        openGenAsseListModal={openGenAsseListModal}
        setOpenGenAsseListModal={setOpenGenAsseListModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      <ConfirmationModal
        confirmationOpen={openConfirmation}
        confirmationHandleClose={() => {
          setOpenConfirmation(false);
        }}
        confirmationSubmitFunc={postData}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to add record ?"
        confirmationButtonMsg="Yes"
      />
      {openPrintModal && renderPrint()}
    </div>
  );
};

export default GeneralAssessment;
