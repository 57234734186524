import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import InputField from "../../../../common/components/FormFields/InputField";

const HistorySheet = () => {
  const {
    control,
  } = useFormContext();

  return (
    <>
      <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
          {/* ///Chief Complaint/// */}
          <div>
            <InputArea
              name="chiefComplaint"
              label="Chief Complaint"
              placeholder="Chief Complaint"
              control={control}
              rows={3}
            />
          </div>
          <div>
            {/* //History of Present Illness// */}

            <InputArea
              name="historyOfPresentIllness"
              label="History Of Present Illness"
              placeholder="History Of Present Illness"
              control={control}
              rows={3}
            />
          </div>
          {/* //Surgical History// */}
          <div className=" ">
            <InputArea
              name="surgicalHistory"
              label="Surgical History"
              placeholder="Surgical History"
              control={control}
              rows={3}
            />
          </div>
          {/* //Treatment History// */}
          <div className="lg:col-span-2 ">
            <InputArea
              name="treatmentHistory"
              label="Treatment History"
              placeholder="Treatment History"
              control={control}
              rows={3}
            />
          </div>
          {/* //Family History// */}
          <div className=" ">
            <InputArea
              name="familyHistory"
              label="Family History"
              placeholder="Family History"
              control={control}
              rows={3}
            />
          </div>
        </div>
        <div className="font-bold tracking-wide text-sm font-Poppins mt-3">
          Past History
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-3 pt-3">
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="dm" label="DM" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="dmDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="ihd" label="IHD" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="ihdDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="asthama" label="Asthma" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="asthamaDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="htn" label="HTN" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="htnDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div>
              <CheckBoxField control={control} name="copd" label="COPD" />
            </div>
            <div className="col-span-2 ">
              <InputField
                name="copdDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="grid grid-cols-2">
            <div>
              <CheckBoxField
                control={control}
                name="tuberculosis"
                label="Tuberculosis"
              />
            </div>
            <div className="">
              <InputField
                name="tuberculosisDtls"
                variant="outlined"
                // label="Family History"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 col-span-2">
            <div className="col-span-2 ">
              <CheckBoxField control={control} name="stroke" label="Stroke" />
            </div>
            <div className="col-span-10 ">
              <InputArea
                name="otherDtls"
                variant="outlined"
                label="Other Details"
                // error={errors.representativeAddress}
                placeholder="Other Details"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistorySheet;
