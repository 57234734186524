import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Checkbox, Modal, TextField, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  BarcodeOnIcon,
  CloseIcon,
  DispatchOffIcon,
  DispatchOnIcon,
} from "../../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePaginationNew from "../../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import RadioFeild from "../../../../../common/components/FormFields/RadioField";
import { warningAlert } from "../../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../../common/components/loadingspinner/loadingSpinner";
import { blobToBase64 } from "../../../../../common/components/printModal/BlobToB64";
import CommonPrintModal from "../../../../../common/components/printModal/CommonPrintModal";
import NumberOfCopiesModal from "../../../../../common/components/printModal/NumberOfCopiesModal";
import { routeToPrinterDevice } from "../../../../../commonServices/barCodeServices/BarCodeServices";
import { fetchUnit } from "../../../../../commonServices/miscellaneousServices/MiscServices";
import {
  dietOrderViewModule,
  diticianModuleList,
  fetchBarcodeDietationInfo,
  fetchDitician,
  fetchPrinDietOrderOForm,
  updateDietitianModuleStatus,
} from "../../../../services/diet/dietetianmoduleservices/DietetianModuleServices";
import TableDropDownField from "../../../../../common/components/FormFields/TableDropDownField";
import CreateableSelect from "../../../../../common/components/FormFields/CreateableSelect";

const DietRadioButton = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Open",
    value: "Open",
    label: "Open",
  },
  {
    id: "Dispatched",
    value: "Dispatched",
    label: "Dispatched",
  },
];

let spinner = false;

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 1,
};

export default function DietetianModule(props) {
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  const {
    selectAllFlag,
    setSelectAllFlag,
    setFromDate,
    setToDate,
    selectedUnit,
    setSelectedUnit,
    setPopulateDietTale,
    populateDietTale,
    dieOptions,
    dietPlan,
    dietTypes,
    allergiesTo,
    foodTypes,
    dietOptions,
    dietationModuleList,
    setDietatianModuleList,
  } = props;

  const defaultValues = {
    ipdOpdObj: "All",
    unit: null,
    dietOptions: null,
    fromDateCalender: new Date(),
    toDateCalender: new Date(),
  };
  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    watch,
    setValue,
    formState: { errors },
    control,
    register,
  } = methods;

  const [dataResult, setDataResult] = useState([]);
  const [unit, setUnit] = useState(null);
  const [ditician, setDitician] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState();
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [barcodeResponse, setBarcodeResponse] = useState(null);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [inputType, setInputType] = useState("");
  const [openInputEntryModal, setOpenInputEntryModal] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");

  //backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const location = useLocation();
  let unitObj = {
    id: userInfo.unitId,
    value: userInfo.unitId,
    label: userInfo.unitName,
  };
  let unitVal = watch("unit");
  let dietOptionsVal = watch("dietOptions");
  let ipdOpdObjVal = watch("ipdOpdObj");
  let fromDateCalender = watch("fromDateCalender");
  let toDateCalender = watch("toDateCalender");

  let status;
  if (ipdOpdObjVal === "All") {
    status = 2;
  } else if (ipdOpdObjVal === "Open") {
    status = 0;
  } else if (ipdOpdObjVal === "Dispatched") {
    status = 1;
  }

  const populateTable = (forPagination) => {
    spinner = true;
    setFromDate && setFromDate(fromDateCalender);
    setToDate && setToDate(toDateCalender);
    setSelectedUnit && setSelectedUnit(unitVal !== null ? unitVal.id : 0);
    setPopulateDietTale && setPopulateDietTale(false);
    if (!forPagination) {
      // spinner = true;
    }

    let defaultParams = {
      // dietOption: dietOptionsVal ? dietOptionsVal?.id : 0,
      dietOption: dietOptionsVal?.id,
      fromDate: fromDateCalender,
      status: status,
      toDate: toDateCalender,
      unitId: unitVal !== null ? unitVal.id : 0,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      menuId: props.viewOrderMenuId
        ? props.viewOrderMenuId
        : location.state.menuId,
    };

    if (props?.isFromDietOrder === true) {
      dietOrderViewModule(defaultParams)
        .then((response) => {
          setCount(response.data.count);
          return response.data;
        })
        .then((res) => {
          if (res.statusCode === 200) {
            // setDataResult(res.result);
            props.setListActions(res.actions);
            spinner = false;

            if (forPagination) {
              let updatedResult = selectAllFlag
                ? res.result.map((item) => ({
                    ...item,
                    checked: true,
                    isDisable: true,
                    AllergicTo: JSON.parse(item.AllergicTo),
                  }))
                : res.result.map((item) => ({
                    ...item,
                    AllergicTo: JSON.parse(item.AllergicTo),
                  }));
              setDataResult((prevData) => [...prevData, ...updatedResult]);
            } else {
              setDataResult(
                res.result.map((item) => ({
                  ...item,
                  AllergicTo: JSON.parse(item.AllergicTo),
                }))
              );
              setPage(0);
            }
            setUserActions(res.actions);
          }
        });
    } else {
      diticianModuleList(defaultParams)
        .then((response) => {
          setCount(response.data.count);
          return response.data;
        })
        .then((res) => {
          if (res.statusCode === 200) {
            // setDataResult(res.result);
            props.setListActions && props.setListActions(res.actions);
            spinner = false;
            if (forPagination) {
              let updatedResult = selectAllFlag
                ? res.result.map((item) => ({
                    ...item,
                    checked: true,
                    isDisable: true,
                  }))
                : res.result;
              setDataResult((prevData) => [...prevData, ...updatedResult]);
            } else {
              setDataResult(res.result);
              setPage(0);
            }
            setUserActions(res.actions);
          }
        });
    }
  };

  function handleChange(tableDataResult) {
    let orderDtlsArr = [];
    for (let i = 0; i < tableDataResult.length; i++) {
      if (tableDataResult[i].checked === true) {
        orderDtlsArr.push(tableDataResult[i].DietChartId);
      }
    }
    props.setDietOrderIdArray(orderDtlsArr);
  }

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(barcodeResponse);

    const obj = [
      {
        printerName: defaultPrinters?.barCodePrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "Barcode",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setBarcodeResponse(null);
    });
  };

  const handleOpenPrintModal = (row, type) => {
    const printObj = {
      dietChartIdList: !selectAllFlag ? props.dietOrderIdArray : [],
      fromDate: fromDateCalender,
      isSelectAll: selectAllFlag,
      toDate: toDateCalender,
      unitId: unitVal !== null ? unitVal.id : 0,
    };

    type === "print" &&
      fetchPrinDietOrderOForm(printObj).then((response) => {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      });

    if (type === "barcode") {
      // let barcodeObj = [row.AdmissionId];
      let barcodeObj = [
        {
          admissionId: row.AdmissionId,
          dietChartId: row.DietChartId,
          dietOptionId: dieOptions?.id,
        },
      ];

      fetchBarcodeDietationInfo(barcodeObj).then((response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setBarcodeResponse(response);
        } else {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });
    }
  };

  useEffect(() => {
    populateDietTale === true && populateTable(false);
  }, [populateDietTale]);

  const renderInput = (row, index, header) => {
    let patchDietPlan = {
      id: row?.DietPlanId,
      value: row?.DietPlanId,
      label: row?.DietPlanName,
    };
    let patchFoodType = {
      id: row?.FoodTypeid,
      value: row?.FoodTypeid,
      label: row?.FoodType,
    };
    let patchDietType = {
      id: row?.DietTypeId,
      value: row?.DietTypeId,
      label: row?.DietType,
    };

    let allergicTo = row?.AllergicTo?.map((val) => ({
      ...val,
      value: val?.id,
      label: val?.description !== null ? val?.description : "",
    }));
    return (
      <>
        {header === "Status" && (
          <div
            className={` ${
              row["Status"] === 0 ? "text-red-500" : "text-green-500"
            }`}
          >
            {row["Status"] === 0 ? "open" : "Dispatched"}
          </div>
        )}
        {header === "DietPlanName" && (
          <TableDropDownField
            width={60}
            control={control}
            defaultValue={patchDietPlan?.id !== null ? patchDietPlan : null}
            name={`DietPlanName${row?.DietChartId}`}
            isClearable={true}
            isSearchable={true}
            dataArray={dietPlan}
            isDisabled={row?.checked === false}
            inputRef={{
              ...register(`DietPlanName${row?.DietChartId}`, {
                onChange: (e) => {
                  let tempDataResult = [...dataResult];
                  for (let obj of tempDataResult) {
                    if (obj?.DietChartId === row?.DietChartId) {
                      obj.DietPlanId = e.target.value?.id;
                      obj.DietPlanName = e.target.value?.label;
                    }
                    setDataResult(tempDataResult);
                  }
                },
              }),
            }}
          />
        )}
        {header === "DietType" && (
          <TableDropDownField
            width={60}
            control={control}
            defaultValue={patchDietType.id !== null ? patchDietType : null}
            name={`DietTypeName${row?.DietChartId}`}
            isClearable={true}
            isSearchable={true}
            dataArray={dietTypes}
            isDisabled={row?.checked === false}
            inputRef={{
              ...register(`DietTypeName${row?.DietChartId}`, {
                onChange: (e) => {
                  let tempDataResult = [...dataResult];
                  for (let obj of tempDataResult) {
                    if (obj?.DietChartId === row?.DietChartId) {
                      obj.DietTypeId = e.target.value?.id;
                      obj.DietType = e.target.value?.label;
                    }
                    setDataResult(tempDataResult);
                  }
                },
              }),
            }}
          />
        )}
        {header === "FoodType" && (
          <TableDropDownField
            width={60}
            control={control}
            defaultValue={patchFoodType.id !== null ? patchFoodType : null}
            name={`FoodTypeName${row?.DietChartId}`}
            isClearable={true}
            isSearchable={true}
            dataArray={foodTypes}
            isDisabled={row?.checked === false}
            inputRef={{
              ...register(`FoodTypeName${row?.DietChartId}`, {
                onChange: (e) => {
                  let tempDataResult = [...dataResult];
                  for (let obj of tempDataResult) {
                    if (obj?.DietChartId === row?.DietChartId) {
                      obj.FoodTypeid = e.target.value?.id;
                      obj.FoodType = e.target.value?.label;
                    }
                    setDataResult(tempDataResult);
                  }
                },
              }),
            }}
          />
        )}
        {header === "remark" && (
          <input
            type="text"
            // {...register(`Instructions${index}`)}
            className="border rounded border-[#6e7070]  h-[30px] px-1"
            size="small"
            name={`Instructions${row?.DietChartId}`}
            value={row?.remark || ""}
            disabled={row?.checked === false}
            onClick={() => {
              setSelectedRowId(row?.DietChartId);
              setInputType("remark");
              setOpenInputEntryModal(true);
              setEnteredValue(row?.remark);
            }}
            // onChange={handleChange}
            // onClick={(e) => {
            //   props.setSelectedRowIndex(row);
            //   props.setInputInstructonValue(row?.Remark);

            //   handleInstruction();
            //   setSelectedIndex(index);
            //   setSelectedRow(row);
            // }}
          />
        )}
        {header === "Instructions" && (
          <input
            type="text"
            {...register(`Instructions${index}`)}
            className="border rounded border-[#6e7070] w-25 h-[30px] px-1"
            size="small"
            name={`Instructions${index}`}
            value={row?.Instructions || ""}
            disabled={row?.checked === false}
            onClick={() => {
              setSelectedRowId(row?.DietChartId);
              setInputType("instruction");
              setOpenInputEntryModal(true);
              setEnteredValue(row?.Instructions);
            }}
            // onChange={handleChange}
            // onClick={(e) => {
            //   props.setSelectedSpecialRowIndex(row);
            //   props.setInputSpecialInstValue(row?.["Special Instructions"]);

            //   handleSpecialInstruction();
            //   setSelectedSpecialIndex && setSelectedSpecialIndex(index);
            //   setSelectedRow(row);
            // }}
          />
        )}
        {header === "AllergicTo" && (
          <div className="w-48">
            <CreateableSelect
              control={control}
              defaultValue={allergicTo?.filter((val) => val?.label !== "")}
              name={`AllergicTo${row?.DietChartId}`}
              menuPlacement={"bottom"}
              isClearable={true}
              isSearchable={true}
              dataArray={allergiesTo}
              placeholder={""}
              isMulti={true}
              maxMenuHeight={200}
              isDisabled={row?.checked === false}
              inputRef={{
                ...register(`AllergicTo${row?.DietChartId}`, {
                  onChange: (e) => {
                    if (e.target.value) {
                      let tempDataResult = [...dataResult];
                      for (let obj of tempDataResult) {
                        if (obj?.DietChartId === row?.DietChartId) {
                          obj.AllergicTo = e.target.value;
                        }
                        setDataResult(tempDataResult);
                      }
                    }
                  },
                }),
              }}
            />
          </div>
        )}
      </>
    );
  };

  const renderTableHeader = () => {
    return (
      <>
        {props.viewOrderModal && (
          <div className="flex gap-3 ml-2">
            <Checkbox
              size="small"
              className="w-4 h-4"
              type="checkbox"
              color="primary"
              onChange={(e) => {
                setSelectAllFlag(e.target.checked);
                let temp = [...dataResult];
                let updatedTemp = temp.map((item) => ({
                  ...item,
                  checked: e.target.checked,
                  isDisable: e.target.checked,
                }));
                setDataResult(updatedTemp);
                handleChange(updatedTemp);
              }}
              name={`isSelectAll`}
            />
            <p className="text-gray-600 font-bold">Select All</p>
          </div>
        )}
      </>
    );
  };

  const handleDispatchStatus = () => {
    setOpenBackdrop(true);
    updateDietitianModuleStatus(
      selectedRow?.DietChartId,
      location?.state?.menuId,
      privilege
    )
      .then((response) => response.data)
      .then((res) => {
        populateTable(false);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      })
      .catch(() => setOpenBackdrop(false));
  };

  useEffect(() => {
    if (props.isFromDietOrder === true) {
      setDietatianModuleList(dataResult);
    }
  }, [dataResult]);

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3 items-center">
        <>
          <div className="flex pt-1 justify-center gap-2 cursor-pointer items-center">
            <>
              {props.viewOrderModal ? (
                <Checkbox
                  size="small"
                  className="w-4 h-4"
                  type="checkbox"
                  color="primary"
                  onChange={(e) => {
                    let temp = [...dataResult];
                    let updated = temp.map((item) =>
                      item.DietChartId === row.DietChartId
                        ? { ...item, checked: e.target.checked }
                        : { ...item }
                    );
                    setDataResult(updated);

                    handleChange(updated);
                  }}
                  checked={row.checked ? true : false}
                  name={`indentCheckBox${index}`}
                  disabled={row.isDisable ? true : false}
                />
              ) : null}

              <BarcodeOnIcon
                title={"Barcode"}
                onClick={() => {
                  handleOpenPrintModal(row, "barcode");
                }}
              />

              {userActions.map((item) => (
                <>
                  {item.action === "Dispatch" && item.isAction && (
                    <div
                      className={
                        row.Status === 0 ? "text-customGreen" : "text-gray-400"
                      }
                    >
                      {row.Status === 0 ? (
                        <DispatchOnIcon
                          title="Dispatch Diet"
                          onClick={() => {
                            if (row.Status === 0) {
                              setOpenConfirmationModal(true);
                              setSelectedRow(row);
                              setPrivilege(item.action);
                            }
                          }}
                        />
                      ) : (
                        <DispatchOffIcon />
                      )}
                    </div>
                  )}
                </>
              ))}
            </>
          </div>
        </>
      </div>
    );
  };

  useEffect(() => {
    setValue("unit", unitObj);

    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);
      });

    fetchDitician()
      .then((response) => response.data)
      .then((res) => {
        setDitician(res.result);
      });

    populateTable(false);
  }, []);

  useEffect(() => {
    for (let i = 0; i < dataResult.length; i++) {
      if (dataResult[i].checked === undefined) {
        dataResult[i].checked = false;
      }
    }
  }, [dataResult]);

  useEffect(() => {
    if (unitVal !== null && unitVal !== undefined) {
      populateTable(false);
    }
  }, [unitVal]);

  console.log("dataResult123", dataResult);

  return (
    <>
      <div
        className={`w-full grid  ${
          !props.isFromDietOrder ? "mt-14" : "mt-2"
        } overflow-hidden`}
      >
        <div className="text-center text-black 2 font-bold text-lg">
          {!props.isFromDietOrder ? "Dietitian Order" : "Diet Order"}
        </div>

        <div className="">
          <div className=" xl:grid-cols-6  md:grid md:grid-cols-3  px-2 gap-3 items-center">
            {!props.isFromDietOrder && (
              <div className="flex w-full justify-center mt-1 space-x-3 xl:col-span-1  text-sm">
                <span className="font-semibold whitespace-nowrap">
                  Status :
                </span>
                <RadioFeild
                  control={control}
                  name="ipdOpdObj"
                  dataArray={DietRadioButton}
                />
              </div>
            )}
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="fromDateCalender"
                label="From Date"
                value={new Date()}
                disablePast={false}
                disableFuture={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="toDateCalender"
                label="To Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("toDateCalender", newValue);
                }}
                disablePast={false}
                disableFuture={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            {!props.isFromDietOrder && (
              <>
                <div className="">
                  <DropdownField
                    control={control}
                    error={errors.unit}
                    name="unit"
                    dataArray={unit}
                    placeholder="Select Unit"
                    isClearable={true}
                  />
                </div>
                <div className="">
                  <DropdownField
                    control={control}
                    error={errors.dietOptions}
                    name="dietOptions"
                    dataArray={ditician}
                    isClearable={true}
                    placeholder="Select Diet Options"
                  />
                </div>
              </>
            )}
            <div
              className={
                !props.isFromDietOrder
                  ? "flex justify-between items-center"
                  : "lg:col-span-4 flex justify-between items-center"
              }
            >
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                searchIcon={true}
                onClick={() => populateTable()}
              />

              {props.viewOrderModal && (
                <Tooltip title="Print" arrow>
                  <LocalPrintshopIcon
                    sx={{ color: "#4B5563" }}
                    onClick={(row) => {
                      if (props.dietOrderIdArray?.length > 0 || selectAllFlag) {
                        handleOpenPrintModal(row, "print");
                      } else {
                        warningAlert("Kindly Select Checkbox...!");
                      }
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {spinner ? (
          <div className=" grid justify-center items-center my-32">
            <LoadingSpinner />
          </div>
        ) : dataResult?.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            setDataResult={setDataResult}
            actionsRequired={props.viewOrderModal ? true : null}
            populateTable={populateTable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
            //renderActions={props.viewOrderModal ? renderActions : null}
            renderActions={renderActions}
            renderInput={renderInput}
            editableColumns={[
              "Status",
              "DietPlanName",
              "DietType",
              "FoodType",
              "remark",
              "Instructions",
              "AllergicTo",
              "Diagnosis",
            ]}
            renderTableHeader={renderTableHeader}
            highlightRow={false}
            tableClass={"h-96"}
            removeHeaders={[
              "Id",
              "checked",
              "FoodTypeid",
              "DietOptionID",
              "DietChartId",
              "AdmissionId",
              "isDisable",
              "IsAllergic",
            ]}
          />
        ) : (
          <h3 className="flex justify-center my-20 font-bold text-gray-600">
            No Records Found...
          </h3>
        )}
      </div>
      <Modal open={openInputEntryModal}>
        <Box sx={ModalStyle}>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                setOpenInputEntryModal(false);
                setInputType("");
                setEnteredValue("");
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex space-x-2">
            <div className="w-full">
              <TextField
                fullWidth
                id="outlined-multiline-flexible"
                label="Remark"
                name="instructions"
                value={enteredValue}
                onChange={(e) => {
                  setEnteredValue(e.target?.value);
                }}
                multiline
                inputProps={{
                  style: { overflow: "hidden" },
                  width: "100%",
                }}
              />
            </div>
            <div className="flex items-end">
              <CommonButton
                type="button"
                label="Ok"
                className="bg-customGreen text-white "
                onClick={() => {
                  let tempDataResult = [...dataResult];
                  for (let obj of tempDataResult) {
                    if (obj?.DietChartId === selectedRowId) {
                      if (inputType === "remark") {
                        obj.remark = enteredValue;
                      } else if (inputType === "instruction") {
                        obj.Instructions = enteredValue;
                      }
                    }
                    setDataResult(tempDataResult);
                  }
                  setOpenInputEntryModal(false);
                  setInputType("");
                  setEnteredValue("");
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleDispatchStatus()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}
