import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Tooltip } from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import useBase64Converter from "../../../common/components/Custom Hooks/Base64Conversion";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchDepartment } from "../../../commonServices/miscellaneousServices/MiscServices";
import { saveConsentTemplete } from "../../services/operationTheater/ConsentMasterServices";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import InputArea from "../../../common/components/FormFields/InputArea";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "95%",
  height: "90%",
  p: 3,
  overflowY: "scroll",
};

const config2 = {
  readonly: false,
  height: 300,
  allowResizeY: false,
  statusbar: false,
  buttons: [
    "bold",
    "underline",
    "strikethrough",
    "italic",
    "indent",
    "outdent",
    "fontsize",
    "paragraph",
    "brush",
    "|",
    "align",
    "ul",
    "ol",
    "table",
    "hr",
    "symbol",
    "eraser",
    "copyformat",
    "superscript",
    "subscript",
    "undo",
    "redo",
    "find",
    "preview",
    "print",
    "image",
  ],
};

const OtConcentMasterModal = (props) => {
  const schema = yup.object().shape({
    department: yup.object().nullable().required("Required"),
    templateName: yup.string().required("Required"),
  });

  const { base64Data, convertFilesToBase64 } = useBase64Converter();

  const defaultValues = {
    templateCode: "",
    templateName: "",
    radiologist: null,
    gender: null,
    testResults: null,
    templateDescription: "",
    languageName: 0,
    //new
    isCertificate: false,
  };
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  //
  const textareaRef = useRef(null);
  //
  const [openTextModal, setOpenTextModal] = useState(false);
  const [content1, setContent1] = useState(null);
  const [content2, setContent2] = useState(null);
  const [content3, setContent3] = useState(null);
  const [content4, setContent4] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [finalData, setFinalData] = useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [departmentSearchString, setDepartmentSearchString] = useState();
  const [department, setDepartment] = useState();
  const {
    openTemplateModal,
    handleCloseModal,
    editRowData,
    setEditRowData,
    populateTable,
  } = props;
  //
  let editor1 = useRef(null);
  let editor2 = useRef(null);
  let editor3 = useRef(null);
  let editor4 = useRef(null);
  //
  const handleCloseConfirmation = () => {
    setOpenConfirmModal(false);
  };

  // Api to Get department
  useEffect(() => {
    departmentSearchString &&
      fetchDepartment(departmentSearchString).then((response) => {
        setDepartment(response.data.result);
      });
  }, [departmentSearchString]);

  const onSubmitData = (data) => {
    const filesArray = [
      data.Document1[0] && data.Document1[0],
      data.Document2[0] && data.Document2[0],
      data.Document3[0] && data.Document3[0],
    ];
    convertFilesToBase64(filesArray);

    setFinalData(data);
    setOpenConfirmModal(true);
  };

  function postData(data) {
    setOpenBackdrop(true);
    setOpenConfirmModal(false);

    let finalObj = {};

    finalObj.consentTempId = editRowData?.id;
    finalObj.departmentId = data.department.id;
    finalObj.fontName = "";
    finalObj.fontSize = 0;

    finalObj.image1Base64 = base64Data[0]?.base64String;
    finalObj.image2Base64 = base64Data[1]?.base64String;
    finalObj.image3Base64 = base64Data[2]?.base64String;
    finalObj.imageName1 = base64Data[0]?.fileName;
    finalObj.imageName2 = base64Data[1]?.fileName;
    finalObj.imageName3 = base64Data[2]?.fileName;

    finalObj.page = 0;
    finalObj.templateName = data.templateName;
    finalObj.webConsentEnglish = editor3.current.value;
    finalObj.webConsentMarathi = editor4.current.value;
    finalObj.webConsentMarathiTemplate = editor2.current.value;
    finalObj.webConsentTemplate = editor1.current.value;
    finalObj.isCertificate = data.isCertificate;

    saveConsentTemplete(finalObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenConfirmModal(false);
        handleCloseModal();
        setOpenBackdrop(false);
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenConfirmModal(false);
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    editRowData !== null &&
      setValue("department", {
        id: editRowData?.DepartmentID,
        label: editRowData?.Department,
        value: editRowData?.Department,
      });
    editRowData !== null &&
      setValue("templateName", editRowData["Template Name"]);
    //
    setContent1(editRowData?.WebConsentTemplate);
    setContent2(editRowData?.WebMarathiDescription);
    setContent3(editRowData?.WebConsentEnglish);
    setContent4(editRowData?.WebConsentMarathi);
  }, [editRowData, props]);

  useEffect(() => {
    if (openTextModal && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [openTextModal]);
  return (
    <>
      <Modal open={openTemplateModal}>
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              handleCloseModal();
              reset(defaultValues);
              setEditRowData(null);
              setContent1();
              setContent2();
              setContent3();
              setContent4();
            }}
          />
          <form onSubmit={handleSubmit(onSubmitData)}>
            <div className="space-y-2">
              <label className="flex font-semibold text-lg">New Template</label>
              <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-2">
                <div>
                  <SearchDropdown
                    control={control}
                    error={errors.department}
                    name="department"
                    label="Department"
                    dataArray={department}
                    handleInputChange={(e) => {
                      setDepartmentSearchString(e);
                    }}
                    isSearchable={true}
                    placeholder="Department *"
                    isClearable={true}
                    searchIcon={true}
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="templateName"
                    label="Template Name"
                    error={errors.templateName}
                  />
                </div>
                <div>
                  <CheckBoxField
                    control={control}
                    name="isCertificate"
                    label="Is Certificate"
                    style={{ fontSize: "12px" }}
                  />
                </div>
                <div>
                  <Tooltip title={"Edit Template"}>
                    <TextSnippetIcon onClick={() => setOpenTextModal(true)} />
                  </Tooltip>
                </div>
              </div>
              {/* //editor */}
              <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-2 ">
                <div>
                  <h1 className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    English Description
                  </h1>

                  <JoditEditor
                    name="joditone"
                    ref={editor1}
                    value={content1 !== null ? content1 : ""}
                    config={config2}
                    onBlur={(newContent) => {
                      //writerContent = newContent;
                      if (newContent !== "") {
                        setContent1(newContent);
                      }
                    }}
                  />
                </div>

                <div>
                  <h1 className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Marathi Description
                  </h1>

                  <JoditEditor
                    name="joditTwo"
                    ref={editor2}
                    value={content2 !== null ? content2 : ""}
                    config={config2}
                    onBlur={(newContent) => {
                      // writerContent = newContent;
                      if (newContent !== "") {
                        setContent2(newContent);
                      }
                    }}
                  />
                </div>
                <div>
                  <h1 className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    English Consent
                  </h1>
                  <JoditEditor
                    name="joditThree"
                    ref={editor3}
                    value={content3 !== null ? content3 : ""}
                    config={config2}
                    onBlur={(newContent) => {
                      // writerContent = newContent;
                      if (newContent !== "") {
                        setContent3(newContent);
                      }
                    }}
                  />
                </div>

                <div>
                  <h1 className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Marathi Consent
                  </h1>
                  <JoditEditor
                    name="joditFour"
                    ref={editor4}
                    value={content4 !== null ? content4 : ""}
                    config={config2}
                    onBlur={(newContent) => {
                      // writerContent = newContent;
                      if (newContent !== "") {
                        setContent4(newContent);
                      }
                    }}
                  />
                </div>
              </div>

              {/* upload Images */}
              <div className="grid lg:grid-cols-5 gap-3">
                <div>
                  <input type="file" {...register(`Document1`)} />
                </div>
                <div>
                  <input type="file" {...register(`Document2`)} />
                </div>
                <div>
                  <input type="file" {...register(`Document3`)} />
                </div>
              </div>

              <div className="flex justify-end gap-2">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    reset(defaultValues);
                    setEditRowData(null);
                    setContent1();
                    setContent2();
                    setContent3();
                    setContent4();
                  }}
                />
                <CommonButton
                  type="submit"
                  label="Save"
                  className="bg-customGreen text-white"
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openConfirmModal}
        confirmationHandleClose={handleCloseConfirmation}
        confirmationSubmitFunc={() => postData(finalData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this Record ?"
        confirmationButtonMsg="Add"
      />

      {openTextModal && (
        <Modal
          open={openTextModal}
          // onClose={() => {
          //   setOpenTextModal(false);
          // }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              bgcolor: "background.paper",
              borderRadius: 2,
              border: "none",
              "&:focus": {
                outline: "none",
              },
              overflowY: "auto",
              maxBlockSize:"80%"
            }}
          >
            <CancelPresentationIconButton
              onClick={() => {
                setOpenTextModal(false);
                setValue("editTemplate","")
              }}
            />

            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                Edit Template format
              </div>

              <hr className="border mx-1  border-blue-900" />

              <div className="p-5">
                <InputArea
                  control={control}
                  name="editTemplate"
                  label="Edit Template"
                  placeholder="Edit Template"
                  minRows={12}
                  size={"lg"}
                  ref={textareaRef}                />
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default OtConcentMasterModal;
