import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteSuccionSaturation,
  getAllSuccsionSaturation,
} from "../../../services/cliinicalChartServices/succionsaturationServices/SuccsionSaturationServices";
import {
  deleteAlert,
  errorAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

function SuccionSaturation(props) {
  const [
    succionSaturationDataResult,
    setSuccionSaturationDataResult,
  ] = useState([]);

  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  useEffect(() => {
    PopulateTable();
  }, [props.displayInfo?.AdmissionId, props.admissionId]);

  const PopulateTable = () => {
    showSpinner(true);
    getAllSuccsionSaturation(
      props.displayInfo?.AdmissionId || props.admissionId
    )
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        setSuccionSaturationDataResult(res.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deleteSuccionSaturation(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            PopulateTable();
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true &&
            obj.action === "Delete" &&
            !props.selectedPatient?.AllowToGO ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    let deleteObjVal = {};

                    deleteObjVal.Id = row.Id;

                    deleteObjVal.action = obj.action;

                    setDeleteObj(deleteObjVal);

                    //open the delete confirmation modal
                    handleOpenDeleteConfirm();
                  }}
                />
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  return (
    <div>
      <h1 className="my-1 text-sm font-semibold whitespace-nowrap">
        Suction / Saturation
      </h1>

      {spinner ? (
        <div className="grid justify-center">
          <LoadingSpinner />
        </div>
      ) : null}

      {succionSaturationDataResult.length > 0 && spinner === false ? (
        <CommonDynamicTableNew
          dataResult={succionSaturationDataResult}
          tableClass={"rounded h-[240px] 2xl:h-60 border"}
          highlightRow={false}
          removeHeaders={[
            "Id",
            "phlebitis",
            "visitId",
            "dateTime",
            "addedBy",
            "vipScore",
          ]}
          renderActions={renderActions}
        />
      ) : (
        <h3 className="flex justify-center mt-20 font-bold text-gray-600">
          No Records Found...
        </h3>
      )}
      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={deleteconfirm}
        confirmationHandleClose={handleCloseDeleteConfirm}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />
    </div>
  );
}

export default SuccionSaturation;
