import { yupResolver } from "@hookform/resolvers/yup";
import { Print } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Divider, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePagination from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerField from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  fetchAllIndentStores,
  getAllIndentDetailsList,
  getToStoreDropdown,
  isCloseIndent,
  isVerifyIndent,
} from "../../../services/commonServices/StoreIndent/StoreIndentService";
import {
  autoSearchIndent,
  fetchPrintPatientIndentList,
} from "../../../services/generalStore/PatientIndentServices/PatientIndentServices";
import { VerifyDisabledIcon } from "../../generalStore/verifyGrn/assets/VerifyDisabledIcon";
import { VerifyIcon } from "./assets/VerifyIcon";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import StoreIndentPrint from "../../../prints/StoreIndentPrint";

const isUrgentObj = [
  { id: "All", value: "All", label: "All", name: "All" },
  { id: "Yes", value: "Yes", label: "Yes", name: "Yes" },
  { id: "No", value: "No", label: "No", name: "No" },
];
const statusObj = [
  { id: "All", value: "All", label: "All", name: "All" },
  { id: "Pending", value: "Pending", label: "Pending", name: "Pending" },
  { id: "Closed", value: "Closed", label: "Closed", name: "Closed" },
];

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "93%",
  height: { sm: 490, lg: 550 },
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
};

export default function ViewIndentListModal(props) {
  const schema = yup.object().shape({
    fromStore: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Please Select from Store"),
        label: yup.string().required("Please Select from Store"),
      }),
  });
  const defaultValues = {
    searchItems: null,
    searchByIndentNumber: null,
    indentToStore: null,
    tax: null,
    isUrgent: "All",
    statusType: "All",
    fromDate: new Date(),
    toDate: new Date(),
  };
  const { control, handleSubmit, reset, watch, setValue, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  // from local storage
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [dataResult, setDataResult] = useState([]); // populate table data
  const [spinner, showSpinner] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [storeOptions, setStoreOptions] = useState([]);
  const [toStoreOptions, setToStoreOptions] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [options, setOptions] = useState([]);
  const [indentDetailsListData, setIndentDetailsListData] = useState([]);
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  //state variable for showing or not showing "No Recrods Found" message
  const [totalIndents, setTotalIndent] = useState();
  const [openPost, setOpenPost] = useState(false);
  const [isClosedIndent, setIsClosedIndent] = useState(false);
  const [openPrintList, setOpenListPrint] = useState(false);

  let selecteFromDate = watch("fromDate");
  let selecteToDate = watch("toDate");
  // select Is Urgent Type
  let isUrgent = watch("isUrgent");
  // select status Type
  let statusType = watch("statusType");
  let toStoreId = watch("indentToStore");

  let isUrgentType;
  if (isUrgent === "All") {
    isUrgentType = 2;
  } else if (isUrgent === "Yes") {
    isUrgentType = 1;
  } else if (isUrgent === "No") {
    isUrgentType = 0;
  }
  let isStatusType;
  if (statusType === "All") {
    isStatusType = 2;
  } else if (statusType === "Pending") {
    isStatusType = 1;
  } else if (statusType === "Closed") {
    isStatusType = 0;
  }

  //populate the CommonTable using the function inputPopulateTable
  const populateTable = (forPagination) => {
    const defaultParams = {
      cashCredit: 0,
      fromDate: selecteFromDate,
      fromStoreId: getUserInfo.storeId,
      loginStoreId: getUserInfo.storeId,
      searchString: searchString,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      isForceFullyClosed: 2,
      isCancelled: 2,
      isApproved: 2,
      toDate: selecteToDate,
      toStoreId: toStoreId ? toStoreId.id : 0,
      indentStatus: isStatusType,
      isUrgent: isUrgentType,
    };
    fetchAllIndentStores(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [
            ...prevData,
            ...res.result.getIndentList,
          ]);
        } else {
          setCount(res.count);
          setTotalIndent(res.result.countInfo);
          setDataResult(res.result?.getIndentList);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch(() => {});
  };

  // searchbar
  const handleChange = (autoSearchString) => {
    if (autoSearchString !== "") {
      setSearchString(autoSearchString);
      let obj = {
        fromDate: selecteFromDate,
        searchString: autoSearchString,
        toDate: selecteToDate,
      };
      autoSearchIndent(obj)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleClosePost = () => {
    setOpenPost(false);
  };

  const handleCloseIndent = () => {
    setIsClosedIndent(false);
  };

  // handleRowClick
  const handleSelectedRow = (row, index) => {
    getAllIndentDetailsList(row && row.IndentId)
      .then((response) => response.data)
      .then((res) => {
        setIndentDetailsListData(res.result);
      });
  };

  const handleOpenPrintModal = (IndentId) => {
    fetchPrintPatientIndentList(IndentId, false).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  const CloseIndent = () => {
    let obj = {
      indentId: selectedRow.IndentId,
      menuId: props.menuId,
      privilege: props.privilege,
    };

    isCloseIndent(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        setIsClosedIndent(false);
        setSelectedRow(null);
        props.setPrivilege("");
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const verifyIndent = () => {
    isVerifyIndent(getUserInfo.userId, selectedRow.IndentId)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        setOpenPost(false);
        setSelectedRow(null);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex pt-1 justify-center  space-x-2">
                  {actions.action === "Edit" ? (
                    <>
                      <Tooltip title="Edit Indent">
                        <button
                          type="button"
                          onClick={() => {
                            props.setEditIndentRow(row);
                          }}
                        >
                          <EditIcon className="text-customBlue cursor-pointer" />
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}

                  {actions.action === "Approve" ? (
                    <>
                      {row.IsApproved === false ? (
                        <div className="flex justify-center ">
                          <Tooltip title="Verify Indent">
                            <button
                              type="button"
                              className="text-customBlue cursor-pointer"
                              onClick={() => {
                                setOpenPost(true);
                                setSelectedRow(row);
                                props.setPrivilege(actions.action);
                              }}
                            >
                              <VerifyIcon className="" />
                            </button>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="text-customBlue">
                          <VerifyDisabledIcon className=" cursor-pointer" />
                        </div>
                      )}
                    </>
                  ) : null}

                  {actions.action === "Cancel" ? (
                    <>
                      {row.Closed === false ? (
                        <div className="flex items-center space-x-1">
                          <Tooltip title="Close Indent">
                            <button
                              type="button"
                              className="text-gray-600 "
                              onClick={() => {
                                setIsClosedIndent(true);
                                setSelectedRow(row);
                              }}
                            >
                              <HighlightOffIcon />
                            </button>
                          </Tooltip>
                        </div>
                      ) : (
                        <span>
                          <HighlightOffIcon className="text-gray-100" />
                        </span>
                      )}
                    </>
                  ) : null}

                  {actions.action === "Print" ? (
                    <>
                      <Tooltip title="Print ">
                        <button
                          type="button"
                          className="text-gray-600 cursor-pointer"
                          onClick={() => {
                            handleOpenPrintModal(row.IndentId);
                          }}
                        >
                          {<Print />}
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (getUserInfo.storeId !== null) {
      let fromStore = {
        id: getUserInfo.storeId,
        value: getUserInfo.storeName,
        label: getUserInfo.storeName,
      };

      setValue("indentFromStore", fromStore);
    } else {
      getToStoreDropdown(getUserInfo.loginUnitId)
        .then((response) => response.data)
        .then((res) => {
          setStoreOptions(res.result);
          setValue("indentFromStore", "Store");
        });
    }
    getToStoreDropdown(getUserInfo.loginStoreId, getUserInfo.loginUnitId)
      .then((response) => response.data)
      .then((res) => {
        setToStoreOptions(res.result);
      });
  }, []);

  useEffect(() => {
    populateTable();
  }, []);

  const StoreIndentPrintObj = {
    fromDate: selecteFromDate,
    fromStoreId: getUserInfo.storeId,
    indentStatus: isStatusType,
    isUrgent: isUrgentType,
    loginStoreId: getUserInfo.storeId,
    page: 0,
    size: 10,
    toDate: selecteToDate,
    toStoreId: toStoreId ? toStoreId.id : 0,
  };
  const renderPrint = () => {
    setTimeout(() => {
      setOpenListPrint(false);
    }, 2000);

    return (
      <div className="hidden">
        <StoreIndentPrint Object={StoreIndentPrintObj} />
      </div>
    );
  };
  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0 z-10">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className=" px-4 pb-2 ">
          <div className="py-2">
            <CancelPresentationIconButton
              className=""
              onClick={() => {
                props.handleClose();
                reset(defaultValues);
              }}
            />
          </div>

          <div className="">
            <label className="text-lg text-gray-600 text-center font-semibold flex justify-center my-1">
              Store Indent List
            </label>

            <form>
              <div className="grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-2">
                <div className="col-span-2">
                  <SearchDropdown
                    searchIcon={true}
                    control={control}
                    name="searchByIndentNumber"
                    placeholder="Search By Indent Number"
                    handleInputChange={handleChange}
                    dataArray={options}
                  />
                </div>

                <div className="flex space-x-2 col-span-2 w-full">
                  <div className="w-full">
                    <DatePickerField
                      control={control}
                      name="fromDate"
                      label="From Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("fromDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="w-full">
                    <DatePickerField
                      control={control}
                      name="toDate"
                      label="To Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("toDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>

                <div className="w-full">
                  <DropdownField
                    control={control}
                    // error={errors.indentFromStore}
                    isDisabled={getUserInfo.storeId ? true : false}
                    name="indentFromStore"
                    placeholder="Indent From Store"
                    dataArray={storeOptions}
                  />
                </div>
                <div className="w-full">
                  <DropdownField
                    control={control}
                    // error={errors.indentToStore}
                    name="indentToStore"
                    placeholder="Indent To Store"
                    dataArray={toStoreOptions}
                    isDisabled={props.edit}
                  />
                </div>
                <div className=" flex space-x-2 items-center whitespace-nowrap col-span-4 xl:col-span-4">
                  <label className="font-semibold text-sm text-gray-700 whitespace-nowrap">
                    Is Urgent &nbsp;
                  </label>
                  <div className="mt-1 flex space-x-2 text-sm">
                    <RadioField
                      name="isUrgent"
                      control={control}
                      dataArray={isUrgentObj}
                    />
                  </div>
                  <div className=" flex space-x-1 xl:space-x-3 items-center whitespace-nowrap ">
                    <label className="font-semibold  text-sm lg:text-base text-gray-700 whitespace-nowrap">
                      Status &nbsp;
                    </label>
                    <div className="mt-1 flex space-x-3 text-sm">
                      <RadioField
                        name="statusType"
                        control={control}
                        dataArray={statusObj}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      searchIcon={true}
                      onClick={() => {
                        populateTable();
                      }}
                    />

                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                        setSearchString("");
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <Tooltip title="Indent List">
                  <button type="button" className="cursor-pointer ">
                    <LocalPrintshopIcon
                      sx={{ color: "#4B5563" }}
                      onClick={() => {
                        setOpenListPrint(true);
                      }}
                    />
                  </button>
                </Tooltip>
              </div>
            </form>
            <Divider
              className="pt-2"
              sx={{
                borderColor: "#0B83A5",
                borderBottomWidth: "2px",
              }}
            />
            <div className="flex space-x-3 text-gray-600 font-semibold mt-2 lg:w-7/12 justify-between">
              <div className="flex space-x-2  items-center">
                <h1>Total Indents :</h1>
                <label className="text-customGreen text-center ">
                  <span className=" text-lg">
                    {totalIndents?.["Total Indent"]}
                  </span>
                </label>
              </div>
              <div className="flex space-x-2  items-center">
                <h1>Pending Indents :</h1>
                <label className="  text-customRed text-center ">
                  <span className="text-lg">
                    {totalIndents?.["Pending Indent"]}
                  </span>
                </label>
              </div>
              <div className="flex space-x-2  items-center">
                <h1>Closed Indents :</h1>
                <label className="text-customBlue text-center">
                  <span className="text-lg">
                    {totalIndents?.["Closed Indent"]}
                  </span>
                </label>
              </div>
            </div>

            <div className="w-full">
              {spinner ? (
                <div className="grid justify-center">
                  <LoadingSpinner />
                </div>
              ) : null}
              {dataResult?.length > 0 ? (
                <>
                  <CommonDynamicTablePagination
                    dataResult={dataResult}
                    setDataResult={setDataResult}
                    actionsRequired={true}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    handleSelectedRow={handleSelectedRow}
                    highlightRow={true}
                    renderActions={renderActions}
                    tableHeading={"Item List"}
                    removeHeaders={[
                      "DrId",
                      "FromStoreId",
                      "IndentId",
                      "ToStoreId",
                    ]}
                  />
                </>
              ) : (
                <div className="flex justify-center">
                  <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                    No Items Found...
                  </h3>
                </div>
              )}
            </div>
            <div>
              <h3 className="text-sm font-semibold">Item Details</h3>
              {indentDetailsListData.length > 0 ? (
                <div>
                  <CommonDynamicTable
                    dataResult={indentDetailsListData}
                    tableClass={"rounded md:h-[270px] 2xl:h-72 "}
                    highlightRow={false}
                    removeHeaders={[
                      "IndentDetailId",
                      "ItemId",
                      "IndentId",
                      "ToStoreId",
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {openPrintList && renderPrint()}

      {/* 1st table */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={verifyIndent}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to verify this indent ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={isClosedIndent}
        confirmationHandleClose={handleCloseIndent}
        confirmationSubmitFunc={CloseIndent}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to close this indent ?"
        confirmationButtonMsg="Yes"
      />
    </div>
  );
}
