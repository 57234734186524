import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
//import PreviousReportsList from "./PreviousReportsList";

const lifeSupportArr = [
  {
    id: 0,
    label: "Basic",
    value: "Basic",
  },

  { id: 1, label: "Advanced", value: "Advanced" },
];

const RadiologyMriCTArr = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },

  { id: 0, label: "No", value: "No" },
];

const CurrentLocationArr = [
  {
    id: 0,
    label: "Shifted To ICU",
    value: "Shifted To ICU",
  },

  { id: 1, label: "Stabilized In Ward", value: "Stabilized In Ward" },
];

const OrangeForm = () => {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const strokeWithinWindowPeriod_selected = watch("strokeWithinWindowPeriod");

  return (
    <div className=" mb-2">
      {/* code orange form */}
      <div>
        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3">
          <div className="grid col-span-2 grid-cols-2 gap-3">
            {/* from Date */}
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="codeDateOrange"
                label="Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>

            <div className="">
              <Controller
                control={control}
                name="codeTimeOrange"
                render={({ field: { value, onChange } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      label="Time"
                      value={value}
                      onChange={onChange}
                      disabled={false}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          // error={errors.codeTime}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: "14px",
                              position: "absolute",
                              top: "-2px",
                            },
                          }}
                          sx={{
                            svg: {
                              color: "#1e3a8a",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                // border: 0,
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>
          {/* <div className="">
            <InputField
              name="codeOrangeNumber"
              label="Code Orange Number"
              variant="outlined"
              error={errors.codeOrangeNumber}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div> */}
          <div className="col-span-2 gap-3 grid grid-cols-3">
            <div className="col-span-2">
              <InputField
                name="patientName"
                label="Patient Name"
                variant="outlined"
                error={errors.patientName}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div>
              <InputField
                name="patientMRNO"
                label="MRNo"
                variant="outlined"
                error={errors.patientMRNO}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="">
            <CheckBoxField
              control={control}
              name="strokeWithinWindowPeriod"
              label="Stroke Within Window Period"
            />
          </div>

          {strokeWithinWindowPeriod_selected ? (
            <div className="">
              <Controller
                control={control}
                name="strokeTime"
                render={({ field: { value, onChange } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      label="Time (If Yes)"
                      value={value}
                      onChange={onChange}
                      disabled={false}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          // error={errors.strokeTime}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: "14px",
                              position: "absolute",
                              top: "-2px",
                            },
                          }}
                          sx={{
                            svg: {
                              color: "#1e3a8a",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                // border: 0,
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          ) : null}

          <div className="">
            <CheckBoxField
              control={control}
              name="mockDrillOrange"
              label="Mock Drill"
            />
          </div>
        </div>
        <div className="my-2">
          <InputArea
            control={control}
            name="diagnosis"
            label="Diagnosis"
            placeholder="Diagnosis"
            //error={errors.cause}
          />
        </div>
        <div className="my-2">
          <InputArea
            control={control}
            name="shortSummaryOrange"
            label="Short Summary Of Case"
            placeholder="Short Summary Of Case"
            //error={errors.cause}
          />
        </div>

        <hr className="my-3 bg-red-500" />

        <div className="flex  gap-x-10 flex-wrap items-center">
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Life Support Given :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="lifeSupport"
                control={control}
                dataArray={lifeSupportArr}
              />
            </div>
          </div>
          <div className="flex gap-x-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Radiology Mri/CT :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="radiologyMriCt"
                control={control}
                dataArray={RadiologyMriCTArr}
              />
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Outcome Of Code Orange-Thrombolysis :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="outcomeOrange"
                control={control}
                dataArray={RadiologyMriCTArr}
              />
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Current Location :
            </p>
            <div className="mt-1">
              <RadioField
                label=""
                name="currentLocation"
                control={control}
                dataArray={CurrentLocationArr}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <InputArea
            control={control}
            name="codeOrangeTeam"
            label="Code Orange Team"
            placeholder="Code Orange Team"
            //error={errors.cause}
          />
        </div>
        <div className="mt-3">
          <InputArea
            control={control}
            name="anyDifficultiesToCO"
            label="Any difficulties encountered while attending to Code Orange"
            placeholder="Any difficulties encountered while attending to Code Orange"
            //error={errors.cause}
          />
        </div>
      </div>
    </div>
  );
};

export default OrangeForm;
