import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";

export default function CurrentMedicationTable(props) {
  //state varibale for the table
  //
  const [rowIndex, setRowIndex] = React.useState("");

  const handleClick = (index, row) => {
    setRowIndex(index);
  };

  const handleCheckClick = (event, index, row) => {
    if (event.target.checked === true) {
      setRowIndex(index);
    } else if (event.target.checked === false) {
      setRowIndex("");
    }
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const [headers, setHeaders] = React.useState([]);
  React.useEffect(() => {
    if (props.data.length > 0) {
      const allHeaders = Object.keys(props.data[0]);

      const headersArr = removeHeaders(allHeaders, ["id"]);
      setHeaders(headersArr);
    }
  }, [props.data]);

  const deleteRow = (index) => {
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    props.setServiceData(newTemplateData);
  };

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-36"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Action
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Dose
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Frequency
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Route
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Instructions
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data.map((Medication, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Tooltip title="Delete">
                            <div
                              className="text-red-500 mr-3"
                              onClick={() => deleteRow(index)}
                            >
                              {<DeleteOutlineOutlinedIcon />}
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {Medication.ItemName}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {Medication.Dose}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {Medication.Frequency}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {Medication.Route}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {Medication.Instructions}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
