import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

import ResetButton from "../../../common/components/Buttons/ResetButton";

import SearchIconButton from "../../../common/components/Buttons/SearchIconButton";
import RadioField from "../../../common/components/FormFields/RadioField";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { successAlert } from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  cabinDropdown,
  doctorDropdown,
} from "../../../opd/services/patientappointmentservices/PatientVisitListServices";
import {
  fetchAllOPDCasePaperRegList,
  saveCasePaperRegister,
  searchOPDCasePaper,
} from "../../services/opdcasepaperregister/OpdCasePaperRegisterServices";
import { Checkbox } from "@mui/material";
// radio button all,pending and appt
/****************************Radio button array of object i.e All,Pending & Received **************************************/
const patientStatusData = [
  {
    id: 0,
    label: "Pending",
    value: 0,
  },
  {
    id: 1,
    label: "Received",
    value: 1,
    opdCasePaperRegistereSearch: "",
  },
  {
    id: 2,
    label: "All",
    value: 2,
  },
];

const OPDCasePaperRegister = () => {
  /**************************** useFrom **************************************/
  const schema = yup.object().shape({});
  // default values in components
  const defaultValues = {
    fromDateCalender: new Date(),
    toDateCalender: new Date(),
    patientStatus: 0,
    doctor: null,
    cabin: null,
    selectAll: false,
  };
  // useForm Destructuring
  const methods = useForm({
    shouldFocusError: false,
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    control,

    reset,

    watch,

    setValue,
  } = methods;

  /******************************* state variables **************************************/
  const [open, setOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState();
  const [cabin, setCabin] = React.useState();
  const [data1, setData1] = React.useState([]);
  const [opdCasePaperRegister, setOpdCasePaperRegister] = React.useState([]);
  const [saveData, setSaveData] = React.useState([]);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState(null);
  const [searchString, setSearchString] = React.useState("");
  const [count, setCount] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataResult, setDataResult] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  /******************************* other variables *********************************/
  let patientStatusVal = watch("patientStatus");
  let fromDateCalenderVal = watch("fromDateCalender");
  let toDateCalenderVal = watch("toDateCalender");
  // get menu id
  let location = useLocation();
  let userInfoObject = JSON.parse(localStorage.getItem("userInfo"));

  /******************************* useEffects**************************************/
  // Dynamic Actions
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);
  // doctor dropdown
  useEffect(() => {
    populateTable();
  }, [
    fromDateCalenderVal,
    toDateCalenderVal,
    doctor,
    cabin,
    searchString,
    patientStatusVal,
  ]);

  /****************************Functions*****************************************/
  //  open modal
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenBackDrop(false);
  };

  // search patient for Search patient in table
  function handleChange(autoSearchString) {
    let searchObj = {
      fromDate: fromDateCalenderVal ? fromDateCalenderVal : new Date(),
      searchId: searchString?.id,
      searchString: autoSearchString,
      status: patientStatusVal ? Number(patientStatusVal) : Number(0),
      toDate: toDateCalenderVal ? toDateCalenderVal : new Date(),
      unitId: userInfoObject?.unitId,
    };
    searchOPDCasePaper(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setOpdCasePaperRegister(res?.result);
      });
  }

  // selected value from options
  function autoSelectedValue(selectedValue) {
    if (selectedValue !== "") {
      setSearchString(selectedValue);
    } else if (selectedValue === null) {
      setSearchString("");
    }
  }
  // doctor dropdown by search
  const getDoctorDropdownList = (searchDoctorString) => {
    if (searchDoctorString !== "") {
      doctorDropdown(searchDoctorString)
        .then((response) => response.data)
        .then((res) => {
          if (searchDoctorString !== null) {
            setDoctor(res.result);
          }
        });
    }
  };

  // cabin dropdon search string
  const getCabinDropdownList = (searchCabinString) => {
    cabinDropdown(searchCabinString)
      .then((response) => response.data)
      .then((res) => {
        setCabin(res.result);
      });
  };

  // fetch opd case paper listing
  function populateTable(forPagination) {
    if (isFetching) return;

    // Set isFetching to true to indicate that fetch is in progress
    setIsFetching(true);
    let searchObj = {
      fromDate: fromDateCalenderVal ? fromDateCalenderVal : new Date(),
      searchId: searchString?.id,
      status: patientStatusVal ? Number(patientStatusVal) : Number(0),
      toDate: toDateCalenderVal ? toDateCalenderVal : new Date(),
      unitId: userInfoObject?.unitId,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    setShowSpinner(true);
    setShowWarning(false);
    fetchAllOPDCasePaperRegList(searchObj)
      .then((response) => {
        setCount(response.data.count);
        return response.data;
      })
      .then((res) => {
        let arr = [];
        arr = [...res?.result];

        setShowSpinner(false);
        // setShowWarning(true);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
        // setUserActions(res.actions);
        setData1(res?.result);
      })
      .catch((error) => {
        setShowSpinner(false);

        setShowWarning(true);
      })
      .finally(() => {
        // Set isFetching to false when fetch is completed or errored
        setIsFetching(false);
      });
  }

  // submit data function
  function onSubmitDataHandler() {
    handleOpen();

    let userInfObj = JSON.parse(localStorage.getItem("userInfo"));
    let arr = [];

    for (let i = 0; i < data1.length; i++) {
      let obj = {};
      obj.addedBy = userInfObj.userId;
      obj.isCasePaperReceivedByMRD = data1[i].isCasePaperReceivedByMRD;
      obj.isCasePaperSendToMRD =
        data1[i].isCasePaperSendToMRDVal ||
        data1[i].isCasePaperSendToMRD ||
        false;
      obj.receivedBy = userInfObj.userId;
      obj.visitId = data1[i].VisitId;

      arr.push(obj);
    }
    setSaveData(arr);
  }

  //save case paper check box true value record
  function saveRecord(saveData) {
    setOpenBackDrop(true);

    saveCasePaperRegister(saveData, location?.state?.menuId, privilege)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
        setData1([]);
        setOpenBackDrop(false);
        setOpen(false);
        reset();
      });
  }

  /****************************dynamic  actions*****************************************/
  const renderInput1 = (row, index, header) => {
    function handleSelectedReceivedFromMRD(row, newVal) {
      //   let isCasePaperReceivedByMRDVal = watch(
      //     `isCasePaperReceivedByMRD${index}`
      //   );
      //   data1[index].isCasePaperReceivedByMRD = isCasePaperReceivedByMRDVal;
      setData1((prevData) =>
        prevData.map((item) =>
          item["VisitId"] === row["VisitId"]
            ? { ...item, isCasePaperReceivedByMRD: newVal }
            : item
        )
      );
    }

    return (
      <>
        <div className="">
          <fieldset
            className="m-0 p-0"
            onClick={(e) => {
              handleSelectedReceivedFromMRD(row, e.target.checked);

              // if (e.target.checked === true) {
              //   let dataArr = [...data1];
              //   dataArr[index].isChecked = true;
              //   setData1(dataArr);
              // } else if (e.target.checked === false) {
              //   let dataArr = [...data1];
              //   dataArr[index].isChecked = false;
              //   setData1(dataArr);
              // }
            }}
          >
            <CheckBoxField
              control={control}
              // defaultValue={false}
              name={`isCasePaperReceivedByMRD${index}`}
              //defaultChecked
              //checked={row.isCasePaperReceivedByMRD}
              size="small"
              disabled={!row.isCasePaperSendToMRD}
            />
          </fieldset>
        </div>
      </>
    );
  };

  const renderActions = (row, index, header) => {
    function handleSelectedSendToMRD(row, newVal) {
      setData1((prevData) =>
        prevData.map((item) =>
          item["VisitId"] === row["VisitId"]
            ? { ...item, isCasePaperSendToMRDVal: newVal }
            : item
        )
      );
    }

    return (
      <>
        <fieldset
          className="m-0 p-0"
          onClick={(e) => {
            handleSelectedSendToMRD(row, e.target.checked);
          }}
        >
          <CheckBoxField
            control={control}
            defaultValue={row?.isCasePaperSendToMRD}
            name={`isCasePaperSendToMRD${index}`}
            disabled={row?.isCasePaperSendToMRD}
          />
        </fieldset>
      </>
    );
  };

  React.useEffect(() => {
    if (data1.length > 0) {
      let dataArr = [...data1];
      let lengthArr = [];
      for (let i = 0; i <= dataArr.length; i++) {
        if (dataArr?.isChecked === true) {
          lengthArr.push(dataArr[i]);
        }
        if (lengthArr?.length < dataArr?.length) {
          setValue("selectAll", false);
        } else if (lengthArr?.length === dataArr.length) {
          setValue("selectAll", true);
        }
      }
    }
  }, [data1]);

  return (
    <FormProvider {...methods}>
      <div className="pt-14">
        <div className="py-2">
          <p className="text-center text-2xl text-gray-700 font-Poppins pb-2">
            OPD Case Paper Register
          </p>
        </div>
        <div className=" flex gap-3 md:flex md:w-full">
          <div className="grid w-[190%]">
            <SearchBar
              searchIcon={true}
              isClearable={true}
              name="opdCasePaperRegistereSearch"
              placeholder="Search by Name / MR No."
              dataArray={opdCasePaperRegister}
              isSearchable={true}
              handleInputChange={handleChange}
              onChange={autoSelectedValue}
            />
          </div>
          <div className="w-full">
            <DatePickerFieldNew
              name="fromDateCalender"
              control={control}
              label="From Date"
              onChange={(newValue) => {
                setValue("fromDateCalender", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="w-full">
            <DatePickerFieldNew
              name="toDateCalender"
              control={control}
              label="To Date"
              // value={new Date()}
              onChange={(newValue) => {
                //  setSelectedFromDate(newValue);
                //  let today = format(newValue, "yyyy-MM-dd");
                setValue("toDateCalender", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
            {/********  <Controller
              control={control}
              defaultValue={new Date()}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="To Date"
                    value={toDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {

                      setToDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="bg-white"
                        name="fromDateCalender"
                        fullWidth
                        size="small"
                        {...params}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              width: "100%",

                              borderColor: "#0B83A5",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
              name="fromDateCalender"
            />****/}
          </div>
          <div className="w-full">
            <SearchDropdown
              control={control}
              name="doctor"
              placeholder="Doctor"
              label="Doctor"
              isSearchable={true}
              searchIcon={true}
              isClearable={true}
              dataArray={doctor}
              handleInputChange={getDoctorDropdownList}
            />
          </div>
          <div className="w-full">
            <SearchDropdown
              control={control}
              name="cabin"
              placeholder="Cabin"
              label="Cabin"
              isSearchable={true}
              searchIcon={true}
              isClearable={true}
              dataArray={cabin}
              handleInputChange={getCabinDropdownList}
            />
          </div>
        </div>
        <div className="flex justify-between py-1">
          <div className="flex py-1 md:h-auto px-2">
            <RadioField
              label=""
              name="patientStatus"
              control={control}
              dataArray={patientStatusData}
            />
          </div>
          <div className="flex gap-2">
            <ResetButton
              onClick={() => {
                reset(defaultValues);
                setValue("fromDateCalender", new Date());

                setValue("toDateCalender", new Date());
              }}
            />
            <SearchIconButton
              onClick={() => {
                populateTable();
              }}
            />
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 justify-between">
            <div>
              <div className="content-start ">
                <fieldset
                  className=""
                  onChange={(e) => {
                    if (e?.target?.checked === true) {
                      let dataArr = [...data1];
                      dataArr.forEach((obj) => {
                        obj.isChecked = e?.target?.checked;
                      });
                    } else if (e?.target?.checked === false) {
                      let dataArr = [...data1];
                      dataArr.forEach((obj) => {
                        obj.isChecked = e?.target?.checked;
                      });
                    }
                    let dataArr = [...data1];
                    let lengthArr = [];
                    for (let i = 0; i <= dataArr.length; i++) {
                      setValue(
                        `isCasePaperSendToMRD${i}`,
                        dataArr[i]?.isChecked
                      );
                      if (dataArr[i]?.isChecked === true) {
                        lengthArr.push(dataArr[i]);
                      }
                      if (lengthArr?.length < dataArr.length) {
                        setValue("selectAll", false);
                      } else if (lengthArr?.length === dataArr.length) {
                        setValue("selectAll", true);
                      }
                    }
                  }}
                >
                  <CheckBoxField
                    control={control}
                    defaultValue={false}
                    name="selectAll"
                    label="Select All"
                  />
                </fieldset>
              </div>
            </div>
            <div></div>
          </div>
          {showSpinner ? (
            <div className="grid justify-center items-center">
              <LoadingSpinner />
            </div>
          ) : null}
          {dataResult.length > 0 && showSpinner === false ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              highlightRow={false}
              tableClass="h-96 2xl:h-[520px]"
              removeHeaders={[
                "isCasePaperSendToMRDVal",
                "isCasePaperSendToMRD",
                "label",
                "VisitId",
                "DoctorId",
                "isChecked",
              ]}
              renderActions={patientStatusVal !== "1" && renderActions}
              renderInput={patientStatusVal !== "1" && renderInput1}
              editableColumns={
                patientStatusVal !== "1" && ["isCasePaperReceivedByMRD"]
              }
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
            />
          ) : null}
          {showWarning === true && showSpinner === false ? (
            <div className="flex justify-center">
              <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                No Records Found...
              </h3>
            </div>
          ) : null}
        </div>

        <div className="flex py-2 gap-2 justify-end">
          {userActions &&
            userActions.map((actions) => (
              <>
                {!actions.isAction ? (
                  <>
                    {actions.action === "Create" ? (
                      <div className="">
                        <CommonButton
                          label="Save"
                          className="bg-customGreen text-white"
                          onClick={() => {
                            onSubmitDataHandler();
                            setPrivilege(actions?.action);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
        </div>

        <CommonBackDrop openBackdrop={openBackDrop} />
        <ConfirmationModal
          confirmationOpen={open}
          confirmationHandleClose={handleClose}
          confirmationSubmitFunc={() => {
            handleClose();
            saveRecord(saveData);
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Do You Want To Save OPD Case Paper Register?"
          confirmationButtonMsg="Save"
        />
      </div>
    </FormProvider>
  );
};

export default OPDCasePaperRegister;
