import { format } from "date-fns";
import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";
import RadioNABHLogo from "../../../assets/icons/RadioNABHLogo";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";

export default function RadiologyWorkOrderPrint(props) {
  const {
    selectedRow,
    setOpenBackdrop,
    isWhatsAppPrint,
    setPdfBase64,
    dataResult,
    isPrint,
    setIsPrint,
    printImage,
    renderImage,
    images,
    selectedRowIndex,
    setSelectedRowIndex,
    isWhatsApp,
    isEmail,
    isPreview,
  } = props;

  const printRef = useRef(null);
  const [printCount, setPrintCount] = React.useState(0);

  useEffect(() => {
    let imageId = document.getElementById("imageId");

    if (selectedRow?.IsCompleted === true) {
      if (imageId) {
        imageId.src = images?.find(
          (image) =>
            image?.testName !== null && image?.testName === dataResult?.TestName
        )?.base64;
        console.log("imageIdimageId321", imageId?.src);
      }
    } else {
      imageId.src = "";
    }
  }, [images]);

  useEffect(() => {
    let imageId = document.getElementById("imageId");
    console.log("imageIDsrc123", imageId?.src);
    if (
      isPrint === true &&
      printCount === 0 &&
      dataResult !== null &&
      imageId?.src !== "" &&
      isWhatsApp === false &&
      isEmail === false &&
      (isPreview === undefined || isPreview === false)
    ) {
      setTimeout(() => {
        GeneratePrint(printRef);
        setPrintCount(printCount + 1);
        setSelectedRowIndex(null);
        setOpenBackdrop(false);
        setIsPrint(false);
      }, 500);
    }
  }, [isPrint]);

  return (
    <div
      className={`printBody ${
        isWhatsApp === true || isEmail === true ? "px-4" : ""
      }`}
    >
      <table
        className={` ${
          isWhatsApp === false && isEmail === false ? "w-[100%] ml-10" : ""
        }`}
        ref={printRef}
        style={{
          "-fs-table-paginate": "paginate !important",
        }}
      >
        <thead
          className={` ${
            isWhatsApp === false && isEmail === false ? "w-[100%]" : ""
          }`}
        >
          {isWhatsApp === true || isEmail === true ? (
            <div className="flex items-center text-center h-16 ">
              {(isWhatsApp === true || isEmail === true) && (
                <div
                  className={`flex justify-start ${
                    isWhatsApp === true || isEmail === true ? "w-10" : ""
                  } `}
                >
                  <RadioNABHLogo />
                </div>
              )}
              <div className="text-center w-full">
                <h1 className="text-[12px] font-semibold">
                  Inamdar Multispeciality Hospital
                </h1>
                <h1 className="text-[12px] font-semibold">
                  Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040
                </h1>
                <h1 className="text-[12px] font-semibold">
                  Phone No : 020-66812222/44
                </h1>
              </div>
              {(isWhatsApp === true || isEmail === true) && (
                <div
                  className={`flex justify-end ${
                    isWhatsApp === true || isEmail === true ? "w-16" : ""
                  } `}
                >
                  <InamdarHospiLogo />
                </div>
              )}
            </div>
          ) : (
            <div className=""></div>
          )}
          {isWhatsApp === false && isEmail === false && (
            <>
              <br />
              <br />
              <br />
              <br />
            </>
          )}

          <hr className={`border-t-[1px] border-black`} />
          <div className="p-1 grid grid-cols-2">
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-[88px]">Date</div>
              <div className="text-[14px]">:&nbsp;{dataResult?.Date}</div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-36">
                Report Date & Time
              </div>
              <div className="text-[14px]">
                :&nbsp;{dataResult?.ReportDate} {dataResult?.ReportTime}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-[88px]">MRNo</div>
              <div className="text-[14px]">:&nbsp;{dataResult?.MRNo}</div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-36">
                Printed Date & Time
              </div>
              <div className="text-[14px]">
                :&nbsp;{format(new Date(), "dd/MM/yyyy hh:mm aa")}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-[88px]">Name</div>
              <div className="text-[14px]">
                :&nbsp;{dataResult?.PatientName}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-36">Consultant</div>
              <div className="text-[14px]">:&nbsp;{dataResult?.Consultant}</div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-[88px]">
                Age / Sex
              </div>
              <div className="text-[14px]">
                :&nbsp;
                {`${dataResult?.AgeYear} Y, ${dataResult?.AgeMonth} M,${dataResult?.AgeDay} D / ${dataResult?.Gender}`}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-36">Advised By</div>
              <div className="text-[14px]">
                :&nbsp;
                {dataResult?.AdvisedBy === null ||
                dataResult?.AdvisedBy === undefined
                  ? ""
                  : dataResult?.AdvisedBy.trim() === "Select Refered Doctor"
                  ? ""
                  : dataResult?.AdvisedBy === "0"
                  ? ""
                  : dataResult?.AdvisedBy}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-[88px]">Bed No</div>
              <div className="text-[14px]">:&nbsp;{dataResult?.BedNo}</div>
            </div>
            <div className="flex items-center">
              <div className="text-[14px] font-semibold w-36">Ward</div>
              <div className="text-[14px]">:&nbsp;{dataResult?.Ward}</div>
            </div>
          </div>

          <div>
            <hr className="border-t-[1px] border-black" />
            {!selectedRow?.IsCompleted && (
              <div className="text-[15px]">
                <div className="text-customRed">Provisional Report</div>
                <hr className="border-t-[1px] border-black" />
              </div>
            )}
          </div>
          {/* <div className="space-y-1">
            <hr className="border-t-[1px] border-black" />
            <div className="grid grid-cols-3 w-full -mt-4 text-[15px]">
              {!selectedRow?.IsCompleted ? (
                <div className="text-customRed">Provisional Report</div>
              ) : (
                <div></div>
              )}
              <div className="col-span-2 font-semibold ml-10">
                {dataResult?.TestName}
              </div>
            </div>
            <hr className="border-t-[1px] border-black" />
          </div> */}
        </thead>

        <tbody className="w-full">
          <div>
            <div
              className={`${
                isWhatsApp === false && isEmail === false ? "w-[750px]" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: dataResult?.TemplateDescription,
              }}
            />
          </div>
          <div>
            <hr className="border-t-[1px] border-black mb-1" />
            <img id="imageId" className="w-44" />
            <div className="text-[14px]">
              <div className=" font-semibold ">
                {dataResult?.ConsultantRadiologist}
              </div>
              {dataResult?.ConsultantRadiologistDesignation !== null && (
                <div>
                  {"(" + dataResult?.ConsultantRadiologistDesignation + ")"}
                </div>
              )}

              <div>{dataResult?.ESINo}</div>
              <div className="mb-2">Reg No : {dataResult?.RegNo}</div>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}

