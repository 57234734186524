import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
//import Inamdar2Icon from "../printfile/Inamdar2Icon";
import NabhLogo from "../../assets/icons/NabhLogo";
import { fetchMlcInjuryCertificatePrint } from "../services/admissionServices/AdmissionServices";

export default function MlcCertificatePrint({ certificateId }) {
  const printRef = useRef(null);

  const [dataResult, setDataResult] = React.useState();

  React.useState(() => {
    fetchMlcInjuryCertificatePrint(certificateId)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, []);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <table className="w-full" ref={printRef}>
      <thead
        className=" w-full"
        style={{
          "-fs-table-paginate": "paginate",
        }}
      >
        <div className=" flex justify-between items-center">
          <NabhLogo />
          <h1 className="font-semibold">{dataResult?.CertficateType}</h1>
          <InamdarHospiLogo />
        </div>
      </thead>
      <tbody className="w-full">
        <div className=" grid grid-cols-[20%_1%_79%] p-1 text-[11px] my-2">
          <div className=" font-semibold">MLCNo</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.MLCNo}</div>
          <div className=" font-semibold">Reg No</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.RegNo}</div>
          <div className=" font-semibold">Patient Name</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.PatientName}</div>
          <div className=" font-semibold">Age</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.Age}</div>
          <div className=" font-semibold">Sex</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.Gender}</div>
          <div className=" font-semibold">Address</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.Address}</div>
          <div className=" font-semibold">Mobile No</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.MobileNo}</div>
          <div className=" font-semibold">Brought by Name/ Relationship</div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.BoughtByName}</div>
          {/* <div className=" font-semibold"></div>
          <div className=" font-semibold">:</div>
          <div>{dataResult?.AgeOfInjury}</div> */}
          <div className=" font-semibold">Address</div>
          <div>:</div>
          <div>{dataResult?.BoughtByAddress}</div>
          <div className=" font-semibold">Contact Details</div>
          <div>:</div>
          <div>{dataResult?.ContactDetails}</div>
        </div>
        <div className=" p-1 flex space-x-5 text-[11px]">
          <div className=" font-semibold">Date and Time Of Examination</div>
          <div>{dataResult?.DateTimeOfExami}</div>
        </div>
        <div className=" p-1 flex space-x-5 text-[11px]">
          <div className=" font-semibold">
            Date and Time Of Accident / Assault
          </div>
          <div>{dataResult?.DateTimeOfAccidentAssault}</div>
        </div>
        <div className=" p-1 space-x-5 text-[11px]">
          <div className=" font-semibold">
            Brief history, Clinical Examination and Diagnosis
          </div>
          <div>{dataResult?.BreifHistory}</div>
        </div>
        <div className=" text-[11px] p-1 ">
          <div className="grid grid-cols-[20%_1%_79%] space-y-2">
            <div className=" font-semibold mt-2 ">Age of injury</div>
            <div className=" font-semibold ">:</div>
            <div>{dataResult?.AgeOfInjury}</div>
            <div className=" font-semibold ">Cause of injury</div>
            <div className=" font-semibold ">:</div>
            <div>{dataResult?.CauseOfInjury}</div>
            <div className=" font-semibold ">Admitted Under</div>
            <div className=" font-semibold ">:</div>
            <div>{dataResult?.AdmittedUnder}</div>
            <div className=" font-semibold ">Ward and Bed No</div>
            <div className=" font-semibold ">:</div>
            <div>{dataResult?.WardBedNo}</div>
          </div>
          <div className=" col-span-3 font-semibold text-[11px]">
            Information given to
          </div>
          <div className=" text-[11px] grid grid-cols-2 ">
            <div className=" grid grid-cols-[25%_75%] space-y-2">
              <div className=" mt-2 font-semibold">Police Station</div>
              <div>{dataResult?.PoliceStation}</div>

              <div className=" font-semibold">Constable</div>
              <div>{dataResult?.Constable}</div>

              <div className=" font-semibold">Buckle No</div>
              <div>{dataResult?.BuckleNo}</div>

              <div className=" font-semibold">Date and Time</div>
              <div>{dataResult?.MLCDateTime}</div>
            </div>
            <div className=" space-y-2">
              <div className=" font-semibold text-[11px]">
                Sign. of Medical Officer
              </div>
              <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
                <h1>Name</h1>
                <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
              </div>
              <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
                <h1>Reg. No</h1>
                <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
              </div>
              <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
                <h1>Designation</h1>
                <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
              </div>
            </div>
          </div>
        </div>
        <div className=" p-1 text-[11px] ">
          Personal effects of patient / Samples handed over to Police
        </div>
        <div className=" space-y-2 text-[11px] p-1">
          <div className=" font-semibold text-[11px]">Certificate taken by</div>
          <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
            <h1>Police Station</h1>
            <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
          </div>
          <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
            <h1>Constable</h1>
            <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
          </div>
          <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
            <h1>Buckle No</h1>
            <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
          </div>
          <div className=" font-semibold items-center grid grid-cols-[20%_80%] ">
            <h1>Date and Time</h1>
            <p className=" w-56 border-b-[1px] mt-1.5 border-black"></p>
          </div>
        </div>
      </tbody>
    </table>
  );
}
