import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";

/////function for key press
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  React.useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function AltDrugTable(props) {
  const {
    selectedAltDrugRowID,
    setSelectedAltDrugRowID,
    setSelectedDrugRowID,
    lastIndex,
    selectedDrugRowID,
  } = props;

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  /////
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(allHeaders, ["Id"]);

  ////code for key press output
  const tableContainerRef = React.useRef(null);

  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowRightPressed = useKeyPress("ArrowRight");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const enterPressed = useKeyPress("Enter");

  var arrLength = props.data.length;

  //up arrow
  useEffect(() => {
    if (arrowUpPressed && selectedDrugRowID === null) {
      if (selectedAltDrugRowID <= 0) {
        setSelectedAltDrugRowID(arrLength - 1);
      } else if (selectedAltDrugRowID !== 0) {
        setSelectedAltDrugRowID(selectedAltDrugRowID - 1);
      }
    }
  }, [arrowUpPressed]);

  // down arrow
  useEffect(() => {
    if (arrowDownPressed && selectedDrugRowID === null) {
      if (selectedAltDrugRowID >= arrLength - 1) {
        setSelectedAltDrugRowID(0);
      } else if (selectedAltDrugRowID !== arrLength) {
        setSelectedAltDrugRowID(selectedAltDrugRowID + 1);
      }
    }
  }, [arrowDownPressed]);

  //right key
  React.useEffect(() => {
    if (arrowRightPressed === true && selectedDrugRowID === null) {
    }
  }, [arrowRightPressed]);

  // left key
  React.useEffect(() => {
    if (arrowLeftPressed === true) {
      setSelectedAltDrugRowID(null);
      setSelectedDrugRowID(lastIndex);
    }
  }, [arrowLeftPressed]);

  //enter
  React.useEffect(() => {
    if (
      enterPressed === true &&
      selectedAltDrugRowID !== null &&
      selectedAltDrugRowID !== undefined
    ) {
      if (selectedAltDrugRowID !== null && selectedAltDrugRowID !== undefined) {
        setSelectedAltDrugRowID(props.data[selectedAltDrugRowID]);
        props.setSelectedDrug(props.data[selectedAltDrugRowID]);

        props.setDrugTableData([]);
        props.setAltDrugTableData([]);
        props.setDrugSearchString(null);

        props.setSelectedDrugRowData(null);
        setSelectedAltDrugRowID(null);

        // ////on enter focus batch input
        // const e = { target: { name: "input-1" } };
        // props.onEnterFocusNext(e);
        if (
          props.data[selectedDrugRowID]?.BatchesRequired === "No" ||
          props.data[selectedDrugRowID]?.BatchesRequired === false
        ) {
          props.inputRefThree.current.focus();
        }
      }
    }
  }, [enterPressed, selectedAltDrugRowID]);

  useEffect(() => {
    if (
      tableContainerRef?.current &&
      selectedAltDrugRowID !== null &&
      typeof selectedAltDrugRowID === "number"
    ) {
      const selectedRow = tableContainerRef?.current?.querySelector(
        `tr:nth-child(${selectedAltDrugRowID + 1})`
      );
      if (selectedRow) {
        selectedRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [selectedAltDrugRowID]);

  return (
    <>
      <div className="grid w-auto" ref={tableContainerRef}>
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded lg:h-52 md:h-72"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    {headers.map((header, index) => (
                      <TableCell
                        sortDirection={orderBy === header ? order : false}
                        className="whitespace-nowrap"
                      >
                        <TableSortLabel
                          active={false}
                          direction={orderBy === header ? order : "asc"}
                          onClick={createSortHandler(header)}
                          key={index}
                        >
                          <span className="text-gray-600 font-bold">
                            {header}
                          </span>
                          {orderBy === header ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data &&
                    props.data.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover={false}
                          style={{
                            backgroundColor:
                              selectedAltDrugRowID === index ? "#FFC44B" : "",
                          }}
                          sx={{
                            "& td": {
                              paddingY: 0.2,
                            },
                          }}
                          onClick={() => {
                            setSelectedAltDrugRowID(index);

                            props.setSelectedDrugRowData(row);
                          }}
                        >
                          {headers &&
                            headers.map((header, i) => (
                              <TableCell
                                className="whitespace-nowrap"
                                key={i}
                                onClick={() => {
                                  // props.displayView(row, index);
                                }}
                              >
                                {row[header] === true
                                  ? "Yes"
                                  : row[header] === false
                                  ? "No"
                                  : row[header]}
                              </TableCell>
                            ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
