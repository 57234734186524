import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//Store Name
export const fetchStoreName = () => {
  //
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};

//Item patient List
export const fetchPatientList = (searchString) => {
  //
  return apiClient.get(`/patientInfo/admissionAutoComplete/${searchString}`, {
    headers: authHeader(),
  });
};

//Item patient Details
export const fetchPatientDetails = (patientId) => {
  //
  return apiClient.get(`/patientInfo/transfer/${patientId}`, {
    headers: authHeader(),
  });
};

//Item Kit List
export const fetchKitList = (searchString) => {
  return apiClient.get(`/inventory/inPatientIssue/itemKitList/${searchString}`);
};

//Item Kit List  //also used in in-pt-issue
export const fetchKitListItems = (kitId, storeId) => {
  return apiClient.get(`/patientIndent/kitItems/${kitId}/${storeId}`, {
    headers: authHeader(),
  });
};

export const fetchSupplier = () => {
  return apiClient.get(`/purchaseOrder/supplierList`);
};

export const fetchStore = (cashCounterId) => {
  return apiClient.get(`/inventory/inPatientIssue/storeName/${cashCounterId}`, {
    headers: authHeader(),
  });
};

export const fetchStoreDropdown = () => {
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};
