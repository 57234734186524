import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Box, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { styled } from "@mui/material/styles";
import { InfoIcon } from "./InfoIcon";

//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function RolesListTable(props) {
  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [rowIndex, setRowIndex] = React.useState();

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  function handleClick(row, index) {
    setRowIndex(index);
  }
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props?.data?.[0]);
  const headers = removeHeaders(allHeaders, ["id"]);

  // ttoltip for showing counters of the role
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      placement="right"
      // open={openTooltip}
      // onClose={setOpenTooltip(false)}
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      // borderColor: openTooltip ? "gray" : "",
      // border: openTooltip ? 0.5 : 0,
      color: "black",
      width: 700,
      maxHeight: 500,
      overflow: "scroll",
      fontSize: theme.typography.pxToRem(13),
      border: "1px solid #7c7978",
    },
  }));

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                  borderRadius: "10px",
                },
              }}
              className="rounded max-h-96"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold">Action</span>
                    </TableCell>
                    {headers.map((header, index) => (
                      <>
                        <TableCell
                          sortDirection={orderBy === header ? order : false}
                          className="whitespace-nowrap"
                        >
                          <TableSortLabel
                            active={false}
                            direction={orderBy === header ? order : "asc"}
                            onClick={createSortHandler(header)}
                            key={index}
                          >
                            <span className="text-gray-600 font-bold">
                              {header}
                            </span>
                            {orderBy === header ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& th": {
                            paddingY: -0.5,
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        <TableCell className="whitespace-nowrap" key={index}>
                          <Tooltip title="Edit Role">
                            <IconButton
                              size="small"
                              onClick={() => {
                                props.editRow(row);
                              }}
                            >
                              <DriveFileRenameOutlineIcon
                                sx={{ color: "#1E3A8A" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="whitespace-nowrap" key={index}>
                          {row.Role}
                        </TableCell>
                        <TableCell className="whitespace-nowrap" key={index}>
                          {row?.["Role Description"]}
                        </TableCell>
                        <TableCell className="whitespace-nowrap" key={index}>
                          {row?.["Cash Counter"]?.length > 0 ? (
                            <div className="flex gap-2 items-center">
                              <div className="flex gap-2 truncate w-[250px]">
                                {row?.["Cash Counter"]?.map((counter) => {
                                  return (
                                    <>
                                      <span
                                        style={{
                                          color:
                                            counter.charAt() === "P"
                                              ? "red"
                                              : "blue",
                                        }}
                                      >
                                        •
                                      </span>
                                      <span className="px-1">{counter}</span>
                                    </>
                                  );
                                })}
                              </div>
                              <div>
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      <div className="grid grid-cols-2 ">
                                        {row?.["Cash Counter"]?.length > 0
                                          ? row?.["Cash Counter"]?.map(
                                              (counter, counterIndex) => {
                                                return (
                                                  <div className="w-32 truncate ">
                                                    <span
                                                      style={{
                                                        color:
                                                          counterIndex % 3 === 0
                                                            ? "red"
                                                            : "blue",
                                                        // color:
                                                        //   counter.charAt() ===
                                                        //   "P"
                                                        //     ? "red"
                                                        //     : "blue",
                                                      }}
                                                    >
                                                      •
                                                    </span>
                                                    <span className="px-1">
                                                      {counter}
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            )
                                          : ""}
                                      </div>
                                    </React.Fragment>
                                  }
                                >
                                  <button>
                                    <InfoIcon />
                                  </button>
                                </HtmlTooltip>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
