import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import { ModalStyle } from "../../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getANCSchedulerData,
  getPatientAncScheduler,
  getPatientInfo,
  saveANCSchedulerData,
  getServicesSearch,
} from "../../Services/WorkOrderViewServices";
import { PrintOnIcon } from "../../../../assets/icons/CustomIcons";
import ANCSchedulerPrint from "../../prints/ANCSchedulerPrint";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { format } from "date-fns";

const ANCSchedulerModal = (props) => {
  const {
    openANCSchedulerModal,
    setOpenANCSchedulerModal,
    selectedTestId,
    setSelectedTestId,
    privilege,
    setPrivilege,
    menuId,
    setOpenBackdrop,
    selectedRow1,
  } = props;
  const { control, watch, reset, setValue, register } = useForm({
    defaultValues: {
      eddDate: new Date(),
    },
  });
  const [ANCSchedulerData, setANCSchedulerData] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const [openPostConfirm, setOpenPostConfirm] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [ANCSchedulerResult, setANCSchedulerResult] = useState([]);
  const [ANCSchLmpDate, setANCSchLmpDate] = useState(null);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [lmpDate, setLmpDate] = useState(null);
  console.log("openPrintModal", openPrintModal);
  let getEddDate = watch("eddDate");

  function getANCSchedulerList() {
    getANCSchedulerData({
      eddDate: getEddDate
        ? format(getEddDate, "yyyy-MM-dd'T'hh:mm:ss.000'Z'")
        : format(new Date(), "yyyy-MM-dd'T'hh:mm:ss.000'Z'"),
    }).then((response) => {
      setANCSchedulerData(response.data.result?.ancSchedulerList);
      setLmpDate(response.data.result?.lmpDate);
    });
  }

  function onSubmitData() {
    let ancSchedulerList = [];
    if (ANCSchedulerData !== undefined && ANCSchedulerData?.length > 0) {
      for (let obj of ANCSchedulerData) {
        let SelectedServiceIds = [];
        if (obj.services?.length > 0) {
          for (let val of obj?.services) {
            SelectedServiceIds.push(val.id);
          }
        }
        ancSchedulerList.push({
          serviceIds:
            SelectedServiceIds?.length > 0 ? SelectedServiceIds : null,
          ancSchedulerId: obj.ancSchedulerId,
          comment: obj.comment,
          date: obj.date,
          isCurrentWeek: obj.isCurrentWeek,
          weeks: obj.weeks,
        });
      }
    }
    let postObj = {
      ancSchedulerDtoList: ancSchedulerList,
      menuId: menuId,
      opdIpd: selectedTestId?.Opd_Ipd_External,
      opdIpdId: selectedTestId?.Opd_Ipd_External_Id,
      patientId: selectedRow1?.PatientId,
      privilege: privilege,
    };
    if (ANCSchedulerData?.length > 0) {
      setFinalData(postObj);

      setOpenPostConfirm(true);
    } else {
      warningAlert("Scheduler data not available...!");
    }
  }

  function saveRecord() {
    setOpenBackdrop(true);
    saveANCSchedulerData(finalData)
      .then((response) => {
        successAlert(response.data.message);
        setSelectedTestId(null);
        setANCSchedulerData([]);
        setPrivilege("");
        setOpenPostConfirm(false);
        setOpenANCSchedulerModal(false);
        setOpenBackdrop(false);
        setLmpDate(null);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    if (selectedTestId !== null) {
      if (getEddDate !== undefined) {
        getPatientAncScheduler(
          selectedRow1?.PatientId,
          format(getEddDate, "yyyy-MM-dd'T'hh:mm:ss.000'Z'")
        ).then((response) => {
          setANCSchedulerData(response.data.result?.ancDetails);
          setANCSchedulerResult(response.data.result?.ancDetails);
          setValue(
            "eddDate",
            (response.data.result?.ancDetails?.length - 1).date
          );
          setLmpDate(response.data.result?.lmpDate);
        });
      }
      getPatientInfo(selectedTestId?.OrderDtlsId, selectedTestId?.Opd_Ipd_External)
        .then((response) => response.data)
        .then((res) => {
          setPatientInfo(res.result);
        });
    }
  }, [selectedTestId]);

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <ANCSchedulerPrint
          lmpDate={lmpDate}
          patientInfo={patientInfo}
          ANCSchedulerData={ANCSchedulerData}
          selectedRow1={selectedRow1}
        />
      </div>
    );
  };

  function handleChange(searchString) {
    if (searchString !== "") {
      getServicesSearch(searchString).then((response) => {
        setServiceList(response.data.result);
      });
    }
  }

  return (
    <>
      <Modal open={openANCSchedulerModal}>
        <Box sx={ModalStyle}>
          <div
            onClick={() => {
              setOpenANCSchedulerModal(false);
              reset();
              setANCSchedulerData(null);
              setSelectedTestId(null);
              setPrivilege("");
              setLmpDate(null);
            }}
          >
            <CancelPresentationIconButton />
          </div>
          <div className="text-base font-semibold text-center">
            ANC Scheduler
          </div>
          <div className="mt-4 space-y-3">
            <div className="grid grid-cols-2 xl:flex xl:justify-between bg-[#F1F1F1] border border-gray-400 rounded p-2">
              <div className="flex">
                <span className="font-bold w-28 ">Patient Name&nbsp;</span>
                <span>: {patientInfo?.PatientName}</span>
              </div>
              <div className="flex">
                <span className="font-bold w-16">MR No.&nbsp;</span>
                <span>: {selectedRow1?.MRNo}</span>
              </div>
              <div className="flex">
                <span className="font-bold w-10">Age&nbsp;</span>
                <span>: {patientInfo?.AgeYear}</span>
              </div>
              <div className="flex">
                <span className="font-bold w-16">Gender&nbsp;</span>
                <span className="flex gap-2 items-center">
                  :&nbsp;
                  {patientInfo?.Gender}
                </span>
              </div>
            </div>
            <div className="flex justify-between">
              <fieldset
                className="flex space-x-2"
                disabled={ANCSchedulerResult?.length > 0}
              >
                <div className="w-40">
                  <DatePickerFieldNew
                    control={control}
                    name="eddDate"
                    label="EDD Date "
                    value={new Date()}
                    size="small"
                    inputFormat="dd-MM-yyyy"
                    disabled={ANCSchedulerResult?.length > 0}
                  />
                </div>
                <CommonButton
                  type="button"
                  label="Create Scheduler"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    getANCSchedulerList();
                  }}
                />
                <div className="flex text-base items-center">
                  <div className="font-semibold">LMP Date&nbsp;</div>
                  <div>
                    :&nbsp;
                    {lmpDate?.split("-").reverse().join("-")}
                  </div>
                </div>
              </fieldset>
              <Tooltip title="Print ANC Scheduler">
                <button
                  onClick={() => {
                    setOpenPrintModal(true);
                  }}
                >
                  <PrintOnIcon />
                </button>
              </Tooltip>
            </div>
            <div>
              {ANCSchedulerData !== undefined &&
              ANCSchedulerData?.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: "0px",
                  }}
                  className="h-[450px]"
                >
                  <Table size="small" stickyHeader>
                    <TableHead className="z-50">
                      <TableRow
                        sx={{
                          "& th": {
                            paddingY: 0.5,
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        <TableCell
                          size="small"
                          className=" text-lg font-semibold"
                        >
                          Date
                        </TableCell>
                        <TableCell
                          size="small"
                          className=" text-lg font-semibold"
                        >
                          Weeks
                        </TableCell>
                        <TableCell>Services</TableCell>
                        <TableCell
                          size="small"
                          className=" text-lg font-semibold"
                        >
                          Comment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ANCSchedulerData?.map((row, index) => {
                        let pathcServices;
                        if (typeof row?.services === "string") {
                          pathcServices = JSON.parse(row?.services);
                        }
                        row.services = pathcServices
                          ? pathcServices
                          : row?.services;
                        return (
                          <TableRow
                            key={row.name}
                            sx={{
                              "& td": {
                                paddingY: 1,
                              },
                            }}
                          >
                            <TableCell size="small" component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell size="small" component="th" scope="row">
                              {row.weeks}
                            </TableCell>
                            <TableCell>
                              <div className="w-96">
                                <SearchDropdown
                                  defaultValue={
                                    pathcServices
                                      ? pathcServices
                                      : row?.services
                                  }
                                  isMulti={true}
                                  control={control}
                                  name={`searchService${index}`}
                                  placeholder="Search Service"
                                  dataArray={serviceList}
                                  searchIcon={true}
                                  isSearchable={true}
                                  isClearable={true}
                                  handleInputChange={handleChange}
                                  isDisabled={row?.isCurrentWeek === false}
                                  inputRef={{
                                    ...register(`searchService${index}`, {
                                      onChange: (e) => {
                                        if (e.target.value?.length > 0) {
                                          let tempData = [...ANCSchedulerData];
                                          tempData[index].services =
                                            e.target.value;
                                          setANCSchedulerData(tempData);
                                        }
                                      },
                                    }),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              //   rowSpan={row?.weeks === 12 && 2}
                              size="small"
                              component="th"
                              scope="row"
                            >
                              <input
                                name={`comment${index}`}
                                type="text"
                                className={`border ${
                                  row?.isCurrentWeek === true
                                    ? "border-black"
                                    : "border-gray-300"
                                } rounded px-1 w-full h-8`}
                                value={row?.comment}
                                disabled={row?.isCurrentWeek === false}
                                onChange={(e) => {
                                  let tempData = [...ANCSchedulerData];
                                  tempData[index].comment = e.target.value;
                                  setANCSchedulerData(tempData);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="flex h-96 font-semibold justify-center items-center">
                  Scheduler Not Found...
                </div>
              )}
            </div>
            <div className="flex space-x-2 justify-end">
              <CommonButton
                type="button"
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  reset();
                  setANCSchedulerData([]);
                  setANCSchedulerResult([]);
                }}
              />
              <CommonButton
                type="button"
                label="Save"
                className="bg-customGreen text-white"
                onClick={() => {
                  onSubmitData();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openPrintModal && renderPrint()}

      <ConfirmationModal
        confirmationOpen={openPostConfirm}
        confirmationHandleClose={() => {
          setOpenPostConfirm(false);
        }}
        confirmationSubmitFunc={saveRecord}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to add record ?"
        confirmationButtonMsg="Yes"
      />
    </>
  );
};

export default ANCSchedulerModal;
