import axios from "axios";
import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

/////registration autocomplte
export const fetchRegistrationSearchDropDown = (
  searchString,
  isAppointment
) => {
  return apiClient.get(
    `/patientInfo/registrationAutoComplete/${searchString}/${isAppointment}`,
    {
      headers: authHeader(),
    }
  );
};

///fetch data
export const fetchSearchedregistrationData = (PatientId, isAppointment) => {
  return apiClient.get(
    `/patientInfo/regPatientInfo/${PatientId}/${isAppointment}`,
    {
      headers: authHeader(),
    }
  );
};

///fetch data
export const fetchBlacklistAproval = (obj) => {
  return apiClient.post(`/patientInfo/addPTToBlackList`, obj, {
    headers: authHeader(),
  });
};

// autocomplete for patient in ipd opd advance
export const patientAdvanceAutocomplete = (patientInfo) => {
  return apiClient.get(`/patientInfo/${patientInfo}`, {
    headers: authHeader(),
  });
};

////fetch Source
export const fetchSource = () => {
  //
  return apiClient.get(`/misc/source`, {
    headers: authHeader(),
  });
};

////check adhar number duplication
export const fetchAdharStatus = (aadharNo) => {
  //
  return apiClient.get(`/admission/checkAadharNo/${aadharNo}`, {
    headers: authHeader(),
  });
};

////check Patient duplication
export const fetchPatientStatus = (firstName, lastName, mobileNo) => {
  //
  return apiClient.get(
    `/patientInfo/checkPatientAlreadyExists/${firstName}/${lastName}/${mobileNo}`,
    {
      headers: authHeader(),
    }
  );
};

///visit status
export const fetchVisitStatus = (patientId, departmentId, doctorId) => {
  //
  return apiClient.get(
    `/patientInfo/visitStatus/${patientId}/${departmentId}/${doctorId}`,
    {
      headers: authHeader(),
    }
  );
};

///visit status
export const fetchEmergencyNo = () => {
  //
  return apiClient.get(`/patientInfo/emergencyNumberForOPD`, {
    headers: authHeader(),
  });
};

// submit data //
export const PostMyPatientRegistrationInfo = (finalObject) => {
  //
  return apiClient.post(`/patientInfo/saveRegistration`, finalObject, {
    headers: authHeader(),
  });
};

///print

export const fetchOpdRegistrationCasePaperPrint = (patientId) => {
  return apiClient.get(`/reports/opd/caseSummary?visitId=${patientId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};
