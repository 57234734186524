import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";
import RadioField from "../../../../../common/components/FormFields/RadioField";
import MLCModalEdit from "../../../../../common/components/mlcDetails/MLCModalEdit";
import AdmissionInfo from "./AdmissionInfo";
import BedDetails from "./BedDetails";
import CompanyDetails from "./CompanyDetails";
import { fetchTariffByCompCategory } from "../../../../../commonServices/miscellaneousServices/MiscServices";
// import MLCModalEdit from "../../commonModal/MLCModalEdit";

const Admitted_For = [
  {
    id: 0,
    value: 0,
    label: "Medicine",
  },
  {
    id: 1,
    value: 1,
    label: "Operative",
  },
];

const AdmissionDetails = (props) => {
  const [expandPanal6, setExpandPanal6] = useState(true);
  const [expandPanal7, setExpandPanal7] = useState(true);
  const [expandPanal9, setExpandPanal9] = useState(true);
  // const [patientCategoryChange, setPatientCategoryChange] = useState("Self");
  const [openModal, setOpenModal] = React.useState(false);
  //
  const [tariff, setTariff] = useState();

  const {
    selectedBedDetails,
    setSelectedBedDetails,
    firstValid,
    setMlcModalData,
    patientCategoryChange,
    setPatientCategoryChange,
    EmergencyNumber,
    patchData,
  } = props;

  const handleChangePanal6 = () => {
    setExpandPanal6(!expandPanal6);
  };
  const handleChangePanal7 = () => {
    setExpandPanal7(!expandPanal7);
  };

  const handleChangePanal9 = () => {
    setExpandPanal9(!expandPanal9);
  };

  const { control, watch, setValue } = useFormContext();

  const PatientCategory = watch("patientcategory");
  const Company = watch("company");

  // let isMlc = watch("medicoLegalCase");

  // const StateIsMlc = () => {
  //   setOpenModal(isMlc);
  // };

  // useEffect(() => {
  //   if (isMlc) {
  //     StateIsMlc();
  //   }
  // }, [isMlc]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  ///

  const handleTariffByCompCategory = (Category) => {
    if (PatientCategory !== null) {
      fetchTariffByCompCategory(
        Category?.id || PatientCategory?.id,
        Company?.id !== undefined ? Company?.id : 0
      )
        .then((response) => {
          setTariff(response.data.result);
          if (response?.data?.result.length > 0) {
            if (PatientCategory?.id === 16) {
              setValue("tariff", response?.data?.result[1]);
            } else {
              setValue("tariff", response?.data?.result[0]);
            }
          }
        })
        .catch(() => {});
    }
  };

  return (
    <div>
      <div className="grid lg:grid-cols-8 md:grid-cols-2">
        {/* //Radio Buttons// */}
        <div className="mb-[-2rem] lg:col-span-2 md:col-span-2    ">
          <div className=" flex gap-3  lg:justify-center -mt-5 ">
            <RadioField
              label=""
              name="admittedFor"
              control={control}
              dataArray={Admitted_For}
            />
          </div>
        </div>
        {/* ////checkboxes */}
        <div className="lg:mt-[-1.8rem] lg:ml-20 lg:col-span-5 grid lg:grid-cols-3 md:col-span-5 md:grid-cols-3">
          <div className="md:col-span-1 lg:col-span-1 ">
            <CheckBoxField
              control={control}
              name="lockIndent"
              label="Lock Indent"
            />
          </div>
          <div
            className="lg:col-span-1 md:col-span-1"
            onChange={(e) => {
              if (e.target.checked) {
                setOpenModal(e.target.checked);
              }
            }}
          >
            <CheckBoxField
              control={control}
              name="medicoLegalCase"
              label="Medico Legal Case"
            />
          </div>
          {/*<div className="md:col-span-1 lg:col-span-1 ">
            <CheckBoxField
              control={control}
              name="cashPayment"
              label="Cash Payment"
            />
  </div>*/}
        </div>
      </div>
      {/* Admission Info */}
      <Accordion
        expanded={expandPanal6}
        onChange={handleChangePanal6}
        elevation={6}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          sx={{
            "&.Mui-expanded": {
              marginBottom: "-1rem",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <p className="font-bold tracking-wide text-sm font-Poppins">
            Admission Details
          </p>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Admission Info /> */}
          <AdmissionInfo
            setPatientCategoryChange={setPatientCategoryChange}
            patientCategoryChange={patientCategoryChange}
            //
            casualtyDate={props.casualtyDate}
            setCasualtyDate={props.setCasualtyDate}
            //
            casualtyTime={props.casualtyTime}
            setCasualtyTime={props.setCasualtyTime}
            //
            handleTariffByCompCategory={handleTariffByCompCategory}
            tariff={tariff}
          />
        </AccordionDetails>
      </Accordion>
      {/* CompanyDetails */}
      {patientCategoryChange !== "Self" &&
      patientCategoryChange !== "International" &&
      patientCategoryChange !== "Ipf" ? (
        <>
          <Accordion
            expanded={expandPanal7}
            onChange={handleChangePanal7}
            elevation={6}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              sx={{
                "&.Mui-expanded": {
                  marginBottom: "-1rem",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
              }}
            >
              <p className="font-bold tracking-wide text-sm font-Poppins">
                Company Details
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {/* <CompanyDetails /> */}
              <CompanyDetails
                handleTariffByCompCategory={handleTariffByCompCategory}
                patchData={patchData}
                />
            </AccordionDetails>
          </Accordion>
        </>
      ) : null}
      {/* BedDetails */}
      <Accordion
        expanded={expandPanal9}
        onChange={handleChangePanal9}
        elevation={6}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          sx={{
            "&.Mui-expanded": {
              marginBottom: "-1rem",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <p className="font-bold tracking-wide text-sm font-Poppins">
            Bed Details
          </p>
        </AccordionSummary>
        <AccordionDetails>
          {/* <BedDetails /> */}

          <BedDetails
            selectedBedDetails={selectedBedDetails}
            setSelectedBedDetails={setSelectedBedDetails}
            isForEdit={props.isForEdit}
          />
        </AccordionDetails>
      </Accordion>
      {/* mlc Modal */}
      {openModal && firstValid.length !== 0 ? (
        <MLCModalEdit
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
          patientInfo={props.patientInfo}
          patientData={props.patientData}
          patientName={firstValid.firstName + " " + firstValid.lastName}
          gender={firstValid.gender.label}
          age={firstValid.age}
          setMlcModalData={setMlcModalData}
          mrNo={0}
          MLCNo={null}
          // isEdit={true}
          isFromForm={true}
          //
          ipdEmergency={props.editAdmissionId !== 0 ? 1 : 2}
          admissionId={
            props.editAdmissionId !== null
              ? Number(props.editAdmissionId)
              : Number(0)
          }
          emergencyId={
            EmergencyNumber !== null && EmergencyNumber !== undefined
              ? Number(EmergencyNumber.id)
              : Number(0)
          }
          opdIpd={1}
          //
          patientId={patchData?.id}
          isEmergency={false}
          //
        />
      ) : null}
    </div>
  );
};

export default AdmissionDetails;
