import authHeader from "../../../../../authentication/authservices/auth-header";
import apiClient from "../../../../../http-common";

//get credit bills list
export const getListOfCreditBills = (patientId, opdIpd) => {
  return apiClient.get(
    `/pharmacySettlement/listOfCreditBills/${patientId}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

//get patient advance details
export const getPatientAdvanceDetails = (patientId) => {
  return apiClient.get(
    `/companySettlement/patientAdvanceBalance/${patientId}`,
    {
      headers: authHeader(),
    }
  );
};

//get patient information
export const getPatientInformation = (patientId, opdIpd) => {
  return apiClient.get(`/counterSale/patientInfo/${patientId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

//save pharmacy self settlement
export const savePharmacySelfSettlement = (postObj) => {
  return apiClient.post(`/pharmacySettlement/saveSelfSettlement`, postObj, {
    headers: authHeader(),
  });
};

//get list of payments
export const getListOfPayment = (opdIpdExternalId, opdIpd) => {
  return apiClient.get(
    `/pharmacySettlement/listOfPayments/${opdIpdExternalId}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

// service for pharmacy self settlement print
export const getPharmacySettlementPrint = (PaymentId) => {
  return apiClient.get(
    `/reports/bills/settlementReceiptPharmacy/${PaymentId}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};
