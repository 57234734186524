import { Box, Grid, Modal, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as React from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import InputField from "../../../../common/components/FormFields/InputField";

import CancelButton from "../../../../common/components/Buttons/CancelButton";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SaveButton } from "../../../../common/components/Buttons/CommonButtons";

import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
//imports from react hook form
import { Controller, useFieldArray, useForm } from "react-hook-form";

import {
  getAdvancedDropdown,
  getBankList,
  getPaymentMode,
  getUpiType,
} from "../../../../billing/services/BillingServices";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};
export default function BillInformationModal(props) {
  let paymentSchema = {
    paymentCart: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()
            .typeError("Amount Required")
            .min(0, "Min value 1.")
            .required("Amount Required"),

          paymentMode: yup
            .object()
            .nullable()
            .shape({
              value: yup.string().required("Required"),
              label: yup.string().required("Required"),
            })
            .required("Required"),

          // referenceNumber: yup.string().when("paymentMode", (paymentMode) => {
          //   if (paymentMode !== null) {
          //     if (paymentMode.label !== "CASH") {
          //       return yup
          //         .string()
          //         .required("Required")
          //         .min(4, "Add Min Four Digit")
          //         .max(4, "Add Max Four Digit  ");
          //     }
          //   }
          // }),
          //validation schema for referenceNumber input field
          referenceNumber: yup.string().when("paymentMode", (paymentMode) => {
            let paymentModeName;

            if (paymentMode) {
              paymentModeName = paymentMode.label;
            }

            if (
              paymentModeName === "CREDIT CARD" ||
              paymentModeName === "DEBIT CARD"
            ) {
              return yup
                .string()
                .required("Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(4)
                .max(4);
            } else if (paymentModeName === "UPI") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(20);
              // .length(20, "Only 6 Digits allowed");
            } else if (paymentModeName === "CHEQUE") {
              return yup
                .string()
                .required("Reference Number Required")
                .length(6, "Only 6 Digits allowed");
            } else if (paymentModeName === "ECS") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(20);
            } else if (paymentModeName === "ONLINE") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(20);
              // .length(20, "Only 6 Digits allowed");
            } else {
              return yup.string().notRequired();
            }
          }),
          bankName: yup
            .object()
            .nullable()
            .typeError("Required")
            .when("paymentMode", (paymentMode) => {
              if (paymentMode !== null) {
                if (
                  paymentMode.label !== "CASH" &&
                  paymentMode.label !== "UPI"
                ) {
                  return yup
                    .object()
                    .nullable()
                    .shape({
                      value: yup.string().required("Select Bank"),
                      label: yup.string().required("Select Bank"),
                    })
                    .required("Select Bank");
                }
              }
            }),
        })
      )
      .min(1, "Please Add amount Details"),
  };

  const paymentAppointValidation = yup.object().shape(paymentSchema).required();
  const paymentSchemaObj = paymentAppointValidation;

  let today = new Date();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(paymentSchemaObj),
    defaultValues: {
      paymentCart: [
        {
          paymentMode: "",
          amount: 0,
          referenceNumber: "",
          bankName: "",
          paymentDate: today,
        },
      ],
    },
  });

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    control,
    getValues,
    clearErrors,
    setValue,
    watch,
  } = methods;

  const { fields, append: append, remove: remove } = useFieldArray({
    control: control,
    name: "paymentCart",
  });

  // state variables
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState([]);

  const [showAddIcon, setShowAddIcon] = React.useState(false);
  const [netPayableAmountVal, setNetPayableAmountVal] = React.useState(0);

  const [netPayErrorMessage, setNetPayErrorMessage] = React.useState("");
  const [customCashError, setCustomCashError] = React.useState(false);
  const [consumeAdvanceFlag, setConsumedAdvanceFlag] = React.useState(false);
  const [upiTypes, setUpiTypes] = React.useState();
  const [advance, setAdvance] = React.useState(0);
  const [consumeErrorMsgOne, setConsumeErrorMsgOne] = React.useState("");

  const [consumeErrorMsgTwo, setConsumeErrorMsgTwo] = React.useState("");
  const [advancedList, setAdvancedList] = React.useState([]);
  const [bankList, setBankList] = React.useState([]);
  const [paymentModeList, setPaymentModeList] = React.useState([
    {
      id: 1,
      value: "CASH",
      label: "CASH",
    },
    {
      id: 2,
      value: "DD",
      label: "DD",
    },
    {
      id: 3,
      value: "CHEQUE",
      label: "CHEQUE",
    },
    {
      id: 3,
      value: "CREDIT CARD",
      label: "CREDIT CARD",
    },
    {
      id: 4,
      value: "DEBIT CARD",
      label: "DEBIT CARD",
    },
    {
      id: 5,
      value: "UPI",
      label: "UPI",
    },
  ]);

  // other variables
  const watchPaymentCart = watch("paymentCart");

  let consumeAdvanceFlagVal = watch("consumeAmount");

  let refundVal = props.getValues("refundAmount");

  let totalAmount = 0;
  let netPayErrorMessage1 = "";
  let paymentInfoList = [];

  // function
  const controlledPaymentCart = fields.map((field, index) => {
    return {
      ...field,
      ...watchPaymentCart[index],
    };
  });

  let payments = "";
  React.useEffect(() => {
    if (watchPaymentCart.length > 0) {
      for (let paymentObj of watchPaymentCart) {
        if (paymentObj?.paymentMode?.label) {
          payments = payments + paymentObj?.paymentMode?.label;
        }
      }
    }
    let newPaymentArr = [];
    for (let payment of paymentModeList) {
      if (
        !payments
          ?.toLocaleLowerCase()
          .includes(payment?.label?.toLocaleLowerCase())
      ) {
        newPaymentArr.push(payment);

        setPaymentModeList(newPaymentArr);
      }
    }
  }, [watchPaymentCart]);

  React.useEffect(() => {
    //storing the patient's advance in the state variable called "advance"
    if (props.advanceAmount === 0 || props.advanceAmount === undefined) {
      setAdvance(0);
    } else if (props.advanceAmount !== 0) {
      setAdvance(props.advanceAmount);
    }
  }, [errors, advance, props.advanceAmount]);

  React.useEffect(() => {
    if (controlledPaymentCart.length > 0) {
      let paidAmount = 0;
      for (let paymentObj of controlledPaymentCart) {
        if (paymentObj.amount) {
          paidAmount = parseInt(paidAmount) + parseInt(paymentObj.amount);
        }
      }
      let netPayableAmount = props.getValues("netPayableAmount");
      if (paidAmount > netPayableAmount) {
      }
    }
  }, [props, controlledPaymentCart]);

  // call dropdown api in useEffect
  React.useEffect(() => {
    getAdvancedDropdown().then((response) => {
      setAdvancedList(response.data.result);
    });

    getBankList().then((response) => {
      setBankList(response.data.result);
    });

    getPaymentMode().then((response) => {
      setPaymentModeList(response.data.result);

      setValue(`paymentCart.0.paymentMode`, response.data.result[1]);
    });

    getUpiType()
      .then((response) => response.data)
      .then((res) => {
        setUpiTypes(res.result);
      });

    setValue(`paymentCart.0.amount`, props.refundAmount);
    // setRefundAmount(props.refundAmount);
  }, [setValue, props.refundAmount]);

  // when consume advance flag false
  React.useEffect(() => {
    if (consumeAdvanceFlag === false) {
      setNetPayableAmountVal(props.refundAmount);
      setValue("consumeAmount", "");
      // clear error if any in consume amount field
      clearErrors("consumeAmount");
    }
  }, [consumeAdvanceFlag, props.refundAmount, clearErrors, setValue]);

  // functions
  // open confirmaition modal
  const handleOpenConfirmation = () => {
    if (
      netPayErrorMessage1 === "" &&
      consumeErrorMsgOne === "" &&
      consumeErrorMsgTwo === ""
    ) {
      setConfirmationOpen(true);
    }
  };

  // close confirmation modal
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  //calculate sum of all add button
  function calculateFieldAmountSum() {
    let totalAmount = 0;

    //Calculate the sum of all the paid amounts from various payment modes and store it in the variable totalAmount.
    for (let cartObj of watchPaymentCart) {
      if (watchPaymentCart.length > 1) {
        if (cartObj && cartObj.amount) {
          totalAmount = Number(totalAmount) + Number(cartObj.amount);
        }
      } else if (watchPaymentCart.length === 1 && cartObj.amount) {
        totalAmount = Number(cartObj.amount);
      }
    }

    return totalAmount;
  }

  if (watchPaymentCart[0].amount < props.getValues("refundAmount")) {
    netPayErrorMessage1 = "Total Amount Must Be Equal To Refund Amount";
  } else {
    netPayErrorMessage1 = "";
  }

  //update amount change from amount input filed
  function handleAmountChange() {
    //The below if block is to display the "+" icon only at the first field ; when the length of the field is 1.
    if (watchPaymentCart.length === 1) {
      let firstFieldAmount = Number(watchPaymentCart[0].amount);

      if (firstFieldAmount === props.refundAmount) {
        setShowAddIcon(false);
      } else if (firstFieldAmount < props.refundAmount) {
        setShowAddIcon(true);
      }
    }

    let totalSumVal = calculateFieldAmountSum();

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (props.refundAmount !== null) {
      if (totalSumVal > props.refundAmount) {
        setNetPayErrorMessage("Amount Exceeds Current Bill Total");
      } else {
        setNetPayErrorMessage("");
      }
    }
  }

  // cash options allow only once
  function detectDuplicateCashOption() {
    let cashCounterIndicator = 0;
    for (let cartArr of watchPaymentCart) {
      if (cartArr.paymentMode.label === "CASH") {
        cashCounterIndicator = cashCounterIndicator + 1;
      }
      if (cashCounterIndicator > 1) {
        setCustomCashError(true);
      } else {
        setCustomCashError(false);
      }
    }
  }
  const resetPaymentModes = () => {
    getPaymentMode().then((response) => {
      setPaymentModeList(response.data.result);
      setValue(`paymentCart.0.paymentMode`, response.data.result[1]);
    });
  };

  //function that is called after clicking the submit button of the payment info modal
  function onSubmitDataHandler(data) {
    //  props.setOpenBackdrop(true);
    let paymentCart = data.paymentCart;

    for (let formObj of paymentCart) {
      let obj = {
        amount: 0,
        bankId: 0,
        paymentDate: "",
        paymentType: {
          id: 0,
          label: "",
          value: "",
        },
        upiname: "",
        referenceNumber: "",
      };

      obj.amount = formObj.amount;
      obj.paymentType = formObj.paymentMode;

      if (formObj.hasOwnProperty("upiname")) {
        obj.upiTypeId = formObj?.upiname?.id;
      }

      if (formObj.hasOwnProperty("referenceNumber")) {
        obj.referenceNumber = formObj.referenceNumber;
      }

      if (formObj.hasOwnProperty("bankName") && formObj["bankName"] !== null) {
        obj.bankId = formObj.bankName.id;
      }

      if (formObj.hasOwnProperty("paymentDate")) {
        obj.paymentDate = formObj.paymentDate;
      }

      paymentInfoList.push(obj);
      setPaymentInfo(paymentInfoList);
      if (netPayErrorMessage === "") {
        handleOpenConfirmation();
      }
    }

    // props.submitPaymentInfoModal(paymentInfoList);
  }
  function saveData(paymentInfo) {
    props.setOpenBackdrop(true);

    props.submitPaymentInfoModal(paymentInfo);
    // props.setOpenBackdrop(false);
    reset();
    handleCloseConfirmation();
  }

  function appendPaymentField(fieldIndex) {
    let requiredObj = watchPaymentCart[fieldIndex];

    //if the payment mode is "CASH"

    let totalAmountVal = calculateFieldAmountSum();

    //check CASH Payment mode
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "CASH") {
      if (requiredObj.amount || requiredObj.amount === 0) {
        if (totalAmountVal < props.refundAmount) {
          let nextFieldAmountVal = props.refundAmount - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        } else {
          trigger();
        }
      }
    }

    //payment mode is UPI
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "UPI") {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < props.refundAmount && customCashError === false) {
          let nextFieldAmountVal = props.refundAmount - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the payment mode is one of "CHEQUE" , "CREDIT CARD" , "DD" , "DEBIT CARD"
    if (
      requiredObj.paymentMode &&
      (requiredObj.paymentMode.label === "ECS" ||
        requiredObj.paymentMode.label === "CREDIT CARD" ||
        requiredObj.paymentMode.label === "DEBIT CARD" ||
        requiredObj.paymentMode.label === "ONLINE")
    ) {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber &&
        requiredObj.bankName &&
        requiredObj.bankName.label !== " "
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < props.refundAmount && customCashError === false) {
          let nextFieldAmountVal = props.refundAmount - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the paymentMode is not selected -- that is when it is undefined ; then at that time also trigger the validation.
    if (!requiredObj.paymentMode) {
      trigger();
    }

    //if the paymentMode is selected as blank ; then at that time also trigger the validation.
    if (requiredObj.paymentMode.label === " ") {
      trigger();
    }
  }

  function removePaymentFieldRow(fieldIndex) {
    let requiredObj = watchPaymentCart[fieldIndex];

    let totalSumVal = 0;
    for (let index = 0; index < watchPaymentCart.length; index++) {
      if (watchPaymentCart.length > 1) {
        if (watchPaymentCart[index] && watchPaymentCart[index].amount) {
          if (index !== fieldIndex) {
            totalSumVal =
              Number(totalSumVal) + Number(watchPaymentCart[index].amount);
          }
        }
      } else if (
        watchPaymentCart.length === 1 &&
        watchPaymentCart[index].amount
      ) {
        totalSumVal = Number(watchPaymentCart[index].amount);
      }
    }
    remove(fieldIndex);
  }

  function handleConsumeAmountChange(consumedAmountNum) {
    //Here the "requiredBillAmount" means the Total Bill Amount.
    let requiredBillAmountVal = Number(props.refundAmount);

    let consumeAdvanceFlagVal = getValues("consumeAdvance");

    //We need to change the "netPayableAmountVal" upon changes in consumeAmountVal.
    let tempNetPayableAmountVal =
      Number(requiredBillAmountVal) - Number(consumedAmountNum);

    tempNetPayableAmountVal = Number(tempNetPayableAmountVal.toFixed(3));

    //When the advance is not null and the amount is being consumed ; then at that time
    //remove all the fields except the first one.
    if (advance !== 0 && consumeAdvanceFlag === true) {
      if (fields.length > 1) {
        remove();
        setShowAddIcon(false);
      }
    }

    //Set the latest net payable amount into the first field.
    //remove all the fields except the first one.
    setValue(`paymentCart.[0].amount`, tempNetPayableAmountVal);

    //If there is any previous error on the first field's amount InputField
    clearErrors(`paymentCart.[0].amount`);

    //set the payment mode to CASH
    setValue(`paymentCart.[0].paymentMode`, {
      id: 4,
      label: "CASH",
      value: "CASH",
    });

    tempNetPayableAmountVal = Number(tempNetPayableAmountVal.toFixed(1));

    //setting the state variable to update the ui

    if (Number(tempNetPayableAmountVal) === 0.0) {
      setNetPayableAmountVal(0);
    } else {
      setNetPayableAmountVal(tempNetPayableAmountVal);
    }

    //show the below error message if the consumed amount's value exceeds available patient advance.

    if (consumedAmountNum > advance) {
      setConsumeErrorMsgOne(
        "Note* : Consume Amount Exceeded Available Advance Balance"
      );
    }

    //show the below error message ; if consumedAmountNum becomes greater than the Total Bill Amount.
    if (
      consumedAmountNum > requiredBillAmountVal ||
      tempNetPayableAmountVal < 0.0
    ) {
      setConsumeErrorMsgTwo("Note* : Consume Amount Exceeded Bill Amount");
    }

    //Do not show any error message ; if the consumed amount value is less than or equal to available advance consumed amount value is less than or equal to Total Bill Amount .
    if (consumedAmountNum <= advance) {
      setConsumeErrorMsgOne("");
    }

    // Do not show any error message if the consumed amount value is less than or equal to Total Bill Amount.
    if (consumedAmountNum <= requiredBillAmountVal) {
      setConsumeErrorMsgTwo("");
    }
  }

  const handleCancelClick = () => {
    props.handleClose();
    reset(); // Reset the form fields
    // Other operations you want to perform on button click
    props.setAdvanceConsumeAmount(0);
    setValue("consumeAmount", null);
    props.setAdvanceBalance(0);
    setNetPayableAmountVal(0);
    setPaymentModeList([]);
    setPaymentInfo([]);
  };

  return (
    <div className=" bg-white px-6">
      <Modal
        open={props.open}
        onClose={() => {
          // props.handleClose();
          props.setSelectedPatient("");
          props.setOptions([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              type="button"
              onClick={handleCancelClick} // Call the function to handle button click
              style={{
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="row">
            <fieldset className="border border-gray-300 text-left   lg:mx-auto lg:px-4 md:ml-0 md:mr-0  rounded   lg:m-2 ">
              <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                Bill Information
              </legend>
              <form
                onSubmit={handleSubmit(onSubmitDataHandler)}
                className="grid grid-cols-1 md:grid-cols-1  gap-2"
              >
                <div className=" bg-white px-4 ">
                  <>
                    {/* Paitent Inf0 */}
                    <fieldset className="mx-auto border w-full rounded my-2 bg-gray-100 px-4">
                      <legend className=" rounded-xl">
                        <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                          Patient Information
                        </p>
                      </legend>
                      <>
                        <div className="mb-2 grid  lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-2 ">
                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                            <span className="w-24 font-semibold">
                              Patient Name
                            </span>
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {props.selectedPatient &&
                                props.selectedPatient.patientName}
                            </span>
                          </div>
                          <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                            <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                              MR No.
                            </span>
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {props.selectedPatient &&
                                props.selectedPatient.mrNo}
                            </span>
                          </div>

                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                            <span className="w-24 xl:w-8 font-semibold">
                              Age
                            </span>
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {props.selectedPatient &&
                                props.selectedPatient.age}
                            </span>
                          </div>

                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                            <span className=" w-32  ">Net Payable Amount</span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                                ₹{netPayableAmountVal}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    </fieldset>

                    <fieldset className="mx-auto border w-full rounded my-3 bg-gray-100 px-4">
                      <legend className="ml-2 rounded-xl">
                        <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                          Patient Advance Information
                        </p>
                      </legend>
                      <div className=" flex gap-2  ">
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-28 whitespace-nowrap ">
                            Advance Available &nbsp;: &nbsp; ₹
                            {props.advanceAmount ? props.advanceAmount : 0}
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span className="text-gray-700 font-normal"></span>
                          </div>
                        </div>
                        {props.advanceAmount > 0 ? (
                          <>
                            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 ">
                              <div className="">
                                <input
                                  type="checkbox"
                                  id="consumeAdvance"
                                  name="consumeAdvance"
                                  {...register("consumeAdvance")}
                                  // value="false"
                                  onChange={(e) => {
                                    //if the consumeAmount flag is unticked then there is no question of "Consume Amount" input field
                                    if (e.target.checked === true) {
                                      props.setIsAdvanceConsumeFlag(true);
                                    } else {
                                      props.setIsAdvanceConsumeFlag(false);
                                    }
                                    if (e.target.checked === false) {
                                      setConsumeErrorMsgOne("");
                                      setConsumeErrorMsgTwo("");
                                    }

                                    setConsumedAdvanceFlag(e.target.checked);
                                  }}
                                />
                                <label
                                  htmlFor="consumeAdvance"
                                  className="pl-2"
                                >
                                  Consume Advance
                                </label>
                              </div>
                            </div>
                            {consumeAdvanceFlag === true ? (
                              <>
                                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                                  <InputField
                                    control={control}
                                    name="consumeAmount"
                                    label="Consume Amount"
                                    type="number"
                                    onWheel={(e) => {}}
                                    error={errors.consumeAmount}
                                    inputRef={{
                                      ...register(`consumeAmount`, {
                                        onChange: (e) => {
                                          let consumedAmountNum = Number(
                                            e.target.value
                                          );
                                          props.setAdvanceConsumeAmount(
                                            consumedAmountNum
                                          );

                                          handleConsumeAmountChange(
                                            consumedAmountNum
                                          );
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                        <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                          <span className=" font-semibold w-28 whitespace-nowrap">
                            Bill Amount
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              ₹{props.refundAmount}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                          <span className="font-semibold w-32 whitespace-nowrap">
                            Net Payable Amount
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span>:</span>
                            <span className="text-gray-700 font-normal ">
                              ₹{netPayableAmountVal}
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    {/* Payment Form */}
                    <div className="w-full">
                      <fieldset className="mx-auto border w-full rounded my-3">
                        <legend className="ml-6 my-0 rounded-xl">
                          <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                            Payment Details
                          </p>
                        </legend>

                        <div className="mx-auto px-6 py-2">
                          {fields.map((field, index) => {
                            let dropdownObj = watch(
                              `paymentCart.[${index}].paymentMode`
                            );

                            return (
                              <Grid container spacing={2} key={field.id}>
                                <>
                                  <Grid
                                    item
                                    lg={2.5}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    <DropdownField
                                      control={control}
                                      name={`paymentCart.[${index}].paymentMode`}
                                      error={
                                        watchPaymentCart[index].paymentMode
                                          ?.label === "CASH" &&
                                        customCashError === true
                                          ? {
                                              message:
                                                "Cash Allowed only once.",
                                            }
                                          : errors.paymentCart?.[index]
                                              ?.paymentMode
                                      }
                                      label="Payment Type"
                                      dataArray={paymentModeList}
                                      isSearchable={false}
                                      placeholder="Payment Type *"
                                      inputRef={{
                                        ...register(
                                          `paymentCart.[${index}].paymentMode`,
                                          {
                                            onChange: (e) => {
                                              detectDuplicateCashOption();
                                            },
                                          }
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  {dropdownObj &&
                                  dropdownObj.label === "UPI" ? (
                                    <>
                                      <Grid
                                        item
                                        lg={2.5}
                                        md={6}
                                        sm={6}
                                        sx={{ marginY: "0.5rem" }}
                                      >
                                        <DropdownField
                                          control={control}
                                          error={
                                            errors.paymentCart?.[index]?.upiname
                                          }
                                          name={`paymentCart[${index}].upiname`}
                                          label="Payment Type *"
                                          dataArray={upiTypes}
                                          isSearchable={false}
                                          placeholder="UPI Type*"
                                          inputRef={{
                                            ...register(
                                              `paymentCart[${index}].upiname`,
                                              {
                                                onChange: (e) => {},
                                              }
                                            ),
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <Grid
                                    item
                                    lg={2}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    <InputField
                                      name={`paymentCart.[${index}].amount`}
                                      error={
                                        errors.paymentCart?.[index]?.amount
                                      }
                                      variant="outlined"
                                      label="Amount"
                                      control={control}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      inputRef={{
                                        ...register(
                                          `paymentCart.[${index}].amount`,
                                          {
                                            onChange: (e) => {
                                              handleAmountChange();
                                            },
                                          }
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </>

                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "UPI" ||
                                  dropdownObj.label === "ONLINE") ? (
                                  <>
                                    <Grid
                                      item
                                      lg={2}
                                      md={6}
                                      sm={6}
                                      sx={{ marginY: "0.5rem" }}
                                    >
                                      <InputField
                                        name={`paymentCart.[${index}].referenceNumber`}
                                        variant="outlined"
                                        label="Reference Number"
                                        control={control}
                                        error={
                                          errors.paymentCart?.[index]
                                            ?.referenceNumber
                                        }
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "ONLINE") ? (
                                  <>
                                    <Grid
                                      item
                                      lg={2.5}
                                      md={6}
                                      sm={6}
                                      sx={{ marginY: "0.5rem" }}
                                    >
                                      <DropdownField
                                        control={control}
                                        name={`paymentCart.[${index}].bankName`}
                                        label="Bank Name"
                                        dataArray={bankList}
                                        isSearchable={true}
                                        placeholder="Bank Name *"
                                        searchIcon={true}
                                        error={
                                          errors.paymentCart?.[index]?.bankName
                                        }
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                {/* Date Picker Component */}
                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "ONLINE") ? (
                                  <>
                                    <Grid
                                      lg={2}
                                      sm={6}
                                      sx={{
                                        marginY: "1.6rem",
                                        marginLeft: 2,
                                      }}
                                    >
                                      <Controller
                                        control={control}
                                        defaultValue={new Date()}
                                        rules={{
                                          required: true,
                                        }}
                                        render={({ field }) => (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DatePicker
                                              // open={openDate}
                                              // onOpen={() => setOpenDate(true)}
                                              // onClose={() => setOpenDate(false)}
                                              inputProps={{
                                                readOnly: true,
                                              }}
                                              // disablePast
                                              // readOnly={true}

                                              renderInput={(props) => (
                                                <TextField
                                                  {...props}
                                                  type="date"
                                                  variant="outlined"
                                                  label="Registration Date"
                                                  name="paymentDate"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  fullWidth
                                                  size="small"
                                                  // onClick={(e) => setOpenDate(true)}
                                                />
                                              )}
                                              inputFormat="dd/MM/yyyy"
                                              disablePast
                                              {...field}
                                              onAccept={(e) => {
                                                // getNewRegDate(e, index);
                                              }}
                                              // error={
                                              //   errors.paymentDetails?.[index]
                                              //     ?.paymentDate
                                              // }
                                              // helperText={errors.paymentDate?.message}
                                            />
                                          </LocalizationProvider>
                                        )}
                                        name={`paymentCart.[${index}].paymentDate`}
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                <div className="flex items-center">
                                  {showAddIcon === true && index === 0 ? (
                                    <AddOutlinedIcon
                                      className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                      onClick={() => {
                                        if (netPayErrorMessage === "") {
                                          appendPaymentField(index);
                                        }
                                      }}
                                    />
                                  ) : null}

                                  {index > 0 && fields.length > 1 ? (
                                    <>
                                      <RemoveOutlinedIcon
                                        className="mt-2 mx-1 rounded-full border-2 border-red-600"
                                        onClick={() => {
                                          if (fields.length > 1) {
                                            removePaymentFieldRow(index);
                                          }
                                        }}
                                      />
                                     {fields?.length<4? <AddOutlinedIcon
                                        className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                        onClick={() => {
                                          if (netPayErrorMessage === "") {
                                            appendPaymentField(index);
                                          }
                                        }}
                                      />:null}
                                    </>
                                  ) : null}
                                </div>
                                {/* </Grid> */}
                              </Grid>
                            );
                          })}
                          <span className=" text-red-500">
                            {netPayErrorMessage}
                          </span>
                        </div>
                      </fieldset>

                      <Grid
                        item
                        lg={1.5}
                        md={1.5}
                        className="flex justify-end gap-2 py-2"
                      >
                        <CancelButton
                          onClick={() => {
                            props.handleClose();
                            resetPaymentModes();
                            setPaymentModeList([]);
                            reset();
                          }}
                        />
                        <SaveButton
                          // let consumeAmountFlag=
                          // let consumeAmountVal=
                          onClick={() => {
                            //sum of all the amounts in the field
                            let totalOfFieldAmounts = calculateFieldAmountSum();

                            if (
                              netPayErrorMessage === "" &&
                              totalOfFieldAmounts === netPayableAmountVal
                            ) {
                              //open the confirmation modal
                              setConfirmationOpen(true);
                            }
                          }}
                        />
                      </Grid>
                    </div>
                  </>
                </div>
              </form>
            </fieldset>
            <div>
              {consumeErrorMsgOne !== "" ? (
                <span className="text-red-500 flex">{consumeErrorMsgOne}</span>
              ) : null}
            </div>

            <div>
              {consumeErrorMsgTwo !== "" ? (
                <span className="text-red-500 flex">{consumeErrorMsgTwo}</span>
              ) : null}
            </div>
            <CommonBackDrop openBackdrop={props.openBackdrop} />
          </div>
        </Box>
      </Modal>
      {confirmationOpen ? (
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={() => {
            saveData(paymentInfo);
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Payment Details?"
          confirmationButtonMsg="Save Payment"
        />
      ) : null}
    </div>
  );
}
