import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getDailyOrderList = (searchObj) => {
  return apiClient.post(`/grn/dailyOrderList`, searchObj, {
    headers: authHeader(),
  });
};

export const getItemDetailsList = (doId, supplierId) => {
  return apiClient.get(`/grn/dailyOrderDetails/${doId}/${supplierId}`, {
    headers: authHeader(),
  });
};

export const fetchAllPrintGrnList = (obj) => {
  return apiClient.post(`/reports/inventory/GRNList`, obj, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchPrintGrnList = (grnId) => {
  return apiClient.get(`/reports/inventory/goodReceiptNote/${grnId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};
