import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import { useEffect } from "react";

export default function MedicationErrorTable(props) {
  //Multiselect checkbox
  const handleClick = (e, row) => {
    let arr = [...props.errorIds];
    if (e.target.checked === true) {
      arr.push(row.id);
      props.setErrorIds(arr);
    } else if (e.target.checked === false) {
      let filter = arr.filter((item) => item !== row.id);

      props.setErrorIds(filter);
    }
  };

  useEffect(() => {
    let arr = [...props.errorIds];
    for (let i = 0; i < props.data.length; i++) {
      if (props.selectedMedcationRow?.errorIdList.includes(props.data[i].id)) {
        arr.push(props.data[i].id);
      }
    }

    props.setErrorIds(arr);
  }, [props.data]);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded border h-[200px] 2xl:h-72"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold">Select</span>
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      // key={index}
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Errors</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow
                        sx={{
                          "& td": {
                            paddingY: 0.4,
                          },
                        }}
                        key={index.id}
                        tabIndex={-1}
                      >
                        <TableCell>
                          <Checkbox
                            size="small"
                            className="w-3 h-3 border border-gray-600 rounded bg-transparent"
                            type="checkbox"
                            color="primary"
                            name={`checkbox${index + 1}`}
                            onChange={(e) => {
                              handleClick(e, row);
                            }}
                            defaultChecked={
                              props.selectedMedcationRow?.errorIdList.includes(
                                row.id
                              )
                                ? true
                                : false
                            }
                          />
                        </TableCell>

                        <TableCell className="whitespace-nowrap" key={row.id}>
                          {row.label}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}

// useEffect(() => {
//   // let check = props.selectedMedcationRow.errorIdList.some(element => element=== false);
//   for (let i = 0; i < props.data.length; i++) {
//     if (props.selectedMedcationRow.errorIdList.includes(props.data[i].id)) {
//
//     }
//   }
// }, [props.selectedMedcationRow])
