import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllInputOutput = (admissionId, status) => {
  return apiClient.get(
    `/clinicalCareChart/inputOutputList/${admissionId}/${status}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveInputOutput = async (addNewInputOutput) => {
  return await apiClient.post(
    `/clinicalCareChart/saveInputOutput`,
    addNewInputOutput,
    {
      headers: authHeader(),
    }
  );
};

export const inputOutputViewList = async (inputOutputList) => {
  return await apiClient.post(
    `/clinicalCareChart/inputOutputViewList`,
    inputOutputList,
    {
      headers: authHeader(),
    }
  );
};


export const deleteInputOutput = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deleteInputOutput/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const IntakeOuttakePrintData = (postObj) => {
  return apiClient.post(`/reports/nursing/cccIntakeOuttake`, postObj, {
   headers: authHeader(),
   responseType:"blob"
  });
};
