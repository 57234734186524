import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ResetButton from "../../../../common/components/Buttons/ResetButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  successAlert
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { searchSupplier } from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import {
  fetchListOfSupplierAdvance,
  getAdvanceAgainst,
  getPODetails,
  getPOWONumber,
  getTDS,
  saveSupplierAdvance,
} from "../../../services/supplierAdvance/SupplierAdvanceService";
import BillingInformationModal from "./BillingInformationModal";


const actions = ["print"];

const removeHeaders = [""];
const SupplierAdvance = () => {
  // useform
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required(),
    advAgainst: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required(),
    store: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required(),
    powoNo: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required(),
    netAmount: yup.number(),
    amount: yup
      .number()
      .typeError("Required")
      .min(1, "Required")
      .required("Required")
      .when("netAmount", (netAmount, schema) => {
        console.log("netAmountnetAmountnetAmount", netAmount);

        return schema.test({
          test: (amount) => {
            if (!amount) return true;
            console.log("netAmountnetAmountnetAmount", netAmount);
            return amount <= netAmount;
          },
          message: "Amount Exceeds Than Total Advance",
        });
      }),

    remarks: yup.string(),
  });
  const defaultValues = {
    supplierName: null,
    advAgainst: null,
    store: null,
    powoNo: null,
    tdsType: null,
    tdsAmount: 0,
    amount: "",
    netAmount: 0,
    gstAmount: 0,
    totalpowo: 0,
    remarks: "",
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    watch,
    getValues,
    trigger,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variable
  const [open, setOpen] = React.useState(false);
  const [supplier, setSupplier] = React.useState([]);
  const [advAgainst, setAdvAgainst] = React.useState([]);
  const [store, setStore] = React.useState([]);
  const [poWoNO, setPoWoNo] = React.useState([]);
  const [tds, setTds] = React.useState([]);
  const [supplierList, setSupplierList] = React.useState([]);
  const [totalPoWOAmt, setTotalPoWoAmt] = React.useState(0);
  const [supplierAdvObj, setSupplierAdvObj] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState(null);
  const[openBackdrop,setOpenBackdrop] = React.useState(false);

  // other variables
  let supplierVal = watch("supplierName");
  let advAgainstVal = watch("advAgainst");
  let storeVal = watch("store");
  let powoNoVal = watch("powoNo");
  let amountVal = watch("amount");
  let tdsVal = getValues("tdstype");
  console.log("powoNoValpowoNoValpowoNoValpowoNoVal", supplierVal?.id);
  // get menu id
  let location = useLocation();
  console.log("locationlocation", location?.state?.menuId);
  // useEffect
  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          console.log("getUserActions", res.result);
          setUserActions(res.result);
        });
  }, []);
  // fetch list of advances
  useEffect(() => {
    handleChangeAdvAgainest();
    handleChangeTDS();
  }, []);
  useEffect(() => {
    populateTable();
  }, [supplierVal]);
  useEffect(() => {
    if (supplierVal?.id !== null && advAgainstVal?.id !== null) {
      handleChangeStore();
    }
  }, [supplierVal, advAgainstVal]);
  console.log("supplierListsupplierList", supplierList);
  // functions
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function populateTable() {
    fetchListOfSupplierAdvance(supplierVal?.id)
      .then((response) => response.data)
      .then((res) => {
        console.log("listOfSupplierAdvance", res);
        setSupplierList(res.result);
      }).catch((error) => {
        
        
        console.log("errorAlerterrorAlert", error);

      });
  }

  // supplier dropdown api
  function handleSupplierChange(autoSearchSupplier) {
    if (autoSearchSupplier !== "") {
      searchSupplier(autoSearchSupplier)
        .then((response) => response.data)
        .then((res) => {
          setSupplier(res.result);
        }).catch((error) => {
        
          
          console.log("errorAlerterrorAlert", error);
  
        });
    }
  }
  // advance againest dropdown api
  function handleChangeAdvAgainest() {
    if (advAgainstVal !== "") {
      getAdvanceAgainst()
        .then((response) => response.data)
        .then((res) => {
          setAdvAgainst(res.result);
        });
    }
  }
  // store dropdown from supplier and adv againest
  function handleChangeStore() {
    getPODetails(supplierVal?.id, advAgainstVal?.id)
      .then((response) => response.data)
      .then((res) => {
        setValue("store", res.result[0]);
        setStore(res.result);
        console.log("fetchStore", res);
      }).catch((error) => {
        
        
        console.log("errorAlerterrorAlert", error);

      });
  }

  function handleChangeTDS() {
    getTDS()
      .then((response) => response.data)
      .then((res) => {
        setTds(res.result);
        console.log("fetchStore", res);
      }).catch((error) => {
        
      
        console.log("errorAlerterrorAlert", error);

      });
  }
  function submitPaymentInfoModal(paymentInfoList) {
    console.log("paymentInfoListpaymentInfoList", paymentInfoList);
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfo);
    let postObj = {
      addedBy: userInfoObj.userId,
      advanceBalance: 0,
      againstId: advAgainstVal?.id,
      netAmount: getValues("netAmount"),
      paymentInfoList: paymentInfoList,
      po_wo_id: powoNoVal?.id,
      remark: getValues("remarks"),
      storeId: storeVal?.id,
      supplierId: supplierVal?.id,
      tdsAmount: getValues("tdsAmount") ? Number(getValues("tdsAmount")) : 0,
      tdsLadger: tdsVal?.label,
      totalAdvance: getValues("amount"),
      menuId:location?.state?.menuId,
      privilege:privilege
    };
    setOpenBackdrop(true)
    addSupplierAdvanceRecord(postObj)

  }
  function addSupplierAdvanceRecord(postObj)
  {
    handleClose()
    console.log("postObjpostObjpostObjpostObjpostObj", postObj);
    saveSupplierAdvance(postObj)
      .then((response) => response.data)
      .then((res) => {
        setSupplierAdvObj(res.result);
        successAlert(res.result);
        setOpenBackdrop(false)
        handleClose();
        reset(defaultValues);
        populateTable();
      }).catch((error) => {
        
        setOpenBackdrop(false)
        console.log("errorAlerterrorAlert", error);

      });
  }
  function onSubmitDataHandler() {
    
  }
  function handleSelectedRow() {}
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitDataHandler)}
        className="grid grid-cols-1 md:grid-cols-1  gap-2"
      >
        <div className="mt-14 space-y-2">
          <div className="text-center w-full">
            <label className="text-xl font-semibold">Supplier Advance</label>
          </div>
          <div className="grid grid-cols-6 gap-2">
            <div>
              <SearchDropdown
                control={control}
                error={errors.supplierName}
                name="supplierName"
                label="Supplier*"
                placeholder="Supplier*"
                searchIcon={true}
                isSearchable={true}
                isClearable={true}
                dataArray={supplier}
                handleInputChange={handleSupplierChange}
              />
            </div>
            <div>
              <DropdownField
                control={control}
                name="advAgainst"
                placeholder="Advance Against *"
                dataArray={advAgainst}
                error={errors.advAgainst}
                inputRef={{
                  ...register("advAgainst", {
                    onChange: (e) => {
                      console.log("SupplierSupplierSupplier", e);
                      if (e.target.value === null) {
                        setValue("store", null);
                        setValue("powoNo", null);
                        setValue("amount", 0);
                        setValue("netAmount", 0);
                        setValue("tdsAmount", 0);
                        setValue("tdsType", null);
                        setValue("totalpowo", 0);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div>
              <DropdownField
                control={control}
                name="store"
                placeholder="Store *"
                dataArray={store}
                error={errors.store}
                inputRef={{
                  ...register("store", {
                    onChange: (e) => {
                      console.log(
                        "supplierValsupplierValsupplierValsupplierVal",
                        supplierVal?.id
                      );
                      console.log(
                        "advAgainstValadvAgainstValadvAgainstValadvAgainstVal",
                        advAgainstVal?.id
                      );
                      if (e.target.value !== null) {
                        getPOWONumber(
                          supplierVal?.id,
                          advAgainstVal?.id,
                          storeVal?.id
                        )
                          .then((response) => response.data)
                          .then((res) => {
                            setPoWoNo(res.result);
                            console.log("responseresult", res.result);

                            let powoObj = {};
                            powoObj.id = res.result[0]?.id;
                            powoObj.value = res.result[0]?.label;
                            powoObj.label = res.result[0]?.value;

                            setValue("powoNo", powoObj);
                            setValue("amount", res.result[0]?.NetAmount);
                            setValue("totalpowo", res.result[0]?.NetAmount);
                            setTotalPoWoAmt(res.result[0]?.NetAmount);
                            setValue("netAmount", res.result[0]?.NetAmount);
                          });
                      }
                    },
                  }),
                }}
              />
            </div>
            <div>
              <DropdownField
                control={control}
                name="powoNo"
                placeholder="PO/WO No. *"
                dataArray={poWoNO}
                error={errors.powoNo}

                // inputRef={{...register("powoNo",{
                //   onChange: (e) => {
                //     console.log("e.target.value=" , e.target.value);
                //     setValue("amount", e.target.value?.NetAmount);
                //     setValue("totalpowo", e.target.value?.NetAmount);
                //     setTotalPoWoAmt(e.target.value?.NetAmount);
                //     setValue("tdsAmount",0)
                //     setValue("netAmount", e.target.value?.NetAmount);
                //   }
                // })}}
              />
            </div>
            <div>
              <InputField
                control={control}
                name="amount"
                label="Amount *"
                error={errors.amount}
              />
            </div>

            <div>
              <InputField
                control={control}
                name="tdsAmount"
                label="TDS"
                inputRef={{
                  ...register("tdsAmount", {
                    onChange: (e) => {
                      setValue("totalpowo", totalPoWOAmt - e.target.value);
                    },
                  }),
                }}
              />
            </div>
            <DropdownField
              control={control}
              name="tdsType"
              placeholder="TDS Type"
              dataArray={tds}
              error={errors.tdstype}
            />
            <div>
              <InputField
                control={control}
                name="netAmount"
                label="Net Amount"
              />
            </div>
            <div>
              <InputField
                control={control}
                name="totalpowo"
                label="Total PO/WO"
                disabled={true}
              />
            </div>
            <div className="col-span-1.5">
              <InputField control={control} name="remarks" label="Remarks " />
            </div>
            <div className="flex gap-2">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                     <ResetButton
                        onClick={() => {
                          reset(defaultValues);
                        }}
                      />
                      <CommonButton
                       
                        label="Add"
                        className="bg-green-600 text-white"
                        onClick={() => {
                          trigger();
                          console.log(
                            "errorsisshowmeerrorsisshowmeerrorsisshowme",
                            supplierVal,
                            isValid,
                            errors
                          );

                          if (supplierVal === null) {
                            // errorAlert("Select Advance From List");
                          } else if (
                            errors?.["amount"]?.message ===
                            "Amount Exceeds Than Total Advance"
                          ) {
                            // errorAlert("Refund Amount Must Be Greater Than 1");
                          } else if (supplierVal !== null) {
                            handleOpen();
                              setPrivilege(obj.action)
                          }
                        }}
                      />
                     
                    </>
                  ) : null}
                </>
              ))}
              {/* <button
                type="button"
                className="h-9 px-3 text-base font-medium bg-green-600 text-white rounded "
                onClick={() => {
                  trigger();
                  console.log(
                    "errorsisshowmeerrorsisshowmeerrorsisshowme",
                    supplierVal,
                    isValid,
                    errors
                  );

                  if (supplierVal === null) {
                    // errorAlert("Select Advance From List");
                  } else if (
                    errors?.["amount"]?.message ===
                    "Amount Exceeds Than Total Advance"
                  ) {
                    // errorAlert("Refund Amount Must Be Greater Than 1");
                  } else if (supplierVal !== null) {
                    handleOpen();
                  }
                }}
              >
                Add
              </button> */}
              {/* <ResetButton
                onClick={() => {
                  reset(defaultValues);
                }}
              /> */}
            </div>
          </div>
          <div className="space-y-2">
            <label className="font-semibold">List Of Supplier Advance</label>
            <div></div>
          </div>
          <div>
            {/* {supplierList.hasOwnProperty("result") &&
            supplierList.result.length > 0 ? (
              <CommonDynamicTable data={supplierList} actions={actions} />
            ) : null} */}
            {supplierList.length > 0 ? (
              <CommonDynamicTable
                data={supplierList}
                actions={actions}
                actionsRequired={false}
                setSelectedRow={setSelectedRow}
                handleSelectedRo={handleSelectedRow}
                highlightRow={false}
                tableClass={"rounded 2xl:[500px] lg:h-86 md:h-72"}
                removeHeaders={removeHeaders}
              />
            ) : null}
          </div>
        </div>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop}/>
      <BillingInformationModal
        open={open}
        getValues={getValues}
        handleClose={handleClose}
        submitPaymentInfoModal={submitPaymentInfoModal}
        refundAmount={amountVal}
        supplierVal={supplierVal}
        menuId={location?.state?.menuId}
        privilege={privilege}
        setPrivilege={setPrivilege}
        
      />
    </>
  );
};

export default SupplierAdvance;
