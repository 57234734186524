import { Modal } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";

import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import BedScoreAssessment from "./initialnursingtabs/BedScoreAssessment";
import NursingSynopsis from "./initialnursingtabs/NursingSynopsis";
import PatientFallAssessment from "./initialnursingtabs/PatientFallAssessment";
import PatientInformation from "./initialnursingtabs/PatientInformation";
import PatientVulnerabilityCriteria from "./initialnursingtabs/PatientVulnerabilityCriteria";
import View from "./initialnursingtabs/View";

import {
  fetchInitialNursingAssessment,
  saveInitialNursingAssessment,
} from "../../../services/cliinicalChartServices/initialNursingAssessment/InitialNursingAssessmentServices";

import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";

import ConfirmationModal from "../../../../common/components/ConfirmationModal";

import AssessmentListingModal from "./AssessmentListingModal";

import CommonButton from "../../../../common/components/Buttons/CommonButton";

import { FormProvider, useForm } from "react-hook-form";

import {
  errorAlertCustom,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";

import getInitialNursingAssessmentPtInfo from "./CalculationOfInitialNursingAssessment/getInitialNursingAssessmentPtInfo";

import getPatientVulnerabilityCriteria from "./CalculationOfInitialNursingAssessment/getPatientVulnerabilityCriteria";

import getBedScoreAssesment from "./CalculationOfInitialNursingAssessment/getBedScoreAssesment";

import getNursingSynopsis from "./CalculationOfInitialNursingAssessment/getNursingSynopsis";

import getPatientFallAssessment from "./CalculationOfInitialNursingAssessment/getPatientFallAssessment";

import patchPatientInformation from "./PatchIncomingAssessmentData/patchPatientInformation";

import patchPatientFallAssessment from "./PatchIncomingAssessmentData/patchPatientFallAssessment";

import patchPatientVulnerability from "./PatchIncomingAssessmentData/patchPatientVulnerability";

import patchBedScoreTable from "./PatchIncomingAssessmentData/patchBedScoreAssessment";

import patchNursingSynopsis from "./PatchIncomingAssessmentData/patchNursingSynopsis";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "85%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  // p: 2,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function InitialNursingModal(props) {
  const Actions = [
    {
      id: 0,
      action: "Save",
      isAction: false,
    },
    {
      id: 1,
      action: "Update",
      isAction: false,
    },
  ];

  const [reassessmentButton, setReassessmentButton] = React.useState(false);

  const [bedAssessmentScore, setBedAssessmentScore] = React.useState(0);

  //state variable to open the confirmation modal ; for confirmation of saving payment
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const [updateConfirmationOpen, setUpdateConfirmationOpen] =
    React.useState(false);

  const [localFormDisableFlag, setLocalFormDisableFlag] = React.useState(false);

  const [patchedNursingInfo, setPatchedNursingInfo] = React.useState(null);

  //state variable to display OR to not display the CommonBackDrop component.
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [assessmentListModal, showAssessmentListModal] = React.useState(false);

  const [bedScoreAssessmentArray, setBedScoreAssessmentArray] = React.useState([
    {
      sensoryPerCompletelyLimited: false,
      score: 1,
      flagValue: false,
    },
    {
      sensoryPerVeryLimited: false,
      score: 2,
      flagValue: false,
    },
    {
      sensoryPerSlightlyLimited: false,
      score: 3,
      flagValue: false,
    },
    {
      sensoryPerNoImpairment: false,
      score: 4,
      flagValue: false,
    },

    {
      moistureCompletelyLimited: false,
      score: 1,
      flagValue: false,
    },
    {
      moistureVeryLimited: false,
      score: 2,
      flagValue: false,
    },
    {
      moistureOccasionallyLimited: false,
      score: 3,
      flagValue: false,
    },
    {
      rarelyMoist: false,
      score: 4,
      flagValue: false,
    },

    {
      activeBedLying: false,
      score: 1,
      flagValue: false,
    },
    {
      activeChairDependent: false,
      score: 2,
      flagValue: false,
    },
    {
      activeWalksOccasionally: false,
      score: 3,
      flagValue: false,
    },
    {
      activeWalksFrequency: false,
      score: 4,
      flagValue: false,
    },

    {
      mobilityCompletelyLimited: false,
      score: 1,
      flagValue: false,
    },
    {
      mobilityVeryLimited: false,
      score: 2,
      flagValue: false,
    },
    {
      mobilitySlightlyLimited: false,
      score: 3,
      flagValue: false,
    },
    {
      mobilityNoImpairment: false,
      score: 4,
      flagValue: false,
    },

    {
      nutritionVeryPoor: false,
      score: 1,
      flagValue: false,
    },
    {
      nutritionProbablyInadequate: false,
      score: 2,
      flagValue: false,
    },

    {
      nutritionAdequate: false,
      score: 3,
      flagValue: false,
    },

    {
      nutritionExcellent: false,
      score: 4,
      flagValue: false,
    },

    {
      frictionAndShearProblem: false,
      score: 1,
      flagValue: false,
    },
    {
      frictionAndShearPotentialProblem: false,
      score: 2,
      flagValue: false,
    },
    {
      frictionAndShearNoApparentProblem: false,
      score: 4,
      flagValue: false,
    },
  ]);

  const defaultValues = {
    //patientInformation
    height: "",
    weightInKgs: 0,
    isBandWithCorrectInformation: true,
    knownAllergies: "",
    disabilities: "",
    externalEquipments: [],
    staffName: "",

    //patientFallAssessment
    highFallRisk: [],
    lowFallRisk: [],

    //patientFallAssesmentTable
    Age: null,
    Elimination: null,
    Medications: null,
    PatientCareEquipment: null,
    FallHistory: null,
    mobilityOne: false,
    mobilityTwo: false,
    mobilityThree: false,
    cognitionOne: false,
    cognitionTwo: false,
    cognitionThree: false,

    //Nursing Synopsis
    reasonForHospitalization: "",
    doctorInform: "",
    patientSymptoms: "",
    patientSurgery: "",
    patientHandOver: "",
    handOverToName: "",
    relationToPatient: "",
    assessedByName: "",

    //Bed Score Assessment
    sensoryPerCompletelyLimited: false,
    sensoryPerVeryLimited: false,
    sensoryPerSlightlyLimited: false,
    sensoryPerNoImpairment: false,

    moistureCompletelyLimited: false,
    moistureVeryLimited: false,
    moistureOccasionallyLimited: false,
    rarelyMoist: false,

    activeBedLying: false,
    activeChairDependent: false,
    activeWalksOccasionally: false,

    mobilityCompletelyLimited: false,
    mobilityVeryLimited: false,
    mobilitySlightlyLimited: false,
    mobilityNoImpairment: false,

    nutritionVeryPoor: false,
    nutritionProbablyInadequate: false,
    nutritionAdequate: false,
    nutritionExcellent: false,

    frictionAndShearProblem: false,
    frictionAndShearPotentialProblem: false,
    frictionAndShearNoApparentProblem: false,

 
  };

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues,
  });

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  const updateConfirmationClose = () => {
    if (updateConfirmationOpen === true) {
      setUpdateConfirmationOpen(false);
    }
  };

  React.useEffect(() => {
    let admissionIdVal = null;

    if (props.patientInformation === "") {
      admissionIdVal = props.admissionId;
    } else if (props.patientInformation !== "") {
      admissionIdVal = props.patientInformation.AdmissionId;
    }

    fetchInitialNursingAssessment(admissionIdVal)
      .then((response) => {
        //Below is the response of GET request when we are filling the form for the first time.
        //   {
        //     "statusCode": 200,
        //     "message": "Initial Nursing Assessment retrieved Successfully...",
        //     "result": {
        //         "count": 0,
        //         "bedScoreAssPatchDto": null,
        //         "nursingSynopsisPatchDto": null,
        //         "ptFallAssPatchDto": {
        //             "hisFallMorethan1in6Months": null,
        //             "compParal_Immobiliz": null,
        //             "score": null,
        //             "fallAssId": null,
        //             "hopkinsScoreHeaderPatchDtoList": null,
        //             "ptexpFallDurHospitalization": null,
        //             "ptdeemedHighRiskProtocol": null
        //         },
        //         "ptInfoPatchDto": null,
        //         "vulnerabilityPatchDto": null
        //     },
        //     "actions": null,
        //     "count": null,
        //     "countInfo": null
        // }

        if (response.data.result.count !== 0) {
          let patientInfoObj = response.data.result.ptInfoPatchDto;
          patchPatientInformation(patientInfoObj, methods);

          let patientFallAssessmentObj = response.data.result.ptFallAssPatchDto;
          patchPatientFallAssessment(patientFallAssessmentObj, methods);

          let vulnerabilityPatchDtoObj =
            response.data.result.vulnerabilityPatchDto;
          patchPatientVulnerability(vulnerabilityPatchDtoObj, methods);

          let bedScoreAssPatchDtoObj = response.data.result.bedScoreAssPatchDto;

          let tempbedScoreAssessmentArray = structuredClone(
            bedScoreAssessmentArray
          );

          let requiredBedScoreAssessmentArray = patchBedScoreTable(
            bedScoreAssPatchDtoObj,
            methods,
            tempbedScoreAssessmentArray
          );

          //modify this state variable to properly patch the values of the bedScoreAssessment table.
          setBedScoreAssessmentArray(requiredBedScoreAssessmentArray);

          let nursingSynopsisObj = response.data.result.nursingSynopsisPatchDto;
          patchNursingSynopsis(nursingSynopsisObj, methods);

          // if (
          //   bedScoreAssPatchDtoObj.totalScore >= 0 &&
          //   bedScoreAssPatchDtoObj.totalScore <= 60
          // ) {
          //   methods.setValue("noRiskFlag", true);
          // }

          // if (
          //   bedScoreAssPatchDtoObj.totalScore >= 61 &&
          //   bedScoreAssPatchDtoObj.totalScore <= 100
          // ) {
          //   methods.setValue("lowRiskFlag", true);
          // }

          // if (bedScoreAssPatchDtoObj.totalScore > 100) {
          //   methods.setValue("highRiskFlag", true);
          // }

          const isAgeOver75 = props.selectedPatient.Age > 75;
          const mildRiskRangeLow = isAgeOver75 ? 15 : 15;
          const mildRiskRangeHigh = isAgeOver75 ? 18 : 16;

          if (
            props.bedAssessmentScore >= mildRiskRangeLow &&
            props.bedAssessmentScore <= mildRiskRangeHigh
          ) {
            methods.setValue("mildRiskFlag", true);
          } else {
            methods.setValue("mildRiskFlag", false);
          }

          if (
            props.bedAssessmentScore >= 12 &&
            props.bedAssessmentScore <= 14
          ) {
            methods.setValue("moderateRiskFlag", true);
          } else {
            methods.setValue("moderateRiskFlag", false);
          }

          if (props.bedAssessmentScore < 12) {
            methods.setValue("highRiskFlag", true);
          } else {
            methods.setValue("highRiskFlag", false);
          }

          setBedAssessmentScore(bedScoreAssPatchDtoObj.totalScore);
          setPatchedNursingInfo(response);
        }

        if (response.data.result.count === 1) {
          //display the reassessment buttons
          setReassessmentButton(true);
        }
      })
      .catch((error) => {
        errorAlertCustom(error.message);
      });
  }, []);

  React.useEffect(() => {}, [bedAssessmentScore]);

  function updateTheExistingRecord(data) {
    let requiredObj = {
      initialNursigAssNursingSynopsisDto: null,
      initialNursingAssBedScoreAssDto: null,
      initialNursingAssPTFallAssDto: null,
      initialNursingAssPTInfoDto: null,
      initialNursingAssVulnerabilityDto: null,
    };

    let admissionIdVal = null;

    if (props.patientInformation === "") {
      admissionIdVal = props.admissionId;
    } else if (props.patientInformation !== "") {
      admissionIdVal = props.patientInformation.AdmissionId;
    }

    //Patient Information object
    let initialNursingAssPTInfoDto = getInitialNursingAssessmentPtInfo(
      data,
      patchedNursingInfo,
      admissionIdVal
    );

    requiredObj.initialNursingAssPTInfoDto = initialNursingAssPTInfoDto;

    let initialNursingAssVulnerabilityDto =
      getPatientVulnerabilityCriteria(data);

    requiredObj.initialNursingAssVulnerabilityDto =
      initialNursingAssVulnerabilityDto;

    let initialNursingAssBedScoreAssDto = getBedScoreAssesment(data);

    requiredObj.initialNursingAssBedScoreAssDto =
      initialNursingAssBedScoreAssDto;

    let initialNursigAssNursingSynopsisDto = getNursingSynopsis(data);

    requiredObj.initialNursigAssNursingSynopsisDto =
      initialNursigAssNursingSynopsisDto;

    let initialNursingAssPTFallAssDto = getPatientFallAssessment(
      data,
      admissionIdVal,
      patchedNursingInfo
    );

    requiredObj.initialNursingAssPTFallAssDto = initialNursingAssPTFallAssDto;

    setOpenBackdrop(true);

    //console.log("requiredObj",requiredObj);

    saveInitialNursingAssessment(requiredObj)
      .then((response) => {
        setOpenBackdrop(false);
        successAlert(response.data.message);
        if (updateConfirmationOpen === true) {
          updateConfirmationClose();
          props.handleClose();
        }
      })
      .catch((error) => {
        setOpenBackdrop(false);

        if (updateConfirmationOpen === true) {
          updateConfirmationClose();
        }
      });
  }

  function addTheNewRecord(data) {
    // console.log("requiredObjnew", requiredObj);

    let requiredObj = {
      initialNursigAssNursingSynopsisDto: null,
      initialNursingAssBedScoreAssDto: null,
      initialNursingAssPTFallAssDto: null,
      initialNursingAssPTInfoDto: null,
      initialNursingAssVulnerabilityDto: null,
    };

    let admissionIdVal = null;

    if (props.patientInformation === "") {
      admissionIdVal = props.admissionId;
    } else if (props.patientInformation !== "") {
      admissionIdVal = props.patientInformation.AdmissionId;
    }

    //Patient Information object
    let initialNursingAssPTInfoDto = getInitialNursingAssessmentPtInfo(
      data,
      patchedNursingInfo,
      admissionIdVal
    );

    requiredObj.initialNursingAssPTInfoDto = initialNursingAssPTInfoDto;

    let initialNursingAssVulnerabilityDto =
      getPatientVulnerabilityCriteria(data);

    requiredObj.initialNursingAssVulnerabilityDto =
      initialNursingAssVulnerabilityDto;

    let initialNursingAssBedScoreAssDto = getBedScoreAssesment(data);

    requiredObj.initialNursingAssBedScoreAssDto =
      initialNursingAssBedScoreAssDto;

    let initialNursigAssNursingSynopsisDto = getNursingSynopsis(data);

    requiredObj.initialNursigAssNursingSynopsisDto =
      initialNursigAssNursingSynopsisDto;

    let initialNursingAssPTFallAssDto = getPatientFallAssessment(
      data,
      admissionIdVal,
      patchedNursingInfo
    );

    requiredObj.initialNursingAssPTFallAssDto = initialNursingAssPTFallAssDto;

    //If we are doing reassessment ; then at that time initialNursingAssId should be sent as 0 in the postObj.
    if (reassessmentButton === true) {
      initialNursingAssPTInfoDto.initialNursingAssId = 0;

      let userInfoString = localStorage.getItem("userInfo");

      let userInfoObj = JSON.parse(userInfoString);

      let addedByVal = userInfoObj.userId;

      initialNursingAssPTInfoDto.addedBy = addedByVal;
    }

    saveInitialNursingAssessment(requiredObj)
      .then((response) => {
        successAlert(response.data.message);
        props.handleClose();
        //  setOpenBackdrop(false);
        confirmationHandleClose();
      })
      .catch((error) => {
        //  setOpenBackdrop(false);
        confirmationHandleClose();
      });
  }

  const onSubmitDataHandler = (data) => {
    if (patchedNursingInfo) {
      updateTheExistingRecord(data);
    } else if (patchedNursingInfo === null) {
      addTheNewRecord(data);
    }
  };

  console.log(
    "props.patientInformation,props.selectedPatient?.PatientName",
    props.patientInformation,
    props.selectedPatient
  );

  return (
    <>
      <div className=" backdrop-blur-0 bg-white z-50 top-0">
        <Modal
          open={props.open}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className=""
        >
          <Box
            sx={ModalStyle}
            className="w-[80%] h-[88%] xl:h-[80%] mt-2 xl:max-h-[90%]"
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmitDataHandler)}>
                <div className="sticky top-0 bg-white z-50 pb-0.4">
                  <div className="pb-2">
                    <CancelPresentationIconButton
                      onClick={() => {
                        props.handleClose();
                        setReassessmentButton(false);
                      }}
                    />
                  </div>
                  <div className="px-4 bg-white">
                    <div className="w-full text-normal font-semibold text-center ">
                      Initial Nursing Assessment
                    </div>

                    <div className="grid border bg-gray-100 border-gray-300 px-3 rounded mt-2 ">
                      <div className="grid grid-cols-2  lg:grid-cols-3 gap-2 py-2">
                        <div className="grid gap-2  border-r-2  border-slate-500 my-1 ">
                          {/* Patient Name , UHID */}
                          <div className="flex gap-2 text-sm">
                            <h1 className="text-black font-semibold flex space-x-3">
                              <span>Patient Name </span>
                              <span className=""> :</span>
                            </h1>
                            <h1 className="text-black font-normal">
                              {props.patientInformation.PatientName ||
                                props.selectedPatient?.PatientName}
                            </h1>
                          </div>
                          <div className="flex gap-2 text-sm">
                            <h1 className="text-black font-semibold flex space-x-[52px]">
                              <span>MR No.</span>
                              <span className="">:</span>
                            </h1>
                            <h1 className="text-black font-normal">
                              {props.patientInformation.MRNo ||
                                props.selectedPatient?.MRNo}
                            </h1>
                          </div>
                        </div>

                        <div className="grid gap-2  lg:border-r-2 pl-4 border-slate-500 my-1 ">
                          <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                            <span className="w-24 font-semibold">
                              Department
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="font-normal">
                                {props.patientInformation.Department ||
                                  props.selectedPatient?.Department}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-2 text-sm">
                            <h1 className="text-black font-semibold flex space-x-20">
                              <span>Age</span>
                              <span className="">:</span>
                            </h1>
                            <h1 className="text-black font-normal">
                              {props.patientInformation.AgeYear ||
                                props.selectedPatient?.AgeYear}
                            </h1>
                          </div>
                        </div>

                        {/* Dr Name */}
                        <div className="grid gap-2  pl-4 ">
                          <div className="flex space-x-1 items-center  gap-2 w-full font-semibold text-sm py-1">
                            <span className="font-semibold whitespace-nowrap">
                              Doctore Name
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="font-normal">
                                {props.patientInformation.DoctorName ||
                                  props.selectedPatient?.DoctorName}
                              </span>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>

                    <div className=" px-2  mt-2 rounded-md ">
                      <Tabs
                        className="text-left mt-2 font-semibold "
                        onChange={handleChange}
                        value={tabValue}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#0B83A5",
                            color: "#0B83A5",
                          },
                        }}
                        // textColor="#0B83A5"
                        textColor="#0B83A5"
                        indicatorColor="#0B83A5"
                        variant="scrollable"
                        scrollButtons
                      >
                        <Tab
                          label="Patient's Information"
                          className="PatientInformation"
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                          }}
                          {...a11yProps(0)}
                        />
                        <Tab
                          label="John Hopkins Patient Fall Assessment"
                          className="PatientFallAssessment"
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                          }}
                          {...a11yProps(1)}
                        />
                        <Tab
                          label="Patient Vulnerability Criteria"
                          className="PatientVulnerabiltiyCriteria"
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                          }}
                          {...a11yProps(2)}
                        />
                        <Tab
                          label="Braden Risk Assessment Scale"
                          className="BedScoreAssessment"
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                          }}
                          {...a11yProps(3)}
                        />
                        <Tab
                          label="Nursing Synopsis"
                          className="NursingSynopsis"
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                          }}
                          {...a11yProps(4)}
                        />
                        {/* <Tab
                        label="View"
                        className="view"
                        style={{
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                        {...a11yProps(5)}
                      /> */}
                      </Tabs>
                    </div>
                  </div>
                </div>

                <div className=" ">
                  <div className="">
                    <TabPanel value={tabValue} index={0}>
                      <PatientInformation
                        patchedNursingInfo={patchedNursingInfo}
                      />
                    </TabPanel>
                  </div>
                  <TabPanel value={tabValue} index={1}>
                    <PatientFallAssessment
                      patchedNursingInfo={patchedNursingInfo}
                      setLocalFormDisableFlag={setLocalFormDisableFlag}
                      localFormDisableFlag={localFormDisableFlag}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <PatientVulnerabilityCriteria
                      patchedNursingInfo={patchedNursingInfo}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <BedScoreAssessment
                      setBedAssessmentScore={setBedAssessmentScore}
                      bedAssessmentScore={bedAssessmentScore}
                      patchedNursingInfo={patchedNursingInfo}
                      bedScoreAssessmentArray={bedScoreAssessmentArray}
                      setBedScoreAssessmentArray={setBedScoreAssessmentArray}
                      selectedPatient={props.selectedPatient}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <NursingSynopsis patchedNursingInfo={patchedNursingInfo} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <View />
                  </TabPanel>
                </div>

                <div className="flex gap-2 justify-end px-4">
                  {Actions.map((singleActionObj) => {
                    if (
                      singleActionObj.isAction === false &&
                      singleActionObj.action === "Update" &&
                      patchedNursingInfo !== null &&
                      reassessmentButton === true
                    ) {
                      return (
                        <>
                          <div>
                            <CommonButton
                              label="View Assessments"
                              className="bg-customBlue text-white"
                              onClick={() => {
                                showAssessmentListModal(true);
                              }}
                            />
                          </div>

                          <CommonButton
                            label="Reassessment"
                            className="h-9 px-3 w-full  bg-[#4B5563] text-white rounded text-sm font-bold"
                            onClick={() => {
                              methods.reset(defaultValues);
                              setPatchedNursingInfo(null);

                              let tempBedScoreAssessmentArray = structuredClone(
                                bedScoreAssessmentArray
                              );

                              for (let tempObj of tempBedScoreAssessmentArray) {
                                if (tempObj.flagValue === true) {
                                  tempObj.flagValue = false;
                                }
                              }

                              setBedScoreAssessmentArray(
                                tempBedScoreAssessmentArray
                              );
                            }}
                          />
                          {props.patientInformation?.AllowToGO === true ||
                          props.selectedPatient?.AllowToGO === true ? null : (
                            <CommonButton
                              label="Update"
                              className="bg-customGreen text-white"
                              onClick={() => {
                                setUpdateConfirmationOpen(true);
                              }}
                            />
                          )}
                        </>
                      );
                    } else if (
                      singleActionObj.isAction === false &&
                      singleActionObj.action === "Update" &&
                      patchedNursingInfo !== null &&
                      reassessmentButton === false
                    ) {
                      return (
                        <>
                          <div>
                            <CommonButton
                              label="View Assessments"
                              className="bg-customBlue text-white"
                              onClick={() => {
                                showAssessmentListModal(true);
                              }}
                            />
                          </div>
                          {props.patientInformation?.AllowToGO === true ||
                          props.selectedPatient?.AllowToGO === true ? null : (
                            <div>
                              <CommonButton
                                label="Update"
                                className="bg-customGreen text-white"
                                onClick={() => {
                                  setUpdateConfirmationOpen(true);
                                }}
                              />
                            </div>
                          )}
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>

                <div className="flex gap-2 justify-end px-4">
                  {Actions.map((singleActionObj) => {
                    if (
                      patchedNursingInfo === null &&
                      singleActionObj.isAction === false &&
                      singleActionObj.action === "Save"
                    ) {
                      return (
                        <>
                          <div className="flex gap-2">
                            <div>
                              <CommonButton
                                label="View Assessments"
                                className="bg-customBlue text-white"
                                onClick={() => {
                                  showAssessmentListModal(true);
                                }}
                              />
                            </div>
                            <div>
                              <CommonButton
                                label="Reset"
                                className="border border-customRed text-customRed"
                                onClick={() => {
                                  methods.reset(defaultValues);
                                  setLocalFormDisableFlag(false);

                                  let tempBedScoreAssessmentArray =
                                    structuredClone(bedScoreAssessmentArray);

                                  for (let tempObj of tempBedScoreAssessmentArray) {
                                    if (tempObj.flagValue === true) {
                                      tempObj.flagValue = false;
                                    }
                                  }

                                  setBedScoreAssessmentArray(
                                    tempBedScoreAssessmentArray
                                  );
                                }}
                              />
                            </div>
                            {props.patientInformation?.AllowToGO === true ||
                            props.selectedPatient?.AllowToGO === true ? null : (
                              <div>
                                <CommonButton
                                  label="Save"
                                  className="bg-customGreen text-white"
                                  onClick={() => {
                                    //  setOpenBackdrop(true);
                                    setConfirmationOpen(true);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </form>
            </FormProvider>
          </Box>
        </Modal>
      </div>
      {assessmentListModal ? (
        <AssessmentListingModal
          open={assessmentListModal}
          setOpen={showAssessmentListModal}
          patientInformation={props.patientInformation}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={confirmationHandleClose}
        confirmationSubmitFunc={() => {
          methods.handleSubmit(onSubmitDataHandler)();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Initial Nursing Assessment ?"
        confirmationButtonMsg="Save"
      />
      <ConfirmationModal
        confirmationOpen={updateConfirmationOpen}
        confirmationHandleClose={updateConfirmationClose}
        confirmationSubmitFunc={() => {
          methods.handleSubmit(onSubmitDataHandler)();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Update Initial Nursing Assessment ?"
        confirmationButtonMsg="Update"
      />
      {/* backdrop */}
      // <CommonBackDrop openBackdrop={openBackdrop} />
    </>
  );
}

export default InitialNursingModal;

