import { yupResolver } from "@hookform/resolvers/yup";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { format } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchAllDischargeList,
  patientAllowToGoToSleep,
  searchDischargeList,
} from "../../services/discharge/DischargeServices";
import DischargeModal from "./DischargeModal";
import ReasonForDischarge from "./ReasonForDischarge";
import {
  AllowGoSlipIcon,
  AllowGoSlipIconDisabled,
  AllowGoSlipIconEnabled,
} from "../../../assets/icons/CustomIcons";
import { logDOM } from "@testing-library/react";
export default function Discharge() {
  /*************************** useForm ********************************/
  const schema = yup.object().shape({});
  const defaultValues = {
    fromDateCalender: new Date(),
    SearchableSelect: "",
    toDateCalender: new Date(),
  };
  const {
    control,
    watch,
    reset,
    setValue,
    register,
    formState: {},
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  /*************************** state variables ********************************/
  const [data, setData] = React.useState([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dataResult, setDataResult] = React.useState([]);
  const [count, setCount] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [openDischargeModal, setOpenDischargeModal] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState("");

  const [menuIdVal, setMenuIdVal] = React.useState(null);

  /*************************** other variables ********************************/
  let dichargeListVal = watch("searchDischargeList");
  let fromDateCalenderVal = watch("fromDateCalender");
  let toDateCalenderVal = watch("toDateCalender");
  
  let location = useLocation();
  

  /*************************** useEffects ********************************/
  //visit listing
  useEffect(() => {
    let forPagination = 0;
    let listObj = {
      fromDate: selectedFromDate,
      menuId: location?.state?.menuId,
      page: !forPagination ? 0 : page,
      searchId: searchString?.id ? searchString?.id : "",
      searchString: "",
      size: rowsPerPage,
      toDate: selectedToDate,
    };

    fetchAllDischargeList(listObj)
      .then((response) => response.data)
      .then((res) => {
        setCount(res.count);
        setDataResult(res.result);
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        
        setUserActions(res.actions);
      })
      .catch(() => {
        
      });
  }, [selectedFromDate, selectedToDate, searchString]);

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          
          setUserActions(res.result);
        });
  }, []);

  /*************************** functions ********************************/
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenConfirmatoin = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleOpenDischargeModal = () => {
    setOpenDischargeModal(true);
  };
  const handleCloseDischargeModal = () => {
    setOpenDischargeModal(false);
  };

  const handleChange = (dischargeSearch) => {
    let searchObj = {
      fromDate: selectedFromDate,

      searchString: searchString,

      toDate: selectedToDate,
    };
    
    if (dischargeSearch !== "") {
      searchDischargeList(searchObj)
        .then((response) => response.data)
        .then((res) => {
          
          setSearchOptions(res.result);
        });
    }
  };

  function autoSelectedValue(selectedValue) {
    console.log("selectedValue",selectedValue);
    if (selectedValue !== "") {
      setSearchString(selectedValue);
    } else if (selectedValue === "") {
      setSearchString("");
    }
  }
  const populateTable = (forPagination) => {
    let obj = {
      fromDate: fromDateCalenderVal,
      menuId: location?.state?.menuId,
      page: !forPagination ? 0 : page,
      searchId: searchString?.id ? searchString?.id : "",
      searchString: "",
      size: rowsPerPage,
      toDate: toDateCalenderVal,
    };

    fetchAllDischargeList(obj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.data.count);
      })
      .catch(() => {});
  };
  //

  function displayData(row) {
    
    setRowData(row.AdmissionId);
  }
  function allowToGoToSleep() {
    let requirdData = userActions.find((obj) => {
      if (obj.action === "Allow To Go Slip") {
        return obj?.functionalityId;
      }
    });
    
    
    //requirdData?.action,
    if (rowData.AdmissionId !== null) {
      patientAllowToGoToSleep(
        rowData,

        requirdData?.functionalityId
      )
        .then((response) => response.data)
        .then((res) => {
          
          if (res.statusCode === 200) {
            successAlert(res.message);
            populateTable();
          } else {
            errorAlert(res.message);
          }
        });
    }
  }

  /////
  const renderActions = (row, index) => {
    

    return (
      <div className="flex gap-1 items-center cursor-pointer">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  {actions.action === "Allow To Go Slip" ? (
                    <Tooltip title="Allow To Go Slip">
                      <IconButton
                        disabled={row.allowgotoslip === true ? true : false}
                        onClick={() => {
                          
                          displayData(row);
                          handleOpenConfirmatoin();
                        }}
                        sx={{ padding: 0 }}
                      >
                        {row.allowgotoslip === true ? (
                          <AllowGoSlipIconDisabled />
                        ) : (
                          <AllowGoSlipIconEnabled />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {actions.action === "Cancel" ? (
                    <Tooltip title="Discharge Cancel" className="pt-1">
                      <IconButton
                        disabled={row.allowgotoslip === true ? true : false}
                        onClick={() => {
                          
                          setSelectedPatient(row);
                          handleOpenDischargeModal();
                        }}
                        sx={{ padding: 0 }}
                      >
                        <HighlightOffRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  // useEffect(()=>{
  //   iconStateGenerator()
  // },[])
  // function iconStateGenerator(inputObj) {
  //   let outputObj = {};

  //   if (inputObj.IsBillGenerated === true && inputObj.allowgotoslip
  //     === true) {
  //   outputObj.IsBillGenerated = true;
  //   outputObj.allowgotoslip = true;
  //   }

  //   if (inputObj.IsBillGenerated === true && inputObj.allowgotoslip === false) {
  //   outputObj.IsBillGenerated = true;
  //   outputObj.allowgotoslip = false;
  //   }

  //   if (inputObj.IsBillGenerated === false && inputObj.allowgotoslip === true) {
  //   outputObj.IsBillGenerated = true;
  //   outputObj.allowgotoslip = true;
  //   }

  //   if (inputObj.IsBillGenerated === false && inputObj.allowgotoslip === false) {
  //   outputObj.IsBillGenerated = false;
  //   outputObj.allowgotoslip = false;
  //   }

  //   return outputObj;
  //  }
  //  let inputObj={IsBillGenerated:false,allowgotoslip:false}
  // let requiredOutPut=iconStateGenerator(inputObj)
  return (
    <>
      <div className="pt-12">
        <div className="text-center text-2xl text-gray-700 font-Poppins pb-2">
          Discharge List
        </div>
        <div className="">
          <fieldset
            className="col-span-3 w-full lg:py-1
               text-left lg:px-0 md:px-4 rounded "
          >
            <div className="lg:flex md:grid md:grid-cols-5 p-1 lg:px-3 md:px-2 gap-2 items-center">
              <div className="lg:col-span-2 w-[50%] md:col-span-2 z-50">
                <SearchBar
                  searchIcon={true}
                  isClearable={true}
                  name="SearchableSelect"
                  placeholder="Search By Patient Name / MRNo./ Mobile No. "
                  dataArray={searchOptions}
                  isSearchable={true}
                  handleInputChange={handleChange}
                  onChange={autoSelectedValue}

                />
              </div>

              <div className="lg:32">
                <DatePickerFieldNew
                  control={control}
                  name="fromDateCalender"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                  inputRef={{
                    ...register("fromDateCalender", {
                      onChange: (e) => {
                        if (e?.target?.value?.toString()?.length === 55) {
                          let formatedDate = format(
                            e.target.value,
                            "yyyy-MM-dd"
                          );
                          let formatedDateTime =
                            formatedDate + "T" + "07:44:11.341Z";
                          setSelectedFromDate(formatedDateTime);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="toDateCalender"
                  label="To Date"
                  value={new Date()}
                  disableFuture={false}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                  inputRef={{
                    ...register("toDateCalender", {
                      onChange: (e) => {
                        if (e.target.value.toString().length === 55) {
                          let dateVal = format(e.target.value, "yyyy-MM-dd");
                          let timeVal = dateVal + "T" + "07:44:11.341Z";
                          setSelectedToDate(timeVal);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="flex gap-2">
                <div className="">
                  <CommonButton
                    searchIcon={true}
                    className="bg-customBlue text-white"
                    onClick={() => {
                      // setDischarge()
                      //populateTable()
                    }}
                  />
                </div>
                <div className="">
                  <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      setValue("SearchableSelect", "");
                      setSelectedToDate(new Date());
                      setSelectedFromDate(new Date());
                      reset();
                    }}
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center lg:gap-10 md:gap-10 2xl:gap-10">
            <div>
              <p className="font-bold text-sm  px-1 text-gray-700">
                List Of Discharge
              </p>
            </div>

            <div></div>
          </div>

          <div className="items-center pt-2 flex gap-2">
            {userActions &&
              userActions.map((actions, i) => (
                <>
                  {actions.isAction === false ? (
                    <>
                      <div className="flex gap-2 cursor-pointer">
                        {actions.action === "Create" ? (
                          <CommonButton
                            className="bg-customGreen text-white"
                            label="New"
                            type="submit"
                            onClick={() => {
                              handleOpen();
                              reset();
                              setMenuIdVal(actions);
                            }}
                          />
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </>
              ))}
          </div>
        </div>
        <CommonBackDrop openBackdrop={openBackdrop} />
        <div>
          {dataResult?.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              highlightRow={false}
              renderActions={renderActions}
              setRowsPerPage={setRowsPerPage}
              populateTable={populateTable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              tableClass="lg:h-[500px] 2xl:h-[650px]"
              removeHeaders={[
                "id",
                "Floor",
                "Block",
                "BedCategory",
                "DischargeId",
                "Discharged",
                "Ward",
                "isCancel",
                "allowgotoslip",
                "Unit",
                "PatientId",
                "AdmissionId",
                "AdmNo",
                "IsBillGenerated",
              ]}
            />
          ) : (
            <>
              <div className="text-center mt-10">No Record Found</div>
            </>
          )}

          <ConfirmationModal
            confirmationOpen={confirmationOpen}
            confirmationHandleClose={handleCloseConfirmation}
            confirmationSubmitFunc={() => {
              handleCloseConfirmation();
              allowToGoToSleep();
            }}
            confirmationLabel="Confirmation"
            confirmationMsg="Are you sure Allow to Go Slip ?"
            confirmationButtonMsg="Allow To Go Slip"
          />

          <DischargeModal
            open={open}
            setOpen={setOpen}
            openBackdrop={openBackdrop}
            setOpenBackdrop={setOpenBackdrop}
            handleClose={handleClose}
            populateTable={populateTable}
            userActions={userActions}
            menuIdVal={menuIdVal}
          />
          {openDischargeModal ? (
            <ReasonForDischarge
              openDischargeModal={openDischargeModal}
              setOpenDischargeModal={setOpenDischargeModal}
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              selectedPatient={selectedPatient}
              populateTable={populateTable}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
