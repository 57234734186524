import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const saveContraVoucherMultiple = (postObj) => {
  return apiClient.post(`/accounting/saveContraVoucherMultiple`, postObj, {
    headers: authHeader(),
  });
};

export const getAccountHead = () => {
  return apiClient.get(`/invWorkOrderGrn/accountHead`, {
    headers: authHeader(),
  });
};
