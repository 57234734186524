import { FormControl, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { fetchBatchNumberForGrn } from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import "./BatchNumberCss.css";
import BatchSelectionModal from "./BatchSelectionModal";
import { format } from "date-fns";

export default function ItemDetailsTable(props) {
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();
  // for table data
  const [indexVal, setIndexVal] = useState(null);
  const [itemDetailsData, setItemDetailsData] = useState([]);
  // dailog inputField
  const [searchValue, setSearchValue] = useState("");
  const [matchingEntries, setMatchingEntries] = useState([]);

  // end

  // selected batch batch information
  const [batchData, setBatchData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogClosedByButton, setDialogClosedByButton] = useState(false);
  //local variable
  let batchAvailable;
  let batchCodeField = useRef();
  let expiryDateValue = watch(`grnBatchCode[${indexVal}].expirayDate`);
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const freeQuantityValueRef = useRef();
  const isInputFocused = useRef(false);

  useEffect(() => {
    for (let i = 0; i < props.data.length; i++) {
      if (props.resetIndex === -1) {
        if (props.data.length > 0) {
          setValue(`PendQty${i}`, props.data[i].PendQty);
          setValue(`FreeQty${i}`, props.data[i].FreeQty);
          setValue(`grnBatchCode[${i}].batchCode`, props.data[i].batchCode);
          // setValue(`grnBatchCode[${i}].expirayDate`, new Date());
          setValue(`MRP${i}`, props.data[i].MRP);
          setValue(`Conversion${i}`, props.data[i].Conversion);
          setValue(`Consignment${i}`, props.data[i].Consignment);
          setValue(`DiscPer${i}`, props.data[i].DiscPer);
          setValue(`DiscAmt${i}`, props.data[i].DiscAmt);
          setValue(`VATPer${i}`, props.data[i].VATPer);
          setValue(`VATAmt${i}`, props.data[i].VATAmt);
          setValue(`otherCharges${i}`, props.data[i].otherCharges);
        }
      }
    }
  }, [props.resetIndex, props.data]);

  // enter key for table input field
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSelectedRow(null);
      setIndexVal(null);
      setIsDialogOpen(false);
    }
  };

  // enter key for modal input field
  const handleKeyPressForSearchBatchCode = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setIsDialogOpen(false);
      setValue("searchBatchCode", "");
      assignBatchNumberForItem(batchAvailable);
    }
  };

  // set batch number
  const assignBatchNumberForItem = (isBatchAvailable) => {
    let itemsInfo = props?.data;
    let selectedItem = itemsInfo[indexVal];
    let selectedItemRow = itemsInfo[indexVal];
    if (isBatchAvailable === undefined || isBatchAvailable === false) {
      setValue(`grnBatchCode[${indexVal}].batchCode`, searchValue);
      setValue(`grnBatchCode[${indexVal}].expirayDate`, new Date());
      if (selectedItemRow !== undefined) {
        selectedItemRow.requiredBatchNumber = "#0B83A5";
        selectedItemRow.batchCode = searchValue;
        // selectedItemRow.expiryDate = new Date();
      }
    } else if (isBatchAvailable === true && selectedRow !== null) {
      let gstPercent = getValues(`VATPer${indexVal}`);
      let receivedQty = getValues(`PendQty${indexVal}`);
      let rate = selectedRow.UnitPurchaseRate;
      let discountPer = getValues(`DiscPer${indexVal}`);
      let freeQty = getValues(`FreeQty${indexVal}`);
      let totalAmount = Number(receivedQty) * Number(rate);
      let discountAmt = (Number(discountPer) * totalAmount) / 100;
      let discountedAmt = totalAmount - discountAmt;
      let gstAmt = (Number(discountedAmt) * Number(gstPercent)) / 100;
      let netAmt = receivedQty * rate - discountAmt + Number(gstAmt);
      let landedRate = netAmt / (Number(receivedQty) + Number(freeQty));

      setValue(`grnBatchCode[${indexVal}].batchCode`, selectedRow.BatchCode);
      let batchDateIs = selectedRow?.BatchExpDate;
      const formatedDate = batchDateIs
        .toString()
        .split("-")
        .reverse()
        .join("-")
        .replaceAll("-", "-");
      setValue(`grnBatchCode[${indexVal}].expirayDate`, new Date(formatedDate));
      if (selectedItem) {
        selectedItem.Rate = selectedRow.UnitPurchaseRate;
        selectedItem.requiredBatchNumber = "#0B83A5";
        selectedItem.landedRate = landedRate.toFixed(2);
        selectedItem.Amount = totalAmount.toFixed(2);
        selectedItem.NetAmount = netAmt.toFixed(2);
        selectedItem.Consignment =
          selectedRow["Is Consignment"] === 1 ? true : false;
        selectedItem.Conversion = selectedRow.Conversion;
        selectedItem.batchCode = selectedRow?.BatchCode;
        selectedItem.expiryDate = formatedDate;
        
      }
    }
    itemsInfo[indexVal] = selectedItem;
    setIsDialogOpen(false);
  };

  // modal input field search data populate to table
  const handleInputChangeForBatchCode = (e) => {
    const input = e.target.value;
    setSearchValue(input);
    const entries = batchData.filter((entry) =>
      entry.BatchCode.toLowerCase().includes(input.toLowerCase())
    );
    setMatchingEntries(entries);
  };

  // for expiry date

  const handleChangeExpiryDate = (newValue, index) => {
    const currentDate = new Date();
    if (new Date(newValue) >= currentDate) {
      let requiredExpDate =
        expiryDateValue === undefined ? currentDate : new Date(newValue);
      let temp = [...props.data];
      let tempObj = temp[index];
      tempObj.expiryDate = format(
        requiredExpDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      temp[index] = tempObj;
      props.setData(temp);
    }
  };

  // calculations
  const batchCodeNumber = (
    index,
    batchNumber,
    Conversion,
    receivedQuantity,
    expiryDate,
    row
  ) => {
    if (batchNumber === "") {
      setValue(`grnBatchCode[${index}].batchCode`, "");
      setSearchValue("");
      setSelectedRow(null);
    }
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.batchCode = batchNumber;
    tempObj.MRP = row.MRP;
    tempObj.Conversion = row.Conversion;
    tempObj.Consignment = row.Consignment;
    tempObj.PendQty = Number(row.PendQty);
    tempObj.requiredBatchNumber = batchNumber !== "" ? "#0B83A5" : "#D11A2A";
    tempObj.recQty = Number(receivedQuantity);
    temp[index] = tempObj;
    props.setData(temp);
  };

  const calculatePurchaseRate = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstAmount,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let totalAmount = Number(receivedQuantity) * Number(row.Rate);
    let netAmt = receivedQuantity * row.Rate - row.DiscAmt + Number(row.VATAmt);
    let landedRate = netAmt / (Number(receivedQuantity) + Number(row.FreeQty));
    if (receivedQuantity) {
      if (receivedQuantity > row.PendQty) {
        row.color = "#D11A2A";
      } else {
        row.color = "#0B83A5";
      }
    }
    let temp = [...props.data];
    let tempObj = temp[index];

    tempObj.DOQty = Number(row.DOQty);
    tempObj.PendQty = Number(row.PendQty);
    tempObj.recQty = Number(receivedQuantity);
    tempObj.otherCharges = Number(otherCharges);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.DiscPer = Number(discountPercentage);
    tempObj.Consignment = Consignment;
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);
    tempObj.expiryDate =
      expiryDate === undefined
        ? new Date(row.expiryDate)
        : new Date(expiryDate);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.DiscAmt = Number(discountAmount);
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);
    temp[index] = tempObj;
    props.setData(temp);
  };

  const calculateFreeQuantity = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstAmount,
    gstPercentage,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let totalAmount = Number(row.DOQty) * Number(row.Rate);
    let discountAmt = (Number(row.DiscPer) * totalAmount) / 100;
    let discountedAmt = totalAmount - discountAmt;
    let gstAmt = (Number(discountedAmt) * Number(row.VATPer)) / 100;
    let netAmt = row.DOQty * Number(row.Rate) - row.DiscAmt + Number(gstAmount);
    let landedRate = netAmt / (Number(row.DOQty) + Number(freeQuantity));
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.DOQty = Number(row.DOQty);
    tempObj.PendQty = Number(row.PendQty);
    tempObj.recQty = Number(receivedQuantity);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.DiscPer = Number(discountPercentage);
    tempObj.otherCharges = Number(otherCharges);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.Consignment = Consignment;
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);
    tempObj.expiryDate = expiryDate ? expiryDate : new Date();
    tempObj.DiscAmt = Number(discountAmt.toFixed(2));
    tempObj.VATAmt = Number(gstAmt.toFixed(2));
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);
    temp[index] = tempObj;

    props.setData(temp);
  };

  const calculateDiscountAmount = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstAmount,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let tempGstAmount = watch(`VATAmt${index}`);

    let totalAmount = Number(row.DOQty) * Number(row.Rate);
    let discountAmt = (Number(discountPercentage) * totalAmount) / 100;

    let discountedAmt = totalAmount - discountAmt;

    let gstAmt = (Number(discountedAmt) * Number(row.VATPer)) / 100;

    let netAmt = row.DOQty * Number(row.Rate) - discountAmt + Number(gstAmt);

    let landedRate = netAmt / (Number(row.DOQty) + Number(row.FreeQty));
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.DOQty = Number(row.DOQty);
    tempObj.PendQty = Number(row.PendQty);
    tempObj.recQty = Number(receivedQuantity);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.otherCharges = Number(otherCharges);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.DiscPer = Number(discountPercentage);
    tempObj.DiscAmt = Number(discountAmt.toFixed(2));
    tempObj.Consignment = Consignment;
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.VATAmt = Number(gstAmt.toFixed(2));
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);

    setValue(`DiscAmt${index}`, discountAmt.toFixed(2));

    temp[index] = tempObj;

    props.setData(temp);
  };

  const calculateDiscountPercent = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstAmount,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let totalAmount = Number(row.DOQty) * Number(row.Rate);
    let discountPer = (Number(discountAmount) * 100) / totalAmount;
    let netAmt = row.DOQty * row.Rate - row.DiscAmt + Number(row.VATAmt);
    let landedRate = netAmt / (Number(row.DOQty) + Number(row.FreeQty));
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.DOQty = Number(row.DOQty);
    tempObj.PendQty = Number(row.PendQty);
    tempObj.recQty = Number(receivedQuantity);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.DiscPer = Number(discountPer);
    tempObj.otherCharges = Number(otherCharges);
    tempObj.DiscAmt = Number(discountAmount).toFixed(2);
    tempObj.Consignment = Consignment;
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);

    setValue(`DiscPer${index}`, discountPer);
    temp[index] = tempObj;
    props.setData(temp);
  };

  const calculateGstAmount = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstPercentage,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let totalAmount = Number(row.DOQty) * Number(row.Rate);

    let discountedAmt = totalAmount - row.DiscAmt;
    let gstAmt = (Number(totalAmount) * Number(gstPercentage)) / 100;

    let netAmt = row.DOQty * row.Rate - row.DiscAmt + Number(gstAmt);
    let landedRate = netAmt / (Number(row.DOQty) + Number(row.FreeQty));
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.DOQty = Number(row.DOQty);
    tempObj.PendQty = Number(row.PendQty);
    tempObj.recQty = Number(receivedQuantity);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.otherCharges = Number(otherCharges);
    tempObj.DiscPer = Number(discountPercentage);
    tempObj.Consignment = Consignment;
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);
    tempObj.DiscAmt = Number(discountAmount).toFixed(2);
    tempObj.VATPer = Number(gstPercentage);
    tempObj.VATAmt = Number(gstAmt).toFixed(2);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);

    setValue(`VATAmt${index}`, gstAmt.toFixed(2));
    temp[index] = tempObj;
    props.setData(temp);
  };

  const calculateOtherCharges = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstPercentage,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let totalAmount = Number(row.DOQty) * Number(row.Rate);

    let discountedAmt = totalAmount - row.DiscAmt;
    let gstAmt = (Number(discountedAmt) * Number(gstPercentage)) / 100;

    let netAmt = row.DOQty * row.Rate - row.DiscAmt + Number(gstAmt);
    let landedRate = netAmt / (Number(row.DOQty) + Number(row.FreeQty));
    let temp = [...props.data];
    let tempObj = temp[index];
    tempObj.DOQty = Number(row.DOQty);
    tempObj.PendQty = Number(row.PendQty);
    tempObj.recQty = Number(receivedQuantity);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.otherCharges = Number(otherCharges);
    tempObj.DiscPer = Number(discountPercentage);
    tempObj.DiscAmt = Number(discountAmount).toFixed(2);
    tempObj.Consignment = Consignment;
    tempObj.VATPer = Number(gstPercentage);
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);

    tempObj.VATAmt = Number(gstAmt).toFixed(2);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);

    setValue(`VATAmt${index}`, gstAmt.toFixed(2));
    temp[index] = tempObj;
    props.setData(temp);
  };

  const calculateGstPercentage = (
    index,
    receivedQuantity,
    freeQuantity,
    purchaseRate,
    discountPercentage,
    discountAmount,
    gstPercentage,
    gstAmount,
    otherCharges,
    Consignment,
    expiryDate,
    Conversion,
    mrp,
    row
  ) => {
    let totalAmount = Number(row.DOQty) * Number(row.Rate);
    let gstPercent = (Number(gstAmount) * 100) / totalAmount;

    let netAmt = row.DOQty * row.Rate - row.DiscAmt + Number(gstAmount);
    let landedRate = netAmt / (Number(row.DOQty) + Number(row.FreeQty));
    let temp = [...props.data];
    let tempObj = temp[index];

    tempObj.DOQty = Number(receivedQuantity);
    tempObj.purchaseRate = Number(purchaseRate);
    tempObj.otherCharges = Number(otherCharges);
    tempObj.totalAmount = Number(totalAmount.toFixed(2));
    tempObj.DiscPer = Number(discountPercentage);
    tempObj.DiscAmt = Number(discountAmount).toFixed(2);
    tempObj.VATPer = Number(gstPercentage);
    tempObj.Consignment = Consignment;
    tempObj.Conversion = Conversion;
    tempObj.MRP = Number(mrp);
    tempObj.VATAmt = Number(gstAmount);
    tempObj.FreeQty = Number(freeQuantity);
    tempObj.NetAmount = netAmt.toFixed(2);
    tempObj.landedRate = landedRate.toFixed(2);

    setValue(`VATPer${index}`, gstPercent.toFixed(2));
    temp[index] = tempObj;
    props.setData(temp);
  };

  const handleInputBlur = () => {
    isInputFocused.current = false;
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setDialogClosedByButton(true);
  };
  // set index
  const handleRowClick = (index) => {
    setIndexVal(index);
  };

  const handleInputClick = (event) => {
    event.stopPropagation();
  };

  // get batchcode and set batch related info
  useEffect(() => {
    //used for batchRequired
    if (selectedRow && selectedRow.BatchCode) {
      batchAvailable = true;
      assignBatchNumberForItem(batchAvailable);
    } else {
      batchAvailable = false;
      assignBatchNumberForItem(batchAvailable);
    }
    if (isDialogOpen === true) {
      setSearchValue(null);
      setValue(`grnBatchCode[${indexVal}].batchCode`, null);
      setValue("searchBatchCode", "");
      setSelectedRow(null);
      setIndexVal(null);
    }
  }, [selectedRow]);
  // using after and before discount
  useEffect(() => {
    let itemsArr = [...props.data];
    let array = [];
    if (props.discountType === "After Disc & Tax") {
      for (let object of itemsArr) {
        let item = {};
        item.DOId = object.DOId;
        item.BatchesRequired = object.BatchesRequired;
        item.DONo = object.DONo;
        item.ItemId = object.ItemId;
        item.ItemCode = object.ItemCode;
        item.ItemName = object.ItemName;
        item.Rate = object.Rate;
        item.color = object.color;
        item.DOQty = object.DOQty;
        item.PendQty = object.PendQty;
        item.recQty = object.recQty;
        item.Amount = object.Amount;
        item.batchCode = object.batchCode;
        item.UnitRate = object.UnitRate;
        item.PurchaseUOM = object.PurchaseUOM;
        item.ItemForm = object.ItemForm;
        item.DODetailId = object.DODetailId;
        item.grnNumber = object.grnNumber;
        item.ItemMRP = object.ItemMRP;
        item.expiryDate =
          object.expiryDate === undefined ? new Date() : object.expiryDate;
        item.DiscPer = object.DiscPer;
        let disc = Number(object.Amount) * Number(object.DiscPer);
        let discVal = Number(disc) / 100;
        let discountedAmount = Number(object.Amount - discVal);
        item.DiscAmt = discVal.toFixed(2);
        item.VATPer = object.VATPer;
        let gst = (Number(object.Amount) * Number(object.VATPer)) / 100;
        let netAmt = Number(discountedAmount) + Number(gst);
        let landedRate =
          netAmt / (Number(object.DOQty) + Number(object.FreeQty));
        item.landedRate = Number(landedRate).toFixed(2);
        item.VATAmt = gst.toFixed(2);
        item.Specification = object.Specification;
        item.MRP = object.MRP;
        item.Conversion = object.Conversion;
        item.Consignment = object.Consignment;
        item.NetAmount = netAmt.toFixed(2);
        item.FreeQty = object.FreeQty;
        item.otherCharges = object.otherCharges;
        array.push(item);
        props.setData(array);
      }
    } else if (props.discountType === "Before Disc & Tax") {
      for (let object of itemsArr) {
        let item = {};
        item.DOId = object.DOId;
        item.BatchesRequired = object.BatchesRequired;
        item.DONo = object.DONo;
        item.ItemId = object.ItemId;
        item.ItemCode = object.ItemCode;
        item.ItemName = object.ItemName;
        item.Rate = object.Rate;
        item.color = object.color;
        item.DOQty = object.DOQty;
        item.PendQty = object.PendQty;
        item.recQty = object.recQty;
        item.batchCode = object.batchCode;
        item.Amount = object.Amount;
        item.UnitRate = object.UnitRate;
        item.PurchaseUOM = object.PurchaseUOM;
        item.ItemForm = object.ItemForm;
        item.DODetailId = object.DODetailId;
        item.grnNumber = object.grnNumber;
        item.ItemMRP = object.ItemMRP;
        item.DiscPer = object.DiscPer;
        item.Conversion = object.Conversion;
        item.Consignment = object.Consignment;
        item.expiryDate =
          object.expiryDate === undefined ? new Date() : object.expiryDate;
        let disc = Number(object.Amount) * Number(object.DiscPer);
        let discVal = Number(disc) / 100;
        item.DiscAmt = discVal.toFixed(2);
        item.VATPer = object.VATPer;
        let gst = (Number(object.Amount) * Number(object.VATPer)) / 100;
        let netAmt = Number(object.Amount) + Number(gst);
        let landedRate =
          netAmt / (Number(object.DOQty) + Number(object.FreeQty));
        item.landedRate = Number(landedRate).toFixed(2);
        item.VATAmt = gst.toFixed(2);
        item.Specification = object.Specification;
        item.MRP = object.MRP;
        item.NetAmount = netAmt.toFixed(2);
        item.FreeQty = object.FreeQty;
        item.otherCharges = object.otherCharges;
        array.push(item);
        props.setData(array);
      }
    }
  }, [props.discountType]);

  // using from parent calculations
  useEffect(() => {
    let temp = [...props.data];
    let totalAmount = 0;
    let discAmount = 0;
    let gstAmount = 0;
    let netAmount = 0;
    let roundingAmt = 0;
    let otherCharges = 0;
    let mrp = 0;

    let freeQty = 0;
    for (let obj of temp) {
      let tempObj = {};
      tempObj.totalAmount = totalAmount += Number(obj.Amount);
      tempObj.otherCharges = otherCharges += Number(obj.otherCharges);
      tempObj.discountAmount = discAmount += Number(obj.DiscAmt);
      tempObj.gstAmount = gstAmount += Number(obj.VATAmt);
      tempObj.netAmount = netAmount += Number(obj.NetAmount);
      tempObj.mrp = mrp += Number(obj.MRP);
      tempObj.batchCode = obj.batchCode;
      tempObj.expiryDate = obj.expiryDate;
      tempObj.FreeQty = freeQty += Number(obj.FreeQty);
      tempObj.roundingAmt = roundingAmt +=
        Number(obj.Amount) - Number(obj.DiscAmt) + Number(obj.VATAmt);
      props.setTotalCalculations(tempObj);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      freeQuantityValueRef.current === undefined &&
      freeQuantityValueRef.current
    ) {
      freeQuantityValueRef.current.focus();
    }
  }, [freeQuantityValueRef.current]);
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            {/* pagination */}

            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                  borderRadius: "10px",
                },
              }}
              className="rounded md:h-52 2xl:h-72 border"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Item Code
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Item Name
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Rc. Qty
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Fr. Qty
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Batch No.
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Exp. Date
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      M.R.P
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      P.T.R
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      P.T.R.* Qty
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Conv.F.
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Is Consignment
                    </TableCell>

                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Disc %
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Disc.Amt
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      GST %
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      GST Amt
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Other Charges
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Item Tax
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Landed Rate
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      Net Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow
                        key={row.ItemId}
                        className={index === indexVal ? "active" : ""}
                        onMouseDown={() => handleRowClick(index)}
                      >
                        <TableCell className="whitespace-nowrap">
                          {row.ItemCode}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.ItemName}
                        </TableCell>
                        <TableCell>
                          <input
                            defaultValue={row.PendQty}
                            name={`PendQty${index}`}
                            {...register(`PendQty${index}`)}
                            type="number"
                            style={{ borderColor: `${row.color}` }}
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              setValue(`PendQty${index}`, e.target.value);
                              let receivedQuantity = e.target.value;
                              let freeQuantity = getValues(`FreeQty${index}`);
                              let otherCharges = getValues(
                                `otherCharges${index}`
                              );

                              let purchaseRate = row.Rate;
                              let Consignment = getValues(
                                `Consignment${index}`
                              );
                              let discountPercentage = getValues(
                                `DiscPer${index}`
                              );
                              let discountAmount = getValues(`DiscAmt${index}`);
                              let Conversion = getValues(`Conversion${index}`);
                              let gstAmount = getValues(`VATAmt${index}`);
                              let expiryDate = getValues(`expiryDate${index}`);
                              let mrp = getValues(`MRP${index}`);

                              calculatePurchaseRate(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstAmount,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            defaultValue={row.FreeQty}
                            type="number"
                            inputRef={freeQuantityValueRef}
                            size="small"
                            name={`FreeQty${index}`}
                            {...register(`FreeQty${index}`)}
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              let freeQuantity = e.target.value;
                              let receivedQuantity = getValues(
                                `PendQty${index}`
                              );

                              let purchaseRate = row.Rate;
                              let discountPercentage = getValues(
                                `DiscPer${index}`
                              );
                              let Consignment = getValues(
                                `Consignment${index}`
                              );
                              let expiryDate = getValues(`expiryDate${index}`);
                              let gstPercentage = getValues(`VATPer${index}`);
                              let Conversion = getValues(`Conversion${index}`);

                              let discountAmount = getValues(`DiscAmt${index}`);
                              let gstAmount = getValues(`VATAmt${index}`);
                              let otherCharges = getValues(
                                `otherCharges${index}`
                              );
                              let mrp = getValues(`MRP${index}`);

                              // gstAmount
                              calculateFreeQuantity(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstAmount,
                                gstPercentage,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            onChange={(e) => {
                              let batchNumber = e.target.value;
                              let Conversion = getValues(`Conversion${index}`);
                              let receivedQuantity = watch(`PendQty${index}`);
                              let expiryDate = getValues(
                                `grnBatchCode[${index}].expirayDate`
                              );
                              batchCodeNumber(
                                index,
                                batchNumber,
                                Conversion,
                                receivedQuantity,
                                expiryDate,
                                row
                              );
                            }}
                          >
                            <input
                              type="text"
                              error={
                                errors?.grnBatchCode &&
                                errors?.grnBatchCode[index]?.batchCode
                              }
                              placeholder="Enter Batch Number"
                              autocomplete="off"
                              value={row.batchCode}
                              disabled={row.BatchesRequired ===true?false:true}
                              {...register(`grnBatchCode[${index}].batchCode`)}
                              name={`grnBatchCode[${index}].batchCode`}
                              style={{
                                border:
                                  row.requiredBatchNumber === "#D11A2A"
                                    ? "1px solid red"
                                    : "1px solid #3184a6",
                              }}
                              className="pl-2 border border-customBlue rounded"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  setValue(
                                    `grnBatchCode[${index}].batchCode`,
                                    null
                                  );
                                }
                                if (
                                  row.BatchesRequired === true ||
                                  batchData.result?.length > 0
                                ) {
                                  if (e.key === "Enter") {
                                    if (isDialogOpen) {
                                      setIsDialogOpen(false);
                                      setSearchValue("");
                                      handleKeyPress(e);
                                      setIndexVal(index);
                                      setValue(
                                        `grnBatchCode[${index}].batchCode`,
                                        null
                                      );
                                    } else {
                                      setIsDialogOpen(true);
                                      if (
                                        row.BatchesRequired === true &&
                                        row.batchCode === ""
                                      ) {
                                        setIndexVal(index);
                                        setValue(
                                          `grnBatchCode[${index}].batchCode`,
                                          null
                                        );
                                        setSearchValue("")
                                        fetchBatchNumberForGrn(
                                          row.ItemId,
                                          token.storeId
                                        )
                                          .then((response) => response.data)
                                          .then((res) => {
                                            let arr = [];
                                            for (let obj of res.result) {
                                              obj.ItemCode = row.ItemCode;
                                              obj.ItemName = row.ItemName;
                                              arr.push(obj);
                                            }
                                            if (
                                              e.target.value === "" &&
                                              res.result?.length > 0
                                            ) {
                                              setValue(
                                                `grnBatchCode[${index}].batchCode`,
                                                null
                                              );
                                              setSearchValue("");
                                            }
                                            setBatchData(arr);
                                          });
                                      }
                                    }
                                  }
                                }
                              }}
                              onFocus={(e) => {
                                if (
                                  row.BatchesRequired === true &&
                                  row.batchCode === ""
                                ) {
                                  setIndexVal(index);
                                  setSearchValue("")
                                  setValue(
                                    `grnBatchCode[${index}].batchCode`,
                                    null
                                  );
                                  fetchBatchNumberForGrn(
                                    row.ItemId,
                                    token.storeId
                                  )
                                    .then((response) => response.data)
                                    .then((res) => {
                                      let arr = [];
                                      for (let obj of res.result) {
                                        obj.ItemCode = row.ItemCode;
                                        obj.ItemName = row.ItemName;
                                        arr.push(obj);
                                      }
                                      if (
                                        e.target.value === "" &&
                                        res.result?.length > 0
                                      ) {
                                        isInputFocused.current = true;
                                        setIsDialogOpen(!dialogClosedByButton);
                                        setValue(
                                          `grnBatchCode[${index}].batchCode`,
                                          null
                                        );
                                        setSearchValue("");
                                        setDialogClosedByButton(false);
                                      }
                                      setBatchData(arr);
                                    });
                                }
                              }}
                              onFocusCapture={(e) => {
                                if (
                                  e.target.value === "" &&
                                  batchData.result?.length > 0
                                ) {
                                  isInputFocused.current = true;
                                  setIsDialogOpen(!dialogClosedByButton);
                                  setSearchValue("");
                                  setDialogClosedByButton(false);
                                  setIndexVal(index);
                                  setValue(
                                    `grnBatchCode[${index}].batchCode`,
                                    null
                                  );
                                }
                              }}
                              onBlur={handleInputBlur}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="z-0">
                          <FormControl
                            sx={{ overflow: "hidden", zIndex: "0px" }}
                          >
                            <Controller
                              name={`grnBatchCode[${index}].expirayDate`}
                              {...register(
                                `grnBatchCode[${index}].expirayDate`
                              )}
                              sx={{ overflow: "hidden" }}
                              control={control}
                              render={({ field: { value } }) => (
                                <LocalizationProvider
                                  sx={{ overflow: "hidden" }}
                                  dateAdapter={AdapterDateFns}
                                >
                                  {row.BatchesRequired && !row.batchCode ? (
                                    <DesktopDatePicker
                                      value={value}
                                      sx={{ overflow: "hidden" }}
                                      disablePast
                                      inputFormat="dd-MM-yyyy"
                                      popperProps={{
                                        positionFixed: true,
                                      }}
                                      disabled
                                      style={{ border: "0" }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          InputLabelProps={{ shrink: true }}
                                          {...params}
                                          sx={{
                                            svg: {
                                              color: "#1e3a8a",
                                              height: 20,
                                            },
                                            backgroundColor: "white",
                                            overflow: "hidden",
                                            width: 150,
                                            "& .MuiOutlinedInput-root": {
                                              "& .MuiOutlinedInput-input": {
                                                fontSize: 14,
                                                height: "0.5375em",
                                                borderColor: "#0B83A5",
                                                overflow: "hidden",
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  ) : (
                                    <DesktopDatePicker
                                      value={value}
                                      sx={{ overflow: "hidden" }}
                                      disablePast
                                      inputFormat="dd-MM-yyyy"
                                      popperProps={{
                                        positionFixed: true,
                                      }}
                                      onChange={(newValue) => {
                                        handleChangeExpiryDate(newValue, index);
                                        setValue(
                                          `grnBatchCode[${index}].expirayDate`,
                                          newValue
                                        );
                                      }}
                                      style={{ border: "0" }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          InputLabelProps={{ shrink: true }}
                                          {...params}
                                          sx={{
                                            svg: {
                                              color: "#1e3a8a",
                                              height: 20,
                                            },
                                            backgroundColor: "white",
                                            overflow: "hidden",
                                            width: 150,
                                            "& .MuiOutlinedInput-root": {
                                              "& .MuiOutlinedInput-input": {
                                                fontSize: 14,
                                                height: "0.5375em",
                                                borderColor: "#0B83A5",
                                                overflow: "hidden",
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  )}
                                </LocalizationProvider>
                              )}
                            />
                          </FormControl>
                        </TableCell>

                        <TableCell>
                          <input
                            autoComplete="off"
                            defaultValue={row.MRP}
                            name={`MRP${index}`}
                            {...register(`MRP${index}`)}
                            onKeyDown={handleKeyPress}
                            type="text"
                            className="border border-customBlue rounded w-20 px-2"
                          />
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.Rate}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {Number(row.DOQty * row.Rate).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <input
                            autoComplete="off"
                            defaultValue={row.Conversion}
                            name={`Conversion${index}`}
                            {...register(`Conversion${index}`)}
                            inputRef={batchCodeField}
                            onKeyDown={handleKeyPress}
                            type="text"
                            className="border border-customBlue rounded w-20 px-2"
                          />
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          <input
                            type="checkbox"
                            className="w-[18px] h-[18px]"
                            name={`Consignment${index}`}
                            {...register(`Consignment${index}`)}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {}}
                            defaultChecked={row.Consignment}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            autoComplete="off"
                            defaultValue={row.DiscPer}
                            name={`DiscPer${index}`}
                            {...register(`DiscPer${index}`)}
                            type="text"
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              let discountPercentage = e.target.value;

                              if (discountPercentage > 100) {
                                setValue(`DiscPer${index}`, 100);
                              }

                              let freeQuantity = getValues(`FreeQty${index}`);
                              let receivedQuantity = getValues(
                                `PendQty${index}`
                              );
                              let otherCharges = getValues(
                                `otherCharges${index}`
                              );
                              let purchaseRate = row.Rate;

                              let gstAmount = getValues(`VATAmt${index}`);
                              let discountAmount = getValues(`DiscAmt${index}`);
                              let Consignment = getValues(
                                `Consignment${index}`
                              );
                              let expiryDate = getValues(`expiryDate${index}`);
                              let Conversion = getValues(`Conversion${index}`);
                              let mrp = getValues(`MRP${index}`);

                              calculateDiscountAmount(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstAmount,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            autoComplete="off"
                            defaultValue={row.DiscAmt}
                            name={`DiscAmt${index}`}
                            {...register(`DiscAmt${index}`)}
                            type="number"
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              let discountAmount = e.target.value;

                              let discountPercentage = getValues(
                                `DiscPer${index}`
                              );
                              let gstAmount = getValues(`VATAmt${index}`);
                              let otherCharges = getValues(
                                `otherCharges${index}`
                              );

                              let freeQuantity = getValues(`FreeQty${index}`);
                              let receivedQuantity = getValues(
                                `PendQty${index}`
                              );
                              let Consignment = getValues(
                                `Consignment${index}`
                              );
                              let purchaseRate = row.Rate;
                              let expiryDate = getValues(`expiryDate${index}`);
                              let Conversion = getValues(`Conversion${index}`);
                              let mrp = getValues(`MRP${index}`);

                              calculateDiscountPercent(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstAmount,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            autoComplete="off"
                            defaultValue={row.VATPer}
                            name={`VATPer${index}`}
                            {...register(`VATPer${index}`)}
                            type="text"
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              let gstPercentage = e.target.value;

                              if (gstPercentage > 100) {
                                setValue(`VATPer${index}`, 100);
                              }
                              let discountPercentage = getValues(
                                `DiscPer${index}`
                              );

                              let freeQuantity = getValues(`FreeQty${index}`);

                              let receivedQuantity = getValues(
                                `PendQty${index}`
                              );
                              let otherCharges = getValues(
                                `otherCharges${index}`
                              );
                              let Consignment = getValues(
                                `Consignment${index}`
                              );
                              let expiryDate = getValues(`expiryDate${index}`);
                              let purchaseRate = row.Rate;
                              let Conversion = getValues(`Conversion${index}`);

                              let discountAmount = getValues(`DiscAmt${index}`);
                              let mrp = getValues(`MRP${index}`);

                              calculateGstAmount(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstPercentage,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            autoComplete="off"
                            defaultValue={row.VATAmt}
                            name={`VATAmt${index}`}
                            {...register(`VATAmt${index}`)}
                            type="text"
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              let gstAmount = e.target.value;
                              let gstPercentage = getValues(`VATPer${index}`);
                              let discountPercentage = getValues(
                                `DiscPer${index}`
                              );
                              let otherCharges = getValues(
                                `otherCharges${index}`
                              );
                              let freeQuantity = getValues(`FreeQty${index}`);
                              let receivedQuantity = getValues(
                                `PendQty${index}`
                              );
                              let purchaseRate = row.Rate;
                              let discountAmount = getValues(`DiscAmt${index}`);
                              let Consignment = getValues(
                                `Consignment${index}`
                              );
                              let Conversion = getValues(`Conversion${index}`);

                              let expiryDate = getValues(`expiryDate${index}`);
                              let mrp = getValues(`MRP${index}`);

                              calculateGstPercentage(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstPercentage,
                                gstAmount,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <input
                            autoComplete="off"
                            type="number"
                            defaultValue={"0"}
                            name={`otherCharges${index}`}
                            {...register(`otherCharges${index}`)}
                            className="border border-customBlue rounded w-20 px-2"
                            onKeyDown={handleKeyPress}
                            onChange={(e) => {
                              let otherCharges = e.target.value;

                              let discountPercentage = getValues(
                                `DiscPer${index}`
                              );

                              let freeQuantity = getValues(`FreeQty${index}`);
                              let receivedQuantity = getValues(
                                `PendQty${index}`
                              );
                              let gstPercentage = getValues(`VATPer${index}`);
                              let Consignment = getValues(
                                `Consignment${index}`
                              );

                              let purchaseRate = row.Rate;
                              let Conversion = getValues(`Conversion${index}`);
                              let mrp = getValues(`MRP${index}`);

                              let discountAmount = getValues(`DiscAmt${index}`);
                              let expiryDate = getValues(`expiryDate${index}`);
                              calculateOtherCharges(
                                index,
                                receivedQuantity,
                                freeQuantity,
                                purchaseRate,
                                discountPercentage,
                                discountAmount,
                                gstPercentage,
                                otherCharges,
                                Consignment,
                                expiryDate,
                                Conversion,
                                mrp,
                                row
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {/* {row.ItemTax} */}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.landedRate}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.Amount}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {row.NetAmount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
      {isDialogOpen ? (
        <BatchSelectionModal
          searchValue={searchValue}
          batchData={batchData}
          isDialogOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
          matchingEntries={matchingEntries}
          handleInputClick={handleInputClick}
          handleKeyPressForSearchBatchCode={handleKeyPressForSearchBatchCode}
          handleInputChangeForBatchCode={handleInputChangeForBatchCode}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setBatchData={setBatchData}
          setIsDialogOpen={setIsDialogOpen}
          open={props.open}
        />
      ) : (
        ""
      )}
    </>
  );
}
