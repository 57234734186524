import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { ItemIssuedListPrintData } from "../services/otDetailsServices/OtDetailsServices";

export default function OtItemIssueListPrint(props) {
  const printRef = useRef(null);

  const [data, setData] = React.useState();

  React.useEffect(() => {
    let PrintSearchObj = {
      fromDate:
        props.FromDate?.$d !== undefined ? props.FromDate?.$d : new Date(),
      toDate: props.ToDate?.$d !== undefined ? props.ToDate?.$d : new Date(),
    };
    ItemIssuedListPrintData(PrintSearchObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
  }, [props.FromDate, props.ToDate]);

  useEffect(() => {
    data && printRef && GeneratePrint(printRef);
  }, [data]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <tr>
            <th colSpan={"12"} style={{ textAlign: "center", padding: "2px" }}>
              ITEM ISSUED LIST
            </th>
          </tr>

          <div className=" space-x-3 grid grid-cols-[5%_8%_8%_10%_11%_10%_11%_5%_10%_10%_5%_7%] border-black border-b-[1px] border-t-[1px]">
            <div className=" text-[11px] font-semibold ">IssueNo</div>
            <div className=" text-[11px] font-semibold ">OT date</div>
            <div className=" text-[11px] font-semibold ">OPD/IPD</div>
            <div className=" text-[11px] font-semibold ">Patient Name</div>
            <div className=" text-[11px] font-semibold ">Procedure Name</div>
            <div className=" text-[11px] font-semibold ">Item Name</div>
            <div className=" text-[11px] font-semibold ">Item Class</div>
            <div className=" text-[11px] font-semibold ">Qty</div>
            <div className=" text-[11px] font-semibold ">Batch No</div>
            <div className=" text-[11px] font-semibold ">Landed Rate</div>
            <div className=" text-[11px] font-semibold ">MRP</div>
            <div className=" text-[11px] font-semibold whitespace-nowrap ">
              Total Sale
            </div>
          </div>
        </thead>
        <tbody className="w-full">
          {data?.ItemList?.map((TableData) => {
            return (
              <div className=" space-x-3 grid grid-cols-[5%_8%_8%_10%_11%_10%_11%_5%_10%_10%_5%_7%]">
                <div className=" text-start text-[11px] ">
                  {TableData?.IssueNo}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.OTDate}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["OPD/IPD"]}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Patient Name"]}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Procedure Name"]}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Item Name"]}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Item Class"]}
                </div>
                <div className=" text-start text-[11px] ">{TableData?.Qty}</div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Batch No"]}
                </div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Landed Rate"]}
                </div>
                <div className=" text-start text-[11px] ">{TableData?.MRP}</div>
                <div className=" text-start text-[11px] ">
                  {TableData?.["Total Sale"]}
                </div>
              </div>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
