import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";

export default function VentilatorBubdleChecklistTable(props) {
  const { dataResult, removeHeaders, tableClass, setDataResult } = props;

  const [rowIndex, setRowIndex] = React.useState();

  const removeHeader = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  const allHeaders = Object.keys(dataResult[0]);
  const headers = removeHeaders
    ? removeHeader(allHeaders, removeHeaders && removeHeaders)
    : allHeaders;

  const handleChangeCheckbox = (e, row, header, index, category) => {
    let temp = [...dataResult];
    temp[index][header][category] = e.target.checked;
    setDataResult(temp);
  };

  return (
    <div className="grid w-auto">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#ebebeb",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#7393b3",
                borderRadius: 4,
              },
            }}
            className={tableClass}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead align="center">
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell style={{ width: "50%" }}>
                    <div className="text-gray-600 font-bold whitespace-nowrap">
                      Day No
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      1
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      2
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      3
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      4
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      5
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      6
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-600 font-bold whitespace-nowrap text-center">
                      7
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataResult.map((row, index) => (
                  <TableRow
                    key={index}
                    hover={false}
                    sx={{
                      "& td": {
                        paddingY: 0.1,
                      },
                    }}
                    onClick={() => {
                      setRowIndex(index);
                    }}
                  >
                    <TableCell className="whitespace-nowrap ">
                      {row["Day No"]}
                    </TableCell>
                    {headers.map((header, i) => (
                      <TableCell className="whitespace-nowrap" key={i}>
                        {row["Day No"] === "" && header !== "Day No" && (
                          <div className="flex gap-x-7 pl-3">
                            <div>M</div>
                            <div>E</div>
                            <div>N</div>
                          </div>
                        )}
                        {header !== "Day No" && row["Day No"] !== "" && (
                          <div className="">
                            <Checkbox
                              size="small"
                              // name={`checkbox_M_${i}_${header}`}
                              onChange={(e) => {
                                handleChangeCheckbox(
                                  e,
                                  row,
                                  header,
                                  index,
                                  "M"
                                );
                              }}
                              checked={row[header].M}
                            />
                            <Checkbox
                              size="small"
                              // name={`checkbox_E_${i}_${header}`}
                              onChange={(e) =>
                                handleChangeCheckbox(e, row, header, index, "E")
                              }
                              checked={row[header].E}
                            />
                            <Checkbox
                              size="small"
                              // name={`checkbox_N_${i}_${header}`}
                              onChange={(e) =>
                                handleChangeCheckbox(e, row, header, index, "N")
                              }
                              checked={row[header].N}
                            />
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}
