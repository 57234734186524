import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { format } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { successAlert } from "../../../common/components/Toasts/CustomToasts";
import {
  fetchETURegisterList,
  getETURegisterStaffName,
  saveETURegister,
} from "../../services/etuRegisterServices/etuRegisterServices";
import ETURegisterStaffTable from "./ETURegisterStaffTable.js";

const removeHeaders = [
  "patchDate",
  "Address",
  "AgeYear",
  "ArrivalTime",
  "BatchNo",
  "BillId",
  "BillNo",
  "BodyHandedOverBy",
  "BodyHandedOverTo",
  "BodyHandoverRemark",
  "DepartmentId",
  "DepartureTime",
  "Diagnosis",
  "Gender",
  "GenderId",
  "ID",
  "IsDC",
  "IsDoctorVisit",
  "IsMLC",
  "IsNurseVisit",
  "IsPM",
  "IsSingleDose",
  "IsVaccineDateEdited",
  "MortuaryInTime",
  "MortuaryOutTime",
  "NextVaccineDate",
  "PatientId",
  "ReceivedCallDateTime",
  "StaffIds",
  "Staffs",
  "Treatement",
  "TrnType",
  "VaccineDoseDurationID",
  "VaccineId",

  "VisitedLocation",
];
const actions = ["print"];
const AmbulanceCall = (props) => {
  const schema = yup.object().shape({});
  const defaultValues = {
    departureTime: "",
    arrivalTime: "",
    visitedLocation: "",
    diagnosis: "",
    treatment: "",
    staffs: null,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,

    watch,

    setValue,
    formState: {},
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables

  const [open, setOpen] = useState(false);

  const [employeeName, setEmployeeName] = React.useState();
  const [employeeArr, setEmployeeArr] = React.useState([]);
  const [departureTime, setDepartureTime] = React.useState(new Date());
  const [receivedDate, setReceivedDate] = React.useState(new Date());
  const [receivedTime, setReceivedTime] = React.useState(new Date());
  const [arrivalTime, setArrivalTime] = React.useState(new Date());
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [ambulanceCallList, setAmbulanceCallList] = React.useState([]);
  const [etuAmbulanceCallObj, setETUAmbulanceCallObj] = React.useState(null);
  const [rowIndex, setRowIndex] = React.useState();
  const [cellInfo, setCellInfo] = React.useState("");
  const [resetConfirmationOpen, setResetConfirmationOpen] =
    React.useState(false);
  const [privilege, setPrivilege] = React.useState(null);
  
  let staffsVal = watch("staffs");

  let callReceivedDateTime = watch("callreveiveddate");

  let location = useLocation();
 

  //useEffects
  useEffect(() => {}, []);
  //functions
  const handleOpen = () => {
    setConfirmationOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setConfirmationOpen(false);
  };

  const handleResetOpenConfirmation = () => {
    setResetConfirmationOpen(true);
  };

  const handleResetCloseConfirmation = () => {
    if (resetConfirmationOpen === true) {
      setResetConfirmationOpen(false);
    }
  };

  useEffect(() => {
    getETURegisterStaffName()
      .then((Response) => Response.data)
      .then((res) => {
        setEmployeeName(res.result);
      });
  }, []);

  useEffect(() => {
    let staffObj = {};
    staffObj.id = 0;
    staffObj.value = cellInfo?.Staffs;
    staffObj.label = cellInfo?.Staffs;
    if (cellInfo) {
      setValue("diagnosis", cellInfo?.Diagnosis);
      setValue("treatment", cellInfo?.Treatement);
      setValue("staffs", staffObj);
      setValue("isDoctorVisit", cellInfo?.IsDoctorVisit);
      setValue("isNurseVisit", cellInfo?.IsNurseVisit);
      setValue("visitedLocation", cellInfo?.VisitedLocation);
      let receivedDate = cellInfo?.ReceivedCallDateTime;
      setReceivedDate(new Date(receivedDate));
      let receivedTime = cellInfo?.ReceivedCallDateTime;
      setReceivedTime(new Date(receivedTime));

      let departureDate = cellInfo?.DepartureTime;
      setReceivedDate(new Date(departureDate));
      let departureTime = cellInfo?.DepartureTime;
      setReceivedTime(new Date(departureTime));
    }
  }, [cellInfo]);

  useEffect(() => {
    if (props.showInfo === true) {
      setValue("callreveiveddate", new Date());
      setValue("callReceivedTime", new Date());
      setValue("departureTime", new Date());
      setValue("arrivalTime", new Date());
      setValue("visitedLocation", "");
      setValue("diagnosis", "");
      setValue("treatment", "");
      setValue("staffs", null);
    }
  }, [props.showInfo]);

  function handleChange() {}
  function addStaffNameToTable() {
    if (staffsVal !== null) {
      let requiredArr = [];
      for (let empObj of staffsVal) {
        let Obj = {};
        Obj = empObj.label;
        requiredArr.push(Obj);
     
        setEmployeeArr(requiredArr);
      }
    }
  }
  // fetch listing of etu register home visit
  useEffect(() => {
    fetchETURegisterList(props.eturegisteroptionVal.label)
      .then((response) => response.data)
      .then((res) => {
       
        setAmbulanceCallList(res);
      });
  }, []);
  function onSubmitDataHandler(data) {
    let getPatientInfo = localStorage.getItem("userInfo");
    let getPatientInfoObj = JSON.parse(getPatientInfo);

    let nameArr = [];
    let idArr = [];
    let arrToStringID = "";
    let arrToStringName = "";
    for (let i = 0; i < staffsVal.length; i++) {
      let id = staffsVal[i].id;
      idArr.push(id);
      arrToStringID = idArr.toString(); //conversion arrey to string
    }
    for (let i = 0; i < staffsVal.length; i++) {
      let label = staffsVal[i].label;
      nameArr.push(label);
      arrToStringName = nameArr.toString(); //conversion arrey to string
     
    }
    let postETURegisterObj = {
      arrivalTime: "2023-05-02T10:16:17.049Z",

      batchNo: data.batchNo ? data.batchNo : null,

      billId: 0,
      addedBy: getPatientInfoObj.userId,
      billNo: data.billNo ? data.billNo : null,
      bodyHandOverBy: data.bodyHandOverBy ? data.bodyHandOverBy : null,
      bodyHandOverRemark: data.bodyHandOverRemark
        ? data.bodyHandOverRemark
        : null,
      bodyHandOverTo: data.bodyHandOverTo ? data.bodyHandOverTo : null,
      departmentId: data.departmentId ? data.departmentId : null,
      departureTime: format(departureTime, "yyyy-MM-dd'T'HH:mm:ss"),
      diagnosis: data.diagnosis ? data.diagnosis : null,
      isDc: data.isDc ? data.isDc : null,
      isDoctorVisit: data.isDoctorVisit ? data.isDoctorVisit : null,
      isMlc: data.isMlc ? data.isMlc : null,
      isNurseVisit: data.isNurseVisit ? data.isNurseVisit : null,
      isPm: data.isPm ? data.isPm : null,
      isSingleDose: data.isSingleDose ? data.isSingleDose : null,
      isVaccineDateEdited: data.isVaccineDateEdited
        ? data.isVaccineDateEdited
        : null,
      isVccRemainderSMSSend: data.isVccRemainderSMSSend
        ? data.isVccRemainderSMSSend
        : null,
      mortuaryInTime: null,
      mortuaryOutTime: null,
      nextVaccineDate: "",
      patientId: props.patientId,
      receivedCallDateTime: callReceivedDateTime,
      staffIds: arrToStringID,
      staffs: arrToStringName,
      tmType: props.eturegisteroptionVal.label,
      treatment: data.treatment,
      vaccineDoseDurationId: data.vaccineDoseDurationId
        ? data.vaccineDoseDurationId
        : null,
      vaccineId: data.vaccineId ? data.vaccineId : null,
      visitedLocation: data.visitedLocation ? data.visitedLocation : null,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };
    setETUAmbulanceCallObj(postETURegisterObj);
    handleOpen();
  }

  function addRecord() {
    let etuObj = etuAmbulanceCallObj;
    saveETURegister(etuObj)
      .then((response) => response.data)
      .then((res) => {
        reset(defaultValues, props.etuRegisterSearchVal);
        setEmployeeArr([]);
        props.populateTable();
        successAlert(res.message);
      });
  }

  function displayRowData(row, index) {
  }
  function resetHomeVisit() {
    handleResetCloseConfirmation();
    setReceivedDate(new Date());
    setReceivedTime(new Date());
    setDepartureTime(new Date());
    setArrivalTime(new Date());

    reset(defaultValues);
    setRowIndex();
    props.setShowInfo(true);
  }
  // get row action and row data
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1 items-center cursor-pointer">
        {props.userActions &&
          props.userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-1 items-center cursor-pointer">
                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title={"Print"}>
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                             

                              if (props.etuType === "HomeVisit") {
                                props.printHomeReceipt(Number(row?.ID));
                              } else if (props.etuType === "AmbulanceCall") {
                                props.printAmbulanceCallReceipt(
                                  Number(row?.ID)
                                );
                              } else if (props.etuType === "BodyHandover") {
                                props.printBodyHandoverReceipt(Number(row?.ID));
                              } else if (props.etuType === "Vaccine") {
                                props.printVaccineChartReceipt(Number(row?.ID));
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  // get selected Row Data
  function handleSelectedRow(row, index) {
    displayRowData(row, index);
    setCellInfo(row);
    if (props.showInfo === true) {
      props.setShowInfo(false);
    }
  }
  return (
    <div className=" pb-4">
      {props.showInfo === true ? (
        <>
          {props.patientInfoById && props.patientInfoById !== "" ? (
            <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
              <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 font-semibold">Patient Name</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props.patientInfoById.PatientName &&
                      props?.patientInfoById?.PatientName}
                  </span>
                </div>
                <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                    
                    MR No.
                  </span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById && props?.patientInfoById.MRNo}
                  </span>
                </div>

                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                  <span className="w-24 xl:w-8 font-semibold">Age</span>
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById && props?.patientInfoById.AgeYear}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                  <span className="w-24 lg:w-20 font-semibold">Address </span>

                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {props?.patientInfoById && props?.patientInfoById.Address}
                  </span>
                </div>
              </div>
            </fieldset>
          ) : null}
        </>
      ) : null}
      <div className="w-full py-2">
        {props.dataResult.length > 0 ? (
          <CommonDynamicTableNew
            dataResult={props.dataResult}
            highlightRow={true}
            renderActions={renderActions}
            handleSelectedRow={handleSelectedRow}
            tableClass="lg:h-[300px] 2xl:h-[450px]"
            removeHeaders={removeHeaders}
          />
        ) : (
          <div className="text-center py-12">
            <span>Records Not Found...</span>
          </div>
        )}
      </div>
      {props.showInfo === false ? (
        <>
          {cellInfo && cellInfo !== "" ? (
            <>
              <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 font-semibold">Patient Name</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo?.PatientName && cellInfo?.PatientName}
                    </span>
                  </div>
                  <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                      MR No.
                    </span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.MRNo}
                    </span>
                  </div>

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="w-24 xl:w-8 font-semibold">Age</span>
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo?.AgeYear}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                    <span className="w-24 lg:w-20 font-semibold">Address</span>

                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {cellInfo && cellInfo.Address}
                    </span>
                  </div>
                </div>
              </fieldset>
            </>
          ) : null}
        </>
      ) : null}
      {(props.patientInfoById && props.patientInfoById !== "") ||
      (cellInfo && cellInfo !== "") ? (
        <>
          <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-1 items-center">
            <div className="">
              <div className="text-md font-semibold">
                Ambulance Call Details
              </div>
            </div>
            <div className=" flex justify-end">
              {props.showInfo === true ? null : (
                <CommonButton
                  label="New"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    handleResetOpenConfirmation();
                  }}
                />
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <div className="">
              <div className="lg:grid lg:grid-cols-3 gap-2 items-center">
                <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                  <legend className="px-2">Received Call Date & Time</legend>
                  <div className="flex gap-2 py-2 px-2">
                    <div className="">
                      <DatePickerFieldNew
                        control={control}
                        name="callreveiveddate"
                        label="Received Call Date"
                        defaultValue={new Date()}
                        onChange={(newValue) => {
                          setValue("callreveiveddate", newValue);
                        }}
                        // disableFuture={true}

                        inputFormat="dd-MM-yyyy"
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        defaultValue={new Date()}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopTimePicker
                              label="Received Call Time"
                              name="callReceivedTime"
                              value={receivedTime}
                              onChange={(newValue) => {
                                setReceivedTime(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="bg-white"
                                  fullWidth
                                  size="small"
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: "#0B83A5",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                        name="callReceivedTime"
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                  <legend className="px-2">Departure & Arrival Time</legend>
                  <div className="flex gap-2 py-2 px-2">
                    <div className="">
                      <Controller
                        control={control}
                        defaultValue={new Date()}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopTimePicker
                              label="Departure Time"
                              value={departureTime}
                              defaultValue={new Date()}
                              onChange={(newValue) => {
                                setDepartureTime(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="bg-white"
                                  fullWidth
                                  size="small"
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: "#0B83A5",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                        name="departureTime"
                      />
                    </div>
                    <div className="">
                      <Controller
                        control={control}
                        defaultValue={new Date()}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopTimePicker
                              label="Arrival Time"
                              name="arrivalTime"
                              value={arrivalTime}
                              onChange={(newValue) => {
                                setArrivalTime(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="bg-white"
                                  fullWidth
                                  size="small"
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "14px",
                                      position: "absolute",
                                      top: "-2px",
                                    },
                                  }}
                                  sx={{
                                    svg: {
                                      color: "#1e3a8a",
                                      height: 22,
                                      width: "100%",
                                      marginRight: "16px",
                                    },
                                    backgroundColor: "white",
                                    overflow: "visible",

                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-input": {
                                        // border: 0,
                                        fontSize: 14,
                                        height: "18px",
                                        width: "100%",

                                        borderColor: "#0B83A5",
                                        overflow: "hidden",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                        name="arrivalTime"
                      />
                    </div>
                  </div>
                </fieldset>
                <div className="col-span-1 pt-2">
                  <TextField
                    id="outlined-multiline-static"
                    name="visitedLocation"
                    {...register("visitedLocation")}
                    label="Visit Location"
                    InputLabelProps={{ shrink: true }}
                    rows={1.3}
                    multiline
                    fullWidth
                  />
                </div>
              </div>
              <div className="flex gap-2 py-4 items-center">
                <TextField
                  id="outlined-multiline-static"
                  name="diagnosis"
                  {...register("diagnosis")}
                  label="Diagnosis"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                />

                <TextField
                  id="outlined-multiline-static"
                  name="treatment"
                  {...register("treatment")}
                  label="Treatment"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                />
              </div>
              {/* <div className="lg:grid lg:grid-cols-2 gap-2">
        <div>
          <div className="py-2 flex gap-2">
          <SearchDropdown
                    control={control}
                    name="staffs"
                    placeholder="Search By Patient Name/ MR No./Mobile No"
                    dataArray={employeeName}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={true}
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("staffs", {
                        onChange: (e) => {},
                      }),
                    }}
                  />
           
          </div>
<div><AddButton/></div>
</div>
          <div>
            <NoActionsTable data={empData} />
          </div>
      
     
      </div> */}
              <div className="lg:grid lg:grid-cols-2 gap-2">
                <div>
                  <div className="py-2 flex gap-2">
                    <div className="w-full">
                      <DropdownField
                        control={control}
                        name="staffs"
                        placeholder="Search By Employee Name"
                        dataArray={employeeName}
                        searchIcon={true}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={true}
                        handleInputChange={handleChange}
                        inputRef={{
                          ...register("staffs", {
                            onChange: (e) => {},
                          }),
                        }}
                      />
                    </div>
                    <div>
                      {props.showInfo === true ? (
                        <CommonButton
                          label="Add"
                          type="button"
                          className="border bg-customGreen text-white"
                          onClick={() => {
                            addStaffNameToTable();
                          }}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    {employeeArr.length ? (
                      <ETURegisterStaffTable
                        data={employeeArr}
                        actions={actions}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <div className="pt-2 ">
              <EmployeeTable data={empTableData} />
            </div> */}
              </div>
            </div>
            <div className="flex gap-2 justify-end">
              {props.showInfo === true ? (
                <>
                  {props.userActions &&
                    props.userActions.map((actions, i) => (
                      <>
                        {actions.isAction === false ? (
                          <>
                            <div className="flex gap-2 cursor-pointer">
                              {actions.action === "Create" ? (
                                <>
                                  {props.showInfo === true ? (
                                    <>
                                      <div>
                                        <CommonButton
                                          label="Reset"
                                          type="button"
                                          className="border border-customRed text-customRed"
                                          onClick={() => {
                                            reset(defaultValues);
                                            props.setPatientInfoById("");
                                            setCellInfo("");
                                            props.setValue(
                                              "etuRegisterSearch",
                                              null
                                            );
                                          }}
                                        />
                                      </div>

                                      <div>
                                        <CommonButton
                                          type="submit"
                                          label="Save"
                                          className="bg-customGreen text-white"
                                          onClick={() => {
                                            setPrivilege(actions?.action);
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </>
                    ))}
                </>
              ) : null}
            </div>
          </form>
        </>
      ) : null}
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          handleClose();
          addRecord();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save ETU Details?"
        confirmationButtonMsg="Save"
      />
      <ConfirmationModal
        confirmationOpen={resetConfirmationOpen}
        confirmationHandleClose={handleResetCloseConfirmation}
        confirmationSubmitFunc={() => {
          resetHomeVisit();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure to Reset Home Visit?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default AmbulanceCall;
