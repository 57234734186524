import { Box, Checkbox, Modal } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../../common/components/CommonTable/CommonDynamicTableNew";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import {
  fetchAllQuotation,
  getEnquiryList,
  getQuotationEnqItemDetailsList,
} from "../../../../services/purchase/QuotationServies";
import ComparartiveModal from "./ComparartiveModal";

export default function QuoationListModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    // overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
  };
  const {
    control,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [openComparativeModal, setOpenComparativeModal] = React.useState(false);
  const handleComparativeModalOpen = () => setOpenComparativeModal(true);
  const handleComparativeModalClose = () => setOpenComparativeModal(false);

  const [dataResult, setDataResult] = React.useState([]);
  //comparativ state
  const [selectQuotationData, setSelectQuotationData] = React.useState([]);
  // Quotation table State For set data
  const [selectedQutLength, setSelectedQutLength] = React.useState([]); // For Quotation List Check Box
  const [dataQuotation, setDataQuotation] = React.useState({
    result: [],
  });
  const [quotationDataResult, setQuotationDataResult] = React.useState([]);
  //getting QuotationListTable QID
  const [quotationQId, setQuotationQId] = React.useState(null);
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");

  ////For Enquiry table////
  const filterData = (date) => {
    const formatedFromDate1 = format(fromDate, "yyyy-MM-dd'T'hh:mm:ss.000");
    const formatedFromDate2 = format(toDate, "yyyy-MM-dd'T'hh:mm:ss.000");
    let FromDate =
      formatedFromDate1 !== undefined ? formatedFromDate1 : formatedFromDate2;
    let ToDate =
      formatedFromDate2 !== undefined ? formatedFromDate2 : formatedFromDate2;
    getEnquiryList(FromDate, ToDate)
      .then((response) => response.data)
      .then((res) => {
        // setData(res);
        setDataResult(res.result);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    filterData(new Date());
  }, []);

  //Single Select CheckBox
  const [checkBoxIndex, setCheckBoxIndex] = React.useState(-1);
  const handleChange = (e, row, requiredIndex) => {
    setCheckBoxIndex(requiredIndex);
    if (e.target.checked === true) {
      const formatedFromDate1 = format(fromDate, "yyyy-MM-dd'T'hh:mm:ss.000");
      const formatedFromDate2 = format(toDate, "yyyy-MM-dd'T'hh:mm:ss.000");
      let FromDate =
        formatedFromDate1 !== undefined ? formatedFromDate1 : formatedFromDate2;
      let ToDate =
        formatedFromDate2 !== undefined ? formatedFromDate2 : formatedFromDate2;
      fetchAllQuotation(row.EnquiryNo, FromDate, ToDate)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          // setDataQuotation(res);
          setQuotationDataResult(res.result);
        });
    }
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <Checkbox
          size="small"
          className="border  border-gray-600 rounded bg-transparent"
          type="checkbox"
          color="primary"
          checked={checkBoxIndex === index ? true : false}
          value={index}
          onChange={(e) => {
            handleChange(e, row, index);
          }}
          inputProps={{
            "aria-label": "primary checkbox",
          }}
          name={`indentCheckBox${index}`}
        />
      </div>
    );
  };

  const [selectedQutRow, setSelectedQutRow] = React.useState([]);
  let isItemSelected;
  const handleClick = (event, name) => {
    isItemSelected = isSelected(name);
    let newSelected = [];
    const selectedIndex = selectedQutRow.indexOf(name);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedQutRow, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedQutRow.slice(1));
    } else if (selectedIndex === selectedQutRow.length - 1) {
      newSelected = newSelected.concat(selectedQutRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedQutRow.slice(0, selectedIndex),
        selectedQutRow.slice(selectedIndex + 1)
      );
    }
    setSelectedQutRow(newSelected);
    setSelectedQutLength(newSelected);
  };

  const isSelected = (name) => selectedQutRow.indexOf(name) !== -1;

  const renderActionsTwo = (row, index) => {
    return (
      <div className="flex gap-3">
        <Checkbox
          size="small"
          className="border border-gray-600 rounded bg-transparent"
          type="checkbox"
          color="primary"
          checked={isItemSelected}
          onClick={(event) => {
            handleClick(event, row);
            setQuotationQId(row.QId);
            props.setEnquiryItemRow(row);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Modal open={props.quotationListModal}>
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleQuotationListClose();
              }}
            />
          </div>

          <span className="grid justify-start text-center text-black font-bold text-xl my-auto pb-2">
            Quotation Details
          </span>
          <div className="grid grid-cols-4 gap-3">
            <div className="flex gap-3">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("fromDate", newValue);
                }}
                disablePast={false}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("toDate", newValue);
                }}
                disablePast={false}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className="">
              <CommonButton
                type="button"
                searchIcon={true}
                onClick={filterData}
                className="bg-customBlue text-white"
              />
            </div>
          </div>

          <div className="flex gap-5">
            <div className="w-[35%]">
              {dataResult.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={dataResult}
                  actionsRequired={true}
                  renderActions={renderActions}
                  tableClass={"rounded h-96"}
                />
              ) : (
                <h3 className="flex justify-center my-16 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>
            <div className="w-[65%]">
              {quotationDataResult.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={quotationDataResult}
                  actionsRequired={true}
                  renderActions={renderActionsTwo}
                  tableClass={"rounded h-96"}
                />
              ) : (
                <h3 className="flex justify-center my-16 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>
          </div>

          <div className="flex gap-3 justify-end">
            <>
              <div className="flex gap-2 justify-end">
                {selectedQutLength.length === 1 ? (
                  <div>
                    <CommonButton
                      type="button"
                      className="bg-customGreen text-white"
                      label="Ok"
                      onClick={() => {
                        getQuotationEnqItemDetailsList(quotationQId)
                          .then((response) => {
                            return response.data;
                          })
                          .then((res) => {
                            let arr = [];
                            for (let obj of res.result) {
                              let RequiredObj = {
                                ItemId: obj.ItemId,
                                ItemName: obj.ItemName,
                                PurchaseUOM: obj.PurchaseUOM,
                                Qty: obj.Qty,
                                Rate: obj.Rate,
                                Remarks: obj.Remarks,
                                amount: 0,
                                gstPercentage: 0,
                                gstAmount: 0,
                                concessionPercentage: 0,
                                concessionAmount: 0,
                                netAmount: 0,
                              };
                              arr.push(RequiredObj);
                              props.setQuotationData(arr);
                            }
                          })
                          .catch((error) => {});

                        props.handleQuotationListClose();
                      }}
                    />
                  </div>
                ) : null}

                {selectedQutLength.length === 1 ||
                selectedQutLength.length >= 2 ? (
                  <div>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="Comparative Settlement"
                      onClick={() => {
                        handleComparativeModalOpen();
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </>
          </div>
        </Box>
      </Modal>

      <ComparartiveModal
        openComparativeModal={openComparativeModal}
        handleComparativeModalClose={handleComparativeModalClose}
        selectQuotationData={selectQuotationData}
      />
    </div>
  );
}
