import authHeader from "../../../../authentication/authservices/auth-header";
import storeGRN from "../../../../http-common";

export const getVerifyGrnList = (searchObj) => {
  return storeGRN.post(`/grn/grnList`, searchObj, {
    headers: authHeader(),
  });
};

export const getVerifyGrnItemDetailsList = (grnId, storeId) => {
  return storeGRN.get(`/grn/grnDetailsList/${grnId}/${storeId}`, {
    headers: authHeader(),
  });
};

export const postVerifyGrn = (menuId, privilege, postObj) => {
  return storeGRN.post(`/grn/verifyGrn/${menuId}/${privilege}`, postObj, {
    headers: authHeader(),
  });
};
