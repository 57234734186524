import React from "react";
import AdmissionListing from "../../../ipd/components/admission/admissionlisting/AdmissionList";

const AdmittedPatients = (props) => {
  // let location = useLocation();
  //
  return (
    <div className="mt-11">
      <AdmissionListing
      //  menuId={location?.state?.menuId}
      updateComponent={props?.updateComponent}
      />
    </div>
  );
};

export default AdmittedPatients;
