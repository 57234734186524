import { Box, Modal } from "@mui/material";
import React, { useRef } from "react";
import { PrintOnIcon } from "../../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

function UploadFileViewModal(props) {
  const { openImageModal, setOpenImageModal, fileSrc } = props;

  const contentRef = useRef();
  const generatePDF = () => {
    // Clone the content of the div for printing
    const clonedContent = contentRef.current.cloneNode(true);
    const printStyles = `
    body {
      font-family: Arial, sans-serif;
    }
    .print:last-child {
      page-break-after: auto;
    }
    @media screen {
      @page {
        size: landscape !important;
      }
      #pdfContent {
        display: none !important;
      }
    }
    @media print {
      body * {
        
        visibility: hidden;
      }
      #pdfContent, #pdfContent * {
        visibility: visible;
      }
      #pdfContent {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    `;

    const style = document.createElement("style");
    style.innerHTML = printStyles;

    clonedContent.id = "pdfContent";
    document.body.appendChild(style);
    document.body.appendChild(clonedContent);

    window.print();

    document.body.removeChild(style);
    document.body.removeChild(clonedContent);
  };
  return (
    <div>
      <div className="hidden">
        <div ref={contentRef}>
          <img src={fileSrc?.src} />
        </div>
      </div>
      <Modal open={openImageModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "90%",
            minHeight: "80%",
            // maxHeight: "80%",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 20,
            py: 4,
            px: 2,
          }}
          className="max-h-[88%] xl:max-h-[100%]"
        >
          <div className="flex justify-between items-center">
            {fileSrc?.type !== "pdf" && (
              <button
                className="-mt-7"
                onClick={() => {
                  generatePDF();
                }}
              >
                <PrintOnIcon title="Print Image" />
              </button>
            )}
            <CancelPresentationIconButton
              onClick={() => {
                setOpenImageModal(false);
              }}
            />
          </div>
          <div className="mt-4">
            {fileSrc?.type !== "pdf" ? (
              <div>
                <img
                  id="imageId"
                  src={fileSrc?.src}
                  // width="1300" height="800"
                  style={{ height: "500", width: "100%" }}
                />
              </div>
            ) : (
              <embed
                type="application/pdf"
                height="620"
                width="100%"
                src={fileSrc?.src}
              />
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default UploadFileViewModal;

