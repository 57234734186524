import axios from "axios";
import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
//autocomplete search service
// /api/patientInfo/admissionAutoComplete/{patientInfo}
export const getPatient = (patientInfo) => {
  return apiClient.get(`/patientInfo/admissionAutoComplete/${patientInfo}`, {
    headers: authHeader(),
  });
};

// export const getRefundOfBillPatientInfoById = (patientId,opdIpd) => {
//
//   return apiClient.get(`/patientInfo/bill/${patientId}/${opdIpd}`);
// };
export const getRefundOfBillPatientInfoById = (patientId) => {
  return apiClient.get(`/patientInfo/registration/${patientId}`, {
    headers: authHeader(),
  });
};

//Bill listing
export const billListingService = (patientId) => {
  return apiClient.get(`/refundOfBill/listOfIpdBill/${patientId}`, {
    headers: authHeader(),
  });
};
//Refund listing
// refundOfBill/listOfIpdRefund/1059222

export const refundListingService = (admissionId) => {
  return apiClient.get(`/refundOfBill/listOfIpdRefund/${admissionId}`, {
    headers: authHeader(),
  });
};

//servicce listing
export const serviceListing = (billId) => {
  return apiClient.get(`/refundOfBill/billServiceList/${billId}`, {
    headers: authHeader(),
  });
};
//post refund of bill
// /api/refundOfBill/saveRefundOfBill
export const postRefundOfBillService = (postObj) => {
  return apiClient.post(`/refundOfBill/saveRefundOfBill`, postObj, {
    headers: authHeader(),
  });
};

// print
export const ipdRefundOfBillPdfPrint = (advanceId, opdIpd) => {
  return apiClient.get(
    `/reports/bills/getRefundOfBill/${advanceId}/${opdIpd}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};
