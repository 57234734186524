import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import "../../../../input.css";

import CommonButton from "../../../../common/components/Buttons/CommonButton";
import InputArea from "../../../../common/components/FormFields/InputArea";
import RadioField from "../../../../common/components/FormFields/RadioField";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import RetriveParametersModal from "./RetriveParametersModal";

const investigationsType = [
  {
    id: 0,
    label: "Pathalogy",
    value: "Pathalogy",
  },
  {
    id: 1,
    label: "Radiology",
    value: "Radiology",
  },
];
const PatientDetails = () => {
  const { patientData } = useContext(DischargeSummaryContext);

  const { register, control } = useFormContext();
  const [openRetriveParamModal, setOpenRetriveParamModal] = useState(false);
  const handleOpenRetriveParamModal = () => setOpenRetriveParamModal(true);
  const handleCloseRetriveParamModal = () => setOpenRetriveParamModal(false);

  return (
    <div>
      <div className="grid grid-cols-2 gap-3">
        {/* ///conditionAtDischarge /// */}

        <div className=" ">
          <InputArea
            control={control}
            placeholder="Condition at Discharge"
            name="conditionAtDischarge"
            // {...register("conditionAtDischarge")}
            label="Condition at Discharge"
            rows={6}
          />
        </div>
        <div>
          {/* //courseInHospital.// */}

          <InputArea
            control={control}
            name="courseInHospital"
            label="Course In Hospital"
            placeholder="Course In Hospital"
            rows={6}
            fullWidth
          />
        </div>
        {/* //diagnosisICD// */}
        <div className=" ">
          <InputArea
            control={control}
            name="diagnosisICD"
            label="Diagnosis/ICD"
            placeholder="Diagnosis/ICD"
            rows={6}
          />
        </div>

        {/* //investigationsPathology// */}
        <div>
          <div className="">
            <InputArea
              control={control}
              name="investigationsPathology"
              label="Investigations/ Pathology"
              placeholder="Investigations/ Pathology"
              rows={6}
            />
          </div>
        </div>
        {patientData && (
          <div className="my-3 flex justify-end col-span-2 gap-3">
            <div className="flex items-center space-x-2">
              <label className="font-semibold whitespace-nowrap">
                Investigations Type:
              </label>

              <span className="pt-1">
                <RadioField
                  label=""
                  name="investigations"
                  control={control}
                  dataArray={investigationsType}
                />
              </span>
            </div>
            <CommonButton
              label="Retrieve Investigations"
              className="border border-customBlue text-customBlue"
              onClick={() => handleOpenRetriveParamModal()}
            />
          </div>
        )}
      </div>
      {openRetriveParamModal ? (
        <RetriveParametersModal
          open={openRetriveParamModal}
          setOpen={setOpenRetriveParamModal}
          handleOpen={handleOpenRetriveParamModal}
          handleClose={handleCloseRetriveParamModal}
        />
      ) : null}
    </div>
  );
};

export default PatientDetails;
