import React from "react";

function OnClickSaveButton({ onClick }) {
  return (
    <div>
      <button
        type="button"
        className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium overflow-hidden transform   "
        onClick={onClick}
      >
        Save
      </button>
    </div>
  );
}

export default OnClickSaveButton;
