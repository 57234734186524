import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Modal, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { OtDetailsContext } from "./OtDetails";
import DocumentsViewModal from "../../../../ipd/components/admission/commonModal/DocumentsViewModal";
import { EyeOnIcon } from "../../../../assets/icons/CustomIcons";
import { useState } from "react";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { deleteOTFiles } from "../../../services/otDetailsServices/OtDetailsServices";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";

const UploadFileDetails = (props) => {
  const {
    patientdocs,
    setPatientdocs,
    menuId,
    privilege,
    OTHeaderID,
  } = useContext(OtDetailsContext);

  const [
    openlDeleteDocConfirmationModal,
    setOpenlDeleteDocConfirmationModal,
  ] = useState(false);
  const [docRowIndex, setDocRowIndex] = useState(null);

  //
  const [pathForDocView, setPathForDocView] = useState();
  //
  const [openDocumentsViewModal, setOpenDocumentsViewModal] = React.useState(
    false
  );
  const handleOpenDocumentsViewModal = () => setOpenDocumentsViewModal(true);
  const handleCloseDocumentsViewModal = () => setOpenDocumentsViewModal(false);
  //

  const { register, getValues, resetField } = useFormContext();

  //patient Documents
  const handleAddPatientDocuments = () => {
    let DocData = getValues("PatientDocumentsFileData");

    let serviceObj = {
      Doc: DocData,
      DocName: DocData[0].name,
      Docfile: DocData[0],
    };

    let arr = [...patientdocs];
    arr.push(serviceObj);
    setPatientdocs(arr);

    resetField("PatientDocumentsFileData");
  };

  const renderActionsPatient = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...patientdocs];
      newTemplateData.splice(index, 1);
      setPatientdocs(newTemplateData);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="text-red-500">
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                if (row.FilePath) {
                  setDocRowIndex({ ...row, index });
                  setOpenlDeleteDocConfirmationModal(true);
                } else {
                  deleteRow(index);
                }
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const handleDeleteDoc = (docRow) => {
    const deleteDocobj = {
      documentId: docRow.ID,
      menuId: menuId,
      otHeaderId: OTHeaderID,
      privilege: privilege,
    };

    if (docRow) {
      deleteOTFiles(deleteDocobj)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          setOpenlDeleteDocConfirmationModal(false);
          let newTemplateData = [...patientdocs];
          newTemplateData.splice(docRow.index, 1);
          setPatientdocs(newTemplateData);
        })
        .catch((error) => {
          errorAlert(error.message);
          setOpenlDeleteDocConfirmationModal(false);
        });
    }
  };

  const renderInputPatient = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.FilePath);
      handleOpenDocumentsViewModal(true);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          {row.FilePath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {/* Patient Documents */}
      <div>
        <p className="font-bold tracking-wide text-sm mb-3  font-Poppins">
          File Upload
        </p>
        <div className="grid grid-cols-12 gap-3 items-center ">
          <div className="col-span-7 lg:col-span-3 ">
            <input type="file" {...register(`PatientDocumentsFileData`)} />
          </div>

          <div className="justify-end">
            <Tooltip title="Add">
              <AddCircleIcon
                sx={{ color: "#1e3a8a" }}
                onClick={() => {
                  handleAddPatientDocuments();
                }}
              />
            </Tooltip>
          </div>
        </div>

        {patientdocs.length > 0 ? (
          <div className="w-full mt-2">
            {/* <DocumentsTable data={patientdocs} setState={setPatientdocs} /> */}
            <CommonDynamicTableNew
              dataResult={patientdocs}
              renderActions={renderActionsPatient}
              removeHeaders={[
                "Docfile",
                "ID",
                "Doc",
                "docId",
                "baseSixtyFourString",
              ]}
              editableColumns={["FilePath", "DocName"]}
              renderInput={renderInputPatient}
              tableClass="rounded h-36"
              highlightRow={false}
            />
          </div>
        ) : null}
      </div>

      {/* Documents view modal */}
      {openDocumentsViewModal ? (
        <DocumentsViewModal
          open={openDocumentsViewModal}
          setOpen={setOpenDocumentsViewModal}
          handleOpen={handleOpenDocumentsViewModal}
          handleClose={handleCloseDocumentsViewModal}
          pathForDocView={pathForDocView}
        />
      ) : null}

      {/* /// modal */}
      <Modal open={openlDeleteDocConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Confirmation
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To Delete Document ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setOpenlDeleteDocConfirmationModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      handleDeleteDoc(docRowIndex);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UploadFileDetails;
