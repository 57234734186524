import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useFormContext } from "react-hook-form";

export default function PatientFallAssessmentTable(props) {
  // Context
  const [tableFormDisableFlag, setTableFormDisableFlag] = React.useState(false);

  

  const { watch, register, setValue, getValues } = useFormContext(); // retrieve all hook methods

  let ageVal = watch("Age");
  let eliminationVal = watch("Elimination");
  let medicationsVal = watch("Medications");
  let fallHistoryVal = watch("FallHistory");
  let patientCareEquipementVal = watch("PatientCareEquipment");

  
 

  let mobilityOne = watch("mobilityOne");
  let mobilityTwo = watch("mobilityTwo");
  let mobilityThree = watch("mobilityThree");

  let cognitionOne = watch("cognitionOne");
  let cognitionTwo = watch("cognitionTwo");
  let cognitionThree = watch("cognitionThree");

  

  //to disable the local form through the radio buttons of the tabular form.
  if (
    ageVal ||
    fallHistoryVal ||
    eliminationVal ||
    medicationsVal ||
    patientCareEquipementVal
  ) {
    if (props.localFormDisableFlag === false) {
      props.setLocalFormDisableFlag(true);
    }
  }

  //useEffect to disable the tabular form ; if even a single field inside the local form becomes dirty
  React.useEffect(() => {
    if (
      (props.highFallRiskArrayValue &&
        props.highFallRiskArrayValue.length > 0) ||
      (props.lowFallRiskArrayValue && props.lowFallRiskArrayValue.length > 0)
    ) {
      setTableFormDisableFlag(true);

      setValue("Age", null);
      setValue("Elimination", null);
      setValue("Medications", null);
      setValue("PatientCareEquipment", null);
      setValue("FallHistory", null);
      setValue("mobility", []);
      setValue("cognition", []);
    }

    if (
      props.highFallRiskArrayValue &&
      props.highFallRiskArrayValue.length === 0 &&
      props.lowFallRiskArrayValue &&
      props.lowFallRiskArrayValue.length === 0
    ) {
      setTableFormDisableFlag(false);
    }

    //When the object that we have in the get request ; comes to us with empty hopkinsScoreArray ;
    //then at that time ; keep the table form always disabled.
    if (
      props.patchedNursingInfo &&
      props.patchedNursingInfo.data.result.ptFallAssPatchDto
        .hopkinsScoreHeaderPatchDtoList instanceof Array
    ) {
      if (
        props.patchedNursingInfo.data.result.ptFallAssPatchDto
          .hopkinsScoreHeaderPatchDtoList.length === 0
      ) {
        setTableFormDisableFlag(true);
      }
    }
  }, [
    props.highFallRiskArrayValue,
    props.lowFallRiskArrayValue,
    props.patchedNursingInfo,
  ]);

  let ageValElement = 0;
  let fallHistoryValElement = 0;
  let eliminationValElement = 0;
  let medicationsValElement = 0;
  let patientCareEquipementValElement = 0;
  let mobilityScore = 0;
  let cognitionScore = 0;
  // let fallAssessmentScore = 0;

  //Calculating the display of ageVal for the table.
  if (ageVal === "60 - 69 years") {
    ageValElement = 1;
  } else if (ageVal === "70 -79 years") {
    ageValElement = 2;
  } else if (ageVal === "Greater than or equal to 80 years") {
    ageValElement = 3;
  }

  //Calculating the display of fallHistoryVal for the table.
  if (fallHistoryVal === "One fall within 6 months before admission") {
    fallHistoryValElement = 5;
  }

  //Calculating the display of eliminationVal for the table.
  if (eliminationVal === "Incontinence") {
    eliminationValElement = 2;
  } else if (eliminationVal === "Urgency or frequency") {
    eliminationValElement = 2;
  } else if (eliminationVal === "Urgency/frequency and incontinence") {
    eliminationValElement = 4;
  }

  if (medicationsVal === "On 1 high fall risk drug") {
    medicationsValElement = 3;
  } else if (medicationsVal === "On 2 or more high fall risk drugs") {
    medicationsValElement = 5;
  } else if (medicationsVal === "Sedated procedure within past 24 hours") {
    medicationsValElement = 7;
  }

  if (patientCareEquipementVal === "One present") {
    patientCareEquipementValElement = 1;
  } else if (patientCareEquipementVal === "Two present") {
    patientCareEquipementValElement = 2;
  } else if (patientCareEquipementVal === "3 or more present") {
    patientCareEquipementValElement = 3;
  }

  // calculating mobility score
  if (mobilityOne || mobilityTwo || mobilityThree) {
    let m = 0;

    if (mobilityOne) {
      m = m + 2;
    }

    if (mobilityTwo) {
      m = m + 2;
    }

    if (mobilityThree) {
      m = m + 2;
    }

    mobilityScore = m;
  }

  // calculating cognition score
  if (cognitionOne || cognitionTwo || cognitionThree) {
    let c = 0;

    if (cognitionOne) {
      c = c + 1;
    }

    if (cognitionTwo) {
      c = c + 2;
    }

    if (cognitionThree) {
      c = c + 4;
    }

    cognitionScore = c;
  }

  //useEffect to disable the local form through mobility checkbox.
  React.useEffect(() => {
    let fetchedAge = getValues("Age");
    let fetchedFallHistory = getValues("FallHistory");
    let fetchedElimination = getValues("Elimination");
    let fetchedMedications = getValues("Medications");
    let fetchedPatientCareEquipment = getValues("PatientCareEquipment");

    let mobilityOneVal = getValues("mobilityOne");
    let mobilityTwoVal = getValues("mobilityTwo");
    let mobilityThreeVal = getValues("mobilityThree");

    if (mobilityScore === 0) {
      if (
        fetchedAge === null &&
        fetchedFallHistory === null &&
        fetchedElimination === null &&
        fetchedMedications === null &&
        fetchedPatientCareEquipment === null &&
        mobilityOneVal === false &&
        mobilityTwoVal === false &&
        mobilityThreeVal === false
      ) {
        props.setLocalFormDisableFlag(false);
      }
    } else if (
      mobilityOneVal === true ||
      mobilityTwoVal === true ||
      mobilityThreeVal === true
    ) {
      props.setLocalFormDisableFlag(true);
    }
  }, [mobilityScore]);

  //useEffect to disable the local form through cognition checkbox.
  React.useEffect(() => {
    let fetchedAge = getValues("Age");
    let fetchedFallHistory = getValues("FallHistory");
    let fetchedElimination = getValues("Elimination");
    let fetchedMedications = getValues("Medications");
    let fetchedPatientCareEquipment = getValues("PatientCareEquipment");

    let cognitionOneFlagVal = getValues("cognitionOne");
    let cognitionTwoFlagVal = getValues("cognitionTwo");
    let cognitionThreeFlagVal = getValues("cognitionThree");

    if (cognitionScore === 0) {
      if (
        fetchedAge === null &&
        fetchedFallHistory === null &&
        fetchedElimination === null &&
        fetchedMedications === null &&
        fetchedPatientCareEquipment === null &&
        cognitionOneFlagVal === false &&
        cognitionTwoFlagVal === false &&
        cognitionThreeFlagVal === false
      ) {
        props.setLocalFormDisableFlag(false);
      }
    } else if (
      cognitionOneFlagVal === true &&
      cognitionTwoFlagVal === true &&
      cognitionThreeFlagVal === true
    ) {
      props.setLocalFormDisableFlag(true);
    }
  }, [cognitionScore]);

  //calculation of fall assessment score
  let fallAssessmentScore =
    ageValElement +
    fallHistoryValElement +
    eliminationValElement +
    medicationsValElement +
    patientCareEquipementValElement +
    mobilityScore +
    cognitionScore;

  return (
    <div>
      <form>
        <TableContainer component={Paper}>
          <Table
            sx={
              tableFormDisableFlag
                ? { minWidth: 650, backgroundColor: "#e5e7eb" }
                : { minWidth: 650, backgroundColor: "#ffffff" }
            }
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                sx={
                  tableFormDisableFlag
                    ? {
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#e5e7eb",
                          // backgroundColor: "#EBB700",
                          // backgroundColor: "yellow",
                        },
                      }
                    : {
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#ffffff",
                          // backgroundColor: "#e5e7eb",
                          // backgroundColor: "red",
                        },
                      }
                }
              >
                <TableCell
                  className="text-lg whitespace-nowrap"
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    borderTop: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center">
                    <p className="text-black">
                      FALL RISK SCORE CALCULATION &nbsp; - &nbsp;
                    </p>
                    <p className="text-black text-sm">
                      Select the appropriate option in each category. Add all
                      points to calculate Fall Risk Score.
                      <br /> (If no option is selected, score for category is 0)
                    </p>
                  </div>
                </TableCell>
                <TableCell
                  sx={{ width: 170, textAlign: "center" }}
                  className="text-lg text-center"
                  style={{
                    fontWeight: "bold",
                    borderRight: "1px solid lightGray",
                    borderTop: "1px solid lightGray",
                  }}
                >
                  Points
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold">Age</p>
                    <span className="text-gray-700 text-xs font-semibold">
                      (single-select)
                    </span>
                    <span>
                      {/* <p style={{ color: "red" }}>{errors.Age?.message}</p> */}
                    </span>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label="60 - 69 years"
                      value={"60 - 69 years"}
                      name="Age"
                      {...register("Age")}
                    />
                    <span>60 - 69 years (1 point)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"70 -79 years"}
                      name="Age"
                      {...register("Age")}
                    />
                    <span className=""> 70 -79 years (2 points)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"Greater than or equal to 80 years"}
                      name="Age"
                      {...register("Age")}
                    />
                    <span className="">
                      Greater than or equal to 80 years (3 points)
                    </span>
                  </fieldset>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <span>{ageValElement}</span>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold">
                      Fall History (single-select)
                    </p>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"One fall within 6 months before admission"}
                      name="FallHistory"
                      {...register("FallHistory")}
                    />

                    <span className="">
                      One fall within 6 months before admission (5 points)
                    </span>
                  </fieldset>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{fallHistoryValElement}</p>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold">
                      Elimination, Bowel and Urine
                    </p>
                    <span className="text-gray-700 text-xs font-semibold">
                      (single-select)
                    </span>
                    <span>
                      <p style={{ color: "red" }}>
                        {/* {errors.Elimination?.message} */}
                      </p>
                    </span>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"Incontinence"}
                      {...register("Elimination")}
                      name="Elimination"
                    />
                    <span className="">Incontinence (2 points)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"Urgency or frequency"}
                      {...register("Elimination")}
                      name="Elimination"
                    />
                    <span className="">Urgency or frequency (2 points)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"Urgency/frequency and incontinence"}
                      {...register("Elimination")}
                      name="Elimination"
                    />
                    <span className="">
                      Urgency/frequency and incontinence (4 points)
                    </span>
                  </fieldset>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{eliminationValElement}</p>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold">
                      Medications : &nbsp;
                      <span className="text-sm text-black ">
                        Includes PCA/opiates, anticonvulsants,
                        anti-hypertensives, diuretics, hypnotics, laxatives,
                        sedatives, and psychotropics.
                      </span>
                      <span className="text-gray-700 text-xs font-semibold">
                        (single-select)
                        <span>
                          <p style={{ color: "red" }}>
                            {/* {errors.Medications?.message} */}
                          </p>
                        </span>
                      </span>
                    </p>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"On 1 high fall risk drug"}
                      name="Medications"
                      {...register("Medications")}
                    />
                    <span className="">
                      On 1 high fall risk drug (3 points)
                    </span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"On 2 or more high fall risk drugs"}
                      name="Medications"
                      {...register("Medications")}
                    />
                    <span className="">
                      On 2 or more high fall risk drugs (5 points)
                    </span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"Sedated procedure within past 24 hours"}
                      name="Medications"
                      {...register("Medications")}
                    />
                    <span className="">
                      Sedated procedure within past 24 hours (7 points)
                    </span>
                  </fieldset>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{medicationsValElement}</p>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold whitespace-nowrap">
                      Patient Care Equipment :
                    </p>
                    <span>
                      Any equipment that tethers patient (e.g., IV infusion,
                      chest tube, indwelling catheter, SCDs, etc.)
                    </span>
                    <span className="text-gray-700 text-xs font-semibold">
                      (single-select)
                    </span>
                    <span style={{ color: "red" }}>
                      {/* {errors.PatientCareEquipment?.message} */}
                    </span>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"One present"}
                      name="PatientCareEquipment"
                      {...register("PatientCareEquipment")}
                    />
                    <span className="">One present (1 point)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"Two present"}
                      name="PatientCareEquipment"
                      {...register("PatientCareEquipment")}
                    />
                    <span className="">Two present (2 points)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-3"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      className="h-4 w-4"
                      type="radio"
                      label=""
                      value={"3 or more present"}
                      name="PatientCareEquipment"
                      {...register("PatientCareEquipment")}
                    />
                    <span className="">3 or more present (3 points)</span>
                  </fieldset>
                </TableCell>
                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{patientCareEquipementValElement}</p>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold">
                      Mobility
                    </p>
                    <span className="text-gray-700 text-xs font-semibold">
                      (Multi-select)
                      <span style={{ color: "red" }}>
                        {/* {errors.Mobility?.message} */}
                      </span>
                    </span>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-1"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name="mobilityOne"
                      label=""
                      value="Requires assistance or supervision for mobility, transfer,or ambulation"
                      {...register("mobilityOne")}
                    />
                    <span className="">
                      Requires assistance or supervision for mobility, transfer,
                      or ambulation (2 points)
                    </span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-1"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name="mobilityTwo"
                      label=""
                      value="Unsteady gait"
                      {...register("mobilityTwo")}
                    />
                    <span className="">Unsteady gait (2 points)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-1"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name="mobilityThree"
                      label=""
                      value="Visual or auditory impairment affecting mobility"
                      {...register("mobilityThree")}
                    />
                    <span className="">
                      Visual or auditory impairment affecting mobility (2
                      points)
                    </span>
                  </fieldset>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{mobilityScore}</p>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  "& td": {
                    paddingY: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderLeft: "1px solid lightGray",
                    fontWeight: "bold",
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-base text-black font-semibold">
                      Cognition
                    </p>
                    <span className="text-gray-700 text-xs font-semibold">
                      (Multi-select)
                    </span>
                  </div>

                  <fieldset
                    className="flex items-center gap-2 py-1"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name="cognitionOne"
                      label=""
                      value="Altered awareness of immediate physical environment (1 point)"
                      {...register("cognitionOne")}
                    />
                    <span className="">
                      Altered awareness of immediate physical environment (1
                      point)
                    </span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-1"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name="cognitionTwo"
                      label=""
                      value="Impulsive (2 points)"
                      {...register("cognitionTwo")}
                    />
                    <span className="">Impulsive (2 points)</span>
                  </fieldset>

                  <fieldset
                    className="flex items-center gap-2 py-1"
                    disabled={tableFormDisableFlag}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name="cognitionThree"
                      label=""
                      value="Lack of understanding of one's physical and cognitive limitations (4 points)"
                      {...register("cognitionThree")}
                    />
                    <span className="">
                      Lack of understanding of one's physical and cognitive
                      limitations (4 points)
                    </span>
                  </fieldset>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "1px solid lightGray",
                    borderRight: "1px solid lightGray",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{cognitionScore}</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <h2 className="text-base font-semibold my-2">
          <div>
            <div className="justify-self-start col-span-2 pl-4">
              <div className="flex ">
                <div className="justify-start">
                  SCORING : 6-13 Total Points =
                  <span className="text-customOrange">Moderate Fall Risk </span>
                  ,{" > 13 "}
                  Total Points =
                  <span className="text-customRed"> High Fall Risk</span>
                </div>

                <div className="flex gap-4 justify-end  md:ml-28 lg:ml-64">
                  <div className="text-black flex items-center">Total</div>

                  {fallAssessmentScore > 13 ? (
                    <div class="w-10 h-10 rounded-full border-2 border-red-500 flex justify-center  bg-red-500 items-center  place-items-center space-x-4">
                      <div class=" text-white py-2 ">{fallAssessmentScore}</div>
                    </div>
                  ) : null}

                  {fallAssessmentScore >= 6 && fallAssessmentScore <= 13 ? (
                    <div class="w-10 h-10 rounded-full border-2 border-yellow-500 flex justify-center  bg-yellow-500 items-center  place-items-center space-x-4">
                      <div class=" text-white py-2 ">{fallAssessmentScore}</div>
                    </div>
                  ) : null}

                  {fallAssessmentScore >= 0 && fallAssessmentScore < 6 ? (
                    <div class="w-10 h-10  flex justify-center items-center  place-items-center space-x-4">
                      <div class=" text-black py-2 ">{fallAssessmentScore}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </h2>
      </form>
    </div>
  );
}
