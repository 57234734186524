import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const getScaleScoringSheetHeader = (nihStrokeId) => {
  return apiClient.get(
    `/nihStrokeScale/scaleScoringSheetHeader/${nihStrokeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getEligibleCriteriaHeader = (nihStrokeId) => {
  return apiClient.get(
    `/nihStrokeScale/eligibleCriteriaHeader/${nihStrokeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientList = (postObj) => {
  return apiClient.post(`/nihStrokeScale/patientList`, postObj, {
    headers: authHeader(),
  });
};

export const saveNihStrokeScale = (postObj) => {
  return apiClient.post(`/nihStrokeScale/saveNihStrokeScale`, postObj, {
    headers: authHeader(),
  });
};

export const getPatientInfoAutoCompleteForOpdAndIpd = (
  searchString,
  opdIpd
) => {
  return apiClient.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const getNihStrokeScaleSheet = (nihStrokeId) => {
  return apiClient.get(
    `/reports/emergency/nihStrokeScaleSheet/${nihStrokeId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    },
  );
};
