import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { saveChangedPassword } from "../../services/changePasswordServices/ChangePasswordServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { logout } from "../../../authentication/slices/auth";

const schema = yup.object().shape({
  //loginName: yup.string().required("Login Name is required"),
  oldPassword: yup.string().required("Old Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(5, "New Password must be at least 6 characters"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});

const ChangePassword = (props) => {
  let location = useLocation();

  const token = JSON.parse(localStorage.getItem("userInfo"));
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [formData, setFormData] = useState(null);
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  ///

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      //loginName: token?.loginName,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
  } = methods;

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  //   saveChangedPassword;

  const onSubmit = (data) => {
    setFormData(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);

    setOpenBackdrop(true);

    saveChangedPassword({
      confirmPassword: data.confirmNewPassword,
      loginName: token?.loginName,
      newPassword: data.newPassword,
      oldPassword: data.oldPassword,
      userId: token.userId,
      privilege: privilege,
      menuId: location?.state?.menuId,
    })
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          logout();
          //   localStorage.clear();
          //   props.setIsLogin(false);
        }
      })
      .catch((res) => {
        console.error(res);
        errorAlert(res?.response?.data?.message);
        setOpenBackdrop(false);
      });
  };

  return (
    <div className="">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div class="flex items-center justify-center h-[97vh]">
          <div class="bg-gray-100 p-8 rounded-md grid gap-y-3">
            <p className="text-center font-semibold">Change Password</p>
            {/* <div className="w-64">
              <InputField
                name="loginName"
                label="Login Name"
                variant="outlined"
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
                error={errors.loginName}
              />
            </div> */}
            <div className="">
              <InputField
                name="oldPassword"
                label="Old Password*"
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
                error={errors.oldPassword}
              />
            </div>
            <div className="">
              <InputField
                name="newPassword"
                label="New Password*"
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
                error={errors.newPassword}
                type={"password"}
                showPasswordIcon={true}
                autoComplete="off"
              />
            </div>
            <div className="">
              <InputField
                name="confirmNewPassword"
                label="Confirm New Password*"
                variant="outlined"
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
                error={errors.confirmNewPassword}
                type={"password"}
                showPasswordIcon={true}
              />
            </div>
            <div className="flex justify-end gap-3">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <div>
                        <CommonButton
                          label="Reset"
                          className="border border-customRed text-customRed"
                          onClick={() => methods.reset()}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setPrivilege(obj.action);
                        }}
                      >
                        <CommonButton
                          type="submit"
                          label="Save"
                          className="bg-customGreen text-white"
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </form>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(formData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default ChangePassword;
