import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Dot,
  Label,
  LabelList,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from "recharts";
// import "../App.css";

import { Scatter, ScatterChart } from "recharts";
// import { tpiChartData } from "../../../services/cliinicalChartServices/vitalsService/VitalsService";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { tpiChartData } from "../services/cliinicalChartServices/vitalsService/VitalsService";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";

const tealwindColors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "red",
  "pink",
];

const VitalsTPRPrint = (props) => {
  const printRef = useRef(null);

  const [patientInfo, setPatientInfo] = useState({});
  const [result, setResult] = useState([]);
  const [patientInfoDate, setPatientInfoDate] = useState({});
  const [lineChartList, setLineChartList] = useState({});
  const [scatterChartList, setScatterChartList] = useState({});

  useEffect(() => {
    const postObj = {
      admissionId: props.admissionId,
      fromDate: props.fromDate,
      toDate: props.toDate,
      fromTime: props.fromTime,
      toTime: props.toTime,
    };
    tpiChartData(postObj)
      .then((response) => response.data.result)
      .then((res) => {
        if (res.vitalList.length === 0) {
          warningAlert("Please Select Valid Date And Time..!");
        } else {
          const obj = {
            "Mr No": res.MRNo,
            "Patient Name": res.PatientName,
            "Doctor Name": res.DoctorName,
            "Admission Date": res.AdmissionDate,
          };
          setPatientInfo(obj);
          const patientInfoDate = {
            "From Date": res.FromDate,
            "To Date": res.ToDate,
          };
          setPatientInfoDate(patientInfoDate);
          const patientObj = res.vitalList.map((data) => {
            return {
              Date: data.Date,
              Time: data.Time,
              Temp: data.Temperature || 0,
              Pulse: data.Pulse || 0,
              Resp: data.Respiration || 0,
              BP: data.BP || "0/0",
              MEWS: data.MewsScore || 0,
              Oral: data.oral || false,
              Tracheostomy: data.tracheostomy || false,
              ETT: data.Ett || false,
              Nasal: data.Nasal || false,
              SaturationwithO2: data.saturationwitho2 || 0,
              Saturationwithouto2: data.saturationwithouto2 || 0,
            };
          });

          const LineChartObjList = res.vitalList.map((data) => {
            return {
              Time: data.Time,
              Temp: data.Temperature,
            };
          });
          const ScatterChartObjList = res.BpList.map((data) => {
            return {
              Time: data.Time,
              LowerBp: data["Lower BP"],
              UpperBp: data["Upper BP"],
            };
          });
          setScatterChartList(ScatterChartObjList);
          setLineChartList(LineChartObjList);
          setResult([...patientObj]);
        }
      });
  }, [props]);

  useEffect(() => {
    result.length > 0 && printRef && GeneratePrint(printRef);
  }, [result]);

  const headers = [
    "Date",
    "Time",
    "Temp",
    "Pulse",
    "Resp",
    "BP",
    "MEWS",
    "Oral",
    "Tracheostomy",
    "ETT",
    "Nasal",
    "SaturationwithO2",
    "Saturationwithouto2",
  ];

  const editableColumns = ["Oral", "Tracheostomy", "ETT", "Nasal"];

  const CustomizedShape = (props) => {
    const { cx, cy, fill, riderName, value, data, LowerBp } = props;
    return (
      <g>
        <Dot cx={cx} cy={cy} r={5} fill={fill} />
        <g transform={`translate(${cx},${cy})`}>
          <text
            x={-10}
            y={0}
            dy={-10}
            textAnchor="bottom"
            className="text-sm font-semibold tracking-wide"
          >
            {LowerBp}
          </text>
        </g>
      </g>
    );
  };

  const CustomizedShapeUpper = (props) => {
    const { cx, cy, fill, riderName, value, data, UpperBp } = props;
    return (
      <g>
        <Dot cx={cx} cy={cy} r={5} fill={fill} />
        <g transform={`translate(${cx},${cy})`}>
          <text
            x={-10}
            y={0}
            dy={-10}
            textAnchor="bottom"
            className="text-sm font-semibold tracking-wide"
          >
            {UpperBp}
          </text>
        </g>
      </g>
    );
  };

  return (
    <div ref={printRef}>
      <div className="mt-2 ">
        <table className="w-full break-after-page">
          <thead
            style={{
              "-fs-table-paginate": "paginate",
            }}
          >
            <tr>
              <th colSpan={headers?.length + 1}>
                <div className="flex justify-between gap-4 w-full">
                  <div className="space-y-2  text-left justify-start font-normal ">
                    {Object.entries(patientInfo).map(([key, value]) => (
                      <div key={key} className="flex items-center">
                        <p className="text-[11px] font-semibold w-[100px]">
                          {key}
                        </p>
                        <p className=" font-semibold text-[11px] mx-2">:</p>
                        <p className="text-[11px] whitespace-nowrap">{value}</p>
                      </div>
                    ))}
                  </div>

                  <InamdarHospiLogo />
                </div>
                <div>
                  <div className="flex  justify-end items-end gap-2 font-normal">
                    {Object.entries(patientInfoDate).map(([key, value]) => (
                      <div key={key} className="flex items-center">
                        <p className="text-[11px]   font-semibold  w-35">
                          {key}
                        </p>
                        <p className="font-semiboldtext-[11px] mx-2">:</p>
                        <p className="text-[11px] whitespace-nowrap">{value}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="font-semibold text-center border-t-[1px] border-black mt-1 text-[18px]  whitespace-nowrap">
                  TPR Chart
                </div>
              </th>
            </tr>

            <tr className="border-b-[1px] border-t-[1px] mt[1px]  border-black  w-full ">
              {headers?.map((header, index) => (
                <th key={index} className="py-1 gap-5">
                  <td className=" font-semibold flex truncate gap-2 text-[11px]">
                    {header}
                  </td>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {result?.map((row, rowIndex) => (
              <tr key={rowIndex} className=" text-left border-gray-200">
                {headers.map((header, columnIndex) => (
                  <td
                    key={columnIndex}
                    className="px-2   text-[11px]  whitespace-nowrap"
                  >
                    {editableColumns &&
                    editableColumns.includes(header) &&
                    typeof row[header] === "boolean" ? (
                      <Checkbox
                        defaultChecked={row[header]}
                        color="default"
                        size="small"
                        disabled
                        sx={{ padding: 0 }}
                      />
                    ) : (
                      row[header]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <table className=" break-after-page w-full">
          <div className="flex justify-between">
            <div className="space-y-2  text-left justify-start font-normal">
              {Object.entries(patientInfo).map(([key, value]) => (
                <div key={key} className="flex items-center">
                  <p className="text-[11px] font-semibold w-[100px]">{key}</p>
                  <p className="font-semibold  text-[11px] mx-2">:</p>
                  <p className="text-[11px] whitespace-nowrap">{value}</p>
                </div>
              ))}
            </div>
            <div className="mr-8">
              <InamdarHospiLogo />
            </div>
          </div>

          <div className="flex  justify-end items-end gap-2 py-1 ">
            {Object.entries(patientInfoDate).map(([key, value]) => (
              <div key={key} className="flex items-center">
                <p className="text-[11px] font-semibold w-35">{key}</p>
                <p className="font-semibold text-[11px] mx-2">:</p>
                <p className="text-[11px] whitespace-nowrap">{value}</p>
              </div>
            ))}
          </div>

          <div className="font-semibold text-center border-t-[1px] border-black mt-1 text-[18px]  whitespace-nowrap">
            TPR Chart
          </div>
          <div className="w-full mt-5 flex justify-center ">
            <LineChart
              width={750}
              height={250}
              paddingTop="10px"
              data={lineChartList}
              cx="50%"
              cy="50%"
              margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeLinecap="3 3" />
              <XAxis
                dataKey="Time"
                tick={{ fontSize: 13, fontWeight: "normal" }}
                interval={0}
                padding={{ left: 50, right: 50 }}
              >
                {Array.isArray(props?.lineChartList) &&
                  props?.lineChartList.map((entry, index) => (
                    <Label
                      key={index}
                      offset={5}
                      position="top"
                      fontSize="13px"
                    />
                  ))}
              </XAxis>
              <YAxis
                label={{
                  value: "Temp",
                  angle: -90,
                  position: "insideLeft",
                  fontSize: "12px",
                  fontWeight: "15",
                }}
                tick={{ fontSize: 13, fontWeight: "normal" }}
                axisLine={{ strokeWidth: 1 }}
                tickLine={{ strokeWidth: 1 }}
              />
              <Legend />
              <Line
                dataKey="Temp"
                legendType="none"
                stroke="#243c5a"
                fill="#E34234"
                dot={{ r: 5 }}
                label={{
                  position: "top",
                  fontSize: 14,
                  margin: { top: 10, right: 30, left: 20, bottom: 40 },
                }}
              />
            </LineChart>
          </div>
          <div className="flex justify-center space-x-2">
            <div className="flex space-x-2 justify-center">
              <span className="text-[#E34234] font-semibold">Time</span>
            </div>
          </div>
        </table>
        <table className="w-full">
          <div className="flex justify-between gap-2 w-full">
            <div className="space-y-2  text-left justify-start font-normal">
              {Object.entries(patientInfo).map(([key, value]) => (
                <div key={key} className="flex items-center">
                  <p className="text-[11px] font-semibold w-[100px]">{key}</p>
                  <p className="font-semibold  text-[11px] mx-2">:</p>
                  <p className="text-[11px] whitespace-nowrap">{value}</p>
                </div>
              ))}
            </div>
            <div className="mr-8">
              <InamdarHospiLogo />
            </div>
          </div>

          <div className="flex  justify-end items-end gap-2 py-1">
            {Object.entries(patientInfoDate).map(([key, value]) => (
              <div key={key} className="flex items-center">
                <p className="text-[11px] font-semibold w-35">{key}</p>
                <p className="font-semibold text-[11px] mx-2">:</p>
                <p className="text-[11px] whitespace-nowrap">{value}</p>
              </div>
            ))}
          </div>

          <div className="font-semibold text-center border-t-[1px] border-black mt-1 text-[18px]  whitespace-nowrap">
            TPR Chart
          </div>
          <div className="flex justify-center">
            <ScatterChart
              width={750}
              height={250}
              paddingTop="10px"
              dot={{ r: 5 }}
              data={scatterChartList}
              cx="50%"
              cy="50%"
              margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeLinecap="round" />
              <XAxis
                dataKey="Time"
                tick={{ fontSize: 13, fontWeight: "normal" }}
                axisLine={{ strokeWidth: 1 }}
                tickLine={{ strokeWidth: 1 }}
              >
                {Array.isArray(scatterChartList) &&
                  scatterChartList.map((entry, index) => (
                    <Label
                      key={index}
                      offset={5}
                      position="top"
                      padding={{ top: 15, right: 30, left: 20, bottom: 40 }}
                    />
                  ))}
              </XAxis>
              <YAxis
                tick={{ fontSize: 13, fontWeight: "normal" }}
                axisLine={{ strokeWidth: 1 }}
                tickLine={{ strokeWidth: 1 }}
              />
              <Legend />

              <Scatter
                dataKey="LowerBp"
                stroke="#243c5a"
                fill="#0769fe"
                legendType="none"
                shape={<CustomizedShape data={scatterChartList.LowerBp} />}
              >
                <Label
                  type="number"
                  stroke="#243c5a"
                  sx={{ marginTop: 13 }}
                  dataKey="LowerBp"
                  label={{
                    position: "left",
                    padding: { top: 15, right: 30, left: 20, bottom: 40 },
                  }}
                />
              </Scatter>
              <Scatter
                dataKey="UpperBp"
                fill="#E34234"
                legendType="none"
                stroke="#243c5a"
                shape={<CustomizedShapeUpper data={scatterChartList.LowerBp} />}
              >
                <Label
                  type="number"
                  stroke="#243c5a"
                  dataKey="UpperBp"
                  label={{
                    position: "Top",
                    padding: { top: 15, right: 30, left: 20, bottom: 40 },
                  }}
                />
              </Scatter>
            </ScatterChart>
          </div>
          <div className="flex justify-center space-x-2">
            <div className="flex space-x-2 justify-center">
              <span className="text-red-500 font-semibold">Upper BP</span>
            </div>
            <div className="flex space-x-2 justify-center">
              <span className="text-blue-700 font-semibold">Lower BP</span>
            </div>
          </div>
        </table>
      </div>
    </div>
  );
};

export default VitalsTPRPrint;
