import authHeader from "../../../../../authentication/authservices/auth-header";
import apiClient from "../../../../../http-common";

export const addNewCertificate = (finalObj) => {
  return apiClient.post(`/certificate/saveCertificate`, finalObj, {
    headers: authHeader(),
  });
};

export const fetchCertificateList = (patientId, opdIpd) => {
  return apiClient.get(`/otConsent/otConsentList/${patientId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

export const fetchCertificateTemplate = () => {
  return apiClient.get(`/certificate/certificateTemplate`, {
    headers: authHeader(),
  });
};

export const getPreviousCertificateList = (opdIpdExternalId, menuId) => {
  return apiClient.get(
    `/certificate/previousCertificateList/${opdIpdExternalId}/${menuId}`,
    {
      headers: authHeader(),
    }
  );
};

////
//print

export const fetchOpdCertificatePrint = (certificateDtlsId) => {
  return apiClient.get(`/reports/opd/opdCertificate/${certificateDtlsId}`, {
    headers: authHeader(),
  });
};

// Swagger URL=/api/certificate/previousCertificateList/{opdIpdExternalId}

// For Akshada madam :-
// This service Bases On OpdIpdExternal Id,
// For Opd Patient you have to Pass visitId
// and for IPD Patient you have to pass AdmissionId...
