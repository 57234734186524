import { Checkbox, fabClasses } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { suctionSaturationPrintData } from "../services/cliinicalChartServices/succionsaturationServices/SuccsionSaturationServices";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";
const SuccioSaturationPrint = (props) => {
  //Saturation
  const [patientSaturationInfo, setPatientSaturationInfo] = useState({});
  const [patientInfoSaturationObj, setPatientInfoSaturationObj] = useState({});
  const [resultSaturation, setResultSaturation] = useState([]);
  const [patientInfoSaturationDate, setPatientInfoSaturationDate] = useState(
    {}
  );

  useEffect(() => {
    // suction Saturation
    const postObj = {
      admissionId: props.admissionId,
      fromDate: props.fromDate,
      toDate: props.toDate,
      fromTime: props.fromTime,
      toTime: props.toTime,
    };
    suctionSaturationPrintData(postObj)
      .then((response) => response.data.result)
      .then((res) => {
        if (res.suctionSaturationList.length === 0) {
          warningAlert("Please Select Valid Date And Time..!");
        } else {
          const obj = {
            "Mr No": res.MRNo,
            "Patient Name": res.PatientName,
            "Doctor Name": res.DoctorName,
            "Admission Date": res.AdmissionDate,
          };
          setPatientSaturationInfo(obj);
          const patientobj = {
            Age: res.AgeYear,
            Gender: res.Gender,
          };
          const patientInfoDate = {
            "From Date": res.FromDate,
            "To Date": res.ToDate,
          };
          setPatientInfoSaturationObj(patientobj);
          setPatientInfoSaturationDate(patientInfoDate);
          let updatedSuctionSaturationList = [];

          updatedSuctionSaturationList = res.suctionSaturationList?.map(
            (data) => {
              return {
                Date: data.Date,
                Time: data.Time,
                Oral: data.Oral || false,
                Tracheostomy: data.Tracheostomy || false,
                ETT: data.Ett || false,
                Nasal: data.Nasal || false,
                SaturationwithO2: data?.["Saturation Witho2"] || 0,
                Saturationwithouto2: data?.["Saturation Withouto2"] || 0,
              };
            }
          );

          setResultSaturation([...updatedSuctionSaturationList]);
        }
      });
  }, [props]);
  const printRef = useRef(null);

  useEffect(() => {
    resultSaturation.length > 0 && printRef && GeneratePrint(printRef);
  }, [resultSaturation]);

  const headers = [
    "Date",
    "Time",
    "Oral",
    "Tracheostomy",
    "ETT",
    "Nasal",
    "SaturationwithO2",
    "Saturationwithouto2",
  ];

  const editableColumns = ["Oral", "Tracheostomy", "ETT", "Nasal"];

  return (
    <div ref={printRef}>
      <div className="mt-2 ">
        <table className="w-full ">
          <thead
            style={{
              "-fs-table-paginate": "paginate",
            }}
          >
            <tr>
              <th colSpan={headers?.length + 1}>
                <div className="flex justify-between gap-5 w-full">
                  <div className="space-y-2  text-left justify-start font-normal">
                    {Object.entries(patientSaturationInfo)?.map(
                      ([key, value]) => (
                        <div key={key} className="flex items-center">
                          <p className="text-[11px] font-semibold w-[95px]">
                            {key}
                          </p>
                          <p className=" font-semibold text-[11px] mx-2">:</p>
                          <p className="text-[11px] whitespace-nowrap">
                            {value}
                          </p>
                        </div>
                      )
                    )}

                    <div className="grid font-normal  gap-2  -mt-5">
                      {Object.entries(patientInfoSaturationObj)?.map(
                        ([key, value]) => (
                          <div key={key} className="flex items-center">
                            <p className="text-[11px] font-semibold w-[95px]">
                              {key}
                            </p>
                            <p className=" font-semibold text-[11px] mx-2">:</p>
                            <p className="text-[11px] whitespace-nowrap">
                              {value}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="grid items-end">
                    <div className="justify-end flex ">
                      <InamdarHospiLogo />
                    </div>

                    <div className="flex justify-end">
                      {Object.entries(patientInfoSaturationDate)?.map(
                        ([key, value]) => (
                          <div key={key} className="flex items-center">
                            <p className="text-[11px] font-semibold ">{key}</p>
                            <p className=" font-semibold text-[11px] mx-2">:</p>
                            <p className="text-[11px] whitespace-nowrap">
                              {value}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full font-semibold text-center border-t-[1px] border-black mt-1 text-[18px]  whitespace-nowrap">
                  Saturation
                </div>
              </th>
            </tr>

            <tr className="border-b-[1px]   border-black  border-t-[1px] w-full ">
              {headers?.map((header, index) => (
                <th key={index} className="text-center py-1 gap-5">
                  <td className=" font-semibold flex items-center truncate gap-2 text-[11px] justify-center">
                    {header}
                  </td>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {resultSaturation?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className=" font-semibold text-[19px] text-center border-black"
              >
                {headers?.map((header, columnIndex) => (
                  <td
                    key={columnIndex}
                    className=" px-2   text-[10px] whitespace-nowrap"
                  >
                    {editableColumns &&
                    editableColumns.includes(header) &&
                    typeof row[header] === "boolean" ? (
                      <Checkbox
                        defaultChecked={row[header]}
                        color="default"
                        size="small"
                        sx={{ padding: 0 }}
                        disabled
                      />
                    ) : (
                      row[header]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuccioSaturationPrint;
