import React, { useEffect, useState } from "react";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { useForm } from "react-hook-form";
import {
  fetchGroups,
  fetchServiceList,
  fetchServices,
} from "../../services/billingServices/BillingServices";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { EditOnIcon } from "../../../assets/icons/CustomIcons";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";

const ServiceMasterListing = () => {
  let navigate = useNavigate();
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [Group, setGroup] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [servicesACData, setServicesACData] = useState([]);


  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  //
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      serviceSearch: "",
      group: "",
    },
  });
  const { control, watch, register } = methods;
  //
  const group_selected = watch("group");

  //

  useEffect(() => {
    getGroups();
  }, []);

  //Groups
  const getGroups = () => {
    fetchGroups().then((response) => {
      setGroup(response.data.result);
    });
  };

  useEffect(() => {
    getServiceAutoComplete();
  }, []);



  const getServiceAutoComplete = (serchString) => {
    serchString &&
      fetchServices(serchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  ////////table related

  useEffect(() => {
    populateTable();
  }, [group_selected, selectedService]);

  const populateTable = (forPagination) => {
    const servicelistObj = {
      serviceId: selectedService?.id || null,
      groupId: group_selected.id || null,
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    fetchServiceList(servicelistObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setUserActions(res.actions);
        setCount(res.count);
      });
  };

  const renderActions = (row) => {
    const handleEditService = () => {
      setSelectedRow(row);
      navigate("/master/billing/serviceForm", {
        state: {
          //  menuId: MenuId,
          row: row,
          isEdit: true,
        },
      });
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <EditOnIcon
            title={"Edit"}
            onClick={() => {
              handleEditService();
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="mt-14">
      <div className="text-center text-black font-bold text-xl ">
        Service Master
      </div>
      <div className=" py-2 grid grid-cols-6 gap-3 mt-3">
        <div className="md:col-span-2 lg:col-span-2 mb-3">
          <SearchDropdown
            control={control}
            placeholder="Search By Service Name"
            dataArray={autocompleteData}
            name="serviceSearch"
            searchIcon={true}
            isClearable={true}
            handleInputChange={getServiceAutoComplete}
            inputRef={{
              ...register("serviceSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedService(e.target.value);
                  } else {
                    setSelectedService("");
                  }
                },
              }),
            }}
          />
        </div>
        <div className="">
          <DropdownField
            control={control}
            name="group"
            placeholder="Group"
            dataArray={Group}
            isSearchable={false}
            //   inputRef={{
            //     ...register("group", {
            //       onChange: (e) => {
            //         getSubGroups();
            //       },
            //     }),
            //   }}
          />
        </div>
        <div className="col-span-3 flex justify-end">
          <CommonButton
            label="New Service"
            className="bg-customBlue text-white"
            onClick={() => {
              navigate("/master/billing/serviceForm", {
                state: {
                  isEdit: false,
                },
              });
            }}
          />
        </div>
      </div>
      <div>
        {dataResult.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            highlightRow={false}
            renderActions={renderActions}
            populateTable={populateTable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            count={count}
            tableClass="2xl:h-[650px]"
            removeHeaders={["id"]}
          />
        ) : (
          <p className="text-center my-6 ">No Data Found</p>
        )}
      </div>{" "}
    </div>
  );
};

export default ServiceMasterListing;
