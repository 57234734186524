import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllErrorTypes = () => {
  return apiClient.get("/medicationErrorReporting/errorTypes", {
    headers: authHeader(),
  });
};

export const fetchAllMedicationErrorList = async (medicErrorListObj) => {
  return await apiClient.post(
    `/medicationErrorReporting/medicationErrorList`,
    medicErrorListObj,
    {
      headers: authHeader(),
    }
  );
};

// /api/medicationErrorReporting/multiSelectErrors/{medErrorTypeId}
export const getAllMultiSelectErrors = (medErrorTypeId) => {
  return apiClient.get(
    `/medicationErrorReporting/multiSelectErrors/${medErrorTypeId}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveMedicationError = async (createMedicationError) => {
  return await apiClient.post(
    `medicationErrorReporting/saveMedicationError`,
    createMedicationError,
    {
      headers: authHeader(),
    }
  );
};
