import { Divider, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../../common/components/FormFields/InputField";

const NriDetails = () => {


  const {
    control,
    formState: { errors },
  } = useFormContext();


  return (
    <div className="grid grid-cols-11 gap-3">
      {/* /////////// passport details/////////////// */}
      <div className="grid col-span-5 grid-cols-2 gap-3">
        <div className="col-span-2  ">
          <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
            Passport Details
          </p>
        </div>

        <div className="w-full  ">
          <InputField
            name="passportNumber"
            variant="outlined"
            label="Passport Number "
            error={errors.passportNumber}
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className="w-full  ">
          <InputField
            name="placeOfIssuePassport"
            variant="outlined"
            label="Place Of Issue "
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        {/* //Issue Date */}
        <div className="">
          <DatePickerFieldNew
            control={control}
            name="DateOfIssuePassport"
            label="Date of Issue Passport"
            value={new Date()}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        {/* //valid till Date */}
        <div className="">
          <DatePickerFieldNew
            control={control}
            name="passportValidTill"
            label="Passport Valid Till"
            value={new Date()}
            disableFuture={false}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
      </div>
      <div className="col-span-1 grid justify-items-center">
        <Divider orientation="vertical" variant="middle" color="#FFFFFF" />
      </div>
      {/* /////////// visa details/////////////// */}
      <div className="grid col-span-5 grid-cols-2 gap-3">
        <div className="col-span-2  ">
          <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
            Visa Details
          </p>
        </div>
        <div className="w-full  ">
          <InputField
            name="visaNumber"
            variant="outlined"
            label="Visa Number "
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className="w-full  ">
          <InputField
            name="placeOfIssueVisa"
            variant="outlined"
            label="Place Of Issue "
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        {/* //Issue Date */}
        <div className="">
          <DatePickerFieldNew
            control={control}
            name="DateOfIssueVisa"
            label="Date Of Issue Visa"
            value={new Date()}
            disableFuture={false}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        {/* //valid till Date */}
        <div className="">
          <DatePickerFieldNew
            control={control}
            name="visaValidTill"
            label="Visa Valid Till"
            value={new Date()}
            disableFuture={false}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
      </div>
      <div className="col-span-5">
        <CheckBoxField
          control={control}
          name="pendingPassportDetails"
          label={
            <Typography
              variant="body3"
              sx={{ fontWeight: 700, color: " #676060" }}
            >
              Pending Passport Details
            </Typography>
          }
        />
      </div>
    </div>
  );
};

export default NriDetails;
