import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  fetchStore
} from "../../../../commonServices/miscellaneousServices/MiscServices";
import { postMrpAdjustment, printMRPAdjustmentReceipt } from "../../../services/generalStore/mrpAdjustment/MrpAdjustmentServices";
import { searchStockAdjustmentItem } from "../../../services/generalStore/stockAdjustmentServices/stockAdjustmentServices";
import { itemBatchNumber } from "../../../services/stockAdjustment/StockAdjustmentServices";
import BatchInfoModal from "./BatchInfoModal";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import {
  successAlert
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";

const actions = ["Edit"];
const Actions = [
  {
    id: 0,
    action: "Add",
    isAction: false,
  },
  {
    id: 1,
    action: "Save",
    isAction: false,
  },
];
function MrpAdjustment() {
  const schema = yup.object().shape({
    remarks: yup.string(),
  });
  const defaultValues = {
    itemCode: null,
    itemBatchNo: 0,
    newUnitMrp: 0,
    searchMrpAdjustmentItem: "",
    remarks: "",
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // state variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [storeDropdown, setStoreDropdown] = React.useState();
  const [searchString, setSearchString] = React.useState([]);
  const [openBatchInfoModal, setOpenBatchInfoModal] = React.useState(false);
  const [batchItemInfoObj, setBatchItemInfoObj] = React.useState(null);
  const [selectedBatchData, setSelectedBatchData] = React.useState([]);
  const [mrpSearchResult, setMrpSearchResult] = React.useState(null);
  const [itemName, setItemName] = React.useState();
  const [tempArr, setTempArr] = React.useState([]);
  const [itemId, setItemId] = React.useState([]);
  const [postObj, setPostObj] = React.useState([]);
  const [confirmationOpen, setOpenConfirmation] = React.useState(false);
  const [customSearchError, setCustomSearchError] = React.useState("");
  const [customBatchError, setCustomBatchError] = React.useState(false);
  const[openBackdrop,setOpenBackdrop]= React.useState(false);
  const [printUrl, setPrintUrl] = React.useState();
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState(null);


  // other variables
  let searchMrpAdjustmentItemVal = getValues("searchMrpAdjustmentItem");
  let itemBatchNoVal = getValues("itemBatchNo");
  let location = useLocation();
  console.log("locationlocation", location?.state?.menuId);
  let remarkVal = watch("remarks");
  console.log("remarkValremarkValremarkValremarkVal", remarkVal);
  console.log("");
  // useEffects
  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          console.log("getUserActions", res.result);
          setUserActions(res.result);
        });
  }, []);
console.log("privilegeprivilege",privilege);
  // sotre name api
  useEffect(() => {
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        console.log("storedropdownstoredropdown", res);
        setStoreDropdown(res.result);
      });
  }, []);
  // set login store name to droopdown
  useEffect(() => {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let storeId = userInfoObj.storeId;
    let storeIdValueLabelObj = {
      id: storeId,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };
    setValue("store", storeIdValueLabelObj);
  }, []);
  // set the value of selected batch data
  useEffect(() => {
    if (batchItemInfoObj !== null) {
      setMrpSearchResult(batchItemInfoObj);
      let itemName = getValues("searchMrpAdjustmentItem");
      setItemName(itemName);
      setValue("itemBatchNo", batchItemInfoObj.BatchCode);
      setValue("newUnitMrp", batchItemInfoObj.UnitMRP);
    }
  }, [batchItemInfoObj, setValue, getValues]);
  // functions
  const handleOpenBatchModal = () => {
    setOpenBatchInfoModal(true);
  };
  const handleCloseBatchModal = () => {
    setOpenBatchInfoModal(false);
  };

  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);


  // search service for item search
  const handleChange = (autoSearchString) => {
    console.log("handleChange has been invoked", autoSearchString);
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfo);
    console.log("userInfouserInfouserInfouserInfo", userInfoObj.storeId);
    let searchObj = {
      deptStoreId: userInfoObj.storeId,
      isConsignment: 1,
      itemCode: "%",
      itemName: "%",
      deptStore: 1,
      searchString: autoSearchString,
    };
    console.log("searchObjsearchObjsearchObjsearchObj" + searchObj);
    if(autoSearchString!==""&&UseCheckConsecutiveCharacters(autoSearchString))
    {
      
      searchStockAdjustmentItem(searchObj)
        .then((response) => response.data)
        .then((res) => {
          if (searchObj !== null) {
            console.log(
              "searchStockAdjustmentItemsearchStockAdjustmentItem",
              res
            );
            setSearchString(res.result);
          } else {
            console.log(
              "searchStockAdjustmentItemsearchStockAdjustmentItem",
              res
            );
            setSearchString([]);
          }
        });
    }
  };

  // add mrp adjustment to table
  function addUpdatedMrpAdjustmentToTable() {
    console.log(
      "searchMrpAdjustmentItemsearchMrpAdjustmentItem",
      searchMrpAdjustmentItemVal
    );
    if (
      searchMrpAdjustmentItemVal === null ||
      searchMrpAdjustmentItemVal === ""
    ) {
      setCustomSearchError("Search Item For MRP Adjustment *");
    }
    if (itemBatchNoVal === 0 || itemBatchNoVal === "") {
      setCustomBatchError("No Item For MRP Adjustment *");
    }
    let newUnitMrpVal = getValues("newUnitMrp");
    console.log("newUnitMrpValnewUnitMrpVal", newUnitMrpVal);
    let Obj = {};
    Obj.ItemName = itemId.label;
    Obj.batchCode = batchItemInfoObj.BatchCode;
    Obj.itemId = itemId?.id;
    Obj.newMrp = Number(newUnitMrpVal);
    Obj.oldMrp = batchItemInfoObj.UnitMRP;
    Obj.Remark = remarkVal;
    console.log(
      "addUpdatedMrpAdjustmentToTableaddUpdatedMrpAdjustmentToTable",
      Obj
    );
    if (
      (searchMrpAdjustmentItemVal === null ||
        searchMrpAdjustmentItemVal === "") &&
      (itemBatchNoVal === 0 || itemBatchNoVal === "")
    ) {
      setCustomSearchError("Search Item For MRP Adjustment");
      setCustomBatchError("No Item For MRP Adjustment");
    } else {
      let temp = [...tempArr];
      temp.push(Obj);
      setTempArr(temp);
      reset(defaultValues);
      setMrpSearchResult(null);
      setItemName();
      setItemId([]);
      setBatchItemInfoObj(null);
      let userInfoString = localStorage.getItem("userInfo");
      let userInfoObj = JSON.parse(userInfoString);
      let storeId = userInfoObj.storeId;
      let storeIdValueLabelObj = {
        id: storeId,
        value: userInfoObj.storeName,
        label: userInfoObj.storeName,
      };
      setValue("store", storeIdValueLabelObj);
    }
  }

  // post mrp adjustment in general store
  function saveMrpAdjustment() {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let postObj = {
      addedBy: userInfoObj.userId,
      isConsignment: true,
      mrpAdjustmentDetailsDto: tempArr,
      remarks: remarkVal,
      storeId: userInfoObj.storeId,
      menuId:location?.state?.menuId,
      privilege:"Create"
    };
    console.log("postObjpostObj", postObj);
    setPostObj(postObj);
    handleOpenConfirmation();
    setOpenBackdrop(true)
  }

  // add MRP Updatation records to MRP Adjusment table
  function addRecord() {
    handleCloseConfirmation()
    postMrpAdjustment(postObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleOpenPrintModal(res.result);
      
        setOpenBackdrop(false)
        setTempArr([]);
      })
      .catch((error) => {
        // errorAlert(error.message);
        setOpenBackdrop(false)
        console.log("errorAlerterrorAlert", error);

      });
  }

  // print mrp recepit
  // const handleOpenPrintModal = (mrpAdjustmentId) => {
  //   let url = `${baseUrl}/reports/inventory/mrpAdjustment/${mrpAdjustmentId}`;
  //   setPrintUrl(url);
  //   setOpenPrintModal(true);
  // };
  //prints print
    const [urlforPrint, setUrlforPrint] = React.useState();
    const [openPrintModal, setOpenPrintModal] = React.useState(false);
    const handleClosePrintModal = () => {
      setOpenPrintModal(false);
    };
    const handleOpenPrintModal = (mrpAdjustmentId) => {
     
  
   
      printMRPAdjustmentReceipt(mrpAdjustmentId).then((response) => {
        setUrlforPrint(response);
      });
      setOpenPrintModal(true);
     
    };

  function onSubmitDataHandler(data) {}
  
  // cursor Move in input field
  // function move tab in batch selection
  function findNextTabStop(el) {
    console.log("findNextTabStopfindNextTabStop",el);
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    
    return list[3]?.focus();
  }
        
  return (
    <div className="mt-12 space-y-2">
      <div className="py-2">
        <p className="text-center text-xl text-gray-700 font-Poppins">
          MRP Adjustment
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <>
      <div className="flex fles-wrap">
        <div className="w-[30%] mx-5">
          <DropdownField
            control={control}
            error={errors.store}
            name="store"
            placeholder="Store"
            dataArray={storeDropdown}
            isDisabled={true}
          />
        </div>
      </div>

      <div className="flex flex-col-reverse lg:flex-row lg:space-x-5 xl:space-x-3 w-full my-2">
        <div className="lg:w-[55%] xl:w-[70%] border rounded bg-white p-2  mt-2 lg:mt-0 lg:pb-0 lg:bg-transparent lg:border-none ">
        <div className="px-4 font-bold text-gray-700 font-Poppins font-">
              MRP Adjustment List
            </div>
          {tempArr && tempArr.length > 0 ? (
            <>
              {/* <NoApiCommonMasterTable data={tempArr} actions={actions} /> */}
              <CommonDynamicTable data={tempArr} requiredActions={false} tableClass={"rounded xl:h-[400px] lg:h-36   md:h-52"} />
              <div className="text-right"></div>
            </>
          ) : (
            <div className="text-center py-8">
              <p>No Record Found....</p>
            </div>
          )}
          <div className="text-right py-2">
            {tempArr.length > 0 && userActions &&
              userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <CommonButton
                      label="Save"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        saveMrpAdjustment();
                        setPrivilege(obj.action);
                      }}
                    />
                  ) : null}
                </>
              ))}
          </div>
        </div>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ color: "black", borderRightWidth: 2 }}
        />
        <div className="lg:w-[50%] xl:w-[35%] bg-white rounded border p-2">
          <div className="mt-4 col-span-3 lg:col-span-2 ">
            <SearchDropdown
              control={control}
              name="searchMrpAdjustmentItem"
              placeholder="Search By Item Name"
              dataArray={searchString}
              searchIcon={true}
              isClearable={true}
              isSearchable={true}
              handleInputChange={handleChange}
              inputRef={{
                ...register("searchMrpAdjustmentItem", {
                  onChange: (e) => {
                  
                    findNextTabStop(document.activeElement);
                    if (e.target.value !== null) {
                      setItemId(e.target.value);
                      setCustomSearchError("");
                    } else {
                      setSearchString([]);
                    }
                  },
                }),
              }}
            />
            {customSearchError !== "" ? (
              <>
                <span className="text-red-500  text-xs flex whitespace-nowrap ">
                  {customSearchError}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="grid grid-cols-2 text-sm lg:text-xs xl:text-sm font-semibold gap-3 py-4 ">
            <div className="flex space-x-7 lg:space-x-5 xl:space-x-7 lg:col-span-2 whitespace-nowrap">
              <h1>Item Name</h1>
              <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                : &nbsp;{itemId?.label}
              </label>
            </div>
            <div className="flex space-x-2 whitespace-nowrap">
              <h1>Curr.Batch.No</h1>
              <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                : &nbsp;{mrpSearchResult && mrpSearchResult.BatchCode}
              </label>
            </div>
            <div className="flex space-x-2 lg:space-x-2 xl:space-x-2 whitespace-nowrap">
              <h1 className="">Current Expiry</h1>
              <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                : &nbsp;{mrpSearchResult && mrpSearchResult.BatchExpDate}
              </label>
            </div>
            <div className="flex space-x-7 lg:space-x-6   whitespace-nowrap">
              <h1>Current Bal </h1>
              <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                : &nbsp;{mrpSearchResult && mrpSearchResult.BalQty}
              </label>
            </div>
            <div className="flex space-x-5  whitespace-nowrap">
              <h1 className="">Current MRP</h1>
              <label className="pl-[1.5px]  lg:pl-[3.3px] xl:pl-[0px] font-normal">
                : &nbsp; {mrpSearchResult && mrpSearchResult.UnitMRP}
              </label>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <TextField
                name="itemBatchNo"
                {...register("itemBatchNo")}
                size="small"
                // type="number"
                variant="outlined"
                // inputRef={itemKitRefrence}
                label="Batch No*"
                sx={{
                  "& .MuiInputBase-root": {
                    height: 35,
                  },
                }}
                error={errors.itemBatchNo}
                control={control}
                onKeyDown={(e) => {
                  console.log("on key press event", e);
                  if (e.key === "Enter") {
                    let getStoreVal = getValues("store");
                    let getItemVal = getValues("searchMrpAdjustmentItem");
                    console.log(
                      "getStoreValgetStoreValgetStoreValgetStoreVal",
                      getItemVal
                    );
                    setOpenBatchInfoModal(true);
                    setCustomBatchError("");
                    let currentDate = new Date();
                    let batchPostObj = {
                      batchExpDate: currentDate,
                      isConsignment: 0,
                      itemId: getItemVal.id,
                      storeId: 3,
                    };
                    console.log(
                      "batchPostObjbatchPostObjbatchPostObj",
                      batchPostObj
                    );
                    itemBatchNumber(batchPostObj).then((response) => {
                      console.log("selectedbatch no is", response.data.result);
                      setSelectedBatchData(response.data.result);
                    });
                  }
                }}
              />
              {customBatchError !== "" ? (
                <>
                  <span className="text-red-500  text-xs flex whitespace-nowrap ">
                    {customBatchError}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="">
              <InputField
                control={control}
                error={errors.adjQty}
                name="newUnitMrp"
                label="New Unit MRP *"
                type="number"
              />
            </div>
          </div>
          <div className="py-3">
            <TextField
              id="outlined-multiline-static"
              name="remarks"
              {...register("remarks")}
              label="Remarks"
              InputLabelProps={{ shrink: true }}
              multiline
              fullWidth
            />
          </div>
          <div className="mt-2 flex space-x-2 justify-end">
            {Actions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Add" ? (
                  <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset(defaultValues);
                      setCustomSearchError("");
                      setCustomBatchError("");
                      setMrpSearchResult(null);
                      setTempArr([]);
                      setItemName("");
                      let userInfoString = localStorage.getItem("userInfo");
                      let userInfoObj = JSON.parse(userInfoString);
                      let storeId = userInfoObj.storeId;
                      let storeIdValueLabelObj = {
                        id: storeId,
                        value: userInfoObj.storeName,
                        label: userInfoObj.storeName,
                      };
                      setValue("store", storeIdValueLabelObj);
                    }}
                  />
                ) : null}

                {!obj.isAction && obj.action === "Add" ? (
                  <CommonButton
                    label="Add"
                    className="bg-customGreen text-white"
                    onClick={() => {
                      addUpdatedMrpAdjustmentToTable();
                    }}
                  />
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>
      </>
      </form>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <BatchInfoModal
        handleOpen={handleOpenBatchModal}
        handleClose={handleCloseBatchModal}
        open={openBatchInfoModal}
        setOpen={setOpenBatchInfoModal}
        selectedBatchData={selectedBatchData}
        setBatchItemInfoObj={setBatchItemInfoObj}
        tableData={tempArr}
      />
      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleCloseConfirmation}
        confirmationSubmitFunc={() => {
          addRecord(postObj);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save MRP Adjustment ?"
        confirmationButtonMsg="Save"
      />
   {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
}

export default MrpAdjustment;
