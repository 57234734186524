
import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getAllPewsScore = (admissionId) => {
  return apiClient.get(`/clinicalCareChart/pewsScore/${admissionId}`,
  
  {
    headers: authHeader(),
  });
};

export const pewsScoreService = async (addNewPewsScore) => {
  return await apiClient.post(`/clinicalCareChart/savePewsScore`, addNewPewsScore,
  
  {
    headers: authHeader(),
  });
};

export const deletePews = (id, menuId, privilege) => {
  return apiClient.get(
    `clinicalCareChart/deletePews/${id}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};