import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  fetchDepartment,
  fetchEmoloyeeName,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { format, isValid } from "date-fns";
import {
  fetchIncidentCategory,
  fetchIncidentReportingDetails,
  saveEmployeeIncidentDetails,
  saveIncidentReport,
} from "../../services/incidentReportingServices/IncidentReportingServices";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import InputArea from "../../../common/components/FormFields/InputArea";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  minHeight: "44%",
  maxHeight: "95%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

// const validationSchema = yup.object().shape({
//   nameOfVictim: yup.string().required("Required"),
//   reportingPersonName: yup.string().required("Required"),
//   placeOfOccurrence: yup.string().required("Required"),
//   eventDescriptionDtls: yup.string().required("Required"),
// });

const incidentStatus = [
  {
    id: 0,
    label: "0 Forward to HR for action",
    value: "0 Forward to HR for action",
  },
  { id: 1, label: "Hold", value: "Hold" },
  { id: 2, label: "Incident not clear", value: "Incident not clear" },
  { id: 3, label: "Resolved", value: "Resolved" },
  {
    id: 4,
    label: "To meet and discuss with concerned",
    value: "To meet and discuss with concerned",
  },
  {
    id: 5,
    label: "Response pending from HOD",
    value: "Response pending from HOD",
  },
];

export default function ResponsibleDeptModal(props) {
  const {
    handleClose,
    setSelectedRow,
    selectedRow,
    patchCAPA,
    populateTable,
  } = props;
  const token = JSON.parse(localStorage.getItem("userInfo"));
  const [patchData, setPatchData] = useState(null);
  const [incidentCategoryArr, setIncidentCategoryArr] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  const [resDeptArr, setResDeptArr] = useState([]);

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      correctiveActions: "",
      preventiveActions: "",
      capaDate: new Date(),
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
    resetField,
  } = methods;

  useEffect(() => {
    getIncidentCategory();
  }, []);

  useEffect(() => {
    !!selectedRow?.IncidentId &&
      getIncidentReportingDetails(selectedRow.IncidentId);
  }, [selectedRow]);

  const getIncidentReportingDetails = (incidentId) => {
    fetchIncidentReportingDetails(incidentId)
      .then((response) => response.data)
      .then((res) => {
        setPatchData(res.result);
      });
  };

  const getIncidentCategory = () => {
    fetchIncidentCategory()
      .then((response) => response.data)
      .then((res) => {
        setIncidentCategoryArr(res.result);
      });
  };

  useEffect(() => {
    setValue("correctiveActions", patchCAPA?.ca);
    setValue("preventiveActions", patchCAPA?.ca);
    // setValue("correctiveActions", patchCAPA?.ca);
  }, [patchCAPA]);

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    // let incidentTimeValid = isValid(data.incidentTime);
    setOpenConfirmationModal(false);

    setOpenBackdrop(true);

    const finalObj = {
      ca: data.correctiveActions,
      capaReceivedDate: format(data.capaDate, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'"),
      employeeId: token?.empId,
      incidentId: selectedRow?.IncidentId || 0,
      pa: data.preventiveActions,
      departmentId: token.departmentId,
    };

    saveEmployeeIncidentDetails(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          setValue("incidentTime", new Date());
          setSelectedRow(null);
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Incident Details
            </div>

            <hr className="border mx-1  border-customBlue mb-2" />
            <div>
              <fieldset
                className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
              >
                <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Incident Created Dept.
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.EmployeeDepartment}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Incident Created Person
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.ReportPersonName}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Place Of Occurance
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.OccurencePlace}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Incident Date/Time
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.IncidentDateTime}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">Victim Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.VictimName}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">Description</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.EventDescription}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <form>
              <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-4 mt-3">
                {/* <div className="md:col-span-2 lg:col-span-5">
                  <SearchDropdown
                    control={control}
                    placeholder="Responsible Departments"
                    dataArray={resDeptArr}
                    name="responsibleDepts"
                    searchIcon={true}
                    isClearable={true}
                    handleInputChange={(e) => getResponsibleDepts(e)}
                    isMulti={true}
                    inputRef={{
                      ...register("responsibleDepts", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            //setSelectedPatient(e.target.value);
                          } else {
                            //setSelectedPatient("");
                            //methods.reset();
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="md:col-span-2 lg:col-span-6">
                  <InputField
                    name="reportingPersonName"
                    label="Reporting Person Name *"
                    variant="outlined"
                    error={errors.reportingPersonName}
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div> */}

                <div className="md:col-span-3 lg:col-span-6">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="correctiveActions"
                    label="Corrective Actions"
                    placeholder="Corrective Actions"
                    // disabled={patchCAPA !== null ? true : false}
                  />
                </div>
                <div className="md:col-span-3 lg:col-span-6">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="preventiveActions"
                    label="Preventive Actions"
                    placeholder="Preventive Actions"
                    // disabled={patchCAPA !== null ? true : false}
                  />
                </div>
                <div className="md:col-span-1 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="capaDate"
                    label="CAPA Received Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="md:col-span-2 lg:col-span-5 justify-end gap-3  flex ">
                  <CommonButton
                    label="Cancel"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      methods.reset();
                      setSelectedRow(null);
                      handleClose();
                    }}
                  />

                  <CommonButton
                    label="Save"
                    className="bg-customGreen text-white"
                    onClick={methods.handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
