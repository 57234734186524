import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import NoActionsTable from "../../../../common/components/CommonTable/NoActionsTable";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import {
  getCabinPatientDtlList,
  getCategoryPatientDtlList,
  selectCabin,
  selectCategory,
} from "../../../Radiology/Services/WorkOrderViewServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "90%",
  height: "70%",
  p: 3,
  overflowY: "scroll",
};

const RadiologyQueueModal = (props) => {
  const [cabinDropdown, setCabinDropdown] = useState();
  const [categoryDropdown, setCategoryDropdown] = useState();
  const [cabinDetailsList, setCabinDetailsList] = useState([]);
  const [categoryDetailsList, setCategoryDetailsList] = useState([]);
  const { openQueueModal, setOpenQueueModal, getUnit } = props;
  const { control, register, watch } = useForm();

  let getCabin = watch("cabin");
  let getCategory = watch("category");

  function getcabinDropdown() {
    selectCabin(getUnit?.id).then((response) => {
      setCabinDropdown(response.data.result);
    });
  }

  function getcategoryDropdown() {
    selectCategory().then((response) => {
      setCategoryDropdown(response.data.result);
    });
  }

  function getCabinDtlsList() {
    getCabinPatientDtlList(getCabin ? getCabin?.label : 0).then((response) => {
      setCabinDetailsList(response.data);
    });
  }

  function getCategoryDtlsList() {
    getCategoryPatientDtlList(getCategory ? getCategory?.id : 0).then(
      (response) => {
        setCategoryDetailsList(response.data);
      }
    );
  }

  useEffect(() => {
    if (openQueueModal === true) {
      getcabinDropdown();
      getcategoryDropdown();
      getCabinDtlsList();
      getCategoryDtlsList();
    }
  }, [openQueueModal, getCabin, getCategory]);

  return (
    <>
      <Modal open={openQueueModal}>
        <Box sx={ModalStyle}>
          <div>
            <CancelPresentationIconButton
              onClick={() => {
                setOpenQueueModal(false);
              }}
            />
          </div>
          <div className="space-y-2 mt-3">
            <div className="w-80">
              <DropdownField
                control={control}
                name="cabin"
                placeholder="Select Cabin"
                // handleInputChange={handleChange}
                dataArray={cabinDropdown}
                isClearable={true}
              />
            </div>
            <div>
              {cabinDetailsList.hasOwnProperty("result") &&
              cabinDetailsList.result.length > 0 &&
              cabinDetailsList.statusCode === 200 ? (
                <NoActionsTable data={cabinDetailsList} />
              ) : (
                <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded my-2">
                  No Records Found...
                </div>
              )}
            </div>
            <div className="w-80">
              <DropdownField
                control={control}
                name="category"
                placeholder="Select Category"
                // handleInputChange={handleChange}
                dataArray={categoryDropdown}
                isClearable={true}
              />
            </div>
            <div>
              {categoryDetailsList.hasOwnProperty("result") &&
              categoryDetailsList.result.length > 0 &&
              categoryDetailsList.statusCode === 200 ? (
                <NoActionsTable data={categoryDetailsList} />
              ) : (
                <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded my-2">
                  No Records Found...
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RadiologyQueueModal;
