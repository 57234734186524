import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Divider, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EyeOnIcon } from "../../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  validationAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  deleteAdmissionDocument,
  fetchDcumentType,
  fetchDocuments,
  fetchEmergencyNumber,
  fetchPatchDataNew,
  saveAdmissionFormData,
} from "../../../services/admissionServices/AdmissionServices";
import DocumentsViewModal from "../commonModal/DocumentsViewModal";

const IndividualAdmitting = [
  {
    id: 1,
    value: "Self",
    label: "Self",
  },
  {
    id: 2,
    value: "Relative",
    label: "Relative",
  },
  {
    id: 3,
    value: "Other",
    label: "Other",
  },
];

const Relative = [
  {
    id: 1,
    value: "Blood",
    label: "Blood",
  },
  {
    id: 2,
    value: "Marrital",
    label: "Marrital",
  },
  {
    id: 3,
    value: "Friends",
    label: "Friends",
  },
  {
    id: 4,
    value: "Other",
    label: "Other",
  },
];

const ModeOfPayment = [
  {
    id: 1,
    value: "Mediclaim",
    label: "Mediclaim",
  },
  {
    id: 2,
    value: "Cash",
    label: "Cash",
  },
  {
    id: 3,
    value: "Debit/Credit Card",
    label: "Debit/Credit Card",
  },
  {
    id: 4,
    value: "Corporate",
    label: "Corporate",
  },
  {
    id: 5,
    value: "Other",
    label: "Other",
  },
];

const EditAdmissionModal = (props) => {
  const { menuId, privilege, selectedRow, handleClose } = props;
  const [emergencyNumber, setEmergencyNumber] = useState();
  const [patchData, setPatchData] = useState(null);
  const [docRowIndex, setDocRowIndex] = useState(null);

  //

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [allFormDetails, setAllFormDetails] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  //
  const [documentsType, setDocumentsType] = useState();
  //
  const [pathForDocView, setPathForDocView] = useState();
  //
  const [openDocumentsViewModal, setOpenDocumentsViewModal] = React.useState(
    false
  );
  const handleOpenDocumentsViewModal = () => setOpenDocumentsViewModal(true);
  const handleCloseDocumentsViewModal = () => setOpenDocumentsViewModal(false);

  //
  const [patientdocs, setPatientdocs] = useState([]);
  const [relativedocs, setRelativedocs] = useState([]);
  //

  const [
    openlDeleteDocConfirmationModal,
    setOpenlDeleteDocConfirmationModal,
  ] = useState(false);
  //

  const {
    formState: { errors },
    getValues,
    setError,
    resetField,
    control,
    register,
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      PatientDocumentsType: null,
      PatientDocumentsFileData: "",

      relativeDocumentsType: null,
      relativeDocumentsFileData: "",

      pendingDocuments: false,
      individualAdmitting: 0,
      relative: 0,
      modeOfPayment: 0,
    },
  });

  useEffect(() => {
    selectedRow && setValue("mediClaimAmount", selectedRow?.Mediclaim_Amount);
  }, [selectedRow]);

  useEffect(() => {
    getDcumentType();
    getOldDocuments();
    getEmergencyNumber();
    getSearchedData();
  }, []);

  //API For  emergency number

  const getSearchedData = () => {
    fetchPatchDataNew(selectedRow?.PatientId, 0, true)
      .then((response) => response.data)
      .then((res) => {
        setPatchData(res.result);

        if (res.result.PendingDocuments !== null) {
          setValue(
            "pendingDocuments",
            res.result.PendingDocuments === 0 ? false : true
          );
        }

        setValue("individualAdmitting", res.result.ind_adm_patient);
        setValue("relative", res.result.patient_relative);
        setValue("modeOfPayment", res.result.modeofpayment);

        //

        if (
          res.result.EmergencyPatientId &&
          res.result.EmergencyPatientId !== null &&
          res.result.label &&
          res.result.label !== null
        ) {
          setValue(
            "SelectEmergencyNo",
            {
              id: res.result.EmergencyPatientId,
              label: res.result.label,
              value: res.result.label,
            },
            { shouldValidate: true }
          );
        }
      })
      .catch((res) => {
        warningAlert(res?.response?.data?.message || res?.message);
      });
  };

  const getEmergencyNumber = () => {
    fetchEmergencyNumber().then((response) => {
      setEmergencyNumber(response.data.result);
    });
  };

  const getDcumentType = () => {
    fetchDcumentType()
      .then((response) => response.data)
      .then((res) => {
        setDocumentsType(res.result);
      });
  };

  const getOldDocuments = () => {
    fetchDocuments(selectedRow?.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setPatientdocs(res.result.fetchPatientDocDtoList);
        setRelativedocs(res.result.fetchRelDocDtoList);
      });
  };

  //patient Documents
  const handleAddPatientDocuments = () => {
    let DocType = getValues("PatientDocumentsType");
    let DocData = getValues("PatientDocumentsFileData");

    if (DocType === null) {
      setError("PatientDocumentsType", { type: "custom", message: "Required" });
      setError("PatientDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    } else if (DocType === null) {
      setError("PatientDocumentsType", { type: "custom", message: "Required" });
    } else if (DocData === "") {
      setError("PatientDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    }

    let serviceObj = {
      id: DocType.id,
      documentType: DocType.label,
      Doc: DocData,
      DocName: DocData[0].name,
      Docfile: DocData[0],
    };

    let arr = [...patientdocs];
    arr.push(serviceObj);
    setPatientdocs(arr);

    resetField("PatientDocumentsType");
    resetField("PatientDocumentsFileData");
  };
  //Relative Documents
  const handleAddRelativeDocuments = () => {
    let DocType = getValues("relativeDocumentsType"); //relativedocs, setRelativedocs
    let DocData = getValues("relativeDocumentsFileData");

    if (DocType === null) {
      setError("relativeDocumentsType", {
        type: "custom",
        message: "Required",
      });
      setError("relativeDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    } else if (DocType === null) {
      setError("relativeDocumentsType", {
        type: "custom",
        message: "Required",
      });
    } else if (DocData === "") {
      setError("relativeDocumentsFileData", {
        type: "custom",
        message: "Required",
      });
    }

    let serviceObj = {
      id: DocType.id,
      documentType: DocType.label,
      Doc: DocData,
      DocName: DocData[0].name,
      Docfile: DocData[0],
    };

    let arr = [...relativedocs];
    arr.push(serviceObj);
    setRelativedocs(arr);

    resetField("relativeDocumentsType");
    resetField("relativeDocumentsFileData");
  };

  //
  const handleDeleteDoc = (docRow) => {
    if (docRow) {
      deleteAdmissionDocument(
        selectedRow?.AdmissionId,
        docRow.docId,
        docRow.type
      )
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          setOpenlDeleteDocConfirmationModal(false);
          if (docRow.type === "Patient") {
            let newTemplateData = [...patientdocs];
            newTemplateData.splice(docRow.index, 1);
            setPatientdocs(newTemplateData);
          } else if (docRow.type === "Relative") {
            let newTemplateData = [...relativedocs];
            newTemplateData.splice(docRow.index, 1);
            setRelativedocs(newTemplateData);
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          setOpenlDeleteDocConfirmationModal(false);
        });
    }
  };

  ////////table related
  const renderActionsPatient = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...patientdocs];
      newTemplateData.splice(index, 1);
      setPatientdocs(newTemplateData);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="text-red-500">
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                if (row.documentPath) {
                  setDocRowIndex({ ...row, index, type: "Patient" });
                  setOpenlDeleteDocConfirmationModal(true);
                } else {
                  deleteRow(index);
                }
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const renderInputPatient = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.documentPath);
      handleOpenDocumentsViewModal(true);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          {row.documentPath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  const renderActionsRelative = (row, index) => {
    const deleteRow = (index) => {
      let newTemplateData = [...relativedocs];
      newTemplateData.splice(index, 1);
      setRelativedocs(newTemplateData);
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          <span className="text-red-500">
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                if (row.documentPath) {
                  setDocRowIndex({ ...row, index, type: "Relative" });
                  setOpenlDeleteDocConfirmationModal(true);
                } else {
                  deleteRow(index);
                }
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const renderInputRelative = (row, index) => {
    const HandleDocmentsView = (row, index) => {
      setPathForDocView(row.documentPath);
      handleOpenDocumentsViewModal(true);
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          {row.documentPath ? (
            <EyeOnIcon
              title="View Documents"
              onClick={() => {
                HandleDocmentsView(row, index);
              }}
            />
          ) : (
            row["DocName"]
          )}
        </div>
      </>
    );
  };

  const onSubmit = (data) => {
    //for patient document cart -- base64 string insersion

    for (let i = 0; i < patientdocs.length; i++) {
      let fileObj = patientdocs[i].Docfile;

      if (fileObj !== null && fileObj !== undefined) {
        let baseSixtyFourString;
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
          baseSixtyFourString = reader.result;
          patientdocs[i].baseSixtyFourString = baseSixtyFourString;
        };

        // }
      }
    }

    //for relative document cart -- base64 string insersion

    for (let i = 0; i < relativedocs.length; i++) {
      let fileObj = relativedocs[i].Docfile;

      let file;
      if ((fileObj !== null && fileObj) !== undefined) {
        let baseSixtyFourString;
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
          baseSixtyFourString = reader.result;
          relativedocs[i].baseSixtyFourString = baseSixtyFourString;
        };
      }
    }

    setOpenConfirmationModal(true);
    setAllFormDetails(data);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);

    const finalObject = {};

    let pendingDocument;
    if (data?.pendingDocuments === true) {
      pendingDocument = 1;
    } else if (data?.pendingDocuments === false) {
      pendingDocument = 0;
    }
    finalObject.patientId = Number(selectedRow?.PatientId);

    finalObject.admissionId = Number(selectedRow?.AdmissionId);

    finalObject.emergencyId =
      data.SelectEmergencyNo !== null && data.SelectEmergencyNo !== undefined
        ? Number(data.SelectEmergencyNo.id)
        : Number(0);

    //document Page
    finalObject.documentId =
      patchData !== null && patchData.documentId !== null
        ? patchData.documentId
        : 0;
    finalObject.individualAdmPt = Number(data.individualAdmitting);
    finalObject.relativeSpecific = Number(data.relative);
    finalObject.pendingDocuments = pendingDocument;
    finalObject.modeOfPayment = Number(data.modeOfPayment);
    //patientDoc arr
    var patientDocArray = [];
    for (let i = 0; i < patientdocs.length; i++) {
      let docId =
        patientdocs[i].id !== null && patientdocs[i].id !== undefined
          ? patientdocs[i].id //Available @ new Doc
          : patientdocs[i].docId; //Available @ Old Doc
      //
      let documentName = patientdocs[i].DocName; //Available @ new Doc
      //
      let arr =
        patientdocs[i].baseSixtyFourString !== undefined
          ? patientdocs[i].baseSixtyFourString.split(",")
          : [];
      let documentBase64 = arr[1]; //Available @ new Doc
      //
      let documentPath =
        patientdocs[i].documentPath !== undefined
          ? patientdocs[i].documentPath //Available @ Old Doc
          : null;

      patientDocArray.push({
        docId,
        documentBase64,
        documentName,
        documentPath,
      });
      data.PatientDocumentCart !== null
        ? (finalObject.patientDocumentsDtoList = patientDocArray)
        : (finalObject.patientDocumentsDtoList = null);
    }

    //RelativeDoc arr
    var relativeDocArray = [];
    for (let i = 0; i < relativedocs.length; i++) {
      let relDocId =
        relativedocs[i].id !== null && relativedocs[i].id !== undefined
          ? relativedocs[i].id
          : relativedocs[i].docId;
      //
      let relDocumentName = relativedocs[i].DocName;
      //
      let arr =
        relativedocs[i].baseSixtyFourString !== undefined
          ? relativedocs[i].baseSixtyFourString.split(",")
          : [];
      let relDocumentBase64 = arr[1];
      //
      let documentPath =
        relativedocs[i].documentPath !== undefined
          ? relativedocs[i].documentPath //Available @ Old Doc
          : null;

      relativeDocArray.push({
        relDocId,
        relDocumentBase64,
        relDocumentName,
        documentPath,
      });
      data.PatientDocumentCart !== null
        ? (finalObject.relativeDocumentDto = relativeDocArray)
        : (finalObject.relativeDocumentDto = null);
    }

    finalObject.menuId = menuId;
    finalObject.privilege = privilege;

    setOpenBackdrop(true);

    saveAdmissionFormData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          reset();
          setOpenBackdrop(false);
          handleClose();
        }
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());
      });
  };

  return (
    <div>
      {/* /// modal */}
      <Modal open={props.open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "85%", lg: "75%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            overflowY: "auto",
            height: "90%",
          }}
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
            }}
          />
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Edit Admission Details
            </div>

            <hr className="border mx-1  border-blue-900" mb-3 />
            <div className="grid w-full ">
              {/* Populate Patient Info Fields */}
              <div className="grid border border-gray-300 bg-gray-100 px-3 rounded-md mt-2">
                <div className="grid grid-cols-2  gap-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                    {/* Patient Name , UHID */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-1">
                        <span className="text-sm">Patient Name </span>
                        <span className=""> :</span>
                      </h1>

                      <h1 className="font-normal">
                        {selectedRow ? selectedRow["Patient Name"] : null}
                      </h1>
                    </div>
                    {/* Gender */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                        <span className="text-sm">Gender</span>
                        <span className="lg:pl-7 md:pl-6">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {patchData ? patchData.gender : null}
                      </h1>
                    </div>
                  </div>

                  {/* MRNo */}
                  <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">MRNo</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {patchData ? patchData.MRNo : null}
                      </h1>
                    </div>
                    {/* age */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">Age</span>
                        <span className="md:pl-4">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {patchData ? patchData.ageInYears : null}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form>
              <div className="my-3 p-2">
                <>
                  <div className="grid grid-cols-3 mb-3">
                    {/* Emergency Documents */}
                    <div className=" ">
                      <DropdownField
                        control={control}
                        name="SelectEmergencyNo"
                        label="Emergency"
                        dataArray={emergencyNumber}
                        isSearchable={false}
                        isDisabled={false}
                        isClearable={false}
                        placeholder="Emergency *"
                        inputRef={{
                          ...register("SelectEmergencyNo", {
                            // onChange: (e) => {
                            //   setCasualtyDate(parseISO(e.target.value.Date));
                            //   let time = parseISO(e.target.value.Time);
                            //   setCasualtyTime(dayjs(time));
                            // },
                          }),
                        }}
                      />
                    </div>
                  </div>

                  {/* Patient Documents */}
                  <div>
                    <p className="font-bold tracking-wide text-sm mb-3  font-Poppins">
                      Patient Documents
                    </p>
                    <div className="grid grid-cols-12 gap-3 items-center ">
                      <div className="col-span-4 lg:col-span-3">
                        <DropdownField
                          control={control}
                          label="Document Type"
                          dataArray={documentsType}
                          isSearchable={false}
                          isClearable={false}
                          placeholder="Document Type"
                          name="PatientDocumentsType"
                          error={errors.PatientDocumentsType}
                        />
                      </div>

                      <div className="col-span-7 lg:col-span-3 ">
                        <input
                          type="file"
                          {...register(`PatientDocumentsFileData`)}
                        />
                      </div>

                      <div className="justify-end">
                        <Tooltip title="Add">
                          <AddCircleIcon
                            sx={{ color: "#1e3a8a" }}
                            onClick={() => {
                              handleAddPatientDocuments();
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    {patientdocs.length > 0 ? (
                      <div className="w-full mt-2">
                        {/* <DocumentsTable data={patientdocs} setState={setPatientdocs} /> */}
                        <CommonDynamicTableNew
                          dataResult={patientdocs}
                          renderActions={renderActionsPatient}
                          removeHeaders={["Docfile", "id", "Doc", "docId"]}
                          editableColumns={["documentPath", "DocName"]}
                          renderInput={renderInputPatient}
                          tableClass="rounded h-36"
                          highlightRow={false}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/* Relative Documents */}
                  <div>
                    <p className="font-bold tracking-wide text-sm mb-3 font-Poppins">
                      Relative Documents
                    </p>
                    <div className="grid grid-cols-12 gap-3 items-center ">
                      <div className="col-span-4 lg:col-span-3">
                        <DropdownField
                          control={control}
                          label="Document Type"
                          dataArray={documentsType}
                          isSearchable={false}
                          isClearable={false}
                          placeholder="Document Type"
                          name="relativeDocumentsType"
                          error={errors.relativeDocumentsType}
                        />
                      </div>

                      <div className="col-span-7 lg:col-span-3 ">
                        <input
                          type="file"
                          {...register(`relativeDocumentsFileData`)}
                        />
                      </div>

                      <div className="justify-end">
                        <Tooltip title="Add">
                          <AddCircleIcon
                            sx={{ color: "#1e3a8a" }}
                            onClick={() => {
                              handleAddRelativeDocuments();
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    {relativedocs.length > 0 ? (
                      <div className="w-full mt-2">
                        {/* <DocumentsTable data={relativedocs} setState={setRelativedocs} /> */}
                        <CommonDynamicTableNew
                          dataResult={relativedocs}
                          renderActions={renderActionsRelative}
                          removeHeaders={["Docfile", "id", "Doc", "docId"]}
                          editableColumns={["documentPath", "DocName"]}
                          renderInput={renderInputRelative}
                          tableClass="rounded h-36"
                          highlightRow={false}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/* panding Documents */}
                  <div className="">
                    <CheckBoxField
                      control={control}
                      name="pendingDocuments"
                      label={
                        <Typography
                          variant="body3"
                          sx={{ fontWeight: 700, color: " #676060" }}
                        >
                          Pending Documents
                        </Typography>
                      }
                    />
                  </div>
                  {/* checkboxes */}
                  <div className="font">
                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      color="#FFFFFF"
                    />
                  </div>
                  {/* <div className="mt-3 gap-y-6 "> */}
                  <div className="flex  gap-2 w-full col-span-2 xl:col-span-1 font-semibold my-3">
                    <span className=" font-semibold w-44">
                      Individual Admitting
                    </span>
                    <div className="flex space-x-3 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal ">
                        <RadioField
                          label=""
                          name="individualAdmitting"
                          control={control}
                          dataArray={IndividualAdmitting}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="flex  gap-2 w-full col-span-2 xl:col-span-1 font-semibold my-3">
                    <span className=" font-semibold w-44">Relative</span>
                    <div className="flex space-x-3 ">
                      <span>:</span>
                      <span className="text-gray-700 font-normal ">
                        <RadioField
                          label=""
                          name="relative"
                          control={control}
                          dataArray={Relative}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2 w-full col-span-2 xl:col-span-1 font-semibold my-3">
                    <span className=" font-semibold w-44">Mode Of Payment</span>
                    <div className="flex space-x-3 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal ">
                        <RadioField
                          label=""
                          name="modeOfPayment"
                          control={control}
                          dataArray={ModeOfPayment}
                        />
                      </span>
                    </div>
                  </div>

                  {/* Documents view modal */}
                  {openDocumentsViewModal ? (
                    <DocumentsViewModal
                      open={openDocumentsViewModal}
                      setOpen={setOpenDocumentsViewModal}
                      handleOpen={handleOpenDocumentsViewModal}
                      handleClose={handleCloseDocumentsViewModal}
                      pathForDocView={pathForDocView}
                    />
                  ) : null}
                </>
              </div>
              <div className="w-full flex justify-end gap-3">
                <CommonButton
                  label="Cancel"
                  onClick={() => {
                    props.handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  //type="submit"
                  label="Save"
                  className="bg-customGreen text-white"
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={() => handleFormSubmit(allFormDetails)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {/* /// modal */}
      <Modal open={openlDeleteDocConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Confirmation
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To Delete Document ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setOpenlDeleteDocConfirmationModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      handleDeleteDoc(docRowIndex);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditAdmissionModal;
