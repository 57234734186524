import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getPrinterOptions,
  getSavedPrinterSettings,
  savePrinterSettings,
} from "../../services/printerSettingsServices/PrinterSettingsServices";

export default function PrinterSettings() {
  const defaults = JSON.parse(localStorage.getItem("defaultPrinters"));
  const defaultValues = {
    defaultPrinter: null,
    barcodePrinter: null,
    printerPaperSize: "A4",
    noOfPages: "",
    barcodePaperSize: {
      id: 1,
      label: "barcode 55x25",
      value: "barcode 55x25",
    },
    barcodeNoOfPages: "",
  };
  const schema = yup.object().shape({
    defaultPrinter: yup.object().required(),
    barcodePrinter: yup.object().required(),
  });
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const [printerOptions, setPrinterOptions] = React.useState([
    { id: 1, label: "HP_DeskJet_5820_series_F98AFB_USB", value: 1 },
    { id: 2, label: "HP_Barcode_5820_series_F98AFB_USB", value: 2 },
  ]);

  const [barcodeSize, setBarcodeSize] = React.useState([
    // {
    //   id: 1,
    //   label: "barcode",
    //   value: "barcode",
    // },
    {
      id: 1,
      label: "barcode 55x25",
      value: "barcode 55x25",
    },
    {
      id: 1,
      label: "barcode 33x15",
      value: "barcode 33x15",
    },
  ]);
  const [openChild, setOpenChild] = React.useState(false);
  const [defaultPrinterObject, setDefaultPrinterObject] = React.useState(null);
  const [openReset, setOpenReset] = React.useState(false);
  const [printerSettings, setPrinterSettings] = React.useState([]);

  const handleCloseChild = () => {
    if (openChild) {
      setOpenChild(false);
    }
  };

  const handleCloseReset = () => {
    if (openReset) {
      setOpenReset(false);
    }
  };

  const getSavedPrinters = () => {
    getSavedPrinterSettings()
      .then((response) => response.data)
      .then((res) => {
        let printers = res.result;
        if (printers?.length > 0) {
          for (let printer of printers) {
            console.log("Barcode :: ", printer);
            if (
              // printer?.pageSize?.toLowerCase() === "barcode" ||
              printer?.pageSize?.toLowerCase() === "barcode 55x25" ||
              printer?.pageSize?.toLowerCase() === "barcode 33x15"
            ) {
              console.log("Barcode ::1 ", printer);

              setValue("barcodePaperSize", {
                id: 1,
                label: printer?.pageSize,
                value: printer?.pageSize,
              });

              setValue("barcodePrinter", {
                id: printer.id,
                label: printer?.printerName,
                value: printer.id,
              });
              setValue("barcodeNoOfPages", printer?.numberOfPages);
            } else {
              console.log("Barcode ::2 ", printer);

              setValue("printerPaperSize", printer?.pageSize);
              setValue("defaultPrinter", {
                id: printer.id,
                label: printer?.printerName,
                value: printer.id,
              });
              setValue("noOfPages", printer?.numberOfPages);
            }
          }

          let printerObject = printers.find(
            (printer) =>
              printer?.pageSize?.toLowerCase() === "a4" ||
              printer?.pageSize?.toLowerCase() === "a5"
          );

          let barcodePrinterObject = printers.find(
            (printer) =>
              // printer?.pageSize?.toLowerCase() === "barcode" ||
              printer?.pageSize?.toLowerCase() === "barcode 55x25" ||
              printer?.pageSize?.toLowerCase() === "barcode 33x15"
          );

          let defaultObject = {
            defaultPrinter: printerObject?.printerName,
            barCodePrinter: barcodePrinterObject?.printerName,
            printPages: printerObject?.numberOfPages,
            printSize: printerObject?.pageSize,
            barcodePages: barcodePrinterObject?.numberOfPages,
            barcodeSize: barcodePrinterObject?.pageSize,
          };

          localStorage.setItem(
            "defaultPrinters",
            JSON.stringify(defaultObject)
          );
        }
      });
  };

  React.useEffect(() => {
    getSavedPrinters();
  }, []);

  React.useEffect(() => {
    getPrinterOptions()
      .then((response) => response.data)
      .then((res) => {
        let printers = [];
        if (res.result?.length > 0) {
          for (let i = 0; i <= res.result.length; i++) {
            if (res.result[i] !== undefined && res.result[i] !== "") {
              let printerObject = {
                id: i + 1,
                label: res.result[i],
                value: i + 1,
              };
              printers.push(printerObject);
              setPrinterOptions(printers);
            }
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching printer options:", err);
      });
  }, []);

  React.useEffect(() => {
    if (printerOptions?.length > 0) {
      let defaultPrinter = printerOptions.find(
        (printer) => printer.label === defaults?.defaultPrinter
      );
      let defaultBarCodePrinter = printerOptions.find(
        (printer) => printer.label === defaults?.barCodePrinter
      );
      if (defaultPrinter?.label) {
        setValue("defaultPrinter", defaultPrinter);
      }
      setValue("printerPaperSize", defaults?.printSize);
      setValue("noOfPages", defaults?.printPages);
      setValue("barcodePaperSize", {
        id: 1,
        label: defaults?.barcodeSize,
        value: defaults?.barcodeSize,
      });

      setValue("barcodeNoOfPages", defaults?.barcodePages);
      if (defaultBarCodePrinter?.label) {
        setValue("barcodePrinter", defaultBarCodePrinter);
      }
    }
  }, [printerOptions]);

  const onSubmitDataHandler = (data) => {
    let defaultObject = {
      defaultPrinter: data.defaultPrinter?.label,
      barCodePrinter: data.barcodePrinter?.label,
      printSize: data?.printerPaperSize,
      printPages: data?.noOfPages,
      barcodePages: data?.barcodeNoOfPages,
      barcodeSize: data?.barcodePaperSize?.label,
    };

    // defaultPrinter: printerObject?.printerName,
    //         barCodePrinter: barcodePrinterObject?.printerName,
    //         printPages: printerObject?.numberOfPages,
    //         printSize: printerObject?.pageSize,
    //         barcodePages: barcodePrinterObject?.numberOfPages,
    //         barcodeSize: barcodePrinterObject?.pageSize,

    let postArray = [
      {
        printerName: data.defaultPrinter?.label,
        numberCopies: Number(data?.noOfPages),
        pageSize: data?.printerPaperSize,
      },
      {
        printerName: data.barcodePrinter?.label,
        numberCopies: Number(data?.barcodeNoOfPages),
        pageSize: data?.barcodePaperSize?.label,
      },
    ];

    setPrinterSettings(postArray);

    setDefaultPrinterObject(defaultObject);
    setOpenChild(true);
  };

  const savePrinters = () => {
    if (defaultPrinterObject !== null) {
      localStorage.removeItem("defaultPrinters");
      localStorage.setItem(
        "defaultPrinters",
        JSON.stringify(defaultPrinterObject)
      );
      savePrinterSettings(printerSettings)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
        })
        .catch((err) => {
          errorAlert(err.message);
        });
      handleCloseChild();
      // window?.location?.reload(false);
    }
  };

  const handleResetPrinters = () => {
    localStorage.removeItem("defaultPrinters");
    reset();
    handleCloseReset();
    let postArray = [];
    savePrinterSettings(postArray)
      .then((response) => response.data)
      .then((res) => {
        successAlert("Default printers removed successfully..!");
        getSavedPrinters();
      })
      .catch((err) => {
        errorAlert(err.message);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitDataHandler)}
      className="mt-20 flex justify-center  "
    >
      <fieldset className="border border-gray-400 rounded-lg w-[30%]  px-5 bg-white">
        <legend className="text-lg font-semibold tracking-wide px-2 ml-5">
          Printer Settings
        </legend>
        <div className="my-2">
          <div className="my-2">
            <DropdownField
              control={control}
              name="defaultPrinter"
              placeholder="Default Printer *"
              error={errors.defaultPrinter}
              dataArray={printerOptions}
            />
            <div className="flex gap-2 items-center my-4">
              <InputField
                control={control}
                name="printerPaperSize"
                label={"Size"}
              />
              <InputField
                control={control}
                name={"noOfPages"}
                label="No Of Pages"
              />
            </div>
          </div>
          <hr className="border-b-slate-700 px-4 mt-4 border border-slate-700" />
          <div className="my-5">
            <DropdownField
              control={control}
              name="barcodePrinter"
              placeholder="Barcode Printer *"
              dataArray={printerOptions}
              error={errors.barcodePrinter}
            />
            <div className="flex gap-2 items-center my-4">
              <DropdownField
                control={control}
                name="barcodePaperSize"
                placeholder="Size"
                dataArray={barcodeSize}
                error={errors.barcodePrinter}
              />
              <InputField
                control={control}
                name={"barcodeNoOfPages"}
                label="No Of Pages"
              />
            </div>
          </div>
          <div className="flex gap-2 justify-end my-2">
            <div>
              <CommonButton
                className="border border-customRed text-customRed bg-white px-2 rounded h-9 "
                label="Reset"
                type="button"
                onClick={() => {
                  setOpenReset(true);
                }}
              />
            </div>
            <div>
              <CommonButton
                className="bg-customGreen h-9 px-2 text-white rounded"
                label="Set"
                type="submit"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <ConfirmationModal
        confirmationOpen={openChild}
        confirmationHandleClose={handleCloseChild}
        confirmationSubmitFunc={savePrinters}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to save default printers ?"
        confirmationButtonMsg="Confirm"
      />
      <ConfirmationModal
        confirmationOpen={openReset}
        confirmationHandleClose={handleCloseReset}
        confirmationSubmitFunc={handleResetPrinters}
        confirmationLabel="Confirmation "
        confirmationMsg="Are you sure want to reset default printers ?"
        confirmationButtonMsg="Confirm"
      />
    </form>
  );
}
