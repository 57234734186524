import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";

import Tooltip from "@mui/material/Tooltip";
//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ExpiredItemReturnTable(props) {
  console.log("ExpiredItemReturnTableExpiredItemReturnTable", props);
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);

  const [open, setOpen] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //actions

  const [rescheduleAction, setrescheduleAction] = React.useState(false);
  const [cancelAction, setcancelAction] = React.useState(false);
  const [casePaperAction, setCasePaperAction] = React.useState(false);
  const [editAction, setEditAction] = React.useState(false);
  const [deleteAction, setDeleteAction] = React.useState(false);
  const [vistiPdf, setVisitPdf] = React.useState("");
  const [rowIndex, setRowIndex] = React.useState();
  const [viewAction, setViewAction] = React.useState(false);
  const [checkBoxAction, setCheckBoxAction] = React.useState(false);
  const [printAction, setPrintAction] = React.useState(false);
  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  function handleClick(row, index) {
    setRowIndex(index);
  }
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);
  console.log("data object in table is:", props.data.result);
  const headers = removeHeaders(allHeaders, ["id"]);
  // headers.unshift("#");
  // headers[0] = "#";

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  //5,10.25 change as per the selection
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleChangeCheckBox(e, index, row) {
    let selectOptions = watch(`selectOptions${index}`);
    console.log("selectOptionsselectOptions", selectOptions);
    let selectedRowData = [...props.childData];
    if (selectOptions === true) {
      selectedRowData.push(row);
      console.log("selectedRowDataafter", selectedRowData);
      props.setChildData(selectedRowData);
    }
  }

  React.useEffect(() => {
    props.actions.forEach((action) => {
      if (action === "cancel") {
        setcancelAction(true);
      }
      if (action === "casepaper") {
        setCasePaperAction(true);
      }
      if (action === "Edit") {
        setEditAction(true);
      }
      if (action === "Delete") {
        setDeleteAction(true);
      }
      if (action === "print") {
        setPrintAction(true);
      }
      if (action === "View") {
        setViewAction(true);
      }
      if (action === "Checkbox") {
        setCheckBoxAction(true);
      }
    });
  }, []);

  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <>
                {/* <span style={{ marginRight: "10px", color: "blue" }}>
                  <span>
                    <div onClick={props.DownloadTableData}>
                      Download Template
                    </div>
                  </span>
                  <span> | </span>

                  <span>
                    <label>
                      Import Data
                      <input
                        type="file"
                        style={{ display: "none" }}
                        name="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={(e) => props.uploadExcelData(e)}
                      ></input>
                    </label>
                  </span>
                </span>
                <span>Rows Per Page:</span> */}
              </>
            }
          />
            <TableContainer
             sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "lightBlue",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1e3a8a",
                borderRadius: 4,
              },
            }}
              className="rounded lg:h-80  2xl:h-72"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>Actions</TableCell>
                    {headers.map((header, index) => (
                      <>
                        <TableCell
                          sortDirection={orderBy === header ? order : false}
                          className="whitespace-nowrap"
                        >
                          <TableSortLabel
                            active={false}
                            direction={orderBy === header ? order : "asc"}
                            onClick={createSortHandler(header)}
                            key={index}
                          >
                            <span className="text-gray-600 font-bold">
                              {header}
                            </span>
                            {orderBy === header ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.result.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          handleClick(row, index);
                        }}
                        size="small"
                        sx={{
                          "& td": {
                            paddingY: 0,

                            height: "8px",
                          },
                        }}
                      >
                        {props.actions.length > 0 ? (
                          <TableCell
                            className="px-4  flex whitespace-nowrap "
                            sx={{
                              "& td": {
                                paddingY: 0,

                                height:8,
                              },
                              "& tr": {
                                paddingY: 0,

                                height: 8,
                              },
                            }}
                          >
                            <div className="flex">
                              {deleteAction ? (
                                <div
                                  className="text-red-700 mr-2"
                                  onClick={() => props.deleteRow(row)}
                                  // onClick={() => props.deleteRow(index)}
                                >
                                  {<DeleteOutlineOutlinedIcon />}
                                </div>
                              ) : (
                                ""
                              )}
                              {editAction ? (
                                <div
                                  className="text-blue-900 mr-2"
                                  onClick={() => props.deleteRow(row)}
                                  // onClick={() => props.deleteRow(index)}
                                >
                                  {<DriveFileRenameOutlineIcon />}
                                </div>
                              ) : (
                                ""
                              )}

                              {cancelAction ? (
                                <div className="flex items-center space-x-1">
                                  <Tooltip title="Canel Appointment">
                                    <div
                                      className="text-red-500 mr-3"
                                      onClick={() => props.deleteRow()}
                                    >
                                      <CancelIcon />
                                    </div>
                                  </Tooltip>

                                  {/* <img
                                    
                                    alt="reschedule"
                                    src={user1}
                                    className="h-10 w-10 rounded-md"
                                  /> */}
                                </div>
                              ) : (
                                ""
                              )}
                              {viewAction ? (
                                <div className="flex items-center space-x-1">
                                  <Tooltip title="View">
                                    <div
                                      className="text-blue-900 mr-2"
                                      onClick={() => props.deleteRow(row)}
                                      // onClick={() => props.deleteRow(index)}
                                    >
                                      {<VisibilityIcon />}
                                    </div>
                                  </Tooltip>
                                </div>
                              ) : (
                                ""
                              )}
                              {checkBoxAction ? (
                                <fieldset
                                  className="m-0 p-0"
                                  onChange={(e) => {
                                    console.log(
                                      "selected servie are",
                                      e.target.checked
                                    );
                                    handleChangeCheckBox(
                                      e.target.checked,
                                      index,
                                      row
                                    );
                                  }}
                                >
                                  <CheckBoxField
                                    control={control}
                                    // defaultValue={true}
                                    name={`selectOptions${index}`}
                                  />
                                </fieldset>
                              ) : null}
                            </div>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {headers &&
                          headers.map((header, index) => (
                            <TableCell
                              className="whitespace-nowrap"
                              key={index}
                            >
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
