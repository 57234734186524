import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../../common/components/Toasts/CustomToasts";
import {
  addNewcareNPosition,
  getAllCarePosition,
} from "../../../../services/cliinicalChartServices/vitalsService/VitalsService";
import CommonBackDrop from "../../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ControlledCheckBoxField from "../../../../../common/components/FormFields/ControlledCheckBoxField";
import LoadingSpinner from "../../../../../common/components/loadingspinner/loadingSpinner";
const ModalStyleCarePosation = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: { lg: "70%", xl: "80%" },
  width: "80%",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid gray",
};

export default function CareNPosition(props) {
  let getUserId = JSON.parse(localStorage.getItem("userInfo"));

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [finalData, setFinalData] = React.useState();
  const [openPost, setOpenPost] = React.useState(false);
  const [careNPositionResult, setCareNPositionResult] = React.useState([]);
  const [countClick, setCountClick] = React.useState(0);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();

  const [spinner, showSpinner] = useState(false);
  const [recordWarning, setRecordWarning] = useState(false);

  const defaultValues = {
    eyeCare: false,
    mouthCare: false,
    backCare: false,
    foleysCathater: false,
    supinePosition: false,
    leftPosition: false,
    rightPosition: false,
    pronePosition: false,
  };

  const { control, handleSubmit, reset, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    populateCareNPositionTable();
  }, [props.displayInfo?.AdmissionId || props.admissionId]);

  const populateCareNPositionTable = (forPagination) => {
    showSpinner(true);
    getAllCarePosition(
      props.displayInfo?.AdmissionId || props.admissionId,
      !forPagination ? 0 : page,
      rowsPerPage
    )
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        showSpinner(false);
        if (forPagination) {
          setCareNPositionResult((prevData) => [...prevData, ...res.result]);
        } else {
          setCareNPositionResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  function postCareNPosition(obj) {
    addNewcareNPosition(obj)
      .then((response) => {
        if (
          (response.data.statusCode === 200 &&
            props.displayInfo?.AdmissionId) ||
          props.admissionId
        ) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          props.handleClose();
          populateCareNPositionTable();
          setCountClick(0);
        } else if (!props.displayInfo?.AdmissionId || props.admissionId) {
          if (countClick === 0) {
            setCountClick(0);
            warningAlert("Please Select Patient");
          }
        }
        reset(defaultValues);
      })
      .catch((error) => {
        setCountClick(0);
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  const onSubmitDataHandler = (postdata) => {
    let getpatientAdmId = props.displayInfo?.AdmissionId;
    let getipdAdmissionId = props.admissionId;
    let getAdmId;
    if (getpatientAdmId) {
      getAdmId = getpatientAdmId;
    } else if (getipdAdmissionId) {
      getAdmId = getipdAdmissionId;
    }

    let eyeCare = getValues("eyeCare");
    let mouthCare = getValues("mouthCare");
    let backCare = getValues("backCare");
    let foleysCathater = getValues("foleysCathater");
    let supinePosition = getValues("supinePosition");
    let leftPosition = getValues("leftPosition");
    let rightPosition = getValues("rightPosition");
    let pronePosition = getValues("pronePosition");

    let carePositionPostObj = {
      addedBy: getUserId.userId,
      admissionId: getAdmId,
      careBack: postdata.backCare,
      careFoleysCathater: postdata.foleysCathater,
      careMouth: postdata.mouthCare,
      careEye: postdata.eyeCare,

      l: postdata.leftPosition,
      p: postdata.pronePosition,
      r: postdata.rightPosition,
      b: postdata.supinePosition,
      menuId: props.menuId,
      privilege: props.privilege,
    };

    setFinalData(carePositionPostObj);
    if (getpatientAdmId !== undefined || getipdAdmissionId !== null)
      if (
        eyeCare ||
        mouthCare ||
        backCare ||
        foleysCathater ||
        supinePosition ||
        leftPosition ||
        rightPosition ||
        pronePosition === true
      ) {
        setOpenPost(true);
      } else {
        warningAlert("Please select atleast one care...!");
      }
  };

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postCareNPosition(finalData);
  }

  const renderInput = (row, index, header) => {
    return (
      <>
        {header === "Care_Eye" ||
        header === "Care_Mouth" ||
        header === "Care_Back" ||
        header === "Care_FoleysCathater" ||
        header === "S" ||
        header === "L" ||
        header === "R" ||
        header === "P" ? (
          <ControlledCheckBoxField
            name={`type[${index}].${header}`}
            value={row[header]}
            defaultValue={row[header]}
            control={control}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className=" backdrop-blur-0">
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleCarePosation}
          className="w-[90%] h-[85%] xl:h-[75%] px-8 pb-2"
        >
          <form
            className="grid grid-cols-1 w-full gap-x-2"
            onSubmit={handleSubmit(onSubmitDataHandler)}
          >
            <div className="sticky top-0 bg-white z-50 w-full">
              <div className="flex justify-between items-center w-full py-2">
                <div className="my-1 text-sm font-semibold whitespace-nowrap">
                  Care And Position
                </div>
                <CancelPresentationIconButton
                  onClick={() => {
                    props.handleClose();
                    reset(defaultValues);
                  }}
                />
              </div>
              <div className="grid rounded">
                <div className="grid grid-cols-2 lg:grid-cols-3 bg-gray-100 border-gray-300 gap-x-2 px-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1">
                    <div className="hidden lg:block">
                      <div className="flex gap-2 text-sm">
                        <h1 className="text-black font-semibold flex items-center lg:space-x-2">
                          <span>Patient Name </span>
                          <span className=""> :</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.PatientName ||
                            props.selectedPatient?.PatientName}
                        </h1>
                      </div>
                    </div>
                    <div className="flex gap-2 text-sm">
                      <h1 className="text-black font-semibold flex space-x-9 lg:space-x-9">
                        <span>Dr. Name</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="text-black font-normal">
                        {props.displayInfo?.DoctorName ||
                          props.selectedPatient?.DoctorName}
                      </h1>
                    </div>
                    <div className="lg:hidden">
                      <div className="flex gap-2 text-sm">
                        <h1 className="text-black items-center font-semibold flex space-x-16 lg:space-x-16">
                          <span>Age</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.AgeYear ||
                            props.selectedPatient?.AgeYear}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-2  lg:border-r-2 pl-4 border-slate-500 my-1">
                    <div className="flex gap-2 text-sm">
                      <h1 className="text-black items-center font-semibold flex space-x-14 lg:space-x-16">
                        <span>MR NO.</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="text-black font-normal">
                        {props.displayInfo?.MRNo || props.selectedPatient?.MRNo}
                      </h1>
                    </div>
                    <div className="flex gap-2 text-sm">
                      <h1 className="text-black items-center font-semibold flex space-x-16 lg:space-x-9">
                        <span>Department</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="text-black font-normal">
                        {props.displayInfo?.Department ||
                          props.selectedPatient?.Department}
                      </h1>
                    </div>
                    <div className="lg:hidden">
                      <div className="flex gap-2 text-sm">
                        <h1 className="text-black font-semibold flex items-center space-x-3 lg:space-x-1">
                          <span>PatientName </span>
                          <span className=""> :</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.PatientName ||
                            props.selectedPatient?.PatientName}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-2 pl-3">
                    <div className="hidden lg:block">
                      <div className="flex gap-2 ">
                        <h1 className="text-black items-center font-semibold flex space-x-6 lg:space-x-14">
                          <span>Age</span>
                          <span className="">:</span>
                        </h1>
                        <h1 className="text-black font-normal">
                          {props.displayInfo?.AgeYear ||
                            props.selectedPatient?.AgeYear}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>

              <div className="grid md:grid-cols-1 2xl:flex items-center gap-x-2">
                <div className="flex gap-2 items-center md:justify-between lg:justify-start w-full">
                  <span className="w-16 font-semibold"> Care</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal"></span>
                  </div>

                  <CheckBoxField
                    control={control}
                    name="eyeCare"
                    label="Eye Care"
                  />

                  <CheckBoxField
                    control={control}
                    name="mouthCare"
                    label="Mouth Care"
                  />
                  <CheckBoxField
                    control={control}
                    name="backCare"
                    label="Back Care"
                  />
                  <CheckBoxField
                    control={control}
                    name="foleysCathater"
                    label="Foleys Cathater"
                  />
                </div>
                <div className="flex gap-2 items-center justify-between w-full">
                  <div className="flex gap-2 items-center justify-between">
                    <span className="w-16 font-semibold"> Position</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal"></span>
                    </div>
                    <CheckBoxField
                      control={control}
                      name="supinePosition"
                      label="S"
                    />
                    <CheckBoxField
                      control={control}
                      name="leftPosition"
                      label="L"
                    />
                    <CheckBoxField
                      control={control}
                      name="rightPosition"
                      label="R"
                    />
                    <CheckBoxField
                      control={control}
                      name="pronePosition"
                      label="P"
                    />
                  </div>

                  {props.patientInformation?.AllowToGO === true ||
                  props.selectedPatient?.AllowToGO === true ? null : (
                    <div className="flex justify-end">
                      {props.userActions.map((obj) => (
                        <>
                          {!obj.isAction && obj.action === "Create" ? (
                            <>
                              {props.displayInfo !== null ||
                              props.admissionId !== undefined ? (
                                <CommonButton
                                  type="submit"
                                  className="bg-customGreen text-white"
                                  label="Save"
                                  
                                  onClick={() => {
                                    props.setPrivilege(obj.action);
                                  }}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* {careNPositionResult.length > 0 ? (
                <CommonDynamicTableNew
                  data={careNPositionResult}
                  tableClass={"rounded h-72 lg:h-64 2xl:h-[400px] border"}
                  highlightRow={false}
                  removeHeaders={["id"]}
                />
              ) : (
                <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )} */}

              {spinner ? (
                <div className="grid justify-center">
                  <LoadingSpinner />
                </div>
              ) : null}

              {careNPositionResult.length > 0 && spinner === false ? (
                <>
                  <CommonDynamicTablePaginationNew
                    dataResult={careNPositionResult}
                    populateTable={populateCareNPositionTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    highlightRow={false}
                    renderInput={renderInput}
                    editableColumns={[
                      "Care_Eye",
                      "Care_Mouth",
                      "Care_Back",
                      "Care_FoleysCathater",
                      "S",
                      "L",
                      "R",
                      "P",
                    ]}
                    removeHeaders={["Id", "Opd_Ipd_External_Id"]}
                  />
                </>
              ) : (
                <h3 className="flex justify-center my-20 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>
          </form>

          <CommonBackDrop openBackdrop={openBackdrop} />
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
