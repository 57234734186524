import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const saveGeneralAssessment = (saveObj) => {
  return apiClient.post(`/physiotherapy/saveGeneralAssessment`, saveObj, {
    headers: authHeader(),
  });
};

export const getPhysioAssessmentList = (listObj) => {
  return apiClient.post(`/physiotherapy/getPhysioAssessmentList`, listObj, {
    headers: authHeader(),
  });
};

export const getGeneralAssessmentDetails = (physioId) => {
  return apiClient.get(
    `/physiotherapy/getGeneralAssessmentDetails/${physioId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPhysioAssessmentPrint = (physioAssessmentId) => {
  return apiClient.get(
    `/reports/lapReports/physioAssessment/${physioAssessmentId}`,
    {
      headers: authHeader(),
    }
  );
};

