import apiClient from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";
// /api/companySettlement/saveCompanySettlement
export const saveCompanyBillSettlement = (postObj) => {
  return apiClient.post(`/companySettlement/saveCompanySettlement`, postObj, {
    headers: authHeader(),
  });
};
///api/companySettlement/previousReceiptList
export const fetchPreviousReceiptList = (previousReceiptObj) => {
  return apiClient.post(
    `/companySettlement/previousReceiptList`,
    previousReceiptObj,
    {
      headers: authHeader(),
    }
  );
};

export const opdCompanySettlementPdfPrint = (paymentId) => {
  return apiClient.get(`/reports/bills/companySettlementReceipt/${paymentId}`, {
    headers: authHeader(),

    responseType: "blob",
  });
};
