import React from "react";
import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import InputArea from "../../../common/components/FormFields/InputArea";

const CBForm2 = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="gap-3 grid md:grid-cols-2 lg:grid-cols-4 mt-3">
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="shortSummeryOfCase"
            label="Short Summary Of Case"
            placeholder="Short Summary Of Case"
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="codeTeamMembers"
            label="Code Team Members"
            placeholder="Code Team Members"
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="difficulties"
            label="Any Difficulties Encountered While Attending To Blue Code"
            placeholder="Any Difficulties Encountered While Attending To Blue Code"
          />
        </div>
        <div className="col-span-2 ">
          <InputArea
            control={control}
            name="comments"
            label="Overall Comments"
            placeholder="Overall Comments"
          />
        </div>
        <div className="col-span-2 ">
          <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
            Outcome Of Code Blue:
          </p>
          <div className="flex md:flex-wrap">
            <div className="md:col-span-1 lg:col-span-1 ">
              <CheckBoxField
                control={control}
                name="resuscitationSuccessfull"
                label="Resuscitation Successfull"
              />
            </div>

            <div className="md:col-span-1 lg:col-span-1 ">
              <CheckBoxField
                control={control}
                name="notRevived"
                label="Not Revived"
              />
            </div>
          </div>
        </div>

        <div className="col-span-2 ">
          <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
            Current Location Of Patient:
          </p>

          <div className="flex md:flex-wrap">
            <div className="md:col-span-1 lg:col-span-1 ">
              <CheckBoxField
                control={control}
                name="shiftedToIcu"
                label="Shifted To Icu"
              />
            </div>

            <div className="md:col-span-1 lg:col-span-1 ">
              <CheckBoxField
                control={control}
                name="stabilizedInWard"
                label="Stabilized In Ward"
              />
            </div>

            <div className="md:col-span-1 lg:col-span-1 ">
              <CheckBoxField
                control={control}
                name="treatedOnOpd"
                label="Treated On OPD"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CBForm2;
