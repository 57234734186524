import patientEngagement from "../../http-common";
import authHeader from "../../authentication/authservices/auth-header";

export const getListOfGroupsAndDetails = () => {
  //
  return patientEngagement.get("/misc/opdGroup", { headers: authHeader() });
};

export const getPreviousDetails = (preDetailsId) => {
  //
  return patientEngagement.get(`/patientEnngInfo/${preDetailsId}`, {
    headers: authHeader(),
  });
};

export const postPatientEngagementList = (patientList) => {
  return patientEngagement.post(`/patientEnngInfo/patientList`, patientList, {
    headers: authHeader(),
  });
};

export const searchPatientEngagementList = (patientSearch) => {
  return patientEngagement.post(`/patientEnngInfo/patientList`, patientSearch, {
    headers: authHeader(),
  });
};

export const postPatientEngagement = (patientInfo) => {
  //
  return patientEngagement.post(`/patientInfo/${patientInfo}`, {
    headers: authHeader(),
  });
};

export const getPatientOrderSearchOptions = (patientInfo) => {
  //
  return patientEngagement.get(`/patientInfo/${patientInfo}`, {
    headers: authHeader(),
  });
};
export const getPatienEngagementSearch = (patientInfo) => {
  //
  return patientEngagement.get(`/patientInfo/${patientInfo}`, {
    headers: authHeader(),
  });
};

export const savePatientEngFinalObj = (postobj) => {
  return patientEngagement.post(`/patientEnngInfo/savePTEngagement`, postobj, {
    headers: authHeader(),
  });
};

export const getGroupArr = () => {
  return patientEngagement.get("/misc/group");
};

export const getPTErejectResons = () => {
  return patientEngagement.get("/patientEnngInfo/rejectReasons", {
    headers: authHeader(),
  });
};

export const getPTEorderSearchList = (patientSearch) => {
  return patientEngagement.post(
    `/patientEnngInfo/ptEngFollowupDetailsList`,
    patientSearch,
    { headers: authHeader() }
  );
};

export const getPTEorderDetailsList = (listObj) => {
  return patientEngagement.post(
    `/patientEnngInfo/ptEngFollowupDetailsList`,
    listObj,
    { headers: authHeader() }
  );
};

export const postEngOrderDetails = (pteOrderObj, menuId, privilege) => {
  return patientEngagement.post(
    `/patientEnngInfo/savePTEngOrder/${menuId}/${privilege}`,
    pteOrderObj,
    { headers: authHeader() }
  );
};

export const getPreviousDocuments = (patientEngId) => {
  return patientEngagement.get(
    `/patientEnngInfo/fetchFilePath/${patientEngId}`,
    { headers: authHeader() }
  );
};

export const getDocumentsById = (fileType, fileName) => {
  return patientEngagement.get(`/file/${fileType}/${fileName}`, {
    headers: authHeader(),
  });
};

export const uploadDocuments = (postObj) => {
  return patientEngagement.post(
    `/patientEnngInfo/savePTEngagementDocument`,
    postObj,
    { headers: authHeader() }
  );
};

export const getSelectedGroups = (ptEngId) => {
  return patientEngagement.get(`/patientEnngInfo/groupSelection/${ptEngId}`, {
    headers: authHeader(),
  });
};
