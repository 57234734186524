import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

//  prefix
export const fetchPrefix = () => {
  return apiClient.get(`/misc/salutation`, {
    headers: authHeader(),
  });
};

//upi payment options
export const fetchUpiTypes = () => {
  return apiClient.get(`/misc/upiTypes`, {
    headers: authHeader(),
  });
};

// Marital Status
export const fetchMaritalStatus = () => {
  return apiClient.get(`/misc/maritalStatus`, {
    headers: authHeader(),
  });
};

// DOB
export const fetchBirthDetails = (enteredAge, fullDOB) => {
  return apiClient.get(`/misc/${enteredAge}/${fullDOB}`, {
    headers: authHeader(),
  });
};

// Genders
export const fetchGenders = () => {
  return apiClient.get(`/misc/gender`, {
    headers: authHeader(),
  });
};

// nationality
export const fetchNationality = () => {
  return apiClient.get(`/misc/nationality`, {
    headers: authHeader(),
  });
};

/////// Address //////

// countries
export const fetchCountries = () => {
  return apiClient.get(`/misc/country`, {
    headers: authHeader(),
  });
};
// State ////////
export const fetchState = (countryId) => {
  return apiClient.get(`/misc/state/${countryId}`, {
    headers: authHeader(),
  });
};
// District //////
export const fetchDistrict = (stateId) => {
  return apiClient.get(`/misc/district/${stateId}`, {
    headers: authHeader(),
  });
};
// City /////////
export const fetchCity = (districtId) => {
  return apiClient.get(`/misc/city/${districtId}`, {
    headers: authHeader(),
  });
};
// Area
export const fetchArea = (cityId) => {
  return apiClient.get(`/misc/area/${cityId}`, {
    headers: authHeader(),
  });
};

// patientsource ////////
export const fetchPatientsource = () => {
  return apiClient.get(`/misc/patientsource`, {
    headers: authHeader(),
  });
};

// patientsource ////////
export const fetchCampData = () => {
  return apiClient.get(`/misc/camp`, {
    headers: authHeader(),
  });
};
// patientCategory ///////
export const fetchPatientCategory = () => {
  return apiClient.get(`/misc/patientcategory`, {
    headers: authHeader(),
  });
};
// Department //
export const fetchDepartment = (departmentSearchString) => {
  return apiClient.get(`/misc/department/${departmentSearchString}`, {
    headers: authHeader(),
  });
};

export const fetchDepartmentForAppointment = (
  unitId,
  departmentSearchString
) => {
  return apiClient.get(
    `/misc/departmentForAppt/${unitId}/${departmentSearchString}`,
    {
      headers: authHeader(),
    }
  );
};

// doctor //
export const fetchDoctor = (doctorSearchString) => {
  return apiClient.get(`/misc/doctor/${doctorSearchString}`, {
    headers: authHeader(),
  });
};
// doctor By Department Id //
export const fetchDoctorListByDepartmentId = (departmentId, unitId) => {
  return apiClient.get(
    `/phoneAppointment/doctorList/${departmentId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchDoctorListByDepartmentIdUnitId = (departmentId, unitId) => {
  return apiClient.get(
    `/phoneAppointment/doctorList/${departmentId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

// tariff //
export const fetchTariff = () => {
  return apiClient.get(`/misc/tariff`, {
    headers: authHeader(),
  });
};

//tariff by company And Category
export const fetchTariffByCompCategory = (patientCategoryId, companyId) => {
  return apiClient.get(
    `/misc/tariffForRegistration/${patientCategoryId}/${companyId}`,
    {
      headers: authHeader(),
    }
  );
};
// company //
export const fetchCompany = () => {
  return apiClient.get(`/misc/company`, {
    headers: authHeader(),
  });
};

// company According patient category
export const fetchCompanyByPatientCategory = (patientCategoryId) => {
  return apiClient.get(`/misc/companyByPatientCategory/${patientCategoryId}`, {
    headers: authHeader(),
  });
};
// company asst //
export const fetchCompanyAsst = (companyId) => {
  return apiClient.get(`/misc/companyAss/${companyId}`, {
    headers: authHeader(),
  });
};
// Employee Name //
export const fetchEmoloyeeName = () => {
  return apiClient.get(`/misc/employee`, {
    headers: authHeader(),
  });
};

// Employee Name //
export const fetchEmoloyeeDependent = (empId) => {
  return apiClient.get(`/misc/staffDependent/${empId}`, {
    headers: authHeader(),
  });
};

//code-limit-used
export const fetchEmployeeDetails = (employeeId, dependantId) => {
  return apiClient.get(
    `/patientInfo/employeeDetails/${employeeId}/${dependantId}`,
    {
      headers: authHeader(),
    }
  );
};

// referenceDoctor //
export const fetchReferanceDoctor = (referanceDoctorSearchString) => {
  return apiClient.get(`/misc/referenceDoctor/${referanceDoctorSearchString}`, {
    headers: authHeader(),
  });
};

// Cabin //
export const fetchCabin = () => {
  return apiClient.get(`/misc/cabin`, {
    headers: authHeader(),
  });
};

// bedCategory//
export const fetchBedCategory = () => {
  return apiClient.get(`/misc/bedCategory`, {
    headers: authHeader(),
  });
};
// ChargesPerBedCategory //
export const fetchChargesPerBedCategory = () => {
  return apiClient.get(`/misc/bedCategory`, {
    headers: authHeader(),
  });
};
// MyWard //
export const fetchWard = () => {
  return apiClient.get(`/misc/ward`, {
    headers: authHeader(),
  });
};
//unit
export const fetchUnit = () => {
  return apiClient.get(`/misc/unit`, {
    headers: authHeader(),
  });
};
//occupation
export const fetchOccupation = () => {
  return apiClient.get(`misc/occupation`, {
    headers: authHeader(),
  });
};
//block
export const fetchBlock = (unitId) => {
  return apiClient.get(`/misc/block/${unitId}`, {
    headers: authHeader(),
  });
};

//Floor
export const fetchFloor = () => {
  return apiClient.get(`/misc/floor`, {
    headers: authHeader(),
  });
};

// Concession Authority
export const fetchConcessionAuthority = () => {
  return apiClient.get(`/concessionManager/concessionAuthority`, {
    headers: authHeader(),
  });
};

// stores
export const fetchStore = () => {
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};

// Group-----used in Ot
export const fetchGroup = () => {
  return apiClient.get(`/misc/group`, {
    headers: authHeader(),
  });
};

// Item Category
export const fetchItemCategory = () => {
  return apiClient.get(`/storeStockMinMax/itemCategory`, {
    headers: authHeader(),
  });
};

// Item Class

export const fetchItemClass = () => {
  return apiClient.get(`/storeStockMinMax/itemClass`, {
    headers: authHeader(),
  });
};

// Item Type

export const fetchItemType = () => {
  return apiClient.get(`/storeStockMinMax/itemType`, {
    headers: authHeader(),
  });
};

// Rack api
export const fetchRack = (searchString) => {
  return apiClient.get(`/storeStockMinMax/getRack/${searchString}`, {
    headers: authHeader(),
  });
};

// Shelf api
export const fetchShelf = (searchString) => {
  return apiClient.get(`/storeStockMinMax/getShelf/${searchString}`, {
    headers: authHeader(),
  });
};

//surgery instructions
export const fetchSurgeryInstructions = () => {
  return apiClient.get(`/misc/getSurgeryInstruction`, {
    headers: authHeader(),
  });
};
//aplicable stores
export const fetchApplicableStores = (storeId) => {
  return apiClient.get(`/misc/applicableStores/${storeId}`, {
    headers: authHeader(),
  });
};

export const fetchDoctorsForAdm = (doctorIds, searchString) => {
  return apiClient.get(`/misc/doctorsForAdm/${doctorIds}/${searchString}`, {
    headers: authHeader(),
  });
};

export const fetchAdmissionType = () => {
  return apiClient.get(`/misc/getAdmissionType`, {
    headers: authHeader(),
  });
};

// Group-----used in configuration screen
export const fetchServicesSearch = (searchString) => {
  return apiClient.get(`/services/getServicesSearch/${searchString}`, {
    headers: authHeader(),
  });
};

//
