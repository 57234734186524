import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";
// search etu Register
// /api/patientInfo/{patientInfo}
export const searchPatient = (patientInfo) => {
  return apiClient.get(`/patientInfo/${patientInfo}`, {
    headers: authHeader(),
  });
};
// /api/etuCaseSheet/patientInfoForEtuRegister/{patientId}
export const getPatientInfoForETURegister = (patientId) => {
  return apiClient.get(`/etuCaseSheet/patientInfoForEtuRegister/${patientId}`, {
    headers: authHeader(),
  });
};

// get staff name from list
// /api/etuCaseSheet/staffName
export const getETURegisterStaffName = () => {
  return apiClient.get(`/etuCaseSheet/staffName`, {
    headers: authHeader(),
  });
};

export const getETURegisterVacinneName = () => {
  return apiClient.get(`/etuCaseSheet/vaccines`, {
    headers: authHeader(),
  });
};

// /api/etuCaseSheet/saveETURegister
export const saveETURegister = (postObj) => {
  return apiClient.post(`/etuCaseSheet/saveETURegister`, postObj, {
    headers: authHeader(),
  });
};
// /api/etuCaseSheet/detailsList
export const fetchETURegisterList = (postObj) => {
  return apiClient.post(`/etuCaseSheet/detailsList`, postObj, {
    headers: authHeader(),
  });
};

// home visit print
export const printETURegisterHomevisitReceipt = (homeObj) => {
  return apiClient.post(`/reports/homeVisitRegister`, homeObj, {
    responseType: "blob",
    headers: authHeader(),
  });
};

//ambulance call print
export const printETURegisterAmbulanceCallReceipt = (ambulaceObj) => {
  return apiClient.post(`/reports/ambulanceRegister`, ambulaceObj, {
    responseType: "blob",
    headers: authHeader(),
  });
};

// Body Handover print
export const printETURegisterBodyHandoverReceipt = (BodyHandoverObj) => {
  return apiClient.post(`/reports/bodyHandOver`, BodyHandoverObj, {
    responseType: "blob",
    headers: authHeader(),
  });
};
// Vaccine Chart print
export const printETURegisterVaccineChartReceipt = (vaccineChartObj) => {
  return apiClient.post(`/reports/vaccineRegister`, vaccineChartObj, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const BodyHandedOverConsentInamdarAndIpIssuePrint = (printObj) => {
  return apiClient.post(`/reports/bodyHandOver`, printObj, {
    headers: authHeader(),
  });
};
