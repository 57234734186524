import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import RadioField from "../../../common/components/FormFields/RadioField";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";

const ReceiptCancellation = () => {
  const eyeOpeningOptions = [
    { id: 2, label: "OPD", value: 2 },
    { id: 1, label: "IPD", value: 1 },
    { id: 3, label: "All", value: 3 },
  ];

  const defaultValues = {
    OpdIpd: 2,
    fromDate: new Date(),
    toDate: new Date(),
    receiptType: null,
    cancellation: null,
    cencelReceipt: false,
  };
  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  const dataResult = [
    {
      Date: "",
      "Bill No": "",
      "Receipt No": "",
      "petient Name": "",
      Amount: "",
      "Cash Payment Amount": "",
      "Cheque Pay Amount": "",
      "DD Pay Amount": "",
      "Credit Card Pay Amount": "",
      "Debit card Pay Amount": "",
    },
  ];
  const handleEyeOpeningResponseChange = (event) => {};

  const handleSearchClick = () => {};
  const cencelltionList = [
    { id: 1, label: "Cash Pay Amount", value: 1 },
    { id: 2, label: "Cheque Pay Amount", value: 2 },
    { id: 3, label: "DD Pay Amount", value: 3 },
    { id: 4, label: "Credit Card Pay Amount", value: 4 },
    { id: 5, label: "Debit card Pay Amount", value: 5 },
  ];

  return (
    <div className="mt-12 mb-2">
      <h1 className="text-center text-black font-bold text-xl">
        Receipt Cencelltion
      </h1>
      <div className=" grid  grid-cols-1 gap-2 items-center ">
        <div className=" grid  md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 grid-cols-1  gap-2  mt-2">
          <RadioField
            dataArray={eyeOpeningOptions}
            name="OpdIpd"
            control={control}
            onChange={handleEyeOpeningResponseChange}
          />
          <DatePickerFieldNew
            control={control}
            name="fromDate"
            label="From Date"
            value={new Date()}
            inputFormat="yyyy-MM-dd"
          />
          <DatePickerFieldNew
            control={control}
            name="toDate"
            label="To Date"
            value={new Date()}
            inputFormat="yyyy-MM-dd"
          />
          <DropdownField
            control={control}
            name="receiptType"
            placeholder="Receipt Type "
            // dataArray={}
            isClearable={true}
            isSearchable={true}
          />

          <DropdownField
            control={control}
            name="cancellation"
            placeholder=" Pay. Cencelltion Mode"
            dataArray={cencelltionList}
            isClearable={false}
            isSearchable={false}
          />
          <div className="flex  justify-between items-center">
            <CheckBoxField
              name="cencelReceipt"
              label="Cencel Receipts"
              control={control}
            />
            <CommonButton
              type="button"
              label={<searchIcon />}
              onClick={handleSearchClick}
              className="bg-customBlue text-white"
              disabled={false}
              searchIcon={true}
            />
          </div>
        </div>
      </div>

      <h1 className="font-semibold text-sm">Lists of Receipts</h1>
      <CommonDynamicTableNew
        dataResult={dataResult}
        removeHeaders={["id"]}
        tableClass="h-28 "
        highlightRow={false}
        rowBackgroundColor={(row, index) => {
          return index % 2 === 0 ? "bg-gray-300" : "bg-white";
        }}
        handleSelectedRow={(row, index) => {}}
        editableColumns={[""]}
        SelectCheckbox={false}
      />
      <div className="flex justify-end space-x-3">
        <CommonButton
          label="Ok"
          type="button"
          onClick={() => ""}
          className="bg-customBlue text-white"
        />
        <CommonButton
          label="Reset"
          type="button"
          onClick={() => {
            reset(defaultValues);
          }}
          className="border border-customRed text-customRed"
        />
      </div>
    </div>
  );
};

export default ReceiptCancellation;
