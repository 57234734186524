import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import { differenceInMinutes, format, isValid } from "date-fns";
import { createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  fetchAutocompleteForOtDtls,
  fetchOtDetailsViewData,
  fetchPatientInformationForOtDtls,
  otReservationDetails,
  saveOtDetails,
} from "../../../services/otDetailsServices/OtDetailsServices";
import OtDetailsTabs from "./OtDetailsTabs";
import { fetchEmployeeName } from "../../../services/otReservationServices/OtReservationServices";
export const OtDetailsContext = createContext("");

const patientTypeArray = [
  {
    id: 1,
    value: "IPD",
    label: "IPD",
  },
  {
    id: 0,
    value: "OPD",
    label: "OPD",
  },
];

const schema = yup.object().shape({
  theater: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Prefix"),
      label: yup.string().required("Please Select Prefix"),
    })
    .required("Required"),

  otType: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Prefix"),
      label: yup.string().required("Please Select Prefix"),
    })
    .required("Required"),

  otCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Prefix"),
      label: yup.string().required("Please Select Prefix"),
    })
    .required("Required"),
});

const Actions = [
  {
    id: 0,
    action: "save",
    isAction: false,
  },
];

const OtDetails = () => {
  let location = useLocation();
  let navigate = useNavigate();

  let token = JSON.parse(localStorage.getItem("userInfo"));
  // const [userActions, setUserActions] = useState([]);

  //
  const [allFormData, setAllFormData] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  //patient search
  const [options, setOptions] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [employeeNameData, setEmployeeNameData] = useState();

  //
  //surgery details
  // const [sergeryServiceData, setSergeryServiceData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  //Doctors And Emp Details
  const [empDetailsArr, setEmpDetailsArr] = React.useState([]);
  const [doctorDetailsArr, setDoctorDetailsArr] = React.useState([]);
  const [deletedDoctorData, setDeletedDoctorData] = React.useState([]);
  const [deletedEmpData, setDeletedEmpData] = React.useState([]);
  //surgery Details
  const [sugeryDetailsData, setSugeryDetailsData] = useState([]);
  const [totalOtCharges, setTotalOtCharges] = useState();
  const [deletedSergeryData, setDeletedSergeryData] = useState([]);
  // const [surgeryArr, setSurgeryArr] = useState([]);
  //Anesthetia notes
  const [anesthetiaStartTime, setAnesthetiaStartTime] = useState(new Date());
  const [anesthetiaEndTime, setAnesthetiaEndTime] = useState(new Date());
  //surgery notes
  const [templateContent, setTemplateContent] = useState("");
  //File upload
  const [patientdocs, setPatientdocs] = useState([]);
  //instruments
  const [instrumentsData, setInstrumentsData] = useState([]);
  const [sugeryEquipmentsData, setSugeryEquipmentsData] = useState([]);
  //pending Implant
  const [implantData, setImplantData] = useState([]);
  ///
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);
  ///retrived data
  const [reservedSurgeries, setReservedSurgeries] = useState([]);
  //is edit/patch Data
  const [disableSearch, setDisableSearch] = useState(null);
  const [patchData, setPatchData] = useState(null);

  // antibiotic file
  const [antibioticAtWardArr, setAntibioticAtWardArr] = React.useState([]);
  const [antibioticAtOtArr, setAntibioticAtOtArr] = React.useState([]);

  ///
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      operationDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      actualStartTime: new Date(),
      actualEndTime: new Date(),
      totalTime: 0,
      theater: null,
      anaes: null,
      procedureType: null,
      otResule: null,
      otStatus: null,
      nurse: null,
      physicion: null,
      otType: null,
      otCategory: null,
      manPower: "",
      remark: "",
      //
      surgeonArrivalTime: new Date(),
      anesthesistArrivalTime: new Date(),
      patientArrivalTime: new Date(),
      timeofAdministrationAnesthesia: new Date(),
      wheelIn: new Date(),
      wheelOut: new Date(),
      otCleaningEndTime: new Date(),
      otCleaningStarTime: new Date(),
      antibioticGivenAtWard: null,
      antibioticGivenAtWardTime: new Date(),
      antibioticGivenAtOt: null,
      antibioticGivenAtOtTime: new Date(),
      otCaseType: 0,
      //
      patientType: 1,
      patientSearch: null,
      againstReservation: false,
      //
      surgeryNameDr: null,
      doctorName: null,
      doctorType: null,
      doctorFees: "",
      EmployeeName: null,
      doctArrivalTime: null, //new
      //
      isPendingImplantChecked: true,
      ///
      adverseAnaesthesiaEvents: false,
      adverseAnaesthesiaEventsReason: "",
      anaesthesiaRelatedMortility: false,
      isPlanCase: false,
      otAntibioticRequestDtoList: [],
      pendingImplantsRemarks: "",
      planOfAnaesthesia: 0,
      planOfAnaesthesiaModified: 0,
      reasonOfAnaesthesiaRelatedMortility: "",
      reasonOfDelay: "",
      reasonOfModificationOfAnaesthesia: "",

      reasonOfUnplannedVentilationAnaesthesia: "",
      reExplorationReason: "",
      scrubCirculatoryTech: 0,
      unplannedReturnToOtReason: "",
      unplannedVentilationAnaesthesia: false,
      wardAntibioticRequestDtoList: [],
      wardShiftedBy: 0,
      wardShiftTime: new Date(),

      intraOpChangesOfSurgicalPlan: false,
      intraOpChangesOfSurgicalPlanReason: "",
      reasonOfSurgeryReschedule: "",
      surgicalReschedule: false,
      unplannedReturnToOt: false,
      unplannedReturnToOtReason: "",
      reExporation: false,
      reExplorationReason: "",
      //new
      signInTime: new Date(),
      inductionTime: new Date(),
      signOutTime: new Date(),
      incisionTime: new Date(),
      timeOutTime: new Date(),
      sutureTime: new Date(),
      intraOpExtraItemList: "",
      extrubation: "",
    },
  });
  const { watch, register, control, setValue } = methods;

  //

  ////
  /////receiving data of navigate
  useEffect(() => {
    if (location?.state !== null && location?.state.patientInfo !== null) {
      setPatientInfo(location.state.patientInfo);
      setDisableSearch(location.state.isEdit);
      //get patch data
      location?.state?.row?.OTHeaderID &&
        // location?.state?.row?.opdIpd &&
        getOtDtlsPatchData(
          location?.state?.row?.OTHeaderID,
          location?.state?.row?.opdIpd
        );

      location?.state?.row?.PatientId &&
        // location?.state?.row?.opdIpd &&
        getPatientInfo(
          location?.state?.row?.PatientId,
          location?.state?.row?.opdIpd
        );
    }

    // EmployeeName
    fetchEmployeeName()
      .then((response) => response.data)
      .then((res) => {
        setEmployeeNameData(res.result);
      });
  }, []);

  const getOtDtlsPatchData = (OTHeaderID, opdIpd) => {
    fetchOtDetailsViewData(OTHeaderID, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setPatchData(res.result);
        // setEnteredAge(res.result.Age)
      });
  };

  ///patch Data
  useEffect(() => {
    if (patchData !== null) {
      setPatientdocs(patchData?.uploadedFiles);
      // setDoctorDetailsArr(patchData?.doctorDetails);
      setDoctorDetailsArr(
        patchData?.doctorDetails.map(({ ArrivalTime, ...item }) => {
          return {
            ...item,
            "Arrival Time": ArrivalTime
              ? `1900-01-01T${ArrivalTime}.000`
              : null,
          };
        })
      );
      setAntibioticAtOtArr(
        patchData?.otAntibiotic.map(
          ({ GivenDateTime, GivenDateTimePatch, ...item }) => ({
            ...item,
            time: GivenDateTimePatch
              ? GivenDateTimePatch?.split("+")?.[0]
              : null,
            Time: GivenDateTime,
          })
        )
      );
      setAntibioticAtWardArr(
        patchData?.wardAntibiotic.map(
          ({ GivenDateTime, GivenDateTimePatch, ...item }) => ({
            ...item,
            time: GivenDateTimePatch
              ? GivenDateTimePatch?.split("+")?.[0]
              : null,
            Time: GivenDateTime,
          })
        )
      );

      setEmpDetailsArr(patchData?.employeeDetails);
      setInstrumentsData(patchData?.otherDetails);
      setTemplateContent(patchData?.SurgeryNotes);
      setSugeryDetailsData(
        patchData?.surgeryDetails.map((item) => ({ ...item, isDelete: false }))
      );
      setImplantData(patchData?.pendingImplants);

      if (patchData?.pendingImplants.length > 0) {
        setValue("isPendingImplantChecked", true);
        setValue(
          "implantRemark",
          patchData?.pendingImplants[0].PendingImplantRemark
        );
      }
      ///
      let resSurgery = [];
      for (let i = 0; i < patchData.surgeryDetails.length; i++) {
        let obj = {
          id: patchData.surgeryDetails[i].SurgeryId,
          label: patchData.surgeryDetails[i].SurgeryName,
          value: patchData.surgeryDetails[i].SurgeryName,
        };
        resSurgery.push(obj);
      }

      setReservedSurgeries(resSurgery);
      ///
      //ot sheet dtls
      setValue("operationDate", patchData.OTDate);
      setValue("patientType", patientInfo?.OpdIpd);

      setValue("startTime", `1900-01-01T${patchData.BookingStartTime}.000`);
      setValue("endTime", `1900-01-01T${patchData.BookingEndTime}.000`);

      setValue("actualStartTime", `1900-01-01T${patchData.StartTimePatch}.000`);
      setValue("actualEndTime", `1900-01-01T${patchData.EndTimePath}.000`);

      let minutes = differenceInMinutes(
        `1900-01-01T${patchData.EndTimePath}.000Z`,
        `1900-01-01T${patchData.StartTimePatch}.000Z`
      );

      // setValue("startTime", patchData.StartTimePatch);
      // setValue("endTime", patchData.EndTimePath);

      setValue("totalTime", patchData.TotalTime || 0);
      setValue("actualTotalTime", patchData.TotalTime);

      if (patchData.Theator !== null) {
        setValue("theater", {
          id: patchData.TheatorId,
          label: patchData.Theator,
          value: patchData.Theator,
        });
      }
      if (patchData.AnaesTypeId !== null) {
        setValue("anaes", {
          id: patchData.AnaesTypeId,
          label: patchData.AnaesType,
          value: patchData.AnaesType,
        });
      }
      if (patchData.ProcedureType !== null && patchData.ProcedureTypeId) {
        setValue("procedureType", {
          id: patchData.ProcedureTypeId,
          label: patchData.ProcedureType,
          value: patchData.ProcedureType,
        });
      }
      if (patchData.OTResult !== null) {
        setValue("otResule", {
          id: patchData.ResultId,
          label: patchData.OTResult,
          value: patchData.OTResult,
        });
      }
      if (patchData.OTStatus !== null) {
        setValue("otStatus", {
          id: patchData.OTStatusId,
          label: patchData.OTStatus,
          value: patchData.OTStatus,
        });
      }
      if (patchData.Nurse !== null) {
        setValue("nurse", {
          id: patchData.NurseId,
          label: patchData.Nurse,
          value: patchData.Nurse,
        });
      }
      // setValue("physicion",{
      //   id:patchData.NurseId,
      //   label:patchData.Nurse,
      //   value:patchData.Nurse,
      // })  ///not available from backend

      if (patchData.OTType !== null) {
        setValue("otType", {
          id: patchData.otTypeId,
          label: patchData.OTType,
          value: patchData.OTType,
        });
      }

      if (patchData.OTCategory !== null) {
        setValue("otCategory", {
          id: patchData.OTCategoryId,
          label: patchData.OTCategory,
          value: patchData.OTCategory,
        });
      }
      setValue(
        "manPower",
        patchData.ManPower !== null ? patchData.ManPower : ""
      );
      setValue("remark", patchData.Remark);

      setValue(
        "surgeonArrivalTime",
        `1900-01-01T${patchData.SurgeonArrivaltimePatch}.000`
      );

      setValue(
        "anesthesistArrivalTime",
        `1900-01-01T${patchData.AnasthetistArrivaltimePatch}.000`
      );

      setValue(
        "patientArrivalTime",
        `1900-01-01T${patchData.PatientArrivaltimePatch}.000`
      );
      setValue(
        "timeofAdministrationAnesthesia",
        `1900-01-01T${patchData.TimeOfAdministrationAnesthesiaPatch}.000`
      );
      setValue("wheelIn", `1900-01-01T${patchData.TimeOfWheelInPatch}.000`);
      setValue("wheelOut", `1900-01-01T${patchData.TimeOfWheelOutPatch}.000`);
      setValue(
        "otCleaningEndTime",
        `1900-01-01T${patchData.OTCleaningEndTimePatch}.000`
      );
      setValue(
        "otCleaningStarTime",
        `1900-01-01T${patchData.OTCleaningStartTimePatch}.000`
      );
      setValue(
        "antibioticGivenAtWardTime",
        `1900-01-01T${patchData.AntibioticGivenAtWardTimePatch}.000`
      );
      setValue(
        "antibioticGivenAtOtTime",
        `1900-01-01T${patchData.AntibioticGivenAtOTTimePatcch}.000`
      );

      ////new
      setValue(
        "adverseAnaesthesiaEvents",
        patchData.IsAdverseAnaesthesiaEvents
      );
      setValue(
        "adverseAnaesthesiaEventsReason",
        patchData.AdverseAnaesthesiaEvents
      );
      setValue(
        "anaesthesiaRelatedMortility",
        patchData.AnaesthesiaRelatedMortility
      );
      setValue(
        "intraOpChangesOfSurgicalPlanReason",
        patchData.IntraOpChangesOfSurgicalPlan
      );
      setValue("otCaseType", patchData.IsPlanCase ? 0 : 1); //patchData.isEmergency
      setValue("reExploration", patchData.IsReExploration);
      setValue("surgicalReschedule", patchData.IsSurgicalReschedule);
      setValue("unplannedReturnToOt", patchData.IsUnplannedReturnToOt);
      setValue(
        "planOfAnaesthesiaModified",
        patchData.PlanOfAnaesthesiaModified
      );
      setValue("reExplorationReason", patchData.ReExplorationReason);
      setValue(
        "reasonOfAnaesthesiaRelatedMortility",
        patchData.ReasonOfAnaesthesiaRelatedMortility
      );
      setValue("reasonOfOtDelay", patchData.ReasonOfDelay);
      setValue(
        "reasonOfModificationOfAnaesthesia",
        patchData.ReasonOfModificationOfAnaethesia
      );
      setValue(
        "reasonOfSurgeryReschedule",
        patchData.ReasonOfSurgeryReschedule
      );
      setValue(
        "reasonOfUnplannedVentilationAnaesthesia",
        patchData.ReasonOfUnplannedVentilationAnaesthesia
      );
      setValue(
        "unplannedReturnToOtReason",
        patchData.UnplannedReturnToOtReason
      );
      setValue(
        "unplannedVentilationAnaesthesia",
        patchData.UnplannedVentilationAnaesthesia
      );
      setValue("wardShiftTime", `1900-01-01T${patchData.WardShiftTime}.000`);
      if (!!patchData.WardShiftedById && !!patchData.WardShiftedByName) {
        setValue("wardShiftedBy", {
          id: patchData.WardShiftedById,
          label: patchData.WardShiftedByName,
          value: patchData.WardShiftedByName,
        });
      }
      setValue(
        "intraOpChangesOfSurgicalPlan",
        patchData.isIntraOpChangesOfSurgicalPlan
      );
      setValue("reExploration", patchData.IsReExploration);
      //new
      setValue("signInTime", `1900-01-01T${patchData.signInTime}.000`);
      setValue("inductionTime", `1900-01-01T${patchData.inductionTime}.000`);
      setValue("signOutTime", `1900-01-01T${patchData.signOutTime}.000`);
      setValue("incisionTime", `1900-01-01T${patchData.incisionTime}.000`);
      setValue("timeOutTime", `1900-01-01T${patchData.timeOutTime}.000`);
      setValue("sutureTime", `1900-01-01T${patchData.sutureTime}.000`);
      setValue("intraOpExtraItemList", patchData.intraOpExtraItemList);
      setValue("extrubation", patchData.extrubation);
    }
  }, [patchData]);

  /////
  let PatientTypeRadio = watch("patientType");
  let AgainstReservation = watch("againstReservation");
  let TheaterData = watch("theater");
  let OtTypeData = watch("otType");
  let OtCategoryData = watch("otCategory");

  useEffect(() => {
    if (
      TheaterData !== null &&
      OtTypeData !== null &&
      OtCategoryData !== null
    ) {
      setIsDisable(false);
    } else if (
      TheaterData === null ||
      OtTypeData === null ||
      OtTypeData === null
    ) {
      setIsDisable(true);
    }
  }, [TheaterData, OtTypeData, OtCategoryData]);

  //Change handler for "Search By Patient Name / MR No" SearchDropdown.
  const handleSearch = (searchString) => {
    if (searchString !== "") {
      fetchAutocompleteForOtDtls(
        searchString,
        AgainstReservation ? 1 : 0 || 0,
        PatientTypeRadio
      )
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  //
  function getPatientInfo(patientId, OpdIPd) {
    //call the below function to get the patient information by id.
    fetchPatientInformationForOtDtls(patientId, OpdIPd)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
        // getOtDtlsPatchData = (OTHeaderID, opdIpd)
      });
  }

  //allFormData, setAllFormData
  const onSubmit = (data) => {
    //for documents collection
    for (let i = 0; i < patientdocs.length; i++) {
      let fileObj = patientdocs[i].Docfile;

      let file;
      if (fileObj !== null && fileObj !== undefined) {
        let baseSixtyFourString;
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
          baseSixtyFourString = reader.result;
          patientdocs[i].baseSixtyFourString = baseSixtyFourString;
        };

        // }
      }
    }

    let checkValidSurgryDtls = sugeryDetailsData?.some(
      (item) => item["Net Amt"] && item["Net Amt"] !== 0
    );
    let checkValidOtherDtls = instrumentsData?.some(
      (item) => item["Qty"] && item["Qty"] !== 0
    );
    let checkValidArrivalTime = doctorDetailsArr?.some(
      (item) => item["Arrival Time"] === null
    );

    if (checkValidArrivalTime) {
      warningAlert("Please select doctor arrival time...");
    } else if (!checkValidSurgryDtls) {
      warningAlert("Please Enter Valid Quantity/Amount In Sergery Details...");
    } else if (instrumentsData.length > 0 && !checkValidOtherDtls) {
      warningAlert("Please Enter Valid Quantity In Instruments Details...");
    } else if (patientInfo && patientInfo !== null) {
      setOpenConfirmationModal(true);
      setAllFormData({ ...data });
    } else {
      warningAlert("Please Select Patient !");
    }
  };

  const finalObj = {
    addedBy: 0,
    anaesTypeId: 0,
    anaesthetist1Id: 0,
    anasthetistArrivalTime: new Date(),
    anesthesiaRecord: "",
    antibioticGivenAtOTId: 0, //
    antibioticGivenAtOTTime: new Date(), //
    antibioticGivenAtWardId: 0, //
    antibioticGivenAtWardTime: new Date(), //
    cashCounterId: 0,
    classId: 0,
    companyId: 0,
    doctorListDtos: [0],
    otDetailsEmployeeDtoList: [0],
    endTime: new Date(),
    isEmergency: true,
    manPower: 0,
    nurseId: 0,
    opdIpdExternal: 0,
    opdIpdExternalId: 0,
    otCategory: 0,
    // otChargesDtoList: [0],
    otConsumableChargeId: 0,
    otDate: new Date(),
    otReservationId: 0,
    otStatusId: 0,
    otType: 0,
    otcleaningEndTime: new Date(), //
    otcleaningStartTime: new Date(), //
    otherDetailsDtoList: [0],
    patientArrivalTime: new Date(),
    // pendingImplants: true,
    // pendingImplantsRemarks: "string",
    procedureTypeId: 0,
    remark: "",
    resultId: 0,
    startTime: new Date(),
    storeId: 0,
    surgeon1Id: 0,
    surgeonArrivalTime: new Date(),
    surgeryDetailsDtoList: [0],
    surgeryNotes: "",
    tariffId: 0,
    theaterId: 0,
    theaterServiceId: 0,
    timeOfAdministrationAnesthesia: new Date(), //
    timeOfWheelIn: new Date(), //
    timeOfWheelOut: new Date(), //
    totalTime: 0,
    //
    otFileUploadDtoList: null,
    otHeaderId: 0,
    //
    pendingImplantsDto: {},
    ///
    adverseAnaesthesiaEvents: false,
    adverseAnaesthesiaEventsReason: "",
    anaesthesiaRelatedMortility: false,
    intraOpChangesOfSurgicalPlan: "",
    isPlanCase: false,
    otAntibioticRequestDtoList: [],
    pendingImplantsRemarks: "",
    planOfAnaesthesia: 0,
    planOfAnaesthesiaModified: 0,
    reasonOfAnaesthesiaRelatedMortility: "",
    reasonOfDelay: "",
    reasonOfModificationOfAnaesthesia: "",
    reasonOfSurgeryReschedule: "",
    reasonOfUnplannedVentilationAnaesthesia: "",
    reExplorationReason: "",
    scrubCirculatoryTech: 0,
    unplannedReturnToOtReason: "",
    unplannedVentilationAnaesthesia: false,
    wardAntibioticRequestDtoList: [],
    wardShiftedBy: 0,
    wardShiftTime: null,
  };
  const handleFormSubmit = (data) => {
    console.log("ot dtls data", data);
    setOpenConfirmationModal(false);
    //

    //

    finalObj.addedBy = Number(token.userId);
    finalObj.anaesTypeId = data.anaes ? data.anaes.id : 0;
    finalObj.anaesthetist1Id = 0; //

    let DateIsValidAns = isValid(data.anesthesistArrivalTime);
    finalObj.anasthetistArrivalTime = DateIsValidAns
      ? format(data.anesthesistArrivalTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.anesthesistArrivalTime.concat("Z"); //add time state

    finalObj.anesthesiaRecord = `anesthetiaType-${
      data.anesthetiaType || ""
    },bp-${data?.bp || ""},pulseType-${
      data?.["pulseType-A"] || ""
    },startingTime-${
      data?.startingTime?.$d ? data.startingTime?.$d : new Date()
    },endingTime-${data.endingTime?.$d ? data.endingTime?.$d : new Date()}`; ///

    finalObj.antibioticGivenAtOTId =
      data.antibioticGivenAtOt !== null &&
      data.antibioticGivenAtOt !== undefined
        ? data.antibioticGivenAtOt
        : 0; //

    let DateIsValidAgot = isValid(data.antibioticGivenAtOtTime);
    finalObj.antibioticGivenAtOTTime = DateIsValidAgot
      ? format(data.antibioticGivenAtOtTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.antibioticGivenAtOtTime.concat("Z"); //add time state

    finalObj.antibioticGivenAtWardId =
      data.antibioticGivenAtWard !== null &&
      data.antibioticGivenAtWard !== undefined
        ? data.antibioticGivenAtWard.id
        : 0; //

    let DateIsValidAgw = isValid(data.antibioticGivenAtWardTime);
    finalObj.antibioticGivenAtWardTime = DateIsValidAgw
      ? format(data.antibioticGivenAtWardTime, "HH:mm:ss.000'Z'")
      : data?.antibioticGivenAtWardTime?.concat("Z"); //add time state

    finalObj.cashCounterId = Number(token.cashCounterId);
    finalObj.classId = patientInfo?.classId;
    finalObj.companyId = patientInfo?.CompanyId;

    let finalDoctArr = [...doctorDetailsArr, ...deletedDoctorData];
    let finalEmpArr = [...empDetailsArr, ...deletedEmpData];

    // finalObj.doctorListDtos = finalDoctArr.map((item) => ({
    //   doctorId: Number(item.DoctorId),
    //   classificationId: Number(item.DoctorTypeId),
    //   surgeryId:
    //     item.surgeryId !== null
    //       ? Number(item.surgeryId || item.SurguryId)
    //       : Number(0),
    //   doctorShareAmount: Number(item["Doctor Charges"]),
    //   otDoctorDetailsId:
    //     item.otDoctorDetailsId !== undefined ? item.otDoctorDetailsId : 0,
    //   isDelete: item.isDelete || false,
    // }));

    let drArr = finalDoctArr.map((item) =>
      item.DoctorId && Array.isArray(item.DoctorId)
        ? item.DoctorId.map((obj) => ({
            doctorId: Number(obj.id),
            classificationId: Number(item.DoctorTypeId),
            surgeryId:
              item.surgeryId !== null
                ? Number(item.surgeryId || item.SurguryId)
                : Number(0),
            doctorShareAmount: Number(item["Doctor Charges"]),
            otDoctorDetailsId:
              item.otDoctorDetailsId !== undefined ? item.otDoctorDetailsId : 0,
            isDelete: item.isDelete || false,
            arrivalTime: isValid(item["Arrival Time"])
              ? format(item["Arrival Time"], "HH:mm:ss.sss")
              : item["Arrival Time"]?.split("T")?.[1],
          }))
        : {
            doctorId: Number(item.DoctorId),
            classificationId: Number(item.doctorTypeId || item.DoctorTypeId),
            surgeryId:
              item.surgeryId !== null
                ? Number(item.surgeryId || item.SurguryId)
                : Number(0),
            doctorShareAmount: Number(item["Doctor Charges"]),
            otDoctorDetailsId:
              item.otDoctorDetailsId !== undefined ? item.otDoctorDetailsId : 0,
            isDelete: item.isDelete || false,
            arrivalTime: isValid(item["Arrival Time"])
              ? format(item["Arrival Time"], "HH:mm:ss.sss")
              : item["Arrival Time"]?.split("T")?.[1],
          }
    );

    finalObj.doctorListDtos = drArr.flat();
    //  empDetails Arr

    finalObj.otDetailsEmployeeDtoList = finalEmpArr.map((item) => ({
      employeeId: item.EmployeeId,
      otEmployeeDetailsId:
        item.otEmployeeDetailsId !== undefined ? item.otEmployeeDetailsId : 0,

      surgeryId:
        item["SurgeryId"] !== undefined ? Number(item["SurgeryId"]) : 0,
      isDelete: item.isDelete || false,
    }));

    //
    let DateIsValided = isValid(data.actualEndTime);
    finalObj.endTime = DateIsValided
      ? format(data.actualEndTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.actualEndTime.concat("Z"); //add time state
    //

    finalObj.isEmergency = Number(data?.otCaseType) > 0 ? true : false;
    finalObj.manPower = data.manPower; //is number ?
    finalObj.nurseId = data.nurse ? data.nurse.id : 0;
    finalObj.opdIpdExternal = PatientTypeRadio || patientInfo?.OpdIpd;

    console.log(
      "patientInfo",
      patientInfo,
      patientInfo.visitId,
      PatientTypeRadio || patientInfo?.OpdIpd
    );

    finalObj.opdIpdExternalId =
      PatientTypeRadio > 0 || patientInfo?.OpdIpd > 0
        ? patientInfo?.admissionId
        : patientInfo.visitId;
    finalObj.otCategory = OtCategoryData !== null ? OtCategoryData.id : 0;
    // finalObj.otChargesDtoList = [0];
    finalObj.otConsumableChargeId = 0;

    finalObj.otDate = data.operationDate?.$d
      ? data.operationDate?.$d
      : new Date();

    finalObj.otReservationId = patientInfo?.OTReservationId
      ? patientInfo?.OTReservationId
      : 0; ///need it from patient info
    finalObj.otStatusId = data.otStatus ? data.otStatus.id : 0;
    finalObj.otType = OtTypeData !== null ? OtTypeData.id : 0;

    let DateIsValidOtcs = isValid(data.otCleaningEndTime);
    finalObj.otcleaningEndTime = DateIsValidOtcs
      ? format(data.otCleaningEndTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.otCleaningEndTime.concat("Z"); //add time state

    let DateIsValidOtce = isValid(data.otCleaningStarTime);

    finalObj.otcleaningStartTime = DateIsValidOtce
      ? format(data.otCleaningStarTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.otCleaningStarTime.concat("Z"); //add time state

    // instrumentsData

    finalObj.otherDetailsDtoList = instrumentsData.map((item) => ({
      chargeId: 0,
      qty: item.Qty,
      rate: item.Rate,
      serviceId: item.ServiceId || 0,
      totalAmt: item.NetAmount,
      tariffServiceId: item.TariffId,
      otOtherDetailsId:
        item.otOtherDetailsId !== undefined ? item.otOtherDetailsId : 0,
      isDelete: item.isDelete,
    }));

    let DateIsValidpta = isValid(data.patientArrivalTime);
    finalObj.patientArrivalTime = DateIsValidpta
      ? format(data.patientArrivalTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.patientArrivalTime.concat("Z"); //add time state

    finalObj.procedureTypeId = data.procedureType ? data.procedureType.id : 0;
    finalObj.remark = data.remark;
    finalObj.resultId = data.otResule ? data.otResule.id : 0;
    //finalObj.startTime = data.startTime?.$d ? data.startTime?.$d : new Date();/////

    let DateIsValidst = isValid(data.actualStartTime);
    finalObj.startTime = DateIsValidst
      ? format(data.actualStartTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.actualStartTime.concat("Z");

    finalObj.storeId = Number(token.storeId);
    finalObj.surgeon1Id = 0; ///

    let DateIsValid = isValid(data.surgeonArrivalTime);
    finalObj.surgeonArrivalTime = DateIsValid
      ? format(data.surgeonArrivalTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.surgeonArrivalTime.concat("Z"); //add time state

    //  sergeryServiceArr

    let finalSurgeryArr = [...sugeryDetailsData, ...deletedSergeryData];

    let sergeryServiceArr = finalSurgeryArr.map((item) => ({
      anaesCharges: item.aneasAmount,
      chargeId: item.ChargeId || 0,
      netAmt: item["Net Amt"],
      procedureTypeId: item.ProcedureTypeId || data.procedureType?.id,
      qty: Number(item.Qty) || 0,
      serviceId: item.ServiceId || item.SurguryId,
      tariffId: item.tariffId,
      otSurgeryDetailsId:
        item.otSurgeryDetailsId !== undefined ? item.otSurgeryDetailsId : 0,
      surgeonFee: Number(item.Rate),
      surgeonFeeCharged: 0,
      tariffServiceId: item?.TariffServiceId,
      totalSurgeonFee: 0,
      isDelete: item.isDelete || false,
      classId: item.classId,
    }));

    const nonZeroItemsSergeryService = sergeryServiceArr.filter(
      (item) => item.qty !== undefined && item.qty !== 0
    );

    finalObj.surgeryDetailsDtoList = nonZeroItemsSergeryService;
    finalObj.surgeryNotes = !!templateContent ? templateContent : "";
    finalObj.tariffId = patientInfo?.tariffId;
    finalObj.theaterId = TheaterData !== null ? TheaterData.id : 0;
    finalObj.theaterServiceId = 0;

    let DateIsValidAnsAdm = isValid(data.timeofAdministrationAnesthesia);
    finalObj.timeOfAdministrationAnesthesia = DateIsValidAnsAdm
      ? format(
          data.timeofAdministrationAnesthesia,
          "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
        )
      : data.timeofAdministrationAnesthesia.concat("Z"); //add time state

    let DateIsValidwi = isValid(data.wheelIn);
    finalObj.timeOfWheelIn = DateIsValidwi
      ? format(data.wheelIn, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.wheelIn.concat("Z"); //add time state

    let DateIsValidwo = isValid(data.wheelOut);
    finalObj.timeOfWheelOut = DateIsValidwo
      ? format(data.wheelOut, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.wheelOut.concat("Z"); //add time state

    finalObj.totalTime = data.totalTime || 0;
    finalObj.otHeaderId =
      patchData !== null && patchData.OTHeaderID !== undefined
        ? patchData.OTHeaderID
        : 0;

    /////

    var patientDocArray = [];
    for (let i = 0; i < patientdocs.length; i++) {
      let docId =
        patientdocs[i].ID !== undefined
          ? patientdocs[i].ID //Available @ Old Doc
          : 0; //Available @ new Doc

      let documentName = patientdocs[i].DocName; //Available @ new Doc
      //
      let arr =
        patientdocs[i].baseSixtyFourString !== undefined
          ? patientdocs[i].baseSixtyFourString.split(",")
          : [];
      let documentBase64 = arr[1]; //Available @ new Doc

      let isDeleted = patientdocs[i].isDelete;
      patientDocArray.push({
        docId,
        documentBase64,
        documentName,
        // documentPath,
        isDeleted,
      });
      data.patientdocs !== null
        ? (finalObj.otFileUploadDtoList = patientDocArray)
        : (finalObj.otFileUploadDtoList = null);
    }

    ////////////
    let implantArr = [];
    for (let obj of implantData) {
      let rate = parseInt(obj.Rate);
      let qty = parseInt(obj.Quantity);
      let chargeId = obj.ChargeId || 0;
      let surgeryId = obj?.SurgeryId || obj?.surgeryId;
      let isDelete = obj?.isDelete ? obj?.isDelete : false;
      implantArr.push({ rate, qty, chargeId, surgeryId, isDelete });
    }

    finalObj.pendingImplantsDto = {
      isCheck: data?.isPendingImplantChecked,
      isPendingImplants: true,
      otPendingImplantsListDtoList: implantArr,
      pendingImplantsRemark: data.implantRemark,
    };

    finalObj.otCharges = totalOtCharges;
    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = location?.state?.privilege;

    //////////// new keys added
    finalObj.adverseAnaesthesiaEvents = data.adverseAnaesthesiaEvents; //

    finalObj.adverseAnaesthesiaEventsReason =
      data.adverseAnaesthesiaEventsReason; //

    finalObj.anaesthesiaRelatedMortility = data.anaesthesiaRelatedMortility; //

    finalObj.isPlanCase = Number(data?.otCaseType) < 1 ? true : false;

    finalObj.otAntibioticRequestDtoList = antibioticAtOtArr.map((item) => ({
      antibioticGivenDateTime: item?.time,
      antibioticId: item.antibioticId,
    }));

    finalObj.planOfAnaesthesia = 0;

    finalObj.planOfAnaesthesiaModified = Number(data.planOfAnaesthesiaModified); //

    finalObj.reasonOfAnaesthesiaRelatedMortility =
      data?.reasonOfAnaesthesiaRelatedMortility; //

    finalObj.reasonOfDelay = data?.reasonOfOtDelay; //

    finalObj.reasonOfModificationOfAnaesthesia =
      data?.reasonOfModificationOfAnaesthesia; //

    finalObj.reasonOfUnplannedVentilationAnaesthesia =
      data?.reasonOfUnplannedVentilationAnaesthesia; //

    finalObj.scrubCirculatoryTech = 0;

    finalObj.unplannedVentilationAnaesthesia =
      data?.unplannedVentilationAnaesthesia; //

    finalObj.reExplorationReason = data.reExplorationReason || "";
    finalObj.reExploration = data.reExploration || false;

    finalObj.intraOpChangesOfSurgicalPlan =
      data?.intraOpChangesOfSurgicalPlan || false;

    finalObj.intraOpChangesOfSurgicalPlanReason =
      data?.intraOpChangesOfSurgicalPlanReason || "";

    finalObj.reasonOfSurgeryReschedule = data?.reasonOfSurgeryReschedule || "";
    finalObj.surgicalReschedule = data?.surgicalReschedule || false;

    finalObj.unplannedReturnToOtReason = data?.unplannedReturnToOtReason || "";
    finalObj.unplannedReturnToOt = data?.unplannedReturnToOt || false;

    finalObj.wardAntibioticRequestDtoList = antibioticAtWardArr.map((item) => ({
      antibioticGivenDateTime: item?.time,
      antibioticId: Number(item.antibioticId),
    }));
    finalObj.wardShiftedBy = data?.wardShiftedBy?.id || 0;

    let DateIsValidwst = isValid(data.wardShiftTime);
    finalObj.wardShiftTime = DateIsValidwst
      ? format(data.wardShiftTime, "HH:mm:ss.000")
      : data.wardShiftTime?.split("T")?.[1]; //add time state

    // Example for setting another property
    finalObj.otCharges = totalOtCharges;

    ///new
    let DateIsValidSignInTime = isValid(data.signInTime);
    let DateIsValidInductionTime = isValid(data.inductionTime);
    let DateIsValidSignOutTime = isValid(data.signOutTime);
    let DateIsValidIncisionTime = isValid(data.incisionTime);
    let DateIsValidTimeOutTime = isValid(data.timeOutTime);
    let DateIsValidSutureTime = isValid(data.sutureTime);

    finalObj.signInTime = DateIsValidSignInTime
      ? format(data.signInTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.signInTime.concat("Z");

    finalObj.inductionTime = DateIsValidInductionTime
      ? format(data.inductionTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.inductionTime.concat("Z");

    finalObj.signOutTime = DateIsValidSignOutTime
      ? format(data.signOutTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.signOutTime.concat("Z");

    finalObj.incisionTime = DateIsValidIncisionTime
      ? format(data.incisionTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.incisionTime.concat("Z");

    finalObj.timeOutTime = DateIsValidTimeOutTime
      ? format(data.timeOutTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.timeOutTime.concat("Z");

    finalObj.sutureTime = DateIsValidSutureTime
      ? format(data.sutureTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data.sutureTime.concat("Z");

    finalObj.intraOpExtraItemList = data?.intraOpExtraItemList || "";
    finalObj.extrubation = data?.extrubation || "";

    ////////////
    setOpenBackdrop(true);
    console.log("finalObj", finalObj);
    /////

    saveOtDetails(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          // navigate("/ipd/admission");
          successAlert(res.message);
          setTabValue(0);
          // methods.reset();
          setOpenBackdrop(false);
          // setSergeryServiceData([]);
          // setPatientInfo(null);
          // setInstrumentsData([]);
          // setPatientdocs([]);
          // setDoctorDetailsArr([]);
          // setEmpDetailsArr([]);
          // setSugeryDetailsData([]);

          // resetField("searchPatient"); //clear search

          handleResetForm();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  };

  ///data retrive From reservation

  useEffect(() => {
    if (AgainstReservation && patientInfo !== null) {
      let rsertvationId = patientInfo?.OTReservationId;

      // retrive Doctor list
      rsertvationId &&
        otReservationDetails(rsertvationId)
          .then((response) => response.data)
          .then((res) => {
            let updatedDoctorDtls = res.result.reservationDoctor.map(
              ({ ArrivalTime, ...item }) => {
                return {
                  ...item,
                  "Doctor Charges": Number(0),
                  "Arrival Time": ArrivalTime
                    ? `1900-01-01T${ArrivalTime}.000`
                    : null,
                };
              }
            );
            let updatedSurgeryDtls = res.result.reservationSurgeryDetails.map(
              (item) => {
                return { ...item, Qty: Number(0), isDelete: false };
              }
            );

            setDoctorDetailsArr(updatedDoctorDtls);
            setEmpDetailsArr(res.result.reservationEmployee);
            setSugeryDetailsData(updatedSurgeryDtls);
            setReservedSurgeries(res.result.reservedSurgery);
            setSugeryEquipmentsData(res.result?.reservedEquipment);

            // let minutes = differenceInMinutes(
            //   new Date(`1900-01-01T${res.result.EndTime}`),
            //   new Date(`1900-01-01T${res.result.StartTime}`)
            // );

            // setValue("totalTime", minutes);

            setValue("startTime", `1900-01-01T${res.result.StartTime}.000`);
            setValue("endTime", `1900-01-01T${res.result.EndTime}.000`);

            setValue(
              "actualStartTime",
              `1900-01-01T${res.result.StartTimePatch}.000`
            );
            setValue(
              "actualEndTime",
              `1900-01-01T${res.result.EndTimePath}.000`
            );

            setValue("operationDate", res.result.OTDate);
          });
    }
  }, [patientInfo, AgainstReservation]);

  ///calculate OT Charges
  useEffect(() => {
    let totalSergeryRate =
      sugeryDetailsData.length > 0 &&
      sugeryDetailsData
        .filter((item) => item.isDelete === false)
        ?.reduce((accumulator, currentvalue) => {
          return Number(accumulator) + Number(currentvalue["Net Amt"]);
        }, 0);

    let totalDoctorRate = doctorDetailsArr
      .filter((item) => item.DoctorTypeId !== 13) ///if doctor type not gynac(id=13) then onl doctor charges considered
      .reduce((accumulator, currentvalue) => {
        return (
          Number(accumulator) + (Number(currentvalue["Doctor Charges"]) || 0)
        );
      }, 0);

    let finalOTCharges = totalSergeryRate - (totalDoctorRate || Number(0));

    setTotalOtCharges(finalOTCharges);

    if (finalOTCharges < 0) {
      warningAlert("Please Verify OT Charges...");
    }
  }, [sugeryDetailsData, doctorDetailsArr]);

  const handleResetForm = () => {
    setTabValue(0);
    methods.reset();
    setPatientInfo(null);
    setInstrumentsData([]);
    setPatientdocs([]);
    setDoctorDetailsArr([]);
    setEmpDetailsArr([]);
    setSugeryDetailsData([]);
  };

  useEffect(() => {
    let surgeryDropdownData = sugeryDetailsData
      ?.filter((item) => !item.isdelete)
      .map((item) => {
        return {
          id: item["ServiceId"],
          label: item["Surgery Name"],
          value: item["Surgery Name"],
        };
      });
    setReservedSurgeries(surgeryDropdownData);
  }, [sugeryDetailsData]);

  // button and menu id
  // useEffect(() => {
  //   location?.state?.menuId &&
  //     fetchUserActionsByMenuId(location?.state?.menuId)
  //       .then((response) => response.data)
  //       .then((res) => {
  //
  //         setUserActions(res.result);
  //       });
  // }, []);

  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          OT Details
        </div>
        {/* //search */}
        <div className="grid grid-cols-4">
          <div className="col-span-3 flex items-center gap-3 ">
            <div className="flex md:justify-start  gap-2 items-center">
              {/* <p className="text-gray-700 font-semibold">Patient Type :</p> */}
              <div className="mt-1">
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={patientTypeArray}
                />
              </div>
            </div>
            <div
              className="ml-3"
              // onClick={(e) => {
              //
              //    handleResetForm();

              // }}
            >
              <CheckBoxField
                control={control}
                name="againstReservation"
                label="Against Reservation"
                style={{ paddingRight: 0, paddingLeft: 1 }}
              />
            </div>
            <div className="mt-1.5 w-96">
              <SearchDropdown
                name="patientSearch"
                control={control}
                searchIcon={true}
                placeholder="Search By Patient Name / MR.No./ Mobile No."
                handleInputChange={handleSearch}
                isClearable={true}
                dataArray={options}
                isDisabled={disableSearch}
                inputRef={{
                  ...register("patientSearch", {
                    onChange: (e) => {
                      if (e.target.value !== null) {
                        getPatientInfo(e.target.value.id, PatientTypeRadio);
                      } else {
                        handleResetForm();
                      }
                    },
                  }),
                }}
              />
            </div>
          </div>
          <div className="col-span-1 flex justify-end">
            {Actions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "save" ? (
                  <>
                    <div className="">
                      <CommonButton
                        className=" bg-customBlue text-white"
                        label="OT Details List"
                        onClick={() => {
                          navigate("/operationTheater/Details", {
                            state: {
                              menuId: location?.state?.previousMenuId,
                            },
                          });
                        }}
                      />{" "}
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>

        {/* patient Details */}
        <div className="mb-3">
          <fieldset
            className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
          >
            <div className=" grid  grid-cols-4 2xl:grid-cols-4  ">
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.patientName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">MR. No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.mrNo}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">IPD/OPD No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && (patientInfo.ipdNo || patientInfo.OPDNO)}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Issue No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {/*patientInfo && patientInfo.mrNo*/}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Gender</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.gender}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.ageYear}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Doctor</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.doctorName}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Bed No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.BedNo}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Bed Category</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.BedCategory}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Ward</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.Ward}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Admission Date</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.admissionDate}
                  </span>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        {patientInfo && patientInfo !== null ? (
          <FormProvider {...methods}>
            <form>
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <OtDetailsContext.Provider
                    value={{
                      menuId: location?.state?.menuId,
                      privilege: location?.state?.privilege,
                      OTHeaderID: patchData?.OTHeaderID,
                      patchData,
                      //
                      tabValue,
                      setTabValue,
                      isDisable,
                      employeeNameData,
                      setEmployeeNameData,

                      //surgeryDetails
                      sugeryDetailsData,
                      setSugeryDetailsData,
                      patientInfo,
                      PatientTypeRadio,
                      reservedSurgeries,
                      setReservedSurgeries,
                      totalOtCharges,
                      // setSurgeryArr,
                      setDeletedSergeryData,

                      //doctor/emp
                      doctorDetailsArr,
                      setDoctorDetailsArr,
                      setEmpDetailsArr,
                      empDetailsArr,
                      reservedSurgeries,
                      setDeletedDoctorData,
                      setDeletedEmpData,

                      //suregery notes
                      templateContent,
                      setTemplateContent,

                      //instruments
                      sugeryEquipmentsData,
                      instrumentsData,
                      setInstrumentsData,
                      PatientTypeRadio,
                      patientInfo,

                      //anesthetia
                      anesthetiaStartTime,
                      setAnesthetiaStartTime,
                      anesthetiaEndTime,
                      setAnesthetiaEndTime,

                      //file upload
                      patientdocs,
                      setPatientdocs,

                      //add implant
                      implantData,
                      setImplantData,
                      reservedSurgeries,

                      //antibiotic file
                      antibioticAtWardArr,
                      setAntibioticAtWardArr,
                      antibioticAtOtArr,
                      setAntibioticAtOtArr,
                    }}
                  >
                    <OtDetailsTabs />
                  </OtDetailsContext.Provider>
                  {/* <input type="submit" /> */}
                  <div className="flex justify-end">
                    <div className="">
                      <div className="flex gap-3 my-3">
                        <div>
                          <CommonButton
                            className=" border border-customBlue text-customBlue"
                            label="Previous"
                            onClick={() => {
                              setTabValue(tabValue - 1);
                            }}
                          />
                        </div>
                        {Actions.map((obj) => (
                          <>
                            {!obj.isAction && obj.action === "save" ? (
                              <>
                                <div>
                                  <CommonButton
                                    className="border border-customRed text-customRed"
                                    label="Reset"
                                    onClick={() => handleResetForm()}
                                  />
                                </div>
                                <div>
                                  <CommonButton
                                    className="bg-customGreen text-white"
                                    label="Save"
                                    onClick={methods.handleSubmit(onSubmit)}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        ) : null}
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default OtDetails;
