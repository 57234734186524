import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../common/components/FormFields/InputField";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import { DeleteOnIcon } from "../../../assets/icons/CustomIcons";
import InputArea from "../../../common/components/FormFields/InputArea";

let medicationHeaders = [
  {
    "Item Name": "",
    Dose: "",
    Route: "",
    Frequency: "",
    Instructions: "",
  },
];

const MlcAndMedications = (props) => {
  const {
    handOverTime,
    setHandOverTime,
    allMedicationData,
    setAllMedicationData,
    setDeletedMedicationData,
  } = props;
  // datetime
  // const [handOverTime, setHandOverTime] = useState(new Date());
  useEffect(() => {}, [handOverTime]);

  const handleChange = (newValue) => {
    setHandOverTime(newValue);
  };

  const { control, register } = useFormContext();

  const renderActions = (row, index) => {
    const handleDeleteRow = (row, rowIndex) => {
      let temp = [...allMedicationData];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === rowIndex ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });
      console.log(
        "allMedicationDataOut",
        allMedicationData,
        deletedItems,
        notDeletedItems
      );

      // Update the state with deleted items and allMedicationData
      setDeletedMedicationData((prev) => [...prev, ...deletedItems]);
      setAllMedicationData(notDeletedItems);
    };

    return (
      <>
        <div>
          {allMedicationData.length > 0 ? (
            //&&
            //allMedicationData.filter((item) => !item.isDelete).length
            <DeleteOnIcon
              title={"Delete"}
              onClick={() => handleDeleteRow(row, index)}
            />
          ) : null}
        </div>
      </>
    );
  };
  return (
    <div>
      <div className="mt-1">
        <CommonDynamicTableNew
          dataResult={
            allMedicationData.length > 0 &&
            allMedicationData.filter((item) => !item.isDelete).length > 0
              ? allMedicationData.filter((item) => !item.isDelete)
              : medicationHeaders
          }
          setAllMedicationData={setAllMedicationData}
          renderActions={renderActions}
          removeHeaders={["ptMedicationId", "isDelete", "opdMedicationId"]}
          tableClass="h-52"
          highlightRow={false}
        />
      </div>
      <hr />

      <div>
        <h1 className="text-black py-3 font-semibold">Nursing Hand Over</h1>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
          {/* Time Of Hand Over */}
          <div className="flex items-center gap-2 w-full   font-semibold text-sm py-1">
            <span className=" font-semibold w-36">Handed Over By</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="handedOverBy"
                  variant="outlined"
                  // label="Pulse Rate"
                  // placeholder="Pulse Rate"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>

          {/* Time Of Hand Over */}
          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
            <span className=" font-semibold w-36">Handed Over To</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <InputField
                  name="handedOverTo"
                  variant="outlined"
                  // label="Pulse Rate"
                  // placeholder="Pulse Rate"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </span>
            </div>
          </div>
          {/* Time Of Hand Over */}
          <div className="flex items-center gap-2 w-full  font-semibold text-sm py-1">
            <span className=" font-semibold w-36">Time Of Hand Over</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                <div className=" ">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      // label="Hand Over Time "
                      value={handOverTime}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          className="bg-white"
                          fullWidth
                          name="handOverTime"
                          size="small"
                          defaultValue=""
                          disabled={true}
                          inputFormat="dd/MM/yyyy"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          sx={{
                            svg: { color: "#0B83A5" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-3 pt-3">
        {/* PT belonging Documents */}
        <div className=" ">
          <InputArea
            control={control}
            name="ptBelongingDocuments"
            label="PT Belonging Documents "
            placeholder="PT Belonging Documents"
          />
        </div>
        {/* Special Instructions */}
        <div className=" ">
          <InputArea
            control={control}
            name="specialInstructions"
            label="Special Instructions "
            placeholder="Special Instructions"
          />
        </div>
      </div>
    </div>
  );
};

export default MlcAndMedications;
