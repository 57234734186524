import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

export const getAutoCharges = () => {
  return apiClient.get(
    `/configureAutoCharges/getConfiguredCharges`, { headers: authHeader() }
  );
};

export const autoSearchService = (searchString) => {
  // /api/services/getServicesSearch/{searchString}
    return apiClient.get(`/services/getServicesSearch/${searchString}`, {
      headers: authHeader(),
    });
  };

  export const saveSearchService = (saveObj) => {
    return apiClient.post(`/configureAutoCharges/configureNewAutoCharges`, saveObj, {
      headers: authHeader(),
    });
  };

  export const chargesRemoveFromAutoCharges = (serviceId) => {
    return apiClient.get(`/configureAutoCharges/chargeRemoveFromAutoCharges/${serviceId}`, {
      headers: authHeader(),
    });
  };

