import templateMaster from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const savePathTemplate = (postObj) => {
  return templateMaster.post(`/pathMasters/savePathTemplate`, postObj, {
    headers: authHeader(),
  });
};

export const getTemplateAutoComplete = (searchString) => {
  return templateMaster.get(
    `/pathMasters/templateAutoComplete/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const getTemplateDetails = (templateId, page, size) => {
  return templateMaster.get(
    `/pathMasters/templateDetails/${templateId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};

export const updatePathTempStatus = (templateId, status) => {
  return templateMaster.get(
    `/pathMasters/updatePathTempStatus/${templateId}/${status}`,
    {
      headers: authHeader(),
    }
  );
};
