import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import DischargeInstruction from "./DischargeInstruction";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import FollowUpDetails from "./FollowUpDetails";
import MedicationAdvices from "./MedicationAdvices";
import PrescriptionModal from "./MedicationPrescription";
import SpecificAdvices from "./SpecificAdvices";

let medicationHeaders = [
  {
    "Item Name": "",
    Dose: "",
    Route: "",
    Frequency: "",
    Instructions: "",
  },
];
const FollowUpInstructionTab = () => {
  const {
    allMedicationData,
    setAllMedicationData,
    //
    setDeletedMedicationData,
  } = React.useContext(DischargeSummaryContext);
  //
  const [expandPanal7, setExpandPanal7] = useState(true);
  const [expandPanal8, setExpandPanal8] = useState(true);
  const [expandPanal9, setExpandPanal9] = useState(true);
  const [expandPanal10, setExpandPanal10] = useState(true);
  const [expandPanal17, setExpandPanal17] = useState(true);
  //
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const handleOpenPrescriptionModal = () => setOpenPrescriptionModal(true);
  const handleClosePrescriptionModal = () => setOpenPrescriptionModal(false);
  //
  // const [allMedicationData, setAllMedicationData] = useState([]);
  ///
  const handleChangePanal7 = () => {
    setExpandPanal7(!expandPanal7);
  };
  const handleChangePanal8 = () => {
    setExpandPanal8(!expandPanal8);
  };
  const handleChangePanal9 = () => {
    setExpandPanal9(!expandPanal9);
  };
  const handleChangePanal10 = () => {
    setExpandPanal10(!expandPanal10);
  };
  const handleChangePanal17 = () => {
    setExpandPanal17(!expandPanal17);
  };
  ///
  const renderActions = (row, index) => {
    const handleDeleteRow = (row, rowIndex) => {
      let temp = [...allMedicationData];

      let updated = temp.map((item, arrIndex) =>
        arrIndex === rowIndex ? { ...item, isDelete: true } : item
      );

      let deletedItems = [];
      let notDeletedItems = [];

      updated.forEach((item) => {
        if (item.isDelete === true) {
          deletedItems.push(item);
        } else {
          notDeletedItems.push(item);
        }
      });
      console.log(
        "allMedicationDataOut",
        allMedicationData,
        deletedItems,
        notDeletedItems
      );

      // Update the state with deleted items and allMedicationData
      setDeletedMedicationData((prev) => [...prev, ...deletedItems]);
      setAllMedicationData(notDeletedItems);
    };

    return (
      <>
        <div>
          {allMedicationData.length > 0 ? (
            //&&
            //allMedicationData.filter((item) => !item.isDelete).length
            <DeleteOnIcon
              title={"Delete"}
              onClick={() => handleDeleteRow(row, index)}
            />
          ) : null}
        </div>
      </>
    );
  };

  ///
  return (
    <>
      {/* <h1 className="text-lg font-semibold pt-3">Follow-up Instruction</h1> */}
      <div className="pt-3">
        {/* Medication On Discharge */}
        <div className="grid lg:grid-cols-2 gap-3 pt-3">
          <div className="">
            <div>
              <Accordion
                expanded={expandPanal7}
                //onChange={handleChangePanal7}
                elevation={6}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#CFFAFE",
                  }}
                >
                  {/*<div className="flex w-full justify-between">
                    <p className="font-bold tracking-wide text-sm font-Poppins">
                      Medication On Discharge
                    </p>
                    <span>
                      <AddCircleIcon
                        sx={{ color: "#1e3a8a" }}
                        onClick={() => {
                          setOpenPrescriptionModal(true);
                        }}
                      />
                    </span>
                      </div>*/}
                  <div className="grid grid-cols-12 w-full">
                    <div
                      className="col-span-11 font-bold tracking-wide text-sm font-Poppins"
                      onClick={handleChangePanal7}
                    >
                      Medication On Discharge
                    </div>
                    <div className="flex justify-end">
                      <AddCircleIcon
                        sx={{ color: "#1e3a8a" }}
                        onClick={() => {
                          setOpenPrescriptionModal(true);
                        }}
                      />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-1">
                    <CommonDynamicTableNew
                      dataResult={
                        allMedicationData.length > 0 &&
                        allMedicationData.filter((item) => !item.isDelete)
                          .length > 0
                          ? allMedicationData.filter((item) => !item.isDelete)
                          : medicationHeaders
                      }
                      setAllMedicationData={setAllMedicationData}
                      renderActions={renderActions}
                      removeHeaders={[
                        "medicationId",
                        "isDelete",
                      ]}
                      tableClass="h-52"
                      highlightRow={false}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {/* Medication Advices */}
          <div className="">
            <div>
              <Accordion
                expanded={expandPanal8}
                onChange={handleChangePanal8}
                elevation={6}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#FFD9D4",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Medication Advices
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Medication Advices*/}
                  <MedicationAdvices />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {/* Specific Advices */}
          <div className="">
            <div>
              <Accordion
                expanded={expandPanal9}
                onChange={handleChangePanal9}
                elevation={6}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#EDE9FE",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Specific Advices
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* SpecificAdvices*/}
                  <SpecificAdvices />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {/* On Discharge Instruction */}
          <div className="">
            <div>
              <Accordion
                expanded={expandPanal10}
                onChange={handleChangePanal10}
                elevation={6}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#CCFBF1",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    On Discharge Instruction
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* DischargeInstruction*/}
                  <DischargeInstruction />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>

        {/* Follow-up Date */}
        <div className="pt-3">
          <div>
            <Accordion
              expanded={expandPanal17}
              onChange={handleChangePanal17}
              elevation={6}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
                sx={{
                  "&.Mui-expanded": {
                    marginBottom: "0.3rem",
                    minHeight: "40px",
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                  },

                  backgroundColor: "#FFCCFA",
                }}
              >
                <p className="font-bold tracking-wide text-sm font-Poppins">
                  Follow-up Date
                </p>
              </AccordionSummary>
              <AccordionDetails>
                {/* Follow-up Date*/}
                <FollowUpDetails />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      {openPrescriptionModal ? (
        <PrescriptionModal
          // allMedicationData={allMedicationData}
          // setAllMedicationData={setAllMedicationData}
          open={openPrescriptionModal}
          setOpen={setOpenPrescriptionModal}
          handleOpen={handleOpenPrescriptionModal}
          handleClose={handleClosePrescriptionModal}
          //   medicationData={medicationData}
          //   setMedicationData={setMedicationData}
        />
      ) : null}
    </>
  );
};

export default FollowUpInstructionTab;
