import { Box, Modal, Tooltip } from "@mui/material";

import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

import DropdownField from "../../../../common/components/FormFields/DropdownField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";

import {
  getPreviousConsumptionList,
  getPreviousIssueDetailsList,
  getConsumptionListPrint,
} from "./services/materialConsumptionServices";

import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";

import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  overflowY: "scroll",
  p: 2,
};

const defaultValues = {
  fromDate: new Date(),
  toDate: new Date(),
  department: null,
};

function InventoryMaterialConsumptionModal(props) {
  const { control, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [previousConsumptionList, setPreviousConsumptionList] = React.useState(
    []
  );
  const [previousIssueList, setPreviousIssueList] = React.useState([]);
  const [ItemConstIdValue, setItemConstIdValue] = React.useState(null);

  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  React.useEffect(() => {
    previousConsumptionListSearchBtnHandler();
  }, []);

  React.useEffect(() => {
    console.log("previousConsumptionList is ", previousConsumptionList);
  }, [previousConsumptionList]);

  //function to return the date in yyyy-MM-DD format
  function getRequiredDateVal(inputDateVal) {
    let requiredDate = null;

    let objectDate = new Date(inputDateVal);

    let day = String(objectDate.getDate());

    if (day.length === 1) {
      day = `0${day}`;
    }

    let month = objectDate.getMonth();

    month = month + 1;

    month = String(month);

    if (month.length === 1) {
      month = `0${month}`;
    }

    let year = objectDate.getFullYear();

    requiredDate = `${year}-${month}-${day}`;

    if (requiredDate.length === 10) {
      return requiredDate;
    } else {
      return null;
    }
  }

  function previousConsumptionListSearchBtnHandler() {
    let userInfoString = localStorage.getItem("userInfo");

    let userInfoObj = JSON.parse(userInfoString);

    console.log("userInfoObj inside the empty useEffect is ", userInfoObj);

    let storeIdVal = userInfoObj.storeId;

    let storeObj = {
      id: storeIdVal,
      value: userInfoObj.storeName,
      label: userInfoObj.storeName,
    };

    setValue("department", storeObj);

    let previousConsumptionListObj = {
      fromDate: null,
      storeId: storeIdVal,
      toDate: null,
    };

    console.log("previousConsumptionListObj is ", previousConsumptionListObj);

    let fromDateValue = getValues("fromDate");
    let toDateValue = getValues("toDate");

    fromDateValue = getRequiredDateVal(fromDateValue);

    toDateValue = getRequiredDateVal(toDateValue);

    if (fromDateValue) {
      let d = new Date(fromDateValue);
      d = d.toISOString();
      previousConsumptionListObj.fromDate = d;
    }

    if (toDateValue) {
      let d = new Date(toDateValue);
      d = d.toISOString();
      previousConsumptionListObj.toDate = d;
    }

    setPreviousIssueList([]);
    setItemConstIdValue(null);

    getPreviousConsumptionList(previousConsumptionListObj).then((response) => {
      console.log("The previous consumption list is ", response);

      setPreviousConsumptionList(response.data.result);
    });
  }

  function populatePreviousIssueList(itemConstIdVal) {
    console.log(
      "itemConstIdVal inside populatePreviousIssueList function is ",
      itemConstIdVal
    );

    getPreviousIssueDetailsList(itemConstIdVal)
      .then((response) => {
        console.log("previous issue details list is ", response);
        setPreviousIssueList(response.data.result);
      })
      .catch((error) => {
        console.log("The error object is ", error);
      });
  }

  const Actions = [
    {
      id: 0,
      action: "checkbox",
      isAction: true,
    },
    {
      id: 1,
      action: "print",
      isAction: true,
    },
  ];

  //function to close the printing modal
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  //function to open the printing modal
  const handleOpenPrintModal = (itemConsumptionId) => {
    console.log(
      "itemConsumptionId inside handleOpenPrintModal is ",
      itemConsumptionId
    );
    // print service
    getConsumptionListPrint(itemConsumptionId).then((response) => {
      setOpenPrintModal(true);

      setUrlforPrint(response);
    });
  };

  function renderActions(singleDataObj) {
    return (
      <div className="flex items-center justify-start gap-1 ">
        {Actions.map((singleActionObj) => (
          <>
            {singleActionObj.action === "checkbox" &&
            singleActionObj.isAction === true ? (
              <input
                className="w-4 h-4"
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    let ItemConsIdVal = singleDataObj.ItemConsId;
                    populatePreviousIssueList(ItemConsIdVal);
                    console.log(
                      "The singleDataObj after clicking is ",
                      singleDataObj
                    );
                    setItemConstIdValue(ItemConsIdVal);
                  }
                }}
                checked={
                  singleDataObj.ItemConsId === ItemConstIdValue ? true : false
                }
              />
            ) : null}

            {singleActionObj.action === "print" &&
            singleActionObj.isAction === true ? (
              <>
                <Tooltip title="Print previous consumption list">
                  <LocalPrintshopIcon
                    className="text-[#4B5563] mr-1 cursor-pointer"
                    onClick={() => {
                      console.log("The print icon has been clicked.");
                      console.log(
                        "The singleDataObj on the click of the print icon is ",
                        singleDataObj
                      );
                      console.log(
                        "The ItemConsumption Id is ",
                        singleDataObj.ItemConsId
                      );
                      let itemConsumptionId = singleDataObj.ItemConsId;
                      handleOpenPrintModal(itemConsumptionId);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  }

  return (
    <>
      <>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <CancelPresentationIconButton onClick={props.close} />
            <div className="p-2">
              <div>
                <div className="text-normal font-semibold text-center">
                  Consumption List
                </div>
                <form>
                  <div className="flex gap-2 my-2">
                    <div className="flex  gap-3">
                      <div className="xl:w-52">
                        <DatePickerFieldNew
                          control={control}
                          name="fromDate"
                          label="From Date"
                          value={new Date()}
                          onChange={(newValue) => {
                            setValue("fromDate", newValue);
                          }}
                          // disableFuture={true}
                          inputFormat="dd-MM-yyyy"
                        />
                      </div>
                      <div className="xl:w-52">
                        <DatePickerFieldNew
                          name="toDate"
                          control={control}
                          label="To Date"
                          value={new Date()}
                          onChange={(newValue) => {
                            setValue("toDate", newValue);
                          }}
                          // disableFuture={true}
                          inputFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                    <div className="xl:w-96 lg:w-60">
                      <DropdownField
                        control={control}
                        name="department"
                        label="Department"
                        placeholder="Department"
                      />
                    </div>

                    <div className="flex gap-4">
                      <CommonButton
                        searchIcon
                        className="bg-customBlue text-white"
                        onClick={() => {
                          console.log("SearchIconButton has been clicked");
                          previousConsumptionListSearchBtnHandler();
                        }}
                      />

                      <CommonButton
                        label="Reset"
                        className="border border-customRed text-customRed"
                        onClick={() => {
                          console.log("The reset button has been clicked");

                          if (previousConsumptionList.length > 0) {
                            setPreviousConsumptionList([]);
                          }

                          if (previousIssueList.length > 0) {
                            setPreviousIssueList([]);
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
                <hr className="w-full  border-t-2 border-customBlue" />

                {previousConsumptionList.length > 0 ? (
                  <>
                    <div className="flex justify-between items-center">
                      <label className="text-normal font-semibold ">
                        Previous Consumption List
                      </label>
                    </div>

                    <CommonDynamicTableNew
                      dataResult={previousConsumptionList}
                      renderActions={renderActions}
                      highlightRow={true}
                      tableClass={"rounded lg:h-52 md:h-72"}
                      removeHeaders={["ItemConsId"]}
                    />
                  </>
                ) : (
                  <div className="grid justify-center items-center row h-44">
                    <div className=" text-black">Data Not Available.</div>
                  </div>
                )}

                {previousIssueList.length > 0 ? (
                  <>
                    <div className="flex justify-between items-center">
                      <label className="text-normal font-semibold ">
                        Issue Details List
                      </label>
                    </div>

                    <CommonDynamicTableNew
                      dataResult={previousIssueList}
                      highlightRow={true}
                      tableClass={"rounded lg:h-52 md:h-72"}
                      removeHeaders={["ItemConsId"]}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </Box>
        </Modal>

        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}
      </>
    </>
  );
}

export default InventoryMaterialConsumptionModal;
