import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
//import PreviousReportsList from "./PreviousReportsList";

const CodeOutcomeArr = [
  {
    id: 0,
    label: "Resolved",
    value: "Resolved",
  },

  { id: 1, label: "UnResolved", value: "UnResolved" },
];

const IfUnresolvedArr = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },

  { id: 0, label: "No", value: "No" },
];



const OtherForms = () => {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();


  return (
    <div className=" mb-2">
      <div>
        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3">
          <div className="grid col-span-2 grid-cols-2 gap-3">
            {/* from Date */}
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="codeDate"
                label="Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>

            <div className="">
              <Controller
                control={control}
                name="codeTime"
                render={({ field: { value, onChange } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      label="Time"
                      value={value}
                      onChange={onChange}
                      disabled={false}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          // error={errors.codeTime}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: "14px",
                              position: "absolute",
                              top: "-2px",
                            },
                          }}
                          sx={{
                            svg: {
                              color: "#1e3a8a",
                              height: 22,
                              width: "100%",
                              marginRight: "16px",
                            },
                            backgroundColor: "white",
                            overflow: "visible",

                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-input": {
                                // border: 0,
                                fontSize: 14,
                                height: "18px",
                                width: "100%",

                                borderColor: "  ",
                                overflow: "hidden",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>
          <div className="">
            <InputField
              name="floor"
              label="Floor"
              variant="outlined"
              error={errors.floor}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="room"
              label="Room"
              variant="outlined"
              error={errors.room}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <InputField
              name="area"
              label="Area"
              variant="outlined"
              // error={errors.area}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="mockDrillOther"
              label="Mock Drill"
            />
          </div>
        </div>
        <div className="my-2">
          <InputArea
            control={control}
            name="cause"
            label="Cause"
            placeholder="Cause"
            //error={errors.cause}
          />
        </div>

        <hr className="my-3 bg-red-500" />

        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3 ">
          <div className="">
            <Controller
              control={control}
              name="timeOfArrival"
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    label="Time Of Arrival At Code Scene"
                    value={value}
                    onChange={onChange}
                    disabled={false}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        // error={errors.codeTime}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                            position: "absolute",
                            top: "-2px",
                          },
                        }}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              width: "100%",

                              borderColor: "  ",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>

          <div className="col-span-2">
            <InputField
              name="teamLead"
              label="Person who acted as team leader"
              variant="outlined"
              // error={errors.area}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="col-span-3">
            <InputArea
              control={control}
              name="respondingTeamMember"
              label="Responding Team Member"
              placeholder="Responding Team Member"
              //error={errors.cause}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-wrap items-center">
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Outcome Of Code :
            </p>
            <div className="mt-2">
              <RadioField
                label=""
                name="outcomeOfCode"
                control={control}
                dataArray={CodeOutcomeArr}
              />
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              If Unresolved Escalated To Administartion :
            </p>
            <div className="mt-2">
              <RadioField
                label=""
                name="ifUnresolved"
                control={control}
                dataArray={IfUnresolvedArr}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <InputArea
            control={control}
            name="escalatedToAdministartionDetails"
            label="If Escalated To Administartion Mention Details"
            placeholder="If Escalated To Administartion Mentoion Details"
            //error={errors.cause}
          />
        </div>
        <div className="mt-3">
          <InputArea
            control={control}
            name="briefNarative"
            label="Brief narrative on cause and outcome of code"
            placeholder="Brief narrative on cause and outcome of code"
            //error={errors.cause}
          />
        </div>
        <div className="mt-3">
          <InputArea
            control={control}
            name="comments"
            label="Team Leader / Night Supervisor / Night HOD Comments"
            placeholder="Team Leader/Night Supervisor/Night HOD Comments"
            //error={errors.cause}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherForms;
