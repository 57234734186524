import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import InputField from "../../../common/components/FormFields/InputField";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import { getBillStatusInfo } from "../../services/patientClearenceServices/PatientClearenceServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  //   minHeight: "80%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function BillStatusModal(props) {
  //
  var token = JSON.parse(localStorage.getItem("userInfo"));
  //
  const [billStatusDtls, setBillStatusDtls] = useState(null);

  const { open, handleClose, selctedPatient, handleSubmitAllowToGo } = props;

  const schema = billStatusDtls?.selfObj[0].PayableAmount
    ? yup.object().shape({
        remark: yup.string().nullable().required(),
      })
    : yup.object().shape({
        remark: yup.string().nullable().notRequired(),
      });


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      remark: "",
    },
  });
  const {
    formState: { errors },
    control,
  } = methods;

  useEffect(() => {
    getBillStatusInfo(selctedPatient?.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setBillStatusDtls(res.result);
      })
      .catch((res) => {
        errorAlert(res.message);
      });
  }, []);

  const onSubmit = (data) => {
    handleSubmitAllowToGo(data);
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
            <CancelPresentationIconButton
              onClick={(e) => {
                handleClose();
                e.preventDefault();
              }}
            />
            <form>
              <div>
                <div className="my-2">
                  <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                    Bill Status
                  </span>
                </div>
                {/* patientInfo */}
                <fieldset
                  className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
                >
                  <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Patient Name</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.PatientName}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">MR. No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.MRNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">IPD. No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.IpdNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">
                        Admission Date
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.AdmDate}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Gender</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.Gender}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Age</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.AgeYear}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Department</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.Department}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Doctor</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls?.doctor}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Ward</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.Ward}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Bed Category</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.BedCategory}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Bed No.</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.BedNo}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Tariff</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.Tariff}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">
                        Patient Category
                      </span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.PatientCategory}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                      <span className=" font-semibold w-28">Company</span>
                      <div className="flex space-x-2 items-center">
                        <span>:</span>
                        <span className="text-gray-700 font-normal">
                          {billStatusDtls && billStatusDtls.Company}
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className={`mt-3 grid grid-cols-1 lg:grid-cols-2 gap-3`}>
                  {/* card-1 */}
                  <div className={`border shadow rounded `}>
                    {/* 
                  
                  ${
                    billStatusDtls?.CompanyId <= 1
                      ? "col-span-1 lg:col-span-2"
                      : "col-span-1"
                  }  */}
                    <div className="flex justify-between items-center bg-[#FFF6E1] py-2 px-3">
                      <h1 className="w-[59px] h-[15px] text-cyan-600 text-[17px] font-semibold">
                        Self
                      </h1>

                      <h1>
                        <span className="text-black text-[15px] font-medium">
                          Payable Amount :
                        </span>
                        <span className="text-red-600 text-lg font-semibold">
                          ₹{billStatusDtls?.selfObj[0].PayableAmount}
                        </span>
                      </h1>
                    </div>
                    <div className="p-1">
                      <fieldset className="w-full text-left rounded ">
                        <div className="py-2 grid  grid-cols-4 lg:grid-cols-2 gap-2 p-1 ">
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-28">
                              Self Bill Amount
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹ {billStatusDtls?.selfObj[0].SelfAmount}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-28">
                              Advance Amount
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹ {billStatusDtls?.selfObj[0].AdvanceAmount}
                              </span>
                            </div>
                          </div>
                          {/* <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                          <span className="text-black text-[14px] font-medium w-28">
                            Discount %
                          </span>
                          <div className="flex space-x-2 items-center">
                            <span className="font-semibold text-gray-600">
                              :
                            </span>
                            <span className="text-stone-500 text-[14px] font-medium">
                              ₹ {billStatusDtls?.selfObj[0].SelfAmount}
                            </span>
                          </div>
                        </div> */}
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-28">
                              Discount Amount
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹
                                {
                                  billStatusDtls?.selfObj[0]
                                    .TotalConcessionAmount
                                }
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-28">
                              Paid Amount
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹ {billStatusDtls?.selfObj[0].PaidAmount}
                              </span>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  {/* card-2 */}
                  {/* {billStatusDtls?.CompanyId > 1 && ( */}
                  <div className="border shadow rounded">
                    <div className="flex justify-between items-center bg-[#FFF6E1] py-2 px-3">
                      <h1 className="w-[59px] h-[15px] text-cyan-600 text-[17px] font-semibold">
                        Company
                      </h1>

                      <h1>
                        <span className="text-black text-[15px] font-medium">
                          Payable Amount :
                        </span>
                        <span className="text-red-600 text-lg font-semibold">
                          ₹ {billStatusDtls?.companyObj[0].PayableAmount}
                        </span>
                      </h1>
                    </div>
                    <div className="p-1">
                      <fieldset className="w-full text-left rounded ">
                        <div className="py-2 grid  grid-cols-4 lg:grid-cols-2 gap-2 p-1 ">
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-32">
                              Company Bill Amt
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹ {billStatusDtls?.companyObj[0].CompanyBillAmt}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-28">
                              Claimed Amt.
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹ {billStatusDtls?.companyObj[0].ClaimAmount}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 text-sm ">
                            <span className="text-black text-[14px] font-medium w-32">
                              Approved Amt.
                            </span>
                            <div className="flex space-x-2 items-center">
                              <span className="font-semibold text-gray-600">
                                :
                              </span>
                              <span className="text-stone-500 text-[14px] font-medium">
                                ₹ {billStatusDtls?.companyObj[0].ApprovedAmount}
                              </span>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  {/* )} */}

                  <div>
                    <span className="text-black text-base font-medium">
                      Total Bill Amt.
                    </span>
                    <span className="text-green-700 text-lg font-semibold">
                      : ₹ {billStatusDtls?.TotalBillAmount}
                    </span>
                  </div>
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-3">
                      <InputField
                        control={control}
                        name="remark"
                        label="Remark*"
                        error={errors.remark}
                      />
                    </div>
                    <div className="col-span-1 flex justify-end">
                      <CommonButton
                        label="Proceed"
                        className="bg-customGreen text-white"
                        onClick={methods.handleSubmit(onSubmit)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}
