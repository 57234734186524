import templateMaster from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const saveNonPathTemplate = (postObj) => {
  return templateMaster.post(`/nonPathMasters/saveNonPathTemplate`, postObj, {
    headers: authHeader(),
  });
};

export const getTemplateAutoComplete = (searchString) => {
  return templateMaster.get(
    `/nonPathMasters/templateAutoComplete/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const getTemplateDetails = (templateId, page, size) => {
  return templateMaster.get(
    `/nonPathMasters/templateDetails/${templateId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};

export const updateNonPathTempStatus = (templateId, status) => {
  return templateMaster.get(
    `/nonPathMasters/updateNonPathTempStatus/${templateId}/${status}`,
    {
      headers: authHeader(),
    }
  );
};
