import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EditOnIcon } from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { fetchPendingAntibioticFormList } from "../../services/pendingAntibioticFormServices/PendingAntibioticFormServices";

export default function PendingAntibioticListModal(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, setSelectedRow, menuId, privilege } = props;

  ///related to table

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "50%",
    width: {
      lg: "80%",
      sm: "80%",
    },
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      toDate: new Date(),
      fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate", new Date());

  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate]);

  const populateTable = (forPagination) => {
    const listObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      menuId: menuId,
      unitId: token.unitId,
    };

    fetchPendingAntibioticFormList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  };

  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {/* {userActions.map((actions) => (
             <>
               {actions.isAction && actions.action === "Status" ? (
                 <> */}
          <div className="">
            <EditOnIcon
              onClick={() => {
                setSelectedRow(row);
                handleClose();
              }}
              title="Edit"
            />
          </div>
          {/* </>
               ) : null}
             </>
           ))} */}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold ">
              Antibiotic Form List
            </div>

            <hr className="border mx-1  border-customBlue" />
            <form className="">
              <div className="grid lg:grid-cols-6 md:grid-cols-2 mb-3 gap-3 mt-4">
                {/* from Date */}
                <div className="">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={false}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
                {/* to Date */}
                <div className="">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={false}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>

              <div className="my-2">
                {dataResult.length > 0 ? (
                  <CommonDynamicTablePaginationNew
                    dataResult={dataResult}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    tableClass="lg:h-[350px] 2xl:h-[550px]"
                    removeHeaders={["ID"]}
                    // renderActions={renderActions}
                    highlightRow={false}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>

              <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Close"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
                {/* <CommonButton
                  label="Save"
                  className="bg-customGreen text-white"
                /> */}
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
