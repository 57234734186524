import { yupResolver } from "@hookform/resolvers/yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Checkbox, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import BatchSelectionModal from "./BatchSelectionModal";

///key press action
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function ItemDetailsTable(props) {
  const {
    setSelectedDrugData,
    selectedDrugData,
    openBatchModal,
    setOpenBatchModal,
    handleOpenBatchModal,
    handleCloseBatchModal,
    FocusBatchModal,
    selectedItemDtlsID,
    setSelectedItemDtlsID,
    //setTotalQuantity,
    // setTotalAmount,
    //
    batchInputFocus,
    //
    selectedDrug,

    //
    batchSelctedRowData,
    setBatchSelctedRowData,
    // onEnterFocusNext,
    //handleInputFocus,
    // inputRefThree,
    //
    batchInsideTableInput,
    setBatchInsideTableInput,
    setErrorToTblInput,
    selctedRowData,
    setSelctedRowData,
  } = props;

  //batch table data
  const [batchData, setBatchData] = useState(null);

  // Entered row id before open batch selection modal....i.e. for update batch number of only entered row data
  const [enteredId, setEnteredId] = React.useState(null);
  //for error input

  const [qtyChange, setQtyChange] = useState(0);

  //
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {},
  });

  const { control } = methods;

  /////

  // var arrLength = props.data.length;
  //add required field in arr
  useEffect(() => {
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].BatchNumber === undefined) {
        props.data[i].BatchNumber = "";
      }
      if (
        props.data[i].Quantity === undefined &&
        props.data[i].Quantity === null
      ) {
        props.data[i].Quantity = 0;
      }
      if (props.data[i].Amount === undefined) {
        props.data[i].Amount = 0;
      }
      if (props.data[i].IndentQty === undefined) {
        props.data[i].IndentQty = 0;
      }
      if (props.data[i].PendIssueQty === undefined) {
        props.data[i].PendIssueQty = 0;
      }

      setSelectedDrugData(props.data);
    }
  }, [props.data.length]);

  //
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };
  //

  //set rows object to table
  const [headers, setHeaders] = useState([]);
  React.useEffect(() => {
    if (props.data.length > 0) {
      const allHeaders = Object.keys(props.data[0]);

      const headersArr = removeHeaders(allHeaders, ["id"]);
      setHeaders(headersArr);
    }
  }, [props.data]);

  //
  const FocusQuantityInput = useRef(null);

  // //The focus Drug Table
  const focusQuantity = () => {
    if (FocusQuantityInput.current) {
      FocusQuantityInput.current.focus();
    }
  };

  ///keypress fuctionality code
  // const arrowUpPressed = useKeyPress("ArrowUp");
  // const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");

  //   //up
  //   useEffect(() => {
  //     if (arrowUpPressed) {
  //       if (
  //         (FocusBatchModal.current === undefined ||
  //           FocusBatchModal.current === null) &&
  //         (FocusDrugTable.current === undefined ||
  //           FocusDrugTable.current === null)
  //       ) {
  //         if (selectedItemDtlsID <= 0) {
  //           setSelectedItemDtlsID(arrLength - 1);
  //         } else if (selectedItemDtlsID !== 0) {
  //           setSelectedItemDtlsID(selectedItemDtlsID - 1);
  //         }
  //       }
  //     }
  //   }, [arrowUpPressed, FocusBatchModal]);

  //   //down
  //   useEffect(() => {
  //     if (arrowDownPressed) {
  //       if (
  //         (FocusBatchModal.current === undefined ||
  //           FocusBatchModal.current === null) &&
  //         (FocusDrugTable.current === undefined ||
  //           FocusDrugTable.current === null)
  //       ) {
  //         if (selectedItemDtlsID >= arrLength - 1) {
  //           setSelectedItemDtlsID(0);
  //         } else if (selectedItemDtlsID !== arrLength) {
  //           setSelectedItemDtlsID(selectedItemDtlsID + 1);
  //         }
  //       }
  //     }
  //   }, [arrowDownPressed, FocusBatchModal]);

  // enter
  React.useEffect(() => {
    focusQuantity();
    if (enterPressed === true) {
      setEnteredId(selectedItemDtlsID);
    }
  }, [enterPressed, selectedItemDtlsID]);

  useEffect(() => {
    let temp = [...props.data];

    let ErrArr = [];
    for (let item of temp) {
      let minimumValue =
        item.IndentId > 0
          ? Math.min(item.BalanceQty, item.PendIssueQty)
          : item.BalanceQty;
      if (Number(item.Quantity) < 1 || item.Quantity > minimumValue) {
        item.error = true;
        // setErrorToTblInput(true);
        ErrArr.push(true);
      } else {
        item.error = false;
        // setErrorToTblInput(false);
        ErrArr.push(false);
      }
    }
    setErrorToTblInput(ErrArr.some((item) => item === true));

    /////////////////

    // Case-1: Total quantity calculations on change
    // let totalQuantity = props.data.reduce(
    //   (total, item) => Number(total) + Number(item.Quantity || 0),
    //   0
    // );
    // setTotalQuantity(totalQuantity);

    // Case-2: Calculations for amount according to quantity
    let totalAmount = props.data.reduce((total, item) => {
      const quantity = item.Quantity || 0;
      const amount = quantity * (item.UnitMRP || item.MRP || 0);
      item.Amount = amount.toFixed(3);
      return total + amount;
    }, 0);
    // setTotalAmount(totalAmount);
  }, [qtyChange, props.data, selectedItemDtlsID]);

  // Batch onchange
  // const handleTableBatchInputChange = (e, index) => {
  //   props.data[index].BatchCode = e.target.value;
  // };

  // quantity onchange
  const handleTableQuantityChange = (e, index) => {
    let temp = [...props.data];
    temp[index].Quantity = e.target.value;
    props.setSelectedDrugData(temp);

    //if else to avoid NaN in calculations when the table input gets empty
    if (e.target.value !== "") {
      setQtyChange(parseInt(e.target.value));
    } else if (e.target.value === "") {
      setQtyChange(parseInt(0));
    }
  };
  //
  const deleteRow = (index) => {
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    props.setSelectedDrugData(newTemplateData);
    //
    handleTableQuantityChange();
  };
  //update batch number
  useEffect(() => {
    let temp = [...props.data];
    console.log("batchData", batchData, batchSelctedRowData, temp[enteredId]);

    if (
      temp[enteredId] &&
      // batchData !== null &&
      //batchData.length > 0 &&
      batchSelctedRowData !== null
    ) {
      temp[enteredId].BatchNumber = batchSelctedRowData.BatchCode;
      temp[enteredId].BalanceQty = batchSelctedRowData.BalQty;
      temp[enteredId].BatchExpDate = batchSelctedRowData.BatchExpDatePatch;

      temp[enteredId].UnitMRP = batchSelctedRowData.UnitMRP;
    }

    props.setSelectedDrugData(temp);
  }, [batchSelctedRowData, enteredId, batchData]);

  //////// on enter batch input open batch modal
  const handleKeypressForBatch = (e, items) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      setSelctedRowData(items);
      handleOpenBatchModal();
      setBatchInsideTableInput(true);
    }
  };

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.2,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Action
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch Number
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Balance Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        UnitMRP
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Rate
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Amount
                      </span>
                    </TableCell>

                    {/* <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Is Consumed
                      </span>
                  </TableCell>*/}

                    {/*<TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Is Packaged
                      </span>
                  </TableCell>*/}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data &&
                    props.data.map((items, index) => {
                      return (
                        <TableRow
                          ref={batchInputFocus}
                          key={index}
                          // tabIndex={-1}
                          hover={false}
                          sx={{
                            "& td": {
                              paddingY: 0.2,
                            },
                          }}
                        >
                          <TableCell>
                            <div className="flex">
                              <Tooltip title="Delete">
                                <DeleteOutlineOutlinedIcon
                                  className="text-customRed hover:cursor-pointer"
                                  onClick={() => deleteRow(index)}
                                />
                              </Tooltip>
                            </div>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Code}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.value}
                          </TableCell>

                          {/* //Batch no// */}
                          <TableCell className="whitespace-nowrap">
                            <div>
                              <input
                                disabled={
                                  items.BatchesRequired ||
                                  items.BatchesRequired === "Yes"
                                    ? false
                                    : true
                                }
                                onKeyDown={(e) =>
                                  handleKeypressForBatch(e, items)
                                }
                                onFocus={() => {
                                  setSelectedItemDtlsID(index);
                                }}
                                onBlur={() => {
                                  // setBatchData(null);
                                  setBatchSelctedRowData(null);
                                  // setSelctedRowData(null)
                                }}
                                // onChange={(e) =>
                                //   handleTableBatchInputChange(e, index)
                                // }
                                className={
                                  (items.BatchesRequired ||
                                    items.BatchesRequired === "Yes") &&
                                  items.BatchNumber === ""
                                    ? "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                                    : "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                }
                                value={
                                  // batchSelctedRowData?.length > 0 &&  batchData.length > 0 ?
                                  items.BatchNumber
                                  // : 0
                                }
                                onWheel={(event) => event.currentTarget.blur()}
                                name={`batchInput${[index]}`}
                              />
                            </div>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.BatchQty?.toFixed(2) ||
                              items?.BalanceQty?.toFixed(2)}
                          </TableCell>

                          {/* //qty// */}
                          <TableCell className="whitespace-nowrap">
                            <span ref={FocusQuantityInput}>
                              <input
                                className={
                                  items.error && items.error === true
                                    ? //items.IndentQty < items.Quantity
                                      "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                                    : "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                }
                                style={{
                                  border:
                                    items.BatchNumber !== "" &&
                                    items.Quantity > items.BalanceQty
                                      ? "1px solid #ef4444"
                                      : "",
                                }}
                                onFocus={() => {
                                  setSelectedItemDtlsID(index);
                                }}
                                onChange={(e) => {
                                  setQtyChange(0);
                                  if (
                                    (items.BatchesRequired === true ||
                                      items.BatchesRequired === "Yes") &&
                                    items.BatchNumber === ""
                                  ) {
                                    warningAlert(
                                      "Please Select Batch Before Enter Quantity..."
                                    );
                                  } else {
                                    handleTableQuantityChange(e, index);
                                  }
                                }}
                                value={items.Quantity}
                                type="number"
                                min="0"
                                onWheel={(event) => event.currentTarget.blur()}
                                onKeyDown={(e) => {
                                  if (
                                    e?.key === "-" ||
                                    e?.key === "+" ||
                                    e?.code === "ArrowUp" ||
                                    e?.code === "ArrowDown" ||
                                    e?.code === "NumpadDecimal"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                name={`Quantity${[index]}`}
                              />
                            </span>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.UnitMRP || items.MRP}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.MRP}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Amount}
                          </TableCell>

                          {/*<TableCell className="whitespace-nowrap">
                            <span
                            // onChange={(e) => {
                            //   items.isConsumed = e.target.checked;
                            // }}
                            >
                              <Checkbox
                                size="small"
                                className="border  border-gray-600 rounded bg-transparent"
                                type="checkbox"
                                color="primary"
                                checked={items.isConsumed ? true : false}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                name={`isConsumed${index}`}
                                onClick={(e) => {
                                  let temp = [...props.data];
                                  temp[index].isConsumed = e.target.checked;
                                  setSelectedDrugData(temp);
                                }}
                              />
                            </span>
                          </TableCell>*/}

                          {/*<TableCell className="whitespace-nowrap">
                            <span
                              onChange={(e) => {
                                items.isPackaged = e.target.checked;
                              }}
                            >
                              <CheckBoxField
                                control={control}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                name={`isPackaged${index}`}
                              />
                            </span>
                            </TableCell>*/}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>

      {/* Batch Modal */}
      {openBatchModal ? (
        <BatchSelectionModal
          open={openBatchModal}
          setOpen={setOpenBatchModal}
          handleOpen={handleOpenBatchModal}
          handleClose={handleCloseBatchModal}
          selctedRowData={selctedRowData}
          setSelctedRowData={setSelctedRowData}
          //
          FocusBatchModal={FocusBatchModal}
          //
          batchSelctedRowData={batchSelctedRowData}
          setBatchSelctedRowData={setBatchSelctedRowData}
          batchData={batchData}
          setBatchData={setBatchData}
          //
          selectedDrug={selectedDrug}
          batchInsideTableInput={batchInsideTableInput}
          // onEnterFocusNext={onEnterFocusNext}
          //inputRefThree={inputRefThree}
          //
          setSelectedDrugData={setSelectedDrugData}
          selectedDrugData={selectedDrugData}
          selectedItemDtlsID={selectedItemDtlsID}

          //
        />
      ) : null}
    </>
  );
}
