import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { BodyHandedOverConsentInamdarAndIpIssuePrint } from "../services/etuRegisterServices/etuRegisterServices";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";

export default function BodyHandOverPrint(props) {
  const printRef = useRef(null);
  const [dataResult, setDataResult] = React.useState();

  React.useEffect(() => {
    let printObj = {
      etuRegisterId: props.etuRegisterId || null,
      fromDate: props.selectedFromDate,
      toDate: props.selectedToDate,
    };
    BodyHandedOverConsentInamdarAndIpIssuePrint(printObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.result.data.length === 0) {
          warningAlert("Please Select Valid Date And Time..!");
        } else {
          setDataResult(res.result);
        }
      });
  }, []);

  let getMrno = dataResult?.data?.[0]?.IsMLC;

  let Mrno;

  if (getMrno === 1) {
    Mrno = true;
  }
  if (getMrno === 0) {
    Mrno = false;
  }
  let getPM = dataResult?.data?.[0]?.IsPM;

  let Pm;

  if (getPM === 1) {
    Pm = true;
  }
  if (getPM === 0) {
    Pm = false;
  }
  let getDC = dataResult?.data?.[0]?.IsDC;

  let DC;

  if (getDC === 1) {
    DC = true;
  }
  if (getDC === 0) {
    DC = false;
  }

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <div className=" grid grid-cols-1   ">
        <div className="">
          <h6 className="font-semibold text-[16px]  text-center">
            Body Handed Over Consent
          </h6>
          <h6 className="font-semibold text-[12px]  text-center">
            Inamdar Multispeciality Hospital
          </h6>
          <p className=" text-[12px]  text-center">
            Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
          </p>
          <div className=" flex justify-end gap-2 -mt-[48px] py-2"></div>
        </div>
      </div>

      <div className="grid grid-cols-[14%_1%_35%_14%_1%_35%]  gap-2 text-start p-1 border-t-[1px]  border-b-[1px] border-black">
        <div className=" font-semibold text-[11px] ite">MRNo</div>
        <div className="font-semibold text-[11px]">:</div>
        <div className="text-[11px]">{dataResult?.data?.[0]?.MRNo}</div>
        <div className=" font-semibold text-[11px]">Age</div>
        <div className="font-semibold text-[11px]">:</div>
        <div className="text-[11px]">{dataResult?.data?.[0]?.age}</div>
        <div className=" font-semibold text-[11px]">Patient Name</div>
        <div className="font-semibold text-[11px]">:</div>
        <div className="text-[11px]">{dataResult?.data?.[0]?.patientName}</div>
        <div className=" font-semibold text-[11px]">Address</div>
        <div className="font-semibold text-[11px]">:</div>
        <div className="text-[11px]">{dataResult?.data?.[0]?.Address}</div>
        <div className=" font-semibold text-[11px]">Gender</div>
        <div className="font-semibold text-[11px]">:</div>
        <div className="text-[11px]">{dataResult?.data?.[0]?.gender}</div>
      </div>
      <div>
        <div className=" grid grid-cols-2   pt-3">
          <div className="  gap-1 flex text-[14px] ">
            <div className="w-[162px] ">
              <span className="font-semibold ">Date </span>
            </div>
            <span className="font-semibold">:</span>
            <span>{dataResult?.data?.[0]?.date}</span>
          </div>
          <div className=" grid grid-cols-3 gap-2 text-[14px]">
            <div className="flex space-x-3">
              <h1 className="font-semibold">MLC </h1>
              <span className="font-semibold">:</span>
              <input type="checkbox" className="MLC" checked={Mrno} />
            </div>
            <div className="flex space-x-3">
              <h1 className="font-semibold">PM </h1>
              <span className="font-semibold">:</span>
              <input type="checkbox" className="PM" checked={Pm} />
            </div>
            <div className="flex space-x-3">
              <h1 className="font-semibold">DC </h1>
              <span className="font-semibold">:</span>
              <input type="checkbox" className="DC" checked={DC} />
            </div>
          </div>
        </div>
        <div className="flex py-2 gap-1  text-[14px] ">
          <div className="w-[162px]">
            <span className="font-semibold">Body Handed Over To</span>
          </div>
          <span className="font-semibold">:</span>
          <span>{dataResult?.data?.[0]?.BodyHandedOverTo}</span>
        </div>
        <div className="flex py-2 gap-1 text-[14px]">
          <div className="w-[162px]">
            <span className="font-semibold">Body Handed Over By</span>
          </div>
          <span className="font-semibold">:</span>
          <span>{dataResult?.data?.[0]?.BodyHandedOverBy}</span>
        </div>
        <div className="flex py-2 space-x-2 text-[14px]">
          <span className="font-semibold">Body Hand Over Remark</span>
          <span className="font-semibold">:</span>
          <span>{dataResult?.data?.[0]?.BodyHandoverRemark}</span>
        </div>
      </div>
      <div className="flex py-2 border-t-[1px] border-black space-x-3 text-[14px]">
        <span className="font-semibold">Patient Relative Name</span>
        <span className="font-semibold">:</span>
        <span>
          ____________________________________________________________
        </span>
      </div>

      <div className="grid grid-cols-2 gap-2 text-[14px] py-2">
        <div className="space-x-3">
          <span className="font-semibold">Relation</span>
          <span className="font-semibold">:</span>
          <span>_______________________________________</span>
        </div>
        <div className="space-x-3">
          <span className="font-semibold">Sign</span>
          <span className="font-semibold">:</span>
          <span>
            ____________________________________________________________
          </span>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 text-[14px] py-2">
        <div className="space-x-3">
          <span className="font-semibold">Contact No</span>
          <span className="font-semibold">:</span>
          <span>________________________________________</span>
        </div>
        <div className="space-x-3">
          <span className="font-semibold">Security Supervisor Sign</span>
          <span className="font-semibold">:</span>
          <span>
            ____________________________________________________________
          </span>
        </div>
      </div>
    </div>
  );
}
