import { Card, Rating } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getPatientStatisticsForDashboard } from "../../../services/feedbackdashbordservice/FeedBackDashbbordService";
// showing tha chart value
const data = [
  { name: "A", value: 5, color: "#FF2A2A" },
  { name: "B", value: 5, color: "#E99566" },
  { name: "C", value: 5, color: "#DDE032" },
  { name: "D", value: 5, color: "#AADF12" },
  { name: "E", value: 5, color: "#239F21" },
];

const PatientFedbackPieChartWithNeedle = () => {
  // state variables
  const [width, setWidth] = useState(200);
  const [patientData, setPatientData] = useState([]);
  const [chartValue, setChartValue] = useState(null);

  // local variables
  const sumValues = data?.map((cur) => cur.value).reduce((a, b) => a);

  // functions
  const activeSectorIndex = data?.map((cur, index, arr) => {
      const curMax = [...arr]
        .splice(0, index + 1)
        .reduce((a, b) => ({ value: a.value + b.value })).value;
      return chartValue > curMax - cur.value && chartValue <= curMax;
    })
    .findIndex((cur) => cur);

  const arrowData = [
    { value: Number(chartValue) },
    { value: 0 },
    { value: sumValues - Number(chartValue) },
  ];

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: width / 2,
  };

  const pieRadius = {
    innerRadius: "80%",
    outerRadius: (width / 2) * 0.4,
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + width * 0.03) * cos;
    const my = cy + (outerRadius + width * 0.03) * sin;
    return (
      <g>
        <g
          transform={`translate(${width / 2}, ${width / 2}) rotate(${
            360 - midAngle
          })`}
        >
          <path
            d="M5.60469 9.37139C2.82684 9.54267 0.429368 7.66264 0.276978 5.19354C0.124588 2.72445 2.27269 0.564139 5.05054 0.392861L63.1551 1.279L5.60469 9.37139Z"
            fill="#073763"
          />
        </g>
      </g>
    );
  };

  const calculateDynamicWidth = () => {
    const numberOfDataPoints = data?.length;
    const defaultWidth = 200;
    const multiplier = window.innerWidth >= 1536 ? 65 : 40;
    const dynamicWidth = numberOfDataPoints * multiplier;
    return dynamicWidth < defaultWidth ? defaultWidth : dynamicWidth;
  };
  // end

  // setting dynamic width
  useEffect(() => {
    const newWidth = calculateDynamicWidth();
    setWidth(newWidth);
  }, []);

  // call api for patient data
  useEffect(() => {
    getPatientStatisticsForDashboard()
      .then((response) => {
        if ((response.data.statusCode = 200)) {
          setPatientData(response.data.result.result);
          setChartValue(response.data.result.totalAvgInOneToFive.toFixed(2));
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  }, []);

  return (
    <Card
      elevation={2}
      sx={{
        borderRadius: "10px",
        width: "100%",
        border: "1px solid lightgray",
      }}
    >
      <div className="flex justify-between ">
        <div className="w-auto">
          <h1 className="p-2 font-semibold whitespace-nowrap w-[234.41px]">
            Patient Feedback
          </h1>
          <div className="text-center flex flex-col justify-center items-center">
            <PieChart width={width} height={width / 2 + 100}>
              <Pie
                activeIndex={activeSectorIndex}
                innerRadius="55%"
                data={data}
                blendStroke
                fill="#8884d8"
                {...pieProps}
              >
                {data?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={data[index].color} />
                ))}
              </Pie>
              <Pie
                stroke="none"
                activeIndex={1}
                activeShape={Arrow}
                data={arrowData}
                outerRadius={pieRadius.innerRadius}
                fill="none"
                {...pieProps}
              />
            </PieChart>
            <div className="text-center text-[#073763] font-semibold relative -top-[90px] left-[5px] text-sm">
              <Rating
                name="simple-controlled"
                value={chartValue}
                precision={0.5}
              />
              <h2>{chartValue}</h2>
            </div>
          </div>
        </div>
        <div className="grid gap-y-2 px-1 py-2 w-full border-l-2 2xl:w-[60%]">
          <div className="grid gap-y-2 px-1  w-full  2xl:w-full">
            {patientData?.length > 0 ? (
              patientData?.map((value, index) => {
                return (
                  <>
                    <div className="text-sm grid gap-y-2">
                      <h2 className="font-semibold">
                        Total Patients Feedback &nbsp; : &nbsp;
                        {value["Total Patients Feedback"]}
                      </h2>
                      <div
                        key={index}
                        className=" whitespace-nowrap   border rounded p-1  bg-[#e6ffe5] flex items-center space-x-[5px] "
                      >
                        <h4 className="text-black flex space-x-[5px]">
                          <span>
                            <FiberManualRecordIcon
                              className="text-[#239f21] text-sm"
                              fontSize="20"
                            />
                          </span>
                          <span>Highly Satisfied &nbsp; </span>
                        </h4>
                        <span className="text-black text-sm font-medium">
                          : &nbsp;{value["Highly Satisfied"]}
                        </span>
                      </div>
                      <div
                        key={index}
                        className=" whitespace-nowrap  border rounded p-1 bg-[#f3ffd0] flex items-center space-x-[57px]"
                      >
                        <h5 className="text-black flex space-x-1">
                          <span>
                            <FiberManualRecordIcon
                              className="text-[#AADF12]"
                              fontSize="20"
                            />
                          </span>
                          <span>Satisfied </span>
                        </h5>
                        <span className="text-black text-sm font-medium">
                          : &nbsp; {value["Satisfied"]}
                        </span>
                      </div>
                      <div
                        key={index}
                        className=" whitespace-nowrap  border rounded p-1 bg-[#feffd2] flex items-center space-x-[54px]"
                      >
                        <h4 className="text-black flex space-x-1">
                          <span>
                            <FiberManualRecordIcon
                              className="text-[#DDE032]"
                              fontSize="20"
                            />
                          </span>
                          <span>Average &nbsp;</span>
                        </h4>
                        <span className="text-black text-sm font-medium">
                          : &nbsp; {value["Average"]}
                        </span>
                      </div>
                      <div
                        key={index}
                        className="whitespace-nowrap  border rounded p-1 bg-[#ffebd2] flex items-center space-x-[75.5px]"
                      >
                        <h4 className="text-black flex space-x-1">
                          <span>
                            <FiberManualRecordIcon
                              className="text-[#E99566]"
                              fontSize="20"
                            />
                          </span>
                          <span>Poor &nbsp;</span>
                        </h4>
                        <span className="text-black text-sm font-medium">
                          : &nbsp; {value["Poor"]}
                        </span>
                      </div>
                      <div
                        key={index}
                        className=" whitespace-nowrap  border rounded p-1 bg-[#ffdbdb] flex items-center space-x-[30px]"
                      >
                        <h4 className="text-black flex space-x-1">
                          <span>
                            <FiberManualRecordIcon
                              className="text-[#FF2A2A]"
                              fontSize="20"
                            />
                          </span>
                          <span>Dissatisfied &nbsp;</span>
                        </h4>

                        <span className="text-black text-sm font-medium">
                          : &nbsp;{value["Dissatisfied"]}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <h2 className="text-center flex justify-center items-center text-gray-600 font-semibold text-sm">
                Patient Feedback Is Not Availabale
                <span className="animate-pulse tracking-wider">...</span>
              </h2>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PatientFedbackPieChartWithNeedle;
