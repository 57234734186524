import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";


//subGroup
export const fetchSubGroups = (groupId) => {
  return apiClient.get(`/otReservation/subGroup/${groupId}`, {
    headers: authHeader(),
  });
};

//Classes Applicable To
export const fetchClassesApplicableTo = (opdIpdBoth) => {
  return apiClient.get(`/serviceMaster/getClasses/${opdIpdBoth}`, {
    headers: authHeader(),
  });
};

//createService
export const createNewService = (finalObj) => {
  return apiClient.post(`/serviceMaster/createService`, finalObj, {
    headers: authHeader(),
  });
};

//searchService
export const fetchServices = (searchString) => {
  return apiClient.get(`/serviceMaster/searchService/${searchString}`, {
    headers: authHeader(),
  });
};

//searchService
export const fetchPatchDetails = (serviceId) => {
  return apiClient.get(`/serviceMaster/searchDetails/${serviceId}`, {
    headers: authHeader(),
  });
};

//searchService
export const fetchServiceList = (servicelistObj) => {
  return apiClient.post(`/serviceMaster/serviceList`, servicelistObj, {
    headers: authHeader(),
  });
};
//Group
export const fetchGroups = () => {
  return apiClient.get(`/otReservation/groups`, {
    headers: authHeader(),
  });
};

