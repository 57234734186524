import { TextField } from "@mui/material";
import {
  DesktopTimePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import InputArea from "../../../../common/components/FormFields/InputArea";

const FollowUpDetails = (props) => {
  const { control } = useFormContext();
  return (
    <div className="grid grid-cols-5 gap-3">
      
      <div className=" ">
      
        <DatePickerFieldNew
          control={control}
          name="followUpDate"
          label="Follow-Up Date"
          value={new Date()}
          disableFuture={false}
          disablePast={true}
          inputFormat="dd-MM-yyyy"
        />
      </div>
      <Controller
        control={control}
        name="followUpTime"
        render={({ field: { value, onChange } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopTimePicker
              label="FollowUp Time"
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  //error={errors.followUpTime}
                  //helperText={errors.followUpTime?.message}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  InputLabelProps={{
                    style: {
                      fontSize: "14px",
                      position: "absolute",
                      top: "-2px",
                    },
                  }}
                  sx={{
                    svg: {
                      color: "#1e3a8a",
                      height: 22,
                      width: "100%",
                      marginRight: "16px",
                    },
                    backgroundColor: "white",
                    overflow: "visible",

                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-input": {
                        // border: 0,
                        fontSize: 14,
                        height: "18px",
                        width: "100%",

                        borderColor: "  ",
                        overflow: "hidden",
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "14px",
                      },
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
      <div></div>
      {/* ///Follow-up With Doctor  /// */}
      <div className="col-span-2">
        <InputArea
          name="followUpWithDoctor"
          label="Follow-Up With Doctor"
          placeholder="Follow-Up With Doctor"
          variant="outlined"
          // error={errors.representativeAddress}
          control={control}
          disabled={false}
          inputProps={{ maxLength: 50 }}
        />
      </div>
    </div>
  );
};

export default FollowUpDetails;
