import { Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CallRejectedrow from "../assets/icons/CallRejectedrow";
import Callrejectedhieden from "../assets/icons/Callrejectedhieden";
import Completedcallscallmangemennt from "../assets/icons/Completedcallscallmangemennt";
import FillDetailshiden from "../assets/icons/FillDetailshiden";
import FillDetailsrow from "../assets/icons/FillDetailsrow";
import Filldetails from "../assets/icons/Filldetails";
import { Notconnected } from "../assets/icons/Notconnected";
import { Remainingcalls } from "../assets/icons/Remainingcalls";
import { Rescheduled } from "../assets/icons/Rescheduled";
import Reschedulehiden from "../assets/icons/Reschedulehiden";
import Reschedulerow from "../assets/icons/Reschedulerow";
import CommonButton from "../common/components/Buttons/CommonButton";
import CommonTransactionTable from "../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../common/components/ConfirmationModal";
import DatePickerFieldNew from "../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../common/components/FormFields/DropdownField";
import SearchBar from "../common/components/FormFields/SearchBar";
import {
  errorAlert,
  successAlert,
} from "../common/components/Toasts/CustomToasts";
import ConfirmationModalDatepicker from "./ConfirmationModalDatepicker";
import Filldetailsmodal from "./Filldetailsmodal";
import {
  callingDashboardss,
  getPostDischargePatientListSearch,
  getpashentInfodata,
  rejectCall,
  rescheduleCall,
  startcalling,
} from "./services/callingsearvices";
import { fetchUserActionsByMenuId } from "../commonServices/functionalityServices/FunctionalityServices";
// import Completedcalls from "../assets/icons/Completedcalls";

const datacalltype = [
  {
    id: null,
    label: "All",
    value: null,
  },
  {
    id: "Surgical",
    label: "Surgical",
    value: "Surgical",
  },
  {
    id: "Medical",
    label: "Medical",
    value: "Medical",
  },
];
const dataday = [
  {
    id: null,
    label: "All",
    value: null,
  },
  {
    id: "5",
    label: "5",
    value: "5",
  },
  {
    id: "30",
    label: "30",
    value: "30",
  },
  {
    id: "90",
    label: "90",
    value: "90",
  },
];

const datacallstatus = [
  {
    id: null,
    label: "All",
    value: null,
  },
  {
    id: "Ongoing",
    label: "Ongoing",
    value: "Ongoing",
  },
  {
    id: "Pending",
    label: "Pending",
    value: "Pending",
  },
  {
    id: "Not",
    label: "Not",
    value: "Not",
  },
  {
    id: "Rejected",
    label: "Rejected",
    value: "Rejected",
  },
  {
    id: "Rescheduled",
    label: "Rescheduled",
    value: "Rescheduled",
  },
];

export default function Todayscallingdashboard() {
  let location = useLocation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataResult, setdataResult] = useState([]);
  const [dataResultcount, setdataResultcount] = useState();
  const [pashenthistre, setpashenthistre] = useState();
  const [openfilldetails, setOpenfilldetails] = useState(false);
  const [rowdata, setrowdata] = useState();
  const [openreject, setopenreject] = useState(false);
  const [reject, setreject] = useState();
  const [count, setCount] = useState(0);
  const [datareschedule, setdatareschedule] = useState({
    postDischargeDetailsId: 0,
    rescheduleDate: "",
  });
  const [searchString, setSearchString] = useState("");
  const [searchBardata, setSearchBardata] = React.useState("");
  const [openReschedule, setopenReschedule] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [listingObject, setListingObject] = useState({
    id: searchString?.id ? searchString?.id : null,
    searchString: searchBardata !== "" ? searchBardata : "",
  });

  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  //

  const handleOpenfilldetails = () => setOpenfilldetails(true);
  const handleClosefilldetails = () => setOpenfilldetails(false);

  const defaultValues = {
    patientsearchbar: 0,
    callstatus: {
      id: null,
      label: "All",
      value: null,
    },
    callType: {
      id: null,
      label: "All",
      value: null,
    },
    callday: {
      id: null,
      label: "All",
      value: null,
    },
    fromDate: new Date(),
    toDate: new Date(),
  };

  const { control, watch, setValue, reset } = useForm({ defaultValues });

  let getcallstatus = watch("callstatus");
  let getcallType = watch("callType");
  let getcallday = watch("callday");
  let selectedFromDate = watch("fromDate");
  let selectedToDate = watch("toDate");

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  //   <>
  //     {actions.isAction === true ? (
  //       <>
  //         <div className="flex gap-2 cursor-pointer">
  //           {actions.action === "Cancel" ?     (
  //             <>
  //               {row.Opd_Ipd_External === 1 ? (
  //                 <Tooltip title="Cancel Order">
  //                   <IconButton
  //                     onClick={() => {
  //                       setSelectedRow1(row);
  //                       setCancelOrderConfirmation(true);
  //                       setPrivilege(actions.action);
  //                     }}
  //                   >
  //                     <HighlightOffIcon
  //                       sx={{ color: "#FF0000", height: "20px" }}
  //                     />
  //                   </IconButton>
  //                 </Tooltip>
  //               ) : (
  //                 <Tooltip>
  //                   <IconButton sx={{ cursor: "default" }}>
  //                     <HighlightOffIcon
  //                       sx={{
  //                         color: "#4B5563",
  //                         height: "20px",
  //                       }}
  //                     />
  //                   </IconButton>
  //                 </Tooltip>
  //               )}
  //             </>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       </>
  //     ) : null}
  //   </>

  const renderActions = (row) => {
    return (
      <div className="flex gap-1  pt-1">
        {userActions.map((actions, i) => (
          <div className="">
            {actions.action === "Reschedule" &&
              (row?.["Call Status"] !== "Do Not Call" ? (
                <div>
                  <Tooltip title="Reschedule">
                    <button
                      onClick={() => {
                        setrowdata(row);
                        setopenReschedule(true);
                      }}
                    >
                      <Reschedulerow />
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <Reschedulehiden />
              ))}
            {actions.action === "Create" &&
              (row?.["Call Status"] !== "Do Not Call" ? (
                <div className="">
                  <Tooltip title="Fill Details">
                    <button
                      onClick={() => {
                        handleOpenfilldetails();
                        pashentInfo(row);
                        daytodaycall(row);
                        setrowdata(row);
                      }}
                    >
                      <FillDetailsrow />
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <FillDetailshiden />
              ))}

            {actions.action === "Reject" &&
              (row?.["Call Status"] !== "Do Not Call" ? (
                <div>
                  <Tooltip title="Call Rejected">
                    <button
                      onClick={() => {
                        setopenreject(true);
                        setreject(row?.["post_discharge_details_id"]);
                      }}
                    >
                      <CallRejectedrow />
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <Callrejectedhieden />
              ))}
          </div>
        ))}
      </div>
    );
  };

  const rowBackgroundColor = (row, index) => {
    if (row?.["Discharge Type"] === "Lama") {
      return "#FFDFFA";
    } else if (row?.["Call Status"] === "Do Not Call") {
      return "#B7B7B7";
    } else {
      return "";
    }
  };

  const renderInput = (row, index, header) => {
    return (
      <>
        {header === "Call Status" && (
          <div
            className={`${
              row["Call Status"] === "Pending"
                ? `text-[#4744D6]`
                : row["Call Status"] === "Rejected"
                ? `text-[#FF2323]`
                : row["Call Status"] === "Do Not Call"
                ? `text-[#FF2323]`
                : row["Call Status"] === "Completed"
                ? `text-[#33B650]`
                : row["Call Status"] === "Rescheduled"
                ? `text-[#D4B927]`
                : row["Call Status"] === "Ongoing"
                ? `text-[#FF9417]`
                : ``
            }`}
          >
            {row["Call Status"]}
          </div>
        )}

        {header === "Call Type" && (
          <div
            className={`${
              row["Call Type"] === "Medical"
                ? `text-[#A5832D]`
                : row["Call Type"] === "Surgical"
                ? `text-[#14b8a6]`
                : row["Call Type"] === "Implant Removal"
                ? `text-[#2C50AD]`
                : ``
            }`}
          >
            {row["Call Type"]}
          </div>
        )}
      </>
    );
  };

  const handleClosereject = () => {
    if (openreject) {
      setopenreject(false);
    }
  };
  const handleCloseReschedule = () => {
    if (openReschedule) {
      setopenReschedule(false);
    }
  };

  const populateTable = (forPagination) => {
    let listobj = {
      searchId: searchString?.id ? searchString?.id : 0,
      callStatus: getcallstatus?.id ? getcallstatus?.id : null,
      callType: getcallType?.id ? getcallType?.id : null,
      callDay: getcallday?.id ? getcallday?.id : null,
      fromDate: selectedFromDate,
      page: !forPagination ? 0 : page,
      size: 10,
      toDate: selectedToDate,
    };
    callingDashboardss(listobj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setdataResult((prevData) => [...prevData, ...res.result.List]);
        } else {
          let updated = res.result.List.map(
            ({
              "Patient Name": patientName,
              "Call Day": callDay,
              "Call Status": callStatus,
              "Call Type": callType,
              Age,
              ...items
            }) => ({
              "Patient Name": patientName,
              Age,
              "Call Day": callDay,
              "Call Status": callStatus,
              "Call Type": callType,
              ...items,
            })
          );

          setdataResult(updated);
          setPage(0);
          setdataResultcount(res.result.TodaysCounts);
        }
        setCount(res.count);
      })
      .catch((error) => {
        errorAlert(error.respons?.data.message);
      });
  };

  const handleChange = (autoSearchString) => {
    setSearchBardata(autoSearchString);
    if (autoSearchString !== "") {
      getPostDischargePatientListSearch(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    } else if (autoSearchString === "") {
      setListingObject((listingObject) => ({
        ...listingObject,
        searchString: "",
      }));
    }
  };

  const autoSelectedValue = (value) => {
    if (value !== null) {
      setSearchString(value);
      setListingObject((listingObject) => ({
        ...listingObject,
        id: null,
      }));
    } else if (value === null) {
      setSearchString("");
      populateTable();
    }
  };

  const pashentInfo = (row) => {
    getpashentInfodata(row?.AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setpashenthistre(res.result);
      });
  };

  function daytodaycall(row) {
    startcalling(row?.["post_discharge_details_id"])
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        populateTable();
      });
  }

  function callreject() {
    rejectCall(reject)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleClosereject();
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
        handleClosereject();
      });
  }

  function callReschedule() {
    rescheduleCall(datareschedule)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        handleCloseReschedule();
        populateTable();
      })
      .catch((error) => {
        errorAlert(error.message);
        handleCloseReschedule();
      });
  }

  useMemo(() => {
    populateTable();
  }, [searchString?.id]);

  return (
    <div className="px-6 mt-12">
      <div className="text-center text-black font-bold text-xl my-3">
        Post Discharge
      </div>
      <div className=" grid xl:grid-cols-5  lg:grid-cols-3 grid-cols-2 gap-2 rounded-md ">
        <div className=" 2xl:p-2 font-Poppins bg-white border-[1px] rounded-md p-[1px] items-center">
          <div className=" mx-1 mt-1 font-semibold grid col-span-3  text-[14px] ">
            Calls Want To Done By Today
          </div>
          <div className=" my-2 mx-1 flex justify-between ">
            <div className="text-[#329DFF] font-semibold text-lg">
              {dataResultcount?.CallWantToDaneByToday}
            </div>
            <div>
              <Filldetails />
            </div>
          </div>
        </div>
        <div className=" 2xl:p-2 font-Poppins bg-white border-[1px] rounded-md p-[1px] items-center">
          <div className=" mx-1 mt-1 font-semibold grid col-span-3  text-[14px] ">
            Completed Calls
          </div>
          <div className=" my-2 mx-1 flex justify-between ">
            <div className="text-[#1EBB24] font-semibold text-lg">
              {dataResultcount?.CompletedCalls}
            </div>
            <div>
              <Completedcallscallmangemennt />
            </div>
          </div>
        </div>
        <div className=" 2xl:p-2 font-Poppins bg-white border-[1px] rounded-md p-[1px] items-center">
          <div className=" mx-1 mt-1 font-semibold grid col-span-3  text-[14px] ">
            Remaining Calls
          </div>
          <div className=" my-2 mx-1 flex justify-between ">
            <div className="text-[#C2A026] font-semibold text-lg">
              {dataResultcount?.PendingCalls}
            </div>
            <div>
              <Remainingcalls />
            </div>
          </div>
        </div>
        <div className=" 2xl:p-2 font-Poppins bg-white border-[1px] rounded-md p-[1px] items-center">
          <div className=" mx-1 mt-1 font-semibold grid col-span-3  text-[14px] ">
            Rescheduled
          </div>
          <div className=" my-2 mx-1 flex justify-between ">
            <div className="text-[#9C40C8] font-semibold text-lg">
              {dataResultcount?.RescheduledCalls}
            </div>
            <div>
              <Rescheduled />
            </div>
          </div>
        </div>
        <div className=" 2xl:p-2 font-Poppins bg-white border-[1px] rounded-md p-[1px] items-center">
          <div className=" mx-1 mt-1 font-semibold grid col-span-3  text-[14px] ">
            Rejected/ Not Connected
          </div>
          <div className=" my-2 mx-1 flex justify-between ">
            <div className="text-[#E03434] font-semibold text-lg">
              {" "}
              {dataResultcount?.RejectedCalls}
            </div>
            <div>
              <Notconnected />
            </div>
          </div>
        </div>
      </div>
      <div className=" grid lg:grid-cols-5 grid-cols-3 gap-3 mt-4">
        <div className=" col-span-2">
          <SearchBar
            control={control}
            name="patientsearchbar"
            placeholder="Search By Patient Name / Mobile Number"
            dataArray={options}
            isClearable={true}
            searchIcon={true}
            handleInputChange={handleChange}
            onChange={autoSelectedValue}
          />
        </div>
        <DatePickerFieldNew
          control={control}
          name="fromDate"
          label="From Date"
          value={new Date()}
          inputFormat="dd-MM-yyyy"
        />
        <DatePickerFieldNew
          control={control}
          name="toDate"
          label="To Date"
          value={new Date()}
          inputFormat="dd-MM-yyyy"
        />
        <DropdownField
          control={control}
          name="callstatus"
          placeholder="Call Status"
          dataArray={datacallstatus}
          isClearable={true}
        />
        <DropdownField
          control={control}
          name="callType"
          placeholder="Call Type"
          dataArray={datacalltype}
          isClearable={true}
        />
        <DropdownField
          control={control}
          name="callday"
          placeholder="Call Day"
          dataArray={dataday}
        />
        <div className=" flex gap-3">
          <CommonButton
            searchIcon={true}
            className="searchIcon bg-customBlue text-white"
            onClick={() => {
              populateTable();
            }}
          />
          <CommonButton
            type="button"
            label="Reset"
            className="resetButton border border-customRed text-customRed"
            onClick={() => {
              reset();
            }}
          />
        </div>
      </div>
      <div>
        {dataResult?.length > 0 ? (
          <CommonTransactionTable
            dataResult={dataResult}
            count={count}
            setCount={setCount}
            highlightRow={false}
            page={page}
            setPage={setPage}
            editableColumns={["Call Type", "Call Status"]}
            renderActions={renderActions}
            renderInput={renderInput}
            rowBackgroundColor={rowBackgroundColor}
            removeHeaders={[
              "post_discharge_details_id",
              "id",
              "AdmissionId",
              "is_patient_died",
              "PatientId",
            ]}
            populateTable={populateTable}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <div className="flex justify-center">
            <h3 className="flex justify-center my-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        )}
      </div>

      <Filldetailsmodal
        openfilldetails={openfilldetails}
        handleClosefilldetails={handleClosefilldetails}
        pashenthistre={pashenthistre}
        rowdata={rowdata}
        userActions={userActions}
        populateTable={populateTable}
      />

      <ConfirmationModal
        confirmationOpen={openreject}
        confirmationHandleClose={handleClosereject}
        confirmationSubmitFunc={callreject}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to reject this record ?"
        confirmationButtonMsg="Add"
      />
      <ConfirmationModalDatepicker
        setdatareschedule={setdatareschedule}
        rowdata={rowdata}
        confirmationOpen={openReschedule}
        confirmationHandleClose={handleCloseReschedule}
        callReschedule={callReschedule}
        datareschedule={datareschedule}
      />
    </div>
  );
}
