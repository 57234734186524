import React from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { useEffect } from "react";
import { useState } from "react";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "70%",
  height: "50%",
  p: 3,
  overflowY: "scroll",
};

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};
const HelpValuesModal = (props) => {
  const [selectedDrugRowID, setSelectedDrugRowID] = useState(0);

  ////code for key press output
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");

  var arrLength = props.helpValuesData?.length;

  //up arrow
  useEffect(() => {
    if (props.helpValuesModal === true) {
      if (arrowUpPressed) {
        if (selectedDrugRowID <= 0) {
          setSelectedDrugRowID(arrLength - 1);
        } else if (selectedDrugRowID !== 0) {
          setSelectedDrugRowID(selectedDrugRowID - 1);
        }
      }
    }
  }, [arrowUpPressed]);

  // down arrow
  useEffect(() => {
    if (props.helpValuesModal === true) {
      if (arrowDownPressed) {
        if (selectedDrugRowID >= arrLength - 1) {
          setSelectedDrugRowID(0);
        } else if (selectedDrugRowID !== arrLength) {
          setSelectedDrugRowID(selectedDrugRowID + 1);
        }
      }
    }
  }, [arrowDownPressed]);

  //enter
  React.useEffect(() => {
    if (enterPressed === true && props.helpValuesModal === true) {
      props.setSelectedHelpValue(null);
      if (selectedDrugRowID !== null && selectedDrugRowID !== undefined) {
        if (props.helpValuesData[selectedDrugRowID] !== undefined) {
          props.setSelectedHelpValue(props.helpValuesData[selectedDrugRowID]);
        }
        props.sethelpValuesModal(false);
      }
    }
  }, [enterPressed, selectedDrugRowID]);

  return (
    <div>
      {arrLength > 0 ? (
        <Modal
          open={props.helpValuesModal}
          onKeyDown={(e) => {
            if (e.keyCode === 116) {
              e.preventDefault();
            }
          }}
        >
          <Box sx={ModalStyle} className="space-y-3">
            <div className="flex justify-end">
              <CancelPresentationIconButton
                onClick={() => {
                  props.sethelpValuesModal(false);
                  props.setSelectedHelpValue(null);
                }}
              />
            </div>
            <div>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 10,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgrorequiredTableArrundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded h-max"
              >
                <Table
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    border: 1,
                    borderColor: "#e0e0e0",
                    paddingY: "scroll",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      <TableCell>Help Values</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.helpValuesData?.map((data, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              selectedDrugRowID === index ? "#FFC44B" : "",
                            // height: selectedDrugRowID === index ? "32px" : "",
                          }}
                          onClick={() => setSelectedDrugRowID(index)}
                        >
                          <TableCell>{data.label}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default HelpValuesModal;
