import React, { useEffect, useRef } from "react";
import { Checkbox } from "@mui/material";
import { PainAssessmentRowClickPrint } from "../services/cliinicalChartServices/painAssesmentService/PainAssesmentService";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import PainAssessmentScale from "../components/clinicalcarechart/painassessment/PainAssessmentScale.png";

export default function PrintPainAssesUseRowClick(props) {
  const [dataResult, setDataResult] = React.useState(null);

  const printRef = useRef(null);

  React.useEffect(() => {
    let obj = {
      id: props?.painAssesPrintId,
      admissionId: props?.admissionId,
      fromDate: null,
      fromTime: null,
      toDate: null,
      toTime: null,
    };
    PainAssessmentRowClickPrint(obj)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props.painAssesPrintId, props.admissionId]);

  let CheckboxData;
  let ICheckBox;
  let IICheckBox;
  let IIICheckBox;
  let IVheckBox;
  let VCheckBox;
  let VICheckBox;
  let VIICheckBox;
  let VIIICheckBox;
  let VIVCheckBox;
  let XCheckBox;

  if (dataResult?.PainAssessment["Pain Assessment"] === 0) {
    CheckboxData = true;
  } else {
    CheckboxData = false;
  }

  if (dataResult?.PainAssessment["Pain Assessment"] === 1) {
    ICheckBox = true;
  } else {
    ICheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 2) {
    IICheckBox = true;
  } else {
    IICheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 3) {
    IIICheckBox = true;
  } else {
    IIICheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 4) {
    IVheckBox = true;
  } else {
    IVheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 5) {
    VCheckBox = true;
  } else {
    VCheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 6) {
    VICheckBox = true;
  } else {
    VICheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 7) {
    VIICheckBox = true;
  } else {
    VIICheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 8) {
    VIIICheckBox = true;
  } else {
    VIIICheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 9) {
    VIVCheckBox = true;
  } else {
    VIVCheckBox = false;
  }
  if (dataResult?.PainAssessment["Pain Assessment"] === 10) {
    XCheckBox = true;
  } else {
    XCheckBox = false;
  }

  useEffect(() => {
    dataResult !== null && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <>
      <div ref={printRef}>
        <div className=" border-b-[1px] border-black">
          <div className="  flex justify-end">
            <InamdarHospiLogo />
          </div>
          <div className=" text-[14px] font-semibold border-t-[1px] border-b-[1px] border-black text-center">
            Pain Assessment
          </div>
          <div className=" grid grid-cols-[18%_1%_31%_18%_1%_31%]">
            <div className=" font-semibold text-[11px] ite">MR No.</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.MRNo}</div>
            <div className=" font-semibold text-[11px]">Patient Name</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.PatientName}</div>
            <div className=" font-semibold text-[11px]">Consultant</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.DoctorName}</div>
            <div className=" font-semibold text-[11px]">Bed No</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.BedNo}</div>
            <div className=" font-semibold text-[11px]">Admission Date</div>
            <div className="font-semibold text-[11px]">:</div>
            <div className="text-[11px]">{dataResult?.AdmissionDate}</div>
          </div>
        </div>
        <div className=" mx-5 my-5 center">
          <img src={PainAssessmentScale} alt="" />
        </div>
        <div className=" grid grid-cols-11 mx-5 my-10">
          <div>
            <Checkbox checked={CheckboxData} />
          </div>
          <div>
            <Checkbox checked={ICheckBox} />
          </div>
          <div>
            <Checkbox checked={IICheckBox} />
          </div>
          <div>
            <Checkbox checked={IIICheckBox} />
          </div>
          <div>
            <Checkbox checked={IVheckBox} />
          </div>
          <div>
            <Checkbox checked={VCheckBox} />
          </div>
          <div>
            <Checkbox checked={VICheckBox} />
          </div>
          <div>
            <Checkbox checked={VIICheckBox} />
          </div>
          <div>
            <Checkbox checked={VIIICheckBox} />
          </div>
          <div>
            <Checkbox checked={VIVCheckBox} />
          </div>
          <div>
            <Checkbox checked={XCheckBox} />
          </div>
        </div>
      </div>
    </>
  );
}
