

import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//
export const fetchToDoServices = (searchString) => {
  return apiClient.get(`serviceMaster/searchService/${searchString}`, {
    headers: authHeader(),
  });
};
//