import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";

export default function OldRecordModal(props) {
  const { handleClose, selectedRecordRow } = props;
  const token = JSON.parse(localStorage.getItem("userInfo"));
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
            Oxygen Ventilation All Record
          </div>
          <hr className="border mx-1  border-customBlue" />
          <div className="m-3">
            <div>
              {!!selectedRecordRow && selectedRecordRow.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={selectedRecordRow}
                  tableClass="h-96 xl:h-[350px] 2xl:h-[550px]"
                  // renderActions={renderActions}
                  // renderInput={renderInput}
                  highlightRow={false}
                  removeHeaders={[
                    "id",
                    "ventilationId",
                    "oxygenStartDateTime",
                    "oxygenStartDateTime",
                    "oxystartdatetimePatch",
                    "oxygenEndDateTime",
                    "oxygenStartDateTime",
                  ]}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
