import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import InputField from "../../../common/components/FormFields/InputField";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import { postPatientClearence } from "../../services/patientClearenceServices/PatientClearenceServices";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import CommonButton from "../../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  minHeight: "30%",
  // maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function PharmacyClearenceModal(props) {
  //
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var UserId = token.userId;

  //
  const [amount, setAmount] = React.useState(0);
  //backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const {
    open,
    handleClose,
    selctedPatient,
    setSelctedPatient,
    clearenceDept,
    setClearenceData,
    reset,
  } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      amount: 0,
    },
  });
  const { control, register } = methods;

  //   postPatientClearence
  const finalObj = {
    admissionId: 0,
    departmentId: 0,
    pharmacyClearanceAmount: 0,
    userId: 0,
isFromTab:false,
    patientDocumentClearanceDto: [
      {
        docAddedBy: 0,
        docViewBy: 0,
        filePath: "",
        viewDateTime: "2023-03-17T13:37:46.247Z",
      },
    ],
    saveWithDoc: false, //need to change when actualy we sacan document
  };

  const onSubmit = () => {
    setOpenConfirmationModal(true);
    // props.setOpenNursingClearenceModal(false);
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);
    handleClose();

    //
    finalObj.admissionId = selctedPatient.AdmissionId;
    finalObj.departmentId = clearenceDept.Id;
    finalObj.pharmacyClearanceAmount = amount;
    finalObj.userId = UserId;
    finalObj.menuId = props.menuId;
    finalObj.privilege = props.privilege;
    finalObj.isFromTab = false;

    //

    postPatientClearence(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setSelctedPatient(null);
          setClearenceData([]);
          reset();
        }
      })
      .catch((res) => {
        errorAlert(res.response.data.message || res.message);
        setOpenBackdrop(false);
      });
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
            <CancelPresentationIconButton
              onClick={(e) => {
                handleClose();
                e.preventDefault();
              }}
            />
            <div>
              <div className="my-2">
                <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                  Pharmacy Clearance
                </span>
              </div>
              <div className="">
                <span className=" text-gray-700 font-normal whitespace-nowrap">
                  Enter Pharmacy Clearance Amount
                </span>
              </div>

              <div className="w-64 my-2">
                <InputField
                  control={control}
                  name="amount"
                  placeholder="Search by Name/MRNO "
                  inputRef={{
                    ...register("amount", {
                      onChange: (e) => {
                        setAmount(e.target.value);
                      },
                    }),
                  }}
                />
              </div>
              <div className="flex gap-3 justify-end">
                <div>
                  <CommonButton
                    className="border border-customRed text-customRed"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>

                <div className="flex justify-end">
                  <CommonButton
                    label="Add"
                    className="bg-customGreen text-white"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}
