import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

import { format, isAfter } from "date-fns";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import { fetchMRDCheckView } from "../../services/mrdChecklistServices/MrdChecklistServices";

export default function MrdChecklistView(props) {
  const { handleClose, selectedHeader, selectedRow } = props;
  let location = useLocation();
  const token = JSON.parse(localStorage.getItem("userInfo"));
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      toDate: new Date(),
      fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  //
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  //
  useEffect(() => {
    isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate]);

  const populateTable = (forPagination) => {
    const viewListObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };

    fetchMRDCheckView(viewListObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
            MRD Checklist View
          </div>
          <hr className="border mx-1  border-customBlue" />
          <div className="m-3">
            <div className="grid grid-cols-6 gap-3 ">
              {/* from Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>

              {/* to Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
            </div>

            <div>
              {dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  tableClass="h-96 xl:h-[350px] 2xl:h-[550px]"
                  // renderActions={renderActions}
                  // renderInput={renderInput}
                  highlightRow={false}
                  removeHeaders={[
                    "id",
                    "AdmDate",
                    "AdmDoc",
                    "BillingDoc",
                    "ConsentDoc",
                    "Date",
                    "DischargeDoc",
                    "HistoryDoc",
                    "LabDoc",
                    "MedicationDoc",
                    "OrderDoc",
                    "OtDoc",
                    "RadiologyDoc",
                    "Time",
                    "admissionid",
                  ]}
                />
              ) : (
                <p className="text-center my-6 ">No Data Found</p>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
