import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Tooltip } from "@mui/material";
import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  CancelOffIcon,
  CancelOnIcon,
  DeleteOnIcon,
  HoldOffIcon,
  HoldOnIcon,
} from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import AcceptedListModal from "./AcceptedListModal";
import ActionsConfirmationModal from "./ActionsConfirmationModal";

import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchOfficePermissionListAutoComplete,
  fetchOfficePermissionsList,
} from "../../services/officePermissionServices/OfficePermissionsServices";
import PreviousListModal from "./PreviousListModal";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import NewOfficeModal from "./NewOfficeModal";

const OfficePermissions = () => {
  let location = useLocation();
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  //
  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  //
  const [floors, setFloors] = useState();
  const [floorId, setFloorId] = useState({ id: getUserInfo.floorId } || null);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionObject, setActionObject] = useState(null);

  // accepted list
  const [openAcceptedListModal, setOpenAcceptedListModal] = useState(false);
  const handleOpenAcceptedListModal = () => setOpenAcceptedListModal(true);
  const handleCloseAcceptedListModal = () => setOpenAcceptedListModal(false);

  // Previous List

  const [openPreviousListModal, setOpenPreviousListModal] = useState(false);
  const handleOpenPreviousListModal = () => setOpenPreviousListModal(true);
  const handleClosePreviousListModal = () => setOpenPreviousListModal(false);

  const [openNewRquestModal, setOpenNewRequestModal] = useState(false);
  // Actions Confirmation
  const [openActionsConfirmationModal, setOpenActionsConfirmationModal] =
    useState(false);
  const handleOpenActionsConfirmationModal = () =>
    setOpenActionsConfirmationModal(true);
  const handleCloseActionsConfirmationModal = () =>
    setOpenActionsConfirmationModal(false);

  //

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      patientSearch: "",
      // fromDate: new Date(),
      // toDate: new Date(),
    },
  });

  const { control, register, watch, setValue } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  const getFloors = () => {
    fetchFloor().then((response) => {
      setFloors(response.data.result);
    });
  };

  useEffect(() => {
    getUserInfo.floorId !== null &&
      getUserInfo.floorId > 1 &&
      getUserInfo.floor &&
      setValue("floor", {
        id: getUserInfo.floorId,
        label: getUserInfo.floor,
        value: getUserInfo.floor,
      });
    getFloors();
  }, []);

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [FromDate, ToDate, floorId]);

  const getPatientInfoAutoComplete = (SerchString) => {
    const autoCopmleteObj = {
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      searchString: SerchString ?? "",
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      floorId: (getUserInfo.floorId > 1 && getUserInfo.floorId) || floorId?.id,
    };

    fetchOfficePermissionListAutoComplete(autoCopmleteObj)
      .then((response) => response.data)
      .then((res) => {
        setAutocompleteData(res.result);
      });
  };

  useEffect(() => {
    isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [FromDate, ToDate, selectedPatient, floorId]);

  const populateTable = (forPagination) => {
    setShowSpinner(true);

    const acceptanceObj = {
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      menuId: location?.state?.menuId,
      cashCounterId: getUserInfo?.cashCounterId,
      flag: 0,
      mrno: "",
      searchId: selectedPatient?.id,
      floorId: floorId?.id,
    };

    fetchOfficePermissionsList(acceptanceObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setUserActions(res.actions);
        setCount(res.count);
        setShowSpinner(false);
      })
      .catch(() => setShowSpinner(false));
  };

  ////////table related
  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {userActions.map((actions) => (
            <>
              {actions.isAction ? (
                <>
                  <div className="flex cursor-pointer items-center gap-2">
                    {actions.action === "Delete" && (
                      <div className="text-red-500">
                        <DeleteOnIcon
                          title="Delete"
                          onClick={() => {
                            handleOpenActionsConfirmationModal();
                            setSelectedRow(row);
                            setPrivilege(actions.action);
                          }}
                        />
                      </div>
                    )}

                    {actions.action === "Reject" &&
                      (row.Status === 3 ? (
                        <CancelOffIcon title="Reject" />
                      ) : (
                        <CancelOnIcon
                          title="Reject"
                          onClick={() => {
                            handleOpenActionsConfirmationModal();
                            setSelectedRow(row);
                            setPrivilege(actions.action);
                          }}
                        />
                      ))}

                    {actions.action === "Hold" &&
                      (row.Status === 3 || row.Status === 2 ? (
                        <div className="text-gray-300">
                          <HoldOffIcon />
                        </div>
                      ) : (
                        <div className="text-customBlue">
                          <HoldOnIcon
                            title="Hold"
                            onClick={() => {
                              handleOpenActionsConfirmationModal();
                              setSelectedRow(row);
                              setPrivilege(actions.action);
                            }}
                          />
                        </div>
                      ))}

                    {actions.action === "Accept" &&
                      (row.Status === 3 ? (
                        <div className="text-gray-300">
                          <TaskAltOutlinedIcon />
                        </div>
                      ) : (
                        <div className="text-customGreen">
                          <Tooltip title="Accept">
                            <TaskAltOutlinedIcon
                              onClick={() => {
                                handleOpenActionsConfirmationModal();
                                setSelectedRow(row);
                                setPrivilege(actions.action);
                              }}
                            />
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    const handleInputChange = (e, row, index) => {
      let temp = [...dataResult];
      temp[index]["Remark"] = e.target.value;
      setDataResult([...temp]);
    };
    return (
      <>
        {header === "Remark" && (
          <div className="">
            <input
              className="border border-customBlue rounded w-72 text-center appearance-none px-2"
              onChange={(e) => {
                handleInputChange(e, row, index);
              }}
            />
          </div>
        )}
        {header === "OT Status" && (
          <div
            className={
              row["OT Status"] === "Rejected"
                ? "text-customRed"
                : row["OT Status"] === "On Hold"
                ? "text-customOrange"
                : row["OT Status"] === "Accepted"
                ? "text-green-500"
                : "text-sky-500"
            }
          >
            {row["OT Status"]}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="mt-12">
      <div className="text-center text-black font-bold text-xl ">
        Office Permissions
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 items-center">
        <div className="">
          <SearchDropdown
            control={control}
            placeholder="Search By Patient Name / MR.No./Mob No."
            dataArray={autocompleteData}
            name="patientSearch"
            searchIcon={true}
            isClearable={true}
            handleInputChange={getPatientInfoAutoComplete}
            inputRef={{
              ...register("patientSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSelectedPatient(e.target.value);
                  } else {
                    setSelectedPatient("");
                    methods.reset();
                  }
                },
              }),
            }}
          />
        </div>
        <div className="flex space-x-2">
          <DatePickerFieldNew
            control={control}
            name="fromDate"
            label="From Date"
            value={new Date()}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
          <DatePickerFieldNew
            control={control}
            name="toDate"
            label="To Date"
            value={new Date()}
            disableFuture={true}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        <div className=" ">
          <DropdownField
            control={control}
            isSearchable={true}
            isClearable={true}
            name="floor"
            placeholder="Floor"
            dataArray={floors}
            inputRef={{
              ...register("floor", {
                onChange: (e) => {
                  setFloorId(e.target.value);
                },
              }),
            }}
          />
        </div>
        <div className="flex justify-end space-x-2 lg:col-span-3 xl:col-span-1">
          {userActions?.map((action) => (
            <>
              {action?.isAction === false && action?.action === "Create" && (
                <CommonButton
                  label="Add New"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    setOpenNewRequestModal(true);
                    setActionObject(action);
                  }}
                />
              )}
              {action?.isAction === false &&
                action?.action === "Show Previous Data" && (
                  <CommonButton
                    label="Show Previous List"
                    className="bg-customBlue text-white"
                    onClick={() => {
                      handleOpenPreviousListModal();
                    }}
                  />
                )}
            </>
          ))}
        </div>
        {/* {userActions.map((item) => (
          <div className="flex justify-end space-x-2 md:col-span-3 lg:col-span-2 ">
            <div>
              {item.isAction === false && item.action === "Create" && (
                <CommonButton
                  label="Add New"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    setOpenNewRequestModal(true);
                    setActionObject(item);
                  }}
                />
              )}
            </div>
            <div>
              {!item.isAction && item.action === "Show Previous Data" && (
                <CommonButton
                  label="Show Previous List"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    handleOpenPreviousListModal();
                  }}
                />
              )}
            </div>
            
          </div>
        ))} */}
      </div>

      <div>
        {showSpinner ? (
          <div className="flex justify-center mt-14">
            <LoadingSpinner />
          </div>
        ) : dataResult.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            highlightRow={false}
            renderActions={renderActions}
            renderInput={renderInput}
            populateTable={populateTable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            count={count}
            tableClass="2xl:h-[550px]"
            editableColumns={["Remark", "OT Status"]}
            removeHeaders={[
              "User Name",
              "IS_OTReserved",
              "Request_Flag",
              "Account_User",
              "PatientCategoryId",
              "Office_Detail_ID",
              "ID",
              "AdmissionID",
              "PatientId",
              "BedId",
              "Status",
            ]}
          />
        ) : (
          <p className="text-center my-6 ">No Data Found</p>
        )}
      </div>

      {openAcceptedListModal ? (
        <AcceptedListModal
          open={openAcceptedListModal}
          setOpen={setOpenAcceptedListModal}
          handleOpen={handleOpenAcceptedListModal}
          handleClose={handleCloseAcceptedListModal}
        />
      ) : null}

      {openPreviousListModal ? (
        <PreviousListModal
          open={openPreviousListModal}
          setOpen={setOpenPreviousListModal}
          handleOpen={handleOpenPreviousListModal}
          handleClose={handleClosePreviousListModal}
        />
      ) : null}

      {openNewRquestModal && (
        <NewOfficeModal
          openNewRquestModal={openNewRquestModal}
          setOpenNewRequestModal={setOpenNewRequestModal}
          actionObject={actionObject}
          setActionObject={setActionObject}
          getUserInfo={getUserInfo}
          floorId={floorId}
          populateTable={populateTable}
          displayInfo={null}
        />
      )}

      {openActionsConfirmationModal ? (
        <ActionsConfirmationModal
          open={openActionsConfirmationModal}
          setOpen={setOpenActionsConfirmationModal}
          handleOpen={handleOpenActionsConfirmationModal}
          handleClose={handleCloseActionsConfirmationModal}
          selectedRow={selectedRow}
          populateTable={populateTable}
          menuId={location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}
    </div>
  );
};

export default OfficePermissions;

