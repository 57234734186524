import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  autoSearchEmeregncy,
  fetchEmergencyRegPatchDetails,
  fetchPatientInformationEmergencyNew,
} from "../../services/emergencyservice/EmergencyServices";
import IpdNotRegisteredForm from "./IpdNotRegisteredForm";
import IpdRegisteredForm from "./IpdRegisterForm";
import OpdNotRegisteredForm from "./OpdNotRegisteredForm";
import OpdRegisteredForm from "./OpdRegisteredForm";

export default function EmergencyModal(props) {
  const {
    populateTable,
    setOpen,
    openBackdrop,
    selectedRow,
    setSelectedRow,
    privilege,
  } = props;

  const [patientInfo, setPatientInfo] = React.useState(false); //hide show
  const [options, setOptions] = React.useState([]); // use for display options in searchbar
  const [patientData, setPatientData] = React.useState();
  const [patientInfoId, setPatientInfoId] = React.useState(null);

  const defaultValues = {
    patientInfo: null,
  };

  const opdIpd = [
    {
      id: "OPD",
      value: "OPD",
      label: "OPD",
    },
    {
      id: "IPD",
      value: "IPD",
      label: "IPD",
    },
  ];
  const resisteremergency = [
    {
      id: "Registered",
      value: "Registered",
      label: "Registered",
    },
    {
      id: "notRegistered",
      value: "notRegistered",
      label: "Not Registered",
    },
  ];

  const { control, reset, register, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let opdIpdVal = watch("opdIpd");
  let isEmergency = watch("isEmergency");

  useEffect(() => {
    if (selectedRow) {
      setValue("opdIpd", selectedRow?.OPD_IPD === 0 ? "OPD" : "IPD");
      setValue("isEmergency", "Registered");
    } else {
      setValue("opdIpd", "OPD");
      setValue("isEmergency", "Registered");
    }

    setPatientInfo(privilege === "Edit" ? true : false);
  }, [selectedRow]);

  useEffect(() => {
    if (privilege !== "Edit") {
      if (opdIpdVal === "OPD" && isEmergency === "Registered") {
        setValue("patientInfo", null);
        setPatientInfo("");
        setPatientData("");
      } else if (opdIpdVal === "IPD" && isEmergency === "Registered") {
        setValue("patientInfo", null);
        setPatientInfo("");
        setPatientData("");
      }
    }
  }, [opdIpdVal, isEmergency]);

  // useEffect(() => {
  //   setValue("opdIpd", "OPD");
  //   setValue("isEmergency", "Registered");
  // }, []);

  const handleChange = (autoSearchString) => {
    if (autoSearchString) {
      autoSearchEmeregncy(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  useEffect(() => {
    patientInfoId &&
      fetchPatientInformationEmergencyNew(patientInfoId).then((response) => {
        setPatientData(response.data.result);
      });
    //is from edit
    props?.selectedRow?.EmergencyPatientId &&
      privilege === "Edit" &&
      fetchEmergencyRegPatchDetails(props?.selectedRow?.EmergencyPatientId)
        .then((response) => response.data)
        .then((res) => {
          setPatientData(res.result);
        });
  }, [patientInfoId]);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    minHeight: "50%",
     maxHeight: "65%",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    py: 2,
    px: 2,
  };

  return (
    <>
      {/* Model and table name start */}
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              props.setEdit(false);
              reset(defaultValues);
            }}
          />

          <div className="row">
            <div className="border border-gray-300 xl:px-2 mx-1 text-left rounded p-2">
              <p className="md:mx-2 lg:px-2 py-2 my-2 font-bold text-gray-700">
                Emergency Patient Details
              </p>

              {/* Radio Buttons 2 Patient Type */}
              <div className="lg:flex  md:flex items-center gap-2 p-2">
                {(opdIpdVal === "OPD" && isEmergency === "Registered") ||
                (opdIpdVal === "IPD" && isEmergency === "Registered") ? (
                  <>
                    <div className="lg:w-5/6">
                      <div className="hidden lg:block">
                        <SearchDropdown
                          control={control}
                          searchIcon={true}
                          isDisabled={privilege === "Edit" ? true : false}
                          isSerchable={true}
                          isClearable={true}
                          name="patientInfo"
                          label="Search By Patient Name/MR.No./Mobile No."
                          placeholder="Search By Patient Name/MR.No./ Mobile No."
                          isMulti={false}
                          dataArray={options}
                          handleInputChange={(e) => {
                            handleChange(e);
                          }}
                          menuPlacement={"bottom"}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  " "
                )}
                {/* Visit Type */}
                <div className=" w-full flex items-center justify-start gap-2">
                  <div className=" font-semibold text-sm text-gray-700  lg:ml-4 md:ml-2 pb-1">
                    Visit Type
                  </div>
                  <fieldset
                    disabled={privilege === "Edit" ? true : false}
                    className="flex  justify-center "
                  >
                    <RadioField
                      label=""
                      name="opdIpd"
                      control={control}
                      dataArray={opdIpd}
                    />
                  </fieldset>
                </div>

                {/* Patient Type */}
                <div className=" w-full flex justify-start items-center gap-2">
                  <div className=" font-semibold text-sm text-gray-700 md:-ml-14 lg:-ml-40 col-span-1 pb-1">
                    Patient Type
                  </div>
                  <fieldset
                    disabled={privilege === "Edit" ? true : false}
                    className="flex  justify-center"
                  >
                    <RadioField
                      label=""
                      name="isEmergency"
                      control={control}
                      dataArray={resisteremergency}
                    />
                  </fieldset>
                </div>
              </div>

              {(opdIpdVal === "OPD" && isEmergency === "Registered") ||
              (opdIpdVal === "IPD" && isEmergency === "Registered") ? (
                <>
                  {/* Search by MR No./Name */}
                  <div className="grid px-2 w-full">
                    <div className="lg:hidden">
                      <SearchDropdown
                        control={control}
                        searchIcon={true}
                        isDisabled={privilege === "Edit" ? true : false}
                        name="patientInfo"
                        label="Search by MR No./Name"
                        placeholder="Search by MR No./Name"
                        isMulti={false}
                        dataArray={options}
                        handleInputChange={handleChange}
                        inputRef={{
                          ...register("patientInfo", {
                            onChange: (e) => {
                              setPatientInfo(true);
                              setPatientData(e);

                              if (e.target.value !== null) {
                                setPatientInfoId(e.target.value.id);
                              } else {
                                setPatientData(null);
                                setPatientInfo(false);
                                setPatientInfoId(null);
                              }
                            },
                          }),
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                " "
              )}

              <div className="py-2">
                {/* OpdRegisteredForm */}
                {opdIpdVal === "OPD" && isEmergency === "Registered" ? (
                  <OpdRegisteredForm
                    patientData={patientData}
                    patientInfo={patientInfo}
                    patientInfoId={patientInfoId}
                    populateTable={populateTable}
                    setOpen={setOpen}
                    openBackdrop={openBackdrop}
                    setOpenBackdrop={props.setOpenBackdrop}
                    setValue={setValue}
                    setPatientData={setPatientData}
                    setPatientInfoId={setPatientInfoId}
                    location={props.location}
                    privilege={props.privilege}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                  />
                ) : null}

                {/* OpdNotRegisteredForm */}
                {opdIpdVal === "OPD" && isEmergency === "notRegistered" ? (
                  <OpdNotRegisteredForm
                    patientInfoId={patientInfoId}
                    populateTable={populateTable}
                    setOpen={setOpen}
                    openBackdrop={openBackdrop}
                    setOpenBackdrop={props.setOpenBackdrop}
                    location={props.location}
                    privilege={props.privilege}
                  />
                ) : null}
                {/* IpdRegisteredForm */}
                {opdIpdVal === "IPD" && isEmergency === "Registered" ? (
                  <IpdRegisteredForm
                    patientData={patientData}
                    setPatientData={setPatientData}
                    patientInfo={patientInfo}
                    patientInfoId={patientInfoId}
                    populateTable={populateTable}
                    setOpen={setOpen}
                    openBackdrop={openBackdrop}
                    setOpenBackdrop={props.setOpenBackdrop}
                    setValue={setValue}
                    setPatientInfoId={setPatientInfoId}
                    location={props.location}
                    privilege={props.privilege}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                  />
                ) : null}
                {/* IpdNotRegisteredForm */}
                {opdIpdVal === "IPD" && isEmergency === "notRegistered" ? (
                  <IpdNotRegisteredForm
                    patientInfoId={patientInfoId}
                    populateTable={populateTable}
                    setOpen={setOpen}
                    setOpenBackdrop={props.setOpenBackdrop}
                    openBackdrop={openBackdrop}
                    location={props.location}
                    privilege={props.privilege}
                  />
                ) : null}
              </div>
            </div>
            <CommonBackDrop openBackdrop={openBackdrop} />
          </div>
        </Box>
      </Modal>
    </>
  );
}
