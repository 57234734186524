//function to patch the nursing synopsis form.
function patchNursingSynopsis(nursingSynopsisObjVal, methods) {
  
 

  //Nursing Synopsis
  // let initialNursigAssNursingSynopsisDto = {
  //   admissionConsultant: "", //doctorInform
  //   admissionSymptons: "", //patientSymptoms
  //   assessedByName: "", //assessedByName
  //   handOverName: "", //handOverToName
  //   personalHandOver: "", //patientHandOver
  //   reasonsForHospitalization: "", //reasonForHospitalization
  //   receiversRelationPt: "", //relationToPatient
  //   scheduledPreOpOrders: "", //patientSurgery
  // };

  //response from the get request
  //   {
  //     "reasonsForHospitalization": "Fever",
  //     "admissionConsultant": "",
  //     "admissionSymptons": "",
  //     "scheduledPreOpOrders": "",
  //     "personalHandOver": "",
  //     "handOverName": "",
  //     "receiversRelationPt": "",
  //     "assessedByName": ""
  // }

  if (nursingSynopsisObjVal.reasonsForHospitalization) {
    methods.setValue(
      "reasonForHospitalization",
      nursingSynopsisObjVal.reasonsForHospitalization
    );
  }

  if (nursingSynopsisObjVal.admissionConsultant) {
    methods.setValue("doctorInform", nursingSynopsisObjVal.admissionConsultant);
  }

  if (nursingSynopsisObjVal.admissionSymptons) {
    methods.setValue(
      "patientSymptoms",
      nursingSynopsisObjVal.admissionSymptons
    );
  }

  if (nursingSynopsisObjVal.scheduledPreOpOrders) {
    methods.setValue(
      "patientSurgery",
      nursingSynopsisObjVal.scheduledPreOpOrders
    );
  }

  if (nursingSynopsisObjVal.personalHandOver) {
    methods.setValue("patientHandOver", nursingSynopsisObjVal.personalHandOver);
  }

  if (nursingSynopsisObjVal.handOverName) {
    methods.setValue("handOverToName", nursingSynopsisObjVal.handOverName);
  }

  if (nursingSynopsisObjVal.receiversRelationPt) {
    methods.setValue(
      "relationToPatient",
      nursingSynopsisObjVal.receiversRelationPt
    );
  }

  if (nursingSynopsisObjVal.assessedByName) {
    methods.setValue("assessedByName", nursingSynopsisObjVal.assessedByName);
  }
}

export default patchNursingSynopsis;
