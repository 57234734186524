import React from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import InputArea from "../../../../common/components/FormFields/InputArea";

const DischargeInstruction = () => {
  const { control } = useFormContext();
  return (
    <div>
      <div>
        <InputArea
          name="diet"
          label="Diet"
          placeholder="Diet"
          control={control}
        />
      </div>
      <div className="pt-3">
        <InputArea
          name="physiotherapyInstructions"
          label="Physiotherapy Instructions"
          placeholder="Physiotherapy Instructions"
          control={control}
        />
      </div>
      <div className="pt-3 lg:pb-4">
        <InputArea
          name="investigationsBeforeInstructions"
          label="Investigations to be Done Before Follow-up Instructions"
          placeholder="Investigations to be Done Before Follow-up Instructions"
          control={control}
        />
      </div>
      <div className="pt-3 lg:pb-4">
        <InputArea
          name="reportToHospital"
          label="Report To Hospital *"
          placeholder="Report To Hospital *"
          control={control}
        />
      </div>
    </div>
  );
};

export default DischargeInstruction;
