import { Print } from "@mui/icons-material";
import { Box, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  fetchPrintWorkOrderList,
  getWorkOrderGRNDetailsList,
  getWorkOrderGRNList,
} from "../../../services/generalStore/workOrderGrnServices/WorkOrderGrnServices";
import {
  getSupplierList,
  getworkOrderGRNNoSearch,
} from "../../../services/generalStore/workOrderServices/WorkOrderServices";

export default function WorkOrderGRNModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "94%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const defaultValues = {
    supplierName: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    setValue,
    trigger,
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  // local storage
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  //state variables
  const [supplierId, setSupplierId] = useState(0);
  const [supplierList, setSupplierList] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState();
  const [dataResult, setDataResult] = React.useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [workOrderNoList, setWorkOrderNoList] = useState([]);
  const [workOrderId, setWorkOrderId] = useState(0);

  let getStoreName = JSON.parse(localStorage.getItem("userInfo"));

  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");

  const handleChangeGetSupplierList = (autoSearchString) => {
    if (
      autoSearchString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchString)
    ) {
      getSupplierList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplierList(res.result);
        });
    }
  };

  function workOrderList(forPagination) {
    let defaultParams = {
      fromDate: getFromDate ? getFromDate : new Date(),
      isAmmendment: false,
      isCancelled: false,
      isOrderPlaced: 0,
      searchString: "",
      storeId: getUserInfo.storeId,
      supplierId: supplierId,
      toDate: getToDate ? getToDate : new Date(),
      workOrderId: workOrderId.id === undefined ? 0 : workOrderId.id,
    };

    getWorkOrderGRNList(defaultParams)
      .then((response) => {
        setCount(response.data.count);
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  }

  function displayRowData(row) {
    let obj = {
      doId: row ? row.WorkOrderDoneId : 0,
      storeId: row ? row.StoreId : 0,
      supplierId: supplierId,
    };

    getWorkOrderGRNDetailsList(obj)
      .then((response) => response.data)
      .then((res) => {
        setDetailsData(res.result);
      });
  }

  const handleChangeGetWorkOrderNoList = (searchWONo) => {
    let obj = {
      fromDate: getFromDate ? getFromDate : new Date(),
      searchString: searchWONo,
      supplierId: supplierId,
      toDate: getToDate ? getToDate : new Date(),
    };

    if (searchWONo !== "" && UseCheckConsecutiveCharacters(searchWONo)) {
      getworkOrderGRNNoSearch(obj)
        .then((response) => response.data)
        .then((res) => {
          setWorkOrderNoList(res.result);
        });
    }
  };
  function onSubmitDataHandler() {}

  useEffect(() => {
    setValue("storeName", getStoreName.storeName);
    workOrderList();
  }, []);

  //Table
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleOpenPrintModal = (WorkOrderDoneId) => {
    fetchPrintWorkOrderList(WorkOrderDoneId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "Print" ? (
                    <>
                      <Tooltip title="Print">
                        <div
                          className="text-gray-600 mr-3 cursor-pointer"
                          onClick={() => {
                            handleOpenPrintModal(row.WorkOrderDoneId);
                          }}
                        >
                          {<Print />}
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%]">
          <div className="flex justify-between">
            <h1 className="px-2 ml-2 lg:ml-0 font-bold text-gray-800 whitespace-nowrap">
              Search Work Order GRN
            </h1>
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
              }}
            />
          </div>
          <div className="row">
            <form
              className="w-full md:px-3 lg:px-0 py-2"
              onSubmit={handleSubmit(onSubmitDataHandler)}
            >
              <div className="grid grid-cols-3 xl:grid-cols-5 gap-3">
                <div className="">
                  <InputField
                    control={control}
                    name="storeName"
                    label="Store Name*"
                    disabled
                  />
                </div>
                <div className=" ">
                  <SearchDropdown
                    control={control}
                    searchIcon={true}
                    isDisabled={false}
                    isSearchable={true}
                    isClearable={true}
                    error={errors.supplierName}
                    name="supplierName"
                    placeholder="Supplier Name*"
                    dataArray={supplierList}
                    handleInputChange={handleChangeGetSupplierList}
                    inputRef={{
                      ...register(`supplierName`, {
                        onChange: (e) => {
                          if (e.target.value === null) {
                            setSupplierId(0);
                          } else {
                            setSupplierId(e.target.value.id);
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div className="">
                  <SearchDropdown
                    control={control}
                    type="button"
                    searchIcon={true}
                    name="searchPurchaseOrderNuber"
                    placeholder="Search By WO Number"
                    isSearchable={true}
                    isClearable={true}
                    dataArray={workOrderNoList}
                    handleInputChange={handleChangeGetWorkOrderNoList}
                    inputRef={{
                      ...register(`searchPurchaseOrderNuber`, {
                        onChange: (e) => {
                          if (e.target.value === null) {
                            setWorkOrderId(0);
                          } else {
                            setWorkOrderId(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="flex space-x-2 w-full col-span-2">
                  <DatePickerFieldNew
                    defaultValue={new Date()}
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                  <DatePickerFieldNew
                    defaultValue={new Date()}
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                    }}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                  />
                  <CommonButton
                    type="button"
                    className="bg-customBlue text-white"
                    searchIcon={true}
                    onClick={() => {
                      workOrderList();
                    }}
                  />
                </div>
              </div>
            </form>

            <div className="w-full">
              <div className="grid grid-cols-2 justify-between items-center font-bold"></div>
              <div className="">
                {dataResult.length > 0 ? (
                  <CommonDynamicTablePaginationNew
                    dataResult={dataResult}
                    setDataResult={setDataResult}
                    populateTable={workOrderList}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    handleSelectedRow={displayRowData}
                    tableHeading={"Work Order List"}
                    renderActions={renderActions}
                    highlightRow={true}
                  />
                ) : (
                  <div className="justify-center items-center font-semibold h-48 w-full flex border-2 border-gray-300 rounded">
                    No Records Found...
                  </div>
                )}
              </div>
            </div>

            <div className="w-full">
              <h1 className="font-bold">Work Order Details</h1>
              <div className="h-48">
                {detailsData.length > 0 ? (
                  // <NoActionsTable data={detailsData} />

                  <CommonDynamicTableNew
                    dataResult={detailsData}
                    highlightRow={false}
                    tableClass={"rounded h-56 lg:h-96"}
                  />
                ) : (
                  <div className="justify-center items-center font-semibold h-48  w-full flex border-2 border-gray-300 rounded">
                    No Records Found...
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end gap-2">
              <CommonButton
                type="button"
                className="border border-customRed text-customRed"
                label="Reset"
                onClick={() => {
                  reset(defaultValues);
                  setDataResult([]);
                  setDetailsData([]);
                }}
              />

              <CommonButton
                type="button"
                className="border border-customRed text-customRed"
                label="Close"
                onClick={() => {
                  props.handleClose();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}
