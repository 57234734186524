import React from "react";
export const BellIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
      >
        <circle cx="22.5" cy="22.5" r="22" fill="white" stroke="#1E3A8A" />
        <path
          d="M30.657 13.063C30.5313 13.5246 30.3033 13.9529 29.9897 14.3166C29.6477 14.7151 29.2114 15.0238 28.7197 15.215L24.9509 14.216C24.6217 13.8071 24.3999 13.324 24.3051 12.8098C24.2163 12.3339 24.2369 11.8443 24.3654 11.3773C24.4939 10.9103 24.727 10.478 25.0474 10.1123C25.3679 9.74658 25.7674 9.45695 26.2165 9.26485C26.6655 9.07276 27.1525 8.98313 27.6413 9.00261C28.1301 9.0221 28.6082 9.1502 29.0401 9.37741C29.4721 9.60461 29.8468 9.92511 30.1365 10.3151C30.4261 10.7051 30.6233 11.1546 30.7134 11.6303C30.8036 12.106 30.7843 12.5957 30.657 13.063Z"
          fill="#FF6E1D"
        />
        <path
          d="M29.9897 14.3166C29.6477 14.7151 29.2114 15.0238 28.7197 15.215L24.9509 14.216C24.6217 13.8071 24.3999 13.324 24.3051 12.8098C24.7048 12.3462 25.2285 12.0043 25.8167 11.8234C26.4048 11.6425 27.0327 11.6302 27.6277 11.7879C28.2226 11.9456 28.7602 12.2669 29.1781 12.7144C29.596 13.162 29.8778 13.7175 29.9897 14.3166Z"
          fill="#F8CA86"
        />
        <path
          d="M27.1741 33.5982C26.7061 34.2805 26.0874 34.8486 25.3648 35.2596C24.5473 35.7265 23.6235 35.9809 22.6798 35.999C21.736 36.0171 20.803 35.7983 19.9678 35.3631C19.1326 34.9279 18.4225 34.2905 17.9037 33.5103C17.385 32.7301 17.0745 31.8326 17.0013 30.9016L27.1741 33.5982Z"
          fill="#FF7B5F"
        />
        <path
          d="M26.1343 33.3225L25.8639 33.4844C25.2049 33.8622 24.4748 34.1028 23.7184 34.1912C22.9621 34.2797 22.1954 34.2143 21.4655 33.9989C20.7357 33.7835 20.058 33.4228 19.4742 32.9389C18.8904 32.455 18.4128 31.8582 18.0707 31.185L26.1343 33.3225Z"
          fill="#FDFFB6"
        />
        <path
          d="M34.3984 34.2396C34.7896 34.7657 34.2928 35.4854 33.6551 35.3164L10.5177 29.1832C9.88004 29.0141 9.81353 28.1451 10.4182 27.883L11.2433 27.5253C12.973 26.7754 14.5101 25.6503 15.7408 24.2332C16.9715 22.8162 17.8643 21.1435 18.3531 19.3389C18.8325 17.5689 20.003 16.0599 21.6071 15.1437C23.2112 14.2275 25.1174 13.9792 26.9066 14.4534C28.6957 14.9277 30.2211 16.0856 31.1472 17.6725C32.0733 19.2594 32.3243 21.1453 31.8449 22.9153C31.3545 24.7197 31.2814 26.6103 31.6311 28.4466C31.9807 30.2828 32.7441 32.0175 33.8646 33.5217L34.3984 34.2396Z"
          fill="#FF6E1D"
        />
        <path
          d="M34.3984 34.2396C34.7896 34.7657 34.2928 35.4854 33.6551 35.3164L17.2022 30.9551C19.7862 29.2772 21.6562 26.718 22.4564 23.7645C22.9358 21.9945 24.1063 20.4855 25.7104 19.5693C27.3145 18.6531 29.2207 18.4048 31.0099 18.8791C31.2735 18.9471 31.532 19.0332 31.7836 19.1366C32.162 20.3655 32.1834 21.675 31.8449 22.9153C31.3545 24.7197 31.2814 26.6103 31.6311 28.4466C31.9807 30.2828 32.7441 32.0175 33.8646 33.5217L34.3984 34.2396Z"
          fill="#F8CA86"
        />
        <path
          d="M35 21.8017C35 23.2158 33.86 24.3621 32.4537 24.3621C31.0474 24.3621 29.9074 23.2158 29.9074 21.8017C29.9074 20.3877 31.0474 19.2414 32.4537 19.2414C33.86 19.2414 35 20.3877 35 21.8017Z"
          fill="#FF2F2F"
        />
      </svg>
    );
  };