import * as React from "react";
import { useEffect } from "react";

// importing the CommonMasterTable ; which is common to all
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
//fetching data into ipdBillService.js

import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchSearchDropDown } from "../../../../commonServices/patientInfoServices/PatientInfoServices";

import {
  getListOfOPDCreditBills,
  getListOfOPDSelfPaymentSettlement,
  opdSelfSettlementPdfPrint,
  saveSelfBillSettlement,
} from "../../../services/BillSettlementServices";
import {
  getSelftSettlementInformationById,
  searchPatientInformation,
} from "../../../services/BillingServices";
import BillInformtionModal from "./BillInformtionModal";

const actions = ["cancel", "casepaper"];
const Actions = [
  {
    id: 0,
    action: "Print",
    isAction: true,
  },
  {
    id: 2,
    action: "SettleBill",
    isAction: false,
  },
];
export default function PaymentSettlement() {
  const schema = yup.object().shape({});
  const defaultValues = {
    SelfbillsttlementSearch: "",
  };
  const {
    register,
    watch,
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [selectedPatient, setSelectedPatient] = React.useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [listOfPaymentData, setListOfPaymentData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const [balanceAmount, setBalanceAmount] = React.useState(0);
  const [billId, setBillId] = React.useState();
  const [selectedRow, setSelectedRow] = React.useState();
  const [userActions, setUserActions] = React.useState([]);
  // get menu id
  let location = useLocation();

  // useEffect
  useEffect(() => {
    fetchListOfCreditBill();
    fetchListOfPayment();
  }, [selectedPatient, rowData]);

  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);
  //functions
  const handleOpenChild = () => setOpen(true);
  const handleCloseChild = () => setOpen(false);

  const handleChange = (searchString) => {
    let opdIpd = 0;
    if (searchString !== "")
      fetchSearchDropDown(searchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          if (res !== null) {
            setOptions(res.result);
          } else {
            setOptions([]);
          }
        });
  };

  const getSelfSettlementInfo = (selectedPatient) => {
    let opdIpd = 0;
    getSelftSettlementInformationById(selectedPatient.id, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setSelectedPatient(res.result);
      });
  };
  function fetchListOfCreditBill() {
    let creditBillObj = {
      opdIpd: 0,
      patientId: selectedPatient && selectedPatient.PatientId,
    };

    getListOfOPDCreditBills(creditBillObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
  }

  function fetchListOfPayment() {
    let listOfPyamentObj = {
      opdIpd: 0,
      patientId: selectedPatient && selectedPatient.PatientId,
    };

    getListOfOPDSelfPaymentSettlement(listOfPyamentObj)
      .then((response) => response.data)
      .then((res) => {
        setListOfPaymentData(res.result);
      });
  }

  function displayRow(row) {
    setRowData(row);
    setBillId(row.BillId);

    setBalanceAmount(row.BalanceAmount);
  }

  function submitPaymentInfoModal(paymentInfoList) {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    // setOpenBackdrop(true);

    let postObj = {
      addedBy: userInfoObj.userId,
      // balanceBill: selectedRow && selectedRow.BillBalanceAmount   //old change key
      balanceBill: selectedRow && selectedRow.BalanceAmount,
      billId: selectedRow && selectedRow.BillId,
      cashCounterId: userInfoObj.cashCounterId,
      menuId: location?.state?.menuId,
      privilege: "SettleBill",
      isCash: false,
      opdIpd: 0,
      opdIpdExternalId: selectedRow && selectedRow.Opd_Ipd_External_Id,
      paidAmount: selectedRow && selectedRow.PaidAmount,
      totalBill: selectedRow && selectedRow.TotalBillAmount,
      totalConcessionAmount: selectedRow && selectedRow.TotalConcessionAmount,
    };

    // postObj.totalAmt = lastTotal;
    postObj.paymentInfoList = paymentInfoList;

    saveSelfBillSettlement(postObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        if (res.statusCode === 200) {
          fetchListOfPayment();
          fetchListOfCreditBill();
          setSelectedPatient("");
          // setOpenBackdrop(false);
          setOptions([]);

          handleOpenPrintModal(res.result);

          setOpenBackdrop(false);
          reset(defaultValues);
          handleCloseChild();
        }
      })
      .catch(() => {
        errorAlert(errors.message);
        // setOpenBackdrop(false);
      });
  }

  //prints print
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (paymentId) => {
    // admissionId,formId
    opdSelfSettlementPdfPrint(paymentId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };
  // table action and button visibility mgmt
  // pervious bill actions
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === false ? (
                <>
                  <div className="flex gap-2 cursor-pointer">
                    {actions.action === "Print" ? (
                      <Tooltip title={"Print"}>
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            displayRow(row);
                            handleOpenPrintModal(row?.PaymentId);
                            // setOpenSettlementPrintModal(true);
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  function handleSelectedRow(row) {
    setSelectedRow(row);
  }
  return (
    <>
      <div className="text center mt-20">
        <div className="text-center text-black font-bold text-xl ">
          Self Bill Settlement
        </div>
      </div>
      <div className="w-full grid  px-6 md:rounded-md  ">
        {/* SearchBar */}
        <div className=" flex items-center w-full md:gap-2 lg:gap-5  ">
          {/*searchable dropdown */}
          <div className="flex lg:w-2/5 md:w-full gap-2 py-1">
            <SearchDropdown
              control={control}
              searchIcon={true}
              className="z-80"
              name="SelfbillsttlementSearch"
              placeholder="Search By Patient Name / MR No./ Mobile No"
              dataArray={options}
              isSearchable={true}
              isClearable={true}
              handleInputChange={handleChange}
              inputRef={{
                ...register("SelfbillsttlementSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                      getSelfSettlementInfo(e.target.value);
                    } else {
                      setSelectedPatient("");
                    }
                  },
                }),
              }}
            />
          </div>
        </div>

        {selectedPatient && selectedPatient !== null ? (
          <>
            <div className="border bg-gray-100 border-gray-300 text-left w-full reounded mb-2 py-5 px-4 mt-1 md:grid md:grid-cols-[18%_1.5%_42%_12%_1.5%_25%] lg:grid lg:grid-cols-[8%_1%_22%_6%_1%_16%_6%_1%_16%_6%_1%_16%] text-sm ">
              <div className="font-semibold ">Patient Name</div>
              <div>:</div>
              <div className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.patientName}
              </div>
              <div className="font-semibold "> MR No.</div>
              <div>:</div>
              <div className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.mrNo}
              </div>
              <div className="font-semibold ">Age</div>
              <div>:</div>
              <div className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.age}
              </div>
              <div className="font-semibold">Mobile No</div>
              <div>:</div>
              <div className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.MobileNo}
              </div>
            </div>

            {/* List Of Payment Table */}
            <div className=" w-full overflow-hidden">
              <div className="flex justify-between">
                <p className="font-bold text-sm text-gray-700">
                  List Of Payment
                </p>
              </div>

              {listOfPaymentData && listOfPaymentData.length > 0 ? (
                <>
                  <CommonDynamicTableNew
                    dataResult={listOfPaymentData}
                    renderActions={renderActions}
                    setSelectedRow={setSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72"}
                    removeHeaders={["Opd_Ipd_External_Id","PaymentId","BillId"]}
                  />
                </>
              ) : (
                <>
                  <div className="text-center mt-4 p-4">No Record Found</div>
                </>
              )}
            </div>

            {/* List Of Credit Bill Table */}
            <div className=" w-full overflow-hidden">
              <p className="font-bold text-sm py-2 text-gray-700">
                List Of Credit Bills
              </p>

              {data && data.length > 0 ? (
                <>
                  <div className="px-0">
                    <CommonDynamicTableNew
                      dataResult={data}
                      //setSelectedRow={setSelectedRow}
                      tableClass={"rounded lg:h-52 md:h-72"}
                      handleSelectedRow={handleSelectedRow}
                      removeHeaders={["Opd_Ipd_External_Id","BillId","IsSettled","CNAmount","DBAmount"]}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center mt-4 p-4">No Record Found</div>
                </>
              )}
            </div>
            <div className="flex justify-end py-2 gap-3">
              {userActions &&
                userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "SettleBill" ? (
                      <CommonButton
                        className="bg-blue-900  text-white"
                        label="Settle Bill"
                        onClick={() => {
                          if (
                            options.length > 0 &&
                            selectedRow &&
                            selectedRow.BalanceAmount > 0
                          ) {
                            handleOpenChild();
                          } else {
                            errorAlert("Please Select Credit Bill");
                          }
                        }}
                      />
                    ) : null}
                  </>
                ))}
            </div>
          </>
        ) : (
          ""
        )}
        {/* ServiceTax Ok Cancel Btn */}
      </div>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <BillInformtionModal
        open={open}
        setOpen={setOpen}
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
        handleClose={handleCloseChild}
        getValues={getValues}
        handleCloseChild={handleCloseChild}
        submitPaymentInfoModal={submitPaymentInfoModal}
        refundAmount={selectedRow?.BalanceAmount}
        advanceAmount={0}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
      />

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}
