import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";

//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ExpiredItemReturnParentTable(props) {
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);

  const [open, setOpen] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //actions

  const [rescheduleAction, setrescheduleAction] = React.useState(false);
  const [cancelAction, setcancelAction] = React.useState(false);
  const [casePaperAction, setCasePaperAction] = React.useState(false);
  const [editAction, setEditAction] = React.useState(false);
  const [deleteAction, setDeleteAction] = React.useState(false);
  const [vistiPdf, setVisitPdf] = React.useState("");
  const [rowIndex, setRowIndex] = React.useState();
  const [viewAction, setViewAction] = React.useState(false);
  const [checkBoxAction, setCheckBoxAction] = React.useState(false);
  const [printAction, setPrintAction] = React.useState(false);
  // calculation of expiry item
  function handleExpiredItemChange(e, row, index, dataArray) {
    let allTotalAmount = 0;
    let allVATTotalAmount = 0;
    for (let obj of dataArray) {
      allTotalAmount = allTotalAmount + Number(obj?.totalAmount);
      allVATTotalAmount = allVATTotalAmount + obj?.vatAmount;
    }

    //
    props.setTotalAmount(allTotalAmount);
    props.setVATAmount(allVATTotalAmount);
  }
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "UnitPurchaseRate",
    "PurchaseRate",
    "VatPercentage",
    "OctroiPer",
    "TaxAmount",

    "AcceptQty",
    "OtherTaxAmount",

    "MRP",
    "UnitMRP",
    "GRNDetailId",
  ]);

  React.useEffect(() => {
    props.actions.forEach((action) => {
      if (action === "cancel") {
        setcancelAction(true);
      }
      if (action === "casepaper") {
        setCasePaperAction(true);
      }
      if (action === "Edit") {
        setEditAction(true);
      }
      if (action === "Delete") {
        setDeleteAction(true);
      }
      if (action === "print") {
        setPrintAction(true);
      }
      if (action === "View") {
        setViewAction(true);
      }
      if (action === "Checkbox") {
        setCheckBoxAction(true);
      }
    });
  }, []);

  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "lightBlue",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                  borderRadius: 4,
                },
              }}
              className="rounded lg:h-80  2xl:h-72"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.2,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center  whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Item Id
                      </span>
                    </TableCell> */}

                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>

                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Unit Purchase Rate
                      </span>
                    </TableCell> */}
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Purchase Rate
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Vat Percentage(%)
                      </span>
                    </TableCell>

                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Bal Qty
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Return Qty
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Total Amount
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        VAT Amount
                      </span>
                    </TableCell>
                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Octroi Per
                      </span>
                    </TableCell> */}
                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Tax Amount
                      </span>
                    </TableCell> */}
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Accept Qty
                      </span>
                    </TableCell>
                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Other Tax Amount
                      </span>
                    </TableCell> */}
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Batch Code
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Batch Exp Date
                      </span>
                    </TableCell>

                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Unit MRP
                      </span>
                    </TableCell> */}
                    {/* <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        GRN Detail Id
                      </span>
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody className="border ">
                  {props?.data?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {}}
                        sx={{
                          "& td": {
                            paddingY: 0,
                            // backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          <fieldset
                            className="m-0 p-0"
                            disabled={true}
                            onChange={(e) => {
                              // handleSelectData(index, row, e);
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              defaultValue={true}
                              name={`storeInventoryMinMax${index}`}
                            />
                          </fieldset>
                        </TableCell>
                        {/* 
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.ItemId}
                        </TableCell> */}
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.ItemCode}
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.ItemName}
                        </TableCell>
                        {/* <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.UnitPurchaseRate}
                        </TableCell> */}
                        <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                          {row.PurchaseRate}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.VatPercentage}
                        </TableCell>

                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.BalQty}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          <input
                            type="number"
                            name={`returnQty${index}`}
                            defaultValue={0}
                            style={{
                              border:
                                row.returnQty > row.BalQty
                                  ? "1px solid red"
                                  : "1px solid #3184a6",
                            }}
                            className="border rounded border-gray-400 w-20"
                            onChange={(e) => {
                              if (e.target.value > row.BalQty) {
                                props.setReturnQtyErrorsFlag(true);
                              } else {
                                props.setReturnQtyErrorsFlag(false);
                              }
                              let dataArray = [...props.data];
                              let rowData = row;
                              rowData.returnQty = Number(e.target.value);
                              dataArray[index] = rowData;
                              let totalAmt = 0;
                              let VATAmt = 0;
                              totalAmt =
                                row.PurchaseRate * Number(e.target.value);
                              VATAmt = (totalAmt * row?.VatPercentage) / 100;
                              rowData.totalAmount = totalAmt;
                              rowData.vatAmount = VATAmt;
                              setValue(`totalAmount${index}`, totalAmt);
                              setValue(`vatAmount${index}`, VATAmt);

                              handleExpiredItemChange(e, row, index, dataArray);
                            }}
                          />
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          <input
                            defaultValue={0}
                            type="number"
                            disabled={true}
                            name={`totalAmount${index}`}
                            {...register(`totalAmount${index}`)}
                            control={control}
                            className="border rounded border-gray-400 w-20"
                          />
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          <input
                            type="number"
                            disabled={true}
                            name={`vatAmount${index}`}
                            {...register(`vatAmount${index}`)}
                            control={control}
                            className="border rounded border-gray-400 w-20"
                          />
                        </TableCell>
                        {/* <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.OctroiPer}
                        </TableCell> */}
                        {/* <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.TaxAmount}
                        </TableCell> */}
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.AcceptQty}
                        </TableCell>
                        {/* <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.OtherTaxAmount}
                        </TableCell> */}
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.BatchCode}
                        </TableCell>
                        <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.BatchExpDate}
                        </TableCell>
                        {/* <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.UnitMRP}
                        </TableCell> */}
                        {/* <TableCell className="text-gray-600 font-bold text-center justify-center whitespace-nowrap">
                          {row.GRNDetailId}
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
