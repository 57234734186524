import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const fetchStore = () => {
  return apiClient.get(`/misc/stores`, {
    headers: authHeader(),
  });
};

export const fetchSupplier = () => {
  return apiClient.get(`/purchase/supplierListForPurchase`, {
    headers: authHeader(),
  });
};

export const addNewEnquiry = async (createEnquiry) => {
  return await apiClient.post(`/purchase/savePurchaseEnquiry`, createEnquiry, {
    headers: authHeader(),
  });
};

export const itemDetailsSearch = (itemSearch) => {
  return apiClient.post(`/purchase/searchItem`, itemSearch, {
    headers: authHeader(),
  });
};

// POST List
export const fetchAllEnquiry = async (enquiryObj) => {
  return await apiClient.post(`/purchase/enquiryReviewList`, enquiryObj, {
    headers: authHeader(),
  });
};

// Enquiry Modal in Quotation Component Get QId And Call This API on Click of Checkbox  and Gettting items Or Showing ionto Parent
export const getQuotationItemDetailsList = async (qId) => {
  return await apiClient.get(`/purchase/quotationItemDetailsList/${qId}`, {
    headers: authHeader(),
  });
};

export const fetchTermsOfPayment = () => {
  return apiClient.get(`purchase/getTermAndConditions`, {
    headers: authHeader(),
  });
};
