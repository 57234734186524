import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DropdownField from "../../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../../common/components/FormFields/InputField";
import { fetchRelationWithPatient } from "../../../../services/admissionServices/AdmissionServices";
import InputLimit from "../../../../../common/components/FormFields/InputLimit";

const RepresentativeDetails = () => {
  const [relationWithPatient, setRelationWithPatient] = useState();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    fetchRelationWithPatient()
      .then((response) => response.data)
      .then((res) => {
        setRelationWithPatient(res.result);
      });
  }, []);

  return (
    <div>
      <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-3">
        {/* ///Representative Name /// */}
        <div>
          <InputField
            name="nameOfRepresentative"
            variant="outlined"
            label="Name "
            error={errors.nameOfRepresentative}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div>
          {/* //Representative Mobile No.// */}

          <InputLimit
            maxDigits={10}
            name="mobileNumberOfRepresentative"
            error={errors.mobileNumberOfRepresentative}
            variant="outlined"
            label="Mobile No."
            control={control}
            disabled={false}
            type={"number"}
          />
        </div>
        {/* //Relationship with patient// */}
        <div>
          <DropdownField
            control={control}
            // error={errors.relationshipWithPatient}
            name="relationshipWithPatient"
            label="Relationship With Patient"
            dataArray={relationWithPatient}
            isSearchable={false}
            isClearable={false}
            placeholder="Relationship With Patient"
          />
        </div>

        {/* //Relationship Address// */}
        <div className="lg:col-span-2 md:col-span-1 ">
          <InputField
            name="representativeAddress"
            variant="outlined"
            label="Address"
            error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
    </div>
  );
};

export default RepresentativeDetails;
