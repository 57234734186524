import { Checkbox } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchStore,
  fetchSupplier,
} from "../../../services/purchase/EnquiryServices";
import {
  fetchAllPurchaseApprovalList,
  getApprovedOrRejectPurchase,
  savePurchaseApproval,
} from "../../../services/purchase/PurchaseApprovalServices";
import PurchaseApproveModal from "./PurchaseApproveModal";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";

const grnType = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "By DO",
    value: "By DO",
    label: "By DO",
  },
  {
    id: "Direct Purchase",
    value: "Direct Purchase",
    label: "Direct Purchase",
  },
];

const paymentType = [
  {
    id: "Cash",
    value: "Cash",
    label: "Cash",
  },
  {
    id: "Credit",
    value: "Credit",
    label: "Credit",
  },
];
const status = [
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Approved",
    value: "Approved",
    label: "Approved",
  },
  {
    id: "Rejected",
    value: "Rejected",
    label: "Rejected",
  },
];

const afterDays = [
  {
    id: "Due Date",
    value: "Due Date",
    label: "Due Date",
  },
  {
    id: "GRN Date",
    value: "GRN Date",
    label: "GRN Date",
  },
];

export default function PurchaseApproval() {
  const defaultValues = {
    supplier: null,
    store: null,
    fromDate: new Date(),
    toDate: new Date(),
    grnType: "All",
    paymentType: "Credit",
    status: "Pending",
    purchaseGrnDate: new Date(),
    netapprovedamount: 0,
    changeableNetApprovedamount: 0,
    remark: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [finalData, setFinalData] = useState({});
  const [store, setStore] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [grnIds, setGrnIds] = useState([]);
  const [
    purchaseApprovalDetailsDtoArray,
    setPurchaseApprovalDetailsDtoArray,
  ] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  const [approvedPrivilege, setApprovedPrivilege] = useState(null);
  const [openApprovedPost, setOpenApprovedPost] = useState(false);
  const handleCloseApprovedPost = () => {
    if (openApprovedPost) {
      setOpenApprovedPost(false);
    }
  };

  //Purchase Approveal List Modal UseState
  const [openPurchApprListModal, setOpenPurchApprListModal] = useState(false);
  const handleOpenPurchApprListModal = () => {
    setOpenPurchApprListModal(true);
  };
  const handleClosePurchApprListModal = () => {
    setOpenPurchApprListModal(false);
  };
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [privilege, setPrivilege] = useState("");
  const [userActions, setUserActions] = useState([]);
  const [totalSelectedApprovedAmt, setTotalSelectedApprovedAmt] = useState(0);

  let changeableNetAppramtVal = watch("changeableNetApprovedamount");
  let statusVal = watch("status");
  let storeVal = watch("store");
  let supplierVal = watch("supplier");
  let fromDateVal = watch("fromDate");
  let toDateVal = watch("toDate");
  let paymentTypeVal = watch("paymentType");

  const location = useLocation();

  function callTableDataApi(forPagination) {
    let cashCredit;
    let pendAppRej;
    if (paymentTypeVal === "Cash") {
      cashCredit = false;
    } else if (paymentTypeVal === "Credit") {
      cashCredit = true;
    }

    if (statusVal === "Pending") {
      pendAppRej = 0;
    } else if (statusVal === "Approved") {
      pendAppRej = 1;
    } else if (statusVal === "Rejected") {
      pendAppRej = 2;
    }

    let purchaseAccountCfoCEO;

    //  getting result against this object : {
    //     "page": 0,
    //     "size": 10,
    //     "cashCredit": false,
    //     "fromDate": "2018-01-09T10:56:15.000Z",
    //     "pendAppRej": 0,
    //     "purchaseAccountCfoCEO": 0,
    //     "storeId": 3,
    //     "supplierId": null,
    //     "toDate": "2024-01-09T10:56:15.000Z"
    // }

    let stringSearchObj = {
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      cashCredit: cashCredit,
      fromDate: format(fromDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      pendAppRej: pendAppRej,
      purchaseAccountCfoCEO: 0,
      storeId: storeVal !== null ? storeVal?.id : null,
      supplierId: supplierVal !== null ? supplierVal?.id : null,
      toDate: format(toDateVal, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };

    fetchAllPurchaseApprovalList(stringSearchObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        res.result.forEach((object) => {
          object.isChecked = false;
        });
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
  }

  function postPurchaseApproval(obj) {
    savePurchaseApproval(obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setOpenBackdrop(false);
          successAlert(response.data.message);
          callTableDataApi();
          reset(defaultValues);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postPurchaseApproval(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let purchaseApprovalArr = [];
    for (let i = 0; i < purchaseApprovalDetailsDtoArray.length; i++) {
      console.log(
        "purchaseApprovalDetailsDtoArray",
        purchaseApprovalDetailsDtoArray[i]
      );
      let obj = {
        grnId: purchaseApprovalDetailsDtoArray[i].GRNId,
        grnType: purchaseApprovalDetailsDtoArray[i].GRNType,
        grnNetAmount: purchaseApprovalDetailsDtoArray[i].NetAmount,
        // approvalDetailsId: ,
        supplierId: purchaseApprovalDetailsDtoArray[i].SupplierId,
      };
      purchaseApprovalArr.push(obj);
    }

    // {
    //   "addedBy": 0,
    //   "approvalId": 0,
    //   "approvalNo": "string",
    //   "isConsignment": true,
    //   "menuId": 0,
    //   "netApprovedAmount": 0,
    //   "privilege": "string",
    //   "purchaseApprovalDetailsDtoList": [
    //     {
    //       "approvalDetailsId": 0,
    //       "grnId": 0,
    //       "grnType": "string",
    //       "supplierId": 0
    //     }
    //   ],
    //   "remark": "string",
    //   "storeId": 0,
    //   "supplierId": 0
    // }

    let postObj = {
      addedBy: userInfo.userId,
      isConsignment: false,
      menuId: location.state.menuId,
      netApprovedAmount: Number(changeableNetAppramtVal),
      privilege: privilege,
      purchaseApprovalDetailsDtoList: purchaseApprovalArr,
      remark: data.remark,
      storeId: data.store !== null ? data.store?.id : 0,
      supplierId: data.supplier !== null ? data.supplier?.id : 0,
    };

    if (purchaseApprovalArr.length > 0) {
      setOpenPost(true);
      setFinalData(postObj);
    }
  };

  useEffect(() => {
    setValue("changeableNetApprovedamount", totalSelectedApprovedAmt);
  }, [totalSelectedApprovedAmt]);

  const renderActions = (row, index) => {
    return (
      <div>
        <Checkbox
          className=" w-3 h-3 border border-gray-600 rounded bg-transparent"
          type="checkbox"
          color="primary"
          onChange={(e) => {
            let totalSelectedNetApprovedAmt = 0;
            // if (e.target.checked === true) {
            // setValue("changeableNetApprovedamount", row?.ApprovedAmount);
            // }
            // else if (e.target.checked === false) {
            //   setValue("changeableNetApprovedamount", Number(0));
            // }

            let temp = [...dataResult];
            temp[index].isChecked = e.target.checked;
            setDataResult(temp);
            let purchaseApprovalDetailsArr = []; // onSubmit
            let tempGrnIds = [];
            for (let t = 0; t < temp.length; t++) {
              let obj = {
                grnId: temp[t].GRNId,
                grnType: temp[t].GRNType,
              };
              if (temp[t].isChecked === true) {
                console.log("temp[t]", temp[t]);
                totalSelectedNetApprovedAmt += temp[t].ApprovedAmount;
                tempGrnIds.push(obj);
                purchaseApprovalDetailsArr.push(temp[t]);
              }
              setGrnIds(tempGrnIds);
              setPurchaseApprovalDetailsDtoArray(purchaseApprovalDetailsArr);
            }

            setTotalSelectedApprovedAmt(
              Number(totalSelectedNetApprovedAmt).toFixed(2)
            );
          }}
        />
      </div>
    );
  };

  console.log("23456789mb", grnIds);
  function addApprovedRecord() {
    setOpenApprovedPost(false);
    setOpenBackdrop(true);
    handleApproveOrRejectPurchase(approvedPrivilege);
  }

  // Call getApprovedOrRejectPurchase API
  const handleApproveOrRejectPurchase = (privilage) => {
    getApprovedOrRejectPurchase(location?.state?.menuId, privilage, grnIds)
      .then((response) => {
        setOpenBackdrop(false);
        successAlert(response.data.message);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    fetchStore()
      .then((response) => response.data)
      .then((res) => {
        setStore(res.result);
      });

    fetchSupplier()
      .then((response) => response.data)
      .then((res) => {
        setSupplier(res.result);
      });

    // 398
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    // Call Purchase Approved List Table
    callTableDataApi();
  }, []);

  return (
    <div className="pt-14 ">
      <span className="grid justify-center text-center text-black font-bold text-xl pb-2 ">
        Purchase Approval
      </span>
      <form onSubmit={handleSubmit(onSubmitDataHandler)}>
        <div className="grid grid-cols-6 gap-3 py-2 ">
          <div className="">
            <DropdownField
              control={control}
              name="store"
              dataArray={store}
              placeholder="Store"
              isMulti={false}
              isSearchable={false}
              isClearable={false}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              error={errors.supplier}
              name="supplier"
              dataArray={supplier}
              placeholder="Suppliers*"
              isMulti={false}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div className="flex col-span-2 gap-3">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("fromDate", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("toDate", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="flex col-span-2 items-center space-x-2 text-sm whitespace-nowrap">
            <span className="px-1 font-bold whitespace-nowrap ">GRN Type:</span>

            <RadioField
              label=""
              name="grnType"
              control={control}
              dataArray={grnType}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex space-x-2 items-center text-sm whitespace-nowrap">
            <span className="px-1 font-bold whitespace-nowrap ">
              Payment Type:
            </span>

            <RadioField
              label=""
              name="paymentType"
              control={control}
              dataArray={paymentType}
            />
            <span className="px-1 font-bold whitespace-nowrap ">Status:</span>

            <RadioField
              label=""
              name="status"
              control={control}
              dataArray={status}
            />
            <div className="flex items-center py-1 space-x-2 ">
              {/* <div className="w-[20%] pl-4">
                <InputField
                  name="afterDays"
                  variant="outlined"
                  label=""
                  error={errors.afterDays}
                  control={control}
                />
              </div>
              <span className="font-semibold px-2 ">Days After</span>
              <RadioField
                label=""
                name="afterDays"
                control={control}
                dataArray={afterDays}
              /> */}
              <CommonButton
                type="button"
                searchIcon={true}
                onClick={() => {
                  callTableDataApi();
                }}
                className="bg-customBlue text-white"
              />
            </div>
          </div>
          <div className="flex  justify-end">
            <CommonButton
              type="button"
              label="Purchase Approval List"
              className="bg-customBlue text-white"
              onClick={() => {
                handleOpenPurchApprListModal();
              }}
            />
          </div>
        </div>

        {dataResult?.length > 0 ? (
          <div className="">
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              tableClass={"rounded h-[200px]"}
              renderActions={renderActions}
              populateTable={callTableDataApi}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              // handleSelectedRow={handleSelectedRow}
              tableHeading={"Purchase Approval List"}
              removeHeaders={[
                "id",
                "isChecked",
                "GRNId",
                "InvoiceNo",
                "SupplierId",
                "StoreId",
                "Cash_Credit",
                "totaladv",
              ]}
              highlightRow={true}
            />
          </div>
        ) : (
          <h1 className="font-semibold text-sm text-center text-gray-600 py-8">
            No Record Found...!
          </h1>
        )}

        <div className=" flex gap-3 py-2">
          <div className="flex items-center gap-2">
            <DatePickerFieldNew
              control={control}
              name="purchaseGrnDate"
              label="Party Invoice Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("purchaseGrnDate", newValue);
              }}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
            />

            <span className="font-semibold text-sm whitespace-nowrap">
              Net Approved Amount
            </span>

            <div className="w-[60%]">
              <InputField
                name="changeableNetApprovedamount"
                variant="outlined"
                label=""
                control={control}
              />
            </div>
          </div>

          <div className="w-[80%]">
            <InputField
              name="remark"
              variant="outlined"
              label="Remark"
              error={errors.remark}
              control={control}
            />
          </div>
          {userActions.map((action) => (
            <>
              {!action.isAction && action.action === "Create" ? (
                <fieldset
                  onClick={() => {
                    setPrivilege(action.action);
                  }}
                >
                  <CommonButton
                    type="submit"
                    label="Change"
                    className="bg-customBlue text-white"
                  />
                </fieldset>
              ) : null}
            </>
          ))}
        </div>

        <hr className="w-full border-t-1 border-blue-800" />

        <div className="flex gap-2 py-2">
          <div className="flex gap-2 items-center pt-2">
            <span className="font-semibold text-sm whitespace-nowrap">
              Net Approved ₹ :
            </span>
            <input
              className="bg-white text-[#5361e0]"
              value={Number(totalSelectedApprovedAmt).toFixed(2)}
              name="netapprovedamount"
              {...register("netapprovedamount")}
              disabled={true}
            />
          </div>
          {statusVal === "Rejected" || statusVal === "Pending" ? (
            <>
              <fieldset className="flex space-x-2 border border-gray-300 rounded mx-2 pb-2">
                <legend className="px-2 ml-2 lg:ml-0 font-bold text-xs text-gray-800">
                  Approved By
                </legend>

                {userActions.map((action) => (
                  <div className="flex gap-2">
                    {!action.isAction && action.action === "PurchaseApp" ? (
                      <CommonButton
                        type="button"
                        label="Purchase"
                        className="bg-customBlue text-white"
                        onClick={() => {
                          setOpenApprovedPost(true);
                          setApprovedPrivilege(action.action);
                        }}
                      />
                    ) : null}
                    {!action.isAction && action.action === "AccountsApp" ? (
                      <CommonButton
                        type="button"
                        label="Account"
                        className="bg-customBlue text-white"
                        onClick={() => {
                          setOpenApprovedPost(true);
                          setApprovedPrivilege(action.action);
                        }}
                      />
                    ) : null}
                    {!action.isAction && action.action === "CFOApp" ? (
                      <CommonButton
                        type="button"
                        onClick={() => {
                          setOpenApprovedPost(true);
                          setApprovedPrivilege(action.action);
                        }}
                        label="CFO"
                        className="bg-customBlue text-white"
                      />
                    ) : null}
                    {!action.isAction && action.action === "CEOApp" ? (
                      <CommonButton
                        type="button"
                        label="CEO"
                        className="bg-customBlue text-white"
                        onClick={() => {
                          setOpenApprovedPost(true);
                          setApprovedPrivilege(action.action);
                        }}
                      />
                    ) : null}
                  </div>
                ))}
              </fieldset>
            </>
          ) : null}

          {statusVal === "Approved" || statusVal === "Pending" ? (
            <div className="border-l-2  border-black">
              <>
                <fieldset className="flex space-x-2 border border-gray-300 rounded mx-2 pb-2 ">
                  <legend className="px-2 ml-2 lg:ml-0 font-bold text-xs text-gray-800">
                    Rejected By
                  </legend>
                  {/* <span>GII</span> */}
                  {userActions.map((action) => (
                    <>
                      {/* div className="flex gap-2 mr-6  border border-blue-400" */}
                      {!action.isAction && action.action === "PurchaseRej" ? (
                        <div className="pl-4">
                          <CommonButton
                            type="button"
                            onClick={() => {
                              setOpenApprovedPost(true);
                              setApprovedPrivilege(action.action);
                            }}
                            label="Purchase"
                            className="bg-customBlue text-white"
                          />
                        </div>
                      ) : null}
                      {!action.isAction && action.action === "AccountsRej" ? (
                        <CommonButton
                          type="button"
                          onClick={() => {
                            setOpenApprovedPost(true);
                            setApprovedPrivilege(action.action);
                          }}
                          label="Account"
                          className="bg-customBlue text-white"
                        />
                      ) : null}
                      {!action.isAction && action.action === "CFORej" ? (
                        <CommonButton
                          type="button"
                          onClick={() => {
                            setOpenApprovedPost(true);
                            setApprovedPrivilege(action.action);
                          }}
                          label="CFO"
                          className="bg-customBlue text-white"
                        />
                      ) : null}
                      {!action.isAction && action.action === "CEORej" ? (
                        <div className="pr-6">
                          <CommonButton
                            type="button"
                            onClick={() => {
                              setOpenApprovedPost(true);
                              setApprovedPrivilege(action.action);
                            }}
                            label="CEO"
                            className="bg-customBlue text-white"
                          />
                        </div>
                      ) : null}
                    </>
                  ))}
                </fieldset>
              </>
            </div>
          ) : null}
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      {openPurchApprListModal ? (
        <PurchaseApproveModal
          openPurchApprListModal={openPurchApprListModal}
          handleOpenPurchApprListModal={handleOpenPurchApprListModal}
          handleClosePurchApprListModal={handleClosePurchApprListModal}
          store={store}
          supplier={supplier}
          postPurchaseApproval={postPurchaseApproval}
          userActions={userActions}
          setPrivilege={setPrivilege}
          privilage={privilege}
          menuId={location?.state?.menuId}
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to change approved amount ?"
        confirmationButtonMsg="Add"
      />

      <ConfirmationModal
        confirmationOpen={openApprovedPost}
        confirmationHandleClose={handleCloseApprovedPost}
        confirmationSubmitFunc={addApprovedRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to approved ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
