import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useState } from "react";

/////function for key press
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function DrugTable(props) {
  const {
    selectedDrugRowID,
    setSelectedDrugRowID,
    setSelectedAltDrugRowID,
    selectedAltDrugRowID,
    lastIndex,
    setLastIndex,
    setSelectedItemDtlsID,
    unFocusDrugInputField,
    removeFocusInputSearch,
    setSelectedDrug,
    //
  } = props;

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  /////
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(allHeaders, ["Id"]);
  const tableContainerRef = React.useRef(null);

  ////code for key press output
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowRightPressed = useKeyPress("ArrowRight");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const enterPressed = useKeyPress("Enter");
  let arrLength = props.data.length;

  useEffect(() => {
    setSelectedDrugRowID(0);
    setSelectedItemDtlsID(null);
  }, []);

  //up arrow
  useEffect(() => {
    if (arrowUpPressed && selectedAltDrugRowID === null) {
      if (selectedDrugRowID <= 0) {
        setSelectedDrugRowID(arrLength - 1);
      } else if (selectedDrugRowID !== 0) {
        setSelectedDrugRowID(selectedDrugRowID - 1);
      }
    }
  }, [arrowUpPressed]);

  // down arrow
  useEffect(() => {
    if (arrowDownPressed && selectedAltDrugRowID === null) {
      if (selectedDrugRowID >= arrLength - 1) {
        setSelectedDrugRowID(0);
      } else if (selectedDrugRowID !== arrLength) {
        setSelectedDrugRowID(selectedDrugRowID + 1);
      }
    }
  }, [arrowDownPressed]);

  //right key
  React.useEffect(() => {
    if (arrowRightPressed === true && selectedAltDrugRowID === null) {
      setSelectedDrugRowID(null);
      setSelectedAltDrugRowID(0);
      setLastIndex(selectedDrugRowID);
    }
  }, [arrowRightPressed]);

  React.useEffect(() => {
    if (arrowLeftPressed === true && selectedAltDrugRowID === null) {
      setSelectedDrugRowID(null);
      setSelectedAltDrugRowID(0);
      setLastIndex(selectedDrugRowID);
    }
  }, [arrowLeftPressed]);

  //enter
  React.useEffect(() => {
    if (enterPressed === true && selectedAltDrugRowID === null) {
      if (selectedDrugRowID !== null && selectedDrugRowID !== undefined) {
        setSelectedDrugRowID(props.data[selectedDrugRowID]);
        setSelectedDrug(props.data[selectedDrugRowID]);
        props.setDrugTableData([]);
        props.setAltDrugTableData([]);
        props.setSelectedDrugRowData(null);
        props.setDrugSearchString(null);
        removeFocusInputSearch();
        ///pass name to function to detect which input clicked
      }
    }
  }, [enterPressed, selectedDrugRowID]);

  console.log("selectedDrugRowID", selectedDrugRowID);

  useEffect(() => {
    if (
      tableContainerRef?.current &&
      selectedDrugRowID !== null &&
      typeof selectedDrugRowID === "number"
    ) {
      const selectedRow = tableContainerRef?.current?.querySelector(
        `tr:nth-child(${selectedDrugRowID + 1})`
      );
      if (selectedRow) {
        selectedRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else {
      console.error("Invalid selectedDrugRowID:", selectedDrugRowID);
    }
  }, [selectedDrugRowID]);

  ///for----- to get alternate table onChange
  useEffect(() => {
    if (props.data[selectedDrugRowID]) {
      props.setSelectedGenericId(props.data[selectedDrugRowID].itemgenericid);
      props.setSelectedDrugRowData(props.data[selectedDrugRowID]);
    }
  }, [selectedDrugRowID]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <div className="grid w-auto" ref={tableContainerRef}>
              <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 10,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#7393B3",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightBlue",
                  },
                }}
                className="rounded lg:h-52 md:h-72"
              >
                <Table
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    border: 1,
                    borderColor: "#e0e0e0",

                    paddingY: "scroll",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDownCapture={handleKeyPress}
                    >
                      {headers.map((header, index) => (
                        <TableCell
                          sortDirection={orderBy === header ? order : false}
                          className="whitespace-nowrap"
                        >
                          <TableSortLabel
                            active={false}
                            direction={orderBy === header ? order : "asc"}
                            onClick={createSortHandler(header)}
                            key={index}
                          >
                            <span className="text-gray-600 font-bold">
                              {header}
                            </span>
                            {orderBy === header ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.data &&
                      props.data.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            hover={false}
                            style={{
                              backgroundColor:
                                selectedDrugRowID === index ? "#FFC44B" : "",
                            }}
                            sx={{
                              "& td": {
                                paddingY: 0.2,
                              },
                            }}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDownCapture={handleKeyPress}
                            onClick={(event) => {
                              setSelectedDrugRowID(index);
                              if (event.key === "Enter") {
                                event.preventDefault();
                              }
                              event.preventDefault();
                            }}
                          >
                            {headers &&
                              headers.map((header, i) => (
                                <TableCell
                                  className="whitespace-nowrap"
                                  key={i}
                                  onKeyPress={(event) => {
                                    event.preventDefault();
                                  }}
                                  onKeyDownCapture={handleKeyPress}
                                >
                                  {row[header]}
                                </TableCell>
                              ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  );
}
