import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../../../common/components/printModal/GeneratePrint";

const OtLayoutPrint = (props) => {
  const layoutRef = useRef(null);

  const { positions, selectedEquipmentArr, patientInfo } = props;

  useEffect(() => {
    setTimeout(() => {
      layoutRef && GeneratePrint(layoutRef);
    }, 500);
    // layoutRef && GeneratePrint(layoutRef);
  }, []);
  return (
    <div className=" " ref={layoutRef}>
      <div className="p-2 font-semibold flex justify-center">Operation Theater Layout Details</div>
      <div>
        <fieldset
          // ref={layoutRef1}
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
        >
          <div className="py-2 grid  grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm ">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfo && patientInfo["patientName"]}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfo && patientInfo.mrNo}
                </span>
              </div>
            </div>
          </div>
        </fieldset>

        <div
          className="overflow-auto w-full  bg-white shadow-lg lg:h-[200px] xl:h-[400px] 2xl:h-[600px] mt-5 "
          // onMouseUp={selectedRowData?.OTHeaderID === undefined && handleDragEnd}
          // onMouseLeave={() => {
          //   selectedRowData?.OTHeaderID === undefined && setDragging(false);
          // }}
        >
          <div className="w-full h-full">
            {selectedEquipmentArr.map((item, index) => {
              return (
                <span
                  key={item.id}
                  // className={`draggable text-white rounded cursor-grab ${
                  //   dragging ? "pointer-events-none" : ""
                  // }`}
                  style={{
                    position: "absolute",
                    transform: `translate(${positions[item.id]?.x}px, ${
                      positions[item.id]?.y
                    }px)`,
                    zIndex: item.zindex,
                  }}
                  // onMouseDown={(e) =>
                  //   selectedRowData?.OTHeaderID === undefined &&
                  //   handleDragStart(item.id, e)
                  // }
                >
                  <img
                    src={item.img}
                    style={{
                      height: `${item.height}px`,
                      width: `${item.width}px`,
                      transform: `rotate(${item.rotate}deg) ${
                        item.mirrorX ? "scaleX(-1)" : ""
                      } ${item.mirrorY ? "scaleY(-1)" : ""}`,
                    }}
                  />

                  <p className="text-slate-600 text-center">
                    {item.equipmentName}
                  </p>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtLayoutPrint;
