import { Box, Modal, TextField } from "@mui/material";
import React from "react";
import { ModalStyle } from "../../../../common/components/ModalStyle";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import { useForm } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import {
  completePhysioTest,
  getTestNoteDetails,
} from "../../services/PhysioWorkOrderViewServices";
import { successAlert } from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { useState } from "react";
import { useEffect } from "react";

const ChestPhysio = [
  {
    id: "DBE",
    value: "DBE",
    label: "DEEP BREATHING EXERCISE",
  },
  {
    id: "PLB",
    value: "PLB",
    label: "PURSED LIP BREATHING",
  },
  {
    id: "TEE",
    value: "TEE",
    label: "THORACIC EXPANSION EXERCISE",
  },
  {
    id: "DB",
    value: "DB",
    label: "DIAPHRAGMATIC BREATHING",
  },
  {
    id: "CPAV",
    value: "CPAV",
    label: "CHEST PERCUSSIONS AND VIBRATIONS",
  },
  {
    id: "S",
    value: "S",
    label: "SPIROMETER",
  },
];

const BedsideActivity = [
  {
    id: "ACTIVITY",
    label: "ACTIVITY",
    type: "text",
  },
  {
    id: "INDEPENDENT",
    label: "INDEPENDENT",
    type: "text",
  },
  {
    id: "MILD ASSISTANCE",
    label: "MILD ASSISTANCE",
    type: "text",
  },
  {
    id: "MAXIMUM ASSISTANCE",
    label: "MAXIMUM ASSISTANCE",
    type: "text",
  },
  {
    id: "BEDSIDE SITTING",
    label: "BEDSIDE SITTING",
    type: "text",
  },
  {
    id: "SITTINGINDEPENDENT",
    label: "SITTINGINDEPENDENT",
    value: "SITTINGINDEPENDENT",
    type: "check",
  },
  {
    id: "SITTINGMILDASSI",
    label: "SITTINGMILDASSI",
    value: "SITTINGMILDASSI",
    type: "check",
  },
  {
    id: "SITTINGMAXIMUMASSI",
    label: "SITTINGMAXIMUMASSI",
    value: "SITTINGMAXIMUMASSI",
    type: "check",
  },
  {
    id: "BEDSIDE STANDING",
    label: "BEDSIDE STANDING",
    type: "text",
  },
  {
    id: "STANDINGINDEPENDENT",
    label: "STANDINGINDEPENDENT",
    value: "STANDINGINDEPENDENT",
    type: "check",
  },
  {
    id: "STANDINGMILDASSI",
    label: "STANDINGMILDASSI",
    value: "STANDINGMILDASSI",
    type: "check",
  },
  {
    id: "STANDINGMAXIMUMASSI",
    label: "STANDINGMAXIMUMASSI",
    value: "STANDINGMAXIMUMASSI",
    type: "check",
  },
];

const Ambulation = [
  {
    id: "AMBULATION WITH WALKER",
    label: "AMBULATION WITH WALKER",
    type: "text",
  },
  {
    id: "FWB",
    label: "FWB",
    value: "FWB",
    type: "check",
  },
  {
    id: "PWB",
    label: "PWB",
    value: "PWB",
    type: "check",
  },
  {
    id: "NWB",
    label: "NWB",
    value: "NWB",
    type: "check",
  },
  {
    id: "AMBULATION WITHOUT WALKER",
    label: "AMBULATION WITHOUT WALKER",
    type: "text",
  },
  {
    id: "INDEPENDENT",
    label: "INDEPENDENT",
    value: "INDEPENDENT",
    type: "check",
  },
  {
    id: "MILD SUPPORT",
    label: "MILD SUPPORT",
    value: "MILD SUPPORT",
    type: "check",
  },
  {
    id: "MAX SUPPORT",
    label: "MAX SUPPORT",
    value: "MAX SUPPORT",
    type: "check",
  },
];

const Machine = [
  {
    id: "IRR",
    label: "IRR",
    value: "IRR",
  },
  {
    id: "IFT",
    label: "IFT",
    value: "IFT",
  },
  {
    id: "HCP",
    label: "HCP",
    value: "HCP",
  },
];

const UpperLimb = [
  {
    label: "SHOULDER FLEXION/EXTENSION",
    type: "rowHeading",
  },
  {
    label: "ELBOW FLEXION/EXTENSION",
    type: "rowHeading",
  },
  {
    label: "HANDLPUMPS",
    type: "rowHeading",
  },
  {
    label: "Active",
    type: "RSColumn",
  },
  {
    label: "Active Assisted",
    type: "RSColumn",
  },
  {
    label: "Passive",
    type: "RSColumn",
  },
  {
    id: "RSSFEACTIVE",
    label: "RSSFEACTIVE",
    value: "RSSFEACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSSFEACTIVEASSISTED",
    label: "RSSFEACTIVEASSISTED",
    value: "RSSFEACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSSFEPASSIVE",
    label: "RSSFEPASSIVE",
    value: "RSSFEPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSEFEACTIVE",
    label: "RSEFEACTIVE",
    value: "RSEFEACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSEFEACTIVEASSISTED",
    label: "RSEFEACTIVEASSISTED",
    value: "RSEFEACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSEFEPASSIVE",
    label: "RSEFEPASSIVE",
    value: "RSEFEPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSHACTIVE",
    label: "RSHACTIVE",
    value: "RSHACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSHACTIVEASSISTED",
    label: "RSHACTIVEASSISTED",
    value: "RSHACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSHPASSIVE",
    label: "RSHPASSIVE",
    value: "RSHPASSIVE",
    type: "RSCheck",
  },
  {
    label: "Active",
    type: "LSColumn",
  },
  {
    label: "Active Assisted",
    type: "LSColumn",
  },
  {
    label: "Passive",
    type: "LSColumn",
  },
  {
    id: "LSSFEACTIVE",
    label: "LSSFEACTIVE",
    value: "LSSFEACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSSFEACTIVEASSISTED",
    label: "LSSFEACTIVEASSISTED",
    value: "LSSFEACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSSFEPASSIVE",
    label: "LSSFEPASSIVE",
    value: "LSSFEPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSEFEACTIVE",
    label: "LSEFEACTIVE",
    value: "LSEFEACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSEFEACTIVEASSISTED",
    label: "LSEFEACTIVEASSISTED",
    value: "LSEFEACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSEFEPASSIVE",
    label: "LSEFEPASSIVE",
    value: "LSEFEPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSHACTIVE",
    label: "LSHACTIVE",
    value: "LSHACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSHACTIVEASSISTED",
    label: "LSHACTIVEASSISTED",
    value: "LSHACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSHPASSIVE",
    label: "LSHPASSIVE",
    value: "LSHPASSIVE",
    type: "LSCheck",
  },
];

const LowerLimb = [
  {
    label: "HIP FLEXION/EXTENSION",
    type: "rowHeading",
  },
  {
    label: "KNEE FLEXION/EXTENSION",
    type: "rowHeading",
  },
  {
    label: "ANKLE TOE MOVEMENTS",
    type: "rowHeading",
  },
  {
    label: "DYNAMIC QUADRICEPS",
    type: "rowHeading",
  },
  {
    label: "STATIC QUADRICEPS",
    type: "rowHeading",
  },
  {
    label: "STATIC HAMSTRINGS",
    type: "rowHeading",
  },
  {
    label: "Active",
    type: "RSColumn",
  },
  {
    label: "Active Assisted",
    type: "RSColumn",
  },
  {
    label: "Passive",
    type: "RSColumn",
  },
  {
    id: "RSHIPFEACTIVE",
    label: "RSHIPFEACTIVE",
    value: "RSHIPFEACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSHIPFEACTIVEASSISTED",
    label: "RSHIPFEACTIVEASSISTED",
    value: "RSHIPFEACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSHIPFEPASSIVE",
    label: "RSHIPFEPASSIVE",
    value: "RSHIPFEPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSKFEACTIVE",
    label: "RSKFEACTIVE",
    value: "RSKFEACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSKFEACTIVEASSISTED",
    label: "RSKFEACTIVEASSISTED",
    value: "RSKFEACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSKFEPASSIVE",
    label: "RSKFEPASSIVE",
    value: "RSKFEPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSATMACTIVE",
    label: "RSATMACTIVE",
    value: "RSATMACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSATMACTIVEASSISTED",
    label: "RSATMACTIVEASSISTED",
    value: "RSATMACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSATMPASSIVE",
    label: "RSATMPASSIVE",
    value: "RSATMPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSDQACTIVE",
    label: "RSDQACTIVE",
    value: "RSDQACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSDQACTIVEASSISTED",
    label: "RSDQACTIVEASSISTED",
    value: "RSDQACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSDQPASSIVE",
    label: "RSDQPASSIVE",
    value: "RSDQPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSSQACTIVE",
    label: "RSSQACTIVE",
    value: "RSSQACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSSQACTIVEASSISTED",
    label: "RSSQACTIVEASSISTED",
    value: "RSSQACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSSQPASSIVE",
    label: "RSSQPASSIVE",
    value: "RSSQPASSIVE",
    type: "RSCheck",
  },
  {
    id: "RSSHACTIVE",
    label: "RSSHACTIVE",
    value: "RSSHACTIVE",
    type: "RSCheck",
  },
  {
    id: "RSSHACTIVEASSISTED",
    label: "RSSHACTIVEASSISTED",
    value: "RSSHACTIVEASSISTED",
    type: "RSCheck",
  },
  {
    id: "RSSHPASSIVE",
    label: "RSSHPASSIVE",
    value: "RSSHPASSIVE",
    type: "RSCheck",
  },
  {
    label: "Active",
    type: "LSColumn",
  },
  {
    label: "Active Assisted",
    type: "LSColumn",
  },
  {
    label: "Passive",
    type: "LSColumn",
  },
  {
    id: "LSHIPFEACTIVE",
    label: "RSHIPFEACTIVE",
    value: "RSHIPFEACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSHIPFEACTIVEASSISTED",
    label: "RSHIPFEACTIVEASSISTED",
    value: "RSHIPFEACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSHIPFEPASSIVE",
    label: "RSHIPFEPASSIVE",
    value: "RSHIPFEPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSKFEACTIVE",
    label: "RSKFEACTIVE",
    value: "RSKFEACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSKFEACTIVEASSISTED",
    label: "RSKFEACTIVEASSISTED",
    value: "RSKFEACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSKFEPASSIVE",
    label: "RSKFEPASSIVE",
    value: "RSKFEPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSATMACTIVE",
    label: "RSATMACTIVE",
    value: "RSATMACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSATMACTIVEASSISTED",
    label: "RSATMACTIVEASSISTED",
    value: "RSATMACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSATMPASSIVE",
    label: "RSATMPASSIVE",
    value: "RSATMPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSDQACTIVE",
    label: "RSDQACTIVE",
    value: "RSDQACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSDQACTIVEASSISTED",
    label: "RSDQACTIVEASSISTED",
    value: "RSDQACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSDQPASSIVE",
    label: "RSDQPASSIVE",
    value: "RSDQPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSSQACTIVE",
    label: "RSSQACTIVE",
    value: "RSSQACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSSQACTIVEASSISTED",
    label: "RSSQACTIVEASSISTED",
    value: "RSSQACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSSQPASSIVE",
    label: "RSSQPASSIVE",
    value: "RSSQPASSIVE",
    type: "LSCheck",
  },
  {
    id: "LSSHACTIVE",
    label: "RSSHACTIVE",
    value: "RSSHACTIVE",
    type: "LSCheck",
  },
  {
    id: "LSSHACTIVEASSISTED",
    label: "RSSHACTIVEASSISTED",
    value: "RSSHACTIVEASSISTED",
    type: "LSCheck",
  },
  {
    id: "LSSHPASSIVE",
    label: "RSSHPASSIVE",
    value: "RSSHPASSIVE",
    type: "LSCheck",
  },
];

const PhysioNoteModal = (props) => {
  const { control, watch, setValue, handleSubmit, register, reset } = useForm({
    defaultValues: {
      DFE: false,
      PLB: false,
      TEE: false,
      DB: false,
      CPAV: false,
      S: false,
      DBE: false,
      SITTINGINDEPENDENT: false,
      SITTINGMILDASSI: false,
      SITTINGMAXIMUMASSI: false,
      STANDINGINDEPENDENT: false,
      STANDINGMILDASSI: false,
      STANDINGMAXIMUMASSI: false,
      ambulationYes: false,
      ambulationNo: false,
      FWB: false,
      PWB: false,
      NWB: false,
      INDEPENDENT: false,
      "MILD SUPPORT": false,
      "MAX SUPPORT": false,
      wheelChairAmbu: false,
      IRR: false,
      IFT: false,
      HCP: false,
      lowerLimb: false,
      upperLimb: false,
      RSSFEACTIVE: false,
      RSSFEACTIVEASSISTED: false,
      RSSFEPASSIVE: false,
      RSEFEACTIVE: false,
      RSEFEACTIVEASSISTED: false,
      RSEFEPASSIVE: false,
      RSHACTIVE: false,
      RSHACTIVEASSISTED: false,
      RSHPASSIVE: false,
      LSSFEACTIVE: false,
      LSSFEACTIVEASSISTED: false,
      LSSFEPASSIVE: false,
      LSEFEACTIVE: false,
      LSEFEACTIVEASSISTED: false,
      LSEFEPASSIVE: false,
      LSHACTIVE: false,
      LSHACTIVEASSISTED: false,
      LSHPASSIVE: false,
      RSHIPFEACTIVE: false,
      RSHIPFEACTIVEASSISTED: false,
      RSHIPFEPASSIVE: false,
      RSKFEACTIVE: false,
      RSKFEACTIVEASSISTED: false,
      RSKFEPASSIVE: false,
      RSATMACTIVE: false,
      RSATMACTIVEASSISTED: false,
      RSATMPASSIVE: false,
      RSDQACTIVE: false,
      RSDQACTIVEASSISTED: false,
      RSDQPASSIVE: false,
      RSSQACTIVE: false,
      RSSQACTIVEASSISTED: false,
      RSSQPASSIVE: false,
      RSSHACTIVE: false,
      RSSHACTIVEASSISTED: false,
      RSSHPASSIVE: false,
      LSHIPFEACTIVE: false,
      LSHIPFEACTIVEASSISTED: false,
      LSHIPFEPASSIVE: false,
      LSKFEACTIVE: false,
      LSKFEACTIVEASSISTED: false,
      LSKFEPASSIVE: false,
      LSATMACTIVE: false,
      LSATMACTIVEASSISTED: false,
      LSATMPASSIVE: false,
      LSDQACTIVE: false,
      LSDQACTIVEASSISTED: false,
      LSDQPASSIVE: false,
      LSSQACTIVE: false,
      LSSQACTIVEASSISTED: false,
      LSSQPASSIVE: false,
      LSSHACTIVE: false,
      LSSHACTIVEASSISTED: false,
      LSSHPASSIVE: false,
      ambulationRemark: "",
      remark: "",
    },
  });
  let getUpperLimb = watch("upperLimb");
  let getLowerLimb = watch("lowerLimb");
  let getAmbulationYes = watch("ambulationYes");
  let loggedUser = JSON.parse(localStorage.getItem("userInfo"));

  const {
    openNoteModal,
    setOpenNoteModal,
    physioTestListTable,
    selectedTest,
    setSelectedTest,
    viewNoteDetails,
    setViewNoteDetails,
    privilege,
    setPrivilege,
    getPatientType,
    selectedRow,
    selectedPhysioTest,
    setSelectedPhysioTest,
  } = props;
  const [finalObj, setFinalObj] = useState(null);
  const [completeConfirmation, setCompleteConfirmation] = useState(false);

  const submitCompleteData = (data) => {
    let postObj = {
      addedBy: loggedUser?.userId,
      ambGivenin24Hrs_YesRemark: data.ambulationRemark,
      isAmbGivenin24Hrs_No: data.ambulationNo,
      isAmbGivenin24Hrs_Yes: data.ambulationYes,
      isAmbIndep: data.INDEPENDENT,
      isAmbMildSupp: data["MILD SUPPORT"],
      isAmbiaxSupp: data["MAX SUPPORT"],
      isAnkleActiveAsst_Lft: data.LSATMACTIVEASSISTED,
      isAnkleActiveAsst_Rgt: data.RSATMACTIVEASSISTED,
      isAnkleActive_Lft: data.LSATMACTIVE,
      isAnkleActive_Rgt: data.RSATMACTIVE,
      isAnklePassive_Lft: data.LSATMPASSIVE,
      isAnklePassive_Rgt: data.RSATMPASSIVE,
      isBedSideIndep: data.SITTINGINDEPENDENT,
      isBedSideMaxAsst: data.SITTINGMAXIMUMASSI,
      isBedSideMldAsst: data.SITTINGMILDASSI,
      isBedStandIndep: data.STANDINGINDEPENDENT,
      isBedStandMaxAsst: data.STANDINGMAXIMUMASSI,
      isBedStandMildAsst: data.STANDINGMILDASSI,
      isChestPercus: data.CPAV,
      isDeepBreath: data.DBE,
      isDiaphBreath: data.DB,
      isDynamicActiveAsst_Lft: data.LSDQACTIVEASSISTED,
      isDynamicActiveAsst_Rgt: data.RSDQACTIVEASSISTED,
      isDynamicActive_Lft: data.LSDQACTIVE,
      isDynamicActive_Rgt: data.RSDQACTIVE,
      isDynamicPassive_Lft: data.LSDQPASSIVE,
      isDynamicPassive_Rgt: data.RSDQPASSIVE,
      isElbowFlexActiveAsst_Lft: data.LSEFEACTIVEASSISTED,
      isElbowFlexActiveAsst_Rgt: data.RSEFEACTIVEASSISTED,
      isElbowFlexActive_Lft: data.LSEFEACTIVE,
      isElbowFlexActive_Rgt: data.RSEFEACTIVE,
      isElbowFlexPassive_Lft: data.LSEFEPASSIVE,
      isElbowFlexPassive_Rgt: data.RSEFEPASSIVE,
      isFWB: data.FWB,
      isHCP: data.HCP,
      isHandpActiveAsst_Lft: data.LSHACTIVEASSISTED,
      isHandpActiveAsst_Rgt: data.RSHACTIVEASSISTED,
      isHandpActive_Lft: data.LSHACTIVE,
      isHandpActive_Rgt: data.RSHACTIVE,
      isHandpPassive_Lft: data.LSHPASSIVE,
      isHandpPassive_Rgt: data.RSHPASSIVE,
      isHipFlexActiveAsst_Lft: data.LSHIPFEACTIVEASSISTED,
      isHipFlexActiveAsst_Rgt: data.RSHIPFEACTIVEASSISTED,
      isHipFlexActive_Lft: data.LSHIPFEACTIVE,
      isHipFlexActive_Rgt: data.RSHIPFEACTIVE,
      isHipFlexPassive_Lft: data.LSHIPFEPASSIVE,
      isHipFlexPassive_Rgt: data.RSHIPFEPASSIVE,
      isIFT: data.IFT,
      isIRR: data.IRR,
      isKneeFlexActiveAsst_Lft: data.LSKFEACTIVEASSISTED,
      isKneeFlexActiveAsst_Rgt: data.RSKFEACTIVEASSISTED,
      isKneeFlexActive_Lft: data.LSKFEACTIVE,
      isKneeFlexActive_Rgt: data.RSKFEACTIVE,
      isKneeFlexPassive_Lft: data.LSKFEPASSIVE,
      isKneeFlexPassive_Rgt: data.RSKFEPASSIVE,
      isLowerLimb: data.lowerLimb,
      isNWB: data.NWB,
      isPWB: data.PWB,
      isPursedLip: data.PLB,
      isSholderFlexActiveAsst_Lft: data.LSSFEACTIVEASSISTED,
      isSholderFlexActiveAsst_Rgt: data.RSSFEACTIVEASSISTED,
      isSholderFlexActive_Lft: data.LSSFEACTIVE,
      isSholderFlexActive_Rgt: data.RSSFEACTIVE,
      isSholderFlexPassive_Lft: data.LSSFEPASSIVE,
      isSholderFlexPassive_Rgt: data.RSSFEPASSIVE,
      isSpirometer: data.S,
      isStaticHActiveAsst_Lft: data.LSSHACTIVEASSISTED,
      isStaticHActiveAsst_Rgt: data.RSSHACTIVEASSISTED,
      isStaticHActive_Lft: data.LSSHACTIVE,
      isStaticHActive_Rgt: data.RSSHACTIVE,
      isStaticHPassive_Lft: data.LSSHPASSIVE,
      isStaticHPassive_Rgt: data.RSSHPASSIVE,
      isStaticQActiveAsst_Lft: data.LSSQACTIVEASSISTED,
      isStaticQActiveAsst_Rgt: data.RSSQACTIVEASSISTED,
      isStaticQActive_Lft: data.LSSQACTIVE,
      isStaticQActive_Rgt: data.RSSQACTIVE,
      isStaticQPassive_Lft: data.LSSQPASSIVE,
      isStaticQPassive_Rgt: data.RSSQPASSIVE,
      isThoracExpan: data.TEE,
      isUpperLimb: data.upperLimb,
      isWheelAmb: data.wheelChairAmbu,
      menuId: 339,
      orderDtlId: selectedTest?.OrderDtlsId,
      privilege: privilege,
      opdIpdExternal: selectedRow?.Opd_Ipd_External,
      opdIpdExternalId: selectedRow?.Opd_Ipd_External_Id,
      tariffServiceId: selectedTest?.TariffServiceId,
      remark: data.remark,
    };

    setCompleteConfirmation(true);
    setFinalObj(postObj);
  };

  function addCompletePhysioTest() {
    completePhysioTest(finalObj).then((response) => {
      physioTestListTable();
      successAlert(response.data.message);
      setOpenNoteModal(false);
      setCompleteConfirmation(false);
      reset();
      setPrivilege("");
    });
  }

  console.log("viewNoteDetails098", viewNoteDetails);
  useEffect(() => {
    if (viewNoteDetails === true) {
      getTestNoteDetails(
        selectedTest?.OrderDtlsId || selectedPhysioTest?.OrderDtlsId
      ).then((response) => {
        let patchData = response.data.result;

        setValue("DBE", patchData.IsDeepBreath === 1 ? true : false);
        setValue("PLB", patchData.IsPursedLip === 1 ? true : false);
        setValue("TEE", patchData.IsThoracExpan === 1 ? true : false);
        setValue("DB", patchData.IsDiaphBreath === 1 ? true : false);
        setValue("CPAV", patchData.IsChestPercus === 1 ? true : false);
        setValue("S", patchData.IsSpirometer === 1 ? true : false);
        setValue("upperLimb", patchData.IsUpperLimb === 1 ? true : false);
        setValue("lowerLimb", patchData.IsLowerLimb === 1 ? true : false);
        setValue(
          "RSSFEACTIVE",
          patchData.IsSholderFlexActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSSFEACTIVEASSISTED",
          patchData.IsSholderFlexActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSSFEPASSIVE",
          patchData.IsSholderFlexPassive_Rgt === 1 ? true : false
        );
        setValue(
          "RSEFEACTIVE",
          patchData.IsElbowFlexActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSEFEACTIVEASSISTED",
          patchData.IsElbowFlexActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSEFEPASSIVE",
          patchData.IsElbowFlexPassive_Rgt === 1 ? true : false
        );
        setValue("RSHACTIVE", patchData.IsHandpActive_Rgt === 1 ? true : false);
        setValue(
          "RSHACTIVEASSISTED",
          patchData.IsHandpActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSHPASSIVE",
          patchData.IsHandpPassive_Rgt === 1 ? true : false
        );
        setValue(
          "LSSFEACTIVE",
          patchData.IsSholderFlexActive_Lft === 1 ? true : false
        );
        setValue(
          "LSSFEACTIVEASSISTED",
          patchData.IsSholderFlexActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSSFEPASSIVE",
          patchData.IsSholderFlexPassive_Lft === 1 ? true : false
        );
        setValue(
          "LSEFEACTIVE",
          patchData.IsElbowFlexActive_Lft === 1 ? true : false
        );
        setValue(
          "LSEFEACTIVEASSISTED",
          patchData.IsElbowFlexActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSEFEPASSIVE",
          patchData.IsElbowFlexPassive_Lft === 1 ? true : false
        );
        setValue("LSHACTIVE", patchData.IsHandpActive_Lft === 1 ? true : false);
        setValue(
          "LSHACTIVEASSISTED",
          patchData.IsHandpActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSHPASSIVE",
          patchData.IsHandpPassive_Lft === 1 ? true : false
        );
        setValue(
          "RSHIPFEACTIVE",
          patchData.IsHipFlexActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSHIPFEACTIVEASSISTED",
          patchData.IsHipFlexActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSHIPFEPASSIVE",
          patchData.IsHipFlexPassive_Rgt === 1 ? true : false
        );
        setValue(
          "RSKFEACTIVE",
          patchData.IsKneeFlexActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSKFEACTIVEASSISTED",
          patchData.IsKneeFlexActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSKFEPASSIVE",
          patchData.IsKneeFlexPassive_Rgt === 1 ? true : false
        );
        setValue(
          "RSATMACTIVE",
          patchData.IsAnkleActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSATMACTIVEASSISTED",
          patchData.IsAnkleActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSATMPASSIVE",
          patchData.IsAnklePassive_Rgt === 1 ? true : false
        );
        setValue(
          "RSDQACTIVE",
          patchData.IsDynamicActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSDQACTIVEASSISTED",
          patchData.IsDynamicActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSDQPASSIVE",
          patchData.IsDynamicPassive_Rgt === 1 ? true : false
        );
        setValue(
          "RSSQACTIVE",
          patchData.IsStaticQActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSSQACTIVEASSISTED",
          patchData.IsStaticQActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSSQPASSIVE",
          patchData.IsStaticQPassive_Rgt === 1 ? true : false
        );
        setValue(
          "RSSHACTIVE",
          patchData.IsStaticHActive_Rgt === 1 ? true : false
        );
        setValue(
          "RSSHACTIVEASSISTED",
          patchData.IsStaticHActiveAsst_Rgt === 1 ? true : false
        );
        setValue(
          "RSSHPASSIVE",
          patchData.IsStaticHPassive_Rgt === 1 ? true : false
        );
        setValue(
          "LSHIPFEACTIVE",
          patchData.IsHipFlexActive_Lft === 1 ? true : false
        );
        setValue(
          "LSHIPFEACTIVEASSISTED",
          patchData.IsHipFlexActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSHIPFEPASSIVE",
          patchData.IsHipFlexPassive_Lft === 1 ? true : false
        );
        setValue(
          "LSKFEACTIVE",
          patchData.IsKneeFlexActive_Lft === 1 ? true : false
        );
        setValue(
          "LSKFEACTIVEASSISTED",
          patchData.IsKneeFlexActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSKFEPASSIVE",
          patchData.IsKneeFlexPassive_Lft === 1 ? true : false
        );
        setValue(
          "LSATMACTIVE",
          patchData.IsAnkleActive_Lft === 1 ? true : false
        );
        setValue(
          "LSATMACTIVEASSISTED",
          patchData.IsAnkleActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSATMPASSIVE",
          patchData.IsAnklePassive_Lft === 1 ? true : false
        );
        setValue(
          "LSDQACTIVE",
          patchData.IsDynamicActive_Lft === 1 ? true : false
        );
        setValue(
          "LSDQACTIVEASSISTED",
          patchData.IsDynamicActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSDQPASSIVE",
          patchData.IsDynamicPassive_Lft === 1 ? true : false
        );
        setValue(
          "LSSQACTIVE",
          patchData.IsStaticQActive_Lft === 1 ? true : false
        );
        setValue(
          "LSSQACTIVEASSISTED",
          patchData.IsStaticQActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSSQPASSIVE",
          patchData.IsStaticQPassive_Lft === 1 ? true : false
        );
        setValue(
          "LSSHACTIVE",
          patchData.IsStaticHActive_Lft === 1 ? true : false
        );
        setValue(
          "LSSHACTIVEASSISTED",
          patchData.IsStaticHActiveAsst_Lft === 1 ? true : false
        );
        setValue(
          "LSSHPASSIVE",
          patchData.IsStaticHPassive_Lft === 1 ? true : false
        );
        setValue(
          "SITTINGINDEPENDENT",
          patchData.IsBedSideIndep === 1 ? true : false
        );
        setValue(
          "SITTINGMILDASSI",
          patchData.IsBedSideMldAsst === 1 ? true : false
        );
        setValue(
          "SITTINGMAXIMUMASSI",
          patchData.IsBedSideMaxAsst === 1 ? true : false
        );
        setValue(
          "STANDINGINDEPENDENT",
          patchData.IsBedStandIndep === 1 ? true : false
        );
        setValue(
          "STANDINGMILDASSI",
          patchData.IsBedStandMildAsst === 1 ? true : false
        );
        setValue(
          "STANDINGMAXIMUMASSI",
          patchData.IsBedStandMaxAsst === 1 ? true : false
        );
        setValue(
          "ambulationYes",
          patchData.IsAmbGivenin24Hrs_Yes === 1 ? true : false
        );
        setValue(
          "ambulationYNo",
          patchData.IsAmbGivenin24Hrs_No === 1 ? true : false
        );
        setValue("ambulationRemark", patchData.AmbGivenin24Hrs_YesRemark);
        setValue("FWB", patchData.IsFWB === 1 ? true : false);
        setValue("PWB", patchData.IsPWB === 1 ? true : false);
        setValue("NWB", patchData.IsNWB === 1 ? true : false);
        setValue("INDEPENDENT", patchData.IsAmbIndep === 1 ? true : false);
        setValue("MILD SUPPORT", patchData.IsAmbMildSupp === 1 ? true : false);
        setValue("MAX SUPPORT", patchData.IsAmbMaxSupp === 1 ? true : false);
        setValue("wheelChairAmbu", patchData.IsWheelAmb === 1 ? true : false);
        setValue("IRR", patchData.IsIRR === 1 ? true : false);
        setValue("IFT", patchData.IsIFT === 1 ? true : false);
        setValue("HCP", patchData.IsHCP === 1 ? true : false);
        setValue("remark", patchData.Remark);
      });
    }
  }, [viewNoteDetails]);

  return (
    <Modal open={openNoteModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxHeight: "80%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          border: "1px solid gray",
          boxShadow: 20,
          p: 2,
        }}
      >
        <form onSubmit={handleSubmit(submitCompleteData)}>
          <button
            type="button"
            onClick={() => {
              setOpenNoteModal(false);
              setViewNoteDetails(false);
              setSelectedTest(null);
              setSelectedPhysioTest(null);
              reset();
              setPrivilege("");
            }}
          >
            <CancelPresentationIconButton />
          </button>
          <div className="space-y-2">
            <label className="text-lg font-semibold">Physio Note</label>
            <fieldset className="border-[1px] border-gray-400 px-2 ">
              <legend className="text-sm font-semibold px-2">
                CHEST PHYSIOTHERAPY
              </legend>
              <div className="grid grid-cols-2 lg:grid-cols-3">
                {ChestPhysio.map((val, index) => {
                  return (
                    <fieldset disabled={viewNoteDetails === true}>
                      <CheckBoxField
                        control={control}
                        name={val.value}
                        label={val.label}
                      />
                    </fieldset>
                  );
                })}
              </div>
            </fieldset>
            <div className="flex space-x-4 items-center">
              <label className="text-sm font-semibold">
                LIMB PHYSIOTHERAPY
              </label>
              <div className="flex space-x-2">
                <fieldset
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setValue("lowerLimb", false);
                    }
                  }}
                  disabled={viewNoteDetails === true}
                >
                  <CheckBoxField
                    control={control}
                    name="upperLimb"
                    label="UPPER LIMB"
                  />
                </fieldset>
                <fieldset
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setValue("upperLimb", false);
                    }
                  }}
                  disabled={viewNoteDetails === true}
                >
                  <CheckBoxField
                    control={control}
                    name="lowerLimb"
                    label="LOWER LIMB"
                  />
                </fieldset>
              </div>
            </div>
            {getUpperLimb === true && (
              <div className="flex w-full">
                <fieldset className="space-y-5 border-[1px] border-gray-400 px-2">
                  <legend className="text-sm font-semibold px-2">
                    UPPER LIMB
                  </legend>
                  {UpperLimb.map((val) => {
                    return (
                      <div className="text-sm relative top-2.5">
                        {val.type === "rowHeading" && (
                          <label>{val.label}</label>
                        )}
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset className="grid grid-cols-3 space-x-2 border-[1px] border-gray-400 px-2">
                  <legend className="text-sm font-semibold px-2">
                    RIGHT SIDE
                  </legend>
                  {UpperLimb.map((val) => {
                    return (
                      <div className="text-sm text-center">
                        {val.type === "RSColumn" ? (
                          <label>{val.label}</label>
                        ) : val.type === "RSCheck" ? (
                          <fieldset disabled={viewNoteDetails === true}>
                            <CheckBoxField control={control} name={val.id} />
                          </fieldset>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset className="grid grid-cols-3 space-x-2 border-[1px] border-gray-400 px-2">
                  <legend className="text-sm font-semibold px-2">
                    LEFT SIDE
                  </legend>
                  {UpperLimb.map((val) => {
                    return (
                      <div className="text-sm text-center">
                        {val.type === "LSColumn" ? (
                          <label>{val.label}</label>
                        ) : val.type === "LSCheck" ? (
                          <fieldset disabled={viewNoteDetails === true}>
                            <CheckBoxField control={control} name={val.id} />
                          </fieldset>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            )}
            {getLowerLimb === true && (
              <div className="flex w-full">
                <fieldset className="space-y-5 border-[1px] border-gray-400 px-2">
                  <legend className="text-sm font-semibold px-2">
                    Lower LIMB
                  </legend>
                  {LowerLimb.map((val) => {
                    return (
                      <div className="text-sm relative top-2.5">
                        {val.type === "rowHeading" && (
                          <label>{val.label}</label>
                        )}
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset className="grid grid-cols-3 space-x-2 border-[1px] border-gray-400 px-2">
                  <legend className="text-sm font-semibold px-2">
                    RIGHT SIDE
                  </legend>
                  {LowerLimb.map((val) => {
                    return (
                      <div className="text-sm text-center">
                        {val.type === "RSColumn" ? (
                          <label>{val.label}</label>
                        ) : val.type === "RSCheck" ? (
                          <fieldset disabled={viewNoteDetails === true}>
                            <CheckBoxField control={control} name={val.id} />
                          </fieldset>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset className="grid grid-cols-3 space-x-2 border-[1px] border-gray-400 px-2">
                  <legend className="text-sm font-semibold px-2">
                    LEFT SIDE
                  </legend>
                  {LowerLimb.map((val) => {
                    return (
                      <div className="text-sm text-center">
                        {val.type === "LSColumn" ? (
                          <label>{val.label}</label>
                        ) : val.type === "LSCheck" ? (
                          <fieldset disabled={viewNoteDetails === true}>
                            <CheckBoxField control={control} name={val.id} />
                          </fieldset>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            )}
            <fieldset className="border-[1px] border-gray-400 px-2">
              <legend className="text-sm font-semibold px-2">
                BEDSIDE ACTIVITY
              </legend>
              <div className="grid grid-cols-4 items-center justify-center">
                {BedsideActivity.map((val) => {
                  return (
                    <>
                      {val.type === "text" ? (
                        <div className="text-xs font-semibold">{val.label}</div>
                      ) : (
                        <fieldset disabled={viewNoteDetails === true}>
                          <CheckBoxField control={control} name={val.id} />
                        </fieldset>
                      )}
                    </>
                  );
                })}
              </div>
            </fieldset>
            <div className="flex space-x-2 items-center">
              <label className="text-sm font-semibold w-64">
                Ambulation Given in 24 Hrs.
              </label>
              <fieldset
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setValue("ambulationNo", false);
                  }
                }}
                disabled={viewNoteDetails === true}
              >
                <CheckBoxField
                  control={control}
                  name="ambulationYes"
                  label="Yes"
                />
              </fieldset>
              <fieldset
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setValue("ambulationYes", false);
                    setValue("ambulationRemark", "");
                  }
                }}
                disabled={viewNoteDetails === true}
              >
                <CheckBoxField
                  control={control}
                  name="ambulationNo"
                  label="No"
                />
              </fieldset>
              <TextField
                className="w-full"
                name="ambulationRemark"
                label="Ambulation Remark"
                minRows={1}
                maxRows={1}
                multiline
                size="small"
                {...register("ambulationRemark")}
                disabled={
                  viewNoteDetails === true || getAmbulationYes === false
                }
              />
            </div>
            <div>
              <div className="grid grid-cols-4 items-center">
                {Ambulation.map((val) => {
                  return (
                    <>
                      {val.type === "text" ? (
                        <div
                          className={`text-xs font-semibold ${
                            val.label === "AMBULATION WITHOUT WALKER" &&
                            "row-span-2"
                          }`}
                        >
                          {val.label}
                        </div>
                      ) : (
                        <fieldset disabled={viewNoteDetails === true}>
                          <CheckBoxField
                            control={control}
                            name={val.id}
                            label={val.label}
                          />
                        </fieldset>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="grid grid-cols-4">
                <div></div>
                <fieldset
                  className="col-span-2"
                  disabled={viewNoteDetails === true}
                >
                  <CheckBoxField
                    control={control}
                    name="wheelChairAmbu"
                    label="WHEELCHAIR AMBULATION"
                  />
                </fieldset>
              </div>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="text-sm font-semibold">MACHINE</div>
              {Machine.map((val) => {
                return (
                  <fieldset disabled={viewNoteDetails === true}>
                    <CheckBoxField
                      control={control}
                      name={val.id}
                      label={val.label}
                    />
                  </fieldset>
                );
              })}
            </div>
            <div className="flex space-x-2">
              <TextField
                name="remark"
                label="Remark"
                className="w-full"
                minRows={1}
                maxRows={1}
                multiline
                size="small"
                {...register("remark")}
                disabled={viewNoteDetails === true}
              />
              {viewNoteDetails === false && (
                <div className="flex space-x-2 justify-end">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset();
                      setPrivilege("");
                    }}
                  />
                  <CommonButton
                    type="submit"
                    label="Save"
                    className="bg-customGreen text-white"
                  />
                </div>
              )}
            </div>
          </div>
        </form>
        <ConfirmationModal
          confirmationOpen={completeConfirmation}
          confirmationHandleClose={() => {
            setCompleteConfirmation(false);
          }}
          confirmationSubmitFunc={addCompletePhysioTest}
          confirmationLabel="Confirmation "
          confirmationMsg="Are you sure want to add record ?"
          confirmationButtonMsg="Yes"
        />
      </Box>
    </Modal>
  );
};

export default PhysioNoteModal;
