import React from "react";
import { Box, Modal, TextField } from "@mui/material";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import BatchSelectionTable from "./BatchSelectionTable";
import { useForm } from "react-hook-form";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 20,
  p: 2,
};

const StoreGRNModal = (props) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm();
  return (
    <>
      <Modal open={props.isDialogOpen}>
        <Box sx={ModalStyle}>
          <div className="px-2 mb-2 w-full">
            <div className=" flex justify-between items-center">
              <h1 className="text-base font-semibold  my-1 whitespace-nowrap">
                Select Batch
              </h1>
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleDialogClose();
                }}
              />
            </div>
            <div className="flex justify-between rounded border space-x-4 bg-gray-100 p-2 w-full mb-2">
              <div className="flex space-x-2 text-sm">
                <h1 className="text-gray-600 font-semibold">
                  Item Name &nbsp;:
                </h1>
                <h1>{props.batchData[0]?.ItemName}</h1>
              </div>
              <div className="flex space-x-2 text-sm">
                <h1 className="text-gray-600 font-semibold">
                  Item Code &nbsp;:
                </h1>
                <h1>{props.batchData[0]?.ItemCode}</h1>
              </div>
            </div>
            <TextField
              control={control}
              name="searchBatchCode"
              size="small"
              autoComplete="off"
              autoFocus
              {...register("searchBatchCode")}
              label="Batch Number"
              onMouseDown={props.handleInputClick}
              onKeyPress={props.handleKeyPressForSearchBatchCode}
              inputRef={{
                ...register(`searchBatchCode`, {
                  onChange: (e) => {
                    props.handleInputChangeForBatchCode(e);
                  },
                }),
              }}
            />
            {props.searchValue === "" ? (
              <div className=" mt-1">
                <BatchSelectionTable
                  data={props.batchData}
                  setBatchData={props.setBatchData}
                  open={props.open}
                  selectedRow={props.selectedRow}
                  setSelectedRow={props.setSelectedRow}
                  handleClose={props.handleDialogClose}
                  setIsDialogOpen={props.setIsDialogOpen}
                />
              </div>
            ) : props.matchingEntries.length > 0 ? (
              <div className="mt-1">
                <BatchSelectionTable
                  data={props.matchingEntries}
                  setBatchData={props.setBatchData}
                  open={props.open}
                  selectedRow={props.selectedRow}
                  setSelectedRow={props.setSelectedRow}
                  handleClose={props.handleDialogClose}
                  setIsDialogOpen={props.setIsDialogOpen}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default StoreGRNModal;