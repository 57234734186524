import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function NoApiCommonMasterTable(props) {
  console.log("patient table props", props);
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  console.log("api response in table", props);
  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  //actions
  const [printBill, setPrintBill] = React.useState(false);
  // const [Checkbox, setCheckbox] = React.useState(false);

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);
  console.log("data object in table is:", props.data);
  const headers = removeHeaders(allHeaders, [
    "id",
    "ChargeId",
    "Date",
    "Time",
    "Opd_Ipd_External_Id",
    "LoginName",
    "Doctor",
    "OTSheetNo",
    "Concession",
    "Date",
    "Time",
    "SponsorType",
    "isPackageService",
    "IsPackaged",
    "Discount",
    "StaffFree",
    "DoctorId",
    "RateEditable",
    "IsPackage",
    "ServiceId",
    "DiscountPerc",
    "DiscountAmt",
    "NetAmt",
    "AdminCharges",
    "IsGSTApplicable",
    "GSTAmount",
    "GSTApplicableAmount",
    "IsGSTApplicable",
    "DeductionPerc",
    "IsServiceTaxApp",
    "GSTAmountLimit",
    "deductible",
    "DeductionAmt",
    "isRowDisabled",
  ]);
  // headers.unshift("#");
  // headers[0] = "#";

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  //5,10.25 change as per the selection
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    props.actions.forEach((action) => {
      if (action === "Print") {
        setPrintBill(true);
      }
    });
  }, []);

  React.useEffect(() => {
    let patienttArr = [...props.data];
    let sum = 0;
    for (let obj of patienttArr) {
      sum = sum + obj.TotalAmount;
     
      props.setCompanyTotal(sum);
      // props.setPatientCompanyTotal(props.patientTotal + sum);
    }
  }, [props]);

  function handleSelectAll(e) {
    if (e === true) {
      for (let i = 0; i <= props.data?.length; i++) {
        setValue(`selectOptions${i}`, true);
      }
      props.setCharged(props.data)
    } else if (e === false) {
      for (let i = 0; i <= props.data?.length; i++) {
        setValue(`selectOptions${i}`, false);
      }
      props.setCharged([])
    }
  }

  React.useEffect(() => {
    if (props.data?.length > 0) {
      for (let i = 0; i <= props.data.length; i++) {
        setValue(`selectOptions${i}`, false);
      }
    }
  }, [props.resetCount]);

  React.useEffect(() => {
    if (props.consolidatedVal) {
      for (let i = 0; i <= props.data.length; i++) {
        setValue(`selectOptions${i}`, false);
      }
      setValue("selectAll", false);
    }
  }, [props.consolidatedVal]);

  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "lightBlue",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                  borderRadius: 4,
                },
              }}
              className="rounded lg:h-64  2xl:h-72"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",

                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& td": {
                        paddingY: 0,
                      },
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <fieldset
                        disabled={props.consolidatedVal}
                        className=""
                        onChange={(e) => {
                          console.log("selected servie are", e.target.checked);
                          handleSelectAll(e.target.checked);
                        }}
                      >
                        <CheckBoxField
                          control={control}
                          defaultValue={false}
                          name="selectAll"
                        />
                      </fieldset>
                    </TableCell>
                    {headers.map((header, index) => (
                      <>
                        <TableCell
                          sortDirection={orderBy === header ? order : false}
                          className="whitespace-nowrap"
                        >
                          <TableSortLabel
                            active={false}
                            direction={orderBy === header ? order : "asc"}
                            onClick={createSortHandler(header)}
                            key={index}
                          >
                            <span className="text-gray-600 font-bold">
                              {header}
                            </span>
                            {orderBy === header ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0,
                          },
                        }}
                      >
                        <TableCell className="  flex whitespace-nowrap ">
                          <fieldset
                            className=""
                            disabled={props.consolidatedVal}
                            onChange={(e) => {
                              if (e.target.checked === false) {
                                let data = [...props.chargeId];
                                let afterRemove = data.filter(
                                  (item) => item !== row
                                );
                                props.setCharged(afterRemove);
                                setValue("selectAll", false);
                              } else if (e.target.checked === true) {
                                let checkedArray = [];
                                for (let i = 0; i < props.data.length; i++) {
                                  if (getValues(`selectOptions${i}`)) {
                                    checkedArray.push(props.data[i]);

                                    props.setCharged(checkedArray);
                                  }
                                }

                                if (checkedArray.length === props.data.length) {
                                  console.log("chekced arrayy length equal to data.length");
                                  let allCheckedArr = [];
                                  setValue("selectAll", true);
                                  // for (let i = 0; i < props.data.length; i++) {
                                  //   if (getValues(`selectOptions${i}`)) {
                                  //     allCheckedArr.push(props.data[i]);

                                  //     props.setCharged(allCheckedArr);
                                  //   }
                                  // }

                                  
                                  
                                }
                              }
                              console.log("selected servie are", props.data);
                              props.displayRowData(row, index);
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              // defaultValue={props.consolidatedVal ? true : null}

                              name={`selectOptions${index}`}
                            />
                          </fieldset>
                        </TableCell>

                        {headers &&
                          headers.map((header, index) => (
                            <TableCell
                              className="whitespace-nowrap"
                              key={index}
                            >
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
