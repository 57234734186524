import React, { useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { MiscellaneousExpenceReportData } from "../services/miscellaneousExpenseServices/MiscellaneousExpenseServices";

export default function MiscellaneousExpenceReport(props) {
  const printRef = useRef(null);
  let obj = {
    accountHeadId: null,
    fromDate: props.fromDate,
    toDate: props.toDate,
  };

  const [dataResult, setDataResult] = React.useState();

  React.useEffect(() => {
    MiscellaneousExpenceReportData(obj)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props]);

  React.useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center  ">
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
            <div className=" -mt-9">
              <h1 className=" font-semibold text-[15px] ">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[11px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" mt-2 font-semibold text-[13px]">
                Miscellaneous Expence Report
              </h1>
            </div>
            <div className=" space-x-2 mx-2 font-semibold flex justify-end text-[11px]">
              <h1>Print Date & Time </h1>
              <h1>:</h1>
              <div>16-09-2002</div>
            </div>
          </div>
          <div className="grid grid-cols-[10%_10%_10%_15%_25%_30%] border-b-[1px] border-t-[1px] p-1 border-black text-[11px]">
            <div className=" font-semibold">SR.No.</div>
            <div className=" font-semibold">BillNo</div>
            <div className=" font-semibold">Name</div>
            <div className=" font-semibold">Amount</div>
            <div className=" font-semibold">AccountHeadName</div>
            <div className=" font-semibold">Remark</div>
          </div>
        </thead>
        <tbody className="w-full">
          <div className=" border-b-[1px] border-black">
            {dataResult?.map((data) => {
              return (
                <div className=" grid grid-cols-[10%_10%_10%_15%_25%_30%] text-[11px] border-b-[1px] p-1">
                  <div className="">{data?.SerialNumber}</div>
                  <div className="">{data?.BillNo}</div>
                  <div className="">{data?.Name}</div>
                  <div className="">{data?.Amount}</div>
                  <div className="">{data?.AccountHeadName}</div>
                  <div className="">{data?.Remark}</div>
                </div>
              );
            })}
          </div>

          <div className=" grid grid-cols-[10%_5%_85%] text-[11px] space-x-3 p-1">
            <div className=" whitespace-nowrap font-semibold">
              Total Expenses{" "}
            </div>
            <div className=" font-semibold">:</div>
            <div className=" font-semibold">
              {dataResult?.[0]?.TotalExpenses}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}
