import React from "react";

export default function Filldetails() {
  return (
    <button>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="34" rx="4" fill="#329DFF" />
        <path
          d="M25.5881 22.4943C24.995 21.9969 21.5167 19.7943 20.9385 19.8954C20.6671 19.9435 20.4595 20.1749 19.9037 20.8379C19.6467 21.1642 19.3645 21.4698 19.0596 21.7519C18.501 21.617 17.9602 21.4166 17.4486 21.1548C15.4423 20.178 13.8214 18.5566 12.8452 16.55C12.5834 16.0384 12.383 15.4977 12.2481 14.9391C12.5302 14.6342 12.8358 14.3519 13.1621 14.0949C13.8244 13.5392 14.0565 13.3329 14.1046 13.0601C14.2057 12.4806 12.0011 9.00361 11.5057 8.41054C11.2981 8.16489 11.1094 8 10.8679 8C10.1676 8 7 11.9167 7 12.4243C7 12.4657 7.06786 16.5432 12.2175 21.7825C17.4568 26.9321 21.5343 27 21.5757 27C22.0833 27 26 23.8324 26 23.1321C26 22.8906 25.8351 22.7019 25.5881 22.4943Z"
          fill="white"
        />
        <path
          d="M22.5 16H24C23.9982 14.4092 23.3655 12.8842 22.2407 11.7593C21.1158 10.6345 19.5908 10.0018 18 10V11.5C19.1931 11.5012 20.337 11.9757 21.1807 12.8193C22.0243 13.663 22.4988 14.8069 22.5 16Z"
          fill="white"
        />
        <path
          d="M20 17H21C20.9988 15.9395 20.577 14.9228 19.8271 14.1729C19.0772 13.423 18.0605 13.0012 17 13V14C17.7954 14.0008 18.558 14.3171 19.1204 14.8796C19.6829 15.442 19.9992 16.2046 20 17Z"
          fill="white"
        />
        <path
          d="M25.7692 15H27C26.9976 12.879 26.1539 10.8456 24.6542 9.34584C23.1544 7.84608 21.121 7.00244 19 7V8.23077C20.7947 8.23289 22.5152 8.94675 23.7842 10.2158C25.0532 11.4848 25.7671 13.2053 25.7692 15Z"
          fill="white"
        />
      </svg>
    </button>
  );
}
