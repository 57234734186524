import React from "react";
import IPDCharges from "../../../ipd/components/charges/IPDCharges";

const Charges = () => {
  return (
    <div>
      <IPDCharges />
    </div>
  );
};

export default Charges;
