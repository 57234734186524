import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { Box, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import CancelPresentationIconButton from "../Buttons/CancelPresentationIconButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",

  height: "99%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

function ImageViewer({ images, onClose, initialIndex, open }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(initialIndex);

  console.log("images", images);

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  console.log(images?.length > 0, selectedImageIndex);
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
        <CancelPresentationIconButton
          onClick={() => {
            onClose();
          }}
        />
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-1500"
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          <div className="max-w-full max-h-full relative overflow-y-auto">
            <div className="fixed z-50 top-[2px] right-[2px]  cursor-pointer">
              <CancelPresentationIconButton onClick={onClose} />
            </div>
            <img
              src={images[selectedImageIndex]}
              alt={`Image ${selectedImageIndex + 1}`}
              className="w-full h-full object-contain"
            />
            <images />
            <div className="fixed bottom-4 left-0 w-full flex justify-end p-10 space-x-2 ">
              {images?.length > 0 && selectedImageIndex > 0 && (
                <button
                  onClick={handlePrevImage}
                  className="btn-nav border rounded-full bg-customBlue text-white"
                >
                  <Tooltip title={"Previous"}>
                    <KeyboardArrowLeftOutlinedIcon
                      style={{
                        fontSize: "40px",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </Tooltip>
                </button>
              )}

              {images?.length !== selectedImageIndex + 1 && (
                <button
                  onClick={handleNextImage}
                  className="btn-nav border rounded-full bg-customBlue text-white"
                >
                  <Tooltip title={"Next"}>
                    <ChevronRightOutlinedIcon
                      style={{
                        fontSize: "40px",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </Tooltip>
                </button>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ImageViewer;
