import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  addMinutes,
  differenceInMinutes,
  differenceInSeconds,
  endOfYesterday,
  format,
  isAfter,
  parseISO,
} from "date-fns";
import React from "react";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";

function OtReservationTable(props) {
  var notAllowToBook;
  const { slotsToShow, setSlotsToShow,} = props;

  const [startIndex, setStartIndex] = React.useState(null);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedSlots, setSelectedSlots] = React.useState([]);
  const [selectedSlotsIndex, setSelectedSlotsIndex] = React.useState(null);
  const [openlSotConfirmationModal, setOpenlSotConfirmationModal] =
    React.useState(false);
    const [isDragging, setIsDragging] = React.useState(false); 


  ////////////
  const handleDragStart = (dateObj, slot, slotIndex) => {
    setStartIndex(slotIndex);
    setSelectedClass(dateObj);
    setIsDragging(true)
  };
  //////
const handleDraging=(slotIndex, isCancel, )=>{

  if(isDragging)
 { let arr = props.otReservationSlots.slice(startIndex, slotIndex + 1);


  for (let selectObj of arr) {
    selectObj.tableId = selectedClass.id;
    props.otReservationSlots.find((item) => {
      if (item === selectObj && !isCancel) {
        item.isBooked = true;
        item.class = selectedClass?.label;
      } 
    });
    props.setOtReservationSlots(props.otReservationSlots);
    
  }
  }
}
const handleDragingOut=(slotIndex, isCancel, )=>{

  if(isDragging)
 { let arr = props.otReservationSlots.slice(startIndex, slotIndex + 1);


  for (let selectObj of arr) {
    selectObj.tableId = selectedClass.id;
    props.otReservationSlots.find((item) => {
      if (item === selectObj && !isCancel) {
        item.isBooked = false;
        item.class = selectedClass?.label;
      } 
    });
    props.setOtReservationSlots(props.otReservationSlots);
    
  }
  }
}

  const handleDragEnd = (slotIndex, isCancel, dateObj, slot) => {
    setIsDragging(false)

    if (startIndex !== null) {
      var arr = props.otReservationSlots.slice(startIndex, slotIndex + 1);

      /////check that arr contains pre-booked key or not
      function checkException(arr) {
        return arr.some((el) => el.isPreBooked === true);
      }
      notAllowToBook = checkException(arr);

      // setSelectedSlots(arr);

      ///
      if (!notAllowToBook) {
        /////
        
        setSelectedSlots(arr);
        // ///

        for (let selectObj of arr) {
          selectObj.tableId = selectedClass.id;
          props.otReservationSlots.find((item) => {
            if (item === selectObj && !isCancel) {
              item.isBooked = true;
              item.class = selectedClass?.label;
            } else if (item === selectObj && isCancel) {
              item.isBooked = false;
              item.class = selectedClass?.label;
            }
          });
          props.setOtReservationSlots(props.otReservationSlots);
          
          setSelectedClass("");
        }
      } else if (notAllowToBook) {
        //props.setOtReservationSlots([])
        warningAlert("Please Select Another Slot!");

        for (let selectObj of arr) {
          selectObj.tableId = selectedClass.id;
          props.otReservationSlots.find((item) => {
            if (item === selectObj ) {
              item.isBooked = false;
              item.class = selectedClass?.label;
            } 
          });
          props.setOtReservationSlots(props.otReservationSlots);
          
        }
      }
    }
    ////executes on entering cancel button of time slot confirmation modal
    if (isCancel) {
      for (let selectObj of arr) {
        selectObj.tableId = selectedClass.id;
        props.otReservationSlots.find((item) => {
          if (item === selectObj && isCancel) {
            if (item?.isPrebooked !== undefined) {
              item.isBooked = false;
            }
            // item.isBooked = false;
            // item.isPreBooked = false;
            item.class = selectedClass?.label;
          }
        });

        props.setOtReservationSlots(props.otReservationSlots);
        setStartIndex(null);
        setSelectedClass("");
        setSlotsToShow(null);
        setSelectedSlots([]);
      }
    }

    ///////
  };

  React.useEffect(() => {
    if (selectedSlots.length > 0) {
      let slotLength = selectedSlots?.length;
      //from time
      let afromTime = format(
        props.reservationDate,
        "yyyy-MM-dd'T'" + selectedSlots[0].timeHour + ":ss"
      );
      //to time
      let aToTime = format(
        props.reservationDate,
        "yyyy-MM-dd'T'" + selectedSlots[slotLength - 1].timeHour + ":ss"
      );

      //if one slot selected add 15 Mins
      let addedMinsDate;
      if (afromTime === aToTime) {
        let addedMins = addMinutes(parseISO(aToTime), 15);
        addedMinsDate = format(addedMins, "yyyy-MM-dd'T'HH:mm:ss");
      }
      //conditional to time
      let finaltoTime = afromTime !== aToTime ? aToTime : addedMinsDate;

      //duration
      let durationMin = differenceInMinutes(
        parseISO(finaltoTime),
        parseISO(afromTime)
      );

      //obj for post
      let obj = {
        fromTime: afromTime,
        toTime: finaltoTime,
        date: format(
          afromTime !== aToTime ? parseISO(aToTime) : parseISO(addedMinsDate),
          "yyyy-MM-dd'T'HH:mm:ss"
        ),
        tableId: selectedSlots[0].tableId,
        DurationMin: durationMin,
      };
      setOpenlSotConfirmationModal(
        obj !== undefined && obj.tableId !== undefined ? true : false
      );
      props.setSlotToPost(obj);

      //below obj for show in modal
      let objForDataShow = {
        fromTime: format(parseISO(afromTime), "hh:mm a"),
        toTime: format(parseISO(finaltoTime), "hh:mm a"),
        date: format(props.reservationDate, "dd-MM-yyyy"),
        OtTable:
          props.selctedOtTable !== null ? props.selctedOtTable.value : "",
        OtTableId: props.selctedOtTable !== null ? props.selctedOtTable.id : "",

        durationMin: durationMin,
      };
      setSlotsToShow(objForDataShow);
    }
    ////
  }, [selectedSlots]);

 
    

  return (
    <>
      <Box>
        <Paper>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "white",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
                borderRadius: "10px",
              },
            }}
            className="rounded h-96 2xl:h-[550px]"
          >
            <Table
              stickyHeader
              sx={{ maxWidth: `${props.classArray?.length * 90}px` }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  <TableCell width="90px">Time/Mode</TableCell>
                  {props.classArray.map((dateObj, dateIndex) => {
                    return (
                      <TableCell
                        className="whitespace-nowrap"
                        sx={{
                          borderLeft: "1px solid #d6d2d2",
                        }}
                        width="90px"
                      >
                        <div className="text-center">
                          <div>
                            {isAfter(props.reservationDate, endOfYesterday()) && format(props.reservationDate, "dd-MM-yyyy")}
                          </div>
                          <div>{dateObj.label}</div>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {props.otReservationSlots.map((slot, slotIndex) => {
                  return (
                    <TableRow
                      sx={{
                        "& td": {
                          paddingY: 0.5,
                        },
                      }}
                    >
                      <TableCell width="90px">{slot.label}</TableCell>

                      {props.classArray?.map((dateObj, dateIndex) => {
                        console.log("slot",slot,slot?.isPreBooked === true &&
                        slot?.class, dateObj.label)
                        return slot?.isPreBooked === undefined ? (
                          <TableCell
                            className="whitespace-nowrap border border-black"
                            width="90px"
                            sx={{
                              //borderLeft: "1px solid #d6d2d2",
                              backgroundColor: `${
                                slot?.isBooked === true &&
                                slot?.class === dateObj.label
                                  ? `#f1c6f7`
                                  : slot?.isPreBooked === true &&
                                    slot?.class === dateObj.label
                                  ? `#99e6ff`
                                  : ``
                              }`,
                            }}
                            onMouseDown={(e) => {
                              //to check selected slot not before current time
                              e.preventDefault();

                              let FromTime = format(
                                props.reservationDate,
                                "yyyy-MM-dd'T'" +
                                  props.otReservationSlots[slotIndex]
                                    ?.timeHour +
                                  ":ss"
                              );

                              let currentTimeDiff = differenceInSeconds(
                                parseISO(FromTime),
                                new Date()
                              );

                              ///////////
                              if (currentTimeDiff > 0) {
                                handleDragStart(dateObj, slot, slotIndex);
                              } else if (currentTimeDiff <= 0) {
                                warningAlert("Time Elapsed ...");
                              }
                            }}
                            onMouseUp={(e) => {
                              e.preventDefault();
                              setSelectedSlotsIndex(slotIndex);
                              handleDragEnd(slotIndex, false, dateObj, slot);
                            }}
                             onMouseOver={(e)=>{
                              if(isDragging)
                              { e.preventDefault();
                               setSelectedSlotsIndex(slotIndex);
                               handleDraging(slotIndex, false, dateObj, slot);
                              }
                              
                              
                               }}

                               onMouseOut={(e)=>{
                                if(isDragging)
                              { e.preventDefault();
                               setSelectedSlotsIndex(slotIndex);
                               handleDragingOut(slotIndex, false, dateObj, slot);
                              }
                               }}
                            ///merge row
                          >
                          </TableCell>
                        ) : slot.isPreBooked &&
                          slot.fromTime === slot.timeHour &&
                          slot?.class === dateObj.label ? (
                          ///table cell to define preBooked Slots
                          <TableCell
                          rowSpan={
                            slot.isPreBooked &&
                            slot.fromTime === slot.timeHour &&
                            slot?.class === dateObj.label
                                ? slot.duration / 15 + 1
                                : 1 
                        }
                        className="border border-black"
                            sx={{
                             // borderLeft: "1px solid #d6d2d2",
                              backgroundColor: `${
                                slot?.isPreBooked === true &&
                                slot?.class === dateObj.label
                                  ? `#99e6ff`
                                  : ``
                              }`,
                            }}
                          >
                            {slot?.patientInfo}
                          </TableCell>
                        ) : <TableCell sx={{
                          borderLeft: "1px solid #d6d2d2",
                         }}
                         onMouseDown={(e) => {
                          //to check selected slot not before current time
                          e.preventDefault();

                          let FromTime = format(
                            props.reservationDate,
                            "yyyy-MM-dd'T'" +
                              props.otReservationSlots[slotIndex]
                                ?.timeHour +
                              ":ss"
                          );

                          let currentTimeDiff = differenceInSeconds(
                            parseISO(FromTime),
                            new Date()
                          );

                          ///////////
                          if (currentTimeDiff > 0) {
                            handleDragStart(dateObj, slot, slotIndex);
                          } else if (currentTimeDiff <= 0) {
                            warningAlert("Time Elapsed ...");
                          }
                        }}
                        onMouseUp={(e) => {
                          e.preventDefault();
                          setSelectedSlotsIndex(slotIndex);
                          handleDragEnd(slotIndex, false, dateObj, slot);
                        }}
                         >
                        
                        </TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {/* /// modal */}
      <Modal open={openlSotConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            // minWidth:"25%",
            // height: "30%",
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              OT Shedule
            </div>
          

            <hr className="border mx-1  border-blue-900" />
            <div className="p-2">
              <div className=" grid    ">
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28 text-gray-600">
                    Operation Date
                  </span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {slotsToShow?.date}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28 text-gray-600">
                    OT Table
                  </span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {slotsToShow?.OtTable}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28 text-gray-600">
                    From Time
                  </span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {slotsToShow?.fromTime}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28 text-gray-600">
                    To Time
                  </span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {slotsToShow?.toTime}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28 text-gray-600">
                    Duration
                  </span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {slotsToShow?.durationMin} Mins
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className=" border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setOpenlSotConfirmationModal(false);
                    setSlotsToShow(null);
                    handleDragEnd(selectedSlotsIndex, true);
                    setStartIndex(null);
                  }}
                />
              </div>

              <div>
                
                <div>
                  <CommonButton
                    label="Ok"
                    type="submit"
                    className="h-9 px-3 text-base font-medium  bg-green-600 text-white rounded "
                    onClick={() => {
                      props.setTabValue(props.tabValue + 1);
                      setOpenlSotConfirmationModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default OtReservationTable;
