import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";


const staffDuty = [
  {
    id: 1,
    value: "Morning",
    label: "Morning",
  },
  {
    id: 2,
    value: "AfterNoon",
    label: "AfterNoon",
  },
  {
    id: 2,
    value: "Night",
    label: "Night",
  },
];
//main function
export default function NursingReportTable(props) {
  
 
  const schema = yup.object().shape({});
  const defaultValues = {};
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [checkBoxAction, setCheckBoxAction] = React.useState(false);
  React.useEffect(() => {
    props.actions.forEach((action) => {
      if (action === "Checkbox") {
        setCheckBoxAction(true);
      }
    });
  }, []);
  function handleClick(index) {
    setRowIndex(index);
  }
  function checkBoxActions(row, index) {
    
  }
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
                sx={{
                  "&::-webkit-scrollbar": {
                    width: 7,
                    height: 10,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "lightBlue",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1e3a8a",
                    borderRadius: 4,
                  },
                }}
              className="rounded h-80 2xl:h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        Class Name
                      </span>
                    </TableCell>

                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        01st Floor
                      </span>
                    </TableCell>

                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        02nd Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        03rd Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        04th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        05th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap">
                        06h Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        07th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        08th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        09th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        10th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        11th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        12th Floor
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold text-center whitespace-nowrap w-full">
                        13th Floor
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data.map((row, index) => {
                    
                    return (
                      <>
                        <TableRow
                          key={index}
                          onClick={() => {}}
                          sx={{
                            "& th": {
                              paddingY: 0,
                              backgroundColor: "#F1F1F1",
                            },
                          }}
                        >
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row.desc1}
                          </TableCell>

                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["4"] ? row["4"] : 0}
                          </TableCell>

                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["5"] ? row["5"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["6"] ? row["6"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["7"] ? row["7"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["8"] ? row["8"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["9"] ? row["9"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["10"] ? row["10"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["11"] ? row["11"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["12"] ? row["12"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["13"] ? row["13"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["14"] ? row["14"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["20"] ? row["20"] : 0}
                          </TableCell>
                          <TableCell className="text-gray-600 font-bold text-center whitespace-nowrap">
                            {row["21"] ? row["21"] : 0}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
