import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { saveNonPathTemplate } from "../../services/radiology/RadiologyTemplateMasterServices";
import JoditEditor from "jodit-react";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "90%",
  height: "85%",
  p: 3,
  overflowY: "scroll",
};

const config2 = {
  useSearch: false,
  spellcheck: false,
  enter: "P",
  defaultMode: "1",
  toolbarAdaptive: false,
  toolbarSticky: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  minHeight: 500,
  minWidth: null,
  buttons: [
    "bold",
    "underline",
    "strikethrough",
    "italic",
    "indent",
    "outdent",
    "image",
    "fontsize",
    "paragraph",
    "brush",
    "|",
    "align",
    "ul",
    "ol",
    "table",
    "hr",
    "symbol",
    "eraser",
    "copyformat",
    "superscript",
    "subscript",
    "undo",
    "redo",
    "find",
    "preview",
    "print",
  ],
  editorCssClass: "alic",
  placeHolder: "",
  controls: {
    fontsize: {
      list: [
        "8",
        "9",
        "10",
        "11",
        "12",
        "14",
        "16",
        "18",
        "24",
        "30",
        "36",
        "48",
        "60",
        "72",
        "96",
        "100",
      ],
    },
    font: {
      command: "fontname",
      list: {
        "": "Default",
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
      },
    },
  },
};

const RadiologyTemplateMasterModal = (props) => {
  const schema = yup.object().shape({
    templateCode: yup.string().required("Required"),
    templateName: yup.string().required("Required"),
  });

  const defaultValues = {
    templateCode: "",
    templateName: "",
    radiologist: null,
    gender: null,
    testResults: null,
  };
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [content1, setContent1] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [finalData, setFinalData] = useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const {
    openTemplateModal,
    handleCloseModal,
    editRowData,
    setEditRowData,
    getTemplateList,
  } = props;

  const handleCloseConfirmation = () => {
    setOpenConfirmModal(false);
  };

  const onSubmitData = (data) => {
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));

    let postObj = {};
    if (editRowData !== null) {
      postObj.updatedBy = getUserId.userId;
    } else {
      postObj.addedBy = getUserId.userId;
    }
    postObj.status = editRowData ? editRowData.Status : true;
    postObj.templateCode = data.templateCode;
    postObj.templateDescription = content1;
    postObj.templateId = editRowData ? editRowData.TemplateId : 0;
    postObj.templateName = data.templateName;

    setFinalData(postObj);
    setOpenConfirmModal(true);
  };

  function postData() {
    setOpenBackdrop(true);
    setOpenConfirmModal(false);
    saveNonPathTemplate(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenConfirmModal(false);
        handleCloseModal();
        setOpenBackdrop(false);
        getTemplateList();
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenConfirmModal(false);
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    setValue("templateCode", editRowData?.TemplateCode);
    setValue("templateName", editRowData?.TemplateName);
    setContent1(editRowData?.WebDescription);
  }, [editRowData, props]);

  return (
    <>
      <Modal open={openTemplateModal}>
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              handleCloseModal();
              reset(defaultValues);
              setEditRowData(null);
              setContent1();
            }}
          />
          <form onSubmit={handleSubmit(onSubmitData)}>
            <div className="space-y-2">
              <label className="flex font-semibold text-lg">New Template</label>
              <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2">
                <div>
                  <InputField
                    control={control}
                    name="templateCode"
                    label="Template Code"
                    error={errors.templateCode}
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="templateName"
                    label="Template Name"
                    error={errors.templateName}
                  />
                </div>
              </div>
              <div>
                <JoditEditor
                  className="h-[200px]"
                  name="templateDescription"
                  config={config2}
                  value={content1 !== null ? content1 : ""}
                  onBlur={(c) => {
                    if (c !== "") {
                      setContent1(c);
                    }
                  }}
                />
              </div>
              <div className="flex justify-end gap-2">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    reset(defaultValues);
                    setContent1();
                  }}
                />
                <CommonButton
                  type="submit"
                  label="Save"
                  className="bg-customGreen text-white"
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openConfirmModal}
        confirmationHandleClose={handleCloseConfirmation}
        confirmationSubmitFunc={postData}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this Record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
};

export default RadiologyTemplateMasterModal;

