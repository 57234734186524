import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

export const callingDashboardss = (listobj) => {
  return apiClient.post(
    `/postDischargeCallManagementController/getPostDischargePatientList`,
    listobj,
    {
      headers: authHeader(),
    }
  );
};
export const getPostDischargePatientListSearch = (searchString) => {
  return apiClient.get(
    `/postDischargeCallManagementController/getPostDischargePatientListSearch/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};
export const getpashentInfodata = (admissionId) => {
  return apiClient.get(
    `/postDischargeCallManagementController/getPostDischargePatientDetails/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};
export const disconnectCall = (postDischargeDetailsId) => {
  return apiClient.get(
    `/postDischargeCallManagementController/disconnectCall/${postDischargeDetailsId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getPreviousCallDetails = (id) => {
  return apiClient.get(
    `/postDischargeCallManagementController/getPreviousCallDetails/${id}`,
    {
      //   headers: authHeader(),
    }
  );
};
export const startcalling = (postDischargeDetailsId) => {
  return apiClient.get(
    `/postDischargeCallManagementController/startCall/${postDischargeDetailsId}`,
    {
      //   headers: authHeader(),
    }
  );
};
export const rejectCall = (postDischargeDetailsId) => {
  return apiClient.get(
    `/postDischargeCallManagementController/rejectCall/${postDischargeDetailsId}`,
    {
      //   headers: authHeader(),
    }
  );
};
export const rescheduleCall = (object) => {
  return apiClient.post(
    `/postDischargeCallManagementController/rescheduleCall`,
    object,
    {
      headers: {
        Authorization:
          "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJNciBOaWxlc2ggQWJoaW1hbnl1IFNoaW5kZSIsImVtcElkIjo2OCwiZW1wbG95ZWVUeXBlIjoiSG91c2VrZWVwaW5nIEluY2hhcmdlIiwiY3JlYXRlZCI6MTcxMTQzNjA1ODk0NCwiZXhwIjoyNzkxNDM2MDU4LCJ1c2VyIjoxNTQsInN0YXR1cyI6dHJ1ZSwidXNlcm5hbWUiOiJNciBOaWxlc2ggQWJoaW1hbnl1IFNoaW5kZSJ9.1qTR5SEZkcJI1K55faJtyxNlEk5HJT8E0_koi7-RTwI6p4Jf_Onq4Ds9VNKV_96w6iJrVuc8qOynOoPB986AoQ",
      },
    }
  );
};
export const addCallDetails = (saveobj) => {
  return apiClient.post(
    `/postDischargeCallManagementController/savePostDischargeDetails`,
    saveobj,
    {
      headers: {
        Authorization:
          "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJNciBOaWxlc2ggQWJoaW1hbnl1IFNoaW5kZSIsImVtcElkIjo2OCwiZW1wbG95ZWVUeXBlIjoiSG91c2VrZWVwaW5nIEluY2hhcmdlIiwiY3JlYXRlZCI6MTcxMTQzNjA1ODk0NCwiZXhwIjoyNzkxNDM2MDU4LCJ1c2VyIjoxNTQsInN0YXR1cyI6dHJ1ZSwidXNlcm5hbWUiOiJNciBOaWxlc2ggQWJoaW1hbnl1IFNoaW5kZSJ9.1qTR5SEZkcJI1K55faJtyxNlEk5HJT8E0_koi7-RTwI6p4Jf_Onq4Ds9VNKV_96w6iJrVuc8qOynOoPB986AoQ",
      },
    }
  );
};
