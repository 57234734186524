import React from "react";

function AddBtn({ type, onClick,onKeyDown }) {
  return (
    <div>
      <button
        type={type}
        className="h-[36px] cursor-pointer px-3 text-sm 2xl:text-base font-medium  bg-customBlue text-white rounded  whitespace-nowrap"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        + Add
      </button>
    </div>
  );
}

export default AddBtn;
