import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Tooltip } from "@mui/material";

export default function RightArrowButton({ onClick }) {
  return (
    <div>
      <Tooltip title="Proceed">
        <button
          type="button"
          className="h-9 cursor-pointer w-14 px-2 rounded-md bg-customBlue text-white "
          variant="outlined"
          size="small"
          onClick={onClick}
        >
          <ArrowRightAltIcon className="cursor-pointer" />
        </button>
      </Tooltip>
    </div>
  );
}
