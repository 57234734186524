import React, { useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { miscIncomeLineItemPrint } from "../services/miscellaneousIcomeServices/MiscellaneousIcomeServices";

export default function MiscellaneousIncomeLineItemPrint(props) {
  const [dataResult, setdataResult] = React.useState();
  const printRef = useRef(null);

  React.useEffect(() => {
    miscIncomeLineItemPrint(props?.miscExpenseId)
      .then((response) => response.data)
      .then((res) => {
        setdataResult(res.result);
      });
  }, [props]);

  React.useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center ">
            <h1 className=" font-semibold text-[15px] ">
              Inamdar Multispeciality Hospital
            </h1>
            <h1 className=" text-[11px] ">
              Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
            </h1>
          </div>
          <div className=" text-center text-[14px] mt-2 font-semibold border-b-[1px] border-black p-">
            Cash Receipt
          </div>
        </thead>
        <tbody className="w-full">
          <div className=" grid grid-cols-[20%_5%_65%] text-[11px] p-1">
            <div className=" font-semibold">Receipt No.</div>
            <div className=" font-semibold">:</div>
            <div>{dataResult?.ReceiptNo}</div>
            <div className=" font-semibold">Receipt Date</div>
            <div className=" font-semibold">:</div>
            <div>{dataResult?.ReceiptDate}</div>
            <div className=" font-semibold">Name</div>
            <div className=" font-semibold">:</div>
            <div>{dataResult?.Name}</div>
            <div className=" font-semibold">Amount</div>
            <div className=" font-semibold">:</div>
            <div className=" font-semibold w-36 border-[1px] border-black pl-1 ">
              {dataResult?.Amount}
            </div>
            <div className=" font-semibold">Amt In Figures</div>
            <div className=" font-semibold">:</div>
            <div>{dataResult?.["Amt In Figures"]}</div>
            <div className=" font-semibold">Remarks</div>
            <div className=" font-semibold">:</div>
            <div>{dataResult?.Remark}</div>
          </div>
          <div className=" p-1 flex justify-between mt-7 text-[11px]">
            <div>
              <h1>{dataResult?.["Prepared By"]}</h1>
              <h1 className=" font-semibold">Prepared by</h1>
            </div>
            <div className=" font-semibold">Checked by</div>
            <div className=" font-semibold">Receiver's Sign.</div>
          </div>
          <div className=" p-1 text-[11px]">Cheque - Subject to Relization</div>
        </tbody>
      </table>
    </div>
  );
}
