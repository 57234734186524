import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const postGrnItems = async (searchObj) => {
  return await apiClient.post(`/grn/saveGrn`, searchObj, {
    headers: authHeader(),
  });
};

export const getAllBatchNumberList = (itemId, storeId) => {
  return apiClient.get(`/grn/batchNumberForGRN/${itemId}/${storeId}`, {
    headers: authHeader(),
  });
};

export const getLastPurchaseDetails = (itemId, storeId) => {
  return apiClient.get(`/grn/getLastPurchaseDetails/${itemId}/${storeId}`, {
    headers: authHeader(),
  });
};
