import React, { createContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";

import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { saveExpiredItemReturnData } from "../../../services/expiredItemReturn/ExpiredItemReturnServices";
import { searchSupplier } from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import ExpireItemReturnModal from "./ExpireItemReturnModal";
import ExpiredItemReturnParentTable from "./ExpiredItemReturnParentTable";


const actions = ["Checkbox"];
const UserContext = createContext();
const ExpiredItemsReturn = () => {
  // useForm destructuring
  const schema = yup.object().shape({
    supplierName: yup
      .object()
      .nullable()
      .shape({ label: yup.string(), value: yup.string() }),
  });
  const defaultValues = {
    supplierName: null,
    totalAmount: 0,
    netAmt: 0,
    otherDeduction: 0,
    remarks:null,
    totalGstAmount: 0,
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    register,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  // state variables
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [supplier, setSupplier] = React.useState([]);
  const [parentData, setParentData] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [VATAmount, setVATAmount] = React.useState(0);
  const [expiredItemReturnData, setExpiredItemReturnData] =
    React.useState(null);
  const [returnQtyErrorsFlag, setReturnQtyErrorsFlag] = React.useState(false);
  const [expiredItem, setExpiredItemList] = React.useState([]);
  const [userActions, setUserActions] = React.useState([]);
  const[openBackdrop,setOpenBackdrop]= React.useState(false);
  const [privilege, setPrivilege] = React.useState(null);
  // other variables
  let supplierVal = watch("supplierName");
  console.log("supplierNamesupplierName",supplierVal);
  let location=useLocation()
  console.log("locationlocation",location?.state?.menuId);

  let userInfoObj = JSON.parse(localStorage.getItem("userInfo"));
  // useEffects

  console.log("locationlocation", location.state.menuId);
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location.state?.menuId)
        .then((response) => response.data)
        .then((res) => {

          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    // set default store to store dropdown
    let storeObj = {};
    storeObj.id = userInfoObj.storeId;
    storeObj.label = userInfoObj.storeName;
    storeObj.value = userInfoObj.storeName;
    setValue("store", storeObj);
  }, []);

  useEffect(() => {
    setValue("totalAmount", Number(totalAmount?.toFixed(2)));
    setValue("netAmt", Number(totalAmount.toFixed(2)));
    setValue("totalGstAmount", Number(VATAmount.toFixed(2)));
    console.log("parentDataparentDataparentData", parentData);
    console.log(
      "returnQtyErrorsreturnQtyErrorsreturnQtyErrors",
      returnQtyErrorsFlag
    );
  }, [
    totalAmount,
    setValue,
    VATAmount,
    parentData,
    errors,
    returnQtyErrorsFlag,
  ]);

  // functions
  const handleOpen = () => {
    console.log("parentDataparentDataparentData", parentData);
    if (returnQtyErrorsFlag !== false) {
      console.log("notsubmit the data");
    } else {
      console.log("submit the data");
    }
    // if (watch("supplierName") !== undefined && watch("supplierName") !== null) {
    setOpen(true);
    // }
  };
  const handleClose = () => setOpen(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () =>{
    setOpenConfirmation(false)
    setOpenBackdrop(false)
  };

  //store dropdown api fun
  function handleChangeSupplierChange(autoSearchString) {
    if (autoSearchString !== ""&&UseCheckConsecutiveCharacters(autoSearchString)) {
      searchSupplier(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setSupplier(res.result);
        }).catch((error) => {
          // errorAlert(error.message);
       
          console.log("errorAlerterrorAlert", error);
  
        });
    }
  }

  // on submit function
  function onSubmitDataHandler() {
    let postArr = [];
    for (let Object of parentData) {
      console.log("Obectisdatais", Object);
      let obj = {};
      obj.batchCode = Object.BatchCode;
      obj.batchExpiryDate = Object.BatchExpDate.replaceAll("+00:00", "");
      obj.conversion = 0;
      obj.discAmount = 0;
      obj.discPercentage = 0;
      obj.itemId = Object.ItemId;
      obj.netAmount = Object.totalAmount;
      obj.octroi = Object.OctroiPer;
      obj.purchaseRate = Object.PurchaseRate;
      obj.returnQty = Object.returnQty;
      obj.totalAmount = Object.totalAmount;
      obj.totalTaxAmount = Object.totalTaxAmount;
      obj.vatAmount = Object.vatAmount;
      obj.vatPercentage = Object.VatPercentage;
      postArr.push(obj);
    }
    let postObj = {
      addedBy: userInfoObj.userId,
      expiredItemReturnDtlsDtoList: postArr,
      isConsignment: true,
      netAmount: watch("netAmt"),
      otherDeductionAmount: 0,
      storeId: userInfoObj.storeId,
      supplierId: supplierVal?.id,
      totalAmount: watch("totalAmount"),
      totalOctroi: 0,
      totalTaxAmount: watch("totalTaxAmt"),
      totalVatAmount: watch("totalGstAmount"),
      menuId:location?.state?.menuId,
      privilege:privilege
    };
    

    console.log("postArrpostArrpostArrpostArrpostArr", postObj);
    let totalQty = 0;
    for (let totalQtyObj of parentData) {
      totalQty += totalQtyObj.BalQty;
    }
    if (totalQty > 0 && returnQtyErrorsFlag === false&&supplierVal!==null) {

      handleOpenConfirmation();
      setOpenBackdrop(true)
      setExpiredItemReturnData(postObj);
    } else if (supplierVal === null) {
      errorAlert("Please Select Supplier");
      setOpenConfirmation(false);
      setOpenBackdrop(false)
    } else if (totalQty === 0) {
      errorAlert("Balance Qtunatity is 0");
      setOpenBackdrop(false)
    }
  }

  // api call to save expired item retruen data
  function updateExpiredItemReturn() {
    handleCloseConfirmation()
    saveExpiredItemReturnData(expiredItemReturnData)
      .then((response) => response.data)
      .then((res) => {
        console.log(res);
        setOpenBackdrop(false)
        successAlert(res.message);
        reset(defaultValues);
        setTotalAmount(0);
        setVATAmount(0);
        setExpiredItemReturnData(null);
        setParentData([]);
      }).catch((error) => {
        
        setOpenBackdrop(false)
        console.log("errorAlerterrorAlert", error);

      });
  }

  return (
    <>
      <UserContext.Provider value={parentData}>
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="space-y-2">
            <div className="mt-12 text-center font-bold text-lg">
              Expired Items Return
            </div>
            <div className="flex gap-2">
              <div className="w-full lg:w-72">
                <DropdownField
                  control={control}
                  name="store"
                  isDisabled={true}
                  placeholder={"Store"}
                />
              </div>
              <div className="w-full lg:w-72">
                <SearchDropdown
                  control={control}
                  error={errors.supplierName}
                  name="supplierName"
                  label="Supplier*"
                  placeholder="Supplier*"
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  dataArray={supplier}
                  handleInputChange={handleChangeSupplierChange}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="h-9 w-max px-3 py-2 bg-[#133e87] font-semibold rounded text-white"
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  Get Items
                </button>
              </div>
            </div>

            <div className="space-y-2">
              <label className="font-semibold ">Item Details</label>
              {parentData?.length > 0 ? (
                <ExpiredItemReturnParentTable
                  data={parentData}
                  actions={actions}
                  setTotalAmount={setTotalAmount}
                  setReturnQtyErrorsFlag={setReturnQtyErrorsFlag}
                  setVATAmount={setVATAmount}
                />
              ) : (
                <div className="py-28 text-center">
                  <span> No Record Found...</span>
                </div>
              )}
            </div>

            <div className="grid  lg:grid-cols-1">
              <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
                <div>
                  <InputField
                    control={control}
                    name="totalAmount"
                    defaultValue={0}
                    disabled={true}
                    label="Total Amount"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="totalGstAmount"
                    defaultValue={0}
                    disabled={true}
                    label="Total GST Amount"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="totalTaxAmt"
                    defaultValue={0}
                    disabled={true}
                    label="Total Tax Amount"
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    defaultValue={0}
                    name="otherDeduction"
                    label="Other Deduction"
                    inputRef={{
                      ...register("otherDeduction", {
                        onChange: (e) => {},
                      }),
                    }}
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="netAmt"
                    defaultValue={0}
                    disabled={true}
                    label="Net Amount"
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2">
              <div className="">
                <InputField control={control} name="remarks" label="Remarks" />
              </div>
              <div className="flex justify-end gap-2">
              { userActions &&
              userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                  onClick={() => {
                    setValue("supplierName", null);
                    setSupplier(null);
                    setExpiredItemList([]);
                    setParentData([]);
                    setValue("totalAmount", 0);
                    setValue("totalGstAmount", 0);
                    setValue("totalTaxAmt", 0);
                    setValue("otherDeduction", 0);
                    setValue("netAmt", 0);
                    setValue("remarks", 0);
                  }}
                />
                  ) : null}
                </>
              ))}
               { userActions &&
              userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                       <CommonButton
                       label="Save"
                       className="bg-customGreen text-white"
                       type="submit"
                       onClick={()=>{
                        setPrivilege(obj.action)
                       }}
                       />
                  ) : null}
                </>
              ))}
              
            
              </div>
            </div>
          </div>
        </form>
<CommonBackDrop openBackdrop={openBackdrop}/>
        <ExpireItemReturnModal
          handleOpen={handleOpen}
          handleClose={handleClose}
          openModal={open}
          parentData={parentData}
          setParentData={setParentData}
          storeId={userInfoObj.storeId}
          supplierId={supplierVal?.id}
          expiredItem={expiredItem}
          setExpiredItemList={setExpiredItemList}
        />
        <ConfirmationModal
          confirmationOpen={openConfirmation}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={() => {
            updateExpiredItemReturn(expiredItemReturnData);
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Expired Item Return ?"
          confirmationButtonMsg="Save"
        />
      </UserContext.Provider>
    </>
  );
};

export default ExpiredItemsReturn;
