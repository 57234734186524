import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { AssignOffIcon, AssignOnIcon } from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchDepartment,
  fetchEmoloyeeName,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchIncidentCategory,
  fetchIncidentDepartmentDetails,
  fetchSubError,
  fettchErrorCategory,
  resignIncident,
  saveIncidentReport,
} from "../../services/incidentReportingServices/IncidentReportingServices";
import PreviousReportsList from "./PreviousReportsList";

const severityArr = [
  {
    id: 1,
    label: "A",
    value: "A",
  },
  {
    id: 2,
    label: "B",
    value: "B",
  },
  {
    id: 3,
    label: "C",
    value: "C",
  },
  {
    id: 4,
    label: "D",
    value: "D",
  },
];
const incidentStatus = [
  {
    id: 0,
    label: "0 Forward to HR for action",
    value: "0 Forward to HR for action",
  },
  { id: 1, label: "Hold", value: "Hold" },
  { id: 2, label: "Incident not clear", value: "Incident not clear" },
  { id: 3, label: "Resolved", value: "Resolved" },
  {
    id: 4,
    label: "To meet and discuss with concerned",
    value: "To meet and discuss with concerned",
  },
  {
    id: 5,
    label: "Response pending from HOD",
    value: "Response pending from HOD",
  },
];

const validationSchema = yup.object().shape({
  nameOfVictim: yup.string().required("Required"),
  reportingPersonName: yup.string().required("Required"),
  placeOfOccurrence: yup.string().required("Required"),
  eventDescriptionDtls: yup.string().required("Required"),
  // responsibleDepts:
});

let isQuality = false;

const IncidentReporting = () => {
  let location = useLocation();

  const token = JSON.parse(localStorage.getItem("userInfo"));
  //

  const [isFormView, setIsFormView] = useState(false);
  ///
  const [selectedRow, setSelectedRow] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  //
  const [reportedToArr, setReportedToArr] = useState([]);
  const [incidentCategoryArr, setIncidentCategoryArr] = useState([]);
  const [errorCategoryArr, setErrorCategoryArr] = useState([]);
  const [errorSubCategoryArr, setErrorSubCategoryArr] = useState([]);
  const [resDeptArr, setResDeptArr] = useState([]);
  const [capaPatchData, setCapaPatchData] = useState([]);
  const [isReAssign, setIsReAssign] = useState(false);
  const [CaPaSelectedRow, setCaPaSelectedRow] = useState(null);
  //
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  //
  const [openPreviousReportsModal, setOpenPreviousReportsModal] = useState(
    false
  );
  const handleOpenPreviousReportsModal = () =>
    setOpenPreviousReportsModal(true);
  const handleClosePreviousReportsModal = () =>
    setOpenPreviousReportsModal(false);

  ///

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nameOfVictim: "",
      reportingPersonName: "",
      employeeDepartment: "",
      eventDescriptionDtls: "",
      placeOfOccurrence: "",
      reportedTo: null,
      incidentCategory: null,
      incidentDate: new Date(),
      incidentTime: new Date(),
      dispensingError: false,
      administrationError: false,
      transcriptionError: false,
      adr: false,
      communicationError: false,
      documentationError: false,
      hRRelatedError: false,
      bedSore: false,
      patientCareError: false,
      hKRelatedError: false,
      billingRelatedError: false,
      otherError: false,
      administrationRelatedError: false,
      needleStickError: false,
      processError: false,
      patientFall: false,
      status: null,
      remarkDtls: "",
      responsibleDepts: null,
      severityOfIncident: null,
      //
      errorCategory: null,
      errorSubCategory: null,
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    register,
  } = methods;

  useEffect(() => {
    getReportedToName();
    getIncidentCategory();
    getErrorCategory();
  }, []);

  const getReportedToName = () => {
    fetchEmoloyeeName()
      .then((response) => response.data)
      .then((res) => {
        setReportedToArr(res.result);
      });
  };
  const getErrorCategory = () => {
    fettchErrorCategory()
      .then((response) => response.data)
      .then((res) => {
        setErrorCategoryArr(res.result);
      });
  };
  const getSubError = (errCat) => {
    fetchSubError(errCat?.id)
      .then((response) => response.data)
      .then((res) => {
        setErrorSubCategoryArr(res.result);
      });
  };

  const getIncidentCategory = () => {
    fetchIncidentCategory()
      .then((response) => response.data)
      .then((res) => {
        setIncidentCategoryArr(res.result);
      });
  };

  const getResponsibleDepts = (searchString) => {
    searchString &&
      fetchDepartment(searchString)
        .then((response) => response.data)
        .then((res) => {
          setResDeptArr(res.result);
        });
  };

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const onSubmit = (data) => {
    if (isQuality) {
      if (data?.responsibleDepts?.length > 0) {
        setAllFormData(data);
        setOpenConfirmationModal(true);
        setIsReAssign(false);
      } else {
        warningAlert("Kindly Select Rresponsible Departmeents...");
      }
    } else {
      setAllFormData(data);
      setOpenConfirmationModal(true);
      setIsReAssign(false);
    }
  };

  const handleFormSubmit = (data) => {
    if (isReAssign) {
      setOpenBackdrop(true);

      resignIncident(selectedRow?.IncidentId || 0, CaPaSelectedRow?.deptid)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            setOpenConfirmationModal(false);
            setIsReAssign(false);
            setCaPaSelectedRow(null);
            getIncidentDepartmentDetails();
          }
        })
        .catch((res) => {
          errorAlert(res.message);
          setOpenConfirmationModal(false);
          setOpenBackdrop(false);
        });
    } else {
      let incidentTimeValid = isValid(data.incidentTime);

      const finalObj = {
        addedBy: 0,
        administrationError: Number(data.administrationError), //0
        administrationErrorMed: Number(data.administrationRelatedError), //0
        administrationMedError: 0,
        adrMedError: Number(data.adr), //0
        bedSoreError: Number(data.bedSore), //0
        bedsore: 0,
        billingError: Number(data.billingRelatedError), //0
        incidentCategory:
          data.incidentCategory !== null
            ? Number(data.incidentCategory?.id)
            : 0,

        categoryId:
          data.incidentCategory !== null
            ? Number(data.incidentCategory?.id)
            : 0,
        communicationError: Number(data.communicationError),
        departmentIds:
          data?.responsibleDepts?.length > 0
            ? data.responsibleDepts.map((item) => item.id)
            : null,
        dispensingError: Number(data.dispensingError), //not sure
        dispensingMedError: Number(data.dispensingError),
        documentationError: Number(data.documentationError),
        employee: 0,
        employeeDepartment: data.employeeDepartment,
        entryDate: format(data.incidentDate, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'"),

        entryTime: incidentTimeValid
          ? format(data.incidentTime, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
          : data.incidentTime
          ? `${data.incidentTime}Z`
          : null,

        eventDescription: data.eventDescriptionDtls,
        hkError: Number(data.hKRelatedError),
        hospital: "",
        hrError: Number(data.hRRelatedError),
        incidentId: selectedRow?.IncidentId || 0,
        // incidentId:
        //   data.incidentCategory !== null ? data.incidentCategory?.id : 0,
        incidentNo: 0,
        medicationError: 0,
        needleInjury: data.needleStickError, //t/f
        occurrencePlace: data.placeOfOccurrence,
        otherErrors: Number(data.otherError),
        otherResponsibleDepartment: "",
        patientCareError: Number(data.patientCareError),
        patientFall: data.patientFall, //t/f
        patientName: data.nameOfVictim, //nc
        processError: Number(data.processError),
        remark: data.remarkDtls,
        reportPersonName: data.reportingPersonName,
        reportedTo: data.reportedTo !== null ? data.reportedTo?.id : 0,
        status: data?.status?.id || 0,
        severity: data?.severityOfIncident?.label || "",
        transcriptionError: Number(data.transcriptionError),
        transcriptionMedError: Number(data.transcriptionError),
        updatedBy: token.userId,
        victimName: data.nameOfVictim,
        visitor: "",

        //
        menuId: location?.state?.menuId,
        privilege: privilege,
        errorCategory: data?.errorCategory?.id || 0,
        errorSubCategory: data?.errorSubCategory?.id || 0,
      };
      setOpenBackdrop(true);

      saveIncidentReport(finalObj)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setOpenBackdrop(false);
            setOpenConfirmationModal(false);
            methods.reset();
            setValue("incidentTime", new Date());
            setSelectedRow(null);
            setCapaPatchData([]);
          }
        })
        .catch((res) => {
          errorAlert(res.message);
          setOpenConfirmationModal(false);
          setOpenBackdrop(false);
        });
    }
  };

  const getIncidentDepartmentDetails = () => {
    patchIncidentDate();

    // isQuality = token.userRoles.some((item) => item === "Quality");
    isQuality = token.department === "Quality";

    selectedRow?.IncidentId &&
      fetchIncidentDepartmentDetails(
        selectedRow?.IncidentId,
        isQuality ? 0 : token?.departmentId
        // 0
      )
        .then((response) => response.data)
        .then((res) => {
          setCapaPatchData(res.result);
        });
  };

  // patch on edit
  useEffect(() => {
    getIncidentDepartmentDetails();
  }, [selectedRow]);

  const patchIncidentDate = () => {
    if (!!selectedRow) {
      setValue("nameOfVictim", selectedRow["Victim Name"]);
      setValue("reportingPersonName", selectedRow["Report Person Name"]);
      setValue("employeeDepartment", selectedRow["Employee Department"]);
      setValue("eventDescriptionDtls", selectedRow["Event Description"]);
      setValue("placeOfOccurrence", selectedRow["Occurence Place"]);
      setValue("reportedTo", {
        id: selectedRow["reportingEmpId"],
        label: selectedRow["Reporting Employee"],
        value: selectedRow["Reporting Employee"],
      });
      setValue("incidentCategory", {
        id: selectedRow["IncidentCategoryId"],
        label: selectedRow["Incident Category"],
        value: selectedRow["Incident Category"],
      });

      setValue("incidentDate", new Date(selectedRow["EntryDate"])); /////
      setValue("incidentTime", `1900-01-01T${selectedRow["entryTime"]}.000`); ////
      setValue("dispensingError", selectedRow["Dispensing Med Error"]); ////
      setValue("administrationError", selectedRow["Administration Error"]);
      setValue("transcriptionError", selectedRow["Transcription Med Error"]); ///
      setValue("adr", selectedRow["ADR Med Error"]); ///
      setValue("communicationError", selectedRow["Communication Error"]);
      setValue("documentationError", selectedRow["Documentation Error"]);
      setValue("hRRelatedError", selectedRow["HR Error"]);
      setValue("bedSore", selectedRow["Bed Sore Error"]); ////
      setValue("patientCareError", selectedRow["Patient Care Error"]);
      setValue("hKRelatedError", selectedRow["HK Error"]);
      setValue("billingRelatedError", selectedRow["Billing Error"]);
      setValue("otherError", selectedRow["Other Errors"]);
      setValue(
        "administrationRelatedError",
        selectedRow["Administration Error"]
      );
      setValue("needleStickError", selectedRow["Needle Injury"]); ///
      setValue("processError", selectedRow["Process Error"]);
      setValue("patientFall", selectedRow["Patient Fall"]); ///
      setValue(
        "status",
        incidentStatus.find((item) => item.id === selectedRow["Status"])
      );
      setValue("remarkDtls", selectedRow["Remark"]);
      setValue(
        "responsibleDepts",
        !!selectedRow["ResponsibleDepartments"] &&
          JSON.parse(selectedRow["ResponsibleDepartments"]).map((item) => ({
            id: item.DepartmentId,
            label: item.DepartmentName,
            value: item.DepartmentName,
          }))
      );
      //
      setValue("errorCategory", {
        id: selectedRow.errorCategoryId,
        label: selectedRow.IncidentCaregory,
        value: selectedRow.IncidentCaregory,
      });

      setValue("errorSubCategory", {
        id: selectedRow.errorSubCategoryId,
        label: selectedRow.IncidentSubCategory,
        value: selectedRow.IncidentSubCategory,
      });

      setValue(
        "severityOfIncident",
        severityArr.filter((item) => item.value === selectedRow?.Severity)
      );
    }
  };

  const renderActions = (row) => {
    return (
      <>
        {!!row?.IsClosed ? (
          <AssignOnIcon
            title={"Re-Assign Incident"}
            onClick={() => {
              setOpenConfirmationModal(true);
              setIsReAssign(true);
              setCaPaSelectedRow(row);
            }}
          />
        ) : (
          <AssignOffIcon />
        )}
      </>
    );
  };

  return (
    <div className="mt-12 mb-2">
      <div className="text-center text-black font-bold text-xl ">
        Incident Reporting
      </div>

      <form>
        <div className="grid grid-cols-5">
          <div></div>
          <div className="md:col-span-5 lg:col-span-3">
            <div className="flex justify-between items-center mb-3">
              <div>
                <p className="my-3 font-bold text-red-500 tracking-wide text-sm font-Poppins">
                  To be filled by staff within 12 hours of Incident/Accident.
                </p>
              </div>

              <div>
                <CommonButton
                  label="Previous Reports"
                  className="bg-customBlue text-white"
                  onClick={() => handleOpenPreviousReportsModal()}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-3 lg:grid-cols-2 gap-3">
              <div className="">
                <InputField
                  name="nameOfVictim"
                  label="Name of The Victim *"
                  variant="outlined"
                  error={errors.nameOfVictim}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="reportingPersonName"
                  label="Reporting Person Name *"
                  variant="outlined"
                  error={errors.reportingPersonName}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="employeeDepartment"
                  label="Employee Department"
                  variant="outlined"
                  // error={errors.representativeAddress}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="placeOfOccurrence"
                  label="Place of Occurrence *"
                  variant="outlined"
                  error={errors.placeOfOccurrence}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>

              <div className="md:col-span-2 lg:col-span-1">
                <DropdownField
                  control={control}
                  // error={errors.MLCType}
                  name="reportedTo"
                  placeholder="Reported To"
                  dataArray={reportedToArr}
                  isSearchable={false}
                />
              </div>

              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="incidentDate"
                  label="Incident Date"
                  value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              {!isQuality && (
                <>
                  <div className="">
                    <DropdownField
                      control={control}
                      // error={errors.errorCategory}
                      name="errorCategory"
                      placeholder="Category Of Incident"
                      dataArray={errorCategoryArr}
                      isSearchable={false}
                      inputRef={{
                        ...register("errorCategory", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              getSubError(e.target.value);
                            } else {
                              //setSelectedPatient("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="">
                    <DropdownField
                      control={control}
                      // error={errors.MLCType}
                      name="errorSubCategory"
                      placeholder="Sub Category Of Incident"
                      dataArray={errorSubCategoryArr}
                      isSearchable={false}
                    />
                  </div>
                </>
              )}

              <div className="">
                <Controller
                  control={control}
                  name="incidentTime"
                  render={({ field: { value, onChange } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopTimePicker
                        label="Incident Time"
                        value={value}
                        onChange={onChange}
                        disabled={false}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            // error={errors.startTime}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                position: "absolute",
                                top: "-2px",
                              },
                            }}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "  ",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            {/*
        <p className="my-3 font-bold tracking-wide text-sm font-Poppins">
          Medication Error
        </p>

        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3">
          <div className="">
            <CheckBoxField
              control={control}
              name="dispensingError"
              label="Dispensing Error "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="administrationError"
              label="Administration Error "
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="transcriptionError"
              label="Transcription Error "
            />
          </div>
          <div className="">
            <CheckBoxField control={control} name="adr" label="ADR " />
          </div>
        </div>
        <p className="my-3 font-bold tracking-wide text-sm font-Poppins">
          General Error
        </p>

        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3">
          <div className="">
            <CheckBoxField
              control={control}
              name="communicationError"
              label="Communication Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="documentationError"
              label="Documentation Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="hRRelatedError"
              label="HR Related Error"
            />
          </div>
          <div className="">
            <CheckBoxField control={control} name="bedSore" label="Bed Sore " />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="patientCareError"
              label="Patient Care Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="hKRelatedError"
              label="HK Related Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="billingRelatedError"
              label="Billing Related Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="otherError"
              label="Other Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="administrationRelatedError"
              label="Administration Related Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="needleStickError"
              label="Needle Stick Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="processError"
              label="Process Error"
            />
          </div>
          <div className="">
            <CheckBoxField
              control={control}
              name="patientFall"
              label="Patient Fall"
            />
          </div>
        </div>*/}
            <div className="mt-3">
              <InputArea
                control={control}
                name="eventDescriptionDtls"
                label="Event Description"
                placeholder="Event Description"
                error={errors.eventDescriptionDtls}
              />
            </div>
            {isQuality && (
              <>
                <hr className="border mx-1 mt-3  " />

                <p className="my-3 font-bold tracking-wide text-sm font-Poppins">
                  Details Filled By Responsible Department
                </p>

                <div className="my-3">
                  {capaPatchData?.length > 0 ? (
                    <CommonDynamicTableNew
                      dataResult={capaPatchData}
                      tableClass="max-h-60"
                      highlightRow={false}
                      handleSelectedRow={false}
                      SelectCheckbox={false}
                      removeHeaders={[
                        "incidentid",
                        "deptid",
                        "capaReceiveDate",
                        "IsClosed",
                      ]}
                      renderActions={renderActions}
                    />
                  ) : (
                    <div className="justify-center items-center font-semibold h-40 w-full flex border-2 border-gray-300 rounded my-2">
                      No Records Found...
                    </div>
                  )}
                </div>
                <p className="my-3 font-bold tracking-wide text-sm font-Poppins">
                  To Be Filled By Quality Department
                </p>
                <div className="grid md:grid-cols-3 lg:grid-cols-2 gap-3">
                  <div className="">
                    <DropdownField
                      control={control}
                      // error={errors.MLCType}
                      name="incidentCategory"
                      placeholder="Incident Category"
                      dataArray={incidentCategoryArr}
                      isSearchable={false}
                    />
                  </div>
                  <div className="">
                    <DropdownField
                      control={control}
                      // error={errors.MLCType}
                      name="status"
                      placeholder="Status"
                      dataArray={incidentStatus}
                      isSearchable={false}
                    />
                  </div>
                  <div className="">
                    <DropdownField
                      control={control}
                      // error={errors.errorCategory}
                      name="errorCategory"
                      placeholder="Category Of Incident"
                      dataArray={errorCategoryArr}
                      isSearchable={false}
                      inputRef={{
                        ...register("errorCategory", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              getSubError(e.target.value);
                            } else {
                              //setSelectedPatient("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="">
                    <DropdownField
                      control={control}
                      // error={errors.MLCType}
                      name="errorSubCategory"
                      placeholder="Sub Category Of Incident"
                      dataArray={errorSubCategoryArr}
                      isSearchable={false}
                    />
                  </div>
                  <div className="">
                    <DropdownField
                      control={control}
                      name="severityOfIncident"
                      placeholder="Severity Of Incident"
                      dataArray={severityArr}
                      isSearchable={false}
                    />
                  </div>
                  <div>
                    <InputArea
                      control={control}
                      //{...register("otherDtls")}
                      name="remarkDtls"
                      label="Remark"
                      placeholder="Remark"
                    />
                  </div>
                  <div className="md:col-span-2 lg:col-span-1">
                    <SearchDropdown
                      control={control}
                      placeholder="Responsible Departments"
                      dataArray={resDeptArr}
                      name="responsibleDepts"
                      searchIcon={true}
                      isClearable={true}
                      handleInputChange={(e) => getResponsibleDepts(e)}
                      isMulti={true}
                      inputRef={{
                        ...register("responsibleDepts", {
                          onChange: (e) => {
                            if (e.target.value !== null) {
                              //setSelectedPatient(e.target.value);
                            } else {
                              //setSelectedPatient("");
                              //methods.reset();
                            }
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {/*!isQuality && (
          <div className="grid grid-cols-4 mt-3">
            <SearchDropdown
              control={control}
              placeholder="Responsible Departments"
              dataArray={resDeptArr}
              name="responsibleDepts"
              searchIcon={true}
              isClearable={true}
              handleInputChange={(e) => getResponsibleDepts(e)}
              isMulti={true}
              inputRef={{
                ...register("responsibleDepts", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      //setSelectedPatient(e.target.value);
                    } else {
                      //setSelectedPatient("");
                      //methods.reset();
                    }
                  },
                }),
              }}
            />
          </div>
            )*/}

            {userActions.map((actions) => (
              <>
                {!actions.isAction && actions.action === "Create" ? (
                  <>
                    <div className="gap-3 my-3 flex justify-end">
                      <div>
                        <CommonButton
                          label="Reset"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            methods.reset();
                            setSelectedRow(null);
                            setIsFormView(false);
                          }}
                        />
                      </div>
                      {!isFormView && (
                        <div
                          onClick={() => {
                            setPrivilege(actions.action);
                          }}
                        >
                          <CommonButton
                            label="Save"
                            className="bg-customGreen text-white"
                            onClick={methods.handleSubmit(onSubmit)}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>
          <div></div>
        </div>
      </form>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {openPreviousReportsModal ? (
        <PreviousReportsList
          open={openPreviousReportsModal}
          setOpen={setOpenPreviousReportsModal}
          handleOpen={handleOpenPreviousReportsModal}
          handleClose={handleClosePreviousReportsModal}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          userActions={userActions}
          setIsFormView={setIsFormView}
        />
      ) : null}
    </div>
  );
};

export default IncidentReporting;
