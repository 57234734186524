import React from "react";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";

import CheckBoxField from "../../../../../common/components/FormFields/CheckBoxField";

const data = {
  result: [
    {
      markasappropriate: "History of Previous Falls",
    },
    {
      markasappropriate: "Patient Who Are Above 65 years.",
    },
    {
      markasappropriate: "Below Age of 16 years.",
    },
    {
      markasappropriate:
        "Patients with Physical Limitations :- Both Visual and Mobility.",
    },
    {
      markasappropriate: "Patients With Cerebral vasculr Accident (CVA).",
    },
    {
      markasappropriate:
        "Patients Who Have Postural Hypotension and Dizziness / Vertigo.",
    },
    {
      markasappropriate: "Emotionally Disturbed / psychiatric Pts.",
    },
    {
      markasappropriate: "Patients With Visual/ hearing Defects.",
    },
    {
      markasappropriate: "Post Operative in the last 24 hours.",
    },
    {
      markasappropriate: "Terminally ill.",
    },
    {
      markasappropriate: "Experiencing pain.",
    },
    {
      markasappropriate: "Women in labour.",
    },
    {
      markasappropriate: "Victim of Suspected drug/ Substance Abuse.",
    },
    {
      markasappropriate: "Patient Fall Assessment score of >13.",
    },
  ],
};

function PatientVulnerabilityCriteria() {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <div className="w-full mx-2">
      <div className="w-auto grid mx-2">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 0.4 }}>
            <form
              className="w-full "
              // onSubmit={handleSubmit(onSubmitDataHandler)}
            >
              <TableContainer sx={{ marginTop: "0rem" }} className="rounded ">
                <Table size="small">
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          paddingY: 0.5,
                          backgroundColor: "#F1F1F1",
                        },
                      }}
                    >
                      <TableCell style={{ borderLeft: "1px solid lightGray" }}>
                        <span className="font-bold whitespace-nowrap">
                          Mark as Appropriate
                        </span>
                      </TableCell>

                      <TableCell style={{ borderRight: "1px solid lightGray" }}>
                        <span className="font-bold whitespace-nowrap">
                          Mark
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.result.map((row, index) => {
                      return (
                        <TableRow
                          sx={{
                            "& td": {
                              paddingY: 0,
                            },
                          }}
                          key={row.markasappropriate}
                        >
                          <TableCell
                            className="whitespace-nowrap"
                            style={{ borderLeft: "1px solid lightGray" }}
                          >
                            <span className="">{row.markasappropriate}</span>
                          </TableCell>

                          <TableCell
                            className="whitespace-nowrap"
                            style={{ borderRight: "1px solid lightGray" }}
                          >
                            <span className="">
                              <CheckBoxField
                                control={control}
                                name={row.markasappropriate}
                                label=""
                              />
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="border border-b border-customBlue mx-2"> </div>
            </form>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default PatientVulnerabilityCriteria;
