import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Modal, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DeleteIcon } from "../../../../assets/CommonAssets";
import Average from "../../../../assets/scaleEmoji/Average.png";
import StarPng from "../../../../assets/scaleEmoji/Star.png";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonTransactionTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { CapitalizeStatement } from "../../../../common/components/Custom Hooks/CapitalizeStatement";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import InputField from "../../../../common/components/FormFields/InputField";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { saveAnswerReviewScale } from "../../../services/feedback/answerreview/AnswerReviewServices";
import PreviewModal from "./PreviewModal";

const ratingLength = [
  {
    id: 1,
    value: "3",
  },
  {
    id: 2,
    value: "5",
  },
];

const answerTypeData = [
  {
    id: 1,
    value: "Star",
  },
  {
    id: 2,
    value: "Emoji",
  },
  {
    id: 3,
    value: "Chips",
  },
];

const ratingArr = [
  {
    id: 1,
    value: "1",
    label: "1",
  },
  {
    id: 2,
    value: "2",
    label: "2",
  },
  {
    id: 3,
    value: "3",
    label: "3",
  },
  {
    id: 4,
    value: "4",
    label: "4",
  },
  {
    id: 5,
    value: "5",
    label: "5",
  },
];
export default function CreateNewReviewModal(props) {
  const schema = yup.object().shape({
    answerType: yup
      .string()
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    // reviewLabel: yup
    //   .string()
    //   .when("hasConclusionQuestion", (isMultiLangauge) => {
    //     if (isMultiLangauge === false) {
    //       return yup
    //         .string()
    //         .required("Required")
    //         .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed");
    //     }
    //   }),
  });
  const defaultValues = {
    answerType: "",
    reviewLabel: "",
    reviewLabelHindi: "",
    reviewLabelMarathi: "",
    rating: null,
    isMultiLangauge: false,
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  // local varibles
  let localStorageValue = JSON.parse(localStorage.getItem("userInfo"));
  let isMultiLangaugeValue = watch("isMultiLangauge");

  // state varibles
  const [reviewCreateData, setReviewCreateData] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const [selectedAnswerType, setSelectedAnswerType] = useState("Star");
  const [selectedRatingCount, setSelectedRatingCount] = useState("3");
  const [deleteRow, setDeleteRow] = useState(null);

  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const [openPostConfirmationModal, setOpenPostConfirmationModal] = useState(
    false
  );
  //
  const [selectedLanguages, setSelectedLanguages] = useState(["english"]);

  const handleLanguageSelect = (language) => {
    const isSelected = selectedLanguages.includes(language);
    if (isSelected) {
      setSelectedLanguages(
        selectedLanguages.filter((lang) => lang !== language)
      );
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  ///

  // handle close preview modal
  const handleClosePreviewModal = () => setOpenPreviewModal(false);

  // delete row confirmation modal
  const handleCloseDeletConfirmationModal = () => {
    if (openDeleteConfirmationModal) {
      setOpenDeleteConfirmationModal(false);
    }
  };

  // add reviews
  const handleCreateNewReview = () => {
    let tempArr = [...reviewCreateData];
    let reviewLabelvalue = getValues("reviewLabel");
    let reviewLabelvalueHindi = getValues("reviewLabelHindi");
    let reviewLabelvalueMarathi = getValues("reviewLabelMarathi");
    let ratingValue = getValues("rating");

    if (tempArr.length >= 5) {
      warningAlert("You cannot add more than 5 reviews.");
      return;
    }

    // Check for duplicate "Review Label" and "Rating"

    if (reviewLabelvalue !== "" && ratingValue !== null) {
      if (isMultiLangaugeValue === false) {
        const isDuplicate = tempArr.find(
          (review) =>
            review["Review Label"] === reviewLabelvalue ||
            review.Rating === ratingValue.label
        );

        if (isDuplicate) {
          warningAlert(
            "Review with the same label and rating already exists. Please choose different values."
          );
          return;
        }
      } else {
        const isDuplicate = tempArr.find(
          (review) =>
            review["Review Label"] === reviewLabelvalue ||
            review["Review Label Hindi"] === reviewLabelvalueHindi ||
            review["Review Label Marathi"] === reviewLabelvalueMarathi ||
            review.Rating === ratingValue.label
        );

        if (isDuplicate) {
          warningAlert(
            "Review with the same label and rating already exists. Please choose different values."
          );
          return;
        }
      }
      if (isMultiLangaugeValue === false) {
        let obj = {
          id: `${Date.now()}`,
          "Review Label": reviewLabelvalue,
          Rating: ratingValue.label,
        };
        tempArr.push(obj);
      } else {
        let obj = {
          id: `${Date.now()}`,
          "Review Label": reviewLabelvalue,
          "Review Label Hindi": reviewLabelvalueHindi,
          "Review Label Marathi": reviewLabelvalueMarathi,
          Rating: ratingValue.label,
        };
        tempArr.push(obj);
      }
      tempArr.sort((a, b) => a.Rating - b.Rating);
      setReviewCreateData(tempArr);
      setValue("rating", null);
      setValue("reviewLabel", "");
      setValue("reviewLabelHindi", "");
      setValue("reviewLabelMarathi", "");
    }

    if (isMultiLangaugeValue) {
      if (reviewLabelvalueHindi !== "") {
        clearErrors(["reviewLabelHindi"]);
      } else if (reviewLabelvalueHindi === "") {
        setError("reviewLabelHindi", { type: "custom", message: "Required" });
      }
    }

    if (isMultiLangaugeValue) {
      if (reviewLabelvalueMarathi !== "") {
        clearErrors(["reviewLabelMarathi"]);
      } else if (reviewLabelvalueMarathi === "") {
        setError("reviewLabelMarathi", { type: "custom", message: "Required" });
      }
    }

    if (reviewLabelvalue !== "") {
      clearErrors(["reviewLabel"]);
    } else if (reviewLabelvalue === "") {
      setError("reviewLabel", { type: "custom", message: "Required" });
    }

    if (ratingValue !== null) {
      clearErrors(["rating"]);
    } else if (ratingValue === null) {
      setError("rating", { type: "custom", message: "Required" });
    }
  };

  // close save confirmation modal
  const handleClosePostConfirmationModal = () => {
    if (openPostConfirmationModal) {
      setOpenPostConfirmationModal(false);
    }
  };

  // delete
  const deleteRecord = () => {
    if (deleteRow !== null) {
      let newTemplateData = [...reviewCreateData];
      let newTemplate = newTemplateData.filter(
        (item) => item.Rating !== deleteRow
      );
      setReviewCreateData(newTemplate);
      handleCloseDeletConfirmationModal();
    }
  };

  const onSubmitDataHandler = (dataObj) => {
    const isLanguageSelected = (data) => {
      const selectedLanguages = Object.keys(data[0]).filter(
        (key) => key !== "Rating" && data[0][key] !== ""
      );
      if (
        selectedLanguages.length === 0 ||
        data.some((row) => selectedLanguages.some((lang) => row[lang] === ""))
      ) {
        return false; // Not all conditions met
      }
      return true; // All conditions met
    };

    // Usage:
    const isValid = isLanguageSelected(reviewCreateData);

    /////////////

    if (isValid && reviewCreateData.length > 0) {
      const requiredArr = reviewCreateData.map((obj) => ({
        label: CapitalizeStatement(obj["Review Label English"]),
        labelHindi: obj["Review Label Hindi"]
          ? CapitalizeStatement(obj["Review Label Hindi"])
          : null,
        labelMarathi: obj["Review Label Marathi"]
          ? CapitalizeStatement(obj["Review Label Marathi"])
          : null,
        value: Number(obj.Rating),
      }));

      const requiredObj = {
        answerPatternReviewLabel: requiredArr,
        answerReviewTypeDescription: dataObj.answerType,
        answerType: selectedAnswerType,
        createdBy: localStorageValue.userId,
        lastModifiedBy: localStorageValue.userId,
        isMultiLang: isMultiLangaugeValue,
        id: props.selectedInfoRow ? props.selectedInfoRow.Id : null,
        scaleLength: selectedRatingCount,
      };

      setFinalData(requiredObj);
      setOpenPostConfirmationModal(true);
    } else {
      warningAlert("Please Create Review Label!");
    }
  };

  // api function
  function postAnswerReview() {
    saveAnswerReviewScale(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.handleClose();
        reset(defaultValues);
        setSelectedAnswerType("Star");
        props.populateTable();
        setOpenPostConfirmationModal(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  }

  // calling the  api function
  function addRecord() {
    setOpenPostConfirmationModal(false);
    postAnswerReview();
  }

  // delete button
  function renderActions(row) {
    return (
      <div className="flex gap-1">
        <Tooltip title="Delete">
          <button
            type="button"
            onClick={() => {
              setDeleteRow(row.Rating);
              setOpenDeleteConfirmationModal(true);
            }}
            className="cursor-pointer"
          >
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
    );
  }

  // selected edit row information
  useEffect(() => {
    let tempArr = [];
    if (props.selectedInfoRow !== null) {
      for (let obj of props.selectedInfoRow.PreViewInfo) {
        let tempObj = {
          Rating: obj.value,
          "Review Label": obj.label,
        };
        tempArr.push(tempObj);
        setReviewCreateData(tempArr);
      }
      setValue("answerType", props.selectedInfoRow["Answer Review Type"]);
      setSelectedAnswerType(props.selectedInfoRow["Answer Type"]);
    }
  }, [props.selectedInfoRow]);

  // clear errors when is multilangauge checkbox
  useEffect(() => {
    clearErrors(["reviewLabel"]);
    clearErrors(["reviewLabelHindi"]);
    clearErrors(["reviewLabelMarathi"]);
    clearErrors(["rating"]);
  }, [isMultiLangaugeValue]);

  ////////////////$$$$$$$$$$$$$

  useEffect(() => {
    generateReviewData(selectedRatingCount, selectedLanguages);
  }, [selectedRatingCount, selectedLanguages]);

  const generateReviewData = (count, selectedLanguages) => {
    const dataArray = Array.from({ length: count }, (_, index) => {
      const reviewData = { Rating: index + 1 };
      selectedLanguages.forEach((lang) => {
        if (lang === "english" || lang === "hindi" || lang === "marathi") {
          reviewData[
            `Review Label ${lang?.charAt(0)?.toUpperCase() + lang?.slice(1)}`
          ] = "";
        }
      });
      return reviewData;
    });
    setReviewCreateData(dataArray);
  };

  ////////////////$$$$$$$$$$$$$
  const renderInput = (row, index, header) => {
    const handleChange = (e, header) => {
      const inputValue = e.target.value;
      const temp = [...reviewCreateData];
      temp[index][header] = inputValue;
      setReviewCreateData(temp);
    };

    return (
      <>
        <div>
          <input
            key={index}
            className="border border-gray-300 rounded-md px-3 py-1 w-full focus:outline-none focus:border-blue-500"
            value={row[header]}
            onChange={(e) => handleChange(e, header)}
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="w-[90%] lg:w-[85%] h-[430px] 2xl:h-[500px]"
        >
          <CancelPresentationIconButton
            className="bg-[#FFDCDC] rounded-full"
            onClick={() => {
              props.handleClose();
              props.setSelectedInfoRow(null);
            }}
          />
          <h1 className="font-Poppins font-semibold text-lg grid items-center justify-center  pb-2">
            Answer Review Create
          </h1>
          <form
          // onSubmit={handleSubmit(onSubmitDataHandler)}
          >
            <div
              className="flex space-x-2 items-center"
              onClick={(e) => {
                if (!e.target.checked) {
                  setSelectedLanguages(["english"]);
                  // handleLanguageSelect("english");
                }
              }}
            >
              <CheckBoxField
                control={control}
                name="isMultiLangauge"
                label="Is MultiLangauge"
              />
              <div className="w-5/12">
                <InputField
                  control={control}
                  name="answerType"
                  label="Answer Review Type *"
                  error={errors.answerType}
                />
              </div>
              <div className="flex space-x-2 items-center">
                <h3 className="whitespace-nowrap text-sm font-semibold ">
                  Answer Type &nbsp; : &nbsp;
                </h3>
                {answerTypeData.map((value, index) => {
                  return (
                    <>
                      <div className="flex space-x-2 items-center">
                        {value.value === "Star" ? (
                          <button
                            type="button"
                            className={
                              selectedAnswerType === "Star"
                                ? "border bg-customBlue px-5 rounded py-[5px] w-[131px]  justify-center flex space-x-2 text-sm"
                                : `px-5 rounded py-[5px] w-[131px] bg-[#D0E9FF] justify-center flex space-x-2 text-sm`
                            }
                            onClick={() => {
                              setSelectedAnswerType(value.value);
                            }}
                          >
                            <img src={StarPng} className="h-6 text-center" />
                          </button>
                        ) : (
                          ""
                        )}
                        {value.value === "Emoji" ? (
                          <button
                            type="button"
                            className={
                              selectedAnswerType === "Emoji"
                                ? "border bg-customBlue px-5 rounded py-[5px] w-[131px]  justify-center flex space-x-2 text-sm"
                                : `px-5 rounded py-[5px] w-[131px] bg-[#D0E9FF] justify-center flex space-x-2 text-sm`
                            }
                            onClick={() => {
                              setSelectedAnswerType(value.value);
                            }}
                          >
                            <img src={Average} className="h-6" />
                          </button>
                        ) : (
                          ""
                        )}
                        {value.value === "Chips" ? (
                          <button
                            type="button"
                            className={
                              selectedAnswerType === "Chips"
                                ? "border bg-customBlue px-5 rounded py-[5px] w-[131px]  justify-center flex space-x-2 text-sm"
                                : `px-5 rounded py-[5px] w-[131px] bg-[#D0E9FF] justify-center flex space-x-2 text-sm`
                            }
                            onClick={() => {
                              setSelectedAnswerType(value.value);
                            }}
                          >
                            <h5 className="border px-2 rounded bg-white">1</h5>
                            <h5 className="border px-2 rounded bg-white">2</h5>
                            <h5 className="border px-2 rounded bg-white">3</h5>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <Divider variant="middle" className="py-2" />

            <div className="flex gap-3 items-center my-3">
              <div className="flex gap-3 ">
                {ratingLength.map((item) => {
                  return (
                    <div className="flex gap-3">
                      <button
                        type="button"
                        className={
                          Number(selectedRatingCount) === Number(item.value)
                            ? "border bg-customBlue px-5 rounded py-[5px]   justify-center flex space-x-2 text-sm"
                            : "px-5 rounded py-[5px]  bg-[#D0E9FF] justify-center flex space-x-2 text-sm"
                        }
                        onClick={() => {
                          setSelectedRatingCount(Number(item.value));
                        }}
                      >
                        {Array.from({ length: Number(item.value) }).map(
                          (_, index) => (
                            <h5
                              key={index}
                              className="border px-2 rounded bg-white"
                            >
                              {selectedAnswerType === "Star" ? (
                                <img
                                  src={StarPng}
                                  className="h-5 text-center"
                                />
                              ) : selectedAnswerType === "Emoji" ? (
                                <img src={Average} className="h-5" />
                              ) : (
                                index + 1
                              )}
                            </h5>
                          )
                        )}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="w-px bg-gray-400 h-8"></div>

              <div className="flex space-x-4">
                {["english", "hindi", "marathi"].map((language) => (
                  <div
                    key={language}
                    className={`px-4 py-1 rounded-2xl ${
                      selectedLanguages.includes(language)
                        ? "bg-blue-900 text-white"
                        : "bg-gray-200"
                    }`}
                    onClick={() => {
                      isMultiLangaugeValue && handleLanguageSelect(language);
                    }}
                  >
                    {language.charAt(0).toUpperCase() + language.slice(1)}
                  </div>
                ))}
              </div>
            </div>
            {reviewCreateData?.length > 0 ? (
              <CommonTransactionTable
                dataResult={reviewCreateData}
                // renderActions={renderActions}
                highlightRow={false}
                removeHeaders={["id"]}
                tableClass={""}
                editableColumns={[
                  "Review Label English",
                  "Review Label Hindi",
                  "Review Label Marathi",
                ]}
                renderInput={renderInput}
              />
            ) : (
              <h3 className="text-center my-24 text-gray-600">
                No Review Added <span className="animate-pulse">...</span>
              </h3>
            )}
            <div className="flex justify-end space-x-2">
              <CommonButton
                type="button"
                label="Show Preview"
                className="border border-[#073763] text-[#073763]"
                onClick={() => {
                  if (reviewCreateData.length > 0) {
                    setOpenPreviewModal(true);
                  } else {
                    warningAlert("Please Add Atleast One Review!");
                  }
                }}
              />
              <CommonButton
                type="button"
                label="Reset"
                className="border border-red-600 text-red-600 "
                onClick={() => {
                  setReviewCreateData([]);
                  reset(defaultValues);
                  setSelectedAnswerType("Star");
                  setSelectedRatingCount(3);
                  setSelectedLanguages(["english"]);
                }}
              />
              <CommonButton
                // type="submit"
                label="Save"
                className="bg-green-600 text-white"
                onClick={handleSubmit(onSubmitDataHandler)}
              />
            </div>
          </form>
        </Box>
      </Modal>
      {openPreviewModal ? (
        <PreviewModal
          open={openPreviewModal}
          handleClose={handleClosePreviewModal}
          reviewCreateData={reviewCreateData}
          selectedAnswerType={selectedAnswerType}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        confirmationOpen={openPostConfirmationModal}
        confirmationHandleClose={handleClosePostConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Proceed"
      />
      <ConfirmationModal
        confirmationOpen={openDeleteConfirmationModal}
        confirmationHandleClose={handleCloseDeletConfirmationModal}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are You Sure Want To Delete This Item ?"
        confirmationButtonMsg="Delete"
      />
    </div>
  );
}
