import { format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchBloodIssueNoteList,
  fetchBloodIssueNoteListSearch,
} from "../../services/bloodIssueNoteServices/BloodIssueNoteServices";
import BloodIssueDtlsModal from "./BloodIssueDtlsModal";
import InstructionsModalIssueNote from "./InstructionsModalIssueNote";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

let showSpinner = false;
const BloodIssueNote = (props) => {
  const location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  //Instructions modal
  const [openInstructionsModal, setOpenInstructionsModal] = useState(true);
  const handleOpenInstructionsModal = () => setOpenInstructionsModal(true);
  const handleCloseInstructionsModal = () => setOpenInstructionsModal(false);

  // blood issue dtls modal
  const [openBloodIssueDtlsModal, setOpenBloodIssueDtlsModal] = useState(false);
  const handleOpenBloodIssueDtlsModal = () => setOpenBloodIssueDtlsModal(true);
  const handleCloseBloodIssueDtlsModal = () =>
    setOpenBloodIssueDtlsModal(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      patientSearch: "",
      // toDate: new Date(),
      // fromDate: new Date(),
    },
  });

  const { control, watch, register } = methods;

  

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [selectedFromDate, selectedToDate]);

  const getPatientInfoAutoComplete = (SearchString) => {
    console.log("getPatientInfoAutoComplete", SearchString);
    const autoCopmleteObj = {
      fromDate: format(selectedFromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
      searchString: SearchString || "",
      toDate: format(selectedToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'"),
    };
    fetchBloodIssueNoteListSearch(autoCopmleteObj)
      .then((response) => response.data)
      .then((res) => {
        setAutocompleteData(res.result);
      });
  };

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [selectedFromDate, selectedToDate, selectedPatient]);

  const populateTable = (forPagination) => {
    showSpinner = true;
    const acceptanceObj = {
      admissionId: props.admissionId || selectedPatient?.id,
      fromDate: format(selectedFromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      toDate: format(selectedToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      menuId: props.menuId || location?.state?.menuId,
    };

    fetchBloodIssueNoteList(acceptanceObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
        setUserActions(res.actions);
        showSpinner = false;
      });
  };

  const handleSelectedRow = (row) => {
    const actionObj = userActions.find(
      (actionObj) => actionObj.action === "Create" && actionObj.isAction
    );

    if (actionObj) {
      handleOpenBloodIssueDtlsModal();
      setSelectedRow(row);
      setPrivilege(actionObj.action);
    }
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-center text-black font-bold text-xl ">
          Blood Issue Note
        </div>

        <div className=" py-2 grid grid-cols-6 gap-3 mt-3">
          <div className="md:col-span-2 lg:col-span-2 mb-3">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={autocompleteData}
              name="patientSearch"
              searchIcon={true}
              isClearable={true}
              isDisabled={props.isFromClinicalCarechart}
              handleInputChange={getPatientInfoAutoComplete}
              inputRef={{
                ...register("patientSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                    } else {
                      setSelectedPatient("");
                      methods.reset();
                    }
                  },
                }),
              }}
            />
          </div>

          {/* from Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              inputRef={{
                ...register("fromDate", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedFromDate(e.target.value);
                    }
                  },
                }),
              }}
            />
          </div>

          {/* to Date */}
          <div className="md:col-span-2 lg:col-span-1">
            <DatePickerFieldNew
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              inputRef={{
                ...register("toDate", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelectedToDate(e.target.value);
                    }
                  },
                }),
              }}
            />
          </div>
        </div>

        <div>
          {showSpinner ? (
            <div className="flex justify-center mt-14">
              <LoadingSpinner />
            </div>
          ) : dataResult.length > 0 ? (
            <CommonDynamicTablePaginationNew
              dataResult={dataResult}
              handleSelectedRow={handleSelectedRow}
              populateTable={populateTable}
              // renderActions={renderActions}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              count={count}
              tableClass="2xl:h-[650px]"
              removeHeaders={[
                "id",
                "Routine",
                "Emergency",
                "Adult",
                "Pediatric",
                "Whole Blood",
                "PCV",
                "FFP",
                "RDP",
                "SDP",
                "Any Other",
                "Status",
                "Whole Blood Qty",
                "SDP Qty",
                "PCV Qty",
                "FFP Qty",
                "RDP Qty",
                "CRYOPRECIPITATE",
                "CRYOPRECIPITATEQty",
                "CRYOPRECIPITATE_BalQty",
                "RequisitionID",
                "admissionid",
                "Any Other Text",
              ]}
            />
          ) : (
            <p className="text-center my-6 ">No Data Found</p>
          )}
        </div>
      </div>

      {/* //Instruction Modal// */}
      {openInstructionsModal && (
        <InstructionsModalIssueNote
          open={openInstructionsModal}
          setOpen={setOpenInstructionsModal}
          handleOpen={handleOpenInstructionsModal}
          handleClose={handleCloseInstructionsModal}
        />
      )}

      {openBloodIssueDtlsModal ? (
        <BloodIssueDtlsModal
          open={openBloodIssueDtlsModal}
          setOpen={setOpenBloodIssueDtlsModal}
          handleOpen={handleOpenBloodIssueDtlsModal}
          handleClose={handleCloseBloodIssueDtlsModal}
          selectedRow={selectedRow}
          populateTable={populateTable}
          menuId={props.menuId || location?.state?.menuId}
          privilege={privilege}
        />
      ) : null}
    </>
  );
};

export default BloodIssueNote;
