import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

///in patient return post

export const postInPatientReturnData = (finalObj) => {
  return apiClient.post(`/inPatientReturn/saveInvIPDReturn`, finalObj, {
    headers: authHeader(),
  });
};

///search patient/autocomplet
//Item patient List for listing page
export const fetchPatientList = (searchString) => {
  return apiClient.get(`/inPatientReturn/searchPatient/${searchString}`, {
    headers: authHeader(),
  });
};

//Item patient Details
export const fetchPatientDetails = (admissionId) => {
  return apiClient.get(`/inPatientReturn/patientInfo/${admissionId}`, {
    headers: authHeader(),
  });
};

export const fetchIssueItemList = (admissionId,returnType) => {
  return apiClient.get(`/inPatientReturn/issueItemList/${admissionId}/${returnType}`, {
    headers: authHeader(),
  });
};


//Item  Return Items
export const fetchItemDetails = (
  admissionId,
  ipdIssueId,
  menuId,returnType
) => {
  return apiClient.get(
    `/inPatientReturn/itemDetailsList/${admissionId}/${ipdIssueId}/${menuId}/${returnType}`,
    {
      headers: authHeader(),
    }
  );
};

//Item  Return Items
export const fetchTaxType = () => {
  return apiClient.get(`/misc/taxType`, {
    headers: authHeader(),
  });
};

//return list

//Item list search
export const fetchInPatientReturnListSearch = (searchListObj) => {
  return apiClient.post(
    `/inPatientReturn/getInPatientReturnListForApprovalSearch`,
    searchListObj,
    {
      headers: authHeader(),
    }
  );
};

//Item  Return list
export const fetchInPatientReturnListForApproval = (returnListObj) => {
  return apiClient.post(
    `/inPatientReturn/getInPatientReturnListForApproval`,
    returnListObj,
    {
      headers: authHeader(),
    }
  );
};

//Item  Return approve
export const approveInPatientReturn = (approvalObj) => {
  return apiClient.post(
    `/inPatientReturn/approveInPatientReturn`,
    approvalObj,
    {
      headers: authHeader(),
    }
  );
};

//Item  Return details list
export const fetchInPatientReturnDetailsList = (ipdReturnId,cashCredit) => {
  return apiClient.get(
    `/inPatientReturn/getInPatientReturnDetailsListForApproval/${ipdReturnId}/${cashCredit}`,
    {
      headers: authHeader(),
    }
  );
};

export const CreditReturnVoucherData = (ipdReturnId) => {
  // /api/reports/inventory/ipdCreditReturnVoucherPrint/{ipdReturnId} 983068
  return apiClient.get(
    `/reports/inventory/ipdCreditReturnVoucherPrint/${ipdReturnId}`,
    {
      headers: authHeader(),
    }
  );
};
