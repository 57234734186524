import apiClient from "../http-common";
import authHeader from "../authentication/authservices/auth-header";

export const saveReviews = (postObj) => {
  return apiClient.post(`/reviews/saveTemplateReview`, postObj, {
    headers: authHeader(),
  });
};

export const getTemplateQuestions = (id) => {
  return apiClient.get(`/getTemplateQuestions?templateId=${id}`, {
    headers: authHeader(),
  });
};
//http://192.168.0.136:5858/api/reviews/getTemplateQuestionsForReviewForWeb/6/Consultant

export const getTemplateQuestionsForReviewForWeb = (templateFor, depId) => {
  return apiClient.get(
    `/reviews/getTemplateQuestionsForReviewForWeb/${depId}/${templateFor}`,
    {
      headers: authHeader(),
    }
  );
};