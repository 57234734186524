import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useState } from "react";
import CommonButton from "../../../common/components/Buttons/CommonButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function InstructionModal(props) {
  const { open, setOpen, handleClose } = props;
  const [readCheck, setReadCheck] = useState(false);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
         

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              BEFORE FILLING THE FORM PLEASE READ THE FOLLOWING INSTRUCTIONS
              CAREFULLY
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  ">
              <div>
                <span className="font-bold">1. Blood Reservation : </span>
                <span>
                  To Reserve blood in blood storage/ blood bank please fill this
                  form correctly and completely. Along with this form send to cc
                  EDTA sample of patient. Reservation can be done 48 hrs before
                  planned use and the blood / blood product will be reserved in
                  the blood storage/ blood bank for the patient for a period of
                  72 hrs only.
                </span>
              </div>
              <br />
              <div>
                <span className="font-bold"> 2. Blood Issue : </span>
                <span>
                  To issue blood from blood storage/ blood bank an issue note
                  needs to be sent. The issue note will authroise release of the
                  blood / blood product from the blood storage / blood bank for
                  patients use. Once blood is issued for a particular patient it
                  will be used for that patient only and cannot be returned for
                  use by someone else.
                </span>
              </div>
              <br />
              <div>
                <span className="font-bold"> 3. Discard Note : </span>
                <span>
                  In case there is doubt on the time of transfusion/instructions
                  from consultant, blood should not be issued from blood storage
                  / blood bank until clarification is obtained. In a situation
                  blood has been issued from blood storage / blood bank and the
                  transfusion is cancelled, the discard note should be sent to
                  the blood storage providing reason for discard. AVOID WASTAGE
                  OF BLOOD / BLOOD PRODUCTS. ISSUE SENSIBLY
                </span>
              </div>
            </div>

            <div className="flex justify-between my-2 gap-3">
              <div className="flex items-center">
                <Checkbox
                  label="Read"
                  size="small"
                  checked={readCheck}
                  onChange={() => {
                    setReadCheck(!readCheck);
                  }}
                />
                <span className="font-bold text-gray-700">Read</span>
              </div>
              <div>
                <CommonButton
                  label="Proceed"
                  className="bg-customGreen text-white"
                  disabled={!readCheck}
                  onClick={() => handleClose()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
