import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

//set descending sort order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//set sort desc
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function CommonDynamicTable(props) {
  const [selectedID, setSelectedID] = React.useState(null);
  const [rowIndex, setRowIndex] = React.useState();

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data[0]);

  const headers = removeHeaders(
    allHeaders,
    props.removeHeaders ? props.removeHeaders : ["id"]
  );

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "lightBlue",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1e3a8a",
                  borderRadius: 4,
                },
              }}
              //   className="rounded lg:h-52 md:h-72"
              className={props.tableClass}
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",
                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    {props.actionsRequired ? (
                      <TableCell>
                        <span className="text-gray-600 font-bold whitespace-nowrap">
                          Actions
                        </span>
                      </TableCell>
                    ) : null}
                    {headers.map((header, index) => (
                      <TableCell
                        sortDirection={orderBy === header ? order : false}
                        className="whitespace-nowrap"
                      >
                        <TableSortLabel
                          active={false}
                          direction={orderBy === header ? order : "asc"}
                          onClick={createSortHandler(header)}
                          key={index}
                        >
                          <span className="text-gray-600 font-bold">
                            {header}
                          </span>
                          {orderBy === header ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data &&
                    props.data.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover={false}
                          // style={{
                          //   backgroundColor:
                          //     (props.highlightRow === undefined ||
                          //       props.highlightRow === true) &&
                          //     rowIndex === index
                          //       ? "#FFC44B"
                          //       : row.FirstLevel === true ||
                          //         row.SecondLevel === true ||
                          //         row.ThirdLevel === true
                          //       ? "#9CEF95"
                          //       : "",
                          // }}

                          style={{
                            backgroundColor:
                              (props.highlightRow === undefined ||
                                props.highlightRow === true) &&
                              rowIndex === index
                                ? props.customRowBgColor || "#FFC44B"
                                : props.rowBackgroundColor
                                ? props.rowBackgroundColor(row, index)
                                : "",
                          }}
                          sx={{
                            "& td": props.actionsRequired
                              ? {
                                  paddingY: 0.2,
                                }
                              : { paddingY: 0.5 },
                          }}
                          onClick={() => {
                            setRowIndex(index);
                            {
                              props.handleSelectedRow &&
                                props.handleSelectedRow(row, index);
                            }
                            {
                              props.setSelectedRow && props.setSelectedRow(row);
                            }
                          }}
                        >
                          {props.actionsRequired ? (
                            <TableCell>
                              {props.renderActions(row, index)}
                            </TableCell>
                          ) : null}
                          {headers &&
                            headers.map((header, i) => (
                              <TableCell
                                className="whitespace-nowrap"
                                key={i}
                                onClick={() => {
                                  // props.displayView(row, index);
                                }}
                              >
                                {row[header] === true
                                  ? "Yes"
                                  : row[header] === false
                                  ? "No"
                                  : props.editableColumns &&
                                    props.editableColumns.includes(header)
                                  ? props.renderInput(row, index, header)
                                  : row[header]}
                              </TableCell>
                            ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}
