import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonTransactionTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";

function AnswerCreationInfoModal(props) {
  const [answerCreationInfoData, seAnswerCreationInfoData] = useState([]);

  const handleRowReorder = (startIndex, endIndex) => {
    const updatedRows = [...answerCreationInfoData];
    const [movedRow] = updatedRows.splice(startIndex, 1);
    updatedRows.splice(endIndex, 0, movedRow);
    seAnswerCreationInfoData(updatedRows);
  };

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (index) => (event) => {
    event.preventDefault();
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const startIndex = Number(event.dataTransfer.getData("text/plain"));
    handleRowReorder(startIndex, index);
  };
  function renderInput(row, index, header) {
    return (
      <>
        <button
          type="button"
          draggable
          onDragStart={handleDragStart(index)}
          onDragOver={handleDragOver(index)}
          onDrop={handleDrop(index)}
        >
          <DragIndicatorIcon className="text-[#BDBDBD]" />
        </button>
      </>
    );
  }
  useEffect(() => {
    let tempArr = [];
    for (let obj of props.selectedInfoRow.PreViewInfo) {
      let tempObj = {
        Arrange: "",
        Rating: obj.value,
        " Review Label": obj.label,
      };
      tempArr.push(tempObj)
      seAnswerCreationInfoData(tempArr)
    }
  }, [props.selectedInfoRow]);
  
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyleFeedback} className="rounded w-[860px] max-h-[60%]">
          <h1 className="font-semibold text-[20px] text-center">Scale</h1>
          <CancelPresentationIconButton
            className="bg-[#FFDCDC] rounded-full"
            onClick={() => {
              props.handleClose();
            }}
          />
          <div>
            <h2 className="text-sm border p-1 rounded bg-gray-100">
              <span className="font-semibold">
                Answer Review Type &nbsp; : &nbsp;
              </span>
              {props.selectedInfoRow?.["Answer Review Type"]}
            </h2>
            {answerCreationInfoData?.length > 0 ? (
              <CommonTransactionTable
                dataResult={answerCreationInfoData}
                // renderInput={renderInput}
                // editableColumns={["Arrange"]}
                removeHeaders={["id","Arrange"]}
                highlightRow={false}
                tableClass={"h-[180px] lg:h-[250px] border rounded"}
              />
            ) : (
              ""
            )}
     
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AnswerCreationInfoModal;