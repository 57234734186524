import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getPatientInformationById } from "../../../../billing/services/BillingServices";
import { autoSearchPatientAdvance } from "../../../../billing/services/PatientAdvanceServices";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";

import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  listOfAdvanceService,
  listofRefundService,
  opdRefundOfAdvancePdfPrint,
  postRefundOfAdvanceService,
} from "../../../services/refund/RefundOfAdvanceServices";
import BillingInformationModal from "./BillingInformationModal";

const Actions = [
  {
    id: 0,
    action: "Print",
    isAction: true,
  },
];
const RefundOfAdvance = () => {
  const schema = yup.object().shape({
    advanceAvailable: yup.number(),
    refundAmount: yup
      .number()
      .min(1, "Required")
      .typeError("Required")
      .required("Required")
      .when("advanceAvailable", (advanceAvailable, schema) => {
        return schema
          .test({
            test: (refundAmount) => {
              if (!refundAmount) return true;
              return refundAmount <= advanceAvailable;
            },
            message: "Amount Exceeds Than Total Advance",
          })
          .required("Required");
      }),
    remark: yup.string(),
  });
  const defaultValues = {
    totalAdvance: 0,
    totalConsumed: 0,
    totalRefund: 0,
    advanceAvailable: 0,
    refundAmount: "",
    refundOfAdvanceSearch: "",
    remark: "",
    opdIpd: 0,
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables
  const [refundSearchOptions, setRefundSearchOptions] = useState([]);
  const [searchResultById, setSearchResultById] = useState("");
  const [advanceList, setAdvanceList] = useState([]);
  const [refundList, setRefundList] = useState([]);
  const [menuIdVal, setMenuIdVal] = useState(null);
  const [open, setOpen] = useState(false);
  const [patientInfoById, setPatientInfoById] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [postRefundOfAdvance, setPostRefundOfAdvance] = useState([]);
const[refundTotalAmt,setRefundTotalAmt]=React.useState(0)
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [billPaidAmount, setBillPaidAmount] = useState(0);
  const [sumOfRefundedAmt, setSumOfRefundedAmt] = useState(0);
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [totalOfRefund, setTotalOfRefund] = useState(0);
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const [userActions, setUserActions] = React.useState([]);
  //other variables
  let refundAmount = watch("refundAmount");
  let totalAdvanceVal = watch("BalanceAmount");
  // get menu id
  let location = useLocation();

  //useEffects
  useEffect(() => {
    setValue("totalAdvance", selectedRow["Amount"]);

    setValue("totalConsumed", selectedRow["RefundAmount"]);
    setValue("totalRefund", totalOfRefund);
    setValue("advanceAvailable", selectedRow["BalanceAmount"]);
  }, [selectedRow, totalOfRefund]);

  useEffect(() => {
    let totalAmt = 0;
    let finalRefundAmt = 0;
    let tempRefundAmt = [...refundList];

    for (let obj of tempRefundAmt) {
      totalAmt += obj.RefundAmount;
      setSumOfRefundedAmt(totalAmt);
    }
    finalRefundAmt = totalAmt - billPaidAmount;

    setTotalOfRefund(finalRefundAmt);
  }, [refundList, billPaidAmount]);

  // get menu id and button on components
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        })
        .catch((error) => {});
  }, []);

  //functions
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  //auto complete search patient
  function handleChange(searchRefundOfAdvance) {
    let opdIpd = 0;
    if (searchRefundOfAdvance !== "") {
      autoSearchPatientAdvance(searchRefundOfAdvance, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setRefundSearchOptions(res.result);
        })
        .catch((error) => {});
    }
  }

  function getPatientInfomation(seletedPatientid) {
    let opdIpd = 0;
    getPatientInformationById(seletedPatientid, opdIpd)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfoById(res.result);
        listOfAdvance(res.result);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    listOfRefund(selectedRow);
  }, [selectedRow]);

  //advance listing
  function listOfAdvance(advance) {
    let advanceObj = {
      opdIpd: 0,
      patientId: advance.patientId,
      unitId: advance.unitId,
      visitId: advance.visitId,
    };

    if (advanceObj) {
      listOfAdvanceService(advanceObj)
        .then((response) => response.data)
        .then((res) => {
          if (res !== null) {
            setAdvanceList(res.result);
            listOfRefund(selectedRow);
          } else {
            errorAlert(res.result.message);
            setAdvanceList("");
          }
        })
        .catch((error) => {});
    }
  }

  //Refund Listing
  function listOfRefund(selectedRow) {
   
      listofRefundService(selectedRow?.PatientAdvanceDetails)
        .then((response) => response.data)
        .then((res) => {
          setRefundList(res.result);
        })
        .catch((error) => {});
  
  }

  function submitPaymentInfoModal(paymentInfoList) {
    if (totalAdvanceVal !== 0) {
      setOpen(true);
    } else {
      setOpen(false);
      errorAlert("Please Select Advance from List");
    }

    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let remarksVal = getValues("remark");
    let refundAmountVal = getValues("refundAmount");

    let refundAdvancePostObj = {
      addedBy: userInfoObj.userId,
      cashCounterId: userInfoObj?.cashCounterId,

      menuId: location?.state?.menuId,
      privilege: "Create",
      advanceId: selectedRow.PatientAdvanceDetails,
      patientId: searchResultById.id,
      refundAmount: refundTotalAmt,
      remark: remarksVal,
      visitId: patientInfoById.visitId,
      opdIpdExternal: 0,
    };
    refundAdvancePostObj.paymentInfoList = paymentInfoList;
    console.log("setRefundTotalAmtsetRefundTotalAmtsetRefundTotalAmtsetRefundTotalAmt",refundTotalAmt);
    setPostRefundOfAdvance(refundAdvancePostObj);
    handleOpenConfirmation();
    setOpenBackdrop(true);
    postRefundOfAdvanceService(refundAdvancePostObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setPostRefundOfAdvance(res.result);
        setOpenBackdrop(false);
        reset();
        if (res.statusCode === 200) {
          handleOpenPrintModal(res);
        } else {
          errorAlert(errors.message);
        }
        setPostRefundOfAdvance(null);
        setPatientInfoById("");
        setAdvanceList([]);
        setSearchResultById("");
        setRefundList([]);
        reset();
        handleClose();
      })
      .catch(() => {
        errorAlert(errors.message);
        setOpenBackdrop(false);
      });
  }

  //prints print

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (advanceId) => {
    let advanceID = advanceId.result ? advanceId.result : advanceId;
    opdRefundOfAdvancePdfPrint(advanceID).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  // common table action button click function
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                {
                  <div className="flex gap-2 cursor-pointer">
                    {actions.action === "Print" ? (
                      <Tooltip title="Print">
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            handleOpenPrintModal(row?.RefundId);
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                }
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };
  // get row data function
  function handleSelectedRow(row) {
    setSelectedRow(row);
  }
  return (
    <div className="mt-12  px-6 min-h-screen">
      <div className="text-center text-black font-bold text-xl py-4">
        Refund Of Advance
      </div>
      <div className=" flex gap-3 md:flex md:w-full">
        <div className=" lg:w-2/5 md:w-full">
          <SearchDropdown
            control={control}
            name="refundOfAdvanceSearch"
            placeholder="Search By Patient Name / MR No./ Mobile No"
            dataArray={refundSearchOptions}
            searchIcon={true}
            isClearable={true}
            isSearchable={true}
            handleInputChange={handleChange}
            inputRef={{
              ...register("refundOfAdvanceSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSearchResultById(e.target.value);
                    getPatientInfomation(e.target.value.id);
                  } else {
                    setAdvanceList([]);
                    setSearchResultById("");
                    setRefundList([]);
                  }
                },
              }),
            }}
          />
        </div>
      </div>
      {searchResultById && searchResultById !== null ? (
        <>
          <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
            <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 font-semibold">Patient Name</span>
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById.patientName && patientInfoById?.patientName}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 xl:w-8 font-semibold">MRNo.</span>
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById && patientInfoById?.mrNo}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 xl:w-8 font-semibold">Age</span>
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById && patientInfoById?.ageYear}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                <span className="w-24 lg:w-20 font-semibold">Mobile No</span>

                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {patientInfoById && patientInfoById?.MobileNo}
                </span>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                <span className="w-24 lg:w-20 font-semibold">Balance Amt </span>

                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedRow && selectedRow?.BalanceAmount}
                </span>
              </div>
            </div>
          </fieldset>
          <form onSubmit={handleSubmit(submitPaymentInfoModal)}>
            <div className="lg:grid lg:grid-cols-2 gap-2 ">
              <div>
                <div className="text-md font-semibold">List Of Advance</div>
                {advanceList.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={advanceList}
                    actionsRequired={false}
                    handleSelectedRow={handleSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72"}
                  />
                ) : (
                  <>
                    <div className="text-center mt-4 p-4">No Record Found</div>
                  </>
                )}
                {/* <ListOfAdvance
                  advanceList={advanceList}

                  displayRowData={displayRowData}
                /> */}
              </div>
              <div>
                <div className="text-md font-semibold">List Of Refund</div>
                {refundList.length > 0 ? (
                  <CommonDynamicTableNew
                    dataResult={refundList}
                    renderActions={renderActions}
                    highlightRow={false}
                    tableClass={"rounded lg:h-52 md:h-72"}
                  />
                ) : (
                  <>
                    <div className="text-center mt-4 p-4">No Record Found</div>
                  </>
                )}
                {/* <ListOfRefund refundList={refundList} actions={actions} /> */}
              </div>
            </div>
            <fieldset className="  mx-auto border w-full rounded my-1">
              <legend className="ml-2 my-1 rounded-xl ">
                <p className="text-sm font-semibold tracking-wide mx-2">
                  Add Refund
                </p>
              </legend>

              <div className="grid lg:grid-cols-4 md:grid-cols-2  gap-3 p-3">
                <div>
                  <InputField
                    name="totalAdvance"
                    variant="outlined"
                    type="number"
                    label="Total Advance ₹"
                    error={errors.totalAdvance}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  <InputField
                    name="totalConsumed"
                    variant="outlined"
                    label=" Advance Consumed ₹"
                    error={errors.totalConsumed}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  <InputField
                    name="totalRefund"
                    variant="outlined"
                    label="Total Refund ₹"
                    error={errors.totalRefund}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  <InputField
                    name="advanceAvailable"
                    variant="outlined"
                    label="Advance Available ₹"
                    error={errors.advanceAvailable}
                    control={control}
                    disabled={true}
                  />
                </div>
                <div>
                  <InputField
                    name="refundAmount"
                    variant="outlined"
                    label="Refund Amount ₹"
                    error={errors.refundAmount}
                    control={control}
                    type="number"
                    disabled={false}
                  />
                </div>
                <div className="lg:col-span-2">
                  <InputField
                    name="remark"
                    variant="outlined"
                    label="Remarks"
                    control={control}
                    disabled={false}
                  />
                </div>
                <div className="flex justify-end  py-2 gap-2 lg:col-span-1 md:col-span-2">
                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <>
                          <CommonButton
                            label="Reset"
                            className="border border-customRed text-customRed"
                            onClick={() => {
                              reset();
                              setSearchResultById(null);
                              setValue("refundOfAdvanceSearch", null);
                              setAdvanceList([]);

                              setRefundList([]);
                            }}
                          />
                          <CommonButton
                            className="bg-customGreen text-white "
                            label="Save"
                            type="submit"
                            onClick={() => {
                              setMenuIdVal(obj);
                            }}
                          />
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </fieldset>
          </form>
        </>
      ) : null}

      <CommonBackDrop openBackdrop={openBackdrop} />
      <BillingInformationModal
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        refundAmount={Number(refundAmount)}
        postRefundOfAdvance={postRefundOfAdvance}
        submitPaymentInfoModal={submitPaymentInfoModal}
        setSearchResultById={setSearchResultById}
        getValues={getValues}
        patientInfoById={patientInfoById}
        setRefundTotalAmt={setRefundTotalAmt}
      />
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
  );
};

export default RefundOfAdvance;
