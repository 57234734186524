import { Modal, TableCell } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { useState } from "react";
import { useEffect } from "react";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { retriveInvestigations } from "../../../services/dischargeSummary/DischargeSummaryServices";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import { useFormContext } from "react-hook-form";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

export default function RetriveParametersModal(props) {
  const { patientData } = useContext(DischargeSummaryContext);
  const { setValue, watch } = useFormContext();

  const { handleClose } = props;
  const [investigationsData, setInvestigationsData] = useState([]);

  const selcted_investigations = watch("investigations");

  useEffect(() => {
    getInvestigations();
  }, []);

  const getInvestigations = () => {
    let admissionId = patientData.AdmissionId;
    let pathologyRadiology = selcted_investigations;

    retriveInvestigations(admissionId, pathologyRadiology)
      .then((response) => response.data)
      .then((res) => {
        //handleClose();
        setInvestigationsData(res.result);
      });
  };

  const renderInput = (row, index, header) => {
    const updateDataResult = (rowIndex, updatedData) => {
      // Update the dataResult state with the modified data
      //
      const newDataResult = [...investigationsData];
      newDataResult[rowIndex]["Test Parameters"] = updatedData;
      setInvestigationsData(newDataResult);
    };

    return (
      <div>
        {header === "Test Parameters" && (
          <TableCell className="whitespace-nowrap capitalize" key={index}>
            {row["Test Parameters"].length > 0 && (
              <CommonDynamicTableNew
                dataResult={row["Test Parameters"]}
                editableColumns={["Test Parameters"]}
                renderInput={renderInput}
                highlightRow={false}
                enableSelectAll={true}
                removeHeaders={["ReportId"]}
                setDataResult={(updatedData) =>
                  updateDataResult(index, updatedData)
                }
              />
            )}
          </TableCell>
        )}
      </div>
    );
  };

  const addInvestigationsToDischargeSummary = () => {
    const selectedParameters = investigationsData.map((item) =>
      item["Test Parameters"]
        .filter((param) => param.isChecked)
        .map(
          (param) =>
            `${param["Parameter Name"]} : ${param["First Level Value"]}`
        )
        .join(", ")
    );

    setValue("investigationsPathology", selectedParameters.join(","));
    handleClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Investigations
            </div>

            <hr className="border mx-1  border-blue-900" />
            {investigationsData.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={investigationsData}
                editableColumns={["Test Parameters"]}
                renderInput={renderInput}
                highlightRow={false}
                removeHeaders={["OrderDtlsId", "PathTestId"]}
              />
            ) : (
              <div className="my-4 text-center">Data Not Found</div>
            )}

            <div className="gap-3 my-3 flex justify-end">
              <CommonButton
                label="Cancel"
                className="border border-customRed text-customRed"
                onClick={() => handleClose()}
              />
              {investigationsData.length > 0 && (
                <CommonButton
                  label="Proceed"
                  className="bg-customGreen text-white"
                  onClick={addInvestigationsToDischargeSummary}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
