import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { DeleteOnIcon } from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputArea from "../../../common/components/FormFields/InputArea";
import InputField from "../../../common/components/FormFields/InputField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchAntibioticPatientAutoComplete,
  fetchAntibioticPatientInfo,
  fetchAntibiotics,
  fetchSensitiveAntibiotic,
  saveAntibioticUseForm,
} from "../../services/pendingAntibioticFormServices/PendingAntibioticFormServices";
import PendingAntibioticListModal from "./PendingAntibioticListModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";

const cultureHasSent = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },
  {
    id: 0,
    label: "No",
    value: "No",
  },
];
const PendingAntibioticForm = (props) => {
  let location = useLocation();
  console.log(props);
  const token = JSON.parse(localStorage.getItem("userInfo"));
  const [userActions, setUserActions] = useState(null);
  const [privilege, setPrivilege] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [antiBioticArr, setAntiBioticArr] = useState([]);
  const [sensitiveAntibiotics, setSensitiveAntibiotics] = useState([]);
  const [antibiotics, setAntibiotics] = useState([]);

  //PendingAntibioticList modal
  const [
    openPendingAntibioticListModal,
    setOpenPendingAntibioticListModal,
  ] = useState(false);
  const handleOpenPendingAntibioticListModal = () =>
    setOpenPendingAntibioticListModal(true);
  const handleClosePendingAntibioticListModal = () =>
    setOpenPendingAntibioticListModal(false);
  ///

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
    defaultValues: {
      antibioticPrescribed: null,
      dose: "",
      duration: "",
      fromDate: new Date(),
      toDate: new Date(),
      //
      other: false,
      isCultureSent: 0,
      isCultureAvailable: 0,
      changeInPrescription: 0,
      patientSearch: "",
      provisionalDiagnosis: "",
      anyComorbidities: "",
      antiBioticUsedBefore: "",
      prophilaxis: false,
      esblProducer: false,
      MDRO: false,
      MRSA: false,
      VRE: false,
      otherReasonsOfAntibioticUse: "",
      isCultureSent: 0,
      dateOfReport: new Date(),
      blood: false,
      sputum: false,
      VRE: false,
      Pus: false,
      urine: false,
      ifOther: "",
      isCultureAvailable: 0,
      organismIsolated: "",
      sample: "",
      cultureSentDate: new Date(),
      changeInPrescription: 0,
      writePrescription: "",
      changedDose: "",
      changedDuration: "",
      changePrescriptionDate: "",
      deEscalationReasons: "",
      changedDuration: "",

      //
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    register,
    getValues,
    setError,
    resetField,
  } = methods;

  let OtherCheck = watch("other");
  let IsCultureSent = watch("isCultureSent");
  let IsCultureAvailable = watch("isCultureAvailable");
  let ChangeInPrescription = watch("changeInPrescription");

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(props.menuId || location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const getPatientInfoAutoComplete = (searchString) => {
    //
    searchString &&
      fetchAntibioticPatientAutoComplete(searchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };

  useEffect(() => {
    getPatientData(props.admissionId);
  }, [props]);

  const getPatientData = (admId) => {
    //
    admId &&
      fetchAntibioticPatientInfo(admId)
        .then((response) => response.data)
        .then((res) => {
          setSelectedPatient(res.result);
        });

    fetchSensitiveAntibiotic()
      .then((response) => response.data)
      .then((res) => {
        setSensitiveAntibiotics(res.result);
      });
  };
  const getAntibiotics = (searchString) => {
    //
    searchString &&
      fetchAntibiotics(searchString)
        .then((response) => response.data)
        .then((res) => {
          setAntibiotics(res.result);
        });
  };

  const handleAddAntibiotics = () => {
    const AntibioticPrescribed = getValues("antibioticPrescribed");
    const Dose = getValues("dose");
    const Duration = getValues("duration");
    const FromDate = getValues("fromDate");
    const ToDate = getValues("toDate");

    if (AntibioticPrescribed === null) {
      setError("antibioticPrescribed", { type: "custom", message: "required" });
    }
    if (Dose === "") {
      setError("dose", { type: "custom", message: "required" });
    }
    if (Duration === "") {
      setError("duration", { type: "custom", message: "required" });
    }
    if (FromDate === "") {
      setError("fromDate", { type: "custom", message: "required" });
    }
    if (ToDate === "") {
      setError("toDate", { type: "custom", message: "required" });
    }

    if (
      AntibioticPrescribed !== null &&
      Dose !== "" &&
      Duration !== "" &&
      FromDate !== "" &&
      ToDate !== ""
    ) {
      const newObj = {
        "Antibiotic Prescribed": AntibioticPrescribed.value,
        dose: Dose,
        duration: Duration,
        "Start Date": format(FromDate, "yyyy-MM-dd"),
        "End Date": format(ToDate, "yyyy-MM-dd"),
        endDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        itemId: AntibioticPrescribed.id,
        startDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        isDelete: false,
      };

      // let serviceAlreadyExists = false;

      // serviceAlreadyExists = dataResultServiceWise.some(
      //   (item) => !item.isDelete && item["ServiceId"] === newObj["ServiceId"]
      // );

      // if (serviceAlreadyExists) {
      //   warningAlert("Service Already Exists...");
      // } else {
      //   setDataResultServiceWise((prevData) => [...prevData, newObj]);
      // }

      setAntiBioticArr((prevData) => [...prevData, newObj]);

      resetField("antibioticPrescribed");
      resetField("dose");
      resetField("duration");
      resetField("fromDate");
      resetField("toDate");
    }
  };

  const onSubmit = (data) => {
    if (antiBioticArr.length > 0) {
      setAllFormData(data);
      setOpenConfirmationModal(true);
    } else {
      warningAlert("Please Provide At least One Antibiotic...");
    }
  };

  const handleFormSubmit = (data) => {
    const finalObj = {
      addedBy: token.userId,
      addedDateTime: new Date(),
      admissionId: selectedPatient?.AdmissionId,
      antibioticIds: sensitiveAntibiotics
        .filter((item) => item.isChecked)
        .map((item) => item.Id),
      antibioticPrescribedDetailsDtoList:
        antiBioticArr?.length > 0 &&
        antiBioticArr.map((item) => ({
          dose: item.dose,
          duration: item.duration,
          endDate: item.endDate,
          itemId: item.itemId,
          startDate: item.startDate,
        })),
      antibioticUseFormId: 0,
      antibioticUsedBeforeCulture: data.antiBioticUsedBefore || "",
      anyComorbidities: data.anyComorbidities || "",
      cultureRptDate: data.dateOfReport || new Date(),
      cultureSampleNo: data.sample || "",
      cultureSentDate: data.cultureSentDate || new Date(),
      dateTime: new Date(),
      diagnosis: data.provisionalDiagnosis || "",
      dose: data.changedDose || "",
      duration: data.changedDuration || "",
      isBloodSample: Number(data.blood) || 0,
      isChangeInPrescription: Number(data.changeInPrescription) || 0,
      isCultureRptAvailable: Number(data.isCultureAvailable) || 0,
      isCultureSent: Number(data.isCultureSent) || 0,
      isESBLProducer: Number(data.esblProducer) || 0,
      isMDRO: Number(data.mdro) || 0,
      isMRSA: Number(data.mrsa) || 0,
      isOtherSample: Number(data.other) || 0,
      isProphylaxis: Number(data.prophilaxis) || 0,
      isPusSample: Number(data.pus) || 0,
      isSputumSample: Number(data.sputum) || 0,
      isUrineSample: Number(data.urine) || 0,
      isVRE: Number(data.vre) || 0,
      organismIsolated: data.organismIsolated || "",
      otherReasonsOfAntibioticUse: data.otherReasonsOfAntibioticUse || "",
      prescriptionChangeDate: data.changePrescriptionDate || new Date(),
      reasonForNoDeEscalation: data.deEscalationReasons || "",
      sampleSentOther: data.ifOther || "",
      updatedBy: token.userId,
      updatedDateTime: new Date(),
      writePrescription: data.writePrescription || "",
    };

    saveAntibioticUseForm(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          // if (props.isFromClinicalCarechart) {
          //   getPatientData(props.admissionId);

          // }
          methods.reset();
          setSelectedPatient(null);
          setSensitiveAntibiotics([]);
          setAntiBioticArr([]);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  const handlePatchData = () => {
    let selectedRow;
    if (selectedRow !== null) {
      setValue("other");
      setValue("isCultureSent");
      setValue("isCultureAvailable");
      setValue("changeInPrescription");
      setValue("patientSearch");
      setValue("provisionalDiagnosis");
      setValue("anyComorbidities");
      setValue("antiBioticUsedBefore");
      setValue("prophilaxis");
      setValue("esblProducer");
      setValue("MDRO");
      setValue("MRSA");
      setValue("VRE");
      setValue("otherReasonsOfAntibioticUse");
      setValue("isCultureSent");
      setValue("dateOfReport");
      setValue("blood");
      setValue("sputum");
      setValue("VRE");
      setValue("Pus");
      setValue("urine");
      setValue("ifOther");
      setValue("isCultureAvailable");
      setValue("organismIsolated");
      setValue("sample");
      setValue("cultureSentDate");
      setValue("changeInPrescription");
      setValue("writePrescription");
      setValue("changedDose");
      setValue("changedDuration");
      setValue("changePrescriptionDate");
      setValue("deEscalationReasons");
      setValue("changedDuration");
    }
  };
  ////////table related
  const renderActions = (row, index) => {
    const handleDelete = (row, index) => {
      let temp = [...antiBioticArr];
      temp.splice(index, 1);
      setAntiBioticArr(temp);
    };
    return (
      <>
        <div className="flex gap-2 items-center">
          <div className="text-customGray">
            {/* <Tooltip title="Print"> */}
            <DeleteOnIcon
              onClick={() => {
                handleDelete(row, index);
              }}
            />
            {/* </Tooltip> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="mt-12 mb-2">
      <div className="text-center text-black font-bold text-xl col-span-9 mb-2">
        Pending Antibiotic Form
      </div>
      <form>
        <div className="grid lg:grid-cols-6 md:grid-cols-2 gap-3">
          <div className="col-span-2">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./ Mob No."
              dataArray={autocompleteData}
              name="patientSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={getPatientInfoAutoComplete}
              isDisabled={props.isFromClinicalCarechart}
              inputRef={{
                ...register("patientSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      getPatientData(e.target.value.id);
                    } else {
                      methods.reset();
                      setSelectedPatient(null);
                      setSensitiveAntibiotics([]);
                      setAntiBioticArr([]);
                    }
                  },
                }),
              }}
            />
          </div>

          <div className="col-span-4 grid justify-end">
            <CommonButton
              label={"Pending Antibiotic List"}
              className="bg-customBlue text-white"
              onClick={() => {
                handleOpenPendingAntibioticListModal();
              }}
            />
          </div>
        </div>
        <div className="my-3">
          {/* patientInfo */}
          <fieldset
            className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
          >
            <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.patientName}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">MR. No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.MRNo}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.AgeYear}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                <span className=" font-semibold w-28">Gender</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {selectedPatient && selectedPatient.Gender}
                  </span>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        {selectedPatient !== null ? (
          <div className="grid grid-cols-6 gap-3">
            <div className="col-span-6">
              <InputArea
                control={control}
                name="provisionalDiagnosis"
                label="Provisional Diagnosis"
                placeholder="Provisional Diagnosis"
                //error={errors.provisionalDiagnosis}
              />
            </div>

            <div className="col-span-6">
              <InputArea
                control={control}
                name="anyComorbidities"
                label="Any Comorbidities"
                placeholder="Any Comorbidities"
                //error={errors.anyComorbidities}
              />
            </div>
            <div className="grid col-span-6 lg:grid-cols-6 md:grid-cols-3 gap-3">
              <div className="">
                <SearchDropdown
                  control={control}
                  placeholder="Antibiotic Prescribed *"
                  dataArray={antibiotics}
                  name="antibioticPrescribed"
                  searchIcon={true}
                  error={errors.antibioticPrescribed}
                  isClearable={true}
                  handleInputChange={getAntibiotics}
                  // inputRef={{
                  //   ...register("antibioticPrescribed", {
                  //     onChange: (e) => {
                  //
                  //       if (e.target.value !== null) {
                  //         getPatientData(e.target.value.id);
                  //       } else {
                  //         methods.reset();
                  //         setSelectedPatient(null);
                  //         setSensitiveAntibiotics([]);
                  //         setAntiBioticArr([]);
                  //       }
                  //     },
                  //   }),
                  // }}
                />
              </div>
              <div className="">
                <InputField
                  name="dose"
                  label="Dose *"
                  variant="outlined"
                  error={errors.dose}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="">
                <InputField
                  name="duration"
                  label="Duration *"
                  variant="outlined"
                  error={errors.duration}
                  control={control}
                  disabled={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              {/* from Date */}
              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  disableFuture={false}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              {/* to Date */}
              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  disableFuture={false}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <CommonButton
                  label="Add"
                  className="bg-customGreen text-white"
                  onClick={() => {
                    handleAddAntibiotics();
                  }}
                />
              </div>

              {antiBioticArr.length > 0 && (
                <div className="col-span-6">
                  <CommonDynamicTableNew
                    dataResult={antiBioticArr}
                    highlightRow={false}
                    renderActions={renderActions}
                    removeHeaders={[
                      "id",
                      "endDate",
                      "itemId",
                      "startDate",
                      "isDelete",
                    ]}
                  />
                </div>
              )}
            </div>
            <div className="col-span-6">
              <InputArea
                control={control}
                name="antiBioticUsedBefore"
                label="AntiBiotic Used Before Sending Culture"
                placeholder="AntiBiotic Used Before Sending Culture"
                //error={errors.provisionalDiagnosis}
              />
            </div>
            <div className="lg:col-span-4 md:col-span-6 grid lg:grid-cols-7 md:grid-cols-3 items-center">
              <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins">
                Reasons for use of antibiotics
              </p>

              <div className="">
                <CheckBoxField
                  control={control}
                  name="prophilaxis"
                  label="Prophilaxis"
                />
              </div>
              <div className="">
                <CheckBoxField
                  control={control}
                  name="esblProducer"
                  label="ESBL Producer"
                />
              </div>
              <div className="">
                <CheckBoxField control={control} name="mdro" label="MDRO" />
              </div>
              <div className="">
                <CheckBoxField control={control} name="mrsa" label="MRSA" />
              </div>
              <div className="">
                <CheckBoxField control={control} name="vre" label="VRE" />
              </div>
            </div>

            <div className="lg:col-span-2 md:col-span-6">
              <InputArea
                control={control}
                name="otherReasonsOfAntibioticUse"
                label="Other"
                placeholder="Other"
                //error={errors.provisionalDiagnosis}
              />
            </div>
            <div className="lg:col-span-3 md:col-span-6 flex gap-3 items-center">
              <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                Have culture been sent
              </p>
              <RadioField
                label=""
                name="isCultureSent"
                control={control}
                dataArray={cultureHasSent}
              />
              {Number(IsCultureSent) === 1 && (
                <>
                  <div className="">
                    <DatePickerFieldNew
                      control={control}
                      name="dateOfReport"
                      label="Date Of Report"
                      value={new Date()}
                      disableFuture={false}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="col-span-6 grid grid-cols-8 items-center">
              <p className=" lg:col-span-1 md:col-span-2 mr-3 font-bold tracking-wide text-sm font-Poppins">
                Sample Sent Of
              </p>

              <div className="">
                <CheckBoxField control={control} name="blood" label="Blood" />
              </div>
              <div className="">
                <CheckBoxField control={control} name="sputum" label="Sputum" />
              </div>
              <div className="">
                <CheckBoxField control={control} name="pus" label="Pus" />
              </div>
              <div className="">
                <CheckBoxField control={control} name="urine" label="Urine" />
              </div>
              <div className="">
                <CheckBoxField control={control} name="other" label="Other" />
              </div>
              {OtherCheck && (
                <div className="col-span-2">
                  <InputArea
                    control={control}
                    name="ifOther"
                    label="If Other (Specify)"
                    placeholder="If Other (Specify)"
                    //error={errors.provisionalDiagnosis}
                  />
                </div>
              )}
            </div>

            <div className="lg:col-span-6 md:col-span-6 flex gap-3 items-center">
              <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                Have culture report available
              </p>
              <RadioField
                label=""
                name="isCultureAvailable"
                control={control}
                dataArray={cultureHasSent}
              />
              {Number(IsCultureAvailable) === 1 && (
                <>
                  <div>
                    <InputField
                      name="organismIsolated"
                      label="Organism Isolated"
                      variant="outlined"
                      error={errors.antibioticPrescribed}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div>
                    <InputField
                      name="sample"
                      label="Sample"
                      variant="outlined"
                      error={errors.antibioticPrescribed}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>

                  <div className="">
                    <DatePickerFieldNew
                      control={control}
                      name="cultureSentDate"
                      label="Culture Sent Date"
                      value={new Date()}
                      disableFuture={false}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </>
              )}
            </div>

            <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
              Sensitive Antibiotics
            </p>
            {sensitiveAntibiotics.length > 0 && (
              <div className="col-span-6">
                <CommonDynamicTableNew
                  dataResult={sensitiveAntibiotics}
                  setDataResult={setSensitiveAntibiotics}
                  removeHeaders={["Id", "isChecked"]}
                  enableSelectAll={true}
                  highlightRow={false}
                  tableClass="h-52"
                />
              </div>
            )}

            <div className="lg:col-span-6 md:col-span-6 flex gap-3 items-center">
              <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                Change In Prescription
              </p>
              <RadioField
                label=""
                name="changeInPrescription"
                control={control}
                dataArray={cultureHasSent}
              />
              {Number(ChangeInPrescription) === 1 && (
                <>
                  <div>
                    <InputField
                      name="writePrescription"
                      label="Write Prescription"
                      variant="outlined"
                      // error={errors.writePrescription}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div>
                    <InputField
                      name="changedDose"
                      label="Dose"
                      variant="outlined"
                      // error={errors.antibioticPrescribed}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div>
                    <InputField
                      name="changedDuration"
                      label="Duration"
                      variant="outlined"
                      // error={errors.antibioticPrescribed}
                      control={control}
                      disabled={false}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>

                  <div className="">
                    <DatePickerFieldNew
                      control={control}
                      name="changePrescriptionDate"
                      label="Date Change Of Prescription"
                      value={new Date()}
                      disableFuture={false}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="col-span-6">
              <InputArea
                control={control}
                name="deEscalationReasons"
                label="Write Reasons For No De-Escalation"
                placeholder="Write Reasons For No De-Escalation"
                //error={errors.provisionalDiagnosis}
              />
            </div>

            <div className="col-span-6 gap-3 my-3 flex justify-end">
              {!!userActions &&
                userActions.map((actions) => (
                  <>
                    {!actions.isAction ? (
                      <>
                        <div className="flex cursor-pointer items-center">
                          {actions.action === "Create" && (
                            <>
                              <div className="gap-3 my-3 flex justify-end">
                                <div>
                                  <CommonButton
                                    label="Reset"
                                    className="border border-customRed text-customRed"
                                    onClick={() => {
                                      methods.reset();
                                      setSelectedPatient(null);
                                      setSensitiveAntibiotics([]);
                                      setAntiBioticArr([]);
                                    }}
                                  />
                                </div>
                                <div
                                  onClick={() => setPrivilege(actions.action)}
                                >
                                  <CommonButton
                                    label="Save"
                                    className="bg-customGreen text-white"
                                    onClick={methods.handleSubmit(onSubmit)}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
            </div>
          </div>
        ) : null}
      </form>

      {/* //Instruction Modal// */}
      {openPendingAntibioticListModal && (
        <PendingAntibioticListModal
          open={openPendingAntibioticListModal}
          setOpen={setOpenPendingAntibioticListModal}
          handleOpen={handleOpenPendingAntibioticListModal}
          handleClose={handleClosePendingAntibioticListModal}
          menuId={props.menuId || location?.state?.menuId}
          privilege={privilege}
        />
      )}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default PendingAntibioticForm;
