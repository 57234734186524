import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//Blood issue Acceptance List
export const fetchBloodIssueNoteAcceptanceList = (tableObj) => {
  return apiClient.post(`/bloodBank/bloodIssueNoteAcceptanceList`, tableObj, {
    headers: authHeader(),
  });
};

//autoComplete
export const fetchBloodIssueNoteAcceptanceListSearch = (searchObj) => {
  return apiClient.post(
    `/bloodBank/bloodIssueNoteAcceptanceListSearch
    `,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};

export const saveAcceptIssueNote = (IssueId, menuId, privilege) => {
  return apiClient.get(
    `/bloodBank/acceptIssueNote/${IssueId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};
