import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

// fetchMlcType -- mlc modal
export const fetchMlcType = () => {
  return apiClient.get(`/patientEmergencyInfo/mlcTypes`, {
    headers: authHeader(),
  });
};

// // MLC POST From Listing
export const postMlcData = async (mlcObj) => {
  console.log(" emergency OBJ @service page", mlcObj);
  return await apiClient.post(`/medicoLegalCase/saveMedicoLegalCase`, mlcObj, {
    headers: authHeader(),
  });
};
// POST List
export const fetchAllEmergency = async (searchEmergencyObj) => {
  console.log("fetch all emergency OBJ ", searchEmergencyObj);
  return await apiClient.post(
    `/patientEmergencyInfo/emergencyPatientList`,
    searchEmergencyObj,
    {
      headers: authHeader(),
    }
  );
};

//Get Unit List
export const fetchUnit = () => {
  return apiClient.get(`/misc/unit`, {
    headers: authHeader(),
  });
};

//Get Unit List
export const fetEmergencyBedCategory = () => {
  return apiClient.get(`/misc/emergencyBedCategory`, {
    headers: authHeader(),
  });
};

// Patient Source
export const getpatientSource = () => {
  return apiClient.get(`/misc/patientsource`, {
    headers: authHeader(),
  });
};

// getDoctore department Id service
export const getDoctorDropdown = (unitId) => {
  console.log("Get Doctor Dropdown options is : ", unitId);
  return apiClient.get(`/employees/doctorDropdown?unitId=${unitId}`, {
    headers: authHeader(),
  });
};

//Post request to save the modal form data into the database.
export const postMyEmergencyData = async (finalObject) => {
  console.log("Object sent for data record in emergency is ", finalObject);
  return await apiClient.post(
    `/patientEmergencyInfo/emergencyRegistration`,
    finalObject,
    {
      headers: authHeader(),
    }
  );
};

// search data , Get request to fetch the autocomplete options
export const autoSearchService = (searchString) => {
  console.log("searchString", searchString);
  return apiClient.get(`/patientEmergencyInfo/search/${searchString}`, {
    headers: authHeader(),
  });
};

//search by Patient UHID/ Name Number
// export const autoSerachPatient = (searchInfo) => {
//   return apiClient.get(`/patientInfo/${searchInfo}`);
// };
export const autoSerachPatient = (searchInfo, opdIpd) => {
  console.log(opdIpd, searchInfo);
  return apiClient.get(
    `/patientInfo/opdIpdAutoComplete/${searchInfo}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};
/////nnw autocomplete
export const emergencyPatientListAutoComplete = (autoCompleteObj) => {
  return apiClient.post(
    `/patientEmergencyInfo/emergencyPatientListSearch`,
    autoCompleteObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchPatientInformation = (patientId) => {
  console.log(patientId);
  return apiClient.get(`/patientInfo/registration/${patientId}`, {
    headers: authHeader(),
  });
}; /////old service..

export const fetchPatientInformationEmergencyNew = (patientId) => {
  console.log(patientId);
  return apiClient.get(`/patientEmergencyInfo/registration/${patientId}`, {
    headers: authHeader(),
  });
};

// search data ,Get request to fetch the autocomplete options
export const autoSearchEmeregncy = (searchString) => {
  console.log("Search patient is " + JSON.stringify(searchString));
  return apiClient.get(
    `/patientInfo/${searchString}`,
    {
      headers: authHeader(),
    },
    {
      headers: authHeader(),
    }
  );
};

// ?addedBy=54&cancelRemark=dsddsf&emergencyId=33819
export const saveAdmissionDenial = (
  cancelRemark,
  emergencyId,
  menuId,
  privilege
) => {
  // console.log("Search patient is " + admDenialObj);
  return apiClient.get(
    `/patientEmergencyInfo/saveDenialAdmission?cancelRemark=${cancelRemark}&emergencyId=${emergencyId}&menuId=${menuId}&privilege=${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

// /api/patientEmergencyInfo/saveTransferToWard
export const saveTransferToWard = (
  emergencyId,
  transferRemark,
  menuId,
  privilege
) => {
  console.log("saveTransferToWardsaveTransferToWard");
  return apiClient.get(
    `/patientEmergencyInfo/saveTransferToWard?emergencyId=${emergencyId}&transferRemark=${transferRemark}&menuId=${menuId}&privilege=${privilege}`,
    {
      headers: authHeader(),
    }
  );
};
// saveOPDTreatmentSheet
export const saveOPDTreatmentRecord = (opdTreatmentPostObj) => {
  console.log(
    "opdTreatmentPostObjopdTreatmentPostObjopdTreatmentPostObj",
    opdTreatmentPostObj,
    {
      headers: authHeader(),
    }
  );
  return apiClient.post(
    `/patientEmergencyInfo/saveOPDTreatmentSheet`,
    opdTreatmentPostObj,
    {
      headers: authHeader(),
    }
  );
};

//opdTreatmentDetails
export const fetchOpdTreatmentDetails = (emergencyId) => {
  return apiClient.get(
    `/patientEmergencyInfo/opdTreatmentDetails/${emergencyId}`,
    {
      headers: authHeader(),
    }
  );
};

// opdTreatmentMedicationDetails
export const fetchOpdTreatmentMedicationDetails = (opdTreatmentId) => {
  return apiClient.get(
    `/patientEmergencyInfo/opdTreatmentMedicationDetails/${opdTreatmentId}`,
    {
      headers: authHeader(),
    }
  );
};

//advisedServices
export const fetchAdvisedServices = (opdTreatmentId) => {
  return apiClient.get(
    `/patientEmergencyInfo/advisedServices/${opdTreatmentId}`,
    {
      headers: authHeader(),
    }
  );
};

//patch data
export const fetchEmergencyRegPatchDetails = (emergencyId) => {
  return apiClient.get(
    `/patientEmergencyInfo/emergencyRegDetails/${emergencyId}`,
    {
      headers: authHeader(),
    }
  );
};

//print mlc
export const fetchEmergencyMlcPrint = (admissionId, formId) => {
  return apiClient.get(`/reports/emergency/mlcPrint/${admissionId}/${formId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const fetchIpdConsentFormPrint = (emergencyId) => {
  return apiClient.get(`/reports/getAdmissionConsentForm/${emergencyId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

//Print Opd-Trt-Record
export const fetchPrintOpdTrtRecord = (EmergencyPatientId) => {
  return apiClient.get(
    `/reports/emergency/opdTreatmentRecord/${EmergencyPatientId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

//Print Opd-Charging-Slip
export const fetchPrintOpdChargingSlip = (emergencyId) => {
  return apiClient.get(`/reports/emergency/opdChargingSlip/${emergencyId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

//Print Opd-Charging-Slip
export const fetchAdmissionRefusal = (emergencyId) => {
  return apiClient.get(`/reports/getAdmissionRefusal/${emergencyId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};
