import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const saveInitialNursingAssessment = async (nursingAssessmentObj) => {
  console.log("nursing_Assessment_Final_Obj", nursingAssessmentObj);
  return await apiClient.post(
    `/clinicalCareChart/saveInitialNursingAssessment`,
    nursingAssessmentObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchInitialNursingAssessment = async (admissionIdVal) => {
  return await apiClient.get(
    `/clinicalCareChart/initialNursingAssessmentDetails/${admissionIdVal}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchNursingAssessmentList = async (assessmentListObj) => {
  return await apiClient.post(
    `/clinicalCareChart/initialNursingAssesListing`,
    assessmentListObj,
    {
      headers: authHeader(),
    }
  );
};

export const getAssessmentPrint = async (admissionIdVal,initialNursingAssId) => {
  return await apiClient.get(
    `/reports/nursing/initialAssessmentNursing/${admissionIdVal}/${initialNursingAssId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};
