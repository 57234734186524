function getPatientVulnerabilityCriteria(data) {
  let initialNursingAssVulnerabilityDto = {
    assessmentScore: false,
    belowAgeOf16Years: false,
    cerebralVascularAccident: false,
    emotionallyDisturbed: false,
    experiencingPain: false,
    historyOfPreviousFalls: false,
    patientsAbove65Years: false,
    physicalLimitationBothVisualAndMobility: false,
    postOperative: false,
    posturalHypotension: false,
    suspectedDrug: false,
    terminallyIII: false,
    visualHearingDefects: false,
    womanInLabour: false,
  };
  if (data.hasOwnProperty("History of Previous Falls")) {
    if (data["History of Previous Falls"]) {
      initialNursingAssVulnerabilityDto.historyOfPreviousFalls = true;
    }
  }

  if (data.hasOwnProperty("Patient Who Are Above 65 years")) {
    if (data["Patient Who Are Above 65 years"]) {
      initialNursingAssVulnerabilityDto.patientsAbove65Years = true;
    }
  }

  if (data.hasOwnProperty("Below Age of 16 years")) {
    if (data["Below Age of 16 years"]) {
      initialNursingAssVulnerabilityDto.belowAgeOf16Years = true;
    }
  }

  if (
    data.hasOwnProperty(
      "Patients with Physical Limitations :- Both Visual and Mobility"
    )
  ) {
    if (
      data["Patients with Physical Limitations :- Both Visual and Mobility"]
    ) {
      initialNursingAssVulnerabilityDto.physicalLimitationBothVisualAndMobility = true;
    }
  }

  if (data.hasOwnProperty("Patients With Cerebral vasculr Accident (CVA)")) {
    if (data["Patients With Cerebral vasculr Accident (CVA)"]) {
      initialNursingAssVulnerabilityDto.cerebralVascularAccident = true;
    }
  }

  if (
    data.hasOwnProperty(
      "Patients Who Have Postural Hypotension and Dizziness / Vertigo"
    )
  ) {
    if (
      data["Patients Who Have Postural Hypotension and Dizziness / Vertigo"]
    ) {
      initialNursingAssVulnerabilityDto.posturalHypotension = true;
    }
  }

  if (data.hasOwnProperty("Emotionally Disturbed / psychiatric Pts")) {
    if (data["Emotionally Disturbed / psychiatric Pts"]) {
      initialNursingAssVulnerabilityDto.emotionallyDisturbed = true;
    }
  }

  if (data.hasOwnProperty("Patients With Visual/ hearing Defects")) {
    if (data["Patients With Visual/ hearing Defects"])
      initialNursingAssVulnerabilityDto.visualHearingDefects = true;
  }

  if (data.hasOwnProperty("Post Operative in the last 24 hours")) {
    if (data["Post Operative in the last 24 hours"]) {
      initialNursingAssVulnerabilityDto.postOperative = true;
    }
  }

  if (data.hasOwnProperty("Terminally ill")) {
    if (data["Terminally ill"]) {
      initialNursingAssVulnerabilityDto.terminallyIII = true;
    }
  }

  if (data.hasOwnProperty("Experiencing pain")) {
    if (data["Experiencing pain"]) {
      initialNursingAssVulnerabilityDto.experiencingPain = true;
    }
  }

  if (data.hasOwnProperty("Women in labour")) {
    if (data["Women in labour"]) {
      initialNursingAssVulnerabilityDto.womanInLabour = true;
    }
  }

  if (data.hasOwnProperty("Victim of Suspected drug/ Substance Abuse")) {
    if (data["Victim of Suspected drug/ Substance Abuse"]) {
      initialNursingAssVulnerabilityDto.suspectedDrug = true;
    }
  }

  if (data.hasOwnProperty("Patient Fall Assessment score of >13")) {
    if (data["Patient Fall Assessment score of >13"]) {
      initialNursingAssVulnerabilityDto.assessmentScore = true;
    }
  }

  return initialNursingAssVulnerabilityDto;
}

export default getPatientVulnerabilityCriteria;
